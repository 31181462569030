import React from "react";
import * as themes from "./JoinUsThemes";
import * as textThemes from "themes/TextThemes";
import { Box, Typography } from "@mui/material";
import SearchIcon from "assets/PromoTutorAssets/PNG/SearchIcon.png";
import CalendarIcon from "assets/PromoTutorAssets/PNG/CalendarIcon.png";
import DollarIcon from "assets/PromoTutorAssets/PNG/DollarIcon.png";
import BellIcon from "assets/PromoTutorAssets/PNG/BellIcon.png";
import CrowIcon from "assets/PromoTutorAssets/PNG/CrowIcon.png";
import JoinUsPhoto from "assets/CashbackAssets/PNG/JoinUsPhoto.png";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import SButton from "components/UI/SButton";
import AuthorizationStore from "store/AuthorizationStore";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import UserStore from "../../../store/UserStore";

const JoinUs = observer(({ children, ...props }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleClickSignUp = () => {
        AuthorizationStore.setPreviousLocationPath("/cashback");
        AuthorizationStore.setCurrentAuthorizationStep("SignIn");
        AuthorizationStore.setPreSMSPage(null);
        AuthorizationStore.setTemporaryEmail("");
        AuthorizationStore.setTemporaryPassword("");
        AuthorizationStore.setTemporaryPhoneNumber("");
        navigate("/authorization");
    };

    return (
        <Box sx={themes.ServiceAdvantagesBlock}>
            <img
                src={JoinUsPhoto}
                alt="JoinUsPhoto"
            />
            <Box sx={themes.JoinUsContent}>
                <Box sx={themes.TitleBlock}>
                    <Box>
                        <Typography sx={textThemes.HeadlineH2Black}>{t("promoPages.cashback.areYouTutor1")}</Typography>
                        <Typography sx={textThemes.HeadlineH2Black}>{t("promoPages.cashback.areYouTutor2")}</Typography>
                    </Box>
                    <Typography sx={textThemes.Body1Black}>{t("promoPages.cashback.developTutor")}</Typography>
                </Box>

                <Box sx={themes.BottomBlock}>
                    <Box sx={themes.FunctionsBlock}>
                        <Box sx={themes.functionElement}>
                            <Box sx={themes.iconElem}>
                                <img
                                    src={SearchIcon}
                                    alt="SearchIcon"
                                />
                            </Box>
                            <Typography sx={textThemes.Body1Black}>{t("promoPages.cashback.fastAndEasy")}</Typography>
                        </Box>

                        <Box sx={themes.functionElement}>
                            <Box sx={themes.iconElem}>
                                <img
                                    src={CalendarIcon}
                                    alt="CalendarIcon"
                                />
                            </Box>
                            <Typography sx={textThemes.Body1Black}>{t("promoPages.cashback.setUpYourOwnPrices")}</Typography>
                        </Box>

                        <Box sx={themes.functionElement}>
                            <Box sx={themes.iconElem}>
                                <img
                                    src={DollarIcon}
                                    alt="DollarIcon"
                                />
                            </Box>
                            <Typography sx={textThemes.Body1Black}>{t("promoPages.cashback.getPaid")}</Typography>
                        </Box>

                        <Box sx={themes.functionElement}>
                            <Box sx={themes.iconElem}>
                                <img
                                    src={BellIcon}
                                    alt="BellIcon"
                                />
                            </Box>
                            <Typography sx={textThemes.Body1Black}>{t("promoPages.cashback.conductMoreClasses")}</Typography>
                        </Box>

                        <Box sx={themes.functionElement}>
                            <Box sx={themes.iconElem}>
                                <img
                                    src={CrowIcon}
                                    alt="CrowIcon"
                                />
                            </Box>
                            <Typography sx={textThemes.Body1Black}>{t("promoPages.cashback.automaticallyGetHighRating")}</Typography>
                        </Box>
                    </Box>
                    {UserStore.isAuth !== true ? (
                        <SButton
                            onClick={handleClickSignUp}
                            style={themes.Button}
                            variant="unshadowed"
                            padding="15px 26px"
                        >
                            {t("promoPages.cashback.becomeTutor")}
                        </SButton>
                    ) : null}
                </Box>
            </Box>
        </Box>
    );
});

export default memo(JoinUs);
