import React, { memo } from "react";
import * as themes from "./LeftCardBlockThemes";
import * as textThemes from "themes/TextThemes";
import { observer } from "mobx-react-lite";
import MainPageStore from "store/MainPageStore";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useMedias } from "../../../../API/mediasHook";
import Clock from "../../../MainPromoBodyBlock/HowWork/RightCardBlock/StateOne/clock.png";

const LeftCardBlock = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const toggleActiveItem = (value) => {
        MainPageStore.SetActiveHowWorkItem(value, "manual");
    };

    const medias = useMedias();

    return (
        <Box sx={themes.cardBlock(medias)}>
            <Box
                sx={MainPageStore.activeHowWorkItem === 0 ? themes.Card(true, medias) : themes.Card(false, medias)}
                onClick={() => toggleActiveItem(0)}
            >
                <Box sx={textThemes.Button20BoldBlack}>{t("promoPages.promoTutor.createAccount")}</Box>
                <Box sx={textThemes.Body2Black}>{t("promoPages.promoTutor.selectSubjects")}</Box>
            </Box>

            <Box
                sx={MainPageStore.activeHowWorkItem === 1 ? themes.Card(true, medias) : themes.Card(false, medias)}
                onClick={() => toggleActiveItem(1)}
            >
                <Box sx={textThemes.Button20BoldBlack}>{t("promoPages.promoTutor.chooseStudents")}</Box>
                <Box sx={textThemes.Body2Black}>{t("promoPages.promoTutor.respondOrders")}</Box>
            </Box>

            <Box
                sx={MainPageStore.activeHowWorkItem === 2 ? themes.Card(true, medias) : themes.Card(false, medias)}
                onClick={() => toggleActiveItem(2)}
            >
                <Box sx={textThemes.Button20BoldBlack}>{t("promoPages.promoTutor.getPoints")}</Box>
                <Box sx={textThemes.Body2Black}>{t("promoPages.promoTutor.smallCommission")}</Box>
            </Box>
        </Box>
    );
});

export default memo(LeftCardBlock);
