import { makeAutoObservable } from "mobx";
import { getCookie } from "API/utils";
import UserStore from "store/UserStore";
import * as AccountManagerService from "API/services/grpc/AccountManagerService";
import * as TypicalInfoService from "API/services/grpc/TypicalInfoService";
import { handleStoreActionError } from "API/plugins/errorHandlers";

class TeacherQStore {
    sexList = ["TeacherQuestionnaire.notSelected", "TeacherQuestionnaire.male", "TeacherQuestionnaire.female"];

    citiesList = [];
    conditionsBySubjectObject = {};
    districtsAndUndergroundStationsByCityList = [];
    subjectsList = [];

    loadingProfileData = true;

    daysList = Array.apply(null, new Array(31)).map((val, index) => {
        return index + 1;
    });
    monthList = [
        "main.months.atJanuary",
        "main.months.atFebruary",
        "main.months.atMarch",
        "main.months.atApril",
        "main.months.atMay",
        "main.months.atJune",
        "main.months.atJuly",
        "main.months.atAugust",
        "main.months.atSeptember",
        "main.months.atOctober",
        "main.months.atNovember",
        "main.months.atDecember",
    ];
    yearsList = Array.apply(null, new Array(new Date().getFullYear() - 1929))
        .map((val, index) => {
            return index + 1930;
        })
        .sort((a, b) => b - a);
    timeToClassesList = [
        "TeacherQuestionnaire.7hours",
        "TeacherQuestionnaire.6hours",
        "TeacherQuestionnaire.5hours",
        "TeacherQuestionnaire.4hours",
        "TeacherQuestionnaire.3hours",
        "TeacherQuestionnaire.2hours",
        "TeacherQuestionnaire.1hour",
    ];
    timeToClassesDict = {
        "TeacherQuestionnaire.7hours": 7,
        "TeacherQuestionnaire.6hours": 6,
        "TeacherQuestionnaire.5hours": 5,
        "TeacherQuestionnaire.4hours": 4,
        "TeacherQuestionnaire.3hours": 3,
        "TeacherQuestionnaire.2hours": 2,
        "TeacherQuestionnaire.1hour": 1,
    };
    fullWeekDaysObject = {
        Понедельник: "main.daysOfWeek.mondayUpper",
        Вторник: "main.daysOfWeek.tuesdayUpper",
        Среда: "main.daysOfWeek.wednesdayUpper",
        Четверг: "main.daysOfWeek.thursdayUpper",
        Пятница: "main.daysOfWeek.fridayUpper",
        Суббота: "main.daysOfWeek.saturdayUpper",
        Воскресенье: "main.daysOfWeek.sundayUpper",
    };

    timings = {
        Утро: ["06:00", "07:00", "08:00", "09:00", "10:00", "11:00"],
        День: ["12:00", "13:00", "14:00", "15:00", "16:00", "17:00"],
        Вечер: ["18:00", "19:00", "20:00", "21:00", "22:00", "23:00"],
        Ночь: ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00"],
    };

    timingsTranslate = {
        Утро: "TeacherQuestionnaire.morning",
        День: "TeacherQuestionnaire.afternoon",
        Вечер: "TeacherQuestionnaire.evening",
        Ночь: "TeacherQuestionnaire.night",
    };

    teacherQuestionnaireSteps = [
        "TeacherQuestionnaire.personalData",
        "TeacherQuestionnaire.subjects",
        "TeacherQuestionnaire.specialization",
        "TeacherQuestionnaire.placeOfClasses",
        "TeacherQuestionnaire.education",
        "TeacherQuestionnaire.experience",
        "TeacherQuestionnaire.certificates",
        "TeacherQuestionnaire.schedule",
        "TeacherQuestionnaire.prices",
        "TeacherQuestionnaire.profile",
    ];

    teacher = {
        firstName: "",
        secondName: "",
        surname: "",
        sex: "TeacherQuestionnaire.notSelected",
        birthday: {
            day: null,
            month: null,
            year: null,
        },
        city: null,
        phone: "",
        email: "",
        studentTypes: {
            scholar: false,
            student: false,
            adult: false,
        },
        subjects: [],
        education: [
            {
                id: 1,
                university: "",
                faculty: "",
                speciality: "",
                beginYear: "",
                endYear: "",
                stillStudying: false,
                photo: { imageSize: "", imageName: "", imageSrc: "" },
            },
        ],
        experience: [
            {
                id: 1,
                organization: "",
                position: "",
                beginYear: "",
                endYear: "",
                stillWorking: false,
            },
        ],
        certificates: [
            {
                id: 1,
                certificate: "",
                receiptYear: "",
                photo: { imageSize: "", imageName: "", imageSrc: "" },
                showToStudents: "",
            },
        ],
        timeToClasses: "TeacherQuestionnaire.7hours",
        timingsTemp: {
            Понедельник: [],
            Вторник: [],
            Среда: [],
            Четверг: [],
            Пятница: [],
            Суббота: [],
            Воскресенье: [],
        },
        timings: {
            Понедельник: [],
            Вторник: [],
            Среда: [],
            Четверг: [],
            Пятница: [],
            Суббота: [],
            Воскресенье: [],
        },
        timeZone: {
            value: "Moscow Standard Time",
            abbr: "MSK",
            offset: 3,
            isdst: false,
            text: "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk",
            utc: ["Europe/Kirov", "Europe/Moscow", "Europe/Simferopol", "Europe/Volgograd", "Europe/Minsk"],
        },
        links: [],
        about: "",
        photo: { imageSize: "", imageName: "", imageSrc: "" },
        miniature: { imageSize: "", imageName: "", imageSrc: "" },
        videoUrl: null,
        video: { videoSize: "", videoName: "", videoSrc: "", isPublic: false },
        classFormats: [],
        studentsPlaces: { city: "", locations: [] },
        tutorsPlace: { city: "", locations: [] },
        emailNotifications: {
            classesSchedule: true,
            studentMessages: true,
            orders: true,
            responses: true,
            payments: true,
            updates: true,
            serviceImprovement: true,
        },
        telegramNotifications: {
            linked: true,
            classesSchedule: true,
            studentMessages: true,
            orders: true,
            responses: true,
            payments: true,
            updates: true,
        },
        privilegeProgram: {
            nextMonth: null,
            currentMonth: null,
        },
        conductedClasses: {
            number: null,
            points: null,
            previousNumber: null,
        },
        academicWorks: {
            number: null,
            points: null,
        },
        invitedUsers: {
            number: null,
            points: null,
        },
        anonymousProfile: false,
        verifiedProfile: false,
        cancelClassAttempts: null,
    };

    privilegeProgram = {
        4: {
            lowerBoundary: 0,
            commission: 15,
            cashback: 0,
        },
        3: {
            lowerBoundary: 300,
            commission: 14,
            cashback: 0,
        },
        2: {
            lowerBoundary: 700,
            commission: 13,
            cashback: 2,
        },
        1: {
            lowerBoundary: 1200,
            commission: 12,
            cashback: 2,
        },
    };

    steps = {
        step0: 0,
        step1: 0,
        step2: 0,
        step3: 0,
        step4: 0,
        step5: 0,
        step6: 0,
        step7: 0,
        step8: 0,
        step9: 0,
    };

    initialInputPhoto = "";

    temporaryCroppedPhoto = "";
    temporaryCroppedMiniature = "";

    croppingStep = 0;

    openCropDialog = false;

    requestSent = false;

    // temporaryPersonalData = {
    //     firstName: this.teacher.firstName,
    //     secondName: this.teacher.secondName,
    //     surname: this.teacher.surname,
    //     sex: this.teacher.sex,
    //     birthDay: this.teacher.birthday.getDate(),
    //     birthMonth: this.monthList[this.teacher.birthday.getMonth()],
    //     birthYear: this.teacher.birthday.getFullYear(),
    // };

    temporaryAboutTeacher = this.teacher.about;

    temporaryVideoCard = this.teacher.video;

    temporaryEducation = JSON.parse(JSON.stringify(this.teacher.education));

    temporaryExperience = JSON.parse(JSON.stringify(this.teacher.experience));

    temporaryCertificates = JSON.parse(JSON.stringify(this.teacher.certificates));

    temporarySubjects = JSON.parse(JSON.stringify(this.teacher.subjects));

    temporaryLinks = JSON.parse(JSON.stringify(this.teacher.links));

    temporaryPlaces = JSON.parse(JSON.stringify(this.teacher.studentsPlaces));

    temporarySiteName = "";
    temporarySiteLink = "";

    temporaryTimezone = JSON.parse(JSON.stringify(this.teacher.timeZone));

    temporaryTimeToClasses = this.teacher.timeToClasses;

    monthNames = [
        "teacherAccount.months.january",
        "teacherAccount.months.february",
        "teacherAccount.months.march",
        "teacherAccount.months.april",
        "teacherAccount.months.may",
        "teacherAccount.months.june",
        "teacherAccount.months.july",
        "teacherAccount.months.august",
        "teacherAccount.months.september",
        "teacherAccount.months.october",
        "teacherAccount.months.november",
        "teacherAccount.months.december",
    ];

    monthNamesIn = [
        "teacherAccount.months.inJanuary",
        "teacherAccount.months.inFebruary",
        "teacherAccount.months.inMarch",
        "teacherAccount.months.inApril",
        "teacherAccount.months.inMay",
        "teacherAccount.months.inJune",
        "teacherAccount.months.inJuly",
        "teacherAccount.months.inAugust",
        "teacherAccount.months.inSeptember",
        "teacherAccount.months.inOctober",
        "teacherAccount.months.inNovember",
        "teacherAccount.months.inDecember",
    ];

    dayMapping = {
        Понедельник: "mo",
        Вторник: "tu",
        Среда: "we",
        Четверг: "th",
        Пятница: "fr",
        Суббота: "sa",
        Воскресенье: "su",
    };

    sexMapping = {
        "TeacherQuestionnaire.notSelected": "",
        "TeacherQuestionnaire.male": "male",
        "TeacherQuestionnaire.female": "female",
    };

    optionsLessonsPlace = [];

    loadingFetchConditionsBySubjectData = true;

    constructor() {
        makeAutoObservable(this);
    }

    blobToBase64(blob) {
        // eslint-disable-next-line no-undef
        return new Promise((resolve, _) => {
            const reader = new FileReader();

            reader.readAsDataURL(blob);
            reader.onloadend = () => resolve(reader.result);
        });
    }

    PrepareQuestionaryData() {
        let data = {
            body: {
                audience: Object.keys(this.teacher.studentTypes).filter((key) => this.teacher.studentTypes[key]),
                subjects: this.teacher.subjects,
                classFormats: this.teacher.classFormats,
                tutorsPlace: {
                    ...this.teacher.tutorsPlace,
                    city: this.teacher.city.title,
                    locations: [this.teacher.tutorsPlace.locations[0].LessonsPlace],
                },
                studentsPlaces: {
                    ...this.teacher.studentsPlaces,
                    city: this.teacher.city.title,
                    locations: this.teacher.studentsPlaces.locations,
                },
                educations: this.teacher.education.map((education) => {
                    return {
                        university: education.university,
                        faculty: education.faculty,
                        speciality: education.speciality,
                        startYear: education.beginYear,
                        studyNowadays: education.stillStudying,
                        finishYear: education.endYear,
                        confirmatoryDocuments: [
                            {
                                name: education.photo.imageName,
                                isPublic: true,
                                data: education.photo.imageSrc.split(",")[1],
                            },
                        ],
                    };
                }),
                experience: this.teacher.experience.map((experience) => {
                    return {
                        organization: experience.organization,
                        post: experience.position,
                        startYear: experience.beginYear,
                        workNowadays: experience.stillWorking,
                        finishYear: experience.endYear,
                    };
                }),
                certificates: this.teacher.certificates.map((certificate) => {
                    return {
                        certificateName: certificate.certificate,
                        yearOfGraduating: certificate.receiptYear,
                        confirmatoryDocuments: [
                            {
                                name: certificate.photo.imageName,
                                isPublic: certificate.showToStudents,
                                data: certificate.photo.imageSrc.split(",")[1],
                            },
                        ],
                    };
                }),
                latestTimeUntilClass: this.timeToClassesDict[this.teacher.timeToClasses],
                weekDaysTimetables: Object.keys(this.teacher.timings)
                    .map((day) => ({
                        weekDayName: this.dayMapping[day],
                        hours: this.teacher.timings[day],
                    }))
                    .filter((value) => value.hours.length),
                aboutMeText: this.teacher.about,
                videoCard: {
                    name: this.teacher.video.videoName,
                    isPublic: this.teacher.video.isPublic,
                    data: this.teacher.video.videoSrc.split(",")[1],
                },
                externalServicesProfiles: this.teacher.links.map((link) => {
                    return {
                        websiteName: link.siteName,
                        url: link.link,
                    };
                }),
            },
        };

        if (this.teacher.classFormats.includes("at_the_students")) {
            data["studentsPlaces"] = {
                ...this.teacher.studentsPlaces,
                city: this.teacher.city.title,
            };
        }

        return data;
    }

    getTimestampDate = (date) => {
        if (window.proto) {
            const proto = window.proto;

            const timeMS = date;

            const timestamp = new proto.google.protobuf.Timestamp();

            timestamp.setSeconds(timeMS / 1000);
            timestamp.setNanos((timeMS % 1000) * 1e6);
            return timestamp;
        }
    };

    PreparePersonalData() {
        let data = {
            name: this.teacher.firstName,
            surname: this.teacher.surname,
            patronymic: this.teacher.secondName,
            dateOfBirth: this.getTimestampDate(new Date(Date.UTC(this.teacher.birthday.year, this.monthList.indexOf(this.teacher.birthday.month), this.teacher.birthday.day))),
            timezoneUtcOffset: this.teacher.timeZone.text.substring(4, 10),
            city: this.teacher.city.title,
        };

        if (this.sexMapping[this.teacher.sex] !== "") {
            data["gender"] = this.sexMapping[this.teacher.sex];
        }

        return data;
    }

    SetLoadingState(field, value) {
        this[field] = value;
    }

    async SendTutorQuestionaryData() {
        let metaData = {
            authorization: "Bearer " + getCookie(`${UserStore.role}AccessToken`),
        };

        let data = this.PrepareQuestionaryData();

        this.SetLoadingState("loadingProfileData", true);

        await AccountManagerService.AddTutorQuestionary(data, metaData, async (error, response) => {
            if (error) {
                handleStoreActionError({
                    error,
                    customErrorText: "account.errors.fetchProfileDataError",
                    dispatch: true,
                });
            } else {
                this.SetLoadingState("loadingProfileData", false);
            }

            this.SetLoadingState("loadingProfileData", false);
        });
    }

    async loadPersonalData() {
        let data = null;

        let metaData = {
            authorization: "Bearer " + getCookie("tutorAccessToken"),
        };

        await AccountManagerService.GetPersonalData(data, metaData, async (error, response) => {
            if (error) {
                handleStoreActionError({
                    error,
                    customErrorText: "account.errors.fetchGetPersonalDataError",
                    dispatch: true,
                });
            } else {
                let data = response.toObject();

                this.setPhone(data.phone);
                this.setEmail(data.email);
                this.setFirstName(data.name);
                this.SetLoadingState("loadingProfileData", false);
            }

            this.SetLoadingState("loadingProfileData", false);
        });
    }

    async SendPersonalData() {
        let metaData = {
            authorization: "Bearer " + getCookie(`${UserStore.role}AccessToken`),
        };

        let data = this.PreparePersonalData();

        this.SetLoadingState("loadingProfileData", true);

        await AccountManagerService.UpdatePersonalData(data, metaData, async (error, response) => {
            if (error) {
                handleStoreActionError({
                    error,
                    customErrorText: "account.errors.fetchProfileDataError",
                    dispatch: true,
                });
            } else {
                this.SetLoadingState("loadingProfileData", false);
            }

            this.SetLoadingState("loadingProfileData", false);
        });
    }

    async SendProfilePhotoData() {
        let metaData = {
            authorization: "Bearer " + getCookie(`${UserStore.role}AccessToken`),
        };

        let data = {
            photoData: this.teacher.photo.imageSrc.split(",")[1],
        };

        this.SetLoadingState("loadingProfileData", true);

        await AccountManagerService.UpdateProfilePhotoData(data, metaData, async (error, response) => {
            if (error) {
                handleStoreActionError({
                    error,
                    customErrorText: "account.errors.fetchProfileDataError",
                    dispatch: true,
                });
            } else {
                this.SetLoadingState("loadingProfileData", false);
            }

            this.SetLoadingState("loadingProfileData", false);
        });
    }

    async FetchCitiesData() {
        await TypicalInfoService.GetCities(null, async (error, response) => {
            if (error) {
                handleStoreActionError({
                    error,
                    customErrorText: "account.errors.FetchCitiesDataError",
                    dispatch: true,
                });
            } else {
                let data = response.toObject().citiesList;

                this.FillCitiesList(data);
            }
        });
    }

    async FetchConditionsBySubjectData(subjectName) {
        let data = {
            subject: subjectName,
        };

        this.SetLoadingState("loadingFetchConditionsBySubjectData", true);

        await TypicalInfoService.GetConditionsBySubject(data, async (error, response) => {
            if (error) {
                handleStoreActionError({
                    error,
                    customErrorText: "account.errors.FetchConditionsBySubjectDataError",
                    dispatch: true,
                });
                this.SetLoadingState("loadingFetchConditionsBySubjectData", false);
            } else {
                let data = response.toObject().conditionsList;

                this.FillConditionsBySubjectObject(data, subjectName);
                this.SetLoadingState("loadingFetchConditionsBySubjectData", false);
            }
        });
    }

    async FetchDistrictsAndUndergroundStationsByCityData() {
        let data = {
            city: this.teacher.city?.title ? this.teacher.city?.title : "Москва",
        };

        await TypicalInfoService.GetDistrictsAndUndergroundStationsByCity(data, async (error, response) => {
            if (error) {
                handleStoreActionError({
                    error,
                    customErrorText: "account.errors.FetchDistrictsAndUndergroundStationsByCityDataError",
                    dispatch: true,
                });
            } else {
                let data = response.toObject().districtsList;

                let undergroundStations = response.toObject().undergroundStationsList;

                this.FillDistrictsAndUndergroundStationsByCityList(data, undergroundStations);
            }
        });
    }

    async FetchSubjectsData() {
        await TypicalInfoService.GetSubjects(null, async (error, response) => {
            if (error) {
                handleStoreActionError({
                    error,
                    customErrorText: "account.errors.FetchSubjectsDataError",
                    dispatch: true,
                });
            } else {
                let data = response.toObject().subjectsList;

                this.FillSubjectsList(data);
            }
        });
    }

    FillCitiesList(data) {
        this.citiesList = [];
        data.forEach((value, index) => {
            this.citiesList.push({
                ID: index,
                City: value,
            });
        });
    }

    FillConditionsBySubjectObject(data, subjectName) {
        const conditions = [];

        data.forEach((value, index) => {
            conditions.push({
                ID: index,
                Condition: value,
            });
        });
        this.conditionsBySubjectObject[subjectName] = conditions;
    }

    FillDistrictsAndUndergroundStationsByCityList(data, undergroundStations) {
        this.districtsAndUndergroundStationsByCityList = [];
        data.forEach((value, index) => {
            this.districtsAndUndergroundStationsByCityList.push({
                ID: index,
                District: value,
            });
        });
        undergroundStations.forEach((value, index) => {
            this.districtsAndUndergroundStationsByCityList.push({
                ID: index,
                District: "м. " + value,
            });
        });
    }

    FillSubjectsList(data) {
        this.subjectsList = [];
        data.forEach((value, index) => {
            this.subjectsList.push({
                ID: index,
                Subject: value,
            });
        });
    }

    setCity(value) {
        this.teacher.city = value;
    }

    setPhone(phone) {
        this.teacher.phone = phone;
    }

    setEmail(email) {
        this.teacher.email = email;
    }

    setFirstName(value) {
        this.teacher.firstName = value;
    }

    setTemporaryTimeToClasses(value) {
        this.temporaryTimeToClasses = value;
    }

    setCancelClassAttempts(value) {
        this.teacher.cancelClassAttempts = value;
    }

    getLastDayOfMonth = () => {
        const date = new Date();

        const year = date.getFullYear();

        const month = date.getMonth() + 1; // Месяцы в JS начинаются с 0

        const lastDay = new Date(year, month, 0); // Получаем последний день месяца

        const day = String(lastDay.getDate()).padStart(2, "0");

        const formattedMonth = String(lastDay.getMonth() + 1).padStart(2, "0");

        return `${day}.${formattedMonth}.${year}`;
    };

    getTutorPrivilegeLevel(value) {
        if (value < this.privilegeProgram[3].lowerBoundary) {
            return {
                level: 4,
                title: "teacherAccount.privilegeProgram.bronze",
                levelEnding: "teacherAccount.privilegeProgram.fourEnding",
                commission: this.privilegeProgram[4].commission,
                nextLevelBoundary: this.privilegeProgram[3].lowerBoundary,
                privileges: ["teacherAccount.privilegeProgram.fourthPriority", "teacherAccount.privilegeProgram.15commission", "teacherAccount.privilegeProgram.100pointsForClass"],
            };
        } else if (value >= this.privilegeProgram[3].lowerBoundary && value < this.privilegeProgram[2].lowerBoundary) {
            return {
                level: 3,
                title: "teacherAccount.privilegeProgram.silver",
                levelEnding: "teacherAccount.privilegeProgram.threeEnding",
                commission: this.privilegeProgram[3].commission,
                nextLevelBoundary: this.privilegeProgram[2].lowerBoundary,
                privileges: ["teacherAccount.privilegeProgram.thirdPriority", "teacherAccount.privilegeProgram.14commission", "teacherAccount.privilegeProgram.100pointsForClass"],
            };
        } else if (value >= this.privilegeProgram[2].lowerBoundary && value < this.privilegeProgram[1].lowerBoundary) {
            return {
                level: 2,
                title: "teacherAccount.privilegeProgram.gold",
                levelEnding: "teacherAccount.privilegeProgram.twoEnding",
                commission: this.privilegeProgram[2].commission,
                nextLevelBoundary: this.privilegeProgram[1].lowerBoundary,
                privileges: [
                    "teacherAccount.privilegeProgram.secondPriority",
                    "teacherAccount.privilegeProgram.13commission",
                    "teacherAccount.privilegeProgram.cashbackToStudent",
                    "teacherAccount.privilegeProgram.100pointsForClass",
                ],
            };
        } else if (value >= this.privilegeProgram[1].lowerBoundary) {
            return {
                level: 1,
                title: "teacherAccount.privilegeProgram.platinum",
                levelEnding: "teacherAccount.privilegeProgram.oneEnding",
                commission: this.privilegeProgram[1].commission,
                nextLevelBoundary: this.teacher.privilegeProgram.nextMonth,
                privileges: [
                    "teacherAccount.privilegeProgram.firstPriority",
                    "teacherAccount.privilegeProgram.12commission",
                    "teacherAccount.privilegeProgram.cashbackToStudent",
                    "teacherAccount.privilegeProgram.personalManager",
                ],
            };
        }
    }

    setEmailNotifications(key, value) {
        this.teacher.emailNotifications[key] = value;
    }

    setTelegramNotifications(key, value) {
        this.teacher.telegramNotifications[key] = value;
    }

    setTemporaryTimezone(value) {
        this.temporaryTimezone = value;
    }

    setTemporaryPlaces(key, value) {
        this.temporaryPlaces[key] = value;
    }

    setWholeTemporaryPlaces(value) {
        this.temporaryPlaces = value;
    }

    setStudentPlaces(value) {
        this.temporaryPlaces.studentPlaces = value;
    }

    addReadyToSubjects() {
        // Перебираем каждую ключ-пару в объекте subjects
        for (const [subjectName, subjectData] of Object.entries(this.temporarySubjects)) {
            // Добавляем свойство "ready" : true к внутреннему объекту
            this.temporarySubjects[subjectName] = {
                ...subjectData,
                ready: true,
                newAim: "",
                newSubject: "",
            };
        }
    }

    setTemporaryEducationData = (index, key, value, additionalKey = null) => {
        if (additionalKey === null) {
            this.temporaryEducation[index][key] = value;
        } else {
            this.temporaryEducation[index][key][additionalKey] = value;
        }
    };

    setTemporaryEducation = (value) => {
        this.temporaryEducation = value;
    };

    removeEducation = (index) => {
        this.temporaryEducation.splice(index, 1);
    };

    addEducation = (value) => {
        this.temporaryEducation.push(value);
    };

    setTemporaryExperienceData = (index, key, value, additionalKey = null) => {
        if (additionalKey === null) {
            this.temporaryExperience[index][key] = value;
        } else {
            this.temporaryExperience[index][key][additionalKey] = value;
        }
    };

    setTemporaryExperience = (value) => {
        this.temporaryExperience = value;
    };

    setMainTimezone = (value) => {
        this.teacher.timeZone = value;
    };

    removeExperience = (index) => {
        this.temporaryExperience.splice(index, 1);
    };

    addExperience = (value) => {
        this.temporaryExperience.push(value);
    };

    setTemporaryCertificatesData = (index, key, value, additionalKey = null) => {
        if (additionalKey === null) {
            this.temporaryCertificates[index][key] = value;
        } else {
            this.temporaryCertificates[index][key][additionalKey] = value;
        }
    };

    setTemporaryCertificates = (value) => {
        this.temporaryCertificates = value;
    };

    removeCertificates = (index) => {
        this.temporaryCertificates.splice(index, 1);
    };

    addCertificates = (value) => {
        this.temporaryCertificates.push(value);
    };

    setTemporaryVideoCard = (value) => {
        this.temporaryVideoCard = value;
    };

    setTemporaryAboutTeacher = (value) => {
        this.temporaryAboutTeacher = value;
    };

    setTutorData = (key, value) => {
        this.teacher[key] = value;
    };

    setTemporarySubjectsData = (value) => {
        this.temporarySubjects = value;
    };

    setSubjectData = (item, value, subject, price) => {
        if (item === "subject") {
            this.temporarySubjects[value] = { ready: false, newAim: "", newSubject: "" };
            delete this.temporarySubjects[""];
        } else if (item === "ready") {
            this.temporarySubjects[value]["ready"] = true;
        } else if (item === "aim") {
            this.temporarySubjects[subject][value] = "";
        } else if (item === "newAim") {
            this.temporarySubjects[subject]["newAim"] = value;
        } else if (item === "removeAim") {
            delete this.temporarySubjects[subject][value];
        } else if (item === "changePrice") {
            this.temporarySubjects[subject][value] = price;
        } else if (item === "removeSubject") {
            delete this.temporarySubjects[value];
        } else if (item === "addNewSubject") {
            this.temporarySubjects[""] = { ready: false, newAim: "", newSubject: "" };
        } else if (item === "newSubject") {
            this.temporarySubjects[subject]["newSubject"] = value;
        }
    };

    getClearSubjectsDict = () => {
        const newTemporarySubjects = JSON.parse(JSON.stringify(this.temporarySubjects));

        for (let subject in newTemporarySubjects) {
            // Use the Object.prototype.hasOwnProperty.call method
            if (Object.prototype.hasOwnProperty.call(newTemporarySubjects[subject], "ready")) {
                delete newTemporarySubjects[subject]["ready"];
            }

            if (Object.prototype.hasOwnProperty.call(newTemporarySubjects[subject], "newSubject")) {
                delete newTemporarySubjects[subject]["newSubject"];
            }

            if (Object.prototype.hasOwnProperty.call(newTemporarySubjects[subject], "newAim")) {
                delete newTemporarySubjects[subject]["newAim"];
            }
        }

        return newTemporarySubjects;
    };

    findMinimumValueInObject(nestedObj) {
        let minValue = Infinity; // Initialize minValue to a very large number

        // Iterate over each key in the nested object
        for (const key in nestedObj) {
            if (Object.prototype.hasOwnProperty.call(nestedObj, key)) {
                const value = nestedObj[key];

                // Convert the value to a number
                const numValue = Number(value);

                // Check if the value is a valid number and less than minValue
                if (!isNaN(numValue) && numValue < minValue) {
                    minValue = numValue;
                }
            }
        }

        // If no valid numeric values were found, return null
        return minValue === Infinity ? null : minValue;
    }

    setDefaultTemporaryPersonalData = () => {
        (this.temporaryPersonalData.firstName = this.teacher.firstName),
            (this.temporaryPersonalData.secondName = this.teacher.secondName),
            (this.temporaryPersonalData.surname = this.teacher.surname),
            (this.temporaryPersonalData.sex = this.teacher.sex),
            (this.temporaryPersonalData.birthDay = this.teacher.birthday.getDate()),
            (this.temporaryPersonalData.birthMonth = this.monthList[this.teacher.birthday.getMonth()]),
            (this.temporaryPersonalData.birthYear = this.teacher.birthday.getFullYear());
    };

    getDisabledSendRequest = () => {
        if (this.requestSent === false) {
            return (
                this.temporaryPersonalData.firstName === this.teacher.firstName &&
                this.temporaryPersonalData.secondName === this.teacher.secondName &&
                this.temporaryPersonalData.surname === this.teacher.surname &&
                this.temporaryPersonalData.sex === this.teacher.sex &&
                this.temporaryPersonalData.birthYear === this.teacher.birthday.getFullYear() &&
                this.monthList.indexOf(this.temporaryPersonalData.birthMonth) === this.teacher.birthday.getMonth() &&
                this.temporaryPersonalData.birthDay === this.teacher.birthday.getDate()
            );
        } else {
            return true;
        }
    };

    hasNestedObjectWithFewerThanFourKeys(obj) {
        // Iterate over each key in the object
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const nestedObj = obj[key];

                // Check if the current property is an object
                if (typeof nestedObj === "object" && nestedObj !== null) {
                    // Count the number of keys in the nested object
                    const keyCount = Object.keys(nestedObj).length;

                    // Check if the nested object has fewer than three keys
                    if (keyCount < 4) {
                        return true;
                    }
                }
            }
        }

        // If no nested object with fewer than three keys was found, return false
        return false;
    }

    hasEmptyValueExcludingReady(obj) {
        // Iterate over each key in the main object
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const nestedObj = obj[key];

                // Ensure the property is an object
                if (typeof nestedObj === "object" && nestedObj !== null) {
                    // Iterate over the keys of the nested object
                    for (const nestedKey in nestedObj) {
                        if (Object.prototype.hasOwnProperty.call(nestedObj, nestedKey)) {
                            const value = nestedObj[nestedKey];

                            // Check if the key is not "ready" and the value is empty
                            if (nestedKey !== "ready" && nestedKey !== "newSubject" && nestedKey !== "newAim" && value === "") {
                                return true;
                            }
                        }
                    }
                }
            }
        }

        // If no empty values were found, return false
        return false;
    }

    getDisabledSubjectPrices = () => {
        const areObjectsEqual = (obj1, obj2) => {
            const keys1 = Object.keys(obj1);

            const keys2 = Object.keys(obj2);

            if (keys1.length !== keys2.length) {
                return false;
            }

            for (let key of keys1) {
                const val1 = obj1[key];

                const val2 = obj2[key];

                const areObjects = val1 !== null && typeof val1 === "object" && val2 !== null && typeof val2 === "object";

                if ((areObjects && !areObjectsEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
                    return false;
                }
            }

            return true;
        };

        const clearSubjectsDict = this.getClearSubjectsDict();

        if (areObjectsEqual(clearSubjectsDict, this.teacher.subjects)) {
            return true;
        } else if (this.hasNestedObjectWithFewerThanFourKeys(this.temporarySubjects)) {
            return true;
        } else return this.hasEmptyValueExcludingReady(this.temporarySubjects);
    };

    areArraysEqual = (array1, array2) => {
        if (array1.length !== array2.length) {
            return false;
        }

        const areObjectsEqual = (obj1, obj2) => {
            const keys1 = Object.keys(obj1);

            const keys2 = Object.keys(obj2);

            if (keys1.length !== keys2.length) {
                return false;
            }

            for (let key of keys1) {
                const val1 = obj1[key];

                const val2 = obj2[key];

                const areObjects = val1 !== null && typeof val1 === "object" && val2 !== null && typeof val2 === "object";

                if ((areObjects && !areObjectsEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
                    return false;
                }
            }

            return true;
        };

        for (let i = 0; i < array1.length; i++) {
            if (!areObjectsEqual(array1[i], array2[i])) {
                return false;
            }
        }

        return true;
    };

    getDisabledSaveEducation = () => {
        return (
            this.areArraysEqual(this.temporaryEducation, this.teacher.education) ||
            this.temporaryEducation.some(
                (item) =>
                    !item.university ||
                    !item.faculty ||
                    !item.speciality ||
                    item.beginYear === "Год" ||
                    (!item.stillStudying && (item.endYear === "Год" || item.endYear === "")) ||
                    item.beginYear > item.endYear,
            )
        );
    };

    getDisabledSaveExperience = () => {
        return (
            this.areArraysEqual(this.temporaryExperience, this.teacher.experience) ||
            this.temporaryExperience.some(
                (item) => !item.organization || !item.position || item.beginYear === "Год" || (!item.stillWorking && (item.endYear === "Год" || item.endYear === "")) || item.beginYear > item.endYear,
            )
        );
    };

    getDisabledSaveTimezone = () => {
        return this.teacher.timeZone.text === this.temporaryTimezone.text;
    };

    getDisabledSaveCertificates = () => {
        return (
            this.areArraysEqual(this.temporaryCertificates, this.teacher.certificates) ||
            this.temporaryCertificates.some((item) => !item.certificate || item.receiptYear === "Год" || !item.photo.imageSrc || item.photo.imageSrc === null)
        );
    };

    getDisabledSaveLinks = () => {
        return this.areArraysEqual(this.temporaryLinks, this.teacher.links) || this.temporaryLinks.length === 0;
    };

    getDisabledSavePlaces = () => {
        const areObjectsEqual = (obj1, obj2) => {
            const keys1 = Object.keys(obj1);

            const keys2 = Object.keys(obj2);

            if (keys1.length !== keys2.length) {
                return false;
            }

            for (let key of keys1) {
                const val1 = obj1[key];

                const val2 = obj2[key];

                const areObjects = val1 !== null && typeof val1 === "object" && val2 !== null && typeof val2 === "object";

                if ((areObjects && !areObjectsEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
                    return false;
                }
            }

            return true;
        };

        return (
            areObjectsEqual(this.temporaryPlaces, this.teacher.studentsPlaces) ||
            (this.temporaryPlaces.student === true && this.temporaryPlaces.studentPlaces.length === 0) ||
            (this.temporaryPlaces.teacher === true && (this.temporaryPlaces.tutorsPlace === "" || this.temporaryPlaces.tutorsPlace === null || this.temporaryPlaces.tutorsPlace === undefined)) ||
            (this.temporaryPlaces.online === false && this.temporaryPlaces.student === false && this.temporaryPlaces.teacher === false)
        );
    };

    sendRequest = (value) => {
        this.requestSent = value;
    };

    setTemporaryPersonalData = (key, value) => {
        this.temporaryPersonalData[key] = value;
    };

    setOpenCropDialog = (value) => {
        this.openCropDialog = value;
    };

    setTemporaryCroppedMiniature = (value) => {
        this.temporaryCroppedMiniature = value;
    };

    setTemporaryCroppedPhoto = (value) => {
        this.temporaryCroppedPhoto = value;
    };

    setCroppingStep = (value) => {
        this.croppingStep = value;
    };

    setInitialInputPhoto = (value) => {
        this.initialInputPhoto = value;
    };

    setPhoto = (value) => {
        this.teacher.photo.imageSrc = value;
    };

    setMiniature = (value) => {
        this.teacher.miniature.imageSrc = value;
    };

    setVideo = async (value) => {
        if (value) {
            this.teacher.videoUrl = URL.createObjectURL(value);
            this.teacher.video.videoName = value.name;
            this.teacher.video.videoSize = value.size;
            this.teacher.video.videoSrc = await this.blobToBase64(value);
        } else {
            this.teacher.videoUrl = null;
            this.teacher.video.videoName = "";
            this.teacher.video.videoSize = "";
            this.teacher.video.videoSrc = "";
        }
    };

    deleteLink = (value) => {
        this.temporaryLinks.splice(value, 1);
    };

    addLink = (value) => {
        this.temporaryLinks.push(value);
    };

    setTemporarySiteName = (value) => {
        this.temporarySiteName = value;
    };

    setTemporarySiteLink = (value) => {
        this.temporarySiteLink = value;
    };

    setTemporaryLinks = (value) => {
        this.temporaryLinks = value;
    };

    setStudentTypes = (key, value) => {
        this.teacher.studentTypes[key] = value;
    };

    setBirthdayData(field, value) {
        this.teacher.birthday[field] = value;
    }

    setTeacherData = (key, value) => {
        if (key === "BirthDay") {
            this.teacher["birthday"] = new Date(this.teacher["birthday"].getFullYear(), this.teacher["birthday"].getMonth(), value === "День" ? 0 : value);
        } else if (key === "BirthMonth") {
            this.teacher["birthday"] = new Date(this.teacher["birthday"].getFullYear(), this.monthList.indexOf(value), this.teacher["birthday"].getDate());
        } else if (key === "BirthYear") {
            this.teacher["birthday"] = new Date(value === "Год" ? 0 : value, this.teacher["birthday"].getMonth(), this.teacher["birthday"].getDate());
        } else this.teacher[key] = value;
    };

    syncTempTimings = (weekday) => {
        this.teacher.timingsTemp[weekday] = [...this.teacher.timings[weekday]];
    };

    saveTimings = (weekday) => {
        this.teacher.timings[weekday] = [...this.teacher.timingsTemp[weekday]];
    };

    setAnonymousProfile = (value) => {
        this.teacher.anonymousProfile = value;
    };

    setPlaces = (value) => {
        if (this.teacher.classFormats.includes(value)) {
            this.teacher.classFormats.splice(this.teacher.classFormats.indexOf(value), 1);
        } else {
            this.teacher.classFormats.push(value);
        }
    };

    setMainStudentPlaces(value) {
        this.teacher.studentsPlaces.locations = value;
    }

    setMainTeacherPlace(value) {
        this.teacher.tutorsPlace.locations[0] = value;
    }

    setVerifiedProfile(value) {
        this.teacher.verifiedProfile = value;
    }

    setTutorSubjects(value) {
        this.teacher.subjects = value;
    }

    setTutorAims(subject, value) {
        this.teacher.subjects.find((someSubject) => someSubject.subjectName === subject).conditions = value;
    }

    setTutorPrice(subject, aim, value) {
        this.teacher.subjects.find((someSubject) => someSubject.subjectName === subject).conditions.find((someAim) => someAim.description === aim).price = value;
    }

    SetOptionsLessonsPlace(locations) {
        this.optionsLessonsPlace = locations;
    }

    changeStep(step, value) {
        this.steps[step] = value;
    }
}

export default new TeacherQStore();
