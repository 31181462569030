import { styleProcessing } from "API/plugins/Utilities";

export const AddLoginDetailTextField = (condition) => {
    return {
        width: "100%",
        "& .MuiInput-underline:before": {
            borderBottom: condition ? "1px solid #E12626" : "1px solid #BBBBC8",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: condition ? "#E12626" : "#6212FF",
        },
        "& .MuiInputBase-input": {
            paddingBottom: "10px",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "16px",
            lineHeight: "150%",
            fontWeight: 400,
            textTransform: "none",
        },
    };
};

export const LabelField = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "0 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TextWrapper = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
};

export const AddForm = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100vw",
            maxWidth: "100vw",
            minWidth: "370px",
            gap: "20px",
            height: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ButtonsContainer = (theme) => {
    let commonStyle = {
        display: "flex",
        justifyContent: "center",
        gap: "16px",
        marginBottom: "30px",
        marginTop: "10px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            gap: "14px",
            marginBottom: "26px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ButtonsDrawerContainer = (theme) => {
    let commonStyle = {
        display: "flex",
        justifyContent: "center",
        gap: "16px",
        marginBottom: "30px",
        marginTop: "10px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "14px",
            marginTop: "auto",
            padding: "0 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ButtonSize = (theme) => {
    let commonStyle = {
        width: "136px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "288px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const SMSsent = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "0 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const PasswordHelper = (condition) => {
    return {
        fontSize: "13px",
        fontWeight: "400",
        lineHeight: "140%",
        color: condition ? "#E12626" : "#19B20C",
    };
};

export const ModalIllustration = {
    textAlign: "center",
    marginBottom: "15px",
};

export const ModalTitle = (theme) => {
    let commonStyle = {
        textAlign: "center",
        marginBottom: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            maxWidth: "280px",
            marginBottom: "10px",
            marginTop: "10px",
            fontSize: "18px",
            lineHeight: "27px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ModalText = {
    textAlign: "center",
    marginBottom: "30px",
};

export const ModalButton = {
    display: "flex",
    justifyContent: "center",
    marginBottom: "30px",
};

export const ModalTitlePhoneChange = (theme) => {
    let commonStyle = {
        textAlign: "center",
        marginBottom: "20px",
        marginTop: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            maxWidth: "280px",
            marginBottom: "10px",
            marginTop: "10px",
            fontSize: "18px",
            lineHeight: "27px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ModalTextPhoneChange = {
    textAlign: "center",
    marginBottom: "40px",
};

export const ModalDeletePhoto = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
};
