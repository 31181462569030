import React from "react";
import * as themes from "./NumZeroThemes";
import CircleSample from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumbersCommonComponents/CircleSample/CircleSample";
import { Box } from "@mui/material";
import { imageCircleSources } from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumbersCommonComponents/NumbersAssetsIndex";
import NumberPositionBox from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumbersCommonComponents/NumberPositionBox/NumberPositionBox";
import { getRandomImageSource } from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumbersCommonComponents/imageUtils";
import { memo } from "react";

const NumZero = ({ children, ...props }) => {
    const positions = [
        { left: 20, top: 249, component: <CircleSample /> },
        { left: 184, top: 67, component: <CircleSample /> },
        { left: 102, top: 0, width: "20px", height: "10px" },
        { left: 20, top: 67, width: "60px", height: "60px" },
        { left: 0, top: 158, width: "60px", height: "60px" },
        { left: 102, top: 302, width: "60px", height: "60px" },
        { left: 184, top: 249, width: "60px", height: "60px" },
        { left: 204, top: 158, width: "60px", height: "60px" },
    ];

    return (
        <Box sx={themes.BlockStyle}>
            {positions.map((position, index) => (
                <NumberPositionBox
                    key={index}
                    position={position}
                >
                    {index < 2 ? (
                        <CircleSample />
                    ) : (
                        <img
                            src={getRandomImageSource(imageCircleSources)}
                            alt="Random Icon"
                        />
                    )}
                </NumberPositionBox>
            ))}
        </Box>
    );
};

export default memo(NumZero);
