export const CardTitle = {
    color: "#222",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%" /* 27px */,
    marginTop: "16px",
    marginLeft: "16px",
};

export const CardDescribe = {
    color: "#222",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "130%" /* 27px */,
    marginTop: "5px",
    marginLeft: "16px",
};

export const Quote = {
    width: "145px",
    color: "#222",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "130%" /* 27px */,
    position: "absolute",
    bottom: 16, // Прижимаем к нижней части бокса
    marginLeft: "16px",
};
