import orderResponseStore from "store/OrderResponseStore";
import SButton from "components/UI/SButton";
import "./CancelModals.css";
import filterStore from "store/FilterStore";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";

const AreYouSureStep = observer(({ setDialogNumber }) => {
    const handleCancel = () => {
        setDialogNumber(2);
    };

    const handleEdit = () => {
        orderResponseStore.setToggleCancelOrderModal(false);
        filterStore.setToggleDrawer();
    };

    return (
        <Box className="CancelModalContent">
            <Box className="CancelModalTitle">
                Вы уверены, что хотите
                <br />
                отменить заказ?
            </Box>
            <Box className="CancelModalMainText">
                Перед тем как отменить заказ, вы можете
                <br />
                попробовать отредактировать его и изменить
                <br />
                нужные вам параметры
            </Box>
            <Box className="CancelModalButtonGroup">
                <SButton
                    onClick={handleEdit}
                    padding="10px 20px 11px 20px"
                    variant="secondary"
                >
                    Редактировать
                </SButton>
                <SButton
                    onClick={handleCancel}
                    padding="10px 20px 11px 20px"
                    variant="red"
                >
                    Отменить заказ
                </SButton>
            </Box>
        </Box>
    );
});

export default AreYouSureStep;
