export const Table = {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
    textAlign: "left",
    borderCollapse: "collapse",
    maxWidth: "804px",
    width: "100%",
};

export const HeaderRow = {
    color: "#67677A",
    borderBottom: "1px solid #9E9EB0",
};

export const HeaderTh = {
    padding: "12px 0 12px 0",
};

export const HeaderThSum = {
    padding: "12px 0 12px 30px",
};

export const HeaderThPoints = {
    padding: "12px 0 12px 10px",
};

export const FirstRow = {
    color: "#222",
};

export const FirstRowTdDate = {
    width: "105px",
    padding: "12px 0 0 0",
};

export const FirstRowTdService = {
    maxWidth: "356px",
    padding: "12px 0 0 0",
};

export const FirstRowTdAmount = {
    width: "85px",
    padding: "12px 0 0 30px",
};

export const FirstRowTdPoints = (condition) => {
    return {
        width: "66px",
        padding: "12px 0 0 10px",
        verticalAlign: "top",
        color: condition ? "#19B20C" : "#222",
        borderBottom: "1px solid #DADADF",
    };
};

export const FirstRowTdNote = {
    width: "140px",
    padding: "12px 0 0 10px",
    verticalAlign: "top",
    borderBottom: "1px solid #DADADF",
};

export const SecondRow = {
    color: "#9E9EB0",
};

export const SecondRowTd = {
    padding: "0 0 12px 0",
    borderBottom: "1px solid #DADADF",
    verticalAlign: "top",
    maxWidth: "356px",
};

export const SecondRowTdDiscount = {
    padding: "0 0 12px 30px",
    borderBottom: "1px solid #DADADF",
    verticalAlign: "top",
    maxWidth: "356px",
    textDecoration: "line-through",
};
