import React, { memo } from "react";
import * as themes from "./InfoAreaThemes";
import CardText from "./CardText";
import CardActions from "./CardActions";
import Box from "@mui/material/Box";

const InfoArea = ({ children, ...props }) => {
    return (
        <Box sx={themes.tcInfoAreaStyle}>
            <CardText value={props.value}></CardText>
            <CardActions value={props.value}></CardActions>
        </Box>
    );
};

export default memo(InfoArea);
