export const labelStyle = {
    color: "var(--text-grey, #9E9EB0)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    alignSelf: "stretch",
};

export const infoAreaStyle = {
    display: "flex",
    marginTop: 12,
    padding: "16px",
    flexDirection: "column",
    borderRadius: "16px",
    background: "#F5F5F7",
};

export const infoContentStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "12px",
    alignSelf: "stretch",
};

export const infoStyle = {
    color: "var(--text-black, #222)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const attachmentsContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "14px",
};

export const fileContainerStyle = {
    display: "flex",
    alignItems: "flex-start",
    gap: "10px",
    alignSelf: "stretch",
};

export const fileInfoContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "3px",
    flex: "1 0 0",
};

export const fileNameStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "135%",
};

export const fileActionsContainerStyle = {
    display: "flex",
    alignItems: "center",
    gap: "6px",
};

export const fileSizeStyle = {
    color: "var(--text-grey, #9E9EB0)",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
};

export const fileDownloadStyle = {
    color: "#6212FF",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
    cursor: "pointer",
    textDecoration: "unset",
};
