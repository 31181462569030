import { styleProcessing } from "API/plugins/Utilities";

export const topBlock = (theme) => {
    let commonStyle = {
        display: "inline-flex",
        width: 1216,
        background: "#F7F7F7",
        marginTop: 80,
        paddingBottom: 80,
        gap: 40,
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: 328,
            flexDirection: "column-reverse",
            gap: 0,
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
