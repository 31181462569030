const Home = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.3954 10.7905C12.7407 10.092 12.917 9.47088 12.917 8.95964C12.917 7.66283 12.5354 6.93416 12.0926 6.51997C11.6337 6.09062 10.933 5.83464 10.0003 5.83464C9.06763 5.83464 8.36695 6.09062 7.90803 6.51997C7.4653 6.93416 7.08366 7.66283 7.08366 8.95964C7.08366 9.47088 7.25993 10.092 7.60525 10.7905C7.94547 11.4787 8.41231 12.1671 8.90121 12.7915C9.28603 13.283 9.67283 13.7201 10.0003 14.0692C10.3278 13.7201 10.7146 13.283 11.0994 12.7915C11.5883 12.1671 12.0552 11.4787 12.3954 10.7905ZM10.0003 10.0013C10.8057 10.0013 11.4587 9.34838 11.4587 8.54297C11.4587 7.73755 10.8057 7.08464 10.0003 7.08464C9.19491 7.08464 8.54199 7.73755 8.54199 8.54297C8.54199 9.34838 9.19491 10.0013 10.0003 10.0013Z"
                fill="#BBBBC8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.3337 10.0013C18.3337 14.6037 14.6027 18.3346 10.0003 18.3346C5.39795 18.3346 1.66699 14.6037 1.66699 10.0013C1.66699 5.39893 5.39795 1.66797 10.0003 1.66797C14.6027 1.66797 18.3337 5.39893 18.3337 10.0013ZM10.0003 15.8346C10.0003 15.8346 14.167 12.0662 14.167 8.95964C14.167 5.85303 12.3015 4.58464 10.0003 4.58464C7.69914 4.58464 5.83366 5.85303 5.83366 8.95964C5.83366 12.0662 10.0003 15.8346 10.0003 15.8346Z"
                fill="#BBBBC8"
            />
        </svg>
    );
};

export default Home;
