export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const WhiteContainer = {
    padding: "20px 20px",
    width: "249px",
    height: "99px",
    borderRadius: "20px",
    backgroundColor: "white",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.05)",
    cursor: "pointer",
    "&:hover": {
        transform: "scale(1.02)",
        transition: "all 0.2s ease-out",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
};
