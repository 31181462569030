import React, { memo } from "react";
import * as themes from "./TeacherQuestionnaireBodyBlockThemes";
import Box from "@mui/material/Box";
import TeacherStepper from "components/TeacherQuestionnaireBodyBlock/Stepper/TeacherStepper";

const TeacherQuestionnaireBodyBlock = ({ children, ...props }) => {
    return (
        <Box sx={themes.TeacherQuestionnaireBodyBlock}>
            <TeacherStepper />
        </Box>
    );
};

export default memo(TeacherQuestionnaireBodyBlock);
