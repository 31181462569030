/**
 * @fileoverview gRPC-Web generated client stub for pb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.6.1
// source: account-manager.proto

const grpc = {};

grpc.web = require("grpc-web");

var google_protobuf_timestamp_pb = require("google-protobuf/google/protobuf/timestamp_pb.js");

const proto = {};

proto.pb = require("./account-manager_pb.js");

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.AccountManagerServiceClient = function (hostname, credentials, options) {
    if (!options) options = {};

    options.format = "text";

    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */
    this.client_ = new grpc.web.GrpcWebClientBase(options);

    /**
     * @private @const {string} The hostname
     */
    this.hostname_ = hostname.replace(/\/+$/, "");
};

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.AccountManagerServicePromiseClient = function (hostname, credentials, options) {
    if (!options) options = {};

    options.format = "text";

    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */
    this.client_ = new grpc.web.GrpcWebClientBase(options);

    /**
     * @private @const {string} The hostname
     */
    this.hostname_ = hostname.replace(/\/+$/, "");
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.GetPersonalDataRequest,
 *   !proto.pb.GetPersonalDataResponse>}
 */
const methodDescriptor_AccountManagerService_GetPersonalData = new grpc.web.MethodDescriptor(
    "/pb.AccountManagerService/GetPersonalData",
    grpc.web.MethodType.UNARY,
    proto.pb.GetPersonalDataRequest,
    proto.pb.GetPersonalDataResponse,
    /**
     * @param {!proto.pb.GetPersonalDataRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.GetPersonalDataResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.GetPersonalDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.GetPersonalDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.GetPersonalDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AccountManagerServiceClient.prototype.getPersonalData = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.AccountManagerService/GetPersonalData", request, metadata || {}, methodDescriptor_AccountManagerService_GetPersonalData, callback);
};

/**
 * @param {!proto.pb.GetPersonalDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.GetPersonalDataResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AccountManagerServicePromiseClient.prototype.getPersonalData = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AccountManagerService/GetPersonalData", request, metadata || {}, methodDescriptor_AccountManagerService_GetPersonalData);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.UpdatePersonalDataRequest,
 *   !proto.pb.UpdatePersonalDataResponse>}
 */
const methodDescriptor_AccountManagerService_UpdatePersonalData = new grpc.web.MethodDescriptor(
    "/pb.AccountManagerService/UpdatePersonalData",
    grpc.web.MethodType.UNARY,
    proto.pb.UpdatePersonalDataRequest,
    proto.pb.UpdatePersonalDataResponse,
    /**
     * @param {!proto.pb.UpdatePersonalDataRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.UpdatePersonalDataResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.UpdatePersonalDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.UpdatePersonalDataResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.UpdatePersonalDataResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AccountManagerServiceClient.prototype.updatePersonalData = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.AccountManagerService/UpdatePersonalData", request, metadata || {}, methodDescriptor_AccountManagerService_UpdatePersonalData, callback);
};

/**
 * @param {!proto.pb.UpdatePersonalDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.UpdatePersonalDataResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AccountManagerServicePromiseClient.prototype.updatePersonalData = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AccountManagerService/UpdatePersonalData", request, metadata || {}, methodDescriptor_AccountManagerService_UpdatePersonalData);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.UpdateProfilePhotoRequest,
 *   !proto.pb.UpdateProfilePhotoResponse>}
 */
const methodDescriptor_AccountManagerService_UpdateProfilePhoto = new grpc.web.MethodDescriptor(
    "/pb.AccountManagerService/UpdateProfilePhoto",
    grpc.web.MethodType.UNARY,
    proto.pb.UpdateProfilePhotoRequest,
    proto.pb.UpdateProfilePhotoResponse,
    /**
     * @param {!proto.pb.UpdateProfilePhotoRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.UpdateProfilePhotoResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.UpdateProfilePhotoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.UpdateProfilePhotoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.UpdateProfilePhotoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AccountManagerServiceClient.prototype.updateProfilePhoto = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.AccountManagerService/UpdateProfilePhoto", request, metadata || {}, methodDescriptor_AccountManagerService_UpdateProfilePhoto, callback);
};

/**
 * @param {!proto.pb.UpdateProfilePhotoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.UpdateProfilePhotoResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AccountManagerServicePromiseClient.prototype.updateProfilePhoto = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AccountManagerService/UpdateProfilePhoto", request, metadata || {}, methodDescriptor_AccountManagerService_UpdateProfilePhoto);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.DeleteProfilePhotoRequest,
 *   !proto.pb.DeleteProfilePhotoResponse>}
 */
const methodDescriptor_AccountManagerService_DeleteProfilePhoto = new grpc.web.MethodDescriptor(
    "/pb.AccountManagerService/DeleteProfilePhoto",
    grpc.web.MethodType.UNARY,
    proto.pb.DeleteProfilePhotoRequest,
    proto.pb.DeleteProfilePhotoResponse,
    /**
     * @param {!proto.pb.DeleteProfilePhotoRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.DeleteProfilePhotoResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.DeleteProfilePhotoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.DeleteProfilePhotoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.DeleteProfilePhotoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AccountManagerServiceClient.prototype.deleteProfilePhoto = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.AccountManagerService/DeleteProfilePhoto", request, metadata || {}, methodDescriptor_AccountManagerService_DeleteProfilePhoto, callback);
};

/**
 * @param {!proto.pb.DeleteProfilePhotoRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.DeleteProfilePhotoResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AccountManagerServicePromiseClient.prototype.deleteProfilePhoto = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AccountManagerService/DeleteProfilePhoto", request, metadata || {}, methodDescriptor_AccountManagerService_DeleteProfilePhoto);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.GetPersonalDataForAuthRequest,
 *   !proto.pb.GetPersonalDataForAuthResponse>}
 */
const methodDescriptor_AccountManagerService_GetPersonalDataForAuth = new grpc.web.MethodDescriptor(
    "/pb.AccountManagerService/GetPersonalDataForAuth",
    grpc.web.MethodType.UNARY,
    proto.pb.GetPersonalDataForAuthRequest,
    proto.pb.GetPersonalDataForAuthResponse,
    /**
     * @param {!proto.pb.GetPersonalDataForAuthRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.GetPersonalDataForAuthResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.GetPersonalDataForAuthRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.GetPersonalDataForAuthResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.GetPersonalDataForAuthResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AccountManagerServiceClient.prototype.getPersonalDataForAuth = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.AccountManagerService/GetPersonalDataForAuth", request, metadata || {}, methodDescriptor_AccountManagerService_GetPersonalDataForAuth, callback);
};

/**
 * @param {!proto.pb.GetPersonalDataForAuthRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.GetPersonalDataForAuthResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AccountManagerServicePromiseClient.prototype.getPersonalDataForAuth = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AccountManagerService/GetPersonalDataForAuth", request, metadata || {}, methodDescriptor_AccountManagerService_GetPersonalDataForAuth);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.AddPersonalDataForAuthRequest,
 *   !proto.pb.AddPersonalDataForAuthResponse>}
 */
const methodDescriptor_AccountManagerService_AddPersonalDataForAuth = new grpc.web.MethodDescriptor(
    "/pb.AccountManagerService/AddPersonalDataForAuth",
    grpc.web.MethodType.UNARY,
    proto.pb.AddPersonalDataForAuthRequest,
    proto.pb.AddPersonalDataForAuthResponse,
    /**
     * @param {!proto.pb.AddPersonalDataForAuthRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.AddPersonalDataForAuthResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.AddPersonalDataForAuthRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.AddPersonalDataForAuthResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.AddPersonalDataForAuthResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AccountManagerServiceClient.prototype.addPersonalDataForAuth = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.AccountManagerService/AddPersonalDataForAuth", request, metadata || {}, methodDescriptor_AccountManagerService_AddPersonalDataForAuth, callback);
};

/**
 * @param {!proto.pb.AddPersonalDataForAuthRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.AddPersonalDataForAuthResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AccountManagerServicePromiseClient.prototype.addPersonalDataForAuth = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AccountManagerService/AddPersonalDataForAuth", request, metadata || {}, methodDescriptor_AccountManagerService_AddPersonalDataForAuth);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.SetLastLogonTimeForAuthRequest,
 *   !proto.pb.SetLastLogonTimeForAuthResponse>}
 */
const methodDescriptor_AccountManagerService_SetLastLogonTimeForAuth = new grpc.web.MethodDescriptor(
    "/pb.AccountManagerService/SetLastLogonTimeForAuth",
    grpc.web.MethodType.UNARY,
    proto.pb.SetLastLogonTimeForAuthRequest,
    proto.pb.SetLastLogonTimeForAuthResponse,
    /**
     * @param {!proto.pb.SetLastLogonTimeForAuthRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.SetLastLogonTimeForAuthResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.SetLastLogonTimeForAuthRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.SetLastLogonTimeForAuthResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.SetLastLogonTimeForAuthResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AccountManagerServiceClient.prototype.setLastLogonTimeForAuth = function (request, metadata, callback) {
    return this.client_.rpcCall(
        this.hostname_ + "/pb.AccountManagerService/SetLastLogonTimeForAuth",
        request,
        metadata || {},
        methodDescriptor_AccountManagerService_SetLastLogonTimeForAuth,
        callback,
    );
};

/**
 * @param {!proto.pb.SetLastLogonTimeForAuthRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.SetLastLogonTimeForAuthResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AccountManagerServicePromiseClient.prototype.setLastLogonTimeForAuth = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AccountManagerService/SetLastLogonTimeForAuth", request, metadata || {}, methodDescriptor_AccountManagerService_SetLastLogonTimeForAuth);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.GetTutorQuestionaryRequest,
 *   !proto.pb.GetTutorQuestionaryResponse>}
 */
const methodDescriptor_AccountManagerService_GetTutorQuestionary = new grpc.web.MethodDescriptor(
    "/pb.AccountManagerService/GetTutorQuestionary",
    grpc.web.MethodType.UNARY,
    proto.pb.GetTutorQuestionaryRequest,
    proto.pb.GetTutorQuestionaryResponse,
    /**
     * @param {!proto.pb.GetTutorQuestionaryRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.GetTutorQuestionaryResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.GetTutorQuestionaryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.GetTutorQuestionaryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.GetTutorQuestionaryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AccountManagerServiceClient.prototype.getTutorQuestionary = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.AccountManagerService/GetTutorQuestionary", request, metadata || {}, methodDescriptor_AccountManagerService_GetTutorQuestionary, callback);
};

/**
 * @param {!proto.pb.GetTutorQuestionaryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.GetTutorQuestionaryResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AccountManagerServicePromiseClient.prototype.getTutorQuestionary = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AccountManagerService/GetTutorQuestionary", request, metadata || {}, methodDescriptor_AccountManagerService_GetTutorQuestionary);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.AddTutorQuestionaryRequest,
 *   !proto.pb.AddTutorQuestionaryResponse>}
 */
const methodDescriptor_AccountManagerService_AddTutorQuestionary = new grpc.web.MethodDescriptor(
    "/pb.AccountManagerService/AddTutorQuestionary",
    grpc.web.MethodType.UNARY,
    proto.pb.AddTutorQuestionaryRequest,
    proto.pb.AddTutorQuestionaryResponse,
    /**
     * @param {!proto.pb.AddTutorQuestionaryRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.AddTutorQuestionaryResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.AddTutorQuestionaryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.AddTutorQuestionaryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.AddTutorQuestionaryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AccountManagerServiceClient.prototype.addTutorQuestionary = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.AccountManagerService/AddTutorQuestionary", request, metadata || {}, methodDescriptor_AccountManagerService_AddTutorQuestionary, callback);
};

/**
 * @param {!proto.pb.AddTutorQuestionaryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.AddTutorQuestionaryResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AccountManagerServicePromiseClient.prototype.addTutorQuestionary = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AccountManagerService/AddTutorQuestionary", request, metadata || {}, methodDescriptor_AccountManagerService_AddTutorQuestionary);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.DeleteTutorQuestionaryRequest,
 *   !proto.pb.DeleteTutorQuestionaryResponse>}
 */
const methodDescriptor_AccountManagerService_DeleteTutorQuestionary = new grpc.web.MethodDescriptor(
    "/pb.AccountManagerService/DeleteTutorQuestionary",
    grpc.web.MethodType.UNARY,
    proto.pb.DeleteTutorQuestionaryRequest,
    proto.pb.DeleteTutorQuestionaryResponse,
    /**
     * @param {!proto.pb.DeleteTutorQuestionaryRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.DeleteTutorQuestionaryResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.DeleteTutorQuestionaryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.DeleteTutorQuestionaryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.DeleteTutorQuestionaryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AccountManagerServiceClient.prototype.deleteTutorQuestionary = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.AccountManagerService/DeleteTutorQuestionary", request, metadata || {}, methodDescriptor_AccountManagerService_DeleteTutorQuestionary, callback);
};

/**
 * @param {!proto.pb.DeleteTutorQuestionaryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.DeleteTutorQuestionaryResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AccountManagerServicePromiseClient.prototype.deleteTutorQuestionary = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AccountManagerService/DeleteTutorQuestionary", request, metadata || {}, methodDescriptor_AccountManagerService_DeleteTutorQuestionary);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.GetTutorInfoForCatalogExtendedCardRequest,
 *   !proto.pb.GetTutorInfoForCatalogExtendedCardResponse>}
 */
const methodDescriptor_AccountManagerService_GetTutorInfoForCatalogExtendedCard = new grpc.web.MethodDescriptor(
    "/pb.AccountManagerService/GetTutorInfoForCatalogExtendedCard",
    grpc.web.MethodType.UNARY,
    proto.pb.GetTutorInfoForCatalogExtendedCardRequest,
    proto.pb.GetTutorInfoForCatalogExtendedCardResponse,
    /**
     * @param {!proto.pb.GetTutorInfoForCatalogExtendedCardRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.GetTutorInfoForCatalogExtendedCardResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.GetTutorInfoForCatalogExtendedCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.GetTutorInfoForCatalogExtendedCardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.GetTutorInfoForCatalogExtendedCardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AccountManagerServiceClient.prototype.getTutorInfoForCatalogExtendedCard = function (request, metadata, callback) {
    return this.client_.rpcCall(
        this.hostname_ + "/pb.AccountManagerService/GetTutorInfoForCatalogExtendedCard",
        request,
        metadata || {},
        methodDescriptor_AccountManagerService_GetTutorInfoForCatalogExtendedCard,
        callback,
    );
};

/**
 * @param {!proto.pb.GetTutorInfoForCatalogExtendedCardRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.GetTutorInfoForCatalogExtendedCardResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AccountManagerServicePromiseClient.prototype.getTutorInfoForCatalogExtendedCard = function (request, metadata) {
    return this.client_.unaryCall(
        this.hostname_ + "/pb.AccountManagerService/GetTutorInfoForCatalogExtendedCard",
        request,
        metadata || {},
        methodDescriptor_AccountManagerService_GetTutorInfoForCatalogExtendedCard,
    );
};

module.exports = proto.pb;
