import React from "react";
import TeacherOrdersStore from "store/TeacherOrdersStore";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import InProgressCard from "components/OrdersBodyBlock/TutorOrders/OrderCards/InProgressCard/InProgressCard";
import CompletedCard from "components/OrdersBodyBlock/TutorOrders/OrderCards/CompletedCard/CompletedCard";
import CancelledCard from "components/OrdersBodyBlock/TutorOrders/OrderCards/CancelledCard/CancelledCard";

const TutorOrders = observer(({ children, ...props }) => {
    return (
        <>
            {TeacherOrdersStore.activeTab === 0 &&
                TeacherOrdersStore.orders.filter((item) => item.type === "inProgress").length > 0 &&
                TeacherOrdersStore.orders
                    .filter((item) => item.type === "inProgress")
                    .map((order) => (
                        <InProgressCard
                            key={order.id}
                            orderInfo={order}
                        />
                    ))}
            {TeacherOrdersStore.activeTab === 1 &&
                TeacherOrdersStore.orders.filter((item) => item.type === "completed").length > 0 &&
                TeacherOrdersStore.orders
                    .filter((item) => item.type === "completed")
                    .map((order) => (
                        <CompletedCard
                            key={order.id}
                            orderInfo={order}
                        />
                    ))}
            {TeacherOrdersStore.activeTab === 2 &&
                TeacherOrdersStore.orders.filter((item) => item.type === "cancelled").length > 0 &&
                TeacherOrdersStore.orders
                    .filter((item) => item.type === "cancelled")
                    .map((order) => (
                        <CancelledCard
                            key={order.id}
                            orderInfo={order}
                        />
                    ))}
        </>
    );
});

export default memo(TutorOrders);
