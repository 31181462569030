import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import React, { memo, useEffect } from "react";
import SChipsGroup from "components/UI/ChipGroup/ChipGroup";
import { districts } from "API/dictionaries/const_data";
import QuestionnaireButtons from "components/TeacherQuestionnaireBodyBlock/Components/QuestionnaireButtons";
import { observer } from "mobx-react-lite";
import IOSSwitch from "components/UI/IOSSwitch";
import FormControlLabel from "@mui/material/FormControlLabel";
import * as themes from "components/CatalogBodyBlock/FiltersDrawer/LessonsPlaceThemes";
import SingleChoiceLightFilter from "components/UI/SingleChoiceLightFilter";
import { Box, Typography } from "@mui/material";
import * as theme from "./ClassesPlaceStepThemes";
import * as textThemes from "themes/TextThemes";
import OrangeExclamationIcon from "assets/CommonAssets/SVG/OrangeExclamationIcon";
import { useTranslation } from "react-i18next";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { useMedias } from "API/mediasHook";
import fetchSuggestions from "API/services/AddressHintService";
import UserStore from "store/UserStore";

const ClassesPlaceStep = observer(({ currentStep, setCurrentStep }) => {
    const medias = useMedias();

    useEffect(() => {
        const fetchData = async () => {
            await TeacherQuestionnaireStore.FetchDistrictsAndUndergroundStationsByCityData();
        };

        fetchData();
    }, []);

    let debounceTimerId = null;

    const debounce = (callback, delay) => {
        return (...args) => {
            if (debounceTimerId) {
                clearTimeout(debounceTimerId);
            }

            debounceTimerId = setTimeout(() => {
                callback.call(this, ...args);
            }, delay);
        };
    };

    async function fetchAddresses(value) {
        const locations = await fetchSuggestions(value);

        TeacherQuestionnaireStore.SetOptionsLessonsPlace(locations);
    }

    useEffect(() => {
        fetchAddresses(UserStore.UserInfo.city.title);
        if (!TeacherQuestionnaireStore.teacher.tutorsPlace.locations.length) {
            TeacherQuestionnaireStore.setMainTeacherPlace({ ID: 0, LessonsPlace: UserStore.UserInfo.city.title });
        }
    }, []);

    const getSuggestionsFromInput = async (value) => {
        await fetchAddresses(value);
    };

    const getSuggestionsWithDebounce = (value) => {
        debounce(() => getSuggestionsFromInput(value), 500)();
    };

    const { t } = useTranslation();

    const disabledContinue =
        (!TeacherQuestionnaireStore.teacher.classFormats.includes("at_the_tutors") &&
            !TeacherQuestionnaireStore.teacher.classFormats.includes("at_the_students") &&
            !TeacherQuestionnaireStore.teacher.classFormats.includes("online")) ||
        (TeacherQuestionnaireStore.teacher.classFormats.includes("at_the_students") && TeacherQuestionnaireStore.teacher.studentsPlaces.locations.length === 0) ||
        (TeacherQuestionnaireStore.teacher.classFormats.includes("at_the_tutors") && TeacherQuestionnaireStore.teacher.tutorsPlace.locations.length === 0);

    return (
        <ScrollToTop>
            <Box sx={theme.TeacherPersonalDataStep}>
                <Box sx={theme.TeacherPersonalData}>
                    <Typography sx={textThemes.HeadlineH41Black}>{t("TeacherQuestionnaire.whereWillYouHold")}</Typography>
                </Box>
                <Box sx={theme.TeacherSwitcherContainer}>
                    <Box sx={theme.TeacherQuestionnaireSwitcherBlock}>
                        <Box sx={theme.TeacherQuestionnaireSwitcherText}>
                            <Typography sx={textThemes.Button16Black}>{t("TeacherQuestionnaire.online")}</Typography>
                            <Typography sx={textThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.onlineClassesAreHeld")}</Typography>
                        </Box>
                        <FormControlLabel
                            sx={theme.FormControlLabel}
                            control={
                                <IOSSwitch
                                    sx={theme.IOSSwitch}
                                    onChange={() => TeacherQuestionnaireStore.setPlaces("online")}
                                    checked={TeacherQuestionnaireStore.teacher.classFormats.includes("online")}
                                />
                            }
                        />
                    </Box>
                    <Box>
                        <Box sx={theme.TeacherQuestionnaireSwitcherBlock}>
                            <Box sx={theme.TeacherQuestionnaireSwitcherText}>
                                <Typography sx={textThemes.Button16Black}>{t("TeacherQuestionnaire.atHome")}</Typography>
                                <Typography sx={textThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.pleaseIndicateAddress")}</Typography>
                            </Box>
                            <FormControlLabel
                                sx={theme.FormControlLabel}
                                control={
                                    <IOSSwitch
                                        sx={theme.IOSSwitch}
                                        onChange={() => TeacherQuestionnaireStore.setPlaces("at_the_tutors")}
                                        checked={TeacherQuestionnaireStore.teacher.classFormats.includes("at_the_tutors")}
                                    />
                                }
                            />
                        </Box>
                        <Box>
                            {TeacherQuestionnaireStore.teacher.classFormats.includes("at_the_tutors") ? (
                                <Box sx={theme.warningBox}>
                                    <SingleChoiceLightFilter
                                        propOption={TeacherQuestionnaireStore.teacher.tutorsPlace.locations[0]}
                                        propOptions={TeacherQuestionnaireStore.optionsLessonsPlace}
                                        valueToShow={"LessonsPlace"}
                                        valueToBind={"ID"}
                                        processingChange={(event, value) => TeacherQuestionnaireStore.setMainTeacherPlace(value)}
                                        processingInput={getSuggestionsWithDebounce}
                                        themeWrapper={themes.FiltersLessonsPlaceTheme}
                                        placeholder={t("teacherSettings.questionnaire.address")}
                                        clearOnBlur={false}
                                        disableClearable={false}
                                        variant={"standard"}
                                        freeSolo={true}
                                    />
                                    <Box sx={theme.TeacherQuestionnaireExclamationBlock}>
                                        <Box>
                                            <OrangeExclamationIcon />
                                        </Box>
                                        <Box sx={theme.TeacherQuestionnaireSwitcherExclamationText}>
                                            <Typography sx={textThemes.Caption1Black}>{t("TeacherQuestionnaire.noOneWillSee")}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={theme.TeacherQuestionnaireSwitcherBlock}>
                            <Box sx={theme.TeacherQuestionnaireSwitcherText}>
                                <Typography sx={textThemes.Button16Black}>{t("TeacherQuestionnaire.atTheStudent")}</Typography>
                                <Typography sx={textThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.pleaseIndicateRequiredNumberOfDistricts")}</Typography>
                            </Box>
                            <FormControlLabel
                                sx={theme.FormControlLabel}
                                control={
                                    <IOSSwitch
                                        sx={theme.IOSSwitch}
                                        onChange={() => TeacherQuestionnaireStore.setPlaces("at_the_students")}
                                        checked={TeacherQuestionnaireStore.teacher.classFormats.includes("at_the_students")}
                                    />
                                }
                            />
                        </Box>
                        <Box>
                            {TeacherQuestionnaireStore.teacher.classFormats.includes("at_the_students") ? (
                                <Box sx={theme.DistrictBox(TeacherQuestionnaireStore.teacher.studentsPlaces.locations.length > 0)}>
                                    <SChipsGroup
                                        width="100%"
                                        chipData={TeacherQuestionnaireStore.teacher.studentsPlaces.locations}
                                        setChipData={(value) => TeacherQuestionnaireStore.setMainStudentPlaces(value)}
                                        list={TeacherQuestionnaireStore.districtsAndUndergroundStationsByCityList.map((obj) => obj.District)}
                                        error={!TeacherQuestionnaireStore.teacher.studentsPlaces.locations.length}
                                        placeholder={t("TeacherQuestionnaire.metroDistrict")}
                                    />
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                </Box>
                <QuestionnaireButtons
                    disabled={disabledContinue}
                    setCurrentStep={setCurrentStep}
                    currentStep={currentStep}
                    width={medias.sm ? "" : "100%"}
                />
            </Box>
        </ScrollToTop>
    );
});

export default memo(ClassesPlaceStep);
