const AdviceIcon4 = function (props) {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="18"
                cy="18"
                r="18"
                fill="#6212FF"
            />
            <path
                d="M22.7502 7.75V9.31C26.4602 9.31 27 10.5 26.9602 13.56C26.9902 13.84 26.7502 14.09 26.4602 14.09H9.5402C9.2602 14.09 9.0202 13.85 9.0402 13.56C9 10.5 9.5402 9.31 13.2502 9.31V7.75C13.2502 7.34 13.5902 7 14.0002 7C14.4102 7 14.7502 7.34 14.7502 7.75V9.25H21.2502V7.75C21.2502 7.34 21.5902 7 22.0002 7C22.4102 7 22.7502 7.34 22.7502 7.75Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 15.5898H26C26.55 15.5898 27 16.0399 27 16.5899V22.7499C27 27 26 27.7499 22 27.7499H14C10 27.7499 9 27 9 22.7499V16.5899C9 16.0399 9.45 15.5898 10 15.5898ZM12.5 19C12.5 18.7239 12.7239 18.5 13 18.5H14C14.2761 18.5 14.5 18.7239 14.5 19V20C14.5 20.2761 14.2761 20.5 14 20.5H13C12.7239 20.5 12.5 20.2761 12.5 20V19ZM13 22.5C12.7239 22.5 12.5 22.7239 12.5 23V24C12.5 24.2761 12.7239 24.5 13 24.5H14C14.2761 24.5 14.5 24.2761 14.5 24V23C14.5 22.7239 14.2761 22.5 14 22.5H13ZM17 19C17 18.7239 17.2239 18.5 17.5 18.5H18.5C18.7761 18.5 19 18.7239 19 19V20C19 20.2761 18.7761 20.5 18.5 20.5H17.5C17.2239 20.5 17 20.2761 17 20V19ZM17.5 22.5C17.2239 22.5 17 22.7239 17 23V24C17 24.2761 17.2239 24.5 17.5 24.5H18.5C18.7761 24.5 19 24.2761 19 24V23C19 22.7239 18.7761 22.5 18.5 22.5H17.5ZM21.5 19C21.5 18.7239 21.7239 18.5 22 18.5H23C23.2761 18.5 23.5 18.7239 23.5 19V20C23.5 20.2761 23.2761 20.5 23 20.5H22C21.7239 20.5 21.5 20.2761 21.5 20V19ZM22 22.5C21.7239 22.5 21.5 22.7239 21.5 23V24C21.5 24.2761 21.7239 24.5 22 24.5H23C23.2761 24.5 23.5 24.2761 23.5 24V23C23.5 22.7239 23.2761 22.5 23 22.5H22Z"
                fill="white"
            />
        </svg>
    );
};

export default AdviceIcon4;
