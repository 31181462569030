import React from "react";

import { Divider, Typography, Stack } from "@mui/material";

import OrderDate from "./OrderDate";
import OrderDetailsState from "./OrderDetailsState";

import * as styles from "pages/DiplomaChats/styles";
import Box from "@mui/material/Box";

const HeadContainer = () => {
    return (
        <Box sx={styles.headContainer}>
            <Typography variant="title">Дипломная работа • Информационная безопасность</Typography>

            <Divider sx={{ marginTop: "20px", marginBottom: "10px" }} />
            <Stack
                direction="row"
                justifyContent="space-between"
            >
                <OrderDate />
                <OrderDetailsState />
            </Stack>
        </Box>
    );
};

export default HeadContainer;
