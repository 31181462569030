import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import OrdersSearchStore from "store/OrdersSearchStore";
import { ThemeProvider } from "@mui/material/styles";
import * as themes from "./OrdersStudentAgeThemes";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import FreeMultipleChoiceFilter from "components/UI/FreeMultipleChoiceFilter";

const OrdersStudentAge = observer(({ children, ...props }) => {
    const handleChange = () => {
        OrdersSearchStore.ToggleOrdersStudentAgeExpanded();
    };

    const processingOrdersStudentAge = (event, value) => {
        OrdersSearchStore.SetOrdersStudentAge(value);
    };

    return (
        <ThemeProvider theme={themes.OrdersStudentAgeAccordionTheme}>
            <Accordion
                expanded={OrdersSearchStore.ordersStudentAgeExpanded}
                onChange={handleChange}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography sx={themes.headerOrdersStudentAgeStyle}>Возраст ученика</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FreeMultipleChoiceFilter
                        propOptions={OrdersSearchStore.optionsOrdersStudentAge}
                        valueToShow={"OrdersStudentAge"}
                        valueToBind={"Checked"}
                        valueForKey={"ID"}
                        processingChange={processingOrdersStudentAge}
                        themeWrapper={themes.FiltersOrdersStudentAgeTheme}
                    ></FreeMultipleChoiceFilter>
                </AccordionDetails>
            </Accordion>
        </ThemeProvider>
    );
});

export default memo(OrdersStudentAge);
