import React from "react";

const SetMail = () => {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="18"
                cy="18"
                r="18"
                fill="#FF9212"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.9526 7.51681C18.3583 7.19492 17.6417 7.19492 17.0474 7.51681L9.69477 11.4995C9.61022 11.5396 9.52815 11.5842 9.44892 11.6329C9.40859 11.6559 9.37083 11.6806 9.33558 11.7068C8.68346 12.1567 8.25 12.8993 8.25 13.7508V24.2508C8.25 25.6473 9.41584 26.7508 10.8 26.7508H25.2C26.5842 26.7508 27.75 25.6473 27.75 24.2508V13.7508C27.75 12.8993 27.3165 12.1567 26.6644 11.7068C26.6291 11.6805 26.5914 11.6559 26.5511 11.633C26.4719 11.5842 26.3898 11.5396 26.3052 11.4995L18.9526 7.51681ZM18.4509 18.773C18.1674 18.9163 17.8326 18.9163 17.5491 18.773L11.354 15.6434C10.4142 15.1686 10.7519 13.7508 11.8049 13.7508L24.1951 13.7508C25.2481 13.7508 25.5858 15.1686 24.646 15.6434L18.4509 18.773Z"
                fill="white"
            />
        </svg>
    );
};

export default SetMail;
