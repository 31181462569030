import React, { memo } from "react";
import * as themes from "./InfoItemThemes";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const InfoItem = observer(({ children, ...props }) => {
    return (
        <Box>
            <Typography sx={themes.labelStyle}>{props.label}</Typography>
            <Typography sx={themes.infoStyle}>{props.info}</Typography>
        </Box>
    );
});

export default memo(InfoItem);
