import React from "react";
import * as theme from "./HeaderStyle";
import SearchLine from "./SearchLine/SearchLine";
import PopularPart from "./PopularPart/PopularPart";
import { useMedias } from "API/mediasHook";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { memo } from "react";

const SearchBlock = ({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <Box>
            <Box sx={theme.textContainer(medias)}>
                <Typography sx={theme.mainHeader(medias)}>{t("promoPages.main.weWillFindBestTutor")}</Typography>
                <Box>
                    <Typography sx={theme.supHeader(medias)}>{t("promoPages.main.studyBuddyKnowsHowPart1")}</Typography>
                    <Typography sx={theme.supHeader(medias)}>{t("promoPages.main.studyBuddyKnowsHowPart2")}</Typography>
                </Box>
            </Box>
            <Box>
                <SearchLine />
                <PopularPart />
            </Box>
        </Box>
    );
};

export default memo(SearchBlock);
