const TaskIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path
                d="M12.771 0H5.229C1.953 0 0 1.953 0 5.229V12.771C0 16.047 1.953 18 5.229 18H12.771C16.047 18 18 16.047 18 12.771V5.229C18 1.953 16.047 0 12.771 0ZM7.173 11.61L5.148 13.635C5.013 13.77 4.842 13.833 4.671 13.833C4.5 13.833 4.32 13.77 4.194 13.635L3.519 12.96C3.249 12.699 3.249 12.267 3.519 12.006C3.78 11.745 4.203 11.745 4.473 12.006L4.671 12.204L6.219 10.656C6.48 10.395 6.903 10.395 7.173 10.656C7.434 10.917 7.434 11.349 7.173 11.61ZM7.173 5.31L5.148 7.335C5.013 7.47 4.842 7.533 4.671 7.533C4.5 7.533 4.32 7.47 4.194 7.335L3.519 6.66C3.249 6.399 3.249 5.967 3.519 5.706C3.78 5.445 4.203 5.445 4.473 5.706L4.671 5.904L6.219 4.356C6.48 4.095 6.903 4.095 7.173 4.356C7.434 4.617 7.434 5.049 7.173 5.31ZM14.004 13.158H9.279C8.91 13.158 8.604 12.852 8.604 12.483C8.604 12.114 8.91 11.808 9.279 11.808H14.004C14.382 11.808 14.679 12.114 14.679 12.483C14.679 12.852 14.382 13.158 14.004 13.158ZM14.004 6.858H9.279C8.91 6.858 8.604 6.552 8.604 6.183C8.604 5.814 8.91 5.508 9.279 5.508H14.004C14.382 5.508 14.679 5.814 14.679 6.183C14.679 6.552 14.382 6.858 14.004 6.858Z"
                fill="white"
            />
        </svg>
    );
};

export default TaskIcon;
