const Distant = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_7631_75970)">
                <path
                    d="M14.8331 17.4571C14.8331 17.755 14.5893 17.9988 14.2914 17.9988H6.12469C5.82677 17.9988 5.58302 17.755 5.58302 17.4571C5.58302 17.1592 5.82677 16.9154 6.12469 16.9154H8.58303V16.3321C8.58303 16.0559 8.80689 15.8321 9.08303 15.8321H10.208H11.333C11.6092 15.8321 11.833 16.0559 11.833 16.3321V16.9154H14.2914C14.5893 16.9154 14.8331 17.1592 14.8331 17.4571ZM10.208 9.33206C10.1506 9.33206 10.0921 9.32285 10.0369 9.30443L8.04136 8.63926V10.7924C8.20874 10.9901 8.77587 11.4987 10.208 11.4987C11.6299 11.4987 12.1998 10.9971 12.3747 10.7891V8.63872L10.3792 9.30389C10.324 9.32285 10.2655 9.33206 10.208 9.33206ZM7.70927 6.94422C7.49678 7.01505 7.49682 7.31561 7.70932 7.38638L7.71149 7.38692L9.57534 8.00848C9.98602 8.14543 10.4301 8.14543 10.8407 8.00848L12.7046 7.38692L12.7068 7.38638C12.9193 7.31561 12.9193 7.01505 12.7068 6.94422L10.8405 6.32211C10.43 6.18527 9.98612 6.18527 9.57558 6.32211L7.70927 6.94422ZM18.3331 5.29037V12.2904C18.3331 13.1863 17.4997 14.7487 15.8747 14.7487H4.54135C2.49973 14.7487 2.08301 13.1863 2.08301 12.2904V5.29037C2.08301 4.39445 2.49973 2.83203 4.54135 2.83203H15.8747C17.4997 2.83203 18.3331 4.39445 18.3331 5.29037ZM15.6247 7.16538C15.6247 6.93192 15.4758 6.725 15.2542 6.65134L10.3792 5.02633C10.2682 4.9895 10.1474 4.9895 10.0363 5.02633L5.16131 6.65134C4.94031 6.72555 4.79135 6.93192 4.79135 7.16538C4.79135 7.39884 4.94031 7.60576 5.16185 7.67943L6.95803 8.27797V10.9571C6.95803 11.041 6.97753 11.1244 7.0149 11.1992C7.08586 11.3406 7.77703 12.5821 10.208 12.5821C12.639 12.5821 13.3302 11.3406 13.4012 11.1992C13.4385 11.1244 13.458 11.041 13.458 10.9571V8.27797L14.5414 7.91668V9.58206C14.5414 9.88106 14.7835 10.1237 15.0831 10.1237C15.3826 10.1237 15.6247 9.88106 15.6247 9.58206V7.16538Z"
                    fill="#BBBBC8"
                />
            </g>
            <defs>
                <clipPath id="clip0_7631_75970">
                    <rect
                        width="20"
                        height="20"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Distant;
