import Platinum from "assets/PAAssets/SVG/platinum";
import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { useTranslation } from "react-i18next";
import * as textThemes from "themes/TextThemes";
import * as themes from "./CurrentLevelThemes";
import { memo } from "react";
import { useMedias } from "API/mediasHook";

const CurrentLevel = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    return (
        <Box
            sx={themes.WhiteContainer}
            onClick={() => {
                navigate("/tutor/level");
            }}
        >
            <Box sx={themes.TopBlock}>
                <Typography sx={textThemes.Body2DarkGrey}>{`${t("teacherAccount.privilegeProgram.levelFor")} ${t(TeacherQuestionnaireStore.monthNames[new Date().getMonth()])}`}</Typography>
                <Box sx={themes.LevelRow}>
                    <Typography sx={textThemes.Button16BoldBlack}>
                        {t(TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.currentMonth).title)}
                        <span style={textThemes.Caption1Black}>
                            {` (${t("teacherAccount.privilegeProgram.commission")} ${TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.currentMonth).commission}%)`}
                        </span>
                    </Typography>
                    {TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.currentMonth).level === 1 && (
                        <Box sx={themes.PlatinumIcon(medias)}>
                            <Platinum />
                        </Box>
                    )}
                </Box>
            </Box>
            <Typography
                sx={textThemes.Caption11Black}
            >{`${TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.currentMonth).level}${t(TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.currentMonth).levelEnding)} ${t("teacherAccount.privilegeProgram.tutorLevel")}`}</Typography>
        </Box>
    );
});

export default memo(CurrentLevel);
