import * as React from "react";
import { memo } from "react";
import { observer } from "mobx-react-lite";

import Box from "@mui/material/Box";

import ImageAttachment from "./ImageAttachment/ImageAttachment";
import VideoAttachment from "./VideoAttachment/VideoAttachment";

import * as themes from "./MessageAttachmentsThemes";

const MessageAttachments = observer(({ children, ...props }) => {
    const currentContent = (value, elementsInRow, finalInRow, moreToShow) => {
        const attachmentType = value.content_type.split("/")[0];

        switch (attachmentType) {
            case "image":
                return (
                    <ImageAttachment
                        value={value}
                        elementsInRow={elementsInRow}
                        finalInRow={finalInRow}
                        moreToShow={moreToShow}
                    ></ImageAttachment>
                );
            case "video":
                return (
                    <VideoAttachment
                        value={value}
                        elementsInRow={elementsInRow}
                        finalInRow={finalInRow}
                        moreToShow={moreToShow}
                    ></VideoAttachment>
                );
            default:
                return null;
        }
    };

    const attachmentContainerCalc = () => {
        let totalCount = props.message.medias.length;

        if (totalCount === 1) {
            return props.message.medias.map((value, index) => currentContent(value, 1));
        } else if (totalCount > 1 && totalCount < 4) {
            return <Box sx={themes.singleRowContainerStyle}>{props.message.medias.map((value, index) => currentContent(value, totalCount))}</Box>;
        } else if (totalCount >= 4) {
            return (
                <Box sx={themes.twoRowsContainerStyle}>
                    <Box sx={themes.firstRowContainerStyle}>{currentContent(props.message.medias.at(0), 1)}</Box>
                    <Box sx={themes.secondRowContainerStyle}>{props.message.medias.slice(1, 4).map((value, index) => currentContent(value, 3, index === 2, totalCount - 4))}</Box>
                </Box>
            );
        }
    };

    return <Box sx={themes.messageAttachmentsContainerStyle}>{attachmentContainerCalc()}</Box>;
});

export default memo(MessageAttachments);
