import React from "react";

const MicroOffIconWB = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 2C6.25052 2 4.83333 3.41719 4.83333 5.16667V8.5C4.83333 8.77614 5.05719 9 5.33333 9C5.60948 9 5.83333 8.77614 5.83333 8.5V5.16667C5.83333 3.96948 6.80281 3 8 3C9.19719 3 10.1667 3.96948 10.1667 5.16667V5.36667C10.1667 5.4275 10.1775 5.48579 10.1974 5.53972L2.26633 13.4757C2.07113 13.671 2.07123 13.9876 2.26655 14.1828C2.46187 14.378 2.77846 14.3779 2.97366 14.1826L4.25498 12.9005C5.13189 13.6848 6.25616 14.2005 7.5 14.3103V15.8333C7.5 16.1095 7.72386 16.3333 8 16.3333C8.27614 16.3333 8.5 16.1095 8.5 15.8333V14.3102C11.3559 14.0567 13.6 11.6533 13.6 8.73229V7.59896C13.6 7.32282 13.3762 7.09896 13.1 7.09896C12.8239 7.09896 12.6 7.32282 12.6 7.59896V8.73229C12.6 11.2695 10.5372 13.3323 8.00002 13.3323C6.83505 13.3323 5.77577 12.9016 4.96318 12.1919L5.92653 11.2279C6.48302 11.7118 7.21084 12 7.99999 12C9.74946 12 11.1667 10.5828 11.1667 8.83333V8.5C11.1667 8.22386 10.9428 8 10.6667 8C10.3905 8 10.1667 8.22386 10.1667 8.5V8.83333C10.1667 10.0305 9.19718 11 7.99999 11C7.48304 11 7.00745 10.8206 6.63591 10.5181L13.7337 3.41594C13.9289 3.22062 13.9288 2.90404 13.7334 2.70884C13.5381 2.51364 13.2215 2.51373 13.0263 2.70906L11.1192 4.61734C10.8593 3.12989 9.56211 2 8 2ZM3.39998 7.59896C3.39998 7.32282 3.17613 7.09896 2.89998 7.09896C2.62384 7.09896 2.39998 7.32282 2.39998 7.59896V8.73229C2.39998 9.50621 2.55355 10.2392 2.83964 10.9141C2.94741 11.1683 3.24089 11.2871 3.49513 11.1793C3.74937 11.0715 3.86811 10.7781 3.76033 10.5238C3.52642 9.97202 3.39998 9.3717 3.39998 8.73229V7.59896Z"
                fill="white"
            />
        </svg>
    );
};

export default MicroOffIconWB;
