import { styleProcessing } from "API/plugins/Utilities";

export const SignIn = (theme) => {
    let commonStyle = {
        height: "630px",
        padding: "50px 60px 40px 60px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "13px 16px 16px 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const SignInContent = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "30px",
};

export const EmailField = (condition) => {
    return {
        "& .MuiInput-underline:before": {
            borderBottom: condition ? "1px solid ##E12626" : "1px solid #BBBBC8",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: condition ? "#E12626" : "#6212FF",
        },
        "& .MuiInputBase-input": {
            fontSize: "16px",
            lineHeight: "150%",
            fontWeight: 400,
            textTransform: "none",
        },
    };
};

export const PasswordField = (condition) => {
    return {
        "& .MuiInput-underline:before": {
            borderBottom: condition ? "1px solid ##E12626" : "1px solid #BBBBC8",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: condition ? "#E12626" : "#6212FF",
        },
        "& .MuiInputBase-input": {
            fontSize: "16px",
            lineHeight: "150%",
            fontWeight: 400,
            textTransform: "none",
        },
    };
};

export const HideButton = {
    p: 0,
    mb: "4px",
};

export const LoginButton = {
    width: "100%",
};

export const SignUpContainer = (theme) => {
    let commonStyle = {
        background: "rgba(211, 203, 226, 0.2)",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "row",
        padding: "20px 40px 20px 40px",
        justifyContent: "space-between",
        alignContent: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const SignUpButtons = {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    alignItems: "flex-start",
};

export const Form = (theme) => {
    let commonStyle = {
        maxWidth: "328px",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            maxWidth: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TabsAndForm = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const PhoneForm = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const EmailForm = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const EmailAndForgotPassword = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
};

export const ForgotPassword = {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
};
