import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import { Button, Dialog, IconButton } from "@mui/material";
import * as themes from "./ModalErrorThemes";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CloseRounded } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import ErrorStore from "store/ErrorStore";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ModalError = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const Close = () => {
        ErrorStore.HideError();
    };

    const RefreshCurrentPage = () => {
        window.location.reload();
    };

    const RedirectToMainPage = () => {
        ErrorStore.HideError();
        navigate("/");
    };

    const downloadErrorsReport = () => {
        ErrorStore.DownloadErrorsReport();
    };

    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={ErrorStore.shouldShowError}
                onClose={Close}
            >
                <Box sx={themes.dialogContainerStyle}>
                    <ThemeProvider theme={themes.CloseIconTheme}>
                        <IconButton
                            onClick={Close}
                            disableRipple
                        >
                            <CloseRounded></CloseRounded>
                        </IconButton>
                    </ThemeProvider>
                    <Box sx={themes.cardContainerStyle}>
                        <Box sx={themes.headerBlockContainerStyle}>
                            <Typography sx={themes.headerTextStyle}>{t("error.title")}</Typography>
                        </Box>
                        <Box sx={themes.contentBlockContainerStyle}>
                            <Typography sx={themes.contentTextStyle}>{ErrorStore.error}</Typography>
                        </Box>
                        <Box sx={themes.actionsBlockContainerStyle}>
                            <Box sx={themes.actionsContainerStyle}>
                                {ErrorStore.isCritical ? (
                                    <>
                                        <ThemeProvider theme={themes.ModalActionButtonTheme}>
                                            <Button
                                                onClick={RedirectToMainPage}
                                                disableRipple
                                            >
                                                <Typography style={themes.buttonTextStyle}>{t("error.redirectAfterError")}</Typography>
                                            </Button>
                                        </ThemeProvider>
                                        <ThemeProvider theme={themes.ModalActionButtonTheme}>
                                            <Button
                                                onClick={RefreshCurrentPage}
                                                disableRipple
                                            >
                                                <Typography style={themes.buttonTextStyle}>{t("error.refreshAfterError")}</Typography>
                                            </Button>
                                        </ThemeProvider>
                                    </>
                                ) : (
                                    <>
                                        <ThemeProvider theme={themes.ModalActionButtonTheme}>
                                            <Button
                                                onClick={Close}
                                                disableRipple
                                            >
                                                <Typography style={themes.buttonTextStyle}>{t("ui.close")}</Typography>
                                            </Button>
                                        </ThemeProvider>
                                    </>
                                )}
                            </Box>

                            <Box>
                                <ThemeProvider theme={themes.ModalActionButtonTheme}>
                                    <Button
                                        onClick={downloadErrorsReport}
                                        disableRipple
                                    >
                                        <Typography style={themes.buttonTextStyle}>{t("ui.errorReportDownload")}</Typography>
                                    </Button>
                                </ThemeProvider>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </ThemeProvider>
    );
});

export default memo(ModalError);
