import img1Cirle from "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle1.png";
import img2Cirle from "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle2.png";
import img3Cirle from "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle3.png";
import img4Cirle from "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle4.png";
import img5Cirle from "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle5.png";
import img6Cirle from "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle6.png";
import img7Cirle from "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle7.png";
import img8Cirle from "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle8.png";
import img9Cirle from "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle9.png";
import img10Cirle from "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle10.png";
import img11Cirle from "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle11.png";
import img12Cirle from "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle12.png";
import img13Cirle from "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle13.png";
import img14Cirle from "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle14.png";
import img15Cirle from "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle15.png";
import img16Cirle from "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle16.png";
import img17Cirle from "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle17.png";
import img18Cirle from "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle18.png";
import img19Cirle from "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle19.png";

import img1Rect from "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle1.png";
import img2Rect from "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle2.png";
import img3Rect from "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle3.png";
import img4Rect from "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle4.png";
import img5Rect from "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle5.png";
import img6Rect from "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle6.png";
import img7Rect from "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle7.png";
import img8Rect from "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle8.png";
import img9Rect from "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle9.png";
import img10Rect from "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle10.png";

export const imageCircleSources = [
    {
        path: "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle1.png",
        id: img1Cirle,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle2.png",
        id: img2Cirle,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle3.png",
        id: img3Cirle,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle4.png",
        id: img4Cirle,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle5.png",
        id: img5Cirle,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle6.png",
        id: img6Cirle,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle7.png",
        id: img7Cirle,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle8.png",
        id: img8Cirle,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle9.png",
        id: img9Cirle,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle10.png",
        id: img10Cirle,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle11.png",
        id: img11Cirle,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle12.png",
        id: img12Cirle,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle13.png",
        id: img13Cirle,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle14.png",
        id: img14Cirle,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle15.png",
        id: img15Cirle,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle16.png",
        id: img16Cirle,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle17.png",
        id: img17Cirle,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle18.png",
        id: img18Cirle,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/CirclePhoto/Circle19.png",
        id: img19Cirle,
    },
];

export const imageRectangleSources = [
    {
        path: "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle1.png",
        id: img1Rect,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle2.png",
        id: img2Rect,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle3.png",
        id: img3Rect,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle4.png",
        id: img4Rect,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle5.png",
        id: img5Rect,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle6.png",
        id: img6Rect,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle7.png",
        id: img7Rect,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle8.png",
        id: img8Rect,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle9.png",
        id: img9Rect,
    },
    {
        path: "assets/ErrorPagesAssets/PNG/RectanglePhoto/Rectangle10.png",
        id: img10Rect,
    },
];
