import { createTheme } from "@mui/material/styles";

export const actionsBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: "10px",
};

export const priceTextStyle = (mode) => {
    return {
        color: "var(--text-black, #222)",
        textAlign: "center",
        fontSize: mode ? "14px" : "18px",
        fontStyle: "normal",
        fontWeight: mode ? "400" : "600",
        lineHeight: "150%",
    };
};

export const ResponseButtonTheme = (state) =>
    createTheme({
        components: {
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "7px 16px 8px 16px",
                        gap: "10px",
                        width: "100%",
                        height: "36px",
                        background: state ? "var(--grey-light-grey, #BBBBC8)" : "var(--main-purple, #6212FF)",
                        borderRadius: "30px!important",
                        "&:hover": {
                            background: state ? "#BBBBC8!important" : "#6212FF!important",
                            borderColor: "#6212FF!important",
                            ".MuiTypography-root": {
                                color: "white!important",
                            },
                        },
                        "&:active": {
                            background: state ? "#BBBBC8!important" : "#6212FF!important",
                            borderColor: "#6212FF!important",
                            ".MuiTypography-root": {
                                color: "white!important",
                            },
                        },
                    },
                },
            },
        },
    });

export const responseTextStyle = {
    color: "var(--text-white, #FFF)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
    textTransform: "none",
};

export const RefuseButtonTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "7px 16px 8px 16px",
                    gap: "10px",
                    width: "100%",
                    height: "36px",
                    background: "var(--Grey-Separator, #EEEEF0)",
                    borderRadius: "30px!important",
                    "&:hover": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                        ".MuiTypography-root": {
                            color: "white!important",
                        },
                    },
                    "&:active": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                        ".MuiTypography-root": {
                            color: "white!important",
                        },
                    },
                },
            },
        },
    },
});

export const refuseTextStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
    textTransform: "none",
};

export const actionsContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: 10,
};
