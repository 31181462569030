import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import FullChatStore from "../../../store/FullChatStore";
import AddChatsToFolder from "./AddChatsToFolder/AddChatsToFolder";
import CreateOrEditFolder from "./CreateOrEditFolder/CreateOrEditFolder";
import Box from "@mui/material/Box";

const FolderProcessingBlock = observer(({ children, ...props }) => {
    return FullChatStore.folderProcessingStep === 0 ? (
        <Box>
            <CreateOrEditFolder folderChats={props.folderChats} />
        </Box>
    ) : (
        <Box>
            <AddChatsToFolder folderChats={props.folderChats} />
        </Box>
    );
});

export default memo(FolderProcessingBlock);
