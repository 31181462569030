import { TextField, Typography } from "@mui/material";
import * as textStyles from "themes/TextThemes";
import * as styles from "components/SettingsBodyBlock/StudentSettings/Profile/ProfileForms/ProfileFormsThemes";
import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import SettingsStore from "store/SettingsStore";

const FirstNameForm = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={styles.FormBlock}>
            <Typography sx={textStyles.Body2Black}>{t("main.settings.profile.firstName")}</Typography>
            <TextField
                onChange={(event) => {
                    SettingsStore.setTemporaryProfileInfo("name", event.target.value);
                }}
                value={SettingsStore.TemporaryUserInfo.name}
                placeholder={t("main.notSpecified_neuter")}
                sx={styles.NameFields}
                fullWidth
                variant="standard"
                name="name"
            />
        </Box>
    );
});

export default memo(FirstNameForm);
