import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import * as AboutUsStyle from "../AboutUsStyle";
import TutorCard from "../TutorCard";
import { ThemeProvider } from "@mui/material/styles";
import { PlayArrow } from "@mui/icons-material";
import MyPlayer from "../Video/TutorVideo";
import { useMedias } from "../../../../API/mediasHook";
import { observer } from "mobx-react-lite";
import MainPageStore from "../../../../store/MainPageStore";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./MobileCardsCarouselStyles.css";
import Box from "@mui/material/Box";

const MobileCardsCarousel = observer(() => {
    const medias = useMedias();

    const [activeVideoId, setActiveVideoId] = useState(null);

    const handlePlayClick = (id) => {
        setActiveVideoId(id);
    };

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true, // Отключаем боковые стрелки
        centerMode: true,
        centerPadding: "0",
    };

    return (
        <Box
            sx={AboutUsStyle.CarouselContainer}
            className="feedback-slider"
        >
            <Slider {...settings}>
                {MainPageStore.tutorsFeedbackList.map((perTutor) => (
                    <Box
                        key={perTutor.ID}
                        sx={AboutUsStyle.mobileCardBlock}
                    >
                        <Box sx={AboutUsStyle.VideoBlock(medias)}>
                            {/* Третий блок - компонент видеоплеера (рендерится при activeVideoId === perTutor.ID) */}
                            {activeVideoId === perTutor.ID ? (
                                <MyPlayer
                                    style={AboutUsStyle.PlayerBlock(medias)}
                                    VideoName={perTutor.tutorVideo}
                                />
                            ) : (
                                <Box>
                                    <img
                                        style={AboutUsStyle.ImgBlock(medias)}
                                        src={perTutor.tutorPhoto}
                                        alt="Tutor"
                                        onClick={() => handlePlayClick(perTutor.ID)}
                                    />
                                    <Box
                                        onClick={() => handlePlayClick(perTutor.ID)}
                                        sx={AboutUsStyle.tcPlayIconBGStyle(medias)}
                                    >
                                        <ThemeProvider theme={AboutUsStyle.TCPlayIconTheme(medias)}>
                                            <PlayArrow />
                                        </ThemeProvider>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                        <TutorCard
                            key={perTutor.ID}
                            tutor={perTutor}
                            disableClick
                            alwaysWhite
                        />
                    </Box>
                ))}
            </Slider>
        </Box>
    );
});

export default MobileCardsCarousel;
