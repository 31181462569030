export const labelStyle = {
    color: "var(--text-grey, #9E9EB0)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    alignSelf: "stretch",
};

export const infoStyle = {
    color: "var(--text-black, #222)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};
