import React from "react";

const Verified = () => {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="18"
                cy="18"
                r="18"
                fill="#E12626"
            />
            <rect
                width="24"
                height="24"
                transform="translate(6 6)"
                fill="#E12626"
            />
            <path
                d="M18.0054 8C12.4872 8 8 12.4872 8 18C8 23.5128 12.4872 28 18 28C23.5128 28 28 23.5128 28 18C28 12.4872 23.5182 8 18.0054 8ZM18.0054 25.3087C17.3001 25.3087 16.7249 24.7336 16.7249 24.0282C16.7249 23.3228 17.3001 22.7477 18.0054 22.7477C18.7108 22.7477 19.2859 23.3228 19.2859 24.0282C19.2859 24.7336 18.7108 25.3087 18.0054 25.3087ZM19.8394 12.8345L18.6782 20.6587C18.624 21.0331 18.3364 21.3044 18 21.3044C17.6636 21.3044 17.376 21.0331 17.3218 20.6587L16.1606 12.8345C16.0738 12.2485 16.2366 11.6462 16.6001 11.223C16.8931 10.8812 17.2892 10.6913 17.707 10.6913H18.2876C18.7054 10.6913 19.1015 10.8812 19.3945 11.223C19.7634 11.6462 19.9262 12.2485 19.8394 12.8345Z"
                fill="white"
            />
        </svg>
    );
};

export default Verified;
