import React, { memo, useEffect } from "react";
import SettingsBodyBlock from "components/SettingsBodyBlock/SettingsBodyBlock";
import { observer } from "mobx-react-lite";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    spacing: 1,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const SettingsPage = observer(() => {
    return (
        <ThemeProvider theme={theme}>
            <SettingsBodyBlock />;
        </ThemeProvider>
    );
});

export default memo(SettingsPage);
