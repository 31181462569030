import React from "react";
import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import { Button, Avatar, Box, Typography } from "@mui/material";
import { memo } from "react";
import StarIconWhite from "assets/CommonAssets/SVG/StarSVGWhite";
import SButton from "components/UI/SButton";
import { observer } from "mobx-react-lite";
import * as styles from "./ModalThanksForRateThemes";
import * as textStyles from "themes/TextThemes";
import { useTranslation } from "react-i18next";

const ModalThanksForRate = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <ModalSettingsSmall
            visible={props.open}
            handleClose={props.handleDiscard}
        >
            <Box sx={styles.ThanksForRate}>
                <Box sx={styles.AvatarRating}>
                    <Avatar
                        src={props.lesson.photo}
                        sx={styles.Avatar}
                    />
                    <Box sx={styles.ratingBlock(props.lesson.rating)}>
                        <Typography sx={styles.ratingText}>{props.lesson.rating}</Typography>
                        <StarIconWhite />
                    </Box>
                </Box>

                <Typography sx={textStyles.HeadlineH41Black}>{t("main.myClasses.thanksForRatingTitle")}</Typography>
                <Box sx={styles.DescriptionButton}>
                    <Typography sx={textStyles.Body2Black}>{t("main.myClasses.thanksForRatingDescription")}</Typography>
                    <SButton
                        variant="unshadowed"
                        padding="10px 20px 11px 20px"
                    >
                        {t("main.myClasses.signUpForClass")}
                    </SButton>
                </Box>
                <Button
                    variant="text"
                    onClick={props.handleDiscard}
                    disableRipple
                    sx={styles.BackButton}
                >
                    <Typography sx={textStyles.Body2DarkGrey}>{t("main.myClasses.returnToMyClasses")}</Typography>
                </Button>
            </Box>
        </ModalSettingsSmall>
    );
});

export default memo(ModalThanksForRate);
