export const InviteBlock = {
    border: "1px solid var(--main-purple, #6212FF)",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
    backgroundColor: "white",
    borderRadius: "20px",
};

export const InviteBlockContent = {
    padding: "20px 14px 20px 20px",
    display: "flex",
    flexDirection: "column",
    gap: "22px",
};

export const InviteTitle = {
    display: "flex",
    justifyContent: "space-between",
};

export const LinkContainer = {
    paddingBottom: "10px",
    borderBottom: "1px solid #BBBBC8",
    cursor: "pointer",
};

export const PopperProps = {
    disablePortal: true,
    sx: {
        "& .MuiTooltip-tooltip": {
            border: "none",
            color: "#222",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "140%",
            borderRadius: "10px",
            background: "#fff",
            width: "fit-content",
            boxShadow: "0px 2px 10px 0px rgba(106, 99, 118, 0.15)",
            padding: "10px",
            textAlign: "center",
        },
        "& .MuiTooltip-arrow": {
            color: "#fff",
        },
    },
};

export const CopyButton = {
    width: "249px",
    height: "fit-content",
    ":hover": { bgcolor: "#8141FF", boxShadow: "none" },
    ":active": { bgcolor: "#5513D7", boxShadow: "none" },
    ":disabled": { bgcolor: "#B088FF", boxShadow: "none" },
    boxShadow: "none",
    borderRadius: "42px",
    display: "flex",
    padding: "7px 16px 8px 16px",
    gap: "10px",
    alignItems: "center",
    backgroundColor: "#6212FF",
    textTransform: "none",
};

export const InviteText = {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
};

export const LinkBlock = {
    paddingRight: "6px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const BonusesText = {
    width: "fit-content",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "150%",
    background: "linear-gradient(257deg, #04C83B 20.58%, #12AAFF 81.82%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    color: "transparent",
};
