import React from "react";

const VisibilityOffIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M16.32 16.3198C15.0768 17.2675 13.563 17.7925 12 17.818C6.90909 17.818 4 11.9998 4 11.9998C4.90465 10.3139 6.15937 8.84101 7.68 7.67984M10.4727 6.3562C10.9733 6.23903 11.4859 6.18045 12 6.18166C17.0909 6.18166 20 11.9998 20 11.9998C19.5585 12.8257 19.032 13.6033 18.4291 14.3198M13.5418 13.5417C13.3421 13.756 13.1012 13.928 12.8336 14.0472C12.5659 14.1665 12.277 14.2306 11.9841 14.2357C11.6911 14.2409 11.4001 14.187 11.1284 14.0773C10.8568 13.9676 10.61 13.8042 10.4028 13.597C10.1956 13.3899 10.0323 13.1431 9.92255 12.8714C9.81282 12.5997 9.75893 12.3087 9.7641 12.0158C9.76927 11.7228 9.83339 11.4339 9.95264 11.1663C10.0719 10.8986 10.2438 10.6578 10.4582 10.458"
                stroke="#67677A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4 4L20 20"
                stroke="#67677A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default VisibilityOffIcon;
