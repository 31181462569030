import React from "react";

const VisibilityOnIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M4 12.2772C4 12.2772 6.90909 6.45898 12 6.45898C17.0909 6.45898 20 12.2772 20 12.2772C20 12.2772 17.0909 18.0953 12 18.0953C6.90909 18.0953 4 12.2772 4 12.2772Z"
                stroke="#67677A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.0002 14.4593C13.2052 14.4593 14.182 13.4825 14.182 12.2775C14.182 11.0725 13.2052 10.0957 12.0002 10.0957C10.7952 10.0957 9.81836 11.0725 9.81836 12.2775C9.81836 13.4825 10.7952 14.4593 12.0002 14.4593Z"
                stroke="#67677A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default VisibilityOnIcon;
