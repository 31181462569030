import React, { memo } from "react";
import { Box } from "@mui/material";
import ChatBodyBlock from "components/ChatBodyBlock/ChatBodyBlock";

const ChatPage = ({ props }) => {
    const [openModalSend, setOpenModalSend] = React.useState(false);

    return (
        <Box sx={{ background: "#F3F3F3", display: "flex", flexDirection: "column", alignItems: "center", borderRadius: "0px 0px 40px 40px" }}>
            <ChatBodyBlock setOpenModalSend={setOpenModalSend} />
        </Box>
    );
};

export default memo(ChatPage);
