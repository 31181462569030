const AdviceIcon2 = function (props) {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="18"
                cy="18"
                r="18"
                fill="#6212FF"
            />
            <path
                d="M23.8001 26.9485C23.8001 27.306 23.5076 27.5985 23.1501 27.5985H13.35C12.9925 27.5985 12.7 27.306 12.7 26.9485C12.7 26.591 12.9925 26.2985 13.35 26.2985H16.3V25.4985C16.3 25.2224 16.5239 24.9985 16.8 24.9985H18.25H19.7C19.9762 24.9985 20.2 25.2224 20.2 25.4985V26.2985H23.1501C23.5076 26.2985 23.8001 26.591 23.8001 26.9485ZM18.25 17.1985C18.1811 17.1985 18.1109 17.1874 18.0446 17.1653L15.65 16.3671V18.9509C15.8509 19.1881 16.5314 19.7985 18.25 19.7985C19.9563 19.7985 20.6401 19.1966 20.85 18.947V16.3665L18.4554 17.1647C18.3891 17.1874 18.3189 17.1985 18.25 17.1985ZM15.2515 14.3331C14.9965 14.4181 14.9966 14.7787 15.2516 14.8637L15.2542 14.8643L17.6173 15.6524C18.028 15.7893 18.4721 15.7893 18.8827 15.6524L21.2459 14.8643L21.2485 14.8637C21.5035 14.7787 21.5035 14.4181 21.2486 14.3331L18.8825 13.5444C18.472 13.4075 18.0281 13.4075 17.6176 13.5444L15.2515 14.3331ZM28.0001 12.3484V20.7485C28.0001 21.8236 27.0001 23.6985 25.0501 23.6985H11.45C9.00006 23.6985 8.5 21.8236 8.5 20.7485V12.3484C8.5 11.2733 9.00006 9.39844 11.45 9.39844H25.0501C27.0001 9.39844 28.0001 11.2733 28.0001 12.3484ZM24.7501 14.5985C24.7501 14.3183 24.5713 14.07 24.3055 13.9816L18.4554 12.0316C18.3222 11.9874 18.1772 11.9874 18.044 12.0316L12.194 13.9816C11.9288 14.0707 11.75 14.3183 11.75 14.5985C11.75 14.8786 11.9288 15.1269 12.1946 15.2153L14.35 15.9336V19.1485C14.35 19.2492 14.3734 19.3493 14.4183 19.439C14.5034 19.6087 15.3328 21.0985 18.25 21.0985C21.1672 21.0985 21.9966 19.6087 22.0818 19.439C22.1266 19.3493 22.1501 19.2492 22.1501 19.1485V15.9336L23.4501 15.5V17.4985C23.4501 17.8573 23.7406 18.1485 24.1001 18.1485C24.4595 18.1485 24.7501 17.8573 24.7501 17.4985V14.5985Z"
                fill="white"
            />
        </svg>
    );
};

export default AdviceIcon2;
