import React, { memo } from "react";
import * as themes from "./ReviewBlockThemes";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { StarOutlined, VisibilityOutlined } from "@mui/icons-material";

const ReviewBlock = observer(({ children, ...props }) => {
    return (
        <Box sx={themes.reviewBlockStyle}>
            <Box sx={themes.mainBlockStyle}>
                <Box sx={themes.textBlockStyle}>
                    <Typography sx={themes.reviewsLabelStyle}>Отклики:</Typography>
                    <Typography sx={themes.valueStyle}>{props.value.ReviewsCount}</Typography>
                </Box>
                <Box sx={themes.textBlockStyle}>
                    <VisibilityOutlined sx={themes.iconViewedStyle}></VisibilityOutlined>
                    <Typography sx={themes.valueStyle}>{props.value.ViewedCount}</Typography>
                </Box>
            </Box>
            <Box sx={themes.additionalBlockStyle}>
                <StarOutlined sx={themes.iconStarStyle}></StarOutlined>
                <Typography sx={themes.additionalTextStyle}>Высокая вероятность получить заказ</Typography>
            </Box>
        </Box>
    );
});

export default memo(ReviewBlock);
