import React, { memo } from "react";
import ModalSettings from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsBig/ModalSettings";
import { useTranslation } from "react-i18next";
import { Avatar, Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import TeacherStore from "store/TeacherStore";
import CustomCalendar from "components/CustomCalendar/CustomCalendar";
import * as styles from "./ModalCalendarRescheduleThemes";
import * as textStyles from "themes/TextThemes";
import SButton from "components/UI/SButton";
import { useMedias } from "API/mediasHook";
import DrawerHeader from "components/SettingsBodyBlock/StudentSettings/DrawerHeader/DrawerHeader";
import MyClassesDrawer from "components/MyClassesBodyBlock/MyClassesDrawer";
import TutorClassesStore from "store/TutorClassesStore";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";

const ModalCalendarReschedule = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        props.handleSave();
        TutorClassesStore.setTemporaryRescheduleTimeStart(date);
        const newRescheduleDate = new Date(date.getTime() + (props.lesson.timeEnd - props.lesson.timeStart));

        TutorClassesStore.setTemporaryRescheduleTimeEnd(newRescheduleDate);
    };

    const [date, setDate] = React.useState("");

    const DrawerList = (
        <Box sx={styles.ModalCalendarReschedule(medias)}>
            <DrawerHeader
                text={t("main.myClasses.rescheduleRequest")}
                handleClose={Close}
            />
            <Box sx={styles.CalendarContainer(medias)}>
                <CustomCalendar
                    maxContentHeight="466px"
                    type="modal"
                    weekTimeSlots={TeacherStore.teacherWeekTimeSlots}
                    setDate={setDate}
                    date={date}
                    currentClassTime={props.lesson.timeStart}
                />
            </Box>
            <Box sx={styles.ButtonContainer(medias)}>
                <Box sx={styles.TimeZone(medias)}>
                    <Typography
                        sx={textStyles.Body2DarkGrey}
                        component={"div"}
                    >
                        {t("main.myClasses.yourTimezone")}
                    </Typography>
                </Box>
                <SButton
                    variant="arrow"
                    padding="11px 24px 12px 24px"
                    disabled={date === ""}
                    onClick={Save}
                >
                    {t("main.myClasses.continueReschedule")}
                </SButton>
            </Box>
        </Box>
    );

    return (
        <>
            {medias.sm ? (
                <ModalSettings
                    visible={props.open}
                    handleClose={Close}
                    header={t("main.myClasses.rescheduleRequest")}
                    padding="0px"
                    width="665px"
                >
                    <Box sx={styles.ModalCalendarReschedule}>
                        <Box sx={styles.CalendarContainer}>
                            <CustomCalendar
                                maxContentHeight="466px"
                                type="modal"
                                weekTimeSlots={TeacherStore.teacherWeekTimeSlots}
                                setDate={setDate}
                                date={date}
                                currentClassTime={props.lesson.timeStart}
                            />
                        </Box>
                        <Box sx={styles.ButtonContainer}>
                            <Box sx={styles.TimeZone}>
                                <Typography
                                    sx={textStyles.Body2DarkGrey}
                                    component={"div"}
                                >
                                    {t("main.myClasses.yourTimezone")}
                                    <span style={textStyles.Button14DarkGrey}>
                                        {" "}
                                        {TeacherQuestionnaireStore.temporaryTimezone.text.slice(0, TeacherQuestionnaireStore.temporaryTimezone.text.indexOf(","))}
                                    </span>
                                </Typography>
                            </Box>
                            <SButton
                                variant="arrow"
                                padding="11px 24px 12px 24px"
                                disabled={date === ""}
                                onClick={Save}
                            >
                                {t("main.myClasses.continueReschedule")}
                            </SButton>
                        </Box>
                    </Box>
                </ModalSettings>
            ) : (
                <MyClassesDrawer
                    open={props.open}
                    close={Close}
                >
                    {DrawerList}
                </MyClassesDrawer>
            )}
        </>
    );
});

export default memo(ModalCalendarReschedule);
