export const IconSettingsMini = () => {
    return (
        <svg
            width="2.2vh"
            height="2.2vh"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.00021 8.85491C8.4736 8.85491 8.85735 8.47115 8.85735 7.99777C8.85735 7.52438 8.4736 7.14062 8.00021 7.14062C7.52682 7.14062 7.14307 7.52438 7.14307 7.99777C7.14307 8.47115 7.52682 8.85491 8.00021 8.85491Z"
                stroke="#9E9EB0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.85714 8.85491C3.33053 8.85491 3.71429 8.47115 3.71429 7.99777C3.71429 7.52438 3.33053 7.14062 2.85714 7.14062C2.38376 7.14062 2 7.52438 2 7.99777C2 8.47115 2.38376 8.85491 2.85714 8.85491Z"
                stroke="#9E9EB0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.1428 8.85491C13.6162 8.85491 13.9999 8.47115 13.9999 7.99777C13.9999 7.52438 13.6162 7.14062 13.1428 7.14062C12.6694 7.14062 12.2856 7.52438 12.2856 7.99777C12.2856 8.47115 12.6694 8.85491 13.1428 8.85491Z"
                stroke="#9E9EB0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
