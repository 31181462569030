import IconLogo from "assets/ChatAssets/SVG/iconLogo";
import React from "react";
import CashbackNotificationIcon from "assets/NotificationsAssets/PNG/CashbackNotificationIcon.png";
import TimeNotificationIcon from "assets/NotificationsAssets/SVG/TimeNotificationIcon";
import ResponseNotificationIcon from "assets/NotificationsAssets/PNG/ResponseNotificationIcon.png";
import StartNotificationIcon from "assets/NotificationsAssets/PNG/StartNotificationIcon.png";
import RescheduleNotificationIcon from "assets/NotificationsAssets/PNG/RescheduleNotificationIcon.png";
import CancelNotificationIcon from "assets/NotificationsAssets/PNG/CancelNotificationIcon.png";
import PaidNotificationIcon from "assets/NotificationsAssets/PNG/PaidNotifications.png";
import * as themes from "./NotificationTypesThemes";

export const NOTIFICATION_TYPES = {
    personal: <IconLogo />,
    cashback: (
        <img
            style={themes.Icon}
            src={CashbackNotificationIcon}
            alt=""
        />
    ),
    rescheduleRequest: <TimeNotificationIcon />,
    response: (
        <img
            style={themes.Icon}
            src={ResponseNotificationIcon}
            alt=""
        />
    ),
    startLesson: (
        <img
            style={themes.Icon}
            src={StartNotificationIcon}
            alt=""
        />
    ),
    rescheduled: (
        <img
            style={themes.Icon}
            src={RescheduleNotificationIcon}
            alt=""
        />
    ),
    cancelLesson: (
        <img
            style={themes.Icon}
            src={CancelNotificationIcon}
            alt=""
        />
    ),
    newOrder: (
        <img
            style={themes.Icon}
            src={ResponseNotificationIcon}
            alt=""
        />
    ),
    paid: (
        <img
            style={themes.Icon}
            src={PaidNotificationIcon}
            alt=""
        />
    ),
};
