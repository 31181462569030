import { createTheme } from "@mui/material/styles";

export const stickyVideoBlockContainerStyle = {
    position: "sticky",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    gap: 20,
    top: 0,
    marginTop: 20,
    marginRight: 112,
    width: "289px",
    height: "fit-content",
};

export const avatarAreaStyle = {
    width: "289px",
    height: "170px",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const AvatarTheme = createTheme({
    components: {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    position: "relative",
                    width: "289px",
                    height: "170px",
                    left: "0px",
                    top: "0px",
                    borderRadius: "20px",
                    cursor: "default",
                },
            },
        },
    },
});

export const playIconBGStyle = {
    position: "relative",
    width: "40px",
    height: "40px",
    left: "16px",
    bottom: "56px",
    borderRadius: "50%",
    background: "rgba(255, 255, 255, 0.5)",
    cursor: "pointer",
};

export const actionsAreaStyle = {
    width: "289px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    flexShrink: 0,
    gap: 20,
};

export const priceContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    gap: "10px",
    width: "165px",
    height: "32px",
    left: "0px",
    top: "5px",
};

export const textContainerStyle = {
    display: "flex",
    gap: "5px",
};

export const fromTextStyle = {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#9E9EB0",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const fromTextValueStyle = {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#222222",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const AddCashbackButtonTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "5px",
                    width: "52px",
                    height: "32px",
                    padding: "5px 14px 6px 12px!important",
                    background: "linear-gradient(256.9deg, #04C83B 20.58%, #12AAFF 81.82%)",
                    borderRadius: "32px!important",
                    flex: "none",
                    minWidth: "0px!important",
                    order: 1,
                    flexGrow: 0,
                },
            },
        },

        MuiTooltip: {
            styleOverrides: {
                root: {},
                tooltip: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    padding: "10px",
                    boxSizing: "border-box",
                    /*gap: '10px',*/
                    /*width: '244px',*/
                    /*height: '74px',*/
                    marginLeft: "6px!important",
                    background: "#FFFFFF",
                    borderRadius: "10px",
                    flex: "none",
                    order: 1,
                    flexGrow: 1,
                    filter: "drop-shadow(0px 2px 10px rgba(106, 99, 118, 0.15))",
                },
                arrow: {
                    color: "white!important",
                    filter: "drop-shadow(0px 2px 10px rgba(106, 99, 118, 0.15))",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                },
            },
        },
    },
});

export const tooltipCashbackStyle = {
    width: "214px",
    /*maxWidth: '214px!important',*/
    /*height: '54px',*/
    fontWtyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "140%",
    display: "flex",
    alignItems: "center",
    color: "#222222!important",
    flex: "none",
    order: 0,
    flexGrow: 1,
};

export const tooltipCashbackValueStyle = {
    color: "#19B20C",
};

export const cashbackTextStyle = {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const buttonsContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
    gap: "16px",
    width: "286px",
    right: "0px",
    top: "0px",
};

export const MessageButtonTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "11px 24px 12px 24px!important",
                    gap: "10px",
                    width: "100%",
                    height: "47px",
                    border: "1px solid #6212FF",
                    borderRadius: "42px!important",
                    flex: "none",
                    order: 1,
                    flexGrow: 0,
                    "&:hover": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                        ".MuiTypography-root": {
                            color: "white!important",
                        },
                    },
                    "&:active": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                        ".MuiTypography-root": {
                            color: "white!important",
                        },
                    },
                },
            },
        },
    },
});

export const messageTextStyle = {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    color: "#6212FF",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const OrderButtonTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "11px 24px 12px 24px!important",
                    gap: "10px",
                    width: "100%",
                    height: "47px",
                    background: "#6212FF",
                    borderRadius: "42px!important",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                    "&:hover": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                    },
                    "&:active": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                    },
                },
            },
        },
    },
});

export const orderTextStyle = {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    color: "#FFFFFF",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const textAdvieceStyle = {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    color: "var(--text-grey, #9E9EB0)",
    flex: "none",
    order: 2,
    flexGrow: 0,
};
