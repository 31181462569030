import { Rating, styled } from "@mui/material";

const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
        color: "#6212FF",
    },
    "& .MuiRating-iconHover": {
        transform: "scale(1)",
    },
});

export default StyledRating;
