import React, { memo } from "react";
import * as themes from "./LeftCardBlockStyle";
import { observer } from "mobx-react-lite";
import MainPageStore from "store/MainPageStore";
import { useMedias } from "API/mediasHook";
import Clock from "../RightCardBlock/StateOne/clock.png";
import { useTranslation } from "react-i18next";
import { Typography, Box } from "@mui/material";

const LeftCardBlock = observer(({ children, ...props }) => {
    const toggleActiveItem = (value) => {
        MainPageStore.SetActiveHowWorkItem(value, "manual");
    };

    const { t, i18n } = useTranslation();

    const medias = useMedias();

    return (
        <Box style={themes.cardBlock(medias)}>
            <Box
                style={MainPageStore.activeHowWorkItem === 0 ? themes.cardTop(true, medias) : themes.cardTop(false, medias)}
                onClick={() => toggleActiveItem(0)}
            >
                <Typography style={themes.cardTitle}>{t("promoPages.main.goToCatalog")}</Typography>
                <Typography style={themes.cardText}>{t("promoPages.main.forSearchTutors")}</Typography>
            </Box>

            <Box
                style={MainPageStore.activeHowWorkItem === 1 ? themes.cardMid(true, medias) : themes.cardMid(false, medias)}
                onClick={() => toggleActiveItem(1)}
            >
                <Typography style={themes.cardTitle}>{t("promoPages.main.chooseTutor")}</Typography>
                <Typography style={themes.cardText}>{t("promoPages.main.goodAlgorithm")}</Typography>
            </Box>

            <Box
                style={MainPageStore.activeHowWorkItem === 2 ? themes.cardBottom(true, medias) : themes.cardBottom(false, medias)}
                onClick={() => toggleActiveItem(2)}
            >
                <Typography style={themes.cardTitle}>{t("promoPages.main.startClasses")}</Typography>
                <Typography style={themes.cardText}>{t("promoPages.main.studyOnPlatform")}</Typography>
            </Box>

            {!medias.sm && (
                <Box style={themes.bonusIcon}>
                    <Box style={themes.bonusIconText}>+35</Box>
                </Box>
            )}

            {!medias.sm && (
                <Box style={themes.clockIconContainer}>
                    <img
                        style={themes.clockIconImg}
                        src={Clock}
                    />
                </Box>
            )}

            {!medias.sm && (
                <Box style={themes.rateIcon}>
                    <Box style={themes.rateIconText}>5.0</Box>
                </Box>
            )}
        </Box>
    );
});

export default memo(LeftCardBlock);
