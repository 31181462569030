import React, { memo } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import OrdersResponsesBodyBlock from "components/OrdersResponsesBodyBlock/OrdersResponsesBodyBlock";
import Box from "@mui/material/Box";

const theme = createTheme({
    spacing: 1,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const OrdersResponsesPage = ({ props }) => {
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ background: "#F3F3F3", display: "flex", flexDirection: "column", alignItems: "center", borderRadius: "0px 0px 40px 40px" }}>
                <OrdersResponsesBodyBlock></OrdersResponsesBodyBlock>
            </Box>
        </ThemeProvider>
    );
};

export default memo(OrdersResponsesPage);
