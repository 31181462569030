import { styleProcessing } from "API/plugins/Utilities";

export const TeacherStepper = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
        gap: "122px",
        maxWidth: "1216px",
        minHeight: "716px",
        padding: "0",
        border: "none",
        marginTop: "60px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            marginTop: "0px",
            padding: "8px 16px 10px 16px",
            boxSizing: "border-box",
            gap: "30px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherStepperMain = (theme) => {
    let commonStyle = {
        width: "186px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherStepperHeader = {
    marginBottom: "10px",
    whiteSpace: "nowrap",
};

export const TeacherStepIndicator = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "6px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "row",
            overflowX: "scroll",
            "&::-webkit-scrollbar": {
                display: "none",
            },
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherStepperSxBRules = {
    borderTop: "1px dashed #bbbbc8",
    borderBottom: "1px dashed #bbbbc8",
    padding: "15px 0",
    marginTop: "15px",
};

export const NoWrapText = { whiteSpace: "nowrap" };

export const styles = {
    teacherQStepActive: {
        color: "#6212ff",
        fontSize: "13px",
        fontWeight: 400,
        lineHeight: "140%",
        cursor: "default",
        display: "flex",
        flexDirection: "row",
        gap: "6px",
        alignItems: "center",
    },
    teacherQStepPrev: {
        color: "#222",
        fontSize: "13px",
        fontWeight: 400,
        lineHeight: "140%",
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        gap: "6px",
        alignItems: "center",
    },
    teacherQStepNext: {
        color: "#9e9eb0",
        fontSize: "13px",
        fontWeight: 400,
        lineHeight: "140%",
        cursor: "default",
        display: "flex",
        flexDirection: "row",
        gap: "6px",
        alignItems: "center",
    },
    stepCircleActive: {
        height: "24px",
        width: "24px",
        borderRadius: "50%",
        border: "1px solid #6212ff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    stepCirclePrev: {
        height: "24px",
        width: "24px",
        borderRadius: "50%",
        border: "1px solid #222",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    stepCircleNext: {
        height: "24px",
        width: "24px",
        borderRadius: "50%",
        border: "1px solid #9e9eb0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
};
