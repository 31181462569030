import React, { memo } from "react";
import * as themes from "./CardTextThemes";
import NameBlock from "./NameBlock";
import TagsBlock from "./TagsBlock";
import Box from "@mui/material/Box";

const CardText = ({ children, ...props }) => {
    return (
        <Box sx={themes.cardTextContainerStyle}>
            <NameBlock value={props.value}></NameBlock>
            <TagsBlock value={props.value}></TagsBlock>
        </Box>
    );
};

export default memo(CardText);
