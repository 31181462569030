export const HeaderAccountAppBar = (auth, medias) => {
    return {
        width: "100%",
        height: "70px",
        background: "rgba(255, 255, 255, 0.40)",
        backdropFilter: "blur(10px)",
        borderRadius: 0,
        display: "flex",
        justifyContent: "center",
        position: "fixed",
        padding: "0!important",
        zIndex: "999",
        boxShadow: "none",
        borderBottom: auth === true ? "none" : "1px solid #BBBBC8",
        right: "auto",
    };
};

export const HeaderAccount = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 17px",
};

export const HeaderAccountContent = (medias) => {
    return {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: medias.sm ? "1216px" : "100%",
    };
};

export const LoginButton = {
    display: "flex",
    flexDirection: "column",
    textTransform: "none",
    ":hover": {
        background: "transparent",
    },
};

export const ZeroBox = {
    width: 0,
    height: 0,
};
