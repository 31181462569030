export const InfoForTutor = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    maxWidth: "350px",
};

export const Title = {
    maxWidth: "319px",
};

export const PointsContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const InfoPoint = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
};
