import React from "react";

const LinkArrow = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
        >
            <path
                d="M5 15.5L15 5.5M15 5.5C15 5.5 8.51472 5.5 5 5.5M15 5.5V15.5"
                stroke={props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default LinkArrow;
