import { createTheme } from "@mui/material/styles";

export const iconContainerStyle = (mode) => {
    let background = "";

    switch (Number(mode)) {
        case 0:
            background = "#6212FF0C";
            break;
        case 1:
            background = "#6212FF";
            break;
        case 2:
            background = "var(--Secondary-Red, #E12626)";
            break;
        default:
            break;
    }

    return {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 40,
        height: 40,
        borderRadius: "50%",
        background: background,
        cursor: "pointer",
        ":hover": {
            background: mode === 2 ? "var(--Secondary-Red, #E12626)" : "#6212FF",
        },
    };
};

export const ActionButtonTheme = (mode, state) => {
    let fillColor = "";

    if (mode === 0) {
        if (Number(state) === 1) {
            fillColor = "var(--main-white, #FFF)";
        } else {
            fillColor = "#6212FF";
        }
    }

    if (mode === 1) {
        if (Number(state) === 1) {
            fillColor = "var(--main-white, #FFF)";
        } else {
            fillColor = "#6212FF";
        }
    }

    if (mode === 2) {
        fillColor = "var(--main-white, #FFF)";
    }

    return createTheme({
        components: {
            MuiIconButton: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        "*": {
                            fill: fillColor,
                        },
                        "&:hover *": {
                            fill: "var(--main-white, #FFF)",
                        },
                    },
                },
            },

            MuiTooltip: {
                styleOverrides: {
                    root: {},
                    tooltip: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        padding: "3px 10px 4px 10px",
                        boxSizing: "border-box",
                        marginBottom: "5px!important",
                        background: "var(--BG-Footer30, rgba(23, 15, 38, 0.30))",
                        borderRadius: "42px",
                        backdropFilter: "blur(3.4033873081207275px)",
                    },
                },
            },
        },
    });
};

export const tooltipStyle = {
    color: "var(--Text-White, #FFF)",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
};

export const MoreActionsMenuTheme = createTheme({
    components: {
        MuiMenu: {
            styleOverrides: {
                // Name of the slot
                root: {},
                paper: {
                    marginTop: -15,
                    borderRadius: "10px!important",
                    ".MuiList-root": {
                        display: "flex",
                        width: "max-content",
                        padding: "7px 4px",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    },
                    ".MuiMenuItem-root": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        alignSelf: "stretch",
                        gap: 10,
                        padding: "5px 12px",
                        "&:hover": {
                            borderRadius: "10px!important",
                            background: "rgba(187, 187, 200, 0.17)!important",
                            "& *": {
                                color: "#6212FF!important",
                            },
                        },
                    },
                },
            },
        },
    },
});

export const actionTextStyle = {
    color: "var(--Text-Black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    "&:hover": {
        color: "#6212FF!important",
    },
};
