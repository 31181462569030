import axios from "axios";
import ResponseService from "./ResponseService";
import UserStore from "store/UserStore";
import { getCookie } from "API/utils";

class RequestService {
    METHOD_GET = "GET";
    METHOD_POST = "POST";
    METHOD_PUT = "PUT";
    METHOD_DELETE = "DELETE";
    METHOD_PATCH = "PATCH";

    headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };

    responseType = "json";

    async sendAsync(url, method = this.METHOD_GET, data = {}, withCredentials = false) {
        return axios(this.getRequestOptions(url, method, data, withCredentials));
    }

    getRequestOptions(url, method = this.METHOD_GET, data = {}, withCredentials = false) {
        if (withCredentials) {
            this._getHeaders();
        }

        return {
            url,
            method,
            data,
            params: method === this.METHOD_GET ? data : null,
            headers: this.headers,
            responseType: this.responseType,
            withCredentials: withCredentials,
        };
    }

    /**
     * Получить заголовки для отправки на сервер
     * @return {{}}
     * @private
     */
    _getHeaders() {
        this._addTokenToHeader(this.headers);
    }

    /**
     * Добавить токен в заголовки
     * @param {{}} headers
     * @private
     */
    _addTokenToHeader(headers) {
        if (UserStore.role === "tutor") {
            if (getCookie("tutorAccessToken") !== null) {
                headers["Authorization"] = "Bearer " + getCookie("tutorAccessToken");
            }
        }

        if (UserStore.role === "student") {
            if (getCookie("studentAccessToken") !== null) {
                headers["Authorization"] = "Bearer " + getCookie("studentAccessToken");
            }
        }
    }

    /**
     * Установить заголовок
     * @param key
     * @param type
     */
    setHeader(key, type) {
        this.headers[key] = type;
    }

    /**
     * Установить тип ответа
     * @param type
     */
    setResponseType(type) {
        this.responseType = type;
    }
}

export default RequestService;
