import { MoreHorizOutlined } from "@mui/icons-material";
import MenuListComposition from "components/UI/PopperMenu";
import React from "react";
import { memo } from "react";
import { observer } from "mobx-react-lite";
import * as styles from "./ClassMoreMenuThemes";
import SButton from "components/UI/SButton";
import ClassMoreMenuContent from "./ClassMoreMenuContent/ClassMoreMenuContent";
import { Box, IconButton, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CloseSnackBarIcon } from "assets/MyClassesAssets/SVG/CloseSnackBarIcon";
import Fade from "@mui/material/Fade";
import { useMedias } from "API/mediasHook";

const ClassMoreMenu = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const [openMore, setOpenMore] = React.useState(false);

    const [openSnackBar, setOpenSnackBar] = React.useState(false);

    return (
        <Box>
            <MenuListComposition
                placement="bottom-start"
                open={openMore}
                setOpen={setOpenMore}
                content={
                    <ClassMoreMenuContent
                        setOpen={setOpenMore}
                        lesson={props.lesson}
                        setOpenSnackBar={setOpenSnackBar}
                    />
                }
                zIndex={1}
                paperSx={[styles.Paper, !medias.sm && styles.MenuListCompositionMobilePos]}
            >
                <SButton variant="icon">
                    <MoreHorizOutlined />
                </SButton>
            </MenuListComposition>
            <Snackbar
                ContentProps={styles.ContentProps}
                TransitionComponent={Fade}
                autoHideDuration={5000}
                open={openSnackBar}
                onClose={() => setOpenSnackBar(false)}
                message={t("main.myClasses.rescheduleRequestSent")}
                action={
                    <IconButton
                        disableRipple
                        onClick={() => setOpenSnackBar(false)}
                    >
                        <CloseSnackBarIcon />
                    </IconButton>
                }
            />
        </Box>
    );
});

export default memo(ClassMoreMenu);
