import React, { useEffect } from "react";
import useWebSocket from "react-use-websocket";

import ChatField from "./ChatField/ChatField";
import Files from "./Files/Files";
import Details from "./Details/Details";
import DiplomaStore from "../store/DiplomaStore";
import { set } from "components/ChatBodyBlock/CommonData/helper_functions";
import { observer } from "mobx-react-lite";

const LeftPanel = ({ tab }) => {
    const URL = "wss://" + process.env.REACT_APP_API_URL + "/chats/ws";

    const { sendMessage, sendJsonMessage, lastMessage, lastJsonMessage, readyState } = useWebSocket(URL, {
        share: true,
    });

    const auth = {
        id: 1,
        token: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MjQxNjQ0MTcsInJvbGUiOiJzdHVkZW50Iiwic3ViIjoiYmEzMDZjNDUtMGIxYi00MGViLWFlM2QtNmUwZWRiY2ZhMDIyIn0.H5M9M-G0BdSYCkmCrJJOWhQoPqGKzaai27Y04iccvnSd53HRjoZKNI-Cihp-i0Wcgeu-ydvvYnVKO1Qz57v39gI00-ilAjmws5xkvPiz-Ll07EhcfTtJg1eexSd3soyGNKsw-icMpgDGd8itr1BGwwt1sMCtPF4roADKlUM6ezs",
    };

    set("token", auth.token);

    useEffect(() => {
        DiplomaStore.ProcessMessages(sendMessage, sendJsonMessage, lastMessage, lastJsonMessage);
    }, [lastMessage, lastJsonMessage]);

    if (tab === "ChatBodyBlock") {
        return <ChatField sendJsonMessage={sendJsonMessage} />;
    } else if (tab === "Files") {
        return <Files />;
    } else {
        return <Details />;
    }
};

export default observer(LeftPanel);
