import { Typography, Box } from "@mui/material";
import React, { memo } from "react";
import * as themes from "./OrderStatusRoundedThemes";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";

const statusConfig = {
    appliedForWork: "orders.tutorOrders.appliedForWork",
    new: "orders.tutorOrders.new",
    inProgress: "orders.tutorOrders.inProgress",
    waitingCheck: "orders.tutorOrders.waitingForCheck",
    completed: "orders.tutorOrders.completed",
    cancelled: "orders.tutorOrders.cancelled",
};

const OrderStatusRounded = ({ children, status, ...props }) => {
    const { t } = useTranslation();

    const translationKey = statusConfig[status];

    if (!translationKey) return null;

    return (
        <Box sx={themes.StatusBox(status)}>
            <Typography
                variant="span"
                sx={themes.BullPoint(status)}
            >
                &bull;
            </Typography>
            <Typography sx={textThemes.Body2Black}>{t(translationKey)}</Typography>
        </Box>
    );
};

export default memo(OrderStatusRounded);
