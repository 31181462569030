import React, { memo, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Chip, IconButton, Menu, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import * as themes from "./ChipBlockThemes";
import TeacherStore from "store/TeacherStore";
import { observer } from "mobx-react-lite";
import MenuItem from "@mui/material/MenuItem";
import { CheckSharp, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { actionTextStyle } from "./ChipBlockThemes";

const ChipBlock = observer(({ children, ...props }) => {
    const handleClick = async (value, event) => {
        TeacherStore.SetActiveChip(value);
        TeacherStore.SetChipFilter();
        await TeacherStore.FetchMoreReviewsData("reset");
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleMenuToggle = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = async (value, event) => {
        if (value !== null) {
            TeacherStore.SetActiveChip(value);
            TeacherStore.SetChipFilter();
            await TeacherStore.FetchMoreReviewsData("reset");
        }

        setAnchorEl(null);
    };

    const chipInMenuList = () => {
        return [...props.value.Disciplines, ...props.value.Tags]
            .map((value, index) => {
                return { ID: index, Query: value?.Query ? value.Query : value.discipline };
            })
            .slice(6)
            .find((value) => TeacherStore.activeChip.ID === value.ID);
    };

    return (
        <Box style={themes.tagsContainerStyle}>
            <ThemeProvider theme={TeacherStore.activeChip.ID === null ? themes.ActiveChipTheme : themes.InactiveChipTheme}>
                <Chip
                    label={"Все"}
                    disableRipple
                    onClick={handleClick.bind(this, { ID: null, Query: "" })}
                />
            </ThemeProvider>
            {[...props.value.Disciplines, ...props.value.Tags]
                .map((value, index) => {
                    return { ID: index, Query: value?.Query ? value.Query : value.discipline };
                })
                .map((value, index) =>
                    index <= 5 ? (
                        <ThemeProvider
                            key={value.ID}
                            theme={TeacherStore.activeChip.ID === value.ID ? themes.ActiveChipTheme : themes.InactiveChipTheme}
                        >
                            <Chip
                                label={value.Query}
                                disableRipple
                                onClick={handleClick.bind(this, value)}
                            />
                        </ThemeProvider>
                    ) : null,
                )}
            {[...props.value.Disciplines, ...props.value.Tags].length > 6 ? (
                <Box>
                    <ThemeProvider
                        theme={themes.MoreChipTheme(props.value.Tags.find((value, index) => (value.ID === TeacherStore.activeChip.ID || TeacherStore.activeChip.ID === null) && index <= 5))}
                    >
                        <Chip
                            label={TeacherStore.activeChip.Query && chipInMenuList() ? TeacherStore.activeChip.Query : "ещё"}
                            disableRipple
                            deleteIcon={
                                <IconButton
                                    disableRipple
                                    onClick={handleMenuToggle}
                                >
                                    {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                </IconButton>
                            }
                            onDelete={handleMenuToggle}
                            onClick={handleMenuToggle}
                        />
                    </ThemeProvider>
                    <ThemeProvider theme={themes.MenuTheme}>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose.bind(this, null)}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            {[...props.value.Disciplines, ...props.value.Tags]
                                .map((value, index) => {
                                    return { ID: index, Query: value?.Query ? value.Query : value.discipline };
                                })
                                .map((value, index) =>
                                    index > 5 ? (
                                        <MenuItem
                                            key={value.ID}
                                            onClick={handleMenuClose.bind(this, value)}
                                            disableRipple
                                            selected={TeacherStore.activeChip.ID === value.ID}
                                        >
                                            <Typography sx={themes.actionTextStyle}>{value.Query}</Typography>
                                            <Box sx={themes.IconContainerStyle}>
                                                <ThemeProvider theme={themes.IconCheckTheme}>{TeacherStore.activeChip.ID === value.ID ? <CheckSharp /> : null}</ThemeProvider>
                                            </Box>
                                        </MenuItem>
                                    ) : null,
                                )}
                        </Menu>
                    </ThemeProvider>
                </Box>
            ) : null}
        </Box>
    );
});

export default memo(ChipBlock);
