import React, { useState } from "react";

import { Box, CircularProgress, ThemeProvider, Typography } from "@mui/material";

import MessagesBox from "./MessagesBox";
import SendMessageBox from "components/ChatBodyBlock/GridFieldChat/BotomFieldChat/SendMessageBox";
import DiplomaStore from "../../store/DiplomaStore";

import * as styles from "./styles";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import * as themes from "components/ChatBodyBlock/ChatBodyBlockThemes";
import * as textStyles from "themes/TextThemes";
import { observer } from "mobx-react-lite";
import ReplyOrEditInfo from "components/ChatBodyBlock/GridFieldChat/ReplyOrEditInfo/ReplyOrEditInfo";

const ChatField = ({ sendJsonMessage }) => {
    const [openEditModalMessage, SetOpenEditModalMessage] = useState(false);

    const [openReplyModalMessage, SetOpenReplyModalMessage] = useState(false);

    const [disable, SetDisable] = useState(false);

    const [text, SetText] = useState("");

    return (
        <ScrollToTop>
            <Box sx={styles.chatBox}>
                {DiplomaStore.tabsData.messages ? (
                    <>
                        <MessagesBox
                            SetText={SetText}
                            SetDisable={SetDisable}
                            sendJsonMessage={sendJsonMessage}
                            openReplyModalMessage={openReplyModalMessage}
                            SetOpenReplyModalMessage={SetOpenReplyModalMessage}
                            openEditModalMessage={openEditModalMessage}
                            SetOpenEditModalMessage={SetOpenEditModalMessage}
                            disable={disable}
                        />
                        {(openEditModalMessage || openReplyModalMessage) && (
                            <ReplyOrEditInfo
                                isDiploma={true}
                                handleOpenEditModalMessage={SetOpenEditModalMessage}
                                handleOpenReplyModalMessage={SetOpenReplyModalMessage}
                                handleText={SetText}
                                handleDisable={SetDisable}
                            />
                        )}
                        <SendMessageBox
                            sendJsonMessage={sendJsonMessage}
                            isDiploma={true}
                            disable={disable}
                            setDisable={SetDisable}
                            text={text}
                            setText={SetText}
                            openReplyModalMessage={openReplyModalMessage}
                            setOpenReplyModalMessage={SetOpenReplyModalMessage}
                            openEditModalMessage={openEditModalMessage}
                            setOpenEditModalMessage={SetOpenEditModalMessage}
                        />
                    </>
                ) : (
                    <Box sx={themes.loadingContainerStyle}>
                        <Typography sx={textStyles.HeadlineH5Black}>Чат загружается...</Typography>
                        <ThemeProvider theme={themes.CircularProgressTheme}>
                            <CircularProgress />
                        </ThemeProvider>
                    </Box>
                )}
            </Box>
        </ScrollToTop>
    );
};

export default observer(ChatField);
