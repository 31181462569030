const RefEmptyIcon = function (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
        >
            <g clipPath="url(#clip0_267_20254)">
                <path
                    d="M23.1401 36.8757L23.1443 41.4655H6.67285V36.8757L14.9088 35.834L23.1401 36.8757Z"
                    fill="#6212FF"
                />
                <path
                    d="M23.1401 33.3267L23.1443 36.875H6.67285V33.3267L14.9088 32.2852L23.1401 33.3267Z"
                    fill="#F0F5F7"
                />
                <path
                    d="M2.62949 3.79041C2.92852 1.60066 4.81113 -0.0241426 7.021 0.0002715L7.14727 0.00154103C9.6084 0.0293731 11.5638 2.0782 11.478 4.53806L11.496 12.0168L12.2864 13.4038L14.5527 15.199L23.1406 23.9754V33.3272H6.67314L0 22.6944L2.62949 3.79041Z"
                    fill="#FFD2A7"
                />
                <path
                    d="M11.7538 15.2253C10.9433 16.0043 10.9173 17.2979 11.6959 18.1085L17.27 23.9114L15.1574 25.9408L9.58334 20.1379C7.6851 18.1623 7.74887 15.0105 9.72445 13.1124C10.4592 12.4066 11.4964 12.0176 11.4964 12.0176L14.5978 15.2463C13.815 14.4738 12.5515 14.4594 11.7538 15.2253Z"
                    fill="#FAB073"
                />
                <path
                    d="M26.8597 36.8757L26.8555 41.4655H43.327V36.8757L35.091 35.834L26.8597 36.8757Z"
                    fill="#6212FF"
                />
                <path
                    d="M26.8597 33.3267L26.8555 36.875H43.327V33.3267L35.091 32.2852L26.8597 33.3267Z"
                    fill="#D9E7EC"
                />
                <path
                    d="M47.3702 3.79041C47.0712 1.60066 45.189 -0.0241426 42.9787 0.0002715L42.8525 0.00154103C40.3916 0.0293731 38.4358 2.0782 38.5217 4.53797L38.5037 12.0167L37.7133 13.4037L35.4474 15.1989L26.8594 23.9753V33.3271H43.3267L49.9997 22.6944L47.3702 3.79041Z"
                    fill="#FFC186"
                />
                <path
                    d="M38.2466 15.2253C39.0571 16.0043 39.0831 17.2979 38.3045 18.1085L32.7305 23.9114L34.8431 25.9408L40.4171 20.1379C42.3153 18.1623 42.2521 15.0105 40.276 13.1124C39.5412 12.4066 38.504 12.0176 38.504 12.0176L35.403 15.2463C36.1854 14.4738 37.4489 14.4594 38.2466 15.2253Z"
                    fill="#F09B59"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.1109 0C21.6653 0 23.1265 0.605006 24.2255 1.70393L25.0001 2.47893V2.48L25.0003 2.47893L25.7754 1.70441C26.8743 0.605486 28.3355 0 29.8895 0C31.4438 0 32.9051 0.605486 34.004 1.70441C36.2726 3.97303 36.2726 7.66449 34.004 9.93311L25.0003 18.9368L25.0001 18.9356V18.9363L15.9967 9.93263C13.7276 7.66401 13.7276 3.97255 15.9967 1.70393C17.0957 0.605006 18.5569 0 20.1109 0Z"
                    fill="#6212FF"
                />
            </g>
            <defs>
                <clipPath id="clip0_267_20254">
                    <rect
                        width="50"
                        height="50"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default RefEmptyIcon;
