import { memo } from "react";
import { observer } from "mobx-react-lite";
import { Navigate, useLocation } from "react-router-dom";
import { globalMiddlewares } from "../router/Middlewares";

const Middleware = observer(({ children, ...props }) => {
    let location = useLocation();

    let redirectTo = props.redirectTo ? props.redirectTo : "/";

    const includedMiddlewares = props.include || [];

    const excludedMiddlewares = props.exclude || [];

    const defineMiddlewareList = () => {
        let result;

        //console.log(1, globalMiddlewares, includedMiddlewares, excludedMiddlewares);

        // eslint-disable-next-line no-undef
        result = [...new Set(globalMiddlewares.concat(includedMiddlewares))].filter((value) => !excludedMiddlewares.includes(value));
        //console.log(2, result);

        return result;
    };

    const executeMiddlewares = () => {
        let result = true;

        for (let middleware of defineMiddlewareList()) {
            if (!middleware()) {
                result = false;
                break;
            }
        }

        //console.log(3, result);

        return result;
    };

    let allowed = executeMiddlewares();

    if (!allowed) {
        // Redirect them to some page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they log in, which is a nicer user experience
        // than dropping them off on the home page.
        return (
            <Navigate
                to={redirectTo}
                state={{ from: location }}
                replace
            />
        );
    }

    return children;
});

export default memo(Middleware);
