import React, { memo } from "react";
import { Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as themes from "./DescriptionCardThemes";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";
import { useMedias } from "../../../API/mediasHook";

const DescriptionCard = observer(({ children, ...props }) => {
    const [state, SetState] = React.useState(false);

    const Toggle = () => {
        SetState(!state);
    };

    const medias = useMedias();

    return (
        <Card
            sx={themes.descriptionCardStyle(medias)}
            id={"DescriptionCard"}
        >
            <Box sx={state ? themes.descriptionFullContainerStyle(medias) : themes.descriptionCutContainerStyle(medias)}>
                <Typography sx={themes.aboutTextStyle}>О репетиторе</Typography>
                <Typography sx={themes.descriptionTextStyle(state)}>{props.value.DescriptionFull}</Typography>
            </Box>
            <Typography
                sx={themes.showMoreTextStyle}
                onClick={Toggle}
            >
                {state ? "Показать меньше" : "Показать больше"}
            </Typography>
        </Card>
    );
});

export default memo(DescriptionCard);
