import React from "react";
import { Box, Typography } from "@mui/material";
import * as themes from "./CashbackDefFuncThemes";
import * as textThemes from "themes/TextThemes";
import { memo } from "react";

const CashbackDefFunc = ({ children, ...props }) => {
    return (
        <Box sx={Object.assign({}, props.theme, themes.CashbackDefFuncBlock)}>
            <Box>{props.functionIcon}</Box>
            <Typography sx={{ ...textThemes.Body1Black, ...themes.CashbackFuncText }}>{props.functionText}</Typography>
        </Box>
    );
};

export default memo(CashbackDefFunc);
