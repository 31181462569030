import { useEffect, useState } from "react";

function throttle(func, delay) {
    let timer;

    return function (...args) {
        if (!timer) {
            timer = setTimeout(() => {
                func.apply(this, args);
                timer = null;
            }, delay);
        }
    };
}

export function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
            });
        }

        const throttledResize = throttle(handleResize, 100);

        window.addEventListener("resize", throttledResize);

        return () => {
            window.removeEventListener("resize", throttledResize);
        };
    }, [windowSize]);

    return windowSize.width - 44;
}
