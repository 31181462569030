import { Box } from "@mui/material";
import React from "react";
import OrdersModal from "components/UI/OrdersModal/OrdersModal";
import { observer } from "mobx-react-lite";
import OrderResponseRadioSelect from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_OrderResponseRadioSelect/OrderResponseRadioSelect";
import OrderResponseButton from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseButton/OrderResponseButton";
import OrderModalTitle from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_OrderModalTitle/OrderModalTitle";
import orderResponseStore from "store/OrderResponseStore";
import OrderModalSubtitle from "components/OrderResponse/_OrderModalSubtitle/OrderModalSubtitle";
import ComplainTextField from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_ComplainTextField/ComplainTextField";
import { useState } from "react";
import * as themes from "./CompainModalStyled";
const list = [
    { label: "Мошенничество или обмен контактными данными", value: "fraud" },
    { label: "Неуместное или оскорбительное поведение", value: "behavior" },
    { label: "Спам", value: "spam" },
    { label: "Другое", value: "other" },
];

const CompainModal = observer(() => {
    const [otherValue, setOtherValue] = useState("");

    const [isOther, setIsother] = useState("");

    const handleClick = () => {
        orderResponseStore.setTutorStatus("complained", orderResponseStore.id);
        orderResponseStore.setToggleModal();
        // orderResponseStore.setIsRejected(true);
        // orderResponseStore.setSortStatusResponseTutors();
        orderResponseStore.setIsDismissClicked(false);
        orderResponseStore.setIsComplainClicked(false);
        setTimeout(() => {
            orderResponseStore.setIsDisabled(true);
        }, 100);
    };

    const handleClearIsDisabledStore = () => {
        orderResponseStore.setIsDisabled(true);
        orderResponseStore.setIsDismissClicked(false);
        orderResponseStore.setIsComplainClicked(false);
    };

    return (
        <OrdersModal handleClick={handleClearIsDisabledStore}>
            <Box>
                <Box sx={themes.CompainModalWrapper}>
                    <OrderModalTitle>Пожаловаться на отклик</OrderModalTitle>
                    <OrderModalSubtitle>Выберите, пожалуйста, причину жалобы или, если нет подходящего варианта, напишите своими словами</OrderModalSubtitle>
                </Box>
                <Box>
                    <OrderResponseRadioSelect
                        list={list}
                        setIsother={setIsother}
                    />
                    {isOther === "other" && (
                        <ComplainTextField
                            otherValue={otherValue}
                            setOtherValue={setOtherValue}
                        />
                    )}
                    <Box sx={themes.CompainModalButtonWrapper}>
                        <OrderResponseButton
                            text="Отправить жалобу"
                            variant="contained"
                            handleClick={handleClick}
                            disabled={orderResponseStore.isDisabled}
                        />
                    </Box>
                </Box>
            </Box>
        </OrdersModal>
    );
});

export default CompainModal;
