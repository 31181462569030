export const Avatar = {
    width: "60px",
    height: "60px",
};

export const RadioGroup = {
    gap: "10px",
};

export const ButtonsContainer = {
    display: "flex",
    gap: "12px",
    width: "fit-content",
};

export const TopContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "center",
};

export const ReasonWhyQuestion = {
    textAlign: "center",
};

export const ReasonWhyDelete = {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    alignItems: "center",
    marginTop: "10px",
    marginBottom: "30px",
};

export const FormControl = {
    display: "flex",
    alignItems: "flex-start",
    gap: "12px",
    margin: 0,
};

export const Radio = {
    margin: 0,
    padding: "4px 0 0 0",
};
