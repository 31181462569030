// source: internal/push/pb/notifier.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require("google-protobuf");

var goog = jspb;

var global = Function("return this")();

goog.exportSymbol("proto.pb.CancelLesson", null, global);
goog.exportSymbol("proto.pb.Cashback", null, global);
goog.exportSymbol("proto.pb.Creator", null, global);
goog.exportSymbol("proto.pb.Empty", null, global);
goog.exportSymbol("proto.pb.NewOrder", null, global);
goog.exportSymbol("proto.pb.Notification", null, global);
goog.exportSymbol("proto.pb.Notification.DataCase", null, global);
goog.exportSymbol("proto.pb.Order", null, global);
goog.exportSymbol("proto.pb.Paid", null, global);
goog.exportSymbol("proto.pb.Personal", null, global);
goog.exportSymbol("proto.pb.Photo", null, global);
goog.exportSymbol("proto.pb.Postponement", null, global);
goog.exportSymbol("proto.pb.ReadNotificationsRequest", null, global);
goog.exportSymbol("proto.pb.ReadNotificationsResponse", null, global);
goog.exportSymbol("proto.pb.RecentNotificationsResponse", null, global);
goog.exportSymbol("proto.pb.RequestToReschedule", null, global);
goog.exportSymbol("proto.pb.Rescheduled", null, global);
goog.exportSymbol("proto.pb.Responses", null, global);
goog.exportSymbol("proto.pb.SeenOrder", null, global);
goog.exportSymbol("proto.pb.SingleResponse", null, global);
goog.exportSymbol("proto.pb.StartLesson", null, global);
goog.exportSymbol("proto.pb.StreamNewNotificationsResponse", null, global);
goog.exportSymbol("proto.pb.TimeReschedule", null, global);
goog.exportSymbol("proto.pb.TimeStartEnd", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.StreamNewNotificationsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.StreamNewNotificationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.StreamNewNotificationsResponse.displayName = "proto.pb.StreamNewNotificationsResponse";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Notification = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, proto.pb.Notification.oneofGroups_);
};

goog.inherits(proto.pb.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Notification.displayName = "proto.pb.Notification";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.SingleResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.SingleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.SingleResponse.displayName = "proto.pb.SingleResponse";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Personal = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Personal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Personal.displayName = "proto.pb.Personal";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Cashback = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Cashback, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Cashback.displayName = "proto.pb.Cashback";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.RequestToReschedule = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.RequestToReschedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.RequestToReschedule.displayName = "proto.pb.RequestToReschedule";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Rescheduled = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Rescheduled, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Rescheduled.displayName = "proto.pb.Rescheduled";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.StartLesson = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.StartLesson, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.StartLesson.displayName = "proto.pb.StartLesson";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.CancelLesson = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.CancelLesson, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.CancelLesson.displayName = "proto.pb.CancelLesson";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Responses = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Responses, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Responses.displayName = "proto.pb.Responses";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.SeenOrder = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.SeenOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.SeenOrder.displayName = "proto.pb.SeenOrder";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.NewOrder = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.NewOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.NewOrder.displayName = "proto.pb.NewOrder";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Paid = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Paid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Paid.displayName = "proto.pb.Paid";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Order = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Order, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Order.displayName = "proto.pb.Order";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Creator = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Creator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Creator.displayName = "proto.pb.Creator";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Photo = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Photo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Photo.displayName = "proto.pb.Photo";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.TimeReschedule = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.TimeReschedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.TimeReschedule.displayName = "proto.pb.TimeReschedule";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.TimeStartEnd = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.TimeStartEnd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.TimeStartEnd.displayName = "proto.pb.TimeStartEnd";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Postponement = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Postponement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Postponement.displayName = "proto.pb.Postponement";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Empty = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Empty.displayName = "proto.pb.Empty";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.RecentNotificationsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.RecentNotificationsResponse.repeatedFields_, null);
};

goog.inherits(proto.pb.RecentNotificationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.RecentNotificationsResponse.displayName = "proto.pb.RecentNotificationsResponse";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.ReadNotificationsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.ReadNotificationsRequest.repeatedFields_, null);
};

goog.inherits(proto.pb.ReadNotificationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.ReadNotificationsRequest.displayName = "proto.pb.ReadNotificationsRequest";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.ReadNotificationsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.ReadNotificationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.ReadNotificationsResponse.displayName = "proto.pb.ReadNotificationsResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.StreamNewNotificationsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.StreamNewNotificationsResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.StreamNewNotificationsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.StreamNewNotificationsResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                notification: (f = msg.getNotification()) && proto.pb.Notification.toObject(includeInstance, f),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.StreamNewNotificationsResponse}
 */
proto.pb.StreamNewNotificationsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.StreamNewNotificationsResponse();

    return proto.pb.StreamNewNotificationsResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.StreamNewNotificationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.StreamNewNotificationsResponse}
 */
proto.pb.StreamNewNotificationsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = new proto.pb.Notification();

                reader.readMessage(value, proto.pb.Notification.deserializeBinaryFromReader);
                msg.setNotification(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.StreamNewNotificationsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.StreamNewNotificationsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.StreamNewNotificationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.StreamNewNotificationsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getNotification();
    if (f != null) {
        writer.writeMessage(1, f, proto.pb.Notification.serializeBinaryToWriter);
    }
};

/**
 * optional Notification notification = 1;
 * @return {?proto.pb.Notification}
 */
proto.pb.StreamNewNotificationsResponse.prototype.getNotification = function () {
    return /** @type{?proto.pb.Notification} */ (jspb.Message.getWrapperField(this, proto.pb.Notification, 1));
};

/**
 * @param {?proto.pb.Notification|undefined} value
 * @return {!proto.pb.StreamNewNotificationsResponse} returns this
 */
proto.pb.StreamNewNotificationsResponse.prototype.setNotification = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.StreamNewNotificationsResponse} returns this
 */
proto.pb.StreamNewNotificationsResponse.prototype.clearNotification = function () {
    return this.setNotification(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.StreamNewNotificationsResponse.prototype.hasNotification = function () {
    return jspb.Message.getField(this, 1) != null;
};

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.pb.Notification.oneofGroups_ = [[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]];

/**
 * @enum {number}
 */
proto.pb.Notification.DataCase = {
    DATA_NOT_SET: 0,
    SINGLE_RESPONSE: 1,
    PERSONAL: 2,
    CASHBACK: 3,
    REQUEST_TO_RESCHEDULE: 4,
    RESCHEDULED: 5,
    START_LESSON: 6,
    CANCEL_LESSON: 7,
    RESPONSES: 8,
    SEEN_ORDER: 9,
    NEW_ORDER: 10,
    PAID: 11,
};

/**
 * @return {proto.pb.Notification.DataCase}
 */
proto.pb.Notification.prototype.getDataCase = function () {
    return /** @type {proto.pb.Notification.DataCase} */ (jspb.Message.computeOneofCase(this, proto.pb.Notification.oneofGroups_[0]));
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Notification.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Notification.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Notification} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Notification.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                singleResponse: (f = msg.getSingleResponse()) && proto.pb.SingleResponse.toObject(includeInstance, f),
                personal: (f = msg.getPersonal()) && proto.pb.Personal.toObject(includeInstance, f),
                cashback: (f = msg.getCashback()) && proto.pb.Cashback.toObject(includeInstance, f),
                requestToReschedule: (f = msg.getRequestToReschedule()) && proto.pb.RequestToReschedule.toObject(includeInstance, f),
                rescheduled: (f = msg.getRescheduled()) && proto.pb.Rescheduled.toObject(includeInstance, f),
                startLesson: (f = msg.getStartLesson()) && proto.pb.StartLesson.toObject(includeInstance, f),
                cancelLesson: (f = msg.getCancelLesson()) && proto.pb.CancelLesson.toObject(includeInstance, f),
                responses: (f = msg.getResponses()) && proto.pb.Responses.toObject(includeInstance, f),
                seenOrder: (f = msg.getSeenOrder()) && proto.pb.SeenOrder.toObject(includeInstance, f),
                newOrder: (f = msg.getNewOrder()) && proto.pb.NewOrder.toObject(includeInstance, f),
                paid: (f = msg.getPaid()) && proto.pb.Paid.toObject(includeInstance, f),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Notification}
 */
proto.pb.Notification.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Notification();

    return proto.pb.Notification.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Notification}
 */
proto.pb.Notification.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = new proto.pb.SingleResponse();

                reader.readMessage(value, proto.pb.SingleResponse.deserializeBinaryFromReader);
                msg.setSingleResponse(value);
                break;
            case 2:
                var value = new proto.pb.Personal();

                reader.readMessage(value, proto.pb.Personal.deserializeBinaryFromReader);
                msg.setPersonal(value);
                break;
            case 3:
                var value = new proto.pb.Cashback();

                reader.readMessage(value, proto.pb.Cashback.deserializeBinaryFromReader);
                msg.setCashback(value);
                break;
            case 4:
                var value = new proto.pb.RequestToReschedule();

                reader.readMessage(value, proto.pb.RequestToReschedule.deserializeBinaryFromReader);
                msg.setRequestToReschedule(value);
                break;
            case 5:
                var value = new proto.pb.Rescheduled();

                reader.readMessage(value, proto.pb.Rescheduled.deserializeBinaryFromReader);
                msg.setRescheduled(value);
                break;
            case 6:
                var value = new proto.pb.StartLesson();

                reader.readMessage(value, proto.pb.StartLesson.deserializeBinaryFromReader);
                msg.setStartLesson(value);
                break;
            case 7:
                var value = new proto.pb.CancelLesson();

                reader.readMessage(value, proto.pb.CancelLesson.deserializeBinaryFromReader);
                msg.setCancelLesson(value);
                break;
            case 8:
                var value = new proto.pb.Responses();

                reader.readMessage(value, proto.pb.Responses.deserializeBinaryFromReader);
                msg.setResponses(value);
                break;
            case 9:
                var value = new proto.pb.SeenOrder();

                reader.readMessage(value, proto.pb.SeenOrder.deserializeBinaryFromReader);
                msg.setSeenOrder(value);
                break;
            case 10:
                var value = new proto.pb.NewOrder();

                reader.readMessage(value, proto.pb.NewOrder.deserializeBinaryFromReader);
                msg.setNewOrder(value);
                break;
            case 11:
                var value = new proto.pb.Paid();

                reader.readMessage(value, proto.pb.Paid.deserializeBinaryFromReader);
                msg.setPaid(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Notification.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Notification.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Notification.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getSingleResponse();
    if (f != null) {
        writer.writeMessage(1, f, proto.pb.SingleResponse.serializeBinaryToWriter);
    }

    f = message.getPersonal();
    if (f != null) {
        writer.writeMessage(2, f, proto.pb.Personal.serializeBinaryToWriter);
    }

    f = message.getCashback();
    if (f != null) {
        writer.writeMessage(3, f, proto.pb.Cashback.serializeBinaryToWriter);
    }

    f = message.getRequestToReschedule();
    if (f != null) {
        writer.writeMessage(4, f, proto.pb.RequestToReschedule.serializeBinaryToWriter);
    }

    f = message.getRescheduled();
    if (f != null) {
        writer.writeMessage(5, f, proto.pb.Rescheduled.serializeBinaryToWriter);
    }

    f = message.getStartLesson();
    if (f != null) {
        writer.writeMessage(6, f, proto.pb.StartLesson.serializeBinaryToWriter);
    }

    f = message.getCancelLesson();
    if (f != null) {
        writer.writeMessage(7, f, proto.pb.CancelLesson.serializeBinaryToWriter);
    }

    f = message.getResponses();
    if (f != null) {
        writer.writeMessage(8, f, proto.pb.Responses.serializeBinaryToWriter);
    }

    f = message.getSeenOrder();
    if (f != null) {
        writer.writeMessage(9, f, proto.pb.SeenOrder.serializeBinaryToWriter);
    }

    f = message.getNewOrder();
    if (f != null) {
        writer.writeMessage(10, f, proto.pb.NewOrder.serializeBinaryToWriter);
    }

    f = message.getPaid();
    if (f != null) {
        writer.writeMessage(11, f, proto.pb.Paid.serializeBinaryToWriter);
    }
};

/**
 * optional SingleResponse single_response = 1;
 * @return {?proto.pb.SingleResponse}
 */
proto.pb.Notification.prototype.getSingleResponse = function () {
    return /** @type{?proto.pb.SingleResponse} */ (jspb.Message.getWrapperField(this, proto.pb.SingleResponse, 1));
};

/**
 * @param {?proto.pb.SingleResponse|undefined} value
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.setSingleResponse = function (value) {
    return jspb.Message.setOneofWrapperField(this, 1, proto.pb.Notification.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.clearSingleResponse = function () {
    return this.setSingleResponse(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Notification.prototype.hasSingleResponse = function () {
    return jspb.Message.getField(this, 1) != null;
};

/**
 * optional Personal personal = 2;
 * @return {?proto.pb.Personal}
 */
proto.pb.Notification.prototype.getPersonal = function () {
    return /** @type{?proto.pb.Personal} */ (jspb.Message.getWrapperField(this, proto.pb.Personal, 2));
};

/**
 * @param {?proto.pb.Personal|undefined} value
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.setPersonal = function (value) {
    return jspb.Message.setOneofWrapperField(this, 2, proto.pb.Notification.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.clearPersonal = function () {
    return this.setPersonal(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Notification.prototype.hasPersonal = function () {
    return jspb.Message.getField(this, 2) != null;
};

/**
 * optional Cashback cashback = 3;
 * @return {?proto.pb.Cashback}
 */
proto.pb.Notification.prototype.getCashback = function () {
    return /** @type{?proto.pb.Cashback} */ (jspb.Message.getWrapperField(this, proto.pb.Cashback, 3));
};

/**
 * @param {?proto.pb.Cashback|undefined} value
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.setCashback = function (value) {
    return jspb.Message.setOneofWrapperField(this, 3, proto.pb.Notification.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.clearCashback = function () {
    return this.setCashback(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Notification.prototype.hasCashback = function () {
    return jspb.Message.getField(this, 3) != null;
};

/**
 * optional RequestToReschedule request_to_reschedule = 4;
 * @return {?proto.pb.RequestToReschedule}
 */
proto.pb.Notification.prototype.getRequestToReschedule = function () {
    return /** @type{?proto.pb.RequestToReschedule} */ (jspb.Message.getWrapperField(this, proto.pb.RequestToReschedule, 4));
};

/**
 * @param {?proto.pb.RequestToReschedule|undefined} value
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.setRequestToReschedule = function (value) {
    return jspb.Message.setOneofWrapperField(this, 4, proto.pb.Notification.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.clearRequestToReschedule = function () {
    return this.setRequestToReschedule(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Notification.prototype.hasRequestToReschedule = function () {
    return jspb.Message.getField(this, 4) != null;
};

/**
 * optional Rescheduled rescheduled = 5;
 * @return {?proto.pb.Rescheduled}
 */
proto.pb.Notification.prototype.getRescheduled = function () {
    return /** @type{?proto.pb.Rescheduled} */ (jspb.Message.getWrapperField(this, proto.pb.Rescheduled, 5));
};

/**
 * @param {?proto.pb.Rescheduled|undefined} value
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.setRescheduled = function (value) {
    return jspb.Message.setOneofWrapperField(this, 5, proto.pb.Notification.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.clearRescheduled = function () {
    return this.setRescheduled(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Notification.prototype.hasRescheduled = function () {
    return jspb.Message.getField(this, 5) != null;
};

/**
 * optional StartLesson start_lesson = 6;
 * @return {?proto.pb.StartLesson}
 */
proto.pb.Notification.prototype.getStartLesson = function () {
    return /** @type{?proto.pb.StartLesson} */ (jspb.Message.getWrapperField(this, proto.pb.StartLesson, 6));
};

/**
 * @param {?proto.pb.StartLesson|undefined} value
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.setStartLesson = function (value) {
    return jspb.Message.setOneofWrapperField(this, 6, proto.pb.Notification.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.clearStartLesson = function () {
    return this.setStartLesson(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Notification.prototype.hasStartLesson = function () {
    return jspb.Message.getField(this, 6) != null;
};

/**
 * optional CancelLesson cancel_lesson = 7;
 * @return {?proto.pb.CancelLesson}
 */
proto.pb.Notification.prototype.getCancelLesson = function () {
    return /** @type{?proto.pb.CancelLesson} */ (jspb.Message.getWrapperField(this, proto.pb.CancelLesson, 7));
};

/**
 * @param {?proto.pb.CancelLesson|undefined} value
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.setCancelLesson = function (value) {
    return jspb.Message.setOneofWrapperField(this, 7, proto.pb.Notification.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.clearCancelLesson = function () {
    return this.setCancelLesson(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Notification.prototype.hasCancelLesson = function () {
    return jspb.Message.getField(this, 7) != null;
};

/**
 * optional Responses responses = 8;
 * @return {?proto.pb.Responses}
 */
proto.pb.Notification.prototype.getResponses = function () {
    return /** @type{?proto.pb.Responses} */ (jspb.Message.getWrapperField(this, proto.pb.Responses, 8));
};

/**
 * @param {?proto.pb.Responses|undefined} value
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.setResponses = function (value) {
    return jspb.Message.setOneofWrapperField(this, 8, proto.pb.Notification.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.clearResponses = function () {
    return this.setResponses(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Notification.prototype.hasResponses = function () {
    return jspb.Message.getField(this, 8) != null;
};

/**
 * optional SeenOrder seen_order = 9;
 * @return {?proto.pb.SeenOrder}
 */
proto.pb.Notification.prototype.getSeenOrder = function () {
    return /** @type{?proto.pb.SeenOrder} */ (jspb.Message.getWrapperField(this, proto.pb.SeenOrder, 9));
};

/**
 * @param {?proto.pb.SeenOrder|undefined} value
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.setSeenOrder = function (value) {
    return jspb.Message.setOneofWrapperField(this, 9, proto.pb.Notification.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.clearSeenOrder = function () {
    return this.setSeenOrder(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Notification.prototype.hasSeenOrder = function () {
    return jspb.Message.getField(this, 9) != null;
};

/**
 * optional NewOrder new_order = 10;
 * @return {?proto.pb.NewOrder}
 */
proto.pb.Notification.prototype.getNewOrder = function () {
    return /** @type{?proto.pb.NewOrder} */ (jspb.Message.getWrapperField(this, proto.pb.NewOrder, 10));
};

/**
 * @param {?proto.pb.NewOrder|undefined} value
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.setNewOrder = function (value) {
    return jspb.Message.setOneofWrapperField(this, 10, proto.pb.Notification.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.clearNewOrder = function () {
    return this.setNewOrder(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Notification.prototype.hasNewOrder = function () {
    return jspb.Message.getField(this, 10) != null;
};

/**
 * optional Paid paid = 11;
 * @return {?proto.pb.Paid}
 */
proto.pb.Notification.prototype.getPaid = function () {
    return /** @type{?proto.pb.Paid} */ (jspb.Message.getWrapperField(this, proto.pb.Paid, 11));
};

/**
 * @param {?proto.pb.Paid|undefined} value
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.setPaid = function (value) {
    return jspb.Message.setOneofWrapperField(this, 11, proto.pb.Notification.oneofGroups_[0], value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Notification} returns this
 */
proto.pb.Notification.prototype.clearPaid = function () {
    return this.setPaid(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Notification.prototype.hasPaid = function () {
    return jspb.Message.getField(this, 11) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.SingleResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.SingleResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.SingleResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.SingleResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                notificationUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                type: jspb.Message.getFieldWithDefault(msg, 2, ""),
                creator: (f = msg.getCreator()) && proto.pb.Creator.toObject(includeInstance, f),
                order: (f = msg.getOrder()) && proto.pb.Order.toObject(includeInstance, f),
                read: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
                createdAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
                category: jspb.Message.getFieldWithDefault(msg, 7, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.SingleResponse}
 */
proto.pb.SingleResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.SingleResponse();

    return proto.pb.SingleResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.SingleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.SingleResponse}
 */
proto.pb.SingleResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setNotificationUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setType(value);
                break;
            case 3:
                var value = new proto.pb.Creator();

                reader.readMessage(value, proto.pb.Creator.deserializeBinaryFromReader);
                msg.setCreator(value);
                break;
            case 4:
                var value = new proto.pb.Order();

                reader.readMessage(value, proto.pb.Order.deserializeBinaryFromReader);
                msg.setOrder(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setRead(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());

                msg.setCreatedAt(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());

                msg.setCategory(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.SingleResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.SingleResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.SingleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.SingleResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getNotificationUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getType();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getCreator();
    if (f != null) {
        writer.writeMessage(3, f, proto.pb.Creator.serializeBinaryToWriter);
    }

    f = message.getOrder();
    if (f != null) {
        writer.writeMessage(4, f, proto.pb.Order.serializeBinaryToWriter);
    }

    f = message.getRead();
    if (f) {
        writer.writeBool(5, f);
    }

    f = message.getCreatedAt();
    if (f.length > 0) {
        writer.writeString(6, f);
    }

    f = message.getCategory();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
};

/**
 * optional string notification_uuid = 1;
 * @return {string}
 */
proto.pb.SingleResponse.prototype.getNotificationUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.SingleResponse} returns this
 */
proto.pb.SingleResponse.prototype.setNotificationUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string type = 2;
 * @return {string}
 */
proto.pb.SingleResponse.prototype.getType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.SingleResponse} returns this
 */
proto.pb.SingleResponse.prototype.setType = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional Creator creator = 3;
 * @return {?proto.pb.Creator}
 */
proto.pb.SingleResponse.prototype.getCreator = function () {
    return /** @type{?proto.pb.Creator} */ (jspb.Message.getWrapperField(this, proto.pb.Creator, 3));
};

/**
 * @param {?proto.pb.Creator|undefined} value
 * @return {!proto.pb.SingleResponse} returns this
 */
proto.pb.SingleResponse.prototype.setCreator = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.SingleResponse} returns this
 */
proto.pb.SingleResponse.prototype.clearCreator = function () {
    return this.setCreator(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.SingleResponse.prototype.hasCreator = function () {
    return jspb.Message.getField(this, 3) != null;
};

/**
 * optional Order order = 4;
 * @return {?proto.pb.Order}
 */
proto.pb.SingleResponse.prototype.getOrder = function () {
    return /** @type{?proto.pb.Order} */ (jspb.Message.getWrapperField(this, proto.pb.Order, 4));
};

/**
 * @param {?proto.pb.Order|undefined} value
 * @return {!proto.pb.SingleResponse} returns this
 */
proto.pb.SingleResponse.prototype.setOrder = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.SingleResponse} returns this
 */
proto.pb.SingleResponse.prototype.clearOrder = function () {
    return this.setOrder(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.SingleResponse.prototype.hasOrder = function () {
    return jspb.Message.getField(this, 4) != null;
};

/**
 * optional bool read = 5;
 * @return {boolean}
 */
proto.pb.SingleResponse.prototype.getRead = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.SingleResponse} returns this
 */
proto.pb.SingleResponse.prototype.setRead = function (value) {
    return jspb.Message.setProto3BooleanField(this, 5, value);
};

/**
 * optional string created_at = 6;
 * @return {string}
 */
proto.pb.SingleResponse.prototype.getCreatedAt = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.SingleResponse} returns this
 */
proto.pb.SingleResponse.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional string category = 7;
 * @return {string}
 */
proto.pb.SingleResponse.prototype.getCategory = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.SingleResponse} returns this
 */
proto.pb.SingleResponse.prototype.setCategory = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Personal.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Personal.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Personal} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Personal.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                notificationUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                type: jspb.Message.getFieldWithDefault(msg, 2, ""),
                creator: (f = msg.getCreator()) && proto.pb.Creator.toObject(includeInstance, f),
                order: (f = msg.getOrder()) && proto.pb.Order.toObject(includeInstance, f),
                actionType: jspb.Message.getFieldWithDefault(msg, 5, ""),
                read: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
                createdAt: jspb.Message.getFieldWithDefault(msg, 7, ""),
                category: jspb.Message.getFieldWithDefault(msg, 8, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Personal}
 */
proto.pb.Personal.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Personal();

    return proto.pb.Personal.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Personal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Personal}
 */
proto.pb.Personal.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setNotificationUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setType(value);
                break;
            case 3:
                var value = new proto.pb.Creator();

                reader.readMessage(value, proto.pb.Creator.deserializeBinaryFromReader);
                msg.setCreator(value);
                break;
            case 4:
                var value = new proto.pb.Order();

                reader.readMessage(value, proto.pb.Order.deserializeBinaryFromReader);
                msg.setOrder(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());

                msg.setActionType(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setRead(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());

                msg.setCreatedAt(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());

                msg.setCategory(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Personal.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Personal.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Personal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Personal.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getNotificationUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getType();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getCreator();
    if (f != null) {
        writer.writeMessage(3, f, proto.pb.Creator.serializeBinaryToWriter);
    }

    f = message.getOrder();
    if (f != null) {
        writer.writeMessage(4, f, proto.pb.Order.serializeBinaryToWriter);
    }

    f = message.getActionType();
    if (f.length > 0) {
        writer.writeString(5, f);
    }

    f = message.getRead();
    if (f) {
        writer.writeBool(6, f);
    }

    f = message.getCreatedAt();
    if (f.length > 0) {
        writer.writeString(7, f);
    }

    f = message.getCategory();
    if (f.length > 0) {
        writer.writeString(8, f);
    }
};

/**
 * optional string notification_uuid = 1;
 * @return {string}
 */
proto.pb.Personal.prototype.getNotificationUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Personal} returns this
 */
proto.pb.Personal.prototype.setNotificationUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string type = 2;
 * @return {string}
 */
proto.pb.Personal.prototype.getType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Personal} returns this
 */
proto.pb.Personal.prototype.setType = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional Creator creator = 3;
 * @return {?proto.pb.Creator}
 */
proto.pb.Personal.prototype.getCreator = function () {
    return /** @type{?proto.pb.Creator} */ (jspb.Message.getWrapperField(this, proto.pb.Creator, 3));
};

/**
 * @param {?proto.pb.Creator|undefined} value
 * @return {!proto.pb.Personal} returns this
 */
proto.pb.Personal.prototype.setCreator = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Personal} returns this
 */
proto.pb.Personal.prototype.clearCreator = function () {
    return this.setCreator(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Personal.prototype.hasCreator = function () {
    return jspb.Message.getField(this, 3) != null;
};

/**
 * optional Order order = 4;
 * @return {?proto.pb.Order}
 */
proto.pb.Personal.prototype.getOrder = function () {
    return /** @type{?proto.pb.Order} */ (jspb.Message.getWrapperField(this, proto.pb.Order, 4));
};

/**
 * @param {?proto.pb.Order|undefined} value
 * @return {!proto.pb.Personal} returns this
 */
proto.pb.Personal.prototype.setOrder = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Personal} returns this
 */
proto.pb.Personal.prototype.clearOrder = function () {
    return this.setOrder(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Personal.prototype.hasOrder = function () {
    return jspb.Message.getField(this, 4) != null;
};

/**
 * optional string action_type = 5;
 * @return {string}
 */
proto.pb.Personal.prototype.getActionType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Personal} returns this
 */
proto.pb.Personal.prototype.setActionType = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional bool read = 6;
 * @return {boolean}
 */
proto.pb.Personal.prototype.getRead = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.Personal} returns this
 */
proto.pb.Personal.prototype.setRead = function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
};

/**
 * optional string created_at = 7;
 * @return {string}
 */
proto.pb.Personal.prototype.getCreatedAt = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Personal} returns this
 */
proto.pb.Personal.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional string category = 8;
 * @return {string}
 */
proto.pb.Personal.prototype.getCategory = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Personal} returns this
 */
proto.pb.Personal.prototype.setCategory = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Cashback.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Cashback.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Cashback} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Cashback.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                notificationUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                type: jspb.Message.getFieldWithDefault(msg, 2, ""),
                order: (f = msg.getOrder()) && proto.pb.Order.toObject(includeInstance, f),
                value: jspb.Message.getFieldWithDefault(msg, 4, 0),
                read: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
                createdAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
                category: jspb.Message.getFieldWithDefault(msg, 7, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Cashback}
 */
proto.pb.Cashback.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Cashback();

    return proto.pb.Cashback.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Cashback} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Cashback}
 */
proto.pb.Cashback.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setNotificationUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setType(value);
                break;
            case 3:
                var value = new proto.pb.Order();

                reader.readMessage(value, proto.pb.Order.deserializeBinaryFromReader);
                msg.setOrder(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setValue(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setRead(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());

                msg.setCreatedAt(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());

                msg.setCategory(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Cashback.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Cashback.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Cashback} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Cashback.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getNotificationUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getType();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getOrder();
    if (f != null) {
        writer.writeMessage(3, f, proto.pb.Order.serializeBinaryToWriter);
    }

    f = message.getValue();
    if (f !== 0) {
        writer.writeInt32(4, f);
    }

    f = message.getRead();
    if (f) {
        writer.writeBool(5, f);
    }

    f = message.getCreatedAt();
    if (f.length > 0) {
        writer.writeString(6, f);
    }

    f = message.getCategory();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
};

/**
 * optional string notification_uuid = 1;
 * @return {string}
 */
proto.pb.Cashback.prototype.getNotificationUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Cashback} returns this
 */
proto.pb.Cashback.prototype.setNotificationUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string type = 2;
 * @return {string}
 */
proto.pb.Cashback.prototype.getType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Cashback} returns this
 */
proto.pb.Cashback.prototype.setType = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional Order order = 3;
 * @return {?proto.pb.Order}
 */
proto.pb.Cashback.prototype.getOrder = function () {
    return /** @type{?proto.pb.Order} */ (jspb.Message.getWrapperField(this, proto.pb.Order, 3));
};

/**
 * @param {?proto.pb.Order|undefined} value
 * @return {!proto.pb.Cashback} returns this
 */
proto.pb.Cashback.prototype.setOrder = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Cashback} returns this
 */
proto.pb.Cashback.prototype.clearOrder = function () {
    return this.setOrder(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Cashback.prototype.hasOrder = function () {
    return jspb.Message.getField(this, 3) != null;
};

/**
 * optional int32 value = 4;
 * @return {number}
 */
proto.pb.Cashback.prototype.getValue = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.Cashback} returns this
 */
proto.pb.Cashback.prototype.setValue = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional bool read = 5;
 * @return {boolean}
 */
proto.pb.Cashback.prototype.getRead = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.Cashback} returns this
 */
proto.pb.Cashback.prototype.setRead = function (value) {
    return jspb.Message.setProto3BooleanField(this, 5, value);
};

/**
 * optional string created_at = 6;
 * @return {string}
 */
proto.pb.Cashback.prototype.getCreatedAt = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Cashback} returns this
 */
proto.pb.Cashback.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional string category = 7;
 * @return {string}
 */
proto.pb.Cashback.prototype.getCategory = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Cashback} returns this
 */
proto.pb.Cashback.prototype.setCategory = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.RequestToReschedule.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.RequestToReschedule.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.RequestToReschedule} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.RequestToReschedule.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                notificationUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                type: jspb.Message.getFieldWithDefault(msg, 2, ""),
                creator: (f = msg.getCreator()) && proto.pb.Creator.toObject(includeInstance, f),
                order: (f = msg.getOrder()) && proto.pb.Order.toObject(includeInstance, f),
                read: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
                time: (f = msg.getTime()) && proto.pb.TimeReschedule.toObject(includeInstance, f),
                postponementDecision: (f = msg.getPostponementDecision()) && proto.pb.Postponement.toObject(includeInstance, f),
                createdAt: jspb.Message.getFieldWithDefault(msg, 8, ""),
                category: jspb.Message.getFieldWithDefault(msg, 9, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.RequestToReschedule}
 */
proto.pb.RequestToReschedule.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.RequestToReschedule();

    return proto.pb.RequestToReschedule.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.RequestToReschedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.RequestToReschedule}
 */
proto.pb.RequestToReschedule.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setNotificationUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setType(value);
                break;
            case 3:
                var value = new proto.pb.Creator();

                reader.readMessage(value, proto.pb.Creator.deserializeBinaryFromReader);
                msg.setCreator(value);
                break;
            case 4:
                var value = new proto.pb.Order();

                reader.readMessage(value, proto.pb.Order.deserializeBinaryFromReader);
                msg.setOrder(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setRead(value);
                break;
            case 6:
                var value = new proto.pb.TimeReschedule();

                reader.readMessage(value, proto.pb.TimeReschedule.deserializeBinaryFromReader);
                msg.setTime(value);
                break;
            case 7:
                var value = new proto.pb.Postponement();

                reader.readMessage(value, proto.pb.Postponement.deserializeBinaryFromReader);
                msg.setPostponementDecision(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());

                msg.setCreatedAt(value);
                break;
            case 9:
                var value = /** @type {string} */ (reader.readString());

                msg.setCategory(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.RequestToReschedule.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.RequestToReschedule.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.RequestToReschedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RequestToReschedule.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getNotificationUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getType();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getCreator();
    if (f != null) {
        writer.writeMessage(3, f, proto.pb.Creator.serializeBinaryToWriter);
    }

    f = message.getOrder();
    if (f != null) {
        writer.writeMessage(4, f, proto.pb.Order.serializeBinaryToWriter);
    }

    f = message.getRead();
    if (f) {
        writer.writeBool(5, f);
    }

    f = message.getTime();
    if (f != null) {
        writer.writeMessage(6, f, proto.pb.TimeReschedule.serializeBinaryToWriter);
    }

    f = message.getPostponementDecision();
    if (f != null) {
        writer.writeMessage(7, f, proto.pb.Postponement.serializeBinaryToWriter);
    }

    f = message.getCreatedAt();
    if (f.length > 0) {
        writer.writeString(8, f);
    }

    f = message.getCategory();
    if (f.length > 0) {
        writer.writeString(9, f);
    }
};

/**
 * optional string notification_uuid = 1;
 * @return {string}
 */
proto.pb.RequestToReschedule.prototype.getNotificationUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.RequestToReschedule} returns this
 */
proto.pb.RequestToReschedule.prototype.setNotificationUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string type = 2;
 * @return {string}
 */
proto.pb.RequestToReschedule.prototype.getType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.RequestToReschedule} returns this
 */
proto.pb.RequestToReschedule.prototype.setType = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional Creator creator = 3;
 * @return {?proto.pb.Creator}
 */
proto.pb.RequestToReschedule.prototype.getCreator = function () {
    return /** @type{?proto.pb.Creator} */ (jspb.Message.getWrapperField(this, proto.pb.Creator, 3));
};

/**
 * @param {?proto.pb.Creator|undefined} value
 * @return {!proto.pb.RequestToReschedule} returns this
 */
proto.pb.RequestToReschedule.prototype.setCreator = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.RequestToReschedule} returns this
 */
proto.pb.RequestToReschedule.prototype.clearCreator = function () {
    return this.setCreator(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.RequestToReschedule.prototype.hasCreator = function () {
    return jspb.Message.getField(this, 3) != null;
};

/**
 * optional Order order = 4;
 * @return {?proto.pb.Order}
 */
proto.pb.RequestToReschedule.prototype.getOrder = function () {
    return /** @type{?proto.pb.Order} */ (jspb.Message.getWrapperField(this, proto.pb.Order, 4));
};

/**
 * @param {?proto.pb.Order|undefined} value
 * @return {!proto.pb.RequestToReschedule} returns this
 */
proto.pb.RequestToReschedule.prototype.setOrder = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.RequestToReschedule} returns this
 */
proto.pb.RequestToReschedule.prototype.clearOrder = function () {
    return this.setOrder(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.RequestToReschedule.prototype.hasOrder = function () {
    return jspb.Message.getField(this, 4) != null;
};

/**
 * optional bool read = 5;
 * @return {boolean}
 */
proto.pb.RequestToReschedule.prototype.getRead = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.RequestToReschedule} returns this
 */
proto.pb.RequestToReschedule.prototype.setRead = function (value) {
    return jspb.Message.setProto3BooleanField(this, 5, value);
};

/**
 * optional TimeReschedule time = 6;
 * @return {?proto.pb.TimeReschedule}
 */
proto.pb.RequestToReschedule.prototype.getTime = function () {
    return /** @type{?proto.pb.TimeReschedule} */ (jspb.Message.getWrapperField(this, proto.pb.TimeReschedule, 6));
};

/**
 * @param {?proto.pb.TimeReschedule|undefined} value
 * @return {!proto.pb.RequestToReschedule} returns this
 */
proto.pb.RequestToReschedule.prototype.setTime = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.RequestToReschedule} returns this
 */
proto.pb.RequestToReschedule.prototype.clearTime = function () {
    return this.setTime(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.RequestToReschedule.prototype.hasTime = function () {
    return jspb.Message.getField(this, 6) != null;
};

/**
 * optional Postponement postponement_decision = 7;
 * @return {?proto.pb.Postponement}
 */
proto.pb.RequestToReschedule.prototype.getPostponementDecision = function () {
    return /** @type{?proto.pb.Postponement} */ (jspb.Message.getWrapperField(this, proto.pb.Postponement, 7));
};

/**
 * @param {?proto.pb.Postponement|undefined} value
 * @return {!proto.pb.RequestToReschedule} returns this
 */
proto.pb.RequestToReschedule.prototype.setPostponementDecision = function (value) {
    return jspb.Message.setWrapperField(this, 7, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.RequestToReschedule} returns this
 */
proto.pb.RequestToReschedule.prototype.clearPostponementDecision = function () {
    return this.setPostponementDecision(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.RequestToReschedule.prototype.hasPostponementDecision = function () {
    return jspb.Message.getField(this, 7) != null;
};

/**
 * optional string created_at = 8;
 * @return {string}
 */
proto.pb.RequestToReschedule.prototype.getCreatedAt = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.RequestToReschedule} returns this
 */
proto.pb.RequestToReschedule.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * optional string category = 9;
 * @return {string}
 */
proto.pb.RequestToReschedule.prototype.getCategory = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.RequestToReschedule} returns this
 */
proto.pb.RequestToReschedule.prototype.setCategory = function (value) {
    return jspb.Message.setProto3StringField(this, 9, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Rescheduled.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Rescheduled.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Rescheduled} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Rescheduled.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                notificationUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                type: jspb.Message.getFieldWithDefault(msg, 2, ""),
                creator: (f = msg.getCreator()) && proto.pb.Creator.toObject(includeInstance, f),
                order: (f = msg.getOrder()) && proto.pb.Order.toObject(includeInstance, f),
                newLessonDate: jspb.Message.getFieldWithDefault(msg, 5, ""),
                read: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
                createdAt: jspb.Message.getFieldWithDefault(msg, 7, ""),
                category: jspb.Message.getFieldWithDefault(msg, 8, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Rescheduled}
 */
proto.pb.Rescheduled.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Rescheduled();

    return proto.pb.Rescheduled.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Rescheduled} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Rescheduled}
 */
proto.pb.Rescheduled.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setNotificationUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setType(value);
                break;
            case 3:
                var value = new proto.pb.Creator();

                reader.readMessage(value, proto.pb.Creator.deserializeBinaryFromReader);
                msg.setCreator(value);
                break;
            case 4:
                var value = new proto.pb.Order();

                reader.readMessage(value, proto.pb.Order.deserializeBinaryFromReader);
                msg.setOrder(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());

                msg.setNewLessonDate(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setRead(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());

                msg.setCreatedAt(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());

                msg.setCategory(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Rescheduled.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Rescheduled.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Rescheduled} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Rescheduled.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getNotificationUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getType();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getCreator();
    if (f != null) {
        writer.writeMessage(3, f, proto.pb.Creator.serializeBinaryToWriter);
    }

    f = message.getOrder();
    if (f != null) {
        writer.writeMessage(4, f, proto.pb.Order.serializeBinaryToWriter);
    }

    f = message.getNewLessonDate();
    if (f.length > 0) {
        writer.writeString(5, f);
    }

    f = message.getRead();
    if (f) {
        writer.writeBool(6, f);
    }

    f = message.getCreatedAt();
    if (f.length > 0) {
        writer.writeString(7, f);
    }

    f = message.getCategory();
    if (f.length > 0) {
        writer.writeString(8, f);
    }
};

/**
 * optional string notification_uuid = 1;
 * @return {string}
 */
proto.pb.Rescheduled.prototype.getNotificationUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Rescheduled} returns this
 */
proto.pb.Rescheduled.prototype.setNotificationUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string type = 2;
 * @return {string}
 */
proto.pb.Rescheduled.prototype.getType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Rescheduled} returns this
 */
proto.pb.Rescheduled.prototype.setType = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional Creator creator = 3;
 * @return {?proto.pb.Creator}
 */
proto.pb.Rescheduled.prototype.getCreator = function () {
    return /** @type{?proto.pb.Creator} */ (jspb.Message.getWrapperField(this, proto.pb.Creator, 3));
};

/**
 * @param {?proto.pb.Creator|undefined} value
 * @return {!proto.pb.Rescheduled} returns this
 */
proto.pb.Rescheduled.prototype.setCreator = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Rescheduled} returns this
 */
proto.pb.Rescheduled.prototype.clearCreator = function () {
    return this.setCreator(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Rescheduled.prototype.hasCreator = function () {
    return jspb.Message.getField(this, 3) != null;
};

/**
 * optional Order order = 4;
 * @return {?proto.pb.Order}
 */
proto.pb.Rescheduled.prototype.getOrder = function () {
    return /** @type{?proto.pb.Order} */ (jspb.Message.getWrapperField(this, proto.pb.Order, 4));
};

/**
 * @param {?proto.pb.Order|undefined} value
 * @return {!proto.pb.Rescheduled} returns this
 */
proto.pb.Rescheduled.prototype.setOrder = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Rescheduled} returns this
 */
proto.pb.Rescheduled.prototype.clearOrder = function () {
    return this.setOrder(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Rescheduled.prototype.hasOrder = function () {
    return jspb.Message.getField(this, 4) != null;
};

/**
 * optional string new_lesson_date = 5;
 * @return {string}
 */
proto.pb.Rescheduled.prototype.getNewLessonDate = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Rescheduled} returns this
 */
proto.pb.Rescheduled.prototype.setNewLessonDate = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional bool read = 6;
 * @return {boolean}
 */
proto.pb.Rescheduled.prototype.getRead = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.Rescheduled} returns this
 */
proto.pb.Rescheduled.prototype.setRead = function (value) {
    return jspb.Message.setProto3BooleanField(this, 6, value);
};

/**
 * optional string created_at = 7;
 * @return {string}
 */
proto.pb.Rescheduled.prototype.getCreatedAt = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Rescheduled} returns this
 */
proto.pb.Rescheduled.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional string category = 8;
 * @return {string}
 */
proto.pb.Rescheduled.prototype.getCategory = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Rescheduled} returns this
 */
proto.pb.Rescheduled.prototype.setCategory = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.StartLesson.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.StartLesson.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.StartLesson} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.StartLesson.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                notificationUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                type: jspb.Message.getFieldWithDefault(msg, 2, ""),
                order: (f = msg.getOrder()) && proto.pb.Order.toObject(includeInstance, f),
                read: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
                createdAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
                category: jspb.Message.getFieldWithDefault(msg, 7, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.StartLesson}
 */
proto.pb.StartLesson.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.StartLesson();

    return proto.pb.StartLesson.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.StartLesson} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.StartLesson}
 */
proto.pb.StartLesson.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setNotificationUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setType(value);
                break;
            case 4:
                var value = new proto.pb.Order();

                reader.readMessage(value, proto.pb.Order.deserializeBinaryFromReader);
                msg.setOrder(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setRead(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());

                msg.setCreatedAt(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());

                msg.setCategory(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.StartLesson.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.StartLesson.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.StartLesson} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.StartLesson.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getNotificationUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getType();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getOrder();
    if (f != null) {
        writer.writeMessage(4, f, proto.pb.Order.serializeBinaryToWriter);
    }

    f = message.getRead();
    if (f) {
        writer.writeBool(5, f);
    }

    f = message.getCreatedAt();
    if (f.length > 0) {
        writer.writeString(6, f);
    }

    f = message.getCategory();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
};

/**
 * optional string notification_uuid = 1;
 * @return {string}
 */
proto.pb.StartLesson.prototype.getNotificationUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.StartLesson} returns this
 */
proto.pb.StartLesson.prototype.setNotificationUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string type = 2;
 * @return {string}
 */
proto.pb.StartLesson.prototype.getType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.StartLesson} returns this
 */
proto.pb.StartLesson.prototype.setType = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional Order order = 4;
 * @return {?proto.pb.Order}
 */
proto.pb.StartLesson.prototype.getOrder = function () {
    return /** @type{?proto.pb.Order} */ (jspb.Message.getWrapperField(this, proto.pb.Order, 4));
};

/**
 * @param {?proto.pb.Order|undefined} value
 * @return {!proto.pb.StartLesson} returns this
 */
proto.pb.StartLesson.prototype.setOrder = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.StartLesson} returns this
 */
proto.pb.StartLesson.prototype.clearOrder = function () {
    return this.setOrder(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.StartLesson.prototype.hasOrder = function () {
    return jspb.Message.getField(this, 4) != null;
};

/**
 * optional bool read = 5;
 * @return {boolean}
 */
proto.pb.StartLesson.prototype.getRead = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.StartLesson} returns this
 */
proto.pb.StartLesson.prototype.setRead = function (value) {
    return jspb.Message.setProto3BooleanField(this, 5, value);
};

/**
 * optional string created_at = 6;
 * @return {string}
 */
proto.pb.StartLesson.prototype.getCreatedAt = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.StartLesson} returns this
 */
proto.pb.StartLesson.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional string category = 7;
 * @return {string}
 */
proto.pb.StartLesson.prototype.getCategory = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.StartLesson} returns this
 */
proto.pb.StartLesson.prototype.setCategory = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.CancelLesson.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.CancelLesson.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.CancelLesson} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.CancelLesson.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                notificationUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                type: jspb.Message.getFieldWithDefault(msg, 2, ""),
                creator: (f = msg.getCreator()) && proto.pb.Creator.toObject(includeInstance, f),
                order: (f = msg.getOrder()) && proto.pb.Order.toObject(includeInstance, f),
                read: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
                createdAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
                category: jspb.Message.getFieldWithDefault(msg, 7, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.CancelLesson}
 */
proto.pb.CancelLesson.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.CancelLesson();

    return proto.pb.CancelLesson.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.CancelLesson} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.CancelLesson}
 */
proto.pb.CancelLesson.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setNotificationUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setType(value);
                break;
            case 3:
                var value = new proto.pb.Creator();

                reader.readMessage(value, proto.pb.Creator.deserializeBinaryFromReader);
                msg.setCreator(value);
                break;
            case 4:
                var value = new proto.pb.Order();

                reader.readMessage(value, proto.pb.Order.deserializeBinaryFromReader);
                msg.setOrder(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setRead(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());

                msg.setCreatedAt(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());

                msg.setCategory(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.CancelLesson.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.CancelLesson.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.CancelLesson} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.CancelLesson.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getNotificationUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getType();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getCreator();
    if (f != null) {
        writer.writeMessage(3, f, proto.pb.Creator.serializeBinaryToWriter);
    }

    f = message.getOrder();
    if (f != null) {
        writer.writeMessage(4, f, proto.pb.Order.serializeBinaryToWriter);
    }

    f = message.getRead();
    if (f) {
        writer.writeBool(5, f);
    }

    f = message.getCreatedAt();
    if (f.length > 0) {
        writer.writeString(6, f);
    }

    f = message.getCategory();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
};

/**
 * optional string notification_uuid = 1;
 * @return {string}
 */
proto.pb.CancelLesson.prototype.getNotificationUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CancelLesson} returns this
 */
proto.pb.CancelLesson.prototype.setNotificationUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string type = 2;
 * @return {string}
 */
proto.pb.CancelLesson.prototype.getType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CancelLesson} returns this
 */
proto.pb.CancelLesson.prototype.setType = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional Creator creator = 3;
 * @return {?proto.pb.Creator}
 */
proto.pb.CancelLesson.prototype.getCreator = function () {
    return /** @type{?proto.pb.Creator} */ (jspb.Message.getWrapperField(this, proto.pb.Creator, 3));
};

/**
 * @param {?proto.pb.Creator|undefined} value
 * @return {!proto.pb.CancelLesson} returns this
 */
proto.pb.CancelLesson.prototype.setCreator = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.CancelLesson} returns this
 */
proto.pb.CancelLesson.prototype.clearCreator = function () {
    return this.setCreator(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.CancelLesson.prototype.hasCreator = function () {
    return jspb.Message.getField(this, 3) != null;
};

/**
 * optional Order order = 4;
 * @return {?proto.pb.Order}
 */
proto.pb.CancelLesson.prototype.getOrder = function () {
    return /** @type{?proto.pb.Order} */ (jspb.Message.getWrapperField(this, proto.pb.Order, 4));
};

/**
 * @param {?proto.pb.Order|undefined} value
 * @return {!proto.pb.CancelLesson} returns this
 */
proto.pb.CancelLesson.prototype.setOrder = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.CancelLesson} returns this
 */
proto.pb.CancelLesson.prototype.clearOrder = function () {
    return this.setOrder(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.CancelLesson.prototype.hasOrder = function () {
    return jspb.Message.getField(this, 4) != null;
};

/**
 * optional bool read = 5;
 * @return {boolean}
 */
proto.pb.CancelLesson.prototype.getRead = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.CancelLesson} returns this
 */
proto.pb.CancelLesson.prototype.setRead = function (value) {
    return jspb.Message.setProto3BooleanField(this, 5, value);
};

/**
 * optional string created_at = 6;
 * @return {string}
 */
proto.pb.CancelLesson.prototype.getCreatedAt = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CancelLesson} returns this
 */
proto.pb.CancelLesson.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional string category = 7;
 * @return {string}
 */
proto.pb.CancelLesson.prototype.getCategory = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CancelLesson} returns this
 */
proto.pb.CancelLesson.prototype.setCategory = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Responses.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Responses.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Responses} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Responses.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                notificationUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                type: jspb.Message.getFieldWithDefault(msg, 2, ""),
                order: (f = msg.getOrder()) && proto.pb.Order.toObject(includeInstance, f),
                count: jspb.Message.getFieldWithDefault(msg, 4, 0),
                read: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
                createdAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
                category: jspb.Message.getFieldWithDefault(msg, 7, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Responses}
 */
proto.pb.Responses.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Responses();

    return proto.pb.Responses.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Responses} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Responses}
 */
proto.pb.Responses.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setNotificationUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setType(value);
                break;
            case 3:
                var value = new proto.pb.Order();

                reader.readMessage(value, proto.pb.Order.deserializeBinaryFromReader);
                msg.setOrder(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setCount(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setRead(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());

                msg.setCreatedAt(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());

                msg.setCategory(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Responses.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Responses.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Responses} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Responses.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getNotificationUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getType();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getOrder();
    if (f != null) {
        writer.writeMessage(3, f, proto.pb.Order.serializeBinaryToWriter);
    }

    f = message.getCount();
    if (f !== 0) {
        writer.writeInt32(4, f);
    }

    f = message.getRead();
    if (f) {
        writer.writeBool(5, f);
    }

    f = message.getCreatedAt();
    if (f.length > 0) {
        writer.writeString(6, f);
    }

    f = message.getCategory();
    if (f.length > 0) {
        writer.writeString(7, f);
    }
};

/**
 * optional string notification_uuid = 1;
 * @return {string}
 */
proto.pb.Responses.prototype.getNotificationUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Responses} returns this
 */
proto.pb.Responses.prototype.setNotificationUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string type = 2;
 * @return {string}
 */
proto.pb.Responses.prototype.getType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Responses} returns this
 */
proto.pb.Responses.prototype.setType = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional Order order = 3;
 * @return {?proto.pb.Order}
 */
proto.pb.Responses.prototype.getOrder = function () {
    return /** @type{?proto.pb.Order} */ (jspb.Message.getWrapperField(this, proto.pb.Order, 3));
};

/**
 * @param {?proto.pb.Order|undefined} value
 * @return {!proto.pb.Responses} returns this
 */
proto.pb.Responses.prototype.setOrder = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Responses} returns this
 */
proto.pb.Responses.prototype.clearOrder = function () {
    return this.setOrder(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Responses.prototype.hasOrder = function () {
    return jspb.Message.getField(this, 3) != null;
};

/**
 * optional int32 count = 4;
 * @return {number}
 */
proto.pb.Responses.prototype.getCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.Responses} returns this
 */
proto.pb.Responses.prototype.setCount = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional bool read = 5;
 * @return {boolean}
 */
proto.pb.Responses.prototype.getRead = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.Responses} returns this
 */
proto.pb.Responses.prototype.setRead = function (value) {
    return jspb.Message.setProto3BooleanField(this, 5, value);
};

/**
 * optional string created_at = 6;
 * @return {string}
 */
proto.pb.Responses.prototype.getCreatedAt = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Responses} returns this
 */
proto.pb.Responses.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional string category = 7;
 * @return {string}
 */
proto.pb.Responses.prototype.getCategory = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Responses} returns this
 */
proto.pb.Responses.prototype.setCategory = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.SeenOrder.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.SeenOrder.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.SeenOrder} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.SeenOrder.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                creator: (f = msg.getCreator()) && proto.pb.Creator.toObject(includeInstance, f),
                orderId: jspb.Message.getFieldWithDefault(msg, 2, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.SeenOrder}
 */
proto.pb.SeenOrder.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.SeenOrder();

    return proto.pb.SeenOrder.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.SeenOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.SeenOrder}
 */
proto.pb.SeenOrder.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = new proto.pb.Creator();

                reader.readMessage(value, proto.pb.Creator.deserializeBinaryFromReader);
                msg.setCreator(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setOrderId(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.SeenOrder.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.SeenOrder.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.SeenOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.SeenOrder.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getCreator();
    if (f != null) {
        writer.writeMessage(1, f, proto.pb.Creator.serializeBinaryToWriter);
    }

    f = message.getOrderId();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};

/**
 * optional Creator creator = 1;
 * @return {?proto.pb.Creator}
 */
proto.pb.SeenOrder.prototype.getCreator = function () {
    return /** @type{?proto.pb.Creator} */ (jspb.Message.getWrapperField(this, proto.pb.Creator, 1));
};

/**
 * @param {?proto.pb.Creator|undefined} value
 * @return {!proto.pb.SeenOrder} returns this
 */
proto.pb.SeenOrder.prototype.setCreator = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.SeenOrder} returns this
 */
proto.pb.SeenOrder.prototype.clearCreator = function () {
    return this.setCreator(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.SeenOrder.prototype.hasCreator = function () {
    return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string order_id = 2;
 * @return {string}
 */
proto.pb.SeenOrder.prototype.getOrderId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.SeenOrder} returns this
 */
proto.pb.SeenOrder.prototype.setOrderId = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.NewOrder.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.NewOrder.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.NewOrder} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.NewOrder.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                notificationUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                type: jspb.Message.getFieldWithDefault(msg, 2, ""),
                order: (f = msg.getOrder()) && proto.pb.Order.toObject(includeInstance, f),
                read: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
                createdAt: jspb.Message.getFieldWithDefault(msg, 5, ""),
                category: jspb.Message.getFieldWithDefault(msg, 6, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.NewOrder}
 */
proto.pb.NewOrder.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.NewOrder();

    return proto.pb.NewOrder.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.NewOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.NewOrder}
 */
proto.pb.NewOrder.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setNotificationUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setType(value);
                break;
            case 3:
                var value = new proto.pb.Order();

                reader.readMessage(value, proto.pb.Order.deserializeBinaryFromReader);
                msg.setOrder(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setRead(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());

                msg.setCreatedAt(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());

                msg.setCategory(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.NewOrder.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.NewOrder.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.NewOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.NewOrder.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getNotificationUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getType();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getOrder();
    if (f != null) {
        writer.writeMessage(3, f, proto.pb.Order.serializeBinaryToWriter);
    }

    f = message.getRead();
    if (f) {
        writer.writeBool(4, f);
    }

    f = message.getCreatedAt();
    if (f.length > 0) {
        writer.writeString(5, f);
    }

    f = message.getCategory();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
};

/**
 * optional string notification_uuid = 1;
 * @return {string}
 */
proto.pb.NewOrder.prototype.getNotificationUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.NewOrder} returns this
 */
proto.pb.NewOrder.prototype.setNotificationUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string type = 2;
 * @return {string}
 */
proto.pb.NewOrder.prototype.getType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.NewOrder} returns this
 */
proto.pb.NewOrder.prototype.setType = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional Order order = 3;
 * @return {?proto.pb.Order}
 */
proto.pb.NewOrder.prototype.getOrder = function () {
    return /** @type{?proto.pb.Order} */ (jspb.Message.getWrapperField(this, proto.pb.Order, 3));
};

/**
 * @param {?proto.pb.Order|undefined} value
 * @return {!proto.pb.NewOrder} returns this
 */
proto.pb.NewOrder.prototype.setOrder = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.NewOrder} returns this
 */
proto.pb.NewOrder.prototype.clearOrder = function () {
    return this.setOrder(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.NewOrder.prototype.hasOrder = function () {
    return jspb.Message.getField(this, 3) != null;
};

/**
 * optional bool read = 4;
 * @return {boolean}
 */
proto.pb.NewOrder.prototype.getRead = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.NewOrder} returns this
 */
proto.pb.NewOrder.prototype.setRead = function (value) {
    return jspb.Message.setProto3BooleanField(this, 4, value);
};

/**
 * optional string created_at = 5;
 * @return {string}
 */
proto.pb.NewOrder.prototype.getCreatedAt = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.NewOrder} returns this
 */
proto.pb.NewOrder.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional string category = 6;
 * @return {string}
 */
proto.pb.NewOrder.prototype.getCategory = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.NewOrder} returns this
 */
proto.pb.NewOrder.prototype.setCategory = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Paid.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Paid.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Paid} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Paid.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                notificationUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                type: jspb.Message.getFieldWithDefault(msg, 2, ""),
                creator: (f = msg.getCreator()) && proto.pb.Creator.toObject(includeInstance, f),
                order: (f = msg.getOrder()) && proto.pb.Order.toObject(includeInstance, f),
                cardTail: jspb.Message.getFieldWithDefault(msg, 5, ""),
                value: jspb.Message.getFieldWithDefault(msg, 6, 0),
                read: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
                createdAt: jspb.Message.getFieldWithDefault(msg, 8, ""),
                category: jspb.Message.getFieldWithDefault(msg, 9, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Paid}
 */
proto.pb.Paid.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Paid();

    return proto.pb.Paid.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Paid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Paid}
 */
proto.pb.Paid.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setNotificationUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setType(value);
                break;
            case 3:
                var value = new proto.pb.Creator();

                reader.readMessage(value, proto.pb.Creator.deserializeBinaryFromReader);
                msg.setCreator(value);
                break;
            case 4:
                var value = new proto.pb.Order();

                reader.readMessage(value, proto.pb.Order.deserializeBinaryFromReader);
                msg.setOrder(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());

                msg.setCardTail(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setValue(value);
                break;
            case 7:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setRead(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());

                msg.setCreatedAt(value);
                break;
            case 9:
                var value = /** @type {string} */ (reader.readString());

                msg.setCategory(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Paid.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Paid.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Paid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Paid.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getNotificationUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getType();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getCreator();
    if (f != null) {
        writer.writeMessage(3, f, proto.pb.Creator.serializeBinaryToWriter);
    }

    f = message.getOrder();
    if (f != null) {
        writer.writeMessage(4, f, proto.pb.Order.serializeBinaryToWriter);
    }

    f = message.getCardTail();
    if (f.length > 0) {
        writer.writeString(5, f);
    }

    f = message.getValue();
    if (f !== 0) {
        writer.writeInt32(6, f);
    }

    f = message.getRead();
    if (f) {
        writer.writeBool(7, f);
    }

    f = message.getCreatedAt();
    if (f.length > 0) {
        writer.writeString(8, f);
    }

    f = message.getCategory();
    if (f.length > 0) {
        writer.writeString(9, f);
    }
};

/**
 * optional string notification_uuid = 1;
 * @return {string}
 */
proto.pb.Paid.prototype.getNotificationUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Paid} returns this
 */
proto.pb.Paid.prototype.setNotificationUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string type = 2;
 * @return {string}
 */
proto.pb.Paid.prototype.getType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Paid} returns this
 */
proto.pb.Paid.prototype.setType = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional Creator creator = 3;
 * @return {?proto.pb.Creator}
 */
proto.pb.Paid.prototype.getCreator = function () {
    return /** @type{?proto.pb.Creator} */ (jspb.Message.getWrapperField(this, proto.pb.Creator, 3));
};

/**
 * @param {?proto.pb.Creator|undefined} value
 * @return {!proto.pb.Paid} returns this
 */
proto.pb.Paid.prototype.setCreator = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Paid} returns this
 */
proto.pb.Paid.prototype.clearCreator = function () {
    return this.setCreator(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Paid.prototype.hasCreator = function () {
    return jspb.Message.getField(this, 3) != null;
};

/**
 * optional Order order = 4;
 * @return {?proto.pb.Order}
 */
proto.pb.Paid.prototype.getOrder = function () {
    return /** @type{?proto.pb.Order} */ (jspb.Message.getWrapperField(this, proto.pb.Order, 4));
};

/**
 * @param {?proto.pb.Order|undefined} value
 * @return {!proto.pb.Paid} returns this
 */
proto.pb.Paid.prototype.setOrder = function (value) {
    return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Paid} returns this
 */
proto.pb.Paid.prototype.clearOrder = function () {
    return this.setOrder(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Paid.prototype.hasOrder = function () {
    return jspb.Message.getField(this, 4) != null;
};

/**
 * optional string card_tail = 5;
 * @return {string}
 */
proto.pb.Paid.prototype.getCardTail = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Paid} returns this
 */
proto.pb.Paid.prototype.setCardTail = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional int32 value = 6;
 * @return {number}
 */
proto.pb.Paid.prototype.getValue = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.Paid} returns this
 */
proto.pb.Paid.prototype.setValue = function (value) {
    return jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * optional bool read = 7;
 * @return {boolean}
 */
proto.pb.Paid.prototype.getRead = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.Paid} returns this
 */
proto.pb.Paid.prototype.setRead = function (value) {
    return jspb.Message.setProto3BooleanField(this, 7, value);
};

/**
 * optional string created_at = 8;
 * @return {string}
 */
proto.pb.Paid.prototype.getCreatedAt = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Paid} returns this
 */
proto.pb.Paid.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * optional string category = 9;
 * @return {string}
 */
proto.pb.Paid.prototype.getCategory = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Paid} returns this
 */
proto.pb.Paid.prototype.setCategory = function (value) {
    return jspb.Message.setProto3StringField(this, 9, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Order.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Order.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Order} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Order.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                id: jspb.Message.getFieldWithDefault(msg, 1, ""),
                title: jspb.Message.getFieldWithDefault(msg, 2, ""),
                date: jspb.Message.getFieldWithDefault(msg, 3, ""),
                dateDeadline: jspb.Message.getFieldWithDefault(msg, 4, ""),
                daysLeft: jspb.Message.getFieldWithDefault(msg, 5, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Order}
 */
proto.pb.Order.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Order();

    return proto.pb.Order.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Order} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Order}
 */
proto.pb.Order.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setId(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setTitle(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());

                msg.setDate(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());

                msg.setDateDeadline(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());

                msg.setDaysLeft(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Order.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Order.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Order} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Order.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getTitle();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getDate();
    if (f.length > 0) {
        writer.writeString(3, f);
    }

    f = message.getDateDeadline();
    if (f.length > 0) {
        writer.writeString(4, f);
    }

    f = message.getDaysLeft();
    if (f.length > 0) {
        writer.writeString(5, f);
    }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pb.Order.prototype.getId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Order} returns this
 */
proto.pb.Order.prototype.setId = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string title = 2;
 * @return {string}
 */
proto.pb.Order.prototype.getTitle = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Order} returns this
 */
proto.pb.Order.prototype.setTitle = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string date = 3;
 * @return {string}
 */
proto.pb.Order.prototype.getDate = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Order} returns this
 */
proto.pb.Order.prototype.setDate = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string date_deadline = 4;
 * @return {string}
 */
proto.pb.Order.prototype.getDateDeadline = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Order} returns this
 */
proto.pb.Order.prototype.setDateDeadline = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string days_left = 5;
 * @return {string}
 */
proto.pb.Order.prototype.getDaysLeft = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Order} returns this
 */
proto.pb.Order.prototype.setDaysLeft = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Creator.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Creator.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Creator} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Creator.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                name: jspb.Message.getFieldWithDefault(msg, 2, ""),
                photo: (f = msg.getPhoto()) && proto.pb.Photo.toObject(includeInstance, f),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Creator}
 */
proto.pb.Creator.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Creator();

    return proto.pb.Creator.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Creator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Creator}
 */
proto.pb.Creator.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setName(value);
                break;
            case 3:
                var value = new proto.pb.Photo();

                reader.readMessage(value, proto.pb.Photo.deserializeBinaryFromReader);
                msg.setPhoto(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Creator.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Creator.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Creator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Creator.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getName();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getPhoto();
    if (f != null) {
        writer.writeMessage(3, f, proto.pb.Photo.serializeBinaryToWriter);
    }
};

/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.pb.Creator.prototype.getUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Creator} returns this
 */
proto.pb.Creator.prototype.setUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string name = 2;
 * @return {string}
 */
proto.pb.Creator.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Creator} returns this
 */
proto.pb.Creator.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional Photo photo = 3;
 * @return {?proto.pb.Photo}
 */
proto.pb.Creator.prototype.getPhoto = function () {
    return /** @type{?proto.pb.Photo} */ (jspb.Message.getWrapperField(this, proto.pb.Photo, 3));
};

/**
 * @param {?proto.pb.Photo|undefined} value
 * @return {!proto.pb.Creator} returns this
 */
proto.pb.Creator.prototype.setPhoto = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Creator} returns this
 */
proto.pb.Creator.prototype.clearPhoto = function () {
    return this.setPhoto(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Creator.prototype.hasPhoto = function () {
    return jspb.Message.getField(this, 3) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Photo.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Photo.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Photo} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Photo.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                link: jspb.Message.getFieldWithDefault(msg, 1, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Photo}
 */
proto.pb.Photo.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Photo();

    return proto.pb.Photo.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Photo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Photo}
 */
proto.pb.Photo.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setLink(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Photo.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Photo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Photo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Photo.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getLink();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};

/**
 * optional string link = 1;
 * @return {string}
 */
proto.pb.Photo.prototype.getLink = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Photo} returns this
 */
proto.pb.Photo.prototype.setLink = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.TimeReschedule.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.TimeReschedule.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.TimeReschedule} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.TimeReschedule.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                currentTime: (f = msg.getCurrentTime()) && proto.pb.TimeStartEnd.toObject(includeInstance, f),
                requestedTime: (f = msg.getRequestedTime()) && proto.pb.TimeStartEnd.toObject(includeInstance, f),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.TimeReschedule}
 */
proto.pb.TimeReschedule.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.TimeReschedule();

    return proto.pb.TimeReschedule.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.TimeReschedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.TimeReschedule}
 */
proto.pb.TimeReschedule.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = new proto.pb.TimeStartEnd();

                reader.readMessage(value, proto.pb.TimeStartEnd.deserializeBinaryFromReader);
                msg.setCurrentTime(value);
                break;
            case 2:
                var value = new proto.pb.TimeStartEnd();

                reader.readMessage(value, proto.pb.TimeStartEnd.deserializeBinaryFromReader);
                msg.setRequestedTime(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.TimeReschedule.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.TimeReschedule.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.TimeReschedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.TimeReschedule.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getCurrentTime();
    if (f != null) {
        writer.writeMessage(1, f, proto.pb.TimeStartEnd.serializeBinaryToWriter);
    }

    f = message.getRequestedTime();
    if (f != null) {
        writer.writeMessage(2, f, proto.pb.TimeStartEnd.serializeBinaryToWriter);
    }
};

/**
 * optional TimeStartEnd current_time = 1;
 * @return {?proto.pb.TimeStartEnd}
 */
proto.pb.TimeReschedule.prototype.getCurrentTime = function () {
    return /** @type{?proto.pb.TimeStartEnd} */ (jspb.Message.getWrapperField(this, proto.pb.TimeStartEnd, 1));
};

/**
 * @param {?proto.pb.TimeStartEnd|undefined} value
 * @return {!proto.pb.TimeReschedule} returns this
 */
proto.pb.TimeReschedule.prototype.setCurrentTime = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.TimeReschedule} returns this
 */
proto.pb.TimeReschedule.prototype.clearCurrentTime = function () {
    return this.setCurrentTime(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.TimeReschedule.prototype.hasCurrentTime = function () {
    return jspb.Message.getField(this, 1) != null;
};

/**
 * optional TimeStartEnd requested_time = 2;
 * @return {?proto.pb.TimeStartEnd}
 */
proto.pb.TimeReschedule.prototype.getRequestedTime = function () {
    return /** @type{?proto.pb.TimeStartEnd} */ (jspb.Message.getWrapperField(this, proto.pb.TimeStartEnd, 2));
};

/**
 * @param {?proto.pb.TimeStartEnd|undefined} value
 * @return {!proto.pb.TimeReschedule} returns this
 */
proto.pb.TimeReschedule.prototype.setRequestedTime = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.TimeReschedule} returns this
 */
proto.pb.TimeReschedule.prototype.clearRequestedTime = function () {
    return this.setRequestedTime(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.TimeReschedule.prototype.hasRequestedTime = function () {
    return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.TimeStartEnd.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.TimeStartEnd.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.TimeStartEnd} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.TimeStartEnd.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                start: jspb.Message.getFieldWithDefault(msg, 1, ""),
                end: jspb.Message.getFieldWithDefault(msg, 2, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.TimeStartEnd}
 */
proto.pb.TimeStartEnd.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.TimeStartEnd();

    return proto.pb.TimeStartEnd.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.TimeStartEnd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.TimeStartEnd}
 */
proto.pb.TimeStartEnd.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setStart(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setEnd(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.TimeStartEnd.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.TimeStartEnd.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.TimeStartEnd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.TimeStartEnd.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getStart();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getEnd();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};

/**
 * optional string start = 1;
 * @return {string}
 */
proto.pb.TimeStartEnd.prototype.getStart = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.TimeStartEnd} returns this
 */
proto.pb.TimeStartEnd.prototype.setStart = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string end = 2;
 * @return {string}
 */
proto.pb.TimeStartEnd.prototype.getEnd = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.TimeStartEnd} returns this
 */
proto.pb.TimeStartEnd.prototype.setEnd = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Postponement.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Postponement.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Postponement} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Postponement.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                answer: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
                accept: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Postponement}
 */
proto.pb.Postponement.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Postponement();

    return proto.pb.Postponement.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Postponement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Postponement}
 */
proto.pb.Postponement.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setAnswer(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setAccept(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Postponement.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Postponement.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Postponement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Postponement.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getAnswer();
    if (f) {
        writer.writeBool(1, f);
    }

    f = message.getAccept();
    if (f) {
        writer.writeBool(2, f);
    }
};

/**
 * optional bool answer = 1;
 * @return {boolean}
 */
proto.pb.Postponement.prototype.getAnswer = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.Postponement} returns this
 */
proto.pb.Postponement.prototype.setAnswer = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional bool accept = 2;
 * @return {boolean}
 */
proto.pb.Postponement.prototype.getAccept = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.Postponement} returns this
 */
proto.pb.Postponement.prototype.setAccept = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Empty.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Empty.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Empty} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Empty.toObject = function (includeInstance, msg) {
        var f,
            obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Empty}
 */
proto.pb.Empty.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Empty();

    return proto.pb.Empty.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Empty}
 */
proto.pb.Empty.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Empty.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Empty.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Empty.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.RecentNotificationsResponse.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.RecentNotificationsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.RecentNotificationsResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.RecentNotificationsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.RecentNotificationsResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                status: jspb.Message.getFieldWithDefault(msg, 1, ""),
                notificationsList: jspb.Message.toObjectList(msg.getNotificationsList(), proto.pb.Notification.toObject, includeInstance),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.RecentNotificationsResponse}
 */
proto.pb.RecentNotificationsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.RecentNotificationsResponse();

    return proto.pb.RecentNotificationsResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.RecentNotificationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.RecentNotificationsResponse}
 */
proto.pb.RecentNotificationsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setStatus(value);
                break;
            case 2:
                var value = new proto.pb.Notification();

                reader.readMessage(value, proto.pb.Notification.deserializeBinaryFromReader);
                msg.addNotifications(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.RecentNotificationsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.RecentNotificationsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.RecentNotificationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RecentNotificationsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getStatus();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getNotificationsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(2, f, proto.pb.Notification.serializeBinaryToWriter);
    }
};

/**
 * optional string status = 1;
 * @return {string}
 */
proto.pb.RecentNotificationsResponse.prototype.getStatus = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.RecentNotificationsResponse} returns this
 */
proto.pb.RecentNotificationsResponse.prototype.setStatus = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * repeated Notification notifications = 2;
 * @return {!Array<!proto.pb.Notification>}
 */
proto.pb.RecentNotificationsResponse.prototype.getNotificationsList = function () {
    return /** @type{!Array<!proto.pb.Notification>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.Notification, 2));
};

/**
 * @param {!Array<!proto.pb.Notification>} value
 * @return {!proto.pb.RecentNotificationsResponse} returns this
 */
proto.pb.RecentNotificationsResponse.prototype.setNotificationsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.pb.Notification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.Notification}
 */
proto.pb.RecentNotificationsResponse.prototype.addNotifications = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pb.Notification, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.RecentNotificationsResponse} returns this
 */
proto.pb.RecentNotificationsResponse.prototype.clearNotificationsList = function () {
    return this.setNotificationsList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.ReadNotificationsRequest.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.ReadNotificationsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.ReadNotificationsRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.ReadNotificationsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.ReadNotificationsRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                notificationUuidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.ReadNotificationsRequest}
 */
proto.pb.ReadNotificationsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.ReadNotificationsRequest();

    return proto.pb.ReadNotificationsRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.ReadNotificationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.ReadNotificationsRequest}
 */
proto.pb.ReadNotificationsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.addNotificationUuids(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.ReadNotificationsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.ReadNotificationsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.ReadNotificationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.ReadNotificationsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getNotificationUuidsList();
    if (f.length > 0) {
        writer.writeRepeatedString(1, f);
    }
};

/**
 * repeated string notification_uuids = 1;
 * @return {!Array<string>}
 */
proto.pb.ReadNotificationsRequest.prototype.getNotificationUuidsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pb.ReadNotificationsRequest} returns this
 */
proto.pb.ReadNotificationsRequest.prototype.setNotificationUuidsList = function (value) {
    return jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.ReadNotificationsRequest} returns this
 */
proto.pb.ReadNotificationsRequest.prototype.addNotificationUuids = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.ReadNotificationsRequest} returns this
 */
proto.pb.ReadNotificationsRequest.prototype.clearNotificationUuidsList = function () {
    return this.setNotificationUuidsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.ReadNotificationsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.ReadNotificationsResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.ReadNotificationsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.ReadNotificationsResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                status: jspb.Message.getFieldWithDefault(msg, 1, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.ReadNotificationsResponse}
 */
proto.pb.ReadNotificationsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.ReadNotificationsResponse();

    return proto.pb.ReadNotificationsResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.ReadNotificationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.ReadNotificationsResponse}
 */
proto.pb.ReadNotificationsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setStatus(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.ReadNotificationsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.ReadNotificationsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.ReadNotificationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.ReadNotificationsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getStatus();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};

/**
 * optional string status = 1;
 * @return {string}
 */
proto.pb.ReadNotificationsResponse.prototype.getStatus = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.ReadNotificationsResponse} returns this
 */
proto.pb.ReadNotificationsResponse.prototype.setStatus = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

goog.object.extend(exports, proto.pb);
