import * as React from "react";
import { observer } from "mobx-react-lite";

import { Box, Typography } from "@mui/material";

import MenuShowImage from "../ImageChatAttachments/MenuShowImage/MenuShowImage";
import SingleVideoAttachment from "./SingleVideoAttachment";

import * as styles from "./VideoChatAttachmentsThemes";
import * as textStyles from "themes/TextThemes";

import { useGetChatAttachments } from "hooks/useGetChatAttachments";

const VideoChatAttachments = ({ avatar, getDate, mergeArraysFromObject, handleDownload }) => {
    const [open, setOpen] = React.useState(false);

    const [opening, setOpening] = React.useState(false);

    const [chosenMessage, setChosenMessage] = React.useState("");

    const [chosenAttachment, setChosenAttachment] = React.useState("");

    const sortedVideos = useGetChatAttachments("videos");

    return (
        <Box sx={styles.imageAttachmentsContainer}>
            {Object.keys(sortedVideos).map((monthyear, index) => {
                return (
                    <Box
                        key={index}
                        sx={styles.imageDateBox}
                    >
                        <Typography sx={textStyles.Body2DarkGrey}>{monthyear}</Typography>
                        <Box sx={styles.imagesBox}>
                            {sortedVideos[monthyear].reverse().map((link, lindex) => {
                                return (
                                    <SingleVideoAttachment
                                        link={link}
                                        key={lindex}
                                        duration={sortedVideos[monthyear][lindex].duration}
                                        handleDownload={handleDownload}
                                    />
                                );
                            })}
                        </Box>
                    </Box>
                );
            })}
            {/*<ModalShowImage
                open={open}
                setOpen={setOpen}
                chosenMessage={chosenMessage}
                chosenAttachment={chosenAttachment}
                avatar={avatar}
                getDate={getDate}
                orderedGroupedMessages={orderedGroupedMessages}
            />*/}
        </Box>
    );
};

export default observer(VideoChatAttachments);
