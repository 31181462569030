import React, { memo, useState } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./ChipBlockThemes";
import { ThemeProvider } from "@mui/material/styles";
import { Chip, IconButton, Menu, Typography } from "@mui/material";
import FavouritesStore from "store/FavouritesStore";
import TeacherStore from "../../../../store/TeacherStore";
import { CheckSharp, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";

const ChipBlock = observer(({ children, ...props }) => {
    const handleClick = async (value, event) => {
        FavouritesStore.SetActiveChip(value);
        await FavouritesStore.FetchFavouritesData("replace");
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleMenuToggle = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = async (value, event) => {
        if (value !== null) {
            FavouritesStore.SetActiveChip(value);
            await FavouritesStore.FetchFavouritesData("replace");
        }

        setAnchorEl(null);
    };

    const chipInMenuList = () => {
        return FavouritesStore.uniqueDisciplines.slice(6).find((value) => FavouritesStore.activeChip.ID === value.ID);
    };

    return (
        <Box style={themes.tagsContainerStyle}>
            <ThemeProvider theme={FavouritesStore.activeChip.ID === null ? themes.ActiveChipTheme : themes.InactiveChipTheme}>
                <Chip
                    label={"Все"}
                    disableRipple
                    onClick={handleClick.bind(this, { ID: null, Query: "" })}
                />
            </ThemeProvider>
            {FavouritesStore.uniqueDisciplines.map((value, index) =>
                index <= 5 ? (
                    <ThemeProvider
                        key={value.ID}
                        theme={FavouritesStore.activeChip.ID === value.ID ? themes.ActiveChipTheme : themes.InactiveChipTheme}
                    >
                        <Chip
                            label={value.Query}
                            disableRipple
                            onClick={handleClick.bind(this, value)}
                        />
                    </ThemeProvider>
                ) : null,
            )}
            {FavouritesStore.uniqueDisciplines.length > 6 ? (
                <Box>
                    <ThemeProvider
                        theme={themes.MoreChipTheme(
                            FavouritesStore.uniqueDisciplines.find((value, index) => (value.ID === FavouritesStore.activeChip.ID || FavouritesStore.activeChip.ID === null) && index <= 5),
                        )}
                    >
                        <Chip
                            label={FavouritesStore.activeChip.Query && chipInMenuList() ? FavouritesStore.activeChip.Query : "ещё"}
                            disableRipple
                            deleteIcon={
                                <IconButton
                                    disableRipple
                                    onClick={handleMenuToggle}
                                >
                                    {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                </IconButton>
                            }
                            onDelete={handleMenuToggle}
                            onClick={handleMenuToggle}
                        />
                    </ThemeProvider>
                    <ThemeProvider theme={themes.MenuTheme}>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose.bind(this, null)}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            {FavouritesStore.uniqueDisciplines.map((value, index) =>
                                index > 5 ? (
                                    <MenuItem
                                        key={value.ID}
                                        onClick={handleMenuClose.bind(this, value)}
                                        disableRipple
                                        selected={FavouritesStore.activeChip.ID === value.ID}
                                    >
                                        <Typography sx={themes.actionTextStyle}>{value.Query}</Typography>
                                        <Box sx={themes.IconContainerStyle}>
                                            <ThemeProvider theme={themes.IconCheckTheme}>{FavouritesStore.activeChip.ID === value.ID ? <CheckSharp /> : null}</ThemeProvider>
                                        </Box>
                                    </MenuItem>
                                ) : null,
                            )}
                        </Menu>
                    </ThemeProvider>
                </Box>
            ) : null}
        </Box>
    );
});

export default memo(ChipBlock);
