import React, { useEffect, useMemo, useRef, useState } from "react";

import { Box, sliderClasses, Typography } from "@mui/material";

import StudentRecievedWorkActions from "./WaitingState/StudentRecievedWorkActions";
import SendWork from "./WorkingState/Tutor/SendWork";
import StartWork from "./AcceptedState/Tutor/StartWork";
import IncomingMessageCard from "components/ChatBodyBlock/GridFieldChat/IncomingMessageCard/IncomingMessageCard";
import OutcomingMessageCard from "components/ChatBodyBlock/GridFieldChat/OutcomingMessageCard/OutcomingMessageCard";
import { reverseMassiv, getDateGroups } from "components/ChatBodyBlock/CommonData/helper_functions";

import DiplomaStore from "../../store/DiplomaStore";
import { observer } from "mobx-react-lite";
import * as textStyles from "themes/TextThemes";
import * as themes from "./styles";

const MessagesBox = ({ sendJsonMessage, SetOpenEditModalMessage, SetOpenReplyModalMessage, SetText, SetDisable, disable }) => {
    const boxRef = useRef(null);

    const [messagesList, SetMessagesList] = useState([]);

    const [dateGroups, SetDateGroups] = useState([]);

    const Load = () => {
        const scrollElement = boxRef.current;

        if (scrollElement.scrollTop === 0) {
            DiplomaStore.SetNoScroll(true);
            DiplomaStore.GetForScrollMessagesNoLimit(sendJsonMessage);
        }
    };

    useEffect(() => {
        if (DiplomaStore.id) {
            console.log("reload effect");
            let messagesListReversed = reverseMassiv(DiplomaStore.tabsData);

            let dateGroupsFromList = getDateGroups(messagesListReversed);

            SetMessagesList(messagesListReversed);
            SetDateGroups(dateGroupsFromList);
        }
    }, [DiplomaStore.tabsData.messages, DiplomaStore.id, DiplomaStore.newLoadedMessages]);

    useEffect(() => {
        if (messagesList.length) {
            let lastMessageOwner = messagesList.at(-1).owner.uuid;

            let isScroolledUp = DiplomaStore.newLoadedMessages?.length;

            console.log("scroll effect");

            if (DiplomaStore.noScroll) {
                DiplomaStore.SetNoScroll(false);
                if (DiplomaStore.lastMessage) {
                    const scrollElement = boxRef.current;

                    const elementTo = document.getElementById(DiplomaStore.lastMessage);

                    console.log(elementTo);
                    scrollElement.scrollTop = elementTo.offsetTop - 380;
                }
            } else if (lastMessageOwner !== DiplomaStore.participantId || isScroolledUp === undefined) {
                const scrollElement = boxRef.current;

                scrollElement.scrollTop = scrollElement.scrollHeight;
            } else {
                DiplomaStore.SetNewLoadedMessages(undefined);
            }
        }
    }, [messagesList.length]);

    return (
        <Box
            ref={boxRef}
            onScroll={Load}
            sx={{ overflow: "auto", flexGrow: 1 }}
        >
            {dateGroups.map((group, groupIndex) =>
                messagesList.map((message, messageIndex) => (
                    <Box key={groupIndex + messageIndex}>
                        {messageIndex === 0 && (
                            <Box sx={themes.MessageGroup}>
                                <Typography sx={textStyles.Caption1Grey}>{group}</Typography>
                            </Box>
                        )}
                        {message.formattedDate === group &&
                            (message.owner !== DiplomaStore.participantId ? (
                                <Box id={message.message_id}>
                                    <OutcomingMessageCard
                                        diploma={true}
                                        message={message}
                                        chat_id={DiplomaStore.id}
                                        disable={disable}
                                        handleDisable={SetDisable}
                                        handleOpenEditModalMessage={SetOpenEditModalMessage}
                                        handleOpenReplyModalMessage={SetOpenReplyModalMessage}
                                        handleText={SetText}
                                        reply_to_message={
                                            "reply_to_message" in message && message.reply_to_message
                                                ? DiplomaStore.tabsData.messages.find((element) => element.message_id === message.reply_to_message)?.message
                                                : null
                                        }
                                    />
                                </Box>
                            ) : (
                                <Box id={message.message_id}>
                                    <IncomingMessageCard
                                        diploma={true}
                                        message={message}
                                        chat_id={DiplomaStore.id}
                                        disable={disable}
                                        handleDisable={SetDisable}
                                        handleOpenReplyModalMessage={SetOpenReplyModalMessage}
                                        handleText={SetText}
                                    />
                                </Box>
                            ))}
                    </Box>
                )),
            )}
            <StartWork />
            <StudentRecievedWorkActions />
            <SendWork />
        </Box>
    );
};

export default observer(MessagesBox);
