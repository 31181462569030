import React from "react";

const ThumbDownIconEmpty = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M13.5334 22.4342C13.449 22.517 13.201 22.75 12.832 22.75C11.8375 22.75 10.8836 22.3549 10.1804 21.6516C9.47712 20.9484 9.08203 19.9946 9.08203 19L9.08203 15.75L5.17203 15.75C3.852 15.75 3.45578 15.7031 3.02336 15.509C2.65966 15.3458 2.3358 15.1054 2.07422 14.8046C1.79824 14.4872 1.55614 13.9882 1.45177 13.6287C1.35132 13.2827 1.06584 12.0181 1.43391 10.559L2.088 5.40556C2.09024 5.38792 2.09311 5.37035 2.0966 5.35291C2.34892 4.09132 2.58585 3.33918 2.87113 2.8371C3.13846 2.36661 3.44999 2.13057 3.66841 1.96508L3.66843 1.96506C3.70395 1.93815 3.737 1.91311 3.767 1.88923C4.05099 1.66315 4.42287 1.54375 4.73768 1.47038C5.07396 1.39201 5.45082 1.34348 5.81875 1.3122C6.55025 1.24999 7.32589 1.25 7.82226 1.25L7.82233 1.25L19.832 1.25C20.5614 1.25 21.2609 1.53973 21.7766 2.05546C22.2923 2.57118 22.582 3.27065 22.582 4L22.582 11C22.582 11.7293 22.2923 12.4288 21.7766 12.9445C21.2609 13.4603 20.5614 13.75 19.832 13.75L17.3588 13.75C17.2723 13.9881 17.1577 14.3013 17.0221 14.667C16.708 15.5142 16.2802 16.6456 15.8267 17.7787C15.3746 18.9081 14.8913 20.0538 14.4665 20.923C14.2559 21.3542 14.0483 21.741 13.8567 22.0288C13.7627 22.1701 13.6542 22.3156 13.5334 22.4342Z"
                fill="white"
            />
        </svg>
    );
};

export default ThumbDownIconEmpty;
