import React, { memo, useState } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./OrdersResponsesBodyBlockThemes";
import Typography from "@mui/material/Typography";
import OrdersResponsesStore from "store/OrdersResponsesStore";
import OrderCard from "components/UI/OrderCard/OrderCard";
import SortByBlock from "./SortByBlock/SortByBlock";
import { Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import MyResponsesBlock from "./MyResponsesBlock/MyResponsesBlock";
import { EastSharp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OrdersResponsesBodyBlock = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const [tabIndex, SetTabIndex] = useState(0);

    const navigate = useNavigate();

    const changeTab = (value, event) => {
        SetTabIndex(value);
    };

    return (
        <Box sx={themes.ordersResponsesBodyBlockStyle(tabIndex)}>
            <Box sx={themes.ordersResponsesHeaderContainerStyle}>
                <Typography sx={themes.ordersResponsesHeaderStyle}>Отклики</Typography>
                <Box sx={themes.tabButtonsContainerStyle}>
                    <ThemeProvider theme={themes.ActionButtonTheme(tabIndex === 0)}>
                        <Button
                            onClick={changeTab.bind(this, 0)}
                            disableRipple
                        >
                            <Typography sx={themes.buttonTextStyle(tabIndex === 0)}>Предложения</Typography>
                        </Button>
                    </ThemeProvider>
                    <ThemeProvider theme={themes.ActionButtonTheme(tabIndex === 1)}>
                        <Button
                            onClick={changeTab.bind(this, 1)}
                            disableRipple
                        >
                            <Typography sx={themes.buttonTextStyle(tabIndex === 1)}>Мои отклики</Typography>
                        </Button>
                    </ThemeProvider>
                </Box>
            </Box>
            {tabIndex === 0 ? (
                <Box sx={themes.ordersResponsesBodyBlockContainerStyle}>
                    {tabIndex === 0 ? (
                        <Box sx={themes.ordersResponsesActionsContainerStyle}>
                            <Typography sx={themes.ordersResponsesCountStyle}>{t("main.responses", { count: OrdersResponsesStore.ordersArrFiltered.length })}</Typography>
                            <SortByBlock></SortByBlock>
                        </Box>
                    ) : null}
                    {OrdersResponsesStore.ordersArrFiltered.length ? (
                        <Box sx={themes.ordersResponsesInfoBlockStyle}>
                            {OrdersResponsesStore.ordersArrFiltered.map((value) => (
                                <OrderCard
                                    key={value.ID}
                                    value={value}
                                    styleMode={1}
                                ></OrderCard>
                            ))}
                        </Box>
                    ) : (
                        <Box sx={themes.emptyOrdersContainerStyle}>
                            <Box sx={themes.emptyTextContainerStyle}>
                                <Typography sx={themes.emptyHeaderStyle}>Здесь будут предложения от заказчиков поработать с тобой</Typography>
                                <Typography sx={themes.emptyTextStyle}>
                                    У тебя пока нет предложений. Но не теряй время, не жди пока заказчики тебе предложат заказ, переходи в «Поиск заказов» и откликайся самостоятельно
                                </Typography>
                            </Box>
                            <Box sx={themes.emptyActionsContainerStyle}>
                                <ThemeProvider theme={themes.ButtonRedirectTheme}>
                                    <Button
                                        onClick={() => navigate("/orders-search")}
                                        endIcon={<EastSharp />}
                                        disableRipple
                                    >
                                        <Typography style={themes.buttonRedirectTextStyle}>Поиск заказов</Typography>
                                    </Button>
                                </ThemeProvider>
                            </Box>
                        </Box>
                    )}
                </Box>
            ) : (
                <MyResponsesBlock></MyResponsesBlock>
            )}
        </Box>
    );
});

export default memo(OrdersResponsesBodyBlock);
