import React from "react";
import { CloseRounded } from "@mui/icons-material";
import { Box, Drawer, IconButton, ThemeProvider, Typography } from "@mui/material";

import * as themes from "./RenewOrderFilterThemes";
import CreateOrderButton from "components/OrdersBodyBlock/OrderMenu/CreateOrderButton/CreateOrderButton";
import RenewOrderFilterList from "./RenewOrderFilterList";
import store from "store/RequestStore";
import filterstore from "store/FilterStore";
import RenewOrder from "./RenewOrder";
import filterStore from "store/FilterStore";
import { observer } from "mobx-react-lite";
import OrderResponseButton from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseButton/OrderResponseButton";
import orderResponseStore from "store/OrderResponseStore";
import * as theme from "./RenewOrderFilterStyled";
const RenewOrderFilter = observer(({ toggleOrderDrawer }) => {
    const toggleOrderDrawerAndEdited = () => {
        filterStore.setToggleDrawer();
        orderResponseStore.setToggleCancelOrderModal(false);
        filterStore.setChangeDrawerContent("");
        if (store.edited === true) {
            store.setEdited();
        }
    };

    const toggler = () => {
        toggleOrderDrawer();
        filterStore.setChangeDrawerContent("");
        orderResponseStore.setToggleCancelOrderModal(false);
    };

    const showDialog = () => {
        orderResponseStore.setToggleCancelOrderModal(true);

        filterStore.setToggleDrawer();
    };

    return (
        <>
            <ThemeProvider theme={themes.OrderDrawerTheme}>
                <Drawer
                    anchor="right"
                    open={filterStore.toggleDrawer}
                    onClose={toggleOrderDrawerAndEdited}
                >
                    {!store.edited ? (
                        <>
                            <Box style={themes.OrderheaderContainerStyle}>
                                <Typography style={themes.OrdertextStyle}>Английский язык</Typography>
                                <ThemeProvider theme={themes.OrderCloseIconTheme}>
                                    <IconButton onClick={toggler}>
                                        <CloseRounded></CloseRounded>
                                    </IconButton>
                                </ThemeProvider>
                            </Box>
                            {filterStore.changeDrawerContent === "canceled" ? (
                                <Typography
                                    variant="p"
                                    sx={theme.CanseledSubtitle}
                                >
                                    Проверьте детали заказа и при необходимости измените их перед тем, как опубликовать его повторно
                                </Typography>
                            ) : (
                                <Typography
                                    variant="p"
                                    sx={theme.CheckResponseTextSubtitle}
                                >
                                    Вы можете изменить некоторые параметры заказа. После редактирования статус заказа не изменится. Если заказ доступен для откликов, специалисты будут его видеть,
                                    если закрыт — мы просто сохраним новые параметры
                                </Typography>
                            )}

                            {filterstore.filterList.map((el, i) => (
                                <RenewOrderFilterList
                                    key={i}
                                    i={i}
                                    label={el.label}
                                    text={el.text}
                                    active={el.active}
                                    id={el.id}
                                />
                            ))}
                            <Box sx={theme.DateWrapper}>
                                <Typography sx={theme.DateTextFontSize}>
                                    <Typography
                                        variant="span"
                                        sx={theme.DateNumberStyle}
                                    >
                                        Создан:
                                    </Typography>
                                    19 декабря 2022
                                </Typography>
                                <Typography sx={theme.DateTextFontSize}>
                                    <Typography
                                        variant="span"
                                        sx={theme.DateNumberStyle}
                                    >
                                        Номер заказа:
                                    </Typography>
                                    555677453
                                </Typography>
                            </Box>
                            <Box sx={theme.RenewOrderButtonWrapper}>
                                {filterStore.changeDrawerContent === "canceled" ? (
                                    <CreateOrderButton
                                        text={"Возобновить заказ"}
                                        toggleOrderDrawer={toggleOrderDrawer}
                                    />
                                ) : (
                                    <OrderResponseButton
                                        variant="grayFilled"
                                        text="Отменить заказ"
                                        handleClick={showDialog}
                                    />
                                )}
                            </Box>
                        </>
                    ) : (
                        <RenewOrder toggleOrderDrawer={toggleOrderDrawer} />
                    )}
                </Drawer>
            </ThemeProvider>
        </>
    );
});

export default RenewOrderFilter;
