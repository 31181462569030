const CustomStar = ({ empty = true }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="31"
            viewBox="0 0 34 31"
            fill="none"
        >
            <path
                d="M16.3399 0.532909C16.5893 -0.0463776 17.4107 -0.0463773 17.6601 0.532909L21.6981 9.90936C21.8021 10.151 22.0299 10.3165 22.2918 10.3408L32.4571 11.2836C33.0852 11.3418 33.339 12.123 32.8651 12.5392L25.1954 19.277C24.9977 19.4506 24.9107 19.7184 24.9686 19.975L27.2132 29.9341C27.3519 30.5494 26.6874 31.0322 26.1451 30.7102L17.367 25.4979C17.1408 25.3636 16.8592 25.3636 16.633 25.4979L7.85495 30.7102C7.31263 31.0322 6.64815 30.5494 6.78682 29.9341L9.03142 19.975C9.08926 19.7184 9.00227 19.4506 8.80462 19.277L1.13487 12.5392C0.661026 12.123 0.914837 11.3418 1.54286 11.2836L11.7082 10.3408C11.9701 10.3165 12.1979 10.151 12.3019 9.90936L16.3399 0.532909Z"
                stroke={empty ? "#BBBBC8" : undefined}
                fill={!empty ? "#6212FF" : undefined}
            />
        </svg>
    );
};

export default CustomStar;
