import React from "react";
import * as themes from "./TutorPointsThemes";
import * as textThemes from "themes/TextThemes";
import { Typography } from "@mui/material";
import Rectangle6492 from "assets/ReferralPageAssets/PNG/Rectangle6492.png";
import Rectangle6493 from "assets/ReferralPageAssets/PNG/Rectangle6493.png";
import Rectangle6494 from "assets/ReferralPageAssets/PNG/Rectangle6494.png";
import Box from "@mui/material/Box";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const TutorPoints = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.tutorPointsBlock}>
            <Typography sx={textThemes.HeadlineH2Black}>{t("promoPages.inviteFriend.howToGetToTutor")}</Typography>

            <Box sx={themes.imgBlock}>
                <img
                    style={themes.img}
                    src={Rectangle6492}
                    alt={1}
                />
                <img
                    style={themes.img}
                    src={Rectangle6493}
                    alt={2}
                />
                <img
                    style={themes.img}
                    src={Rectangle6494}
                    alt={3}
                />
            </Box>

            <Box sx={themes.textBlock}>
                <Box sx={themes.textBlockContent}>
                    <Typography sx={textThemes.Button20BoldBlack}>{t("promoPages.inviteFriend.inviteStudents")}</Typography>
                    <Typography sx={textThemes.Body1Black}>{t("promoPages.inviteFriend.copyReferral")}</Typography>
                </Box>
                <Box sx={themes.textBlockContent}>
                    <Typography sx={textThemes.Button20BoldBlack}>{t("promoPages.inviteFriend.studentsGiftPoints")}</Typography>
                    <Typography sx={textThemes.Body1Black}>{t("promoPages.inviteFriend.studentWillGet")}</Typography>
                </Box>
                <Box sx={themes.textBlockContent}>
                    <Typography sx={textThemes.Button20BoldBlack}>{t("promoPages.inviteFriend.accumulatePoints")}</Typography>
                    <Typography sx={textThemes.Body1Black}>{t("promoPages.inviteFriend.signUpStudents")}</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default memo(TutorPoints);
