import { memo } from "react";
import * as styles from "./NewBankCardThemes";
import * as textStyles from "themes/TextThemes";
import PaymentStore from "store/PaymentStore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, InputAdornment, Radio, TextField, Typography } from "@mui/material";
import PurpleRadioDefault from "assets/PaymentAssets/SVG/PurpleRadioDefault";
import PurpleRadioChecked from "assets/PaymentAssets/SVG/PurpleRadioChecked";
import VisaPaymentIcon from "assets/PaymentAssets/SVG/VisaPaymentIcon";
import MastercardPaymentIcon from "assets/PaymentAssets/SVG/MastercardPaymentIcon";
import MirPaymentIcon from "assets/PaymentAssets/SVG/MirPaymentIcon";
import CardsSmall from "assets/SettingsAssets/SVG/cardssmall";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import CardsGroupIcon from "assets/PaymentAssets/SVG/CardsGroupIcon";
import PurpleCheckBoxDefault from "assets/PaymentAssets/SVG/PurpleCheckBoxDefault";
import PurpleCheckBoxChecked from "assets/PaymentAssets/SVG/PurpleCheckBoxChecked";

const NewBankCard = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Accordion
            sx={styles.Accordion}
            expanded={PaymentStore.selectedPaymentMethod === "bankCard"}
            onClick={() => PaymentStore.changePaymentMethod("bankCard")}
        >
            <AccordionSummary sx={styles.AccordionSummary}>
                <Box sx={styles.RadioCards}>
                    <Radio
                        icon={<PurpleRadioDefault />}
                        checkedIcon={<PurpleRadioChecked />}
                        disableRipple
                        sx={styles.Radio}
                        checked={PaymentStore.selectedPaymentMethod === "bankCard"}
                    />
                    <Typography sx={textStyles.Body2Black}>Банковская карта</Typography>
                </Box>
                {PaymentStore.selectedPaymentMethod === "bankCard" ? null : <CardsGroupIcon />}
            </AccordionSummary>
            <AccordionDetails sx={styles.AccordionDetails}>
                <TextField
                    sx={styles.AddCardNumber}
                    fullWidth
                    variant="outlined"
                    placeholder="1234 1234 1234 1234"
                    value={PaymentStore.cardNumber}
                    onChange={(e) => PaymentStore.handleChangeCardNumber(e)}
                    inputProps={{
                        maxLength: 19, // 16 digits + 3 spaces
                        inputMode: "numeric",
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                {PaymentStore.cardNumber === null ? (
                                    <CardsSmall />
                                ) : PaymentStore.cardNumber.slice(0, 1) === "4" ? (
                                    <VisaPaymentIcon />
                                ) : PaymentStore.cardNumber.slice(0, 1) === "5" ? (
                                    <MastercardPaymentIcon />
                                ) : PaymentStore.cardNumber.slice(0, 1) === "2" ? (
                                    <MirPaymentIcon />
                                ) : (
                                    <CardsSmall />
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
                <Box sx={styles.AddCardBottomFields}>
                    <TextField
                        value={PaymentStore.expireDate}
                        onChange={(e) => PaymentStore.handleChangeExpirationDate(e)}
                        sx={styles.AddMonthYear}
                        fullWidth
                        variant="outlined"
                        placeholder={t("main.settings.paymentMethodsBlock.mmyy")}
                    />
                    <TextField
                        value={PaymentStore.cvc}
                        onChange={(e) => PaymentStore.handleChangeCVC(e)}
                        sx={styles.AddCVC}
                        fullWidth
                        variant="outlined"
                        placeholder="CVC"
                        inputProps={{
                            maxLength: 5, // Max length is 5 for MM/YY format
                            inputMode: "numeric", // Show numeric keypad on mobile
                        }}
                    />
                </Box>
                <Box sx={styles.SaveCard}>
                    <Checkbox
                        checked={PaymentStore.saveCard}
                        onChange={(e) => PaymentStore.setSaveCard(e)}
                        sx={styles.CheckBox}
                        icon={<PurpleCheckBoxDefault />}
                        checkedIcon={<PurpleCheckBoxChecked />}
                        disableRipple
                    />
                    <Typography sx={textStyles.Caption2DarkGrey}>Сохранить эту карту для оплаты в будущем</Typography>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
});

export default memo(NewBankCard);
