import React from "react";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { memo } from "react";
import Error403BodyBlock from "components/ErrorsBodyBlock/Error403BodyBlock/Error403BodyBlock";

const PageError403 = ({ children, ...props }) => {
    return (
        <ScrollToTop>
            <Error403BodyBlock />
        </ScrollToTop>
    );
};

export default memo(PageError403);
