import React, { memo } from "react";
import Box from "@mui/material/Box";
import UserStore from "store/UserStore";
import TutorAccount from "./TeacherAccount/TutorAccount";
import IconOpenMiniChat from "components/MiniChat/IconOpenMiniChat/IconOpenMiniChat";
import StudentAccount from "./StudentAccount/StudentAccount";
import { observer } from "mobx-react-lite";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

const AccountBodyBlock = observer(({ children, ...props }) => {
    return (
        <ScrollToTop>
            <Box>{UserStore.role === "student" ? <StudentAccount /> : <TutorAccount />}</Box>
            <IconOpenMiniChat />
        </ScrollToTop>
    );
});

export default memo(AccountBodyBlock);
