import React, { memo, useState } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./CardActionsAltStickyThemes";
import { Button, Tooltip, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ModalNotAuth from "components/UI/ModalNotAuth/ModalNotAuth";
import UserStore from "store/UserStore";
import MiniChatStore from "store/MiniChatStore";
import { useMedias } from "../../../../API/mediasHook";
import Box from "@mui/material/Box";

const CardActionsAltSticky = observer(({ children, ...props }) => {
    const navigate = useNavigate();

    const [dialogOpen, SetDialogOpen] = useState(false);

    const messageTutor = () => {
        //
    };

    const showDialog = () => {
        UserStore.isAuth ? MiniChatStore.SetOpen(true) : SetDialogOpen(true);
    };

    const hideDialog = () => {
        SetDialogOpen(false);
    };

    const medias = useMedias();

    return (
        <Box sx={themes.actionsContainerStyle(props.styleMode ? props.styleMode : 0, medias)}>
            <Box sx={themes.buttonsContainerStyle(props.styleMode ? props.styleMode : 0, medias)}>
                <ThemeProvider theme={themes.MessageButtonTheme}>
                    <Button
                        onClick={false ? messageTutor : showDialog}
                        disableRipple
                    >
                        <Typography style={themes.messageTextStyle}>Написать</Typography>
                    </Button>
                </ThemeProvider>
                <ThemeProvider theme={themes.OrderButtonTheme}>
                    <Button
                        onClick={() => navigate("/bid")}
                        disableRipple
                    >
                        <Typography style={themes.orderTextStyle}>Предложить заказ</Typography>
                    </Button>
                </ThemeProvider>
            </Box>
            <ModalNotAuth
                open={dialogOpen}
                handleClose={hideDialog}
                value={props.value}
                header={"Зарегистрируйтесь, чтобы написать репетитору"}
                content={"Чтобы начать переписку с репетитором " + props.value.Name + ", нужно создать аккаунт на StudyBuddy"}
            ></ModalNotAuth>
        </Box>
    );
});

export default memo(CardActionsAltSticky);
