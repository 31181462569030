const TimingChooseDownArrow = function (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <path
                d="M7.64645 10.1464L3.85355 6.35355C3.53857 6.03857 3.76165 5.5 4.20711 5.5H11.7929C12.2383 5.5 12.4614 6.03857 12.1464 6.35355L8.35355 10.1464C8.15829 10.3417 7.84171 10.3417 7.64645 10.1464Z"
                fill="#67677A"
            />
        </svg>
    );
};

export default TimingChooseDownArrow;
