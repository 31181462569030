import { createTheme } from "@mui/material";

export const OrderModalCloseIcon = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid #9E9EB0",
                    borderRadius: "50%",

                    position: "absolute",
                    top: -34,
                    right: 0,
                    width: "24px",
                    height: "24px",
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: 0,
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    "&:hover": {
                        borderColor: "#FFFFFF",
                    },
                    "&:active": {
                        borderColor: "#FFFFFF",
                    },
                },
            },
        },
    },
});
