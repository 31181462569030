import React from "react";
import * as themes from "./ImgBlockThemes";
import * as textThemes from "themes/TextThemes";
import Img from "assets/CashbackAssets/PNG/CashbackMainGirl.png";
import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const ImgBlock = ({ children, ...props }) => {
    const { t } = useTranslation();

    return (
        <Box sx={themes.ImgBlock}>
            <Box sx={themes.ImgBackground}></Box>

            <Box sx={themes.Img}>
                <img
                    src={Img}
                    alt="Img"
                />
            </Box>

            <Box sx={themes.NoteLeftBlock}>
                <Typography sx={textThemes.Body2Black}>{t("promoPages.cashback.getCashback")}</Typography>
            </Box>

            <Box sx={themes.NoteRightBlock}>
                <Typography sx={textThemes.Body2Black}>{t("promoPages.cashback.getPoints")}</Typography>
            </Box>
        </Box>
    );
};

export default memo(ImgBlock);
