import React, { memo, useState } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "components/BalanceBodyBlock/FilterPanel/FilterPanelThemes";
import { IconButton, Menu, Typography } from "@mui/material";
import BalanceStore from "store/BalanceStore";
import MenuItem from "@mui/material/MenuItem";
import { ThemeProvider } from "@mui/material/styles";
import { CheckSharp } from "@mui/icons-material";
import DropDownFilterIcon from "assets/CommonAssets/SVG/DropDownFilterIcon";
import { useMedias } from "API/mediasHook";

const FilterOperationType = observer(({ children, ...props }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const medias = useMedias();

    const open = Boolean(anchorEl);

    const handleMenuToggle = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (value, event) => {
        if (value !== null) {
            BalanceStore.SetFilterBy("filterByOptionFirst", value);
            BalanceStore.SetChipFilter();
        }

        setAnchorEl(null);
    };

    return (
        <Box sx={themes.filterByContainerStyle(medias)}>
            <Box
                sx={themes.filterByOptionsSwitcherStyle}
                onClick={handleMenuToggle}
            >
                <Typography sx={themes.filterByOptionsTextStyle}>{BalanceStore.filterByOptionFirst.filterBy}</Typography>
                <ThemeProvider theme={themes.IconOptionsTheme}>
                    <IconButton
                        sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "auto" }}
                        disableRipple
                        onClick={handleMenuToggle}
                    >
                        <DropDownFilterIcon />
                    </IconButton>
                </ThemeProvider>
            </Box>

            <ThemeProvider theme={themes.MenuTheme}>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose.bind(this, null)}
                    PaperProps={{
                        style: {
                            left: "50%",
                            transform: medias.sm ? "translateX(-7%) translateY(5%)" : "translateX(6.3%) translateY(5%)",
                        },
                    }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: medias.sm ? "left" : "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: medias.sm ? "left" : "right",
                    }}
                >
                    {BalanceStore.filterByOptionsFirst.map((value, index) => (
                        <MenuItem
                            key={value.ID}
                            onClick={handleMenuClose.bind(this, value)}
                            disableRipple
                            selected={BalanceStore.filterByOptionFirst.ID === value.ID}
                        >
                            <Typography>{value.filterBy}</Typography>
                            <Box sx={themes.IconContainerStyle}>
                                <ThemeProvider theme={themes.IconCheckTheme}>{BalanceStore.filterByOptionFirst.ID === value.ID ? <CheckSharp /> : null}</ThemeProvider>
                            </Box>
                        </MenuItem>
                    ))}
                </Menu>
            </ThemeProvider>
        </Box>
    );
});

export default memo(FilterOperationType);
