import React from "react";

const SetPassword = () => {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="18"
                cy="18"
                r="18"
                fill="#FF9212"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.0784 9.92838C15.8202 9.35147 16.869 9 18 9C19.131 9 20.1798 9.35147 20.9216 9.92838C21.6575 10.5008 22 11.2154 22 11.8889V14.25H14V11.8889C14 11.2154 14.3425 10.5008 15.0784 9.92838ZM12 14.25V11.8889C12 10.4995 12.7111 9.23592 13.8505 8.34968C14.9841 7.46797 16.4788 7 18 7C19.5212 7 21.0159 7.46797 22.1495 8.34968C23.2889 9.23592 24 10.4995 24 11.8889V14.25H26.1274C26.6815 14.25 27.1349 14.7202 27.1349 15.2948V21.731C27.1349 24.8655 25.6236 28 22.0974 28H14.0375C10.5112 28 9 24.8655 9 21.731V15.2948C9 14.7202 9.45337 14.25 10.0075 14.25H12ZM20.1349 20.75C20.1349 21.8546 19.2394 22.75 18.1349 22.75C17.0303 22.75 16.1349 21.8546 16.1349 20.75C16.1349 19.6454 17.0303 18.75 18.1349 18.75C19.2394 18.75 20.1349 19.6454 20.1349 20.75Z"
                fill="white"
            />
        </svg>
    );
};

export default SetPassword;
