import { createTheme } from "@mui/material/styles";

export const ordersResponsesBodyBlockStyle = (tabIndex) => {
    return {
        width: 1216,
        margin: "auto",
        marginTop: 40,
        marginBottom: 70,
        display: "flex",
        flexDirection: "column",
        gap: tabIndex ? 60 : 40,
    };
};

export const ordersResponsesHeaderContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    width: 907,
};

export const ordersResponsesActionsContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    width: 907,
};

export const ordersResponsesHeaderStyle = {
    color: "var(--text-black, #222)",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "140%",
};

export const tabButtonsContainerStyle = {
    display: "flex",
    flexDirection: "row",
    gap: 10,
};

export const ActionButtonTheme = (mode) =>
    createTheme({
        components: {
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "8px 16px 10px 16px!important",
                        gap: "10px",
                        height: "39px",
                        background: mode ? "var(--Text-Black, #222)" : "var(--Text-Grey, #F7F7F7)",
                        border: mode ? "1px solid var(--Text-Black, #222)" : "1px solid var(--Text-Dark-Grey, #67677A)",
                        borderRadius: "30px!important",
                        "&:hover": {
                            background: "var(--Text-Black, #222)!important",
                            borderColor: "var(--Text-Black, #222)!important",
                            ".MuiTypography-root": {
                                color: "white!important",
                            },
                        },
                        "&:active": {
                            background: "var(--Text-Black, #222)!important",
                            borderColor: "var(--Text-Black, #222)!important",
                            ".MuiTypography-root": {
                                color: "white!important",
                            },
                        },
                    },
                },
            },
        },
    });

export const buttonTextStyle = (mode) => {
    return {
        color: mode ? "var(--Text-White, #FFF)" : "var(--Text-Light-Black, #4E4E4E)",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "150%",
        textTransform: "none",
    };
};

export const ordersResponsesCountStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
};

export const ordersResponsesBodyBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: 20,
};

export const ordersResponsesInfoBlockStyle = {
    position: "relative",
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
    width: "fit-content",
};

export const emptyOrdersContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: 30,
    width: 907,
    boxSizing: "border-box",
    padding: "40px",
    borderRadius: "20px",
    background: "var(--BG-White, #FFF)",
};

export const emptyTextContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: 20,
};

export const emptyHeaderStyle = {
    color: "var(--text-black, #222)",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "140%",
};

export const emptyTextStyle = {
    color: "var(--text-black, #222)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const emptyActionsContainerStyle = {
    display: "flex",
    alignItems: "start",
};

export const ButtonRedirectTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "11px 24px 12px 24px!important",
                    height: "fit-content",
                    gap: "8px",
                    borderRadius: "42px!important",
                    background: "var(--main-purple, #6212FF)",
                    boxShadow: "0px 4px 10px 0px rgba(51, 12, 128, 0.20)",
                    ".MuiButton-endIcon": {
                        color: "#FFF",
                        margin: 0,
                    },
                    "&:hover": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                    },
                    "&:active": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                    },
                },
            },
        },
    },
});

export const buttonRedirectTextStyle = {
    textTransform: "none",
    color: "var(--text-white, #FFF)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
};
