import React from "react";
import bonusLogo from "./icon223.png";

const Bonus = () => {
    return (
        <img
            src={bonusLogo}
            style={{ width: "24px", height: "24px" }}
        />
    );
};

export default Bonus;
