const IconUp = ({ disabled }) => {
    return (
        <svg
            width="2.3vh"
            height="2.3vh"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon">
                <path
                    id="Vector (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.5886 11.5395C15.2889 11.8642 14.7828 11.8844 14.4582 11.5847L8.50078 6.0856L2.5434 11.5847C2.21874 11.8844 1.71262 11.8642 1.41293 11.5395C1.11325 11.2148 1.1335 10.7087 1.45815 10.409L7.95815 4.40903C8.2646 4.12615 8.73695 4.12615 9.0434 4.40903L15.5434 10.409C15.8681 10.7087 15.8883 11.2148 15.5886 11.5395Z"
                    fill={disabled === true ? "#9E9EB0" : "#6212FF"}
                />
            </g>
        </svg>
    );
};

export default IconUp;
