export const studentPointsBlock = {
    width: "1116px",
    height: "490px",
    borderRadius: "40px",
    background: "#6212FF",
    padding: "50px",
    marginTop: "140px",
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
};

export const textBlock = {
    height: "348px",
    gap: "30px",
    display: "flex",
    flexDirection: "column",
};

export const subTextBlock = {
    width: "549px",
    gap: "10px",
    display: "flex",
    flexDirection: "column",
};

export const addFrame1 = {
    display: "flex",
    width: "199px",
    height: "83px",
    padding: "10px 16px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "0px 6px 6px 6px",
    background: "rgba(255, 255, 255, 0.90)",
    boxShadow: "1px 4px 20px 0px rgba(88, 4, 252, 0.08), 0px 9px 10px 0px rgba(51, 12, 128, 0.15)",
    position: "absolute",
    left: "334px",
    top: "90px",
    boxSizing: "border-box",
};

export const addFrame2 = {
    display: "flex",
    width: "213px",
    height: "83px",
    padding: "10px 16px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "6px 0px 6px 6px",
    background: "rgba(255, 255, 255, 0.90)",
    boxShadow: "1px 4px 20px 0px rgba(88, 4, 252, 0.08), 0px 9px 10px 0px rgba(51, 12, 128, 0.15)",
    position: "absolute",
    left: "96px",
    top: "457px",
    color: "#222",
    boxSizing: "border-box",
};

export const FirstPhoto = { width: 399, height: 291, top: 50, left: 50, position: "absolute" };

export const SecondPhoto = { width: 266, height: 200, top: 305, left: 229, position: "absolute" };

export const TextContent = {
    gap: "40px",
    display: "flex",
    flexDirection: "column",
    width: "549px",
};
