import React from "react";
import { observer } from "mobx-react-lite";
import TutorInfoForStudent from "./TutorInfoForStudent";
import OrderPayment from "./OrderPayment";
import OrderConditions from "components/DiplomaChats/RightPanel/OrderInfo/OrderConditions";
import CashbackInfo from "./CashbackInfo";
import OrderPaymentEnded from "./OrderPaymentEnded";

import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";

const OrderInfoForStudent = () => {
    return (
        <>
            <TutorInfoForStudent />
            {DiplomaStore.status !== "ended" ? (
                <>
                    <OrderPayment />
                    <OrderConditions />
                    <CashbackInfo />
                </>
            ) : (
                <OrderPaymentEnded />
            )}
        </>
    );
};

export default observer(OrderInfoForStudent);
