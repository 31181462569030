const EmptyRatingIcon = function (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M7.54077 1.06637C7.71432 0.663388 8.28569 0.663389 8.45923 1.06637L10.0456 4.75014C10.118 4.91822 10.2764 5.03333 10.4587 5.05024L14.4524 5.42064C14.8892 5.46116 15.0658 6.00457 14.7362 6.29414L11.7229 8.94124C11.5854 9.06202 11.5249 9.24828 11.5652 9.42681L12.447 13.3395C12.5435 13.7675 12.0812 14.1034 11.704 13.8794L8.25528 11.8316C8.09792 11.7381 7.90208 11.7381 7.74472 11.8316L4.29604 13.8793C3.91877 14.1034 3.45652 13.7675 3.55299 13.3395L4.43484 9.42681C4.47507 9.24827 4.41456 9.06202 4.27706 8.94124L1.26382 6.29414C0.934186 6.00456 1.11075 5.46116 1.54764 5.42064L5.54133 5.05024C5.72356 5.03333 5.88199 4.91822 5.95438 4.75014L7.54077 1.06637Z"
                stroke="#BBBBC8"
            />
        </svg>
    );
};

export default EmptyRatingIcon;
