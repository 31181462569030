export const BlockStyle = {
    width: "201px",
    height: "362px",
    position: "relative",
};

export const boxTopLeft = { left: 0, top: 97, position: "absolute" };

export const boxTopRight = { left: 145, top: 184, position: "absolute" };

export const boxBottomLeft = { left: 0, top: 0, position: "absolute" };

export const boxCenterCenter = { left: 72, top: 128, position: "absolute" };
