import { createTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

export const LessonsPlaceAccordionTheme = (medias) => {
    return {
        components: {
            MuiAccordion: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        width: medias.sm ? "430px!important" : "328px!important",
                        position: "relative",
                        boxShadow: "none!important",
                        "&:before": {
                            background: "none!important",
                        },
                        "&.Mui-expanded": {
                            marginTop: "0px!important",
                            marginBottom: "0px!important",
                        },
                        ".MuiAccordionSummary-root": {
                            minHeight: "0px!important",
                            padding: 0,
                            paddingBottom: "12px",
                            ".MuiAccordionSummary-content": {
                                marginTop: "0px!important",
                                marginBottom: "0px!important",
                                ".Mui-expanded": {
                                    marginTop: "0px!important",
                                    marginBottom: "0px!important",
                                },
                            },
                        },
                        ".MuiAccordionDetails-root": {
                            padding: 0,
                        },
                    },
                },
            },
        },
    };
};

export const headerLessonsPlaceStyle = {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#222222",
    flex: "none",
    order: 0,
    flexGrow: 1,
};

export const selectedLessonsPlaceContainerStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    padding: "0px",
    gap: "8px",
    marginBottom: "16px",
};

export const LessonsChipTheme = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                // Name of the slot
                root: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "4px 10px 5px",
                    gap: "4px",
                    width: "fit-content",
                    height: "30px",
                    background: "#FFFFFF",
                    border: "1px solid #BBBBC8",
                    borderRadius: "25px!important",
                    ".MuiChip-deleteIcon": {
                        width: "16px",
                        height: "16px",
                        flex: "none",
                        order: 1,
                        flexGrow: 0,
                    },
                    ".MuiIconButton-root": {
                        margin: 0,
                        color: "#4E4E4E!important",
                    },
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    ".MuiIconButton-root:hover": {
                        color: "#6212FF!important",
                    },
                },
                label: {
                    width: "fit-content",
                    height: "18px",
                    fontStyle: "normal",
                    padding: 0,
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "130%",
                    fontFeatureSettings: "'tnum' on, 'lnum' on",
                    color: "#222222",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                },
            },
        },
    },
});

export const FiltersLessonsPlaceTheme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "--TextField-brandBorderColor": "#9E9EB0",
                    "--TextField-brandBorderHoverColor": "#6212FF",
                    "--TextField-brandBorderFocusedColor": "#6212FF",
                    "& label.Mui-focused": {
                        color: "var(--TextField-brandBorderFocusedColor)",
                    },
                    "& .Mui-focused": {
                        background: "#FFFFFF",
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: "var(--TextField-brandBorderColor)",
                },
                root: {
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: "var(--TextField-brandBorderHoverColor)",
                    },
                    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: "var(--TextField-brandBorderFocusedColor)",
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    "&:before, &:after": {
                        borderBottom: "2px solid var(--TextField-brandBorderColor)",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                    },
                    "&.Mui-focused:after": {
                        borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    "&:before": {
                        borderBottom: "1px solid var(--TextField-brandBorderColor)",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderBottom: "1px solid var(--TextField-brandBorderHoverColor)",
                    },
                    "&.Mui-focused:after": {
                        borderBottom: "1px solid var(--TextField-brandBorderFocusedColor)",
                    },
                },
            },
        },

        MuiAutocomplete: {
            styleOverrides: {
                // Name of the slot
                root: {
                    ".MuiAutocomplete-popupIndicator:hover": {
                        background: "#FFFFFF",
                        color: "#6212FF",
                    },
                },
                option: {
                    '&[aria-selected="true"]': {
                        background: "#6212FF!important",
                        color: "#FFFFFF",
                    },
                    "&:hover": {
                        background: "#6212FF!important",
                        color: "#FFFFFF",
                    },
                    "&:hover div > span": {
                        color: "#FFFFFF!important",
                    },
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "130%",
                    fontFeatureSettings: "'tnum' on, 'lnum' on",
                    color: "#222222",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                },
                clearIndicator: {
                    "&:hover": {
                        color: "#6212FF",
                        background: "white",
                    },
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                // Name of the slot
                root: {
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "13px 16px",
                    gap: "4px",
                    width: "100%",
                    height: "44px",
                    flex: "none",
                    order: 1,
                    flexGrow: 0,
                    "&.MuiInputBase-root": {
                        border: "1px!important solid #9E9EB0",
                        height: "34px",
                        borderRadius: "12px",
                        width: "100%",
                        paddingLeft: 0,
                        paddingTop: 0,
                    },
                    ".MuiInputBase-input": {
                        width: "-webkit-fill-available!important",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "150%",
                        fontFeatureSettings: "'tnum' on, 'lnum' on",
                        color: "#222222",
                        flex: "none",
                        order: 0,
                        flexGrow: 0,
                    },
                },
            },
        },
    },
});
