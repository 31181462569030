export const accordionText = {
    color: "#222",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%" /* 28px */,
};

export const accordionForm = {
    borderRadius: "14px",
    background: "transparent",
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
};

export const AccordionSummary = (medias) => {
    return {
        bgcolor: "#FFF",
        borderRadius: "14px",
        padding: medias.sm ? "25px 40px 25px 30px" : "16px",
        display: "flex",
        justifyContent: "space-between",
        margin: 0,
        gap: "15px",
        "& .MuiAccordionSummary-content": { margin: "0", maxWidth: "561px" },
        "& .MuiAccordionSummary-expanded": { margin: 0, minHeight: 0 },
        "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: "unset",
        },
        ".MuiAccordionSummary-root": {
            minHeight: "unset",
        },
        ".MuiInputBase-root": {
            minHeight: "unset",
        },
    };
};

export const AccordionDetails = (medias) => {
    return {
        bgcolor: "#FFF",
        borderRadius: "14px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "30px",
    };
};

export const PointBlock = {
    display: "flex",
    gap: "12px",
};

export const PointIcon = {
    paddingTop: "4px",
};

export const AccordionOuterBlock = (medias) => {
    return {
        width: medias.sm ? "701px" : "328px",
    };
};
