import { styleProcessing } from "API/plugins/Utilities";

export const TeacherPersonalDataStepWider = {
    display: "flex",
    flexDirection: "column",
};

export const TeacherPersonalData = {
    marginBottom: "60px",
};

export const TeacherQuestionnaireTopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const TeacherQuestionnaireSubTitle = {
    maxWidth: "598px",
    width: "100%",
};

export const BottomButtonWrappe = {
    maxWidth: "598px",
    width: "100%",
};

export const TeacherQuestionnairePriceSubjects = {
    display: "flex",
    padding: "12px 20px 12px 20px",
    alignItems: "center",
    borderRadius: "10px",
    background: "#f0efff",
    marginBottom: "20px",
    whiteSpace: "nowrap",
    width: "100%",
    boxSizing: "border-box",
};

export const TeacherQuestionnairePriceAimsOuterContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const TeacherQuestionnairePriceAimsInnerContainer = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            alignItems: "left",
            gap: "11px",
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherQuestionnairePriceAimsNameBox = {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
};

export const TeacherQuestionnairePriceAimsPriceBox = {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    alignItems: "center",
};

export const TeacherQuestionnaireDeleteStatementButton = {
    cursor: "pointer",
    height: "fit-content",
};

export const SubjectContainer = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
};

export const IconDelete = {
    margin: 0,
    padding: 0,
    height: "16px",
    width: "16px",
};

export const TooltipPopperProps = {
    sx: {
        "& .MuiTooltip-tooltip": {
            border: "none",
        },
    },
};

export const SubjectsContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
};

export const CostBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
};

export const TextButtonDelete = {
    textTransform: "none",
    color: "#6212FF",
    padding: 0,
    marginTop: "24px",
    lineHeight: "21px",
    fontSize: "14px",
    "&:hover": {
        color: "#6212FF",
    },
};
