import { observer } from "mobx-react-lite";
import React, { memo } from "react";
import * as themes from "./ModalAccountDeletedThemes";
import * as textThemes from "themes/TextThemes";
import { Typography } from "@mui/material";
import SButton from "components/UI/SButton";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";

const ModalAccountDeleted = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const Close = () => {
        props.handleDiscard();
    };

    return (
        <ModalSettingsSmall
            visible={props.open}
            handleClose={Close}
        >
            <Box sx={themes.AccountDeleted}>
                <Box sx={themes.AccountDeletedContent}>
                    <Typography sx={textThemes.HeadlineH41Black}>{t("teacherSettings.accountActions.accountDeleted")}</Typography>
                    <Typography sx={textThemes.Body2Black}>{t("teacherSettings.accountActions.infoDeleted")}</Typography>
                </Box>
                <SButton
                    variant="text"
                    onClick={Close}
                >
                    {t("main.close")}
                </SButton>
            </Box>
        </ModalSettingsSmall>
    );
});

export default memo(ModalAccountDeleted);
