import React from "react";
import { Box, Typography } from "@mui/material";
import * as themes from "./PremiumLvlCardThemes";
import * as textThemes from "themes/TextThemes";
import IconCheckWhite from "assets/CashbackAssets/PNG/IconCheckWhite.png";
import { memo } from "react";

const PremiumLvlCard = ({ children, ...props }) => {
    return (
        <Box sx={themes.CardBlock}>
            <img
                src={props.CardPhoto}
                alt="CardPhoto"
            />
            <Box sx={themes.CardContent}>
                <Box sx={themes.TopCard}>
                    <Typography sx={textThemes.Button20BoldWhite}>{props.CardName}</Typography>
                    <Typography sx={textThemes.Body1White}>{props.CardSubName}</Typography>
                </Box>
                <Box sx={themes.advantageBlock}>
                    {props.Advantage1 ? (
                        <Box sx={themes.advantageElement}>
                            <Box sx={themes.iconElem}>
                                <img
                                    src={IconCheckWhite}
                                    alt="IconCheckWhite"
                                />
                            </Box>
                            <Typography sx={textThemes.Body2White}>{props.Advantage1}</Typography>
                        </Box>
                    ) : null}

                    {props.Advantage2 ? (
                        <Box sx={themes.advantageElement}>
                            <Box sx={themes.iconElem}>
                                <img
                                    src={IconCheckWhite}
                                    alt="IconCheckWhite"
                                />
                            </Box>
                            <Typography sx={textThemes.Body2White}>{props.Advantage2}</Typography>
                        </Box>
                    ) : null}

                    {props.Advantage3 ? (
                        <Box sx={themes.advantageElement}>
                            <Box sx={themes.iconElem}>
                                <img
                                    src={IconCheckWhite}
                                    alt="IconCheckWhite"
                                />
                            </Box>
                            <Typography sx={textThemes.Body2White}>{props.Advantage3}</Typography>
                        </Box>
                    ) : null}

                    {props.Advantage4 ? (
                        <Box sx={themes.advantageElement}>
                            <Box sx={themes.iconElem}>
                                <img
                                    src={IconCheckWhite}
                                    alt="IconCheckWhite"
                                />
                            </Box>
                            <Typography sx={textThemes.Body2White}>{props.Advantage4}</Typography>
                        </Box>
                    ) : null}
                </Box>
            </Box>
        </Box>
    );
};

export default memo(PremiumLvlCard);
