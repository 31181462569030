import React from "react";
import * as themes from "./OrderResponseHiddenAvatarStyled";

const OrderResponseHiddenAvatar = () => {
    return (
        <themes.StyledOrderResponseHiddenAvatar>
            <themes.StyledOrderResponseHiddenAvatarText>Фото скрыто</themes.StyledOrderResponseHiddenAvatarText>
        </themes.StyledOrderResponseHiddenAvatar>
    );
};

export default OrderResponseHiddenAvatar;
