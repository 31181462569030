export const HowWorkMainBlock = {
    paddingLeft: "112px",
    paddingRight: "112px",
    paddingTop: "140px",
    width: "1216px",
    height: "583px",
    background: "#F7F7F7",
    display: "inline-flex",
};

export const HowWorkSubBlock = {
    width: "1216px",
    height: "583px",
    borderRadius: "40px",
    border: "2px solid var(--main-purple, #6212FF)",
    position: "relative",
};

export const HowWorkTitle = {
    color: "#222",
    fontSize: "40px",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "135%",
    //paddingBot: '16px'
    paddingTop: "51px",
    paddingLeft: "51px",
    width: "599px",
    height: "54px",
    position: "absolute",
};

// export const photoBlock = {
//     width: '566px',
//     height: '581px',
//     paddingLeft:'650px'
// }

export const cardTitle = {
    color: "#222",
    fontSize: "20px",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
};

export const cardTop = {
    //display: 'flex',
    width: "547px",
    height: "116px",
    paddingTop: "145px",
    paddingLeft: "51px",
    //padding: '20px',
    //flexDirection: 'column',
    //alignItems: 'flex-start',
    //gap: '12px',
};

// Bulb Icon

export const bulbIconContainer = {
    width: 30,
    height: 30,
    left: 175.15,
    top: 520,
    position: "absolute",
    borderRadius: "7px",
    border: "1px solid",
    transform: "rotate(-16deg)",
    borderColor: "#6212FF",
};

export const bulbIconImg = {
    marginTop: "-1px",
    marginLeft: "-1px",
    transform: "rotate(19deg)",
};

// Clock Icon

export const clockIconContainer = {
    width: 30,
    height: 30,
    left: 564.5,
    top: 283,
    position: "absolute",
    borderRadius: "7px",
    border: "1px solid",
    transform: "rotate(216deg)",
    borderColor: "#FF9212",
};

export const clockIconImg = {
    marginTop: "-2px",
    marginLeft: "-2px",
    transform: "rotate(151deg)",
};

// Rate Icon

export const rateIcon = {
    top: "123px",
    left: "411px",
    width: "35px",
    height: "21px",
    position: "absolute",
    gap: "8.159px",

    borderRadius: "4.895px",
    background: "#6212FF",
    transform: "rotate(-21.495deg)",
    paddingBottom: "3px",
};

export const rateIconText = {
    color: "#FFF",
    fontSize: "11,422px",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
    textAlign: "center",
};

// CardInfo Image

export const RedWomanImg = {
    top: "118px",
    left: "706px",
    width: "447px",
    height: "205px",
    position: "absolute",
};

// RequestCreateEnd Icon

export const TeachingImg = {
    top: "263px",
    left: "849px",
    width: "148px",
    height: "163px",
    position: "absolute",
};

export const MessageKaterinaLeftImg = {
    top: "513px",
    left: "396px",
    width: "183px",
    height: "46px",
    position: "absolute",
};

export const MessageKaterinaRightImg = {
    top: "376px",
    left: "700px",
    width: "187px",
    height: "47px",
    position: "absolute",
};

export const MessageSlavaImg = {
    top: "438px",
    left: "752px",
    width: "187px",
    height: "47px",
    position: "absolute",
};

export const bonusIcon = {
    top: "61px",
    left: "1020px",
    width: "39.36px",
    height: "24.4px",
    position: "absolute",
    padding: "3.826px 10.712px 4.591px 7.652px",
    borderRadius: "24.486px",
    background: "var(--main-gradient, linear-gradient(227deg, #04C83B 8.70%, #12AAFF 89.43%))",
    transform: "rotate(22.125deg)",
};

export const bonusIconText = {
    color: "var(--main-white, #FFF)",
    fontFamily: "Arial",
    fontSize: "10.712px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%" /* 16.069px */,
    textAlign: "center",
    paddingTop: "5px",
    paddingLeft: "1px",
};
