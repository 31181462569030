import { styled, Typography } from "@mui/material";

export const StyledOrderModalTitle = styled(Typography)({
    maxWidth: "330px",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "34px",
    textAlign: "left",
});
