import React, { memo, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Dialog, IconButton } from "@mui/material";
import * as themes from "./ModalCarouselThemesDefault";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { ArrowBackIos, ArrowForwardIos, CloseRounded } from "@mui/icons-material";
import ImageContent from "./ImageContent/ImageContent";
import VideoContent from "./VideoContent/VideoContent";

const ModalCarousel = observer(({ children, ...props }) => {
    const [currentElementIndex, SetCurrentElementIndex] = useState(0);

    const boxRefs = [useRef(null), useRef(null), useRef(null)];

    const Close = () => {
        SetCurrentElementIndex(0);
        props.handleDiscard();
    };

    const handleClickNext = () => {
        SetCurrentElementIndex(currentElementIndex + 1);
    };

    const handleClickPrev = () => {
        SetCurrentElementIndex(currentElementIndex - 1);
    };

    const handleKeyDown = (event) => {
        if (event.key === "ArrowLeft" && currentElementIndex > 0) {
            handleClickPrev();
        } else if (event.key === "ArrowRight" && currentElementIndex < props.content.length - 1) {
            handleClickNext();
        }
    };

    const handleClickOutside = (event) => {
        const elementToPrevent = boxRefs.some((ref) => ref.current && ref.current.contains(event.target));

        if (!elementToPrevent && props.open) {
            Close();
        }
    };

    useEffect(() => {
        if (props.open) {
            document.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [props.open, currentElementIndex]);

    useEffect(() => {
        if (props.open) {
            document.addEventListener("click", handleClickOutside);
        }

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [props.open]);

    const currentContent = () => {
        if (props.content[currentElementIndex] !== undefined) {
            switch (props.content[currentElementIndex].type) {
                case "image":
                    return <ImageContent value={props.content[currentElementIndex]}></ImageContent>;
                case "video":
                    return <VideoContent value={props.content[currentElementIndex]}></VideoContent>;
                default:
                    return null;
            }
        } else return null;
    };

    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={props.open}
                onClose={Close}
            >
                <Box sx={themes.dialogContainerStyle}>
                    <Box sx={themes.topPanelContainerStyle}>
                        <ThemeProvider theme={themes.CloseIconTheme}>
                            <IconButton
                                onClick={Close}
                                disableRipple
                            >
                                <CloseRounded></CloseRounded>
                            </IconButton>
                        </ThemeProvider>
                    </Box>
                    <Box sx={themes.contentContainerStyle}>
                        <Box
                            sx={themes.navContainerStyle(currentElementIndex === 0)}
                            ref={boxRefs[0]}
                            onClick={handleClickPrev}
                        >
                            <ThemeProvider theme={themes.NavIconTheme(0)}>
                                <IconButton disableRipple>
                                    <ArrowBackIos></ArrowBackIos>
                                </IconButton>
                            </ThemeProvider>
                        </Box>
                        <Box
                            sx={themes.contentBlockContainerStyle}
                            ref={boxRefs[1]}
                        >
                            {currentContent()}
                        </Box>
                        <Box
                            sx={themes.navContainerStyle(currentElementIndex === props.content.length - 1)}
                            ref={boxRefs[2]}
                            onClick={handleClickNext}
                        >
                            <ThemeProvider theme={themes.NavIconTheme(1)}>
                                <IconButton disableRipple>
                                    <ArrowForwardIos></ArrowForwardIos>
                                </IconButton>
                            </ThemeProvider>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </ThemeProvider>
    );
});

export default memo(ModalCarousel);
