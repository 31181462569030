export const TopCardWrapper = {
    display: "flex",
    justifyContent: "space-between",
};

export const TargetSubjWrapper = {
    display: "flex",
    gap: "4px",
};

export const AvatarPriceWrapper = {
    marginBottom: "12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const Price = {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0em",
};

export const divider = { marginBottom: "10px", color: "#EEEEF0" };

export const BottomCardWrapper = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const TitleBlock = {
    maxWidth: "478px",
};
