import React from "react";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { memo } from "react";
import TutorPromoBodyBlock from "components/TutorPromoBodyBlock/TutorPromoBodyBlock";

const TutorPromoPage = ({ children, ...props }) => {
    return (
        <ScrollToTop>
            <TutorPromoBodyBlock />
        </ScrollToTop>
    );
};

export default memo(TutorPromoPage);
