export const IconCloseMini = () => {
    return (
        <svg
            width="3vh"
            height="3vh"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.7559 8.42259C17.0814 8.09715 17.0814 7.56951 16.7559 7.24408C16.4305 6.91864 15.9028 6.91864 15.5774 7.24408L12 10.8215L8.42259 7.24408C8.09715 6.91864 7.56951 6.91864 7.24408 7.24408C6.91864 7.56951 6.91864 8.09715 7.24408 8.42259L10.8215 12L7.24408 15.5774C6.91864 15.9028 6.91864 16.4305 7.24408 16.7559C7.56951 17.0814 8.09715 17.0814 8.42259 16.7559L12 13.1785L15.5774 16.7559C15.9028 17.0814 16.4305 17.0814 16.7559 16.7559C17.0814 16.4305 17.0814 15.9028 16.7559 15.5774L13.1785 12L16.7559 8.42259Z"
                fill="#9E9EB0"
            />
        </svg>
    );
};
