import React from "react";

const VisaIcon = () => {
    return (
        <svg
            width="48"
            height="30"
            viewBox="0 0 48 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="0.5"
                width="47"
                height="29"
                rx="4.5"
                stroke="#DADADF"
            />
            <g clipPath="url(#clip0_542_22341)">
                <path
                    d="M28.8375 10.0156C26.5677 10.0156 24.5393 11.1261 24.5393 13.1777C24.5393 15.5305 28.1368 15.693 28.1368 16.875C28.1368 17.3727 27.5325 17.8182 26.5004 17.8182C25.0357 17.8182 23.9409 17.1957 23.9409 17.1957L23.4725 19.266C23.4725 19.266 24.7336 19.7919 26.408 19.7919C28.8896 19.7919 30.8424 18.6269 30.8424 16.5402C30.8424 14.054 27.2299 13.8963 27.2299 12.7992C27.2299 12.4094 27.726 11.9822 28.7551 11.9822C29.9163 11.9822 30.8637 12.4349 30.8637 12.4349L31.3221 10.4353C31.3221 10.4353 30.2913 10.0156 28.8375 10.0156ZM8.07059 10.1665L8.01562 10.4684C8.01562 10.4684 8.97056 10.6333 9.83063 10.9624C10.938 11.3397 11.0169 11.5593 11.2034 12.2416L13.2358 19.6362H15.9602L20.1573 10.1665H17.4391L14.7422 16.605L13.6418 11.1475C13.5408 10.5228 13.0296 10.1665 12.4039 10.1665H8.07059ZM21.2503 10.1665L19.118 19.6362H21.71L23.8347 10.1665H21.2503ZM35.7066 10.1665C35.0816 10.1665 34.7504 10.4824 34.5074 11.0343L30.71 19.6362H33.4281L33.954 18.2026H37.2655L37.5853 19.6362H39.9836L37.8913 10.1665H35.7066ZM36.0601 12.725L36.8658 16.2785H34.7073L36.0601 12.725Z"
                    fill="#1434CB"
                />
            </g>
            <defs>
                <clipPath id="clip0_542_22341">
                    <rect
                        width="32"
                        height="9.80645"
                        fill="white"
                        transform="translate(8 10)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default VisaIcon;
