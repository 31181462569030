import React, { memo } from "react";
import * as themes from "./TopFilterCardThemes";
import { Card } from "@mui/material";
import HeaderPart from "components/CatalogBodyBlock/TopFilterCard/HeaderPart/HeaderPart";
import PopularPart from "components/CatalogBodyBlock/TopFilterCard/PopularPart/PopularPart";
import FilterPart from "components/CatalogBodyBlock/TopFilterCard/FilterPart/FilterPart";
import { useMedias } from "API/mediasHook";

const TopFilterCard = ({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Card sx={themes.filtersCardStyle(medias)}>
            <HeaderPart></HeaderPart>
            {medias.sm && <PopularPart></PopularPart>}
            <FilterPart></FilterPart>
        </Card>
    );
};

export default memo(TopFilterCard);
