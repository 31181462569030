import { styleProcessing } from "API/plugins/Utilities";

export const StudyListItem = (theme) => {
    let commonStyle = {
        background: "#FFF",
        borderRadius: "10px",
        display: "flex",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            position: "relative",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const LeftIndicator = (type, reschedule) => {
    return {
        background: type === "upcoming" ? (reschedule ? "#FF9212" : "#6212FF") : "#BBBBC8",
        width: "4px",
        borderRadius: "20px 0px 0px 20px",
    };
};

export const StudyListItemContent = (theme) => {
    let commonStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        padding: "16px 20px 16px 16px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "12px",
            flexDirection: "column",
            alignItems: "left",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const LeftPanel = {
    display: "flex",
    gap: "16px",
};

export const RightPanel = {
    display: "flex",
    alignItems: "center",
    gap: "35px",
};

export const RightPanelContent = {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    width: "174px",
};

export const TooltipPopperProps = {
    modifiers: [
        {
            name: "offset",
            options: {
                offset: [0, -10],
            },
        },
    ],
    sx: {
        "& .MuiTooltip-tooltip": {
            border: "none",
            color: "#FFF",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "140%",
            borderRadius: "42px",
            background: "rgba(23, 15, 38, 0.30)",
            backdropFilter: "blur(3.4033873081207275px)",
            width: "fit-content",
            padding: "3px 10px 4px 10px",
        },
    },
};

export const IconButton = (theme) => {
    let commonStyle = {
        padding: 0,
        margin: 0,
        cursor: "default",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            position: "absolute",
            right: "16px",
            bottom: "16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ratingBlock = (rating, theme) => {
    let commonStyle = {
        display: "flex",
        alignItems: "center",
        gap: "4px",
        backgroundColor: rating > 2 ? "#F1B063" : "#c0c0c0",
        borderRadius: "6px",
        padding: "2px 10px 3px 10px",
        color: "white",
        height: "fit-content",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            position: "absolute",
            top: "16px",
            right: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Rating = (theme) => {
    let commonStyle = {
        display: "flex",
        alignItems: "center",
        gap: "20px",
        flexDirection: "row",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            alignItems: "flex-start",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const RatingTutor = (theme) => {
    let commonStyle = {
        paddingLeft: "20px",
        display: "flex",
        gap: "8px",
        alignItems: "center",
        width: "174px",
        borderLeft: "1px solid #EEEEF0",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            paddingLeft: "0px",
            borderLeft: "none",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const SubjectDate = {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
};

export const ratingText = {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "150%",
};

export const RateButton = {
    minWidth: "90px",
};

export const RescheduleTimes = {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
};

export const DropMenuWrapper = (theme) => {
    let commonStyle = {
        padding: 0,
        margin: 0,
        cursor: "default",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            position: "absolute",
            top: 10,
            right: 16,
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
