export const Step14Wrapper = { width: "495px" };

export const FormWrapper = {
    display: "flex",
    alignItems: "start",
    marginTop: "-24px",
};

export const FormControlLabel = { p: 0, mr: 0, ml: 0, mb: 0, mt: "3px" };

export const Checkbox = { p: 0, m: 0 };

export const Text = {
    marginLeft: "10px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "150%",
    fontWeight: 400,
    textTransform: "none",
    color: "#222",
    width: "420px",
};
