import React, { memo } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import OrderBodyBlock from "components/OrderBodyBlock/OrderBodyBlock";
import OtherOrdersBlock from "components/OrderBodyBlock/OtherOrdersBlock/OtherOrdersBlock";
import Box from "@mui/material/Box";

const theme = createTheme({
    spacing: 1,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const OrderPage = ({ props }) => {
    const { id } = useParams();

    console.log(id);

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ background: "#F3F3F3", display: "flex", flexDirection: "column", alignItems: "center", borderRadius: "0px 0px 40px 40px" }}>
                <OrderBodyBlock orderID={id}></OrderBodyBlock>
                <OtherOrdersBlock></OtherOrdersBlock>
            </Box>
        </ThemeProvider>
    );
};

export default memo(OrderPage);
