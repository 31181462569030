export const TopQuestionsBlock = (medias) => {
    return {
        width: medias.sm ? "1216px" : "328px",
        display: "flex",
        gap: "20px",
        flexDirection: medias.sm ? "unset" : "column",
    };
};

export const AccordionBlock = {
    gap: "20px",
    display: "flex",
    flexDirection: "column",
};

export const QuestionOne = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const LinkButton = {
    cursor: "pointer",
};
