import { Box, Divider } from "@mui/material";
import React, { useEffect, useRef } from "react";
import * as theme from "./TimeScaleStyled";
const TimeScale = ({ isCurrentWeekForTime, currentTimeIndex, cellIndex, minutes }) => {
    const timeLine = minutes * 1.33;

    const timeScaleRef = useRef(null);

    const handleScrollToTimeScale = () => {
        if (timeScaleRef.current) {
            const windowHeight = window.innerHeight;

            const elementTop = timeScaleRef.current.getBoundingClientRect().top;

            const scrollToPosition = elementTop - windowHeight / 2;

            window.scrollTo({
                top: scrollToPosition,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        setTimeout(() => {
            handleScrollToTimeScale();
        }, 200);
    }, []);
    return (
        <>
            {isCurrentWeekForTime && currentTimeIndex === cellIndex && (
                <>
                    <Divider
                        sx={[theme.TimeScaleDivider, { top: timeLine }]}
                        ref={timeScaleRef}
                    />
                    <Box sx={[theme.TimeScaleCircle, { top: timeLine - 4.5 }]} />
                </>
            )}
        </>
    );
};

export default TimeScale;
