import React, { memo } from "react";
import * as themes from "./PendingClassCardThemes";
import * as textThemes from "themes/TextThemes";
import Box from "@mui/material/Box";
import { Avatar, Divider, Tooltip, Typography } from "@mui/material";
import RegularStudentIcon from "assets/TeacherClassesAssets/SVG/RegularStudentIcon";
import NewStudentIcon from "assets/TeacherClassesAssets/SVG/NewStudentIcon";
import { useTranslation } from "react-i18next";
import TutorClassesStore from "store/TutorClassesStore";
import Distant from "assets/PAAssets/SVG/distant";
import Home from "assets/PAAssets/SVG/home";
import { observer } from "mobx-react-lite";
import SButton from "components/UI/SButton";
import EmptyAvatar from "assets/TeacherClassesAssets/PNG/EmptyAvatar.png";
import { useMedias } from "API/mediasHook";

const PendingClassCard = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const getClassTimeRange = (classItem) => {
        const timeRange = TutorClassesStore.getTimeDifference(classItem);

        return `${timeRange} ${t("main.min")}`;
    };

    const handleClickConfirm = () => {
        TutorClassesStore.setCompleted(TutorClassesStore.classes.findIndex((object) => object.id === props.classItem.id));
        TutorClassesStore.ClassesUpdate();
    };

    const MobileVersion = (
        <Box sx={themes.PendingClassCard(medias)}>
            <Box sx={themes.LeftPanel(medias)}>
                <Box sx={themes.TimeBlock(medias)}>
                    <Typography sx={textThemes.Button14Black}>{TutorClassesStore.formatTimeRange(props.classItem)},</Typography>
                    <Typography sx={textThemes.Button14Black}>{getClassTimeRange(props.classItem)}</Typography>
                </Box>
                <Box sx={themes.PlaceSubjectBlock(medias)}>
                    <Box sx={themes.SubjectAimBlock(medias)}>
                        <Typography sx={textThemes.Caption1Grey}>{props.classItem.subject},</Typography>
                        <Typography sx={textThemes.Caption1Grey}>{props.classItem.aim}</Typography>
                    </Box>
                </Box>
            </Box>
            <Divider />
            <Box sx={themes.StudentBlock(medias)}>
                <Avatar
                    src={props.classItem.studentAvatar ? props.classItem.studentAvatar : EmptyAvatar}
                    sx={themes.Avatar(medias)}
                />
                {props.classItem.studentType === "constant" ? (
                    <Box sx={themes.StudentTextBox}>
                        <Box sx={themes.StudentNameBox}>
                            <Typography sx={textThemes.Body2Black}>{props.classItem.studentName}</Typography>
                            <Box sx={themes.IconBox}>
                                <RegularStudentIcon />
                            </Box>
                        </Box>
                        <Typography sx={textThemes.Caption2DarkGrey}>{t("teacherClasses.constantStudent")}</Typography>
                    </Box>
                ) : props.classItem.studentType === "new" ? (
                    <Box sx={themes.StudentTextBox}>
                        <Box sx={themes.StudentNameBox}>
                            <Typography sx={textThemes.Body2Black}>{props.classItem.studentName}</Typography>
                            <Box sx={themes.IconBox}>
                                <NewStudentIcon />
                            </Box>
                        </Box>
                        <Typography sx={textThemes.Caption2DarkGrey}>{t("teacherClasses.newStudent")}</Typography>
                    </Box>
                ) : (
                    <Typography sx={textThemes.Body2Black}>{props.classItem.studentName}</Typography>
                )}
            </Box>
            <SButton
                onClick={handleClickConfirm}
                variant="unshadowed"
                padding="7px 16px 8px 16px"
            >
                {t("teacherClasses.confirmCompletion")}
            </SButton>
        </Box>
    );

    return (
        <>
            {medias.sm ? (
                <Box sx={themes.PendingClassCard(medias)}>
                    <Box sx={themes.LeftPanel(medias)}>
                        <Box sx={themes.StudentBlock(medias)}>
                            <Avatar
                                src={props.classItem.studentAvatar ? props.classItem.studentAvatar : EmptyAvatar}
                                sx={themes.Avatar(medias)}
                            />
                            {props.classItem.studentType === "constant" ? (
                                <Box sx={themes.StudentTextBox}>
                                    <Box sx={themes.StudentNameBox}>
                                        <Typography sx={textThemes.Body2Black}>{props.classItem.studentName}</Typography>
                                        <Box sx={themes.IconBox}>
                                            <RegularStudentIcon />
                                        </Box>
                                    </Box>
                                    <Typography sx={textThemes.Caption2DarkGrey}>{t("teacherClasses.constantStudent")}</Typography>
                                </Box>
                            ) : props.classItem.studentType === "new" ? (
                                <Box sx={themes.StudentTextBox}>
                                    <Box sx={themes.StudentNameBox}>
                                        <Typography sx={textThemes.Body2Black}>{props.classItem.studentName}</Typography>
                                        <Box sx={themes.IconBox}>
                                            <NewStudentIcon />
                                        </Box>
                                    </Box>
                                    <Typography sx={textThemes.Caption2DarkGrey}>{t("teacherClasses.newStudent")}</Typography>
                                </Box>
                            ) : (
                                <Typography sx={textThemes.Body2Black}>{props.classItem.studentName}</Typography>
                            )}
                        </Box>
                        <Box sx={themes.Divider} />
                        <Box sx={themes.TimeBlock(medias)}>
                            <Typography sx={textThemes.Body2Black}>{TutorClassesStore.formatTimeRange(props.classItem)}</Typography>
                        </Box>
                        <Box sx={themes.Divider} />
                        <Box sx={themes.MinutesBlock}>
                            <Typography sx={textThemes.Body2Black}>{getClassTimeRange(props.classItem)}</Typography>
                        </Box>
                        <Box sx={themes.Divider} />
                        <Box sx={themes.PlaceSubjectBlock(medias)}>
                            {props.classItem.place === "online" ? (
                                <Tooltip
                                    title={t("teacherClasses.online")}
                                    PopperProps={themes.TooltipPopperProps}
                                    placement="top"
                                >
                                    <Box sx={themes.PlaceIconContainer}>
                                        <Distant />
                                    </Box>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={props.classItem.place === "tutor" ? t("teacherClasses.atTutor") : t("teacherClasses.atStudent")}
                                    PopperProps={themes.TooltipPopperProps}
                                    placement="top"
                                >
                                    <Box sx={themes.PlaceIconContainer}>
                                        <Home />
                                    </Box>
                                </Tooltip>
                            )}
                            <Box sx={themes.SubjectAimBlock(medias)}>
                                <Typography sx={textThemes.Body2Black}>{props.classItem.subject}</Typography>
                                <Typography sx={textThemes.Caption2Grey}>{props.classItem.aim}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <SButton
                        onClick={handleClickConfirm}
                        variant="unshadowed"
                        padding="7px 16px 8px 16px"
                    >
                        {t("teacherClasses.confirmCompletion")}
                    </SButton>
                </Box>
            ) : (
                <>{MobileVersion}</>
            )}
        </>
    );
});

export default memo(PendingClassCard);
