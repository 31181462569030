import React from "react";
import * as themes from "./TutorsTypeThemes";
import * as textThemes from "themes/TextThemes";
import { Typography } from "@mui/material";
import Type1 from "assets/PromoTutorAssets/PNG/Type1.png";
import Type2 from "assets/PromoTutorAssets/PNG/Type2.png";
import Type3 from "assets/PromoTutorAssets/PNG/Type3.png";
import TypeComp from "./TutorTypeComp/TypeComp";
import Box from "@mui/material/Box";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useMedias } from "../../../API/mediasHook";
import { columnBlockReverse } from "./TutorsTypeThemes";

const TutorsType = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const medias = useMedias();

    return (
        <Box sx={themes.TutorsTypeBlock(medias)}>
            <Typography sx={textThemes.HeadlineH2Black(medias)}>{t("promoPages.promoTutor.whoCanBecome")}</Typography>
            <Box sx={themes.TypesBlock(medias)}>
                <Box sx={themes.columnBlock}>
                    <Box sx={themes.Image1(medias)}>
                        <img
                            style={themes.Image1(medias)}
                            src={Type3}
                            alt={1}
                        />
                    </Box>
                    <TypeComp
                        height="198px"
                        header={t("promoPages.promoTutor.students")}
                        text={t("promoPages.promoTutor.getIncome")}
                    />
                </Box>
                <Box sx={themes.columnBlockReverse(medias)}>
                    <TypeComp
                        height={medias.sm ? "246px" : "315px"}
                        header={t("promoPages.promoTutor.specialists")}
                        text={t("promoPages.promoTutor.bothWorkAndTutoring")}
                    />
                    <Box>
                        <img
                            style={themes.Image2(medias)}
                            src={Type2}
                            alt={2}
                        />
                    </Box>
                </Box>
                <Box sx={themes.columnBlock}>
                    <Box>
                        <img
                            style={themes.Image1(medias)}
                            src={Type1}
                            alt={3}
                        />
                    </Box>
                    <TypeComp
                        height={medias.sm ? "198px" : "222"}
                        header={t("promoPages.promoTutor.experiencedTutors")}
                        text={t("promoPages.promoTutor.easyFindStudents")}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default memo(TutorsType);
