import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import * as themes from "./CurrentMonthAchievementsThemes";
import { useMedias } from "API/mediasHook";

const CurrentMonthAchievements = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.WhiteContainer(props.width, medias)}>
            <Box sx={themes.TopBlock}>
                <Typography sx={textThemes.Body2Black}>{t(props.header)}</Typography>
                <Typography sx={textThemes.Body2Black}>
                    <span style={textThemes.HeadlineH5Black}>{props.top}</span> {t("teacherAccount.privilegeProgram.points")}
                </Typography>
            </Box>
            <Typography sx={textThemes.Body2DarkGrey}>{`${props.number === 0 ? "" : props.number}${t(props.bottom)}`}</Typography>
        </Box>
    );
});

export default memo(CurrentMonthAchievements);
