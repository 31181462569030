import { observer } from "mobx-react-lite";
import React, { memo } from "react";
import * as themes from "./ModalSomethingLeftThemes";
import * as textThemes from "themes/TextThemes";
import { Link, Box, Typography } from "@mui/material";
import ModalSettings from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsBig/ModalSettings";
import { useTranslation } from "react-i18next";
import SButton from "components/UI/SButton";
import TeacherSettingsStore from "store/TeacherSettingsStore";
import DrawerHeader from "components/SettingsBodyBlock/StudentSettings/DrawerHeader/DrawerHeader";
import MyClassesDrawer from "components/MyClassesBodyBlock/MyClassesDrawer";
import { useMedias } from "API/mediasHook";

const ModalSomethingLeft = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const formatDate = (date) => {
        const monthList = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];

        const day = String(date.getDate()).padStart(2, "0");

        const month = date.getMonth();

        return `${day} ${monthList[month]}`;
    };

    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        props.handleSave();
    };

    const DrawerList = (
        <Box sx={{ maxHeight: "640px", boxSizing: "border-box" }}>
            <DrawerHeader
                text={t("teacherSettings.accountActions.somethingImportant")}
                handleClose={Close}
            />
            <Box sx={{ padding: "20px 16px", height: "100%", boxSizing: "border-box" }}>
                <Box sx={themes.TopBlock}>
                    <Typography sx={textThemes.Body2Black}>{t("teacherSettings.accountActions.youCannotDelete")}</Typography>
                    <Box
                        component="ul"
                        sx={themes.Ul}
                    >
                        {TeacherSettingsStore.leftOnAccount.map((element) => {
                            return (
                                <Box
                                    component="li"
                                    key={element.key}
                                >
                                    <Typography
                                        sx={textThemes.Body2Green}
                                    >{`${element.type === "order" ? t("teacherSettings.accountActions.orderInWork") : t("teacherSettings.accountActions.upcomingClass")}${element.name} ${element.date === null ? "" : formatDate(element.date)}`}</Typography>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
                <Box sx={themes.BottomBlock}>
                    <Typography sx={textThemes.Body2Black}>
                        {t("teacherSettings.accountActions.toDeletePart1")}
                        <Link
                            underline="none"
                            sx={themes.Link}
                        >
                            {t("teacherSettings.accountActions.privacyPolicy")}
                        </Link>
                        {t("teacherSettings.accountActions.toDeletePart2")}
                    </Typography>
                    <SButton
                        onClick={Save}
                        variant="arrow"
                        padding="10px 20px 11px 20px"
                        style={themes.Button}
                    >
                        {t("teacherSettings.accountActions.goToSupport")}
                    </SButton>
                </Box>
            </Box>
        </Box>
    );

    return (
        <>
            {" "}
            {medias.sm ? (
                <ModalSettings
                    visible={props.open}
                    handleClose={Close}
                    header={t("teacherSettings.accountActions.somethingImportant")}
                >
                    <Box>
                        <Box sx={themes.TopBlock}>
                            <Typography sx={textThemes.Body2Black}>{t("teacherSettings.accountActions.youCannotDelete")}</Typography>
                            <Box
                                component="ul"
                                sx={themes.Ul}
                            >
                                {TeacherSettingsStore.leftOnAccount.map((element) => {
                                    return (
                                        <Box
                                            component="li"
                                            key={element.key}
                                        >
                                            <Typography
                                                sx={textThemes.Body2Green}
                                            >{`${element.type === "order" ? t("teacherSettings.accountActions.orderInWork") : t("teacherSettings.accountActions.upcomingClass")}${element.name} ${element.date === null ? "" : formatDate(element.date)}`}</Typography>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>
                        <Box sx={themes.BottomBlock}>
                            <Typography sx={textThemes.Body2Black}>
                                {t("teacherSettings.accountActions.toDeletePart1")}
                                <Link
                                    underline="none"
                                    sx={themes.Link}
                                >
                                    {t("teacherSettings.accountActions.privacyPolicy")}
                                </Link>
                                {t("teacherSettings.accountActions.toDeletePart2")}
                            </Typography>
                            <SButton
                                onClick={Save}
                                variant="arrow"
                                padding="10px 20px 11px 20px"
                                style={themes.Button}
                            >
                                {t("teacherSettings.accountActions.goToSupport")}
                            </SButton>
                        </Box>
                    </Box>
                </ModalSettings>
            ) : (
                <MyClassesDrawer
                    open={props.open}
                    close={Close}
                >
                    {DrawerList}
                </MyClassesDrawer>
            )}
        </>
    );
});

export default memo(ModalSomethingLeft);
