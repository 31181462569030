const aimsTypes = {
    "Подготовка к A-Level": "class",
    "Подготовка к A1 Movers": "class",
    "Подготовка к A2 Key": "class",
    "Подготовка к B1 Preliminary": "class",
    "Подготовка к BEC": "class",
    "Подготовка к BULATS": "class",
    "Подготовка к C1 Advanced": "class",
    "Подготовка к CELPIP": "class",
    "Подготовка к Duolingo English Test": "class",
    "Подготовка к GCSE": "class",
    "Подготовка к GRE": "class",
    "Подготовка к IELTS": "class",
    "Подготовка к IELTS General": "class",
    "Подготовка к ILEC": "class",
    "Подготовка к LCCI": "class",
    "Подготовка к Linguaskill": "class",
    "Подготовка к Linguaskill General": "class",
    "Подготовка к PTE": "class",
    "Подготовка к SAT": "class",
    "Подготовка к TEFL": "class",
    "Подготовка к TOEFL": "class",
    "Подготовка к YLE": "class",
    "Подготовка к ГВЭ-9": "class",
    "Подготовка к ЕГЭ": "class",
    "Подготовка к МЦКО": "class",
    "Авиационный английский": "class",
    "Академический английский": "class",
    "Американское произношение": "class",
    "Английский для архитекторов": "class",
    "Английский для гостиничного бизнеса": "class",
    "Английский для журналистов": "class",
    "Английский для маркетинга": "class",
    "Английский для нефтяников": "class",
    "Английский для программистов": "class",
    "Английский для путешествий": "class",
    "Английский разговорный клуб": "class",
    "Аудирование по английскому языку": "class",
    "Обучение британскому произношение": "class",
    "Медицинский английский": "class",
    "Морской английский": "class",
    Олимпиады: "class",
    "Политический английский": "class",
    "Разговорный английский": "class",
    "Технический английский": "class",
    "Экономический английский": "class",
    "Подготовка к A.C.T.": "class",
    "Подготовка к A2 Flyers": "class",
    "Подготовка к AP English Language and Composition": "class",
    "Подготовка к B2 First": "class",
    "Подготовка к BMAT": "class",
    "Подготовка к Business English": "class",
    "Подготовка к C2 Proficient": "class",
    "Подготовка к CELTA": "class",
    "Подготовка к ESOL": "class",
    "Подготовка к GMAT": "class",
    "Подготовка к ICFE": "class",
    "Подготовка к IELTS Academic": "class",
    "Подготовка к IGCSE": "class",
    "Подготовка к IMAT": "class",
    "Подготовка к LSAT": "class",
    "Подготовка к Linguaskill Business": "class",
    "Подготовка к Linguaskill Reading and Listening": "class",
    "Подготовка к Pre A1 Starters": "class",
    "Подготовка к SSAT": "class",
    "Подготовка к TKT": "class",
    "Подготовка к TOEIC": "class",
    "Подготовка к ВПР": "class",
    "Подготовка к ДВИ": "class",
    "Подготовка к МЦКО для учителей": "class",
    "Подготовка к ОГЭ": "class",
    "Австралийский английский язык": "class",
    "Американский английский язык": "class",
    "Английская литература": "class",
    "Английский для бортпроводников": "class",
    "Английский для дошкольников": "class",
    "Английский для логистов": "class",
    "Английский для младших школьников": "class",
    "Английский для пилотов": "class",
    "Английский для психологов": "class",
    "Английский для ресторанного сервиса": "class",
    "Английский язык для дизайнеров": "class",
    "Британский английский язык": "class",
    "Древнеанглийский язык": "class",
    "Международные экзамены": "class",
    "Проверка домашнего задания": "class",
    "Произношение английского языка": "class",
    "Спортивный английский": "class",
    "Юридический английский": "class",
    "Для себя": "class",
    "Для работы": "class",
    "Подготовка к экзаменам в университете": "class",
    "Разбор темы": "class",
    "Повышение успеваемости": "class",
    "Написание рефератов": "work",
    "Написание эссе": "work",
    "Написание дипломных": "work",
    "Написание курсовых": "work",
    "Написание сочинений": "work",
    "Подготовка презентаций": "work",
    "Подготовка докладов": "work",
    "Подготовка к поступлению в университет": "class",
    "Написание письма": "work",
    "Разговорный арабский": "class",
    Таджвид: "class",
    "Сирийский язык": "class",
    "Подготовка к TOAFL": "class",
    "Арабский для предпринимателей": "class",
    "Изучение арабского с нуля": "class",
    "Углубленное изучение грамматики и синтаксиса": "class",
    "Разговорный белорусский": "class",
    "Белорусский для предпринимателей": "class",
    "Изучение белорусского с нуля": "class",
    "Подготовка к IB Biology": "class",
    "Общая биология": "class",
    "Повышение квалификации": "class",
    "Подготовка к аспирантуре": "class",
    "Биология на английском языке": "class",
    "Подготовка к международным экзаменам": "class",
    "Общая биоинформатика": "class",
    "Биоинформатика на английском языке": "class",
    "Общая биофизика": "class",
    "Биофизика на английском языке": "class",
    "Общая генетика": "class",
    "Генетика на английском языке": "class",
    "Общая зоология": "class",
    "Зоология на английском языке": "class",
    "Общая молекулярная биология": "class",
    "Молекулярная биология на английском языке": "class",
    "Общая цитология": "class",
    "Цитология на английском языке": "class",
    "Общая энтомология": "class",
    "Энтомология на английском языке": "class",
    "Общая орнитология": "class",
    "Орнитология на английском языке": "class",
    "Общая природоведение": "class",
    "Природоведение на английском языке": "class",
    "Общая нейрофизиология": "class",
    "Нейрофизиология на английском языке": "class",
    "Общая гистология": "class",
    "Гистология на английском языке": "class",
    "Общая физиология": "class",
    "Физиология на английском языке": "class",
    "Общая анатомия": "class",
    "Анатомия на английском языке": "class",
    "Общая биотехнология": "class",
    "Биотехнология на английском языке": "class",
    "Общая ботаника": "class",
    "Ботаника на английском языке": "class",
    "Общая герпетология": "class",
    "Герпетология на английском языке": "class",
    "Общая микробиология": "class",
    "Микробиология на английском языке": "class",
    "Общая вирусология": "class",
    "Вирусология на английском языке": "class",
    "Общая микология": "class",
    "Микология на английском языке": "class",
    "Общая экология": "class",
    "Экология на английском языке": "class",
    "Общая палеонтология": "class",
    "Палеонтология на английском языке": "class",
    "Общая почвоведение": "class",
    "Почвоведение на английском языке": "class",
    Москвоведение: "class",
    Петербурговедение: "class",
    "Политическая география": "class",
    "Социальная география": "class",
    "Физическая география": "class",
    "Экономическая география": "class",
    "Обучение MapInfo": "class",
    "Геоинформационные системы": "class",
    "Общая география": "class",
    "География на английском языке": "class",
    "Общая геология": "class",
    "Геология на английском языке": "class",
    "Инженерная геология": "class",
    "Общая картография": "class",
    "Картография на английском языке": "class",
    "Общая кристаллография": "class",
    "Кристаллография на английском языке": "class",
    "Общая геодезия": "class",
    "Геодезия на английском языке": "class",
    "Общая геоинформатика": "class",
    "Геоинформатика на английском языке": "class",
    "Общая геополитика": "class",
    "Геополитика на английском языке": "class",
    "Общее краеведение": "class",
    "Краеведение на английском языке": "class",
    "Общая минералогия": "class",
    "Минералогия на английском языке": "class",
    "Общая навигация": "class",
    "Навигация на английском языке": "class",
    "Общая геофизика": "class",
    "Геофизика на английском языке": "class",
    "Общая топография": "class",
    "Топография на английском языке": "class",
    "Общее страноведение": "class",
    "Страноведение на английском языке": "class",
    "Общая фотограмметрия": "class",
    "Фотограмметрия на английском языке": "class",
    "Разговорный абхазский": "class",
    "Абхазский для предпринимателей": "class",
    "Изучение абхазского с нуля": "class",
    "Разговорный адыгейский": "class",
    "Адыгейский для предпринимателей": "class",
    "Изучение адыгейского с нуля": "class",
    "Разговорный албанский": "class",
    "Албанский для предпринимателей": "class",
    "Изучение албанский с нуля": "class",
    "Разговорный армянский": "class",
    "Армянский для предпринимателей": "class",
    "Изучение армянского с нуля": "class",
    "Разговорный башкирский": "class",
    "Башкирский для предпринимателей": "class",
    "Изучение башкирского с нуля": "class",
    "Разговорный бирманский": "class",
    "Бирманский для предпринимателей": "class",
    "Изучение бирманского с нуля": "class",
    "Разговорный боснийский": "class",
    "Боснийский для предпринимателей": "class",
    "Изучение боснийского с нуля": "class",
    "Разговорный венгерский": "class",
    "Венгерский для предпринимателей": "class",
    "Изучение венгерского с нуля": "class",
    "Разговорный голландский": "class",
    "Голландский для предпринимателей": "class",
    "Изучение голландского с нуля": "class",
    "Подготовка к CNaVT": "class",
    "Разговорный грузинский": "class",
    "Грузинский для предпринимателей": "class",
    "Изучение грузинского с нуля": "class",
    "Разговорный датский": "class",
    "Датский для предпринимателей": "class",
    "Изучение датского с нуля": "class",
    "Подготовка к NT2": "class",
    "Разговорный древнееврейский": "class",
    "Древнееврейский для предпринимателей": "class",
    "Изучение древнееврейского с нуля": "class",
    "Разговорный иврит": "class",
    "Иврит для предпринимателей": "class",
    "Изучение иврита с нуля": "class",
    "Разговорный ингушский": "class",
    "Ингушский для предпринимателей": "class",
    "Изучение ингушского с нуля": "class",
    "Разговорный ирландский": "class",
    "Ирландский для предпринимателей": "class",
    "Изучение ирландского с нуля": "class",
    "Разговорный кабардино-черкесский": "class",
    "Кабардино-черкесский для предпринимателей": "class",
    "Изучение кабардино-черкесского с нуля": "class",
    "Разговорный карельский": "class",
    "Карельский для предпринимателей": "class",
    "Изучение карельского с нуля": "class",
    "Разговорный киргизский": "class",
    "Киргизский для предпринимателей": "class",
    "Изучение киргизского с нуля": "class",
    "Разговорный корейский": "class",
    "Корейский для предпринимателей": "class",
    "Изучение корейского с нуля": "class",
    "Подготовка к TOPIK": "class",
    "Разговорный кхмерский": "class",
    "Кхмерский для предпринимателей": "class",
    "Изучение кхмерского с нуля": "class",
    "Разговорный латышский": "class",
    "Латышский для предпринимателей": "class",
    "Изучение латышского с нуля": "class",
    "Разговорный литовский": "class",
    "Литовский для предпринимателей": "class",
    "Изучение литовского с нуля": "class",
    "Разговорный малайский": "class",
    "Малайский для предпринимателей": "class",
    "Изучение малайского с нуля": "class",
    "Разговорный молдавский": "class",
    "Молдавский для предпринимателей": "class",
    "Изучение молдавского с нуля": "class",
    "Разговорный мордовский": "class",
    "Мордовский для предпринимателей": "class",
    "Изучение мордовского с нуля": "class",
    "Разговорный новогреческий": "class",
    "Новогреческий для предпринимателей": "class",
    "Изучение новогреческого с нуля": "class",
    "Разговорный осетинский": "class",
    "Осетинский для предпринимателей": "class",
    "Изучение осетинского с нуля": "class",
    "Разговорный польский": "class",
    "Польский для предпринимателей": "class",
    "Изучение польского с нуля": "class",
    "Разговорный санскрит": "class",
    "Санскрит для предпринимателей": "class",
    "Изучение санскрита с нуля": "class",
    "Разговорный сингальский": "class",
    "Сингальский для предпринимателей": "class",
    "Изучение сингальского с нуля": "class",
    "Разговорный словенский": "class",
    "Словенский для предпринимателей": "class",
    "Изучение словенского с нуля": "class",
    "Разговорный таджикский": "class",
    "Таджикский для предпринимателей": "class",
    "Изучение таджикского с нуля": "class",
    "Разговорный татарский": "class",
    "Татарский для предпринимателей": "class",
    "Изучение татарского с нуля": "class",
    "Разговорный турецкий": "class",
    "Турецкий для предпринимателей": "class",
    "Изучение турецкого с нуля": "class",
    "Подготовка к YOS": "class",
    "Подготовка к TOMER": "class",
    "Разговорный удмуртский": "class",
    "Удмуртский для предпринимателей": "class",
    "Изучение удмуртского с нуля": "class",
    "Разговорный урду": "class",
    "Урду для предпринимателей": "class",
    "Изучение урду с нуля": "class",
    "Разговорный хорватский": "class",
    "Хорватский для предпринимателей": "class",
    "Изучение хорватского с нуля": "class",
    "Разговорный черногорский": "class",
    "Черногорский для предпринимателей": "class",
    "Изучение черногорского с нуля": "class",
    "Разговорный чешский": "class",
    "Чешский для предпринимателей": "class",
    "Изучение чешского с нуля": "class",
    "Разговорный шведский": "class",
    "Шведский для предпринимателей": "class",
    "Изучение шведского с нуля": "class",
    "Подготовка к Swedex": "class",
    "Подготовка к TISUS": "class",
    "Разговорный эсперанто": "class",
    "Эсперанто для предпринимателей": "class",
    "Изучение эсперанто с нуля": "class",
    "Разговорный якутский": "class",
    "Якутский для предпринимателей": "class",
    "Изучение якутского с нуля": "class",
    "Разговорный аварский": "class",
    "Аварский для предпринимателей": "class",
    "Изучение аварского с нуля": "class",
    "Разговорный азербайджанский": "class",
    "Азербайджанский для предпринимателей": "class",
    "Изучение азербайджанского с нуля": "class",
    "Разговорный амхарский": "class",
    "Амхарский для предпринимателей": "class",
    "Изучение амхарского с нуля": "class",
    "Разговорный арамейский": "class",
    "Арамейский для предпринимателей": "class",
    "Изучение арамейского с нуля": "class",
    "Разговорный африкаанс": "class",
    "Африкаанс для предпринимателей": "class",
    "Изучение африкаанс с нуля": "class",
    "Разговорный бенгальский": "class",
    "Бенгальский для предпринимателей": "class",
    "Изучение бенгальского с нуля": "class",
    "Разговорный болгарский": "class",
    "Болгарский для предпринимателей": "class",
    "Изучение болгарского с нуля": "class",
    "Разговорный бурятский": "class",
    "Бурятский для предпринимателей": "class",
    "Изучение бурятского с нуля": "class",
    "Разговорный вьетнамский": "class",
    "Вьетнамский для предпринимателей": "class",
    "Изучение вьетнамского с нуля": "class",
    "Разговорный греческий": "class",
    "Греческий для предпринимателей": "class",
    "Изучение греческого с нуля": "class",
    "Разговорный дари": "class",
    "Дари для предпринимателей": "class",
    "Изучение дари с нуля": "class",
    "Разговорный древнегреческий": "class",
    "Древнегреческий для предпринимателей": "class",
    "Изучение древнегреческого с нуля": "class",
    "Разговорный идиш": "class",
    "Идиш для предпринимателей": "class",
    "Изучение идиш с нуля": "class",
    "Разговорный индонезийский": "class",
    "Индонезийский для предпринимателей": "class",
    "Изучение индонезийского с нуля": "class",
    "Разговорный исландский": "class",
    "Исландский для предпринимателей": "class",
    "Изучение исландского с нуля": "class",
    "Разговорный карачаевский": "class",
    "Карачаевский для предпринимателей": "class",
    "Изучение карачаевского с нуля": "class",
    "Разговорный каталанский": "class",
    "Каталанский для предпринимателей": "class",
    "Изучение каталанского с нуля": "class",
    "Разговорный коми": "class",
    "Коми для предпринимателей": "class",
    "Изучение коми с нуля": "class",
    "Разговорный курдский": "class",
    "Курдский для предпринимателей": "class",
    "Изучение курдского с нуля": "class",
    "Разговорный лаосский": "class",
    "Лаосский для предпринимателей": "class",
    "Изучение лаосского с нуля": "class",
    "Разговорный лезгинский": "class",
    "Лезгинский для предпринимателей": "class",
    "Изучение лезгинского с нуля": "class",
    "Разговорный македонский": "class",
    "Македонский для предпринимателей": "class",
    "Изучение македонского с нуля": "class",
    "Разговорный марийский": "class",
    "Марийский для предпринимателей": "class",
    "Изучение марийского с нуля": "class",
    "Разговорный монгольский": "class",
    "Монгольский для предпринимателей": "class",
    "Изучение монгольского с нуля": "class",
    "Разговорный непальский": "class",
    "Непальский для предпринимателей": "class",
    "Изучение непальского с нуля": "class",
    "Разговорный норвежский": "class",
    "Норвежский для предпринимателей": "class",
    "Изучение норвежского с нуля": "class",
    "Разговорный персидский": "class",
    "Персидский для предпринимателей": "class",
    "Изучение персидского с нуля": "class",
    "Разговорный пушту": "class",
    "Пушту для предпринимателей": "class",
    "Изучение пушту с нуля": "class",
    "Разговорный румынский": "class",
    "Румынский для предпринимателей": "class",
    "Изучение румынского с нуля": "class",
    "Разговорный сербский": "class",
    "Сербский для предпринимателей": "class",
    "Изучение сербского с нуля": "class",
    "Разговорный словацкий": "class",
    "Словацкий для предпринимателей": "class",
    "Изучение словацкого с нуля": "class",
    "Разговорный суахили": "class",
    "Суахили для предпринимателей": "class",
    "Изучение суахили с нуля": "class",
    "Разговорный тайский": "class",
    "Тайский для предпринимателей": "class",
    "Изучение тайского с нуля": "class",
    "Разговорный тибетский": "class",
    "Тибетский для предпринимателей": "class",
    "Изучение тибетского с нуля": "class",
    "Разговорный туркменский": "class",
    "Туркменский для предпринимателей": "class",
    "Изучение туркменского с нуля": "class",
    "Разговорный узбекский": "class",
    "Узбекский для предпринимателей": "class",
    "Изучение узбекского с нуля": "class",
    "Разговорный финский": "class",
    "Финский для предпринимателей": "class",
    "Изучение финского с нуля": "class",
    "Разговорный чеченский": "class",
    "Чеченский для предпринимателей": "class",
    "Изучение чеченского с нуля": "class",
    "Разговорный чувашский": "class",
    "Чувашский для предпринимателей": "class",
    "Изучение чувашского с нуля": "class",
    "Разговорный шотландский": "class",
    "Шотландский для предпринимателей": "class",
    "Изучение шотландского с нуля": "class",
    "Разговорный эстонский": "class",
    "Эстонский для предпринимателей": "class",
    "Изучение эстонского с нуля": "class",
    "Академический рисунок": "class",
    Граттаж: "class",
    Композиция: "class",
    "Обучение акварельной живописи": "class",
    "Обучение аэрографии": "class",
    "Обучение живописи": "class",
    "Обучение живописи мастихином": "class",
    "Обучение иллюстрации": "class",
    "Обучение китайской живописи": "class",
    "Обучение леттерингу": "class",
    "Обучение масляной живописи": "class",
    "Обучение мультипликации": "class",
    "Обучение рисованию": "class",
    "Обучение рисованию комиксов": "class",
    "Обучение рисованию на воде": "class",
    "Обучение рисованию песком": "class",
    "Обучение рисованию тушью": "class",
    "Обучение рисованию эпоксидной смолой": "class",
    "Обучение скульптуре": "class",
    "Обучение флористике": "class",
    "Обучение художественной графике": "class",
    "Обучение энкаустике": "class",
    Офорт: "class",
    Пленэр: "class",
    "Подготовка в художественную школу": "class",
    "Подготовка к художественным конкурсам, выставкам": "class",
    "Проектная деятельность по ИЗО": "class",
    "Художественные экзамены": "class",
    "Японская каллиграфия": "class",
    "Арабская каллиграфия": "class",
    "Китайская каллиграфия": "class",
    "Конструктивный рисунок": "class",
    "Обучение акриловой живописи": "class",
    "Обучение граффити": "class",
    "Обучение живописи гуашью": "class",
    "Обучение иконописи": "class",
    "Обучение каллиграфии": "class",
    "Обучение коллажу": "class",
    "Обучение линогравюре": "class",
    "Обучение монументальной живописи": "class",
    "Обучение реставрации": "class",
    "Обучение рисованию карандашом": "class",
    "Обучение рисованию маркером": "class",
    "Обучение рисованию пастелью": "class",
    "Обучение рисованию ручкой": "class",
    "Обучение рисованию углём": "class",
    "Обучение скетчингу": "class",
    "Обучение темперной живописи": "class",
    "Обучение фуд-флористике": "class",
    "Обучение цветоведению": "class",
    "Основы перспективы": "class",
    "Пластическая анатомия": "class",
    "Подготовка в художественное училище": "class",
    "Подготовка в художественный ВУЗ": "class",
    "Правополушарное рисование": "class",
    Сценография: "class",
    "Подготовка к DELE": "class",
    "Подготвока к ЕГЭ": "class",
    "Аргентинский испанский язык": "class",
    "Латиноамериканский испанский язык": "class",
    "Подготовка к SIELE": "class",
    "Подготовак к ОГЭ": "class",
    "Кубинский диалект": "class",
    "Медицинский испанский": "class",
    "Юридический испанский": "class",
    "Разговорный испанский": "class",
    "Испанский для предпринимателей": "class",
    "Изучение испанского с нуля": "class",
    "Подготовка к CELI": "class",
    "Подготовка к PLIDA": "class",
    "Итальянский для путешествий": "class",
    "Подготовка к CILS": "class",
    "Разговорный итальянский": "class",
    "Итальянский для предпринимателей": "class",
    "Изучение итальянского с нуля": "class",
    "Разговорный казахский": "class",
    "Казахский для предпринимателей": "class",
    "Изучение казахского с нуля": "class",
    "Подготовка к BCT": "class",
    "Подготовка к HSKK": "class",
    "Китайская литература": "class",
    "Фонетика китайского языка": "class",
    "Подготовка к HSK": "class",
    "Подготовка к YCT": "class",
    Вэньянь: "class",
    "Китайская иероглифика": "class",
    "Технический китайский": "class",
    "Разговорный китайский": "class",
    "Китайский для предпринимателей": "class",
    "Изучение китайского с нуля": "class",
    "Медицинская латынь": "class",
    "Разговорная латынь": "class",
    "Изучение латыни с нуля": "class",
    "Подготовка к Abitur": "class",
    "Подготовка к DSH": "class",
    "Подготовка к GDS": "class",
    "Подготовка к KDS": "class",
    "Подготовка к OnDaF": "class",
    "Подготовка к Start Deutsch": "class",
    "Подготовка к TestAS": "class",
    "Подготовка к ZD": "class",
    "Подготовка к ZOP": "class",
    "Немецкий для дошкольников": "class",
    "Технический немецкий": "class",
    "Юридический немецкий": "class",
    "Подготовка к DSD": "class",
    "Подготовка к Fit in Deutsch": "class",
    "Подготовка к Goethe-Zertifikat": "class",
    "Подготовка к OSD": "class",
    "Подготовка к Sprachtest": "class",
    "Подготовка к TELC": "class",
    "Подготовка к TestDaF": "class",
    "Подготовка к ZMP": "class",
    "Медицинский немецкий": "class",
    "Немецкая литература": "class",
    "Немецкий для путешествий": "class",
    "Политический немецкий": "class",
    "Экономический немецкий": "class",
    "Разговорный немецкий": "class",
    "Немецкий для предпринимателей": "class",
    "Изучение немецкого с нуля": "class",
    "Подготовка к TORFL": "class",
    "Подготовка к итоговому сочинению": "class",
    "Подготовка к изложению": "class",
    Правописание: "class",
    Пунктуация: "class",
    "Стилистика русского языка": "class",
    "Подготовка к ГВЭ-11": "class",
    "Древнерусский язык": "class",
    "Коррекция почерка": "class",
    "Подготовка к итоговому собеседованию": "class",
    "Старославянский язык": "class",
    "Церковнославянский язык": "class",
    "Разговорный русский": "class",
    "Русский для предпринимателей": "class",
    "Изучение русского с нуля": "class",
    "Подготовка к DALF": "class",
    "Подготовка к TCF": "class",
    "Подготовка к TEFaQ": "class",
    "Французский для дошкольников": "class",
    "Подготовка к DELF": "class",
    "Подготовка к TEF": "class",
    "Произношение французского языка": "class",
    "Технический французский": "class",
    "Французская литература": "class",
    "Юридический французский": "class",
    "Разговорный французский": "class",
    "Французский для предпринимателей": "class",
    "Изучение французского с нуля": "class",
    BJT: "class",
    "Древнеяпонский язык": "class",
    "Нихонго норёку сикэн": "class",
    "Японская иероглифика": "class",
    "Международные экзамены по японскому": "class",
    "Разговорный японский": "class",
    "Японский для предпринимателей": "class",
    "Изучение японского с нуля": "class",
    "История России": "class",
    "История государственного управления": "class",
    "История искусств": "class",
    "История на английском языке": "class",
    "Всемирная история": "class",
    "Подготовка к историческому сочинению": "class",
    "История архитектуры": "class",
    "История дизайна": "class",
    "История культуры": "class",
    "История религии": "class",
    "Речевой этикет": "class",
    "Семейный этикет": "class",
    "Столовый этикет": "class",
    "Школьный этикет": "class",
    "Дипломатический этикет": "class",
    "Воинский этикет": "class",
    "Религиозный этикет": "class",
    "Светский этикет": "class",
    "Сетевой этикет": "class",
    "Мобильный этикет": "class",
    "Деловой этикет": "class",
    "Источниковедение на английском языке": "class",
    "Театроведение на английском языке": "class",
    "Археология на английском языке": "class",
    "Востоковедение на английском языке": "class",
    "Искусствоведение на английском языке": "class",
    "Киноведение на английском языке": "class",
    "Музееведение на английском языке": "class",
    "Зарубежная литература": "class",
    "Литературное мастерство": "class",
    "Обучение стихосложению": "class",
    "Японская литература": "class",
    "Древнерусская литература": "class",
    "История литературы": "class",
    Литературоведение: "class",
    "Русская литература": "class",
    "Татарская литература": "class",
    "Литература на английском языке": "class",
    "Стиховедение на английском языке": "class",
    "Шахматные дебюты": "class",
    "Шахматы на английском языке": "class",
    "Обучение тренеров по шахматам": "class",
    "Подготовка к AP Calculus AB": "class",
    "Подготовка к AP Statistics": "class",
    "Подготовка к ЕГЭ (базовый уровень)": "class",
    "Подготовка к ЕГЭ (профильный уровень)": "class",
    "Актуарная математика": "class",
    "Вариационное исчисление": "class",
    "Векторное исчисление": "class",
    "Вычислительная математика": "class",
    "Дискретная математика": "class",
    "Дифференциальное исчисление": "class",
    "Интегральное исчисление": "class",
    "Интересные задачки": "class",
    "Математика на немецком языке": "class",
    "Математическая логика": "class",
    "Математический анализ": "class",
    "Методы оптимальных решений": "class",
    "Обучение SPSS": "class",
    "Обучение Statistica": "class",
    "Операционное исчисление": "class",
    "Регрессионный анализ": "class",
    "Тензорное исчисление": "class",
    "Теория вероятностей": "class",
    "Теория групп": "class",
    "Теория кодирования": "class",
    "Теория рядов": "class",
    "Углублённая математика": "class",
    "Финансовая математика": "class",
    "Функциональный анализ": "class",
    "Элементарная математика": "class",
    "Подготовка к AP Calculus BC": "class",
    "Подготовка к IB Math": "class",
    "Векторный анализ": "class",
    "Высшая математика": "class",
    "Дифференциальные уравнения": "class",
    "Интегральные уравнения": "class",
    "Исследование операций": "class",
    "Комплексный анализ": "class",
    "Математика на китайском": "class",
    "Математика на французском языке": "class",
    "Математическое программирование": "class",
    "Ментальная арифметика": "class",
    "Обучение EViews": "class",
    "Обучение Stata": "class",
    "Теория алгоритмов": "class",
    "Теория графов": "class",
    "Теория информации": "class",
    "Теория множеств": "class",
    "Теория чисел": "class",
    "Уравнения математической физики": "class",
    "Формальная логика": "class",
    "Численные методы": "class",
    Тригонометрия: "class",
    "Математика на английском языке": "class",
    Стереометрия: "class",
    Планиметрия: "class",
    "Математическая статистика": "class",
    "Линейное программирование": "class",
    Геометрия: "class",
    Алгебра: "class",
    "Булева алгебра": "class",
    "Векторная алгебра": "class",
    "Дифференциальная геометрия": "class",
    Топология: "class",
    "Линейная алгебра": "class",
    Комбинаторика: "class",
    "Компьютерная лингвистика": "class",
    "Высшая алгебра": "class",
    "Аналитическая геометрия": "class",
    "Тригонометрия на английском языке": "class",
    "Аналитическая тригонометрия": "class",
    "Графики тригонометрических функций": "class",
    "Решение тригонометрических уравнений": "class",
    "Основные тригонометрические функции": "class",
    "Экономическая статистика": "class",
    "Медицинская статистика": "class",
    "Статистика на английском языке": "class",
    "Подготовка к CAS": "class",
    "Подготовка к CStat": "class",
    "Подготовка к CAP": "class",
    "Геометрия на английском языке": "class",
    "Алгебра на английском языке": "class",
    "Линейное программирование на английском языке": "class",
    "Криптография на английском языке": "class",
    "Компьютерная лингвистика на английском языке": "class",
    "Теория вероятностей на английском языке": "class",
    "Майнд-фитнес": "class",
    "Обучение стенографии": "class",
    "Развитие креативности": "class",
    "Развитие памяти и внимания": "class",
    "Развитие творческих способностей": "class",
    "Слепая печать": "class",
    Мнемотехника: "class",
    "Осмысленное чтение": "class",
    "Развитие образного мышления": "class",
    "Развитие пространственного мышления": "class",
    Скорочтение: "class",
    "Подготовка к ДВИ творческий конкурс": "class",
    "Анализ музыкальных форм": "class",
    Гармония: "class",
    Гроулинг: "class",
    "Дошкольное музыкальное развитие": "class",
    "История музыки": "class",
    "Музыкальная импровизация": "class",
    "Музыкальная композиция": "class",
    Музыковедение: "class",
    "Нотная грамота": "class",
    "Обучение Guitar Pro": "class",
    "Обучение sound design": "class",
    "Обучение аккомпанированию": "class",
    "Обучение битбоксу": "class",
    "Обучение джазовому фортепиано": "class",
    "Обучение дирижированию оркестром": "class",
    "Обучение звукорежиссуре": "class",
    "Обучение игре на акустической гитаре": "class",
    "Обучение игре на арфе": "class",
    "Обучение игре на банджо": "class",
    "Обучение игре на бас-гитаре": "class",
    "Обучение игре на блокфлейте": "class",
    "Обучение игре на варгане": "class",
    "Обучение игре на виолончели": "class",
    "Обучение игре на волынке": "class",
    "Обучение игре на гитаре": "class",
    "Обучение игре на губной гармошке": "class",
    "Обучение игре на дарбуке": "class",
    "Обучение игре на домре": "class",
    "Обучение игре на испанской гитаре": "class",
    "Обучение игре на клавесине": "class",
    "Обучение игре на классической гитаре": "class",
    "Обучение игре на корнете": "class",
    "Обучение игре на курае": "class",
    "Обучение игре на мандолине": "class",
    "Обучение игре на органе": "class",
    "Обучение игре на саксофоне": "class",
    "Обучение игре на семиструнной гитаре": "class",
    "Обучение игре на ситаре": "class",
    "Обучение игре на тромбоне": "class",
    "Обучение игре на тубе": "class",
    "Обучение игре на фаготе": "class",
    "Обучение игре на флейте Пана": "class",
    "Обучение игре на флюгельгорне": "class",
    "Обучение игре на ханге": "class",
    "Обучение игре на электрогитаре": "class",
    "Обучение йодлю": "class",
    "Обучение мастерингу": "class",
    "Обучение сведению звука": "class",
    "Обучение специальному фортепиано": "class",
    "Обучение ударным инструментам": "class",
    "Обучение хоровому дирижированию": "class",
    "Обучение язычковым музыкальным инструментам": "class",
    "Олимпиады по сольфеджио": "class",
    "Повышение квалификации по музыке": "class",
    "Подготовка в музыкальную школу": "class",
    "Подготовка к музыкальным конкурсам": "class",
    "Постановка голоса и дыхания": "class",
    "Расширение диапазона": "class",
    "Рок-вокал": "class",
    "Сольное пение": "class",
    "Теория музыки": "class",
    "Церковное пение": "class",
    "Экскурсии по музыке": "class",
    "Эстрадный вокал": "class",
    "Академический вокал": "class",
    Вокал: "class",
    "Горловое пение": "class",
    "Джазовый вокал": "class",
    Инструментовка: "class",
    "Концертмейстерское искусство": "class",
    "Музыкальная информатика": "class",
    "Музыкальная литература": "class",
    "Народный вокал": "class",
    "Обучение Ableton Live": "class",
    "Обучение Logic Pro X": "class",
    "Обучение sound production": "class",
    "Обучение аранжировке": "class",
    "Обучение джазовой гитаре": "class",
    "Обучение дирижированию": "class",
    "Обучение духовым музыкальным инструментам": "class",
    "Обучение игре на аккордеоне": "class",
    "Обучение игре на альте": "class",
    "Обучение игре на балалайке": "class",
    "Обучение игре на барабанах": "class",
    "Обучение игре на баяне": "class",
    "Обучение игре на валторне": "class",
    "Обучение игре на вибрафоне": "class",
    "Обучение игре на вистле": "class",
    "Обучение игре на гармони": "class",
    "Обучение игре на гобое": "class",
    "Обучение игре на гуслях": "class",
    "Обучение игре на джембе": "class",
    "Обучение игре на дудуке": "class",
    "Обучение игре на кахоне": "class",
    "Обучение игре на кларнете": "class",
    "Обучение игре на контрабасе": "class",
    "Обучение игре на ксилофоне": "class",
    "Обучение игре на лютне": "class",
    "Обучение игре на окарине": "class",
    "Обучение игре на поперечной флейте": "class",
    "Обучение игре на свирели": "class",
    "Обучение игре на синтезаторе": "class",
    "Обучение игре на скрипке": "class",
    "Обучение игре на трубе": "class",
    "Обучение игре на укулеле": "class",
    "Обучение игре на флейте": "class",
    "Обучение игре на флейте-пикколо": "class",
    "Обучение игре на фортепиано": "class",
    "Обучение игре на цимбалах": "class",
    "Обучение игре на электроскрипке": "class",
    "Обучение клавишным музыкальным инструментам": "class",
    "Обучение нотным редакторам": "class",
    "Обучение слушанию музыки": "class",
    "Обучение струнным музыкальным инструментам": "class",
    "Обучение фингерстайлу": "class",
    "Обучение эстрадному фортепиано": "class",
    "Олимпиады по музыке": "class",
    "Оперный вокал": "class",
    "Подготовка в музыкальное училище": "class",
    "Подготовка в музыкальные вузы": "class",
    Полифония: "class",
    "Постановка рук": "class",
    "Развитие музыкального слуха": "class",
    Ритмика: "class",
    Скриминг: "class",
    Сольфеджио: "class",
    "Хоровое пение": "class",
    "Экзамены по музыке": "class",
    "Экстрим-вокал": "class",
    АООП: "class",
    "ВПР по математике в 4 классе": "class",
    "ВПР по русскому языку в 4 классе": "class",
    "МЦКО для учителей": "class",
    "МЦКО по окружающему миру": "class",
    "Планета знаний": "class",
    "Русская классическая школа": "class",
    "Школа 2100": "class",
    "Вальдорфская система": "class",
    "Начальная информатика": "class",
    "Начальный русский": "class",
    "Олимпиады по окружающему миру": "class",
    "Система Д.Б. Эльконина — В.В. Давыдова": "class",
    Чистописание: "class",
    "Бененсон Е.П.": "class",
    "ВПР по окружающему миру": "class",
    "Гейдман Б.П.": "class",
    "МЦКО по литературному чтению": "class",
    "Моро М.И.": "class",
    Перспектива: "class",
    "Петерсон Л.Г.": "class",
    "Рудницкая В.Н.": "class",
    "УМК «Гармония»": "class",
    "Школа России": "class",
    "Литературное чтение": "class",
    "Начальная математика": "class",
    "Окружающий мир": "class",
    "Подготовка к итоговому тестированию": "class",
    "Система Л.В. Занкова": "class",
    "Обучение бухгалтерскому учёту": "class",
    "Обучение 1С": "class",
    "Обучение 1С:Документообороту": "class",
    "Обучение 1С:Предприятию": "class",
    "Обучение МСФО": "class",
    "Обучение бюджетному учёту": "class",
    "Обучение документообороту": "class",
    "Обучение налогообложению": "class",
    "Обучение финансовому учёту": "class",
    "Повышение квалификации кадровиков": "class",
    "Обучение 1С:Бухгалтерии": "class",
    "Обучение 1С:ЗуП": "class",
    "Обучение 1С:Управление торговлей": "class",
    "Обучение УСН": "class",
    "Обучение делопроизводству": "class",
    "Обучение налоговому учёту": "class",
    "Обучение управленческому учёту": "class",
    "Повышение квалификации бухгалтеров": "class",
    "Повышение квалификации секретарей": "class",
    "Бухгалтерский учёт на английском языке": "class",
    "Подготовка к ДВИ творческий конкурс (архитектура)": "class",
    "Обучение архитектурному проектированию": "class",
    "Обучение графическому дизайну": "class",
    "Обучение дизайну одежды": "class",
    "Обучение инфографике": "class",
    "Обучение макетированию": "class",
    "Обучение промышленному дизайну": "class",
    "Повышение квалификации по дизайну": "class",
    "Подготовка к ДВИ творческий конкурс (дизайн)": "class",
    "Обучение архитектурному рисунку": "class",
    "Обучение дизайну интерьера": "class",
    "Обучение интерьерному скетчингу": "class",
    "Обучение ландшафтному дизайну": "class",
    "Обучение моушен-дизайну": "class",
    "Дизайн на английском языке": "class",
    "Подготовка к ДВИ творческий конкурс (журналистика)": "class",
    "Обучение блогерству": "class",
    "История журналистики": "class",
    "Обучение копирайтингу": "class",
    "Журналистика на английском языке": "class",
    "Обучение Amazon": "class",
    "Обучение MPSTATS": "class",
    "Обучение SMM": "class",
    "Обучение Авито": "class",
    "Обучение Яндекс.Директ": "class",
    "Обучение контекстной рекламе": "class",
    "Обучение продажам на маркетплейсах": "class",
    "Обучение таргетированной рекламе": "class",
    "Обучение Google AdWords": "class",
    "Обучение Ozon": "class",
    "Обучение Wildberries": "class",
    "Обучение МегаМаркет": "class",
    "Обучение интернет-маркетингу": "class",
    "Обучение поисковой оптимизации": "class",
    "Обучение связям с общественностью": "class",
    "Маркетинг на английском языке": "class",
    "Подготовка к USMLE": "class",
    "Обучение акушерству и гинекологии": "class",
    "Обучение иммунологии": "class",
    "Обучение неврологии": "class",
    "Обучение паразитологии": "class",
    "Обучение стоматологии": "class",
    "Обучение фармакологии": "class",
    "Обучение эмбриологии": "class",
    "Общественное здоровье и здравоохранение": "class",
    "Патологическая анатомия": "class",
    "Топографическая анатомия": "class",
    "Обучение ЭКГ": "class",
    "Обучение ветеринарии": "class",
    "Обучение кардиологии": "class",
    "Обучение оказанию первой помощи": "class",
    "Обучение педиатрии": "class",
    "Обучение судебной медицине": "class",
    "Обучение хирургии": "class",
    "Обучение эндокринологии": "class",
    "Патологическая физиология": "class",
    "Пропедевтика внутренних болезней": "class",
    Фармакогнозия: "class",
    "Медицина на английском языке": "class",
    "Акушерство и гинекология на английском языке": "class",
    "Иммунология на английском языке": "class",
    "Неврология на английском языке": "class",
    "Паразитология на английском языке": "class",
    "Стоматология на английском языке": "class",
    "Фармакология на английском языке": "class",
    "Эмбриология на английском языке": "class",
    "Общественное здоровье и здравоохранение на английском языке": "class",
    "Патологическая анатомия на английском языке": "class",
    "Топографическая анатомия на английском языке": "class",
    "Ветеринария на английском языке": "class",
    "Кардиология на английском языке": "class",
    "Педиатрия на английском языке": "class",
    "Хирургия на английском языке": "class",
    "Эндокринология на английском языке": "class",
    "Патологическая физиология на английском языке": "class",
    "Пропедевтика внутренних болезней на английском языке": "class",
    "Фармакогнозия на английском языке": "class",
    "Подготовка к IB Business": "class",
    "Гостиничное дело": "class",
    "Инвестиционный менеджмент": "class",
    Квалиметрия: "class",
    "Менеджмент организации": "class",
    "Обучение Jira": "class",
    "Обучение бизнес-планированию": "class",
    "Обучение управлению качеством": "class",
    "Обучение управлению проектами": "class",
    "Основы бизнеса и предпринимательства": "class",
    "Риск-менеджмент": "class",
    "Теория управления": "class",
    "Государственное и муниципальное управление": "class",
    "Инновационный менеджмент": "class",
    "Обучение Confluence": "class",
    "Обучение антикризисному управлению": "class",
    "Обучение категорийному менеджменту": "class",
    "Обучение подбору персонала": "class",
    "Обучение управлению персоналом": "class",
    "Производственный менеджмент": "class",
    "Стратегический менеджмент": "class",
    "Финансовый менеджмент": "class",
    "Менеджмент на английском языке": "class",
    "Логистика на английском языке": "class",
    "Гончарное дело": "class",
    "Обучение аппликации": "class",
    "Обучение бисероплетению": "class",
    "Обучение валянию": "class",
    "Обучение валянию шерсти": "class",
    "Обучение выжиганию": "class",
    "Обучение вышивке": "class",
    "Обучение вышивке гладью": "class",
    "Обучение вязанию": "class",
    "Обучение вязанию спицами": "class",
    "Обучение городецкой росписи": "class",
    "Обучение жостовской росписи": "class",
    "Обучение квиллингу": "class",
    "Обучение кондитерскому делу": "class",
    "Обучение кройке и шитью": "class",
    "Обучение лепке": "class",
    "Обучение лепке из пластилина": "class",
    "Обучение лепке из теста": "class",
    "Обучение машинной вышивке": "class",
    "Обучение мезенской росписи": "class",
    "Обучение мозаике": "class",
    "Обучение ниткографии": "class",
    "Обучение оформлению подарков": "class",
    "Обучение петриковской росписи": "class",
    "Обучение пластилинографии": "class",
    "Обучение плетению из лозы": "class",
    "Обучение пошиву сумок": "class",
    "Обучение резьбе по дереву": "class",
    "Обучение росписи по дереву": "class",
    "Обучение росписи по металлу": "class",
    "Обучение свечеварению": "class",
    "Обучение созданию гобелена": "class",
    "Обучение созданию мягкой игрушки": "class",
    "Обучение ткачеству": "class",
    "Обучение фриволите": "class",
    "Обучение хохломской росписи": "class",
    "Повышение квалификации поваров": "class",
    "Кулинарное искусство": "class",
    "Обучение батику": "class",
    "Обучение бумажной пластике": "class",
    "Обучение валянию войлока": "class",
    "Обучение витражной росписи": "class",
    "Обучение выпиливанию лобзиком": "class",
    "Обучение вышивке бисером": "class",
    "Обучение вышивке крестиком": "class",
    "Обучение вязанию крючком": "class",
    "Обучение гжельской росписи": "class",
    "Обучение декупажу": "class",
    "Обучение канзаши": "class",
    "Обучение керамике": "class",
    "Обучение конструированию одежды": "class",
    "Обучение кружевоплетению": "class",
    "Обучение лепке из мастики": "class",
    "Обучение лепке из полимерной глины": "class",
    "Обучение макраме": "class",
    "Обучение машинному вязанию": "class",
    "Обучение моделированию одежды": "class",
    "Обучение мыловарению": "class",
    "Обучение оригами": "class",
    "Обучение папье-маше": "class",
    "Обучение пивоварению": "class",
    "Обучение плетению из искусственного ротанга": "class",
    "Обучение плетению корзин": "class",
    "Обучение приготовлению роллов": "class",
    "Обучение росписи": "class",
    "Обучение росписи по керамике": "class",
    "Обучение росписи по стеклу": "class",
    "Обучение скрапбукингу": "class",
    "Обучение созданию кукол": "class",
    "Обучение сухому валянию": "class",
    "Обучение точечной росписи": "class",
    "Обучение хлебопечению": "class",
    "Обучение шерстяной живописи": "class",
    ОРКСЭ: "class",
    "Обществознание на английском языке": "class",
    "Граждановедение на английском языке": "class",
    "Культурология на английском языке": "class",
    "Обучение межкультурным коммуникациям": "class",
    "Народная художественная культура": "class",
    "Социология на английском языке": "class",
    "Социология управления": "class",
    "Обучение социальному проектированию": "class",
    "Теология на английском языке": "class",
    "Основы ислама": "class",
    "Обучение намазу": "class",
    "Основы буддизма": "class",
    "Основы иудаизма": "class",
    "Основы христианства": "class",
    "Основы православной культуры": "class",
    "Религиоведение на английском языке": "class",
    "Теория международных отношений на английском языке": "class",
    "Политология на английском языке": "class",
    "Демография на английском языке": "class",
    "ОБЖ на английском языке": "class",
    Андрагогика: "class",
    "Методика дистанционного преподавания": "class",
    "Образовательный консалтинг": "class",
    "Обучение репетиторству": "class",
    "Разработка методических материалов": "class",
    "Дошкольная педагогика": "class",
    "Музыкальная педагогика": "class",
    "Обучение проведению мастер-классов": "class",
    "Педагогика на английском языке": "class",
    "Подготовка к школе": "class",
    "Реджио-педагогика": "class",
    Легоконструирование: "class",
    "Методика Глена Домана": "class",
    "Обучение письму": "class",
    "Обучение счёту": "class",
    "Орф-педагогика": "class",
    "Подготовка к поступлению в 1 класс": "class",
    "Развитие логического мышления": "class",
    "Развитие младенцев": "class",
    ФЭМП: "class",
    "Логические блоки Дьенеша": "class",
    "Методика Зайцева": "class",
    "Обучение письму леворуких детей": "class",
    "Обучение чтению": "class",
    "Пальчиковая живопись": "class",
    "Развивающие методики": "class",
    "Развитие мелкой моторики": "class",
    "Система Монтессори": "class",
    "Подготовка к CELPE": "class",
    "Европейский португальский язык": "class",
    "Бразильский португальский язык": "class",
    "Разговорный португальский": "class",
    "Португальский для предпринимателей": "class",
    "Изучение португальского с нуля": "class",
    "История государства и права": "class",
    Криминалистика: "class",
    "Обучение административному праву": "class",
    "Обучение гражданскому праву": "class",
    "Обучение земельному праву": "class",
    "Обучение конституционному праву": "class",
    "Обучение медицинскому праву": "class",
    "Обучение муниципальному праву": "class",
    "Обучение наследственному праву": "class",
    "Обучение предпринимательскому праву": "class",
    "Обучение семейному праву": "class",
    "Обучение таможенному праву": "class",
    "Обучение уголовному праву": "class",
    "Обучение экологическому праву": "class",
    "Отраслевые юридические науки": "class",
    "Правоохранительные органы": "class",
    "Римское право": "class",
    "История политических и правовых учений": "class",
    Криминология: "class",
    "Обучение арбитражному процессуальному праву": "class",
    "Обучение жилищному праву": "class",
    "Обучение коммерческому праву": "class",
    "Обучение корпоративному праву": "class",
    "Обучение международному праву": "class",
    "Обучение налоговому праву": "class",
    "Обучение праву социального обеспечения": "class",
    "Обучение процессуальному праву": "class",
    "Обучение страховому праву": "class",
    "Обучение трудовому праву": "class",
    "Обучение финансовому праву": "class",
    "Подготовка к экзамену на статус адвоката": "class",
    "Прокурорский надзор": "class",
    "Теория государства и права": "class",
    "Правоведение на английском языке": "class",
    "IB Physics": "class",
    Астрофизика: "class",
    Гидродинамика: "class",
    "Квантовая механика": "class",
    "Математическая физика": "class",
    Механика: "class",
    "Общая физика": "class",
    Радиофизика: "class",
    "Теоретическая механика": "class",
    "Теория колебаний": "class",
    Тепломассообмен: "class",
    Термодинамика: "class",
    "Физика на немецком языке": "class",
    Электродинамика: "class",
    "Ядерная физика": "class",
    Астрономия: "class",
    "Атомная физика": "class",
    "Динамика полёта": "class",
    Кинематика: "class",
    "Медицинская физика": "class",
    "Молекулярная физика": "class",
    Оптика: "class",
    "Статистическая физика": "class",
    "Теоретическая физика": "class",
    "Теория поля": "class",
    Теплотехника: "class",
    "Техническая термодинамика": "class",
    "Физика на китайском": "class",
    "Физика элементарных частиц": "class",
    Электростатика: "class",
    "Физика на английском языке": "class",
    "Общая астрономия": "class",
    "Астрономия на английском языке": "class",
    "Культура речи": "class",
    "Обучение литературному редактированию": "class",
    Психолингвистика: "class",
    Этимология: "class",
    Лексикология: "class",
    "Обучение переводу": "class",
    "Олимпиады по филологии": "class",
    Социолингвистика: "class",
    Языкознание: "class",
    "Филология на английском языке": "class",
    Антропология: "class",
    Естествознание: "class",
    "История философии": "class",
    Логика: "class",
    "Социальная философия": "class",
    Феноменология: "class",
    Этика: "class",
    Гносеология: "class",
    "История и философия науки": "class",
    "Концепции современного естествознания": "class",
    "Обучение критическому мышлению": "class",
    Онтология: "class",
    "Социальная антропология": "class",
    "Теория аргументации": "class",
    Эстетика: "class",
    "Обучение мобильной фотографии": "class",
    "Фотодело на английском языке": "class",
    "Подготовка к IB Chemistry": "class",
    "Аналитическая химия": "class",
    Биохимия: "class",
    "Коллоидная химия": "class",
    "Неорганическая химия": "class",
    "Общая химия": "class",
    "Органическая химия": "class",
    "Подготовка к аспирантуре по химии": "class",
    "Проектная деятельность по химии": "class",
    "Фармацевтическая химия": "class",
    "Химическая кинетика": "class",
    "Химия на китайском": "class",
    "Экологическая химия": "class",
    Электрохимия: "class",
    Агрохимия: "class",
    "Биоорганическая химия": "class",
    "Квантовая химия": "class",
    "Медицинская химия": "class",
    "Обучение Aspen Hysys": "class",
    "Токсикологическая химия": "class",
    "Физическая химия": "class",
    "Химия на немецком языке": "class",
    "Химия на английском языке": "class",
    "Разговорный хинди": "class",
    "Хинди для предпринимателей": "class",
    "Изучение хинди с нуля": "class",
    "Инженерная графика": "class",
    "Обучение ANSYS": "class",
    "Обучение AutoCAD": "class",
    "Обучение Autodesk Fusion 360": "class",
    "Обучение Autodesk Navisworks": "class",
    "Обучение CATIA": "class",
    "Обучение IDEF": "class",
    "Обучение PRO100": "class",
    "Обучение Revit MEP": "class",
    "Обучение SCAD": "class",
    "Обучение Solidworks": "class",
    "Обучение Tinkercad": "class",
    "Обучение bCAD": "class",
    "Обучение ЛИРА-САПР": "class",
    "Обучение программе БАЗИС-Мебельщик": "class",
    "Обучение чтению чертежей": "class",
    "Начертательная геометрия": "class",
    "Обучение Archicad": "class",
    "Обучение Autodesk AutoCAD Civil 3D": "class",
    "Обучение Autodesk Inventor": "class",
    "Обучение Autodesk Revit": "class",
    "Обучение COMSOL Multiphysics": "class",
    "Обучение NX": "class",
    "Обучение Revit Architecture": "class",
    "Обучение Revit Structure": "class",
    "Обучение Solid Edge": "class",
    "Обучение T-FLEX CAD": "class",
    "Обучение UML": "class",
    "Обучение nanoCAD": "class",
    "Обучение САПР": "class",
    "Обучение программе Компас": "class",
    "Черчение на английском языке": "class",
    "Подготовка к ACCA": "class",
    "Подготовка к AP Microeconomics": "class",
    "Подготовка к CFA": "class",
    "Подготовка к CIPA": "class",
    "Банковское дело": "class",
    "Институциональная экономика": "class",
    "Корпоративные финансы": "class",
    "Математические методы в экономике": "class",
    Микроэкономика: "class",
    "Обучение анализу финансово-хозяйственной деятельности": "class",
    "Обучение брокеров": "class",
    "Обучение имитационному моделированию": "class",
    "Обучение ипотечных брокеров": "class",
    "Обучение криптотрейдингу": "class",
    "Обучение таможенных брокеров": "class",
    "Обучение трейдингу": "class",
    "Обучение финансовому моделированию": "class",
    "Обучение экономическому анализу": "class",
    "Рынок ценных бумаг": "class",
    "Теория игр": "class",
    "Экономика природопользования": "class",
    "Экономическая безопасность": "class",
    "Подготовка к AP Macroeconomics": "class",
    "Подготовка к CIMA": "class",
    "Подготовка к IB Economics": "class",
    "Биржевое дело": "class",
    "История экономики": "class",
    Макроэкономика: "class",
    "Международная экономика": "class",
    "Обучение Gretl": "class",
    "Обучение аудиту": "class",
    "Обучение бюджетированию": "class",
    "Обучение инвестиционному анализу": "class",
    "Обучение кредитных брокеров": "class",
    "Обучение нормированию труда": "class",
    "Обучение товароведению": "class",
    "Обучение финансовому анализу": "class",
    "Обучение фундаментальному анализу": "class",
    "Олимпиады по финансовой грамотности": "class",
    "Проектная деятельность по экономике": "class",
    "Страховое дело": "class",
    "Финансовая грамотность": "class",
    Эконометрика: "class",
    "Экономика предприятия": "class",
    "Экономика труда": "class",
    "Микроэкономика на английском языке": "class",
    "Эконометрика на английском языке": "class",
    "Актёрское мастерство": "class",
    "Подготовка кДВИ творческий конкурс (актёрское мастерство)": "class",
    Драматургия: "class",
    "Обучение режиссуре": "class",
    "Подготовка в театральные училища": "class",
    Риторика: "class",
    "Сценическое движение": "class",
    "Техника речи": "class",
    "Актёрское мастерство на английском языке": "class",
    "Логика речи": "class",
    "Подготовка в театральные вузы": "class",
    Продюсирование: "class",
    "Сценическая речь": "class",
    "Театральные экзамены": "class",
    "Художественное чтение": "class",
    Газодинамика: "class",
    Материаловедение: "class",
    "Нефтегазовое дело": "class",
    "Обучение Multisim": "class",
    "Обучение ГРАНД-Смете": "class",
    "Обучение гидравлике": "class",
    "Обучение микропроцессорной технике": "class",
    "Обучение робототехнике": "class",
    "Обучение стандартизации": "class",
    "Обучение схемотехнике": "class",
    "Обучение электронике": "class",
    "Олимпиады по робототехнике": "class",
    "Прикладная механика": "class",
    Радиолокация: "class",
    "Строительная механика": "class",
    "Теория связи": "class",
    Теплоэнергетика: "class",
    "Технология машиностроения": "class",
    Электроэнергетика: "class",
    ТРИЗ: "class",
    "Детали машин и основы конструирования": "class",
    "Механика грунтов": "class",
    "Обучение FPGA": "class",
    "Обучение VHDL": "class",
    "Обучение авиамоделированию": "class",
    "Обучение метрологии": "class",
    "Обучение радиотехнике": "class",
    "Обучение сметному делу": "class",
    "Обучение строительным конструкциям": "class",
    "Обучение цифровой обработке сигналов": "class",
    "Обучение электротехнике": "class",
    "Повышение квалификации по сметному делу": "class",
    "Процессы и аппараты химической технологии": "class",
    "Сопротивление материалов": "class",
    "Теория механизмов и машин": "class",
    "Теория электрических цепей": "class",
    "Техническая механика": "class",
    "Химическая технология": "class",
    Кибернетика: "class",
    "Мобильная грамотность": "class",
    "Системы математического моделирования": "class",
    Алгоритмизация: "class",
    "Информационные технологии": "class",
    "Компьютерная грамотность": "class",
    "Обучение 3D-анимации": "class",
    "Обучение СУБД": "class",
    "Обучение векторной графике": "class",
    "Обучение информационной безопасности": "class",
    "Обучение компьютерной графике": "class",
    "Обучение операционным системам": "class",
    "Обучение сводным таблицам": "class",
    "Обучение системному анализу": "class",
    "Обучение цифровой живописи": "class",
    "Экономическая информатика": "class",
    "Информатика на английском языке": "class",
    "Основы работы в программе": "class",
    "Подготовка к собеседованиям": "class",
    "Создание и анимация текста": "class",
    "Работа с масками и ротоскопированием": "class",
    "Работа с 3D-объектами и камерами": "class",
    "Создание адаптивных веб-страниц (Responsive Design)": "class",
    "Интеграция с базами данных (для динамических сайтов)": "class",
    "Тестирование и отладка веб-страниц в разных браузерах": "class",
    "Работа с FTP для загрузки и управления файлами на сервере": "class",
    "Создание логотипов и графических элементов": "class",
    "Работа с текстом и типографикой": "class",
    "Слои и маски для сложных композиций": "class",
    "Пресеты и корректирующие инструменты": "class",
    "Тональные кривые и HSL-таблица": "class",
    "Устранение дефектов: шум, резкость и векторизация": "class",
    "Ретушь и улучшение фотографий": "class",
    "Работа с масками и альфа-каналами": "class",
    "Работа с текстовыми эффектами": "class",
    "Работа с материалами и шейдерами": "class",
    "Создание и использование масок и слоев": "class",
    "Редактирование и аннотирование PDF-файлов": "class",
    "Создание и защита форм PDF": "class",
    "Работа с безопасностью и шифрованием PDF-документов": "class",
    "Создание и микширование многодорожечных проектов": "class",
    "Применение эффектов и фильтров к аудиотрекам": "class",
    "Работа с мастерингом и улучшением качества звука": "class",
    "Создание 2D-анимированных персонажей и объектов": "class",
    "Разработка анимаций для веба и мобильных устройств": "class",
    "Работа с временной шкалой и ключевыми кадрами": "class",
    "Интеграция интерактивных элементов и взаимодействие с пользователем": "class",
    "Создание макетов для печатной продукции (журналы, буклеты, газеты)": "class",
    "Создание макетов для печатных изданий (журналы, брошюры, газеты)": "class",
    "Работа с текстом, изображениями и графическими элементами": "class",
    "Работа с эффектами, переходами и цветокоррекцией": "class",
    "Синхронизация аудио и видео": "class",
    "Создание прототипов для мобильных и веб-приложений": "class",
    "Разработка интерактивных элементов и анимаций": "class",
    "Работа с макетами и дизайном пользовательских интерфейсов (UI)": "class",
    "Создание 3D-моделей для архитектуры": "class",
    "Работа с текстурами и материалами для фотореалистичной визуализации": "class",
    "Анимация объектов и создание движений": "class",
    "Рендеринг сцен и подготовка изображений для презентаций и портфолио": "class",
    "Создание и редактирование 3D-анимированных персонажей": "class",
    "Работа с захватом движения (motion capture) и его обработка": "class",
    "Анимация движений": "class",
    "Интеграция с другими 3D-пакетами (например, 3ds Max, Maya)": "class",
    "Работа с файлами и директориями через команды": "class",
    "Циклы, условные операторы и функции в скриптах": "class",
    "Подготовка к LFCS": "class",
    "Подготовка к CompTIA Linux+": "class",
    "Подготовка к RHCSA": "class",
    "Работа с текстурами, материалами и освещением для фотореалистичной визуализации": "class",
    "Анимация объектов и персонажей": "class",
    "Рендеринг сцен": "class",
    "Анимация объектов и создание сложных движений": "class",
    "Настройка фотореалистичных материалов и текстур": "class",
    "Работа с освещением": "class",
    "Оптимизация рендеринга": "class",
    "Подготовка изображений для архитектурной визуализации": "class",
    "Работа с цветокоррекцией и градацией цвета для улучшения качества видео": "class",
    "Применение эффектов, переходов и титров": "class",
    "Пакеты и установка программ с помощью APT": "class",
    "Настройка сетевых подключений и безопасности системы": "class",
    "Администрирование системы": "class",
    "Работа с MIDI, сэмплами и аудиофайлами": "class",
    "Аранжировка треков": "class",
    "Применение эффектов и обработка звука (эквалайзеры, реверберации, компрессоры)": "class",
    "Микширование и мастеринг": "class",
    "Цветокоррекция и градация цвета": "class",
    "Эффекты и анимация": "class",
    "Моделирование 3D объектов и сборок": "class",
    "Разработка чертежей и технической документации": "class",
    "Параметрическое моделирование": "class",
    "Сведение и микширование треков": "class",
    "Использование эффектов и обработки звука": "class",
    "Формулы и функции для автоматизации расчетов": "class",
    "Google Scripts для автоматизации таблиц": "class",
    "Подготовка к Google workspace Certification": "class",
    "Подготовка к Google Sheets Advanced Certification": "class",
    "Подготовка к сертфикациям": "class",
    "Работа с текстом и шрифтами": "class",
    "Математические формулы и символы": "class",
    "Подготовка к LPIC-1": "class",
    "Обучение Microsoft Office Excel": "class",
    "Обучение Microsoft Office PowerPoint": "class",
    "Обучение Microsoft Office Word": "class",
    "Обучение Microsoft Office Access": "class",
    "Обучение Microsoft Office Visio": "class",
    "Обучение Microsoft Office Project": "class",
    "Обучение Microsoft Office SharePoint": "class",
    "Обучение встроенным формулам": "class",
    "Обучение VBA": "class",
    "Построение графиков и визуализация данных": "class",
    "Пользовательские функции": "class",
    "Визуализация данных и построение графиков": "class",
    "Администрирование баз данных": "class",
    "Написание сложных SQL-запросов": "class",
    "Проектирование и нормализация баз данных": "class",
    "Резервное копирование, восстановление и безопасность данных": "class",
    "Подготовка к Microsoft Certified: Windows Server Hybrid Administrator Associate": "class",
    "Создание и редактирование текста и титров": "class",
    "Работа с шейдерами (Vertex и Fragment Shaders)": "class",
    "Оптимизация рендеринга и работа с текстурами": "class",
    "Написание расширений": "class",
    "Редактор загрузки скриптов": "class",
    "Set analysis": "class",
    "Цветокоррекция и работа с визуальными эффектами": "class",
    "Импорт, очистка и преобразование данных": "class",
    "Соединения и объединение данных": "class",
    "Работа с анимацией": "class",
    "Моделирование данных и загрузка данных": "class",
    "Создание и настройка визуализаций": "class",
    "Оптимизация производительности": "class",
    "MIDI и виртуальные инструменты": "class",
    "Сведение и обработка аудио": "class",
    "Создание и использование пользовательских блоков": "class",
    "Интеграция с реальными системами и кодогенерация": "class",
    "Текстуры и материалы": "class",
    "Моделирование 3D-объектов": "class",
    "Проектирование интерьеров и экстерьеров": "class",
    "Эффекты и фильтры": "class",
    "Диаграммы Ганта и календари": "class",
    "Управление ресурсами и бюджетом": "class",
    "Анализ и обработка данных": "class",
    "Разработка алгоритмов и программирование": "class",
    "Создание фотореалистичных визуализаций": "class",
    "Улучшение качества рендеринга проектов": "class",
    "Создание анимации и спецэффектов": "class",
    "Разработка текстур и материалов": "class",
    "Освоение инструментов риггинга": "class",
    "Создание цифровых иллюстраций": "class",
    "Освоение техник скетчинга": "class",
    "Разработка концепт-артов": "class",
    "Улучшение навыков цифровой живописи": "class",
    "Освоение инструментов и технологий Big Data": "class",
    "Подготовка к (CCA) Data Analyst": "class",
    "Подготовка к (CCP) Data Engineer": "class",
    "Подготовка к IBM Data Science Professional Certificate": "class",
    "Подготовка к Microsoft Certified: Azure Data Engineer Associate": "class",
    "Подготовка к Google Professional Data Engineer": "class",
    "Подготовка к AWS Certified Big Data - Specialty": "class",
    "Подготовка к Hortonworks Certified Associate (HCA)": "class",
    "Подготовка к Databricks Certified Data Engineer Associate": "class",
    "Освоение базового интерфейса и инструментов": "class",
    "Подготовка фотографий для печати или публикации": "class",
    "Оптимизация обработки RAW-файлов": "class",
    "Создание векторной графики": "class",
    "Создание иллюстраций и инфографики": "class",
    "Освоение инструментов для работы с текстом и дизайном": "class",
    "Работа с виртуальными инструментами и MIDI": "class",
    "Освоение функций аудиообработки": "class",
    "Освоение Python или R для Data Science": "class",
    "Разработка моделей машинного обучения": "class",
    "Анализ реальных данных и создание отчетов": "class",
    "Изучение основ анализа данных": "class",
    "Microsoft Certified: Azure Data Scientist Associate": "class",
    "IBM Data Science Professional Certificate": "class",
    "Google Professional Machine Learning Engineer": "class",
    "DataCamp Data Scientist Certification": "class",
    "SAS Certified Data Scientist": "class",
    "AWS Certified Machine Learning - Specialty": "class",
    "Cloudera Data Platform Generalist Certification": "class",
    "TensorFlow Developer Certificate": "class",
    "Освоение работы с Docker Compose и Docker Swarm": "class",
    "Оптимизация развертывания приложений": "class",
    "Настройка CI/CD с использованием Docker": "class",
    "Подготовка к Docker Certified Associate (DCA)": "class",
    "Подготовка к Kubernetes and Docker Essentials by IBM": "class",
    "Подготовка к AWS Certified DevOps Engineer - Professional": "class",
    "Подготовка к Certified Kubernetes Administrator (CKA)": "class",
    "Подготовка к HashiCorp Certified: Terraform Associate": "class",
    "Разработка дизайнов для веб и мобильных приложений": "class",
    Прототипирование: "class",
    "Auto Layout": "class",
    "Оптимизация запросов и производительности": "class",
    "Администрирование и настройка серверов Firebird": "class",
    "Разработка приложений с использованием Firebird": "class",
    "Миграция данных между системами": "class",
    "Ретушь и восстановление изображений": "class",
    "Разработка веб-графики и иконок": "class",
    "Освоение работы со слоями и масками": "class",
    "Освоение работы с Portage и ebuild": "class",
    "Повышение производительности и безопасности системы": "class",
    "Создание процедурной 3D-графики": "class",
    "Разработка визуальных эффектов (VFX)": "class",
    "Анимация и рендеринг проектов": "class",
    "Разработка концепт-арта и персонажей": "class",
    "Создание анимации в Krita": "class",
    "Создание таблиц и анализ данных (Calc)": "class",
    "Автоматизация задач с помощью макросов": "class",
    "Разработка презентаций (Impress)": "class",
    "Освоение работы с текстовыми документами (Writer)": "class",
    "Создание архитектурных визуализаций для проектов": "class",
    "Разработка анимаций и видеопрезентаций": "class",
    "Автоматизация расчетных процессов и создание отчетов": "class",
    "Создание инженерных расчетов и моделей": "class",
    "Визуализация данных и графиков": "class",
    "Подготовка к сертификациям": "class",
    "Разработка и оптимизация запросов": "class",
    "Проектирование и настройка баз данных": "class",
    "Управление репликацией и шардированием": "class",
    "Разработка и оптимизация SQL-запросов": "class",
    "Проектирование структуры базы данных": "class",
    "Настройка резервного копирования и восстановления данных": "class",
    "Администрирование и настройка Oracle Database": "class",
    "Управление пользователями и правами доступа": "class",
    "Проектирование и настройка структуры базы данных": "class",
    "Настройка репликации и резервного копирования": "class",
    "Работа с виртуальными инструментами и плагинами": "class",
    "Подготовка проектов для финального рендеринга или выпуска": "class",
    "Работа с графикой и изображениями в макетах": "class",
    "Создание сложных 3D-форм и поверхностей": "class",
    "Разработка прототипов и моделей для производства": "class",
    "Подготовка моделей для 3D-печати": "class",
    "Визуализация и рендеринг 3D-проектов": "class",
    "Создание UI/UX-дизайнов для веб и мобильных приложений": "class",
    "Разработка интерактивных прототипов": "class",
    "Создание и управление дизайн-системами": "class",
    "Работа с эффектами и фильтрами": "class",
    "Создание анимаций и переходов": "class",
    "Создание научных и технических документов": "class",
    "Форматирование математических формул и уравнений": "class",
    "Разработка библиографий и ссылок": "class",
    "Подготовка документов для печати и публикации": "class",
    "Создание фотореалистичных рендеров": "class",
    "Разработка материалов и текстур для 3D-моделей": "class",
    "Подготовка анимаций и визуализаций для проектов": "class",
    "Создание детализированных 3D-моделей и скульптур": "class",
    "Разработка текстур и материалов для моделей": "class",
    "Подготовка моделей для 3D-печати или анимации": "class",
    "Улучшение навыков цифровой скульптуры и рендеринга": "class",
    "Основы Python": "class",
    "Подготовка к сертификации": "class",
    "Подготовка к олимпиаде": "class",
    "Веб-разработка": "class",
    "Анализ данных и Data Science": "class",
    "Машинное обучение": "class",
    "Написание скриптов, парсинг веб-страниц, обработка данных": "class",
    "Разработка игр (библиотеки Pygame и пр.)": "class",
    "Разработка чат-ботов и работы с API": "class",
    "Введение в DevOps (развертывание,CI/CD, контейнеры)": "class",
    "Работа с базами данных (PostgreSQL, MySQL и пр.)": "class",
    "Разработка приложений и GUI (PyQt, Tkinter, Kivy и пр.)": "class",
    "Основы CCIE": "class",
    "Понимание сетевых технологий (маршрутизация, коммутация, QoS, VPN, MPLS.)": "class",
    "Экзамен CCIE Lab": "class",
    "Экзамен CCIE Written": "class",
    "Cisco IOS и NX-OS": "class",
    "Проектирование и архитектура сетей": "class",
    "Отработка навыков устранения неполадок": "class",
    "Подготовка к использованию автоматизации сетей": "class",
    "Изучение технологий SD-WAN и SDN": "class",
    "Работа с безопасностью сетей (Network Security)": "class",
    "Глубокое понимание протоколов маршрутизации": "class",
    "Оптимизация производительности сетей": "class",
    "Подготовка к DevNet и интеграции с CCIE": "class",
    "Управление мультидоменной средой": "class",
    "Виртуализация сетей и сервисов NFV (Network Function Virtualization), Cisco VIRL": "class",
    "Технологии Cisco": "class",
    "Основы CCNP": "class",
    "Подготовка к экзаменам CCNP Core и Concentration": "class",
    "Углубленное изучение протоколов маршрутизации (OSPF, EIGRP, BGP, RIP и их настройкой в реальных сценариях)": "class",
    "Изучение коммутации и VLAN ( STP, RSTP, MSTP, EtherChannel, VTP и других технологий уровня 2)": "class",
    "Работа с технологиями MPLS и VPN (L3VPN, GRE, DMVPN, FlexVPN и пр.)": "class",
    "Подготовка к задачам по устранению неполадок": "class",
    "Оптимизация производительности сети (QoS, управление трафиком и балансировка нагрузки)": "class",
    "Виртуализация сетей и Cisco DNA": "class",
    "Автоматизация сетей и DevNet": "class",
    "Безопасность сетей (Access Lists, NAT, Cisco Firepower, IPSec VPN для защиты инфраструктуры)": "class",
    "Изучение технологий SD-WAN (Cisco Viptela, Meraki SD-WAN и пр.)": "class",
    "Интеграция с облачными решениями (AWS, Azure, Google Cloud и пр.))": "class",
    "Понимание архитектуры сетей": "class",
    "Работа с инструментами сетевого мониторинга (NetFlow, SNMP, Cisco Prime и пр.)": "class",
    "Тренировка работы в лабораториях (Cisco Packet Tracer, GNS3,Cisco VIRL и пр.)": "class",
    "Подготовка к реальным проектам и задачам (Настройка корпоративных сетей и поддержка систем в продакшене)": "class",
    "Основы ООП": "class",
    "Изучение базовых концепций ООП": "class",
    "Написание структурированного и масштабируемого кода": "class",
    "Понимание классов и объектов": "class",
    "Работа с наследованием и иерархиями": "class",
    "Изучение интерфейсов и протоколов": "class",
    "Изучение работы со сложными структурами данных": "class",
    "Работа с паттернами проектирования": "class",
    "Применение ООП в разработке игр": "class",
    "Изучение ООП в популярных языках программирования (Python, Java, C++, C#, JavaScript и пр.)": "class",
    "Понимание композиции и агрегации": "class",
    "Модульное тестирование ООП-кода": "class",
    "Работа с исключениями и их обработкой": "class",
    "Применение принципов SOLID и DRY": "class",
    "Создание сложных проектов с использованием ООП": "class",
    "Изучение основ машинного обучения": "class",
    "Работа с библиотеками (Scikit-learn, TensorFlow, PyTorch, Keras, XGBoost и пр.) и инструментами ML": "class",
    "Анализ данных и предобработка (Pandas, NumPy, обработка пропущенных данных, масштабирование, категоризация, очистка данных)": "class",
    "Решение задач классификации и регрессии": "class",
    "Понимание алгоритмов ML": "class",
    "Обучение и оценка моделей": "class",
    "Работа с нейронными сетями": "class",
    "Глубокое обучение (Deep Learning)": "class",
    "Обработка естественного языка (NLP)": "class",
    "Компьютерное зрение": "class",
    "Инженерия признаков": "class",
    "Автоматизация обучения моделей (AutoML)": "class",
    "Реализация ML-проектов": "class",
    "Интеграция моделей в продакшен (MLflow, Docker, API, создание и развертывание моделей)": "class",
    "Освоение основ 1С:Предприятие": "class",
    "Создание отчетов и обработок": "class",
    "Разработка конфигураций для 1С": "class",
    "Изучение языка программирования 1С (1С:Предприятие)": "class",
    "Настройка и поддержка типовых конфигураций 1С": "class",
    "Работа с внешними отчетами": "class",
    "Интеграция 1С с другими системами": "class",
    "Разработка бизнес-логики и алгоритмов": "class",
    "Проектирование и настройка базы данных 1С": "class",
    "Работа с задачами по бухгалтерии и налогам": "class",
    "Разработка пользовательских интерфейсов в 1С": "class",
    "Тестирование и отладка программ на 1С": "class",
    "Обновление и миграция конфигураций 1С": "class",
    "Оптимизация производительности 1С": "class",
    "Подготовка к сертификационным экзаменам 1С": "class",
    "Изучение основ AJAX": "class",
    "Создание асинхронных запросов с использованием XMLHttpRequest": "class",
    "Использование Fetch API для асинхронных запросов": "class",
    "Обработка и парсинг данных в формате JSON": "class",
    "Интеграция AJAX с серверными технологиями (PHP, Node.js)": "class",
    "Оптимизация работы с сервером с помощью AJAX": "class",
    "Реализация динамических интерфейсов без перезагрузки страницы": "class",
    "Работа с формами и отправка данных с использованием AJAX": "class",
    "Обработка ошибок и исключений в AJAX-запросах": "class",
    "Использование jQuery для упрощения AJAX-запросов": "class",
    "Настройка и использование кук и сессий в AJAX": "class",
    "Разработка динамических пользовательских интерфейсов": "class",
    "Реализация динамической загрузки данных в таблицы и списки": "class",
    "Понимание и работа с CORS (Cross-Origin Resource Sharing)": "class",
    "Тестирование и отладка AJAX-запросов в браузерах": "class",
    "Освоение основ ActionScript": "class",
    "Изучение взаимодействия с объектами и переменными в ActionScript": "class",
    "Создание и обработка событий в ActionScript": "class",
    "Разработка анимаций с использованием ActionScript": "class",
    "Работа с графикой и визуальными эффектами в ActionScript": "class",
    "Создание динамических интерфейсов с использованием ActionScript": "class",
    "Взаимодействие с пользовательскими формами и обработка ввода данных": "class",
    "Использование библиотеки и компонентов для создания приложений": "class",
    "Реализация мультимедийных элементов (видео, аудио) с помощью ActionScript": "class",
    "Работа с асинхронными запросами": "class",
    "Создание интерактивных игр с использованием ActionScript": "class",
    "Интеграция ActionScript с Flash и AIR для создания кроссплатформенных приложений": "class",
    "Оптимизация производительности приложений на ActionScript": "class",
    "Обработка ошибок и исключений в ActionScript": "class",
    "Подготовка к разработке приложений для мобильных устройств с использованием AIR": "class",
    "Освоение основ Angular.js и его архитектуры": "class",
    "Создание и настройка Angular.js проектов с использованием Angular CLI": "class",
    "Работа с директивами в Angular.js": "class",
    "Создание и использование компонентов в Angular.js": "class",
    "Изучение привязки данных (two-way data binding) в Angular.js": "class",
    "Реализация маршрутизации в Angular.js": "class",
    "Работа с сервисами и зависимостями (dependency injection) в Angular.js": "class",
    "Обработка событий и создание пользовательских форм в Angular.js": "class",
    "Использование фильтров для обработки данных в Angular.js": "class",
    "Реализация HTTP-запросов и взаимодействие с API": "class",
    "Работа с состоянием приложения с использованием ngRx или других инструментов управления состоянием": "class",
    "Оптимизация производительности приложений на Angular.js": "class",
    "Тестирование приложений Angular.js с использованием Jasmine и Karma": "class",
    "Изучение реактивного программирования с использованием RxJS в Angular.js": "class",
    "Подготовка к развертыванию и деплою приложений на Angular.js в продакшн": "class",
    "Освоение основ синтаксиса и структуры Assembler": "class",
    "Понимание архитектуры процессоров и работы с регистрами": "class",
    "Разработка простых программ на Assembler для вычислений": "class",
    "Работа с арифметическими операциями в Assembler": "class",
    "Использование условных операторов и циклов в Assembler": "class",
    "Создание процедур и функций в Assembler": "class",
    "Понимание работы со стеком и операциями с памятью": "class",
    "Работа с системными вызовами и интерфейсами ОС через Assembler": "class",
    "Оптимизация кода и использование инструкций для улучшения производительности": "class",
    "Изучение работы с массивами и строками в Assembler": "class",
    "Реализация взаимодействия с внешними устройствами через порты ввода-вывода": "class",
    "Программирование на Assembler для микроконтроллеров": "class",
    "Понимание работы с флагами процессора и их использование в логических операциях": "class",
    "Создание интерфейсов взаимодействия с другими языками программирования": "class",
    "Отладка и тестирование программ на Assembler": "class",
    "Освоение основ C# и структуры": "class",
    "Изучение ООП (объектно-ориентированного программирования) в C#": "class",
    "Работа с коллекциями и структурами данных в C#": "class",
    "Разработка пользовательских интерфейсов с использованием Windows Forms или WPF": "class",
    "Изучение работы с асинхронностью и многозадачностью (async/await, Task)": "class",
    "Создание и использование методов, делегатов и событий в C#": "class",
    "Работа с LINQ для обработки данных": "class",
    "Обработка исключений и работа с ошибками в C#": "class",
    "Разработка консольных приложений и утилит на C#": "class",
    "Изучение работы с базами данных с использованием Entity Framework": "class",
    "Работа с файлами и потоками в C#": "class",
    "Разработка веб-приложений с использованием ASP.NET и ASP.NET Core": "class",
    "Работа с зависимостями и внедрением зависимостей (Dependency Injection) в C#": "class",
    "Оптимизация кода и улучшение производительности программ на C#": "class",
    "Подготовка к сертификационным экзаменам по C# и .NET": "class",
    "Освоение основ и структуры Clojure": "class",
    "Понимание философии функционального программирования в Clojure": "class",
    "Изучение работы с неизменяемыми структурами данных в Clojure": "class",
    "Разработка и использование функций высшего порядка": "class",
    "Работа с макросами в Clojure": "class",
    "Изучение системы типов в Clojure и использование типа nil": "class",
    "Работа с коллекциями: списки, векторы, множества, карты": "class",
    "Применение принципов чистых функций и композиции функций": "class",
    "Реализация многозадачности и асинхронного программирования с помощью core.async": "class",
    "Интеграция Clojure с Java и использование Java-библиотек": "class",
    "Создание и использование атомарных операций и многозадачности с помощью refs, atoms, agents": "class",
    "Изучение работы с ленивая оценка и потоками данных (lazy sequences)": "class",
    "Работа с тестированием в Clojure (например, использование библиотеки clojure.test)": "class",
    "Разработка веб-приложений с использованием Clojure (например, с помощью Compojure или Luminus)": "class",
    "Оптимизация кода и улучшение производительности Clojure-программ": "class",
    "Освоение основ Delphi": "class",
    "Разработка графического пользовательского интерфейса с использованием VCL и FMX": "class",
    "Изучение объектно-ориентированного программирования (ООП) в Delphi": "class",
    "Создание и работа с компонентами Delphi": "class",
    "Работа с базами данных с использованием FireDAC, dbExpress или других компонентов": "class",
    "Разработка консольных приложений на Delphi": "class",
    "Работа с памятью и динамическими массивами в Delphi": "class",
    "Создание многозадачных приложений с использованием многопоточности в Delphi": "class",
    "Изучение обработки ошибок и исключений в Delphi": "class",
    "Работа с файлами, потоками ввода-вывода и сериализацией данных": "class",
    "Интеграция с веб-сервисами и создание RESTful приложений с Delphi": "class",
    "Разработка мобильных приложений с использованием Delphi и FireMonkey": "class",
    "Оптимизация производительности и повышение скорости работы приложений на Delphi": "class",
    "Создание и настройка установочных пакетов для Delphi-программ": "class",
    "Подготовка к сертификационным экзаменам и трудоустройству с Delphi": "class",
    "Освоение основ установки и настройки Drupal": "class",
    "Освоение основ Drupal": "class",
    "Изучение структуры и архитектуры сайта на Drupal": "class",
    "Работа с контентом в Drupal: создание типов контента, таксономии, блоков": "class",
    "Разработка и настройка тем оформления для сайта на Drupal": "class",
    "Использование и настройка модулей в Drupal": "class",
    "Разработка пользовательских модулей и расширений в Drupal": "class",
    "Работа с пользователями и правами доступа в Drupal": "class",
    "Настройка многоязычных сайтов в Drupal": "class",
    "Разработка кастомных форм с использованием Drupal Form API": "class",
    "Интеграция с внешними API и системами через Drupal": "class",
    "Оптимизация производительности сайта на Drupal": "class",
    "Настройка и управление SEO в Drupal": "class",
    "Безопасность сайта на Drupal: защита от уязвимостей, обновления и патчи": "class",
    "Разработка и внедрение кастомных тем и шаблонов для Drupal": "class",
    "Подготовка сайта на Drupal к запуску: тестирование, деплой, перенос на продакшн": "class",
    "Освоение основ FastAPI и его архитектуры": "class",
    "Создание простых RESTful API с использованием FastAPI": "class",
    "Изучение аннотаций типов и автоматической валидации данных": "class",
    "Реализация аутентификации и авторизации с использованием OAuth2 и JWT": "class",
    "Работа с базами данных через SQLAlchemy и Tortoise ORM": "class",
    "Реализация обработки и валидации данных с использованием Pydantic моделей": "class",
    "Настройка и использование CORS для работы с фронтендом": "class",
    "Оптимизация производительности API с использованием асинхронных функций и async/await": "class",
    "Разработка и использование зависимостей (Dependency Injection) в FastAPI": "class",
    "Обработка ошибок и исключений в FastAPI": "class",
    "Интеграция с внешними сервисами и API": "class",
    "Разработка документации API с использованием встроенных возможностей FastAPI (Swagger, ReDoc)": "class",
    "Тестирование API с использованием pytest и встроенных тестовых клиентов FastAPI": "class",
    "Реализация пагинации и фильтрации в API": "class",
    "Развертывание FastAPI-приложений с использованием Docker, Nginx и других инструментов": "class",
    "Освоение основ Fortran": "class",
    "Изучение работы с переменными, типами данных и операторами в Fortran": "class",
    "Работа с массивами и многомерными структурами данных в Fortran": "class",
    "Понимание принципов управления потоком исполнения: циклы, условия и переходы": "class",
    "Изучение работы с процедурами и функциями в Fortran": "class",
    "Создание и использование модулей и интерфейсов для организации кода": "class",
    "Оптимизация вычислений и использование параллельных вычислений (OpenMP, MPI)": "class",
    "Разработка научных и инженерных вычислений с использованием Fortran": "class",
    "Работа с файлами и потоками ввода-вывода в Fortran": "class",
    "Использование и работа с библиотеками и внешними модулями в Fortran": "class",
    "Работа с динамической памятью и указателями в Fortran": "class",
    "Отладка и тестирование программ на Fortran": "class",
    "Интеграция Fortran с другими языками программирования (например, C или Python)": "class",
    "Разработка высокопроизводительных приложений и численных моделей": "class",
    "Подготовка к работе с суперкомпьютерами и облачными вычислениями на Fortran": "class",
    "Изучение основ программирования": "class",
    "Понимание принципов функционального программирования в Haskell": "class",
    "Работа с неизменяемыми данными и списками в Haskell": "class",
    "Изучение функций высшего порядка и лямбда-выражений в Haskell": "class",
    "Разработка и использование рекурсии в Haskell": "class",
    "Изучение монад в Haskell для обработки побочных эффектов": "class",
    "Работа с типами данных, включая типы данных и их обобщения": "class",
    "Использование Haskell для создания чистых и высокоуровневых алгоритмов": "class",
    "Понимание ленивых вычислений и работы с ленивыми списками в Haskell": "class",
    "Изучение и использование классов типов и полиморфизма в Haskell": "class",
    "Работа с IO в Haskell и взаимодействие с миром": "class",
    "Разработка и использование библиотек и пакетов в Haskell": "class",
    "Создание и тестирование программ с использованием Hspec или QuickCheck": "class",
    "Реализация и использование алгебраических типов данных (ADTs) в Haskell": "class",
    "Оптимизация и повышение производительности программ на Haskell": "class",
    "Освоение основ JavaScript": "class",
    "Изучение типов данных, переменных и операторов в JavaScript": "class",
    "Понимание основ объектно-ориентированного программирования (ООП) в JavaScript": "class",
    "Работа с функциями, замыканиями и функциями высшего порядка": "class",
    "Освоение работы с массивами и объектами в JavaScript": "class",
    "Изучение асинхронного программирования с использованием callback, Promises и async/await": "class",
    "Изучение DOM (Document Object Model) и взаимодействие с веб-страницей": "class",
    "Работа с событиями и обработчиками событий в JavaScript": "class",
    "Реализация и управление состоянием в приложениях с использованием JavaScript": "class",
    "Использование ES6+ функциональности: стрелочные функции, классы, модули, деструктуризация": "class",
    "Работа с API: отправка запросов с использованием fetch или axios": "class",
    "Изучение работы с библиотеками и фреймворками (например, React, Vue, Angular)": "class",
    "Реализация локального хранения данных в браузере (localStorage, sessionStorage, cookies)": "class",
    "Оптимизация производительности и отладка JavaScript-программ": "class",
    "Тестирование JavaScript-программ с использованием инструментов, таких как Jest или Mocha": "class",
    "Освоение основ Kotlin": "class",
    "Понимание объектно-ориентированного программирования (ООП) в Kotlin": "class",
    "Изучение работы с типами данных, переменными и операторами в Kotlin": "class",
    "Использование функций высшего порядка и лямбда-выражений в Kotlin": "class",
    "Работа с коллекциями и стандартными библиотеками Kotlin": "class",
    "Изучение принципов работы с неизменяемыми и изменяемыми данными в Kotlin": "class",
    "Понимание работы с null-значениями и безопасной работы с null (Null Safety)": "class",
    "Изучение классов, наследования и интерфейсов в Kotlin": "class",
    "Работа с расширениями (extension functions) в Kotlin": "class",
    "Использование корутин для асинхронного программирования в Kotlin": "class",
    "Разработка мобильных приложений для Android с использованием Kotlin": "class",
    "Работа с базами данных через Room и SQLite в Kotlin": "class",
    "Интеграция Kotlin с Java и использование Java-библиотек в Kotlin": "class",
    "Тестирование Kotlin-программ с использованием JUnit и других библиотек для тестирования": "class",
    "Оптимизация производительности и повышение качества кода на Kotlin": "class",
    "Освоение основ Laravel": "class",
    "Изучение структуры каталогов и архитектуры Laravel": "class",
    "Создание и управление базами данных с использованием Eloquent ORM": "class",
    "Разработка маршрутов и роутеров в Laravel": "class",
    "Работа с контроллерами и методами в Laravel": "class",
    "Создание и управление миграциями, сидерами и моделями": "class",
    "Разработка и использование представлений с Blade шаблонизатором": "class",
    "Аутентификация и авторизация пользователей в Laravel": "class",
    "Работа с формами и валидацией данных в Laravel": "class",
    "Использование middleware для обработки HTTP-запросов": "class",
    "Реализация и работа с RESTful API с использованием Laravel": "class",
    "Работа с очередями и обработка фоновых задач в Laravel": "class",
    "Настройка и использование уведомлений и событий в Laravel": "class",
    "Оптимизация производительности и кеширование в Laravel": "class",
    "Развертывание Laravel-приложений на сервере и настройка окружений": "class",
    "Освоение основ Lisp": "class",
    "Освоение основ синтаксиса и структуры программ на Lisp": "class",
    "Изучение концепции списка как основной структуры данных в Lisp": "class",
    "Работа с функциями высшего порядка и лямбда-выражениями в Lisp": "class",
    "Понимание работы с рекурсией в Lisp": "class",
    "Изучение объектно-ориентированного программирования в Lisp (CLOS)": "class",
    "Работа с макросами и их использованием для создания новых конструкций языка": "class",
    "Изучение динамической типизации и обработки ошибок в Lisp": "class",
    "Реализация алгоритмов и структур данных в Lisp": "class",
    "Работа с глобальными и локальными переменными, окружениями и символами": "class",
    "Создание и использование функций для обработки данных в Lisp": "class",
    "Работа с вводом-выводом в Lisp: чтение и запись данных в файлы": "class",
    "Изучение взаимодействия Lisp с внешними библиотеками и другими языками": "class",
    "Оптимизация производительности программ на Lisp": "class",
    "Тестирование и отладка программ на Lisp": "class",
    "Разработка искусственного интеллекта и обработки данных с использованием Lisp": "class",
    "Основы программирования Microsoft Visual Studio": "class",
    "Освоение интерфейса и основных функций Microsoft Visual Studio": "class",
    "Создание и настройка нового проекта в Visual Studio": "class",
    "Изучение работы с различными типами проектов (например, консольные, веб-приложения, мобильные приложения)": "class",
    "Работа с отладчиком: установка точек останова и пошаговая отладка кода": "class",
    "Использование средств для тестирования кода и написания юнит-тестов": "class",
    "Интеграция с системами контроля версий, такими как Git и Team Foundation Server (TFS)": "class",
    "Настройка и использование инструментов для анализа производительности и памяти": "class",
    "Работа с библиотеками и внешними зависимостями (например, через NuGet)": "class",
    "Создание и работа с базами данных в Visual Studio через SQL Server": "class",
    "Разработка пользовательских интерфейсов с использованием Windows Forms, WPF или ASP.NET": "class",
    "Использование инструментов для создания и развертывания приложений (например, для мобильных устройств или облака)": "class",
    "Использование IntelliSense и других инструментов автодополнения для повышения продуктивности": "class",
    "Настройка и использование CI/CD пайплайнов для автоматизации развертывания приложений": "class",
    "Работа с компонентами и расширениями для расширения возможностей Visual Studio": "class",
    "Оптимизация кода и настройка сборки с помощью MSBuild и других инструментов сборки": "class",
    "Освоение Objective-C": "class",
    "Изучение основ объектно-ориентированного программирования (ООП) в Objective-C": "class",
    "Работа с классами, объектами и инкапсуляцией в Objective-C": "class",
    "Понимание работы с протоколами и делегированием в Objective-C": "class",
    "Использование и создание категорий для расширения функционала классов": "class",
    "Работа с памятью: ARC (Automatic Reference Counting) и ручное управление памятью": "class",
    "Обработка ошибок с использованием механизмов исключений в Objective-C": "class",
    "Работа с коллекциями: массивами, словарями и наборами в Objective-C": "class",
    "Создание и управление пользовательскими интерфейсами с использованием UIKit (iOS)": "class",
    "Разработка приложений для iOS с использованием Objective-C и Xcode": "class",
    "Интеграция с другими библиотеками и фреймворками, такими как Core Data и Core Animation": "class",
    "Работа с многозадачностью и асинхронным программированием в Objective-C": "class",
    "Разработка и использование сетевых приложений: HTTP-запросы и обработка ответов": "class",
    "Тестирование приложений и использование XCTest для юнит-тестирования": "class",
    "Оптимизация производительности и улучшение качества кода на Objective-C": "class",
    "Основы Pandas": "class",
    "Загрузка и чтение данных в Pandas из различных форматов (CSV, Excel, SQL, JSON и других)": "class",
    "Предобработка данных: очистка, фильтрация и преобразование данных": "class",
    "Обработка пропущенных значений и дублирующихся данных": "class",
    "Работа с датами и временем в Pandas": "class",
    "Манипуляции с индексами, сортировка и фильтрация данных в DataFrame": "class",
    "Агрегация данных с использованием группировки (groupby)": "class",
    "Преобразование и агрегирование данных с помощью методов pivot_table и melt": "class",
    "Работа с текстовыми данными: поиск, замена и разделение строк": "class",
    "Объединение и слияние данных из различных источников с помощью merge и concat": "class",
    "Визуализация данных с использованием Pandas и интеграции с библиотеками, такими как Matplotlib и Seaborn": "class",
    "Использование методов фильтрации и выборки данных с использованием условных выражений": "class",
    "Оптимизация работы с большими данными: использование памяти и производительность": "class",
    "Работа с категориальными переменными и их кодирование": "class",
    "Применение функций и методов для анализа данных и создания статистических отчетов": "class",
    "Основы Perl": "class",
    "Работа с переменными, типами данных и операторами в Perl": "class",
    "Использование регулярных выражений для обработки строк": "class",
    "Работа с массивами и хешами (словарями) в Perl": "class",
    "Создание и использование функций в Perl": "class",
    "Обработка ошибок и исключений в Perl с помощью eval и die": "class",
    "Работа с файлами и потоками ввода-вывода в Perl": "class",
    "Работа с модулями и библиотеками в Perl (CPAN)": "class",
    "Управление памятью и оптимизация программ на Perl": "class",
    "Обработка текстовых данных, парсинг файлов и данных в различных форматах": "class",
    "Работа с объектно-ориентированным программированием (ООП) в Perl": "class",
    "Разработка CGI-скриптов и веб-приложений с использованием Perl": "class",
    "Использование Perl для системного программирования и автоматизации задач": "class",
    "Разработка сетевых приложений и взаимодействие с API через HTTP и сокеты": "class",
    "Тестирование Perl-программ с использованием модулей, таких как Test::More и других инструментов для юнит-тестирования": "class",
    "Основы Roblox Studio": "class",
    "Освоение интерфейса Roblox Studio и основных инструментов разработки": "class",
    "Создание и настройка игрового мира в Roblox Studio": "class",
    "Работа с базовыми 3D-объектами и моделями в Roblox Studio": "class",
    "Изучение основ скриптинга на Lua для создания интерактивных элементов": "class",
    "Разработка игровых механик с использованием скриптов Lua": "class",
    "Создание персонажей и анимаций для игр в Roblox Studio": "class",
    "Работа с физикой и коллизиями в Roblox Studio": "class",
    "Создание пользовательского интерфейса (GUI) для взаимодействия с игроками": "class",
    "Программирование и настройка игровых событий (например, победы, поражения, счета)": "class",
    "Создание многопользовательских игр и работа с сетевой синхронизацией": "class",
    "Использование данных и сохранение прогресса игроков в игре": "class",
    "Оптимизация производительности игры для лучшего опыта игроков": "class",
    "Разработка системы инвентаря и магазина в Roblox Studio": "class",
    "Реализация системы анимаций для персонажей и объектов": "class",
    "Подготовка игры к публикации и монетизации на платформе Roblox": "class",
    "Основы Ruby on Rails": "class",
    "Создание и настройка нового проекта в Rails": "class",
    "Работа с базой данных: создание и управление моделями с использованием ActiveRecord": "class",
    "Разработка маршрутов и настройка роутинга в Rails": "class",
    "Использование контроллеров и представлений для обработки данных и отображения информации": "class",
    "Создание и использование форм в Rails: обработка ввода данных и валидация": "class",
    "Аутентификация и авторизация пользователей с использованием Devise или других гемов": "class",
    "Работа с RESTful API и создание JSON-ответов в Rails": "class",
    "Использование миграций для управления схемой базы данных": "class",
    "Работа с ассоциациями моделей: has_many, belongs_to и другие": "class",
    "Интеграция с внешними API и использование HTTP-запросов в Rails": "class",
    "Тестирование приложений с использованием RSpec и Minitest": "class",
    "Работа с JavaScript и фронтенд-разработкой в Rails через Webpacker": "class",
    "Оптимизация производительности приложения: кэширование, запросы к базе данных и индексы": "class",
    "Развертывание приложения на сервере и настройка окружений (например, Heroku, AWS)": "class",
    "Основы SQL": "class",
    "Работа с операторами фильтрации данных: WHERE, AND, OR, NOT": "class",
    "Использование функций агрегирования: COUNT, SUM, AVG, MIN, MAX": "class",
    "Группировка данных с помощью GROUP BY и фильтрация групп с HAVING": "class",
    "Сортировка данных с использованием ORDER BY": "class",
    "Создание и управление таблицами: CREATE, ALTER, DROP": "class",
    "Работа с первичными и внешними ключами (PRIMARY KEY, FOREIGN KEY)": "class",
    "Создание индексов для ускорения запросов": "class",
    "Объединение таблиц с использованием JOIN (INNER JOIN, LEFT JOIN, RIGHT JOIN, FULL JOIN)": "class",
    "Использование подзапросов (subqueries) в SELECT, INSERT, UPDATE, DELETE": "class",
    "Работа с транзакциями: COMMIT, ROLLBACK, SAVEPOINT": "class",
    "Создание представлений (views) для упрощения запросов": "class",
    "Использование SQL-функций и операторов для обработки строк и дат": "class",
    "Оптимизация запросов и индексация для улучшения производительности": "class",
    "Управление правами доступа и безопасностью в базе данных с использованием GRANT и REVOKE": "class",
    "Основы Scheme": "class",
    "Понимание принципов функционального программирования на Scheme": "class",
    "Работа с переменными и областью видимости (bindings) в Scheme": "class",
    "Изучение рекурсии как основного способа решения задач в Scheme": "class",
    "Создание и использование функций в Scheme": "class",
    "Работа с атомарными типами данных: числа, символы, строки": "class",
    "Использование списков как основной структуры данных в Scheme": "class",
    "Изучение концепции хвостовой рекурсии и оптимизация рекурсивных функций": "class",
    "Разработка и использование замыканий в Scheme": "class",
    "Реализация и использование макросов для расширения языка": "class",
    "Работа с условными выражениями и конструкциями (if, cond, case)": "class",
    "Обработка ошибок и исключений в Scheme": "class",
    "Применение ленивых вычислений и каррирования в Scheme": "class",
    "Работа с более сложными структурами данных, такими как деревья и графы": "class",
    "Изучение работы с модулями и пакетами в Scheme": "class",
    "Основы Spring": "class",
    "Настройка и создание Spring Boot приложений": "class",
    "Изучение принципов инверсии управления (IoC) и внедрения зависимостей (DI) в Spring": "class",
    "Работа с компонентами Spring: Beans, Annotations, и конфигурации": "class",
    "Создание и управление RESTful веб-сервисами с использованием Spring MVC": "class",
    "Работа с базами данных: настройка Spring Data JPA и взаимодействие с БД через Hibernate": "class",
    "Использование Spring Security для реализации аутентификации и авторизации": "class",
    "Разработка микросервисов с использованием Spring Cloud и Spring Boot": "class",
    "Настройка и использование Spring AOP для аспектно-ориентированного программирования": "class",
    "Работа с транзакциями в Spring: управление транзакциями и их распространение": "class",
    "Интеграция Spring с различными источниками данных (JMS, RabbitMQ, Kafka и другими)": "class",
    "Создание и тестирование приложений с использованием Spring Test и MockMvc": "class",
    "Оптимизация производительности приложений с использованием Spring Cache": "class",
    "Интеграция Spring с внешними сервисами и API (REST, SOAP, и другие)": "class",
    "Развертывание Spring приложений и настройка CI/CD пайплайнов для автоматизации развертывания": "class",
    "Основы Turbo Pascal": "class",
    "Изучение работы с переменными, типами данных и операторами в Turbo Pascal": "class",
    "Работа с условными конструкциями (if, case) для ветвления программ": "class",
    "Использование циклов (for, while, repeat) для итераций в программах": "class",
    "Создание и использование процедур и функций для организации кода": "class",
    "Работа с массивами и строками для обработки данных": "class",
    "Управление файлами: чтение и запись данных в Turbo Pascal": "class",
    "Работа с указателями для динамического выделения памяти": "class",
    "Реализация структуры данных: списки, стеки, очереди с использованием указателей": "class",
    "Обработка ошибок с использованием конструкций try-except в Turbo Pascal": "class",
    "Разработка простых графических приложений с использованием библиотеки Graph": "class",
    "Реализация базовых алгоритмов сортировки и поиска": "class",
    "Работа с функциями и процедурами для упрощения кода": "class",
    "Оптимизация программ и использование более эффективных структур данных": "class",
    "Разработка игр и простых приложений с графическим интерфейсом": "class",
    "Основы UX/UI дизайн": "class",
    "Изучение принципов пользовательского опыта (UX) и проектирования интерфейсов (UI)": "class",
    "Создание и анализ пользовательских персонажей (user personas)": "class",
    "Проектирование пользовательских потоков (user flows) и карты пути пользователя (user journey)": "class",
    "Понимание и применение принципов информационной архитектуры": "class",
    "Изучение принципов и техник юзабилити-тестирования и его внедрение в процесс разработки": "class",
    "Создание wireframes (каркасных макетов) для прототипирования интерфейсов": "class",
    "Разработка интерактивных прототипов с использованием инструментов, таких как Figma, Sketch, Adobe XD": "class",
    "Понимание и применение цветовых схем, типографики и визуальных иерархий для улучшения UI": "class",
    "Создание и поддержка дизайн-систем и компонентных библиотек": "class",
    "Изучение принципов адаптивного и отзывчивого дизайна (responsive design)": "class",
    "Оптимизация интерфейса для разных устройств и экранов (мобильные и десктопные версии)": "class",
    "Понимание и создание микровзаимодействий (microinteractions) для улучшения пользовательского опыта": "class",
    "Работа с доступностью интерфейсов (accessibility, a11y) для более широкой аудитории": "class",
    "Разработка и проведение usability-тестов для получения обратной связи и улучшения дизайна": "class",
    "Основы Unreal Engine": "class",
    "Создание и настройка первого проекта в Unreal Engine": "class",
    "Изучение системы Blueprint для визуального программирования": "class",
    "Работа с 3D-моделями и текстурами: импорты, размещение и настройка объектов": "class",
    "Создание и настройка уровней с использованием инструментов ландшафта и декораций": "class",
    "Работа с освещением и материалами для создания реалистичных сцен": "class",
    "Программирование логики игрового процесса с помощью Blueprints и C++": "class",
    "Разработка анимаций для персонажей и объектов с использованием Animation Blueprint": "class",
    "Создание взаимодействий игрока с объектами и окружающим миром (например, переключатели, двери, лифты)": "class",
    "Создание искусственного интеллекта (AI) для NPC с использованием Behavior Tree": "class",
    "Оптимизация производительности игры и использование профайлеров": "class",
    "Работа с физикой Unreal Engine для симуляции столкновений и поведения объектов": "class",
    "Создание многопользовательских игр с использованием сетевых возможностей Unreal Engine": "class",
    "Интеграция звука и музыки в игру с использованием аудиосистемы Unreal": "class",
    "Экспорт и публикация игры на различных платформах (PC, консоли, мобильные устройства)": "class",
    "Основы VBA": "class",
    "Автоматизация задач в Microsoft Excel с использованием VBA": "class",
    "Создание и настройка макросов для ускорения работы с данными": "class",
    "Работа с объектной моделью Excel: ячейки, строки, столбцы и диапазоны": "class",
    "Создание пользовательских форм (UserForms) для улучшения интерфейса пользователя": "class",
    "Разработка и использование функций для обработки данных в Excel": "class",
    "Работа с циклами (For, For Each, Do While) и условиями (If, Select Case) в VBA": "class",
    "Интерактивное взаимодействие с пользователями через всплывающие окна и сообщения (MsgBox, InputBox)": "class",
    "Использование событий в VBA для реагирования на действия пользователя (например, изменения в ячейках, открытие документа)": "class",
    "Работа с несколькими листами и рабочими книгами в одном проекте VBA": "class",
    "Оптимизация макросов для улучшения производительности и работы с большими объемами данных": "class",
    "Импорт и экспорт данных между Excel и другими приложениями (например, Access, текстовые файлы, базы данных)": "class",
    "Автоматизация отчетности и создание динамических отчетов с помощью VBA": "class",
    "Работа с графиками и диаграммами в Excel через VBA": "class",
    "Использование VBA для автоматизации работы с другими приложениями Microsoft Office (Word, Outlook, PowerPoint)": "class",
    "Основы Visual Basic": "class",
    "Создание простых программ с использованием основного функционала Visual Basic": "class",
    "Изучение работы с переменными, типами данных и операторами в Visual Basic": "class",
    "Разработка и использование функций и процедур в Visual Basic": "class",
    "Создание графического пользовательского интерфейса (GUI) с помощью Windows Forms": "class",
    "Обработка событий (например, нажатия кнопок, изменение текста) в Visual Basic": "class",
    "Работа с массивами и коллекциями для обработки данных": "class",
    "Использование условных операторов (If, Select Case) и циклов (For, While, Do) в Visual Basic": "class",
    "Обработка ошибок с использованием конструкции Try-Catch для повышения стабильности программ": "class",
    "Работа с файлами: чтение и запись данных в текстовые и бинарные файлы": "class",
    "Работа с базами данных через ADO.NET для хранения и извлечения данных": "class",
    "Использование многозадачности и работы с потоками в Visual Basic": "class",
    "Создание и настройка отчетов с использованием Crystal Reports или встроенных инструментов Visual Basic": "class",
    "Разработка клиент-серверных приложений с использованием TCP/IP и сокетов": "class",
    "Оптимизация программ для повышения производительности и работы с большими объемами данных": "class",
    "Основы Wordpress": "class",
    "Выбор и установка подходящей темы для сайта": "class",
    "Настройка и персонализация внешнего вида сайта через Customizer и настройки темы": "class",
    "Работа с плагинами для добавления функционала на сайт": "class",
    "Создание и управление страницами и записями в WordPress": "class",
    "Разработка и настройка меню для навигации по сайту": "class",
    "Работа с виджетами для добавления контента в боковые панели и футер": "class",
    "Управление мультимедийными файлами (изображения, видео, документы) в WordPress": "class",
    "Создание и настройка интернет-магазина с использованием плагина WooCommerce": "class",
    "Работа с пользовательскими полями и таксономиями для создания сложных структур контента": "class",
    "SEO-оптимизация сайта на WordPress с использованием плагинов (например, Yoast SEO)": "class",
    "Защита сайта с помощью плагинов безопасности и настройка резервного копирования": "class",
    "Интеграция с социальными сетями и настройка соцшаров и комментариев": "class",
    "Разработка и настройка форм обратной связи через плагины, такие как Contact Form 7": "class",
    "Оптимизация производительности сайта на WordPress (кэширование, сжатие изображений, CDN)": "class",
    "Основы jQuery": "class",
    "Изучение базовых операций с DOM: выбор элементов, изменение текста и атрибутов": "class",
    "Создание анимаций и эффектов с использованием jQuery (fade, slide, show, hide)": "class",
    "Обработка событий: клики, наведений мыши, изменение значений форм": "class",
    "Работа с AJAX для загрузки данных без перезагрузки страницы": "class",
    "Манипуляции с элементами формы: получение и установка значений, валидация данных": "class",
    "Создание и управление динамическим контентом (добавление/удаление элементов) на странице": "class",
    "Оптимизация производительности с использованием методов jQuery, таких как .on() для обработки событий": "class",
    "Использование плагинов jQuery для расширения функционала веб-страниц": "class",
    "Создание сложных эффектов и анимаций, таких как прокрутка страниц и переходы": "class",
    "Создание адаптивных и отзывчивых интерфейсов с использованием jQuery UI": "class",
    "Работа с JSON и другими форматами данных с помощью jQuery AJAX": "class",
    "Работа с модальными окнами, слайдерами и каруселями с использованием jQuery": "class",
    "Реализация drag-and-drop (перетаскивание элементов) с использованием jQuery UI": "class",
    "Отладка и устранение ошибок в коде с использованием консоли браузера и инструментов разработчика": "class",
    "Основы веб-программирования": "class",
    "Изучение CSS для стилизации веб-страниц и создания адаптивных дизайнов": "class",
    "Разработка интерактивных элементов с использованием JavaScript": "class",
    "Понимание и использование DOM (Document Object Model) для манипуляции элементами страницы": "class",
    "Создание и использование форм на веб-страницах, включая валидацию данных": "class",
    "Интеграция и работа с базами данных через серверный язык программирования (например, PHP, Node.js)": "class",
    "Основы работы с фреймворками JavaScript, такими как React, Angular, Vue.js": "class",
    "Изучение принципов разработки RESTful API и взаимодействие с ними через AJAX": "class",
    "Использование препроцессоров CSS, таких как Sass или LESS": "class",
    "Создание анимаций и эффектов с помощью CSS и JavaScript": "class",
    "Понимание основ безопасности веб-приложений: защита от XSS, CSRF и SQL-инъекций": "class",
    "Оптимизация производительности веб-страниц (кэширование, минимизация файлов, сжатие изображений)": "class",
    "Использование систем контроля версий (например, Git) для управления кодом и совместной работы": "class",
    "Разработка адаптивных и мобильных версий сайтов с использованием медиа-запросов и фреймворков (например, Bootstrap)": "class",
    "Развертывание веб-приложений на хостингах и настройка серверной части для работы с данными": "class",
    "Основы компьютерного зрения": "class",
    "Работа с библиотеками для компьютерного зрения, такими как OpenCV и PIL (Python Imaging Library)": "class",
    "Предобработка изображений: преобразования, фильтрация, улучшение качества изображений": "class",
    "Распознавание и сегментация объектов на изображениях и видео": "class",
    "Использование методов машинного обучения для классификации объектов на изображениях": "class",
    "Применение нейронных сетей и глубокого обучения для распознавания лиц и объектов": "class",
    "Разработка и обучение сверточных нейронных сетей (CNN) для решения задач компьютерного зрения": "class",
    "Анализ движения и отслеживание объектов на видео": "class",
    "Использование алгоритмов для извлечения признаков и дескрипторов (например, SIFT, SURF, ORB)": "class",
    "Разработка систем для распознавания текста на изображениях с помощью OCR (Optical Character Recognition)": "class",
    "Использование технологии глубокой сегментации изображений, такой как Mask R-CNN": "class",
    "Реализация системы дополненной реальности (AR) с использованием компьютерного зрения": "class",
    "Обработка и анализ медицинских изображений для диагностики (например, снимки МРТ, рентгеновские снимки)": "class",
    "Реализация систем распознавания жестов и движения (например, для взаимодействия с интерфейсами)": "class",
    "Оптимизация алгоритмов для реального времени: уменьшение времени отклика и использования вычислительных ресурсов": "class",
    "Основы программирования микроконтроллеров": "class",
    "Освоение основ программирования на C/C++ для работы с микроконтроллерами": "class",
    "Разработка программ для Arduino: работа с датчиками, модулями и исполнительными устройствами": "class",
    "Изучение интерфейсов микроконтроллеров (UART, SPI, I2C, CAN)": "class",
    "Создание схем подключения и взаимодействие микроконтроллера с периферийными устройствами": "class",
    "Работа с АЦП/ЦАП для обработки аналоговых сигналов": "class",
    "Разработка прошивок для работы с светодиодами, дисплеями и другими индикаторами": "class",
    "Создание систем управления электродвигателями (например, шаговые, DC и BLDC двигатели)": "class",
    "Программирование микроконтроллеров для работы в реальном времени (RTOS)": "class",
    "Работа с беспроводной связью: Wi-Fi, Bluetooth, ZigBee, LoRa": "class",
    "Разработка приложений на STM32 или ESP32 для более сложных проектов": "class",
    "Изучение систем управления энергопотреблением микроконтроллеров": "class",
    "Реализация систем сбора и обработки данных с использованием микроконтроллеров": "class",
    "Работа с системами безопасности: шифрование данных, защита от взлома прошивки": "class",
    "Отладка и тестирование программ для микроконтроллеров с использованием аппаратных и программных инструментов": "class",
    "Основы разработки компьютерных игр": "class",
    "Изучение движков для разработки игр, таких как Unity, Unreal Engine, или Godot": "class",
    "Программирование игровых механик с использованием языков C#, C++ или Python": "class",
    "Создание и настройка 2D и 3D-объектов для игр": "class",
    "Работа с физикой в играх: столкновения, гравитация, динамика объектов": "class",
    "Разработка анимаций для персонажей, объектов и окружения": "class",
    "Создание искусственного интеллекта для NPC (неигровых персонажей)": "class",
    "Разработка пользовательских интерфейсов (UI) для меню, HUD и интерактивных элементов": "class",
    "Работа со звуком: интеграция музыки, звуковых эффектов и голосов": "class",
    "Создание многопользовательских игр: сетевое взаимодействие и серверная часть": "class",
    "Оптимизация производительности игр: управление памятью, шейдерами и FPS": "class",
    "Работа с визуальными эффектами: постобработка, освещение, частицы": "class",
    "Разработка кроссплатформенных игр для ПК, мобильных устройств и консолей": "class",
    "Тестирование игр: поиск и исправление ошибок, настройка баланса": "class",
    "Публикация и продвижение игры на платформах Steam, App Store, Play Market или itch.io": "class",
    "Основы создания нейросетей": "class",
    "Освоение библиотек для работы с нейросетями, таких как TensorFlow, PyTorch или Keras": "class",
    "Создание простых нейронных сетей для решения базовых задач (например, классификации)": "class",
    "Понимание работы основных слоев нейронных сетей: Dense, Convolutional, Recurrent": "class",
    "Проектирование и обучение сверточных нейронных сетей (CNN) для анализа изображений": "class",
    "Изучение рекуррентных нейронных сетей (RNN) и LSTM для обработки последовательных данных": "class",
    "Создание моделей для обработки текстов: классификация, генерация, машинный перевод": "class",
    "Использование предобученных моделей и трансформеров, таких как GPT или BERT": "class",
    "Реализация систем генерации изображений или видео с использованием GAN (Generative Adversarial Networks)": "class",
    "Оптимизация и настройка гиперпараметров для улучшения качества модели": "class",
    "Работа с большими объемами данных: подготовка и аугментация данных": "class",
    "Реализация алгоритмов обучения с подкреплением для создания интеллектуальных агентов": "class",
    "Применение нейросетей для анализа временных рядов и прогнозирования": "class",
    "Использование нейросетей в прикладных задачах: медицина, финансы, игры, автопилоты": "class",
    "Развертывание обученных моделей на сервере или в облаке для реального использования": "class",
    "Основы Frontend - разработки": "class",
    "Изучение CSS для стилизации и адаптивной верстки веб-сайтов": "class",
    "Работа с JavaScript для добавления интерактивности и динамического контента": "class",
    "Использование препроцессоров CSS, таких как Sass или LESS, для удобной работы со стилями": "class",
    "Изучение популярных фреймворков JavaScript, таких как React, Angular или Vue.js": "class",
    "Создание адаптивных и отзывчивых дизайнов с помощью медиа-запросов и фреймворков (например, Bootstrap)": "class",
    "Работа с DOM (Document Object Model) для манипуляции элементами страницы": "class",
    "Освоение методов работы с API и интеграция данных через fetch/AJAX": "class",
    "Понимание принципов управления состоянием в приложениях (Redux, Vuex, Context API)": "class",
    "Использование инструментов сборки (Webpack, Vite, Parcel) и модульной разработки": "class",
    "Создание тестов для фронтенд-кода с помощью Jest, Cypress или Puppeteer": "class",
    "Работа с TypeScript для создания более структурированных и надежных приложений": "class",
    "Оптимизация производительности веб-приложений (lazy loading, минимизация файлов)": "class",
    "Изучение и применение практик доступности (Accessibility, A11Y) для создания удобных интерфейсов": "class",
    "Развертывание готовых фронтенд-приложений на хостинг (Netlify, Vercel, Firebase)": "class",
    "Основы Dart": "class",
    "Изучение концепций объектно-ориентированного программирования (ООП) на Dart": "class",
    "Создание простых приложений на языке Dart для консольного ввода/вывода": "class",
    "Работа с коллекциями: списки, множества и словари (List, Set, Map)": "class",
    "Изучение работы с асинхронностью: Future, Stream, async/await": "class",
    "Использование Dart вместе с Flutter для разработки мобильных приложений": "class",
    "Работа с обработкой ошибок и исключениями в Dart": "class",
    "Понимание работы с замыканиями и функциональным программированием в Dart": "class",
    "Изучение библиотек Dart для работы с HTTP-запросами и REST API": "class",
    "Создание модульного и масштабируемого кода с использованием пакетов и библиотек Dart": "class",
    "Работа с файлами и файловой системой в Dart": "class",
    "Оптимизация производительности приложений на Dart": "class",
    "Работа с null safety и избегание ошибок, связанных с null значениями": "class",
    "Создание серверных приложений с использованием Dart и платформы Shelf": "class",
    "Основы Go": "class",
    "Понимание особенностей строгой статической типизации в Go": "class",
    "Создание простых приложений с использованием базовых конструкций языка": "class",
    "Работа с горутинами (goroutines) и каналами для реализации конкурентности": "class",
    "Изучение стандартной библиотеки Go: работа с файлами, форматирование, ввод/вывод": "class",
    "Разработка RESTful API с использованием Go и фреймворков, таких как Gin или Echo": "class",
    "Оптимизация производительности приложений благодаря встроенным средствам профилирования Go": "class",
    "Работа с интерфейсами и понимание их роли в абстракции кода": "class",
    "Использование встроенных структур данных: массивы, срезы, карты (maps)": "class",
    "Создание и управление модулями с использованием системы модулей Go (Go Modules)": "class",
    "Обработка ошибок с помощью idiomatic error handling в Go": "class",
    "Работа с базами данных с использованием библиотеки GORM или стандартного database/sql": "class",
    "Создание кроссплатформенных приложений благодаря универсальной компиляции Go": "class",
    "Тестирование кода с помощью стандартной библиотеки тестирования (testing и benchmarks)": "class",
    "Разработка высоконагруженных распределенных систем и микросервисов на Go": "class",
    "Основы Cisco": "class",
    "Получение высшего уровня сертификации Cisco Certified Internetwork Expert (CCIE)": "class",
    "Подготовка к сертификации Cisco Certified CyberOps Associate для специалистов по кибербезопасности": "class",
    "Сертификация Cisco DevNet Associate для разработчиков, работающих с сетями": "class",
    "Углубление знаний в области сетевой безопасности через Cisco Certified Security Professional (CCSP)": "class",
    "Освоение технологий беспроводных сетей для сертификации Cisco Wireless Specialist": "class",
    "Изучение работы с сетями центров обработки данных для сертификации Cisco Data Center Specialist": "class",
    "Получение сертификации Cisco Collaboration для работы с коммуникационными системами": "class",
    "Подготовка к Cisco Certified Specialist – Enterprise Advanced Infrastructure Implementation": "class",
    "Изучение SD-WAN технологий для сертификации Cisco SD-WAN Specialist": "class",
    "Получение сертификации Cisco Certified Design Professional (CCDP) для проектирования сетей": "class",
    "Подготовка к Cisco Certified Service Provider для работы с крупными поставщиками услуг связи": "class",
    "Изучение облачных технологий для сертификации Cisco Certified Cloud Professional": "class",
    "Разработка автоматизированных решений и подготовка к экзамену Cisco DevNet Professional": "class",
    "Освоение основ сетевых технологий": "class",
    "Изучение принципов и конфигурации маршрутизации (RIP, EIGRP, OSPF)": "class",
    "Понимание работы коммутаторов и VLAN, настройка VLAN и VTP": "class",
    "Работа с IP-адресацией: подсети, суперсети, CIDR": "class",
    "Изучение базовых концепций безопасности сети (ACL, NAT, порт-секьюрити)": "class",
    "Практическая настройка сетевых устройств Cisco с использованием CLI": "class",
    "Конфигурация DHCP и DNS для локальных сетей": "class",
    "Изучение и применение концепций качества обслуживания (QoS)": "class",
    "Работа с протоколами передачи данных (STP, RSTP, EtherChannel)": "class",
    "Изучение и настройка VPN для обеспечения безопасности соединений": "class",
    "Понимание работы IPv6 и его конфигурация в сетях": "class",
    "Работа с технологиями WAN (PPP, Frame Relay, MPLS)": "class",
    "Использование сетевых инструментов диагностики и мониторинга (ping, traceroute, debug)": "class",
    "Подготовка к лабораторным заданиям и вопросам экзамена CCNA": "class",
    "Изучение основ автоматизации и управления сетями с использованием SDN и контроллеров Cisco DNA Center": "class",
    "Изучение основ алгоритмического языка КуМи": "class",
    "Решение задач с использованием линейных алгоритмов в КуМир": "class",
    "Освоение базовых конструкций ветвления: если, иначе": "class",
    "Работа с циклами: пока, для, реализация повторяющихся действий": "class",
    "Разработка и отладка алгоритмов на КуМир для решения школьных олимпиадных задач": "class",
    "Использование подпрограмм для создания модульного кода": "class",
    "Работа с текстовыми и числовыми данными: ввод и вывод информации": "class",
    "Изучение массивов и их применение в алгоритмах": "class",
    "Решение задач с вложенными циклами и сложной логикой": "class",
    "Разработка и тестирование рекурсивных алгоритмов на языке КуМир": "class",
    "Использование среды КуМир для обучения программированию в младших классах": "class",
    "Понимание основных принципов построения алгоритмов с использованием трассировки": "class",
    "Работа с графической средой КуМир для визуального представления алгоритмов": "class",
    "Создание программ для моделирования процессов (например, симуляции движения робота)": "class",
    "Подготовка к экзаменам и контрольным работам, основанным на языке КуМир": "class",
    "Основы .NET": "class",
    "Освоение принципов объектно-ориентированного программирования (ООП) в .NET": "class",
    "Разработка и отладка приложений с использованием Visual Studio": "class",
    "Работа с базами данных через Entity Framework и LINQ": "class",
    "Создание веб-приложений с использованием ASP.NET Core": "class",
    "Освоение принципов многозадачности и асинхронного программирования в .NET": "class",
    "Разработка кроссплатформенных приложений с помощью .NET MAUI": "class",
    "Создание и тестирование RESTful API с использованием ASP.NET Core Web API": "class",
    "Изучение безопасного программирования и защиты приложений на .NET": "class",
    "Работа с кэшированием, сессиями и аутентификацией в ASP.NET Core": "class",
    "Использование паттернов проектирования в .NET для улучшения структуры кода": "class",
    "Разработка десктопных приложений с использованием Windows Forms и WPF": "class",
    "Использование Dependency Injection и других принципов для улучшения качества кода": "class",
    "Создание и развертывание приложений на Azure с использованием .NET": "class",
    "Оптимизация производительности и профилирование приложений на платформе .NET": "class",
    "Основы 1С:Битрикс": "class",
    "Создание и настройка корпоративных сайтов на платформе 1С:Битрикс": "class",
    "Разработка и настройка интернет-магазинов с использованием 1С:Битрикс": "class",
    "Интеграция 1С:Битрикс с внешними системами и сервисами (например, ERP, CRM, маркетплейсы)": "class",
    "Изучение работы с компонентами и модулями платформы для расширения функционала": "class",
    "Настройка и использование системы управления пользователями и правами доступа": "class",
    "Разработка и настройка индивидуальных шаблонов и дизайна сайтов на 1С:Битрикс": "class",
    "Настройка и интеграция платежных систем в интернет-магазины на 1С:Битрикс": "class",
    "Оптимизация сайтов на 1С:Битрикс для поисковых систем (SEO)": "class",
    "Работа с API и создание RESTful API на 1С:Битрикс": "class",
    "Автоматизация процессов с использованием бизнес-процессов в 1С:Битрикс": "class",
    "Разработка и настройка интеграции с 1С:Предприятие для автоматизации учета и отчетности": "class",
    "Настройка и использование системы веб-аналитики для отслеживания посещаемости и поведения пользователей": "class",
    "Работа с версиями и настройка системы управления версиями для проектов на 1С:Битрикс": "class",
    "Развертывание и администрирование сайтов на платформе 1С:Битрикс в облаке и на сервере": "class",
    "Основы ASP.NET": "class",
    "Разработка RESTful API с использованием ASP.NET Core Web API": "class",
    "Создание динамичных веб-страниц с использованием ASP.NET MVC": "class",
    "Интеграция и работа с базами данных через Entity Framework и LINQ": "class",
    "Использование Razor для создания интерактивных веб-страниц и форм": "class",
    "Настройка аутентификации и авторизации в ASP.NET Core (Identity, JWT, OAuth)": "class",
    "Создание и управление сессиями и куками для персонализации приложений": "class",
    "Освоение принципов работы с кэшированием для повышения производительности приложений": "class",
    "Настройка и использование middleware для обработки запросов в ASP.NET Core": "class",
    "Разработка кроссплатформенных приложений с использованием ASP.NET Core": "class",
    "Использование асинхронного программирования и параллельных операций в ASP.NET": "class",
    "Интеграция с внешними сервисами, такими как платежные системы и API": "class",
    "Оптимизация производительности веб-приложений с использованием профилирования и кэширования": "class",
    "Тестирование приложений с использованием unit-тестирования и интеграционных тестов в ASP.NET Core": "class",
    "Развертывание и настройка приложений на сервере или в облаке (Azure, Docker)": "class",
    "Основы Android-разработки": "class",
    "Освоение основ Java и Kotlin для разработки Android-приложений": "class",
    "Изучение структуры Android-проекта и архитектуры приложений": "class",
    "Разработка пользовательских интерфейсов с использованием XML и Android Layouts": "class",
    "Работа с компонентами Android: Activity, Fragment, Service, BroadcastReceiver": "class",
    "Использование RecyclerView для создания списков и адаптеров": "class",
    "Интеграция с базами данных: использование SQLite и Room для хранения данных": "class",
    "Настройка и использование системы навигации в приложениях (Navigation Component)": "class",
    "Обработка асинхронных задач с использованием Coroutines и LiveData": "class",
    "Изучение и настройка REST API и работа с сетевыми запросами через Retrofit или Volley": "class",
    "Работа с мультимедийными данными: использование камеры, аудио и видео": "class",
    "Использование Google Play Services для работы с картами, геолокацией и уведомлениями": "class",
    "Создание и использование анимаций в Android-приложениях для улучшения пользовательского опыта": "class",
    "Интеграция с Firebase для авторизации, хранения данных в реальном времени и уведомлений": "class",
    "Оптимизация производительности Android-приложений и управление памятью": "class",
    "Подготовка и публикация приложения в Google Play Store: от создания подписей до обновлений": "class",
    "Основы Arduino": "class",
    "Изучение синтаксиса языка программирования C/C++ для Arduino": "class",
    "Создание простых схем и подключение базовых электронных компонентов (светодиоды, резисторы, кнопки)": "class",
    "Разработка проектов с использованием датчиков (температуры, движения, освещенности)": "class",
    "Работа с моторами и сервоприводами для создания движущихся объектов": "class",
    "Использование экранов LCD и OLED для отображения информации": "class",
    "Создание проектов с использованием звуковых сигналов, в том числе динамиков и пьезоизлучателей": "class",
    "Обработка аналоговых и цифровых сигналов: чтение значений с датчиков и управление выводами": "class",
    "Разработка системы управления освещением и автоматизации (например, умный дом)": "class",
    "Использование радиомодулей (например, NRF24L01) для создания беспроводных систем": "class",
    "Проектирование и реализация датчиков на базе Arduino для мониторинга окружающей среды": "class",
    "Работа с беспроводными технологиями, такими как Wi-Fi и Bluetooth, для создания удалённых проектов": "class",
    "Использование реального времени в проектах с использованием часов реального времени (RTC)": "class",
    "Создание автономных проектов с использованием аккумуляторов и управления энергопотреблением": "class",
    "Разработка и отладка сложных роботов и автоматизированных систем с использованием Arduino": "class",
    "Освоение BASIC": "class",
    "Изучение принципов программирования с использованием простых команд и операторов": "class",
    "Разработка простых математических и логических операций в BASIC": "class",
    "Создание текстовых интерфейсов для взаимодействия с пользователем": "class",
    "Работа с переменными, типами данных и операциями с ними": "class",
    "Изучение использования циклов (FOR, WHILE) для автоматизации повторяющихся действий": "class",
    "Использование условных операторов (IF, ELSE) для принятия решений в программе": "class",
    "Создание и использование подпрограмм для улучшения структуры кода": "class",
    "Разработка игр и графических приложений с использованием BASIC": "class",
    "Работа с массивами для хранения и обработки данных": "class",
    "Реализация ввода/вывода данных с клавиатуры и на экране": "class",
    "Проектирование и реализация простых текстовых меню и интерфейсов": "class",
    "Использование математических и строковых функций для обработки данных": "class",
    "Оптимизация работы программы для эффективного использования памяти": "class",
    "Создание учебных и практических проектов для закрепления знаний": "class",
    "Основы C++": "class",
    "Освоение синтаксиса C++ и основ программирования": "class",
    "Изучение принципов объектно-ориентированного программирования (ООП) в C++": "class",
    "Разработка алгоритмов с использованием стандартных библиотек C++ (STL)": "class",
    "Использование указателей и ссылок для работы с памятью": "class",
    "Реализация структур данных, таких как массивы, списки, стеки и очереди": "class",
    "Работа с файлами: чтение и запись данных в файлы": "class",
    "Использование классов и объектов для создания структур программ": "class",
    "Освоение работы с динамическим выделением памяти и операциями с памятью (new, delete)": "class",
    "Разработка многозадачных программ с использованием потоков (threads)": "class",
    "Использование шаблонов (templates) для создания универсальных функций и классов": "class",
    "Разработка и использование исключений для обработки ошибок": "class",
    "Работа с алгоритмами сортировки и поиска, а также с их оптимизацией": "class",
    "Изучение принципов разработки производительных и эффективных программ": "class",
    "Разработка игр и графических приложений с использованием библиотек (например, SFML, OpenGL)": "class",
    "Основы Construct": "class",
    "Создание 2D-игр с использованием визуальных скриптов и логики": "class",
    "Разработка простых платформеров с использованием физических движков": "class",
    "Работа с анимациями и спрайтами для создания динамичного контента": "class",
    "Изучение использования событий (Event System) для логики игры": "class",
    "Проектирование уровней и создание редактора уровней в Construct": "class",
    "Работа с переменными и массивами для хранения данных в игре": "class",
    "Создание пользовательских интерфейсов (UI) для взаимодействия с игроками": "class",
    "Интеграция звуков и музыки для улучшения игрового опыта": "class",
    "Использование шейдеров и визуальных эффектов для создания уникальной графики": "class",
    "Работа с инвентарем и системой предметов в игре": "class",
    "Создание AI (искусственного интеллекта) для врагов и NPC в игре": "class",
    "Многоплатформенная разработка: экспорт игры на мобильные устройства, ПК и веб": "class",
    "Тестирование и отладка игры, устранение багов и оптимизация": "class",
    "Публикация игр на популярных платформах (например, Google Play, Itch.io, Steam)": "class",
    "Основы Django": "class",
    "Создание базовых моделей данных с использованием Django ORM": "class",
    "Работа с админ-панелью Django для управления контентом": "class",
    "Разработка RESTful API с использованием Django Rest Framework": "class",
    "Использование шаблонов (templates) для создания динамичных веб-страниц": "class",
    "Настройка и работа с формами для сбора данных от пользователей": "class",
    "Реализация аутентификации и авторизации пользователей с использованием Django": "class",
    "Создание и настройка системы URL-адресов и маршрутизации в приложении": "class",
    "Работа с базами данных, миграциями и выполнением сложных SQL-запросов через Django ORM": "class",
    "Использование сессий и cookies для хранения данных между запросами": "class",
    "Реализация функционала для загрузки и обработки файлов в Django": "class",
    "Оптимизация производительности веб-приложений с использованием кеширования в Django": "class",
    "Разработка и настройка многоуровневых форм и сложных бизнес-логик в Django": "class",
    "Интеграция Django с внешними API и сервисами (например, платежными системами, облачными сервисами)": "class",
    "Развертывание Django-приложений на сервере (например, с использованием Docker, Gunicorn, Nginx)": "class",
    "Основы Eclipse": "class",
    "Настройка Eclipse для разработки на разных языках программирования (Java, C++, Python и др.)": "class",
    "Разработка Java-программ с использованием Eclipse IDE": "class",
    "Работа с проектами в Eclipse: создание, настройка и управление проектами": "class",
    "Использование инструментов отладки в Eclipse для поиска и устранения ошибок в коде": "class",
    "Работа с версиями кода через интеграцию с Git и SVN в Eclipse": "class",
    "Использование плагинов Eclipse для расширения функционала IDE": "class",
    "Создание и использование Maven и Gradle проектов в Eclipse для управления зависимостями": "class",
    "Реализация юнит-тестов с использованием JUnit в Eclipse": "class",
    "Создание и настройка веб-приложений с использованием Eclipse и серверов приложений (Tomcat, JBoss)": "class",
    "Работа с базами данных через Eclipse Data Tools Platform (DTP)": "class",
    "Интеграция с Docker для создания и управления контейнерами прямо из Eclipse": "class",
    "Использование Eclipse для разработки мобильных приложений (например, через Android SDK)": "class",
    "Настройка и использование инструментов для профилирования и оптимизации производительности в Eclipse": "class",
    "Развертывание и деплой приложений на сервер с использованием Eclipse": "class",
    "Основы Flask": "class",
    "Настройка маршрутов и работа с HTTP-запросами в Flask": "class",
    "Использование шаблонов Jinja2 для динамического отображения данных в веб-страницах": "class",
    "Работа с формами и валидацией данных с помощью Flask-WTF": "class",
    "Интеграция с базами данных через SQLAlchemy и создание моделей данных": "class",
    "Настройка аутентификации и авторизации пользователей с использованием Flask-Login": "class",
    "Разработка RESTful API с использованием Flask-RESTful и Flask-SQLAlchemy": "class",
    "Обработка ошибок и создание пользовательских страниц ошибок": "class",
    "Работа с файлами: загрузка и обработка изображений, документов и других файлов": "class",
    "Настройка и использование Flask для обработки веб-сокетов (WebSockets)": "class",
    "Создание и использование Middleware для обработки запросов и ответов": "class",
    "Оптимизация производительности Flask-приложений с помощью кеширования и сжатия": "class",
    "Использование Flask с внешними API и интеграция сторонних сервисов": "class",
    "Развертывание Flask-приложений на сервере (например, с использованием Gunicorn и Nginx)": "class",
    "Основы Git": "class",
    "Работа с основными командами Git: git init, git add, git commit, git status": "class",
    "Изучение ветвления в Git: создание, переключение и слияние веток": "class",
    "Использование Git для совместной работы: клонирование репозиториев, создание pull-запросов": "class",
    "Решение конфликтов слияния и использование git merge и git rebase": "class",
    "Работа с удаленными репозиториями: использование git remote, git push, git pull": "class",
    "Реализация ветвления по модели Git Flow или GitHub Flow": "class",
    "Использование тегов в Git для маркировки релизов и версий": "class",
    "Оценка истории изменений: git log, git diff, git blame": "class",
    "Работа с подмодулями в Git: добавление и обновление подмодулей": "class",
    "Реализация отката изменений с использованием git revert, git reset, git checkout": "class",
    "Изучение лучшей практики написания сообщений коммитов": "class",
    "Использование .gitignore для исключения файлов и папок из коммитов": "class",
    "Настройка и использование Git hooks для автоматизации задач (например, pre-commit)": "class",
    "Управление несколькими ветками и разрешение конфликтов в процессе командной работы": "class",
    "Основы Joomla": "class",
    "Создание и настройка сайтов на платформе Joomla": "class",
    "Установка и настройка расширений (компонентов, модулей, плагинов)": "class",
    "Разработка и настройка шаблонов для кастомизации внешнего вида сайта": "class",
    "Создание и управление контентом: статьи, категории, меню": "class",
    "Работа с пользователями: создание, управление правами доступа и регистрация пользователей": "class",
    "Использование Joomla для создания многогранных сайтов (интернет-магазины, форумы, блоги)": "class",
    "Настройка SEO для Joomla-сайта: метатеги, URL-адреса, карты сайта": "class",
    "Интеграция Joomla с внешними сервисами и API": "class",
    "Оптимизация производительности сайта на Joomla (кэширование, сжатие)": "class",
    "Создание многоязычных сайтов с использованием встроенных возможностей Joomla": "class",
    "Работа с шаблонами и темами: адаптация шаблонов под мобильные устройства (Responsive Design)": "class",
    "Создание и управление формами для сбора данных пользователей (например, контактные формы)": "class",
    "Развертывание сайта на сервере: настройка хостинга и баз данных": "class",
    "Обновление и поддержка безопасности Joomla-сайтов: регулярные обновления, бэкапы, защита от взлома": "class",
    "Основы LabVIEW": "class",
    "Создание простых программ на LabVIEW с использованием графического программирования": "class",
    "Работа с виртуальными приборами и сенсорами в LabVIEW для сбора данных": "class",
    "Разработка систем автоматизации с использованием LabVIEW": "class",
    "Использование блок-схем для визуального представления логики программы": "class",
    "Настройка и работа с элементами пользовательского интерфейса (UI) в LabVIEW": "class",
    "Подключение и взаимодействие с внешними устройствами через порты и интерфейсы (GPIB, USB, COM)": "class",
    "Обработка и анализ данных с использованием встроенных функций и математических инструментов LabVIEW": "class",
    "Использование циклов и структур для управления потоком выполнения программы": "class",
    "Интеграция LabVIEW с другими языками программирования и внешними системами": "class",
    "Разработка и отладка программ для выполнения измерений в реальном времени": "class",
    "Использование LabVIEW для создания тестовых систем и автоматического тестирования": "class",
    "Разработка системы сбора и обработки сигналов с датчиков (например, температуры, давления)": "class",
    "Моделирование процессов и создание графиков для визуализации данных": "class",
    "Подготовка и настройка отчетности по результатам измерений и тестирования в LabVIEW": "class",
    "Основы Lazarus": "class",
    "Создание простых приложений с использованием Pascal и среды Lazarus": "class",
    "Работа с графическим интерфейсом (GUI) с использованием компонентов Lazarus": "class",
    "Разработка многослойных приложений с использованием принципов ООП в Lazarus": "class",
    "Работа с базами данных: использование компонентов для подключения к базам данных (SQL, MySQL, SQLite)": "class",
    "Разработка кроссплатформенных приложений для Windows, Linux и macOS": "class",
    "Использование событий и обработчиков событий для создания интерактивных приложений": "class",
    "Реализация мультимедийных приложений с использованием аудио и видео в Lazarus": "class",
    "Работа с графикой и изображениями, создание визуальных эффектов": "class",
    "Обработка файлов и директорий, создание систем управления файлами": "class",
    "Оптимизация производительности приложений, управление памятью и ресурсами": "class",
    "Использование встроенных библиотек и модулей для расширения функционала приложений": "class",
    "Создание и отладка программ с использованием многоуровневых форм и интерфейсов": "class",
    "Интеграция с внешними API и сервисами (например, создание сетевых приложений)": "class",
    "Развертывание и подготовка приложений для распространения (создание установочных пакетов)": "class",
    "Основы Lua": "class",
    "Создание простых программ и скриптов с использованием Lua": "class",
    "Использование Lua для автоматизации задач и создания утилит": "class",
    "Работа с таблицами в Lua (основной тип данных) для хранения и обработки информации": "class",
    "Освоение функций и замыканий (closures) для эффективного написания кода": "class",
    "Разработка скриптов для игровых движков, например, Love2D или Roblox": "class",
    "Работа с метатаблицами и метаметом Lua для изменения поведения объектов": "class",
    "Использование Lua в качестве встроенного языка для других приложений (сценариев)": "class",
    "Интеграция Lua с C/C++ для расширения функционала": "class",
    "Работа с модулями и библиотеками в Lua для улучшения структуры программ": "class",
    "Обработка ошибок и исключений в Lua с использованием pcall и xpcall": "class",
    "Оптимизация производительности Lua-скриптов для быстродействующих приложений": "class",
    "Реализация простых игр и приложений с использованием Lua и игровых движков": "class",
    "Создание графических интерфейсов с использованием Lua (например, с Love2D или LÖVE)": "class",
    "Работа с внешними API и интеграция с другими языками и сервисами (например, Lua с JSON или REST API)": "class",
    "Основы Node.js": "class",
    "Разработка простых серверных приложений с использованием Node.js": "class",
    "Работа с модулями Node.js и использование npm для установки зависимостей": "class",
    "Создание и настройка HTTP-сервера с использованием встроенного модуля http": "class",
    "Работа с асинхронным кодом в Node.js: использование коллбеков, промисов и async/await": "class",
    "Разработка RESTful API с использованием Express.js и маршрутизации": "class",
    "Обработка запросов и ответов, работа с методами HTTP (GET, POST, PUT, DELETE)": "class",
    "Работа с базами данных: интеграция с MongoDB, MySQL, PostgreSQL через драйверы и ORM": "class",
    "Использование middleware в Express для обработки данных и аутентификации": "class",
    "Разработка веб-приложений с использованием шаблонов (например, EJS или Pug)": "class",
    "Интеграция с внешними сервисами и API с использованием HTTP-клиентов, например, Axios": "class",
    "Настройка и использование WebSockets для реального времени (например, с Socket.io)": "class",
    "Оптимизация производительности Node.js-приложений, использование кеширования и балансировки нагрузки": "class",
    "Развертывание Node.js приложений на сервере (например, с использованием Docker или на облачных платформах)": "class",
    "Тестирование и отладка Node.js приложений с использованием инструментов, таких как Mocha, Chai и другие": "class",
    "Основы PHP": "class",
    "Работа с формами: получение и обработка данных из пользовательских форм": "class",
    "Основы работы с базами данных: подключение к MySQL, выполнение запросов с использованием PDO или MySQLi": "class",
    "Разработка динамических веб-страниц с использованием PHP и HTML": "class",
    "Использование сессий и cookies для хранения данных пользователя между запросами": "class",
    "Реализация аутентификации и авторизации пользователей с использованием PHP": "class",
    "Создание и использование функций в PHP для улучшения структуры кода": "class",
    "Работа с файлами: загрузка, чтение, запись и удаление файлов с использованием PHP": "class",
    "Разработка RESTful API с использованием PHP для взаимодействия с клиентскими приложениями": "class",
    "Обработка ошибок и исключений в PHP с использованием try-catch и пользовательских обработчиков ошибок": "class",
    "Использование шаблонов для разделения логики и представления (например, с использованием шаблонных движков типа Twig)": "class",
    "Интеграция PHP с внешними API и сервисами (например, с REST или SOAP API)": "class",
    "Оптимизация производительности PHP-приложений: кеширование, сжатие данных и использование кэширования на уровне приложений": "class",
    "Разработка и настройка простых CMS (Content Management Systems) с использованием PHP": "class",
    "Развертывание PHP-приложений на сервере, настройка окружений и использование инструментов для деплоя": "class",
    "Основы Pascal": "class",
    "Создание простых программ с использованием переменных, операторов и выражений": "class",
    "Работа с циклами и условиями (for, while, if, case) для управления потоком выполнения программы": "class",
    "Использование процедур и функций для организации кода": "class",
    "Работа с массивами и динамическими структурами данных": "class",
    "Создание рекурсивных функций для решения задач": "class",
    "Обработка строковых данных и работа с текстом в Pascal": "class",
    "Использование указателей и работа с памятью (например, динамическое выделение памяти)": "class",
    "Основы объектно-ориентированного программирования в Pascal (например, использование классов и объектов)": "class",
    "Реализация алгоритмов сортировки и поиска в Pascal": "class",
    "Разработка графических приложений с использованием библиотеки графики (например, BGI или графические компоненты Delphi)": "class",
    "Разработка простых консольных и текстовых приложений": "class",
    "Оптимизация производительности программ и улучшение структуры кода": "class",
    "Использование Pascal для решения математических задач и моделирования процессов": "class",
    "Основы Prolog": "class",
    "Изучение принципа логического программирования и его особенностей в Prolog": "class",
    "Решение задач с использованием фактов, правил и запросов в Prolog": "class",
    "Работа с базой данных фактов и правил для представления знаний": "class",
    "Использование логического вывода для поиска решений задач": "class",
    "Понимание и использование механизма унификации в Prolog для сопоставления структур": "class",
    "Реализация и работа с рекурсией в Prolog для решения задач": "class",
    "Разработка программ для решения задач на графах и деревьях (поиск в глубину, поиск в ширину)": "class",
    "Использование предикатов для организации сложных вычислений и манипуляций с данными": "class",
    "Реализация алгоритмов поиска и сортировки на основе логического вывода": "class",
    "Разработка и использование предикатов для работы с текстами и строками": "class",
    "Реализация решений задач на шахматах, судоку и других логических играх с использованием Prolog": "class",
    "Разработка систем искусственного интеллекта и экспертных систем с использованием Prolog": "class",
    "Оптимизация логических программ и повышение их производительности": "class",
    "Интеграция Prolog с другими языками программирования для расширения возможностей приложений": "class",
    "Основы React": "class",
    "Разработка простых интерфейсов с использованием функциональных компонентов": "class",
    "Работа с состоянием компонентов с использованием useState": "class",
    "Обработка событий и взаимодействие с пользователем в React": "class",
    "Создание и использование эффектов с помощью useEffect для побочных эффектов": "class",
    "Роутинг и управление переходами между страницами с использованием React Router": "class",
    "Управление состоянием приложения с использованием контекста (React Context API)": "class",
    "Использование Redux для глобального состояния и управления данными приложения": "class",
    "Оптимизация производительности React-приложений (мемоизация, lazy loading)": "class",
    "Разработка форм в React, валидация и управление данными формы": "class",
    "Работа с API: запросы к серверу с использованием fetch или библиотеки Axios": "class",
    "Создание компонентов и переиспользуемых UI-элементов с использованием React hooks": "class",
    "Тестирование компонентов с использованием библиотеки Jest и React Testing Library": "class",
    "Интеграция React с другими библиотеками и фреймворками (например, с Bootstrap или Material-UI)": "class",
    "Развертывание React-приложений на сервере (например, с использованием Netlify или Vercel)": "class",
    "Основы Ruby": "class",
    "Создание и использование методов для организации кода в Ruby": "class",
    "Работа с массивами, хешами и другими коллекциями данных в Ruby": "class",
    "Использование объектов и классов для организации объектно-ориентированного кода": "class",
    "Реализация наследования и полиморфизма в Ruby": "class",
    "Работа с модулями и включение их в классы для расширения функционала": "class",
    "Обработка исключений и ошибок с использованием begin-rescue-end": "class",
    "Работа с файлами: чтение, запись, изменение и удаление файлов в Ruby": "class",
    "Использование регулярных выражений для поиска и обработки строк": "class",
    "Работа с библиотеками и гемами для расширения функциональности приложений": "class",
    "Создание веб-приложений с использованием фреймворка Ruby on Rails": "class",
    "Реализация тестирования кода с использованием RSpec или MiniTest": "class",
    "Разработка CLI-программ с использованием библиотек для командной строки": "class",
    "Оптимизация производительности Ruby-приложений и профилирование кода": "class",
    "Развертывание и обслуживание Ruby-приложений на сервере (например, с использованием Heroku или Docker)": "class",
    "Основы SAP ERP": "class",
    "Изучение интерфейса пользователя SAP и навигации в системе": "class",
    "Основы работы с модулями SAP, такими как MM (Управление материалами), SD (Продажи и распределение)": "class",
    "Настройка и управление основными данными в SAP, такими как материалы, поставщики, клиенты": "class",
    "Интеграция различных модулей SAP (например, MM и SD, MM и FI)": "class",
    "Управление закупками и снабжением с использованием модуля MM": "class",
    "Процесс управления продажами в SAP с использованием модуля SD": "class",
    "Работа с финансами и бухгалтерией с использованием модуля FI": "class",
    "Управление производственными процессами с использованием модуля PP (Производственное планирование)": "class",
    "Создание и управление заказами, планирование поставок и отслеживание инвентаря": "class",
    "Обработка и анализ отчетности в SAP, создание стандартных и пользовательских отчетов": "class",
    "Работа с документами и данными в SAP: создание и изменение заказов, счетов и платежей": "class",
    "Управление человеческими ресурсами с использованием модуля HR (Человеческие ресурсы)": "class",
    "Реализация и настройка SAP Fiori для улучшения пользовательского интерфейса и мобильности": "class",
    "Конфигурация и настройка системы SAP для специфических бизнес-потребностей и процессов": "class",
    "Основы Scala": "class",
    "Работа с переменными, типами данных и коллекциями в Scala": "class",
    "Использование функций высшего порядка и лямбда-выражений в Scala": "class",
    "Реализация классов и объектов в Scala, работа с ООП-концепциями": "class",
    "Применение наследования, трейтов и интерфейсов в Scala": "class",
    "Работа с сопоставлением с образцом (Pattern Matching) для обработки данных": "class",
    "Работа с коллекциями: списки, множества, карты, очереди в Scala": "class",
    "Реализация многозадачности и параллельных вычислений с использованием ак Actors в Akka": "class",
    "Использование Option, Try, Future и других конструкций для обработки ошибок и асинхронного программирования": "class",
    "Работа с библиотеками и фреймворками Scala, такими как Akka, Play Framework, Spark": "class",
    "Оптимизация кода с помощью Immutable Data Structures и других функциональных подходов": "class",
    "Тестирование Scala-кода с использованием фреймворков, таких как ScalaTest или Specs2": "class",
    "Разработка RESTful API и веб-приложений с использованием Play Framework": "class",
    "Использование для обработки больших данных с Apache Spark": "class",
    "Интеграция Scala с Java для расширения функционала и использования существующих библиотек": "class",
    "Основы Scratch": "class",
    "Изучение блоков команд Scratch для создания простых программ": "class",
    "Создание интерактивных анимаций с использованием спрайтов и фонов": "class",
    "Разработка игр с элементами управления, таких как кнопки и клавиши": "class",
    "Использование переменных и списков для хранения данных в проектах": "class",
    "Создание взаимодействия между персонажами и элементами сцены с помощью условий и циклов": "class",
    "Работа с событиями, такими как нажатие клавиш и касание объектов": "class",
    "Использование звуков и музыки для улучшения взаимодействия с пользователем": "class",
    'Создание алгоритмов для простых игр, таких как "угадай число" или лабиринты': "class",
    "Разработка проектов с использованием таймеров и счетчиков": "class",
    "Использование операторов для математических вычислений и обработки данных": "class",
    "Создание проектов с многими уровнями или переходами между сценами": "class",
    "Интеграция графики и анимации для улучшения визуальной части проекта": "class",
    "Работа с расширениями Scratch (например, для подключения датчиков, камер или голосовых команд)": "class",
    "Разработка образовательных проектов и интерактивных историй для детей": "class",
    "Создание и использование функций в Swift": "class",
    "Работа с основными структурами данных: массивы, словари, множества": "class",
    "Использование условных операторов и циклов для управления потоком выполнения": "class",
    "Обработка ошибок и исключений с использованием do-try-catch в Swift": "class",
    "Реализация объектно-ориентированного программирования: классы, структуры, протоколы": "class",
    "Применение наследования, полиморфизма и расширений в Swift": "class",
    "Использование замыканий (closures) и функций высшего порядка": "class",
    "Работа с коллекциями: фильтрация, сортировка и обработка данных с помощью методов коллекций": "class",
    "Создание и управление пользовательским интерфейсом с использованием UIKit": "class",
    "Работа с автолейаутами и динамическими интерфейсами в iOS-приложениях": "class",
    "Использование SwiftUI для создания современных пользовательских интерфейсов": "class",
    "Разработка и настройка навигации в iOS-приложениях (табличные и коллекционные виды, навигационные контроллеры)": "class",
    "Интеграция с внешними API, работа с сетью и загрузка данных в Swift (с использованием URLSession)": "class",
    "Тестирование приложений с использованием XCTest и создание юнит-тестов для Swift-кода": "class",
    "Основы TypeScript": "class",
    "Работа с интерфейсами для описания структуры объектов и типов данных": "class",
    "Использование классов и объектов в TypeScript для объектно-ориентированного программирования": "class",
    "Типизация функций и параметров, использование типов возвращаемых значений": "class",
    "Работа с массивами, кортежами и другими коллекциями в TypeScript": "class",
    "Использование объединений (union types) и пересечений (intersection types) для сложной типизации": "class",
    "Настройка и использование перечислений (enums) для улучшения читаемости кода": "class",
    "Работа с generics для создания универсальных компонентов и функций": "class",
    "Интеграция TypeScript с JavaScript-кодом и настройка проекта для совместной работы": "class",
    "Использование типов для работы с асинхронным кодом и Promise": "class",
    "Работа с модулями и импортами/экспортами в TypeScript для организации кода": "class",
    "Использование декораторов для улучшения архитектуры приложения": "class",
    "Конфигурация TypeScript проекта через файл tsconfig.json": "class",
    "Интеграция с фронтенд-фреймворками, такими как Angular и React, для улучшения типизации": "class",
    "Тестирование TypeScript-программ с использованием популярных библиотек, таких как Jest или Mocha": "class",
    "Основы Unity3D": "class",
    "Создание и настройка 3D-объектов, работа с моделями и текстурами": "class",
    "Использование компонентов и объектов в Unity (colliders, rigidbodies, lights, cameras)": "class",
    "Разработка и настройка анимаций для объектов и персонажей с использованием Animator и Animation Clips": "class",
    "Реализация движений и поведения персонажей с использованием скриптов на C#": "class",
    "Создание игрового мира: работа с terrain, ландшафтами и окружающей средой": "class",
    "Создание и настройка камеры: свободная и следящая камеры": "class",
    "Работа с физикой: гравитация, столкновения, физические материалы": "class",
    "Разработка пользовательского интерфейса (UI) для взаимодействия с игроком (кнопки, меню, текстовые поля)": "class",
    "Интеграция звуков и музыки в игру, настройка аудио-источников и звуковых эффектов": "class",
    "Сетевое взаимодействие и создание многопользовательских игр с использованием Unity Networking": "class",
    "Оптимизация производительности игры и использование инструментов профилирования в Unity": "class",
    "Скрипты для AI (искусственного интеллекта): создание врагов, NPC и паттернов поведения": "class",
    "Использование физики частиц для создания визуальных эффектов (дождь, пыль, огонь)": "class",
    "Реализация развертывания игры на различных платформах: ПК, мобильные устройства, консоли": "class",
    "Основы VB.NET": "class",
    "Создание и использование функций и процедур для организации кода": "class",
    "Работа с условными операторами и циклами для управления логикой выполнения программы": "class",
    "Основы объектно-ориентированного программирования: классы, объекты, наследование": "class",
    "Использование коллекций, таких как массивы, списки, словари, очереди и стеки": "class",
    "Обработка ошибок и исключений с помощью конструкции Try...Catch...Finally": "class",
    "Разработка графических интерфейсов с использованием Windows Forms": "class",
    "Работа с базами данных: подключение, выполнение запросов и обработка результатов": "class",
    "Использование LINQ (Language Integrated Query) для работы с коллекциями и базами данных": "class",
    "Разработка многозадачных приложений с использованием асинхронного программирования и потоков": "class",
    "Создание веб-приложений с использованием ASP.NET и VB.NET": "class",
    "Разработка и отладка серверных приложений с использованием VB.NET и IIS": "class",
    "Интеграция с внешними API и библиотеками через HTTP-запросы и JSON/XML": "class",
    "Тестирование и отладка VB.NET приложений с использованием встроенных средств Visual Studio": "class",
    "Основы Verilog": "class",
    "Работа с различными типами данных в Verilog: битовые поля, регистры, векторы": "class",
    "Создание и использование модулей (modules) для структурирования проектов": "class",
    "Применение процедур (always, initial) для моделирования поведения логических элементов": "class",
    "Разработка тестовых схем (testbenches) для проверки функциональности проекта": "class",
    "Проектирование синхронных и асинхронных цепей с использованием тактовых сигналов": "class",
    "Работа с блоками управления (if, case, for, while) для реализации логических операций": "class",
    "Использование временных диаграмм и симуляций для проверки работы схем": "class",
    "Создание простых цифровых устройств, таких как мультиплексоры, декодеры и сумматоры": "class",
    "Работа с последовательными и параллельными интерфейсами для обмена данными": "class",
    "Проектирование памяти и регистров: RAM, ROM, сдвиговые регистры": "class",
    "Создание и тестирование простых процессоров на Verilog": "class",
    "Использование параметров (parameters) для конфигурации модулей и упрощения их использования": "class",
    "Интеграция Verilog с другими языками и инструментами для разработки схем": "class",
    "Оптимизация Verilog-кода для повышения эффективности и уменьшения затрат ресурсов на FPGA и ASIC": "class",
    "Основы Vue.js": "class",
    "Работа с реактивностью в Vue.js: использование data, computed, и watch": "class",
    "Создание и использование компонентов для построения пользовательского интерфейса": "class",
    "Настройка маршрутизации с использованием Vue Router для создания многостраничных приложений": "class",
    "Использование Vuex для управления состоянием приложения в крупных проектах": "class",
    "Работа с событиями и директивами для динамического взаимодействия с DOM": "class",
    "Передача данных между компонентами с использованием props и событий": "class",
    "Создание и использование пользовательских директив для расширения функционала Vue.js": "class",
    "Работа с формами: двусторонняя привязка данных и валидация форм": "class",
    "Интеграция с внешними API с использованием Axios или Fetch для асинхронных запросов": "class",
    "Использование Vue CLI для быстрого создания и настройки проектов": "class",
    "Реализация анимаций и переходов в Vue.js для улучшения пользовательского опыта": "class",
    "Оптимизация производительности приложения с помощью lazy loading и динамических импортов": "class",
    "Разработка приложений с использованием серверной части с Vue.js (SSR) с Vue Server Renderer": "class",
    "Тестирование Vue.js приложений с использованием Jest и Vue Test Utils": "class",
    "Основы iOS-разработки": "class",
    "Изучение языка Swift для разработки приложений на iOS": "class",
    "Работа с интерфейсом пользователя с использованием UIKit: создание и настройка элементов UI (кнопки, метки, текстовые поля)": "class",
    "Использование Auto Layout для создания адаптивных и гибких интерфейсов": "class",
    "Работа с контроллерами представлений (ViewControllers) для управления переходами между экранами": "class",
    "Использование NavigationController и TabBarController для навигации между экранами": "class",
    "Работа с данными: использование UserDefaults, Core Data и SQLite для хранения данных в приложении": "class",
    "Интеграция с внешними API через сетевые запросы с использованием URLSession": "class",
    "Обработка событий пользователя: использование кнопок, жестов и других элементов управления": "class",
    "Создание анимаций и переходов в приложениях с использованием UIKit и Core Animation": "class",
    "Разработка приложений с использованием SwiftUI для создания современных интерфейсов": "class",
    "Интеграция с iOS-специфическими функциями: работа с камерами, сенсорами, GPS и уведомлениями": "class",
    "Тестирование и отладка iOS-приложений с использованием XCTest и Unit Testing": "class",
    "Оптимизация производительности приложений для iPhone и iPad": "class",
    "Развертывание и публикация приложений в App Store: создание сертификатов, профилей, и управление приложениями через App Store Connect": "class",
    "Основы веб-дизайна": "class",
    "Изучение UX/UI дизайна: создание удобных и интуитивно понятных интерфейсов": "class",
    "Проектирование и создание адаптивных веб-сайтов с использованием HTML и CSS": "class",
    "Работа с современными фреймворками для веб-дизайна: Bootstrap, Tailwind CSS": "class",
    "Создание макетов и прототипов веб-страниц с помощью инструментов типа Figma, Adobe XD или Sketch": "class",
    "Проектирование логотипов и фирменного стиля для веб-сайтов": "class",
    "Использование анимаций и эффектов для улучшения взаимодействия с пользователем": "class",
    "Оптимизация пользовательского интерфейса для мобильных устройств и различных экранов": "class",
    "Использование графических редакторов (Adobe Photoshop, Illustrator) для создания визуальных элементов": "class",
    "Разработка интерактивных элементов интерфейса с помощью JavaScript и jQuery": "class",
    "Создание и настройка типографики на сайте: выбор шрифтов, межстрочное расстояние, настройка контента": "class",
    "Проектирование структур и навигации сайта с учетом удобства пользователей (меню, хлебные крошки, фильтры)": "class",
    "Интеграция с социальными сетями и другими внешними сервисами": "class",
    "Оптимизация веб-сайтов для быстрого загрузки и SEO-оптимизация": "class",
    "Создание готовых веб-дизайнов для WordPress и других CMS (систем управления контентом)": "class",
    "Основы вёрстки веб-страниц": "class",
    "Изучение CSS: стилизация элементов, использование селекторов и свойств": "class",
    "Адаптивная вёрстка: создание страниц, которые подстраиваются под различные устройства и разрешения экрана": "class",
    "Использование медиазапросов для адаптации дизайна под мобильные устройства и десктопы": "class",
    "Работа с изображениями: настройка размеров, обтекание, использование форматов и оптимизация": "class",
    "Создание навигационных меню: горизонтальные и вертикальные меню, выпадающие списки": "class",
    "Проектирование форм: создание и стилизация форм для ввода данных с использованием HTML и CSS": "class",
    "Работа с типографикой: шрифты, межстрочное расстояние, выравнивание текста и настройка шрифтов с помощью Google Fonts": "class",
    "Интеграция и использование иконок (Font Awesome, SVG) в вёрстке": "class",
    "Использование анимаций и переходов с помощью CSS для улучшения пользовательского опыта": "class",
    "Оптимизация производительности: уменьшение веса файлов, использование спрайтов и сжатие изображений": "class",
    "Разработка сеток и сеточных систем (Grid Systems) для структурирования контента": "class",
    "Использование препроцессоров CSS (Sass, LESS) для упрощения работы с кодом и улучшения организации стилей": "class",
    "Реализация перекрытий, эффектов и других визуальных элементов с помощью CSS и HTML": "class",
    "Освоение основ нейросетей": "class",
    "Изучение математической основы нейросетей: линейная алгебра, градиентный спуск, активационные функции": "class",
    "Обучение простых нейросетей с использованием библиотек TensorFlow и PyTorch": "class",
    "Разработка и обучение многослойных перцептронов (MLP) для классификации и регрессии": "class",
    "Реализация сверточных нейросетей (CNN) для обработки изображений и компьютерного зрения": "class",
    "Изучение рекуррентных нейросетей (RNN) и их применение в обработке последовательностей данных": "class",
    "Использование LSTM и GRU для работы с длинными последовательностями и временными рядами": "class",
    "Обучение автоэнкодеров для задач сжатия данных, восстановления и генерации изображений": "class",
    "Применение нейросетей в обработке естественного языка (NLP): анализ текста, машинный перевод, классификация": "class",
    "Генеративные модели: использование GAN для создания новых данных, таких как изображения и музыка": "class",
    "Оптимизация нейросетей: методы регуляризации, снижение переобучения, использование dropout и batch normalization": "class",
    "Работа с большими данными: подготовка данных для обучения нейросетей, предобработка, нормализация": "class",
    "Модели transfer learning: использование предварительно обученных моделей для решения новых задач": "class",
    "Применение нейросетей в реальных задачах: распознавание лиц, автономные автомобили, медико-диагностические системы": "class",
    "Обучение нейросетей на GPU и распределённых вычислениях для ускорения процесса обучения на больших данных": "class",
    "Освоение основ мобильной разработки": "class",
    "Изучение языков программирования для мобильных приложений: Swift (iOS), Kotlin (Android), Dart (Flutter)": "class",
    "Создание простых приложений с использованием сред разработки Android Studio и Xcode": "class",
    "Разработка интерфейсов для мобильных приложений с использованием UIKit (iOS) или Android UI-компонентов (Android)": "class",
    "Изучение принципов адаптивного дизайна для разных экранов и разрешений устройств": "class",
    "Использование фреймворков для кроссплатформенной разработки: Flutter, React Native": "class",
    "Работа с базами данных и хранилищами на мобильных устройствах: SQLite, Realm, CoreData (iOS)": "class",
    "Интеграция с внешними API и веб-сервисами для обмена данными через HTTP запросы": "class",
    "Реализация систем авторизации и аутентификации пользователей (Firebase, OAuth)": "class",
    "Работа с мультимедиа: использование камеры, галереи, записи звука и видео в мобильных приложениях": "class",
    "Интеграция с геолокационными сервисами и картами (Google Maps, MapKit для iOS)": "class",
    "Обработка уведомлений: локальные и push-уведомления в мобильных приложениях": "class",
    "Тестирование мобильных приложений с использованием Unit Testing и UI Testing (XCTest, Espresso)": "class",
    "Оптимизация производительности мобильных приложений: уменьшение времени загрузки, управление памятью, улучшение отклика интерфейса": "class",
    "Публикация мобильных приложений в App Store и Google Play: настройка сертификатов, профилей, сборка и сдача приложений на проверку": "class",
    "Основы тестирования": "class",
    "Различие между типами тестирования: функциональное, нефункциональное, регрессионное, стрессовое и нагрузочное тестирование": "class",
    "Разработка тестовых планов, сценариев и кейсов для тестирования программных продуктов": "class",
    "Изучение различных типов тестирования: юнит-тестирование, интеграционное тестирование, системное тестирование, приемочное тестирование": "class",
    "Использование автоматизации тестирования с помощью инструментов: Selenium, Appium, JUnit, TestNG": "class",
    "Ручное тестирование: создание и выполнение тестов вручную для проверки функциональности и обнаружения ошибок": "class",
    "Работа с баг-трекинговыми системами (JIRA, Bugzilla, Redmine) для отслеживания дефектов и управления тестами": "class",
    "Тестирование веб-приложений: тестирование безопасности, совместимости и производительности веб-сайтов": "class",
    "Тестирование мобильных приложений: работа с мобильными эмуляторами и реальными устройствами для тестирования мобильных приложений": "class",
    "Тестирование производительности: нагрузочные и стресс-тесты, анализ отклика системы при больших нагрузках": "class",
    "Применение TDD (Test Driven Development) и BDD (Behavior Driven Development) подходов для тестирования и разработки": "class",
    "Тестирование безопасности: уязвимости, защита от SQL-инъекций, XSS и других атак": "class",
    "Изучение инструментов для нагрузочного тестирования, таких как Apache JMeter и LoadRunner": "class",
    "Интеграция тестов в процессы CI/CD: автоматизация тестирования в конвейере DevOps для непрерывной интеграции и доставки": "class",
    "Обучение методам тестирования API с использованием Postman, SoapUI или REST Assured": "class",
    "Изучение основ языка C": "class",
    "Освоение основ языка C: синтаксис, переменные, операторы, типы данных и структуры": "class",
    "Изучение работы с указателями: адреса, указатели на функции, арифметика указателей": "class",
    "Массивы и строки в C: работа с одномерными и многомерными массивами, строками и их манипуляция": "class",
    "Реализация структур данных: списки, стеки, очереди, деревья и другие структуры данных": "class",
    "Функции и рекурсия: создание функций, передача параметров, рекурсивные функции": "class",
    "Управление памятью: выделение и освобождение памяти с использованием malloc, free": "class",
    "Обработка ошибок: использование стандартных библиотек для обработки ошибок и исключений в C": "class",
    "Работа с файлами: чтение и запись данных в файлы, бинарные и текстовые файлы": "class",
    "Использование стандартных библиотек: работа с математическими, строковыми и другими стандартными функциями C": "class",
    "Оптимизация кода: использование эффективных алгоритмов, минимизация времени работы программы": "class",
    "Разработка программного обеспечения низкого уровня: взаимодействие с операционной системой и аппаратными средствами": "class",
    "Параллельное программирование: основы многозадачности и использование потоков в C": "class",
    "Интерфейсы и библиотеки: создание динамических и статических библиотек": "class",
    "Взаимодействие с ассемблером: внедрение ассемблерных вставок в код на C": "class",
    "Разработка и отладка программ: использование средств отладки, таких как GDB, для поиска ошибок в коде": "class",
    "Основы FoxPro": "class",
    "Создание и работа с базами данных: создание таблиц, индексов и ключей": "class",
    "Запросы SQL в FoxPro: выборка данных, фильтрация, сортировка и агрегация": "class",
    "Работа с формами: создание интерфейсов для ввода и отображения данных": "class",
    "Создание и обработка отчетов в FoxPro: настройка отчетных форматов и вывод данных": "class",
    "Использование макросов для автоматизации задач и улучшения работы с данными": "class",
    "Работа с массивами и индексами: обработка больших объемов данных": "class",
    "Обработка событий и программирование в стиле событий для создания интерактивных приложений": "class",
    "Создание и использование функций и процедур для повторного использования кода": "class",
    "Обработка ошибок и исключений с помощью команд TRY, CATCH и обработки ошибок": "class",
    "Работа с внешними приложениями и интеграция с другими базами данных и сервисами": "class",
    "Оптимизация запросов и работы с базами данных для повышения производительности": "class",
    "Использование команд для импорта и экспорта данных в различные форматы (CSV, Excel, текстовые файлы)": "class",
    "Создание и управление пользовательскими интерфейсами с использованием объектов и формы": "class",
    "Реализация безопасности данных и управление правами доступа в FoxPro": "class",
    "Изучение основ языка R": "class",
    "Работа с векторами, списками, матрицами и дата-фреймами для обработки данных": "class",
    "Изучение базовых функций для статистической обработки данных (среднее, медиана, стандартное отклонение и т. д.)": "class",
    "Загрузка, очистка и преобразование данных с использованием пакетов dplyr и tidyr": "class",
    "Визуализация данных: создание графиков с использованием ggplot2 и других библиотек": "class",
    "Построение и анализ линейных регрессионных моделей": "class",
    "Применение методов машинного обучения: классификация, регрессия, кластеризация": "class",
    "Работа с временными рядами: анализ, моделирование и прогнозирование данных": "class",
    "Проведение гипотезных тестов и анализ статистической значимости данных": "class",
    "Использование пакетов для биоинформатики и анализа генетических данных (например, Bioconductor)": "class",
    "Реализация методов оптимизации и линейного программирования с использованием R": "class",
    "Моделирование данных и применение методов многомерного анализа (PCA, факторный анализ и т. д.)": "class",
    "Разработка собственных функций и пакетов для упрощения повторяющихся задач": "class",
    "Работа с большими данными и использование R для обработки больших наборов данных (например, с использованием пакетов data.table)": "class",
    "Интеграция R с другими языками программирования и платформами, такими как Python, SQL, Hadoop и Spark": "class",
};

export default aimsTypes;
