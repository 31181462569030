// source: internal/pb/tutor-catalog.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require("google-protobuf");

var goog = jspb;

var global = Function("return this")();

goog.exportSymbol("proto.pb.Action", null, global);
goog.exportSymbol("proto.pb.Age", null, global);
goog.exportSymbol("proto.pb.AvailableTime", null, global);
goog.exportSymbol("proto.pb.AvailableWeekDayTimetable", null, global);
goog.exportSymbol("proto.pb.CardTutor", null, global);
goog.exportSymbol("proto.pb.CountTutorsRequest", null, global);
goog.exportSymbol("proto.pb.CountTutorsResponse", null, global);
goog.exportSymbol("proto.pb.Empty", null, global);
goog.exportSymbol("proto.pb.FavouritesRequest", null, global);
goog.exportSymbol("proto.pb.FavouritesResponse", null, global);
goog.exportSymbol("proto.pb.MetaData", null, global);
goog.exportSymbol("proto.pb.ModifyFavouritesRequest", null, global);
goog.exportSymbol("proto.pb.ModifyFavouritesResponse", null, global);
goog.exportSymbol("proto.pb.OtherSimilarTutorsRequest", null, global);
goog.exportSymbol("proto.pb.OtherSimilarTutorsResponse", null, global);
goog.exportSymbol("proto.pb.Paging", null, global);
goog.exportSymbol("proto.pb.Photo", null, global);
goog.exportSymbol("proto.pb.PlacesStudy", null, global);
goog.exportSymbol("proto.pb.Price", null, global);
goog.exportSymbol("proto.pb.Rates", null, global);
goog.exportSymbol("proto.pb.SearchParameters", null, global);
goog.exportSymbol("proto.pb.SearchTutorsRequest", null, global);
goog.exportSymbol("proto.pb.SearchTutorsResponse", null, global);
goog.exportSymbol("proto.pb.SmallCardTutor", null, global);
goog.exportSymbol("proto.pb.StatusTutor", null, global);
goog.exportSymbol("proto.pb.TimeSlot", null, global);
goog.exportSymbol("proto.pb.Timetable", null, global);
goog.exportSymbol("proto.pb.University", null, global);
goog.exportSymbol("proto.pb.Video", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.SearchTutorsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.SearchTutorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.SearchTutorsRequest.displayName = "proto.pb.SearchTutorsRequest";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.SearchParameters = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.SearchParameters.repeatedFields_, null);
};

goog.inherits(proto.pb.SearchParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.SearchParameters.displayName = "proto.pb.SearchParameters";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.PlacesStudy = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.PlacesStudy.repeatedFields_, null);
};

goog.inherits(proto.pb.PlacesStudy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.PlacesStudy.displayName = "proto.pb.PlacesStudy";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Age = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Age, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Age.displayName = "proto.pb.Age";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Price = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Price, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Price.displayName = "proto.pb.Price";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.University = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.University, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.University.displayName = "proto.pb.University";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.SearchTutorsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.SearchTutorsResponse.repeatedFields_, null);
};

goog.inherits(proto.pb.SearchTutorsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.SearchTutorsResponse.displayName = "proto.pb.SearchTutorsResponse";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.CardTutor = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.CardTutor.repeatedFields_, null);
};

goog.inherits(proto.pb.CardTutor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.CardTutor.displayName = "proto.pb.CardTutor";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.OtherSimilarTutorsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.OtherSimilarTutorsRequest.repeatedFields_, null);
};

goog.inherits(proto.pb.OtherSimilarTutorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.OtherSimilarTutorsRequest.displayName = "proto.pb.OtherSimilarTutorsRequest";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.OtherSimilarTutorsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.OtherSimilarTutorsResponse.repeatedFields_, null);
};

goog.inherits(proto.pb.OtherSimilarTutorsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.OtherSimilarTutorsResponse.displayName = "proto.pb.OtherSimilarTutorsResponse";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.SmallCardTutor = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.SmallCardTutor.repeatedFields_, null);
};

goog.inherits(proto.pb.SmallCardTutor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.SmallCardTutor.displayName = "proto.pb.SmallCardTutor";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Rates = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Rates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Rates.displayName = "proto.pb.Rates";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.FavouritesRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.FavouritesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.FavouritesRequest.displayName = "proto.pb.FavouritesRequest";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.FavouritesResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.FavouritesResponse.repeatedFields_, null);
};

goog.inherits(proto.pb.FavouritesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.FavouritesResponse.displayName = "proto.pb.FavouritesResponse";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.ModifyFavouritesRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.ModifyFavouritesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.ModifyFavouritesRequest.displayName = "proto.pb.ModifyFavouritesRequest";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.ModifyFavouritesResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.ModifyFavouritesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.ModifyFavouritesResponse.displayName = "proto.pb.ModifyFavouritesResponse";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.CountTutorsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.CountTutorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.CountTutorsRequest.displayName = "proto.pb.CountTutorsRequest";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.CountTutorsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.CountTutorsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.CountTutorsResponse.displayName = "proto.pb.CountTutorsResponse";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.AvailableWeekDayTimetable = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.AvailableWeekDayTimetable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.AvailableWeekDayTimetable.displayName = "proto.pb.AvailableWeekDayTimetable";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Timetable = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Timetable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Timetable.displayName = "proto.pb.Timetable";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.AvailableTime = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.AvailableTime.repeatedFields_, null);
};

goog.inherits(proto.pb.AvailableTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.AvailableTime.displayName = "proto.pb.AvailableTime";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.TimeSlot = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.TimeSlot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.TimeSlot.displayName = "proto.pb.TimeSlot";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.StatusTutor = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.StatusTutor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.StatusTutor.displayName = "proto.pb.StatusTutor";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.MetaData = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.MetaData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.MetaData.displayName = "proto.pb.MetaData";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Paging = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Paging, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Paging.displayName = "proto.pb.Paging";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Photo = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Photo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Photo.displayName = "proto.pb.Photo";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Video = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Video, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Video.displayName = "proto.pb.Video";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Empty = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Empty.displayName = "proto.pb.Empty";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.SearchTutorsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.SearchTutorsRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.SearchTutorsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.SearchTutorsRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                metaData: (f = msg.getMetaData()) && proto.pb.MetaData.toObject(includeInstance, f),
                body: (f = msg.getBody()) && proto.pb.SearchParameters.toObject(includeInstance, f),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.SearchTutorsRequest}
 */
proto.pb.SearchTutorsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.SearchTutorsRequest();

    return proto.pb.SearchTutorsRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.SearchTutorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.SearchTutorsRequest}
 */
proto.pb.SearchTutorsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = new proto.pb.MetaData();

                reader.readMessage(value, proto.pb.MetaData.deserializeBinaryFromReader);
                msg.setMetaData(value);
                break;
            case 2:
                var value = new proto.pb.SearchParameters();

                reader.readMessage(value, proto.pb.SearchParameters.deserializeBinaryFromReader);
                msg.setBody(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.SearchTutorsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.SearchTutorsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.SearchTutorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.SearchTutorsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getMetaData();
    if (f != null) {
        writer.writeMessage(1, f, proto.pb.MetaData.serializeBinaryToWriter);
    }

    f = message.getBody();
    if (f != null) {
        writer.writeMessage(2, f, proto.pb.SearchParameters.serializeBinaryToWriter);
    }
};

/**
 * optional MetaData meta_data = 1;
 * @return {?proto.pb.MetaData}
 */
proto.pb.SearchTutorsRequest.prototype.getMetaData = function () {
    return /** @type{?proto.pb.MetaData} */ (jspb.Message.getWrapperField(this, proto.pb.MetaData, 1));
};

/**
 * @param {?proto.pb.MetaData|undefined} value
 * @return {!proto.pb.SearchTutorsRequest} returns this
 */
proto.pb.SearchTutorsRequest.prototype.setMetaData = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.SearchTutorsRequest} returns this
 */
proto.pb.SearchTutorsRequest.prototype.clearMetaData = function () {
    return this.setMetaData(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.SearchTutorsRequest.prototype.hasMetaData = function () {
    return jspb.Message.getField(this, 1) != null;
};

/**
 * optional SearchParameters body = 2;
 * @return {?proto.pb.SearchParameters}
 */
proto.pb.SearchTutorsRequest.prototype.getBody = function () {
    return /** @type{?proto.pb.SearchParameters} */ (jspb.Message.getWrapperField(this, proto.pb.SearchParameters, 2));
};

/**
 * @param {?proto.pb.SearchParameters|undefined} value
 * @return {!proto.pb.SearchTutorsRequest} returns this
 */
proto.pb.SearchTutorsRequest.prototype.setBody = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.SearchTutorsRequest} returns this
 */
proto.pb.SearchTutorsRequest.prototype.clearBody = function () {
    return this.setBody(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.SearchTutorsRequest.prototype.hasBody = function () {
    return jspb.Message.getField(this, 2) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.SearchParameters.repeatedFields_ = [6];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.SearchParameters.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.SearchParameters.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.SearchParameters} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.SearchParameters.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                subject: jspb.Message.getFieldWithDefault(msg, 1, ""),
                price: (f = msg.getPrice()) && proto.pb.Price.toObject(includeInstance, f),
                audience: jspb.Message.getFieldWithDefault(msg, 3, ""),
                purpose: jspb.Message.getFieldWithDefault(msg, 4, ""),
                placesStudy: (f = msg.getPlacesStudy()) && proto.pb.PlacesStudy.toObject(includeInstance, f),
                classFormatsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
                gender: jspb.Message.getFieldWithDefault(msg, 7, ""),
                age: (f = msg.getAge()) && proto.pb.Age.toObject(includeInstance, f),
                university: (f = msg.getUniversity()) && proto.pb.University.toObject(includeInstance, f),
                timetable: (f = msg.getTimetable()) && proto.pb.Timetable.toObject(includeInstance, f),
                sort: jspb.Message.getFieldWithDefault(msg, 11, ""),
                cashback: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
                keywords: jspb.Message.getFieldWithDefault(msg, 13, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.SearchParameters}
 */
proto.pb.SearchParameters.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.SearchParameters();

    return proto.pb.SearchParameters.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.SearchParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.SearchParameters}
 */
proto.pb.SearchParameters.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setSubject(value);
                break;
            case 2:
                var value = new proto.pb.Price();

                reader.readMessage(value, proto.pb.Price.deserializeBinaryFromReader);
                msg.setPrice(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());

                msg.setAudience(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());

                msg.setPurpose(value);
                break;
            case 5:
                var value = new proto.pb.PlacesStudy();

                reader.readMessage(value, proto.pb.PlacesStudy.deserializeBinaryFromReader);
                msg.setPlacesStudy(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());

                msg.addClassFormats(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());

                msg.setGender(value);
                break;
            case 8:
                var value = new proto.pb.Age();

                reader.readMessage(value, proto.pb.Age.deserializeBinaryFromReader);
                msg.setAge(value);
                break;
            case 9:
                var value = new proto.pb.University();

                reader.readMessage(value, proto.pb.University.deserializeBinaryFromReader);
                msg.setUniversity(value);
                break;
            case 10:
                var value = new proto.pb.Timetable();

                reader.readMessage(value, proto.pb.Timetable.deserializeBinaryFromReader);
                msg.setTimetable(value);
                break;
            case 11:
                var value = /** @type {string} */ (reader.readString());

                msg.setSort(value);
                break;
            case 12:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setCashback(value);
                break;
            case 13:
                var value = /** @type {string} */ (reader.readString());

                msg.setKeywords(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.SearchParameters.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.SearchParameters.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.SearchParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.SearchParameters.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getSubject();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getPrice();
    if (f != null) {
        writer.writeMessage(2, f, proto.pb.Price.serializeBinaryToWriter);
    }

    f = message.getAudience();
    if (f.length > 0) {
        writer.writeString(3, f);
    }

    f = message.getPurpose();
    if (f.length > 0) {
        writer.writeString(4, f);
    }

    f = message.getPlacesStudy();
    if (f != null) {
        writer.writeMessage(5, f, proto.pb.PlacesStudy.serializeBinaryToWriter);
    }

    f = message.getClassFormatsList();
    if (f.length > 0) {
        writer.writeRepeatedString(6, f);
    }

    f = message.getGender();
    if (f.length > 0) {
        writer.writeString(7, f);
    }

    f = message.getAge();
    if (f != null) {
        writer.writeMessage(8, f, proto.pb.Age.serializeBinaryToWriter);
    }

    f = message.getUniversity();
    if (f != null) {
        writer.writeMessage(9, f, proto.pb.University.serializeBinaryToWriter);
    }

    f = message.getTimetable();
    if (f != null) {
        writer.writeMessage(10, f, proto.pb.Timetable.serializeBinaryToWriter);
    }

    f = message.getSort();
    if (f.length > 0) {
        writer.writeString(11, f);
    }

    f = message.getCashback();
    if (f) {
        writer.writeBool(12, f);
    }

    f = message.getKeywords();
    if (f.length > 0) {
        writer.writeString(13, f);
    }
};

/**
 * optional string subject = 1;
 * @return {string}
 */
proto.pb.SearchParameters.prototype.getSubject = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.setSubject = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional Price price = 2;
 * @return {?proto.pb.Price}
 */
proto.pb.SearchParameters.prototype.getPrice = function () {
    return /** @type{?proto.pb.Price} */ (jspb.Message.getWrapperField(this, proto.pb.Price, 2));
};

/**
 * @param {?proto.pb.Price|undefined} value
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.setPrice = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.clearPrice = function () {
    return this.setPrice(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.SearchParameters.prototype.hasPrice = function () {
    return jspb.Message.getField(this, 2) != null;
};

/**
 * optional string audience = 3;
 * @return {string}
 */
proto.pb.SearchParameters.prototype.getAudience = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.setAudience = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string purpose = 4;
 * @return {string}
 */
proto.pb.SearchParameters.prototype.getPurpose = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.setPurpose = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional PlacesStudy places_study = 5;
 * @return {?proto.pb.PlacesStudy}
 */
proto.pb.SearchParameters.prototype.getPlacesStudy = function () {
    return /** @type{?proto.pb.PlacesStudy} */ (jspb.Message.getWrapperField(this, proto.pb.PlacesStudy, 5));
};

/**
 * @param {?proto.pb.PlacesStudy|undefined} value
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.setPlacesStudy = function (value) {
    return jspb.Message.setWrapperField(this, 5, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.clearPlacesStudy = function () {
    return this.setPlacesStudy(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.SearchParameters.prototype.hasPlacesStudy = function () {
    return jspb.Message.getField(this, 5) != null;
};

/**
 * repeated string class_formats = 6;
 * @return {!Array<string>}
 */
proto.pb.SearchParameters.prototype.getClassFormatsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.setClassFormatsList = function (value) {
    return jspb.Message.setField(this, 6, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.addClassFormats = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.clearClassFormatsList = function () {
    return this.setClassFormatsList([]);
};

/**
 * optional string gender = 7;
 * @return {string}
 */
proto.pb.SearchParameters.prototype.getGender = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.setGender = function (value) {
    return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional Age age = 8;
 * @return {?proto.pb.Age}
 */
proto.pb.SearchParameters.prototype.getAge = function () {
    return /** @type{?proto.pb.Age} */ (jspb.Message.getWrapperField(this, proto.pb.Age, 8));
};

/**
 * @param {?proto.pb.Age|undefined} value
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.setAge = function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.clearAge = function () {
    return this.setAge(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.SearchParameters.prototype.hasAge = function () {
    return jspb.Message.getField(this, 8) != null;
};

/**
 * optional University university = 9;
 * @return {?proto.pb.University}
 */
proto.pb.SearchParameters.prototype.getUniversity = function () {
    return /** @type{?proto.pb.University} */ (jspb.Message.getWrapperField(this, proto.pb.University, 9));
};

/**
 * @param {?proto.pb.University|undefined} value
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.setUniversity = function (value) {
    return jspb.Message.setWrapperField(this, 9, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.clearUniversity = function () {
    return this.setUniversity(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.SearchParameters.prototype.hasUniversity = function () {
    return jspb.Message.getField(this, 9) != null;
};

/**
 * optional Timetable timetable = 10;
 * @return {?proto.pb.Timetable}
 */
proto.pb.SearchParameters.prototype.getTimetable = function () {
    return /** @type{?proto.pb.Timetable} */ (jspb.Message.getWrapperField(this, proto.pb.Timetable, 10));
};

/**
 * @param {?proto.pb.Timetable|undefined} value
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.setTimetable = function (value) {
    return jspb.Message.setWrapperField(this, 10, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.clearTimetable = function () {
    return this.setTimetable(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.SearchParameters.prototype.hasTimetable = function () {
    return jspb.Message.getField(this, 10) != null;
};

/**
 * optional string sort = 11;
 * @return {string}
 */
proto.pb.SearchParameters.prototype.getSort = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.setSort = function (value) {
    return jspb.Message.setProto3StringField(this, 11, value);
};

/**
 * optional bool cashback = 12;
 * @return {boolean}
 */
proto.pb.SearchParameters.prototype.getCashback = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.setCashback = function (value) {
    return jspb.Message.setProto3BooleanField(this, 12, value);
};

/**
 * optional string keywords = 13;
 * @return {string}
 */
proto.pb.SearchParameters.prototype.getKeywords = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.SearchParameters} returns this
 */
proto.pb.SearchParameters.prototype.setKeywords = function (value) {
    return jspb.Message.setProto3StringField(this, 13, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.PlacesStudy.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.PlacesStudy.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.PlacesStudy.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.PlacesStudy} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.PlacesStudy.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                city: jspb.Message.getFieldWithDefault(msg, 1, ""),
                locationsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.PlacesStudy}
 */
proto.pb.PlacesStudy.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.PlacesStudy();

    return proto.pb.PlacesStudy.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.PlacesStudy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.PlacesStudy}
 */
proto.pb.PlacesStudy.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setCity(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.addLocations(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.PlacesStudy.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.PlacesStudy.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.PlacesStudy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.PlacesStudy.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getCity();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getLocationsList();
    if (f.length > 0) {
        writer.writeRepeatedString(2, f);
    }
};

/**
 * optional string city = 1;
 * @return {string}
 */
proto.pb.PlacesStudy.prototype.getCity = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.PlacesStudy} returns this
 */
proto.pb.PlacesStudy.prototype.setCity = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * repeated string locations = 2;
 * @return {!Array<string>}
 */
proto.pb.PlacesStudy.prototype.getLocationsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pb.PlacesStudy} returns this
 */
proto.pb.PlacesStudy.prototype.setLocationsList = function (value) {
    return jspb.Message.setField(this, 2, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.PlacesStudy} returns this
 */
proto.pb.PlacesStudy.prototype.addLocations = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.PlacesStudy} returns this
 */
proto.pb.PlacesStudy.prototype.clearLocationsList = function () {
    return this.setLocationsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Age.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Age.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Age} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Age.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                min: jspb.Message.getFieldWithDefault(msg, 1, 0),
                max: jspb.Message.getFieldWithDefault(msg, 2, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Age}
 */
proto.pb.Age.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Age();

    return proto.pb.Age.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Age} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Age}
 */
proto.pb.Age.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setMin(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setMax(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Age.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Age.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Age} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Age.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getMin();
    if (f !== 0) {
        writer.writeInt32(1, f);
    }

    f = message.getMax();
    if (f !== 0) {
        writer.writeInt32(2, f);
    }
};

/**
 * optional int32 min = 1;
 * @return {number}
 */
proto.pb.Age.prototype.getMin = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.Age} returns this
 */
proto.pb.Age.prototype.setMin = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 max = 2;
 * @return {number}
 */
proto.pb.Age.prototype.getMax = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.Age} returns this
 */
proto.pb.Age.prototype.setMax = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Price.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Price.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Price} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Price.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                min: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
                max: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Price}
 */
proto.pb.Price.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Price();

    return proto.pb.Price.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Price} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Price}
 */
proto.pb.Price.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readDouble());

                msg.setMin(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readDouble());

                msg.setMax(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Price.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Price.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Price} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Price.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getMin();
    if (f !== 0.0) {
        writer.writeDouble(1, f);
    }

    f = message.getMax();
    if (f !== 0.0) {
        writer.writeDouble(2, f);
    }
};

/**
 * optional double min = 1;
 * @return {number}
 */
proto.pb.Price.prototype.getMin = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};

/**
 * @param {number} value
 * @return {!proto.pb.Price} returns this
 */
proto.pb.Price.prototype.setMin = function (value) {
    return jspb.Message.setProto3FloatField(this, 1, value);
};

/**
 * optional double max = 2;
 * @return {number}
 */
proto.pb.Price.prototype.getMax = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};

/**
 * @param {number} value
 * @return {!proto.pb.Price} returns this
 */
proto.pb.Price.prototype.setMax = function (value) {
    return jspb.Message.setProto3FloatField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.University.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.University.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.University} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.University.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                name: jspb.Message.getFieldWithDefault(msg, 1, ""),
                onlyUniversity: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.University}
 */
proto.pb.University.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.University();

    return proto.pb.University.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.University} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.University}
 */
proto.pb.University.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setName(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setOnlyUniversity(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.University.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.University.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.University} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.University.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getName();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getOnlyUniversity();
    if (f) {
        writer.writeBool(2, f);
    }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.pb.University.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.University} returns this
 */
proto.pb.University.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional bool only_university = 2;
 * @return {boolean}
 */
proto.pb.University.prototype.getOnlyUniversity = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.University} returns this
 */
proto.pb.University.prototype.setOnlyUniversity = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.SearchTutorsResponse.repeatedFields_ = [4];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.SearchTutorsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.SearchTutorsResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.SearchTutorsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.SearchTutorsResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                status: jspb.Message.getFieldWithDefault(msg, 1, ""),
                paging: (f = msg.getPaging()) && proto.pb.Paging.toObject(includeInstance, f),
                metaData: (f = msg.getMetaData()) && proto.pb.MetaData.toObject(includeInstance, f),
                dataList: jspb.Message.toObjectList(msg.getDataList(), proto.pb.CardTutor.toObject, includeInstance),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.SearchTutorsResponse}
 */
proto.pb.SearchTutorsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.SearchTutorsResponse();

    return proto.pb.SearchTutorsResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.SearchTutorsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.SearchTutorsResponse}
 */
proto.pb.SearchTutorsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setStatus(value);
                break;
            case 2:
                var value = new proto.pb.Paging();

                reader.readMessage(value, proto.pb.Paging.deserializeBinaryFromReader);
                msg.setPaging(value);
                break;
            case 3:
                var value = new proto.pb.MetaData();

                reader.readMessage(value, proto.pb.MetaData.deserializeBinaryFromReader);
                msg.setMetaData(value);
                break;
            case 4:
                var value = new proto.pb.CardTutor();

                reader.readMessage(value, proto.pb.CardTutor.deserializeBinaryFromReader);
                msg.addData(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.SearchTutorsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.SearchTutorsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.SearchTutorsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.SearchTutorsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getStatus();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getPaging();
    if (f != null) {
        writer.writeMessage(2, f, proto.pb.Paging.serializeBinaryToWriter);
    }

    f = message.getMetaData();
    if (f != null) {
        writer.writeMessage(3, f, proto.pb.MetaData.serializeBinaryToWriter);
    }

    f = message.getDataList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(4, f, proto.pb.CardTutor.serializeBinaryToWriter);
    }
};

/**
 * optional string status = 1;
 * @return {string}
 */
proto.pb.SearchTutorsResponse.prototype.getStatus = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.SearchTutorsResponse} returns this
 */
proto.pb.SearchTutorsResponse.prototype.setStatus = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional Paging paging = 2;
 * @return {?proto.pb.Paging}
 */
proto.pb.SearchTutorsResponse.prototype.getPaging = function () {
    return /** @type{?proto.pb.Paging} */ (jspb.Message.getWrapperField(this, proto.pb.Paging, 2));
};

/**
 * @param {?proto.pb.Paging|undefined} value
 * @return {!proto.pb.SearchTutorsResponse} returns this
 */
proto.pb.SearchTutorsResponse.prototype.setPaging = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.SearchTutorsResponse} returns this
 */
proto.pb.SearchTutorsResponse.prototype.clearPaging = function () {
    return this.setPaging(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.SearchTutorsResponse.prototype.hasPaging = function () {
    return jspb.Message.getField(this, 2) != null;
};

/**
 * optional MetaData meta_data = 3;
 * @return {?proto.pb.MetaData}
 */
proto.pb.SearchTutorsResponse.prototype.getMetaData = function () {
    return /** @type{?proto.pb.MetaData} */ (jspb.Message.getWrapperField(this, proto.pb.MetaData, 3));
};

/**
 * @param {?proto.pb.MetaData|undefined} value
 * @return {!proto.pb.SearchTutorsResponse} returns this
 */
proto.pb.SearchTutorsResponse.prototype.setMetaData = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.SearchTutorsResponse} returns this
 */
proto.pb.SearchTutorsResponse.prototype.clearMetaData = function () {
    return this.setMetaData(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.SearchTutorsResponse.prototype.hasMetaData = function () {
    return jspb.Message.getField(this, 3) != null;
};

/**
 * repeated CardTutor data = 4;
 * @return {!Array<!proto.pb.CardTutor>}
 */
proto.pb.SearchTutorsResponse.prototype.getDataList = function () {
    return /** @type{!Array<!proto.pb.CardTutor>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.CardTutor, 4));
};

/**
 * @param {!Array<!proto.pb.CardTutor>} value
 * @return {!proto.pb.SearchTutorsResponse} returns this
 */
proto.pb.SearchTutorsResponse.prototype.setDataList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 4, value);
};

/**
 * @param {!proto.pb.CardTutor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.CardTutor}
 */
proto.pb.SearchTutorsResponse.prototype.addData = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.pb.CardTutor, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.SearchTutorsResponse} returns this
 */
proto.pb.SearchTutorsResponse.prototype.clearDataList = function () {
    return this.setDataList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.CardTutor.repeatedFields_ = [9, 11, 12, 14, 16, 19];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.CardTutor.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.CardTutor.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.CardTutor} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.CardTutor.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                cardUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                tutorUuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
                name: jspb.Message.getFieldWithDefault(msg, 3, ""),
                surname: jspb.Message.getFieldWithDefault(msg, 4, ""),
                patronymic: jspb.Message.getFieldWithDefault(msg, 5, ""),
                photo: (f = msg.getPhoto()) && proto.pb.Photo.toObject(includeInstance, f),
                video: (f = msg.getVideo()) && proto.pb.Video.toObject(includeInstance, f),
                description: jspb.Message.getFieldWithDefault(msg, 8, ""),
                subjectList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
                price: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
                locationsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
                classFormatsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
                age: jspb.Message.getFieldWithDefault(msg, 13, 0),
                educationsList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
                cashback: jspb.Message.getFieldWithDefault(msg, 15, 0),
                availableWeekDayTimetableList: jspb.Message.toObjectList(msg.getAvailableWeekDayTimetableList(), proto.pb.AvailableWeekDayTimetable.toObject, includeInstance),
                status: (f = msg.getStatus()) && proto.pb.StatusTutor.toObject(includeInstance, f),
                rates: (f = msg.getRates()) && proto.pb.Rates.toObject(includeInstance, f),
                tagsList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
                experience: jspb.Message.getFieldWithDefault(msg, 20, 0),
                isAnonymous: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
                isVerified: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
                isFavourite: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.CardTutor}
 */
proto.pb.CardTutor.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.CardTutor();

    return proto.pb.CardTutor.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.CardTutor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.CardTutor}
 */
proto.pb.CardTutor.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setCardUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setTutorUuid(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());

                msg.setName(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());

                msg.setSurname(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());

                msg.setPatronymic(value);
                break;
            case 6:
                var value = new proto.pb.Photo();

                reader.readMessage(value, proto.pb.Photo.deserializeBinaryFromReader);
                msg.setPhoto(value);
                break;
            case 7:
                var value = new proto.pb.Video();

                reader.readMessage(value, proto.pb.Video.deserializeBinaryFromReader);
                msg.setVideo(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());

                msg.setDescription(value);
                break;
            case 9:
                var value = /** @type {string} */ (reader.readString());

                msg.addSubject(value);
                break;
            case 10:
                var value = /** @type {number} */ (reader.readDouble());

                msg.setPrice(value);
                break;
            case 11:
                var value = /** @type {string} */ (reader.readString());

                msg.addLocations(value);
                break;
            case 12:
                var value = /** @type {string} */ (reader.readString());

                msg.addClassFormats(value);
                break;
            case 13:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setAge(value);
                break;
            case 14:
                var value = /** @type {string} */ (reader.readString());

                msg.addEducations(value);
                break;
            case 15:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setCashback(value);
                break;
            case 16:
                var value = new proto.pb.AvailableWeekDayTimetable();

                reader.readMessage(value, proto.pb.AvailableWeekDayTimetable.deserializeBinaryFromReader);
                msg.addAvailableWeekDayTimetable(value);
                break;
            case 17:
                var value = new proto.pb.StatusTutor();

                reader.readMessage(value, proto.pb.StatusTutor.deserializeBinaryFromReader);
                msg.setStatus(value);
                break;
            case 18:
                var value = new proto.pb.Rates();

                reader.readMessage(value, proto.pb.Rates.deserializeBinaryFromReader);
                msg.setRates(value);
                break;
            case 19:
                var value = /** @type {string} */ (reader.readString());

                msg.addTags(value);
                break;
            case 20:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setExperience(value);
                break;
            case 21:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setIsAnonymous(value);
                break;
            case 22:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setIsVerified(value);
                break;
            case 23:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setIsFavourite(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.CardTutor.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.CardTutor.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.CardTutor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.CardTutor.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getCardUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getTutorUuid();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getName();
    if (f.length > 0) {
        writer.writeString(3, f);
    }

    f = message.getSurname();
    if (f.length > 0) {
        writer.writeString(4, f);
    }

    f = message.getPatronymic();
    if (f.length > 0) {
        writer.writeString(5, f);
    }

    f = message.getPhoto();
    if (f != null) {
        writer.writeMessage(6, f, proto.pb.Photo.serializeBinaryToWriter);
    }

    f = message.getVideo();
    if (f != null) {
        writer.writeMessage(7, f, proto.pb.Video.serializeBinaryToWriter);
    }

    f = message.getDescription();
    if (f.length > 0) {
        writer.writeString(8, f);
    }

    f = message.getSubjectList();
    if (f.length > 0) {
        writer.writeRepeatedString(9, f);
    }

    f = message.getPrice();
    if (f !== 0.0) {
        writer.writeDouble(10, f);
    }

    f = message.getLocationsList();
    if (f.length > 0) {
        writer.writeRepeatedString(11, f);
    }

    f = message.getClassFormatsList();
    if (f.length > 0) {
        writer.writeRepeatedString(12, f);
    }

    f = message.getAge();
    if (f !== 0) {
        writer.writeInt32(13, f);
    }

    f = message.getEducationsList();
    if (f.length > 0) {
        writer.writeRepeatedString(14, f);
    }

    f = message.getCashback();
    if (f !== 0) {
        writer.writeInt32(15, f);
    }

    f = message.getAvailableWeekDayTimetableList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(16, f, proto.pb.AvailableWeekDayTimetable.serializeBinaryToWriter);
    }

    f = message.getStatus();
    if (f != null) {
        writer.writeMessage(17, f, proto.pb.StatusTutor.serializeBinaryToWriter);
    }

    f = message.getRates();
    if (f != null) {
        writer.writeMessage(18, f, proto.pb.Rates.serializeBinaryToWriter);
    }

    f = message.getTagsList();
    if (f.length > 0) {
        writer.writeRepeatedString(19, f);
    }

    f = message.getExperience();
    if (f !== 0) {
        writer.writeInt32(20, f);
    }

    f = message.getIsAnonymous();
    if (f) {
        writer.writeBool(21, f);
    }

    f = message.getIsVerified();
    if (f) {
        writer.writeBool(22, f);
    }

    f = message.getIsFavourite();
    if (f) {
        writer.writeBool(23, f);
    }
};

/**
 * optional string card_uuid = 1;
 * @return {string}
 */
proto.pb.CardTutor.prototype.getCardUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setCardUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string tutor_uuid = 2;
 * @return {string}
 */
proto.pb.CardTutor.prototype.getTutorUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setTutorUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string name = 3;
 * @return {string}
 */
proto.pb.CardTutor.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string surname = 4;
 * @return {string}
 */
proto.pb.CardTutor.prototype.getSurname = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setSurname = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string patronymic = 5;
 * @return {string}
 */
proto.pb.CardTutor.prototype.getPatronymic = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setPatronymic = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional Photo photo = 6;
 * @return {?proto.pb.Photo}
 */
proto.pb.CardTutor.prototype.getPhoto = function () {
    return /** @type{?proto.pb.Photo} */ (jspb.Message.getWrapperField(this, proto.pb.Photo, 6));
};

/**
 * @param {?proto.pb.Photo|undefined} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setPhoto = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.clearPhoto = function () {
    return this.setPhoto(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.CardTutor.prototype.hasPhoto = function () {
    return jspb.Message.getField(this, 6) != null;
};

/**
 * optional Video video = 7;
 * @return {?proto.pb.Video}
 */
proto.pb.CardTutor.prototype.getVideo = function () {
    return /** @type{?proto.pb.Video} */ (jspb.Message.getWrapperField(this, proto.pb.Video, 7));
};

/**
 * @param {?proto.pb.Video|undefined} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setVideo = function (value) {
    return jspb.Message.setWrapperField(this, 7, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.clearVideo = function () {
    return this.setVideo(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.CardTutor.prototype.hasVideo = function () {
    return jspb.Message.getField(this, 7) != null;
};

/**
 * optional string description = 8;
 * @return {string}
 */
proto.pb.CardTutor.prototype.getDescription = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setDescription = function (value) {
    return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * repeated string subject = 9;
 * @return {!Array<string>}
 */
proto.pb.CardTutor.prototype.getSubjectList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setSubjectList = function (value) {
    return jspb.Message.setField(this, 9, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.addSubject = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.clearSubjectList = function () {
    return this.setSubjectList([]);
};

/**
 * optional double price = 10;
 * @return {number}
 */
proto.pb.CardTutor.prototype.getPrice = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};

/**
 * @param {number} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setPrice = function (value) {
    return jspb.Message.setProto3FloatField(this, 10, value);
};

/**
 * repeated string locations = 11;
 * @return {!Array<string>}
 */
proto.pb.CardTutor.prototype.getLocationsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setLocationsList = function (value) {
    return jspb.Message.setField(this, 11, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.addLocations = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.clearLocationsList = function () {
    return this.setLocationsList([]);
};

/**
 * repeated string class_formats = 12;
 * @return {!Array<string>}
 */
proto.pb.CardTutor.prototype.getClassFormatsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setClassFormatsList = function (value) {
    return jspb.Message.setField(this, 12, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.addClassFormats = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.clearClassFormatsList = function () {
    return this.setClassFormatsList([]);
};

/**
 * optional int32 age = 13;
 * @return {number}
 */
proto.pb.CardTutor.prototype.getAge = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setAge = function (value) {
    return jspb.Message.setProto3IntField(this, 13, value);
};

/**
 * repeated string educations = 14;
 * @return {!Array<string>}
 */
proto.pb.CardTutor.prototype.getEducationsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setEducationsList = function (value) {
    return jspb.Message.setField(this, 14, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.addEducations = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.clearEducationsList = function () {
    return this.setEducationsList([]);
};

/**
 * optional int32 cashback = 15;
 * @return {number}
 */
proto.pb.CardTutor.prototype.getCashback = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setCashback = function (value) {
    return jspb.Message.setProto3IntField(this, 15, value);
};

/**
 * repeated AvailableWeekDayTimetable available_week_day_timetable = 16;
 * @return {!Array<!proto.pb.AvailableWeekDayTimetable>}
 */
proto.pb.CardTutor.prototype.getAvailableWeekDayTimetableList = function () {
    return /** @type{!Array<!proto.pb.AvailableWeekDayTimetable>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.AvailableWeekDayTimetable, 16));
};

/**
 * @param {!Array<!proto.pb.AvailableWeekDayTimetable>} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setAvailableWeekDayTimetableList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 16, value);
};

/**
 * @param {!proto.pb.AvailableWeekDayTimetable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.AvailableWeekDayTimetable}
 */
proto.pb.CardTutor.prototype.addAvailableWeekDayTimetable = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.pb.AvailableWeekDayTimetable, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.clearAvailableWeekDayTimetableList = function () {
    return this.setAvailableWeekDayTimetableList([]);
};

/**
 * optional StatusTutor status = 17;
 * @return {?proto.pb.StatusTutor}
 */
proto.pb.CardTutor.prototype.getStatus = function () {
    return /** @type{?proto.pb.StatusTutor} */ (jspb.Message.getWrapperField(this, proto.pb.StatusTutor, 17));
};

/**
 * @param {?proto.pb.StatusTutor|undefined} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setStatus = function (value) {
    return jspb.Message.setWrapperField(this, 17, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.clearStatus = function () {
    return this.setStatus(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.CardTutor.prototype.hasStatus = function () {
    return jspb.Message.getField(this, 17) != null;
};

/**
 * optional Rates rates = 18;
 * @return {?proto.pb.Rates}
 */
proto.pb.CardTutor.prototype.getRates = function () {
    return /** @type{?proto.pb.Rates} */ (jspb.Message.getWrapperField(this, proto.pb.Rates, 18));
};

/**
 * @param {?proto.pb.Rates|undefined} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setRates = function (value) {
    return jspb.Message.setWrapperField(this, 18, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.clearRates = function () {
    return this.setRates(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.CardTutor.prototype.hasRates = function () {
    return jspb.Message.getField(this, 18) != null;
};

/**
 * repeated string tags = 19;
 * @return {!Array<string>}
 */
proto.pb.CardTutor.prototype.getTagsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 19));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setTagsList = function (value) {
    return jspb.Message.setField(this, 19, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.addTags = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.clearTagsList = function () {
    return this.setTagsList([]);
};

/**
 * optional int32 experience = 20;
 * @return {number}
 */
proto.pb.CardTutor.prototype.getExperience = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setExperience = function (value) {
    return jspb.Message.setProto3IntField(this, 20, value);
};

/**
 * optional bool is_anonymous = 21;
 * @return {boolean}
 */
proto.pb.CardTutor.prototype.getIsAnonymous = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setIsAnonymous = function (value) {
    return jspb.Message.setProto3BooleanField(this, 21, value);
};

/**
 * optional bool is_verified = 22;
 * @return {boolean}
 */
proto.pb.CardTutor.prototype.getIsVerified = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setIsVerified = function (value) {
    return jspb.Message.setProto3BooleanField(this, 22, value);
};

/**
 * optional bool is_favourite = 23;
 * @return {boolean}
 */
proto.pb.CardTutor.prototype.getIsFavourite = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.CardTutor} returns this
 */
proto.pb.CardTutor.prototype.setIsFavourite = function (value) {
    return jspb.Message.setProto3BooleanField(this, 23, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.OtherSimilarTutorsRequest.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.OtherSimilarTutorsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.OtherSimilarTutorsRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.OtherSimilarTutorsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.OtherSimilarTutorsRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                tagsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
                subject: jspb.Message.getFieldWithDefault(msg, 2, ""),
                tutorUuid: jspb.Message.getFieldWithDefault(msg, 3, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.OtherSimilarTutorsRequest}
 */
proto.pb.OtherSimilarTutorsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.OtherSimilarTutorsRequest();

    return proto.pb.OtherSimilarTutorsRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.OtherSimilarTutorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.OtherSimilarTutorsRequest}
 */
proto.pb.OtherSimilarTutorsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.addTags(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setSubject(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());

                msg.setTutorUuid(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.OtherSimilarTutorsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.OtherSimilarTutorsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.OtherSimilarTutorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OtherSimilarTutorsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getTagsList();
    if (f.length > 0) {
        writer.writeRepeatedString(1, f);
    }

    f = message.getSubject();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getTutorUuid();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};

/**
 * repeated string tags = 1;
 * @return {!Array<string>}
 */
proto.pb.OtherSimilarTutorsRequest.prototype.getTagsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pb.OtherSimilarTutorsRequest} returns this
 */
proto.pb.OtherSimilarTutorsRequest.prototype.setTagsList = function (value) {
    return jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.OtherSimilarTutorsRequest} returns this
 */
proto.pb.OtherSimilarTutorsRequest.prototype.addTags = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.OtherSimilarTutorsRequest} returns this
 */
proto.pb.OtherSimilarTutorsRequest.prototype.clearTagsList = function () {
    return this.setTagsList([]);
};

/**
 * optional string subject = 2;
 * @return {string}
 */
proto.pb.OtherSimilarTutorsRequest.prototype.getSubject = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.OtherSimilarTutorsRequest} returns this
 */
proto.pb.OtherSimilarTutorsRequest.prototype.setSubject = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string tutor_uuid = 3;
 * @return {string}
 */
proto.pb.OtherSimilarTutorsRequest.prototype.getTutorUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.OtherSimilarTutorsRequest} returns this
 */
proto.pb.OtherSimilarTutorsRequest.prototype.setTutorUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.OtherSimilarTutorsResponse.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.OtherSimilarTutorsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.OtherSimilarTutorsResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.OtherSimilarTutorsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.OtherSimilarTutorsResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                status: jspb.Message.getFieldWithDefault(msg, 1, ""),
                dataList: jspb.Message.toObjectList(msg.getDataList(), proto.pb.SmallCardTutor.toObject, includeInstance),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.OtherSimilarTutorsResponse}
 */
proto.pb.OtherSimilarTutorsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.OtherSimilarTutorsResponse();

    return proto.pb.OtherSimilarTutorsResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.OtherSimilarTutorsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.OtherSimilarTutorsResponse}
 */
proto.pb.OtherSimilarTutorsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setStatus(value);
                break;
            case 2:
                var value = new proto.pb.SmallCardTutor();

                reader.readMessage(value, proto.pb.SmallCardTutor.deserializeBinaryFromReader);
                msg.addData(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.OtherSimilarTutorsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.OtherSimilarTutorsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.OtherSimilarTutorsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.OtherSimilarTutorsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getStatus();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getDataList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(2, f, proto.pb.SmallCardTutor.serializeBinaryToWriter);
    }
};

/**
 * optional string status = 1;
 * @return {string}
 */
proto.pb.OtherSimilarTutorsResponse.prototype.getStatus = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.OtherSimilarTutorsResponse} returns this
 */
proto.pb.OtherSimilarTutorsResponse.prototype.setStatus = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * repeated SmallCardTutor data = 2;
 * @return {!Array<!proto.pb.SmallCardTutor>}
 */
proto.pb.OtherSimilarTutorsResponse.prototype.getDataList = function () {
    return /** @type{!Array<!proto.pb.SmallCardTutor>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.SmallCardTutor, 2));
};

/**
 * @param {!Array<!proto.pb.SmallCardTutor>} value
 * @return {!proto.pb.OtherSimilarTutorsResponse} returns this
 */
proto.pb.OtherSimilarTutorsResponse.prototype.setDataList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.pb.SmallCardTutor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.SmallCardTutor}
 */
proto.pb.OtherSimilarTutorsResponse.prototype.addData = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pb.SmallCardTutor, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.OtherSimilarTutorsResponse} returns this
 */
proto.pb.OtherSimilarTutorsResponse.prototype.clearDataList = function () {
    return this.setDataList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.SmallCardTutor.repeatedFields_ = [8, 11];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.SmallCardTutor.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.SmallCardTutor.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.SmallCardTutor} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.SmallCardTutor.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                cardUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                tutorUuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
                name: jspb.Message.getFieldWithDefault(msg, 3, ""),
                surname: jspb.Message.getFieldWithDefault(msg, 4, ""),
                patronymic: jspb.Message.getFieldWithDefault(msg, 5, ""),
                photo: (f = msg.getPhoto()) && proto.pb.Photo.toObject(includeInstance, f),
                rates: (f = msg.getRates()) && proto.pb.Rates.toObject(includeInstance, f),
                tagsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
                price: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
                cashback: jspb.Message.getFieldWithDefault(msg, 10, 0),
                subjectsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
                isAnonymous: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
                isVerified: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.SmallCardTutor}
 */
proto.pb.SmallCardTutor.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.SmallCardTutor();

    return proto.pb.SmallCardTutor.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.SmallCardTutor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.SmallCardTutor}
 */
proto.pb.SmallCardTutor.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setCardUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setTutorUuid(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());

                msg.setName(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());

                msg.setSurname(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());

                msg.setPatronymic(value);
                break;
            case 6:
                var value = new proto.pb.Photo();

                reader.readMessage(value, proto.pb.Photo.deserializeBinaryFromReader);
                msg.setPhoto(value);
                break;
            case 7:
                var value = new proto.pb.Rates();

                reader.readMessage(value, proto.pb.Rates.deserializeBinaryFromReader);
                msg.setRates(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());

                msg.addTags(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readDouble());

                msg.setPrice(value);
                break;
            case 10:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setCashback(value);
                break;
            case 11:
                var value = /** @type {string} */ (reader.readString());

                msg.addSubjects(value);
                break;
            case 12:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setIsAnonymous(value);
                break;
            case 13:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setIsVerified(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.SmallCardTutor.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.SmallCardTutor.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.SmallCardTutor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.SmallCardTutor.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getCardUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getTutorUuid();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getName();
    if (f.length > 0) {
        writer.writeString(3, f);
    }

    f = message.getSurname();
    if (f.length > 0) {
        writer.writeString(4, f);
    }

    f = message.getPatronymic();
    if (f.length > 0) {
        writer.writeString(5, f);
    }

    f = message.getPhoto();
    if (f != null) {
        writer.writeMessage(6, f, proto.pb.Photo.serializeBinaryToWriter);
    }

    f = message.getRates();
    if (f != null) {
        writer.writeMessage(7, f, proto.pb.Rates.serializeBinaryToWriter);
    }

    f = message.getTagsList();
    if (f.length > 0) {
        writer.writeRepeatedString(8, f);
    }

    f = message.getPrice();
    if (f !== 0.0) {
        writer.writeDouble(9, f);
    }

    f = message.getCashback();
    if (f !== 0) {
        writer.writeInt32(10, f);
    }

    f = message.getSubjectsList();
    if (f.length > 0) {
        writer.writeRepeatedString(11, f);
    }

    f = message.getIsAnonymous();
    if (f) {
        writer.writeBool(12, f);
    }

    f = message.getIsVerified();
    if (f) {
        writer.writeBool(13, f);
    }
};

/**
 * optional string card_uuid = 1;
 * @return {string}
 */
proto.pb.SmallCardTutor.prototype.getCardUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.SmallCardTutor} returns this
 */
proto.pb.SmallCardTutor.prototype.setCardUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string tutor_uuid = 2;
 * @return {string}
 */
proto.pb.SmallCardTutor.prototype.getTutorUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.SmallCardTutor} returns this
 */
proto.pb.SmallCardTutor.prototype.setTutorUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string name = 3;
 * @return {string}
 */
proto.pb.SmallCardTutor.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.SmallCardTutor} returns this
 */
proto.pb.SmallCardTutor.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string surname = 4;
 * @return {string}
 */
proto.pb.SmallCardTutor.prototype.getSurname = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.SmallCardTutor} returns this
 */
proto.pb.SmallCardTutor.prototype.setSurname = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string patronymic = 5;
 * @return {string}
 */
proto.pb.SmallCardTutor.prototype.getPatronymic = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.SmallCardTutor} returns this
 */
proto.pb.SmallCardTutor.prototype.setPatronymic = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional Photo photo = 6;
 * @return {?proto.pb.Photo}
 */
proto.pb.SmallCardTutor.prototype.getPhoto = function () {
    return /** @type{?proto.pb.Photo} */ (jspb.Message.getWrapperField(this, proto.pb.Photo, 6));
};

/**
 * @param {?proto.pb.Photo|undefined} value
 * @return {!proto.pb.SmallCardTutor} returns this
 */
proto.pb.SmallCardTutor.prototype.setPhoto = function (value) {
    return jspb.Message.setWrapperField(this, 6, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.SmallCardTutor} returns this
 */
proto.pb.SmallCardTutor.prototype.clearPhoto = function () {
    return this.setPhoto(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.SmallCardTutor.prototype.hasPhoto = function () {
    return jspb.Message.getField(this, 6) != null;
};

/**
 * optional Rates rates = 7;
 * @return {?proto.pb.Rates}
 */
proto.pb.SmallCardTutor.prototype.getRates = function () {
    return /** @type{?proto.pb.Rates} */ (jspb.Message.getWrapperField(this, proto.pb.Rates, 7));
};

/**
 * @param {?proto.pb.Rates|undefined} value
 * @return {!proto.pb.SmallCardTutor} returns this
 */
proto.pb.SmallCardTutor.prototype.setRates = function (value) {
    return jspb.Message.setWrapperField(this, 7, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.SmallCardTutor} returns this
 */
proto.pb.SmallCardTutor.prototype.clearRates = function () {
    return this.setRates(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.SmallCardTutor.prototype.hasRates = function () {
    return jspb.Message.getField(this, 7) != null;
};

/**
 * repeated string tags = 8;
 * @return {!Array<string>}
 */
proto.pb.SmallCardTutor.prototype.getTagsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pb.SmallCardTutor} returns this
 */
proto.pb.SmallCardTutor.prototype.setTagsList = function (value) {
    return jspb.Message.setField(this, 8, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.SmallCardTutor} returns this
 */
proto.pb.SmallCardTutor.prototype.addTags = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.SmallCardTutor} returns this
 */
proto.pb.SmallCardTutor.prototype.clearTagsList = function () {
    return this.setTagsList([]);
};

/**
 * optional double price = 9;
 * @return {number}
 */
proto.pb.SmallCardTutor.prototype.getPrice = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};

/**
 * @param {number} value
 * @return {!proto.pb.SmallCardTutor} returns this
 */
proto.pb.SmallCardTutor.prototype.setPrice = function (value) {
    return jspb.Message.setProto3FloatField(this, 9, value);
};

/**
 * optional int32 cashback = 10;
 * @return {number}
 */
proto.pb.SmallCardTutor.prototype.getCashback = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.SmallCardTutor} returns this
 */
proto.pb.SmallCardTutor.prototype.setCashback = function (value) {
    return jspb.Message.setProto3IntField(this, 10, value);
};

/**
 * repeated string subjects = 11;
 * @return {!Array<string>}
 */
proto.pb.SmallCardTutor.prototype.getSubjectsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pb.SmallCardTutor} returns this
 */
proto.pb.SmallCardTutor.prototype.setSubjectsList = function (value) {
    return jspb.Message.setField(this, 11, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.SmallCardTutor} returns this
 */
proto.pb.SmallCardTutor.prototype.addSubjects = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.SmallCardTutor} returns this
 */
proto.pb.SmallCardTutor.prototype.clearSubjectsList = function () {
    return this.setSubjectsList([]);
};

/**
 * optional bool is_anonymous = 12;
 * @return {boolean}
 */
proto.pb.SmallCardTutor.prototype.getIsAnonymous = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.SmallCardTutor} returns this
 */
proto.pb.SmallCardTutor.prototype.setIsAnonymous = function (value) {
    return jspb.Message.setProto3BooleanField(this, 12, value);
};

/**
 * optional bool is_verified = 13;
 * @return {boolean}
 */
proto.pb.SmallCardTutor.prototype.getIsVerified = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.SmallCardTutor} returns this
 */
proto.pb.SmallCardTutor.prototype.setIsVerified = function (value) {
    return jspb.Message.setProto3BooleanField(this, 13, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Rates.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Rates.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Rates} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Rates.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                average: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
                total: jspb.Message.getFieldWithDefault(msg, 2, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Rates}
 */
proto.pb.Rates.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Rates();

    return proto.pb.Rates.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Rates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Rates}
 */
proto.pb.Rates.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readDouble());

                msg.setAverage(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setTotal(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Rates.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Rates.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Rates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Rates.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getAverage();
    if (f !== 0.0) {
        writer.writeDouble(1, f);
    }

    f = message.getTotal();
    if (f !== 0) {
        writer.writeInt32(2, f);
    }
};

/**
 * optional double average = 1;
 * @return {number}
 */
proto.pb.Rates.prototype.getAverage = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};

/**
 * @param {number} value
 * @return {!proto.pb.Rates} returns this
 */
proto.pb.Rates.prototype.setAverage = function (value) {
    return jspb.Message.setProto3FloatField(this, 1, value);
};

/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.pb.Rates.prototype.getTotal = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.Rates} returns this
 */
proto.pb.Rates.prototype.setTotal = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.FavouritesRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.FavouritesRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.FavouritesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.FavouritesRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                metaData: (f = msg.getMetaData()) && proto.pb.MetaData.toObject(includeInstance, f),
                subject: jspb.Message.getFieldWithDefault(msg, 2, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.FavouritesRequest}
 */
proto.pb.FavouritesRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.FavouritesRequest();

    return proto.pb.FavouritesRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.FavouritesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.FavouritesRequest}
 */
proto.pb.FavouritesRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = new proto.pb.MetaData();

                reader.readMessage(value, proto.pb.MetaData.deserializeBinaryFromReader);
                msg.setMetaData(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setSubject(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.FavouritesRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.FavouritesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.FavouritesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.FavouritesRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getMetaData();
    if (f != null) {
        writer.writeMessage(1, f, proto.pb.MetaData.serializeBinaryToWriter);
    }

    f = message.getSubject();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};

/**
 * optional MetaData meta_data = 1;
 * @return {?proto.pb.MetaData}
 */
proto.pb.FavouritesRequest.prototype.getMetaData = function () {
    return /** @type{?proto.pb.MetaData} */ (jspb.Message.getWrapperField(this, proto.pb.MetaData, 1));
};

/**
 * @param {?proto.pb.MetaData|undefined} value
 * @return {!proto.pb.FavouritesRequest} returns this
 */
proto.pb.FavouritesRequest.prototype.setMetaData = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.FavouritesRequest} returns this
 */
proto.pb.FavouritesRequest.prototype.clearMetaData = function () {
    return this.setMetaData(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.FavouritesRequest.prototype.hasMetaData = function () {
    return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string subject = 2;
 * @return {string}
 */
proto.pb.FavouritesRequest.prototype.getSubject = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.FavouritesRequest} returns this
 */
proto.pb.FavouritesRequest.prototype.setSubject = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.FavouritesResponse.repeatedFields_ = [4, 5];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.FavouritesResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.FavouritesResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.FavouritesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.FavouritesResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                status: jspb.Message.getFieldWithDefault(msg, 1, ""),
                paging: (f = msg.getPaging()) && proto.pb.Paging.toObject(includeInstance, f),
                metaData: (f = msg.getMetaData()) && proto.pb.MetaData.toObject(includeInstance, f),
                dataList: jspb.Message.toObjectList(msg.getDataList(), proto.pb.CardTutor.toObject, includeInstance),
                subjectTagsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.FavouritesResponse}
 */
proto.pb.FavouritesResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.FavouritesResponse();

    return proto.pb.FavouritesResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.FavouritesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.FavouritesResponse}
 */
proto.pb.FavouritesResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setStatus(value);
                break;
            case 2:
                var value = new proto.pb.Paging();

                reader.readMessage(value, proto.pb.Paging.deserializeBinaryFromReader);
                msg.setPaging(value);
                break;
            case 3:
                var value = new proto.pb.MetaData();

                reader.readMessage(value, proto.pb.MetaData.deserializeBinaryFromReader);
                msg.setMetaData(value);
                break;
            case 4:
                var value = new proto.pb.CardTutor();

                reader.readMessage(value, proto.pb.CardTutor.deserializeBinaryFromReader);
                msg.addData(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());

                msg.addSubjectTags(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.FavouritesResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.FavouritesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.FavouritesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.FavouritesResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getStatus();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getPaging();
    if (f != null) {
        writer.writeMessage(2, f, proto.pb.Paging.serializeBinaryToWriter);
    }

    f = message.getMetaData();
    if (f != null) {
        writer.writeMessage(3, f, proto.pb.MetaData.serializeBinaryToWriter);
    }

    f = message.getDataList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(4, f, proto.pb.CardTutor.serializeBinaryToWriter);
    }

    f = message.getSubjectTagsList();
    if (f.length > 0) {
        writer.writeRepeatedString(5, f);
    }
};

/**
 * optional string status = 1;
 * @return {string}
 */
proto.pb.FavouritesResponse.prototype.getStatus = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.FavouritesResponse} returns this
 */
proto.pb.FavouritesResponse.prototype.setStatus = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional Paging paging = 2;
 * @return {?proto.pb.Paging}
 */
proto.pb.FavouritesResponse.prototype.getPaging = function () {
    return /** @type{?proto.pb.Paging} */ (jspb.Message.getWrapperField(this, proto.pb.Paging, 2));
};

/**
 * @param {?proto.pb.Paging|undefined} value
 * @return {!proto.pb.FavouritesResponse} returns this
 */
proto.pb.FavouritesResponse.prototype.setPaging = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.FavouritesResponse} returns this
 */
proto.pb.FavouritesResponse.prototype.clearPaging = function () {
    return this.setPaging(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.FavouritesResponse.prototype.hasPaging = function () {
    return jspb.Message.getField(this, 2) != null;
};

/**
 * optional MetaData meta_data = 3;
 * @return {?proto.pb.MetaData}
 */
proto.pb.FavouritesResponse.prototype.getMetaData = function () {
    return /** @type{?proto.pb.MetaData} */ (jspb.Message.getWrapperField(this, proto.pb.MetaData, 3));
};

/**
 * @param {?proto.pb.MetaData|undefined} value
 * @return {!proto.pb.FavouritesResponse} returns this
 */
proto.pb.FavouritesResponse.prototype.setMetaData = function (value) {
    return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.FavouritesResponse} returns this
 */
proto.pb.FavouritesResponse.prototype.clearMetaData = function () {
    return this.setMetaData(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.FavouritesResponse.prototype.hasMetaData = function () {
    return jspb.Message.getField(this, 3) != null;
};

/**
 * repeated CardTutor data = 4;
 * @return {!Array<!proto.pb.CardTutor>}
 */
proto.pb.FavouritesResponse.prototype.getDataList = function () {
    return /** @type{!Array<!proto.pb.CardTutor>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.CardTutor, 4));
};

/**
 * @param {!Array<!proto.pb.CardTutor>} value
 * @return {!proto.pb.FavouritesResponse} returns this
 */
proto.pb.FavouritesResponse.prototype.setDataList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 4, value);
};

/**
 * @param {!proto.pb.CardTutor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.CardTutor}
 */
proto.pb.FavouritesResponse.prototype.addData = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.pb.CardTutor, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.FavouritesResponse} returns this
 */
proto.pb.FavouritesResponse.prototype.clearDataList = function () {
    return this.setDataList([]);
};

/**
 * repeated string subject_tags = 5;
 * @return {!Array<string>}
 */
proto.pb.FavouritesResponse.prototype.getSubjectTagsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pb.FavouritesResponse} returns this
 */
proto.pb.FavouritesResponse.prototype.setSubjectTagsList = function (value) {
    return jspb.Message.setField(this, 5, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.FavouritesResponse} returns this
 */
proto.pb.FavouritesResponse.prototype.addSubjectTags = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.FavouritesResponse} returns this
 */
proto.pb.FavouritesResponse.prototype.clearSubjectTagsList = function () {
    return this.setSubjectTagsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.ModifyFavouritesRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.ModifyFavouritesRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.ModifyFavouritesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.ModifyFavouritesRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                tutorUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                action: jspb.Message.getFieldWithDefault(msg, 2, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.ModifyFavouritesRequest}
 */
proto.pb.ModifyFavouritesRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.ModifyFavouritesRequest();

    return proto.pb.ModifyFavouritesRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.ModifyFavouritesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.ModifyFavouritesRequest}
 */
proto.pb.ModifyFavouritesRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setTutorUuid(value);
                break;
            case 2:
                var value = /** @type {!proto.pb.Action} */ (reader.readEnum());

                msg.setAction(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.ModifyFavouritesRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.ModifyFavouritesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.ModifyFavouritesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.ModifyFavouritesRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getTutorUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getAction();
    if (f !== 0.0) {
        writer.writeEnum(2, f);
    }
};

/**
 * optional string tutor_uuid = 1;
 * @return {string}
 */
proto.pb.ModifyFavouritesRequest.prototype.getTutorUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.ModifyFavouritesRequest} returns this
 */
proto.pb.ModifyFavouritesRequest.prototype.setTutorUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional Action action = 2;
 * @return {!proto.pb.Action}
 */
proto.pb.ModifyFavouritesRequest.prototype.getAction = function () {
    return /** @type {!proto.pb.Action} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {!proto.pb.Action} value
 * @return {!proto.pb.ModifyFavouritesRequest} returns this
 */
proto.pb.ModifyFavouritesRequest.prototype.setAction = function (value) {
    return jspb.Message.setProto3EnumField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.ModifyFavouritesResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.ModifyFavouritesResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.ModifyFavouritesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.ModifyFavouritesResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                status: jspb.Message.getFieldWithDefault(msg, 1, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.ModifyFavouritesResponse}
 */
proto.pb.ModifyFavouritesResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.ModifyFavouritesResponse();

    return proto.pb.ModifyFavouritesResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.ModifyFavouritesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.ModifyFavouritesResponse}
 */
proto.pb.ModifyFavouritesResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setStatus(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.ModifyFavouritesResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.ModifyFavouritesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.ModifyFavouritesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.ModifyFavouritesResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getStatus();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};

/**
 * optional string status = 1;
 * @return {string}
 */
proto.pb.ModifyFavouritesResponse.prototype.getStatus = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.ModifyFavouritesResponse} returns this
 */
proto.pb.ModifyFavouritesResponse.prototype.setStatus = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.CountTutorsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.CountTutorsRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.CountTutorsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.CountTutorsRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                body: (f = msg.getBody()) && proto.pb.SearchParameters.toObject(includeInstance, f),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.CountTutorsRequest}
 */
proto.pb.CountTutorsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.CountTutorsRequest();

    return proto.pb.CountTutorsRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.CountTutorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.CountTutorsRequest}
 */
proto.pb.CountTutorsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = new proto.pb.SearchParameters();

                reader.readMessage(value, proto.pb.SearchParameters.deserializeBinaryFromReader);
                msg.setBody(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.CountTutorsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.CountTutorsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.CountTutorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.CountTutorsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getBody();
    if (f != null) {
        writer.writeMessage(1, f, proto.pb.SearchParameters.serializeBinaryToWriter);
    }
};

/**
 * optional SearchParameters body = 1;
 * @return {?proto.pb.SearchParameters}
 */
proto.pb.CountTutorsRequest.prototype.getBody = function () {
    return /** @type{?proto.pb.SearchParameters} */ (jspb.Message.getWrapperField(this, proto.pb.SearchParameters, 1));
};

/**
 * @param {?proto.pb.SearchParameters|undefined} value
 * @return {!proto.pb.CountTutorsRequest} returns this
 */
proto.pb.CountTutorsRequest.prototype.setBody = function (value) {
    return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.CountTutorsRequest} returns this
 */
proto.pb.CountTutorsRequest.prototype.clearBody = function () {
    return this.setBody(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.CountTutorsRequest.prototype.hasBody = function () {
    return jspb.Message.getField(this, 1) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.CountTutorsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.CountTutorsResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.CountTutorsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.CountTutorsResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                status: jspb.Message.getFieldWithDefault(msg, 1, ""),
                count: jspb.Message.getFieldWithDefault(msg, 2, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.CountTutorsResponse}
 */
proto.pb.CountTutorsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.CountTutorsResponse();

    return proto.pb.CountTutorsResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.CountTutorsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.CountTutorsResponse}
 */
proto.pb.CountTutorsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setStatus(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt64());

                msg.setCount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.CountTutorsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.CountTutorsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.CountTutorsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.CountTutorsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getStatus();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getCount();
    if (f !== 0) {
        writer.writeInt64(2, f);
    }
};

/**
 * optional string status = 1;
 * @return {string}
 */
proto.pb.CountTutorsResponse.prototype.getStatus = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CountTutorsResponse} returns this
 */
proto.pb.CountTutorsResponse.prototype.setStatus = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.pb.CountTutorsResponse.prototype.getCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.CountTutorsResponse} returns this
 */
proto.pb.CountTutorsResponse.prototype.setCount = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.AvailableWeekDayTimetable.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.AvailableWeekDayTimetable.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.AvailableWeekDayTimetable} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.AvailableWeekDayTimetable.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                day: jspb.Message.getFieldWithDefault(msg, 1, ""),
                timetable: (f = msg.getTimetable()) && proto.pb.Timetable.toObject(includeInstance, f),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.AvailableWeekDayTimetable}
 */
proto.pb.AvailableWeekDayTimetable.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.AvailableWeekDayTimetable();

    return proto.pb.AvailableWeekDayTimetable.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.AvailableWeekDayTimetable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.AvailableWeekDayTimetable}
 */
proto.pb.AvailableWeekDayTimetable.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setDay(value);
                break;
            case 2:
                var value = new proto.pb.Timetable();

                reader.readMessage(value, proto.pb.Timetable.deserializeBinaryFromReader);
                msg.setTimetable(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.AvailableWeekDayTimetable.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.AvailableWeekDayTimetable.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.AvailableWeekDayTimetable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.AvailableWeekDayTimetable.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getDay();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getTimetable();
    if (f != null) {
        writer.writeMessage(2, f, proto.pb.Timetable.serializeBinaryToWriter);
    }
};

/**
 * optional string day = 1;
 * @return {string}
 */
proto.pb.AvailableWeekDayTimetable.prototype.getDay = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.AvailableWeekDayTimetable} returns this
 */
proto.pb.AvailableWeekDayTimetable.prototype.setDay = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional Timetable timetable = 2;
 * @return {?proto.pb.Timetable}
 */
proto.pb.AvailableWeekDayTimetable.prototype.getTimetable = function () {
    return /** @type{?proto.pb.Timetable} */ (jspb.Message.getWrapperField(this, proto.pb.Timetable, 2));
};

/**
 * @param {?proto.pb.Timetable|undefined} value
 * @return {!proto.pb.AvailableWeekDayTimetable} returns this
 */
proto.pb.AvailableWeekDayTimetable.prototype.setTimetable = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.AvailableWeekDayTimetable} returns this
 */
proto.pb.AvailableWeekDayTimetable.prototype.clearTimetable = function () {
    return this.setTimetable(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.AvailableWeekDayTimetable.prototype.hasTimetable = function () {
    return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Timetable.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Timetable.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Timetable} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Timetable.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                morning: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
                afternoon: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
                evening: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
                night: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Timetable}
 */
proto.pb.Timetable.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Timetable();

    return proto.pb.Timetable.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Timetable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Timetable}
 */
proto.pb.Timetable.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setMorning(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setAfternoon(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setEvening(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setNight(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Timetable.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Timetable.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Timetable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Timetable.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getMorning();
    if (f) {
        writer.writeBool(1, f);
    }

    f = message.getAfternoon();
    if (f) {
        writer.writeBool(2, f);
    }

    f = message.getEvening();
    if (f) {
        writer.writeBool(3, f);
    }

    f = message.getNight();
    if (f) {
        writer.writeBool(4, f);
    }
};

/**
 * optional bool morning = 1;
 * @return {boolean}
 */
proto.pb.Timetable.prototype.getMorning = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.Timetable} returns this
 */
proto.pb.Timetable.prototype.setMorning = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional bool afternoon = 2;
 * @return {boolean}
 */
proto.pb.Timetable.prototype.getAfternoon = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.Timetable} returns this
 */
proto.pb.Timetable.prototype.setAfternoon = function (value) {
    return jspb.Message.setProto3BooleanField(this, 2, value);
};

/**
 * optional bool evening = 3;
 * @return {boolean}
 */
proto.pb.Timetable.prototype.getEvening = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.Timetable} returns this
 */
proto.pb.Timetable.prototype.setEvening = function (value) {
    return jspb.Message.setProto3BooleanField(this, 3, value);
};

/**
 * optional bool night = 4;
 * @return {boolean}
 */
proto.pb.Timetable.prototype.getNight = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.Timetable} returns this
 */
proto.pb.Timetable.prototype.setNight = function (value) {
    return jspb.Message.setProto3BooleanField(this, 4, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.AvailableTime.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.AvailableTime.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.AvailableTime.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.AvailableTime} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.AvailableTime.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                dayOfWeek: jspb.Message.getFieldWithDefault(msg, 1, ""),
                timeSlotsList: jspb.Message.toObjectList(msg.getTimeSlotsList(), proto.pb.TimeSlot.toObject, includeInstance),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.AvailableTime}
 */
proto.pb.AvailableTime.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.AvailableTime();

    return proto.pb.AvailableTime.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.AvailableTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.AvailableTime}
 */
proto.pb.AvailableTime.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setDayOfWeek(value);
                break;
            case 2:
                var value = new proto.pb.TimeSlot();

                reader.readMessage(value, proto.pb.TimeSlot.deserializeBinaryFromReader);
                msg.addTimeSlots(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.AvailableTime.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.AvailableTime.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.AvailableTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.AvailableTime.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getDayOfWeek();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getTimeSlotsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(2, f, proto.pb.TimeSlot.serializeBinaryToWriter);
    }
};

/**
 * optional string day_of_week = 1;
 * @return {string}
 */
proto.pb.AvailableTime.prototype.getDayOfWeek = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.AvailableTime} returns this
 */
proto.pb.AvailableTime.prototype.setDayOfWeek = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * repeated TimeSlot time_slots = 2;
 * @return {!Array<!proto.pb.TimeSlot>}
 */
proto.pb.AvailableTime.prototype.getTimeSlotsList = function () {
    return /** @type{!Array<!proto.pb.TimeSlot>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.TimeSlot, 2));
};

/**
 * @param {!Array<!proto.pb.TimeSlot>} value
 * @return {!proto.pb.AvailableTime} returns this
 */
proto.pb.AvailableTime.prototype.setTimeSlotsList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.pb.TimeSlot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.TimeSlot}
 */
proto.pb.AvailableTime.prototype.addTimeSlots = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pb.TimeSlot, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.AvailableTime} returns this
 */
proto.pb.AvailableTime.prototype.clearTimeSlotsList = function () {
    return this.setTimeSlotsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.TimeSlot.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.TimeSlot.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.TimeSlot} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.TimeSlot.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                start: jspb.Message.getFieldWithDefault(msg, 1, ""),
                end: jspb.Message.getFieldWithDefault(msg, 2, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.TimeSlot}
 */
proto.pb.TimeSlot.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.TimeSlot();

    return proto.pb.TimeSlot.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.TimeSlot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.TimeSlot}
 */
proto.pb.TimeSlot.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setStart(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setEnd(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.TimeSlot.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.TimeSlot.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.TimeSlot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.TimeSlot.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getStart();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getEnd();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};

/**
 * optional string start = 1;
 * @return {string}
 */
proto.pb.TimeSlot.prototype.getStart = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.TimeSlot} returns this
 */
proto.pb.TimeSlot.prototype.setStart = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string end = 2;
 * @return {string}
 */
proto.pb.TimeSlot.prototype.getEnd = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.TimeSlot} returns this
 */
proto.pb.TimeSlot.prototype.setEnd = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.StatusTutor.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.StatusTutor.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.StatusTutor} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.StatusTutor.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                online: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
                lastSeen: jspb.Message.getFieldWithDefault(msg, 2, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.StatusTutor}
 */
proto.pb.StatusTutor.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.StatusTutor();

    return proto.pb.StatusTutor.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.StatusTutor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.StatusTutor}
 */
proto.pb.StatusTutor.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setOnline(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setLastSeen(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.StatusTutor.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.StatusTutor.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.StatusTutor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.StatusTutor.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getOnline();
    if (f) {
        writer.writeBool(1, f);
    }

    f = message.getLastSeen();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};

/**
 * optional bool online = 1;
 * @return {boolean}
 */
proto.pb.StatusTutor.prototype.getOnline = function () {
    return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};

/**
 * @param {boolean} value
 * @return {!proto.pb.StatusTutor} returns this
 */
proto.pb.StatusTutor.prototype.setOnline = function (value) {
    return jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional string last_seen = 2;
 * @return {string}
 */
proto.pb.StatusTutor.prototype.getLastSeen = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.StatusTutor} returns this
 */
proto.pb.StatusTutor.prototype.setLastSeen = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.MetaData.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.MetaData.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.MetaData} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.MetaData.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                timeSearch: jspb.Message.getFieldWithDefault(msg, 1, ""),
                page: jspb.Message.getFieldWithDefault(msg, 2, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.MetaData}
 */
proto.pb.MetaData.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.MetaData();

    return proto.pb.MetaData.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.MetaData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.MetaData}
 */
proto.pb.MetaData.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setTimeSearch(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setPage(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.MetaData.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.MetaData.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.MetaData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.MetaData.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getTimeSearch();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getPage();
    if (f !== 0) {
        writer.writeInt32(2, f);
    }
};

/**
 * optional string time_search = 1;
 * @return {string}
 */
proto.pb.MetaData.prototype.getTimeSearch = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.MetaData} returns this
 */
proto.pb.MetaData.prototype.setTimeSearch = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.pb.MetaData.prototype.getPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.MetaData} returns this
 */
proto.pb.MetaData.prototype.setPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Paging.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Paging.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Paging} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Paging.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                total: jspb.Message.getFieldWithDefault(msg, 1, 0),
                page: jspb.Message.getFieldWithDefault(msg, 2, 0),
                pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Paging}
 */
proto.pb.Paging.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Paging();

    return proto.pb.Paging.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Paging} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Paging}
 */
proto.pb.Paging.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setTotal(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setPage(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setPages(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Paging.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Paging.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Paging} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Paging.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getTotal();
    if (f !== 0) {
        writer.writeInt32(1, f);
    }

    f = message.getPage();
    if (f !== 0) {
        writer.writeInt32(2, f);
    }

    f = message.getPages();
    if (f !== 0) {
        writer.writeInt32(3, f);
    }
};

/**
 * optional int32 total = 1;
 * @return {number}
 */
proto.pb.Paging.prototype.getTotal = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.Paging} returns this
 */
proto.pb.Paging.prototype.setTotal = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.pb.Paging.prototype.getPage = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.Paging} returns this
 */
proto.pb.Paging.prototype.setPage = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int32 pages = 3;
 * @return {number}
 */
proto.pb.Paging.prototype.getPages = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.Paging} returns this
 */
proto.pb.Paging.prototype.setPages = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Photo.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Photo.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Photo} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Photo.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                link: jspb.Message.getFieldWithDefault(msg, 1, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Photo}
 */
proto.pb.Photo.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Photo();

    return proto.pb.Photo.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Photo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Photo}
 */
proto.pb.Photo.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setLink(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Photo.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Photo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Photo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Photo.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getLink();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};

/**
 * optional string link = 1;
 * @return {string}
 */
proto.pb.Photo.prototype.getLink = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Photo} returns this
 */
proto.pb.Photo.prototype.setLink = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Video.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Video.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Video} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Video.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                link: jspb.Message.getFieldWithDefault(msg, 1, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Video}
 */
proto.pb.Video.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Video();

    return proto.pb.Video.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Video} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Video}
 */
proto.pb.Video.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setLink(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Video.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Video.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Video} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Video.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getLink();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};

/**
 * optional string link = 1;
 * @return {string}
 */
proto.pb.Video.prototype.getLink = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Video} returns this
 */
proto.pb.Video.prototype.setLink = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Empty.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Empty.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Empty} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Empty.toObject = function (includeInstance, msg) {
        var f,
            obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Empty}
 */
proto.pb.Empty.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Empty();

    return proto.pb.Empty.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Empty}
 */
proto.pb.Empty.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Empty.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Empty.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Empty.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};

/**
 * @enum {number}
 */
proto.pb.Action = {
    ADD: 0,
    REMOVE: 1,
};

goog.object.extend(exports, proto.pb);
