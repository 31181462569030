const Play = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="12"
                cy="12"
                r="12"
                fill="#6212FF"
            />
            <path
                d="M16.1389 10.7526C17.0209 11.3304 17.0209 12.668 16.1389 13.2459L10.5714 16.5702C9.62828 17.1881 8.40039 16.483 8.40039 15.3235L8.40039 8.67491C8.40039 7.51542 9.62828 6.81031 10.5714 7.42825L16.1389 10.7526Z"
                fill="white"
            />
        </svg>
    );
};

export default Play;
