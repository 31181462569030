import { memo } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import * as themes from "./ConductedClassesThemes";
import * as textThemes from "themes/TextThemes";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import UserStore from "store/UserStore";

const ConductedClasses = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const getClassTitle = (number) => {
        if (number === 0) {
            return "teacherAccount.privilegeProgram.noClasses";
        } else if (number === 1) {
            return "teacherAccount.privilegeProgram.oneClass";
        } else if (number > 1 && number < 5) {
            return "teacherAccount.privilegeProgram.fewClasses";
        } else if (number > 5) {
            return "teacherAccount.privilegeProgram.manyClasses";
        }
    };

    const getClassBottomText = (currentValue, previousValue) => {
        if (currentValue > previousValue) {
            return `${t("teacherAccount.privilegeProgram.biggerOrLessPart1")}${currentValue - previousValue} ${t(getClassTitle(currentValue - previousValue))}${t("teacherAccount.privilegeProgram.biggerPart2")}${t(TeacherQuestionnaireStore.monthNamesIn[new Date().getMonth() - 1])}`;
        } else if (currentValue < previousValue) {
            return `${t("teacherAccount.privilegeProgram.biggerOrLessPart1")}${previousValue - currentValue} ${t(getClassTitle(previousValue - currentValue))}${t("teacherAccount.privilegeProgram.lessPart2")}${t(TeacherQuestionnaireStore.monthNamesIn[new Date().getMonth() - 1])}`;
        } else {
            return `${t("teacherAccount.privilegeProgram.same")}${t(TeacherQuestionnaireStore.monthNamesIn[new Date().getMonth() - 1])}`;
        }
    };

    const handleBlockClick = () => {
        navigate("/tutor/classes");
        UserStore.setPage(2);
    };

    return (
        <Box
            sx={themes.WhiteContainer}
            onClick={handleBlockClick}
        >
            <Box sx={themes.TopBlock}>
                <Typography
                    sx={textThemes.Body2DarkGrey}
                >{`${t("teacherAccount.privilegeProgram.quantityOfClassesIn")}${t(TeacherQuestionnaireStore.monthNamesIn[new Date().getMonth()])}`}</Typography>
                <Typography
                    sx={textThemes.Button16BoldBlack}
                >{`${TeacherQuestionnaireStore.teacher.conductedClasses.number} ${t(getClassTitle(TeacherQuestionnaireStore.teacher.conductedClasses.number))}`}</Typography>
            </Box>
            {TeacherQuestionnaireStore.teacher.conductedClasses.number !== 0 && TeacherQuestionnaireStore.teacher.conductedClasses.previousNumber !== 0 ? (
                <Typography sx={textThemes.Caption1Black}>
                    {getClassBottomText(TeacherQuestionnaireStore.teacher.conductedClasses.number, TeacherQuestionnaireStore.teacher.conductedClasses.previousNumber)}
                </Typography>
            ) : null}
        </Box>
    );
});

export default memo(ConductedClasses);
