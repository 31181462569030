import { createTheme } from "@mui/material/styles";

export const certificatesMainContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: 20,
};

export const certificatesContentContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 10,
};

export const certificatesItemStyle = (medias) => {
    return {
        display: "flex",
        width: medias.sm ? "827px" : "320px",
        alignItems: "flex-start",
        gap: "6px",
    };
};

export const certificatesInfoStyle = {
    color: "var(--text-black, #222)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const certificatesYearStyle = {
    color: "var(--text-dark-grey, #67677A)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const VerificationTheme = (isVerified) =>
    createTheme({
        components: {
            MuiTooltip: {
                styleOverrides: {
                    root: {},
                    tooltip: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        padding: "10px",
                        left: "-18px",
                        marginBottom: "10px!important",
                        boxSizing: "border-box",
                        background: isVerified ? "var(--main-green, #19B20C)" : "#FF9212",
                        borderRadius: "10px",
                        flex: "none",
                        order: 1,
                        flexGrow: 1,
                    },
                    arrow: {
                        left: "18px!important",
                        color: isVerified ? "var(--main-green, #19B20C)" : "#FF9212",
                        flex: "none",
                        order: 0,
                        flexGrow: 0,
                    },
                },
            },
        },
    });

export const tooltipVerificationStyle = (isVerified) => ({
    fontWtyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "140%",
    display: "flex",
    alignItems: "center",
    color: isVerified ? "#FFFFFF!important" : "#222222!important",
    flex: "none",
    flexGrow: 1,
});

export const verifiedStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "5px",
    width: "24px",
    height: "24px",
    flex: "none",
    flexGrow: 0,
    color: "#19B20C",
};

export const safetyCheckStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "5px",
    width: "24px",
    height: "24px",
    flex: "none",
    flexGrow: 0,
    color: "gray",
};

export const imageListTheme = (medias) => {
    return {
        components: {
            MuiImageList: {
                styleOverrides: {
                    root: {
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "20px!important",
                        margin: 0,
                        width: medias.sm ? "827px" : "320px",
                        height: "fit-content",
                    },
                },
            },
            MuiImageListItem: {
                styleOverrides: {
                    root: {
                        cursor: "pointer",
                        "&>img": {
                            borderRadius: "10px!important",
                            border: "1px solid var(--grey-separator, #EEEEF0)!important",
                        },
                    },
                },
            },
        },
    };
};
