export const BackIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <g clipPath="url(#clip0_3984_82980)">
                <path
                    d="M0 12C-5.79387e-07 5.37258 5.37258 5.79387e-07 12 0C18.6274 -5.79387e-07 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 5.79387e-07 18.6274 0 12Z"
                    fill="white"
                    fillOpacity="0.5"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 1C5.92487 1 0.999999 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 0.999999 12 1ZM12 0C5.37258 5.79387e-07 -5.79387e-07 5.37258 0 12C5.79387e-07 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 -5.79387e-07 12 0Z"
                    fill="#222222"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.7427 17.3037L6.96974 12.5307C6.67685 12.2378 6.67685 11.7629 6.96974 11.4701L11.7427 6.69708C12.0356 6.40419 12.5105 6.40419 12.8034 6.69708C13.0963 6.98998 13.0963 7.46485 12.8034 7.75774L9.31073 11.2504H16.5001C16.9143 11.2504 17.2501 11.5862 17.2501 12.0004C17.2501 12.4146 16.9143 12.7504 16.5001 12.7504H9.31073L12.8034 16.243C13.0963 16.5359 13.0963 17.0108 12.8034 17.3037C12.5105 17.5966 12.0356 17.5966 11.7427 17.3037Z"
                    fill="#222222"
                />
            </g>
            <defs>
                <clipPath id="clip0_3984_82980">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(24 24) rotate(180)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
