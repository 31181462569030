import React from "react";
import { Typography } from "@mui/material";
import * as themes from "./TutorClassesBodyBlockThemes";
import * as textThemes from "themes/TextThemes";
import NextLesson from "./NextLesson/NextLesson";
import { memo } from "react";
import ClassesListBlock from "./ClassesListBlock/ClassesListBlock";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import TutorClassesTabs from "./TutorClassesTabs/TutorClassesTabs";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import TutorClassesStore from "store/TutorClassesStore";
import { useMedias } from "API/mediasHook";

const TutorClassesBodyBlock = ({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <ScrollToTop>
            <Box sx={themes.TutorClasses(medias)}>
                <Box sx={themes.TutorClassesContent(medias)}>
                    {TutorClassesStore.classes.filter((classItem) => classItem.classStatus === "upcoming").length > 0 && (
                        <Box sx={themes.NextClassBlock}>
                            <Typography sx={medias.sm ? textThemes.HeadlineH3Black : textThemes.HeadlineH41Black}>{t("teacherClasses.nextClassWithoutDots")}</Typography>
                            <NextLesson />
                        </Box>
                    )}
                    <Box sx={themes.ClassesBlock}>
                        <Typography sx={medias.sm ? textThemes.HeadlineH3Black : textThemes.HeadlineH41Black}>{t("teacherClasses.classes")}</Typography>
                        <Box sx={themes.TabsAndClasses(medias)}>
                            <TutorClassesTabs />
                            <ClassesListBlock />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ScrollToTop>
    );
};

export default memo(TutorClassesBodyBlock);
