import React from "react";
import { IconButton, Typography } from "@mui/material";
import * as themes from "components/SettingsBodyBlock/TutorSettings/MainForm/MainFormThemes";
import * as textThemes from "themes/TextThemes";
import SButton from "components/UI/SButton";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import { observer } from "mobx-react-lite";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { useMedias } from "API/mediasHook";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import SIconButton from "../../SIconButton";

const MainInfo = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const handleEdit = () => {
        props.handleSet(1);
    };

    return (
        <Box sx={themes.SmallItem(medias)}>
            <Box sx={themes.ItemContent(medias)}>
                <Box sx={themes.TitleAndEdit}>
                    <Typography sx={medias.sm ? textThemes.HeadlineH41Black : textThemes.HeadlineH5Black}>{t("teacherSettings.questionnaire.personalInfo")}</Typography>
                    {medias.sm ? (
                        <SButton
                            onClick={handleEdit}
                            variant="text"
                        >
                            {t("teacherSettings.questionnaire.edit")}
                        </SButton>
                    ) : (
                        <SIconButton onClick={handleEdit}>
                            <DriveFileRenameOutlineOutlinedIcon />
                        </SIconButton>
                    )}
                </Box>
                <Box sx={themes.ImageBlock(medias)}>
                    <img
                        src={TeacherQuestionnaireStore.teacher.photo.imageSrc}
                        style={themes.Photo}
                    />
                    <Box sx={themes.verticalDescriptionBlock(medias)}>
                        <Box sx={themes.descriptionBlock}>
                            <Typography sx={textThemes.Body2DarkGrey}>{t("teacherSettings.questionnaire.fio")}</Typography>
                            <Typography sx={textThemes.Body1Black}>
                                {TeacherQuestionnaireStore.teacher.secondName} {TeacherQuestionnaireStore.teacher.firstName} {TeacherQuestionnaireStore.teacher.surname}
                            </Typography>
                        </Box>
                        <Box sx={themes.horizontalDescriptionBlock(medias)}>
                            <Box sx={themes.descriptionBlock}>
                                <Typography sx={textThemes.Body2DarkGrey}>{t("teacherSettings.questionnaire.birthday")}</Typography>
                                <Typography sx={textThemes.Body1Black}>
                                    {TeacherQuestionnaireStore.teacher.birthday.toLocaleString("ru", { year: "numeric", month: "numeric", day: "numeric" })}
                                </Typography>
                            </Box>
                            <Box sx={themes.descriptionBlock}>
                                <Typography sx={textThemes.Body2DarkGrey}>{t("teacherSettings.questionnaire.gender")}</Typography>
                                <Typography sx={textThemes.Body1Black}>{TeacherQuestionnaireStore.teacher.sex}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
});

export default memo(MainInfo);
