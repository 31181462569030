import { Button, Menu, Typography, styled } from "@mui/material";

export const StyledOrderResponseCountSpecialist = styled(Typography)({
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "27px",
    marginTop: "12px",
});

export const StyledOrderResponseMenuButton = styled(Button)({
    marginRight: "-7px",
    fontFamily: '"Inter", sans-serif',
    fontWeight: 500,
    fontSize: "14px",
    minWidth: "0px",
    color: "rgba(34, 34, 34, 1)",
    backgroundColor: "none",
    textTransform: "none",
    transition: "opacity 0.5s",
    "&:hover": {
        color: "rgba(34, 34, 34, 1)",
        background: "none",
        transition: "opacity 0.5s",
    },
    "& .MuiTouchRipple-root": {
        color: "rgba(34, 34, 34, 1)",
    },
});

export const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    transform: "translateX(-9px)",
    "& .MuiPaper-root": {
        borderRadius: "10px",
        marginTop: theme.spacing(0.4),
        minWidth: 260,
        boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            display: "flex",
            justifyContent: "space-between",
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: "rgba(98, 18, 255, 1)",
            },
            "&:active": {
                color: "rgba(98, 18, 255, 1)",
                backgroundColor: "rgba(243, 243, 243, 1)",
            },
            "&:hover": {
                color: "rgba(98, 18, 255, 1)",
                backgroundColor: "rgba(243, 243, 243, 1)",
            },
        },
    },
}));

export const StyledOrderResponseMenuTitle = styled(Typography)({
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: '"Inter", sans-serif',
});
