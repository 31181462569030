import { styleProcessing } from "API/plugins/Utilities";

export const ModalChooseTutor = (theme) => {
    let commonStyle = {
        width: "540px",
        paddingBottom: "12px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            maxWidth: "599px",
            width: "100%",
            height: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ModalChooseTutorContent = (theme) => {
    let commonStyle = {
        padding: "8px 20px 24px 20px",
        display: "flex",
        flexDirection: "column",
        gap: "6px",
        maxHeight: "400px",
        overflowY: "scroll",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            maxHeight: "100%",
            padding: "20px 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TutorInfo = {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
};

export const Avatar = {
    width: "50px",
    height: "50px",
};

export const TutorContainer = {
    display: "flex",
    gap: "12px",
    alignItems: "center",
};

export const TutorButton = (hovered) => {
    return {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 10px",
        background: hovered ? "rgba(187, 187, 200, 0.17)" : "transparent",
        borderRadius: "10px",
        cursor: "pointer",
    };
};

export const Divider = {
    background: "#EEEEF0",
    height: "1px",
};

export const DividerContainer = {
    padding: "0 20px",
};

export const ChooseTutor = {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
};
