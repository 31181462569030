export const headerPartContainerStyle = (medias) => {
    return {
        width: medias.sm ? "1216px" : "fit-content",
        //margin: medias.sm ? "auto" : "16px",
        marginLeft: medias.sm ? "auto" : "16px",
        marginRight: medias.sm ? "auto" : "16px",
    };
};

export const filtersBCStyle = (medias) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: 0,
    gap: "6px",
    width: "fit-content",
    height: "fit-content", //21px
    marginTop: medias.sm ? "20px" : "10px",
});

export const filtersMainHeader = (medias) => {
    return {
        width: "fit-content",
        height: "48px",
        marginTop: medias.sm ? "60px" : "20px",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: medias.sm ? "40px" : "24px",
        lineHeight: "120%",
        display: "flex",
        alignItems: "center",
        color: "#222222",
    };
};

export const linkStyle = (medias) => {
    return {
        color: "var(--Text-Black, #222)",
        fontFamily: "Inter",
        fontSize: medias.sm ? "16px" : "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "150%",
    };
};

export const linkSubjectStyle = (medias) => {
    return {
        color: "var(--Text-Grey, #9E9EB0)",
        fontFamily: "Inter",
        fontSize: medias.sm ? "16px" : "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "150%",
    };
};
