import { memo, useState } from "react";
import ModalSettings from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsBig/ModalSettings";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Avatar, Box, Drawer, Typography } from "@mui/material";
import MyClassesStore from "store/MyClassesStore";
import { TutorArrowIcon } from "assets/MyClassesAssets/SVG/TutorArrowIcon";
import * as styles from "./ModalChooseTutorThemes";
import * as textStyles from "themes/TextThemes";
import { useMedias } from "API/mediasHook";
import DrawerHeader from "components/SettingsBodyBlock/StudentSettings/DrawerHeader/DrawerHeader";
import MyClassesDrawer from "components/MyClassesBodyBlock/MyClassesDrawer";

const ModalChooseTutor = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const Close = () => {
        props.handleDiscard();
    };

    const Save = (tutor) => {
        MyClassesStore.setNewClassTutor(tutor);
        props.handleSave();
    };

    const [hovered, setHovered] = useState(null);

    const DrawerList = (
        <Box sx={styles.ModalChooseTutor(medias)}>
            <DrawerHeader
                text={t("main.myClasses.chooseTutor")}
                handleClose={Close}
            />
            <Box sx={styles.ModalChooseTutorContent(medias)}>
                {MyClassesStore.getTurorList().map((tutor, index) => {
                    return (
                        <Box
                            sx={styles.ChooseTutor}
                            key={index}
                        >
                            <Box
                                onClick={() => Save(tutor)}
                                sx={styles.TutorButton(hovered === index)}
                                onMouseEnter={() => setHovered(index)}
                                onMouseLeave={() => setHovered(null)}
                            >
                                <Box sx={styles.TutorContainer}>
                                    <Avatar
                                        src={tutor.photo}
                                        sx={styles.Avatar}
                                    />
                                    <Box sx={styles.TutorInfo}>
                                        <Typography sx={textStyles.Button16Black}>{tutor.tutor}</Typography>
                                        <Typography sx={textStyles.Body2DarkGrey}>{tutor.subject}</Typography>
                                    </Box>
                                </Box>
                                <TutorArrowIcon />
                            </Box>
                            {MyClassesStore.getTurorList().length - 1 === index ? null : (
                                <Box sx={styles.DividerContainer}>
                                    <Box sx={styles.Divider}></Box>
                                </Box>
                            )}
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );

    return (
        <>
            {medias.sm ? (
                <ModalSettings
                    visible={props.open}
                    handleClose={Close}
                    header={t("main.myClasses.chooseTutor")}
                    padding="0px"
                    width="540px"
                >
                    <Box sx={styles.ModalChooseTutor(medias)}>
                        <Box sx={styles.ModalChooseTutorContent(medias)}>
                            {MyClassesStore.getTurorList().map((tutor, index) => {
                                return (
                                    <Box
                                        sx={styles.ChooseTutor}
                                        key={index}
                                    >
                                        <Box
                                            onClick={() => Save(tutor)}
                                            sx={styles.TutorButton(hovered === index)}
                                            onMouseEnter={() => setHovered(index)}
                                            onMouseLeave={() => setHovered(null)}
                                        >
                                            <Box sx={styles.TutorContainer}>
                                                <Avatar
                                                    src={tutor.photo}
                                                    sx={styles.Avatar}
                                                />
                                                <Box sx={styles.TutorInfo}>
                                                    <Typography sx={textStyles.Button16Black}>{tutor.tutor}</Typography>
                                                    <Typography sx={textStyles.Body2DarkGrey}>{tutor.subject}</Typography>
                                                </Box>
                                            </Box>
                                            <TutorArrowIcon />
                                        </Box>
                                        {MyClassesStore.getTurorList().length - 1 === index ? null : (
                                            <Box sx={styles.DividerContainer}>
                                                <Box sx={styles.Divider}></Box>
                                            </Box>
                                        )}
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                </ModalSettings>
            ) : (
                <MyClassesDrawer
                    open={props.open}
                    close={Close}
                >
                    {DrawerList}
                </MyClassesDrawer>
            )}
        </>
    );
});

export default memo(ModalChooseTutor);
