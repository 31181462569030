import { memo } from "react";
import * as themes from "./TeacherInfoMiniCardThemes";
import AvatarArea from "./AvatarArea";
import InfoArea from "./InfoArea";
import Box from "@mui/material/Box";

const TeacherInfoMiniCard = ({ children, ...props }) => {
    return (
        <Box sx={themes.teacherInfoContainerStyle}>
            <AvatarArea value={props.value}></AvatarArea>
            <InfoArea value={props.value}></InfoArea>
        </Box>
    );
};

export default memo(TeacherInfoMiniCard);
