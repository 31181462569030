import MenuListComposition from "components/UI/PopperMenu";
import Button from "@mui/material/Button";
import DropDownFilterIcon from "assets/CommonAssets/SVG/DropDownFilterIcon";
import * as React from "react";
import Paper from "@mui/material/Paper";
import { ListItemButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as styles from "./LanugageCurrencyModalThemes";
import CheckMark from "assets/CommonAssets/SVG/CheckMark";
import Box from "@mui/material/Box";
import { memo } from "react";

const languagesList = { "ru-RU": "Русский", "en-US": "English", ru: "Русский", en: "English" };

// const currenciesList = ["RUB", "USD", "EUR"];

const LanguageCurrencyModal = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const [openLangChoice, setOpenLangChoice] = React.useState(false);

    // const [openCurrChoice, setOpenCurrChoice] = React.useState(false);

    const handleChooseLang = (value, event) => {
        localStorage.setItem("userLanguage", value);
        i18n.changeLanguage(value.split("-")[0]);
        setOpenLangChoice(false);
        props.chooseLanguage(value);
    };

    // const handleChooseCurr = (value, event) => {
    //     setCurr(value);
    //     setOpenCurrChoice(false);
    // };

    return (
        <Box sx={styles.LanguageCurrencyContent}>
            <Box sx={styles.LanguageCurrencyChoiceContainter}>
                <Box sx={styles.LanguageCurrencyTitle}>{t("main.header.language")}</Box>
                <MenuListComposition
                    open={openLangChoice}
                    setOpen={setOpenLangChoice}
                    zIndex={2}
                    paperSx={styles.LanguageCurrencyPaper}
                    content={
                        <Paper sx={styles.LanguageCurrencyContentPaper}>
                            {Object.keys(languagesList).map((language, index) => (
                                <ListItemButton
                                    key={index}
                                    disableRipple
                                    onClick={handleChooseLang.bind(this, language)}
                                    sx={styles.LanguageCurrencyButton}
                                >
                                    {languagesList[language]}
                                    {languagesList[language] === languagesList[localStorage.getItem("userLanguage")] ? <CheckMark /> : null}
                                </ListItemButton>
                            ))}
                        </Paper>
                    }
                >
                    <Button
                        disableRipple
                        sx={styles.LanguageCurrencyChooseButton(openLangChoice)}
                    >
                        {languagesList[localStorage.getItem("userLanguage")]}
                        <DropDownFilterIcon />
                    </Button>
                </MenuListComposition>
            </Box>
            {/*<Box sx={styles.LanguageCurrencyChoiceContainter}>*/}
            {/*    <Box sx={styles.LanguageCurrencyTitle}>{t("main.header.currency")}</Box>*/}
            {/*    <MenuListComposition*/}
            {/*        open={openCurrChoice}*/}
            {/*        setOpen={setOpenCurrChoice}*/}
            {/*        zIndex={1}*/}
            {/*        paperSx={styles.LanguageCurrencyPaper}*/}
            {/*        content={*/}
            {/*            <Paper sx={styles.LanguageCurrencyContentPaper}>*/}
            {/*                {currenciesList.map((currency, index) => (*/}
            {/*                    <ListItemButton*/}
            {/*                        key={index}*/}
            {/*                        disableRipple*/}
            {/*                        onClick={handleChooseCurr.bind(this, currency)}*/}
            {/*                        sx={styles.LanguageCurrencyButton}*/}
            {/*                    >*/}
            {/*                        {currency}*/}
            {/*                        {currency === curr ? <CheckMark /> : null}*/}
            {/*                    </ListItemButton>*/}
            {/*                ))}*/}
            {/*            </Paper>*/}
            {/*        }*/}
            {/*    >*/}
            {/*        <Button*/}
            {/*            disableRipple*/}
            {/*            sx={styles.LanguageCurrencyChooseButton(openCurrChoice)}*/}
            {/*        >*/}
            {/*            {curr}*/}
            {/*            <DropDownFilterIcon />*/}
            {/*        </Button>*/}
            {/*    </MenuListComposition>*/}
            {/*</Box>*/}
        </Box>
    );
};

export default memo(LanguageCurrencyModal);
