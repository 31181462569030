import React from "react";
import OtherTutor1 from "components/MainPromoBodyBlock/AboutUs/OtherTutors/OtherTutor1.png";
import OtherTutor2 from "components/MainPromoBodyBlock/AboutUs/OtherTutors/OtherTutor2.png";
import OtherTutor3 from "components/MainPromoBodyBlock/AboutUs/OtherTutors/OtherTutor3.png";
import OtherTutor4 from "components/MainPromoBodyBlock/AboutUs/OtherTutors/OtherTutor4.png";
import * as OtherTutorsStyle from "./OtherTutorsStyle";
import FIveStarsIconSVG from "assets/CommonAssets/SVG/fivestarsicon";
import { useMedias } from "API/mediasHook";
import { memo } from "react";
import Box from "@mui/material/Box";
import SButton from "components/UI/SButton";
import { useTranslation } from "react-i18next";

const OtherTutors = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const medias = useMedias();

    return (
        <Box style={OtherTutorsStyle.OtherTutorBlock(medias)}>
            <Box style={OtherTutorsStyle.OtherTutors}>
                <img
                    style={OtherTutorsStyle.OtherTutor1}
                    src={OtherTutor1}
                ></img>
                <img
                    style={OtherTutorsStyle.OtherTutor2}
                    src={OtherTutor2}
                ></img>
                <img
                    style={OtherTutorsStyle.OtherTutor3}
                    src={OtherTutor3}
                ></img>
                <img
                    style={OtherTutorsStyle.OtherTutor4}
                    src={OtherTutor4}
                ></img>
                <Box style={OtherTutorsStyle.Stars}>
                    <FIveStarsIconSVG />
                </Box>
                <Box style={OtherTutorsStyle.OtherText(medias)}>{t("promoPages.main.youWillFindVerifiedSpecialists")}</Box>
            </Box>
            <Box style={OtherTutorsStyle.OtherTextMobile(medias)}>{t("promoPages.main.youWillFindVerifiedSpecialists")}</Box>
            <SButton
                style={OtherTutorsStyle.FindTutorText(medias)}
                fontSize="16px"
                variant="text-arrow"
                onClick={() => {
                    window.open("https://study-buddy.ru/catalog");
                }}
            >
                {t("promoPages.main.findYours")}
            </SButton>
        </Box>
    );
};

export default memo(OtherTutors);
