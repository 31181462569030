import UserStore from "store/UserStore";
import SettingsStore from "store/SettingsStore";
import { Link, Typography } from "@mui/material";
import SetMail from "assets/SettingsAssets/SVG/SetMail";
import Verified from "assets/SettingsAssets/SVG/Verified";
import SetPassword from "assets/SettingsAssets/SVG/SetPassword";
import React from "react";
import * as styles from "./LoginActionRequiredThemes";
import * as textStyles from "themes/TextThemes";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

const LoginActionRequired = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const addEmail = () => {
        props.addEmail();
    };

    const addPassword = () => {
        props.addPassword();
    };

    if (UserStore.UserInfo.mail.current === null)
        return (
            <Box sx={styles.LoginActionRequired(UserStore.role !== "student")}>
                <Box sx={styles.LoginActionRequiredIcon}>
                    <SetMail />
                </Box>
                <Box sx={styles.LoginActionRequiredContent}>
                    <Typography sx={textStyles.Button16Black}>{t("main.settings.loginDetails.enterEmail")}</Typography>
                    <Typography sx={textStyles.Body2Black}>
                        {t("main.settings.loginDetails.enterEmailDescriptionBlack")}
                        <Link
                            underline="none"
                            color="#6212FF"
                            sx={{ cursor: "pointer" }}
                            onClick={addEmail}
                        >
                            {t("main.settings.loginDetails.enterEmailDescriptionPurple")}
                        </Link>
                    </Typography>
                </Box>
            </Box>
        );

    if (UserStore.UserInfo.mail.verified === false)
        return (
            <Box sx={styles.LoginActionRequired(UserStore.role !== "student")}>
                <Box sx={styles.LoginActionRequiredIcon}>
                    <Verified />
                </Box>
                <Box sx={styles.LoginActionRequiredContent}>
                    <Typography sx={textStyles.Button16Black}> {t("main.settings.loginDetails.verifyEmail")}</Typography>
                    <Typography sx={textStyles.Body2Black}>
                        {t("main.settings.loginDetails.verifyEmailDescriptionBlack")}
                        <Link
                            underline="none"
                            color="#6212FF"
                            sx={{ cursor: "pointer" }}
                            onClick={() => UserStore.setVerifiedEmail(true)}
                        >
                            {t("main.settings.loginDetails.verifyEmailDescriptionPurple")}
                        </Link>
                    </Typography>
                </Box>
            </Box>
        );

    if (SettingsStore.temporaryEmailNotVerified !== null)
        return (
            <Box sx={styles.LoginActionRequired(UserStore.role !== "student")}>
                <Box sx={styles.LoginActionRequiredIcon}>
                    <Verified />
                </Box>
                <Box sx={styles.LoginActionRequiredContent}>
                    <Typography sx={textStyles.Button16Black}> {t("main.settings.loginDetails.verifyEmail")}</Typography>
                    <Typography sx={textStyles.Body2Black}>
                        {t("main.settings.loginDetails.verifyEmailDescriptionBlackNewPart1")}
                        <Link
                            underline="none"
                            color="#67677A"
                        >
                            {SettingsStore.temporaryEmailNotVerified}
                        </Link>
                        {t("main.settings.loginDetails.verifyEmailDescriptionBlackNewPart2")}
                        <Link
                            underline="none"
                            color="#6212FF"
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                                UserStore.setEmail(SettingsStore.temporaryEmailNotVerified);
                                UserStore.setVerifiedEmail(true);
                                SettingsStore.setTemporaryEmailNotVerified(null);
                            }}
                        >
                            {t("main.settings.loginDetails.verifyEmailDescriptionPurple")}
                        </Link>
                    </Typography>
                </Box>
            </Box>
        );

    if (UserStore.UserInfo.password === null)
        return (
            <Box sx={styles.LoginActionRequired(UserStore.role !== "student")}>
                <Box sx={styles.LoginActionRequiredIcon}>
                    <SetPassword />
                </Box>
                <Box sx={styles.LoginActionRequiredContent}>
                    <Typography sx={textStyles.Button16Black}> {t("main.settings.loginDetails.createPassword")}</Typography>
                    <Typography sx={textStyles.Body2Black}>
                        {t("main.settings.loginDetails.createPasswordDescriptionBlack")}
                        <Link
                            underline="none"
                            color="#6212FF"
                            sx={{ cursor: "pointer" }}
                            onClick={addPassword}
                        >
                            {t("main.settings.loginDetails.createPasswordDescriptionPurple")}
                        </Link>
                    </Typography>
                </Box>
            </Box>
        );
});

export default LoginActionRequired;
