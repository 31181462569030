export const YourCityText = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "150%",
    color: "#222",
};

export const YourCityButton = {
    p: 0,
    m: 0,
    textTransform: "none",
    display: "flex",
    gap: "2px",
};

export const CurrentCityText = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "150%",
    color: "#6212FF",
};

export const ArrowDownIcon = { color: "#6212FF" };
