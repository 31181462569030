import React, { useState } from "react";

import { Stack, Typography } from "@mui/material";

import SButton from "components/UI/SButton";
import StyledTextField from "components/DiplomaChats/StyledComponents/StyledTextField";
import SendReviewModal from "./SendReviewModal";
import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";

const Review = ({ rating, cancelRating }) => {
    const [showSendReviewModal, setShowSendReviewModal] = useState(false);

    const [commentBuf, setCommentBuf] = useState(null);

    const handleChangeComment = (e) => {
        setCommentBuf(e.target.value);
    };

    const handleSendReview = () => {
        setShowSendReviewModal(true);
        // TODO: переделать логику, потому что тут полная хуйня происходит
        // DiplomaStore.SetStars(rating);
        // DiplomaStore.SetComment(commentBuf);
    };

    const handleCloseReviewModal = () => {
        setShowSendReviewModal(false);
        DiplomaStore.SetStars(rating);
        DiplomaStore.SetComment(commentBuf);
    };

    return (
        <Stack
            direction="column"
            spacing={2}
        >
            <Typography variant="standard">Расскажите, что именно вам {rating < 3 && " не "} понравилось?</Typography>
            <StyledTextField
                placeholder="Ваш отзыв..."
                multiline
                rows={6}
                fullWidth
                margin="none"
                onChange={handleChangeComment}
            />
            <Stack
                spacing={1.5}
                justifyContent="flex-end"
                direction="row"
            >
                <SButton
                    variant="secondary"
                    onClick={cancelRating}
                >
                    Отменить
                </SButton>
                <SButton style={{ height: "36px" }}>
                    <Typography
                        variant="standardWhite"
                        onClick={handleSendReview}
                    >
                        Отправить
                    </Typography>
                </SButton>
                <SendReviewModal
                    starsCount={rating}
                    open={showSendReviewModal}
                    close={handleCloseReviewModal}
                />
            </Stack>
        </Stack>
    );
};

export default Review;
