import { Box, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import * as themes from "./WeekDatesStyled";
import { useTranslation } from "react-i18next";
import { memo } from "react";
const WeekDates = ({ weekDates, currentDate }) => {
    const { t, i18n } = useTranslation();

    const today = new Date();

    const currentDay = currentDate.getDay();

    const options = {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
    };

    const formattedDate = today.toLocaleString("ru-RU", options);

    const formattedDateProps = currentDate.toLocaleString("ru-RU", options);

    const isCurrentWeek = formattedDateProps === formattedDate;

    const getColor = (dateString, i) => {
        const dateParts = dateString.split(" ");

        const day2 = parseInt(dateParts[0]);

        const monthIndex = getMonthIndex(dateParts[1]);

        const year = parseInt(dateParts[2]);

        const date = new Date(year, monthIndex, day2);

        function getMonthIndex(month) {
            const monthNames = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];

            return monthNames.indexOf(month);
        }

        if (isCurrentWeek && ((currentDay === 0 && i === 6) || currentDay === i + 1)) {
            return "rgba(98, 18, 255, 1)";
        }

        if (date <= today) {
            return "rgba(187, 187, 200, 1)";
        }

        return "rgba(34, 34, 34, 1)";
    };

    const getDayOfWeek = (dayIndex) => {
        const weekdays = [
            "main.daysOfWeek.mondayShort",
            "main.daysOfWeek.tuesdayShort",
            "main.daysOfWeek.wednesdayShort",
            "main.daysOfWeek.thursdayShort",
            "main.daysOfWeek.fridayShort",
            "main.daysOfWeek.saturdayShort",
            "main.daysOfWeek.sundayShort",
        ];

        return weekdays[dayIndex % 7];
    };

    return (
        <Box sx={{ marginLeft: "36px" }}>
            {" "}
            <TableHead>
                <TableRow>
                    <TableCell sx={themes.weekDatesNoneBorder} />
                    {weekDates.map((day, i) => (
                        <TableCell
                            key={i}
                            sx={[themes.weekDatesTextColor, { color: getColor(day, i), border: "none" }]}
                            align="center"
                        >
                            <Typography
                                sx={themes.dateNumber}
                                variant="span"
                            >
                                {day.split(" ")[0]}
                            </Typography>
                            <Typography
                                sx={[themes.dayOfWeek, { color: getColor(day, i) }]}
                                variant="span"
                            >
                                {t(getDayOfWeek(i))}
                            </Typography>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        </Box>
    );
};

export default memo(WeekDates);
