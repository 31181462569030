import { styleProcessing } from "API/plugins/Utilities";

export const StyledOrderCard = (theme) => {
    let commonStyle = {
        width: "100%",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "12px",
        padding: "20px 20px 10px 20px ",
        borderRadius: "16px",
        backgroundColor: "#fff",
        boxSizing: "border-box",
        cursor: "pointer",
        boxShadow: "0px 4px 30px 0px rgba(106, 99, 118, 0.05)",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, padding: "12px 20px 16px 20px", gap: "14px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const SubjectAndDeadlineBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "7px",
};

export const TopContentWrapper = {
    display: "flex",
    justifyContent: "space-between",
};

export const BottomBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const Divider = { color: "#EEEEF0" };

export const BottomContentWrapper = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const AvatarContainer = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
};

export const PhotoHidden = {
    borderRadius: 10000,
    border: "1px dashed #BBBBC8",
    width: "36px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

export const PhotoHiddenText = {
    color: "#67677A",
    fontSize: "8px",
    textAlign: "center",
};

export const DeadlineBlock = {
    display: "flex",
    alignItems: "center",
    gap: "4px",
};
