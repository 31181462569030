import Button from "@mui/material/Button";
import MenuListComposition from "components/UI/PopperMenu";
import * as React from "react";
import Paper from "@mui/material/Paper";
import { List, ListItemButton } from "@mui/material";
import DropDownSVG from "assets/CommonAssets/SVG/DropDownSVG";
import * as styles from "components/SettingsBodyBlock/StudentSettings/Profile/ProfileForms/ProfileFormsThemes";
import CheckMark from "assets/CommonAssets/SVG/CheckMark";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import SettingsStore from "store/SettingsStore";

const EditBirthYearContent = ({ setOpenYearPopper }) => {
    const handleChooseBirthYear = (value, event) => {
        SettingsStore.setTemporaryProfileInfo("birthYear", value);
        setOpenYearPopper(false);
    };

    return (
        <Paper sx={styles.EditContentPaper}>
            <List sx={styles.EditContentList}>
                {SettingsStore.yearsList.map((year, index) => (
                    <ListItemButton
                        key={index}
                        disableRipple
                        onClick={handleChooseBirthYear.bind(this, year)}
                        sx={styles.EditContentListItem(year === SettingsStore.yearsList[0])}
                    >
                        {year}
                        {year === SettingsStore.TemporaryUserInfo.birthYear && <CheckMark />}
                    </ListItemButton>
                ))}
            </List>
        </Paper>
    );
};

const EditBirthYear = observer(({ children, ...props }) => {
    const [openYearPopper, setOpenYearPopper] = React.useState(false);

    return (
        <MenuListComposition
            placement="bottom-start"
            open={openYearPopper}
            setOpen={setOpenYearPopper}
            content={<EditBirthYearContent setOpenYearPopper={setOpenYearPopper} />}
            zIndex={1}
            paperSx={styles.EditPaper("190px")}
        >
            <Button
                disableRipple
                sx={styles.EditButton(openYearPopper, SettingsStore.TemporaryUserInfo.birthYear === SettingsStore.yearsList[0], "80px")}
            >
                {SettingsStore.TemporaryUserInfo.birthYear}
                <DropDownSVG />
            </Button>
        </MenuListComposition>
    );
});

export default memo(EditBirthYear);
