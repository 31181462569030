import React from "react";
import { observer } from "mobx-react-lite";

import { IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import { IconBack } from "components/MiniChat/OneChat/SendMessage/SendMessageStyles";

import SendMessageIcon from "components/DiplomaChats/Icons/SendMessageIcon";

import FullChatStore from "store/FullChatStore";
import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";
import VideoChatStore from "store/VideoChatStore";

const SendMessageButton = ({
    openEditModalMessage,
    setOpenEditModalMessage,
    openReplyModalMessage,
    setOpenReplyModalMessage,
    sendJsonMessage,
    text,
    setText,
    message,
    changed,
    isDiploma,
    isVideo,
}) => {
    const handleSendMessage = () => {
        sendJsonMessage(message);
        setText("");
    };

    const handleApply = openReplyModalMessage
        ? () => {
              sendJsonMessage({
                  action: "send_message",
                  chat_id: isDiploma ? DiplomaStore.id : isVideo ? VideoChatStore.chatData.id : FullChatStore.id,
                  message: text,
                  reply_to_message: isDiploma ? DiplomaStore.selectedMessage.message_id : isVideo ? VideoChatStore.chatData.selectedMessage.message_id : FullChatStore.selectedMessage.message_id,
              });
              setText("");
              setOpenReplyModalMessage(false);
          }
        : () => {
              sendJsonMessage({
                  action: "edit_message",
                  chat_id: isDiploma ? DiplomaStore.id : isVideo ? VideoChatStore.chatData.id : FullChatStore.id,
                  message_id: isDiploma ? DiplomaStore.selectedMessage.message_id : isVideo ? VideoChatStore.chatData.selectedMessage.message_id : FullChatStore.selectedMessage.message_id,
                  message: text,
              });
              setText("");

              setOpenEditModalMessage(false);
          };

    return (
        <>
            {openEditModalMessage || openReplyModalMessage ? (
                <IconButton
                    disabled={!text}
                    sx={IconBack}
                    onClick={handleApply}
                >
                    <CheckIcon />
                </IconButton>
            ) : (
                <IconButton
                    disabled={!text}
                    sx={IconBack}
                    onClick={handleSendMessage}
                >
                    <SendMessageIcon color={changed ? "#6212FF" : "#BBBBC8"} />
                </IconButton>
            )}
        </>
    );
};

export default observer(SendMessageButton);
