import { styleProcessing } from "API/plugins/Utilities";
import { createTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

export const TeacherPersonalDataStep = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        maxWidth: "599px",
        width: "100%",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            boxSizing: "border-box",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherWelcomeBanner = (theme) => {
    let commonStyle = {
        borderRadius: "20px",
        background: "#6212ff",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        marginBottom: "60px",
        padding: "24px 30px",
        boxSizing: "border-box",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "16px 20px",
            marginBottom: "40px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherWelcomeBannerHeader = (theme) => {
    let commonStyle = {
        width: "459px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherPersonalData = (theme) => {
    let commonStyle = {
        marginBottom: "60px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            marginBottom: "40px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherPersTeacherQuestionnaireTopBlockonalData = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const TeacherPersonalDataForm = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        marginTop: "40px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            marginTop: "30px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherQuestionnaireDropDownEdit = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherQuestionnaireDropDownTitle = {
    marginTop: "2px",
    whiteSpace: "nowrap",
};

export const TeacherQuestionnaireBirthdayEdit = {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    width: "100%",
};

export const TeacherQuestionnaireTopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const FiltersCitiesTheme = (error) =>
    createTheme({
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": error ? "#d32f2f" : "#9E9EB0",
                        "--TextField-brandBorderHoverColor": error ? "#d32f2f" : "#6212FF",
                        "--TextField-brandBorderFocusedColor": error ? "#d32f2f" : "#6212FF",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                        "& .Mui-focused": {
                            background: "#FFFFFF",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&:before, &:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&:before": {
                            borderBottom: "1px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "1px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "1px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },

            MuiAutocomplete: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        ".MuiAutocomplete-popupIndicator:hover": {
                            background: "#FFFFFF",
                            color: "#6212FF",
                        },
                    },
                    option: {
                        '&[aria-selected="true"]': {
                            background: "#6212FF!important",
                            color: "#FFFFFF",
                        },
                        "&:hover": {
                            background: "#6212FF!important",
                            color: "#FFFFFF",
                        },
                        "&:hover div > span": {
                            color: "#FFFFFF!important",
                        },
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "130%",
                        fontFeatureSettings: "'tnum' on, 'lnum' on",
                        color: "#222222",
                        flex: "none",
                        order: 0,
                        flexGrow: 0,
                    },
                    clearIndicator: {
                        "&:hover": {
                            color: "#6212FF",
                            background: "white",
                        },
                    },
                },
            },

            MuiInputBase: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        boxSizing: "border-box",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "13px 16px",
                        gap: "4px",
                        width: "100%",
                        height: "44px",
                        flex: "none",
                        order: 1,
                        flexGrow: 0,
                        "&.MuiInputBase-root": {
                            border: "1px!important solid #9E9EB0",
                            height: "34px",
                            borderRadius: "12px",
                            width: "100%",
                            paddingLeft: 0,
                            paddingTop: 0,
                        },
                        ".MuiInputBase-input": {
                            width: "-webkit-fill-available!important",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "150%",
                            fontFeatureSettings: "'tnum' on, 'lnum' on",
                            color: "#222222",
                            flex: "none",
                            order: 0,
                            flexGrow: 0,
                        },
                    },
                },
            },
        },
    });

export const DropDownOuterBox = (condition) => {
    return {
        width: condition ? "424px" : "100%",
    };
};
