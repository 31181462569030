import { createTheme } from "@mui/material/styles";

export const ratingContainerStyle = {
    display: "flex",
    gap: 40,
};

export const starContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    width: 249,
    height: 112,
    padding: "16px 24px 16px 24px",
    boxSizing: "border-box",
    borderRadius: "20px!important",
    border: "1px solid var(--grey-dark-separator, #DADADF)",
};

export const starRowStyle = {
    display: "flex",
    alignItems: "center",
    gap: 4,
};

export const ratingTextStyle = {
    color: "var(--txt-black, #171717)",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "140%",
};

export const subTextStyle = {
    color: "var(--text-grey, #9E9EB0)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const starsLinearContainerStyle = {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 2,
};

export const ratingLineStyle = (medias) => {
    return {
        display: "flex",
        width: medias.sm ? "538px" : "320px",
        height: 21,
        alignItems: "center",
        gap: "15px",
    };
};

export const ratingLinePreLabelStyle = (value) => ({
    width: "65px",
    whiteSpace: "nowrap",
    color: value ? "var(--txt-black, #222)" : "var(--grey-light-grey, #BBBBC8)",
    fontDize: "14px",
    fontDtyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
});

export const ratingLinePostLabelStyle = (value) => ({
    width: "36px",
    color: value ? "var(--txt-black, #222)" : "var(--grey-light-grey, #BBBBC8)",
    fontDize: "14px",
    fontDtyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
});

export const RatingProgressLinearTheme = createTheme({
    components: {
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    width: "407px",
                    background: "#EEEEF0",
                    borderRadius: "4px!important",
                    ".MuiLinearProgress-bar": {
                        background: "#F1B063",
                    },
                },
            },
        },
    },
});
