import { makeAutoObservable } from "mobx";
import RequestService from "API/services/RequestService";
import { findChatById, get } from "components/ChatBodyBlock/CommonData/helper_functions";
const requestService = new RequestService();

class VideoChatStore {
    call = null;

    countdownEnded = false;
    callActive = false;

    moreActionsOptions = [];
    moreActionsOption = null;

    videoRef = null;

    managementPanel = {
        microState: false,
        videoState: false,
        demoState: false,
        chatState: false,
        moreState: false,
    };

    chatData = {
        id: "",
        tabsData: "",
        lastMessage: "",
        noScroll: false,
        participantId: "",
        selectedMessage: "",
        isMedia: "",
        newLoadedMessages: [],
    };

    positivesFeedbackOptions = [];
    negativesFeedbackOptions = [];

    feedback = {
        feedbackRating: null,
        feedbackMessage: "",
    };

    platformFeedback = {
        connectionFeedbackRating: null,
        positivesFeedbackRating: null,
        positivesFeedbackList: [],
        positivesFeedbackMessage: "",
        negativesFeedbackRating: null,
        negativesFeedbackList: [],
        negativesFeedbackMessage: "",
    };

    feedbackStep = 0;
    stepReady = false;

    timerId = null;

    constructor() {
        this.Init();
        makeAutoObservable(this);
    }

    debounce(callback, delay) {
        return (...args) => {
            if (this.timerID) {
                clearTimeout(this.timerID);
            }

            this.timerID = setTimeout(() => {
                callback.call(this, ...args);
            }, delay);
        };
    }

    MarkCountdownEnd() {
        this.countdownEnded = true;
    }

    SetCallTimer(value) {
        this.callActive = value;
    }

    SetNewLoadedMessages(value) {
        this.chatData.newLoadedMessages = value;
    }

    SetCapture(mode, value) {
        if (mode === "stop") {
            let tracks = this.videoRef.srcObject.getTracks();

            tracks.forEach((track) => track.stop());
        }

        this.videoRef.srcObject = value;
    }

    SetFeedbackRating(value) {
        this.feedback.feedbackRating = value;
        this.CalcStepReadyCondition();
    }

    SetFeedbackMessage(value) {
        this.feedback.feedbackMessage = value;
        this.CalcStepReadyCondition();
    }

    GetForScrollMessagesNoLimit(sendJsonMessage) {
        if (this.chatData.tabsData.messages.length > 0) {
            this.SetLastMessage(this.chatData.tabsData.messages[this.chatData.tabsData.messages.length - 1].message_id);
            sendJsonMessage({
                action: "get_fix_messages",
                chat_id: this.chatData.id,
                start: this.chatData.tabsData.messages[this.chatData.tabsData.messages.length - 1].message_id,
            });
        } else this.SetNoScroll(false);
    }

    AddMessagesToChat(data) {
        if (data.messages.length > 0) {
            data.messages.map((item) => this.chatData.tabsData.messages.push(item));
        } else this.SetNoScroll(false);
    }

    SetSelectedMessage(value) {
        this.chatData.selectedMessage = value;
    }

    SetLastMessage(value) {
        this.chatData.lastMessage = value;
    }

    SetNoScroll(value) {
        this.chatData.noScroll = value;
    }

    SetTabsData(value) {
        this.chatData.tabsData = value;
    }

    SetChatID(value) {
        this.chatData.id = value;
    }

    SetParticipantID(value) {
        this.chatData.participantId = value;
    }

    SendIsWrite(sendJsonMessage, isWrite) {
        sendJsonMessage({
            action: "typing_message",
            chat_id: this.chatData.id,
            typing: isWrite,
        });
    }

    SetIsMedia(value) {
        this.chatData.isMedia = value;
    }

    ProcessMessages(sendMessage, sendJsonMessage, lastMessage, lastJsonMessage) {
        if (!lastMessage) {
            get("token").then((token) =>
                sendJsonMessage({
                    id: 1,
                    token: token,
                }),
            );
        } else {
            if ("status" in lastJsonMessage) {
                if (lastJsonMessage?.status !== "Ok") {
                    console.log("invalid token");
                    return;
                } else {
                    sendJsonMessage({
                        action: "get_data",
                        lesson_uuid: "437fe310-e902-4046-bb29-1fa6a19021ba",
                    });
                }
            }

            if (lastJsonMessage?.meta_info?.action === "get_links") {
                this.tabsData.chats.forEach((chat, i) => {
                    if (chat.chat_id === this.id) {
                        this.tabsData.chats[i].attachments = lastJsonMessage.data;
                        this.SetSelectedChatAttachments(lastJsonMessage.data);
                    }
                });
            }

            if ("meta_info" in lastJsonMessage) {
                console.log(lastJsonMessage);
                let reaction = null;

                let message_id = null;

                let chat_id = null;

                let uuid = null;

                let editedMessage = null;

                let edited = null;

                switch (lastJsonMessage.meta_info.action) {
                    case "get_data":
                        console.log(lastJsonMessage.data);

                        this.SetTabsData(lastJsonMessage.data);

                        if (this.chatData.id === "") this.SetChatID(lastJsonMessage.data.chat_id);

                        if (this.chatData.participantId === "") this.SetParticipantID(lastJsonMessage.data.participants_info[0].uuid);

                        break;
                    case "reaction_to_message":
                        reaction = lastJsonMessage.data.reaction;

                        message_id = lastJsonMessage.data.message_id;

                        uuid = lastJsonMessage.data.uuid;

                        this.chatData.tabsData.messages = this.chatData.tabsData.messages.map((message) =>
                            message.message_id === message_id
                                ? {
                                      ...message,
                                      reactions: /*message.reactions.length ? [...message.reactions, { reaction: reaction, uuid: uuid }] : */ [{ reaction: reaction, uuid: uuid }],
                                  }
                                : message,
                        );
                        break;
                    case "cancel_reaction_to_message":
                        message_id = lastJsonMessage.data.message_id;

                        chat_id = lastJsonMessage.data.chat_id;

                        this.tabsData.chats = this.tabsData.chats.map((chat) =>
                            chat.chat_id === chat_id
                                ? {
                                      ...chat,
                                      messages: chat.messages.map((message) =>
                                          message.message_id === message_id
                                              ? {
                                                    ...message,
                                                    reactions: [],
                                                }
                                              : message,
                                      ),
                                  }
                                : chat,
                        );
                        break;
                    case "send_message":
                        chat_id = lastJsonMessage.data.chat_id;

                        this.chatData.tabsData.messages = [lastJsonMessage.data, ...this.chatData.tabsData.messages];
                        break;
                    case "edit_message":
                        console.log(lastJsonMessage.data);

                        chat_id = lastJsonMessage.data.chat_id;

                        message_id = lastJsonMessage.data.message_id;

                        editedMessage = lastJsonMessage.data.message;

                        edited = lastJsonMessage.data.edited;

                        this.chatData.tabsData.messages = this.chatData.tabsData.messages.map((message) =>
                            message.message_id === message_id ? { ...message, message: editedMessage, edited: edited } : message,
                        );
                        break;
                    case "delete_message":
                        chat_id = lastJsonMessage.data.chat_id;

                        message_id = lastJsonMessage.data.message_id;

                        this.chatData.tabsData.messages = this.chatData.tabsData.messages.filter((message) => message.message_id !== message_id);
                        break;
                    case "updated_data":
                        this.SetTabsData(lastJsonMessage.data);
                        break;
                    case "search_message":
                        this.SetFoundMessages(lastJsonMessage.data);
                        this.GetForScrollMessages(sendJsonMessage);
                        break;
                    case "scroll_to_message":
                        this.AddMessagesToChat(lastJsonMessage.data);
                        break;
                    case "typing_message":
                        if (lastJsonMessage.typing_message.user_id === this.chatData.participantId) {
                            this.SetIsWrite(lastJsonMessage.typing_message.typing);
                        }

                        break;
                    case "get_fix_messages":
                        this.AddMessagesToChat(lastJsonMessage.data);
                        this.SetNewLoadedMessages(lastJsonMessage.data.messages);
                        break;
                    default:
                        break;
                }
            }
        }
    }

    async AddCallFeedback() {
        //отправка данных по оценке занятия
        this.ClearModalFeedback();
    }

    ClearModalFeedback() {
        this.feedback.feedbackMessage = "";
        this.feedback.feedbackRating = null;
    }

    SetConnectionFeedbackRating(value) {
        this.platformFeedback.connectionFeedbackRating = value;
        this.CalcStepReadyCondition();
    }

    SetPositivesFeedbackRating(value) {
        this.platformFeedback.positivesFeedbackRating = value;
        this.CalcStepReadyCondition();
    }

    SetPositivesFeedbackList() {
        this.platformFeedback.positivesFeedbackList = [...this.positivesFeedbackOptions];
        this.CalcStepReadyCondition();
    }

    SetPositivesFeedbackMessage(value) {
        this.platformFeedback.positivesFeedbackMessage = value;
        this.CalcStepReadyCondition();
    }

    SetNegativesFeedbackRating(value) {
        this.platformFeedback.negativesFeedbackRating = value;
        this.CalcStepReadyCondition();
    }

    SetNegativesFeedbackList() {
        this.platformFeedback.negativesFeedbackList = [...this.negativesFeedbackOptions];
        this.CalcStepReadyCondition();
    }

    SetNegativesFeedbackMessage(value) {
        this.platformFeedback.negativesFeedbackMessage = value;
        this.CalcStepReadyCondition();
    }

    ToggleChip(mode, value) {
        this[`${mode}FeedbackOptions`] = this[`${mode}FeedbackOptions`].map((x) => {
            return x.ID === value.ID ? { ...x, Checked: !x.Checked } : x;
        });
    }

    SetFeedbackStep(value) {
        this.feedbackStep = value;
    }

    CalcStepReadyCondition() {
        switch (this.feedbackStep) {
            case 0:
                this.stepReady = !!(this.feedback.feedbackRating && this.feedback.feedbackMessage);
                break;
            case 1:
                this.stepReady = !!this.platformFeedback.connectionFeedbackRating;
                break;
            case 2:
                let flagPositives = false;

                if (this.platformFeedback.positivesFeedbackList.find((value) => value.ID === 4 && value.Checked)) {
                    flagPositives = this.platformFeedback.positivesFeedbackMessage;
                } else {
                    flagPositives = this.platformFeedback.positivesFeedbackList.find((value) => value.Checked);
                }

                this.stepReady = !!(this.platformFeedback.positivesFeedbackRating && flagPositives);
                break;
            case 3:
                let flagNegatives = false;

                if (this.platformFeedback.negativesFeedbackList.find((value) => value.ID === 4 && value.Checked)) {
                    flagNegatives = this.platformFeedback.negativesFeedbackMessage;
                } else {
                    flagNegatives = this.platformFeedback.negativesFeedbackList.find((value) => value.Checked);
                }

                this.stepReady = !!(this.platformFeedback.negativesFeedbackRating && flagNegatives);
                break;
            default:
                this.stepReady = false;
        }
    }

    SetMicroState(value) {
        this.managementPanel.microState = value;
    }

    SetVideoState(value) {
        this.managementPanel.videoState = value;
    }

    SetDemoState(value) {
        this.managementPanel.demoState = value;
    }

    SetChatState(value) {
        this.managementPanel.chatState = value;
    }

    SetMoreState(value) {
        this.managementPanel.moreState = value;
    }

    Init() {
        this.Load();
    }

    Load() {
        this.call = {
            AvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
            FIO: "Александра Абрамова",
            StartDateTime: new Date(2024, 1, 14, 21, 41),
            CallTheme: "Занятие по английскому языку",
            StudentAvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
            StudentName: "Анна",
        };

        this.moreActionsOptions = [
            { ID: 0, actionName: "Настройки" },
            { ID: 1, actionName: "Поддержка StudyBuddy" },
        ];
        this.moreActionsOption = this.moreActionsOptions[0];

        this.positivesFeedbackOptions = [
            {
                ID: 0,
                Query: "Скорость соединения",
                Checked: false,
            },
            {
                ID: 1,
                Query: "Качество звука",
                Checked: false,
            },
            {
                ID: 2,
                Query: "Стабильность работы",
                Checked: false,
            },
            {
                ID: 3,
                Query: "Интерфейс",
                Checked: false,
            },
            {
                ID: 4,
                Query: "Свой вариант",
                Checked: false,
            },
        ];

        this.negativesFeedbackOptions = [...this.positivesFeedbackOptions];
    }
}

export default new VideoChatStore();
