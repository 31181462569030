import * as React from "react";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";

import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import { Box, Stack, Typography } from "@mui/material";

import IncomingMessageCard from "./IncomingMessageCard/IncomingMessageCard";
import OutcomingMessageCard from "./OutcomingMessageCard/OutcomingMessageCard";
import { findChatById, getDateGroups, reverseMassiv } from "components/ChatBodyBlock/CommonData/helper_functions";
import PartnerPanel from "./PartnerPanel/PartnerPanel";
import SendMessageBox from "./BotomFieldChat/SendMessageBox";
import SearchMessagesResult from "./SearchMessages/SearchMessagesResult";
import SearchMessagesPanel from "./SearchMessages/SearchMessagesPanel";

import FullChatStore from "store/FullChatStore";

import Evgen from "assets/ChatAssets/PNG/Evgen.png";
import Dima from "assets/ChatAssets/PNG/Dima.png";
import Konstantin from "assets/ChatAssets/PNG/Konstantin.png";
import Boris from "assets/ChatAssets/PNG/Pavel.png";
import ReplyOrEditInfo from "./ReplyOrEditInfo/ReplyOrEditInfo";
import SearchMessageResult from "../../MiniChat/OneChat/SearchMessages/SearchMessageResult";
import * as textStyles from "themes/TextThemes";
import NoChatSelected from "assets/MiniChat/SVG/NoChatSelected";
import PinnedMessagePanel from "./PinedMessage/PinnedMessagePanel";
import * as themes from "./GridFieldChatThemes";

const avatar = {
    Дмитрий: Dima,
    Константин: Konstantin,
    Борис: Boris,
    Егор: Evgen,
};

const GridFieldChat = observer(({ children, ...props }) => {
    const boxRef = useRef(null);

    const [openEditModalMessage, SetOpenEditModalMessage] = useState(false);

    const [openReplyModalMessage, SetOpenReplyModalMessage] = useState(false);

    const [disable, SetDisable] = useState(false);

    const [text, SetText] = useState("");

    const [messagesList, SetMessagesList] = useState([]);

    const [dateGroups, SetDateGroups] = useState([]);

    const chatData = useMemo(() => {
        return findChatById(FullChatStore.tabsData.chats, FullChatStore.id);
    }, [FullChatStore.tabsData, FullChatStore.id]);

    const Load = () => {
        const scrollElement = boxRef.current;

        if (scrollElement.scrollTop === 0) {
            FullChatStore.SetNoScroll(true);
            FullChatStore.GetForScrollMessagesNoLimit(props.sendJsonMessage);
        }
    };

    React.useEffect(() => {
        if (FullChatStore.toScrollMessage) {
            const scrollElement = boxRef.current;

            const elto = document.getElementById(FullChatStore.toScrollMessage);

            scrollElement.scrollTop = elto.offsetTop - 300;

            FullChatStore.SetToScrollMessage(null);
        }
    }, [FullChatStore.toScrollMessage]);

    useEffect(() => {
        if (FullChatStore.id) {
            console.log("reload effect");
            let messagesListReversed = reverseMassiv(FullChatStore.tabsData, FullChatStore.id);

            let dateGroupsFromList = getDateGroups(messagesListReversed);

            SetMessagesList(messagesListReversed);
            SetDateGroups(dateGroupsFromList);
        }
    }, [FullChatStore.tabsData.chats, FullChatStore.id, FullChatStore.newLoadedMessages, FullChatStore.change]);

    useEffect(() => {
        if (messagesList.length) {
            if (FullChatStore.toScrollMessageWait) {
                const scrollElement = boxRef.current;

                const elto = document.getElementById(FullChatStore.toScrollMessageWait);

                const elementOffset = elto.offsetTop;

                scrollElement.scrollTop = elementOffset - 300;

                FullChatStore.SetToScrollMessageWait(null);
            } else {
                let lastMessageOwner = messagesList.at(-1).owner.uuid;

                let isScroolledUp = FullChatStore.newLoadedMessages?.length;

                if (FullChatStore.noScroll) {
                    FullChatStore.SetNoScroll(false);
                    if (FullChatStore.lastMessage) {
                        const scrollElement = boxRef.current;

                        const elto = document.getElementById(FullChatStore.lastMessage);

                        const elementOffset = elto.offsetTop - 300;

                        scrollElement.scrollTop = elementOffset;
                    }
                } else if (lastMessageOwner === FullChatStore.tabsData.user_id && !isScroolledUp) {
                    const scrollElement = boxRef.current;

                    scrollElement.scrollTop = scrollElement.scrollHeight;
                } else {
                    FullChatStore.SetNewLoadedMessages(undefined);
                }
            }
        }
    }, [messagesList.length]);

    const [page, SetPage] = useState(1);

    return (
        <Box sx={themes.GridFieldChat}>
            {FullChatStore.id ? (
                <>
                    <PartnerPanel
                        avatar={avatar}
                        sendJsonMessage={props.sendJsonMessage}
                    />
                    <PinnedMessagePanel sendJsonMessage={props.sendJsonMessage} />
                    <Stack sx={themes.MessagesContainer(FullChatStore.currPinnedMessageId !== null, FullChatStore.isBlocked !== false || FullChatStore.isBlockedBy !== false)}>
                        <Stack sx={themes.SearchMessagesContainer}>
                            <SearchMessagesPanel
                                sendJsonMessage={props.sendJsonMessage}
                                page={page}
                                SetPage={SetPage}
                            />
                            <SearchMessagesResult page={page} />
                        </Stack>
                        <Box
                            ref={boxRef}
                            sx={themes.Messages}
                            onScroll={Load}
                        >
                            {dateGroups.map((group, groupIndex) =>
                                messagesList.map((message, messageIndex) => (
                                    <Box key={groupIndex + messageIndex}>
                                        {messageIndex === 0 && (
                                            <Box sx={themes.MessageGroup}>
                                                <Typography sx={textStyles.Caption1Grey}>{group}</Typography>
                                            </Box>
                                        )}
                                        {message.formattedDate === group &&
                                            (FullChatStore.tabsData.user_info.uuid === message.owner.uuid ? (
                                                <Box id={message.message_id}>
                                                    {" "}
                                                    <OutcomingMessageCard
                                                        message={message}
                                                        chat_id={FullChatStore.id}
                                                        disable={disable}
                                                        handleDisable={SetDisable}
                                                        handleOpenEditModalMessage={SetOpenEditModalMessage}
                                                        handleOpenReplyModalMessage={SetOpenReplyModalMessage}
                                                        handleText={SetText}
                                                        reply_to_message={
                                                            "reply_to_message" in message && message.reply_to_message
                                                                ? findChatById(FullChatStore.tabsData.chats, FullChatStore.id).messages.find(
                                                                      (element) => element.message_id === message.reply_to_message,
                                                                  )?.message
                                                                : null
                                                        }
                                                    />{" "}
                                                </Box>
                                            ) : (
                                                <Box id={message.message_id}>
                                                    {" "}
                                                    <IncomingMessageCard
                                                        message={message}
                                                        chat_id={FullChatStore.id}
                                                        disable={disable}
                                                        handleDisable={SetDisable}
                                                        handleOpenReplyModalMessage={SetOpenReplyModalMessage}
                                                        handleText={SetText}
                                                    />
                                                </Box>
                                            ))}
                                    </Box>
                                )),
                            )}
                        </Box>
                        {(openEditModalMessage || openReplyModalMessage) && (
                            <Box sx={themes.ReplyOrEditContainer}>
                                <ReplyOrEditInfo
                                    handleOpenEditModalMessage={SetOpenEditModalMessage}
                                    handleOpenReplyModalMessage={SetOpenReplyModalMessage}
                                    handleText={SetText}
                                    handleDisable={SetDisable}
                                />
                            </Box>
                        )}
                    </Stack>
                    <Box sx={themes.BottomChat}>
                        {FullChatStore.isBlocked !== false ? (
                            <Box sx={themes.BlockedContainer}>
                                <Typography sx={textStyles.Body2Black}>Переписка недоступна, специалист заблокирован</Typography>
                            </Box>
                        ) : FullChatStore.isBlockedBy !== false ? (
                            <Box sx={themes.BlockedContainer}>
                                <Typography sx={textStyles.Body2Black}>Переписка недоступна, вас заблокировали</Typography>
                            </Box>
                        ) : (
                            <Box sx={themes.TypeMessageContainer}>
                                <Box>{FullChatStore.isWrite && <Typography sx={themes.TypingText}>{chatData.participants_info[0].name} печатает...</Typography>}</Box>
                                <SendMessageBox
                                    disable={disable}
                                    setDisable={SetDisable}
                                    text={text}
                                    setText={SetText}
                                    sendJsonMessage={props.sendJsonMessage}
                                    openReplyModalMessage={openReplyModalMessage}
                                    setOpenReplyModalMessage={SetOpenReplyModalMessage}
                                    openEditModalMessage={openEditModalMessage}
                                    setOpenEditModalMessage={SetOpenEditModalMessage}
                                />
                            </Box>
                        )}
                    </Box>
                </>
            ) : (
                <Box sx={themes.NoChatSelectedContainer}>
                    <Box>
                        <NoChatSelected />
                    </Box>
                    <Box>Выберите чат, чтобы начать переписку</Box>
                </Box>
            )}
        </Box>
    );
});

export default memo(GridFieldChat);
