import { styleProcessing } from "API/plugins/Utilities";

export const PaymentInfo = (theme) => {
    let commonStyle = {
        background: "#ffffff",
        maxWidth: "435px",
        width: "100%",
        height: "fit-content",
        padding: "30px",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "40px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const PaymentMethods = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const PaymentTitleAndMethods = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const headerWrapper = { display: "flex", alignItems: "center" };

export const IconButton = { width: "24px", height: "24px", padding: 0, marginRight: "16px", borderRadius: "50%", border: "solid 1px #222222", color: "red" };

export const Agreements = {
    display: "flex",
    alignItems: "flex-start",
    gap: "10px",
};

export const CheckBoxAgreement = {
    marginTop: "3px",
    padding: 0,
};

export const PayButton = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};
