import { styleProcessing } from "API/plugins/Utilities";

export const TeacherPersonalData = { maxWidth: "599px", width: "100%", boxSizing: "border-box" };

export const TeacherQuestionnaireTopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const TeacherQuestionnaireTitle = {
    color: "#222",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "140%",
};

export const TeacherQuestionnaireSubTitle = {
    maxWidth: "596px",
    width: "100%",
};

export const TeacherQuestionnaireExclamationBox = (condition, theme) => {
    let commonStyle = {
        borderRadius: "10px",
        background: "#f7f7f7",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "16px",
        marginTop: "30px",
        width: condition ? "none" : "566px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
            flexDirection: "column",
            boxSizing: "border-box",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherQuestionnaireExclamationInnerBox = {
    display: "flex",
    flexDirection: "row",
    gap: "14px",
};

export const TeacherQuestionnaireExclamationText = (theme) => {
    let commonStyle = {
        width: "300px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherQuestionnaireWeekDay = {
    "font-size": "16px",
    "font-weight": 500,
    "line-height": "150%", // 24px
};

export const Accordion = {
    maxWidth: "599px",
    border: "none",
    boxShadow: "none",
    "&.MuiPaper-root.MuiAccordion-root:before": {
        backgroundColor: "transparent",
    },
};

export const AccordionSummary = {
    padding: "14px 20px",
    minHeight: 0,
    borderRadius: "10px",
    border: "1px solid #BBBBC8",
    margin: 0,

    "&.MuiButtonBase-root.Mui-expanded": {
        borderRadius: "10px 10px 0px 0px",
        border: "1px solid #BBBBC8",
        background: "#F7F7F7",
        padding: "14px 20px",
        margin: 0,
    },
    "& .MuiAccordionSummary-content": {
        margin: 0,
    },
};

export const TeacherQuestionnaireChooseTimeBoxHided = {
    "font-size": "14px",
    "font-weight": 400,
    "line-height": "150%", // 21px
};

export const TeacherQuestionnaireWeekDayTitleRow = {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    margin: 0,
};

export const AccordionDetails = {
    padding: "20px",
    borderRadius: "0px 0px 10px 10px",
    borderRight: "1px solid #BBBBC8",
    borderBottom: "1px solid #BBBBC8",
    borderLeft: "1px solid #BBBBC8",
    background: "#FFF",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
};

export const TeacherQuestionnaireTimeOfDayRow = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
};

export const TeacherQuestionnaireTimeOfDayText = {
    color: "#67677a",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "150%", // 21px
};

export const TeacherQuestionnaireTimingsRow = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "row",
        gap: "16px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexWrap: "wrap",
            gap: "12px",
            justifyContent: "flex-end",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherQuestionnaireTimeButton = {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "150%", // 24px
    width: "64px",
    height: "32px",
    borderRadius: "4px",
};

export const TeacherQuestionnaireChooseButtonsBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
};

export const TeacherQuestionnaireChooseButtons = {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    marginTop: "5px",
    width: "fit-content",
};

export const SettingsProfileBlockLeftPanelTimezoneEdit = { marginTop: "30px" };

export const ExclamationBox = {
    marginTop: "2px",
};

export const WeekDayContainer = {
    maxWidth: "598px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    boxSizing: "border-box",
};

export const WeekDayOuterContainerAndTimezone = (condition, theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "60px",
        height: condition ? "auto" : "430px",
        overflow: condition ? "visible" : "scroll",
        paddingRight: condition ? "0" : "28px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "40px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const FilterAndDays = {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
};

export const TimeZoneBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "0px",
};

export const WeekdaysOuterContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
};
