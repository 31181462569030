import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./OrdersPlaceThemes";
import { Accordion, AccordionDetails, AccordionSummary, Chip, IconButton, Typography } from "@mui/material";
import OrdersSearchStore from "store/OrdersSearchStore";
import { CloseRounded, ExpandMore } from "@mui/icons-material";
import { ThemeProvider } from "@mui/material/styles";
import SingleChoiceLightFilter from "components/UI/SingleChoiceLightFilter";
import Box from "@mui/material/Box";

const OrdersPlace = observer(({ children, ...props }) => {
    const handleChange = () => {
        OrdersSearchStore.ToggleOrdersPlaceExpanded();
    };

    const handleDelete = (value, event) => {
        console.log(value);
        OrdersSearchStore.ClearOrdersPlace(value);
    };

    const processingOrdersPlace = (event, value) => {
        OrdersSearchStore.SetOrdersPlace(value);
    };

    return (
        <ThemeProvider theme={themes.OrdersPlaceAccordionTheme}>
            <Accordion
                expanded={OrdersSearchStore.ordersPlaceExpanded}
                onChange={handleChange}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography sx={themes.headerOrdersPlaceStyle}>Место занятий</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {OrdersSearchStore.selectedOrdersPlaceChipList.length > 0 ? (
                        <ThemeProvider theme={themes.OrdersChipTheme}>
                            <Box sx={themes.selectedOrdersPlaceContainerStyle}>
                                {OrdersSearchStore.selectedOrdersPlaceChipList.map((value) => (
                                    <Chip
                                        key={value.ID}
                                        label={value.OrdersPlace}
                                        deleteIcon={
                                            <IconButton onClick={handleDelete.bind(this, value)}>
                                                <CloseRounded></CloseRounded>
                                            </IconButton>
                                        }
                                        onDelete={handleDelete.bind(this, value)}
                                    />
                                ))}
                            </Box>
                        </ThemeProvider>
                    ) : null}
                    <SingleChoiceLightFilter
                        propOption={OrdersSearchStore.optionOrdersPlace}
                        propOptions={OrdersSearchStore.optionsOrdersPlace}
                        valueToShow={"OrdersPlace"}
                        valueToBind={"ID"}
                        processingChange={processingOrdersPlace}
                        themeWrapper={themes.FiltersOrdersPlaceTheme}
                        placeholder={"Выберите районы/станции метро"}
                        clearOnBlur={true}
                        disableClearable={false}
                        variant={"standard"}
                        freeSolo={true}
                    ></SingleChoiceLightFilter>
                </AccordionDetails>
            </Accordion>
        </ThemeProvider>
    );
});

export default memo(OrdersPlace);
