export const onlineStatusWrapper = {
    marginLeft: "8px",
    display: "flex",
    alignItems: "center",
};

export const onlineStatusText = {
    fontSize: "14px",
    color: "rgba(25, 178, 12, 1)",
    marginLeft: "6px",
};

export const onlinseStatusLastOnlineText = {
    fontSize: "14px",
    color: "rgba(158, 158, 176, 1)",
    marginLeft: "6px",
};
