const SearchIconGrey = function (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5 11C16.5 14.0376 14.0376 16.5 11 16.5C7.96243 16.5 5.5 14.0376 5.5 11C5.5 7.96243 7.96243 5.5 11 5.5C14.0376 5.5 16.5 7.96243 16.5 11ZM15.3974 16.4467C14.1956 17.4182 12.6657 18 11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 12.6657 17.4182 14.1956 16.4467 15.3974C16.4759 15.4191 16.5038 15.4432 16.5303 15.4697L19.5303 18.4697C19.8232 18.7626 19.8232 19.2374 19.5303 19.5303C19.2374 19.8232 18.7626 19.8232 18.4697 19.5303L15.4697 16.5303C15.4432 16.5038 15.4191 16.4759 15.3974 16.4467Z"
                fill="#9E9EB0"
            />
        </svg>
    );
};

export default SearchIconGrey;
