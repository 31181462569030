import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import CatalogStore from "store/CatalogStore";
import { ThemeProvider } from "@mui/material/styles";
import * as themes from "./LessonsTeacherSexThemes";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import FreeSingleChoiceFilter from "components/UI/FreeSingleChoiceFilter";
import { useMedias } from "../../../API/mediasHook";

const LessonsTeacherSex = observer(({ children, ...props }) => {
    const handleChange = () => {
        CatalogStore.ToggleLessonsTeacherSexExpanded();
    };

    const processingLessonsTeacherSex = (event, value) => {
        CatalogStore.SetLessonsTeacherSex(value);
    };

    const medias = useMedias();

    return (
        <ThemeProvider theme={themes.LessonsTeacherSexAccordionTheme(medias)}>
            <Accordion
                expanded={CatalogStore.lessonsTeacherSexExpanded}
                onChange={handleChange}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography sx={themes.headerLessonsTeacherSexStyle}>Пол репетитора</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FreeSingleChoiceFilter
                        propOption={CatalogStore.optionLessonsTeacherSex}
                        propOptions={CatalogStore.optionsLessonsTeacherSex}
                        valueToShow={"LessonsTeacherSex"}
                        valueToBind={"ID"}
                        processingChange={processingLessonsTeacherSex}
                        themeWrapper={themes.FiltersLessonsTeacherSexTheme}
                    ></FreeSingleChoiceFilter>
                </AccordionDetails>
            </Accordion>
        </ThemeProvider>
    );
});

export default memo(LessonsTeacherSex);
