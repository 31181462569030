import React from "react";
import { Modal, Grid, IconButton } from "@mui/material";
import Close from "assets/CommonAssets/SVG/close";
import * as styles from "./ModalSettingsSmallThemes";
import Box from "@mui/material/Box";
import { useMedias } from "API/mediasHook";
const ModalSettingsSmall = ({ children, ...props }) => {
    const medias = useMedias();

    const [hovered, setHovered] = React.useState(false);

    return (
        <Modal
            open={props.visible}
            onClose={props.handleClose}
            sx={styles.ModalSize(medias, props.width)}
        >
            <Grid
                sx={styles.modalSettingsSmall}
                container
            >
                <IconButton
                    onClick={props.handleClose}
                    disableRipple
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    <Close hovered={hovered} />
                </IconButton>
                <Box
                    sx={{
                        ...styles.modalSettingsSmallContent(medias, props.bg, props.pl, props.pr, props.pt, props.pb),
                    }}
                >
                    {children}
                </Box>
            </Grid>
        </Modal>
    );
};

export default ModalSettingsSmall;
