import * as styles from "components/SettingsBodyBlock/SettingsBodyBlockThemes";
import { Typography } from "@mui/material";
import * as textStyles from "themes/TextThemes";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import ModalImportantOnAccount from "./Modals/ModalImportantOnAccount";
import { useTranslation } from "react-i18next";
import ModalDeleteAccount from "./Modals/ModalDeleteAccount";
import SettingsStore from "store/SettingsStore";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import ModalEnterSMSCode from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/Modals/ModalEnterSMSCode";
import ModalAccountDeleted from "./Modals/ModalAccountDeleted";
import UserStore from "store/UserStore";
import { useNavigate } from "react-router-dom";

const DeleteAccount = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    //Important on account
    const [dialogImportantOnAccountOpen, SetDialogImportantOnAccountOpen] = useState(false);

    const showImportantOnAccountDialog = () => {
        SetDialogImportantOnAccountOpen(true);
    };

    const discardImportantOnAccountDialog = () => {
        SetDialogImportantOnAccountOpen(false);
    };

    const saveImportantOnAccountDialog = () => {
        SetDialogImportantOnAccountOpen(false);
    };

    //Delete account
    const [dialogDeleteAccountOpen, SetDialogDeleteAccountOpen] = useState(false);

    const showDeleteAccountDialog = () => {
        SetDialogDeleteAccountOpen(true);
        SettingsStore.setTemporaryPhoneNumberHelper(false);
        SettingsStore.setTemporaryPhoneNumber(null);
    };

    const discardDeleteAccountDialog = () => {
        SetDialogDeleteAccountOpen(false);
    };

    const saveDeleteAccountDialog = () => {
        SetDialogDeleteAccountOpen(false);
        showEnterSMSDialog();
    };

    //Enter SMS code
    const [dialogEnterSMSOpen, SetDialogEnterSMSOpen] = useState(false);

    const showEnterSMSDialog = () => {
        SetDialogEnterSMSOpen(true);
        SettingsStore.setSeconds(59);
        SettingsStore.setWrongSMSCode(false);
        SettingsStore.setSMSCode(null);
    };

    const discardEnterSMSDialog = () => {
        SetDialogEnterSMSOpen(false);
    };

    const saveEnterSMSDialog = () => {
        SetDialogEnterSMSOpen(false);
        UserStore.setAuth(false);
        showAccountDeletedDialog();
    };

    const backEnterSMSDialog = () => {
        SetDialogEnterSMSOpen(false);
        showDeleteAccountDialog();
    };

    //Account deleted
    const [dialogAccountDeletedOpen, SetDialogAccountDeletedOpen] = useState(false);

    const showAccountDeletedDialog = () => {
        SetDialogAccountDeletedOpen(true);
    };

    const discardAccountDeletedDialog = () => {
        SetDialogAccountDeletedOpen(false);
        navigate("/");
    };

    return (
        <Box sx={styles.DeleteAccount}>
            <Typography
                sx={textStyles.Button14Red}
                style={{ cursor: "pointer", width: "fit-content" }}
                onClick={showDeleteAccountDialog}
            >
                {t("main.settings.deleteAccountBlock.deleteAccount")}
            </Typography>

            <ModalImportantOnAccount
                open={dialogImportantOnAccountOpen}
                handleDiscard={discardImportantOnAccountDialog}
                handleSave={saveImportantOnAccountDialog}
            />

            <ModalDeleteAccount
                open={dialogDeleteAccountOpen}
                handleDiscard={discardDeleteAccountDialog}
                handleSave={saveDeleteAccountDialog}
            />

            <ModalEnterSMSCode
                open={dialogEnterSMSOpen}
                handleDiscard={discardEnterSMSDialog}
                handleSave={saveEnterSMSDialog}
                handleBack={backEnterSMSDialog}
            />

            <ModalAccountDeleted
                open={dialogAccountDeletedOpen}
                handleDiscard={discardAccountDeletedDialog}
            />
        </Box>
    );
});

export default memo(DeleteAccount);
