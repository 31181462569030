export const TeacherPersonalDataStep = {
    display: "flex",
    flexDirection: "column",
    maxWidth: "599px",
    width: "100%",
};

export const TeacherPersonalData = {
    marginBottom: "60px",
};

export const TeacherQuestionnaireTitle = {
    color: "#222",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "140%",
};

export const TeacherSwitcherContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
};

export const TeacherQuestionnaireSwitcherBlock = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
};

export const TeacherQuestionnaireSwitcherText = {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
};

export const TeacherQuestionnaireSwitcherTitle = {
    color: "#222",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "150%",
};

export const TeacherQuestionnaireSwitcherSubTitle = {
    color: "#67677a",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "150%",
};

export const FormControlLabel = { alignItems: "flex-start", margin: 0 };

export const IOSSwitch = { m: 1 };

export const warningBox = { marginTop: "20px" };

export const TeacherQuestionnaireExclamationBlock = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "12px",
};

export const TeacherQuestionnaireSwitcherExclamationText = {
    width: "568px",
};

export const DistrictBox = (condition) => {
    return {
        marginTop: condition > 0 ? "30px" : "0px",
    };
};
