import { createTheme } from "@mui/material/styles";

export const nameBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
    alignSelf: "stretch",
};

export const nameAndDateTextStyle = {
    alignSelf: "stretch",
    color: "var(--text-black, #222)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
};

export const RatingTheme = createTheme({
    components: {
        MuiRating: {
            styleOverrides: {
                root: {
                    marginLeft: -1,
                    ".MuiSvgIcon-root": {
                        width: "16px!important",
                        height: "16px!important",
                    },
                },
            },
        },
    },
});
