import { Box, Divider } from "@mui/material";
import React from "react";
import { RenewStyledBottText, RenewStyledListButton, RenewStyledTopText } from "./RenewOrderFilterListStyled";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import store from "store/RequestStore";
import filterStore from "store/FilterStore";

const RenewOrderFilterList = ({ label, text, i, id }) => {
    const renewOrderClick = () => {
        switch (label) {
            case "ВУЗ":
                store.setActiveStepIndex(2);
                store.setActiveSubStepIndex(1);
                break;
            case "Сколько осталось до экзамена":
                store.setActiveStepIndex(2);
                store.setActiveSubStepIndex(2);
                break;
            case "Где удобно заниматься":
                store.setActiveStepIndex(3);
                store.setActiveSubStepIndex(0);
                break;
            case "Место занятий":
                store.setActiveStepIndex(3);
                store.setActiveSubStepIndex(1);
                break;
            case "Количество занятий в неделю":
                store.setActiveStepIndex(4);
                store.setActiveSubStepIndex(0);
                break;
            case "Удобное время":
                store.setActiveStepIndex(4);
                store.setActiveSubStepIndex(1);
                break;
            case "Какой репетитор подходит":
                store.setActiveStepIndex(5);
                break;
            case "Пожелания к репетитору":
                store.setActiveStepIndex(6);
                break;
            case "Комментарии":
                store.setActiveStepIndex(7);
                break;
            case "Ваше место обучения?":
                store.setActiveStepIndex(11);
                store.setActiveSubStepIndex(1);
                break;
            case "Укажите срок сдачи работы":
                store.setActiveStepIndex(12);
                break;
            case "Детали работы":
                store.setActiveStepIndex(13);
                break;
            case "Дополнительные требования":
                store.setActiveStepIndex(14);
                break;
            case "Сопутствующие услуги":
                store.setActiveStepIndex(15);
                break;
            case "На какой бюджет рассчитываете?":
                store.setActiveStepIndex(16);
                break;
            default:
                break;
        }
    };

    let cursor = "";

    if (i <= 3) {
        cursor = "";
    }

    if (i >= 3) {
        cursor = "pointer";
    }

    const goToAndToggleDrawer = (event) => {
        if (i >= 3) {
            event.stopPropagation();
            renewOrderClick();

            store.setEdited();
            filterStore.setCurrentId(id);
        }
    };

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: cursor,
                }}
                onClick={goToAndToggleDrawer}
            >
                <Box>
                    <RenewStyledTopText>{label}</RenewStyledTopText>
                    <RenewStyledBottText>{text + " "}</RenewStyledBottText>
                </Box>
                {i >= 3 && (
                    <RenewStyledListButton
                        variant="text"
                        endIcon={<ArrowForwardIosIcon sx={{ width: "12px" }} />}
                        onClick={goToAndToggleDrawer}
                    />
                )}
            </Box>
            <Divider sx={{ marginTop: "13px", color: "rgba(238, 238, 240, 1)" }} />
        </Box>
    );
};

export default RenewOrderFilterList;
