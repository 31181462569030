import Box from "@mui/material/Box";
import React, { memo } from "react";
import { Typography } from "@mui/material";
import CurrentLevel from "./CurrentLevel/CurrentLevel";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { useNavigate } from "react-router-dom";
import SButton from "../UI/SButton";
import BackAccountButton from "assets/CommonAssets/SVG/BackAccountButton";
import { useTranslation } from "react-i18next";
import * as textThemes from "themes/TextThemes";
import * as themes from "./TutorLevelBodyBlockThemes";
import CurrentPrivileges from "./CurrentPrivileges/CurrentPrivileges";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import ForecastedLevel from "./ForecastedLevel/ForecastedLevel";
import CurrentMonthAchievements from "./CurrentMonthAchievements/CurrentMonthAchievements";
import { useMedias } from "API/mediasHook";

const TutorLevelBodyBlock = ({ children, ...props }) => {
    const medias = useMedias();

    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    const handleBackClick = () => {
        navigate("/account");
    };

    const getBottomText = (type, number) => {
        const texts = {
            academicWorks: [
                "teacherAccount.privilegeProgram.noWorks",
                "teacherAccount.privilegeProgram.oneWork",
                "teacherAccount.privilegeProgram.fewWorks",
                "teacherAccount.privilegeProgram.manyWorks",
            ],
            conductedClasses: [
                "teacherAccount.privilegeProgram.noConductedClass",
                "teacherAccount.privilegeProgram.oneConductedClass",
                "teacherAccount.privilegeProgram.fewConductedClass",
                "teacherAccount.privilegeProgram.manyConductedClass",
            ],
            invitedUsers: [
                "teacherAccount.privilegeProgram.noInvitedUsers",
                "teacherAccount.privilegeProgram.oneInvitedUser",
                "teacherAccount.privilegeProgram.fewInvitedUsers",
                "teacherAccount.privilegeProgram.manyInvitedUsers",
            ],
        };

        if (!texts[type]) return null;

        if (number === 0) return texts[type][0];

        if (number === 1) return texts[type][1];

        if (number > 1 && number < 5) return texts[type][2];

        return texts[type][3];
    };

    return (
        <ScrollToTop>
            <Box sx={themes.TutorLevel}>
                <Box sx={themes.TutorLevelContent}>
                    <Box sx={themes.CurrentBlock}>
                        <Box sx={themes.TitleBlock}>
                            <SButton
                                variant="icon"
                                onClick={handleBackClick}
                            >
                                <BackAccountButton />
                            </SButton>
                            <Typography sx={medias.sm ? textThemes.HeadlineH3Black : textThemes.HeadlineH41Black}>{t("teacherAccount.privilegeProgram.tutorLevelTitle")}</Typography>
                        </Box>
                        <Box sx={themes.CurrentLevelContainer(medias)}>
                            <CurrentLevel />
                            <CurrentPrivileges />
                        </Box>
                    </Box>
                    <Box sx={themes.ForecastedBlock}>
                        <Typography
                            sx={textThemes.HeadlineH5Black}
                        >{`${t("teacherAccount.privilegeProgram.forecastedRatingFor")}${t(TeacherQuestionnaireStore.monthNames[new Date().getMonth() + 1])}`}</Typography>
                        <Box sx={themes.BottomBlocks(medias)}>
                            <ForecastedLevel />
                            <Box sx={themes.CurrentMonthAchievementsBlock(medias)}>
                                <Box sx={themes.ClassAndWorksBlock(medias)}>
                                    <CurrentMonthAchievements
                                        header="teacherAccount.privilegeProgram.classes"
                                        top={TeacherQuestionnaireStore.teacher.conductedClasses.points}
                                        bottom={getBottomText("conductedClasses", TeacherQuestionnaireStore.teacher.conductedClasses.number)}
                                        width="197px"
                                        number={TeacherQuestionnaireStore.teacher.conductedClasses.number}
                                    />
                                    <CurrentMonthAchievements
                                        header="teacherAccount.privilegeProgram.works"
                                        top={TeacherQuestionnaireStore.teacher.academicWorks.points}
                                        bottom={getBottomText("academicWorks", TeacherQuestionnaireStore.teacher.academicWorks.number)}
                                        width="197px"
                                        number={TeacherQuestionnaireStore.teacher.academicWorks.number}
                                    />
                                </Box>
                                <CurrentMonthAchievements
                                    header="teacherAccount.privilegeProgram.invitedUsers"
                                    top={TeacherQuestionnaireStore.teacher.invitedUsers.points}
                                    bottom={getBottomText("invitedUsers", TeacherQuestionnaireStore.teacher.invitedUsers.number)}
                                    width="455px"
                                    number={TeacherQuestionnaireStore.teacher.invitedUsers.number}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ScrollToTop>
    );
};

export default memo(TutorLevelBodyBlock);
