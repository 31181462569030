import * as React from "react";
import { Box, Typography, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import * as styles from "./ForgotPasswordThemes";
import * as textStyles from "themes/TextThemes";
import SButton from "components/UI/SButton";
import AuthorizationStore from "store/AuthorizationStore";
import { useNavigate } from "react-router-dom";
import { BackIcon } from "assets/Authorization/SVG/BackIcon";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";
import Logo from "components/UI/Logo/logo";

const ForgotPassword = observer(({ setCurrent, style, current }) => {
    const { t, i18n } = useTranslation();

    const medias = useMedias();

    const navigate = useNavigate();

    const handleBack = () => {
        AuthorizationStore.setCurrentAuthorizationStep("SignIn");
        AuthorizationStore.setTemporaryPhoneNumber(null);
        AuthorizationStore.setTemporaryEmail("");
        AuthorizationStore.setTemporaryPassword("");
        AuthorizationStore.setSignInTab(1);
    };

    const handleChangeEmail = (e) => {
        AuthorizationStore.setResetEmail(e.target.value);
        AuthorizationStore.setResetEmailError(false);
    };

    const handleClickSend = async () => {
        await AuthorizationStore.handleRecoverPassword(navigate);
    };

    return (
        <Box sx={styles.ForgotPassword(medias)}>
            {!medias.sm && (
                <Box sx={styles.LogoPos}>
                    <Logo />
                </Box>
            )}
            <Box sx={styles.ForgotPasswordContent(medias)}>
                <Box sx={styles.TopBlock(medias)}>
                    <SButton
                        variant="icon"
                        style={styles.BackIcon(medias)}
                        onClick={handleBack}
                    >
                        <BackIcon />
                    </SButton>
                    <Typography sx={medias.sm ? textStyles.HeadlineH3Black : textStyles.HeadlineH41Black}>Забыли пароль?</Typography>
                    <Typography sx={textStyles.Body2DarkGrey}>
                        Для восстановления пароля введите адрес эл.почты, на который вы регистрировались. Мы отправим письмо для восстановления пароля
                    </Typography>
                </Box>
                <Box sx={styles.EmailAndButton(medias)}>
                    <Box>
                        <Typography sx={textStyles.Body2Black}>Электронная почта</Typography>
                        <TextField
                            value={AuthorizationStore.resetEmail}
                            sx={styles.EmailField(AuthorizationStore.resetEmailError)}
                            error={AuthorizationStore.resetEmailError}
                            helperText={AuthorizationStore.resetEmailError ? "Пользователь с таким адресом электронной почты не зарегистрирован" : ""}
                            fullWidth
                            variant="standard"
                            name="email"
                            onChange={handleChangeEmail}
                            placeholder="Введите электронную почту"
                        />
                    </Box>
                    <SButton
                        variant="unshadowed"
                        padding="11px 24px 12px 24px"
                        onClick={handleClickSend}
                        disabled={AuthorizationStore.resetEmail === "" || AuthorizationStore.resetEmail === null}
                    >
                        Отправить
                    </SButton>
                </Box>
            </Box>
        </Box>
    );
});

export default memo(ForgotPassword);
