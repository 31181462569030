export const StudentTeacherPopperPaper = {
    width: "300px",
    mt: "12px",
    mr: "10px",
    backgroundColor: "white",
    borderRadius: "10px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "150%",
    border: "none",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
    p: 0,
};

export const StudentTeacherPopperMenuList = {
    padding: "13px 5px 12px 5px",
};

export const StudentTeacherPopperUpperGrid = {
    borderBottom: "1px solid #EEEEF0",
    padding: "0px 0px 8px 0px",
};

export const StudentTeacherPopperLowerGrid = {
    borderBottom: "1px solid #EEEEF0",
    padding: "8px 0px 8px 0px",
};

export const StudentTeacherPopperMenuItem = {
    borderRadius: "5px",
    ":hover": {
        color: "#6212FF",
    },
};

export const StudentTeacherSwitchAccountItem = {
    borderRadius: "5px",
    backgroundColor: "#F4F4F6",
    padding: "3px 0 3px 8px",
    display: "flex",
    gap: "13px",
    ":hover": {
        backgroundColor: "rgba(226, 223, 255, 0.50)",
    },
};

export const StudentTeacherSwitchAccountText = {
    display: "flex",
    flexDirection: "column",
};

export const StudentTeacherSwitchAccountContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    padding: "6px 0",
};

export const SwitchAccountLabelBox = {
    padding: "0px 0px 0px 16px",
};

export const PaymentIconBox = {
    color: "white",
    paddingLeft: "80px",
    paddingBottom: "4px",
};

export const BottomContainer = {
    padding: "8px 0px 0px 0px",
};

export const Photo = {
    width: "40px",
    height: "40px",
    borderRadius: "10px",
};
