import React, { memo } from "react";
import * as themes from "./CashbackFilterThemes";
import ButtonFilter from "components/UI/ButtonFilter";
import { CloseRounded } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import CatalogStore from "store/CatalogStore";
import CashbackIconDisable from "../../../../assets/CashbackAssets/SVG/CashbackIconDisable";
import { useMedias } from "../../../../API/mediasHook";
import CashbackIconEnable from "../../../../assets/CashbackAssets/SVG/CashbackIconEnable";

const CashbackFilter = observer(({ children, ...props }) => {
    const toggleCashback = (value) => {
        CatalogStore.SetCashback(value);
    };

    const medias = useMedias();

    const currentIcon = CatalogStore.cashback ? <CashbackIconEnable /> : <CashbackIconDisable />;

    return (
        <ButtonFilter
            valueToBind={CatalogStore.cashback}
            toggleFunc={toggleCashback}
            themeWrapper={themes.FiltersCashbackTheme(medias)}
            themeSpanSelected={themes.spanCashbackSelected}
            themeSpanDefault={themes.spanCashbackDefault}
            buttonText={medias.sm ? "Кэшбек" : ""}
            //icon={currentIcon}
            iconAppend={medias.sm ? <CloseRounded sx={themes.iconCashback} /> : null}
            iconPrepend={!medias.sm ? currentIcon : null}
            iconAppendAlwaysVisible={false}
            iconPrependAlwaysVisible={true}
            iconsPosition={"Double"}
        ></ButtonFilter>
    );
});

export default memo(CashbackFilter);
