import { styleProcessing } from "API/plugins/Utilities";

export const ClassItem = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "20px",
        alignItems: "flex-start",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, justifyContent: "space-between" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const SubjectAndAvatarWrapper = (theme) => {
    let commonStyle = {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, flexDirection: "column", width: "fit-content" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Avatar = {
    width: "24px",
    height: "24px",
};

export const StudentNameContainer = {
    display: "flex",
    gap: "4px",
    alignItems: "center",
};

export const StudentBlock = {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    width: "160px",
};

export const SubjectAimBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
};

export const PlaceSubjectBlock = {
    maxWidth: "231px",
    minWidth: "150px",
    width: "100%",
    display: "flex",
    gap: "8px",
    alignItems: "flex-start",
};

export const PlaceIconContainer = {
    paddingTop: "2px",
};

export const TooltipPopperProps = {
    sx: {
        "& .MuiTooltip-tooltip": {
            border: "none",
            color: "#222",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "140%",
            borderRadius: "10px",
            bgcolor: "#fff",
            maxWidth: "196px",
            boxShadow: "0px 2px 10px 0px rgba(106, 99, 118, 0.15)",
            padding: "10px",
        },
    },
};

export const TimeBlock = {
    width: "109px",
};

export const TimeAndSubjectBlock = {
    display: "flex",
    gap: "20px",
};
