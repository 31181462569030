import React from "react";

import { Stack, Typography } from "@mui/material";
import { VerifiedUser } from "@mui/icons-material";

import TimeIcon from "components/DiplomaChats/Icons/TimeIcon";

import * as styles from "pages/DiplomaChats/styles";
import { Caption1Black } from "themes/TextThemes";

const InfoAboutTutorAcc = () => {
    return (
        <Stack
            direction="column"
            spacing={0.75}
            justifyContent="flex-start"
        >
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
            >
                <VerifiedUser sx={{ ...styles.verifiedGreen, fontSize: "16px" }} />
                <Typography sx={Caption1Black}>подтверждены номер и почта</Typography>
            </Stack>
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
            >
                <TimeIcon />
                <Typography sx={Caption1Black}>на StudyBuddy c 19 декабря 2022</Typography>
            </Stack>
        </Stack>
    );
};

export default InfoAboutTutorAcc;
