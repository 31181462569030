import React, { memo } from "react";
import * as themes from "./StateTwoStyle";
import Bulb from "./bulb.png";
import Clock from "./clock.png";
import chatWindow from "./chatWindow.png";
import Calendar from "./calendar.png";
import { Box } from "@mui/material";

const StateTwo = ({ children, ...props }) => {
    return (
        <Box>
            <Box sx={themes.bulbIconContainer}>
                <img
                    style={themes.bulbIconImg}
                    src={Bulb}
                    alt="Bulb"
                />
            </Box>

            <Box sx={themes.clockIconContainer}>
                <img
                    style={themes.clockIconImg}
                    src={Clock}
                    alt="Clock"
                />
            </Box>

            <Box sx={themes.rateIcon}>
                <Box sx={themes.rateIconText}>5.0</Box>
            </Box>

            <Box sx={themes.bonusIcon}>
                <Box sx={themes.bonusIconText}>+35</Box>
            </Box>

            <Box sx={themes.chatWindowImg}>
                <img
                    src={chatWindow}
                    alt="chatWindow"
                />
            </Box>

            <Box sx={themes.CalendarImg}>
                <img
                    src={Calendar}
                    alt="Calendar"
                />
            </Box>
        </Box>
    );
};

export default memo(StateTwo);
