import { styleProcessing } from "API/plugins/Utilities";

export const logoThemes = (theme) => {
    let commonStyle = {
        padding: "0px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "56px",
            height: "24px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
