export const TeacherQuestionnaireBodyBlock = {
    background: "#FFF",
    maxWidth: 1440,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "0px 0px 40px 40px",
    margin: "0 auto",
};
