export const ModalButtonWrapper = {
    display: "flex",
    gap: "12px",
    justifyContent: "center",
};

export const Title = {
    textAlign: "center",
};

export const OpenOrderContent = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const OpenOrderModal = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    maxWidth: "320px",
};
