export const TypeBlock = (height, medias) => {
    return {
        height: height,
        width: medias.sm ? "312px" : "288px",
        borderRadius: "12px",
        background: "#FFF",
        gap: "12px",
        padding: medias.sm ? "40px" : "20px",
        display: "flex",
        flexDirection: "column",
    };
};
