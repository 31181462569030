export const timeColumn = {
    width: "34px",
    height: "80px",
    borderBottom: "none",
    verticalAlign: "top",
    padding: "0 10px 0 10px",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "17px",
    letterSpacing: "0em",
    textAlign: "right",
};

export const timeAndDayCell = {
    position: "relative",
    padding: "0",
    width: "161px",
    height: "81px",
    borderTop: "solid 1px rgba(238, 238, 240, 1)",
    borderRight: "solid 1px rgba(238, 238, 240, 1)",
    borderLeft: "solid 1px rgba(238, 238, 240, 1)",
    boxSizing: "border-box",
};

export const workDayBg = {
    background: "rgba(255, 255, 255, 1)",
};

export const dayOffBg = {
    background: "rgba(245, 245, 247, 1)",
};
