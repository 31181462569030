export const PaperStyles = {
    mt: "5px",
    backgroundColor: "white",
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
    p: 0,
};

export const MenuList = {
    padding: "8px 4px",
};

export const FoldersContainer = (UpdateFolders) => {
    return {
        borderBottom: UpdateFolders.length > 4 && "1px solid #EEEEF0",
        paddingBottom: UpdateFolders.length > 4 && "8px",
    };
};

export const MenuItem = {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    ":hover": {
        color: "#6212FF",
    },
    "&.MuiMenuItem-root": {
        minHeight: "32px",
        padding: "4px 12px",
    },
};

export const FoldersBox = (UpdateFolders) => {
    return {
        paddingTop: UpdateFolders.length > 4 && "8px",
        display: "flex",
        flexDirection: "column",
        gap: "4px",
    };
};

export const PlusIcon = {
    color: "white",
    paddingLeft: "50px",
    height: "24px",
    display: "flex",
    alignItems: "center",
};

export const TextStyles = {
    "& fieldset": { border: "none" },
    "& .MuiOutlinedInput-root": {
        fontSize: "14px",
        backgroundColor: "rgba(187, 187, 200, 0.17)",
        borderRadius: "8px",
        height: "32px",
    },
};

export const TeacherField = {
    height: "fit-content",
    borderRadius: "20px 20px 0px 0px",
    padding: "16px 20px",
};

export const IconBack = {
    height: "3.68vh",
    width: "3.68vh",
    padding: "0px",
    marginRight: "0px",
    marginTop: "0.62vh",
};

export const SearchBlock = {
    display: "flex",
    alignItems: "center",
    gap: "16px",
};
