import { Typography } from "@mui/material";
import Datepicker from "components/UI/CustomDatePicker/CustomDatePicker";
import SButton from "components/UI/SButton";
import React from "react";
import ModalSettings from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsBig/ModalSettings";
import * as themes from "./ModalBusyModeThemes";
import * as textThemes from "themes/TextThemes";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import TeacherSettingsStore from "store/TeacherSettingsStore";
import { observer } from "mobx-react-lite";
import DrawerHeader from "components/SettingsBodyBlock/StudentSettings/DrawerHeader/DrawerHeader";
import MyClassesDrawer from "components/MyClassesBodyBlock/MyClassesDrawer";
import { useMedias } from "API/mediasHook";

const ModalBusyMode = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        props.handleSave();
    };

    const TurnOffBusyMode = () => {
        TeacherSettingsStore.setDayUntilBusy("");
        props.handleDiscard();
    };

    const handleSetDate = (value) => {
        TeacherSettingsStore.setTemporaryDayUntilBusy(value);
    };

    const DrawerList = (
        <Box sx={themes.ModalBusyMode(medias)}>
            <DrawerHeader
                text={t("teacherSettings.accountActions.employmentMode")}
                handleClose={Close}
            />
            <Box sx={themes.PickerContainer}>
                <Typography sx={[textThemes.Body2Black, themes.mobilePadding(medias)]}>{t("teacherSettings.accountActions.untilWhichDate")}</Typography>
                <Datepicker
                    minDate={new Date()}
                    selectedDate={TeacherSettingsStore.temporaryDayUntilBusy}
                    setSelectedDate={handleSetDate}
                    opened={true}
                />
            </Box>
            {TeacherSettingsStore.dayUntilBusy !== "" ? (
                <SButton
                    variant="text"
                    onClick={TurnOffBusyMode}
                >
                    {t("teacherSettings.accountActions.turnOffBusyMode")}
                </SButton>
            ) : null}
            <Box sx={[themes.ButtonsContainer, themes.mobilePadding(medias)]}>
                <SButton
                    onClick={Close}
                    variant="secondary"
                    padding="10px 20px 11px 20px"
                    style={themes.Button}
                >
                    {t("main.cancel")}
                </SButton>
                <SButton
                    onClick={Save}
                    disabled={TeacherSettingsStore.temporaryDayUntilBusy === ""}
                    variant="unshadowed"
                    padding="10px 20px 11px 20px"
                    style={themes.Button}
                >
                    {t("main.save")}
                </SButton>
            </Box>
        </Box>
    );

    return (
        <>
            {medias.sm ? (
                <ModalSettings
                    visible={props.open}
                    handleClose={Close}
                    header={t("teacherSettings.accountActions.employmentMode")}
                >
                    <Box sx={themes.ModalBusyMode(medias)}>
                        <Box sx={themes.PickerContainer}>
                            <Typography sx={textThemes.Body2Black}>{t("teacherSettings.accountActions.untilWhichDate")}</Typography>
                            <Datepicker
                                minDate={new Date()}
                                selectedDate={TeacherSettingsStore.temporaryDayUntilBusy}
                                setSelectedDate={handleSetDate}
                                opened={true}
                            />
                        </Box>
                        {TeacherSettingsStore.dayUntilBusy !== "" ? (
                            <SButton
                                variant="text"
                                onClick={TurnOffBusyMode}
                            >
                                {t("teacherSettings.accountActions.turnOffBusyMode")}
                            </SButton>
                        ) : null}
                        <Box sx={themes.ButtonsContainer}>
                            <SButton
                                onClick={Close}
                                variant="secondary"
                                padding="10px 20px 11px 20px"
                                style={themes.Button}
                            >
                                {t("main.cancel")}
                            </SButton>
                            <SButton
                                onClick={Save}
                                disabled={TeacherSettingsStore.temporaryDayUntilBusy === ""}
                                variant="unshadowed"
                                padding="10px 20px 11px 20px"
                                style={themes.Button}
                            >
                                {t("main.save")}
                            </SButton>
                        </Box>
                    </Box>
                </ModalSettings>
            ) : (
                <MyClassesDrawer
                    open={props.open}
                    close={Close}
                >
                    {DrawerList}
                </MyClassesDrawer>
            )}
        </>
    );
});

export default memo(ModalBusyMode);
