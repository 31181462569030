import { memo } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import { Avatar, Box, Rating, Typography } from "@mui/material";
import MyClassesStore from "store/MyClassesStore";
import * as styles from "./ModalRateClassThemes";
import * as textStyles from "themes/TextThemes";
import SButton from "components/UI/SButton";
import { EmptyRatingIcon } from "assets/MyClassesAssets/SVG/EmptyRatingIcon";
import { RatingIcon } from "assets/MyClassesAssets/SVG/RatingIcon";
import TextfieldReview from "./TextfieldReview/TextfieldReview";
import { useMedias } from "API/mediasHook";

const ModalRateClass = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <ModalSettingsSmall
            visible={props.open}
            handleClose={props.handleDiscard}
        >
            <Box sx={styles.ModalRateClass(props.lesson.review === null)}>
                <Box sx={styles.ModalRateClassContent}>
                    <Box sx={styles.ClassDetails(medias, props.lesson.review)}>
                        <Avatar
                            src={props.lesson.photo}
                            sx={styles.TutorPhoto(medias, props.lesson.review)}
                        />
                        <Box sx={styles.ClassInfo}>
                            <Typography sx={textStyles.HeadlineH5Black}>{`${t("main.myClasses.rateClassTitle")}${props.lesson.tutor}`}</Typography>
                            <Typography sx={textStyles.Body1DarkGrey}>{MyClassesStore.getFromattedDateForRate(MyClassesStore.classes.indexOf(props.lesson))}</Typography>
                        </Box>
                    </Box>
                    <Box sx={styles.RatingContainer}>
                        <Typography sx={textStyles.HeadlineH5Black}>{t("main.myClasses.doYouLikeClass")}</Typography>
                        <Rating
                            iconSpacing={8}
                            emptyIcon={<EmptyRatingIcon />}
                            icon={<RatingIcon />}
                            value={MyClassesStore.temporaryRating}
                            onChange={(event, newValue) => {
                                MyClassesStore.setTemporaryRating(newValue);
                            }}
                            sx={styles.Rating}
                        />
                    </Box>
                    {props.lesson.review === null ? (
                        <Box sx={styles.Review}>
                            <Typography sx={textStyles.HeadlineH5Black}>{t("main.myClasses.pleaseLeaveReview")}</Typography>
                            <TextfieldReview
                                maxCharacters={1000}
                                handleChange={MyClassesStore.setTemporaryReview}
                                value={MyClassesStore.temporaryReview}
                                placeholder={t("main.myClasses.yourReview")}
                            />
                        </Box>
                    ) : null}
                </Box>
                <SButton
                    variant="unshadowed"
                    padding="10px 20px 11px 20px"
                    style={styles.ButtonSend}
                    onClick={props.handleSave}
                    disabled={MyClassesStore.temporaryRating === null}
                >
                    {t("main.send")}
                </SButton>
            </Box>
        </ModalSettingsSmall>
    );
});

export default memo(ModalRateClass);
