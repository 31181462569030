import { styleProcessing } from "API/plugins/Utilities";

export const PhotoButtons = {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
};

export const ProfilePhoto = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "row",
        gap: "30px",
        marginBottom: "30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const AvatarImg = (theme) => {
    let commonStyle = {
        borderRadius: "16px",
        width: "150px",
        height: "150px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100px",
            height: "100px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ProfilePhotoContent = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        maxWidth: "360px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            maxWidth: "278px",
            gap: "10px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
