import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import { Stack, Typography } from "@mui/material";

import SButton from "components/UI/SButton";

import SendForCorrectionModal from "./SendForCorrectionModal";
import EndOrderModal from "./EndOrderModal";

import UserStore from "store/UserStore";
import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";

const StudentRecievedWorkActions = () => {
    const showStudentRecievedWorkButtons = DiplomaStore.status === "waiting" && UserStore.role === "student";

    const [showCorrectionModal, setShowCorrectionModal] = useState(false);

    const [showEndOrderModal, setShowEndOrderModal] = useState(false);

    const handleShowCorrectionModal = () => {
        setShowCorrectionModal(true);
    };

    const handleShowEndOrderModal = () => {
        setShowEndOrderModal(true);
    };

    return showStudentRecievedWorkButtons ? (
        <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}
            marginBottom="10px"
        >
            <SButton
                variant="secondary"
                onClick={handleShowCorrectionModal}
            >
                Отправить на корректировку
            </SButton>
            <SButton style={{ height: "36px" }}>
                <Typography
                    variant="standardWhite"
                    onClick={handleShowEndOrderModal}
                >
                    Принять работу
                </Typography>
            </SButton>
            <SendForCorrectionModal
                open={showCorrectionModal}
                close={() => setShowCorrectionModal(false)}
            />
            <EndOrderModal
                open={showEndOrderModal}
                close={() => setShowEndOrderModal(false)}
            />
        </Stack>
    ) : null;
};

export default observer(StudentRecievedWorkActions);
