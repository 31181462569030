import { Box, Typography } from "@mui/material";
import MenuListComposition from "components/UI/PopperMenu";
import LanguageCurrencyModal from "./LanguageCurrencyModal/LanguageCurrencyModal";
import * as themes from "./LanguageSelectThemes";
import Button from "@mui/material/Button";
import * as textThemes from "themes/TextThemes";
import DropDownFilterIcon from "assets/CommonAssets/SVG/DropDownFilterIcon";
import * as React from "react";
import { memo } from "react";
import { useMedias } from "API/mediasHook";

const LanguageSelect = ({ children, ...props }) => {
    const languagesList = { "ru-RU": "Русский", "en-US": "English", ru: "Русский", en: "English" };

    const selectedLanguage = languagesList[localStorage.getItem("userLanguage")];

    const browserLanguage = languagesList[navigator.language] || languagesList[navigator.userLanguage];

    const [language, setLanguage] = React.useState(selectedLanguage ? selectedLanguage : browserLanguage);

    const medias = useMedias();

    const [openLangCurrChoice, setOpenLangCurrChoice] = React.useState(false);

    const [selectedCurrency, setSelectedCurrency] = React.useState("RUB");

    return (
        <Box>
            {medias.sm && (
                <MenuListComposition
                    open={openLangCurrChoice}
                    setOpen={setOpenLangCurrChoice}
                    content={
                        <LanguageCurrencyModal
                            curr={selectedCurrency}
                            setCurr={setSelectedCurrency}
                            chooseLanguage={(value) => setLanguage(languagesList[value])}
                        />
                    }
                    zIndex={1}
                    paperSx={themes.LangCurrPaper}
                >
                    <Button
                        disableRipple
                        sx={themes.CityButton}
                    >
                        <Typography sx={textThemes.Body2Black}>{`${language}, RUB`}</Typography>
                        <DropDownFilterIcon />
                    </Button>
                </MenuListComposition>
            )}
        </Box>
    );
};

export default memo(LanguageSelect);
