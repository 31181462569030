import { Box, styled } from "@mui/material";

export const StyledOrderCardWrapper = styled(Box)({
    width: "100%",
    margin: "0 auto",
    marginBottom: "40px",
    justifyContent: "center",
    padding: "20px 20px 0px 20px ",
    borderRadius: "16px",
    backgroundColor: "#fff",
    boxSizing: "border-box",
    position: "relative",
});

export const OrderCardWrapperBackdrop = {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    background: "rgba(247, 247, 247, 0.5)",
};
