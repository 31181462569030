import React from "react";

const CardsSmall = () => {
    return (
        <svg
            width="96"
            height="24"
            viewBox="0 0 96 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_638_30600)">
                <path
                    d="M19.6873 7.2832H14.2793V16.7162H19.6873V7.2832Z"
                    fill="#FF5A00"
                />
                <path
                    d="M14.64 12C14.64 10.0835 15.568 8.38252 16.992 7.2835C15.944 6.4835 14.622 6 13.18 6C9.764 6 7 8.6835 7 12C7 15.3165 9.764 18 13.18 18C14.622 18 15.944 17.5165 16.992 16.7165C15.566 15.633 14.64 13.9165 14.64 12Z"
                    fill="#EB001B"
                />
                <path
                    d="M26.9842 12C26.9842 15.3165 24.2202 18 20.8042 18C19.3622 18 18.0402 17.5165 16.9922 16.7165C18.4342 15.6155 19.3442 13.9165 19.3442 12C19.3442 10.0835 18.4162 8.38252 16.9922 7.2835C18.0382 6.4835 19.3602 6 20.8022 6C24.2202 6 26.9842 8.70097 26.9842 12Z"
                    fill="#F79E1B"
                />
            </g>
            <g clipPath="url(#clip1_638_30600)">
                <path
                    d="M51.2568 8.01172C49.3771 8.01172 47.6973 8.9313 47.6973 10.6303C47.6973 12.5787 50.6765 12.7133 50.6765 13.6922C50.6765 14.1043 50.1761 14.4733 49.3214 14.4733C48.1084 14.4733 47.2018 13.9577 47.2018 13.9577L46.8139 15.6722C46.8139 15.6722 47.8583 16.1077 49.2448 16.1077C51.3 16.1077 52.9171 15.1429 52.9171 13.4149C52.9171 11.356 49.9255 11.2254 49.9255 10.3169C49.9255 9.99404 50.3363 9.64028 51.1886 9.64028C52.1502 9.64028 52.9347 10.0152 52.9347 10.0152L53.3143 8.3593C53.3143 8.3593 52.4607 8.01172 51.2568 8.01172ZM34.0592 8.13669L34.0137 8.38664C34.0137 8.38664 34.8045 8.52324 35.5167 8.79573C36.4338 9.1082 36.4991 9.2901 36.6536 9.85508L38.3366 15.9788H40.5927L44.0685 8.13669H41.8175L39.5842 13.4686L38.6728 8.94902C38.5892 8.43176 38.1659 8.13669 37.6477 8.13669H34.0592ZM44.9736 8.13669L43.2078 15.9788H45.3543L47.1139 8.13669H44.9736ZM56.9452 8.13669C56.4276 8.13669 56.1534 8.39824 55.9522 8.85529L52.8075 15.9788H55.0584L55.4939 14.7915H58.2362L58.501 15.9788H60.4872L58.7545 8.13669H56.9452ZM57.238 10.2554L57.9052 13.1981H56.1177L57.238 10.2554Z"
                    fill="#1434CB"
                />
            </g>
            <path
                d="M90.7578 8H85.6602C85.9571 9.64553 87.5903 11.1943 89.372 11.1943H93.4303C93.4798 11.0491 93.4798 10.8071 93.4798 10.6619C93.4798 9.20995 92.2425 8 90.7578 8Z"
                fill="url(#paint0_linear_638_30600)"
            />
            <path
                d="M86.1055 11.4824V15.4995H88.58V13.3699H90.7577C91.9455 13.3699 92.9848 12.5472 93.3312 11.4824H86.1055Z"
                fill="#4FAD50"
            />
            <path
                d="M77.3945 8V15.4533H79.5722C79.5722 15.4533 80.1166 15.4533 80.4135 14.9209C81.8983 12.0654 82.3437 11.1943 82.3437 11.1943H82.6406V15.4533H85.1152V8H82.9376C82.9376 8 82.3932 8.0484 82.0962 8.53238C80.8589 11.0007 80.1661 12.259 80.1661 12.259H79.8691V8H77.3945Z"
                fill="#4FAD50"
            />
            <path
                d="M67 15.4982V8.04492H69.4746C69.4746 8.04492 70.1675 8.04492 70.5634 9.10968C71.5532 11.9168 71.6522 12.3039 71.6522 12.3039C71.6522 12.3039 71.8502 11.6264 72.741 9.10968C73.137 8.04492 73.8298 8.04492 73.8298 8.04492H76.3044V15.4982H73.8298V11.4812H73.5329L72.1471 15.4982H71.0583L69.6726 11.4812H69.3756V15.4982H67Z"
                fill="#4FAD50"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_638_30600"
                    x1="85.6513"
                    y1="9.62003"
                    x2="93.4623"
                    y2="9.62003"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#27B1E6" />
                    <stop
                        offset="1"
                        stopColor="#148ACA"
                    />
                </linearGradient>
                <clipPath id="clip0_638_30600">
                    <rect
                        width="20"
                        height="12"
                        fill="white"
                        transform="translate(7 6)"
                    />
                </clipPath>
                <clipPath id="clip1_638_30600">
                    <rect
                        width="26.5"
                        height="8.12097"
                        fill="white"
                        transform="translate(34 8)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default CardsSmall;
