import { Box, Grid, TextField, Typography } from "@mui/material";
import { Bonus } from "assets/MiniChat/SVG/Bonus";
import SButton from "components/UI/SButton";
import React from "react";
import * as Styles from "./TeacherPriceStyles";
import "./TeacherCSS.css";

const TeacherPrice = (props) => {
    return (
        <Box sx={Styles.MainBox}>
            <SButton style={{ width: "23.5vw", height: "4.3vh" }}>
                <Box sx={{ fontSize: "1.6vh" }}>Выбрать {props.price}  ₽/ч</Box>
            </SButton>
            <Grid
                container
                sx={Styles.Price}
            >
                <Grid
                    className="gradient"
                    item
                    xs={11}
                >
                    + {props.bonus} баллов
                </Grid>
                <Grid
                    sx={{ paddingLeft: "0.2vw" }}
                    item
                    xs={1}
                >
                    {" "}
                    <Bonus />{" "}
                </Grid>
            </Grid>
        </Box>
    );
};

export default TeacherPrice;
