import { Grid, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import * as Styles2 from "components/MiniChat/StartPage/FindFieldStyles";
import { SearchIcon } from "assets/MiniChat/SVG/SearchIcon";
import * as Styles from "components/MiniChat/OneChat/TeacherField/TeacherFieldStyles";
import SButton from "components/UI/SButton";
import FullChatStore from "store/FullChatStore";

const SearchMessagesWindow = (props) => {
    const [value, setValue] = useState("");

    const handleChange = (e) => {
        if (e.target.value.length > 0) {
            props.sendJsonMessage({
                action: "search_message",
                chat_id: FullChatStore.id,
                message: e.target.value,
            });
        }

        setValue(e.target.value);
    };

    return (
        <Grid
            sx={Styles.TeacherField}
            container
        >
            <Grid item>
                <TextField
                    placeholder="Искать в чате"
                    fullWidth
                    value={value}
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={Styles2.TextStyles}
                    margin="none"
                />
            </Grid>
            <Grid
                item
                sx={{ paddingTop: "1vh" }}
            >
                <SButton
                    variant="text"
                    onClick={() => {
                        FullChatStore.SetShowSearchMessagesMini(false);
                        FullChatStore.SetShowSearchMessagesResultMini(false);
                        FullChatStore.SetFoundMessages(null);
                        FullChatStore.SetToScrollMessage(null);
                        FullChatStore.SetNoScroll(true);
                        FullChatStore.SetLastMessage(null);
                    }}
                >
                    Отмена
                </SButton>
            </Grid>
        </Grid>
    );
};

export default observer(SearchMessagesWindow);
