import { memo } from "react";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import TutorClassesBodyBlock from "components/TutorClassesBodyBlock/TutorClassesBodyBlock";

const TutorClassesPage = ({ children, ...props }) => {
    return (
        <ScrollToTop>
            <TutorClassesBodyBlock />
        </ScrollToTop>
    );
};

export default memo(TutorClassesPage);
