export const descriptionBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
    alignSelf: "stretch",
};

export const descriptionTextStyle = {
    alignSelf: "stretch",
    color: "var(--text-black, #222)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const assocTagsTextStyle = {
    alignSelf: "stretch",
    color: "var(--text-grey, #9E9EB0)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};
