import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";
import { Typography } from "@mui/material";
import * as themes from "./OpenOrderModalThemes";
import * as textThemes from "themes/TextThemes";
import { memo } from "react";
import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import SButton from "components/UI/SButton";
import { useTranslation } from "react-i18next";

const OpenOrderModal = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <ModalSettingsSmall
            visible={props.open}
            handleClose={props.handleDiscard}
        >
            <Box sx={themes.OpenOrderModal}>
                <Box sx={themes.OpenOrderContent}>
                    <Typography sx={{ ...textThemes.HeadlineH5Black, ...themes.Title }}>{t("orders.studentOrders.makeOpen")}</Typography>
                    <Typography sx={textThemes.Body2Black}>{t("orders.studentOrders.youWillGetResponses")}</Typography>
                </Box>
                <Box sx={themes.ModalButtonWrapper}>
                    <SButton
                        onClick={props.handleDiscard}
                        variant="secondary"
                        padding="10px 20px 11px 20px"
                    >
                        {t("orders.studentOrders.noKeep")}
                    </SButton>
                    <SButton
                        onClick={props.handleSave}
                        variant="unshadowed"
                        padding="10px 20px 11px 20px"
                    >
                        {t("orders.studentOrders.yesOpen")}
                    </SButton>
                </Box>
            </Box>
        </ModalSettingsSmall>
    );
});

export default memo(OpenOrderModal);
