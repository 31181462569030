import React, { memo, useState } from "react";
import { Typography, Box, Avatar, IconButton, Tooltip } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as styles from "./StudyListItemThemes";
import MyClassesStore from "store/MyClassesStore";
import * as textStyles from "themes/TextThemes";
import { PlatformClassIcon } from "assets/MyClassesAssets/SVG/PlatformClassIcon";
import { PlaceClassIcon } from "assets/MyClassesAssets/SVG/PlaceClassIcon";
import { PastPlatformClassIcon } from "assets/MyClassesAssets/SVG/PastPlatformClassIcon";
import { PastPlaceClassIcon } from "assets/MyClassesAssets/SVG/PastPlaceClassIcon";
import ClassMoreMenu from "./ClassMoreMenu/ClassMoreMenu";
import StarIconWhite from "assets/CommonAssets/SVG/StarSVGWhite";
import SButton from "components/UI/SButton";
import { useTranslation } from "react-i18next";
import ModalThanksForRate from "./Modals/ModalThanksForRate/ModalThanksForRate";
import ModalRateClass from "./Modals/ModalRateClass/ModalRateClass";
import { ReschedulePlatformClassIcon } from "assets/MyClassesAssets/SVG/ReschedulePlatformClassIcon";
import { ReschedulePlaceClassIcon } from "assets/MyClassesAssets/SVG/ReschedulePlaceClassIcon";
import { useMedias } from "API/mediasHook";

const StudyListItem = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const lessonType = MyClassesStore.checkPastUpcoming(props.lesson);

    //Rate class
    const [dialogRateClassOpen, SetDialogRateClassOpen] = useState("");

    const showRateClassDialog = () => {
        SetDialogRateClassOpen(true);
        MyClassesStore.setTemporaryRating(null);
        MyClassesStore.setTemporaryReview(null);
    };

    const discardRateClassDialog = () => {
        SetDialogRateClassOpen(false);
    };

    const saveRateClassDialog = () => {
        SetDialogRateClassOpen(false);
        showThanksForRateDialog();
        MyClassesStore.setRating(MyClassesStore.classes.indexOf(props.lesson), MyClassesStore.temporaryRating);
        MyClassesStore.setReview(MyClassesStore.classes.indexOf(props.lesson), MyClassesStore.temporaryReview);
    };

    //Thanks for rate
    const [dialogThanksForRateOpen, SetDialogThanksForRateOpen] = useState("");

    const showThanksForRateDialog = () => {
        SetDialogThanksForRateOpen(true);
    };

    const discardThanksForRateDialog = () => {
        SetDialogThanksForRateOpen(false);
    };

    return (
        <Box sx={styles.StudyListItem(medias)}>
            <Box sx={styles.LeftIndicator(lessonType, props.lesson.rescheduleTimeStart !== null)}></Box>
            <Box sx={styles.StudyListItemContent(medias)}>
                <Box sx={styles.LeftPanel}>
                    <Tooltip
                        PopperProps={styles.TooltipPopperProps}
                        title={
                            props.lesson.place === "online" ? t("main.myClasses.poweredByStudyBuddy") : props.lesson.place === "tutor" ? t("main.myClasses.tutorHome") : t("main.myClasses.studentHome")
                        }
                        placement="top"
                    >
                        <IconButton
                            sx={styles.IconButton(medias)}
                            disableRipple
                        >
                            {lessonType === "upcoming" ? (
                                props.lesson.place === "online" ? (
                                    props.lesson.rescheduleTimeStart !== null ? (
                                        <ReschedulePlatformClassIcon />
                                    ) : (
                                        <PlatformClassIcon />
                                    )
                                ) : props.lesson.rescheduleTimeStart !== null ? (
                                    <ReschedulePlaceClassIcon />
                                ) : (
                                    <PlaceClassIcon />
                                )
                            ) : props.lesson.place === "online" ? (
                                <PastPlatformClassIcon />
                            ) : (
                                <PastPlaceClassIcon />
                            )}
                        </IconButton>
                    </Tooltip>
                    <Box sx={styles.SubjectDate}>
                        <Typography sx={textStyles.Body1Black}>{props.lesson.subject}</Typography>
                        {props.lesson.rescheduleTimeStart && <Typography sx={textStyles.Body2Orange}>{t("main.myClasses.rescheduleRequest")}</Typography>}
                        {props.lesson.rescheduleTimeStart !== null ? (
                            <Box sx={styles.RescheduleTimes}>
                                <Typography
                                    sx={textStyles.Body2DarkGrey}
                                    component={"div"}
                                >
                                    {t("main.myClasses.currentTimeLower")}
                                    <span style={textStyles.Body2Black}>{` ${MyClassesStore.getFormatedDateExtended(props.lesson.timeStart, props.lesson.timeEnd)}`}</span>
                                </Typography>
                                <Typography
                                    sx={textStyles.Body2DarkGrey}
                                    component={"div"}
                                >
                                    {t("main.myClasses.requestedTimeShort")}
                                    <span style={textStyles.Body2Black}>{` ${MyClassesStore.getFormatedDateExtended(props.lesson.rescheduleTimeStart, props.lesson.rescheduleTimeEnd)}`}</span>
                                </Typography>
                            </Box>
                        ) : (
                            <Typography sx={textStyles.Body2DarkGrey}>{MyClassesStore.formatTodayClassTime(props.lesson)}</Typography>
                        )}
                    </Box>
                </Box>
                {lessonType === "upcoming" ? (
                    <Box sx={styles.RightPanel}>
                        <Box sx={styles.RightPanelContent}>
                            <Avatar src={props.lesson.photo} />
                            <Typography sx={textStyles.Body2Black}>{props.lesson.tutor}</Typography>
                        </Box>

                        <Box sx={styles.DropMenuWrapper(medias)}>
                            <ClassMoreMenu lesson={props.lesson} />
                        </Box>
                    </Box>
                ) : (
                    <Box sx={styles.Rating(medias)}>
                        {props.lesson.rating === null ? (
                            <SButton
                                variant="stroked"
                                onClick={showRateClassDialog}
                                style={styles.RateButton}
                            >
                                {t("main.myClasses.rateClass")}
                            </SButton>
                        ) : (
                            <Box sx={styles.ratingBlock(props.lesson.rating, medias)}>
                                <Typography sx={styles.ratingText}>{props.lesson.rating}</Typography>
                                <StarIconWhite />
                            </Box>
                        )}
                        <Box sx={styles.RatingTutor(medias)}>
                            <Avatar src={props.lesson.photo} />
                            <Typography sx={textStyles.Body2Black}>{props.lesson.tutor}</Typography>
                        </Box>
                    </Box>
                )}
            </Box>

            <ModalRateClass
                open={dialogRateClassOpen}
                handleDiscard={discardRateClassDialog}
                handleSave={saveRateClassDialog}
                lesson={props.lesson}
            />

            <ModalThanksForRate
                open={dialogThanksForRateOpen}
                handleDiscard={discardThanksForRateDialog}
                lesson={props.lesson}
            />
        </Box>
    );
});

export default memo(StudyListItem);
