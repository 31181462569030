import { memo, useEffect } from "react";
import NotificationsBodyBlock from "components/NotificationsBodyBlock/NotificationsBodyBlock";
import NotificationsStore from "store/NotificationsStore";
import { observer } from "mobx-react-lite";
import UserStore from "store/UserStore";

const NotificationsPage = observer(({ children, ...props }) => {
    useEffect(() => {
        const fetchFunc = async () => {
            await NotificationsStore.FetchPageData();
        };

        fetchFunc();
    }, [UserStore.role]);

    return <NotificationsBodyBlock />;
});

export default memo(NotificationsPage);
