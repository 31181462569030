import { styleProcessing } from "API/plugins/Utilities";

export const ArrowButton = {
    display: "flex",
    alignItems: "center",
    m: 0,
    p: 0,
};

export const MonthContainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 16px",
};

export const YearContainer = {
    textAlign: "center",
    backgroundColor: "#BBBBC82B",
    padding: "11px 0px",
};

export const WeekDaysContainer = {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    textAlign: "center",
    padding: "0px 12px",
    marginBottom: "8px",
};

export const TextField = {
    borderRadius: "8px",
    width: "100%",
    marginBottom: "20px",
};

export const TextFieldContainer = (borderMode, anchorEl, theme) => {
    let commonStyle = {
        display: "flex",
        alignItems: "center",
        gap: "4px",
        margin: 0,
        padding: 0,
        border: !borderMode ? "none" : anchorEl ? "1px solid #6212FF" : "1px solid #EEEEF0",
        borderRadius: borderMode ? "8px" : "none",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "0 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const DateInput = (fontSize, width) => {
    return {
        border: "none",
        borderRadius: "8px",
        padding: "0",
        margin: "0",
        color: "#222",
        fontFamily: "Inter",
        fontSize: fontSize,
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "150%",
        width: 104,
    };
};

export const DaysContainer = {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    rowGap: "8px",
    padding: "0px 12px",
    marginBottom: "12px",
};

export const Calendar = (width, theme) => {
    let commonStyle = {
        display: "inline-block",
        border: "none",
        borderRadius: "10px",
        boxShadow: "0px 8px 16px #8e8dd01f",
        backgroundColor: "#FFFFFF",
        width: width,
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            boxShadow: "none",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const DayButton = (currentMonth, notSelectable, selected, selectedpurp) => {
    return {
        color: selectedpurp ? "#FFF" : selected ? "#222" : notSelectable ? "#BBBBC8" : currentMonth ? "#222" : "#BBBBC8",
        textAlign: "center",
        borderRadius: "2px",
        width: "100%",
        height: "24px",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "150%",
        cursor: notSelectable ? "not-allowed" : "pointer",
        pointerEvents: notSelectable ? "none" : "auto",
        backgroundColor: selectedpurp ? "#6212FF" : selected ? "#DADADF" : "none",
        ":hover": {
            backgroundColor: "#6212FF",
            color: "#FFFFFF",
        },
    };
};
