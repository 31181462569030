export const TutorsTypeBlock = (medias) => {
    return {
        width: medias.sm ? "fit-content" : "328px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    };
};

export const Header = {
    width: "938px",
    color: "#222",
    fontFamily: "Inter",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "135%" /* 54px */,
};

export const TypesBlock = (medias) => {
    return {
        width: medias.sm ? "1216px" : "328px",
        gap: "20px",
        display: "flex",
        flexDirection: medias.sm ? "unset" : "column",
    };
};

export const columnBlockReverse = (medias) => {
    return {
        gap: "20px",
        display: "flex",
        flexDirection: medias.sm ? "column" : "column-reverse",
    };
};

export const columnBlock = {
    gap: "20px",
    display: "flex",
    flexDirection: "column",
};

export const Image1 = (medias) => {
    return {
        width: medias.sm ? 392 : 328,
        height: medias.sm ? 265 : 224,
    };
};

export const Image2 = (medias) => {
    return {
        width: medias.sm ? 392 : 328,
        height: medias.sm ? 220 : 224,
    };
};
