import { createTheme } from "@mui/material/styles";

export const ordersDisciplineContainerStyle = {
    width: "249px!important",
};

export const headerOrdersDisciplineStyle = {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#222222",
    flex: "none",
    order: 0,
    flexGrow: 1,
    paddingBottom: "12px",
};

export const selectedOrdersDisciplineContainerStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    padding: "0px",
    gap: "8px",
    marginBottom: "16px",
};

export const OrdersChipTheme = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                // Name of the slot
                root: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "4px 10px 5px",
                    gap: "4px",
                    width: "fit-content",
                    height: "30px",
                    background: "#FFFFFF",
                    border: "1px solid #BBBBC8",
                    borderRadius: "25px!important",
                    ".MuiChip-deleteIcon": {
                        width: "16px",
                        height: "16px",
                        flex: "none",
                        order: 1,
                        flexGrow: 0,
                    },
                    ".MuiIconButton-root": {
                        margin: 0,
                        color: "#4E4E4E!important",
                    },
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    ".MuiIconButton-root:hover": {
                        color: "#6212FF!important",
                    },
                },
                label: {
                    width: "fit-content",
                    height: "18px",
                    fontStyle: "normal",
                    padding: 0,
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "130%",
                    fontFeatureSettings: "'tnum' on, 'lnum' on",
                    color: "#222222",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                },
            },
        },
    },
});

export const ordersDisciplineActionsStyle = {
    display: "flex",
    gap: "11px",
};

export const FilterActionButtonTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "7px 16px 8px 16px",
                    gap: "10px",
                    width: "100%",
                    height: "36px",
                    background: "var(--grey-separator, #EEEEF0)",
                    borderRadius: "30px!important",
                    "&:hover": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                        ".MuiTypography-root": {
                            color: "white!important",
                        },
                    },
                    "&:active": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                        ".MuiTypography-root": {
                            color: "white!important",
                        },
                    },
                },
            },
        },
    },
});

export const fromPATextStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
    textTransform: "none",
};

export const fromListTextStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
    textTransform: "none",
};
