import { styleProcessing } from "API/plugins/Utilities";

export const StyledOrderCard = (theme) => {
    let commonStyle = {
        width: "100%",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "24px",
        padding: "20px 20px 10px 20px ",
        borderRadius: "16px",
        backgroundColor: "#fff",
        boxSizing: "border-box",
        cursor: "pointer",
        boxShadow: "0px 4px 30px 0px rgba(106, 99, 118, 0.05)",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, padding: "12px 20px 16px 20px", gap: "14px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
