export const TopCardWrapper = {
    display: "flex",
    justifyContent: "space-between",
};

export const TargetSubjWrapper = {
    display: "flex",
    gap: "4px",
};

export const AvatarPriceWrapper = {
    marginBottom: "12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const Price = {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0em",
};

export const divider = { marginBottom: "10px", color: "#EEEEF0" };

export const BottomCardWrapper = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const DeadlineWrapper = {
    display: "flex",
    gap: "4px",
};

export const DeadlineText = { color: "#9E9EB0", paddingRight: "4px" };

export const BurnDeadlineText = {
    color: "rgba(225, 38, 38, 1)",
    paddingLeft: "4px",
};

export const LessonAssessmentWrapper = {
    width: "36px",
    height: "24px",
    color: "rgba(255, 255, 255, 1)",
    background: "rgba(241, 176, 99, 1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100px",
};

export const LessonAssessmentText = {
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "18px",
    letterSpacing: "0em",
};

export const LessonAssessmentIcon = { fontSize: "16px", marginRight: "-4px" };

export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};
