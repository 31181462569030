import React from "react";
import { Typography, Box } from "@mui/material";
import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import SButton from "components/UI/SButton";
import * as textStyles from "themes/TextThemes";
import * as styles from "./ModalsDeleteAccountThemes";
import { useMedias } from "API/mediasHook";

const ModalAccountDeleted = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const medias = useMedias();

    const Close = () => {
        props.handleDiscard();
    };

    return (
        <ModalSettingsSmall
            visible={props.open}
            handleClose={Close}
        >
            <Box sx={styles.AccountDeletedContent}>
                <Typography
                    sx={textStyles.HeadlineH41Black}
                    style={styles.ModalDeletedtext(medias)}
                >
                    {t("main.settings.deleteAccountBlock.accountDeletedTitle")}
                </Typography>
                <Typography
                    sx={textStyles.Body2Black}
                    style={{ textAlign: "center" }}
                >
                    {t("main.settings.deleteAccountBlock.accountDeletedDescription")}
                </Typography>
            </Box>
            <Box sx={styles.AccountDeletedClose}>
                <SButton
                    variant="text"
                    onClick={Close}
                >
                    {t("main.close")}
                </SButton>
            </Box>
        </ModalSettingsSmall>
    );
});

export default memo(ModalAccountDeleted);
