const EmptyReviewsList = function (props) {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.3411 44.5938C28.5996 44.5938 28.8393 44.4591 28.974 44.2386C29.1085 44.0178 29.1182 43.7432 28.9999 43.5135C28.4023 42.3536 28.0651 41.0385 28.0651 39.6454C28.0651 34.9736 31.8578 31.1809 36.5295 31.1809C36.9796 31.1809 37.4216 31.216 37.8527 31.2839C38.0666 31.3175 38.2847 31.2559 38.4493 31.115C38.6141 30.9744 38.7087 30.7686 38.7087 30.5518V12.8134C38.7087 12.6168 38.6307 12.4283 38.4918 12.2894L27.4194 1.21698C27.2804 1.07802 27.0919 1 26.8953 1H10.2821C8.61601 1 7.01798 1.66183 5.83991 2.8399C4.66184 4.01798 4 5.61601 4 7.28209V38.3117C4 39.9778 4.66184 41.5758 5.83991 42.7539C7.01798 43.932 8.61601 44.5938 10.2821 44.5938H28.3411Z"
                fill="#6212FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.1543 1V7.63708C26.1543 9.9733 27.0824 12.214 28.7345 13.8661C30.3866 15.5179 32.6273 16.446 34.9635 16.446H38.7088V12.8134C38.7088 12.6168 38.6307 12.4283 38.4918 12.2894L27.4194 1.21698C27.2804 1.07802 27.0919 1 26.8953 1H26.1543Z"
                fill="#4508BE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.7303 19.6598H25.3666C25.7754 19.6598 26.1076 19.3278 26.1076 18.9188C26.1076 18.5097 25.7754 18.1777 25.3666 18.1777H13.7303C13.3212 18.1777 12.9893 18.5097 12.9893 18.9188C12.9893 19.3278 13.3212 19.6598 13.7303 19.6598Z"
                fill="#EEEEF0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.7303 27.4176H28.9781C29.3872 27.4176 29.7191 27.0856 29.7191 26.6766C29.7191 26.2675 29.3872 25.9355 28.9781 25.9355H13.7303C13.3212 25.9355 12.9893 26.2675 12.9893 26.6766C12.9893 27.0856 13.3212 27.4176 13.7303 27.4176Z"
                fill="#EEEEF0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.2287 44.5945H28.341C28.5995 44.5945 28.8393 44.4598 28.974 44.2393C29.1084 44.0185 29.1181 43.7439 28.9998 43.5142C28.4022 42.3543 28.065 41.0392 28.065 39.6461C28.065 35.2949 31.3552 31.7062 35.5816 31.2343C35.243 31.1994 34.9004 31.1816 34.5534 31.1816C29.0638 31.1816 24.6069 35.6385 24.6069 41.1281C24.6069 42.3467 24.8265 43.5146 25.2287 44.5945Z"
                fill="#4508BE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.1543 1V7.27237C26.1543 8.93846 26.8164 10.5363 27.9944 11.7143C29.1725 12.8924 30.7703 13.5545 32.4364 13.5545H38.7088V12.8134C38.7088 12.6168 38.6307 12.4283 38.4918 12.2894L27.4194 1.21698C27.2804 1.07802 27.0919 1 26.8953 1H26.1543Z"
                fill="#EEEEF0"
            />
            <circle
                cx="35.945"
                cy="39.945"
                r="9.945"
                fill="#F1B063"
            />
            <path
                d="M35.5408 33.0664C35.7143 32.6634 36.2857 32.6634 36.4592 33.0664L38.0456 36.7501C38.118 36.9182 38.2764 37.0333 38.4587 37.0502L42.4524 37.4206C42.8892 37.4612 43.0658 38.0046 42.7362 38.2941L39.7229 40.9412C39.5854 41.062 39.5249 41.2483 39.5652 41.4268L40.447 45.3395C40.5435 45.7675 40.0812 46.1034 39.704 45.8794L36.2553 43.8316C36.0979 43.7381 35.9021 43.7381 35.7447 43.8316L32.296 45.8793C31.9188 46.1034 31.4565 45.7675 31.553 45.3395L32.4348 41.4268C32.4751 41.2483 32.4146 41.062 32.2771 40.9412L29.2638 38.2941C28.9342 38.0046 29.1108 37.4612 29.5476 37.4206L33.5413 37.0502C33.7236 37.0333 33.882 36.9182 33.9544 36.7501L35.5408 33.0664Z"
                fill="white"
            />
        </svg>
    );
};

export default EmptyReviewsList;
