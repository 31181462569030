export const Snackbar = {
    width: "305px",
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "16px",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.05), 0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const CloseIcon = {
    width: "fit-content",
    height: "fit-content",
};

export const SnackbarContent = {
    display: "flex",
    gap: "10px",
};

export const AddCardButton = {
    width: "100%",
};
