import SButton from "components/UI/SButton";
import "./CancelModals.css";
import SRadioGroup from "components/UI/RadioGroup";
import React, { useState } from "react";
import Box from "@mui/material/Box";

const CancelReasonsStep = ({ setDialogNumber }) => {
    const [cancelReasonVar, setCancelReasonVar] = useState("");

    const [cancelReasonText, setCancelReasonText] = useState("");

    const cancelReasonsList = [
        "Уже нашёл(а) специалиста на другом сайте",
        "Уже нашёл(а) специалиста через знакомых",
        "Изменились обстоятельства",
        "Не подошли специалисты",
        "Не устроила цена",
        "Не устроил сервис компании",
    ];

    const handleSend = () => {
        setDialogNumber(3);
    };

    return (
        <Box className="CancelModalContent">
            <Box className="CancelReasonsModalTitle">
                Пожалуйста, укажите причину
                <br />
                отмены заказа
            </Box>
            <SRadioGroup
                changefunc={setCancelReasonVar}
                value={cancelReasonVar}
                list={cancelReasonsList}
                anotherVar={true}
                textVar={cancelReasonText}
                setTextVar={setCancelReasonText}
            />
            <SButton
                disabled={cancelReasonVar === "" || (cancelReasonVar === "Другое" && cancelReasonText === "")}
                padding="10px 20px 11px 20px"
                variant="unshadowed"
                onClick={handleSend}
                style={{ marginTop: "40px" }}
            >
                Отправить
            </SButton>
        </Box>
    );
};

export default CancelReasonsStep;
