import { styleProcessing } from "API/plugins/Utilities";

export const HowWorkMainBlock = (theme) => {
    let commonStyle = {
        padding: "50px 65px 49px 50px",
        width: "1216px",
        background: "#F7F7F7",
        display: "flex",
        gap: "124px",
        borderRadius: "40px",
        border: "2px solid #6212FF",
        position: "relative",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "328px",
            height: "fit-content",
            border: "0px",
            padding: "0px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TextBlock = (medias) => {
    return {
        display: "flex",
        flexDirection: "column",
        gap: medias.sm ? "40px" : "16px",
    };
};

export const RightBlock = {
    position: "relative",
};
/*
export const HowWorkMainBlock = {
    padding: "50px 65px 49px 50px",
    width: "1216px",
    background: "#F7F7F7",
    display: "flex",
    gap: "124px",
    borderRadius: "40px",
    border: "2px solid #6212FF",
};

export const TextBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
};

export const RightBlock = {
    position: "relative",
};
*/
