import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { memo } from "react";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as textStyles from "themes/TextThemes";
import * as styles from "./PhoneTextFieldThemes";
import { observer } from "mobx-react-lite";
import AuthorizationStore from "store/AuthorizationStore";

const PhoneInputComponent = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleOnChange = (value, country) => {
        if (props.mode === "SignUp") {
            AuthorizationStore.setSignUpPhoneError(false);
            AuthorizationStore.setSignUpPhone(value);
            AuthorizationStore.setSignUpEmailError(false);
            AuthorizationStore.setSignUpEmailErrorText("");
        } else {
            AuthorizationStore.setTemporaryPhoneNumberHelper(false);
            AuthorizationStore.setTemporaryPhoneNumber(value);
        }
    };

    return (
        <Box sx={styles.PhoneBox}>
            <Box style={styles.Box(isFocused, props.mode === "SignUp" ? AuthorizationStore.signUpPhoneError : AuthorizationStore.temporaryPhoneNumberHelper)}>
                <Typography sx={textStyles.Body2Black}>{t("main.phoneNumber")}</Typography>
                <PhoneInput
                    country={"ru"}
                    value={
                        props.mode === "SignUp"
                            ? AuthorizationStore.signUpPhone === null
                                ? ""
                                : AuthorizationStore.signUpPhone
                            : AuthorizationStore.temporaryPhoneNumber === null
                              ? ""
                              : AuthorizationStore.temporaryPhoneNumber
                    }
                    onChange={handleOnChange}
                    inputStyle={styles.Input}
                    dropdownStyle={styles.Dropdown}
                    buttonStyle={styles.Button}
                    placeholder="000 000-00-00"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
            </Box>
            {props.mode === "SignUp" ? null : AuthorizationStore.temporaryPhoneNumberHelper === false ? null : (
                <Typography sx={textStyles.Caption1Red}>Неверный номер телефона или 1 минута с прошлой попытки еще не прошла</Typography>
            )}
        </Box>
    );
});

export default memo(PhoneInputComponent);
