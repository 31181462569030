import React, { useState } from "react";
import { Box } from "@mui/system";
import * as theme from "./TutorCardStyle";
import * as themeText from "./TutorCardTextStyle";
import { Typography } from "@mui/material";
import PromoTutorStore from "../../../../store/PromoTutorStore";
import * as AboutUsStyle from "../../../MainPromoBodyBlock/AboutUs/AboutUsStyle";
import { ThemeProvider } from "@mui/material/styles";
import { PlayArrow } from "@mui/icons-material";
import { useMedias } from "../../../../API/mediasHook";
import MyPlayer from "../../../TutorPromo/Video/TutorVideo";
import { AvatarStyle } from "./TutorCardStyle";

const TutorCard = ({ imageSrc, title, description, quote, tutor, disableClick = false }) => {
    const SetActiveTutorPromo = () => {
        PromoTutorStore.SetActiveTutorPromo(tutor);
    };

    const [isPlayerVisible, setPlayerVisibility] = useState(false);

    const handlePlayClick = () => {
        setPlayerVisibility(true);
    };

    const medias = useMedias();

    return (
        <Box
            sx={theme.CardTheme(medias)}
            onClick={() => (!disableClick && SetActiveTutorPromo(), handlePlayClick())}
        >
            <img
                style={theme.AvatarStyle(medias)}
                src={imageSrc}
                alt={1}
            />
            <Box sx={{ background: "#FFF" }}>
                <Typography sx={themeText.CardTitle}>{title}</Typography>
                <Typography sx={themeText.CardDescribe}>{description}</Typography>
                <Typography sx={themeText.Quote}>{quote}</Typography>
            </Box>

            {isPlayerVisible ? (
                <MyPlayer
                    // style={theme.PlayerBlock(medias)}
                    VideoName={PromoTutorStore.activeTutorPromo.tutorVideo}
                />
            ) : (
                <Box
                    onClick={handlePlayClick}
                    style={theme.tcPlayIconBGStyle(medias)}
                >
                    <ThemeProvider theme={theme.TCPlayIconTheme(medias)}>
                        <PlayArrow />
                    </ThemeProvider>
                </Box>
            )}
        </Box>
    );
};

export default TutorCard;
