import { styleProcessing } from "API/plugins/Utilities";

export const RefTableEmpty = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    borderRadius: "16px",
    background: "#FFF",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
    gap: "30px",
    maxWidth: "804px",
    padding: "20px 0 20px 0",
};

export const RefTableEmptyContent = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    alignItems: "center",
    maxWidth: "804px",
    padding: "20px",
};

export const RefTableEmptyContentSubtitle = {
    maxWidth: "598px",
    width: "100%",
};

export const ButtonSize = (theme) => {
    let commonStyle = { width: "fit-content" };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
