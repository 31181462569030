export const PaperStyles = {
    width: "22vw",
    mt: "2.7vh",
    backgroundColor: "white",
    borderRadius: "1vh",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "1.9vh",
    lineHeight: "150%",
    border: "none",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
    p: 0,
};

export const TextStyles = {
    "& fieldset": { border: "none" },
    "& .MuiOutlinedInput-root": {
        fontSize: "1.9vh",
        margin: "0.61vh 0.33vw 0.61vh 0.66vw",
        padding: "0.94vh 0.78vw 0.74vh 0.78vw",
        backgroundColor: "rgba(187, 187, 200, 0.17)",
        borderRadius: "1vh",
        width: "17.25vw",
        height: "4vh",
    },
};

export const MessageRight = {
    display: "inline-block",
    fontSize: "1.6vh",
    textAlign: "right",
    width: "4.25vw",
    verticalAlign: "top",
    paddingTop: "1.3vh",
};

export const MessageCenter = {
    display: "inline-block",
    fontSize: "1.9vh", //14px
    width: "14.7vw",
    paddingLeft: "0.52vw",
};

export const MessageBox = {
    padding: "0.67vh 0px 1.27vh 1vw",
    borderBottom: "1px solid #EEEEF0",
    marginLeft: "0.45vw",
};

export const UnreadMessage = {
    borderRadius: "99px",
    marginTop: "0.55vh",
    display: "inline-block",
    paddingRight: "0.4vw",
    paddingLeft: "0.4vw",
    color: "white",
};

export const MenuItem = {
    fontSize: "2.1vh",
    borderRadius: "1vh",
    height: "4.92vh",
    ":hover": {
        color: "#6212FF",
    },
};

export const AvatarDiv = {
    display: "inline-block",
    height: "7vh",
    verticalAlign: "bottom",
};

export const TabsStyles = {
    ".MuiTab-root": { fontSize: "1.9vh", minWidth: "0px", paddingLeft: "0px", paddingRight: "0px" },
    ".MuiTab-root:hover": { color: "#6212FF" },
};

export const Avatar = {
    marginTop: "1.25vh",
    width: "5.43vh",
    height: "5.43vh",
};
