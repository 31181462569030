export const Paper = {
    borderRadius: "10px",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
    padding: "12px 5px",
    width: "fit-content",
};

export const MenuItem = {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    alignItems: "flex-start",
    maxWidth: "250px",
    padding: "6px 11px",
    borderRadius: "8px",
    whiteSpace: "normal",
};

export const MenuList = {
    padding: 0,
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
};

export const Divider = {
    height: "1px",
    background: "#EEEEF0",
};

export const DividerContainer = {
    padding: "0px 11px",
};

export const TooltipPopperProps = {
    sx: {
        "& .MuiTooltip-tooltip": {
            border: "none",
            color: "#222",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "140%",
            borderRadius: "10px",
            bgcolor: "#fff",
            maxWidth: "273px",
            boxShadow: "0px 2px 10px 0px rgba(106, 99, 118, 0.15)",
            padding: "10px",
        },
        "& .MuiTooltip-arrow": {
            color: "#fff",
        },
    },
};
