import { memo } from "react";
import * as styles from "./UsedBankCardThemes";
import * as textStyles from "themes/TextThemes";
import PaymentStore from "store/PaymentStore";
import { Box, Radio, Typography } from "@mui/material";
import PurpleRadioDefault from "assets/PaymentAssets/SVG/PurpleRadioDefault";
import PurpleRadioChecked from "assets/PaymentAssets/SVG/PurpleRadioChecked";
import VisaPaymentIcon from "assets/PaymentAssets/SVG/VisaPaymentIcon";
import MastercardPaymentIcon from "assets/PaymentAssets/SVG/MastercardPaymentIcon";
import MirPaymentIcon from "assets/PaymentAssets/SVG/MirPaymentIcon";
import CardsSmall from "assets/SettingsAssets/SVG/cardssmall";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

const UsedBankCard = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={styles.CardsContainer}>
            {PaymentStore.paymentCards.map((card, index) => {
                return (
                    <Box
                        key={card.id}
                        sx={styles.CardContainer}
                        onClick={() => PaymentStore.changePaymentMethod(`card${index}`)}
                    >
                        <Box sx={styles.RadioCards}>
                            <Radio
                                icon={<PurpleRadioDefault />}
                                checkedIcon={<PurpleRadioChecked />}
                                disableRipple
                                sx={styles.Radio}
                                checked={PaymentStore.selectedPaymentMethod === `card${index}`}
                            />
                            <Typography sx={textStyles.Body2Black}>
                                {`${card.number.slice(0, 1) === "2" ? "Мир" : card.number.slice(0, 1) === "4" ? "Visa" : card.number.slice(0, 1) === "5" ? "Mastercard" : ""} ****${card.number.slice(-4)}
                                            `}
                            </Typography>
                        </Box>
                        {card.number.slice(0, 1) === "4" ? (
                            <VisaPaymentIcon />
                        ) : card.number.slice(0, 1) === "5" ? (
                            <MastercardPaymentIcon />
                        ) : card.number.slice(0, 1) === "2" ? (
                            <MirPaymentIcon />
                        ) : (
                            <CardsSmall />
                        )}
                    </Box>
                );
            })}
        </Box>
    );
});

export default memo(UsedBankCard);
