import { styleProcessing } from "API/plugins/Utilities";

export const PrivilegeBlock = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
};

export const IconBlock = {
    width: "16px",
    height: "16px",
};

export const PrivilegesBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const WhiteContainer = (theme) => {
    let commonStyle = {
        padding: "20px",
        width: "445px",
        height: "165px",
        borderRadius: "20px",
        backgroundColor: "white",
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.05)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
            height: "auto",
            boxSizing: "border-box",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
