export const otherTutorsBlockStyle = {
    display: "flex",
    flexDirection: "column",
    gap: 40,
    paddingTop: 70,
    paddingBottom: 70,
};

export const otherTutorsHeaderStyle = {
    color: "var(--text-black, #222)",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "120%",
};

export const otherTutorsContainerStyle = {
    display: "flex",
    flexDirection: "row",
    gap: 21,
    width: 1216,
    height: 280,
};
