import { createTheme } from "@mui/material";

const DiplomaProgressTheme = createTheme({
    components: {
        MuiFormControl: {
            styleOverrides: {
                // Name of the slot
                root: {
                    ".MuiSvgIcon-root": {
                        color: "#6212FF",
                    },
                    ".MuiButtonBase-root": {
                        paddingTop: 0,
                        paddingBottom: 0,
                        "&:hover": {
                            background: "none!important",
                        },
                    },
                    ".MuiTypography-root": {
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "150%",
                        display: "flex",
                        alignItems: "center",
                        color: "#222222",
                        width: "100%",
                        flex: "none",
                        order: 0,
                        flexGrow: 1,
                        "&:hover": {
                            color: "#6212FF",
                        },
                    },
                    ".MuiFormGroup-root": {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "0px",
                        gap: "12px",
                    },
                },
            },
        },
    },
});

export default DiplomaProgressTheme;
