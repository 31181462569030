import { styleProcessing } from "API/plugins/Utilities";

export const RecommendedOrders = (theme) => {
    let commonStyle = {
        borderRadius: "20px",
        backgroundColor: "#EEEEF0",
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
        maxWidth: "599px",
        width: "100%",
        padding: "20px",
        boxSizing: "border-box",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, borderRadius: "0px", backgroundColor: "undefinde", boxShadow: "none", padding: "0px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Header = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "21px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, flexDirection: "column", alignItems: "start", gap: "12px", paddingTop: "10px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const SwiperSlide = {
    maxWidth: "fit-content",
};
