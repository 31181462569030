import React, { memo } from "react";
import { Typography } from "@mui/material";
import * as themes from "./InfoAreaThemes";
import Box from "@mui/material/Box";

const InfoArea = ({ children, ...props }) => {
    return (
        <Box sx={themes.ocInfoAreaStyle}>
            <Box style={themes.themeBlockContainerStyle}>
                <Typography sx={themes.themeLabelStyle}>Тема:</Typography>
                <Typography sx={themes.themeTextStyle}>{props.value.Theme}</Typography>
            </Box>
            <Box style={themes.deadlineBlockContainerStyle}>
                <Typography sx={themes.deadlineLabelStyle}>Срок выполнения:</Typography>
                <Typography sx={themes.deadlineTextStyle}>{props.value.Deadline}</Typography>
            </Box>
        </Box>
    );
};

export default memo(InfoArea);
