export const Check = () => {
    return (
        <svg
            width="2.6vh"
            height="2.6vh"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3 8.5L5.77056 12.3659C6.16949 12.9225 6.99726 12.9225 7.39619 12.3659L9.06665 10.035L13.0334 4.5"
                stroke="#6212FF"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};
