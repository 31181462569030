import { styleProcessing } from "API/plugins/Utilities";

export const ModalClassCanceled = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        gap: "40px",
        padding: "20px 0",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "0",
            paddingBottom: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Container = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            textAlign: "left",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TextButton = {
    cursor: "pointer",
};
