export const ocFooterAreaStyle = {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
};

export const priceTextStyle = (mode) => {
    return {
        color: "var(--text-black, #222)",
        textAlign: "center",
        fontSize: mode ? "14px" : "16px",
        fontStyle: "normal",
        fontWeight: mode ? "400" : "600",
        lineHeight: "150%",
    };
};

export const lastSeenTextStyle = {
    color: "var(--text-grey, #9E9EB0)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};
