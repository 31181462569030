import React, { memo, useState } from "react";
import { Card, Divider, IconButton, Menu, Snackbar, Typography } from "@mui/material";
import * as themes from "./InfoCardThemes";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";
import { CheckCircleSharp, CloseRounded, MoreHorizSharp } from "@mui/icons-material";
import { ThemeProvider } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import ReviewBlock from "./ReviewBlock/ReviewBlock";
import InfoItem from "./InfoItem/InfoItem";
import CommentItem from "./CommentItem/CommentItem";
import ModalReport from "./ModalReport";
import OrderStore from "store/OrderStore";

const InfoCard = observer(({ children, ...props }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const [dialogReportOpen, SetDialogReportOpen] = useState(false);

    const [snackBarOpen, SetSnackBarOpen] = useState(false);

    const open = Boolean(anchorEl);

    const handleMenuToggle = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (value, event) => {
        if (value !== null) {
            //
        }

        setAnchorEl(null);
    };

    const showReportDialog = () => {
        SetDialogReportOpen(true);
    };

    const discardReportDialog = () => {
        SetDialogReportOpen(false);
        OrderStore.ClearModalReport();
    };

    const submitReportDialog = () => {
        SetDialogReportOpen(false);
        OrderStore.SubmitReport();
        OrderStore.ClearModalReport();
        showSnackBar();
    };

    const showSnackBar = () => {
        SetSnackBarOpen(true);
    };

    const hideSnackBar = () => {
        SetSnackBarOpen(false);
    };

    return (
        <Card sx={themes.infoCardStyle}>
            <Box sx={themes.infoHeaderContainerStyle}>
                <Typography sx={themes.infoHeaderStyle}>{props.value.OrderTitle}</Typography>
                <ThemeProvider theme={themes.MoreIconTheme}>
                    <IconButton
                        onClick={handleMenuToggle}
                        disableRipple
                    >
                        <MoreHorizSharp></MoreHorizSharp>
                    </IconButton>
                </ThemeProvider>
                <ThemeProvider theme={themes.MenuTheme}>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose.bind(this, null)}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <MenuItem
                            onClick={handleMenuClose.bind(this, true)}
                            disableRipple
                        >
                            <Typography
                                sx={themes.reportActionTextStyle}
                                onClick={showReportDialog}
                            >
                                Пожаловаться на заказ
                            </Typography>
                        </MenuItem>
                    </Menu>
                </ThemeProvider>
            </Box>
            <Box sx={themes.infoBodyContainerStyle}>
                <Box>
                    <Divider sx={themes.dividerStyle(0, 8)}></Divider>
                    <ReviewBlock value={props.value}></ReviewBlock>
                    <Divider sx={themes.dividerStyle(10, 0)}></Divider>
                </Box>
                <Box>
                    {props.value.OrderType ? (
                        <Box sx={themes.itemContainerStyle}>
                            <InfoItem
                                label={"Для кого"}
                                info={props.value.Audience}
                            ></InfoItem>
                            <Divider sx={themes.dividerStyle(12, 11)}></Divider>
                            <InfoItem
                                label={"Курс"}
                                info={props.value.Course}
                            ></InfoItem>
                            <Divider sx={themes.dividerStyle(12, 11)}></Divider>
                            <InfoItem
                                label={"Цель занятий"}
                                info={props.value.Goal}
                            ></InfoItem>
                            <Divider sx={themes.dividerStyle(12, 11)}></Divider>
                            <InfoItem
                                label={"ВУЗ"}
                                info={props.value.Edu}
                            ></InfoItem>
                            <Divider sx={themes.dividerStyle(12, 11)}></Divider>
                            <InfoItem
                                label={"Сколько осталось до экзамена"}
                                info={props.value.Deadline}
                            ></InfoItem>
                            <Divider sx={themes.dividerStyle(12, 11)}></Divider>
                            <InfoItem
                                label={"Место занятий"}
                                info={props.value.Location.Type}
                            ></InfoItem>
                            <Divider sx={themes.dividerStyle(12, 11)}></Divider>
                            <InfoItem
                                label={"Где"}
                                info={props.value.Location.Stations.reduce((acc, value, index) => acc + " м. " + value.Location + (index !== props.value.Location.Stations.length - 1 ? ", " : ""), "")}
                            ></InfoItem>
                            <Divider sx={themes.dividerStyle(12, 11)}></Divider>
                            <InfoItem
                                label={"Количество занятий"}
                                info={props.value.LessonsPerWeek}
                            ></InfoItem>
                            <Divider sx={themes.dividerStyle(12, 11)}></Divider>
                            <InfoItem
                                label={"Удобное время"}
                                info={props.value.ScheduleContent}
                            ></InfoItem>
                            <Divider sx={themes.dividerStyle(12, 11)}></Divider>
                            <CommentItem
                                label={"Комментарий"}
                                info={props.value.Comment}
                                attachments={props.value.FileAttachments}
                            ></CommentItem>
                        </Box>
                    ) : (
                        <Box sx={themes.itemContainerStyle}>
                            <InfoItem
                                label={"Предмет"}
                                info={props.value.Discipline}
                            ></InfoItem>
                            <Divider sx={themes.dividerStyle(12, 11)}></Divider>
                            <InfoItem
                                label={"Тема"}
                                info={props.value.Theme}
                            ></InfoItem>
                            <Divider sx={themes.dividerStyle(12, 11)}></Divider>
                            <InfoItem
                                label={"Для кого"}
                                info={props.value.Audience}
                            ></InfoItem>
                            <Divider sx={themes.dividerStyle(12, 11)}></Divider>
                            <InfoItem
                                label={"Курс"}
                                info={props.value.Course}
                            ></InfoItem>
                            <Divider sx={themes.dividerStyle(12, 11)}></Divider>
                            <InfoItem
                                label={"ВУЗ"}
                                info={props.value.Edu}
                            ></InfoItem>
                            <Divider sx={themes.dividerStyle(12, 11)}></Divider>
                            <InfoItem
                                label={"Сроки"}
                                info={props.value.Deadline}
                            ></InfoItem>
                            <Divider sx={themes.dividerStyle(12, 11)}></Divider>
                            <InfoItem
                                label={"Детали"}
                                info={props.value.Details}
                            ></InfoItem>
                            <Divider sx={themes.dividerStyle(12, 11)}></Divider>
                            <InfoItem
                                label={"Дополнительные требования"}
                                info={props.value.AdditionalInfo}
                            ></InfoItem>
                            <Divider sx={themes.dividerStyle(12, 11)}></Divider>
                            <InfoItem
                                label={"Сопутствующие услуги"}
                                info={props.value.AdditionalService}
                            ></InfoItem>
                            <Divider sx={themes.dividerStyle(12, 11)}></Divider>
                            <CommentItem
                                label={"Комментарий"}
                                info={props.value.Comment}
                                attachments={props.value.FileAttachments}
                            ></CommentItem>
                        </Box>
                    )}
                    <Box sx={themes.postInfoContainerStyle}>
                        <Box sx={themes.dateContainerStyle}>
                            <Typography sx={themes.dateLabelStyle}>Дата:</Typography>
                            <Typography sx={themes.dateStyle}>{props.value.OrderDate.toLocaleString("default", { day: "numeric", month: "long", year: "numeric" })}</Typography>
                        </Box>
                        <Box sx={themes.numberContainerStyle}>
                            <Typography sx={themes.numberLabelStyle}>Номер:</Typography>
                            <Typography sx={themes.numberStyle}>{props.value.OrderNumber}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <ModalReport
                open={dialogReportOpen}
                handleDiscard={discardReportDialog}
                handleReport={submitReportDialog}
            ></ModalReport>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackBarOpen}
                autoHideDuration={3000}
                onClose={hideSnackBar}
            >
                <Box sx={themes.snackBarContentStyle}>
                    <ThemeProvider theme={themes.CheckIconTheme}>
                        <CheckCircleSharp />
                    </ThemeProvider>
                    <Typography sx={themes.snackTextStyle}>Жалоба отправлена</Typography>
                    <ThemeProvider theme={themes.CloseSnackBarIconTheme}>
                        <IconButton
                            onClick={hideSnackBar}
                            disableRipple
                        >
                            <CloseRounded></CloseRounded>
                        </IconButton>
                    </ThemeProvider>
                </Box>
            </Snackbar>
        </Card>
    );
});

export default memo(InfoCard);
