import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, CircularProgress, Paper, Typography } from "@mui/material";
import SingleNotification from "./SingleNotification/SingleNotification";
import * as themes from "./NotificationsPopperContentThemes";
import * as textThemes from "themes/TextThemes";
import NotificationsStore from "store/NotificationsStore";
import { useTranslation } from "react-i18next";
import EmptyNotifications from "components/NotificationsBodyBlock/EmptyNotifications/EmptyNotifications";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const NotificationsPopperContent = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const handleReadAllNotifications = async () => {
        await NotificationsStore.ReadAllNotifications();
    };

    return (
        <Box>
            <Paper sx={themes.Paper}>
                <Box sx={themes.TitleBlock}>
                    <Typography sx={textThemes.HeadlineH5Black}>{t("notifications.notifications")}</Typography>
                    <Button
                        disableRipple
                        sx={themes.ReadAllButton}
                        disabled={NotificationsStore.notifications.length === 0}
                        onClick={handleReadAllNotifications}
                    >
                        {t("notifications.readAll")}
                    </Button>
                </Box>
                {!NotificationsStore.loadingNotificationsData ? (
                    <>
                        {NotificationsStore.notificationsFiltered.length > 0 ? (
                            <Paper sx={themes.PopoverStyle}>
                                {NotificationsStore.notificationsFiltered.map((item) => (
                                    <SingleNotification
                                        key={item.ID}
                                        notification={item}
                                    />
                                ))}
                            </Paper>
                        ) : (
                            <EmptyNotifications />
                        )}
                    </>
                ) : (
                    <ThemeProvider theme={themes.CircularProgressTheme}>
                        <Box sx={themes.progressContainerStyle}>
                            <CircularProgress />
                        </Box>
                    </ThemeProvider>
                )}
                <Button
                    disableRipple
                    sx={themes.AllNotificationsButton(NotificationsStore.loadingNotificationsData)}
                    onClick={() => navigate("/notifications")}
                >
                    {t("notifications.allNotifications")}
                </Button>
            </Paper>
        </Box>
    );
});

export default memo(NotificationsPopperContent);
