import React from "react";
import { Box, Typography } from "@mui/material";
import * as themes from "./TextBlockThemes";
import * as textThemes from "themes/TextThemes";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import SButton from "components/UI/SButton";
import { observer } from "mobx-react-lite";
import UserStore from "store/UserStore";
import AuthorizationStore from "store/AuthorizationStore";
import { useNavigate } from "react-router-dom";

const TextBlock = observer(({ children, ...props }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleClickSignUp = () => {
        AuthorizationStore.setPreviousLocationPath("/cashback");
        AuthorizationStore.setCurrentAuthorizationStep("SignIn");
        AuthorizationStore.setPreSMSPage(null);
        AuthorizationStore.setTemporaryEmail("");
        AuthorizationStore.setTemporaryPassword("");
        AuthorizationStore.setTemporaryPhoneNumber("");
        navigate("/authorization");
    };

    return (
        <Box sx={themes.TextBlock}>
            <Box sx={themes.TextBlockContent(UserStore.isAuth !== true)}>
                <Typography sx={textThemes.HeadlineH2Black}>{t("promoPages.cashback.cashbackFromStudyBuddy")}</Typography>
                <Typography sx={textThemes.Body1Black}>{t("promoPages.cashback.debitCashback")}</Typography>
            </Box>

            {UserStore.isAuth !== true ? (
                <SButton
                    onClick={handleClickSignUp}
                    style={themes.Button}
                    variant="unshadowed"
                    padding="20px 26px"
                >
                    {t("promoPages.cashback.signUp")}
                </SButton>
            ) : null}
        </Box>
    );
});

export default memo(TextBlock);
