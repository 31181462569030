export const teacherInfoCardStyle = (styleMode, medias) => {
    if (styleMode === 0) {
        return {
            boxShadow: "none",
            display: "flex",
            boxSizing: "border-box",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: medias.sm ? 20 : 16,
            gap: 20,
            width: medias.sm ? 907 : 328,
            height: medias.sm ? 415 : 601,
            background: "#FFFFFF",
            borderRadius: "20px",
            flex: "none",
            order: 0,
            flexGrow: 0,
            position: "relative",
        };
    }

    if (styleMode === 1) {
        return {
            boxShadow: "none",
            display: "flex",
            boxSizing: "border-box",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: medias.sm ? 20 : 20,
            gap: 20,
            width: medias.sm ? 907 : 360, //320,
            height: medias.sm ? 370 : "fit-content",
            background: "#FFFFFF",
            borderRadius: "20px",
            flex: "none",
            order: 0,
            flexGrow: 0,
            // alignItems: "center",
        };
    }
};

export const teacherInfoContainerStyle = (styleMode, medias) => {
    if (styleMode === 0) {
        return {
            display: "flex",
            flexDirection: medias.sm ? "row" : "column",
            alignItems: "flex-start",
            gap: 20,
        };
    }

    if (styleMode === 1) {
        return {
            display: "flex",
            flexDirection: medias.sm ? "row" : "column",
            alignItems: medias.sm ? "flex-start" : "center",
            gap: 20,
            width: medias.sm ? "fit-content" : "320px",
        };
    }
};
