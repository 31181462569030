export const attachedContentContainerStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 12,
    padding: "16px",
    boxSizing: "border-box",
    gap: "10px",
    borderRadius: "16px",
    background: "#F5F5F7",
};

export const commentTextStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const attachedFilesContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const fileContainerStyle = {
    display: "flex",
    alignItems: "flex-start",
    gap: "10px",
    alignSelf: "stretch",
};

export const fileInfoContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "3px",
    flex: "1 0 0",
};

export const fileNameStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "135%",
};

export const fileSizeStyle = {
    color: "var(--text-grey, #9E9EB0)",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
};
