import { createTheme } from "@mui/material/styles";

export const ratingContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "center",
    width: "100%",
};

export const RatingTheme = createTheme({
    components: {
        MuiRating: {
            styleOverrides: {
                root: {
                    gap: "1px",
                    "& *": {
                        transform: "none!important",
                    },
                    svg: {
                        width: "36px!important",
                        height: "36px!important",
                    },
                },
            },
        },
    },
});

export const dividerStyle = (marginTop, marginBottom) => {
    return {
        width: "100%",
        marginTop: marginTop,
        marginBottom: marginBottom,
    };
};

export const headerTextStyle = {
    color: "var(--text-black, #222)",
    textAlign: "center",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
};
