export const ModalSettingsHeader = {
    padding: "16px 0px 16px 40px",
    backgroundColor: "#F7F7F7",
    borderRadius: "20px 20px 0px 0px",
    borderBottom: "1px solid #BBBBC8",
    textAlign: "left",
    width: "100%",
};

export const ModalSettingsBottom = (condition, padding) => {
    return {
        backgroundColor: "#fff",
        borderRadius: condition ? "20px" : "0px 0px 20px 20px",
        padding: padding ? padding : "20px 40px 0px 40px",
    };
};

export const ModalSettings = {
    position: "absolute",
    marginLeft: "50vw",
    top: "50%",
    transform: "translate(-50%, -50%)",
    justifyContent: "flex-end",
};
