import * as theme2 from "components/OrdersBodyBlock/OrderCard/StyledOrderCardThemes";
import { Box, Divider, Typography } from "@mui/material";
import OrderDate from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/OrderDate/OrderDate";
import TargetBoxRounded from "components/OrdersBodyBlock/TutorOrders/OrderCards/CommonCardComponents/TargetBoxRounded/TargetBoxRounded";
import OrderSoloAvatar from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/OrderSoloAvatar/OrderSoloAvatar";
import HiddenInfo from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/HiddenInfo/HiddenInfo";
import OrderStatusRounded from "components/OrdersBodyBlock/TutorOrders/OrderCards/CommonCardComponents/OrderStatusRounded/OrderStatusRounded";
import CancellationAuthorText from "./CancellationAuthorText/CancellationAuthorText";
import PriceFormater from "components/OrdersBodyBlock/TutorOrders/OrderCards/CommonCardComponents/PriceFormater/PriceFormater";
import { memo } from "react";
import * as textThemes from "themes/TextThemes";
import * as themes from "./CancelledCardThemes";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";

const CancelledCard = ({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <Box sx={theme2.StyledOrderCard(medias)}>
            <Box sx={themes.TopBlock}>
                <Box sx={themes.TopCardWrapper}>
                    <Box sx={themes.TitleBlock}>
                        <Typography
                            variant="h6"
                            sx={textThemes.HeadlineH5Black}
                        >
                            {props.orderInfo.title}
                        </Typography>
                    </Box>
                    <OrderDate date={props.orderInfo.date} />
                </Box>
                <Box sx={themes.TargetSubjWrapper}>
                    <TargetBoxRounded>{props.orderInfo.target}</TargetBoxRounded>
                    <TargetBoxRounded>{props.orderInfo.subject}</TargetBoxRounded>
                </Box>
            </Box>

            <Box>
                <Box sx={themes.AvatarPriceWrapper}>
                    {!props.orderInfo.hiddenUser && (
                        <OrderSoloAvatar
                            name={props.orderInfo.name}
                            avatar={props.orderInfo.avatar}
                        />
                    )}
                    {props.orderInfo.hiddenUser && <HiddenInfo />}
                    <Box>
                        <Typography
                            variant="span"
                            sx={textThemes.Body2Black}
                        >
                            {t("orders.tutorOrders.price")}
                        </Typography>
                        <PriceFormater price={props.orderInfo.price} />
                    </Box>
                </Box>

                <Box>
                    <Divider sx={themes.divider} />
                    <Box sx={themes.BottomCardWrapper}>
                        <CancellationAuthorText cancelStatus={props.orderInfo.cancelStatus} />
                        <OrderStatusRounded status={props.orderInfo.status} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default memo(CancelledCard);
