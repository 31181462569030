const ExpandIconOn = function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
        >
            <circle
                cx="14"
                cy="14"
                r="14"
                fill="#DADADF"
            />
            <path
                d="M7 12.0703L13.364 18.4343C13.7545 18.8248 14.3877 18.8248 14.7782 18.4343L21.1421 12.0703"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default ExpandIconOn;
