import { makeAutoObservable } from "mobx";
import * as NotificationService from "../API/services/grpc/NotificationService";
import { handleStoreActionError } from "../API/plugins/errorHandlers";
import UserStore from "./UserStore";
import { getCookie } from "../API/utils";

class NotificationsStore {
    loadingNotificationsData = true;

    notifications = [];
    notificationsFiltered = [];

    Init() {
        this.Load();
    }

    constructor() {
        this.Init();
        makeAutoObservable(this);
    }

    getHourWord(number) {
        if (number === 1) {
            return "main.hourOne";
        } else if (number >= 2 && number <= 4) {
            return "main.hourFew";
        } else {
            return "main.hourMany";
        }
    }

    getDayWord(number) {
        if (number === 1) {
            return "main.dayOne";
        } else if (number >= 2 && number <= 4) {
            return "main.dayFew";
        } else {
            return "main.dayMany";
        }
    }

    getPointWord(number) {
        if (number === 1) {
            return "notifications.pointOne";
        } else if (number >= 2 && number <= 4) {
            return "notifications.pointFew";
        } else {
            return "notifications.pointMany";
        }
    }

    getNewResponseWord(number) {
        if (number === 1) {
            return "notifications.newResponseOne";
        } else if (number >= 2 && number <= 4) {
            return "notifications.newResponseFew";
        } else {
            return "notifications.newResponseMany";
        }
    }

    formatDate(date) {
        const months = [
            "main.months.atJanuary",
            "main.months.atFebruary",
            "main.months.atMarch",
            "main.months.atApril",
            "main.months.atMay",
            "main.months.atJune",
            "main.months.atJuly",
            "main.months.atAugust",
            "main.months.atSeptember",
            "main.months.atOctober",
            "main.months.atNovember",
            "main.months.atDecember",
        ];

        const day = date.getDate();

        const month = months[date.getMonth()];

        const hours = date.getHours().toString().padStart(2, "0");

        const minutes = date.getMinutes().toString().padStart(2, "0");

        return { day: day, month: month, hours: hours, minutes: minutes };
    }

    formatDateRange(startDate, endDate) {
        const months = [
            "main.months.atJanuary",
            "main.months.atFebruary",
            "main.months.atMarch",
            "main.months.atApril",
            "main.months.atMay",
            "main.months.atJune",
            "main.months.atJuly",
            "main.months.atAugust",
            "main.months.atSeptember",
            "main.months.atOctober",
            "main.months.atNovember",
            "main.months.atDecember",
        ];

        const daysOfWeek = [
            "main.daysOfWeek.sundayShort",
            "main.daysOfWeek.mondayShort",
            "main.daysOfWeek.tuesdayShort",
            "main.daysOfWeek.wednesdayShort",
            "main.daysOfWeek.thursdayShort",
            "main.daysOfWeek.fridayShort",
            "main.daysOfWeek.saturdayShort",
        ];

        const day = startDate.getDate();

        const month = months[startDate.getMonth()];

        const dayOfWeek = daysOfWeek[startDate.getDay()];

        const startHours = startDate.getHours().toString().padStart(2, "0");

        const startMinutes = startDate.getMinutes().toString().padStart(2, "0");

        const endHours = endDate.getHours().toString().padStart(2, "0");

        const endMinutes = endDate.getMinutes().toString().padStart(2, "0");

        return { day: day, month: month, weekDay: dayOfWeek, time: `${startHours}:${startMinutes}–${endHours}:${endMinutes}` };
    }

    formatCreatedAtDate(date) {
        if (date !== undefined) {
            const months = [
                "main.months.atJanuary",
                "main.months.atFebruary",
                "main.months.atMarch",
                "main.months.atApril",
                "main.months.atMay",
                "main.months.atJune",
                "main.months.atJuly",
                "main.months.atAugust",
                "main.months.atSeptember",
                "main.months.atOctober",
                "main.months.atNovember",
                "main.months.atDecember",
            ];

            const now = new Date();

            const diff = Math.abs(Math.floor((now - new Date(date)) / 1000));

            if (diff === 0 || diff === null) {
                return { diff: null, type: "main.rightNow", date: null };
            } else if (diff <= 60) {
                return { diff: diff, type: "main.sec", date: null };
            } else if (diff <= 3600) {
                const minutes = Math.floor(diff / 60);

                return { diff: minutes, type: "main.min", date: null };
            } else if (diff <= 86400) {
                const hours = Math.floor(diff / 3600);

                const hourString = this.getHourWord(hours);

                return { diff: hours, type: hourString, date: null };
            } else if (diff <= 604800) {
                const days = Math.floor(diff / 86400);

                const dayString = this.getDayWord(days);

                return { diff: days, type: dayString, date: null };
            } else if (date.getFullYear() === now.getFullYear()) {
                const day = date.getDate();

                const month = months[date.getMonth()];

                return { diff: null, type: { day: day, month: month }, date: null };
            } else {
                const formattedDate = new Date(date).toLocaleDateString("ru-RU", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "2-digit",
                });

                return { diff: null, type: null, date: formattedDate };
            }
        } else return { diff: ``, type: ``, date: `` };
    }

    SetLoadingState(field, value) {
        this[field] = value;
    }

    async FetchPageData() {
        await this.FetchNotificationsData();
    }

    async FetchNotificationsData() {
        let metaData = {
            authorization: "Bearer " + getCookie(`${UserStore.role}AccessToken`),
        };

        this.SetLoadingState("loadingNotificationsData", true);

        await NotificationService.GetRecentNotifications(null, metaData, async (error, response) => {
            if (error) {
                console.log(error);
                handleStoreActionError({
                    error,
                    customErrorText: "notifications.errors.fetchNotificationsDataError",
                    dispatch: true,
                });
            } else {
                let data = response.toObject().notificationsList;

                this.FillNotificationsData(data, "default");
                this.SetLoadingState("loadingNotificationsData", false);
            }

            this.SetLoadingState("loadingNotificationsData", false);
        });
    }

    FetchStreamNotificationsData() {
        let metaData = {
            authorization: "Bearer " + getCookie(`${UserStore.role}AccessToken`),
        };

        this.SetLoadingState("loadingNotificationsData", true);

        let stream = NotificationService.GetStreamNotifications(null, metaData);

        stream.on("data", (streamData) => {
            console.log(streamData.toObject().notification);
            let data = [streamData.toObject().notification];

            this.FillNotificationsData(data, "prepend");
        });
    }

    FillNotificationsData(data, contentLoadingMode) {
        console.log(data);
        let newDataToPrepend = [];

        switch (contentLoadingMode) {
            case "default":
                this.notifications = [];
                break;
            case "replace":
                this.notifications = [];
                break;
            case "prepend":
                break;
            default:
                break;
        }

        data.forEach((dataValue, index) => {
            let targetValue = null;

            const mapper = {
                singleResponse: "single",
                personal: "personal",
                cashback: "cashback",
                requestToReschedule: "rescheduleRequest",
                rescheduled: "rescheduled",
                startLesson: "startLesson",
                cancelLesson: "cancelLesson",
                responses: "response",
                newOrder: "newOrder",
                paid: "paid",
                start: "startLesson",
                cancel: "cancelLesson",
            };

            Object.keys(dataValue)
                .filter((value) => dataValue[value])
                .forEach((key) => {
                    if (Object.prototype.hasOwnProperty.call(dataValue, key)) {
                        targetValue = dataValue[key];
                    }
                });
            newDataToPrepend.push({
                ID: index,
                category: "student", //mocked
                notificationUuid: targetValue.notificationUuid,
                type: mapper[targetValue.type],
                creator: {
                    uuid: targetValue?.creator?.uuid,
                    name: targetValue?.creator?.name,
                    photo: {
                        link: targetValue?.creator?.photo?.link,
                    },
                },
                order: {
                    id: targetValue?.order?.id,
                    title: targetValue?.order?.title,
                    date: new Date(targetValue?.order?.date),
                },
                cardTail: targetValue?.cardTail,
                value: targetValue?.value,
                count: targetValue?.count,
                actionType: targetValue?.actionType,
                read: targetValue.read,
                correctionDeadline: new Date(), //mocked
                correctionDaysLeft: 5, //mocked
                currentTime: {
                    start: new Date(), //mocked
                    end: new Date(), //mocked
                },
                requestedTime: {
                    start: new Date(), //mocked
                    end: new Date(), //mocked
                },
                postponementDecision: {
                    answer: targetValue?.postponementDecision?.answer,
                    accept: targetValue?.postponementDecision?.accept,
                },
                newLessonDate: {
                    start: new Date(), //mocked
                    end: new Date(), //mocked
                },
                createdAt: new Date(targetValue.createdAt),
            });
        });

        this.notifications = [...newDataToPrepend, ...this.notifications];

        this.FilterNotifications();
    }

    FilterNotifications() {
        this.notificationsFiltered = this.notifications
            .filter((item) => item.category === UserStore.role)
            .sort((a, b) => {
                return a.createdAt < b.createdAt ? 1 : -1;
            });
    }

    MarkNotificationsAsRead(data) {
        this.notifications = this.notifications.map((value) =>
            data.includes(value.notificationUuid)
                ? {
                      ...value,
                      read: true,
                  }
                : value,
        );
    }

    async ReadAllNotifications() {
        await this.ReadNotification();
    }

    async ReadNotification(notificationUuid) {
        let data = {
            notificationUuids: notificationUuid ? [notificationUuid] : [...this.notificationsFiltered.map((value) => value.notificationUuid)],
        };

        let metaData = {
            authorization: "Bearer " + getCookie(`${UserStore.role}AccessToken`),
        };

        //this.SetLoadingState("loadingNotificationsData", true);

        await NotificationService.ReadNotifications(data, metaData, async (error, response) => {
            if (error) {
                handleStoreActionError({
                    error,
                    customErrorText: "notifications.errors.readNotificationError",
                    dispatch: true,
                });
            } else {
                this.MarkNotificationsAsRead(data.notificationUuids);
                this.FilterNotifications();
                //this.SetLoadingState("loadingNotificationsData", false);
            }

            //this.SetLoadingState("loadingNotificationsData", false);
        });
    }

    makeDecisionAboutReschedule(index, decision) {
        this.notifications[index].postponementDecision.answer = true;
        this.notifications[index].postponementDecision.accept = decision;
    }

    Load() {
        /*this.notifications = [
            {
                category: "student",
                notificationUuid: "123e4567-e89b-12d3-a456-426614174000",
                type: "single",
                creator: {
                    uuid: "creator-uuid-1",
                    name: "Александра А.",
                    photo: {
                        link: AleksandraAvatar,
                    },
                },
                order: {
                    id: "order-1",
                    title: "Английский язык, подготовка к экзамену в ВУЗе",
                    date: new Date(new Date().setHours(new Date().getHours() + 8)),
                },
                cardTail: null,
                value: null,
                count: null,
                actionType: null,
                read: false,
                correctionDeadline: null,
                correctionDaysLeft: null,
                currentTime: {
                    start: null,
                    end: null,
                },
                requestedTime: {
                    start: null,
                    end: null,
                },
                postponementDecision: {
                    answer: null,
                    accept: null,
                },
                newLessonDate: {
                    start: null,
                    end: null,
                },
                createdAt: new Date(new Date().setMinutes(new Date().getMinutes() - 5)),
            },
            {
                category: "student",
                notificationUuid: "123e4567-e89b-12d3-a456-426614174002",
                type: "cashback",
                creator: {
                    uuid: null,
                    name: null,
                    photo: {
                        link: null,
                    },
                },
                order: {
                    id: "order-3",
                    title: "Английский язык",
                    date: new Date(new Date().setHours(new Date().getHours() - 15)),
                },
                cardTail: null,
                value: 500,
                count: null,
                actionType: null,
                read: false,
                correctionDeadline: null,
                correctionDaysLeft: null,
                currentTime: {
                    start: null,
                    end: null,
                },
                requestedTime: {
                    start: null,
                    end: null,
                },
                postponementDecision: {
                    answer: null,
                    accept: null,
                },
                newLessonDate: {
                    start: null,
                    end: null,
                },
                createdAt: new Date(new Date().setHours(new Date().getHours() - 2)),
            },
            {
                category: "student",
                notificationUuid: "123e4567-e89b-12d3-a456-426614174001",
                type: "personal",
                creator: {
                    uuid: "creator-uuid-2",
                    name: "Екатерина А.",
                    photo: {
                        link: EkaterinaAvatar,
                    },
                },
                order: {
                    id: "order-2",
                    title: "Дипломная работа, Информационная безопасность",
                    date: new Date(new Date().setHours(new Date().getHours() + 55)),
                },
                cardTail: null,
                value: null,
                count: null,
                actionType: "sendForReview",
                read: false,
                correctionDeadline: null,
                correctionDaysLeft: null,
                currentTime: {
                    start: null,
                    end: null,
                },
                requestedTime: {
                    start: null,
                    end: null,
                },
                postponementDecision: {
                    answer: null,
                    accept: null,
                },
                newLessonDate: {
                    start: null,
                    end: null,
                },
                createdAt: new Date(new Date().setHours(new Date().getHours() - 24)),
            },
            {
                category: "student",
                notificationUuid: "123e4567-e89b-12d3-a456-426614174003",
                type: "rescheduleRequest",
                creator: {
                    uuid: "creator-uuid-3",
                    name: "Екатерина М.",
                    photo: {
                        link: null,
                    },
                },
                order: {
                    id: "order-4",
                    title: "Теория вероятности",
                    date: new Date(new Date().setHours(new Date().getHours() - 1)),
                },
                cardTail: null,
                value: null,
                count: null,
                actionType: null,
                read: false,
                correctionDeadline: null,
                correctionDaysLeft: null,
                currentTime: {
                    start: new Date(new Date().setHours(new Date().getHours() + 8)),
                    end: new Date(new Date().setHours(new Date().getHours() + 9)),
                },
                requestedTime: {
                    start: new Date(new Date().setHours(new Date().getHours() + 10)),
                    end: new Date(new Date().setHours(new Date().getHours() + 11)),
                },
                postponementDecision: {
                    answer: null,
                    accept: null,
                },
                newLessonDate: {
                    start: null,
                    end: null,
                },
                createdAt: new Date(new Date().setHours(new Date().getHours() - 73)),
            },
            {
                category: "student",
                notificationUuid: "123e4567-e89b-12d3-a456-426614174007",
                type: "response",
                creator: {
                    uuid: null,
                    name: null,
                    photo: {
                        link: null,
                    },
                },
                order: {
                    id: "order-8",
                    title: "Английский язык, Подготовка к экзамену в ВУЗе",
                    date: new Date(new Date().setHours(new Date().getHours() - 1)),
                },
                cardTail: null,
                value: null,
                count: 3,
                actionType: null,
                read: false,
                correctionDeadline: null,
                correctionDaysLeft: null,
                currentTime: {
                    start: null,
                    end: null,
                },
                requestedTime: {
                    start: null,
                    end: null,
                },
                postponementDecision: {
                    answer: null,
                    accept: null,
                },
                newLessonDate: {
                    start: null,
                    end: null,
                },
                createdAt: new Date(new Date().setHours(new Date().getHours() - 140)),
            },
            {
                category: "student",
                notificationUuid: "123e4567-e89b-12d3-a456-426614174005",
                type: "startLesson",
                creator: {
                    uuid: null,
                    name: null,
                    photo: {
                        link: null,
                    },
                },
                order: {
                    id: "order-6",
                    title: "Английский язык",
                    date: new Date(new Date().setHours(new Date().getHours() - 1)),
                },
                cardTail: null,
                value: null,
                count: null,
                actionType: null,
                read: false,
                correctionDeadline: null,
                correctionDaysLeft: null,
                currentTime: {
                    start: null,
                    end: null,
                },
                requestedTime: {
                    start: null,
                    end: null,
                },
                postponementDecision: {
                    answer: null,
                    accept: null,
                },
                newLessonDate: {
                    start: null,
                    end: null,
                },
                createdAt: new Date(new Date().setHours(new Date().getHours() - 250)),
            },
            {
                category: "student",
                notificationUuid: "123e4567-e89b-12d3-a456-426614174004",
                type: "rescheduled",
                creator: {
                    uuid: "creator-uuid-4",
                    name: "Александра А.",
                    photo: {
                        link: null,
                    },
                },
                order: {
                    id: "order-5",
                    title: "Английский язык",
                    date: new Date(new Date().setHours(new Date().getHours() - 1)),
                },
                cardTail: null,
                value: null,
                count: null,
                actionType: null,
                read: false,
                correctionDeadline: null,
                correctionDaysLeft: null,
                currentTime: {
                    start: null,
                    end: null,
                },
                requestedTime: {
                    start: null,
                    end: null,
                },
                postponementDecision: {
                    answer: null,
                    accept: null,
                },
                newLessonDate: {
                    start: new Date(new Date().setHours(new Date().getHours() - 2000)),
                    end: new Date(new Date().setHours(new Date().getHours() - 1999)),
                },
                createdAt: new Date(new Date().setHours(new Date().getHours() - 2010)),
            },
            {
                category: "student",
                notificationUuid: "123e4567-e89b-12d3-a456-426614174006",
                type: "cancelLesson",
                creator: {
                    uuid: "creator-uuid-4",
                    name: "Александра А.",
                    photo: {
                        link: null,
                    },
                },
                order: {
                    id: "order-7",
                    title: "Английский язык",
                    date: new Date(new Date().setHours(new Date().getHours() - 1)),
                },
                cardTail: null,
                value: null,
                count: null,
                actionType: null,
                read: false,
                correctionDeadline: null,
                correctionDaysLeft: null,
                currentTime: {
                    start: null,
                    end: null,
                },
                requestedTime: {
                    start: null,
                    end: null,
                },
                postponementDecision: {
                    answer: null,
                    accept: null,
                },
                newLessonDate: {
                    start: null,
                    end: null,
                },
                createdAt: new Date(new Date().setSeconds(new Date().getSeconds() - 10)),
            },
            {
                category: "tutor",
                notificationUuid: "123e4567-e89b-12d3-a456-426614174008",
                type: "newOrder",
                creator: {
                    uuid: null,
                    name: null,
                    photo: {
                        link: null,
                    },
                },
                order: {
                    id: "order-10",
                    title: "Дипломная работа, Макроэкономика",
                    date: new Date(new Date().setMinutes(new Date().getMinutes() - 5)),
                },
                cardTail: null,
                value: null,
                count: null,
                actionType: null,
                read: false,
                correctionDeadline: null,
                correctionDaysLeft: null,
                currentTime: {
                    start: null,
                    end: null,
                },
                requestedTime: {
                    start: null,
                    end: null,
                },
                postponementDecision: {
                    answer: null,
                    accept: null,
                },
                newLessonDate: {
                    start: null,
                    end: null,
                },
                createdAt: new Date(new Date().setMinutes(new Date().getMinutes() - 5)),
            },
            {
                category: "tutor",
                notificationUuid: "123e4567-e89b-12d3-a456-426614174009",
                type: "paid",
                creator: {
                    uuid: "creator-uuid-7",
                    name: "Станислав К.",
                    photo: {
                        link: null,
                    },
                },
                order: {
                    id: "order-11",
                    title: "Английский язык",
                    date: new Date(new Date().setHours(new Date().getHours() - 2)),
                },
                cardTail: "5678",
                value: 1500,
                count: null,
                actionType: null,
                read: false,
                correctionDeadline: null,
                correctionDaysLeft: null,
                currentTime: {
                    start: null,
                    end: null,
                },
                requestedTime: {
                    start: null,
                    end: null,
                },
                postponementDecision: {
                    answer: null,
                    accept: null,
                },
                newLessonDate: {
                    start: null,
                    end: null,
                },
                createdAt: new Date(new Date().setHours(new Date().getHours() - 2)),
            },
            {
                category: "tutor",
                notificationUuid: "123e4567-e89b-12d3-a456-426614174001",
                type: "personal",
                creator: {
                    uuid: "creator-uuid-2",
                    name: "Екатерина А.",
                    photo: {
                        link: EkaterinaAvatar,
                    },
                },
                order: {
                    id: "order-2",
                    title: "Дипломная работа, Информационная безопасность",
                    date: new Date(new Date().setHours(new Date().getHours() - 2)),
                },
                cardTail: null,
                value: null,
                count: null,
                actionType: "sendForCorrection",
                read: false,
                correctionDeadline: new Date(new Date().setDate(new Date().getDate() + 5)),
                correctionDaysLeft: 5,
                currentTime: {
                    start: null,
                    end: null,
                },
                requestedTime: {
                    start: null,
                    end: null,
                },
                postponementDecision: {
                    answer: null,
                    accept: null,
                },
                newLessonDate: {
                    start: null,
                    end: null,
                },
                createdAt: new Date(new Date().setDate(new Date().getDate() - 1)),
            },
            {
                category: "tutor",
                notificationUuid: "123e4567-e89b-12d3-a456-426614174033",
                type: "rescheduleRequest",
                creator: {
                    uuid: "creator-uuid-3",
                    name: "Екатерина М.",
                    photo: {
                        link: null,
                    },
                },
                order: {
                    id: "order-4",
                    title: "Теория вероятности",
                    date: new Date(new Date().setHours(new Date().getHours() - 1)),
                },
                cardTail: null,
                value: null,
                count: null,
                actionType: null,
                read: false,
                correctionDeadline: null,
                correctionDaysLeft: null,
                currentTime: {
                    start: new Date(new Date().setHours(new Date().getHours() + 8)),
                    end: new Date(new Date().setHours(new Date().getHours() + 9)),
                },
                requestedTime: {
                    start: new Date(new Date().setHours(new Date().getHours() + 10)),
                    end: new Date(new Date().setHours(new Date().getHours() + 11)),
                },
                postponementDecision: {
                    answer: null,
                    accept: null,
                },
                newLessonDate: {
                    start: null,
                    end: null,
                },
                createdAt: new Date(new Date().setHours(new Date().getHours() - 73)),
            },
            {
                category: "tutor",
                notificationUuid: "123e4567-e89b-12d3-a456-426614174005",
                type: "startLesson",
                creator: {
                    uuid: "creator-uuid-3",
                    name: "Станислав К.",
                    photo: {
                        link: null,
                    },
                },
                order: {
                    id: "order-6",
                    title: "Английский язык",
                    date: new Date(new Date().setHours(new Date().getHours() - 1)),
                },
                cardTail: null,
                value: null,
                count: null,
                actionType: null,
                read: false,
                correctionDeadline: null,
                correctionDaysLeft: null,
                currentTime: {
                    start: null,
                    end: null,
                },
                requestedTime: {
                    start: null,
                    end: null,
                },
                postponementDecision: {
                    answer: null,
                    accept: null,
                },
                newLessonDate: {
                    start: null,
                    end: null,
                },
                createdAt: new Date(new Date().setHours(new Date().getHours() - 10250)),
            },
        ];*/
    }
}

export default new NotificationsStore();
