import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import YesNoBlock from "components/AccountBodyBlock/StudentAccount/Stories/Stories/YesNoBlock/YesNoBlock";
import LikeDislikeBlock from "components/AccountBodyBlock/StudentAccount/Stories/Stories/LikeDislikeBlock/LikeDislikeBlock";
import * as themes from "components/AccountBodyBlock/StudentAccount/Stories/Stories/StoryThemes";
import * as textThemes from "themes/TextThemes";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const AlreadyUsedCashbackStory = observer((children, ...props) => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.StoryBox}>
            <Box sx={themes.AlreadyUsedCashbackOuter}>
                <Box sx={themes.StoryForm}>
                    <Typography sx={textThemes.Caption700White}>{t("main.studentAccount.stories.alreadyUsedCashback")}</Typography>
                    <YesNoBlock story="AlreadyUsedCashbackStory" />
                </Box>
            </Box>
            <LikeDislikeBlock story="AlreadyUsedCashbackStory" />
        </Box>
    );
});

export default memo(AlreadyUsedCashbackStory);
