import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import { Button, Dialog, IconButton } from "@mui/material";
import * as themes from "./ModalRefuseThemes";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CloseRounded } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

const ModalRefuse = observer(({ children, ...props }) => {
    const Close = () => {
        props.handleDiscard();
    };

    const ProcessRemoval = (value, event) => {
        props.handleRefuse(value);
    };

    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={props.open}
                onClose={Close}
            >
                <Box sx={themes.dialogContainerStyle}>
                    <ThemeProvider theme={themes.CloseIconTheme}>
                        <IconButton
                            onClick={Close}
                            disableRipple
                        >
                            <CloseRounded></CloseRounded>
                        </IconButton>
                    </ThemeProvider>
                    <Box sx={themes.cardContainerStyle}>
                        <Box sx={themes.headerBlockContainerStyle}>
                            <Typography sx={themes.headerTextStyle}>Отклонить предложение</Typography>
                        </Box>
                        <Box sx={themes.contentBlockContainerStyle}>
                            <Box>
                                <Typography sx={themes.orderRefuseTextStyle}>Вы уверены, что хотите отклонить предложение</Typography>
                                {props.value.Price?.PriceFrom ? (
                                    props.value.Price?.PriceTo ? (
                                        <Typography sx={themes.priceTextStyle(0)}>
                                            {props.value.OrderTitle + ", " + props.value.Price.PriceFrom.toLocaleString()} - {props.value.Price.PriceTo.toLocaleString()} ₽ ?
                                        </Typography>
                                    ) : (
                                        <Typography sx={themes.priceTextStyle(0)}>{props.value.OrderTitle + ", " + props.value.Price.PriceFrom.toLocaleString()} ₽ ?</Typography>
                                    )
                                ) : (
                                    <Typography sx={themes.priceTextStyle(0)}>{props.value.OrderTitle + ", " + "по договоренности"} ?</Typography>
                                )}
                            </Box>
                            <Box sx={themes.avatarContainerStyle}>
                                <ThemeProvider theme={themes.AvatarTheme}>
                                    <Avatar src={props.value.AvatarSrc}></Avatar>
                                </ThemeProvider>
                                <Typography sx={themes.studentTextStyle}>{props.value.StudentFIO}</Typography>
                            </Box>
                        </Box>
                        <Box sx={themes.actionsBlockContainerStyle}>
                            <ThemeProvider theme={themes.ModalActionButtonTheme(0)}>
                                <Button
                                    onClick={Close}
                                    disableRipple
                                >
                                    <Typography style={themes.buttonTextStyle(0)}>Нет, оставить</Typography>
                                </Button>
                            </ThemeProvider>
                            <ThemeProvider theme={themes.ModalActionButtonTheme(1)}>
                                <Button
                                    onClick={ProcessRemoval.bind(this, props.value)}
                                    disableRipple
                                >
                                    <Typography style={themes.buttonTextStyle(1)}>Да, отклонить</Typography>
                                </Button>
                            </ThemeProvider>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </ThemeProvider>
    );
});

export default memo(ModalRefuse);
