import React, { useState } from "react";
import * as AboutUsStyle from "./AboutUsStyle";
import { Box, Typography } from "@mui/material";
import TutorCard from "./TutorCard";
import MainPageStore from "store/MainPageStore";
import { observer } from "mobx-react-lite";
import { ThemeProvider } from "@mui/material/styles";
import { PlayArrow } from "@mui/icons-material";
import OtherTutors from "./OtherTutors/OtherTutors";
import MyPlayer from "./Video/TutorVideo";
import { useMedias } from "API/mediasHook";
import MobileCardsCarousel from "./MobileCardsCarousel/MobileCardsCarousel";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const AboutUs = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const medias = useMedias();

    const [isPlayerVisible, setPlayerVisibility] = useState(false);

    const handlePlayClick = () => {
        setPlayerVisibility(true);
    };

    return (
        <Box style={AboutUsStyle.AboutUsBlock(medias)}>
            <Box style={AboutUsStyle.mainHeader(medias)}>{t("promoPages.main.tutorsAndStudentsAboutService")}</Box>

            <Box style={AboutUsStyle.AboutUsContentBlock(medias)}>
                <Typography style={AboutUsStyle.Text1(medias)}>{t("promoPages.main.weCreatedStudyBuddyToReduce")}</Typography>
                <Typography style={AboutUsStyle.Text2(medias)}>{t("promoPages.main.seeWhatTeachersAndStudents")}</Typography>
            </Box>

            {!medias.sm ? ( // Мобильный экран
                <MobileCardsCarousel />
            ) : (
                <Box>
                    <Box style={AboutUsStyle.AboutUsCardBlock(medias)}>
                        {MainPageStore.tutorsFeedbackList.map((perTutor) => (
                            <TutorCard
                                key={perTutor.ID}
                                tutor={perTutor}
                            />
                        ))}
                    </Box>
                    <Box style={AboutUsStyle.VideoBlock(medias)}>
                        {/* Третий блок - компонент видеоплеера (рендерится при isPlayerVisible === true) */}
                        {isPlayerVisible ? (
                            <MyPlayer
                                style={AboutUsStyle.PlayerBlock(medias)}
                                VideoName={MainPageStore.activeTutor.tutorVideo}
                            />
                        ) : (
                            <img
                                style={AboutUsStyle.ImgBlock(medias)}
                                src={MainPageStore.activeTutor.tutorPhoto}
                                alt="Tutor"
                                onClick={handlePlayClick}
                            />
                        )}
                    </Box>
                </Box>
            )}
            {/*Второй блок - значок запуска*/}
            {!isPlayerVisible && medias.sm && (
                <Box
                    onClick={handlePlayClick}
                    style={AboutUsStyle.tcPlayIconBGStyle(medias)}
                >
                    <ThemeProvider theme={AboutUsStyle.TCPlayIconTheme(medias)}>
                        <PlayArrow />
                    </ThemeProvider>
                </Box>
            )}
            <OtherTutors />
        </Box>
    );
});

export default memo(AboutUs);
