export const AccountDeleted = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    marginTop: "20px",
    marginBottom: "30px",
    alignItems: "center",
    textAlign: "center",
};

export const AccountDeletedContent = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};
