/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require("google-protobuf");

var goog = jspb;

var global = Function("return this")();

goog.exportSymbol("proto.pb.GetCitiesRequest", null, global);
goog.exportSymbol("proto.pb.GetCitiesResponse", null, global);
goog.exportSymbol("proto.pb.GetConditionsBySubjectRequest", null, global);
goog.exportSymbol("proto.pb.GetConditionsBySubjectResponse", null, global);
goog.exportSymbol("proto.pb.GetDistrictsAndUndergroundStationsByCityRequest", null, global);
goog.exportSymbol("proto.pb.GetDistrictsAndUndergroundStationsByCityResponse", null, global);
goog.exportSymbol("proto.pb.GetSubjectsRequest", null, global);
goog.exportSymbol("proto.pb.GetSubjectsResponse", null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.GetCitiesRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.GetCitiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.GetCitiesRequest.displayName = "proto.pb.GetCitiesRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.GetCitiesRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.GetCitiesRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.GetCitiesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.GetCitiesRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.GetCitiesRequest}
 */
proto.pb.GetCitiesRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.GetCitiesRequest();

    return proto.pb.GetCitiesRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.GetCitiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.GetCitiesRequest}
 */
proto.pb.GetCitiesRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.GetCitiesRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.GetCitiesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.GetCitiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.GetCitiesRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.GetCitiesResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.GetCitiesResponse.repeatedFields_, null);
};

goog.inherits(proto.pb.GetCitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.GetCitiesResponse.displayName = "proto.pb.GetCitiesResponse";
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.GetCitiesResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.GetCitiesResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.GetCitiesResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.GetCitiesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.GetCitiesResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                citiesList: jspb.Message.getRepeatedField(msg, 1),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.GetCitiesResponse}
 */
proto.pb.GetCitiesResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.GetCitiesResponse();

    return proto.pb.GetCitiesResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.GetCitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.GetCitiesResponse}
 */
proto.pb.GetCitiesResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.addCities(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.GetCitiesResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.GetCitiesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.GetCitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.GetCitiesResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getCitiesList();
    if (f.length > 0) {
        writer.writeRepeatedString(1, f);
    }
};

/**
 * repeated string cities = 1;
 * @return {!Array<string>}
 */
proto.pb.GetCitiesResponse.prototype.getCitiesList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};

/** @param {!Array<string>} value */
proto.pb.GetCitiesResponse.prototype.setCitiesList = function (value) {
    jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.pb.GetCitiesResponse.prototype.addCities = function (value, opt_index) {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

proto.pb.GetCitiesResponse.prototype.clearCitiesList = function () {
    this.setCitiesList([]);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.GetDistrictsAndUndergroundStationsByCityRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.GetDistrictsAndUndergroundStationsByCityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.GetDistrictsAndUndergroundStationsByCityRequest.displayName = "proto.pb.GetDistrictsAndUndergroundStationsByCityRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.GetDistrictsAndUndergroundStationsByCityRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.GetDistrictsAndUndergroundStationsByCityRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.GetDistrictsAndUndergroundStationsByCityRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.GetDistrictsAndUndergroundStationsByCityRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                city: jspb.Message.getFieldWithDefault(msg, 1, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.GetDistrictsAndUndergroundStationsByCityRequest}
 */
proto.pb.GetDistrictsAndUndergroundStationsByCityRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.GetDistrictsAndUndergroundStationsByCityRequest();

    return proto.pb.GetDistrictsAndUndergroundStationsByCityRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.GetDistrictsAndUndergroundStationsByCityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.GetDistrictsAndUndergroundStationsByCityRequest}
 */
proto.pb.GetDistrictsAndUndergroundStationsByCityRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setCity(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.GetDistrictsAndUndergroundStationsByCityRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.GetDistrictsAndUndergroundStationsByCityRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.GetDistrictsAndUndergroundStationsByCityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.GetDistrictsAndUndergroundStationsByCityRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getCity();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};

/**
 * optional string city = 1;
 * @return {string}
 */
proto.pb.GetDistrictsAndUndergroundStationsByCityRequest.prototype.getCity = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.GetDistrictsAndUndergroundStationsByCityRequest.prototype.setCity = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.GetDistrictsAndUndergroundStationsByCityResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.repeatedFields_, null);
};

goog.inherits(proto.pb.GetDistrictsAndUndergroundStationsByCityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.displayName = "proto.pb.GetDistrictsAndUndergroundStationsByCityResponse";
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.repeatedFields_ = [1, 2];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.GetDistrictsAndUndergroundStationsByCityResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                districtsList: jspb.Message.getRepeatedField(msg, 1),
                undergroundStationsList: jspb.Message.getRepeatedField(msg, 2),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.GetDistrictsAndUndergroundStationsByCityResponse}
 */
proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.GetDistrictsAndUndergroundStationsByCityResponse();

    return proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.GetDistrictsAndUndergroundStationsByCityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.GetDistrictsAndUndergroundStationsByCityResponse}
 */
proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.addDistricts(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.addUndergroundStations(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.GetDistrictsAndUndergroundStationsByCityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getDistrictsList();
    if (f.length > 0) {
        writer.writeRepeatedString(1, f);
    }

    f = message.getUndergroundStationsList();
    if (f.length > 0) {
        writer.writeRepeatedString(2, f);
    }
};

/**
 * repeated string districts = 1;
 * @return {!Array<string>}
 */
proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.prototype.getDistrictsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};

/** @param {!Array<string>} value */
proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.prototype.setDistrictsList = function (value) {
    jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.prototype.addDistricts = function (value, opt_index) {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.prototype.clearDistrictsList = function () {
    this.setDistrictsList([]);
};

/**
 * repeated string underground_stations = 2;
 * @return {!Array<string>}
 */
proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.prototype.getUndergroundStationsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};

/** @param {!Array<string>} value */
proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.prototype.setUndergroundStationsList = function (value) {
    jspb.Message.setField(this, 2, value || []);
};

/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.prototype.addUndergroundStations = function (value, opt_index) {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};

proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.prototype.clearUndergroundStationsList = function () {
    this.setUndergroundStationsList([]);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.GetSubjectsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.GetSubjectsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.GetSubjectsRequest.displayName = "proto.pb.GetSubjectsRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.GetSubjectsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.GetSubjectsRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.GetSubjectsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.GetSubjectsRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.GetSubjectsRequest}
 */
proto.pb.GetSubjectsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.GetSubjectsRequest();

    return proto.pb.GetSubjectsRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.GetSubjectsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.GetSubjectsRequest}
 */
proto.pb.GetSubjectsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.GetSubjectsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.GetSubjectsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.GetSubjectsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.GetSubjectsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.GetSubjectsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.GetSubjectsResponse.repeatedFields_, null);
};

goog.inherits(proto.pb.GetSubjectsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.GetSubjectsResponse.displayName = "proto.pb.GetSubjectsResponse";
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.GetSubjectsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.GetSubjectsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.GetSubjectsResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.GetSubjectsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.GetSubjectsResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                subjectsList: jspb.Message.getRepeatedField(msg, 1),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.GetSubjectsResponse}
 */
proto.pb.GetSubjectsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.GetSubjectsResponse();

    return proto.pb.GetSubjectsResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.GetSubjectsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.GetSubjectsResponse}
 */
proto.pb.GetSubjectsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.addSubjects(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.GetSubjectsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.GetSubjectsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.GetSubjectsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.GetSubjectsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getSubjectsList();
    if (f.length > 0) {
        writer.writeRepeatedString(1, f);
    }
};

/**
 * repeated string subjects = 1;
 * @return {!Array<string>}
 */
proto.pb.GetSubjectsResponse.prototype.getSubjectsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};

/** @param {!Array<string>} value */
proto.pb.GetSubjectsResponse.prototype.setSubjectsList = function (value) {
    jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.pb.GetSubjectsResponse.prototype.addSubjects = function (value, opt_index) {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

proto.pb.GetSubjectsResponse.prototype.clearSubjectsList = function () {
    this.setSubjectsList([]);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.GetConditionsBySubjectRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.GetConditionsBySubjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.GetConditionsBySubjectRequest.displayName = "proto.pb.GetConditionsBySubjectRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.GetConditionsBySubjectRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.GetConditionsBySubjectRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.GetConditionsBySubjectRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.GetConditionsBySubjectRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                subject: jspb.Message.getFieldWithDefault(msg, 1, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.GetConditionsBySubjectRequest}
 */
proto.pb.GetConditionsBySubjectRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.GetConditionsBySubjectRequest();

    return proto.pb.GetConditionsBySubjectRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.GetConditionsBySubjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.GetConditionsBySubjectRequest}
 */
proto.pb.GetConditionsBySubjectRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setSubject(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.GetConditionsBySubjectRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.GetConditionsBySubjectRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.GetConditionsBySubjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.GetConditionsBySubjectRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getSubject();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};

/**
 * optional string subject = 1;
 * @return {string}
 */
proto.pb.GetConditionsBySubjectRequest.prototype.getSubject = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.GetConditionsBySubjectRequest.prototype.setSubject = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.GetConditionsBySubjectResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.GetConditionsBySubjectResponse.repeatedFields_, null);
};

goog.inherits(proto.pb.GetConditionsBySubjectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.GetConditionsBySubjectResponse.displayName = "proto.pb.GetConditionsBySubjectResponse";
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.GetConditionsBySubjectResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.GetConditionsBySubjectResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.GetConditionsBySubjectResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.GetConditionsBySubjectResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.GetConditionsBySubjectResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                conditionsList: jspb.Message.getRepeatedField(msg, 1),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.GetConditionsBySubjectResponse}
 */
proto.pb.GetConditionsBySubjectResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.GetConditionsBySubjectResponse();

    return proto.pb.GetConditionsBySubjectResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.GetConditionsBySubjectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.GetConditionsBySubjectResponse}
 */
proto.pb.GetConditionsBySubjectResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.addConditions(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.GetConditionsBySubjectResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.GetConditionsBySubjectResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.GetConditionsBySubjectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.GetConditionsBySubjectResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getConditionsList();
    if (f.length > 0) {
        writer.writeRepeatedString(1, f);
    }
};

/**
 * repeated string conditions = 1;
 * @return {!Array<string>}
 */
proto.pb.GetConditionsBySubjectResponse.prototype.getConditionsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};

/** @param {!Array<string>} value */
proto.pb.GetConditionsBySubjectResponse.prototype.setConditionsList = function (value) {
    jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.pb.GetConditionsBySubjectResponse.prototype.addConditions = function (value, opt_index) {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

proto.pb.GetConditionsBySubjectResponse.prototype.clearConditionsList = function () {
    this.setConditionsList([]);
};

goog.object.extend(exports, proto.pb);
