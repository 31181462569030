export const WeSentInstructionsIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
        >
            <g clipPath="url(#clip0_1747_57058)">
                <path
                    d="M49.4004 18V44.3372C49.4004 45.688 48.8206 46.9044 47.8944 47.7561C47.0543 48.5281 45.9304 49 44.6955 49H5.10445C3.86945 49 2.74557 48.5281 1.90664 47.7569C0.980226 46.9044 0.400391 45.688 0.400391 44.3372V18H49.4004Z"
                    fill="#6212FF"
                />
                <path
                    d="M49.0665 19.0219C49.5516 18.6258 49.5575 17.8865 49.0788 17.4828L30.7813 2.05103C27.4316 -0.683678 22.5684 -0.683678 19.2187 2.05103L0.921288 17.4827C0.442581 17.8865 0.448515 18.6258 0.933641 19.0218L5.66471 22.8837L19.2187 33.9484C22.5688 36.6839 27.432 36.6839 30.7809 33.9484L44.3362 22.8837L49.0665 19.0219Z"
                    fill="#4D09D3"
                />
                <path
                    d="M44 8.61173V23.4757L30.6801 34.8846C27.3893 37.7051 22.6106 37.7051 19.3187 34.8846L6 23.4757V8.61173C6 5.51272 8.44248 3 11.4554 3H38.5454C41.5583 3 43.9999 5.51272 43.9999 8.61173H44Z"
                    fill="#E3E3E3"
                />
                <path
                    d="M44 8.68588V20.2967L30.6801 31.857C27.3893 34.7143 22.6106 34.7143 19.3187 31.857L6 20.2967V8.68588C6 5.54592 8.44248 3 11.4554 3H38.5454C41.5583 3 43.9999 5.54592 43.9999 8.68588H44Z"
                    fill="#F5F5F7"
                />
                <path
                    d="M35.772 22.0878H14.2239C14.1274 22.0879 14.0319 22.0689 13.9427 22.0319C13.8536 21.995 13.7726 21.9409 13.7044 21.8727C13.6362 21.8045 13.5821 21.7235 13.5452 21.6344C13.5082 21.5452 13.4892 21.4497 13.4893 21.3532C13.4893 21.1584 13.5667 20.9716 13.7045 20.8338C13.8422 20.6961 14.0291 20.6186 14.2239 20.6186H35.772C35.9669 20.6186 36.1537 20.6961 36.2914 20.8338C36.4292 20.9716 36.5066 21.1584 36.5067 21.3532C36.5067 21.4497 36.4877 21.5452 36.4508 21.6344C36.4139 21.7235 36.3597 21.8045 36.2915 21.8727C36.2233 21.9409 36.1423 21.995 36.0532 22.0319C35.964 22.0689 35.8685 22.0879 35.772 22.0878ZM35.772 26.3546H14.2239C13.8181 26.3546 13.4893 26.0257 13.4893 25.62C13.4893 25.2142 13.8181 24.8853 14.2239 24.8853H35.772C36.1779 24.8853 36.5067 25.2142 36.5067 25.62C36.5067 26.0257 36.1779 26.3546 35.772 26.3546ZM35.772 13.5552H14.2239C13.8181 13.5552 13.4893 13.2263 13.4893 12.8206C13.4893 12.4148 13.8181 12.0859 14.2239 12.0859H35.772C36.1779 12.0859 36.5067 12.4148 36.5067 12.8206C36.5067 13.2263 36.1779 13.5552 35.772 13.5552ZM35.772 17.8216H14.2239C13.8181 17.8216 13.4893 17.4927 13.4893 17.0869C13.4893 16.6812 13.8181 16.3523 14.2239 16.3523H35.772C36.1779 16.3523 36.5067 16.6811 36.5067 17.0869C36.5067 17.4928 36.1779 17.8216 35.772 17.8216Z"
                    fill="#6212FF"
                />
                <path
                    d="M48.0155 11.2825C48.0155 16.9356 43.4327 21.5185 37.7799 21.5185C32.1267 21.5185 27.5439 16.9357 27.5439 11.2825C27.5439 5.62966 32.1267 1.04688 37.7799 1.04688C43.4327 1.04688 48.0155 5.62966 48.0155 11.2825Z"
                    fill="#19B20C"
                />
                <path
                    d="M37.4942 15.7537C37.3871 15.7536 37.2812 15.7301 37.1841 15.6848C37.087 15.6395 37.001 15.5735 36.9321 15.4915L32.6719 11.4184C32.4113 11.1074 32.4515 10.6442 32.762 10.3834C32.8359 10.3213 32.9212 10.2744 33.0132 10.2454C33.1052 10.2163 33.2021 10.2057 33.2982 10.2141C33.3943 10.2224 33.4878 10.2497 33.5734 10.2942C33.659 10.3387 33.735 10.3997 33.797 10.4736L37.2895 13.6326L42.227 7.44512C42.265 7.35644 42.3201 7.27612 42.3891 7.20875C42.4582 7.14138 42.5398 7.08827 42.6294 7.05246C42.719 7.01665 42.8148 6.99885 42.9112 7.00006C43.0077 7.00127 43.103 7.02148 43.1917 7.05952C43.2803 7.09751 43.3607 7.1526 43.428 7.22164C43.4954 7.29069 43.5485 7.37233 43.5843 7.46192C43.6201 7.5515 43.638 7.64726 43.6367 7.74372C43.6355 7.84019 43.6153 7.93547 43.5772 8.02412L38.1695 15.3084C38.1202 15.4238 38.0421 15.5246 37.9427 15.6011C37.8434 15.6777 37.726 15.7274 37.6018 15.7456C37.5662 15.7511 37.5302 15.7538 37.4942 15.7537Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_1747_57058">
                    <rect
                        width="50"
                        height="50"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
