export const OperationsTableMobileWrapper = { maxWidth: "600px", width: "100%", margin: "0 auto" };

export const Header = { width: "100%", display: "flex", justifyContent: "space-between", borderBottom: "1px solid #DADADF", padding: "12px 0px" };

export const ContentWrapper = { display: "flex", justifyContent: "space-between", gap: "10px", borderBottom: "1px solid #DADADF", padding: "12px 0" };

export const SummPos = { textAlign: "right", width: "100px" };

export const pointsText = (points) => {
    return {
        color: points ? "#19B20C" : "#222",
    };
};

export const discountText = {
    textDecoration: "line-through",
};
