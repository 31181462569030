import { createTheme } from "@mui/material/styles";

export const tagsBlockContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    rowGap: "5px",
    columnGap: "5px",
    flexWrap: "wrap",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const textStyle = {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#222222",
};

export const TagTheme = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                // Name of the slot
                root: {
                    height: "27px",
                    "&:hover": {
                        background: "#FFFFFF!important",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "#FFFFFF!important",
                        borderColor: "#6212FF",
                    },
                },
                label: {
                    padding: "2px 10px 4px 10px!important",
                },
            },
        },
    },
});
