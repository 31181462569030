import { makeAutoObservable } from "mobx";
import RequestService from "API/services/RequestService";
import { handleStoreActionError } from "../API/plugins/errorHandlers";
import * as CatalogService from "../API/services/grpc/CatalogService";
import { getCookie } from "../API/utils";
import UserStore from "./UserStore";

const requestService = new RequestService();

class CatalogStore {
    teachersArr = [];

    optionsDiscipline = [];
    optionDiscipline = null;

    optionsAudience = [];
    optionAudience = null;

    optionsGoal = [];
    optionGoal = null;

    optionsCost = [];
    optionCost = [];

    optionsSort = [];
    optionSort = null;

    selectedChipsList = [];

    optionsLessonsFormat = [];

    optionsLessonsPlace = [];
    optionLessonsPlace = null;
    selectedLessonsPlaceChipList = [];

    optionsLessonsPerWeek = [];
    optionLessonsPerWeek = null;

    optionsLessonsDayTime = [];

    optionsLessonsEdu = [];
    optionLessonsEdu = null;
    selectedLessonsEduChipList = [];

    optionsLessonsTeacherSex = [];
    optionLessonsTeacherSex = null;

    optionsLessonsTeacherAge = [];
    optionLessonsTeacherAge = null;

    cashback = false;

    search = "";
    searchReady = "";

    popularQueries = [];

    drawerOpened = false;
    lessonsFormatExpanded = false;
    lessonsPlaceExpanded = false;
    lessonsPerWeekExpanded = false;
    lessonsDayTimeExpanded = false;
    lessonsEduExpanded = false;
    lessonsTeacherSexExpanded = false;
    lessonsTeacherAgeExpanded = false;

    onlyMyLessonsEdu = false;

    currentPage = 1;
    pagesTotal = null;
    itemsForPage = 6;
    from = 0;
    to = this.itemsForPage;
    totalTeachersCount = null;

    activeChip = null;

    timerID = null;

    loadingTutorsData = true;
    loadingMoreTutorsData = false;
    loadingPaginatedTutorsData = false;

    timeSearch = "";

    noDataFetched = false;
    firstInit = true;

    constructor() {
        this.Init();
        makeAutoObservable(this);
    }

    debounce(callback, delay) {
        return (...args) => {
            if (this.timerID) {
                clearTimeout(this.timerID);
            }

            this.timerID = setTimeout(() => {
                callback.call(this, ...args);
            }, delay);
        };
    }

    /*FilterSearch(object){
        if (!this.searchReady) return true;
        for (let value of Object.values(object))
        {
            if (typeof value === 'string')
            {
               if (value.includes(this.searchReady))
               {
                   return true;
               }
            }
        }
    }

    FilterDiscipline(object){
        if (!this.optionDiscipline) return true;
        return object['Disciplines'].includes(this.optionDiscipline.discipline)
    }

    FilterTeachersArr(){
        this.LoadTeachers();
        this.teachersArr = this.teachersArr.filter(x => {
            return this.FilterSearch(x) && this.FilterDiscipline(x)
        });
    }*/

    SetSearch(value) {
        this.search = value;
    }

    SetSearchDebounced(value) {
        this.SetSearch(value);
        this.debounce(async () => {
            this.searchReady = this.search;
            console.log(value);
            await this.FetchTutorsData("replace");
        }, 1000)();
    }

    SetDiscipline(value) {
        this.optionDiscipline = value;
    }

    SetAudience(value) {
        console.log(value);
        this.optionAudience = value;
        //this.FilterTeachersArr();
    }

    SetGoal(value) {
        console.log(value);
        this.optionGoal = value;
        //this.FilterTeachersArr();
    }

    SetCost(value) {
        console.log(value);
        this.optionCost = value;
        //this.FilterTeachersArr();
    }

    SetCashback(value) {
        console.log(value);
        this.cashback = value;
        //this.FilterTeachersArr();
    }

    SetOptionsSort(value) {
        console.log(value);
        this.optionSort = value;
        //this.FilterTeachersArr();
    }

    ToggleDrawer() {
        console.log(this.drawerOpened);
        this.drawerOpened = !this.drawerOpened;
    }

    ToggleLessonsFormatExpanded() {
        console.log(this.lessonsFormatExpanded);
        this.lessonsFormatExpanded = !this.lessonsFormatExpanded;
    }

    ToggleLessonsPlaceExpanded() {
        console.log(this.lessonsPlaceExpanded);
        this.lessonsPlaceExpanded = !this.lessonsPlaceExpanded;
    }

    ToggleLessonsPerWeekExpanded() {
        console.log(this.lessonsPerWeekExpanded);
        this.lessonsPerWeekExpanded = !this.lessonsPerWeekExpanded;
    }

    ToggleLessonsDayTimeExpanded() {
        console.log(this.lessonsDayTimeExpanded);
        this.lessonsDayTimeExpanded = !this.lessonsDayTimeExpanded;
    }

    ToggleLessonsEduExpanded() {
        console.log(this.lessonsEduExpanded);
        this.lessonsEduExpanded = !this.lessonsEduExpanded;
    }

    ToggleLessonsTeacherSexExpanded() {
        console.log(this.lessonsTeacherSexExpanded);
        this.lessonsTeacherSexExpanded = !this.lessonsTeacherSexExpanded;
    }

    ToggleLessonsTeacherAgeExpanded() {
        console.log(this.lessonsTeacherAgeExpanded);
        this.lessonsTeacherAgeExpanded = !this.lessonsTeacherAgeExpanded;
    }

    SetLessonsFormat(value) {
        console.log(value);
        this.optionsLessonsFormat = this.optionsLessonsFormat.map((element) => (element.ID === value.ID ? { ...element, Checked: !value.Checked } : element));
        this.FillSelectedChipsList();
    }

    SetLessonsPlace(value) {
        //console.log(value, this.optionsLessonsPlace.find(x => x.ID === parseInt(value?.ID)));
        this.optionLessonsPlace = value;
        if (typeof value?.ID !== "undefined" && !this.selectedLessonsPlaceChipList.find((x) => x.ID === parseInt(value?.ID))) {
            this.selectedLessonsPlaceChipList.push(value);
        }

        console.log(this.selectedLessonsPlaceChipList);
        this.FillSelectedChipsList();
    }

    ClearLessonsPlace(value) {
        this.selectedLessonsPlaceChipList = this.selectedLessonsPlaceChipList.filter((x) => x.ID !== parseInt(value.ID));
        this.FillSelectedChipsList(); //очистка локальных чипов синхронизируется с глобальными
    }

    SetLessonsPerWeek(value) {
        console.log(this.optionsLessonsPerWeek.find((x) => x.ID === parseInt(value)));
        this.optionLessonsPerWeek = this.optionsLessonsPerWeek.find((x) => x.ID === parseInt(value));
        this.FillSelectedChipsList();
    }

    SetLessonsDayTime(value) {
        console.log(value);
        if (!value.Checked) {
            this.optionsLessonsDayTime = this.optionsLessonsDayTime.map((element) => (element.ID === value.ID ? { ...element, Checked: !value.Checked } : element));
        } else {
            this.optionsLessonsDayTime = this.optionsLessonsDayTime.map((element) =>
                element.ID === value.ID ? { ...element, Checked: !value.Checked, Days: element.Days.map((day) => ({ ...day, Checked: false })) } : element,
            );
        }

        this.FillSelectedChipsList();
    }

    SetDays(value, valueAddition) {
        console.log(value, valueAddition);
        this.optionsLessonsDayTime = this.optionsLessonsDayTime.map((element) =>
            element.ID === value.ID ? { ...element, Days: element.Days.map((day) => (day.ID === valueAddition.ID ? { ...day, Checked: !valueAddition.Checked } : day)) } : element,
        );
        this.FillSelectedChipsList();
    }

    SetLessonsEdu(value) {
        //console.log(value, this.optionsLessonsPlace.find(x => x.ID === parseInt(value?.ID)));
        this.optionLessonsEdu = value;
        if (typeof value?.ID !== "undefined" && !this.selectedLessonsEduChipList.find((x) => x.ID === parseInt(value?.ID))) {
            this.selectedLessonsEduChipList.push(value);
        }

        console.log(this.selectedLessonsEduChipList);
        this.FillSelectedChipsList();
    }

    ClearLessonsEdu(value) {
        this.selectedLessonsEduChipList = this.selectedLessonsEduChipList.filter((x) => x.ID !== parseInt(value.ID));
        this.FillSelectedChipsList(); //очистка локальных чипов синхронизируется с глобальными
    }

    SetOnlyMyLessonsEdu(value) {
        this.onlyMyLessonsEdu = value;
    }

    SetLessonsTeacherSex(value) {
        console.log(this.optionsLessonsTeacherSex.find((x) => x.ID === parseInt(value)));
        this.optionLessonsTeacherSex = this.optionsLessonsTeacherSex.find((x) => x.ID === parseInt(value));
        this.FillSelectedChipsList();
    }

    SetLessonsTeacherAge(value) {
        console.log(this.optionsLessonsTeacherAge.find((x) => x.ID === parseInt(value)));
        this.optionLessonsTeacherAge = this.optionsLessonsTeacherAge.find((x) => x.ID === parseInt(value));
        this.FillSelectedChipsList();
    }

    Clear(value) {
        if (value.controlType === "AutoMultiple") {
            if (value.fieldForMultiple) {
                if (value.additionField) {
                    this[value.sourceToFillFrom] = this[value.sourceOfOptions].map((element) =>
                        element.ID === value.value.ID
                            ? {
                                  ...element,
                                  [value.fieldForMultiple]: !value.value[value.fieldForMultiple],
                                  [value.additionField]: element[value.additionField].map((addition) => ({ ...addition, [value.fieldForMultiple]: !value.value[value.fieldForMultiple] })),
                              }
                            : element,
                    );
                } else {
                    this[value.sourceToFillFrom] = this[value.sourceOfOptions].map((element) =>
                        element.ID === value.value.ID ? { ...element, [value.fieldForMultiple]: !value.value[value.fieldForMultiple] } : element,
                    );
                }
            } else {
                this[value.sourceToFillFrom] = []; //пока не используем
            }
        }

        if (value.controlType === "AutoSingle") {
            this[value.sourceToFillFrom] = this[value.sourceOfOptions].at(-1);
        }

        if (value.controlType === "ChipMultiple") {
            this[value.sourceToFillFrom] = this[value.sourceOfOptions].filter((element) => element.ID !== value.value.ID);
        }

        this.FillSelectedChipsList();
    }

    MainFiltersClearAll() {
        this.optionDiscipline = null;
        this.optionAudience = null;
        this.optionGoal = null;
        this.optionCost = [];
        this.optionSort = this.optionsSort.at(0);
        this.cashback = false;
        this.search = "";
        this.searchReady = "";
    }

    SideFiltersClearAll() {
        this.selectedChipsList = [];
        this.optionsLessonsFormat = this.optionsLessonsFormat.map((element) => (element = { ...element, Checked: false }));
        this.optionLessonsPlace = null;
        this.selectedLessonsPlaceChipList = [];
        this.optionLessonsPerWeek = this.optionsLessonsPerWeek.at(-1);
        this.optionsLessonsDayTime = this.optionsLessonsDayTime.map((element) => (element = { ...element, Checked: false, Days: element.Days.map((day) => (day = { ...day, Checked: false })) }));
        this.optionLessonsEdu = null;
        this.selectedLessonsEduChipList = [];
        this.optionLessonsTeacherSex = this.optionsLessonsTeacherSex.at(-1);
        this.optionLessonsTeacherAge = this.optionsLessonsTeacherAge.at(-1);
    }

    FillSelectedChipsList() {
        this.selectedChipsList = [];
        this.FillSelectedChipsListOpt("optionsLessonsFormat", "optionsLessonsFormat", "LessonFormat", "Checked", "AutoMultiple", null);
        this.FillSelectedChipsListOpt("selectedLessonsPlaceChipList", "selectedLessonsPlaceChipList", "LessonsPlace", null, "ChipMultiple", null);
        this.FillSelectedChipsListOpt("optionLessonsPerWeek", "optionsLessonsPerWeek", "LessonsPerWeek", null, "AutoSingle", null);
        this.FillSelectedChipsListOpt("optionsLessonsDayTime", "optionsLessonsDayTime", "LessonDayTime", "Checked", "AutoMultiple", "Days");
        this.FillSelectedChipsListOpt("selectedLessonsEduChipList", "selectedLessonsEduChipList", "LessonsEdu", null, "ChipMultiple", null);
        this.FillSelectedChipsListOpt("optionLessonsTeacherSex", "optionsLessonsTeacherSex", "LessonsTeacherSex", null, "AutoSingle", null);
        this.FillSelectedChipsListOpt("optionLessonsTeacherAge", "optionsLessonsTeacherAge", "LessonsTeacherAge", null, "AutoSingle", null);
    }

    FillSelectedChipsListOpt(sourceToFillFrom, sourceOfOptions, fieldName, field, controlType, additionField) {
        if (Array.isArray(this[sourceToFillFrom])) {
            //выбрано несколько опций
            if (field) {
                //в случае, если изменяется поле самого источника
                this[sourceToFillFrom]
                    .filter((value) => value[field])
                    .forEach((value) => {
                        if (!this.selectedChipsList.find((x) => x.ID === value.ID + fieldName)) {
                            this.selectedChipsList.push({
                                ID: value.ID + " " + fieldName,
                                sourceToFillFrom: sourceToFillFrom,
                                sourceOfOptions: sourceOfOptions,
                                controlType: controlType,
                                additionField: additionField,
                                fieldForMultiple: field,
                                fieldName: fieldName,
                                value: value,
                            });
                        }
                    });
            } else {
                this[sourceToFillFrom].forEach((value) => {
                    if (!this.selectedChipsList.find((x) => x.ID === value.ID + fieldName)) {
                        this.selectedChipsList.push({
                            ID: value.ID + " " + fieldName,
                            sourceToFillFrom: sourceToFillFrom,
                            sourceOfOptions: sourceOfOptions,
                            controlType: controlType,
                            additionField: additionField,
                            fieldForMultiple: field,
                            fieldName: fieldName,
                            value: value,
                        });
                    }
                });
            }
        } else if (!this.selectedChipsList.find((x) => x.ID === this[sourceToFillFrom].ID + fieldName) && this[sourceToFillFrom].ID !== this[sourceOfOptions].at(-1).ID) {
            this.selectedChipsList.push({
                ID: this[sourceToFillFrom].ID + " " + fieldName,
                sourceToFillFrom: sourceToFillFrom,
                sourceOfOptions: sourceOfOptions,
                controlType: controlType,
                additionField: additionField,
                fieldForMultiple: field,
                fieldName: fieldName,
                value: this[sourceToFillFrom],
            });
        }
    }

    async ExecuteSearch() {
        console.log("executing search");
        this.currentPage = 1;

        await this.FetchTutorsData("replace");
    }

    ProcessTagSearch(value) {
        console.log(value);
        this.SetSearchDebounced(value);
    }

    ToggleLiked(item) {
        this.teachersArr = this.teachersArr.map((value) => (value._id === item._id ? { ...value, Liked: !item.Liked } : value));
    }

    async ToggleFavourites(item) {
        console.log(!item.Liked);

        let data = {
            tutorUuid: item._id,
            action: item.Liked ? "REMOVE" : "ADD",
        };

        let metaData = {
            authorization: "Bearer " + getCookie(`${UserStore.role}AccessToken`),
        };

        await CatalogService.ToggleFavourites(data, metaData, (error, response) => {
            if (error) {
                handleStoreActionError({
                    error,
                    customErrorText: "catalog.errors.toggleFavouritesError",
                    dispatch: true,
                });
            } else {
                this.ToggleLiked(item);
            }
        });
    }

    PrepareFiltersObject(mode = "allFilters") {
        let iterator;

        const baseIterator = [
            "optionDiscipline",
            "optionAudience",
            "optionGoal",
            "optionCost",
            "search",
            "cashback",
            "optionSort",
            "optionLessonsTeacherAge",
            "optionsLessonsDayTime",
            "selectedLessonsEduChipList",
            "selectedLessonsPlaceChipList",
        ];

        if (mode === "onlyBaseFilters") {
            iterator = baseIterator.slice(0, 1);
        } else {
            iterator = baseIterator;
        }

        let filters = {};

        iterator.forEach((item) => {
            switch (item) {
                case "optionDiscipline":
                    if (this[item]?.discipline) {
                        filters["subject"] = this[item].discipline;
                    }

                    break;
                case "optionAudience":
                    if (this[item]?.audience) {
                        filters["audience"] = this[item].audience;
                    }

                    break;
                case "optionGoal":
                    if (this[item]?.goal) {
                        filters["purpose"] = this[item].goal;
                    }

                    break;
                case "optionCost":
                    if (this[item].length) {
                        let priceObj = {};

                        let minPrice = Math.min.apply(
                            this,
                            this[item].map((x) => x.from),
                        );

                        let maxPrice = Math.max.apply(
                            this,
                            this[item].map((x) => x.to),
                        );

                        if (minPrice !== Number.NEGATIVE_INFINITY) {
                            priceObj["min"] = minPrice;
                        }

                        if (maxPrice !== Number.POSITIVE_INFINITY) {
                            priceObj["max"] = maxPrice;
                        }

                        if (Object.keys(priceObj).length) {
                            filters["price"] = priceObj;
                        }
                    }

                    break;
                case "search":
                    if (this[item].length) {
                        filters["keywords"] = this[item];
                    }

                    break;
                case "cashback":
                    filters["cashback"] = this[item];
                    break;
                case "optionSort":
                    if (this[item]?.sort) {
                        filters["sort"] = this[item].sort;
                    }

                    break;
                case "optionLessonsTeacherAge":
                    if (this[item]) {
                        let ageObj = {};

                        let minAge = this[item].from;

                        let maxAge = this[item].to;

                        if (minAge !== Number.NEGATIVE_INFINITY) {
                            ageObj["min"] = minAge;
                        }

                        if (maxAge !== Number.POSITIVE_INFINITY) {
                            ageObj["max"] = maxAge;
                        }

                        if (Object.keys(ageObj).length) {
                            filters["age"] = ageObj;
                        }
                    }

                    break;

                case "optionsLessonsDayTime":
                    if (this[item].filter((value) => value.Checked).length) {
                        let preparedData = this.optionsLessonsDayTime[0].Days.reduce((acc, value) => {
                            let dayIsPresentData = this.optionsLessonsDayTime.filter((x) => x.Days.filter((day) => day.DayNameEng === value.DayNameEng && day.Checked).length > 0);

                            if (dayIsPresentData.length > 0) {
                                acc.push({
                                    day: value.DayNameEng,
                                    timetable: dayIsPresentData.reduce((acc, value) => {
                                        acc[value.LessonDayTimeHuman] = true;
                                        return acc;
                                    }, {}),
                                });
                            }

                            return acc;
                        }, []);

                        console.log(preparedData);
                        filters["time"] = preparedData;
                    }

                    break;
                case "selectedLessonsEduChipList":
                    if (this[item]?.length) {
                        filters["university"] = {
                            name: this[item][0].LessonsEdu,
                            onlyUniversity: this.onlyMyLessonsEdu,
                        };
                    }

                    break;
                case "selectedLessonsPlaceChipList":
                    if (this[item]?.length) {
                        filters["placesStudy"] = {
                            city: "Москва", //mocked now
                            locations: this[item].map((value) => value.LessonsPlace),
                        };
                    }

                    break;
                default:
                    break;
            }
        });

        console.log(filters);
        return filters;
    }

    async ProcessPagination(value) {
        this.currentPage = value;
        await this.FetchTutorsData("replace");
    }

    async ShowMore() {
        await this.FetchTutorsData("append");
    }

    async FetchPageData() {
        await this.FetchTutorsData();
    }

    SetDataFetchedFlag(value) {
        this.noDataFetched = value;
    }

    SetFirstInit(value) {
        this.firstInit = value;
    }

    async FetchTutorsData(contentLoadingMode = "default") {
        this.SetDataFetchedFlag(false);
        let data = {
            metaData: {
                timeSearch: this.timeSearch,
                page: contentLoadingMode === "append" ? Math.round(this.teachersArr.length / this.itemsForPage) + 1 : this.currentPage,
            },
        };

        this.SetLoadingState(this.ChooseLoadingMode(contentLoadingMode), true);
        await this.FetchTutors(data, contentLoadingMode);
    }

    async FetchTutors(data, contentLoadingMode, mode = "allFilters") {
        let preparedFilters = this.PrepareFiltersObject(mode);

        if (Object.keys(preparedFilters).length) {
            data["body"] = preparedFilters;
        }

        await CatalogService.SearchTutors(data, async (error, response) => {
            if (error) {
                handleStoreActionError({
                    error,
                    customErrorText: "catalog.errors.fetchTutorsError",
                    dispatch: true,
                });
            } else {
                let data = response.toObject();

                console.log(data.paging.total);
                if (data.paging.total === 0 && !this.firstInit) {
                    this.SetDataFetchedFlag(true);
                    //если ничего не нашли по фильтрам - урезаем до базовых и рекурсивно вызываем
                    await this.FetchTutors(data, contentLoadingMode, "onlyBaseFilters");
                    return;
                }

                this.FillTutorsData(data, contentLoadingMode);
                this.SetLoadingState(this.ChooseLoadingMode(contentLoadingMode), false);
            }

            this.SetFirstInit(false);
            this.SetLoadingState(this.ChooseLoadingMode(contentLoadingMode), false);
        });
    }

    FillTutorsData(data, contentLoadingMode) {
        console.log(data);
        this.timeSearch = data.metaData.timeSearch;
        this.pagesTotal = data.paging.pages;
        this.totalTeachersCount = data.paging.total;
        let newDataToAppend = [];

        switch (contentLoadingMode) {
            case "default":
                this.teachersArr = [];
                break;
            case "replace":
                this.teachersArr = [];
                break;
            case "append":
                break;
            default:
                break;
        }

        data.dataList.forEach((value, index) => {
            newDataToAppend.push({
                ID: this.teachersArr.length + index,
                _id: value.tutorUuid,
                AvatarSrc: "https://" + process.env.REACT_APP_API_URL + value.photo.link,
                VideoSrc: "https://" + process.env.REACT_APP_API_URL + value.video.link,
                Name: value.name,
                Surname: value.surname,
                Patronymic: value.patronymic,
                IsVerified: value.isVerified,
                IsAnonymous: value.isAnonymous,
                LastSeen:
                    "был в сети: " +
                    (new Date(value.status.lastSeen) === new Date()
                        ? "Сегодня, " +
                          new Date(data.status.lastSeen).toLocaleString("ru", {
                              hour: "numeric",
                              minute: "numeric",
                          })
                        : "" +
                          new Date(value.status.lastSeen).toLocaleString("ru", {
                              day: "numeric",
                              month: "short",
                              weekday: "short",
                              hour: "numeric",
                              minute: "numeric",
                          })),
                IsActive: value.status.online,
                Rating: value.rates.average,
                ReviewsCount: value.rates.total,
                Disciplines: value.subjectList.map((x, index) => {
                    return { ID: x, discipline: x };
                }),
                Location: value.locationsList.map((x, index) => {
                    return { ID: index, location: x };
                }),
                Education: value.educationsList.map((x, index) => {
                    return { ID: index, education: x };
                }),
                Experience: value.experience + " лет",
                Tags: value.tagsList.map((x, index) => {
                    return { ID: index, Query: x };
                }),
                Schedule: [
                    {
                        ID: 0,
                        Time: "06:00-12:00",
                        from: 6,
                        to: 12,
                        Days: [
                            {
                                ID: 0,
                                Day: "Пн",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "mo" && value.timetable.morning).length > 0,
                            },
                            {
                                ID: 1,
                                Day: "Вт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "tu" && value.timetable.morning).length > 0,
                            },
                            {
                                ID: 2,
                                Day: "Ср",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "we" && value.timetable.morning).length > 0,
                            },
                            {
                                ID: 3,
                                Day: "Чт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "th" && value.timetable.morning).length > 0,
                            },
                            {
                                ID: 4,
                                Day: "Пт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "fr" && value.timetable.morning).length > 0,
                            },
                            {
                                ID: 5,
                                Day: "Сб",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "sa" && value.timetable.morning).length > 0,
                            },
                            {
                                ID: 6,
                                Day: "Вс",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "su" && value.timetable.morning).length > 0,
                            },
                        ],
                    },
                    {
                        ID: 1,
                        Time: "12:00-18:00",
                        from: 12,
                        to: 18,
                        Days: [
                            {
                                ID: 0,
                                Day: "Пн",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "mo" && value.timetable.afternoon).length > 0,
                            },
                            {
                                ID: 1,
                                Day: "Вт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "tu" && value.timetable.afternoon).length > 0,
                            },
                            {
                                ID: 2,
                                Day: "Ср",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "we" && value.timetable.afternoon).length > 0,
                            },
                            {
                                ID: 3,
                                Day: "Чт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "th" && value.timetable.afternoon).length > 0,
                            },
                            {
                                ID: 4,
                                Day: "Пт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "fr" && value.timetable.afternoon).length > 0,
                            },
                            {
                                ID: 5,
                                Day: "Сб",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "sa" && value.timetable.afternoon).length > 0,
                            },
                            {
                                ID: 6,
                                Day: "Вс",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "su" && value.timetable.afternoon).length > 0,
                            },
                        ],
                    },
                    {
                        ID: 2,
                        Time: "18:00-24:00",
                        from: 18,
                        to: 24,
                        Days: [
                            {
                                ID: 0,
                                Day: "Пн",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "mo" && value.timetable.evening).length > 0,
                            },
                            {
                                ID: 1,
                                Day: "Вт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "tu" && value.timetable.evening).length > 0,
                            },
                            {
                                ID: 2,
                                Day: "Ср",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "we" && value.timetable.evening).length > 0,
                            },
                            {
                                ID: 3,
                                Day: "Чт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "th" && value.timetable.evening).length > 0,
                            },
                            {
                                ID: 4,
                                Day: "Пт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "fr" && value.timetable.evening).length > 0,
                            },
                            {
                                ID: 5,
                                Day: "Сб",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "sa" && value.timetable.evening).length > 0,
                            },
                            {
                                ID: 6,
                                Day: "Вс",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "su" && value.timetable.evening).length > 0,
                            },
                        ],
                    },
                    {
                        ID: 3,
                        Time: "00:00-06:00",
                        from: 0,
                        to: 6,
                        Days: [
                            {
                                ID: 0,
                                Day: "Пн",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "mo" && value.timetable.night).length > 0,
                            },
                            {
                                ID: 1,
                                Day: "Вт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "tu" && value.timetable.night).length > 0,
                            },
                            {
                                ID: 2,
                                Day: "Ср",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "we" && value.timetable.night).length > 0,
                            },
                            {
                                ID: 3,
                                Day: "Чт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "th" && value.timetable.night).length > 0,
                            },
                            {
                                ID: 4,
                                Day: "Пт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "fr" && value.timetable.night).length > 0,
                            },
                            {
                                ID: 5,
                                Day: "Сб",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "sa" && value.timetable.night).length > 0,
                            },
                            {
                                ID: 6,
                                Day: "Вс",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "su" && value.timetable.night).length > 0,
                            },
                        ],
                    },
                ],
                Description: value.description,
                DescriptionFull: value.description,
                PriceFrom: value.price,
                Cashback: value.cashback,
                Liked: value.isFavourite,
            });
        });

        this.teachersArr = [...this.teachersArr, ...newDataToAppend];
    }

    SetLoadingState(field, value) {
        this[field] = value;
    }

    ChooseLoadingMode(value) {
        switch (value) {
            case "default":
                return "loadingTutorsData";
            case "append":
                return "loadingMoreTutorsData";
            case "replace":
                return "loadingPaginatedTutorsData";
            default:
                return "loadingTutorsData";
        }
    }

    Init() {
        this.LoadFilters();
    }

    LoadFilters() {
        this.optionsDiscipline = [
            { ID: 0, discipline: "Английский язык" },
            { ID: 1, discipline: "Астрономия" },
            { ID: 2, discipline: "Алгебра" },
        ];
        this.optionDiscipline = this.optionsDiscipline[0];

        this.optionsAudience = [
            { ID: 0, audience: "Школьник" },
            { ID: 1, audience: "Студент" },
            { ID: 2, audience: "Взрослый" },
        ];
        this.optionAudience = this.optionsAudience[0];

        this.optionsGoal = [
            { ID: 0, goal: "Подготовка к олимпиаде" },
            { ID: 1, goal: "Подготовка к международному экзамену" },
            { ID: 2, goal: "Разговорная практика с носителем языка" },
        ];
        this.optionGoal = this.optionsGoal[0];

        this.optionsCost = [
            { ID: 0, cost: "до 800 ₽", from: 0, to: 800 },
            { ID: 1, cost: "800-1500 ₽", from: 800, to: 1500 },
            { ID: 2, cost: "1500-3000 ₽", from: 1500, to: 3000 },
            { ID: 3, cost: "от 3000 ₽", from: 3000, to: Number.POSITIVE_INFINITY },
        ];
        this.optionCost = [this.optionsCost[0]];

        this.optionsSort = [
            { ID: 0, title: "Онлайн", sort: "online" },
            { ID: 1, title: "Подешевле", sort: "cheaper" },
            { ID: 2, title: "Подороже", sort: "expensive" },
            { ID: 3, title: "По рейтингу", sort: "rating" },
            { ID: 4, title: "Наши рекомендации", sort: "recommendations" },
        ];
        this.optionSort = this.optionsSort[0];

        this.popularQueries = [
            { ID: 0, Query: "поступление в ВУЗ" },
            { ID: 1, Query: "подготовка к ЕГЭ" },
            { ID: 2, Query: "повышение успеваемости" },
            { ID: 3, Query: "разбор пропущенной темы" },
            { ID: 4, Query: "для работы" },
            { ID: 5, Query: "для работы 1" },
            { ID: 6, Query: "для работы 2 " },
            { ID: 7, Query: "для работы 3" },
        ];

        this.optionsLessonsFormat = [
            { ID: 0, LessonFormat: "Онлайн", Checked: false },
            { ID: 1, LessonFormat: "У ученика", Checked: false },
            { ID: 2, LessonFormat: "У репетитора", Checked: false },
        ];

        this.optionsLessonsPlace = [
            { ID: 0, LessonsPlace: "here" },
            { ID: 1, LessonsPlace: "there" },
            { ID: 2, LessonsPlace: "where" },
        ];

        this.optionsLessonsPerWeek = [
            { ID: 0, LessonsPerWeek: "Меньше 1 (1-2 занятия в месяц)" },
            { ID: 1, LessonsPerWeek: "1 занятие" },
            { ID: 2, LessonsPerWeek: "2-3 занятия" },
            { ID: 3, LessonsPerWeek: "Более 3 занятий" },
            { ID: 4, LessonsPerWeek: "Решу после первого занятия" },
        ];
        this.optionLessonsPerWeek = this.optionsLessonsPerWeek.at(-1);

        this.optionsLessonsDayTime = [
            {
                ID: 0,
                LessonDayTime: "06:00-12:00",
                from: 6,
                to: 12,
                LessonDayTimeHuman: "morning",
                Checked: false,
                Days: [
                    { ID: 0, Day: "Пн", DayNameEng: "mo", Checked: false },
                    { ID: 1, Day: "Вт", DayNameEng: "tu", Checked: false },
                    { ID: 2, Day: "Ср", DayNameEng: "we", Checked: false },
                    { ID: 3, Day: "Чт", DayNameEng: "th", Checked: false },
                    { ID: 4, Day: "Пт", DayNameEng: "fr", Checked: false },
                    { ID: 5, Day: "Сб", DayNameEng: "sa", Checked: false },
                    { ID: 6, Day: "Вс", DayNameEng: "su", Checked: false },
                ],
            },
            {
                ID: 1,
                LessonDayTime: "12:00-18:00",
                from: 12,
                to: 18,
                LessonDayTimeHuman: "afternoon",
                Checked: false,
                Days: [
                    { ID: 0, Day: "Пн", DayNameEng: "mo", Checked: false },
                    { ID: 1, Day: "Вт", DayNameEng: "tu", Checked: false },
                    { ID: 2, Day: "Ср", DayNameEng: "we", Checked: false },
                    { ID: 3, Day: "Чт", DayNameEng: "th", Checked: false },
                    { ID: 4, Day: "Пт", DayNameEng: "fr", Checked: false },
                    { ID: 5, Day: "Сб", DayNameEng: "sa", Checked: false },
                    { ID: 6, Day: "Вс", DayNameEng: "su", Checked: false },
                ],
            },
            {
                ID: 2,
                LessonDayTime: "18:00-24:00",
                from: 18,
                to: 24,
                LessonDayTimeHuman: "evening",
                Checked: false,
                Days: [
                    { ID: 0, Day: "Пн", DayNameEng: "mo", Checked: false },
                    { ID: 1, Day: "Вт", DayNameEng: "tu", Checked: false },
                    { ID: 2, Day: "Ср", DayNameEng: "we", Checked: false },
                    { ID: 3, Day: "Чт", DayNameEng: "th", Checked: false },
                    { ID: 4, Day: "Пт", DayNameEng: "fr", Checked: false },
                    { ID: 5, Day: "Сб", DayNameEng: "sa", Checked: false },
                    { ID: 6, Day: "Вс", DayNameEng: "su", Checked: false },
                ],
            },
            {
                ID: 3,
                LessonDayTime: "00:00-06:00",
                from: 0,
                to: 6,
                LessonDayTimeHuman: "night",
                Checked: false,
                Days: [
                    { ID: 0, Day: "Пн", DayNameEng: "mo", Checked: false },
                    { ID: 1, Day: "Вт", DayNameEng: "tu", Checked: false },
                    { ID: 2, Day: "Ср", DayNameEng: "we", Checked: false },
                    { ID: 3, Day: "Чт", DayNameEng: "th", Checked: false },
                    { ID: 4, Day: "Пт", DayNameEng: "fr", Checked: false },
                    { ID: 5, Day: "Сб", DayNameEng: "sa", Checked: false },
                    { ID: 6, Day: "Вс", DayNameEng: "su", Checked: false },
                ],
            },
        ];

        this.optionsLessonsEdu = [
            { ID: 0, LessonsEdu: "МЭИ" },
            { ID: 1, LessonsEdu: "МГУ" },
            { ID: 2, LessonsEdu: "РГГУ" },
        ];

        this.optionsLessonsTeacherSex = [
            { ID: 0, LessonsTeacherSex: "Мужчина" },
            { ID: 1, LessonsTeacherSex: "Женщина" },
            { ID: 2, LessonsTeacherSex: "Не важно" },
        ];
        this.optionLessonsTeacherSex = this.optionsLessonsTeacherSex.at(-1);

        this.optionsLessonsTeacherAge = [
            { ID: 0, LessonsTeacherAge: "До 25 лет", from: Number.NEGATIVE_INFINITY, to: 25 },
            { ID: 1, LessonsTeacherAge: "25-50 лет", from: 25, to: 50 },
            { ID: 2, LessonsTeacherAge: "Старше 50 лет", from: 50, to: Number.POSITIVE_INFINITY },
            { ID: 3, LessonsTeacherAge: "Не важно", from: Number.NEGATIVE_INFINITY, to: Number.POSITIVE_INFINITY },
        ];
        this.optionLessonsTeacherAge = this.optionsLessonsTeacherAge.at(-1);

        this.activeChip = { ID: null, Query: "" };

        this.FillSelectedChipsList();
    }
}

export default new CatalogStore();
