import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import CatalogStore from "store/CatalogStore";
import { ThemeProvider } from "@mui/material/styles";
import * as themes from "./LessonsEduThemes";
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Chip, FormControlLabel, IconButton, Typography } from "@mui/material";
import { CloseRounded, ExpandMore } from "@mui/icons-material";
import SingleChoiceLightFilter from "components/UI/SingleChoiceLightFilter";
import { useMedias } from "../../../API/mediasHook";

const LessonsEdu = observer(({ children, ...props }) => {
    const handleChange = () => {
        CatalogStore.ToggleLessonsEduExpanded();
    };

    const handleDelete = (value, event) => {
        console.log(value);
        CatalogStore.ClearLessonsEdu(value);
    };

    const processingLessonsEdu = (event, value) => {
        CatalogStore.SetLessonsEdu(value);
    };

    const processingOnlyMyLessonsEdu = (event) => {
        console.log(event.target.checked);
        CatalogStore.SetOnlyMyLessonsEdu(event.target.checked);
    };

    const medias = useMedias();

    return (
        <ThemeProvider theme={themes.LessonsEduAccordionTheme(medias)}>
            <Accordion
                expanded={CatalogStore.lessonsEduExpanded}
                onChange={handleChange}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography sx={themes.headerLessonsEduStyle}>Учебное заведение</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {CatalogStore.selectedLessonsEduChipList.length > 0 ? (
                        <ThemeProvider theme={themes.LessonsChipTheme}>
                            <Box sx={themes.selectedLessonsEduContainerStyle}>
                                {CatalogStore.selectedLessonsEduChipList.map((value) => (
                                    <Chip
                                        key={value.ID}
                                        label={value.LessonsEdu}
                                        deleteIcon={
                                            <IconButton onClick={handleDelete.bind(this, value)}>
                                                <CloseRounded></CloseRounded>
                                            </IconButton>
                                        }
                                        onDelete={handleDelete.bind(this, value)}
                                    />
                                ))}
                            </Box>
                        </ThemeProvider>
                    ) : null}
                    <SingleChoiceLightFilter
                        propOption={CatalogStore.optionLessonsEdu}
                        propOptions={CatalogStore.optionsLessonsEdu}
                        valueToShow={"LessonsEdu"}
                        valueToBind={"ID"}
                        processingChange={processingLessonsEdu}
                        themeWrapper={themes.FiltersLessonsEduTheme}
                        placeholder={"Выберите учебное заведение"}
                        clearOnBlur={true}
                        disableClearable={false}
                        variant={"standard"}
                        freeSolo={true}
                    ></SingleChoiceLightFilter>
                    <ThemeProvider theme={themes.OnlyMyLessonsEduTheme}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disableRipple={true}
                                    checked={CatalogStore.onlyMyLessonsEdu}
                                    onChange={processingOnlyMyLessonsEdu}
                                />
                            }
                            label={
                                <Typography>
                                    Показывать репетиторов только из моего ВУЗа (студентов,
                                    <br />
                                    выпускников, преподавателей)
                                </Typography>
                            }
                        />
                    </ThemeProvider>
                </AccordionDetails>
            </Accordion>
        </ThemeProvider>
    );
});

export default memo(LessonsEdu);
