export const addButtonContainer = (padding = "16px 20px") => ({
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "150%",
    color: "#222",
    border: "1px dashed #9E9EB0",
    bgcolor: "#fff",
    justifyContent: "space-between",
    padding: padding,
    textTransform: "none",
    boxShadow: "none",
    borderRadius: "10px",

    "&:hover": {
        backgroundColor: "rgba(187, 187, 200, 0.17);",
        boxShadow: "none",
    },
});

export const addIcon = { color: "#6212FF" };

export const addedFilesContainer = {
    maxHeight: 140,
    overflow: "auto",
    boxShadow: "none",
    "&::-webkit-scrollbar": {
        width: "0.3em",
    },

    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#bbbbc8",
        borderRadius: "10px",
    },
};

export const singleAddedFileContainer = (index) => ({
    display: "flex",
    marginTop: index === 0 ? "30px" : "20px",
    alignItems: "center",
    gap: "10px",
    flexDirection: "row",
    justifyContent: "space-between",
});

export const fileNameContainer = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "135%",
    color: "#222222",
    marginBottom: "3px",
};

export const fileSizeContainer = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "140%",
    color: "#9E9EB0",
};

export const filesNotAttached = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "150%",
    color: "#E12626",
    marginBottom: "4px",
};

export const tooltip = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "150%",
    color: "#67677A",
    marginBottom: "40px",
    width: "493px",
};
