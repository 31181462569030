import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Box, Checkbox, Typography, FormControlLabel } from "@mui/material";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import SChipsGroup from "components/UI/ChipGroup/ChipGroup";
import QuestionnaireButtons from "components/TeacherQuestionnaireBodyBlock/Components/QuestionnaireButtons";
import store from "store/TeacherQuestionnaireStore";
import * as themes from "./TaughtSubjectsStepThemes";
import * as textThemes from "themes/TextThemes";
import PurpleCheckBoxDefault from "assets/PaymentAssets/SVG/PurpleCheckBoxDefault";
import PurpleCheckBoxChecked from "assets/PaymentAssets/SVG/PurpleCheckBoxChecked";
import { subjects as subjectsList } from "API/dictionaries/const_data";
import { memo } from "react";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { useMedias } from "API/mediasHook";

const TaughtSubjectsStep = observer(({ currentStep, setCurrentStep }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const [subjects, setSubjects] = useState(TeacherQuestionnaireStore.teacher.subjects.map((item) => item.subjectName));

    const disabledContinue =
        (TeacherQuestionnaireStore.teacher.studentTypes.scholar === false &&
            TeacherQuestionnaireStore.teacher.studentTypes.adult === false &&
            TeacherQuestionnaireStore.teacher.studentTypes.student === false) ||
        TeacherQuestionnaireStore.teacher.subjects.length === 0;

    useEffect(() => {
        subjects.forEach((newSubject) => {
            const exists = TeacherQuestionnaireStore.teacher.subjects.some((subject) => subject.subjectName === newSubject);

            if (!exists) {
                TeacherQuestionnaireStore.teacher.subjects.push({
                    subjectName: newSubject,
                    conditions: [],
                });
            }
        });

        TeacherQuestionnaireStore.setTutorSubjects(TeacherQuestionnaireStore.teacher.subjects.filter((subject) => subjects.some((newSubject) => newSubject === subject.subjectName)));
    }, [subjects]);

    useEffect(() => {
        const fetchData = async () => {
            await TeacherQuestionnaireStore.FetchSubjectsData();
        };

        fetchData();
    }, []);

    const handleCheckboxChange = (type) => (event) => {
        store.setStudentTypes(type, event.target.checked);
    };

    const studentTypes = ["scholar", "student", "adult"];

    return (
        <ScrollToTop>
            <Box sx={themes.TeacherPersonalDataStep}>
                <Box sx={themes.TeacherPersonalData}>
                    <Typography sx={textThemes.HeadlineH41Black}>{t("TeacherQuestionnaire.whatKindOfStudents")}</Typography>
                    <Box sx={themes.CheckBoxGroup(medias)}>
                        {studentTypes.map((type) => (
                            <FormControlLabel
                                key={type}
                                onClick={handleCheckboxChange(type)}
                                disableRipple
                                control={
                                    <Checkbox
                                        disableRipple
                                        sx={themes.CheckBox}
                                        checked={store.teacher.studentTypes[type]}
                                        icon={<PurpleCheckBoxDefault />}
                                        checkedIcon={<PurpleCheckBoxChecked />}
                                    />
                                }
                                label={
                                    type === "scholar" ? t(`TeacherQuestionnaire.schoolchildren`) : type === "student" ? t(`TeacherQuestionnaire.universityStudents`) : t(`TeacherQuestionnaire.adults`)
                                }
                                sx={themes.FormControl}
                            />
                        ))}
                    </Box>
                </Box>
                <Box sx={themes.TeacherPersonalData}>
                    <Box sx={themes.TeacherQuestionnaireTopBlock}>
                        <Typography sx={textThemes.HeadlineH41Black}>{t("TeacherQuestionnaire.whatSubjectDoYouTeach")}</Typography>
                        <Typography sx={textThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.youCanChooseSeveralSubjects")}</Typography>
                    </Box>
                    <Box sx={themes.ChipsOuterContainer(TeacherQuestionnaireStore.teacher.subjects.length)}>
                        <SChipsGroup
                            chipData={TeacherQuestionnaireStore.teacher.subjects.map((item) => item.subjectName)}
                            setChipData={setSubjects}
                            list={TeacherQuestionnaireStore.subjectsList.map((value) => value.Subject)}
                            error={!TeacherQuestionnaireStore.teacher.subjects.length}
                            placeholder={t("TeacherQuestionnaire.enterSubject")}
                            crossColor="#FFF"
                            chipTextColor="#FFF"
                            chipBgColor="#6212FF"
                            chipBorder="none"
                        />
                        <Typography sx={{ ...textThemes.Body2DarkGrey, ...themes.HelperText }}>{t("TeacherQuestionnaire.didntFoundSubject")}</Typography>
                    </Box>
                </Box>
                <QuestionnaireButtons
                    disabled={disabledContinue}
                    setCurrentStep={setCurrentStep}
                    currentStep={currentStep}
                    width={medias.sm ? "" : "100%"}
                />
            </Box>
        </ScrollToTop>
    );
});

export default memo(TaughtSubjectsStep);
