import React, { useState } from "react";
import FindField from "./FindField";
import ListChats from "./ListChats";
import { observer } from "mobx-react-lite";

const StartPage = (props) => {
    const [name, setName] = useState("");

    return (
        <>
            <FindField
                setFolderChats={props.setFolderChats}
                setPage={props.setPage}
                name={name}
                setName={setName}
            />
            <ListChats
                setPageChat={props.setPage}
                name={name}
            />
        </>
    );
};

export default observer(StartPage);
