import { styleProcessing } from "API/plugins/Utilities";

export const WhiteContainer = (width, theme) => {
    let commonStyle = {
        width: width,
        height: "99px",
        borderRadius: "20px",
        backgroundColor: "white",
        padding: "20px",
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.05)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
            maxWidth: "599px",
            height: "auto",
            boxSizing: "border-box",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
};
