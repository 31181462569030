import React from "react";
import { observer } from "mobx-react-lite";
import { Stack, Typography } from "@mui/material";
import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";
import UserStore from "store/UserStore";

const OrderDate = () => {
    return DiplomaStore.status === "ended" && UserStore.role === "student" ? (
        <Stack
            spacing={2}
            direction="row"
        >
            <Stack
                spacing={0.5}
                direction="row"
            >
                <Typography variant="grayText">Создан:</Typography>
                <Typography variant="standard">18 марта</Typography>
            </Stack>
            <Stack
                spacing={0.5}
                direction="row"
            >
                <Typography variant="grayText">Номер заказа:</Typography>
                <Typography variant="standard">555677453</Typography>
            </Stack>
        </Stack>
    ) : (
        <Stack
            direction="row"
            spacing={0.5}
        >
            <Typography variant="grayText">Срок выполнения:</Typography>
            <Typography variant="standard">18 апреля</Typography>
        </Stack>
    );
};

export default observer(OrderDate);
