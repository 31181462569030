import { Box, TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import ClassCard from "./ClassCard/ClassCard";
import * as theme from "./SheduleTableBodyStyled";
import TimeScale from "./TimeScale/TimeScale";
import store from "store/TeacherQuestionnaireStore";
import { observer } from "mobx-react-lite";
import TutorClassesStore from "store/TutorClassesStore";
const SheduleTableBody = observer(({ weekDates }) => {
    // Логика для рабочих не рабочих дней
    const daysOfWeek = Object.keys(store.teacher.timings);

    const convertedRanges = daysOfWeek.map((day) => {
        const timeRanges = store.teacher.timings[day];

        const hourRanges = [];

        timeRanges.forEach((range) => {
            const startHour = parseInt(range.split(":")[0]);

            hourRanges.push(startHour);
        });

        return { day, hourRanges };
    });

    function parseDateString(dateString) {
        let dateParts = dateString.split(" ");

        let day = parseInt(dateParts[0]);

        let monthIndex = getMonthIndex(dateParts[1]);

        let year = parseInt(dateParts[2]);

        return new Date(year, monthIndex, day);
    }

    // Функция для преобразования названия месяца в индекс месяца
    function getMonthIndex(month) {
        let monthNames = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];

        return monthNames.indexOf(month);
    }

    // Преобразуем значения из weekDates в объекты Date и сохраняем их в новом массиве
    let convertedWeekDates = weekDates.map((dateString) => parseDateString(dateString));

    let isCurrentWeek;

    // Проходимся по каждому объекту в массиве classTime
    const classDateSumm = [];

    TutorClassesStore.classes.forEach((item) => {
        let classDateTime = new Date(item.timeStart);

        convertedWeekDates.forEach((weekDate) => {
            if (classDateTime.getDate() === weekDate.getDate() && classDateTime.getMonth() === weekDate.getMonth() && classDateTime.getFullYear() === weekDate.getFullYear()) {
                let dayOfWeek;

                let timeIndex;

                dayOfWeek = classDateTime.getDay(); // Получаем индекс дня недели (0-6)
                if (dayOfWeek === 0) {
                    // Если день недели - воскресенье (индекс 0), изменяем его на 6
                    dayOfWeek = 6;
                } else {
                    // Иначе уменьшаем индекс дня недели на 1
                    dayOfWeek -= 1;
                }

                timeIndex = classDateTime.getHours();
                let cellIndex = timeIndex * 7 + dayOfWeek;

                isCurrentWeek = true;
                classDateSumm.push(cellIndex); // Store the matching date
                // Здесь можно добавить нужную логику, когда даты и время совпадают
            }
        });
    });

    const currentDay = new Date();

    let dayOfWeek;

    let timeIndex;

    dayOfWeek = currentDay.getDay(); // Получаем индекс дня недели (0-6)
    if (dayOfWeek === 0) {
        // Если день недели - воскресенье (индекс 0), изменяем его на 6
        dayOfWeek = 6;
    } else {
        // Иначе уменьшаем индекс дня недели на 1
        dayOfWeek -= 1;
    }

    timeIndex = currentDay.getHours();
    let currentTimeIndex = timeIndex * 7 + dayOfWeek;

    const minutes = currentDay.getMinutes();

    let isCurrentWeekForTime;

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" };

        const date = new Date(dateString);

        return date.toLocaleString("ru-RU", options);
    };

    const formattedDate = formatDate(currentDay);

    if (weekDates.includes(formattedDate)) {
        isCurrentWeekForTime = true;
    } else {
        isCurrentWeekForTime = false;
    }

    return (
        <TableBody>
            {/* Таблица часов */}
            {[...Array(24)].map((_, index) => (
                <TableRow key={index}>
                    {/* Столбец времени */}
                    <TableCell
                        sx={theme.timeColumn}
                        align="center"
                        scope="row"
                    >
                        {`${index.toString().padStart(2, "0")}:00`}
                    </TableCell>
                    {/* Блоки дня недели */}
                    {convertedRanges.map((entry, dayIndex) => {
                        const { day, hourRanges } = entry;

                        const cellIndex = index * convertedRanges.length + dayIndex;

                        const currentClass = TutorClassesStore.classes.find((classItem) => {
                            const classCellIndex = parseClassDateTime(classItem.timeStart, dayIndex, index);

                            return classCellIndex === cellIndex;
                        });

                        return (
                            <TableCell
                                key={dayIndex}
                                sx={[theme.timeAndDayCell, daysOfWeek.includes(day) && hourRanges.includes(index) ? theme.workDayBg : theme.dayOffBg]}
                                align="center"
                            >
                                <TimeScale
                                    isCurrentWeekForTime={isCurrentWeekForTime}
                                    currentTimeIndex={currentTimeIndex}
                                    cellIndex={cellIndex}
                                    minutes={minutes}
                                />
                                {currentClass && isCurrentWeek && classDateSumm.includes(cellIndex) && (
                                    <Box>
                                        <ClassCard class={currentClass} />
                                    </Box>
                                )}
                            </TableCell>
                        );
                    })}
                </TableRow>
            ))}
        </TableBody>
    );
});

function parseClassDateTime(dateTime) {
    const classDateTime = new Date(dateTime);

    const classDay = classDateTime.getDay();

    const classHour = classDateTime.getHours();

    return classHour * 7 + (classDay === 0 ? 6 : classDay - 1);
}

export default SheduleTableBody;
