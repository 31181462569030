import { createTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

export const OrdersPriceAccordionTheme = createTheme({
    components: {
        MuiAccordion: {
            styleOverrides: {
                // Name of the slot
                root: {
                    width: "249px!important",
                    position: "relative",
                    boxShadow: "none!important",
                    "&:before": {
                        background: "none!important",
                    },
                    "&.Mui-expanded": {
                        marginTop: "0px!important",
                        marginBottom: "0px!important",
                    },
                    ".MuiAccordionSummary-root": {
                        minHeight: "0px!important",
                        padding: 0,
                        paddingBottom: "12px",
                        ".MuiAccordionSummary-content": {
                            marginTop: "0px!important",
                            marginBottom: "0px!important",
                            ".Mui-expanded": {
                                marginTop: "0px!important",
                                marginBottom: "0px!important",
                            },
                        },
                    },
                    ".MuiAccordionDetails-root": {
                        padding: 0,
                    },
                },
            },
        },
    },
});

export const headerOrdersPriceStyle = {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#222222",
    flex: "none",
    order: 0,
    flexGrow: 1,
};

export const OrdersPriceTheme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "--TextField-brandBorderColor": "#9E9EB0",
                    "--TextField-brandBorderHoverColor": "#6212FF",
                    "--TextField-brandBorderFocusedColor": "#6212FF",
                    "& label.Mui-focused": {
                        color: "var(--TextField-brandBorderFocusedColor)",
                    },
                    "& .Mui-focused": {
                        background: "#FFFFFF",
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: "var(--TextField-brandBorderColor)",
                },
                root: {
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: "var(--TextField-brandBorderHoverColor)",
                    },
                    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: "var(--TextField-brandBorderFocusedColor)",
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    "&:before, &:after": {
                        borderBottom: "2px solid var(--TextField-brandBorderColor)",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                    },
                    "&.Mui-focused:after": {
                        borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    "&:before": {
                        borderBottom: "1px solid var(--TextField-brandBorderColor)",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderBottom: "1px solid var(--TextField-brandBorderHoverColor)",
                    },
                    "&.Mui-focused:after": {
                        borderBottom: "1px solid var(--TextField-brandBorderFocusedColor)",
                    },
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                // Name of the slot
                root: {
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px",
                    width: "116px",
                    height: "44px",
                    flex: "none",
                    order: 1,
                    flexGrow: 0,
                    "&.MuiInputBase-root": {
                        border: "1px!important solid #9E9EB0",
                        height: "31px",
                        borderRadius: "12px",
                        paddingLeft: 0,
                        paddingTop: 0,
                    },
                    ".MuiInputBase-input": {
                        width: "-webkit-fill-available!important",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "150%",
                        color: "#222222",
                        flex: "none",
                        order: 0,
                        flexGrow: 0,
                    },
                },
            },
        },
    },
});
