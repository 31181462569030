//На какой бюджет рассчитываете?
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import SBButtonGroup from "components/UI/ButtonGroup";
import store from "store/RequestStore";

import TextField from "@mui/material/TextField";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import StepHiddenButtonWrapper from "components/OrdersBodyBlock/RenewOrder/StepHiddenButtonWrapper";
import filterStore from "store/FilterStore";
import RequestNullAlert from "./RequestNullAlert";
import * as theme from "./Step24Theme";
const Step24 = () => {
    const [alert, setAlert] = useState(0);

    let data = store.storage[store.activeStepIndex].substeps[store.subStepIndex]?.value;

    if (data === undefined) store.setActiveSubStepIndex(0);

    const [budget, setBudget] = useState(store.storage[store.activeStepIndex].substeps[store.subStepIndex].value[0]);

    const [cannot, setCannot] = useState(store.storage[store.activeStepIndex].substeps[store.subStepIndex].value[1]);

    useEffect(() => {
        if (cannot === true) {
            setBudget("");
        }
    }, [cannot]);

    useEffect(() => {
        if (budget !== "") {
            setCannot(false);
        }
    }, [budget]);

    const handleNext = () => {
        if (budget === "") {
            setCannot(true);
            store.setStep(16, "Не могу оценить");
            store.setStepValue(16, ["", true]);
            store.incrementStepIndex();
        } else {
            store.setStep(16, "До " + budget + " рублей");
            store.setStepValue(16, [budget, false]);
            store.incrementStepIndex();
        }
    };

    const handleSubmitFilterChange = () => {
        filterStore.setSubmitButtonFilter(false);
    };

    return (
        <>
            <Box sx={theme.TopText}>Укажите примерную стоимость, финальный бюджет можно будет обсудить с специалистом</Box>

            <TextField
                placeholder={"Бюджет, ₽"}
                variant="standard"
                sx={theme.TextField}
                onChange={(event) => {
                    setBudget(event.target.value);
                    handleSubmitFilterChange();
                }}
                value={budget}
            />

            <Box sx={theme.FormWrapper}>
                <FormControlLabel
                    sx={theme.FormControlLabel}
                    control={
                        <Checkbox
                            sx={theme.Checkbox}
                            checked={cannot}
                            onChange={(event, newInputValue) => {
                                setCannot(newInputValue);
                                handleSubmitFilterChange();
                            }}
                            style={{ color: "#6212FF" }}
                        />
                    }
                />
                <Box sx={theme.BottomText}>Не могу оценить стоимость заказа, жду предложений от исполнителей</Box>
            </Box>
            <StepHiddenButtonWrapper submitRenew={handleNext}>
                <SBButtonGroup
                    handleNext={handleNext}
                    handleBack={() => store.decrementStepIndex()}
                    activeStep={store.activeStepIndex}
                    subStep={store.subStepIndex}
                    steps={store.storage}
                />
                {alert === 1 ? <RequestNullAlert>Чтобы перейти к следующему вопросу, заполните это поле</RequestNullAlert> : null}
            </StepHiddenButtonWrapper>
        </>
    );
};

export default observer(Step24);
