import React from "react";
import { Stack, Typography } from "@mui/material";
import LittleStar from "components/DiplomaChats/Icons/LittleStar";
import * as styles from "pages/DiplomaChats/styles";

const StarsCount = ({ starsCount }) => {
    return (
        <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            justifyContent="center"
            sx={styles.gradeContainer}
        >
            <Typography sx={styles.gradeTypography}>{starsCount}</Typography>
            <span style={styles.gradeStarContainer}>
                <LittleStar />
            </span>
        </Stack>
    );
};

export default StarsCount;
