const VisibilityOffIcon = function (props) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon">
                <g id="eye">
                    <path
                        id="Vector"
                        d="M4 12.2791C4 12.2791 6.90909 6.46094 12 6.46094C17.0909 6.46094 20 12.2791 20 12.2791C20 12.2791 17.0909 18.0973 12 18.0973C6.90909 18.0973 4 12.2791 4 12.2791Z"
                        stroke="#67677A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_2"
                        d="M12.0002 14.4652C13.2052 14.4652 14.182 13.4884 14.182 12.2834C14.182 11.0784 13.2052 10.1016 12.0002 10.1016C10.7952 10.1016 9.81836 11.0784 9.81836 12.2834C9.81836 13.4884 10.7952 14.4652 12.0002 14.4652Z"
                        stroke="#67677A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </svg>
    );
};

export default VisibilityOffIcon;
