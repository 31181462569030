import React, { useState } from "react";

import EmojiPicker from "emoji-picker-react";

import { IconButton } from "@mui/material";

import { Emoji } from "assets/MiniChat/SVG/Emoji";

import { IconBack } from "components/MiniChat/OneChat/SendMessage/SendMessageStyles";
import PopperMenu from "components/UI/PopperMenu";
import SButton from "../../../../UI/SButton";

const EmojiButton = ({ text, setText, setChanged }) => {
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const handleChooseEmoji = (emoji) => {
        setText(`${text}${emoji.emoji}`);
        setChanged(true);
    };

    return (
        <PopperMenu
            content={<EmojiPicker onEmojiClick={handleChooseEmoji} />}
            open={showEmojiPicker}
            setOpen={setShowEmojiPicker}
        >
            <SButton
                variant="icon"
                style={IconBack}
                disableRipple
            >
                <Emoji />
            </SButton>
        </PopperMenu>
    );
};

export default EmojiButton;
