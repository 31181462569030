import { memo } from "react";
import { Avatar, Typography } from "@mui/material";
import * as themes from "./AvatarAreaThemes";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";

const AvatarArea = ({ children, ...props }) => {
    return (
        <Box sx={themes.tcAvatarAreaStyle}>
            {props.value.IsAnonymous ? (
                <Box>
                    <Box
                        sx={themes.anonymousAvatarContainer}
                        onClick={() => window.open("/tutor/" + props.value._id, "_blank", "noreferrer")}
                    >
                        <Typography sx={themes.anonymousAvatarTextStyle}>Фото скрыто специалистом</Typography>
                    </Box>
                </Box>
            ) : (
                <Box>
                    <ThemeProvider theme={themes.TCAvatarThemeClickable}>
                        <Avatar
                            src={props.value.AvatarSrc}
                            onClick={() => window.open("/tutor/" + props.value._id, "_blank", "noreferrer")}
                        ></Avatar>
                    </ThemeProvider>
                </Box>
            )}
        </Box>
    );
};

export default memo(AvatarArea);
