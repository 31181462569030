import { createTheme } from "@mui/material/styles";

export const tagsBlockContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    rowGap: "3px",
    columnGap: "3px",
    flexWrap: "wrap",
    width: "188px",
    height: "75px",
    flex: "none",
    order: 2,
    flexGrow: 0,
    zIndex: 2,
};

export const textStyle = {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "140%",
    display: "flex",
    alignItems: "center",
    color: "#222222",
};

export const TagTheme = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                // Name of the slot
                root: {
                    height: "23px",
                    "&:hover": {
                        background: "#FFFFFF!important",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "#FFFFFF!important",
                        borderColor: "#6212FF",
                    },
                },
                label: {
                    padding: "3px 10px 3px 10px!important",
                },
            },
        },
    },
});
