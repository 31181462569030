import React from "react";
import { observer } from "mobx-react-lite";

import { Stack } from "@mui/material";

import StyledWhiteBox from "components/DiplomaChats/StyledComponents/StyledWhiteBox";
import OrderInfoForStudent from "./Student/OrderInfoForStudent";

import UserStore from "store/UserStore";
import OrderInfoForTutor from "./Tutor/OrderInfoForTutor";
import Box from "@mui/material/Box";

const OrderInfo = () => {
    return (
        <Box sx={{ marginTop: "20px" }}>
            <StyledWhiteBox
                padding={"30px"}
                width={"435px"}
            >
                <Stack
                    direction="column"
                    spacing={2.5}
                    style={{ width: "100%" }}
                >
                    {UserStore.role === "student" ? <OrderInfoForStudent /> : <OrderInfoForTutor />}
                </Stack>
            </StyledWhiteBox>
        </Box>
    );
};

export default observer(OrderInfo);
