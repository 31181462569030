import React, { memo, useState } from "react";
import * as themes from "./TagsBlockThemes";
import { observer } from "mobx-react-lite";
import { Chip, IconButton, Menu, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CatalogStore from "store/CatalogStore";
import Box from "@mui/material/Box";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import { useMedias } from "../../../../API/mediasHook";

const TagsBlock = observer(({ children, ...props }) => {
    const handleClick = (query, event) => {
        CatalogStore.ProcessTagSearch(query.Query);
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleMenuToggle = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (query, event) => {
        if (query !== null) {
            CatalogStore.ProcessTagSearch(query.Query);
        }

        setAnchorEl(null);
    };

    const medias = useMedias();

    const isSmallScreen = medias.sm;

    // const maxTags = isSmallScreen ? 5 : 2;

    const maxTags =
        !isSmallScreen && props.styleMode === 1
            ? props.value.Tags.length // если оба условия выполнены, убираем ограничение
            : isSmallScreen
              ? 4
              : 2; // иначе оставляем как было

    // console.log(isSmallScreen);
    // console.log(props.styleMode);
    // console.log(maxTags);

    const remainingTagsCount = props.value.Tags.length - maxTags;

    return (
        <Box sx={themes.tagsBlockContainerStyle(medias, props.styleMode ? props.styleMode : 0)}>
            {props.value.Tags.slice(0, maxTags).map((tag) => (
                <ThemeProvider
                    key={tag.ID}
                    theme={themes.TagTheme(props.styleMode ? props.styleMode : 0)}
                >
                    <Chip
                        key={tag.ID}
                        label={tag.Query}
                        variant="outlined"
                        size="small"
                        disableRipple
                        sx={themes.textStyle}
                        onClick={handleClick.bind(this, tag)}
                    />
                </ThemeProvider>
            ))}

            {remainingTagsCount > 0 && (
                <Box>
                    <ThemeProvider theme={themes.MoreChipTheme}>
                        <Chip
                            label={`+ ${remainingTagsCount}`}
                            disableRipple
                            onClick={handleMenuToggle}
                        />
                    </ThemeProvider>
                    <ThemeProvider theme={themes.MenuTheme}>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose.bind(this, null)}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            {props.value.Tags.slice(maxTags).map((value) => (
                                <MenuItem
                                    key={value.ID}
                                    onClick={handleMenuClose}
                                    disableRipple
                                >
                                    <Typography sx={themes.actionTextStyle}>{value.Query}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </ThemeProvider>
                </Box>
            )}
        </Box>
    );
});

export default memo(TagsBlock);
