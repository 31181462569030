import { styleProcessing } from "API/plugins/Utilities";

export const ClassInfo = (theme) => {
    let commonStyle = {
        background: "#ffffff",
        maxWidth: "435px",
        width: "100%",
        height: "fit-content",
        padding: "30px",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const IconButton = { width: "24px", height: "24px", marginTop: "-36px", padding: 0, borderRadius: "50%", border: "solid 1px #222222", color: "red" };

export const TutorBlock = {
    display: "flex",
    gap: "10px",
    alignItems: "center",
};

export const Rating = {
    borderRadius: "6px",
    background: "#6212FF",
    display: "flex",
    padding: "2px 10px 3px 10px",
    width: "fit-content",
};

export const TutorAndRating = {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
};

export const Avatar = {
    width: "60px",
    height: "60px",
};

export const TutorInfo = {
    display: "flex",
    gap: "16px",
    alignItems: "center",
};

export const TimeBlock = {
    display: "flex",
    gap: "5px",
    alignItems: "center",
};

export const ClassDate = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "9px 0px 10px 0px",
    borderTop: "1px solid #DADADF",
    borderBottom: "1px solid #DADADF",
};

export const ClassPrice = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const OrderStatements = {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
};

export const YourOrder = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const Radio = {
    margin: 0,
    padding: 0,
};

export const RadioGroup = {
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const FormControlAdd = (value) => {
    return {
        margin: 0,
        padding: 0,
        display: "flex",
        gap: "8px",
        ".MuiTypography-root": {
            fontSize: "14px",
            fontWeight: "500",
            color: value !== "add" ? "#9E9EB0" : "#222", // Изменение цвета текста для выбранного значения
        },
    };
};

export const FormControlSubstract = (value) => {
    return {
        margin: 0,
        padding: 0,
        display: "flex",
        gap: "8px",
        ".MuiTypography-root": {
            fontSize: "14px",
            fontWeight: "500",
            color: value !== "add" ? "#222" : "#9E9EB0", // Изменение цвета текста для выбранного значения
        },
    };
};

export const YourOrderAndRadio = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const FormControlCheckBox = {
    margin: 0,
    padding: 0,
    display: "flex",
    gap: "8px",
    ".MuiTypography-root": {
        fontSize: "14px",
        fontWeight: "500",
        color: "#222",
    },
};

export const CheckBox = {
    margin: 0,
    padding: 0,
};

export const CheckBoxBlock = {
    padding: "11px 0px 12px 0px",
    borderTop: "1px dashed #DADADF",
    borderBottom: "1px dashed #DADADF",
};

export const TotalBill = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const SecuredStatement = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
};

export const SecuredBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    padding: "16px",
    borderRadius: "10px",
    background: "rgba(187, 187, 200, 0.15)",
};

export const LockBlock = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
};
