export const SheduleContainer = {
    position: "relative",
    boxSizing: "border-box",
    maxWidth: "1216px",
    width: "100%",
    padding: "0 0 60px 0",
    margin: "0 auto",
    marginBottom: "70px",
    marginTop: "40px",
    background: "rgba(255, 255, 255, 1)",
    border: "solid 1px rgba(238, 238, 240, 1)",
    borderRadius: "20px",
};

export const Divider = { color: "#EEEEF0" };

export const Header = {
    position: "sticky",
    top: 0,
    zIndex: 998,
    background: "rgba(255, 255, 255, 1)",
    paddingTop: "16px",
    borderRadius: "20px",
};
