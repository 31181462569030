import React, { memo } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import MainPromoBodyBlock from "components/MainPromoBodyBlock/MainPromoBodyBlock";

const theme = createTheme({
    spacing: 1,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const MainPromoPage = ({ children, ...props }) => {
    return (
        <ScrollToTop>
            <ThemeProvider theme={theme}>
                <MainPromoBodyBlock />
            </ThemeProvider>
        </ScrollToTop>
    );
};

export default memo(MainPromoPage);
