import React, { memo } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Checkbox, FormControl, FormControlLabel, FormGroup, ToggleButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";

const FreeMultipleChoiceFilter = ({ children, ...props }) => {
    const processing = (value, event) => {
        props.processingChange(event, value);
    };

    const toggle = (value, valueAddition) => {
        props.toggleFunc(value, valueAddition);
    };

    return (
        <ThemeProvider theme={props.themeWrapper}>
            <FormControl>
                <FormGroup>
                    {props.propOptions.map((value) => (
                        <Box key={value[props.valueForKey]}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disableRipple={true}
                                        checked={value[props.valueToBind]}
                                        onChange={processing.bind(this, value)}
                                    />
                                }
                                label={value[props.valueToShow]}
                            />
                            {props.useAddition && value[props.valueToBind] ? (
                                <ThemeProvider theme={props.themeOptionAddition}>
                                    <Box sx={props.themeOptionsAdditionContainer}>
                                        {value[props.propOptionsAddition].map((valueAddition) => (
                                            <ToggleButton
                                                disableRipple={true}
                                                key={valueAddition[props.valueForKeyAddition]}
                                                value="check"
                                                selected={valueAddition[props.valueToBindAddition]}
                                                onChange={() => toggle(value, valueAddition)}
                                            >
                                                <Typography sx={props.themeOptionsAdditionText}>{valueAddition[props.valueToShowAddition]}</Typography>
                                            </ToggleButton>
                                        ))}
                                    </Box>
                                </ThemeProvider>
                            ) : null}
                        </Box>
                    ))}
                </FormGroup>
            </FormControl>
        </ThemeProvider>
    );
};

export default memo(FreeMultipleChoiceFilter);
