import React, { memo, useState } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./StickyVideoBlockThemes";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { ThemeProvider } from "@mui/material/styles";
import PlayIcon from "assets/CommonAssets/SVG/PlayIcon";
import { Button, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ModalNotAuth from "components/UI/ModalNotAuth/ModalNotAuth";
import ModalVideo from "components/UI/ModalVideo/ModalVideo";
import UserStore from "store/UserStore";
import MiniChatStore from "store/MiniChatStore";

const StickyVideoBlock = observer(({ children, ...props }) => {
    const navigate = useNavigate();

    const [dialogOpen, SetDialogOpen] = useState(false);

    const [dialogVideoOpen, SetDialogVideoOpen] = useState(false);

    const messageTutor = () => {
        //
    };

    const showDialog = () => {
        UserStore.isAuth ? MiniChatStore.SetOpen(true) : SetDialogOpen(true);
    };

    const hideDialog = () => {
        SetDialogOpen(false);
    };

    const showVideoDialog = () => {
        SetDialogVideoOpen(true);
    };

    const discardVideoDialog = () => {
        SetDialogVideoOpen(false);
    };

    return (
        <Box sx={themes.stickyVideoBlockContainerStyle}>
            {!props.value.IsAnonymous ? (
                <Box sx={themes.avatarAreaStyle}>
                    <Box>
                        <ThemeProvider theme={themes.AvatarTheme}>
                            <Avatar src={props.value.AvatarSrc}></Avatar>
                        </ThemeProvider>
                        <Box
                            onClick={showVideoDialog}
                            style={themes.playIconBGStyle}
                        >
                            <PlayIcon></PlayIcon>
                        </Box>
                    </Box>
                </Box>
            ) : null}
            <Box sx={themes.actionsAreaStyle}>
                <Box sx={themes.priceContainerStyle}>
                    <Box sx={themes.textContainerStyle}>
                        <Typography sx={themes.fromTextStyle}>от</Typography>
                        <Typography sx={themes.fromTextValueStyle}>{props.value.PriceFrom.toLocaleString()} ₽/ч</Typography>
                    </Box>
                    <ThemeProvider theme={themes.AddCashbackButtonTheme}>
                        <Tooltip
                            title={
                                <Typography sx={themes.tooltipCashbackStyle}>
                                    <span>
                                        При обучении у репетитора вам вернётся <span style={themes.tooltipCashbackValueStyle}>{props.value.Cashback} баллов </span>на бонусный счёт
                                    </span>
                                </Typography>
                            }
                            arrow={true}
                            placement="right"
                        >
                            <Button
                                onClick={() => {
                                    console.log("clicked");
                                }}
                            >
                                <Typography style={themes.cashbackTextStyle}>+{props.value.Cashback}</Typography>
                            </Button>
                        </Tooltip>
                    </ThemeProvider>
                </Box>
                <Box sx={themes.buttonsContainerStyle}>
                    <ThemeProvider theme={themes.OrderButtonTheme}>
                        <Button
                            onClick={() => navigate("/bid")}
                            disableRipple
                        >
                            <Typography style={themes.orderTextStyle}>Предложить заказ</Typography>
                        </Button>
                    </ThemeProvider>
                    <ThemeProvider theme={themes.MessageButtonTheme}>
                        <Button
                            onClick={false ? messageTutor : showDialog}
                            disableRipple
                        >
                            <Typography style={themes.messageTextStyle}>Написать</Typography>
                        </Button>
                    </ThemeProvider>
                    <Typography sx={themes.textAdvieceStyle}>Отвечает примерно в течение 1 часа</Typography>
                </Box>
            </Box>
            <ModalNotAuth
                open={dialogOpen}
                handleClose={hideDialog}
                value={props.value}
                header={"Зарегистрируйтесь, чтобы написать репетитору"}
                content={"Чтобы начать переписку с репетитором " + props.value.Name + ", нужно создать аккаунт на StudyBuddy"}
            ></ModalNotAuth>
            <ModalVideo
                open={dialogVideoOpen}
                handleDiscard={discardVideoDialog}
                value={props.value}
            ></ModalVideo>
        </Box>
    );
});

export default memo(StickyVideoBlock);
