import React, { memo } from "react";
import * as themes from "./InfoAreaThemesDefault";
import Box from "@mui/material/Box";
import NameBlock from "./NameBlock/NameBlock";
import DescriptionBlock from "./DescriptionBlock/DescriptionBlock";

const InfoArea = ({ children, ...props }) => {
    return (
        <Box sx={themes.infoAreaContainerStyle}>
            <NameBlock value={props.tutor}></NameBlock>
            <DescriptionBlock value={props.value}></DescriptionBlock>
        </Box>
    );
};

export default memo(InfoArea);
