import React from "react";
import { Typography } from "@mui/material";
import * as themes from "components/SettingsBodyBlock/TutorSettings/MainForm/MainFormThemes";
import SButton from "components/UI/SButton";
import Box from "@mui/material/Box";
import * as textThemes from "themes/TextThemes";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { useMedias } from "API/mediasHook";
import SIconButton from "../../SIconButton";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";

const Places = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const handleEdit = () => {
        props.handleSet(8);
    };

    return (
        <Box sx={themes.SmallItem(medias)}>
            <Box sx={themes.ItemContent(medias)}>
                <Box sx={themes.TitleAndEdit}>
                    <Typography sx={medias.sm ? textThemes.HeadlineH41Black : textThemes.HeadlineH5Black}>{t("teacherSettings.questionnaire.classesPlace")}</Typography>
                    {medias.sm ? (
                        <SButton
                            onClick={handleEdit}
                            variant="text"
                        >
                            {t("teacherSettings.questionnaire.edit")}
                        </SButton>
                    ) : (
                        <SIconButton onClick={handleEdit}>
                            <DriveFileRenameOutlineOutlinedIcon />
                        </SIconButton>
                    )}
                </Box>
                <Typography sx={textThemes.Body1Black}>
                    {TeacherQuestionnaireStore.teacher.places["online"] && t("teacherSettings.questionnaire.online")}
                    {TeacherQuestionnaireStore.teacher.places["online"] === true && TeacherQuestionnaireStore.teacher.places["teacher"] === true && ", "}
                    {TeacherQuestionnaireStore.teacher.places["teacher"] && t("teacherSettings.questionnaire.atTutors")}
                    {(TeacherQuestionnaireStore.teacher.places["online"] === true || TeacherQuestionnaireStore.teacher.places["teacher"] === true) &&
                        TeacherQuestionnaireStore.teacher.places["student"] === true &&
                        ", "}
                    {TeacherQuestionnaireStore.teacher.places["student"] && t("teacherSettings.questionnaire.atStudents")}
                </Typography>
            </Box>
        </Box>
    );
});

export default memo(Places);
