import React from "react";

import { Stack, Typography } from "@mui/material";

const OrderNumberAndData = () => {
    return (
        <Stack
            direction="column"
            spacing={0.5}
        >
            <Typography variant="grayText">
                Создан: <Typography variant="standard">18 марта</Typography>
            </Typography>
            <Typography variant="grayText">
                Номер заказа: <Typography variant="standard">555677453</Typography>
            </Typography>
        </Stack>
    );
};

export default OrderNumberAndData;
