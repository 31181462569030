import React from "react";

import { Stack } from "@mui/material";

import Header from "./Header";
import FileInfo from "./FileInfo";

const WithFiles = ({ files }) => {
    return (
        <Stack
            direction="column"
            spacing={4.5}
        >
            <Header />
            <Stack
                direction="column"
                spacing={2.5}
            >
                {files.map((file, index) => (
                    <Stack
                        key={index}
                        direction="column"
                        spacing={index !== files.length - 1 ? 2.5 : 0}
                    >
                        <FileInfo
                            key={file.name}
                            fileInfo={file}
                            index={index}
                            count={files.length}
                        />
                        {index !== files.length - 1 && <span style={{ borderTop: "1px dashed #DADADF" }}></span>}
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
};

export default WithFiles;
