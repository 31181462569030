import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import { Button, Dialog, IconButton } from "@mui/material";
import * as themes from "./ModalRatePlatformThemes";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CloseRounded } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import VideoChatStore from "store/VideoChatStore";
import RateConnection from "./RateConnection";
import RatePositives from "./RatePositives";
import RateNegatives from "./RateNegatives";

const ModalRatePlatform = observer(({ children, ...props }) => {
    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        props.handleSave(VideoChatStore.feedbackStep + 1);
    };

    let currentContent = null;

    switch (VideoChatStore.feedbackStep) {
        case 1:
            currentContent = <RateConnection></RateConnection>;
            break;
        case 2:
            currentContent = <RatePositives></RatePositives>;
            break;
        case 3:
            currentContent = <RateNegatives></RateNegatives>;
            break;
        default:
            currentContent = null;
    }

    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={props.open}
                onClose={Close}
            >
                <Box sx={themes.dialogContainerStyle}>
                    <ThemeProvider theme={themes.CloseIconTheme}>
                        <IconButton
                            onClick={Close}
                            disableRipple
                        >
                            <CloseRounded></CloseRounded>
                        </IconButton>
                    </ThemeProvider>
                    <Box sx={themes.cardContainerStyle}>
                        <Box sx={themes.headerBlockContainerStyle}>
                            <Typography sx={themes.headerTextStyle}>Оценка платформы</Typography>
                            <Typography sx={themes.subHeaderTextStyle}>Ваша обратная связь помогает нам стать лучше!</Typography>
                        </Box>
                        <Box sx={themes.contentBlockContainerStyle}>{currentContent}</Box>
                        <Box sx={themes.actionsBlockContainerStyle}>
                            <Box sx={themes.actionsContainerStyle}>
                                <ThemeProvider theme={themes.ModalActionButtonTheme(VideoChatStore.stepReady)}>
                                    <Button
                                        onClick={Save}
                                        disabled={!VideoChatStore.stepReady}
                                        disableRipple
                                    >
                                        <Typography style={themes.buttonTextStyle}>Готово</Typography>
                                    </Button>
                                </ThemeProvider>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </ThemeProvider>
    );
});

export default memo(ModalRatePlatform);
