import React from "react";

const IconDownload = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.625 2.5C10.625 2.15482 10.3452 1.875 10 1.875C9.65482 1.875 9.375 2.15482 9.375 2.5V10.9911L6.27528 7.89139C6.0312 7.64731 5.63547 7.64731 5.39139 7.89139C5.14731 8.13547 5.14731 8.5312 5.39139 8.77527L9.55806 12.9419C9.61798 13.0019 9.68704 13.0471 9.76076 13.0776C9.83445 13.1081 9.91526 13.125 10 13.125C10.0847 13.125 10.1655 13.1081 10.2392 13.0776C10.313 13.0471 10.382 13.0019 10.4419 12.9419L14.6086 8.77527C14.8527 8.5312 14.8527 8.13547 14.6086 7.89139C14.3645 7.64731 13.9688 7.64731 13.7247 7.89139L10.625 10.9911V2.5ZM2.5 11.875C2.84518 11.875 3.125 12.1548 3.125 12.5V15.8333C3.125 16.1096 3.23475 16.3746 3.4301 16.5699C3.62545 16.7653 3.8904 16.875 4.16667 16.875H15.8333C16.1096 16.875 16.3746 16.7653 16.5699 16.5699C16.7653 16.3746 16.875 16.1096 16.875 15.8333V12.5C16.875 12.1548 17.1548 11.875 17.5 11.875C17.8452 11.875 18.125 12.1548 18.125 12.5V15.8333C18.125 16.4411 17.8836 17.024 17.4538 17.4538C17.024 17.8836 16.4411 18.125 15.8333 18.125H4.16667C3.55888 18.125 2.97598 17.8836 2.54621 17.4538C2.11644 17.024 1.875 16.4411 1.875 15.8333V12.5C1.875 12.1548 2.15482 11.875 2.5 11.875Z"
                fill="white"
            />
        </svg>
    );
};

export default IconDownload;
