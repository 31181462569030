import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import { Button, Dialog, Divider, IconButton, Rating } from "@mui/material";
import * as themes from "./ModalFinishCallThemes";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CloseRounded, ErrorSharp } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import VideoChatStore from "store/VideoChatStore";
import TextField from "@mui/material/TextField";
import StarFilledIcon from "assets/VideoChatAssets/SVG/starFilledIcon";
import StarOutlinedIcon from "assets/VideoChatAssets/SVG/starOutlinedIcon";

const ModalFinishCall = observer(({ children, ...props }) => {
    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        props.handleSave();
    };

    const processingFeedbackRating = (event) => {
        VideoChatStore.SetFeedbackRating(Number(event.target.value));
    };

    const processingFeedbackMessage = (event) => {
        VideoChatStore.SetFeedbackMessage(event.target.value);
    };

    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={props.open}
                onClose={Close}
            >
                <Box sx={themes.dialogContainerStyle}>
                    <ThemeProvider theme={themes.CloseIconTheme}>
                        <IconButton
                            onClick={Close}
                            disableRipple
                        >
                            <CloseRounded></CloseRounded>
                        </IconButton>
                    </ThemeProvider>
                    <Box sx={themes.cardContainerStyle}>
                        <Box sx={themes.headerBlockContainerStyle}>
                            <Box>
                                <Typography sx={themes.headerTextStyle}>Занятие завершено</Typography>
                                <Typography sx={themes.callTimeStyle}>
                                    {VideoChatStore.call.StartDateTime.toLocaleString("default", { day: "numeric", month: "long" }) + ", "}
                                    {VideoChatStore.call.StartDateTime.toLocaleString("default", { weekday: "short" }) + ", "}
                                    {VideoChatStore.call.StartDateTime.toLocaleString("default", { hour: "2-digit", minute: "2-digit" })}
                                </Typography>
                            </Box>
                            <Box sx={themes.infoMessageContainerStyle}>
                                <ThemeProvider theme={themes.WarningIconTheme}>
                                    <ErrorSharp></ErrorSharp>
                                </ThemeProvider>
                                <Typography sx={themes.infoMessageStyle}>Запись занятия сохранена, посмотреть можно в «Мои занятия»</Typography>
                            </Box>
                        </Box>
                        <Box sx={themes.contentBlockContainerStyle}>
                            <Box sx={themes.ratingContainerStyle}>
                                <Divider sx={themes.dividerStyle(0, 0)}></Divider>
                                <Typography sx={themes.headerTextStyle}>Понравилось ли вам занятие?</Typography>
                                <ThemeProvider theme={themes.RatingTheme}>
                                    <Rating
                                        value={VideoChatStore.feedback.feedbackRating}
                                        onChange={processingFeedbackRating}
                                        icon={<StarFilledIcon fontSize="inherit" />}
                                        emptyIcon={<StarOutlinedIcon fontSize="inherit" />}
                                    />
                                </ThemeProvider>
                                <Divider sx={themes.dividerStyle(0, 0)}></Divider>
                            </Box>
                            <Box sx={themes.feedbackMessageContainerStyle}>
                                <Typography sx={themes.headerTextStyle}>Оставьте, пожалуйста, отзыв о репетиторе</Typography>
                                <ThemeProvider theme={themes.FeedbackMessageTheme(VideoChatStore.feedback.feedbackMessage.length === 1000)}>
                                    <TextField
                                        onChange={processingFeedbackMessage}
                                        value={VideoChatStore.feedback.feedbackMessage}
                                        variant="outlined"
                                        placeholder={"Ваш отзыв о репетиторе"}
                                        multiline
                                        rows={6}
                                        inputProps={{
                                            maxLength: 1000,
                                        }}
                                        FormHelperTextProps={{ component: "div" }}
                                        helperText={
                                            <Box sx={themes.helperContainerStyle(VideoChatStore.feedback.feedbackMessage.length === 1000)}>
                                                {VideoChatStore.feedback.feedbackMessage.length === 1000 ? <Typography sx={themes.currLengthTextMaxStyle}>Достигнут лимит символов</Typography> : null}
                                                <Box sx={themes.helperCounterContainerStyle}>
                                                    <Typography sx={themes.currLengthTextStyle(VideoChatStore.feedback.feedbackMessage.length)}>
                                                        {VideoChatStore.feedback.feedbackMessage.length}
                                                    </Typography>
                                                    <Typography sx={themes.maxLengthTextStyle}>/1000</Typography>
                                                </Box>
                                            </Box>
                                        }
                                    ></TextField>
                                </ThemeProvider>
                            </Box>
                        </Box>
                        <Box sx={themes.actionsBlockContainerStyle}>
                            <Box sx={themes.actionsContainerStyle}>
                                <ThemeProvider theme={themes.ModalActionButtonTheme(VideoChatStore.stepReady)}>
                                    <Button
                                        onClick={Save}
                                        disabled={!VideoChatStore.stepReady}
                                        disableRipple
                                    >
                                        <Typography style={themes.buttonTextStyle}>Продолжить</Typography>
                                    </Button>
                                </ThemeProvider>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </ThemeProvider>
    );
});

export default memo(ModalFinishCall);
