import React from "react";

import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";

import * as styles from "./styles";

const STabs = ({ content, alignment, setAlignment }) => {
    const handleChange = (e, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    return (
        <ToggleButtonGroup
            exclusive
            value={alignment}
            onChange={handleChange}
            sx={styles.toggleButtonGroupStyles}
        >
            {content.map((item) => (
                <ToggleButton
                    key={item.id}
                    disableRipple
                    sx={styles.toggleButtonSXStyles}
                    style={{
                        borderRadius: "30px",
                        border: "1px solid var(--text-dark-grey, #67677A)",
                    }}
                    value={item.value}
                >
                    {item.title}
                    {item.count !== undefined && item.count !== 0 ? <Typography variant={item.value !== alignment ? "standardFat" : "standardWhite"}>&nbsp;{`• ${item.count}`}</Typography> : ""}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};

export default STabs;
