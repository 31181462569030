import IconChats from "assets/ChatAssets/SVG/iconChats";
import { IconButton } from "@mui/material";
import * as React from "react";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import * as themes from "./ChatsButtonThemes";
import { Button } from "./ChatsButtonThemes";
const ChatsButton = ({ children, ...props }) => {
    const navigate = useNavigate();

    const [hovered, setHovered] = React.useState(false);

    const handleClickChats = () => {
        navigate("/chat");
    };

    return (
        <IconButton
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            disableRipple
            sx={themes.Button(hovered)}
            onClick={handleClickChats}
        >
            <IconChats hovered={hovered} />
        </IconButton>
    );
};

export default memo(ChatsButton);
