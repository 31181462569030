import SButton from "components/UI/SButton";
import store from "store/TeacherQuestionnaireStore";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import * as theme from "./QuestionnaireButtonsThemes";
import ArrowBackIcon from "assets/TeacherQuestionnaireAssets/ArrowBackIcon";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";

const stepDoner = (step) => {
    store.changeStep(`step${step}`, 1);
};

const QuestionnaireButtons = ({ currentStep, setCurrentStep, disabled, width }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const executeNextAction = async () => {
        if (currentStep === 9) {
            // eslint-disable-next-line no-undef
            await TeacherQuestionnaireStore.SendTutorQuestionaryData();
            await TeacherQuestionnaireStore.SendPersonalData();
            await TeacherQuestionnaireStore.SendProfilePhotoData();
            navigate("/questionnaire-on-check");
            stepDoner(currentStep);
            stepDoner(currentStep + 1);
        } else {
            setCurrentStep(currentStep + 1);
            stepDoner(currentStep);
            stepDoner(currentStep + 1);
        }
    };

    return (
        <Box sx={[theme.QuestionnaireButtonsWrapper, { justifyContent: currentStep !== 0 ? "space-between" : "flex-end" }]}>
            {currentStep !== 0 ? (
                <SButton
                    typographyHeight="20px"
                    variant="secondary"
                    padding="11px 24px 12px 24px"
                    onClick={() => setCurrentStep(currentStep - 1)}
                >
                    <ArrowBackIcon />
                </SButton>
            ) : null}
            <SButton
                disabled={disabled}
                variant="arrow"
                padding="11px 24px 12px 24px"
                onClick={executeNextAction}
                style={theme.NextButton(width)}
            >
                {t("main.continue")}
            </SButton>
        </Box>
    );
};

export default memo(QuestionnaireButtons);
