const DropDownFilterIcon = function ({ stroke = "#222" }) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 6.5L7.5 10.5L3 6.5"
                stroke={stroke}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default DropDownFilterIcon;
