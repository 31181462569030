import { Box } from "@mui/material";
import React from "react";
import OrdersModal from "components/UI/OrdersModal/OrdersModal";
import { observer } from "mobx-react-lite";
import OrderResponseRadioSelect from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_OrderResponseRadioSelect/OrderResponseRadioSelect";
import OrderResponseButton from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseButton/OrderResponseButton";
import OrderModalTitle from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_OrderModalTitle/OrderModalTitle";
import orderResponseStore from "store/OrderResponseStore";
import * as themes from "./OrdersResponseDismissModalStyled";
const list = [
    { label: "Высокая цена", value: "highPrice" },
    { label: "У специалиста мало отзывов", value: "lowReviews" },
    { label: "Не соответствует специализация", value: "wrongSpecialization" },
    { label: " Другое", value: "other" },
];

const OrdersResponseDismissModal = observer(() => {
    const handleClick = (e) => {
        orderResponseStore.setTutorStatus("rejected", orderResponseStore.id);
        orderResponseStore.setToggleModal();
        orderResponseStore.setIsRejected(true);
        orderResponseStore.setSortStatusResponseTutors();
        orderResponseStore.setIsDismissClicked(false);
        orderResponseStore.setIsComplainClicked(false);
        setTimeout(() => {
            orderResponseStore.setIsDisabled(true);
        }, 100);
    };

    const handleClearIsDisabledStore = () => {
        orderResponseStore.setIsDisabled(true);
        orderResponseStore.setIsDismissClicked(false);
        orderResponseStore.setIsComplainClicked(false);
    };

    return (
        <OrdersModal handleClick={handleClearIsDisabledStore}>
            <Box>
                <Box sx={themes.OrdersResponseDismissModalTitleWrapper}>
                    <OrderModalTitle>Отклик отклонён. Почему не подошёл специалист?</OrderModalTitle>
                </Box>
                <Box sx={themes.OrdersResponseDismissRadioWrapper}>
                    <OrderResponseRadioSelect list={list} />
                    <Box sx={themes.OrdersResponseDismissButtonWrapper}>
                        <OrderResponseButton
                            text="Готово"
                            variant="contained"
                            handleClick={handleClick}
                            disabled={orderResponseStore.isDisabled}
                        />
                    </Box>
                </Box>
            </Box>
        </OrdersModal>
    );
});

export default OrdersResponseDismissModal;
