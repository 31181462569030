//Есть ли у вас комментарии?
import React from "react";
import { observer } from "mobx-react-lite";
import CommentsPage from "components/SearchRequest/RightPanel/CommentsPage";
import SBButtonGroup from "components/UI/ButtonGroup";
import store from "store/RequestStore";
import RequestStore from "store/RequestStore";

import StepHiddenButtonWrapper from "components/OrdersBodyBlock/RenewOrder/StepHiddenButtonWrapper";
import { Box } from "@mui/material";

const Step25 = () => {
    const [orderCommentText, setOrderCommentText] = React.useState(RequestStore.storage[store.activeStepIndex].substeps[store.subStepIndex].value.commentText);

    const [attachedFiles, setAttachedFiles] = React.useState(RequestStore.storage[store.activeStepIndex].substeps[store.subStepIndex].value.attachedFiles);

    function addAttach() {
        try {
            const name = document.getElementById("fileInput");

            const file = document.querySelector("input[type=file]").files[0];

            let url = window.URL.createObjectURL(file);

            const tempDict = {
                fileName: name.files.item(0).name,
                fileSize: (name.files.item(0).size / 1000000).toFixed(2),
                fileType: name.files.item(0).type,
                fileUrl: url,
            };

            const result = [...attachedFiles, tempDict];

            setAttachedFiles(result);
        } catch (e) {
            //
        }
    }

    return (
        <Box>
            <CommentsPage
                attachedFiles={attachedFiles}
                setAttachedFiles={setAttachedFiles}
                addAttach={addAttach}
                orderCommentText={orderCommentText}
                setOrderCommentText={setOrderCommentText}
            />
            <StepHiddenButtonWrapper>
                <SBButtonGroup
                    handleNext={() => {
                        if ((orderCommentText !== "") | (attachedFiles.length !== 0)) {
                            store.setStep(17, "Есть");
                        } else {
                            store.setStep(17, "Нет");
                        }

                        store.setStepValue(17, {
                            commentText: orderCommentText,
                            attachedFiles: attachedFiles,
                        });
                        //store.setStep(7, stepData);
                        store.incrementStepIndex();
                    }}
                    handleBack={() => store.decrementStepIndex()}
                    activeStep={store.activeStepIndex}
                    steps={store.storage}
                />
            </StepHiddenButtonWrapper>
        </Box>
    );
};

export default observer(Step25);
