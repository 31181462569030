import React from "react";
import { Typography, Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as themes from "./ClassesListThemes";
import * as textThemes from "themes/TextThemes";
import { memo } from "react";
import TutorClassesStore from "store/TutorClassesStore";
import UpcomingClassCard from "./UpcomingClassCard/UpcomingClassCard";
import PendingClassCard from "./PendingClassCard/PendingClassCard";
import RescheduleRequestCard from "./RescheduleRequestCard/RescheduleRequestCard";
import CompletedClassCard from "./CompletedClassCard/CompletedClassCard";
import CancelledClassCard from "./CancelledClassCard/CancelledClassCard";
import { useTranslation } from "react-i18next";
import EmptyClassesBlock from "./EmptyClassesBlock/EmptyClassesBlock";

const ClassesListBlock = observer((children, ...props) => {
    const { t } = useTranslation();

    const getFormattedDate = (date) => {
        const dateObject = TutorClassesStore.formatDate(date);

        return `${dateObject.today ? t("main.today").charAt(0).toUpperCase() + t("main.today").slice(1) + ", " : ""}${dateObject.day} ${t(dateObject.month)}, ${t(dateObject.weekday)}`;
    };

    const getFormattedRescheduleDate = (date) => {
        const dateObject = TutorClassesStore.formatRescheduleDate(date);

        return `${dateObject.today ? t("main.today").charAt(0).toUpperCase() + t("main.today").slice(1) : dateObject.yesterday ? t("main.yesterday").charAt(0).toUpperCase() + t("main.yesterday").slice(1) : `${dateObject.day} ${t(dateObject.month)}`} ${t("main.at")} ${dateObject.time}`;
    };

    const renderClasses = (groupedClasses, ClassCardComponent, status) => (
        <Box sx={themes.DaysContainer}>
            {Object.keys(groupedClasses).map((date, index) => (
                <Box
                    key={index}
                    sx={themes.DayBlock}
                >
                    <Typography sx={status === "rescheduled" ? textThemes.Body2Black : textThemes.Button16Black}>
                        {status === "rescheduled" ? getFormattedRescheduleDate(date) : getFormattedDate(date)}
                    </Typography>
                    <Box sx={themes.CardsContainer}>
                        {groupedClasses[date].map((classItem) => (
                            <ClassCardComponent
                                classItem={classItem}
                                key={classItem.id}
                            />
                        ))}
                    </Box>
                </Box>
            ))}
        </Box>
    );

    const { activeClassesTab, classes, groupedUpcomingClasses, groupedPendingClasses, groupedRescheduledRequests, groupedConductedClasses, groupedCancelledClasses } = TutorClassesStore;

    const classGroups = {
        1: { filterStatus: "upcoming", groupedClasses: groupedUpcomingClasses, component: UpcomingClassCard },
        2: { filterStatus: "pending", groupedClasses: groupedPendingClasses, component: PendingClassCard },
        3: { filterStatus: "rescheduled", groupedClasses: groupedRescheduledRequests, component: RescheduleRequestCard },
        4: { filterStatus: "completed", groupedClasses: groupedConductedClasses, component: CompletedClassCard },
        5: { filterStatus: "cancelled", groupedClasses: groupedCancelledClasses, component: CancelledClassCard },
    };

    const currentTab = classGroups[activeClassesTab];

    if (!currentTab) return null;

    const filteredClasses = classes.filter((item) => (Array.isArray(currentTab.filterStatus) ? currentTab.filterStatus.includes(item.classStatus) : item.classStatus === currentTab.filterStatus));

    return filteredClasses.length > 0 ? renderClasses(currentTab.groupedClasses, currentTab.component, currentTab.filterStatus) : <EmptyClassesBlock status={currentTab.filterStatus} />;
});

export default memo(ClassesListBlock);
