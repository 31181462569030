import React from "react";
import * as themes from "./IncomeCalculatorThemes";
import * as textThemes from "themes/TextThemes";
import { Typography } from "@mui/material";
import LessonInputBlock from "./LessonInputBlock/LessonInputBlock";
import RateInputBlock from "./RateInpuBlock/RateInputBlock";
import PromoTutorStore from "store/PromoTutorStore";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import SButton from "components/UI/SButton";
import AuthorizationStore from "store/AuthorizationStore";
import { useNavigate } from "react-router-dom";
import { useMedias } from "../../../../API/mediasHook";

const IncomeCalculator = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const handleClickSignUp = () => {
        AuthorizationStore.setPreviousLocationPath(location.pathname);
        AuthorizationStore.setCurrentAuthorizationStep("SignUpTutor");
        AuthorizationStore.setSignUpPhone(null);
        AuthorizationStore.setSignUpPhoneError(false);
        AuthorizationStore.setSignUpName("");
        AuthorizationStore.setSignUpEmail("");
        AuthorizationStore.setSignUpEmailError(false);
        AuthorizationStore.setTutorOfferAgree(false);
        AuthorizationStore.setTutorRulesAgree(false);
        navigate("/authorization");
    };

    const handleClickRules = () => {
        navigate("/buddyrules");
    };

    const medias = useMedias();

    return (
        <Box sx={themes.IncomeCalculatorBlock(medias)}>
            <Box sx={themes.TitleBlock(medias)}>
                <Typography sx={textThemes.HeadlineH2Black(medias)}>{t("promoPages.promoTutor.howMuchYouWillEarn")}</Typography>
                <Typography sx={textThemes.Body1Black}>{t("promoPages.promoTutor.onlineCalculator")}</Typography>
            </Box>
            <Box sx={themes.ContentBlock}>
                <Box sx={themes.inputBlock(medias)}>
                    <Box sx={themes.lessonInputBlock(medias)}>
                        <RateInputBlock />
                    </Box>
                    <Box sx={themes.lessonInputBlock(medias)}>
                        <LessonInputBlock />
                    </Box>
                </Box>
                <Box sx={themes.counterBlock}>
                    <Typography sx={textThemes.HeadlineH2Black}>
                        {!PromoTutorStore.optionRate?.rate || !PromoTutorStore.optionLesson?.lesson ? 0 : (PromoTutorStore.optionRate.rate * PromoTutorStore.optionLesson.lesson * 4).toLocaleString()}
                    </Typography>
                    <Typography sx={textThemes.Body1Black}>{t("promoPages.promoTutor.rublesPerMonth")}</Typography>
                </Box>
                <Box sx={themes.ButtonBlock}>
                    <SButton
                        onClick={handleClickSignUp}
                        variant="unshadowed"
                        style={themes.SignUpButton(medias)}
                    >
                        {t("promoPages.promoTutor.signUp")}
                    </SButton>
                    <Box sx={textThemes.Body2Grey}>{t("promoPages.promoTutor.offerIsNotPublic")}</Box>
                </Box>
                <Typography sx={textThemes.Body1Black}>
                    {t("promoPages.promoTutor.beforeStartingWorkPart1")}
                    <Typography
                        onClick={handleClickRules}
                        component="span"
                        sx={{ ...textThemes.Body1Purple, ...themes.LinkButton }}
                    >
                        {t("promoPages.promoTutor.cooperationRules")}
                    </Typography>
                    {t("promoPages.promoTutor.beforeStartingWorkPart2")}
                </Typography>
            </Box>
        </Box>
    );
});

export default memo(IncomeCalculator);
