import { styleProcessing } from "API/plugins/Utilities";

export const ServiceItem = (theme) => {
    let commonStyle = {
        paddingBottom: "12px",
        borderBottom: "1px dashed #DADADF",
        display: "flex",
        gap: "20px",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            alignItems: "left",
            paddingBottom: "12px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const NumberBlock = {
    width: "20px",
};

export const Avatar = {
    width: "36px",
    height: "36px",
};

export const StudentBlock = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
};

export const AvatarSubjWrapper = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "20px",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "2px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const NumberAndStudentBlock = {
    display: "flex",
    alignItems: "flex-start",
    gap: "10px",
};

export const StudentNameBlock = (theme) => {
    let commonStyle = {
        width: "160px",
        display: "flex",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "fit-content",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const SubjectBlock = (theme) => {
    let commonStyle = {
        width: "350px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "fit-content",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const FinanceWrapper = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            justifyContent: "space-between",
            gap: "0px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const PriceBlock = (theme) => {
    let commonStyle = {
        width: "107px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "fit-content",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const CommissionBlock = (theme) => {
    let commonStyle = {
        width: "115px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "fit-content",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TotalPriceBlock = (theme) => {
    let commonStyle = {
        width: "84px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "fit-content",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
