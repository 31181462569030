import InviteBlock from "./InviteBlock/InviteBlock";
import StudentPoints from "./StudentPoints/StudentPoints";
import TutorPoints from "./TutorPoints/TutorPoints";
import WhyUs from "./WhyUs/WhyUs";
import TopQuestions from "./TopQuestions/TopQuestions";
import Box from "@mui/material/Box";
import React from "react";
import * as themes from "./ReferralPromoBodyBlockThemes";
import { memo } from "react";

const ReferralPromoBodyBlock = ({ children, ...props }) => {
    return (
        <Box sx={themes.ReferralPromoBodyBlock}>
            <InviteBlock />
            <StudentPoints />
            <TutorPoints />
            <WhyUs />
            <TopQuestions />
        </Box>
    );
};

export default memo(ReferralPromoBodyBlock);
