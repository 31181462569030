import { Box, MenuItem, MenuList, Tooltip, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import * as styles from "./ClassMoreMenuContentThemes";
import * as textStyles from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import ModalCancelClass from "./Modals/ModalCancelClass/ModalCancelClass";
import ModalCalendarReschedule from "./Modals/ModalCalendarReschedule/ModalCalendarReschedule";
import ModalRescheduleRequest from "./Modals/ModalRescheduleRequest/ModalRescheduleRequest";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import TutorClassesStore from "store/TutorClassesStore";

const ClassMoreMenuContent = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    //Cancel class
    const [dialogCancelClassOpen, SetDialogCancelClassOpen] = React.useState(false);

    const showCancelClassDialog = () => {
        SetDialogCancelClassOpen(true);
    };

    const discardCancelClassDialog = () => {
        props.setOpen(false);
        SetDialogCancelClassOpen(false);
    };

    const handleRescheduleClass = () => {
        SetDialogCancelClassOpen(false);
        showRescheduleCalendarDialog();
    };

    const saveCancelClassDialog = () => {
        props.setOpenSnackBarCancelled(true);
        TutorClassesStore.setCancelReason(
            TutorClassesStore.classes.findIndex((object) => object.id === props.lesson.id),
            TutorClassesStore.temporaryCancelReason,
        );
        TutorClassesStore.setCancelled(TutorClassesStore.classes.findIndex((object) => object.id === props.lesson.id));
        TeacherQuestionnaireStore.setCancelClassAttempts(TeacherQuestionnaireStore.teacher.cancelClassAttempts - 1);
        TutorClassesStore.ClassesUpdate();
    };

    //Reschedule calendar
    const [dialogRescheduleCalendarOpen, SetDialogRescheduleCalendarOpen] = React.useState(false);

    const showRescheduleCalendarDialog = () => {
        if (props.lesson.rescheduleAttempt !== 0 && props.lesson.rescheduleTimeStart === null) {
            SetDialogRescheduleCalendarOpen(true);
        }
    };

    const discardRescheduleCalendarDialog = () => {
        props.setOpen(false);
        SetDialogRescheduleCalendarOpen(false);
    };

    const saveRescheduleCalendarDialog = () => {
        SetDialogRescheduleCalendarOpen(false);
        showRescheduleRequestDialog();
    };

    //Reschedule request
    const [dialogRescheduleRequestOpen, SetDialogRescheduleRequestOpen] = React.useState(false);

    const showRescheduleRequestDialog = () => {
        SetDialogRescheduleRequestOpen(true);
    };

    const discardRescheduleRequestDialog = () => {
        props.setOpen(false);
        SetDialogRescheduleRequestOpen(false);
    };

    const saveRescheduleRequestDialog = () => {
        props.setOpen(false);
        SetDialogRescheduleRequestOpen(false);
        TutorClassesStore.setRescheduleReason(
            TutorClassesStore.classes.findIndex((object) => object.id === props.lesson.id),
            TutorClassesStore.temporaryRescheduleReason,
        );
        TutorClassesStore.setRescheduleTimeStart(
            TutorClassesStore.classes.findIndex((object) => object.id === props.lesson.id),
            TutorClassesStore.temporaryRescheduleTimeStart,
        );
        TutorClassesStore.setRescheduleTimeEnd(
            TutorClassesStore.classes.findIndex((object) => object.id === props.lesson.id),
            TutorClassesStore.temporaryRescheduleTimeEnd,
        );
        TutorClassesStore.setRescheduleAttempt(
            TutorClassesStore.classes.findIndex((object) => object.id === props.lesson.id),
            props.lesson.rescheduleAttempt - 1,
        );
        props.setOpenSnackBar(true);
        TutorClassesStore.ClassesUpdate();
    };

    const handleClickRevoke = () => {
        props.setOpen(false);
        TutorClassesStore.setRescheduleReason(
            TutorClassesStore.classes.findIndex((object) => object.id === props.lesson.id),
            null,
        );
        TutorClassesStore.setRescheduleTimeStart(
            TutorClassesStore.classes.findIndex((object) => object.id === props.lesson.id),
            null,
        );
        TutorClassesStore.setRescheduleTimeEnd(
            TutorClassesStore.classes.findIndex((object) => object.id === props.lesson.id),
            null,
        );
        props.setOpenSnackBarRevoke(true);
        TutorClassesStore.ClassesUpdate();
    };

    return (
        <Paper sx={styles.Paper}>
            <ModalCancelClass
                open={dialogCancelClassOpen}
                handleDiscard={discardCancelClassDialog}
                handleSave={saveCancelClassDialog}
                handleReschedule={handleRescheduleClass}
                lesson={props.lesson}
            />

            <ModalCalendarReschedule
                open={dialogRescheduleCalendarOpen}
                handleDiscard={discardRescheduleCalendarDialog}
                handleSave={saveRescheduleCalendarDialog}
                lesson={props.lesson}
            />

            <ModalRescheduleRequest
                open={dialogRescheduleRequestOpen}
                handleDiscard={discardRescheduleRequestDialog}
                handleSave={saveRescheduleRequestDialog}
                lesson={props.lesson}
            />

            <MenuList sx={styles.MenuList}>
                {props.lesson.rescheduleTimeStart && (
                    <MenuItem
                        disableRipple
                        sx={styles.MenuItem}
                        onClick={handleClickRevoke}
                    >
                        <Typography sx={textStyles.Body2Black}>{t("teacherClasses.revokeRequest")}</Typography>
                    </MenuItem>
                )}
                <Tooltip
                    title={props.lesson.rescheduleAttempt === 0 ? t("main.myClasses.noRescheduleAttempts") : ""}
                    PopperProps={styles.TooltipPopperProps}
                    placement="top"
                    arrow
                >
                    <MenuItem
                        disableRipple
                        sx={styles.MenuItem}
                        onClick={showRescheduleCalendarDialog}
                    >
                        <Typography sx={props.lesson.rescheduleAttempt === 0 || props.lesson.rescheduleTimeStart !== null ? textStyles.Body2Grey : textStyles.Body2Black}>
                            {t("main.rescheduleClass")}
                        </Typography>
                        {props.lesson.rescheduleTimeStart !== null ? (
                            <Typography sx={textStyles.Body2Grey}>{t("main.myClasses.alreadyRescheduling")}</Typography>
                        ) : props.lesson.rescheduleAttempt === 0 ? (
                            <Typography sx={textStyles.Body2Grey}>{t("main.myClasses.threeAttemptsUsed")}</Typography>
                        ) : (
                            <Typography
                                sx={textStyles.Body2Grey}
                                component={"div"}
                            >
                                {t("main.myClasses.attemptsLeftPart1")}
                                <span style={textStyles.Body2Green}>{`${props.lesson.rescheduleAttempt} `}</span>
                                {props.lesson.rescheduleAttempt === 1
                                    ? t("main.myClasses.attemptsLeftPart2_one")
                                    : props.lesson.rescheduleAttempt > 1 && props.lesson.rescheduleAttempt < 5
                                      ? t("main.myClasses.attemptsLeftPart2_few")
                                      : t("main.myClasses.attemptsLeftPart2_many")}
                            </Typography>
                        )}
                    </MenuItem>
                </Tooltip>
                <Box sx={styles.DividerContainer}>
                    <Box sx={styles.Divider}></Box>
                </Box>
                <Tooltip
                    title={TeacherQuestionnaireStore.teacher.cancelClassAttempts === 0 ? t("main.myClasses.noCancelThisMonth") : ""}
                    PopperProps={styles.TooltipPopperProps}
                    placement="top"
                    arrow
                >
                    <MenuItem
                        disableRipple
                        sx={styles.MenuItem}
                        onClick={() => {
                            if (TeacherQuestionnaireStore.teacher.cancelClassAttempts > 0) showCancelClassDialog();
                        }}
                    >
                        <Typography sx={TeacherQuestionnaireStore.teacher.cancelClassAttempts === 0 ? textStyles.Body2Grey : textStyles.Body2Red}>{t("main.myClasses.cancelClass")}</Typography>
                        <Typography sx={textStyles.Body2Grey}>
                            {TeacherQuestionnaireStore.teacher.cancelClassAttempts === 0 ? t("main.myClasses.limitReachedThisMonth") : t("main.myClasses.threeClassesForCancel")}
                        </Typography>
                    </MenuItem>
                </Tooltip>
            </MenuList>
        </Paper>
    );
});

export default memo(ClassMoreMenuContent);
