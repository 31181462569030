import { Button, styled } from "@mui/material";

export const StyledOrderButton = styled(Button)({
    marginLeft: "auto",
    fontFamily: '"Inter", sans-serif',

    borderRadius: "42px",
    backgroundColor: "#6212FF",
    textTransform: "none",
    padding: "11px 30px",
    transition: "opacity 0.5s",
    boxShadow: "none",

    "&:hover": {
        backgroundColor: "#8141FF",
        color: "#fff",
        transition: "opacity 0.5s",
        boxShadow: "none",
    },
    "&:active": {
        backgroundColor: "#5513D7",
        color: "#fff",
        transition: "opacity 0.5s",
        boxShadow: "none",
        border: "none",
    },
});
