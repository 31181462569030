import { Typography } from "@mui/material";
import React from "react";
import * as textThemes from "themes/TextThemes";
import { memo } from "react";

const PriceFormater = ({ children, ...props }) => {
    const formattedNumber = props.price.toLocaleString();

    return (
        <Typography
            variant="span"
            sx={textThemes.Button16BoldBlack}
        >
            {formattedNumber} ₽
        </Typography>
    );
};

export default memo(PriceFormater);
