import * as React from "react";
import * as themes from "./VideoContentThemesDefault";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import ReactPlayer from "react-player/lazy";

const VideoContent = observer(({ children, ...props }) => {
    return (
        <ReactPlayer
            width="100%"
            height="100%"
            loop
            playing
            muted
            controls
            volume={1}
            style={themes.videoContentStyle}
            url={props.value.src}
        />
    );
});

export default memo(VideoContent);
