import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { memo } from "react";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as textStyles from "themes/TextThemes";
import SettingsStore from "store/SettingsStore";
import * as styles from "./PhoneTextFieldThemes";
import { observer } from "mobx-react-lite";

const PhoneInputComponent = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleOnChange = (value, country) => {
        SettingsStore.setTemporaryPhoneNumber(value);
    };

    return (
        <Box style={styles.Box(isFocused, SettingsStore.temporaryPhoneNumberHelper)}>
            <Typography sx={textStyles.Body2Black}>{t("main.phoneNumber")}</Typography>
            <PhoneInput
                country={"ru"}
                value={SettingsStore.temporaryPhoneNumber === null ? "" : SettingsStore.temporaryPhoneNumber}
                onChange={handleOnChange}
                inputStyle={styles.Input}
                dropdownStyle={styles.Dropdown}
                buttonStyle={styles.Button}
                placeholder="000 000-00-00"
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
        </Box>
    );
});

export default memo(PhoneInputComponent);
