import React, { memo } from "react";
import { Box, Typography } from "@mui/material";
import EmptyBalanceIcon from "assets/CommonAssets/SVG/EmptyBalanceIcon";
import CreateNewOrderIcon from "assets/CommonAssets/SVG/CreateNewOrderIcon";
import { useNavigate } from "react-router-dom";
import * as styles from "./OperationsTableEmptyThemes";
import * as textStyles from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import SButton from "components/UI/SButton";
import { useMedias } from "API/mediasHook";

const OperationsTableEmpty = () => {
    const medias = useMedias();

    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    return (
        <Box sx={styles.OperationsTableEmpty}>
            <Box sx={styles.HistoryEmpty}>
                <EmptyBalanceIcon />
                <Box sx={styles.HistoryEmptyContent}>
                    <Typography sx={textStyles.HeadlineH5Black}>{t("main.balance.historyEmpty")}</Typography>
                    <Typography sx={[medias.sm ? textStyles.Body1Black : textStyles.Body2Black, styles.HistoryEmptyContentSubtitle]}>{t("main.balance.hereOperations")}</Typography>
                </Box>
            </Box>
            <Box sx={styles.CreateNewOrder}>
                <Box sx={{ display: "flex", gap: "20px" }}>
                    <Box sx={{ width: "36px", height: "36px" }}>
                        <CreateNewOrderIcon />
                    </Box>
                    <Box sx={styles.CreateNewOrderTopContainer}>
                        <Typography sx={textStyles.Body1Black}>{t("main.balance.needHelp")}</Typography>
                    </Box>
                </Box>
                <Box sx={styles.CreateNewOrderBottomContainer}>
                    <SButton
                        variant="arrow"
                        padding="7px 16px 8px 16px"
                        onClick={() => navigate("/bid")}
                        style={styles.ButtonSize(medias)}
                    >
                        {t("main.createNewOrder")}
                    </SButton>
                </Box>
            </Box>
        </Box>
    );
};

export default memo(OperationsTableEmpty);
