const SecuredIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="18"
            viewBox="0 0 16 18"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.9847 6.52462L13.9849 6.53659C14.0534 10.1249 14.1471 15.0393 8.16355 17.1133C8.05751 17.15 7.94219 17.15 7.83615 17.1133C1.85305 15.0394 1.94689 10.1251 2.01541 6.53687C2.02281 6.15122 2.02974 5.78683 2.02974 5.43437C2.02974 5.3018 2.0824 5.17465 2.17615 5.0809C2.2699 4.98715 2.39705 4.93449 2.52962 4.93449C4.66734 4.93449 6.29477 4.32009 7.6514 3.00092C7.74469 2.91016 7.86972 2.85938 7.99988 2.85938C8.13005 2.85938 8.25507 2.91016 8.34837 3.00092C9.70513 4.32009 11.3328 4.93449 13.4707 4.93449C13.6033 4.93449 13.7304 4.98715 13.8241 5.0809C13.9179 5.17465 13.9706 5.3018 13.9706 5.43437C13.9706 5.78311 13.9774 6.14347 13.9847 6.52462ZM7.37729 12.0742C7.65918 12.0742 7.87232 11.8591 7.87232 11.8591L10.6382 9.09324C10.7032 9.02826 10.7548 8.95111 10.79 8.8662C10.8252 8.78128 10.8433 8.69027 10.8433 8.59835C10.8433 8.50643 10.8252 8.41541 10.7901 8.33049C10.7549 8.24558 10.7033 8.16843 10.6383 8.10345C10.5071 7.9722 10.3291 7.89846 10.1435 7.89844C9.95789 7.89842 9.77985 7.97215 9.64859 8.10337L7.37729 10.3745L6.48916 9.48638L6.48673 9.48402C6.35473 9.35642 6.17788 9.28579 5.9943 9.28735C5.81071 9.28891 5.63509 9.36254 5.50528 9.49236C5.37547 9.62219 5.30186 9.79781 5.30032 9.9814C5.29877 10.165 5.3694 10.3418 5.49701 10.4738L6.88225 11.8591C6.88225 11.8591 7.0954 12.0742 7.37729 12.0742Z"
                fill="#BBBBC8"
            />
        </svg>
    );
};

export default SecuredIcon;
