import { Avatar, AvatarGroup, Box, Divider, Tooltip, Typography } from "@mui/material";
import OrderDate from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/OrderDate/OrderDate";
import OrderTarget from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/OrderTarget/OrderTarget";
import filterStore from "store/FilterStore";
import * as themes from "./СancelledOrderCardThemes";
import { useMedias } from "API/mediasHook";
import * as textThemes from "themes/TextThemes";
import * as React from "react";
import PhotoHidden from "assets/OrderAssets/PNG/photoHidden.png";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import SButton from "components/UI/SButton";
import Information from "assets/PAAssets/SVG/information";
import { WhiteLayer, WhiteLayerContainer } from "./СancelledOrderCardThemes";
const CanceledOrder = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const medias = useMedias();

    const toggleOrderDrawer = () => {
        filterStore.setToggleDrawer();
        filterStore.setSubmitButtonFilter(true);
        filterStore.setChangeDrawerContent("canceled");
    };

    const getDaysDifference = (inputDate) => {
        const currentDate = new Date();

        const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

        const inputDateOnly = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());

        const timeDifference = inputDateOnly - currentDateOnly;

        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

        return daysDifference;
    };

    const getDayWord = (number) => {
        if (number === 1) {
            return "main.dayOne";
        } else if (number >= 2 && number <= 4) {
            return "main.dayFew";
        } else {
            return "main.dayMany";
        }
    };

    return (
        <Box sx={themes.StyledOrderCard(medias)}>
            <Box sx={themes.TopBlock}>
                <Box sx={themes.TopContentWrapper}>
                    <Typography sx={{ ...textThemes.HeadlineH5Black, ...themes.CardTitle }}>
                        {props.order.baseOrderInfo.orderType === "tutoring"
                            ? props.order.baseOrderInfo.subject
                                ? props.order.baseOrderInfo.subject
                                : props.order.baseOrderInfo.requestTopic
                            : props.order.baseOrderInfo.requestTopic}
                    </Typography>
                    <OrderDate date={props.order.baseOrderInfo.createdAt} />
                </Box>

                <OrderTarget
                    order={props.order}
                    type="Body1"
                />
            </Box>
            <Box>
                {!props.order.baseOrderInfo.isPersonal ? (
                    props.order.baseOrderInfo.responses.length > 1 ? (
                        <Box sx={themes.WhiteLayerContainer}>
                            <AvatarGroup
                                max={6}
                                sx={themes.AvatarGroup}
                            >
                                {props.order.baseOrderInfo.responses.map((response) => (
                                    <Avatar
                                        sx={themes.Avatar}
                                        src={response.tutor.anonymous ? PhotoHidden : response.tutor.photo.link}
                                        key={response.responseUuid}
                                    />
                                ))}
                            </AvatarGroup>
                            <Box sx={themes.WhiteLayer} />
                        </Box>
                    ) : (
                        <Box sx={themes.WhiteLayerContainer}>
                            <Box sx={themes.SingleAvatar}>
                                {props.order.baseOrderInfo.responses[0].tutor.anonymous ? (
                                    <Box sx={themes.PhotoHidden}>
                                        <Typography sx={themes.PhotoHiddenText}>{t("orders.studentOrders.photoHidden")}</Typography>
                                    </Box>
                                ) : (
                                    <Avatar
                                        sx={themes.Avatar}
                                        src={props.order.baseOrderInfo.responses[0].tutor.photo.link}
                                    />
                                )}
                                <Typography sx={textThemes.Body2Black}>
                                    {props.order.baseOrderInfo.responses[0].tutor.anonymous
                                        ? t("orders.studentOrders.fioHidden")
                                        : `${props.order.baseOrderInfo.responses[0].tutor.name} ${props.order.baseOrderInfo.responses[0].tutor.surname[0]}.`}
                                </Typography>
                            </Box>
                            <Box sx={themes.WhiteLayer} />
                        </Box>
                    )
                ) : props.order.baseOrderInfo.offers.length > 1 ? (
                    <Box sx={themes.WhiteLayerContainer}>
                        <Box sx={themes.WhiteLayerContainer}>
                            <AvatarGroup
                                max={6}
                                sx={themes.AvatarGroup}
                            >
                                {props.order.baseOrderInfo.offers.map((offer) => (
                                    <Avatar
                                        sx={themes.Avatar}
                                        src={offer.tutor.anonymous ? PhotoHidden : offer.tutor.photo.link}
                                        key={offer.responseUuid}
                                    />
                                ))}
                            </AvatarGroup>
                            <Box sx={themes.WhiteLayer} />
                        </Box>
                        <Box sx={themes.WhiteLayer} />
                    </Box>
                ) : (
                    <Box sx={themes.SingleAvatar}>
                        {props.order.baseOrderInfo.offers[0].tutor.anonymous ? (
                            <Box sx={themes.PhotoHidden}>
                                <Typography sx={themes.PhotoHiddenText}>{t("orders.studentOrders.photoHidden")}</Typography>
                            </Box>
                        ) : (
                            <Avatar
                                sx={themes.Avatar}
                                src={props.order.baseOrderInfo.offers[0].tutor.photo.link}
                            />
                        )}
                        <Typography sx={textThemes.Body2Black}>
                            {props.order.baseOrderInfo.offers[0].tutor.anonymous
                                ? t("orders.studentOrders.fioHidden")
                                : `${props.order.baseOrderInfo.offers[0].tutor.name} ${props.order.baseOrderInfo.offers[0].tutor.surname[0]}.`}
                        </Typography>
                    </Box>
                )}
            </Box>
            <Box sx={themes.BottomBlock}>
                <Divider sx={themes.Divider} />
                <Box sx={themes.BottomContentWrapper}>
                    <Box sx={themes.OrderCancelledBlock}>
                        <Typography sx={textThemes.Body2Black}>{t("orders.studentOrders.orderCancelled")}</Typography>
                        <Tooltip
                            placement="top"
                            PopperProps={themes.TooltipPopperProps}
                            title={`${t("orders.studentOrders.orderWillBeRemoved")} ${getDaysDifference(props.order.baseOrderInfo.removalTime)} ${t(getDayWord(getDaysDifference(props.order.baseOrderInfo.removalTime)))}`}
                        >
                            <button style={themes.TooltipButton}>
                                <Information />
                            </button>
                        </Tooltip>
                    </Box>
                    <SButton
                        variant="text"
                        onClick={toggleOrderDrawer}
                    >
                        {t("orders.studentOrders.resumeOrder")}
                    </SButton>
                </Box>
            </Box>
        </Box>
    );
};

export default memo(CanceledOrder);
