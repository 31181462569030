import React from "react";
import * as themes from "./InProgressStatusThemes";
import * as textThemes from "themes/TextThemes";
import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
const InProgressStatus = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const stylesMap = {
        accepted_for_work: {
            statusStyle: themes.hiredStatus,
            textStyle: themes.hiredStatusText,
            statusText: "orders.studentOrders.acceptedForWork",
        },
        in_work: {
            statusStyle: themes.inWorkStatus,
            textStyle: themes.inWorkStatusText,
            statusText: "orders.studentOrders.inProgress",
        },
        waiting_for_check: {
            statusStyle: themes.awaitingVerificationStatus,
            textStyle: themes.awaitingVerificationStatusText,
            statusText: "orders.studentOrders.waitingForCheck",
        },
        adjusting: {
            statusStyle: themes.inWorkStatus,
            textStyle: themes.inWorkStatusText,
            statusText: "orders.studentOrders.correcting",
        },
    };

    const { statusStyle, textStyle, statusText } = stylesMap[props.order.inWorkStatus];

    return (
        <Box sx={{ ...themes.StatusContainer, ...statusStyle }}>
            <Typography sx={textStyle}>&bull;</Typography>
            <Typography sx={textThemes.Body2Black}>{t(statusText)}</Typography>
        </Box>
    );
};

export default memo(InProgressStatus);
