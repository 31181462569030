export const SignUpButton = (medias) => {
    return {
        padding: medias.sm ? "20px 26px" : "16px 24px 17px 24px",
        width: medias.sm ? "289px" : "327px",
        //fontSize: "16px !important",
    };
};

export const TextAndButtonBlock = (medias) => {
    return {
        maxWidth: "450px",
        display: "flex",
        flexDirection: "column",
        gap: medias.sm ? "60px" : "40px",
    };
};

export const TextBlock = (medias) => {
    return {
        display: "flex",
        flexDirection: "column",
        gap: medias.sm ? "30px" : "20px",
    };
};

export const PhotosBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "33px",
};

export const UpperPhotosBlock = (medias) => {
    return {
        display: "flex",
        gap: medias.sm ? "102px" : "48px",
    };
};

export const BottomPhotosBlock = (medias) => {
    return {
        display: medias.sm ? "flex" : "none",
        gap: "113px",
        ml: "161px",
    };
};

export const UpperLeftPhotosBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "59px",
};

export const ManFirstContainer = (medias) => {
    return {
        width: medias.sm ? 109 : 74,
        height: medias.sm ? 110 : 74,
        ml: "62px",
    };
};

export const Man2 = (medias) => {
    return {
        width: medias.sm ? 103 : 74,
        height: medias.sm ? 104 : 74,
        mt: medias.sm ? "29px" : "0px",
    };
};

export const ManSecondContainer = (medias) => {
    return {
        display: medias.sm ? "flex" : "none",
        width: 103,
        height: 104,
    };
};

export const WomanFirstContainer = (medias) => {
    return {
        width: medias.sm ? 289 : 124,
        height: medias.sm ? 239 : 124,
        mt: medias.sm ? "29px" : "0px",
    };
};

export const Woman1 = (medias) => {
    return {
        width: medias.sm ? 289 : 124,
        height: medias.sm ? 239 : 124,
        mt: medias.sm ? "29px" : "0px",
    };
};

export const WomanSecondContainer = {
    width: 150,
    height: 150,
};

export const WomanThirdContainer = {
    width: 106,
    height: 106,
};

export const StartBlock = (medias) => {
    return {
        display: "flex",
        width: medias.sm ? "1216px" : "328px",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: medias.sm ? "unset" : "column-reverse",
        gap: medias.sm ? "0px" : "30px",
    };
};
