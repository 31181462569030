export const MessageBox = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "safe end",
    height: "100%",
    width: "100%",
    overflowX: "scroll",
    overflowY: "auto",
    marginBottom: "-2vh",
    "&::-webkit-scrollbar": {
        width: 0,
    },
    "&::-webkit-scrollbar-thumb": {
        borderRadius: 0,
    },
    backgroundColor: "white",
};

export const PaperStyles = {
    display: "inline-block",
    maxWidth: "20vw",
    padding: "1.4vh 1.05vw 0.9vh 1.05vw",
    borderRadius: "2.1vh",
    border: "1px solid #EBEAFF",
};

export const InfoStyles = { float: "right", paddingTop: "1vh", paddingLeft: "0.5vw" };

export const MessageGroup = {
    display: "flex",
    justifyContent: "center",
};
