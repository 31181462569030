export const RightPanelTitleStyles = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "140%",
    display: "flex",
    alignItems: "center",
    color: "#222222",
    marginBottom: "30px",
    whiteSpace: "nowrap",
};
