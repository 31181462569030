import React, { memo } from "react";
import Box from "@mui/material/Box";
import * as themes from "./CertificatesContentThemes";
import { ImageList, ImageListItem, Tooltip, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { SafetyCheck, VerifiedUser } from "@mui/icons-material";
import { useMedias } from "../../../../API/mediasHook";
import { certificatesItemStyle } from "./CertificatesContentThemes";

const CertificatesContent = ({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={themes.certificatesMainContainerStyle}>
            <Box sx={themes.certificatesContentContainerStyle}>
                {props.value.CertificatesInfo.map((value) => (
                    <Box
                        key={value.ID}
                        sx={themes.certificatesItemStyle(medias)}
                    >
                        <ThemeProvider theme={themes.VerificationTheme(value.IsVerified)}>
                            <Tooltip
                                title={<Typography sx={themes.tooltipVerificationStyle(value.IsVerified)}>{value.IsVerified ? "Подтверждено документом" : "Документ ожидает проверки"}</Typography>}
                                arrow={true}
                                placement="top-start"
                            >
                                {value.IsVerified ? <VerifiedUser sx={themes.verifiedStyle}></VerifiedUser> : <SafetyCheck sx={themes.safetyCheckStyle}></SafetyCheck>}
                            </Tooltip>
                        </ThemeProvider>
                        <Typography sx={themes.certificatesInfoStyle}>
                            {value.CertificatesInfo}, <span style={themes.certificatesYearStyle}>{value.CertificatesYear} г.</span>
                        </Typography>
                    </Box>
                ))}
            </Box>
            {!props.value.IsAnonymous ? (
                <ThemeProvider theme={themes.imageListTheme(medias)}>
                    <ImageList
                        cols={props.value.CertificatesInfo.length}
                        rowHeight={140}
                    >
                        {props.value.CertificatesInfo.map((item) => (
                            <ImageListItem key={item.ID}>
                                <img
                                    src={`${item.Doc.Img}?w=120&h=140`}
                                    srcSet={`${item.Doc.Img}?w=120&h=140`}
                                    onClick={() => window.open(item.Doc.Img)}
                                    alt={item.Doc.Title}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </ThemeProvider>
            ) : null}
        </Box>
    );
};

export default memo(CertificatesContent);
