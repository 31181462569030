import { createTheme } from "@mui/material/styles";

export const ChatBodyBlock = {
    display: "flex",
    width: "1216px",
    gap: "20px",
    padding: "20px 0",
};

export const ChatListBox = {
    width: "392px",
};

export const loadingContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    justifyContent: "center",
    alignItems: "center",
    width: "-webkit-fill-available",
    height: "80vh",
};

export const CircularProgressTheme = createTheme({
    components: {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: "#6212FF",
                },
            },
        },
    },
});
