import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./RatingThemes";
import * as textThemes from "themes/TextThemes";
import { Typography, Box, Tooltip, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FeedbackStore from "store/FeedbackStore";
import ReviewStarIcon from "assets/CommonAssets/SVG/ReviewStarIcon";
import Information from "assets/PAAssets/SVG/information";

const Rating = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const handleBlockClick = () => {
        navigate("/tutor/feedbacks");
    };

    const getReviewTitle = (number) => {
        if (number === 0) {
            return "teacherAccount.rating.noReview";
        } else if (number === 1) {
            return "teacherAccount.rating.oneReview";
        } else if (number > 1 && number < 5) {
            return "teacherAccount.rating.fewReviews";
        } else if (number > 5) {
            return "teacherAccount.rating.manyReviews";
        }
    };

    const getNewTitle = (number) => {
        if (number === 1) {
            return "teacherAccount.rating.oneNewReview";
        } else if (number > 1) {
            return "teacherAccount.rating.manyNewReviews";
        }
    };

    const getConductedClassTitle = (number) => {
        if (number === 1) {
            return "teacherAccount.rating.oneConductedClass";
        } else if (number > 1) {
            return "teacherAccount.rating.manyConductedClass";
        }
    };

    return (
        <Box
            sx={themes.WhiteContainer}
            onClick={handleBlockClick}
        >
            <Box sx={themes.TopBlock}>
                <Box sx={themes.LeftBlock}>
                    <Typography sx={textThemes.Body2DarkGrey}>{t("teacherAccount.rating.rating")}</Typography>
                    <Typography sx={textThemes.Button16BoldPurple}>{`${FeedbackStore.reviewsNow} ${t(getReviewTitle(FeedbackStore.reviewsNow))}`}</Typography>
                </Box>
                <Box sx={themes.RatingBlock}>
                    <Box>
                        <ReviewStarIcon />
                    </Box>
                    <Box sx={themes.TextWithTooltip}>
                        <Typography sx={textThemes.HeadlineH41Black}>{FeedbackStore.rating === 0 || FeedbackStore.rating === null ? `-` : `${FeedbackStore.rating}`}</Typography>
                        <Tooltip
                            PopperProps={themes.TooltipPopperProps}
                            title={`${t("teacherAccount.rating.basedOn")}${FeedbackStore.classesRated}${t(getConductedClassTitle(FeedbackStore.classesRated))}`}
                        >
                            <IconButton
                                disableRipple
                                style={themes.TooltipButton}
                            >
                                <Information />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
            {FeedbackStore.newReviewsThisWeek !== null && FeedbackStore.newReviewsThisWeek !== 0 ? (
                <Typography sx={textThemes.Caption1Purple}>
                    {`+${FeedbackStore.newReviewsThisWeek}${t(getNewTitle(FeedbackStore.newReviewsThisWeek))}`}
                    <span style={textThemes.Caption1Black}>{`${t(getReviewTitle(FeedbackStore.newReviewsThisWeek))}${t("teacherAccount.rating.thisWeek")}`}</span>
                </Typography>
            ) : null}
        </Box>
    );
});

export default memo(Rating);
