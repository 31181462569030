import { styleProcessing } from "API/plugins/Utilities";

export const StudyListItemGroup = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
};

export const StudyListItemContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const StudyListItemGroupContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "18px",
};

export const TitleTabs = (theme) => {
    let commonStyle = {
        display: "flex",
        alignItems: "center",
        gap: "40px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            gap: "0px",
            alignItems: "left",
            maxWidth: "600px",
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TopBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
            flexDirection: "column",
            alignItems: "left",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ClassesList = {
    display: "flex",
    flexDirection: "column",
    maxWidth: "804px",
    width: "100%",
    gap: "20px",
};
