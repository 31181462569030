import React, { useState, useRef, useEffect } from "react";
import { register } from "swiper/element/bundle";
import { Keyboard, Navigation } from "swiper";
import Story from "../Stories/Story";
import "swiper/css";
import "swiper/css/navigation";
import { styles, storyContainer, miniStories, swiperSlide } from "./styles.js";
import getBonusesPreview from "assets/Stories/PreviewImages/getBonusesPreview.png";
import chatBotPreview from "assets/Stories/PreviewImages/chatBotPreview.png";
import cashbackPreview from "assets/Stories/PreviewImages/cashbackPreview.png";
import diplomaPreview from "assets/Stories/PreviewImages/diplomaPreview.png";
import findTutorPreview from "assets/Stories/PreviewImages/findTutorPreview.png";
import increaseMotivationPreview from "assets/Stories/PreviewImages/increaseMotivationPreview.png";
import { Typography, Box } from "@mui/material";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";

const Slider = () => {
    const medias = useMedias();

    const swiperRef = useRef();

    const { t, i18n } = useTranslation();

    const [openStory, setOpenStory] = useState(false);

    const [index, setIndex] = useState(0);

    const handleClickOpen = (ind) => {
        setOpenStory(false);
        setIndex(ind);
        setOpenStory(true);
    };

    const handleClose = () => {
        setOpenStory(false);
    };

    const isDisl = (index) => {
        if (index === 0) return true;
        else return false;
    };

    const isDisr = (index) => {
        if (index === stories.length - 1) return true;
        else return false;
    };

    const stories = [
        {
            smallImage: `url(${getBonusesPreview})`,
            smallTitle: t("main.studentAccount.stories.getBonuses"),
        },
        {
            smallImage: `url(${chatBotPreview})`,
            smallTitle: t("main.studentAccount.stories.chatBot"),
        },
        {
            smallImage: `url(${cashbackPreview})`,
            smallTitle: t("main.studentAccount.stories.cashback"),
        },
        {
            smallImage: `url(${diplomaPreview})`,
            smallTitle: t("main.studentAccount.stories.diploma"),
        },
        {
            smallImage: `url(${findTutorPreview})`,
            smallTitle: t("main.studentAccount.stories.findTutor"),
        },
        {
            smallImage: `url(${increaseMotivationPreview})`,
            smallTitle: t("main.studentAccount.stories.increaseMotivation"),
        },
    ];

    useEffect(() => {
        register();
        const params = {
            spaceBetween: 10,
            slidesPerView: "auto",
            centeredSlides: false,
            slidesPerGroupSkip: 1,
            grabCursor: true,
            keyboard: {
                enabled: true,
            },
            navigation: {
                disabledClass: "disabled-button",
            },
            modules: [Keyboard, Navigation],
            injectStyles: [styles],
        };

        Object.assign(swiperRef.current, params);
        swiperRef.current.initialize();
    }, []);

    return (
        <Box>
            <swiper-container
                ref={swiperRef}
                init="false"
            >
                {stories.map((item, index) => {
                    return (
                        <swiper-slide
                            key={index}
                            style={swiperSlide}
                        >
                            <Box sx={storyContainer(index, stories.length, medias)}>
                                <Box
                                    className="innerSlide"
                                    style={miniStories(item.smallImage)}
                                    onClick={() => handleClickOpen(index)}
                                >
                                    <Typography sx={textThemes.Caption11White}>{item.smallTitle}</Typography>
                                </Box>
                            </Box>
                        </swiper-slide>
                    );
                })}
            </swiper-container>
            <Story
                open={openStory}
                index={index}
                onClose={handleClose}
                change={handleClickOpen}
                disl={isDisl(index)}
                disr={isDisr(index)}
            />
        </Box>
    );
};

export default Slider;
