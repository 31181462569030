import React from "react";

const TypeLineInactiveIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M15.771 3H8.229C4.953 3 3 4.953 3 8.229V15.771C3 19.047 4.953 21 8.229 21H15.771C19.047 21 21 19.047 21 15.771V8.229C21 4.953 19.047 3 15.771 3ZM17.004 16.158H7.279C6.91 16.158 6.604 15.852 6.604 15.483C6.604 15.114 6.91 14.808 7.279 14.808H17.004C17.382 14.808 17.679 15.114 17.679 15.483C17.679 15.852 17.382 16.158 17.004 16.158ZM17.004 9.858H7.279C6.91 9.858 6.604 9.552 6.604 9.183C6.604 8.814 6.91 8.508 7.279 8.508H17.004C17.382 8.508 17.679 8.814 17.679 9.183C17.679 9.552 17.382 9.858 17.004 9.858Z"
                fill="#6212FF"
                fillOpacity="0.2"
            />
        </svg>
    );
};

export default TypeLineInactiveIcon;
