const BalanceLineSeparator = function (props) {
    return (
        <svg
            width="1"
            height="21"
            viewBox="0 0 1 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line
                x1="0.5"
                y1="-2.18557e-08"
                x2="0.500001"
                y2="31"
                stroke="black"
                strokeOpacity="0.2"
            />
        </svg>
    );
};

export default BalanceLineSeparator;
