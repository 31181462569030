import Button from "@mui/material/Button";
import MenuListComposition from "components/UI/PopperMenu";
import * as React from "react";
import Paper from "@mui/material/Paper";
import { List, ListItemButton } from "@mui/material";
import DropDownSVG from "assets/CommonAssets/SVG/DropDownSVG";
import * as styles from "components/SettingsBodyBlock/StudentSettings/Profile/ProfileForms/ProfileFormsThemes";
import CheckMark from "assets/CommonAssets/SVG/CheckMark";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import SettingsStore from "store/SettingsStore";

const EditBirthDayContent = ({ setOpenDayPopper }) => {
    const handleChooseBirthDay = (value, event) => {
        SettingsStore.setTemporaryProfileInfo("birthDay", value);
        setOpenDayPopper(false);
    };

    return (
        <Paper sx={styles.EditContentPaper}>
            <List sx={styles.EditContentList}>
                {SettingsStore.daysList.map((day, index) => (
                    <ListItemButton
                        key={index}
                        disableRipple
                        onClick={handleChooseBirthDay.bind(this, day)}
                        sx={styles.EditContentListItem(day === SettingsStore.daysList[0])}
                    >
                        {day}
                        {day === SettingsStore.TemporaryUserInfo.birthDay && <CheckMark />}
                    </ListItemButton>
                ))}
            </List>
        </Paper>
    );
};

const EditBirthDay = observer(({ children, ...props }) => {
    const [openDayPopper, setOpenDayPopper] = React.useState(false);

    return (
        <MenuListComposition
            placement="bottom-start"
            open={openDayPopper}
            setOpen={setOpenDayPopper}
            content={<EditBirthDayContent setOpenDayPopper={setOpenDayPopper} />}
            zIndex={1}
            paperSx={styles.EditPaper("190px")}
        >
            <Button
                disableRipple
                sx={styles.EditButton(openDayPopper, SettingsStore.TemporaryUserInfo.birthDay === SettingsStore.daysList[0], "80px")}
            >
                {SettingsStore.TemporaryUserInfo.birthDay}
                <DropDownSVG />
            </Button>
        </MenuListComposition>
    );
});

export default memo(EditBirthDay);
