const PaperClip = () => {
    return (
        <svg
            width="3.2vh"
            height="3.2vh"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.09 13.5008L11.9558 18.635C11.0818 19.509 9.89641 20 8.6604 20C7.42438 20 6.23899 19.509 5.365 18.635C4.491 17.761 4 16.5756 4 15.3396C4 14.1036 4.491 12.9182 5.365 12.0442L8.9321 8.4771L12.4992 4.91C13.0819 4.32734 13.8721 4 14.6961 4C15.5201 4 16.3104 4.32734 16.8931 4.91C17.4757 5.49266 17.8031 6.28292 17.8031 7.10693C17.8031 7.93094 17.4757 8.7212 16.8931 9.30386L13.3221 12.871L9.7511 16.4381C9.45977 16.7294 9.06464 16.8931 8.65263 16.8931C8.24063 16.8931 7.8455 16.7294 7.55417 16.4381C7.26284 16.1467 7.09917 15.7516 7.09917 15.3396C7.09917 14.9276 7.26284 14.5325 7.55417 14.2411L12.145 9.6581"
                stroke="#9E9EB0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default PaperClip;
