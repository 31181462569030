const ViewsIcon = function (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <g clipPath="url(#clip0_3567_75988)">
                <mask
                    id="mask0_3567_75988"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="16"
                    height="17"
                >
                    <path
                        d="M0 0.5H16V16.5H0V0.5Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask0_3567_75988)">
                    <path
                        d="M14.667 8.4974C14.667 9.66406 11.7446 13.1641 8.00028 13.1641C4.25596 13.1641 1.33362 9.66406 1.33362 8.4974C1.33362 7.33073 4.25596 3.83073 8.00028 3.83073C11.7446 3.83073 14.667 7.33073 14.667 8.4974Z"
                        stroke="#9E9EB0"
                        strokeWidth="1.3"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10.5001 8.5C10.5001 9.88072 9.38084 11 8.00012 11C6.6194 11 5.50012 9.88072 5.50012 8.5C5.50012 7.11928 6.6194 6 8.00012 6C9.38084 6 10.5001 7.11928 10.5001 8.5Z"
                        fill="#9E9EB0"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_3567_75988">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ViewsIcon;
