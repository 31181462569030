import { styleProcessing } from "API/plugins/Utilities";

export const StoriesBlock = (theme) => {
    let commonStyle = {
        maxWidth: "599px",
        width: "100%",
        height: "225px",
        borderRadius: "20px",
        background: "#FFF",
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            height: "auto",
            borderRadius: "none",
            background: "none",
            boxShadow: "none",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TitleBox = {
    padding: "22px 0 21px 20px",
};
