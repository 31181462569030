export const Paper = {
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
    borderRadius: "10px",
    padding: "7px 5px 12px 5px",
};

export const List = {
    padding: 0,
    margin: 0,
    border: "none",
    maxHeight: "200px",
    position: "relative",
    overflow: "auto",
    "&::-webkit-scrollbar": {
        width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        background: "rgba(187, 187, 200, 0.50)",
        height: "60px",
    },
};

export const MenuItem = {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%" /* 21px */,
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "7px 10px 6px 10px",
    ":hover": {
        color: "#6212FF",
        background: "rgba(187, 187, 200, 0.20)",
    },
};
