import { Avatar, Box, Typography } from "@mui/material";
import Message from "assets/PAAssets/SVG/message";
import React from "react";
import SButton from "components/UI/SButton";
import MiniChatStore from "store/MiniChatStore";
import TutorClassesStore from "store/TutorClassesStore";
import * as themes from "./NewLessonThemes";
import * as textThemes from "themes/TextThemes";
import RegularStudentIcon from "assets/TeacherClassesAssets/SVG/RegularStudentIcon";
import NewStudentIcon from "assets/TeacherClassesAssets/SVG/NewStudentIcon";
import { useTranslation } from "react-i18next";
import StartClassButton from "components/AccountBodyBlock/TeacherAccount/NextClass/StartClassButton/StartClassButton";
import { memo } from "react";
import { observer } from "mobx-react-lite";
import { useMedias } from "API/mediasHook";

const NextLesson = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const getNextClassTime = () => {
        const classTime = TutorClassesStore.formatClassDate(TutorClassesStore.nextClass.timeStart);

        return `${t(classTime.dayPart).charAt(0).toUpperCase() + t(classTime.dayPart).slice(1)}, ${classTime.dateDay} ${t(classTime.dateMonth)} ${t("main.at")} ${classTime.timePart}`;
    };

    const getClassTimeRange = () => {
        const timeRange = TutorClassesStore.getTimeDifference(TutorClassesStore.nextClass);

        return `${timeRange} ${t("main.min")}`;
    };

    const showDialog = () => {
        MiniChatStore.SetOpen(true);
    };

    const MobileVersion = (
        <Box sx={themes.NextLesson(medias)}>
            <Box sx={themes.LeftPanel(medias)}>
                <Typography sx={textThemes.Button14BoldBlack}>{`${getNextClassTime()}`}</Typography>
                <Box sx={themes.SubjectAimContainer}>
                    <Typography sx={textThemes.Body2Black}>{TutorClassesStore.nextClass.subject}</Typography>
                    <Typography sx={textThemes.Caption2DarkGrey}>{`${TutorClassesStore.nextClass.aim}, ${getClassTimeRange()}`}</Typography>
                </Box>
            </Box>
            <StartClassButton style={themes.StartButtonStyle} />
            <Box sx={themes.StudentBlock(medias)}>
                <Avatar
                    src={TutorClassesStore.nextClass.studentAvatar}
                    sx={themes.Avatar}
                />
                {TutorClassesStore.nextClass.studentType === "constant" ? (
                    <Box sx={themes.StudentTextBox}>
                        <Box sx={themes.StudentNameBox}>
                            <Typography sx={textThemes.Body2Black}>{TutorClassesStore.nextClass.studentName}</Typography>
                            <Box sx={themes.IconBox}>
                                <RegularStudentIcon />
                            </Box>
                        </Box>
                        <Typography sx={textThemes.Caption2DarkGrey}>{t("teacherClasses.constantStudent")}</Typography>
                    </Box>
                ) : TutorClassesStore.nextClass.studentType === "new" ? (
                    <Box sx={themes.StudentTextBox}>
                        <Box sx={themes.StudentNameBox}>
                            <Typography sx={textThemes.Body2Black}>{TutorClassesStore.nextClass.studentName}</Typography>
                            <Box sx={themes.IconBox}>
                                <NewStudentIcon />
                            </Box>
                        </Box>
                        <Typography sx={textThemes.Caption2DarkGrey}>{t("teacherClasses.newStudent")}</Typography>
                    </Box>
                ) : (
                    <Typography sx={textThemes.Body2Black}>{TutorClassesStore.nextClass.studentName}</Typography>
                )}
                <SButton
                    variant="icon"
                    onClick={showDialog}
                    style={themes.ChatButton}
                >
                    <Message />
                </SButton>
            </Box>
        </Box>
    );

    return (
        <>
            {medias.sm ? (
                <Box sx={themes.NextLesson(medias)}>
                    <Box sx={themes.LeftPanel(medias)}>
                        <Box sx={themes.StudentBlock(medias)}>
                            <Avatar
                                src={TutorClassesStore.nextClass.studentAvatar}
                                sx={themes.Avatar}
                            />
                            {TutorClassesStore.nextClass.studentType === "constant" ? (
                                <Box sx={themes.StudentTextBox}>
                                    <Box sx={themes.StudentNameBox}>
                                        <Typography sx={textThemes.Body2Black}>{TutorClassesStore.nextClass.studentName}</Typography>
                                        <Box sx={themes.IconBox}>
                                            <RegularStudentIcon />
                                        </Box>
                                    </Box>
                                    <Typography sx={textThemes.Caption2DarkGrey}>{t("teacherClasses.constantStudent")}</Typography>
                                </Box>
                            ) : TutorClassesStore.nextClass.studentType === "new" ? (
                                <Box sx={themes.StudentTextBox}>
                                    <Box sx={themes.StudentNameBox}>
                                        <Typography sx={textThemes.Body2Black}>{TutorClassesStore.nextClass.studentName}</Typography>
                                        <Box sx={themes.IconBox}>
                                            <NewStudentIcon />
                                        </Box>
                                    </Box>
                                    <Typography sx={textThemes.Caption2DarkGrey}>{t("teacherClasses.newStudent")}</Typography>
                                </Box>
                            ) : (
                                <Typography sx={textThemes.Body2Black}>{TutorClassesStore.nextClass.studentName}</Typography>
                            )}
                        </Box>
                        <Typography sx={textThemes.Button14BoldBlack}>{`${getNextClassTime()}`}</Typography>
                        <Box sx={themes.SubjectAimContainer}>
                            <Typography sx={textThemes.Body2Black}>{TutorClassesStore.nextClass.subject}</Typography>
                            <Typography sx={textThemes.Caption2DarkGrey}>{`${TutorClassesStore.nextClass.aim}, ${getClassTimeRange()}`}</Typography>
                        </Box>
                    </Box>
                    <Box sx={themes.ButtonsBlock}>
                        <SButton
                            variant="icon"
                            onClick={showDialog}
                        >
                            <Message />
                        </SButton>
                        <Box sx={themes.Divider}></Box>
                        <StartClassButton />
                    </Box>
                </Box>
            ) : (
                <>{MobileVersion}</>
            )}
        </>
    );
});

export default memo(NextLesson);
