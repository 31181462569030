import React from "react";
import InfoIcon from "assets/CommonAssets/SVG/InfoIcon";
import { Tooltip } from "@mui/material";
import { IconButton } from "@mui/material";
import * as styles from "./RefTableThemes";
import { observer } from "mobx-react-lite";
import BalanceStore from "store/BalanceStore";
import UserStore from "store/UserStore";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";

const RefTable = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <table style={styles.Table}>
            <tr style={styles.HeaderRow}>
                <th style={styles.HeaderRowTh}>
                    {medias.sm ? t("main.balance.sentTo") : t("main.balance.toWhom")}
                    <Tooltip
                        sx={styles.TooltipSx}
                        componentsProps={styles.TooltipComponentProps}
                        PopperProps={styles.TooltipPopperProps}
                        title={UserStore.role === "student" ? t("main.balance.tooltipStudentFirst") : t("main.balance.tooltipTeacherFirst")}
                        placement="bottom"
                    >
                        <IconButton
                            disableRipple
                            sx={styles.IconButton}
                        >
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </th>
                <th style={styles.HeaderRowThDate}>{t("main.balance.date")}</th>
                <th style={styles.HeaderRowThPoints}>
                    {medias.sm ? t("main.balance.pointsReceived") : t("main.balance.points1")}
                    <Tooltip
                        sx={styles.TooltipSx}
                        componentsProps={styles.TooltipComponentProps}
                        PopperProps={styles.TooltipPopperProps}
                        title={UserStore.role === "student" ? t("main.balance.tooltipStudentSecond") : t("main.balance.tooltipTeacherSecond")}
                        placement="bottom"
                    >
                        <IconButton
                            disableRipple
                            sx={styles.IconButton}
                        >
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </th>
            </tr>
            {BalanceStore.refList.map((val, key) => {
                return (
                    <tbody key={key}>
                        <tr style={styles.Row}>
                            <td style={styles.RowTd}>{val.refFriend}</td>
                            <td style={styles.RowTdDate}>{val.date}</td>
                            <td style={styles.RowTdPoints}>{val.points}</td>
                        </tr>
                    </tbody>
                );
            })}
        </table>
    );
});

export default memo(RefTable);
