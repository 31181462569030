export const descriptionStyle = (medias) => {
    return {
        width: medias.sm ? "667px" : "296px",
        height: medias.sm ? "84px" : "fit-content",
        wordBreak: "break-word",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: medias.sm ? "4" : "9",
        WebkitBoxOrient: "vertical",
        color: "#222222",
        flex: "none",
        order: 3,
        flexGrow: 0,
        zIndex: 3,
    };
};
