import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./OrdersPriceThemes";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import OrdersSearchStore from "store/OrdersSearchStore";
import { ExpandMore } from "@mui/icons-material";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";

const OrdersPrice = observer(({ children, ...props }) => {
    const handleChange = () => {
        OrdersSearchStore.ToggleOrdersPriceExpanded();
    };

    const processingOrdersPrice = (value, event) => {
        OrdersSearchStore.SetOrdersPrice(value, event.target.value);
    };

    return (
        <ThemeProvider theme={themes.OrdersPriceAccordionTheme}>
            <Accordion
                expanded={OrdersSearchStore.ordersPriceExpanded}
                onChange={handleChange}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography sx={themes.headerOrdersPriceStyle}>Ставка, ₽</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <ThemeProvider theme={themes.OrdersPriceTheme}>
                            <TextField
                                variant={"standard"}
                                placeholder={"От"}
                                value={OrdersSearchStore.optionsOrdersPrice[0].PriceFrom}
                                onChange={processingOrdersPrice.bind(this, "PriceFrom")}
                            />
                            <TextField
                                variant={"standard"}
                                placeholder={"До"}
                                value={OrdersSearchStore.optionsOrdersPrice[0].PriceTo}
                                onChange={processingOrdersPrice.bind(this, "PriceTo")}
                            />
                        </ThemeProvider>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </ThemeProvider>
    );
});

export default memo(OrdersPrice);
