import SBAutoComplete from "components/UI/Autocomplete";
import { universities } from "API/dictionaries/const_data";
import React from "react";
import * as textStyles from "themes/TextThemes";
import { Typography } from "@mui/material";
import * as styles from "../ProfileFormsThemes";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import SettingsStore from "store/SettingsStore";

const UniversityForm = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={styles.FormBlock}>
            <Typography sx={textStyles.Body2Black}>{t("main.settings.profile.university")}</Typography>
            <SBAutoComplete
                value={SettingsStore.TemporaryUserInfo.university}
                changefunc={SettingsStore.setTemporaryUniversity}
                inputvalue={SettingsStore.TemporaryUserInfo.university}
                inputchangefunc={SettingsStore.setTemporaryUniversity}
                list={universities}
                placeholder={t("main.settings.profile.nameOfUniversity")}
            />
        </Box>
    );
});

export default memo(UniversityForm);
