export const DrawerHeaderWrapper = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    background: "#F7F7F7",
    borderBottom: "solid 1px #BBBBC8",
    borderTop: "solid 1px #BBBBC8",
    boxSizing: "border-box",
};
