import GetBonusesImage from "assets/Stories/GetBonuses/GetBonusesImage.png";
import WhatToDoImage from "assets/Stories/GetBonuses/WhatToDoImage.png";
import BonusesCreditTimeImage from "assets/Stories/GetBonuses/BonusesCreditTimeImage.png";
import GetBonusesFormImage from "assets/Stories/GetBonuses/GetBonusesFormImage.png";
import ChatBotImage from "assets/Stories/ChatBot/ChatBotImage.png";
import CashbackImage from "assets/Stories/Cashback/CashbackImage.png";
import HowItWorksImage from "assets/Stories/Cashback/HowItWorksImage.png";
import WhatAboutBonusesImage from "assets/Stories/Cashback/WhatAboutBonusesImage.png";
import AlreadyUsedCashbackImage from "assets/Stories/Cashback/AlreadyUsedCashbackImage.png";
import DiplomaImage from "assets/Stories/Diploma/DiplomaImage.png";
import HowToOrderImage from "assets/Stories/Diploma/HowToOrderImage.png";
import UnderControlImage from "assets/Stories/Diploma/UnderControlImage.png";
import OrderWorkImage from "assets/Stories/Diploma/OrderWorkImage.png";
import FindTutorImage from "assets/Stories/FindTutor/FindTutorImage.png";
import YourChoiceImage from "assets/Stories/FindTutor/YourChoiceImage.png";
import HowSearchWorksImage from "assets/Stories/FindTutor/HowSearchWorksImage.png";
import WantFindByYourselfImage from "assets/Stories/FindTutor/WantFindByYourselfImage.png";
import WhatNextImage from "assets/Stories/FindTutor/WhatNextImage.png";
import HowToHelpImage from "assets/Stories/FindTutor/HowToHelpImage.png";
import IncreaseMotivationImage from "assets/Stories/IncreaseMotivation/IncreaseMotivationImage.png";
import HelpIncreaseImage from "assets/Stories/IncreaseMotivation/HelpIncreaseImage.png";
import TutorEncourageImage from "assets/Stories/IncreaseMotivation/TutorEncourageImage.png";
import HelpSaveImage from "assets/Stories/IncreaseMotivation/HelpSaveImage.png";
import TutorSmilingImage from "assets/Stories/IncreaseMotivation/TutorSmilingImage.png";
import ReadInBlogImage from "assets/Stories/IncreaseMotivation/ReadInBlogImage.png";
import { styleProcessing } from "API/plugins/Utilities";

export const StoryDialog = {
    sx: {
        borderRadius: 5,
        backgroundColor: "rgba(255,255,255,0)",
        boxShadow: "0",
    },
};

export const StoryContainer = {
    display: "flex",
    alignItems: "center",
    gap: "30px",
};

export const StoryContent = (theme) => {
    let commonStyle = {
        width: 460,
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            position: "relative",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Stories = {
    main: "main",
    progressContainer: "progress-bar",
    progressBarContainer: "progress-cont",
    height: "auto",
};

export const ChangeStoryButton = (hovered) => {
    return {
        height: "32px",
        width: "32px",
        background: hovered ? "rgba(255, 255, 255, 0.70)" : "rgba(255, 255, 255, 0.20)",
    };
};

export const CloseIcon = (theme) => {
    let commonStyle = {
        position: "relative",
        zIndex: "2",
        float: "right",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            position: "absolute",
            top: "58px",
            right: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const StoryBox = {
    height: "fit-content",
};

export const StoryText = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "0px 30px 60px 30px",
};

export const StoryForm = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    padding: "0px 30px 60px 30px",
};

export const GetBonusesOuter = {
    backgroundImage: `url(${GetBonusesImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const WhatToDoOuter = {
    backgroundImage: `url(${WhatToDoImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const BonusesCreditTimeOuter = {
    backgroundImage: `url(${BonusesCreditTimeImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const GetBonusesFormOuter = {
    backgroundImage: `url(${GetBonusesFormImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const ChatBotOuter = {
    backgroundImage: `url(${ChatBotImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const CashbackOuter = {
    backgroundImage: `url(${CashbackImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const HowItWorksOuter = {
    backgroundImage: `url(${HowItWorksImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const WhatAboutBonusesOuter = {
    backgroundImage: `url(${WhatAboutBonusesImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const AlreadyUsedCashbackOuter = {
    backgroundImage: `url(${AlreadyUsedCashbackImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const DiplomaOuter = {
    backgroundImage: `url(${DiplomaImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const HowToOrderOuter = {
    backgroundImage: `url(${HowToOrderImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const UnderControlOuter = {
    backgroundImage: `url(${UnderControlImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const OrderWorkOuter = {
    backgroundImage: `url(${OrderWorkImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const FindTutorOuter = {
    backgroundImage: `url(${FindTutorImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const YourChoiceOuter = {
    backgroundImage: `url(${YourChoiceImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const HowSearchWorksOuter = {
    backgroundImage: `url(${HowSearchWorksImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const WantFindByYourselfOuter = {
    backgroundImage: `url(${WantFindByYourselfImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const WhatNextOuter = {
    backgroundImage: `url(${WhatNextImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const HowToHelpOuter = (condition) => {
    return {
        backgroundImage: `url(${HowToHelpImage})`,
        height: "678px",
        display: "flex",
        alignItems: condition ? "flex-end" : "center",
    };
};

export const IncreaseMotivationOuter = {
    backgroundImage: `url(${IncreaseMotivationImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const HelpIncreaseOuter = {
    backgroundImage: `url(${HelpIncreaseImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const TutorEncourageOuter = {
    backgroundImage: `url(${TutorEncourageImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const HelpSaveOuter = {
    backgroundImage: `url(${HelpSaveImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const TutorSmilingOuter = {
    backgroundImage: `url(${TutorSmilingImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const ReadInBlogOuter = {
    backgroundImage: `url(${ReadInBlogImage})`,
    height: "678px",
    display: "flex",
    alignItems: "flex-end",
};

export const TextField = {
    position: "relative",
    zIndex: "2",
    background: "rgba(255, 255, 255, 0.05)",
    borderRadius: "10px",
    "& .MuiInputBase-input::placeholder": {
        color: "rgba(255, 255, 255, 0.70)",
        opacity: 1,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "150%",
    },
    "& .MuiInputBase-input": {
        color: "#FFF",
        opacity: 1,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "150%",
    },
    "& .MuiInputBase-inputMultiline::-webkit-scrollbar": {
        width: "3px",
    },
    "& .MuiInputBase-inputMultiline::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(255, 255, 255, 0.30)",
        borderRadius: "100px",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: "10px",
            borderColor: "rgba(255, 255, 255, 0.30)",
        },
        "&:hover fieldset": {
            borderRadius: "10px",
            borderColor: "rgba(255, 255, 255, 0.30)",
        },
        "&.Mui-focused fieldset": {
            borderRadius: "10px",
            borderColor: "rgba(255, 255, 255, 0.30)",
        },
    },
};

export const SendButton = {
    color: "#6212FF",
    position: "relative",
    zIndex: "2",
    width: "100%",
    margin: "12px 0px 28px 0px",
    background: "rgba(255, 255, 255)",
    ":hover": {
        background: "rgba(255, 255, 255)",
        color: "#6212FF",
        boxShadow: "none",
    },
};

const op = (dis) => {
    if (dis) {
        return "0%";
    } else return "100%";
};

export const LeftIcon = (disr) => {
    return {
        color: "#222222",
        opacity: op(disr),
    };
};

export const RightIcon = (disr) => {
    return {
        color: "#222222",
        opacity: op(disr),
    };
};
