import React from "react";
import * as themes from "./InviteBlockThemes";
import LinkBlock from "./LinkBlock/LinkBlock";
import PhotoInviteBlock from "./PhotoInviteBlock/PhotoInviteBlock";
import Box from "@mui/material/Box";
import { memo } from "react";

const InviteBlock = ({ children, ...props }) => {
    return (
        <Box sx={themes.topBlock}>
            <LinkBlock />
            <PhotoInviteBlock />
        </Box>
    );
};

export default memo(InviteBlock);
