import { createTheme } from "@mui/material/styles";

export const educationCardStyle = (medias) => {
    return {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "20px",
        padding: medias.sm ? "30px 40px" : "20px",
        width: medias.sm ? "827px" : "320px",
        borderRadius: "20px",
        background: "var(--main-white, #FFF)",
    };
};

export const educationContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    position: "relative",
    maxHeight: "fit-content",
};

export const headerContainerStyle = (medias) => {
    return {
        display: "flex",
        flexDirection: medias.sm ? "unset" : "column",
        alignItems: "center",
        gap: medias.sm ? 60 : 10,
    };
};

export const aboutTextStyle = {
    alignSelf: "stretch",
    color: "var(--text-black, #222)",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
};

export const TabsTheme = createTheme({
    components: {
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: "fit-content",
                    ".MuiTabs-flexContainer": {
                        gap: "20px",
                    },
                    ".MuiButtonBase-root": {
                        color: "var(--text-grey, #9E9EB0)",
                        fontVariantNumeric: "lining-nums tabular-nums",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "150%",
                        textTransform: "none",
                        padding: 0,
                        minWidth: "fit-content",
                        minHeight: "fit-content",
                        marginBottom: 4,
                    },
                    ".MuiTabs-indicator": {
                        background: "#6212FF",
                    },
                    ".Mui-selected": {
                        color: "var(--text-black, #222)!important",
                    },
                },
            },
        },
    },
});

export const tabPanelStyle = {
    padding: 0,
};
