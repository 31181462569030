import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import { Button, Dialog, IconButton } from "@mui/material";
import * as themes from "./ModalReportThemes";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CloseRounded } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import OrderStore from "store/OrderStore";
import FreeSingleChoiceFilter from "components/UI/FreeSingleChoiceFilter";
import TextField from "@mui/material/TextField";

const ModalReport = observer(({ children, ...props }) => {
    const Close = () => {
        props.handleDiscard();
    };

    const Report = () => {
        props.handleReport();
    };

    const processingReports = (event, value) => {
        OrderStore.SetReports(value);
    };

    const processingReportMessage = (event) => {
        OrderStore.SetReportMessage(event.target.value);
    };

    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={props.open}
                onClose={Close}
            >
                <Box sx={themes.dialogContainerStyle}>
                    <ThemeProvider theme={themes.CloseIconTheme}>
                        <IconButton
                            onClick={Close}
                            disableRipple
                        >
                            <CloseRounded></CloseRounded>
                        </IconButton>
                    </ThemeProvider>
                    <Box sx={themes.cardContainerStyle}>
                        <Box sx={themes.headerBlockContainerStyle}>
                            <Typography sx={themes.headerTextStyle}>Пожаловаться на отклик</Typography>
                            <Typography sx={themes.subheaderTextStyle}>Выберите, пожалуйста, причину жалобы или, если нет подходящего варианта, напишите своими словами</Typography>
                        </Box>
                        <Box sx={themes.contentBlockContainerStyle}>
                            <FreeSingleChoiceFilter
                                propOption={OrderStore.optionReports}
                                propOptions={OrderStore.optionsReports}
                                valueToShow={"Reports"}
                                valueToBind={"ID"}
                                processingChange={processingReports}
                                themeWrapper={themes.FiltersReportsTheme}
                            ></FreeSingleChoiceFilter>
                            {OrderStore.optionReports.ID === 3 ? (
                                <ThemeProvider theme={themes.ReportMessageTheme(OrderStore.reportMessage.length === 1000)}>
                                    <TextField
                                        onChange={processingReportMessage}
                                        value={OrderStore.reportMessage}
                                        variant="outlined"
                                        placeholder={"Опишите жалобу своими словами"}
                                        multiline
                                        rows={6}
                                        inputProps={{
                                            maxLength: 1000,
                                        }}
                                        FormHelperTextProps={{ component: "div" }}
                                        helperText={
                                            <Box sx={themes.helperContainerStyle(OrderStore.reportMessage.length === 1000)}>
                                                {OrderStore.reportMessage.length === 1000 ? <Typography sx={themes.currLengthTextMaxStyle}>Достигнут лимит символов</Typography> : null}
                                                <Box sx={themes.helperCounterContainerStyle}>
                                                    <Typography sx={themes.currLengthTextStyle(OrderStore.reportMessage.length)}>{OrderStore.reportMessage.length}</Typography>
                                                    <Typography sx={themes.maxLengthTextStyle}>/1000</Typography>
                                                </Box>
                                            </Box>
                                        }
                                    ></TextField>
                                </ThemeProvider>
                            ) : null}
                        </Box>
                        <Box sx={themes.actionsBlockContainerStyle}>
                            <ThemeProvider theme={themes.ModalActionButtonTheme(!(OrderStore.optionReports.ID === 3 && OrderStore.reportMessage.length === 0))}>
                                <Button
                                    onClick={Report}
                                    disabled={OrderStore.optionReports.ID === 3 && OrderStore.reportMessage.length === 0}
                                    disableRipple
                                >
                                    <Typography style={themes.buttonTextStyle}>Отправить жалобу</Typography>
                                </Button>
                            </ThemeProvider>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </ThemeProvider>
    );
});

export default memo(ModalReport);
