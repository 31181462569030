import { Box, Typography, styled } from "@mui/material";
export const HiddenAvatarWrapper = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
};

export const HiddenAvatar = styled(Box)({
    display: "flex",
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    border: "1px dashed",
    borderColor: "rgba(187, 187, 200, 1)",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
});

export const HiddenAvatarText = styled(Typography)({
    textAlign: "center",
    fontSize: "8px",
    letterSpacing: "-0.2px",
    lineHeight: "8px",
    color: "rgba(103, 103, 122, 1)",
});

export const HiddenAvatarName = { fontSize: "14px" };
