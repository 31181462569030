export const reviewBlockStyle = {
    display: "flex",
    gap: 20,
    alignItems: "center",
};

export const mainBlockStyle = {
    display: "flex",
    gap: 15,
};

export const textBlockStyle = {
    display: "flex",
    gap: 5,
};

export const reviewsLabelStyle = {
    color: "var(--text-grey, #9E9EB0)",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
};

export const valueStyle = {
    color: "var(--text-black, #222)",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
};

export const iconViewedStyle = {
    color: "#BBBBC8",
    width: 16,
    height: 16,
    alignSelf: "center",
};

export const additionalBlockStyle = {
    display: "flex",
    padding: "2px 10px 4px 10px",
    flexDirection: "row",
    gap: "10px",
    borderRadius: "6px",
    background: "rgba(25, 178, 12, 0.10)",
};

export const iconStarStyle = {
    color: "#19B20C",
    width: 16,
    height: 16,
    alignSelf: "center",
};

export const additionalTextStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};
