import React from "react";

const IconLeft = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M10.4615 2L4.7979 7.13679C4.36715 7.52747 4.35933 8.20189 4.7809 8.60245L10.4615 14"
                stroke="#222222"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default IconLeft;
