export const TeacherPersonalDataStep = {
    display: "flex",
    flexDirection: "column",
    maxWidth: "599px",
    width: "100%",
};

export const TeacherPersonalData = {
    marginBottom: "60px",
};

export const HelperText = {
    marginTop: "10px",
};

export const TeacherQuestionnaireTopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const TeacherQuestionnaireTitle = {
    color: "#222",
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const TeacherQuestionnaireSubTitle = {
    color: "#67677a",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const TeacherQuestionnaireClassesAimsOuterContainer = {
    marginTop: "40px",
    display: "flex",
    flexDirection: "column",
    gap: "40px",
};

export const TeacherQuestionnaireClassesAimsContainer = {
    marginTop: "40px",
    display: "flex",
    flexDirection: "column",
};

export const TeacherQuestionnaireClassesAimsTitle = {
    color: "#222",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "150%",
    borderRadius: "10px",
    background: "rgba(187, 187, 200, 0.17)",
    padding: "12px 0 12px 20px",
};

export const ClassesAimsContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "",
};

export const ChipsContainer = (len) => {
    return {
        marginTop: len > 0 ? "16px" : "0px",
    };
};

// let commonStyle = {
//     marginBottom: "60px",
// };

// let styleObject = {
//     xlStyle: commonStyle,
//     lgStyle: commonStyle,
//     mdStyle: commonStyle,
//     smStyle: commonStyle,
//     xsStyle: {
//         ...commonStyle,
//         marginBottom: "40px",
//     },
//     defaultStyle: commonStyle,
// };

// return styleProcessing(theme, styleObject);
