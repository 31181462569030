import React, { memo, useState } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./CardActionsThemes";
import { Button, Tooltip, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ModalNotAuth from "components/UI/ModalNotAuth/ModalNotAuth";
import UserStore from "store/UserStore";
import MiniChatStore from "store/MiniChatStore";
import { useMedias } from "../../../../API/mediasHook";
import Box from "@mui/material/Box";

const CardActions = observer(({ children, ...props }) => {
    const navigate = useNavigate();

    const [dialogOpen, SetDialogOpen] = useState(false);

    const messageTutor = () => {
        //
    };

    const showDialog = () => {
        UserStore.isAuth ? MiniChatStore.SetOpen(true) : SetDialogOpen(true);
    };

    const hideDialog = () => {
        SetDialogOpen(false);
    };

    const medias = useMedias();

    return (
        <Box sx={themes.actionsContainerStyle(props.styleMode ? props.styleMode : 0, medias)}>
            <Box sx={themes.priceContainerStyle}>
                <Box sx={themes.textContainerStyle}>
                    <Typography sx={themes.fromTextStyle}>от</Typography>
                    <Typography sx={themes.fromTextValueStyle}>{props.value.PriceFrom.toLocaleString()} ₽/ч</Typography>
                </Box>
                <ThemeProvider theme={themes.AddCashbackButtonTheme}>
                    <Tooltip
                        title={
                            <Typography sx={themes.tooltipCashbackStyle}>
                                <span>
                                    При обучении у репетитора вам вернётся <span style={themes.tooltipCashbackValueStyle}>{props.value.Cashback} баллов </span>на бонусный счёт
                                </span>
                            </Typography>
                        }
                        arrow={true}
                        placement="right"
                    >
                        <Button
                            onClick={() => {
                                console.log("clicked");
                            }}
                        >
                            <Typography style={themes.cashbackTextStyle}>+{props.value.Cashback}</Typography>
                        </Button>
                    </Tooltip>
                </ThemeProvider>
            </Box>
            <Box sx={themes.buttonsContainerStyle(props.styleMode ? props.styleMode : 0, medias)}>
                <ThemeProvider theme={themes.MessageButtonTheme}>
                    <Button
                        onClick={false ? messageTutor : showDialog}
                        disableRipple
                    >
                        <Typography style={themes.messageTextStyle}>Написать</Typography>
                    </Button>
                </ThemeProvider>
                <ThemeProvider theme={themes.OrderButtonTheme}>
                    <Button
                        onClick={() => navigate("/bid")}
                        disableRipple
                    >
                        <Typography style={themes.orderTextStyle}>Предложить заказ</Typography>
                    </Button>
                </ThemeProvider>
            </Box>
            <ModalNotAuth
                open={dialogOpen}
                handleClose={hideDialog}
                value={props.value}
                header={"Зарегистрируйтесь, чтобы написать репетитору"}
                content={"Чтобы начать переписку с репетитором " + props.value.Name + ", нужно создать аккаунт на StudyBuddy"}
            ></ModalNotAuth>
        </Box>
    );
});

export default memo(CardActions);
