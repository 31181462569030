import { createTheme } from "@mui/material/styles";
export const anonymousAvatarContainer = (medias) => {
    return {
        boxSizing: "border-box",
        width: medias.sm ? "50px" : "36px",
        height: medias.sm ? "50px" : "36px",
        border: "1px dashed #BBBBC8",
        borderRadius: "50%",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        flexWrap: "nowrap",
        flexDirection: "column",
        alignItems: "center",
    };
};

export const anonymousAvatarTextStyle = {
    fontSize: 10,
    textAlign: "center",
    color: "#67677A",
};

export const AvatarTheme = (medias) => {
    return {
        components: {
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        position: "relative",
                        width: medias.sm ? "50px" : "36px",
                        height: medias.sm ? "50px" : "36px",
                        left: "0px",
                        top: "0px",
                        borderRadius: "50px",
                        cursor: "default",
                    },
                },
            },
        },
    };
};
