export const mainContainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "solid 1px rgba(238, 238, 240, 1)",
    minHeight: "52px",
    padding: "0px 17px 0px 20px",
};

export const tutorContainer = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "12px",
};

export const buttonsContainer = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "16px",
};

export const iconButtonsContainer = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "2px",
};

export const tutorNameContainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "0px",
};

export const bonusesContainer = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "4px",
};

export const bonusesText = {
    textAlign: "right",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "150%",
    background: "linear-gradient(257deg, #04C83B 20.58%, #12AAFF 81.82%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
};

export const conditionalButtons = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "12px",
};
