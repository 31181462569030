import React from "react";
import { Stack, Typography } from "@mui/material";
import StyledWhiteBox from "components/DiplomaChats/StyledComponents/StyledWhiteBox";
import SButton from "components/UI/SButton";
import TaskIcon from "components/DiplomaChats/Icons/TaskIcon";

import * as styles from "pages/DiplomaChats/styles";

const DuplicateOrder = () => {
    return (
        <StyledWhiteBox padding="20px">
            <Stack
                direction="column"
                spacing={3}
            >
                <Stack
                    direction="row"
                    spacing={2.5}
                >
                    <span style={styles.taskIconBackground}>
                        <TaskIcon />
                    </span>
                    <Typography
                        variant="standard"
                        width={400}
                    >
                        Нужна помощь с похожей задачей? Продублируйте заказ и мы быстро подберём подходящих специалистов
                    </Typography>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                >
                    {/* TODO route to duplicate order tab */}
                    <SButton variant="arrow">Продублировать заказ</SButton>
                </Stack>
            </Stack>
        </StyledWhiteBox>
    );
};

export default DuplicateOrder;
