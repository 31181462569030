import React from "react";

import { Stack, Typography } from "@mui/material";

import * as styles from "pages/DiplomaChats/styles";
import { VerifiedUser } from "@mui/icons-material";

const TutorInfoForStudent = () => {
    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            spacing={2.5}
        >
            <span style={styles.avatarContainer}>test</span>
            <Stack
                direction="column"
                spacing={1}
            >
                <Stack
                    direction="row"
                    spacing={1.25}
                    alignItems="center"
                    justifyContent="flex-start"
                >
                    <Typography variant="title">Екатерина М.</Typography>
                    <VerifiedUser sx={styles.verifiedGreen} />
                </Stack>
                <Stack
                    direction="row"
                    spacing={1.25}
                    alignItems="center"
                >
                    <span style={styles.ratingContainer}>4.9</span>
                    <span style={styles.reviewCont}>200 отзывов</span>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default TutorInfoForStudent;
