import React from "react";

import { Stack, Box, Typography } from "@mui/material";

import LessonAssessment from "./LessonAssessment";

import * as styles from "pages/DiplomaChats/styles";

const InfoAboutTutor = () => {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                spacing={1.5}
                alignItems="center"
            >
                <Box
                    component="span"
                    sx={{
                        ...styles.avatarContainer,
                        width: "40px",
                        height: "40px",
                    }}
                >
                    face
                </Box>
                <Stack
                    direction="column"
                    spacing={0}
                    justifyContent="flex-start"
                >
                    <Typography variant="title1">Елизавета А.</Typography>
                    <Typography variant="grayText">в сети 3 ч назад</Typography>
                </Stack>
            </Stack>
            <LessonAssessment />
        </Stack>
    );
};

export default InfoAboutTutor;
