import { useMediaQuery, useTheme } from "@mui/material";
import { createTheme } from "@mui/material/styles";

export const useMedias = () => {
    const theme = useTheme();

    const xsMedia = useMediaQuery(theme.breakpoints.up("xs"));

    const smMedia = useMediaQuery(theme.breakpoints.up("sm"));

    const mdMedia = useMediaQuery(theme.breakpoints.up("md"));

    const lgMedia = useMediaQuery(theme.breakpoints.up("lg"));

    const xlMedia = useMediaQuery(theme.breakpoints.up("xl"));

    return { xs: xsMedia, sm: smMedia, md: mdMedia, lg: lgMedia, xl: xlMedia };

    /*return createTheme({
        spacing: 1,
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
    });*/
};
