import React, { memo, useState } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./CardActionsAltThemes";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ModalNotAuth from "components/UI/ModalNotAuth/ModalNotAuth";
import UserStore from "store/UserStore";
import MiniChatStore from "store/MiniChatStore";
import Box from "@mui/material/Box";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import CatalogStore from "../../../../store/CatalogStore";
import FavouritesStore from "../../../../store/FavouritesStore";
import { useMedias } from "../../../../API/mediasHook";

const CardActionsAlt = observer(({ children, ...props }) => {
    const medias = useMedias();

    const [dialogOpen, SetDialogOpen] = useState(false);

    const handleFavourites = async () => {
        if (!UserStore.isAuth) {
            showDialog();
            return;
        }

        await toggleFavourites();
    };

    const toggleFavourites = async () => {
        await CatalogStore.ToggleFavourites(props.value);
        await FavouritesStore.ToggleLiked(props.value);
    };

    const showDialog = () => {
        SetDialogOpen(true);
    };

    const hideDialog = () => {
        SetDialogOpen(false);
    };

    return (
        <Box sx={themes.actionsContainerStyle}>
            <Box sx={themes.priceContainerStyle}>
                <Box sx={themes.textContainerStyle}>
                    <Typography sx={themes.fromTextStyle}>от</Typography>
                    <Typography sx={themes.fromTextValueStyle}>{props.value.PriceFrom.toLocaleString()} ₽/ч</Typography>
                </Box>
                <ThemeProvider theme={themes.AddCashbackButtonTheme}>
                    <Tooltip
                        title={
                            <Typography sx={themes.tooltipCashbackStyle}>
                                <span>
                                    При обучении у репетитора вам вернётся <span style={themes.tooltipCashbackValueStyle}>{props.value.Cashback} баллов </span>на бонусный счёт
                                </span>
                            </Typography>
                        }
                        arrow={true}
                        placement="right"
                    >
                        <Button
                            onClick={() => {
                                console.log("clicked");
                            }}
                        >
                            <Typography style={themes.cashbackTextStyle}>+{props.value.Cashback}</Typography>
                        </Button>
                    </Tooltip>
                </ThemeProvider>
            </Box>
            <ThemeProvider theme={props.value.Liked ? themes.LikeIconActiveTheme(medias) : themes.LikeIconInactiveTheme(medias)}>
                <IconButton
                    onClick={handleFavourites}
                    disableRipple={true}
                >
                    {props.value.Liked ? <Favorite></Favorite> : <FavoriteBorder></FavoriteBorder>}
                </IconButton>
            </ThemeProvider>
            <ModalNotAuth
                open={dialogOpen}
                handleClose={hideDialog}
                value={props.value}
                header={"Зарегистрируйтесь, чтобы добавить в избранное"}
                content={"Вы сможете добавлять понравившихся репетиторов в избранное, чтобы не потерять их"}
            ></ModalNotAuth>
        </Box>
    );
});

export default memo(CardActionsAlt);
