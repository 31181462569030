import React from "react";
const OKLogo = ({ hover }) => {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="OK">
                <path
                    id="Combined-Shape"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18ZM17.9878 18.289C20.6421 18.289 22.7997 16.206 22.7997 13.6452C22.7997 11.0836 20.6421 9 17.9878 9C15.3341 9 13.1758 11.0836 13.1758 13.6452C13.1758 16.206 15.3341 18.289 17.9878 18.289ZM22.7412 20.9633C21.8711 21.4908 20.9265 21.8648 19.947 22.0802L22.637 24.6771C23.188 25.208 23.188 26.0701 22.637 26.6015C22.0865 27.1328 21.1942 27.1328 20.6443 26.6015L17.9995 24.0496L15.357 26.6015C15.0815 26.8669 14.7204 26.9997 14.3594 26.9997C13.9989 26.9997 13.6384 26.8669 13.3629 26.6015C12.8124 26.0701 12.8124 25.2085 13.3623 24.6771L16.0526 22.0802C15.0731 21.8648 14.1285 21.4902 13.2584 20.9633C12.5999 20.5625 12.4021 19.7229 12.817 19.0867C13.2308 18.4497 14.1008 18.258 14.7604 18.6587C16.7304 19.8549 19.2681 19.8552 21.2391 18.6587C21.8988 18.258 22.7685 18.4497 23.1831 19.0867C23.598 19.7223 23.3997 20.5625 22.7412 20.9633Z"
                    fill={hover ? "#8141FF" : "#6212FF"}
                />
                <path
                    id="Path"
                    d="M17.9875 11.7223C19.0862 11.7223 19.9799 12.5847 19.9799 13.6452C19.9799 14.7049 19.0862 15.5679 17.9875 15.5679C16.8897 15.5679 15.9951 14.7049 15.9951 13.6452C15.9951 12.5847 16.8897 11.7223 17.9875 11.7223Z"
                    fill={hover ? "#8141FF" : "#6212FF"}
                />
            </g>
        </svg>
    );
};

export default OKLogo;
