import React, { useEffect, useRef, useState } from "react";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import TeacherStepSwitcher from "components/TeacherQuestionnaireBodyBlock/StepSwitcher/TeacherStepSwitcher";
import { observer } from "mobx-react-lite";
import * as themes from "./TeacherStepperThemes";
import * as textThemes from "themes/TextThemes";
import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";

const TeacherStepper = observer(({ props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const [currentStep, setCurrentStep] = useState(0);

    const [rulesHover, setRulesHover] = useState(false);

    const handleStepClick = (step) => {
        if (TeacherQuestionnaireStore.steps[`step${step}`] === 1) {
            setCurrentStep(step);
        }
    };

    const renderSteps = () => {
        const steps = [];

        for (let i = 0; i <= 9; i++) {
            const isActive = currentStep === i;

            const isPrev = TeacherQuestionnaireStore.steps[`step${i}`] === 1;

            const isFut = TeacherQuestionnaireStore.steps[`step${i}`] === 0;

            const stepClassName = `teacherQStep${isActive ? "Active" : isPrev ? "Prev" : isFut ? "Next" : ""}`;

            const circleClassName = `stepCircle${isActive ? "Active" : isPrev ? "Prev" : "Next"}`;

            const stepStyle = isActive ? themes.styles.teacherQStepActive : isPrev ? themes.styles.teacherQStepPrev : themes.styles.teacherQStepNext;

            const circleStyle = isActive ? themes.styles.stepCircleActive : isPrev ? themes.styles.stepCirclePrev : themes.styles.stepCircleNext;

            const stepRef = useRef(null);

            steps.push(
                <Box
                    key={i}
                    className={stepClassName}
                    sx={[stepStyle, themes.NoWrapText]}
                    onClick={() => handleStepClick(i)}
                    ref={stepRef}
                >
                    <Typography
                        component="span"
                        className={circleClassName}
                        style={circleStyle}
                    >
                        {i + 1}
                    </Typography>
                    {t(TeacherQuestionnaireStore.teacherQuestionnaireSteps[i])}
                </Box>,
            );
        }

        useEffect(() => {
            if (currentStep >= 0 && steps[currentStep] && steps[currentStep].ref.current) {
                steps[currentStep].ref.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
            }
        }, [currentStep, steps]);

        return steps;
    };

    return (
        <Box sx={themes.TeacherStepper(medias)}>
            <Box sx={themes.TeacherStepperMain(medias)}>
                {medias.sm && (
                    <Box sx={themes.TeacherStepperHeader}>
                        <Typography sx={textThemes.Button16Black}>{t("TeacherQuestionnaire.fillingOutQuestionnaire")}</Typography>
                    </Box>
                )}
                <Box sx={themes.TeacherStepIndicator(medias)}>{renderSteps()}</Box>
                {medias.sm && (
                    <Box sx={themes.TeacherStepperSxBRules}>
                        <Typography
                            onClick={() => window.open("/buddyrules", "_blank")}
                            sx={rulesHover ? { ...textThemes.Button16Purple, cursor: "pointer" } : textThemes.Button16Black}
                            onMouseEnter={() => setRulesHover(true)}
                            onMouseLeave={() => setRulesHover(false)}
                        >
                            {t("TeacherQuestionnaire.serviceRules")}
                        </Typography>
                    </Box>
                )}
            </Box>
            <TeacherStepSwitcher
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
            />
        </Box>
    );
});

export default memo(TeacherStepper);
