export const messageAttachmentsContainerStyle = {
    width: "100%",
    height: "220px",
    borderRadius: "20px",
};

export const singleRowContainerStyle = {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    width: "100%",
    height: "100%",
};

export const twoRowsContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    width: "100%",
    height: "220px",
};

export const firstRowContainerStyle = {
    width: "100%",
    height: "67.5%",
};

export const secondRowContainerStyle = {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    width: "100%",
    height: "30%",
};
