import { styleProcessing } from "API/plugins/Utilities";

export const Card = (theme) => {
    let commonStyle = {
        width: "392px",
        height: "fit-content", //'284px',
        // gap:'14px',
        display: "inline-flex",
        flexDirection: "column",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "328px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const CardHeaderBlock = {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    gap: "14px",
};

export const CardHeaderTextBlock = {
    display: "inline-flex",
    flexDirection: "column",
    gap: "4px",
};

export const TutorName = {
    color: "#222",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
};

export const TutorSubject = {
    color: "var(--text-grey, #9E9EB0)",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
};

export const ReviewerName = {
    color: "#222",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    height: "17px",
    marginTop: "14px",
};

export const Stars = {
    marginTop: "4px",
};

export const CardText = (theme) => {
    let commonStyle = {
        width: "389px",
        height: "fit-content",
        color: "#222",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "150%" /* 21px */,
        marginTop: "14px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "328px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
