import React from "react";
import TutorClassesStore from "store/TutorClassesStore";
import { observer } from "mobx-react-lite";
import * as themes from "./NextClassThemes";
import { Avatar, Box, Typography } from "@mui/material";
import Message from "assets/PAAssets/SVG/message";
import SButton from "components/UI/SButton";
import MiniChatStore from "store/MiniChatStore";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import RegularStudentIcon from "assets/TeacherClassesAssets/SVG/RegularStudentIcon";
import NewStudentIcon from "assets/TeacherClassesAssets/SVG/NewStudentIcon";
import StartClassButton from "./StartClassButton/StartClassButton";
import { memo } from "react";
import { useMedias } from "API/mediasHook";

const NextClass = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const getClassTimeRange = () => {
        const timeRange = TutorClassesStore.getTimeDifference(TutorClassesStore.nextClass);

        return `${timeRange} ${t("main.min")}`;
    };

    const getNextClassTime = () => {
        const classTime = TutorClassesStore.formatClassDate(TutorClassesStore.nextClass.timeStart);

        return `${t(classTime.dayPart).charAt(0).toUpperCase() + t(classTime.dayPart).slice(1)}, ${classTime.dateDay} ${t(classTime.dateMonth)} ${t("main.at")} ${classTime.timePart}`;
    };

    const showDialog = () => {
        MiniChatStore.SetOpen(true);
    };

    return (
        <Box sx={themes.NextClass(TutorClassesStore.startClassButtonDisabled(), medias)}>
            <Box sx={themes.TopBlock}>
                <Box sx={{ display: "flex" }}>
                    {medias.sm && <Typography sx={textThemes.HeadlineH5Black}>{t("teacherClasses.nextClass")}</Typography>}
                    <Typography sx={medias.sm ? textThemes.HeadlineH5Black : textThemes.Body16w600}>{getNextClassTime()}</Typography>
                </Box>
                <Box sx={themes.SubjectAimContainer}>
                    <Typography sx={medias.sm ? textThemes.Body1Black : textThemes.Body2Black}>{TutorClassesStore.nextClass.subject}</Typography>
                    <Typography sx={medias.sm ? textThemes.Body2DarkGrey : textThemes.Caption2DarkGrey}>{`${TutorClassesStore.nextClass.aim}, ${getClassTimeRange()}`}</Typography>
                </Box>
            </Box>
            <Box sx={themes.BottomBlock}>
                <Box sx={themes.StudentBlock(medias)}>
                    <Avatar
                        src={TutorClassesStore.nextClass.studentAvatar}
                        sx={themes.Avatar}
                    />
                    {TutorClassesStore.nextClass.studentType === "constant" ? (
                        <Box sx={themes.StudentTextBox}>
                            <Box sx={themes.StudentNameBox}>
                                <Typography sx={textThemes.Body2Black}>{TutorClassesStore.nextClass.studentName}</Typography>
                                <Box sx={themes.IconBox}>
                                    <RegularStudentIcon />
                                </Box>
                            </Box>
                            <Typography sx={textThemes.Caption2DarkGrey}>{t("teacherClasses.constantStudent")}</Typography>
                        </Box>
                    ) : TutorClassesStore.nextClass.studentType === "new" ? (
                        <Box sx={themes.StudentTextBox}>
                            <Box sx={themes.StudentNameBox}>
                                <Typography sx={textThemes.Body2Black}>{TutorClassesStore.nextClass.studentName}</Typography>
                                <Box sx={themes.IconBox}>
                                    <NewStudentIcon />
                                </Box>
                            </Box>
                            <Typography sx={textThemes.Caption2DarkGrey}>{t("teacherClasses.newStudent")}</Typography>
                        </Box>
                    ) : (
                        <Typography sx={textThemes.Body2Black}>{TutorClassesStore.nextClass.studentName}</Typography>
                    )}
                </Box>
                <Box sx={themes.ButtonsBlock(medias)}>
                    <SButton
                        variant="icon"
                        onClick={showDialog}
                    >
                        <Message />
                    </SButton>
                    {medias.sm && <Box sx={themes.Divider}></Box>}
                    <StartClassButton />
                </Box>
            </Box>
        </Box>
    );
});

export default memo(NextClass);
