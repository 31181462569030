import React, { memo } from "react";
import { Autocomplete, Checkbox, TextField, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { useMedias } from "../../API/mediasHook";
import Box from "@mui/material/Box";

const MultipleChoiceFilter = ({ children, ...props }) => {
    const processing = (event, value) => {
        props.processingChange(event, value);
    };

    let inputRef;

    const CalcText = (values) => {
        let min = Math.min.apply(
            this,
            values.map((x) => x.from),
        );

        //console.log(values.map(x => x.from), min);
        let max = Math.max.apply(
            this,
            values.map((x) => x.to),
        );

        //console.log(max);
        if (min === 0) {
            if (max === Number.POSITIVE_INFINITY) {
                return "от " + min + " ₽";
            } else {
                return "до " + max + " ₽";
            }
        }

        if (max === Number.POSITIVE_INFINITY) {
            return "от " + min + " ₽";
        } else {
            return min + "-" + max + " ₽";
        }
    };

    const medias = useMedias();

    return (
        <ThemeProvider theme={props.themeWrapper}>
            <Autocomplete
                value={props.propOption}
                onChange={processing}
                multiple
                disableCloseOnSelect
                size="small"
                clearText={"Очистить"}
                closeText={"Закрыть"}
                openText={"Открыть"}
                openOnFocus
                options={props.propOptions}
                noOptionsText={"Вариантов не найдено"}
                getOptionLabel={(option) => option[props.valueToShow]}
                isOptionEqualToValue={(value1, value2) => value1[props.valueToBind] === value2[props.valueToBind]}
                renderOption={(propsR, option, { inputValue, selected }) => {
                    const matches = match(option[props.valueToShow], inputValue, { insideWords: true });

                    const parts = parse(option[props.valueToShow], matches);

                    return (
                        <Box
                            {...propsR}
                            sx={props.themeOptionContainer}
                        >
                            <Checkbox
                                disableRipple
                                icon={<CheckBoxOutlineBlank style={props.themeIcon}></CheckBoxOutlineBlank>}
                                checkedIcon={<CheckBox style={props.themeIcon}></CheckBox>}
                                checked={selected}
                                style={props.themeIconContainer}
                            />
                            <Box>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            color: part.highlight ? (selected ? "" : "#6212FF") : "",
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            </Box>
                        </Box>
                    );
                }}
                renderInput={(params) => (
                    <Box>
                        {medias.sm && <Typography style={props.themeLabel}>{props.label}</Typography>}
                        <TextField
                            {...params}
                            inputRef={(input) => {
                                inputRef = input;
                            }}
                            placeholder={props.propOption?.length > 0 ? "" : props.placeholder}
                        />
                    </Box>
                )}
                renderTags={(value) => (
                    <Box
                        onClick={() => {
                            inputRef.blur();
                            inputRef.focus();
                        }}
                        sx={props.themeSelectedPart}
                    >
                        {CalcText(value)}
                    </Box>
                )}
            />
        </ThemeProvider>
    );
};

export default memo(MultipleChoiceFilter);
