import { makeAutoObservable } from "mobx";
import RequestService from "API/services/RequestService";

const requestService = new RequestService();

class OrdersResponsesStore {
    ordersArr = [];
    ordersArrFiltered = [];

    myResponses = {};

    sortByOptions = [];
    sortByOption = null;

    timerID = null;

    constructor() {
        this.Init();
        makeAutoObservable(this);
    }

    debounce(callback, delay) {
        return (...args) => {
            if (this.timerID) {
                clearTimeout(this.timerID);
            }

            this.timerID = setTimeout(() => {
                callback.call(this, ...args);
            }, delay);
        };
    }

    SetSortBy(value) {
        console.log(value);
        this.sortByOption = value;
    }

    SortOrders() {
        this.ordersArrFiltered = this.ordersArrFiltered.sort((a, b) => {
            if (this.sortByOption.sortValue === "OrderDate") {
                return new Date(b[this.sortByOption.sortValue]) - new Date(a[this.sortByOption.sortValue]);
            }

            if (this.sortByOption.sortValue === "Price") {
                if (this.sortByOption?.sortDirection) {
                    if (this.sortByOption.sortDirection === "desc") {
                        return b[this.sortByOption.sortValue][`${this.sortByOption.sortValue}From`] - a[this.sortByOption.sortValue][`${this.sortByOption.sortValue}From`];
                    } else {
                        return a[this.sortByOption.sortValue][`${this.sortByOption.sortValue}From`] - b[this.sortByOption.sortValue][`${this.sortByOption.sortValue}From`];
                    }
                } else {
                    return a[this.sortByOption.sortValue][`${this.sortByOption.sortValue}From`] - b[this.sortByOption.sortValue][`${this.sortByOption.sortValue}From`];
                }
            }

            if (this.sortByOption.sortValue === "IsPersonal") {
                return b[this.sortByOption.sortValue] - a[this.sortByOption.sortValue];
            }
        });
    }

    RemoveResponseFromList(value) {
        this.ordersArr = this.ordersArr.filter((x) => x.ID !== value.ID);
        this.ordersArrFiltered = [...this.ordersArr];
        this.SortOrders();
    }

    Init() {
        this.Load();
    }

    Load() {
        this.sortByOptions = [
            { ID: 0, sortBy: "Сначала новые", sortValue: "OrderDate" },
            { ID: 1, sortBy: "Сначала дорогие", sortValue: "Price", sortDirection: "desc" },
            { ID: 2, sortBy: "Сначала персональные", sortValue: "IsPersonal" },
        ];
        this.sortByOption = this.sortByOptions[0];

        this.ordersArr = [
            {
                ID: 0,
                _id: "64902a483594e963dd20818a",
                OrderType: 0,
                OrderTitle: "Репетитор по английскому языку",
                AvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
                IsVerified: true,
                RegistrationDate: new Date(2022, 12, 19),
                Price: {
                    PriceFrom: 9000,
                    PriceTo: 9900,
                },
                SeenByTeacher: true,
                ReviewsCount: 7,
                ViewedCount: 120,
                IsPersonal: true,
                Tags: [
                    {
                        ID: 0,
                        Query: "Подготовка к экзамену",
                    },
                    {
                        ID: 1,
                        Query: "2 курс",
                    },
                    {
                        ID: 2,
                        Query: "МГИМО",
                    },
                    {
                        ID: 3,
                        Query: "Английский язык",
                    },
                    {
                        ID: 4,
                        Query: "Английский язык 1",
                    },
                    {
                        ID: 5,
                        Query: "Английский язык 2",
                    },
                    {
                        ID: 6,
                        Query: "Английский язык 3",
                    },
                ],
                Location: {
                    Type: "У репетитора",
                    Stations: [
                        {
                            ID: 0,
                            Location: "Комсомольская",
                        },
                        {
                            ID: 1,
                            Location: "Красные ворота",
                        },
                        {
                            ID: 2,
                            Location: "Красносельская",
                        },
                    ],
                },
                ScheduleContent: "вт, чт 12:00–18:00; сб 18:00–24:00",
                Comment: "",
                FileAttachments: [],
                StudentFIO: "Анна А.",
                LastSeen: "54 мин. назад",
                OrderDate: new Date(2022, 12, 19, 12, 57),
            },
            {
                ID: 1,
                _id: "44902a483594e963dd20818a",
                OrderType: 1,
                OrderTitle: "Написание дипломной работы",
                AvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
                IsVerified: true,
                RegistrationDate: new Date(2022, 12, 19),
                Price: {
                    PriceFrom: 12000,
                    PriceTo: null,
                },
                SeenByTeacher: false,
                ReviewsCount: 7,
                ViewedCount: 120,
                IsPersonal: false,
                Tags: [
                    {
                        ID: 0,
                        Query: "Дипломная работа",
                    },
                    {
                        ID: 1,
                        Query: "Английский язык",
                    },
                ],
                Theme: "Лексический подход при обучении аудированию с целью улучшения навыков коммуникацииЛексический подход при обучении аудированию с целью улучшения навыков коммуникацииЛексический подход при обучении аудированию с целью улучшения навыков коммуникацииЛексический подход при обучении аудированию с целью улучшения навыков коммуникацииЛексический подход при обучении аудированию с целью улучшения навыков коммуникацииЛексический подход при обучении аудированию с целью улучшения навыков коммуникации",
                Deadline: "20 мая",
                Comment: "«Курсовая должна соответствовать всем требованиям. Прикрепляю документ»",
                FileAttachments: [
                    {
                        ID: 0,
                        FileName: "Курсовая по английскому. Требования.doc",
                        FileSize: "3.2MB",
                    },
                ],
                StudentFIO: "Виктория Б.",
                LastSeen: "3 часа назад",
                OrderDate: new Date(2022, 12, 18, 12, 57),
            },
            {
                ID: 2,
                _id: "34902a483594e963dd20818a",
                OrderType: 0,
                OrderTitle: "Репетитор по английскому языку",
                AvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
                IsVerified: true,
                RegistrationDate: new Date(2022, 12, 19),
                Price: {
                    PriceFrom: null,
                    PriceTo: null,
                },
                SeenByTeacher: true,
                ReviewsCount: 7,
                ViewedCount: 120,
                IsPersonal: false,
                Tags: [
                    {
                        ID: 0,
                        Query: "Подготовка к экзамену",
                    },
                    {
                        ID: 1,
                        Query: "2 курс",
                    },
                    {
                        ID: 2,
                        Query: "МГИМО",
                    },
                    {
                        ID: 3,
                        Query: "Английский язык",
                    },
                ],
                Location: {
                    Type: "У репетитора",
                    Stations: [
                        {
                            ID: 0,
                            Location: "Комсомольская",
                        },
                        {
                            ID: 1,
                            Location: "Красные ворота",
                        },
                        {
                            ID: 2,
                            Location: "Красносельская",
                        },
                    ],
                },
                ScheduleContent: "вт, чт 12:00–18:00; сб 18:00–24:00",
                Comment:
                    "«Ищу репетитора для дочки 16 лет, задача - ЗНО и получить сертификат минимум В2 IELTS для поступления в Университет Варшавы - SWPS. Ищу репетитора для дочки 16 лет, задача - ЗНО и получить сертификат минимум В2 IELTS для поступления»",
                FileAttachments: [],
                StudentFIO: "Анна А.",
                LastSeen: "54 мин. назад",
                OrderDate: new Date(2022, 12, 17, 12, 57),
            },
            {
                ID: 3,
                _id: "24902a483594e963dd20818a",
                OrderType: 1,
                OrderTitle: "Написание дипломной работы",
                AvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
                IsVerified: true,
                RegistrationDate: new Date(2022, 12, 19),
                Price: {
                    PriceFrom: 12000,
                    PriceTo: null,
                },
                SeenByTeacher: false,
                ReviewsCount: 7,
                ViewedCount: 120,
                IsPersonal: false,
                Tags: [
                    {
                        ID: 0,
                        Query: "Дипломная работа",
                    },
                    {
                        ID: 1,
                        Query: "Английский язык",
                    },
                ],
                Theme: "Лексический подход при обучении аудированию с целью улучшения навыков коммуникации",
                Deadline: "20 мая",
                Comment: "",
                FileAttachments: [],
                StudentFIO: "Виктория Б.",
                LastSeen: "3 часа назад",
                OrderDate: new Date(2021, 12, 19, 12, 57),
            },
            {
                ID: 4,
                _id: "14902a483594e963dd20818a",
                OrderType: 0,
                OrderTitle: "Репетитор по английскому языку",
                AvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
                IsVerified: true,
                RegistrationDate: new Date(2022, 12, 19),
                Price: {
                    PriceFrom: 9000,
                    PriceTo: 9900,
                },
                SeenByTeacher: true,
                ReviewsCount: 7,
                ViewedCount: 120,
                IsPersonal: true,
                Tags: [
                    {
                        ID: 0,
                        Query: "Подготовка к экзамену",
                    },
                    {
                        ID: 1,
                        Query: "2 курс",
                    },
                    {
                        ID: 2,
                        Query: "МГИМО",
                    },
                    {
                        ID: 3,
                        Query: "Английский язык",
                    },
                ],
                Location: {
                    Type: "У репетитора",
                    Stations: [
                        {
                            ID: 0,
                            Location: "Комсомольская",
                        },
                        {
                            ID: 1,
                            Location: "Красные ворота",
                        },
                        {
                            ID: 2,
                            Location: "Красносельская",
                        },
                    ],
                },
                ScheduleContent: "вт, чт 12:00–18:00; сб 18:00–24:00",
                Comment: "",
                FileAttachments: [],
                StudentFIO: "Анна А.",
                LastSeen: "54 мин. назад",
                OrderDate: new Date(2021, 12, 15, 12, 57),
            },
        ];

        this.ordersArrFiltered = [...this.ordersArr];

        this.SortOrders();

        this.myResponses = {
            Headers: [
                { ID: 0, HeaderText: "Заказ" },
                { ID: 1, HeaderText: "Твоя цена" },
                { ID: 2, HeaderText: "Время отклика" },
                { ID: 3, HeaderText: "Статус" },
            ],
            Data: [
                {
                    ID: 0,
                    _id: "64902a483594e963dd20818a",
                    OrderType: 1,
                    OrderTitle: "Написание дипломной работы",
                    Theme: "Лексический подход при обучении аудированию с целью улучшения навыков коммуникации",
                    Deadline: "20 мая",
                    MyPrice: null,
                    ResponseDate: "сегодня",
                    Status: {
                        ID: 0,
                        Name: "ожидание",
                    },
                },
                {
                    ID: 1,
                    _id: "64902a483594e963dd20818a",
                    OrderType: 0,
                    OrderTitle: "ЕГЭ по английскому языку",
                    Tags: [
                        {
                            ID: 0,
                            Query: "Подготовка к экзамену",
                        },
                        {
                            ID: 1,
                            Query: "2 курс",
                        },
                        {
                            ID: 2,
                            Query: "МГИМО",
                        },
                        {
                            ID: 3,
                            Query: "Английский язык",
                        },
                    ],
                    Location: {
                        Type: "У репетитора",
                        Stations: [
                            {
                                ID: 0,
                                Location: "Комсомольская",
                            },
                            {
                                ID: 1,
                                Location: "Красные ворота",
                            },
                            {
                                ID: 2,
                                Location: "Красносельская",
                            },
                        ],
                    },
                    MyPrice: 1500,
                    ResponseDate: new Date(2021, 12, 15, 12, 57),
                    Status: {
                        ID: 2,
                        Name: "отклонен",
                    },
                },
                {
                    ID: 2,
                    _id: "64902a483594e963dd20818a",
                    OrderType: 0,
                    OrderTitle: "Репетитор по английскому языку",
                    Tags: [
                        {
                            ID: 0,
                            Query: "Подготовка к экзамену",
                        },
                        {
                            ID: 1,
                            Query: "2 курс",
                        },
                        {
                            ID: 2,
                            Query: "МГИМО",
                        },
                        {
                            ID: 3,
                            Query: "Английский язык",
                        },
                    ],
                    Location: {
                        Type: "У репетитора",
                        Stations: [
                            {
                                ID: 0,
                                Location: "Комсомольская",
                            },
                            {
                                ID: 1,
                                Location: "Красные ворота",
                            },
                            {
                                ID: 2,
                                Location: "Красносельская",
                            },
                        ],
                    },
                    MyPrice: 2000,
                    ResponseDate: new Date(2021, 12, 15, 12, 57),
                    Status: {
                        ID: 1,
                        Name: "выбран",
                    },
                },
            ],
        };
    }
}

export default new OrdersResponsesStore();
