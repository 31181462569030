import { styleProcessing } from "API/plugins/Utilities";

export const ModalCancelClass = (theme) => {
    let commonStyle = {
        padding: "20px 0 30px 0",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "0px",
            paddingBottom: "16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const RescheduleClass = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    paddingBottom: "20px",
    borderBottom: "1px dashed #DADADF",
};

export const ClassTime = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
};

export const ButtonContainer = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "12px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            justifyContent: "space-between",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const CancelReason = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
};

export const Button = {
    width: "194px",
};

export const ButtonHoldLesson = (theme) => {
    let commonStyle = {
        width: "194px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "106px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const RescheduleButton = {
    cursor: "pointer",
};
