/**
 * @fileoverview gRPC-Web generated client stub for pb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.6.1
// source: typical-info.proto

/* eslint-disable */
// @ts-nocheck

const grpc = {};
grpc.web = require("grpc-web");

const proto = {};
proto.pb = require("./typical-info_pb.js");

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.TypicalInfoServiceClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options.format = "text";

    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */
    this.client_ = new grpc.web.GrpcWebClientBase(options);

    /**
     * @private @const {string} The hostname
     */
    this.hostname_ = hostname.replace(/\/+$/, "");
};

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.TypicalInfoServicePromiseClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options.format = "text";

    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */
    this.client_ = new grpc.web.GrpcWebClientBase(options);

    /**
     * @private @const {string} The hostname
     */
    this.hostname_ = hostname.replace(/\/+$/, "");
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.GetCitiesRequest,
 *   !proto.pb.GetCitiesResponse>}
 */
const methodDescriptor_TypicalInfoService_GetCities = new grpc.web.MethodDescriptor(
    "/pb.TypicalInfoService/GetCities",
    grpc.web.MethodType.UNARY,
    proto.pb.GetCitiesRequest,
    proto.pb.GetCitiesResponse,
    /**
     * @param {!proto.pb.GetCitiesRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.GetCitiesResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.GetCitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.GetCitiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.GetCitiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.TypicalInfoServiceClient.prototype.getCities = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.TypicalInfoService/GetCities", request, metadata || {}, methodDescriptor_TypicalInfoService_GetCities, callback);
};

/**
 * @param {!proto.pb.GetCitiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.GetCitiesResponse>}
 *     Promise that resolves to the response
 */
proto.pb.TypicalInfoServicePromiseClient.prototype.getCities = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.TypicalInfoService/GetCities", request, metadata || {}, methodDescriptor_TypicalInfoService_GetCities);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.GetDistrictsAndUndergroundStationsByCityRequest,
 *   !proto.pb.GetDistrictsAndUndergroundStationsByCityResponse>}
 */
const methodDescriptor_TypicalInfoService_GetDistrictsAndUndergroundStationsByCity = new grpc.web.MethodDescriptor(
    "/pb.TypicalInfoService/GetDistrictsAndUndergroundStationsByCity",
    grpc.web.MethodType.UNARY,
    proto.pb.GetDistrictsAndUndergroundStationsByCityRequest,
    proto.pb.GetDistrictsAndUndergroundStationsByCityResponse,
    /**
     * @param {!proto.pb.GetDistrictsAndUndergroundStationsByCityRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.GetDistrictsAndUndergroundStationsByCityResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.GetDistrictsAndUndergroundStationsByCityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.GetDistrictsAndUndergroundStationsByCityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.GetDistrictsAndUndergroundStationsByCityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.TypicalInfoServiceClient.prototype.getDistrictsAndUndergroundStationsByCity = function (request, metadata, callback) {
    return this.client_.rpcCall(
        this.hostname_ + "/pb.TypicalInfoService/GetDistrictsAndUndergroundStationsByCity",
        request,
        metadata || {},
        methodDescriptor_TypicalInfoService_GetDistrictsAndUndergroundStationsByCity,
        callback,
    );
};

/**
 * @param {!proto.pb.GetDistrictsAndUndergroundStationsByCityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.GetDistrictsAndUndergroundStationsByCityResponse>}
 *     Promise that resolves to the response
 */
proto.pb.TypicalInfoServicePromiseClient.prototype.getDistrictsAndUndergroundStationsByCity = function (request, metadata) {
    return this.client_.unaryCall(
        this.hostname_ + "/pb.TypicalInfoService/GetDistrictsAndUndergroundStationsByCity",
        request,
        metadata || {},
        methodDescriptor_TypicalInfoService_GetDistrictsAndUndergroundStationsByCity,
    );
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.GetSubjectsRequest,
 *   !proto.pb.GetSubjectsResponse>}
 */
const methodDescriptor_TypicalInfoService_GetSubjects = new grpc.web.MethodDescriptor(
    "/pb.TypicalInfoService/GetSubjects",
    grpc.web.MethodType.UNARY,
    proto.pb.GetSubjectsRequest,
    proto.pb.GetSubjectsResponse,
    /**
     * @param {!proto.pb.GetSubjectsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.GetSubjectsResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.GetSubjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.GetSubjectsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.GetSubjectsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.TypicalInfoServiceClient.prototype.getSubjects = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.TypicalInfoService/GetSubjects", request, metadata || {}, methodDescriptor_TypicalInfoService_GetSubjects, callback);
};

/**
 * @param {!proto.pb.GetSubjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.GetSubjectsResponse>}
 *     Promise that resolves to the response
 */
proto.pb.TypicalInfoServicePromiseClient.prototype.getSubjects = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.TypicalInfoService/GetSubjects", request, metadata || {}, methodDescriptor_TypicalInfoService_GetSubjects);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.GetConditionsBySubjectRequest,
 *   !proto.pb.GetConditionsBySubjectResponse>}
 */
const methodDescriptor_TypicalInfoService_GetConditionsBySubject = new grpc.web.MethodDescriptor(
    "/pb.TypicalInfoService/GetConditionsBySubject",
    grpc.web.MethodType.UNARY,
    proto.pb.GetConditionsBySubjectRequest,
    proto.pb.GetConditionsBySubjectResponse,
    /**
     * @param {!proto.pb.GetConditionsBySubjectRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.GetConditionsBySubjectResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.GetConditionsBySubjectRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.GetConditionsBySubjectResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.GetConditionsBySubjectResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.TypicalInfoServiceClient.prototype.getConditionsBySubject = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.TypicalInfoService/GetConditionsBySubject", request, metadata || {}, methodDescriptor_TypicalInfoService_GetConditionsBySubject, callback);
};

/**
 * @param {!proto.pb.GetConditionsBySubjectRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.GetConditionsBySubjectResponse>}
 *     Promise that resolves to the response
 */
proto.pb.TypicalInfoServicePromiseClient.prototype.getConditionsBySubject = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.TypicalInfoService/GetConditionsBySubject", request, metadata || {}, methodDescriptor_TypicalInfoService_GetConditionsBySubject);
};

module.exports = proto.pb;
