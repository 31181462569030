export const TextStyles = {
    "& fieldset": { border: "none" },
    "& .MuiOutlinedInput-root": {
        marginLeft: "auto",
        marginRight: "auto",
        padding: "0.55vh 0.8vw 0.55vh 0.8vw",
        backgroundColor: "rgba(187, 187, 200, 0.17)",
        borderRadius: "1.2vh",
        width: "22.7vw",
        height: "5.4vh",
        fontSize: "2.1vh",
    },
};

export const ChatBox = {
    maxHeight: "57vh",
    width: "22.7vw",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "2.6vh",
    border: "1px solid rgba(187, 187, 200, 0.30)",
    borderRadius: "1.2vh",
};

export const ChatItem = {
    borderTop: "1px solid #EEEEF0",
    padding: "1.1vh 0.9vw 1.1vh 0.9vw",
    display: "flex",
    fontSize: "2.15vh",
};

export const PaperStyles = {
    width: "11.7vw",
    backgroundColor: "white",
    borderRadius: "1.2vh",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
    border: "none",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
    p: 0,
};

export const MenuItem = {
    borderRadius: "1vh",
    height: "4.42vh",
    fontSize: "2vh",
    color: "red",
};

export const Folder = {
    fontSize: "2.2vh",
    textAlign: "center",
    marginTop: "1.28vh",
};

export const ButtonGrid = {
    marginTop: "0.2vh",
    marginLeft: "0.65vw",
};
