export const TopContentBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    textAlign: "center",
    alignItems: "center",
};

export const WeSentInstructions = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    textAlign: "center",
    alignItems: "center",
};
