import React, { memo } from "react";
import * as theme from "./SearchLineStyle";
import { Typography } from "@mui/material";
import MainPageStore from "store/MainPageStore";
import TextFilter from "components/UI/TextFilter";
import { observer } from "mobx-react-lite";
import { useMedias } from "API/mediasHook";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const SearchLine = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const medias = useMedias();

    const search = async () => {
        await MainPageStore.ExecuteSearch();
    };

    const clearSearch = () => {
        MainPageStore.SetSearchDebounced("");
    };

    const handleSearch = (e) => {
        MainPageStore.SetSearchDebounced(e.target.value);
    };

    return (
        <Box sx={theme.filtersBlockMainRow(medias)}>
            <Box sx={theme.filtersDropdownsBlock(medias)}>
                <TextFilter
                    valueToBind={t(MainPageStore.search)}
                    handleFunc={handleSearch}
                    clearFunc={clearSearch}
                    variant={"outlined"}
                    placeholder={t("promoPages.main.findServiceOrSpecialist")}
                    themeWrapper={theme.FiltersSearcherTheme}
                />
            </Box>

            <Box
                onClick={search}
                sx={theme.filtersSearchBtn(medias)}
            >
                <Typography sx={{ paddingRight: "4px" }}>{t("promoPages.main.Search")}</Typography>
            </Box>
        </Box>
    );
});

export default memo(SearchLine);
