import * as themes from "./ModalWeSentInstructionsThemes";
import * as textThemes from "themes/TextThemes";
import { memo } from "react";
import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import { WeSentInstructionsIcon } from "assets/Authorization/SVG/WeSentInstructionsIcon";
import { Typography, Box } from "@mui/material";
import SButton from "components/UI/SButton";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import AuthorizationStore from "store/AuthorizationStore";
import { observer } from "mobx-react-lite";

const ModalWeSentInstructions = observer(({ children }) => {
    const { t, i18n } = useTranslation();

    const [disabled, setDisabled] = useState(false);

    const handleClose = () => {
        AuthorizationStore.setShowSendInstructions(false);
    };

    const handleClickResend = () => {
        // props.clickResend();
        AuthorizationStore.setShowSendInstructions(false);
        setDisabled(true);
    };

    return (
        <ModalSettingsSmall
            visible={AuthorizationStore.showSendInstructions}
            handleClose={handleClose}
        >
            <Box sx={themes.WeSentInstructions}>
                <Box sx={themes.TopContentBlock}>
                    <WeSentInstructionsIcon />
                    <Typography sx={textThemes.HeadlineH41Black}>{t("main.weSentInstructions")}</Typography>
                    <Typography sx={textThemes.Body2Black}>{t("main.ifYouDontGetInstructions")}</Typography>
                </Box>
                <SButton
                    disabled={disabled}
                    variant="text"
                    onClick={handleClickResend}
                >
                    {t("main.sendAgain")}
                </SButton>
            </Box>
        </ModalSettingsSmall>
    );
});

export default memo(ModalWeSentInstructions);
