import { Box, Typography, styled } from "@mui/material";

export const StyledOrderResponseInfoCard = styled(Box)({
    width: "100%",
    margin: "0 auto",
    marginTop: "20px",
    marginBottom: "40px",
    justifyContent: "center",
    padding: "20px 20px 0px 20px ",
    borderRadius: "16px",
    backgroundColor: "#fff",
    boxSizing: "border-box",
});

export const StyledOrderResponseTopWrapper = styled(Box)({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
});

export const StyledOrderResponseTitle = styled(Typography)({
    fontWeight: 600,
    fontSize: "18px",
    position: "relative",
});

export const StyledOrderResponseBottomWrapper = styled(Box)({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
});
