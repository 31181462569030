import { dateRangeInputPhrases } from "@datepicker-react/styled";
import { makeAutoObservable } from "mobx";
import Photo from "assets/PAAssets/PNG/photo.png";

class TutorClassesStore {
    classes = [{}];

    nextClass = null;

    todayClasses = null;

    tomorrowClasses = null;

    todayAndTomorrowDates = null;

    activeClassesTab = 1;

    groupedUpcomingClasses = null;

    groupedPendingClasses = null;

    groupedRescheduledRequests = null;

    groupedConductedClasses = null;

    groupedCancelledClasses = null;

    temporaryRating = null;
    temporaryReview = null;
    temporaryCancelReason = null;
    temporaryRescheduleTimeStart = null;
    temporaryRescheduleTimeEnd = null;
    temporaryRescheduleReason = null;

    currentPage = 1;
    pagesTotal = 22;
    itemsForPage = 2;
    from = 0;
    to = this.itemsForPage;

    constructor() {
        this.Init();
        makeAutoObservable(this);
    }

    setActiveClassesTab(value) {
        this.activeClassesTab = value;
    }

    getClosestUpcomingClass() {
        if (this.classes.filter((classItem) => classItem.classStatus === "upcoming").length > 0) {
            return this.classes
                .filter((classItem) => classItem.classStatus === "upcoming") // Фильтруем только "upcoming"
                .reduce((closest, current) => {
                    return new Date(current.timeStart) < new Date(closest.timeStart) ? current : closest;
                });
        }
    }

    getTimeDifference(Class, type = "default") {
        var diffInMilliseconds = null;

        if ((type = "default")) {
            diffInMilliseconds = Class.timeEnd - Class.timeStart; // Разница в миллисекундах
        } else if ((type = "reschedule")) {
            diffInMilliseconds = Class.timeEndRescheduled - Class.timeStartRescheduled; // Разница в миллисекундах
        }

        const diffInMinutes = Math.floor(diffInMilliseconds / 60000); // Переводим миллисекунды в минуты

        return diffInMinutes;
    }

    startClassButtonDisabled() {
        const classStart = this.nextClass.timeStart;

        const currentTime = new Date();

        const timeDifference = classStart - currentTime;

        const fifteenMinutes = 15 * 60 * 1000;

        return timeDifference > fifteenMinutes;
    }

    formatClassDate(date) {
        const daysOfWeek = [
            "main.daysOfWeek.sunday",
            "main.daysOfWeek.monday",
            "main.daysOfWeek.tuesday",
            "main.daysOfWeek.wednesday",
            "main.daysOfWeek.thursday",
            "main.daysOfWeek.friday",
            "main.daysOfWeek.saturday",
        ];

        const months = [
            "main.months.atJanuary",
            "main.months.atFebruary",
            "main.months.atMarch",
            "main.months.atApril",
            "main.months.atMay",
            "main.months.atJune",
            "main.months.atJuly",
            "main.months.atAugust",
            "main.months.atSeptember",
            "main.months.atOctober",
            "main.months.atNovember",
            "main.months.atDecember",
        ];

        const today = new Date();

        const isToday = today.toDateString() === date.toDateString();

        // Вычисляем завтрашний и послезавтрашний дни
        const tomorrow = new Date(today);

        tomorrow.setDate(today.getDate() + 1);

        const dayAfterTomorrow = new Date(today);

        dayAfterTomorrow.setDate(today.getDate() + 2);

        const isTomorrow = tomorrow.toDateString() === date.toDateString();

        // Определяем, какой день выводить
        let dayPart;

        if (isToday) {
            dayPart = "main.today";
        } else if (isTomorrow) {
            dayPart = "main.tomorrow";
        } else {
            dayPart = `${daysOfWeek[date.getDay()]}`;
        }

        const dateDay = date.getDate();

        const dateMonth = months[date.getMonth()];

        const timePart = date.toLocaleTimeString("ru-RU", { hour: "2-digit", minute: "2-digit" });

        return { dayPart: dayPart, dateDay: dateDay, dateMonth: dateMonth, timePart: timePart };
    }

    filterClassesToday() {
        if (this.classes.length > 0) {
            const today = new Date();

            today.setHours(0, 0, 0, 0); // Устанавливаем начало дня
            const tomorrow = new Date(today);

            tomorrow.setDate(today.getDate() + 1); // Завтра - это следующий день

            // Фильтруем занятия
            const filteredClasses = this.classes.filter((classItem) => {
                const timeStart = classItem.timeStart;

                return classItem.classStatus === "upcoming" && timeStart >= today && timeStart < tomorrow; // Проверяем, что занятие начинается сегодня
            });

            // Возвращаем количество занятий
            return filteredClasses;
        }
    }

    filterClassesTomorrow() {
        if (this.classes.length > 0) {
            const today = new Date();

            today.setHours(0, 0, 0, 0);

            const tomorrowStart = new Date(today);

            tomorrowStart.setDate(today.getDate() + 1);

            const tomorrowEnd = new Date(tomorrowStart);

            tomorrowEnd.setDate(tomorrowStart.getDate() + 1);
            const filteredClasses = this.classes.filter((classItem) => {
                const timeStart = classItem.timeStart;

                return classItem.classStatus === "upcoming" && timeStart >= tomorrowStart && timeStart < tomorrowEnd;
            });

            return filteredClasses;
        }
    }

    getFormattedTodayAndTomorrowDate() {
        const months = [
            "main.months.atJanuary",
            "main.months.atFebruary",
            "main.months.atMarch",
            "main.months.atApril",
            "main.months.atMay",
            "main.months.atJune",
            "main.months.atJuly",
            "main.months.atAugust",
            "main.months.atSeptember",
            "main.months.atOctober",
            "main.months.atNovember",
            "main.months.atDecember",
        ];

        const daysOfWeek = [
            "main.daysOfWeek.sundayShort",
            "main.daysOfWeek.mondayShort",
            "main.daysOfWeek.tuesdayShort",
            "main.daysOfWeek.wednesdayShort",
            "main.daysOfWeek.thursdayShort",
            "main.daysOfWeek.fridayShort",
            "main.daysOfWeek.saturdayShort",
        ];

        const today = new Date();

        const tomorrow = new Date();

        tomorrow.setDate(tomorrow.getDate() + 1);

        const dayToday = today.getDate();

        const monthToday = months[today.getMonth()];

        const dayOfWeekToday = daysOfWeek[today.getDay()];

        const dayTomorrow = tomorrow.getDate();

        const monthTomorrow = months[tomorrow.getMonth()];

        const dayOfWeekTomorrow = daysOfWeek[tomorrow.getDay()];

        return { today: { date: dayToday, month: monthToday, dayOfWeek: dayOfWeekToday }, tomorrow: { date: dayTomorrow, month: monthTomorrow, dayOfWeek: dayOfWeekTomorrow } };
    }

    groupClassesByDay(classes, mode = "default") {
        const today = new Date();

        const formattedToday = `${String(today.getDate()).padStart(2, "0")}.${String(today.getMonth() + 1).padStart(2, "0")}.${today.getFullYear()}`;

        const groupedClasses = classes.reduce((acc, currentClass) => {
            var date = null;

            if (mode === "default" || mode === "upcoming") {
                date = currentClass.timeStart;
            } else if (mode === "reschedule") {
                date = currentClass.rescheduleRequestTime;
            }

            const day = String(date.getDate()).padStart(2, "0"); // Получаем день и добавляем 0, если нужно

            const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяц (нумерация с 0, поэтому +1)

            const year = date.getFullYear(); // Получаем год

            const formatTime = (date) => `${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;

            const time = formatTime(date);

            var formattedDate = null;

            if (mode === "default" || mode === "upcoming") {
                formattedDate = `${day}.${month}.${year}`;
            } else if (mode === "reschedule") {
                formattedDate = `${day}.${month}.${year} ${time}`;
            }

            if (!acc[formattedDate]) {
                acc[formattedDate] = []; // Создаем новый массив, если для этой даты еще нет записей
            }

            acc[formattedDate].push(currentClass); // Добавляем класс в массив соответствующей даты

            return acc;
        }, {});

        const sortObjectByKey = (obj, order = "asc") => {
            // Преобразуем объект в массив пар [ключ, значение]
            const entries = Object.entries(obj);

            // Сортируем массив по ключам
            const sortedEntries = entries.sort(([keyA], [keyB]) => {
                if (order === "asc") {
                    return keyA.localeCompare(keyB); // Сортировка по возрастанию
                } else if (order === "desc") {
                    return keyB.localeCompare(keyA); // Сортировка по убыванию
                }
            });

            // Преобразуем отсортированный массив обратно в объект
            return Object.fromEntries(sortedEntries);
        };

        if (mode === "upcoming") {
            return sortObjectByKey(groupedClasses);
        } else return sortObjectByKey(groupedClasses, "desc");
    }

    formatTimeRange(classItem) {
        const formatTime = (date) => {
            const hours = String(date.getHours()).padStart(2, "0");

            const minutes = String(date.getMinutes()).padStart(2, "0");

            return `${hours}:${minutes}`;
        };

        const startTimeFormatted = formatTime(classItem.timeStart);

        const endTimeFormatted = formatTime(classItem.timeEnd);

        return `${startTimeFormatted}–${endTimeFormatted}`;
    }

    formatDate(dateString) {
        const weekDays = [
            "main.daysOfWeek.sundayShort",
            "main.daysOfWeek.mondayShort",
            "main.daysOfWeek.tuesdayShort",
            "main.daysOfWeek.wednesdayShort",
            "main.daysOfWeek.thursdayShort",
            "main.daysOfWeek.fridayShort",
            "main.daysOfWeek.saturdayShort",
        ];

        const months = [
            "main.months.atJanuary",
            "main.months.atFebruary",
            "main.months.atMarch",
            "main.months.atApril",
            "main.months.atMay",
            "main.months.atJune",
            "main.months.atJuly",
            "main.months.atAugust",
            "main.months.atSeptember",
            "main.months.atOctober",
            "main.months.atNovember",
            "main.months.atDecember",
        ];

        const inputDate = new Date(dateString.split(".").reverse().join("-"));

        const today = new Date();

        const isToday = inputDate.toDateString() === today.toDateString();

        const day = inputDate.getDate();

        const month = months[inputDate.getMonth()];

        const weekday = weekDays[inputDate.getDay()];

        const formattedDate = `${day} ${month}, ${weekday}`;

        return isToday ? { today: true, day: day, month: month, weekday: weekday } : { today: false, day: day, month: month, weekday: weekday };
    }

    formatTodayClassDate(lesson, attribute = "timeStart") {
        const months = [
            "main.months.atJanuary",
            "main.months.atFebruary",
            "main.months.atMarch",
            "main.months.atApril",
            "main.months.atMay",
            "main.months.atJune",
            "main.months.atJuly",
            "main.months.atAugust",
            "main.months.atSeptember",
            "main.months.atOctober",
            "main.months.atNovember",
            "main.months.atDecember",
        ];

        const days = [
            "main.daysOfWeek.sundayShort",
            "main.daysOfWeek.mondayShort",
            "main.daysOfWeek.tuesdayShort",
            "main.daysOfWeek.wednesdayShort",
            "main.daysOfWeek.thursdayShort",
            "main.daysOfWeek.fridayShort",
            "main.daysOfWeek.saturdayShort",
        ];

        const classDate = lesson[attribute];

        const day = days[classDate.getDay()];

        const month = months[classDate.getMonth()];

        const date = classDate.getDate();

        const formattedDate = { date: date, month: month, weekday: day };

        return formattedDate;
    }

    formatTodayClassDateFromDate(dateclass) {
        const months = [
            "main.months.atJanuary",
            "main.months.atFebruary",
            "main.months.atMarch",
            "main.months.atApril",
            "main.months.atMay",
            "main.months.atJune",
            "main.months.atJuly",
            "main.months.atAugust",
            "main.months.atSeptember",
            "main.months.atOctober",
            "main.months.atNovember",
            "main.months.atDecember",
        ];

        const days = [
            "main.daysOfWeek.sundayShort",
            "main.daysOfWeek.mondayShort",
            "main.daysOfWeek.tuesdayShort",
            "main.daysOfWeek.wednesdayShort",
            "main.daysOfWeek.thursdayShort",
            "main.daysOfWeek.fridayShort",
            "main.daysOfWeek.saturdayShort",
        ];

        if (dateclass !== null) {
            const classDate = dateclass;

            const day = days[classDate.getDay()];

            const month = months[classDate.getMonth()];

            const date = classDate.getDate();

            const formattedDate = { date: date, month: month, weekday: day };

            return formattedDate;
        } else return { date: "", month: "", weekday: "" };
    }

    formatRescheduleDate(dateString) {
        const months = [
            "main.months.atJanuary",
            "main.months.atFebruary",
            "main.months.atMarch",
            "main.months.atApril",
            "main.months.atMay",
            "main.months.atJune",
            "main.months.atJuly",
            "main.months.atAugust",
            "main.months.atSeptember",
            "main.months.atOctober",
            "main.months.atNovember",
            "main.months.atDecember",
        ];

        const [datePart, timePart] = dateString.split(" ");

        const [day, month, year] = datePart.split(".").map(Number); // Разделяем дату

        const [hours, minutes] = timePart.split(":").map(Number); // Разделяем время

        // Создаем объект даты с учетом времени
        const date = new Date(year, month - 1, day, hours, minutes);

        const now = new Date();

        const yesterday = new Date(now);

        yesterday.setDate(now.getDate() - 1); // Вчерашняя дата

        const formatTime = (date) => `${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`; // Форматирование времени

        if (date.toDateString() === now.toDateString()) {
            return { today: true, yesterday: false, day: null, month: null, time: formatTime(date) };
        } else if (date.toDateString() === yesterday.toDateString()) {
            return { today: false, yesterday: true, day: null, month: null, time: formatTime(date) };
        } else {
            return { today: false, yesterday: false, day: day, month: months[month - 1], time: formatTime(date) };
        }
    }

    setCancelReason(index, value) {
        this.classes[index].cancelReason = value;
    }

    setCancelled(index) {
        this.classes[index].classStatus = "cancelled";
        this.classes[index].cancellationInitiator = "tutor";
    }

    setCompleted(index) {
        this.classes[index].classStatus = "completed";
    }

    setRescheduleReason(index, value) {
        this.classes[index].rescheduleReason = value;
    }

    setRescheduleTimeStart(index, value) {
        console.log(this.classes[index].rescheduleTimeStart);
        this.classes[index].rescheduleTimeStart = value;
        console.log(this.classes[index].rescheduleTimeStart);
    }

    setRescheduleTimeEnd(index, value) {
        this.classes[index].rescheduleTimeEnd = value;
    }

    setTemporaryRescheduleTimeStart(value) {
        this.temporaryRescheduleTimeStart = value;
    }

    setTemporaryRescheduleTimeEnd(value) {
        this.temporaryRescheduleTimeEnd = value;
    }

    setTemporaryRescheduleReason(value) {
        this.temporaryRescheduleReason = value;
    }

    setRescheduleAttempt(index, value) {
        this.classes[index].rescheduleAttempt = value;
    }

    acceptRescheduleTime(index) {
        this.classes[index].classStatus = "upcoming";
        this.classes[index].timeStart = this.classes[index].rescheduleTimeStart;
        this.classes[index].timeEnd = this.classes[index].rescheduleTimeEnd;
        this.classes[index].rescheduleTimeStart = null;
        this.classes[index].rescheduleTimeEnd = null;
        this.classes[index].rescheduleReason = null;
        this.classes[index].rescheduleRequestTime = null;
    }

    rejectRescheduleTime(index) {
        this.classes[index].classStatus = "upcoming";
        this.classes[index].rescheduleTimeStart = null;
        this.classes[index].rescheduleTimeEnd = null;
        this.classes[index].rescheduleReason = null;
        this.classes[index].rescheduleRequestTime = null;
    }

    setSeenClasses(type) {
        this.classes.forEach((item) => {
            if (item.classStatus === type) {
                item.seen = true;
            }
        });
    }

    Init() {
        this.classes = [
            {
                id: 100000001,
                seen: null,
                classStatus: "upcoming",
                subject: "Английский язык",
                aim: "Подготовка к ЕГЭ",
                timeStart: new Date(new Date().setHours(new Date().getHours() + 1)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() + 2)),
                rescheduleAttempt: 3,
                rescheduleReason: null,
                rescheduleRequestTime: null,
                rescheduleTimeStart: null,
                rescheduleTimeEnd: null,
                studentName: "Марина Л.",
                studentAvatar: Photo,
                studentType: "constant",
                place: "online",
                rating: null,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000002,
                seen: null,
                classStatus: "upcoming",
                subject: "Английский язык",
                aim: "Для работы",
                timeStart: new Date(new Date().setHours(new Date().getHours() + 5)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() + 6)),
                rescheduleAttempt: 3,
                rescheduleReason: null,
                rescheduleRequestTime: null,
                rescheduleTimeStart: null,
                rescheduleTimeEnd: null,
                studentName: "Станислав К.",
                studentAvatar: Photo,
                studentType: null,
                place: "online",
                rating: null,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000003,
                seen: null,
                classStatus: "upcoming",
                subject: "Французский язык",
                aim: "Поступление в ВУЗ",
                timeStart: new Date(new Date().setHours(new Date().getHours() + 6)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() + 7)),
                rescheduleAttempt: 3,
                rescheduleReason: null,
                rescheduleRequestTime: null,
                rescheduleTimeStart: null,
                rescheduleTimeEnd: null,
                studentName: "Мария Н.",
                studentAvatar: Photo,
                studentType: "new",
                place: "tutor",
                rating: null,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000004,
                seen: null,
                classStatus: "upcoming",
                subject: "Английский язык",
                aim: "Поступление в ВУЗ",
                timeStart: new Date(new Date().setHours(new Date().getHours() + 24)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() + 25)),
                rescheduleAttempt: 3,
                rescheduleReason: null,
                rescheduleRequestTime: null,
                rescheduleTimeStart: null,
                rescheduleTimeEnd: null,
                studentName: "Мария М.",
                studentAvatar: Photo,
                studentType: "new",
                place: "tutor",
                rating: null,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000005,
                seen: null,
                classStatus: "upcoming",
                subject: "Английский язык",
                aim: "Для работы",
                timeStart: new Date(new Date().setHours(new Date().getHours() + 25)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() + 26)),
                rescheduleAttempt: 3,
                rescheduleReason: null,
                rescheduleRequestTime: null,
                rescheduleTimeStart: null,
                rescheduleTimeEnd: null,
                studentName: "Мария Д.",
                studentAvatar: null,
                studentType: null,
                place: "online",
                rating: null,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000006,
                seen: null,
                classStatus: "upcoming",
                subject: "Английский язык",
                aim: "Подготовка к ЕГЭ",
                timeStart: new Date(new Date().setHours(new Date().getHours() + 26)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() + 27)),
                rescheduleAttempt: 3,
                rescheduleReason: null,
                rescheduleRequestTime: null,
                rescheduleTimeStart: null,
                rescheduleTimeEnd: null,
                studentName: "Иван Н.",
                studentAvatar: Photo,
                studentType: null,
                place: "online",
                rating: null,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000007,
                seen: null,
                classStatus: "upcoming",
                subject: "Английский язык",
                aim: "Подготовка к ЕГЭ",
                timeStart: new Date(new Date().setHours(new Date().getHours() + 27)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() + 28)),
                rescheduleAttempt: 3,
                rescheduleReason: null,
                rescheduleRequestTime: null,
                rescheduleTimeStart: null,
                rescheduleTimeEnd: null,
                studentName: "Александр Ш.",
                studentAvatar: Photo,
                studentType: "new",
                place: "online",
                rating: null,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000008,
                seen: null,
                classStatus: "upcoming",
                subject: "Французский язык",
                aim: "Для работы",
                timeStart: new Date(new Date().setHours(new Date().getHours() + 45)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() + 46)),
                rescheduleAttempt: 3,
                rescheduleReason: null,
                rescheduleRequestTime: null,
                rescheduleTimeStart: null,
                rescheduleTimeEnd: null,
                studentName: "Кирилл М.",
                studentAvatar: null,
                studentType: null,
                place: "tutor",
                rating: null,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000009,
                seen: null,
                classStatus: "upcoming",
                subject: "Английский язык",
                aim: "Подготовка к кзамену в ВУЗе",
                timeStart: new Date(new Date().setHours(new Date().getHours() + 46)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() + 47)),
                rescheduleAttempt: 3,
                rescheduleReason: null,
                rescheduleRequestTime: null,
                rescheduleTimeStart: null,
                rescheduleTimeEnd: null,
                studentName: "Вероника Л.",
                studentAvatar: Photo,
                studentType: "new",
                place: "tutor",
                rating: null,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000010,
                seen: null,
                classStatus: "upcoming",
                subject: "Английский язык",
                aim: "Подготовка к ЕГЭ",
                timeStart: new Date(new Date().setHours(new Date().getHours() + 47)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() + 48)),
                rescheduleAttempt: 3,
                rescheduleReason: null,
                rescheduleRequestTime: null,
                rescheduleTimeStart: null,
                rescheduleTimeEnd: null,
                studentName: "Елизавета А.",
                studentAvatar: Photo,
                studentType: "constant",
                place: "tutor",
                rating: null,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000011,
                seen: false,
                classStatus: "pending",
                subject: "Английский язык",
                aim: "Для работы",
                timeStart: new Date(new Date().setHours(new Date().getHours() - 3)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() - 2)),
                rescheduleAttempt: 3,
                rescheduleReason: null,
                rescheduleRequestTime: null,
                rescheduleTimeStart: null,
                rescheduleTimeEnd: null,
                studentName: "Станислав К.",
                studentAvatar: Photo,
                studentType: null,
                place: "online",
                rating: null,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000012,
                seen: false,
                classStatus: "pending",
                subject: "Французский язык",
                aim: "Поступление в ВУЗ",
                timeStart: new Date(new Date().setHours(new Date().getHours() - 4)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() - 3)),
                rescheduleAttempt: 3,
                rescheduleReason: null,
                rescheduleRequestTime: null,
                rescheduleTimeStart: null,
                rescheduleTimeEnd: null,
                studentName: "Мария Н.",
                studentAvatar: Photo,
                studentType: "new",
                place: "tutor",
                rating: null,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000013,
                seen: false,
                classStatus: "rescheduled",
                subject: "Английский язык",
                aim: "Поступление в ВУЗ",
                timeStart: new Date(new Date().setHours(new Date().getHours() + 3)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() + 4)),
                rescheduleAttempt: 3,
                rescheduleReason: "Здравствуйте, Елизавета! Здесь должна быть указана причина",
                rescheduleRequestTime: new Date(new Date().setHours(new Date().getHours() - 1)),
                rescheduleTimeStart: new Date(new Date().setHours(new Date().getHours() + 23)),
                rescheduleTimeEnd: new Date(new Date().setHours(new Date().getHours() + 24)),
                studentName: "Елизавета А.",
                studentAvatar: Photo,
                studentType: "constant",
                place: "online",
                rating: null,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000014,
                seen: false,
                classStatus: "rescheduled",
                subject: "Английский язык, английский язык, английский язык",
                aim: "Поступление в ВУЗ",
                timeStart: new Date(new Date().setHours(new Date().getHours() + 10)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() + 11)),
                rescheduleAttempt: 3,
                rescheduleReason: "Здравствуйте, Елизавета! Здесь должна быть указана причина, по которой ученик просит перенести занятие. ",
                rescheduleRequestTime: new Date(new Date().setHours(new Date().getHours() - 55)),
                rescheduleTimeStart: new Date(new Date().setHours(new Date().getHours() + 30)),
                rescheduleTimeEnd: new Date(new Date().setHours(new Date().getHours() + 31)),
                studentName: "Мария Н.",
                studentAvatar: Photo,
                studentType: "new",
                place: "tutor",
                rating: null,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000015,
                seen: false,
                classStatus: "rescheduled",
                subject: "Английский язык, английский язык, английский язык",
                aim: "Поступление в ВУЗ",
                timeStart: new Date(new Date().setHours(new Date().getHours() + 11)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() + 12)),
                rescheduleAttempt: 3,
                rescheduleReason:
                    "Здравствуйте, Елизавета! Здесь должна быть указана причина, по которой ученик просит перенести занятие. Здравствуйте, Елизавета! Здесь должна быть указана причина, по которой ученик просит перенести занятие. Здравствуйте, Елизавета! Здесь должна быть указана причина.",
                rescheduleRequestTime: new Date(new Date().setHours(new Date().getHours() - 22)),
                rescheduleTimeStart: new Date(new Date().setHours(new Date().getHours() + 41)),
                rescheduleTimeEnd: new Date(new Date().setHours(new Date().getHours() + 42)),
                studentName: "Кирилл М.",
                studentAvatar: null,
                studentType: null,
                place: "tutor",
                rating: null,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000016,
                seen: null,
                classStatus: "completed",
                subject: "Французский язык",
                aim: "Поступление в ВУЗ",
                timeStart: new Date(new Date().setHours(new Date().getHours() - 11)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() - 10)),
                rescheduleAttempt: 3,
                rescheduleReason: null,
                rescheduleRequestTime: null,
                rescheduleTimeStart: null,
                rescheduleTimeEnd: null,
                studentName: "Мария Н.",
                studentAvatar: null,
                studentType: "new",
                place: "tutor",
                rating: 5,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000017,
                seen: null,
                classStatus: "completed",
                subject: "Английский язык",
                aim: "Подготовка к ЕГЭ",
                timeStart: new Date(new Date().setHours(new Date().getHours() - 12)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() - 11)),
                rescheduleAttempt: 3,
                rescheduleReason: null,
                rescheduleRequestTime: null,
                rescheduleTimeStart: null,
                rescheduleTimeEnd: null,
                studentName: "Иван Н.",
                studentAvatar: null,
                studentType: null,
                place: "online",
                rating: 4,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000018,
                seen: null,
                classStatus: "completed",
                subject: "Английский язык",
                aim: "Подготовка к ЕГЭ",
                timeStart: new Date(new Date().setHours(new Date().getHours() - 13)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() - 12)),
                rescheduleAttempt: 3,
                rescheduleReason: null,
                rescheduleRequestTime: null,
                rescheduleTimeStart: null,
                rescheduleTimeEnd: null,
                studentName: "Александр Ш.",
                studentAvatar: null,
                studentType: "constant",
                place: "online",
                rating: null,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000019,
                seen: null,
                classStatus: "completed",
                subject: "Английский язык",
                aim: "Подготовка к экзамену в ВУЗе",
                timeStart: new Date(new Date().setHours(new Date().getHours() - 33)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() - 32)),
                rescheduleAttempt: 3,
                rescheduleReason: null,
                rescheduleRequestTime: null,
                rescheduleTimeStart: null,
                rescheduleTimeEnd: null,
                studentName: "Вероника Л.",
                studentAvatar: null,
                studentType: null,
                place: "online",
                rating: null,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000020,
                seen: null,
                classStatus: "completed",
                subject: "Английский язык",
                aim: "Подготовка к ЕГЭ",
                timeStart: new Date(new Date().setHours(new Date().getHours() - 32)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() - 31)),
                rescheduleAttempt: 3,
                rescheduleReason: null,
                rescheduleRequestTime: null,
                rescheduleTimeStart: null,
                rescheduleTimeEnd: null,
                studentName: "Елизавета А.",
                studentAvatar: null,
                studentType: "constant",
                place: "online",
                rating: 5,
                cancellationInitiator: null,
                cancelReason: null,
            },
            {
                id: 100000021,
                seen: null,
                classStatus: "cancelled",
                subject: "Английский язык",
                aim: "Подготовка к ЕГЭ",
                timeStart: new Date(new Date().setHours(new Date().getHours() - 8)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() - 7)),
                rescheduleAttempt: 3,
                rescheduleReason: null,
                rescheduleRequestTime: null,
                rescheduleTimeStart: null,
                rescheduleTimeEnd: null,
                studentName: "Константин Ш.",
                studentAvatar: null,
                studentType: "constant",
                place: "online",
                rating: 5,
                cancellationInitiator: "student",
                cancelReason: null,
            },
            {
                id: 100000022,
                seen: null,
                classStatus: "cancelled",
                subject: "Английский язык",
                aim: "Подготовка к ЕГЭ",
                timeStart: new Date(new Date().setHours(new Date().getHours() - 15)),
                timeEnd: new Date(new Date().setHours(new Date().getHours() - 14)),
                rescheduleAttempt: 3,
                rescheduleReason: null,
                rescheduleRequestTime: null,
                rescheduleTimeStart: null,
                rescheduleTimeEnd: null,
                studentName: "Вероника Л.",
                studentAvatar: null,
                studentType: null,
                place: "online",
                rating: 5,
                cancellationInitiator: "student",
                cancelReason: null,
            },
        ];
        this.ClassesUpdate();
    }

    ClassesUpdate() {
        this.nextClass = this.getClosestUpcomingClass();
        this.todayClasses = this.filterClassesToday();
        this.tomorrowClasses = this.filterClassesTomorrow();
        this.todayAndTomorrowDates = this.getFormattedTodayAndTomorrowDate();

        this.groupedUpcomingClasses = this.groupClassesByDay(
            this.classes.filter((item) => item.classStatus === "upcoming"),
            "upcoming",
        );
        this.groupedPendingClasses = this.groupClassesByDay(this.classes.filter((item) => item.classStatus === "pending"));
        this.groupedRescheduledRequests = this.groupClassesByDay(
            this.classes.filter((item) => item.classStatus === "rescheduled"),
            "reschedule",
        );
        this.groupedConductedClasses = this.groupClassesByDay(this.classes.filter((item) => item.classStatus === "completed"));
        this.groupedCancelledClasses = this.groupClassesByDay(this.classes.filter((item) => item.classStatus === "cancelled"));
    }
}

export default new TutorClassesStore();
