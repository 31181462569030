const universitiesWithFaculties = {
    "Санкт-Петербургский государственный университет (СПбГУ)": [
        "Высшая школа менеджмента",
        "Биологический факультет",
        "Факультет политологии",
        "Физический факультет",
        "Институт наук о Земле",
        "Восточный факультет",
        "Факультет прикладной математики - процессов управления",
        "Филологический факультет",
        "Высшая школа журналистики и массовых коммуникаций",
        "Факультет искусств",
        "Факультет психологии",
        "Экономический факультет",
        "Общеуниверситетская кафедра физической культуры и спорта",
        "Институт педагогики",
        "Институт истории",
        "Математико-механический факультет",
        "Факультет свободных искусств и наук",
        "Юридический факультет",
        "Факультет иностранных языков",
        "Институт философии",
        "Медицинский факультет",
        "Факультет социологии",
        "Факультет математики и компьютерных наук",
        "Институт развития конкуренции и антимонопольного регулирования",
        "Институт химии",
        "Факультет международных отношений",
        "Факультет стоматологии и медицинских технологий",
    ],
    "Северо-Кавказский федеральный университет (СКФУ)": [
        "Медико-биологический факультет",
        "Юридический институт",
        "Химико-фармацевтический факультет",
        "Школа креативных индустрий",
        "Факультет математики и компьютерных наук имени профессора Н.И. Червякова",
        "Физико-технический факультет",
        "Инженерный институт",
        "Институт наук о Земле",
        "Институт цифрового развития",
        "Психолого-педагогический факультет",
        "Факультет пищевой инженерии и биотехнологий",
        "Гуманитарный институт",
        "Институт экономики и управления",
        "Факультет физической культуры и спорта",
    ],
    "Российский государственный геологоразведочный университет имени Серго Орджоникидзе (МГРИ) (МГРИ)": [
        "Факультет геологии и геофизики нефти и газа",
        "Гидрогеологический факультет",
        "Экологический факультет",
        "Факультет технологии разведки и разработки",
        "Факультет экономики и управления",
        "Геологоразведочный факультет",
    ],
    "Институт отраслевого менеджмента РАНХиГС (РАНХиГС)": [
        "Факультет рыночных технологий",
        "Факультет инженерного менеджмента",
        "Факультет управления в медицине и здравоохранении",
        "Факультет гостеприимства",
    ],
    "Санкт-Петербургский государственный электротехнический университет «ЛЭТИ» им. В.И. Ульянова (Ленина) (СПбГЭТУ «ЛЭТИ»)": [
        "Факультет информационно-измерительных и биотехнических систем",
        "Институт инновационного проектирования и технологического предпринимательства",
        "Факультет радиотехники и телекоммуникаций",
        "Гуманитарный факультет",
        "Факультет электроники",
        "Факультет компьютерных технологий и информатики",
        "Факультет электротехники и автоматики",
    ],
    "Московский институт психоанализа (МИП)": [
        "Кафедра экономики и менеджмента",
        "Факультет общей и клинической психологии",
        "Факультет коррекционной педагогики и специальной психологии",
        "Психолого-педагогический факультет",
        "Факультет психоанализа",
        "Факультет наук о жизни",
    ],
    "Университет науки и технологий МИСИС (Университет МИСИС)": [
        "Институт базового образования",
        "Институт экотехнологий и инжиниринга",
        "Институт новых материалов и нанотехнологий",
        "Институт экономики и управления промышленными предприятиями имени В.А. Роменца",
        "Институт информационных технологий и компьютерных наук",
        "Горный институт",
    ],
    'Национальный исследовательский университет "МЭИ" (НИУ МЭИ)': [
        "Институт информационных и вычислительных технологий",
        "Институт энергомашиностроения и механики",
        "Институт радиотехники и электроники им. В.А. Котельникова",
        "Институт тепловой и атомной энергетики",
        "Гуманитарно-прикладной институт",
        "Институт энергоэффективности и водородных технологий",
        "Инженерно-экономический институт",
        "Институт электротехники и электрификации",
        "Институт дистанционного и дополнительного образования",
        "Институт электроэнергетики",
        "Институт гидроэнергетики и возобновляемых источников энергии",
    ],
    "Институт международных экономических связей (ИМЭС)": ["Факультет управления, бизнеса и права"],
    "Нижегородский филиал Национального исследовательского университета «Высшая школа экономики» (НИУ ВШЭ - Нижний Новгород)": [
        "Факультет информатики, математики и компьютерных наук",
        "Факультет гуманитарных наук",
        "Факультет менеджмента",
        "Факультет права",
        "Факультет экономики",
    ],
    "Санкт-Петербургский филиал Национального исследовательского университета «Высшая школа экономики» (НИУ ВШЭ - Санкт-Петербург)": [
        "Санкт-Петербургская школа экономики и менеджмента",
        "Санкт-Петербургская школа гуманитарных наук и искусств",
        "Юридический факультет",
        "Санкт-Петербургская школа физико-математических и компьютерных наук",
        "Санкт-Петербургская школа социальных наук и востоковедения",
    ],
    "Уральский федеральный университет имени первого Президента России Б.Н. Ельцина (УрФУ им. Б.Н. Ельцина)": [
        "Институт радиоэлектроники и информационных технологий – РтФ",
        "Физико-технологический институт",
        "Институт технологий открытого образования",
        "Химико-технологический институт",
        "Институт новых материалов и технологий",
        "Институт физической культуры, спорта и молодежной политики",
        "Институт экономики и управления",
        "Институт фундаментального образования",
        "Уральский гуманитарный институт",
        "Институт строительства и архитектуры",
        "Институт естественных наук и математики",
        "Уральский энергетический институт",
    ],
    "Российский экономический университет имени Г.В. Плеханова (РЭУ им. Г.В. Плеханова)": [
        "Высшая школа кибертехнологий, математики и статистики",
        "Высшая школа менеджмента",
        "Высшая школа права",
        'Специальный факультет талантливой молодежи «Высшая школа "Форсайт"»',
        'Факультет бизнеса "Капитаны"',
        "Высшая школа cоциально-гуманитарных наук",
        "Высшая инженерная школа «Новые материалы и технологии»",
        "Высшая школа финансов",
        "Институт «Первая Академия Медиа»",
        "Высшая школа экономики и бизнеса",
        'Факультет «Плехановская школа бизнеса "Интеграл"»',
    ],
    "Северо-Западный институт управления - филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Северо-Западный институт управления РАНХиГС)": [
        "Факультет государственного и муниципального управления",
        "Факультет экономики и финансов",
        "Юридический факультет",
        "Факультет безопасности и таможни",
        "Факультет международных отношений и политических исследований",
        "Факультет социальных технологий",
    ],
    "Российский государственный университет им. А.Н. Косыгина (Технологии. Дизайн. Искусство) (РГУ им. А.Н. Косыгина)": [
        "Институт экономики и менеджмента",
        "Институт Академии имени Маймонида",
        "Институт мехатроники и информационных технологий",
        "Институт славянской культуры",
        "Отдел магистратуры",
        "Институт химических технологий и промышленной экологии",
        "Институт искусств",
        "Технологический институт легкой промышленности",
        "Институт дизайна",
        "Текстильный институт",
        "Институт социальной инженерии",
    ],
    "Институт бизнеса и делового администрирования РАНХиГС (РАНХиГС)": ["Факультет международного бизнеса и делового администрирования", "Факультет международных отношений"],
    "Адыгейский государственный университет (АГУ)": [
        "Факультет иностранных языков",
        "Экономический факультет",
        "Юридический факультет",
        "Исторический факультет",
        "Факультет социальных технологий и туризма",
        "Факультет адыгейской филологии и культуры",
        "Филологический факультет",
        "Факультет математики и компьютерных наук",
        "Институт физической культуры и дзюдо",
        "Факультет естествознания",
        "Международный факультет",
        "Институт искусств",
        "Инженерно-физический факультет",
        "Факультет педагогики и психологии",
    ],
    "Технологический университет имени дважды Героя Советского Союза, летчика-космонавта А.А. Леонова (МГОТУ)": [
        "Институт проектного менеджмента и инженерного бизнеса",
        "Институт ракетно-космической техники и технологии машиностроения",
        "Институт инфокоммуникационных систем и технологий",
        "Институт международного и дистанционного образования",
    ],
    "Санкт-Петербургский государственный университет промышленных технологий и дизайна (СПбГУПТД)": [
        "Институт текстиля и моды",
        "Институт бизнес-коммуникаций",
        "Институт энергетики и автоматизации",
        "Институт прикладной химии и экологии",
        "Институт дизайна костюма",
        "Институт управления и экономики",
        "Высшая школа технологии и энергетики",
        "Институт дизайна пространственной среды",
        "Институт полиграфических технологий и оборудования",
        "Институт информационных технологий и автоматизации",
        "Институт прикладного искусства",
        "Институт медиатехнологий",
        "Институт технологии",
        "Институт экономики и социальных технологий",
        "Институт дизайна и искусств",
        "Институт графического дизайна",
        "Высшая школа печати и медиатехнологий",
        "Региональный институт непрерывного профессионального образования",
    ],
    "МИРЭА – Российский технологический университет (РТУ МИРЭА)": [
        "Институт кибербезопасности и цифровых технологий",
        "Институт перспективных технологий и индустриального программирования",
        "Институт радиоэлектроники и информатики",
        "Институт тонких химических технологий им. М.В. Ломоносова",
        "Институт технологий управления",
        "Институт информационных технологий",
        "Институт искусственного интеллекта",
    ],
    "Российский государственный аграрный университет - МСХА имени К.А. Тимирязева (РГАУ-МСХА им. К.А. Тимирязева)": [
        "Институт садоводства и ландшафтной архитектуры",
        "Институт мелиорации, водного хозяйства и строительства им. А. Н. Костякова",
        "Технологический институт",
        "Институт механики и энергетики им. В.П. Горячкина",
        "Факультет заочного образования",
        "Институт агробиотехнологии",
        "Институт зоотехнии и биологии",
        "Институт экономики и управления АПК",
    ],
    "Университет при Межпарламентской Ассамблее ЕврАзЭС (Университет при МПА ЕврАзЭС)": [
        "Факультет бизнес-коммуникаций",
        "Юридический факультет",
        "Факультет автомобильного сервиса",
        "Невский институт дизайна",
        "Институт прикладного психоанализа и психологии",
        "Факультет экономики и таможенного дела",
    ],
    "Финансовый университет при Правительстве Российской Федерации (Финансовый университет)": [
        "Факультет информационных технологий и анализа больших данных",
        "Финансовый факультет",
        "Факультет социальных наук и массовых коммуникаций",
        "Факультет «Высшая школа управления»",
        "Факультет налогов, аудита и бизнес-анализа",
        "Факультет международных экономических отношений",
        "Факультет экономики и бизнеса",
        "Институт онлайн-образования",
        "Юридический факультет",
    ],
    "Филиал Медицинского университета «Реавиз» (Саратовский медицинский университет «Реавиз»)": ["Фармацевтический факультет", "Лечебный и медико-социальный факультет", "Стоматологический факультет"],
    "Санкт-Петербургский политехнический университет Петра Великого (СПбПУ)": [
        "Институт энергетики",
        "Институт промышленного менеджмента, экономики и торговли",
        "Институт кибербезопасности и защиты информации",
        "Гуманитарный институт",
        "Институт машиностроения, материалов и транспорта",
        "Институт передовых производственных технологий",
        "Институт биомедицинских систем и биотехнологий",
        "Институт физической культуры, спорта и туризма",
        "Институт электроники и телекоммуникаций",
        "Институт компьютерных наук и технологий",
        "Физико-механический институт",
        "Инженерно-строительный институт",
    ],
    "Московский государственный университет геодезии и картографии (МИИГАиК)": [
        "Факультет управления территориями",
        "Факультет архитектуры и градостроительства",
        "Факультет геоинформатики и информационной безопасности",
        "Геодезический факультет",
        "Заочный факультет",
        "Картографический факультет",
        "Факультет оптического приборостроения",
    ],
    "Санкт-Петербургский реставрационно-строительный институт (СПбРСИ)": [
        "Факультет архитектуры и строительства",
        "Факультет экономики и менеджмента",
        "Факультет истории искусств и реставрации",
        "Факультет социально-гуманитарных наук",
        "Факультет музыки и театра",
    ],
    "Уфимский государственный нефтяной технический университет (УГНТУ)": [
        "Институт нефтегазового инжиниринга и цифровых технологий",
        "Высшая школа информационных и социальных технологий",
        "Горно-нефтяной факультет",
        "Институт нефтегазового бизнеса",
        "Технологический факультет",
        "Архитектурно-строительный институт",
        "Институт цифровых систем, автоматизации и энергетики (IT-институт)",
        "Институт экосистем бизнеса и креативных индустрий",
        "Уфимская высшая школа экономики и управления",
        "Факультет трубопроводного транспорта",
    ],
    "Новосибирский национальный исследовательский государственный университет (Новосибирский государственный университет)": [
        "Экономический факультет",
        "Передовая инженерная школа",
        "Институт философии и права",
        "Геолого-геофизический факультет",
        "Механико-математический факультет",
        "Факультет информационных технологий",
        "Институт интеллектуальной робототехники",
        "Физический факультет",
        "Институт медицины и психологии В. Зельмана",
        "Гуманитарный институт",
        "Факультет естественных наук",
    ],
    "Медицинский университет «Реавиз» (Медицинский университет «Реавиз»)": ["Лечебный факультет", "Стоматологический факультет", "Медико-социальный факультет", "Фармацевтический факультет"],
    "Российский государственный социальный университет (РГСУ)": [
        "Факультет юриспруденции и правового регулирования",
        "Факультет экологии и природоохранной деятельности",
        "Кафедра современной педагогики, непрерывного образования и персональных треков",
        "Кафедра физической культуры, спорта и здорового образа жизни",
        "Факультет специальных исторических, гуманитарных дисциплин и военно-мемориальной работы",
        "Факультет политических и социальных технологий",
        "Кафедра иностранных языков и культуры",
        "Кафедра искусства и народного творчества",
        "Кафедра комплекса естественно-научных дисциплин",
        "Факультет политических и социальных наук",
        "Кафедра комплекса гуманитарных дисциплин",
        "Факультет экономики и управления",
        "Медицинский факультет",
        "Факультет клиентского, специального и международного образования",
        "Кафедра современных аксиологических проблем и религиозной мысли",
    ],
    "Российский новый университет (РосНОУ)": [
        "Институт экономики, управления и финансов",
        "Гуманитарный институт",
        "Институт информационных систем и инженерно-компьютерных технологий",
        "Институт бизнес-технологий",
        "Налоговый институт",
    ],
    "Институт правоведения и предпринимательства (ИПП)": ["Факультет управления"],
    "Московский политехнический университет (Московский Политех)": [
        "Институт издательского дела и журналистики",
        "Транспортный факультет",
        "Факультет информационных технологий",
        "Факультет машиностроения",
        "Институт графики и искусства книги имени В.А. Фаворского",
        "Факультет экономики и управления",
        "Факультет урбанистики и городского хозяйства",
        "Факультет химической технологии и биотехнологии",
        "Полиграфический институт",
    ],
    "Российский химико-технологический университет имени Д.И. Менделеева (РХТУ им. Д.И. Менделеева)": [
        "Факультет технологии неорганических веществ и высокотемпературных материалов",
        "Факультет химико-фармацевтических технологий и биомедицинских препаратов",
        "Факультет нефтегазохимии и полимерных материалов",
        "Гуманитарный факультет",
        "Высший химический колледж Российской академии наук",
        "Институт материалов современной энергетики и нанотехнологий",
        "Инженерный химико-технологический факультет",
        "Факультет цифровых технологий и химического инжиниринга",
        "Институт химии и проблем устойчивого развития",
        "Факультет биотехнологии и промышленной экологии",
        "Факультет естественных наук",
    ],
    "Севастопольский государственный университет (СевГУ)": [
        "Институт информационных технологий и управления в технических системах",
        "Институт общественных наук и международных отношений",
        "Институт национальной технологической инициативы",
        "Морской институт",
        "Институт развития города",
        "Институт финансов, экономики и управления",
        "Политехнический институт",
        "Юридический институт",
        "Институт радиоэлектроники и информационной безопасности",
        "Гуманитарно-педагогический институт",
        "Центр «Физическая культура и спорт»",
        "Институт ядерной энергии и промышленности",
    ],
    "Санкт-Петербургский Гуманитарный университет профсоюзов (СПбГУП)": ["Факультет конфликтологии", "Факультет культуры", "Факультет искусств", "Экономический факультет", "Юридический факультет"],
    "Университет «Реавиз» в Санкт-Петербурге (Университет Реавиз в СПб)": ["Стоматологический факультет", "Лечебный факультет"],
    "Дальневосточный федеральный университет (ДВФУ)": [
        "Институт наукоемких технологий и передовых материалов",
        "Институт наук о жизни и биомедицины",
        "Восточный институт – Школа региональных и международных исследований",
        "Институт математики и компьютерных технологий",
        "Передовая инженерная школа «Институт биотехнологий, биоинженерии и пищевых систем»",
        "Школа экономики и менеджмента",
        "Школа медицины",
        "Школа искусств и гуманитарных наук",
        "Институт Мирового океана",
        "Школа педагогики",
        "Политехнический институт",
        "Юридическая школа",
    ],
    "Сибирский государственный медицинский университет (СибГМУ)": [
        "Педиатрический факультет",
        "Медико-биологический факультет",
        "Фармацевтический факультет",
        "Институт интегративного здравоохранения",
        "Лечебный факультет",
    ],
    "Магнитогорский государственный технический университет им. Г. И. Носова (МГТУ им. Г.И. Носова)": [
        "Институт строительства, архитектуры и искусства",
        "Институт элитных программ и открытого образования",
        "Институт энергетики и автоматизированных систем",
        "Институт металлургии, машиностроения и материалообработки",
        "Институт горного дела и транспорта",
        "Институт гуманитарного образования",
        "Факультет физической культуры и спортивного мастерства",
        "Институт естествознания и стандартизации",
        "Институт экономики и управления",
    ],
    "Российский государственный университет правосудия (РГУП)": [
        "Факультет непрерывного образования",
        "Факультет подготовки специалистов для судебной системы (очно-заочный и заочный юридический факультет)",
        "Экономический факультет",
        "Факультет подготовки специалистов для судебной системы очной формы обучения (очный юридический факультет)",
    ],
    "Филиал Медицинского университета «Реавиз» в городе Москве (Московский филиал Медицинского университета «Реавиз»)": [
        "Лечебный факультет",
        "Стоматологический факультет",
        "Фармацевтический факультет",
    ],
    "Государственный университет просвещения (ГУП)": [
        "Физико-математический факультет",
        "Факультет специальной педагогики и психологии",
        "Факультет изобразительного искусства и народных ремесел",
        "Экономический факультет",
        "Лингвистический факультет",
        "Юридический факультет",
        "Факультет романо-германских языков",
        "Факультет истории, политологии и права",
        "Факультет естественных наук",
        "Медицинский факультет",
        "Факультет русской филологии",
        "Факультет физической культуры",
        "Факультет психологии",
        "Факультет безопасности жизнедеятельности",
    ],
    "Кабардино-Балкарский государственный университет имени Х. М. Бербекова (КБГУ  им. Х. М. Бербекова)": [
        "Медицинский факультет",
        "Институт искусственного интеллекта и цифровых технологий",
        "Институт архитектуры, строительства и дизайна",
        "Институт права, экономики и финансов",
        "Социально-гуманитарный институт",
        "Институт педагогики, психологии и физкультурно-спортивного образования",
        "Институт информатики, электроники и робототехники",
        "Институт физики и математики",
        "Институт стоматологии и челюстно-лицевой хирургии",
        "Институт химии и биологии",
    ],
    "Московский технический университет связи и информатики (МТУСИ)": [
        "Факультет радио и телевидения",
        "Центр заочного обучения по программам магистратуры",
        "Факультет сетей и систем связи",
        "Факультет цифровой экономики и массовых коммуникаций",
        "Центр индивидуального обучения",
        "Центр заочного обучения по программам бакалавриата",
        "Факультет информационных технологий",
        "Факультет кибернетики и информационной безопасности",
    ],
    "Московский государственный университет технологий и управления им. К.Г. Разумовского (Первый казачий университет) (МГУТУ им. К.Г. Разумовского (ПКУ))": [
        "Факультет пищевых технологий и биоинженерии",
        "Факультет биотехнологий и рыбного хозяйства",
        "Факультет социально-гуманитарных технологий",
        "Юридический факультет",
        "Факультет цифровых технологий",
        "Факультет экономики и управления",
    ],
    "Санкт-Петербургский государственный экономический университет (СПбГЭУ)": [
        "Факультет информатики и прикладной математики",
        "Юридический факультет",
        "Факультет управления",
        "Факультет сервиса, туризма и гостеприимства",
        "Факультет экономики и финансов",
        "Институт магистратуры",
        "Гуманитарный факультет",
        "Факультет бизнеса, таможенного дела и экономической безопасности",
        "Международный институт экономики и политики",
    ],
    "Южно-Уральский государственный университет (ЮУрГУ)": [
        "Институт лингвистики и международных коммуникаций",
        "Высшая школа электроники и компьютерных наук",
        "Политехнический институт",
        "Юридический институт",
        "Высшая медико-биологическая школа",
        "Институт медиа и социально-гуманитарных наук",
        "Институт открытого и дистанционного образования",
        "Институт естественных и точных наук",
        "Заочный факультет политехнического института",
        "Архитектурно-строительный институт",
        "Высшая школа экономики и управления",
        "Институт спорта, туризма и сервиса",
    ],
    "Северо-Кавказский филиал Московского технического университета связи и информатики (Северо-Кавказский филиал МТУСИ)": ["Факультет инфокоммуникаций"],
    "Челябинский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Челябинский филиал РАНХиГС)": [
        "Факультет экономики и права",
        "Факультет управления",
    ],
    "Сибирский институт управления - филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (СИУ РАНХиГС)": [
        "Факультет государственного и муниципального управления",
        "Юридический факультет",
        "Факультет экономики и финансов",
    ],
    "Государственный морской университет имени адмирала Ф.Ф. Ушакова (ГМУ им. адм. Ф.Ф. Ушакова)": [
        "Факультет эксплуатации водного транспорта и судовождения",
        "Судомеханический факультет",
        "Заочный факультет",
        "Институт морского транспортного менеджмента, экономики и права",
    ],
    "Национальный исследовательский университет «Московский институт электронной техники» (НИУ МИЭТ)": [
        "Институт системной и программной инженерии и информационных технологий",
        "Институт микроприборов и систем управления имени Л.Н. Преснухина",
        "Кафедра информационной безопасности",
        "Кафедра права",
        "Институт нано- и микросистемной техники",
        "Кафедра маркетинга и управления проектами",
        "Кафедра квантовой физики и наноэлектроники",
        "Институт перспективных материалов и технологий",
        "Кафедра экономики, менеджмента и финансов",
        "Кафедра общей физики",
        "Кафедра интегральной электроники и микросистем",
        "Кафедра высшей математики №1",
        "Кафедра телекоммуникационных систем",
        "Кафедра проектирования и конструирования интегральных микросхем",
        "Институт биомедицинских систем",
        "Кафедра инженерной графики и дизайна",
        "Институт лингвистического и педагогического образования",
    ],
    "Национальный исследовательский Нижегородский государственный университет им. Н.И. Лобачевского (ННГУ им. Н.И. Лобачевского)": [
        "Физический факультет",
        "Институт экономики и предпринимательства",
        "Институт филологии и журналистики",
        "Институт биологии и биомедицины",
        "Высшая школа общей и прикладной физики",
        "Институт информационных технологий, математики и механики",
        "Юридический факультет",
        "Институт реабилитации и здоровья человека",
        "Химический факультет",
        "Факультет социальных наук",
        "Институт международных отношений и мировой истории",
        "Радиофизический факультет",
        "Факультет физической культуры и спорта",
    ],
    "Сибирский федеральный университет (СФУ)": [
        "Институт фундаментальной биологии и биотехнологии",
        "Институт управления бизнес-процессами",
        "Институт торговли и сферы услуг",
        "Институт гастрономии",
        "Институт цветных металлов и материаловедения",
        "Гуманитарный институт",
        "Институт экономики, государственного управления и финансов",
        "Институт экологии и географии",
        "Институт педагогики, психологии и социологии",
        "Юридический институт",
        "Институт математики и фундаментальной информатики",
        "Институт архитектуры и дизайна",
        "Военный учебный центр - институт",
        "Институт филологии и языковой коммуникации",
        "Институт космических и информационных технологий",
        "Инженерно-строительный институт",
        "Институт нефти и газа",
        "Институт физической культуры, спорта и туризма",
        "Институт инженерной физики и радиоэлектроники",
        "Политехнический институт",
        "Институт горного дела, геологии и геотехнологий",
    ],
    "Белгородский государственный национальный исследовательский университет (НИУ «БелГУ»)": [
        "Историко-филологический факультет",
        "Институт межкультурной коммуникации и международных отношений",
        "Институт фармации, химии и биологии",
        "Факультет математики и естественнонаучного образования",
        "Факультет иностранных языков",
        "Институт экономики и управления",
        "Юридический институт",
        "Факультет психологии",
        "Институт инженерных и цифровых технологий",
        "Факультет физической культуры",
        "Медицинский институт",
        "Институт наук о Земле",
        "Факультет дошкольного, начального и специального образования",
        "Факультет медико-профилактического дела",
        "Институт общественных наук и массовых коммуникаций",
    ],
    "Рязанский государственный медицинский университет имени академика И. П. Павлова (РязГМУ)": [
        "Фармацевтический факультет",
        "Стоматологический факультет",
        "Факультет клинической психологии",
        "Лечебный факультет",
        "Факультет среднего профессионального образования и бакалавриата",
        "Педиатрический факультет",
        "Медико-профилактический факультет",
    ],
    "Российская академия адвокатуры и нотариата (РААН)": ["Юридический факультет"],
    "Институт государственного администрирования (ИГА)": ["Юридический факультет", "Факультет экономики и управления", "Психолого-педагогический факультет"],
    "Первый Московский государственный медицинский университет имени И.М. Сеченова Минздрава России (Сеченовский Университет) (Первый МГМУ им. И.М. Сеченова Минздрава России (Сеченовский Университет))":
        [
            "Институт общественного здоровья им. Ф.Ф. Эрисмана",
            "Институт социальных наук",
            "Клинический институт детского здоровья имени Н.Ф. Филатова",
            "Центр магистерских программ",
            "Институт цифровой медицины",
            "Институт стоматологии им. Е.В. Боровского",
            "Институт лингвистики и межкультурной коммуникации",
            "Институт психолого-социальной работы",
            'Международная школа "Медицина будущего"',
            "Институт фармации им. А.П. Нелюбина",
            "Институт клинической медицины им. Н.В. Склифосовского",
        ],
    "Балтийский государственный технический университет «ВОЕНМЕХ» им. Д.Ф. Устинова (БГТУ ВОЕНМЕХ им. Д.Ф. Устинова)": [
        "Факультет «Информационные и управляющие системы» (И)",
        "Факультет международного промышленного менеджмента и коммуникации (Р)",
        "Факультет естественнонаучный (О)",
        "Факультет ракетно-космической техники (А)",
        "Факультет «Оружие и системы вооружения» (Е)",
    ],
    "Тюменский индустриальный университет (ТИУ)": [
        "Институт промышленных технологий и инжиниринга",
        "Институт геологии и нефтегазодобычи",
        "Институт архитектуры и дизайна",
        "Институт транспорта",
        "Институт сервиса и отраслевого управления",
        "Строительный институт",
        "Институт дополнительного и дистанционного образования",
        "Высшая инженерная школа EG",
    ],
    "Иркутский государственный университет путей сообщения (ИрГУПС)": [
        "Факультет строительства железных дорог",
        "Факультет транспортных систем",
        "Факультет экономики и управления",
        "Факультет менеджмента, логистики и таможенного дела",
        "Факультет управления на транспорте и информационных технологий",
        "Факультет систем обеспечения транспорта",
    ],
    "Северо-Кавказский институт – филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Северо-Кавказский институт – филиал РАНХиГС)": [
        "Факультет экономики, управления и права",
    ],
    "Московский государственный психолого-педагогический университет (МГППУ)": [
        "Факультет консультативной и клинической психологии",
        "Факультет юридической психологии",
        "Факультет психологии образования",
        "Факультет экстремальной психологии",
        "Факультет социальной психологии",
        "Факультет дистанционного обучения",
        "Факультет социальной коммуникации",
        "Факультет клинической и специальной психологии",
        "Институт экспериментальной психологии",
        "Факультет информационных технологий",
        "Институт иностранных языков, современных коммуникаций и управления",
        "Кафедра ЮНЕСКО «Культурно-историческая психология детства»",
    ],
    "Российский государственный университет туризма и сервиса (РГУТИС)": [
        "Высшая школа бизнеса, менеджмента и права",
        "Высшая школа дизайна",
        "Институт сервисных технологий",
        "Высшая школа сервиса",
        "Высшая школа туризма и гостеприимства",
    ],
    "Международный институт экономики и права (МИЭП)": ["Юридический факультет", "Факультет экономики и управления"],
    "Санкт-Петербургский государственный университет телекоммуникаций им. проф. М.А. Бонч-Бруевича (СПбГУТ)": [
        "Факультет радиотехнологий связи",
        "Институт непрерывного образования",
        "Факультет фундаментальной подготовки",
        "Факультет инфокоммуникационных сетей и систем",
        "Факультет цифровой экономики, управления и бизнес-информатики",
        "Институт магистратуры",
        "Факультет информационных систем и технологий",
        "Факультет социальных цифровых технологий",
    ],
    "Калмыцкий государственный университет имени Б.Б. Городовикова (КалмГУ)": [
        "Аграрный факультет",
        "Инженерно-технологический факультет",
        "Гуманитарный факультет",
        "Факультет педагогического образования и биологии",
        "Институт калмыцкой филологии и востоковедения",
        "Факультет математики, физики и информационных технологий",
        "Факультет управления и права",
        "Экономический факультет",
    ],
    "Югорский государственный университет (ЮГУ)": ["Юридический институт", "Гуманитарный институт", "Институт цифровой экономики", "Институт нефти и газа"],
    "Московский информационно-технологический университет – Московский архитектурно-строительный институт (МИТУ-МАСИ)": [
        "Московская академия системной интеграции",
        "Московский архитектурно-строительный институт",
        "Международная академия свободных искусств",
    ],
    "Художественно-технический институт (ВХУТЕИН)": [
        "Факультет информационных технологий",
        "Факультет медиа коммуникаций и креативных индустрий",
        'Факультет театрального искусства "Школа русской драмы им. И.О. Горбачева"',
        "Факультет дизайна",
    ],
    "Московский городской педагогический университет (МГПУ)": [
        "Институт гуманитарных наук",
        "Институт иностранных языков",
        "Институт культуры и искусств",
        "Институт педагогики и психологии образования",
        "Дирекция образовательных программ",
        "Институт цифрового образования",
        "Институт права и управления",
        "Институт естествознания и спортивных технологий",
        "Институт специального образования и психологии",
    ],
    "Московский государственный технологический университет СТАНКИН (МГТУ СТАНКИН)": [
        "Институт информационных технологий",
        "Институт производственных технологий и инжиниринга",
        "Институт социально-технологического менеджмента",
        "Институт цифровых интеллектуальных систем",
    ],
    "Национальный исследовательский Московский государственный строительный университет (НИУ МГСУ)": [
        "Институт гидротехнического и энергетического строительства",
        "Институт инженерно-экологического строительства и механизации",
        "Институт цифровых технологий и моделирования в строительстве",
        "Институт промышленного и гражданского строительства",
        "Институт экономики, управления и коммуникаций в сфере строительства и недвижимости",
        "Институт дистанционного образования",
        "Мытищинский филиал",
        "Институт архитектуры и градостроительства",
    ],
    "Саратовский национальный исследовательский государственный университет имени Н.Г. Чернышевского (СГУ имени Н.Г. Чернышевского)": [
        "Социологический факультет",
        "Факультет иностранных языков и лингводидактики",
        "Институт химии",
        "Биологический факультет",
        "Факультет психолого-педагогического и специального образования",
        "Институт искусств",
        "Географический факультет",
        "Философский факультет",
        "Экономический факультет",
        "Институт физической культуры и спорта",
        "Геологический факультет",
        "Факультет психологии",
        "Юридический факультет",
        "Институт дополнительного профессионального образования",
        "Механико-математический факультет",
        "Факультет компьютерных наук и информационных технологий",
        "Институт истории и международных отношений",
        "Факультет фундаментальной медицины и медицинских технологий",
        "Институт физики",
        "Институт филологии и журналистики",
    ],
    "Новосибирский государственный университет архитектуры, дизайна и искусств (НГУАДИ)": ["Факультет градостроительства и архитектуры", "Факультет дизайна и искусств"],
    "Тамбовский государственный университет имени Г. Р. Державина (ТГУ им. Г. Р. Державина)": [
        "Факультет филологии и журналистики",
        "Институт математики, физики и информационных технологий",
        "Педагогический институт",
        "Медицинский институт",
        "Факультет культуры и искусств",
        "Факультет физической культуры и спорта",
        "Институт экономики, управления и сервиса",
        "Институт права и национальной безопасности",
        "Институт естествознания",
        "Факультет истории, мировой политики и социологии",
    ],
    "Сибирский государственный индустриальный университет (СибГИУ)": [
        "Институт физической культуры, здоровья и спорта",
        "Архитектурно-строительный институт",
        "Институт информационных технологий и автоматизированных систем",
        "Институт металлургии и материаловедения",
        "Институт педагогического образования",
        "Институт экономики и менеджмента",
        "Институт открытого образования",
        "Институт машиностроения и транспорта",
        "Институт горного дела и геосистем",
    ],
    "Чеченский государственный педагогический университет (ЧГПУ)": [
        "Факультет технологии, искусства и менеджмента в образовании",
        "Факультет физики, математики и информационных технологий",
        "Факультет естествознания",
        "Факультет физической культуры",
        "Кафедра психологии",
        "Институт педагогики, психологии и дефектологии",
        "Институт филологии, истории и права",
        "Кафедра философии, политологии и социологии",
    ],
    "Чеченский государственный университет имени А.А. Кадырова (ЧГУ им. А.А. Кадырова)": [
        "Исторический факультет",
        "Институт математики, физики и информационных технологий",
        "Агротехнологический институт",
        "Медицинский институт",
        "Биолого-химический факультет",
        "Факультет государственного управления",
        "Факультет географии и геоэкологии",
        "Институт экономики и финансов",
        "Факультет иностранных языков",
        "Юридический факультет",
        "Филологический факультет",
    ],
    "Российский Исламский университет имени Кунта-Хаджи (РИУ им. Кунта-Хаджи)": ["Теологический факультет"],
    "Московский финансово-промышленный университет «Синергия» (Университет Синергия)": [
        "Факультет менеджмента",
        "Факультет психологии",
        "Факультет анимации",
        "Факультет искусственного интеллекта",
        "Факультет гостеприимства",
        "Факультет индустрии спорта",
        "Факультет кино и телевидения",
        "Арт-факультет",
        "Факультет геймдизайна и разработки игр",
        "Факультет банковского дела",
        "Факультет лингвистики",
        "Медицинский факультет",
        "Факультет игровой индустрии и киберспорта",
        "Факультет музыкальной индустрии",
        "Факультет экономики",
        "Факультет дизайна и рекламы",
        "Факультет государственного и муниципального управления",
        "Факультет технологического предпринимательства",
        "Факультет программирования",
        "Факультет бизнеса",
        "Юридический факультет",
        "Факультет продюсирования",
        "Факультет робототехники",
        "Новая театральная школа",
        "Факультет информационных технологий",
        "Факультет педагогики",
        "Факультет беспилотных технологий",
    ],
    "Грозненский государственный нефтяной технический университет имени академика М.Д. Миллионщикова (ГГНТУ им. акад. М.Д. Миллионщикова)": [
        "Институт нефти и газа",
        "Институт цифровой экономики и технологического предпринимательства",
        "Институт строительства, архитектуры и дизайна",
        "Иститут энергетики",
        "Институт прикладных информационных технологий",
    ],
    "Московский авиационный институт (национальный исследовательский университет) (МАИ) (МАИ)": [
        "Институт Системы управления, информатика и электроэнергетика",
        "Институт иностранных языков",
        "Институт материаловедения и технологий материалов",
        "Институт Радиоэлектроника, инфокоммуникации и информационная безопасность",
        "Институт Аэрокосмические наукоемкие технологии и производства",
        "Аэрокосмический институт",
        "Институт Экономика и менеджмент высокотехнологичной индустрии",
        "Институт Робототехнические и интеллектуальные системы",
        "Институт Авиационная техника",
        "Институт Компьютерные науки и прикладная математика",
        "Институт Авиационные, ракетные двигатели и энергетические установки",
        "Институт общеинженерной подготовки",
        "Институт Передовая инженерная школа",
    ],
    "Университет Иннополис (Университет Иннополис)": ["Факультет компьютерных наук"],
    "Российский университет транспорта (РУТ (МИИТ))": [
        "Российская открытая академия транспорта",
        'Академия "Высшая инженерная школа"',
        "Институт международных транспортных коммуникаций",
        "Юридический институт",
        "Институт пути, строительства и сооружений",
        "Институт транспортной техники и систем управления",
        "Институт управления и цифровых технологий",
        "Академия водного транспорта",
        "Академия дорожного хозяйства",
        "Институт экономики и финансов",
        "Академия гражданской авиации",
    ],
    "Казанский филиал Российского государственного университета правосудия (Казанский филиал РГУП)": ["Факультет подготовки специалистов для судебной системы (юридический факультет)"],
    "Национальный исследовательский университет ИТМО (Университет ИТМО)": [
        "Факультет информационных технологий и программирования",
        "Факультет биотехнологий",
        "Факультет экотехнологий",
        "Образовательный центр «Энергоэффективные инженерные системы»",
        "Факультет инфокоммуникационных технологий",
        "Факультет безопасности информационных технологий",
        "Центр химической инженерии",
        "Физико-технический мегафакультет",
        "Факультет фотоники",
        "Химико-биологический кластер",
        "Научно-образовательный центр инфохимии",
        "Факультет наноэлектроники",
        "Физический факультет",
        "Институт международного развития и партнерства",
        "Инженерно-исследовательский факультет",
        "Высшая школа цифровой культуры",
        "Институт «Высшая инженерно-техническая школа»",
        "Факультет систем управления и робототехники",
        "Институт дизайна и урбанистики",
        "Факультет цифровых трансформаций",
        "Институт перспективных систем передачи данных",
        "Факультет программной инженерии и компьютерной техники",
        "Факультет технологического менеджмента и инноваций",
        "Институт прикладных компьютерных наук",
    ],
    "Национальный исследовательский Томский политехнический университет (НИ ТПУ)": [
        "Инженерная школа ядерных технологий",
        "Инженерная школа неразрушающего контроля и безопасности",
        "Инженерная школа природных ресурсов",
        "Инженерная школа новых производственных технологий",
        "Передовая инженерная школа «Интеллектуальные энергетические системы»",
        "Инженерная школа информационных технологий и робототехники",
        "Инженерная школа энергетики",
        "Школа инженерного предпринимательства",
    ],
    "Казанский национальный исследовательский технологический университет (КНИТУ)": [
        "Институт химического и нефтяного машиностроения",
        "Институт пищевых производств и биотехнологии",
        "Высшая школа экономики",
        "Институт управления инновациями",
        "Институт нефти, химии и нанотехнологий",
        "Институт полимеров",
        "Институт технологии легкой промышленности, моды и дизайна",
        "Казанский межвузовский инженерный центр «Новые технологии»",
        "Инженерный химико-технологический институт",
        "Институт управления, автоматизации и информационных технологий",
    ],
    "Томский государственный университет систем управления и радиоэлектроники (ТУСУР)": [
        "Факультет систем управления",
        "Факультет безопасности",
        "Факультет электронной техники",
        "Заочный и вечерний факультет",
        "Передовая инженерная школа «Электронное приборостроение и системы связи» им. А.В. Кобзева",
        "Экономический факультет",
        "Факультет дистанционного обучения",
        "Радиотехнический факультет",
        "Гуманитарный факультет",
        "Радиоконструкторский факультет",
        "Юридический факультет",
        "Факультет вычислительных систем",
        "Факультет инновационных технологий",
    ],
    'Национальный исследовательский университет "Высшая школа экономики" (НИУ ВШЭ)': [
        "Факультет компьютерных наук",
        "Международный институт экономики и финансов",
        "Факультет биологии и биотехнологии",
        "Факультет географии и геоинформационных технологий",
        "Высшая школа бизнеса",
        "Московский институт электроники и математики имени А.Н. Тихонова",
        "Факультет математики",
        "Высшая школа урбанистики имени А.А. Высоковского",
        "Факультет физики",
        "Факультет городского и регионального развития",
        "Базовая кафедра Музея современного искусства «Гараж»",
        "Международная лаборатория прикладного сетевого анализа (ANR-Lab)",
        "Факультет гуманитарных наук",
        "Факультет мировой экономики и мировой политики",
        "Высшая школа юриспруденции и администрирования",
        "Кафедра менеджмента инноваций",
        "Факультет креативных индустрий",
        "Факультет права",
        "Институт классического Востока и античности",
        "Школа дизайна",
        "Факультет социальных наук",
        "Институт статистических исследований и экономики знаний",
        "Институт когнитивных нейронаук",
        "Банковский институт",
        "Факультет экономических наук",
        "Институт образования",
        "Институт демографии имени А.Г. Вишневского",
        "Школа иностранных языков",
        "Факультет химии",
        "Институт проблем безопасности",
    ],
    "Государственный университет по землеустройству (ГУЗ)": [
        "Факультет землеустройства и управления природопользованием",
        "Факультет управления недвижимостью и права",
        "Архитектурный факультет",
        "Факультет кадастра недвижимости и инфраструктуры пространственных данных",
    ],
    "Московский гуманитарный университет (МосГУ)": ["Юридический факультет", "Факультет экономики, управления и международных отношений", "Факультет рекламы, журналистики, психологии и искусства"],
    "Всероссийская академия внешней торговли Министерства экономического развития Российской Федерации (ВАВТ Минэкономразвития РФ)": [
        "Факультет экономистов-международников",
        "Факультет международных финансов",
        "Факультет внешнеторгового менеджмента",
        "Факультет международного бизнеса",
        "Международно-правовой факультет",
    ],
    "Московский государственный гуманитарно-экономический университет (МГГЭУ)": [
        "Факультет цифровых технологий и кибербезопасности",
        "Факультет психологии и педагогики",
        "Факультет экономики",
        "Факультет социологии и журналистики",
        "Факультет юриспруденции",
        "Факультет иностранных языков",
    ],
    "Московский государственный медико-стоматологический университет имени А.И. Евдокимова (МГМСУ им. А.И. Евдокимова Минздрава России)": [
        "Экономический факультет",
        "Стоматологический факультет",
        "Лечебный факультет",
        "Факультет клинической психологии",
        "Факультет социальной работы",
    ],
    "Московский педагогический государственный университет (МПГУ)": [
        "Институт детства",
        "Институт изящных искусств",
        "Институт математики и информатики",
        "Дефектологический факультет",
        "Институт истории и политики",
        "Факультет дошкольной педагогики и психологии",
        "Факультет начального образования",
        "Институт социально-гуманитарного образования",
        "Институт педагогики и психологии",
        "Институт физики, технологии и информационных систем",
        "Географический факультет",
        "Институт журналистики, коммуникаций и медиаобразования",
        "Институт физической культуры, спорта и здоровья",
        "Институт международного образования",
        "Институт биологии и химии",
        "Институт иностранных языков",
        "Институт филологии",
    ],
    "Московская государственная академия ветеринарной медицины и биотехнологии – МВА имени К.И. Скрябина (МГАВМиБ – МВА имени К.И. Скрябина)": [
        "Факультет ветеринарной медицины",
        "Ветеринарно-биологический факультет",
        "Факультет заочного, очно-заочного (вечернего) образования",
        "Факультет зоотехнологий и агробизнеса",
        "Факультет товароведения и экспертизы сырья животного происхождения",
    ],
    "Российская государственная академия интеллектуальной собственности (РГАИС)": ["Юридический факультет", "Факультет управления интеллектуальной собственностью"],
    "Московский государственный технический университет гражданской авиации (МГТУ ГА)": [
        "Факультет авиационных систем и комплексов",
        "Факультет управления на воздушном транспорте",
        "Заочный факультет",
        "Механический факультет",
    ],
    "Санкт-Петербургский государственный университет аэрокосмического приборостроения (ГУАП)": [
        "Институт киберфизических систем",
        "Институт фундаментальной подготовки и технологических инноваций",
        "Институт информационных технологий и программирования",
        "Военный учебный центр",
        "Институт технологий предпринимательства и права",
        "Институт аэрокосмических приборов и систем",
        "Институт непрерывного и дистанционного образования",
        "Гуманитарный факультет",
        "Институт радиотехники и инфокоммуникационных технологий",
    ],
    "Российский государственный педагогический университет им. А.И. Герцена (РГПУ им. А.И. Герцена)": [
        "Институт детства",
        "Институт физической культуры и спорта",
        "Факультет математики",
        "Институт экономики и управления",
        "Институт востоковедения",
        "Институт иностранных языков",
        "Институт философии человека",
        "Институт истории и социальных наук",
        "Институт информационных технологий и технологического образования",
        "Факультет биологии",
        "Институт физики",
        "Институт музыки, театра и хореографии",
        "Факультет географии",
        "Филологический факультет",
        "Институт народов Севера",
        "Институт художественного образования",
        "Факультет химии",
        "Факультет безопасности жизнедеятельности",
        "Институт педагогики",
        "Институт дефектологического образования и реабилитации",
        "Юридический факультет",
        "Институт психологии",
    ],
    "Петербургский государственный университет путей сообщения Императора Александра I (ПГУПС)": [
        "Факультет «Транспортное строительство»",
        "Факультет безотрывных форм обучения",
        "Факультет «Транспортные и энергетические системы»",
        "Факультет «Управление перевозками и логистика»",
        "Факультет «Экономика и менеджмент»",
        "Факультет «Автоматизация и интеллектуальные технологии»",
        "Факультет «Промышленное и гражданское строительство»",
    ],
    "Российский биотехнологический университет (РОСБИОТЕХ)": [
        "Международный институт управления и бизнеса",
        "Институт промышленной инженерии, информационных технологий и мехатроники",
        "Институт пищевых систем и здоровьесберегающих технологий",
        "Институт биотехнологии и глобального здоровья",
        "Институт прикладной биотехнологии имени академика РАН И.А. Рогова",
        "Институт ветеринарии, ветеринарно-санитарной экспертизы и агробезопасности",
    ],
    "Всероссийский государственный институт кинематографии имени С.А. Герасимова (ВГИК)": [
        "Сценарно-киноведческий факультет",
        "Режиссерский факультет",
        "Продюсерский факультет",
        "Актерский факультет",
        "Художественный факультет",
        "Операторский факультет",
        "Факультет анимации и мультимедиа",
    ],
    "Курганский государственный университет (КГУ)": [
        "Институт педагогики, психологии и физической культуры",
        "Гуманитарный институт",
        "Политехнический институт",
        "Институт экономики и права",
        "Институт естественных наук",
        "Институт математики и интеллектуальных систем",
    ],
    "Самарский государственный медицинский университет (СамГМУ)": [
        "Институт фармации",
        "Институт сестринского образования",
        "Институт стоматологии",
        "Институт социально-гуманитарного и цифрового развития медицины",
        "Институт клинической медицины",
        "Институт педиатрии",
        "Институт профилактической медицины",
    ],
    "Новосибирский государственный медицинский университет (НГМУ)": [
        "Факультет социальной работы, клинической психологии и менеджмента",
        "Лечебный факультет",
        "Педиатрический факультет",
        "Стоматологический факультет",
        "Медико-профилактический факультет",
        "Фармацевтический факультет",
    ],
    "Башкирский государственный медицинский университет (БГМУ)": [
        "Факультет медико-профилактический с отделением биологии",
        "Лечебный факультет",
        "Фармацевтический факультет",
        "Педиатрический факультет",
        "Стоматологический факультет",
    ],
    "Тольяттинский государственный университет (ТГУ)": [
        "Институт права",
        "Архитектурно-строительный институт",
        "Институт физической культуры и спорта",
        "Гуманитарно-педагогический институт",
        "Институт финансов, экономики и управления",
        "Институт изобразительного и декоративно-прикладного искусства",
        "Институт химии и энергетики",
        "Институт инженерной и экологической безопасности",
        "Институт математики, физики и информационных технологий",
        "Институт машиностроения",
    ],
    "Пермский государственный медицинский университет имени академика Е.А. Вагнера (ПГМУ им. ак. Е.А. Вагнера)": [
        "Лечебный факультет",
        "Педиатрический факультет",
        "Стоматологический факультет",
        "Медико-профилактический факультет",
        "Факультет психолого–социальной работы и высшего сестринского образования",
    ],
    "Дальневосточная государственная академия физической культуры (ДВГАФК)": ["Факультет профессиональной подготовки"],
    "Воронежский государственный лесотехнический университет имени Г.Ф. Морозова (ВГЛТУ)": [
        "Автомобильный факультет",
        "Экономический факультет",
        "Лесной факультет",
        "Факультет компьютерных наук и технологий",
        "Машиностроительный факультет",
        "Лесопромышленный факультет",
    ],
    "Волгоградский государственный институт искусств и культуры (ВГИИК)": ["Факультет художественного творчества", "Факультет социально-культурной деятельности", "Факультет искусств"],
    "Ярославская государственная сельскохозяйственная академия (Ярославская ГСХА)": ["Инженерный факультет", "Агротехнологический факультет", "Факультет ветеринарии и зоотехнии"],
    "Нижегородский государственный педагогический университет имени Козьмы Минина (Мининский университет) (Мининский университет)": [
        "Факультет управления и социально-технических сервисов",
        "Факультет гуманитарных наук",
        "Факультет физической культуры и спорта",
        "Факультет информационных технологий",
        "Факультет психологии и педагогики",
        "Лингвистический факультет",
        "Естественно-географический факультет",
        "Факультет дизайна, изящных искусств и медиа-технологий",
    ],
    "Казанский государственный энергетический университет (КГЭУ)": ["Институт теплоэнергетики", "Институт электроэнергетики и электроники", "Институт цифровых технологий и экономики"],
    "Новосибирский государственный университет экономики и управления (НИНХ) (НГУЭУ)": [
        "Юридический факультет",
        "Факультет корпоративной экономики и предпринимательства",
        "Факультет государственного сектора",
    ],
    "Волгоградский филиал Российского экономического университета имени Г.В. Плеханова (Волгоградский филиал РЭУ им. Г.В. Плеханова)": ["Факультет экономики и управления"],
    "Самарский государственный технический университет (СамГТУ)": [
        "Электротехнический факультет",
        "Институт инженерно-экономического и гуманитарного образования",
        "Факультет машиностроения, металлургии и транспорта",
        "Факультет инженерных систем и природоохранного строительства",
        "Инженерно-технологический факультет",
        "Институт нефтегазовых технологий",
        "Строительно-технологический факультет",
        "Химико-технологический факультет",
        "Факультет пищевых производств",
        "Академия строительства и архитектуры",
        "Институт автоматики и информационных технологий",
        "Факультет промышленного и гражданского строительства",
        "Теплоэнергетический факультет",
        "Факультет архитектуры и дизайна",
    ],
    "Сибирский государственный университет науки и технологий им. М.Ф. Решетнева (СибГУ им. М.Ф. Решетнева)": [
        "Институт космической техники",
        "Институт информатики и телекоммуникаций",
        "Институт гражданской авиации и таможенного дела",
        "Институт лесных технологий",
        "Институт космических исследований и высоких технологий",
        "Институт менеджмента и международного бизнеса",
        "Институт химических технологий",
        "Институт заочного обучения",
        "Институт социального инжиниринга",
        "Институт инженерной экономики",
        "Институт машиноведения и мехатроники",
    ],
    "Нижегородский государственный технический университет им. Р.Е. Алексеева (НГТУ)": [
        "Институт транспортных систем",
        "Институт радиоэлектроники и информационных технологий",
        "Институт промышленных технологий машиностроения",
        "Институт экономики и управления",
        "Институт ядерной энергетики и технической физики",
        "Автозаводская высшая школа управления и технологий",
        "Институт физико-химических технологий и материаловедения",
        "Институт электроэнергетики",
    ],
    "Челябинский институт путей сообщения - филиал Уральского государственного университета путей сообщения (ЧИПС УрГУПС)": ["Факультет высшего образования"],
    "Уральский государственный экономический университет (УрГЭУ)": [
        "Институт менеджмента, предпринимательства и инжиниринга",
        "Институт экономики и финансов",
        "Институт государственного, муниципального управления и права",
        "Институт непрерывного и дистанционного образования",
        "Институт цифровых технологий управления и информационной безопасности",
    ],
    "Санкт-Петербургская государственная художественно-промышленная академия имени А.Л. Штиглица (СПГХПА им. А.Л. Штиглица)": ["Факультет дизайна", "Факультет монументально-декоративного  искусства"],
    "Саратовский государственный аграрный университет имени Н.И. Вавилова (СГАУ им. Н.И. Вавилова)": [
        "Агрономический факультет",
        "Факультет инженерии и природообустройства",
        "Факультет ветеринарной медицины, пищевых и биотехнологий",
        "Факультет экономики и менеджмента",
    ],
    "Донской казачий государственный институт пищевых технологий и бизнеса (филиал) Московского государственного университета технологий и управления им. К.Г. Разумовского (Первого казачьего университета) (ДКГИПТиБ (филиал) МГУТУ им. К.Г. Разумовского (ПКУ))":
        ["Факультет технологического менеджмента", "Факультет управления и информационных технологий"],
    "Кировский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Кировский филиал РАНХиГС)": [
        "Юридический факультет",
        "Факультет экономики и управления",
    ],
    "Одинцовский филиал Московского государственного института международных отношений (университета) Министерства иностранных дел Российской Федерации (Одинцовский филиал МГИМО МИД России)": [
        "Факультет лингвистики и межкультурной коммуникации",
        "Международно-правовой факультет",
        "Факультет финансовой экономики",
        "Международный институт энергетической политики и управления инновациями",
        "Факультет управления и политики",
    ],
    "Московский социально-экономический институт (МСЭИ)": ["Факультет высшего образования"],
    "Кабардино-Балкарский государственный аграрный университет им. В.М. Кокова (Кабардино-Балкарский ГАУ)": [
        "Факультет экономики и управления",
        "Торгово-технологический факультет",
        "Агрономический факультет",
        "Факультет ветеринарной медицины и биотехнологии",
        "Факультет механизации и энергообеспечения предприятий",
        "Факультет строительства и землеустройства",
    ],
    "Северо-Кавказский социальный институт (СКСИ)": ["Факультет информационных систем и технологий", "Социально-психологический факультет", "Экономический факультет", "Юридический факультет"],
    "Алтайский государственный технический университет им. И.И. Ползунова (АлтГТУ)": [
        "Факультет специальных технологий",
        "Институт архитектуры и дизайна",
        "Факультет энергомашиностроения и автомобильного транспорта",
        "Институт биотехнологии, пищевой и химической инженерии",
        "Энергетический факультет",
        "Институт экономики и управления",
        "Заочный институт",
        "Строительно-технологический факультет",
        "Факультет информационных технологий",
    ],
    "Дагестанский государственный медицинский университет (ДГМУ)": [
        "Медико-профилактический факультет",
        "Лечебный факультет",
        "Фармацевтический факультет",
        "Педиатрический факультет",
        "Стоматологический факультет",
    ],
    "Дагестанский государственный аграрный университет имени М.М. Джамбулатова (ДагГАУ)": [
        "Экономический факультет",
        "Автомобильный факультет",
        "Инженерный факультет",
        "Факультет биотехнологии",
        "Факультет агроэкологии",
        "Факультет ветеринарной медицины",
        "Технологический факультет",
    ],
    "Дагестанский гуманитарный институт (ДГИ)": ["Гуманитарно-экономический факультет"],
    "Брянский государственный университет имени академика И.Г. Петровского (БГУ)": [
        "Естественно-географический факультет",
        "Финансово-экономический факультет",
        "Институт экономики, истории и права",
        "Факультет педагогики и психологии",
        "Юридический факультет",
        "Институт русской и романо-германской филологии",
        "Факультет технологии и дизайна",
        "Факультет истории и международных отношений",
        "Институт педагогики и психологии",
        "Факультет физической культуры",
        "Естественно-научный институт",
        "Филологический факультет",
        "Физико-математический факультет",
        "Факультет иностранных языков",
    ],
    "Брянский государственный технический университет (БГТУ)": [
        "Факультет отраслевой и цифровой экономики",
        "Факультет информационных технологий",
        "Учебно-научный институт транспорта",
        "Факультет энергетики и электроники",
        "Учебно-научный технологический институт",
        "Механико-технологический факультет",
    ],
    "Пензенский государственный технологический университет (ПензГТУ)": [
        "Факультет промышленных технологий",
        "Факультет биотехнологий",
        "Факультет автоматизированных информационных технологий",
        "Факультет заочного обучения",
    ],
    "Ульяновский государственный аграрный университет имени П.А. Столыпина (Ульяновский ГАУ)": [
        "Факультет агротехнологий, земельных ресурсов и пищевых производств",
        "Факультет ветеринарной медицины и биотехнологии",
        "Инженерный факультет",
        "Экономический факультет",
    ],
    "Тверской государственный технический университет (ТвГТУ)": [
        "Факультет информационных технологий",
        "Химико-технологический факультет",
        "Факультет управления и социальных коммуникаций",
        "Машиностроительный факультет",
        "Институт заочного и дополнительного профессионального образования",
        "Факультет природопользования и инженерной экологии",
        "Инженерно-строительный факультет",
    ],
    "Владимирский государственный университет имени Александра Григорьевича и Николая Григорьевича Столетовых (ВлГУ)": [
        "Юридический институт",
        "Педагогический институт",
        "Институт прикладной математики, физики и информатики",
        "Институт машиностроения и автомобильного транспорта",
        "Институт туризма и предпринимательства",
        "Институт биологии и экологии",
        "Гуманитарный институт",
        "Институт искусств и художественного образования",
        "Институт архитектуры, строительства и энергетики",
        "Институт экономики и менеджмента",
        "Институт информационных технологий и радиоэлектроники",
        "Институт физической культуры и спорта",
    ],
    "Орловский государственный аграрный университет имени Н.В. Парахина (Орловский ГАУ)": [
        "Факультет агробизнеса и экологии",
        "Экономический факультет",
        "Факультет агротехники и энергообеспечения",
        "Факультет биотехнологии и ветеринарной медицины",
        "Инженерно-строительный институт",
    ],
    "Коми республиканская академия государственной службы и управления (КРАГСиУ)": ["Юридический факультет", "Факультет управления"],
    "Академия социального управления (АСОУ)": ["Факультет образовательных программ"],
    "Воронежский институт Федеральной службы исполнения наказаний России (Воронежский институт ФСИН России)": ["Инженерно-технический факультет", "Юридический факультет"],
    "Гжельский государственный университет (ГГУ)": ["Институт изобразительного искусства и дизайна", "Институт социально-гуманитарного образования", "Институт заочного обучения"],
    "Елецкий государственный университет им. И.А. Бунина (ЕГУ им. И.А. Бунина)": [
        "Институт психологии и педагогики",
        "Институт физической культуры, спорта и безопасности жизнедеятельности",
        "Институт филологии",
        "Институт культуры, истории и права",
        "Медицинский факультет",
        "Агропромышленный институт",
        "Институт математики, естествознания и техники",
        "Институт экономики, управления и сервисных технологий",
    ],
    "Ивановский государственный университет (ИвГУ)": [
        "Институт гуманитарных наук",
        "Институт социально-экономических наук",
        "Институт математики, информационных технологий и естественных наук",
        "Юридический факультет",
    ],
    "Новомосковский институт (филиал) Российского химико-технологического университета имени Д.И. Менделеева (НИ РХТУ)": [
        "Факультет кибернетики",
        "Факультет экономики и управления",
        "Факультет заочного и очно-заочного обучения",
        "Химико-технологический факультет",
        "Энерго-механический факультет",
    ],
    "Пущинский государственный естественно-научный институт (ПущГЕНИ)": [
        "Факультет биомедицинской инженерии",
        "Факультет микробиологии и биотехнологии",
        "Факультет промышленных технологий",
        "Факультет БиоМедФармТехнологический",
        "Факультет экономики, управления и права",
        "Факультет цифровой технологии",
    ],
    "Российский институт театрального искусства – ГИТИС (ГИТИС)": [
        "Актерский факультет",
        "Продюсерский факультет",
        "Режиссерский факультет",
        "Факультет сценографии",
        "Факультет музыкального театра",
        "Театроведческий факультет",
        "Балетмейстерский факультет",
        "Факультет новых направлений сценических искусств",
    ],
    "Санкт-Петербургская юридическая академия (СЮА)": ["Факультет юриспруденции"],
    "Великолукская государственная академия физической культуры и спорта (ВЛГАФК)": ["Социально-гуманитарный факультет", "Факультет физического воспитания и спорта", "Факультет заочного обучения"],
    "Крымский филиал Российского государственного университета правосудия (Крымский филиал РГУП)": ["Факультет подготовки специалистов для судебной системы (юридический факультет)"],
    "Сочинский государственный университет (СГУ)": [
        "Факультет экономики и права",
        "Инженерно-экологический факультет",
        "Факультет информационных технологий и математики",
        "Социально-педагогический факультет",
        "Факультет туризма и сервиса",
    ],
    "Южный университет (ИУБиП) (ЮУ (ИУБиП))": [
        "Академия экономики и управления",
        "Академия государственной службы и управления",
        "Академия права и национальной безопасности",
        "Академия цифрового развития",
        "Академия гуманитарных технологий",
    ],
    "Новочеркасский инженерно-мелиоративный институт имени А.К. Кортунова - филиал Донского государственного аграрного университета (НИМИ – филиал Донского ГАУ)": [
        "Лесохозяйственный факультет",
        "Факультет механизации",
        "Факультет бизнеса и социальных технологий",
        "Землеустроительный факультет",
        "Инженерно-мелиоративный факультет",
    ],
    "Таганрогский институт управления и экономики (ТИУиЭ)": ["Факультет управления", "Юридический факультет", "Экономический факультет"],
    "Северо-Кавказский горно-металлургический институт (государственный технологический университет) (СКГМИ (ГТУ))": [
        "Горно-металлургический факультет",
        "Факультет заочного обучения",
        "Электромеханический факультет",
        "Архитектурно-строительный факультет",
        "Факультет информационных технологий и электронной техники",
        "Экономический факультет",
        "Юридический факультет",
    ],
    "Пятигорский институт (филиал) Северо-Кавказского федерального университета (Пятигорский институт (филиал) СКФУ)": [
        "Юридический факультет",
        "Инженерный факультет",
        "Школа Кавказского гостеприимства",
        "Факультет экономики и управления",
    ],
    "Елабужский институт (филиал) Казанского (Приволжского) федерального университета (Елабужский институт КФУ)": [
        "Отделение психологии и педагогики",
        "Отделение филологии и истории",
        "Отделение экономических и юридических наук",
        "Инженерно-технологическое отделение",
        "Отделение иностранных языков",
        "Отделение математики и естественных наук",
    ],
    "Нижегородский институт (филиал) Московского гуманитарно-экономического университета (Нижегородский институт (филиал) МГЭУ)": ["Факультет экономики и управления", "Гуманитарный факультет"],
    "Гуманитарный университет (ГУ)": [
        "Факультет социальной психологии",
        "Факультет журналистики и медиакоммуникаций",
        "Факультет бизнеса и управления",
        "Юридический факультет",
        "Факультет современного танца",
        "Факультет компьютерных технологий",
    ],
    "Филиал Южно-Уральского государственного университета (национального исследовательского университета) в г. Миассе (Филиал ЮУрГУ (НИУ) в г. Миассе)": [
        "Геологический факультет",
        "Машиностроительный факультет",
        "Факультет экономики, управления, права",
        "Электротехнический факультет",
    ],
    "Рубцовский индустриальный институт (филиал) Алтайского государственного технического университета имени И.И. Ползунова (РИИ АлтГТУ)": [
        "Технический факультет",
        "Факультет заочной формы обучения",
    ],
    "Куйбышевский филиал Новосибирского государственного педагогического университета (КФ НГПУ)": ["Факультет психолого-педагогического образования"],
    "Западно-Сибирский филиал Российского государственного университета правосудия (ЗСФ РГУП)": ["Факультет подготовки специалистов для судебной системы (юридический факультет)"],
    "Амурский гуманитарно-педагогический государственный университет (АмГПГУ)": [
        "Институт педагогики и психологии",
        "Факультет информационных технологий, математики и физики",
        "Институт заочного и дополнительного образования",
        "Факультет истории и юриспруденции",
        "Естественно-географический факультет",
        "Факультет технологии, экономики, дизайна",
        "Факультет филологии и межкультурной коммуникации",
    ],
    "Северо-Восточный государственный университет (СВГУ)": ["Институт цифровых технологий, экономики и права", "Политехнический институт"],
    "Южный федеральный университет в г. Таганроге (ЮФУ в г. Таганроге)": [
        "Институт радиотехнических систем и управления",
        "Институт нанотехнологий, электроники и приборостроения",
        "Институт компьютерных технологий и информационной безопасности",
        "Институт управления в экономических, экологических и социальных системах",
    ],
    "Научно-технологический университет «Сириус» (ЮФУ в г. Таганроге)": ["Научный центр генетики и наук о жизни"],
    "Научно-технологический университет «Сириус» (Университет Сириус)": [
        "Научный центр информационных технологий и искусственного интеллекта",
        "Научный центр трансляционной медицины",
        "Центр урбанистики и дизайна окружающей среды",
    ],
    "Международная ветеринарная академия (МВА)": ["Факультет ветеринарной медицины", "Факультет менеджмента в зообизнесе"],
    "Государственный университет управления (ГУУ)": [
        "Институт управления персоналом, социальных и бизнес-коммуникаций",
        "Институт экономики и финансов",
        "Институт маркетинга",
        "Институт отраслевого менеджмента",
        "Институт заочного образования",
        "Институт государственного управления и права",
        "Институт информационных систем",
    ],
    "Российская академия народного хозяйства и государственной службы при Президенте Российской Федерации (РАНХиГС)": [
        "Институт общественных наук",
        "Отделение прикладной информатики",
        "Факультет государственного управления экономикой",
        "Факультет рыночных технологий",
        "Институт бизнеса и делового администрирования",
        "Институт отраслевого менеджмента",
        "Школа IT-менеджмента",
        "Факультет международного бизнеса и делового администрирования",
        "Факультет инженерного менеджмента",
        "Факультет международного регионоведения и регионального управления",
        "Факультет журналистики",
        "Факультет управления в медицине и здравоохранении",
        "Высшая школа корпоративного управления",
        "Отделение национальной экономики",
        "Факультет международных отношений",
        "Факультет управления персоналом и государственной службы",
        "Факультет гостеприимства",
        "Высшая школа финансов и менеджмента",
        "Институт экономики, математики и информационных технологий",
        "Институт права и национальной безопасности",
        "Международный центр программ и проектов в бизнес-образовании",
        "Институт государственной службы и управления",
        "Отделение экономики",
        "Факультет финансов и банковского дела",
        "Высшая школа правоведения",
        "Институт финансов и устойчивого развития",
        "Институт управления и регионального развития",
        "Отделение бизнес-информатики",
        "Факультет экономических и социальных наук",
        "Факультет государственного и муниципального управления",
    ],
    "Российский национальный исследовательский медицинский университет имени Н.И. Пирогова (РНИМУ им. Н.И. Пирогова)": [
        "Педиатрический факультет",
        "Медико-биологический факультет",
        "Институт клинической психологии и социальной работы",
        "Стоматологический факультет",
        "Лечебный факультет",
    ],
    "Московский государственный технический университет имени Н.Э. Баумана (национальный исследовательский университет) (МГТУ им. Н.Э. Баумана)": [
        "Факультет «Радиоэлектроника и лазерная техника»",
        "Факультет «Биомедицинская техника»",
        "Факультет «Инженерный бизнес и менеджмент»",
        "Факультет «Социальные и гуманитарные науки»",
        "Факультет «Юриспруденция»",
        "Факультет «Информатика, искусственный интеллект и системы управления»",
        "Факультет «Ракетно-космическая техника»",
        "Факультет «Робототехника и комплексная автоматизация»",
        "Факультет «Специальное машиностроение»",
        "Аэрокосмический факультет",
        "Факультет «Лингвистика»",
        "Факультет «Машиностроительные технологии»",
        "Приборостроительный факультет",
        "Факультет «Фундаментальные науки»",
        "Факультет «Энергомашиностроение»",
        "Радиотехнический факультет",
    ],
    "Государственный университет «Дубна» (Государственный университет Дубна)": [
        "Институт системного анализа и управления",
        "Факультет естественных и инженерных наук",
        "Факультет социальных и гуманитарных наук",
        "Инженерно-физический институт",
    ],
    "Санкт-Петербургский горный университет (Горный университет)": [
        "Механико-машиностроительный факультет",
        "Энергетический факультет",
        "Экономический факультет",
        "Горный факультет",
        "Строительный факультет",
        "Геологоразведочный факультет",
        "Нефтегазовый факультет",
        "Факультет переработки минерального сырья",
        "Факультет фундаментальных и гуманитарных дисциплин",
    ],
    "Российский государственный гидрометеорологический университет (РГГМУ)": [
        "Институт «Полярная Академия»",
        "Экологический факультет",
        "Метеорологический факультет",
        "Институт морского права, экономики и управления",
        "Институт информационных систем и геотехнологий",
        "Институт гидрологии и океанологии",
    ],
    "Санкт-Петербургский государственный аграрный университет (СПбГАУ)": [
        "Факультет зооинженерии и биотехнологий",
        "Факультет землеустройства и сельскохозяйственного строительства",
        "Электроэнергетический факультет",
        "Инженерно-технологический факультет",
        "Факультет агротехнологий, почвоведения и экологии",
        "Юридический факультет",
        "Факультет экономики и организации в АПК",
    ],
    "Уральский государственный медицинский университет (УГМУ)": [
        "Фармацевтический факультет",
        "Лечебно-профилактический факультет",
        "Педиатрический факультет",
        "Факультет психолого-социальной работы и высшего сестринского образования",
        "Медико-профилактический факультет",
        "Cтоматологический факультет",
    ],
    "Тюменский государственный университет (ТюмГУ)": [
        "Институт социально-гуманитарных наук",
        "Институт наук о Земле",
        "Физико-технический институт",
        "Школа исследований окружающей среды и общества (Антропошкола)",
        "Финансово-экономический институт",
        "Институт психологии и педагогики",
        "Школа перспективных исследований (School of Advanced Studies, SAS)",
        "Институт биологии",
        "Институт физической культуры",
        "Институт экологической и сельскохозяйственной биологии (X-BIO)",
        "Институт государства и права",
        "Институт химии",
        "Политехническая школа",
        "Институт математики и компьютерных наук",
        "Школа естественных наук",
    ],
    "Южный федеральный университет в г. Ростове-на-Дону (ЮФУ в г. Ростове-на-Дону)": [
        "Физический факультет",
        "Юридический факультет",
        "Химический факультет",
        "Институт философии и социально-политических наук",
        'Факультет бизнеса "Капитаны"',
        "Институт наук о Земле",
        "Академия психологии и педагогики",
        "Институт истории и международных отношений",
        "Международный исследовательский институт интеллектуальных материалов",
        'Центр научных исследований "Инструментальные, математические и интеллектуальные средства в экономике"',
        "Научно-исследовательский институт физики",
        "Институт математики, механики и компьютерных наук им. И.И. Воровича",
        "Академия биологии и биотехнологии",
        "Институт социологии и регионоведения",
        "Институт филологии, журналистики и межкультурной коммуникации",
        "Научно-исследовательский институт физической и органической химии",
        "Центр устойчивого развития",
        "Факультет управления",
        "Академия архитектуры и искусств",
        "Экономический факультет",
        "Высшая школа бизнеса",
        "Международный институт междисциплинарного образования и иберо-американских исследований",
        "Институт высоких технологий и пьезотехники",
        "Академия физической культуры и спорта",
    ],
    "Самарский государственный социально-педагогический университет (СГСПУ)": [
        "Исторический факультет",
        "Факультет физической культуры и спорта",
        "Филологический факультет",
        "Естественно-географический факультет",
        "Факультет математики, физики и информатики",
        "Факультет экономики, управления и сервиса",
        "Факультет начального образования",
        "Факультет иностранных языков",
        "Факультет культуры и искусства",
        "Факультет психологии и специального образования",
    ],
    "Кубанский государственный аграрный университет имени И.Т. Трубилина (КубГАУ)": [
        "Факультет агрономии и экологии",
        "Землеустроительный факультет",
        "Факультет пищевых производств и биотехнологий",
        "Факультет управления",
        "Институт цифровой экономики и инноваций",
        "Архитектурно-строительный факультет",
        "Факультет зоотехнии",
        "Юридический факультет",
        "Факультет прикладной информатики",
        "Факультет агрохимии и защиты растений",
        "Факультет заочного обучения",
        "Факультет ветеринарной медицины",
        "Факультет энергетики",
        "Учетно-финансовый факультет",
        "Факультет плодоовощеводства и виноградарства",
        "Факультет финансов и кредита",
        "Факультет гидромелиорации",
        "Факультет механизации",
        "Экономический факультет",
    ],
    "Красноярский государственный медицинский университет имени профессора В.Ф. Войно-Ясенецкого (КрасГМУ им. проф. В.Ф. Войно-Ясенецкого)": [
        "Педиатрический факультет",
        "Медико-психолого-фармацевтический факультет",
        "Стоматологический факультет",
        "Лечебный факультет",
    ],
    "Национальный исследовательский Томский государственный университет (НИ ТГУ)": [
        "Институт искусств и культуры",
        "Химический факультет",
        "Физический факультет",
        "Институт биомедицины",
        "Механико-математический факультет",
        "Институт прикладной математики и компьютерных наук",
        "Центр исследований Транссибирский научный путь",
        "Научно-образовательный центр «Высшая ИТ-школа (HITs)»",
        "Институт экономики и менеджмента",
        "Институт биологии, экологии, почвоведения, сельского и лесного хозяйства",
        "Факультет иностранных языков",
        "Факультет психологии",
        "Геолого-географический факультет",
        "Факультет инновационных технологий",
        "САЕ Институт «Умные материалы и технологии»",
        "Факультет исторических и политических наук",
        "Философский факультет",
        "Радиофизический факультет",
        "Факультет физической культуры",
        "Институт образования",
        "Филологический факультет",
        "Юридический институт",
        "Факультет журналистики",
        "Физико-технический факультет",
        "Институт человека цифровой эпохи",
    ],
    "Дальневосточный государственный медицинский университет (ДВГМУ)": [
        "Лечебный факультет",
        "Педиатрический факультет",
        "Стоматологический факультет",
        "Факультет фармации и биомедицины",
        "Медико-гуманитарный факультет",
    ],
    "Хабаровский государственный университет экономики и права (ХГУЭП)": [
        "Факультет международных экономических отношений",
        "Факультет управления и технологий",
        "Экономический факультет",
        "Юридический факультет",
    ],
    "Ставропольский государственный медицинский университет (СтГМУ)": [
        "Стоматологический факультет",
        "Факультет гуманитарного и медико-биологического образования",
        "Лечебный факультет",
        "Педиатрический факультет",
    ],
    "Кемеровский государственный медицинский университет (КемГМУ)": [
        "Фармацевтический факультет",
        "Лечебный факультет",
        "Стоматологический факультет",
        "Медико-профилактический факультет",
        "Педиатрический факультет",
    ],
    "Кемеровский государственный институт культуры (КемГИК)": [
        "Факультет хореографии",
        "Факультет режиссуры и актерского искусства",
        "Факультет визуальных искусств",
        "Факультет социально-культурных технологий",
        "Социально-гуманитарный факультет",
        "Факультет информационных, библиотечных и музейных технологий",
        "Факультет музыкального искусства",
    ],
    "Волгоградский государственный университет (ВолГУ)": [
        "Институт математики и информационных технологий",
        "Институт истории, международных отношений и социальных технологий",
        "Институт права",
        "Институт естественных наук",
        "Институт филологии и межкультурной коммуникации",
        "Институт приоритетных технологий",
        "Институт экономики и управления",
    ],
    "Ярославский государственный педагогический университет им. К.Д. Ушинского (ЯГПУ им. К.Д. Ушинского)": [
        "Естественно-географический факультет",
        "Факультет социального управления",
        "Факультет иностранных языков",
        "Факультет физической культуры",
        "Педагогический факультет",
        "Исторический факультет",
        "Факультет русской филологии и культуры",
        "Физико-математический факультет",
        "Дефектологический факультет",
    ],
    "Ханты-Мансийская государственная медицинская академия (ХМГМА)": ["Лечебный факультет"],
    "Новосибирский государственный архитектурно-строительный университет (Сибстрин) (НГАСУ (Сибстрин))": [
        "Факультет вечернего и заочного обучения",
        "Институт архитектуры и градостроительства",
        "Инженерно-экологический факультет",
        "Институт строительства",
        "Институт цифровых и инженерных технологий",
    ],
    "Новосибирский государственный аграрный университет (Новосибирский ГАУ)": [
        "Факультет экономики и управления",
        "Юридический факультет",
        "Агрономический факультет",
        "Биолого-технологический факультет",
        "Факультет ветеринарной медицины",
        "Инженерный институт",
    ],
    "Волгоградский государственный технический университет (ВолгГТУ)": [
        "Факультет строительства и жилищно-коммунального хозяйства",
        "Факультет транспортных, инженерных систем и техносферной безопасности",
        "Химико-технологический факультет",
        "Факультет автоматизированных систем, транспорта и вооружений",
        "Факультет дистанционного обучения",
        "Факультет экономики и управления",
        "Факультет автомобильного транспорта",
        "Факультет технологии пищевых производств",
        "Факультет подготовки инженерных кадров",
        "Институт архитектуры и строительства",
        "Факультет технологии конструкционных материалов",
        "Кировский вечерний факультет",
        "Факультет послевузовского образования",
        "Факультет электроники и вычислительной техники",
        "Факультет архитектуры и градостроительного развития",
    ],
    "Воронежский филиал Российского экономического университета имени Г.В. Плеханова (Воронежский филиал РЭУ им. Г.В. Плеханова)": ["Экономический факультет", "Факультет обслуживания и управления"],
    "Институт гуманитарного образования и информационных технологий (ИГУМО и ИТ)": ["Факультет фотографии", "Факультет дизайна"],
    "Самарский государственный экономический университет (СГЭУ)": ["Институт национальной и мировой экономики", "Институт менеджмента", "Институт экономики предприятий", "Институт права"],
    "Санкт-Петербургская академия Следственного комитета Российской Федерации (Санкт-Петербургская академия Следственного комитета)": ["Юридический факультет"],
    "Университет прокуратуры Российской Федерации (УП РФ)": ["Юридический факультет"],
    "Санкт-Петербургский институт (филиал) Всероссийского государственного университета юстиции (РПА Минюста России) (Санкт-Петербургский институт (филиал) ВГУЮ (РПА Минюста России))": [
        "Юридический факультет",
    ],
    "Томский государственный архитектурно-строительный университет (ТГАСУ)": [
        "Механико-технологический факультет",
        "Общеобразовательный факультет",
        "Архитектурный факультет",
        "Заочный факультет",
        "Строительный факультет",
        "Институт кадастра, экономики и инженерных систем в строительстве",
        "Дорожно-строительный факультет",
    ],
    "Пермский национальный исследовательский политехнический университет (ПНИПУ)": [
        "Механико-технологический факультет",
        "Горно-нефтяной факультет",
        "Факультет прикладной математики и механики",
        "Факультет химических технологий, промышленной экологии и биотехнологий",
        "Строительный факультет",
        "Гуманитарный факультет",
        "Электротехнический факультет",
        "Аэрокосмический факультет",
    ],
    "Пермский государственный аграрно­-технологический университет имени академика Д.Н. Прянишникова (Пермский ГАТУ)": [
        "Факультет ветеринарной медицины и зоотехнии",
        "Факультет экономики и информационных технологий",
        "Факультет почвоведения, агрохимии, экологии и товароведения",
        "Факультет заочного обучения",
        "Факультет агротехнологий и лесного хозяйства",
        "Факультет землеустройства, кадастра и строительных технологий",
        "Инженерный факультет",
    ],
    "Саровский физико-технический институт - филиал Национального исследовательского ядерного университета МИФИ (СарФТИ НИЯУ МИФИ)": [
        "Факультет информационных технологий и электроники",
        "Экономико-математический факультет",
        "Физико-технический факультет",
    ],
    "Сибирский университет потребительской кооперации (СибУПК)": ["Факультет экономики и управления", "Торгово-технологический факультет", "Юридический факультет"],
    "Санкт-Петербургский филиал Финансового университета при Правительстве Российской Федерации (Санкт-Петербургский филиал Финуниверситета​)": ["Финансово-экономический факультет"],
    "Международный юридический институт (МЮИ)": ["Юридический факультет"],
    "Нижегородский государственный инженерно-экономический университет (НГИЭУ)": [
        "Инженерный институт",
        "Институт экономики и управления",
        "Институт информационных технологий и систем связи",
        "Институт транспорта, сервиса и туризма",
    ],
    "Белорусско-Российский университет (Белорусско-Российский университет)": ["Инженерно-экономический факультет"],
    "Уральский государственный аграрный университет (УрГАУ)": [
        "Факультет биотехнологии и пищевой инженерии",
        "Факультет инженерных технологий",
        "Институт экономики, финансов и менеджмента",
        "Факультет ветеринарной медицины и экспертизы",
        "Факультет агротехнологий и землеустройства",
    ],
    "Ижевская государственная сельскохозяйственная академия (ИжГСХА)": [
        "Инженерный факультет",
        "Факультет энергетики и электрификации",
        "Агрономический факультет",
        "Экономический факультет",
        "Зооинженерный факультет",
        "Факультет заочного обучения",
        "Факультет ветеринарной медицины",
        "Лесохозяйственный факультет",
    ],
    "Иркутский национальный исследовательский технический университет (ИРНИТУ)": [
        "Институт недропользования",
        "Институт информационных технологий и анализа данных",
        "Институт экономики, управления и права",
        "Байкальский институт БРИКС",
        "Институт архитектуры, строительства и дизайна",
        "Институт энергетики",
        "Институт авиамашиностроения и транспорта",
        "Институт высоких технологий",
        "Институт заочно-вечернего обучения",
    ],
    "Институт менеджмента, маркетинга и финансов (ИММиФ)": ["Экономический факультет"],
    "Оренбургский государственный аграрный университет (ОГАУ)": [
        "Инженерный факультет",
        "Факультет агротехнологий, землеустройства и пищевых производств",
        "Факультет экономики и права",
        "Факультет ветеринарной медицины",
    ],
    "Московский университет имени А.С. Грибоедова (ИМПЭ им. А. С. Грибоедова)": [
        "Факультет лингвистики",
        "Институт международной экономики, лидерства и менеджмента",
        "Юридический факультет",
        "Факультет журналистики",
        "Факультет психологии",
    ],
    "Вятский государственный агротехнологический университет (Вятский ГАТУ)": [
        "Биологический факультет",
        "Факультет ветеринарной медицины",
        "Инженерный факультет",
        "Экономический факультет",
        "Агрономический факультет",
    ],
    "Дагестанский государственный технический университет (ДГТУ)": [
        "Факультет нефти, газа и природообустройства",
        "Архитектурно-строительный факультет",
        "Факультет компьютерных технологий, вычислительной техники и энергетики",
        "Факультет права и управления на транспорте",
        "Факультет информационных систем в экономике и управлении",
        "Заочный факультет",
        "Факультет радиоэлектроники, телекоммуникаций и мультимедийных технологий",
        "Технологический факультет",
    ],
    "Северо-Кавказский институт (филиал) Всероссийского государственного университета юстиции (РПА Минюста России) (Северо-Кавказский институт (филиал) ВГУЮ (РПА Минюста России))": [
        "Юридический факультет",
    ],
    "Ульяновский институт гражданской авиации имени Главного маршала авиации Б.П. Бугаева (УИ ГА)": [
        "Факультет лётной эксплуатации и управления воздушным движением",
        "Факультет подготовки авиационных специалистов",
    ],
    "Ростовский государственный университет путей сообщения (РГУПС)": [
        "Факультет «Управление процессами перевозок»",
        "Факультет «Дорожно-строительные машины»",
        "Электромеханический факультет",
        "Факультет «Экономика, управление и право»",
        "Факультет «Информационные технологии управления»",
        "Гуманитарный факультет",
        "Энергетический факультет",
        "Строительный факультет",
    ],
    "Астраханский государственный медицинский университет (Астраханский ГМУ)": [
        "Стоматологический факультет",
        "Лечебный факультет",
        "Медико-профилактический факультет",
        "Факультет клинической психологии",
        "Фармацевтический факультет",
        "Факультет менеджмента и высшего сестринского образования",
        "Педиатрический факультет",
    ],
    "Читинская государственная медицинская академия (ЧГМА)": ["Стоматологический факультет", "Лечебный факультет", "Педиатрический факультет"],
    "Кузбасский гуманитарно-педагогический институт (филиал) Кемеровского государственного университета (КГПИ КемГУ)": [
        "Факультет физической культуры, естествознания и природопользования",
        "Факультет филологии",
        "Факультет информатики, математики и экономики",
        "Факультет психологии и педагогики",
        "Факультет истории и права",
    ],
    "Восточно-Сибирский государственный университет технологий и управления (ВСГУТУ)": [
        "Строительный факультет",
        "Машиностроительный факультет",
        "Институт пищевой инженерии и биотехнологии",
        "Электротехнический факультет",
        "Юридический факультет",
        "Факультет экономики и управления",
        "Факультет экологии, сервиса, технологии и дизайна",
    ],
    "Орловский государственный институт культуры (ОГИК)": [
        "Факультет художественного творчества",
        "Факультет социально-культурной деятельности",
        "Факультет документных коммуникаций",
        "Факультет заочного обучения",
    ],
    "Сыктывкарский лесной институт (филиал) Санкт-Петербургского государственного лесотехнического университета имени С.М. Кирова (Сыктывкарский лесной институт (филиал) СПбГЛТУ)": [
        "Транспортно-технологический факультет",
        "Факультет лесного и сельского хозяйства",
    ],
    "Воронежский институт экономики и социального управления (ВИЭСУ)": ["Факультет экономики, государственного и муниципального управления"],
    "Государственный социально-гуманитарный университет (ГСГУ)": [
        "Факультет истории, управления и сервиса",
        "Филологический факультет",
        "Факультет математики, физики, химии, информатики",
        "Экономический факультет",
        "Педагогический факультет",
        "Юридический факультет",
        "Социально-психологический факультет",
        "Технологический факультет",
        "Факультет иностранных языков",
        "Факультет физической культуры и спорта",
    ],
    "Ивановский государственный химико-технологический университет (ИГХТУ)": [
        "Факультет неорганической химии и технологии",
        "Факультет органической химии и технологии",
        "Факультет техники, управления и цифровой инфраструктуры",
        "Факультет заочного обучения и адресной профессиональной подготовки",
    ],
    "Ковровская государственная технологическая академия имени В.А. Дегтярева (КГТА им. Дегтярева)": [
        "Факультет экономики и менеджмента",
        "Механико-технологический факультет",
        "Факультет автоматики и электроники",
    ],
    "Курская государственная сельскохозяйственная академия имени И.И. Иванова (Курская ГСХА им. И. И. Иванова)": [
        "Инженерный факультет",
        "Экономический факультет",
        "Агротехнологический факультет",
        "Факультет ветеринарной медицины",
        "Зооинженерный факультет",
    ],
    "Рыбинский государственный авиационный технический университет имени П.А. Соловьева (РГАТУ им. П.А. Соловьева)": [
        "Социально-экономический факультет",
        "Факультет заочного обучения",
        "Факультет авиадвигателестроения",
        "Авиатехнологический факультет",
        "Факультет радиоэлектроники и информатики",
    ],
    "Балтийская академия туризма и предпринимательства (БАТиП)": ["Факультет экономики, менеджмента и предпринимательства", "Факультет туризма и сервиса"],
    "Великолукская государственная сельскохозяйственная академия (Великолукская ГСХА)": [
        "Заочный факультет",
        "Экономический факультет",
        "Инженерный факультет",
        "Факультет технологии животноводства и агроэкологии",
    ],
    "Северо-Западный институт (филиал) Московского гуманитарно-экономического университета (СЗИ (ф) МГЭУ)": ["Факультет экономики и управления", "Юридический факультет"],
    "Крымский федеральный университет имени В.И. Вернадского (КФУ им. В.И. Вернадского)": [
        "Институт экономики и управления",
        "Медицинская академия имени С.И. Георгиевского",
        "Институт медиакоммуникаций, медиатехнологий и дизайна",
        "Таврическая академия",
        "Институт филологии",
        "Физико-технический институт",
        "Академия строительства и архитектуры",
        "Агротехнологическая академия",
    ],
    "Университет экономики и управления (УЭУ)": ["Факультет экономики и управления"],
    "Армавирский социально-психологический институт (АСПИ)": ["Факультет высшего образования"],
    "Азово-Черноморский инженерный институт — филиал Донского государственного аграрного университета в г. Зернограде (АЧИИ ДонГАУ)": [
        "Энергетический факультет",
        "Инженерно-технологический факультет",
        "Факультет экономики и управления территориями",
    ],
    "Волгодонский инженерно-технический институт - филиал Национального исследовательского ядерного университета «МИФИ» (ВИТИ НИЯУ МИФИ)": [
        "Факультет атомной энергетики и машиностроения",
        "Факультет очно-заочного и заочного обучения",
    ],
    "Северо-Осетинский государственный университет имени К. Л. Хетагурова (СОГУ им. К. Л. Хетагурова)": [
        "Факультет математики и компьютерных наук",
        "Физико-технический факультет",
        "Исторический факультет",
        "Факультет географии и геоэкологии",
        "Юридический факультет",
        "Факультет международных отношений",
        "Факультет осетинской филологии",
        "Факультет журналистики",
        "Медицинский факультет",
        "Факультет искусств",
        "Факультет экономики и управления",
        "Факультет русской филологии",
        "Психолого-педагогический факультет",
        "Факультет химии, биологии и биотехнологии",
        "Факультет физической культуры и спорта",
    ],
    "Технологический институт сервиса (филиал) Донского государственного технического университета в г. Ставрополе (ТИС (филиал) ДГТУ в г. Ставрополе)": [
        "Механико-технологический факультет",
        "Факультет экономики и сервиса",
    ],
    "Башкирская академия государственной службы и управления при Главе Республики Башкортостан (БАГСУ)": [
        "Факультет государственного и муниципального управления и экономики",
        "Юридический факультет",
    ],
    "Нефтекамский филиал Уфимского университета науки и технологий (Нефтекамский филиал УУНиТ)": ["Гуманитарный факультет", "Экономико-математический факультет"],
    "Марийский государственный университет (МарГУ)": [
        "Факультет общего и профессионального образования",
        "Экономический факультет",
        "Медицинский институт",
        "Физико-математический факультет",
        "Аграрно-технологический институт",
        "Институт естественных наук и фармации",
        "Юридический факультет",
        "Факультет иностранных языков",
        "Историко-филологический факультет",
        "Психолого-педагогический факультет",
        "Электроэнергетический факультет",
        "Факультет физической культуры, спорта и туризма",
        "Институт национальной культуры и межкультурной коммуникации",
    ],
    "Саранский кооперативный институт (филиал) Российского университета кооперации (СКИ РУК)": ["Экономический факультет", "Факультет права и управления"],
    "Алатырский филиал Чувашского государственного университета имени И.Н. Ульянова (Алатырский филиал ЧГУ им. И.Н. Ульянова)": ["Факультет управления и экономики"],
    "Чайковская государственная академия физической культуры и спорта (ЧГАФКиС)": ["Факультет физической культуры и спорта"],
    "Приволжский исследовательский медицинский университет (ПИМУ)": [
        "Лечебный факультет",
        "Медико-профилактический факультет",
        "Педиатрический факультет",
        "Стоматологический факультет",
        "Фармацевтический факультет",
    ],
    "Арзамасский филиал Национального исследовательского Нижегородского государственного университета им. Н.И. Лобачевского (Арзамасский филиал ННГУ)": [
        "Психолого-педагогический факультет",
        "Факультет естественных и математических наук",
        "Историко-филологический факультет",
    ],
    "Новотроицкий филиал Университета науки и технологий МИСИС (НФ НИТУ МИСИС)": ["Факультет металлургических технологий", "Факультет заочного обучения"],
    "Поволжский государственный университет сервиса (ПВГУС)": ["Институт дизайна, туризма и социальных технологий", "Институт заочного обучения", "Факультет информационно-технического сервиса"],
    "Технологический институт - филиал Ульяновского государственного аграрного университета имени П.А. Столыпина (Технологический институт – филиал Ульяновского ГАУ)": [
        "Инженерно-экономический факультет",
    ],
    "Екатеринбургский государственный театральный институт (ЕГТИ)": ["Актерский факультет"],
    "Нижнетагильский государственный социально-педагогический институт (филиал) Российского государственного профессионально-педагогического университета (НТГСПИ РГППУ)": [
        "Социально-гуманитарный факультет",
        "Факультет психолого-педагогического образования",
        "Факультет естествознания, математики и информатики",
        "Факультет спорта и безопасности жизнедеятельности",
        "Факультет художественного образования",
        "Факультет филологии и межкультурной коммуникации",
    ],
    "Филиал Южно-Уральского государственного университета (национального исследовательского университета) в г. Златоусте (Филиал ЮУрГУ НИУ в г. Златоусте)": [
        "Факультет техники и технологии",
        "Факультет сервиса, экономики и права",
    ],
    "Камчатский государственный университет имени Витуса Беринга (КамГУ им. Витуса Беринга)": [
        "Физико-математический факультет",
        "Психолого-педагогический факультет",
        "Факультет филологии и межкультурной коммуникации",
        "Социально-экономический факультет",
    ],
    "Комсомольский-на-Амуре государственный университет (КнАГУ)": [
        "Факультет компьютерных технологий",
        "Факультет энергетики и управления",
        "Социально-гуманитарный факультет",
        "Факультет машиностроительных и химических технологий",
        "Факультет авиационной и морской техники",
        "Факультет кадастра и строительства",
    ],
    "Московский государственный университет спорта и туризма (МГУСиТ)": ["Институт индустрии туризма и гостеприимства", "Институт спортивных технологий и физического воспитания"],
    "Российская таможенная академия (РТА)": ["Юридический факультет", "Факультет таможенного дела", "Экономический факультет"],
    "Московский архитектурный институт (государственная академия) (МАРХИ)": ["Факультет бакалавриата"],
    "Государственный гуманитарно-технологический университет (ГГТУ)": [
        "Факультет иностранных языков",
        "Юридический факультет",
        "Факультет математики, физики и экономики",
        "Филологический факультет",
        "Факультет дошкольного образования",
        "Психолого-педагогический факультет",
        "Биолого-химический факультет",
        "Фармацевтический факультет",
        "Факультет начального образования",
    ],
    "Санкт-Петербургский государственный химико-фармацевтический университет (СПХФУ)": ["Фармацевтический факультет", "Факультет промышленной технологии лекарств"],
    "Государственный университет морского и речного флота имени адмирала С.О. Макарова (ГУМРФ им. адм. С.О. Макарова)": [
        "Факультет судовой энергетики",
        "Арктический факультет",
        "Факультет навигации и связи",
        "Институт водного транспорта",
        "Институт международного транспортного менеджмента",
    ],
    "Санкт-Петербургский государственный лесотехнический университет имени С.М. Кирова (СПбГЛТУ им. С.М. Кирова)": [
        "Институт ландшафтной архитектуры, строительства и обработки древесины",
        "Институт технологических машин и транспорта леса",
        "Институт химической переработки биомассы дерева и техносферной безопасности",
        "Институт леса и природопользования",
        "Институт лесного бизнеса и инноватики",
    ],
    "Нижегородский государственный архитектурно-строительный университет (ННГАСУ)": [
        "Факультет архитектуры и дизайна",
        "Факультет инженерно-экологических систем и сооружений",
        "Инженерно-строительный факультет",
        "Институт технологий бизнеса",
        "Общетехнический факультет",
    ],
    "Казанский (Приволжский) федеральный университет (КФУ)": [
        "Институт геологии и нефтегазовых технологий",
        "Институт филологии и межкультурной коммуникации",
        "Институт дизайна и пространственных искусств",
        "Институт физики",
        "Институт фундаментальной медицины и биологии",
        "Институт психологии и образования",
        "Институт математики и механики им. Н.И. Лобачевского",
        "Институт экологии и природопользования",
        "Институт социально-философских наук и массовых коммуникаций",
        "Институт информационных технологий и интеллектуальных систем",
        "Химический институт им. А.М. Бутлерова",
        "Институт вычислительной математики и информационных технологий",
        "Юридический факультет",
        "Институт управления, экономики и финансов",
        "Инженерный институт",
        "Институт международных отношений",
        "Высшая школа бизнеса",
    ],
    "Тюменский государственный медицинский университет (Тюменский ГМУ)": [
        "Институт стоматологии",
        "Институт клинической медицины",
        "Институт фармации",
        "Институт фундаментальной медицины",
        "Институт материнства и детства",
    ],
    "Южно-Уральский государственный медицинский университет (ЮУГМУ)": [
        "Факультет клинической психологии и социальной работы",
        "Фармацевтический факультет",
        "Лечебный факультет",
        "Медико-профилактический факультет",
        "Стоматологический факультет",
        "Педиатрический факультет",
    ],
    "Омский государственный университет им. Ф.М. Достоевского (ОмГУ им. Ф.М. Достоевского)": [
        "Физический факультет",
        "Факультет экономики, психологии, менеджмента",
        "Химический факультет",
        "Юридический факультет",
        "Центр делового образования",
        "Факультет культуры и искусств",
        "Факультет истории, теологии и международных отношений",
        "Факультет цифровых технологий и кибербезопасности",
        "Факультет филологии, переводоведения и медиакоммуникаций",
    ],
    "Омский государственный технический университет (ОмГТУ)": [
        "Энергетический институт",
        "Факультет транспорта, нефти и газа",
        "Факультет информационных технологий и компьютерных систем",
        "Нефтехимический институт",
        "Факультет гуманитарного образования",
        "Машиностроительный институт",
        "Радиотехнический факультет",
        "Институт заочного обучения",
        "Факультет экономики, сервиса и управления",
        "Художественно-технологический факультет",
    ],
    "Ростовский государственный медицинский университет (РостГМУ)": [
        "Медико-профилактический факультет",
        "Лечебно-профилактический факультет",
        "Факультет общей клинической практики",
        "Факультет клинической психологии",
        "Стоматологический факультет",
        "Педиатрический факультет",
        "Фармацевтический факультет",
    ],
    "Санкт-Петербургский государственный институт культуры (СПбГИК)": [
        "Факультет социально-культурных технологий",
        "Факультет мировой культуры",
        "Библиотечно-информационный факультет",
        "Образовательный центр современной музыкальной индустрии",
        "Факультет искусств",
        "Факультет музыкального искусства эстрады",
    ],
    "Дальневосточный государственный университет путей сообщения (ДВГУПС)": [
        "Институт транспортного строительства",
        "Электроэнергетический институт",
        "Институт интегрированных форм обучения",
        "Институт тяги и подвижного состава",
        "Социально-гуманитарный институт",
        "Институт управления, автоматизации и телекоммуникаций",
        "Институт экономики",
        "Институт международного сотрудничества",
        "Естественно-научный институт",
        "Факультет воздушных сообщений",
    ],
    "Дальневосточный государственный технический рыбохозяйственный университет (Дальрыбвтуз)": [
        "Мореходный институт",
        "Институт рыболовства и аквакультуры",
        "Институт пищевых производств",
        "Международный институт",
        "Центр заочного и вечернего обучения",
    ],
    "Ставропольский государственный аграрный университет (Ставропольский ГАУ)": [
        "Факультет агробиологии и земельных ресурсов",
        "Экономический факультет",
        "Факультет ветеринарной медицины",
        "Электроэнергетический факультет",
        "Инженерно-технологический факультет",
        "Учетно-финансовый факультет",
        "Факультет социально-культурного сервиса и туризма",
        "Биотехнологический факультет",
        "Факультет экологии и ландшафтной архитектуры",
    ],
    "Ставропольский государственный педагогический институт (СГПИ)": [
        "Факультет заочного и дистанционного образования",
        "Педагогический факультет",
        "Факультет психологии и дефектологии",
        "Факультет искусств и физической культуры",
    ],
    "Воронежский государственный технический университет (ВГТУ)": [
        "Факультет информационных технологий и компьютерной безопасности",
        "Строительный факультет",
        "Факультет инженерных систем и сооружений",
        "Культурно-просветительский факультет",
        "Факультет машиностроения и аэрокосмической техники",
        "Факультет архитектуры и градостроительства",
        "Факультет радиотехники и электроники",
        "Дорожно-транспортный факультет",
        "Факультет энергетики и систем управления",
        "Факультет экономики, менеджмента и информационных технологий",
    ],
    "Кемеровский государственный университет (КемГУ)": [
        "Технологический институт пищевой промышленности",
        "Институт фундаментальных наук",
        "Юридический институт",
        "Медицинский институт",
        "Институт филологии, иностранных языков и медиакоммуникаций",
        "Институт образования",
        "Социально-психологический институт",
        "Факультет физической культуры и спорта",
        "Институт инженерных технологий",
        "Институт биологии, экологии и природных ресурсов",
        "Институт цифры",
        "Институт экономики и управления",
        "Институт истории и международных отношений",
    ],
    "Волгоградская государственная академия физической культуры (ВГАФК)": ["Факультет физической культуры и спорта", "Факультет заочного обучения"],
    "Уральский государственный университет путей сообщения (УрГУПС)": [
        "Строительный факультет",
        "Электромеханический факультет",
        "Факультет экономики и управления",
        "Механический факультет",
        "Институт заочного образования",
        "Факультет управления процессами перевозок",
        "Электротехнический факультет",
    ],
    "Сибирский государственный университет водного транспорта (СГУВТ)": [
        "Электромеханический факультет",
        "Судомеханический факультет",
        'Институт "Морская академия"',
        "Гидротехнический факультет",
        "Факультет управления на водном транспорте",
    ],
    "Кубанский государственный технологический университет (КубГТУ)": [
        "Институт строительства и транспортной инфраструктуры",
        "Институт экономики, управления и бизнеса",
        "Институт пищевой и перерабатывающей промышленности",
        "Институт нефти, газа и энергетики",
        "Институт механики, робототехники, инженерии транспортных и технических систем",
        "Институт компьютерных систем и информационной безопасности",
        "Институт фундаментальных наук",
    ],
    "Краснодарский филиал Российского экономического университета имени Г.В. Плеханова (Краснодарский филиал РЭУ им. Г.В. Плеханова)": ["Факультет экономики, менеджмента и торговли"],
    "Поволжский государственный университет телекоммуникаций и информатики (ПГУТИ)": [
        "Факультет оптических и квантовых коммуникаций",
        "Факультет информационных систем и технологий",
        "Факультет систем радиосвязи и радиотехники",
        "Факультет кибербезопасности и управления",
        "Факультет цифровой экономики и массовых коммуникаций",
    ],
    "Самарский государственный институт культуры (СГИК)": [
        "Музыкально-исполнительский факультет (консерватория)",
        "Факультет культурологии, социально-культурных и информационных технологий",
        "Факультет современного искусства и художественных коммуникаций",
        "Театральный факультет",
    ],
    "Ростовский государственный экономический университет (РИНХ) (РГЭУ (РИНХ))": [
        "Учетно-экономический факультет",
        "Факультет экономики и финансов",
        "Юридический факультет",
        "Факультет менеджмента и предпринимательства",
        "Факультет лингвистики и журналистики",
        "Факультет торгового дела",
        "Факультет компьютерных технологий и информационной безопасности",
    ],
    "Красноярский государственный педагогический университет им. В.П. Астафьева (КГПУ)": [
        "Факультет начальных классов",
        "Институт физической культуры, спорта и здоровья им. И. С. Ярыгина",
        "Институт математики, физики и информатики",
        "Институт социально-гуманитарных технологий",
        "Филологический факультет",
        "Институт психолого-педагогического образования",
        "Исторический факультет",
        "Факультет иностранных языков",
        "Факультет биологии, географии и химии",
    ],
    "Белгородский государственный технологический университет им. В.Г. Шухова (БГТУ им. В.Г. Шухова)": [
        "Институт технологического оборудования и машиностроения",
        "Химико-технологический институт",
        "Институт экономики и менеджмента",
        "Инженерно-строительный институт",
        "Архитектурный институт",
        "Институт заочного обучения",
        "Транспортно-технологический институт",
        "Институт магистратуры",
        "Институт энергетики, информационных технологий и управляющих систем",
    ],
    "Челябинский государственный университет (ЧелГУ)": [
        "Биологический факультет",
        "Институт образования и практической психологии",
        "Факультет заочного дистанционного обучения",
        "Факультет фундаментальной медицины",
        "Факультет экологии",
        "Факультет лингвистики и перевода",
        "Институт информационных технологий",
        "Факультет Евразии и Востока",
        "Математический факультет",
        "Историко-филологический факультет",
        "Экономический факультет",
        "Физический факультет",
        "Факультет журналистики",
        "Факультет управления",
        "Химический факультет",
        "Институт права",
        "Институт экономики, отраслей бизнеса и администрирования",
    ],
    "Санкт-Петербургский имени В.Б. Бобкова филиал Российской таможенной академии (Санкт-Петербургский имени В.Б. Бобкова филиал Российской таможенной академии)": [
        "Юридический факультет",
        "Факультет таможенного дела",
        "Экономический факультет",
    ],
    "Севастопольский филиал Российского экономического университета имени Г.В. Плеханова (Севастопольский филиал РЭУ им. Г.В. Плеханова)": ["Финансовый факультет", "Факультет менеджмента"],
    "Московская высшая школа социальных и экономических наук (МВШСЭН)": [
        "Факультет социальных наук",
        "Факультет права",
        "Факультет практической психологии",
        "Факультет управления социокультурными проектами",
        "Факультет гуманитарных наук",
    ],
    "Государственный музыкально-педагогический институт имени М.М. Ипполитова-Иванова (ГМПИ им. М.М. Ипполитова-Иванова)": [
        "Факультет инструментального исполнительского искусства",
        "Факультет вокально-хоровой и дирижерской подготовки",
        "Факультет историко-теоретико-композиторской и общегуманитарной подготовки",
    ],
    "Волгоградский институт управления - филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Волгоградский институт управления - филиал РАНХиГС)":
        ["Юридический факультет", "Экономический факультет", "Факультет государственного и муниципального управления"],
    "Волжский государственный университет водного транспорта (ВГУВТ)": [
        "Факультет судовождения",
        "Электромеханический факультет",
        "Институт экономики, управления и права",
        "Факультет кораблестроения, гидротехники и защиты окружающей среды",
    ],
    "Уральский государственный университет физической культуры (УралГУФК)": [
        "Факультет оздоровительных технологий и спортивной медицины",
        "Факультет экономики, юриспруденции и гуманитарных наук",
        "Отделение заочного обучения",
        "Факультет зимних видов спорта и единоборств",
        "Факультет летних видов спорта",
    ],
    "Сибирский государственный университет физической культуры и спорта (СибГУФК)": ["Факультет физической культуры", "Факультет спорта", "Факультет заочного обучения и дополнительного образования"],
    "Омский государственный педагогический университет (ОмГПУ)": [
        "Факультет истории, философии и права",
        "Факультет начального, дошкольного и специального образования",
        "Факультет психологии и педагогики",
        "Факультет естественнонаучного образования",
        "Факультет искусств",
        "Факультет математики, информатики, физики и технологии",
        "Филологический факультет",
        "Факультет иностранных языков",
        "Факультет экономики, менеджмента, сервиса и туризма",
    ],
    "Институт пищевых технологий и дизайна - филиал Нижегородского государственного инженерно-экономического университета (Институт пищевых технологий и дизайна - филиал НГИЭУ)": [
        "Факультет технологии и дизайна",
        "Технологический факультет",
        "Факультет сервиса",
    ],
    "Дальневосточный государственный институт искусств (ДВГИИ)": ["Музыкальный факультет", "Художественный факультет", "Театральный факультет"],
    "Удмуртский государственный университет (УдГУ)": [
        "Институт гражданской защиты",
        "Институт языка и литературы",
        "Институт педагогики, психологии и социальных технологий",
        "Институт физической культуры и спорта",
        "Институт экономики и управления",
        "Институт искусств и дизайна",
        "Институт математики, информационных технологий и физики",
        "Институт истории и социологии",
        "Институт права, социального управления и безопасности",
        "Институт естественных наук",
        "Институт социальных коммуникаций",
        "Институт нефти и газа им. М.С. Гуцериева",
        "Институт удмуртской филологии, финно-угроведения и журналистики",
    ],
    "Иркутский государственный аграрный университет имени А.А. Ежевского (Иркутский ГАУ)": [
        "Инженерный факультет",
        "Агрономический факультет",
        "Институт экономики, управления и прикладной информатики",
        "Энергетический факультет",
        "Факультет биотехнологии и ветеринарной медицины",
        "Институт управления природными ресурсами - факультет охотоведения имени В.Н. Скалона",
    ],
    "Кубанский государственный университет физической культуры, спорта и туризма (КГУФКСТ)": [
        "Факультет адаптивной и оздоровительной физической культуры",
        "Факультет спорта",
        "Факультет спортивного менеджмента, педагогики и психологии",
        "Факультет сервиса и туризма",
        "Факультет физической культуры",
    ],
    "Северо-Кавказский филиал Российского государственного университета правосудия (СКФ РГУП)": [
        "Факультет подготовки специалистов для судебной системы (Юридический факультет)",
        "Факультет непрерывного образования",
    ],
    "Северо-Кавказский государственный институт искусств (СКГИИ)": ["Театральный факультет", "Исполнительский факультет"],
    "Восточно-Сибирский филиал Российского государственного университета правосудия (ВСФ РГУП)": ["Факультет подготовки специалистов для судебной системы (юридический факультет)"],
    "Тульский государственный университет (ТулГУ)": [
        "Политехнический институт",
        "Институт права и управления",
        "Институт прикладной математики и компьютерных наук",
        "Медицинский институт",
        "Институт горного дела и строительства",
        "Департамент управления контингентом обучающихся «Студенческий офис»",
        "Естественно-научный институт",
        "Институт гуманитарных и социальных наук",
        "Интернет-институт",
        "Институт высокоточных систем им. В.П. Грязева",
        "Институт педагогики, физической культуры, спорта и туризма",
    ],
    "Оренбургский государственный педагогический университет (ОГПУ)": [
        "Исторический факультет",
        "Филологический факультет",
        "Институт физической культуры и спорта",
        "Институт дошкольного и начального образования",
        "Факультет иностранных языков",
        "Институт педагогики и психологии",
        "Физико-математический факультет",
        "Институт естествознания и экономики",
    ],
    "Кировский государственный медицинский университет (Кировский ГМУ)": ["Лечебный факультет", "Социально-экономический факультет", "Стоматологический факультет", "Педиатрический факультет"],
    "Вятский государственный университет (ВятГУ)": [
        "Педагогический институт",
        "Институт биологии и биотехнологии",
        "Институт химии и экологии",
        "Институт гуманитарных и социальных наук",
        "Политехнический институт",
        "Юридический институт",
        "Институт математики и информационных систем",
        "Институт экономики и менеджмента",
    ],
    "Чебоксарский институт (филиал) Московского гуманитарно-экономического университета (Чебоксарский институт (филиал) МГЭУ)": ["Факультет экономики, управления и права"],
    "Волжский филиал Московского автомобильно-дорожного государственного технического университета (МАДИ) (ВФ МАДИ)": ["Факультет автомобильных дорог и транспорта", "Факультет заочного обучения"],
    "Волгоградский кооперативный институт (филиал) Российского университета кооперации (Волгоградский кооперативный институт (филиал) РУК)": [
        "Экономический факультет",
        "Факультет управления и права",
    ],
    "Дагестанский государственный университет народного хозяйства (ДГУНХ)": [
        "Финансово-экономический факультет",
        "Факультет информационных технологий и инженерии",
        "Факультет иностранных языков",
        "Юридический факультет",
    ],
    "Махачкалинский филиал Московского автомобильно-дорожного государственного технического университета (МАДИ) (Махачкалинский филиал МАДИ)": [
        "Факультет транспорта, дорожного строительства и экономики",
        "Факультет заочной формы обучения",
    ],
    "Тверской государственный университет (ТвГУ)": [
        "Химико-технологический факультет",
        "Факультет географии и геоэкологии",
        "Институт педагогического образования и социальных технологий",
        "Филологический факультет",
        "Математический факультет",
        "Исторический факультет",
        "Факультет прикладной математики и кибернетики",
        "Факультет физической культуры",
        "Биологический факультет",
        "Юридический факультет",
        "Факультет психологии",
        "Физико-технический факультет",
        "Институт экономики и управления",
        "Факультет иностранных языков и международной коммуникации",
    ],
    "Пензенский государственный аграрный университет (Пензенский ГАУ)": ["Инженерный факультет", "Экономический факультет", "Агрономический факультет", "Технологический факультет"],
    "Ульяновский государственный технический университет (УлГТУ)": [
        "Строительный факультет",
        "Инженерно-экономический факультет",
        "Институт авиационных технологий и управления",
        "Гуманитарный факультет",
        "Заочно-вечерний факультет",
        "Институт дистанционного и дополнительного образования",
        "Международный институт",
        "Факультет информационных систем и технологий",
        "Радиотехнический факультет",
        "Центр открытого образования Факультета информационных систем и технологий",
        "Энергетический факультет",
        "Машиностроительный факультет",
        "Машиностроительный центр открытого образования «Партнер»",
    ],
    "Калужский филиал Российского государственного аграрного университета - МСХА имени К.А. Тимирязева (КФ РГАУ - МСХА имени К.А. Тимирязева)": [
        "Факультет агротехнологий, инженерии и землеустройства",
        "Факультет ветеринарной медицины и зоотехнии",
        "Экономический факультет",
    ],
    "Арктический государственный агротехнологический университет (Арктический ГАТУ)": [
        "Экономический факультет",
        "Факультет ветеринарной медицины",
        "Агротехнологический факультет",
        "Инженерный факультет",
        "Факультет лесного комплекса и землеустройства",
    ],
    "Астраханский государственный университет им. В. Н. Татищева (АГУ им. В. Н. Татищева)": [
        "Факультет физики, математики и инженерных технологий",
        "Биологический факультет",
        "Юридический факультет",
        "Факультет цифровых технологий и кибербезопасности",
        "Факультет психологии",
        "Факультет архитектуры и дизайна",
        "Факультет педагогического образования, искусства, сервиса и культуры",
        "Факультет агробизнеса, технологий и ветеринарной медицины",
        "Факультет физической культуры и спорта",
        "Факультет иностранных языков",
        "Геолого-географический факультет",
        "Исторический факультет",
        "Факультет экономики и управления",
        "Химический факультет",
        "Факультет социальных коммуникаций",
        "Факультет филологии и журналистики",
    ],
    "Забайкальский государственный университет (ЗабГУ)": [
        "Горный факультет",
        "Психолого-педагогический факультет",
        "Факультет физической культуры и спорта",
        "Факультет строительства и экологии",
        "Факультет естественных наук, математики и технологий",
        "Энергетический факультет",
        "Факультет культуры и искусств",
        "Юридический факультет",
        "Факультет экономики и управления",
        "Историко-филологический факультет",
        "Социологический факультет",
    ],
    "Восточно-Сибирский государственный институт культуры (ВСГИК)": [
        "Гуманитарно-информационный факультет",
        "Факультет искусств",
        "Факультет музыкального и хореографического искусства",
        "Факультет социально-культурной деятельности, наследия и туризма",
    ],
    "Набережночелнинский государственный педагогический университет (НГПУ)": [
        "Факультет математики и информатики",
        "Факультет искусств и дизайна",
        "Факультет педагогики и психологии",
        "Филологический факультет",
        "Факультет физической культуры и спорта",
        "Историко-географический факультет",
    ],
    "Бронницкий филиал Московского автомобильно-дорожного государственного технического университета (МАДИ) (Бронницкий филиал МАДИ)": ["Факультет автомобильного транспорта"],
    "Воронежский институт (филиал) Московского гуманитарно-экономического университета (ВИ (Ф) МГЭУ)": ["Гуманитарно-правовой факультет", "Факультет экономики и управления"],
    "Ивановский государственный энергетический университет имени В.И. Ленина (ИГЭУ)": [
        "Электромеханический факультет",
        "Электроэнергетический факультет",
        "Факультет информатики и вычислительной техники",
        "Факультет заочного и вечернего обучения",
        "Инженерно-физический факультет",
        "Теплоэнергетический факультет",
        "Факультет экономики и управления",
    ],
    "Курский государственный медицинский университет (КГМУ)": [
        "Стоматологический факультет",
        "Факультет медико-профилактического дела",
        "Биотехнологический факультет",
        "Фармацевтический факультет",
        "Факультет социальной работы",
        "Факультет клинической психологии",
        "Лечебный факультет",
        "Педиатрический факультет",
        "Факультет экономики и менеджмента",
    ],
    "Московский областной филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Московский областной филиал РАНХиГС)": [
        "Факультет экономики и менеджмента",
        "Факультет государственного управления и права",
    ],
    "Российская академия живописи, ваяния и зодчества Ильи Глазунова (РАЖВиЗ Ильи Глазунова)": [
        "Факультет искусствоведения",
        "Факультет архитектуры",
        "Факультет живописи",
        "Факультет скульптуры",
        "Факультет реставрации живописи",
    ],
    "Смоленский государственный медицинский университет (СмолГМУ)": [
        "Педиатрический факультет",
        "Стоматологический факультет",
        "Психолого-социальный факультет",
        "Фармацевтический факультет",
        "Факультет медико-биологического и гуманитарного образования",
        "Лечебный факультет",
    ],
    "Старооскольский технологический институт им. А.А. Угарова (филиал) Университета науки и технологий МИСИС (СТИ НИТУ МИСИС)": [
        "Факультет автоматизации и информационных технологий",
        "Инженерно-экономический факультет",
        "Факультет металлургических и машиностроительных технологий",
    ],
    "Шуйский филиал Ивановского государственного университета (Шуйский филиал ИвГУ)": [
        "Факультет физической культуры",
        "Математико-технологический факультет",
        "Историко-филологический факультет",
        "Факультет педагогики и психологии",
    ],
    "Национальный открытый институт г. Санкт-Петербург (НОИ СПб)": ["Факультет здоровья", "Социально-экономический факультет", "Факультет геодезии и кадастра"],
    "Калининградский филиал Санкт-Петербургского государственного аграрного университета (Калининградский филиал СПбГАУ)": [
        "Экономический факультет",
        "Инженерно-технологический факультет",
        "Зооинженерный факультет",
        "Агрономический факультет",
    ],
    "Череповецкий государственный университет (ЧГУ)": [
        "Институт информационных технологий",
        "Гуманитарный институт",
        "Инженерно-технический институт",
        "Институт педагогики и психологии",
        "Бизнес-школа",
        "Факультет биологии и здоровья человека",
    ],
    "Государственный институт экономики, финансов, права и технологий (ГИЭФПТ)": [
        "Юридический факультет",
        "Заочный факультет",
        "Факультет менеджмента, социальной работы и туризма",
        "Экономический факультет",
    ],
    "Новгородский государственный университет имени Ярослава Мудрого (НовГУ)": [
        "Политехнический институт",
        "Институт цифровой экономики, управления и сервиса",
        "Институт электронных и информационных систем",
        "Гуманитарный институт",
        "Институт медицинского образования",
        "Институт биотехнологий и химического инжиниринга",
        "Институт непрерывного педагогического образования",
        "Юридический институт",
    ],
    "Петрозаводская государственная консерватория имени А.К. Глазунова (ПГК им. А.К. Глазунова)": ["Исполнительский факультет", "Теоретико-дирижерский факультет"],
    "Ухтинский государственный технический университет (УГТУ)": ["Нефтегазовый факультет", "Технологический факультет", "Факультет экономики, управления и информационных технологий"],
    "Волжский политехнический институт (филиал) Волгоградского государственного технического университета (ВПИ (филиал) ВолгГТУ)": [
        "Инженерно-экономический факультет",
        "Автомеханический факультет",
        "Вечерний факультет",
    ],
    "Ростовский филиал Российской таможенной академии (Ростовский филиал РТА)": ["Юридический факультет", "Экономический факультет", "Факультет таможенного дела"],
    "Институт технологий (филиал) Донского государственного технического университета в г. Волгодонске (ИТ (ф) ДГТУ в Волгодонске)": ["Факультет технологий и менеджмента"],
    "Владикавказский филиал Финансового университета при Правительстве Российской Федерации (Владикавказский филиал Финуниверситета)": ["Финансово-экономический факультет"],
    "Филиал МИРЭА - Российского технологического университета в г. Ставрополе (Филиал РТУ МИРЭА в г. Ставрополе)": ["Факультет регионального развития", "Технологический факультет"],
    "Пятигорский медико-фармацевтический институт - филиал Волгоградского государственного медицинского университета (ПМФИ - филиал ВолгГМУ)": ["Фармацевтический факультет", "Медицинский факультет"],
    "Филиал Ставропольского государственного педагогического института в г. Буденновске (Филиал СГПИ в Буденновске)": ["Психолого-педагогический факультет"],
    "Уфимский государственный институт искусств имени Загира Исмагилова (УГИИ им. Загира Исмагилова)": [
        "Факультет изобразительных искусств",
        "Музыкальный факультет",
        "Факультет башкирской музыки",
        "Театральный факультет",
    ],
    "Бирский филиал Уфимского университета науки и технологий (Бирский филиал УУНиТ)": [
        "Факультет физики и математики",
        "Факультет биологии и химии",
        "Факультет филологии и межкультурных коммуникаций",
        "Инженерно-технологический факультет",
        "Факультет педагогики",
        "Социально-гуманитарный факультет",
    ],
    "Поволжский государственный технологический университет (Волгатех)": [
        "Институт строительства и архитектуры",
        "Радиотехнический факультет",
        "Факультет информатики и вычислительной техники",
        "Факультет управления и права",
        "Институт леса и природопользования",
        "Экономический факультет",
        "Институт механики и машиностроения",
        "Факультет социальных технологий",
    ],
    "Альметьевский государственный нефтяной институт (АГНИ)": ["Нефтегазовый факультет", "Энергомеханический факультет", "Факультет экономических и гуманитарных дисциплин"],
    "Нижнекамский химико-технологический институт (филиал) Казанского национального исследовательского технологического университета (НХТИ КНИТУ)": [
        "Технологический факультет",
        "Механический факультет",
        "Факультет информационных технологий",
    ],
    "Университет управления «ТИСБИ» (Университет управления ТИСБИ)": [
        "Юридический факультет",
        "Гуманитарный факультет",
        "Факультет информационных технологий",
        "Факультет управления",
        "Экономический факультет",
    ],
    "Чайковский филиал Пермского национального исследовательского политехнического университета (ЧФ ПНИПУ)": ["Факультет профессиональной подготовки высшего образования"],
    "Нижегородская государственная консерватория им. М.И. Глинки (ННГК)": [
        "Факультет народных инструментов",
        "Вокальный факультет",
        "Дирижерский факультет",
        "Композиторско-музыковедческий факультет",
        "Фортепианный факультет",
        "Оркестровый факультет",
    ],
    "Арзамасский политехнический институт (филиал) Нижегородского государственного технического университета им. Р.Е. Алексеева (АПИ НГТУ)": [
        "Факультет машиностроения, приборостроения и информационных технологий",
    ],
    "Бузулукский гуманитарно-технологический институт (филиал) Оренбургского государственного университета (Бузулукский гуманитарно-технологический институт (филиал) ОГУ)": [
        "Факультет экономики и права",
        "Строительно-технологический факультет",
    ],
    "Саратовская государственная консерватория имени Л.В. Собинова (СГК им. Л.В. Собинова)": [
        "Театральный институт",
        "Фортепианный факультет",
        "Оркестровый факультет",
        "Вокально-дирижерский факультет",
        "Историко-теоретический факультет",
    ],
    "Балаковский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Балаковский филиал РАНХиГС)": [
        "Факультет государственно-правового управления",
    ],
    "Челябинский государственный институт культуры (ЧГИК)": [
        "Факультет документальных коммуникаций и туризма",
        "Факультет театра, кино и телевидения",
        "Хореографический факультет",
        "Консерваторский факультет",
        "Культурологический факультет",
        "Факультет декоративно-прикладного творчества",
    ],
    "Кузбасский институт Федеральной службы исполнения наказаний России (Кузбасский институт ФСИН России)": ["Факультет правоохранительной деятельности", "Юридический факультет"],
    "Камчатский государственный технический университет (КамчатГТУ)": ["Мореходный факультет", "Факультет информационных технологий, экономики и менеджмента", "Технологический факультет"],
    "Дальневосточный государственный аграрный университет (Дальневосточный ГАУ)": [
        "Электроэнергетический факультет",
        "Финансово-экономический факультет",
        "Факультет агрономии и экологии",
        "Факультет механизации сельского хозяйства",
        "Факультет ветеринарной медицины, зоотехнии и биотехнологий",
        "Факультет строительства и природообустройства",
    ],
    "Приамурский государственный университет имени Шолом-Алейхема (ПГУ им. Шолом-Алейхема)": [
        "Факультет филологии, истории и журналистики",
        "Факультет экономики, экологии и права",
        "Факультет педагогики и психологии – Центр педагогического образования",
        "Факультет социально-культурной деятельности и сервиса",
        "Факультет математики, информационных технологий и техники",
    ],
    "Камчатский филиал Российского университета кооперации (Камчатский филиал РУК)": ["Факультет высшего образования"],
    "Московский физико-технический институт (национальный исследовательский университет) (МФТИ)": [
        "Физтех-школа аэрокосмических технологий (факультет аэрофизики и космических исследований)",
        "Физтех-школа прикладной математики и информатики (факультет инноваций и высоких технологий)",
        "Физтех-школа электроники, фотоники и молекулярной физики (факультет молекулярной и химической физики)",
        "Институт нано-, био-, информационных, когнитивных и социогуманитарных наук и технологий",
        "Высшая школа системного инжиниринга МФТИ",
        "Кафедра технологического предпринимательства",
        "Физтех-школа электроники, фотоники и молекулярной физики (факультет физической и квантовой электроники)",
        "Физтех-школа биологической и медицинской физики (факультет биологической и медицинской физики)",
        "Физтех-школа аэрокосмических технологий (факультет аэромеханики и летательной техники)",
        "Передовая инженерная школа радиолокации, радионавигации и программной инженерии",
        "Физтех-школа радиотехники и компьютерных технологий (факультет радиотехники и кибернетики)",
        "Физтех-школа прикладной математики и информатики (факультет управления и прикладной математики)",
        "Высшая школа программной инженерии МФТИ - Яндекс",
        "Физтех-школа физики и исследований им. Ландау (факультет общей и прикладной физики)",
        "Физтех-школа физики и исследований им. Ландау (факультет проблем физики и энергетики)",
        "Центр продвижения, управления, сопровождения и контент-производства онлайн-программ",
        "Физтех-школа бизнеса высоких технологий",
    ],
    "Российский государственный гуманитарный университет (РГГУ)": [
        "Институт психологии им. Л.С. Выготского",
        "Институт восточных культур и античности",
        "Учебно-научный Мезоамериканский центр имени Ю.В. Кнорозова",
        "Институт массмедиа и рекламы",
        "Отделение интеллектуальных систем в гуманитарной сфере",
        "Учебно-научный центр социальной антропологии",
        "Институт лингвистики",
        "Философский факультет",
        "Центр изучения религий",
        "Институт информационных наук и технологий безопасности",
        "Социологический факультет",
        "Историко-архивный институт",
        "Институт филологии и истории",
        "Факультет истории искусства",
        "Факультет рекламы и связей с общественностью",
        "Институт экономики, управления и права",
        "Институт евразийских и межрегиональных исследований",
        "Факультет культурологии",
        "Учебно-научный центр типологии и семиотики фольклора",
    ],
    "Московский государственный юридический университет имени О.Е. Кутафина (МГЮА) (Университет имени О.Е. Кутафина (МГЮА))": [
        "Международно-правовой институт",
        "Высшая школа права",
        "Институт частного права",
        "Институт непрерывного образования имени Н.С. Киселевой",
        "Институт прокуратуры",
        "Институт публичного права и управления",
        "Институт судебных экспертиз",
        "Институт правового консалтинга",
        "Институт бизнес-права",
    ],
    "Российский университет кооперации (РУК)": ["Экономический факультет", "Факультет управления и права"],
    "Московский государственный институт международных отношений (Университет) МИД России (МГИМО)": [
        "Международно-правовой факультет",
        "Факультет Международных отношений",
        "Факультет Международных экономических отношений",
        "Управление магистерской подготовки",
        "Институт международных отношений и управления",
        "Школа бизнеса и международных компетенций",
        "Факультет Международной журналистики",
        "Европейский учебный институт",
        "Международный институт энергетической политики и дипломатии",
        "Институт международной торговли и устойчивого развития",
        "Факультет международного бизнеса и делового администрирования",
        "Факультет управления и политики",
    ],
    "Всероссийский государственный университет юстиции (РПА Минюста России) (ВГУЮ (РПА Минюста России))": ["Юридический факультет"],
    "Российский государственный университет нефти и газа (национальный исследовательский университет) имени И.М. Губкина (РГУ нефти и газа (НИУ) имени И.М. Губкина)": [
        "Факультет инженерной механики",
        "Факультет автоматики и вычислительной техники",
        "Юридический факультет",
        "Факультет химической технологии и экологии",
        "Факультет комплексной безопасности ТЭК",
        "Факультет геологии и геофизики нефти и газа",
        "Факультет экономики и управления",
        "Факультет разработки нефтяных и газовых месторождений",
        "Факультет международного энергетического бизнеса",
        "Факультет проектирования, сооружения и эксплуатации систем трубопроводного транспорта",
    ],
    "Российский университет дружбы народов (РУДН)": [
        "Институт экологии",
        "Институт иностранных языков",
        "Учебно-научный институт сравнительной образовательной политики",
        "Институт гостиничного бизнеса и туризма",
        "Аграрно-технологический институт",
        "Институт биохимической технологии и нанотехнологии",
        "Факультет гуманитарных и социальных наук",
        "Филологический факультет",
        "Высшая школа промышленной политики и предпринимательства",
        "Инженерная академия",
        "Институт мировой экономики и бизнеса (Международная школа бизнеса)",
        "Медицинский институт",
        "Экономический факультет",
        "Институт русского языка",
        "Факультет физико-математических и естественных наук",
        "Юридический институт",
    ],
    "Московский автомобильно-дорожный государственный технический университет (МАДИ) (МАДИ)": [
        "Факультет дорожных и технологических машин",
        "Факультет управления",
        "Заочный факультет",
        "Энерго-экологический факультет",
        "Конструкторско-механический факультет",
        "Дорожно-строительный факультет",
        "Факультет автомобильного транспорта",
        "Факультет логистики и общетранспортных проблем",
        "Экономический факультет",
    ],
    "Северо-Западный государственный медицинский университет имени И.И. Мечникова (СЗГМУ им. И.И. Мечникова)": [
        "Лечебный факультет",
        "Медико-профилактический факультет",
        "Педиатрический факультет",
        "Стоматологический факультет",
    ],
    "Санкт-Петербургский государственный технологический институт (Технический университет) (СПбГТИ (ТУ))": [
        "Факультет экономики и менеджмента",
        "Факультет химии веществ и материалов",
        "Механический факультет",
        "Факультет информационных технологий и управления",
        "Факультет химической и биотехнологии",
        "Инженерно-технологический факультет",
    ],
    "Санкт-Петербургский государственный институт психологии и социальной работы (СПбГИПСР)": ["Факультет прикладной психологии", "Факультет психолого-социальной работы"],
    "Уральский государственный юридический университет имени В.Ф. Яковлева (УрГЮУ имени В.Ф. Яковлева)": [
        "Институт права и предпринимательства",
        "Институт прокуратуры",
        "Институт специальных образовательных программ",
        "Институт юстиции",
        "Институт государственного и международного права",
    ],
    "Нижегородский институт управления – филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Нижегородский институт управления – филиал РАНХиГС)":
        ["Юридический факультет", "Факультет экономики", "Факультет управления"],
    "Санкт-Петербургский государственный институт кино и телевидения (СПбГИКиТ)": ["Факультет экранных искусств"],
    "Московский государственный академический художественный институт имени В.И. Сурикова при Российской академии художеств (МГАХИ им. В.И. Сурикова)": [
        "Факультет скульптуры",
        "Факультет архитектуры",
        "Факультет теории и истории искусства",
        "Факультет живописи",
        "Факультет графики",
    ],
    "Санкт-Петербургская академия художеств имени Ильи Репина (Санкт-Петербургская академия художеств им. И.Е. Репина)": [
        "Факультет скульптуры",
        "Факультет архитектуры",
        "Факультет теории и истории искусств",
        "Факультет живописи",
        "Факультет графики",
    ],
    "Московская государственная консерватория имени П.И. Чайковского (МГК им. П.И. Чайковского)": [
        "Факультет симфонического и хорового дирижирования",
        "Вокальный факультет",
        "Научно-композиторский факультет",
        "Факультет исторического и современного исполнительского искусства",
        "Фортепианный факультет",
        "Оркестровый факультет",
    ],
    "Кубанский государственный медицинский университет (КубГМУ)": [
        "Стоматологический факультет",
        "Фармацевтический факультет",
        "Медико-профилактический факультет",
        "Лечебный факультет",
        "Педиатрический факультет",
    ],
    "Российский университет спорта «ГЦОЛИФК» (РУС «ГЦОЛИФК»)": ["Факультет адаптивной физической культуры, рекреации и туризма", "Социально-гуманитарный факультет", "Тренерский факультет"],
    "Тихоокеанский государственный медицинский университет (ТГМУ)": [
        "Педиатрический факультет",
        "Факультет общественного здоровья",
        "Стоматологический факультет",
        "Лечебный факультет",
        "Фармацевтический факультет",
    ],
    "Воронежский государственный университет (ВГУ)": [
        "Факультет географии, геоэкологии и туризма",
        "Факультет романо-германской филологии",
        "Фармацевтический факультет",
        "Геологический факультет",
        "Физический факультет",
        "Факультет философии и психологии",
        "Факультет журналистики",
        "Филологический факультет",
        "Факультет компьютерных наук",
        "Исторический факультет",
        "Химический факультет",
        "Факультет международных отношений",
        "Математический факультет",
        "Экономический факультет",
        "Медико-биологический факультет",
        "Факультет прикладной математики, информатики и механики",
        "Юридический факультет",
    ],
    "Воронежский государственный институт искусств (ВГИИ)": ["Музыкальный факультет", "Театральный факультет", "Факультет живописи"],
    "Волгоградский государственный аграрный университет (Волгоградский ГАУ)": [
        "Эколого-мелиоративный факультет",
        "Агротехнологический факультет",
        "Экономический факультет",
        "Факультет биотехнологий и ветеринарной медицины",
        "Электроэнергетический факультет",
        "Инженерно-технологический факультет",
        "Факультет перерабатывающих технологий и товароведения",
    ],
    "Ярославский государственный университет им. П.Г. Демидова (ЯрГУ)": [
        "Математический факультет",
        "Факультет социально-политических наук",
        "Факультет информатики и вычислительной техники",
        "Экономический факультет",
        "Физический факультет",
        "Юридический факультет",
        "Факультет биологии и экологии",
        "Факультет филологии и коммуникации",
        "Факультет психологии",
        "Исторический факультет",
        "Институт иностранных языков",
    ],
    "Балтийский федеральный университет имени Иммануила Канта (БФУ им. И.Канта)": [
        "Институт живых систем",
        "Медицинский институт",
        "Институт гуманитарных наук",
        "Институт физико-математических наук и информационных технологий",
        "Инженерно-технический институт",
        "Институт экономики, управления и туризма",
        "Институт образования",
        "Юридический институт",
    ],
    "Московский гуманитарно-экономический университет (МГЭУ)": ["Гуманитарный факультет", "Юридический факультет", "Факультет экономики и управления"],
    "Уральский государственный архитектурно-художественный университет (УрГАХУ)": [
        "Архитектурный институт",
        "Институт дизайна",
        "Институт изобразительных искусств",
        "Факультет очно-заочного обучения",
    ],
    "Уральский государственный лесотехнический университет (УГЛТУ)": [
        "Социально-экономический институт",
        "Инженерно-технический институт",
        "Институт заочного обучения",
        "Институт леса и природопользования",
        "Химико-технологический институт",
    ],
    "Казанская государственная консерватория имени Н.Г. Жиганова (КГК им. Н.Г. Жиганова)": [
        "Факультет народных инструментов",
        "Вокальный факультет",
        "Дирижерско-хоровой факультет",
        "Теоретико-композиторский факультет",
        "Фортепианный факультет",
        "Оркестровый факультет",
    ],
    "Казанский государственный архитектурно-строительный университет (КГАСУ)": [
        "Институт строительства",
        "Институт экономики и управления в строительстве",
        "Институт транспортных сооружений",
        "Институт архитектуры и дизайна",
        "Институт строительных технологий и инженерно-экологических систем",
    ],
    "Казанский государственный медицинский университет (КГМУ)": [
        "Медико-профилактический факультет",
        "Стоматологический факультет",
        "Фармацевтический факультет",
        "Медико-биологический факультет",
        "Лечебный факультет",
        "Факультет социальной работы и высшего сестринского образования",
        "Педиатрический факультет",
    ],
    "Сибирский государственный университет геосистем и технологий (СГУГиТ)": [
        "Институт оптики и технологий информационной безопасности",
        "Институт геодезии и менеджмента",
        "Институт кадастра и природопользования",
    ],
    "Сибирский государственный университет путей сообщения (СГУПС)": [
        "Факультет строительства железных дорог",
        "Факультет бизнес-информатики",
        "Факультет управления транспортно-технологическими комплексами",
        "Инженерно-экономический факультет",
        "Факультет управления персоналом",
        "Факультет управления процессами перевозок на железнодорожном транспорте",
        "Факультет мировой экономики и права",
        "Факультет промышленного и гражданского строительства",
        "Факультет мосты и тоннели",
    ],
    "Высшая школа народных искусств (академия) (ВШНИ)": ["Факультет декоративно-прикладного искусства"],
    "Южно-Российский институт управления - филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Южно-Российский институт управления - филиал РАНХиГС)":
        ["Юридический факультет", "Факультет политологии", "Факультет управления", "Факультет экономики"],
    "Саратовский государственный медицинский университет имени В.И. Разумовского (Саратовский ГМУ)": [
        "Стоматологический и медико-профилактический факультеты",
        "Фармацевтический факультет",
        "Институт сестринского образования",
        "Лечебный факультет и факультет клинической психологии",
        "Педиатрический факультет",
    ],
    "Донской государственный технический университет (ДГТУ)": [
        "Факультет автоматизации, мехатроники и управления",
        "Институт физической культуры и спорта",
        "Факультет промышленного и гражданского строительства",
        "Факультет энергетики и нефтегазопромышленности",
        "Агропромышленный факультет",
        "Факультет информатики и вычислительной техники",
        "Факультет психологии, педагогики и дефектологии",
        "Юридический факультет",
        "Факультет безопасности жизнедеятельности и инженерной экологии",
        "Факультет машиностроительных технологий и оборудования",
        "Социально-гуманитарный факультет",
        "Факультет «Биоинженерия и ветеринарная медицина»",
        "Институт опережающих технологий «Школа Икс»",
        "Дорожно-транспортный факультет",
        "Факультет медиакоммуникационных и мультимедийных технологий",
        "Факультет технологии машиностроения",
        "Т-университет",
        "Факультет сервиса и туризма",
        "Инженерно-строительный факультет",
        "Факультет транспорта, сервиса и эксплуатации",
        "Передовая инженерная школа «Институт перспективного машиностроения „Ростсельмаш“»",
        "Факультет авиастроения",
        "Факультет инновационного бизнеса и менеджмента",
        "Факультет приборостроения и технического регулирования",
        "Школа архитектуры, дизайна и искусств",
    ],
    "Академия Русского балета имени А.Я. Вагановой (Академия Русского балета им. А.Я. Вагановой)": ["Исполнительский факультет", "Педагогический факультет"],
    "Саратовский государственный технический университет имени Гагарина Ю.А. (СГТУ)": [
        "Физико-технический институт",
        "Институт прикладных информационных технологий и коммуникаций",
        "Институт электронной техники и приборостроения",
        "Социально-экономический институт",
        "Институт социального и производственного менеджмента",
        "Институт энергетики",
        "Институт машиностроения, материаловедения и транспорта",
        "Институт урбанистики, архитектуры и строительства",
    ],
    "Башкирский государственный аграрный университет (Башкирский ГАУ)": [
        "Энергетический факультет",
        "Экономический факультет",
        "Факультет пищевых технологий",
        "Факультет заочного обучения",
        "Механический факультет",
        "Факультет агротехнологий и лесного хозяйства",
        "Факультет биотехнологий и ветеринарной медицины",
        "Факультет природопользования и строительства",
    ],
    "Омский государственный аграрный университет имени П.А. Столыпина (Омский ГАУ)": [
        "Землеустроительный факультет",
        "Факультет технического сервиса в АПК",
        "Экономический факультет",
        "Факультет зоотехнии, товароведения и стандартизации",
        "Факультет агрохимии, почвоведения, экологии, природообустройства и водопользования",
        "Факультет ветеринарной медицины",
        "Агротехнологический факультет",
    ],
    "Томский государственный педагогический университет (ТГПУ)": [
        "Историко-филологический факультет",
        "Факультет культуры и искусств",
        "Физико-математический факультет",
        "Факультет физической культуры и спорта",
        "Факультет дошкольного и начального образования",
        "Биолого-химический факультет",
        "Технолого-экономический факультет",
        "Институт иностранных языков и международного сотрудничества",
        "Факультет психологии и специального образования",
        "Институт развития педагогического образования",
    ],
    "Санкт-Петербургская государственная консерватория им. Н.А. Римского-Корсакова (СПбГК им. Н.А. Римского-Корсакова)": [
        "Факультет композиции и дирижирования",
        "Музыковедческий факультет",
        "Оркестровый факультет",
        "Факультет народных инструментов",
        "Фортепианный факультет",
        "Вокально-режиссерский факультет",
    ],
    "Академия труда и социальных отношений (АТиСО)": ["Социально-гуманитарный факультет", "Экономический факультет", "Юридический факультет"],
    "Приволжский филиал Российского государственного университета правосудия (Приволжский филиал РГУП)": [
        "Факультет непрерывного образования по подготовке специалистов для судебной системы",
        "Факультет подготовки специалистов для судебной системы (юридический факультет)",
    ],
    "Омский институт водного транспорта - филиал Сибирского государственного университета водного транспорта (ОИВТ - филиал СГУВТ)": [
        "Факультет технологии и управления на транспорте",
        "Технический факультет",
    ],
    "Институт международных связей (ИМС)": ["Факультет лингвистики и межкультурной коммуникации"],
    "Ижевская государственная медицинская академия (ИГМА)": ["Педиатрический факультет", "Лечебный факультет", "Стоматологический факультет"],
    "Байкальский государственный университет (БГУ)": [
        "Институт народного хозяйства",
        "Институт государства и права",
        "Институт мировой экономики и международных отношений",
        "Русско-китайский факультет",
        "Институт культуры, социальных коммуникаций и информационных технологий",
        "Институт управления и финансов",
    ],
    "Восточно-Сибирский институт экономики и права (ВСИЭП)": ["Юридический факультет", "Факультет экономики и управления"],
    "Центральный филиал Российского государственного университета правосудия (Центральный филиал РГУП)": ["Факультет подготовки специалистов для судебной системы (юридический факультет)"],
    "Рязанский государственный университет имени С. А. Есенина (РГУ имени С. А. Есенина)": [
        "Институт иностранных языков",
        "Институт физико-математических и компьютерных наук",
        "Факультет русской филологии и национальной культуры",
        "Факультет физической культуры и спорта",
        "Факультет экономики",
        "Институт естественных наук",
        "Юридический институт",
        "Институт истории, философии и политических наук",
        "Факультет социологии и управления",
        "Институт психологии, педагогики и социальной работы",
    ],
    "Сибирский институт бизнеса, управления и психологии (СИБУП)": ["Юридический факультет", "Факультет психологии", "Экономический факультет"],
    "Алтайский государственный институт культуры (АГИК)": [
        "Факультет социально-культурных и информационных технологий",
        "Факультет художественного творчества",
        "Хореографический факультет",
        "Музыкальный факультет",
    ],
    "Оренбургский государственный институт искусств им. Л. и М. Ростроповичей (ОГИИ им. Л. и М. Ростроповичей)": ["Гуманитарно-творческий факультет", "Музыкальный факультет"],
    "Оренбургский государственный университет (ОГУ)": [
        "Транспортный факультет",
        "Финансово-экономический факультет",
        "Институт социально-гуманитарных инноваций и массмедиа",
        "Химико-биологический факультет",
        "Факультет математики и информационных технологий",
        "Институт энергетики, электроники и связи",
        "Архитектурно-строительный факультет",
        "Факультет прикладной биотехнологии и инженерии",
        "Юридический факультет",
        "Институт непрерывного профессионального образования",
        "Институт наук о Земле",
        "Факультет филологии",
        "Институт менеджмента",
        "Физический факультет",
    ],
    "Тамбовский государственный технический университет (ТГТУ)": [
        "Институт архитектуры, строительства и транспорта",
        "Институт энергетики, приборостроения и радиоэлектроники",
        "Институт автоматики и информационных технологий",
        "Институт экономики и качества жизни",
        "Технологический институт",
        "Юридический институт",
        "Институт заочного обучения",
    ],
    "Дальневосточный филиал Российского государственного университета правосудия (Дальневосточный филиал РГУП)": ["Факультет подготовки специалистов для судебной системы (Юридический факультет)"],
    "Чувашский государственный университет имени И.Н. Ульянова (ЧГУ им. И.Н. Ульянова)": [
        "Факультет искусств",
        "Факультет русской и чувашской филологии и журналистики",
        "Факультет энергетики и электротехники",
        "Историко-географический факультет",
        "Строительный факультет",
        "Юридический факультет",
        "Машиностроительный факультет",
        "Факультет управления и социальных технологий",
        "Медицинский факультет",
        "Химико-фармацевтический факультет",
        "Факультет иностранных языков",
        "Факультет прикладной математики, физики и информационных технологий",
        "Экономический факультет",
        "Факультет информатики и вычислительной техники",
        "Факультет радиоэлектроники и автоматики",
    ],
    "Чувашский государственный аграрный университет (Чувашский ГАУ)": [
        "Инженерный факультет",
        "Экономический факультет",
        "Факультет биотехнологий и агрономии",
        "Факультет ветеринарной медицины и зоотехнии",
    ],
    "Волгоградская консерватория (институт) имени П. А. Серебрякова (Волгоградская консерватория им. П. А. Серебрякова)": ["Факультет высшего образования"],
    "Пензенский государственный университет (ПГУ)": [
        "Политехнический институт",
        "Институт экономики и управления",
        "Институт физической культуры и спорта",
        "Юридический институт",
        "Педагогический институт им. В.Г. Белинского",
        "Медицинский институт",
    ],
    "Пензенский государственный университет архитектуры и строительства (ПГУАС)": [
        "Институт экономики и менеджмента",
        "Технологический факультет",
        "Автомобильно-дорожный институт",
        "Факультет управления территориями",
        "Архитектурный факультет",
        "Инженерно-строительный институт",
        "Институт инженерной экологии",
    ],
    "Липецкий институт кооперации (филиал) Белгородского университета кооперации, экономики и права (Липецкий институт кооперации (филиал) БУКЭП)": ["Факультет экономики и товароведения"],
    "Тверской государственный медицинский университет (Тверской ГМУ)": ["Фармацевтический факультет", "Лечебный факультет", "Педиатрический факультет", "Стоматологический факультет"],
    "Калужский государственный университет им. К.Э. Циолковского (КГУ им. К.Э. Циолковского)": [
        "Институт истории и права",
        "Институт искусств и социокультурного проектирования",
        "Медицинский институт",
        "Инженерно-технологический институт",
        "Институт лингвистики и мировых языков",
        "Институт естествознания",
        "Институт филологии и массмедиа",
        "Институт педагогики",
        "Институт психологии",
    ],
    "Каспийский институт морского и речного транспорта имени генерал-адмирала Ф.М. Апраксина - филиал Волжского государственного университета водного транспорта (Каспийский институт морского и речного транспорта - филиал ВГУВТ)":
        ["Факультет высшего образования"],
    "Институт кино и телевидения (ГИТР) (ГИТР)": [
        "Режиссерский факультет",
        "Операторский факультет",
        "Факультет звукорежиссуры",
        "Художественный факультет",
        "Факультет журналистики",
        "Продюсерский факультет",
    ],
    "Московский инновационный университет (Московский инновационный университет)": [
        "Кафедра экономики и информационных технологий",
        "Кафедра управления и гуманитарных дисциплин",
        "Кафедра юридических дисциплин",
    ],
    "Бурятский государственный университет имени Доржи Банзарова (БГУ)": [
        "Социально-психологический факультет",
        "Педагогический институт",
        "Институт математики и информатики",
        "Институт экономики и управления",
        "Факультет физической культуры, спорта и туризма",
        "Физико-технический факультет",
        "Исторический факультет",
        "Факультет биологии, географии и землепользования",
        "Юридический факультет",
        "Химический факультет",
        "Восточный институт",
        "Медицинский институт",
        "Институт филологии, иностранных языков и массовых коммуникаций",
    ],
    "Академия акварели и изящных искусств Сергея Андрияки (Академия С. Андрияки)": ["Департамент высшего образования"],
    "Ивановская государственная медицинская академия (ИвГМА)": ["Лечебный факультет", "Педиатрический факультет", "Стоматологический факультет"],
    "Курский государственный университет (КГУ)": [
        "Факультет искусств и арт-педагогики",
        "Филологический факультет",
        "Дефектологический факультет",
        "Исторический факультет",
        "Факультет философии и социологии",
        "Естественно-географический факультет",
        "Факультет педагогики и психологии",
        "Художественно-графический факультет",
        "Индустриально-педагогический факультет",
        "Факультет теологии и религиоведения",
        "Юридический факультет",
        "Факультет иностранных языков",
        "Факультет физики, математики, информатики",
        "Институт социальных инноваций и технологий",
        "Институт экономики и управления",
        "Факультет физической культуры и спорта",
    ],
    "Международный институт компьютерных технологий (МИКТ)": ["Факультет информационных систем", "Энергетический факультет"],
    "Муромский институт (филиал) Владимирского государственного университета имени Александра Григорьевича и Николая Григорьевича Столетовых (МИ ВлГУ)": [
        "Факультет информационных технологий и радиоэлектроники",
        "Факультет экономики и менеджмента",
        "Машиностроительный факультет",
        "Гуманитарный факультет",
    ],
    "Российская государственная специализированная академия искусств (РГСАИ)": ["Театральный факультет", "Музыкальный факультет", "Факультет изобразительного искусства"],
    "Смоленский государственный университет (СмолГУ)": [
        "Физико-математический факультет",
        "Филологический факультет",
        "Факультет искусства и дизайна",
        "Естественно-географический факультет",
        "Факультет истории и права",
        "Факультет экономики и управления",
        "Психолого-педагогический факультет",
        "Социологический факультет",
    ],
    "Старооскольский филиал Белгородского государственного национального исследовательского университета (СОФ НИУ БелГУ)": ["Педагогический факультет", "Факультет экономики и управления"],
    "Тутаевский филиал Рыбинского государственного авиационного технического университета имени П.А. Соловьева (Тутаевский филиал РГАТУ им. П.А. Соловьева)": ["Машиностроительный факультет"],
    "Юго-Западный государственный университет (ЮЗГУ)": [
        "Факультет лингвистики и межкультурной коммуникации",
        "Механико-технологический факультет",
        "Факультет строительства и архитектуры",
        "Факультет фундаментальной и прикладной информатики",
        "Факультет государственного управления и международных отношений",
        "Факультет экономики и менеджмента",
        "Естественно-научный факультет",
        "Юридический факультет",
    ],
    "Русская христианская гуманитарная академия им. Ф. М. Достоевского (РХГА)": [
        "Факультет психологии и философии человека",
        "Факультет мировых языков и культур",
        "Факультет философии, богословия и религиоведения",
    ],
    "Вологодский институт права и экономики Федеральной службы исполнения наказаний (ВИПЭ ФСИН России)": ["Юридический факультет", "Психологический факультет", "Факультет психологии и права"],
    "Псковский государственный университет (ПсковГУ)": [
        "Институт гуманитарных наук и языковых коммуникаций",
        "Институт инженерных наук",
        "Институт образования и социальных наук",
        "Институт медицины и экспериментальной биологии",
        "Институт математического моделирования и игропрактики",
        "Институт права, экономики и управления",
    ],
    "Петрозаводский государственный университет (ПетрГУ)": [
        "Институт биологии, экологии и агротехнологий",
        "Институт истории, политических и социальных наук",
        "Институт экономики и права",
        "Институт лесных, горных и строительных наук",
        "Медицинский институт",
        "Институт математики и информационных технологий",
        "Физико-технический институт",
        "Институт педагогики и психологии",
        "Институт иностранных языков",
        "Институт физической культуры, спорта и туризма",
        "Институт филологии",
    ],
    "Крымский инженерно-педагогический университет имени Февзи Якубова (КИПУ им. Февзи Якубова)": [
        "Инженерно-технологический факультет",
        "Факультет психологии и педагогического образования",
        "Факультет филологии",
        "Факультет истории, искусств, крымскотатарского языка и литературы",
        "Факультет экономики, менеджмента и информационных технологий",
    ],
    "Гуманитарно-педагогическая академия (филиал) Крымского федерального университета имени В.И. Вернадского в г. Ялте (Гуманитарно-педагогическая академия (филиал) КФУ им. В.И. Вернадского в г. Ялте)":
        ["Институт филологии, истории и искусств", "Институт педагогики, психологии и инклюзивного образования", "Институт экономики и управления"],
    "Ростовский филиал Российского государственного университета правосудия (г. Ростов-на-Дону) (Ростовский филиал РГУП)": [
        "Факультет подготовки специалистов для судебной системы (юридический факультет)",
    ],
    "Технологический институт (филиал) Донского государственного технического университета в г. Азове (ТИ (филиал) ДГТУ в Азове)": ["Факультет высшего образования"],
    "Ингушский государственный университет (ИнгГУ)": [
        "Финансово-экономический факультет",
        "Агроинженерный факультет",
        "Химико-биологический факультет",
        "Медицинский факультет",
        "Экономический факультет",
        "Технолого-педагогический факультет",
        "Юридический факультет",
        "Физико-математический факультет",
        "Филологический факультет",
    ],
    "Мордовский государственный педагогический университет имени М.Е. Евсевьева (МГПУ)": [
        "Факультет педагогического и художественного образования",
        "Физико-математический факультет",
        "Факультет физической культуры",
        "Естественно-технологический факультет",
        "Факультет психологии и дефектологии",
        "Факультет иностранных языков",
        "Филологический факультет",
        "Факультет истории и права",
    ],
    "Казанский государственный институт культуры (КазГИК)": [
        "Факультет социально-культурных технологий и интеллектуальных систем",
        "Факультет высшей школы искусств",
        "Факультет инноваций и традиций народной художественной культуры",
        "Факультет театра, кино и телевидения",
    ],
    "Воткинский филиал Ижевского государственного технического университета имени М.Т. Калашникова (ВФ ИжГТУ им. М.Т. Калашникова)": ["Технологический факультет"],
    "Пермский государственный гуманитарно-педагогический университет (ПГГПУ)": [
        "Факультет педагогики и методики начального образования",
        "Факультет музыки",
        "Факультет педагогики и психологии детства",
        "Институт психологии",
        "Естественнонаучный факультет",
        "Факультет информатики и экономики",
        "Математический факультет",
        "Факультет иностранных языков",
        "Факультет правового и социально-педагогического образования",
        "Филологический факультет",
        "Физический факультет",
        "Факультет физической культуры",
        "Исторический факультет",
    ],
    "Березниковский филиал Пермского национального исследовательского политехнического университета (БФ ПНИПУ)": ["Факультет техники, технологии и управления"],
    "Нижегородская государственная сельскохозяйственная академия (Нижегородская ГСХА)": [
        "Факультет лесного хозяйства",
        "Инженерный факультет",
        "Зооинженерный факультет",
        "Ветеринарный факультет",
        "Биоэкологический факультет",
        "Агрономический факультет",
        "Факультет перерабатывающих технологий",
        "Экономический факультет",
    ],
    "Дзержинский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Дзержинский филиал РАНХиГС)": ["Факультет управления и экономики"],
    "Орский гуманитарно-технологический институт (филиал) Оренбургского государственного университета (Орский гуманитарно-технологический институт (филиал) ОГУ)": [
        "Психолого-педагогический факультет",
        "Факультет педагогического образования",
        "Факультет инженерии, экономики и права",
    ],
    "Самарский филиал Московского городского педагогического университета (СФ МГПУ)": ["Факультет педагогики и психологии", "Филологический факультет", "Факультет права и управления"],
    "Саратовская государственная юридическая академия (СГЮА)": [
        "Институт второго высшего и заочного обучения",
        "Институт правоохранительной деятельности",
        "Институт прокуратуры",
        "Институт юстиции",
        "Юридический институт правосудия и адвокатуры",
        "Межрегиональный юридический институт",
    ],
    "Шадринский государственный педагогический университет (ШГПУ)": [
        "Гуманитарный институт",
        "Факультет физической культуры",
        "Институт информационных технологий, точных и естественных наук",
        "Институт психологии и педагогики",
    ],
    "Уральская государственная консерватория имени М.П. Мусоргского (УГК им. М.П. Мусоргского)": [
        "Факультет инструментального исполнительства",
        "Факультет вокально-хорового искусства, музыковедения, композиции и музыкальной звукорежиссуры",
    ],
    "Тобольский педагогический институт имени Д.И. Менделеева (филиал) Тюменского государственного университета (ТПИ им. Д.И. Менделеева (филиал) ТюмГУ)": [
        "Социально-педагогический факультет",
        "Естественнонаучный факультет",
        "Гуманитарный факультет",
    ],
    "Горно-Алтайский государственный университет (ГАГУ)": [
        "Факультет алтаистики и тюркологии",
        "Историко-филологический факультет",
        "Физико-математический и инженерно-технологический институт",
        "Естественно-географический факультет",
        "Экономико-юридический факультет",
        "Психолого-педагогический факультет",
    ],
    "Алтайский государственный гуманитарно-педагогический университет имени В.М. Шукшина (АГГПУ им. В.М. Шукшина)": [
        "Институт педагогики и психологии",
        "Институт естественных наук и профессионального образования",
        "Институт гуманитарного образования",
    ],
    "Омская гуманитарная академия (ОмГА)": ["Факультет очно-заочного и заочного обучения", "Факультет социально-экономический очной формы обучения"],
    "Амурская государственная медицинская академия (Амурская ГМА)": ["Лечебный факультет", "Педиатрический факультет"],
    "Казанский кооперативный институт (филиал) Российского университета кооперации (Казанский кооперативный институт РУК)": [
        "Факультет экономики и таможенного дела",
        "Инженерно-технологический факультет",
    ],
    "Московский государственный университет имени М.В. Ломоносова (МГУ им. Ломоносова)": [
        "Факультет вычислительной математики и кибернетики",
        "Факультет почвоведения",
        "Филологический факультет",
        "Институт стран Азии и Африки",
        "Факультет глобальных процессов",
        "Высшая школа государственного аудита",
        "Физический факультет",
        "Геологический факультет",
        "Философский факультет",
        "Социологический факультет",
        "Факультет педагогического образования",
        "Высшая школа управления и инноваций",
        "Химический факультет",
        "Факультет фундаментальной медицины",
        "Экономический факультет",
        "Факультет иностранных языков и регионоведения",
        "Факультет политологии",
        "Высшая школа инновационного бизнеса",
        "Факультет наук о материалах",
        "Факультет фундаментальной физико-химической инженерии",
        "Юридический факультет",
        "Факультет государственного управления",
        "Высшая школа бизнеса",
        "Высшая школа современных социальных наук",
        "Географический факультет",
        "Высшая школа государственного администрирования",
        "Биологический факультет",
        "Биотехнологический факультет",
        "Факультет журналистики",
        "Факультет мировой политики",
        "Московская школа экономики",
        "Высшая школа телевидения",
        "Механико-математический факультет",
        "Факультет биоинженерии и биоинформатики",
        "Исторический факультет",
        "Факультет психологии",
        "Факультет искусств",
        "Высшая школа перевода",
        "Высшая школа культурной политики и управления в гуманитарной сфере (факультет)",
        "Факультет космических исследований",
    ],
    "Московский государственный лингвистический университет (МГЛУ)": [
        "Институт международных отношений и социально-политических наук",
        "Факультет немецкого языка",
        "Переводческий факультет",
        "Институт международного права и правосудия",
        "Факультет французского языка",
        "Факультет заочного обучения",
        "Институт иностранных языков имени Мориса Тореза",
        "Институт информационных наук",
        "Институт гуманитарных и прикладных наук",
        "Факультет английского языка",
    ],
    "Институт бизнеса и дизайна (B&D)": ["Факультет управления бизнесом", "Факультет дизайна и моды"],
    "Государственный институт русского языка им. А.С. Пушкина (Гос. ИРЯ им. А.С. Пушкина)": ["Филологический факультет"],
    "Литературный институт имени A.M. Горького (Литературный институт имени A.M. Горького)": ["Очный факультет", "Заочный факультет"],
    "Первый Санкт-Петербургский государственный медицинский университет имени академика И.П. Павлова (ПСПбГМУ им. И.П. Павлова)": [
        "Стоматологический факультет",
        "Факультет высшего сестринского образования",
        "Педиатрический факультет",
        "Факультет адаптивной физической культуры",
        "Отделение клинической психологии",
        "Лечебный факультет",
    ],
    "Ленинградский государственный университет имени А.С. Пушкина (ЛГУ им. А.С. Пушкина)": [
        "Факультет математики и информатики",
        "Юридический факультет",
        "Факультет психологии",
        "Факультет специального (дефектологического) образования",
        "Факультет физической культуры",
        "Факультет естествознания, географии и туризма",
        "Филологический факультет",
        "Факультет иностранных языков",
        "Факультет философии, культурологии и искусства",
        "Факультет истории и социальных наук",
        "Экономический факультет",
    ],
    "Санкт-Петербургский государственный университет гражданской авиации (СПбГУ ГА)": [
        "Факультет аэропортов и инженерно-технического обеспечения полётов",
        "Факультет управления на воздушном транспорте",
        "Гуманитарный факультет",
        "Факультет лётной эксплуатации",
    ],
    "Национальный государственный университет физической культуры, спорта и здоровья имени П.Ф. Лесгафта (НГУ им. П.Ф. Лесгафта)": [
        "Факультет образовательных технологий адаптивной физической культуры",
        "Факультет летних олимпийских видов спорта",
        "Факультет зимних олимпийских видов спорта",
        "Факультет единоборств и неолимпийских видов спорта",
        "Факультет здоровья и реабилитологии",
        "Институт менеджмента и социальных технологий",
    ],
    "Российский государственный художественно-промышленный университет им. С.Г. Строганова (РГХПУ им. С.Г. Строганова)": [
        "Факультет монументально-декоративного и декоративно-прикладного искусства",
        "Факультет искусства реставрации",
        "Факультет дизайна",
    ],
    "Мурманский государственный технический университет (МГТУ)": ["Естественно-технологический институт", "Институт «Морская академия»", "Институт арктических технологий"],
    "Театральный институт имени Бориса Щукина при Государственном академическом театре имени Евгения Вахтангова (Театральный институт имени Бориса Щукина)": [
        "Актерский факультет",
        "Режиссерский факультет",
    ],
    "Российский государственный институт сценических искусств (РГИСИ)": [
        "Продюсерский факультет",
        "Театроведческий факультет",
        "Факультет сценографии и театральной технологии",
        "Факультет театра кукол",
        "Факультет актерского искусства и режиссуры",
        "Факультет музыкального театра и эстрадного искусства",
    ],
    "Санкт-Петербургский государственный морской технический университет (СПбГМТУ)": [
        "Факультет корабельной энергетики и автоматики",
        "Факультет морского приборостроения",
        "Инженерно-экономический факультет",
        "Факультет цифровых промышленных технологий",
        "Факультет естественнонаучного и гуманитарного образования",
        "Факультет кораблестроения и океанотехники",
        "Вечерне-заочный факультет",
    ],
    "Кубанский государственный университет (КубГУ)": [
        "Факультет химии и высоких технологий",
        "Факультет журналистики",
        "Факультет управления и психологии",
        "Филологический факультет",
        "Факультет педагогики, психологии и коммуникативистики",
        "Факультет романо-германской филологии",
        "Факультет математики и компьютерных наук",
        "Художественно-графический факультет",
        "Факультет компьютерных технологий и прикладной математики",
        "Факультет истории, социологии и международных отношений",
        "Факультет архитектуры и дизайна",
        "Институт географии, геологии, туризма и сервиса",
        "Биологический факультет",
        "Физико-технический факультет",
        "Юридический факультет",
        "Экономический факультет",
    ],
    "Тихоокеанский государственный университет (ТОГУ)": [
        "Факультет естественных наук, математики и информационных технологий",
        "Факультет искусств, рекламы и дизайна",
        "Институт архитектуры и дизайна",
        "Транспортно-энергетический факультет",
        "Юридический институт",
        "Инженерно-строительный институт",
        "Факультет природопользования и экологии",
        "Факультет начального, дошкольного и дефектологического образования",
        "Факультет автоматизации и информационных технологий",
        "Факультет востоковедения и истории",
        "Институт экономики и управления",
        "Факультет психологии и социально-гуманитарных технологий",
        "Факультет физической культуры",
        "Факультет компьютерных и фундаментальных наук",
        "Институт социально-политических технологий и коммуникаций",
        "Факультет филологии, переводоведения и межкультурной коммуникации",
    ],
    "Хабаровский государственный институт культуры (ХГИК)": ["Факультет искусств и социокультурной деятельности"],
    "Воронежский государственный университет инженерных технологий (ВГУИТ)": [
        "Факультет управления и информатики в технологических системах",
        "Инженерно-технический факультет",
        "Факультет экологии и химической технологии",
        "Технологический факультет",
        "Факультет экономики и управления",
    ],
    "Воронежский государственный аграрный университет имени императора Петра I (Воронежский ГАУ)": [
        "Гуманитарно-правововой факультет",
        "Факультет землеустройства и кадастров",
        "Факультет технологии и товароведения",
        "Агроинженерный факультет",
        "Экономический факультет",
        "Факультет агрономии, агрохимии и экологии",
        "Факультет ветеринарной медицины и технологии животноводства",
    ],
    "Волгоградский государственный медицинский университет (ВолгГМУ)": [
        "Фармацевтический факультет",
        "Медико-биологический факультет",
        "Факультет социальной работы и клинической психологии",
        "Лечебный факультет",
        "Педиатрический факультет",
        "Стоматологический факультет",
    ],
    "Ярославский государственный технический университет (ЯГТУ)": [
        "Институт архитектуры и дизайна",
        "Институт цифровых систем",
        "Институт инженеров строительства и транспорта",
        "Институт экономики и менеджмента",
        "Институт инженерии и машиностроения",
        "Институт химии и химической технологии",
    ],
    "Калининградский государственный технический университет (КГТУ)": [
        "Институт агроинженерии и пищевых систем",
        "Институт рыболовства и аквакультуры",
        "Институт цифровых технологий",
        "Балтийская государственная академия рыбопромыслового флота",
        "Институт морских технологий, энергетики и строительства",
        "Институт отраслевой экономики и управления",
        "Морской институт",
    ],
    "Московский государственный институт культуры (МГИК)": [
        "Факультет медиакоммуникаций и аудиовизуальных искусств",
        "Театрально-режиссерский факультет",
        "Хореографический факультет",
        "Библиотечно-информационный факультет",
        "Факультет государственной культурной политики",
    ],
    "Казанский национальный исследовательский технический университет им. А.Н. Туполева-КАИ (КНИТУ-КАИ)": [
        "Физико-математический факультет",
        "Институт автоматики и электронного приборостроения",
        "Высшая школа прикладных информационных технологий",
        "Институт компьютерных технологий и защиты информации",
        "Институт радиоэлектроники, фотоники и цифровых технологий",
        "Институт инженерной экономики и предпринимательства",
        "Институт авиации, наземного транспорта и энергетики",
    ],
    "Казанский государственный аграрный университет (Казанский государственный аграрный университет)": [
        "Факультет лесного хозяйства и экологии",
        "Институт механизации и технического сервиса",
        "Институт экономики",
        "Институт агробиотехнологий и землепользования",
    ],
    "Новосибирский государственный технический университет (НГТУ)": [
        "Факультет летательных аппаратов",
        "Факультет энергетики",
        "Механико-технологический факультет",
        "Факультет бизнеса",
        "Факультет мехатроники и автоматизации",
        "Факультет гуманитарного образования",
        "Факультет прикладной математики и информатики",
        "Институт социальных технологий",
        "Факультет радиотехники и электроники",
        "Центр развития онлайн-обучения",
        "Факультет автоматики и вычислительной техники",
        "Физико-технический факультет",
    ],
    "Новосибирский государственный педагогический университет (НГПУ)": [
        "Институт естественных и социально-экономических наук",
        "Институт искусств",
        "Институт детства",
        "Факультет физической культуры",
        "Институт менеджмента и социальных коммуникаций",
        "Институт физико-математического и информационно-экономического образования",
        "Институт филологии, массовой информации и психологии",
        "Институт культуры и молодежной политики",
        "Факультет иностранных языков",
        "Институт истории, гуманитарного и социального образования",
        "Факультет психологии",
    ],
    "Западный филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Западный филиал РАНХиГС)": ["Факультет управления, экономики и сервиса"],
    "Балтийский гуманитарный институт (БГИ)": ["Юридический факультет", "Психологический факультет", "Факультет экономики и управления"],
    "Белгородский государственный институт искусств и культуры (БГИИК)": [
        "Факультет музыкального творчества",
        "Факультет режиссуры, актерского искусства и хореографии",
        "Факультет дизайна и технологий",
        "Факультет исполнительского искусства",
        "Факультет социально-культурной и информационно-библиотечной деятельности",
    ],
    "Российский государственный профессионально-педагогический университет (РГППУ)": [
        "Институт психолого-педагогического образования",
        "Институт гуманитарного и социально-экономического образования",
        "Институт физической культуры, спорта и здоровья",
        "Институт инженерно-педагогического образования",
    ],
    "Омский государственный университет путей сообщения (ОмГУПС)": [
        "Институт наземных транспортных систем",
        "Институт электрического транспорта и систем энергообеспечения",
        "Институт менеджмента и экономики",
        "Институт автоматики, телекоммуникаций и информационных технологий",
        "Институт образовательных коммуникаций и дистанционных технологий",
    ],
    "Нижегородский государственный лингвистический университет им. Н.А. Добролюбова (НГЛУ)": [
        "Высшая школа перевода",
        "Высшая школа лингвистики, педагогики и психологии",
        "Институт дистанционного обучения",
        "Институт русского языка",
        "Высшая школа международных отношений и мировой политики",
        "Высшая школа социальных наук",
    ],
    "Санкт-Петербургский университет Государственной противопожарной службы Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий (Санкт-Петербургский университет ГПС МЧС России)":
        ["Факультет экономики и права", "Институт безопасности жизнедеятельности", "Инженерно-технический факультет"],
    "Тувинский государственный университет (ТувГУ)": [
        "Естественно-географический факультет",
        "Факультет физической культуры и спорта",
        "Сельскохозяйственный факультет",
        "Физико-математический факультет",
        "Экономический факультет",
        "Исторический факультет",
        "Юридический факультет",
        "Филологический факультет",
        "Кызылский педагогический институт",
        "Инженерно-технический факультет",
    ],
    "Казанский инновационный университет имени В.Г. Тимирясова (КИУ (ИЭУП))": [
        "Факультет психологии и педагогики",
        "Факультет лингвистики и межкультурной коммуникации",
        "Экономический факультет",
        "Факультет менеджмента и инженерного бизнеса",
        "Юридический факультет",
        "Факультет гостеприимства и дизайна",
    ],
    "Московский филиал Российской международной академии туризма (Московский филиал РМАТ)": ["Факультет гостеприимства и туристской индустрии"],
    "Сибирский институт бизнеса и информационных технологий (СИБИТ)": ["Департамент заочного обучения", "Факультет очного обучения"],
    "Южно-Уральский государственный институт искусств имени П. И. Чайковского (ЮУрГИИ им. П. И. Чайковского)": [
        "Факультет социокультурной деятельности",
        "Хореографический факультет",
        "Факультет музыкального искусства",
    ],
    "Белгородский университет кооперации, экономики и права (БУКЭП)": [
        "Факультет заочного обучения",
        "Факультет экономики и менеджмента",
        "Юридический факультет",
        "Факультет общественного питания и товароведения",
        "Факультет таможенного дела и информационных технологий",
    ],
    "Владивостокский филиал Российской таможенной академии (Владивостокский филиал РТА)": ["Экономический факультет", "Юридический факультет", "Факультет таможенного дела"],
    "Воронежская государственная академия спорта (ВГИФК)": ["Факультет дневного обучения", "Факультет заочного обучения"],
    "Иркутский государственный медицинский университет (ИГМУ)": [
        "Стоматологический факультет",
        "Фармацевтический факультет",
        "Лечебный факультет",
        "Медико-профилактический факультет",
        "Педиатрический факультет",
    ],
    "Иркутский государственный университет (ИГУ)": [
        "Геологический факультет",
        "Факультет психологии",
        "Педагогический институт",
        "Сибирско-американский факультет менеджмента",
        "Институт математики и информационных технологий",
        "Факультет бизнес-коммуникаций и информатики",
        "Институт социальных наук",
        "Физический факультет",
        "Институт филологии, иностранных языков и медиакоммуникации",
        "Химический факультет",
        "Юридический институт",
        "Исторический факультет",
        "Биолого-почвенный факультет",
        "Географический факультет",
        "Международный институт экономики и лингвистики",
    ],
    "Краснодарский кооперативный институт (филиал) Российского университета кооперации (Краснодарский кооперативный институт (филиал) РУК)": [
        "Факультет управления и права",
        "Экономический факультет",
    ],
    "Рязанский государственный радиотехнический университет (РГРТУ)": [
        "Факультет автоматики и информационных технологий в управлении",
        "Факультет вычислительной техники",
        "Инженерно-экономический факультет",
        "Факультет электроники",
        "Факультет радиотехники и телекоммуникаций",
    ],
    "Глазовский государственный педагогический институт имени В.Г. Короленко (ГГПИ)": [
        "Факультет информатики, физики и математики",
        "Факультет социальных коммуникации и филологии",
        "Факультет педагогического и художественного образования",
        "Историко-лингвистический факультет",
    ],
    "Алтайский государственный медицинский университет (АГМУ)": [
        "Институт клинической медицины",
        "Институт педиатрии",
        "Институт фармации",
        "Институт клинической психологии",
        "Институт стоматологии",
        "Институт общественного здоровья и профилактической медицины",
    ],
    "Алтайский государственный университет (АлтГУ)": [
        "Институт географии",
        "Юридический институт",
        "Институт истории и международных отношений",
        "Институт математики и информационных технологий",
        "Институт гуманитарных наук",
        "Международный институт экономики, менеджмента и информационных систем",
        "Институт цифровых технологий, электроники и физики",
        "Институт биологии и биотехнологии",
        "Институт химии и химико-фармацевтических технологий",
    ],
    "Красноярский институт железнодорожного транспорта - филиал Иркутского государственного университета путей сообщения (КрИЖТ - филиал ИрГУПС)": [
        "Факультет очного обучения",
        "Факультет заочного обучения и дополнительного профессионального образования",
    ],
    "Оренбургский государственный медицинский университет (ОрГМУ)": [
        "Стоматологический факультет",
        "Факультет высшего сестринского образования",
        "Медико-профилактический факультет",
        "Фармацевтический факультет",
        "Педиатрический факультет",
        "Лечебный факультет",
        "Факультет клинической психологии",
    ],
    "Хабаровский институт инфокоммуникаций (филиал) Сибирского государственного университета телекоммуникаций и информатики (ХИИК СибГУТИ)": [
        "Факультет заочного обучения",
        "Факультет дневного обучения Инфокоммуникаций и средств связи",
    ],
    "Липецкий государственный педагогический университет имени П. П. Семенова-Тян-Шанского (ЛГПУ им. П. П. Семенова-Тян-Шанского)": [
        "Институт истории, права и общественных наук",
        "Институт культуры и искусства",
        "Институт психологии и образования",
        "Институт физической культуры и спорта",
        "Институт филологии",
        "Институт естественных, математических и технических наук",
    ],
    "Чувашский государственный педагогический университет им. И. Я. Яковлева (ЧГПУ им. И. Я. Яковлева)": [
        "Факультет иностранных языков",
        "Факультет истории, управления и права",
        "Факультет дошкольной и коррекционной педагогики и психологии",
        "Факультет чувашской и русской филологии",
        "Факультет художественного и музыкального образования",
        "Факультет физической культуры",
        "Факультет естественнонаучного образования",
        "Психолого-педагогический факультет",
        "Физико-математический факультет",
        "Технолого-экономический факультет",
    ],
    "Чебоксарский филиал Российской академии народного хозяйства и государственной службы при Президенте РФ (Чебоксарский филиал РАНХиГС)": ['Факультет "Управление и экономика"'],
    "Дагестанский государственный университет (ДГУ)": [
        "Социальный факультет",
        "Факультет психологии и философии",
        "Юридический институт",
        "Факультет востоковедения",
        "Факультет управления",
        "Факультет физкультуры и спорта",
        "Факультет иностранных языков",
        "Физический факультет",
        "Биологический факультет",
        "Факультет информатики и информационных технологий",
        "Филологический факультет",
        "Институт Экологии и устойчивого развития",
        "Факультет культуры",
        "Химический факультет",
        "Исторический факультет",
        "Факультет математики и компьютерных наук",
        "Экономический факультет",
    ],
    "Брянский государственный инженерно-технологический университет (БГИТУ)": [
        "Строительный институт",
        "Инженерно-экономический институт",
        "Институт лесного комплекса, ландшафтной архитектуры, транспорта и экологии",
    ],
    "Брянский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Брянский филиал РАНХиГС)": [
        "Юридический факультет",
        "Факультет государственного и муниципального управления",
        "Факультет экономики и финансов",
    ],
    "Сургутский государственный университет (СурГУ)": [
        "Институт государства и права",
        "Институт гуманитарного образования и спорта",
        "Институт естественных и технических наук",
        "Медицинский институт",
        "Политехнический институт",
        "Институт экономики и управления",
    ],
    "Ульяновский государственный педагогический университет имени И.Н. Ульянова (УлГПУ им. И.Н. Ульянова)": [
        "Факультет педагогики и психологии",
        "Естественно-географический факультет",
        "Факультет физической культуры и спорта",
        "Факультет физико-математического и технологического образования",
        "Факультет права, экономики и управления",
        "Историко-филологический факультет",
        "Факультет иностранных языков",
    ],
    "Калужский институт (филиал) Московского гуманитарно-экономического университета (Калужский институт (филиал) МГЭУ)": [
        "Факультет менеджмента и психологии",
        "Факультет дизайна",
        "Юридический факультет",
        "Факультет экономики",
    ],
    "Северный (Арктический) федеральный университет имени М. В. Ломоносова (САФУ им. М. В. Ломоносова)": [
        "Высшая школа экономики, управления и права",
        "Высшая инженерная школа",
        "Высшая школа энергетики, нефти и газа",
        "Высшая школа естественных наук и технологий",
        "Высшая школа информационных технологий и автоматизированных систем",
        "Высшая школа психологии, педагогики и физической культуры",
        "Высшая школа социально-гуманитарных наук и международной коммуникации",
    ],
    "Астраханский государственный архитектурно-строительный университет (АГАСУ)": [
        "Строительный факультет",
        "Архитектурный факультет",
        "Факультет инженерных систем и пожарной безопасности",
        "Экономический факультет",
    ],
    "Национальный институт бизнеса (Национальный институт бизнеса)": ["Факультет экономики, управления и права", "Заочный факультет экономики и права"],
    "Академия управления и производства (Академия управления и производства)": [
        "Кафедра гражданского права и процесса",
        "Кафедра уголовного права и процесса",
        "Кафедра экономики и управления",
        "Кафедра социально-гуманитарных дисциплин",
        "Кафедра естественно-научных и технических дисциплин",
    ],
    "Читинский институт (филиал) Байкальского государственного университета (ЧИ БГУ)": ["Юридический факультет", "Финансово-экономический факультет"],
    "Бурятская государственная сельскохозяйственная академия имени В.Р. Филиппова (Бурятская ГСХА)": [
        "Инженерный факультет",
        "Технологический факультет",
        "Факультет агробизнеса и межкультурных коммуникаций",
        "Институт землеустройства, кадастров и мелиорации",
        "Агрономический факультет",
        "Факультет ветеринарной медицины",
    ],
    "Бурятский институт инфокоммуникаций (филиал) Сибирского государственного университета телекоммуникаций и информатики в г. Улан-Удэ (БИИК СибГУТИ)": [
        "Факультет Инфокоммуникационных технологий и экономики",
    ],
    "Академия гражданской защиты Министерства Российской Федерации по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий (АГЗ МЧС России)": [
        "Гуманитарный факультет",
        "Факультет заочного обучения",
        "Инженерный факультет",
    ],
    "Владимирский юридический институт Федеральной службы исполнения наказаний (ВЮИ ФСИН России)": ["Юридический факультет", "Факультет права и управления"],
    "Высшая школа сценических искусств (Театральная школа Константина Райкина) (ВШСИ)": ["Факультет театроведения, менеджмента и театральных технологий"],
    "Еврейский университет (Еврейский университет)": ["Факультет дополнительного образования", "Гуманитарно-экономический факультет", "Факультет права и информационных технологий"],
    "Ивановская государственная сельскохозяйственная академия имени Д.К. Беляева (Ивановская ГСХА)": [
        "Факультет ветеринарной медицины и биотехнологии в животноводстве",
        "Инженерно-экономический факультет",
        "Факультет агротехнологий и агробизнеса",
    ],
    "Калужский филиал Московского государственного технического университета имени Н.Э. Баумана (национальный исследовательский университет) (Калужский филиал МГТУ им. Н.Э. Баумана)": [
        "Машиностроительный факультет",
        "Факультет информатики и управления",
    ],
    "Костромская государственная сельскохозяйственная академия (Костромская ГСХА)": [
        "Факультет агробизнеса",
        "Факультет ветеринарной медицины и зоотехнии",
        "Электроэнергетический факультет",
        "Экономический факультет",
        "Архитектурно-строительный факультет",
        "Инженерно-технологический факультет",
    ],
    "Мичуринский государственный аграрный университет (Мичуринский ГАУ)": [
        "Плодоовощной институт им. И.В. Мичурина",
        "Инженерный институт",
        "Социально-педагогический институт",
        "Институт экономики и управления",
    ],
    "Российский православный университет святого Иоанна Богослова (РПУ св. Иоанна Богослова)": ["Философско-богословский факультет", "Факультет психологии", "Юридический факультет"],
    "Российская международная академия туризма (РМАТ)": ["Факультет менеджмента туризма"],
    "Тверской институт (филиал) Московского гуманитарно-экономического университета (Тверской институт (филиал) МГЭУ)": [
        "Юридический факультет",
        "Факультет экономики и управления",
        "Факультет психологии",
    ],
    "Вологодский государственный университет (ВоГУ)": [
        "Институт социальных и гуманитарных наук",
        "Институт культуры и туризма",
        "Институт математики, естественных и компьютерных наук",
        "Институт машиностроения, энергетики и транспорта",
        "Институт педагогики, психологии и физического воспитания",
        "Инженерно-строительный институт",
        "Институт управления, экономики и юриспруденции",
    ],
    "Керченский государственный морской технологический университет (КГМТУ)": ["Технологический факультет", "Морской факультет"],
    "Филиал Кубанского государственного университета в г. Славянске-на-Кубани (Филиал КубГУ в г. Славянске-на-Кубани)": [
        "Факультет математики, информатики, биологии и технологии",
        "Факультет педагогики, психологии и физической культуры",
        "Факультет филологии, истории и обществознания",
    ],
    "Сочинский институт (филиал) Российского университета дружбы народов (Сочинский институт РУДН)": [
        "Экономический факультет",
        "Юридический факультет",
        "Историко-филологический факультет",
        "Учебно-научный департамент биомедицинских, ветеринарных и экологических направлений",
    ],
    "Армавирский лингвистический социальный институт (АЛСИ)": ["Факультет высшего образования"],
    "Волгоградский гуманитарный институт (ВгГИ)": ["Юридический факультет", "Экономический факультет", "Факультет психологии, журналистики и дизайна"],
    "Ростовский институт (филиал) Всероссийского государственного университета юстиции (РПА Минюста России) (Ростовский институт (филиал) ВГУЮ (РПА Минюста России))": ["Юридический факультет"],
    "Донской государственный аграрный университет (Донской ГАУ)": ["Факультет ветеринарной медицины", "Агрономический факультет", "Биотехнологический факультет"],
    "Политехнический институт (филиал) Донского государственного технического университета в г. Таганроге (ПИ (филиал) ДГТУ в г. Таганроге)": ["Факультет высшего образования"],
    "Северо-Кавказская государственная академия (СевКавГА)": [
        "Юридический институт",
        "Аграрный институт",
        "Факультет дизайна и искусств",
        "Инженерный институт",
        "Медицинский институт",
        "Институт прикладной математики и информационных технологий",
        "Институт экономики и управления",
    ],
    "Ставропольский институт кооперации (филиал) Белгородского университета кооперации, экономики и права (Ставропольский институт кооперации (филиал) БУКЭП)": [
        "Финансово-экономический факультет",
        "Юридический факультет",
    ],
    "Невинномысский государственный гуманитарно-технический институт (НГГТИ)": ["Факультет техники и современных технологий", "Финансово-экономический факультет", "Гуманитарный факультет"],
    "Филиал Ставропольского государственного педагогического института в г. Железноводске (Филиал СГПИ в г. Железноводске)": ["Психолого-педагогический факультет"],
    "Башкирский институт физической культуры (филиал) Уральского государственного университета физической культуры (БИФК УралГУФК)": ["Факультет спорта и адаптивной физической культуры"],
    "Сибайский институт (филиал) Уфимского университета науки и технологий (СИ УУНиТ)": [
        "Естественно-математический факультет",
        "Педагогический факультет",
        "Технологический факультет",
        "Факультет экономики и права",
    ],
    "Национальный исследовательский Мордовский государственный университет им. Н.П. Огарёва (МГУ им. Н.П. Огарёва)": [
        "Институт национальной культуры",
        "Факультет математики и информационных технологий",
        "Институт электроники и светотехники",
        "Филологический факультет",
        "Историко-социологический институт",
        "Медицинский институт",
        "Аграрный институт",
        "Факультет биотехнологии и биологии",
        "Институт механики и энергетики",
        "Факультет иностранных языков",
    ],
    "Поволжский государственный университет физической культуры, спорта и туризма (Поволжский ГУФКСиТ)": [
        "Международный институт гостиничного менеджмента и туризма",
        "Институт спорта",
        "Институт физической культуры",
        "Институт заочного образования",
    ],
    "Пермский государственный институт культуры (ПГИК)": ["Факультет культурологии и социально-культурных технологий", "Консерватория", "Факультет искусств"],
    "Лысьвенский филиал Пермского национального исследовательского политехнического университета (ЛФ ПНИПУ)": ["Факультет профессионального образования"],
    "Поволжский институт (филиал) Всероссийского государственного университета юстиции (РПА Минюста России) в г. Саратове (Поволжский институт ВГУЮ)": ["Факультет высшего образования"],
    "Балаковский инженерно-технологический институт - филиал Национального исследовательского ядерного университета «МИФИ» (БИТИ НИЯУ МИФИ)": ["Факультет атомной энергетики и технологий"],
    "Курганская государственная сельскохозяйственная академия имени Т.С. Мальцева - филиал Курганского государственного университета (Курганская ГСХА)": [
        "Институт биотехнологии",
        "Институт инженерии и агрономии",
    ],
    "Екатеринбургская академия современного искусства (институт) (ЕАСИ)": ["Факультет творческих индустрий"],
    "Магнитогорская государственная консерватория (академия) имени М.И. Глинки (МаГК (академия) им. М.И. Глинки)": [
        "Музыковедческо-исполнительский факультет",
        "Факультет хореографического искусства и музыкального образования",
        "Театральный факультет",
    ],
    "Хакасский государственный университет им. Н.Ф. Катанова (ХГУ им. Н.Ф. Катанова)": [
        "Институт естественных наук и математики",
        "Институт искусств",
        "Медико-психолого-социальный институт",
        "Сельскохозяйственный институт",
        "Инженерно-технологический институт",
        "Институт непрерывного педагогического образования",
        "Институт истории и права",
        "Институт филологии и межкультурной коммуникации",
        "Институт экономики и управления",
    ],
    "Заполярный государственный университет им. Н.М. Федоровского (ЗГУ им. Н.М. Федоровского)": ["Факультет электроэнергетики, экономики и управления", "Горно-технологический факультет"],
    "Ангарский государственный технический университет (АнГТУ)": ["Факультет технической кибернетики", "Технологический факультет", "Факультет управления и бизнеса"],
    "Новосибирский технологический институт (филиал) Российского государственного университета им. А.Н. Косыгина (Технологии. Дизайн. Искусство) (НТИ (филиал) РГУ им. А.Н. Косыгина)": [
        "Факультет заочного обучения и экстерната",
        "Факультет технологии и дизайна",
    ],
    "Амурский государственный университет (АмГУ)": [
        "Факультет дизайна и технологии",
        "Энергетический факультет",
        "Факультет математики и информатики",
        "Юридический факультет",
        "Факультет международных отношений",
        "Факультет социальных наук",
        "Филологический факультет",
        "Инженерно-физический факультет",
        "Экономический факультет",
    ],
    "Европейский университет в Санкт-Петербурге (АмГУ)": [
        "Факультет истории",
        "Отдел международных программ",
        "Факультет истории искусств",
        "Факультет политических наук",
        "Факультет социологии",
        "Факультет экономики",
        "Факультет антропологии",
        "Центр практической философии «Стасис»",
    ],
    "Ростовская государственная консерватория (академия) им. С. В. Рахманинова (РГК им. С.В.Рахманинова)": [
        "Оркестровый факультет",
        "Историко-теоретико-композиторский факультет",
        "Факультет сольного пения и дирижирования",
        "Факультет эстрадно-джазовой музыки и звукорежиссуры",
        "Фортепианный факультет",
    ],
    "Московский университет имени С.Ю. Витте (МУ им. С.Ю. Витте)": ["Факультет управления", "Факультет экономики и финансов", "Юридический факультет"],
    "Университет мировых цивилизаций имени В.В. Жириновского (УМЦ им. В.В. Жириновского)": [
        "Институт управления, экономики и финансов",
        "Факультет современного права",
        "Факультет дизайна и цивилизационных коммуникаций",
        "Факультет международных отношений и геополитики",
        "Факультет журналистики",
        "Институт лингвопереводческих технологий и развития международных коммуникаций",
    ],
    "Московский социально-педагогический институт (МСПИ)": [
        "Факультет педагогики и психологии",
        "Факультет практической психологии",
        "Факультет государственного и муниципального управления",
        "Факультет коррекционной педагогики и специальной психологии",
    ],
    "Дипломатическая академия Министерства иностранных дел Российской Федерации (Дипломатическая академия МИД России)": [
        "Факультет «Международные отношения»",
        "Факультет «Мировая экономика и международное право»",
    ],
    "Национальный исследовательский ядерный университет «МИФИ» (НИЯУ МИФИ)": [
        "Институт нанотехнологий в электронике, спинтронике и фотонике",
        "Институт интеллектуальных кибернетических систем",
        "Факультет очно-заочного (вечернего) обучения",
        "Институт финансовых технологий и экономической безопасности",
        "Институт ядерной физики и технологий",
        "Институт международных отношений",
        "Институт лазерных и плазменных технологий",
        "Факультет бизнес-информатики и управления комплексными системами",
        "Инженерно-физический институт биомедицины",
        "Институт физико-технических интеллектуальных систем",
        "Высшая инжиниринговая школа",
    ],
    "Московский экономический институт (МЭИ)": ["Факультет права и экономики", "Факультет дизайна"],
    "Государственный академический университет гуманитарных наук (ГАУГН)": [
        "Восточный факультет",
        "Исторический факультет",
        "Социологический факультет",
        "Юридический факультет",
        "Факультет мировой политики",
        "Факультет психологии",
        "Философский факультет",
        "Экономический факультет",
        "Факультет политологии",
    ],
    "Мытищинский филиал Московского государственного технического университета имени Н.Э. Баумана (национального исследовательского университета) (МФ МГТУ им. Н.Э. Баумана)": [
        "Факультет лесного хозяйства, лесопромышленных технологий и садово-паркового строительства",
        "Космический факультет",
    ],
    "Санкт-Петербургский государственный педиатрический медицинский университет (СПбГПМУ)": [
        "Стоматологический факультет",
        "Педиатрический факультет",
        "Факультет клинической психологии",
        "Факультет Лечебное дело",
    ],
    "Санкт-Петербургский государственный архитектурно-строительный университет (СПбГАСУ)": [
        "Автомобильно-дорожный факультет",
        "Факультет экономики и управления",
        "Факультет судебных экспертиз и права в строительстве и на транспорте",
        "Архитектурный факультет",
        "Институт безотрывных форм обучения",
        "Строительный факультет",
        "Факультет инженерной экологии и городского хозяйства",
    ],
    "Санкт-Петербургский государственный университет ветеринарной медицины (СПбГУВМ)": [
        "Факультет ветеринарной медицины",
        "Факультет ветеринарно-санитарной экспертизы",
        "Факультет водных биоресурсов и аквакультуры",
        "Факультет биоэкологии",
    ],
    "Российская академия музыки имени Гнесиных (РАМ имени Гнесиных)": [
        "Оркестровый факультет",
        "Факультет современной музыкальной индустрии",
        "Факультет народных инструментов",
        "Факультет фольклорного искусства",
        "Вокальный факультет",
        "Факультет музыкального искусства эстрады",
        "Дирижерский факультет",
        "Фортепианный факультет",
        "Историко-теоретико-композиторский факультет",
    ],
    "Пермский филиал Национального исследовательского университета «Высшая школа экономики» (Пермский филиал НИУ ВШЭ)": [
        "Социально-гуманитарный факультет",
        "Факультет экономики, менеджмента и бизнес-информатики",
    ],
    "Мурманский арктический государственный университет (МАГУ)": [
        "Факультет математических и естественных наук",
        "Институт креативных индустрий и предпринимательства",
        "Институт лингвистики",
        "Юридический факультет",
        "Психолого-педагогический институт",
        "Социально-гуманитарный институт",
        "Факультет физической культуры и безопасности жизнедеятельности",
    ],
    "Омский государственный медицинский университет (ОмГМУ)": [
        "Лечебный факультет",
        "Стоматологический факультет",
        "Медико-профилактический факультет",
        "Педиатрический факультет",
        "Фармацевтический факультет",
    ],
    "Самарский национальный исследовательский университет имени академика С.П. Королева (Самарский университет Королева)": [
        "Институт экономики и управления",
        "Естественнонаучный институт",
        "Социально-гуманитарный институт",
        "Юридический институт",
        "Институт авиационной и ракетно-космической техники",
        "Институт информатики и кибернетики",
        "Институт двигателей и энергетических установок",
    ],
    "Уфимский университет науки и технологий (УУНиТ)": [
        "Физико-технический институт",
        "Факультет философии и социологии",
        "Институт истории и государственного управления",
        "Вечерний факультет при ПАО «ОДК-УМПО»",
        "Химический факультет",
        "Институт экономики, финансов и бизнеса",
        "Факультет авиационных двигателей, энергетики и транспорта",
        "Факультет защиты в чрезвычайных ситуациях",
        "Инженерный факультет",
        "Институт права",
        "Факультет авионики, энергетики и инфокоммуникаций",
        "Биологический факультет",
        "Филологический факультет",
        "Факультет информатики и робототехники",
        "Факультет психологии",
        "Факультет башкирской филологии, востоковедения и журналистики",
        "Институт авиационных технологий и материалов",
        "Факультет математики и информационных технологий",
        "Факультет наук о Земле и туризма",
        "Факультет романо-германской филологии",
        "Институт экономики и управления",
    ],
    "Международный банковский институт имени Анатолия Собчака (МБИ им. А. Собчака)": ["Факультет экономических и прикладных наук"],
    "Пермский государственный национальный исследовательский университет (ПГНИУ)": [
        "Географический факультет",
        "Филологический факультет",
        "Геологический факультет",
        "Философско-социологический факультет",
        "Историко-политологический факультет",
        "Химический факультет",
        "Механико-математический факультет",
        "Экономический факультет",
        "Факультет современных иностранных языков и литератур",
        "Юридический факультет",
        "Биологический факультет",
        "Физический факультет",
        "Институт компьютерных наук и технологий",
    ],
    "Владивостокский государственный университет (ВВГУ)": [
        "Институт физической культуры и спорта",
        "Международный институт окружающей среды и туризма",
        "Институт международного бизнеса, экономики и управления",
        "Институт педагогики и лингвистики",
        "Институт маркетинга и коммерческой деятельности",
        "Институт права",
        "Высшая школа кино и телевидения",
        "Институт креативных индустрий",
        "Институт информационных технологий",
        "Институт нефтегазового дела, транспорта и логистики",
        "Институт цифровой экономики и бизнес-аналитики",
    ],
    "Воронежский государственный медицинский университет им. Н.Н. Бурденко (ВГМУ им. Н.Н. Бурденко)": [
        "Лечебный факультет",
        "Педиатрический факультет",
        "Стоматологический факультет",
        "Фармацевтический факультет",
        "Медико-профилактический факультет",
    ],
    "Кузбасский государственный технический университет имени Т.Ф. Горбачева (КузГТУ)": [
        "Горный институт",
        "Институт экономики и управления",
        "Строительный институт",
        "Институт информационных технологий, машиностроения и автотранспорта",
        "Институт энергетики",
        "Институт химических и нефтегазовых технологий",
    ],
    "Волгоградский государственный социально-педагогический университет (ВГСПУ)": [
        "Факультет математики, информатики и физики",
        "Институт технологии, экономики и сервиса",
        "Институт иностранных языков",
        "Институт естественнонаучного образования, физической культуры и безопасности жизнедеятельности",
        "Институт русского языка и словесности",
        "Факультет дошкольного и начального образования",
        "Факультет психолого-педагогического и социального образования",
        "Факультет исторического и правового образования",
        "Факультет социальной и коррекционной педагогики",
        "Институт художественного образования",
    ],
    "Ярославский государственный медицинский университет (ЯГМУ)": [
        "Лечебный факультет",
        "Педиатрический факультет",
        "Фармацевтический факультет",
        "Стоматологический факультет",
        "Факультет клинической психологии и социальной работы",
    ],
    "Санкт-Петербургский университет технологий управления и экономики (СПбУТУиЭ)": [
        "Институт гуманитарных и социальных наук",
        "Институт экономики, менеджмента и информационных технологий",
        "Юридический институт",
        "Институт международных программ",
        "Институт электронного обучения",
    ],
    "Казанская государственная академия ветеринарной медицины им. Н.Э. Баумана (Казанская ГАВМ)": ["Факультет ветеринарной медицины", "Факультет биотехнологии и стандартизации"],
    "Сибирский государственный университет телекоммуникаций и информатики (СибГУТИ)": [
        "Институт безопасности",
        "Институт телекоммуникаций",
        "Институт информатики и вычислительной техники",
        "Межрегиональный учебный центр переподготовки специалистов",
        "Институт заочного образования",
    ],
    "Пермский институт (филиал) Российского экономического университета имени Г.В. Плеханова (Пермский институт (филиал) РЭУ им. Г.В. Плеханова)": [
        "Факультет менеджмента",
        "Учетно-финансовый факультет",
    ],
    "Краснодарский государственный институт культуры (КГИК)": [
        "Факультет дизайна и изобразительных искусств",
        "Факультет народной культуры",
        "Факультет Консерватория",
        "Факультет гуманитарного образования",
        "Факультет телерадиовещания и театрального искусства",
    ],
    "Самарский государственный университет путей сообщения (СамГУПС)": [
        "Факультет систем обеспечения движения поездов",
        "Факультет экономики и управления персоналом",
        "Факультет эксплуатации железных дорог и логистики",
        "Заочный факультет",
        "Факультет строительства железных дорог и информационных технологий",
        "Факультет подвижного состава и путевых машин",
    ],
    "Северо-Западный филиал Российского государственного университета правосудия (СЗФ РГУП)": ["Факультет подготовки специалистов для судебной системы (Юридический факультет)"],
    "Красноярский государственный аграрный университет (Красноярский ГАУ)": [
        "Институт агроэкологических технологий",
        "Юридический институт",
        "Институт прикладной биотехнологии и ветеринарной медицины",
        "Институт землеустройства, кадастров и природообустройства",
        "Институт пищевых производств",
        "Институт инженерных систем и энергетики",
        "Институт экономики и управления АПК",
    ],
    "Морской государственный университет имени адмирала Г.И. Невельского (МГУ им. адм. Г.И. Невельского)": [
        "Судоводительский факультет",
        "Факультет экологической безопасности и освоения шельфа",
        "Факультет заочного и дистанционного обучения",
        "Факультет управления морским транспортом и экономики",
        "Судомеханический факультет",
        "Юридический факультет",
        "Электромеханический факультет",
        "Морской факультет гуманитарных технологий",
        "Физико-технический факультет",
        "Факультет электроники и информационных технологий",
    ],
    "Сибирский государственный автомобильно-дорожный университет (СибАДИ) (СибАДИ)": [
        "Институт «Информационные системы, экономика и управление»",
        "Институт «Автомобильно-дорожное, промышленное и гражданское строительство»",
        "Институт «Автомобильный транспорт, нефтегазовая и строительная техника»",
    ],
    "Уральский государственный горный университет (УГГУ)": [
        "Горно-механический факультет",
        "Горно-технологический факультет",
        "Инженерно-экономический факультет",
        "Факультет геологии и геофизики",
        "Факультет заочного обучения",
    ],
    "Государственный аграрный университет Северного Зауралья (ГАУ Северного Зауралья)": [
        "Агротехнологический институт",
        "Инженерно-технологический институт",
        "Институт биотехнологии и ветеринарной медицины",
    ],
    "Иркутский филиал Московского государственного технического университета гражданской авиации (Иркутский филиал МГТУ ГА)": [
        "Факультет авиационных систем и комплексов",
        "Факультет эксплуатации летательных аппаратов",
        "Факультет сервиса на транспорте",
    ],
    "Уральский институт управления - филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Уральский институт управления РАНХиГС)": [
        "Факультет экономики и менеджмента",
        "Юридический факультет",
        "Факультет государственного и муниципального управления",
    ],
    "Сибирский государственный институт искусств имени Дмитрия Хворостовского (СГИИ им. Д. Хворостовского)": [
        "Музыкальный факультет",
        "Художественный факультет",
        "Факультет театрального и хореографического искусств",
    ],
    "Уральский технический институт связи и информатики (филиал) Сибирского государственного университета телекоммуникаций и информатики (УрТИСИ СибГУТИ)": [
        "Факультет инфокоммуникаций, информатики и управления",
        "Факультет непрерывного образования",
    ],
    "Южно-Уральский государственный гуманитарно-педагогический университет (ЮУрГГПУ)": [
        "Факультет математики, физики, информатики",
        "Филологический факультет",
        "Факультет подготовки учителей начальных классов",
        "Высшая школа физической культуры и спорта",
        "Факультет психологии",
        "Факультет народного художественного творчества",
        "Исторический факультет",
        "Факультет инклюзивного и коррекционного образования",
        "Факультет иностранных языков",
        "Факультет дошкольного образования",
        "Естественно-технологический факультет",
        "Профессионально-педагогический институт",
    ],
    "Башкирский государственный педагогический университет им. М. Акмуллы (БГПУ им. М. Акмуллы)": [
        "Институт филологического образования и межкультурных коммуникаций",
        "Институт исторического, правового и социально-гуманитарного образования",
        "Институт физики, математики, цифровых и нанотехнологий",
        "Художественно-графический факультет",
        "Институт физической культуры и здоровья человека",
        "Естественно-географический факультет",
        "Институт педагогики",
        "Факультет башкирской филологии",
        "Факультет психологии",
    ],
    "Поволжский институт управления имени П. А. Столыпина - филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Поволжский институт управления – филиал РАНХиГС)":
        ["Факультет государственного и муниципального управления", "Факультет экономики и управления", "Факультет политико-правового управления"],
    "Белгородский государственный аграрный университет имени В.Я. Горина (Белгородский ГАУ)": [
        "Агрономический факультет",
        "Инженерный факультет",
        "Технологический факультет",
        "Факультет ветеринарной медицины",
        "Экономический факультет",
    ],
    "Воронежский экономико-правовой институт (Воронежский экономико-правовой институт)": ["Социально-правовой факультет", "Экономический факультет"],
    "Ижевский государственный технический университет имени М. Т. Калашникова (ИжГТУ имени М. Т. Калашникова)": [
        "Факультет математики и естественных наук",
        "Институт строительства и архитектуры имени В. А. Шумилова",
        "Институт современных технологий машиностроения, автомобилестроения и металлургии",
        "Институт энергетики и жилищно-коммунального хозяйства",
        "Институт информатики и вычислительной техники",
        "Институт цифровой экономики",
        "Приборостроительный факультет",
        "Институт международных образовательных программ",
        "Машиностроительный факультет",
        "Институт физической культуры и спорта им. А.И. Тихонова четырехкратного олимпийского чемпиона",
    ],
    "Воронежский государственный педагогический университет (ВГПУ)": [
        "Факультет физической культуры и безопасности жизнедеятельности",
        "Физико-математический факультет",
        "Факультет искусств и художественного образования",
        "Гуманитарный факультет",
        "Естественно-географический факультет",
        "Психолого-педагогический факультет",
        "Факультет иностранных языков",
    ],
    "Академия маркетинга и социально-информационных технологий - ИМСИТ (Академия ИМСИТ)": [
        "Институт педагогики и социальных коммуникаций",
        "Институт информационных технологий и инноваций",
        "Институт цифровой экономики и управления бизнес-процессами",
    ],
    "Уральский филиал Российского государственного университета правосудия (Уральский филиал РГУП)": ["Факультет подготовки специалистов для судебной системы (юридический факультет)"],
    "Тамбовский государственный музыкально-педагогический институт им. С.В. Рахманинова (ТГМПИ им. С.В. Рахманинова)": [
        "Факультет педагогического образования и народной художественной культуры",
        "Факультет музыкального исполнительства и музыковедения",
    ],
    "Прикамский социальный институт (ПСИ)": ["Юридический факультет", "Факультет психологии и управления"],
    "Рязанский государственный агротехнологический университет имени П. А. Костычева (РГАТУ)": [
        "Автодорожный факультет",
        "Факультет экономики и менеджмента",
        "Факультет ветеринарной медицины и биотехнологии",
        "Инженерный факультет",
        "Технологический факультет",
    ],
    "Алтайский государственный аграрный университет (АГАУ)": [
        "Агрономический факультет",
        "Факультет заочного обучения",
        "Инженерный факультет",
        "Биолого-технологический факультет",
        "Экономический факультет",
        "Факультет ветеринарной медицины",
        "Факультет природообустройства",
    ],
    "Алтайский государственный педагогический университет (АлтГПУ)": [
        "Лингвистический институт",
        "Институт психологии и педагогики",
        "Институт информационных технологий и физико-математического образования",
        "Институт истории, социальных коммуникаций и права",
        "Институт физической культуры и спорта",
        "Институт филологии и межкультурной коммуникации",
    ],
    "Тульский государственный педагогический университет им. Л.Н. Толстого (ТГПУ им. Л.Н. Толстого)": [
        "Физико-математический факультет",
        "Факультет технологий и бизнеса",
        "Институт передовых информационных технологий",
        "Факультет естественных наук",
        "Факультет психологии",
        "Институт туризма и креативных индустрий",
        "Факультет физической культуры",
        "Факультет иностранных языков",
        "Факультет русской филологии и документоведения",
        "Международный факультет",
        "Факультет истории и права",
        "Факультет искусств, социальных и гуманитарных наук",
    ],
    "Липецкий государственный технический университет (ЛГТУ)": [
        "Институт машиностроения",
        "Факультет гуманитарно-социальных наук и права",
        "Металлургический институт",
        "Экономический факультет",
        "Инженерно-строительный факультет",
        "Заочный факультет",
        "Физико-технологический факультет",
        "Факультет инженеров транспорта",
        "Факультет автоматизации и информатики",
    ],
    "Тюменский государственный институт культуры (ТГИК)": [
        "Факультет дизайна, визуальных искусств и архитектуры",
        "Факультет социально-культурных технологий",
        "Факультет музыки, театра и хореографии",
    ],
    "Дагестанский государственный педагогический университет (ДГПУ)": [
        "Факультет дошкольного образования",
        "Факультет социальной педагогики и психологии",
        "Музыкально-педагогический факультет",
        "Факультет иностранных языков",
        "Факультет специального (дефектологического) образования",
        "Исторический факультет",
        "Факультет профессионально-педагогического образования",
        "Институт физико-математического и информационно-технологического образования",
        "Факультет физической культуры и безопасности жизнедеятельности",
        "Факультет биологии, географии и химии",
        "Факультет начальных классов",
        "Филологический факультет",
        "Факультет дагестанской филологии",
        "Факультет управления и права",
        "Художественно-графический факультет",
    ],
    "Кузбасская государственная сельскохозяйственная академия (Кузбасская ГСХА)": ["Зоотехнический факультет", "Факультет технологического предпринимательства", "Инженерный факультет"],
    "Брянский государственный аграрный университет (Брянский ГАУ)": [
        "Институт энергетики и природопользования",
        "Институт экономики и агробизнеса",
        "Институт ветеринарной медицины и биотехнологии",
        "Инженерно-технологический институт",
    ],
    "Ульяновский государственный университет (УлГУ)": [
        "Институт международных отношений",
        "Институт открытого образования",
        "Факультет математики, информационных и авиационных технологий",
        "Заволжский экономико-гуманитарный факультет",
        "Инженерно-физический факультет высоких технологий",
        "Институт экономики и бизнеса",
        "Факультет культуры и искусства",
        "Институт медицины, экологии и физической культуры",
        "Факультет трансферных специальностей",
        "Юридический факультет",
        "Факультет гуманитарных наук и социальных технологий",
    ],
    "Чебоксарский кооперативный институт (филиал) Российского университета кооперации (ЧКИ РУК)": ["Юридический факультет", "Факультет экономики и управления"],
    "Тверская государственная сельскохозяйственная академия (Тверская ГСХА)": ["Инженерный факультет", "Технологический факультет", "Экономический факультет"],
    "Сургутский государственный педагогический университет (СурГПУ)": [
        "Факультет управления",
        "Социально-гуманитарный факультет",
        "Факультет физической культуры и спорта",
        "Факультет психологии и педагогики",
        "Факультет социально-культурных коммуникаций",
        "Филологический факультет",
    ],
    "Северный государственный медицинский университет (СГМУ)": [
        "Факультет медико-профилактического дела и медицинской биохимии",
        "Факультет экономики и управления",
        "Лечебный факультет",
        "Факультет клинической психологии, социальной работы и адаптивной физической культуры",
        "Педиатрический факультет",
        "Факультет сестринского образования",
        "Стоматологический факультет",
        "Фармацевтический факультет",
    ],
    "Северо-Восточный федеральный университет имени М.К. Аммосова (СВФУ им. М.К. Аммосова)": [
        "Институт естественных наук",
        "Институт физической культуры и спорта",
        "Медицинский институт",
        "Институт зарубежной филологии и регионоведения",
        "Институт языков и культуры народов Северо-Востока Российской Федерации",
        "Педагогический институт",
        "Институт математики и информатики",
        "Филологический факультет",
        "Институт психологии",
        "Физико-технический институт",
        "Автодорожный факультет",
        "Горный институт",
        "Инженерно-технический институт",
        "Финансово-экономический институт",
        "Геологоразведочный факультет",
        "Исторический факультет",
        "Юридический факультет",
    ],
    "Астраханский государственный технический университет (АГТУ)": [
        "Институт информационных технологий и коммуникаций",
        "Институт градостроительства",
        "Институт рыбного хозяйства, биологии и природопользования",
        "Институт экономики",
        "Институт морских технологий, энергетики и транспорта",
        "Институт нефти и газа",
    ],
    "Забайкальский институт предпринимательства - филиал Сибирского университета потребительской кооперации (Забайкальский институт предпринимательства - филиал СибУПК)": [
        "Факультет технологии и управления",
        "Юридический факультет",
    ],
    "Орловский государственный университет имени И.С. Тургенева (ОГУ им. И.С. Тургенева)": [
        "Юридический институт",
        "Факультет физической культуры и спорта",
        "Философский факультет",
        "Институт заочного и очно-заочного образования",
        "Институт экономики и управления",
        "Физико-математический факультет",
        "Художественно-графический факультет",
        "Институт педагогики и психологии",
        "Институт естественных наук и биотехнологии",
        "Архитектурно-строительный институт",
        "Институт иностранных языков",
        "Факультет технологии, предпринимательства и сервиса",
        "Институт приборостроения, автоматизации и информационных технологий",
        "Институт филологии",
        "Социальный факультет",
        "Политехнический институт имени Н.Н. Поликарпова",
        "Медицинский институт",
        "Исторический факультет",
    ],
    "Сыктывкарский государственный университет имени Питирима Сорокина (СГУ им. Питирима Сорокина)": [
        "Медицинский институт",
        "Юридический институт",
        "Институт гуманитарных наук",
        "Институт менеджмента и предпринимательства",
        "Институт естественных наук",
        "Институт педагогики и психологии",
        "Институт иностранных языков",
        "Институт социальных технологий",
        "Институт истории и права",
        "Институт точных наук и информационных технологий",
        "Институт культуры и искусства",
        "Институт экономики и финансов",
    ],
    "Академия права и управления Федеральной службы исполнения наказаний (Академия ФСИН России)": ["Психологический факультет", "Экономический факультет", "Юридический факультет"],
    "Ивановский государственный политехнический университет (ИВГПУ)": [
        "Институт архитектуры, строительства и транспорта",
        "Институт текстильной индустрии и моды",
        "Институт информационных технологий, естественных и гуманитарных наук",
    ],
    "Калужский филиал Финансового университета при Правительстве Российской Федерации (Калужский филиал Финуниверситета)": ["Факультет экономики и бизнес-технологий"],
    "Костромской государственный университет (КГУ)": [
        "Институт педагогики и психологии",
        "Институт управления, экономики и финансов",
        "Институт автоматизированных систем и технологий",
        "Институт физико-математических и естественных наук",
        "Институт гуманитарных наук и социальных технологий",
        "Юридический институт имени Ю.П. Новицкого",
        "Институт дизайна и технологий",
        "Институт культуры и искусств",
    ],
    "Московский психолого-социальный университет (МПСУ)": ["Факультет психологии", "Факультет экономики и права"],
    "Православный Свято-Тихоновский гуманитарный университет (ПСТГУ)": [
        "Факультет церковных художеств",
        "Факультет церковного пения",
        "Историко-филологический факультет",
        "Факультет информатики и прикладной математики",
        "Богословский факультет",
    ],
    "Российский государственный аграрный заочный университет (РГАЗУ)": [
        "Факультет электроэнергетики и технического сервиса",
        "Факультет агро- и биотехнологий",
        "Институт экономики и управления в АПК",
    ],
    "Смоленская государственная сельскохозяйственная академия (Смоленская ГСХА)": [
        "Экономический факультет",
        "Инженерно-технологический факультет",
        "Факультет технологий животноводства и ветеринарной медицины",
    ],
    "Санкт-Петербургский медико-социальный институт (СПбМСИ)": ["Медицинский факультет"],
    "Вологодская государственная молочнохозяйственная академия имени Н.В. Верещагина (Вологодская ГМХА)": [
        "Факультет агрономии и лесного хозяйства",
        "Факультет ветеринарной медицины и биотехнологий",
        "Инженерный факультет",
        "Технологический факультет",
        "Экономический факультет",
    ],
    "Лужский институт (филиал) Ленинградского государственного университета имени А.С. Пушкина (Лужский институт ЛГУ им. А.С. Пушкина)": [
        "Биотехнологический факультет",
        "Экономический факультет",
        "Юридический факультет",
        "Межфакультетская кафедра гуманитарных и естественнонаучных дисциплин",
    ],
    "Северный институт (филиал) Всероссийского государственного университета юстиции (РПА Минюста России) в г. Петрозаводске (Северный институт ВГУЮ РПА)": ["Юридический факультет"],
    "Майкопский государственный технологический университет (МГТУ)": [
        "Факультет аграрных технологий",
        "Экологический факультет",
        "Инженерный факультет",
        "Факультет экономики и управления",
        "Факультет информационных систем в экономике и юриспруденции",
        "Технологический факультет",
        "Медицинский институт",
    ],
    "Армавирский государственный педагогический университет (АГПУ)": [
        "Факультет технологии, экономики и дизайна",
        "Институт прикладной информатики, математики и физики",
        "Институт русской и иностранной филологии",
        "Факультет дошкольного и начального образования",
        "Исторический факультет",
        "Социально-психологический факультет",
    ],
    "Южный институт менеджмента (ЮИМ)": ["Факультет экономики и управления", "Юридический факультет", "Высшая школа международного бизнеса"],
    "Камышинский технологический институт (филиал) Волгоградского государственного технического университета (КТИ (филиал) ВолгГТУ)": ["Факультет «Высшее образование»"],
    "Институт водного транспорта имени Г.Я. Седова - филиал Государственного морского университета имени адмирала Ф.Ф. Ушакова (ИВТ им. Г.Я. Седова ГМУ им. адм. Ф.Ф. Ушакова)": [
        "Факультет инженеров морского транспорта",
    ],
    "Южно-Российский государственный политехнический университет (НПИ) имени М.И. Платова (ЮРГПУ (НПИ) им. М.И. Платова)": [
        "Факультет инноватики и организации производства",
        "Институт фундаментального инженерного образования",
        "Механический факультет",
        "Строительный факультет",
        "Технологический факультет",
        "Энергетический факультет",
        "Факультет информационных технологий и управления",
        "Факультет геологии, горного и нефтегазового дела",
        "Кафедра информационной безопасности",
    ],
    "Институт сферы обслуживания и предпринимательства (филиал) Донского государственного технического университета в г. Шахты (ИСОиП (филиал) ДГТУ в г. Шахты)": [
        "Факультет экономики, сервиса и предпринимательства",
        "Факультет юриспруденции, социальных технологий и психологии",
        "Факультет техники и технологии",
    ],
    "Таганрогский институт имени А.П. Чехова (филиал) Ростовского государственного экономического университета (РИНХ) (Таганрогский институт имени А.П. Чехова (филиал) РГЭУ (РИНХ))": [
        "Факультет иностранных языков",
        "Факультет истории и филологии",
        "Факультет психологии и социальной педагогики",
        "Факультет педагогики и методики дошкольного, начального и дополнительного образования",
        "Факультет экономики и права",
        "Факультет физики, математики, информатики",
    ],
    "Горский государственный аграрный университет (Горский ГАУ)": [
        "Факультет биотехнологии и стандартизации",
        "Факультет ветеринарной медицины и ветеринарно-санитарной экспертизы",
        "Инженерный факультет",
        "Факультет технологического менеджмента",
        "Факультет экономики и менеджмента",
        "Агрономический факультет",
        "Юридический факультет",
    ],
    "Карачаево-Черкесский государственный университет имени У.Д. Алиева (КЧГУ им. У.Д. Алиева)": [
        "Факультет физической культуры",
        "Естественно-географический факультет",
        "Физико-математический факультет",
        "Исторический факультет",
        "Институт филологии",
        "Педагогический факультет",
        "Институт культуры и искусств",
        "Факультет психологии и социальной работы",
        "Факультет экономики и управления",
    ],
    "Пятигорский государственный университет (ПГУ)": [
        "Высшая школа дизайна и архитектуры",
        "Юридический институт",
        "Институт иностранных языков и международного туризма",
        "Институт заочного обучения, информационных технологий и онлайн-проектов",
        "Институт международных отношений",
        "Институт переводоведения, русистики и многоязычия",
        "Высшая школа управления",
        "Институт романо-германских языков, информационных и гуманитарных технологий",
    ],
    "Филиал Ставропольского государственного педагогического института в г. Ессентуки (Филиал СГПИ в г. Ессентуки)": [
        "Гуманитарно-технический факультет",
        "Психолого-педагогический факультет",
        "Факультет заочной формы обучения",
    ],
    "Башкирский институт социальных технологий (филиал) Академии труда и социальных отношений (БИСТ (филиал) АТиСО)": ["Финансово-экономический факультет", "Юридический факультет"],
    "Стерлитамакский филиал Уфимского университета науки и технологий (Стерлитамакский филиал УУНиТ)": [
        "Факультет башкирской и тюркской филологии",
        "Юридический факультет",
        "Исторический факультет",
        "Факультет математики и информационных технологий",
        "Естественнонаучный факультет",
        "Факультет педагогики и психологии",
        "Филологический факультет",
        "Экономический факультет",
    ],
    "Дзержинский политехнический институт (филиал) Нижегородского государственного технического университета имени Р.Е. Алексеева (ДПИ НГТУ)": ["Инженерно-технологический факультет"],
    "Самарский государственный аграрный университет (Самарский ГАУ)": [
        "Агрономический факультет",
        "Инженерный факультет",
        "Факультет биотехнологии и ветеринарной медицины",
        "Экономический факультет",
        "Технологический факультет",
    ],
    "Балашовский институт (филиал) Саратовского национального исследовательского государственного университета имени Н.Г. Чернышевского (БИ СГУ)": [
        "Факультет математики и естественных наук",
        "Филологический факультет",
        "Психолого-педагогический факультет",
    ],
    "Институт деловой карьеры (Институт деловой карьеры)": [],
    "Институт общественных наук РАНХиГС (РАНХиГС)": [],
    "Московский институт технологий и управления (МИТУ)": [],
    "Московский областной филиал Санкт-Петербургского Гуманитарного университета профсоюзов - Институт искусств и информационных технологий (Московский областной филиал СПбГУП ИИиИТ)": [],
    "Институт права и национальной безопасности РАНХиГС (РАНХиГС)": [],
    "Инженерно-экономический институт НИУ МЭИ (НИУ МЭИ)": [],
    "Факультет финансов и банковского дела РАНХиГС (РАНХиГС)": [],
    "Московский городской университет управления Правительства Москвы имени Ю.М. Лужкова (МГУУ Правительства Москвы)": [],
    "Московский финансово-юридический университет (МФЮА)": [],
    "Институт современного искусства (ИСИ)": [],
    "Факультет гуманитарного образования НГТУ (НГТУ)": [],
    "Высшая школа современных социальных наук МГУ им. Ломоносова (МГУ им. Ломоносова)": [],
    "Волго-Вятский филиал Московского технического университета связи и информатики (ВВФ МТУСИ)": [],
    "Московский технологический институт (МТИ)": [],
    "Институт iSpring (Институт iSpring)": [],
    "Дальневосточный институт управления - филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Дальневосточный институт управления - филиал РАНХиГС)":
        [],
    "Филиал Самарского государственного университета путей сообщения в Нижнем Новгороде (Филиал СамГУПС в Нижнем Новгороде)": [],
    "Краснодарский филиал Финансового университета при Правительстве Российской Федерации (Краснодарский филиал Финуниверситета)": [],
    "Московский художественно-промышленный институт (МХПИ)": [],
    "Сибирский казачий институт технологий и управления (филиал) Московского государственного университета технологий и управления имени К.Г. Разумовского (Первого казачьего университета) (СКИТУ (филиал) МГУТУ им. К.Г. Разумовского (ПКУ))":
        [],
    "Международный институт дизайна и сервиса (МИДиС)": [],
    "Воронежский филиал Государственного университета морского и речного флота имени адмирала С. О. Макарова (Воронежский филиал ГУМРФ имени адмирала С. О. Макарова)": [],
    "Воронежский институт высоких технологий (ВИВТ)": [],
    "Новосибирская государственная консерватория имени М. И. Глинки (Новосибирская государственная консерватория имени М. И. Глинки)": [],
    "Тамбовский филиал Российского нового университета (Тамбовский филиал РосНОУ)": [],
    "Алтайский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Алтайский филиал РАНХиГС)": [],
    "Оренбургский институт путей сообщения - филиал Самарского государственного университета путей сообщения (ОрИПС - филиал СамГУПС)": [],
    "Тульский филиал Международного юридического института (Тульский филиал Международного юридического института)": [],
    "Филиал Тюменского индустриального университета в г. Сургуте (Филиал ТИУ в г. Сургуте)": [],
    "Северный институт предпринимательства (Северный институт предпринимательства)": [],
    "Филиал Самарского государственного технического университета в г. Новокуйбышевске (Филиал СамГТУ в г. Новокуйбышевске)": [],
    "Астраханский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Астраханский филиал РАНХиГС)": [],
    "Набережночелнинский институт (филиал) Казанского (Приволжского) федерального университета (Набережночелнинский институт КФУ)": [],
    "Набережночелнинский филиал Университета управления «ТИСБИ» (Набережночелнинский филиал Университета управления «ТИСБИ»)": [],
    "Карачевский филиал Орловского государственного университета имени И.С. Тургенева (Карачевский филиал ОГУ им. И.С. Тургенева)": [],
    "Курская академия государственной и муниципальной службы (Академия госслужбы)": [],
    "Курский филиал Финансового университета при Правительстве Российской Федерации (Курский филиал Финуниверситета)": [],
    "Московский государственный институт музыки имени А.Г. Шнитке (МГИМ им. А.Г. Шнитке)": [],
    "Московский региональный социально-экономический институт (МРСЭИ)": [],
    "Смоленский филиал Международного юридического института (СФ МЮИ)": [],
    "Современный технический университет (СТУ)": [],
    "Тульский институт (филиал) Всероссийского государственного университета юстиции (РПА Минюста России) (Тульский филиал ВГУЮ (РПА Минюста России))": [],
    "Филиал Российского государственного университета им. А.Н. Косыгина (Технологии. Дизайн. Искусство) в г. Твери (Филиал РГУ им. А.Н. Косыгина в г. Твери)": [],
    "Северо-Западный институт (филиал) Московского государственного юридического университета имени О.Е. Кутафина (МГЮА) (Северо-Западный институт Университета им. О.Е. Кутафина МГЮА)": [],
    "Мурманская академия экономики и управления (МАЭУ)": [],
    "Воркутинский филиал Ухтинского государственного технического университета (ВФ УГТУ)": [],
    "Филиал Российского государственного гидрометеорологического университета в г. Туапсе Краснодарского края (Филиал РГГМУ в г. Туапсе)": [],
    "Филиал Ростовского государственного университета путей сообщения в г. Туапсе (Филиал РГУПС в г. Туапсе)": [],
    "Сочинский филиал Всероссийского государственного университета юстиции (РПА Минюста России) (Сочинский филиал ВГУЮ (РПА Минюста России))": [],
    "Новороссийский институт (филиал) Московского гуманитарно-экономического университета (НИ (Ф) МГЭУ)": [],
    "Волжский филиал Волгоградского государственного университета (ВФ ВолГУ)": [],
    "Себряковский филиал Волгоградского государственного технического университета (Себряковский филиал ВолгГТУ)": [],
    "Филиал Южного федерального университета в г. Новошахтинске (НШФ ЮФУ)": [],
    "Институт экономики и права (филиал) Академии труда и социальных отношений в г. Севастополе (ИЭиП (филиал) АТиСО в г. Севастополе)": [],
    "Филиал Дагестанского государственного технического университета в г. Кизляре (Филиал ДГТУ в г. Кизляре)": [],
    "Северо-Кавказский филиал Белгородского государственного технологического университета им. В.Г. Шухова (СКФ БГТУ им. В.Г. Шухова)": [],
    "Ессентукский институт управления, бизнеса и права (ЕИУБП)": [],
    "Филиал Самарского государственного технического университета в г. Белебее (Филиал СамГТУ в г. Белебее)": [],
    "Башкирский институт технологий и управления (филиал) Московского государственного университета технологий и управления имени К.Г. Разумовского (Первого казачьего университета) (БИТУ (филиал) МГУТУ им. К.Г. Разумовского (ПКУ))":
        [],
    "Институт химических технологий и инжиниринга Уфимского государственного нефтяного технического университета (филиал в г. Стерлитамаке) (ИХТИ УГНТУ в г. Стерлитамаке)": [],
    "Лениногорский филиал Казанского национального исследовательского технического университета им. А.Н. Туполева–КАИ (Лениногорский филиал КНИТУ–КАИ)": [],
    "Сарапульский политехнический институт (филиал) Ижевского государственного технического университета имени М.Т. Калашникова (СПИ (филиал) ИжГТУ им. М.Т. Калашникова)": [],
    "Уральский филиал Российской академии живописи, ваяния и зодчества Ильи Глазунова (Уральский филиал РАЖВиЗ)": [],
    "Волго-Вятский институт (филиал) Московского государственного юридического университета имени О.Е. Кутафина (МГЮА) (Волго-Вятский институт (филиал) Университета имени О.Е. Кутафина МГЮА)": [],
    "Павловский филиал Национального исследовательского Нижегородского государственного университета имени Н.И. Лобачевского (Павловский филиал ННГУ)": [],
    "Филиал Самарского государственного технического университета в г. Сызрани (Филиал СамГТУ в г. Сызрани)": [],
    "Саратовская православная духовная семинария Саратовской Епархии Русской Православной Церкви (Саратовская православная духовная семинария)": [],
    "Энгельсский технологический институт (филиал) Саратовского государственного технического университета имени Гагарина Ю.А. (ЭТИ (филиал) СГТУ им. Гагарина Ю.А.)": [],
    "Технологический институт - филиал Национального исследовательского ядерного университета «МИФИ» в г. Лесном (ТИ НИЯУ МИФИ)": [],
    "Филиал Южно-Уральского государственного университета (национального исследовательского университета) в г. Нижневартовске (Филиал ЮУрГУ (НИУ) в г. Нижневартовске)": [],
    "Трехгорный технологический институт - филиал Национального исследовательского ядерного университета «МИФИ» (ТТИ НИЯУ МИФИ)": [],
    "Саяно-Шушенский филиал Сибирского федерального университета (Саяно-Шушенский филиал СФУ)": [],
    "Филиал Кузбасского государственного технического университета имени Т.Ф. Горбачева в г. Прокопьевске (Филиал КузГТУ в г. Прокопьевске)": [],
    "Технический институт (филиал) Северо-Восточного федерального университета имени М.К. Аммосова в г. Нерюнгри (ТИ (ф) СВФУ)": [],
    "Приморский институт железнодорожного транспорта – филиал Дальневосточного государственного университета путей сообщения в г. Уссурийске (ПримИЖТ – филиал ДВГУПС в г. Уссурийске)": [],
    "Домодедовский филиал Российского нового университета (Домодедовский филиал РосНОУ)": [],
    "Филиал МИРЭА - Российского технологического университета в г. Фрязино (Филиал РТУ МИРЭА в г. Фрязино)": [],
    'Филиал Университета "Дубна" - Дмитровский институт непрерывного образования (Филиал Университета Дубна - ДИНО)': [],
    "Красноярский филиал Санкт-Петербургского Гуманитарного университета профсоюзов (Красноярский филиал СПбГУП)": [],
    "Московская духовная академия Русской Православной Церкви (Московская духовная академия РПЦ)": [],
    "Волховский филиал Российского государственного педагогического университета им. А. И. Герцена (Волховский филиал РГПУ им. А. И. Герцена)": [],
    "Курганский институт железнодорожного транспорта - филиал Уральского государственного университета путей сообщения в г. Кургане (КИЖТ УрГУПС)": [],
    "Иркутский институт (филиал) Всероссийского государственного университета юстиции (РПА Минюста России) (Иркутский институт (филиал) ВГУЮ (РПА Минюста России))": [],
    "Пермский институт железнодорожного транспорта - филиал Уральского государственного университета путей сообщения (ПИЖТ УрГУПС)": [],
    "Омский филиал Финансового университета при Правительстве Российской Федерации (Омский филиал Финуниверситета)": [],
    "Южно-Уральский технологический университет (ЮУТУ)": [],
    "Ижевский филиал Российского университета кооперации (Ижевский филиал РУК)": [],
    "Алтайский институт экономики – филиал Санкт-Петербургского университета технологий управления и экономики (Алтайский институт экономики СПбУТУиЭ)": [],
    "Оренбургский филиал Российского экономического университета имени Г.В. Плеханова (Оренбургский филиал РЭУ им. Г.В. Плеханова)": [],
    "Калининградский институт управления (КИУ)": [],
    "Брянский институт управления и бизнеса (БИУБ)": [],
    "Оренбургский институт (филиал) Московского государственного юридического университета имени О.Е. Кутафина (МГЮА) (Оренбургский институт (филиал) Университета имени О.Е. Кутафина (МГЮА))": [],
    "Тверской филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Тверской филиал РАНХиГС)": [],
    "Калужский филиал Российской академии народного хозяйства и государственной службы при Президенте РФ (Калужский филиал РАНХиГС)": [],
    "Институт мировой экономики и финансов (ИМЭФ)": [],
    "Набережночелнинский филиал Казанского инновационного университета имени В.Г. Тимирясова (ИЭУП) (Набережночелнинский филиал КИУ (ИЭУП))": [],
    "Елецкий филиал Российского нового университета (Елецкий филиал РосНОУ)": [],
    "Ливенский филиал Орловского государственного университета имени И.С. Тургенева (Ливенский филиал ОГУ им. И.С. Тургенева)": [],
    "Институт традиционного прикладного искусства - Московский филиал Высшей школы народных искусств (академии) (ИТПИ ВШНИ)": [],
    "Обнинский институт атомной энергетики - филиал Национального исследовательского ядерного университета «МИФИ» (ИАТЭ НИЯУ МИФИ)": [],
    "Региональный открытый социальный институт (РОСИ)": [],
    "Филиал Национального исследовательского университета «МЭИ» в г. Смоленске (Смоленский филиал МЭИ)": [],
    "Тульский институт управления и бизнеса имени Н. Д. Демидова (ТИУБ им. Н.Д. Демидова)": [],
    "Филиал Ухтинского государственного технического университета в г. Усинске (УФ УГТУ)": [],
    "Филиал Южного федерального университета в г. Геленджике (Филиал ЮФУ в г. Геленджике)": [],
    "Армавирский механико-технологический институт (филиал) Кубанского государственного технологического университета (АМТИ КубГТУ)": [],
    "Астраханская государственная консерватория (АГК)": [],
    "Филиал Национального исследовательского университета «МЭИ» в г. Волжском (Волжский филиал НИУ МЭИ)": [],
    "Ростовский институт защиты предпринимателя (РИЗП)": [],
    "Таганрогский филиал Российского нового университета (Таганрогский филиал РосНОУ)": [],
    "Филиал Дагестанского государственного технического университета в г. Дербенте (Филиал ДГТУ в г. Дербенте)": [],
    "Филиал Дагестанского государственного университета в г. Кизляре (Филиал ДГУ в г. Кизляре)": [],
    "Филиал Российского экономического университета имени Г.В. Плеханова в г. Пятигорске (Филиал РЭУ им. Г.В. Плеханова в г. Пятигорске)": [],
    "Кисловодский филиал Ростовского государственного экономического университета (РИНХ) (Кисловодский филиал РГЭУ (РИНХ))": [],
    "Филиал Магнитогорского государственного технического университета им. Г.И. Носова в г. Белорецке (Филиал МГТУ им. Г.И. Носова в г. Белорецке)": [],
    "Бугульминский филиал Казанского национального исследовательского технологического университета (БФ КНИТУ)": [],
    "Чистопольский филиал «Восток» Казанского национального исследовательского технического университета им. А.Н. Туполева–КАИ (Чистопольский филиал «Восток» КНИТУ-КАИ)": [],
    "Пензенский филиал Финансового университета при Правительстве Российской Федерации (Пензенский филиал Финуниверситета)": [],
    "Екатеринбургская духовная семинария Екатеринбургской Епархии Русской Православной Церкви (Екатеринбургская духовная семинария)": [],
    "Филиал Тюменского индустриального университета в городе Нижневартовске (Филиал ТИУ в г. Нижневартовске)": [],
    'Снежинский физико-технический институт - филиал Национального исследовательского ядерного университета "МИФИ" (СФТИ НИЯУ МИФИ)': [],
    "Рубцовский институт (филиал) Алтайского государственного университета (Рубцовский институт АлтГУ)": [],
    "Лесосибирский филиал Сибирского государственного университета науки и технологий имени академика М.Ф. Решетнева (ЛФ СибГУ им. М.Ф. Решетнева)": [],
    "Филиал Кузбасского государственного технического университета имени Т.Ф. Горбачева в г. Междуреченске (Филиал КузГТУ в г. Междуреченске)": [],
    "Томский сельскохозяйственный институт - филиал Новосибирского государственного аграрного университета (ТСХИ - филиал НГАУ)": [],
    "Институт законодательства и сравнительного правоведения при Правительстве Российской Федерации (ТСХИ - филиал НГАУ)": [],
    "Королёвский филиал Международного юридического института (Королёвский филиал МЮИ)": [],
    "Филиал Российского государственного социального университета в г. Клин (Филиал РГСУ в г. Клину)": [],
    "Дмитровский рыбохозяйственный технологический институт (филиал) Астраханского государственного технического университета (ДРТИ АГТУ)": [],
    "Санкт-Петербургский университет Федеральной службы исполнения наказаний (Университет ФСИН России)": [],
    "Филиал Санкт-Петербургского государственного экономического университета в г. Кизляре (Филиал СПбГЭУ в г. Кизляре)": [],
    "Филиал Московского педагогического государственного университета в г. Черняховске (Филиал МПГУ в г. Черняховске)": [],
    "Московский международный университет (ММУ)": [],
    "Выборгский филиал Российского государственного педагогического университета им. А. И. Герцена (Выборгский филиал РГПУ им. А. И. Герцена)": [],
    "Ярославский государственный театральный институт (ЯГТИ)": [],
    "Уфимский филиал Финансового университета при Правительстве Российской Федерации (Уфимский филиал Финуниверситета)": [],
    "Воронежский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Воронежский филиал РАНХиГС)": [],
    "Иркутский филиал Всероссийского государственного института кинематографии имени С.А. Герасимова (Иркутский филиал ВГИК)": [],
    "Ижевский институт (филиал) Всероссийского государственного университета юстиции (РПА Минюста России) (Ижевский институт (филиал) ВГУЮ (РПА Минюста России))": [],
    "Алтайский филиал Финансового университета при Правительстве Российской Федерации (Алтайский филиал Финуниверситета)": [],
    "Оренбургский филиал Поволжского государственного университета телекоммуникаций и информатики (Оренбургский филиал ПГУТИ)": [],
    "Оренбургский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Оренбургский филиал РАНХиГС)": [],
    "Тамбовский филиал Российской академии народного хозяйства при Правительстве Российской Федерации (Тамбовский филиал РАНХиГС)": [],
    "Тамбовский филиал Мичуринского государственного аграрного университета (Тамбовский филиал Мичуринского ГАУ)": [],
    "Институт стран Востока (Институт стран Востока)": [],
    "Забайкальский аграрный институт - филиал Иркутского государственного аграрного университета имени А.А. Ежевского (ЗабАИ - филиал Иркутского ГАУ)": [],
    "Филиал Кузбасского государственного технического университета имени Т.Ф. Горбачева в г. Новокузнецке (Филиал КузГТУ в г. Новокузнецке)": [],
    "Среднерусский институт управления - филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Среднерусский институт управления - филиал РАНХиГС)":
        [],
    "Губкинский филиал Белгородского государственного технологического университета им. В.Г. Шухова (Губкинский филиал БГТУ им. В.Г. Шухова)": [],
    "Западно-Подмосковный институт туризма - филиал Российской международной академии туризма (ЗПИТ - филиал РМАТ)": [],
    "Коломенский институт (филиал) Московского политехнического университета (Коломенский институт Политеха)": [],
    "Международная академия бизнеса и новых технологий (МУБиНТ) (Академия МУБиНТ)": [],
    "Мстерский институт лаковой миниатюрной живописи имени Ф.А. Модорова - филиал Высшей школы народных искусств (академии) (Мстерский филиал ВШНИ)": [],
    "Одинцовский филиал Международного юридического института (Одинцовский филиал МЮИ)": [],
    "Смоленский филиал Российского экономического университета имени Г.В. Плеханова (СФ РЭУ им. Г.В. Плеханова)": [],
    "Международная полицейская академия ВПА (МПА ВПА)": [],
    "Крымский университет культуры, искусств и туризма (КУКИиТ)": [],
    "Евпаторийский институт социальных наук (филиал) Крымского федерального университета имени В.И. Вернадского (ЕИСН (филиал) КФУ им. В.И. Вернадского)": [],
    "Филиал Сочинского государственного университета в г. Анапе (Филиал СГУ в г. Анапе)": [],
    "Новороссийский политехнический институт (филиал) Кубанского государственного технологического университета (НПИ КубГТУ)": [],
    "Международный инновационный университет (МИУ)": [],
    "Астраханский филиал Саратовской государственной юридической академии (Астраханский филиал СГЮА)": [],
    "Южно-Российский гуманитарный институт (ЮРГИ)": [],
    "Каменский технологический институт (филиал) Южно-Российского государственного политехнического университета (НПИ) имени М.И. Платова (КТИ ЮРГПУ (НПИ) им. М.И. Платова)": [],
    "Гуковский институт экономики и права (филиал) Ростовского государственного экономического университета (РИНХ) (ГИЭиП (филиал) РГЭУ (РИНХ))": [],
    "Филиал Дагестанского государственного университета в г. Дербенте (Филиал ДГУ в г. Дербенте)": [],
    "Филиал Дагестанского государственного университета в г. Хасавюрте (Филиал ДГУ в г. Хасавюрте)": [],
    "Филиал Уфимского государственного нефтяного технического университета в г. Октябрьском (Филиал УГНТУ в г. Октябрьском)": [],
    "Пермская государственная фармацевтическая академия (ПГФА)": [],
    "Димитровградский инженерно-технологический институт – филиал Национального исследовательского ядерного университета «МИФИ» (ДИТИ НИЯУ МИФИ)": [],
    "Нижнетагильский технологический институт (филиал) Уральского федерального университета имени первого Президента России Б.Н. Ельцина (НТИ (филиал) УрФУ)": [],
    "Уральский институт фондового рынка (УИФР)": [],
    "Миасский филиал Челябинского государственного университета (Миасский филиал ЧелГУ)": [],
    "Институт агроэкологии – филиал Южно-Уральского государственного аграрного университета (Институт агроэкологии – филиал Южно-Уральского ГАУ)": [],
    "Филиал Алтайского государственного университета в г. Белокурихе (Филиал АлтГУ в г. Белокурихе)": [],
    "Ачинский филиал Красноярского государственного аграрного университета (Ачинский филиал Красноярского ГАУ)": [],
    "Юргинский технологический институт (филиал) Национального исследовательского Томского политехнического университета (ЮТИ ТПУ)": [],
    "Филиал Омского государственного педагогического университета в г. Таре (Филиал ОмГПУ в г. Таре)": [],
    "Московский областной казачий институт технологий и управления (филиал) Московского государственного университета технологий и управления имени К.Г. Разумовского (Первый казачий университет) (МОКИТУ (филиал) МГУТУ им. К.Г.Разумовского (ПКУ))":
        [],
    "Ступинский филиал Московского авиационного института (национального исследовательского университета) (Ступинский филиал МАИ)": [],
    "Филиал Российского государственного социального университета в г. Павловский Посад (Филиал РГСУ в г. Павловский Посад)": [],
    "Выксунский филиал Университета науки и технологий МИСИС (Выксунский филиал НИТУ МИСИС)": [],
    "Дербентский филиал Московского педагогического государственного университета (Дербентский филиал МПГУ)": [],
    "Мурманский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Мурманский филиал РАНХиГС)": [],
    "Институт мировой экономики и бизнеса (Международная школа бизнеса) РУДН (РУДН)": [],
    "Филиал Московского государственного университета имени М.В. Ломоносова в Севастополе (Филиал МГУ в Севастополе)": [],
    "Санкт-Петербургский национальный исследовательский Академический университет имени Ж.И. Алфёрова Российской академии наук (Алферовский университет) (СПбАУ РАН им. Ж.И. Алфёрова)": [],
    "Ставропольский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Ставропольский филиал РАНХиГС)": [],
    "Институт морского и речного флота имени Героя Советского Союза М.П. Девятаева - Казанский филиал Волжского государственного университета водного транспорта (Институт морского и речного флота им. героя Советского Союза М.П. Девятаева - Казанский филиал ВГУВТ)":
        [],
    "Уральский филиал Финансового университета (Уральский филиал Финансового университета)": [],
    "Дальневосточный институт коммуникаций (ДВИК)": [],
    "Волгоградский филиал Московского финансово-юридического университета МФЮА (Волгоградский филиал МФЮА)": [],
    "Камский институт гуманитарных и инженерных технологий (КИГИТ)": [],
    "Тульский филиал Финансового университета при Правительстве Российской Федерации (Тульский филиал Финуниверситета)": [],
    "Липецкий филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Липецкий филиал РАНХиГС)": [],
    "Чебоксарский институт (филиал) Московского политехнического университета (Чебоксарский институт Московского Политеха)": [],
    "Брянский филиал Российского экономического университета имени Г.В. Плеханова (Брянский филиал РЭУ им. Г.В. Плеханова)": [],
    "Поволжский казачий институт управления и пищевых технологий (филиал) МГУТУ им. К. Г. Разумовского (ПКУ) (ПКИУПТ (филиал) МГУТУ им. К.Г. Разумовского (ПКУ))": [],
    "Забайкальский институт железнодорожного транспорта - филиал Иркутского государственного университета путей сообщения (ЗабИЖТ ИрГУПС)": [],
    "Владимирский филиал Финансового университета при Правительстве Российской Федерации (Владимирский филиал Финуниверситета)": [],
    "Орловский филиал Финансового университета при Правительстве Российской Федерации (Орловский филиал Финуниверситета)": [],
    "Владимирский филиал Российского университета кооперации (Владимирский филиал РУК)": [],
    "Воскресенский институт туризма - филиал Российской международной академии туризма (Воскресенский институт туризма - филиал РМАТ)": [],
    "Гуманитарно-социальный институт (ГСИ)": [],
    "Ивановский филиал Международного юридического института (Ивановский филиал МЮИ)": [],
    "Калужский институт (филиал) Всероссийского государственного университета юстиции (РПА Минюста России) (Калужский институт (филиал) ВГУЮ (РПА Минюста России))": [],
    "Свято-Филаретовский институт (СФИ)": [],
    "Смоленский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Смоленский филиал РАНХиГС)": [],
    "Выборгский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Выборгский филиал РАНХиГС)": [],
    "Новгородский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Новгородский филиал РАНХиГС)": [],
    "Филиал Северного (Арктического) федерального университета имени М.В. Ломоносова в г. Северодвинске (Филиал САФУ в г. Северодвинске)": [],
    "Филиал Кубанского государственного университета в г. Армавире (Филиал КубГУ в г. Армавире)": [],
    "Новороссийский филиал Пятигорского государственного университета (НФ ПГУ)": [],
    "Кубанский казачий государственный институт пищевой индустрии и бизнеса (филиал) Московского государственного университета технологий и управления имени К.Г. Разумовского (Первого казачьего университета) (ККГИПИБ МГУТУ им. К.Г. Разумовского (ПКУ))":
        [],
    "Армавирский гуманитарно-социальный институт (АГСИ)": [],
    "Филиал Астраханского государственного университета в г. Знаменске (Филиал АГУ в г. Знаменске)": [],
    "Филиал Московского университета имени С. Ю. Витте в г. Ростове-на-Дону (Филиал МУ им. С. Ю. Витте в г. Ростове-на-Дону)": [],
    "Каменский филиал Российского нового университета (Каменский филиал РосНОУ)": [],
    "Филиал Ростовского государственного экономического университета (РИНХ) в г. Миллерово Ростовской области (Филиал РГЭУ (РИНХ) в г. Миллерово)": [],
    "Дагестанский филиал Российского государственного педагогического университета имени А. И. Герцена (Дагестанский филиал РГПУ им. А. И. Герцена)": [],
    "Северо-Осетинская государственная медицинская академия (СОГМА)": [],
    "Владикавказский институт управления (ВИУ)": [],
    "Северо-Кавказский филиал Московского автомобильно-дорожного государственного технического университета (МАДИ) (СКФ МАДИ)": [],
    "Филиал Уфимского университета науки и технологий в г. Ишимбае (Филиал Уфимского университета в г. Ишимбае)": [],
    "Филиал Уфимского государственного нефтяного технического университета в г. Салавате (Филиал УГНТУ в г. Салавате)": [],
    "Нижнекамский филиал Казанского инновационного университета имени В.Г. Тимирясова (ИЭУП) (Нижнекамский филиал КИУ (ИЭУП))": [],
    "Новоуральский технологический институт - филиал Национального исследовательского ядерного университета «МИФИ» (НТИ НИЯУ МИФИ)": [],
    "Озерский технологический институт - филиал Национального исследовательского ядерного университета «МИФИ» (ОТИ НИЯУ МИФИ)": [],
    "Лесосибирский педагогический институт - филиал Сибирского федерального университета (ЛПИ - филиал СФУ)": [],
    "Новосибирский государственный театральный институт (НГТИ)": [],
    "Арктический государственный институт культуры и искусств (АГИКИ)": [],
    "Дальневосточный филиал Всероссийской академии внешней торговли Министерства экономического развития Российской Федерации (ДВФ ВАВТ Минэкономразвития России)": [],
    "Чукотский филиал Северо-Восточного федерального университета имени М.К. Аммосова (Чукотский филиал СВФУ)": [],
    "Филиал «Протвино» государственного университета «Дубна» (Филиал Протвино университета Дубна)": [],
    "Электростальский институт (филиал) Московского политехнического университета (Электростальский институт (филиал) Московского политеха)": [],
    "Филиал Уральского государственного университета путей сообщения в г. Тюмени (Филиал УрГУПС в г. Тюмени)": [],
    "Институт экономики и культуры (ИЭИК)": [],
    "Московская международная высшая школа бизнеса МИРБИС (Институт) (Институт МИРБИС)": [],
    "Российская экономическая школа (РЭШ)": [],
    "Экономический факультет РУДН (РУДН)": [],
    "Курганский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Курганский филиал РАНХиГС)": [],
    "Институт международного образования МПГУ (МПГУ)": [],
    "Пермский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Пермский филиал РАНХиГС)": [],
    "Пермский филиал Волжского государственного университета водного транспорта (Пермский филиал ВГУВТ)": [],
    "Ростовский-на-Дону филиал Всероссийского государственного института кинематографии им. С.А.Герасимова (Ростовский-на-Дону филиал ВГИК им. С.А.Герасимова)": [],
    "Ярославский филиал Финансового университета при Правительстве Российской Федерации (Ярославский филиал Финуниверситета)": [],
    "Екатеринбургский институт физической культуры (филиал) Уральского государственного университета физической культуры (Екатеринбургский институт физической культуры УралГуфк)": [],
    "Академия хорового искусства имени В. С. Попова (Академия хорового искусства им. В. С. Попова)": [],
    "Уральский социально-экономический институт (филиал) Академии труда и социальных отношений (УрСЭИ АТиСО)": [],
    "Саратовский филиал Самарского государственного университета путей сообщения (Саратовский филиал СамГУПС)": [],
    "Ставропольский филиал Московского педагогического государственного университета (Ставропольский филиал МПГУ)": [],
    "Филиал Российского университета спорта «ГЦОЛИФК» в г. Иркутске (Иркутский филиал РУС «ГЦОЛИФК»)": [],
    "Липецкий филиал Финансового университета при Правительстве Российской Федерации (Липецкий филиал Финуниверситета)": [],
    "Калининградский филиал Московского финансово-юридического университета МФЮА (Калининградский филиал МФЮА)": [],
    "Ульяновский филиал Российской академии народного хозяйства и государственной службы при Президенте РФ (РАНХиГС) (Ульяновский филиал РАНХиГС)": [],
    "Якутский институт водного транспорта (филиал) Сибирского государственного университета водного транспорта (ЯИВТ (филиал) СГУВТ)": [],
    "Филиал Воронежского экономико-правового института в г. Орел (Филиал ВЭПИ в г. Орел)": [],
    "Ивановский филиал Российского экономического университета им. Г.В. Плеханова (Ивановский филиал РЭУ им. Г.В. Плеханова)": [],
    "Мценский филиал Орловского государственного университета имени И.С. Тургенева (Мценский филиал ОГУ им. И.С. Тургенева)": [],
    "Покровский филиал Московского педагогического государственного университета (Покровский филиал МПГУ)": [],
    "Смоленский государственный университет спорта (СГУС)": [],
    "Смоленский институт экономики - филиал Санкт-Петербургского университета технологий управления и экономики (Смоленский институт экономики СПбУТУиЭ)": [],
    "Филиал Брянского государственного университета имени академика И.Г. Петровского в г. Новозыбков (Филиал БГУ в г. Новозыбков)": [],
    "Санкт-Петербургский институт экономики и управления (СПбИЭУ)": [],
    "Псковский филиал Академии права и управления Федеральной службы исполнения наказаний (Псковский филиал Академии ФСИН России)": [],
    "Бокситогорский институт (филиал) Ленинградского государственного университета имени А.С. Пушкина (Бокситогорский институт ЛГУ им. Пушкина)": [],
    "Карельский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Карельский филиал РАНХиГС)": [],
    "Институт педагогического образования и менеджмента (филиал) Крымского федерального университета имени В.И. Вернадского в г. Армянске (ИПОМ КФУ В.И. Вернадского)": [],
    "Новороссийский филиал Финансового университета при Правительстве Российской Федерации (Новороссийский филиал Финуниверситета)": [],
    "Филиал «Взлёт» Московского авиационного института (национального исследовательского университета) в г. Ахтубинске (Филиал Взлёт МАИ в г. Ахтубинске)": [],
    "Шахтинский автодорожный институт (филиал) Южно-Российского государственного политехнического университета (НПИ) имени М.И. Платова (ШАДИ ЮРГПУ (НПИ) им. М.И. Платова)": [],
    "Филиал Государственного морского университета имени адмирала Ф.Ф. Ушакова в г. Севастополе (Филиал ГМУ им. адм. Ф.Ф. Ушакова в г. Севастополе)": [],
    "Филиал Дагестанского государственного университета в г. Избербаше (Филиал ДГУ в г. Избербаше)": [],
    "Институт экономики и правоведения (г. Назрань) (ИЭиП)": [],
    "Филиал Уфимского университета науки и технологий в г. Кумертау (Филиал УУНиТ в г. Кумертау)": [],
    "Альметьевский филиал Казанского национального исследовательского технического университета им. А.Н. Туполева–КАИ (Альметьевский филиал КНИТУ-КАИ)": [],
    "Филиал Удмуртского государственного университета в городе Воткинске (Филиал УдГУ в г. Воткинске)": [],
    "Дзержинский филиал Национального исследовательского Нижегородского государственного университета имени Н.И. Лобачевского (Дзержинский филиал ННГУ им. Н.И. Лобачевского)": [],
    "Пензенский казачий институт технологий (филиал) Московского государственного университета технологий и управления им. К. Г. Разумовского (Первого казачьего университета) (ПКИТ (филиал) МГУТУ им. К. Г. Разумовского (ПКУ))":
        [],
    "Политехнический институт (филиал) Уральского федерального университета имени первого Президента России Б.Н. Ельцина в г. Каменске-Уральском (КПИ (филиал) УрФУ)": [],
    "Ишимский педагогический институт имени П.П. Ершова (филиал) Тюменского государственного университета (ИПИ им. П.П. Ершова (филиал) ТюмГУ)": [],
    "Троицкий филиал Челябинского государственного университета (Троицкий филиал ЧелГУ)": [],
    "Филиал Алтайского государственного университета в г. Бийске (Филиал АлтГУ в г. Бийске)": [],
    "Филиал Кузбасского государственного технического университета имени Т.Ф. Горбачева в г. Белово (Филиал КузГТУ им. Т.Ф. Горбачева в г. Белово)": [],
    "Октемский филиал Арктического государственного агротехнологического университета (Октемский филиал АГАТУ)": [],
    "Петропавловск-Камчатский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Петропавловский филиал РАНХиГС)": [],
    "Филиал «Стрела» Московского авиационного института (национального исследовательского университета) в г. Жуковском (Филиал Стрела МАИ в г. Жуковском)": [],
    "Филиал Уральского государственного университета путей сообщения в г. Нижнем Тагиле (Филиал УрГУПС в г. Нижний Тагил)": [],
    "Вологодский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Вологодский филиал РАНХиГС)": [],
    "Институт ядерной энергетики (филиал) Санкт-Петербургского политехнического университета Петра Великого в г. Сосновый Бор (ИЯЭ СПбПУ)": [],
    "Ростовский филиал Московского государственного технического университета гражданской авиации (Ростовский филиал МГТУ ГА)": [],
    "Тывинский филиал Сибирского университета потребительской кооперации (Тывинский филиал СибУПК)": [],
    "Калининградский филиал Российского университета кооперации (Калининградский филиал РУК)": [],
    "Московская государственная академия хореографии (МГАХ)": [],
    "Тульский филиал Российского экономического университета имени Г. В. Плеханова (Тульский филиал РЭУ им. Г. В. Плеханова)": [],
    "Рязанский институт (филиал) Московского политехнического университета (Рязанский институт (филиал) Московского политеха)": [],
    "Институт медицинского образования Национального медицинского исследовательского центра имени В. А. Алмазова (Институт медицинского образования НМИЦ им. В. А. Алмазова)": [],
    "Липецкий казачий институт технологий и управления (филиал) Московского государственного университета технологий и управления имени К. Г. Разумовского (Первого казачьего университета) (ЛКИТиУ (филиал) МГУТУ им. К. Г. Разумовского (ПКУ))":
        [],
    "Чувашский государственный институт культуры и искусств (ЧГИКИ)": [],
    "Филиал Российского государственного университета туризма и сервиса в г. Махачкале (Филиал РГУТИС в Махачкале)": [],
    "Филиал Московского университета имени С.Ю. Витте в г. Рязани (Филиал Московского университета им. С.Ю. Витте в г. Рязани)": [],
    "Ярославский филиал Петербургского государственного университета путей сообщения Императора Александра I (Ярославский филиал ПГУПС)": [],
    "Владимирский филиал Российской академии народного хозяйства и государственной службы при Президенте Российской Федерации (Владимирский филиал РАНХиГС)": [],
    "Набережночелнинский филиал Казанского национального исследовательского технического университета им. А.Н. Туполева-КАИ (Набережночелнинский филиал КНИТУ-КАИ)": [],
    "Высшее театральное училище (институт) им. М.С.Щепкина при Государственном академическом Малом театре России (ВТУ им. М.С. Щепкина)": [],
    "Егорьевский технологический институт (филиал) Московского государственного технологического университета «СТАНКИН» (ЕТИ Станкин)": [],
    "Ивановский филиал Российской Академии народного хозяйства и государственной службы при президенте РФ (Ивановский филиал РАНХиГС)": [],
    "Московская государственная академия физической культуры (МГАФК)": [],
    "Национальный институт дизайна (НИД)": [],
    "Смоленский казачий институт промышленных технологий и бизнеса (филиал) Московского государственного университета технологий и управления имени К.Г. Разумовского (СКИПТБ МГУТУ)": [],
    "Смоленский филиал Финансового университета при Правительстве Российской Федерации (Смоленский филиал Финуниверситета)": [],
    "Ярославский филиал Московского финансово-юридического университета МФЮА (Ярославский филиал МФЮА)": [],
    "Псковский филиал Российской международной академии туризма (Псковский филиал РМАТ)": [],
    "Филиал Мурманского арктического государственного университета в г. Апатиты (Филиал МАГУ в г. Апатиты)": [],
    "Крымский юридический институт (филиал) Университета прокуратуры Российской Федерации (КЮИ (ф) УП РФ)": [],
    "Филиал Кубанского государственного университета в г. Новороссийске (Филиал КубГУ в г. Новороссийске)": [],
    "Филиал Ростовского государственного экономического университета (РИНХ) в г. Ейске Краснодарского края (Филиал РГЭУ (РИНХ) в г. Ейске Краснодарского края)": [],
    "Анапский филиал Московского педагогического государственного университета (Анапский филиал МПГУ)": [],
    "Волжский институт экономики, педагогики и права (ВИЭПП)": [],
    "Севастопольский экономико-гуманитарный институт (филиал) Крымского федерального университета имени В.И. Вернадского (СЭГИ КФУ)": [],
    "Филиал Дагестанского государственного технического университета в г. Каспийске (Филиал ДГТУ в г. Каспийске)": [],
    "Невинномысский технологический институт (филиал) Северо-Кавказского федерального университета (НТИ СКФУ)": [],
    "Кумертауский филиал Оренбургского государственного университета (Кумертауский филиал ОГУ)": [],
    "Рузаевский институт машиностроения (филиал) Национального исследовательского Мордовского государственного университета имени Н.П. Огарёва (Рузаевский филиал НИ МГУ им. Н.П. Огарева)": [],
    "Глазовский инженерно-экономический институт (филиал) Ижевского государственного технического университета имени М.Т. Калашникова (ГИЭИ ИжГТУ им. М.Т. Калашникова)": [],
    "Соликамский государственный педагогический институт (филиал) Пермского государственного национального исследовательского университета (СГПИ филиал ПГНИУ)": [],
    "Нижегородская духовная семинария Нижегородской Епархии Русской Православной Церкви (Московский Патриархат) (Нижегородская духовная семинария)": [],
    "Пензенская духовная семинария Пензенской Епархии Русской Православной Церкви (Пензенская духовная семинария Пензенской Епархии РПЦ)": [],
    "Смоленский государственный институт искусств (СГИИ)": ["Факультет культуры и искусств, дополнительного профессионального образования"],
};

export default universitiesWithFaculties;
