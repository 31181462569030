export const ClassPlaceIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.8741 12.947C15.2885 12.1088 15.5 11.3635 15.5 10.75C15.5 9.19383 15.042 8.31943 14.5108 7.8224C13.96 7.30718 13.1192 7 12 7C10.8808 7 10.04 7.30718 9.48924 7.8224C8.95797 8.31943 8.5 9.19383 8.5 10.75C8.5 11.3635 8.71153 12.1088 9.12591 12.947C9.53418 13.7729 10.0944 14.5989 10.6811 15.3483C11.1428 15.938 11.607 16.4625 12 16.8814C12.393 16.4625 12.8572 15.938 13.3189 15.3483C13.9056 14.5989 14.4658 13.7729 14.8741 12.947ZM12 12C12.9665 12 13.75 11.2165 13.75 10.25C13.75 9.2835 12.9665 8.5 12 8.5C11.0335 8.5 10.25 9.2835 10.25 10.25C10.25 11.2165 11.0335 12 12 12Z"
                fill="#4E4E4E"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 19C12 19 17 14.4779 17 10.75C17 7.02208 14.7614 5.5 12 5.5C9.23858 5.5 7 7.02208 7 10.75C7 14.4779 12 19 12 19Z"
                fill="#4E4E4E"
            />
        </svg>
    );
};
