import React, { useState } from "react";
import { Box } from "@mui/material";
import * as Styles from "./MiniChatStyles";
import StartPage from "./StartPage/StartPage";
import OneChat from "./OneChat/OneChat";
import EditFolder from "./EditFolder/EditFolder";
import NewFolder from "./NewFolder/NewFolder";
import { openDB } from "idb";
import useWebSocket from "react-use-websocket";
import { useEffect } from "react";
import FullChatStore from "store/FullChatStore";
import { useNavigate } from "react-router-dom";

const dbPromise = openDB("keyval-store", 1, {
    upgrade(db) {
        db.createObjectStore("keyval");
    },
});

export async function get(key) {
    return (await dbPromise).get("keyval", key);
}

export async function set(key, val) {
    return (await dbPromise).put("keyval", val, key);
}

export async function del(key) {
    return (await dbPromise).delete("keyval", key);
}

export async function clear() {
    return (await dbPromise).clear("keyval");
}

export async function keys() {
    return (await dbPromise).getAllKeys("keyval");
}

const URL = "wss://study-buddy.ru/chats/ws";

const MiniChat = () => {
    const [state, setState] = useState(1);

    const [folderChats, setFolderChats] = useState();

    const { sendMessage, sendJsonMessage, lastMessage, lastJsonMessage, readyState } = useWebSocket(URL, {
        share: true,
    });

    const auth = {
        id: 1,
        token: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3Mjk4NjQwMjMsImlhdCI6MTcyNzI3MjAyMywicm9sZSI6InR1dG9yIiwic3ViIjoiN2E3M2I4NDItZjRlMy00YjM4LWFmZTAtNTA5MTRmODVlYWYxIiwidGltZXpvbmUiOjEwODAwLCJ0eXBlIjoiYWNjZXNzIn0.AwyAGavOTQhGHi7uGHsjvpni9uj2UvbsEooakUrI1uL6HsEP40bbHe6cp-VvYwxvqJMVXK_SpuTRjlXQTwQXAMXn-qSgpWEYc9zWN-MEokO4uWMmfbUcmHV44_zR7siUVN7--vLGmLHDWw63qVxcIAiNPSQB8btBsfxqaCt9X0U",
    };

    set("token", auth.token);

    const navigate = useNavigate();

    useEffect(() => {
        FullChatStore.ProcessMessages(sendMessage, sendJsonMessage, lastMessage, lastJsonMessage, navigate);
    }, [lastMessage, lastJsonMessage]);

    const page = () => {
        if (state === 0)
            return (
                <OneChat
                    sendMessage={sendMessage}
                    sendJsonMessage={sendJsonMessage}
                    setPage={setState}
                />
            );

        if (state === 1)
            return (
                <StartPage
                    setFolderChats={setFolderChats}
                    setPage={setState}
                />
            );

        if (state === 2)
            return (
                <EditFolder
                    folderChats={folderChats}
                    setPage={setState}
                />
            );

        if (state === 3)
            return (
                <NewFolder
                    folderChats={folderChats}
                    setPage={setState}
                />
            );
    };

    return <Box sx={Styles.MiniChat}>{page()}</Box>;
};

export default MiniChat;
