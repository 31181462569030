import React, { memo, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { TabPanel } from "components/AuthorizationBodyBlock/Tabs/Tabs";
import StudyListItem from "./StudyListItem/StudyListItem";
import ClassesListTabs from "./ClassesListTabs/ClassesListTabs";
import * as textStyles from "themes/TextThemes";
import FilterClasses from "./FilterClasses/FilterClasses";
import MyClassesStore from "store/MyClassesStore";
import { observer } from "mobx-react-lite";
import * as styles from "./MyClassesListThemes";
import NoClassesYet from "./NoClassesYet/NoClassesYet";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";

const MyClassesList = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    useEffect(() => {
        MyClassesStore.fillFilters();
    }, []);

    return (
        <Box sx={styles.ClassesList}>
            <Box sx={styles.TopBlock(medias)}>
                <Box sx={styles.TitleTabs(medias)}>
                    <Typography sx={textStyles.HeadlineH3Black}>{t("main.myClasses.myClassesTitle")}</Typography>
                    <ClassesListTabs />
                </Box>
                <FilterClasses />
            </Box>
            <TabPanel
                value={MyClassesStore.page}
                index={0}
                style={{ width: "100%" }}
            >
                <Box sx={styles.StudyListItemGroupContainer}>
                    {Object.keys(MyClassesStore.filterUpcomingClasses()).length !== 0 ? (
                        Object.keys(MyClassesStore.filterUpcomingClasses()).map((date, index) => (
                            <Box
                                key={index}
                                sx={styles.StudyListItemGroup}
                            >
                                <Typography sx={textStyles.Button16Black}>{MyClassesStore.getFormattedDate(date)}</Typography>
                                <Box sx={styles.StudyListItemContainer}>
                                    {MyClassesStore.filterUpcomingClasses()[date].map((lesson, index) => (
                                        <StudyListItem
                                            key={lesson.id}
                                            lesson={lesson}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        ))
                    ) : (
                        <NoClassesYet type="upcoming" />
                    )}
                </Box>
            </TabPanel>
            <TabPanel
                value={MyClassesStore.page}
                index={1}
                style={{ width: "100%" }}
            >
                <Box sx={styles.StudyListItemGroupContainer}>
                    {Object.keys(MyClassesStore.filterPastClasses()).length !== 0 ? (
                        Object.keys(MyClassesStore.filterPastClasses()).map((date, index) => (
                            <Box
                                key={index}
                                sx={styles.StudyListItemGroup}
                            >
                                <Typography sx={textStyles.Button16Black}>{MyClassesStore.getFormattedDate(date)}</Typography>
                                <Box sx={styles.StudyListItemContainer}>
                                    {MyClassesStore.filterPastClasses()[date].map((lesson, index) => (
                                        <StudyListItem
                                            key={lesson.id}
                                            lesson={lesson}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        ))
                    ) : (
                        <NoClassesYet type="past" />
                    )}
                </Box>
            </TabPanel>
        </Box>
    );
});

export default memo(MyClassesList);
