import { createTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { styleProcessing } from "API/plugins/Utilities";

export const PaidServicesContent = {
    maxWidth: "1216px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    boxSizing: "border-box",
};

export const Header = {
    fontSize: "32px",
    fontWeight: "600",
};

export const PeriodCalendar = {
    display: "flex",
    alignItems: "center",
    gap: "16px",
};

export const TitleBlock = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    maxWidth: "1010px",
    width: "100%",
};

export const pdfButton = (theme) => {
    let commonStyle = {
        gap: "0px",
        padding: "7px 16px 8px 16px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "36px",
            height: "36px",
            minWidth: "36px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Table = {
    borderRadius: "20px",
    backgroundColor: "#FFFFFF",
    maxWidth: "1010px",
    width: "100%",
    border: "1px solid #EEEEF0",
};

export const Date = {
    borderRadius: "8px",
    border: "1px solid #DADADF",
    width: "118px",
    fontSize: "14px",
    fontWeight: "600",
    padding: "8px 8px 8px 8px",
    marginLeft: "20px",
    textAlign: "center",
};

export const FiltersSearcherTheme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "--TextField-brandBorderColor": "#FFFFFF",
                    "--TextField-brandBorderRadius": "8px",
                    "--TextField-brandBorderHoverColor": "#6212FF",
                    "--TextField-brandBorderFocusedColor": "#6212FF",
                    "& label.Mui-focused": {
                        color: "var(--TextField-brandBorderFocusedColor)",
                    },
                    "& .Mui-focused": {
                        background: "#FFFFFF",
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: "var(--TextField-brandBorderColor)",
                    borderRadius: "var(--TextField-brandBorderRadius)",
                },
                root: {
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: "var(--TextField-brandBorderHoverColor)",
                    },
                    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: "var(--TextField-brandBorderFocusedColor)",
                    },
                },
            },
        },

        // Name of the component ⚛️
        MuiFormControl: {
            styleOverrides: {
                // Name of the slot
                root: {
                    height: "32px",
                    margin: 0,
                    // Some CSS
                    marginTop: -1,
                    marginLeft: -1,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    height: 32,
                    borderRadius: "12px!important",
                    background: "#F9F9F9",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 14,
                    paddingLeft: "8px!important",
                    lineHeight: "150%",
                    ".MuiInputBase-root": {
                        background: "#6212FF!important",
                    },
                    ".MuiInputAdornment-positionStart": {
                        stroke: "#FFFFFF",
                        strokeWidth: 1,
                        ".MuiIconButton-root:hover": {
                            background: "#ffffff00!important",
                            color: "#6212FF!important",
                        },
                    },
                    ".MuiInputAdornment-positionEnd": {
                        ".MuiIconButton-root": {
                            paddingRight: "0!important",
                            paddingLeft: "0!important",
                            marginRight: "-5px",
                        },
                        ".MuiSvgIcon-root": {
                            width: "16px",
                            height: "16px",
                        },
                        ".MuiIconButton-root:hover": {
                            background: "#ffffff00!important",
                            color: "#6212FF!important",
                        },
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                },
            },
        },
    },
});

export const TextFieldStyle = (searchVisible, theme) => {
    let commonStyle = {};

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
            marginLeft: "32px",
            transition: "transform 0.3s ease",
            transform: searchVisible ? "translateX(0)" : "translateX(-100%)",
            opacity: searchVisible ? 1 : 0,
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TextFieldWrapper = (theme, searchVisible) => {
    let commonStyle = { display: "flex", alignItems: "center" };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            position: "absolute",
            top: "14px",
            right: "16px",
            width: searchVisible ? "100%" : "24px",
            justifyContent: "flex-end",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TextFieldIconButton = { padding: "0px", color: "#9E9EB0" };

export const PaidServices = (theme) => {
    let commonStyle = {
        borderRadius: "0px 0px 40px 40px",
        background: "#F7F7F7",
        paddingTop: "40px",
        paddingBottom: "70px",
        minHeight: "800px",
        display: "flex",
        justifyContent: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "20px 16px 46px 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const HeaderBorderBottom = (activeFilter, theme) => {
    let commonStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "14px 20px",
        borderBottom: "1px solid #EEEEF0",
        height: "34px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            height: "fit-content",
            alignItems: activeFilter === 5 ? "start" : "center",
            position: "relative",
            padding: "14px 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TextField = {
    width: "320px",
    border: "0px solid",
};

export const SearchedServices = (theme) => {
    let commonStyle = {
        padding: "30px 20px 66px 20px",
        display: "flex",
        flexDirection: "column",
        gap: "40px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "20px 16px 46px 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Filter = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "16px",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "14px",
            flexDirection: "column",
            alignItems: "left",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const PeriodFilter = {
    display: "flex",
    gap: "6px",
    alignItems: "center",
};

export const DateContainer = {
    width: "fit-content",
    borderRadius: "8px",
    border: "1px solid #EEEEF0",
    background: "#FFF",
    padding: "6px 8px",
};

export const ServicesDetailsContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
};

export const DateBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const TotalRow = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
};

export const LeftTotalBlock = {
    display: "flex",
    gap: "194px",
};

export const TotalBlock = {
    width: "60px",
};

export const TotalSumBlock = (theme) => {
    let commonStyle = {
        width: "84px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "fit-content",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const DetailsAndTotalsBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
};

export const EmptyDetails = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        textAlign: "center",
        padding: "120px 160px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "34px 30px 40px 30px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
