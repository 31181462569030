import { styleProcessing } from "API/plugins/Utilities";

export const FirstClass = {
    maxWidth: "764px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    background: "#FFF",
    padding: "20px 20px 23px 20px",
    borderRadius: "16px",
};

export const FirstClassTitle = {
    display: "flex",
    gap: "20px",
    alignItems: "flex-start",
};

export const FirstClassContent = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    maxWidth: "690px",
};

export const FirstClassButton = {
    display: "flex",
    justifyContent: "flex-end",
};

export const ButtomSize = (theme) => {
    let commonStyle = {
        width: "undefined",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
