import React from "react";
import { Tabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import { useNavigate } from "react-router-dom";
import UserStore from "store/UserStore";
import CancelStepModal from "components/OrdersBodyBlock/OrderCard/OrderSwitch/CancelStepModal";
import RenewOrderFilter from "components/OrdersBodyBlock/RenewOrder/RenewOrderFilter";
import filterStore from "store/FilterStore";
import orderResponseStore from "store/OrderResponseStore";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import * as styles from "./HeaderNavigationThemes";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { useMedias } from "API/mediasHook";
import { memo } from "react";

const HeaderNavigation = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const location = useLocation();

    const medias = useMedias();

    if (
        location.pathname === "/video-chat" ||
        location.pathname === "/payment" ||
        location.pathname === "/authorization" ||
        location.pathname === "/questionnaire" ||
        location.pathname === "/questionnaire-on-check" ||
        location.pathname === "/buddyrules"
    ) {
        return;
    }

    const PageChange = (event, newValue) => {
        if (newValue === 0) {
            navigate("/account");
        } else if (newValue === 1) {
            UserStore.role === "student" ? navigate("/myclasses") : navigate("/schedule");
        } else if (newValue === 2) {
            UserStore.role === "student" ? navigate("/orders") : navigate("/tutor/classes");
        } else if (newValue === 3) {
            navigate("/orders");
        } else if (newValue === 4) {
            navigate("/orders-responses");
        }

        UserStore.setPage(newValue);
    };

    const toggleOrderDrawer = () => {
        filterStore.setToggleDrawer();
        filterStore.setSubmitButtonFilter(true);
        filterStore.setChangeDrawerContent("canceled");
    };

    const hideDialog = () => {
        orderResponseStore.setToggleCancelOrderModal(false);
    };

    return (
        <>
            {UserStore.isAuth === true && UserStore.paymentPageActivated === false ? (
                <Box sx={styles.HeaderNavigation(medias)}>
                    {UserStore.role === "student" ? (
                        <Box sx={styles.HeaderNavigationContent}>
                            <Tabs
                                sx={styles.NavigationTabs(medias)}
                                TabIndicatorProps={{ style: styles.NavigationTabIndicator }}
                                value={["/account", "/myclasses", "/orders"].includes(window.location.pathname) ? UserStore.page : false}
                                onChange={PageChange}
                            >
                                <Tab
                                    disableRipple
                                    sx={styles.NavigationTab}
                                    label={t("main.header.personalAccount")}
                                />
                                <Tab
                                    disableRipple
                                    sx={styles.NavigationTab}
                                    label={t("main.header.myClasses")}
                                />
                                <Tab
                                    disableRipple
                                    sx={styles.NavigationTab}
                                    label={t("main.header.myOrders")}
                                />
                            </Tabs>
                        </Box>
                    ) : (
                        <Box sx={styles.HeaderNavigationContent}>
                            <Tabs
                                sx={styles.NavigationTabs}
                                TabIndicatorProps={{ style: styles.NavigationTabIndicator }}
                                value={["/account", "/schedule", "/tutor/classes", "/orders", "/orders-responses"].includes(window.location.pathname) ? UserStore.page : false}
                                onChange={PageChange}
                            >
                                <Tab
                                    disableRipple
                                    sx={styles.NavigationTab}
                                    label={t("main.header.personalAccount")}
                                />
                                <Tab
                                    disableRipple
                                    sx={styles.NavigationTab}
                                    label={t("main.header.schedule")}
                                />
                                <Tab
                                    disableRipple
                                    sx={styles.NavigationTab}
                                    label={t("main.header.classes")}
                                />
                                <Tab
                                    disableRipple
                                    sx={styles.NavigationTab}
                                    label={t("main.header.orders")}
                                />
                                <Tab
                                    disableRipple
                                    sx={styles.NavigationTab}
                                    label={t("main.header.responses")}
                                />
                            </Tabs>
                        </Box>
                    )}
                    <CancelStepModal
                        dialogOpen={orderResponseStore.toggleCancelOrderModal}
                        hideDialog={hideDialog}
                    />
                    <RenewOrderFilter toggleOrderDrawer={toggleOrderDrawer} />
                </Box>
            ) : (
                <></>
            )}
        </>
    );
});

export default memo(HeaderNavigation);
