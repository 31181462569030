import React from "react";

const NotificationsIcon = function ({ children, ...props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M18.7832 16.9739C18.1684 16.4139 17.6301 15.7719 17.1829 15.0651C16.6946 14.0896 16.402 13.0243 16.3221 11.9316V8.71335C16.3264 6.99714 15.7171 5.33842 14.6088 4.04884C13.5005 2.75926 11.9695 1.9276 10.3034 1.7101V0.869707C10.3034 0.639046 10.2137 0.417833 10.0541 0.254731C9.89443 0.0916296 9.67793 0 9.45218 0C9.22644 0 9.00994 0.0916296 8.85031 0.254731C8.69068 0.417833 8.60101 0.639046 8.60101 0.869707V1.72313C6.94983 1.9563 5.4373 2.793 4.34356 4.07825C3.24982 5.36349 2.64899 7.01019 2.65235 8.71335V11.9316C2.57249 13.0243 2.27986 14.0896 1.79161 15.0651C1.35224 15.7703 0.822607 16.4122 0.216779 16.9739C0.148768 17.035 0.0942613 17.1101 0.0568836 17.1944C0.0195059 17.2786 0.000114635 17.37 0 17.4625V18.3485C0 18.5213 0.0671738 18.687 0.186744 18.8092C0.306314 18.9314 0.468486 19 0.637584 19H18.3624C18.5315 19 18.6937 18.9314 18.8133 18.8092C18.9328 18.687 19 18.5213 19 18.3485V17.4625C18.9999 17.37 18.9805 17.2786 18.9431 17.1944C18.9057 17.1101 18.8512 17.035 18.7832 16.9739Z"
                fill={props.pressed ? "#8141FF" : "#222"}
            />
        </svg>
    );
};

export default NotificationsIcon;
