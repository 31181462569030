import { createTheme } from "@mui/material/styles";

export const orderBodyBlockStyle = {
    display: "flex",
    flexDirection: "column",
    gap: 20,
};

export const orderBodyNavBlockStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    marginTop: 20,
    cursor: "pointer",
};

export const navTextStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    "&:hover": {
        color: "#6212FF",
    },
    "&:active": {
        color: "#6212FF",
    },
};

export const orderBodyContentBlockStyle = {
    display: "flex",
    flexDirection: "row",
    gap: 20,
};

export const GoBackIconTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    display: "flex",
                    width: "24px",
                    height: "24px",
                    "&:hover": {
                        color: "#6212FF",
                    },
                    "&:active": {
                        color: "#6212FF",
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    padding: 0,
                    ".MuiSvgIcon-root": {
                        width: "24px",
                        height: "24px",
                    },
                    "&:hover": {
                        color: "#6212FF",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        color: "#6212FF",
                        borderColor: "#6212FF",
                    },
                },
            },
        },
    },
});
