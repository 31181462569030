import React, { memo } from "react";
import * as themes from "./NameBlockThemesDefault";
import Box from "@mui/material/Box";
import { Rating, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

const NameBlock = ({ children, ...props }) => {
    return (
        <Box sx={themes.nameBlockContainerStyle}>
            <Typography sx={themes.nameAndDateTextStyle}>
                {props.value.Name}, {props.value.ReviewDateFormatted}
            </Typography>
            <ThemeProvider theme={themes.RatingTheme}>
                <Rating
                    value={props.value.RatingValue}
                    readOnly
                />
            </ThemeProvider>
        </Box>
    );
};

export default memo(NameBlock);
