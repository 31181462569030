import { createTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

export const FiltersSearcherTheme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "--TextField-brandBorderColor": "#9E9EB0",
                    "--TextField-brandBorderHoverColor": "#6212FF",
                    "--TextField-brandBorderFocusedColor": "#6212FF",
                    "& label.Mui-focused": {
                        color: "var(--TextField-brandBorderFocusedColor)",
                    },
                    "& .Mui-focused": {
                        background: "#FFFFFF",
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: "var(--TextField-brandBorderColor)",
                },
                root: {
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: "var(--TextField-brandBorderHoverColor)",
                    },
                    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: "var(--TextField-brandBorderFocusedColor)",
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    "&:before, &:after": {
                        borderBottom: "2px solid var(--TextField-brandBorderColor)",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                    },
                    "&.Mui-focused:after": {
                        borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    "&:before": {
                        borderBottom: "2px solid var(--TextField-brandBorderColor)",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                    },
                    "&.Mui-focused:after": {
                        borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                    },
                },
            },
        },

        /*MuiIconButton: {
            styleOverrides: {
                root: {
                    '&.MuiIconButton-root:hover': {
                        background: '#ffffff00',
                        color: '#6212FF'
                    }
                },
            },
        },*/

        // Name of the component ⚛️
        MuiFormControl: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    width: 279,
                    height: 44,
                    marginTop: 10,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    height: 44,
                    borderRadius: "12px!important",
                    background: "#F9F9F9",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 14,
                    paddingLeft: "8px!important",
                    lineHeight: "150%",
                    ".MuiInputBase-root": {
                        background: "#6212FF!important",
                    },
                    ".MuiInputAdornment-positionStart": {
                        stroke: "#FFFFFF",
                        strokeWidth: 1,
                        ".MuiIconButton-root:hover": {
                            background: "#ffffff00!important",
                            color: "#6212FF!important",
                        },
                    },
                    ".MuiInputAdornment-positionEnd": {
                        ".MuiIconButton-root": {
                            paddingRight: "0!important",
                            paddingLeft: "0!important",
                            marginRight: "-5px",
                        },
                        ".MuiSvgIcon-root": {
                            width: "16px",
                            height: "16px",
                        },
                        ".MuiIconButton-root:hover": {
                            background: "#ffffff00!important",
                            color: "#6212FF!important",
                        },
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                },
            },
        },
    },
});
