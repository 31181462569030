import { styleProcessing } from "API/plugins/Utilities";

export const containerStyle = {
    textAlign: "center",
    height: "100%",
    display: "flex",
    flexDirection: "column",
};

export const calendarTypeSwitchRowStyle = {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
};

export const calendarSwitchTypeButtonStyle = {
    margin: 0,
    padding: 0,
};

export const weekStyleLine = {
    display: "flex",
    justifyContent: "flex-start",
    margin: "20px 0",
    width: "100%",
};

export const weekStyleLineContent = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        paddingLeft: "40px",
        justifyContent: "space-between",
        alignItems: "flex-start",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "0px 20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Calendar = (maxHeight, theme) => {
    let commonStyle = {
        width: "100%",
        maxHeight: maxHeight ? maxHeight : "none",
        overflowY: maxHeight ? "scroll" : "visible",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {},
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const weekStyleTable = (type) => {
    return {
        display: "flex",
        margin: "20px 0",
        flexDirection: "row",
        gap: type === "modal" ? "5px" : "8px",
        paddingLeft: type === "modal" ? "40px" : "0",
    };
};

export const weekStyleTableOuter = {
    width: "100%",
};

export const dayStyle = {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    gap: "12px",
};

export const dayTitleContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    gap: "5px",
};

export const dayTitle = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    margin: 0,
    color: "#222",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const dayNMonth = {
    margin: 0,
    color: "#9E9EB0",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const timeContainerStyle = {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    flexWrap: "wrap",
};

export const dayTableTitleContainerStyle = (date, today, type) => {
    return {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "0px",
        width: type === "modal" ? "80px" : "111px",
        boxSizing: "border-box",
        padding: "5px 29.5px 5px 29.5px",
        borderRadius: "10px",
        border: !(date < today) && !(date > today) ? "1px solid #6212FF" : "1px solid transparent",
        backgroundColor: date < today ? "#F9F9F9" : date > today ? "#F9F9F9" : "none",
    };
};

export const dayTableTitleStyle = (date, today) => {
    return {
        margin: 0,
        color: date < today ? "#BBBBC8" : date > today ? "#222" : "#6212FF",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "150%",
    };
};

export const dayTableNMonthStyle = (date, today) => {
    return {
        margin: 0,
        color: date < today ? "#BBBBC8" : date > today ? "#67677A" : "#6212FF",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "150%",
    };
};

export const timeTableContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    alignItems: "center",
};

export const weekNavigation = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    background: "rgba(98, 18, 255, 0.05)",
    alignItems: "center",
    justifyContent: "space-between",
};

export const weekNavigationContent = (theme) => {
    let commonStyle = {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        padding: "12px 40px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "12px 20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const weekNavigationRight = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "row",
        gap: "16px",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const weekChangeButtons = {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
};

export const weekButton = {
    padding: 0,
    margin: 0,
};

export const HourButton = (condition, active, theme) => {
    let commonStyle = {
        width: "64px",
        height: "32px",
        borderRadius: "4px",
        color: condition ? "#fff" : active ? "#fff" : "#222",
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "150%",
        padding: "4px 9px 4px 10px",
        background: condition ? "#9E9EB0" : active ? "#6212FF" : "transparent",
        cursor: condition ? "default" : "pointer",
        ":hover": {
            color: condition ? "#fff" : active ? "#fff" : "#222",
            background: condition ? "#9E9EB0" : active ? "#6212FF" : "rgba(187, 187, 200, 0.17)",
        },
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "140%",
            width: "47px",
            height: "25px",
            boxSizing: "border-box",
            padding: "2px 5px",
            minWidth: "0px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TooltipPopperProps = {
    sx: {
        "& .MuiTooltip-tooltip": {
            border: "none",
            color: "#222",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "140%",
            borderRadius: "10px",
            bgcolor: "#fff",
            maxWidth: "273px",
            boxShadow: "0px 2px 10px 0px rgba(106, 99, 118, 0.15)",
            padding: "10px",
        },
        "& .MuiTooltip-arrow": {
            color: "#fff",
        },
    },
};
