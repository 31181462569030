import * as React from "react";

import MenuItem from "@mui/material/MenuItem";

import DoneIcon from "@mui/icons-material/Done";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import * as themes from "./OrderResponseDropMenuStyled";
import orderResponseStore from "store/OrderResponseStore";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import Box from "@mui/material/Box";
const dropDownTitle = [{ text: "Новые" }, { text: "Подешевле" }, { text: "Подороже" }, { text: "С Кешбэком" }, { text: "По рейтингу" }];

const OrderResponseDropMenu = observer(() => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [activeMenuItem, setActiveMenuItem] = React.useState("Новые");

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (title) => {
        setActiveMenuItem(title);
        handleClose();
    };

    useEffect(() => {
        orderResponseStore.setSortResponseTutors(activeMenuItem);
    }, [activeMenuItem]);

    return (
        <Box>
            <themes.StyledOrderResponseMenuButton
                disableRipple
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon sx={{ marginLeft: "-7px" }} />}
            >
                {activeMenuItem}
            </themes.StyledOrderResponseMenuButton>
            <themes.StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {dropDownTitle.map((el, index) => (
                    <MenuItem
                        disableRipple={true}
                        key={index.toString()}
                        onClick={() => handleMenuItemClick(el.text)}
                    >
                        <themes.StyledOrderResponseMenuTitle>{el.text}</themes.StyledOrderResponseMenuTitle>
                        {activeMenuItem === el.text && <DoneIcon />}
                    </MenuItem>
                ))}
            </themes.StyledMenu>
        </Box>
    );
});

export default OrderResponseDropMenu;
