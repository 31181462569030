import { styleProcessing } from "API/plugins/Utilities";

export const TeacherQuestionnaireTopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const TeacherQuestionnaireSubTitle = {
    maxWidth: "598px",
    width: "100%",
};

export const TeacherQuestionnaireProfileLinksOuterContainer = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "row",
        gap: "16px",
        alignItems: "center",
        marginTop: "30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            width: "100%",
            alignItems: "left",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherQuestionnaireProfileLinksInnerContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
};

export const STooltipButton = { margin: 0, padding: 0 };

export const TeacherQuestionnaireProfileLinksOuterBox = (condition) => {
    return {
        maxWidth: "598px",
        width: "100%",
        marginTop: condition ? "23px" : "0px",
        display: "flex",
        flexDirection: "column",
        gap: 0,
    };
};

export const TeacherQuestionnaireProfileLinksInnerBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "17px 0",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
};

export const TeacherQuestionnaireProfileLinksSiteNameBox = {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
};

export const TeacherQuestionnaireProfileLinksSiteName = {
    color: "#222",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "150%",
};

export const TooltipPopperProps = {
    sx: {
        "& .MuiTooltip-tooltip": {
            border: "none",
            color: "#222",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "140%",
            borderRadius: "10px",
            bgcolor: "#fff",
            width: "fit-content",
            boxShadow: "0px 2px 10px 0px rgba(106, 99, 118, 0.15)",
            padding: "10px",
        },
    },
};

export const TooltipButton = {
    padding: 0,
    height: "16px",
};

export const PrivilegeBlock = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
};

export const IconBlock = {
    width: "16px",
    height: "16px",
};

export const IconDelete = { margin: 0, padding: 0, height: "16px" };

export const LinkText = (theme) => {
    let commonStyle = {};

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "185px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
