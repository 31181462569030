import { Drawer, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import UserStore from "store/UserStore";
import SettingsStore from "store/SettingsStore";
import SButton from "components/UI/SButton";
import React from "react";
import * as styles from "./LoginModalsThemes";
import * as textStyles from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";
import ModalSettings from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsBig/ModalSettings";
import VisibilityOnIcon from "assets/SettingsAssets/SVG/VisibilityOnIcon";
import VisibilityOffIcon from "assets/SettingsAssets/SVG/VisibilityOffIcon";
import { useMedias } from "API/mediasHook";
import DrawerHeader from "components/SettingsBodyBlock/StudentSettings/DrawerHeader/DrawerHeader";

const ModalVerifyPassword = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowNewPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const Forgot = () => {
        props.handleForgot();
    };

    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        if (SettingsStore.temporaryPassword === UserStore.UserInfo.password) {
            props.handleSave();
        } else {
            SettingsStore.setWrongPassword(true);
        }
    };

    const DrawerList = (
        <Box sx={styles.AddForm(medias)}>
            <DrawerHeader
                text={t("main.settings.loginDetails.addCurrentPasswordTitle")}
                handleClose={Close}
            />
            <Box sx={styles.SMSsent(medias)}>
                <Typography sx={textStyles.Body2Black}>{t("main.settings.loginDetails.addCurrentPasswordDescription")}</Typography>
                <Typography sx={textStyles.Body2Black}>{t("main.currentPassword")}</Typography>
                <TextField
                    sx={styles.AddLoginDetailTextField("400px", SettingsStore.wrongPassword)}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    disableRipple
                                >
                                    {showPassword ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    required
                    fullWidth
                    variant="standard"
                    name="email"
                    placeholder={t("main.enterPassword")}
                    helperText={SettingsStore.wrongPassword === true ? t("main.settings.loginDetails.incorrectPassword") : ""}
                    onChange={(e) => SettingsStore.setTemporaryPassword(e.target.value)}
                    FormHelperTextProps={{ sx: { color: "#E12626" } }}
                />
                <Typography
                    sx={textStyles.Body2Purple}
                    style={{ cursor: "pointer" }}
                    onClick={Forgot}
                >
                    {t("main.settings.loginDetails.forgotPassword")}
                </Typography>
            </Box>
            <Box
                container
                sx={styles.ButtonsDrawerContainer(medias)}
            >
                <SButton
                    style={{ width: "192px" }}
                    padding="10px 20px 11px 20px"
                    variant="secondary"
                    onClick={Close}
                >
                    {t("main.cancel")}
                </SButton>
                <SButton
                    style={{ width: "192px" }}
                    padding="10px 20px 11px 20px"
                    variant="unshadowed"
                    onClick={Save}
                    disabled={SettingsStore.temporaryPassword === null}
                >
                    {t("main.continue")}
                </SButton>
            </Box>
        </Box>
    );

    return (
        <>
            {!medias.sm ? (
                <Drawer
                    open={props.open}
                    onClose={Close}
                >
                    {DrawerList}
                </Drawer>
            ) : (
                <ModalSettings
                    visible={props.open}
                    handleClose={Close}
                    header={t("main.settings.loginDetails.addCurrentPasswordTitle")}
                >
                    <Box sx={styles.AddForm(medias)}>
                        <Typography sx={textStyles.Body2Black}>{t("main.settings.loginDetails.addCurrentPasswordDescription")}</Typography>
                        <Box sx={styles.SMSsent}>
                            <Box sx={styles.LabelField}>
                                <Typography sx={textStyles.Body2Black}>{t("main.currentPassword")}</Typography>
                                <TextField
                                    sx={styles.AddLoginDetailTextField("400px", SettingsStore.wrongPassword)}
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowNewPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    disableRipple
                                                >
                                                    {showPassword ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    required
                                    fullWidth
                                    variant="standard"
                                    name="email"
                                    placeholder={t("main.enterPassword")}
                                    helperText={SettingsStore.wrongPassword === true ? t("main.settings.loginDetails.incorrectPassword") : ""}
                                    onChange={(e) => SettingsStore.setTemporaryPassword(e.target.value)}
                                    FormHelperTextProps={{ sx: { color: "#E12626" } }}
                                />
                            </Box>
                            <Typography
                                sx={textStyles.Body2Purple}
                                style={{ cursor: "pointer" }}
                                onClick={Forgot}
                            >
                                {t("main.settings.loginDetails.forgotPassword")}
                            </Typography>
                        </Box>
                        <Box
                            container
                            sx={styles.ButtonsDrawerContainer(medias)}
                        >
                            <SButton
                                style={{ width: "192px" }}
                                padding="10px 20px 11px 20px"
                                variant="secondary"
                                onClick={Close}
                            >
                                {t("main.cancel")}
                            </SButton>
                            <SButton
                                style={{ width: "192px" }}
                                padding="10px 20px 11px 20px"
                                variant="unshadowed"
                                onClick={Save}
                                disabled={SettingsStore.temporaryPassword === null}
                            >
                                {t("main.continue")}
                            </SButton>
                        </Box>
                    </Box>
                </ModalSettings>
            )}
        </>
    );
});

export default ModalVerifyPassword;
