import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import * as themes from "./OrderResponseThemes";
import { memo } from "react";
const OrderResponse = ({ children, ...props }) => {
    return (
        <Box sx={themes.OrderResponseWrapper}>
            <Typography sx={themes.ResponseText}>Отклики:</Typography>
            <Typography sx={themes.ResponseCount}>7</Typography>
            <RemoveRedEyeOutlined sx={themes.ResponseIcon} />
            <Typography sx={themes.ResponseViews}>
                40(
                <Typography
                    variant="span"
                    sx={themes.ResponseViewsPlus}
                >
                    +15
                </Typography>
                )
            </Typography>
        </Box>
    );
};

export default memo(OrderResponse);
