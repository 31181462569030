export const ServiceAdvantagesBlock = {
    width: "1216px",
    display: "flex",
    gap: "123px",
    marginTop: "140px",
    position: "relative",
};

export const FunctionsBlock = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "20px",
};

export const functionElement = {
    display: "flex",
    gap: "16px",
};

export const iconElem = {
    height: "fit-content",
};

export const TitleBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "570px",
};

export const JoinUsContent = {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    width: "598px",
};

export const Button = {
    width: "289px",
};

export const BottomBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "34px",
};
