import React, { memo } from "react";
import Box from "@mui/material/Box";
import * as styles from "./InfoForStudentThemes";
import * as textStyles from "themes/TextThemes";
import { Typography } from "@mui/material";
import { InfoPointIcon } from "assets/Authorization/SVG/InfoPointIcon";
const InfoForStudent = () => {
    return (
        <Box sx={styles.InfoForStudent}>
            <Box sx={styles.Title}>
                <Typography sx={textStyles.HeadlineH41White}>Мы можем помочь с любой задачей</Typography>
            </Box>
            <Box sx={styles.PointsContainer}>
                <Box sx={styles.InfoPoint}>
                    <Box>
                        <InfoPointIcon />
                    </Box>
                    <Typography sx={textStyles.Body2White}>Репетиторы по школьной программе</Typography>
                </Box>
                <Box sx={styles.InfoPoint}>
                    <Box>
                        <InfoPointIcon />
                    </Box>
                    <Typography sx={textStyles.Body2White}>Репетиторы для студентов ВУЗов</Typography>
                </Box>
                <Box sx={styles.InfoPoint}>
                    <Box>
                        <InfoPointIcon />
                    </Box>
                    <Typography sx={textStyles.Body2White}>Помощь со студенческими работами</Typography>
                </Box>
                <Box sx={styles.InfoPoint}>
                    <Box>
                        <InfoPointIcon />
                    </Box>
                    <Typography sx={textStyles.Body2White}>И многое другое</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default memo(InfoForStudent);
