import { styleProcessing } from "API/plugins/Utilities";

export const TeacherPersonalDataStep = {
    display: "flex",
    flexDirection: "column",
    maxWidth: "599px",
    width: "100%",
};

export const TeacherPersonalData = (theme) => {
    let commonStyle = {
        marginBottom: "60px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            marginBottom: "40px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherQuestionnaireTopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const ChipsOuterContainer = (len) => {
    return {
        marginTop: len > 0 ? "30px" : "0px",
    };
};

export const HelperText = {
    marginTop: "10px",
};

export const CheckBox = {
    padding: 0,
};

export const FormControl = {
    display: "flex",
    gap: "12px",
    m: 0,
};

export const CheckBoxGroup = (theme) => {
    let commonStyle = {
        marginTop: "30px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            marginTop: "16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
