import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, IconButton, Typography } from "@mui/material";
import LinkArrow from "assets/PAAssets/SVG/link-arrow";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import * as styles from "./InviteBlockThemes";
import * as textStyles from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import UserStore from "store/UserStore";

const InviteBlock = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
        Copy();
        setTimeout(() => {
            setOpen(false);
        }, 1700);
    };

    async function Copy() {
        try {
            let value = UserStore.referralLink;

            if (navigator.clipboard) {
                await navigator.clipboard.writeText(value);
            } else {
                console.log(`Copy Error`);
            }
        } catch (err) {
            console.error(`Failed to copy: ${err}`);
        }
    }

    return (
        <Box sx={styles.InviteBlock}>
            <Box sx={styles.InviteBlockContent}>
                <Box sx={styles.InviteText}>
                    <Box sx={styles.InviteTitle}>
                        <Typography sx={textStyles.HeadlineH4Black}>{t("main.studentAccount.inviteFriend")}</Typography>
                        <IconButton
                            onClick={() => navigate("/referral")}
                            disableRipple
                            sx={{ p: 0, m: 0 }}
                        >
                            <LinkArrow color="#6212FF" />
                        </IconButton>
                    </Box>
                    <Typography sx={textStyles.Body2Black}>
                        {t("main.studentAccount.andGet")}
                        <span style={styles.BonusesText}>{t("main.studentAccount.nBonuses")}</span> <br />
                        {t("main.studentAccount.onBonusAccount")}
                    </Typography>
                </Box>
                <Box sx={styles.LinkBlock}>
                    <Box
                        sx={styles.LinkContainer}
                        onClick={Copy}
                    >
                        <Typography sx={textStyles.Body2DarkGrey}>{UserStore.referralLink}</Typography>
                    </Box>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            PopperProps={styles.PopperProps}
                            title={t("main.studentAccount.linkCopied")}
                            arrow
                            onClose={handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                        >
                            <Button
                                disableRipple
                                variant="contained"
                                onClick={handleTooltipOpen}
                                sx={styles.CopyButton}
                            >
                                <Typography sx={textStyles.Button14White}>{t("main.studentAccount.copyLink")}</Typography>
                            </Button>
                        </Tooltip>
                    </ClickAwayListener>
                </Box>
            </Box>
        </Box>
    );
});

export default memo(InviteBlock);
