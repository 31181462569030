export const OffersCardsWrapper = {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "10px",
};

export const OffersCardsTopContentWrapper = { display: "flex", width: "100%" };

export const OffersCardsLeftWrapper = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
};

export const OffersCardsNameOnlineWrapper = { display: "flex" };

export const OffersCardsRatingReviewWrapper = {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginTop: "4px",
};

export const OffersCardsDivider = {
    marginTop: "12px",
    color: "#EEEEF0",
    width: "100%",
};

export const OffersCardsBottomWrapper = {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
};

export const OffersCardsRightSideWrapper = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    maxWidth: "230px",
    width: "100%",
    gap: "10px",
    marginLeft: "40px",
};

export const OffersCardsPriceWrapper = {
    display: "flex",
    flexWrap: "wrap",
    gap: "11px",
    justifyContent: "center",
    alignItems: "top",
    maxWidth: "230px",
    width: "100%",
    marginTop: "-2px",
};

export const OffersCardsRejectComplainWrapper = {
    display: "flex",
    gap: "20px",
};
