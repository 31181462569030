import { createTheme } from "@mui/material/styles";

export const DialogContainerTheme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: "rgba(0, 0, 0, 0)!important",
                    boxShadow: "none!important",
                    margin: "0px",
                },
            },
        },
    },
});

export const dialogContainerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    gap: "10px",
};

export const cardContainerStyle = (medias) => {
    return {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        padding: medias.sm ? "20px 40px" : "20px 20px",
        flexDirection: "column",
        gap: "20px",
        borderRadius: "20px",
        background: "var(--main-white, #FFF)",
    };
};

export const contentBlockContainerStyle = (medias) => {
    return {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: medias.sm ? "40px" : "30px",
    };
};

export const contentBlockStyle = (medias) => {
    return {
        display: "flex",
        width: medias.sm ? "350px" : "270px",
        flexDirection: "column",
        alignItems: "center",
        gap: medias.sm ? "16px" : "20px",
    };
};

export const AvatarTheme = createTheme({
    components: {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    width: 80,
                    height: 80,
                    borderRadius: "80px",
                },
            },
        },
    },
});

export const contentTextContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "20px",
};

export const headerTextStyle = (medias) => {
    return {
        color: "var(--text-black, #222)",
        textAlign: "center",
        fontSize: medias.sm ? "24px" : "18px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "140%",
    };
};

export const subHeaderTextStyle = {
    color: "var(--text-black, #222)",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const ButtonRegistrationTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    display: "flex",
                    padding: "10px 20px 11px 20px!important",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "42px!important",
                    background: "var(--main-purple, #6212FF)",
                    ".MuiButton-endIcon": {
                        color: "#FFF",
                        margin: 0,
                    },
                    "&:hover": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                    },
                    "&:active": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                    },
                },
            },
        },
    },
});

export const buttonRegistrationTextStyle = {
    textTransform: "none",
    color: "var(--text-white, #FFF)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
};

export const actionsBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
};

export const horizontalSeparatorStyle = (medias) => {
    return {
        width: medias.sm ? 359 : 270,
        height: 1,
        background: "#DADADF",
    };
};

export const actionsContainerStyle = {
    display: "flex",
    alignItems: "flex-start",
    gap: "10px",
};

export const captionTextStyle = {
    color: "var(--text-dark-grey, #67677A)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const loginTextStyle = {
    color: "#6212FF",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
    cursor: "pointer",
};

export const CloseIconTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid #9E9EB0",
                    borderRadius: "50%",
                    background: "transparent",
                    width: "24px",
                    height: "24px",
                    "&:hover": {
                        background: "#transparent!important",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "#transparent!important",
                        borderColor: "#6212FF",
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    padding: 0,
                    color: "#FFFFFF",
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    "&:hover": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                    "&:active": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                },
            },
        },
    },
});
