import React from "react";
import * as themes from "./OrdersInProgressThemes";
import * as textThemes from "themes/TextThemes";
import { Box, Typography } from "@mui/material";
import SButton from "components/UI/SButton";
import { useNavigate } from "react-router-dom";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import UserStore from "store/UserStore";
import TeacherOrdersStore from "store/TeacherOrdersStore";
import InProgressCard from "components/OrdersBodyBlock/TutorOrders/OrderCards/InProgressCard/InProgressCard";
import { useMedias } from "API/mediasHook";

const OrdersInProgress = ({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const handleClickAllOrders = () => {
        navigate("/orders");
        UserStore.setPage(3);
    };

    return (
        <Box style={themes.OrdersInProgress(medias)}>
            <Box sx={themes.Header(medias)}>
                <Typography sx={textThemes.HeadlineH5Black}>{t("teacherAccount.ordersInProgress.ordersInProgress")}</Typography>
                <SButton
                    variant="text-arrow"
                    onClick={handleClickAllOrders}
                >
                    {t("teacherAccount.ordersInProgress.allOrders")}
                </SButton>
            </Box>
            <Box sx={themes.OrdersContainer}>
                {TeacherOrdersStore.orders
                    .filter((item) => item.type === "inProgress")
                    .map((order) => (
                        <InProgressCard
                            key={order.id}
                            orderInfo={order}
                        />
                    ))}
            </Box>
        </Box>
    );
};

export default memo(OrdersInProgress);
