export const disciplineContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: 30,
};

export const disciplineContentContainerStyle = {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 10,
};

export const disciplineItemStyle = (medias) => {
    return {
        display: "flex",
        width: medias.sm ? "827px" : "320px",
        justifyContent: "space-between",
    };
};

export const disciplineInfoStyle = {
    color: "var(--text-black, #222)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const disciplineCostStyle = (hasCost) => ({
    color: hasCost ? "var(--text-black, #222)" : "var(--text-dark-grey, #67677A)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
});

export const cashbackInfoStyle = {
    display: "flex",
    padding: "16px 20px",
    justifyContent: "space-between",
    alignItems: "flex-start",
    borderRadius: "10px",
    background: "linear-gradient(227deg, #04C83B 8.70%, #12AAFF 89.43%)",
};

export const cashbackTextStyle = {
    color: "var(--main-white, #FFF)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
};
