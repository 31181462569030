import SettingsStore from "store/SettingsStore";
import UserStore from "store/UserStore";
import { memo } from "react";
import { observer } from "mobx-react-lite";
import MastercardIcon from "assets/SettingsAssets/SVG/MastercardIcon";
import { Typography, Box } from "@mui/material";
import * as textStyles from "themes/TextThemes";
import * as styles from "./CardDetectionThemes";
import VisaIcon from "assets/SettingsAssets/SVG/VisaIcon";
import MirIcon from "assets/SettingsAssets/SVG/MirIcon";

const CardDetection = observer(({ children, ...props }) => {
    if (SettingsStore.cardDetection(UserStore.cardNumber) === "Mastercard") {
        return (
            <Box sx={styles.CardDetection}>
                <MastercardIcon />
                <Typography sx={textStyles.Body1Black}>{`Mastercard ****${UserStore.cardNumber.slice(-4)}`}</Typography>
            </Box>
        );
    } else if (SettingsStore.cardDetection(UserStore.cardNumber) === "Visa") {
        return (
            <Box sx={styles.CardDetection}>
                <VisaIcon />
                <Typography sx={textStyles.Body1Black}>{`Visa ****${UserStore.cardNumber.slice(-4)}`}</Typography>
            </Box>
        );
    } else {
        return (
            <Box sx={styles.CardDetection}>
                <MirIcon />
                <Typography sx={textStyles.Body1Black}>{`Мир ****${UserStore.cardNumber.slice(-4)}`}</Typography>
            </Box>
        );
    }
});

export default memo(CardDetection);
