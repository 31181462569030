import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./SelectedFiltersTagsThemes";
import CatalogStore from "store/CatalogStore";
import { Box, Chip, IconButton } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { CloseRounded } from "@mui/icons-material";

const SelectedFiltersTags = observer(({ children, ...props }) => {
    const handleDelete = (value, event) => {
        console.log(value);
        CatalogStore.Clear(value);
    };

    const handleDeleteAll = (event) => {
        CatalogStore.SideFiltersClearAll();
    };

    return (
        <Box sx={Object.assign({}, themes.filtersTagsContainerStyle, props?.filtersTagsContainerStyle)}>
            <ThemeProvider theme={Object.assign({}, themes.AllChipTheme, props?.allChipTheme)}>
                {CatalogStore.selectedChipsList.map((value) => (
                    <Chip
                        key={value.ID}
                        label={value.value[value.fieldName]}
                        deleteIcon={
                            <IconButton onClick={handleDelete.bind(this, value)}>
                                <CloseRounded></CloseRounded>
                            </IconButton>
                        }
                        onDelete={handleDelete.bind(this, value)}
                    />
                ))}
            </ThemeProvider>
            <ThemeProvider theme={Object.assign({}, themes.ClearChipTheme, props?.clearChipTheme)}>
                {CatalogStore.selectedChipsList.length > 0 ? (
                    <Chip
                        label={"Сбросить все фильтры"}
                        deleteIcon={
                            <IconButton onClick={handleDeleteAll}>
                                <CloseRounded></CloseRounded>
                            </IconButton>
                        }
                        onDelete={handleDeleteAll}
                    />
                ) : null}
            </ThemeProvider>
        </Box>
    );
});

export default memo(SelectedFiltersTags);
