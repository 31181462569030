import React, { memo } from "react";
import * as themes from "./StateThreeStyle";
import Bulb from "./bulb.png";
import Clock from "./clock.png";
import RedWoman from "./redWoman.png";
import Teaching from "./Teaching.png";
import MessageSlava from "./MessageSlava.png";
import MessageKaterina from "./MessageKaterina.png";
import { Box } from "@mui/material";

const StateThree = ({ children, ...props }) => {
    return (
        <Box>
            <Box sx={themes.bulbIconContainer}>
                <img
                    style={themes.bulbIconImg}
                    src={Bulb}
                    alt="Bulb"
                />
            </Box>

            <Box sx={themes.clockIconContainer}>
                <img
                    style={themes.clockIconImg}
                    src={Clock}
                    alt="Clock"
                />
            </Box>

            <Box sx={themes.rateIcon}>
                <Box sx={themes.rateIconText}>5.0</Box>
            </Box>

            <Box sx={themes.bonusIcon}>
                <Box sx={themes.bonusIconText}>+35</Box>
            </Box>

            <Box sx={themes.RedWomanImg}>
                <img
                    src={RedWoman}
                    alt="RedWoman"
                />
            </Box>

            <Box sx={themes.TeachingImg}>
                <img
                    src={Teaching}
                    alt="Teaching"
                />
            </Box>

            <Box sx={themes.MessageSlavaImg}>
                <img
                    src={MessageSlava}
                    alt="MessageSlava"
                />
            </Box>

            <Box sx={themes.MessageKaterinaLeftImg}>
                <img
                    src={MessageKaterina}
                    alt="MessageKaterina"
                />
            </Box>

            <Box sx={themes.MessageKaterinaRightImg}>
                <img
                    src={MessageKaterina}
                    alt="MessageKaterina"
                />
            </Box>
        </Box>
    );
};

export default memo(StateThree);
