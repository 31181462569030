import React, { memo } from "react";
import * as themes from "./SnackbarAddCardThemes";
import * as textThemes from "themes/TextThemes";
import { Box, Snackbar, Typography } from "@mui/material";
import Payment from "assets/PAAssets/SVG/payment";
import SButton from "components/UI/SButton";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import CloseIcon from "assets/TeacherAccountAssets/SVG/CloseIcon";
import { useNavigate } from "react-router-dom";
import TeacherSettingsStore from "store/TeacherSettingsStore";

const SnackbarAddCard = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const handleClickAddCard = () => {
        navigate("/settings");
        TeacherSettingsStore.setSelectedTab(2);
        TeacherSettingsStore.setOpenDialogAddCard(true);
    };

    const handleClickCloseSnackBar = () => {
        TeacherSettingsStore.setSnackBarClose();
    };

    return (
        <Snackbar open={TeacherSettingsStore.snackbarAddCard}>
            <Box sx={themes.Snackbar}>
                <Box sx={themes.SnackbarContent}>
                    <Box>
                        <Payment />
                    </Box>
                    <Typography sx={textThemes.Body2Black}>{t("teacherAccount.snackbarAddCard.addCardText")}</Typography>
                    <SButton
                        variant="icon"
                        style={themes.CloseIcon}
                        onClick={handleClickCloseSnackBar}
                    >
                        <CloseIcon />
                    </SButton>
                </Box>
                <SButton
                    style={themes.AddCardButton}
                    onClick={handleClickAddCard}
                >
                    {t("teacherAccount.snackbarAddCard.addButton")}
                </SButton>
            </Box>
        </Snackbar>
    );
});

export default memo(SnackbarAddCard);
