import React, { useState } from "react";

import { Stack, Typography } from "@mui/material";

import StyledWhiteBox from "components/DiplomaChats/StyledComponents/StyledWhiteBox";
import CustomStar from "components/DiplomaChats/Icons/CustomStar";
import StyledRating from "components/DiplomaChats/StyledComponents/StyledRating";
import Review from "./Review";

const EvaluateWork = () => {
    const [rating, setRating] = useState(null);

    const handleCancelRating = () => {
        setRating(null);
    };

    const handleChangeRating = (e) => {
        setRating(parseInt(e.target.value));
    };

    return (
        <StyledWhiteBox padding="30px 30px 20px 30px">
            <Stack
                direction="column"
                spacing={2.5}
            >
                <Stack
                    direction="column"
                    spacing={2}
                    alignItems="center"
                    padding="0px 23px"
                >
                    <Typography variant="title">Оцените, пожалуйста, работу специалиста</Typography>
                    <StyledRating
                        icon={<CustomStar empty={false} />}
                        emptyIcon={<CustomStar />}
                        value={rating}
                        onChange={handleChangeRating}
                    />
                </Stack>
                {rating && (
                    <Review
                        rating={rating}
                        cancelRating={handleCancelRating}
                    />
                )}
            </Stack>
        </StyledWhiteBox>
    );
};

export default EvaluateWork;
