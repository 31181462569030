import React from "react";

import { Stack, Typography } from "@mui/material";

import StarsCount from "./StarsCount";

import { containerLayout } from "pages/DiplomaChats/styles";
import { HeadlineH5Black, Body2Black } from "themes/TextThemes";

const YourGrade = ({ stars, comment }) => {
    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignContent="center"
            padding="20px 30px"
            sx={containerLayout}
            spacing={2.5}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignContent="center"
            >
                <Typography sx={HeadlineH5Black}>Ваша оценка</Typography>
                <StarsCount starsCount={stars} />
            </Stack>
            {comment ? <Typography sx={Body2Black}>{comment}</Typography> : null}
        </Stack>
    );
};

export default YourGrade;
