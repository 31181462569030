export const lessonCard = {
    boxSizing: "border-box",
    display: "flex",
    gap: "13px",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "80px",
    padding: "10px 3px  10px 7px",
};

export const lessonCompleted = {
    background: "rgba(246, 252, 246, 1)",
    borderLeft: "solid 3px rgba(50, 160, 113, 1)",
};

export const lessonCanceled = {
    background: "rgba(255, 245, 245, 1)",
    borderLeft: "solid 3px rgba(225, 38, 38, 1)",
};

export const lessonUpcoming = {
    background: "rgba(242, 243, 255, 1)",
    borderLeft: "solid 3px rgba(99, 105, 241, 1)",
};

export const ClassCardTopContentWrapper = {
    display: "flex",
    gap: "8px",
    alignItems: "center",
};

export const classCardAvatar = {
    width: "24px",
    height: "24px",
    borderRadius: "36px",
};

export const timeTextPos = {
    display: "flex",
    marginTop: "3px",
};

export const NameBlock = {
    display: "flex",
    alignItems: "center",
    gap: "4px",
};
