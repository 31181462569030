import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { memo } from "react";
import React from "react";
import { faculties, universities } from "API/dictionaries/const_data";
import QuestionnaireButtons from "components/TeacherQuestionnaireBodyBlock/Components/QuestionnaireButtons";
import store from "store/TeacherQuestionnaireStore";
import { observer } from "mobx-react-lite";
import SBAutoComplete from "components/UI/Autocomplete";
import STextField from "components/UI/STextField";
import SDropDown from "components/UI/SDropDown";
import IOSSwitch from "components/UI/IOSSwitch";
import FormControlLabel from "@mui/material/FormControlLabel";
import SDragAndDrop from "components/TeacherQuestionnaireBodyBlock/Components/SDragNDrop";
import DeleteStatementIcon from "assets/TeacherQuestionnaireAssets/DeleteStatementIcon";
import STooltip from "components/TeacherQuestionnaireBodyBlock/Components/STooltip";
import { Box, Typography } from "@mui/material";
import * as themes from "./TeacherEducationStepThemes";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useMedias } from "API/mediasHook";
import universitiesWithFaculties from "../../../../API/dictionaries/universitiesWithFaculties";

const TeacherEducationStep = observer(({ currentStep, setCurrentStep }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const disabledContinue = store.teacher.education.some(
        (item) =>
            !item.university ||
            !item.faculty ||
            !item.speciality ||
            !item.photo.imageSrc ||
            item.beginYear === "" ||
            (item.stillStudying === false && item.endYear === "") ||
            (item.stillStudying === false && item.endYear < item.beginYear),
    );

    const setEducationData = (index, key, value, additionalKey = null) => {
        if (additionalKey === null) {
            store.teacher.education[index][key] = value;
        } else {
            store.teacher.education[index][key][additionalKey] = value;
        }
    };

    const handleClickAdd = () => {
        store.teacher.education.push({
            id: store.teacher.education.length + 1,
            university: "",
            faculty: "",
            speciality: "",
            beginYear: "",
            endYear: "",
            stillStudying: false,
            photo: { imageSize: "", imageName: "", imageSrc: "" },
        });
    };

    return (
        <ScrollToTop>
            <Box sx={themes.TeacherPersonalDataStepWider}>
                <Box sx={themes.TeacherPersonalData}>
                    <Box sx={themes.TeacherQuestionnaireTopBlock}>
                        <Typography sx={textThemes.HeadlineH41Black}>{t("TeacherQuestionnaire.tellAboutYourEducation")}</Typography>
                        <Box sx={themes.TeacherQuestionnaireSubTitle}>
                            <Typography sx={textThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.ifYouHaveMultipleDegrees")}</Typography>
                        </Box>
                    </Box>
                    <Box sx={themes.AdditionalBlocks}>
                        {store.teacher.education.map((educationExp, index) => {
                            return (
                                <Box
                                    key={index}
                                    sx={themes.TeacherQuestionnaireEducationOuterContainerWithDelete}
                                >
                                    <Box sx={themes.TeacherQuestionnaireEducationOuterContainerWithoutDelete(medias)}>
                                        <Box sx={themes.TeacherQuestionnaireEducationOuterContainer}>
                                            <Box sx={themes.TeacherQuestionnaireDropDownEdit(medias)}>
                                                <Box sx={themes.TeacherQuestionnaireDropDownTitle}>
                                                    <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.university")}</Typography>
                                                </Box>
                                                <Box sx={themes.TeacherQuestionnaireAutocompleteContainer(medias)}>
                                                    <SBAutoComplete
                                                        marginBottom="0px"
                                                        value={store.teacher.education[index].university}
                                                        changefunc={(value) => setEducationData(index, "university", value)}
                                                        inputvalue={store.teacher.education[index].university}
                                                        inputchangefunc={(value) => setEducationData(index, "university", value)}
                                                        list={Object.keys(universitiesWithFaculties)}
                                                        error={!store.teacher.education[index].university}
                                                        placeholder={t("TeacherQuestionnaire.enterUniversity")}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box sx={themes.TeacherQuestionnaireDropDownEdit(medias)}>
                                                <Box sx={themes.TeacherQuestionnaireDropDownTitle}>
                                                    <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.faculty")}</Typography>
                                                </Box>
                                                <Box sx={themes.TeacherQuestionnaireAutocompleteContainer(medias)}>
                                                    <SBAutoComplete
                                                        marginBottom="0px"
                                                        value={store.teacher.education[index].faculty}
                                                        changefunc={(value) => setEducationData(index, "faculty", value)}
                                                        inputvalue={store.teacher.education[index].faculty}
                                                        inputchangefunc={(value) => setEducationData(index, "faculty", value)}
                                                        list={universitiesWithFaculties[store.teacher.education[index].university] || []}
                                                        error={!store.teacher.education[index].faculty}
                                                        placeholder={t("TeacherQuestionnaire.enterFaculty")}
                                                    />
                                                    <Typography sx={{ ...textThemes.Body2DarkGrey, ...themes.HelperText }}>{t("TeacherQuestionnaire.didntFoundUniversityOrFaculty")}</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={themes.TeacherQuestionnaireDropDownEdit(medias)}>
                                                <Box sx={themes.TeacherQuestionnaireDropDownTitle}>
                                                    <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.speciality")}</Typography>
                                                </Box>
                                                <Box sx={themes.TeacherQuestionnaireAutocompleteContainer(medias)}>
                                                    <STextField
                                                        width={medias.sm ? "424px" : "100%"}
                                                        placeholder={t("TeacherQuestionnaire.enterSpeciality")}
                                                        value={store.teacher.education[index].speciality}
                                                        changeFunction={(value) => setEducationData(index, "speciality", value)}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Box sx={themes.TeacherQuestionnaireDropDownEdit(medias)}>
                                                    <Box sx={themes.TeacherQuestionnaireDropDownTitle}>
                                                        <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.yearOfCommencement")}</Typography>
                                                    </Box>
                                                    <Box sx={themes.TeacherQuestionnaireAutocompleteContainer(medias)}>
                                                        <SDropDown
                                                            tr={true}
                                                            placeholder={t("main.year")}
                                                            firstValueGrey={false}
                                                            dropDownChoice={store.teacher.education[index].beginYear}
                                                            setDropDownChoice={(value) => setEducationData(index, "beginYear", value)}
                                                            dropDownList={store.yearsList}
                                                            buttonWidth={medias.sm ? "424px" : "100%"}
                                                            paperWidth={medias.sm ? "424px" : "100%"}
                                                        />
                                                    </Box>
                                                </Box>
                                                {store.teacher.education[index].stillStudying === false ? (
                                                    <Box
                                                        sx={themes.TeacherQuestionnaireDropDownEdit(medias)}
                                                        style={{ marginTop: "30px" }}
                                                    >
                                                        <Box sx={themes.TeacherQuestionnaireDropDownTitle}>
                                                            <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.yearOfGraduation")}</Typography>
                                                        </Box>
                                                        <Box sx={themes.TeacherQuestionnaireAutocompleteContainer(medias)}>
                                                            <SDropDown
                                                                tr={true}
                                                                placeholder={t("main.year")}
                                                                firstValueGrey={false}
                                                                dropDownChoice={store.teacher.education[index].endYear}
                                                                setDropDownChoice={(value) => setEducationData(index, "endYear", value)}
                                                                dropDownList={store.yearsList}
                                                                buttonWidth={medias.sm ? "424px" : "100%"}
                                                                paperWidth={medias.sm ? "424px" : "100%"}
                                                            />
                                                        </Box>
                                                    </Box>
                                                ) : null}
                                                <Box sx={themes.TeacherQuestionnaireStillStudying(medias)}>
                                                    <FormControlLabel
                                                        sx={themes.FormControlLabel}
                                                        control={
                                                            <IOSSwitch
                                                                sx={{ m: 1 }}
                                                                onChange={() => {
                                                                    setEducationData(index, "stillStudying", !store.teacher.education[index].stillStudying);
                                                                }}
                                                                checked={store.teacher.education[index].stillStudying}
                                                            />
                                                        }
                                                    />
                                                    <Box sx={themes.TeacherQuestionnaireDropDownTitle}>
                                                        <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.studyingHereNow")}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box sx={themes.TeacherQuestionnaireDropDownEdit(medias)}>
                                                <Box sx={themes.PaddingBlock(medias)}>
                                                    <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.photoOfDocument")}</Typography>
                                                </Box>
                                                <Box sx={themes.TeacherQuestionnaireAutocompleteContainer(medias)}>
                                                    <SDragAndDrop
                                                        image={store.teacher.education[index].photo.imageSrc}
                                                        setImage={(value) => setEducationData(index, "photo", value, "imageSrc")}
                                                        imageName={store.teacher.education[index].photo.imageName}
                                                        setImageName={(value) => setEducationData(index, "photo", value, "imageName")}
                                                        imageSize={store.teacher.education[index].photo.imageSize}
                                                        setImageSize={(value) => setEducationData(index, "photo", value, "imageSize")}
                                                    />
                                                    <Box sx={themes.TeacherQuestionnaireDragNDropDescription}>
                                                        <Typography sx={textThemes.Body2DarkGrey}>
                                                            {store.teacher.education[index].photo.imageSrc === null || store.teacher.education[index].photo.imageSrc === ""
                                                                ? t("teacherSettings.questionnaire.uploadPhotoScan")
                                                                : t("teacherSettings.questionnaire.photoSecured")}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        {!medias.sm ? (
                                            <>
                                                {store.teacher.education.length > 1 && (
                                                    <Button
                                                        sx={themes.TextButtonDelete}
                                                        disableRipple
                                                        variant="text"
                                                        onClick={() => {
                                                            store.teacher.education.splice(index, 1);
                                                        }}
                                                    >
                                                        {t("main.delete")}
                                                    </Button>
                                                )}
                                            </>
                                        ) : null}
                                        <Box>
                                            {store.teacher.education.length > 1 && index + 1 !== store.teacher.education.length ? <hr style={themes.TeacherQuestionnaireAddFieldSeparator} /> : null}
                                        </Box>
                                    </Box>
                                    {medias.sm && (
                                        <>
                                            {store.teacher.education.length > 1 ? (
                                                <STooltip
                                                    title={t("main.delete")}
                                                    PopperProps={themes.TooltipPopperProps}
                                                >
                                                    <Box
                                                        sx={themes.TeacherQuestionnaireDeleteStatementButton}
                                                        onClick={() => {
                                                            store.teacher.education.splice(index, 1);
                                                        }}
                                                    >
                                                        <DeleteStatementIcon />
                                                    </Box>
                                                </STooltip>
                                            ) : null}
                                        </>
                                    )}
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
                <Button
                    disableRipple
                    sx={themes.TeacherQuestionnaireAddFieldsButton}
                    onClick={handleClickAdd}
                >
                    {t("TeacherQuestionnaire.addPlaceOfStudy")}
                </Button>
                <QuestionnaireButtons
                    disabled={disabledContinue}
                    setCurrentStep={setCurrentStep}
                    currentStep={currentStep}
                />
            </Box>
        </ScrollToTop>
    );
});

export default memo(TeacherEducationStep);
