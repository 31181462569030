import React from "react";

const EmptyNotificationsIcon = function ({ children, ...props }) {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 512 512"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_229_5006)">
                <path
                    d="M1 378.469C1.66513 377.556 2.52137 377.252 2.96517 376.646C6.52345 371.786 9.42323 366.291 13.618 362.08C25.7039 349.947 37.409 337.674 45.9279 322.569C54.3845 307.575 59.6278 291.558 62.8878 274.811C63.7298 270.486 63.8944 265.985 63.9608 261.559C64.3723 234.147 64.1603 206.715 65.1984 179.33C65.7813 163.952 69.8458 148.998 76.0565 134.808C84.7289 114.994 96.7165 97.4849 112.699 82.7837C129.954 66.9112 149.811 55.4999 172.323 49.1824C181.792 46.5249 191.738 45.4513 201.526 44.0792C207.839 43.1942 214.259 43.0749 221.67 42.5371C218.205 49.1063 215.156 54.5777 212.39 60.1883C203.209 78.8057 196.484 98.2191 195.082 119.072C194.272 131.125 193.143 143.347 194.247 155.3C196.606 180.841 203.753 205.018 216.693 227.583C226.596 244.851 238.58 260.21 253.155 273.495C267.734 286.785 284.166 297.309 302.395 305.268C317.505 311.865 333.148 316.215 349.25 318.771C358.198 320.192 367.455 319.905 376.572 319.898C380.292 319.895 382.131 321.137 384.195 324.209C389.808 332.563 395.708 340.783 402.164 348.494C408.342 355.873 416.398 361.831 421.811 369.668C435.811 389.938 426.284 418.534 402.074 426.354C398.72 427.437 395.03 427.917 391.492 427.931C361.338 428.054 331.183 428.005 300.092 428.015C242.402 428.034 185.648 428.045 128.433 428.032C98.8386 428.005 69.7048 427.84 40.5739 428.062C23.4753 428.193 11.1217 421.243 3.86512 405.559C3.37161 404.493 1.97605 403.843 1.00001 403C1 394.979 1 386.958 1 378.469Z"
                    fill="#5B12FF"
                />
                <path
                    d="M513 162.531C512.339 163.558 511.196 164.035 511.088 164.685C507.276 187.697 497.73 208.117 483.224 226.255C462.648 251.981 436.164 268.67 404.095 275.695C392.638 278.205 380.518 279.297 368.81 278.741C339.096 277.331 312.209 267.599 288.422 249.132C269.381 234.348 255.651 215.739 246.145 193.974C240.331 180.664 237.295 166.55 235.793 151.928C233.613 130.709 236.924 110.494 244.028 90.7209C250.059 73.9325 259.308 59.0506 271.492 45.9446C283.471 33.0591 296.998 22.2196 312.889 14.663C324.649 9.07084 336.83 4.44099 349.935 2.88459C350.481 2.81973 350.955 2.14479 351.231 1.37712C366.354 1.00016 381.708 1.00016 397.301 1.37197C398.376 2.15938 399.175 2.70332 400.057 2.97093C410.129 6.02522 420.606 8.12107 430.214 12.245C452.586 21.847 471.229 36.5919 485.757 56.2802C497.592 72.3194 506.045 89.9108 510.099 109.538C510.565 111.795 512.009 113.851 513 116C513 131.354 513 146.709 513 162.531ZM372.602 201.153C382.808 207.616 395.148 197.382 395.077 187.884C394.864 159.387 394.789 130.883 395.148 102.388C395.239 95.1797 386.651 86.4983 379.842 86.8796C371.036 87.3726 362.182 86.9792 353.349 87.0061C346.014 87.0285 339.157 92.1469 338.03 98.3785C336.465 107.041 340.49 114.708 348.598 116.862C353.417 118.142 358.606 118.029 364.001 118.58C364.001 120.383 364.001 122.183 364.001 123.984C364.001 144.15 364.166 164.319 363.911 184.482C363.822 191.54 366.031 196.997 372.602 201.153Z"
                    fill="#EBB46D"
                />
                <path
                    d="M196.469 513C195.217 512.332 194.535 511.295 193.636 511.047C163.509 502.744 143.154 483.966 132.83 454.434C129.954 446.206 128.455 437.734 128.94 428.515C185.648 428.046 242.402 428.034 299.63 428.041C299.889 453.565 290.225 474.95 271.387 491.961C260.929 501.405 248.782 508.127 234.774 511.044C233.904 511.225 233.142 511.929 232.665 512.695C220.979 513 208.958 513 196.469 513Z"
                    fill="#3F1A89"
                />
            </g>
            <defs>
                <clipPath id="clip0_229_5006">
                    <rect
                        width="512"
                        height="512"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default EmptyNotificationsIcon;
