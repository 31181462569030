import React from "react";
import * as TutorCardStyle from "./TutorCardStyle";
import { Box, Typography, Avatar } from "@mui/material";
import MainPageStore from "store/MainPageStore";
import { observer } from "mobx-react-lite";
import { useMedias } from "API/mediasHook";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const TutorCard = observer(({ tutor, disableClick = false, alwaysWhite = false }) => {
    const { t, i18n } = useTranslation();

    const setActiveTutor = () => {
        MainPageStore.SetActiveTutor(tutor);
    };

    const medias = useMedias();

    const cardStyle = alwaysWhite ? TutorCardStyle.CardAlwaysWhite(medias) : MainPageStore.activeTutor.ID === tutor.ID ? TutorCardStyle.CardActive(medias) : TutorCardStyle.CardInactive(medias);

    return (
        <Box
            style={cardStyle}
            onClick={disableClick ? undefined : () => setActiveTutor()}
        >
            <Avatar
                style={TutorCardStyle.Avatar}
                src={tutor.tutorPreviewPhoto}
                alt="Tutor Preview"
            />
            <Typography style={TutorCardStyle.CardContent}>
                <Typography
                    component="span"
                    style={TutorCardStyle.CardHeader}
                >
                    {t(tutor.tutorName)}
                </Typography>
                <Typography
                    component="span"
                    style={TutorCardStyle.CardDescribe}
                >
                    {t(tutor.tutorDescribe)}
                </Typography>
            </Typography>
        </Box>
    );
});

export default memo(TutorCard);
