export const TeacherField = {
    width: "764px",
    borderRadius: "20px 20px 0px 0px",
    borderBottom: "1px solid #DADADF",
    backgroundColor: "#FFF",
    padding: "21px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
};

export const MenuItemFirst = {
    paddingLeft: "10px",
    borderRadius: "1vh",
    height: "4.12vh",
    fontSize: "1.7vh",
    ":hover": {
        color: "black",
    },
};

export const TextStyles = {
    width: "fit-content",
    "& fieldset": { border: "none" },
    "& .MuiOutlinedInput-input": {
        padding: 0,
        height: "24px",
    },
    "& .MuiOutlinedInput-root": {
        padding: "8px 12px",
        backgroundColor: "#F3F3F3",
        borderRadius: "10px",
        width: "470px",
        lineHeight: "150%",
        fontSize: "14px",
        fontWeight: 500,
    },
};

export const PaperStyles = {
    width: "11.7vw",
    backgroundColor: "white",
    borderRadius: "1.2vh",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
    border: "none",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
    p: 0,
};

export const MenuItem = {
    borderRadius: "1vh",
    height: "4.42vh",
    fontSize: "2vh",
    color: "red",
};

export const Folder = {
    fontSize: "2.2vh",
    textAlign: "center",
    marginTop: "1.28vh",
};

export const CreateOrEditFolder = {
    backgroundColor: "#fff",
    borderRadius: "20px",
    minHeight: "80vh",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const CreateEditContent = {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    alignItems: "center",
};
