import React from "react";
import store from "store/RequestStore";
import filterStore from "store/FilterStore";
import { StyLedSubmitButton } from "./RenewOrderStyled";
import { observer } from "mobx-react-lite";

const StepHiddenButtonWrapper = observer(({ children, submitRenew }) => {
    const doubleToggleDrawer = () => {
        if (store.edited === true) {
            store.setEdited();
        }
    };

    const handleClick = (event) => {
        submitRenew();
        if (store.currentValue !== "" || null) {
            filterStore.setText(filterStore.currentId, store.currentValue);
            doubleToggleDrawer();
            filterStore.setSubmitButtonFilter(true);
        }

        store.currentValue = "";
    };

    return (
        <>
            {!store.edited ? (
                <>{children}</>
            ) : (
                <StyLedSubmitButton
                    variant="contained"
                    disabled={filterStore.submitButtonFilter}
                    onClick={handleClick}
                >
                    Сохранить ответ
                </StyLedSubmitButton>
            )}
        </>
    );
});

export default StepHiddenButtonWrapper;
