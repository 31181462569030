import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import { Button, Dialog, IconButton } from "@mui/material";
import * as themes from "./ModalTemplatesThemes";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CloseRounded } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import ArrowBackShortIcon from "assets/CommonAssets/SVG/arrowBackShortSVG";
import TextField from "@mui/material/TextField";
import OrderStore from "store/OrderStore";

const ModalTemplates = observer(({ children, ...props }) => {
    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        props.handleSave();
    };

    const processingTemplateTitle = (event) => {
        OrderStore.SetTemplateTitle(event.target.value);
    };

    const processingTemplateText = (event) => {
        OrderStore.SetTemplateText(event.target.value);
    };

    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={props.open}
                onClose={Close}
            >
                <Box sx={themes.dialogContainerStyle}>
                    <ThemeProvider theme={themes.CloseIconTheme}>
                        <IconButton
                            onClick={Close}
                            disableRipple
                        >
                            <CloseRounded></CloseRounded>
                        </IconButton>
                    </ThemeProvider>
                    <Box sx={themes.cardContainerStyle}>
                        <Box sx={themes.headerBlockContainerStyle}>
                            <ThemeProvider theme={themes.GoBackIconTheme}>
                                <IconButton
                                    onClick={Close}
                                    disableRipple
                                >
                                    <ArrowBackShortIcon></ArrowBackShortIcon>
                                </IconButton>
                            </ThemeProvider>
                            <Typography sx={themes.headerTextStyle}>Создать новый шаблон</Typography>
                        </Box>
                        <Box sx={themes.contentBlockContainerStyle}>
                            <Box sx={themes.templateTitleContainerStyle}>
                                <Typography sx={themes.templateLabelStyle}>Название</Typography>
                                <ThemeProvider theme={themes.TemplateTitleTheme}>
                                    <TextField
                                        variant={"standard"}
                                        placeholder={"Введите название нового шаблона"}
                                        value={OrderStore.currentTemplate.TemplateTitle}
                                        onChange={processingTemplateTitle}
                                    />
                                </ThemeProvider>
                            </Box>
                            <Box sx={themes.templateTextContainerStyle}>
                                <Typography sx={themes.templateLabelStyle}>Текст отклика</Typography>
                                <ThemeProvider theme={themes.TemplateTextTheme(OrderStore.currentTemplate.TemplateText?.length === 1000)}>
                                    <TextField
                                        onChange={processingTemplateText}
                                        value={OrderStore.currentTemplate.TemplateText}
                                        variant="outlined"
                                        placeholder={"Напишите о своих сильных сторонах, опыте, это поможет выделиться среди других и заинтересовать ученика"}
                                        multiline
                                        rows={11}
                                        inputProps={{
                                            maxLength: 1000,
                                        }}
                                        FormHelperTextProps={{ component: "div" }}
                                        helperText={
                                            <Box sx={themes.helperContainerStyle(OrderStore.currentTemplate.TemplateText?.length === 1000)}>
                                                {OrderStore.currentTemplate.TemplateText?.length === 1000 ? <Typography sx={themes.currLengthTextMaxStyle}>Достигнут лимит символов</Typography> : null}
                                                <Box sx={themes.helperCounterContainerStyle}>
                                                    <Typography sx={themes.currLengthTextStyle(OrderStore.currentTemplate.TemplateText?.length)}>
                                                        {OrderStore.currentTemplate.TemplateText?.length}
                                                    </Typography>
                                                    <Typography sx={themes.maxLengthTextStyle}>/1000</Typography>
                                                </Box>
                                            </Box>
                                        }
                                    ></TextField>
                                </ThemeProvider>
                            </Box>
                        </Box>
                        <Box sx={themes.actionsBlockContainerStyle}>
                            <Box sx={themes.actionsContainerStyle}>
                                <ThemeProvider theme={themes.ModalActionButtonTheme(0)}>
                                    <Button
                                        onClick={Close}
                                        disableRipple
                                    >
                                        <Typography style={themes.buttonTextStyle(0)}>Отменить</Typography>
                                    </Button>
                                </ThemeProvider>
                                <ThemeProvider theme={themes.ModalActionButtonTheme(1)}>
                                    <Button
                                        onClick={Save}
                                        disableRipple
                                    >
                                        <Typography style={themes.buttonTextStyle(1)}>Сохранить</Typography>
                                    </Button>
                                </ThemeProvider>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </ThemeProvider>
    );
});

export default memo(ModalTemplates);
