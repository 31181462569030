import { VerifiedUser } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React from "react";

import * as styles from "pages/DiplomaChats/styles";

const OrderConditions = () => {
    const conditions = [
        { id: 1, text: "Мы зарезервируем 1 500 ₽" },
        { id: 2, text: "Репетитор получит оплату после проведения занятия" },
        {
            id: 3,
            text: "Если репетитор не провёл занятие, деньги вернутся вам",
        },
    ];

    return (
        <Stack
            direction="column"
            spacing={0.75}
            sx={styles.orderConditionsContainer}
        >
            {conditions.map((cond) => (
                <Stack
                    direction="row"
                    key={cond.id}
                    spacing={1}
                >
                    <VerifiedUser sx={styles.verifiedGray} />
                    <Typography variant="conditions">{cond.text}</Typography>
                </Stack>
            ))}
        </Stack>
    );
};

export default OrderConditions;
