import React, { memo, useState } from "react";
import * as themes from "./SubjectBlockThemes";
import { observer } from "mobx-react-lite";
import { Chip, IconButton, Menu, Typography } from "@mui/material";
import { useMedias } from "../../../../../API/mediasHook";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import Subjects from "./Subjects/Subjects";
import Locations from "./Locations/Locations";
import Educations from "./Educations/Educations";
import Experience from "./Experience/Experience";

const SubjectBlock = observer(({ children, ...props }) => {
    const medias = useMedias();

    console.log(props.styleMode);

    return (
        <Box sx={themes.subjectBlockContainerStyle(medias, props.styleMode ? props.styleMode : 0)}>
            <Subjects
                value={props.value}
                styleMode={props.styleMode}
            ></Subjects>
            <Locations
                value={props.value}
                styleMode={props.styleMode}
            ></Locations>

            {props.styleMode === 1 && (
                <>
                    <Educations
                        value={props.value}
                        styleMode={props.styleMode}
                    />
                    <Experience
                        value={props.value}
                        styleMode={props.styleMode}
                    />
                </>
            )}
            <Box sx={themes.educationBlockContainerStyle(props.styleMode ? props.styleMode : 0)}>
                {props.styleMode === 0 && (
                    <>
                        <Educations
                            value={props.value}
                            styleMode={props.styleMode}
                        />
                        <Experience
                            value={props.value}
                            styleMode={props.styleMode}
                        />
                    </>
                )}
            </Box>
        </Box>
    );
});

export default memo(SubjectBlock);
