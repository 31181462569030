const AdviceIcon3 = function (props) {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="18"
                cy="18"
                r="18"
                fill="#6212FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.8741 18.947C21.2885 18.1088 21.5 17.3635 21.5 16.75C21.5 15.1938 21.042 14.3194 20.5108 13.8224C19.96 13.3072 19.1192 13 18 13C16.8808 13 16.04 13.3072 15.4892 13.8224C14.958 14.3194 14.5 15.1938 14.5 16.75C14.5 17.3635 14.7115 18.1088 15.1259 18.947C15.5342 19.7729 16.0944 20.5989 16.6811 21.3483C17.1428 21.938 17.607 22.4625 18 22.8814C18.393 22.4625 18.8572 21.938 19.3189 21.3483C19.9056 20.5989 20.4658 19.7729 20.8741 18.947ZM18 18C18.9665 18 19.75 17.2165 19.75 16.25C19.75 15.2835 18.9665 14.5 18 14.5C17.0335 14.5 16.25 15.2835 16.25 16.25C16.25 17.2165 17.0335 18 18 18Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28 18C28 23.5228 23.5228 28 18 28C12.4772 28 8 23.5228 8 18C8 12.4772 12.4772 8 18 8C23.5228 8 28 12.4772 28 18ZM18 25C18 25 23 20.4779 23 16.75C23 13.0221 20.7614 11.5 18 11.5C15.2386 11.5 13 13.0221 13 16.75C13 20.4779 18 25 18 25Z"
                fill="white"
            />
        </svg>
    );
};

export default AdviceIcon3;
