import React from "react";
import OrderResponseTutorsOffers from "./OrderResponseTutorsWrapper/ResponseCard/OrderResponseTutorsOffers/OrderResponseTutorsOffers";
import OrderResponseTutorsCatalog from "./OrderResponseTutorsWrapper/OrderResponseTutorsCatalog/OrderResponseTutorsCatalog";
import OrderResponeTutorsResponse from "./OrderResponseTutorsWrapper/OrderResponeTutorsResponses/OrderResponeTutorsResponses";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import Box from "@mui/material/Box";
const theme = createTheme({
    spacing: 1,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const OrderResponseTutorsWrapper = ({ value }) => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <Box>
                    {value === 0 && <OrderResponeTutorsResponse />}
                    {value === 1 && <OrderResponseTutorsOffers page={value} />}
                    {value === 2 && <OrderResponseTutorsCatalog />}
                </Box>
            </ThemeProvider>
        </>
    );
};

export default OrderResponseTutorsWrapper;
