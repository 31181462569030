/**
 * @fileoverview gRPC-Web generated client stub for pb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.6.1
// source: app/proto/auth.proto

/* eslint-disable */
// @ts-nocheck

const grpc = {};
grpc.web = require("grpc-web");

const proto = {};
proto.pb = require("./auth_pb.js");

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.AuthServiceClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options.format = "text";

    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */
    this.client_ = new grpc.web.GrpcWebClientBase(options);

    /**
     * @private @const {string} The hostname
     */
    this.hostname_ = hostname.replace(/\/+$/, "");
};

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.AuthServicePromiseClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options.format = "text";

    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */
    this.client_ = new grpc.web.GrpcWebClientBase(options);

    /**
     * @private @const {string} The hostname
     */
    this.hostname_ = hostname.replace(/\/+$/, "");
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.RegisterRequest,
 *   !proto.pb.RegisterResponse>}
 */
const methodDescriptor_AuthService_Register = new grpc.web.MethodDescriptor(
    "/pb.AuthService/Register",
    grpc.web.MethodType.UNARY,
    proto.pb.RegisterRequest,
    proto.pb.RegisterResponse,
    /**
     * @param {!proto.pb.RegisterRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.RegisterResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.RegisterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.RegisterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AuthServiceClient.prototype.register = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.AuthService/Register", request, metadata || {}, methodDescriptor_AuthService_Register, callback);
};

/**
 * @param {!proto.pb.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.RegisterResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AuthServicePromiseClient.prototype.register = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AuthService/Register", request, metadata || {}, methodDescriptor_AuthService_Register);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.RegisterSmsVerifyRequest,
 *   !proto.pb.RegisterSmsVerifyResponse>}
 */
const methodDescriptor_AuthService_RegisterSmsVerify = new grpc.web.MethodDescriptor(
    "/pb.AuthService/RegisterSmsVerify",
    grpc.web.MethodType.UNARY,
    proto.pb.RegisterSmsVerifyRequest,
    proto.pb.RegisterSmsVerifyResponse,
    /**
     * @param {!proto.pb.RegisterSmsVerifyRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.RegisterSmsVerifyResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.RegisterSmsVerifyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.RegisterSmsVerifyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.RegisterSmsVerifyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AuthServiceClient.prototype.registerSmsVerify = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.AuthService/RegisterSmsVerify", request, metadata || {}, methodDescriptor_AuthService_RegisterSmsVerify, callback);
};

/**
 * @param {!proto.pb.RegisterSmsVerifyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.RegisterSmsVerifyResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AuthServicePromiseClient.prototype.registerSmsVerify = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AuthService/RegisterSmsVerify", request, metadata || {}, methodDescriptor_AuthService_RegisterSmsVerify);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.LoginSmsRequest,
 *   !proto.pb.LoginSmsResponse>}
 */
const methodDescriptor_AuthService_LoginSms = new grpc.web.MethodDescriptor(
    "/pb.AuthService/LoginSms",
    grpc.web.MethodType.UNARY,
    proto.pb.LoginSmsRequest,
    proto.pb.LoginSmsResponse,
    /**
     * @param {!proto.pb.LoginSmsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.LoginSmsResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.LoginSmsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.LoginSmsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.LoginSmsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AuthServiceClient.prototype.loginSms = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.AuthService/LoginSms", request, metadata || {}, methodDescriptor_AuthService_LoginSms, callback);
};

/**
 * @param {!proto.pb.LoginSmsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.LoginSmsResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AuthServicePromiseClient.prototype.loginSms = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AuthService/LoginSms", request, metadata || {}, methodDescriptor_AuthService_LoginSms);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.LoginSmsVerifyRequest,
 *   !proto.pb.LoginSmsVerifyResponse>}
 */
const methodDescriptor_AuthService_LoginSmsVerify = new grpc.web.MethodDescriptor(
    "/pb.AuthService/LoginSmsVerify",
    grpc.web.MethodType.UNARY,
    proto.pb.LoginSmsVerifyRequest,
    proto.pb.LoginSmsVerifyResponse,
    /**
     * @param {!proto.pb.LoginSmsVerifyRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.LoginSmsVerifyResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.LoginSmsVerifyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.LoginSmsVerifyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.LoginSmsVerifyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AuthServiceClient.prototype.loginSmsVerify = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.AuthService/LoginSmsVerify", request, metadata || {}, methodDescriptor_AuthService_LoginSmsVerify, callback);
};

/**
 * @param {!proto.pb.LoginSmsVerifyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.LoginSmsVerifyResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AuthServicePromiseClient.prototype.loginSmsVerify = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AuthService/LoginSmsVerify", request, metadata || {}, methodDescriptor_AuthService_LoginSmsVerify);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.LoginEmailRequest,
 *   !proto.pb.LoginEmailResponse>}
 */
const methodDescriptor_AuthService_LoginEmail = new grpc.web.MethodDescriptor(
    "/pb.AuthService/LoginEmail",
    grpc.web.MethodType.UNARY,
    proto.pb.LoginEmailRequest,
    proto.pb.LoginEmailResponse,
    /**
     * @param {!proto.pb.LoginEmailRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.LoginEmailResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.LoginEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.LoginEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.LoginEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AuthServiceClient.prototype.loginEmail = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.AuthService/LoginEmail", request, metadata || {}, methodDescriptor_AuthService_LoginEmail, callback);
};

/**
 * @param {!proto.pb.LoginEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.LoginEmailResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AuthServicePromiseClient.prototype.loginEmail = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AuthService/LoginEmail", request, metadata || {}, methodDescriptor_AuthService_LoginEmail);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.ValidateTokenRequest,
 *   !proto.pb.ValidateTokenResponse>}
 */
const methodDescriptor_AuthService_ValidateToken = new grpc.web.MethodDescriptor(
    "/pb.AuthService/ValidateToken",
    grpc.web.MethodType.UNARY,
    proto.pb.ValidateTokenRequest,
    proto.pb.ValidateTokenResponse,
    /**
     * @param {!proto.pb.ValidateTokenRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.ValidateTokenResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.ValidateTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.ValidateTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.ValidateTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AuthServiceClient.prototype.validateToken = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.AuthService/ValidateToken", request, metadata || {}, methodDescriptor_AuthService_ValidateToken, callback);
};

/**
 * @param {!proto.pb.ValidateTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.ValidateTokenResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AuthServicePromiseClient.prototype.validateToken = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AuthService/ValidateToken", request, metadata || {}, methodDescriptor_AuthService_ValidateToken);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.RefreshTokensRequest,
 *   !proto.pb.RefreshTokensResponse>}
 */
const methodDescriptor_AuthService_RefreshTokens = new grpc.web.MethodDescriptor(
    "/pb.AuthService/RefreshTokens",
    grpc.web.MethodType.UNARY,
    proto.pb.RefreshTokensRequest,
    proto.pb.RefreshTokensResponse,
    /**
     * @param {!proto.pb.RefreshTokensRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.RefreshTokensResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.RefreshTokensRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.RefreshTokensResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.RefreshTokensResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AuthServiceClient.prototype.refreshTokens = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.AuthService/RefreshTokens", request, metadata || {}, methodDescriptor_AuthService_RefreshTokens, callback);
};

/**
 * @param {!proto.pb.RefreshTokensRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.RefreshTokensResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AuthServicePromiseClient.prototype.refreshTokens = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AuthService/RefreshTokens", request, metadata || {}, methodDescriptor_AuthService_RefreshTokens);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.LogoutRequest,
 *   !proto.pb.LogoutResponse>}
 */
const methodDescriptor_AuthService_Logout = new grpc.web.MethodDescriptor(
    "/pb.AuthService/Logout",
    grpc.web.MethodType.UNARY,
    proto.pb.LogoutRequest,
    proto.pb.LogoutResponse,
    /**
     * @param {!proto.pb.LogoutRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.LogoutResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.LogoutRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.LogoutResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.LogoutResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AuthServiceClient.prototype.logout = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.AuthService/Logout", request, metadata || {}, methodDescriptor_AuthService_Logout, callback);
};

/**
 * @param {!proto.pb.LogoutRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.LogoutResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AuthServicePromiseClient.prototype.logout = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AuthService/Logout", request, metadata || {}, methodDescriptor_AuthService_Logout);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.RecoverPasswordRequest,
 *   !proto.pb.RecoverPasswordResponse>}
 */
const methodDescriptor_AuthService_RecoverPassword = new grpc.web.MethodDescriptor(
    "/pb.AuthService/RecoverPassword",
    grpc.web.MethodType.UNARY,
    proto.pb.RecoverPasswordRequest,
    proto.pb.RecoverPasswordResponse,
    /**
     * @param {!proto.pb.RecoverPasswordRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.RecoverPasswordResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.RecoverPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.RecoverPasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.RecoverPasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AuthServiceClient.prototype.recoverPassword = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.AuthService/RecoverPassword", request, metadata || {}, methodDescriptor_AuthService_RecoverPassword, callback);
};

/**
 * @param {!proto.pb.RecoverPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.RecoverPasswordResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AuthServicePromiseClient.prototype.recoverPassword = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AuthService/RecoverPassword", request, metadata || {}, methodDescriptor_AuthService_RecoverPassword);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.VerifyPasswordRecoveryRequest,
 *   !proto.pb.VerifyPasswordRecoveryResponse>}
 */
const methodDescriptor_AuthService_VerifyPasswordRecovery = new grpc.web.MethodDescriptor(
    "/pb.AuthService/VerifyPasswordRecovery",
    grpc.web.MethodType.UNARY,
    proto.pb.VerifyPasswordRecoveryRequest,
    proto.pb.VerifyPasswordRecoveryResponse,
    /**
     * @param {!proto.pb.VerifyPasswordRecoveryRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.VerifyPasswordRecoveryResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.VerifyPasswordRecoveryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.VerifyPasswordRecoveryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.VerifyPasswordRecoveryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.AuthServiceClient.prototype.verifyPasswordRecovery = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.AuthService/VerifyPasswordRecovery", request, metadata || {}, methodDescriptor_AuthService_VerifyPasswordRecovery, callback);
};

/**
 * @param {!proto.pb.VerifyPasswordRecoveryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.VerifyPasswordRecoveryResponse>}
 *     Promise that resolves to the response
 */
proto.pb.AuthServicePromiseClient.prototype.verifyPasswordRecovery = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.AuthService/VerifyPasswordRecovery", request, metadata || {}, methodDescriptor_AuthService_VerifyPasswordRecovery);
};

module.exports = proto.pb;
