import React from "react";

const IconSearchFilter = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.8264 15.8767C13.6861 16.7883 12.24 17.3333 10.6667 17.3333C6.98477 17.3333 4 14.3486 4 10.6667C4 6.98477 6.98477 4 10.6667 4C14.3486 4 17.3333 6.98477 17.3333 10.6667C17.3333 12.2405 16.7879 13.687 15.8759 14.8274C15.9049 14.849 15.9327 14.873 15.959 14.8994L18.8162 17.7565C19.1091 18.0494 19.1091 18.5243 18.8162 18.8172C18.5233 19.1101 18.0484 19.1101 17.7555 18.8172L14.8984 15.96C14.872 15.9336 14.848 15.9058 14.8264 15.8767ZM15.8333 10.6667C15.8333 13.5201 13.5201 15.8333 10.6667 15.8333C7.8132 15.8333 5.5 13.5201 5.5 10.6667C5.5 7.8132 7.8132 5.5 10.6667 5.5C13.5201 5.5 15.8333 7.8132 15.8333 10.6667Z"
                fill="#9E9EB0"
            />
        </svg>
    );
};

export default IconSearchFilter;
