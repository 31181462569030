import { Box, Typography } from "@mui/material";
import * as themes from "./TableHeaderThemes";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import { memo } from "react";
const TableHeader = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.TableHeader}>
            <Typography sx={textThemes.Button14BoldBlack}>№</Typography>
            <Box sx={themes.StudentBlock}>
                <Typography sx={textThemes.Button14BoldBlack}>{t("paidServices.student")}</Typography>
            </Box>
            <Box sx={themes.ServiceBlock}>
                <Typography sx={textThemes.Button14BoldBlack}>{t("paidServices.service")}</Typography>
            </Box>
            <Box sx={themes.PriceBlock}>
                <Typography sx={textThemes.Button14BoldBlack}>{t("paidServices.price")}</Typography>
            </Box>
            <Box sx={themes.CommissionBlock}>
                <Typography sx={textThemes.Button14BoldBlack}>{t("paidServices.commission")}</Typography>
            </Box>
            <Typography sx={textThemes.Button14BoldBlack}>{t("paidServices.total")}</Typography>
        </Box>
    );
};

export default memo(TableHeader);
