import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import React, { useEffect } from "react";
import SChipsGroup from "components/UI/ChipGroup/ChipGroup";
import aimsTypes from "API/dictionaries/aimsTypes";
import QuestionnaireButtons from "components/TeacherQuestionnaireBodyBlock/Components/QuestionnaireButtons";
import store from "store/TeacherQuestionnaireStore";
import { observer } from "mobx-react-lite";
import { Box, Typography } from "@mui/material";
import * as themes from "./ClassesAimsStepThemes";
import * as textThemes from "themes/TextThemes";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { useMedias } from "API/mediasHook";

const ClassesAimsStep = observer(({ currentStep, setCurrentStep }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const [aims, setAims] = React.useState([]);

    const [selectedSubject, setSelectedSubject] = React.useState("");

    useEffect(() => {
        const fetchAims = async () => {
            for (let subject of TeacherQuestionnaireStore.teacher.subjects) {
                await TeacherQuestionnaireStore.FetchConditionsBySubjectData(subject.subjectName);
            }
        };

        fetchAims();
    }, []);

    useEffect(() => {
        aims.forEach((newAim) => {
            const exists = TeacherQuestionnaireStore.teacher.subjects.find((someSubject) => someSubject.subjectName === selectedSubject).conditions.some((aim) => aim.description === newAim);

            if (!exists) {
                TeacherQuestionnaireStore.teacher.subjects
                    .find((someSubject) => someSubject.subjectName === selectedSubject)
                    .conditions.push({
                        type: aimsTypes[newAim],
                        description: newAim,
                        price: "",
                    });
            }
        });

        if (selectedSubject !== "") {
            TeacherQuestionnaireStore.setTutorAims(
                selectedSubject,
                TeacherQuestionnaireStore.teacher.subjects
                    .find((someSubject) => someSubject.subjectName === selectedSubject)
                    .conditions.filter((aim) => aims.some((newAim) => newAim === aim.description)),
            );
        }
    }, [aims]);

    function hasEmptyObjects() {
        return TeacherQuestionnaireStore.teacher.subjects.some((subject) => subject.conditions.length === 0);
    }

    return (
        <ScrollToTop>
            <Box sx={themes.TeacherPersonalDataStep}>
                <Box sx={themes.TeacherPersonalData}>
                    <Box sx={themes.TeacherQuestionnaireTopBlock}>
                        <Typography sx={textThemes.HeadlineH41Black}>{t("TeacherQuestionnaire.forWhatPurposes")}</Typography>
                        <Typography sx={textThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.tellUsWhatGoals")}</Typography>
                    </Box>
                    <Box sx={themes.TeacherQuestionnaireClassesAimsOuterContainer}>
                        {store.teacher.subjects.map((subject, index) => {
                            return (
                                <Box
                                    key={subject.subjectName}
                                    sx={themes.ClassesAimsContainer}
                                >
                                    <Box sx={themes.TeacherQuestionnaireClassesAimsTitle}>{subject.subjectName}</Box>
                                    <Box sx={themes.ChipsContainer(TeacherQuestionnaireStore.teacher.subjects.find((someSubject) => someSubject === subject).conditions.length)}>
                                        <SChipsGroup
                                            additionalAction={() => setSelectedSubject(subject.subjectName)}
                                            width="100%"
                                            chipData={subject.conditions.map((item) => item.description)}
                                            setChipData={setAims}
                                            list={(TeacherQuestionnaireStore.conditionsBySubjectObject[subject.subjectName] || []).map((cond) => cond.Condition)}
                                            error={!subject.conditions.length}
                                            placeholder={t("TeacherQuestionnaire.forExamplePreparationForSAT")}
                                        />
                                        <Typography sx={{ ...textThemes.Body2DarkGrey, ...themes.HelperText }}>{t("TeacherQuestionnaire.didntFoundPurpose")}</Typography>
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>

                <QuestionnaireButtons
                    disabled={hasEmptyObjects()}
                    setCurrentStep={setCurrentStep}
                    currentStep={currentStep}
                    width={medias.sm ? "" : "100%"}
                />
            </Box>
        </ScrollToTop>
    );
});

export default memo(ClassesAimsStep);
