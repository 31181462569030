import React from "react";
import Bonus from "assets/PAAssets/SVG/bonus";
import Information from "assets/PAAssets/SVG/information";
import { Tooltip, Box, Typography } from "@mui/material";
import * as styles from "./BonusBoxThemes";
import * as textStyles from "themes/TextThemes";
import { memo } from "react";
import UserStore from "store/UserStore";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";

const BonusBox = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <Box sx={styles.BonusBox(medias)}>
            <Box sx={styles.BonusOuterPanel}>
                <Box sx={styles.BonusInnerPanel}>
                    <Box sx={styles.BonusTitle}>
                        <Typography sx={textStyles.HeadlineH4Black}>{t("main.balance.bonusAccount")}</Typography>
                        <Bonus />
                    </Box>
                    <Box sx={styles.BonusTitle}>
                        <Typography sx={textStyles.Body2Black}>{t("main.balance.cashbackPoints")}</Typography>
                        <Tooltip
                            PopperProps={styles.TooltipPopperProps}
                            title={t("main.balance.cashbackCondition")}
                        >
                            <button style={styles.TooltipButton}>
                                <Information />
                            </button>
                        </Tooltip>
                    </Box>
                </Box>
                <Box sx={styles.BonusContainer}>
                    <Box sx={styles.BonusText}>
                        {UserStore.bonuses} {UserStore.bonuses === 1 ? t("main.points_one") : UserStore.bonuses < 5 ? t("main.points_few") : t("main.points_many")}
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.BonusLowerPanel}>
                <Typography sx={textStyles.Body2DarkGrey}>
                    {t("main.balance.canPayPart1")}
                    <span style={styles.PercentageText}>10%</span>
                    {t("main.balance.canPayPart2")}
                </Typography>
            </Box>
        </Box>
    );
});

export default memo(BonusBox);
