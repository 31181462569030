import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import UserStore from "store/UserStore";
import Box from "@mui/material/Box";
import StudentSettings from "./StudentSettings/StudentSettings";
import TutorSettings from "./TutorSettings/TutorSettings";

const SettingsBodyBlock = observer(({ children, ...props }) => {
    return <Box>{UserStore.role === "student" ? <StudentSettings /> : <TutorSettings />}</Box>;
});

export default memo(SettingsBodyBlock);
