import React from "react";

import { Dialog, Box, ThemeProvider, IconButton, Stack, Typography } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

import SButton from "components/UI/SButton";

import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";

import * as themes from "components/OrderBodyBlock/StickyActionBlock/ModalResponseThemes";
import * as styles from "pages/DiplomaChats/styles";
import * as textStyles from "themes/TextThemes";

const EndOrderModal = ({ open = false, close }) => {
    const handleCloseOrder = () => {
        DiplomaStore.setStatus("ended");
        close();
    };

    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={open}
                onClose={close}
            >
                <Box sx={themes.dialogContainerStyle}>
                    <ThemeProvider theme={themes.CloseIconTheme}>
                        <IconButton onClick={close}>
                            <CloseRounded />
                        </IconButton>
                    </ThemeProvider>
                    <Stack
                        sx={styles.reviewModalContainerStyle}
                        padding="30px 40px 20px 40px"
                        direction="column"
                        spacing={2.5}
                    >
                        <Typography
                            textAlign="center"
                            sx={textStyles.HeadlineH41Black}
                        >
                            Принять работу и завершить заказ?
                        </Typography>
                        <Typography
                            sx={textStyles.Body2Black}
                            textAlign="center"
                        >
                            Мы отправим оплату специалисту и завершим заказ
                        </Typography>
                        <Stack
                            spacing={1.5}
                            justifyContent="flex-end"
                            direction="row"
                            paddingTop="20px"
                        >
                            <SButton
                                variant="secondary"
                                onClick={close}
                            >
                                Отменить
                            </SButton>
                            <SButton style={{ height: "36px" }}>
                                <Typography
                                    variant="standardWhite"
                                    onClick={handleCloseOrder}
                                >
                                    Да, завершить заказ
                                </Typography>
                            </SButton>
                        </Stack>
                    </Stack>
                </Box>
            </Dialog>
        </ThemeProvider>
    );
};

export default EndOrderModal;
