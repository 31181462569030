const LockIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <g clipPath="url(#clip0_1919_62410)">
                <circle
                    cx="12"
                    cy="12"
                    r="12"
                    fill="#19B20C"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 7C11.4625 7 10.9636 7.1741 10.6096 7.46143C10.2587 7.7462 10.0833 8.11218 10.0833 8.47222V9.5H13.9167V8.47222C13.9167 8.11218 13.7413 7.7462 13.3904 7.46143C13.0364 7.1741 12.5375 7 12 7ZM8.44048 8.47222V9.5H8.16667C6.93461 9.5 6.25 10.5842 6.25 11.5227V15.9773C6.25 16.9158 6.93461 18 8.16667 18H15.8333C17.0654 18 17.75 16.9158 17.75 15.9773V11.5227C17.75 10.5842 17.0654 9.5 15.8333 9.5H15.5595V8.47222C15.5595 7.65352 15.158 6.88904 14.4819 6.34032C13.8089 5.79415 12.9149 5.5 12 5.5C11.0851 5.5 10.1911 5.79415 9.51815 6.34032C8.84204 6.88904 8.44048 7.65352 8.44048 8.47222ZM12 15.25C12.8284 15.25 13.5 14.5784 13.5 13.75C13.5 12.9216 12.8284 12.25 12 12.25C11.1716 12.25 10.5 12.9216 10.5 13.75C10.5 14.5784 11.1716 15.25 12 15.25Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_1919_62410">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default LockIcon;
