import { processLogs } from "./errorHandlers";
import ErrorStore from "../../store/ErrorStore";
import i18next from "../../i18n";

window.onerror = async (msg, url, line, col, error) => {
    console.log("Message: ", msg);
    console.log("Url: ", url);
    console.log("Row: ", line);
    console.log("Column: ", col);
    console.log("Error: ", error);

    let errorData = {
        Message: msg,
        Url: url,
        Row: line,
        Column: col,
        Error: error,
    };

    //ErrorStore.ShowError({ errorText: i18next.t("error.errors.defaultUnknownError"), isCritical: true });

    processLogs(errorData);
};

window.onunhandledrejection = async (error) => {
    console.log("Error: ", error);

    let errorData = {
        Error: error,
    };

    //ErrorStore.ShowError({ errorText: i18next.t("error.errors.defaultUnknownError"), isCritical: true });

    processLogs(errorData);
};
