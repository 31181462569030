import React, { memo, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import * as styles from "./TextfieldReviewThemes";
import * as textStyles from "themes/TextThemes";
import { useTranslation } from "react-i18next";

const TextfieldReview = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const [length, setLength] = useState(0);

    const handleChange = (e) => {
        props.handleChange(e.target.value);
        setLength(e.target.value.length);
    };

    return (
        <Box sx={styles.TextfieldReview}>
            <TextField
                fullWidth
                placeholder={props.placeholder}
                multiline
                rows={6}
                inputProps={styles.inputProps(props.maxCharacters)}
                sx={styles.Textfield(length === props.maxCharacters)}
                onChange={handleChange}
                value={props.value}
            />
            <Box sx={styles.HelperBox}>
                {length === props.maxCharacters ? <Typography sx={textStyles.Body2Red}>{t("main.myClasses.digitsLimitAchieved")}</Typography> : <Typography></Typography>}
                <Typography
                    sx={length === 0 ? textStyles.Body2Grey : textStyles.Body2Black}
                    component={"div"}
                >
                    {length}
                    <span style={textStyles.Body2Black}>/{props.maxCharacters}</span>
                </Typography>
            </Box>
        </Box>
    );
};

export default memo(TextfieldReview);
