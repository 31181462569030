export const marginTop = { marginTop: "30px" };

export const CardsContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const DayBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
};

export const DaysContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
};
