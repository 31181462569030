import { Tabs, styled } from "@mui/material";

export const StyledTabsOrder = styled(Tabs)({
    padding: "1px",
    backgroundColor: "#6212FF",
    bottom: 6,
    boxSizing: "border-box",
    background: "none",
    color: "black",
    "& .MuiTabs-flexContainer": {
        display: "flex",
        gap: "20px",
    },

    "& button": {
        minWidth: 0,
        padding: "0px",
        textTransform: "none",
        fontSize: "16px",
        fontWeight: "500",
        ":hover": { color: "#222" },

        "& .MuiTabs-indicator": {
            color: "red",
            backgroundColor: "#6212FF",
            background: "#6212FF",
        },
    },
    "& button.Mui-selected": {
        color: "black",
        backgroundColor: "none",
        background: "none",
    },
});

export const TabStyled = {
    p: 0,
    textTransform: "none",
};

export const TabIndicator = {
    padding: "1px",
    backgroundColor: "#6212FF",
    bottom: 6,
    boxSizing: "border-box",
};
