import { createTheme } from "@mui/material/styles";

export const reviewsCardStyle = (medias) => {
    return {
        boxShadow: "none",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "20px",
        /*minHeight: "433px",*/
        padding: medias.sm ? "30px 40px" : "20px",
        width: medias.sm ? "827px" : "320px",
        borderRadius: "20px",
        background: "var(--main-white, #FFF)",
    };
};

export const reviewsContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    position: "relative",
    maxHeight: "fit-content",
};

export const headerContainerStyle = {
    display: "flex",
    alignItems: "center",
    gap: 8,
};

export const aboutTextStyle = (medias) => {
    return {
        alignSelf: medias.sm ? "stretch" : "center",
        color: "var(--text-black, #222)",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "150%",
    };
};

export const subTextStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const showMoreTextStyle = {
    position: "sticky",
    color: "var(--main-purple, #6212FF)",
    cursor: "pointer",
    fontSize: "16px",
    fontStyle: "normal",
    fontSeight: "400",
    lineHeight: "150%",
};

export const progressContainerStyle = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
};

export const CircularProgressTheme = createTheme({
    components: {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: "#6212FF",
                },
            },
        },
    },
});

export const starRowStyle = {
    display: "flex",
    alignItems: "center",
    gap: 4,
    marginLeft: "146px",
};

export const ratingTextStyle = {
    color: "var(--txt-black, #171717)",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "140%",
};
