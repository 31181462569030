import * as React from "react";
import { AppBar, Box } from "@mui/material";
import UserStore from "store/UserStore";
import { useLocation } from "react-router-dom";
import * as themes from "./HeaderAccountThemes";
import { observer } from "mobx-react-lite";
import { useMedias } from "API/mediasHook";
import LeftPanel from "./LeftPanel/LeftPanel";
import RightPanel from "./RightPanel/RightPanel";
import QuestionnaireHeader from "./QuestionnaireHeader/QuestionnaireHeader";
import PaymentHeader from "./PaymentHeader/PaymentHeader";
import { memo } from "react";

const HeaderAccount = observer(() => {
    const location = useLocation();

    const medias = useMedias();

    const headerPaths = {
        "/video-chat": <Box sx={themes.ZeroBox} />,
        "/authorization": <Box sx={themes.ZeroBox} />,
        "/questionnaire": <QuestionnaireHeader />,
        "/questionnaire-on-check": <QuestionnaireHeader />,
        "/buddyrules": <QuestionnaireHeader />,
        "/payment": <PaymentHeader />,
    };

    if (headerPaths[location.pathname]) {
        return headerPaths[location.pathname];
    } else
        return (
            <AppBar sx={themes.HeaderAccountAppBar(UserStore.isAuth, medias)}>
                <Box sx={themes.HeaderAccount}>
                    <Box sx={themes.HeaderAccountContent(medias)}>
                        <LeftPanel />
                        <RightPanel />
                    </Box>
                </Box>
            </AppBar>
        );
});

export default memo(HeaderAccount);
