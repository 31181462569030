export const SDragNDropArea = {
    width: "100%",
    borderRadius: "10px",
    border: "1px dashed #9e9eb0",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "&:hover": {
        background: "rgba(187, 187, 200, 0.17)",
        cursor: "pointer",
    },
};

export const SDragNDropAreaInside = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "16px 20px",
};

export const SDragNDropImageBox = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginTop: "20px",
    alignItems: "flex-start",
};

export const SDragNDropImage = {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
};

export const SDragNDropImageStyle = {
    maxWidth: "40px",
    maxHeight: "40px",
    borderRadius: "4px",
};

export const SDragNDropImageData = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
};

export const SDragNDropImageName = {
    color: "#222",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "135%",
    margin: 0,
};

export const SDragNDropImageSize = {
    color: "#9e9eb0",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "140%",
    margin: 0,
};
