import * as React from "react";
import { memo } from "react";
import { observer } from "mobx-react-lite";

import ReactPlayer from "react-player/lazy";

import { Typography, Box } from "@mui/material";

import * as themes from "./VideoAttachmentThemes";

import { useGetChatImageAttachment } from "hooks/useGetChatImageAttachment";

const VideoAttachment = observer(({ children, ...props }) => {
    const video = useGetChatImageAttachment(props.value.link);

    return (
        <>
            <ReactPlayer
                width={100 / props.elementsInRow + "%"}
                height="100%"
                loop
                playing
                muted
                controls
                volume={1}
                style={themes.videoAttachmentStyle(props.elementsInRow, props.finalInRow)}
                url={video}
            />
            {props.finalInRow && (
                <Box sx={themes.blurContainerStyle}>
                    <Typography sx={themes.moreToShowStyle}>+{props.moreToShow}</Typography>
                </Box>
            )}
        </>
    );
});

export default memo(VideoAttachment);
