import React, { memo } from "react";
import { Avatar, ListItemAvatar, Badge, ListItemButton, Box, Typography } from "@mui/material";
import SButton from "components/UI/SButton";
import * as types from "components/NotificationsBodyBlock/NotificationTypes/NotificationTypes";
import * as themes from "./SingleNotificationThemes";
import * as textThemes from "themes/TextThemes";
import { useMedias } from "API/mediasHook";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import NotificationsStore from "store/NotificationsStore";
import RescheduleAcceptIcon from "assets/NotificationsAssets/SVG/RescheduleAcceptIcon";
import RescheduleRejectIcon from "assets/NotificationsAssets/SVG/RescheduleRejectIcon";
import { useNavigate } from "react-router-dom";
import UserStore from "store/UserStore";
import TutorClassesStore from "store/TutorClassesStore";

const SingleNotification = observer(({ notification, ...props }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const medias = useMedias();

    const getTimeOfCreation = () => {
        const timeObject = NotificationsStore.formatCreatedAtDate(notification.createdAt);

        if (timeObject.type === "main.rightNow") {
            return t("main.rightNow");
        } else if (!timeObject.diff && timeObject.date) {
            return timeObject.date;
        } else if (!timeObject.diff && !timeObject.date) {
            return `${timeObject.type.day} ${t(timeObject.type.month)}`;
        } else {
            return `${timeObject.diff} ${t(timeObject.type)} ${t("main.ago")}`;
        }
    };

    const getTimeForCancelledClass = (date) => {
        const timeObject = NotificationsStore.formatDate(date);

        return `${timeObject.day} ${t(timeObject.month)} ${t("main.at")} ${timeObject.hours}:${timeObject.minutes}`;
    };

    const getTimeRange = (start, end) => {
        const timeObject = NotificationsStore.formatDateRange(start, end);

        return `${timeObject.day} ${t(timeObject.month)}, ${t(timeObject.weekDay)} ${timeObject.time}`;
    };

    const handleClickRescheduleAccept = () => {
        NotificationsStore.makeDecisionAboutReschedule(
            NotificationsStore.notifications.findIndex((object) => object.notificationUuid === notification.notificationUuid),
            true,
        );
    };

    const handleClickRescheduleReject = () => {
        NotificationsStore.makeDecisionAboutReschedule(
            NotificationsStore.notifications.findIndex((object) => object.notificationUuid === notification.notificationUuid),
            false,
        );
    };

    const handleClickNotification = async () => {
        await NotificationsStore.ReadNotification(notification.notificationUuid);
        UserStore.setOpenNotifications(false);
        if (notification.type === "startLesson") {
            window.open("/video-chat", "_blank");
        } else if (notification.type === "rescheduleRequest" && notification.category === "tutor") {
            TutorClassesStore.setActiveClassesTab(3);
            navigate(getClickLink());
        } else navigate(getClickLink());
    };

    const getClickLink = () => {
        const { order, type, category } = notification;

        switch (type) {
            case "single":
                return `/myorder/${order.id}`;
            case "cashback":
                return "/balance";
            case "personal":
                return `/diplomas`;
            case "rescheduleRequest":
                if (category === "student") {
                    return `/myclasses`;
                } else if (category === "tutor") {
                    return `/tutor/classes`;
                }

                break;
            case "response":
                return `/myorder/${order.id}`;
            case "startLesson":
                return `/video-chat`;
            case "rescheduled":
                if (category === "student") {
                    return `/myclasses`;
                } else if (category === "tutor") {
                    return `/tutor/classes`;
                }

                break;
            case "cancelLesson":
                if (category === "student") {
                    return `/myclasses`;
                } else if (category === "tutor") {
                    return `/tutor/classes`;
                }

                break;
            case "newOrder":
                return `/orders-responses`;
            case "paid":
                return `/tutor/paid`;
            default:
                return null;
        }
    };

    const renderNotificationText = () => {
        const { creator, order, type, actionType, value, count, cardTail, category } = notification;

        switch (type) {
            case "single":
                return `${t("notifications.newResponseTitlePart1")}${creator.name}${t("notifications.newResponseTitlePart2")}`;
            case "cashback":
                return `${t("notifications.pointsCreditedTitlePart1")}${value} ${t(NotificationsStore.getPointWord(value))}${t("notifications.pointsCreditedTitlePart2")}`;
            case "personal":
                if (actionType === "sendForReview") {
                    return `${creator.name}${t("notifications.sentForReviewTitle")}`;
                } else if (actionType === "sendForCorrection") {
                    return `${creator.name}${t("notifications.sentForCorrectionTitle1")}«${order.title}»${t("notifications.sentForCorrectionTitle2")}`;
                }

                break;
            case "rescheduleRequest":
                return t("notifications.rescheduleRequestTitle");
            case "response":
                return `${count} ${t(NotificationsStore.getNewResponseWord(count))}${t("notifications.toTheOrder")}`;
            case "startLesson":
                if (category === "student") {
                    return t("notifications.classIn15Minutes");
                } else if (category === "tutor") {
                    return `${t("notifications.classWillStartSoonTitlePart1")}${order.title}${t("notifications.classWillStartSoonTitlePart2")}${creator.name}${t("notifications.classWillStartSoonTitlePart3")}`;
                }

                break;
            case "rescheduled":
                return `${t("notifications.classRescheduledTitlePart1")}${creator.name}${t("notifications.classRescheduledTitlePart2")}`;
            case "cancelLesson":
                return `${creator.name}${t("notifications.cancelledClass")}${getTimeForCancelledClass(order.date)}`;
            case "newOrder":
                return `${t("notifications.newOrderTitle")}`;
            case "paid":
                return `${t("notifications.paymentMadeTitle1")}${value}${t("notifications.paymentMadeTitle2")}*${cardTail}`;
            default:
                return null;
        }
    };

    const renderOrderText = () => {
        const { order, type, newLessonDate, actionType, value, cardTail, creator, correctionDeadline, correctionDaysLeft, category } = notification;

        switch (type) {
            case "single":
            case "response":
                return `«${order.title}»`;
            case "cashback":
                return `${t("notifications.pointsAwarded")}${order.date.getDate().toString().padStart(2, "0")}.${(order.date.getMonth() + 1).toString().padStart(2, "0")}.${order.date.getFullYear().toString().slice(-2)}`;
            case "startLesson":
                if (category === "student") {
                    return `${t("notifications.classWillStartSoonTextPart1")}«${order.title}»${t("notifications.classWillStartSoonTextPart2")}`;
                } else if (category === "tutor") {
                    return t("notifications.classWillStartSoonText");
                }

                break;
            case "rescheduled":
                return `${t("notifications.newClassDateTextPart1")}«${order.title}»${t("notifications.newClassDateTextPart2")} ${getTimeRange(newLessonDate.start, newLessonDate.end)}`;
            case "cancelLesson":
                return `${t("notifications.specialistCancelledTextPart1")}«${order.title}»${t("notifications.specialistCancelledTextPart2")}${getTimeForCancelledClass(order.date)}`;
            case "newOrder":
                return `«${order.title}»`;
            case "paid":
                return `${t("notifications.paymentMadeText1")}${value}${t("notifications.paymentMadeText2")}${order.date.getDate().toString().padStart(2, "0")}.${(order.date.getMonth() + 1).toString().padStart(2, "0")}.${order.date.getFullYear().toString().slice(-2)}, ${creator.name}, ${order.title}`;
            case "personal":
                if (actionType === "sendForReview") {
                    return `${t("notifications.orderSentForReviewTextPart1")}«${order.title}»${t("notifications.orderSentForReviewTextPart2")}`;
                } else if (actionType === "sendForCorrection") {
                    return `${t("notifications.sentForCorrectionText1")}${correctionDaysLeft} ${t(NotificationsStore.getDayWord(correctionDaysLeft))}${t("notifications.sentForCorrectionText2")}${correctionDeadline.getDate().toString().padStart(2, "0")}.${(correctionDeadline.getMonth() + 1).toString().padStart(2, "0")}.${correctionDeadline.getFullYear().toString().slice(-2)})`;
                }

                break;
            default:
                return null;
        }
    };

    return (
        <Box sx={themes.Wrapper}>
            <ListItemButton
                disableRipple
                onClick={handleClickNotification}
                sx={themes.ListItemButton}
            >
                <Box sx={themes.BoxItemWrapper}>
                    <Badge
                        sx={themes.Bage}
                        variant="dot"
                        invisible={notification.read}
                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    >
                        <ListItemAvatar sx={themes.ListItemAvatar}>
                            <Avatar sx={themes.Avatar}>
                                {notification.type === "personal" || notification.type === "single" ? (
                                    <img
                                        style={themes.ImgPos}
                                        src={notification.creator.photo.link}
                                        alt="creator"
                                    />
                                ) : (
                                    types.NOTIFICATION_TYPES[notification.type]
                                )}
                            </Avatar>
                        </ListItemAvatar>
                    </Badge>
                    <Box sx={themes.NotificationText}>
                        <Typography sx={textThemes.Button16Black}>{renderNotificationText()}</Typography>
                        {notification.type === "rescheduleRequest" ? (
                            <Box sx={themes.RescheduleBlock}>
                                <Box sx={themes.RescheduleText}>
                                    <Typography sx={textThemes.Body2Black}>{`${notification.creator.name}${t("notifications.asksToReschedule")}«${notification.order.title}»`}</Typography>
                                    <Typography>
                                        <Typography
                                            variant="span"
                                            sx={textThemes.Body2DarkGrey}
                                        >
                                            {t("notifications.currentTime")}
                                        </Typography>
                                        <Typography
                                            variant="span"
                                            sx={textThemes.Button14Black}
                                        >
                                            {getTimeRange(notification.currentTime.start, notification.currentTime.end)}
                                        </Typography>
                                    </Typography>
                                    <Typography>
                                        <Typography
                                            variant="span"
                                            sx={textThemes.Body2DarkGrey}
                                        >
                                            {t("notifications.requestedTime")}
                                        </Typography>
                                        <Typography
                                            variant="span"
                                            sx={textThemes.Button14Black}
                                        >
                                            {getTimeRange(notification.requestedTime.start, notification.requestedTime.end)}
                                        </Typography>
                                    </Typography>
                                </Box>
                                {notification.postponementDecision.accept === null ? (
                                    <Box sx={themes.ButtonsBlock}>
                                        <SButton
                                            onClick={handleClickRescheduleReject}
                                            style={themes.SButtonSize(medias)}
                                            variant="secondary"
                                        >
                                            {t("main.reject")}
                                        </SButton>
                                        <SButton
                                            onClick={handleClickRescheduleAccept}
                                            style={themes.SButtonSize(medias)}
                                            variant="unshadowed"
                                        >
                                            {t("main.accept")}
                                        </SButton>
                                    </Box>
                                ) : notification.postponementDecision.accept === true ? (
                                    <Box sx={themes.RescheduleDecision}>
                                        <Box sx={themes.DecisionIcon}>
                                            <RescheduleAcceptIcon />
                                        </Box>
                                        <Typography sx={textThemes.Body2Black}>{t("notifications.rescheduleAccept")}</Typography>
                                    </Box>
                                ) : notification.postponementDecision.accept === false ? (
                                    <Box sx={themes.RescheduleDecision}>
                                        <Box sx={themes.DecisionIcon}>
                                            <RescheduleRejectIcon />
                                        </Box>
                                        <Typography sx={textThemes.Body2Black}>{t("notifications.rescheduleReject")}</Typography>
                                    </Box>
                                ) : null}
                            </Box>
                        ) : (
                            <Typography sx={textThemes.Body2Black}>{renderOrderText()}</Typography>
                        )}
                    </Box>
                </Box>
                <Box sx={themes.InfoTimeWrapper}>
                    <Typography sx={textThemes.Caption1Grey}>{getTimeOfCreation()}</Typography>
                </Box>
            </ListItemButton>
        </Box>
    );
});

export default memo(SingleNotification);
