import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import * as themes from "./ServiceItemThemes";
import * as textThemes from "themes/TextThemes";
import { memo } from "react";
import EmptyAvatar from "assets/TeacherClassesAssets/PNG/EmptyAvatar.png";
import { useMedias } from "API/mediasHook";
const ServiceItem = ({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={themes.ServiceItem(medias)}>
            <Box sx={themes.AvatarSubjWrapper(medias)}>
                <Box sx={themes.NumberAndStudentBlock}>
                    {medias.sm && (
                        <Box sx={themes.NumberBlock}>
                            <Typography sx={textThemes.Body2Black}>{props.itemNumber + 1}</Typography>
                        </Box>
                    )}
                    <Box sx={themes.StudentBlock}>
                        <Avatar
                            src={props.item.student.avatar ? props.item.student.avatar : EmptyAvatar}
                            sx={themes.Avatar}
                        />
                        <Box sx={themes.StudentNameBlock(medias)}>
                            <Typography sx={textThemes.Body2Black}>{props.item.student.name}</Typography>
                            {!medias.sm && <Typography sx={textThemes.Body2Black}>,</Typography>}
                        </Box>
                    </Box>
                </Box>
                <Box sx={themes.SubjectBlock(medias)}>
                    <Typography sx={textThemes.Body2Black}>{props.item.order.title}</Typography>
                </Box>
            </Box>
            <Box sx={themes.FinanceWrapper(medias)}>
                <Box sx={themes.PriceBlock(medias)}>
                    <Typography sx={textThemes.Button14BoldBlack}>{props.item.cost.initial.toLocaleString("ru-RU")} ₽</Typography>
                </Box>
                <Box sx={themes.CommissionBlock(medias)}>
                    <Typography sx={textThemes.Body2Black}>
                        -{((props.item.cost.initial * props.item.commission) / 100).toLocaleString("ru-RU")} ₽ ({props.item.commission}%)
                    </Typography>
                </Box>
                <Box sx={themes.TotalPriceBlock(medias)}>
                    <Typography sx={textThemes.Button14BoldBlack}>{props.item.cost.total.toLocaleString("ru-RU")} ₽</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default memo(ServiceItem);
