import React, { memo } from "react";
import * as themes from "./DescriptionBlockThemesDefault";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const DescriptionBlock = ({ children, ...props }) => {
    return (
        <Box sx={themes.descriptionBlockContainerStyle}>
            <Typography sx={themes.descriptionTextStyle}>{props.value.Review}</Typography>
            <Typography sx={themes.assocTagsTextStyle}>
                {props.value.AssociatedTags.TagDiscipline} / {props.value.AssociatedTags.TagsOther.reduce((acc, value) => acc + value + ", ", "").slice(0, -2)}
            </Typography>
        </Box>
    );
};

export default memo(DescriptionBlock);
