import React, { memo } from "react";
import * as themes from "./AdditionalFiltersThemes";
import ButtonFilter from "components/UI/ButtonFilter";
import { Tune } from "@mui/icons-material";
import CatalogStore from "store/CatalogStore";
import { observer } from "mobx-react-lite";
import { useMedias } from "../../../../API/mediasHook";

const AdditionalFilter = observer(({ children, ...props }) => {
    const toggleDrawer = () => {
        CatalogStore.ToggleDrawer();
    };

    const medias = useMedias();

    return (
        <ButtonFilter
            valueToBind={CatalogStore.drawerOpened}
            toggleFunc={toggleDrawer}
            themeWrapper={themes.FiltersAdditionalTheme(medias)}
            themeSpanSelected={themes.spanAdditionalSelected}
            themeSpanDefault={themes.spanAdditionalDefault}
            buttonText={medias.sm ? "Все фильтры" : ""}
            icon={<Tune sx={themes.iconAdditional(medias)}></Tune>}
            iconAlwaysVisible={true}
            iconsPosition={"Prepend"}
            activeFiltersCount={CatalogStore.selectedChipsList.length}
            filtersCounterContainerStyle={themes.filtersCounterContainerStyle(medias)}
            filtersCounterTextStyle={themes.filtersCounterTextStyle}
        ></ButtonFilter>
    );
});

export default memo(AdditionalFilter);
