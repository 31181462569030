export const myResponsesBlockStyle = {
    width: 1216,
};

export const myResponsesTableStyle = {
    width: "907px",
    borderRadius: "20px",
    background: "#FFF",
};

export const myResponsesTableHeaderStyle = {
    borderBottom: "1px solid #DADADF",
};

export const myResponsesTableHeaderCellStyle = {
    padding: "15px 20px 15px 20px",
};

export const myResponsesTableHeaderTextStyle = {
    color: "var(--Text-Black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
};

export const myResponsesTableDataRowStyle = {
    verticalAlign: "top",
    borderBottom: "1px dashed #DADADF",
};

export const myResponsesTableDataCellStyle = {
    padding: "20px 20px 20px 20px",
};

export const orderInfoContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
};

export const orderInfoSubContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
};

export const dividerStyle = (marginTop, marginBottom) => {
    return {
        marginTop: marginTop,
        marginBottom: marginBottom,
    };
};

export const themeBlockContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    marginTop: "8px",
    gap: "5px",
    width: "100%",
    order: 1,
    flexGrow: 0,
};

export const orderTitleStyle = {
    color: "var(--Text-Black, #222)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
    cursor: "pointer",
    "&:hover": {
        color: "#6212FF",
    },
};

export const themeLabelStyle = {
    height: "21px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#9E9EB0",
    order: 0,
    flexGrow: 0,
};

export const themeTextStyle = {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
    color: "#222222",
    order: 0,
    flexGrow: 0,
};

export const deadlineBlockContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    marginTop: "7px",
    gap: "5px",
    width: "100%",
    height: "21px",
    order: 1,
    flexGrow: 0,
};

export const deadlineLabelStyle = {
    height: "21px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#9E9EB0",
    order: 0,
    flexGrow: 0,
};

export const deadlineTextStyle = {
    height: "21px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#222222",
    order: 0,
    flexGrow: 0,
};

export const myPriceStyle = {
    color: "var(--Text-Black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const responseDateStyle = {
    color: "var(--Text-Black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const statusContainerStyle = (mode) => {
    let color = "var(--Grey-Light-Grey17, rgba(187, 187, 200, 0.17))";

    switch (mode) {
        case 0:
            color = "var(--Grey-Light-Grey17, rgba(187, 187, 200, 0.17))";
            break;
        case 1:
            color = "rgba(25, 178, 12, 0.10)";
            break;
        case 2:
            color = "rgba(225, 38, 38, 0.10)";
            break;
        default:
            break;
    }

    return {
        display: "inline-flex",
        padding: "2px 10px 4px 4px",
        justifyContent: "center",
        alignItems: "center",
        gap: "1px",
        borderRadius: "26px",
        background: color,
    };
};

export const circleContainerStyle = {
    width: 16,
    height: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

export const circleStyle = (mode) => {
    let color = "#9E9EB0";

    switch (mode) {
        case 0:
            color = "#9E9EB0";
            break;
        case 1:
            color = "#19B20C";
            break;
        case 2:
            color = "#E12626";
            break;
        default:
            break;
    }

    return {
        width: 6,
        height: 6,
        borderRadius: "50%",
        background: color,
    };
};

export const statusTextStyle = (mode) => {
    let color = "var(--Text-Black, #222)";

    switch (mode) {
        case 0:
            color = "var(--Text-Black, #222)";
            break;
        case 1:
            color = "var(--Main-Green, #19B20C)";
            break;
        case 2:
            color = "var(--Text-Red, #E12626)";
            break;
        default:
            break;
    }

    return {
        color: color,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "150%",
    };
};

export const locationBlockContainerStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "0px",
    gap: "5px",
    width: "100%",
    flex: "none",
    order: 1,
    flexGrow: 0,
};

export const locationLabelStyle = {
    height: "21px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#9E9EB0",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const locationTypeTextStyle = {
    color: "var(--text-black, #222)",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
};

export const locationTextContainerStyle = {
    display: "flex",
    alignItems: "center",
    gap: "5px",
};

export const locationTextStyle = {
    height: "21px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "var(--text-dark-grey, #67677A)",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const detailsBlockContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    marginTop: "8px",
    gap: "5px",
    width: "100%",
    order: 1,
    flexGrow: 0,
};

export const detailsLabelStyle = {
    height: "21px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#9E9EB0",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const detailsTextStyle = {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
    color: "#222222",
    order: 0,
    flexGrow: 0,
};

export const emptyResponsesContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: 30,
    width: 907,
    boxSizing: "border-box",
    padding: "40px",
    borderRadius: "20px",
    background: "var(--BG-White, #FFF)",
};

export const emptyTextContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: 20,
};

export const emptyHeaderStyle = {
    color: "var(--text-black, #222)",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "140%",
};

export const emptyTextStyle = {
    color: "var(--text-black, #222)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};
