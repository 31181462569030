import { makeAutoObservable } from "mobx";
import { handleStoreActionError } from "../API/plugins/errorHandlers";
import * as CatalogService from "../API/services/grpc/CatalogService";
import { getCookie } from "../API/utils";
import UserStore from "./UserStore";

class FavouritesStore {
    activeChip = null;

    favouritesList = [];
    favouritesListFiltered = [];

    uniqueDisciplines = [];

    currentPage = 1;
    pagesTotal = null;
    itemsForPage = 6;
    from = 0;
    to = this.itemsForPage;
    totalFavouritesCount = null;

    loadingFavouritesData = true;
    loadingMoreFavouritesData = false;
    loadingPaginatedFavouritesData = false;

    timeSearch = "";

    constructor() {
        this.Init();
        makeAutoObservable(this);
    }

    debounce(callback, delay) {
        let timerID = null;

        return (...args) => {
            if (timerID) {
                clearTimeout(timerID);
            }

            timerID = setTimeout(() => {
                callback.call(this, ...args);
            }, delay);
        };
    }

    SetActiveChip(value) {
        console.log(value);
        this.activeChip = value;
    }

    SetChipFilter() {
        console.log(this.activeChip);
        this.favouritesListFiltered = this.favouritesList.filter((value) => (this.activeChip.Query === "" ? true : value.Disciplines.map((value) => value.discipline).includes(this.activeChip.Query)));
    }

    ToggleLiked(item) {
        this.favouritesList = this.favouritesList.map((value) => (value._id === item._id ? { ...value, Liked: !item.Liked } : value));
        this.SetChipFilter();
    }

    SetLoadingState(field, value) {
        this[field] = value;
    }

    ChooseLoadingMode(value) {
        switch (value) {
            case "default":
                return "loadingFavouritesData";
            case "append":
                return "loadingMoreFavouritesData";
            case "replace":
                return "loadingPaginatedFavouritesData";
            default:
                return "loadingFavouritesData";
        }
    }

    async ProcessPagination(value) {
        this.currentPage = value;
        await this.FetchFavouritesData("replace");
    }

    async ShowMore() {
        await this.FetchFavouritesData("append");
    }

    async FetchPageData() {
        await this.FetchFavouritesData();
    }

    async FetchFavouritesData(contentLoadingMode = "default") {
        let data = {
            metaData: {
                timeSearch: this.timeSearch,
                page: contentLoadingMode === "append" ? Math.round(this.favouritesList.length / this.itemsForPage) + 1 : this.currentPage,
            },
        };

        this.SetLoadingState(this.ChooseLoadingMode(contentLoadingMode), true);
        await this.FetchFavourites(data, contentLoadingMode);
    }

    async FetchFavourites(data, contentLoadingMode) {
        if (this.activeChip.Query !== "") {
            data["subject"] = this.activeChip.Query;
        }

        let metaData = {
            authorization: "Bearer " + getCookie(`${UserStore.role}AccessToken`),
        };

        await CatalogService.GetFavourites(data, metaData, async (error, response) => {
            if (error) {
                handleStoreActionError({
                    error,
                    customErrorText: "catalog.errors.fetchFavouritesError",
                    dispatch: true,
                });
            } else {
                let data = response.toObject();

                this.FillFavouritesData(data, contentLoadingMode);
                this.SetLoadingState(this.ChooseLoadingMode(contentLoadingMode), false);
            }

            this.SetLoadingState(this.ChooseLoadingMode(contentLoadingMode), false);
        });
    }

    FillFavouritesData(data, contentLoadingMode) {
        console.log(data);
        this.timeSearch = data.metaData.timeSearch;
        this.pagesTotal = data.paging.pages;
        this.totalFavouritesCount = data.paging.total;
        this.uniqueDisciplines = data.subjectTagsList.map((x, index) => {
            return { ID: index, Query: x };
        });
        let newDataToAppend = [];

        switch (contentLoadingMode) {
            case "default":
                this.favouritesList = [];
                break;
            case "replace":
                this.favouritesList = [];
                break;
            case "append":
                break;
            default:
                break;
        }

        data.dataList.forEach((value, index) => {
            newDataToAppend.push({
                ID: this.favouritesList.length + index,
                _id: value.tutorUuid,
                AvatarSrc: "https://" + process.env.REACT_APP_API_URL + value.photo.link,
                VideoSrc: "https://" + process.env.REACT_APP_API_URL + value.video.link,
                Name: value.name,
                IsVerified: value.isVerified,
                IsAnonymous: value.isAnonymous,
                LastSeen:
                    "был в сети: " +
                    (new Date(value.status.lastSeen) === new Date()
                        ? "Сегодня, " +
                          new Date(data.status.lastSeen).toLocaleString("ru", {
                              hour: "numeric",
                              minute: "numeric",
                          })
                        : "" +
                          new Date(value.status.lastSeen).toLocaleString("ru", {
                              day: "numeric",
                              month: "short",
                              weekday: "short",
                              hour: "numeric",
                              minute: "numeric",
                          })),
                IsActive: value.status.online,
                Rating: value.rates.average,
                ReviewsCount: value.rates.total,
                Disciplines: value.subjectList.map((x, index) => {
                    return { ID: index, discipline: x };
                }),
                Location: value.metroStationsList.map((x, index) => {
                    return { ID: index, location: x };
                }),
                Education: [value.educationalInstitution].map((x, index) => {
                    return { ID: index, education: x };
                }),
                Experience: value.experience + " лет",
                Tags: [
                    ...value.audienceList.map((x, index) => {
                        return { ID: index, Query: x };
                    }),
                    ...value.purposeList.map((x, index) => {
                        return { ID: value.audienceList.length + index, Query: x };
                    }),
                    { ID: 6, Query: "для работы 2" },
                ],
                Schedule: [
                    {
                        ID: 0,
                        Time: "06:00-12:00",
                        from: 6,
                        to: 12,
                        Days: [
                            {
                                ID: 0,
                                Day: "Пн",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "mo" && value.timetable.morning).length > 0,
                            },
                            {
                                ID: 1,
                                Day: "Вт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "tu" && value.timetable.morning).length > 0,
                            },
                            {
                                ID: 2,
                                Day: "Ср",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "we" && value.timetable.morning).length > 0,
                            },
                            {
                                ID: 3,
                                Day: "Чт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "th" && value.timetable.morning).length > 0,
                            },
                            {
                                ID: 4,
                                Day: "Пт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "fr" && value.timetable.morning).length > 0,
                            },
                            {
                                ID: 5,
                                Day: "Сб",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "sa" && value.timetable.morning).length > 0,
                            },
                            {
                                ID: 6,
                                Day: "Вс",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "su" && value.timetable.morning).length > 0,
                            },
                        ],
                    },
                    {
                        ID: 1,
                        Time: "12:00-18:00",
                        from: 12,
                        to: 18,
                        Days: [
                            {
                                ID: 0,
                                Day: "Пн",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "mo" && value.timetable.afternoon).length > 0,
                            },
                            {
                                ID: 1,
                                Day: "Вт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "tu" && value.timetable.afternoon).length > 0,
                            },
                            {
                                ID: 2,
                                Day: "Ср",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "we" && value.timetable.afternoon).length > 0,
                            },
                            {
                                ID: 3,
                                Day: "Чт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "th" && value.timetable.afternoon).length > 0,
                            },
                            {
                                ID: 4,
                                Day: "Пт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "fr" && value.timetable.afternoon).length > 0,
                            },
                            {
                                ID: 5,
                                Day: "Сб",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "sa" && value.timetable.afternoon).length > 0,
                            },
                            {
                                ID: 6,
                                Day: "Вс",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "su" && value.timetable.afternoon).length > 0,
                            },
                        ],
                    },
                    {
                        ID: 2,
                        Time: "18:00-24:00",
                        from: 18,
                        to: 24,
                        Days: [
                            {
                                ID: 0,
                                Day: "Пн",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "mo" && value.timetable.evening).length > 0,
                            },
                            {
                                ID: 1,
                                Day: "Вт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "tu" && value.timetable.evening).length > 0,
                            },
                            {
                                ID: 2,
                                Day: "Ср",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "we" && value.timetable.evening).length > 0,
                            },
                            {
                                ID: 3,
                                Day: "Чт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "th" && value.timetable.evening).length > 0,
                            },
                            {
                                ID: 4,
                                Day: "Пт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "fr" && value.timetable.evening).length > 0,
                            },
                            {
                                ID: 5,
                                Day: "Сб",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "sa" && value.timetable.evening).length > 0,
                            },
                            {
                                ID: 6,
                                Day: "Вс",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "su" && value.timetable.evening).length > 0,
                            },
                        ],
                    },
                    {
                        ID: 3,
                        Time: "00:00-06:00",
                        from: 0,
                        to: 6,
                        Days: [
                            {
                                ID: 0,
                                Day: "Пн",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "mo" && value.timetable.night).length > 0,
                            },
                            {
                                ID: 1,
                                Day: "Вт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "tu" && value.timetable.night).length > 0,
                            },
                            {
                                ID: 2,
                                Day: "Ср",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "we" && value.timetable.night).length > 0,
                            },
                            {
                                ID: 3,
                                Day: "Чт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "th" && value.timetable.night).length > 0,
                            },
                            {
                                ID: 4,
                                Day: "Пт",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "fr" && value.timetable.night).length > 0,
                            },
                            {
                                ID: 5,
                                Day: "Сб",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "sa" && value.timetable.night).length > 0,
                            },
                            {
                                ID: 6,
                                Day: "Вс",
                                Checked: value.availableWeekDayTimetableList.filter((value) => value.day === "su" && value.timetable.night).length > 0,
                            },
                        ],
                    },
                ],
                Description: value.description,
                DescriptionFull: value.description,
                PriceFrom: value.price,
                Cashback: value.cashback,
                Liked: value.isFavourite,
            });
        });

        this.favouritesList = [...this.favouritesList, ...newDataToAppend];

        this.favouritesListFiltered = [...this.favouritesList];
    }

    Init() {
        this.Load();
    }

    Load() {
        this.activeChip = { ID: null, Query: "" };
    }
}

export default new FavouritesStore();
