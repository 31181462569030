import { TextField } from "@mui/material";
import React from "react";
import { MuiTelInput } from "mui-tel-input";
import { checkKeyDown } from "API/plugins/Utilities";

const STextField = ({ value, changeFunction, placeholder, width, variant = "underline", valueerror, messageerror, handleChange, disabled = false, numeric = false, error }) => {
    if (variant === "underline") {
        return (
            <TextField
                onKeyDown={(event) => (numeric ? checkKeyDown(event, ["number", "decimal", "space"]) : undefined)}
                disabled={disabled}
                onChange={(event) => {
                    changeFunction(event.target.value);
                }}
                value={value}
                placeholder={placeholder}
                error={valueerror}
                sx={{
                    width: width,
                    "& .MuiInput-underline:before": {
                        borderBottom: "1px solid #BBBBC8",
                    },
                    "& .MuiInput-underline:after": {
                        borderBottomColor: "#6212FF",
                    },
                    "& .MuiInputBase-input": {
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "16px",
                        lineHeight: "150%",
                        fontWeight: 400,
                        textTransform: "none",
                    },
                }}
                variant="standard"
            />
        );
    } else if (variant === "phone") {
        return (
            <MuiTelInput
                disabled={disabled}
                sx={{
                    width: width,
                    "& .MuiInput-underline:before": { borderBottom: "1px solid #BBBBC8" },
                    "& .MuiInput-underline:after": { borderBottomColor: "#6212FF" },
                    "& .MuiInputBase-input": { fontFamily: "Inter", fontStyle: "normal", fontSize: "16px", lineHeight: "150%", fontWeight: 400, textTransform: "none" },
                }}
                error={valueerror}
                helperText={messageerror}
                required
                langOfCountryName="rus"
                value={value}
                defaultCountry="RU"
                forceCallingCode
                variant="standard"
                onChange={handleChange}
                placeholder="000 000–00–00"
            />
        );
    }
};

export default STextField;
