import { Avatar, Box, Typography } from "@mui/material";
import * as themes from "./OrderSoloAvatarThemes";
import * as textThemes from "themes/TextThemes";
import { memo } from "react";

const OrderSoloAvatar = ({ children, ...props }) => {
    return (
        <Box sx={themes.SoloAvatarWrapper}>
            <Avatar
                alt={props.name}
                src={props.avatar}
                sx={themes.Avatar}
            />
            <Typography sx={textThemes.Body2Black}>{props.name}</Typography>
        </Box>
    );
};

export default memo(OrderSoloAvatar);
