const RescheduleRejectIcon = function (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.6001 2.10156C5.18182 2.10156 1.6001 5.68328 1.6001 10.1016C1.6001 14.5198 5.18182 18.1016 9.6001 18.1016C14.0184 18.1016 17.6001 14.5198 17.6001 10.1016C17.6001 5.68328 14.0184 2.10156 9.6001 2.10156ZM13.2803 6.71967C13.5732 7.01256 13.5732 7.48744 13.2803 7.78033L10.8107 10.25L13.2803 12.7197C13.5732 13.0126 13.5732 13.4874 13.2803 13.7803C12.9874 14.0732 12.5126 14.0732 12.2197 13.7803L9.75 11.3107L7.28033 13.7803C6.98744 14.0732 6.51256 14.0732 6.21967 13.7803C5.92678 13.4874 5.92678 13.0126 6.21967 12.7197L8.68934 10.25L6.21967 7.78033C5.92678 7.48744 5.92678 7.01256 6.21967 6.71967C6.51256 6.42678 6.98744 6.42678 7.28033 6.71967L9.75 9.18934L12.2197 6.71967C12.5126 6.42678 12.9874 6.42678 13.2803 6.71967Z"
                fill="#BBBBC8"
            />
        </svg>
    );
};

export default RescheduleRejectIcon;
