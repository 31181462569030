import React from "react";
import ReactPlayer from "react-player";
import { useMedias } from "API/mediasHook";
import * as theme from "./TutorVideoStyle";
import { memo } from "react";
import "./Video.css";

function MyPlayer({ children, ...props }) {
    const medias = useMedias();

    return (
        <ReactPlayer
            className="react-player-custom"
            controls={true}
            url={props.VideoName}
            width={theme.getPlayerStyles(medias).width}
            height={theme.getPlayerStyles(medias).height}
            playing={true}
        />
    );
}

export default memo(MyPlayer);
