import React from "react";

const FIveStarsIconSVG = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="12"
            viewBox="0 0 60 12"
            fill="none"
        >
            <path
                d="M5.52447 1.46353C5.67415 1.00287 6.32585 1.00287 6.47553 1.46353L7.23483 3.80041C7.30176 4.00642 7.49374 4.1459 7.71036 4.1459H10.1675C10.6519 4.1459 10.8532 4.76571 10.4614 5.05041L8.47352 6.49468C8.29828 6.622 8.22495 6.84768 8.29188 7.0537L9.05118 9.39058C9.20086 9.85123 8.67362 10.2343 8.28176 9.94959L6.29389 8.50532C6.11865 8.378 5.88135 8.378 5.70611 8.50532L3.71824 9.94959C3.32638 10.2343 2.79914 9.85123 2.94882 9.39058L3.70812 7.0537C3.77505 6.84768 3.70172 6.622 3.52648 6.49468L1.53861 5.05041C1.14675 4.76571 1.34814 4.1459 1.8325 4.1459H4.28964C4.50626 4.1459 4.69824 4.00642 4.76517 3.80041L5.52447 1.46353Z"
                fill="#F1B063"
            />
            <path
                d="M17.5245 1.46353C17.6741 1.00287 18.3259 1.00287 18.4755 1.46353L19.2348 3.80041C19.3018 4.00642 19.4937 4.1459 19.7104 4.1459H22.1675C22.6519 4.1459 22.8532 4.76571 22.4614 5.05041L20.4735 6.49468C20.2983 6.622 20.2249 6.84768 20.2919 7.0537L21.0512 9.39058C21.2009 9.85123 20.6736 10.2343 20.2818 9.94959L18.2939 8.50532C18.1186 8.378 17.8814 8.378 17.7061 8.50532L15.7182 9.94959C15.3264 10.2343 14.7991 9.85123 14.9488 9.39058L15.7081 7.0537C15.7751 6.84768 15.7017 6.622 15.5265 6.49468L13.5386 5.05041C13.1468 4.76571 13.3481 4.1459 13.8325 4.1459H16.2896C16.5063 4.1459 16.6982 4.00642 16.7652 3.80041L17.5245 1.46353Z"
                fill="#F1B063"
            />
            <path
                d="M29.5245 1.46353C29.6741 1.00287 30.3259 1.00287 30.4755 1.46353L31.2348 3.80041C31.3018 4.00642 31.4937 4.1459 31.7104 4.1459H34.1675C34.6519 4.1459 34.8532 4.76571 34.4614 5.05041L32.4735 6.49468C32.2983 6.622 32.2249 6.84768 32.2919 7.0537L33.0512 9.39058C33.2009 9.85123 32.6736 10.2343 32.2818 9.94959L30.2939 8.50532C30.1186 8.378 29.8814 8.378 29.7061 8.50532L27.7182 9.94959C27.3264 10.2343 26.7991 9.85123 26.9488 9.39058L27.7081 7.0537C27.7751 6.84768 27.7017 6.622 27.5265 6.49468L25.5386 5.05041C25.1468 4.76571 25.3481 4.1459 25.8325 4.1459H28.2896C28.5063 4.1459 28.6982 4.00642 28.7652 3.80041L29.5245 1.46353Z"
                fill="#F1B063"
            />
            <path
                d="M41.5245 1.46353C41.6741 1.00287 42.3259 1.00287 42.4755 1.46353L43.2348 3.80041C43.3018 4.00642 43.4937 4.1459 43.7104 4.1459H46.1675C46.6519 4.1459 46.8532 4.76571 46.4614 5.05041L44.4735 6.49468C44.2983 6.622 44.2249 6.84768 44.2919 7.0537L45.0512 9.39058C45.2009 9.85123 44.6736 10.2343 44.2818 9.94959L42.2939 8.50532C42.1186 8.378 41.8814 8.378 41.7061 8.50532L39.7182 9.94959C39.3264 10.2343 38.7991 9.85123 38.9488 9.39058L39.7081 7.0537C39.7751 6.84768 39.7017 6.622 39.5265 6.49468L37.5386 5.05041C37.1468 4.76571 37.3481 4.1459 37.8325 4.1459H40.2896C40.5063 4.1459 40.6982 4.00642 40.7652 3.80041L41.5245 1.46353Z"
                fill="#F1B063"
            />
            <path
                d="M53.5245 1.46353C53.6741 1.00287 54.3259 1.00287 54.4755 1.46353L55.2348 3.80041C55.3018 4.00642 55.4937 4.1459 55.7104 4.1459H58.1675C58.6519 4.1459 58.8532 4.76571 58.4614 5.05041L56.4735 6.49468C56.2983 6.622 56.2249 6.84768 56.2919 7.0537L57.0512 9.39058C57.2009 9.85123 56.6736 10.2343 56.2818 9.94959L54.2939 8.50532C54.1186 8.378 53.8814 8.378 53.7061 8.50532L51.7182 9.94959C51.3264 10.2343 50.7991 9.85123 50.9488 9.39058L51.7081 7.0537C51.7751 6.84768 51.7017 6.622 51.5265 6.49468L49.5386 5.05041C49.1468 4.76571 49.3481 4.1459 49.8325 4.1459H52.2896C52.5063 4.1459 52.6982 4.00642 52.7652 3.80041L53.5245 1.46353Z"
                fill="#F1B063"
            />
        </svg>
    );
};

export default FIveStarsIconSVG;
