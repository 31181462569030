import { styleProcessing } from "API/plugins/Utilities";

export const AccountInfo = (theme) => {
    let commonStyle = {
        backgroundColor: "white",
        borderRadius: "20px",
        maxWidth: "558px",
        width: "100%",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, boxSizing: "border-box", padding: "16px", gap: "12px", marginBottom: "16px", maxWidth: "599px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Chips = {
    backgroundColor: "#F7F3FF",
    padding: "1px 10px 3px 10px",
    height: "25px",
    "& .MuiChip-label": {
        padding: 0,
    },
};

export const BottomBlock = (theme) => {
    let commonStyle = {
        borderRadius: "10px",
        border: "1px solid #EEEEF0",
        color: "#67677A",
        padding: "10px 0 10px 12px",
        display: "flex",
        gap: "10px",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, border: "none", padding: 0 },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TitleBlock = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
};

export const AccountTitle = {
    display: "flex",
    gap: "8px",
    alignItems: "center",
};

export const AnonymousProfileText = {
    display: "flex",
    gap: "8px",
    alignItems: "center",
};

export const AnonymousProfileSwitcher = {
    m: 0,
};

export const TooltipPopperProps = {
    sx: {
        "& .MuiTooltip-tooltip": {
            border: "none",
            color: "#222",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "140%",
            borderRadius: "10px",
            bgcolor: "#fff",
            width: "fit-content",
            boxShadow: "0px 2px 10px 0px rgba(106, 99, 118, 0.15)",
            padding: "10px",
        },
    },
};

export const TooltipButton = {
    padding: 0,
    height: "16px",
};

export const ProfilePhoto = (theme) => {
    let commonStyle = {
        width: "100px",
        borderRadius: "10px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            boxSizing: "border-box",
            width: "61px",
            height: "64px",
            overflow: "hidden",
            objectFit: "cover",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const PlayButton = {
    position: "absolute",
    left: "10px",
    bottom: "10px",
    margin: 0,
    padding: 0,
};

export const TopBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "20px",
        width: "100%",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "row-reverse",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const MobileTopBlock = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "7px",
};

export const InfoContentBlock = (theme) => {
    let commonStyle = {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "4px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const PhotoBlock = {
    position: "relative",
};

export const PhotoHiddenBlock = (theme) => {
    let commonStyle = {
        borderRadius: "10px",
        border: "1px dashed var(--Grey-Light-Grey, #BBBBC8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "48px",
        height: "142px",
        padding: "0 25px",
        textAlign: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            boxSizing: "border-box",
            width: "61px",
            height: "64px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const RowContainer = {
    display: "flex",
    gap: "5px",
    alignItems: "center",
};

export const ColumnContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
};
