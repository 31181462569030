import { styleProcessing } from "API/plugins/Utilities";

export const ButtonsContainer = {
    display: "flex",
    gap: "16px",
    height: "100%",
    alignItems: "end",
};

export const Button = {
    width: "192px",
};

export const PickerContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const mobilePadding = (theme) => {
    let commonStyle = {};

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "0 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ModalBusyMode = (theme) => {
    let commonStyle = { display: "flex", flexDirection: "column", gap: "30px", marginBottom: "30px" };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            marginBottom: "0px",
            background: "#FFFFFF",
            height: "100vh",
            paddingBottom: "30px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
