import Button from "@mui/material/Button";
import MenuListComposition from "components/UI/PopperMenu";
import * as React from "react";
import * as styles from "components/SettingsBodyBlock/StudentSettings/Profile/ProfileForms/ProfileFormsThemes";
import Paper from "@mui/material/Paper";
import { ListItemButton } from "@mui/material";
import DropDownSVG from "assets/CommonAssets/SVG/DropDownSVG";
import CheckMark from "assets/CommonAssets/SVG/CheckMark";
import SettingsStore from "store/SettingsStore";
import { observer } from "mobx-react-lite";
import { memo } from "react";

const EditSexContent = ({ setOpenSexPopper }) => {
    const handleChooseSex = (value, event) => {
        SettingsStore.setTemporaryProfileInfo("sex", value);
        setOpenSexPopper(false);
    };

    return (
        <Paper sx={styles.EditContentPaper}>
            {SettingsStore.sexList.map((sex, index) => (
                <ListItemButton
                    key={index}
                    disableRipple
                    onClick={handleChooseSex.bind(this, sex)}
                    sx={styles.EditContentListItem(sex === SettingsStore.sexList[0])}
                >
                    {sex}
                    {sex === SettingsStore.TemporaryUserInfo.sex && <CheckMark />}
                </ListItemButton>
            ))}
        </Paper>
    );
};

const EditGender = observer(({ children, ...props }) => {
    const [openSexPopper, setOpenSexPopper] = React.useState(false);

    return (
        <MenuListComposition
            open={openSexPopper}
            setOpen={setOpenSexPopper}
            content={<EditSexContent setOpenSexPopper={setOpenSexPopper} />}
            zIndex={1}
            paperSx={styles.EditPaper("306px")}
        >
            <Button
                disableRipple
                sx={styles.EditButton(openSexPopper, SettingsStore.TemporaryUserInfo.sex === SettingsStore.sexList[0], "305px")}
            >
                {SettingsStore.TemporaryUserInfo.sex}
                <DropDownSVG />
            </Button>
        </MenuListComposition>
    );
});

export default memo(EditGender);
