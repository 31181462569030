import React from "react";
import * as theme from "./IncomeBlockThemes";
import { memo } from "react";
import IncomeCalculator from "./IncomeCalculator/IncomeCalculator";
import IncomeImagePart from "./IncomeImagePart/IncomeImagePart";
import Box from "@mui/material/Box";
import { useMedias } from "../../../API/mediasHook";

const IncomeBlock = ({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={theme.IncomeBlock(medias)}>
            <IncomeCalculator />
            <IncomeImagePart />
        </Box>
    );
};

export default memo(IncomeBlock);
