import React, { memo, useEffect } from "react";
import UserStore from "store/UserStore";
import ToggleBalanceType from "./ToggleBalanceType/ToggleBalanceType";
import OperationsTable from "./OperationsTable/OperationsTable";
import BalanceStore from "store/BalanceStore";
import { Typography, Box } from "@mui/material";
import RefTable from "./RefTable/RefTable";
import BonusBox from "./BonusBox/BonusBox";
import * as styles from "./BalanceBodyBlockThemes";
import * as textStyles from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import FilterPanel from "./FilterPanel/FilterPanel";
import OperationsTableEmpty from "./OperationsTable/OperationsTableEmpty/OperationsTableEmpty";
import RefTableEmpty from "./RefTable/RefTableEmpty/RefTableEmpty";
import { useMedias } from "API/mediasHook";
import { observer } from "mobx-react-lite";

const BalanceBodyBlock = observer(() => {
    const medias = useMedias();

    const { t } = useTranslation();

    const [alignment, setAlignment] = React.useState(UserStore.role === "student" ? "OperationsTable" : "RefTable");

    return (
        <Box sx={styles.Balance}>
            <Box sx={styles.BalanceContent}>
                <Typography sx={textStyles.HeadlineH3Black}>{UserStore.role === "student" ? t("main.balance.myBalance") : t("main.balance.invitedUsers")}</Typography>
                <Box sx={styles.BalancePanelsRightLeft(medias)}>
                    <Box sx={styles.LeftPanel}>
                        {UserStore.role === "student" && (
                            <ToggleBalanceType
                                alignment={alignment}
                                setAlignment={setAlignment}
                            />
                        )}
                        <Box sx={styles.FilterAndTable}>
                            <FilterPanel alignment={alignment} />
                            {alignment === "OperationsTable" ? (
                                <Box sx={styles.TableAndEmpty}>
                                    <OperationsTable />
                                    {BalanceStore.balanceListFiltered.length === 0 ? <OperationsTableEmpty /> : null}
                                </Box>
                            ) : alignment === "RefTable" ? (
                                <Box sx={styles.TableAndEmpty}>
                                    <RefTable />
                                    {BalanceStore.refList.length === 0 ? <RefTableEmpty /> : null}
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                    {UserStore.role === "student" && medias.sm && <BonusBox />}
                </Box>
            </Box>
        </Box>
    );
});

export default memo(BalanceBodyBlock);
