import React, { memo } from "react";
import * as themes from "./TopQueriesThemes";
import * as textThemes from "themes/TextThemes";
import { Chip } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import MainPageStore from "store/MainPageStore";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";

const TopQueries = observer(({ children, ...props }) => {
    const handleClick = (query, e) => {};

    return (
        <Box>
            {props.variant === "query"
                ? MainPageStore.popularQueriesBottom.map((query) => (
                      <ThemeProvider
                          theme={themes.TagTheme}
                          key={query.ID}
                      >
                          <Chip
                              key={query.ID}
                              label={query.Query}
                              variant="outlined"
                              size="small"
                              disableRipple
                              sx={textThemes.Body2White90}
                              onClick={handleClick.bind(this, query)}
                          ></Chip>
                      </ThemeProvider>
                  ))
                : MainPageStore.popularSubjectsBottom.map((query) => (
                      <ThemeProvider
                          theme={themes.TagTheme}
                          key={query.ID}
                      >
                          <Chip
                              key={query.ID}
                              label={query.Query}
                              variant="outlined"
                              size="small"
                              disableRipple
                              sx={textThemes.Body2White90}
                              onClick={handleClick.bind(this, query)}
                          ></Chip>
                      </ThemeProvider>
                  ))}
        </Box>
    );
});

export default memo(TopQueries);
