const VideoIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon/20/image">
                <path
                    id="video"
                    d="M8.125 7.275V12.725C8.125 13.0375 8.4675 13.2288 8.73375 13.0662L12.8075 10.5763C12.9063 10.516 12.9879 10.4313 13.0446 10.3304C13.1012 10.2295 13.131 10.1157 13.131 10C13.131 9.88427 13.1012 9.77049 13.0446 9.66958C12.9879 9.56867 12.9063 9.48401 12.8075 9.42375L8.7325 6.935C8.67189 6.89822 8.6026 6.87822 8.53172 6.87703C8.46083 6.87584 8.39091 6.89351 8.3291 6.92823C8.26729 6.96294 8.21582 7.01347 8.17996 7.07462C8.14409 7.13577 8.12513 7.20411 8.125 7.275ZM5.625 3.75C4.7962 3.75 4.00134 4.07924 3.41529 4.66529C2.82924 5.25134 2.5 6.0462 2.5 6.875V13.125C2.5 13.9538 2.82924 14.7487 3.41529 15.3347C4.00134 15.9208 4.7962 16.25 5.625 16.25H14.375C15.2038 16.25 15.9987 15.9208 16.5847 15.3347C17.1708 14.7487 17.5 13.9538 17.5 13.125V6.875C17.5 6.0462 17.1708 5.25134 16.5847 4.66529C15.9987 4.07924 15.2038 3.75 14.375 3.75H5.625ZM3.75 6.875C3.75 6.37772 3.94754 5.90081 4.29917 5.54917C4.65081 5.19754 5.12772 5 5.625 5H14.375C14.8723 5 15.3492 5.19754 15.7008 5.54917C16.0525 5.90081 16.25 6.37772 16.25 6.875V13.125C16.25 13.6223 16.0525 14.0992 15.7008 14.4508C15.3492 14.8025 14.8723 15 14.375 15H5.625C5.12772 15 4.65081 14.8025 4.29917 14.4508C3.94754 14.0992 3.75 13.6223 3.75 13.125V6.875Z"
                    fill="#4E4E4E"
                />
            </g>
        </svg>
    );
};

export default VideoIcon;
