import React from "react";
const Message2 = () => {
    return (
        <svg
            width="70"
            height="66"
            viewBox="0 0 70 66"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_5323_111485)">
                <circle
                    cx="35"
                    cy="31"
                    r="25"
                    fill="#6212FF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.9651 22C29.0955 22 27.5716 22.4713 26.5197 23.5311C25.4679 24.5908 25 26.1261 25 28.0096V32.4612C25 34.7446 25.4475 36.3304 26.5519 37.304C27.6345 38.2584 29.186 38.4709 30.9651 38.4709H31.4025C31.4049 38.4712 31.4125 38.4723 31.4253 38.4758C31.447 38.4817 31.4741 38.4918 31.5025 38.506C31.531 38.5202 31.5542 38.5353 31.5702 38.5478C31.5767 38.5529 31.5807 38.5565 31.5826 38.5584L31.5837 38.5599C31.5837 38.5599 32.3958 39.3058 33.2162 40.1071C33.5304 40.414 33.7922 40.6454 33.9957 40.8141C34.2946 41.062 34.7054 41.062 35.0043 40.8141C35.2078 40.6454 35.4696 40.414 35.7838 40.1071C36.6042 39.3058 37.4137 38.5633 37.4137 38.5633L37.4147 38.5621C37.4592 38.5039 37.5298 38.4709 37.593 38.4709H38.0349C39.9045 38.4709 41.4284 37.9995 42.4803 36.9398C43.5322 35.8801 44 34.3448 44 32.4612V28.0096C44 26.1261 43.5322 24.5908 42.4803 23.5311C41.4284 22.4713 39.9045 22 38.0349 22H30.9651ZM30.3919 31.8606C31.1009 31.8606 31.6757 31.2816 31.6757 30.5673C31.6757 29.8529 31.1009 29.2739 30.3919 29.2739C29.6829 29.2739 29.1081 29.8529 29.1081 30.5673C29.1081 31.2816 29.6829 31.8606 30.3919 31.8606ZM35.7838 30.5673C35.7838 31.2816 35.209 31.8606 34.5 31.8606C33.791 31.8606 33.2162 31.2816 33.2162 30.5673C33.2162 29.8529 33.791 29.2739 34.5 29.2739C35.209 29.2739 35.7838 29.8529 35.7838 30.5673ZM38.6081 31.8606C39.3171 31.8606 39.8919 31.2816 39.8919 30.5673C39.8919 29.8529 39.3171 29.2739 38.6081 29.2739C37.8991 29.2739 37.3243 29.8529 37.3243 30.5673C37.3243 31.2816 37.8991 31.8606 38.6081 31.8606Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_5323_111485"
                    x="0"
                    y="0"
                    width="70"
                    height="70"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite
                        in2="hardAlpha"
                        operator="out"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.199138 0 0 0 0 0.0458333 0 0 0 0 0.5 0 0 0 0.2 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_5323_111485"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_5323_111485"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};

export default Message2;
