import { createTheme } from "@mui/material/styles";

export const videoContentContainerStyle = (state) => {
    return {
        position: "relative",
        margin: "10px 44px 10px 44px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        borderRadius: "20px",
        background: state ? "var(--Text-Black, #222)" : "var(--BG-White, #FFF)",
    };
};

export const videoStyle = (state) => {
    return {
        display: state ? "block" : "none",
        width: "100%",
        height: "100%",
        maxHeight: "92.2vh",
        borderRadius: "20px",
    };
};

export const videoMockBlockStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 332,
    gap: 20,
};

export const AvatarTheme = (mode) =>
    createTheme({
        components: {
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        position: "relative",
                        width: mode ? "40px" : "70px",
                        height: mode ? "40px" : "70px",
                        left: "0px",
                        top: "0px",
                        borderRadius: "50%",
                        cursor: "default",
                    },
                },
            },
        },
    });

export const nameStyle = {
    color: "var(--Text-Black, #222)",
    textAlign: "center",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
};

export const startingTimeContainerStyle = {
    display: "flex",
    gap: 5,
};

export const startingTimeLabelStyle = {
    color: "var(--Text-Dark-Grey, #67677A)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const startingTimeStyle = {
    color: "var(--Main-Purple, #6212FF)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const studentVideoContainerStyle = {
    position: "absolute",
    left: 20,
    bottom: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 230,
    height: 130,
    borderRadius: "16px",
    background: "#262030",
};

export const nameAndMicroStateContainerStyle = (mode) => {
    if (mode === 0) {
        return {
            position: "absolute",
            left: 10,
            bottom: 10,
            display: "flex",
            padding: "1px 10px 3px 10px",
            alignItems: "center",
            gap: "4px",
            borderRadius: "42px",
            background: "var(--BG-Footer30, rgba(23, 15, 38, 0.30))",
            backdropFilter: "blur(3.4033873081207275px)",
        };
    } else {
        return {
            display: "flex",
            alignItems: "center",
            gap: "4px",
            borderRadius: "42px",
        };
    }
};

export const studentNameStyle = {
    color: "var(--Text-White, #FFF)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const tutorNameStyle = {
    color: "var(--Text-White, #FFF)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const previewStyle = {
    objectFit: "cover",
    height: "-webkit-fill-available",
    width: "-webkit-fill-available",
    borderRadius: "16px",
};

export const tutorInfoContainerStyle = {
    position: "absolute",
    left: 20,
    top: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 227,
    height: 42,
    padding: "1px 10px 1px 1px",
    gap: "8px",
    borderRadius: "42px",
    background: "#262030",
};
