import { styleProcessing } from "API/plugins/Utilities";
import arrowPrev from "assets/CommonAssets/SVG/arrowSwiperBack1.png";
import arrowNext from "assets/CommonAssets/SVG/arrowSwiperNext1.png";

export const styles = `
.swiper-button-prev {
    color: black;
    background-image: url(${arrowPrev}) !important;
    background-repeat: no-repeat;
    padding: 4px;
}

.swiper-button-next {
    color: black;
    background-image: url(${arrowNext}) !important;
    background-repeat: no-repeat;
    padding: 4px;
}
.swiper-slide div {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    /* or 18px */

   

    /* BG/White */
    color: #FFFFFF;
    border-radius: 10px;

}
.innerSlide { transition: all .2s ease-in-out; }
.innerSlide:hover { transform: scale(108%); }

.swiper-slide div span {
    padding-left: 5px;
    padding-bottom: 9px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
    content: "";
}
.swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

}
.disabled-button {
    opacity: 0;
}                
`;

export const storyContainer = (index, length, theme) => {
    let commonStyle = {
        borderRadius: "10px",
        border: "1px solid #6212FF",
        background: "#FFF",
        padding: "4px",
        marginLeft: index === 0 ? "20px" : null,
        marginRight: index === length - 1 ? "20px" : null,
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, marginLeft: "none", marginRight: "none" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const miniStories = (image) => {
    return {
        backgroundImage: image,
        width: "113px",
        height: "100px",
        fontSize: "13px",
        color: "white",
        display: "flex",
        alignItems: "flex-end",
        padding: "9px 0px 9px 5px",
    };
};

export const swiperSlide = {
    maxWidth: "fit-content",
    cursor: "pointer",
};
