import { styleProcessing } from "API/plugins/Utilities";

export const MyClasses = (theme) => {
    let commonStyle = {
        borderRadius: "0px 0px 40px 40px",
        background: "#F7F7F7",
        display: "flex",
        justifyContent: "center",
        paddingBottom: "70px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "24px 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const MyClassesContent = {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    paddingTop: "40px",
    minHeight: "780px",
    maxWidth: "1216px",
    width: "100%",
    alignItems: "flex-start",
};
