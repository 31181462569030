import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./TagsBlockThemes";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Chip } from "@mui/material";

const TagsBlock = observer(({ children, ...props }) => {
    return (
        <Box sx={themes.tagsBlockContainerStyle}>
            {props.value.Tags.map((tag) => (
                <ThemeProvider
                    theme={themes.TagTheme}
                    key={tag.ID}
                >
                    <Chip
                        key={tag.ID}
                        label={tag.Query}
                        variant="outlined"
                        size="small"
                        disableRipple
                        sx={themes.textStyle}
                        onClick={() => console.log(tag)}
                    ></Chip>
                </ThemeProvider>
            ))}
        </Box>
    );
});

export default memo(TagsBlock);
