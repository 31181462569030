import React from "react";
import { useState } from "react";
import { Typography, MenuList, MenuItem, ThemeProvider, Box } from "@mui/material";
import Vk from "assets/PAAssets/SVG/vk";
import * as textThemes from "themes/TextThemes";
import * as themes from "./FooterThemes";
import { IconButton } from "@mui/material";
import ReadMore from "assets/PAAssets/SVG/ReadMore";
import TopQueries from "./TopQueries/TopQueries";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TelegramIcon from "assets/CommonAssets/SVG/TelegramIcon";
import ReadLess from "assets/PAAssets/SVG/ReadLess";
import { memo } from "react";

const Footer = ({ children, ...props }) => {
    const location = useLocation();

    const [open, setOpen] = useState(true);

    const { t, i18n } = useTranslation();

    const promoPages = ["/", "/cashback", "/referral", "/promotutor", "/studentrules"];

    const pagesWithoutFooter = ["/video-chat", "/payment", "/authorization", "/questionnaire", "/buddyrules", "/questionnaire-on-check"];

    if (pagesWithoutFooter.includes(location.pathname)) {
        return;
    }

    return (
        <Box sx={themes.Footer}>
            {promoPages.includes(location.pathname) && (
                <>
                    <Box sx={themes.FooterExpandable(open)}>
                        <Box sx={themes.FooterExpandableContent}>
                            <Box sx={themes.FooterExpandableTitleAndInfo}>
                                <Typography sx={textThemes.HeadlineH3White90}>{t("main.footerContent.tutorsForPupil")}</Typography>
                                <Box sx={themes.BasicInfoContainer}>
                                    <Box sx={themes.BasicInfoBox}>
                                        <Typography sx={textThemes.HeadlineH5White90}>{t("main.footerContent.tutorSearchTitle")}</Typography>
                                        <Typography sx={textThemes.Body2White70}>{t("main.footerContent.tutorSearchText")}</Typography>
                                    </Box>
                                    <Box sx={themes.BasicInfoBox}>
                                        <Typography sx={textThemes.HeadlineH5White90}>{t("main.footerContent.studentHelpTitle")}</Typography>
                                        <Typography sx={textThemes.Body2White70}>{t("main.footerContent.studentHelpText")}</Typography>
                                    </Box>
                                    <Box sx={themes.BasicInfoBox}>
                                        <Typography sx={textThemes.HeadlineH5White90}>{t("main.footerContent.tutorWorkTitle")}</Typography>
                                        <Typography sx={textThemes.Body2White70}>{t("main.footerContent.tutorWorkText")}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={themes.QueryBlock}>
                                <Typography sx={textThemes.HeadlineH41White90}>{t("main.footerContent.popularQueries")}</Typography>
                                <TopQueries variant="query" />
                            </Box>
                            <Box sx={themes.QueryBlock}>
                                <Typography sx={textThemes.HeadlineH41White90}>{t("main.footerContent.SchoolAndStudentSubjects")}</Typography>
                                <TopQueries />
                            </Box>
                        </Box>
                    </Box>
                    {open && <Box sx={themes.Gradient}></Box>}
                    <IconButton
                        onClick={() => {
                            setOpen(!open);
                        }}
                        sx={themes.ReadMoreIcon}
                        disableRipple
                    >
                        {open ? <ReadLess /> : <ReadMore />}
                    </IconButton>
                </>
            )}

            <Box sx={themes.FooterMenus}>
                <Box sx={themes.FooterMenusContent(promoPages.includes(location.pathname))}>
                    <Box sx={themes.FooterMenusAndSocial}>
                        <Box sx={themes.FooterMenusContainer}>
                            <ThemeProvider theme={themes.menu}>
                                <Box sx={themes.FooterMenuOuterBox}>
                                    <Typography sx={textThemes.HeadlineH5White90}>{t("main.footerContent.aboutUs")}</Typography>
                                    <MenuList sx={themes.FooterMenu}>
                                        <MenuItem disableRipple>
                                            <Typography sx={textThemes.Body2White70}>{t("main.footerContent.aboutCompany")}</Typography>
                                        </MenuItem>
                                        <MenuItem disableRipple>
                                            <Typography sx={textThemes.Body2White70}>{t("main.footerContent.howWeAreWorking")}</Typography>
                                        </MenuItem>
                                        <MenuItem disableRipple>
                                            <Typography sx={textThemes.Body2White70}>{t("main.footerContent.reviews")}</Typography>
                                        </MenuItem>
                                        <MenuItem disableRipple>
                                            <Typography sx={textThemes.Body2White70}>{t("main.footerContent.workingAtStudyBuddy")}</Typography>
                                        </MenuItem>
                                        <MenuItem disableRipple>
                                            <Typography sx={textThemes.Body2White70}>{t("main.footerContent.affiliateProgram")}</Typography>
                                        </MenuItem>
                                        <MenuItem disableRipple>
                                            <Typography sx={textThemes.Body2White70}>{t("main.footerContent.contacts")}</Typography>
                                        </MenuItem>
                                    </MenuList>
                                </Box>
                                <Box sx={themes.FooterMenuOuterBox}>
                                    <Typography sx={textThemes.HeadlineH5White90}>{t("main.footerContent.forStudents")}</Typography>
                                    <MenuList sx={themes.FooterMenu}>
                                        <MenuItem disableRipple>
                                            <Typography sx={textThemes.Body2White70}>{t("main.footerContent.chooseATutor")}</Typography>
                                        </MenuItem>
                                        <MenuItem disableRipple>
                                            <Typography sx={textThemes.Body2White70}>{t("main.footerContent.graduateWork")}</Typography>
                                        </MenuItem>
                                        <MenuItem disableRipple>
                                            <Typography sx={textThemes.Body2White70}>{t("main.footerContent.createAnOrder")}</Typography>
                                        </MenuItem>
                                        <MenuItem disableRipple>
                                            <Typography sx={textThemes.Body2White70}>{t("main.footerContent.questionsAndAnswers")}</Typography>
                                        </MenuItem>
                                        <MenuItem disableRipple>
                                            <Typography sx={textThemes.Body2White70}>{t("main.footerContent.inviteFriend")}</Typography>
                                        </MenuItem>
                                        <MenuItem disableRipple>
                                            <Typography sx={textThemes.Body2White70}>{t("main.footerContent.bonusesForStudents")}</Typography>
                                        </MenuItem>
                                    </MenuList>
                                </Box>
                                <Box sx={themes.FooterMenuOuterBox}>
                                    <Typography sx={textThemes.HeadlineH5White90}>{t("main.footerContent.forTutors")}</Typography>
                                    <MenuList sx={themes.FooterMenu}>
                                        <MenuItem disableRipple>
                                            <Typography sx={textThemes.Body2White70}>{t("main.footerContent.becomeATutor")}</Typography>
                                        </MenuItem>
                                        <MenuItem disableRipple>
                                            <Typography sx={textThemes.Body2White70}>{t("main.footerContent.termsOfCooperation")}</Typography>
                                        </MenuItem>
                                        <MenuItem disableRipple>
                                            <Typography sx={textThemes.Body2White70}>{t("main.footerContent.ordersForTutors")}</Typography>
                                        </MenuItem>
                                        <MenuItem disableRipple>
                                            <Typography sx={textThemes.Body2White70}>{t("main.footerContent.referralProgram")}</Typography>
                                        </MenuItem>
                                    </MenuList>
                                </Box>
                            </ThemeProvider>
                        </Box>
                        <Box sx={themes.SocialNetworks}>
                            <Typography sx={textThemes.HeadlineH5White90}>{t("main.footerContent.weAtSocialNetworks")}</Typography>
                            <Box sx={themes.NetworksButtons}>
                                <IconButton
                                    sx={themes.IconButton}
                                    disableRipple
                                >
                                    <Vk />
                                </IconButton>
                                <IconButton
                                    sx={themes.IconButton}
                                    disableRipple
                                >
                                    <TelegramIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={themes.FooterBottom}>
                        <Box sx={themes.FooterBottomRight}>
                            <Typography sx={textThemes.Body2White40}>{t("main.footerContent.studyBuddy")}</Typography>
                            <Typography sx={textThemes.Body2White40}>{t("main.footerContent.ooo")}</Typography>
                        </Box>
                        <Typography sx={textThemes.Body2White40}>{t("main.footerContent.legalInformation")}</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default memo(Footer);
