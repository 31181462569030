import React from "react";
import { observer } from "mobx-react-lite";

import { Box } from "@mui/material";

import MenuShowImage from "./MenuShowImage/MenuShowImage";

import * as styles from "./ImageChatAttachmentsThemes";

import { useGetChatImageAttachment } from "hooks/useGetChatImageAttachment";

const SingleImageAttachment = ({ link, handleClick, handleDownload, disabled }) => {
    const imageBlobLink = useGetChatImageAttachment(link.link);

    return (
        <Box sx={styles.imageBox}>
            <img
                style={styles.imageContainer}
                src={imageBlobLink}
                onClick={handleClick}
                disabled={disabled}
            />
            <MenuShowImage
                handleDownload={handleDownload}
                link={link.link}
            />
        </Box>
    );
};

export default observer(SingleImageAttachment);
