import MyClassesStore from "store/MyClassesStore";
import Box from "@mui/material/Box";
import * as styles from "./SubjectDateBlockThemes";
import Calendar from "assets/PAAssets/SVG/calendar";
import { Typography } from "@mui/material";
import * as textStyles from "themes/TextThemes";
import Clock from "assets/PAAssets/SVG/clock";
import React from "react";
import StartClassButton from "./StartClassButton/StartClassButton";
import { memo } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import SButton from "components/UI/SButton";
import UserStore from "store/UserStore";
import { useNavigate } from "react-router-dom";

const SubjectDateBlock = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const OpenMyClasses = () => {
        navigate("/myclasses");
        UserStore.setPage(1);
    };

    const getFormatedDate = (index) => {
        const dateObject = MyClassesStore.formatTodayClassDate(index);

        return `${dateObject.date} ${t(dateObject.month)}, ${t(dateObject.weekday)}`;
    };

    return (
        <>
            {MyClassesStore.findNearestTodayClassIndex() !== null ? (
                <Box sx={styles.TodayClassDetails}>
                    1
                    <Box sx={styles.TodayClassDateTime}>
                        <Box sx={styles.TodayClassDate}>
                            <Calendar />
                            <Typography sx={textStyles.Body1Black}>{getFormatedDate(MyClassesStore.findNearestTodayClassIndex())}</Typography>
                        </Box>
                        <Box sx={styles.TodayClassDate}>
                            <Clock />
                            <Typography sx={textStyles.Body1Black}>{MyClassesStore.formatTodayClassTime(MyClassesStore.findNearestTodayClassObject())}</Typography>
                        </Box>
                    </Box>
                    <StartClassButton />
                </Box>
            ) : (
                <Box sx={styles.NextClass(props.type)}>
                    {MyClassesStore.findNearestClassIndex() === null ? (
                        <Box sx={styles.AllClasses}>
                            <Typography
                                sx={props.type === "account" ? textStyles.Body2Grey : textStyles.Body1Grey}
                                component={"div"}
                            >
                                {t("main.myClasses.next")}:<span style={props.type === "account" ? textStyles.Body2Black : textStyles.Body1Black}>{t("main.myClasses.noUpcomingClasses")}</span>
                            </Typography>
                            {props.type === "account" ? (
                                <SButton
                                    variant="text-arrow"
                                    onClick={OpenMyClasses}
                                >
                                    {t("main.myClasses.allClasses")}
                                </SButton>
                            ) : null}
                        </Box>
                    ) : (
                        <Box sx={styles.AllClasses}>
                            <Typography
                                sx={props.type === "account" ? textStyles.Body2Grey : textStyles.Body1Grey}
                                component={"div"}
                            >
                                {t("main.myClasses.next")}:
                                <span
                                    style={props.type === "account" ? textStyles.Body2Black : textStyles.Body1Black}
                                >{` ${MyClassesStore.classes[MyClassesStore.findNearestClassIndex()].subject}, ${MyClassesStore.formatNearestClassStartTime(MyClassesStore.findNearestClassIndex())}`}</span>
                            </Typography>
                            {props.type === "account" ? (
                                <SButton
                                    variant="text-arrow"
                                    onClick={OpenMyClasses}
                                >
                                    {t("main.myClasses.allClasses")}
                                </SButton>
                            ) : null}
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
});

export default memo(SubjectDateBlock);
