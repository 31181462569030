import React from "react";
import { Box, Typography } from "@mui/material";
import LikeDislikeBlock from "components/AccountBodyBlock/StudentAccount/Stories/Stories/LikeDislikeBlock/LikeDislikeBlock";
import * as textThemes from "themes/TextThemes";
import * as themes from "components/AccountBodyBlock/StudentAccount/Stories/Stories/StoryThemes";
import { useTranslation } from "react-i18next";
import { memo } from "react";

const WhatAboutBonusesStory = (children, ...props) => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.StoryBox}>
            <Box sx={themes.WhatAboutBonusesOuter}>
                <Box sx={themes.StoryText}>
                    <Typography sx={textThemes.Caption700White}>{t("main.studentAccount.stories.whatAboutBonuses")}</Typography>
                    <Typography sx={textThemes.Body1White}>{t("main.studentAccount.stories.cashbackDisplayed")}</Typography>
                </Box>
            </Box>
            <LikeDislikeBlock story="WhatAboutBonusesStory" />
        </Box>
    );
};

export default memo(WhatAboutBonusesStory);
