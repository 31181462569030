import React from "react";
import * as styles from "./ToggleBalanceTypeThemes";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import { useMedias } from "API/mediasHook";

const ToggleBalanceType = ({ alignment, setAlignment }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const handleChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
            const buttonElement = event.target;

            if (!medias.sm) {
                buttonElement.scrollIntoView({ behavior: "smooth" });
            }
        }
    };

    return (
        <ToggleButtonGroup
            exclusive
            value={alignment}
            onChange={handleChange}
            sx={styles.ToggleButtonGroup(medias)}
        >
            {!medias.sm && (
                <ToggleButton
                    disableRipple
                    value="balanceBox"
                    style={styles.ToggleButtonStyle("balanceBox", alignment)}
                    sx={styles.ToggleButtonSx(medias)}
                >
                    {t("main.balance.bonusAccount")}
                </ToggleButton>
            )}
            <ToggleButton
                disableRipple
                value="OperationsTable"
                style={styles.ToggleButtonStyle("Operations", alignment)}
                sx={styles.ToggleButtonSx(medias)}
            >
                {t("main.balance.operationsHistory")}
            </ToggleButton>
            <ToggleButton
                disableRipple
                value="RefTable"
                style={styles.ToggleButtonStyle("Ref", alignment)}
                sx={styles.ToggleButtonSx(medias)}
            >
                {t("main.balance.referralAccount")}
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default memo(ToggleBalanceType);
