export const TutorPromoBodyBlock = (medias) => {
    return {
        background: "#F7F7F7",
        display: "flex",
        flexDirection: "column",
        gap: medias.sm ? "140px" : "50px",
        alignItems: "center",
        borderRadius: "0px 0px 40px 40px",
        padding: "60px 0 118px 0",
    };
};
