export const chatBox = {
    width: "99%",
    height: "99vh",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    // alignItems: 'stretch',
    // overflow: 'hidden',
    //minHeight: "500px",
    //padding: "30px",
};

export const sendMessage = {
    paddingLeft: "10px",
    paddingRight: "10px",
};

export const headerChat = {
    height: "6vh",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    gap: "10px",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F7F7F7",
    paddingTop: "8px",
    paddingBottom: "8px",
    borderBottom: "1px solid #BBBBC8",
};

export const ChatFont = {
    fontSize: "2.2vh",
    float: "left",
    paddingTop: "10px",
    paddingLeft: "1vw",
    width: "20vw",
    justifyContent: "flex-start",
};

export const MessageGroup = {
    display: "flex",
    justifyContent: "center",
};
