import { useEffect, useState } from "react";

import FullChatStore from "store/FullChatStore";

export const useGetChatImageAttachment = (link = null, deps = []) => {
    const [image, setImage] = useState("");

    useEffect(() => {
        async function loadImage() {
            const imageContent = await FullChatStore.LoadImage(link);

            const imageObjectURL = URL.createObjectURL(imageContent);

            setImage(imageObjectURL);
        }

        if (link !== null) {
            loadImage();
        }
    }, deps);
    return image;
};
