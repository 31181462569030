import { styleProcessing } from "API/plugins/Utilities";

export const OrderCard = (theme) => {
    let commonStyle = {
        maxWidth: "340px",
        minWidth: "330px",
        height: "281px",
        backgroundColor: "#FFFFFF",
        borderRadius: "20px",
        padding: "20px",
        cursor: "pointer",
        boxShadow: "0px 4px 30px 0px rgba(106, 99, 118, 0.05)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxSizing: "border-box",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, width: "290px", height: "326px", justifyContent: "start", gap: "22px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Chips = {
    borderRadius: "20px",
    border: "1px solid #BBBBC8",
    backgroundColor: "white",
    padding: "2px 10px 4px 10px",
    ".MuiChip-label": {
        padding: 0,
    },
    color: "#222",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const WhereBlock = {
    display: "flex",
    rowGap: "0",
    columnGap: "4px",
    flexWrap: "wrap",
};

export const CostAndTimeAgo = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
};

export const ChipContainer = {
    display: "flex",
    gap: "4px",
    flexWrap: "wrap",
};

export const TitleBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const UndergroundColorBlock = (item) => {
    return {
        width: "10px",
        height: "10px",
        backgroundColor: item,
        color: item,
    };
};

export const UndergroundCircleAndText = {
    display: "flex",
    gap: "3px",
    alignItems: "center",
};

export const WhereAndWhenContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
};

export const BottomBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "24px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, height: "100%", justifyContent: "space-between" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
