import React from "react";
import { Typography } from "@mui/material";
import * as themes from "./WhoNeedThemes";
import * as textThemes from "themes/TextThemes";
import { memo } from "react";
import Quality from "./Quality/Quality";
import icon1 from "assets/PromoTutorAssets/PNG/icon1.png";
import icon2 from "assets/PromoTutorAssets/PNG/icon2.png";
import icon3 from "assets/PromoTutorAssets/PNG/icon3.png";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import AuthorizationStore from "store/AuthorizationStore";
import { useNavigate } from "react-router-dom";
import { useMedias } from "../../../API/mediasHook";

const WhoNeed = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const handleClickSignUp = () => {
        AuthorizationStore.setPreviousLocationPath(location.pathname);
        AuthorizationStore.setCurrentAuthorizationStep("SignUpTutor");
        AuthorizationStore.setSignUpPhone(null);
        AuthorizationStore.setSignUpPhoneError(false);
        AuthorizationStore.setSignUpName("");
        AuthorizationStore.setSignUpEmail("");
        AuthorizationStore.setSignUpEmailError(false);
        AuthorizationStore.setSignUpEmailErrorText("");
        AuthorizationStore.setTutorOfferAgree(false);
        AuthorizationStore.setTutorRulesAgree(false);
        navigate("/authorization");
    };

    const medias = useMedias();

    return (
        <Box sx={themes.WhoNeedBlock(medias)}>
            <Box sx={themes.LeftBlock(medias)}>
                <Typography sx={textThemes.HeadlineH2White(medias)}>{t("promoPages.promoTutor.whichTutors")}</Typography>
                {medias.sm && (
                    <Box
                        sx={themes.ButtonBlock}
                        onClick={handleClickSignUp}
                    >
                        <Typography sx={textThemes.Button16Purple}>{t("promoPages.promoTutor.signUp")}</Typography>
                    </Box>
                )}
            </Box>
            <Box sx={themes.qualityBlock(medias)}>
                <Quality
                    header={t("promoPages.promoTutor.responsibleAndKnowsSubjects")}
                    text={t("promoPages.promoTutor.qualityImportant")}
                />

                <Quality
                    header={t("promoPages.promoTutor.likeAndCan")}
                    text={t("promoPages.promoTutor.desireImportant")}
                />

                <Quality
                    header={t("promoPages.promoTutor.internetAvailable")}
                    text={t("promoPages.promoTutor.timeImportant")}
                />
            </Box>

            {!medias.sm && (
                <Box
                    sx={themes.ButtonBlock}
                    onClick={handleClickSignUp}
                >
                    <Typography sx={textThemes.Button16Purple}>{t("promoPages.promoTutor.signUp")}</Typography>
                </Box>
            )}
            <Box sx={themes.IconBlock1(medias)}>
                <img
                    src={icon1}
                    alt={1}
                />
            </Box>
            <Box sx={themes.IconBlock2(medias)}>
                <img
                    src={icon2}
                    alt={2}
                />
            </Box>
            <Box sx={themes.IconBlock3(medias)}>
                <img
                    src={icon3}
                    alt={3}
                />
            </Box>
        </Box>
    );
});

export default memo(WhoNeed);
