export const TopText = {
    color: "#67677A",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    marginBottom: "30px",
};

export const TextField = {
    width: "492px",

    "& .MuiInput-underline:before": {
        borderBottom: "1px solid #BBBBC8",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#6212FF",
    },
    "& .MuiInputBase-input": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "16px",
        lineHeight: "150%",
        fontWeight: 400,
        textTransform: "none",
    },
};

export const FormWrapper = {
    display: "flex",
    alignItems: "center",
    marginTop: "16px",
};

export const FormControlLabel = { p: 0, mr: 0, ml: 0, mb: 0, mt: "3px" };

export const Checkbox = { p: 0, m: 0 };

export const BottomText = {
    marginLeft: "10px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "150%",
    fontWeight: 400,
    textTransform: "none",
    color: "#222",
    width: "390px",
};
