import { Button, Grid, List } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import React from "react";
import * as themes from "./PeriodFilterThemes";
import { SelectedOptionIcon } from "assets/PaidServices/SVG/SelectedOptionIcon";
import MenuListComposition from "components/UI/PopperMenu";
import DropDownSVG from "assets/CommonAssets/SVG/DropDownSVG";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import TeacherPaidServicesStore from "store/TeacherPaidServicesStore";
import { observer } from "mobx-react-lite";

const PeriodFilter = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const [openMyFilter, setOpenMyFilter] = React.useState(false);

    const options = ["paidServices.lastWeek", "paidServices.lastMonth", "paidServices.lastSixMonths", "paidServices.lastYear", "paidServices.allTime", "paidServices.period"];

    const handleMenuItemClick = (event, index) => {
        TeacherPaidServicesStore.setActiveFilterOption(index);
        setOpenMyFilter(false);
    };

    return (
        <Grid sx={themes.ReportButtonPos}>
            <MenuListComposition
                placement="bottom-start"
                open={openMyFilter}
                setOpen={setOpenMyFilter}
                content={
                    <Paper sx={themes.Paper}>
                        <List sx={themes.List}>
                            {options.map((option, index) => (
                                <MenuItem
                                    disableRipple
                                    key={option}
                                    sx={themes.MenuItem}
                                    onClick={(event) => handleMenuItemClick(event, index)}
                                >
                                    {t(option)}
                                    {index === TeacherPaidServicesStore.activeFilterOption ? <SelectedOptionIcon /> : <></>}
                                </MenuItem>
                            ))}
                        </List>
                    </Paper>
                }
                zIndex={1}
                paperSx={themes.PaperSx}
            >
                <Button
                    disableRipple
                    sx={themes.filterButtonSX}
                >
                    {t(options[TeacherPaidServicesStore.activeFilterOption])}
                    <DropDownSVG />
                </Button>
            </MenuListComposition>
        </Grid>
    );
});

export default memo(PeriodFilter);
