import { styleProcessing } from "API/plugins/Utilities";

export const ProfileTitle = (theme) => {
    let commonStyle = {
        borderRadius: "20px 20px 0px 0px",
        background: "#222",
        padding: "9px 0px 9px 30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "9px 0px 9px 20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ProfileButtons = (theme) => {
    let commonStyle = {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        gap: "16px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            justifyContent: "flex-end",
            gap: "22px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const buttonSize = (theme) => {
    let commonStyle = {
        width: "115px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "136px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ProfileContent = (theme) => {
    let commonStyle = {
        padding: "30px",
        display: "flex",
        flexDirection: "column",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Profile = {
    maxWidth: "701px",
    minWidth: "328px",
    width: "100%",
    borderRadius: "20px",
    background: "#FFF",
    boxShadow: "0px 4px 30px 0px rgba(106, 99, 118, 0.05)",
};
