import React, { useState } from "react";
import TimeIcon from "assets/CommonAssets/SVG/TimeIcon";
import "./CustomTimePicker.css";
import Box from "@mui/material/Box";

const CustomTimeInput = ({ time, setTime }) => {
    const handleChange = (event) => {
        const { value } = event.target;

        const formattedValue = formatTimeInput(value);

        setTime(formattedValue);
    };

    const formatTimeInput = (input) => {
        // Remove non-digit characters
        const cleanedInput = input.replace(/[^\d]/g, "");

        // Format the input as "hh:mm"
        if (cleanedInput.length <= 2) {
            return cleanedInput;
        } else if (cleanedInput.length <= 4) {
            return cleanedInput.replace(/^(\d{2})/, "$1:");
        } else {
            const hours = cleanedInput.slice(0, 2);

            const minutes = cleanedInput.slice(2, 4);

            return `${hours}:${minutes}`;
        }
    };

    return (
        <Box className="TimeContainer">
            <TimeIcon
                width={24}
                heigth={24}
                fill={"#6212FF"}
                fillOpacity={0.2}
            />
            <input
                className="TimeInput"
                type="text"
                placeholder="00:00"
                value={time}
                onChange={handleChange}
            />
        </Box>
    );
};

export default CustomTimeInput;
