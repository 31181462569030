import { makeAutoObservable } from "mobx";

class FilterStore {
    filterList = [
        { id: 0, label: "Для кого", text: "Студент", active: true },
        { id: 1, label: "Курс ", text: "2 курс", active: true },
        {
            id: 2,
            label: "Цель занятий",
            text: "Подготовка к экзамену в ВУЗе",
            active: true,
        },
        { id: 4, label: "ВУЗ", text: "МГУ" },
        { id: 5, label: "Сколько осталось до экзамена", text: "Несколько месяцев" },
        {
            id: 6,
            label: "Где удобно заниматься",
            text: "Онлайн, у меня",
            stepIndex: 3,
            subStepIndex: 0,
        },
        {
            id: 7,
            label: "Место занятий",
            text: "м.Проспект мира, м.Рижская, м.Трубная, Центральный район",
        },
        { id: 8, label: "Количество занятий в неделю", text: "2–3 занятия" },
        { id: 9, label: "Удобное время", text: "Ср 18:00, Пт 14:00" },
        {
            id: 10,
            label: "Какой репетитор подходит",
            text: "Начинающий ~до 800 рублей, со средним опытом ~800–1300 рублей",
        },
        {
            id: 11,
            label: "Пожелания к репетитору",
            text: "Пол, возраст не имеет значение",
        },
        { id: 12, label: "Ваше место обучения?", text: "Колледж" },
        { id: 13, label: "Укажите срок сдачи работы", text: "30 августа" },
        { id: 14, label: "Детали работы", text: "10" },
        { id: 15, label: "Дополнительные требования", text: "antiplagiat" },
        { id: 16, label: "Сопутствующие услуги", text: "Рецензия" },
        { id: 17, label: "На какой бюджет рассчитываете?", text: "2500" },
    ];

    currentId = 0;
    currenteText = "";
    toggleDrawer = false;
    submitButtonFilter = true;

    changeDrawerContent = "";

    setCurrentId(id) {
        this.currentId = id;
    }
    setChangeDrawerContent(value) {
        this.changeDrawerContent = value;
    }
    constructor() {
        makeAutoObservable(this);
    }
    setToggleDrawer() {
        this.toggleDrawer = !this.toggleDrawer;
    }
    setSubmitButtonFilter(submit) {
        this.submitButtonFilter = submit;
    }
    setText(id, newText) {
        this.filterList = this.filterList.map((filter) => {
            if (filter.id === id) {
                return { ...filter, text: newText };
            }

            return filter;
        });
    }
}

const filterStore = new FilterStore();

export default filterStore;
