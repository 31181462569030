import { Typography } from "@mui/material";

import * as textStyles from "themes/TextThemes";
import * as styles from "./ProfilePhotoThemes";

import SButton from "components/UI/SButton";
import React, { useState } from "react";

import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

import CropPhotoModal from "./CropPhotoModal/CropPhotoModal";
import UserStore from "store/UserStore";
import { observer } from "mobx-react-lite";

import ModalDeletePhoto from "./ModalDeletePhoto/ModalDeletePhoto";
import { useMedias } from "API/mediasHook";
const ProfilePhoto = () => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const fileInput = React.useRef();

    const [imageSrc, setImageSrc] = useState("");

    const [dialogOpen, setDialogOpen] = useState(false);

    const [croppedImageMini, setCroppedImageMini] = useState("");

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        const reader = new FileReader();

        reader.onload = () => {
            setImageSrc(reader.result);
            setDialogOpen(true);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    //Delete photo
    const [dialogDeletePhotoOpen, SetDialogDeletePhotoOpen] = useState("");

    const showDeletePhotoDialog = () => {
        SetDialogDeletePhotoOpen(true);
    };

    const discardDeletePhotoDialog = () => {
        SetDialogDeletePhotoOpen(false);
    };

    return (
        <Box sx={styles.ProfilePhoto(medias)}>
            <ModalDeletePhoto
                open={dialogDeletePhotoOpen}
                handleDiscard={discardDeletePhotoDialog}
            />

            <img
                src={UserStore.UserInfo.photo}
                style={styles.AvatarImg(medias)}
                alt="Avatarimg"
            />
            <Box sx={styles.ProfilePhotoContent(medias)}>
                <Typography sx={textStyles.Body2DarkGrey}>{t("main.settings.profile.photoDescription")}</Typography>
                <Box sx={styles.PhotoButtons}>
                    <SButton
                        variant="unshadowed"
                        onClick={() => {
                            fileInput.current.click();
                            setCroppedImageMini("");
                        }}
                    >
                        <input
                            ref={fileInput}
                            style={{ display: "none" }}
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                        />
                        {t("main.settings.profile.updloadPhoto")}
                    </SButton>
                    <SButton
                        variant="secondary"
                        onClick={showDeletePhotoDialog}
                    >
                        {t("main.settings.profile.deletePhoto")}
                    </SButton>
                </Box>
            </Box>
            <CropPhotoModal
                croppedImageMini={croppedImageMini}
                setCroppedImageMini={setCroppedImageMini}
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                imageSrc={imageSrc}
                setImageSrc={setImageSrc}
            />
        </Box>
    );
};

export default observer(ProfilePhoto);
