import { styleProcessing } from "API/plugins/Utilities";

export const ProfileInactive = {
    padding: "20px",
    maxWidth: "598px",
    borderRadius: "20px",
    border: "1px solid #E12626",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
    background: "#fff",
    display: "flex",

    boxSizing: "border-box",
    position: "relative",
};

export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    paddingLeft: "52px",
};

export const Button = (theme) => {
    let commonStyle = {
        width: "fit-content",
        marginLeft: "52px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, width: "100%", marginLeft: 0 },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const RightPanel = {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
};
