export const ContentProps = {
    sx: {
        background: "#4E4E4E",
        borderRadius: "10px",
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
    },
};

export const WelcomeBackSnackbar = {
    "& .MuiSnackbarContent-root": {
        width: "328px",
        minWidth: 0,
    },
    "& .MuiSnackbarContent-action": {
        margin: 0,
        padding: 0,
    },
};
