import React, { memo } from "react";
import * as themes from "./CostFilterThemes";
import MultipleChoiceFilter from "components/UI/MultipleChoiceFilter";
import { observer } from "mobx-react-lite";
import CatalogStore from "store/CatalogStore";
import { useMedias } from "../../../../API/mediasHook";

const CostFilter = observer(({ children, ...props }) => {
    const processingCost = (event, value) => {
        CatalogStore.SetCost(value);
    };

    const medias = useMedias();

    return (
        <MultipleChoiceFilter
            propOption={CatalogStore.optionCost}
            propOptions={CatalogStore.optionsCost}
            valueToShow={"cost"}
            valueToBind={"ID"}
            processingChange={processingCost}
            themeWrapper={themes.FiltersCostTheme(medias)}
            themeLabel={themes.filtersCostLabel}
            themeSelectedPart={themes.filtersCostSelectedLabel}
            themeOptionContainer={themes.filtersCOContainer}
            themeIconContainer={themes.FiltersCheckboxContainerTheme}
            themeIcon={themes.FiltersCheckboxTheme}
            label={"Стоимость"}
            placeholder={medias.sm ? "Стоимость занятия" : "Стоимость"}
        ></MultipleChoiceFilter>
    );
});

export default memo(CostFilter);
