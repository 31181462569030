export const NewClassesWithTutor = {
    background: "#FFF",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
};

export const NewClassesWithTutorContent = {
    display: "flex",
    gap: "20px",
    alignItems: "flex-start",
    padding: "20px 20px 0 20px",
    width: "352px",
};

export const SignUpForClassButton = {
    width: "fit-content",
};

export const SignUpForClass = {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 20px 20px 0",
};
