import { styleProcessing } from "API/plugins/Utilities";

export const ClassPaid = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        padding: "10px 0 30px 0",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "0px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ClassPaidContent = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const Avatar = {
    width: "60px",
    height: "60px",
};

export const ClassPaidTitle = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "20px",
        alignItems: "flex-start",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            alignItems: "center",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ClassDate = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "9px 0px 10px 0px",
    borderTop: "1px solid #DADADF",
    borderBottom: "1px solid #DADADF",
};

export const TimeBlock = {
    display: "flex",
    gap: "5px",
    alignItems: "center",
};

export const Bonuses = {
    boxSizing: "border-box",
    display: "flex",
    gap: "16px",
    padding: "16px",
    maxWidth: "380px",
    width: "100%",
    borderRadius: "10px",
    border: "1px solid #04C83B",
    boxShadow: "0px 4px 30px 0px rgba(106, 99, 118, 0.05)",
};

export const BonusesText = {
    width: "fit-content",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "150%",
    background: "linear-gradient(257deg, #04C83B 20.58%, #12AAFF 81.82%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    color: "transparent",
};

export const ButtonContainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
};

export const Button = {
    width: "100%",
};
