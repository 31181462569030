import React, { memo, useEffect } from "react";
import { observer } from "mobx-react-lite";
import PopperMenu from "components/UI/PopperMenu";
import NotificationsContent from "./NotificationsPopperContent/NotificationsPopperContent";
import NotificationsStore from "store/NotificationsStore";
import * as themes from "./NotificationsPopperThemes";
import { Badge, Box, IconButton } from "@mui/material";
import UserStore from "store/UserStore";
import { useMedias } from "API/mediasHook";
import NotificationsIcon from "assets/NotificationsAssets/SVG/NotificationsIcon";

const NotificationsPopper = observer(({ children, ...props }) => {
    const [hovered, setHovered] = React.useState(false);

    const medias = useMedias();

    const filteredNotifications = NotificationsStore.notifications.filter((item) => item.category === UserStore.role && item.read === false);

    useEffect(() => {
        const fetchFunc = async () => {
            if (UserStore.tokens.refreshToken) {
                await NotificationsStore.FetchPageData();
            }
        };

        fetchFunc();
    }, [UserStore.role, UserStore.tokens.refreshToken]);

    return (
        <PopperMenu
            open={UserStore.openNotifications}
            setOpen={(value) => UserStore.setOpenNotifications(value)}
            content={<NotificationsContent />}
            zIndex={1}
            paperSx={themes.PopperMenuPaperSx(medias)}
        >
            <IconButton
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                disableRipple
                sx={themes.Button(UserStore.openNotifications || hovered)}
            >
                <Badge
                    badgeContent={filteredNotifications.length}
                    sx={themes.Badge}
                >
                    <NotificationsIcon pressed={UserStore.openNotifications || hovered} />
                </Badge>
            </IconButton>
        </PopperMenu>
    );
});

export default memo(NotificationsPopper);
