export const CardsContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const ActiveOrders = {
    backgroundColor: "#EEEEF0",
    borderRadius: "20px",
    width: "558px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const NeedHelpText = {
    display: "flex",
    gap: "20px",
};

export const NeedHelpContainer = {
    backgroundColor: "white",
    borderRadius: "16px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
};

export const CreateOrderContainer = {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
};

export const NoOrdersYetContainer = {
    backgroundColor: "white",
    padding: "20px",
    maxWidth: "548px",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
};

export const NoOrdersYetIconAndText = {
    display: "flex",
    gap: "20px",
};

export const NoOrdersYetText = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
};
