import React from "react";
import { Link, FormControlLabel, Typography, Box } from "@mui/material";
import { useState } from "react";
import IOSSwitch from "components/UI/IOSSwitch";
import { observer } from "mobx-react-lite";
import UserStore from "store/UserStore";
import * as styles from "./TelegramThemes";
import * as notificationsStyles from "components/SettingsBodyBlock/StudentSettings/Notifications/NotificationsThemes";
import * as textStyles from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import SButton from "components/UI/SButton";
import { memo } from "react";
import { useMedias } from "API/mediasHook";

const Telegram = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const [setbot, setNobot] = useState(false);

    const SetTel = (val) => {
        setNobot(false);
        UserStore.setTelegram(val);
    };

    const SetBot = () => {
        if (!setbot) {
            return (
                <Box sx={styles.UnlinkButton(medias)}>
                    <Typography
                        sx={textStyles.Button14Purple}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setNobot(true);
                        }}
                    >
                        {t("main.settings.telegramBlock.unlink")}
                    </Typography>
                </Box>
            );
        } else {
            return (
                <Box sx={styles.UnlinkBlock(medias)}>
                    <Typography sx={textStyles.Body1Black}>
                        {t("main.settings.telegramBlock.toUnlinkPart1")}
                        <Link
                            className="Link"
                            underline="none"
                            color="#6212FF"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                SetTel(false);
                            }}
                        >
                            @studybuddy_bot
                        </Link>
                        {t("main.settings.telegramBlock.toUnlinkPart2")}
                    </Typography>
                    <SButton
                        style={{ width: "fit-content" }}
                        variant="secondary"
                        padding="10px 20px 11px 20px"
                        onClick={() => {
                            setNobot(false);
                        }}
                    >
                        {t("main.cancel")}
                    </SButton>
                </Box>
            );
        }
    };

    return (
        <Box sx={notificationsStyles.Notifications(medias)}>
            <Box sx={notificationsStyles.NotificationsTitle(medias)}>
                <Typography sx={textStyles.HeadlineH4White}>{t("main.settings.telegramBlock.telegramBotTitle")}</Typography>
            </Box>
            {UserStore.UserInfo.telegram === false ? (
                <Box sx={styles.configureNotifications(medias)}>
                    <Typography sx={textStyles.Body1Black}>
                        {t("main.settings.telegramBlock.configureNotificationsPart1")}
                        <Link
                            underline="none"
                            color="#6212FF"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                SetTel("true");
                            }}
                        >
                            @studybuddy_bot
                        </Link>
                        {t("main.settings.telegramBlock.configureNotificationsPart2")}
                    </Typography>
                </Box>
            ) : (
                <Box sx={notificationsStyles.notificationBlocks(medias)}>
                    <Typography sx={textStyles.HeadlineH4Black}>{t("main.settings.telegramBlock.receiveNotifications")}</Typography>
                    <Box sx={notificationsStyles.notificationBlock}>
                        <Box sx={notificationsStyles.notificationContent(medias)}>
                            <Typography sx={textStyles.Button16Black}>{t("main.settings.notificationsBlock.timetableClassesTitle")}</Typography>
                            <Typography sx={textStyles.Body2Grey}>{t("main.settings.notificationsBlock.timetableClassesDescription")}</Typography>
                        </Box>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    sx={{ m: 1 }}
                                    defaultChecked
                                />
                            }
                        />
                    </Box>
                    <Box sx={notificationsStyles.notificationBlock}>
                        <Box sx={notificationsStyles.notificationContent(medias)}>
                            <Typography sx={textStyles.Button16Black}>{t("main.settings.notificationsBlock.messagesTutorsTitle")}</Typography>
                            <Typography sx={textStyles.Body2Grey}>{t("main.settings.notificationsBlock.messagesTutorsDescription")}</Typography>
                        </Box>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    sx={{ m: 1 }}
                                    defaultChecked
                                />
                            }
                        />
                    </Box>
                    <Box sx={notificationsStyles.notificationBlock}>
                        <Box sx={notificationsStyles.notificationContent(medias)}>
                            <Typography sx={textStyles.Button16Black}>{t("main.settings.notificationsBlock.notificationsPaymentsTitle")}</Typography>
                            <Typography sx={textStyles.Body2Grey}>{t("main.settings.notificationsBlock.notificationsPaymentsDescription")}</Typography>
                        </Box>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    sx={{ m: 1 }}
                                    defaultChecked
                                />
                            }
                        />
                    </Box>
                    <Box sx={notificationsStyles.notificationBlock}>
                        <Box sx={notificationsStyles.notificationContent(medias)}>
                            <Typography sx={textStyles.Button16Black}>{t("main.settings.notificationsBlock.updatesTipsTitle")}</Typography>
                            <Typography sx={textStyles.Body2Grey}>{t("main.settings.notificationsBlock.UpdatesTipsDescription")}</Typography>
                        </Box>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    sx={{ m: 1 }}
                                    defaultChecked
                                />
                            }
                        />
                    </Box>
                </Box>
            )}
            {UserStore.UserInfo.telegram && SetBot()}
        </Box>
    );
});

export default memo(Telegram);
