import React, { memo, useEffect } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./VideoContentThemes";
import { Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import VideoChatStore from "store/VideoChatStore";
import Countdown, { zeroPad } from "react-countdown";
import { useTranslation } from "react-i18next";
import MicroOffIconWB from "assets/VideoChatAssets/SVG/microOffIconWB";
import MicroOnIconWB from "assets/VideoChatAssets/SVG/microOnIconWB";

const VideoContent = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const handleCountdownComplete = () => {
        VideoChatStore.MarkCountdownEnd();
        VideoChatStore.SetCallTimer(true);
    };

    useEffect(() => {
        VideoChatStore.videoRef = document.getElementById("video");
    }, []);

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (!completed) {
            return seconds === 0 ? (
                <Typography>
                    {minutes} {t("main.minutes", { count: minutes })}
                </Typography>
            ) : (
                <Typography>
                    {minutes}:{zeroPad(seconds)} {t("main.minutes", { count: minutes })}
                </Typography>
            );
        }
    };

    return (
        <Box sx={themes.videoContentContainerStyle(VideoChatStore.managementPanel.demoState)}>
            <video
                id="video"
                style={themes.videoStyle(VideoChatStore.managementPanel.demoState)}
                autoPlay
            ></video>
            {!VideoChatStore.managementPanel.demoState && (
                <Box sx={themes.videoMockBlockStyle}>
                    <ThemeProvider theme={themes.AvatarTheme(0)}>
                        <Avatar src={VideoChatStore.call.AvatarSrc}></Avatar>
                    </ThemeProvider>
                    <Typography sx={themes.nameStyle}>{VideoChatStore.call.FIO} скоро подключится к занятию</Typography>
                    <Box sx={themes.startingTimeContainerStyle}>
                        {VideoChatStore.countdownEnded ? (
                            <Typography sx={themes.startingTimeLabelStyle}>Ожидаем подключения репетитора...</Typography>
                        ) : (
                            <Box sx={themes.startingTimeContainerStyle}>
                                <Typography sx={themes.startingTimeLabelStyle}>Занятие начнётся через</Typography>
                                <Typography
                                    sx={themes.startingTimeStyle}
                                    component={"div"}
                                >
                                    <Countdown
                                        date={VideoChatStore.call.StartDateTime}
                                        renderer={renderer}
                                        zeroPadTime={2}
                                        precision={2}
                                        onComplete={handleCountdownComplete}
                                    />
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <Box sx={themes.tutorInfoContainerStyle}>
                        <ThemeProvider theme={themes.AvatarTheme(1)}>
                            <Avatar src={VideoChatStore.call.AvatarSrc}></Avatar>
                        </ThemeProvider>
                        <Box sx={themes.nameAndMicroStateContainerStyle(1)}>
                            <Typography sx={themes.tutorNameStyle}>{VideoChatStore.call.FIO}</Typography>
                            {/* eslint-disable-next-line no-constant-condition */}
                            {true ? <MicroOnIconWB></MicroOnIconWB> : <MicroOffIconWB></MicroOffIconWB>}
                        </Box>
                    </Box>
                    <Box sx={themes.studentVideoContainerStyle}>
                        {VideoChatStore.managementPanel.videoState ? (
                            <img
                                style={themes.previewStyle}
                                src={VideoChatStore.call.StudentAvatarSrc}
                                alt={"StudentVideo"}
                            />
                        ) : (
                            <ThemeProvider theme={themes.AvatarTheme(1)}>
                                <Avatar src={VideoChatStore.call.StudentAvatarSrc}></Avatar>
                            </ThemeProvider>
                        )}
                        <Box sx={themes.nameAndMicroStateContainerStyle(0)}>
                            <Typography sx={themes.studentNameStyle}>{VideoChatStore.call.StudentName}</Typography>
                            {VideoChatStore.managementPanel.microState ? <MicroOnIconWB></MicroOnIconWB> : <MicroOffIconWB></MicroOffIconWB>}
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
});

export default memo(VideoContent);
