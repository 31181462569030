const TifIconSVG = function (props) {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 34.48V5.52C0 2.48 2.48 0 5.52 0H25.576V8.864C25.576 11.904 27.056 13.368 30.096 13.368H40V34.48C40 37.52 37.52 40 34.48 40H5.52C2.48 40 0 37.52 0 34.48ZM30.0962 11.1677C28.8162 11.1677 27.7762 10.1277 27.7762 8.86369V0.879687L39.0082 11.1677H30.0962Z"
                fill="#F1B063"
            />
            <path
                d="M11.4062 20.3945V19H17.9766V20.3945H15.5273V27H13.8555V20.3945H11.4062Z"
                fill="white"
            />
            <path
                d="M21.3 19V27H19.6086V19H21.3Z"
                fill="white"
            />
            <path
                d="M23.2416 27V19H28.5385V20.3945H24.933V22.3008H28.1869V23.6953H24.933V27H23.2416Z"
                fill="white"
            />
        </svg>
    );
};

export default TifIconSVG;
