export const PeriodButton = {
    display: "flex",
    gap: "4px",
    alignItems: "center",
};

export const DatePicker = {
    padding: "3px",
};

export const HyphenPos = { margin: "4px 0px 0px 0px" };
