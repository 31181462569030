import React from "react";

import { Stack, Typography } from "@mui/material";

import SButton from "components/UI/SButton";

import DownloadIcon from "components/DiplomaChats/Icons/DownloadIcon";

const Header = () => {
    return (
        <Stack
            direction="row"
            padding="5px 0px"
            justifyContent="space-between"
        >
            <Typography
                variant="title"
                paddingTop="5px"
            >
                Файлы
            </Typography>
            <SButton style={{ height: "36px" }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                >
                    <span>Скачать все</span> <DownloadIcon />
                </Stack>
            </SButton>
        </Stack>
    );
};

export default Header;
