export const IconButton = {
    position: "relative",
    zIndex: "2",
    padding: 0,
};

export const LikeDislikeBlock = {
    background: "#170F26",
    borderRadius: "0px 0px 20px 20px",
    padding: "19px 30px",
    display: "flex",
    gap: "24px",
};
