export const ServiceRules = {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px 0px 15px",
    gap: "15px",
    borderBottom: "1px dashed #BBBBC8",
    marginTop: "15px",
    width: "100%",
};

export const Specialists = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "2px 10px 4px",
    gap: "10px",
    background: "#19B20C",
    borderRadius: "20px",
    whiteSpace: "nowrap",
};

export const SpecialistsBox = {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "15px 0px",
    gap: "15px",
    borderWidth: "1px 0px",
    borderStyle: "dashed",
    borderColor: "#BBBBC8",
    marginTop: "15px",
};

export const OrderBox = {
    marginBottom: "15px",
};
