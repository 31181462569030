import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, IconButton, Box, ThemeProvider, Typography } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import * as themes from "components/TeacherQuestionnaireBodyBlock/Themes/ModalCropPhotoThemes";
import * as tThemes from "themes/TextThemes";
import BlackPointIcon from "assets/TeacherQuestionnaireAssets/BlackPointIcon";
import SButton from "components/UI/SButton";
import PhotoExample1 from "assets/TeacherQuestionnaireAssets/Images/PhotoExample1.png";
import PhotoExample2 from "assets/TeacherQuestionnaireAssets/Images/PhotoExample2.png";
import PhotoExample3 from "assets/TeacherQuestionnaireAssets/Images/PhotoExample3.png";
import PhotoExample4 from "assets/TeacherQuestionnaireAssets/Images/PhotoExample4.png";
import PhotoExample5 from "assets/TeacherQuestionnaireAssets/Images/PhotoExample5.png";
import PhotoExample6 from "assets/TeacherQuestionnaireAssets/Images/PhotoExample6.png";
import * as theme from "./PhotoExamplesModalThemes";

const PhotoExamplesModal = ({ openDialog, handleClose }) => {
    const { t } = useTranslation();

    const renderTextBlock = (title, sentences) => (
        <Box sx={themes.photoExamplesTextBlock}>
            <Typography sx={tThemes.HeadlineH5Black}>{title}</Typography>
            <Box sx={themes.photoExamplesSentenceBlock}>
                {sentences.map((sentence, index) => (
                    <Box
                        key={index}
                        sx={themes.pointSentence}
                    >
                        <BlackPointIcon />
                        <Typography sx={tThemes.Body2Black}>{sentence}</Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );

    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={openDialog}
                onClose={handleClose}
                maxWidth="none"
            >
                <Box sx={themes.dialogContainerStyle}>
                    <ThemeProvider theme={themes.CloseIconTheme}>
                        <IconButton onClick={handleClose}>
                            <CloseRounded />
                        </IconButton>
                    </ThemeProvider>
                    <Box sx={themes.cardContainerStyle}>
                        <Box sx={themes.dialogContainerHeaderStyle}>
                            <Box sx={themes.dialogContainerHeaderInsideStyle}>
                                <Typography sx={tThemes.HeadlineH5Black}>{t("main.photoCrop.photoExamples")}</Typography>
                            </Box>
                        </Box>
                        <Box sx={themes.dialogContainerBodyStyle}>
                            <Box sx={themes.dialogContainerBodyInsideStyle}>
                                <Box sx={themes.dialogMainPhotoContainer}>
                                    <Box>
                                        <Box sx={themes.photoExamplesTextBlocks}>
                                            {renderTextBlock(t("main.photoCrop.photoFits"), [
                                                t("main.photoCrop.yourRealPhoto"),
                                                t("main.photoCrop.verticalPhoto"),
                                                t("main.photoCrop.faceClear"),
                                                t("main.photoCrop.noOthers"),
                                            ])}
                                            {renderTextBlock(t("main.photoCrop.photoDoesntFit"), [
                                                t("main.photoCrop.notYourPhoto"),
                                                t("main.photoCrop.faceNotClear"),
                                                t("main.photoCrop.fullHeightPhoto"),
                                                t("main.photoCrop.othersOnPhoto"),
                                                t("main.photoCrop.sunglassesOnPhoto"),
                                                t("main.photoCrop.imagesOnPhoto"),
                                            ])}
                                        </Box>
                                        <SButton
                                            style={theme.SButtonMT}
                                            variant="unshadowed"
                                            padding="10px 20px 11px 20px"
                                            onClick={handleClose}
                                        >
                                            {t("main.close")}
                                        </SButton>
                                    </Box>
                                    <Box sx={themes.photoExamplesPhotoBlock}>
                                        <Box sx={themes.photoExamplesPhotoHorizontalBlock}>
                                            <img
                                                src={PhotoExample1}
                                                alt="PhotoExample1"
                                            />
                                            <img
                                                src={PhotoExample2}
                                                alt="PhotoExample2"
                                            />
                                        </Box>
                                        <Box sx={themes.photoExamplesPhotoVerticalBlock}>
                                            <Box sx={themes.photoExamplesPhotoHorizontalBlock}>
                                                <img
                                                    src={PhotoExample3}
                                                    alt="PhotoExample3"
                                                />
                                                <img
                                                    src={PhotoExample4}
                                                    alt="PhotoExample4"
                                                />
                                            </Box>
                                            <Box sx={themes.photoExamplesPhotoHorizontalBlock}>
                                                <img
                                                    src={PhotoExample5}
                                                    alt="PhotoExample5"
                                                />
                                                <img
                                                    src={PhotoExample6}
                                                    alt="PhotoExample6"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </ThemeProvider>
    );
};

export default memo(PhotoExamplesModal);
