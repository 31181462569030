import { styled, Typography } from "@mui/material";

export const OrderResponeDescriptionStyled = styled(Typography)({
    marginTop: "14px",
    fontFamily: "inherit",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0em",
    color: "rgba(34, 34, 34, 1)",
});
