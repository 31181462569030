import React, { memo } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import VideoChatBodyBlock from "components/VideoChatBodyBlock/VideoChatBodyBlock";
import Box from "@mui/material/Box";

const theme = createTheme({
    spacing: 1,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const VideoChatPage = ({ props }) => {
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ background: "var(--Grey-Separator, #EEEEF0)", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <VideoChatBodyBlock></VideoChatBodyBlock>
            </Box>
        </ThemeProvider>
    );
};

export default memo(VideoChatPage);
