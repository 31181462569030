import { Box, Typography } from "@mui/material";
import * as themes from "./HiddenInfoThemes";
import * as textThemes from "themes/TextThemes";
import { memo } from "react";

const HiddenInfo = ({ children, ...props }) => {
    return (
        <Box sx={themes.HiddenAvatarWrapper}>
            <themes.HiddenAvatar>
                <themes.HiddenAvatarText>Фото скрыто</themes.HiddenAvatarText>
            </themes.HiddenAvatar>
            <Typography sx={themes.HiddenAvatarName}>ФИО скрыты</Typography>
        </Box>
    );
};

export default memo(HiddenInfo);
