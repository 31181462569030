import React, { memo, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import useWebSocket from "react-use-websocket";
import * as themes from "./ChatBodyBlockThemes";
import * as textStyles from "themes/TextThemes";
import { Box, CircularProgress, Typography } from "@mui/material";
import ChatListBlock from "./ChatListBlock/ChatListBlock";
import GridFieldChat from "./GridFieldChat/GridFieldChat";
import FolderProcessingBlock from "./FolderProcessingBlock/FolderProcessingBlock";
import ChatAttachments from "./ChatAttachments/ChatAttachments";
import FullChatStore from "store/FullChatStore";
import { set } from "./CommonData/helper_functions";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const ChatBodyBlock = observer(({ children, ...props }) => {
    const [folderChats, setFolderChats] = useState();

    const URL = "wss://" + process.env.REACT_APP_API_URL + "/chats/ws";

    const { sendMessage, sendJsonMessage, lastMessage, lastJsonMessage, readyState } = useWebSocket(URL, {
        share: true,
    });

    const navigate = useNavigate();

    useEffect(() => {
        FullChatStore.ProcessMessages(sendMessage, sendJsonMessage, lastMessage, lastJsonMessage, navigate);
    }, [lastMessage, lastJsonMessage]);

    useEffect(() => {
        FullChatStore.SetMediaPage(false);
    }, [FullChatStore.id]);

    return (
        <ScrollToTop>
            <Box sx={themes.ChatBodyBlock}>
                {FullChatStore.tabsData.chats ? (
                    <>
                        <Box sx={themes.ChatListBox}>
                            <ChatListBlock setFolderChats={setFolderChats} />
                        </Box>

                        {FullChatStore.folderProcessingStep === 0 || FullChatStore.folderProcessingStep === 1 ? (
                            <FolderProcessingBlock folderChats={folderChats} />
                        ) : FullChatStore.mediaPage === false ? (
                            <GridFieldChat sendJsonMessage={sendJsonMessage} />
                        ) : (
                            <ChatAttachments sendJsonMessage={sendJsonMessage} />
                        )}
                    </>
                ) : (
                    <Box sx={themes.loadingContainerStyle}>
                        <Typography sx={textStyles.HeadlineH5Black}>Чаты загружаются...</Typography>
                        <ThemeProvider theme={themes.CircularProgressTheme}>
                            <CircularProgress />
                        </ThemeProvider>
                    </Box>
                )}
            </Box>
        </ScrollToTop>
    );
});

export default memo(ChatBodyBlock);
