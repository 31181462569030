export const videoChatBodyBlockStyleNoChat = {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    gap: 10,
};

export const videoChatBodyBlockStyleOpenChat = {
    width: "73%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    gap: 10,
};

export const videoChatBodyBlockStyleChatBlock = {
    width: "27%",
    height: "100vh",
    gap: 10,
    background: "white",
};

export const main = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100vh",
};
