export const LoginActionRequiredContent = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
};

export const LoginActionRequiredIcon = {
    width: "fit-content",
};

export const LoginActionRequired = (condition) => {
    return {
        borderRadius: "20px 20px 20px 20px",
        padding: "20px 20px 20px 20px",
        background: condition ? "rgba(187, 187, 200, 0.17)" : "#FFF",
        display: "flex",
        gap: "20px",
    };
};
