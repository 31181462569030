import React, { memo, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./ManageCallThemes";
import { Button, IconButton, Menu, Tooltip, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import VideoChatStore from "store/VideoChatStore";
import { RadioButtonChecked } from "@mui/icons-material";
import FinishIcon from "assets/VideoChatAssets/SVG/finishIcon";
import { useNavigate } from "react-router-dom";
import ModalFinishCall from "../ModalFinishCall/ModalFinishCall";
import ModalRatePlatform from "../ModalRatePlatform/ModalRatePlatform";
import ModalFeedbackFinished from "../ModalFeedbackFinished/ModalFeedbackFinished";

const ManageCall = observer(({ children, ...props }) => {
    const navigate = useNavigate();

    const [hour, setHour] = useState("00");

    const [minute, setMinute] = useState("00");

    const [second, setSecond] = useState("00");

    const [counter, setCounter] = useState(0);

    const [dialogFinishCallOpen, SetDialogFinishCallOpen] = useState(false);

    const [dialogRatePlatformOpen, SetDialogRatePlatformOpen] = useState(false);

    const [dialogFeedbackFinishedOpen, SetDialogFeedbackFinishedOpen] = useState(false);

    const showFinishCallDialog = () => {
        VideoChatStore.SetCallTimer(false);
        VideoChatStore.SetFeedbackStep(0);
        VideoChatStore.CalcStepReadyCondition();
        SetDialogFinishCallOpen(true);
    };

    const discardFinishCallDialog = () => {
        VideoChatStore.ClearModalFeedback();
        SetDialogFinishCallOpen(false);
        if (VideoChatStore.feedbackStep !== 4) {
            showFeedbackFinishedDialog();
        }
    };

    const saveFinishCallDialog = async () => {
        await VideoChatStore.AddCallFeedback();
        SetDialogFinishCallOpen(false);
        showRatePlatformDialog();
    };

    const showRatePlatformDialog = () => {
        VideoChatStore.SetFeedbackStep(1);
        VideoChatStore.CalcStepReadyCondition();
        SetDialogRatePlatformOpen(true);
    };

    const discardRatePlatformDialog = () => {
        SetDialogRatePlatformOpen(false);
        if (VideoChatStore.feedbackStep !== 4) {
            showFeedbackFinishedDialog();
        }
    };

    const saveRatePlatformDialog = async (value) => {
        if (value !== 4) {
            VideoChatStore.SetFeedbackStep(value);
            VideoChatStore.CalcStepReadyCondition();
        } else {
            SetDialogRatePlatformOpen(false);
            showFeedbackFinishedDialog();
        }
    };

    const showFeedbackFinishedDialog = () => {
        SetDialogFeedbackFinishedOpen(true);
    };

    const discardFeedbackFinishedDialog = () => {
        SetDialogFeedbackFinishedOpen(false);
    };

    const saveFeedbackFinishedDialog = () => {
        SetDialogFeedbackFinishedOpen(false);
        navigate("/catalog");
    };

    const [callAnchorEl, setCallAnchorEl] = useState(null);

    const callOpen = Boolean(callAnchorEl);

    const handleCallMenuOpen = (event) => {
        setCallAnchorEl(event.currentTarget);
    };

    const handleCallMenuClose = (value, event) => {
        if (value !== null) {
            showFinishCallDialog();
        }

        setCallAnchorEl(null);
    };

    useEffect(() => {
        if (VideoChatStore.callActive) {
            let intervalId;

            intervalId = setInterval(() => {
                const hourCounter = Math.floor(counter / 60 ** 2);

                const minuteCounter = Math.floor(counter / 60);

                const secondCounter = counter % 60;

                const computedHour = String(hourCounter).length === 1 ? `0${hourCounter}` : hourCounter;

                const computedMinute = String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter;

                const computedSecond = String(secondCounter).length === 1 ? `0${secondCounter}` : secondCounter;

                setHour(computedHour);
                setMinute(computedMinute);
                setSecond(computedSecond);

                setCounter((counter) => counter + 1);
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [counter, VideoChatStore.callActive]);

    return (
        <Box sx={themes.manageCallContainerStyle}>
            {VideoChatStore.callActive ? (
                <Box sx={themes.callRecordingContainerStyle}>
                    <ThemeProvider theme={themes.CallRecordIconTheme}>
                        <RadioButtonChecked />
                    </ThemeProvider>
                    <Typography sx={themes.callTimerStyle}>
                        {hour}:{minute}:{second}
                    </Typography>
                </Box>
            ) : null}
            <Box sx={themes.iconContainerStyle(2)}>
                <ThemeProvider theme={themes.ActionButtonTheme(2)}>
                    <Tooltip
                        title={<Typography sx={themes.tooltipStyle}>Завершить занятие</Typography>}
                        placement={"top-end"}
                    >
                        <Box>
                            <IconButton
                                disableRipple
                                onClick={handleCallMenuOpen}
                                disabled={!VideoChatStore.callActive}
                            >
                                <FinishIcon />
                            </IconButton>
                        </Box>
                    </Tooltip>
                </ThemeProvider>
                <ThemeProvider theme={themes.CallMenuTheme}>
                    <Menu
                        anchorEl={callAnchorEl}
                        open={callOpen}
                        onClose={handleCallMenuClose.bind(this, null)}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                    >
                        <Box sx={themes.callMenuContainerStyle}>
                            <Typography sx={themes.endCallTextStyle}>Завершить занятие?</Typography>
                            <Box sx={themes.callMenuActionsContainerStyle}>
                                <ThemeProvider theme={themes.CallModalActionButtonTheme(0)}>
                                    <Button
                                        onClick={handleCallMenuClose.bind(this, null)}
                                        disableRipple
                                    >
                                        <Typography style={themes.callButtonTextStyle(0)}>Остаться</Typography>
                                    </Button>
                                </ThemeProvider>
                                <ThemeProvider theme={themes.CallModalActionButtonTheme(1)}>
                                    <Button
                                        onClick={handleCallMenuClose.bind(this, true)}
                                        disableRipple
                                    >
                                        <Typography style={themes.callButtonTextStyle(1)}>Завершить</Typography>
                                    </Button>
                                </ThemeProvider>
                            </Box>
                        </Box>
                    </Menu>
                </ThemeProvider>
            </Box>
            <ModalFinishCall
                open={dialogFinishCallOpen}
                handleDiscard={discardFinishCallDialog}
                handleSave={saveFinishCallDialog}
            ></ModalFinishCall>
            <ModalRatePlatform
                open={dialogRatePlatformOpen}
                handleDiscard={discardRatePlatformDialog}
                handleSave={saveRatePlatformDialog}
            ></ModalRatePlatform>
            <ModalFeedbackFinished
                open={dialogFeedbackFinishedOpen}
                handleDiscard={discardFeedbackFinishedDialog}
                handleSave={saveFeedbackFinishedDialog}
            ></ModalFeedbackFinished>
        </Box>
    );
});

export default memo(ManageCall);
