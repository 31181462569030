export const videoContentStyle = {
    position: "relative",
    width: "100%",
    borderRadius: "20px",
    overflow: "hidden",
    backgroundColor: "black",
    video: {
        borderRadius: "20px",
    },
};
