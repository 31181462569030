import React from "react";
import { Button, Typography, IconButton } from "@mui/material";
import ButtonArrowIcon2 from "assets/CommonAssets/SVG/ButtonArrow2";
import LinkArrow from "assets/PAAssets/SVG/link-arrow";

const SButton = ({
    fontSize = "14px",
    children,
    type,
    borderRadius = "42px",
    onClick,
    textColor = "#222",
    padding = "7px 16px 8px 16px",
    variant = "shadowed",
    style = {},
    disabled,
    endIcon,
    typographyHeight = "none",
}) => {
    const [color, setColor] = React.useState("#6212FF");

    if (variant === "shadowed") {
        return (
            <Button
                type={type}
                disabled={disabled}
                style={style}
                disableRipple
                variant="contained"
                onClick={onClick}
                endIcon={endIcon}
                sx={{
                    height: "fit-content",
                    ":hover": { bgcolor: "#8141FF", boxShadow: "none" },
                    ":active": { bgcolor: "#5513D7", boxShadow: "none" },
                    ":disabled": { bgcolor: "#B088FF", boxShadow: "none" },
                    boxShadow: "0px 4px 10px rgba(51, 12, 128, 0.2)",
                    borderRadius: "42px",
                    display: "flex",
                    pt: "11px",
                    pb: "12px",
                    pl: "24px",
                    pr: "26px",
                    gap: "11px",
                    alignItems: "center",
                    backgroundColor: "#6212FF",
                }}
            >
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "14px",
                        lineHeight: "150%",
                        fontWeight: 400,
                        textTransform: "none",
                        color: "#fff",
                        whiteSpace: "nowrap",
                    }}
                >
                    {children}
                </Typography>
            </Button>
        );
    } else if (variant === "unshadowed") {
        return (
            <Button
                type={type}
                disabled={disabled}
                style={style}
                disableRipple
                variant="contained"
                onClick={onClick}
                endIcon={endIcon}
                sx={{
                    height: "fit-content",
                    ":hover": { bgcolor: "#8141FF", boxShadow: "none" },
                    ":active": { bgcolor: "#5513D7", boxShadow: "none" },
                    ":disabled": { bgcolor: "#B088FF", boxShadow: "none" },
                    boxShadow: "none",
                    borderRadius: "42px",
                    display: "flex",
                    padding: padding,
                    gap: "10px",
                    alignItems: "center",
                    backgroundColor: "#6212FF",
                }}
            >
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "14px",
                        lineHeight: "150%",
                        fontWeight: 500,
                        textTransform: "none",
                        color: "#fff",
                        whiteSpace: "nowrap",
                    }}
                >
                    {children}
                </Typography>
            </Button>
        );
    } else if (variant === "chat") {
        return (
            <Button
                type={type}
                disabled={disabled}
                style={style}
                disableRipple
                variant="contained"
                onClick={onClick}
                endIcon={endIcon}
                sx={{
                    height: "fit-content",
                    ":hover": { bgcolor: "#8141FF", boxShadow: "none" },
                    ":active": { bgcolor: "#5513D7", boxShadow: "none" },
                    ":disabled": { bgcolor: "#BBBBC8", boxShadow: "none" },
                    boxShadow: "none",
                    borderRadius: borderRadius,
                    display: "flex",
                    padding: padding,
                    gap: "10px",
                    alignItems: "center",
                    backgroundColor: "#6212FF",
                }}
            >
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "14px",
                        lineHeight: "150%",
                        fontWeight: 500,
                        textTransform: "none",
                        color: "#fff",
                        whiteSpace: "nowrap",
                    }}
                >
                    {children}
                </Typography>
            </Button>
        );
    } else if (variant === "stroked") {
        return (
            <Button
                type={type}
                disabled={disabled}
                style={style}
                disableRipple
                variant="contained"
                onClick={onClick}
                endIcon={endIcon}
                sx={{
                    height: "fit-content",
                    ":hover": { bgcolor: "#F7F3FF", boxShadow: "none" },
                    ":active": { bgcolor: "#F7F3FF", boxShadow: "none" },
                    ":disabled": { bgcolor: "#BBBBC8", boxShadow: "none" },
                    boxShadow: "none",
                    border: "1px solid #6212FF",
                    borderRadius: borderRadius,
                    display: "flex",
                    padding: padding,
                    gap: "10px",
                    alignItems: "center",
                    backgroundColor: "transparent",
                }}
            >
                <Typography
                    sx={{
                        fontSize: "14px",
                        lineHeight: "150%",
                        fontWeight: 500,
                        textTransform: "none",
                        color: "#6212FF",
                        whiteSpace: "nowrap",
                    }}
                >
                    {children}
                </Typography>
            </Button>
        );
    } else if (variant === "secondary") {
        return (
            <Button
                type={type}
                disabled={disabled}
                style={style}
                disableRipple
                variant="contained"
                onClick={onClick}
                sx={{
                    height: "fit-content",
                    ":hover": { bgcolor: "#DADADF", boxShadow: "none", color: "#222" },
                    ":active": { bgcolor: "#DADADF", boxShadow: "none", color: "#222" },
                    ":disabled": { bgcolor: "#F7F7F7", boxShadow: "none", color: "#9E9EB0" },
                    boxShadow: "none",
                    borderRadius: "42px",
                    display: "flex",
                    padding: padding,
                    gap: "10px",
                    alignItems: "center",
                    backgroundColor: "#EEEEF0",
                    color: "#222",
                }}
            >
                <Typography
                    sx={{
                        height: typographyHeight,
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "14px",
                        lineHeight: "150%",
                        fontWeight: 500,
                        textTransform: "none",
                        whiteSpace: "nowrap",
                    }}
                >
                    {children}
                </Typography>
            </Button>
        );
    } else if (variant === "icon") {
        return (
            <IconButton
                disabled={disabled}
                style={style}
                disableRipple
                onClick={onClick}
                sx={{
                    padding: "0px",
                    ":hover": {
                        bgcolor: "rgba(187, 187, 200, 0.17)",
                        color: "#222",
                    },
                    ":active": {
                        bgcolor: "rgba(187, 187, 200, 0.17)",
                        color: "#222",
                    },
                }}
            >
                {children}
            </IconButton>
        );
    } else if (variant === "arrow") {
        return (
            <Button
                type={type}
                disabled={disabled}
                style={style}
                disableRipple
                variant="contained"
                onClick={onClick}
                endIcon={<ButtonArrowIcon2 />}
                sx={{
                    height: "fit-content",
                    ":hover": { bgcolor: "#8141FF", boxShadow: "none" },
                    ":disabled": { bgcolor: "#B088FF", boxShadow: "none" },
                    ":active": { bgcolor: "#5513D7", boxShadow: "none" },
                    boxShadow: "none",
                    borderRadius: "42px",
                    display: "flex",
                    padding: padding,
                    gap: "2px",
                    alignItems: "center",
                    backgroundColor: "#6212FF",
                }}
            >
                <Typography
                    sx={{
                        p: 0,
                        m: 0,
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "14px",
                        lineHeight: "150%",
                        fontWeight: 500,
                        textTransform: "none",
                        color: "#fff",
                        whiteSpace: "nowrap",
                    }}
                >
                    {children}
                </Typography>
            </Button>
        );
    } else if (variant === "text-arrow") {
        return (
            <Button
                type={type}
                disabled={disabled}
                variant="text"
                style={style}
                disableRipple
                onClick={onClick}
                onMouseDown={() => {
                    setColor("#5513D7");
                }}
                onMouseEnter={() => {
                    setColor("#8141FF");
                }}
                onMouseLeave={() => {
                    setColor("#6212FF");
                }}
                sx={{
                    color: "#222",
                    height: "fit-content",
                    ":hover": { color: "#8141FF", boxShadow: "none", bgcolor: "transparent" },
                    ":active": { color: "#5513D7", boxShadow: "none", bgcolor: "transparent" },
                    boxShadow: "none",
                    borderRadius: "42px",
                    display: "flex",
                    m: 0,
                    p: 0,
                    gap: "4px",
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{
                        p: 0,
                        m: 0,
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: fontSize,
                        lineHeight: "150%",
                        fontWeight: 500,
                        textTransform: "none",
                        whiteSpace: "nowrap",
                    }}
                >
                    {children}
                </Typography>
                <LinkArrow color={color} />
            </Button>
        );
    } else if (variant === "green") {
        return (
            <Button
                type="submit"
                disabled={disabled}
                variant="contained"
                style={style}
                disableRipple
                onClick={onClick}
                sx={{
                    width: "146px",
                    height: "36px",
                    borderRadius: 15,
                    textTransform: "none",
                    backgroundColor: "#19B20C",
                    float: "right",
                    boxShadow: "none",
                    ":disabled": {
                        backgroundColor: "#BBBBC8",
                        color: "white",
                    },
                    ":hover": {
                        backgroundColor: "#47C13D",
                        color: "#FFF",
                        boxShadow: "none",
                    },
                    ":active": {
                        backgroundColor: "#12A006",
                        color: "#FFF",
                        boxShadow: "none",
                    },
                }}
            >
                <Typography
                    sx={{
                        p: 0,
                        m: 0,
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "14px",
                        lineHeight: "150%",
                        fontWeight: 500,
                        textTransform: "none",
                        whiteSpace: "nowrap",
                    }}
                >
                    {children}
                </Typography>
            </Button>
        );
    } else if (variant === "text") {
        return (
            <Button
                type={type}
                disabled={disabled}
                variant="text"
                style={style}
                disableRipple
                onClick={onClick}
                sx={{
                    color: textColor,
                    height: "fit-content",
                    ":enabled": { color: "#6212FF", boxShadow: "none", background: "transparent" },
                    ":disabled": { color: "#9E9EB0", boxShadow: "none", background: "transparent" },
                    ":hover": { color: "#8141FF", boxShadow: "none", background: "transparent" },
                    ":active": { color: "#5513D7", boxShadow: "none", background: "transparent" },
                    boxShadow: "none",
                    borderRadius: "42px",
                    display: "flex",
                    m: 0,
                    p: 0,
                    gap: "2px",
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{
                        p: 0,
                        m: 0,
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "14px",
                        lineHeight: "150%",
                        fontWeight: 500,
                        textTransform: "none",
                        whiteSpace: "nowrap",
                    }}
                >
                    {children}
                </Typography>
            </Button>
        );
    } else if (variant === "red") {
        return (
            <Button
                type={type}
                disabled={disabled}
                style={style}
                disableRipple
                variant="contained"
                onClick={onClick}
                sx={{
                    height: "fit-content",
                    ":hover": { bgcolor: "#E05757", boxShadow: "none" },
                    ":pressed": { bgcolor: "#E05757", boxShadow: "none" },
                    boxShadow: "none",
                    borderRadius: "42px",
                    display: "flex",
                    padding: padding,
                    gap: "10px",
                    alignItems: "center",
                    backgroundColor: "#E12626",
                }}
            >
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "14px",
                        lineHeight: "150%",
                        fontWeight: 500,
                        textTransform: "none",
                        color: "#fff",
                        whiteSpace: "nowrap",
                    }}
                >
                    {children}
                </Typography>
            </Button>
        );
    } else if (variant === "redText") {
        return (
            <Button
                type={type}
                disabled={disabled}
                variant="text"
                style={style}
                disableRipple
                onClick={onClick}
                sx={{
                    color: textColor,
                    height: "fit-content",
                    ":enabled": { color: "#E12626", boxShadow: "none", background: "transparent" },
                    ":disabled": { color: "#f5a4a4", boxShadow: "none", background: "transparent" },
                    ":hover": { color: "#E05757", boxShadow: "none", background: "transparent" },
                    ":active": { color: "#E05757", boxShadow: "none", background: "transparent" },
                    boxShadow: "none",
                    borderRadius: "42px",
                    display: "flex",
                    m: 0,
                    p: 0,
                    gap: "2px",
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{
                        p: 0,
                        m: 0,
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "14px",
                        lineHeight: "150%",
                        fontWeight: 500,
                        textTransform: "none",
                        whiteSpace: "nowrap",
                    }}
                >
                    {children}
                </Typography>
            </Button>
        );
    }
};

export default SButton;
