import React from "react";
import { memo } from "react";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import ReferralPromoBodyBlock from "components/ReferralPromoBodyBlock/ReferralPromoBodyBlock";

const ReferralPromoPage = ({ children, ...props }) => {
    return (
        <ScrollToTop>
            <ReferralPromoBodyBlock />
        </ScrollToTop>
    );
};

export default memo(ReferralPromoPage);
