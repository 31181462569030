import { styleProcessing } from "API/plugins/Utilities";

export const AddCardFields = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        marginTop: "10px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "0 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const AddCardInfoAndButtonWrapper = { display: "flex", flexDirection: "column-reverse", padding: "20px 16px 0", justifyContent: "space-between", height: "100%" };

export const AddCardBottomFields = {
    marginTop: "-1px",
    display: "flex",
};

export const InfoSecuredBlock = {
    display: "flex",
    gap: "8px",
    paddingTop: "20px",
    paddingBottom: "40px",
    borderTop: "1px solid #EEEEF0",
};

export const AddCardButton = {
    width: "100%",
    marginTop: "30px",
    marginBottom: "20px",
};

export const AddCVC = {
    marginLeft: "-1px",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: "0px 0px 8px 0px",
            border: "1px solid #DADADF",
        },
        "&:hover fieldset": {
            borderRadius: "0px 0px 8px 0px",
            border: "1px solid #DADADF",
        },
        "&.Mui-focused fieldset": {
            borderRadius: "0px 0px 8px 0px",
            border: "1px solid #6212FF",
            zIndex: 500,
        },
    },
};

export const AddMonthYear = {
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: "0px 0px 0px 8px",
            border: "1px solid #DADADF",
        },
        "&:hover fieldset": {
            borderRadius: "0px 0px 0px 8px",
            border: "1px solid #DADADF",
        },
        "&.Mui-focused fieldset": {
            borderRadius: "0px 0px 0px 8px",
            border: "1px solid #6212FF",
            zIndex: 500,
        },
    },
};

export const AddCardNumber = {
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: "8px 8px 0px 0px",
            border: "1px solid #DADADF",
        },
        "&:hover fieldset": {
            borderRadius: "8px 8px 0px 0px",
            border: "1px solid #DADADF",
        },
        "&.Mui-focused fieldset": {
            borderRadius: "8px 8px 0px 0px",
            border: "1px solid #6212FF",
            zIndex: 500,
        },
    },
};
