import React from "react";
import * as themes from "./Error500BodyBlockThemes";
import * as textThemes from "themes/TextThemes";
import NumFive from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumFive/NumFive";
import NumZero from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumZero/NumZero";
import { Box, Typography } from "@mui/material";
import SupportLinkText from "components/ErrorsBodyBlock/ErrorsCommonComponents/SupportLinkText/SupportLinkText";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const Error500BodyBlock = ({ children, planned = true, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.Error500BodyBlock}>
            <Box sx={themes.ErrorBlock}>
                <Box sx={themes.NumBLock}>
                    <NumFive />
                    <NumZero />
                    <NumZero />
                </Box>
                {planned ? (
                    <Box sx={themes.ErrorContentPlanned}>
                        <Typography sx={textThemes.HeadlineH2Black}>{t("errors.serviceNotAble")}</Typography>
                        <Typography sx={textThemes.Body1Black}>{t("errors.weKnowAboutProblem")}</Typography>
                    </Box>
                ) : (
                    <Box sx={themes.ErrorContent}>
                        <Box sx={themes.TopBlock}>
                            <Typography sx={textThemes.HeadlineH2Black}>{t("errors.serviceNotAble")}</Typography>
                            <Typography sx={textThemes.Body1Black}>{t("errors.tryUpdate")}</Typography>
                        </Box>
                        <SupportLinkText />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default memo(Error500BodyBlock);
