import React, { memo } from "react";
import Box from "@mui/material/Box";
import * as themes from "./ExperienceThemes";
import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

const Experience = observer(({ children, ...props }) => {
    return (
        <Box sx={themes.experienceContainerStyle(props.styleMode ? props.styleMode : 0)}>
            <Typography sx={themes.experienceLabelStyle(props.styleMode ? props.styleMode : 0)}>Опыт:</Typography>
            <Box sx={themes.experienceStyle(props.styleMode ? props.styleMode : 0)}>
                <Typography sx={themes.experienceTextStyle(props.styleMode ? props.styleMode : 0)}>{props.value.Experience}</Typography>
            </Box>
        </Box>
    );
});

export default memo(Experience);
