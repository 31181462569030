export const videoManagementPanelContainerStyle = {
    position: "sticky",
    bottom: 10,
    height: 70,
    padding: "12px 20px 12px 20px",
    marginLeft: "10px",
    marginRight: "10px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "20px",
    background: "var(--BG-White, #FFF)",
};

export const demoTooltipContainerStyle = {
    position: "absolute",
    right: 0,
    top: -37,
    display: "flex",
    padding: "3px 10px 4px 10px",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: "42px",
    border: "1px solid var(--Main-Purple, #6212FF)",
    background: "var(--Main-White, #FFF)",
};

export const demoTooltipTextStyle = {
    color: "var(--Main-Purple, #6212FF)",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
};

export const videoManagementPanelMIContainerStyle = {
    display: "flex",
    flexDirection: "column",
};

export const meetingSubjectStyle = {
    color: "var(--Text-Black, #222)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
};

export const meetingStartingTimeStyle = {
    color: "var(--Text-Grey, #9E9EB0)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const videoManagementPanelActionsContainerStyle = {
    display: "flex",
    alignItems: "center",
    gap: 10,
};

export const dividerStyle = (marginLeft, marginRight) => {
    return {
        marginLeft: marginLeft,
        marginRight: marginRight,
    };
};
