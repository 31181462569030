import React, { memo, useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import TutorBodyBlock from "components/TutorBodyBlock/TutorBodyBlock";
import OtherTutorsBlock from "components/TutorBodyBlock/OtherTutorsBlock/OtherTutorsBlock";
import { Box, CircularProgress } from "@mui/material";
import TeacherStore from "store/TeacherStore";
import * as themes from "./TeacherPageThemes";
import { observer } from "mobx-react-lite";
import { useMedias } from "../../API/mediasHook";

const theme = createTheme({
    spacing: 1,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const TeacherPage = observer(({ props }) => {
    const { id } = useParams();

    const [state, SetState] = useState(true);

    useEffect(() => {
        const fetchFunc = async () => {
            await TeacherStore.FetchPageData(id);
        };

        fetchFunc();
    }, [id]);

    useEffect(() => {
        SetState(TeacherStore.loadingTutorData || TeacherStore.loadingRecommendedTutorsData || TeacherStore.loadingRecentReviewsData);
    }, [TeacherStore.loadingTutorData, TeacherStore.loadingRecommendedTutorsData, TeacherStore.loadingRecentReviewsData]);

    const medias = useMedias();

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    background: "#F3F3F3",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "0px 0px 40px 40px",
                    minHeight: medias.sm ? "calc(100% - 120px)" : "unset",
                }}
            >
                {!state ? (
                    <>
                        <TutorBodyBlock></TutorBodyBlock>
                        <OtherTutorsBlock></OtherTutorsBlock>
                    </>
                ) : (
                    <ThemeProvider theme={themes.CircularProgressTheme}>
                        <Box sx={themes.progressContainerStyle}>
                            <CircularProgress />
                        </Box>
                    </ThemeProvider>
                )}
            </Box>
        </ThemeProvider>
    );
});

export default memo(TeacherPage);
