import * as React from "react";
import { memo } from "react";
import { observer } from "mobx-react-lite";

import Box from "@mui/material/Box";

import * as themes from "./MessageFilesThemes";
import DocIconSVG from "assets/CommonAssets/SVG/docIconSVG";
import { Typography } from "@mui/material";

const MessageFiles = observer(({ children, ...props }) => {
    let FileAttachments = [
        {
            ID: 0,
            FileName: "Курсовая по английскому. Требования.doc",
            FileSize: "3.2MB",
            FileSource: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
        },
        {
            ID: 1,
            FileName: "Требования.png",
            FileSize: "0.2MB",
            FileSource: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
        },
    ];

    return (
        <Box sx={themes.messageFilesContainerStyle}>
            {FileAttachments.map((file) => (
                <Box
                    sx={themes.fileContainerStyle}
                    key={file.ID}
                >
                    <DocIconSVG></DocIconSVG>
                    <Box sx={themes.fileInfoContainerStyle}>
                        <Typography sx={themes.fileNameStyle}>{file.FileName}</Typography>
                        <Box sx={themes.fileActionsContainerStyle}>
                            <Typography sx={themes.fileSizeStyle}>{file.FileSize}</Typography>
                            <a
                                style={themes.fileDownloadStyle}
                                href={file.FileSource}
                                download={file.FileName}
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                Скачать
                            </a>
                        </Box>
                    </Box>
                </Box>
            ))}
        </Box>
    );
});

export default memo(MessageFiles);
