import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { saveAs } from "file-saver";

import { Box } from "@mui/material";
import { Avatar } from "@mui/material";

import SButton from "components/UI/SButton";

import STabs from "components/DiplomaChats/STabs/STabs";
import { findChatById } from "../CommonData/helper_functions";
import ImageChatAttachments from "./ImageChatAttachments/ImageChatAttachments";
import VideoChatAttachments from "./VideoChatAttachments/VideoChatAttachments";
import Files from "./FileChatAttachments/Files";
import Urls from "./UrlAttachments/Urls";

import Dima from "assets/ChatAssets/PNG/Dima.png";
import IconBack from "assets/ChatAssets/SVG/iconBack";
import Konstantin from "assets/ChatAssets/PNG/Konstantin.png";
import Boris from "assets/ChatAssets/PNG/Pavel.png";
import Evgen from "assets/ChatAssets/PNG/Evgen.png";

import FullChatStore from "store/FullChatStore";

import * as styles from "./ChatAttachmentsThemes";
import VideoChatStore from "store/VideoChatStore";

const ChatAttachments = ({ sendJsonMessage, isVideo }) => {
    const tabsContent = [
        { id: 1, value: "images", title: "Изображения" },
        { id: 2, value: "videos", title: "Видео" },
        { id: 3, value: "files", title: "Документы" },
        { id: 4, value: "urls", title: "Ссылки" },
    ];

    const tabsContentVideo = [
        { id: 1, value: "images", title: "Изображения" },
        { id: 2, value: "files", title: "Документы" },
        { id: 3, value: "urls", title: "Ссылки" },
    ];

    const [alignment, setAlignment] = useState("images");

    const navigate = useNavigate();

    const avatar = {
        Дмитрий: Dima,
        Константин: Konstantin,
        Борис: Boris,
        Егор: Evgen,
    };

    useEffect(() => {
        sendJsonMessage({
            action: "get_links",
            chat_id: FullChatStore.id,
            type: alignment,
        });
    }, [alignment]);

    const getDate = (attachment) => {
        if (attachment) {
            const monthNames = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];

            const year = attachment.getFullYear();

            const month = attachment.getMonth();

            const day = attachment.getDate();

            let hours = attachment.getHours();

            let minutes = attachment.getMinutes();

            hours = hours < 10 ? "0" + hours : hours;
            minutes = minutes < 10 ? "0" + minutes : minutes;

            return `${day} ${monthNames[month]} ${year}, ${hours}:${minutes}`;
        }
    };

    const handleDownload = async (url, fileName) => {
        try {
            // Получаем изображение в виде blob
            const response = await fetch(url);

            const blob = await response.blob();

            // Сохраняем изображение как файл
            saveAs(blob, fileName);
        } catch (error) {
            console.error("Ошибка при скачивании изображения:", error);
        }
    };

    function mergeArraysFromObject(obj) {
        // Инициализируем пустой массив для объединенных значений
        const mergedArray = [];

        // Получаем все ключи объекта
        const keys = Object.keys(obj);

        // Перебираем каждый ключ
        keys.forEach((key) => {
            // Получаем массив значений по текущему ключу
            const valuesArray = obj[key];

            // Если valuesArray является массивом, объединяем его со mergedArray
            if (Array.isArray(valuesArray)) {
                mergedArray.push(...valuesArray);
            }
        });

        return mergedArray;
    }

    function findIndexByLink(arrayOfObjects, currentLink) {
        // Перебираем массив объектов
        for (let i = 0; i < arrayOfObjects.length; i++) {
            // Получаем текущий объект
            const obj = arrayOfObjects[i];

            // Проверяем, совпадает ли значение ключа 'link' с искомым значением
            if (obj.link === currentLink) {
                // Если совпадение найдено, возвращаем индекс объекта
                return i;
            }
        }

        return -1;
    }

    return (
        <Box style={styles.mainContainer}>
            <Box style={styles.buttonsPanel}>
                <SButton
                    variant="icon"
                    onClick={() => (isVideo ? VideoChatStore.SetIsMedia(false) : FullChatStore.SetMediaPage(false))}
                >
                    <IconBack />
                </SButton>
                {!isVideo && (
                    <STabs
                        content={tabsContent}
                        alignment={alignment}
                        setAlignment={setAlignment}
                    />
                )}
                <Avatar
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                        navigate("/tutor/64902a483594e963dd20818a");
                    }}
                    src={avatar[FullChatStore.tabsData.chats && findChatById(FullChatStore.tabsData.chats, FullChatStore.id).participants_info[0].name]}
                />
            </Box>
            {isVideo && (
                <Box style={styles.buttonsPanelVideo}>
                    <STabs
                        content={tabsContentVideo}
                        alignment={alignment}
                        setAlignment={setAlignment}
                    />
                </Box>
            )}
            <Box style={styles.attachmentsPanel}>
                {alignment === "images" ? (
                    <ImageChatAttachments
                        avatar={avatar}
                        getDate={getDate}
                        handleDownload={handleDownload}
                        mergeArraysFromObject={mergeArraysFromObject}
                        findIndexByLink={findIndexByLink}
                    />
                ) : alignment === "videos" ? (
                    <VideoChatAttachments
                        avatar={avatar}
                        getDate={getDate}
                        handleDownload={handleDownload}
                        mergeArraysFromObject={mergeArraysFromObject}
                    />
                ) : alignment === "files" ? (
                    <Files
                        avatar={avatar}
                        getDate={getDate}
                        handleDownload={handleDownload}
                        mergeArraysFromObject={mergeArraysFromObject}
                    />
                ) : (
                    <Urls />
                )}
            </Box>
        </Box>
    );
};

export default observer(ChatAttachments);
