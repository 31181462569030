import { createTheme } from "@mui/material/styles";

export const OrdersFormatAccordionTheme = createTheme({
    components: {
        MuiAccordion: {
            styleOverrides: {
                // Name of the slot
                root: {
                    width: "249px!important",
                    position: "relative",
                    boxShadow: "none!important",
                    "&:before": {
                        background: "none!important",
                    },
                    "&.Mui-expanded": {
                        marginTop: "0px!important",
                        marginBottom: "0px!important",
                    },
                    ".MuiAccordionSummary-root": {
                        minHeight: "0px!important",
                        padding: 0,
                        paddingBottom: "12px",
                        ".MuiAccordionSummary-content": {
                            marginTop: "0px!important",
                            marginBottom: "0px!important",
                            ".Mui-expanded": {
                                marginTop: "0px!important",
                                marginBottom: "0px!important",
                            },
                        },
                    },
                    ".MuiAccordionDetails-root": {
                        padding: 0,
                    },
                },
            },
        },
    },
});

export const headerOrdersFormatStyle = {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#222222",
    flex: "none",
    order: 0,
    flexGrow: 1,
};

export const FiltersOrdersFormatTheme = createTheme({
    components: {
        MuiFormControl: {
            styleOverrides: {
                // Name of the slot
                root: {
                    ".MuiSvgIcon-root": {
                        color: "#6212FF",
                    },
                    ".MuiButtonBase-root": {
                        paddingTop: 0,
                        paddingBottom: 0,
                        "&:hover": {
                            background: "none!important",
                        },
                    },
                    ".MuiTypography-root": {
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "150%",
                        display: "flex",
                        alignItems: "center",
                        color: "#222222",
                        flex: "none",
                        order: 0,
                        flexGrow: 1,
                        "&:hover": {
                            color: "#6212FF",
                        },
                    },
                    ".MuiFormGroup-root": {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "0px",
                        gap: "12px",
                    },
                },
            },
        },
    },
});
