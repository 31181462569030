const XlsIconSVG = function (props) {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 34.48V5.52C0 2.48 2.48 0 5.52 0H25.576V8.864C25.576 11.904 27.056 13.368 30.096 13.368H40V34.48C40 37.52 37.52 40 34.48 40H5.52C2.48 40 0 37.52 0 34.48ZM30.0962 11.1677C28.8162 11.1677 27.7762 10.1277 27.7762 8.86369V0.879687L39.0082 11.1677H30.0962Z"
                fill="#39AE68"
            />
            <path
                d="M11.5742 18.8906L13.1875 21.6172H13.25L14.8711 18.8906H16.7813L14.3398 22.8906L16.8359 26.8906H14.8906L13.25 24.1602H13.1875L11.5469 26.8906H9.60938L12.1133 22.8906L9.65625 18.8906H11.5742Z"
                fill="white"
            />
            <path
                d="M18.4016 26.8906V18.8906H20.093V25.4961H23.5227V26.8906H18.4016Z"
                fill="white"
            />
            <path
                d="M29.5326 21.1914C29.5014 20.8763 29.3673 20.6315 29.1303 20.457C28.8933 20.2826 28.5717 20.1953 28.1654 20.1953C27.8894 20.1953 27.6563 20.2344 27.4662 20.3125C27.2761 20.388 27.1303 20.4935 27.0287 20.6289C26.9298 20.7643 26.8803 20.918 26.8803 21.0898C26.8751 21.2331 26.905 21.3581 26.9701 21.4648C27.0378 21.5716 27.1303 21.6641 27.2475 21.7422C27.3646 21.8177 27.5001 21.8841 27.6537 21.9414C27.8074 21.9961 27.9714 22.043 28.1459 22.082L28.8646 22.2539C29.2136 22.332 29.5339 22.4362 29.8256 22.5664C30.1173 22.6966 30.3699 22.8568 30.5834 23.0469C30.7969 23.237 30.9623 23.4609 31.0795 23.7188C31.1993 23.9766 31.2605 24.2721 31.2631 24.6055C31.2605 25.0951 31.1355 25.5195 30.8881 25.8789C30.6433 26.2357 30.2891 26.513 29.8256 26.7109C29.3646 26.9063 28.8087 27.0039 28.1576 27.0039C27.5118 27.0039 26.9493 26.9049 26.4701 26.707C25.9936 26.5091 25.6212 26.2161 25.3529 25.8281C25.0873 25.4375 24.948 24.9544 24.935 24.3789H26.5717C26.5899 24.6471 26.6667 24.8711 26.8021 25.0508C26.9402 25.2279 27.1238 25.362 27.3529 25.4531C27.5847 25.5417 27.8464 25.5859 28.1381 25.5859C28.4245 25.5859 28.6732 25.5443 28.8842 25.4609C29.0977 25.3776 29.2631 25.2617 29.3803 25.1133C29.4975 24.9648 29.5561 24.7943 29.5561 24.6016C29.5561 24.4219 29.5027 24.2708 29.3959 24.1484C29.2917 24.026 29.1381 23.9219 28.935 23.8359C28.7344 23.75 28.4883 23.6719 28.1967 23.6016L27.3256 23.3828C26.6511 23.2188 26.1186 22.9622 25.7279 22.6133C25.3373 22.2643 25.1433 21.7943 25.1459 21.2031C25.1433 20.7187 25.2722 20.2956 25.5326 19.9336C25.7956 19.5716 26.1563 19.2891 26.6146 19.0859C27.073 18.8828 27.5938 18.7812 28.1771 18.7812C28.7709 18.7812 29.2891 18.8828 29.7318 19.0859C30.1771 19.2891 30.5235 19.5716 30.7709 19.9336C31.0183 20.2956 31.1459 20.7148 31.1537 21.1914H29.5326Z"
                fill="white"
            />
        </svg>
    );
};

export default XlsIconSVG;
