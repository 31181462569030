import React, { memo } from "react";
import * as themes from "./TagsBlockThemes";
import { observer } from "mobx-react-lite";
import { Chip } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";

const TagsBlock = observer(({ children, ...props }) => {
    const handleClick = (query, e) => {
        console.log(query);
    };

    return (
        <Box sx={themes.tagsBlockContainerStyle}>
            {props.value.Tags.map((tag, index) =>
                index <= 2 ? (
                    <ThemeProvider
                        theme={themes.TagTheme}
                        key={tag.ID}
                    >
                        <Chip
                            key={tag.ID}
                            label={tag.Query}
                            variant="outlined"
                            size="small"
                            disableRipple
                            sx={themes.textStyle}
                            onClick={handleClick.bind(this, tag)}
                        ></Chip>
                    </ThemeProvider>
                ) : null,
            )}
        </Box>
    );
});

export default memo(TagsBlock);
