const DragNDropCloseIcon = function (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M13 7L7 13M13 13L7 7"
                stroke="#4E4E4E"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default DragNDropCloseIcon;
