import { styleProcessing } from "API/plugins/Utilities";

export const photoBlock = (theme) => {
    let commonStyle = {
        width: 475,
        height: 529,
        position: "relative",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: 328,
            height: 144,
            position: "relative",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Mouse = (theme) => {
    let commonStyle = {
        width: 102,
        height: 111,
        left: 372,
        position: "absolute",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            display: "none",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const SmartGirl = (theme) => {
    let commonStyle = {
        width: 123,
        height: 131,
        top: 31,
        right: 352,
        position: "absolute",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            display: "none",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const KidHello = (theme) => {
    let commonStyle = {
        width: 83,
        height: 87,
        top: 36,
        left: 196,
        position: "absolute",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            display: "none",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Strange = (theme) => {
    let commonStyle = {
        width: 83,
        height: 87,
        top: 432,
        left: 63,
        position: "absolute",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            display: "none",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Lady = (theme) => {
    let commonStyle = {
        top: 255,
        left: 24,
        position: "absolute",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: 83,
            height: 87,
            top: 0,
            left: 32,
            position: "absolute",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Pair = (theme) => {
    let commonStyle = {
        top: 194,
        left: 198,
        position: "absolute",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: 124,
            height: 124,
            top: 0,
            left: 204,
            position: "absolute",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
