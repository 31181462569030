import React, { memo } from "react";
import { Typography } from "@mui/material";
import IconArrow from "assets/SettingsAssets/SVG/iconarrow";
import * as themes from "./AccountActionsThemes";
import * as textThemes from "themes/TextThemes";
import Box from "@mui/material/Box";
import TeacherSettingsStore from "store/TeacherSettingsStore";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import ModalBusyMode from "./ModalBusyMode/ModalBusyMode";
import ModalWeAreSorry from "./ModalDeleteAccount/ModalWeAreSorry/ModalWeAreSorry";
import ModalReasonWhyDelete from "./ModalDeleteAccount/ModalReasonWhyDelete/ModalReasonWhyDelete";
import ModalAccountDeleted from "./ModalDeleteAccount/ModalAccountDeleted/ModalAccountDeleted";
import UserStore from "store/UserStore";
import { useNavigate } from "react-router-dom";
import ModalSomethingLeft from "./ModalDeleteAccount/ModalSomethingLeft/ModalSomethingLeft";
import MiniChatStore from "store/MiniChatStore";
import { useMedias } from "API/mediasHook";

const AccountActions = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, "0");

        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based

        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    };

    //Busy mode

    const [dialogBusyModeOpen, SetDialogBusyModeOpen] = React.useState("");

    const showBusyModeDialog = () => {
        TeacherSettingsStore.setTemporaryDayUntilBusy(TeacherSettingsStore.dayUntilBusy);
        SetDialogBusyModeOpen(true);
    };

    const discardBusyModeDialog = () => {
        SetDialogBusyModeOpen(false);
    };

    const saveBusyModeDialog = () => {
        SetDialogBusyModeOpen(false);
        TeacherSettingsStore.setDayUntilBusy(TeacherSettingsStore.temporaryDayUntilBusy);
    };

    //We are sorry

    const [dialogWeAreSorryOpen, SetDialogWeAreSorryOpen] = React.useState("");

    const showWeAreSorryDialog = () => {
        SetDialogWeAreSorryOpen(true);
    };

    const discardWeAreSorryDialog = () => {
        SetDialogWeAreSorryOpen(false);
    };

    const saveWeAreSorryDialog = () => {
        SetDialogWeAreSorryOpen(false);
        showReasonWhyDeleteDialog();
        TeacherSettingsStore.setDeleteAccountReason("");
    };

    //Reason why delete

    const [dialogReasonWhyDeleteOpen, SetDialogReasonWhyDeleteOpen] = React.useState("");

    const showReasonWhyDeleteDialog = () => {
        SetDialogReasonWhyDeleteOpen(true);
    };

    const discardReasonWhyDeleteDialog = () => {
        SetDialogReasonWhyDeleteOpen(false);
    };

    const saveReasonWhyDeleteDialog = () => {
        SetDialogReasonWhyDeleteOpen(false);
        showAccountDeletedDialog();
        UserStore.setAuth(false);
    };

    //Account deleted

    const [dialogAccountDeletedOpen, SetDialogAccountDeletedOpen] = React.useState("");

    const showAccountDeletedDialog = () => {
        SetDialogAccountDeletedOpen(true);
    };

    const discardAccountDeletedDialog = () => {
        SetDialogAccountDeletedOpen(false);
        navigate("/promotutor");
    };

    //Something Left

    const [dialogSomethingLeftOpen, SetDialogSomethingLeftOpen] = React.useState("");

    const showSomethingLeftDialog = () => {
        SetDialogSomethingLeftOpen(true);
    };

    const discardSomethingLeftDialog = () => {
        SetDialogSomethingLeftOpen(false);
    };

    const saveSomethingLeftDialog = () => {
        SetDialogSomethingLeftOpen(false);
        MiniChatStore.SetOpen(true);
    };

    return (
        <Box sx={themes.SmallItem(medias)}>
            <ModalBusyMode
                open={dialogBusyModeOpen}
                handleDiscard={discardBusyModeDialog}
                handleSave={saveBusyModeDialog}
            />

            <ModalWeAreSorry
                open={dialogWeAreSorryOpen}
                handleDiscard={discardWeAreSorryDialog}
                handleSave={saveWeAreSorryDialog}
            />

            <ModalReasonWhyDelete
                open={dialogReasonWhyDeleteOpen}
                handleDiscard={discardReasonWhyDeleteDialog}
                handleSave={saveReasonWhyDeleteDialog}
            />

            <ModalAccountDeleted
                open={dialogAccountDeletedOpen}
                handleDiscard={discardAccountDeletedDialog}
            />

            <ModalSomethingLeft
                open={dialogSomethingLeftOpen}
                handleDiscard={discardSomethingLeftDialog}
                handleSave={saveSomethingLeftDialog}
            />

            <Typography sx={medias.sm ? textThemes.HeadlineH41Black : textThemes.HeadlineH5Black}>{t("teacherSettings.accountActions.accountActions")}</Typography>
            <Box sx={themes.ActionButtonsContainer}>
                <Box
                    onClick={showBusyModeDialog}
                    sx={themes.ActionButton}
                >
                    <Box sx={themes.ActionButtonContent}>
                        <Typography sx={textThemes.Button16Black}>{t("teacherSettings.accountActions.turnOnEmploymentMode")}</Typography>
                        {TeacherSettingsStore.dayUntilBusy === "" ? (
                            <Typography sx={textThemes.Body2DarkGrey}>{t("teacherSettings.accountActions.veryBusy")}</Typography>
                        ) : (
                            <Typography sx={textThemes.Body2DarkGrey}>{`${t("teacherSettings.accountActions.busyModeOn")}${formatDate(TeacherSettingsStore.dayUntilBusy)}`}</Typography>
                        )}
                    </Box>
                    <Box>
                        <IconArrow />
                    </Box>
                </Box>
                <Box
                    onClick={TeacherSettingsStore.leftOnAccount.length === 0 ? showWeAreSorryDialog : showSomethingLeftDialog}
                    sx={themes.ActionButton}
                >
                    <Box sx={themes.ActionButtonContent}>
                        <Typography sx={textThemes.Button16Black}>{t("teacherSettings.accountActions.deleteAccount")}</Typography>
                        <Typography sx={textThemes.Body2DarkGrey}>{t("teacherSettings.accountActions.youCanDelete")}</Typography>
                    </Box>
                    <Box>
                        <IconArrow />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
});

export default memo(AccountActions);
