import React from "react";
import { Grid, Typography } from "@mui/material";
import * as themes from "components/SettingsBodyBlock/TutorSettings/MainForm/MainFormThemes";
import SButton from "components/UI/SButton";
import store from "store/TeacherSettingsStore";
import * as textThemes from "themes/TextThemes";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import { observer } from "mobx-react-lite";
import TeacherQuestionnaireStore from "../../../../../store/TeacherQuestionnaireStore";
import { useMedias } from "API/mediasHook";
import SIconButton from "../../SIconButton";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";

const Experience = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const getYears = (index) => {
        if (TeacherQuestionnaireStore.teacher.experience.beginYear !== "Год") {
            let year =
                (TeacherQuestionnaireStore.teacher.experience[index].endYear === "Год" || TeacherQuestionnaireStore.teacher.experience[index].stillWorking === true
                    ? new Date().getFullYear()
                    : TeacherQuestionnaireStore.teacher.experience[index].endYear) - TeacherQuestionnaireStore.teacher.experience[index].beginYear;

            if (year === 1 || (year > 20 && year % 10 === 1)) return "(" + year + t("teacherSettings.questionnaire.yearOne");

            if (year === 2 || year === 3 || year === 4 || (year > 20 && (year % 10 === 2 || year % 10 === 3 || year % 10 === 4))) return "(" + year + t("teacherSettings.questionnaire.yearFew");
            else if (year > 0) return "(" + year + t("teacherSettings.questionnaire.yearMany");
            else return t("teacherSettings.questionnaire.lessThanYear");
        }
    };

    const handleEdit = () => {
        props.handleSet(5);
    };

    return (
        <Box sx={themes.SmallItem(medias)}>
            <Box sx={themes.ItemContent(medias)}>
                <Box sx={themes.TitleAndEdit}>
                    <Typography sx={medias.sm ? textThemes.HeadlineH41Black : textThemes.HeadlineH5Black}>{t("teacherSettings.questionnaire.experienceShort")}</Typography>
                    {medias.sm ? (
                        <SButton
                            onClick={handleEdit}
                            variant="text"
                        >
                            {t("teacherSettings.questionnaire.edit")}
                        </SButton>
                    ) : (
                        <SIconButton onClick={handleEdit}>
                            <DriveFileRenameOutlineOutlinedIcon />
                        </SIconButton>
                    )}
                </Box>
                <Box sx={themes.VerticalContainer}>
                    {TeacherQuestionnaireStore.teacher.experience.map((item, index) => (
                        <Typography
                            sx={textThemes.Body1Black}
                            key={item.id}
                        >
                            {item.organization}
                            {item.organization && ", "}
                            {item.position}
                            {item.position && ", "}
                            <span style={textThemes.Body1DarkGrey}>
                                {item.stillWorking
                                    ? t("teacherSettings.questionnaire.from") + item.beginYear + t("teacherSettings.questionnaire.year") + getYears(index)
                                    : item.beginYear + "-" + item.endYear + t("teacherSettings.questionnaire.year") + getYears(index)}
                            </span>
                        </Typography>
                    ))}
                </Box>
            </Box>
        </Box>
    );
});

export default memo(Experience);
