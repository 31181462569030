export const HeadlineH60Black = {
    color: "#222",
    fontStyle: "normal",
    fontSize: "60px",
    fontWeight: "500",
    lineHeight: "59px",
};

export const HeadlineH2Black = (medias) => {
    return {
        color: "#222",
        fontStyle: "normal",
        fontSize: medias.sm ? "40px" : "24px",
        fontWeight: "600",
        lineHeight: medias.sm ? "135%" : "130%",
    };
};

export const HeadlineH3Black = {
    color: "#222",
    fontStyle: "normal",
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH41Black = {
    color: "#222",
    fontStyle: "normal",
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH4Black = {
    color: "#222",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const HeadlineH5Black = {
    color: "#222",
    fontStyle: "normal",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "150%",
};

export const Body1Black = {
    color: "#222",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "150%",
    wordBreak: "break-word",
};

export const Body16w600 = {
    color: "#222",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "150%",
};

export const Body2Black = {
    color: "#222",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Caption1Black = {
    color: "#222",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption2Black = {
    color: "#222",
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption11Black = {
    color: "#222",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "500",
    lineHeight: "140%",
};

export const Button16Black = {
    color: "#222",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const Button14Black = {
    color: "#222",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const Button16BoldBlack = {
    color: "#222",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "150%",
};

export const Button20BoldBlack = (medias) => {
    return {
        color: "#222",
        fontStyle: "normal",
        fontSize: medias.sm ? "20px" : "18px",
        fontWeight: "600",
        lineHeight: "135%",
    };
};

export const Button14BoldBlack = {
    color: "#222",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "150%",
};

export const HeadlineH2LightBlack = {
    color: "#4E4E4E",
    fontStyle: "normal",
    fontSize: "40px",
    fontWeight: "600",
    lineHeight: "135%",
};

export const HeadlineH3LightBlack = {
    color: "#4E4E4E",
    fontStyle: "normal",
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH41LightBlack = {
    color: "#4E4E4E",
    fontStyle: "normal",
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH4LightBlack = {
    color: "#4E4E4E",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const HeadlineH5LightBlack = {
    color: "#4E4E4E",
    fontStyle: "normal",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "150%",
};

export const Body1LightBlack = {
    color: "#4E4E4E",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Body2LightBlack = {
    color: "#4E4E4E",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Caption1LightBlack = {
    color: "#4E4E4E",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption2LightBlack = {
    color: "#4E4E4E",
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption11LightBlack = {
    color: "#4E4E4E",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "500",
    lineHeight: "140%",
};

export const Button16LightBlack = {
    color: "#4E4E4E",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const Button14LightBlack = {
    color: "#4E4E4E",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const HeadlineH2DarkGrey = {
    color: "#67677A",
    fontStyle: "normal",
    fontSize: "40px",
    fontWeight: "600",
    lineHeight: "135%",
};

export const HeadlineH3DarkGrey = {
    color: "#67677A",
    fontStyle: "normal",
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH41DarkGrey = {
    color: "#67677A",
    fontStyle: "normal",
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH4DarkGrey = {
    color: "#67677A",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const HeadlineH5DarkGrey = {
    color: "#67677A",
    fontStyle: "normal",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "150%",
};

export const Body1DarkGrey = {
    color: "#67677A",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Body2DarkGrey = {
    color: "#67677A",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Caption1DarkGrey = {
    color: "#67677A",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption2DarkGrey = {
    color: "#67677A",
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption11DarkGrey = {
    color: "#67677A",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "500",
    lineHeight: "140%",
};

export const Button16DarkGrey = {
    color: "#67677A",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const Button14DarkGrey = {
    color: "#67677A",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const HeadlineH2Grey = {
    color: "#9E9EB0",
    fontStyle: "normal",
    fontSize: "40px",
    fontWeight: "600",
    lineHeight: "135%",
};

export const HeadlineH3Grey = {
    color: "#9E9EB0",
    fontStyle: "normal",
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH41Grey = {
    color: "#9E9EB0",
    fontStyle: "normal",
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH4Grey = {
    color: "#9E9EB0",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const HeadlineH5Grey = {
    color: "#9E9EB0",
    fontStyle: "normal",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "150%",
};

export const Body1Grey = {
    color: "#9E9EB0",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Body2Grey = {
    color: "#9E9EB0",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Caption1Grey = {
    color: "#9E9EB0",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption2Grey = {
    color: "#9E9EB0",
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption11Grey = {
    color: "#9E9EB0",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "500",
    lineHeight: "140%",
};

export const Button16Grey = {
    color: "#9E9EB0",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const Button14Grey = {
    color: "#9E9EB0",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const HeadlineH2Purple = {
    color: "#6212FF",
    fontStyle: "normal",
    fontSize: "40px",
    fontWeight: "600",
    lineHeight: "135%",
};

export const HeadlineH3Purple = {
    color: "#6212FF",
    fontStyle: "normal",
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH41Purple = {
    color: "#6212FF",
    fontStyle: "normal",
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH4Purple = {
    color: "#6212FF",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const HeadlineH5Purple = {
    color: "#6212FF",
    fontStyle: "normal",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "150%",
};

export const Body1Purple = {
    color: "#6212FF",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Body2Purple = {
    color: "#6212FF",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Caption1Purple = {
    color: "#6212FF",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption2Purple = {
    color: "#6212FF",
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption11Purple = {
    color: "#6212FF",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "500",
    lineHeight: "140%",
};

export const Button16Purple = {
    color: "#6212FF",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const Button14Purple = {
    color: "#6212FF",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const Button16BoldPurple = {
    color: "#6212FF",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "150%",
};

export const HeadlineH2Red = {
    color: "#E12626",
    fontStyle: "normal",
    fontSize: "40px",
    fontWeight: "600",
    lineHeight: "135%",
};

export const HeadlineH3Red = {
    color: "#E12626",
    fontStyle: "normal",
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH41Red = {
    color: "#E12626",
    fontStyle: "normal",
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH4Red = {
    color: "#E12626",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const HeadlineH5Red = {
    color: "#E12626",
    fontStyle: "normal",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "150%",
};

export const Body1Red = {
    color: "#E12626",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Body2Red = {
    color: "#E12626",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Caption1Red = {
    color: "#E12626",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption2Red = {
    color: "#E12626",
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption11Red = {
    color: "#E12626",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "500",
    lineHeight: "140%",
};

export const Button16Red = {
    color: "#E12626",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const Button14Red = {
    color: "#E12626",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const HeadlineH2Blue = {
    color: "#12AAFF",
    fontStyle: "normal",
    fontSize: "40px",
    fontWeight: "600",
    lineHeight: "135%",
};

export const HeadlineH3Blue = {
    color: "#12AAFF",
    fontStyle: "normal",
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH41Blue = {
    color: "#12AAFF",
    fontStyle: "normal",
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH4Blue = {
    color: "#12AAFF",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const HeadlineH5Blue = {
    color: "#12AAFF",
    fontStyle: "normal",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "150%",
};

export const Body1Blue = {
    color: "#12AAFF",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Body2Blue = {
    color: "#12AAFF",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Caption1Blue = {
    color: "#12AAFF",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption2Blue = {
    color: "#12AAFF",
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption11Blue = {
    color: "#12AAFF",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "500",
    lineHeight: "140%",
};

export const Button16Blue = {
    color: "#12AAFF",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const Button14Blue = {
    color: "#12AAFF",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const HeadlineH2White = (medias) => {
    return {
        width: medias.sm ? "496px" : "fit-content",
        color: "#FFFFFF",
        fontStyle: "normal",
        fontSize: medias.sm ? "40px" : "24px",
        fontWeight: "600",
        lineHeight: "135%",
    };
};

export const HeadlineH3White = {
    color: "#FFFFFF",
    fontStyle: "normal",
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH41White = {
    color: "#FFFFFF",
    fontStyle: "normal",
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH4White = {
    color: "#FFFFFF",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const HeadlineH5White = {
    color: "#FFFFFF",
    fontStyle: "normal",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "150%",
};

export const Body1White = {
    color: "#FFFFFF",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Body2White = {
    color: "#FFFFFF",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Caption1White = {
    color: "#FFFFFF",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption2White = {
    color: "#FFFFFF",
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption11White = {
    color: "#FFFFFF",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "500",
    lineHeight: "140%",
};

export const Button16White = {
    color: "#FFFFFF",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const Button14White = {
    color: "#FFFFFF",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const Button12White = {
    color: "#FFFFFF",
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "130%",
};

export const Button12WhiteBold = {
    color: "#FFFFFF",
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "150%",
};

export const Button20BoldWhite = {
    color: "#FFFFFF",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "135%",
};

export const Button14BoldWhite = {
    color: "#FFFFFF",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "150%",
};

export const HeadlineH2White90 = {
    color: "rgba(255, 255, 255, 0.90)",
    fontStyle: "normal",
    fontSize: "40px",
    fontWeight: "600",
    lineHeight: "135%",
};

export const HeadlineH3White90 = {
    color: "rgba(255, 255, 255, 0.90)",
    fontStyle: "normal",
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH41White90 = {
    color: "rgba(255, 255, 255, 0.90)",
    fontStyle: "normal",
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH4White90 = {
    color: "rgba(255, 255, 255, 0.90)",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const HeadlineH5White90 = {
    color: "rgba(255, 255, 255, 0.90)",
    fontStyle: "normal",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "150%",
};

export const Body1White90 = {
    color: "rgba(255, 255, 255, 0.90)",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Body2White90 = {
    color: "rgba(255, 255, 255, 0.90)",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Caption1White90 = {
    color: "rgba(255, 255, 255, 0.90)",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption2White90 = {
    color: "rgba(255, 255, 255, 0.90)",
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption11White90 = {
    color: "rgba(255, 255, 255, 0.90)",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "500",
    lineHeight: "140%",
};

export const Button16White90 = {
    color: "rgba(255, 255, 255, 0.90)",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const Button14White90 = {
    color: "rgba(255, 255, 255, 0.90)",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const HeadlineH2White70 = {
    color: "rgba(255, 255, 255, 0.70)",
    fontStyle: "normal",
    fontSize: "40px",
    fontWeight: "600",
    lineHeight: "135%",
};

export const HeadlineH3White70 = {
    color: "rgba(255, 255, 255, 0.70)",
    fontStyle: "normal",
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH41White70 = {
    color: "rgba(255, 255, 255, 0.70)",
    fontStyle: "normal",
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "140%",
};

export const HeadlineH4White70 = {
    color: "rgba(255, 255, 255, 0.70)",
    fontStyle: "normal",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const HeadlineH5White70 = {
    color: "rgba(255, 255, 255, 0.70)",
    fontStyle: "normal",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "150%",
};

export const Body1White70 = {
    color: "rgba(255, 255, 255, 0.70)",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Body2White70 = {
    color: "rgba(255, 255, 255, 0.70)",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Caption1White70 = {
    color: "rgba(255, 255, 255, 0.70)",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption2White70 = {
    color: "rgba(255, 255, 255, 0.70)",
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Caption11White70 = {
    color: "rgba(255, 255, 255, 0.70)",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "500",
    lineHeight: "140%",
};

export const Button16White70 = {
    color: "rgba(255, 255, 255, 0.70)",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const Button14White70 = {
    color: "rgba(255, 255, 255, 0.70)",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "150%",
};

export const Caption1DarkGreen = {
    color: "#19B20C",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const Body2Green = {
    color: "#19B20C",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Body2Orange = {
    color: "#F58909",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Body2White40 = {
    color: "rgba(255, 255, 255, 0.40)",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
};

export const Caption700White = {
    color: "#FFF",
    fontStyle: "normal",
    fontSize: "30px",
    fontWeight: "700",
    lineHeight: "120%",
};
