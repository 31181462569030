const ZipIconSVG = function (props) {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 34.48V5.52C0 2.48 2.48 0 5.52 0H25.576V8.864C25.576 11.904 27.056 13.368 30.096 13.368H40V34.48C40 37.52 37.52 40 34.48 40H5.52C2.48 40 0 37.52 0 34.48ZM30.0962 11.1677C28.8162 11.1677 27.7762 10.1277 27.7762 8.86369V0.879687L39.0082 11.1677H30.0962Z"
                fill="#BBBBC8"
            />
            <path
                d="M11.4688 27V25.9961L15.4609 20.3945H11.4609V19H17.5859V20.0039L13.5898 25.6055H17.5938V27H11.4688Z"
                fill="white"
            />
            <path
                d="M21.133 19V27H19.4416V19H21.133Z"
                fill="white"
            />
            <path
                d="M23.0746 27V19H26.2309C26.8376 19 27.3546 19.1159 27.7816 19.3477C28.2087 19.5768 28.5342 19.8958 28.7582 20.3047C28.9848 20.7109 29.098 21.1797 29.098 21.7109C29.098 22.2422 28.9835 22.7109 28.7543 23.1172C28.5251 23.5234 28.1931 23.8398 27.7582 24.0664C27.3259 24.293 26.8025 24.4062 26.1879 24.4062H24.1762V23.0508H25.9145C26.24 23.0508 26.5082 22.9948 26.7191 22.8828C26.9327 22.7682 27.0915 22.6107 27.1957 22.4102C27.3025 22.207 27.3559 21.974 27.3559 21.7109C27.3559 21.4453 27.3025 21.2135 27.1957 21.0156C27.0915 20.8151 26.9327 20.6602 26.7191 20.5508C26.5056 20.4388 26.2348 20.3828 25.9066 20.3828H24.766V27H23.0746Z"
                fill="white"
            />
        </svg>
    );
};

export default ZipIconSVG;
