import React from "react";
import { Box, Typography } from "@mui/material";
import * as themes from "./SummaryIndicatorsThemes";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import TeacherPaidServicesStore from "store/TeacherPaidServicesStore";
import { observer } from "mobx-react-lite";
import { useMedias } from "API/mediasHook";

const SummaryIndicators = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const capitalizeFirstLetter = (string) => {
        if (!string) return "";

        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <Box sx={themes.SummaryIndicators(medias)}>
            <Box sx={themes.VerticalBlock(medias)}>
                <Typography sx={textThemes.Body2DarkGrey}>{medias.sm ? t("paidServices.reportingPeriod") : t("paidServices.period") + ":"}</Typography>
                <Box sx={themes.PeriodBlock}>
                    <Typography
                        sx={medias.sm ? textThemes.HeadlineH5Black : textThemes.Button14BoldBlack}
                    >{`${TeacherPaidServicesStore.formatDateToPoints(TeacherPaidServicesStore.periodStartDate)} - ${TeacherPaidServicesStore.formatDateToPoints(TeacherPaidServicesStore.periodEndDate)}`}</Typography>
                </Box>
            </Box>
            {medias.sm && <Box sx={themes.Divider} />}
            <Box sx={themes.CentralInfoBlockWrapper(medias)}>
                <Box sx={themes.VerticalBlock(medias)}>
                    <Typography sx={textThemes.Body2DarkGrey}>{medias.sm ? t("paidServices.numberOfServices") : capitalizeFirstLetter(t("paidServices.serviceFew") + ":")}</Typography>
                    <Typography sx={medias.sm ? textThemes.HeadlineH5Black : textThemes.Button14BoldBlack}>
                        {TeacherPaidServicesStore.PaidServicesSummary.services == null ? `0` : TeacherPaidServicesStore.PaidServicesSummary.services}
                    </Typography>
                </Box>
                {medias.sm && <Box sx={themes.Divider} />}
                <Box sx={themes.VerticalBlock(medias)}>
                    <Typography sx={textThemes.Body2DarkGrey}>{medias.sm ? t("paidServices.classes") : capitalizeFirstLetter(t("paidServices.classes") + ":")}</Typography>
                    <Typography sx={medias.sm ? textThemes.HeadlineH5Black : textThemes.Button14BoldBlack}>
                        {TeacherPaidServicesStore.PaidServicesSummary.classes == null ? `0` : TeacherPaidServicesStore.PaidServicesSummary.classes}
                    </Typography>
                </Box>
                {medias.sm && <Box sx={themes.Divider} />}
                <Box sx={themes.VerticalBlock(medias)}>
                    <Typography sx={textThemes.Body2DarkGrey}>{medias.sm ? t("paidServices.writtenWorks") : capitalizeFirstLetter(t("paidServices.works") + ":")}</Typography>
                    <Typography sx={medias.sm ? textThemes.HeadlineH5Black : textThemes.Button14BoldBlack}>
                        {TeacherPaidServicesStore.PaidServicesSummary.works == null ? `0` : TeacherPaidServicesStore.PaidServicesSummary.works}
                    </Typography>
                </Box>
            </Box>
            {medias.sm && <Box sx={themes.Divider} />}
            <Box sx={themes.VerticalBlock(medias)}>
                <Typography sx={textThemes.Body2DarkGrey}>{medias.sm ? t("paidServices.profit") : capitalizeFirstLetter(t("paidServices.profit") + ":")}</Typography>
                <Typography sx={medias.sm ? textThemes.HeadlineH5Black : textThemes.Button14BoldBlack}>
                    {TeacherPaidServicesStore.PaidServicesSummary.income == null ? `0 ₽` : `${TeacherPaidServicesStore.PaidServicesSummary.income.toLocaleString("ru-RU").replace(/\s/g, "\u00A0")} ₽`}
                </Typography>
            </Box>
        </Box>
    );
});

export default memo(SummaryIndicators);
