export const favouritesTopBlockContainer = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: 24,
    background: "#FFFFFF",
    boxShadow: "0px 4px 30px rgba(106, 99, 118, 0.05)",
    borderRadius: "0px 0px 40px 40px",
    paddingTop: 40,
    paddingBottom: 40,
    width: "100%",
};

export const headerStyle = {
    width: 1216,
    margin: "auto",
    color: "var(--text-black, #222)",
    fontSize: "40px",
    fontStyle: "normal",
    fontweight: "600",
    lineHeight: "120%",
};
