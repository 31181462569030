import React, { useState } from "react";
import { Grid, IconButton, TextField, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as NewPassStyle from "./NewPasswordStyles";
import SButton from "components/UI/SButton";
import UserStore from "store/UserStore";
import Box from "@mui/material/Box";

const NewPassword = () => {
    async function handleSubmit(e) {
        var strGET = window.location.search.replace("?", "");

        e.preventDefault();
        const data = new FormData(e.currentTarget);

        console.log(strGET.split("=")[1]);
        let resp = await UserStore.reset_pass_verify(strGET.split("=")[1], data.get("pass1"));

        if (resp === "ok") {
            window.location.href = "/";
        } else {
            setMessage2("ошибка сброса, попробуйте позже");
        }
    }

    const handlePassword1 = (e) => {
        var strGET = window.location.search.replace("?", "");

        setPassword(e.target.value);
        let reg = /(?=.*[0-9])(?=.*[a-z])[0-9a-z]{8,}/gi;

        if (e.target.value === "") {
            setMessage1();
            setMessage2();
            setDisabled(true);
        } else {
            if (!reg.test(e.target.value)) {
                setMessage1("Слабый пароль");
                setColor1("error");
                setDisabled(true);
            } else {
                setMessage1("Надежный пароль");
                setColor1("success");
            }

            if (e.target.value !== password2) {
                setMessage2("Пароли должны совпадать");
                setColor2("error");
                setDisabled(true);
            } else {
                setMessage2("Пароли совпадают");
                setColor2("success");
                setDisabled(false);
            }
        }
    };

    const handlePassword2 = (e) => {
        setPassword2(e.target.value);
        if (e.target.value !== "") {
            if (e.target.value !== password) {
                setMessage2("Пароли должны совпадать");
                setColor2("error");
                setDisabled(true);
            } else if (e.target.value !== "") {
                setMessage2("Пароли совпадают");
                setColor2("success");
                setDisabled(false);
            }
        } else {
            setMessage2();
            setDisabled(true);
        }
    };

    const [buttondisabled, setDisabled] = useState(true);

    const [showPassword1, setShowPassword1] = React.useState(false);

    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

    const handleMouseDownPassword1 = (event) => {
        event.preventDefault();
    };

    const [showPassword2, setShowPassword2] = React.useState(false);

    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };

    const [color1, setColor1] = useState("primary");

    const [color2, setColor2] = useState("primary");

    const [messageP1, setMessage1] = useState();

    const [messageP2, setMessage2] = useState();

    const [password, setPassword] = useState();

    const [password2, setPassword2] = useState();

    return (
        <Box sx={{ backgroundColor: "#170F26" }}>
            <Box sx={NewPassStyle.Back}>
                <Grid
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={NewPassStyle.MainBlock}
                >
                    <Box sx={NewPassStyle.Header}>Изменение пароля</Box>
                    <Grid
                        sx={{
                            marginBottom: "30px",
                        }}
                        fontSize="14px"
                    >
                        Пароль должен состоять минимум из 8 символов и содержать латинские буквы и цифры
                    </Grid>
                    <Grid fontSize="14px">Введите новый пароль</Grid>
                    <Grid>
                        <TextField
                            type={showPassword1 ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword1}
                                            onMouseDown={handleMouseDownPassword1}
                                            edge="end"
                                        >
                                            {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            required
                            fullWidth
                            color={color1}
                            FormHelperTextProps={{
                                sx: {
                                    color: color1 === "success" ? "green" : "red",
                                },
                            }}
                            variant="standard"
                            name="pass1"
                            onChange={(e) => {
                                setDisabled(false);
                                handlePassword1(e);
                            }}
                            placeholder="пароль"
                            value={password}
                            helperText={messageP1}
                        />
                    </Grid>
                    <Grid
                        fontSize="14px"
                        sx={{ marginTop: "20px" }}
                    >
                        Повторите пароль, чтобы не ошибиться
                    </Grid>
                    <Grid sx={{ width: "400px" }}>
                        <TextField
                            type={showPassword2 ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword2}
                                            onMouseDown={handleMouseDownPassword2}
                                            edge="end"
                                        >
                                            {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            required
                            fullWidth
                            color={color2}
                            variant="standard"
                            name="pass2"
                            FormHelperTextProps={{
                                sx: {
                                    color: color2 === "success" ? "green" : "red",
                                },
                            }}
                            onChange={(e) => {
                                setDisabled(false);
                                handlePassword2(e);
                            }}
                            placeholder="пароль"
                            value={password2}
                            helperText={messageP2}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            marginTop: "40px",
                        }}
                    >
                        <SButton
                            type="submit"
                            disabled={buttondisabled}
                            style={{ position: "absolute", top: "470px", width: "400px" }}
                        >
                            Сохранить
                        </SButton>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default NewPassword;
