import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";

import { Stack, IconButton, InputAdornment } from "@mui/material";
import FullChatStore from "store/FullChatStore";
import IconClose from "assets/ChatAssets/SVG/iconClose";
import { findChatById } from "../../CommonData/helper_functions";
import Box from "@mui/material/Box";

const PinnedMessagePanel = (props) => {
    const handleUnpinMessage = () => {
        FullChatStore.AddDelPinnedMessage(props.sendJsonMessage, FullChatStore.currPinnedMessageId, false);
    };

    const handleScroll = () => {
        FullChatStore.GetForScrollMessages(props.sendJsonMessage, FullChatStore.currPinnedMessageId);

        var indchat = findChatById(FullChatStore.tabsData.chats, FullChatStore.id, true);

        var ind = FullChatStore.tabsData.chats[indchat].pinned_messages.findIndex((id) => id === FullChatStore.currPinnedMessageId);

        if (ind > 0) FullChatStore.SetCurrMessagePinnedId(FullChatStore.tabsData.chats[indchat].pinned_messages[ind - 1]);
        else FullChatStore.SetCurrMessagePinnedId(FullChatStore.tabsData.chats[indchat].pinned_messages[FullChatStore.tabsData.chats[indchat].pinned_messages.length - 1]);
    };

    useEffect(() => {
        if (FullChatStore.currPinnedMessageId !== null) FullChatStore.GetTextPinnedMessage(props.sendJsonMessage, FullChatStore.currPinnedMessageId);
    }, [FullChatStore.currPinnedMessageId]);

    return FullChatStore.currPinnedMessageId !== null ? (
        <Stack
            sx={{
                width: "100%",
                height: "56px",
                backgroundColor: "#FAFAFC",
                borderBottom: "solid 1px rgba(218, 218, 223, 1)",
                paddingLeft: props.mini ? "1vw" : "3.9vw",
            }}
            direction={"row"}
            alignItems={"center"}
        >
            <Box
                sx={{
                    borderLeft: "solid 1px #6212FF",
                    paddingLeft: "0.7vw",
                    width: "92%",
                    height: "40px",
                }}
            >
                <Box
                    sx={{ display: "inline-block", width: "90%", fontSize: "1.9vh" }}
                    onClick={handleScroll}
                >
                    <Box sx={{ color: "#6212FF", marginBottom: "3px" }}>Закрепленное сообщение</Box>
                    <Box>
                        {FullChatStore.currPinnedMessageText?.substr(0, props.mini ? 30 : 50)}
                        {FullChatStore.currPinnedMessageText?.length > (props.mini ? 30 : 50) && "..."}
                    </Box>
                </Box>
                <Box sx={{ display: "inline-block", width: "10%", verticalAlign: "top" }}>
                    <IconButton onClick={() => handleUnpinMessage()}>
                        <IconClose />
                    </IconButton>
                </Box>
            </Box>
        </Stack>
    ) : null;
};

export default observer(PinnedMessagePanel);
