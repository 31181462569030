const Frame = () => {
    return (
        <svg
            width="16"
            height="18"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3479_76245)">
                <path
                    d="M15.4811 4.67074C15.4719 4.18876 15.4632 3.73345 15.4632 3.29297C15.4632 3.12724 15.3974 2.96831 15.2802 2.85113C15.163 2.73394 15.0041 2.66811 14.8383 2.66811C12.166 2.66811 10.1314 1.90012 8.43546 0.251156C8.31884 0.137699 8.16256 0.0742188 7.99986 0.0742188C7.83715 0.0742187 7.68087 0.137699 7.56425 0.251156C5.86846 1.90012 3.83418 2.66811 1.16203 2.66811C0.996308 2.66811 0.837373 2.73394 0.72019 2.85113C0.603006 2.96831 0.537173 3.12724 0.537173 3.29297C0.537173 3.73353 0.528508 4.18901 0.51926 4.67107C0.433613 9.15639 0.316307 15.2992 7.79519 17.8916C7.92773 17.9375 8.07189 17.9375 8.20443 17.8916C15.6839 15.2992 15.5668 9.15614 15.4811 4.67074ZM7.9999 16.6381C1.58577 14.3081 1.68275 9.20379 1.76881 4.6949C1.77398 4.42429 1.77897 4.16202 1.78247 3.90491C4.28524 3.79927 6.28662 3.04086 7.9999 1.54836C9.71334 3.04086 11.7151 3.79935 14.218 3.90491C14.2215 4.16194 14.2265 4.42404 14.2316 4.69448C14.3176 9.20354 14.4145 14.308 7.9999 16.6381Z"
                    fill="#82D4AE"
                />
                <path
                    d="M7.9999 16.6381C1.58577 14.3081 1.68275 9.20379 1.76881 4.6949C1.77398 4.42429 1.77897 4.16202 1.78247 3.90491C4.28524 3.79927 6.28662 3.04086 7.9999 1.54836C9.71334 3.04086 11.7151 3.79935 14.218 3.90491C14.2215 4.16194 14.2265 4.42404 14.2316 4.69448C14.3176 9.20354 14.4145 14.308 7.9999 16.6381Z"
                    fill="#82D4AE"
                />
                <path
                    d="M10.0805 6.76068L10.0805 6.76067C10.2165 6.62476 10.4008 6.54842 10.5931 6.54844C10.7853 6.54846 10.9696 6.62483 11.1055 6.76075L10.0805 6.76068ZM10.0805 6.76068L7.13531 9.7057M10.0805 6.76068L7.13531 9.7057M7.13531 9.7057L5.91906 8.48945L5.91907 8.48944L5.91785 8.48826C5.78114 8.35611 5.59798 8.28296 5.40784 8.28458C5.21771 8.28619 5.03581 8.36244 4.90137 8.4969C4.76692 8.63136 4.69069 8.81326 4.68909 9.0034C4.68749 9.19353 4.76066 9.37669 4.89282 9.51339L4.89281 9.5134L4.89401 9.51459L6.6227 11.2433L7.13531 9.7057ZM6.85789 11.4006C6.76993 11.3642 6.69003 11.3107 6.62278 11.2434L6.85789 11.4006ZM6.85789 11.4006C6.94588 11.437 7.0402 11.4557 7.13544 11.4556M6.85789 11.4006L7.13544 11.4556M7.13544 11.4556L7.13531 11.3556M7.13544 11.4556L7.13531 11.3556M7.13531 11.3556L7.13518 11.4556C7.23041 11.4557 7.32474 11.437 7.41273 11.4006M7.13531 11.3556C7.05324 11.3557 6.97197 11.3396 6.89615 11.3082L7.41273 11.4006M7.41273 11.4006C7.50069 11.3642 7.58058 11.3107 7.64783 11.2434L7.41273 11.4006ZM7.64792 11.2433L11.1054 7.78584L7.64792 11.2433Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.2"
                />
            </g>
            <defs>
                <clipPath id="clip0_3479_76245">
                    <rect
                        width="16"
                        height="18"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Frame;
