import Dima from "assets/ChatAssets/PNG/Dima.png";
import Konstantin from "assets/ChatAssets/PNG/Konstantin.png";
import Boris from "assets/ChatAssets/PNG/Pavel.png";
import Evgen from "assets/ChatAssets/PNG/Evgen.png";
import Maria from "assets/ChatAssets/PNG/Maria.png";
import Ekater from "assets/ChatAssets/PNG/Ekater.png";
import { openDB } from "idb";

const { format, parseISO } = require("date-fns");

const avatar = {
    Дмитрий: Dima,
    Константин: Konstantin,
    Борис: Boris,
    Егор: Evgen,
    Василий: Maria,
    Антон: Ekater,
};

export function removeMessageById(array, messageId) {
    for (let i = 0; i < array.length; i++) {
        if (array[i].message_id === messageId) {
            array.splice(i, 1);
            break;
        }
    }
}

export function findChatById(arr, id, index) {
    if (id) {
        if (index) {
            return arr.findIndex((item) => Object.prototype.hasOwnProperty.call(item, "chat_id") && item.chat_id === id);
        } else {
            return arr.find((item) => Object.prototype.hasOwnProperty.call(item, "chat_id") && item.chat_id === id);
        }
    } else return null;
}

function convertChatInInfoCard(item, user_id) {
    var last_message = "";

    var is_media = "";

    if (item.messages.length === 0) last_message = "";
    else if (item.messages[0].files !== null) {
        if (item.messages[0].files.length > 0) {
            last_message = "Файл";
            is_media = "1";
        }
    } else if (item.messages[0].medias !== null) {
        if (item.messages[0].medias.length > 0) {
            {
                last_message = item.messages[0].medias[0].content_type.split("/")[0] === "video" ? "Видео" : "Фотография";
                is_media = "1";
            }
        }
    } else last_message = item.messages[0].message;

    return {
        chat_id: item.chat_id,
        last_message: last_message,
        is_media: is_media,
        time_message: item.messages.length > 0 ? format(parseISO(item.messages[0].created_at), "HH:mm") : "",
        pin: item.pin,
        pinned_messages: item.pinned_messages,
        name: item.participants_info[0].name + " " + item.participants_info[0].surname,
        uuid: item.participants_info[0].uuid,
        avatar: "https://study-buddy.ru" + item.participants_info[0].photoLink,
        send_message: item.messages.length > 0 ? user_id === item.messages[0].owner : false,
        read_message: item.messages.length > 0 ? item.messages[0].read : false,
        unread_messages: item.unread_messages,
        disabled_notifications: item.disabled_notifications,
        status: item.participants_info[0].status,
    };
}

export function convertDataInTabsChat(data) {
    if (Object.keys(data).length > 0) {
        return data.folders.map((folder) => ({
            ...folder,
            unread_messages: data.chats.filter((chat) => chat.folders.includes(folder.index)).reduce((total, chat) => total + chat.unread_messages, 0),
            infocards: data.chats.filter((chat) => chat.folders.includes(folder.index)).map((item) => convertChatInInfoCard(item, data.user_id)),
            blockedusers: data.blocked_users ? data.blocked_users : [],
            blockedby: data.blocked_by_users ? data.blocked_by_users : [],
        }));
    } else {
        return [];
    }
}

export const getDateGroups = (messagesList) => {
    // eslint-disable-next-line no-undef
    return Array.from(new Set(messagesList.map((message) => message.formattedDate)));
};

export function reverseMassiv(data, id) {
    if (id) {
        const findId = findChatById(data.chats, id);

        return findId.messages
            .slice()
            .reverse()
            .map((item) => {
                return {
                    ...item,
                    time: format(parseISO(item.created_at), "HH:mm"),
                    avatar: "https://study-buddy.ru" + findId.participants_info[0].photoLink,
                    formattedDate:
                        new Date(item.created_at).toDateString() === new Date().toDateString()
                            ? "Сегодня"
                            : new Date(item.created_at).toDateString() === new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()
                              ? "Вчера"
                              : new Date(item.created_at).toLocaleString("default", { day: "numeric", month: "long" }),
                };
            });
    } else {
        return data.messages
            .slice()
            .reverse()
            .map((item) => {
                return {
                    ...item,
                    time: format(parseISO(item.created_at), "HH:mm"),
                    avatar: "https://study-buddy.ru" + data.participants_info[0].photoLink,
                    formattedDate:
                        new Date(item.created_at).toDateString() === new Date().toDateString()
                            ? "Сегодня"
                            : new Date(item.created_at).toDateString() === new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()
                              ? "Вчера"
                              : new Date(item.created_at).toLocaleString("default", { day: "numeric", month: "long" }),
                };
            });
    }
}

const dbPromise = openDB("keyval-store", 1, {
    upgrade(db) {
        db.createObjectStore("keyval");
    },
});

export async function get(key) {
    return (await dbPromise).get("keyval", key);
}

export async function set(key, val) {
    return (await dbPromise).put("keyval", val, key);
}

export async function del(key) {
    return (await dbPromise).delete("keyval", key);
}

export async function clear() {
    return (await dbPromise).clear("keyval");
}

export async function keys() {
    return (await dbPromise).getAllKeys("keyval");
}
