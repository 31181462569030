import { createTheme } from "@mui/material/styles";

export const footerAreaStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
};

export const avatarContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "6px",
};

export const AvatarTheme = createTheme({
    components: {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    position: "relative",
                    width: "24px",
                    height: "24px",
                    left: "0px",
                    top: "0px",
                    cursor: "default",
                },
            },
        },
    },
});

export const studentTextStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const lastSeenTextStyle = {
    color: "var(--text-grey, #9E9EB0)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};
