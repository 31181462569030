import React from "react";
import * as themes from "./OrderResponseCircleAvatarStyled";

const OrderResponseCircleAvatar = ({ name, avatar }) => {
    return (
        <>
            <themes.OrderResponseCircleAvatarStyled
                alt={name}
                src={avatar}
            />
        </>
    );
};

export default OrderResponseCircleAvatar;
