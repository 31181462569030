import React, { memo } from "react";
import MyClassesStore from "store/MyClassesStore";
import { Tooltip, Box, Typography } from "@mui/material";
import * as styles from "./StartClassButtonThemes";
import * as textStyles from "themes/TextThemes";
import SButton from "components/UI/SButton";
import { useTranslation } from "react-i18next";
import { ClassPlaceIcon } from "assets/MyClassesAssets/SVG/ClassPlaceIcon";
import { observer } from "mobx-react-lite";

const StartClassButton = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            {MyClassesStore.classes[MyClassesStore.findNearestTodayClassIndex()].place !== "online" ? (
                <Box sx={styles.TutorStudentHome}>
                    <ClassPlaceIcon />
                    <Typography sx={textStyles.Button14Black}>
                        {MyClassesStore.classes[MyClassesStore.findNearestTodayClassIndex()].place === "tutor" ? t("myClasses.tutorHome") : t("myClasses.studentHome")}
                    </Typography>
                </Box>
            ) : MyClassesStore.startClassButtonDisabled() ? (
                <Tooltip
                    title={t("main.myClasses.buttonWillBeAvailable")}
                    PopperProps={styles.TooltipPopperProps}
                    placement="top"
                >
                    <span>
                        <SButton
                            variant="green"
                            disabled={true}
                            style={styles.ButtonStyle}
                        >
                            {t("main.myClasses.startClass")}
                        </SButton>
                    </span>
                </Tooltip>
            ) : (
                <SButton
                    variant="green"
                    disabled={false}
                    style={styles.ButtonStyle}
                    onClick={() => window.open("/video-chat", "_blank")}
                >
                    {t("main.myClasses.startClass")}
                </SButton>
            )}
        </>
    );
});

export default memo(StartClassButton);
