const SBPBig = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="84"
            height="48"
            viewBox="0 0 84 48"
            fill="none"
        >
            <g clipPath="url(#clip0_1947_59158)">
                <path
                    d="M82.6656 18.6102V30.3454H78.4752V22.1134H74.4404V30.3454H70.25V18.6094H82.6656V18.6102Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M61.6439 30.7652C65.3951 30.7652 68.1807 28.4652 68.1807 24.9784C68.1807 21.6036 66.1255 19.4124 62.6907 19.4124C61.1055 19.4124 59.7975 19.9704 58.8123 20.9332C59.0475 18.9432 60.7303 17.4904 62.5831 17.4904C63.0107 17.4904 66.2299 17.4836 66.2299 17.4836L68.0503 14C68.0503 14 64.0087 14.092 62.1299 14.092C57.8371 14.1668 54.9375 18.0688 54.9375 22.808C54.9375 28.3292 57.7655 30.7652 61.6439 30.7652ZM61.6667 22.498C63.0595 22.498 64.0251 23.4132 64.0251 24.978C64.0251 26.3864 63.1671 27.5468 61.6667 27.55C60.2315 27.55 59.2659 26.4748 59.2659 25.002C59.2659 23.4368 60.2315 22.498 61.6667 22.498Z"
                    fill="black"
                />
                <path
                    d="M51.5282 26.4193C51.5282 26.4193 50.5386 26.9897 49.0606 27.0977C47.3614 27.1481 45.8474 26.0749 45.8474 24.1681C45.8474 22.3081 47.1834 21.2421 49.0178 21.2421C50.1426 21.2421 51.6306 22.0217 51.6306 22.0217C51.6306 22.0217 52.7194 20.0237 53.2834 19.0245C52.2506 18.2417 50.875 17.8125 49.275 17.8125C45.237 17.8125 42.1094 20.4453 42.1094 24.1445C42.1094 27.8909 45.049 30.4625 49.275 30.3849C50.4562 30.3409 52.0858 29.9261 53.079 29.2881L51.5282 26.4193Z"
                    fill="black"
                />
                <path
                    d="M0 10.4531L5.8128 20.8431V27.1807L0.0068 37.5503L0 10.4531Z"
                    fill="#5B57A2"
                />
                <path
                    d="M22.3203 17.0519L27.7671 13.7135L38.9143 13.7031L22.3203 23.8687V17.0519Z"
                    fill="#D90751"
                />
                <path
                    d="M22.2878 10.3868L22.3186 24.1428L16.4922 20.5628V0L22.2878 10.3868Z"
                    fill="#FAB718"
                />
                <path
                    d="M38.9126 13.7084L27.765 13.7188L22.2878 10.3868L16.4922 0L38.9126 13.7084Z"
                    fill="#ED6F26"
                />
                <path
                    d="M22.3186 37.6079V30.9339L16.4922 27.4219L16.4954 48.0051L22.3186 37.6079Z"
                    fill="#63B22F"
                />
                <path
                    d="M27.752 34.2999L5.8124 20.8431L0 10.4531L38.8892 34.2863L27.752 34.2999Z"
                    fill="#1487C9"
                />
                <path
                    d="M16.4922 48L22.3146 37.6029L27.7478 34.2948L38.885 34.2812L16.4922 48Z"
                    fill="#017F36"
                />
                <path
                    d="M0.0078125 37.5384L16.541 27.4104L10.9826 24L5.81381 27.1688L0.0078125 37.5384Z"
                    fill="#984995"
                />
            </g>
            <defs>
                <clipPath id="clip0_1947_59158">
                    <rect
                        width="84"
                        height="48"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SBPBig;
