import { Typography, styled } from "@mui/material";

export const StyledTutorsInfoTitle = styled(Typography)({
    marginBottom: "4px",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "rgba(158, 158, 176, 1)",
});

export const StyledTutorsInfoContainedText = styled(Typography)({
    height: "27px",
    alignItems: "center",
    marginLeft: "4px",
    background: "rgba(247, 243, 255, 1)",
    borderRadius: "16px",
    padding: "4px 10px",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0em",
    textAlign: "center",
    color: "rgba(34, 34, 34, 1)",
});

export const StyledTutorsInfoText = styled(Typography)({
    marginLeft: "4px",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "rgba(34, 34, 34, 1)",
});

export const OrderResponseTutorsInfoBoxWrapper = { marginTop: "12px" };

export const OrderResponseTutorsInfoAddresText = {
    color: "rgba(103, 103, 122, 1)",
};

export const OrderResponseTutorsInfoEducationWrapper = {
    display: "flex",
    gap: "10px",
};
