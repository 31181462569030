export const Popper = {
    zIndex: "900",
};

export const Paper = {
    marginLeft: "35vw",
    marginTop: "-20px",
    backgroundColor: "white",
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
    p: "12px 16px",
};

export const MenuList = {
    padding: "12",
};

export const UpperMenu = {
    borderBottom: "1px solid #EEEEF0",
};

export const MenuItem2 = {
    borderRadius: "8px",
    fontSize: "2vh",
    ":hover": {
        color: "#6212FF",
    },
};
