import React from "react";
import * as themes from "./NumThreeThemes";
import CircleSample from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumbersCommonComponents/CircleSample/CircleSample";
import { imageCircleSources } from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumbersCommonComponents/NumbersAssetsIndex";
import { Box } from "@mui/material";
import NumberPositionBox from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumbersCommonComponents/NumberPositionBox/NumberPositionBox";
import { getRandomImageSource } from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumbersCommonComponents/imageUtils";
import { memo } from "react";

const NumThree = ({ children, ...props }) => {
    const positions = [
        { left: 93, top: 0 },
        { left: 167, top: 173 },
        { left: 11, top: 254 },
        { left: 11, top: 0 },
        { left: 175, top: 0 },
        { left: 137, top: 82 },
        { left: 81, top: 154 },
        { left: 194, top: 248 },
        { left: 141, top: 302 },
        { left: 71, top: 302 },
    ];

    return (
        <Box sx={themes.BlockStyle}>
            {positions.map((position, index) => (
                <NumberPositionBox
                    key={index}
                    position={position}
                >
                    {index < 3 ? (
                        <CircleSample />
                    ) : (
                        <img
                            src={getRandomImageSource(imageCircleSources)}
                            alt="Random Icon"
                        />
                    )}
                </NumberPositionBox>
            ))}
        </Box>
    );
};

export default memo(NumThree);
