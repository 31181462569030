import { makeAutoObservable } from "mobx";
import { AuthServiceClient } from "API/services/grpc/proto/AuthorizationService/auth_grpc_web_pb";
import { LoginEmail, LoginSms, LoginSmsVerify, RefreshTokens, SignUpSmsVerify, Logout, Register, RecoverPassword } from "API/services/grpc/AuthorizationService";
import UserStore from "./UserStore";
import { getCookie, setCookie } from "API/utils";
import * as AccountManagerService from "../API/services/grpc/AccountManagerService";
import { handleStoreActionError } from "../API/plugins/errorHandlers";

class AuthorizationStore {
    AuthServiceClient = new AuthServiceClient("https://study-buddy.ru:8080", null, null);

    refreshInterval = 60 * 60 * 1000;

    authentificationData = {
        email: "test@study-buddy.ru",
        password: "testStudyBuddy1",
        phone: "79991631637",
        sms: "39952",
    };

    signUpRole = "student"; //'tutor'
    previousLocationPath = null;
    currentAuthorizationStep = "SignIn"; //'SignUpStudent' 'SignUpTutor' 'ForgotPassword' 'EnterSMS'

    signInTab = 0;
    // previousActiveTab = 0

    showPassword = false;

    typingError = false;
    temporaryPassword = "";
    temporaryEmail = "";
    temporaryPhoneNumber = null;
    temporaryPhoneNumberHelper = false;

    temporarySMS = "";
    smsError = false;

    minutes = 0;
    seconds = 0;

    openWelcomeBackSnackbar = false;

    resetEmail = "";
    resetEmailError = false;

    signUpName = "";
    signUpEmail = "";
    signUpEmailError = false;
    signUpEmailErrorText = "";
    signUpPhone = null;
    signUpPhoneError = false;
    showSendInstructions = false;

    studentRulesAgree = false;
    tutorOfferAgree = false;
    tutorRulesAgree = false;
    preSMSPage = null;

    mode = "login";

    hasTutorQuestionnaire = false;

    setShowSendInstructions(value) {
        this.showSendInstructions = value;
    }

    setMode(value) {
        this.mode = value;
    }

    setSignUpPhoneError(value) {
        this.signUpPhoneError = value;
    }

    setSignUpPhone(value) {
        this.signUpPhone = value;
    }

    setPreSMSPage(value) {
        this.preSMSPage = value;
    }

    setStudentRulesAgree(value) {
        this.studentRulesAgree = value;
    }

    setTutorOfferAgree(value) {
        this.tutorOfferAgree = value;
    }

    setTutorRulesAgree(value) {
        this.tutorRulesAgree = value;
    }

    setSignUpName(value) {
        this.signUpName = value;
    }

    setSignUpEmail(value) {
        this.signUpEmail = value;
    }

    setSignUpEmailError(value) {
        this.signUpEmailError = value;
    }

    setSignUpEmailErrorText(value) {
        this.signUpEmailErrorText = value;
    }

    // setPreviousActiveTab(value) {
    //     this.previousActiveTab = value
    // }

    setResetEmailError(value) {
        this.resetEmailError = value;
    }

    setResetEmail(value) {
        this.resetEmail = value;
    }

    setOpenWelcomeBackSnackbar(value) {
        this.openWelcomeBackSnackbar = value;
    }

    setMinutes(value) {
        this.minutes = value;
    }

    setSeconds(value) {
        this.seconds = value;
    }

    setSMSError(value) {
        this.smsError = value;
    }

    setTemporarySMS(value) {
        this.temporarySMS = value;
    }

    setTemporaryPhoneNumberHelper(value) {
        this.temporaryPhoneNumberHelper = value;
    }

    setTypingError(value) {
        this.typingError = value;
    }

    setTemporaryEmail(value) {
        this.temporaryEmail = value;
    }

    setTemporaryPassword(value) {
        this.temporaryPassword = value;
    }

    setShowPassword() {
        this.showPassword = !this.showPassword;
    }

    setSignInTab(value) {
        this.signInTab = value;
    }

    setCurrentAuthorizationStep(value) {
        this.currentAuthorizationStep = value;
    }

    setTemporaryPhoneNumber(value) {
        this.temporaryPhoneNumber = value;
    }

    setSignUpRole(value) {
        this.signUpRole = value;
    }

    setPreviousLocationPath(value) {
        this.previousLocationPath = value;
    }

    async RegisterNewUser() {
        await Register(`+${this.signUpPhone}`, this.signUpEmail, this.signUpName, this.signUpRole, (err, response) => {
            if (err) {
                this.setSignUpEmailError(true);
                if (err.code === 3) {
                    this.setSignUpEmailErrorText("Имя, почта или телефон содержат недопустимые символы");
                } else if (err.code === 6) {
                    this.setSignUpEmailErrorText("Аккаунт с такой почтой или телефоном уже зарегистрирован");
                }
            } else {
                if (this.signUpRole === "tutor") this.setPreSMSPage("SignUpTutor");
                else this.setPreSMSPage("SignUpStudent");

                this.setCurrentAuthorizationStep("EnterSMS");
                this.setTemporarySMS("");
                this.setSeconds(59);
            }
        });
    }

    async handleCheckEmailAndPassword(navigate) {
        await LoginEmail(this.temporaryEmail, this.temporaryPassword, (err, response) => {
            if (err) {
                this.setTypingError(true);
            } else if (response) {
                setCookie("tutorAccessToken", response?.getTokens()?.getAccessTokens()?.getTutorAccessToken());
                setCookie("studentAccessToken", response?.getTokens()?.getAccessTokens()?.getStudentAccessToken());
                setCookie("refreshToken", response?.getTokens()?.getRefreshToken());

                let tokenObject = {
                    tutorAccessToken: response?.getTokens()?.getAccessTokens()?.getTutorAccessToken(),
                    studentAccessToken: response?.getTokens()?.getAccessTokens()?.getStudentAccessToken(),
                    refreshToken: response?.getTokens()?.getRefreshToken(),
                };

                UserStore.SetTokens(tokenObject);

                localStorage.setItem("uuid", response?.getUserUuid());
                if (response?.getTokens()?.getAccessTokens()?.getTutorAccessToken()) {
                    UserStore.setRole("tutor");
                    UserStore.setAuth(true);
                    setCookie("role", "tutor");
                } else if (response?.getTokens()?.getAccessTokens()?.getStudentAccessToken()) {
                    UserStore.setRole("student");
                    UserStore.setAuth(true);
                    setCookie("role", "student");
                }

                this.setOpenWelcomeBackSnackbar(true);
                if (this.previousLocationPath) navigate(this.previousLocationPath);
                else navigate("/");
            }
        });
    }

    async handleCheckPhoneNumber() {
        await LoginSms(`+${this.temporaryPhoneNumber}`, (err, response) => {
            if (err) {
                this.setTemporaryPhoneNumberHelper(true);
            } else {
                this.setPreSMSPage("SignIn");
                this.setCurrentAuthorizationStep("EnterSMS");
                this.setTemporarySMS("");
                this.setSeconds(59);
            }
        });
    }

    async handleLogout() {
        await Logout(getCookie("refreshToken"), (response, err) => {
            console.log("logout", response, err);
        });
    }

    changeHasTutorQuestionnaire(value) {
        this.hasTutorQuestionnaire = value;
    }

    async GetTutorQuestionnaire() {
        let data = null;

        let metaData = {
            authorization: "Bearer " + getCookie("tutorAccessToken"),
        };

        await AccountManagerService.GetTutorQuestionary(data, metaData, (error, response) => {
            if (error) {
                if (error.code === 5) {
                    this.changeHasTutorQuestionnaire(false);
                } else {
                    handleStoreActionError({
                        error,
                        customErrorText: "account.errors.getTutorQuestionnaireError",
                        dispatch: true,
                    });
                }
            } else {
                // let data = response.toObject();
                this.changeHasTutorQuestionnaire(true);
            }
        });
    }

    async verifySignInSmsCode(navigate) {
        await LoginSmsVerify(`+${this.temporaryPhoneNumber}`, this.temporarySMS, (err, response) => {
            if (err) {
                this.setTemporaryPhoneNumberHelper(true);
            } else {
                setCookie("refreshToken", response?.getTokens()?.getRefreshToken());
                setCookie("tutorAccessToken", response?.getTokens()?.getAccessTokens()?.getTutorAccessToken());
                setCookie("studentAccessToken", response?.getTokens()?.getAccessTokens()?.getStudentAccessToken());

                let tokenObject = {
                    tutorAccessToken: response?.getTokens()?.getAccessTokens()?.getTutorAccessToken(),
                    studentAccessToken: response?.getTokens()?.getAccessTokens()?.getStudentAccessToken(),
                    refreshToken: response?.getTokens()?.getRefreshToken(),
                };

                UserStore.SetTokens(tokenObject);

                if (response?.getTokens()?.getAccessTokens()?.getTutorAccessToken()) {
                    UserStore.setRole("tutor");
                    UserStore.setAuth(true);

                    setCookie("role", "tutor");
                } else if (response?.getTokens()?.getAccessTokens()?.getStudentAccessToken()) {
                    UserStore.setRole("student");
                    UserStore.setAuth(true);
                    setCookie("role", "student");
                }

                navigate(this.previousLocationPath);
                this.setOpenWelcomeBackSnackbar(true);
            }
        });
    }

    formatPhoneNumber(number) {
        // Проверяем, является ли входной номер строкой
        if (typeof number !== "string") {
            throw new Error("Phone number must be a string");
        }

        // Удаляем все символы, кроме цифр
        const cleaned = ("" + number).replace(/\D/g, "");

        // Паттерны для разных стран
        const patterns = {
            // Пример паттерна для России (+7 XXX XXX-XX-XX)
            RU: /^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/,
            // Добавьте другие паттерны для других стран по необходимости
            // Например, для США: US: /^(\d{3})(\d{3})(\d{4})$/
        };

        // Определяем код страны на основе длины номера (первые несколько цифр)
        let countryCode;

        if (cleaned.length === 11 && cleaned.slice(0, 1) === "7") {
            countryCode = "RU"; // Пример: Россия
        } else {
            // Добавьте обработку других кодов стран по необходимости
            // Например, для США: countryCode = 'US';
        }

        console.log(countryCode);
        // Применяем соответствующий паттерн для выбранного кода страны
        const match = cleaned.match(patterns[countryCode]);

        if (match) {
            // Форматируем номер
            return `+${match[1]} ${match[2]} ${match[3]}–${match[4]}–${match[5]}`;
        } else {
            // Если номер имеет неправильный формат, просто возвращаем его как есть
            return number;
        }
    }

    // API

    async sendSmsVerifyRegister(navigate) {
        await SignUpSmsVerify(`+${this.signUpPhone ? this.signUpPhone : this.temporaryPhoneNumber}`, this.signUpRole, this.temporarySMS, (err, response) => {
            if (err) {
                this.setSMSError(true);
            } else {
                setCookie("refreshToken", response?.getTokens()?.getRefreshToken());
                setCookie("tutorAccessToken", response?.getTokens()?.getAccessTokens()?.getTutorAccessToken());
                setCookie("studentAccessToken", response?.getTokens()?.getAccessTokens()?.getStudentAccessToken());

                let tokenObject = {
                    tutorAccessToken: response?.getTokens()?.getAccessTokens()?.getTutorAccessToken(),
                    studentAccessToken: response?.getTokens()?.getAccessTokens()?.getStudentAccessToken(),
                    refreshToken: response?.getTokens()?.getRefreshToken(),
                };

                UserStore.SetTokens(tokenObject);

                if (this.preSMSPage === "SignUpTutor") {
                    UserStore.setAuth(true);
                    UserStore.setRole("tutor");
                    navigate("/questionnaire");
                    setCookie("role", "tutor");
                } else if (this.preSMSPage === "SignUpStudent" || this.preSMSPage === "SignIn") {
                    UserStore.setRole("student");
                    UserStore.setAuth(true);
                    navigate("/account");
                    setCookie("role", "student");
                } else {
                    if (response?.getTokens()?.getAccessTokens()?.getTutorAccessToken()) {
                        UserStore.setRole("tutor");
                        UserStore.setAuth(true);
                        setCookie("role", "tutor");
                    } else if (response?.getTokens()?.getAccessTokens()?.getStudentAccessToken()) {
                        UserStore.setRole("student");
                        UserStore.setAuth(true);
                        setCookie("role", "student");
                    }

                    navigate(this.previousLocationPath);
                    this.setOpenWelcomeBackSnackbar(true);
                }
            }
        });
    }

    async handleRecoverPassword(navigate) {
        await RecoverPassword(this.resetEmail, (err, response) => {
            if (err) {
                this.setResetEmailError(true);
            } else {
                navigate(this.previousLocationPath ? this.previousLocationPath : "/");
                this.setShowSendInstructions(true);
            }
        });
    }

    async loadRefreshTokens() {
        await RefreshTokens(getCookie("refreshToken"), (err, response) => {
            if (err) {
                UserStore.setAuth(false);
                return;
            }

            setCookie("refreshToken", response?.getTokens()?.getRefreshToken());
            setCookie("tutorAccessToken", response?.getTokens()?.getAccessTokens()?.getTutorAccessToken());
            setCookie("studentAccessToken", response?.getTokens()?.getAccessTokens()?.getStudentAccessToken());

            let tokenObject = {
                tutorAccessToken: response?.getTokens()?.getAccessTokens()?.getTutorAccessToken(),
                studentAccessToken: response?.getTokens()?.getAccessTokens()?.getStudentAccessToken(),
                refreshToken: response?.getTokens()?.getRefreshToken(),
            };

            UserStore.SetTokens(tokenObject);

            if (response?.getTokens()?.getAccessTokens()?.getTutorAccessToken()) {
                if (getCookie("role") === "tutor") {
                    UserStore.setRole("tutor");
                    UserStore.setAuth(true);
                    setCookie("role", "tutor");
                } else if (response?.getTokens()?.getAccessTokens()?.getStudentAccessToken()) {
                    UserStore.setRole("student");
                    UserStore.setAuth(true);
                    setCookie("role", "student");
                } else {
                    UserStore.setRole("tutor");
                    UserStore.setAuth(true);
                    setCookie("role", "tutor");
                }
            } else if (response?.getTokens()?.getAccessTokens()?.getStudentAccessToken()) {
                UserStore.setRole("student");
                UserStore.setAuth(true);
                setCookie("role", "student");
            }
        });
    }

    debounce(callback, delay) {
        return (...args) => {
            if (this.timerID) {
                clearTimeout(this.timerID);
            }

            this.timerID = setTimeout(() => {
                callback.call(this, ...args);
            }, delay);
        };
    }

    Init() {
        this.Load();
    }

    Load() {}

    constructor() {
        this.Init();
        makeAutoObservable(this);
    }
}

export default new AuthorizationStore();
