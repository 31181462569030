import React, { useState } from "react";

import { Box, Stack } from "@mui/material";
import { MoreHorizOutlined } from "@mui/icons-material";

import PopperMenu from "components/UI/PopperMenu";
import SButton from "components/UI/SButton";

const Actions = ({ hasDownload = true, downloadFile, info }) => {
    const [showMenu, setShowMenu] = useState(false);

    const menuItems = [{ text: "Перейти к сообщению", onClick: null }].concat(
        hasDownload
            ? {
                  text: "Скачать",
                  onClick: () => {
                      downloadFile(info.link, `${info.message_id}_${info.orig_name}`);
                      setShowMenu(false);
                  },
              }
            : [],
    );

    return (
        <PopperMenu
            content={
                <Stack direction="column">
                    {menuItems.map((item) => (
                        <SButton
                            key={item.text}
                            onClick={item.onClick}
                            variant="text"
                        >
                            {item.text}
                        </SButton>
                    ))}
                </Stack>
            }
            open={showMenu}
            setOpen={setShowMenu}
        >
            <SButton variant="icon">
                <MoreHorizOutlined />
            </SButton>
        </PopperMenu>
    );
};

export default Actions;
