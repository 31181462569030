export const CompainModalWrapper = {
    maxWidth: "400px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginBottom: "24px",
};

export const CompainModalButtonWrapper = {
    marginTop: "34px",
    maxWidth: "172px",
    marginBottom: "15px",
};
