import React from "react";
import { Backdrop } from "@mui/material";
import * as themes from "./OrderCardWrapperStyled";
const OrderCardWrapper = ({ children, status }) => {
    return (
        <>
            <themes.StyledOrderCardWrapper>
                {children}
                <Backdrop
                    sx={themes.OrderCardWrapperBackdrop}
                    open={status === "rejected" ? true : false}
                />
            </themes.StyledOrderCardWrapper>
        </>
    );
};

export default OrderCardWrapper;
