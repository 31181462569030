export const RequestNullAlert = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "150%",
    color: "#E12626",
    marginTop: "16px",
    whiteSpace: "nowrap",
};
