export const QuestionnaireHeader = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px 17px",
    borderBottom: "1px solid #BBBBC8",
};

export const EmptyPhoto = {
    borderRadius: "10px",
    background: "#EEEEF0",
    padding: "8px",
};

export const HeaderAccountContent = (medias) => {
    return {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: medias.sm ? "1216px" : "100%",
    };
};

export const QuestionnaireRightPanel = {
    display: "flex",
    alignItems: "center",
    gap: "40px",
};
