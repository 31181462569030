const TimeIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
    >
        <path
            d="M7.99857 2.86719C6.98329 2.86719 5.99081 3.16825 5.14664 3.73231C4.30247 4.29637 3.64452 5.09808 3.25599 6.03608C2.86746 6.97407 2.7658 8.00621 2.96387 9.00198C3.16194 9.99775 3.65085 10.9124 4.36875 11.6303C5.08666 12.3482 6.00134 12.8371 6.99711 13.0352C7.99287 13.2333 9.02502 13.1316 9.96301 12.7431C10.901 12.3546 11.7027 11.6966 12.2668 10.8524C12.8308 10.0083 13.1319 9.0158 13.1319 8.00052C13.1304 6.63953 12.5891 5.33471 11.6267 4.37235C10.6644 3.40998 9.35956 2.86867 7.99857 2.86719ZM10.3355 9.7703C10.29 9.82795 10.232 9.87459 10.166 9.90675C10.1 9.93892 10.0276 9.95578 9.95412 9.95608C9.84357 9.95696 9.73624 9.91888 9.65096 9.84852L7.6954 8.38185C7.63781 8.3363 7.59122 8.27837 7.55907 8.21236C7.52692 8.14635 7.51004 8.07394 7.50968 8.00052V4.5783C7.50968 4.44864 7.56119 4.32429 7.65287 4.2326C7.74456 4.14092 7.86891 4.08941 7.99857 4.08941C8.12823 4.08941 8.25258 4.14092 8.34427 4.2326C8.43595 4.32429 8.48746 4.44864 8.48746 4.5783V7.76585L10.2573 9.08585C10.3082 9.12507 10.3507 9.17411 10.3823 9.23005C10.4138 9.286 10.4339 9.34771 10.4412 9.41154C10.4484 9.47537 10.4428 9.54001 10.4247 9.60164C10.4065 9.66326 10.3762 9.72062 10.3355 9.7703Z"
            fill="#9363F1"
        />
        <path
            d="M7.99935 0.667969C6.54895 0.667969 5.13113 1.09806 3.92517 1.90386C2.71921 2.70965 1.77928 3.85496 1.22424 5.19496C0.669193 6.53495 0.523969 8.00944 0.806927 9.43196C1.08989 10.8545 1.78832 12.1612 2.8139 13.1867C3.83949 14.2123 5.14616 14.9108 6.56869 15.1937C7.99122 15.4767 9.4657 15.3315 10.8057 14.7764C12.1457 14.2214 13.291 13.2814 14.0968 12.0755C14.9026 10.8695 15.3327 9.4517 15.3327 8.0013C15.3303 6.05711 14.5569 4.19322 13.1822 2.81846C11.8074 1.44371 9.94355 0.670334 7.99935 0.667969ZM7.99935 14.145C6.78424 14.145 5.59642 13.7847 4.5861 13.1096C3.57577 12.4345 2.78832 11.475 2.32332 10.3524C1.85832 9.22978 1.73665 7.99449 1.97371 6.80273C2.21076 5.61097 2.79589 4.51626 3.6551 3.65705C4.51431 2.79784 5.60902 2.21271 6.80078 1.97566C7.99254 1.7386 9.22783 1.86027 10.3504 2.32527C11.4731 2.79027 12.4326 3.57772 13.1076 4.58805C13.7827 5.59837 14.143 6.78619 14.143 8.0013C14.1415 9.63023 13.4937 11.192 12.3419 12.3438C11.19 13.4956 9.62828 14.1434 7.99935 14.145Z"
            fill="#9363F1"
        />
    </svg>
);

export default TimeIcon;
