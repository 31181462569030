import { observer } from "mobx-react-lite";
import React, { memo } from "react";
import * as themes from "./TutorSettingsNavigationTabsThemes";
import { Chip } from "@mui/material";
import TeacherSettingsStore from "store/TeacherSettingsStore";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";

const TutorSettingsNavigationTabs = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.TabsGroup(medias)}>
            <Chip
                sx={themes.customChipStyle}
                disableRipple
                style={TeacherSettingsStore.selectedTab === 1 ? themes.ChipSelect : themes.Chip}
                label={t("teacherSettings.questionnaire.questionnaire")}
                variant="outlined"
                onClick={() => {
                    TeacherSettingsStore.setSelectedTab(1);
                }}
            />
            <Chip
                sx={themes.customChipStyle}
                disableRipple
                style={TeacherSettingsStore.selectedTab === 2 ? themes.ChipSelect : themes.Chip}
                label={t("teacherSettings.security.security")}
                variant="outlined"
                onClick={() => {
                    TeacherSettingsStore.setSelectedTab(2);
                }}
            />
            <Chip
                sx={themes.customChipStyle}
                disableRipple
                style={TeacherSettingsStore.selectedTab === 3 ? themes.ChipSelect : themes.Chip}
                label={t("teacherSettings.settings.settings")}
                variant="outlined"
                onClick={() => {
                    TeacherSettingsStore.setSelectedTab(3);
                }}
            />
        </Box>
    );
});

export default memo(TutorSettingsNavigationTabs);
