import React, { memo, useState } from "react";
import ChatTabsSearch from "./ChatTabsSearch/ChatTabsSearch";
import { observer } from "mobx-react-lite";
import TabsList from "./TabsList/TabsList";
import Box from "@mui/material/Box";
import * as themes from "./ChatListBlockThemes";

const ChatListBlock = observer(({ children, ...props }) => {
    const [name, setName] = useState("");

    return (
        <Box sx={themes.ChatListBlock}>
            <ChatTabsSearch
                name={name}
                setName={setName}
                setFolderChats={props.setFolderChats}
            />
            <TabsList name={name} />
        </Box>
    );
});

export default memo(ChatListBlock);
