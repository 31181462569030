export const TeacherField = {
    borderRadius: "2.45vh 0px 0px 0px",
    borderBottom: "1px solid #BBBBC8",
    backgroundColor: "#F7F7F7",
    paddingLeft: "0.66vw",
    paddingTop: "1.23vh",
    paddingBottom: "1vh",
};

export const IconBack = {
    height: "3.68vh",
    width: "3.68vh",
    padding: "0px",
    marginRight: "0px",
    marginTop: "0.62vh",
};

export const PaperStyles = {
    width: "17vw",
    mt: "2.5vh",
    backgroundColor: "white",
    borderRadius: "1.2vh",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
    border: "none",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
    p: 0,
};

export const MenuItem = {
    paddingLeft: "10px",
    borderRadius: "1vh",
    height: "4.12vh",
    fontSize: "1.7vh",
    ":hover": {
        color: "black",
    },
};

export const MenuItem2 = {
    borderRadius: "1vh",
    height: "4.12vh",
    fontSize: "1.7vh",
    ":hover": {
        color: "#6212FF",
    },
};

export const BoxMenu = {
    borderBottom: "1px solid #EEEEF0",
    paddingTop: "5px",
    paddingBottom: "5px",
};
