import * as themes from "./ProfileLinksThemes";
import * as textThemes from "themes/TextThemes";
import React, { memo } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import PurplePrivilegeIcon from "assets/CommonAssets/SVG/PurplePrivelegeIcon";
import Information from "assets/PAAssets/SVG/information";
import STextField from "components/UI/STextField";
import STooltip from "components/TeacherQuestionnaireBodyBlock/Components/STooltip";
import ProfileLinksPlusIcon from "assets/TeacherQuestionnaireAssets/ProfileLinksPlusIcon";
import store from "store/TeacherQuestionnaireStore";
import DeleteLinkIcon from "assets/TeacherQuestionnaireAssets/DeleteLinkIcon";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";

const ProfileLinks = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const [siteName, setSiteName] = React.useState("");

    const [link, setLink] = React.useState("");

    const handleClickPlus = () => {
        store.teacher.links.push({ siteName: siteName, link: link });
        setSiteName("");
        setLink("");
    };

    const handleClickDeleteLink = (index) => {
        store.teacher.links.splice(index, 1);
    };

    return (
        <Box>
            <Box sx={themes.TeacherQuestionnaireTopBlock}>
                <Typography sx={textThemes.HeadlineH41Black}>{t("TeacherQuestionnaire.profileLinks")}</Typography>
                <Box sx={themes.TeacherQuestionnaireSubTitle}>
                    <Typography sx={textThemes.Body2DarkGrey}>
                        {t("TeacherQuestionnaire.ifYouHaveTutorProfilePart1")}
                        <Typography
                            component="span"
                            sx={textThemes.Body2Purple}
                        >
                            {t("TeacherQuestionnaire.platinumLevel")}
                        </Typography>{" "}
                        <Typography component="span">
                            <Tooltip
                                PopperProps={themes.TooltipPopperProps}
                                title={
                                    <Box>
                                        {TeacherQuestionnaireStore.getTutorPrivilegeLevel(1200).privileges.map((privilege, index) => {
                                            return (
                                                <Box
                                                    sx={themes.PrivilegeBlock}
                                                    key={index}
                                                >
                                                    <Box sx={themes.IconBlock}>
                                                        <PurplePrivilegeIcon />
                                                    </Box>
                                                    <Typography sx={textThemes.Body2Black}>{t(privilege)}</Typography>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                }
                            >
                                <IconButton
                                    disableRipple
                                    style={themes.TooltipButton}
                                >
                                    <Information />
                                </IconButton>
                            </Tooltip>
                        </Typography>{" "}
                        {t("TeacherQuestionnaire.ifYouHaveTutorProfilePart2")}
                    </Typography>
                </Box>
            </Box>
            <Box sx={themes.TeacherQuestionnaireProfileLinksOuterContainer(medias)}>
                <Box sx={themes.TeacherQuestionnaireProfileLinksInnerContainer}>
                    <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.websiteName")}</Typography>
                    <STextField
                        width={medias.sm ? "200px" : "100%"}
                        placeholder={t("TeacherQuestionnaire.enterWebsiteName")}
                        value={siteName}
                        changeFunction={setSiteName}
                    />
                </Box>
                <Box sx={themes.TeacherQuestionnaireProfileLinksInnerContainer}>
                    <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.linkToProfile")}</Typography>
                    <Box sx={{ display: "flex", gap: "16px" }}>
                        <STextField
                            width={medias.sm ? "334px" : "100%"}
                            placeholder={t("TeacherQuestionnaire.insertProfileLink")}
                            value={link}
                            changeFunction={setLink}
                        />
                        <STooltip
                            title={t("TeacherQuestionnaire.add")}
                            width={"fit-content"}
                        >
                            <IconButton
                                disableRipple
                                sx={themes.STooltipButton}
                                disabled={siteName === "" || link === ""}
                                onClick={handleClickPlus}
                            >
                                <ProfileLinksPlusIcon />
                            </IconButton>
                        </STooltip>
                    </Box>
                </Box>
            </Box>
            <Box sx={themes.TeacherQuestionnaireProfileLinksOuterBox(store.teacher.links.length > 0)}>
                {store.teacher.links.map((linkElement, index) => {
                    return (
                        <Box
                            key={index}
                            sx={themes.TeacherQuestionnaireProfileLinksInnerBox}
                        >
                            <Box sx={themes.TeacherQuestionnaireProfileLinksSiteNameBox}>
                                <IconButton
                                    disableRipple
                                    sx={themes.IconDelete}
                                    onClick={() => handleClickDeleteLink(index)}
                                >
                                    <DeleteLinkIcon />
                                </IconButton>
                                <Box sx={themes.TeacherQuestionnaireProfileLinksSiteName}>{linkElement.siteName}</Box>
                            </Box>
                            <Box sx={themes.TeacherQuestionnaireProfileLinksSiteName}>
                                <Typography sx={themes.LinkText(medias)}>{linkElement.link}</Typography>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
});

export default memo(ProfileLinks);
