import { styleProcessing } from "../../../../API/plugins/Utilities";

export const filtersBlock = (medias) => {
    return {
        width: medias.sm ? "1216px" : "328px",
        height: medias.sm ? "114px" : "unset",
        marginLeft: medias.sm ? "auto" : "16px",
        marginRight: medias.sm ? "auto" : "16px",
        marginTop: medias.sm ? "52px" : "20px",
    };
};

export const filtersBlockMainRow = (medias) => {
    return {
        position: "relative",
        width: medias.sm ? "1216px" : "fit-content",
        height: medias.sm ? "60px" : "unset",
        background: medias.sm ? "#6212FF" : "none",
        borderRadius: "12px",
    };
};

// export const filtersDropdownsBlock = (medias) => {
//     return {
//         position: "relative",
//         boxSizing: "border-box",
//         width: 1112,
//         height: medias.sm ? "60px" : "unset",
//         background: "#FFFFFF",
//         borderWidth: "1px 0px 1px 1px",
//         borderStyle: "solid",
//         borderColor: "#9E9EB0",
//         borderRadius: "12px",
//         display: medias.sm ? "inline-flex" : "inline",
//         gap: medias.sm ? "0px" : "10px",
//     };
// };

export const filtersDropdownsBlock = (theme) => {
    let commonStyle = {
        position: "relative",
        boxSizing: "border-box",
        width: 1112,
        background: "#FFFFFF",
        borderWidth: "1px 0px 1px 1px",
        borderStyle: "solid",
        borderColor: "#9E9EB0",
        borderRadius: "12px",
        display: "inline-flex",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "fit-content",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            borderStyle: "none",
            borderWidth: "0px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const filtersVerticalSeparator = (medias) => {
    return {
        border: "1px solid rgba(34, 34, 34, 0.2)",
        marginTop: "auto",
        marginBottom: "auto",
        marginLeft: "-1px",
        height: "36px",
        display: medias.sm ? "unset" : "none",
    };
};

export const filtersSearchBtn = {
    position: "relative",
    /*left: '93.59%',
    right: '2.38%',
    top: '0%',
    bottom: '0%',*/
    bottom: "100%",
    height: "inherit",
    left: "91.5%",
    width: "104px",
    justifyContent: "center",
    borderRadius: "12px",
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 16,
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
};

export const filtersMobileSearchBtn = {
    position: "relative",
    /*left: '93.59%',
    right: '2.38%',
    top: '0%',
    bottom: '0%',*/
    bottom: "100%",
    height: "44px",
    width: "99px",
    justifyContent: "center",
    borderRadius: "12px",
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 10,
    lineHeight: "130%",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    background: "#6212FF",
};

export const filtersAdditionalBlock = (medias) => {
    return {
        display: "flex",
        flexDirection: "row",
        justifyContent: medias.sm ? "flex-end" : "unset",
        alignItems: "center",
        padding: "0px",
        gap: "10px",
        height: "44px",
        marginTop: "10px",
    };
};

export const textFind = (medias) => {
    return {
        paddingRight: "4px",
        fontSize: medias.sm ? "16px" : "14px",
    };
};

export const filtersWrapper = {
    display: "flex",
    justifyContent: "space-between",
    width: "1216px",
};

export const iconCashbackMobileDisable = {
    display: "flex",
    width: "40px",
    height: "40px",
    padding: "11px 16px 12px 16px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "10px",
    border: "1px solid var(--Main-Gradient, #04C83B)",
    background: "linear-gradient(257deg, rgba(4, 200, 59, 0.10) 20.58%, rgba(18, 170, 255, 0.10) 81.82%)",
};
