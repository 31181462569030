import React from "react";
import * as themes from "./OrderResponsePriceStyled";
import { Box } from "@mui/material";

const OrderResponsePrice = ({ children }) => {
    let price;

    if (children === null) {
        price = "по договорённости";
    } else if (typeof children === "number") {
        const formattedNumber = children.toLocaleString();

        price = `${formattedNumber} ₽`;
    } else {
        price = children;
    }

    return (
        <Box sx={themes.OrderResponsePriceWrapper}>
            <themes.StyledOrderTitltePrice>цена: </themes.StyledOrderTitltePrice>
            <themes.StyledOrderResponsePrice>{price}</themes.StyledOrderResponsePrice>
        </Box>
    );
};

export default OrderResponsePrice;
