import React, { memo, useEffect, useState } from "react";
import * as themes from "./BodyCardThemes";
import { Button, Card, CircularProgress, Typography } from "@mui/material";
import TeacherCard from "components/UI/TeacherCard/TeacherCard";
import { observer } from "mobx-react-lite";
import CatalogStore from "store/CatalogStore";
import CustomPagination from "components/UI/CustomPagination";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SelectedFiltersTags from "components/CatalogBodyBlock/FiltersDrawer/SelectedFiltersTags";
import AdvCard from "components/UI/AdvCard";
import { useTranslation } from "react-i18next";
import { useMedias } from "../../../API/mediasHook";

const BodyCard = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const handlePagination = async (value) => {
        await CatalogStore.ProcessPagination(value);
        window.scrollTo(0, 0);
    };

    const handleShowMore = async () => {
        await CatalogStore.ShowMore();
    };

    const medias = useMedias();

    return (
        <Card sx={themes.bodyCardStyle(medias)}>
            {!CatalogStore.loadingTutorsData ? (
                <>
                    {!CatalogStore.loadingPaginatedTutorsData && (
                        <>
                            {!CatalogStore.noDataFetched && CatalogStore.teachersArr.length > 0 ? (
                                <Typography sx={themes.teacherFoundStyle(medias)}>{t("main.tutors", { count: CatalogStore.totalTeachersCount })}</Typography>
                            ) : (
                                <Box sx={themes.teacherNotFoundContainerStyle(medias)}>
                                    <Box sx={themes.teacherNotFoundTextContainerStyle}>
                                        <Typography sx={themes.teacherNotFoundHeaderStyle(medias)}>Репетиторы не найдены</Typography>
                                        <Typography sx={themes.teacherNotFoundSubHeaderStyle(medias)}>Попробуйте сбросить фильтры или изменить некоторые параметры</Typography>
                                    </Box>
                                    {CatalogStore.selectedChipsList.length > 0 && (
                                        <SelectedFiltersTags
                                            filtersTagsContainerStyle={themes.filtersTagsContainerStyle(medias)}
                                            clearChipTheme={themes.ClearChipTheme(medias)}
                                            allChipTheme={themes.AllChipTheme(medias)}
                                        ></SelectedFiltersTags>
                                    )}
                                    {CatalogStore.noDataFetched && (
                                        <>
                                            <Typography sx={themes.teacherNotFoundLastHeaderStyle(medias)}>Список специалистов, которые могут подойти</Typography>
                                            <Typography sx={themes.teacherFoundStyle(medias)}>{t("main.tutors", { count: CatalogStore.totalTeachersCount })}</Typography>
                                        </>
                                    )}
                                </Box>
                            )}
                        </>
                    )}
                    {CatalogStore.loadingPaginatedTutorsData && <Typography sx={themes.teacherFoundStyle(medias)}>Ищем репетиторов...</Typography>}
                    <AdvCard></AdvCard>
                    {!CatalogStore.loadingPaginatedTutorsData ? (
                        <>
                            <Box sx={themes.teacherContainerStyle(medias)}>
                                {CatalogStore.teachersArr.map((value, index) => (
                                    <TeacherCard
                                        key={value.ID}
                                        value={value}
                                    ></TeacherCard>
                                ))}
                            </Box>
                            <Box sx={themes.separatorContainerStyle}>
                                <ThemeProvider theme={themes.teacherShowMoreTheme}>
                                    <Button
                                        variant="text"
                                        disabled={CatalogStore.currentPage === CatalogStore.pagesTotal || CatalogStore.totalTeachersCount === CatalogStore.teachersArr.length}
                                        onClick={handleShowMore}
                                        disableRipple
                                    >
                                        Показать больше
                                    </Button>
                                </ThemeProvider>
                                <Box sx={themes.horizontalSeparatorStyle}></Box>
                            </Box>
                            {CatalogStore.loadingMoreTutorsData && (
                                <ThemeProvider theme={themes.CircularProgressTheme}>
                                    <Box sx={themes.progressContainerStyle}>
                                        <CircularProgress />
                                    </Box>
                                </ThemeProvider>
                            )}
                        </>
                    ) : (
                        <ThemeProvider theme={themes.CircularProgressTheme}>
                            <Box sx={themes.progressContainerStyle}>
                                <CircularProgress />
                            </Box>
                        </ThemeProvider>
                    )}

                    <CustomPagination
                        paginationTheme={themes.paginationTheme}
                        paginationContainerStyle={themes.paginationContainerStyle}
                        pagesContainerStyle={themes.pagesContainerStyle}
                        currentPage={CatalogStore.currentPage}
                        pagesTotal={CatalogStore.pagesTotal}
                        processingChange={handlePagination}
                    ></CustomPagination>
                </>
            ) : (
                <ThemeProvider theme={themes.CircularProgressTheme}>
                    <Box sx={themes.progressContainerStyle}>
                        <CircularProgress />
                    </Box>
                </ThemeProvider>
            )}
        </Card>
    );
});

export default memo(BodyCard);
