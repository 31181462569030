import { makeAutoObservable } from "mobx";
import ElizavetaAvatar from "assets/PaidServices/Avatars/Elizaveta.png";
import IvanAvatar from "assets/PaidServices/Avatars/Ivan.png";
import MariaNAvatar from "assets/PaidServices/Avatars/MariaN.png";
import MariaIAvatar from "assets/PaidServices/Avatars/MariaI.png";
import AleksandrAvatar from "assets/PaidServices/Avatars/Aleksandr.png";
import StanislavAvatar from "assets/PaidServices/Avatars/Stanislav.png";

class TeacherPaidServicesStore {
    activeFilterOption = 5;

    periodEndDate = new Date();

    periodStartDate = new Date(new Date().setDate(this.periodEndDate.getDate() - 7));

    PaidServicesDetails = [{}];
    PaidServicesSummary = {};

    studentName = null;

    groupedServices = {};

    getServiceWord(number) {
        if (number === 1) {
            return "paidServices.serviceOne";
        } else if (number >= 2 && number <= 4) {
            return "paidServices.serviceFew";
        } else {
            return "paidServices.serviceMany";
        }
    }

    setStudentName(value) {
        this.studentName = value;
    }

    formatDateToPoints(date) {
        const day = String(date.getDate()).padStart(2, "0");

        const month = String(date.getMonth() + 1).padStart(2, "0");

        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }

    setPeriodStartDate = (value) => {
        this.periodStartDate = value;
    };

    setPeriodEndDate = (value) => {
        this.periodEndDate = value;
    };

    setActiveFilterOption = (value) => {
        this.activeFilterOption = value;
    };

    groupByOrderDate(data) {
        return data.reduce((acc, obj) => {
            const shouldFilterByStudent = this.studentName && this.studentName.trim() !== "";

            if (!shouldFilterByStudent || obj.student.name.toLowerCase().includes(this.studentName.toLowerCase())) {
                const orderDate = obj.order.date.toLocaleDateString("ru-RU", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                });

                if (!acc[orderDate]) {
                    acc[orderDate] = [];
                }

                acc[orderDate].push(obj);
            }

            return acc;
        }, {});
    }

    formatDate(date) {
        const months = [
            "main.months.atJanuary",
            "main.months.atFebruary",
            "main.months.atMarch",
            "main.months.atApril",
            "main.months.atMay",
            "main.months.atJune",
            "main.months.atJuly",
            "main.months.atAugust",
            "main.months.atSeptember",
            "main.months.atOctober",
            "main.months.atNovember",
            "main.months.atDecember",
        ];

        const daysOfWeek = [
            "main.daysOfWeek.sundayShort",
            "main.daysOfWeek.mondayShort",
            "main.daysOfWeek.tuesdayShort",
            "main.daysOfWeek.wednesdayShort",
            "main.daysOfWeek.thursdayShort",
            "main.daysOfWeek.fridayShort",
            "main.daysOfWeek.saturdayShort",
        ];

        const [day, month, year] = date.split(".").map(Number);

        const normalDate = new Date(year, month - 1, day);

        const dayOfWeek = daysOfWeek[normalDate.getDay()];

        return { day: day, month: months[month - 1], dayOfWeek: daysOfWeek };
    }

    Init() {
        this.PaidServicesSummary = {
            services: 120,
            classes: 104,
            works: 16,
            income: 70000,
        };
        this.PaidServicesDetails = [
            {
                order: {
                    id: "order-1",
                    date: new Date(2024, 8, 12, 21, 40),
                    title: "Английский язык",
                },
                student: {
                    name: "Елизавета А.",
                    avatar: ElizavetaAvatar,
                },
                cost: { initial: 1500, total: 1275 },
                commission: 15,
            },
            {
                order: {
                    id: "order-2",
                    date: new Date(2024, 8, 12, 22, 40),
                    title: "Английский язык",
                },
                student: {
                    name: "Иван Н.",
                    avatar: IvanAvatar,
                },
                cost: { initial: 1500, total: 1275 },
                commission: 15,
            },
            {
                order: {
                    id: "order-3",
                    date: new Date(2024, 8, 12, 23, 40),
                    title: "Английский язык",
                },
                student: {
                    name: "Вероника Л.",
                    avatar: null,
                },
                cost: { initial: 1500, total: 1275 },
                commission: 15,
            },
            {
                order: {
                    id: "order-4",
                    date: new Date(2024, 8, 11, 18, 40),
                    title: "Французский язык",
                },
                student: {
                    name: "Мария Н.",
                    avatar: MariaNAvatar,
                },
                cost: { initial: 1700, total: 1445 },
                commission: 15,
            },
            {
                order: {
                    id: "order-5",
                    date: new Date(2024, 8, 11, 19, 40),
                    title: "Английский язык",
                },
                student: {
                    name: "Мария И.",
                    avatar: MariaIAvatar,
                },
                cost: { initial: 1500, total: 1275 },
                commission: 15,
            },
            {
                order: {
                    id: "order-6",
                    date: new Date(2024, 8, 11, 20, 40),
                    title: "Курсовая работа",
                },
                student: {
                    name: "Вероника Л.",
                    avatar: null,
                },
                cost: { initial: 8500, total: 7225 },
                commission: 15,
            },
            {
                order: {
                    id: "order-7",
                    date: new Date(2024, 8, 11, 21, 40),
                    title: "Английский язык",
                },
                student: {
                    name: "Александр Ш.",
                    avatar: AleksandrAvatar,
                },
                cost: { initial: 1500, total: 1275 },
                commission: 15,
            },
            {
                order: {
                    id: "order-8",
                    date: new Date(2024, 8, 11, 22, 40),
                    title: "Дипломная работа",
                },
                student: {
                    name: "Мария Д.",
                    avatar: null,
                },
                cost: { initial: 1500, total: 12750 },
                commission: 15,
            },
            {
                order: {
                    id: "order-9",
                    date: new Date(2024, 8, 10, 17, 40),
                    title: "Французский язык",
                },
                student: {
                    name: "Екатерина В.",
                    avatar: null,
                },
                cost: { initial: 1700, total: 1445 },
                commission: 15,
            },
            {
                order: {
                    id: "order-10",
                    date: new Date(2024, 8, 10, 18, 40),
                    title: "Английский язык",
                },
                student: {
                    name: "Мария И.",
                    avatar: MariaIAvatar,
                },
                cost: { initial: 1500, total: 1275 },
                commission: 15,
            },
            {
                order: {
                    id: "order-11",
                    date: new Date(2024, 8, 10, 19, 40),
                    title: "Курсовая работа",
                },
                student: {
                    name: "Вероника Л.",
                    avatar: null,
                },
                cost: { initial: 8500, total: 7225 },
                commission: 15,
            },
            {
                order: {
                    id: "order-12",
                    date: new Date(2024, 8, 10, 20, 40),
                    title: "Английский язык",
                },
                student: {
                    name: "Станислав К.",
                    avatar: null,
                },
                cost: { initial: 1500, total: 1275 },
                commission: 15,
            },
        ];
        this.PaidServicesUpdate();
    }

    PaidServicesUpdate() {
        this.groupedServices = this.groupByOrderDate(this.PaidServicesDetails);
    }

    constructor() {
        this.Init();
        makeAutoObservable(this);
    }
}

export default new TeacherPaidServicesStore();
