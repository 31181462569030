import React from "react";
import "./OrderPage.css";
import OrderResponseWrapp from "components/OrderResponse/OrderResponseWrapp/OrderResponseWrapp";
import Box from "@mui/material/Box";

const MyOrderPage = () => {
    return (
        <Box className="orderPage">
            <OrderResponseWrapp />
        </Box>
    );
};

export default MyOrderPage;
