import React from "react";
import * as themes from "./StudentCashbackThemes";
import * as textThemes from "themes/TextThemes";
import { Box, Typography } from "@mui/material";
import SubjectsImg from "assets/CashbackAssets/PNG/subjects.png";
import BonusAccountImg from "assets/CashbackAssets/PNG/bonus_account.png";
import TeacherResponseImg from "assets/CashbackAssets/PNG/teacher_response.png";
import IconCoin from "assets/CashbackAssets/PNG/IconCoin.png";
import IconCoins from "assets/CashbackAssets/PNG/IconCoins.png";
import IconHand from "assets/CashbackAssets/PNG/IconHand.png";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const StudentCashback = ({ children, ...props }) => {
    const { t } = useTranslation();

    return (
        <Box sx={themes.AllBlock}>
            <Box sx={themes.TextBlock}>
                <Typography sx={textThemes.HeadlineH2Black}>{t("promoPages.cashback.cashbackForStudent")}</Typography>
                <Typography sx={textThemes.Body1Black}>{t("promoPages.cashback.studyWithTutor")}</Typography>
            </Box>

            <img
                src={SubjectsImg}
                style={themes.SubjectsImg}
                alt="SubjectsImg"
            />
            <img
                src={TeacherResponseImg}
                style={themes.TeacherResponseImg}
                alt="TeacherResponseImg"
            />
            <img
                src={BonusAccountImg}
                style={themes.BonusAccountImg}
                alt="BonusAccountImg"
            />

            <Box sx={themes.IconCoinPosition}>
                <img
                    src={IconCoin}
                    alt="IconCoin"
                />
            </Box>

            <Box sx={themes.IconCoinsPosition}>
                <img
                    src={IconCoins}
                    alt="IconCoins"
                />
            </Box>

            <Box sx={themes.IconHandPosition}>
                <img
                    src={IconHand}
                    alt="IconHand"
                />
            </Box>
        </Box>
    );
};

export default memo(StudentCashback);
