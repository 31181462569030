import React from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./AccountInfoThemes";
import * as textThemes from "themes/TextThemes";
import { Chip, FormControlLabel, IconButton, Typography, Box, Tooltip } from "@mui/material";
import Frame from "assets/PAAssets/SVG/frame";
import IOSSwitch from "components/UI/IOSSwitch";
import Information from "assets/PAAssets/SVG/information";
import Play from "assets/PAAssets/SVG/play";
import StatusAnon from "assets/PAAssets/SVG/status_anon";
import { useNavigate } from "react-router-dom";
import SButton from "components/UI/SButton";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";

const InfoAccount = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const handleProfileClick = () => {
        navigate("/settings");
    };

    const currentYear = new Date().getFullYear();

    const totalExperienceYears = TeacherQuestionnaireStore.teacher.experience.reduce((sum, job) => {
        const endYear = job.endYear || currentYear;

        const difference = endYear - job.beginYear;

        return sum + (difference === 0 ? 1 : difference);
    }, 0);

    const getYearsTitle = (number) => {
        if (number === 1) {
            return "teacherAccount.accountInfo.yearOne";
        } else if (number > 1 && number < 5) {
            return "teacherAccount.accountInfo.yearFew";
        } else if (number > 5) {
            return "teacherAccount.accountInfo.yearMany";
        }
    };

    const MobileVersion = (
        <>
            <Box sx={themes.TopBlock(medias)}>
                <Box>
                    {TeacherQuestionnaireStore.teacher.anonymousProfile ? (
                        <Box sx={themes.PhotoHiddenBlock(medias)}>
                            <Typography sx={medias.sm ? textThemes.Caption1DarkGrey : textThemes.Caption2DarkGrey}>{t("teacherAccount.accountInfo.photoHidden")}</Typography>
                        </Box>
                    ) : (
                        <Box sx={themes.PhotoBlock}>
                            <img
                                src={TeacherQuestionnaireStore.teacher.photo.imageSrc}
                                style={themes.ProfilePhoto(medias)}
                            />
                            {TeacherQuestionnaireStore.teacher.video ? (
                                <IconButton
                                    disableRipple
                                    onClick={props.play}
                                    sx={themes.PlayButton}
                                >
                                    <Play />
                                </IconButton>
                            ) : null}
                        </Box>
                    )}
                </Box>
                <Box sx={themes.InfoContentBlock(medias)}>
                    <Box sx={themes.TitleBlock}>
                        {TeacherQuestionnaireStore.teacher.anonymousProfile ? (
                            <Box sx={themes.AccountTitle}>
                                <Typography sx={textThemes.Body1Black}>{t("teacherAccount.accountInfo.fioHidden")}</Typography>
                                <StatusAnon />
                            </Box>
                        ) : (
                            <Box sx={themes.AccountTitle}>
                                <Typography sx={textThemes.Body1Black}>{`${TeacherQuestionnaireStore.teacher.firstName} ${TeacherQuestionnaireStore.teacher.secondName}`}</Typography>
                                <Frame />
                            </Box>
                        )}
                    </Box>

                    <Box sx={themes.MobileTopBlock}>
                        <Typography sx={textThemes.Caption1DarkGrey}>{Object.keys(TeacherQuestionnaireStore.teacher.subjects)[0]}</Typography>
                        <SButton
                            variant="text-arrow"
                            onClick={handleProfileClick}
                        >
                            {t("teacherAccount.accountInfo.profile")}
                        </SButton>
                    </Box>
                </Box>
            </Box>
            <Box sx={themes.BottomBlock(medias)}>
                <FormControlLabel
                    sx={themes.AnonymousProfileSwitcher}
                    control={
                        <IOSSwitch
                            checked={TeacherQuestionnaireStore.teacher.anonymousProfile}
                            onChange={(event) => {
                                TeacherQuestionnaireStore.setAnonymousProfile(event.target.checked);
                            }}
                        />
                    }
                />
                <Box sx={themes.AnonymousProfileText}>
                    <Typography sx={textThemes.Caption1DarkGrey}>{t("teacherAccount.accountInfo.anonymousProfile")}</Typography>
                </Box>
            </Box>
        </>
    );

    return (
        <>
            {medias.sm ? (
                <Box sx={themes.AccountInfo(medias)}>
                    <Box sx={themes.TopBlock(medias)}>
                        <Box>
                            {TeacherQuestionnaireStore.teacher.anonymousProfile ? (
                                <Box sx={themes.PhotoHiddenBlock(medias)}>
                                    <Typography sx={textThemes.Body2DarkGrey}>{t("teacherAccount.accountInfo.photoHidden")}</Typography>
                                </Box>
                            ) : (
                                <Box sx={themes.PhotoBlock}>
                                    <img
                                        src={TeacherQuestionnaireStore.teacher.photo.imageSrc}
                                        style={themes.ProfilePhoto(medias)}
                                    />
                                    {TeacherQuestionnaireStore.teacher.video ? (
                                        <IconButton
                                            disableRipple
                                            onClick={props.play}
                                            sx={themes.PlayButton}
                                        >
                                            <Play />
                                        </IconButton>
                                    ) : null}
                                </Box>
                            )}
                        </Box>
                        <Box sx={themes.InfoContentBlock(medias)}>
                            <Box sx={themes.TitleBlock}>
                                {TeacherQuestionnaireStore.teacher.anonymousProfile ? (
                                    <Box sx={themes.AccountTitle}>
                                        <Typography sx={textThemes.HeadlineH41Black}>{t("teacherAccount.accountInfo.fioHidden")}</Typography>
                                        <StatusAnon />
                                    </Box>
                                ) : (
                                    <Box sx={themes.AccountTitle}>
                                        <Typography sx={textThemes.HeadlineH41Black}>{`${TeacherQuestionnaireStore.teacher.firstName} ${TeacherQuestionnaireStore.teacher.secondName}`}</Typography>
                                        <Frame />
                                    </Box>
                                )}
                                <SButton
                                    variant="text-arrow"
                                    onClick={handleProfileClick}
                                >
                                    {t("teacherAccount.accountInfo.profile")}
                                </SButton>
                            </Box>
                            <Box sx={themes.ColumnContainer}>
                                <Box sx={themes.RowContainer}>
                                    <Typography
                                        component="span"
                                        sx={textThemes.Body2Grey}
                                    >
                                        {t("teacherAccount.accountInfo.teaches")}
                                    </Typography>
                                    {Object.keys(TeacherQuestionnaireStore.teacher.subjects).map((item, index) => (
                                        <Chip
                                            key={index}
                                            sx={themes.Chips}
                                            label={item}
                                        />
                                    ))}
                                </Box>
                                <Box>
                                    <Typography
                                        component="span"
                                        sx={textThemes.Body2Grey}
                                    >
                                        {t("teacherAccount.accountInfo.where")}
                                        <Typography
                                            component="span"
                                            sx={textThemes.Body2Black}
                                        >
                                            {`
                            ${TeacherQuestionnaireStore.teacher.places.online === true ? t("teacherAccount.recommendedOrders.online") : ""} 
                            ${TeacherQuestionnaireStore.teacher.places.online === true && (TeacherQuestionnaireStore.teacher.places.student || TeacherQuestionnaireStore.teacher.places.teacher) ? "•" : ""}
                            ${TeacherQuestionnaireStore.teacher.places.teacher === true ? t("teacherAccount.recommendedOrders.atTutor") : ""}
                            ${TeacherQuestionnaireStore.teacher.places.teacher === true && TeacherQuestionnaireStore.teacher.places.student === true ? "•" : ""}
                            ${TeacherQuestionnaireStore.teacher.places.student === true ? t("teacherAccount.recommendedOrders.atStudent") : ""}`}
                                        </Typography>
                                    </Typography>
                                </Box>
                                <Typography sx={textThemes.Body2Black}>
                                    <Typography
                                        component="span"
                                        sx={textThemes.Body2Grey}
                                    >
                                        {t("teacherAccount.accountInfo.education")}
                                    </Typography>
                                    {TeacherQuestionnaireStore.teacher.education.length > 1
                                        ? TeacherQuestionnaireStore.teacher.education.map((item) => item.university).join(", ")
                                        : TeacherQuestionnaireStore.teacher.education[0].university}
                                </Typography>
                                <Box sx={themes.RowContainer}>
                                    <Typography
                                        component="span"
                                        sx={textThemes.Body2Grey}
                                    >
                                        {t("teacherAccount.accountInfo.experience")}
                                    </Typography>
                                    <Chip
                                        sx={themes.Chips}
                                        label={`${totalExperienceYears} ${t(getYearsTitle(totalExperienceYears))}`}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={themes.BottomBlock(medias)}>
                        <FormControlLabel
                            sx={themes.AnonymousProfileSwitcher}
                            control={
                                <IOSSwitch
                                    checked={TeacherQuestionnaireStore.teacher.anonymousProfile}
                                    onChange={(event) => {
                                        TeacherQuestionnaireStore.setAnonymousProfile(event.target.checked);
                                    }}
                                />
                            }
                        />
                        <Box sx={themes.AnonymousProfileText}>
                            <Typography sx={textThemes.Body2DarkGrey}>{t("teacherAccount.accountInfo.anonymousProfile")}</Typography>
                            <Tooltip
                                PopperProps={themes.TooltipPopperProps}
                                title={`${t("teacherAccount.accountInfo.anonymousTooltip")}`}
                            >
                                <IconButton
                                    disableRipple
                                    style={themes.TooltipButton}
                                >
                                    <Information />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box sx={themes.AccountInfo(medias)}>{MobileVersion}</Box>
            )}
        </>
    );
});

export default memo(InfoAccount);
