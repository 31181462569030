import { styleProcessing } from "API/plugins/Utilities";

export const SettingsContent = {
    display: "flex",
    flexDirection: "column",
    paddingTop: "40px",
    minHeight: "780px",
    paddingRight: "14px",
    paddingLeft: "14px",
};

export const Settings = (theme) => {
    let commonStyle = {
        borderRadius: "0px 0px 40px 40px",
        background: "#F7F7F7",
        display: "flex",
        justifyContent: "center",
        paddingBottom: "70px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            paddingBottom: "40px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const DeleteAccount = {
    maxWidth: "671px",
    backgroundColor: "#FFFFFF",
    padding: "19px 0px 20px 30px",
    borderRadius: "20px",
    boxShadow: "0px 4px 30px 0px rgba(106, 99, 118, 0.05)",
};

export const ProfileAndLoginDetails = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column-reverse",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const SettingsBlocks = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};
