export const inputProps = (maxCharacters) => {
    return {
        maxLength: maxCharacters,
        sx: {
            "&::-webkit-scrollbar": {
                width: "0.3em",
            },

            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#bbbbc8",
                borderRadius: "10px",
            },
        },
    };
};

export const Textfield = (condition) => {
    return {
        borderRadius: "10px",
        maxWidth: "400px",
        width: "100%",
        "& ::placeholder": {},

        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: condition ? "#E12626" : "#6212FF",
                borderWidth: "1px",
            },
            "&:hover fieldset": {
                borderColor: condition ? "#E12626" : "#6212FF",
                borderWidth: "1px",
            },
            fieldset: { borderColor: condition ? "#E12626" : "#BBBBC8" },
        },
        "& .MuiInputBase-root": {
            "& input": {
                textAlign: "left",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "150%",
                color: "#222",
            },
        },
    };
};

export const helperBox = {
    display: "flex",
    justifyContent: "space-between",
};

export const HelperBox = {
    display: "flex",
    justifyContent: "space-between",
};

export const TextfieldReview = {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
};
