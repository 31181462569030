import React from "react";
import { observer } from "mobx-react-lite";

import { Stack } from "@mui/material";

import StyledWhiteBox from "components/DiplomaChats/StyledComponents/StyledWhiteBox";
import OrderStatusInfoWithTooltips from "./OrderStatusInfoWithTooltips";
import Working from "./Working";
import Term from "./Term";

import UserStore from "store/UserStore";
import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";
import TutorWorkingStatus from "./Tutor/TutorWorkingStatus";

const OrderState = () => {
    return (
        <StyledWhiteBox
            width={"433px"}
            padding={"20px 30px"}
        >
            <Stack
                direction="column"
                spacing={2.5}
            >
                <OrderStatusInfoWithTooltips />
                {DiplomaStore.status === "working" ? <Working /> : null}
                {["waiting", "correcting"].includes(DiplomaStore.status) && <Term />}
            </Stack>
        </StyledWhiteBox>
    );
};

export default observer(OrderState);
