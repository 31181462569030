import { createTheme } from "@mui/material/styles";

export const alterActionsContainerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "0px 0px 20px 20px",
    borderTop: "1px solid rgba(187, 187, 200, 0.50)",
    background: "var(--main-white, #FFF)",
};

export const TabsTheme = createTheme({
    components: {
        MuiTabs: {
            styleOverrides: {
                root: {
                    marginLeft: 20,
                    marginTop: 21,
                    minHeight: "fit-content",
                    ".MuiTabs-flexContainer": {
                        gap: "20px",
                    },
                    ".MuiButtonBase-root": {
                        color: "var(--text-grey, #9E9EB0)",
                        fontVariantNumeric: "lining-nums tabular-nums",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "150%",
                        textTransform: "none",
                        padding: 0,
                        minWidth: "fit-content",
                        minHeight: "fit-content",
                        marginBottom: 4,
                    },
                    ".MuiTabs-indicator": {
                        background: "#6212FF",
                    },
                    ".Mui-selected": {
                        color: "var(--text-black, #222)!important",
                    },
                },
            },
        },
    },
});
