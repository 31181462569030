import React from "react";

import store from "store/RequestStore";
import AdviceIcon1 from "assets/CommonAssets/SVG/adviceIcon1";
import AdviceIcon2 from "assets/CommonAssets/SVG/adviceIcon2";
import AdviceIcon3 from "assets/CommonAssets/SVG/adviceIcon3";
import AdviceIcon4 from "assets/CommonAssets/SVG/adviceIcon4";
import AdviceIcon5 from "assets/CommonAssets/SVG/adviceIcon5";
import AdviceIcon6 from "assets/CommonAssets/SVG/adviceIcon6";
import { Box, Typography } from "@mui/material";
import * as theme from "./RequestTooltipTheme";

const RequestTooltip = ({ text }) => {
    const { tooltip } = store.storage[store.activeStepIndex].substeps[store.subStepIndex];

    const renderTooltipIcon = (iconNumber) => {
        const iconComponents = [AdviceIcon1, AdviceIcon2, AdviceIcon3, AdviceIcon4, AdviceIcon5, AdviceIcon6];

        return iconComponents[iconNumber - 1] || 1;
    };

    return (
        <Box sx={theme.RequestTooltip}>
            <Typography
                variant="p"
                sx={theme.elementStyles}
            >
                {text}
            </Typography>
            <Box sx={theme.iconWrapperStyles}>{tooltip && renderTooltipIcon(tooltip.icon)()}</Box>
        </Box>
    );
};

export default RequestTooltip;
