export const Folder = () => {
    return (
        <svg
            width="3vh"
            height="3vh"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3 8.5V7.75C3 5.67893 4.67893 4 6.75 4H8.25C9.47686 4 10.5661 4.58917 11.2503 5.5L18 5.5C19.6569 5.5 21 6.84315 21 8.5L3 8.5Z"
                fill="#9E9EB0"
            />
            <path
                d="M21 16V10H3V16C3 18.0711 4.67893 19.75 6.75 19.75H17.25C19.3211 19.75 21 18.0711 21 16Z"
                fill="#9E9EB0"
            />
        </svg>
    );
};
