import { createTheme } from "@mui/material/styles";

export const subjectBlockContainerStyle = (medias, styleMode) => {
    if (styleMode === 0) {
        return {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            gap: "4px",
            width: medias.sm ? "560px" : "296px",
            height: medias.sm ? "79px" : "fit-content",
            flex: "none",
            order: 1,
            flexGrow: 0,
            zIndex: 1,
        };
    }

    if (styleMode === 1) {
        return {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            gap: "4px",
            width: "610px",
            height: "88px",
            flex: "none",
            order: 1,
            flexGrow: 0,
            zIndex: 1,
        };
    }
};

export const subjectStyle = (medias, styleMode) => {
    if (styleMode === 0) {
        return {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px",
            gap: medias.sm ? "5px" : "2px",
            height: "25px",
            flex: "none",
            order: 1,
            flexGrow: 0,
        };
    }

    if (styleMode === 1) {
        return {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px",
            gap: "5px",
            height: "28px",
            flex: "none",
            order: 1,
            flexGrow: 0,
        };
    }
};

export const subjectLabelStyle = (styleMode) => {
    if (styleMode === 0) {
        return {
            height: "21px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "150%",
            display: "flex",
            alignItems: "center",
            color: "#9E9EB0",
            flex: "none",
            order: 0,
            flexGrow: 0,
        };
    }

    if (styleMode === 1) {
        return {
            height: "24px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "150%",
            display: "flex",
            alignItems: "center",
            color: "#9E9EB0",
            flex: "none",
            order: 0,
            flexGrow: 0,
        };
    }
};

export const subjectContainerStyle = (styleMode) => {
    if (styleMode === 0) {
        return {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
            padding: "1px 10px 1px",
            gap: "10px",
            height: "25px",
            background: "#F7F3FF",
            borderRadius: "26px",
            flex: "none",
            order: 1,
            flexGrow: 0,
        };
    }

    if (styleMode === 1) {
        return {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
            padding: "1px 10px 1px",
            gap: "10px",
            height: "25px",
            background: "#F7F3FF",
            borderRadius: "26px",
            flex: "none",
            order: 1,
            flexGrow: 0,
        };
    }
};

export const subjectTextStyle = (styleMode) => {
    if (styleMode === 0) {
        return {
            height: "21px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "150%",
            display: "flex",
            alignItems: "center",
            color: "#222222",
            flex: "none",
            order: 0,
            flexGrow: 0,
        };
    }

    if (styleMode === 1) {
        return {
            height: "24px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "150%",
            display: "flex",
            alignItems: "center",
            color: "#222222",
            flex: "none",
            order: 0,
            flexGrow: 0,
        };
    }
};

export const MoreChipTheme = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                // Name of the slot
                root: {
                    display: "flex",
                    flexDirection: "row",
                    gap: 4,
                    alignItems: "center",
                    paddingBottom: "4px",
                    paddingTop: "2px",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    width: "fit-content",
                    height: "27px",
                    background: "rgb(247, 243, 255) !important",
                    border: "0px solid var(--grey-light-grey, #BBBBC8)",
                    "&:hover": {
                        background: "rgb(247, 243, 255) !important",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "rgb(247, 243, 255) !important",
                        borderColor: "#6212FF",
                    },
                    ".MuiChip-deleteIcon": {
                        width: "16px",
                        height: "16px",
                        flex: "none",
                        order: 1,
                        flexGrow: 0,
                    },
                    ".MuiIconButton-root": {
                        margin: 0,
                        color: "#222222!important",
                    },
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    ".MuiIconButton-root:hover": {
                        color: "#222222!important",
                    },
                },
                label: {
                    width: "fit-content",
                    height: "21px",
                    fontStyle: "normal",
                    padding: 0,
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "150%",
                    fontFeatureSettings: "'tnum' on, 'lnum' on",
                    color: "#222222!important",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                },
            },
        },
    },
});

export const MenuTheme = createTheme({
    components: {
        MuiMenu: {
            styleOverrides: {
                // Name of the slot
                root: {},
                paper: {
                    marginTop: 10,
                    borderRadius: "10px!important",
                    ".MuiList-root": {
                        display: "flex",
                        width: "max-content",
                        padding: "7px 4px",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    },
                    ".MuiMenuItem-root": {
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        alignSelf: "stretch",
                        padding: "5px 12px",
                        "&:hover": {
                            background: "rgba(187, 187, 200, 0.17)!important",
                            borderRadius: "10px!important",
                            "& *": {
                                color: "#6212FF!important",
                            },
                        },
                        "&.Mui-selected": {
                            background: "rgba(187, 187, 200, 0.17)!important",
                            borderRadius: "10px!important",
                            "& *": {
                                color: "#6212FF!important",
                            },
                        },
                    },
                },
            },
        },
    },
});

export const actionTextStyle = {
    color: "var(--Text-Black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    "&:hover": {
        color: "#6212FF!important",
    },
};
