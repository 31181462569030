import React, { memo } from "react";
import * as themes from "./TutorClassesTabsThemes";
import { Chip, Box, Badge } from "@mui/material";
import { observer } from "mobx-react-lite";
import TutorClassesStore from "store/TutorClassesStore";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";

const tabLabels = [
    { label: "teacherClasses.upcoming", type: null },
    { label: "teacherClasses.pending", type: "pending" },
    { label: "teacherClasses.rescheduleRequest", type: "rescheduled" },
    { label: "teacherClasses.conducted", type: null },
    { label: "teacherClasses.cancelled", type: null },
];

const TutorClassesTabs = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const countSeen = (type) => {
        if (!type) return 0;

        return TutorClassesStore.classes.filter((element) => !element.seen && element.classStatus === type).length;
    };

    const handleClick = (event) => {
        const buttonElement = event.target;

        if (!medias.sm) {
            buttonElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    const renderChip = (index, label, type) => (
        <Badge
            key={index}
            badgeContent={countSeen(type)}
            sx={themes.Badge(TutorClassesStore.activeClassesTab === index)}
        >
            <Chip
                disableRipple
                style={themes.ChipStyle(TutorClassesStore.activeClassesTab === index)}
                sx={TutorClassesStore.activeClassesTab === index ? themes.ChipSelect : themes.Chip}
                label={t(label)}
                variant="outlined"
                onClick={() => {
                    if (TutorClassesStore.activeClassesTab === 2 && index !== 2) {
                        TutorClassesStore.setSeenClasses("pending");
                    } else if (TutorClassesStore.activeClassesTab === 3 && index !== 3) {
                        TutorClassesStore.setSeenClasses("rescheduled");
                    }

                    TutorClassesStore.setActiveClassesTab(index);
                }}
            />
        </Badge>
    );

    return (
        <Box
            onClick={handleClick}
            sx={themes.ChipContainer(medias)}
        >
            {tabLabels.map((tab, index) => renderChip(index + 1, tab.label, tab.type))}
        </Box>
    );
});

export default memo(TutorClassesTabs);
