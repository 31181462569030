import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grid from "@mui/material/Unstable_Grid2";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Popper from "@mui/material/Popper";
import UserStore from "store/UserStore";
import React, { useEffect, useState } from "react";
import * as styles from "./StudentTeacherPoppersThemes";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import * as textThemes from "themes/TextThemes";
import Profile from "assets/ChatAssets/PNG/profile_navbar.png";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import { deleteCookie, getCookie, setCookie } from "API/utils";
import AuthorizationStore from "store/AuthorizationStore";
import { useNavigate } from "react-router-dom";
import FullChatStore from "store/FullChatStore";

const TeacherPopper = observer(({ open, setOpen, anchorRef }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const [doubleRole, setDouble] = useState(0);

    useEffect(() => {
        if (getCookie("studentAccessToken")) setDouble(1);
        else setDouble(0);
    }, []);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const setChangeRole = () => {
        UserStore.setRole("student");
        setCookie("role", "student");
        FullChatStore.SetTabsData({});
        FullChatStore.SetChatID("");
        navigate("/account");
        UserStore.setPage(0);
        setOpen(false);
    };

    const OpenPA = () => {
        setOpen(false);
        navigate("/account");
        UserStore.setPage(0);
    };

    const OpenBalance = () => {
        setOpen(false);
        navigate("/balance");
    };

    const OpenSchedule = () => {
        setOpen(false);
        navigate("/schedule");
        UserStore.setPage(1);
    };

    const OpenTutorClasses = () => {
        setOpen(false);
        navigate("/tutor/classes");
        UserStore.setPage(2);
    };

    const OpenTutorPaid = () => {
        setOpen(false);
        navigate("/tutor/paid");
    };

    const OpenProfileSettings = () => {
        setOpen(false);
        navigate("/settings");
    };

    const OpenOrders = () => {
        setOpen(false);
        navigate("/orders");
        UserStore.setPage(3);
    };

    const OpenRef = () => {
        setOpen(false);
        navigate("/referral");
    };

    const OpenOrdersResponse = () => {
        setOpen(false);
        navigate("orders-responses");
        UserStore.setPage(4);
    };

    const logout = async () => {
        if (getCookie("refreshToken")) {
            await AuthorizationStore.handleLogout();
            deleteCookie("tutorAccessToken");
            deleteCookie("refreshToken");
            deleteCookie("role");
        }

        setOpen(false);
        UserStore.setAuth(false);
        navigate("/");
    };

    const toSignUp = () => {
        AuthorizationStore.setCurrentAuthorizationStep("SignUpStudent");
        AuthorizationStore.setSignUpPhone(null);
        AuthorizationStore.setSignUpPhoneError(false);
        AuthorizationStore.setSignUpName("");
        AuthorizationStore.setSignUpEmail("");
        AuthorizationStore.setSignUpEmailError(false);
        AuthorizationStore.setTutorOfferAgree(false);
        AuthorizationStore.setTutorRulesAgree(false);
        setOpen(false);
        navigate("/authorization");
    };

    return (
        <Popper
            open={open}
            anchorEl={anchorRef.current}
            transition
            disablePortal
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
                >
                    <Paper sx={styles.StudentTeacherPopperPaper}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <Grid>
                                <MenuList sx={styles.StudentTeacherPopperMenuList}>
                                    <Grid sx={styles.StudentTeacherPopperUpperGrid}>
                                        <MenuItem
                                            disableRipple
                                            onClick={OpenPA}
                                            sx={styles.StudentTeacherPopperMenuItem}
                                        >
                                            {t("main.header.personalAccount")}
                                        </MenuItem>
                                        <MenuItem
                                            disableRipple
                                            onClick={OpenSchedule}
                                            sx={styles.StudentTeacherPopperMenuItem}
                                        >
                                            {t("main.header.schedule")}
                                        </MenuItem>
                                        <MenuItem
                                            disableRipple
                                            onClick={OpenTutorClasses}
                                            sx={styles.StudentTeacherPopperMenuItem}
                                        >
                                            {t("main.header.classes")}
                                        </MenuItem>
                                        <MenuItem
                                            disableRipple
                                            onClick={OpenOrders}
                                            sx={styles.StudentTeacherPopperMenuItem}
                                        >
                                            {t("main.header.orders")}
                                        </MenuItem>
                                        <MenuItem
                                            disableRipple
                                            onClick={OpenOrdersResponse}
                                            sx={styles.StudentTeacherPopperMenuItem}
                                        >
                                            {t("main.header.responses")}
                                        </MenuItem>
                                    </Grid>
                                    <Grid sx={styles.StudentTeacherPopperLowerGrid}>
                                        <MenuItem
                                            disableRipple
                                            onClick={OpenTutorPaid}
                                            sx={styles.StudentTeacherPopperMenuItem}
                                        >
                                            {t("main.header.completedOrders")}
                                        </MenuItem>
                                        <MenuItem
                                            disableRipple
                                            onClick={OpenBalance}
                                            sx={styles.StudentTeacherPopperMenuItem}
                                        >
                                            {t("main.header.invitedUsers")}
                                        </MenuItem>
                                    </Grid>
                                    <Grid sx={styles.StudentTeacherPopperLowerGrid}>
                                        <MenuItem
                                            disableRipple
                                            onClick={OpenProfileSettings}
                                            sx={styles.StudentTeacherPopperMenuItem}
                                        >
                                            {t("main.header.profileAndSettings")}
                                        </MenuItem>
                                    </Grid>
                                    <Grid sx={styles.StudentTeacherPopperLowerGrid}>
                                        <MenuItem
                                            disableRipple
                                            onClick={OpenRef}
                                            sx={styles.StudentTeacherPopperMenuItem}
                                        >
                                            {t("main.header.referralProgram")}
                                        </MenuItem>
                                    </Grid>
                                    <Grid sx={styles.BottomContainer}>
                                        {UserStore.nAccountTypes === 2 && (
                                            <Box sx={styles.StudentTeacherSwitchAccountContainer}>
                                                <Box sx={styles.SwitchAccountLabelBox}>
                                                    <Typography sx={textThemes.Caption11DarkGrey}>{t("main.header.switchAccount")}</Typography>
                                                </Box>
                                                {doubleRole === 1 ? (
                                                    <MenuItem
                                                        onClick={setChangeRole}
                                                        disableRipple
                                                        sx={styles.StudentTeacherSwitchAccountItem}
                                                    >
                                                        <img
                                                            src={Profile}
                                                            alt="teacherLogo"
                                                        />
                                                        <Box sx={styles.StudentTeacherSwitchAccountText}>
                                                            <Typography sx={textThemes.Body2Black}>{`${UserStore.UserInfo.name} ${UserStore.UserInfo.surname}`}</Typography>
                                                            <Typography sx={textThemes.Caption2Grey}>{t("main.header.studentProfile")}</Typography>
                                                        </Box>
                                                    </MenuItem>
                                                ) : (
                                                    <MenuItem
                                                        onClick={toSignUp}
                                                        disableRipple
                                                        sx={styles.StudentTeacherSwitchAccountItem}
                                                    >
                                                        <Box sx={styles.StudentTeacherSwitchAccountText}>
                                                            <Typography sx={textThemes.Body2Black}> Зарегистрироваться как ученик </Typography>
                                                            <Typography sx={textThemes.Caption2Grey}> Вы перейдете в форму для регистрации </Typography>
                                                        </Box>
                                                    </MenuItem>
                                                )}
                                            </Box>
                                        )}
                                        <MenuItem
                                            disableRipple
                                            onClick={logout}
                                            sx={styles.StudentTeacherPopperMenuItem}
                                        >
                                            {t("main.header.signOut")}
                                        </MenuItem>
                                    </Grid>
                                </MenuList>
                            </Grid>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
});

export default memo(TeacherPopper);
