import CashbackStart from "./CashbackStart/CashbackStart";
import CashbackDef from "./CashbackDef/CashbackDef";
import StudentCashback from "./StudentCashback/StudentCashback";
import TutorCashback from "./TutorCashback/TutorCashback";
import JoinUs from "./JoinUs/JoinUs";
import TopQuestionsCashback from "./TopQuestions/TopQuestions";
import React from "react";
import { memo } from "react";
import { Box } from "@mui/material";
import * as themes from "./CashbackPromoBodyBlockThemes";

const CashbackPromoBodyBlock = ({ children, ...props }) => {
    return (
        <Box sx={themes.CashbackPromoBodyBlock}>
            <CashbackStart />
            <CashbackDef />
            <StudentCashback />
            <TutorCashback />
            <JoinUs />
            <TopQuestionsCashback />
        </Box>
    );
};

export default memo(CashbackPromoBodyBlock);
