export const RequestTooltip = {
    alignItems: "flex-start",
    border: "1px solid",
    borderColor: "#BBBBC8",
    borderRadius: "20px",
    display: "flex",
    gap: "10px",
    padding: "20px",
    position: "relative",
    width: " fit-content",
    height: " fit-content",
    marginTop: "72px",
};

export const elementStyles = {
    color: "#222222",
    fontFamily: "'Inter'",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "140",
    letterSpacing: "0",
    lineHeight: "140%",
    marginTop: "-1px",
    marginBottom: "0",
    position: "relative",
    width: "200px",
};

export const iconWrapperStyles = {
    backgroundColor: "#6212ff",
    borderRadius: "18px",
    height: "36px",
    left: "-13px",
    position: "absolute",
    top: "-13px",
    width: "36px",
};
