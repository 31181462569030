import { styleProcessing } from "API/plugins/Utilities";

export const readPolicyBlock = {
    padding: "20px 0 30px 0",
    borderTop: "1px solid #EEEEF0",
};

export const ImportantOnAccount = {
    paddingBottom: "30px",
    height: "85%",
};

export const somethingLeft = (theme) => {
    let commonStyle = {
        padding: "10px 0 20px 0",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            paddingTop: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ButtonsContainer = {
    display: "flex",
    justifyContent: "center",
    gap: "16px",
    marginBottom: "30px",
    marginTop: "10px",
};

export const ButtonsDrawerContainer = (theme) => {
    let commonStyle = {
        display: "flex",
        justifyContent: "center",
        gap: "16px",
        marginBottom: "30px",
        marginTop: "10px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "14px",
            marginTop: "auto",
            padding: "0 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const DeleteAccount = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "20px",
            height: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const AddForm = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100vw",
            maxWidth: "100vw",
            minWidth: "370px",
            gap: "20px",
            height: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const PhoneNumber = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const AccountDeletedContent = {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "center",
};

export const ModalDeletedtext = (theme) => {
    let commonStyle = {
        textAlign: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            fontSize: "18px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const AccountDeletedClose = {
    padding: "30px 0 30px 0",
    display: "flex",
    justifyContent: "center",
};
