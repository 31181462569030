import React, { memo, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./FavouritesBodyBlockThemes";
import { Button, CircularProgress, Typography } from "@mui/material";
import FavouritesStore from "store/FavouritesStore";
import TeacherCard from "components/UI/TeacherCard/TeacherCard";
import { ThemeProvider } from "@mui/material/styles";
import CustomPagination from "../../UI/CustomPagination";

const FavouritesBodyBlock = observer(({ children, ...props }) => {
    const handlePagination = async (value) => {
        await FavouritesStore.ProcessPagination(value);
        window.scrollTo(0, 0);
    };

    const handleShowMore = async () => {
        await FavouritesStore.ShowMore();
    };

    return (
        <Box sx={themes.bodyBlockContainer}>
            {!FavouritesStore.loadingFavouritesData ? (
                <>
                    {!FavouritesStore.loadingPaginatedFavouritesData ? (
                        <>
                            {FavouritesStore.favouritesListFiltered.length > 0 ? (
                                <Typography sx={themes.favouritesFoundStyle}>Сохранено {FavouritesStore.totalFavouritesCount} репетиторов</Typography>
                            ) : (
                                <Typography sx={themes.favouritesNotFoundStyle}>Вы еще не сохранили ни одного репетитора!</Typography>
                            )}
                            <Box sx={themes.favouritesContainerStyle}>
                                {FavouritesStore.favouritesListFiltered.length > 0
                                    ? FavouritesStore.favouritesListFiltered.map((value, index) => (
                                          <TeacherCard
                                              key={value.ID}
                                              value={value}
                                          ></TeacherCard>
                                      ))
                                    : null}
                            </Box>
                            <Box sx={themes.separatorContainerStyle}>
                                <ThemeProvider theme={themes.teacherShowMoreTheme}>
                                    <Button
                                        variant="text"
                                        disabled={FavouritesStore.currentPage === FavouritesStore.pagesTotal || FavouritesStore.totalFavouritesCount === FavouritesStore.favouritesListFiltered.length}
                                        onClick={handleShowMore}
                                        disableRipple
                                    >
                                        Показать больше
                                    </Button>
                                </ThemeProvider>
                                <Box sx={themes.horizontalSeparatorStyle}></Box>
                            </Box>
                            {FavouritesStore.loadingMoreFavouritesData && (
                                <ThemeProvider theme={themes.CircularProgressTheme}>
                                    <Box sx={themes.progressContainerStyle}>
                                        <CircularProgress />
                                    </Box>
                                </ThemeProvider>
                            )}
                        </>
                    ) : (
                        <ThemeProvider theme={themes.CircularProgressTheme}>
                            <Box sx={themes.progressContainerStyle}>
                                <CircularProgress />
                            </Box>
                        </ThemeProvider>
                    )}

                    <CustomPagination
                        paginationTheme={themes.paginationTheme}
                        paginationContainerStyle={themes.paginationContainerStyle}
                        pagesContainerStyle={themes.pagesContainerStyle}
                        currentPage={FavouritesStore.currentPage}
                        pagesTotal={FavouritesStore.pagesTotal}
                        processingChange={handlePagination}
                    ></CustomPagination>
                </>
            ) : (
                <ThemeProvider theme={themes.CircularProgressTheme}>
                    <Box sx={themes.progressContainerStyle}>
                        <CircularProgress />
                    </Box>
                </ThemeProvider>
            )}
        </Box>
    );
});

export default memo(FavouritesBodyBlock);
