import React from "react";
const Clock = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.0001 3.2998C9.47714 3.2998 7.98842 3.7514 6.72216 4.59749C5.4559 5.44358 4.46897 6.64615 3.88618 8.05314C3.30339 9.46013 3.1509 11.0083 3.44801 12.502C3.74511 13.9957 4.47847 15.3677 5.55533 16.4445C6.63219 17.5214 8.0042 18.2547 9.49786 18.5519C10.9915 18.849 12.5397 18.6965 13.9467 18.1137C15.3537 17.5309 16.5563 16.544 17.4024 15.2777C18.2485 14.0114 18.7 12.5227 18.7 10.9998C18.6978 8.95832 17.8859 7.00109 16.4423 5.55754C14.9988 4.114 13.0415 3.30203 11.0001 3.2998ZM14.5054 13.6545C14.4371 13.7409 14.3503 13.8109 14.2512 13.8592C14.1522 13.9074 14.0435 13.9327 13.9334 13.9331C13.7676 13.9345 13.6066 13.8773 13.4786 13.7718L10.5453 11.5718C10.4589 11.5035 10.389 11.4166 10.3408 11.3176C10.2926 11.2185 10.2673 11.1099 10.2667 10.9998V5.86647C10.2667 5.67198 10.344 5.48545 10.4815 5.34793C10.619 5.2104 10.8056 5.13314 11.0001 5.13314C11.1945 5.13314 11.3811 5.2104 11.5186 5.34793C11.6561 5.48545 11.7334 5.67198 11.7334 5.86647V10.6478L14.3881 12.6278C14.4645 12.6866 14.5282 12.7602 14.5756 12.8441C14.623 12.928 14.653 13.0206 14.6639 13.1163C14.6749 13.2121 14.6665 13.309 14.6392 13.4015C14.612 13.4939 14.5665 13.5799 14.5054 13.6545Z"
                fill="#BBBBC8"
            />
            <path
                d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476623 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C21.9965 8.08371 20.8364 5.28787 18.7743 3.22574C16.7121 1.16361 13.9163 0.00354751 11 0ZM11 20.2155C9.17734 20.2155 7.39561 19.6751 5.88012 18.6624C4.36463 17.6498 3.18346 16.2106 2.48595 14.5266C1.78845 12.8427 1.60595 10.9898 1.96154 9.20214C2.31712 7.41449 3.19481 5.77244 4.48363 4.48363C5.77245 3.19481 7.4145 2.31711 9.20214 1.96153C10.9898 1.60595 12.8427 1.78845 14.5266 2.48595C16.2106 3.18345 17.6498 4.36463 18.6624 5.88012C19.6751 7.39561 20.2155 9.17734 20.2155 11C20.2132 13.4434 19.2415 15.786 17.5138 17.5138C15.786 19.2415 13.4434 20.2132 11 20.2155Z"
                fill="#BBBBC8"
            />
        </svg>
    );
};

export default Clock;
