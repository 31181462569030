import Message2 from "assets/PAAssets/SVG/message2";
import React from "react";
import * as themes from "./IconOpenMiniChatThemes";
import { memo } from "react";
import SButton from "components/UI/SButton";
import Box from "@mui/material/Box";
import MiniChatStore from "store/MiniChatStore";

const IconOpenMiniChat = ({ children, ...props }) => {
    const showDialog = () => {
        MiniChatStore.SetOpen(true);
    };

    return (
        <Box sx={themes.IconOpenMiniChat}>
            <SButton
                variant="icon"
                onClick={showDialog}
            >
                <Message2 />
            </SButton>
        </Box>
    );
};

export default memo(IconOpenMiniChat);
