const TimeNotificationIcon = function (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
        >
            <path
                d="M15.0001 4.73438C12.9695 4.73438 10.9846 5.3365 9.29621 6.46462C7.60787 7.59274 6.29197 9.19617 5.51491 11.0722C4.73785 12.9481 4.53453 15.0124 4.93067 17.004C5.32682 18.9955 6.30462 20.8249 7.74044 22.2607C9.17626 23.6965 11.0056 24.6743 12.9971 25.0704C14.9887 25.4666 17.053 25.2633 18.9289 24.4862C20.8049 23.7091 22.4084 22.3932 23.5365 20.7049C24.6646 19.0165 25.2667 17.0316 25.2667 15.001C25.2638 12.2791 24.1811 9.66942 22.2564 7.74469C20.3317 5.81996 17.722 4.73735 15.0001 4.73438ZM19.6738 18.5406C19.5828 18.6559 19.467 18.7492 19.335 18.8135C19.2029 18.8778 19.0581 18.9116 18.9112 18.9122C18.6901 18.9139 18.4754 18.8378 18.3048 18.697L14.3937 15.7637C14.2786 15.6726 14.1854 15.5567 14.1211 15.4247C14.0568 15.2927 14.023 15.1479 14.0223 15.001V8.1566C14.0223 7.89727 14.1253 7.64857 14.3087 7.4652C14.492 7.28183 14.7407 7.17882 15.0001 7.17882C15.2594 7.17882 15.5081 7.28183 15.6915 7.4652C15.8748 7.64857 15.9778 7.89727 15.9778 8.1566V14.5317L19.5175 17.1717C19.6193 17.2501 19.7043 17.3482 19.7674 17.4601C19.8306 17.572 19.8707 17.6954 19.8852 17.8231C19.8998 17.9507 19.8886 18.08 19.8523 18.2033C19.816 18.3265 19.7553 18.4412 19.6738 18.5406Z"
                fill="#FF9212"
            />
            <path
                d="M15.0002 0.335938C12.0994 0.335937 9.26373 1.19612 6.8518 2.80772C4.43988 4.41931 2.56002 6.70993 1.44994 9.38991C0.339851 12.0699 0.049402 15.0189 0.615319 17.8639C1.18124 20.709 2.5781 23.3223 4.62927 25.3735C6.68044 27.4247 9.29379 28.8215 12.1388 29.3874C14.9839 29.9534 17.9329 29.6629 20.6129 28.5528C23.2928 27.4427 25.5835 25.5629 27.1951 23.151C28.8066 20.739 29.6668 17.9034 29.6668 15.0026C29.6621 11.1142 28.1153 7.38644 25.3658 4.63693C22.6163 1.88742 18.8886 0.340668 15.0002 0.335938ZM15.0002 27.29C12.57 27.29 10.1943 26.5693 8.17366 25.2192C6.15301 23.869 4.5781 21.95 3.6481 19.7048C2.7181 17.4596 2.47476 14.989 2.94888 12.6055C3.42299 10.2219 4.59325 8.03253 6.31167 6.31411C8.03009 4.59568 10.2195 3.42542 12.603 2.95131C14.9865 2.4772 17.4571 2.72053 19.7023 3.65053C21.9476 4.58054 23.8666 6.15544 25.2168 8.17609C26.5669 10.1967 27.2876 12.5724 27.2876 15.0026C27.2844 18.2605 25.9888 21.384 23.6852 23.6876C21.3815 25.9913 18.258 27.2868 15.0002 27.29Z"
                fill="#FF9212"
            />
        </svg>
    );
};

export default TimeNotificationIcon;
