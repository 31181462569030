import * as styles from "./ClassesListTabsThemes";
import React, { memo } from "react";
import { createTheme, Tab, Tabs, ThemeProvider } from "@mui/material";
import { observer } from "mobx-react-lite";
import MyClassesStore from "store/MyClassesStore";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";

const ClassesListTabs = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const handleChange = (event, newValue) => {
        MyClassesStore.setPage(newValue);
    };

    const theme = createTheme(styles.Theme);

    return (
        <ThemeProvider theme={theme}>
            <Tabs
                value={MyClassesStore.page}
                sx={styles.ClassesListTabs(medias)}
                textColor="secondary"
                indicatorColor="primary"
                onChange={handleChange}
                TabIndicatorProps={styles.TabIndicatorProps}
            >
                <Tab
                    disableRipple
                    sx={styles.Tab}
                    label={t("main.myClasses.upcoming")}
                />
                <Tab
                    disableRipple
                    sx={styles.Tab}
                    label={t("main.myClasses.previous")}
                />
            </Tabs>
        </ThemeProvider>
    );
});

export default memo(ClassesListTabs);
