import React from "react";

const IconChats = ({ children, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.96512 1C4.09551 1 2.57157 1.47134 1.51971 2.53105C0.467851 3.59076 0 5.12608 0 7.00964V11.4612C0 13.7446 0.447457 15.3304 1.55192 16.304C2.63449 17.2584 4.18595 17.4709 5.96512 17.4709H6.40251C6.4049 17.4712 6.4125 17.4723 6.42529 17.4758C6.44703 17.4817 6.47412 17.4918 6.50254 17.506C6.53103 17.5202 6.55422 17.5353 6.5702 17.5478C6.57668 17.5529 6.58066 17.5565 6.58263 17.5584L6.58372 17.5599C6.58372 17.5599 7.39579 18.3058 8.21622 19.1071C8.53041 19.414 8.79223 19.6454 8.99571 19.8141C9.29457 20.062 9.70543 20.062 10.0043 19.8141C10.2078 19.6454 10.4696 19.414 10.7838 19.1071C11.6042 18.3058 12.4137 17.5633 12.4137 17.5633L12.4147 17.5621C12.4592 17.5039 12.5298 17.4709 12.593 17.4709H13.0349C14.9045 17.4709 16.4284 16.9995 17.4803 15.9398C18.5322 14.8801 19 13.3448 19 11.4612V7.00964C19 5.12608 18.5322 3.59076 17.4803 2.53105C16.4284 1.47134 14.9045 1 13.0349 1H5.96512ZM5.39189 10.8606C6.10091 10.8606 6.67568 10.2816 6.67568 9.56725C6.67568 8.85294 6.10091 8.27389 5.39189 8.27389C4.68288 8.27389 4.10811 8.85294 4.10811 9.56725C4.10811 10.2816 4.68288 10.8606 5.39189 10.8606ZM10.7838 9.56725C10.7838 10.2816 10.209 10.8606 9.5 10.8606C8.79099 10.8606 8.21622 10.2816 8.21622 9.56725C8.21622 8.85294 8.79099 8.27389 9.5 8.27389C10.209 8.27389 10.7838 8.85294 10.7838 9.56725ZM13.6081 10.8606C14.3171 10.8606 14.8919 10.2816 14.8919 9.56725C14.8919 8.85294 14.3171 8.27389 13.6081 8.27389C12.8991 8.27389 12.3243 8.85294 12.3243 9.56725C12.3243 10.2816 12.8991 10.8606 13.6081 10.8606Z"
                fill={props.hovered ? "#8141FF" : "#222"}
            />
        </svg>
    );
};

export default IconChats;
