const RarIconSVG = function (props) {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 34.48V5.52C0 2.48 2.48 0 5.52 0H25.576V8.864C25.576 11.904 27.056 13.368 30.096 13.368H40V34.48C40 37.52 37.52 40 34.48 40H5.52C2.48 40 0 37.52 0 34.48ZM30.0962 11.1677C28.8162 11.1677 27.7762 10.1277 27.7762 8.86369V0.879687L39.0082 11.1677H30.0962Z"
                fill="#BBBBC8"
            />
            <path
                d="M8.92969 27V19H12.0859C12.6901 19 13.2057 19.1081 13.6328 19.3242C14.0625 19.5378 14.3893 19.8411 14.6133 20.2344C14.8398 20.625 14.9531 21.0846 14.9531 21.6133C14.9531 22.1445 14.8385 22.6016 14.6094 22.9844C14.3802 23.3646 14.0482 23.6562 13.6133 23.8594C13.181 24.0625 12.6576 24.1641 12.043 24.1641H9.92969V22.8047H11.7695C12.0924 22.8047 12.3607 22.7604 12.5742 22.6719C12.7878 22.5833 12.9466 22.4505 13.0508 22.2734C13.1576 22.0964 13.2109 21.8763 13.2109 21.6133C13.2109 21.3477 13.1576 21.1237 13.0508 20.9414C12.9466 20.7591 12.7865 20.6211 12.5703 20.5273C12.3568 20.431 12.0872 20.3828 11.7617 20.3828H10.6211V27H8.92969ZM13.25 23.3594L15.2383 27H13.3711L11.4258 23.3594H13.25Z"
                fill="white"
            />
            <path
                d="M18.0773 27H16.2648L19.0266 19H21.2063L23.9641 27H22.1516L20.1477 20.8281H20.0852L18.0773 27ZM17.9641 23.8555H22.2453V25.1758H17.9641V23.8555Z"
                fill="white"
            />
            <path
                d="M25.477 27V19H28.6332C29.2374 19 29.753 19.1081 30.1801 19.3242C30.6098 19.5378 30.9366 19.8411 31.1605 20.2344C31.3871 20.625 31.5004 21.0846 31.5004 21.6133C31.5004 22.1445 31.3858 22.6016 31.1566 22.9844C30.9275 23.3646 30.5954 23.6562 30.1605 23.8594C29.7283 24.0625 29.2048 24.1641 28.5902 24.1641H26.477V22.8047H28.3168C28.6397 22.8047 28.9079 22.7604 29.1215 22.6719C29.335 22.5833 29.4939 22.4505 29.598 22.2734C29.7048 22.0964 29.7582 21.8763 29.7582 21.6133C29.7582 21.3477 29.7048 21.1237 29.598 20.9414C29.4939 20.7591 29.3337 20.6211 29.1176 20.5273C28.904 20.431 28.6345 20.3828 28.309 20.3828H27.1684V27H25.477ZM29.7973 23.3594L31.7855 27H29.9184L27.973 23.3594H29.7973Z"
                fill="white"
            />
        </svg>
    );
};

export default RarIconSVG;
