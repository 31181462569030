//Выберите место занятий
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import SChipsGroup from "components/UI/ChipGroup/ChipGroup";
import SBButtonGroup from "components/UI/ButtonGroup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import store from "store/RequestStore";
import { districts } from "API/dictionaries/const_data";

import StepHiddenButtonWrapper from "components/OrdersBodyBlock/RenewOrder/StepHiddenButtonWrapper";
import Button from "@mui/material/Button";
import CitySelectModal from "components/Header/HeaderAccount/RightPanel/CitySelect/CitySelectModal/CitySelectModal";
import RequestNullAlert from "./RequestNullAlert";
import { Box } from "@mui/material";
import * as theme from "./Step7Themes";

const Step7 = () => {
    const [alert, setAlert] = useState(0);

    const [stepData, setStepData] = useState([]);

    const [currCity, setCurrCity] = React.useState("Москва");

    const [filter, setFilter] = useState("");

    const [openCitySelect, setOpenCitySelect] = React.useState(false);

    const handleClickOpenCitySelect = () => {
        setFilter("");
        setOpenCitySelect(true);
    };

    const handleCloseCitySelect = (value) => {
        setOpenCitySelect(false);
    };

    return (
        <Box>
            <Box
                style={{
                    display: "flex",
                    gap: "10px",
                }}
            >
                <Box sx={theme.YourCityText}>Ваш город:</Box>
                <Button
                    disableRipple
                    onClick={handleClickOpenCitySelect}
                    sx={theme.YourCityButton}
                >
                    <Box sx={theme.CurrentCityText}>{currCity}</Box>
                    <KeyboardArrowDownIcon sx={theme.ArrowDownIcon} />
                </Button>
                <CitySelectModal
                    filter={filter}
                    setFilter={setFilter}
                    setCurrCity={setCurrCity}
                    open={openCitySelect}
                    onClose={handleCloseCitySelect}
                />
            </Box>
            <Box sx={{ marginTop: stepData.length > 0 ? "30px" : "0px" }}>
                <SChipsGroup
                    chipData={stepData}
                    setChipData={setStepData}
                    list={districts}
                    placeholder="Выберите районы/станции метро"
                />
            </Box>
            <StepHiddenButtonWrapper
                submitRenew={() => {
                    if (stepData.length !== 0) {
                        store.setStep(3, stepData);
                    }
                }}
            >
                <SBButtonGroup
                    handleNext={() => {
                        if (stepData.length !== 0) {
                            store.setStep(3, stepData);
                            store.incrementStepIndex();
                        } else {
                            setAlert(1);
                        }
                    }}
                    handleBack={() => store.decrementStepIndex()}
                    activeStep={store.activeStepIndex}
                    steps={store.storage}
                />
                {alert === 1 ? <RequestNullAlert>Чтобы перейти к следующему вопросу, выберите хотя бы один из вариантов</RequestNullAlert> : null}
            </StepHiddenButtonWrapper>
        </Box>
    );
};

export default observer(Step7);
