import React from "react";

const LittleStar = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
    >
        <path
            d="M8.04077 1.06637C8.21432 0.663388 8.78569 0.663389 8.95923 1.06637L10.5456 4.75014C10.618 4.91822 10.7764 5.03333 10.9587 5.05024L14.9524 5.42064C15.3892 5.46116 15.5658 6.00457 15.2362 6.29414L12.2229 8.94124C12.0854 9.06202 12.0249 9.24828 12.0652 9.42681L12.947 13.3395C13.0435 13.7675 12.5812 14.1034 12.204 13.8794L8.75528 11.8316C8.59792 11.7381 8.40208 11.7381 8.24472 11.8316L4.79604 13.8793C4.41877 14.1034 3.95652 13.7675 4.05299 13.3395L4.93484 9.42681C4.97507 9.24827 4.91456 9.06202 4.77706 8.94124L1.76382 6.29414C1.43419 6.00456 1.61075 5.46116 2.04764 5.42064L6.04133 5.05024C6.22356 5.03333 6.38199 4.91822 6.45438 4.75014L8.04077 1.06637Z"
            fill="white"
        />
    </svg>
);

export default LittleStar;
