import React, { useState } from "react";

import { Stack, Typography } from "@mui/material";

import BigTextField from "components/UI/MultirowTextField";

import * as textStyles from "themes/TextThemes";

const MistakesAndRequerements = () => {
    const [text, setText] = useState("");

    return (
        <Stack
            direction="column"
            spacing={2.5}
        >
            <Typography sx={textStyles.Body2Black}>
                Распишите ошибки и недочёты в работе, опишите все
                <br />
                требования по доработке
            </Typography>
            <BigTextField
                placeholderText="Расскажите подробно, что нужно исправить"
                maxLength={1000}
                text={text}
                setText={setText}
            />
        </Stack>
    );
};

export default MistakesAndRequerements;
