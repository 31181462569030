export const Error500BodyBlock = {
    background: "#F7F7F7",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "0px 0px 40px 40px",
    padding: "80px 0 59px 0",
    minHeight: "761px",
};

export const ErrorBlock = {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: "100px",
};

export const NumBLock = {
    display: "flex",
    alignItems: "flex-start",
    gap: "70px",
};

export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "15px",
};

export const ErrorContent = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "50px",
};

export const ErrorContentPlanned = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    maxWidth: "670px",
    gap: "40px",
};
