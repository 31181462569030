const BlackPointIcon = function (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <circle
                cx="8"
                cy="9.5"
                r="2"
                fill="#222222"
            />
        </svg>
    );
};

export default BlackPointIcon;
