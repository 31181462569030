export const AvatarGroup = {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    paddingTop: 0,
    paddingBottom: 0,
};

export const Avatar = { width: "50px", height: "50px" };

export const TutorSearchResult = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    color: "#67677A",
    marginLeft: "20px",
};

export const textFieldSX = {
    width: "495px",
    marginTop: "4px",
    "& .MuiInput-underline:before": { borderBottom: "1px solid #BBBBC8" },
    "& .MuiInput-underline:after": { borderBottomColor: "#6212FF" },
    "& .MuiInputBase-input": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "16px",
        lineHeight: "150%",
        fontWeight: 400,
        textTransform: "none",
    },
};

export const AlmostReadyText = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "32px",
    lineHeight: "140%",
    color: "#222",
    marginTop: "20px",
};

export const AuthText = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    color: "#222",
    marginTop: "20px",
    width: "400px",
};

export const AuthTextMt = { marginTop: "20px" };

export const AlreadyGotAccWrapper = {
    border: "1px solid #DADADF",
    borderRadius: "10px",
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "20px",
    paddingTop: "14px",
    paddingBottom: "15px",
    paddingRight: "20px",
    alignItems: "center",
    marginBottom: "40px",
};

export const AlreadyGotAcc = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    color: "#222",
};

export const StudentAvatarInfoWrapper = {
    border: "1px solid #DADADF",
    borderRadius: "10px",
    marginTop: "20px",
    display: "flex",
    paddingLeft: "20px",
    paddingTop: "14.5px",
    paddingBottom: "14.5px",
    paddingRight: "20px",
    alignItems: "center",
    marginBottom: "40px",
    width: "495px",
};

export const StudentAvatarInfoPos = {
    marginLeft: "12px",
};

export const NameText = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "150%",
    color: "#222",
};

export const PhoneText = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    color: "#9E9EB0",
};
