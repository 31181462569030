export const descriptionBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
};

export const locationBlockContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "5px",
    width: "100%",
    order: 1,
    flexGrow: 0,
};

export const locationLabelStyle = {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#9E9EB0",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const locationTypeTextStyle = {
    color: "var(--text-black, #222)",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
};

export const locationsListContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
};

export const locationTextContainerStyle = {
    display: "flex",
    alignItems: "center",
};

export const locationTextStyle = {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "var(--text-dark-grey, #67677A)",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const scheduleBlockContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    marginTop: "8px",
    gap: "5px",
    width: "100%",
    order: 1,
    flexGrow: 0,
};

export const scheduleLabelStyle = {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#9E9EB0",
    order: 0,
    flexGrow: 0,
};

export const scheduleTextStyle = {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
    color: "#222222",
    order: 0,
    flexGrow: 0,
};

export const themeBlockContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    marginTop: "8px",
    gap: "5px",
    width: "100%",
    order: 1,
    flexGrow: 0,
};

export const themeLabelStyle = {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#9E9EB0",
    order: 0,
    flexGrow: 0,
};

export const themeTextStyle = {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
    color: "#222222",
    order: 0,
    flexGrow: 0,
};

export const deadlineBlockContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    marginTop: "7px",
    gap: "5px",
    width: "100%",
    order: 1,
    flexGrow: 0,
};

export const deadlineLabelStyle = {
    flexWrap: "wrap",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#9E9EB0",
    order: 0,
    flexGrow: 0,
};

export const deadlineTextStyle = {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
    color: "#222222",
    order: 0,
    flexGrow: 0,
};
