const AccordionPoint = function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="5"
            height="40"
            viewBox="0 0 5 40"
            fill="none"
        >
            <circle
                cx="2.5"
                cy="7.5"
                r="2.5"
                fill="#6212FF"
            />
        </svg>
    );
};

export default AccordionPoint;
