import React, { memo, useState } from "react";
import { Box, Chip, TextField, Autocomplete, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import filterStore from "store/FilterStore";
import * as themes from "./ChipGroupThemes";
import { useMedias } from "API/mediasHook";

const SChipsGroup = ({
    chipBorder = "1px solid #BBBBC8",
    chipBgColor = "transparent",
    chipTextColor = "#222",
    crossColor = "#4e4e4e",
    chipData,
    setChipData,
    list,
    placeholder,
    objectKey = false,
    additionalAction = null,
    error,
}) => {
    const medias = useMedias();

    const [inputVal, setInputVal] = useState("");

    const handleDelete = (chipToDelete) => () => {
        const updatedChipData = chipData.filter((chip) => chip !== chipToDelete);

        objectKey ? setChipData(objectKey, updatedChipData) : setChipData(updatedChipData);
    };

    const handleInputChange = (event, newInputValue) => {
        setInputVal(newInputValue);
    };

    const handleChange = (event, newValue) => {
        if (newValue) {
            const updatedChipData = [...chipData, newValue];

            setInputVal("");
            objectKey ? setChipData(objectKey, updatedChipData) : setChipData(updatedChipData);
            additionalAction && additionalAction();
        }
    };

    return (
        <Box>
            <Box sx={themes.InnerContainer}>
                {chipData.map((data, index) => (
                    <Chip
                        key={index}
                        label={data}
                        variant="outlined"
                        style={{ backgroundColor: chipBgColor }}
                        sx={themes.Chip(chipTextColor, chipBorder)}
                        deleteIcon={
                            <IconButton
                                disableRipple
                                sx={{ p: 0 }}
                                size="small"
                            >
                                <ClearIcon
                                    fontSize="small"
                                    sx={{ color: crossColor, fontSize: 14 }}
                                />
                            </IconButton>
                        }
                        onDelete={handleDelete(data)}
                        onChange={() => filterStore.setSubmitButtonFilter(false)}
                    />
                ))}
            </Box>

            <Autocomplete
                inputValue={inputVal}
                onInputChange={handleInputChange}
                onChange={handleChange}
                freeSolo
                options={list.filter((el) => !chipData.includes(el))}
                sx={themes.Autocomplete}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        variant="standard"
                        sx={themes.TextField(error)}
                    />
                )}
                componentsProps={themes.TextFieldComponent}
            />
        </Box>
    );
};

export default memo(SChipsGroup);
