export const NavigationTabs = (medias) => {
    return {
        height: "50px",
        p: 0,
        m: 0,
        border: "none",
        marginLeft: medias.sm ? "0px" : "12px",
        "& .MuiTabs-flexContainer": {
            display: "flex",
            gap: "20px",
        },
        "& button.Mui-selected": { color: "#fff" },
        "& button.Mui-selected:hover": { color: "#fff" },
        "& button:hover": {
            color: "rgba(255, 255, 255, 0.7)",
            outline: "none",
        },
        "& button:active": { color: "rgba(255, 255, 255, 0.7)" },
        " .MuiTab-root": { minWidth: 0 },
    };
};

export const NavigationTab = {
    p: 0,
    textTransform: "none",
    color: "rgba(255, 255, 255, 0.7)",
};

export const NavigationTabIndicator = {
    backgroundColor: "#fff",
    height: "3px",
    borderRadius: "100px",
};

export const HeaderNavigation = (medias) => {
    return {
        background: "#222",
        height: "50px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "0",
        border: "none",
        width: medias.sm ? "100%" : "100%",
    };
};

export const HeaderNavigationContent = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    maxWidth: "1216px",
    padding: "0",
    border: "none",
};
