import { Typography, styled } from "@mui/material";
export const OrderResponsePriceWrapper = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "32px",
};

export const StyledOrderResponsePrice = styled(Typography)({
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
});

export const StyledOrderTitltePrice = styled(Typography)({
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    color: "rgba(34, 34, 34, 1)",
    paddingRight: "8px",
});
