export const TimeScaleDivider = {
    width: "100%",
    background: "rgba(225, 38, 38, 1)",
    height: "2px",
    position: "absolute",
    zIndex: 997,
    "&:focus": {
        outline: "none",
    },
};

export const TimeScaleCircle = {
    position: "absolute",
    width: "12px",
    height: "12px",
    background: "rgba(225, 38, 38, 1)",
    borderRadius: "50%",
    left: -6,
    zIndex: 997,
};
