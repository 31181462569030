import React from "react";

const ThumbUpIconEmpty = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M10.2987 1.56575C10.383 1.48298 10.631 1.25 11 1.25C11.9946 1.25 12.9484 1.64509 13.6517 2.34835C14.3549 3.05161 14.75 4.00544 14.75 5L14.75 8.25L18.66 8.25C19.98 8.25 20.3763 8.29689 20.8087 8.49097C21.1724 8.6542 21.4962 8.89455 21.7578 9.19538C22.0338 9.51275 22.2759 10.0118 22.3803 10.3713C22.4807 10.7173 22.7662 11.9819 22.3981 13.441L21.744 18.5944C21.7418 18.6121 21.7389 18.6296 21.7354 18.6471C21.4831 19.9087 21.2462 20.6608 20.9609 21.1629C20.6936 21.6334 20.382 21.8694 20.1636 22.0349L20.1636 22.0349C20.1281 22.0618 20.095 22.0869 20.065 22.1108C19.7811 22.3368 19.4092 22.4562 19.0944 22.5296C18.7581 22.608 18.3812 22.6565 18.0133 22.6878C17.2818 22.75 16.5061 22.75 16.0098 22.75L16.0097 22.75L4 22.75C3.27066 22.75 2.57118 22.4603 2.05546 21.9445C1.53973 21.4288 1.25 20.7293 1.25 20L1.25 13C1.25 12.2707 1.53973 11.5712 2.05546 11.0555C2.57118 10.5397 3.27066 10.25 4 10.25L6.47321 10.25C6.55972 10.0119 6.6743 9.69873 6.80989 9.33301C7.12401 8.48583 7.55184 7.35436 8.00533 6.22131C8.45739 5.09185 8.94074 3.9462 9.36548 3.07697C9.57615 2.64585 9.78378 2.25902 9.9753 1.97121C10.0693 1.82992 10.1778 1.68436 10.2987 1.56575Z"
                fill="white"
            />
        </svg>
    );
};

export default ThumbUpIconEmpty;
