export const ArrowButtonSheduleStyled = {
    minWidth: "36px",
    maxWidth: "36px",
    width: "36px",
    height: "36px",
    borderRadius: "10px",
    border: "1px solid var(--Text-Dark-Grey, #67677A)",
    fontSize: "20px",
    color: "rgba(34, 34, 34, 1)",
    padding: "4px 1px",
    "&:hover": {
        color: "rgba(34, 34, 34, 1)",
        background: "none",
    },
    "&:disabled": {
        color: "rgba(187, 187, 200, 1)",
        borderColor: "rgba(187, 187, 200, 1)",
    },
};
