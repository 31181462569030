import PersonalDataStep from "components/TeacherQuestionnaireBodyBlock/Steps/PersonalDataStep/PersonalDataStep";
import TaughtSubjectsStep from "components/TeacherQuestionnaireBodyBlock/Steps/TaughtSubjectsStep/TaughtSubjectsStep";
import ClassesAimsStep from "components/TeacherQuestionnaireBodyBlock/Steps/ClassesAimsStep/ClassesAimsStep";
import ClassesPlaceStep from "components/TeacherQuestionnaireBodyBlock/Steps/ClassesPlaceStep/ClassesPlaceStep";
import TeacherEducationStep from "components/TeacherQuestionnaireBodyBlock/Steps/TeacherEducationStep/TeacherEducationStep";
import TeacherExperienceStep from "components/TeacherQuestionnaireBodyBlock/Steps/TeacherExperienceStep/TeacherExperienceStep";
import TeacherCertificatesStep from "components/TeacherQuestionnaireBodyBlock/Steps/TeacherCertificatesStep/TeacherCertificatesStep";
import ClassesScheduleStep from "components/TeacherQuestionnaireBodyBlock/Steps/ClassesScheduleStep/ClassesScheduleStep";
import ClassesPriceStep from "components/TeacherQuestionnaireBodyBlock/Steps/ClassesPriceStep/ClassesPriceStep";
import UploadingPhotoStep from "components/TeacherQuestionnaireBodyBlock/Steps/UploadingPhotoStep/UploadingPhotoStep";
import { memo } from "react";

const TeacherStepSwitcher = ({ currentStep, setCurrentStep }) => {
    switch (currentStep) {
        case 0:
            return (
                <PersonalDataStep
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                />
            );
        case 1:
            return (
                <TaughtSubjectsStep
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                />
            );
        case 2:
            return (
                <ClassesAimsStep
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                />
            );
        case 3:
            return (
                <ClassesPlaceStep
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                />
            );
        case 4:
            return (
                <TeacherEducationStep
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                />
            );
        case 5:
            return (
                <TeacherExperienceStep
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                />
            );
        case 6:
            return (
                <TeacherCertificatesStep
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                />
            );
        case 7:
            return (
                <ClassesScheduleStep
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                />
            );
        case 8:
            return (
                <ClassesPriceStep
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                />
            );
        case 9:
            return (
                <UploadingPhotoStep
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                />
            );
        default:
            return <span>Step {currentStep}</span>;
    }
};

export default memo(TeacherStepSwitcher);
