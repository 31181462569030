import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "@mui/material";
import * as themes from "./FooterAreaThemes";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";

const FooterArea = observer(({ children, ...props }) => {
    return (
        <Box sx={themes.footerAreaStyle}>
            <Box sx={themes.avatarContainerStyle}>
                <ThemeProvider theme={themes.AvatarTheme}>
                    <Avatar src={props.value.AvatarSrc}></Avatar>
                </ThemeProvider>
                <Typography sx={themes.studentTextStyle}>{props.value.StudentFIO}</Typography>
            </Box>
            <Typography sx={themes.lastSeenTextStyle}>{props.value.LastSeen}</Typography>
        </Box>
    );
});

export default memo(FooterArea);
