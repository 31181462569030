import React, { useState } from "react";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import * as themes from "./CustomDatePickerThemes";
import * as textThemes from "themes/TextThemes";
import LeftCalendarIcon from "assets/CommonAssets/SVG/LeftCalendarIcon";
import RightCalendarIcon from "assets/CommonAssets/SVG/RightCalendarIcon";
import { Box, Typography, IconButton, TableBody, TableRow, TableCell, Table, TableHead } from "@mui/material";
import CalendarIcon from "assets/CommonAssets/SVG/CalendarIcon";
import { TextField, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import { useMedias } from "API/mediasHook";

const Datepicker = ({ selectedDate, setSelectedDate, minDate, noMin, styles, opened, inputFontSize = "16px", inputWidth = "130px", borderMode = false }) => {
    const medias = useMedias();
    //borderMode создает границы с радиусом 8px вокруг инпута

    const { t, i18n } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    const [currentMonth, setCurrentMonth] = useState(minDate.getMonth());

    const toggleCalendar = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const id = open ? "simple-popper" : undefined;

    const handleDateClick = (date) => {
        setSelectedDate(date);
        if (opened === undefined) toggleCalendar();
    };

    const handleClose = (event) => {
        setAnchorEl(false);
    };

    const handlePrevMonth = () => {
        const newMonth = currentMonth - 1;

        setCurrentMonth(newMonth);
        if (newMonth === -1) {
            setCurrentMonth(11);
            setCurrentYear(currentYear - 1);
        }
    };

    const handleNextMonth = () => {
        const newMonth = currentMonth + 1;

        setCurrentMonth(newMonth);
        if (newMonth === 12) {
            setCurrentMonth(0);
            setCurrentYear(currentYear + 1);
        }
    };

    const getDaysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const getMonthData = (year, month) => {
        const today = new Date();

        const firstDay = new Date(year, month, 1);

        const lastDay = new Date(year, month + 1, 0);

        const daysInMonth = getDaysInMonth(year, month);

        const prevMonthDays = (firstDay.getDay() + 6) % 7; // Set Monday as the first day of the week

        const nextMonthDays = 6 - lastDay.getDay();

        const days = [];

        // Days from previous month
        for (let i = prevMonthDays - 1; i >= 0; i--) {
            days.push({
                day: new Date(year, month, -i),
                currentMonth: false,
                selectable: false,
            });
        }

        // Days from current month
        for (let i = 1; i <= daysInMonth; i++) {
            const currentDate = new Date(year, month, i);

            days.push({
                day: currentDate,
                currentMonth: true,
                selectable: noMin ? true : currentDate >= minDate, // Disable days before today
            });
        }

        // Days from next month
        for (let i = 1; i <= nextMonthDays; i++) {
            days.push({
                day: new Date(year, month + 1, i),
                currentMonth: false,
                selectable: false,
            });
        }

        return days;
    };

    const russianWeekdays = [
        "main.daysOfWeek.mondayShortUpper",
        "main.daysOfWeek.tuesdayShortUpper",
        "main.daysOfWeek.wednesdayShortUpper",
        "main.daysOfWeek.thursdayShortUpper",
        "main.daysOfWeek.fridayShortUpper",
        "main.daysOfWeek.saturdayShortUpper",
        "main.daysOfWeek.sundayShortUpper",
    ];

    const russianMonths = [
        "main.months.januaryUpper",
        "main.months.februaryUpper",
        "main.months.marchUpper",
        "main.months.aprilUpper",
        "main.months.mayUpper",
        "main.months.juneUpper",
        "main.months.julyUpper",
        "main.months.augustUpper",
        "main.months.septemberUpper",
        "main.months.octoberUpper",
        "main.months.novemberUpper",
        "main.months.decemberUpper",
    ];

    const russianMonthsInput = [
        "main.months.atJanuary",
        "main.months.atFebruary",
        "main.months.atMarch",
        "main.months.atApril",
        "main.months.atMay",
        "main.months.atJune",
        "main.months.atJuly",
        "main.months.atAugust",
        "main.months.atSeptember",
        "main.months.atOctober",
        "main.months.atNovember",
        "main.months.atDecember",
    ];

    const monthData = getMonthData(currentYear, currentMonth);

    const formatDate = (date) => {
        try {
            const day = date.getDate();

            const monthName = russianMonthsInput[date.getMonth()];

            const dayOfWeek = russianWeekdays[date.getDay()];

            return `${day} ${t(monthName)}, ${t(dayOfWeek)}`;
        } catch (e) {
            return date;
        }
    };

    if (opened)
        return (
            <Box>
                <Box
                    sx={themes.TextFieldContainer(borderMode, anchorEl, medias)}
                    style={styles}
                >
                    <TextField
                        size="small"
                        style={{ width: "100%" }}
                        InputProps={{
                            sx: themes.TextField,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CalendarIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={selectedDate ? formatDate(selectedDate) : ""}
                    />
                </Box>
                <Box sx={themes.Calendar("100%", medias)}>
                    <Box sx={themes.MonthContainer}>
                        <IconButton
                            disableRipple
                            sx={themes.ArrowButton}
                            onClick={handlePrevMonth}
                        >
                            <LeftCalendarIcon />
                        </IconButton>
                        <Typography sx={textThemes.Button16Purple}>
                            {t(russianMonths[currentMonth])} {currentYear}
                        </Typography>
                        <IconButton
                            disableRipple
                            sx={themes.ArrowButton}
                            onClick={handleNextMonth}
                        >
                            <RightCalendarIcon />
                        </IconButton>
                    </Box>
                    <Table sx={{ marginBottom: "12px" }}>
                        <TableHead>
                            <TableRow>
                                {russianWeekdays.map((day, index) => (
                                    <TableCell
                                        key={index}
                                        sx={[
                                            day === "main.daysOfWeek.sundayShortUpper" || day === "main.daysOfWeek.saturdayShortUpper" ? textThemes.Body2Red : textThemes.Body2DarkGrey,
                                            { border: "none" },
                                        ]}
                                    >
                                        <Box sx={{ width: "100%", textAlign: "center" }}>{t(day)}</Box>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <>
                            {Array.from({ length: Math.ceil(monthData.length / 7) }).map((_, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {monthData.slice(rowIndex * 7, rowIndex * 7 + 7).map((dayData, index) => (
                                        <TableCell
                                            key={index}
                                            sx={{
                                                padding: "4px 12px",
                                                border: "none",
                                            }}
                                            onClick={() => dayData.selectable && handleDateClick(dayData.day)}
                                        >
                                            <Box
                                                sx={themes.DayButton(
                                                    dayData.currentMonth,
                                                    !dayData.selectable,
                                                    selectedDate && selectedDate.getTime() > dayData.day.getTime() && dayData.selectable,
                                                    selectedDate && selectedDate.getTime() === dayData.day.getTime() && dayData.selectable,
                                                )}
                                            >
                                                {dayData.day.getDate()}
                                            </Box>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </>
                    </Table>
                </Box>
            </Box>
        );
    else
        return (
            <ClickAwayListener onClickAway={handleClose}>
                <Box>
                    <Box
                        sx={themes.TextFieldContainer(borderMode, anchorEl, medias)}
                        style={styles}
                        onClick={toggleCalendar}
                    >
                        <CalendarIcon />
                        <input
                            style={themes.DateInput(inputFontSize, inputWidth)}
                            value={selectedDate ? formatDate(selectedDate) : ""}
                        />
                    </Box>
                    <Popper
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        placement={"bottom-start"}
                    >
                        <Box sx={themes.Calendar("280px", medias)}>
                            <Box sx={themes.YearContainer}>
                                <Typography sx={textThemes.Button16Purple}>{currentYear}</Typography>
                            </Box>
                            <Box sx={themes.MonthContainer}>
                                <IconButton
                                    disableRipple
                                    sx={themes.ArrowButton}
                                    onClick={handlePrevMonth}
                                >
                                    <LeftCalendarIcon />
                                </IconButton>
                                <Typography sx={textThemes.Button16Purple}>{t(russianMonths[currentMonth])}</Typography>
                                <IconButton
                                    disableRipple
                                    sx={themes.ArrowButton}
                                    onClick={handleNextMonth}
                                >
                                    <RightCalendarIcon />
                                </IconButton>
                            </Box>
                            <Box sx={themes.WeekDaysContainer}>
                                {russianWeekdays.map((day, index) => (
                                    <Typography
                                        sx={day === "main.daysOfWeek.sundayShortUpper" || day === "main.daysOfWeek.saturdayShortUpper" ? textThemes.Body2Red : textThemes.Body2DarkGrey}
                                        key={index}
                                    >
                                        {t(day)}
                                    </Typography>
                                ))}
                            </Box>
                            <Box sx={themes.DaysContainer}>
                                {monthData.map((dayData, index) => (
                                    <Box
                                        sx={themes.DayButton(dayData.currentMonth, !dayData.selectable, false, selectedDate && selectedDate.getTime() === dayData.day.getTime() && dayData.selectable)}
                                        key={index}
                                        onClick={() => dayData.selectable && handleDateClick(dayData.day)}
                                    >
                                        {dayData.day.getDate()}
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Popper>
                </Box>
            </ClickAwayListener>
        );
};

export default memo(Datepicker);
