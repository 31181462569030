import { Button, Typography, styled } from "@mui/material";

export const RenewStyledTopText = styled(Typography)({
    fontSize: "14px",
    color: "rgba(158, 158, 176, 1)",
    fontFamily: '"Inter", sans-serif',
});

export const RenewStyledBottText = styled(Typography)({
    fontSize: "16px",
    fontFamily: '"Inter", sans-serif',
});

export const RenewStyledListButton = styled(Button)({
    minWidth: "0px",
    color: "rgba(158, 158, 176, 1)",
    backgroundColor: "none",
    textTransform: "none",
    transition: "opacity 0.5s",

    "&:hover": {
        color: "rgba(158, 158, 176, 1)",
        background: "none",
        opacity: 0.8,
        transition: "opacity 0.5s",
    },
    "& .MuiTouchRipple-root": {
        color: "rgba(158, 158, 176, 1)",
    },
});
