import React, { memo } from "react";
import * as themes from "./SortFilterThemes";
import SingleChoiceLightFilter from "components/UI/SingleChoiceLightFilter";
import CatalogStore from "store/CatalogStore";
import { observer } from "mobx-react-lite";
import { useMedias } from "../../../../API/mediasHook";

const SortFilter = observer(({ children, ...props }) => {
    const processingSort = (event, value) => {
        CatalogStore.SetOptionsSort(value);
    };

    const medias = useMedias();

    return (
        <SingleChoiceLightFilter
            propOption={CatalogStore.optionSort}
            propOptions={CatalogStore.optionsSort}
            valueToShow={"title"}
            valueToBind={"ID"}
            processingChange={processingSort}
            themeWrapper={themes.FiltersSortTheme(medias)}
            placeholder={"Сортировка"}
            clearOnBlur={false}
            disableClearable={true}
            variant={"outlined"}
            freeSolo={false}
        ></SingleChoiceLightFilter>
    );
});

export default memo(SortFilter);
