import * as React from "react";
import { memo } from "react";
import { observer } from "mobx-react-lite";

import { Typography, Box } from "@mui/material";

import * as themes from "./ImageAttachmentThemes";

import { useGetChatImageAttachment } from "hooks/useGetChatImageAttachment";

const ImageAttachment = observer(({ children, ...props }) => {
    const image = useGetChatImageAttachment(props.value.link);

    return (
        <>
            <img
                style={themes.imageAttachmentStyle(props.elementsInRow, props.finalInRow)}
                src={`${image}`}
                alt={`attachment_id-${props.value.link}`}
            />
            {props.finalInRow && (
                <Box sx={themes.blurContainerStyle}>
                    <Typography sx={themes.moreToShowStyle}>+{props.moreToShow}</Typography>
                </Box>
            )}
        </>
    );
});

export default memo(ImageAttachment);
