import React from "react";
import { Close } from "@mui/icons-material";
import { Backdrop, Box, Fade, IconButton, Modal, ThemeProvider } from "@mui/material";
import * as themes from "./OrdersModalStyled";
import orderResponseStore from "store/OrderResponseStore";
import { observer } from "mobx-react-lite";

const style = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "40px  40px 30px 40px",
    borderRadius: "20px",
    bgcolor: "background.paper",
    boxShadow: 24,
};

const OrdersModal = observer(({ children, handleClick }) => {
    const handleOpenModal = (e) => {
        e.preventDefault();
        orderResponseStore.setToggleModal();
        handleClick();
    };

    return (
        <Box>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={orderResponseStore.toggleModal}
                onClose={handleOpenModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={orderResponseStore.toggleModal}>
                    <Box sx={style}>
                        {children}
                        <ThemeProvider theme={themes.OrderModalCloseIcon}>
                            <IconButton onClick={handleOpenModal}>
                                <Close></Close>
                            </IconButton>
                        </ThemeProvider>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
});

export default OrdersModal;
