import React, { memo, useState } from "react";
import { Typography, Button, TextField, Accordion, AccordionSummary, AccordionDetails, Box, IconButton, Snackbar } from "@mui/material";
import AccordionExpandIcon from "assets/PaymentAssets/SVG/AccordionExpandIcon";
import * as styles from "./EnterPromocodeThemes";
import * as textStyles from "themes/TextThemes";
import PaymentStore from "store/PaymentStore";
import { InputAdornment } from "@mui/material";
import TextFieldEndIcon from "assets/PaymentAssets/SVG/TextFieldEndIcon";
import { observer } from "mobx-react-lite";
import ExclamationIcon from "assets/PaymentAssets/SVG/ExclamationIcon";
import ExclamationIconGrey from "assets/PaymentAssets/SVG/ExclamationIconGrey";
import Fade from "@mui/material/Fade";
import { CloseSnackBarIcon } from "assets/MyClassesAssets/SVG/CloseSnackBarIcon";

const EnterPromocode = observer(({ children, ...props }) => {
    const [error, setError] = useState(false);

    const [isDisabled, setIsDisabled] = useState(false);

    const [openSnackBar, setOpenSnackBar] = React.useState(false);

    const handleApplyCode = () => {
        if (PaymentStore.promocodes.includes(PaymentStore.temporaryPromocode)) {
            setError(false);
            setIsDisabled(true);
            PaymentStore.setPromocodeActivated(true);
            PaymentStore.reducePromocodeEnterAttempts();
            setOpenSnackBar(true);
        } else {
            setError(true);
            PaymentStore.setPromocodeActivated(false);
        }
    };

    const handleRemoveCode = () => {
        PaymentStore.setTemporaryPromocode("");
        PaymentStore.setPromocodeActivated(false);
        setError(false);
        setIsDisabled(false);
    };

    return (
        <Accordion sx={styles.Accordion}>
            <AccordionSummary
                expandIcon={<AccordionExpandIcon />}
                sx={styles.AccordionSummary}
            >
                <Typography sx={textStyles.Body2Black}>Есть промокод?</Typography>
            </AccordionSummary>
            <AccordionDetails sx={styles.AccordionDetails}>
                {PaymentStore.paymentInfo.cashbackVariant === "add" ? (
                    <Box sx={styles.TextFieldAndAttempts}>
                        <TextField
                            placeholder="Введите код"
                            sx={styles.TextField}
                            variant="standard"
                            fullWidth
                            value={PaymentStore.temporaryPromocode}
                            onChange={(e) => PaymentStore.setTemporaryPromocode(e.target.value)}
                            disabled={isDisabled}
                            error={error && PaymentStore.temporaryPromocode !== null && PaymentStore.temporaryPromocode !== ""}
                            helperText={error && PaymentStore.temporaryPromocode !== null && PaymentStore.temporaryPromocode !== "" ? "Неверный код" : ""}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disableRipple
                                            onClick={handleRemoveCode}
                                            disabled={PaymentStore.promocodeEnterAttempts === 0}
                                        >
                                            <TextFieldEndIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {(PaymentStore.promocodeEnterAttempts === 2 || PaymentStore.promocodeEnterAttempts === 1) && isDisabled === false ? (
                            <Box sx={styles.PromocodeEnterAttempts}>
                                <ExclamationIcon />
                                <Typography sx={textStyles.Body2Black}>{`Еще ${PaymentStore.promocodeEnterAttempts} ${PaymentStore.promocodeEnterAttempts === 2 ? "попытки" : "попытка"}`}</Typography>
                            </Box>
                        ) : null}
                        {PaymentStore.promocodeEnterAttempts === 0 ? (
                            <Box sx={styles.PromocodeEnterAttempts}>
                                <ExclamationIconGrey />
                                <Typography sx={textStyles.Body2Grey}>{`Вы использовали все попытки ввода кода`}</Typography>
                            </Box>
                        ) : null}
                    </Box>
                ) : (
                    <Box sx={styles.PromocodeEnterAttempts}>
                        <ExclamationIcon />
                        <Typography sx={textStyles.Body2Black}>Нельзя применить промокод при списании баллов</Typography>
                    </Box>
                )}
                <Button
                    sx={styles.ButtonApply}
                    onClick={handleApplyCode}
                    disabled={PaymentStore.promocodeActivated || PaymentStore.paymentInfo.cashbackVariant === "subtract"}
                    disableRipple
                    variant="contained"
                >
                    <Typography sx={textStyles.Button14White}>Применить промокод</Typography>
                </Button>
            </AccordionDetails>
            <Snackbar
                ContentProps={styles.ContentProps}
                TransitionComponent={Fade}
                autoHideDuration={5000}
                open={openSnackBar}
                onClose={() => setOpenSnackBar(false)}
                message={"Промокод на скидку 10% активирован"}
                action={
                    <IconButton
                        disableRipple
                        onClick={() => setOpenSnackBar(false)}
                    >
                        <CloseSnackBarIcon />
                    </IconButton>
                }
            />
        </Accordion>
    );
});

export default memo(EnterPromocode);
