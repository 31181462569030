import React, { memo, useRef } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import * as styles from "./StudentSettingsThemes";
import { Typography } from "@mui/material";
import * as textStyles from "themes/TextThemes";
import SettingsTabs from "./SettingsTabs/SettingsTabs";
import Profile from "./Profile/Profile";
import LoginDetails from "./LoginDetails/LoginDetails";
import Payment from "./Payment/Payment";
import Notifications from "./Notifications/Notifications";
import Telegram from "./Telegram/Telegram";
import DeleteAccount from "./DeleteAccount/DeleteAccount";
import { useMedias } from "API/mediasHook";

const StudentSettings = () => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const profile = useRef(null);

    const payment = useRef(null);

    const notification = useRef(null);

    const telegram = useRef(null);

    return (
        <Box sx={styles.Settings(medias)}>
            <Box sx={styles.SettingsContent}>
                <Typography sx={textStyles.HeadlineH3Black}>{t("main.settings.settings")}</Typography>
                <Box sx={styles.SettingsBlocks}>
                    <SettingsTabs
                        profile={profile}
                        payment={payment}
                        notification={notification}
                        telegram={telegram}
                    />
                    <Box
                        sx={styles.ProfileAndLoginDetails(medias)}
                        ref={profile}
                    >
                        <Profile />
                        <LoginDetails />
                    </Box>
                    <Box ref={payment}>
                        <Payment />
                    </Box>
                    <Box ref={notification}>
                        <Notifications />
                    </Box>
                    <Box ref={telegram}>
                        <Telegram id="bjdbs4848kal34lfa" />
                    </Box>
                    <DeleteAccount />
                </Box>
            </Box>
        </Box>
    );
};

export default memo(StudentSettings);
