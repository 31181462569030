import React, { memo } from "react";
import Box from "@mui/material/Box";
import * as styles from "./InfoForTutorThemes";
import * as textStyles from "themes/TextThemes";
import { Typography } from "@mui/material";
import { InfoPointIcon } from "assets/Authorization/SVG/InfoPointIcon";
const InfoForTutor = () => {
    return (
        <Box sx={styles.InfoForTutor}>
            <Box sx={styles.Title}>
                <Typography sx={textStyles.HeadlineH41White}>Стань репетитором на StudyBuddy</Typography>
            </Box>
            <Box sx={styles.PointsContainer}>
                <Box sx={styles.InfoPoint}>
                    <Box>
                        <InfoPointIcon />
                    </Box>
                    <Typography sx={textStyles.Body2White}>Помогай школьникам и студентам на индивидуальных занятиях и с учебными работами</Typography>
                </Box>
                <Box sx={styles.InfoPoint}>
                    <Box>
                        <InfoPointIcon />
                    </Box>
                    <Typography sx={textStyles.Body2White}>Работай по индивидуальному графику и в удобное время</Typography>
                </Box>
                <Box sx={styles.InfoPoint}>
                    <Box>
                        <InfoPointIcon />
                    </Box>
                    <Typography sx={textStyles.Body2White}>Зарабатывай</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default memo(InfoForTutor);
