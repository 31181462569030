import React from "react";
import StyledWhiteBox from "components/DiplomaChats/StyledComponents/StyledWhiteBox";
import WithoutFiles from "./WithoutFiles";
import WithFiles from "./WithFiles/WithFiles";
import { FILES } from "components/DiplomaChats/store/files";

const Files = () => {
    return (
        <StyledWhiteBox
            padding="20px 30px"
            width="641px"
            flexDirection="column"
        >
            {!FILES.length ? <WithoutFiles /> : <WithFiles files={FILES} />}
        </StyledWhiteBox>
    );
};

export default Files;
