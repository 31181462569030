const CheckBoxChecked = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <g clipPath="url(#clip0_12500_52595)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 0.5C0.895431 0.5 0 1.39543 0 2.5V14.5C0 15.6046 0.895431 16.5 2 16.5H14C15.1046 16.5 16 15.6046 16 14.5V2.5C16 1.39543 15.1046 0.5 14 0.5H2ZM12.7311 6.18232C13.1079 5.77857 13.0861 5.14578 12.6823 4.76894C12.2786 4.39211 11.6458 4.41393 11.2689 4.81768L6.35278 10.085L4.68394 8.52046C4.28103 8.14273 3.64819 8.16315 3.27046 8.56606C2.89273 8.96897 2.91315 9.60181 3.31606 9.97954L5.71606 12.2295C5.90983 12.4112 6.1679 12.5083 6.43335 12.4994C6.69881 12.4906 6.94983 12.3765 7.13106 12.1823L12.7311 6.18232Z"
                    fill="url(#paint0_linear_12500_52595)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_12500_52595"
                    x1="13.8"
                    y1="2.9"
                    x2="-0.392103"
                    y2="6.20175"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        offset="0.0869624"
                        stopColor="#04C83B"
                    />
                    <stop
                        offset="0.894315"
                        stopColor="#12AAFF"
                    />
                </linearGradient>
                <clipPath id="clip0_12500_52595">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default CheckBoxChecked;
