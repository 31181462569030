import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import CatalogStore from "store/CatalogStore";
import { ThemeProvider } from "@mui/material/styles";
import * as themes from "./LessonsTeacherAgeThemes";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import FreeSingleChoiceFilter from "components/UI/FreeSingleChoiceFilter";
import { useMedias } from "../../../API/mediasHook";

const LessonsTeacherAge = observer(({ children, ...props }) => {
    const handleChange = () => {
        CatalogStore.ToggleLessonsTeacherAgeExpanded();
    };

    const processingLessonsTeacherAge = (event, value) => {
        CatalogStore.SetLessonsTeacherAge(value);
    };

    const medias = useMedias();

    return (
        <ThemeProvider theme={themes.LessonsTeacherAgeAccordionTheme(medias)}>
            <Accordion
                expanded={CatalogStore.lessonsTeacherAgeExpanded}
                onChange={handleChange}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography sx={themes.headerLessonsTeacherAgeStyle}>Возраст репетитора</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FreeSingleChoiceFilter
                        propOption={CatalogStore.optionLessonsTeacherAge}
                        propOptions={CatalogStore.optionsLessonsTeacherAge}
                        valueToShow={"LessonsTeacherAge"}
                        valueToBind={"ID"}
                        processingChange={processingLessonsTeacherAge}
                        themeWrapper={themes.FiltersLessonsTeacherAgeTheme}
                    ></FreeSingleChoiceFilter>
                </AccordionDetails>
            </Accordion>
        </ThemeProvider>
    );
});

export default memo(LessonsTeacherAge);
