import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./TutorFeedbacksBodyBlockThemes";
import * as textThemes from "themes/TextThemes";
import { Box, Button, ThemeProvider, Typography } from "@mui/material";
import CustomPagination from "components/UI/CustomPagination";
import StarRating from "assets/PAAssets/SVG/StarRating";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { useNavigate } from "react-router-dom";
import BackAccountButton from "assets/CommonAssets/SVG/BackAccountButton";
import SButton from "components/UI/SButton";
import { useTranslation } from "react-i18next";
import FeedbacksTabs from "./FeedbacksTabs/FeedbacksTabs";
import FeedbackStore from "store/FeedbackStore";
import ReviewCard from "./ReviewCard/ReviewCard";
import EmptyReviewsList from "assets/CommonAssets/SVG/EmptyReviewsList";
import { useMedias } from "API/mediasHook";

const TutorFeedbacksBodyBlock = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const handlePagination = (value) => {
        FeedbackStore.setCurrentPage(value);
        FeedbackStore.setReviewsStart(4 * (value - 1));
        FeedbackStore.setReviewsEnd(4 * (value - 1) + 4);
        window.scrollTo(0, 0);
        if (4 * (value - 1) + 4 + 1 > FeedbackStore.sortedFeedbacks().filter((item) => item.rating >= FeedbackStore.minimumShowedRating && item.rating <= FeedbackStore.maximumShowedRating).length) {
            FeedbackStore.setDisabledShowMore(true);
        } else FeedbackStore.setDisabledShowMore(false);
    };

    const handleShowMore = () => {
        let end1 = Math.min(FeedbackStore.reviewsEnd + 1, FeedbackStore.sortedFeedbacks().length);

        FeedbackStore.setReviewsEnd(end1);

        if (Math.min(FeedbackStore.reviewsEnd + 1, FeedbackStore.sortedFeedbacks().length) === FeedbackStore.sortedFeedbacks().length) {
            FeedbackStore.setDisabledShowMore(true);
        } else FeedbackStore.setDisabledShowMore(false);
    };

    const handleBackClick = () => {
        navigate("/account");
    };

    const getReviewTitle = (number) => {
        if (number === 0) {
            return "teacherAccount.rating.noReview";
        } else if (number === 1) {
            return "teacherAccount.rating.oneReview";
        } else if (number > 1 && number < 5) {
            return "teacherAccount.rating.fewReviews";
        } else if (number > 5) {
            return "teacherAccount.rating.manyReviews";
        }
    };

    const getNewTitle = (number) => {
        if (number === 1) {
            return "teacherAccount.rating.oneNewReview";
        } else if (number > 1) {
            return "teacherAccount.rating.manyNewReviews";
        }
    };

    const getRatedClassTitle = (number) => {
        if (number === 0) {
            return "teacherAccount.rating.manyRatedClass";
        } else if (number === 1) {
            return "teacherAccount.rating.oneRatedClass";
        } else if (number > 1 && number < 5) {
            return "teacherAccount.rating.fewRatedClass";
        } else if (number > 5) {
            return "teacherAccount.rating.manyRatedClass";
        }
    };

    return (
        <ScrollToTop>
            <Box sx={themes.Feedbacks(medias)}>
                <Box sx={themes.FeedbacksContent}>
                    <Box sx={themes.Title}>
                        <SButton
                            variant="icon"
                            onClick={handleBackClick}
                        >
                            <BackAccountButton />
                        </SButton>
                        <Typography sx={medias.sm ? textThemes.HeadlineH3Black : textThemes.HeadlineH41Black}>{t("teacherAccount.rating.reviewsTitle")}</Typography>
                    </Box>
                    <Box sx={themes.FeedbacksRatingContainer}>
                        <Box sx={themes.ReviewContainer(medias)}>
                            {!medias.sm && (
                                <Box>
                                    <Box sx={themes.RatingTopBlock(medias)}>
                                        <Typography sx={textThemes.HeadlineH5Black}>{t("teacherAccount.rating.yourRating")}</Typography>
                                        <Box sx={themes.RatingStarBlock}>
                                            <Box>
                                                <StarRating />
                                            </Box>
                                            <Typography sx={textThemes.HeadlineH41Black}>{FeedbackStore.rating === 0 || FeedbackStore.rating === null ? "-" : FeedbackStore.rating}</Typography>
                                        </Box>
                                    </Box>
                                    <Typography sx={textThemes.Body2Black}>{`${FeedbackStore.reviewsNow} ${t(getReviewTitle(FeedbackStore.reviewsNow))}`}</Typography>
                                </Box>
                            )}
                            <FeedbacksTabs />
                            <Box sx={themes.ReviewsOuterBlock}>
                                <Box sx={themes.ReviewsBlock}>
                                    {FeedbackStore.sortedFeedbacks().filter((item) => item.rating >= FeedbackStore.minimumShowedRating && item.rating <= FeedbackStore.maximumShowedRating).length >
                                    0 ? (
                                        FeedbackStore.sortedFeedbacks()
                                            .filter((item) => item.rating >= FeedbackStore.minimumShowedRating && item.rating <= FeedbackStore.maximumShowedRating)
                                            .map((item) => {
                                                return (
                                                    <Box
                                                        sx={themes.ReviewCardAndSeparator}
                                                        key={item.id}
                                                    >
                                                        <ReviewCard item={item} />
                                                        <Box sx={themes.Separator}></Box>
                                                    </Box>
                                                );
                                            })
                                            .slice(FeedbackStore.reviewsStart, FeedbackStore.reviewsEnd)
                                    ) : (
                                        <Box sx={themes.NoReviews}>
                                            <EmptyReviewsList />
                                            <Box sx={themes.NoReviewsText}>
                                                <Typography
                                                    sx={textThemes.HeadlineH5Black}
                                                >{`${t(FeedbackStore.minimumShowedRating === FeedbackStore.tabConfig[1].minRating ? "teacherAccount.rating.noPositiveReviewsTitle" : FeedbackStore.maximumShowedRating === FeedbackStore.tabConfig[2].maxRating ? "teacherAccount.rating.noNegativeReviewsTitle" : "teacherAccount.rating.noReviewsTitle")}`}</Typography>
                                                <Typography sx={textThemes.Body1Black}>
                                                    {`${t(FeedbackStore.minimumShowedRating === FeedbackStore.tabConfig[1].minRating ? "teacherAccount.rating.noPositiveReviewsText" : FeedbackStore.maximumShowedRating === FeedbackStore.tabConfig[2].maxRating ? "teacherAccount.rating.noNegativeReviewsText" : "teacherAccount.rating.noReviewsText")}`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                                {FeedbackStore.sortedFeedbacks().filter((item) => item.rating >= FeedbackStore.minimumShowedRating && item.rating <= FeedbackStore.maximumShowedRating).length > 0 && (
                                    <Box sx={themes.MoreAndPagination}>
                                        <Box sx={themes.More}>
                                            <ThemeProvider theme={themes.teacherShowMoreTheme}>
                                                <Button
                                                    disabled={FeedbackStore.disabledShowMore}
                                                    disableRipple
                                                    variant="text"
                                                    onClick={handleShowMore}
                                                >
                                                    {t("teacherAccount.rating.showMore")}
                                                </Button>
                                            </ThemeProvider>
                                        </Box>
                                        <CustomPagination
                                            paginationTheme={themes.paginationTheme}
                                            paginationContainerStyle={themes.paginationContainerStyle}
                                            pagesContainerStyle={themes.pagesContainerStyle}
                                            currentPage={FeedbackStore.currentPage}
                                            pagesTotal={FeedbackStore.totalPage}
                                            processingChange={handlePagination}
                                        ></CustomPagination>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        {medias.sm && (
                            <Box sx={themes.RatingsContainer}>
                                <Box sx={themes.Rating}>
                                    <Box sx={themes.RatingTopBlock(medias)}>
                                        <Typography sx={textThemes.HeadlineH5Black}>{t("teacherAccount.rating.yourRating")}</Typography>
                                        <Box sx={themes.RatingStarBlock}>
                                            <Box>
                                                <StarRating />
                                            </Box>
                                            <Typography sx={textThemes.HeadlineH3Black}>{FeedbackStore.rating === 0 || FeedbackStore.rating === null ? "-" : FeedbackStore.rating}</Typography>
                                        </Box>
                                    </Box>
                                    <Typography sx={textThemes.Body2Black}>{`${FeedbackStore.classesRated} ${t(getRatedClassTitle(FeedbackStore.classesRated))}`}</Typography>
                                </Box>
                                <Box sx={themes.Rating}>
                                    <Typography sx={textThemes.Button16BoldPurple}>{`${FeedbackStore.reviewsNow} ${t(getReviewTitle(FeedbackStore.reviewsNow))}`}</Typography>
                                    {FeedbackStore.newReviewsThisWeek > 0 && (
                                        <Typography sx={textThemes.Caption1Purple}>
                                            {`+${FeedbackStore.newReviewsThisWeek}${t(getNewTitle(FeedbackStore.newReviewsThisWeek))}`}
                                            <span style={textThemes.Caption1Black}>{`${t(getReviewTitle(FeedbackStore.newReviewsThisWeek))}${t("teacherAccount.rating.thisWeek")}`}</span>
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </ScrollToTop>
    );
});

export default memo(TutorFeedbacksBodyBlock);
