import { TextField, styled } from "@mui/material";

const StyledTextField = styled(TextField)({
    "& .MuiInputBase-root": {
        padding: "0px",
        "&.Mui-focused fieldset": {
            borderColor: "#6212FF",
            borderWidth: "1px",
        },
        "&:hover fieldset": {
            borderColor: "#6212FF",
            borderWidth: "1px",
        },
    },
    "& fieldset": {
        border: "1px solid var(--grey-light-grey, #BBBBC8)",
        borderRadius: "10px",
    },
    "& .MuiOutlinedInput-root": { padding: "12px 16px" },
});

export default StyledTextField;
