//По какому предмету работа?
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import SBButtonGroup from "components/UI/ButtonGroup";
import store from "store/RequestStore";

import TextField from "@mui/material/TextField";
import StepHiddenButtonWrapper from "components/OrdersBodyBlock/RenewOrder/StepHiddenButtonWrapper";
import filterStore from "store/FilterStore";
import RequestNullAlert from "./RequestNullAlert";
import * as theme from "./Step16Themes";
const Step16 = () => {
    const [alert, setAlert] = useState(0);

    const [stepData, setStepData] = useState(store.storage[store.activeStepIndex].substeps[store.subStepIndex].value);

    const handleLessonName = () => {
        if (stepData) {
            store.setStep(9, stepData);
            store.setStepValue(9, stepData);
        } else {
            setAlert(1);
        }
    };

    const handleIncrement = () => {
        if (stepData) {
            store.incrementStepIndex();
        } else {
            setAlert(1);
        }
    };

    const handleNext = () => {
        handleLessonName();
        handleIncrement();
    };

    return (
        <>
            <TextField
                placeholder={"Предмет"}
                variant="standard"
                sx={theme.TextField}
                onChange={(event) => {
                    setStepData(event.target.value);
                    filterStore.setSubmitButtonFilter(false);
                }}
                value={stepData}
            />
            <StepHiddenButtonWrapper submitRenew={handleLessonName}>
                <SBButtonGroup
                    handleNext={handleNext}
                    handleBack={() => store.decrementStepIndex()}
                    activeStep={store.activeStepIndex}
                    subStep={store.subStepIndex}
                    steps={store.storage}
                />
                {alert === 1 ? <RequestNullAlert>Чтобы перейти к следующему вопросу, заполните это поле</RequestNullAlert> : null}
            </StepHiddenButtonWrapper>
        </>
    );
};

export default observer(Step16);
