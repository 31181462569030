import React from "react";
import { observer } from "mobx-react-lite";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";

import * as styles from "./styles";

const Progress = ({ data = [] }) => {
    const value = DiplomaStore.tutorProgress.length ? data.filter((item) => item.done).length / data.length : 0;

    return (
        <Box sx={styles.progress.mainContainer}>
            <CircularProgress
                variant="determinate"
                disableShrink
                sx={styles.progress.undoneProgress}
                size={100}
                thickness={4}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                value={Math.round(value * 100)}
                sx={styles.progress.doneProgress}
                size={100}
                thickness={4}
            />

            <Box sx={styles.progress.textContainer}>
                <Typography variant="title2">{`${Math.round(value * 100)}%`}</Typography>
                <Typography variant="standard1">выполнено</Typography>
            </Box>
        </Box>
    );
};

export default observer(Progress);
