import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import hat from "assets/ScheduleAssets/scheduleUnion.svg";
import star from "assets/ScheduleAssets/scheduleStar.svg";
import * as themes from "./ClassCardStyled";
import * as textThemes from "themes/TextThemes";
import ClassStatus from "./ClassStatus/ClassStatus";
import EmptyAvatar from "assets/TeacherClassesAssets/PNG/EmptyAvatar.png";
import { memo } from "react";

const ClassCard = ({ children, ...props }) => {
    const statusStyles = {
        completed: themes.lessonCompleted,
        pending: themes.lessonUpcoming,
        rescheduled: themes.lessonUpcoming,
        cancelled: themes.lessonCanceled,
        upcoming: themes.lessonUpcoming,
    };

    const styles = statusStyles[props.class.classStatus];

    const formatTime = (timeString) => {
        const date = new Date(timeString);

        const currentHour = date.getHours().toString().padStart(2, "0");

        const nextHour = (date.getHours() + 1).toString().padStart(2, "0");

        return `${currentHour}:00-${nextHour}:00`;
    };

    const formattedTime = formatTime(props.class.timeStart);

    return (
        <Box sx={[themes.lessonCard, styles]}>
            <Box sx={themes.ClassCardTopContentWrapper}>
                <Avatar
                    sx={themes.classCardAvatar}
                    src={props.class.studentAvatar ? props.class.studentAvatar : EmptyAvatar}
                />
                <Box sx={themes.NameBlock}>
                    <Typography sx={{ ...textThemes.Body2Black, whiteSpace: "nowrap" }}>{props.class.studentName}</Typography>
                    {props.class.studentType && <img src={props.class.studentType === "new" ? hat : star} />}
                </Box>
            </Box>
            {props.class.classStatus === "upcoming" || props.class.classStatus === "rescheduled" || props.class.classStatus === "pending" ? (
                <Typography sx={[textThemes.Caption1Black, themes.timeTextPos]}>{formattedTime}</Typography>
            ) : (
                <>{props.class.classStatus && <ClassStatus status={props.class.classStatus} />}</>
            )}
        </Box>
    );
};

export default memo(ClassCard);
