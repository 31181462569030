export const classStatus = {
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    borderRadius: "16px",
    padding: "4px 7px",
};

export const classStatusCompleted = {
    backgroundColor: "rgba(25, 178, 12, 0.1)",
};

export const classStatusCanceled = {
    backgroundColor: "rgba(225, 38, 38, 0.1)",
};

export const classStatusTextColor = {
    fontSize: "20px",
    marginRight: "3px",
};

export const classStatusTextColorCompleted = {
    color: "rgba(25, 178, 12, 1)",
};

export const classStatusTextColorCanceled = {
    color: "rgba(225, 38, 38, 1)",
};
