import { memo } from "react";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import AuthorizationBodyBlock from "components/AuthorizationBodyBlock/AuthorizationBodyBlock";

const AuthorizationPage = () => {
    return (
        <ScrollToTop>
            <AuthorizationBodyBlock />
        </ScrollToTop>
    );
};

export default memo(AuthorizationPage);
