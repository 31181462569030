export const DATE_FORMAT = "HH:mm DD.MM.YYYY";

export const MIN_PERCENTAGE = 0;

export const MAX_PERCENTAGE = 100;

export const BYTES_IN_MB = 1048576;

export const HTTP_CODES = {
    //INFORMATIONAL
    CONTINUE: 100,
    SWITCHING_PROTOCOLS: 101,
    PROCESSING: 102,
    EARLY_HINTS: 103,
    //SUCCESS
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NON_AUTHORITATIVE_INFORMATION: 203,
    NO_CONTENT: 204,
    RESET_CONTENT: 205,
    PARTIAL_CONTENT: 206,
    MULTI_STATUS: 207,
    ALREADY_REPORTED: 208,
    IM_USED: 226,
    //REDIRECTION
    MULTIPLE_CHOICES: 300,
    MOVED_PERMANENTLY: 301,
    MOVED_TEMPORARILY: 302,
    SEE_OTHER: 303,
    NOT_MODIFIED: 304,
    USE_PROXY: 305,
    TEMPORARY_REDIRECT: 307,
    PERMANENT_REDIRECT: 308,
    //CLIENT_ERROR
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED: 402,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    NOT_ACCEPTABLE: 406,
    PROXY_AUTHENTICATION_REQUIRED: 407,
    REQUEST_TIMEOUT: 408,
    CONFLICT: 409,
    GONE: 410,
    LENGTH_REQUIRED: 411,
    PRECONDITION_FAILED: 412,
    PAYLOAD_TOO_LARGE: 413,
    URI_TOO_LONG: 414,
    UNSUPPORTED_MEDIA_TYPE: 415,
    RANGE_NOT_SATISFIABLE: 416,
    EXPECTATION_FAILED: 417,
    IM_A_TEAPOT: 418,
    AUTHENTICATION_TIMEOUT: 419,
    MISDIRECTED_REQUEST: 421,
    UNPROCESSABLE_ENTITY: 422,
    LOCKED: 423,
    FAILED_DEPENDENCY: 424,
    TOO_EARLY: 425,
    UPGRADE_REQUIRED: 426,
    PRECONDITION_REQUIRED: 428,
    TOO_MANY_REQUESTS: 429,
    REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
    RETRY_WITH: 449,
    UNAVAILABLE_FOR_LEGAL_REASONS: 451,
    CLIENT_CLOSED_REQUEST: 499,
    //SERVER_ERROR
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504,
    HTTP_VERSION_NOT_SUPPORTED: 505,
    VARIANT_ALSO_NEGOTIATES: 506,
    INSUFFICIENT_STORAGE: 507,
    LOOP_DETECTED: 508,
    BANDWIDTH_LIMIT_EXCEEDED: 509,
    NOT_EXTENDED: 510,
    NETWORK_AUTHENTICATION_REQUIRED: 511,
    UNKNOWN_ERROR: 520,
    WEB_SERVER_IS_DOWN: 521,
    CONNECTION_TIMED_OUT: 522,
    ORIGIN_IS_UNREACHABLE: 523,
    A_TIMEOUT_OCCURRED: 524,
    SSL_HANDSHAKE_FAILED: 525,
    INVALID_SSL_CERTIFICATE: 526,
};

export const HTTP_METHODS = {
    GET: "get",
    HEAD: "head",
    POST: "post",
    PUT: "put",
    DELETE: "delete",
    CONNECT: "connect",
    OPTIONS: "options",
    TRACE: "trace",
    PATCH: "patch",
};

export const codesToSuppressGlobal = [
    { code: HTTP_CODES.CONTINUE, shouldBeSuppressed: false },
    { code: HTTP_CODES.SWITCHING_PROTOCOLS, shouldBeSuppressed: false },
    { code: HTTP_CODES.PROCESSING, shouldBeSuppressed: false },
    { code: HTTP_CODES.EARLY_HINTS, shouldBeSuppressed: false },

    { code: HTTP_CODES.OK, shouldBeSuppressed: false },
    { code: HTTP_CODES.CREATED, shouldBeSuppressed: false },
    { code: HTTP_CODES.ACCEPTED, shouldBeSuppressed: false },
    {
        code: HTTP_CODES.NON_AUTHORITATIVE_INFORMATION,
        shouldBeSuppressed: false,
    },
    { code: HTTP_CODES.NO_CONTENT, shouldBeSuppressed: false },
    { code: HTTP_CODES.RESET_CONTENT, shouldBeSuppressed: false },
    { code: HTTP_CODES.PARTIAL_CONTENT, shouldBeSuppressed: false },
    { code: HTTP_CODES.MULTI_STATUS, shouldBeSuppressed: false },
    { code: HTTP_CODES.ALREADY_REPORTED, shouldBeSuppressed: false },
    { code: HTTP_CODES.IM_USED, shouldBeSuppressed: false },

    { code: HTTP_CODES.MULTIPLE_CHOICES, shouldBeSuppressed: false },
    { code: HTTP_CODES.MOVED_PERMANENTLY, shouldBeSuppressed: false },
    { code: HTTP_CODES.MOVED_TEMPORARILY, shouldBeSuppressed: false },
    { code: HTTP_CODES.SEE_OTHER, shouldBeSuppressed: false },
    { code: HTTP_CODES.NOT_MODIFIED, shouldBeSuppressed: false },
    { code: HTTP_CODES.USE_PROXY, shouldBeSuppressed: false },
    { code: HTTP_CODES.TEMPORARY_REDIRECT, shouldBeSuppressed: false },
    { code: HTTP_CODES.PERMANENT_REDIRECT, shouldBeSuppressed: false },

    { code: HTTP_CODES.BAD_REQUEST, shouldBeSuppressed: false },
    { code: HTTP_CODES.UNAUTHORIZED, shouldBeSuppressed: false },
    { code: HTTP_CODES.PAYMENT_REQUIRED, shouldBeSuppressed: false },
    { code: HTTP_CODES.FORBIDDEN, shouldBeSuppressed: false },
    { code: HTTP_CODES.NOT_FOUND, shouldBeSuppressed: false },
    { code: HTTP_CODES.METHOD_NOT_ALLOWED, shouldBeSuppressed: false },
    { code: HTTP_CODES.NOT_ACCEPTABLE, shouldBeSuppressed: false },
    {
        code: HTTP_CODES.PROXY_AUTHENTICATION_REQUIRED,
        shouldBeSuppressed: false,
    },
    { code: HTTP_CODES.REQUEST_TIMEOUT, shouldBeSuppressed: false },
    { code: HTTP_CODES.CONFLICT, shouldBeSuppressed: false },
    { code: HTTP_CODES.GONE, shouldBeSuppressed: false },
    { code: HTTP_CODES.LENGTH_REQUIRED, shouldBeSuppressed: false },
    { code: HTTP_CODES.PRECONDITION_FAILED, shouldBeSuppressed: false },
    { code: HTTP_CODES.PAYLOAD_TOO_LARGE, shouldBeSuppressed: false },
    { code: HTTP_CODES.URI_TOO_LONG, shouldBeSuppressed: false },
    { code: HTTP_CODES.UNSUPPORTED_MEDIA_TYPE, shouldBeSuppressed: false },
    { code: HTTP_CODES.RANGE_NOT_SATISFIABLE, shouldBeSuppressed: false },
    { code: HTTP_CODES.EXPECTATION_FAILED, shouldBeSuppressed: false },
    { code: HTTP_CODES.IM_A_TEAPOT, shouldBeSuppressed: false },
    { code: HTTP_CODES.AUTHENTICATION_TIMEOUT, shouldBeSuppressed: false },
    { code: HTTP_CODES.MISDIRECTED_REQUEST, shouldBeSuppressed: false },
    { code: HTTP_CODES.UNPROCESSABLE_ENTITY, shouldBeSuppressed: false },
    { code: HTTP_CODES.LOCKED, shouldBeSuppressed: false },
    { code: HTTP_CODES.FAILED_DEPENDENCY, shouldBeSuppressed: false },
    { code: HTTP_CODES.TOO_EARLY, shouldBeSuppressed: false },
    { code: HTTP_CODES.UPGRADE_REQUIRED, shouldBeSuppressed: false },
    { code: HTTP_CODES.PRECONDITION_REQUIRED, shouldBeSuppressed: false },
    { code: HTTP_CODES.TOO_MANY_REQUESTS, shouldBeSuppressed: false },
    {
        code: HTTP_CODES.REQUEST_HEADER_FIELDS_TOO_LARGE,
        shouldBeSuppressed: false,
    },
    {
        code: HTTP_CODES.UNAVAILABLE_FOR_LEGAL_REASONS,
        shouldBeSuppressed: false,
    },
    { code: HTTP_CODES.CLIENT_CLOSED_REQUEST, shouldBeSuppressed: false },

    { code: HTTP_CODES.INTERNAL_SERVER_ERROR, shouldBeSuppressed: false },
    { code: HTTP_CODES.NOT_IMPLEMENTED, shouldBeSuppressed: false },
    { code: HTTP_CODES.BAD_GATEWAY, shouldBeSuppressed: false },
    { code: HTTP_CODES.SERVICE_UNAVAILABLE, shouldBeSuppressed: false },
    { code: HTTP_CODES.GATEWAY_TIMEOUT, shouldBeSuppressed: false },
    { code: HTTP_CODES.HTTP_VERSION_NOT_SUPPORTED, shouldBeSuppressed: false },
    { code: HTTP_CODES.VARIANT_ALSO_NEGOTIATES, shouldBeSuppressed: false },
    { code: HTTP_CODES.INSUFFICIENT_STORAGE, shouldBeSuppressed: false },
    { code: HTTP_CODES.LOOP_DETECTED, shouldBeSuppressed: false },
    { code: HTTP_CODES.BANDWIDTH_LIMIT_EXCEEDED, shouldBeSuppressed: false },
    { code: HTTP_CODES.NOT_EXTENDED, shouldBeSuppressed: false },
    {
        code: HTTP_CODES.NETWORK_AUTHENTICATION_REQUIRED,
        shouldBeSuppressed: false,
    },
    { code: HTTP_CODES.UNKNOWN_ERROR, shouldBeSuppressed: false },
    { code: HTTP_CODES.WEB_SERVER_IS_DOWN, shouldBeSuppressed: false },
    { code: HTTP_CODES.CONNECTION_TIMED_OUT, shouldBeSuppressed: false },
    { code: HTTP_CODES.ORIGIN_IS_UNREACHABLE, shouldBeSuppressed: false },
    { code: HTTP_CODES.A_TIMEOUT_OCCURRED, shouldBeSuppressed: false },
    { code: HTTP_CODES.SSL_HANDSHAKE_FAILED, shouldBeSuppressed: false },
    { code: HTTP_CODES.INVALID_SSL_CERTIFICATE, shouldBeSuppressed: false },
];

export const methodsToSuppressGlobal = [
    { method: HTTP_METHODS.GET, shouldBeSuppressed: false },
    { method: HTTP_METHODS.HEAD, shouldBeSuppressed: false },
    { method: HTTP_METHODS.POST, shouldBeSuppressed: false },
    { method: HTTP_METHODS.PUT, shouldBeSuppressed: false },
    { method: HTTP_METHODS.DELETE, shouldBeSuppressed: false },
    { method: HTTP_METHODS.CONNECT, shouldBeSuppressed: false },
    { method: HTTP_METHODS.OPTIONS, shouldBeSuppressed: false },
    { method: HTTP_METHODS.TRACE, shouldBeSuppressed: false },
    { method: HTTP_METHODS.PATCH, shouldBeSuppressed: false },
];
