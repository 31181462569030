import { createTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

export const DialogContainerTheme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: "rgba(0, 0, 0, 0)!important",
                    boxShadow: "none!important",
                    maxWidth: "fit-content",
                },
            },
        },
    },
});

export const dialogContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "10px",
};

export const cardContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
    padding: "40px 40px 30px 40px",
    flexDirection: "column",
    gap: "30px",
    borderRadius: "20px",
    background: "var(--main-white, #FFF)",
    width: 655,
    height: "fit-content",
};

export const headerBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    width: "100%",
};

export const headerTextStyle = {
    color: "var(--text-black, #222)",
    textAlign: "center",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
};

export const infoMessageContainerStyle = {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    gap: "8px",
};

export const WarningIconTheme = createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                // Name of the slot
                root: {
                    width: 20,
                    height: 20,
                    color: "#FF9212",
                },
            },
        },
    },
});

export const infoMessageStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const callTimeStyle = {
    color: "var(--Text-Grey, #9E9EB0)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const contentBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "20px",
    width: "100%",
};

export const dividerStyle = (marginTop, marginBottom) => {
    return {
        width: "100%",
        marginTop: marginTop,
        marginBottom: marginBottom,
    };
};

export const ratingContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "center",
    width: "100%",
};

export const RatingTheme = createTheme({
    components: {
        MuiRating: {
            styleOverrides: {
                root: {
                    gap: "1px",
                    "& *": {
                        transform: "none!important",
                    },
                    svg: {
                        width: "36px!important",
                        height: "36px!important",
                    },
                },
            },
        },
    },
});

export const feedbackMessageContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    alignItems: "center",
    width: "100%",
};

export const IconContainerStyle = {
    display: "flex",
    minWidth: "24px",
    minHeight: "24px",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
};

export const FeedbackMessageTheme = (mode) =>
    createTheme({
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": !mode ? "#9E9EB0" : "var(--Secondary-Red, #E12626)",
                        "--TextField-brandBorderHoverColor": !mode ? "#6212FF" : "var(--Secondary-Red, #E12626)",
                        "--TextField-brandBorderFocusedColor": !mode ? "#6212FF" : "var(--Secondary-Red, #E12626)",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                        "& .Mui-focused": {
                            background: "#FFFFFF",
                        },
                        width: "100%",
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&:before, &:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&:before": {
                            borderBottom: "1px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "1px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "1px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },

            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        display: "flex",
                        margin: 0,
                    },
                },
            },

            MuiInputBase: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        boxSizing: "border-box",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "4px",
                        width: "100%",
                        flexGrow: 0,
                        "&.MuiInputBase-root": {
                            border: "1px!important solid #9E9EB0",
                            borderRadius: "12px",
                            padding: "12px 16px 12px 16px",
                        },
                        ".MuiInputBase-input": {
                            "::placeholder": {
                                fontWeight: "400!important",
                            },
                            width: "-webkit-fill-available!important",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "150%",
                            color: "#222222",
                            flex: "none",
                            order: 0,
                            flexGrow: 0,
                        },
                    },
                },
            },
        },
    });

export const helperContainerStyle = (mode) => {
    return {
        display: "flex",
        justifyContent: mode ? "space-between" : "flex-end",
        width: "100%",
    };
};

export const helperCounterContainerStyle = {
    display: "flex",
};

export const currLengthTextStyle = (length) => {
    return {
        color: length ? "var(--text-black, #222)" : "var(--text-grey, #9E9EB0)",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "150%",
    };
};

export const currLengthTextMaxStyle = {
    color: "var(--Text-Red, #E12626)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const maxLengthTextStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const ModalActionButtonTheme = (mode) =>
    createTheme({
        components: {
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        display: "flex",
                        padding: "10px 20px 11px 20px!important",
                        width: 160,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        borderRadius: "42px!important",
                        background: mode ? "var(--main-purple, #6212FF)" : "var(--grey-light-grey, #BBBBC8);",
                        "&:hover": {
                            background: "#6212FF!important",
                            borderColor: "#6212FF!important",
                            ".MuiTypography-root": {
                                color: "white!important",
                            },
                        },
                        "&:active": {
                            background: "#6212FF!important",
                            borderColor: "#6212FF!important",
                            ".MuiTypography-root": {
                                color: "white!important",
                            },
                        },
                    },
                },
            },
        },
    });

export const buttonTextStyle = {
    textTransform: "none",
    color: "var(--text-white, #FFF)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
};

export const actionsBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
};

export const actionsContainerStyle = {
    display: "flex",
    justifyContent: "flex-end",
};

export const CloseIconTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid #9E9EB0",
                    borderRadius: "50%",
                    background: "transparent",
                    width: "24px",
                    height: "24px",
                    "&:hover": {
                        background: "#transparent!important",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "#transparent!important",
                        borderColor: "#6212FF",
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    padding: 0,
                    color: "#FFFFFF",
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    "&:hover": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                    "&:active": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                },
            },
        },
    },
});
