import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import * as React from "react";
import TextField from "@mui/material/TextField";
import filterStore from "store/FilterStore";
import Box from "@mui/material/Box";

const SRadioGroup = function ({ changefunc, list, value, anotherVar, textVar, setTextVar }) {
    const radioLabel = function (valueLabel = value, textVarLabel = textVar, setTextVarLabel = setTextVar) {
        if (valueLabel === "Другое") {
            return (
                <TextField
                    value={textVarLabel}
                    onChange={(event) => {
                        setTextVarLabel(event.target.value);
                    }}
                    placeholder="Другое"
                    variant="standard"
                    sx={{
                        "& .MuiInput-underline:before": {
                            borderBottom: "1px solid #BBBBC8",
                        },
                        "& .MuiInput-underline:after": { borderBottomColor: "#6212FF" },
                        "& .MuiInputBase-input": {
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "16px",
                            lineHeight: "150%",
                            fontWeight: 400,
                            textTransform: "none",
                        },
                    }}
                />
            );
        } else {
            return "Другое";
        }
    };

    if (anotherVar === false || anotherVar === undefined) {
        return (
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={value}
                    name="radio-buttons-group"
                    onChange={(event, newInputValue) => {
                        changefunc(newInputValue);
                        filterStore.setSubmitButtonFilter(false);
                    }}
                    sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "16px",
                        lineHeight: "150%",
                        fontWeight: 400,
                        textTransform: "none",
                        color: "#222",
                        pb: "0px",
                    }}
                >
                    {list.map((item, index) => {
                        return (
                            <Box key={index}>
                                <FormControlLabel
                                    value={item}
                                    control={
                                        <Radio
                                            sx={{
                                                color: "#6212FF",
                                                "&.Mui-checked": {
                                                    color: "#6212FF",
                                                },
                                                "& .MuiSvgIcon-root": {
                                                    height: 20,
                                                    width: 20,
                                                },
                                                "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                                                    stroke: "#6212FF",
                                                    strokeWidth: 7,
                                                },
                                            }}
                                        />
                                    }
                                    label={item}
                                />
                            </Box>
                        );
                    })}
                </RadioGroup>
            </FormControl>
        );
    } else {
        return (
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={value}
                    name="radio-buttons-group"
                    onChange={(event, newInputValue) => {
                        changefunc(newInputValue);
                    }}
                    sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "16px",
                        lineHeight: "150%",
                        fontWeight: 400,
                        textTransform: "none",
                        color: "#222",
                        pb: "0px",
                    }}
                >
                    {list.map((item, index) => {
                        return (
                            <Box key={index}>
                                <FormControlLabel
                                    value={item}
                                    control={
                                        <Radio
                                            sx={{
                                                color: "#6212FF",
                                                "&.Mui-checked": {
                                                    color: "#6212FF",
                                                },
                                                "& .MuiSvgIcon-root": {
                                                    height: 20,
                                                    width: 20,
                                                },
                                                "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                                                    stroke: "#6212FF",
                                                    strokeWidth: 7,
                                                },
                                            }}
                                        />
                                    }
                                    label={item}
                                />
                            </Box>
                        );
                    })}

                    <FormControlLabel
                        value="Другое"
                        control={
                            <Radio
                                sx={{
                                    color: "#6212FF",
                                    "&.Mui-checked": {
                                        color: "#6212FF",
                                    },
                                    "& .MuiSvgIcon-root": {
                                        height: 20,
                                        width: 20,
                                    },
                                    "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                                        stroke: "#6212FF",
                                        strokeWidth: 7,
                                    },
                                }}
                            />
                        }
                        label={radioLabel()}
                        sx={{ color: "#9E9EB0" }}
                    />
                </RadioGroup>
            </FormControl>
        );
    }
};

export default SRadioGroup;
