import { memo } from "react";
import * as themes from "./InviteStudentsThemes";
import * as textThemes from "themes/TextThemes";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SButton from "components/UI/SButton";
import { useNavigate } from "react-router-dom";
import { useMedias } from "API/mediasHook";

const InviteStudents = ({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const handleClickDetails = () => {
        navigate("/referral");
    };

    return (
        <Box sx={themes.InviteStudents}>
            <Box sx={themes.TopBlock}>
                <Typography sx={textThemes.HeadlineH5Black}>{t("teacherAccount.miniWidgets.inviteStudents")}</Typography>
                <Typography sx={textThemes.Body2Black}>{t("teacherAccount.miniWidgets.inviteAndGetPoints")}</Typography>
            </Box>
            <SButton
                style={themes.Button(medias)}
                variant="arrow"
                padding="10px 20px 11px 20px"
                onClick={handleClickDetails}
            >
                {t("teacherAccount.miniWidgets.details")}
            </SButton>
        </Box>
    );
};

export default memo(InviteStudents);
