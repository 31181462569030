import * as React from "react";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

const PopperMenu = ({ children, content, transformOrigin = "center top", classes, zIndex, paperSx, open, setOpen, placement = "bottom", disabled = false, width, withOutWidth }) => {
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        if (disabled === false) {
            setOpen((prevOpen) => !prevOpen);
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === "Escape") {
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);

    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <Stack
            direction="row"
            sx={{ width: withOutWidth ? "inherit" : "100%" }}
        >
            <Box sx={{ width: "100%" }}>
                <Box
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? "composition-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className={classes}
                >
                    {children}
                </Box>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement={placement}
                    transition
                    disablePortal
                    sx={{
                        zIndex: zIndex,
                    }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper sx={paperSx}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        sx={{
                                            p: 0,
                                            m: 0,
                                            border: "none",
                                            boxShadow: "none",
                                        }}
                                        autoFocusItem={open}
                                        id="composition-menu"
                                        aria-labelledby="composition-button"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        {content}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Box>
        </Stack>
    );
};

export default PopperMenu;
