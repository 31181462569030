import React, { memo } from "react";
import * as theme from "./PopularPartThemes";
import { Chip } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import MainPageStore from "store/MainPageStore";
import { observer } from "mobx-react-lite";
import { useMedias } from "API/mediasHook";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const PopularPart = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const handleClick = (query, e) => {
        MainPageStore.ProcessTagSearch(query.Query);
    };

    return (
        <Box style={theme.filtersPopular(medias)}>
            {MainPageStore.popularQueries.map((query) => (
                <ThemeProvider
                    theme={theme.TagTheme}
                    key={query.ID}
                >
                    <Chip
                        key={query.ID}
                        label={t(query.Query)}
                        variant="outlined"
                        size="small"
                        disableRipple
                        sx={theme.textPopular}
                        onClick={handleClick.bind(this, query)}
                    ></Chip>
                </ThemeProvider>
            ))}
        </Box>
    );
});

export default memo(PopularPart);
