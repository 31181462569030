import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import React from "react";
import QuestionnaireButtons from "components/TeacherQuestionnaireBodyBlock/Components/QuestionnaireButtons";
import store from "store/TeacherQuestionnaireStore";
import { observer } from "mobx-react-lite";
import STextField from "components/UI/STextField";
import SDropDown from "components/UI/SDropDown";
import IOSSwitch from "components/UI/IOSSwitch";
import FormControlLabel from "@mui/material/FormControlLabel";
import SDragAndDrop from "components/TeacherQuestionnaireBodyBlock/Components/SDragNDrop";
import DeleteStatementIcon from "assets/TeacherQuestionnaireAssets/DeleteStatementIcon";
import STooltip from "components/TeacherQuestionnaireBodyBlock/Components/STooltip";
import * as themes from "./TeacherCertificatesStepThemes";
import { Box, Typography } from "@mui/material";
import { memo } from "react";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useMedias } from "API/mediasHook";

const TeacherCertificatesStep = observer(({ currentStep, setCurrentStep }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const disabledContinue = store.teacher.certificates.some((item) => !item.certificate || !item.photo.imageSrc || item.receiptYear === "");

    const setCertificatesData = (index, key, value, additionalKey = null) => {
        if (additionalKey === null) {
            store.teacher.certificates[index][key] = value;
        } else {
            store.teacher.certificates[index][key][additionalKey] = value;
        }
    };

    const handleClickAdd = () => {
        store.teacher.certificates.push({
            id: store.teacher.certificates.length + 1,
            certificate: "",
            receiptYear: "",
            photo: { imageSize: "", imageName: "", imageSrc: "" },
            showToStudents: "",
        });
    };

    return (
        <ScrollToTop>
            <Box sx={themes.TeacherPersonalDataStepWider}>
                <Box sx={themes.TeacherPersonalData}>
                    <Box sx={themes.TeacherQuestionnaireTopBlock}>
                        <Typography sx={textThemes.HeadlineH41Black}>{t("TeacherQuestionnaire.certificatesTitle")}</Typography>
                        <Box sx={themes.TeacherQuestionnaireSubTitle}>
                            <Typography sx={textThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.ifYouHaveAchievements")}</Typography>
                        </Box>
                    </Box>
                    <Box sx={themes.AdditionalBlock}>
                        {store.teacher.certificates.map((certificateExp, index) => {
                            return (
                                <Box
                                    key={index}
                                    sx={themes.TeacherQuestionnaireEducationOuterContainerWithDelete}
                                >
                                    <Box sx={themes.TeacherQuestionnaireEducationOuterContainerWithoutDelete(medias)}>
                                        <Box sx={themes.TeacherQuestionnaireEducationOuterContainer}>
                                            <Box sx={themes.TeacherQuestionnaireDropDownEdit(medias)}>
                                                <Box sx={themes.TeacherQuestionnaireDropDownTitle}>
                                                    <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.certificateTitle")}</Typography>
                                                </Box>
                                                <Box sx={themes.TeacherQuestionnaireAutocompleteContainer(medias)}>
                                                    <STextField
                                                        width={medias.sm ? "424px" : "100%"}
                                                        placeholder={t("TeacherQuestionnaire.enterCertificateTitle")}
                                                        value={store.teacher.certificates[index].certificate}
                                                        changeFunction={(value) => setCertificatesData(index, "certificate", value)}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Box sx={themes.TeacherQuestionnaireDropDownEdit(medias)}>
                                                    <Box sx={themes.TeacherQuestionnaireDropDownTitle}>
                                                        <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.yearOfReceipt")}</Typography>
                                                    </Box>
                                                    <Box sx={themes.TeacherQuestionnaireAutocompleteContainer(medias)}>
                                                        <SDropDown
                                                            tr={true}
                                                            placeholder={t("main.year")}
                                                            firstValueGrey={false}
                                                            dropDownChoice={store.teacher.certificates[index].receiptYear}
                                                            setDropDownChoice={(value) => setCertificatesData(index, "receiptYear", value)}
                                                            dropDownList={store.yearsList}
                                                            buttonWidth={medias.sm ? "424px" : "100%"}
                                                            paperWidth={medias.sm ? "424px" : "100%"}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box sx={themes.TeacherQuestionnaireDropDownEdit(medias)}>
                                                <Box sx={themes.PaddingBlock(medias)}>
                                                    <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.photoOfDocument")}</Typography>
                                                </Box>
                                                <Box sx={themes.TeacherQuestionnaireAutocompleteContainer(medias)}>
                                                    <SDragAndDrop
                                                        image={store.teacher.certificates[index].photo.imageSrc}
                                                        setImage={(value) => setCertificatesData(index, "photo", value, "imageSrc")}
                                                        imageName={store.teacher.certificates[index].photo.imageName}
                                                        setImageName={(value) => setCertificatesData(index, "photo", value, "imageName")}
                                                        imageSize={store.teacher.certificates[index].photo.imageSize}
                                                        setImageSize={(value) => setCertificatesData(index, "photo", value, "imageSize")}
                                                    />
                                                    <Box sx={themes.TeacherQuestionnaireShowToStudents}>
                                                        <FormControlLabel
                                                            sx={themes.FormControlLabel}
                                                            control={
                                                                <IOSSwitch
                                                                    sx={{ m: 1 }}
                                                                    onChange={() => {
                                                                        setCertificatesData(index, "showToStudents", !store.teacher.certificates[index].showToStudents);
                                                                    }}
                                                                    checked={store.teacher.certificates[index].showToStudents}
                                                                />
                                                            }
                                                        />
                                                        <Box sx={themes.TeacherQuestionnaireDropDownTitle}>
                                                            <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.showPhotosToStudents")}</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        {!medias.sm ? (
                                            <>
                                                {store.teacher.certificates.length > 1 && (
                                                    <Button
                                                        sx={themes.TextButtonDelete}
                                                        disableRipple
                                                        variant="text"
                                                        onClick={() => {
                                                            store.teacher.certificates.splice(index, 1);
                                                        }}
                                                    >
                                                        {t("main.delete")}
                                                    </Button>
                                                )}
                                            </>
                                        ) : null}
                                        <Box>
                                            {store.teacher.certificates.length > 1 && index + 1 !== store.teacher.certificates.length ? (
                                                <hr style={themes.TeacherQuestionnaireAddFieldSeparator} />
                                            ) : null}
                                        </Box>
                                    </Box>
                                    {medias.sm && (
                                        <>
                                            {store.teacher.certificates.length > 1 ? (
                                                <STooltip
                                                    title={t("main.delete")}
                                                    PopperProps={themes.TooltipPopperProps}
                                                >
                                                    <Box
                                                        sx={themes.TeacherQuestionnaireDeleteStatementButton}
                                                        onClick={() => {
                                                            store.teacher.certificates.splice(index, 1);
                                                        }}
                                                    >
                                                        <DeleteStatementIcon />
                                                    </Box>
                                                </STooltip>
                                            ) : null}
                                        </>
                                    )}
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
                <Button
                    disableRipple
                    sx={themes.TeacherQuestionnaireAddFieldsButton}
                    onClick={handleClickAdd}
                >
                    {t("TeacherQuestionnaire.addCertificate")}
                </Button>
                <QuestionnaireButtons
                    // disabled={disabledContinue}
                    disabled={false}
                    setCurrentStep={setCurrentStep}
                    currentStep={currentStep}
                />
            </Box>
        </ScrollToTop>
    );
});

export default memo(TeacherCertificatesStep);
