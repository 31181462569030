import React from "react";
import { observer } from "mobx-react-lite";
import { Stack, Typography } from "@mui/material";
import CheckCircle from "@mui/icons-material/CheckCircle";
import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";
import * as styles from "components/DiplomaChats/RightPanel/OrderStatus/styles";

const StudentWorkingStatus = () => {
    return (
        <>
            {DiplomaStore.tutorProgress.map((point) => (
                <Stack
                    key={point.id}
                    direction="row"
                    spacing={1}
                    alignItems="flex-start"
                >
                    <CheckCircle
                        htmlColor={point.done ? "#19B20C" : "#EEEEF0"}
                        sx={styles.workingStatus.checkCircle}
                    />
                    <Typography variant="standard">{point.text}</Typography>
                </Stack>
            ))}
        </>
    );
};

export default observer(StudentWorkingStatus);
