import React from "react";
import { observer } from "mobx-react-lite";

import FreeMultipleChoiceFilter from "components/UI/FreeMultipleChoiceFilter";
import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";

import DiplomaProgressTheme from "./theme";

const TutorWorkingStatus = () => {
    const handleChangeWorkingStatus = (event, value) => {
        DiplomaStore.setTutorProgress(value);
    };

    return (
        <FreeMultipleChoiceFilter
            propOptions={DiplomaStore.tutorProgress}
            valueToShow="text"
            valueForKey="id"
            valueToBind="done"
            processingChange={handleChangeWorkingStatus}
            themeWrapper={DiplomaProgressTheme}
        />
    );
};

export default observer(TutorWorkingStatus);
