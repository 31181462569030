export const IncomeImageBlock = (medias) => {
    return {
        width: medias.sm ? "495px" : "328px",
        height: medias.sm ? "729px" : "261px",
        position: "relative",
    };
};

export const Image1 = (medias) => {
    return {
        left: medias.sm ? 103 : 172,
        top: medias.sm ? 105 : 0,
        position: "absolute",
    };
};

export const Image1Size = (medias) => {
    return {
        width: medias.sm ? 392 : 156,
        height: medias.sm ? 306 : 122,
    };
};

export const Dialog1 = {
    left: 54,
    top: 20,
    position: "absolute",
};

export const Image2 = (medias) => {
    return {
        left: 0,
        top: medias.sm ? 353 : 138,
        position: "absolute",
    };
};

export const Image2Size = (medias) => {
    return {
        width: medias.sm ? 346 : 156,
        height: medias.sm ? 270 : 123,
    };
};

export const Dialog2 = (medias) => {
    return {
        left: medias.sm ? 301 : 129,
        top: medias.sm ? 537 : 205,
        position: "absolute",
    };
};
