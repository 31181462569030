import { codesToSuppressGlobal, HTTP_CODES, HTTP_METHODS, methodsToSuppressGlobal } from "./Constants";
import { processLogs } from "./errorHandlers";
import { routes } from "../../router/backendHttpRoutes";

function requestOnSuccess(request) {
    // console.log("SearchRequest succeeded, processing here");
    return request;
}

function requestOnError(error) {
    // console.log("SearchRequest failed, processing here");
    return "modified SearchRequest";
}

function responseOnSuccess(response) {
    // console.log("response succeeded, processing here");
    return { isFailed: false, originalResponse: response, data: response.data };
}

function responseOnError(error) {
    // console.log("response failed, processing here", error);
    //проверяем в первую очередь
    if (error.response?.status === HTTP_CODES.UNAUTHORIZED) {
        //some actions
    }

    let suppress = checkForSuppression(error);

    if (suppress) {
        return { isFailed: false, originalResponse: error.response, data: error.response };
    } else if (error.response.status === HTTP_CODES.NOT_FOUND) {
        //если 404 и не подавляется, нет смысла передавать ее дальше - сразу редирект
    } else {
        let errorData = {
            Error: {
                ...error,
                config: {
                    ...error.config,
                    headers: Object.fromEntries(Object.entries(error.config.headers).filter(([key]) => key !== "Authorization")),
                },
            },
        };

        processLogs(errorData);
        // eslint-disable-next-line no-undef
        return { isFailed: true, originalResponse: error.response, data: error.response };
    }

    return { isFailed: true, originalResponse: error.response, data: error.response };
}

export const requestInterceptor = {
    requestOnSuccess,
    requestOnError,
};

export const responseInterceptor = {
    responseOnSuccess,
    responseOnError,
};

const checkForSuppression = (error) => {
    let { url: requestUrl, method: requestMethod } = error.response.config;

    let responseCode = error.response.status;

    if (
        methodsToSuppressGlobal
            .filter((method) => method.shouldBeSuppressed)
            .map((method) => method.method)
            .includes(requestMethod)
    ) {
        return true;
    }

    if (
        codesToSuppressGlobal
            .filter((code) => code.shouldBeSuppressed)
            .map((code) => code.code)
            .includes(responseCode)
    ) {
        return true;
    }

    let mappedRoutes = Object.entries(routes).map((route) => route[1]);

    let suppressFlag = false; //по умолчанию не подавляем никакие ошибки ответов

    for (let route of mappedRoutes) {
        if (route?.method === requestMethod) {
            let requestUrlArr = requestUrl.split("/").slice(1);

            let routeArr = route?.urlPattern.split("/").slice(1);

            if (requestUrlArr.length === routeArr.length) {
                let checkedCount = 0;

                for (let i = 0; i < routeArr.length; i++) {
                    if (!route.placeholdersPositions.includes(i)) {
                        if (requestUrlArr[i] === routeArr[i]) {
                            checkedCount++;
                        }
                    }
                }

                if (checkedCount === routeArr.length - route.placeholdersPositions.length) {
                    suppressFlag = route.shouldBeSuppressed;
                    break;
                }
            }
        }
    }

    return suppressFlag;
};
