import { observer } from "mobx-react-lite";
import React, { memo, useMemo, useState } from "react";
import * as themes from "./ContextMenuThemes";
import { Box } from "@mui/material";
import { findChatById } from "../../../CommonData/helper_functions";
import FullChatStore from "store/FullChatStore";
import SButton from "components/UI/SButton";
import useWebSocket from "react-use-websocket";
import ComplainModal from "../../../../MiniChat/OneChat/ComplainModal/ComplainModal";
import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import AreYouSureDelete from "./AreYouSureDelete/AreYouSureDelete";
import AddToFolder from "./AddToFolder/AddToFolder";
import ContextMenuContent from "./ContextMenuContent/ContextMenuContent";

const ContextMenu = observer(({ children, ...props }) => {
    const URL = "wss://" + process.env.REACT_APP_API_URL + "/chats/ws";

    const [openChatDeleteModal, SetOpenChatDeleteModal] = useState(false);

    const [openChatFoldersModal, SetOpenChatFoldersModal] = useState(false);

    const [openDeleteForAllModal, SetOpenDeleteForAllModal] = useState(false);

    const [openReportModal, SetOpenReportModal] = useState(false);

    const handleReportClose = () => {
        SetOpenReportModal(false);
    };

    const [openModalBlock, SetOpenModalBlock] = useState(false);

    const { sendMessage, sendJsonMessage, lastMessage, lastJsonMessage, readyState } = useWebSocket(URL, {
        share: true,
    });

    const handleClose = () => {
        props.setOpen(false);
        SetOpenChatFoldersModal(false);
        SetOpenDeleteForAllModal(false);
        SetOpenChatDeleteModal(false);
        props.setAnchorEl(null);
    };

    const UpdateChat = useMemo(() => {
        console.log(findChatById(FullChatStore.tabsData.chats, props.menuChatID));
        return findChatById(FullChatStore.tabsData.chats, props.menuChatID);
    }, [FullChatStore.tabsData, props.menuChatID]);

    const message_add_archive = {
        action: "add_to_archive",
        chat_id: props.menuChatID,
    };

    const addToFolder = (index) => {
        sendJsonMessage({
            action: "edit_folder",
            index: index,
            rename: {
                take_change: false,
            },
            add_chat_ids: {
                chat_ids: [props.menuChatID],
                take_change: true,
            },
            remove_chat_ids: {
                take_change: false,
            },
        });
    };

    const delFromFolder = (index) => {
        sendJsonMessage({
            action: "edit_folder",
            index: index,
            rename: {
                take_change: false,
            },
            add_chat_ids: {
                take_change: false,
            },
            remove_chat_ids: {
                chat_ids: [props.menuChatID],
                take_change: true,
            },
        });
    };

    const handleDeleteChat = (all) => {
        FullChatStore.SetFolderProcessingStep(null);
        sendJsonMessage({
            action: "delete_chat",
            chat_id: props.menuChatID,
            for_all: all,
        });
        SetOpenChatDeleteModal(false);
        handleClose();
    };

    const delHistory = (all) => {
        console.log({
            action: "delete_history",
            chat_id: props.menuChatID,
            for_all: all,
        });
        sendJsonMessage({
            action: "delete_history",
            chat_id: props.menuChatID,
            for_all: all,
        });
        handleClose();
    };

    return (
        <>
            <AreYouSureDelete
                open={openDeleteForAllModal}
                setOpen={SetOpenDeleteForAllModal}
                setOpenOuter={props.setOpen}
                anchor={props.anchorEl}
                handleClose={handleClose}
                openChatDeleteModal={openChatDeleteModal}
                SetOpenChatDeleteModal={SetOpenChatDeleteModal}
                handleDeleteChat={handleDeleteChat}
                delHistory={delHistory}
            />

            <AddToFolder
                open={openChatFoldersModal}
                anchor={props.anchorEl}
                delFromFolder={delFromFolder}
                addToFolder={addToFolder}
                UpdateChat={UpdateChat}
                handleClose={handleClose}
                SetOpenChatFoldersModal={SetOpenChatFoldersModal}
                setOpenOuter={props.setOpen}
            />

            <ContextMenuContent
                open={props.open}
                anchor={props.anchorEl}
                handleClose={handleClose}
                sendJsonMessage={sendJsonMessage}
                UpdateChat={UpdateChat}
                menuChatID={props.menuChatID}
                menuParticipantID={props.menuParticipantID}
                SetOpenChatFoldersModal={SetOpenChatFoldersModal}
                SetOpenDeleteForAllModal={SetOpenDeleteForAllModal}
                SetOpenChatDeleteModal={SetOpenChatDeleteModal}
                SetOpenModalBlock={SetOpenModalBlock}
                SetOpenReportModal={SetOpenReportModal}
                setOpenOuter={props.setOpen}
                setOpen={props.setOpen}
            />

            <ModalSettingsSmall
                visible={openModalBlock}
                handleClose={() => SetOpenModalBlock(false)}
            >
                <Box sx={{ fontSize: "24px", fontWeight: "600", textAlign: "center" }}>
                    Вы действительно хотите {UpdateChat.folders.includes(3) ? "разблокировать " : "заблокировать "}
                    {UpdateChat.participants_info[0].name}?
                    {UpdateChat.folders.includes(3) === false && <Box sx={{ fontSize: "14px", marginTop: "2.5vh", fontWeight: "400" }}>Специалист больше не сможет вам писать</Box>}
                    <Box
                        sx={{
                            textAlign: "center",
                            marginBottom: "2.5vh",
                            marginTop: "2.5vh",
                            fontWeight: "400",
                        }}
                    >
                        <Box
                            sx={{
                                display: "inline-block",
                                marginRight: "1vh",
                            }}
                        >
                            <SButton
                                variant="secondary"
                                onClick={() => SetOpenModalBlock(false)}
                            >
                                Отменить
                            </SButton>
                        </Box>
                        <Box sx={{ display: "inline-block" }}>
                            <SButton
                                onClick={() => {
                                    UpdateChat.folders.includes(3) === true
                                        ? FullChatStore.UnblockUser(props.menuParticipantID, sendJsonMessage, true)
                                        : FullChatStore.BlockUser(props.menuParticipantID, sendJsonMessage, true);
                                    SetOpenModalBlock(false);
                                }}
                                variant={UpdateChat.folders.includes(3) ? "shadowed" : "red"}
                            >
                                Да, {UpdateChat.folders.includes(3) ? "разблокировать" : "заблокировать"}
                            </SButton>
                        </Box>
                    </Box>
                </Box>
            </ModalSettingsSmall>

            <ComplainModal
                participantId={props.menuParticipantID}
                open={openReportModal}
                handleClose={handleReportClose}
                sendJsonMessage={sendJsonMessage}
            />
        </>
    );
});

export default memo(ContextMenu);
