import React from "react";
import StyledWhiteBox from "components/DiplomaChats/StyledComponents/StyledWhiteBox";
import OrderDetails from "components/DiplomaChats/OrderDetails";

const Details = () => {
    return (
        <StyledWhiteBox
            padding="20px 30px"
            width="641px"
            flexDirection="column"
        >
            <OrderDetails />
        </StyledWhiteBox>
    );
};

export default Details;
