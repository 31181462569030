import React from "react";

const StarsFourPoints = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="55"
            height="55"
            viewBox="0 0 55 55"
            fill="none"
        >
            <path
                d="M29.7891 9.16665V4.58331C40.4682 5.72915 48.8787 14.1854 50.0703 24.8646C51.4453 37.4458 42.3932 48.7666 29.7891 50.1416V45.5583C38.1307 44.5271 44.6849 37.95 45.7391 29.6083C46.0359 27.2185 45.8587 24.7936 45.2175 22.4724C44.5763 20.1511 43.4838 17.9791 42.0024 16.0805C40.5209 14.1819 38.6797 12.594 36.584 11.4076C34.4884 10.2213 32.1793 9.45977 29.7891 9.16665ZM12.9911 9.62498C16.4647 6.75716 20.7206 5.00051 25.2057 4.58331V9.30415C21.9286 9.76248 18.8349 11.0916 16.2682 13.1541L12.9911 9.62498ZM4.69531 25.2083C5.12087 20.7211 6.88541 16.4655 9.7599 12.9937L13.037 16.2708C11.0065 18.8597 9.70855 21.9464 9.27865 25.2083H4.69531ZM9.78281 42.0062C6.93582 38.518 5.1749 34.271 4.71823 29.7916H9.30156C9.71406 33.0458 11.0203 36.1396 13.037 38.7291L9.78281 42.0062ZM12.9911 45.2375L16.1766 42.0979H16.2682C18.8578 44.1146 21.9516 45.4208 25.2057 45.8333V50.4166C20.7141 49.9354 16.4516 48.125 12.9911 45.2375ZM27.4974 38.9583L31.0724 31.1208L38.9557 27.5L31.0724 23.925L27.4974 16.0416L23.8995 23.925L16.0391 27.5L23.8995 31.1208L27.4974 38.9583Z"
                fill="#6212FF"
            />
        </svg>
    );
};

export default StarsFourPoints;
