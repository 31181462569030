import React from "react";
import ResponseCard from "components/OrderResponse/OrderResponseTutorsWrapper/ResponseCard/ResponseCard";
import orderResponseStore from "store/OrderResponseStore";
import { observer } from "mobx-react-lite";
import OrderResponseCountSpDropMenuWrapper from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseCountSpDropMenuWrapper/OrderResponseCountSpDropMenuWrapper";
import { StyledRejectedDivider, StyledRejectedText } from "./OrderResponeTutorsResponsesStyled";
import OrdersResponseDismissModal from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_OrdersResponseDismissModal/OrdersResponseDismissModal";
import CompainModal from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_CompainModal/CompainModal";
import ZeroResponseCard from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_ZeroCard/ZeroResponseCard";

const OrderResponeTutorsResponse = observer(() => {
    const activeTutors = orderResponseStore.responseTutors.filter((el) => el.status === "active");

    const rejectedTutors = orderResponseStore.responseTutors.filter((el) => el.status === "rejected");

    return (
        <>
            {activeTutors.length === 0 && rejectedTutors.length === 0 ? null : <OrderResponseCountSpDropMenuWrapper value={activeTutors.length + rejectedTutors.length} />}
            {activeTutors.length === 0 && rejectedTutors.length === 0 ? (
                <ZeroResponseCard />
            ) : (
                <>
                    <ResponseCard tutors={activeTutors} />
                    {rejectedTutors.length > 0 && (
                        <StyledRejectedDivider>
                            <StyledRejectedText>Отклонённые отклики</StyledRejectedText>
                        </StyledRejectedDivider>
                    )}
                    <ResponseCard tutors={rejectedTutors} />
                    {orderResponseStore.isDismissClicked && <OrdersResponseDismissModal />}
                    {orderResponseStore.isComplainClicked && <CompainModal />}
                </>
            )}
        </>
    );
});

export default OrderResponeTutorsResponse;
