import React from "react";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { memo } from "react";
import Error500BodyBlock from "components/ErrorsBodyBlock/Error500BodyBlock/Error500BodyBlock";

const PageError500 = ({ children, ...props }) => {
    return (
        <ScrollToTop>
            <Error500BodyBlock />
        </ScrollToTop>
    );
};

export default memo(PageError500);
