import { Typography } from "@mui/material";
import IconComp from "assets/MyClassesAssets/SVG/IconComp";
import React from "react";
import { memo } from "react";
import * as styles from "./FirstClassThemes";
import * as textStyles from "themes/TextThemes";
import Box from "@mui/material/Box";
import SButton from "components/UI/SButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMedias } from "API/mediasHook";

const FirstClass = () => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    return (
        <Box sx={styles.FirstClass}>
            <Box sx={styles.FirstClassTitle}>
                <Box>
                    <IconComp />
                </Box>
                <Box sx={styles.FirstClassContent}>
                    <Typography sx={textStyles.HeadlineH5Black}>{t("main.myClasses.signUpFirstClass")}</Typography>
                    <Typography sx={medias.sm ? textStyles.Body1Black : textStyles.Body2Black}>{t("main.myClasses.toFindFittedTutor")}</Typography>
                </Box>
            </Box>
            <Box sx={styles.FirstClassButton}>
                <SButton
                    variant="arrow"
                    padding="7px 16px 8px 16px "
                    onClick={() => navigate("/bid")}
                    style={styles.ButtomSize(medias)}
                >
                    {t("main.myClasses.createOrder")}
                </SButton>
            </Box>
        </Box>
    );
};

export default memo(FirstClass);
