import React from "react";
import * as themes from "./PhotoInviteBlockThemes";
import Rectangle6346 from "assets/ReferralPageAssets/PNG/Rectangle6346.png";
import Rectangle6347 from "assets/ReferralPageAssets/PNG/Rectangle6347.png";
import Rectangle6348 from "assets/ReferralPageAssets/PNG/Rectangle6348.png";
import Rectangle6349 from "assets/ReferralPageAssets/PNG/Rectangle6349.png";
import Rectangle6350 from "assets/ReferralPageAssets/PNG/Rectangle6350.png";
import Box from "@mui/material/Box";
import { memo } from "react";

const PhotoInviteBlock = ({ children, ...props }) => {
    return (
        <Box sx={themes.photoBlock}>
            <Box sx={themes.FirstPhoto}>
                <img
                    src={Rectangle6346}
                    alt={1}
                />
            </Box>
            <Box sx={themes.SecondPhoto}>
                <img
                    src={Rectangle6347}
                    alt={2}
                />
            </Box>
            <Box sx={themes.ThirdPhoto}>
                <img
                    src={Rectangle6348}
                    alt={3}
                />
            </Box>
            <Box sx={themes.FourthPhoto}>
                <img
                    src={Rectangle6349}
                    alt={4}
                />
            </Box>
            <Box sx={themes.FifthPhoto}>
                <img
                    src={Rectangle6350}
                    alt={5}
                />
            </Box>
        </Box>
    );
};

export default memo(PhotoInviteBlock);
