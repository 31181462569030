import { memo } from "react";
import * as themes from "./TeacherCardThemes";
import TeacherInfoCard from "components/UI/TeacherCard/TeacherInfoCard/TeacherInfoCard";
import TeacherScheduleCard from "./TeacherScheduleCard/TeacherScheduleCard";
import { useMedias } from "../../../API/mediasHook";
import Box from "@mui/material/Box";

const TeacherCard = ({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={themes.teacherCardContainerStyle(medias)}>
            <TeacherInfoCard
                value={props.value}
                styleMode={0}
            ></TeacherInfoCard>
            {medias.sm && <TeacherScheduleCard value={props.value}></TeacherScheduleCard>}
        </Box>
    );
};

export default memo(TeacherCard);
