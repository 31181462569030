import { makeAutoObservable } from "mobx";
import RomanAvatar from "assets/Avatars/Roma.jpg";
import RomanMiniAvatar from "assets/Avatars/RomaMini.png";
import AlyonaAvatar from "assets/Avatars/Alyona.jpg";
import DamirAvatar from "assets/Avatars/Damir.jpg";
import AlyonaVideo from "assets/MainPage/MOV/Отзыв Алёна.mp4";
import RomanVideo from "assets/MainPage/MOV/Отзыв Рома.mp4";
import DamirVideo from "assets/MainPage/MOV/Отзыв Дамир.MOV";

class MainPageStore {
    search = "";
    searchReady = "";
    popularQueries = [];
    tutorsFeedbackList = [];
    activeTutor = null;

    TutorsReviewCards = [];
    displayMoreReviews = false;

    popularQueriesBottom = [];
    popularSubjectsBottom = [];

    activeHowWorkItem = 0;

    constructor() {
        makeAutoObservable(this);
        this.Init();
    }

    SetSearch(value) {
        this.search = value;
    }

    SetActiveTutor(value) {
        this.activeTutor = value;
        console.log("Set Active Tutor command");
        console.log(this.activeTutor.tutorVideoName);
    }

    SetDisplayMoreReviewsFlag() {
        this.displayMoreReviews = !this.displayMoreReviews;
        console.log("Set Display More Reviews Flag");
    }

    SetSearchDebounced(value) {
        this.SetSearch(value);
        this.debounce(() => {
            this.searchReady = this.search;
            console.log(value); /*this.FilterTeachersArr();*/
        }, 1000)();
    }

    SetActiveHowWorkItem(value, mode) {
        if (mode === "auto") {
            this.activeHowWorkItem = this.activeHowWorkItem === 2 ? (this.activeHowWorkItem = 0) : ++this.activeHowWorkItem;
        }

        if (mode === "manual") {
            this.activeHowWorkItem = value;
        }
    }

    debounce(callback, delay) {
        let timerID = null;

        return (...args) => {
            if (timerID) {
                clearTimeout(timerID);
            }

            timerID = setTimeout(() => {
                callback.call(this, ...args);
            }, delay);
        };
    }

    ProcessTagSearch(value) {
        this.SetSearchDebounced(value);
    }

    Init() {
        this.popularQueriesBottom = [
            { ID: 0, Query: "подготовка к ЕГЭ" },
            { ID: 1, Query: "подготовка к ОГЭ" },
            { ID: 2, Query: "повышение успеваемости" },
            { ID: 3, Query: "поступление в ВУЗ" },
            { ID: 4, Query: "внутренние экзамены  в ВУЗе" },
            { ID: 5, Query: "дипломные работы" },
            { ID: 6, Query: "курсовые работы" },
            { ID: 7, Query: "помощь с работами" },
            { ID: 8, Query: "презентации" },
            { ID: 9, Query: "написание рефератов" },
            { ID: 10, Query: "подготовка к международным экзаменам" },
            { ID: 11, Query: "подготовка к олипиадам" },
            { ID: 12, Query: "репетитор по английскому" },
            { ID: 13, Query: "репетитор по математике" },
        ];

        this.popularSubjectsBottom = [
            { ID: 0, Query: "математика" },
            { ID: 1, Query: "математика ЕГЭ" },
            { ID: 2, Query: "русский язык" },
            { ID: 3, Query: "химия" },
            { ID: 4, Query: "биология" },
            { ID: 5, Query: "русский язык ОГЭ" },
            { ID: 6, Query: "китайский язык" },
            { ID: 7, Query: "макроэкономика" },
            { ID: 8, Query: "микроэкономика" },
            { ID: 9, Query: "статистика" },
            { ID: 10, Query: "теория вероятностей" },
            { ID: 11, Query: "экономическая кибернетика" },
        ];

        this.popularQueries = [
            { ID: 0, Query: "promoPages.main.popularQueries.english" },
            { ID: 1, Query: "promoPages.main.popularQueries.math" },
            { ID: 2, Query: "promoPages.main.popularQueries.examPreparation" },
        ];

        //import * from "components/MainPage/AboutUs/VideoBlock/Video1.png"

        this.tutorsFeedbackList = [
            {
                ID: 0,
                tutorName: "promoPages.main.alyonaAnikina",
                tutorDescribe: "promoPages.main.alyonaSubject",
                tutorPhoto: AlyonaAvatar,
                tutorPreviewPhoto: AlyonaAvatar,
                tutorVideo: AlyonaVideo,
                tutorVideo2: "https://www.dropbox.com/scl/fi/nxcszvlm5ywuf8g93mg5q/.mp4?rlkey=jvsbshcsw9xe40l9i9fevswxj&dl=0",
                tutorVideoName: "ОтзывАлена",
            },
            {
                ID: 1,
                tutorName: "promoPages.main.romanMalinin",
                tutorDescribe: "promoPages.main.romaSubject",
                tutorPhoto: RomanAvatar,
                tutorPreviewPhoto: RomanMiniAvatar,
                tutorVideo: RomanVideo,
                tutorVideo2: "https://www.dropbox.com/scl/fi/yitvmfin2hvex6v30dza4/.mp4?rlkey=wmox3mkytnxqqm560bc4r5rr9&dl=0",
                tutorVideoName: "ОтзывРома",
            },
            {
                ID: 2,
                tutorName: "promoPages.main.damirSuleymanov",
                tutorDescribe: "promoPages.main.damirSubject",
                tutorPhoto: DamirAvatar,
                tutorPreviewPhoto: DamirAvatar,
                tutorVideo: DamirVideo,
                tutorVideo2: "https://www.dropbox.com/scl/fi/l4fuhs6bkopcjwbq8g0k8/.mp4?rlkey=2h33llfomgrn1v3g0s2a2to5j&dl=0",
                tutorVideoName: "ОтзывДамир",
            },
        ];

        this.activeTutor = this.tutorsFeedbackList[0];
        this.TutorsReviewCards = [
            {
                ID: 0,
                TutorName: "promoPages.main.reviews.AlexeyNikolaevichVasiliev",
                TutorSubject: "promoPages.main.reviews.UX/UI",
                ReviewerName: "promoPages.main.reviews.AlexanderPLeftReview",
                CardText: "promoPages.main.reviews.AlexeyIsGreatMentor",
                TutorPhoto: "https://e.radikal.host/2023/07/29/Rectangle-6320-1.png",
            },
            {
                ID: 1,
                TutorName: "promoPages.main.reviews.IrinaVladimirovnaStoyanovskaya",
                TutorSubject: "promoPages.main.reviews.english",
                ReviewerName: "promoPages.main.reviews.IlyaRLeftReview",
                CardText: "promoPages.main.reviews.iHaveBeenStudyingEnglish",
                TutorPhoto: "https://e.radikal.host/2023/07/29/Rectangle-6320-2.png",
            },
            {
                ID: 2,
                TutorName: "promoPages.main.reviews.KarinaGennadievnaBocharova",
                TutorSubject: "promoPages.main.reviews.courseworkAndTheses",
                ReviewerName: "promoPages.main.reviews.JuliaGLeftReview",
                CardText: "promoPages.main.reviews.finallyFoundSpecialist",
                TutorPhoto: "https://e.radikal.host/2023/07/29/Rectangle-6320-3.png",
            },
            {
                ID: 3,
                TutorName: "promoPages.main.reviews.IlyaMaksimovichKravchenko",
                TutorSubject: "promoPages.main.reviews.JavaScript",
                ReviewerName: "promoPages.main.reviews.IrinaSLeftReview",
                CardText: "promoPages.main.reviews.iHaveBeenStudyingWithIlyaJavaScript",
                TutorPhoto: "https://e.radikal.host/2023/07/29/Rectangle-6320-4.png",
            },
            {
                ID: 4,
                TutorName: "promoPages.main.reviews.InnaValerievnaKhavturova",
                TutorSubject: "promoPages.main.reviews.higherMathematics",
                ReviewerName: "promoPages.main.reviews.OlgaNLeftReview",
                CardText: "promoPages.main.reviews.iHaveBeenStudying",
                TutorPhoto: "https://e.radikal.host/2023/07/29/Rectangle-6320-5.png",
            },
            {
                ID: 5,
                TutorName: "promoPages.main.reviews.DmitryAlekseevichShitel",
                TutorSubject: "promoPages.main.reviews.economics",
                ReviewerName: "promoPages.main.reviews.KirillPLeftReview",
                CardText: "promoPages.main.reviews.itGreatWhenTeacher",
                TutorPhoto: "https://e.radikal.host/2023/07/29/Rectangle-6320-6.png",
            },
            {
                ID: 6,
                TutorName: "promoPages.main.reviews.DaniilMaksimovichBritchenko",
                TutorSubject: "promoPages.main.reviews.courseworkAndTheses",
                ReviewerName: "promoPages.main.reviews.VladSLeftReview",
                CardText: "promoPages.main.reviews.workWasWrittenQuickly",
                TutorPhoto: "https://e.radikal.host/2023/07/29/Rectangle-6320-7.png",
            },
            {
                ID: 7,
                TutorName: "promoPages.main.reviews.IrinaErikovnaReingold",
                TutorSubject: "promoPages.main.reviews.RussianLanguageJournalism",
                ReviewerName: "promoPages.main.reviews.MilanaKLeftReview",
                CardText: "promoPages.main.reviews.wonderfulIrinaErikovna",
                TutorPhoto: "https://e.radikal.host/2023/07/29/Rectangle-6320-8.png",
            },
            {
                ID: 8,
                TutorName: "promoPages.main.reviews.IvanStanislavovichMironin",
                TutorSubject: "promoPages.main.reviews.socialScience",
                ReviewerName: "promoPages.main.reviews.KirillMLeftReview",
                CardText: "promoPages.main.reviews.IvanStanislavovichIsTeacher",
                TutorPhoto: "https://e.radikal.host/2023/07/29/Rectangle-6320-9.png",
            },
            {
                ID: 9,
                TutorName: "promoPages.main.reviews.ValeriaNikolaevnaGurevich",
                TutorSubject: "promoPages.main.reviews.courseworkAndTheses",
                ReviewerName: "promoPages.main.reviews.AlinaLLeftReview",
                CardText: "promoPages.main.reviews.iAmIncrediblyGrateful",
                TutorPhoto: "https://e.radikal.host/2023/07/29/Rectangle-6320-10.png",
            },
            {
                ID: 10,
                TutorName: "promoPages.main.reviews.TatyanaViktorovnaSchmidt",
                TutorSubject: "promoPages.main.reviews.ChemistryBiologyBiochemistry",
                ReviewerName: "promoPages.main.reviews.LenaraTleftReview",
                CardText: "promoPages.main.reviews.TatyanaViktorovnaHelped",
                TutorPhoto: "https://e.radikal.host/2023/07/29/Rectangle-6320-11.png",
            },
        ];
    }

    async ExecuteSearch() {
        console.log("executing search");
        window.open("https://study-buddy.ru/catalog");
    }
}

export default new MainPageStore();
