import React from "react";
export const SearchIcon = () => {
    return (
        <svg
            width="2.08vh"
            height="2.08vh"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="icon/16/search"
                clipPath="url(#clip0_10380_69259)"
            >
                <path
                    id="Union"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.8264 12.8767C9.68611 13.7883 8.24004 14.3333 6.66667 14.3333C2.98477 14.3333 0 11.3486 0 7.66667C0 3.98477 2.98477 1 6.66667 1C10.3486 1 13.3333 3.98477 13.3333 7.66667C13.3333 9.24053 12.7879 10.687 11.8759 11.8274C11.9049 11.849 11.9327 11.873 11.959 11.8994L14.8162 14.7565C15.1091 15.0494 15.1091 15.5243 14.8162 15.8172C14.5233 16.1101 14.0484 16.1101 13.7555 15.8172L10.8984 12.96C10.872 12.9336 10.848 12.9058 10.8264 12.8767ZM11.8333 7.66667C11.8333 10.5201 9.52014 12.8333 6.66667 12.8333C3.8132 12.8333 1.5 10.5201 1.5 7.66667C1.5 4.8132 3.8132 2.5 6.66667 2.5C9.52014 2.5 11.8333 4.8132 11.8333 7.66667Z"
                    fill="#9E9EB0"
                />
            </g>
            <defs>
                <clipPath id="clip0_10380_69259">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
