export const MainBlock = {
    borderRadius: "20px",
    width: "480px",
    backgroundColor: "white",
    padding: "30px 40px 30px 40px",
    marginLeft: "520px",
    height: "421px",
};

export const Back = {
    backgroundColor: "#F3F3F3",
    paddingTop: "60px",
    paddingBottom: "119px",
    borderRadius: "0px 0px 40px 40px",
};

export const Header = {
    fontSize: "24px",
    fontWeight: "600",
    textAlign: "center",
    paddingBottom: "20px",
};
