import React, { memo } from "react";
import Box from "@mui/material/Box";
import * as themes from "./ExperienceContentThemes";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMedias } from "../../../../API/mediasHook";

const ExperienceContent = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const medias = useMedias();

    return (
        <Box sx={themes.experienceContentContainerStyle}>
            {props.value.map((value) => (
                <Box
                    key={value.ID}
                    sx={themes.experienceItemStyle(medias)}
                >
                    <Typography sx={themes.experienceInfoStyle}>
                        {value.ExperienceInfo},{" "}
                        <span style={themes.experienceYearStyle}>
                            {value.ExperienceYearEnd === Number.POSITIVE_INFINITY
                                ? "c " + value.ExperienceYearStart + " г. (" + t("main.years", { count: new Date().getFullYear() - value.ExperienceYearStart }) + ")"
                                : value.ExperienceYearStart + "-" + value.ExperienceYearEnd + " гг. (" + t("main.years", { count: value.ExperienceYearEnd - value.ExperienceYearStart }) + ")"}
                        </span>
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};

export default memo(ExperienceContent);
