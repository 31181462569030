import React, { memo } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TeacherQuestionnaireBodyBlock from "components/TeacherQuestionnaireBodyBlock/TeacherQuestionnaireBodyBlock";

const theme = createTheme({
    spacing: 1,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const TeacherQuestionnairePage = ({ children, ...props }) => {
    return (
        <ThemeProvider theme={theme}>
            <TeacherQuestionnaireBodyBlock />
        </ThemeProvider>
    );
};

export default memo(TeacherQuestionnairePage);
