import TodayClasses from "./TodayClasses/TodayClasses";
import NewClasses from "./NewClasses/NewClasses";
import FirstClass from "./FirstClass/FirstClass";
import React from "react";
import { memo } from "react";
import Box from "@mui/material/Box";
import * as styles from "./CurrentClassesThemes";
import MyClassesStore from "store/MyClassesStore";
import { observer } from "mobx-react-lite";
import { useMedias } from "API/mediasHook";

const CurrentClasses = observer(({ children, ...props }) => {
    const medias = useMedias();

    return MyClassesStore.classes.length ? (
        <Box sx={styles.CurrentClasses(medias)}>
            <TodayClasses type="myClasses" />
            <NewClasses />
        </Box>
    ) : (
        <FirstClass />
    );
});

export default memo(CurrentClasses);
