import React from "react";
import { memo } from "react";
import AccordionRef from "components/ReferralPromoBodyBlock/Accordion/Accordion";
import { Typography } from "@mui/material";
import * as themes from "./TopQuestionsTheme";
import * as textThemes from "themes/TextThemes";
import RuleComp from "components/TutorRulesBodyBlock/RuleComp/RuleComp";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMedias } from "../../../API/mediasHook";

const TopQuestions = ({ children, ...props }) => {
    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    const handleClickRules = () => {
        navigate("/buddyrules");
    };

    const medias = useMedias();

    return (
        <Box sx={themes.TopQuestionsBlock(medias)}>
            <Typography sx={textThemes.HeadlineH2Black(medias)}>{t("promoPages.promoTutor.topFiveQuestions")}</Typography>
            <Box sx={themes.AccordionBlock}>
                <AccordionRef
                    header={t("promoPages.promoTutor.canEverybodyBecomeTutor")}
                    text={
                        <Box sx={themes.QuestionOne}>
                            <Typography>{t("promoPages.promoTutor.thereSeveralConditions")}</Typography>
                            <RuleComp
                                style={{ marginTop: "10px" }}
                                text={t("promoPages.promoTutor.moreThanYears")}
                            />
                            <RuleComp text={t("promoPages.promoTutor.youHaveKnowledge")} />
                            <RuleComp text={t("promoPages.promoTutor.youCanEffectivelyTeach")} />
                            <RuleComp text={t("promoPages.promoTutor.stableInternet")} />
                            <Typography sx={textThemes.Body1Black}>
                                {t("promoPages.promoTutor.allConditionSatisfiedPart1")}
                                <Typography
                                    onClick={handleClickRules}
                                    component="span"
                                    sx={{ ...textThemes.Body1Purple, ...themes.LinkButton }}
                                >
                                    {t("promoPages.promoTutor.cooperationRules")}
                                </Typography>
                                {t("promoPages.promoTutor.allConditionSatisfiedPart2")}
                            </Typography>
                        </Box>
                    }
                />
                <AccordionRef
                    header={t("promoPages.promoTutor.howToStartCooperation")}
                    text={
                        <Box sx={themes.QuestionOne}>
                            <Typography>{t("promoPages.promoTutor.freeSignUp")}</Typography>
                            <Typography>{t("promoPages.promoTutor.fillQuestionnaireAsYouCan")}</Typography>
                            <RuleComp text={t("promoPages.promoTutor.personalData")} />
                            <RuleComp text={t("promoPages.promoTutor.studentCategory")} />
                            <RuleComp text={t("promoPages.promoTutor.subjects")} />
                            <RuleComp text={t("promoPages.promoTutor.aims")} />
                            <RuleComp text={t("promoPages.promoTutor.format")} />
                            <RuleComp text={t("promoPages.promoTutor.education")} />
                            <RuleComp text={t("promoPages.promoTutor.schedule")} />
                            <RuleComp text={t("promoPages.promoTutor.prices")} />
                            <Typography>{t("promoPages.promoTutor.styleProfile")}</Typography>
                            <RuleComp text={t("promoPages.promoTutor.uploadPhoto")} />
                            <RuleComp text={t("promoPages.promoTutor.writeMessage")} />
                            <RuleComp text={t("promoPages.promoTutor.createVideo")} />
                            <Typography>{t("promoPages.promoTutor.afterCheck")}</Typography>
                        </Box>
                    }
                />
                <AccordionRef
                    header={t("promoPages.promoTutor.howToBeUnusual")}
                    text={
                        <Box sx={themes.QuestionOne}>
                            <RuleComp text={t("promoPages.promoTutor.writeInformation")} />
                            <RuleComp text={t("promoPages.promoTutor.sendLinks")} />
                            <RuleComp text={t("promoPages.promoTutor.videoPresentation")} />
                            <RuleComp text={t("promoPages.promoTutor.conductClasses")} />
                        </Box>
                    }
                />
                <AccordionRef
                    header={t("promoPages.promoTutor.onlineOrOffline")}
                    text={
                        <Box sx={themes.QuestionOne}>
                            <Typography>{t("promoPages.promoTutor.bothVariants")}</Typography>
                        </Box>
                    }
                />
                <AccordionRef
                    header={t("promoPages.promoTutor.howToGetPaid")}
                    text={
                        <Box sx={themes.QuestionOne}>
                            <RuleComp text={t("promoPages.promoTutor.afterBooking")} />
                            <RuleComp text={t("promoPages.promoTutor.afterSuccessfulClass")} />
                            <RuleComp text={t("promoPages.promoTutor.classCancellation")} />
                        </Box>
                    }
                />
            </Box>
        </Box>
    );
};

export default memo(TopQuestions);
