import React, { memo, useState } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./ManageMoreThemes";
import { IconButton, Menu, Tooltip, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import VideoChatStore from "store/VideoChatStore";
import MoreIcon from "assets/VideoChatAssets/SVG/moreIcon";
import MenuItem from "@mui/material/MenuItem";

const ManageChat = observer(({ children, ...props }) => {
    const [moreActionsAnchorEl, setMoreActionsAnchorEl] = useState(null);

    const moreActionsOpen = Boolean(moreActionsAnchorEl);

    const handleMoreActionsMenuOpen = (event) => {
        VideoChatStore.SetMoreState(true);
        setMoreActionsAnchorEl(event.currentTarget);
    };

    const handleMoreActionsMenuClose = (value, event) => {
        if (value !== null) {
            console.log("action more", value);
        }

        setMoreActionsAnchorEl(null);
        VideoChatStore.SetMoreState(false);
    };

    return (
        <Box sx={themes.iconContainerStyle(VideoChatStore.managementPanel.moreState)}>
            <ThemeProvider theme={themes.ActionButtonTheme(1, VideoChatStore.managementPanel.moreState)}>
                <Tooltip
                    title={<Typography sx={themes.tooltipStyle}>{VideoChatStore.managementPanel.moreState ? "Скрыть" : "Показать"} действия</Typography>}
                    placement={"top"}
                >
                    <IconButton
                        disableRipple
                        onClick={handleMoreActionsMenuOpen}
                    >
                        <MoreIcon />
                    </IconButton>
                </Tooltip>
            </ThemeProvider>
            <ThemeProvider theme={themes.MoreActionsMenuTheme}>
                <Menu
                    anchorEl={moreActionsAnchorEl}
                    open={moreActionsOpen}
                    onClose={handleMoreActionsMenuClose.bind(this, null)}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                >
                    {VideoChatStore.moreActionsOptions.map((value, index) => (
                        <MenuItem
                            key={value.ID}
                            onClick={handleMoreActionsMenuClose.bind(this, value)}
                            disableRipple
                            selected={VideoChatStore.moreActionsOptions.ID === value.ID}
                        >
                            <Typography sx={themes.actionTextStyle}>{value.actionName}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </ThemeProvider>
        </Box>
    );
});

export default memo(ManageChat);
