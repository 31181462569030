export const reviewsBodyContainerStyle = {
    display: "flex",
    flexDirection: "column",
};

export const horizontalSeparatorStyle = (medias) => {
    return {
        width: medias.sm ? "827px" : "320px",
        height: "1px",
        marginTop: 16,
        marginBottom: 16,
        background: "#DADADF",
    };
};
