import { useState } from "react";
import { MoreHorizOutlined } from "@mui/icons-material";
import { Avatar, AvatarGroup, Box, Divider, IconButton, Typography } from "@mui/material";
import OrderSwitch from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/OrderSwitch/OrderSwitch";
import OrderDate from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/OrderDate/OrderDate";
import OrderTarget from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/OrderTarget/OrderTarget";
import MenuListComposition from "components/UI/PopperMenu";
import * as React from "react";
import ActiveCardPopper from "./ActiveCardPopper/ActiveCardPopper";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import * as themes from "./ActiveOrderCardThemes";
import * as textThemes from "themes/TextThemes";
import { useMedias } from "API/mediasHook";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import ViewsIcon from "assets/CommonAssets/SVG/ViewsIcon";
import PhotoHidden from "assets/OrderAssets/PNG/photoHidden.png";

const ActiveOrderCard = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const medias = useMedias();

    const [openOrderPopper, setOpenOrderPopper] = useState(false);

    return (
        <Box sx={themes.StyledOrderCard(medias)}>
            <Box sx={themes.TopAndTargetBlock}>
                <Box sx={themes.TopBlock}>
                    <Typography sx={{ ...textThemes.HeadlineH5Black, ...themes.CardTitle }}>
                        {props.order.baseOrderInfo.orderType === "tutoring"
                            ? props.order.baseOrderInfo.subject
                                ? props.order.baseOrderInfo.subject
                                : props.order.baseOrderInfo.requestTopic
                            : props.order.baseOrderInfo.requestTopic}
                    </Typography>
                    <Box sx={themes.DateAndMenuBlock}>
                        <OrderDate date={props.order.baseOrderInfo.createdAt} />
                        <MenuListComposition
                            open={openOrderPopper}
                            setOpen={setOpenOrderPopper}
                            content={<ActiveCardPopper setOpen={setOpenOrderPopper} />}
                            zIndex={1}
                            paperSx={themes.paperSx}
                        >
                            <IconButton
                                disableRipple
                                sx={themes.IconButton}
                            >
                                <MoreHorizOutlined />
                            </IconButton>
                        </MenuListComposition>
                    </Box>
                </Box>
                <OrderTarget
                    order={props.order}
                    type="Body1"
                />
            </Box>
            <Box>
                {!props.order.baseOrderInfo.isPersonal ? (
                    props.order.baseOrderInfo.responses.length > 1 ? (
                        <AvatarGroup
                            max={6}
                            sx={themes.AvatarGroup}
                        >
                            {props.order.baseOrderInfo.responses.map((response) => (
                                <Avatar
                                    sx={themes.Avatar}
                                    src={response.tutor.anonymous ? PhotoHidden : response.tutor.photo.link}
                                    key={response.responseUuid}
                                />
                            ))}
                        </AvatarGroup>
                    ) : (
                        <Box sx={themes.SingleAvatar}>
                            {props.order.baseOrderInfo.responses[0].tutor.anonymous ? (
                                <Box sx={themes.PhotoHidden}>
                                    <Typography sx={themes.PhotoHiddenText}>{t("orders.studentOrders.photoHidden")}</Typography>
                                </Box>
                            ) : (
                                <Avatar
                                    sx={themes.Avatar}
                                    src={props.order.baseOrderInfo.responses[0].tutor.photo.link}
                                />
                            )}
                            <Typography sx={textThemes.Body2Black}>
                                {props.order.baseOrderInfo.responses[0].tutor.anonymous
                                    ? t("orders.studentOrders.fioHidden")
                                    : `${props.order.baseOrderInfo.responses[0].tutor.name} ${props.order.baseOrderInfo.responses[0].tutor.surname[0]}.`}
                            </Typography>
                        </Box>
                    )
                ) : props.order.baseOrderInfo.offers.length > 1 ? (
                    <AvatarGroup
                        max={6}
                        sx={themes.AvatarGroup}
                    >
                        {props.order.baseOrderInfo.offers.map((offer) => (
                            <Avatar
                                sx={themes.Avatar}
                                src={offer.tutor.anonymous ? PhotoHidden : offer.tutor.photo.link}
                                key={offer.responseUuid}
                            />
                        ))}
                    </AvatarGroup>
                ) : (
                    <Box sx={themes.SingleAvatar}>
                        {props.order.baseOrderInfo.offers[0].tutor.anonymous ? (
                            <Box sx={themes.PhotoHidden}>
                                <Typography sx={themes.PhotoHiddenText}>{t("orders.studentOrders.photoHidden")}</Typography>
                            </Box>
                        ) : (
                            <Avatar
                                sx={themes.Avatar}
                                src={props.order.baseOrderInfo.offers[0].tutor.photo.link}
                            />
                        )}
                        <Typography sx={textThemes.Body2Black}>
                            {props.order.baseOrderInfo.offers[0].tutor.anonymous
                                ? t("orders.studentOrders.fioHidden")
                                : `${props.order.baseOrderInfo.offers[0].tutor.name} ${props.order.baseOrderInfo.offers[0].tutor.surname[0]}.`}
                        </Typography>
                    </Box>
                )}
                <Divider sx={themes.Divider} />
                <Box sx={themes.BottomBlock(props.order.baseOrderInfo.isPersonal)}>
                    <OrderSwitch order={props.order} />
                    {props.order.baseOrderInfo.isPersonal ? (
                        <Box sx={themes.PersonalApplicationContainer}>
                            <Typography sx={textThemes.Body2Black}>{t("orders.studentOrders.personalOrder")}</Typography>
                        </Box>
                    ) : props.order.baseOrderInfo.isOpened ? (
                        <Box sx={themes.ResponsesAndViewsBlock}>
                            <Box sx={themes.ResponsesContainer}>
                                <Typography sx={textThemes.Body2Grey}>{t("orders.studentOrders.responses")}</Typography>
                                <Typography sx={textThemes.Body2Black}>{props.order.baseOrderInfo.responses.length}</Typography>
                            </Box>
                            <Box sx={themes.ResponsesContainer}>
                                <Box sx={themes.ViewsIcon}>
                                    <ViewsIcon />
                                </Box>
                                <Typography sx={textThemes.Body2Black}>{props.order.baseOrderInfo.views.length}</Typography>
                            </Box>
                        </Box>
                    ) : null}
                </Box>
            </Box>
        </Box>
    );
});

export default memo(ActiveOrderCard);
