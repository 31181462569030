import * as React from "react";
import Box from "@mui/material/Box";
import ArrowIcon from "assets/CommonAssets/SVG/arrow";
import LineIcon from "assets/CommonAssets/SVG/line";
import * as styles from "./StepperThemes";
import * as textStyles from "themes/TextThemes";
import { Typography } from "@mui/material";

export default function VerticalLinearStepper({ activeStep, subStep, steplist, type, switchType }) {
    return (
        <Box sx={styles.StepperBox}>
            {steplist
                .filter((x) => x.type === type || x.type === "universal")
                .map((step, index) => {
                    if (index === activeStep) {
                        return (
                            <Box key={index}>
                                <Box sx={styles.ArrowBox}>
                                    <ArrowIcon />
                                    <Box sx={styles.StepLabel}>
                                        <Typography sx={textStyles.Caption1Black}>{step.substeps[subStep].label}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={styles.StepDescription}>
                                    <Typography sx={textStyles.Caption1Purple}>
                                        {step.substeps
                                            .reduce((acc, value) => (acc += value.description + ", "), "")
                                            .replaceAll("null, ", "")
                                            .slice(0, -2)}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    }

                    if (index > activeStep) {
                        return (
                            <Box key={index}>
                                <Box sx={styles.NextLabelBox}>
                                    {step.substeps[subStep]?.description === null ? <LineIcon /> : <Box sx={{ width: "16px" }} />}
                                    <Box sx={styles.StepLabel}>
                                        <Typography sx={textStyles.Caption1Grey}>{step.substeps[0].label}</Typography>
                                    </Box>
                                </Box>
                                {step.substeps[subStep]?.description && (
                                    <Box sx={styles.StepDescription}>
                                        <Typography sx={textStyles.Caption1Purple}>
                                            {step.substeps
                                                .reduce((acc, value) => (acc += value.description + ", "), "")
                                                .replaceAll("null, ", "")
                                                .slice(0, -2)}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        );
                    }

                    if (index < activeStep) {
                        return (
                            <Box
                                key={index}
                                sx={styles.PreviousStepBox}
                            >
                                <Typography sx={textStyles.Caption1Black}>{step.substeps[0].label}</Typography>
                                <Typography sx={textStyles.Caption1Purple}>
                                    {step.substeps
                                        .reduce((acc, value) => (acc += value.description + ", "), "")
                                        .replaceAll("null, ", "")
                                        .slice(0, -2)}
                                </Typography>
                            </Box>
                        );
                    }
                })}
        </Box>
    );
}
