import React from "react";

const Message = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M8.5 18.999H8C4 18.999 2 17.999 2 12.999V7.99902C2 3.99902 4 1.99902 8 1.99902H16C20 1.99902 22 3.99902 22 7.99902V12.999C22 16.999 20 18.999 16 18.999H15.5C15.19 18.999 14.89 19.149 14.7 19.399L13.2 21.399C12.54 22.279 11.46 22.279 10.8 21.399L9.3 19.399C9.14 19.179 8.77 18.999 8.5 18.999Z"
                stroke="#222222"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.9965 11.0029H16.0054"
                stroke="#222222"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.9965 11.0029H12.0054"
                stroke="#222222"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.99451 11.0029H8.00349"
                stroke="#222222"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Message;
