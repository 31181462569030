export const weekDatesNoneBorder = {
    marginLeft: "10px",
    maxWidth: "1188px",
    border: "none",
};

export const weekDatesTextColor = {
    boxSizing: "border-box",
    width: "161px",
    height: "52px",
    paddingTop: "12px",
    paddingBottom: "14px",
};

export const dateNumber = {
    paddingRight: "4px",
    fontSize: "16px",
    fontWeight: 600,
};

export const dayOfWeek = {
    fontSize: "14px",
    fontWeight: 400,
};
