import React from "react";
import LeftPanel from "components/SearchRequest/LeftPanel/LeftPanel";
import RightPanel from "components/SearchRequest/RightPanel/RightPanel";
import store from "store/RequestStore";

import { observer } from "mobx-react-lite";
import * as styles from "./SearchRequestThemes";
import Box from "@mui/material/Box";
import RequestTooltip from "components/SearchRequest/RequestTooltip/RequestTooltip";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

const SearchRequest = () => {
    return (
        <ScrollToTop>
            <Box sx={styles.RequestPage}>
                <Box sx={styles.QuestionPage}>
                    <Box sx={styles.StepperNQuestion}>
                        <Box sx={styles.LeftPanel}>
                            <LeftPanel />
                        </Box>
                        <RightPanel />
                    </Box>
                    {store.storage[store.activeStepIndex].substeps[store.subStepIndex].tooltip !== null ? (
                        <RequestTooltip text={store.storage[store.activeStepIndex].substeps[store.subStepIndex].tooltip.text} />
                    ) : null}
                </Box>
            </Box>
        </ScrollToTop>
    );
};

export default observer(SearchRequest);
