import React, { memo, useEffect } from "react";
import Box from "@mui/material/Box";
import * as themes from "./AlterActionsAreaThemes";
import { Tab, Tabs } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import TeacherStore from "store/TeacherStore";
import { observer } from "mobx-react-lite";
import { useSearchParams } from "react-router-dom";

const AlterActionsArea = observer(({ children, ...props }) => {
    const handleChange = (event, newValue) => {
        TeacherStore.SetSelectedTab(newValue);
        props.onTabChanged(event, newValue);
    };

    const [searchParams, SetSearchParams] = useSearchParams();

    useEffect(() => {
        let initialTab = searchParams.get("initialTab");

        if (initialTab) {
            TeacherStore.SetSelectedTab(initialTab);
            props.onTabChanged(null, initialTab);
            SetSearchParams();
        }
    }, [searchParams]);

    return (
        <Box sx={themes.alterActionsContainerStyle}>
            <ThemeProvider theme={themes.TabsTheme}>
                <Tabs
                    value={TeacherStore.selectedTab}
                    onChange={handleChange}
                >
                    <Tab
                        value={"DescriptionCard"}
                        disableRipple={true}
                        label="О репетиторе"
                    />
                    <Tab
                        value={"ScheduleCard"}
                        disableRipple={true}
                        label="Расписание"
                    />
                    <Tab
                        value={"ReviewsCard"}
                        disableRipple={true}
                        label="Отзывы"
                    />
                    <Tab
                        value={"EducationCard"}
                        disableRipple={true}
                        label="Резюме"
                    />
                    <Tab
                        value={"DisciplinesCard"}
                        disableRipple={true}
                        label="Предметы"
                    />
                </Tabs>
            </ThemeProvider>
        </Box>
    );
});

export default memo(AlterActionsArea);
