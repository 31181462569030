const VisaPaymentIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="9"
            viewBox="0 0 27 9"
            fill="none"
        >
            <g clipPath="url(#clip0_1919_64510)">
                <path
                    d="M17.2568 0.015625C15.3771 0.015625 13.6973 0.935211 13.6973 2.63422C13.6973 4.58265 16.6765 4.71723 16.6765 5.69607C16.6765 6.10822 16.1761 6.47716 15.3214 6.47716C14.1084 6.47716 13.2018 5.96164 13.2018 5.96164L12.8139 7.67613C12.8139 7.67613 13.8583 8.11158 15.2448 8.11158C17.3 8.11158 18.9171 7.14684 18.9171 5.41879C18.9171 3.35991 15.9255 3.22933 15.9255 2.3208C15.9255 1.99795 16.3363 1.64419 17.1886 1.64419C18.1502 1.64419 18.9347 2.01911 18.9347 2.01911L19.3143 0.363208C19.3143 0.363208 18.4607 0.015625 17.2568 0.015625ZM0.0591874 0.140599L0.0136719 0.390546C0.0136719 0.390546 0.804474 0.527149 1.51672 0.79964C2.4338 1.11211 2.49912 1.29401 2.65357 1.85899L4.33661 7.9827H6.59274L10.0685 0.140599H7.81752L5.58416 5.47249L4.67281 0.952928C4.58923 0.435668 4.16588 0.140599 3.64768 0.140599H0.0591874ZM10.9736 0.140599L9.20781 7.9827H11.3543L13.1139 0.140599H10.9736ZM22.9452 0.140599C22.4276 0.140599 22.1534 0.402148 21.9522 0.859198L18.8075 7.9827H21.0584L21.4939 6.79545H24.2362L24.501 7.9827H26.4872L24.7545 0.140599H22.9452ZM23.238 2.25929L23.9052 5.20204H22.1177L23.238 2.25929Z"
                    fill="#1434CB"
                />
            </g>
            <defs>
                <clipPath id="clip0_1919_64510">
                    <rect
                        width="26.5"
                        height="8.12097"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default VisaPaymentIcon;
