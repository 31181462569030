import React from "react";
import * as themes from "./CashbackStartThemes";
import TextBlock from "components/CashbackPromoBodyBlock/CashbackStart/TextBlock/TextBlock";
import ImgBlock from "components/CashbackPromoBodyBlock/CashbackStart/ImgBlock/ImgBlock";
import { Box } from "@mui/material";
import { memo } from "react";

const CashbackStart = ({ children, ...props }) => {
    return (
        <Box sx={themes.CashbackStartBlock}>
            <TextBlock />
            <ImgBlock />
        </Box>
    );
};

export default memo(CashbackStart);
