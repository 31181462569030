export const tutorCashbackBlock = {
    width: "1216px",
    height: "744px",
    marginTop: "140px",
    position: "relative",
};

export const blockHeader = {
    color: "#222",
    fontFamily: "Inter",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "135%" /* 54px */,
};

export const blockSubheader = {
    color: "#222",
    /* Body-1 */
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%" /* 24px */,
    marginTop: "16px",
};

export const cashbackFunctionsBlock = {
    gap: "20px",
    display: "flex",
    flexDirection: "column",
    width: "392px",
    position: "relative",
};

export const functionElement = {
    display: "flex",
    gap: "12px",
};

export const functionText = {
    color: "#222",
    /* Body-1 */
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%" /* 24px */,

    width: "362px",
};

export const iconElem = {
    height: "fit-content",
    marginTop: "4px",
};

export const bronzeDefaultLvlCardPosition = {
    position: "absolute",
    left: "618px",
    top: "0",
};

export const silverDefaultLvlCardPosition = {
    position: "absolute",
    left: "927px",
    top: "0",
};

export const goldDefaultLvlCardPosition = {
    position: "absolute",
    left: "412px",
    top: "392px",
};

export const goldPremiumLvlCard = {
    position: "absolute",
    left: "721px",
    top: "392px",
};

export const IconCrownPostition = {
    position: "absolute",
    left: "950px",
    top: "367px",
};

export const TitleBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const LeftPanel = {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
};
