//Ваше место обучения?
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import SRadioGroup from "components/UI/RadioGroup";
import SBButtonGroup from "components/UI/ButtonGroup";
import store from "store/RequestStore";
import { universities, universityType } from "API/dictionaries/const_data";
import RequestStore from "store/RequestStore";

import SBAutoComplete from "components/UI/Autocomplete";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import StepHiddenButtonWrapper from "components/OrdersBodyBlock/RenewOrder/StepHiddenButtonWrapper";
import filterStore from "store/FilterStore";
import RequestNullAlert from "./RequestNullAlert";
import * as theme from "./Step19Themes";
const Step19 = () => {
    const [alert, setAlert] = useState(0);

    const [stepData, setStepData] = useState(RequestStore.storage[store.activeStepIndex].substeps[store.subStepIndex].value[0]);

    const [stepData1, setStepData1] = useState(RequestStore.storage[store.activeStepIndex].substeps[store.subStepIndex].value[1]);

    const [hydeData, setHydeData] = useState(RequestStore.storage[store.activeStepIndex].substeps[store.subStepIndex].value[2]);

    return (
        <Box>
            <SRadioGroup
                changefunc={setStepData}
                value={stepData}
                list={universityType}
            />
            <Box sx={theme.SBButtonGroupWrapper}>
                <SBAutoComplete
                    value={stepData1}
                    changefunc={setStepData1}
                    inputvalue={stepData1}
                    inputchangefunc={setStepData1}
                    list={universities}
                    placeholder="Название или аббревиатура ВУЗа или колледжа"
                />
            </Box>
            <Box sx={theme.FormControlWrapper}>
                <FormControlLabel
                    sx={theme.FormControlLabel}
                    control={
                        <Checkbox
                            sx={theme.Checkbox}
                            checked={hydeData}
                            onChange={(event, newInputValue) => {
                                setHydeData(newInputValue);
                                filterStore.setSubmitButtonFilter(false);
                            }}
                            style={{ color: "#6212FF" }}
                        />
                    }
                />
                <Box sx={theme.Text}>Не показывать заказ репетиторам из моего ВУЗа (студентам, выпускникам, преподавателям)</Box>
            </Box>

            <StepHiddenButtonWrapper
                submitRenew={() => {
                    let description = stepData + ", " + stepData1;

                    if (stepData) {
                        store.setStep(11, description);
                        store.setStepValue(11, [stepData, stepData1, hydeData]);
                        store.incrementStepIndex();
                    }
                }}
            >
                <SBButtonGroup
                    handleNext={() => {
                        let description = stepData + ", " + stepData1;

                        if (stepData) {
                            store.setStep(11, description);
                            store.setStepValue(11, [stepData, stepData1, hydeData]);
                            store.incrementStepIndex();
                        } else {
                            setAlert(1);
                        }
                    }}
                    handleBack={() => store.decrementStepIndex()}
                    activeStep={store.activeStepIndex}
                    steps={store.storage}
                />
                {alert === 1 ? <RequestNullAlert>Чтобы перейти к следующему вопросу, выберите один из вариантов</RequestNullAlert> : null}
            </StepHiddenButtonWrapper>
        </Box>
    );
};

export default observer(Step19);
