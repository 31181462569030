import * as themes from "./FilterPartThemes";
import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import CashbackFilter from "./CashbackFilter";
import DisciplineFilter from "./DisciplineFilter";
import SearchFilter from "./SearchFilter";
import SortFilter from "./SortFilter";
import AudienceFilter from "./AudienceFilter";
import GoalFilter from "./GoalFilter";
import AdditionalFilters from "./AdditionalFilters";
import CostFilter from "./CostFilter";
import CatalogStore from "store/CatalogStore";
import { useMedias } from "../../../../API/mediasHook";

const FilterPart = observer(({ children, ...props }) => {
    const search = async () => {
        await CatalogStore.ExecuteSearch();
    };

    const medias = useMedias();

    return (
        <Box sx={themes.filtersBlock(medias)}>
            <Box sx={themes.filtersBlockMainRow(medias)}>
                <Box sx={themes.filtersDropdownsBlock(medias)}>
                    <DisciplineFilter></DisciplineFilter>
                    <Box sx={themes.filtersVerticalSeparator(medias)}></Box>
                    <AudienceFilter></AudienceFilter>
                    <Box sx={themes.filtersVerticalSeparator(medias)}></Box>
                    <GoalFilter></GoalFilter>
                    <Box sx={themes.filtersVerticalSeparator(medias)}></Box>

                    <Box sx={{ display: "flex", gap: "10px" }}>
                        <CostFilter></CostFilter>
                        {!medias.sm && (
                            <Box
                                onClick={search}
                                sx={themes.filtersMobileSearchBtn}
                            >
                                <Typography sx={themes.textFind(medias)}>Найти</Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
                {medias.sm && (
                    <Box
                        onClick={search}
                        sx={themes.filtersSearchBtn}
                    >
                        <Typography sx={themes.textFind(medias)}>Найти</Typography>
                    </Box>
                )}
            </Box>

            <Box sx={themes.filtersWrapper}>
                {medias.sm && <SearchFilter></SearchFilter>}
                <Box sx={themes.filtersAdditionalBlock}>
                    {medias.sm ? (
                        <>
                            <CashbackFilter />
                            <SortFilter />
                            <AdditionalFilters />
                        </>
                    ) : (
                        <>
                            <SortFilter />
                            <CashbackFilter />
                            <AdditionalFilters />
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
});

export default memo(FilterPart);
