export const Step17Wrapper = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "150%",
    fontWeight: 400,
    textTransform: "none",
    color: "#67677A",
    marginBottom: "20px",
};

export const TextField = {
    height: "136px",
    border: "1px solid #BBBBC8",
    borderRadius: "10px",
    width: 497,
    "& ::placeholder": {},

    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: "#6212FF",
            borderWidth: "1px",
        },
        "&:hover fieldset": {
            borderColor: "#6212FF",
            borderWidth: "1px",
        },
    },
    "& .MuiInputBase-root": {
        "& input": {
            textAlign: "left",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "150%",
            color: "#222",
        },
    },
};

export const InputProps = {
    sx: {
        height: "136px",
        display: "flex",
        alignItems: "start",
        "&::-webkit-scrollbar": {
            width: "0.3em",
        },

        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#bbbbc8",
            borderRadius: "10px",
        },
    },
};
