import { createTheme } from "@mui/material/styles";

export const FiltersAdditionalTheme = (medias) => {
    return {
        components: {
            MuiToggleButton: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        // Some CSS
                        //width: "150px",
                        width: medias.sm ? "150px" : "fit-content",
                        height: "44px",
                        flex: "none",
                        background: "#4E4E4E",
                        borderRadius: medias.sm ? "10px" : "12px",
                        order: 2,
                        flexGrow: 0,
                        "&.Mui-selected": {
                            background: "#222222",
                        },
                        "&:hover": {
                            background: "#222222!important",
                        },
                    },
                },
            },
        },
    };
};

export const spanAdditionalDefault = {
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "150%",
    color: "#FFFFFF",
};

export const spanAdditionalSelected = {
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "150%",
    color: "#FFFFFF",
};

export const iconAdditional = (medias) => {
    return {
        color: "white",
        width: "20px",
        height: "20px",
        marginRight: medias.sm ? "9px" : "fit-content",
    };
};

export const filtersCounterContainerStyle = (medias) => {
    return {
        width: medias.sm ? "22px" : "20px",
        height: medias.sm ? "22px" : "20px",
        flexShrink: 0,
        background: "#6212FF",
        borderRadius: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        right: "-10px",
        top: "-10px",
    };
};

export const filtersCounterTextStyle = {
    color: "#FFFFFF",
    textAlign: "center",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "130%",
    letterSpacing: "0.24px",
    paddingTop: 1,
    paddingLeft: 1,
};
