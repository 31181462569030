import { Typography } from "@mui/material";
import IconComp from "assets/MyClassesAssets/SVG/IconComp";
import SButton from "components/UI/SButton";
import React from "react";
import { memo } from "react";
import * as styles from "./NewClassesThemes";
import * as textStyles from "themes/TextThemes";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import ModalChooseTutor from "./ModalChooseTutor/ModalChooseTutor";
import ModalCalendarReschedule from "../../MyClassesList/StudyListItem/ClassMoreMenu/ClassMoreMenuContent/Modals/ModalCalendarReschedule/ModalCalendarReschedule";
import ModalBookClass from "./ModalBookClass/ModalBookClass";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentStore from "../../../../store/PaymentStore";
import { useMedias } from "API/mediasHook";
import myClassesStore from "store/MyClassesStore";
import { observer } from "mobx-react-lite";
const NewClasses = observer(() => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const location = useLocation();

    //Choose tutor
    const [dialogChooseTutorOpen, SetDialogChooseTutorOpen] = React.useState(false);

    const showChooseTutorDialog = () => {
        myClassesStore.SetDialogChooseTutorOpen(true);
    };

    const discardChooseTutorDialog = () => {
        myClassesStore.SetDialogChooseTutorOpen(false);
    };

    const saveChooseTutorDialog = () => {
        myClassesStore.SetDialogChooseTutorOpen(false);
        showScheduleClassDialog();
    };

    //Schedule class
    const [dialogScheduleClassOpen, SetDialogScheduleClassOpen] = React.useState(false);

    const showScheduleClassDialog = () => {
        myClassesStore.SetDialogScheduleClassOpen(true);
    };

    const discardScheduleClassDialog = () => {
        myClassesStore.SetDialogScheduleClassOpen(false);
    };

    const saveScheduleClassDialog = () => {
        myClassesStore.SetDialogScheduleClassOpen(false);
        PaymentStore.setPreviousPageRoute(location.pathname);
        navigate("/payment");
    };

    return (
        <Box sx={styles.NewClassesWithTutor}>
            <ModalChooseTutor
                open={myClassesStore.dialogChooseTutorOpen}
                handleDiscard={discardChooseTutorDialog}
                handleSave={saveChooseTutorDialog}
            />

            <ModalBookClass
                open={myClassesStore.dialogScheduleClassOpen}
                handleDiscard={discardScheduleClassDialog}
                handleSave={saveScheduleClassDialog}
            />

            {medias.sm && (
                <>
                    <Box sx={styles.NewClassesWithTutorContent}>
                        <Box>
                            <IconComp />
                        </Box>
                        <Typography sx={textStyles.HeadlineH5Black}>{t("main.myClasses.scheduleNewClasses")}</Typography>
                    </Box>
                    <Box sx={styles.SignUpForClass}>
                        <SButton
                            variant="arrow"
                            padding="7px 16px 8px 16px"
                            style={styles.SignUpForClassButton}
                            onClick={showChooseTutorDialog}
                        >
                            {t("main.myClasses.signUpForClass")}
                        </SButton>
                    </Box>
                </>
            )}
        </Box>
    );
});

export default memo(NewClasses);
