const DeleteLinkIcon = function (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M12 4L4 12"
                stroke="#67677A"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M4 4L12 12"
                stroke="#67677A"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default DeleteLinkIcon;
