export const HeaderAccountButton = {
    textTransform: "none",
    padding: 0,
    borderStyle: "solid",
    textAlign: "center",
    ":hover": {
        background: "transparent",
        color: "#222",
    },
};

export const LeftPanelButton = (medias) => {
    return {
        display: medias.sm ? "flex" : "none",
        alignItems: "center",
        gap: "20px",
    };
};

export const LeftPanel = (medias) => {
    return {
        display: "flex",
        alignItems: "center",
        gap: medias.sm ? "40px" : "17px",
        margin: "0",
    };
};

export const HeaderMenu = {
    cursor: "pointer",
    padding: 0,
};

export const HeaderMobileMenuIcon = {
    padding: 0,
};

export const StudyBuddyLogoIcon = {
    p: 0,
    m: 0,
};
