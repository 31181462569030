const numberFormatting = (value, mode = 0) => {
    let val = "";

    let DecimalDelimiter = ",";

    if (value !== "" && value != null) {
        if (typeof value === "string" || value instanceof String) {
            value = value.replaceAll(",", ".").replaceAll(" ", "");
        }

        if (value === 0) {
            return `0`;
        }

        if (mode === 0) {
            val = innerNumberFormat(value, 1, DecimalDelimiter, " ");
        }

        if (mode === 1) {
            val = innerNumberFormat(value, 2, DecimalDelimiter, " ");
        }

        if (mode === 2) {
            val = innerNumberFormat(value, 5, DecimalDelimiter, " ");
        }

        return val;
    } else {
        return "";
    }
};

const innerNumberFormat = (number, decimals, dec_point, separator) => {
    number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
    let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof separator === "undefined" ? "," : separator,
        dec = typeof dec_point === "undefined" ? "." : dec_point,
        s = "",
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);

            return "" + (Math.round(n * k) / k).toFixed(prec);
        };

    s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }

    if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
    }

    return s.join(dec);
};

const checkKeyDown = (e, modifiers) => {
    // delete, backspace, tab, escape, enter
    let special = ["Delete", "Backspace", "Tab", "Escape", "Enter", "Insert"];

    if (modifiers.includes("decimal")) {
        // decimal(numpad), period
        special.push(".", "-", ",");
    }

    if (modifiers.includes("special")) {
        // user case
        special.push("!", "@", "#", "$", "%", "^", "&", "*", ")", "(", "+", "=", ".", "<", ">", "{", "}", "[", "]", ":", ";", "'", '"', "|", "~", "`", "_", "-");
    }

    // special from above
    if (
        special.indexOf(e.key) !== -1 ||
        // Ctrl+A
        (e.keyCode === 65 && e.ctrlKey === true) ||
        // Ctrl+C
        (e.keyCode === 67 && e.ctrlKey === true) ||
        // Ctrl+V
        (e.keyCode === 86 && e.ctrlKey === true) ||
        // Ctrl+X
        (e.keyCode === 88 && e.ctrlKey === true) ||
        // home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
        return; // allow
    }

    if (
        modifiers.includes("alpha") &&
        // a-z/A-Z/а-я/А-Я
        /[а-яА-Яa-zA-Z]/.test(e.key)
    ) {
        return; // allow
    }

    if (
        modifiers.includes("space") &&
        // space
        e.key === " "
    ) {
        return; // allow
    }

    if (
        modifiers.includes("number") &&
        // number keys without shift
        ((!e.shiftKey && /[0-9]/.test(e.key)) ||
            // numpad number keys
            /[0-9]/.test(e.key))
    ) {
        return; // allow
    }

    // otherwise stop the keystroke
    e.preventDefault(); // prevent
};

const styleProcessing = (theme, stylesObject) => {
    if (theme.xl) {
        return {
            ...stylesObject.xlStyle,
        };
    }

    if (theme.lg) {
        return {
            ...stylesObject.lgStyle,
        };
    }

    if (theme.md) {
        return {
            ...stylesObject.mdStyle,
        };
    }

    if (theme.sm) {
        return {
            ...stylesObject.smStyle,
        };
    }

    if (theme.xs) {
        return {
            ...stylesObject.xsStyle,
        };
    }

    return stylesObject.defaultStyle;
};

export { numberFormatting, checkKeyDown, styleProcessing };
