import React from "react";
import { Stack, Typography } from "@mui/material";
import * as styles from "components/DiplomaChats/styles";
import PdfIconSVG from "assets/CommonAssets/SVG/pdfIconSVG";
import DocIconSVG from "assets/CommonAssets/SVG/docIconSVG";

const FILE_TYPES = {
    pdf: <PdfIconSVG />,
    doc: <DocIconSVG />,
};

const FileInfo = ({
    fileInfo = {
        size: "3.2MB",
        type: "pdf",
        name: "Требования к дипломной работе.pdf",
        date: "30 апреля в 20:30",
        link: "link",
    },
}) => {
    return (
        <Stack
            spacing={1.25}
            direction="row"
        >
            <span style={styles.fileCont}>{FILE_TYPES[fileInfo.type]}</span>
            <Stack spacing={0.375}>
                <Typography variant="standard">{fileInfo.name}</Typography>
                <Stack
                    direction="row"
                    spacing={0.75}
                    alignItems="center"
                    alignContent="center"
                >
                    <Typography variant="grayText">{`${fileInfo.size} • ${fileInfo.date}`}</Typography>
                    <span style={styles.downloadButton}>Скачать</span>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default FileInfo;
