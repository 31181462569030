import React, { memo } from "react";
import Paper from "@mui/material/Paper";
import { List } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import MyClassesStore from "store/MyClassesStore";
import { observer } from "mobx-react-lite";
import * as styles from "./FilterClassesContentThemes";
import { MarkedIcon } from "assets/MyClassesAssets/SVG/MarkedIcon";

const FilterClassesContent = observer(({ children, ...props }) => {
    const handleMenuItemClickCurrent = (event, index) => {
        MyClassesStore.setSelectedIndexUpcoming(index);
        props.setOpen(false);
        console.log(MyClassesStore.selectedIndexUpcoming, "rrr");
    };

    const handleMenuItemClickPast = (event, index) => {
        MyClassesStore.setSelectedIndexPrevious(index);
        props.setOpen(false);
    };

    return (
        <Paper sx={styles.Paper}>
            <List sx={styles.List}>
                {MyClassesStore.page === 1
                    ? MyClassesStore.optionsPrevious.map((option, index) => (
                          <MenuItem
                              disableRipple
                              key={option}
                              sx={styles.MenuItem}
                              onClick={(event) => handleMenuItemClickPast(event, index)}
                          >
                              {option}
                              {index === MyClassesStore.selectedIndexPrevious ? <MarkedIcon /> : <></>}
                          </MenuItem>
                      ))
                    : MyClassesStore.optionsUpcoming.map((option, index) => (
                          <MenuItem
                              disableRipple
                              key={option}
                              sx={styles.MenuItem}
                              onClick={(event) => handleMenuItemClickCurrent(event, index)}
                          >
                              {option}
                              {index === MyClassesStore.selectedIndexUpcoming ? <MarkedIcon /> : <></>}
                          </MenuItem>
                      ))}
            </List>
        </Paper>
    );
});

export default memo(FilterClassesContent);
