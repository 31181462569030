import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { ThemeProvider } from "@mui/material/styles";
import * as themes from "./LessonsFormatThemes";
import CatalogStore from "store/CatalogStore";
import FreeMultipleChoiceFilter from "components/UI/FreeMultipleChoiceFilter";
import { useMedias } from "../../../API/mediasHook";

const LessonsFormat = observer(({ children, ...props }) => {
    const handleChange = () => {
        CatalogStore.ToggleLessonsFormatExpanded();
    };

    const processingLessonsFormat = (event, value) => {
        CatalogStore.SetLessonsFormat(value);
    };

    const medias = useMedias();

    return (
        <ThemeProvider theme={themes.LessonsFormatAccordionTheme(medias)}>
            <Accordion
                expanded={CatalogStore.lessonsFormatExpanded}
                onChange={handleChange}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography sx={themes.headerLessonsFormatStyle}>Формат занятий</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FreeMultipleChoiceFilter
                        propOptions={CatalogStore.optionsLessonsFormat}
                        valueToShow={"LessonFormat"}
                        valueToBind={"Checked"}
                        valueForKey={"ID"}
                        processingChange={processingLessonsFormat}
                        themeWrapper={themes.FiltersLessonsFormatTheme}
                    ></FreeMultipleChoiceFilter>
                </AccordionDetails>
            </Accordion>
        </ThemeProvider>
    );
});

export default memo(LessonsFormat);
