import { Box, styled } from "@mui/material";

export const OrderCardsWrapper = styled(Box)({
    maxWidth: "1216px",
    display: "grid",
    gap: "20px",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 598px))",
    justifyContent: "center",
    margin: "0 auto",
});
