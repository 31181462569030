import { styleProcessing } from "API/plugins/Utilities";

export const CardInfo = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const ButtonBlock = (theme) => {
    let commonStyle = {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            alignItems: "left",
            gap: "30px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TextAndButtonBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
};

export const SmallItem = (theme) => {
    let commonStyle = {
        backgroundColor: "white",
        borderRadius: "20px",
        padding: "30px",
        maxWidth: "641px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const PaymentMethodsBoxGroup = {
    display: "flex",
    gap: "16px",
};
