const DropDownSVG = function (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M7.64645 9.64645L3.85355 5.85355C3.53857 5.53857 3.76165 5 4.20711 5H11.7929C12.2383 5 12.4614 5.53857 12.1464 5.85355L8.35355 9.64645C8.15829 9.84171 7.84171 9.84171 7.64645 9.64645Z"
                fill="#67677A"
            />
        </svg>
    );
};

export default DropDownSVG;
