import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import { Button, Dialog, IconButton } from "@mui/material";
import * as themes from "./ModalDisciplinesThemes";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CloseRounded } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import FreeMultipleChoiceFilter from "components/UI/FreeMultipleChoiceFilter";
import OrdersSearchStore from "store/OrdersSearchStore";
import TextFilter from "components/UI/TextFilter";

const ModalDisciplines = observer(({ children, ...props }) => {
    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        props.handleSave();
    };

    const processingOrdersDiscipline = (event, value) => {
        OrdersSearchStore.SetOrdersDiscipline(value);
    };

    const clearSearch = () => {
        OrdersSearchStore.SetDisciplineSearchDebounced("");
    };

    const handleSearch = (e) => {
        OrdersSearchStore.SetDisciplineSearchDebounced(e.target.value);
    };

    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={props.open}
                onClose={Close}
            >
                <Box sx={themes.dialogContainerStyle}>
                    <ThemeProvider theme={themes.CloseIconTheme}>
                        <IconButton
                            onClick={Close}
                            disableRipple
                        >
                            <CloseRounded></CloseRounded>
                        </IconButton>
                    </ThemeProvider>
                    <Box sx={themes.cardContainerStyle}>
                        <Box sx={themes.headerBlockContainerStyle}>
                            <Typography sx={themes.headerTextStyle}>Выберите предмет</Typography>
                            <TextFilter
                                valueToBind={OrdersSearchStore.search}
                                handleFunc={handleSearch}
                                clearFunc={clearSearch}
                                placeholder={"Введите название предмета"}
                                themeWrapper={themes.OrdersDisciplineSearcherTheme}
                            ></TextFilter>
                        </Box>
                        <Box sx={themes.contentBlockContainerStyle}>
                            <Typography sx={themes.listHeaderTextStyle}>Все предметы</Typography>
                            <Box sx={themes.contentBlockStyle}>
                                <FreeMultipleChoiceFilter
                                    propOptions={OrdersSearchStore.optionsOrdersDisciplineFiltered}
                                    valueToShow={"OrdersDiscipline"}
                                    valueToBind={"Checked"}
                                    valueForKey={"ID"}
                                    processingChange={processingOrdersDiscipline}
                                    themeWrapper={themes.FiltersOrdersDisciplineTheme}
                                ></FreeMultipleChoiceFilter>
                            </Box>
                        </Box>
                        <Box sx={themes.actionsBlockContainerStyle}>
                            <Box sx={themes.actionsContainerStyle}>
                                <ThemeProvider theme={themes.ModalActionButtonTheme(0)}>
                                    <Button
                                        onClick={Close}
                                        disableRipple
                                    >
                                        <Typography style={themes.buttonTextStyle(0)}>Отменить</Typography>
                                    </Button>
                                </ThemeProvider>
                                <ThemeProvider theme={themes.ModalActionButtonTheme(1)}>
                                    <Button
                                        onClick={Save}
                                        disableRipple
                                    >
                                        <Typography style={themes.buttonTextStyle(1)}>Добавить</Typography>
                                    </Button>
                                </ThemeProvider>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </ThemeProvider>
    );
});

export default memo(ModalDisciplines);
