import React from "react";
import * as themes from "./QualityThemes";
import * as textThemes from "themes/TextThemes";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { memo } from "react";

const Quality = ({ children, ...props }) => {
    return (
        <Box sx={themes.qualityBlock}>
            <Typography sx={textThemes.HeadlineH5White}>{props.header}</Typography>
            <Typography sx={textThemes.Body1White}>{props.text}</Typography>
        </Box>
    );
};

export default memo(Quality);
