import React from "react";
import ReactPlayer from "react-player";
import { useMedias } from "API/mediasHook";
import * as theme from "./TutorVideoStyle";
import { memo } from "react";
import "./Video.css";
import { Box } from "@mui/system";
import { getPlayerTheme } from "./TutorVideoStyle";

function MyPlayer({ children, ...props }) {
    const medias = useMedias();

    return (
        <Box sx={theme.getPlayerTheme}>
            <ReactPlayer
                className="react-player-custom"
                controls={true}
                url={props.VideoName}
                width={theme.getPlayerStyles(medias).width}
                height={theme.getPlayerStyles(medias).height}
                playing={true}
            />
        </Box>
    );
}

export default memo(MyPlayer);
