import React, { memo, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import FavouritesBodyBlock from "components/FavouritesPage/FavouritesBodyBlock/FavouritesBodyBlock";
import FavouritesTopBlock from "components/FavouritesPage/FavouritesTopBlock/FavouritesTopBlock";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import CatalogStore from "../../store/CatalogStore";
import FavouritesStore from "../../store/FavouritesStore";

const theme = createTheme({
    spacing: 1,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const FavouritesPage = observer(({ props }) => {
    useEffect(() => {
        const fetchFunc = async () => {
            await FavouritesStore.FetchPageData();
        };

        fetchFunc();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    background: "#F3F3F3",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    borderRadius: "0px 0px 40px 40px",
                }}
            >
                <FavouritesTopBlock></FavouritesTopBlock>
                <FavouritesBodyBlock></FavouritesBodyBlock>
            </Box>
        </ThemeProvider>
    );
});

export default memo(FavouritesPage);
