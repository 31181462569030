import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import * as styles from "./SettingsTabsThemes";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "hooks/Throttle";

const SettingsTabs = ({ profile, notification, payment, telegram }) => {
    const { t, i18n } = useTranslation();

    const windowSize = useWindowSize();

    const [buttondisabled, setButtonDis] = useState(true);

    const [page, setPage] = useState(0);

    const [value, setValue] = useState("");

    const windowSizePx = `${windowSize}px`;

    const PageChange = (event, newValue) => {
        setPage(newValue);
        setValue("");
        setButtonDis(true);
    };

    const ScrollTo = (item) => {
        if (item === "1") profile.current?.scrollIntoView({ behavior: "smooth" });

        if (item === "2") payment.current?.scrollIntoView({ behavior: "smooth" });

        if (item === "3") notification.current?.scrollIntoView({ behavior: "smooth" });

        if (item === "4") telegram.current?.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <Box sx={{ maxWidth: windowSizePx, overflow: "hidden" }}>
            <Tabs
                value={page}
                onChange={PageChange}
                sx={styles.SettingsTabs}
                TabIndicatorProps={{ style: styles.TabIndicatorProps }}
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab
                    disableRipple
                    sx={styles.SettingsTab}
                    label={t("main.settings.account")}
                    onClick={() => {
                        ScrollTo("1");
                    }}
                />
                <Tab
                    disableRipple
                    sx={styles.SettingsTab}
                    label={t("main.settings.paymentMethods")}
                    onClick={() => {
                        ScrollTo("2");
                    }}
                />
                <Tab
                    disableRipple
                    sx={styles.SettingsTab}
                    label={t("main.settings.notifications")}
                    onClick={() => {
                        ScrollTo("3");
                    }}
                />
                <Tab
                    disableRipple
                    sx={styles.SettingsTab}
                    label={t("main.settings.telegramBot")}
                    onClick={() => {
                        ScrollTo("4");
                    }}
                />
            </Tabs>
        </Box>
    );
};

export default SettingsTabs;
