import React, { memo } from "react";
import { ThemeProvider } from "@mui/material/styles";
import * as themes from "./AvatarAreaThemesDefault";
import { Avatar, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useMedias } from "../../../../API/mediasHook";

const AvatarArea = ({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box>
            {props.value.IsAnonymous ? (
                <Box>
                    <Box sx={themes.anonymousAvatarContainer(medias)}>
                        <Typography sx={themes.anonymousAvatarTextStyle}>Фото скрыто</Typography>
                    </Box>
                </Box>
            ) : (
                <Box>
                    <ThemeProvider theme={themes.AvatarTheme(medias)}>
                        <Avatar src={props.value.AvatarSrc}></Avatar>
                    </ThemeProvider>
                </Box>
            )}
        </Box>
    );
};

export default memo(AvatarArea);
