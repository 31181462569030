export const Create = ({ color }) => {
    return (
        <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon/24/create">
                <g id="Group 147">
                    <g id="Group 146">
                        <path
                            id="line"
                            d="M7.5 12L16.5 12"
                            stroke={color}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            id="line_2"
                            d="M12 7.5L12 16.5"
                            stroke={color}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                    </g>
                    <circle
                        id="Ellipse 13"
                        cx="12"
                        cy="12"
                        r="8.5"
                        stroke={color}
                    />
                </g>
            </g>
        </svg>
    );
};
