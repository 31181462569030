import React from "react";
import * as theme from "./OrderResponseWrappStyled";
import OrderResponseInfo from "components/OrderResponse/OrderResponseInfo/OrderResponseInfo";
import { observer } from "mobx-react-lite";
import OrderResponseTabs from "components/OrderResponse/OrderResponseTabs/OrderResponseTabs";

const OrderResponseWrapp = observer(() => {
    return (
        <theme.StyledOrderResponseWrapper>
            <OrderResponseInfo />
            <OrderResponseTabs />
        </theme.StyledOrderResponseWrapper>
    );
});

export default OrderResponseWrapp;
