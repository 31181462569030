import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import orderResponseStore from "store/OrderResponseStore";
import * as themes from "./ComplainTextFieldStyled";
const ComplainTextField = ({ otherValue, setOtherValue }) => {
    const [error, setError] = useState(false);

    const handleOtherChange = (event) => {
        const value = event.target.value;

        if (value.length <= 1000) {
            setOtherValue(value);
            setError(false);
        }

        if (value.length === 1000) {
            setError(true);
        }
    };

    useEffect(() => {
        if (otherValue <= 0) {
            orderResponseStore.setIsDisabled(true);
        } else {
            orderResponseStore.setIsDisabled(false);
        }
    }, [otherValue]);
    return (
        <>
            <themes.StyledComplainTextField
                value={otherValue}
                onChange={handleOtherChange}
                placeholder="Опишите жалобу своими словами..."
                multiline
                rows={6}
                error={error}
            />
            <themes.TextBox>
                {error && (
                    <Typography
                        sx={{
                            color: error ? "rgba(225, 38, 38, 1)" : null,
                            fontSize: "14px",
                        }}
                    >
                        Достигнут лимит символов
                    </Typography>
                )}
                <Box sx={{ marginLeft: "auto" }}>
                    <Typography
                        variant="span"
                        sx={{
                            color: otherValue.length === 0 ? "rgba(158, 158, 176, 1)" : "",
                        }}
                    >
                        {otherValue.length}
                    </Typography>
                    <Typography variant="span">/1000</Typography>
                </Box>
            </themes.TextBox>
        </>
    );
};

export default ComplainTextField;
