import React from "react";
import * as themes from "./OrderResponseRaitingCountStyled";
const OrderResponseRaitingCount = ({ value }) => {
    let specialistCount = value;

    const pluraliz = (number, one, few, many) => {
        const lastTwoDigits = number % 100;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
            return many;
        }

        const lastDigit = number % 10;

        if (lastDigit === 1) {
            return one;
        }

        if (lastDigit >= 2 && lastDigit <= 4) {
            return few;
        }

        return many;
    };

    return (
        <themes.StyledOrderResponseRaitingCount variant="span">
            {specialistCount + " "}
            {pluraliz(specialistCount, "отзыв", "отзыва", "отзывов")}
        </themes.StyledOrderResponseRaitingCount>
    );
};

export default OrderResponseRaitingCount;
