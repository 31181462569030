import React, { memo } from "react";
import { Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as themes from "./ScheduleCardThemes";
import Box from "@mui/material/Box";
import CustomCalendar from "components/CustomCalendar/CustomCalendar";
import TeacherStore from "store/TeacherStore";
import { useMedias } from "../../../API/mediasHook";

const ScheduleCard = ({ children, ...props }) => {
    const [state, SetState] = React.useState(false);

    const [date, setDate] = React.useState("");

    const Toggle = () => {
        SetState(!state);
    };

    const medias = useMedias();

    return (
        <Card
            sx={themes.descriptionCardStyle(medias)}
            id={"ScheduleCard"}
        >
            <Box sx={state ? themes.descriptionFullContainerStyle : themes.descriptionCutContainerStyle}>
                <Typography sx={themes.aboutTextStyle}>Расписание</Typography>
                <CustomCalendar
                    weekTimeSlots={TeacherStore.teacherWeekTimeSlots}
                    date={date}
                    setDate={setDate}
                ></CustomCalendar>
            </Box>
            <Typography
                sx={themes.showMoreTextStyle}
                onClick={Toggle}
            >
                {state ? "Показать меньше" : "Показать больше"}
            </Typography>
        </Card>
    );
};

export default memo(ScheduleCard);
