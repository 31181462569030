import { makeAutoObservable, runInAction } from "mobx";
import RequestService from "API/services/RequestService";

const requestService = new RequestService();

class OrdersSearchStore {
    ordersArr = [];

    recommendedOrders = [];

    selectedChipsList = [];

    optionsOrdersDiscipline = [];
    optionsOrdersDisciplineFiltered = [];
    optionsOrdersDisciplineBase = [];
    selectedOrdersDisciplineChipList = [];

    optionsOrdersFormat = [];

    optionsOrdersRegion = [];
    optionOrdersRegion = null;

    optionsOrdersPlace = [];
    optionOrdersPlace = null;
    selectedOrdersPlaceChipList = [];

    optionsOrdersPrice = [];

    optionsOrdersCategory = [];

    optionsOrdersGoal = [];

    optionsOrdersWorkType = [];
    optionOrdersWorkType = null;

    selectedOrdersWorkTypeChipList = [];
    optionsOrdersStudentAge = [];

    ordersFormatExpanded = false;
    ordersRegionExpanded = false;
    ordersPlaceExpanded = false;
    ordersPriceExpanded = false;
    ordersCategoryExpanded = false;
    ordersGoalExpanded = false;
    ordersWorkTypeExpanded = false;
    ordersStudentAgeExpanded = false;

    currentPage = 1;
    itemsForPage = 5;
    from = 0;
    to = this.itemsForPage;

    processing = false;

    search = "";
    searchReady = "";

    timerID = null;

    constructor() {
        this.Init();
        makeAutoObservable(this);
    }

    debounce(callback, delay) {
        return (...args) => {
            if (this.timerID) {
                clearTimeout(this.timerID);
            }

            this.timerID = setTimeout(() => {
                callback.call(this, ...args);
            }, delay);
        };
    }

    SetSearch(value) {
        this.search = value;
    }

    SetDisciplineSearchDebounced(value) {
        this.SetSearch(value);
        this.debounce(() => {
            this.searchReady = this.search;
            console.log(value);
            this.FilterOrdersDisciplineList();
        }, 1000)();
    }

    FilterOrdersDisciplineList() {
        this.optionsOrdersDisciplineFiltered = this.optionsOrdersDiscipline.filter((value) => value.OrdersDiscipline.toLocaleLowerCase().indexOf(this.searchReady.toLocaleLowerCase()) !== -1);
    }

    ToggleOrdersFormatExpanded() {
        console.log(this.ordersFormatExpanded);
        this.ordersFormatExpanded = !this.ordersFormatExpanded;
    }

    ToggleOrdersRegionExpanded() {
        console.log(this.ordersRegionExpanded);
        this.ordersRegionExpanded = !this.ordersRegionExpanded;
    }

    ToggleOrdersPlaceExpanded() {
        console.log(this.ordersPlaceExpanded);
        this.ordersPlaceExpanded = !this.ordersPlaceExpanded;
    }

    ToggleOrdersPriceExpanded() {
        console.log(this.ordersPriceExpanded);
        this.ordersPriceExpanded = !this.ordersPriceExpanded;
    }

    ToggleOrdersCategoryExpanded() {
        console.log(this.ordersCategoryExpanded);
        this.ordersCategoryExpanded = !this.ordersCategoryExpanded;
    }

    ToggleOrdersGoalExpanded() {
        console.log(this.ordersGoalExpanded);
        this.ordersGoalExpanded = !this.ordersGoalExpanded;
    }

    ToggleOrdersWorkTypeExpanded() {
        console.log(this.ordersWorkTypeExpanded);
        this.ordersWorkTypeExpanded = !this.ordersWorkTypeExpanded;
    }

    ToggleOrdersStudentAgeExpanded() {
        console.log(this.ordersStudentAgeExpanded);
        this.ordersStudentAgeExpanded = !this.ordersStudentAgeExpanded;
    }

    CopyOrdersDisciplineList(mode) {
        if (mode === 0) {
            this.optionsOrdersDiscipline =
                this.selectedOrdersDisciplineChipList.length > 0
                    ? [
                          ...this.selectedOrdersDisciplineChipList.concat(
                              this.optionsOrdersDisciplineBase.filter((value) => !value.Checked && !this.selectedOrdersDisciplineChipList.find((x) => x.ID === value.ID)),
                          ),
                      ].sort((a, b) => a.ID - b.ID)
                    : [...this.optionsOrdersDisciplineBase];
            this.optionsOrdersDisciplineFiltered = [...this.optionsOrdersDiscipline];
        } else {
            this.selectedOrdersDisciplineChipList = [];
            this.optionsOrdersDiscipline
                .filter((value) => value.Checked)
                .forEach((value) => {
                    if (!this.selectedOrdersDisciplineChipList.find((x) => x.ID === parseInt(value.ID))) {
                        this.selectedOrdersDisciplineChipList.push(value);
                    }
                });
            this.FillSelectedChipsList();
        }
    }

    async SetOrdersDisciplineFromPA() {
        await this.LoadMoreOrders(); //имитация запроса
        runInAction(() => {
            let value = this.optionsOrdersDisciplineBase.at(0);

            this.optionsOrdersDisciplineFiltered = this.optionsOrdersDisciplineFiltered.map((element) => (element.ID === value.ID ? { ...element, Checked: true } : element));
            this.optionsOrdersDiscipline = this.optionsOrdersDiscipline.map((element) => (element.ID === value.ID ? { ...element, Checked: true } : element));
            if (!this.selectedOrdersDisciplineChipList.find((x) => x.ID === parseInt(value.ID))) {
                this.selectedOrdersDisciplineChipList.push({ ...value, Checked: true });
            }

            this.FillSelectedChipsList();
        });
    }

    SetOrdersDiscipline(value) {
        this.optionsOrdersDisciplineFiltered = this.optionsOrdersDisciplineFiltered.map((element) => (element.ID === value.ID ? { ...element, Checked: !value.Checked } : element));
        this.optionsOrdersDiscipline = this.optionsOrdersDiscipline.map((element) => (element.ID === value.ID ? { ...element, Checked: !value.Checked } : element));
    }

    ClearOrdersDiscipline(value) {
        this.optionsOrdersDiscipline = this.optionsOrdersDiscipline.map((element) => (element.ID === value.ID ? { ...element, Checked: false } : element));
        this.optionsOrdersDisciplineFiltered = this.optionsOrdersDisciplineFiltered.map((element) => (element.ID === value.ID ? { ...element, Checked: false } : element));
        this.selectedOrdersDisciplineChipList = this.selectedOrdersDisciplineChipList.filter((x) => x.ID !== parseInt(value.ID));
        this.FillSelectedChipsList(); //очистка локальных чипов синхронизируется с глобальными
    }

    SetOrdersFormat(value) {
        console.log(value);
        this.optionsOrdersFormat = this.optionsOrdersFormat.map((element) => (element.ID === value.ID ? { ...element, Checked: !value.Checked } : element));
        this.FillSelectedChipsList();
    }

    SetOrdersRegion(value) {
        //console.log(value);
        this.optionOrdersRegion = value;
        console.log(this.optionOrdersRegion);
        this.FillSelectedChipsList();
    }

    SetOrdersPlace(value) {
        this.optionOrdersPlace = value;
        if (typeof value?.ID !== "undefined" && !this.selectedOrdersPlaceChipList.find((x) => x.ID === parseInt(value?.ID))) {
            this.selectedOrdersPlaceChipList.push(value);
        }

        console.log(this.selectedOrdersPlaceChipList);
        this.FillSelectedChipsList();
    }

    ClearOrdersPlace(value) {
        this.selectedOrdersPlaceChipList = this.selectedOrdersPlaceChipList.filter((x) => x.ID !== parseInt(value.ID));
        this.FillSelectedChipsList(); //очистка локальных чипов синхронизируется с глобальными
    }

    SetOrdersPrice(mode, value) {
        console.log(mode, value);
        this.optionsOrdersPrice = this.optionsOrdersPrice.map((element) => (typeof element[mode] !== "undefined" ? { ...element, [mode]: value } : element));
        this.FillSelectedChipsList();
    }

    SetOrdersCategory(value) {
        console.log(value);
        this.optionsOrdersCategory = this.optionsOrdersCategory.map((element) => (element.ID === value.ID ? { ...element, Checked: !value.Checked } : element));
        this.FillSelectedChipsList();
    }

    SetOrdersGoal(value) {
        console.log(value);
        this.optionsOrdersGoal = this.optionsOrdersGoal.map((element) => (element.ID === value.ID ? { ...element, Checked: !value.Checked } : element));
        this.FillSelectedChipsList();
    }

    SetOrdersWorkType(value) {
        //console.log(value, this.optionsLessonsPlace.find(x => x.ID === parseInt(value?.ID)));
        this.optionOrdersWorkType = value;
        if (typeof value?.ID !== "undefined" && !this.selectedOrdersWorkTypeChipList.find((x) => x.ID === parseInt(value?.ID))) {
            this.selectedOrdersWorkTypeChipList.push(value);
        }

        console.log(this.selectedOrdersWorkTypeChipList);
        this.FillSelectedChipsList();
    }

    ClearOrdersWorkType(value) {
        this.selectedOrdersWorkTypeChipList = this.selectedOrdersWorkTypeChipList.filter((x) => x.ID !== parseInt(value.ID));
        this.FillSelectedChipsList(); //очистка локальных чипов синхронизируется с глобальными
    }

    SetOrdersStudentAge(value) {
        console.log(value);
        this.optionsOrdersStudentAge = this.optionsOrdersStudentAge.map((element) => (element.ID === value.ID ? { ...element, Checked: !value.Checked } : element));
        this.FillSelectedChipsList();
    }

    Clear(value) {
        if (value.controlType === "AutoMultiple") {
            if (value.fieldForMultiple) {
                if (value.additionField) {
                    this[value.sourceToFillFrom] = this[value.sourceOfOptions].map((element) =>
                        element.ID === value.value.ID
                            ? {
                                  ...element,
                                  [value.fieldForMultiple]: !value.value[value.fieldForMultiple],
                                  [value.additionField]: element[value.additionField].map((addition) => ({ ...addition, [value.fieldForMultiple]: !value.value[value.fieldForMultiple] })),
                              }
                            : element,
                    );
                } else {
                    this[value.sourceToFillFrom] = this[value.sourceOfOptions].map((element) =>
                        element.ID === value.value.ID ? { ...element, [value.fieldForMultiple]: !value.value[value.fieldForMultiple] } : element,
                    );
                }
            } else {
                this[value.sourceToFillFrom] = []; //пока не используем
            }
        }

        if (value.controlType === "AutoSingle") {
            this[value.sourceToFillFrom] = this[value.sourceOfOptions].at(-1);
        }

        if (value.controlType === "ChipMultiple") {
            this[value.sourceToFillFrom] = this[value.sourceOfOptions].filter((element) => element.ID !== value.value.ID);
        }

        this.FillSelectedChipsList();
    }

    SideFiltersClearAll() {
        this.selectedChipsList = [];
        this.optionsOrdersDiscipline = this.optionsOrdersDiscipline.map((element) => (element = { ...element, Checked: false }));
        this.optionsOrdersDisciplineFiltered = this.optionsOrdersDisciplineFiltered.map((element) => (element = { ...element, Checked: false }));
        this.selectedOrdersDisciplineChipList = [];
        this.optionsOrdersFormat = this.optionsOrdersFormat.map((element) => (element = { ...element, Checked: false }));
        this.optionOrdersRegion = this.optionsOrdersRegion.at(0);
        this.optionOrdersPlace = null;
        this.selectedOrdersPlaceChipList = [];
        this.optionsOrdersCategory = this.optionsOrdersCategory.map((element) => (element = { ...element, Checked: false }));
        this.optionsOrdersGoal = this.optionsOrdersGoal.map((element) => (element = { ...element, Checked: false }));
        this.optionOrdersWorkType = null;
        this.selectedOrdersWorkTypeChipList = [];
        this.optionsOrdersStudentAge = this.optionsOrdersStudentAge.map((element) => (element = { ...element, Checked: false }));
    }

    FillSelectedChipsList() {
        this.selectedChipsList = [];
        this.FillSelectedChipsListOpt("selectedOrdersDisciplineChipList", "selectedOrdersDisciplineChipList", "OrdersDiscipline", null, "ChipMultiple", null);
        this.FillSelectedChipsListOpt("optionsOrdersFormat", "optionsOrdersFormat", "OrdersFormat", "Checked", "AutoMultiple", null);
        //this.FillSelectedChipsListOpt('optionOrdersRegion', 'optionsOrdersRegion', 'OrdersRegion',null, 'AutoSingle', null);
        this.FillSelectedChipsListOpt("selectedOrdersPlaceChipList", "selectedOrdersPlaceChipList", "OrdersPlace", null, "ChipMultiple", null);
        this.FillSelectedChipsListOpt("optionsOrdersCategory", "optionsOrdersCategory", "OrdersCategory", "Checked", "AutoMultiple", null);
        this.FillSelectedChipsListOpt("optionsOrdersGoal", "optionsOrdersGoal", "OrdersGoal", "Checked", "AutoMultiple", null);
        this.FillSelectedChipsListOpt("selectedOrdersWorkTypeChipList", "selectedOrdersWorkTypeChipList", "OrdersWorkType", null, "ChipMultiple", null);
        this.FillSelectedChipsListOpt("optionsOrdersStudentAge", "optionsOrdersStudentAge", "OrdersStudentAge", "Checked", "AutoMultiple", null);
    }

    FillSelectedChipsListOpt(sourceToFillFrom, sourceOfOptions, fieldName, field, controlType, additionField) {
        if (Array.isArray(this[sourceToFillFrom])) {
            //выбрано несколько опций
            if (field) {
                //в случае, если изменяется поле самого источника
                this[sourceToFillFrom]
                    .filter((value) => value[field])
                    .forEach((value) => {
                        if (!this.selectedChipsList.find((x) => x.ID === value.ID + fieldName)) {
                            this.selectedChipsList.push({
                                ID: value.ID + " " + fieldName,
                                sourceToFillFrom: sourceToFillFrom,
                                sourceOfOptions: sourceOfOptions,
                                controlType: controlType,
                                additionField: additionField,
                                fieldForMultiple: field,
                                fieldName: fieldName,
                                value: value,
                            });
                        }
                    });
            } else {
                this[sourceToFillFrom].forEach((value) => {
                    if (!this.selectedChipsList.find((x) => x.ID === value.ID + fieldName)) {
                        this.selectedChipsList.push({
                            ID: value.ID + " " + fieldName,
                            sourceToFillFrom: sourceToFillFrom,
                            sourceOfOptions: sourceOfOptions,
                            controlType: controlType,
                            additionField: additionField,
                            fieldForMultiple: field,
                            fieldName: fieldName,
                            value: value,
                        });
                    }
                });
            }
        } else if (!this.selectedChipsList.find((x) => x.ID === this[sourceToFillFrom].ID + fieldName) && this[sourceToFillFrom].ID !== this[sourceOfOptions].at(-1).ID) {
            this.selectedChipsList.push({
                ID: this[sourceToFillFrom].ID + " " + fieldName,
                sourceToFillFrom: sourceToFillFrom,
                sourceOfOptions: sourceOfOptions,
                controlType: controlType,
                additionField: additionField,
                fieldForMultiple: field,
                fieldName: fieldName,
                value: this[sourceToFillFrom],
            });
        }
    }

    ToggleProcessing() {
        this.processing = !this.processing;
    }

    async ShowMore() {
        this.ToggleProcessing();
        this.from = this.ordersArr.length === this.itemsForPage ? this.itemsForPage * this.currentPage + 1 : this.to + 1;
        this.to = this.from + this.itemsForPage - 1;
        console.log(this.from, this.to);
        //query here
        await this.LoadMoreOrders(); //имитация запроса
        runInAction(() => {
            this.AppendLoadedOrdersData();
            this.ToggleProcessing();
        });
    }

    AppendLoadedOrdersData() {
        this.ordersArr = [
            ...this.ordersArr,
            ...this.ordersArr.slice(0, 5).map((value) => {
                return { ...value, ID: value.ID + this.from - 1 };
            }),
        ];
    }

    async ExecuteSearch() {
        console.log("executing search");
        let searchObj = {};

        console.log(searchObj);
        let res = await requestService.sendAsync(process.env.REACT_APP_API_URL_MOCK + "todos/1", "get", JSON.stringify(searchObj));

        console.log(res);
        //this.ordersArr = [];
    }

    Init() {
        this.LoadOrders();
        this.LoadFilters();
        //this.FilterOrdersArr();
    }

    async LoadMoreOrders() {
        // eslint-disable-next-line no-undef
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 1500);
        });
    }

    LoadFilters() {
        this.optionsOrdersDiscipline = [
            { ID: 0, OrdersDiscipline: "Математика", Checked: false },
            { ID: 1, OrdersDiscipline: "Биология", Checked: false },
            { ID: 2, OrdersDiscipline: "Биофизика", Checked: false },
            { ID: 3, OrdersDiscipline: "Экономика", Checked: false },
            { ID: 4, OrdersDiscipline: "Математический анализ", Checked: false },
            { ID: 5, OrdersDiscipline: "Маркетинг", Checked: false },
            { ID: 6, OrdersDiscipline: "Микробиология", Checked: false },
            { ID: 7, OrdersDiscipline: "История", Checked: false },
            { ID: 8, OrdersDiscipline: "Обществознание", Checked: false },
            { ID: 9, OrdersDiscipline: "Теория вероятностей", Checked: false },
            { ID: 10, OrdersDiscipline: "Менеджмент", Checked: false },
            { ID: 11, OrdersDiscipline: "test1", Checked: false },
            { ID: 12, OrdersDiscipline: "test2", Checked: false },
            { ID: 13, OrdersDiscipline: "test3", Checked: false },
        ];
        this.optionsOrdersDisciplineBase = [...this.optionsOrdersDiscipline];
        this.optionsOrdersDisciplineFiltered = [...this.optionsOrdersDiscipline];

        this.optionsOrdersFormat = [
            { ID: 0, OrdersFormat: "Онлайн", Checked: false },
            { ID: 1, OrdersFormat: "У ученика", Checked: false },
            { ID: 2, OrdersFormat: "У репетитора", Checked: false },
        ];

        this.optionsOrdersRegion = [
            { ID: 0, OrdersRegion: "here1" },
            { ID: 1, OrdersRegion: "there1" },
            { ID: 2, OrdersRegion: "where1" },
        ];
        this.optionOrdersRegion = this.optionsOrdersRegion.at(0);

        this.optionsOrdersPlace = [
            { ID: 0, OrdersPlace: "here" },
            { ID: 1, OrdersPlace: "there" },
            { ID: 2, OrdersPlace: "where" },
        ];

        this.optionsOrdersPrice = [
            { ID: 0, PriceFrom: "" },
            { ID: 1, PriceTo: "" },
        ];

        this.optionsOrdersCategory = [
            { ID: 0, OrdersCategory: "Занятия", Checked: false },
            { ID: 1, OrdersCategory: "Учебные работы", Checked: false },
        ];

        this.optionsOrdersGoal = [
            { ID: 0, OrdersGoal: "Экзамен", Checked: false },
            { ID: 1, OrdersGoal: "Олимпиада", Checked: false },
            { ID: 2, OrdersGoal: "Для себя", Checked: false },
            { ID: 3, OrdersGoal: "Для работы", Checked: false },
            { ID: 4, OrdersGoal: "Повышение успеваемости", Checked: false },
        ];

        this.optionsOrdersWorkType = [
            { ID: 0, OrdersWorkType: "Реферат" },
            { ID: 1, OrdersWorkType: "Рецензия" },
            { ID: 2, OrdersWorkType: "Аспирантский реферат" },
        ];

        this.optionsOrdersStudentAge = [
            { ID: 0, OrdersStudentAge: "Школьник", from: 0, to: 25, Checked: false },
            { ID: 1, OrdersStudentAge: "Взрослый", from: 25, to: 50, Checked: false },
            { ID: 2, OrdersStudentAge: "Студент", from: 50, to: Number.POSITIVE_INFINITY, Checked: false },
            { ID: 3, OrdersStudentAge: "Не важно", from: Number.NEGATIVE_INFINITY, to: Number.POSITIVE_INFINITY, Checked: false },
        ];

        this.FillSelectedChipsList();
    }

    LoadOrders() {
        this.ordersArr = [
            {
                ID: 0,
                _id: "64902a483594e963dd20818a",
                OrderType: 0,
                OrderTitle: "Репетитор по английскому языку",
                AvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
                IsVerified: true,
                RegistrationDate: new Date(2022, 12, 19),
                Price: {
                    PriceFrom: 9000,
                    PriceTo: 9900,
                },
                SeenByTeacher: true,
                ReviewsCount: 7,
                ViewedCount: 120,
                IsPersonal: true,
                Tags: [
                    {
                        ID: 0,
                        Query: "Подготовка к экзамену",
                    },
                    {
                        ID: 1,
                        Query: "2 курс",
                    },
                    {
                        ID: 2,
                        Query: "МГИМО",
                    },
                    {
                        ID: 3,
                        Query: "Английский язык",
                    },
                    {
                        ID: 4,
                        Query: "Английский язык 1",
                    },
                    {
                        ID: 5,
                        Query: "Английский язык 2",
                    },
                    {
                        ID: 6,
                        Query: "Английский язык 3",
                    },
                ],
                Location: {
                    Type: "У репетитора",
                    Stations: [
                        {
                            ID: 0,
                            Location: "Комсомольская",
                        },
                        {
                            ID: 1,
                            Location: "Красные ворота",
                        },
                        {
                            ID: 2,
                            Location: "Красносельская",
                        },
                    ],
                },
                ScheduleContent: "вт, чт 12:00–18:00; сб 18:00–24:00",
                Comment: "",
                FileAttachments: [],
                StudentFIO: "Анна А.",
                LastSeen: "54 мин. назад",
            },
            {
                ID: 1,
                _id: "44902a483594e963dd20818a",
                OrderType: 1,
                OrderTitle: "Написание дипломной работы",
                AvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
                IsVerified: true,
                RegistrationDate: new Date(2022, 12, 19),
                Price: {
                    PriceFrom: 12000,
                    PriceTo: null,
                },
                SeenByTeacher: false,
                ReviewsCount: 7,
                ViewedCount: 120,
                IsPersonal: false,
                Tags: [
                    {
                        ID: 0,
                        Query: "Дипломная работа",
                    },
                    {
                        ID: 1,
                        Query: "Английский язык",
                    },
                ],
                Theme: "Лексический подход при обучении аудированию с целью улучшения навыков коммуникацииЛексический подход при обучении аудированию с целью улучшения навыков коммуникацииЛексический подход при обучении аудированию с целью улучшения навыков коммуникацииЛексический подход при обучении аудированию с целью улучшения навыков коммуникацииЛексический подход при обучении аудированию с целью улучшения навыков коммуникацииЛексический подход при обучении аудированию с целью улучшения навыков коммуникации",
                Deadline: "20 мая",
                Comment: "«Курсовая должна соответствовать всем требованиям. Прикрепляю документ»",
                FileAttachments: [
                    {
                        ID: 0,
                        FileName: "Курсовая по английскому. Требования.doc",
                        FileSize: "3.2MB",
                    },
                ],
                StudentFIO: "Виктория Б.",
                LastSeen: "3 часа назад",
            },
            {
                ID: 2,
                _id: "34902a483594e963dd20818a",
                OrderType: 0,
                OrderTitle: "Репетитор по английскому языку",
                AvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
                IsVerified: true,
                RegistrationDate: new Date(2022, 12, 19),
                Price: {
                    PriceFrom: null,
                    PriceTo: null,
                },
                SeenByTeacher: true,
                ReviewsCount: 7,
                ViewedCount: 120,
                IsPersonal: false,
                Tags: [
                    {
                        ID: 0,
                        Query: "Подготовка к экзамену",
                    },
                    {
                        ID: 1,
                        Query: "2 курс",
                    },
                    {
                        ID: 2,
                        Query: "МГИМО",
                    },
                    {
                        ID: 3,
                        Query: "Английский язык",
                    },
                ],
                Location: {
                    Type: "У репетитора",
                    Stations: [
                        {
                            ID: 0,
                            Location: "Комсомольская",
                        },
                        {
                            ID: 1,
                            Location: "Красные ворота",
                        },
                        {
                            ID: 2,
                            Location: "Красносельская",
                        },
                    ],
                },
                ScheduleContent: "вт, чт 12:00–18:00; сб 18:00–24:00",
                Comment:
                    "«Ищу репетитора для дочки 16 лет, задача - ЗНО и получить сертификат минимум В2 IELTS для поступления в Университет Варшавы - SWPS. Ищу репетитора для дочки 16 лет, задача - ЗНО и получить сертификат минимум В2 IELTS для поступления»",
                FileAttachments: [],
                StudentFIO: "Анна А.",
                LastSeen: "54 мин. назад",
            },
            {
                ID: 3,
                _id: "24902a483594e963dd20818a",
                OrderType: 1,
                OrderTitle: "Написание дипломной работы",
                AvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
                IsVerified: true,
                RegistrationDate: new Date(2022, 12, 19),
                Price: {
                    PriceFrom: 12000,
                    PriceTo: null,
                },
                SeenByTeacher: false,
                ReviewsCount: 7,
                ViewedCount: 120,
                IsPersonal: false,
                Tags: [
                    {
                        ID: 0,
                        Query: "Дипломная работа",
                    },
                    {
                        ID: 1,
                        Query: "Английский язык",
                    },
                ],
                Theme: "Лексический подход при обучении аудированию с целью улучшения навыков коммуникации",
                Deadline: "20 мая",
                Comment: "",
                FileAttachments: [],
                StudentFIO: "Виктория Б.",
                LastSeen: "3 часа назад",
            },
            {
                ID: 4,
                _id: "14902a483594e963dd20818a",
                OrderType: 0,
                OrderTitle: "Репетитор по английскому языку",
                AvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
                IsVerified: true,
                RegistrationDate: new Date(2022, 12, 19),
                Price: {
                    PriceFrom: 9000,
                    PriceTo: 9900,
                },
                SeenByTeacher: true,
                ReviewsCount: 7,
                ViewedCount: 120,
                IsPersonal: false,
                Tags: [
                    {
                        ID: 0,
                        Query: "Подготовка к экзамену",
                    },
                    {
                        ID: 1,
                        Query: "2 курс",
                    },
                    {
                        ID: 2,
                        Query: "МГИМО",
                    },
                    {
                        ID: 3,
                        Query: "Английский язык",
                    },
                ],
                Location: {
                    Type: "У репетитора",
                    Stations: [
                        {
                            ID: 0,
                            Location: "Комсомольская",
                        },
                        {
                            ID: 1,
                            Location: "Красные ворота",
                        },
                        {
                            ID: 2,
                            Location: "Красносельская",
                        },
                    ],
                },
                ScheduleContent: "вт, чт 12:00–18:00; сб 18:00–24:00",
                Comment: "",
                FileAttachments: [],
                StudentFIO: "Анна А.",
                LastSeen: "54 мин. назад",
            },
        ];

        this.recommendedOrders = [
            {
                ID: 0,
                _id: "64902a483594e963dd20818a",
                OrderType: 0,
                OrderTitle: "Репетитор по английскому языку",
                AvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
                IsVerified: true,
                RegistrationDate: new Date(2022, 12, 19),
                Price: {
                    PriceFrom: 9000,
                    PriceTo: 9900,
                },
                SeenByTeacher: true,
                ReviewsCount: 7,
                ViewedCount: 120,
                IsPersonal: true,
                Tags: [
                    {
                        ID: 0,
                        Query: "Подготовка к экзамену",
                    },
                    {
                        ID: 1,
                        Query: "2 курс",
                    },
                    {
                        ID: 2,
                        Query: "МГИМО",
                    },
                    {
                        ID: 3,
                        Query: "Английский язык",
                    },
                    {
                        ID: 4,
                        Query: "Английский язык 1",
                    },
                    {
                        ID: 5,
                        Query: "Английский язык 2",
                    },
                    {
                        ID: 6,
                        Query: "Английский язык 3",
                    },
                ],
                Location: ["У репетитора", "У студента", "Онлайн"],
                Stations: [
                    {
                        ID: 0,
                        Location: "Комсомольская",
                        colors: ["red", "brown"],
                    },
                    {
                        ID: 1,
                        Location: "Красные ворота",
                        colors: ["red", "brown"],
                    },
                    {
                        ID: 2,
                        Location: "Красносельская",
                        colors: ["red", "brown"],
                    },
                ],
                ScheduleContent: "вт, чт 12:00–18:00; сб 18:00–24:00",
                Comment: "",
                FileAttachments: [],
                StudentFIO: "Анна А.",
                LastSeen: "54 мин. назад",
            },
            {
                ID: 1,
                _id: "44902a483594e963dd20818a",
                OrderType: 1,
                OrderTitle: "Написание дипломной работы",
                AvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
                IsVerified: true,
                RegistrationDate: new Date(2022, 12, 19),
                Price: {
                    PriceFrom: 12000,
                    PriceTo: null,
                },
                SeenByTeacher: false,
                ReviewsCount: 7,
                ViewedCount: 120,
                IsPersonal: false,
                Tags: [
                    {
                        ID: 0,
                        Query: "Дипломная работа",
                    },
                    {
                        ID: 1,
                        Query: "Английский язык",
                    },
                ],
                Theme: "Лексический подход при обучении аудированию с целью улучшения навыков коммуникацииЛексический подход при обучении аудированию с целью улучшения навыков коммуникацииЛексический подход при обучении аудированию с целью улучшения навыков коммуникацииЛексический подход при обучении аудированию с целью улучшения навыков коммуникацииЛексический подход при обучении аудированию с целью улучшения навыков коммуникацииЛексический подход при обучении аудированию с целью улучшения навыков коммуникации",
                Deadline: "20 мая",
                Comment: "«Курсовая должна соответствовать всем требованиям. Прикрепляю документ»",
                FileAttachments: [
                    {
                        ID: 0,
                        FileName: "Курсовая по английскому. Требования.doc",
                        FileSize: "3.2MB",
                    },
                ],
                StudentFIO: "Виктория Б.",
                LastSeen: "3 часа назад",
            },
            {
                ID: 2,
                _id: "24902a483594e963dd20818a",
                OrderType: 1,
                OrderTitle: "Написание дипломной работы",
                AvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
                IsVerified: true,
                RegistrationDate: new Date(2022, 12, 19),
                Price: {
                    PriceFrom: 12000,
                    PriceTo: null,
                },
                SeenByTeacher: false,
                ReviewsCount: 7,
                ViewedCount: 120,
                IsPersonal: false,
                Tags: [
                    {
                        ID: 0,
                        Query: "Дипломная работа",
                    },
                    {
                        ID: 1,
                        Query: "Английский язык",
                    },
                ],
                Theme: "Лексический подход при обучении аудированию с целью улучшения навыков коммуникации",
                Deadline: "20 мая",
                Comment: "",
                FileAttachments: [],
                StudentFIO: "Виктория Б.",
                LastSeen: "3 часа назад",
            },
            {
                ID: 3,
                _id: "14902a483594e963dd20818a",
                OrderType: 0,
                OrderTitle: "Репетитор по английскому языку",
                AvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
                IsVerified: true,
                RegistrationDate: new Date(2022, 12, 19),
                Price: {
                    PriceFrom: 0,
                    PriceTo: 9900,
                },
                SeenByTeacher: true,
                ReviewsCount: 7,
                ViewedCount: 120,
                IsPersonal: false,
                Tags: [
                    {
                        ID: 0,
                        Query: "Подготовка к экзамену",
                    },
                    {
                        ID: 1,
                        Query: "2 курс",
                    },
                    {
                        ID: 2,
                        Query: "МГИМО",
                    },
                    {
                        ID: 3,
                        Query: "Английский язык",
                    },
                ],
                Location: ["У репетитора"],
                Stations: [
                    {
                        ID: 0,
                        Location: "Комсомольская",
                        colors: ["red", "brown"],
                    },
                    {
                        ID: 1,
                        Location: "Красные ворота",
                        colors: ["red", "brown"],
                    },
                    {
                        ID: 2,
                        Location: "Красносельская",
                        colors: ["red", "brown"],
                    },
                ],
                ScheduleContent: "вт, чт 12:00–18:00; сб 18:00–24:00",
                Comment: "",
                FileAttachments: [],
                StudentFIO: "Анна А.",
                LastSeen: "54 мин. назад",
            },
        ];
    }
}

export default new OrdersSearchStore();
