export const orderInfoContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 24,
    width: "340px",
    height: "280px",
    padding: "20px",
    boxSizing: "border-box",
    borderRadius: "20px",
    background: "#FFFFFF",
};
