import Platinum from "assets/PAAssets/SVG/platinum";
import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { useTranslation } from "react-i18next";
import * as textThemes from "themes/TextThemes";
import * as themes from "./CurrentLevelThemes";
import { memo } from "react";
import { useMedias } from "API/mediasHook";

const CurrentLevel = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.WhiteContainer(medias)}>
            <Box sx={themes.TopBlock}>
                <Typography
                    sx={textThemes.Body1Black}
                >{`${TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.currentMonth).level}${t(TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.currentMonth).levelEnding)} ${t("teacherAccount.privilegeProgram.tutorLevel")}`}</Typography>
                <Box sx={themes.LevelRow}>
                    <Typography sx={textThemes.HeadlineH41Black}>
                        {t(TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.currentMonth).title)}
                    </Typography>
                    {TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.currentMonth).level === 1 && (
                        <Box sx={themes.PlatinumIcon}>
                            <Platinum />
                        </Box>
                    )}
                </Box>
            </Box>
            <Typography
                sx={textThemes.Body2DarkGrey}
            >{`${t("teacherAccount.privilegeProgram.tutorLevelUntilPart1")}${TeacherQuestionnaireStore.getLastDayOfMonth()}${t("teacherAccount.privilegeProgram.tutorLevelUntilPart2")}`}</Typography>
        </Box>
    );
});

export default memo(CurrentLevel);
