import { Drawer, TextField, Typography } from "@mui/material";
import UserStore from "store/UserStore";
import SettingsStore from "store/SettingsStore";
import SButton from "components/UI/SButton";
import React, { useEffect } from "react";
import * as styles from "./LoginModalsThemes";
import * as textStyles from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";
import ModalSettings from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsBig/ModalSettings";
import { useMedias } from "API/mediasHook";
import DrawerHeader from "components/SettingsBodyBlock/StudentSettings/DrawerHeader/DrawerHeader";

const ModalEnterSMSCode = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        if (SettingsStore.checkSMSCode()) {
            props.handleSave();
        } else {
            SettingsStore.setWrongSMSCode(true);
        }
    };

    const Back = () => {
        props.handleBack();
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (SettingsStore.seconds > 0) {
                SettingsStore.setSeconds(SettingsStore.seconds - 1);
            }

            if (SettingsStore.seconds === 0) {
                if (SettingsStore.minutes === 0) {
                    clearInterval(interval);
                } else {
                    SettingsStore.setSeconds(59);
                    SettingsStore.setMinutes(SettingsStore.minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [SettingsStore.seconds]);
    const DrawerList = (
        <Box sx={styles.AddForm(medias)}>
            <DrawerHeader
                text={t("main.settings.loginDetails.enterSMSTitle")}
                handleClose={Close}
            />
            <Box sx={{ padding: "0 16px 16px 16px", display: "flex" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
                    <Typography sx={textStyles.Body2Black}>
                        {t("main.settings.loginDetails.SMSWasSent")} {SettingsStore.ClosePhone(UserStore.UserInfo.phone)}
                    </Typography>
                </Box>
            </Box>
            <Box sx={styles.SMSsent(medias)}>
                <TextField
                    sx={styles.AddLoginDetailTextField(SettingsStore.wrongSMSCode)}
                    required
                    fullWidth
                    variant="standard"
                    name="email"
                    placeholder={t("main.settings.loginDetails.codeFromSMS")}
                    onChange={(e) => SettingsStore.setSMSCode(e.target.value)}
                    helperText={SettingsStore.wrongSMSCode === true ? t("main.settings.loginDetails.incorrectSMS") : ""}
                    FormHelperTextProps={{ sx: { color: "#E12626" } }}
                />
                <Box>
                    {SettingsStore.seconds > 0 || SettingsStore.minutes > 0 ? (
                        <Typography sx={textStyles.Body2DarkGrey}>
                            {t("main.settings.loginDetails.resendSMSLabel")} {SettingsStore.minutes < 10 ? `${SettingsStore.minutes}` : SettingsStore.minutes}:
                            {SettingsStore.seconds < 10 ? `0${SettingsStore.seconds}` : SettingsStore.seconds}
                        </Typography>
                    ) : (
                        <Typography
                            sx={textStyles.Body2Purple}
                            style={{ cursor: "pointer" }}
                            onClick={() => SettingsStore.setSeconds(59)}
                        >
                            {t("main.settings.loginDetails.resendSMSButton")}
                        </Typography>
                    )}
                </Box>
            </Box>
            <Box
                container
                sx={styles.ButtonsDrawerContainer(medias)}
            >
                <SButton
                    style={{ width: "192px" }}
                    padding="10px 20px 11px 20px"
                    variant="secondary"
                    onClick={Back}
                >
                    {t("main.back")}
                </SButton>
                <SButton
                    style={{ width: "192px" }}
                    padding="10px 20px 11px 20px"
                    variant="unshadowed"
                    disabled={SettingsStore.SMSCode === null}
                    onClick={Save}
                >
                    {t("main.continue")}
                </SButton>
            </Box>
        </Box>
    );

    return (
        <>
            {!medias.sm ? (
                <Drawer
                    open={props.open}
                    onClose={Close}
                >
                    {DrawerList}
                </Drawer>
            ) : (
                <ModalSettings
                    visible={props.open}
                    handleClose={Close}
                    header={t("main.settings.loginDetails.enterSMSTitle")}
                >
                    <Box sx={styles.AddForm(medias)}>
                        <Typography sx={textStyles.Body2Black}>
                            {t("main.settings.loginDetails.SMSWasSent")} {SettingsStore.ClosePhone(UserStore.UserInfo.phone)}
                        </Typography>
                        <Box sx={styles.SMSsent}>
                            <TextField
                                sx={styles.AddLoginDetailTextField("400px", SettingsStore.wrongSMSCode)}
                                required
                                fullWidth
                                variant="standard"
                                name="email"
                                placeholder={t("main.settings.loginDetails.codeFromSMS")}
                                onChange={(e) => SettingsStore.setSMSCode(e.target.value)}
                                helperText={SettingsStore.wrongSMSCode === true ? t("main.main.settings.loginDetails.incorrectSMS") : ""}
                                FormHelperTextProps={{ sx: { color: "#E12626" } }}
                            />
                            <Box>
                                {SettingsStore.seconds > 0 || SettingsStore.minutes > 0 ? (
                                    <Typography sx={textStyles.Body2DarkGrey}>
                                        {t("main.settings.loginDetails.resendSMSLabel")} {SettingsStore.minutes < 10 ? `${SettingsStore.minutes}` : SettingsStore.minutes}:
                                        {SettingsStore.seconds < 10 ? `0${SettingsStore.seconds}` : SettingsStore.seconds}
                                    </Typography>
                                ) : (
                                    <Typography
                                        sx={textStyles.Body2Purple}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => SettingsStore.setSeconds(59)}
                                    >
                                        {t("main.settings.loginDetails.resendSMSButton")}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box
                            container
                            sx={styles.ButtonsContainer}
                        >
                            <SButton
                                style={{ width: "192px" }}
                                padding="10px 20px 11px 20px"
                                variant="secondary"
                                onClick={Back}
                            >
                                {t("main.back")}
                            </SButton>
                            <SButton
                                style={{ width: "192px" }}
                                padding="10px 20px 11px 20px"
                                variant="unshadowed"
                                disabled={SettingsStore.SMSCode === null}
                                onClick={Save}
                            >
                                {t("main.continue")}
                            </SButton>
                        </Box>
                    </Box>
                </ModalSettings>
            )}
        </>
    );
});

export default ModalEnterSMSCode;
