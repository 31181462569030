import React from "react";

const LeftArrowDisabledIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M11 2L4.7979 7.62516C4.36715 8.01584 4.35933 8.69026 4.7809 9.09082L11 15"
                stroke="#6212FF"
                strokeOpacity="0.2"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default LeftArrowDisabledIcon;
