//Сколько осталось до экзамена?
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import SRadioGroup from "components/UI/RadioGroup";
import SBButtonGroup from "components/UI/ButtonGroup";
import { timeToExam } from "API/dictionaries/const_data";
import store from "store/RequestStore";

import StepHiddenButtonWrapper from "components/OrdersBodyBlock/RenewOrder/StepHiddenButtonWrapper";
import RequestNullAlert from "./RequestNullAlert";

const Step15 = () => {
    const [alert, setAlert] = useState(0);

    const [stepData, setStepData] = useState(store.storage[store.activeStepIndex].substeps[store.subStepIndex].value);

    const handleExamDate = () => {
        if (stepData) {
            store.setStep(2, stepData);
            store.setStepValue(2, stepData);
        }
    };

    const handleIncrement = () => {
        if (stepData) {
            store.incrementStepIndex();
        } else {
            setAlert(1);
        }
    };

    const handleNext = () => {
        handleExamDate();
        handleIncrement();
    };

    return (
        <>
            <SRadioGroup
                changefunc={setStepData}
                value={stepData}
                list={timeToExam}
            />
            <StepHiddenButtonWrapper submitRenew={handleExamDate}>
                <SBButtonGroup
                    handleNext={handleNext}
                    handleBack={() => store.decrementStepIndex()}
                    activeStep={store.activeStepIndex}
                    steps={store.storage}
                />
                {alert === 1 ? <RequestNullAlert>Чтобы перейти к следующему вопросу, выберите один из вариантов</RequestNullAlert> : null}
            </StepHiddenButtonWrapper>
        </>
    );
};

export default observer(Step15);
