import { Button, Typography, styled } from "@mui/material";
export const OrederResponseInfoDetailsButton = styled(Button)({
    fontFamily: '"Inter", sans-serif',
    minWidth: "0px",
    color: "rgba(34, 34, 34, 1)",
    backgroundColor: "none",
    textTransform: "none",
    transition: "opacity 0.5s",
    "&:hover": {
        color: "rgba(34, 34, 34, 1)",
        background: "none",
        opacity: 0.8,
        transition: "opacity 0.5s",
    },
    "& .MuiTouchRipple-root": {
        color: "rgba(34, 34, 34, 1)",
    },
});

export const StyledOrderResponseBottomWrapper = styled(Typography)({
    fontWeight: 600,
    fontSize: "14px.",
    lineHeight: "21px",
});

export const OrderRespInfoDivider = { marginBottom: "10px", color: "#EEEEF0" };
