import UserStore from "store/UserStore";
import { IconButton, Box } from "@mui/material";
import IconFavourites from "assets/ChatAssets/SVG/favourites";
import * as React from "react";
import { memo } from "react";
import * as themes from "./FavouritesButtonThemes";
import { useNavigate } from "react-router-dom";
import { useMedias } from "API/mediasHook";
import { Button } from "./FavouritesButtonThemes";
const FavouritesButton = ({ children, ...props }) => {
    const navigate = useNavigate();

    const [hovered, setHovered] = React.useState(false);

    const medias = useMedias();

    const handleClickFavourites = () => {
        navigate("/favourites");
    };

    return (
        <Box>
            {medias.sm && UserStore.role === "student" && (
                <IconButton
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    disableRipple
                    sx={themes.Button(hovered)}
                    onClick={handleClickFavourites}
                >
                    <IconFavourites hovered={hovered} />
                </IconButton>
            )}
        </Box>
    );
};

export default memo(FavouritesButton);
