import { useState, useEffect } from "react";

import { Box, Divider, Stack, Typography } from "@mui/material";

import { Button14Black, Body2Purple } from "themes/TextThemes";
import Actions from "../Actions";

function LinkPreview({ url }) {
    const [previewData, setPreviewData] = useState(null);

    const [loading, setLoading] = useState(true);

    const [isPreviewDataLoaded, setIsPreviewDataLoaded] = useState(previewData ? true : false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(url);

                const data = await response.text();

                const isYouTubeVideo = isYouTubeURL(url);

                if (isYouTubeVideo) {
                    const videoId = extractYouTubeVideoId(url);

                    const videoThumbnail = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;

                    setPreviewData({
                        videoId,
                        videoThumbnail,
                    });
                    setLoading(false);
                } else {
                    const parser = new DOMParser();

                    const doc = parser.parseFromString(data, "text/html");

                    const title = doc.querySelector("title")?.textContent || "";

                    const description = doc.querySelector('meta[name="description"]')?.getAttribute("content") || "";

                    const image = doc.querySelector('meta[property="og:image"]')?.getAttribute("content") || "";

                    setPreviewData({
                        title,
                        description,
                        image,
                    });
                    setLoading(false);
                }
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [url]);

    const isYouTubeURL = (url) => {
        return url.includes("youtube.com") || url.includes("youtu.be");
    };

    const extractYouTubeVideoId = (url) => {
        const videoIdRegex = /(?:\/embed\/|\/watch\?v=|\/(?:embed\/|v\/|watch\?.*v=|youtu\.be\/|embed\/|v=))([^&?#]+)/;

        const match = url.match(videoIdRegex);

        return match ? match[1] : "";
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    const handleClick = () => {
        window.open(url, "_blank");
    };

    if (previewData?.videoId) {
        return (
            <Box
                onClick={handleClick}
                sx={{ cursor: "pointer" }}
            >
                <img
                    src={previewData.videoThumbnail}
                    alt="Video Thumbnail"
                />
            </Box>
        );
    }

    return (
        <Stack
            direction="row"
            spacing={1}
            alignItems="flex-start"
            sx={{ borderTop: "1px solid #EEEEF0", py: 1.25 }}
            // onClick={handleClick}
        >
            <Box
                component={previewData?.image ? "img" : "div"}
                src={previewData?.image ? previewData.image : undefined}
                alt="Link Preview"
                sx={{ objectFit: "cover", width: "120px", height: "69px", borderRadius: "10px", border: "1px dashed gray" }}
            />
            <Stack
                direction="column"
                spacing={0.5}
                maxWidth="70%"
                onClick={handleClick}
            >
                <Typography sx={Button14Black}>{previewData?.title ? previewData.title : null}</Typography>
                <Typography sx={Body2Purple}>{url}</Typography>
            </Stack>
            <Stack
                direction="column"
                justifyContent="flex-start"
            >
                <Actions hasDownload={false} />
            </Stack>
        </Stack>
    );
}

export default LinkPreview;
