import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./DescriptionBlockThemes";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import AttachedContent from "./AttachedContent/AttachedContent";

const DescriptionBlock = observer(({ children, ...props }) => {
    return (
        <Box sx={themes.descriptionBlockContainerStyle}>
            {!props.value.OrderType ? (
                <Box>
                    <Box style={themes.locationBlockContainerStyle}>
                        <Typography sx={themes.locationLabelStyle}>Где:</Typography>
                        <Typography sx={themes.locationTypeTextStyle}>{props.value.Location.Type}</Typography>
                        <Box sx={themes.locationsListContainerStyle}>
                            {props.value.Location.Stations.map((value, index) => (
                                <Box
                                    style={themes.locationTextContainerStyle}
                                    key={value.ID}
                                >
                                    <Typography sx={themes.locationTextStyle}>{value.Location}</Typography>
                                    {index === props.value.Location.Stations.length - 1 ? <Box></Box> : <Box>•</Box>}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box style={themes.scheduleBlockContainerStyle}>
                        <Typography sx={themes.scheduleLabelStyle}>Когда:</Typography>
                        <Typography sx={themes.scheduleTextStyle}>{props.value.ScheduleContent}</Typography>
                    </Box>
                    {props.value.Comment && <AttachedContent value={props.value}></AttachedContent>}
                </Box>
            ) : (
                <Box>
                    <Box style={themes.themeBlockContainerStyle}>
                        <Typography sx={themes.themeLabelStyle}>Тема:</Typography>
                        <Typography sx={themes.themeTextStyle}>{props.value.Theme}</Typography>
                    </Box>
                    <Box style={themes.deadlineBlockContainerStyle}>
                        <Typography sx={themes.deadlineLabelStyle}>Срок выполнения:</Typography>
                        <Typography sx={themes.deadlineTextStyle}>{props.value.Deadline}</Typography>
                    </Box>
                    {props.value.Comment && <AttachedContent value={props.value}></AttachedContent>}
                </Box>
            )}
        </Box>
    );
});

export default memo(DescriptionBlock);
