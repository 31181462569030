//Какая тема работы?
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import SBButtonGroup from "components/UI/ButtonGroup";
import store from "store/RequestStore";

import TextField from "@mui/material/TextField";
import StepHiddenButtonWrapper from "components/OrdersBodyBlock/RenewOrder/StepHiddenButtonWrapper";
import RequestNullAlert from "./RequestNullAlert";
import { Box } from "@mui/material";
import * as theme from "./Step17Themes";
const Step17 = () => {
    const [alert, setAlert] = useState(0);

    const [stepData, setStepData] = useState(store.storage[store.activeStepIndex].substeps[store.subStepIndex].value);

    const handleThemeWork = () => {
        if (stepData) {
            store.setStep(10, stepData);
            store.setStepValue(10, stepData);
        }
    };

    return (
        <>
            <Box sx={theme.Step17Wrapper}>Напишите полное название темы</Box>
            <TextField
                InputProps={theme.InputProps}
                sx={theme.TextField}
                id="outlined-multiline-flexible"
                multiline
                maxRows={6}
                value={stepData}
                placeholder="Тема работы"
                variant="outlined"
                onChange={(event) => {
                    setStepData(event.target.value);
                }}
            />

            <StepHiddenButtonWrapper submitRenew={handleThemeWork}>
                <SBButtonGroup
                    handleNext={() => {
                        if (stepData) {
                            store.setStep(10, stepData);
                            store.setStepValue(10, stepData);
                            store.incrementStepIndex();
                        } else {
                            setAlert(1);
                        }
                    }}
                    handleBack={() => store.decrementStepIndex()}
                    activeStep={store.activeStepIndex}
                    subStep={store.subStepIndex}
                    steps={store.storage}
                />
                {alert === 1 ? <RequestNullAlert>Чтобы перейти к следующему вопросу, заполните это поле</RequestNullAlert> : null}
            </StepHiddenButtonWrapper>
        </>
    );
};

export default observer(Step17);
