export const CardBlock = {
    width: "289px",
    height: "335px",
    borderRadius: "12px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    background: "rgb(255, 255, 255)",
    position: "relative",
    paddingTop: "15px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    alignItems: "center",
};

export const advantageElement = {
    display: "flex",
    gap: "8px",
    width: "229px",
};

export const advantageBlock = {
    display: "flex",
    gap: "8px",
    flexDirection: "column",
};

export const iconElem = {
    height: "fit-content",
    marginTop: "1px",
};

export const TopCard = {
    display: "flex",
    gap: "4px",
    flexDirection: "column",
};

export const CardContent = {
    display: "flex",
    gap: "12px",
    flexDirection: "column",
};
