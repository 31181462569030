import React, { useState, useRef, forwardRef } from "react";
import { TextField, IconButton, Stack, Popover } from "@mui/material";
import FullChatStore from "store/FullChatStore";
import Uploady, { useFileInput, useUploady, useBatchAddListener, useAbortItem } from "@rpldy/uploady";
import UploadDropZone from "@rpldy/upload-drop-zone";
import { send } from "@rpldy/sender";
import IconBtnSend from "assets/ChatAssets/SVG/iconButtonSend";
import CloseIcon from "@mui/icons-material/Close";
import { asUploadButton } from "@rpldy/upload-button";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";

import File from "assets/MiniChat/SVG/File.svg";
import Box from "@mui/material/Box";
import { GetHeaders } from "../ModalUploadImage/modalUploadImage";

const mySend = (items, url, sendOptions, onProgress) => {
    sendOptions = {
        ...sendOptions,
        paramName: "attachments",
        formatGroupParamName: (index, paramName) => {
            return "attachments";
        },
    };

    return send(items, url, sendOptions, onProgress);
};

const myEnhancer = (uploader) => {
    return uploader;
};

const SendMessage = ({ sendModalMessage, setSendModalMessage, setOpenModalUploadFile, setItemFile }) => {
    const { processPending } = useUploady();

    const [text, setText] = React.useState("");

    return (
        <Stack direction="row">
            <TextField
                sx={{
                    borderRadius: "8px",
                    width: "320px",
                    height: "33px",
                    flexGrow: "1",
                    border: "solid 1px rgba(158, 158, 176, 1)",
                    marginTop: "10px",
                }}
                placeholder={"Сообщение..."}
                value={text}
                InputProps={{
                    sx: {
                        "&.MuiOutlinedInput-root": {
                            backgroundColor: "white",
                            borderWidth: 0,
                            width: "100%",
                            height: "100%",
                            borderRadius: "inherit",
                            pl: "10px",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderWidth: 0,
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderWidth: 0,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderWidth: 0,
                        },
                        "& .MuiInputBase-input.MuiOutlinedInput-input": {
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "20px",
                        },
                    },
                }}
                onChange={(e) => setText(e.target.value)}
            />
            <IconButton
                onClick={() => {
                    processPending({
                        params: {
                            message: text,
                        },
                    });
                    setItemFile("");
                    setOpenModalUploadFile(false);
                }}
            >
                <IconBtnSend />
            </IconButton>
        </Stack>
    );
};

const DivUploadButton = asUploadButton(
    forwardRef((props, ref) => (
        <Box
            {...props}
            sx={{
                cursor: "pointer",
                width: "10%",
                paddingTop: "5px",
                color: "rgba(98, 18, 255, 1)",
            }}
        >
            <ControlPointIcon />
        </Box>
    )),
);

const UploadListItem = ({ item, delItem, setItemFile }) => {
    return (
        <Stack
            direction="row"
            sx={{
                width: "93%",
                height: "40px",
                display: "flex",
                marginRight: "15px",
                marginBottom: "10px",
            }}
        >
            <Stack
                sx={{
                    width: "12%",
                    backgroundImage: "url(" + File + ")",
                    marginRight: "0.8vw",
                    paddingTop: "10px",
                    color: "white",
                    textAlign: "center",
                }}
            >
                {item.file.name.split(".").at(-1).substr(0, 4)}
            </Stack>

            <Stack
                rowGap="4px"
                sx={{
                    width: "87%",
                }}
            >
                <Stack
                    sx={{
                        fontSize: "14px",
                        lineHeight: "19px",
                        color: "rgba(34, 34, 34, 1)",
                    }}
                >
                    {item.file.name}
                </Stack>
                <Stack
                    sx={{
                        fontSize: "13px",
                        color: "rgba(158, 158, 176, 1)",
                    }}
                >
                    {(item.file.size / 100000).toFixed(2)}МB
                </Stack>
            </Stack>

            <Stack
                onClick={() => {
                    delItem(item);
                }}
                sx={{
                    width: "1%",
                }}
            >
                <CloseIcon
                    sx={{
                        width: "15px",
                        height: "15px",
                    }}
                    viewBox="0 0 24 24"
                />
            </Stack>
        </Stack>
    );
};

const UploadList = ({ setItemFile }) => {
    const { processPending } = useUploady();

    const [items, setItems] = useState([]);

    const abortItem = useAbortItem();

    useBatchAddListener((batch) => {
        setItems((items) => items.concat(batch.items));
    });

    const delItem = (item) => {
        abortItem(item.id);
        const res = items.filter((file) => file.id !== item.id);

        setItems(res);
    };

    return (
        <Box sx={{ height: "200px", overflowY: "scroll" }}>
            {items.map((item) => (
                <UploadListItem
                    key={item.id}
                    item={item}
                    setItemFile={setItemFile}
                    delItem={delItem}
                />
            ))}
        </Box>
    );
};

const ExampleForm = () => {
    const inputRef = useRef();

    useFileInput(inputRef);

    return (
        <form
            action="https://study-buddy.ru/chats/upload"
            method="POST"
        >
            <input
                type="file"
                style={{ display: "none" }}
                ref={inputRef}
                multiple
            />
        </form>
    );
};

const ModalUploadFile = ({ openModalUploadFile, setOpenModalUploadFile, setOpenModal, setItemFile, itemFile, sendModalMessage, setSendModalMessage }) => {
    return (
        <ModalSettingsSmall
            visible={openModalUploadFile}
            bg="#F7F7F7"
            pl="20px"
            pr="20px"
            handleClose={() => {
                setOpenModalUploadFile(false);
                setOpenModal(false);
                setItemFile(null);
            }}
        >
            <Uploady
                enhancer={myEnhancer}
                send={mySend}
                grouped
                maxGroupSize={15}
                inputFieldName={"attachment"}
                multiple={false}
                customInput
                autoUpload={false}
                destination={{
                    headers: {
                        Authorization: GetHeaders(),
                    },
                    params: {
                        chat_id: FullChatStore.id,
                        type: "files",
                    },
                }}
                headers={{
                    Authorization: GetHeaders(),
                }}
            >
                <ExampleForm />
                <UploadDropZone
                    onDragOverClassName="drag-over"
                    grouped
                    maxGroupSize={3}
                >
                    <Stack
                        sx={{
                            width: "380px",
                            height: "310px",
                            borderRadius: "20px",
                            direction: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#F7F7F7",
                        }}
                    >
                        <Stack
                            direction="row"
                            sx={{
                                width: "100%",
                                height: "44px",
                                border: "dashed 1px #BBBBC8",
                                borderRadius: "10px",
                            }}
                            alignItems={"center"}
                        >
                            <Stack
                                sx={{
                                    width: "90%",
                                    paddingLeft: "10px",
                                }}
                            >
                                Добавьте или перетащите файл
                            </Stack>
                            <DivUploadButton />
                        </Stack>
                        <Stack
                            sx={{
                                height: "250px",
                                width: "100%",
                                paddingTop: "10px",
                            }}
                        >
                            <UploadList setItemFile={setItemFile} />
                        </Stack>
                        <SendMessage
                            sendModalMessage={sendModalMessage}
                            setSendModalMessage={setSendModalMessage}
                            setOpenModalUploadFile={setOpenModalUploadFile}
                            setItemFile={setItemFile}
                        />
                    </Stack>
                </UploadDropZone>
            </Uploady>
        </ModalSettingsSmall>
    );
};

export default ModalUploadFile;
