export const IconBackMini = () => {
    return (
        <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 13L5.11111 7.5L10 2"
                stroke="#9E9EB0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
