export const orderCardStyle = (styleMode) => {
    if (styleMode === 0) {
        return {
            boxShadow: "none",
            display: "flex",
            flexDirection: "column",
            gap: "25px",
            width: 804,
            height: "fit-content",
            padding: 20,
            boxSizing: "border-box",
            borderRadius: "20px",
            background: "#FFF",
        };
    }

    if (styleMode === 1) {
        return {
            boxShadow: "none",
            display: "flex",
            flexDirection: "column",
            gap: "25px",
            width: 907,
            height: "fit-content",
            padding: 20,
            boxSizing: "border-box",
            borderRadius: "20px",
            background: "#FFF",
        };
    }
};
