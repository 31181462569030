import { createTheme } from "@mui/material/styles";

export const DialogContainerTheme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: "rgba(0, 0, 0, 0)!important",
                    margin: 0,
                    boxShadow: "none!important",
                    width: "100vw!important",
                    height: "100vh!important",
                    maxWidth: "100vw!important",
                    maxHeight: "100vh!important",
                },
            },
        },
    },
});

export const dialogContainerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
};

export const topPanelContainerStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    height: "10vh",
};

export const contentContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
};

export const navContainerStyle = (hidden) => {
    return {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "transparent",
        width: "15vw",
        height: "100%",
        visibility: hidden ? "hidden" : "visible",
    };
};

export const contentBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "70vw",
    height: "70vh",
};

export const CloseIconTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    position: "absolute",
                    right: "7vw",
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid #9E9EB0",
                    borderRadius: "50%",
                    background: "transparent",
                    width: "24px",
                    height: "24px",
                    "&:hover": {
                        background: "#transparent!important",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "#transparent!important",
                        borderColor: "#6212FF",
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    padding: 0,
                    color: "#FFFFFF",
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    "&:hover": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                    "&:active": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                },
            },
        },
    },
});

export const NavIconTheme = (mode) =>
    createTheme({
        components: {
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "center",
                        border: "1px solid #9E9EB0",
                        borderRadius: "50%",
                        background: "transparent",
                        width: "24px",
                        height: "24px",
                        "&:hover": {
                            background: "transparent!important",
                            borderColor: "#6212FF",
                        },
                        "&:active": {
                            background: "transparent!important",
                            borderColor: "#6212FF",
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        padding: 0,
                        color: "#FFFFFF",
                        ".MuiSvgIcon-root": {
                            width: "16px",
                            height: "16px",
                            paddingLeft: mode ? 1 : 6,
                        },
                        "&:hover": {
                            color: "#FFFFFF",
                            borderColor: "#FFFFFF",
                        },
                        "&:active": {
                            color: "#FFFFFF",
                            borderColor: "#FFFFFF",
                        },
                    },
                },
            },
        },
    });
