import { styleProcessing } from "API/plugins/Utilities";
export const CardTitle = {
    width: "450px",
};

export const AvatarContainer = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
};

export const PhotoHidden = {
    borderRadius: 10000,
    border: "1px dashed #BBBBC8",
    width: "36px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

export const PhotoHiddenText = {
    color: "#67677A",
    fontSize: "8px",
    textAlign: "center",
};

export const StyledOrderCard = (theme) => {
    let commonStyle = {
        width: "100%",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "12px",
        padding: "20px 20px 10px 20px ",
        borderRadius: "16px",
        backgroundColor: "#fff",
        boxSizing: "border-box",
        boxShadow: "0px 4px 30px 0px rgba(106, 99, 118, 0.05)",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, padding: "12px 20px 16px 20px", gap: "14px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TopContentWrapper = {
    display: "flex",
    justifyContent: "space-between",
};

export const PurposeAndFinishTimeBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "7px",
};

export const RateButton = (condition) => {
    return {
        borderRadius: "30px",
        border: "1px solid #6212FF",
        background: condition ? "#F7F3FF" : "#fff",
        padding: "7px 16px 8px 16px",
        width: "fit-content",
        minWidth: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
    };
};

export const RatingBlock = (grade) => {
    return {
        display: "flex",
        gap: "4px",
        alignItems: "center",
        padding: "2px 10px 3px 10px",
        borderRadius: "6px",
        background: grade > 2 ? "#F1B063" : "#c0c0c0",
    };
};

export const ContentAndRateBlock = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
};

export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const StarIcon = {
    height: "16px",
};

export const Title = {
    marginBottom: "20px",
    fontWeight: 600,
    fontSize: "18px",
};

export const Divider = { color: "#EEEEF0" };

export const FinshedDateBlock = {
    display: "flex",
    alignItems: "center",
    gap: "4px",
};

export const StatusContainer = {
    display: "flex",
    alignItems: "center",
    gap: "1px",
    borderRadius: "16px",
    padding: "2px 10px 4px 4px",
    color: "rgba(34, 34, 34, 1)",
    backgroundColor: "rgba(25, 178, 12, 0.10)",
    width: "fit-content",
};

export const StatusBull = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "16px",
    width: "16px",
    fontSize: "20px",
    color: "#19B20C",
};

export const TutorAndStatusBlock = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const BottomBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};
