const RadioDefault = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <circle
                cx="8"
                cy="9"
                r="7.5"
                stroke="url(#paint0_linear_12500_47924)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_12500_47924"
                    x1="13.8"
                    y1="3.4"
                    x2="-0.392103"
                    y2="6.70175"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        offset="0.0869624"
                        stopColor="#04C83B"
                    />
                    <stop
                        offset="0.894315"
                        stopColor="#12AAFF"
                    />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default RadioDefault;
