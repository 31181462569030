import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import React from "react";
import Shedule from "components/ScheduleBodyBlock/SheduleBodyBlock";

const theme = createTheme({
    spacing: 1,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const SchedulePage = () => {
    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    background: "rgba(247, 247, 247, 1)",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    borderRadius: "0px 0px 40px 40px",
                    margin: "0 auto",
                }}
            >
                <Shedule />
            </Box>
        </ThemeProvider>
    );
};

export default SchedulePage;
