import React from "react";
import * as themes from "./TypeCompThemes";
import * as textThemes from "themes/TextThemes";
import { memo } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useMedias } from "../../../../API/mediasHook";

const TypeComp = ({ chidlren, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={themes.TypeBlock(props.height, medias)}>
            <Typography sx={textThemes.HeadlineH3Black}>{props.header}</Typography>
            <Typography sx={textThemes.Body1Black}>{props.text}</Typography>
        </Box>
    );
};

export default memo(TypeComp);
