import React from "react";
import * as themes from "./OrderResponseButtonStyled";
import { ArrowForward } from "@mui/icons-material";
import { Typography } from "@mui/material";

const OrderResponseButton = ({ text, handleClick, variant, disabled, icon, textSize }) => {
    const stylesMap = {
        contained: themes.contained,
        outlined: themes.outlined,
        grayFilled: themes.grayFilled,
        awaitingGrayDisabled: themes.awaitingGrayDisabled,
        containedHeight: themes.containedHeight,
    };

    const styles = stylesMap[variant];

    return (
        <themes.StyledOrderResponseButton
            disableRipple
            disabled={disabled}
            onClick={handleClick}
            variant={variant}
            sx={styles}
            endIcon={icon ? <ArrowForward /> : null}
        >
            <Typography sx={textSize === "big" ? themes.bigText : themes.defaultText}>{text}</Typography>
        </themes.StyledOrderResponseButton>
    );
};

export default OrderResponseButton;
