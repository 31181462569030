import React, { useState } from "react";

import { Menu, Typography, ThemeProvider, Box, MenuItem } from "@mui/material";
import { MoreHorizOutlined } from "@mui/icons-material";

import SButton from "components/UI/SButton";

import * as styles from "./MenuShowImageThemes";

import OrdersResponsesStore from "store/OrdersResponsesStore";

const MenuShowImage = ({ handleDownload, link, type = "jpg" }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const menuItems = [
        { text: "Перейти к сообщению", onClick: null },
        {
            text: "Скачать",
            onClick: () => {
                handleDownload(link, `ChatAttachment.${type}`);
                setAnchorEl(null);
            },
        },
    ];

    const handleMenuToggle = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (value, event) => {
        if (value !== null) {
            OrdersResponsesStore.SetSortBy(value);
            OrdersResponsesStore.SortOrders();
        }

        setAnchorEl(null);
    };

    return (
        <Box style={styles.MenuContainer}>
            <SButton
                variant="icon"
                onClick={handleMenuToggle}
            >
                <MoreHorizOutlined />
            </SButton>
            <ThemeProvider theme={styles.MenuTheme}>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose.bind(this, null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    {menuItems.map((value, index) => {
                        return (
                            <MenuItem
                                key={index}
                                disableRipple
                                onClick={value.onClick}
                            >
                                <Typography sx={styles.MenuText(value.text)}>{value.text}</Typography>
                            </MenuItem>
                        );
                    })}
                </Menu>
            </ThemeProvider>
        </Box>
    );
};

export default MenuShowImage;
