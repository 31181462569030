const OrangeExclamationIcon = function (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path
                d="M9.00387 0.666016C4.40539 0.666016 0.666016 4.40539 0.666016 8.99935C0.666016 13.5933 4.40539 17.3327 8.99935 17.3327C13.5933 17.3327 17.3327 13.5933 17.3327 8.99935C17.3327 4.40539 13.5978 0.666016 9.00387 0.666016ZM9.00387 15.09C8.41606 15.09 7.93677 14.6107 7.93677 14.0229C7.93677 13.4351 8.41606 12.9558 9.00387 12.9558C9.59168 12.9558 10.071 13.4351 10.071 14.0229C10.071 14.6107 9.59168 15.09 9.00387 15.09ZM10.5322 5.52811L9.56455 11.2149C9.51933 11.5269 9.27969 11.753 8.99935 11.753C8.71901 11.753 8.47936 11.5269 8.43415 11.2149L7.46652 5.52811C7.33268 4.41602 7.52982 3.70454 7.83277 3.35185C8.07694 3.06699 8.40702 2.90874 8.75518 2.90874H9.23899C9.58716 2.90874 9.91724 3.06699 10.1614 3.35185C10.4689 3.70454 10.666 4.41602 10.5322 5.52811Z"
                fill="#FF9212"
            />
        </svg>
    );
};

export default OrangeExclamationIcon;
