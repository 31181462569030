//Детали работы
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import SBButtonGroup from "components/UI/ButtonGroup";
import store from "store/RequestStore";

import TextField from "@mui/material/TextField";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import StepHiddenButtonWrapper from "components/OrdersBodyBlock/RenewOrder/StepHiddenButtonWrapper";
import filterStore from "store/FilterStore";
import RequestNullAlert from "./RequestNullAlert";
import * as theme from "./Step21Themes";
const Step21 = () => {
    const formatPages = (pages) => {
        if (pages.slice(-1) === "1") {
            return "страница";
        }

        if (pages.slice(-1) === "2" || pages.slice(-1) === "3" || pages.slice(-1) === "4") {
            return "страницы";
        } else {
            return "страниц";
        }
    };

    const formatSources = (sources) => {
        if (sources.slice(-1) === "1") {
            return "источник";
        }

        if (sources.slice(-1) === "2" || sources.slice(-1) === "3" || sources.slice(-1) === "4") {
            return "источника";
        } else {
            return "источников";
        }
    };

    const handleSubmitFilterChange = () => {
        filterStore.setSubmitButtonFilter(false);
    };

    const [alert, setAlert] = useState(0);

    let data = store.storage[store.activeStepIndex].substeps[store.subStepIndex]?.value;

    if (data === undefined) store.setActiveSubStepIndex(0);

    const [stepDataPages, setStepDataPages] = useState(store.storage[store.activeStepIndex].substeps[store.subStepIndex].value[0]);

    const [stepDataSources, setStepDataSources] = useState(store.storage[store.activeStepIndex].substeps[store.subStepIndex].value[1]);

    const [foreignSources, setForeignSources] = useState(store.storage[store.activeStepIndex].substeps[store.subStepIndex].value[2]);

    const handleNext = () => {
        if (stepDataPages) {
            let description = "";

            if (stepDataPages) {
                description += stepDataPages + " " + formatPages(stepDataPages);
            }

            if (stepDataSources) {
                if (stepDataPages) {
                    description += ", " + stepDataSources + " " + formatSources(stepDataSources);
                } else {
                    description += stepDataSources + " " + formatSources(stepDataSources);
                }
            }

            store.setStep(13, description);
            store.setStepValue(13, [stepDataPages, stepDataSources, foreignSources]);
            store.incrementStepIndex();
        } else {
            setAlert(1);
        }
    };

    return (
        <>
            <Box sx={theme.DetailWrapp}>
                <Box sx={theme.Text}>Укажите количество страниц</Box>
                <TextField
                    placeholder={"0"}
                    variant="standard"
                    sx={theme.TextField}
                    onChange={(event) => {
                        setStepDataPages(event.target.value);
                        handleSubmitFilterChange();
                    }}
                    value={stepDataPages}
                />
            </Box>
            <Box>
                <Box sx={theme.Text}>Количество источников</Box>
                <TextField
                    placeholder={"0"}
                    variant="standard"
                    sx={theme.TextField}
                    onChange={(event) => {
                        setStepDataSources(event.target.value);
                        handleSubmitFilterChange();
                    }}
                    value={stepDataSources}
                />
            </Box>

            <Box sx={theme.CheckboxWrapper}>
                <FormControlLabel
                    sx={theme.FormControlLabel}
                    control={
                        <Checkbox
                            sx={theme.Checkbox}
                            checked={foreignSources}
                            onChange={(event, newInputValue) => {
                                setForeignSources(newInputValue);
                                handleSubmitFilterChange();
                            }}
                            style={{ color: "#6212FF" }}
                        />
                    }
                />
                <Box sx={theme.BottomText}>Включить иностранные источники</Box>
            </Box>

            <StepHiddenButtonWrapper submitRenew={handleNext}>
                <SBButtonGroup
                    handleNext={handleNext}
                    handleBack={() => store.decrementStepIndex()}
                    activeStep={store.activeStepIndex}
                    subStep={store.subStepIndex}
                    steps={store.storage}
                />
                {alert === 1 ? <RequestNullAlert>Чтобы перейти к следующему вопросу, укажите количество страниц</RequestNullAlert> : null}
            </StepHiddenButtonWrapper>
        </>
    );
};

export default observer(Step21);
