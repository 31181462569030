import { makeAutoObservable } from "mobx";
import * as CatalogService from "../API/services/grpc/CatalogService";
import { handleStoreActionError } from "../API/plugins/errorHandlers";
import * as RatesService from "../API/services/grpc/RatesService";
import RequestService from "../API/services/RequestService";

class TeacherStore {
    tutorData = null;

    loadingTutorData = true;
    loadingRecommendedTutorsData = true;
    loadingRecentReviewsData = true;
    loadingMoreReviewsData = false;

    selectedTab = null;

    activeChip = null;

    sortByOptions = [];
    sortByOption = null;

    disciplinesExpanded = false;

    reviewsList = [];
    reviewsListFiltered = [];

    teachersArrDisciplineRecommended = [];

    teacherWeekTimeSlots = null;

    currentBlock = 1;
    reviewsToLoad = 3;
    from = 0;
    to = this.reviewsToLoad;

    constructor() {
        this.Init();
        makeAutoObservable(this);
    }

    debounce(callback, delay) {
        let timerID = null;

        return (...args) => {
            if (timerID) {
                clearTimeout(timerID);
            }

            timerID = setTimeout(() => {
                callback.call(this, ...args);
            }, delay);
        };
    }

    SetSelectedTab(value) {
        console.log(value);
        this.selectedTab = value;
    }

    SetActiveChip(value) {
        console.log(value);
        this.activeChip = value;
    }

    SetChipFilter() {
        console.log(this.activeChip);
        this.reviewsListFiltered = this.reviewsList.filter((value) =>
            this.activeChip.Query === "" ? true : value.AssociatedTags.TagDiscipline === this.activeChip.Query || value.AssociatedTags.TagsOther.find((tag) => tag === this.activeChip.Query),
        );
        this.SortReviews();
    }

    SetSortBy(value) {
        console.log(value);
        this.sortByOption = value;
    }

    LoadMoreReviews(data, mode) {
        console.log(data);
        let newDataToAppend = [];

        if (mode === "reset") {
            this.reviewsList = [];
        }

        data.ratesList.forEach((value, index) => {
            newDataToAppend.push({
                ID: this.reviewsList.length + index,
                _id: value.rateUuid,
                Name: "Юлия", //mocked now
                ReviewDate: new Date(value.createdAt),
                ReviewDateFormatted: new Date(value.createdAt).toLocaleString("ru", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                }),
                AvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg", //mocked now
                Review: value.text,
                RatingValue: value.rate,
                AssociatedTags: { ID: 0, TagDiscipline: value.orderType, TagsOther: value.tagsList },
                Answers: value.answersList.map((x, index) => {
                    return { ID: index, Answer: x };
                }),
            });
        });

        this.reviewsList = [...this.reviewsList, ...newDataToAppend];
        this.reviewsListFiltered = [...this.reviewsList];
        this.SortReviews();
    }

    ToggleDisciplinesExpanded(value) {
        value === undefined ? (this.disciplinesExpanded = !this.disciplinesExpanded) : (this.disciplinesExpanded = value);
    }

    SortReviews() {
        this.reviewsListFiltered = this.reviewsListFiltered.sort((a, b) => {
            if (this.sortByOption.sortValue === "ReviewDate") {
                return new Date(b[this.sortByOption.sortValue]) - new Date(a[this.sortByOption.sortValue]);
            }

            if (this.sortByOption.sortValue === "RatingValue") {
                if (this.sortByOption.sortDirection === "desc") {
                    return b[this.sortByOption.sortValue] - a[this.sortByOption.sortValue];
                } else {
                    return a[this.sortByOption.sortValue] - b[this.sortByOption.sortValue];
                }
            }
        });
    }

    async FetchPageData(uuid) {
        await this.FetchTutorData(uuid);
    }

    async FetchTutorData(uuid) {
        let data = {
            uuid: uuid,
        };

        this.SetLoadingState("loadingTutorData", true);

        await CatalogService.GetTutor(data, async (error, response) => {
            if (error) {
                handleStoreActionError({
                    error,
                    customErrorText: "catalog.errors.fetchTutorDataError",
                    dispatch: true,
                });
            } else {
                let data = response.toObject().data;

                this.FillTutorData(data);
                this.SetLoadingState("loadingTutorData", false);
                await this.FetchRecommendedTutorsData();
            }

            this.SetLoadingState("loadingTutorData", false);
        });
    }

    async FetchRecommendedTutorsData() {
        let data = {
            tags: this.tutorData.Tags.map((tag) => tag.Query),
            subject: this.tutorData.Disciplines[0].discipline,
            tutorUuid: this.tutorData._id,
        };

        this.SetLoadingState("loadingRecommendedTutorsData", true);

        await CatalogService.GetRecommendedTutors(data, async (error, response) => {
            if (error) {
                handleStoreActionError({
                    error,
                    customErrorText: "catalog.errors.fetchRecommendedTutorsDataError",
                    dispatch: true,
                });
            } else {
                let data = response.toObject().dataList; //??

                this.FillRecommendedTutorsData(data);
                this.SetLoadingState("loadingRecommendedTutorsData", false);
                await this.FetchRecentReviewsData();
            }

            this.SetLoadingState("loadingRecommendedTutorsData", false);
        });
    }

    async FetchRecentReviewsData() {
        let data = {
            tutorUuid: this.tutorData._id,
        };

        this.SetLoadingState("loadingRecentReviewsData", true);

        await RatesService.GetRecentReviews(data, (error, response) => {
            if (error) {
                handleStoreActionError({
                    error,
                    customErrorText: "catalog.errors.fetchRecentReviewsDataError",
                    dispatch: true,
                });
            } else {
                let data = response.toObject().data;

                this.FillReviewsData(data);
                this.reviewsListFiltered = [...this.reviewsList];
                this.SortReviews();
                this.SetLoadingState("loadingRecentReviewsData", false);
            }

            this.SetLoadingState("loadingRecentReviewsData", false);
        });
    }

    async FetchMoreReviewsData(mode = "default") {
        /*this.from = this.reviewsList.length === this.reviewsToLoad ? this.reviewsToLoad * this.currentBlock + 1 : this.to + 1;
        this.to = this.from + this.reviewsToLoad - 1;*/
        this.to = mode === "default" ? this.reviewsList.length : 0;

        console.log(this.to, this.activeChip.Query, this.sortByOption);
        let data = {
            skip: this.to,
            tag: this.activeChip.Query,
            tutorUuid: "ia306c45-0b1b-40eb-ae3d-6e0edbcfa022",
            sort: this.sortByOption.sortValueForQuery,
        };

        this.SetLoadingState("loadingMoreReviewsData", true);

        await RatesService.GetMoreReviews(data, (error, response) => {
            if (error) {
                handleStoreActionError({
                    error,
                    customErrorText: "catalog.errors.fetchMoreReviewsDataError",
                    dispatch: true,
                });
            } else {
                let data = response.toObject().data;

                this.LoadMoreReviews(data, mode);
                this.SetLoadingState("loadingMoreReviewsData", false);
            }

            this.SetLoadingState("loadingMoreReviewsData", false);
        });
    }

    SetLoadingState(field, value) {
        this[field] = value;
    }

    FillTutorData(data) {
        console.log(data);
        this.tutorData = {
            ID: 0,
            _id: data.uuid,
            AvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg", //mocked now
            VideoSrc: "https://www.youtube.com/watch?v=k1-TrAvp_xsk", //mocked now
            Name: data.name,
            IsVerified: data.isVerified,
            IsAnonymous: data.isAnonymous,
            LastSeen:
                "был в сети: " +
                (new Date(data.status.lastSeen) === new Date()
                    ? "Сегодня, " +
                      new Date(data.status.lastSeen).toLocaleString("ru", {
                          hour: "numeric",
                          minute: "numeric",
                      })
                    : "" +
                      new Date(data.status.lastSeen).toLocaleString("ru", {
                          day: "numeric",
                          month: "short",
                          weekday: "short",
                          hour: "numeric",
                          minute: "numeric",
                      })),
            IsActive: data.status.online,
            Rating: null,
            ReviewsCount: null,
            Disciplines: data.subjectList.map((x, index) => {
                return { ID: x, discipline: x };
            }),
            Location: data.metroStationsList.map((x, index) => {
                return { ID: index, location: x };
            }),
            Education: [data.educationalInstitution].map((x, index) => {
                return { ID: index, education: x };
            }),
            Experience: data.experience + " лет",
            Tags: [
                ...data.audienceList.map((x, index) => {
                    return { ID: index, Query: x };
                }),
                ...data.purposeList.map((x, index) => {
                    return { ID: data.audienceList.length + index, Query: x };
                }),
            ],
            Schedule: [
                {
                    ID: 0,
                    Time: "06:00-12:00",
                    from: 6,
                    to: 12,
                    Days: [
                        {
                            ID: 0,
                            Day: "Пн",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Monday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "06:00", end: "12:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 1,
                            Day: "Вт",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Tuesday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "06:00", end: "12:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 2,
                            Day: "Ср",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Wednesday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "06:00", end: "12:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 3,
                            Day: "Чт",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Thursday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "06:00", end: "12:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 4,
                            Day: "Пт",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Friday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "06:00", end: "12:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 5,
                            Day: "Сб",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Saturday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "06:00", end: "12:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 6,
                            Day: "Вс",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Sunday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "06:00", end: "12:00" })).length > 0,
                                ).length > 0,
                        },
                    ],
                },
                {
                    ID: 1,
                    Time: "12:00-18:00",
                    from: 12,
                    to: 18,
                    Days: [
                        {
                            ID: 0,
                            Day: "Пн",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Monday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "12:00", end: "18:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 1,
                            Day: "Вт",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Tuesday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "12:00", end: "18:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 2,
                            Day: "Ср",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Wednesday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "12:00", end: "18:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 3,
                            Day: "Чт",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Thursday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "12:00", end: "18:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 4,
                            Day: "Пт",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Friday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "12:00", end: "18:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 5,
                            Day: "Сб",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Saturday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "12:00", end: "18:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 6,
                            Day: "Вс",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Sunday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "12:00", end: "18:00" })).length > 0,
                                ).length > 0,
                        },
                    ],
                },
                {
                    ID: 2,
                    Time: "18:00-24:00",
                    from: 18,
                    to: 24,
                    Days: [
                        {
                            ID: 0,
                            Day: "Пн",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Monday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "18:00", end: "24:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 1,
                            Day: "Вт",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Tuesday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "18:00", end: "24:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 2,
                            Day: "Ср",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Wednesday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "18:00", end: "24:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 3,
                            Day: "Чт",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Thursday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "18:00", end: "24:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 4,
                            Day: "Пт",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Friday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "18:00", end: "24:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 5,
                            Day: "Сб",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Saturday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "18:00", end: "24:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 6,
                            Day: "Вс",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Sunday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "18:00", end: "24:00" })).length > 0,
                                ).length > 0,
                        },
                    ],
                },
                {
                    ID: 3,
                    Time: "00:00-06:00",
                    from: 0,
                    to: 6,
                    Days: [
                        {
                            ID: 0,
                            Day: "Пн",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Monday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "00:00", end: "06:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 1,
                            Day: "Вт",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Tuesday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "00:00", end: "06:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 2,
                            Day: "Ср",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Wednesday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "00:00", end: "06:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 3,
                            Day: "Чт",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Thursday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "00:00", end: "06:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 4,
                            Day: "Пт",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Friday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "00:00", end: "06:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 5,
                            Day: "Сб",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Saturday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "00:00", end: "06:00" })).length > 0,
                                ).length > 0,
                        },
                        {
                            ID: 6,
                            Day: "Вс",
                            Checked:
                                data.availableTimesList.filter(
                                    (value) =>
                                        value.dayOfWeek === "Sunday" && value.timeSlotsList.filter((value) => JSON.stringify(value) === JSON.stringify({ start: "00:00", end: "06:00" })).length > 0,
                                ).length > 0,
                        },
                    ],
                },
            ],
            Description: data.description,
            DescriptionFull: data.description,
            PriceFrom: data.price,
            Cashback: data.cashback,
            Liked: data.isFavourite,
            RatingInfo: [],
            EducationInfo: data.resume.education.placesOfEducationList.map((x, index) => {
                return { ID: index, IsVerified: x.docConfirmed, EducationInfo: x.place, EducationYear: parseInt(x.year) };
            }),
            ExperienceInfo: data.resume.experience.placesOfExperienceList.map((x, index) => {
                return { ID: index, ExperienceInfo: x.place, ExperienceYearStart: parseInt(x.year), ExperienceYearEnd: 2024 }; //нет года окончания
            }),
            CertificatesInfo: data.resume.certificates.placesOfCertificatesList.map((x, index) => {
                return {
                    ID: index,
                    IsVerified: x.docConfirmed,
                    CertificatesInfo: x.place,
                    CertificatesYear: parseInt(x.year),
                    Doc: { ID: index, Img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e", Title: x.certificate },
                };
            }),
            DisciplinesInfo: data.extSubjects.subjectsWithConditionsList.reduce(
                (acc, value, indexUpper) => {
                    return [
                        ...acc,
                        ...value.conditionsList.map((cond, indexLower) => {
                            return {
                                ID: indexUpper + " " + indexLower,
                                DisciplineID: value.subjectName,
                                DisciplineInfo: cond.description,
                                DisciplineCost: cond.price,
                                DisciplineCostTime: 90,
                            };
                        }),
                    ];
                },

                [],
            ),
        };
    }

    FillRecommendedTutorsData(data) {
        console.log(data);

        data.forEach((value, index) => {
            this.teachersArrDisciplineRecommended.push({
                ID: index,
                _id: value.tutorUuid,
                AvatarSrc: "https://" + process.env.REACT_APP_API_URL + value.photo.link,
                Name: value.name,
                IsVerified: value.isVerified,
                IsAnonymous: value.isAnonymous,
                Rating: value.rates.average,
                ReviewsCount: value.rates.total,
                Disciplines: value.subjectsList.map((x, index) => {
                    return { ID: x, discipline: x };
                }),
                Tags: value.tagsList.map((x, index) => {
                    return { ID: index, Query: x };
                }),
                PriceFrom: value.price,
                Cashback: value.cashback,
            });
        });
    }

    FillReviewsData(data) {
        console.log(data);
        data.ratesList.forEach((value, index) => {
            this.reviewsList.push({
                ID: index,
                _id: value.rateUuid,
                Name: "Юлия", //mocked now
                ReviewDate: new Date(value.createdAt),
                ReviewDateFormatted: new Date(value.createdAt).toLocaleString("ru", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                }),
                AvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg", //mocked now
                Review: value.text,
                RatingValue: value.rate,
                AssociatedTags: { ID: 0, TagDiscipline: value.orderType, TagsOther: value.tagsList },
                Answers: value.answersList.map((x, index) => {
                    return { ID: index, Answer: x };
                }),
            });
        });

        let mapper = {
            5: " звёзд",
            4: " звезды",
            3: " звезды",
            2: " звезды",
            1: " звезда",
        };

        this.tutorData.Rating = data.average;
        this.tutorData.ReviewsCount = data.total;
        this.tutorData.RatingInfo = data.ratingList.map((x, index) => {
            return { ID: index, RatingText: x.rate + mapper[x.rate], RatingValue: x.rate, RatingCount: x.count };
        });
    }

    Init() {
        this.Load();
    }

    Load() {
        this.selectedTab = "DescriptionCard";

        this.activeChip = { ID: null, Query: "" };

        this.sortByOptions = [
            { ID: 0, sortBy: "Сначала новые", sortValue: "ReviewDate", sortValueForQuery: "" },
            { ID: 1, sortBy: "Сначала положительные", sortValue: "RatingValue", sortDirection: "desc", sortValueForQuery: "positive" },
            { ID: 2, sortBy: "Сначала отрицательные", sortValue: "RatingValue", sortDirection: "asc", sortValueForQuery: "negative" },
        ];
        this.sortByOption = this.sortByOptions[0];

        this.teacherWeekTimeSlots = {
            Пн: [18, 19, 20, 21, 22, 23],
            Вт: [18, 19, 20, 21, 22, 23],
            Ср: [],
            Чт: [18, 19, 20, 21, 22, 23],
            Пт: [18, 19, 20, 21, 22, 23],
            Сб: [8, 9, 10, 11, 12],
            Вс: [8, 9, 10, 11, 12, 13, 14],
        };
    }
}

export default new TeacherStore();
