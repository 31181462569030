import { Typography } from "@mui/material";
import * as textThemes from "themes/TextThemes";
import { useMedias } from "API/mediasHook";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const OrderDate = ({ date }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    function formatDate(dateString) {
        const date = new Date(dateString);

        const today = new Date();

        today.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);

        const yesterday = new Date(today);

        yesterday.setDate(today.getDate() - 1);

        const months = [
            "main.months.atJanuary",
            "main.months.atFebruary",
            "main.months.atMarch",
            "main.months.atApril",
            "main.months.atMay",
            "main.months.atJune",
            "main.months.atJuly",
            "main.months.atAugust",
            "main.months.atSeptember",
            "main.months.atOctober",
            "main.months.atNovember",
            "main.months.atDecember",
        ];

        if (date.getTime() === today.getTime()) {
            return t("main.today");
        } else if (date.getTime() === yesterday.getTime()) {
            return t("main.yesterday");
        } else {
            const day = date.getDate();

            const month = months[date.getMonth()];

            return `${day} ${t(month)}`;
        }
    }

    return <Typography sx={textThemes.Body2Grey}>{formatDate(date)}</Typography>;
};

export default memo(OrderDate);
