const DeleteStatementIcon = function (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
        >
            <g filter="url(#filter0_d_5411_113476)">
                <ellipse
                    cx="26"
                    cy="24"
                    rx="16"
                    ry="16"
                    fill="white"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.545 18.2588C21.1916 17.9137 20.6185 17.9137 20.2651 18.2588C19.9116 18.6038 19.9116 19.1631 20.2651 19.5081L24.8668 24L20.2651 28.4919C19.9116 28.8369 19.9116 29.3962 20.2651 29.7412C20.6185 30.0863 21.1916 30.0863 21.545 29.7412L26.1467 25.2494L30.455 29.4548C30.8084 29.7998 31.3815 29.7998 31.7349 29.4548C32.0884 29.1098 32.0884 28.5504 31.7349 28.2054L27.4267 24L31.7349 19.7946C32.0884 19.4496 32.0884 18.8902 31.7349 18.5452C31.3815 18.2002 30.8084 18.2002 30.455 18.5452L26.1467 22.7506L21.545 18.2588Z"
                fill="#6212FF"
            />
            <defs>
                <filter
                    id="filter0_d_5411_113476"
                    x="0"
                    y="0"
                    width="52"
                    height="52"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite
                        in2="hardAlpha"
                        operator="out"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.414141 0 0 0 0 0.389271 0 0 0 0 0.4625 0 0 0 0.15 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_5411_113476"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_5411_113476"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};

export default DeleteStatementIcon;
