import { Typography, MenuItem, MenuList, Box } from "@mui/material";
import React, { useState, useRef } from "react";

import Avatar_teacher from "assets/PAAssets/PNG/avatar_teacher.png";

import * as themes from "./MainFormThemes";
import MainInfo from "./MainInfo/MainInfo";
import AboutTeacher from "./AboutTeacher/AboutTeacher";
import Education from "./Education/Education";
import Experience from "./Experience/Experience";
import Certificates from "./Certificates/Certificates";
import Prices from "./Prices/Prices";
import Places from "./Places/Places";
import Links from "./Links/Links";
import MainInfoSet from "./MainInfo/MainInfoSet";
import AboutTeacherEdit from "./AboutTeacher/AboutTeacherEdit";
import LinksEdit from "./Links/LinksEdit";
import EducationEdit from "./Education/EducationEdit";
import ExperienceEdit from "./Experience/ExperienceEdit";
import CertificatesEdit from "./Certificates/CertificatesEdit";
import PricesEdit from "./Prices/PricesEdit";
import PlacesEdit from "./Places/PlacesEdit";
import Video from "./Video/Video";
import VideoEdit from "./Video/VideoEdit";
import * as textThemes from "themes/TextThemes";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";

const MainForm = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const [variant, setVariant] = useState(0);

    const [item, setItem] = useState(1);

    const sections = [
        { ref: useRef(null), component: MainInfo, editComponent: MainInfoSet },
        { ref: useRef(null), component: AboutTeacher, editComponent: AboutTeacherEdit },
        { ref: useRef(null), component: Video, editComponent: VideoEdit },
        { ref: useRef(null), component: Education, editComponent: EducationEdit },
        { ref: useRef(null), component: Experience, editComponent: ExperienceEdit },
        { ref: useRef(null), component: Certificates, editComponent: CertificatesEdit },
        { ref: useRef(null), component: Prices, editComponent: PricesEdit },
        { ref: useRef(null), component: Places, editComponent: PlacesEdit },
        { ref: useRef(null), component: Links, editComponent: LinksEdit },
    ];

    const ScrollTo = (item) => {
        setVariant(0);
        setItem(item);
        sections[item - 1]?.ref.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleSetVariant = (val) => {
        setVariant(val);
    };

    const RightSide = () => {
        if (variant === 0) {
            return (
                <Box sx={themes.InfoBlocks}>
                    {sections.map((section, index) => (
                        <Box
                            key={index}
                            ref={section.ref}
                        >
                            <section.component handleSet={handleSetVariant} />
                        </Box>
                    ))}
                </Box>
            );
        } else {
            const EditComponent = sections[variant - 1]?.editComponent;

            return (
                <EditComponent
                    avt={variant === 1 ? Avatar_teacher : undefined}
                    handleSet={handleSetVariant}
                />
            );
        }
    };

    const menuItems = [
        t("teacherSettings.questionnaire.personalInfo"),
        t("teacherSettings.questionnaire.aboutYourself"),
        t("teacherSettings.questionnaire.videoCard"),
        t("teacherSettings.questionnaire.education"),
        t("teacherSettings.questionnaire.experience"),
        t("teacherSettings.questionnaire.certificates"),
        t("teacherSettings.questionnaire.subjectsAndPrice"),
        t("teacherSettings.questionnaire.classesPlace"),
        t("teacherSettings.questionnaire.profilesLinks"),
    ];

    return (
        <Box sx={themes.MainBlock(medias)}>
            <MenuList sx={themes.Menu(medias)}>
                {menuItems.map((label, index) => (
                    <MenuItem
                        key={index}
                        selected={item === index + 1}
                        disableRipple
                        onClick={() => ScrollTo(index + 1)}
                        sx={themes.MenuItem(item === index + 1)}
                    >
                        <Typography sx={textThemes.Body2Black}>{label}</Typography>
                    </MenuItem>
                ))}
            </MenuList>
            <Box>{RightSide()}</Box>
        </Box>
    );
});

export default memo(MainForm);
