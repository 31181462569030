import React, { memo } from "react";
import CurrentClasses from "./CurrentClasses/CurrentClasses";
import MyClassesList from "./MyClassesList/MyClassesList";
import Box from "@mui/material/Box";
import * as styles from "./MyClassesBodyBlockThemes";
import { observer } from "mobx-react-lite";
import ModalClassPaid from "components/PaymentBodyBlock/ModalClassPaid/ModalClassPaid";
import { useMedias } from "API/mediasHook";
import SButton from "components/UI/SButton";
import MyClassesStore from "store/MyClassesStore";
import { useTranslation } from "react-i18next";

const MyClassesBodyBlock = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const showChooseTutorDialog = () => {
        MyClassesStore.SetDialogChooseTutorOpen(true);
    };

    return (
        <Box sx={styles.MyClasses(medias)}>
            <ModalClassPaid />
            <Box sx={styles.MyClassesContent}>
                <CurrentClasses />
                <MyClassesList />
                {!medias.sm && MyClassesStore.classes.length !== 0 && (
                    <SButton
                        variant="arrow"
                        padding="7px 16px 8px 16px"
                        onClick={showChooseTutorDialog}
                        style={{ width: "100%" }}
                    >
                        {t("main.myClasses.signUpForClass")}
                    </SButton>
                )}
            </Box>
        </Box>
    );
});

export default memo(MyClassesBodyBlock);
