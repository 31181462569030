import React from "react";
import * as themes from "./NumFourThemes";
import RectangleSample from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumbersCommonComponents/RectangleSample/RectangleSample";
import { Box } from "@mui/material";
import { imageRectangleSources } from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumbersCommonComponents/NumbersAssetsIndex";
import { getRandomImageSource } from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumbersCommonComponents/imageUtils";
import NumberPositionBox from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumbersCommonComponents/NumberPositionBox/NumberPositionBox";
import { memo } from "react";

const NumFour = ({ children, ...props }) => {
    const positions = [
        { left: 0, top: 97 },
        { left: 145, top: 184 },
        { left: 0, top: 0 },
        { left: 72, top: 128 },
        { left: 145, top: 87 },
        { left: 145, top: 281 },
    ];

    return (
        <Box sx={themes.BlockStyle}>
            {positions.map((position, index) => (
                <NumberPositionBox
                    key={index}
                    position={position}
                >
                    {index < 2 ? (
                        <RectangleSample />
                    ) : (
                        <img
                            src={getRandomImageSource(imageRectangleSources)}
                            alt="Random Icon"
                        />
                    )}
                </NumberPositionBox>
            ))}
        </Box>
    );
};

export default memo(NumFour);
