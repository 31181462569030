export const MiniChat = {
    position: "fixed",
    bottom: "0vh",
    right: "0vh",
    width: "24.6vw",
    backgroundColor: "white",
    borderRadius: "2.45vh 0px 0px 0px",
    height: "75vh",
    boxShadow: " 0px 4px 40px 0px rgba(112, 108, 121, 0.30)",
};
