import { AuthServiceClient } from "API/services/grpc/proto/AuthorizationService/auth_grpc_web_pb";

import {
    RegisterRequest,
    RefreshTokensRequest,
    LoginSmsRequest,
    RegisterSmsVerifyRequest,
    LoginEmailRequest,
    LogoutRequest,
    ValidateTokenRequest,
    LoginSmsVerifyRequest,
    RecoverPasswordRequest,
} from "API/services/grpc/proto/AuthorizationService/auth_pb";

const AuthServiceUrl = "https://study-buddy.ru:8080";

const AuthService = new AuthServiceClient(AuthServiceUrl, null, null);

export async function Register(phone, email, name, role, callback) {
    const request = new RegisterRequest();

    request.setPhone(phone);
    request.setEmail(email);
    request.setName(name);
    request.setRole(role);
    await AuthService.register(request, null, callback);
}

export async function LoginSms(phone, callback) {
    const request = new LoginSmsRequest();

    request.setPhone(phone);
    await AuthService.loginSms(request, null, callback);
}

export async function SignUpSmsVerify(phone, role, smsCode, callback) {
    const request = new RegisterSmsVerifyRequest();

    request.setPhone(phone);
    request.setRole(role);
    request.setCode(smsCode);
    await AuthService.registerSmsVerify(request, null, callback);
}

export async function LoginSmsVerify(phone, smsCode, callback) {
    const request = new LoginSmsVerifyRequest();

    request.setPhone(phone);
    request.setCode(smsCode);
    await AuthService.loginSmsVerify(request, null, callback);
}

export async function LoginEmail(email, password, callback) {
    const request = new LoginEmailRequest();

    request.setEmail(email);
    request.setPassword(password);
    await AuthService.loginEmail(request, null, callback);
}

export async function ValidateToken(accessToken, callback) {
    const request = new ValidateTokenRequest();

    request.setAccessToken(accessToken);
    await AuthService.refreshTokens(request, null, callback);
}

export async function RefreshTokens(refreshToken, callback) {
    const request = new RefreshTokensRequest();

    request.setRefreshToken(refreshToken);
    await AuthService.refreshTokens(request, null, callback);
}

export async function Logout(refreshToken, callback) {
    const request = new LogoutRequest();

    request.setRefreshToken(refreshToken);
    await AuthService.logout(request, null, callback);
}

export async function RecoverPassword(mail, callback) {
    const request = new RecoverPasswordRequest();

    request.setEmail(mail);
    await AuthService.recoverPassword(request, null, callback);
}
