import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./VideoChatBodyBlockThemes";
import VideoContent from "./VideoContent/VideoContent";
import VideoChatStore from "store/VideoChatStore";
import VideoManagementPanel from "./VideoManagementPanel/VideoManagementPanel";
import ChatForVideo from "./ChatForVideo/ChatForVideo";

const VideoChatBodyBlock = observer(({ children, ...props }) => {
    return (
        <Box sx={themes.main}>
            <Box sx={VideoChatStore.managementPanel.chatState === true ? themes.videoChatBodyBlockStyleOpenChat : themes.videoChatBodyBlockStyleNoChat}>
                <VideoContent></VideoContent>
                <VideoManagementPanel></VideoManagementPanel>
            </Box>
            {VideoChatStore.managementPanel.chatState === true && (
                <Box sx={themes.videoChatBodyBlockStyleChatBlock}>
                    <Box>
                        <ChatForVideo />
                    </Box>
                </Box>
            )}
        </Box>
    );
});

export default memo(VideoChatBodyBlock);
