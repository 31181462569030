import { createTheme } from "@mui/material/styles";

export const nameBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "8px",
    flex: "none",
    order: 0,
    flexGrow: 0,
    zIndex: 0,
};

export const NameAndActivityTheme = createTheme({
    components: {
        MuiTooltip: {
            styleOverrides: {
                root: {},
                tooltip: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    padding: "10px",
                    boxSizing: "border-box",
                    marginLeft: "6px!important",
                    background: "#FFFFFF",
                    borderRadius: "10px",
                    flex: "none",
                    order: 1,
                    flexGrow: 1,
                    filter: "drop-shadow(0px 2px 10px rgba(106, 99, 118, 0.15))",
                },
                arrow: {
                    color: "white!important",
                    filter: "drop-shadow(0px 2px 10px rgba(106, 99, 118, 0.15))",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                },
            },
        },
    },
});

export const nameStyle = {
    height: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#222222",
};

export const ratingAndReviewsContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    gap: "8px",
    width: "188px",
    height: "26px",
    flex: "none",
    alignSelf: "stretch",
};

export const ratingContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    boxSizing: "border-box",
    padding: "2px 10px 3px",
    gap: "10px",
    width: "43px",
    height: "26px",
    background: "#6212FF",
    borderRadius: "6px",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const ratingStyle = {
    height: "21px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "150%",
    fontFeatureSettings: "tnum' on, 'lnum' on'",
    color: "#FFFFFF",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const reviewsStyle = (anyReviews) => ({
    height: "21px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "150%",
    fontFeatureSettings: "tnum' on, 'lnum' on'",
    color: anyReviews ? "#6212FF" : "var(--text-dark-grey, #67677A)",
    flex: "none",
    order: 1,
    flexGrow: 0,
});
