import React from "react";
import { observer } from "mobx-react-lite";

import { Stack, Typography } from "@mui/material";

import SButton from "components/UI/SButton";
import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";

const StartWork = () => {
    const handleStartWork = () => {
        DiplomaStore.setStatus("working");
    };

    return DiplomaStore.status === "accepted" ? (
        <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}
            marginBottom="10px"
        >
            <SButton style={{ height: "36px" }}>
                <Typography
                    variant="standardWhite"
                    onClick={handleStartWork}
                >
                    Начать работу
                </Typography>
            </SButton>
        </Stack>
    ) : null;
};

export default observer(StartWork);
