import React from "react";

const ChatIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon/24/chat">
                <path
                    id="chat"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.75 8C2.75 6.11561 3.22059 4.84007 4.03033 4.03033C4.84007 3.22059 6.11561 2.75 8 2.75H16C17.8844 2.75 19.1599 3.22059 19.9697 4.03033C20.7794 4.84007 21.25 6.11561 21.25 8V13C21.25 14.8844 20.7794 16.1599 19.9697 16.9697C19.1599 17.7794 17.8844 18.25 16 18.25H15.5C14.9521 18.25 14.4325 18.5124 14.1029 18.9462L14.1 18.95L12.6 20.95C12.3908 21.2289 12.1663 21.31 12 21.31C11.8337 21.31 11.6092 21.2289 11.4 20.95L9.90264 18.9535C9.7297 18.7182 9.48803 18.5515 9.27436 18.4455C9.05796 18.3383 8.78282 18.25 8.5 18.25H8C6.01326 18.25 4.76886 17.9887 3.99388 17.3106C3.24367 16.6541 2.75 15.4353 2.75 13V8ZM8 1.25C5.88439 1.25 4.15993 1.77941 2.96967 2.96967C1.77941 4.15993 1.25 5.88439 1.25 8V13C1.25 15.5647 1.75633 17.3459 3.00612 18.4394C4.23114 19.5113 5.98674 19.75 8 19.75H8.49494C8.49766 19.7504 8.50625 19.7517 8.52072 19.7556C8.54532 19.7622 8.57598 19.7735 8.60814 19.7895C8.64037 19.8054 8.66662 19.8224 8.6847 19.8365C8.69203 19.8422 8.69654 19.8462 8.69876 19.8484L10.2 21.85C10.6508 22.4511 11.2963 22.81 12 22.81C12.7037 22.81 13.3492 22.4511 13.8 21.85L15.2971 19.8538L15.2982 19.8524C15.3486 19.7871 15.4284 19.75 15.5 19.75H16C18.1156 19.75 19.8401 19.2206 21.0303 18.0303C22.2206 16.8401 22.75 15.1156 22.75 13V8C22.75 5.88439 22.2206 4.15993 21.0303 2.96967C19.8401 1.77941 18.1156 1.25 16 1.25H8ZM15.9966 9.75C15.3063 9.75 14.7466 10.3096 14.7466 11C14.7466 11.6904 15.3063 12.25 15.9966 12.25H16.0056C16.696 12.25 17.2556 11.6904 17.2556 11C17.2556 10.3096 16.696 9.75 16.0056 9.75H15.9966ZM10.7455 11C10.7455 10.3096 11.3051 9.75 11.9955 9.75H12.0045C12.6948 9.75 13.2545 10.3096 13.2545 11C13.2545 11.6904 12.6948 12.25 12.0045 12.25H11.9955C11.3051 12.25 10.7455 11.6904 10.7455 11ZM7.99481 9.75C7.30446 9.75 6.74481 10.3096 6.74481 11C6.74481 11.6904 7.30446 12.25 7.99481 12.25H8.00379C8.69415 12.25 9.25379 11.6904 9.25379 11C9.25379 10.3096 8.69415 9.75 8.00379 9.75H7.99481Z"
                    fill="#6212FF"
                />
            </g>
        </svg>
    );
};

export default ChatIcon;
