import { createTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { styleProcessing } from "API/plugins/Utilities";

export const filtersBlockMainRow = (theme) => {
    let commonStyle = {
        position: "relative",
        width: "618px",
        height: "60px",
        borderRadius: "12px",
        marginTop: "40px", // !!!УТОЧНИТЬ!!!
        marginBottom: "16px",
        background: "#6212FF",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, width: "328px", bottom: "-60px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const filtersDropdownsBlock = (theme) => {
    let commonStyle = {
        position: "relative",
        boxSizing: "border-box",
        height: "60px",
        borderRadius: "12px",
        display: "inline-flex",
        width: "495px",
        alignItems: "flex-start",
        gap: "10px",
        borderTop: "1px solid var(--text-grey, #9E9EB0)",
        borderBottom: "1px solid var(--text-grey, #9E9EB0)",
        borderLeft: "1px solid var(--text-grey, #9E9EB0)",
        background: "#F7F7F7",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, width: "328px", top: "-80px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

// export const filtersSearchBtn = {
//     position: 'relative',
//     bottom: '100%',
//     height: 'inherit',
//     left: '80%',
//     //width: '123px',
//     width: '123px',
//     justifyContent: 'center',
//     borderRadius: 12,
//     cursor: 'pointer',
//     fontStyle: 'normal',
//     fontWeight: '500',
//     fontSize: 16,
//     lineHeight: '150%',
//     display: 'flex',
//     alignItems: 'center',
//     color: '#FFFFFF',
// }

export const filtersSearchBtn = (theme) => {
    let commonStyle = {
        position: "relative",
        bottom: "100%",
        height: "inherit",
        left: "80%",
        //width: '123px',
        width: "123px",
        justifyContent: "center",
        borderRadius: 12,
        cursor: "pointer",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: 16,
        lineHeight: "150%",
        display: "flex",
        alignItems: "center",
        color: "#FFFFFF",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, width: "328px", left: "0px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const FiltersSearcherTheme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "--TextField-brandBorderColor": "#9E9EB0",
                    "--TextField-brandBorderHoverColor": "#6212FF",
                    "--TextField-brandBorderFocusedColor": "#6212FF",
                    "& label.Mui-focused": {
                        color: "var(--TextField-brandBorderFocusedColor)",
                    },
                    "& .Mui-focused": {
                        background: "#FFFFFF",
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: "var(--TextField-brandBorderColor)",
                },
                root: {
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: "var(--TextField-brandBorderHoverColor)",
                    },
                    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: "var(--TextField-brandBorderFocusedColor)",
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    "&:before, &:after": {
                        borderBottom: "2px solid var(--TextField-brandBorderColor)",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                    },
                    "&.Mui-focused:after": {
                        borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    "&:before": {
                        borderBottom: "2px solid var(--TextField-brandBorderColor)",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                    },
                    "&.Mui-focused:after": {
                        borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                    },
                },
            },
        },

        MuiFormControl: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    width: 496,
                    height: 60,
                    marginTop: -1,
                    marginLeft: -1,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    height: 60,
                    borderRadius: "12px!important",
                    background: "#F9F9F9",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 14,
                    paddingLeft: "8px!important",
                    lineHeight: "150%",
                    ".MuiInputBase-root": {
                        background: "#6212FF!important",
                    },
                    ".MuiInputAdornment-positionStart": {
                        stroke: "#FFFFFF",
                        strokeWidth: 1,
                        ".MuiIconButton-root:hover": {
                            background: "#ffffff00!important",
                            color: "#6212FF!important",
                        },
                    },
                    ".MuiInputAdornment-positionEnd": {
                        ".MuiIconButton-root": {
                            paddingRight: "0!important",
                            paddingLeft: "0!important",
                            marginRight: "-5px",
                        },
                        ".MuiSvgIcon-root": {
                            width: "16px",
                            height: "16px",
                        },
                        ".MuiIconButton-root:hover": {
                            background: "#ffffff00!important",
                            color: "#6212FF!important",
                        },
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                },
            },
        },
    },
});
