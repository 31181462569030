import React from "react";
import { Dialog, Box, ThemeProvider, IconButton, Stack, Typography } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import SButton from "components/UI/SButton";
import StarsCount from "components/DiplomaChats/RightPanel/EndingState/Student/StarsCount";
import * as themes from "components/OrderBodyBlock/StickyActionBlock/ModalResponseThemes";
import * as styles from "pages/DiplomaChats/styles";
import * as textStyles from "themes/TextThemes";

const SendReviewModal = ({ starsCount, open = false, close }) => {
    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={open}
                onClose={close}
            >
                <Box sx={themes.dialogContainerStyle}>
                    <ThemeProvider theme={themes.CloseIconTheme}>
                        <IconButton onClick={close}>
                            <CloseRounded />
                        </IconButton>
                    </ThemeProvider>
                    <Stack
                        sx={styles.reviewModalContainerStyle}
                        padding="30px 40px 20px 40px"
                        direction="column"
                        spacing={2.5}
                    >
                        <Stack
                            direction="column"
                            justifyContent="center"
                            spacing={0.5}
                        >
                            <span style={styles.avatarContainer}>face</span>
                            <StarsCount starsCount={starsCount} />
                        </Stack>
                        <Typography sx={textStyles.HeadlineH41Black}>Спасибо за оценку!</Typography>
                        <Typography
                            sx={textStyles.Body2Black}
                            textAlign="center"
                        >
                            Мы ценим обратную связь от вас и стараемся стать лучше. Если вам нужна помощь с похожей или другой задачей, создайте заказ и мы быстро подберём специалистов
                        </Typography>
                        {/* TODO: Navigate to /bid */}
                        <SButton variant="arrow">Создать новый заказ</SButton>
                        {/* TODO: Navigate to /classes */}
                        <SButton
                            variant="text"
                            style={{ color: "var(--Text-Dark-Grey, #67677A)" }}
                        >
                            Вернуться в Мои заказы
                        </SButton>
                    </Stack>
                </Box>
            </Dialog>
        </ThemeProvider>
    );
};

export default SendReviewModal;
