export const background = {
    width: "100%",
    background: "var(--bg-dark-grey, #F3F3F3)",
    paddingBottom: "40px",
};

export const container = {
    paddingTop: "17px",
    width: 1216,
    marginRight: "auto",
    marginLeft: "auto",
    background: "var(--bg-dark-grey, #F3F3F3)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
};

export const headContainer = {
    padding: "20px 20px 10px",
    backgroundColor: "white",
    borderRadius: 16,
    marginBottom: "40px",
    width: "100%",
};

export const standardDivCont = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
};

export const tabsContainer = {
    marginBottom: 20,
};

export const avatarContainer = {
    width: "60px",
    height: "60px",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "center",
    border: "1px dashed #DADADF",
    borderRadius: "30px",
};

export const ratingContainer = {
    borderRadius: "6px",
    background: "#6212FF",
    padding: "2px 10px 3px 10px",
    color: "#FFFFFF",
};

export const reviewCont = {
    color: "#6212FF",
    fontVariantNumeric: "lining-nums tabular-nums",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "150%",
};

export const dashedBorder = {
    padding: "10px 0px",
    borderBottom: "1px dashed #DADADF",
    borderTop: "1px dashed #DADADF",
};

export const orderConditionsContainer = {
    padding: "16px",
    borderRadius: "10px",
    background: "rgba(187, 187, 200, 0.15)",
};

export const cashbackInfoCont = {
    padding: "16px",
    borderRadius: "10px",
    border: "1px solid var(--main-gradient, #04C83B)",
    boxShadow: "0px 4px 30px 0px rgba(106, 99, 118, 0.05)",
};

export const verifiedGray = {
    width: "16px",
    height: "18px",
    color: "#BBBBC8",
};

export const verifiedGreen = {
    fontSize: "20px",
    color: "#82D4AE",
};

export const giftContainer = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignContent: "center",
    width: "32px",
    height: "32px",
    borderRadius: "20px",
    background: "var(--main-gradient, linear-gradient(257deg, #04C83B 20.58%, #12AAFF 81.82%))",
};

export const giftContainerSmall = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignContent: "center",
    width: "20px",
    height: "20px",
    borderRadius: "20px",
    background: "var(--main-gradient, linear-gradient(257deg, #04C83B 20.58%, #12AAFF 81.82%))",
};

export const cashbackCount = {
    background: "var(--main-gradient, linear-gradient(257deg, #04C83B 20.58%, #12AAFF 81.82%))",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "150%",
};

export const taskIconBackground = {
    background: "var(--main-purple, #6212FF)",
    borderRadius: "30px",
    width: "36px",
    height: "36px",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "center",
};

export const reviewModalContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
    flexDirection: "column",
    borderRadius: "20px",
    background: "var(--main-white, #FFF)",
    width: 410,
    height: "fit-content",
};

export const gradeContainer = {
    padding: "2px 10px 3px 10px",
    borderRadius: "6px",
    background: "#F1B063",
};

export const gradeStarContainer = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignContent: "center",
    width: "16px",
    height: "16px",
};

export const gradeTypography = {
    color: "var(--text-white, #FFF)",
    fontFamily: "inherit",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "150%",
};

export const containerLayout = {
    borderRadius: "16px",
    background: "#FFF",
};
