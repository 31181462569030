import { memo } from "react";
import * as themes from "./EmptyClassesBlockThemes";
import * as textThemes from "themes/TextThemes";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SButton from "components/UI/SButton";
import { useNavigate } from "react-router-dom";

const EmptyClassesBlock = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const StatusTexts = {
        upcoming: { title: "teacherClasses.upcomingClassesTitle", description: "teacherClasses.upcomingClassesDescription" },
        pending: { title: "teacherClasses.pendingClassesTitle", description: "teacherClasses.pendingClassesDescription" },
        rescheduled: { title: "teacherClasses.rescheduleRequestsTitle", description: "teacherClasses.rescheduleRequestsDescription" },
        completed: { title: "teacherClasses.completedClassesTitle", description: "teacherClasses.completedClassesDescription" },
        cancelled: { title: "teacherClasses.cancelledClassesTitle", description: "teacherClasses.cancelledClassesDescription" },
    };

    const handleClickSearch = () => {
        navigate("/orders-search");
    };

    return (
        <Box sx={themes.EmptyClassesBlock}>
            <Box sx={themes.TextBlock}>
                <Typography sx={textThemes.HeadlineH41Black}>{t(StatusTexts[props.status].title)}</Typography>
                <Box sx={themes.DescriptionBlock}>
                    <Typography sx={textThemes.Body1Black}>{t(StatusTexts[props.status].description)}</Typography>
                </Box>
            </Box>
            {props.status === "upcoming" ? (
                <SButton
                    style={themes.Button}
                    variant="arrow"
                    padding="11px 24px 12px 24px"
                    onClick={handleClickSearch}
                >
                    {t("teacherClasses.ordersSearch")}
                </SButton>
            ) : null}
        </Box>
    );
};

export default memo(EmptyClassesBlock);
