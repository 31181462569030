import React, { useState } from "react";
import { StyledOrderResponseInfoCard, StyledOrderResponseTitle, StyledOrderResponseTopWrapper, StyledOrderResponseBottomWrapper } from "./OrderResponseInfoStyled";
import OrderResponse from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/OrderResponse/OrderResponse";
import { Divider } from "@mui/material";
import OrederResponseInfoDetails from "./OrederResponseInfoDetails/OrederResponseInfoDetails";
import OrderSwitch from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/OrderSwitch/OrderSwitch";
import { useLocation } from "react-router-dom";
import OrderOpenOrderModal from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/OrderSwitch/OpenOrderModal/OpenOrderModal";
import PersonOrder from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/PersonOrder/PersonOrder";
import { OrderRespInfoDivider } from "./OrederResponseInfoDetails/OrederResponseInfoDetailsStyled";

const OrderResponseInfo = () => {
    const location = useLocation();

    const { target, title, personal } = location.state;

    const [switchResp, setSwitchResp] = useState(personal);

    const [open, setOpen] = useState(false);

    const [isPersonal, setIsPersonal] = useState(true);

    const switchToggle = (event) => {
        event.stopPropagation();
        if (isPersonal && personal) {
            setOpen(!open);
        } else {
            setSwitchResp(!switchResp);
        }
    };

    const handleModalToggle = () => {
        setOpen(!open);
    };

    return (
        <>
            <StyledOrderResponseInfoCard>
                <StyledOrderResponseTopWrapper>
                    <StyledOrderResponseTitle>
                        {title} • {target}
                    </StyledOrderResponseTitle>
                    {personal && switchResp && !isPersonal ? null : personal && switchResp && isPersonal ? (
                        <PersonOrder />
                    ) : !personal && switchResp ? null : personal && !switchResp && !isPersonal ? (
                        <OrderResponse />
                    ) : !personal && !switchResp ? (
                        <OrderResponse />
                    ) : null}
                </StyledOrderResponseTopWrapper>
                <Divider sx={OrderRespInfoDivider} />
                <StyledOrderResponseBottomWrapper>
                    <OrderSwitch
                        onClick={switchToggle}
                        switchResp={switchResp}
                        title={title}
                    />
                    <OrederResponseInfoDetails />
                </StyledOrderResponseBottomWrapper>
                <OrderOpenOrderModal
                    open={open}
                    handleModalToggle={handleModalToggle}
                    setSwitchResp={setSwitchResp}
                    switchResp={switchResp}
                    isPersonal={isPersonal}
                    setIsPersonal={setIsPersonal}
                />
            </StyledOrderResponseInfoCard>
        </>
    );
};

export default OrderResponseInfo;
