import { makeAutoObservable } from "mobx";
import { findChatById, get } from "components/ChatBodyBlock/CommonData/helper_functions";

class DiplomaStore {
    drawerOpened = false;
    stars = 5;
    comment = null;
    status = "accepted";
    tutorProgress = [
        { id: 1, done: false, text: "Введение" },
        { id: 2, done: false, text: "Обзор литературы и формирование гипотез" },
        { id: 3, done: false, text: "Исследование" },
        { id: 4, done: false, text: "Заключение и выводы" },
    ];
    id = "";

    participantId = "";

    tabsData = {};

    lastMessage = "";
    noScroll = false;
    selectedMessage = "";

    constructor() {
        makeAutoObservable(this);
    }

    setTutorProgress = (value) => {
        this.tutorProgress = this.tutorProgress.map((item) => (item.id === value.id ? { ...item, done: !item.done } : item));
    };

    setStatus = (value) => {
        this.status = value;
    };

    ToggleDrawer() {
        console.log(this.drawerOpened);
        this.drawerOpened = !this.drawerOpened;
    }

    SetStars = (value) => {
        this.stars = value;
    };
    SetComment = (value) => {
        this.comment = value;
    };

    SetTabsData(value) {
        this.tabsData = value;
    }

    SetChatID(value) {
        this.id = value;
    }

    SetParticipantID(value) {
        this.participantId = value;
    }

    SendIsWrite(sendJsonMessage, isWrite) {
        sendJsonMessage({
            action: "typing_message",
            chat_id: this.id,
            typing: isWrite,
        });
    }

    DeleteMessageAction(value, callback, all) {
        callback({
            action: "delete_message",
            chat_id: value.chat_id,
            message_id: value.message_id,
            for_all: all,
        });
    }

    SetSelectedMessage(value) {
        this.selectedMessage = value;
    }

    GetForScrollMessagesNoLimit(sendJsonMessage) {
        if (this.tabsData.messages.length > 0) {
            this.SetLastMessage(this.tabsData.messages[this.tabsData.messages.length - 1].message_id);
            sendJsonMessage({
                action: "get_fix_messages",
                chat_id: this.id,
                start: this.tabsData.messages[this.tabsData.messages.length - 1].message_id,
            });
        } else this.SetNoScroll(false);
    }

    SetLastMessage(value) {
        this.lastMessage = value;
    }

    SetNoScroll(value) {
        this.noScroll = value;
    }

    AddMessagesToChat(data) {
        if (data.messages.length > 0) {
            data.messages.map((item) => this.tabsData.messages.push(item));
        } else this.SetNoScroll(false);
    }

    newLoadedMessages = [];
    SetNewLoadedMessages = (messages) => {
        this.newLoadedMessages = messages;
    };

    ProcessMessages(sendMessage, sendJsonMessage, lastMessage, lastJsonMessage) {
        if (!lastMessage) {
            get("token").then((token) =>
                sendJsonMessage({
                    id: 1,
                    token: token,
                }),
            );
        } else {
            if ("status" in lastJsonMessage) {
                if (lastJsonMessage?.status !== "Ok") {
                    console.log("invalid token");
                    return;
                } else {
                    sendJsonMessage({
                        action: "get_data",
                        order_uuid: "1659250f-ff8c-4913-ae19-25ce1e0cc9e8",
                    });
                }
            }

            if (lastJsonMessage?.meta_info?.action === "get_links") {
                this.tabsData.chats.forEach((chat, i) => {
                    if (chat.chat_id === this.id) {
                        this.tabsData.chats[i].attachments = lastJsonMessage.data;
                        this.SetSelectedChatAttachments(lastJsonMessage.data);
                    }
                });
            }

            if ("meta_info" in lastJsonMessage) {
                let reaction = null;

                let message_id = null;

                let chat_id = null;

                let uuid = null;

                let editedMessage = null;

                let edited = null;

                switch (lastJsonMessage.meta_info.action) {
                    case "get_data":
                        this.SetTabsData(lastJsonMessage.data);

                        if (this.id === "") this.SetChatID(lastJsonMessage.data.chat_id);

                        if (this.participantId === "") this.SetParticipantID(lastJsonMessage.data.participants_info[0].uuid);

                        break;
                    case "reaction_to_message":
                        reaction = lastJsonMessage.data.reaction;

                        message_id = lastJsonMessage.data.message_id;

                        uuid = lastJsonMessage.data.uuid;

                        this.tabsData.messages = this.tabsData.messages.map((message) =>
                            message.message_id === message_id
                                ? {
                                      ...message,
                                      reactions: /*message.reactions.length ? [...message.reactions, { reaction: reaction, uuid: uuid }] : */ [{ reaction: reaction, uuid: uuid }],
                                  }
                                : message,
                        );
                        break;
                    case "cancel_reaction_to_message":
                        message_id = lastJsonMessage.data.message_id;

                        chat_id = lastJsonMessage.data.chat_id;

                        this.tabsData.chats = this.tabsData.chats.map((chat) =>
                            chat.chat_id === chat_id
                                ? {
                                      ...chat,
                                      messages: chat.messages.map((message) =>
                                          message.message_id === message_id
                                              ? {
                                                    ...message,
                                                    reactions: [],
                                                }
                                              : message,
                                      ),
                                  }
                                : chat,
                        );
                        break;
                    case "send_message":
                        chat_id = lastJsonMessage.data.chat_id;

                        this.tabsData.messages = [lastJsonMessage.data, ...this.tabsData.messages];
                        break;
                    case "edit_message":
                        console.log(lastJsonMessage.data);

                        chat_id = lastJsonMessage.data.chat_id;

                        message_id = lastJsonMessage.data.message_id;

                        editedMessage = lastJsonMessage.data.message;

                        edited = lastJsonMessage.data.edited;

                        this.tabsData.messages = this.tabsData.messages.map((message) => (message.message_id === message_id ? { ...message, message: editedMessage, edited: edited } : message));
                        break;
                    case "delete_message":
                        chat_id = lastJsonMessage.data.chat_id;

                        message_id = lastJsonMessage.data.message_id;

                        this.tabsData.messages = this.tabsData.messages.filter((message) => message.message_id !== message_id);
                        break;
                    case "updated_data":
                        this.SetTabsData(lastJsonMessage.data);
                        break;
                    case "search_message":
                        this.SetFoundMessages(lastJsonMessage.data);
                        this.GetForScrollMessages(sendJsonMessage);
                        break;
                    case "scroll_to_message":
                        this.AddMessagesToChat(lastJsonMessage.data);
                        break;
                    case "typing_message":
                        if (lastJsonMessage.typing_message.user_id === this.participantId) {
                            this.SetIsWrite(lastJsonMessage.typing_message.typing);
                        }

                        break;
                    case "get_fix_messages":
                        this.AddMessagesToChat(lastJsonMessage.data);
                        this.SetNewLoadedMessages(lastJsonMessage.data.messages);
                        break;
                    default:
                        break;
                }
            }
        }
    }
}

export default new DiplomaStore();
