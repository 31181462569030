import React, { memo } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { CloseSharp } from "@mui/icons-material";
import * as themes from "./SingleChoiceLightFilterThemesDefault";
import Box from "@mui/material/Box";

const SingleChoiceLightFilter = ({ children, ...props }) => {
    const processing = (event, value) => {
        props.processingChange(event, value);
    };

    const processingInput = (e) => {
        props.processingInput(e.target.value);
    };

    const clear = (event, value) => {
        props.processingChange(event, props?.freeSolo || typeof props?.freeSolo === "undefined" ? "" : null);
    };

    return (
        <ThemeProvider theme={Object.assign({}, themes.themeWrapper(props?.error), typeof props?.themeWrapper === "function" ? props?.themeWrapper(props?.error) : props?.themeWrapper)}>
            <Autocomplete
                value={props.propOption}
                onChange={processing}
                onInput={props.processingInput ? processingInput : undefined}
                filterOptions={props.processingInput ? (x) => x : undefined}
                size="small"
                clearText={"Очистить"}
                closeText={"Закрыть"}
                openText={"Открыть"}
                disableClearable={typeof props?.disableClearable !== "undefined" ? props.disableClearable : false}
                freeSolo={typeof props?.freeSolo !== "undefined" ? props.freeSolo : true}
                onBlur={props?.clearOnBlur === true || typeof props?.clearOnBlur === "undefined" ? clear : null}
                options={props.propOptions}
                clearIcon={props.propOption === "" ? "" : <CloseSharp fontSize={"small"} />}
                noOptionsText={"Вариантов не найдено"}
                isOptionEqualToValue={(value1, value2) => value1[props.valueToBind] === value2[props.valueToBind]}
                getOptionLabel={(option) => (option[props.valueToShow] ? option[props.valueToShow] : "")}
                renderOption={(propsR, option, { inputValue, selected }) => {
                    const matches = match(option[props.valueToShow], inputValue, { insideWords: true });

                    const parts = parse(option[props.valueToShow], matches);

                    return (
                        <li
                            {...propsR}
                            style={{ backgroundColor: "white" }}
                        >
                            <Box>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            color: part.highlight ? (selected ? "" : "#6212FF") : "",
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            </Box>
                        </li>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant={props.variant}
                        placeholder={props.propOption?.length > 0 ? "" : props?.placeholder ? props.placeholder : "Выберите вариант"}
                    />
                )}
            />
        </ThemeProvider>
    );
};

export default memo(SingleChoiceLightFilter);
