const ExclamationIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
        >
            <path
                d="M9.00582 0.164062C4.40734 0.164062 0.667969 3.90344 0.667969 8.4974C0.667969 13.0914 4.40734 16.8307 9.0013 16.8307C13.5953 16.8307 17.3346 13.0914 17.3346 8.4974C17.3346 3.90344 13.5998 0.164062 9.00582 0.164062ZM9.00582 14.588C8.41801 14.588 7.93872 14.1087 7.93872 13.5209C7.93872 12.9331 8.41801 12.4538 9.00582 12.4538C9.59363 12.4538 10.0729 12.9331 10.0729 13.5209C10.0729 14.1087 9.59363 14.588 9.00582 14.588ZM10.5341 5.02615L9.5665 10.713C9.52129 11.025 9.28164 11.2511 9.0013 11.2511C8.72096 11.2511 8.48132 11.025 8.4361 10.713L7.46848 5.02615C7.33464 3.91406 7.53178 3.20259 7.83473 2.8499C8.07889 2.56504 8.40897 2.40678 8.75714 2.40678H9.24095C9.58911 2.40678 9.91919 2.56504 10.1634 2.8499C10.4708 3.20259 10.668 3.91406 10.5341 5.02615Z"
                fill="#FF9212"
            />
        </svg>
    );
};

export default ExclamationIcon;
