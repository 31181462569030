const NoChatSelected = () => {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1747_57080)">
                <path
                    d="M49.3999 18V44.3372C49.3999 45.688 48.8201 46.9044 47.8939 47.7561C47.0539 48.5281 45.9299 49 44.695 49H5.10396C3.86896 49 2.74509 48.5281 1.90615 47.7569C0.979738 46.9044 0.399902 45.688 0.399902 44.3372V18H49.3999Z"
                    fill="#6212FF"
                />
                <path
                    d="M49.0665 19.0219C49.5516 18.6258 49.5575 17.8865 49.0788 17.4828L30.7813 2.05103C27.4316 -0.683678 22.5684 -0.683678 19.2187 2.05103L0.921288 17.4827C0.442581 17.8865 0.448515 18.6258 0.933641 19.0218L5.66471 22.8837L19.2187 33.9484C22.5688 36.6839 27.432 36.6839 30.7809 33.9484L44.3362 22.8837L49.0665 19.0219Z"
                    fill="#4D09D3"
                />
                <path
                    d="M44 8.61173V23.4757L30.6801 34.8846C27.3893 37.7051 22.6106 37.7051 19.3187 34.8846L6 23.4757V8.61173C6 5.51272 8.44248 3 11.4554 3H38.5454C41.5583 3 43.9999 5.51272 43.9999 8.61173H44Z"
                    fill="#E3E3E3"
                />
                <path
                    d="M44 8.68588V20.2967L30.6801 31.857C27.3893 34.7143 22.6106 34.7143 19.3187 31.857L6 20.2967V8.68588C6 5.54592 8.44248 3 11.4554 3H38.5454C41.5583 3 43.9999 5.54592 43.9999 8.68588H44Z"
                    fill="#F5F5F7"
                />
                <path
                    d="M35.7716 22.0878H14.2234C14.1269 22.0879 14.0314 22.0689 13.9423 22.0319C13.8531 21.995 13.7721 21.9409 13.7039 21.8727C13.6357 21.8045 13.5816 21.7235 13.5447 21.6344C13.5078 21.5452 13.4888 21.4497 13.4888 21.3532C13.4888 21.1584 13.5662 20.9716 13.704 20.8338C13.8417 20.6961 14.0286 20.6186 14.2234 20.6186H35.7716C35.9664 20.6186 36.1532 20.6961 36.291 20.8338C36.4287 20.9716 36.5061 21.1584 36.5062 21.3532C36.5062 21.4497 36.4872 21.5452 36.4503 21.6344C36.4134 21.7235 36.3593 21.8045 36.291 21.8727C36.2228 21.9409 36.1418 21.995 36.0527 22.0319C35.9636 22.0689 35.868 22.0879 35.7716 22.0878ZM35.7716 26.3546H14.2234C13.8176 26.3546 13.4888 26.0257 13.4888 25.62C13.4888 25.2142 13.8176 24.8853 14.2234 24.8853H35.7716C36.1774 24.8853 36.5062 25.2142 36.5062 25.62C36.5062 26.0257 36.1774 26.3546 35.7716 26.3546ZM35.7716 13.5552H14.2234C13.8176 13.5552 13.4888 13.2263 13.4888 12.8206C13.4888 12.4148 13.8176 12.0859 14.2234 12.0859H35.7716C36.1774 12.0859 36.5062 12.4148 36.5062 12.8206C36.5062 13.2263 36.1774 13.5552 35.7716 13.5552ZM35.7716 17.8216H14.2234C13.8176 17.8216 13.4888 17.4927 13.4888 17.0869C13.4888 16.6812 13.8176 16.3523 14.2234 16.3523H35.7716C36.1774 16.3523 36.5062 16.6811 36.5062 17.0869C36.5062 17.4928 36.1774 17.8216 35.7716 17.8216Z"
                    fill="#6212FF"
                />
            </g>
            <defs>
                <clipPath id="clip0_1747_57080">
                    <rect
                        width="50"
                        height="50"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default NoChatSelected;
