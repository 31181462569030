import { Box, Typography } from "@mui/material";
import React from "react";
import * as textThemes from "themes/TextThemes";
import * as themes from "./TargetBoxRoundedThemes";
import { memo } from "react";

const TargetBoxRounded = ({ children, ...props }) => {
    return (
        <Box sx={themes.TargetBoxRounded}>
            <Typography sx={textThemes.Body2Black}>{children}</Typography>
        </Box>
    );
};

export default memo(TargetBoxRounded);
