import { createTheme } from "@mui/material/styles";

export const stickyFiltersBlockContainerStyle = {
    position: "sticky",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    width: "289px",
    top: 0,
    height: "fit-content",
};

export const filtersContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    background: "#FFF",
    width: 289,
    borderRadius: "20px",
    boxSizing: "border-box",
    paddingTop: "24px",
    paddingLeft: "20px",
    paddingRight: "20px",
};

export const headerContainerStyle = {
    display: "flex",
    alignItems: "center",
    color: "#9E9EB0",
};

export const textStyle = {
    position: "relative",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "150%",
    color: "#222222",
};

export const SearchButtonTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "11px 24px 12px",
                    marginBottom: "20px",
                    gap: "10px",
                    width: "249px",
                    minHeight: "47px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "20px",
                    background: "#6212FF",
                    borderRadius: "42px!important",
                    "&:hover": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                        ".MuiTypography-root": {
                            color: "white!important",
                        },
                    },
                    "&:active": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                        ".MuiTypography-root": {
                            color: "white!important",
                        },
                    },
                },
            },
        },
    },
});

export const searchTextStyle = {
    width: "239px",
    height: "24px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    color: "#FFFFFF",
    flex: "none",
    order: 0,
    flexGrow: 0,
};
