export const CashbackDefBlock = {
    width: "1216px",
    height: "376px",
    marginTop: "140px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    position: "relative",
};

export const CashbackDefHeader = {
    maxWidth: "495px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const CashbackDefFuncStar = {
    position: "absolute",
    left: "618px",
};

export const CashbackDefFuncSkillLevel = {
    position: "absolute",
    left: "927px",
};

export const CashbackDefFuncHandCoins = {
    position: "absolute",
    top: "195px",
    left: "515px",
};

export const CashbackDefFuncCoins = {
    position: "absolute",
    top: "195px",
    left: "824px",
};
