export const timeStyle = {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "21px",
    letterSpacing: "0em",
    textAlign: "left",
};

export const timeZoneStyle = {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0em",
    textAlign: "left",
    marginLeft: "4px",
};
