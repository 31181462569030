import { memo } from "react";
import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as textStyles from "themes/TextThemes";
import SButton from "components/UI/SButton";
import * as styles from "./ModalClassCanceledThemes";
import { observer } from "mobx-react-lite";
import { useMedias } from "API/mediasHook";

const ModalClassCanceled = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    return (
        <ModalSettingsSmall
            visible={props.open}
            handleClose={props.handleDiscard}
        >
            <Box sx={styles.ModalClassCanceled(medias)}>
                <Box sx={styles.Container(medias)}>
                    <Typography sx={medias.sm ? textStyles.HeadlineH41Black : textStyles.HeadlineH5Black}>{t("main.myClasses.classCancelled")}</Typography>
                    <Typography sx={textStyles.Body2Black}>{t("main.myClasses.ifYouNeedHelp")}</Typography>
                </Box>
                <Box sx={styles.Container}>
                    <SButton
                        variant="arrow"
                        padding="10px 20px 11px 20px"
                        onClick={() => navigate("/bid")}
                    >
                        {t("main.createNewOrder")}
                    </SButton>
                    <Typography
                        sx={textStyles.Body2DarkGrey}
                        onClick={props.handleDiscard}
                        style={styles.TextButton}
                    >
                        {t("main.myClasses.returnToMyClasses")}
                    </Typography>
                </Box>
            </Box>
        </ModalSettingsSmall>
    );
});

export default memo(ModalClassCanceled);
