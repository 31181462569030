import React from "react";

const IconComp = () => {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_282_21142)">
                <circle
                    cx="18"
                    cy="18"
                    r="18"
                    fill="#6212FF"
                />
                <path
                    d="M22.8001 26.9505C22.8001 27.308 22.5076 27.6005 22.1501 27.6005H14.35C13.9925 27.6005 13.7 27.308 13.7 26.9505C13.7 26.593 13.9925 26.3005 14.35 26.3005H16.3V25.0005H20.2V26.3005H22.1501C22.5076 26.3005 22.8001 26.593 22.8001 26.9505ZM18.25 17.2004C18.1811 17.2004 18.1109 17.1894 18.0446 17.1673L15.65 16.3691V18.9528C15.8509 19.1901 16.5314 19.8004 18.25 19.8004C19.9563 19.8004 20.6401 19.1985 20.85 18.9489V16.3684L18.4554 17.1666C18.3891 17.1894 18.3189 17.2004 18.25 17.2004ZM14.4553 14.6004L15.2516 14.8656L15.2542 14.8663L18.25 15.8653L21.2459 14.8663L21.2485 14.8656L22.0447 14.6004L18.25 13.3355L14.4553 14.6004ZM28.0001 11.3504V21.7504C28.0001 22.8255 27.1252 23.7004 26.0501 23.7004H10.45C9.3749 23.7004 8.5 22.8255 8.5 21.7504V11.3504C8.5 10.2753 9.3749 9.40039 10.45 9.40039H26.0501C27.1252 9.40039 28.0001 10.2753 28.0001 11.3504ZM24.7501 14.6004C24.7501 14.3203 24.5713 14.072 24.3055 13.9836L18.4554 12.0336C18.3222 11.9894 18.1772 11.9894 18.044 12.0336L12.194 13.9836C11.9288 14.0726 11.75 14.3203 11.75 14.6004C11.75 14.8806 11.9288 15.1289 12.1946 15.2173L14.35 15.9355V19.1504C14.35 19.2512 14.3734 19.3513 14.4183 19.441C14.5034 19.6106 15.3328 21.1004 18.25 21.1004C21.1672 21.1004 21.9966 19.6106 22.0818 19.441C22.1266 19.3513 22.1501 19.2512 22.1501 19.1504V15.9355L23.4501 15.502V18.5004C23.4501 18.8592 23.7406 19.1504 24.1001 19.1504C24.4595 19.1504 24.7501 18.8592 24.7501 18.5004V14.6004Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_282_21142">
                    <rect
                        width="36"
                        height="36"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconComp;
