export const EditPaper = (width) => {
    return {
        borderRadius: "10px",
        width: width,
        border: "none",
        boxShadow: "none",
        p: 0,
        mt: "10px",
    };
};

export const EditButton = (openPopper, width, medias) => {
    return {
        maxWidth: "641px",
        width: medias.sm ? width : "100%",
        height: "34px",
        borderBottom: openPopper ? "2px solid #6212FF" : "1px solid #BBBBC8",
        borderRadius: 0,
        textTransform: "none",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "150%",
        color: "#222",
        background: "#fff",
        ":hover": {
            color: "#222",
            background: "#fff",
        },
        pb: "10px",
        pl: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    };
};

export const EditContentListItem = (condition) => {
    return {
        color: "#222",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "140%" /* 21px */,
        borderRadius: "5px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "7px 10px 6px 10px",
        ":hover": {
            color: "#6212FF",
            background: "rgba(187, 187, 200, 0.20)",
        },
    };
};

export const EditContentPaper = {
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
    borderRadius: "0 0 10px 10px",
    padding: "3px 5px 12px 5px",
};

export const EditContentList = {
    p: 0,
    m: 0,
    border: "none",
    maxHeight: "200px",
    position: "relative",
    overflow: "auto",
    "&::-webkit-scrollbar": {
        width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        background: "rgba(187, 187, 200, 0.50)",
        height: "60px",
    },
};
