import React, { memo } from "react";
import * as themes from "./CommentItemThemes";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import DocIconSVG from "assets/CommonAssets/SVG/docIconSVG";

const CommentItem = observer(({ children, ...props }) => {
    return (
        <Box>
            <Typography sx={themes.labelStyle}>{props.label}</Typography>
            <Box sx={themes.infoAreaStyle}>
                <Box sx={themes.infoContentStyle}>
                    <Typography sx={themes.infoStyle}>{props.info}</Typography>
                    <Box sx={themes.attachmentsContainerStyle}>
                        {props.attachments.map((file) => (
                            <Box
                                sx={themes.fileContainerStyle}
                                key={file.ID}
                            >
                                <DocIconSVG></DocIconSVG>
                                <Box sx={themes.fileInfoContainerStyle}>
                                    <Typography sx={themes.fileNameStyle}>{file.FileName}</Typography>
                                    <Box sx={themes.fileActionsContainerStyle}>
                                        <Typography sx={themes.fileSizeStyle}>{file.FileSize}</Typography>
                                        <a
                                            style={themes.fileDownloadStyle}
                                            href={file.FileSource}
                                            download={file.FileName}
                                            target={"_blank"}
                                            rel="noreferrer"
                                        >
                                            Скачать
                                        </a>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
});

export default memo(CommentItem);
