import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./ManageVideoThemes";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import VideoChatStore from "store/VideoChatStore";
import VideoOnIcon from "assets/VideoChatAssets/SVG/videoOnIcon";
import VideoOffIcon from "assets/VideoChatAssets/SVG/videoOffIcon";

const ManageVideo = observer(({ children, ...props }) => {
    const toggleVideoState = () => {
        VideoChatStore.SetVideoState(!VideoChatStore.managementPanel.videoState);
    };

    return (
        <Box
            sx={themes.iconContainerStyle(VideoChatStore.managementPanel.videoState)}
            onClick={toggleVideoState}
        >
            <ThemeProvider theme={themes.ActionButtonTheme(0, VideoChatStore.managementPanel.videoState)}>
                <Tooltip
                    title={<Typography sx={themes.tooltipStyle}>{VideoChatStore.managementPanel.videoState ? "Отключить" : "Включить"} видео</Typography>}
                    placement={"top"}
                >
                    <IconButton disableRipple>{VideoChatStore.managementPanel.videoState ? <VideoOnIcon /> : <VideoOffIcon />}</IconButton>
                </Tooltip>
            </ThemeProvider>
        </Box>
    );
});

export default memo(ManageVideo);
