export const imgbronzelvl = {
    left: 139,
    top: 0,
    position: "absolute",
};

export const imggoldlvl = {
    left: 0,
    top: 97,
    position: "absolute",
};

export const imgplatinumlvl = {
    left: 184,
    top: 128,
    position: "absolute",
};
