import { Box, Button, Typography } from "@mui/material";
import React, { memo } from "react";
import * as themes from "components/SettingsBodyBlock/TutorSettings/MainForm/MainFormThemes";
import SButton from "components/UI/SButton";
import STextField from "components/UI/STextField";
import SDropDown from "components/UI/SDropDown";
import IOSSwitch from "components/UI/IOSSwitch";
import FormControlLabel from "@mui/material/FormControlLabel";
import SDragAndDrop from "components/TeacherQuestionnaireBodyBlock/Components/SDragNDrop";
import DeleteStatementIcon from "assets/TeacherQuestionnaireAssets/DeleteStatementIcon";
import STooltip from "components/TeacherQuestionnaireBodyBlock/Components/STooltip";
import base_store from "store/TeacherQuestionnaireStore";
import { observer } from "mobx-react-lite";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { useTranslation } from "react-i18next";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import IconBackRounded from "assets/CommonAssets/SVG/IconBackRounded";
import * as textThemes from "themes/TextThemes";
import { ItemWithSwitcher } from "components/SettingsBodyBlock/TutorSettings/MainForm/MainFormThemes";
import { useMedias } from "API/mediasHook";

const CertificatesEdit = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const handleBackClick = () => {
        TeacherQuestionnaireStore.setTemporaryCertificates(JSON.parse(JSON.stringify(TeacherQuestionnaireStore.teacher.certificates)));
        props.handleSet(0);
    };

    const handleSaveClick = () => {
        TeacherQuestionnaireStore.setTutorData("certificates", JSON.parse(JSON.stringify(TeacherQuestionnaireStore.temporaryCertificates)));
    };

    const handleCancelClick = () => {
        TeacherQuestionnaireStore.setTemporaryCertificates(JSON.parse(JSON.stringify(TeacherQuestionnaireStore.teacher.certificates)));
    };

    return (
        <ScrollToTop>
            <Box style={themes.Top(medias)}>
                <Box sx={themes.EditTitle(medias)}>
                    <SButton
                        variant="icon"
                        onClick={handleBackClick}
                    >
                        <IconBackRounded />
                    </SButton>
                    <Typography sx={medias.sm ? textThemes.HeadlineH41Black : textThemes.HeadlineH5Black}>{t("teacherSettings.questionnaire.certificates")}</Typography>
                </Box>
                <Box>
                    <Box sx={themes.AdditionalBlocks}>
                        {TeacherQuestionnaireStore.temporaryCertificates.map((certificateExp, index) => {
                            return (
                                <Box
                                    key={index}
                                    sx={themes.BlockWithRemove}
                                >
                                    <Box sx={themes.FormBlockFixWidth}>
                                        <Box sx={themes.BottomBlock}>
                                            <Box sx={themes.item(medias)}>
                                                <Typography sx={textThemes.Body2Black}>{t("teacherSettings.questionnaire.certificateName")}</Typography>
                                                <STextField
                                                    width={medias.sm ? "424px" : "100%"}
                                                    placeholder={t("teacherSettings.questionnaire.enterCertificateName")}
                                                    value={TeacherQuestionnaireStore.temporaryCertificates[index].certificate}
                                                    changeFunction={(value) => {
                                                        TeacherQuestionnaireStore.setTemporaryCertificatesData(index, "certificate", value);
                                                    }}
                                                />
                                            </Box>
                                            <Box sx={themes.item(medias)}>
                                                <Typography sx={textThemes.Body2Black}>{t("teacherSettings.questionnaire.yearReceipt")}</Typography>
                                                <SDropDown
                                                    firstValueGrey={false}
                                                    dropDownChoice={TeacherQuestionnaireStore.temporaryCertificates[index].receiptYear}
                                                    setDropDownChoice={(value) => {
                                                        TeacherQuestionnaireStore.setTemporaryCertificatesData(index, "receiptYear", value);
                                                    }}
                                                    dropDownList={base_store.yearsList}
                                                    buttonWidth={medias.sm ? "424px" : "100%"}
                                                    paperWidth={medias.sm ? "424px" : "100%"}
                                                    withOutWidth={true}
                                                />
                                            </Box>
                                            <Box sx={themes.itemFlexStart(medias)}>
                                                <Box sx={themes.PaddingBlock}>
                                                    <Typography sx={textThemes.Body2Black}>{t("teacherSettings.questionnaire.documentPhoto")}</Typography>
                                                </Box>
                                                <Box sx={ItemWithSwitcher(medias)}>
                                                    <SDragAndDrop
                                                        image={TeacherQuestionnaireStore.temporaryCertificates[index].photo.imageSrc}
                                                        setImage={(value) => {
                                                            TeacherQuestionnaireStore.setTemporaryCertificatesData(index, "photo", value, "imageSrc");
                                                        }}
                                                        imageName={TeacherQuestionnaireStore.temporaryCertificates[index].photo.imageName}
                                                        setImageName={(value) => TeacherQuestionnaireStore.setTemporaryCertificatesData(index, "photo", value, "imageName")}
                                                        imageSize={TeacherQuestionnaireStore.temporaryCertificates[index].photo.imageSize}
                                                        setImageSize={(value) => TeacherQuestionnaireStore.setTemporaryCertificatesData(index, "photo", value, "imageSize")}
                                                    />

                                                    <Box sx={themes.SwitcherBlock(medias)}>
                                                        <FormControlLabel
                                                            sx={{ alignItems: "flex-start", m: 0 }}
                                                            control={
                                                                <IOSSwitch
                                                                    sx={{ m: 1 }}
                                                                    onChange={() => {
                                                                        TeacherQuestionnaireStore.setTemporaryCertificatesData(
                                                                            index,
                                                                            "showToStudents",
                                                                            !TeacherQuestionnaireStore.temporaryCertificates[index].showToStudents,
                                                                        );
                                                                    }}
                                                                    checked={TeacherQuestionnaireStore.temporaryCertificates[index].showToStudents}
                                                                />
                                                            }
                                                        />
                                                        <Typography sx={textThemes.Body2Black}>{t("teacherSettings.questionnaire.showStudents")}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        {!medias.sm ? (
                                            <>
                                                {TeacherQuestionnaireStore.temporaryCertificates.length > 1 && (
                                                    <Button
                                                        sx={themes.TextButtonDelete}
                                                        disableRipple
                                                        variant="text"
                                                        onClick={() => {
                                                            TeacherQuestionnaireStore.removeCertificates(index);
                                                        }}
                                                    >
                                                        {t("main.delete")}
                                                    </Button>
                                                )}
                                            </>
                                        ) : null}
                                        <Box>
                                            {TeacherQuestionnaireStore.temporaryCertificates.length > 1 && index + 1 !== TeacherQuestionnaireStore.temporaryCertificates.length ? (
                                                <hr style={themes.Separator} />
                                            ) : null}
                                        </Box>
                                    </Box>

                                    {medias.sm && (
                                        <>
                                            {TeacherQuestionnaireStore.temporaryCertificates.length > 1 ? (
                                                <STooltip title={t("main.delete")}>
                                                    <Box
                                                        sx={themes.RemoveButton}
                                                        onClick={() => {
                                                            TeacherQuestionnaireStore.removeCertificates(index);
                                                        }}
                                                    >
                                                        <DeleteStatementIcon />
                                                    </Box>
                                                </STooltip>
                                            ) : null}
                                        </>
                                    )}
                                </Box>
                            );
                        })}
                    </Box>
                    <Box>
                        <button
                            style={themes.TeacherQuestionnaireAddFieldsButton}
                            onClick={() => {
                                TeacherQuestionnaireStore.addCertificates({
                                    id: TeacherQuestionnaireStore.temporaryCertificates.length + 1,
                                    certificate: "",
                                    receiptYear: "",
                                    photo: { imageSize: "", imageName: "", imageSrc: "" },
                                    showToStudents: "",
                                });
                            }}
                        >
                            {t("teacherSettings.questionnaire.addCertificate")}
                        </button>
                    </Box>
                </Box>
                <Box sx={themes.ButtonsBlock}>
                    <SButton
                        variant="unshadowed"
                        padding="10px 20px 11px 20px"
                        disabled={TeacherQuestionnaireStore.getDisabledSaveCertificates()}
                        onClick={handleSaveClick}
                    >
                        {t("main.save")}
                    </SButton>
                    <SButton
                        variant="secondary"
                        padding="10px 20px 11px 20px"
                        disabled={TeacherQuestionnaireStore.getDisabledSaveCertificates()}
                        onClick={handleCancelClick}
                    >
                        {t("main.cancel")}
                    </SButton>
                </Box>
            </Box>
        </ScrollToTop>
    );
});

export default memo(CertificatesEdit);
