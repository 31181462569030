import EditBirthDay from "./EditBirthDate/EditBirthDay";
import EditBirthMonth from "./EditBirthDate//EditBirthMonth";
import EditBirthYear from "./EditBirthDate//EditBirthYear";
import React from "react";
import * as textStyles from "themes/TextThemes";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import * as styles from "components/SettingsBodyBlock/StudentSettings/Profile/ProfileForms/ProfileFormsThemes";
import { useTranslation } from "react-i18next";
import { memo } from "react";

const BirthDateForm = () => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={styles.FormBlock}>
            <Typography sx={textStyles.Body2Black}>{t("main.settings.profile.birthDate")}</Typography>
            <Box sx={styles.DateBlock}>
                <EditBirthDay />
                <EditBirthMonth />
                <EditBirthYear />
            </Box>
        </Box>
    );
};

export default memo(BirthDateForm);
