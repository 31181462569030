import SButton from "components/UI/SButton";
import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import React from "react";
import Box from "@mui/material/Box";
import * as styles from "./LoginModalsThemes";
import * as textStyles from "themes/TextThemes";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import PasswordChanged from "assets/SettingsAssets/PNG/PasswordChanged.png";
import { useMedias } from "API/mediasHook";

const ModalPasswordChanged = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <ModalSettingsSmall
            visible={props.open}
            handleClose={props.handleDiscard}
        >
            <Box sx={styles.ModalIllustration}>
                <img
                    width={"52px"}
                    height={"52px"}
                    src={PasswordChanged}
                />
            </Box>
            <Typography
                sx={textStyles.HeadlineH41Black}
                style={styles.ModalTitle(medias)}
            >
                {t("main.settings.loginDetails.passwordWasChanged")}
            </Typography>
            <Box style={styles.ModalButton}>
                <SButton
                    variant="text"
                    onClick={props.handleDiscard}
                >
                    {t("main.close")}
                </SButton>
            </Box>
        </ModalSettingsSmall>
    );
});

export default ModalPasswordChanged;
