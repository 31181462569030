// source: internal/pb/rates.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require("google-protobuf");

var goog = jspb;

var global = Function("return this")();

goog.exportSymbol("proto.pb.CreateAnswerToRateRequest", null, global);
goog.exportSymbol("proto.pb.CreateAnswerToRateResponse", null, global);
goog.exportSymbol("proto.pb.CreateRateRequest", null, global);
goog.exportSymbol("proto.pb.CreateRateResponse", null, global);
goog.exportSymbol("proto.pb.DataRates", null, global);
goog.exportSymbol("proto.pb.DataRecentRates", null, global);
goog.exportSymbol("proto.pb.Rate", null, global);
goog.exportSymbol("proto.pb.RatesRequest", null, global);
goog.exportSymbol("proto.pb.RatesResponse", null, global);
goog.exportSymbol("proto.pb.Rating", null, global);
goog.exportSymbol("proto.pb.RecentRatesRequest", null, global);
goog.exportSymbol("proto.pb.RecentRatesResponse", null, global);
goog.exportSymbol("proto.pb.UserInfo", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.CreateRateRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.CreateRateRequest.repeatedFields_, null);
};

goog.inherits(proto.pb.CreateRateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.CreateRateRequest.displayName = "proto.pb.CreateRateRequest";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.CreateRateResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.CreateRateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.CreateRateResponse.displayName = "proto.pb.CreateRateResponse";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.RecentRatesRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.RecentRatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.RecentRatesRequest.displayName = "proto.pb.RecentRatesRequest";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.RecentRatesResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.RecentRatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.RecentRatesResponse.displayName = "proto.pb.RecentRatesResponse";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.DataRecentRates = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.DataRecentRates.repeatedFields_, null);
};

goog.inherits(proto.pb.DataRecentRates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.DataRecentRates.displayName = "proto.pb.DataRecentRates";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.RatesRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.RatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.RatesRequest.displayName = "proto.pb.RatesRequest";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.RatesResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.RatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.RatesResponse.displayName = "proto.pb.RatesResponse";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.DataRates = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.DataRates.repeatedFields_, null);
};

goog.inherits(proto.pb.DataRates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.DataRates.displayName = "proto.pb.DataRates";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.CreateAnswerToRateRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.CreateAnswerToRateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.CreateAnswerToRateRequest.displayName = "proto.pb.CreateAnswerToRateRequest";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.CreateAnswerToRateResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.CreateAnswerToRateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.CreateAnswerToRateResponse.displayName = "proto.pb.CreateAnswerToRateResponse";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Rating = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Rating, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Rating.displayName = "proto.pb.Rating";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Rate = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.Rate.repeatedFields_, null);
};

goog.inherits(proto.pb.Rate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.Rate.displayName = "proto.pb.Rate";
}

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.UserInfo = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.UserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    /**
     * @public
     * @override
     */
    proto.pb.UserInfo.displayName = "proto.pb.UserInfo";
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.CreateRateRequest.repeatedFields_ = [5];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.CreateRateRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.CreateRateRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.CreateRateRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.CreateRateRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                tutorUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                createdBy: jspb.Message.getFieldWithDefault(msg, 2, ""),
                rate: jspb.Message.getFieldWithDefault(msg, 3, 0),
                orderType: jspb.Message.getFieldWithDefault(msg, 4, ""),
                tagsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
                text: jspb.Message.getFieldWithDefault(msg, 6, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.CreateRateRequest}
 */
proto.pb.CreateRateRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.CreateRateRequest();

    return proto.pb.CreateRateRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.CreateRateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.CreateRateRequest}
 */
proto.pb.CreateRateRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setTutorUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setCreatedBy(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setRate(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());

                msg.setOrderType(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());

                msg.addTags(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());

                msg.setText(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.CreateRateRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.CreateRateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.CreateRateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.CreateRateRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getTutorUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getCreatedBy();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getRate();
    if (f !== 0) {
        writer.writeInt32(3, f);
    }

    f = message.getOrderType();
    if (f.length > 0) {
        writer.writeString(4, f);
    }

    f = message.getTagsList();
    if (f.length > 0) {
        writer.writeRepeatedString(5, f);
    }

    f = message.getText();
    if (f.length > 0) {
        writer.writeString(6, f);
    }
};

/**
 * optional string tutor_uuid = 1;
 * @return {string}
 */
proto.pb.CreateRateRequest.prototype.getTutorUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CreateRateRequest} returns this
 */
proto.pb.CreateRateRequest.prototype.setTutorUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string created_by = 2;
 * @return {string}
 */
proto.pb.CreateRateRequest.prototype.getCreatedBy = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CreateRateRequest} returns this
 */
proto.pb.CreateRateRequest.prototype.setCreatedBy = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional int32 rate = 3;
 * @return {number}
 */
proto.pb.CreateRateRequest.prototype.getRate = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.CreateRateRequest} returns this
 */
proto.pb.CreateRateRequest.prototype.setRate = function (value) {
    return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional string order_type = 4;
 * @return {string}
 */
proto.pb.CreateRateRequest.prototype.getOrderType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CreateRateRequest} returns this
 */
proto.pb.CreateRateRequest.prototype.setOrderType = function (value) {
    return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * repeated string tags = 5;
 * @return {!Array<string>}
 */
proto.pb.CreateRateRequest.prototype.getTagsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pb.CreateRateRequest} returns this
 */
proto.pb.CreateRateRequest.prototype.setTagsList = function (value) {
    return jspb.Message.setField(this, 5, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.CreateRateRequest} returns this
 */
proto.pb.CreateRateRequest.prototype.addTags = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.CreateRateRequest} returns this
 */
proto.pb.CreateRateRequest.prototype.clearTagsList = function () {
    return this.setTagsList([]);
};

/**
 * optional string text = 6;
 * @return {string}
 */
proto.pb.CreateRateRequest.prototype.getText = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CreateRateRequest} returns this
 */
proto.pb.CreateRateRequest.prototype.setText = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.CreateRateResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.CreateRateResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.CreateRateResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.CreateRateResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                status: jspb.Message.getFieldWithDefault(msg, 1, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.CreateRateResponse}
 */
proto.pb.CreateRateResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.CreateRateResponse();

    return proto.pb.CreateRateResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.CreateRateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.CreateRateResponse}
 */
proto.pb.CreateRateResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setStatus(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.CreateRateResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.CreateRateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.CreateRateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.CreateRateResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getStatus();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};

/**
 * optional string status = 1;
 * @return {string}
 */
proto.pb.CreateRateResponse.prototype.getStatus = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CreateRateResponse} returns this
 */
proto.pb.CreateRateResponse.prototype.setStatus = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.RecentRatesRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.RecentRatesRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.RecentRatesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.RecentRatesRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                tutorUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.RecentRatesRequest}
 */
proto.pb.RecentRatesRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.RecentRatesRequest();

    return proto.pb.RecentRatesRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.RecentRatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.RecentRatesRequest}
 */
proto.pb.RecentRatesRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setTutorUuid(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.RecentRatesRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.RecentRatesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.RecentRatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RecentRatesRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getTutorUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};

/**
 * optional string tutor_uuid = 1;
 * @return {string}
 */
proto.pb.RecentRatesRequest.prototype.getTutorUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.RecentRatesRequest} returns this
 */
proto.pb.RecentRatesRequest.prototype.setTutorUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.RecentRatesResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.RecentRatesResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.RecentRatesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.RecentRatesResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                status: jspb.Message.getFieldWithDefault(msg, 1, ""),
                data: (f = msg.getData()) && proto.pb.DataRecentRates.toObject(includeInstance, f),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.RecentRatesResponse}
 */
proto.pb.RecentRatesResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.RecentRatesResponse();

    return proto.pb.RecentRatesResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.RecentRatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.RecentRatesResponse}
 */
proto.pb.RecentRatesResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setStatus(value);
                break;
            case 2:
                var value = new proto.pb.DataRecentRates();

                reader.readMessage(value, proto.pb.DataRecentRates.deserializeBinaryFromReader);
                msg.setData(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.RecentRatesResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.RecentRatesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.RecentRatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RecentRatesResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getStatus();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getData();
    if (f != null) {
        writer.writeMessage(2, f, proto.pb.DataRecentRates.serializeBinaryToWriter);
    }
};

/**
 * optional string status = 1;
 * @return {string}
 */
proto.pb.RecentRatesResponse.prototype.getStatus = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.RecentRatesResponse} returns this
 */
proto.pb.RecentRatesResponse.prototype.setStatus = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional DataRecentRates data = 2;
 * @return {?proto.pb.DataRecentRates}
 */
proto.pb.RecentRatesResponse.prototype.getData = function () {
    return /** @type{?proto.pb.DataRecentRates} */ (jspb.Message.getWrapperField(this, proto.pb.DataRecentRates, 2));
};

/**
 * @param {?proto.pb.DataRecentRates|undefined} value
 * @return {!proto.pb.RecentRatesResponse} returns this
 */
proto.pb.RecentRatesResponse.prototype.setData = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.RecentRatesResponse} returns this
 */
proto.pb.RecentRatesResponse.prototype.clearData = function () {
    return this.setData(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.RecentRatesResponse.prototype.hasData = function () {
    return jspb.Message.getField(this, 2) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.DataRecentRates.repeatedFields_ = [3, 4, 5];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.DataRecentRates.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.DataRecentRates.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.DataRecentRates} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.DataRecentRates.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                total: jspb.Message.getFieldWithDefault(msg, 1, 0),
                average: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
                allTagsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
                ratingList: jspb.Message.toObjectList(msg.getRatingList(), proto.pb.Rating.toObject, includeInstance),
                ratesList: jspb.Message.toObjectList(msg.getRatesList(), proto.pb.Rate.toObject, includeInstance),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.DataRecentRates}
 */
proto.pb.DataRecentRates.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.DataRecentRates();

    return proto.pb.DataRecentRates.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.DataRecentRates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.DataRecentRates}
 */
proto.pb.DataRecentRates.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setTotal(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readDouble());

                msg.setAverage(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());

                msg.addAllTags(value);
                break;
            case 4:
                var value = new proto.pb.Rating();

                reader.readMessage(value, proto.pb.Rating.deserializeBinaryFromReader);
                msg.addRating(value);
                break;
            case 5:
                var value = new proto.pb.Rate();

                reader.readMessage(value, proto.pb.Rate.deserializeBinaryFromReader);
                msg.addRates(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.DataRecentRates.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.DataRecentRates.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.DataRecentRates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.DataRecentRates.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getTotal();
    if (f !== 0) {
        writer.writeInt32(1, f);
    }

    f = message.getAverage();
    if (f !== 0.0) {
        writer.writeDouble(2, f);
    }

    f = message.getAllTagsList();
    if (f.length > 0) {
        writer.writeRepeatedString(3, f);
    }

    f = message.getRatingList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(4, f, proto.pb.Rating.serializeBinaryToWriter);
    }

    f = message.getRatesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(5, f, proto.pb.Rate.serializeBinaryToWriter);
    }
};

/**
 * optional int32 total = 1;
 * @return {number}
 */
proto.pb.DataRecentRates.prototype.getTotal = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.DataRecentRates} returns this
 */
proto.pb.DataRecentRates.prototype.setTotal = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional double average = 2;
 * @return {number}
 */
proto.pb.DataRecentRates.prototype.getAverage = function () {
    return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};

/**
 * @param {number} value
 * @return {!proto.pb.DataRecentRates} returns this
 */
proto.pb.DataRecentRates.prototype.setAverage = function (value) {
    return jspb.Message.setProto3FloatField(this, 2, value);
};

/**
 * repeated string all_tags = 3;
 * @return {!Array<string>}
 */
proto.pb.DataRecentRates.prototype.getAllTagsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pb.DataRecentRates} returns this
 */
proto.pb.DataRecentRates.prototype.setAllTagsList = function (value) {
    return jspb.Message.setField(this, 3, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.DataRecentRates} returns this
 */
proto.pb.DataRecentRates.prototype.addAllTags = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.DataRecentRates} returns this
 */
proto.pb.DataRecentRates.prototype.clearAllTagsList = function () {
    return this.setAllTagsList([]);
};

/**
 * repeated Rating rating = 4;
 * @return {!Array<!proto.pb.Rating>}
 */
proto.pb.DataRecentRates.prototype.getRatingList = function () {
    return /** @type{!Array<!proto.pb.Rating>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.Rating, 4));
};

/**
 * @param {!Array<!proto.pb.Rating>} value
 * @return {!proto.pb.DataRecentRates} returns this
 */
proto.pb.DataRecentRates.prototype.setRatingList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 4, value);
};

/**
 * @param {!proto.pb.Rating=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.Rating}
 */
proto.pb.DataRecentRates.prototype.addRating = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.pb.Rating, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.DataRecentRates} returns this
 */
proto.pb.DataRecentRates.prototype.clearRatingList = function () {
    return this.setRatingList([]);
};

/**
 * repeated Rate rates = 5;
 * @return {!Array<!proto.pb.Rate>}
 */
proto.pb.DataRecentRates.prototype.getRatesList = function () {
    return /** @type{!Array<!proto.pb.Rate>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.Rate, 5));
};

/**
 * @param {!Array<!proto.pb.Rate>} value
 * @return {!proto.pb.DataRecentRates} returns this
 */
proto.pb.DataRecentRates.prototype.setRatesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 5, value);
};

/**
 * @param {!proto.pb.Rate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.Rate}
 */
proto.pb.DataRecentRates.prototype.addRates = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.pb.Rate, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.DataRecentRates} returns this
 */
proto.pb.DataRecentRates.prototype.clearRatesList = function () {
    return this.setRatesList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.RatesRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.RatesRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.RatesRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.RatesRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                tutorUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                tag: jspb.Message.getFieldWithDefault(msg, 2, ""),
                sort: jspb.Message.getFieldWithDefault(msg, 3, ""),
                skip: jspb.Message.getFieldWithDefault(msg, 4, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.RatesRequest}
 */
proto.pb.RatesRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.RatesRequest();

    return proto.pb.RatesRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.RatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.RatesRequest}
 */
proto.pb.RatesRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setTutorUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setTag(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());

                msg.setSort(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setSkip(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.RatesRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.RatesRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.RatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RatesRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getTutorUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getTag();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getSort();
    if (f.length > 0) {
        writer.writeString(3, f);
    }

    f = message.getSkip();
    if (f !== 0) {
        writer.writeInt32(4, f);
    }
};

/**
 * optional string tutor_uuid = 1;
 * @return {string}
 */
proto.pb.RatesRequest.prototype.getTutorUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.RatesRequest} returns this
 */
proto.pb.RatesRequest.prototype.setTutorUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string tag = 2;
 * @return {string}
 */
proto.pb.RatesRequest.prototype.getTag = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.RatesRequest} returns this
 */
proto.pb.RatesRequest.prototype.setTag = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string sort = 3;
 * @return {string}
 */
proto.pb.RatesRequest.prototype.getSort = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.RatesRequest} returns this
 */
proto.pb.RatesRequest.prototype.setSort = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional int32 skip = 4;
 * @return {number}
 */
proto.pb.RatesRequest.prototype.getSkip = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.RatesRequest} returns this
 */
proto.pb.RatesRequest.prototype.setSkip = function (value) {
    return jspb.Message.setProto3IntField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.RatesResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.RatesResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.RatesResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.RatesResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                status: jspb.Message.getFieldWithDefault(msg, 1, ""),
                data: (f = msg.getData()) && proto.pb.DataRates.toObject(includeInstance, f),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.RatesResponse}
 */
proto.pb.RatesResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.RatesResponse();

    return proto.pb.RatesResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.RatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.RatesResponse}
 */
proto.pb.RatesResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setStatus(value);
                break;
            case 2:
                var value = new proto.pb.DataRates();

                reader.readMessage(value, proto.pb.DataRates.deserializeBinaryFromReader);
                msg.setData(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.RatesResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.RatesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.RatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RatesResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getStatus();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getData();
    if (f != null) {
        writer.writeMessage(2, f, proto.pb.DataRates.serializeBinaryToWriter);
    }
};

/**
 * optional string status = 1;
 * @return {string}
 */
proto.pb.RatesResponse.prototype.getStatus = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.RatesResponse} returns this
 */
proto.pb.RatesResponse.prototype.setStatus = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional DataRates data = 2;
 * @return {?proto.pb.DataRates}
 */
proto.pb.RatesResponse.prototype.getData = function () {
    return /** @type{?proto.pb.DataRates} */ (jspb.Message.getWrapperField(this, proto.pb.DataRates, 2));
};

/**
 * @param {?proto.pb.DataRates|undefined} value
 * @return {!proto.pb.RatesResponse} returns this
 */
proto.pb.RatesResponse.prototype.setData = function (value) {
    return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.RatesResponse} returns this
 */
proto.pb.RatesResponse.prototype.clearData = function () {
    return this.setData(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.RatesResponse.prototype.hasData = function () {
    return jspb.Message.getField(this, 2) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.DataRates.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.DataRates.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.DataRates.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.DataRates} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.DataRates.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                ratesList: jspb.Message.toObjectList(msg.getRatesList(), proto.pb.Rate.toObject, includeInstance),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.DataRates}
 */
proto.pb.DataRates.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.DataRates();

    return proto.pb.DataRates.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.DataRates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.DataRates}
 */
proto.pb.DataRates.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = new proto.pb.Rate();

                reader.readMessage(value, proto.pb.Rate.deserializeBinaryFromReader);
                msg.addRates(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.DataRates.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.DataRates.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.DataRates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.DataRates.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getRatesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(1, f, proto.pb.Rate.serializeBinaryToWriter);
    }
};

/**
 * repeated Rate rates = 1;
 * @return {!Array<!proto.pb.Rate>}
 */
proto.pb.DataRates.prototype.getRatesList = function () {
    return /** @type{!Array<!proto.pb.Rate>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.Rate, 1));
};

/**
 * @param {!Array<!proto.pb.Rate>} value
 * @return {!proto.pb.DataRates} returns this
 */
proto.pb.DataRates.prototype.setRatesList = function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.pb.Rate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.Rate}
 */
proto.pb.DataRates.prototype.addRates = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.pb.Rate, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.DataRates} returns this
 */
proto.pb.DataRates.prototype.clearRatesList = function () {
    return this.setRatesList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.CreateAnswerToRateRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.CreateAnswerToRateRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.CreateAnswerToRateRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.CreateAnswerToRateRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                tutorUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                rateUuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
                text: jspb.Message.getFieldWithDefault(msg, 3, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.CreateAnswerToRateRequest}
 */
proto.pb.CreateAnswerToRateRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.CreateAnswerToRateRequest();

    return proto.pb.CreateAnswerToRateRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.CreateAnswerToRateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.CreateAnswerToRateRequest}
 */
proto.pb.CreateAnswerToRateRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setTutorUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setRateUuid(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());

                msg.setText(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.CreateAnswerToRateRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.CreateAnswerToRateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.CreateAnswerToRateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.CreateAnswerToRateRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getTutorUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getRateUuid();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getText();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};

/**
 * optional string tutor_uuid = 1;
 * @return {string}
 */
proto.pb.CreateAnswerToRateRequest.prototype.getTutorUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CreateAnswerToRateRequest} returns this
 */
proto.pb.CreateAnswerToRateRequest.prototype.setTutorUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string rate_uuid = 2;
 * @return {string}
 */
proto.pb.CreateAnswerToRateRequest.prototype.getRateUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CreateAnswerToRateRequest} returns this
 */
proto.pb.CreateAnswerToRateRequest.prototype.setRateUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string text = 3;
 * @return {string}
 */
proto.pb.CreateAnswerToRateRequest.prototype.getText = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CreateAnswerToRateRequest} returns this
 */
proto.pb.CreateAnswerToRateRequest.prototype.setText = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.CreateAnswerToRateResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.CreateAnswerToRateResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.CreateAnswerToRateResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.CreateAnswerToRateResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                status: jspb.Message.getFieldWithDefault(msg, 1, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.CreateAnswerToRateResponse}
 */
proto.pb.CreateAnswerToRateResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.CreateAnswerToRateResponse();

    return proto.pb.CreateAnswerToRateResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.CreateAnswerToRateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.CreateAnswerToRateResponse}
 */
proto.pb.CreateAnswerToRateResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setStatus(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.CreateAnswerToRateResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.CreateAnswerToRateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.CreateAnswerToRateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.CreateAnswerToRateResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getStatus();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};

/**
 * optional string status = 1;
 * @return {string}
 */
proto.pb.CreateAnswerToRateResponse.prototype.getStatus = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.CreateAnswerToRateResponse} returns this
 */
proto.pb.CreateAnswerToRateResponse.prototype.setStatus = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Rating.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Rating.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Rating} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Rating.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                rate: jspb.Message.getFieldWithDefault(msg, 1, 0),
                count: jspb.Message.getFieldWithDefault(msg, 2, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Rating}
 */
proto.pb.Rating.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Rating();

    return proto.pb.Rating.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Rating} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Rating}
 */
proto.pb.Rating.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setRate(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setCount(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Rating.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Rating.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Rating} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Rating.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getRate();
    if (f !== 0) {
        writer.writeInt32(1, f);
    }

    f = message.getCount();
    if (f !== 0) {
        writer.writeInt32(2, f);
    }
};

/**
 * optional int32 rate = 1;
 * @return {number}
 */
proto.pb.Rating.prototype.getRate = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.Rating} returns this
 */
proto.pb.Rating.prototype.setRate = function (value) {
    return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.pb.Rating.prototype.getCount = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.Rating} returns this
 */
proto.pb.Rating.prototype.setCount = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.Rate.repeatedFields_ = [4, 7];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Rate.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Rate.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Rate} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Rate.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                rateUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                rate: jspb.Message.getFieldWithDefault(msg, 2, 0),
                orderType: jspb.Message.getFieldWithDefault(msg, 3, ""),
                tagsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
                text: jspb.Message.getFieldWithDefault(msg, 5, ""),
                createdAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
                answersList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
                studentInfo: (f = msg.getStudentInfo()) && proto.pb.UserInfo.toObject(includeInstance, f),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Rate}
 */
proto.pb.Rate.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Rate();

    return proto.pb.Rate.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Rate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Rate}
 */
proto.pb.Rate.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setRateUuid(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setRate(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());

                msg.setOrderType(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());

                msg.addTags(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());

                msg.setText(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());

                msg.setCreatedAt(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());

                msg.addAnswers(value);
                break;
            case 8:
                var value = new proto.pb.UserInfo();

                reader.readMessage(value, proto.pb.UserInfo.deserializeBinaryFromReader);
                msg.setStudentInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Rate.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Rate.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Rate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Rate.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getRateUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getRate();
    if (f !== 0) {
        writer.writeInt32(2, f);
    }

    f = message.getOrderType();
    if (f.length > 0) {
        writer.writeString(3, f);
    }

    f = message.getTagsList();
    if (f.length > 0) {
        writer.writeRepeatedString(4, f);
    }

    f = message.getText();
    if (f.length > 0) {
        writer.writeString(5, f);
    }

    f = message.getCreatedAt();
    if (f.length > 0) {
        writer.writeString(6, f);
    }

    f = message.getAnswersList();
    if (f.length > 0) {
        writer.writeRepeatedString(7, f);
    }

    f = message.getStudentInfo();
    if (f != null) {
        writer.writeMessage(8, f, proto.pb.UserInfo.serializeBinaryToWriter);
    }
};

/**
 * optional string rate_uuid = 1;
 * @return {string}
 */
proto.pb.Rate.prototype.getRateUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Rate} returns this
 */
proto.pb.Rate.prototype.setRateUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional int32 rate = 2;
 * @return {number}
 */
proto.pb.Rate.prototype.getRate = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.pb.Rate} returns this
 */
proto.pb.Rate.prototype.setRate = function (value) {
    return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional string order_type = 3;
 * @return {string}
 */
proto.pb.Rate.prototype.getOrderType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Rate} returns this
 */
proto.pb.Rate.prototype.setOrderType = function (value) {
    return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * repeated string tags = 4;
 * @return {!Array<string>}
 */
proto.pb.Rate.prototype.getTagsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pb.Rate} returns this
 */
proto.pb.Rate.prototype.setTagsList = function (value) {
    return jspb.Message.setField(this, 4, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.Rate} returns this
 */
proto.pb.Rate.prototype.addTags = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.Rate} returns this
 */
proto.pb.Rate.prototype.clearTagsList = function () {
    return this.setTagsList([]);
};

/**
 * optional string text = 5;
 * @return {string}
 */
proto.pb.Rate.prototype.getText = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Rate} returns this
 */
proto.pb.Rate.prototype.setText = function (value) {
    return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional string created_at = 6;
 * @return {string}
 */
proto.pb.Rate.prototype.getCreatedAt = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.Rate} returns this
 */
proto.pb.Rate.prototype.setCreatedAt = function (value) {
    return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * repeated string answers = 7;
 * @return {!Array<string>}
 */
proto.pb.Rate.prototype.getAnswersList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};

/**
 * @param {!Array<string>} value
 * @return {!proto.pb.Rate} returns this
 */
proto.pb.Rate.prototype.setAnswersList = function (value) {
    return jspb.Message.setField(this, 7, value || []);
};

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.pb.Rate} returns this
 */
proto.pb.Rate.prototype.addAnswers = function (value, opt_index) {
    return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.pb.Rate} returns this
 */
proto.pb.Rate.prototype.clearAnswersList = function () {
    return this.setAnswersList([]);
};

/**
 * optional UserInfo student_info = 8;
 * @return {?proto.pb.UserInfo}
 */
proto.pb.Rate.prototype.getStudentInfo = function () {
    return /** @type{?proto.pb.UserInfo} */ (jspb.Message.getWrapperField(this, proto.pb.UserInfo, 8));
};

/**
 * @param {?proto.pb.UserInfo|undefined} value
 * @return {!proto.pb.Rate} returns this
 */
proto.pb.Rate.prototype.setStudentInfo = function (value) {
    return jspb.Message.setWrapperField(this, 8, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.pb.Rate} returns this
 */
proto.pb.Rate.prototype.clearStudentInfo = function () {
    return this.setStudentInfo(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.pb.Rate.prototype.hasStudentInfo = function () {
    return jspb.Message.getField(this, 8) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.UserInfo.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.UserInfo.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.UserInfo} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.UserInfo.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                name: jspb.Message.getFieldWithDefault(msg, 2, ""),
                photo: msg.getPhoto_asB64(),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.UserInfo}
 */
proto.pb.UserInfo.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.UserInfo();

    return proto.pb.UserInfo.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.UserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.UserInfo}
 */
proto.pb.UserInfo.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setName(value);
                break;
            case 3:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());

                msg.setPhoto(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.UserInfo.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.UserInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.UserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.UserInfo.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getName();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getPhoto_asU8();
    if (f.length > 0) {
        writer.writeBytes(3, f);
    }
};

/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.pb.UserInfo.prototype.getUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.UserInfo} returns this
 */
proto.pb.UserInfo.prototype.setUuid = function (value) {
    return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string name = 2;
 * @return {string}
 */
proto.pb.UserInfo.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.pb.UserInfo} returns this
 */
proto.pb.UserInfo.prototype.setName = function (value) {
    return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional bytes photo = 3;
 * @return {string}
 */
proto.pb.UserInfo.prototype.getPhoto = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * optional bytes photo = 3;
 * This is a type-conversion wrapper around `getPhoto()`
 * @return {string}
 */
proto.pb.UserInfo.prototype.getPhoto_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getPhoto()));
};

/**
 * optional bytes photo = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPhoto()`
 * @return {!Uint8Array}
 */
proto.pb.UserInfo.prototype.getPhoto_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getPhoto()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.pb.UserInfo} returns this
 */
proto.pb.UserInfo.prototype.setPhoto = function (value) {
    return jspb.Message.setProto3BytesField(this, 3, value);
};

goog.object.extend(exports, proto.pb);
