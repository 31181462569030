export const AllBlock = {
    width: "1216px",
    marginTop: "140px",
    display: "flex",
    gap: "20px",
    whiteSpace: "pre-wrap",
    paddingBottom: "110px",
};

export const AccordionBlock = {
    width: "701px",
    gap: "20px",
    display: "flex",
    flexDirection: "column",
};
