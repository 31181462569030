// source: auth.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require("google-protobuf");
var goog = jspb;
var global =
    (typeof globalThis !== "undefined" && globalThis) ||
    (typeof window !== "undefined" && window) ||
    (typeof global !== "undefined" && global) ||
    (typeof self !== "undefined" && self) ||
    function () {
        return this;
    }.call(null) ||
    Function("return this")();

goog.exportSymbol("proto.pb.AccessTokenInfo", null, global);
goog.exportSymbol("proto.pb.AccessTokens", null, global);
goog.exportSymbol("proto.pb.LoginEmailRequest", null, global);
goog.exportSymbol("proto.pb.LoginEmailResponse", null, global);
goog.exportSymbol("proto.pb.LoginSmsRequest", null, global);
goog.exportSymbol("proto.pb.LoginSmsResponse", null, global);
goog.exportSymbol("proto.pb.LoginSmsVerifyRequest", null, global);
goog.exportSymbol("proto.pb.LoginSmsVerifyResponse", null, global);
goog.exportSymbol("proto.pb.LogoutRequest", null, global);
goog.exportSymbol("proto.pb.LogoutResponse", null, global);
goog.exportSymbol("proto.pb.RecoverPasswordRequest", null, global);
goog.exportSymbol("proto.pb.RecoverPasswordResponse", null, global);
goog.exportSymbol("proto.pb.RefreshTokensRequest", null, global);
goog.exportSymbol("proto.pb.RefreshTokensResponse", null, global);
goog.exportSymbol("proto.pb.RegisterRequest", null, global);
goog.exportSymbol("proto.pb.RegisterResponse", null, global);
goog.exportSymbol("proto.pb.RegisterSmsVerifyRequest", null, global);
goog.exportSymbol("proto.pb.RegisterSmsVerifyResponse", null, global);
goog.exportSymbol("proto.pb.Tokens", null, global);
goog.exportSymbol("proto.pb.ValidateTokenRequest", null, global);
goog.exportSymbol("proto.pb.ValidateTokenResponse", null, global);
goog.exportSymbol("proto.pb.VerifyPasswordRecoveryRequest", null, global);
goog.exportSymbol("proto.pb.VerifyPasswordRecoveryResponse", null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.AccessTokens = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.AccessTokens, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.AccessTokens.displayName = "proto.pb.AccessTokens";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.AccessTokens.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.AccessTokens.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.AccessTokens} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.AccessTokens.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                studentAccessToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
                tutorAccessToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.AccessTokens}
 */
proto.pb.AccessTokens.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.AccessTokens();
    return proto.pb.AccessTokens.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.AccessTokens} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.AccessTokens}
 */
proto.pb.AccessTokens.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setStudentAccessToken(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setTutorAccessToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.AccessTokens.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.AccessTokens.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.AccessTokens} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.AccessTokens.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getStudentAccessToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getTutorAccessToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};

/**
 * optional string student_access_token = 1;
 * @return {string}
 */
proto.pb.AccessTokens.prototype.getStudentAccessToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.AccessTokens.prototype.setStudentAccessToken = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string tutor_access_token = 2;
 * @return {string}
 */
proto.pb.AccessTokens.prototype.getTutorAccessToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.pb.AccessTokens.prototype.setTutorAccessToken = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Tokens = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.Tokens, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.Tokens.displayName = "proto.pb.Tokens";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Tokens.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Tokens.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Tokens} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Tokens.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                accessTokens: (f = msg.getAccessTokens()) && proto.pb.AccessTokens.toObject(includeInstance, f),
                refreshToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Tokens}
 */
proto.pb.Tokens.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.Tokens();
    return proto.pb.Tokens.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Tokens} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Tokens}
 */
proto.pb.Tokens.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.pb.AccessTokens();
                reader.readMessage(value, proto.pb.AccessTokens.deserializeBinaryFromReader);
                msg.setAccessTokens(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setRefreshToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Tokens.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.Tokens.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Tokens} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Tokens.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAccessTokens();
    if (f != null) {
        writer.writeMessage(1, f, proto.pb.AccessTokens.serializeBinaryToWriter);
    }
    f = message.getRefreshToken();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};

/**
 * optional AccessTokens access_tokens = 1;
 * @return {?proto.pb.AccessTokens}
 */
proto.pb.Tokens.prototype.getAccessTokens = function () {
    return /** @type{?proto.pb.AccessTokens} */ (jspb.Message.getWrapperField(this, proto.pb.AccessTokens, 1));
};

/** @param {?proto.pb.AccessTokens|undefined} value */
proto.pb.Tokens.prototype.setAccessTokens = function (value) {
    jspb.Message.setWrapperField(this, 1, value);
};

proto.pb.Tokens.prototype.clearAccessTokens = function () {
    this.setAccessTokens(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pb.Tokens.prototype.hasAccessTokens = function () {
    return jspb.Message.getField(this, 1) != null;
};

/**
 * optional string refresh_token = 2;
 * @return {string}
 */
proto.pb.Tokens.prototype.getRefreshToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.pb.Tokens.prototype.setRefreshToken = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.AccessTokenInfo = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.AccessTokenInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.AccessTokenInfo.displayName = "proto.pb.AccessTokenInfo";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.AccessTokenInfo.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.AccessTokenInfo.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.AccessTokenInfo} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.AccessTokenInfo.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                role: jspb.Message.getFieldWithDefault(msg, 2, ""),
                utcOffsetSeconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.AccessTokenInfo}
 */
proto.pb.AccessTokenInfo.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.AccessTokenInfo();
    return proto.pb.AccessTokenInfo.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.AccessTokenInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.AccessTokenInfo}
 */
proto.pb.AccessTokenInfo.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setRole(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt64());
                msg.setUtcOffsetSeconds(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.AccessTokenInfo.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.AccessTokenInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.AccessTokenInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.AccessTokenInfo.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getRole();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getUtcOffsetSeconds();
    if (f !== 0) {
        writer.writeInt64(3, f);
    }
};

/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.pb.AccessTokenInfo.prototype.getUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.AccessTokenInfo.prototype.setUuid = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string role = 2;
 * @return {string}
 */
proto.pb.AccessTokenInfo.prototype.getRole = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.pb.AccessTokenInfo.prototype.setRole = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional int64 utc_offset_seconds = 3;
 * @return {number}
 */
proto.pb.AccessTokenInfo.prototype.getUtcOffsetSeconds = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/** @param {number} value */
proto.pb.AccessTokenInfo.prototype.setUtcOffsetSeconds = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.RegisterRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.RegisterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.RegisterRequest.displayName = "proto.pb.RegisterRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.RegisterRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.RegisterRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.RegisterRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.RegisterRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                phone: jspb.Message.getFieldWithDefault(msg, 1, ""),
                email: jspb.Message.getFieldWithDefault(msg, 2, ""),
                name: jspb.Message.getFieldWithDefault(msg, 3, ""),
                role: jspb.Message.getFieldWithDefault(msg, 4, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.RegisterRequest}
 */
proto.pb.RegisterRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.RegisterRequest();
    return proto.pb.RegisterRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.RegisterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.RegisterRequest}
 */
proto.pb.RegisterRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPhone(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setEmail(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setName(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());
                msg.setRole(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.RegisterRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.RegisterRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.RegisterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RegisterRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPhone();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getEmail();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getName();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getRole();
    if (f.length > 0) {
        writer.writeString(4, f);
    }
};

/**
 * optional string phone = 1;
 * @return {string}
 */
proto.pb.RegisterRequest.prototype.getPhone = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.RegisterRequest.prototype.setPhone = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string email = 2;
 * @return {string}
 */
proto.pb.RegisterRequest.prototype.getEmail = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.pb.RegisterRequest.prototype.setEmail = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string name = 3;
 * @return {string}
 */
proto.pb.RegisterRequest.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/** @param {string} value */
proto.pb.RegisterRequest.prototype.setName = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string role = 4;
 * @return {string}
 */
proto.pb.RegisterRequest.prototype.getRole = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/** @param {string} value */
proto.pb.RegisterRequest.prototype.setRole = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.RegisterResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.RegisterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.RegisterResponse.displayName = "proto.pb.RegisterResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.RegisterResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.RegisterResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.RegisterResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.RegisterResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                codeTtl: jspb.Message.getFieldWithDefault(msg, 3, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.RegisterResponse}
 */
proto.pb.RegisterResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.RegisterResponse();
    return proto.pb.RegisterResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.RegisterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.RegisterResponse}
 */
proto.pb.RegisterResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 3:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setCodeTtl(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.RegisterResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.RegisterResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.RegisterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RegisterResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCodeTtl();
    if (f !== 0) {
        writer.writeInt32(3, f);
    }
};

/**
 * optional int32 code_ttl = 3;
 * @return {number}
 */
proto.pb.RegisterResponse.prototype.getCodeTtl = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/** @param {number} value */
proto.pb.RegisterResponse.prototype.setCodeTtl = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.RegisterSmsVerifyRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.RegisterSmsVerifyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.RegisterSmsVerifyRequest.displayName = "proto.pb.RegisterSmsVerifyRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.RegisterSmsVerifyRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.RegisterSmsVerifyRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.RegisterSmsVerifyRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.RegisterSmsVerifyRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                phone: jspb.Message.getFieldWithDefault(msg, 1, ""),
                role: jspb.Message.getFieldWithDefault(msg, 2, ""),
                code: jspb.Message.getFieldWithDefault(msg, 3, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.RegisterSmsVerifyRequest}
 */
proto.pb.RegisterSmsVerifyRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.RegisterSmsVerifyRequest();
    return proto.pb.RegisterSmsVerifyRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.RegisterSmsVerifyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.RegisterSmsVerifyRequest}
 */
proto.pb.RegisterSmsVerifyRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPhone(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setRole(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setCode(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.RegisterSmsVerifyRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.RegisterSmsVerifyRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.RegisterSmsVerifyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RegisterSmsVerifyRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPhone();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getRole();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
    f = message.getCode();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};

/**
 * optional string phone = 1;
 * @return {string}
 */
proto.pb.RegisterSmsVerifyRequest.prototype.getPhone = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.RegisterSmsVerifyRequest.prototype.setPhone = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string role = 2;
 * @return {string}
 */
proto.pb.RegisterSmsVerifyRequest.prototype.getRole = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.pb.RegisterSmsVerifyRequest.prototype.setRole = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string code = 3;
 * @return {string}
 */
proto.pb.RegisterSmsVerifyRequest.prototype.getCode = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/** @param {string} value */
proto.pb.RegisterSmsVerifyRequest.prototype.setCode = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.RegisterSmsVerifyResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.RegisterSmsVerifyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.RegisterSmsVerifyResponse.displayName = "proto.pb.RegisterSmsVerifyResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.RegisterSmsVerifyResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.RegisterSmsVerifyResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.RegisterSmsVerifyResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.RegisterSmsVerifyResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                userUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                tokens: (f = msg.getTokens()) && proto.pb.Tokens.toObject(includeInstance, f),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.RegisterSmsVerifyResponse}
 */
proto.pb.RegisterSmsVerifyResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.RegisterSmsVerifyResponse();
    return proto.pb.RegisterSmsVerifyResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.RegisterSmsVerifyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.RegisterSmsVerifyResponse}
 */
proto.pb.RegisterSmsVerifyResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserUuid(value);
                break;
            case 2:
                var value = new proto.pb.Tokens();
                reader.readMessage(value, proto.pb.Tokens.deserializeBinaryFromReader);
                msg.setTokens(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.RegisterSmsVerifyResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.RegisterSmsVerifyResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.RegisterSmsVerifyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RegisterSmsVerifyResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getTokens();
    if (f != null) {
        writer.writeMessage(2, f, proto.pb.Tokens.serializeBinaryToWriter);
    }
};

/**
 * optional string user_uuid = 1;
 * @return {string}
 */
proto.pb.RegisterSmsVerifyResponse.prototype.getUserUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.RegisterSmsVerifyResponse.prototype.setUserUuid = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional Tokens tokens = 2;
 * @return {?proto.pb.Tokens}
 */
proto.pb.RegisterSmsVerifyResponse.prototype.getTokens = function () {
    return /** @type{?proto.pb.Tokens} */ (jspb.Message.getWrapperField(this, proto.pb.Tokens, 2));
};

/** @param {?proto.pb.Tokens|undefined} value */
proto.pb.RegisterSmsVerifyResponse.prototype.setTokens = function (value) {
    jspb.Message.setWrapperField(this, 2, value);
};

proto.pb.RegisterSmsVerifyResponse.prototype.clearTokens = function () {
    this.setTokens(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pb.RegisterSmsVerifyResponse.prototype.hasTokens = function () {
    return jspb.Message.getField(this, 2) != null;
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.LoginSmsRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.LoginSmsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.LoginSmsRequest.displayName = "proto.pb.LoginSmsRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.LoginSmsRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.LoginSmsRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.LoginSmsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.LoginSmsRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                phone: jspb.Message.getFieldWithDefault(msg, 1, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.LoginSmsRequest}
 */
proto.pb.LoginSmsRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.LoginSmsRequest();
    return proto.pb.LoginSmsRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.LoginSmsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.LoginSmsRequest}
 */
proto.pb.LoginSmsRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPhone(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.LoginSmsRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.LoginSmsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.LoginSmsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.LoginSmsRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPhone();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};

/**
 * optional string phone = 1;
 * @return {string}
 */
proto.pb.LoginSmsRequest.prototype.getPhone = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.LoginSmsRequest.prototype.setPhone = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.LoginSmsResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.LoginSmsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.LoginSmsResponse.displayName = "proto.pb.LoginSmsResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.LoginSmsResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.LoginSmsResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.LoginSmsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.LoginSmsResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                codeTtl: jspb.Message.getFieldWithDefault(msg, 3, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.LoginSmsResponse}
 */
proto.pb.LoginSmsResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.LoginSmsResponse();
    return proto.pb.LoginSmsResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.LoginSmsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.LoginSmsResponse}
 */
proto.pb.LoginSmsResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 3:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setCodeTtl(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.LoginSmsResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.LoginSmsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.LoginSmsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.LoginSmsResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getCodeTtl();
    if (f !== 0) {
        writer.writeInt32(3, f);
    }
};

/**
 * optional int32 code_ttl = 3;
 * @return {number}
 */
proto.pb.LoginSmsResponse.prototype.getCodeTtl = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/** @param {number} value */
proto.pb.LoginSmsResponse.prototype.setCodeTtl = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.LoginSmsVerifyRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.LoginSmsVerifyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.LoginSmsVerifyRequest.displayName = "proto.pb.LoginSmsVerifyRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.LoginSmsVerifyRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.LoginSmsVerifyRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.LoginSmsVerifyRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.LoginSmsVerifyRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                phone: jspb.Message.getFieldWithDefault(msg, 1, ""),
                code: jspb.Message.getFieldWithDefault(msg, 2, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.LoginSmsVerifyRequest}
 */
proto.pb.LoginSmsVerifyRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.LoginSmsVerifyRequest();
    return proto.pb.LoginSmsVerifyRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.LoginSmsVerifyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.LoginSmsVerifyRequest}
 */
proto.pb.LoginSmsVerifyRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setPhone(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setCode(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.LoginSmsVerifyRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.LoginSmsVerifyRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.LoginSmsVerifyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.LoginSmsVerifyRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getPhone();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getCode();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};

/**
 * optional string phone = 1;
 * @return {string}
 */
proto.pb.LoginSmsVerifyRequest.prototype.getPhone = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.LoginSmsVerifyRequest.prototype.setPhone = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string code = 2;
 * @return {string}
 */
proto.pb.LoginSmsVerifyRequest.prototype.getCode = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.pb.LoginSmsVerifyRequest.prototype.setCode = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.LoginSmsVerifyResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.LoginSmsVerifyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.LoginSmsVerifyResponse.displayName = "proto.pb.LoginSmsVerifyResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.LoginSmsVerifyResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.LoginSmsVerifyResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.LoginSmsVerifyResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.LoginSmsVerifyResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                userUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                tokens: (f = msg.getTokens()) && proto.pb.Tokens.toObject(includeInstance, f),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.LoginSmsVerifyResponse}
 */
proto.pb.LoginSmsVerifyResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.LoginSmsVerifyResponse();
    return proto.pb.LoginSmsVerifyResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.LoginSmsVerifyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.LoginSmsVerifyResponse}
 */
proto.pb.LoginSmsVerifyResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserUuid(value);
                break;
            case 2:
                var value = new proto.pb.Tokens();
                reader.readMessage(value, proto.pb.Tokens.deserializeBinaryFromReader);
                msg.setTokens(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.LoginSmsVerifyResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.LoginSmsVerifyResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.LoginSmsVerifyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.LoginSmsVerifyResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getTokens();
    if (f != null) {
        writer.writeMessage(2, f, proto.pb.Tokens.serializeBinaryToWriter);
    }
};

/**
 * optional string user_uuid = 1;
 * @return {string}
 */
proto.pb.LoginSmsVerifyResponse.prototype.getUserUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.LoginSmsVerifyResponse.prototype.setUserUuid = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional Tokens tokens = 2;
 * @return {?proto.pb.Tokens}
 */
proto.pb.LoginSmsVerifyResponse.prototype.getTokens = function () {
    return /** @type{?proto.pb.Tokens} */ (jspb.Message.getWrapperField(this, proto.pb.Tokens, 2));
};

/** @param {?proto.pb.Tokens|undefined} value */
proto.pb.LoginSmsVerifyResponse.prototype.setTokens = function (value) {
    jspb.Message.setWrapperField(this, 2, value);
};

proto.pb.LoginSmsVerifyResponse.prototype.clearTokens = function () {
    this.setTokens(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pb.LoginSmsVerifyResponse.prototype.hasTokens = function () {
    return jspb.Message.getField(this, 2) != null;
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.LoginEmailRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.LoginEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.LoginEmailRequest.displayName = "proto.pb.LoginEmailRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.LoginEmailRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.LoginEmailRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.LoginEmailRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.LoginEmailRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                email: jspb.Message.getFieldWithDefault(msg, 1, ""),
                password: jspb.Message.getFieldWithDefault(msg, 2, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.LoginEmailRequest}
 */
proto.pb.LoginEmailRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.LoginEmailRequest();
    return proto.pb.LoginEmailRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.LoginEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.LoginEmailRequest}
 */
proto.pb.LoginEmailRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setEmail(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());
                msg.setPassword(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.LoginEmailRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.LoginEmailRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.LoginEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.LoginEmailRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEmail();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getPassword();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};

/**
 * optional string email = 1;
 * @return {string}
 */
proto.pb.LoginEmailRequest.prototype.getEmail = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.LoginEmailRequest.prototype.setEmail = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string password = 2;
 * @return {string}
 */
proto.pb.LoginEmailRequest.prototype.getPassword = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.pb.LoginEmailRequest.prototype.setPassword = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.LoginEmailResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.LoginEmailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.LoginEmailResponse.displayName = "proto.pb.LoginEmailResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.LoginEmailResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.LoginEmailResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.LoginEmailResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.LoginEmailResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                userUuid: jspb.Message.getFieldWithDefault(msg, 3, ""),
                tokens: (f = msg.getTokens()) && proto.pb.Tokens.toObject(includeInstance, f),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.LoginEmailResponse}
 */
proto.pb.LoginEmailResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.LoginEmailResponse();
    return proto.pb.LoginEmailResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.LoginEmailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.LoginEmailResponse}
 */
proto.pb.LoginEmailResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setUserUuid(value);
                break;
            case 4:
                var value = new proto.pb.Tokens();
                reader.readMessage(value, proto.pb.Tokens.deserializeBinaryFromReader);
                msg.setTokens(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.LoginEmailResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.LoginEmailResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.LoginEmailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.LoginEmailResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getUserUuid();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
    f = message.getTokens();
    if (f != null) {
        writer.writeMessage(4, f, proto.pb.Tokens.serializeBinaryToWriter);
    }
};

/**
 * optional string user_uuid = 3;
 * @return {string}
 */
proto.pb.LoginEmailResponse.prototype.getUserUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/** @param {string} value */
proto.pb.LoginEmailResponse.prototype.setUserUuid = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional Tokens tokens = 4;
 * @return {?proto.pb.Tokens}
 */
proto.pb.LoginEmailResponse.prototype.getTokens = function () {
    return /** @type{?proto.pb.Tokens} */ (jspb.Message.getWrapperField(this, proto.pb.Tokens, 4));
};

/** @param {?proto.pb.Tokens|undefined} value */
proto.pb.LoginEmailResponse.prototype.setTokens = function (value) {
    jspb.Message.setWrapperField(this, 4, value);
};

proto.pb.LoginEmailResponse.prototype.clearTokens = function () {
    this.setTokens(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pb.LoginEmailResponse.prototype.hasTokens = function () {
    return jspb.Message.getField(this, 4) != null;
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.ValidateTokenRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.ValidateTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.ValidateTokenRequest.displayName = "proto.pb.ValidateTokenRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.ValidateTokenRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.ValidateTokenRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.ValidateTokenRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.ValidateTokenRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                accessToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.ValidateTokenRequest}
 */
proto.pb.ValidateTokenRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.ValidateTokenRequest();
    return proto.pb.ValidateTokenRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.ValidateTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.ValidateTokenRequest}
 */
proto.pb.ValidateTokenRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setAccessToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.ValidateTokenRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.ValidateTokenRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.ValidateTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.ValidateTokenRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAccessToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};

/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.pb.ValidateTokenRequest.prototype.getAccessToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.ValidateTokenRequest.prototype.setAccessToken = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.ValidateTokenResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.ValidateTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.ValidateTokenResponse.displayName = "proto.pb.ValidateTokenResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.ValidateTokenResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.ValidateTokenResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.ValidateTokenResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.ValidateTokenResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                accessTokenInfo: (f = msg.getAccessTokenInfo()) && proto.pb.AccessTokenInfo.toObject(includeInstance, f),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.ValidateTokenResponse}
 */
proto.pb.ValidateTokenResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.ValidateTokenResponse();
    return proto.pb.ValidateTokenResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.ValidateTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.ValidateTokenResponse}
 */
proto.pb.ValidateTokenResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.pb.AccessTokenInfo();
                reader.readMessage(value, proto.pb.AccessTokenInfo.deserializeBinaryFromReader);
                msg.setAccessTokenInfo(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.ValidateTokenResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.ValidateTokenResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.ValidateTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.ValidateTokenResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getAccessTokenInfo();
    if (f != null) {
        writer.writeMessage(1, f, proto.pb.AccessTokenInfo.serializeBinaryToWriter);
    }
};

/**
 * optional AccessTokenInfo access_token_info = 1;
 * @return {?proto.pb.AccessTokenInfo}
 */
proto.pb.ValidateTokenResponse.prototype.getAccessTokenInfo = function () {
    return /** @type{?proto.pb.AccessTokenInfo} */ (jspb.Message.getWrapperField(this, proto.pb.AccessTokenInfo, 1));
};

/** @param {?proto.pb.AccessTokenInfo|undefined} value */
proto.pb.ValidateTokenResponse.prototype.setAccessTokenInfo = function (value) {
    jspb.Message.setWrapperField(this, 1, value);
};

proto.pb.ValidateTokenResponse.prototype.clearAccessTokenInfo = function () {
    this.setAccessTokenInfo(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pb.ValidateTokenResponse.prototype.hasAccessTokenInfo = function () {
    return jspb.Message.getField(this, 1) != null;
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.RefreshTokensRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.RefreshTokensRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.RefreshTokensRequest.displayName = "proto.pb.RefreshTokensRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.RefreshTokensRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.RefreshTokensRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.RefreshTokensRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.RefreshTokensRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                refreshToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.RefreshTokensRequest}
 */
proto.pb.RefreshTokensRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.RefreshTokensRequest();
    return proto.pb.RefreshTokensRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.RefreshTokensRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.RefreshTokensRequest}
 */
proto.pb.RefreshTokensRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setRefreshToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.RefreshTokensRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.RefreshTokensRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.RefreshTokensRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RefreshTokensRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRefreshToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};

/**
 * optional string refresh_token = 1;
 * @return {string}
 */
proto.pb.RefreshTokensRequest.prototype.getRefreshToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.RefreshTokensRequest.prototype.setRefreshToken = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.RefreshTokensResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.RefreshTokensResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.RefreshTokensResponse.displayName = "proto.pb.RefreshTokensResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.RefreshTokensResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.RefreshTokensResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.RefreshTokensResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.RefreshTokensResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                tokens: (f = msg.getTokens()) && proto.pb.Tokens.toObject(includeInstance, f),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.RefreshTokensResponse}
 */
proto.pb.RefreshTokensResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.RefreshTokensResponse();
    return proto.pb.RefreshTokensResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.RefreshTokensResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.RefreshTokensResponse}
 */
proto.pb.RefreshTokensResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = new proto.pb.Tokens();
                reader.readMessage(value, proto.pb.Tokens.deserializeBinaryFromReader);
                msg.setTokens(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.RefreshTokensResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.RefreshTokensResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.RefreshTokensResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RefreshTokensResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getTokens();
    if (f != null) {
        writer.writeMessage(1, f, proto.pb.Tokens.serializeBinaryToWriter);
    }
};

/**
 * optional Tokens tokens = 1;
 * @return {?proto.pb.Tokens}
 */
proto.pb.RefreshTokensResponse.prototype.getTokens = function () {
    return /** @type{?proto.pb.Tokens} */ (jspb.Message.getWrapperField(this, proto.pb.Tokens, 1));
};

/** @param {?proto.pb.Tokens|undefined} value */
proto.pb.RefreshTokensResponse.prototype.setTokens = function (value) {
    jspb.Message.setWrapperField(this, 1, value);
};

proto.pb.RefreshTokensResponse.prototype.clearTokens = function () {
    this.setTokens(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pb.RefreshTokensResponse.prototype.hasTokens = function () {
    return jspb.Message.getField(this, 1) != null;
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.LogoutRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.LogoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.LogoutRequest.displayName = "proto.pb.LogoutRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.LogoutRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.LogoutRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.LogoutRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.LogoutRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                refreshToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.LogoutRequest}
 */
proto.pb.LogoutRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.LogoutRequest();
    return proto.pb.LogoutRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.LogoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.LogoutRequest}
 */
proto.pb.LogoutRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setRefreshToken(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.LogoutRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.LogoutRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.LogoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.LogoutRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getRefreshToken();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};

/**
 * optional string refresh_token = 1;
 * @return {string}
 */
proto.pb.LogoutRequest.prototype.getRefreshToken = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.LogoutRequest.prototype.setRefreshToken = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.LogoutResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.LogoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.LogoutResponse.displayName = "proto.pb.LogoutResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.LogoutResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.LogoutResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.LogoutResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.LogoutResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                success: jspb.Message.getFieldWithDefault(msg, 1, false),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.LogoutResponse}
 */
proto.pb.LogoutResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.LogoutResponse();
    return proto.pb.LogoutResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.LogoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.LogoutResponse}
 */
proto.pb.LogoutResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setSuccess(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.LogoutResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.LogoutResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.LogoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.LogoutResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSuccess();
    if (f) {
        writer.writeBool(1, f);
    }
};

/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.LogoutResponse.prototype.getSuccess = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};

/** @param {boolean} value */
proto.pb.LogoutResponse.prototype.setSuccess = function (value) {
    jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.RecoverPasswordRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.RecoverPasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.RecoverPasswordRequest.displayName = "proto.pb.RecoverPasswordRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.RecoverPasswordRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.RecoverPasswordRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.RecoverPasswordRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.RecoverPasswordRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                email: jspb.Message.getFieldWithDefault(msg, 1, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.RecoverPasswordRequest}
 */
proto.pb.RecoverPasswordRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.RecoverPasswordRequest();
    return proto.pb.RecoverPasswordRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.RecoverPasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.RecoverPasswordRequest}
 */
proto.pb.RecoverPasswordRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setEmail(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.RecoverPasswordRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.RecoverPasswordRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.RecoverPasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RecoverPasswordRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getEmail();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};

/**
 * optional string email = 1;
 * @return {string}
 */
proto.pb.RecoverPasswordRequest.prototype.getEmail = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.RecoverPasswordRequest.prototype.setEmail = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.RecoverPasswordResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.RecoverPasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.RecoverPasswordResponse.displayName = "proto.pb.RecoverPasswordResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.RecoverPasswordResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.RecoverPasswordResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.RecoverPasswordResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.RecoverPasswordResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                success: jspb.Message.getFieldWithDefault(msg, 1, false),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.RecoverPasswordResponse}
 */
proto.pb.RecoverPasswordResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.RecoverPasswordResponse();
    return proto.pb.RecoverPasswordResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.RecoverPasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.RecoverPasswordResponse}
 */
proto.pb.RecoverPasswordResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setSuccess(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.RecoverPasswordResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.RecoverPasswordResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.RecoverPasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.RecoverPasswordResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSuccess();
    if (f) {
        writer.writeBool(1, f);
    }
};

/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.RecoverPasswordResponse.prototype.getSuccess = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};

/** @param {boolean} value */
proto.pb.RecoverPasswordResponse.prototype.setSuccess = function (value) {
    jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.VerifyPasswordRecoveryRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.VerifyPasswordRecoveryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.VerifyPasswordRecoveryRequest.displayName = "proto.pb.VerifyPasswordRecoveryRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.VerifyPasswordRecoveryRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.VerifyPasswordRecoveryRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.VerifyPasswordRecoveryRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.VerifyPasswordRecoveryRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                id: jspb.Message.getFieldWithDefault(msg, 1, ""),
                magicNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
                newPassword: jspb.Message.getFieldWithDefault(msg, 3, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.VerifyPasswordRecoveryRequest}
 */
proto.pb.VerifyPasswordRecoveryRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.VerifyPasswordRecoveryRequest();
    return proto.pb.VerifyPasswordRecoveryRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.VerifyPasswordRecoveryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.VerifyPasswordRecoveryRequest}
 */
proto.pb.VerifyPasswordRecoveryRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());
                msg.setId(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt32());
                msg.setMagicNumber(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());
                msg.setNewPassword(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.VerifyPasswordRecoveryRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.VerifyPasswordRecoveryRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.VerifyPasswordRecoveryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.VerifyPasswordRecoveryRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getId();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
    f = message.getMagicNumber();
    if (f !== 0) {
        writer.writeInt32(2, f);
    }
    f = message.getNewPassword();
    if (f.length > 0) {
        writer.writeString(3, f);
    }
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.pb.VerifyPasswordRecoveryRequest.prototype.getId = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.VerifyPasswordRecoveryRequest.prototype.setId = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional int32 magic_number = 2;
 * @return {number}
 */
proto.pb.VerifyPasswordRecoveryRequest.prototype.getMagicNumber = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/** @param {number} value */
proto.pb.VerifyPasswordRecoveryRequest.prototype.setMagicNumber = function (value) {
    jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional string new_password = 3;
 * @return {string}
 */
proto.pb.VerifyPasswordRecoveryRequest.prototype.getNewPassword = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/** @param {string} value */
proto.pb.VerifyPasswordRecoveryRequest.prototype.setNewPassword = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.VerifyPasswordRecoveryResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.pb.VerifyPasswordRecoveryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.VerifyPasswordRecoveryResponse.displayName = "proto.pb.VerifyPasswordRecoveryResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.VerifyPasswordRecoveryResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.VerifyPasswordRecoveryResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.VerifyPasswordRecoveryResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.VerifyPasswordRecoveryResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                success: jspb.Message.getFieldWithDefault(msg, 1, false),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }
        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.VerifyPasswordRecoveryResponse}
 */
proto.pb.VerifyPasswordRecoveryResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);
    var msg = new proto.pb.VerifyPasswordRecoveryResponse();
    return proto.pb.VerifyPasswordRecoveryResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.VerifyPasswordRecoveryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.VerifyPasswordRecoveryResponse}
 */
proto.pb.VerifyPasswordRecoveryResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }
        var field = reader.getFieldNumber();
        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());
                msg.setSuccess(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }
    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.VerifyPasswordRecoveryResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();
    proto.pb.VerifyPasswordRecoveryResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.VerifyPasswordRecoveryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.VerifyPasswordRecoveryResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
    f = message.getSuccess();
    if (f) {
        writer.writeBool(1, f);
    }
};

/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.VerifyPasswordRecoveryResponse.prototype.getSuccess = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};

/** @param {boolean} value */
proto.pb.VerifyPasswordRecoveryResponse.prototype.setSuccess = function (value) {
    jspb.Message.setProto3BooleanField(this, 1, value);
};

goog.object.extend(exports, proto.pb);
