import { Box, Typography } from "@mui/material";
import SButton from "components/UI/SButton";
import React from "react";
import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import * as textStyles from "themes/TextThemes";
import * as styles from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/Modals/LoginModalsThemes";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import UserStore from "store/UserStore";
import DefaultProfile from "assets/PAAssets/JPG/profile.jpg";
import { useMedias } from "API/mediasHook";

const ModalDeletePhoto = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const Close = () => {
        props.handleDiscard();
    };

    return (
        <ModalSettingsSmall
            visible={props.open}
            handleClose={props.handleDiscard}
        >
            <Box sx={styles.ModalDeletePhoto}>
                <Typography
                    sx={textStyles.HeadlineH41Black}
                    style={styles.ModalTitlePhoneChange(medias)}
                >
                    {t("main.settings.loginDetails.deleteProfilePhotoTitle")}
                </Typography>
                <Box
                    container
                    sx={styles.ButtonsContainer(medias)}
                >
                    <SButton
                        style={styles.ButtonSize(medias)}
                        padding="10px 20px 11px 20px"
                        variant="secondary"
                        onClick={Close}
                    >
                        {t("main.settings.loginDetails.deleteProfilePhotoNo")}
                    </SButton>
                    <SButton
                        style={styles.ButtonSize(medias)}
                        padding="10px 20px 11px 20px"
                        variant="unshadowed"
                        onClick={() => {
                            UserStore.setPhoto(DefaultProfile);
                            Close();
                        }}
                    >
                        {t("main.settings.loginDetails.deleteProfilePhotoYes")}
                    </SButton>
                </Box>
            </Box>
        </ModalSettingsSmall>
    );
});

export default ModalDeletePhoto;
