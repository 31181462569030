import { createTheme } from "@mui/material/styles";

export const DialogContainerTheme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: "rgba(0, 0, 0, 0)!important",
                    boxShadow: "none!important",
                    maxWidth: "fit-content",
                },
            },
        },
    },
});

export const dialogContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "10px",
};

export const cardContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
    padding: "40px 40px 30px 40px",
    flexDirection: "column",
    gap: "30px",
    borderRadius: "20px",
    background: "var(--main-white, #FFF)",
    width: 410,
    height: "fit-content",
};

export const headerBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    width: "100%",
};

export const headerTextStyle = {
    color: "var(--text-black, #222)",
    textAlign: "center",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "140%",
};

export const contentBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "20px",
    width: "100%",
};

export const contentTextStyle = {
    color: "var(--text-black, #222)",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const ModalActionButtonTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    display: "flex",
                    padding: "10px 20px 11px 20px!important",
                    width: "fit-content",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "42px!important",
                    background: "var(--main-purple, #6212FF)",
                    "&:hover": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                        ".MuiTypography-root": {
                            color: "white!important",
                        },
                    },
                    "&:active": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                        ".MuiTypography-root": {
                            color: "white!important",
                        },
                    },
                },
            },
        },
    },
});

export const buttonTextStyle = {
    textTransform: "none",
    color: "var(--text-white, #FFF)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
};

export const actionsBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
};

export const actionsContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "center",
};

export const CloseIconTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid #9E9EB0",
                    borderRadius: "50%",
                    background: "transparent",
                    width: "24px",
                    height: "24px",
                    "&:hover": {
                        background: "#transparent!important",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "#transparent!important",
                        borderColor: "#6212FF",
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    padding: 0,
                    color: "#FFFFFF",
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    "&:hover": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                    "&:active": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                },
            },
        },
    },
});

export const backToLessonsTextStyle = {
    color: "var(--Text-Dark-Grey, #67677A)",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
    cursor: "pointer",
    "&:hover": {
        color: "#6212FF",
    },
};
