export const TextBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
};

export const Button = {
    width: "289px",
};

export const TextBlockContent = (condition) => {
    return {
        paddingTop: condition ? "0px" : "60px",
        maxWidth: "500px",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
    };
};
