export const Paper = {
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
    borderRadius: "10px",
    padding: "5px 4px",
    width: "fit-content",
};

export const ListItemButtonEdit = {
    width: "fit-content",
    whiteSpace: "nowrap",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "7px 10px 6px 10px",
    ":hover": {
        color: "#222",
        background: "rgba(187, 187, 200, 0.17)",
    },
};

export const ListItemButtonCancel = {
    whiteSpace: "nowrap",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "7px 10px 6px 10px",
    ":hover": {
        color: "#E12626",
        background: "rgba(187, 187, 200, 0.17)",
    },
};
