export const Wrapper = {
    borderBottom: "1px solid rgba(0, 0, 0, 0.10)",
    paddingBottom: "5px",
    paddingTop: "4px",
};

export const ListItemButton = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "12px 26px 5px 19px",
    borderRadius: "12px",
    ":hover": {
        bgcolor: "rgba(187, 187, 200, 0.17)",
    },
};

export const BoxItemWrapper = {
    display: "flex",
    alignItems: "flex-start", //,border: '1px solid blue'
}; //!!!};

export const Bage = {
    "& .MuiBadge-badge": {
        backgroundColor: "#6212FF",
    },
};

export const ListItemAvatar = { minWidth: 0, mr: "10px" };

export const Avatar = { width: "32px", height: "32px", bgcolor: "#fff" };

export const ImgPos = { display: "flex", justifyContent: "end" };

export const SButtonSize = (medias) => {
    return {
        width: medias.sm ? "172px" : "132px",
        borderRadius: "30px",
    };
};

export const InfoTimeWrapper = {
    marginTop: "2px",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
};

export const InfoTime = {
    color: "#9E9EB0",
    margin: 0,
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "140%",
    marginRight: "3%",
};

export const ButtonsBlock = {
    display: "flex",
    gap: "16px",
};

export const RescheduleText = {
    display: "flex",
    flexDirection: "column",
    gap: 0,
};

export const RescheduleBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const NotificationText = {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
};

export const RescheduleDecision = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
};

export const DecisionIcon = {
    height: "20px",
};
