import React from "react";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { memo } from "react";
import Error400BodyBlock from "components/ErrorsBodyBlock/Error400BodyBlock/Error400BodyBlock";

const PageError404 = ({ children, ...props }) => {
    return (
        <ScrollToTop>
            <Error400BodyBlock />
        </ScrollToTop>
    );
};

export default memo(PageError404);
