import React from "react";
import { Popper } from "@mui/material";
import MiniChatStore from "store/MiniChatStore";
import MiniChat from "components/MiniChat/MiniChat";
import HeaderAccount from "./HeaderAccount/HeaderAccount";
import HeaderNavigation from "./HeaderNavigation/HeaderNavigation";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";

const Header = () => {
    const location = useLocation();

    return (
        <>
            <Popper
                open={MiniChatStore.Open}
                sx={{ zIndex: "1" }}
            >
                <MiniChat />
            </Popper>
            <HeaderAccount />
            {location.pathname !== "/payment" &&
            location.pathname !== "/video-chat" &&
            location.pathname !== "/authorization" &&
            location.pathname !== "/questionnaire" &&
            location.pathname !== "/buddyrules" ? (
                <Box sx={{ height: "70px" }}></Box>
            ) : null}
            <HeaderNavigation />
        </>
    );
};

export default observer(Header);
