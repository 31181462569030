import { styleProcessing } from "API/plugins/Utilities";

export const ActionButtonContent = {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    maxWidth: "609px",
};

export const ActionButton = {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
};

export const ActionButtonsContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const SmallItem = (theme) => {
    let commonStyle = {
        backgroundColor: "white",
        borderRadius: "20px",
        padding: "30px",
        maxWidth: "641px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
