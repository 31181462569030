import React from "react";
import { observer } from "mobx-react-lite";

import { Stack, Typography, styled } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import StyledDiplomaTooltip from "components/DiplomaChats/StyledComponents/StyledDiplomaTooltip";

import UserStore from "store/UserStore";
import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";
import { ROLE_STATUSES } from "components/DiplomaChats/store/statuses";

const StyledDotIcon = styled(CircleIcon)(({ color }) => ({
    color: color ? color : "#6212FF",
    fontSize: "6px",
}));

const OrderStatusInfoWithTooltips = () => {
    const statusInfo = ROLE_STATUSES[UserStore.role].filter((status) => status.id === DiplomaStore.status)[0];

    return (
        <Stack
            direction="row"
            spacing={0.725}
            justifyContent="flex-start"
            alignItems="center"
            sx={{ width: "100%" }}
        >
            <Typography variant="title">{statusInfo.text}</Typography>
            <StyledDotIcon color={statusInfo.color} />
            {DiplomaStore.status !== "ended" ? (
                <StyledDiplomaTooltip
                    PopperProps={{
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [0, 2],
                                },
                            },
                        ],
                    }}
                    placement="top"
                    arrow
                    leaveDelay={600}
                    title={statusInfo.tooltipText}
                >
                    <InfoOutlinedIcon
                        sx={{
                            fontSize: "20px",
                            color: "rgba(119, 119, 138, 1)",
                        }}
                    />
                </StyledDiplomaTooltip>
            ) : null}
        </Stack>
    );
};

export default observer(OrderStatusInfoWithTooltips);
