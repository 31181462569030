import { createTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

export const FiltersGoalTheme = (medias) =>
    createTheme({
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": medias.sm ? "#ffffff00" : "#9E9EB0",
                        "--TextField-brandBorderHoverColor": "#6212FF",
                        "--TextField-brandBorderFocusedColor": "#6212FF",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                        "& .Mui-focused": {
                            background: "#FFFFFF",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&:before, &:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&:before": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },

            MuiAutocomplete: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        ".MuiAutocomplete-input": {
                            padding: "2.5px 4px 2px 10px!important",
                        },
                        ".MuiAutocomplete-clearIndicator:hover": {
                            background: "#FFFFFF",
                            color: "#6212FF",
                        },
                        ".MuiAutocomplete-popupIndicator:hover": {
                            background: "#FFFFFF",
                            color: "#6212FF",
                        },
                    },
                    option: {
                        paddingTop: "6px!important",
                        paddingBottom: "6px!important",
                        '&[aria-selected="true"]': {
                            color: "#6212FF",
                        },
                        "&:hover": {
                            color: "#6212FF",
                        },
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "130%",
                        fontFeatureSettings: "'tnum' on, 'lnum' on",
                        color: "#222222",
                        flex: "none",
                        order: 0,
                        flexGrow: 0,
                    },
                },
            },

            MuiInputBase: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        boxSizing: "border-box",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "13px 16px",
                        gap: "4px",
                        width: medias.sm ? "279px" : "328px",
                        height: medias.sm ? "60px" : "44px",
                        marginTop: "-1px",
                        marginLeft: "-1px",
                        flex: "none",
                        order: 1,
                        flexGrow: 0,
                        "&.MuiInputBase-root": {
                            border: "1px!important solid #9E9EB0",
                            borderRadius: "12px",
                            display: "flex",
                            alignItems: medias.sm ? "flex-end" : "center",
                        },
                        ".MuiAutocomplete-endAdornment": {
                            marginTop: medias.sm ? "15px" : "0px",
                        },
                        ".MuiSvgIcon-root": {
                            width: "16px",
                            height: "16px",
                        },
                        ".MuiInputBase-input": {
                            width: "-webkit-fill-available!important",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "14px",
                            lineHeight: "130%",
                            fontFeatureSettings: "'tnum' on, 'lnum' on",
                            color: "#222222",
                            flex: "none",
                            order: 0,
                            flexGrow: 0,
                        },
                    },
                },
            },
        },
    });

export const filtersGoalLabel = {
    marginLeft: "16px",
    paddingTop: "8px",
    position: "absolute",
    zIndex: 1,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "150%",
    color: "#67677A",
};

export const filtersGOContainer = {
    backgroundColor: "white",
};

export const FiltersRadioContainerTheme = createTheme({
    padding: "0 12px 0 0",
});
