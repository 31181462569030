export const Input = {
    border: "none",
    width: "100%",
    outline: "none",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "150%",
};

export const Dropdown = {
    border: "none",
};

export const Button = {
    border: "none",
    background: "transparent",
};

export const Box = (condition, helper) => {
    return {
        borderBottom: helper ? "1px solid #E12626" : condition ? "1px solid #6212FF" : "1px solid #BBBBC8",
        paddingBottom: "2px",
    };
};

export const PhoneBox = {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
};
