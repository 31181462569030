import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import TopFilterCard from "./TopFilterCard/TopFilterCard";
import BodyCard from "./BodyCard/BodyCard";
import FiltersDrawer from "./FiltersDrawer/FiltersDrawer";

const CatalogBodyBlock = observer(({ children, ...props }) => {
    return (
        <>
            <TopFilterCard></TopFilterCard>
            <BodyCard></BodyCard>
            <FiltersDrawer></FiltersDrawer>
        </>
    );
});

export default memo(CatalogBodyBlock);
