export const LangCurrPaper = {
    borderRadius: "20px",
    width: "190px",
    border: "1px solid #E6E5EA",
    boxShadow: "none",
    p: 0,
    mt: "10px",
};

export const CityButton = {
    padding: 0,
    margin: 0,
    textTransform: "none",
    gap: "5px",
    ":hover": {
        background: "rgba(255, 255, 255, 0.00)",
        color: "#222",
    },
};
