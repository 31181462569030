import React from "react";

const MirIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="30"
            viewBox="0 0 48 30"
            fill="none"
        >
            <rect
                x="0.5"
                y="0.5"
                width="47"
                height="29"
                rx="4.5"
                stroke="#DADADF"
            />
            <path
                d="M37.7111 10H31.5508C31.9096 11.9886 33.8833 13.8602 36.0365 13.8602H40.9408C41.0006 13.6847 41.0006 13.3923 41.0006 13.2168C41.0006 11.4622 39.5054 10 37.7111 10Z"
                fill="url(#paint0_linear_542_22344)"
            />
            <path
                d="M32.0879 14.209V19.0634H35.0783V16.49H37.7099C39.1453 16.49 40.4013 15.4957 40.82 14.209H32.0879Z"
                fill="#4FAD50"
            />
            <path
                d="M21.5605 10V19.0071H24.1922C24.1922 19.0071 24.85 19.0071 25.2089 18.3637C27.0032 14.9129 27.5414 13.8602 27.5414 13.8602H27.9003V19.0071H30.8908V10H28.2592C28.2592 10 27.6013 10.0585 27.2424 10.6434C25.7472 13.6262 24.9099 15.1469 24.9099 15.1469H24.551V10H21.5605Z"
                fill="#4FAD50"
            />
            <path
                d="M9 19.0617V10.0547H11.9904C11.9904 10.0547 12.8278 10.0547 13.3063 11.3414C14.5024 14.7337 14.6221 15.2016 14.6221 15.2016C14.6221 15.2016 14.8613 14.3828 15.9378 11.3414C16.4163 10.0547 17.2536 10.0547 17.2536 10.0547H20.2441V19.0617H17.2536V14.2073H16.8948L15.2201 19.0617H13.9043L12.2297 14.2073H11.8708V19.0617H9Z"
                fill="#4FAD50"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_542_22344"
                    x1="31.5401"
                    y1="11.9578"
                    x2="40.9794"
                    y2="11.9578"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#27B1E6" />
                    <stop
                        offset="1"
                        stopColor="#148ACA"
                    />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default MirIcon;
