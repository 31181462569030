import { Tab, Typography, Tabs } from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import * as styles from "./TabsThemes";
import AuthorizationStore from "store/AuthorizationStore";

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Typography>{children}</Typography>}
        </Box>
    );
}

export function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const SignInTabs = observer(({ children, ...props }) => {
    const handleChangeTab = (event, newValue) => {
        AuthorizationStore.setSignInTab(newValue);
        // setValue('')
        // setButtonDis(true)
    };

    return (
        <Tabs
            value={AuthorizationStore.signInTab}
            sx={styles.Tabs}
            onChange={handleChangeTab}
            TabIndicatorProps={styles.TabsIndicatorProps}
        >
            <Tab
                sx={styles.Tab}
                disableRipple
                label="Номер телефона"
                {...a11yProps(0)}
            />
            <Tab
                sx={styles.Tab}
                disableRipple
                label="Электронная почта"
                {...a11yProps(1)}
                disabled={true}
            />
        </Tabs>
    );
});

export default memo(SignInTabs);
