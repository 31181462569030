export const IncomeCalculatorBlock = (medias) => {
    return {
        borderRadius: "25px",
        background: "#FFF",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: medias.sm ? "50px" : "30px 16px 30px 16px",
        width: medias.sm ? "500px" : "321.592px",
        boxSizing: medias.sm ? "unset" : "border-box",
    };
};

export const TitleBlock = (medias) => {
    return {
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        maxWidth: medias.sm ? "498px" : "296px",
    };
};

export const Header = (medias) => {
    return {
        color: "#222",
        fontFamily: "Inter",
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "135%" /* 54px */,

        marginTop: "50px",
        marginLeft: "50px",
        width: "498px",
        height: "162px",
    };
};

export const inputBlock = (medias) => {
    return {
        display: "flex",
        gap: medias.sm ? "20px" : "16px",
        flexDirection: medias.sm ? "unset" : "column",
    };
};

export const lessonInputBlock = (medias) => {
    return {
        width: medias.sm ? "239px" : "280px",
        borderRadius: "12px",
        border: "1px solid var(--text-grey, #9E9EB0)",
    };
};

export const counterBlock = {
    display: "flex",
    alignItems: "center",
    gap: "12px",
};

export const miniNote = {
    width: "498px",
    color: "var(--text-grey, #9E9EB0)",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%" /* 21px */,
    marginTop: "12px",
    marginLeft: "50px",
};

export const Note = {
    width: "498px",
    marginTop: "12px",
    marginLeft: "50px",
};

export const SignUpButton = (medias) => {
    return {
        padding: "16px 24px 17px 24px",
        width: medias.sm ? "498px" : "296px",
    };
};

export const LinkButton = {
    cursor: "pointer",
};

export const ButtonBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
};

export const ContentBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};
