import React from "react";
import { observer } from "mobx-react-lite";

import { Box } from "@mui/material";

import WithLinks from "./WithLinks";

import { useGetChatAttachments } from "hooks/useGetChatAttachments";

import { imageAttachmentsContainer } from "../ImageChatAttachments/ImageChatAttachmentsThemes";

const Urls = () => {
    const sortedUrls = useGetChatAttachments("urls");

    return <Box style={imageAttachmentsContainer}>{!Object.keys(sortedUrls).length ? null : <WithLinks urls={sortedUrls} />}</Box>;
};

export default observer(Urls);
