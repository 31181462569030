import React from "react";
import { observer } from "mobx-react-lite";
import VerticalLinearStepper from "./Stepper/Stepper";
import store from "store/RequestStore";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import * as textStyles from "themes/TextThemes";
import * as styles from "./LeftPanelThemes";

const LeftPanel = function () {
    const { t, i18n } = useTranslation();

    return (
        <Box>
            <Box sx={styles.OrderBox}>
                <Typography sx={textStyles.Button16Black}>{t("main.searchRequest.leftPanel.orderCreation")}</Typography>
            </Box>
            <VerticalLinearStepper
                steplist={store.storage}
                activeStep={store.stepperIndex}
                subStep={store.subStepIndex}
                type={store.type}
                switchType={store.switchType}
            />
            <Box sx={styles.SpecialistsBox}>
                <Typography sx={textStyles.Button16Black}>{t("main.searchRequest.leftPanel.specialists")}</Typography>
                <Box sx={styles.Specialists}>
                    <Typography sx={textStyles.Button14White}>125 130</Typography>
                </Box>
            </Box>
            <Box sx={styles.ServiceRules}>
                <Typography sx={textStyles.Button16Black}>{t("main.searchRequest.leftPanel.serviceRules")}</Typography>
            </Box>
        </Box>
    );
};

export default observer(LeftPanel);
