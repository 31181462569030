//C чем нужно помочь?
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import SBAutoComplete from "components/UI/Autocomplete";
import SBButtonGroup from "components/UI/ButtonGroup";

import store from "store/RequestStore";
import { workNames } from "API/dictionaries/const_data";
import * as theme from "./Step1Themes";

import { Box } from "@mui/material";
import RequestNullAlert from "./RequestNullAlert";

const Step1 = () => {
    const [alert, setAlert] = useState(0);

    const [stepData, setStepData] = useState(store.storage[store.activeStepIndex].substeps[store.subStepIndex].value);

    useEffect(() => {
        if (stepData.toLowerCase().includes("написание")) {
            store.type = "work";
            store.setTooltip(0, {
                text: "Мы сохраним анонимность вашего заказа и анкеты исполнителя, вы сможете ознакомиться с опытом, работами и отзывами подходящих исполнителей",
                icon: 6,
            });
        } else {
            store.type = "tutor";
            store.setTooltip(0, {
                text: "Если вам нужна помощь по нескольким задачам, то для каждой нужно создать свой заказ, и вы получите отклики репетиторов с нужным опытом",
                icon: 1,
            });
        }
    }, [stepData]);

    return (
        <>
            <Box sx={theme.Wrapper}>
                <SBAutoComplete
                    value={stepData}
                    changefunc={setStepData}
                    inputvalue={stepData}
                    inputchangefunc={setStepData}
                    list={workNames}
                    placeholder="Например репетитор по математике"
                    page={1}
                />
            </Box>
            <SBButtonGroup
                handleNext={() => {
                    if (stepData) {
                        store.setStep(0, stepData);
                        store.setStepValue(0, stepData);
                        store.incrementStepIndex();
                    } else {
                        setAlert(1);
                    }
                }}
                handleBack={() => store.decrementStepIndex()}
                activeStep={store.activeStepIndex}
                subStep={store.subStepIndex}
                steps={store.storage}
            />
            {alert === 1 ? <RequestNullAlert>Чтобы перейти к следующему вопросу, заполните это поле</RequestNullAlert> : null}
        </>
    );
};

export default observer(Step1);
