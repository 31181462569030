import { memo } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./RateConnectionThemes";
import Box from "@mui/material/Box";
import { Divider, Rating, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import StarFilledIcon from "assets/VideoChatAssets/SVG/starFilledIcon";
import StarOutlinedIcon from "assets/VideoChatAssets/SVG/starOutlinedIcon";
import VideoChatStore from "store/VideoChatStore";

const RateConnection = observer(({ children, ...props }) => {
    const processingConnectionFeedbackRating = (event) => {
        VideoChatStore.SetConnectionFeedbackRating(Number(event.target.value));
    };

    return (
        <Box sx={themes.ratingContainerStyle}>
            <Divider sx={themes.dividerStyle(0, 0)}></Divider>
            <Typography sx={themes.headerTextStyle}>Как бы вы оценили качество видеосвязи?</Typography>
            <ThemeProvider theme={themes.RatingTheme}>
                <Rating
                    value={VideoChatStore.platformFeedback.connectionFeedbackRating}
                    onChange={processingConnectionFeedbackRating}
                    icon={<StarFilledIcon fontSize="inherit" />}
                    emptyIcon={<StarOutlinedIcon fontSize="inherit" />}
                />
            </ThemeProvider>
        </Box>
    );
});

export default memo(RateConnection);
