import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import { Button, Drawer, IconButton, Typography } from "@mui/material";
import CatalogStore from "store/CatalogStore";
import * as themes from "./FiltersDrawerThemes";
import { ThemeProvider } from "@mui/material/styles";
import { CloseRounded } from "@mui/icons-material";
import LessonsFormat from "./LessonsFormat";
import LessonsPerWeek from "./LessonsPerWeek";
import SelectedFiltersTags from "./SelectedFiltersTags";
import LessonsPlace from "./LessonsPlace";
import LessonsEdu from "./LessonsEdu";
import LessonsTeacherSex from "./LessonsTeacherSex";
import LessonsTeacherAge from "./LessonsTeacherAge";
import LessonsDayTime from "./LessonsDayTime";
import { Box } from "@mui/material";
import { useMedias } from "../../../API/mediasHook";

const FiltersDrawer = observer(({ children, ...props }) => {
    const closeDrawer = () => {
        CatalogStore.ToggleDrawer();
    };

    const search = async () => {
        await CatalogStore.ExecuteSearch();
        closeDrawer();
    };

    const medias = useMedias();

    return (
        <ThemeProvider theme={themes.DrawerTheme(medias)}>
            <Drawer
                anchor="right"
                open={CatalogStore.drawerOpened}
                onClose={closeDrawer}
            >
                <Box sx={themes.drawerContainerStyle}>
                    <Box sx={themes.headerContainerStyle}>
                        <Typography sx={themes.textStyle}>Фильтры</Typography>
                        <ThemeProvider theme={themes.CloseIconTheme(medias)}>
                            <IconButton
                                onClick={closeDrawer}
                                disableRipple
                            >
                                <CloseRounded></CloseRounded>
                            </IconButton>
                        </ThemeProvider>
                    </Box>
                    {CatalogStore.selectedChipsList.length > 0 ? <SelectedFiltersTags></SelectedFiltersTags> : null}
                    <LessonsFormat></LessonsFormat>
                    <LessonsPlace></LessonsPlace>
                    <LessonsPerWeek></LessonsPerWeek>
                    <LessonsDayTime></LessonsDayTime>
                    <LessonsEdu></LessonsEdu>
                    <LessonsTeacherSex></LessonsTeacherSex>
                    <LessonsTeacherAge></LessonsTeacherAge>
                    <ThemeProvider theme={themes.SearchButtonTheme(medias)}>
                        <Button
                            onClick={search}
                            disableRipple
                        >
                            <Typography sx={themes.searchTextStyle}>Показать репетиторов</Typography>
                        </Button>
                    </ThemeProvider>
                </Box>
            </Drawer>
        </ThemeProvider>
    );
});

export default memo(FiltersDrawer);
