import OrderSnack from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/OrdersSnack/OrderSnack";
import * as themes from "./OrderSwitchThemes";
import React, { memo, useState } from "react";
import IOSSwitch from "components/UI/IOSSwitch";
import FormControlLabel from "@mui/material/FormControlLabel";
import StudentOrdersStore from "store/StudentOrdersStore";
import { observer } from "mobx-react-lite";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as textThemes from "themes/TextThemes";
import OpenOrderModal from "./OpenOrderModal/OpenOrderModal";

const OrderSwitch = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const [openSnack, setOpenSnack] = useState(false);

    //Open order
    const [dialogOpenOrderOpen, SetDialogOpenOrderOpen] = useState("");

    const showOpenOrderDialog = () => {
        SetDialogOpenOrderOpen(true);
    };

    const discardOpenOrderDialog = () => {
        SetDialogOpenOrderOpen(false);
    };

    const saveOpenOrderDialog = () => {
        SetDialogOpenOrderOpen(false);
        StudentOrdersStore.setIsOpened(props.order, true);
        StudentOrdersStore.setIsPersonal(props.order, false);
    };

    const handleSwitchOrder = () => {
        if (props.order.baseOrderInfo.isPersonal && !props.order.baseOrderInfo.isOpened) {
            showOpenOrderDialog();
        } else {
            setOpenSnack(false);
            setTimeout(() => {
                setOpenSnack(true);
            }, 100);
            StudentOrdersStore.setIsOpened(props.order, !props.order.baseOrderInfo.isOpened);
        }
    };

    return (
        <>
            <Box sx={themes.SwitchBlock}>
                <FormControlLabel
                    sx={themes.FormControl}
                    control={
                        <IOSSwitch
                            sx={themes.IOSSwitch}
                            onChange={handleSwitchOrder}
                            checked={props.order.baseOrderInfo.isOpened}
                        />
                    }
                />
                <Typography sx={textThemes.Body2Black}>{props.order.baseOrderInfo.isOpened ? t("orders.studentOrders.openForResponses") : t("orders.studentOrders.closeForResponses")}</Typography>
            </Box>
            <OrderSnack
                order={props.order}
                openSnack={openSnack}
                setOpenSnack={setOpenSnack}
            />
            <OpenOrderModal
                order={props.order}
                open={dialogOpenOrderOpen}
                handleDiscard={discardOpenOrderDialog}
                handleSave={saveOpenOrderDialog}
            />
        </>
    );
});

export default memo(OrderSwitch);
