import { createTheme } from "@mui/material/styles";

export const OrderDrawerTheme = createTheme({
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    width: "42.5vw",
                    // maxHeight: "100vh",
                    overflow: "scroll",
                    overflowX: "hidden",
                    background: "#FFFFFF",
                    gap: "10px",
                    padding: "24px 30px",
                },
            },
        },
    },
});

export const OrderheaderContainerStyle = {
    position: "relative",
    display: "flex",
    alignItems: "center",
    color: "#9E9EB0",
};

export const OrdertextStyle = {
    position: "relative",
    height: "34px",
    marginBottom: "10px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "140%",
    color: "#000000",
};

export const OrderCloseIconTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid #9E9EB0",
                    borderRadius: "50%",
                    background: "#FFFFFF",
                    position: "relative",
                    width: "24px",
                    height: "24px",
                    marginLeft: "auto",
                    marginRight: "-6px",
                    marginTop: "-24px",
                    "&:hover": {
                        background: "#FFFFFF!important",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "#FFFFFF!important",
                        borderColor: "#6212FF",
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    padding: 0,
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    "&:hover": {
                        color: "#6212FF",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        color: "#6212FF",
                        borderColor: "#6212FF",
                    },
                },
            },
        },
    },
});
