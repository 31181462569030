import { styleProcessing } from "API/plugins/Utilities";

export const CardInactive = (theme) => {
    let commonStyle = {
        width: "392px",
        height: "83px",
        padding: "16px 16px 16px 16px",
        alignItems: "center",
        borderRadius: "14px",
        background: "#F7F7F7",
        boxShadow: "0px 4px 10px 0px rgba(166, 166, 182, 0.20)",
        boxSizing: "border-box",
        display: "inline-flex",
        gap: "16px",
        cursor: "pointer",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "fit-content",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const CardActive = (theme) => {
    let commonStyle = {
        width: "392px",
        height: "83px",
        padding: "16px 16px 16px 16px",
        alignItems: "center",
        borderRadius: "14px",
        background: "#FFF",
        boxShadow: "0px 4px 10px 0px rgba(166, 166, 182, 0.20)",
        boxSizing: "border-box",
        display: "inline-flex",
        gap: "16px",
        cursor: "pointer",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "fit-content",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const CardContent = {
    display: "flex",
    flexDirection: "column",
};

export const CardHeader = {
    color: "#222",
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
};

export const CardDescribe = {
    color: "#222",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
};

export const CardAlwaysWhite = (theme) => {
    let commonStyle = {
        width: "392px",
        height: "83px",
        padding: "16px 16px 16px 16px",
        alignItems: "center",
        borderRadius: "14px",
        background: "#FFF", // Всегда белый цвет
        boxShadow: "0px 4px 10px 0px rgba(166, 166, 182, 0.20)",
        boxSizing: "border-box",
        display: "inline-flex",
        gap: "16px",
        cursor: "pointer",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "fit-content",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Avatar = {
    width: "51px",
    height: "51px",
};
