const StatusAnon = () => {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.4811 6.17074C17.4719 5.68876 17.4632 5.23345 17.4632 4.79297C17.4632 4.62724 17.3974 4.46831 17.2802 4.35113C17.163 4.23394 17.0041 4.16811 16.8383 4.16811C14.166 4.16811 12.1314 3.40012 10.4355 1.75116C10.3188 1.6377 10.1626 1.57422 9.99986 1.57422C9.83715 1.57422 9.68087 1.6377 9.56425 1.75116C7.86846 3.40012 5.83418 4.16811 3.16203 4.16811C2.99631 4.16811 2.83737 4.23394 2.72019 4.35113C2.60301 4.46831 2.53717 4.62724 2.53717 4.79297C2.53717 5.23353 2.52851 5.68901 2.51926 6.17107C2.43361 10.6564 2.31631 16.7992 9.79519 19.3916C9.92773 19.4375 10.0719 19.4375 10.2044 19.3916C17.6839 16.7992 17.5668 10.6561 17.4811 6.17074ZM9.9999 18.1381C3.58577 15.8081 3.68275 10.7038 3.76881 6.1949C3.77398 5.92429 3.77897 5.66202 3.78247 5.40491C6.28524 5.29927 8.28662 4.54086 9.9999 3.04836C11.7133 4.54086 13.7151 5.29935 16.218 5.40491C16.2215 5.66194 16.2265 5.92404 16.2316 6.19448C16.3176 10.7035 16.4145 15.808 9.9999 18.1381Z"
                fill="#FF9212"
            />
            <path
                d="M9.9999 18.1381C3.58577 15.8081 3.68275 10.7038 3.76881 6.1949C3.77398 5.92429 3.77897 5.66202 3.78247 5.40491C6.28524 5.29927 8.28662 4.54086 9.9999 3.04836C11.7133 4.54086 13.7151 5.29935 16.218 5.40491C16.2215 5.66194 16.2265 5.92404 16.2316 6.19448C16.3176 10.7035 16.4145 15.808 9.9999 18.1381Z"
                fill="#FF9212"
            />
            <path
                d="M7.86956 14.5H6.21219L9.22977 5.92857H11.1466L14.1684 14.5H12.511L10.2217 7.68638H10.1547L7.86956 14.5ZM7.92397 11.1392H12.4441V12.3864H7.92397V11.1392Z"
                fill="white"
            />
        </svg>
    );
};

export default StatusAnon;
