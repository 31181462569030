import orderResponseStore from "store/OrderResponseStore";
import SButton from "components/UI/SButton";
import "./CancelModals.css";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
const OrderIsCancelled = ({ hideDialog }) => {
    const navigate = useNavigate();

    const clearState = () => {
        orderResponseStore.setToggleCancelOrderModal(false);
    };

    const clearAndNavigate = () => {
        navigate("/bid");
        clearState();
    };

    const hide = () => {
        hideDialog();
        clearState();
        navigate("/orders");
    };

    return (
        <Box className="CancelModalContent">
            <Box className="CancelModalTitle">Ваш заказ был отменён</Box>
            <Box className="CancelModalMainText">
                Будем ждать от вас новых заказов.
                <br />
                Мы с радостью поможем вам подобрать
                <br />
                репетитора по нужной задаче
            </Box>
            <Box className="CancelModalVericalButtonGroup">
                <SButton
                    onClick={clearAndNavigate}
                    padding="10px 20px 11px 20px"
                    variant="arrow"
                >
                    Создать новый заказ
                </SButton>
                <SButton
                    onClick={hide}
                    textColor="#67677A"
                    padding="10px 20px 11px 20px"
                    variant="text"
                >
                    Вернуться в Мои заказы
                </SButton>
            </Box>
        </Box>
    );
};

export default OrderIsCancelled;
