import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./TitleBlockThemes";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const TitleBlock = observer(({ children, ...props }) => {
    return (
        <Box sx={themes.titleBlockContainerStyle}>
            <Typography
                sx={themes.titleStyle}
                onClick={() => window.open("/order/" + props.value._id, "_blank", "noreferrer")}
            >
                {props.value.OrderTitle}
            </Typography>
            {props.value.IsPersonal && props.styleMode ? (
                <Box sx={themes.personalOrderContainerStyle}>
                    <Typography sx={themes.personalOrderTextStyle}>Персональный заказ</Typography>
                </Box>
            ) : null}
        </Box>
    );
});

export default memo(TitleBlock);
