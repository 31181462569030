import * as React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { Box, Typography } from "@mui/material";

import { convertDataInTabsChat, findChatById } from "../../CommonData/helper_functions";
import * as styles from "components/ChatBodyBlock/GridFieldChat/PartnerPanel/PartnerPanelThemes";
import SButton from "components/UI/SButton";
import PartnerChatMenu from "./PartnerChatMenu/PartnerChatMenu";

import FullChatStore from "store/FullChatStore";

import IconBonusesFilled from "assets/ChatAssets/SVG/iconBonusesFilled";
import IconAvatarBoxChat from "assets/ChatAssets/SVG/IconAvatarBoxChat";
import IconSearchFilter from "assets/ChatAssets/SVG/iconSearchChat";

import * as textStyles from "themes/TextThemes";
import BlockModal from "components/MiniChat/OneChat/BlockModal/BlockModal";

const { format, parseISO } = require("date-fns");

export const GetTime = (time) => {
    if (time === true) return true;

    var date = new Date();

    var diff = (date.getTime() - parseISO(time).getTime()) / 1000 / 60 / 60;

    if (diff > 24)
        return (
            parseISO(time).toLocaleDateString("ru", {
                month: "long",
                day: "numeric",
            }) +
            " в " +
            format(parseISO(time), "HH:mm")
        );
    else return "в " + format(parseISO(time), "HH:mm");
};

const PartnerPanel = ({ sendJsonMessage, avatar }) => {
    const navigate = useNavigate();

    const chatStatuses = ["noResponse", "firstResponse", "secondResponse", "responseWithoutPrice", "priceRequested", "blocked"];

    const [chatStatus, setChatStatus] = React.useState(chatStatuses[3]);

    const [openmodalblock, setOpenModalBlock] = React.useState(false);

    const handleOpenBlock = () => {
        setOpenModalBlock(true);
    };

    const [online, setOnline] = React.useState(true);

    React.useEffect(() => {
        if (FullChatStore.isBlocked !== false) setChatStatus(chatStatuses[5]);
        else setChatStatus(chatStatuses[3]);
    }, [FullChatStore.isBlocked]);

    React.useEffect(() => {
        FullChatStore.tabsData.chats && findChatById(FullChatStore.tabsData.chats, FullChatStore.id).participants_info[0].status.online !== true
            ? setOnline(findChatById(FullChatStore.tabsData.chats, FullChatStore.id).participants_info[0].status.last_seen)
            : setOnline("online");
    }, [FullChatStore.tabsData.chats, FullChatStore.id, FullChatStore.refresh]);

    const renderButtonText = (param) => {
        switch (param) {
            case "noResponse":
                return "Предложить заказ";
            case "firstResponse":
                return "Выбрать 1 250 ₽";
            case "secondResponse":
                return "Записаться";
            case "responseWithoutPrice":
                return "Запросить цену";
            case "priceRequested":
                return "Цена запрошена";
            case "blocked":
                return "Разблокировать";
            default:
                break;
        }
    };

    const renderButtonOnClick = (param) => {
        switch (param) {
            case "noResponse":
                return () => navigate("/bid");
            case "firstResponse":
                return null;
            case "secondResponse":
                return null;
            case "responseWithoutPrice":
                return () => setChatStatus("priceRequested");
            case "priceRequested":
                return null;
            case "blocked":
                return () => setOpenModalBlock(true);
            default:
                break;
        }
    };

    const getName = (chats, id) => {
        var chat = findChatById(chats, id);

        if (chat) return chat.participants_info[0].name + " " + chat.participants_info[0].surname;
        else return null;
    };

    return (
        <Box sx={styles.mainContainer}>
            <Box sx={styles.tutorContainer}>
                <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                        navigate("/tutor/64902a483594e963dd20818a");
                    }}
                >
                    {Object.keys(FullChatStore.tabsData).length > 0 && (
                        <IconAvatarBoxChat
                            isNetwork={online === "online" ? true : false}
                            avatar={FullChatStore.tabsData.chats && "https://study-buddy.ru" + findChatById(FullChatStore.tabsData.chats, FullChatStore.id).participants_info[0].photoLink}
                            tabs={convertDataInTabsChat(FullChatStore.tabsData)}
                        />
                    )}
                </Box>
                <Box sx={styles.tutorNameContainer}>
                    <Typography
                        sx={{ cursor: "pointer" }}
                        style={textStyles.Button16Black}
                        onClick={() => {
                            navigate("/tutor/64902a483594e963dd20818a");
                        }}
                    >
                        {FullChatStore.tabsData.chats && getName(FullChatStore.tabsData.chats, FullChatStore.id)}
                    </Typography>
                    {online === "online" ? <Typography style={textStyles.Caption1DarkGreen}>онлайн</Typography> : <Typography style={textStyles.Caption1Grey}>был в сети {GetTime(online)}</Typography>}
                </Box>
            </Box>

            <Box sx={styles.buttonsContainer}>
                <Box sx={styles.conditionalButtons}>
                    {chatStatus === "firstResponse" ? (
                        <Box sx={styles.bonusesContainer}>
                            <Typography sx={styles.bonusesText}>+ 35 баллов</Typography>
                            <IconBonusesFilled />
                        </Box>
                    ) : chatStatus === "secondResponse" ? (
                        <SButton
                            variant="stroked"
                            padding="7px 16px 8px 16px"
                            borderRadius="30px"
                            onClick={() => {
                                navigate("/bid");
                            }}
                        >
                            Предложить заказ
                        </SButton>
                    ) : null}
                    <SButton
                        variant="chat"
                        padding="7px 16px 8px 16px"
                        disabled={chatStatus === "priceRequested" ? true : false}
                        borderRadius="30px"
                        onClick={renderButtonOnClick(chatStatus)}
                    >
                        {renderButtonText(chatStatus)}
                    </SButton>
                </Box>

                <Box sx={styles.iconButtonsContainer}>
                    <SButton
                        variant="icon"
                        onClick={() => FullChatStore.SetShowSearchMessagesModal(true)}
                    >
                        <IconSearchFilter />
                    </SButton>
                    <BlockModal
                        sendJsonMessage={sendJsonMessage}
                        openmodalblock={openmodalblock}
                        setOpenModalBlock={setOpenModalBlock}
                        name={findChatById(FullChatStore.tabsData.chats, FullChatStore.id) && getName(FullChatStore.tabsData.chats, FullChatStore.id)}
                    />
                    <PartnerChatMenu
                        sendJsonMessage={sendJsonMessage}
                        handleOpenBlock={handleOpenBlock}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default observer(PartnerPanel);
