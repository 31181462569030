const PpsIconSVG = function (props) {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 34.48V5.52C0 2.48 2.48 0 5.52 0H25.576V8.864C25.576 11.904 27.056 13.368 30.096 13.368H40V34.48C40 37.52 37.52 40 34.48 40H5.52C2.48 40 0 37.52 0 34.48ZM30.0962 11.1677C28.8162 11.1677 27.7762 10.1277 27.7762 8.86369V0.879687L39.0082 11.1677H30.0962Z"
                fill="#F16385"
            />
            <path
                d="M9.91797 26.8906V18.8906H13.0742C13.681 18.8906 14.1979 19.0065 14.625 19.2383C15.0521 19.4674 15.3776 19.7865 15.6016 20.1953C15.8281 20.6016 15.9414 21.0703 15.9414 21.6016C15.9414 22.1328 15.8268 22.6016 15.5977 23.0078C15.3685 23.4141 15.0365 23.7305 14.6016 23.957C14.1693 24.1836 13.6458 24.2969 13.0312 24.2969H11.0195V22.9414H12.7578C13.0833 22.9414 13.3516 22.8854 13.5625 22.7734C13.776 22.6589 13.9349 22.5013 14.0391 22.3008C14.1458 22.0977 14.1992 21.8646 14.1992 21.6016C14.1992 21.3359 14.1458 21.1042 14.0391 20.9062C13.9349 20.7057 13.776 20.5508 13.5625 20.4414C13.349 20.3294 13.0781 20.2734 12.75 20.2734H11.6094V26.8906H9.91797Z"
                fill="white"
            />
            <path
                d="M17.59 26.8906V18.8906H20.7463C21.3531 18.8906 21.87 19.0065 22.2971 19.2383C22.7242 19.4674 23.0497 19.7865 23.2736 20.1953C23.5002 20.6016 23.6135 21.0703 23.6135 21.6016C23.6135 22.1328 23.4989 22.6016 23.2697 23.0078C23.0406 23.4141 22.7085 23.7305 22.2736 23.957C21.8413 24.1836 21.3179 24.2969 20.7033 24.2969H18.6916V22.9414H20.4299C20.7554 22.9414 21.0236 22.8854 21.2346 22.7734C21.4481 22.6589 21.607 22.5013 21.7111 22.3008C21.8179 22.0977 21.8713 21.8646 21.8713 21.6016C21.8713 21.3359 21.8179 21.1042 21.7111 20.9062C21.607 20.7057 21.4481 20.5508 21.2346 20.4414C21.021 20.3294 20.7502 20.2734 20.4221 20.2734H19.2814V26.8906H17.59Z"
                fill="white"
            />
            <path
                d="M29.602 21.1914C29.5707 20.8763 29.4366 20.6315 29.1996 20.457C28.9626 20.2826 28.641 20.1953 28.2348 20.1953C27.9587 20.1953 27.7257 20.2344 27.5355 20.3125C27.3454 20.388 27.1996 20.4935 27.098 20.6289C26.9991 20.7643 26.9496 20.918 26.9496 21.0898C26.9444 21.2331 26.9744 21.3581 27.0395 21.4648C27.1072 21.5716 27.1996 21.6641 27.3168 21.7422C27.434 21.8177 27.5694 21.8841 27.723 21.9414C27.8767 21.9961 28.0408 22.043 28.2152 22.082L28.934 22.2539C29.2829 22.332 29.6033 22.4362 29.8949 22.5664C30.1866 22.6966 30.4392 22.8568 30.6527 23.0469C30.8663 23.237 31.0316 23.4609 31.1488 23.7188C31.2686 23.9766 31.3298 24.2721 31.3324 24.6055C31.3298 25.0951 31.2048 25.5195 30.9574 25.8789C30.7126 26.2357 30.3585 26.513 29.8949 26.7109C29.434 26.9062 28.878 27.0039 28.227 27.0039C27.5811 27.0039 27.0186 26.9049 26.5395 26.707C26.0629 26.5091 25.6905 26.2161 25.4223 25.8281C25.1566 25.4375 25.0173 24.9544 25.0043 24.3789H26.641C26.6592 24.6471 26.7361 24.8711 26.8715 25.0508C27.0095 25.2279 27.1931 25.362 27.4223 25.4531C27.654 25.5417 27.9158 25.5859 28.2074 25.5859C28.4939 25.5859 28.7426 25.5443 28.9535 25.4609C29.1671 25.3776 29.3324 25.2617 29.4496 25.1133C29.5668 24.9648 29.6254 24.7943 29.6254 24.6016C29.6254 24.4219 29.572 24.2708 29.4652 24.1484C29.3611 24.026 29.2074 23.9219 29.0043 23.8359C28.8038 23.75 28.5577 23.6719 28.266 23.6016L27.3949 23.3828C26.7204 23.2188 26.1879 22.9622 25.7973 22.6133C25.4066 22.2643 25.2126 21.7943 25.2152 21.2031C25.2126 20.7187 25.3415 20.2956 25.602 19.9336C25.865 19.5716 26.2257 19.2891 26.684 19.0859C27.1423 18.8828 27.6632 18.7812 28.2465 18.7812C28.8402 18.7812 29.3585 18.8828 29.8012 19.0859C30.2465 19.2891 30.5928 19.5716 30.8402 19.9336C31.0876 20.2956 31.2152 20.7148 31.223 21.1914H29.602Z"
                fill="white"
            />
        </svg>
    );
};

export default PpsIconSVG;
