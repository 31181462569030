import React from "react";
import store from "store/TeacherQuestionnaireStore";
import { observer } from "mobx-react-lite";
import SDropDown from "components/UI/SDropDown";
import QuestionnaireOrageExclamation from "assets/TeacherQuestionnaireAssets/QuestionnaireOrageExclamation";
import TimingChooseDownArrow from "assets/TeacherQuestionnaireAssets/TimingChoseDownArrow";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import SButton from "components/UI/SButton";
import { Box, Typography } from "@mui/material";
import * as theme from "./TeacherAvailabilityThemes";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { memo } from "react";
import EditTimezone from "components/SettingsBodyBlock/TutorSettings/Settings/Timezone/EditTimezone/EditTimezone";
import { useMedias } from "API/mediasHook";

const TeacherAvailability = observer(({ changeSchedule, width }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const setScheduleData = (value) => {
        if (changeSchedule !== "changeSchedule") {
            store.teacher.timeToClasses = value;
        } else {
            store.temporaryTimeToClasses = value;
        }
    };

    const setTimeZoneData = (value) => {
        store.teacher.timeZone = value;
    };

    const [prevexpanded, setprevExpanded] = React.useState("");

    const [expanded, setExpanded] = React.useState(false);

    //При открытии другого аккордиона несохраненные выбранные дни стираются
    const isFirstRun = React.useRef(true);

    React.useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        if (prevexpanded !== false) {
            store.syncTempTimings(prevexpanded.slice(5));
        }
    }, [prevexpanded]);

    //При закрытии того же самого аккордиона несохраненные выбранные дни стираются
    const handleChange = (panel) => (event, isExpanded) => {
        setprevExpanded(expanded);
        setExpanded(isExpanded ? panel : false);
        if (isExpanded === false) {
            store.syncTempTimings(panel.slice(5));
        }
    };

    const formatHours = (weekday) => {
        let hoursArray = store.teacher.timings[weekday].map(function (e) {
            return parseInt(e.substring(0, 2));
        });

        let result = "";

        let start = hoursArray[0];

        let end = hoursArray[0];

        for (let i = 1; i < hoursArray.length; i++) {
            if (hoursArray[i] === end + 1) {
                end = hoursArray[i];
            } else {
                result += start === end ? `${start}:00, ` : `${start}:00-${end}:00, `;
                start = hoursArray[i];
                end = hoursArray[i];
            }
        }

        result += start === end ? `${start}:00` : `${start}:00-${end}:00`;

        return result;
    };

    return (
        <Box>
            <Box sx={theme.TeacherPersonalData}>
                <Box sx={theme.TeacherQuestionnaireTopBlock}>
                    {changeSchedule !== "changeSchedule" && <Typography sx={textThemes.HeadlineH41Black}>{t("TeacherQuestionnaire.classSchedule")}</Typography>}
                    <Box sx={theme.TeacherQuestionnaireSubTitle}>
                        <Typography sx={textThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.setUpYourWeeklyAvailability")}</Typography>
                    </Box>
                </Box>
                <Box sx={theme.FilterAndDays}>
                    <Box sx={theme.TeacherQuestionnaireExclamationBox(changeSchedule !== "changeSchedule", medias)}>
                        <Box sx={theme.TeacherQuestionnaireExclamationInnerBox}>
                            <Box sx={theme.ExclamationBox}>
                                <QuestionnaireOrageExclamation />
                            </Box>
                            <Box sx={theme.TeacherQuestionnaireExclamationText(medias)}>
                                <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.latestTimeStudentCanBook")}</Typography>
                            </Box>
                        </Box>
                        <SDropDown
                            tr={true}
                            firstValueGrey={false}
                            dropDownChoice={changeSchedule !== "changeSchedule" ? store.teacher.timeToClasses : store.temporaryTimeToClasses}
                            setDropDownChoice={(value) => setScheduleData(value)}
                            dropDownList={store.timeToClassesList}
                            buttonWidth={medias.sm ? "160px" : "100%"}
                            paperWidth={medias.sm ? "160px" : "100%"}
                        />
                    </Box>
                    <Box sx={theme.WeekDayOuterContainerAndTimezone(changeSchedule !== "changeSchedule", medias)}>
                        <Box sx={theme.WeekdaysOuterContainer}>
                            {Object.keys(store.fullWeekDaysObject).map((weekDay, index) => {
                                return (
                                    <Box
                                        key={index}
                                        sx={theme.WeekDayContainer}
                                    >
                                        <Typography
                                            sx={
                                                store.teacher.timings[weekDay].length > 0
                                                    ? textThemes.Button16Purple
                                                    : store.teacher.timingsTemp[weekDay].length > 0
                                                      ? textThemes.Button16Black
                                                      : textThemes.Button16DarkGrey
                                            }
                                        >
                                            {t(store.fullWeekDaysObject[weekDay])}
                                        </Typography>
                                        <Accordion
                                            sx={theme.Accordion}
                                            expanded={expanded === "panel".concat(weekDay)}
                                            onChange={handleChange("panel".concat(weekDay))}
                                        >
                                            <AccordionSummary
                                                expandIcon={<TimingChooseDownArrow />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                sx={theme.AccordionSummary}
                                            >
                                                <Box sx={theme.TeacherQuestionnaireChooseTimeBoxHided}>
                                                    {store.teacher.timings[weekDay].length === 0 ? (
                                                        <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.selectTimeAvailableForBooking")}</Typography>
                                                    ) : (
                                                        <Box sx={theme.TeacherQuestionnaireWeekDayTitleRow}>
                                                            <Typography
                                                                component="span"
                                                                sx={textThemes.Body2Black}
                                                            >
                                                                {t("TeacherQuestionnaire.availableTime")}:
                                                            </Typography>
                                                            <Typography
                                                                component="span"
                                                                sx={textThemes.Button14Purple}
                                                            >
                                                                {formatHours(weekDay)}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails sx={theme.AccordionDetails}>
                                                {Object.keys(store.timings).map((key, index) => {
                                                    return (
                                                        <Box
                                                            key={index}
                                                            sx={theme.TeacherQuestionnaireTimeOfDayRow}
                                                        >
                                                            <Box sx={theme.TeacherQuestionnaireTimeOfDayText}>{t(store.timingsTranslate[key])}</Box>
                                                            <Box sx={theme.TeacherQuestionnaireTimingsRow(medias)}>
                                                                {store.timings[key].map((time, index) => {
                                                                    return (
                                                                        <Button
                                                                            disableRipple
                                                                            key={index}
                                                                            sx={{
                                                                                ...theme.TeacherQuestionnaireTimeButton,
                                                                                background: store.teacher.timingsTemp[weekDay].includes(time) ? "#6212FF" : "#fff",
                                                                                color: store.teacher.timingsTemp[weekDay].includes(time) ? "#FFF" : "#222",
                                                                                ":hover": {
                                                                                    background: store.teacher.timingsTemp[weekDay].includes(time) ? "#6212FF" : "#fff",
                                                                                    color: store.teacher.timingsTemp[weekDay].includes(time) ? "#FFF" : "#222",
                                                                                },
                                                                            }}
                                                                            onClick={() => {
                                                                                if (store.teacher.timingsTemp[weekDay].includes(time) === false) {
                                                                                    store.teacher.timingsTemp[weekDay].push(time);
                                                                                    store.teacher.timingsTemp[weekDay].sort();
                                                                                } else {
                                                                                    store.teacher.timingsTemp[weekDay].splice(
                                                                                        store.teacher.timingsTemp[weekDay].findIndex((x) => x === time),
                                                                                        1,
                                                                                    );
                                                                                    store.teacher.timingsTemp[weekDay].sort();
                                                                                }
                                                                            }}
                                                                        >
                                                                            {time}
                                                                        </Button>
                                                                    );
                                                                })}
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                                <Box sx={theme.TeacherQuestionnaireChooseButtonsBox}>
                                                    <Box sx={theme.TeacherQuestionnaireChooseButtons}>
                                                        <SButton
                                                            variant="secondary"
                                                            padding="10px 20px 11px 20px"
                                                            onClick={() => store.syncTempTimings(weekDay)}
                                                        >
                                                            {t("main.cancel")}
                                                        </SButton>
                                                        <SButton
                                                            variant="unshadowed"
                                                            padding="10px 20px 11px 20px"
                                                            onClick={() => {
                                                                store.saveTimings(weekDay);
                                                            }}
                                                        >
                                                            {t("main.save")}
                                                        </SButton>
                                                    </Box>
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                );
                            })}
                        </Box>
                        <Box sx={theme.TimeZoneBlock}>
                            <Box sx={theme.TeacherQuestionnaireTopBlock}>
                                <Typography sx={textThemes.HeadlineH41Black}>{t("TeacherQuestionnaire.timeZone")}</Typography>
                                <Typography sx={textThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.makeSureYourLocalTime")}</Typography>
                            </Box>
                            <Box sx={theme.SettingsProfileBlockLeftPanelTimezoneEdit}>
                                <EditTimezone
                                    width={medias.sm ? "598px" : "100%"}
                                    mode={changeSchedule !== "changeSchedule" ? "main" : "temporary"}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
});

export default memo(TeacherAvailability);
