import { styleProcessing } from "API/plugins/Utilities";

export const configureNotifications = (theme) => {
    let commonStyle = { paddingLeft: "30px", paddingRight: "30px" };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            paddingLeft: "20px",
            paddingRight: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const UnlinkButton = (theme) => {
    let commonStyle = { paddingLeft: "30px" };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            paddingLeft: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const UnlinkBlock = (theme) => {
    let commonStyle = { paddingLeft: "30px", paddingRight: "30px", display: "flex", flexDirection: "column", gap: "30px" };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            paddingLeft: "20px",
            paddingRight: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
