const DragNDropPlusIcon = function ({ hovered }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <g clipPath="url(#clip0_8712_73383)">
                <circle
                    cx="12"
                    cy="12"
                    r="11.5"
                    stroke="#6212FF"
                    fill={hovered ? "#6212FF" : "#FFF"}
                />
                <path
                    d="M12.75 6C12.75 5.58579 12.4142 5.25 12 5.25C11.5858 5.25 11.25 5.58579 11.25 6V11.25L6 11.25C5.58579 11.25 5.25 11.5858 5.25 12C5.25 12.4142 5.58579 12.75 6 12.75L11.25 12.75V18C11.25 18.4142 11.5858 18.75 12 18.75C12.4142 18.75 12.75 18.4142 12.75 18V12.75L18 12.75C18.4142 12.75 18.75 12.4142 18.75 12C18.75 11.5858 18.4142 11.25 18 11.25L12.75 11.25V6Z"
                    fill={hovered ? "#FFF" : "#6212FF"}
                />
            </g>
            <defs>
                <clipPath id="clip0_8712_73383">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default DragNDropPlusIcon;
