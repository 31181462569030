import Photo from "assets/PAAssets/PNG/photo.png";
import { makeAutoObservable } from "mobx";
import { useEffect } from "react";
class MyClassesStore {
    // classes = []

    classes = [
        {
            id: 1,
            subject: "Теория вероятности",
            timeStart: new Date(2024, 8, 10, 15, 0),
            timeEnd: new Date(2024, 8, 10, 16, 0),
            tutor: "Александра А.",
            place: "online",
            rating: null,
            photo: Photo,
            review: null,
            rescheduleAttempt: 3,
            cancelled: false,
            cancelReason: null,
            rescheduleTimeStart: null,
            rescheduleTimeEnd: null,
            rescheduleReason: null,
        },
        {
            id: 2,
            subject: "Математическая статистика",
            timeStart: new Date(2024, 4, 11, 0, 0),
            timeEnd: new Date(2024, 4, 11, 0, 1),
            tutor: "Александра А.",
            place: "online",
            rating: null,
            photo: Photo,
            review: "32131",
            rescheduleAttempt: 3,
            cancelled: false,
            cancelReason: null,
            rescheduleTimeStart: null,
            rescheduleTimeEnd: null,
            rescheduleReason: null,
        },
        {
            id: 3,
            subject: "Эконометрика",
            timeStart: new Date(2024, 4, 16, 18, 0),
            timeEnd: new Date(2024, 4, 16, 19, 0),
            tutor: "Владимир Б.",
            place: "student",
            rating: 5,
            photo: Photo,
            review: null,
            rescheduleAttempt: 0,
            cancelled: false,
            cancelReason: null,
            rescheduleTimeStart: null,
            rescheduleTimeEnd: null,
            rescheduleReason: null,
        },
        {
            id: 4,
            subject: "Английский язык",
            timeStart: new Date(2024, 4, 10, 23, 58),
            timeEnd: new Date(2024, 4, 10, 23, 59),
            tutor: "Александра А.",
            place: "online",
            rating: null,
            photo: Photo,
            review: null,
            rescheduleAttempt: 3,
            cancelled: false,
            cancelReason: null,
            rescheduleTimeStart: null,
            rescheduleTimeEnd: null,
            rescheduleReason: null,
        },
        {
            id: 5,
            subject: "Английский язык",
            timeStart: new Date(2024, 10, 30, 11, 0),
            timeEnd: new Date(2024, 10, 30, 12, 0),
            tutor: "Александра А.",
            place: "online",
            rating: null,
            photo: Photo,
            review: null,
            rescheduleAttempt: 3,
            cancelled: false,
            cancelReason: null,
            rescheduleTimeStart: null,
            rescheduleTimeEnd: null,
            rescheduleReason: null,
        },
        {
            id: 6,
            subject: "Английский язык",
            timeStart: new Date(2024, 10, 30, 12, 0),
            timeEnd: new Date(2024, 10, 30, 13, 0),
            tutor: "Анна В.",
            place: "online",
            rating: null,
            photo: Photo,
            review: null,
            rescheduleAttempt: 3,
            cancelled: false,
            cancelReason: null,
            rescheduleTimeStart: null,
            rescheduleTimeEnd: null,
            rescheduleReason: null,
        },
        {
            id: 7,
            subject: "Методы машинного обучения",
            timeStart: new Date(2025, 10, 30, 9, 0),
            timeEnd: new Date(2025, 10, 30, 10, 0),
            tutor: "Дмитрий И.",
            place: "tutor",
            rating: null,
            photo: Photo,
            review: null,
            rescheduleAttempt: 3,
            cancelled: false,
            cancelReason: null,
            rescheduleTimeStart: null,
            rescheduleTimeEnd: null,
            rescheduleReason: null,
        },
    ];

    optionsUpcoming = ["Все предметы"];
    optionsPrevious = ["Все предметы"];
    page = 0;
    selectedTab = "Upcoming";
    selectedIndexUpcoming = 0;
    selectedIndexPrevious = 0;
    temporaryRating = null;
    temporaryReview = null;
    temporaryCancelReason = null;
    temporaryRescheduleTimeStart = null;
    temporaryRescheduleTimeEnd = null;
    temporaryRescheduleReason = null;

    filteredUpcomingClasses = this.filterUpcomingClasses();

    newClassTutor = null;
    dialogChooseTutorOpen = false;
    dialogScheduleClassOpen = false;
    SetDialogChooseTutorOpen(value) {
        this.dialogChooseTutorOpen = value;
    }
    SetDialogScheduleClassOpen(value) {
        this.dialogScheduleClassOpen = value;
    }
    setNewClassTutor(value) {
        this.newClassTutor = value;
    }

    setTemporaryRescheduleTimeEnd(value) {
        this.temporaryRescheduleTimeEnd = value;
    }

    setTemporaryRescheduleTimeStart(value) {
        this.temporaryRescheduleTimeStart = value;
    }

    setTemporaryRescheduleReason(value) {
        this.temporaryRescheduleReason = value;
    }

    setRescheduleTimeStart(index, value) {
        this.classes[index].rescheduleTimeStart = value;
    }

    setRescheduleTimeEnd(index, value) {
        this.classes[index].rescheduleTimeEnd = value;
    }

    setRescheduleReason(index, value) {
        this.classes[index].rescheduleReason = value;
    }
    setOptionsUpcoming(value) {
        this.optionsUpcoming = value;
    }

    setOptionsPrevious(value) {
        this.optionsPrevious = value;
    }

    setPage(value) {
        this.page = value;
    }

    setSelectedTab(value) {
        this.selectedTab = value;
    }

    setRating(index, value) {
        this.classes[index].rating = value;
    }
    setReview(index, value) {
        this.classes[index].review = value;
    }

    setTemporaryRating(value) {
        this.temporaryRating = value;
    }

    setTemporaryCancelReason(value) {
        this.temporaryCancelReason = value;
    }

    setCancelReason(index, value) {
        this.classes[index].cancelReason = value;
    }

    setCancelled(index, value) {
        this.classes[index].cancelled = value;
    }

    setTemporaryReview(value) {
        this.temporaryReview = value;
    }

    setSelectedIndexUpcoming(value) {
        this.selectedIndexUpcoming = value;
    }

    setSelectedIndexPrevious(value) {
        this.selectedIndexPrevious = value;
    }

    onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    getTurorList() {
        // eslint-disable-next-line no-undef
        const uniqueClassesMap = new Map();

        this.classes.forEach((classItem) => {
            const key = `${classItem.tutor}-${classItem.subject}`;

            if (!uniqueClassesMap.has(key)) {
                uniqueClassesMap.set(key, {
                    tutor: classItem.tutor,
                    photo: classItem.photo,
                    subject: classItem.subject,
                });
            }
        });

        return Array.from(uniqueClassesMap.values());
    }

    fillFilters() {
        let arrOptionsUpcoming = ["Все предметы"];

        let arrOptionsPrevious = ["Все предметы"];

        let today = new Date();

        this.classes.forEach((item, index) => {
            if (!arrOptionsUpcoming.includes(item.subject) && item.subject && item.timeEnd >= today && !item.cancelled) {
                arrOptionsUpcoming.push(item.subject);
            }
        });
        this.classes.forEach((item, index) => {
            if (!arrOptionsPrevious.includes(item.subject) && item.subject && item.timeEnd <= today && !item.cancelled) {
                arrOptionsPrevious.push(item.subject);
            }
        });
        this.setOptionsUpcoming(arrOptionsUpcoming);
        this.setOptionsPrevious(arrOptionsPrevious);
    }

    findNearestTodayClassIndex() {
        const today = new Date();

        let nearestIndex = null;

        let nearestDiff = Infinity;

        for (let i = 0; i < this.classes.length; i++) {
            const classDate = this.classes[i].timeStart;

            const classEndDate = this.classes[i].timeEnd;

            if (classDate.getDate() === today.getDate() && classDate.getMonth() === today.getMonth() && classDate.getFullYear() === today.getFullYear() && classEndDate > today) {
                console.log(classEndDate, today);
                const diff = Math.abs(classDate.getTime() - today.getTime());

                if (diff < nearestDiff) {
                    nearestDiff = diff;
                    nearestIndex = i;
                }
            }
        }

        return nearestIndex;
    }

    findNearestTodayClassObject() {
        const today = new Date();

        let nearestClass = null;

        let nearestDiff = Infinity;

        for (let i = 0; i < this.classes.length; i++) {
            const classDate = this.classes[i].timeStart;

            const classEndDate = this.classes[i].timeEnd;

            if (classDate.getDate() === today.getDate() && classDate.getMonth() === today.getMonth() && classDate.getFullYear() === today.getFullYear() && classEndDate > today) {
                console.log(classEndDate, today);
                const diff = Math.abs(classDate.getTime() - today.getTime());

                if (diff < nearestDiff) {
                    nearestDiff = diff;
                    nearestClass = this.classes[i];
                }
            }
        }

        return nearestClass;
    }

    findNearestClassIndex() {
        const now = new Date();

        let nearestIndex = null;

        let nearestDiff = Infinity;

        for (let i = 0; i < this.classes.length; i++) {
            const classDate = this.classes[i].timeStart;

            const diff = Math.abs(classDate.getTime() - now.getTime());

            if (diff < nearestDiff && classDate > now) {
                nearestDiff = diff;
                nearestIndex = i;
            }
        }

        return nearestIndex;
    }

    formatTodayClassDate(index) {
        const months = [
            "main.months.atJanuary",
            "main.months.atFebruary",
            "main.months.atMarch",
            "main.months.atApril",
            "main.months.atMay",
            "main.months.atJune",
            "main.months.atJuly",
            "main.months.atAugust",
            "main.months.atSeptember",
            "main.months.atOctober",
            "main.months.atNovember",
            "main.months.atDecember",
        ];

        const days = [
            "main.daysOfWeek.sundayShort",
            "main.daysOfWeek.mondayShort",
            "main.daysOfWeek.tuesdayShort",
            "main.daysOfWeek.wednesdayShort",
            "main.daysOfWeek.thursdayShort",
            "main.daysOfWeek.fridayShort",
            "main.daysOfWeek.saturdayShort",
        ];

        const classDate = this.classes[index].timeStart;

        const day = days[classDate.getDay()];

        const month = months[classDate.getMonth()];

        const date = classDate.getDate();

        const formattedDate = { date: date, month: month, weekday: day };

        return formattedDate;
    }

    formatTodayClassTime(lesson, variant = "default") {
        var startTime = null;

        var endTime = null;

        if (lesson !== -1) {
            if (variant === "default") {
                startTime = lesson.timeStart;

                endTime = lesson.timeEnd;
            } else if (variant === "reschedule") {
                startTime = lesson.rescheduleTimeStart;

                endTime = lesson.rescheduleTimeEnd;
            }

            console.log(variant, startTime, endTime);

            const startHours = startTime.getHours().toString().padStart(2, "0");

            const startMinutes = startTime.getMinutes().toString().padStart(2, "0");

            const endHours = endTime.getHours().toString().padStart(2, "0");

            const endMinutes = endTime.getMinutes().toString().padStart(2, "0");

            const formattedTimeRange = `${startHours}:${startMinutes}–${endHours}:${endMinutes}`;

            return formattedTimeRange;
        }
    }

    formatTodayClassTimeRescheduled(startTime, endTime) {
        if (startTime !== null && endTime !== null) {
            const startHours = startTime.getHours().toString().padStart(2, "0");

            const startMinutes = startTime.getMinutes().toString().padStart(2, "0");

            const endHours = endTime.getHours().toString().padStart(2, "0");

            const endMinutes = endTime.getMinutes().toString().padStart(2, "0");

            const formattedTimeRange = `${startHours}:${startMinutes}–${endHours}:${endMinutes}`;

            return formattedTimeRange;
        } else return "";
    }

    formatNearestClassStartTime(index) {
        const classDate = this.classes[index].timeStart;

        const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];

        const month = months[classDate.getMonth()];

        const date = classDate.getDate();

        const hours = classDate.getHours().toString().padStart(2, "0");

        const minutes = classDate.getMinutes().toString().padStart(2, "0");

        const formattedStartTime = `${date} ${month} в ${hours}:${minutes}`;

        return formattedStartTime;
    }

    groupClassesByDate() {
        const groupedClasses = {};

        this.classes.forEach((classItem) => {
            const startDate = classItem.timeStart;

            const formattedDate = this.getDateString(startDate);

            if (!groupedClasses[formattedDate]) {
                groupedClasses[formattedDate] = [];
            }

            groupedClasses[formattedDate].push(classItem);
        });

        return groupedClasses;
    }

    getDateString(dateTime) {
        const year = dateTime.getFullYear();

        const month = String(dateTime.getMonth() + 1).padStart(2, "0"); // Months are zero based

        const day = String(dateTime.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
    }

    getDateTime(datetimeString) {
        const [year, month, day] = datetimeString.split("-").map(Number); // Splitting date into year, month, and day

        return new Date(year, month - 1, day, 0, 0);
    }

    getFormattedDate = (date) => {
        const days = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

        const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];

        const formattedDate = this.getDateTime(date);

        const dayOfWeek = days[formattedDate.getDay()];

        const dayOfMonth = formattedDate.getDate();

        const month = months[formattedDate.getMonth()];

        return `${dayOfMonth} ${month}, ${dayOfWeek}`;
    };

    getFormattedDateForReschedule = (date, lesson) => {
        const days = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

        const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];

        const dayOfWeek = days[date?.getDay()];

        const dayOfMonth = date?.getDate();

        const month = months[date?.getMonth()];

        const startTime = lesson.timeStart;

        const endTime = lesson.timeEnd;

        const diff = Math.abs(startTime.getTime() - endTime.getTime());

        const newEndTime = new Date(date?.getTime() + diff);

        this.setTemporaryRescheduleTimeEnd(newEndTime);

        const startHours = date?.getHours().toString().padStart(2, "0");

        const startMinutes = date?.getMinutes().toString().padStart(2, "0");

        const endHours = newEndTime.getHours().toString().padStart(2, "0");

        const endMinutes = newEndTime.getMinutes().toString().padStart(2, "0");

        return `${dayOfMonth} ${month}, ${dayOfWeek}, ${startHours}:${startMinutes}–${endHours}:${endMinutes}`;
    };

    getFormatedDateExtended(dateStart, dateEnd) {
        const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];

        const days = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

        const day = days[dateStart?.getDay()];

        const month = months[dateStart?.getMonth()];

        const date = dateStart?.getDate();

        const startHours = dateStart?.getHours().toString().padStart(2, "0");

        const startMinutes = dateStart?.getMinutes().toString().padStart(2, "0");

        const endHours = dateEnd?.getHours().toString().padStart(2, "0");

        const endMinutes = dateEnd?.getMinutes().toString().padStart(2, "0");

        const formattedDate = `${date} ${month}, ${day}, ${startHours}:${startMinutes}–${endHours}:${endMinutes}`;

        return formattedDate;
    }

    getFromattedDateForRate = (index) => {
        const days = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

        const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];

        const startTime = this.classes[index].timeStart;

        const endTime = this.classes[index].timeEnd;

        const dayOfMonth = startTime.getDate();

        const month = months[startTime.getMonth()];

        const startHours = startTime.getHours().toString().padStart(2, "0");

        const startMinutes = startTime.getMinutes().toString().padStart(2, "0");

        const endHours = endTime.getHours().toString().padStart(2, "0");

        const endMinutes = endTime.getMinutes().toString().padStart(2, "0");

        const formattedTimeRange = `${dayOfMonth} ${month}, ${startHours}:${startMinutes}–${endHours}:${endMinutes}`;

        return formattedTimeRange;
    };

    startClassButtonDisabled() {
        const classStart = this.classes[this.findNearestTodayClassIndex()].timeStart;

        const currentTime = new Date();

        const timeDifference = classStart - currentTime;

        const fifteenMinutes = 15 * 60 * 1000;

        return timeDifference > fifteenMinutes;
    }

    filterUpcomingClasses() {
        const classesObject = this.groupClassesByDate();

        const currentDate = new Date(); // Текущая дата и время

        // Фильтрация занятий
        const upcomingClasses = {};

        for (const dateKey in classesObject) {
            const classes = classesObject[dateKey];

            const upcomingTemp = classes.filter((classItem) => classItem.timeStart > currentDate && classItem.cancelled !== true);

            let upcoming = [];

            if (this.selectedIndexUpcoming !== 0) {
                upcoming = upcomingTemp.filter((classItem) => classItem.subject === this.optionsUpcoming[this.selectedIndexUpcoming]);
            } else {
                upcoming = upcomingTemp;
            }

            if (upcoming.length > 0) {
                upcomingClasses[dateKey] = upcoming;
            }
        }

        return upcomingClasses;
    }

    filterPastClasses() {
        const classesObject = this.groupClassesByDate();

        const currentDate = new Date(); // Текущая дата и время

        // Фильтрация занятий
        const pastClasses = {};

        for (const dateKey in classesObject) {
            const classes = classesObject[dateKey];

            const pastTemp = classes.filter((classItem) => classItem.timeEnd < currentDate && classItem.cancelled !== true);

            let past = [];

            if (this.selectedIndexPrevious !== 0) {
                past = pastTemp.filter((classItem) => classItem.subject === this.optionsPrevious[this.selectedIndexPrevious]);
            } else {
                past = pastTemp;
            }

            if (past.length > 0) {
                pastClasses[dateKey] = past;
            }
        }

        return pastClasses;
    }

    checkPastUpcoming(lesson) {
        const currentDate = new Date();

        if (lesson.timeEnd < currentDate) {
            return "past";
        } else return "upcoming";
    }

    debounce(callback, delay) {
        return (...args) => {
            if (this.timerID) {
                clearTimeout(this.timerID);
            }

            this.timerID = setTimeout(() => {
                callback.call(this, ...args);
            }, delay);
        };
    }

    Init() {
        this.Load();
    }

    Load() {
        this.filteredUpcomingClasses = this.filterUpcomingClasses();
    }

    constructor() {
        this.Init();
        makeAutoObservable(this);
    }
}

export default new MyClassesStore();
