import React, { useEffect } from "react";
import SDropDown from "components/UI/SDropDown";
import STextField from "components/UI/STextField";
import QuestionnaireButtons from "components/TeacherQuestionnaireBodyBlock/Components/QuestionnaireButtons";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import store from "store/TeacherQuestionnaireStore";
import { observer } from "mobx-react-lite";
import { Box, Typography } from "@mui/material";
import * as themes from "./PersonalDataStepThemes";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import cities from "API/dictionaries/cities";
import { useMedias } from "API/mediasHook";
import SingleChoiceLightFilter from "components/UI/SingleChoiceLightFilter";

const PersonalDataStep = observer(({ currentStep, setCurrentStep }) => {
    const medias = useMedias();

    const disabledContinue =
        TeacherQuestionnaireStore.teacher.firstName === "" ||
        TeacherQuestionnaireStore.teacher.firstName === null ||
        TeacherQuestionnaireStore.teacher.firstName === undefined ||
        TeacherQuestionnaireStore.teacher.secondName === "" ||
        TeacherQuestionnaireStore.teacher.secondName === null ||
        TeacherQuestionnaireStore.teacher.secondName === undefined ||
        TeacherQuestionnaireStore.teacher.surname === "" ||
        TeacherQuestionnaireStore.teacher.surname === null ||
        TeacherQuestionnaireStore.teacher.surname === undefined ||
        TeacherQuestionnaireStore.teacher.birthday.month === "" ||
        TeacherQuestionnaireStore.teacher.birthday.month === null ||
        TeacherQuestionnaireStore.teacher.birthday.month === undefined ||
        TeacherQuestionnaireStore.teacher.birthday.day === "" ||
        TeacherQuestionnaireStore.teacher.birthday.day === null ||
        TeacherQuestionnaireStore.teacher.birthday.day === undefined ||
        TeacherQuestionnaireStore.teacher.birthday.year === "" ||
        TeacherQuestionnaireStore.teacher.birthday.year === null ||
        TeacherQuestionnaireStore.teacher.birthday.year === undefined ||
        TeacherQuestionnaireStore.teacher.birthday.year > new Date().getFullYear() - 18 ||
        TeacherQuestionnaireStore.teacher.email === "" ||
        TeacherQuestionnaireStore.teacher.email === null ||
        TeacherQuestionnaireStore.teacher.email === undefined ||
        !TeacherQuestionnaireStore.teacher.email.includes("@") ||
        TeacherQuestionnaireStore.teacher.phone === "" ||
        TeacherQuestionnaireStore.teacher.phone === null ||
        TeacherQuestionnaireStore.teacher.phone === undefined ||
        TeacherQuestionnaireStore.teacher.phone.length > 16 ||
        TeacherQuestionnaireStore.teacher.city === null;

    const { t, i18n } = useTranslation();

    useEffect(() => {
        async function loadTeacherPersonalData() {
            await TeacherQuestionnaireStore.loadPersonalData();
        }

        loadTeacherPersonalData();
    }, []);

    return (
        <ScrollToTop>
            <Box sx={themes.TeacherPersonalDataStep(medias)}>
                <Box sx={themes.TeacherWelcomeBanner(medias)}>
                    <Box sx={themes.TeacherWelcomeBannerHeader(medias)}>
                        <Typography
                            sx={medias.sm ? textThemes.HeadlineH41White : textThemes.HeadlineH5White}
                        >{`${TeacherQuestionnaireStore.teacher.firstName}${TeacherQuestionnaireStore.teacher.firstName !== "" ? "," : ""} ${t("TeacherQuestionnaire.welcomeToStudyBuddy")}`}</Typography>
                    </Box>
                    <Typography sx={textThemes.Body2White}>{t("TeacherQuestionnaire.toContinueSigningUp")}</Typography>
                </Box>
                <Box sx={themes.TeacherPersonalData(medias)}>
                    <Box sx={themes.TeacherPersTeacherQuestionnaireTopBlockonalData}>
                        <Typography sx={textThemes.HeadlineH41Black}>{t("TeacherQuestionnaire.personalData")}</Typography>
                        <Typography sx={textThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.thisInformationIsNeeded")}</Typography>
                    </Box>

                    <Box sx={themes.TeacherPersonalDataForm(medias)}>
                        <Box sx={themes.TeacherQuestionnaireDropDownEdit(medias)}>
                            <Box sx={themes.TeacherQuestionnaireDropDownTitle}>
                                <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.secondName")}</Typography>
                            </Box>
                            <STextField
                                width={medias.sm ? "424px" : "100%"}
                                placeholder={t("TeacherQuestionnaire.enterSecondName")}
                                value={store.teacher["secondName"]}
                                valueerror={!store.teacher["secondName"]}
                                changeFunction={(value) => store.setTeacherData("secondName", value)}
                            />
                        </Box>

                        <Box sx={themes.TeacherQuestionnaireDropDownEdit(medias)}>
                            <Box sx={themes.TeacherQuestionnaireDropDownTitle}>
                                <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.firstName")}</Typography>
                            </Box>
                            <STextField
                                width={medias.sm ? "424px" : "100%"}
                                placeholder={t("TeacherQuestionnaire.enterFirstName")}
                                value={store.teacher["firstName"]}
                                valueerror={!store.teacher["firstName"]}
                                changeFunction={(value) => store.setTeacherData("firstName", value)}
                            />
                        </Box>

                        <Box sx={themes.TeacherQuestionnaireDropDownEdit(medias)}>
                            <Box sx={themes.TeacherQuestionnaireDropDownTitle}>
                                <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.middleName")}</Typography>
                            </Box>
                            <STextField
                                width={medias.sm ? "424px" : "100%"}
                                placeholder={t("TeacherQuestionnaire.enterMiddleName")}
                                value={store.teacher["surname"]}
                                valueerror={!store.teacher["surname"]}
                                changeFunction={(value) => store.setTeacherData("surname", value)}
                            />
                        </Box>
                        <Box sx={themes.TeacherQuestionnaireDropDownEdit(medias)}>
                            <Box sx={themes.TeacherQuestionnaireDropDownTitle}>
                                <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.gender")}</Typography>
                            </Box>
                            <Box>
                                <SDropDown
                                    tr={true}
                                    firstValueGrey={false}
                                    placeholder={"Пол"}
                                    dropDownChoice={store.teacher["sex"]}
                                    setDropDownChoice={(value) => store.setTeacherData("sex", value)}
                                    dropDownList={store.sexList}
                                    buttonWidth={medias.sm ? "424px" : "100%"}
                                    paperWidth={medias.sm ? "424px" : "100%"}
                                />
                            </Box>
                        </Box>

                        <Box sx={themes.TeacherQuestionnaireDropDownEdit(medias)}>
                            <Box sx={themes.TeacherQuestionnaireDropDownTitle}>
                                <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.birthday")}</Typography>
                            </Box>
                            <Box>
                                {" "}
                                <Box sx={themes.TeacherQuestionnaireBirthdayEdit}>
                                    <SDropDown
                                        placeholder={t("main.day")}
                                        firstValueGrey={false}
                                        dropDownChoice={store.teacher.birthday.day}
                                        setDropDownChoice={(value) => store.setBirthdayData("day", value)}
                                        dropDownList={store.daysList}
                                        error={!store.teacher.birthday.day}
                                        buttonWidth={medias.sm ? "120px" : "100%"}
                                        paperWidth={medias.sm ? "120px" : "100%"}
                                    />
                                    <SDropDown
                                        placeholder={t("main.month")}
                                        tr={true}
                                        firstValueGrey={false}
                                        dropDownChoice={store.teacher.birthday.month}
                                        setDropDownChoice={(value) => store.setBirthdayData("month", value)}
                                        dropDownList={store.monthList}
                                        error={!store.teacher.birthday.month}
                                        buttonWidth={medias.sm ? "152px" : "100%"}
                                        paperWidth={medias.sm ? "152px" : "100%"}
                                    />
                                    <SDropDown
                                        placeholder={t("main.year")}
                                        firstValueGrey={false}
                                        dropDownChoice={store.teacher.birthday.year}
                                        setDropDownChoice={(value) => store.setBirthdayData("year", value)}
                                        dropDownList={store.yearsList}
                                        error={!store.teacher.birthday.year}
                                        buttonWidth={medias.sm ? "120px" : "100%"}
                                        paperWidth={medias.sm ? "120px" : "100%"}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={themes.TeacherQuestionnaireDropDownEdit(medias)}>
                            <Box sx={themes.TeacherQuestionnaireDropDownTitle}>
                                <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.city")}</Typography>
                            </Box>
                            <Box sx={themes.DropDownOuterBox(medias.sm)}>
                                <SingleChoiceLightFilter
                                    propOption={store.teacher.city}
                                    propOptions={cities}
                                    valueToShow={"cityRegion"}
                                    valueToBind={"id"}
                                    processingChange={(event, value) => {
                                        store.setTeacherData("city", value);
                                    }}
                                    themeWrapper={themes.FiltersCitiesTheme}
                                    placeholder={t("TeacherQuestionnaire.selectYourCity")}
                                    clearOnBlur={false}
                                    disableClearable={false}
                                    variant={"standard"}
                                    freeSolo={true}
                                    error={!store.teacher.city}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Box sx={themes.TeacherQuestionnaireTopBlock}>
                        <Typography sx={textThemes.HeadlineH41Black}>{t("TeacherQuestionnaire.contacts")}</Typography>
                        <Typography sx={textThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.noOneWillSeeYourContacts")}</Typography>
                    </Box>
                    <Box sx={themes.TeacherPersonalDataForm}>
                        <Box sx={themes.TeacherQuestionnaireDropDownEdit(medias)}>
                            <Box sx={themes.TeacherQuestionnaireDropDownTitle}>
                                <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.phoneNumber")}</Typography>
                            </Box>
                            <STextField
                                disabled={true}
                                width={medias.sm ? "424px" : "100%"}
                                variant="phone"
                                value={TeacherQuestionnaireStore.teacher.phone}
                                handleChange={(value) => store.setTeacherData("phone", value)}
                            />
                        </Box>
                        <Box sx={themes.TeacherQuestionnaireDropDownEdit(medias)}>
                            <Box sx={themes.TeacherQuestionnaireDropDownTitle}>
                                <Typography sx={textThemes.Body2Black}>{t("TeacherQuestionnaire.Email")}</Typography>
                            </Box>
                            <STextField
                                disabled={true}
                                width={medias.sm ? "424px" : "100%"}
                                placeholder={t("TeacherQuestionnaire.enterEmail")}
                                value={store.teacher["email"]}
                                changeFunction={(value) => store.setTeacherData("email", value)}
                            />
                        </Box>
                    </Box>
                </Box>
                <QuestionnaireButtons
                    disabled={disabledContinue}
                    setCurrentStep={setCurrentStep}
                    currentStep={currentStep}
                    width={medias.sm ? "" : "100%"}
                />
            </Box>
        </ScrollToTop>
    );
});

export default memo(PersonalDataStep);
