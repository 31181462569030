import { TextField, Typography, styled } from "@mui/material";

export const StyledDismissTextField = styled(TextField)({
    height: "24px",
    marginTop: "-3px",
    "& .MuiInput-underline:before": {
        borderBottom: "1px solid #BBBBC8",
        position: "absolute",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#6212FF",
    },
    "& .MuiInputBase-input": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "16px",
        lineHeight: "150%",
        fontWeight: 400,
        textTransform: "none",
    },
});

export const StyledDismissAnother = styled(Typography)({
    color: "rgba(158, 158, 176, 1)",
});
