import { styleProcessing } from "API/plugins/Utilities";

export const BonusBox = (theme) => {
    let commonStyle = {
        backgroundColor: "#FFF",
        borderRadius: "20px",
        maxWidth: "289px",
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            maxWidth: "600px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TooltipPopperProps = {
    sx: {
        "& .MuiTooltip-tooltip": {
            border: "none",
            color: "#222",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "140%",
            borderRadius: "10px",
            bgcolor: "#fff",
            width: "270px",
            boxShadow: "0px 2px 10px 0px rgba(106, 99, 118, 0.15)",
            padding: "10px",
        },
    },
};

export const BonusTitle = {
    display: "flex",
    gap: "8px",
    alignItems: "center",
};

export const BonusContainer = {
    borderRadius: "15px",
    background: "rgba(187, 187, 200, 0.17)",
    padding: "16px 0 17px 16px",
};

export const BonusText = {
    width: "fit-content",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "150%",
    background: "linear-gradient(257deg, #04C83B 20.58%, #12AAFF 81.82%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    color: "transparent",
};

export const PercentageText = {
    width: "fit-content",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
    background: "linear-gradient(257deg, #04C83B 20.58%, #12AAFF 81.82%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    color: "transparent",
};

export const BonusInnerPanel = {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
};

export const BonusOuterPanel = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "20px 20px 0 20px",
};

export const TooltipButton = {
    padding: 0,
    height: "16px",
};

export const BonusLowerPanel = {
    padding: "0  14px 20px 20px",
};
