import React from "react";

import { observer } from "mobx-react-lite";

import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step5 from "./Steps/Step5";
import Step6 from "./Steps/Step6";
import Step7 from "./Steps/Step7";
import Step8 from "./Steps/Step8";
import Step9 from "./Steps/Step9";
import Step10 from "./Steps/Step10";
import Step11 from "./Steps/Step11";
import Step12 from "./Steps/Step12";
import Step13 from "./Steps/Step13";
import Step14 from "./Steps/Step14";
import Step15 from "./Steps/Step15";
import Step16 from "./Steps/Step16";
import Step17 from "./Steps/Step17";
import Step18 from "./Steps/Step18";
import Step19 from "./Steps/Step19";
import Step20 from "./Steps/Step20";
import Step21 from "./Steps/Step21";
import Step22 from "./Steps/Step22";
import Step23 from "./Steps/Step23";
import Step24 from "./Steps/Step24";
import Step25 from "./Steps/Step25";
import Step26 from "./Steps/Step26";

import store from "store/RequestStore";

const ConditionComponent = () => {
    switch (store.activeStepIndex) {
        case 0:
            return <Step1 />;
        case 1:
            switch (store.subStepIndex) {
                case 0:
                    return <Step2 />;
                case 1:
                    return <Step3 />;
                case 2:
                    return <Step4 />;
                default:
                    break;
            }

            break;
        case 2:
            switch (store.subStepIndex) {
                case 0:
                    return <Step5 />;
                case 1:
                    return <Step14 />;
                case 2:
                    return <Step15 />;
                default:
                    break;
            }

            break;
        case 3:
            switch (store.subStepIndex) {
                case 0:
                    return <Step6 />;
                case 1:
                    return <Step7 />;
                default:
                    break;
            }

            break;
        case 4:
            switch (store.subStepIndex) {
                case 0:
                    return <Step8 />;
                case 1:
                    return <Step9 />;
                default:
                    break;
            }

            break;
        case 5:
            return <Step10 />;
        case 6:
            return <Step11 />;
        case 7:
            return <Step12 />;
        case 8:
            return <Step13 />;
        case 9:
            return <Step16 />;
        case 10:
            return <Step17 />;
        case 11:
            switch (store.subStepIndex) {
                case 0:
                    return <Step18 />;
                case 1:
                    return <Step19 />;
                default:
                    break;
            }

            break;
        case 12:
            return <Step20 />;
        case 13:
            return <Step21 />;
        case 14:
            return <Step22 />;
        case 15:
            return <Step23 />;
        case 16:
            return <Step24 />;
        case 17:
            return <Step25 />;
        case 18:
            return <Step26 />;
        default:
            return <Step4 />;
    }
};

export default observer(ConditionComponent);
