import React from "react";

const IconOK = ({ ...props }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M3 9.55556L5.54544 12.9382C5.9455 13.4698 6.74347 13.4698 7.14352 12.9382L8.66221 10.92M12.3645 6L8.66221 10.92M8.66221 10.92L10.1809 12.9382C10.5809 13.4698 11.3793 13.4693 11.7793 12.9377C13.6595 10.4392 14.929 8.75217 17 6"
                stroke={props.color}
                strokeWidth="1.2"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default IconOK;
