import { Box } from "@mui/material";
import React, { useState } from "react";
import OrderModalTitle from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_OrderModalTitle/OrderModalTitle";
import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import OrderModalSubtitle from "components/OrderResponse/_OrderModalSubtitle/OrderModalSubtitle";
import { BpRadio } from "../../NewFolder/NewFolder";
import OrderResponseButton from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseButton/OrderResponseButton";
import ComplainTextField from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_ComplainTextField/ComplainTextField";
import * as themes from "./ComplainModalStyles";
import orderResponseStore from "store/OrderResponseStore";
import FullChatStore from "store/FullChatStore";

const list = [
    { label: "Мошенничество или обмен контактными данными", value: "fraud" },
    { label: "Неуместное или оскорбительное поведение", value: "behavior" },
    { label: "Спам", value: "spam" },
    { label: "Другое", value: "other" },
];

const ComplainModal = (props) => {
    const [otherValue, setOtherValue] = useState("");

    const [isOther, setIsother] = useState("");

    const [disabled, setDisabled] = useState(true);

    const handleClick = () => {
        if (isOther !== "other") {
            FullChatStore.SendComplain(props.sendJsonMessage, isOther);
        } else FullChatStore.SendComplain(props.sendJsonMessage, otherValue);

        handleClose();
    };

    const ClickRadio = (item) => {
        if (item !== "" && item !== "other") {
            setDisabled(false);
        } else setDisabled(true);

        setIsother(item);
    };

    const handleSetOtherValue = (item) => {
        if (item.length > 0) setDisabled(false);
        else setDisabled(true);

        setOtherValue(item);
    };

    const handleClose = () => {
        setIsother("");
        setDisabled(true);
        setOtherValue("");
        props.handleClose();
    };

    return (
        <ModalSettingsSmall
            visible={props.open}
            handleClose={handleClose}
            sx={{ marginTop: "-5vh", width: "480px" }}
        >
            <Box>
                <Box sx={themes.CompainModalWrapper}>
                    <OrderModalTitle>Пожаловаться на репетитора</OrderModalTitle>
                    <OrderModalSubtitle>Выберите, пожалуйста, причину жалобы или, если нет подходящего варианта, напишите своими словами</OrderModalSubtitle>
                </Box>
                <Box>
                    {list.map((item, index) => {
                        return (
                            <Box
                                key={index}
                                sx={{ marginTop: "10px" }}
                            >
                                <Box sx={{ display: "inline-block", verticalAlign: "top" }}>
                                    <BpRadio
                                        checked={isOther === item.value}
                                        onClick={() => ClickRadio(item.value)}
                                    ></BpRadio>
                                </Box>
                                <Box sx={{ display: "inline-block", width: "270px", marginTop: "5px" }}> {item.label}</Box>
                            </Box>
                        );
                    })}
                    {isOther === "other" && (
                        <ComplainTextField
                            otherValue={otherValue}
                            setOtherValue={handleSetOtherValue}
                        />
                    )}
                    <Box sx={themes.CompainModalButtonWrapper}>
                        <OrderResponseButton
                            text="Отправить жалобу"
                            variant="contained"
                            handleClick={handleClick}
                            disabled={disabled}
                        />
                    </Box>
                </Box>
            </Box>
        </ModalSettingsSmall>
    );
};

export default ComplainModal;
