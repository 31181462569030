import React, { memo } from "react";
import * as themes from "./StateThreeThemes";
import bronzelvl from "assets/PromoTutorAssets/PNG/bronzelvl.png";
import goldlvl from "assets/PromoTutorAssets/PNG/goldlvl.png";
import platinumlvl from "assets/PromoTutorAssets/PNG/platinumlvl.png";
import Box from "@mui/material/Box";

const StateThree = ({ children, ...props }) => {
    return (
        <Box>
            <Box sx={themes.imgbronzelvl}>
                <img
                    src={bronzelvl}
                    alt={"bronze"}
                />
            </Box>

            <Box sx={themes.imgplatinumlvl}>
                <img
                    src={platinumlvl}
                    alt={"platinum"}
                />
            </Box>

            <Box sx={themes.imggoldlvl}>
                <img
                    src={goldlvl}
                    alt={"gold"}
                />
            </Box>
        </Box>
    );
};

export default memo(StateThree);
