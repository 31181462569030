import { styleProcessing } from "API/plugins/Utilities";

export const HowWorkMainBlock = (theme) => {
    let commonStyle = {
        paddingTop: "140px",
        width: "1216px",
        height: "583px",
        background: "#F7F7F7",
        display: "inline-flex",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "328px",
            height: "fit-content",
            paddingTop: "50px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const HowWorkSubBlock = (theme) => {
    let commonStyle = {
        width: "1216px",
        height: "583px",
        borderRadius: "40px",
        border: "2px solid var(--main-purple, #6212FF)",
        position: "relative",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "328px",
            border: "0px solid var(--main-purple, #6212FF)",
            borderRadius: "0px",
            height: "fit-content",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const HowWorkTitle = (theme) => {
    let commonStyle = {
        color: "#222",
        fontSize: "40px",
        fontFamily: "Arial",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "135%",
        paddingTop: "51px",
        paddingLeft: "51px",
        width: "599px",
        height: "54px",
        position: "absolute",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "328px",
            height: "31px",
            paddingTop: "0px",
            paddingLeft: "0px",
            fontSize: "24px",
            position: "block",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const cardTitle = {
    color: "#222",
    fontSize: "20px",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
};

export const cardTop = {
    width: "547px",
    height: "116px",
    paddingTop: "145px",
    paddingLeft: "51px",
};

export const bulbIconContainer = {
    width: 30,
    height: 30,
    left: 175.15,
    top: 520,
    position: "absolute",
    borderRadius: "7px",
    border: "1px solid",
    transform: "rotate(-16deg)",
    borderColor: "#6212FF",
};

export const bulbIconImg = {
    marginTop: "-1px",
    marginLeft: "-1px",
    transform: "rotate(19deg)",
};

export const clockIconContainer = {
    width: 30,
    height: 30,
    left: 564.5,
    top: 283,
    position: "absolute",
    borderRadius: "7px",
    border: "1px solid",
    transform: "rotate(216deg)",
    borderColor: "#FF9212",
};

export const clockIconImg = {
    marginTop: "-2px",
    marginLeft: "-2px",
    transform: "rotate(151deg)",
};

export const rateIcon = {
    top: "123px",
    left: "411px",
    width: "35px",
    height: "21px",
    position: "absolute",
    gap: "8.159px",

    borderRadius: "4.895px",
    background: "#6212FF",
    transform: "rotate(-21.495deg)",
    paddingBottom: "3px",
};

export const rateIconText = {
    color: "#FFF",
    fontSize: "11,422px",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
    textAlign: "center",
};

export const CardInfoImg = {
    top: "118px",
    left: "706px",
    width: "447px",
    height: "205px",
    position: "absolute",
};

// RequestCreateEnd Icon

export const RequestCreateEndImg = {
    top: "300px",
    left: "787px",
    width: "148px",
    height: "163px",
    position: "absolute",
};

// RequestCreateSteps Icon

export const RequestCreateStepsImg = {
    top: "276px",
    left: "883px",
    width: "127px",
    height: "210px",
    position: "absolute",
};

export const bonusIcon = {
    top: "61px",
    left: "1020px",
    width: "39.36px",
    height: "24.4px",
    position: "absolute",
    padding: "3.826px 10.712px 4.591px 7.652px",
    borderRadius: "24.486px",
    background: "var(--main-gradient, linear-gradient(227deg, #04C83B 8.70%, #12AAFF 89.43%))",
    transform: "rotate(22.125deg)",
};

export const bonusIconText = {
    color: "var(--main-white, #FFF)",
    fontFamily: "Arial",
    fontSize: "10.712px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%" /* 16.069px */,
    textAlign: "center",
    paddingTop: "5px",
    paddingLeft: "1px",
};
