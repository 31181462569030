import { createTheme } from "@mui/material/styles";

export const ordersSearchBodyBlockStyle = {
    width: 1216,
    margin: "auto",
    marginTop: 40,
    marginBottom: 70,
    display: "flex",
    flexDirection: "column",
    gap: 20,
};

export const ordersSearchHeaderStyle = {
    color: "var(--text-black, #222)",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "140%",
};

export const ordersSearchBodyBlockContainerStyle = {
    display: "flex",
    flexDirection: "row",
    gap: 20,
};

export const ordersSearchInfoBlockStyle = {
    position: "relative",
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
    width: "fit-content",
};

export const progressContainerStyle = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
};

export const CircularProgressTheme = createTheme({
    components: {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: "#6212FF",
                },
            },
        },
    },
});
