import React, { useState, useRef } from "react";
import "./SDragNDrop.css";
import DragNDropPlusIcon from "assets/CommonAssets/SVG/DragNDropPlusIcon";
import DragNDropCloseIcon from "assets/CommonAssets/SVG/DragNDropCloseIcon";
import { Box } from "@mui/material";
import * as theme from "./SDragNDropThemes";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";
const SDragAndDrop = ({ width = "424px", image, setImage, imageSize, setImageSize, imageName, setImageName }) => {
    const medias = useMedias();

    const fileInputRef = useRef(null);

    const { t } = useTranslation();

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];

        if (file.type.startsWith("image/")) {
            const reader = new FileReader();

            reader.onload = () => {
                setImage(reader.result);
            };

            reader.readAsDataURL(file);
        } else {
            alert("Please drop an image file.");
        }
    };

    const handleFileSelect = (e) => {
        const selectedFile = e.target.files && e.target.files[0];

        if (selectedFile && selectedFile.type.startsWith("image/")) {
            const reader = new FileReader();

            reader.onload = () => {
                setImage(reader.result);
                setImageSize((selectedFile.size / (1024 * 1024)).toFixed(2));
                setImageName(selectedFile.name);
            };

            reader.readAsDataURL(selectedFile);
        } else if (selectedFile) {
            alert("Please select an image file.");
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const removeImage = () => {
        setImage(null);
    };

    const handlePlusClick = () => {
        fileInputRef.current.click();
    };

    const [hovered, setHovered] = useState(false);

    return (
        <Box>
            <Box
                sx={theme.SDragNDropArea}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onMouseOver={() => {
                    setHovered(true);
                }}
                onMouseOut={() => {
                    setHovered(false);
                }}
            >
                <Box
                    sx={theme.SDragNDropAreaInside}
                    onClick={handlePlusClick}
                >
                    <p>
                        {medias.sm && t("teacherSettings.questionnaire.dragAndDropFile")}
                        {t("teacherSettings.questionnaire.dragAddFile")}
                    </p>

                    <DragNDropPlusIcon hovered={hovered} />
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileSelect}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                    />
                </Box>
            </Box>
            {image && (
                <Box sx={theme.SDragNDropImageBox}>
                    <Box sx={theme.SDragNDropImage}>
                        <img
                            src={image}
                            alt="Preview"
                            className="preview-image"
                            style={theme.SDragNDropImageStyle}
                        />
                        <Box sx={theme.SDragNDropImageData}>
                            <p style={theme.SDragNDropImageName}>{imageName}</p>
                            <p style={theme.SDragNDropImageSize}>{imageSize}MB</p>
                        </Box>
                    </Box>
                    <button onClick={removeImage}>
                        <DragNDropCloseIcon />
                    </button>
                </Box>
            )}
        </Box>
    );
};

export default SDragAndDrop;
