import { observer } from "mobx-react-lite";
import * as themes from "./ChatsThemes";
import * as textThemes from "themes/TextThemes";
import { Avatar, Box, Typography } from "@mui/material";
import { ChatIcon } from "assets/MiniChat/SVG/ChatIcon";
import { Create } from "assets/MiniChat/SVG/Сreate";
import FullChatStore from "store/FullChatStore";
import { memo } from "react";

const Chats = observer(({ children, ...props }) => {
    const ListChat = () => {
        if (FullChatStore.newFolder !== undefined) {
            if (FullChatStore.newFolder.length > 0 || FullChatStore.FolderChanged)
                return FullChatStore.newFolder.map((itemchat, index) => (
                    <Box
                        key={index}
                        sx={themes.ChatItem}
                    >
                        <Avatar
                            src={itemchat.avatar}
                            sx={themes.Avatar}
                        />
                        <Typography sx={textThemes.Body2Black}>{itemchat.name}</Typography>
                    </Box>
                ));
            else
                return (
                    props.folderChats &&
                    props.folderChats.infocards.map((itemchat, index) => (
                        <Box
                            key={index}
                            sx={themes.ChatItem}
                        >
                            <Avatar
                                src={itemchat.avatar}
                                sx={themes.Avatar}
                            />
                            <Typography sx={textThemes.Body2Black}>{itemchat.name}</Typography>
                        </Box>
                    ))
                );
        } else
            return (
                props.folderChats &&
                props.folderChats.infocards.map((itemchat, index) => (
                    <Box
                        key={index}
                        sx={themes.ChatItem}
                    >
                        <Avatar
                            src={itemchat.avatar}
                            sx={themes.Avatar}
                        />
                        <Typography sx={textThemes.Body2Black}>{itemchat.name}</Typography>
                    </Box>
                ))
            );
    };

    return (
        <Box sx={themes.ChatBox}>
            <Box sx={themes.ChatsLabel}>
                <ChatIcon />
                <Typography sx={textThemes.Button14Black}>Чаты</Typography>
            </Box>
            <Box onClick={() => FullChatStore.SetFolderProcessingStep(1)}>
                <Box sx={themes.AddChatButton}>
                    <Box sx={themes.CreateIcon}>
                        <Create color="#6212FF" />
                    </Box>
                    <Typography sx={textThemes.Body2Purple}>Добавить чат</Typography>
                </Box>
                <Box sx={themes.ChatListContainer}>{ListChat()}</Box>
            </Box>
        </Box>
    );
});

export default memo(Chats);
