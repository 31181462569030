import { createTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

export const DialogContainerTheme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: "rgba(0, 0, 0, 0)!important",
                    boxShadow: "none!important",
                },
            },
        },
    },
});

export const dialogContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "10px",
};

export const cardContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
    padding: "40px 40px 30px 40px",
    flexDirection: "column",
    gap: "20px",
    borderRadius: "20px",
    background: "var(--main-white, #FFF)",
    width: 480,
    height: "fit-content",
};

export const headerBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "20px",
    width: "100%",
};

export const OrdersDisciplineSearcherTheme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "--TextField-brandBorderColor": "#9E9EB0",
                    "--TextField-brandBorderHoverColor": "#6212FF",
                    "--TextField-brandBorderFocusedColor": "#6212FF",
                    "& label.Mui-focused": {
                        color: "var(--TextField-brandBorderFocusedColor)",
                    },
                    "& .Mui-focused": {
                        background: "#FFFFFF",
                    },
                    width: "100%",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: "var(--TextField-brandBorderColor)",
                },
                root: {
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: "var(--TextField-brandBorderHoverColor)",
                    },
                    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: "var(--TextField-brandBorderFocusedColor)",
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    "&:before, &:after": {
                        borderBottom: "2px solid var(--TextField-brandBorderColor)",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                    },
                    "&.Mui-focused:after": {
                        borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    "&:before": {
                        borderBottom: "1px solid var(--TextField-brandBorderColor)",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderBottom: "1px solid var(--TextField-brandBorderHoverColor)",
                    },
                    "&.Mui-focused:after": {
                        borderBottom: "1px solid var(--TextField-brandBorderFocusedColor)",
                    },
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                // Name of the slot
                root: {
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px",
                    width: "100%",
                    flex: "none",
                    order: 1,
                    flexGrow: 0,
                    "&.MuiInputBase-root": {
                        border: "1px!important solid #9E9EB0",
                        height: "34px",
                        borderRadius: "12px",
                        paddingLeft: 0,
                        paddingTop: 0,
                    },
                    ".MuiInputBase-input": {
                        width: "-webkit-fill-available!important",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "150%",
                        fontFeatureSettings: "'tnum' on, 'lnum' on",
                        color: "var(--text-black, #222222)",
                        flex: "none",
                        order: 0,
                        flexGrow: 0,
                    },
                },
            },
        },
    },
});

export const headerTextStyle = {
    color: "var(--text-black, #222)",
    textAlign: "center",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "140%",
};

export const contentBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "30px",
    width: "100%",
};

export const listHeaderTextStyle = {
    color: "var(--text-black, #222)",
    textAlign: "center",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
};

export const contentBlockStyle = {
    display: "flex",
    width: "100%",
    minHeight: "384px",
    maxHeight: "384px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "12px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
        width: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        background: "rgba(187, 187, 200, 0.50)",
        height: "60px",
    },
};

export const FiltersOrdersDisciplineTheme = createTheme({
    components: {
        MuiFormControl: {
            styleOverrides: {
                // Name of the slot
                root: {
                    ".MuiSvgIcon-root": {
                        color: "#6212FF",
                    },
                    ".MuiButtonBase-root": {
                        paddingTop: 0,
                        paddingBottom: 0,
                        "&:hover": {
                            background: "none!important",
                        },
                    },
                    ".MuiTypography-root": {
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "150%",
                        display: "flex",
                        alignItems: "center",
                        color: "#222222",
                        flex: "none",
                        order: 0,
                        flexGrow: 1,
                        "&:hover": {
                            color: "#6212FF",
                        },
                    },
                    ".MuiFormGroup-root": {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "0px",
                        gap: "12px",
                    },
                },
            },
        },
    },
});

export const ModalActionButtonTheme = (mode) =>
    createTheme({
        components: {
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        display: "flex",
                        padding: "10px 20px 11px 20px!important",
                        width: 192,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        borderRadius: "42px!important",
                        background: mode === 0 ? "var(--grey-separator, #EEEEF0)" : "var(--main-purple, #6212FF)",
                        "&:hover": {
                            background: "#6212FF!important",
                            borderColor: "#6212FF!important",
                            ".MuiTypography-root": {
                                color: "white!important",
                            },
                        },
                        "&:active": {
                            background: "#6212FF!important",
                            borderColor: "#6212FF!important",
                            ".MuiTypography-root": {
                                color: "white!important",
                            },
                        },
                    },
                },
            },
        },
    });

export const buttonTextStyle = (mode) => {
    return {
        textTransform: "none",
        color: mode === 0 ? "var(--text-black, #222)" : "var(--text-white, #FFF)",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "150%",
    };
};

export const actionsBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    width: "100%",
};

export const actionsContainerStyle = {
    display: "flex",
    alignItems: "flex-start",
    gap: "16px",
};

export const CloseIconTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid #9E9EB0",
                    borderRadius: "50%",
                    background: "transparent",
                    width: "24px",
                    height: "24px",
                    "&:hover": {
                        background: "#transparent!important",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "#transparent!important",
                        borderColor: "#6212FF",
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    padding: 0,
                    color: "#FFFFFF",
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    "&:hover": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                    "&:active": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                },
            },
        },
    },
});
