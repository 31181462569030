import { styleProcessing } from "API/plugins/Utilities";

export const ProfileForms = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
};

export const NameFields = {
    width: "305px",
    "& .MuiInput-underline:before": {
        borderBottom: "1px solid #BBBBC8",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#6212FF",
    },
    "& .MuiInputBase-input": {
        fontSize: "16px",
        lineHeight: "150%",
        fontWeight: 400,
        textTransform: "none",
    },
};

export const FormBlock = {
    width: "100%",
    minWidth: "288px",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
};

export const RowBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "row",
        gap: "30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const DateBlock = {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
};

export const EditContentPaper = {
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
    borderRadius: "0 0 10px 10px",
    padding: "3px 5px 12px 5px",
};

export const EditContentList = {
    p: 0,
    m: 0,
    border: "none",
    maxHeight: "200px",
    position: "relative",
    overflow: "auto",
    "&::-webkit-scrollbar": {
        width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        background: "rgba(187, 187, 200, 0.50)",
        height: "60px",
    },
};

export const EditContentListItem = (condition) => {
    return {
        color: condition ? "#9E9EB0" : "#222",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "140%" /* 21px */,
        borderRadius: "5px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "7px 10px 6px 10px",
        ":hover": {
            color: "#6212FF",
            background: "rgba(187, 187, 200, 0.20)",
        },
    };
};

export const EditPaper = (width) => {
    return {
        borderRadius: "10px",
        width: width,
        border: "none",
        boxShadow: "none",
        p: 0,
        mt: "10px",
    };
};

export const EditButton = (openPopper, condition, width) => {
    return {
        maxWidth: "641px",
        width: "100%",
        height: "34px",
        borderBottom: openPopper ? "2px solid #6212FF" : "1px solid #BBBBC8",
        borderRadius: 0,
        textTransform: "none",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "150%",
        color: condition ? "#9E9EB0" : "#222",
        background: "#fff",
        ":hover": {
            color: condition ? "#9E9EB0" : "#222",
            background: "#fff",
        },
        pb: "10px",
        pl: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    };
};
