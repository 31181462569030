import { styled } from "@mui/system";
import { Box } from "@mui/material";

const StyledWhiteBox = styled(Box)(({ width, height, minHeight, padding, flexDirection }) => ({
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "20px",
    borderColor: "#EEEEF0",
    width: width,
    height: height,
    backgroundColor: "white",
    display: "flex",
    flexDirection: flexDirection ? flexDirection : "row",
    // alignItems: 'stretch',
    // overflow: 'hidden',
    minHeight: minHeight,
    padding: padding,
}));

export default StyledWhiteBox;
