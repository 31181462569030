import { createTheme } from "@mui/material/styles";

export const TagTheme = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                // Name of the slot
                root: {
                    height: "27px",
                    marginRight: "10px",
                    marginBottom: "10px",
                    "&:hover": {
                        background: "#170F26",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "#170F26",
                        borderColor: "#6212FF",
                    },
                },
            },
        },
    },
});

export const tagText = {
    color: "rgba(255, 255, 255, 0.90)",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150 %",
};
