import { Avatar, Box, Checkbox, ClickAwayListener, Grid, IconButton, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";
import { IconCloseMini } from "assets/MiniChat/SVG/IconCloseMini";
import { IconBackMini } from "assets/MiniChat/SVG/IconBackMini";
import { IconOpenBig } from "assets/MiniChat/SVG/IconOpenBig";
import { IconSettingsMini } from "assets/MiniChat/SVG/IconSettingsMini";
import React, { useState, useMemo, memo } from "react";
import MiniChatStore from "store/MiniChatStore";
import * as TextThemes from "themes/TextThemes";
import * as Styles from "./TeacherFieldStyles";
import { useNavigate } from "react-router-dom";
import { ArrowForward } from "assets/MiniChat/SVG/ArrowForward";
import { observer } from "mobx-react-lite";
import { Check } from "assets/MiniChat/SVG/Check";
import FullChatStore from "store/FullChatStore";
import { findChatById } from "components/ChatBodyBlock/CommonData/helper_functions";
import SButton from "components/UI/SButton";
import { GetTime } from "components/ChatBodyBlock/GridFieldChat/PartnerPanel/PartnerPanel";

const URL = "wss://study-buddy.ru/chats/ws";

const TeacherField = observer(({ children, ...props }) => {
    const [delchat, setDelchat] = useState(false);

    const UpdateChat = useMemo(() => {
        return findChatById(FullChatStore.tabsData.chats, FullChatStore.id);
    }, [FullChatStore.tabsData]);

    const changeArchive = () => {
        UpdateChat.folders.includes(2) ? FullChatStore.СhangeArchive(props.sendJsonMessage, true) : FullChatStore.СhangeArchive(props.sendJsonMessage, false);
    };

    const changeMute = () => {
        FullChatStore.СhangeMute(props.sendJsonMessage, !UpdateChat.disabled_notifications);
    };

    const changePin = () => {
        FullChatStore.СhangePin(props.sendJsonMessage, !UpdateChat.pin);
    };

    const addToFolder = (index) => {
        props.sendJsonMessage({
            action: "edit_folder",
            index: index,
            rename: {
                take_change: false,
            },
            add_chat_ids: {
                chat_ids: [FullChatStore.id],
                take_change: true,
            },
            remove_chat_ids: {
                take_change: false,
            },
        });
    };

    const delFromFolder = (index) => {
        props.sendJsonMessage({
            action: "edit_folder",
            index: index,
            rename: {
                take_change: false,
            },
            add_chat_ids: {
                take_change: false,
            },
            remove_chat_ids: {
                chat_ids: [FullChatStore.id],
                take_change: true,
            },
        });
    };

    const delChat = (all) => {
        props.setPage(1);
        FullChatStore.DelChat(props.sendJsonMessage, all);
        setDelchat(false);
        handleClose();
    };

    const delHistory = (all) => {
        props.sendJsonMessage({
            action: "delete_history",
            chat_id: FullChatStore.id,
            for_all: all,
        });
        handleClose();
    };

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [open, setOpen] = useState(false);

    const [openfolders, setOpenFolders] = useState(false);

    const [openall, setOpenAll] = useState(false);

    const [checked, setChecked] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setOpenFolders(false);
        setOpenAll(false);
        setDelchat(false);
        setAnchorEl(null);
    };

    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget);
        setOpen(true);
    };

    return (
        <Box sx={Styles.TeacherField}>
            <Grid container>
                <Grid
                    item
                    xs={1}
                >
                    <IconButton
                        sx={Styles.IconBack}
                        onClick={() => {
                            props.setPage(1);
                            FullChatStore.SetToScrollMessage(null);
                            FullChatStore.SetToScrollMessageWait(null);
                        }}
                    >
                        <IconBackMini />
                    </IconButton>
                </Grid>
                <Grid
                    item
                    xs={1.5}
                >
                    <Avatar
                        src={props.avt}
                        sx={{ width: "4.4vh", height: "4.4vh", marginTop: "0.4vh" }}
                    />
                </Grid>
                <Grid
                    item
                    xs={6.3}
                    gap="0.1vw"
                    container
                >
                    <Grid
                        item
                        xs={12}
                        sx={{ fontSize: "2.2vh" }}
                    >
                        {props.teacherName}
                    </Grid>
                    {props.status === "online" ? (
                        <Grid
                            item
                            sx={{ ...TextThemes.Caption1DarkGreen, fontSize: "1.76vh" }}
                        >
                            онлайн
                        </Grid>
                    ) : (
                        <Grid
                            item
                            sx={{ ...TextThemes.Caption1DarkGrey, fontSize: "1.76vh" }}
                        >
                            в сети в {GetTime(props.status)}
                        </Grid>
                    )}
                </Grid>
                <Grid
                    item
                    xs={3.2}
                >
                    <IconButton
                        sx={Styles.IconBack}
                        onClick={handleOpen}
                    >
                        <IconSettingsMini />
                    </IconButton>
                    <IconButton
                        sx={Styles.IconBack}
                        onClick={() => {
                            MiniChatStore.SetOpen(false);
                            navigate("/chat");
                        }}
                    >
                        <IconOpenBig />
                    </IconButton>
                    <IconButton
                        sx={Styles.IconBack}
                        onClick={() => {
                            MiniChatStore.SetOpen(false);
                            FullChatStore.SetToScrollMessage(null);
                            FullChatStore.SetToScrollMessageWait(null);
                        }}
                    >
                        <IconCloseMini />
                    </IconButton>
                </Grid>
            </Grid>

            <Popper
                open={openall}
                anchorEl={anchorEl}
                sx={{ zIndex: "900" }}
            >
                <Paper sx={Styles.PaperStyles}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Box
                            sx={{
                                padding: "8px",
                                fontSize: "14px",
                            }}
                        >
                            <Box sx={{ textAlign: "center" }}>
                                <Checkbox
                                    checked={checked}
                                    onClick={() => setChecked(!checked)}
                                />{" "}
                                Удалить у всех?
                            </Box>
                            <Box sx={{ textAlign: "center" }}>
                                <Box sx={{ display: "inline-block" }}>
                                    <SButton
                                        variant="text"
                                        onClick={() => (delchat ? delChat(checked) : delHistory(checked))}
                                    >
                                        Продолжить
                                    </SButton>
                                </Box>
                                <Box sx={{ display: "inline-block" }}>
                                    <SButton
                                        variant="text"
                                        onClick={() => {
                                            setOpenAll(false);
                                            setOpen(true);
                                            setDelchat(false);
                                        }}
                                    >
                                        Назад
                                    </SButton>
                                </Box>
                            </Box>
                        </Box>
                    </ClickAwayListener>
                </Paper>
            </Popper>

            <Popper
                open={openfolders}
                anchorEl={anchorEl}
                sx={{ zIndex: "900" }}
            >
                <Paper sx={Styles.PaperStyles}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Box>
                            <MenuList sx={{ padding: "0.55vh 0.3vw 0.55vh 0.3vw" }}>
                                <Box sx={{ borderBottom: "1px solid #EEEEF0" }}>
                                    {FullChatStore.tabsData.folders.map(
                                        (item, index) =>
                                            index > 3 && (
                                                <MenuItem
                                                    key={index}
                                                    disableRipple
                                                    sx={Styles.MenuItem2}
                                                    onClick={() => {
                                                        UpdateChat.folders.includes(item.index) ? delFromFolder(item.index) : addToFolder(item.index);
                                                    }}
                                                >
                                                    <Box sx={{ width: "13vw" }}>{item.name}</Box>
                                                    {UpdateChat.folders.includes(item.index) && (
                                                        <Box>
                                                            <Check />
                                                        </Box>
                                                    )}
                                                </MenuItem>
                                            ),
                                    )}
                                </Box>
                                <MenuItem
                                    disableRipple
                                    sx={Styles.MenuItem2}
                                    onClick={() => {
                                        setOpenFolders(false);
                                        setOpen(true);
                                    }}
                                >
                                    <Box sx={{ paddingRight: "1vw", paddingTop: "0.6vh" }}>
                                        <IconBackMini />
                                    </Box>
                                    <Box>Назад</Box>
                                </MenuItem>
                            </MenuList>
                        </Box>
                    </ClickAwayListener>
                </Paper>
            </Popper>

            <Popper
                open={open}
                anchorEl={anchorEl}
                sx={{ zIndex: "900" }}
            >
                <Paper sx={Styles.PaperStyles}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Box>
                            <MenuList sx={{ padding: "0.55vh 0.3vw 0.55vh 0.3vw" }}>
                                <Box sx={{ borderBottom: "1px solid #EEEEF0" }}>
                                    <MenuItem
                                        disableRipple
                                        sx={Styles.MenuItem}
                                        onClick={() => {
                                            FullChatStore.SetShowSearchMessagesMini(true);
                                            FullChatStore.SetShowSearchMessagesResultMini(true);
                                            handleClose();
                                        }}
                                    >
                                        Искать в чате
                                    </MenuItem>
                                    <MenuItem
                                        disableRipple
                                        sx={Styles.MenuItem}
                                        onClick={() => {
                                            MiniChatStore.SetOpen(false);
                                            navigate("/chat");
                                            FullChatStore.SetMediaPage(true);
                                        }}
                                    >
                                        Смотреть файлы и ссылки
                                    </MenuItem>
                                    <MenuItem
                                        disableRipple
                                        sx={Styles.MenuItem}
                                        onClick={() => {
                                            navigate("/tutor/64902a483594e963dd20818a");
                                            handleClose();
                                        }}
                                    >
                                        Перейти в профиль
                                    </MenuItem>
                                </Box>
                                <Box sx={{ borderBottom: "1px solid #EEEEF0" }}>
                                    <MenuItem
                                        disableRipple
                                        sx={Styles.MenuItem}
                                        onClick={() => {
                                            changePin();
                                            handleClose();
                                        }}
                                    >
                                        {UpdateChat.pin ? "Открепить" : "Закрепить"} чат
                                    </MenuItem>
                                    <MenuItem
                                        disableRipple
                                        sx={Styles.MenuItem}
                                        onClick={() => {
                                            changeMute();
                                            handleClose();
                                        }}
                                    >
                                        {UpdateChat.disabled_notifications ? "Включить" : "Отключить"} уведомления
                                    </MenuItem>
                                    <MenuItem
                                        disableRipple
                                        sx={Styles.MenuItem}
                                        onClick={() => {
                                            changeArchive();
                                            handleClose();
                                        }}
                                    >
                                        {UpdateChat.folders.includes(2) ? "Убрать из архива" : "Перенести в архив"}
                                    </MenuItem>
                                    <MenuItem
                                        disableRipple
                                        sx={Styles.MenuItem}
                                        onClick={() => {
                                            setOpen(false);
                                            setOpenFolders(true);
                                        }}
                                    >
                                        Добавить в папку
                                        <Box sx={{ paddingLeft: "4vw", paddingTop: "0.6vh" }}>
                                            <ArrowForward />
                                        </Box>
                                    </MenuItem>
                                </Box>
                                <Box sx={{ borderBottom: "1px solid #EEEEF0" }}>
                                    <MenuItem
                                        disableRipple
                                        sx={Styles.MenuItem}
                                        onClick={() => {
                                            setOpen(false);
                                            setOpenAll(true);
                                        }}
                                    >
                                        Очистить историю
                                    </MenuItem>
                                    <MenuItem
                                        disableRipple
                                        sx={Styles.MenuItem}
                                        onClick={() => {
                                            setOpen(false);
                                            setDelchat(true);
                                            setOpenAll(true);
                                        }}
                                    >
                                        Удалить чат
                                    </MenuItem>
                                </Box>
                                <Box>
                                    <MenuItem
                                        disableRipple
                                        sx={Styles.MenuItem}
                                        onClick={() => {
                                            props.handleOpenBlock();
                                            setOpen(false);
                                        }}
                                    >
                                        {UpdateChat.folders.includes(3) ? "Разблокировать" : "Заблокировать"}
                                    </MenuItem>
                                    <MenuItem
                                        disableRipple
                                        sx={Styles.MenuItem}
                                        onClick={() => {
                                            props.handleOpen();
                                            setOpen(false);
                                        }}
                                    >
                                        Пожаловаться
                                    </MenuItem>
                                </Box>
                            </MenuList>
                        </Box>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </Box>
    );
});

export default memo(TeacherField);
