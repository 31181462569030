//Заказ почти готов
import React, { useState } from "react";
import UserStore from "store/UserStore";
import { observer } from "mobx-react-lite";
import AlmostDonePage from "components/SearchRequest/RightPanel/AlmostDone";
import SBButtonGroup from "components/UI/ButtonGroup";
import { Box, FormControlLabel } from "@mui/material";
import { Checkbox } from "@mui/material";
import * as theme from "./Step26Themes";
import store from "store/RequestStore";

const Step26 = () => {
    const [stepData, setStepData] = useState("");

    return (
        <Box>
            <AlmostDonePage authorized={UserStore.isAuth} />
            <SBButtonGroup
                handleNext={() => {
                    store.setStep(8, stepData);
                    store.incrementStepIndex();
                }}
                handleBack={() => store.decrementStepIndex()}
                activeStep={store.activeStepIndex}
                steps={store.storage}
            />
            <Box sx={theme.ItemWrapper}>
                <FormControlLabel
                    sx={theme.FormControlLabel}
                    control={
                        <Checkbox
                            sx={theme.Checkbox}
                            checked={true}
                            style={{ color: "#6212FF" }}
                        />
                    }
                />
                <Box sx={theme.BottomText}>Нажимая «Опубликовать», вы соглашаетесь на обработку персональных данных и условия сервиса</Box>
            </Box>
        </Box>
    );
};

export default observer(Step26);
