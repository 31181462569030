import Liza from "assets/ChatAssets/PNG/Liza.png";
import Maria from "assets/ChatAssets/PNG/Maria.png";
import Pavel from "assets/ChatAssets/PNG/Pavel.png";
import Ekater from "assets/ChatAssets/PNG/Ekater.png";
import Dima from "assets/ChatAssets/PNG/Dima.png";
import Konstantin from "assets/ChatAssets/PNG/Konstantin.png";
import Boris from "assets/ChatAssets/PNG/Pavel.png";
import Evgen from "assets/ChatAssets/PNG/Evgen.png";
import { makeAutoObservable } from "mobx";

class MiniChatStore {
    avatar = {
        Дмитрий: Dima,
        Константин: Konstantin,
        Борис: Boris,
        Егор: Evgen,
        Василий: Maria,
        Антон: Ekater,
    };

    Open = false;

    Messages = [];

    DialogFolders = [];

    Dialogs = [];

    FolderChanged = false;

    CurrentChat = 1;

    WebSocketData = {};

    newFolder = [];

    newFolderName = "";

    SetFolderName(val) {
        this.newFolderName = val;
    }

    setFolder(val) {
        this.newFolder = val;
    }

    setData(val) {
        this.WebSocketData = val;
    }

    constructor() {
        makeAutoObservable(this);
        this.Init();
    }

    SetFolderChanged(val) {
        this.FolderChanged = val;
    }

    SetOpen(val) {
        this.Open = val;
    }

    GetMessage(id) {
        return this.Messages.find((element, index, array) => element.id === id).messages;
    }

    GetDialog(id) {
        return this.Dialogs.find((element, index, array) => element.id === id);
    }

    SetCurrentChat(id) {
        this.CurrentChat = id;
    }

    SetMuted(id) {
        var index = this.Dialogs.findIndex((element, index, array) => element.id === id);

        this.Dialogs[index].muted = !this.Dialogs[index].muted;
    }

    AddDialogToFolder(folder_id, dialog_id) {
        var index = this.DialogFolders.findIndex((element, index, array) => element.id === folder_id);

        var contain = this.DialogFolders[index].items.findIndex((element, index, array) => element === dialog_id);

        if (contain === -1) this.DialogFolders[index].items.push(dialog_id);
        else this.DialogFolders[index].items.splice(contain, 1);

        console.log(this.DialogFolders[index]);
    }

    Init() {
        this.DialogFolders = [
            {
                id: 0,
                unread_messages: 12,
                name: "Все диалоги",
                items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            },
            {
                id: 1,
                unread_messages: 2,
                name: "Мои репетиторы",
                items: [1, 3, 4],
            },
            {
                id: 2,
                unread_messages: 0,
                name: "Архив",
                items: [1, 3, 4],
            },
            {
                id: 3,
                unread_messages: 3,
                name: "Заблокированные",
                items: [1, 3, 4],
            },
            {
                id: 4,
                unread_messages: 1,
                name: "Английский язык",
                items: [1, 3, 4],
            },
        ];
    }
}

export default new MiniChatStore();
