import { styleProcessing } from "API/plugins/Utilities";

export const CardTitle = {
    width: "450px",
};

export const WhiteLayer = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    pointerEvents: "none",
};

export const WhiteLayerContainer = {
    position: "relative",
    display: "inline-block",
    float: "right",
};

export const StyledOrderCard = (theme) => {
    let commonStyle = {
        width: "100%",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "12px",
        padding: "20px 20px 10px 20px ",
        borderRadius: "16px",
        backgroundColor: "#fff",
        boxSizing: "border-box",
        cursor: "pointer",
        boxShadow: "0px 4px 30px 0px rgba(106, 99, 118, 0.05)",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, padding: "12px 20px 16px 20px", gap: "14px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TopContentWrapper = {
    display: "flex",
    justifyContent: "space-between",
};

export const Divider = { color: "#EEEEF0" };

export const BottomBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "11px",
};

export const BottomContentWrapper = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const TooltipPopperProps = {
    sx: {
        "& .MuiTooltip-tooltip": {
            border: "none",
            color: "#222",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "140%",
            borderRadius: "10px",
            bgcolor: "#fff",
            width: "fit-content",
            boxShadow: "0px 2px 10px 0px rgba(106, 99, 118, 0.15)",
            padding: "10px",
        },
    },
};

export const TooltipButton = {
    padding: 0,
    height: "16px",
};

export const OrderCancelledBlock = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
};

export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
};

export const Avatar = {
    border: "none",
    width: "36px",
    height: "36px",
};

export const AvatarGroup = {
    padding: "0 0 12px 0",
    "& .MuiAvatar-root": {
        width: "36px",
        height: "36px",
    },
    "& .MuiAvatarGroup-avatar": {
        background: "#BBBBC8",
        color: "#fff",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "150%",
    },
};

export const SingleAvatar = {
    padding: "0 0 12px 0",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    justifyContent: "flex-end",
};

export const PhotoHidden = {
    borderRadius: 10000,
    border: "1px dashed #BBBBC8",
    width: "36px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

export const PhotoHiddenText = {
    color: "#67677A",
    fontSize: "8px",
    textAlign: "center",
};
