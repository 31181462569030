import { styleProcessing } from "API/plugins/Utilities";

export const FilterButton = (theme) => {
    let commonStyle = {
        display: "flex",
        color: "#222",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "150%",
        padding: 0,
        margin: 0,
        width: "100%",
        textTransform: "none",
        gap: "5px",
        ":hover": {
            background: "#f7f7f7",
            color: "#222",
        },
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            maxWidth: "600px",
            width: "100%",
            padding: "10px 20px",
            borderRadius: "10px",
            background: "rgba(187, 187, 200, 0.17)",
            justifyContent: "space-between",
            ":hover": {
                background: "rgba(187, 187, 200, 0.17)",
                color: "#222",
            },
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Paper = {
    borderRadius: "10px",
    width: "260px",
    boxShadow: "none",
    padding: 0,
    marginTop: "10px",
};
