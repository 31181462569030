import { Box, Typography } from "@mui/material";
import * as themes from "./PaymentHeaderThemes";
import * as textThemes from "themes/TextThemes";
import LanguageSelect from "components/Header/HeaderAccount/RightPanel/LanguageSelect/LanguageSelect";
import * as React from "react";
import { memo } from "react";
import { useMedias } from "API/mediasHook";
import { useTranslation } from "react-i18next";

const PaymentHeader = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const medias = useMedias();

    return (
        <Box>
            {medias.sm ? (
                <Box sx={themes.PaymentHeader}>
                    <Box sx={themes.PaymentHeaderContent}>
                        <Typography sx={textThemes.HeadlineH3White}>{t("main.header.paymentOrder")}</Typography>
                        <LanguageSelect />
                    </Box>
                </Box>
            ) : null}
        </Box>
    );
};

export default memo(PaymentHeader);
