import { styleProcessing } from "API/plugins/Utilities";

export const TopContentWrapper = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "row",
        gap: "24px",
        marginTop: "30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const EmptyPhotoIconWrapper = {
    borderRadius: "16px",
    height: "260px",
    width: "180px",
    background: "#EEEEF0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
};

export const TeacherQuestionnaireModalBigPhoto = {
    display: "flex",
    flexDirection: "column",
    borderRadius: "16px",
};

export const pointSentenceWrapper = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginTop: "20px",
    marginBottom: "16px",
};

export const MidleContentWrapper = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    marginTop: "60px",
    maxWidth: "598px",
    width: "100%",
    boxSizing: "border-box",
};

export const MidleContentTextWrapper = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const BottomContentWrapper = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        marginTop: "80px",
        maxWidth: "598px",
        width: "100%",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            marginTop: "40px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const BottomContentTextWrapper = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const BottomContentDottedTextWrapper = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
};

export const VideoButton = (theme) => {
    let commonStyle = {
        textTransform: "none",
        maxWidth: "598px",
        width: "100%",
        height: "302px",
        borderRadius: "10px",
        background: "#EEEEF0",
        flexDirection: "column",
        alignItems: "center",
        gap: "12px",
        ":hover": {
            backgroundColor: "rgba(240,239,255,0.58)",
        },
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            height: "166px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const VideoRulesText = { marginTop: "20px", whiteSpace: "pre" };

export const SButton = (theme) => {
    let commonStyle = {
        marginTop: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "180px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const CaptionBox = {
    maxWidth: "393px",
    width: "100%",
};

export const Input = {
    display: "none",
};

export const VideoCaption = {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    textAlign: "center",
};

export const VideoButtons = {
    maxWidth: "598px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
};

export const HelperText = {
    marginTop: "5px",
};
