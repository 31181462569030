export const ButtonsContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
};

export const YesButton = (condition) => {
    return {
        width: "100%",
        boxShadow: "none",
        background: condition === "yes" ? "#fff" : "rgba(255, 255, 255, 0.20)",
        position: "relative",
        zIndex: "2",
        color: condition === "yes" ? "#222" : "#FFF",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "150%",
        ":hover": {
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "150%",
            boxShadow: "none",
            background: condition === "yes" ? "#fff" : "rgba(255, 255, 255, 0.45)",
            color: condition === "yes" ? "#222" : "#FFF",
        },
    };
};

export const NoButton = (condition) => {
    return {
        width: "100%",
        boxShadow: "none",
        background: condition === "no" ? "#fff" : "rgba(255, 255, 255, 0.20)",
        position: "relative",
        zIndex: "2",
        color: condition === "no" ? "#222" : "#FFF",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "150%",
        ":hover": {
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "150%",
            boxShadow: "none",
            background: condition === "no" ? "#fff" : "rgba(255, 255, 255, 0.45)",
            color: condition === "no" ? "#222" : "#FFF",
        },
    };
};

export const increaseMotivationButton = (condition) => {
    return {
        width: "100%",
        boxShadow: "none",
        background: condition === "other" ? "#fff" : "rgba(255, 255, 255, 0.20)",
        position: "relative",
        zIndex: "2",
        color: condition === "other" ? "#222" : "#FFF",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "150%",
        ":hover": {
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "150%",
            boxShadow: "none",
            background: condition === "other" ? "#fff" : "rgba(255, 255, 255, 0.45)",
            color: condition === "other" ? "#222" : "#FFF",
        },
    };
};
