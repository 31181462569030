import React from "react";

import { Stack, Typography } from "@mui/material";

import SButton from "components/UI/SButton";

const Actions = ({ close, send }) => {
    return (
        <Stack
            spacing={1.5}
            justifyContent="flex-end"
            direction="row"
        >
            <SButton
                variant="secondary"
                onClick={close}
            >
                Отменить
            </SButton>
            <SButton style={{ height: "36px" }}>
                <Typography
                    variant="standardWhite"
                    onClick={send}
                >
                    Отправить
                </Typography>
            </SButton>
        </Stack>
    );
};

export default Actions;
