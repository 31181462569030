import React from "react";
import Accordion from "@mui/material/Accordion";
import { AccordionDetails, AccordionSummary, Typography, Box } from "@mui/material";
import * as themes from "./AccordionThemes";
import * as textThemes from "themes/TextThemes";
import ExpandIconOn from "assets/ReferralPageAssets/SVG/expandIconOn";
import ExpandIconOff from "assets/ReferralPageAssets/SVG/expandIconOff";
import { useState } from "react";
import AccordionPoint from "assets/ReferralPageAssets/SVG/AccordionPoint";
import { memo } from "react";
import { useMedias } from "../../../API/mediasHook";

const AccordionRef = ({ ...props }) => {
    const [current, setCurrent] = useState(-1);

    const changeState = (panel) => (e, newValue) => {
        setCurrent(newValue ? panel : -1);
    };

    const medias = useMedias();

    return (
        <Box sx={themes.AccordionOuterBlock(medias)}>
            <Accordion
                sx={themes.accordionForm}
                expanded={current === 1}
                onChange={changeState(1)}
            >
                <AccordionSummary
                    sx={themes.AccordionSummary}
                    expandIcon={current === 1 ? <ExpandIconOff /> : <ExpandIconOn />}
                >
                    <Typography sx={textThemes.Button20BoldBlack}>{props.header}</Typography>
                </AccordionSummary>

                <AccordionDetails sx={themes.AccordionDetails(medias)}>
                    <Box sx={themes.PointBlock}>
                        {props.points && (
                            <Box sx={themes.PointIcon}>
                                <AccordionPoint />
                            </Box>
                        )}
                        <Typography style={themes.accordionText}>{props.text}</Typography>
                    </Box>
                    {props.text1 && (
                        <Box sx={themes.PointBlock}>
                            {props.points && (
                                <Box sx={themes.PointIcon}>
                                    <AccordionPoint />
                                </Box>
                            )}
                            <Typography style={themes.accordionText}>{props.text1}</Typography>
                        </Box>
                    )}
                    {props.text2 && (
                        <Box sx={themes.PointBlock}>
                            {props.points && (
                                <Box sx={themes.PointIcon}>
                                    <AccordionPoint />
                                </Box>
                            )}
                            <Typography style={themes.accordionText}>{props.text2}</Typography>
                        </Box>
                    )}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default memo(AccordionRef);
