export const detailsExample = [
    {
        label: "Предмет",
        info: "Информационная безопасность",
    },
    {
        label: "Тема",
        info: "Программный модуль автоматизации проверки безопасности приложений iOS",
    },
    {
        label: "Для кого",
        info: "Студент",
    },
    {
        label: "Курс",
        info: "4 курс",
    },
    {
        label: "ВУЗ",
        info: "МГУ",
    },
    {
        label: "Сроки",
        info: "16 января 9:00–6 февраля 19:00",
    },
    {
        label: "Детали",
        info: "50 страниц, 20 источников",
    },
    {
        label: "Дополнительные требования",
        info: "Оригинальность 80%, проверка по antiplagiat.ru",
    },
    {
        label: "Сопутствующие услуги",
        info: "Рецензия, выступление к защите",
    },
    {
        label: "Стоимость",
        info: "до 5 000 рублей",
    },
    {
        label: "",
        info: {
            comment:
                "Здравствуйте! Работа мне нужна 6 февраля, до этого хотела бы получать два раза материалы на утверждение. Прикрепляю фото, посмотрите, пожалуйста, на требования к работе от преподавателя.",
            files: [
                {
                    size: "3.2MB",
                    type: "pdf",
                    name: "Требования к дипломной работе.pdf",
                },
                {
                    size: "0.5MB",
                    type: "img",
                    name: "Скрин правил.png",
                },
            ],
        },
    },
];
