export const SheduleHeaderContainer = {
    position: "sticky",
    maxWidth: "1132px",
    width: "100%",
    marginLeft: "64px",
    marginBottom: "16px",
    zIndex: 10001,
};

export const OrganizerHeaderWrapper = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const headerLeftSideWrapper = {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
};

export const headerTitleAndBackButtonWrapper = {
    display: "flex",
    gap: "40px",
    alignItems: "center",
};

export const weekaChangePanelWrapper = {
    display: "flex",
    alignItems: "center",
    gap: "12px",
};

export const arrowButtonWrapper = { display: "flex", gap: "10px" };

export const rightSideHeaderWrapperTimeAndChangeButton = {
    display: "flex",
    alignItems: "center",
};

export const changeButtonSizeWrapper = {
    width: "210px",
    height: "36px",
    marginLeft: "18px",
};
