import { styled, createTheme } from "@mui/material";
// import createTheme from "@mui/material";

export const SingleFilterIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: "16px!important",
    height: "16px!important",
    border: "1px solid #6212FF",
    background: "#FFFFFF",
    "input:hover ~ &": {
        backgroundColor: "#6212FF",
    },
}));

export const SingleFilterCheckedIcon = styled(SingleFilterIcon)({
    backgroundColor: "#6212FF",
    "&:before": {
        width: "16px!important",
        height: "16px!important",
    },
    "input:hover ~ &": {
        backgroundColor: "#6212FF",
    },
});

export const pageStyle = {
    background: "#DDDDDD",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "fit-content",
};

export const typographyTheme = createTheme({
    typography: {
        title: {
            color: "var(--text-black, #222)",
            /* Headline/H5 */
            fontFamily: "inherit",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "150%" /* 27px */,
        },
        title1: {
            color: "var(--text-black, #222)",
            fontFamily: "inherit",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "150%" /* 24px */,
        },
        title2: {
            color: "var(--text-black, #222)",
            fontFamily: "inherit",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "150%" /* 24px */,
        },
        grayText: {
            color: "var(--text-grey, #9E9EB0)",
            fontFamily: "inherit",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "150%" /* 21px */,
            display: "inline-block",
        },
        standard: {
            color: "var(--text-black, #222)",
            fontFamily: "inherit",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "150%" /* 21px */,
            display: "inline-block",
        },
        standardFat: {
            color: "var(--text-black, #222)",
            fontFamily: "inherit",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "150%" /* 21px */,
            display: "inline-block",
        },
        standardWhite: {
            color: "var(--text-white, #FFF)",
            fontFamily: "inherit",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "150%" /* 21px */,
            display: "inline-block",
        },
        standard1: {
            color: "var(--text-black, #222)",
            fontFamily: "inherit",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "150%" /* 21px */,
            display: "inline-block",
        },
        standard2: {
            color: "var(--text-black, #222)",
            fontFamily: "inherit",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "150%" /* 21px */,
        },
        conditions: {
            color: "var(--text-light-black, #4E4E4E)",
            fontFamily: "inherit",
            fontSize: 13,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "140%" /* 21px */,
        },
    },
});
