export const WhiteContainer = {
    padding: "20px",
    width: "249px",
    height: "99px",
    borderRadius: "20px",
    backgroundColor: "white",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.05)",
    cursor: "pointer",
    "&:hover": {
        transform: "scale(1.02)",
        transition: "all 0.2s ease-out",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
};

export const LeftBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    paddingTop: "4px",
};

export const RatingBlock = {
    display: "flex",
    alignItems: "center",
    gap: "4px",
};

export const TopBlock = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
};

export const TooltipPopperProps = {
    sx: {
        "& .MuiTooltip-tooltip": {
            border: "none",
            color: "#222",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "140%",
            borderRadius: "10px",
            bgcolor: "#fff",
            width: "fit-content",
            boxShadow: "0px 2px 10px 0px rgba(106, 99, 118, 0.15)",
            padding: "10px",
        },
    },
};

export const TooltipButton = {
    padding: 0,
    height: "16px",
};

export const TextWithTooltip = {
    display: "flex",
    gap: "6px",
    alignItems: "center",
};
