import { IconButton, InputAdornment, TextField, Box, Typography, Drawer } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import * as styles from "./LoginModalsThemes";
import * as textStyles from "themes/TextThemes";
import SButton from "components/UI/SButton";
import VisibilityOnIcon from "assets/SettingsAssets/SVG/VisibilityOnIcon";
import VisibilityOffIcon from "assets/SettingsAssets/SVG/VisibilityOffIcon";
import { observer } from "mobx-react-lite";
import SettingsStore from "store/SettingsStore";
import UserStore from "store/UserStore";
import ModalSettings from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsBig/ModalSettings";
import { useMedias } from "API/mediasHook";
import DrawerHeader from "components/SettingsBodyBlock/StudentSettings/DrawerHeader/DrawerHeader";

const ModalCreatePassword = observer(({ children, ...props }) => {
    const medias = useMedias();

    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        const date = new Date();

        UserStore.setPassword(SettingsStore.newPassword);
        UserStore.setPasswordUpdateDate(`Задан ${date.toLocaleDateString()} в ${date.getHours()}:${date.getMinutes()}`);
        props.handleSave();
    };

    const [showNewPassword, setShowNewPassword] = React.useState(false);

    const [showNewPasswordRepeat, setShowNewPasswordRepeat] = React.useState(false);

    const handleClickShowNewPasswordRepeat = () => setShowNewPasswordRepeat((show) => !show);

    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };

    const handleMouseDownPassword1 = (event) => {
        event.preventDefault();
    };

    const { t, i18n } = useTranslation();

    const handleNewPassword = (e) => {
        SettingsStore.setNewPassword(e.target.value);
        let reg = /(?=.*[0-9])(?=.*[a-z])[0-9a-z]{8,}/gi;

        if (e.target.value.length === 0) {
            SettingsStore.setColorNewPasswordHelper(null);
            SettingsStore.setNewPasswordHelper(null);
        } else if (SettingsStore.newPassword === null || SettingsStore.newPassword === "") {
            SettingsStore.setColorNewPasswordHelper(null);
            SettingsStore.setNewPasswordHelper(null);
        } else if (!reg.test(e.target.value)) {
            SettingsStore.setColorNewPasswordHelper("red");
            SettingsStore.setNewPasswordHelper(t("main.settings.loginDetails.weakPassword"));
        } else {
            SettingsStore.setColorNewPasswordHelper("green");
            SettingsStore.setNewPasswordHelper(t("main.settings.loginDetails.strongPassword"));
        }

        if (e.target.value.length === 0) {
            SettingsStore.setColorNewPasswordRepeatHelper(null);
            SettingsStore.setNewPasswordRepeatHelper(null);
        } else if (SettingsStore.newPasswordRepeat === null || SettingsStore.newPasswordRepeat === "") {
            SettingsStore.setColorNewPasswordRepeatHelper(null);
            SettingsStore.setNewPasswordRepeatHelper(null);
        } else if (e.target.value !== SettingsStore.newPasswordRepeat && SettingsStore.newPasswordRepeat !== null) {
            SettingsStore.setColorNewPasswordRepeatHelper("red");
            SettingsStore.setNewPasswordRepeatHelper(t("main.settings.loginDetails.passwordMismatch"));
        } else if (SettingsStore.newPasswordRepeat !== null) {
            SettingsStore.setColorNewPasswordRepeatHelper("green");
            SettingsStore.setNewPasswordRepeatHelper(t("main.settings.loginDetails.passwordMatch"));
        }
    };

    const handleNewPasswordRepeat = (e) => {
        SettingsStore.setNewPasswordRepeat(e.target.value);

        if (e.target.value.length === 0) {
            SettingsStore.setColorNewPasswordRepeatHelper(null);
            SettingsStore.setNewPasswordRepeatHelper(null);
        } else if (e.target.value !== SettingsStore.newPassword) {
            SettingsStore.setColorNewPasswordRepeatHelper("red");
            SettingsStore.setNewPasswordRepeatHelper(t("main.settings.loginDetails.passwordMismatch"));
        } else {
            SettingsStore.setColorNewPasswordRepeatHelper("green");
            SettingsStore.setNewPasswordRepeatHelper(t("main.settings.loginDetails.passwordMatch"));
        }
    };

    const DrawerList = (
        <Box sx={styles.AddForm(medias)}>
            <DrawerHeader
                text={t("main.settings.loginDetails.createPassword")}
                handleClose={Close}
            />
            <Box sx={styles.LabelField(medias)}>
                <Box sx={styles.TextWrapper}>
                    <Typography sx={textStyles.Body2Black}>{t("main.settings.loginDetails.passwordDescription")}</Typography>
                    <Typography sx={textStyles.Body2Black}>{t("main.password_upper")}</Typography>
                </Box>
                <TextField
                    sx={styles.AddLoginDetailTextField("400px", SettingsStore.colorNewPasswordHelper === "red")}
                    type={showNewPassword ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPassword}
                                    onMouseDown={handleMouseDownPassword1}
                                    edge="end"
                                    disableRipple
                                >
                                    {showNewPassword ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    required
                    fullWidth
                    FormHelperTextProps={{
                        sx: styles.PasswordHelper(SettingsStore.colorNewPasswordHelper === "red"),
                    }}
                    variant="standard"
                    name="email"
                    onChange={(e) => {
                        handleNewPassword(e);
                    }}
                    placeholder={t("main.password_lower")}
                    value={SettingsStore.newPassword}
                    helperText={SettingsStore.newPasswordHelper !== null ? SettingsStore.newPasswordHelper : ""}
                />
            </Box>
            <Box sx={styles.LabelField(medias)}>
                <Typography sx={textStyles.Body2Black}>{t("main.settings.loginDetails.repeatPassword")}</Typography>
                <TextField
                    sx={styles.AddLoginDetailTextField("400px", SettingsStore.colorNewPasswordRepeatHelper === "red")}
                    type={showNewPasswordRepeat ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPasswordRepeat}
                                    onMouseDown={handleMouseDownPassword2}
                                    edge="end"
                                    disableRipple
                                >
                                    {showNewPasswordRepeat ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    required
                    fullWidth
                    variant="standard"
                    name="email"
                    FormHelperTextProps={{
                        sx: styles.PasswordHelper(SettingsStore.colorNewPasswordRepeatHelper === "red"),
                    }}
                    onChange={(e) => {
                        handleNewPasswordRepeat(e);
                    }}
                    placeholder={t("main.password_lower")}
                    value={SettingsStore.newPasswordRepeat}
                    helperText={SettingsStore.newPasswordRepeatHelper !== null ? SettingsStore.newPasswordRepeatHelper : ""}
                />
            </Box>
            <Box
                container
                sx={styles.ButtonsDrawerContainer(medias)}
            >
                <SButton
                    style={{ width: "192px" }}
                    padding="10px 20px 11px 20px"
                    variant="secondary"
                    onClick={Close}
                >
                    {t("main.cancel")}
                </SButton>
                <SButton
                    style={{ width: "192px" }}
                    padding="10px 20px 11px 20px"
                    variant="unshadowed"
                    type="submit"
                    onClick={Save}
                    disabled={
                        SettingsStore.newPassword === null ||
                        SettingsStore.newPasswordRepeat === null ||
                        SettingsStore.colorNewPasswordRepeatHelper === "red" ||
                        SettingsStore.colorNewPasswordHelper === "red" ||
                        SettingsStore.newPassword === "" ||
                        SettingsStore.newPasswordRepeat === ""
                    }
                >
                    {t("main.save")}
                </SButton>
            </Box>
        </Box>
    );

    return (
        <>
            {!medias.sm ? (
                <Drawer
                    open={props.open}
                    onClose={Close}
                >
                    {DrawerList}
                </Drawer>
            ) : (
                <ModalSettings
                    visible={props.open}
                    handleClose={Close}
                    header={t("main.settings.loginDetails.createPassword")}
                >
                    <Box sx={styles.AddForm}>
                        <Typography sx={textStyles.Body2Black}>{t("main.settings.loginDetails.passwordDescription")}</Typography>
                        <Box sx={styles.LabelField}>
                            <Typography sx={textStyles.Body2Black}>{t("main.password_upper")}</Typography>
                            <TextField
                                sx={styles.AddLoginDetailTextField("400px", SettingsStore.colorNewPasswordHelper === "red")}
                                type={showNewPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowNewPassword}
                                                onMouseDown={handleMouseDownPassword1}
                                                edge="end"
                                                disableRipple
                                            >
                                                {showNewPassword ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                fullWidth
                                FormHelperTextProps={{
                                    sx: styles.PasswordHelper(SettingsStore.colorNewPasswordHelper === "red"),
                                }}
                                variant="standard"
                                name="email"
                                onChange={(e) => {
                                    handleNewPassword(e);
                                }}
                                placeholder={t("main.password_lower")}
                                value={SettingsStore.newPassword}
                                helperText={SettingsStore.newPasswordHelper !== null ? SettingsStore.newPasswordHelper : ""}
                            />
                        </Box>
                        <Box sx={styles.LabelField}>
                            <Typography sx={textStyles.Body2Black}>{t("main.settings.loginDetails.repeatPassword")}</Typography>
                            <TextField
                                sx={styles.AddLoginDetailTextField("400px", SettingsStore.colorNewPasswordRepeatHelper === "red")}
                                type={showNewPasswordRepeat ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowNewPasswordRepeat}
                                                onMouseDown={handleMouseDownPassword2}
                                                edge="end"
                                                disableRipple
                                            >
                                                {showNewPasswordRepeat ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                fullWidth
                                variant="standard"
                                name="email"
                                FormHelperTextProps={{
                                    sx: styles.PasswordHelper(SettingsStore.colorNewPasswordRepeatHelper === "red"),
                                }}
                                onChange={(e) => {
                                    handleNewPasswordRepeat(e);
                                }}
                                placeholder={t("main.password_lower")}
                                value={SettingsStore.newPasswordRepeat}
                                helperText={SettingsStore.newPasswordRepeatHelper !== null ? SettingsStore.newPasswordRepeatHelper : ""}
                            />
                        </Box>
                        <Box
                            container
                            sx={styles.ButtonsContainer}
                        >
                            <SButton
                                style={{ width: "192px" }}
                                padding="10px 20px 11px 20px"
                                variant="secondary"
                                onClick={Close}
                            >
                                {t("main.cancel")}
                            </SButton>
                            <SButton
                                style={{ width: "192px" }}
                                padding="10px 20px 11px 20px"
                                variant="unshadowed"
                                type="submit"
                                onClick={Save}
                                disabled={
                                    SettingsStore.newPassword === null ||
                                    SettingsStore.newPasswordRepeat === null ||
                                    SettingsStore.colorNewPasswordRepeatHelper === "red" ||
                                    SettingsStore.colorNewPasswordHelper === "red" ||
                                    SettingsStore.newPassword === "" ||
                                    SettingsStore.newPasswordRepeat === ""
                                }
                            >
                                {t("main.save")}
                            </SButton>
                        </Box>
                    </Box>
                </ModalSettings>
            )}
        </>
    );
});

export default ModalCreatePassword;
