import React, { memo } from "react";
import Box from "@mui/material/Box";
import { Avatar } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import * as themes from "./AvatarAreaThemesDefault";
import { useMedias } from "../../../../API/mediasHook";

const AvatarArea = ({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box>
            <ThemeProvider theme={themes.AvatarTheme(medias)}>
                <Avatar src={props.value.AvatarSrc}></Avatar>
            </ThemeProvider>
        </Box>
    );
};

export default memo(AvatarArea);
