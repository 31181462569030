export const Tabs = {
    minHeight: "10px",
    textTransform: "none",

    "& .MuiTabs-flexContainer": {
        display: "flex",
        gap: "20px",
    },

    "& button": {
        minWidth: 0,
        padding: "0px",
        textTransform: "none",
        fontSize: "16px",
        fontWeight: "500",
        ":hover": { color: "#9E9EB0" },

        "& .MuiTabs-indicator": {
            color: "red",
            backgroundColor: "#6212FF",
            background: "#6212FF",
        },
    },
    "& button.Mui-selected": {
        color: "#222",
        backgroundColor: "none",
        background: "none",
    },
};

export const TabsIndicatorProps = {
    sx: {
        padding: "1px",
        backgroundColor: "#6212FF",
        bottom: 6,
        boxSizing: "border-box",
    },
};

export const Tab = {
    p: 0,
    textTransform: "none",
    color: "#9E9EB0",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "150%",
};
