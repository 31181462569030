const TimeIcon = function ({ ...props }) {
    return (
        <svg
            width={props.width}
            height={props.height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon">
                <g id="Vector">
                    <path
                        d="M12 1C9.82441 1 7.69767 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3165 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C22.9965 9.08371 21.8364 6.28787 19.7743 4.22574C17.7121 2.16361 14.9163 1.00355 12 1ZM12 21.2155C10.1773 21.2155 8.39561 20.6751 6.88012 19.6624C5.36463 18.6498 4.18346 17.2106 3.48595 15.5266C2.78845 13.8427 2.60595 11.9898 2.96154 10.2021C3.31712 8.41449 4.19481 6.77244 5.48363 5.48363C6.77245 4.19481 8.4145 3.31711 10.2021 2.96153C11.9898 2.60595 13.8427 2.78845 15.5266 3.48595C17.2106 4.18345 18.6498 5.36463 19.6624 6.88012C20.6751 8.39561 21.2155 10.1773 21.2155 12C21.2132 14.4434 20.2415 16.786 18.5138 18.5138C16.786 20.2415 14.4434 21.2132 12 21.2155Z"
                        fill={props.fill}
                        fillOpacity={props.fillOpacity}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.7221 5.59846C8.98836 4.75238 10.4771 4.30078 12 4.30078C14.0415 4.30301 15.9987 5.11497 17.4422 6.55852C18.8858 8.00207 19.6978 9.9593 19.7 12.0008C19.7 13.5237 19.2484 15.0124 18.4023 16.2787C17.5562 17.5449 16.3536 18.5319 14.9467 19.1147C13.5397 19.6974 11.9914 19.8499 10.4978 19.5528C9.00414 19.2557 7.63213 18.5224 6.55527 17.4455C5.4784 16.3686 4.74505 14.9966 4.44794 13.503C4.15084 12.0093 4.30332 10.4611 4.88612 9.05412C5.46891 7.64713 6.45584 6.44455 7.7221 5.59846ZM12.25 7C12.25 6.58579 11.9142 6.25 11.5 6.25C11.0858 6.25 10.75 6.58579 10.75 7V12.8C10.75 13.3247 11.1753 13.75 11.7 13.75H16.5C16.9142 13.75 17.25 13.4142 17.25 13C17.25 12.5858 16.9142 12.25 16.5 12.25H12.25V7Z"
                        fill={props.fill}
                        fillOpacity={props.fillOpacity}
                    />
                </g>
            </g>
        </svg>
    );
};

export default TimeIcon;
