import * as React from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import ButtonArrowIcon from "assets/CommonAssets/SVG/ButtonArrow";
import ArrowBackIcon from "assets/CommonAssets/SVG/arrowBack";
import Box from "@mui/material/Box";

const SButtonGroup = function ({ activeStep, steps, handleBack, handleNext, subStep }) {
    return (
        <Box sx={{ display: "flex", marginTop: "40px" }}>
            <Button
                disableRipple
                variant="contained"
                onClick={handleNext}
                sx={{
                    ":hover": { bgcolor: "#8141FF" },
                    ":pressed": { bgcolor: "#5513D7" },
                    boxShadow: "0px 4px 10px rgba(51, 12, 128, 0.2)",
                    borderRadius: "42px",
                    display: "flex",
                    pt: "11px",
                    pb: "12px",
                    pl: "24px",
                    pr: "26px",
                    gap: "11px",
                    alignItems: "center",
                    backgroundColor: "#6212FF",
                }}
            >
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "16px",
                        lineHeight: "150%",
                        fontWeight: 400,
                        textTransform: "none",
                        color: "#fff",
                    }}
                >
                    {activeStep === 8 || activeStep === steps.length - 1 ? "Опубликовать" : "Следующий вопрос"}
                </Typography>
                <ButtonArrowIcon className="ButtonArrowIcon" />
            </Button>
            {activeStep !== 0 && (
                <Button
                    disableRipple
                    style={{ order: -1, marginRight: "30px " }}
                    onClick={handleBack}
                    sx={{
                        ":hover": { bgcolor: "#DADADF" },
                        ":pressed": { bgcolor: "#DADADF" },
                        borderRadius: "42px",
                        display: "flex",
                        pt: "15px",
                        pb: "15px",
                        pl: "27px",
                        pr: "26px",
                        gap: "11px",
                        alignItems: "center",
                        backgroundColor: "#EEEEF0",
                    }}
                >
                    <ArrowBackIcon />
                </Button>
            )}
        </Box>
    );
};

export default SButtonGroup;
