export const photoBlock = {
    width: 538,
    height: 450,
    position: "relative",
};

export const FirstPhoto = {
    width: 224,
    height: 220,
    left: 211,
    position: "absolute",
};

export const SecondPhoto = {
    width: 126,
    height: 126,
    top: 50,
    position: "absolute",
};

export const ThirdPhoto = {
    width: 112,
    height: 112,
    top: 234,
    left: 426,
    position: "absolute",
};

export const FourthPhoto = {
    width: 112,
    height: 112,
    top: 264,
    left: 61,
    position: "absolute",
};

export const FifthPhoto = {
    width: 126,
    height: 126,
    top: 324,
    left: 236,
    position: "absolute",
};
