import { Drawer, Link, Typography } from "@mui/material";
import ModalSettings from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsBig/ModalSettings";
import React from "react";
import { memo } from "react";
import { observer } from "mobx-react-lite";
import * as styles from "./ModalsDeleteAccountThemes";
import * as textStyles from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import SButton from "components/UI/SButton";
import { useNavigate } from "react-router-dom";
import { useMedias } from "API/mediasHook";
import DrawerHeader from "components/SettingsBodyBlock/StudentSettings/DrawerHeader/DrawerHeader";

const ModalImportantOnAccount = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        props.handleSave();
    };

    const DrawerList = (
        <Box sx={styles.ImportantOnAccount}>
            <DrawerHeader
                text={t("main.settings.deleteAccountBlock.importantOnAccount")}
                handleClose={Close}
            />
            <Box sx={{ padding: "0 16px", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
                <Box sx={styles.somethingLeft(medias)}>
                    <Typography sx={textStyles.Body2Black}>{t("main.settings.deleteAccountBlock.beforeDelete")}</Typography>
                    <Typography sx={textStyles.Body2Green}>
                        <ul style={{ marginBottom: "20px" }}>
                            <li>Заказ в работе «Дипломная работа»</li>
                            <li>Предстоящее занятие «Английский язык»</li>
                            <li>Деньги в Кошельке</li>
                        </ul>
                    </Typography>
                    <Box sx={styles.readPolicyBlock}>
                        <Typography sx={textStyles.Body2Black}>
                            {t("main.settings.deleteAccountBlock.readPolicyPart1")}
                            <Link
                                underline="none"
                                sx={{ cursor: "pointer", color: "#6212FF" }}
                            >
                                {t("main.privacyPolicy")}
                            </Link>
                            {t("main.settings.deleteAccountBlock.readPolicyPart2")}
                        </Typography>
                    </Box>
                </Box>
                <SButton
                    variant="arrow"
                    padding="10px 20px 11px 20px"
                    style={{ width: "100%" }}
                    onClick={() => navigate("/chat")}
                >
                    {t("main.goToSupport")}
                </SButton>
            </Box>
        </Box>
    );

    return (
        <>
            {!medias.sm ? (
                <Drawer
                    open={props.open}
                    onClose={Close}
                >
                    {DrawerList}
                </Drawer>
            ) : (
                <ModalSettings
                    visible={props.open}
                    handleClose={Close}
                    header={t("main.settings.deleteAccountBlock.importantOnAccount")}
                >
                    <Box sx={styles.ImportantOnAccount}>
                        <Typography sx={textStyles.Body2Black}>{t("main.settings.deleteAccountBlock.beforeDelete")}</Typography>
                        <Box sx={styles.somethingLeft(medias)}>
                            <Typography sx={textStyles.Body2Green}>
                                <ul style={{ margin: "0" }}>
                                    <li>Заказ в работе «Дипломная работа»</li>
                                    <li>Предстоящее занятие «Английский язык»</li>
                                    <li>Деньги в Кошельке</li>
                                </ul>
                            </Typography>
                        </Box>
                        <Box sx={styles.readPolicyBlock}>
                            <Typography sx={textStyles.Body2Black}>
                                {t("main.settings.deleteAccountBlock.readPolicyPart1")}
                                <Link
                                    underline="none"
                                    sx={{ cursor: "pointer", color: "#6212FF" }}
                                >
                                    {t("main.privacyPolicy")}
                                </Link>
                                {t("main.settings.deleteAccountBlock.readPolicyPart2")}
                            </Typography>
                        </Box>
                        <SButton
                            variant="arrow"
                            padding="10px 20px 11px 20px"
                            style={{ width: "100%" }}
                            onClick={() => navigate("/chat")}
                        >
                            {t("main.goToSupport")}
                        </SButton>
                    </Box>
                </ModalSettings>
            )}
        </>
    );
});

export default memo(ModalImportantOnAccount);
