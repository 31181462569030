import React from "react";
import { FormGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import filterStore from "store/FilterStore";
import Box from "@mui/material/Box";

const SCheckboxAndToggleGroup = function ({ varstate, funcstate }) {
    const formStyle = { height: "24px", marginBottom: "20px" };

    const checkSx = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "16px",
        lineHeight: "150%",
        fontWeight: 400,
        textTransform: "none",
        color: "#222",
    };

    const weekDays = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];

    return (
        <Box>
            <FormGroup>
                {Object.entries(varstate).map(([k, v], index) => {
                    return (
                        <Box key={index}>
                            <FormControlLabel
                                style={formStyle}
                                control={
                                    <Checkbox
                                        checked={v.selected}
                                        onChange={(event, newInputValue) => {
                                            filterStore.setSubmitButtonFilter(false);
                                            funcstate({
                                                ...varstate,
                                                [k]: { ...varstate[k], selected: newInputValue },
                                            });
                                        }}
                                        style={{ color: "#6212FF" }}
                                    />
                                }
                                label={v.title}
                                sx={checkSx}
                            />
                            <Box
                                sx={{
                                    marginTop: "-8px",
                                    marginBottom: index === Object.entries(varstate).length - 1 ? "0px" : "20px",
                                    marginLeft: "28px",
                                }}
                            >
                                {v.selected === true
                                    ? weekDays.map((day, index) => {
                                          return (
                                              <ToggleButton
                                                  key={index}
                                                  sx={{
                                                      "&:hover": {
                                                          borderRadius: "10px",
                                                          width: "36px",
                                                          height: "40px",
                                                          border: "none",
                                                          bgcolor: "#F0EFFF",
                                                          mr: "7px",
                                                          fontFamily: "Inter",
                                                          fontStyle: "normal",
                                                          fontSize: "14px",
                                                          lineHeight: "150%",
                                                          fontWeight: 400,
                                                          textTransform: "none",
                                                          color: "#222",
                                                      },
                                                      "&.Mui-selected:hover": {
                                                          borderRadius: "10px",
                                                          width: "36px",
                                                          height: "40px",
                                                          border: "1px solid #6212FF",
                                                          bgcolor: "#F0EFFF",
                                                          mr: "7px",
                                                          fontFamily: "Inter",
                                                          fontStyle: "normal",
                                                          fontSize: "14px",
                                                          lineHeight: "150%",
                                                          fontWeight: 400,
                                                          textTransform: "none",
                                                          color: "#6212FF",
                                                      },
                                                      "&.Mui-selected": {
                                                          borderRadius: "10px",
                                                          width: "36px",
                                                          height: "40px",
                                                          border: "1px solid #6212FF",
                                                          bgcolor: "#fff",
                                                          mr: "7px",
                                                          fontFamily: "Inter",
                                                          fontStyle: "normal",
                                                          fontSize: "14px",
                                                          lineHeight: "150%",
                                                          fontWeight: 400,
                                                          textTransform: "none",
                                                          color: "#6212FF",
                                                      },
                                                      borderRadius: "10px",
                                                      width: "36px",
                                                      height: "40px",
                                                      border: "none",
                                                      bgcolor: "rgba(187, 187, 200, 0.1)",
                                                      mr: "7px",
                                                      fontFamily: "Inter",
                                                      fontStyle: "normal",
                                                      fontSize: "14px",
                                                      lineHeight: "150%",
                                                      fontWeight: 400,
                                                      textTransform: "none",
                                                      color: "#222",
                                                  }}
                                                  value={day}
                                                  selected={v.list[index]}
                                                  onChange={(event, newInputValue) => {
                                                      funcstate({
                                                          ...varstate,
                                                          [k]: {
                                                              ...varstate[k],
                                                              list: varstate[k].list.map((day, i) => (i === index ? !v.list[index] : day)),
                                                          },
                                                      });
                                                  }}
                                              >
                                                  {day}
                                              </ToggleButton>
                                          );
                                      })
                                    : null}
                            </Box>
                        </Box>
                    );
                })}
            </FormGroup>
        </Box>
    );
};

export default SCheckboxAndToggleGroup;
