import React from "react";

const MicroOffIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9999 2.25C9.37573 2.25 7.24994 4.37579 7.24994 7V12C7.24994 12.4142 7.58573 12.75 7.99994 12.75C8.41415 12.75 8.74994 12.4142 8.74994 12V7C8.74994 5.20421 10.2042 3.75 11.9999 3.75C13.7957 3.75 15.2499 5.20421 15.2499 7V7.3C15.2499 7.39125 15.2662 7.47869 15.2961 7.55958L3.39944 19.4636C3.10663 19.7566 3.10678 20.2314 3.39977 20.5242C3.69275 20.817 4.16762 20.8169 4.46043 20.5239L6.38241 18.6007C7.69777 19.7772 9.38418 20.5507 11.2499 20.7155V23C11.2499 23.4142 11.5857 23.75 11.9999 23.75C12.4142 23.75 12.7499 23.4142 12.7499 23V20.7153C17.0337 20.3351 20.4 16.7299 20.4 12.3484V10.6484C20.4 10.2342 20.0642 9.89844 19.65 9.89844C19.2358 9.89844 18.9 10.2342 18.9 10.6484V12.3484C18.9 16.1542 15.8058 19.2484 12 19.2484C10.2525 19.2484 8.6636 18.6024 7.44472 17.5378L8.88974 16.0919C9.72447 16.8177 10.8162 17.25 11.9999 17.25C14.6241 17.25 16.7499 15.1242 16.7499 12.5V12C16.7499 11.5858 16.4141 11.25 15.9999 11.25C15.5857 11.25 15.2499 11.5858 15.2499 12V12.5C15.2499 14.2958 13.7957 15.75 11.9999 15.75C11.2245 15.75 10.5111 15.4809 9.9538 15.0271L20.6004 4.37392C20.8932 4.08093 20.8931 3.60606 20.6001 3.31326C20.3071 3.02045 19.8322 3.0206 19.5394 3.31358L16.6788 6.176C16.2889 3.94483 14.3431 2.25 11.9999 2.25ZM5.09991 10.6484C5.09991 10.2342 4.76413 9.89844 4.34991 9.89844C3.9357 9.89844 3.59991 10.2342 3.59991 10.6484V12.3484C3.59991 13.5093 3.83026 14.6088 4.2594 15.6212C4.42106 16.0025 4.86127 16.1806 5.24263 16.019C5.624 15.8573 5.8021 15.4171 5.64043 15.0357C5.28957 14.208 5.09991 13.3076 5.09991 12.3484V10.6484Z"
                fill="#6212FF"
            />
        </svg>
    );
};

export default MicroOffIcon;
