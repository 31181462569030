import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import PromoTutorStore from "store/PromoTutorStore";
import * as themes from "./LessonInputBlockThemes";
import SingleChoiceFilter from "components/UI/SingleChoiceFilter";
import { useTranslation } from "react-i18next";

const LessonInputBlock = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const processingLesson = (event, value) => {
        PromoTutorStore.SetLesson(value);
    };

    return (
        <SingleChoiceFilter
            propOption={PromoTutorStore.optionLesson}
            propOptions={PromoTutorStore.optionsLesson}
            valueToShow={"lesson"}
            valueToBind={"ID"}
            processingChange={processingLesson}
            themeWrapper={themes.FiltersGoalTheme}
            themeLabel={themes.filtersGoalLabel}
            themeOptionContainer={themes.filtersGOContainer}
            themeIconContainer={themes.FiltersRadioContainerTheme}
            showLabel={true}
            label={t("promoPages.promoTutor.classesPerWeek")}
            placeholder={t("promoPages.promoTutor.selectFromList")}
        />
    );
});

export default memo(LessonInputBlock);
