const listGender = ["Мужчина", "Женщина", "Не имеет значения"];

const listAge = ["До 25 лет", "26-50 лет", "Старше 50 лет", "Не имеет значения"];

const classesWeekList = ["Меньше 1 (1–2 занятия в месяц)", "1 занятие", "2–3 занятия", "Более 3 занятий", "Решу после первого занятия"];

const studentTypes = ["Школьник", "Студент", "Взрослый"];

const timeToExam = ["Меньше месяца", "Несколько месяцев", "Год и больше"];

const schoolClassesList = ["1 класс", "2 класс", "3 класс", "4 класс", "5 класс", "6 класс", "7 класс", "8 класс", "9 класс", "10 класс", "11 класс"];

const univeristyClassesList = ["1 курс", "2 курс", "3 курс", "4 курс", "5 курс"];

const workNames = ["Написание дипломной работы", "Написание курсовой работы", "Написание эссе", "Репетитор по философии"];

const classesAims = ["Подготовка к олимпиаде", "Повышение успеваемости", "Подготовка к экзамену в ВУЗе", "Для себя", "Для работы", "Реферат", "Эссе"];

const classesAimsDict = {
    "Подготовка к олимпиаде": "class",
    "Повышение успеваемости": "class",
    "Подготовка к экзамену в ВУЗе": "class",
    "Для себя": "class",
    "Для работы": "class",
    Реферат: "work",
    Эссе: "work",
};

const districts = [
    "м.Фрунзенская",
    "м.Спортивная",
    "м.Университет",
    "м.Проспект Вернадского",
    "м.Юго-Западная",
    "м.Тропарёво",
    "м.Румянцево",
    "м.Саларьево",
    "м.Филатов Луг",
    "м.Прокшино",
    "м.Ольховая",
    "м.Коммунарка",
];

const subjects = ["Математика", "Макроэкономика", "Микроэкономика", "Микробиология", "Математический анализ", "Международное право", "Маркетинг"];

const universities = ["МГУ", "ВШЭ", "МГИМО", "МФТИ"];

const faculties = ["Бизнес-информатика", "Экономика", "Физика"];

const myUniversity = "МГУ";

const universityType = ["Колледж", "ВУЗ"];

const deadlineType = ["Указать период", "Срочный заказ"];

const originalityPercentage = ["21-30%", "31-40%", "41-50%", "51-60%", "61-70%", "71-80%", "81-90%", "91-99%", "Не важно"];

const antiplagiatMethod = ["по antiplagiat.ru (бесплатный доступ)", "по eTXT"];

export {
    classesAimsDict,
    faculties,
    subjects,
    antiplagiatMethod,
    originalityPercentage,
    deadlineType,
    universityType,
    myUniversity,
    timeToExam,
    universities,
    districts,
    classesAims,
    workNames,
    schoolClassesList,
    studentTypes,
    classesWeekList,
    listAge,
    listGender,
    univeristyClassesList,
};
