import React, { memo } from "react";
import * as themes from "./StateOneThemes";
import Profiles from "assets/PromoTutorAssets/PNG/Profiles.png";
import Box from "@mui/material/Box";

const StateOne = ({ children, ...props }) => {
    return (
        <Box sx={themes.StateOne}>
            <img
                src={Profiles}
                alt={"profileImage"}
            />
        </Box>
    );
};

export default memo(StateOne);
