import React from "react";
import { FormControlLabel, Typography, Box } from "@mui/material";
import * as themes from "components/SettingsBodyBlock/TutorSettings/MainForm/MainFormThemes";
import IOSSwitch from "components/UI/IOSSwitch";
import * as textThemes from "themes/TextThemes";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { useMedias } from "API/mediasHook";

const NotificationItem = observer(({ title, description, itemKey }) => (
    <Box sx={themes.NotificationsItem}>
        <Box sx={themes.placesSwitchText}>
            <Typography sx={textThemes.Button16Black}>{title}</Typography>
            <Typography sx={textThemes.Body2Grey}>{description}</Typography>
        </Box>
        <FormControlLabel
            sx={themes.NotificationsSwitcher}
            control={
                <IOSSwitch
                    checked={TeacherQuestionnaireStore.teacher.emailNotifications[itemKey]}
                    onChange={(event) => {
                        TeacherQuestionnaireStore.setEmailNotifications(itemKey, event.target.checked);
                    }}
                />
            }
        />
    </Box>
));

const Notifications = observer((children, ...props) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const emailNotifications = [
        {
            title: t("teacherSettings.settings.classesSchedule"),
            description: t("teacherSettings.settings.notificationsAboutClasses"),
            key: "classesSchedule",
        },
        {
            title: t("teacherSettings.settings.messagesFromStudents"),
            description: t("teacherSettings.settings.notificationsAboutMessages"),
            key: "studentMessages",
        },
        {
            title: t("teacherSettings.settings.notificationsAboutOrders"),
            description: t("teacherSettings.settings.notificationsAboutStatuses"),
            key: "orders",
        },
        {
            title: t("teacherSettings.settings.notificationsAboutResponses"),
            description: t("teacherSettings.settings.notificationsAboutNewResponses"),
            key: "responses",
        },
        {
            title: t("teacherSettings.settings.notificationsAboutPayments"),
            description: t("teacherSettings.settings.notificationsAboutBillings"),
            key: "payments",
        },
        {
            title: t("teacherSettings.settings.updatesAndAdvices"),
            description: t("teacherSettings.settings.getToKnowFirst"),
            key: "updates",
        },
    ];

    const studyBuddyInfo = [
        {
            title: t("teacherSettings.settings.allowTeam"),
            description: t("teacherSettings.settings.interviewsAndQuestionnaire"),
            key: "serviceImprovement",
        },
    ];

    return (
        <Box sx={themes.SmallItem(medias)}>
            <Box sx={themes.ItemContent(medias)}>
                <Typography sx={medias.sm ? textThemes.HeadlineH41Black : textThemes.HeadlineH5Black}>{t("teacherSettings.settings.notifications")}</Typography>
                <Box sx={themes.BottomBlock}>
                    <Box sx={themes.verticalDescriptionBlock}>
                        <Typography sx={textThemes.HeadlineH4Black}>{t("teacherSettings.settings.byEmail")}</Typography>
                        <Box sx={themes.verticalDescriptionBlock}>
                            {emailNotifications.map((item, index) => (
                                <NotificationItem
                                    key={index}
                                    title={item.title}
                                    description={item.description}
                                    itemKey={item.key}
                                />
                            ))}
                        </Box>
                    </Box>
                    <Box sx={themes.verticalDescriptionBlock}>
                        <Typography sx={textThemes.HeadlineH4Black}>{t("teacherSettings.settings.infoAboutStudyBuddy")}</Typography>
                        {studyBuddyInfo.map((item, index) => (
                            <NotificationItem
                                key={index}
                                title={item.title}
                                description={item.description}
                                itemKey={item.key}
                            />
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
});

export default memo(Notifications);
