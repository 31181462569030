import React from "react";

const DemoIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon/24/demo">
                <path
                    id="demo"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.99951 1.25C5.43597 1.25 3.62794 1.75612 2.5325 3.16454C1.48308 4.51379 1.24951 6.51072 1.24951 9V15C1.24951 17.4893 1.48308 19.4862 2.5325 20.8355C3.62794 22.2439 5.43597 22.75 7.99951 22.75H15.9995C18.5631 22.75 20.3711 22.2439 21.4665 20.8355C22.5159 19.4862 22.7495 17.4893 22.7495 15V9C22.7495 6.51072 22.5159 4.51379 21.4665 3.16454C20.3711 1.75612 18.5631 1.25 15.9995 1.25H7.99951ZM2.74951 9C2.74951 6.48928 3.01594 4.98621 3.71653 4.08546C4.37108 3.24388 5.56306 2.75 7.99951 2.75H15.9995C18.436 2.75 19.6279 3.24388 20.2825 4.08546C20.9831 4.98621 21.2495 6.48928 21.2495 9V15C21.2495 17.5107 20.9831 19.0138 20.2825 19.9145C19.6279 20.7561 18.436 21.25 15.9995 21.25H7.99951C5.56306 21.25 4.37108 20.7561 3.71653 19.9145C3.01594 19.0138 2.74951 17.5107 2.74951 15V9ZM12.5125 5.96039C12.2241 5.69029 11.7756 5.69029 11.4871 5.96039L8.48715 8.76996C8.18481 9.0531 8.16926 9.52772 8.4524 9.83005C8.73554 10.1324 9.21016 10.1479 9.51249 9.8648L11.2498 8.23775V13C11.2498 13.4142 11.5856 13.75 11.9998 13.75C12.414 13.75 12.7498 13.4142 12.7498 13V8.23775L14.4871 9.8648C14.7895 10.1479 15.2641 10.1324 15.5472 9.83005C15.8304 9.52772 15.8148 9.0531 15.5125 8.76996L12.5125 5.96039ZM6.23754 15.7965C5.84468 15.6652 5.41978 15.8772 5.28849 16.2701C5.1572 16.6629 5.36924 17.0879 5.7621 17.2191C9.80638 18.5707 14.1932 18.5707 18.2375 17.2191C18.6304 17.0879 18.8424 16.6629 18.7111 16.2701C18.5799 15.8772 18.155 15.6652 17.7621 15.7965C14.0264 17.0449 9.97325 17.0449 6.23754 15.7965Z"
                    fill="#6212FF"
                />
            </g>
        </svg>
    );
};

export default DemoIcon;
