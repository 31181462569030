import React, { memo } from "react";
import * as themes from "./ContextMenuContentThemes";
import { ClickAwayListener, MenuItem, MenuList, Paper, Popper, Box } from "@mui/material";
import FullChatStore from "store/FullChatStore";
import { ArrowForward } from "assets/MiniChat/SVG/ArrowForward";
import { useNavigate } from "react-router-dom";

const ContextMenuContent = ({ children, ...props }) => {
    const navigate = useNavigate();

    const handleSearch = () => {
        FullChatStore.SetChatID(props.menuChatID);
        FullChatStore.SetParticipantID(props.menuParticipantID);
        FullChatStore.SetShowSearchMessagesModal(true);
        FullChatStore.SetShowSearchMessagesResultMini(true);
        props.handleClose();
    };

    const message_remove_archive = {
        action: "remove_from_archive",
        chat_id: props.menuChatID,
    };

    const message_add_archive = {
        action: "add_to_archive",
        chat_id: props.menuChatID,
    };

    const message_change_notification = {
        action: "disable_notifications",
        chat_id: props.menuChatID,
        disabled_notifications: !props.UpdateChat.disabled_notifications,
    };

    const message_change_pin = {
        action: "pin_chat",
        chat_id: props.menuChatID,
        pin: !props.UpdateChat.pin,
    };

    const changeMute = () => {
        FullChatStore.SetMenuChatId(props.menuChatID);
        props.sendJsonMessage(message_change_notification);
    };

    const changePin = () => {
        FullChatStore.SetMenuChatId(props.menuChatID);
        props.sendJsonMessage(message_change_pin);
    };

    const changeArchive = () => {
        props.UpdateChat.folders.includes(2) ? props.sendJsonMessage(message_remove_archive) : props.sendJsonMessage(message_add_archive);
    };

    const handleOpenBlock = () => {
        props.SetOpenModalBlock(true);
    };

    const handleReportOpen = () => {
        props.SetOpenReportModal(true);
    };

    return (
        <Popper
            open={props.open}
            anchorEl={props.anchor}
            sx={themes.Popper}
        >
            <Paper sx={themes.Paper}>
                <ClickAwayListener onClickAway={props.handleClose}>
                    <Box>
                        <MenuList sx={{ padding: "0.55vh 0.3vw 0.55vh 0.3vw" }}>
                            <Box style={{ borderBottom: "1px solid #EEEEF0" }}>
                                <MenuItem
                                    disableRipple
                                    sx={themes.MenuItem}
                                    onClick={handleSearch}
                                >
                                    Искать в чате
                                </MenuItem>
                                <MenuItem
                                    disableRipple
                                    sx={themes.MenuItem}
                                    onClick={() => {
                                        FullChatStore.SetOpen(false);
                                        navigate("/chat");
                                        FullChatStore.SetMediaPage(true);
                                    }}
                                >
                                    Смотреть файлы и ссылки
                                </MenuItem>
                                <MenuItem
                                    disableRipple
                                    sx={themes.MenuItem}
                                    onClick={() => {
                                        navigate("/tutor/64902a483594e963dd20818a");
                                        props.handleClose();
                                    }}
                                >
                                    Перейти в профиль
                                </MenuItem>
                            </Box>
                            <Box style={{ borderBottom: "1px solid #EEEEF0" }}>
                                <MenuItem
                                    disableRipple
                                    sx={themes.MenuItem}
                                    onClick={() => {
                                        changePin();
                                        props.handleClose();
                                    }}
                                >
                                    {props.UpdateChat.pin ? "Открепить" : "Закрепить"} чат
                                </MenuItem>
                                <MenuItem
                                    disableRipple
                                    sx={themes.MenuItem}
                                    onClick={() => {
                                        changeMute();
                                        props.handleClose();
                                    }}
                                >
                                    {props.UpdateChat.disabled_notifications ? "Включить" : "Отключить"} уведомления
                                </MenuItem>
                                <MenuItem
                                    disableRipple
                                    sx={themes.MenuItem}
                                    onClick={() => {
                                        changeArchive();
                                        props.handleClose();
                                    }}
                                >
                                    {props.UpdateChat.folders.includes(2) ? "Убрать из архива" : "Перенести в архив"}
                                </MenuItem>
                                <MenuItem
                                    disableRipple
                                    sx={themes.MenuItem}
                                    onClick={() => {
                                        props.setOpen(false);
                                        props.SetOpenChatFoldersModal(true);
                                    }}
                                >
                                    Добавить в папку
                                    <Box style={{ paddingLeft: "4vw", paddingTop: "0.6vh" }}>
                                        <ArrowForward />
                                    </Box>
                                </MenuItem>
                            </Box>
                            <Box style={{ borderBottom: "1px solid #EEEEF0" }}>
                                <MenuItem
                                    disableRipple
                                    sx={themes.MenuItem}
                                    onClick={() => {
                                        props.setOpen(false);
                                        props.SetOpenDeleteForAllModal(true);
                                    }}
                                >
                                    Очистить историю
                                </MenuItem>
                                <MenuItem
                                    disableRipple
                                    sx={themes.MenuItem}
                                    onClick={() => {
                                        props.setOpen(false);
                                        props.SetOpenChatDeleteModal(true);
                                        props.SetOpenDeleteForAllModal(true);
                                    }}
                                >
                                    Удалить чат
                                </MenuItem>
                            </Box>
                            <Box>
                                <MenuItem
                                    disableRipple
                                    sx={themes.MenuItem}
                                    onClick={() => {
                                        handleOpenBlock();
                                        props.setOpenOuter(false);
                                    }}
                                >
                                    {props.UpdateChat.folders.includes(3) ? "Разблокировать " : "Заблокировать "} специалиста
                                </MenuItem>
                                <MenuItem
                                    disableRipple
                                    sx={themes.MenuItem}
                                    onClick={() => {
                                        handleReportOpen();
                                        props.setOpenOuter(false);
                                    }}
                                >
                                    Пожаловаться
                                </MenuItem>
                            </Box>
                        </MenuList>
                    </Box>
                </ClickAwayListener>
            </Paper>
        </Popper>
    );
};

export default memo(ContextMenuContent);
