export const Popper = {
    zIndex: "900",
};

export const Paper = {
    marginLeft: "500px",
    marginTop: "-20px",
    backgroundColor: "white",
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
    p: "12px 16px",
};

export const MenuItem = {
    paddingLeft: "10px",
    borderRadius: "1vh",
    height: "4.12vh",
    fontSize: "1.7vh",
    ":hover": {
        color: "black",
    },
};
