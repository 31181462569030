import { TextField, Typography } from "@mui/material";
import * as textStyles from "themes/TextThemes";
import * as styles from "components/SettingsBodyBlock/StudentSettings/Profile/ProfileForms/ProfileFormsThemes";
import React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import SettingsStore from "store/SettingsStore";
import { observer } from "mobx-react-lite";
import { memo } from "react";

const LastNameForm = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={styles.FormBlock}>
            <Typography sx={textStyles.Body2Black}>{t("main.settings.profile.lastName")}</Typography>
            <TextField
                onChange={(event) => {
                    SettingsStore.setTemporaryProfileInfo("surname", event.target.value);
                }}
                value={SettingsStore.TemporaryUserInfo.surname}
                placeholder={t("main.notSpecified_she")}
                sx={styles.NameFields}
                variant="standard"
                name="surname"
            />
        </Box>
    );
});

export default memo(LastNameForm);
