import { Typography, styled } from "@mui/material";

export const NoReviews = styled(Typography)({
    marginTop: "5px",
    fontFamily: "inherit",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
});

export const cardWrapper = {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "10px",
};

export const LeftSideWrapper = { display: "flex", width: "100%" };

export const LeftSideWrapperWithOutAvatarWrapper = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
};

export const NameOnlineStatusWrapper = { display: "flex" };

export const RatingWrapper = {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginTop: "4px",
};

export const Divider = {
    marginTop: "12px",
    color: "#EEEEF0",
    width: "100%",
};

export const ComplaineDismissWrapper = {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
};

export const RightSideWrapper = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",

    gap: "10px",
    marginLeft: "40px",
};

export const PriceCashbackWrapper = {
    display: "flex",
    flexWrap: "wrap",
    gap: "11px",
    justifyContent: "center",
    alignItems: "top",
    width: "230px",
    marginTop: "-2px",
};
