import { styleProcessing } from "API/plugins/Utilities";

export const NextLesson = (theme) => {
    let commonStyle = {
        borderRadius: "20px",
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
        maxWidth: "970px",
        width: "100%",
        padding: "20px 20px 15px 20px",
        background: "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start",
            gap: "18px",
            padding: "20px 16px 16px 14px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Grey = {
    color: "#77778A",
    fontSize: "12px",
};

export const Popper = {
    "& .MuiTooltip-tooltip": {
        border: "none",
        color: "#222",
        fontFamily: "Inter",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "400",
        borderRadius: "10px",
        bgcolor: "#fff",
        boxShadow: "0px 2px 10px 0px rgba(106, 99, 118, 0.15)",
        padding: "10px",
        textAlign: "center",
    },

    "& .MuiTooltip-arrow": {
        color: "#FFFFFF",
    },
};

export const Avatar = {
    width: "36px",
    height: "36px",
};

export const StudentTextBox = {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
};

export const StudentNameBox = {
    display: "flex",
    alignItems: "center",
    gap: "4px",
};

export const IconBox = {
    height: "fit-content",
    display: "flex",
    alignItems: "center",
};

export const StudentBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        alignItems: "center",
        gap: "8px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const SubjectAimContainer = {
    display: "flex",
    flexDirection: "column",
    gap: 0,
};

export const LeftPanel = (theme) => {
    let commonStyle = {
        display: "flex",
        alignItems: "center",
        gap: "40px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            gap: "16px",
            alignItems: "left",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ButtonsBlock = {
    display: "flex",
    alignItems: "center",
    gap: "20px",
};

export const StartButtonStyle = { width: "100%" };

export const ChatButton = { marginLeft: "auto" };

export const Divider = {
    width: "1px",
    height: "36px",
    background: "rgba(218, 218, 223, 0.50)",
};
