import { Box, ClickAwayListener, Grid, IconButton, InputAdornment, MenuItem, MenuList, Paper, Popper, TextField } from "@mui/material";
import { Folder } from "assets/MiniChat/SVG/Folder";
import { IconBackMini } from "assets/MiniChat/SVG/IconBackMini";
import SButton from "components/UI/SButton";
import React, { useState, useEffect } from "react";
import * as Styles from "components/MiniChat/OneChat/TeacherField/TeacherFieldStyles";
import Chats from "./Chats";
import * as Styles2 from "./EditFolderStyles";
import MiniChatStore from "store/MiniChatStore";
import { IconSettingsMini } from "assets/MiniChat/SVG/IconSettingsMini";
import useWebSocket from "react-use-websocket";
import { observer } from "mobx-react-lite";
import FullChatStore from "store/FullChatStore";

const URL = "wss://study-buddy.ru/chats/ws";

const EditFolder = (props) => {
    const { sendMessage, sendJsonMessage, lastMessage, lastJsonMessage, readyState } = useWebSocket(URL, {
        share: true,
    });

    const [foldername, SetFolderName] = useState(MiniChatStore.newFolderName ? MiniChatStore.newFolderName : props.folderChats ? props.folderChats.name : "");

    const message_create = {
        action: "create_folder",
        name: foldername,
        chats: MiniChatStore.newFolder ? MiniChatStore.newFolder.map((item) => item.chat_id) : null,
    };

    const message_delete = {
        action: "remove_folder",
        index: props.folderChats ? props.folderChats.index : null,
    };

    const message_edit = {
        action: "edit_folder",
        index: props.folderChats ? props.folderChats.index : null,
        rename: {
            name: MiniChatStore.newFolderName ? MiniChatStore.newFolderName : props.folderChats ? props.folderChats.name : null,
            take_change: !!MiniChatStore.newFolderName,
        },
        add_chat_ids: {
            take_change: false,
        },
        remove_chat_ids: {
            take_change: false,
        },
    };

    const message_update = {
        action: "get_data",
    };

    useEffect(() => {
        if (lastJsonMessage && "meta_info" in lastJsonMessage) {
            if (lastJsonMessage.meta_info.action === "remove_folder") {
                if (lastJsonMessage.data.success === true) {
                    MiniChatStore.SetFolderName("");
                    MiniChatStore.setFolder();
                    MiniChatStore.SetFolderChanged(false);
                    sendJsonMessage(message_update);
                } else console.log("error");
            } else if (lastJsonMessage.meta_info.action === "create_folder") {
                if (lastJsonMessage.data.success === true) {
                    MiniChatStore.SetFolderName("");
                    MiniChatStore.setFolder();
                    MiniChatStore.SetFolderChanged(false);
                    sendJsonMessage(message_update);
                } else {
                    console.log("error");
                }
            }

            if (lastJsonMessage.meta_info.action === "edit_folder") {
                if (lastJsonMessage.data.success === true) {
                    MiniChatStore.SetFolderName("");
                    MiniChatStore.setFolder();
                    MiniChatStore.SetFolderChanged(false);
                    sendJsonMessage(message_update);
                } else {
                    console.log("error");
                }
            }

            if (lastJsonMessage.meta_info.action === "get_data") {
                FullChatStore.SetTabsData(lastJsonMessage.data);
                props.setPage(1);
            }
        }
    }, [lastJsonMessage]);

    const [open, setOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const [disabledname, setDisabled] = useState(props.folderChats ? props.folderChats.name !== "" : false);

    const handleChangeName = (e) => {
        props.folderChats && MiniChatStore.SetFolderChanged(true);
        SetFolderName(e.target.value);
        MiniChatStore.SetFolderName(e.target.value);
    };

    const handleDisabled = () => {
        setDisabled(false);
    };

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const handleOpen = (e) => {
        setAnchorEl(anchorEl ? null : e.currentTarget);
        setOpen(true);
    };

    const ItemInInfocards = (data, chat_id) => {
        if (data.find((val) => val.chat_id === chat_id) !== undefined) return false;
        else return true;
    };

    const changeFolder = () => {
        if (MiniChatStore.newFolder || MiniChatStore.newFolderName) {
            if (props.folderChats && foldername !== "") {
                if (MiniChatStore.newFolder) {
                    let add = [];

                    MiniChatStore.newFolder.map((item) => {
                        if (ItemInInfocards(props.folderChats.infocards, item.chat_id)) add.push(item.chat_id);
                    });
                    let del = [];

                    props.folderChats.infocards.map((item) => {
                        if (ItemInInfocards(MiniChatStore.newFolder, item.chat_id)) del.push(item.chat_id);
                    });
                    sendJsonMessage({
                        action: "edit_folder",
                        index: props.folderChats ? props.folderChats.index : null,
                        rename: {
                            name: MiniChatStore.newFolderName ? MiniChatStore.newFolderName : props.folderChats ? props.folderChats.name : null,
                            take_change: !!MiniChatStore.newFolderName,
                        },
                        add_chat_ids: {
                            chat_ids: add,
                            take_change: add.length > 0,
                        },
                        remove_chat_ids: {
                            chat_ids: del,
                            take_change: del.length > 0,
                        },
                    });
                } else {
                    sendJsonMessage(message_edit);
                }
            } else if (foldername !== "") {
                sendJsonMessage(message_create);
            }
        }
    };

    return (
        <>
            <Popper
                anchorEl={anchorEl}
                open={open}
                sx={{ zIndex: "900" }}
                placement="bottom-start"
            >
                <Paper sx={Styles2.PaperStyles}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList sx={{ padding: "0.65vh 0.33vw 0.65vh 0.33vw" }}>
                            <Box>
                                <MenuItem
                                    disableRipple
                                    sx={Styles.MenuItem}
                                    onClick={() => {
                                        handleDisabled();
                                        handleClose();
                                    }}
                                >
                                    Переименовать
                                </MenuItem>
                                <MenuItem
                                    disableRipple
                                    sx={Styles2.MenuItem}
                                    onClick={() => {
                                        handleClose();
                                        sendJsonMessage(message_delete);
                                    }}
                                >
                                    Удалить
                                </MenuItem>
                            </Box>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
            <Box sx={{ ...Styles.TeacherField, marginBottom: "2.45vh" }}>
                <Grid container>
                    <Grid
                        item
                        xs={1}
                    >
                        <IconButton
                            sx={Styles.IconBack}
                            onClick={() => {
                                props.setPage(1);
                                MiniChatStore.SetFolderName("");
                                MiniChatStore.setFolder();
                            }}
                        >
                            <IconBackMini />
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        xs={8.5}
                        sx={Styles2.Folder}
                    >
                        Папка
                    </Grid>
                    <Grid
                        item
                        xs={2.5}
                        sx={{ paddingTop: "1.28vh" }}
                    >
                        <SButton
                            variant="text"
                            disabled={!MiniChatStore.FolderChanged}
                            onClick={changeFolder}
                        >
                            <Box sx={{ fontSize: "1.9vh" }}>Готово </Box>
                        </SButton>
                    </Grid>
                </Grid>
            </Box>
            <TextField
                placeholder="Название папки"
                value={foldername}
                disabled={disabledname}
                onChange={handleChangeName}
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Folder />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment>
                            <IconButton
                                onClick={handleOpen}
                                disabled={!props.folderChats}
                            >
                                <IconSettingsMini />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                sx={Styles2.TextStyles}
                margin="none"
            />
            <Chats
                setPage={props.setPage}
                folderChats={props.folderChats}
            />
        </>
    );
};

export default observer(EditFolder);
