import React, { memo, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import UserStore from "./store/UserStore";
import AuthorizationStore from "store/AuthorizationStore";
import { observer } from "mobx-react-lite";
import CatalogPage from "./pages/catalog/CatalogPage";
import ChatPage from "./pages/ChatPage/ChatPage";
import MainPage from "./pages/MainPromo/MainPromoPage";
import TeacherPage from "./pages/tutor/TeacherPage";
import Balance from "pages/Balance/BalancePage";
import ReferralPromoPage from "./pages/ReferralPromo/ReferralPromoPage";
import Notifications from "pages/Notifications/NotificationsPage";
import Footer from "./components/Footer/Footer";
import Calendar from "./components/CustomCalendar/CustomCalendar";
import OrdersPage from "./pages/orders/OrdersPage";
import CashbackPromoPage from "./pages/CashbackPromo/CashbackPromoPage";
import FavouritesPage from "./pages/favourites/FavouritesPage";
import NewPassword from "pages/NewPassword/NewPassword";
import OrderPage from "./pages/OrderPage/OrderPage";
import OrdersSearchPage from "./pages/OrdersSearchPage/OrdersSearchPage";
import TeacherQuestionnairePage from "./pages/TeacherQuestionnaire/TeacherQuestionnairePage";
import SchedulePage from "./pages/Schedule/SchedulePage";
import TutorPromoPage from "./pages/TutorPromo/TutorPromoPage";
import RulesStudent from "./pages/RulesStudent/RulesStudentPage";
import RulesTutor from "./pages/RulesTutor/RulesTutorPage";
import DiplomaChat from "pages/DiplomaChats/DiplomaChat";
import TeacherQuestionnaireDonePage from "./pages/TeacherQuestionnaire/TeacherQuestionnaireDonePage";
import OrdersResponsesPage from "./pages/OrdersResponsesPage/OrdersResponsesPage";
import PageError404 from "./pages/ErrorPages/PageError404";
import PageError400 from "./pages/ErrorPages/PageError400";
import PageError403 from "./pages/ErrorPages/PageError403";
import PageError500 from "./pages/ErrorPages/PageError500";
import VideoChatPage from "./pages/VideoChatPage/VideoChatPage";
import MiniChat from "components/MiniChat/MiniChat";
import MyOrderPage from "pages/orders/Order/OrderPage";
import Header from "./components/Header/Header";
import SearchRequest from "./pages/SearchRequest/SearchRequest";
import SettingsPage from "./pages/Settings/SettingsPage";
import MyClasses from "./pages/MyClasses/MyClassesPage";
import Account from "./pages/Account/AccountPage";
import PaymentPage from "./pages/Payment/PaymentPage";
import AuthorizationPage from "./pages/Authorization/AuthorizationPage";
import WelcomeBackSnackbar from "./components/UI/WelcomeBackSnackbar/WelcomeBackSnackbar";
import { useMedias } from "./API/mediasHook";
import ModalError from "./components/UI/ModalError/ModalError";
import TutorClassesPage from "./pages/TutorClasses/TutorClassesPage";
import TutorLevelPage from "./pages/TutorLevel/TutorLevelPage";
import TutorFeedbacksPage from "./pages/TutorFeedbacks/TutorFeedbacksPage";
import { getCookie } from "API/utils";
import ModalWeSentInstructions from "./components/AuthorizationBodyBlock/ModalWeSentInstructions/ModalWeSentInstructions";
import PaidServicesPage from "./pages/PaidServices/PaidServicesPage";
import NotificationsStore from "./store/NotificationsStore";
import cities from "./API/dictionaries/cities";
import { useTranslation } from "react-i18next";
import Middleware from "./components/Middleware";
import { middlewares } from "./router/Middlewares";
import CheckHasQuestionnaire from "./components/TeacherQuestionnaireBodyBlock/Components/CheckHasQuestionnaire";

const App = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const URL = "wss://" + process.env.REACT_APP_API_URL + "/chats/ws";

    useEffect(() => {
        const fetchData = async () => {
            if (getCookie("refreshToken")) {
                await AuthorizationStore.loadRefreshTokens();
            } else UserStore.setAuth(false);
        };

        fetchData();
    }, []);

    useEffect(() => {
        let timerId = null;

        const fetchData = () => {
            timerId = setInterval(async () => {
                try {
                    if (getCookie("refreshToken")) {
                        await AuthorizationStore.loadRefreshTokens();
                    } else {
                        UserStore.setAuth(false);
                        await AuthorizationStore.handleLogout();
                    }
                } catch {
                    UserStore.setAuth(false);
                    await AuthorizationStore.handleLogout();
                }
            }, AuthorizationStore.refreshInterval);
        };

        fetchData();

        return () => {
            clearInterval(timerId);
        };
    }, []);

    useEffect(() => {
        const fetchFunc = () => {
            if (UserStore.tokens.refreshToken) {
                NotificationsStore.FetchStreamNotificationsData();
            }
        };

        fetchFunc();
    }, [UserStore.tokens.refreshToken]);

    useEffect(() => {
        const apiKey = "cabe52a486b98b2c69a646f71b4a0f77";

        // eslint-disable-next-line no-undef
        const timeoutPromise = new Promise((resolve, reject) => {
            setTimeout(() => resolve(""), 3000);
        });

        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const { latitude, longitude } = position.coords;

                try {
                    // eslint-disable-next-line no-undef
                    const response = await Promise.race([fetch(`https://api.openweathermap.org/geo/1.0/reverse?lat=${latitude}&lon=${longitude}&appid=${apiKey}`), timeoutPromise]);

                    //console.log("response", response);

                    const data = await response.json();

                    let locatedCity = cities.find((item) => item.title === data[0].local_names.ru);

                    if (data && data.length > 0) {
                        UserStore.setCity(locatedCity ? locatedCity : { id: 605, title: "Москва", region: "Москва", country: "Россия" });
                    }
                } catch (error) {
                    UserStore.setCity({ id: 605, title: "Москва", region: "Москва", country: "Россия" });
                }
            },
            (error) => {
                UserStore.setCity({ id: 605, title: "Москва", region: "Москва", country: "Россия" });
            },
        );
    }, []);

    useEffect(() => {
        const storedLanguage = localStorage.getItem("userLanguage");

        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage.split("-")[0]);
        } else {
            const userLanguage = navigator.language || navigator.languages[0];

            localStorage.setItem("userLanguage", userLanguage);

            i18n.changeLanguage(userLanguage.split("-")[0]);
        }
    }, []);

    const medias = useMedias();

    return (
        <BrowserRouter>
            <CheckHasQuestionnaire />
            <Header />
            <Routes>
                <Route
                    path="/"
                    element={<TutorPromoPage />}
                />
                <Route
                    path="/orders"
                    element={<OrdersPage />}
                />
                <Route
                    path="/orders-search"
                    element={<OrdersSearchPage />}
                />
                <Route
                    path="/orders-responses"
                    element={<OrdersResponsesPage />}
                />
                <Route
                    path="/order/:id"
                    element={<OrderPage />}
                />
                <Route
                    path="/myorder/:id"
                    element={<MyOrderPage />}
                />
                <Route
                    path="/video-chat"
                    element={<VideoChatPage />}
                />
                <Route
                    path="/calendar"
                    element={<Calendar />}
                />
                <Route
                    path="/myclasses"
                    element={<MyClasses />}
                />
                <Route
                    path="/cashback"
                    element={<CashbackPromoPage />}
                />
                <Route
                    path="/referral"
                    element={<ReferralPromoPage />}
                />
                <Route
                    path="/settings"
                    element={<SettingsPage />}
                />
                <Route
                    path="/schedule"
                    element={<SchedulePage />}
                />
                <Route
                    path="/chat"
                    element={<ChatPage />}
                />
                <Route
                    path="/account"
                    element={<Account />}
                />
                <Route
                    path="/questionnaire"
                    element={
                        <TeacherQuestionnairePage />
                        // <Middleware
                        //     include={[middlewares.checkTutorAccessToken]}
                        //     redirectTo={"/authorization"}
                        // >
                        //     <TeacherQuestionnairePage />
                        // </Middleware>
                    }
                />
                <Route
                    path="/catalog"
                    element={
                        <Middleware
                            include={[middlewares.md2]}
                            exclude={[middlewares.md3]}
                        >
                            <CatalogPage />
                        </Middleware>
                    }
                />
                <Route
                    path="/tutor/:id"
                    element={<TeacherPage />}
                />
                <Route
                    path="/authorization"
                    element={<AuthorizationPage />}
                />
                <Route
                    path="/payment"
                    element={<PaymentPage />}
                />
                <Route
                    path="/favourites"
                    element={<FavouritesPage />}
                />
                <Route
                    path="/bid"
                    element={<SearchRequest />}
                />
                <Route
                    path="/balance"
                    element={<Balance />}
                />
                <Route
                    path="/notifications"
                    element={<Notifications />}
                />
                <Route
                    path="/promotutor"
                    element={<TutorPromoPage />}
                />
                <Route
                    path="/studentrules"
                    element={<RulesStudent />}
                />
                <Route
                    path="/buddyrules"
                    element={<RulesTutor />}
                />
                <Route
                    path="/diplomas"
                    element={<DiplomaChat />}
                />
                <Route
                    path="/questionnaire-on-check"
                    element={<TeacherQuestionnaireDonePage />}
                />
                <Route
                    path="/tutor/classes"
                    element={<TutorClassesPage />}
                />
                <Route
                    path="/tutor/feedbacks"
                    element={<TutorFeedbacksPage />}
                />
                <Route
                    path="/tutor/level"
                    element={<TutorLevelPage />}
                />
                <Route
                    path="/tutor/paid"
                    element={<PaidServicesPage />}
                />
                <Route
                    path="/auth/verify-pass-recovery"
                    element={<NewPassword />}
                />
                <Route
                    path="*"
                    element={<PageError404 />}
                />
                <Route
                    path="/error400"
                    element={<PageError400 />}
                />
                <Route
                    path="/error403"
                    element={<PageError403 />}
                />
                <Route
                    path="/error500"
                    element={<PageError500 />}
                />
                <Route
                    path="/MiniChat"
                    element={<MiniChat />}
                />
            </Routes>
            <ModalWeSentInstructions />
            <WelcomeBackSnackbar />
            {medias.sm && <Footer />}
            <ModalError />
        </BrowserRouter>
    );
});

export default memo(App);
