import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import { Stack, Typography } from "@mui/material";

import SButton from "components/UI/SButton";

import SendWorkModal from "./SendWorkModal";

import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";
import UserStore from "store/UserStore";

const SendWork = () => {
    const [showSendWorkModal, setShowSendWorkModal] = useState(false);

    const showTutorSendButton =
        ((DiplomaStore.status === "working" && DiplomaStore.tutorProgress.filter((item) => item.done === true).length === DiplomaStore.tutorProgress.length) || DiplomaStore.status === "correcting") &&
        UserStore.role === "tutor";

    const handleShowSendWorkModal = () => {
        setShowSendWorkModal(true);
    };

    return showTutorSendButton ? (
        <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}
            marginBottom="10px"
        >
            <SButton style={{ height: "36px" }}>
                <Typography
                    variant="standardWhite"
                    onClick={handleShowSendWorkModal}
                >
                    Отправить работу
                </Typography>
            </SButton>
            <SendWorkModal
                open={showSendWorkModal}
                close={() => setShowSendWorkModal(false)}
            />
        </Stack>
    ) : null;
};

export default observer(SendWork);
