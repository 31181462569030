//Сопутствующие услуги
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import SBButtonGroup from "components/UI/ButtonGroup";
import store from "store/RequestStore";

import SCheckboxGroup from "components/UI/CheckboxGroup";
import StepHiddenButtonWrapper from "components/OrdersBodyBlock/RenewOrder/StepHiddenButtonWrapper";
import RequestNullAlert from "./RequestNullAlert";

const Step23 = () => {
    const [alert, setAlert] = useState(0);

    let data = store.storage[store.activeStepIndex].substeps[store.subStepIndex]?.value;

    if (data === undefined) store.setActiveSubStepIndex(0);

    const [stepData, setStepData] = useState(store.storage[store.activeStepIndex].substeps[store.subStepIndex].value);

    const handleNext = () => {
        let descr = "";

        for (let i = 1; i <= Object.keys(stepData).length; i++) {
            let key = "add" + i;

            if (stepData[key].selected === true) {
                descr += stepData[key].title + ", ";
            }
        }

        descr = descr.slice(0, -2).toLowerCase();
        descr = descr.charAt(0).toUpperCase() + descr.slice(1);

        if (descr === "") {
            descr = "Нет";
        }

        store.setStep(15, descr);
        store.setStepValue(15, stepData);
        store.incrementStepIndex();
    };

    return (
        <>
            <SCheckboxGroup
                title={"Выберите все необходимые услуги"}
                caption={0}
                funcstate={setStepData}
                varstate={stepData}
            />
            <StepHiddenButtonWrapper submitRenew={handleNext}>
                <SBButtonGroup
                    handleNext={handleNext}
                    handleBack={() => store.decrementStepIndex()}
                    activeStep={store.activeStepIndex}
                    subStep={store.subStepIndex}
                    steps={store.storage}
                />
                {alert === 1 ? <RequestNullAlert>Чтобы перейти к следующему вопросу, заполните это поле</RequestNullAlert> : null}
            </StepHiddenButtonWrapper>
        </>
    );
};

export default observer(Step23);
