import { styleProcessing } from "API/plugins/Utilities";

export const PlatinumIcon = {
    position: "absolute",
    right: "10px",
    top: "-10px",
};

export const LevelRow = {};

export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
};

export const WhiteContainer = (theme) => {
    let commonStyle = {
        padding: "20px",
        width: "249px",
        height: "165px",
        borderRadius: "20px",
        backgroundColor: "white",
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.05)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "relative",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
            height: "auto",
            gap: "25px",
            boxSizing: "border-box",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
