import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import CatalogStore from "store/CatalogStore";
import * as themes from "./GoalFilterThemes";
import SingleChoiceFilter from "components/UI/SingleChoiceFilter";
import { useMedias } from "../../../../API/mediasHook";

const GoalFilter = observer(({ children, ...props }) => {
    const processingGoal = (event, value) => {
        CatalogStore.SetGoal(value);
    };

    const medias = useMedias();

    return (
        <SingleChoiceFilter
            propOption={CatalogStore.optionGoal}
            propOptions={CatalogStore.optionsGoal}
            valueToShow={"goal"}
            valueToBind={"ID"}
            processingChange={processingGoal}
            themeWrapper={themes.FiltersGoalTheme(medias)}
            themeLabel={themes.filtersGoalLabel}
            useOptionIcon={true}
            themeOptionContainer={themes.filtersGOContainer}
            themeIconContainer={themes.FiltersRadioContainerTheme}
            label={"Цель"}
            placeholder={medias.sm ? "Выберите цель" : "Цель занятий"}
        ></SingleChoiceFilter>
    );
});

export default memo(GoalFilter);
