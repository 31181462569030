import React from "react";
import * as themes from "./StudentPointsThemes";
import * as textThemes from "themes/TextThemes";
import { Typography } from "@mui/material";
import Rectangle6352 from "assets/ReferralPageAssets/PNG/Rectangle6352.png";
import Rectangle6353 from "assets/ReferralPageAssets/PNG/Rectangle6353.png";
import Box from "@mui/material/Box";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const StudentPoints = (children, ...props) => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.studentPointsBlock}>
            <Box sx={themes.TextContent}>
                <Typography sx={textThemes.HeadlineH2White}>{t("promoPages.inviteFriend.howToGetPoints")}</Typography>

                <Box sx={themes.textBlock}>
                    <Box sx={themes.subTextBlock}>
                        <Typography sx={textThemes.Button20BoldWhite}>{t("promoPages.inviteFriend.copyAndInvite")}</Typography>
                        <Typography sx={textThemes.Body1White}>{t("promoPages.inviteFriend.sendLink")}</Typography>
                    </Box>

                    <Box sx={themes.subTextBlock}>
                        <Typography sx={textThemes.Button20BoldWhite}>{t("promoPages.inviteFriend.getTogether")}</Typography>
                        <Typography sx={textThemes.Body1White}>{t("promoPages.inviteFriend.afterSignUp")}</Typography>
                    </Box>

                    <Box sx={themes.subTextBlock}>
                        <Typography sx={textThemes.Button20BoldWhite}>{t("promoPages.inviteFriend.moreFriends")}</Typography>
                        <Typography sx={textThemes.Body1White}>{t("promoPages.inviteFriend.unlimitedPoints")}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={themes.FirstPhoto}>
                <img
                    src={Rectangle6352}
                    alt={1}
                />
            </Box>
            <Box sx={themes.SecondPhoto}>
                <img
                    src={Rectangle6353}
                    alt={2}
                />
            </Box>
            <Box sx={themes.addFrame1}>
                <Typography sx={textThemes.Body2Black}>{t("promoPages.inviteFriend.hiIAmNastya")}</Typography>
            </Box>

            <Box sx={themes.addFrame2}>
                <Typography sx={textThemes.Body2Black}>{t("promoPages.inviteFriend.niceEconomy")}</Typography>
            </Box>
        </Box>
    );
};

export default memo(StudentPoints);
