const ButtonArrowIcon2 = function (props) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon/20px/arrow">
                <path
                    id="Vector 762"
                    d="M3 10.5H18M18 10.5L11 4M18 10.5L11 17"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default ButtonArrowIcon2;
