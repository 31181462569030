export const StudentRulesBodyBlock = {
    background: "#F7F7F7",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "0px 0px 40px 40px",
};

export const StudentRulesBodyBlockContent = {
    width: "1216px",
    display: "flex",
    flexDirection: "column",
    gap: "50px",
    alignItems: "flex-start",
    padding: "50px 0 110px 0",
};

export const TitleBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
};

export const Rules = {
    display: "flex",
    flexDirection: "column",
    gap: "60px",
    maxWidth: "804px",
};

export const Sanctions = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
};

export const rulesBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};
