import React from "react";
import * as themes from "./WhyUsThemes";
import * as textThemes from "themes/TextThemes";
import { Typography } from "@mui/material";
import ReferralBenefit from "./ReferralBenefit/ReferralBenefit";
import IconWorld from "assets/ReferralPageAssets/PNG/IconWorld.png";
import IconPPL from "assets/ReferralPageAssets/PNG/IconPPL.png";
import IconStudent from "assets/ReferralPageAssets/PNG/IconStudent.png";
import Box from "@mui/material/Box";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const WhyUs = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.WhyUsBlock}>
            <Typography sx={{ ...textThemes.HeadlineH2White, ...themes.mainHeader }}>{t("promoPages.inviteFriend.whyStudyBuddy")}</Typography>

            <Box sx={themes.benefitsBlock}>
                <Box sx={themes.BenefitsBlock}>
                    <ReferralBenefit benefitText={t("promoPages.inviteFriend.largeCatalog")} />
                    <ReferralBenefit benefitText={t("promoPages.inviteFriend.abilityToOrder")} />
                </Box>
                <Box sx={themes.SepLineBlock}>
                    <Box sx={themes.sepLine} />
                    <Box sx={themes.sepLine} />
                </Box>
                <Box sx={themes.BenefitsBlock}>
                    <ReferralBenefit benefitText={t("promoPages.inviteFriend.easySearch")} />
                    <ReferralBenefit benefitText={t("promoPages.inviteFriend.convenientPlatform")} />
                </Box>
            </Box>

            <Box sx={themes.FirstIcon}>
                <img
                    src={IconWorld}
                    alt={1}
                />
            </Box>

            <Box sx={themes.SecondIcon}>
                <img
                    src={IconPPL}
                    alt={2}
                />
            </Box>

            <Box sx={themes.ThirdIcon}>
                <img
                    src={IconStudent}
                    alt={3}
                />
            </Box>
        </Box>
    );
};

export default memo(WhyUs);
