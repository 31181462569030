import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./StickyFiltersBlockThemes";
import { Button, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import OrdersSearchStore from "store/OrdersSearchStore";
import SelectedFiltersTags from "./SelectedFiltersTags";
import OrdersFormat from "./OrdersFormat";
import OrdersPlace from "./OrdersPlace";
import OrdersStudentAge from "./OrdersStudentAge";
import OrdersGoal from "./OrdersGoal";
import OrdersCategory from "./OrdersCategory";
import OrdersWorkType from "./OrdersWorkType";
import OrdersRegion from "./OrdersRegion";
import OrdersPrice from "./OrdersPrice";
import OrdersDiscipline from "./OrdersDiscipline";

const StickyFiltersBlock = observer(({ children, ...props }) => {
    const search = async () => {
        await OrdersSearchStore.ExecuteSearch();
    };

    return (
        <Box sx={themes.stickyFiltersBlockContainerStyle}>
            <Box sx={themes.filtersContainerStyle}>
                <Box sx={themes.headerContainerStyle}>
                    <Typography style={themes.textStyle}>Фильтры</Typography>
                </Box>
                {OrdersSearchStore.selectedChipsList.length > 0 ? <SelectedFiltersTags></SelectedFiltersTags> : null}
                <OrdersDiscipline></OrdersDiscipline>
                <OrdersFormat></OrdersFormat>
                <OrdersRegion></OrdersRegion>
                <OrdersPlace></OrdersPlace>
                <OrdersPrice></OrdersPrice>
                <OrdersCategory></OrdersCategory>
                <OrdersGoal></OrdersGoal>
                <OrdersWorkType></OrdersWorkType>
                <OrdersStudentAge></OrdersStudentAge>
                <ThemeProvider theme={themes.SearchButtonTheme}>
                    <Button
                        onClick={search}
                        disableRipple
                    >
                        <Typography sx={themes.searchTextStyle}>Применить</Typography>
                    </Button>
                </ThemeProvider>
            </Box>
        </Box>
    );
});

export default memo(StickyFiltersBlock);
