import React from "react";

const RegularStudentIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <path
                d="M7.54077 1.56637C7.71432 1.16339 8.28569 1.16339 8.45923 1.56637L10.0456 5.25014C10.118 5.41822 10.2764 5.53333 10.4587 5.55024L14.4524 5.92064C14.8892 5.96116 15.0658 6.50457 14.7362 6.79414L11.7229 9.44124C11.5854 9.56202 11.5249 9.74828 11.5652 9.92681L12.447 13.8395C12.5435 14.2675 12.0812 14.6034 11.704 14.3794L8.25528 12.3316C8.09792 12.2381 7.90208 12.2381 7.74472 12.3316L4.29604 14.3793C3.91877 14.6034 3.45652 14.2675 3.55299 13.8395L4.43484 9.92681C4.47507 9.74827 4.41456 9.56202 4.27706 9.44124L1.26382 6.79414C0.934186 6.50456 1.11075 5.96116 1.54764 5.92064L5.54133 5.55024C5.72356 5.53333 5.88199 5.41822 5.95438 5.25014L7.54077 1.56637Z"
                fill="#A171FF"
            />
        </svg>
    );
};

export default RegularStudentIcon;
