import { TypicalInfoServiceClient } from "API/services/grpc/proto/TypicalInfoService/typical-info_grpc_web_pb";

import { GetCitiesRequest, GetConditionsBySubjectRequest, GetDistrictsAndUndergroundStationsByCityRequest, GetSubjectsRequest } from "API/services/grpc/proto/TypicalInfoService/typical-info_pb";

const TypicalInfoServiceUrl = "https://study-buddy.ru:8080";

const TypicalInfoService = new TypicalInfoServiceClient(TypicalInfoServiceUrl, null, null);

export async function GetCities(data, callback) {
    let request = new GetCitiesRequest();

    await TypicalInfoService.getCities(request, null, callback);
}

export async function GetConditionsBySubject(data, callback) {
    let request = new GetConditionsBySubjectRequest();

    let { subject } = data;

    request.setSubject(subject);

    await TypicalInfoService.getConditionsBySubject(request, null, callback);
}

export async function GetDistrictsAndUndergroundStationsByCity(data, callback) {
    let request = new GetDistrictsAndUndergroundStationsByCityRequest();

    let { city } = data;

    request.setCity(city);

    await TypicalInfoService.getDistrictsAndUndergroundStationsByCity(request, null, callback);
}

export async function GetSubjects(data, callback) {
    let request = new GetSubjectsRequest();

    await TypicalInfoService.getSubjects(request, null, callback);
}
