import { ArrowForward } from "@mui/icons-material";
import { Typography } from "@mui/material";

import { StyledOrderButton } from "./CreateOrderButtonThemes";
import { useNavigate } from "react-router-dom";

const CreateOrderButton = ({ text, toggleOrderDrawer }) => {
    const navigate = useNavigate();

    const click = () => {
        toggleOrderDrawer();
        navigate("/bid");
    };

    return (
        <>
            <StyledOrderButton
                disableRipple
                onClick={click}
                variant="contained"
                endIcon={<ArrowForward />}
            >
                <Typography
                    fontSize="16px"
                    fontWeight={500}
                    lineHeight="24px"
                >
                    {text}
                </Typography>
            </StyledOrderButton>
        </>
    );
};

export default CreateOrderButton;
