import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grid from "@mui/material/Unstable_Grid2";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";
import IconPayment from "assets/ChatAssets/SVG/iconPayment";
import Popper from "@mui/material/Popper";
import UserStore from "store/UserStore";
import React, { useState } from "react";
import * as styles from "./StudentTeacherPoppersThemes";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";
import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { deleteCookie, getCookie, setCookie } from "API/utils";
import AuthorizationStore from "store/AuthorizationStore";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FullChatStore from "store/FullChatStore";

const StudentPopper = observer(({ open, setOpen, anchorRef }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const medias = useMedias();

    const [doubleRole, setDouble] = useState(0);

    useEffect(() => {
        if (getCookie("tutorAccessToken")) setDouble(1);
        else setDouble(0);
    }, []);

    const setChangeRole = () => {
        UserStore.setRole("tutor");
        setCookie("role", "tutor");
        FullChatStore.SetTabsData({});
        FullChatStore.SetChatID("");
        navigate("/account");
        UserStore.setPage(0);
        setOpen(false);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const OpenWork = () => {
        setOpen(false);
        UserStore.setPersonalAccountState("Work");
        navigate("/account");
        UserStore.setPage(0);
    };

    const OpenSettings = () => {
        setOpen(false);
        navigate("/settings");
        UserStore.setPage(0);
    };

    const OpenFavorites = () => {
        setOpen(false);
        navigate("/favourites");
        UserStore.setPage(0);
    };

    const OpenBalance = () => {
        setOpen(false);
        navigate("/balance");
    };

    const OpenMyClasses = () => {
        setOpen(false);
        navigate("/myclasses");
        UserStore.setPage(1);
    };

    const OpenOrders = () => {
        setOpen(false);
        navigate("/orders");
        UserStore.setPage(2);
    };

    const OpenRef = () => {
        setOpen(false);
        navigate("/referral");
    };

    const logout = async () => {
        if (getCookie("refreshToken")) {
            await AuthorizationStore.handleLogout();
            deleteCookie("studentAccessToken");
            deleteCookie("refreshToken");
            deleteCookie("role");
        }

        setOpen(false);
        UserStore.setAuth(false);
        navigate("/");
    };

    const toSignUp = () => {
        AuthorizationStore.setCurrentAuthorizationStep("SignUpTutor");
        AuthorizationStore.setSignUpPhone(null);
        AuthorizationStore.setSignUpPhoneError(false);
        AuthorizationStore.setSignUpName("");
        AuthorizationStore.setSignUpEmail("");
        AuthorizationStore.setSignUpEmailError(false);
        AuthorizationStore.setTutorOfferAgree(false);
        AuthorizationStore.setTutorRulesAgree(false);
        setOpen(false);
        navigate("/authorization");
    };

    return (
        <Popper
            open={open}
            anchorEl={anchorRef.current}
            transition
            disablePortal
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
                >
                    <Paper sx={styles.StudentTeacherPopperPaper}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <Grid>
                                <MenuList sx={styles.StudentTeacherPopperMenuList}>
                                    <Grid sx={styles.StudentTeacherPopperUpperGrid}>
                                        <MenuItem
                                            disableRipple
                                            onClick={OpenWork}
                                            sx={styles.StudentTeacherPopperMenuItem}
                                        >
                                            {t("main.header.personalAccount")}
                                        </MenuItem>
                                        <MenuItem
                                            disableRipple
                                            onClick={OpenMyClasses}
                                            sx={styles.StudentTeacherPopperMenuItem}
                                        >
                                            {t("main.header.myClasses")}
                                        </MenuItem>
                                        <MenuItem
                                            disableRipple
                                            onClick={OpenOrders}
                                            sx={styles.StudentTeacherPopperMenuItem}
                                        >
                                            {t("main.header.myOrders")}
                                        </MenuItem>
                                        <MenuItem
                                            disableRipple
                                            onClick={OpenFavorites}
                                            sx={styles.StudentTeacherPopperMenuItem}
                                        >
                                            {t("main.header.favorites")}
                                        </MenuItem>
                                    </Grid>
                                    <Grid sx={styles.StudentTeacherPopperLowerGrid}>
                                        <MenuItem
                                            disableRipple
                                            onClick={OpenBalance}
                                            sx={styles.StudentTeacherPopperMenuItem}
                                        >
                                            {t("main.header.myBalance")}
                                        </MenuItem>
                                        <MenuItem
                                            disableRipple
                                            sx={styles.StudentTeacherPopperMenuItem}
                                        >
                                            {t("main.header.bonusAccount")}
                                            <Grid sx={styles.PaymentIconBox}>
                                                <IconPayment medias={medias} />
                                            </Grid>
                                        </MenuItem>
                                    </Grid>
                                    <Grid sx={styles.StudentTeacherPopperLowerGrid}>
                                        <MenuItem
                                            disableRipple
                                            onClick={OpenRef}
                                            sx={styles.StudentTeacherPopperMenuItem}
                                        >
                                            {t("main.header.inviteFriend")}
                                        </MenuItem>
                                    </Grid>
                                    <Grid sx={styles.BottomContainer}>
                                        <MenuItem
                                            disableRipple
                                            onClick={OpenSettings}
                                            sx={styles.StudentTeacherPopperMenuItem}
                                        >
                                            {t("main.header.settings")}
                                        </MenuItem>
                                        {UserStore.nAccountTypes === 2 && (
                                            <Box sx={styles.StudentTeacherSwitchAccountContainer}>
                                                <Box sx={styles.SwitchAccountLabelBox}>
                                                    <Typography sx={textThemes.Caption11DarkGrey}>{t("main.header.switchAccount")}</Typography>
                                                </Box>
                                                {doubleRole === 1 ? (
                                                    <MenuItem
                                                        onClick={setChangeRole}
                                                        disableRipple
                                                        sx={styles.StudentTeacherSwitchAccountItem}
                                                    >
                                                        <img
                                                            alt="studentLogo"
                                                            src={TeacherQuestionnaireStore.teacher.miniature.imageSrc}
                                                            style={styles.Photo}
                                                        />
                                                        <Box sx={styles.StudentTeacherSwitchAccountText}>
                                                            <Typography sx={textThemes.Body2Black}>{`${UserStore.UserInfo.name} ${UserStore.UserInfo.surname}`}</Typography>
                                                            <Typography sx={textThemes.Caption2Grey}>{t("main.header.tutorProfile")}</Typography>
                                                        </Box>
                                                    </MenuItem>
                                                ) : (
                                                    <MenuItem
                                                        onClick={toSignUp}
                                                        disableRipple
                                                        sx={styles.StudentTeacherSwitchAccountItem}
                                                    >
                                                        <Box sx={styles.StudentTeacherSwitchAccountText}>
                                                            <Typography sx={textThemes.Body2Black}>Создать профиль репетитора</Typography>
                                                            <Typography sx={textThemes.Caption2Grey}>Начните делиться знаниями!</Typography>
                                                        </Box>
                                                    </MenuItem>
                                                )}
                                            </Box>
                                        )}
                                        <MenuItem
                                            disableRipple
                                            onClick={logout}
                                            sx={styles.StudentTeacherPopperMenuItem}
                                        >
                                            {t("main.header.signOut")}
                                        </MenuItem>
                                    </Grid>
                                </MenuList>
                            </Grid>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
});

export default memo(StudentPopper);
