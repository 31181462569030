const CreateNewOrderIcon = function (props) {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="18"
                cy="18"
                r="18"
                fill="#6212FF"
            />
            <rect
                x="6"
                y="6"
                width="24"
                height="24"
                rx="12"
                fill="#6212FF"
            />
            <path
                d="M21.771 9H14.229C10.953 9 9 10.953 9 14.229V21.771C9 25.047 10.953 27 14.229 27H21.771C25.047 27 27 25.047 27 21.771V14.229C27 10.953 25.047 9 21.771 9ZM16.173 20.61L14.148 22.635C14.013 22.77 13.842 22.833 13.671 22.833C13.5 22.833 13.32 22.77 13.194 22.635L12.519 21.96C12.249 21.699 12.249 21.267 12.519 21.006C12.78 20.745 13.203 20.745 13.473 21.006L13.671 21.204L15.219 19.656C15.48 19.395 15.903 19.395 16.173 19.656C16.434 19.917 16.434 20.349 16.173 20.61ZM16.173 14.31L14.148 16.335C14.013 16.47 13.842 16.533 13.671 16.533C13.5 16.533 13.32 16.47 13.194 16.335L12.519 15.66C12.249 15.399 12.249 14.967 12.519 14.706C12.78 14.445 13.203 14.445 13.473 14.706L13.671 14.904L15.219 13.356C15.48 13.095 15.903 13.095 16.173 13.356C16.434 13.617 16.434 14.049 16.173 14.31ZM23.004 22.158H18.279C17.91 22.158 17.604 21.852 17.604 21.483C17.604 21.114 17.91 20.808 18.279 20.808H23.004C23.382 20.808 23.679 21.114 23.679 21.483C23.679 21.852 23.382 22.158 23.004 22.158ZM23.004 15.858H18.279C17.91 15.858 17.604 15.552 17.604 15.183C17.604 14.814 17.91 14.508 18.279 14.508H23.004C23.382 14.508 23.679 14.814 23.679 15.183C23.679 15.552 23.382 15.858 23.004 15.858Z"
                fill="white"
            />
        </svg>
    );
};

export default CreateNewOrderIcon;
