import React from "react";

import { Stack, Typography } from "@mui/material";

import * as styles from "pages/DiplomaChats/styles";

const OrderPayment = () => {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            style={styles.dashedBorder}
        >
            <Typography variant="standard2">Заказ оплачен</Typography>
            <Typography variant="title2">10 000 ₽</Typography>
        </Stack>
    );
};

export default OrderPayment;
