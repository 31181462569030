import { memo } from "react";
import { observer } from "mobx-react-lite";
import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import { useTranslation } from "react-i18next";
import * as styles from "./ModalCancelClassThemes";
import * as textStyles from "themes/TextThemes";
import { Box, Typography } from "@mui/material";
import MyClassesStore from "store/MyClassesStore";
import SButton from "components/UI/SButton";
import TextfieldReview from "components/MyClassesBodyBlock/MyClassesList/StudyListItem/Modals/ModalRateClass/TextfieldReview/TextfieldReview";
import { useMedias } from "API/mediasHook";
import TutorClassesStore from "../../../../../../../../store/TutorClassesStore";

const ModalCancelClass = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const getFormatedDate = (lesson) => {
        const dateObject = TutorClassesStore.formatTodayClassDate(lesson);

        return `${dateObject.date} ${t(dateObject.month)}, ${t(dateObject.weekday)}`;
    };

    return (
        <ModalSettingsSmall
            visible={props.open}
            handleClose={props.handleDiscard}
            pl={!medias.sm ? "16px" : undefined}
            pr={!medias.sm ? "16px" : undefined}
            pt={!medias.sm ? "16px" : undefined}
        >
            <Box sx={styles.ModalCancelClass(medias)}>
                <Typography sx={medias.sm ? textStyles.HeadlineH41Black : textStyles.HeadlineH5Black}>{t("main.myClasses.cancelClasses")}</Typography>
                {props.lesson.rescheduleAttempt !== 0 && props.lesson.rescheduleTimeStart === null ? (
                    <Box sx={styles.RescheduleClass}>
                        <Typography sx={textStyles.Body2Black}>{t("main.myClasses.beforeCancellation")}</Typography>
                        <Typography
                            sx={textStyles.Button16Purple}
                            style={styles.RescheduleButton}
                            onClick={props.handleReschedule}
                        >
                            {t("main.myClasses.rescheduleClass")}
                        </Typography>
                    </Box>
                ) : null}
                <Box sx={styles.ClassTime}>
                    {props.lesson.rescheduleAttempt !== 0 && props.lesson.rescheduleTimeStart === null ? (
                        <Typography sx={textStyles.Button16Black}>{t("main.myClasses.cancelClass")}</Typography>
                    ) : null}
                    <Typography
                        sx={textStyles.Body2DarkGrey}
                        component={"div"}
                    >
                        {t("main.myClasses.timeCancellation")}
                        <span style={textStyles.Body2Black}>{` ${getFormatedDate(props.lesson)}, ${MyClassesStore.formatTodayClassTime(props.lesson)}`}</span>
                    </Typography>
                </Box>
                <Box sx={styles.CancelReason}>
                    <Typography sx={textStyles.Body2Black}>{t("teacherClasses.cancellationReason")}</Typography>
                    <TextfieldReview
                        maxCharacters={500}
                        handleChange={MyClassesStore.setTemporaryCancelReason}
                        value={MyClassesStore.temporaryCancelReason}
                        placeholder={t("main.yourMessage")}
                    />
                </Box>
                <Box sx={styles.ButtonContainer(medias)}>
                    <SButton
                        variant="secondary"
                        padding="10px 20px 11px 20px"
                        style={styles.ButtonHoldLesson(medias)}
                        onClick={props.handleDiscard}
                    >
                        {medias.sm ? t("main.myClasses.keepClass") : t("main.myClasses.keep")}
                    </SButton>
                    <SButton
                        variant="red"
                        padding="10px 20px 11px 20px"
                        style={styles.Button}
                        onClick={props.handleSave}
                    >
                        {t("main.myClasses.cancelClass")}
                    </SButton>
                </Box>
            </Box>
        </ModalSettingsSmall>
    );
});

export default memo(ModalCancelClass);
