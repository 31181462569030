import React from "react";

const MicroOnIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon/24/micro-on">
                <path
                    id="micro-on"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.27362 6.8349C7.27362 4.25 9.42666 2.19531 12.0353 2.19531C14.644 2.19531 16.797 4.25 16.797 6.8349V12.1831C16.797 14.768 14.644 16.8227 12.0353 16.8227C9.42666 16.8227 7.27362 14.768 7.27362 12.1831V6.8349ZM12.0353 3.69531C10.211 3.69531 8.77362 5.1218 8.77362 6.8349V12.1831C8.77362 13.8962 10.211 15.3227 12.0353 15.3227C13.8596 15.3227 15.297 13.8962 15.297 12.1831V6.8349C15.297 5.1218 13.8596 3.69531 12.0353 3.69531ZM4.36328 9.63281C4.77749 9.63281 5.11328 9.9686 5.11328 10.3828V12.0359C5.11328 15.7035 8.1955 18.7247 12.0356 18.7247C15.8758 18.7247 18.958 15.7035 18.958 12.0359V10.3828C18.958 9.9686 19.2938 9.63281 19.708 9.63281C20.1222 9.63281 20.458 9.9686 20.458 10.3828V12.0359C20.458 16.2645 17.1624 19.7209 12.9728 20.1743V22.6719C12.9728 23.1897 12.553 23.6095 12.0352 23.6095C11.5173 23.6095 11.0975 23.1897 11.0975 22.6719V20.1742C6.90842 19.7203 3.61328 16.2642 3.61328 12.0359V10.3828C3.61328 9.9686 3.94907 9.63281 4.36328 9.63281Z"
                    fill="white"
                />
            </g>
        </svg>
    );
};

export default MicroOnIcon;
