import React from "react";
import { Typography } from "@mui/material";
import * as themes from "components/SettingsBodyBlock/TutorSettings/MainForm/MainFormThemes";
import SButton from "components/UI/SButton";
import Box from "@mui/material/Box";
import * as textThemes from "themes/TextThemes";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { useMedias } from "API/mediasHook";
import SIconButton from "../../SIconButton";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";

const Prices = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const handleEdit = () => {
        props.handleSet(7);
    };

    return (
        <Box sx={themes.SmallItem(medias)}>
            <Box sx={themes.ItemContent(medias)}>
                <Box sx={themes.TitleAndEdit}>
                    <Typography sx={medias.sm ? textThemes.HeadlineH41Black : textThemes.HeadlineH5Black}>{t("teacherSettings.questionnaire.subjectsAndPrice")}</Typography>
                    {medias.sm ? (
                        <SButton
                            onClick={handleEdit}
                            variant="text"
                        >
                            {t("teacherSettings.questionnaire.edit")}
                        </SButton>
                    ) : (
                        <SIconButton onClick={handleEdit}>
                            <DriveFileRenameOutlineOutlinedIcon />
                        </SIconButton>
                    )}
                </Box>
                <Box sx={themes.VerticalContainer}>
                    {Object.keys(TeacherQuestionnaireStore.teacher.subjects).map((item, index) => (
                        <Box
                            key={item.id}
                            sx={themes.PriceItem}
                        >
                            <Typography
                                sx={textThemes.Body1Black}
                                style={themes.Capitalise}
                            >
                                {item}
                            </Typography>
                            <Typography sx={textThemes.Body1Black}>
                                <span style={textThemes.Body2DarkGrey}>{t("teacherSettings.questionnaire.fromPrice")}</span>
                                {TeacherQuestionnaireStore.findMinimumValueInObject(TeacherQuestionnaireStore.teacher.subjects[item])} ₽
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
});

export default memo(Prices);
