import { createTheme } from "@mui/material/styles";

export const cardContainerStyle = (medias) => {
    return {
        display: "flex",
        flexDirection: medias.sm ? "unset" : "column",
        width: medias.sm ? 1216 : 328,
        boxSizing: "border-box",
        justifyContent: "space-between",
        alignItems: "center",
        padding: medias.sm ? "40px 80px 40px 100px" : "20px 20px 30px 20px",
        marginLeft: "auto",
        marginRight: "auto",

        borderRadius: "20px",
        border: "2px solid var(--text-light-black, #4E4E4E)",
        background: "var(--bg-white, #FFF)",
    };
};

export const headerStyle = (medias) => {
    return {
        color: "var(--text-black, #222)",
        fontSize: medias.sm ? "32px" : "24px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "120%",
        width: medias.sm ? "677px" : "288px",
        marginBottom: 20,
    };
};

export const subHeaderStyle = (medias) => {
    return {
        color: "var(--text-black, #222)",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "150%",
        width: medias.sm ? "525px" : "288px",
    };
};

export const ButtonRedirectTheme = (medias) => {
    return {
        components: {
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        display: "inline-flex",
                        padding: "11px 20px 12px 20px!important",
                        height: medias.sm ? "fit-content" : "47px",
                        marginTop: medias.sm ? "0px" : "20px",
                        width: "288px",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "8px",
                        borderRadius: "42px!important",
                        background: "var(--main-purple, #6212FF)",
                        boxShadow: "0px 4px 10px 0px rgba(51, 12, 128, 0.20)",
                        ".MuiButton-endIcon": {
                            color: "#FFF",
                            margin: 0,
                        },
                        "&:hover": {
                            background: "#6212FF!important",
                            borderColor: "#6212FF!important",
                        },
                        "&:active": {
                            background: "#6212FF!important",
                            borderColor: "#6212FF!important",
                        },
                    },
                },
            },
        },
    };
};

export const buttonRedirectTextStyle = {
    textTransform: "none",
    color: "var(--text-white, #FFF)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
};
