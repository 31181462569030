import { createTheme } from "@mui/material/styles";

export const infoCardStyle = {
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    padding: "30px 30px 20px 30px",
    width: "796px",
    height: "fit-content",
    borderRadius: "20px",
    background: "var(--main-white, #FFF)",
};

export const infoHeaderContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    gap: 18,
};

export const infoBodyContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    position: "relative",
    maxHeight: "fit-content",
};

export const dividerStyle = (marginTop, marginBottom) => {
    return {
        marginTop: marginTop,
        marginBottom: marginBottom,
    };
};

export const itemContainerStyle = {
    paddingTop: 11,
    paddingBottom: 12,
};

export const postInfoContainerStyle = {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
};

export const dateContainerStyle = {
    display: "inline-flex",
    gap: "5px",
};

export const numberContainerStyle = {
    display: "inline-flex",
    gap: "5px",
};

export const dateLabelStyle = {
    color: "var(--text-grey, #9E9EB0)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const numberLabelStyle = {
    color: "var(--text-grey, #9E9EB0)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const dateStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const numberStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const infoHeaderStyle = {
    color: "var(--text-black, #222)",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "140%",
};

export const MoreIconTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                    borderRadius: "50%",
                    background: "#f4f4f6",
                    position: "relative",
                    width: "28px",
                    height: "28px",
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    color: "#222222",
                    padding: 0,
                    ".MuiSvgIcon-root": {
                        width: "24px",
                        height: "24px",
                    },
                    "&:hover": {
                        color: "#6212FF",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        color: "#6212FF",
                        borderColor: "#6212FF",
                    },
                },
            },
        },
    },
});

export const MenuTheme = createTheme({
    components: {
        MuiMenu: {
            styleOverrides: {
                // Name of the slot
                root: {},
                paper: {
                    marginTop: 10,
                    borderRadius: "10px",
                    ".MuiList-root": {
                        display: "flex",
                        width: "max-content",
                        padding: "12px 16px",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    },
                    ".MuiMenuItem-root": {
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        alignSelf: "stretch",
                        padding: "0",
                        "&:hover": {
                            background: "#FFFFFF",
                            "& *": {
                                color: "#6212FF!important",
                            },
                        },
                        "&.Mui-selected": {
                            background: "#FFFFFF",
                            "& *": {
                                color: "#6212FF!important",
                            },
                        },
                    },
                },
            },
        },
    },
});

export const reportActionTextStyle = {
    color: "var(--Text-Black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    "&:hover": {
        color: "#6212FF!important",
    },
};

export const snackBarContentStyle = {
    display: "flex",
    width: "328px",
    padding: "16px",
    alignItems: "center",
    gap: "10px",
    borderRadius: "10px",
    background: "#6369F1",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
};

export const snackTextStyle = {
    color: "var(--text-white, #FFF)",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
    display: "flex",
    flexGrow: 1,
};

export const CheckIconTheme = createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                // Name of the slot
                root: {
                    padding: 0,
                    color: "#FFFFFF",
                    width: "20px",
                    height: "20px",
                    "&:hover": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                    "&:active": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                },
            },
        },
    },
});

export const CloseSnackBarIconTheme = createTheme({
    components: {
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    padding: 0,
                    color: "#FFFFFF",
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    "&:hover": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                    "&:active": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                },
            },
        },
    },
});
