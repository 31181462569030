export const Chats = {
    overflowY: "scroll",
    cursor: "pointer",
    width: "fit-content",
};

export const MessageRight = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "6px",
};

export const MessageCenter = {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    width: "252px",
};

export const MessageBox = (condition) => {
    return {
        padding: "14px 32px 14px 20px",
        borderBottom: "1px solid #EEEEF0",
        display: "flex",
        gap: "8px",
        alignItems: "flex-start",
        background: condition ? "#F0EFFF" : "#FFF",
        ":hover": {
            background: condition ? "#F0EFFF" : "rgba(187, 187, 200, 0.17)",
        },
    };
};

export const UnreadMessage = {
    borderRadius: "999px",
    padding: "2px 6px",
    color: "white",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "130%",
    width: "fit-content",
};

export const Avatar = {
    width: "40px",
    height: "40px",
};

export const NameBox = {
    display: "flex",
    gap: "6px",
    alignItems: "center",
};

export const PaperStyles = {
    width: "17vw",
    mt: "2.5vh",
    backgroundColor: "white",
    borderRadius: "1.2vh",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
    border: "none",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
    p: 0,
};

export const MenuItem = {
    paddingLeft: "10px",
    borderRadius: "1vh",
    height: "4.12vh",
    fontSize: "1.7vh",
    ":hover": {
        color: "black",
    },
};

export const MenuItem2 = {
    borderRadius: "1vh",
    height: "4.12vh",
    fontSize: "1.7vh",
    ":hover": {
        color: "#6212FF",
    },
};

export const TabPanel = {
    width: "392px",
};

export const TabsList = {
    maxWidth: "392px",
};

export const TabsInfo = {
    fontSize: "14px",
    color: "#67677A",
    textAlign: "center",
};

export const TabsEmpty = {
    fontSize: "16px",
    fontWeight: "500",
    textAlign: "center",
    marginBottom: "12px",
};

export const TabsDivEmpty = {
    width: "70%",
    fontWeight: "500",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "23vh",
};
