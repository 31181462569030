import React, { useState } from "react";

import { ThemeProvider } from "@mui/material/styles";

import HeadContainer from "components/DiplomaChats/HeadContainer/HeadContainer.jsx";
import Tabs from "components/DiplomaChats/Tabs.jsx";
import RightPanel from "components/DiplomaChats/RightPanel/RightPanel.jsx";
import LeftPanel from "components/DiplomaChats/LeftPanel/LeftPanel.jsx";

import { FILES } from "components/DiplomaChats/store/files.js";

import * as styles from "./styles.js";
import * as themes from "themes/AllPageThemes.js";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import Box from "@mui/material/Box";

const DiplomaChat = () => {
    const [alignment, setAlignment] = useState("ChatBodyBlock");

    return (
        <ScrollToTop>
            <Box sx={styles.background}>
                <ThemeProvider theme={themes.typographyTheme}>
                    <Box sx={styles.container}>
                        <HeadContainer />
                        <Tabs
                            filesCount={FILES.length}
                            alignment={alignment}
                            setAlignment={setAlignment}
                        />
                        <Box sx={styles.standardDivCont}>
                            <LeftPanel tab={alignment} />
                            <RightPanel />
                        </Box>
                    </Box>
                </ThemeProvider>
            </Box>
        </ScrollToTop>
    );
};

export default DiplomaChat;
