import React from "react";
import * as FeedbackCardStyle from "./FeedBackCardStyle";
import { Typography } from "@mui/material";
import FIveStarsIconSVG from "assets/CommonAssets/SVG/fivestarsicon";
import { useMedias } from "API/mediasHook";
import { Box } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const FeedbackCard = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const medias = useMedias();

    return (
        <Box sx={FeedbackCardStyle.Card(medias)}>
            <Box sx={FeedbackCardStyle.CardHeaderBlock}>
                <img src={props.review.TutorPhoto}></img>
                <Box sx={FeedbackCardStyle.CardHeaderTextBlock}>
                    <Typography style={FeedbackCardStyle.TutorName}>{t(props.review.TutorName)}</Typography>
                    <Typography style={FeedbackCardStyle.TutorSubject}>{t(props.review.TutorSubject)}</Typography>
                </Box>
            </Box>

            <Box sx={FeedbackCardStyle.ReviewerName}>{t(props.review.ReviewerName)}</Box>

            <Box sx={FeedbackCardStyle.Stars}>
                <FIveStarsIconSVG></FIveStarsIconSVG>
            </Box>

            <Box sx={FeedbackCardStyle.CardText(medias)}>{t(props.review.CardText)}</Box>
        </Box>
    );
};

export default memo(FeedbackCard);
