import EditGender from "./EditGender/EditGender";
import React from "react";
import * as textStyles from "themes/TextThemes";
import * as styles from "components/SettingsBodyBlock/StudentSettings/Profile/ProfileForms/ProfileFormsThemes";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { memo } from "react";

const GenderForm = () => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={styles.FormBlock}>
            <Typography sx={textStyles.Body2Black}>{t("main.settings.profile.gender")}</Typography>
            <EditGender />
        </Box>
    );
};

export default memo(GenderForm);
