import { Dialog, Typography } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { List } from "@mui/material";
import { ListItem } from "@mui/material";
import { ListItemButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import SearchIconGrey from "assets/CommonAssets/SVG/searchIconGrey";
import IconClose from "assets/CommonAssets/SVG/iconClose";
import cities from "API/dictionaries/cities";
import React from "react";
import * as themes from "./CitySelectModalThemes";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import SButton from "components/UI/SButton";
import { memo } from "react";
import { observer } from "mobx-react-lite";
import UserStore from "store/UserStore";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";

const CitySelectModal = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const handleChoose = (value, event) => {
        if (UserStore.role === "tutor") {
            TeacherQuestionnaireStore.setCity(value);
        } else {
            UserStore.setCity(value);
        }

        UserStore.setOpenCitySelect(false);
    };

    const handleClose = () => {
        UserStore.setOpenCitySelect(false);
    };

    const handleAutomaticGeo = () => {
        const apiKey = "cabe52a486b98b2c69a646f71b4a0f77";

        // eslint-disable-next-line no-undef
        const timeoutPromise = new Promise((_, reject) => {
            setTimeout(() => reject(new Error("Таймаут запроса")), 4000);
        });

        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const { latitude, longitude } = position.coords;

                try {
                    // eslint-disable-next-line no-undef
                    const response = await Promise.race([fetch(`https://api.openweathermap.org/geo/1.0/reverse?lat=${latitude}&lon=${longitude}&appid=${apiKey}`), timeoutPromise]);

                    const data = await response.json();

                    if (data && data.length > 0) {
                        if (UserStore.role === "tutor") {
                            TeacherQuestionnaireStore.teacher.city = cities.find((item) => item.title === data[0].local_names.ru);
                        } else {
                            UserStore.setCity(cities.find((item) => item.title === data[0].local_names.ru));
                        }

                        UserStore.setOpenCitySelect(false);
                    }
                } catch (error) {
                    if (UserStore.role === "tutor") {
                        TeacherQuestionnaireStore.setCity({ id: 605, title: "Москва", region: "Москва", country: "Россия" });
                    } else {
                        UserStore.setCity({ id: 605, title: "Москва", region: "Москва", country: "Россия" });
                    }

                    UserStore.setOpenCitySelect(false);
                }
            },
            (error) => {
                if (UserStore.role === "tutor") {
                    TeacherQuestionnaireStore.setCity({ id: 605, title: "Москва", region: "Москва", country: "Россия" });
                } else {
                    UserStore.setCity({ id: 605, title: "Москва", region: "Москва", country: "Россия" });
                }

                UserStore.setOpenCitySelect(false);
            },
        );
    };

    return (
        <Dialog
            sx={themes.CitySelectDialog}
            onClose={handleClose}
            open={UserStore.openCitySelect}
        >
            <Box sx={themes.CitySelectHeader}>
                <DialogTitle sx={themes.CitySelectTitle}>{t("main.header.selectCity")}</DialogTitle>
                <SButton
                    variant="icon"
                    onClick={handleClose}
                    style={{ height: "fit-content" }}
                >
                    <IconClose />
                </SButton>
            </Box>
            <TextField
                placeholder={t("main.header.yourCity")}
                variant="standard"
                sx={themes.CitySelectTextField}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIconGrey />
                        </InputAdornment>
                    ),
                }}
                value={props.filter}
                onChange={(event) => props.setFilter(event.target.value)}
            />
            <SButton
                variant="text"
                onClick={handleAutomaticGeo}
                disableRipple
                style={{ marginTop: "11px" }}
            >
                {t("main.header.detectAutomatically")}
            </SButton>
            <List sx={themes.CityList}>
                {cities
                    .filter((c) => c.title.toLowerCase().includes(props.filter.toLowerCase()) || props.filter === "")
                    .map((v, k) => (
                        <ListItem
                            sx={{ m: 0, p: "0" }}
                            key={v.id}
                        >
                            <ListItemButton
                                onClick={handleChoose.bind(this, v)}
                                disableRipple
                                sx={themes.CityButton}
                            >
                                {v.title}
                                {v.country !== "Россия" ? (
                                    <Typography sx={themes.AdditionalCityText}>{v.country}</Typography>
                                ) : v.region.toLowerCase().includes("область") || v.region.toLowerCase().includes("край") ? (
                                    <Typography sx={themes.AdditionalCityText}>{v.region}</Typography>
                                ) : null}
                            </ListItemButton>
                        </ListItem>
                    ))}
            </List>
        </Dialog>
    );
});

/*CitySelect.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};*/

export default memo(CitySelectModal);
