import { Box, Typography } from "@mui/material";
import React from "react";
import * as theme from "./AccountSectionTitleThemes";
import * as textThemes from "themes/TextThemes";

const AccountSectionTitle = ({ pt, pb, text }) => {
    return (
        <Box sx={theme.TitlePaddingPos(pt, pb)}>
            <Typography sx={textThemes.HeadlineH5Black}>{text}</Typography>
        </Box>
    );
};

export default AccountSectionTitle;
