import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import YesNoBlock from "components/AccountBodyBlock/StudentAccount/Stories/Stories/YesNoBlock/YesNoBlock";
import LikeDislikeBlock from "components/AccountBodyBlock/StudentAccount/Stories/Stories/LikeDislikeBlock/LikeDislikeBlock";
import * as themes from "components/AccountBodyBlock/StudentAccount/Stories/Stories/StoryThemes";
import * as textThemes from "themes/TextThemes";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const HelpIncreaseStory = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.StoryBox}>
            <Box sx={themes.HelpIncreaseOuter}>
                <Box sx={themes.StoryForm}>
                    <Typography sx={textThemes.Caption700White}>{t("main.studentAccount.stories.helpIncrease")}</Typography>
                    <YesNoBlock
                        mode="increaseMotivation"
                        story="HelpIncreaseStory"
                    />
                </Box>
            </Box>
            <LikeDislikeBlock story="HelpIncreaseStory" />
        </Box>
    );
});

export default memo(HelpIncreaseStory);
