export const tutorBodyBlockStyle = {
    display: "flex",
    flexDirection: "row",
    gap: 20,
};

export const tutorInfoBlockStyle = (medias) => {
    return {
        position: "relative",
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        padding: 0,
        width: "fit-content",
        marginLeft: medias.sm ? "112px" : "0px",
        marginTop: "20px",
    };
};
