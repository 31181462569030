import * as themes from "./TutorLevelThemes";
import * as textThemes from "themes/TextThemes";
import React, { memo } from "react";
import { Box, Typography } from "@mui/material";
import ProgramLevelExampleIcon from "assets/TeacherQuestionnaireAssets/ProgramLeveExamplelcon";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";

const TutorLevel = ({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.LevelWrapper(medias)}>
            <Box sx={themes.TeacherQuestionnaireTopBlock}>
                <Typography sx={textThemes.HeadlineH41Black}>{t("TeacherQuestionnaire.earnings")}</Typography>
                <Box sx={themes.TeacherQuestionnaireSubTitle}>
                    <Typography sx={textThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.forEachClassConducted")}</Typography>
                </Box>
            </Box>
            <Box sx={themes.TeacherQuestionnaireProgramExamplesContainer}>
                <Box sx={themes.TeacherQuestionnaireClassesNumberExampleBox(medias)}>
                    <Box sx={themes.TeacherQuestionnaireClassesNumberExampleInnerBox}>
                        <Typography sx={textThemes.Body1DarkGrey}>{t("TeacherQuestionnaire.numberOfClasses")}</Typography>
                        <Typography sx={textThemes.Button16Black}>{t("TeacherQuestionnaire.8classes")}</Typography>
                    </Box>
                    <Typography sx={textThemes.Caption1DarkGrey}>{t("TeacherQuestionnaire.4classesLeft")}</Typography>
                </Box>
                <Box sx={themes.RightExampleBox(medias)}>
                    <Box sx={themes.TeacherQuestionnaireLevelExampleBox(medias)}>
                        <Box sx={themes.TeacherQuestionnaireClassesNumberExampleInnerBox}>
                            <Typography sx={textThemes.Body1DarkGrey}>{t("TeacherQuestionnaire.tutorLevel")}</Typography>
                            <Box sx={themes.TeacherQuestionnaireLevelDescrBox}>
                                <Typography
                                    component="span"
                                    sx={textThemes.Button16Black}
                                >
                                    {t("TeacherQuestionnaire.platinum")}
                                </Typography>
                                <Typography
                                    component="span"
                                    sx={textThemes.Caption1Black}
                                >
                                    {t("TeacherQuestionnaire.12commission")}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={themes.TeacherQuestionnaireLevelPointsContainer}>
                            <Box sx={themes.TeacherQuestionnaireLevelPointsLine} />
                            <Box sx={themes.TeacherQuestionnaireLevelPointsBox}>
                                <Typography sx={textThemes.Caption1Black}>{t("TeacherQuestionnaire.1200points")}</Typography>
                                <Typography sx={textThemes.Caption1DarkGrey}>{t("TeacherQuestionnaire.out1200")}</Typography>
                            </Box>
                        </Box>
                        <Box sx={themes.ProgramLevelExampleIconBox}>
                            <ProgramLevelExampleIcon />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default memo(TutorLevel);
