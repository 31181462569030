import { Radio, styled } from "@mui/material";

export const StyledRadio = styled(Radio)({
    position: "relative",
    padding: "0px",
    paddingLeft: "8px",
    color: "#6212FF",
    "&.Mui-checked": {
        color: "#6212FF",
    },
    "& .MuiSvgIcon-root": {
        height: 20,
        width: 20,
    },
    "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
        stroke: "#6212FF",
        strokeWidth: 7,
    },
});

export const ResponseRadioSelectRadioGroup = { display: "flex", gap: "8px" };

export const ResponseRadioFormControlLabel = {
    display: "flex",
    alignItems: "center",
    marginTop: "4px",
    gap: "10px",
    "&.MuiFormControlLabel-root": {
        maxWidth: "396px",
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
    },
};
