export const StepperBox = {
    maxWidth: 289,
};

export const ArrowBox = {
    display: "flex",
    alignItems: "center",
    width: "100%",
};

export const StepLabel = {
    display: "inline-block",
    paddingLeft: "5px",
    whiteSpace: "nowrap",
};

export const StepDescription = {
    display: "flex",
    alignItems: "center",
    paddingLeft: "21px",
    marginBottom: "5px",
};

export const NextLabelBox = {
    display: "flex",
    alignItems: "center",
};

export const PreviousStepBox = {
    paddingLeft: "21px",
    paddingBottom: "5px",
};
