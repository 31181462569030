import React from "react";

import { Box } from "@mui/material";

import AddFilesForm from "components/UI/AddFilesForm/AddFilesForm";

import Actions from "./Actions";
import MistakesAndRequerements from "./MistakesAndRequerements";

import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";

const ModalContent = ({ close }) => {
    const sendRequirements = () => {
        DiplomaStore.setStatus("correcting");
        close();
    };

    return (
        <Box
            padding="30px 40px 30px 40px"
            sx={{
                background: "#FFF",
                borderRadius: "0px 0px 30px 30px",
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                gap={2.5}
            >
                <MistakesAndRequerements />
                <AddFilesForm
                    addFilesText="Добавьте фото/файл"
                    padding="16px 20px"
                />
                <Actions
                    close={close}
                    send={sendRequirements}
                />
            </Box>
        </Box>
    );
};

export default ModalContent;
