import { Avatar, Grid, IconButton, InputAdornment, Radio, TextField } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { IconBackMini } from "assets/MiniChat/SVG/IconBackMini";
import SButton from "components/UI/SButton";
import React, { memo } from "react";
import * as themes from "./AddChatsToFolderThemes";
import * as textThemes from "themes/TextThemes";
import { SearchIcon } from "assets/MiniChat/SVG/SearchIcon";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import FullChatStore from "store/FullChatStore";
import { Body2Black } from "themes/TextThemes";
import { AvatarTextCard } from "./AddChatsToFolderThemes";

const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "90%",
    width: "2.1vh",
    height: "2.1vh",
    boxShadow: theme.palette.mode === "dark" ? "#6212FF" : "inset 0 0 0 1px rgba(98, 18, 255, 1), inset 0 -1px 0 rgba(98, 18, 255, 1)",
    "input:hover ~ &": {
        backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#6212FF",
    backgroundImage: "#6212FF",
    "input:hover ~ &": {
        backgroundColor: "#6212FF",
    },
});

export function BpRadio(props) {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}

const AddChatsToFolder = observer(({ children, ...props }) => {
    const GetDialog = () => {
        if (FullChatStore.newFolder) {
            if (FullChatStore.newFolder.length > 0 || FullChatStore.FolderChanged) return FullChatStore.newFolder;
            else if (props.folderChats)
                return props.folderChats.infocards.map((item) => ({
                    chat_id: item.chat_id,
                    name: item.name,
                    avatar: item.avatar,
                }));
            else return [];
        } else if (props.folderChats)
            return props.folderChats.infocards.map((item) => ({
                chat_id: item.chat_id,
                name: item.name,
                avatar: item.avatar,
            }));
        else return [];
    };

    const [newDialogs, SetNewDialogs] = useState(GetDialog());

    const Ready = () => {
        FullChatStore.setFolder(newDialogs);
        FullChatStore.SetFolderChanged(true);
        FullChatStore.SetFolderProcessingStep(0);
    };

    const [edited, setEdited] = useState(false);

    const [name, setName] = useState("");

    const handleChange = (e) => {
        setName(e.target.value);
    };

    const handleChangeDialog = (e) => {
        setEdited(true);
        let ind = newDialogs.find((chat) => chat.chat_id === e.chat_id);

        if (ind === undefined) SetNewDialogs([...newDialogs, e]);
        else {
            SetNewDialogs(newDialogs.filter((item) => item.chat_id !== ind.chat_id));
        }
    };

    const [curServ, SetCurServ] = useState(FullChatStore.tabsData.chats);

    const SearchedChats = useMemo(() => {
        return [...curServ].filter((item) => (name !== "" ? (item.participants_info[0].name + item.participants_info[0].surname).toLowerCase().includes(name.toLowerCase()) : true));
    }, [name, curServ]);

    return (
        <Box sx={themes.AddChatsToFolder}>
            <Box sx={themes.TeacherField}>
                <SButton
                    variant="icon"
                    onClick={() => {
                        FullChatStore.SetFolderProcessingStep(0);
                        SetNewDialogs();
                    }}
                >
                    <IconBackMini />
                </SButton>
                <Typography sx={textThemes.Button16Black}>Добавить чаты</Typography>
                <SButton
                    variant="text"
                    disabled={!edited}
                    onClick={() => Ready()}
                >
                    Готово
                </SButton>
            </Box>
            <Box sx={themes.AddChatsToFolderContent}>
                <TextField
                    placeholder="Найти по имени"
                    fullWidth
                    value={name}
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={themes.TextStyles}
                    margin="none"
                />
                <Box sx={themes.GridChats}>
                    {SearchedChats.map((item, index) => (
                        <Box
                            key={index}
                            sx={themes.ChatItem}
                            onClick={() =>
                                handleChangeDialog({
                                    chat_id: item.chat_id,
                                    name: item.participants_info[0].name + " " + item.participants_info[0].surname,
                                    avatar: "https://study-buddy.ru" + item.participants_info[0].photoLink,
                                })
                            }
                        >
                            <Box sx={themes.AvatarTextCard}>
                                <Avatar
                                    src={"https://study-buddy.ru" + item.participants_info[0].photoLink}
                                    sx={themes.Avatar}
                                />
                                <Typography sx={textThemes.Body2Black}>{item.participants_info[0].name + " " + item.participants_info[0].surname}</Typography>
                            </Box>
                            <BpRadio checked={newDialogs && newDialogs.find((chat) => chat.chat_id === item.chat_id) !== undefined}></BpRadio>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
});

export default memo(AddChatsToFolder);
