const TutorVerifiedIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
        >
            <path
                d="M17.4811 6.17464C17.4719 5.69267 17.4632 5.23736 17.4632 4.79687C17.4632 4.63115 17.3974 4.47221 17.2802 4.35503C17.163 4.23785 17.0041 4.17201 16.8383 4.17201C14.166 4.17201 12.1314 3.40402 10.4355 1.75506C10.3188 1.64161 10.1626 1.57812 9.99986 1.57812C9.83715 1.57812 9.68087 1.64161 9.56425 1.75506C7.86846 3.40402 5.83418 4.17201 3.16203 4.17201C2.99631 4.17201 2.83737 4.23785 2.72019 4.35503C2.60301 4.47221 2.53717 4.63115 2.53717 4.79687C2.53717 5.23744 2.52851 5.69292 2.51926 6.17498C2.43361 10.6603 2.31631 16.8031 9.79519 19.3955C9.92773 19.4414 10.0719 19.4414 10.2044 19.3955C17.6839 16.8031 17.5668 10.66 17.4811 6.17464ZM9.9999 18.142C3.58577 15.812 3.68275 10.7077 3.76881 6.1988C3.77398 5.9282 3.77897 5.66593 3.78247 5.40882C6.28524 5.30317 8.28662 4.54476 9.9999 3.05227C11.7133 4.54476 13.7151 5.30326 16.218 5.40882C16.2215 5.66584 16.2265 5.92795 16.2316 6.19839C16.3176 10.7074 16.4145 15.8119 9.9999 18.142Z"
                fill="#82D4AE"
            />
            <path
                d="M9.9999 18.142C3.58577 15.812 3.68275 10.7077 3.76881 6.1988C3.77398 5.9282 3.77897 5.66593 3.78247 5.40882C6.28524 5.30317 8.28662 4.54476 9.9999 3.05227C11.7133 4.54476 13.7151 5.30326 16.218 5.40882C16.2215 5.66584 16.2265 5.92795 16.2316 6.19839C16.3176 10.7074 16.4145 15.8119 9.9999 18.142Z"
                fill="#82D4AE"
            />
            <path
                d="M12.0805 8.26068L12.0805 8.26067C12.2165 8.12476 12.4008 8.04842 12.5931 8.04844C12.7853 8.04846 12.9696 8.12483 13.1055 8.26075L12.0805 8.26068ZM12.0805 8.26068L9.13531 11.2057M12.0805 8.26068L9.13531 11.2057M9.13531 11.2057L7.91906 9.98945L7.91907 9.98944L7.91785 9.98826C7.78114 9.85611 7.59798 9.78296 7.40784 9.78458C7.21771 9.78619 7.03581 9.86244 6.90137 9.9969C6.76692 10.1314 6.69069 10.3133 6.68909 10.5034C6.68749 10.6935 6.76066 10.8767 6.89282 11.0134L6.89281 11.0134L6.89401 11.0146L8.6227 12.7433L9.13531 11.2057ZM8.85789 12.9006C8.76993 12.8642 8.69003 12.8107 8.62278 12.7434L8.85789 12.9006ZM8.85789 12.9006C8.94588 12.937 9.0402 12.9557 9.13544 12.9556M8.85789 12.9006L9.13544 12.9556M9.13544 12.9556L9.13531 12.8556M9.13544 12.9556L9.13531 12.8556M9.13531 12.8556L9.13518 12.9556C9.23041 12.9557 9.32474 12.937 9.41273 12.9006M9.13531 12.8556C9.05324 12.8557 8.97197 12.8396 8.89615 12.8082L9.41273 12.9006M9.41273 12.9006C9.50069 12.8642 9.58058 12.8107 9.64783 12.7434L9.41273 12.9006ZM9.64792 12.7433L13.1054 9.28584L9.64792 12.7433Z"
                fill="white"
                stroke="white"
                strokeWidth="0.2"
            />
        </svg>
    );
};

export default TutorVerifiedIcon;
