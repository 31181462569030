/**
 * Сервис обработки ответов с сервера
 */
class ResponseService {
    static STATUS_400_BAD_REQUEST = 400;
    static STATUS_401_UNAUTORIZED = 401;
    static STATUS_403_ACCESS_DENIED = 403;
    static STATUS_404_NOT_FOUND = 404;
    static STATUS_500_BAD_REQUEST = 500;
    static STATUS_503_SERVICE_UNAVAILABLE = 503;
    static STATUS_504_GATEWAY_TIMEOUT = 504;

    /**
     * Хэндлер ответов от сервера
     * @param {{status: number, data: {message: string}}} response Ответ от сервера
     * @returns {{}|undefined} Ответ от сервера
     */
    static handle(response) {
        if (!response) {
            throw new Error("Can not process the response that came from the server!");
        }

        switch (response.status) {
            case this.STATUS_401_UNAUTORIZED:
                this._handler401(response);
                break;

            case this.STATUS_404_NOT_FOUND:
                this._handler404();
                break;

            case this.STATUS_500_BAD_REQUEST:
                this._handler500(response.data.message);
                break;

            case this.STATUS_503_SERVICE_UNAVAILABLE:
                this._handler503();
                break;

            case this.STATUS_504_GATEWAY_TIMEOUT:
                this._handler504();
                break;

            default:
                break;
        }

        return response;
    }

    /**
     * Обработка ошибки 401 (ошибка авторизации)
     * @private
     */
    static _handler401() {
        let text = "Auth failed!";

        throw new Error(text);
    }

    /**
     * Обработка ошибки 404 (Запрашиваемый ресурс не был найден)
     * @private
     */
    static _handler404() {
        let text = "The requested resource was not found!";

        throw new Error(text);
    }

    /**
     * Обработка ошибки 500 (На сервере произошла ошибка)
     * @private
     */
    static _handler500(message = "An error has occurred on the server!") {
        throw new Error(message);
    }

    /**
     * Обработка ошибки 503 (Сервер временно не имеет возможности обрабатывать запросы по техническим причинам)
     * @private
     */
    static _handler503() {
        let text = "The server is temporarily unable to process requests for technical reasons (maintenance, overload, etc.).";

        throw new Error(text);
    }

    /**
     * Обработка ошибки 504 (Таймаут при ожидании ответа от сервера)
     * @private
     */
    static _handler504() {
        let text = "The server in the role of gateway or proxy did not wait for a response from the upstream server to complete the current SearchRequest.";

        throw new Error(text);
    }
}

export default ResponseService;
