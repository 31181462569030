import React from "react";

import { Stack, Typography } from "@mui/material";

import PdfIconSVG from "assets/CommonAssets/SVG/pdfIconSVG";
import DocIconSVG from "assets/CommonAssets/SVG/docIconSVG";

import * as styles from "components/DiplomaChats/styles";
import { Caption1DarkGrey } from "themes/TextThemes";
import Actions from "../../Actions";

const FILE_TYPES = {
    pdf: <PdfIconSVG />,
    doc: <DocIconSVG />,
};

const FileInfo = ({ fileInfo, downloadFile }) => {
    let fileType = fileInfo?.orig_name ? fileInfo?.orig_name.split(".").pop() : null;

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
        >
            <Stack
                direction="row"
                spacing={1.25}
            >
                <span style={styles.fileCont}>{FILE_TYPES[fileType]}</span>
                <Stack spacing={0.375}>
                    {/* TODO: Add style to TextThemes.js */}
                    <Typography
                        sx={{
                            color: "#222",
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "140%",
                        }}
                    >
                        {fileInfo?.orig_name}
                    </Typography>
                    <Stack
                        direction="row"
                        spacing={0.75}
                        alignItems="center"
                        alignContent="center"
                    >
                        <Typography sx={Caption1DarkGrey}>{`${fileInfo?.size} • ${fileInfo?.created_at}`}</Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Stack>
                <Actions
                    downloadFile={downloadFile}
                    info={fileInfo}
                />
            </Stack>
        </Stack>
    );
};

export default FileInfo;
