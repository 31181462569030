import { makeAutoObservable } from "mobx";
import RequestService from "API/services/RequestService";
import { findChatById, get, reverseMassiv } from "../components/ChatBodyBlock/CommonData/helper_functions";
import UserStore from "./UserStore";
import Dima from "../assets/ChatAssets/PNG/Dima.png";
import Konstantin from "../assets/ChatAssets/PNG/Konstantin.png";
import Boris from "../assets/ChatAssets/PNG/Pavel.png";
import Evgen from "../assets/ChatAssets/PNG/Evgen.png";
import Maria from "../assets/ChatAssets/PNG/Maria.png";
import Ekater from "../assets/ChatAssets/PNG/Ekater.png";
import { getCookie } from "API/utils";
import { useNavigate } from "react-router-dom";
import MiniChatStore from "./MiniChatStore";
import backendHttpRoutes from "../router/backendHttpRoutes";

const requestService = new RequestService();

class FullChatStore {
    messageActionsOptions = [];

    id = "";

    participantId = "";

    tabsData = {};

    currentFolder = "";

    isSearch = false;

    activeChatIndex = 0;

    isBlocked = false;
    isBlockedBy = false;

    searchMessageText = "";
    selectedMessage = null;
    showSearchMessagesModal = false;
    showSearchMessagesResultModal = false;

    openSearchMessagesMini = false;
    openSearchMessagesResultMini = false;
    foundMessages = [];
    mediaPage = false;
    toScrollMessage = null;
    toScrollMessageWait = null;
    noScroll = false;
    lastMessage = null;
    isWrite = false;

    currPinnedMessageId = null;
    needScroll = false;
    idPin = null;
    isPin = false;
    currPinnedMessageText = null;
    muteMenuChatId = null;

    selectedChatAttachments = {
        images: null,
        videos: null,
        files: null,
        urls: null,
    };

    /********************/

    message_update = {
        action: "get_data",
    };

    avatar = {
        Дмитрий: Dima,
        Константин: Konstantin,
        Борис: Boris,
        Егор: Evgen,
        Василий: Maria,
        Антон: Ekater,
    };

    FolderChanged = false;

    newFolder = [];

    newFolderName = "";

    folderProcessingStep = null;

    Open = false;

    refresh = false;

    /******************/

    constructor() {
        this.Init();
        makeAutoObservable(this);
    }

    debounce(callback, delay) {
        return (...args) => {
            if (this.timerID) {
                clearTimeout(this.timerID);
            }

            this.timerID = setTimeout(() => {
                callback.call(this, ...args);
            }, delay);
        };
    }

    SetMenuChatId(value) {
        this.muteMenuChatId = value;
    }

    SetRefresh(value) {
        this.refresh = value;
    }

    SetBlocked(value) {
        this.isBlocked = value;
    }

    SetBlockedBy(value) {
        this.isBlockedBy = value;
    }

    SetIsWrite(value) {
        this.isWrite = value;
    }

    SetLastMessage(value) {
        this.lastMessage = value;
    }

    SetNoScroll(value) {
        this.noScroll = value;
    }

    SetFoundMessages(value) {
        this.foundMessages = value;
    }

    SetToScrollMessage(value) {
        this.toScrollMessage = value;
    }

    SetMediaPage(value) {
        this.mediaPage = value;
    }

    SetChatID(value) {
        this.id = value;
    }

    SetParticipantID(value) {
        this.participantId = value;
    }

    SetTabsData(value) {
        this.tabsData = value;
    }

    GetForScrollMessages(sendJsonMessage, pinnedmessageid) {
        let curchat = reverseMassiv(this.tabsData, this.id);

        if (pinnedmessageid) {
            if (curchat.find((item) => item.message_id === pinnedmessageid) === undefined) {
                sendJsonMessage({
                    action: "scroll_to_message",
                    chat_id: this.id,
                    start_message_id: curchat[0].message_id,
                    to_message_id: pinnedmessageid,
                });
                this.needScroll = pinnedmessageid;
            } else this.SetToScrollMessage(pinnedmessageid);
        }

        if (this.foundMessages !== null) {
            if (this.foundMessages.length > 0) {
                sendJsonMessage({
                    action: "scroll_to_message",
                    chat_id: this.id,
                    start_message_id: curchat[0].message_id,
                    to_message_id: this.foundMessages[this.foundMessages.length - 1].message_id,
                });
            }
        } else this.SetToScrollMessage(null);
    }

    GetForScrollMessagesNoLimit(sendJsonMessage) {
        var curchat = reverseMassiv(this.tabsData, this.id);

        if (curchat.length > 0) {
            this.SetLastMessage(curchat[0].message_id);
            sendJsonMessage({
                action: "get_fix_messages",
                chat_id: this.id,
                start: curchat[0].message_id,
            });
        } else this.SetNoScroll(false);
    }

    SendIsWrite(sendJsonMessage, isWrite) {
        sendJsonMessage({
            action: "typing_message",
            chat_id: this.id,
            typing: isWrite,
        });
    }

    SendComplain(sendJsonMessage, complain, participantId) {
        sendJsonMessage({
            action: "complain",
            user_id: participantId,
            text: complain,
        });
    }

    СhangeArchive(sendJsonMessage, val) {
        sendJsonMessage({
            action: val === true ? "remove_from_archive" : "add_to_archive",
            chat_id: this.id,
        });
    }

    СhangeMute(sendJsonMessage, val) {
        sendJsonMessage({
            action: "disable_notifications",
            chat_id: this.id,
            disabled_notifications: val,
        });
    }

    СhangePin(sendJsonMessage, val) {
        sendJsonMessage({
            action: "pin_chat",
            chat_id: this.id,
            pin: val,
        });
    }

    AddMessagesToChat(data) {
        if (data.messages.length > 0) {
            const findId = findChatById(this.tabsData.chats, this.id, true);

            data.messages.map((item) => this.tabsData.chats[findId].messages.push(item));
            this.SetChange();
            if (this.needScroll !== false) {
                this.SetToScrollMessageWait(this.needScroll);
                this.needScroll === false;
            }
        } else this.SetNoScroll(false);
    }

    SetToScrollMessageWait(value) {
        this.toScrollMessageWait = value;
    }

    AddReaction(value, data, callback) {
        callback({
            action: "reaction_to_message",
            chat_id: data.chat_id,
            message_id: data.message_id,
            reaction: value,
        });
    }

    RemoveReaction(data, callback) {
        callback({
            action: "cancel_reaction_to_message",
            chat_id: data.chat_id,
            message_id: data.message_id,
        });
    }

    DelChat(sendJsonMessage, all) {
        sendJsonMessage({
            action: "delete_chat",
            chat_id: this.id,
            for_all: all,
        });

        this.SetChatID("");
        this.SetParticipantID("");
    }

    ExecuteAction(value, data, callback) {
        switch (value.ID) {
            case 0:
                this.EditMessageAction(callback);
                break;
            case 1:
                this.ReplyMessageAction(callback);
                break;
            case 2:
                this.DeleteMessageAction(data, callback);
                break;
            case 3:
                this.AddDelPinnedMessage(callback, data.message_id, true);
                break;
            default:
        }
    }

    EditMessageAction(callback) {
        callback();
    }

    ReplyMessageAction(callback) {
        callback();
    }

    DeleteMessageAction(value, callback, all) {
        let indchat = findChatById(this.tabsData.chats, this.id, true);

        if (this.tabsData.chats[indchat].messages.includes(value.message_id)) this.AddDelPinnedMessage(callback, value.message_id, false);

        callback({
            action: "delete_message",
            chat_id: value.chat_id,
            message_id: value.message_id,
            for_all: all,
        });
    }

    SetSearchMessageText(value) {
        this.searchMessageText = value;
    }

    SetSelectedMessage(value) {
        this.selectedMessage = value;
    }

    SetShowSearchMessagesModal(value) {
        this.showSearchMessagesModal = value;
    }

    SetShowSearchMessagesResultModal(value) {
        this.showSearchMessagesResultModal = value;
    }

    SetShowSearchMessagesMini(value) {
        this.openSearchMessagesMini = value;
    }

    SetShowSearchMessagesResultMini(value) {
        this.openSearchMessagesResultMini = value;
    }

    SetCurrMessagePinnedId(value) {
        this.currPinnedMessageId = value;
    }

    AddDelPinnedMessage(sendJson, messageid, action) {
        sendJson({
            action: "pin_message",
            chat_id: this.id,
            message_id: messageid,
            pin: action,
        });
        this.toPin = messageid;
        this.isPin = action;
        this.SetToScrollMessage(null);
    }

    GetTextPinnedMessage(sendJson, messageid) {
        sendJson({
            action: "get_message",
            chat_id: this.id,
            message_id: messageid,
        });
    }

    async LoadImage(imageLink) {
        requestService.setResponseType("blob");
        let url, method;

        url = backendHttpRoutes("chats.download.get");
        method = requestService.METHOD_GET;
        const res = await requestService.sendAsync(
            url,
            method,
            {
                chat_id: this.id,
                attachment_id: imageLink,
            },
            true,
        );

        return res?.data;
    }

    async ProcessMedias(data) {
        let result = [];

        let attachment = null;

        let attachmentType = null;

        // eslint-disable-next-line no-undef
        let contents = await Promise.allSettled(data.map((value) => this.LoadImage(value.link)));

        contents.forEach((content) => {
            attachment = URL.createObjectURL(content.value);
            attachmentType = content.value.type.split("/")[0];
            result = [...result, { src: attachment, type: attachmentType }];
        });
        return result;
    }

    newLoadedMessages = [];
    SetNewLoadedMessages = (messages) => {
        this.newLoadedMessages = messages;
    };

    change = false;
    SetChange = () => {
        this.change = !this.change;
    };

    ProcessMessages(sendMessage, sendJsonMessage, lastMessage, lastJsonMessage, navigate) {
        if (!lastMessage) {
            var x = new Date();

            if (getCookie("role") === "student") {
                sendJsonMessage({
                    id: 1,
                    token: getCookie("studentAccessToken"),
                    timezone: x.getTimezoneOffset() / 60,
                });
            }

            if (getCookie("role") === "tutor") {
                sendJsonMessage({
                    id: 1,
                    token: getCookie("tutorAccessToken"),
                    timezone: x.getTimezoneOffset() / 60,
                });
            }
        } else {
            if ("auth" in lastJsonMessage) {
                if (lastJsonMessage.auth !== true) {
                    MiniChatStore.SetOpen(false);
                    this.SetToScrollMessage(null);
                    UserStore.setAuth(false);

                    navigate("/");
                    return;
                } else {
                    sendJsonMessage({
                        action: "get_data",
                    });
                }
            }

            if (lastJsonMessage?.meta_info?.action === "get_links") {
                this.tabsData.chats.forEach((chat, i) => {
                    if (chat.chat_id === this.id) {
                        this.tabsData.chats[i].attachments = lastJsonMessage.data;
                        this.SetSelectedChatAttachments(lastJsonMessage.data);
                    }
                });
            }

            if ("status" in lastJsonMessage) {
                const found = this.tabsData.chats.findIndex((element) => element.participants_info[0].uuid === lastJsonMessage.user_id);

                if ("online" in lastJsonMessage.status) {
                    this.tabsData.chats[found].participants_info[0].status = lastJsonMessage.status;
                } else {
                    this.tabsData.chats[found].participants_info[0].status = lastJsonMessage.status;
                }

                this.SetRefresh(!this.refresh);
            }

            if ("meta_info" in lastJsonMessage) {
                let reaction = null;

                let message_id = null;

                let chat_id = null;

                let uuid = null;

                let editedMessage = null;

                let edited = null;

                switch (lastJsonMessage.meta_info.action) {
                    case "get_data":
                        this.SetTabsData(lastJsonMessage.data);

                        let indchat = 0;

                        if (this.id !== "") {
                            indchat = lastJsonMessage.data.chats?.findIndex((item) => item.chat_id === this.id);
                        }

                        if (this.id === "") this.SetChatID(lastJsonMessage.data.chats[0]?.chat_id);

                        this.SetCurrMessagePinnedId(
                            lastJsonMessage.data.chats[indchat]?.pinned_messages.length > 0
                                ? lastJsonMessage.data.chats[indchat]?.pinned_messages[lastJsonMessage.data.chats[indchat]?.pinned_messages.length - 1]
                                : null,
                        );
                        if (this.participantId === "") this.SetParticipantID(lastJsonMessage.data.chats[0]?.participants_info[0].uuid);

                        break;
                    case "reaction_to_message":
                        reaction = lastJsonMessage.data.reaction;

                        message_id = lastJsonMessage.data.message_id;

                        chat_id = lastJsonMessage.data.chat_id;

                        uuid = lastJsonMessage.data.uuid;

                        this.tabsData.chats = this.tabsData.chats.map((chat) =>
                            chat.chat_id === chat_id
                                ? {
                                      ...chat,
                                      messages: chat.messages.map((message) =>
                                          message.message_id === message_id
                                              ? {
                                                    ...message,
                                                    reactions: /*message.reactions.length ? [...message.reactions, { reaction: reaction, uuid: uuid }] : */ [
                                                        {
                                                            reaction: reaction,
                                                            uuid: uuid,
                                                        },
                                                    ],
                                                }
                                              : message,
                                      ),
                                  }
                                : chat,
                        );
                        break;
                    case "cancel_reaction_to_message":
                        message_id = lastJsonMessage.data.message_id;

                        chat_id = lastJsonMessage.data.chat_id;

                        this.tabsData.chats = this.tabsData.chats.map((chat) =>
                            chat.chat_id === chat_id
                                ? {
                                      ...chat,
                                      messages: chat.messages.map((message) =>
                                          message.message_id === message_id
                                              ? {
                                                    ...message,
                                                    reactions: [],
                                                }
                                              : message,
                                      ),
                                  }
                                : chat,
                        );
                        break;
                    case "send_message":
                        chat_id = lastJsonMessage.data.chat_id;

                        this.tabsData.chats = this.tabsData.chats.map((chat) =>
                            chat.chat_id === chat_id
                                ? {
                                      ...chat,
                                      messages: [lastJsonMessage.data, ...chat.messages],
                                  }
                                : chat,
                        );
                        break;
                    case "edit_message":
                        chat_id = lastJsonMessage.data.chat_id;

                        message_id = lastJsonMessage.data.message_id;

                        editedMessage = lastJsonMessage.data.message;

                        edited = lastJsonMessage.data.edited;

                        this.tabsData.chats = this.tabsData.chats.map((chat) =>
                            chat.chat_id === chat_id
                                ? {
                                      ...chat,
                                      messages: chat.messages.map((message) =>
                                          message.message_id === message_id
                                              ? {
                                                    ...message,
                                                    message: editedMessage,
                                                    edited: edited,
                                                }
                                              : message,
                                      ),
                                  }
                                : chat,
                        );
                        break;
                    case "delete_message":
                        chat_id = lastJsonMessage.data.chat_id;

                        message_id = lastJsonMessage.data.message_id;

                        this.tabsData.chats = this.tabsData.chats.map((chat) =>
                            chat.chat_id === chat_id
                                ? {
                                      ...chat,
                                      messages: [...chat.messages.filter((message) => message.message_id !== message_id)],
                                  }
                                : chat,
                        );
                        break;
                    case "add_to_archive":
                        sendJsonMessage(this.message_update);
                        break;
                    case "updated_data":
                        this.SetTabsData(lastJsonMessage.data);
                        break;
                    case "search_message":
                        this.SetFoundMessages(lastJsonMessage.data);
                        this.GetForScrollMessages(sendJsonMessage);
                        break;
                    case "scroll_to_message":
                        this.AddMessagesToChat(lastJsonMessage.data);
                        break;
                    case "typing_message":
                        if (lastJsonMessage.typing_message.user_id === this.participantId) {
                            this.SetIsWrite(lastJsonMessage.typing_message.typing);
                        }

                        break;
                    case "get_fix_messages":
                        this.AddMessagesToChat(lastJsonMessage.data);
                        this.SetNewLoadedMessages(lastJsonMessage.data.messages);
                        this.SetChange();
                        break;
                    case "remove_from_archive":
                        sendJsonMessage(this.message_update);
                        break;
                    case "disable_notifications":
                        if (lastJsonMessage.data.success === true) {
                            let indchat = null;

                            if (this.muteMenuChatId) {
                                indchat = findChatById(this.tabsData.chats, this.muteMenuChatId, true);
                                this.SetMenuChatId(null);
                            } else indchat = findChatById(this.tabsData.chats, this.id, true);

                            this.tabsData.chats[indchat].disabled_notifications = !this.tabsData.chats[indchat].disabled_notifications;
                            this.SetChange();
                        }

                        break;
                    case "pin_chat":
                        if (lastJsonMessage.data.success === true) {
                            let indchat = null;

                            if (this.muteMenuChatId) {
                                indchat = findChatById(this.tabsData.chats, this.muteMenuChatId, true);
                                this.SetMenuChatId(null);
                            } else indchat = findChatById(this.tabsData.chats, this.id, true);

                            this.tabsData.chats[indchat].pin = !this.tabsData.chats[indchat].pin;
                        }

                        break;
                    case "edit_folder":
                        sendJsonMessage(this.message_update);
                        break;
                    case "delete_history":
                        sendJsonMessage(this.message_update);
                        break;
                    case "delete_chat":
                        sendJsonMessage(this.message_update);
                        break;
                    case "get_message":
                        this.currPinnedMessageText = lastJsonMessage.data.message;
                        break;
                    case "pin_message":
                        if (lastJsonMessage.data.success === true) {
                            let indchat = findChatById(this.tabsData.chats, this.id, true);

                            if (this.isPin === true) {
                                this.tabsData.chats[indchat].pinned_messages.push(this.toPin);
                                this.currPinnedMessageId = this.toPin;
                            } else {
                                this.tabsData.chats[indchat].pinned_messages = this.tabsData.chats[indchat].pinned_messages.filter((item) => item !== this.toPin);
                                this.tabsData.chats[indchat].pinned_messages.length > 0
                                    ? (this.currPinnedMessageId = this.tabsData.chats[indchat].pinned_messages[this.tabsData.chats[indchat].pinned_messages.length - 1])
                                    : (this.currPinnedMessageId = null);
                            }

                            break;
                        }

                        break;
                    default:
                        break;
                }
            }
        }
    }

    SetSelectedChatAttachments(data) {
        this.selectedChatAttachments = data;
    }

    SetOpen(value) {
        this.Open = value;
    }

    /***************************/

    setFolder(value) {
        this.newFolder = value;
    }

    SetFolderName(value) {
        this.newFolderName = value;
    }

    SetFolderChanged(value) {
        this.FolderChanged = value;
    }

    SetFolderProcessingStep(value) {
        this.folderProcessingStep = value;
    }

    /***************************/

    async BlockUser(participantID, callback, isRightBlock) {
        requestService.setResponseType("json");
        const data = JSON.stringify({
            user_id: participantID,
        });

        let url, method;

        url = backendHttpRoutes("chats.block.user.post");
        method = requestService.METHOD_POST;
        const res = await requestService.sendAsync(url, method, data, true);

        if (res.data) {
            if (res.data.success === true) {
                isRightBlock === undefined && this.SetBlocked(true);
                callback({
                    action: "get_data",
                });
            }
        }
    }

    async UnblockUser(participantID, callback, isRightBlock) {
        requestService.setResponseType("json");
        const data = JSON.stringify({
            user_id: participantID,
        });

        let url, method;

        url = backendHttpRoutes("chats.unblock.user.post");
        method = requestService.METHOD_POST;
        const res = await requestService.sendAsync(url, method, data, true);

        if (res.data) {
            if (res.data.success === true) {
                isRightBlock === undefined && this.SetBlocked(false);
                callback({
                    action: "get_data",
                });
            }
        }
    }

    Init() {
        this.Load();
    }

    Load() {
        this.messageActionsOptions = [
            { ID: 0, actionText: "Редактировать" },
            { ID: 1, actionText: "Ответить" },
            { ID: 2, actionText: "Удалить" },
            { ID: 3, actionText: "Закрепить" },
        ];
    }
}

export default new FullChatStore();
