import { Box, Divider, Typography } from "@mui/material";
import OrderDate from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/OrderDate/OrderDate";
import OrderSoloAvatar from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/OrderSoloAvatar/OrderSoloAvatar";
import React from "react";
import TargetBoxRounded from "components/OrdersBodyBlock/TutorOrders/OrderCards/CommonCardComponents/TargetBoxRounded/TargetBoxRounded";
import OrderStatusRounded from "components/OrdersBodyBlock/TutorOrders/OrderCards/CommonCardComponents/OrderStatusRounded/OrderStatusRounded";
import PriceFormater from "components/OrdersBodyBlock/TutorOrders/OrderCards/CommonCardComponents/PriceFormater/PriceFormater";
import { memo } from "react";
import * as textThemes from "themes/TextThemes";
import * as themes from "./InProgressCardThemes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMedias } from "API/mediasHook";
import * as theme2 from "components/OrdersBodyBlock/OrderCard/StyledOrderCardThemes";

const InProgressCard = ({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const handleClickCard = () => {
        navigate("/diplomas");
    };

    return (
        <Box
            sx={theme2.StyledOrderCard(medias)}
            onClick={handleClickCard}
        >
            <Box sx={themes.TopBlock}>
                <Box sx={themes.TopCardWrapper(medias)}>
                    <Typography
                        variant="h6"
                        sx={textThemes.HeadlineH5Black}
                    >
                        {props.orderInfo.title}
                    </Typography>
                    <OrderDate date={props.orderInfo.date} />
                </Box>
                <Box sx={themes.TargetSubjWrapper(medias)}>
                    <TargetBoxRounded>{props.orderInfo.target}</TargetBoxRounded>
                    <TargetBoxRounded>{props.orderInfo.subject}</TargetBoxRounded>
                </Box>
            </Box>

            <Box>
                <Box sx={themes.AvatarPriceWrapper(medias)}>
                    <Box sx={themes.StudentNameAndMessages}>
                        <OrderSoloAvatar
                            name={props.orderInfo.name}
                            avatar={props.orderInfo.avatar}
                        />
                        {props.orderInfo.newMessages !== null && props.orderInfo.newMessages !== 0 ? (
                            <Box sx={themes.PurpleCircle}>
                                <Typography sx={textThemes.Button12White}>{props.orderInfo.newMessages}</Typography>
                            </Box>
                        ) : null}
                    </Box>
                    <Box>
                        <Typography
                            variant="span"
                            sx={textThemes.Body2Black}
                        >
                            {t("orders.tutorOrders.price")}
                        </Typography>
                        <PriceFormater price={props.orderInfo.price} />
                    </Box>
                </Box>

                <Box>
                    <Divider sx={themes.divider} />
                    <Box sx={themes.BottomCardWrapper(medias)}>
                        <Box sx={themes.DeadlineWrapper}>
                            <Typography
                                variant="span"
                                sx={textThemes.Body2Grey}
                            >
                                {t("orders.tutorOrders.completionDeadline")}
                            </Typography>
                            <Typography sx={textThemes.Body2Black}>
                                {props.orderInfo.deadline}
                                {props.orderInfo.burnDeadline && (
                                    <Typography
                                        variant="span"
                                        sx={textThemes.Body2Red}
                                    >
                                        {t("orders.tutorOrders.oneDay")}
                                    </Typography>
                                )}
                            </Typography>
                        </Box>
                        <OrderStatusRounded status={props.orderInfo.status} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default memo(InProgressCard);
