import { memo } from "react";
import { Card, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as themes from "./TeacherScheduleCardThemes";
import { ArrowOutwardSharp } from "@mui/icons-material";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";

const TeacherScheduleCard = observer(({ children, ...props }) => {
    const goToTutor = () => {
        window.open("/tutor/64902a483594e963dd20818a?initialTab=ScheduleCard");
    };

    return (
        <Card sx={themes.teacherScheduleCardStyle}>
            <Typography sx={themes.teacherScheduleHeaderStyle}>Расписание</Typography>
            <Box sx={themes.teacherScheduleContainerStyle}>
                <Box sx={themes.teacherScheduleHeaderContainerStyle}>
                    {props.value.Schedule[0].Days.map((value) => (
                        <Typography
                            key={value.ID}
                            style={themes.teacherScheduleDayStyle}
                        >
                            {value.Day}
                        </Typography>
                    ))}
                </Box>
                {props.value.Schedule.map((mainValue) => (
                    <Box
                        sx={themes.teacherScheduleRowContainerStyle}
                        key={mainValue.ID}
                    >
                        <Typography style={themes.teacherScheduleRowLabelsStyle}>{mainValue.Time}</Typography>
                        <Box sx={themes.teacherScheduleDaysContainerStyle}>
                            {mainValue.Days.map((daysValue) => (
                                <Box
                                    key={daysValue.ID}
                                    style={daysValue.Checked ? themes.teacherScheduleActiveDayStyle : themes.teacherScheduleNotActiveDayStyle}
                                ></Box>
                            ))}
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box
                sx={themes.teacherScheduleLinkContainerStyle}
                onClick={goToTutor}
            >
                <Typography sx={themes.teacherScheduleLinkStyle}>Всё расписание</Typography>
                <ThemeProvider theme={themes.ArrowIconTheme}>
                    <ArrowOutwardSharp></ArrowOutwardSharp>
                </ThemeProvider>
            </Box>
        </Card>
    );
});

export default memo(TeacherScheduleCard);
