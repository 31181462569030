import React from "react";
import { Stack } from "@mui/material";
import SingleDetail from "./LeftPanel/Details/SingleDetail";
import OrderNumberAndData from "./LeftPanel/Details/OrderNumberAndData";
import { detailsExample } from "components/DiplomaChats/store/orderInfoExample";

const OrderDetails = () => {
    return (
        <Stack
            direction="column"
            spacing={2.5}
        >
            <Stack>
                {detailsExample.map((detail) => (
                    <SingleDetail
                        key={detail.label}
                        label={detail.label}
                        info={detail.info}
                    />
                ))}
            </Stack>
            <OrderNumberAndData />
        </Stack>
    );
};

export default OrderDetails;
