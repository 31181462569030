import React, { memo } from "react";
import { Box, Typography } from "@mui/material";
import RefEmptyIcon from "assets/CommonAssets/SVG/RefEmptyIcon";
import UserStore from "store/UserStore";
import * as styles from "./RefTableEmptyThemes";
import * as textStyles from "themes/TextThemes";
import { observer } from "mobx-react-lite";
import SButton from "components/UI/SButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMedias } from "API/mediasHook";

const RefTableEmpty = observer(({ children, ...props }) => {
    const medias = useMedias();

    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    return (
        <Box sx={styles.RefTableEmpty}>
            <Box sx={styles.RefTableEmptyContent}>
                <RefEmptyIcon />
                <Typography sx={textStyles.HeadlineH5Black}>{UserStore.role === "tutor" ? t("main.balance.refTitleTeacher") : t("main.balance.refTitleStudent")}</Typography>
                <Typography sx={[medias.sm ? textStyles.Body1Black : textStyles.Body2Black, styles.RefTableEmptyContentSubtitle]}>
                    {UserStore.role === "tutor" ? t("main.balance.refDescriptionTeacher") : t("main.balance.refDescriptionStudent")}
                </Typography>
            </Box>
            <SButton
                variant="arrow"
                padding="7px 16px 8px 16px"
                onClick={() => navigate("/referral")}
                style={styles.ButtonSize(medias)}
            >
                {UserStore.role === "tutor" ? t("main.balance.refButtonTeacher") : t("main.balance.refButtonStudent")}
            </SButton>
        </Box>
    );
});

export default memo(RefTableEmpty);
