import { Box, ClickAwayListener, Grid, IconButton, InputAdornment, MenuItem, MenuList, Paper, Popper, TextField, Typography } from "@mui/material";
import { Folder } from "assets/MiniChat/SVG/Folder";
import { IconBackMini } from "assets/MiniChat/SVG/IconBackMini";
import SButton from "components/UI/SButton";
import React, { useState, useEffect, memo } from "react";
import * as themes from "./CreateOrEditFolderThemes";
import * as textThemes from "themes/TextThemes";
import { IconSettingsMini } from "assets/MiniChat/SVG/IconSettingsMini";
import useWebSocket from "react-use-websocket";
import { observer } from "mobx-react-lite";
import FullChatStore from "store/FullChatStore";
import Chats from "./Chats/Chats";

const URL = "wss://study-buddy.ru/chats/ws";

const CreateOrEditFolder = observer(({ children, ...props }) => {
    const [open, SetOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const [disabledName, SetDisabledName] = useState(props.folderChats ? props.folderChats.name !== "" : false);

    const [folderName, SetFolderName] = useState(FullChatStore.newFolderName ? FullChatStore.newFolderName : props.folderChats ? props.folderChats.name : "");

    const { sendMessage, sendJsonMessage, lastMessage, lastJsonMessage, readyState } = useWebSocket(URL, {
        share: true,
    });

    const message_create = {
        action: "create_folder",
        name: folderName,
        chats: FullChatStore.newFolder ? FullChatStore.newFolder.map((item) => item.chat_id) : null,
    };

    const message_delete = {
        action: "remove_folder",
        index: props.folderChats ? props.folderChats.index : null,
    };

    const message_edit = {
        action: "edit_folder",
        index: props.folderChats ? props.folderChats.index : null,
        rename: {
            name: FullChatStore.newFolderName ? FullChatStore.newFolderName : props.folderChats ? props.folderChats.name : null,
            take_change: !!FullChatStore.newFolderName,
        },
        add_chat_ids: {
            take_change: false,
        },
        remove_chat_ids: {
            take_change: false,
        },
    };

    const message_update = {
        action: "get_data",
    };

    const handleChangeName = (e) => {
        props.folderChats && FullChatStore.SetFolderChanged(true);
        SetFolderName(e.target.value);
        FullChatStore.SetFolderName(e.target.value);
    };

    const handleDisabled = () => {
        SetDisabledName(false);
    };

    const handleClose = () => {
        SetOpen(false);
        setAnchorEl(null);
    };

    const handleOpen = (e) => {
        setAnchorEl(anchorEl ? null : e.currentTarget);
        SetOpen(true);
    };

    const ItemInInfocards = (data, chat_id) => {
        return data.find((val) => val.chat_id === chat_id) === undefined;
    };

    const changeFolder = () => {
        if (FullChatStore.newFolder || FullChatStore.newFolderName) {
            if (props.folderChats && folderName !== "") {
                if (FullChatStore.newFolder) {
                    let add = [];

                    FullChatStore.newFolder.map((item) => {
                        if (ItemInInfocards(props.folderChats.infocards, item.chat_id)) add.push(item.chat_id);
                    });
                    let del = [];

                    props.folderChats.infocards.map((item) => {
                        if (ItemInInfocards(FullChatStore.newFolder, item.chat_id)) del.push(item.chat_id);
                    });
                    sendJsonMessage({
                        action: "edit_folder",
                        index: props.folderChats ? props.folderChats.index : null,
                        rename: {
                            name: FullChatStore.newFolderName ? FullChatStore.newFolderName : props.folderChats ? props.folderChats.name : null,
                            take_change: !!FullChatStore.newFolderName,
                        },
                        add_chat_ids: {
                            chat_ids: add,
                            take_change: add.length > 0,
                        },
                        remove_chat_ids: {
                            chat_ids: del,
                            take_change: del.length > 0,
                        },
                    });
                } else {
                    sendJsonMessage(message_edit);
                }
            } else if (folderName !== "") {
                sendJsonMessage(message_create);
            }
        }
    };

    useEffect(() => {
        if (lastJsonMessage && "meta_info" in lastJsonMessage) {
            if (lastJsonMessage.meta_info.action === "remove_folder") {
                if (lastJsonMessage.data.success === true) {
                    FullChatStore.SetFolderName("");
                    FullChatStore.setFolder();
                    FullChatStore.SetFolderChanged(false);
                    sendJsonMessage(message_update);
                } else console.log("error");
            } else if (lastJsonMessage.meta_info.action === "create_folder") {
                if (lastJsonMessage.data.success === true) {
                    FullChatStore.SetFolderName("");
                    FullChatStore.setFolder();
                    FullChatStore.SetFolderChanged(false);
                    sendJsonMessage(message_update);
                } else {
                    console.log("error");
                }
            }

            if (lastJsonMessage.meta_info.action === "edit_folder") {
                if (lastJsonMessage.data.success === true) {
                    FullChatStore.SetFolderName("");
                    FullChatStore.setFolder();
                    FullChatStore.SetFolderChanged(false);
                    sendJsonMessage(message_update);
                } else {
                    console.log("error");
                }
            }

            if (lastJsonMessage.meta_info.action === "get_data") {
                FullChatStore.SetTabsData(lastJsonMessage.data);
                FullChatStore.SetFolderProcessingStep(null);
            }
        }
    }, [lastJsonMessage]);

    useEffect(() => {
        SetFolderName(FullChatStore.newFolderName ? FullChatStore.newFolderName : props.folderChats ? props.folderChats.name : "");
        SetDisabledName(props.folderChats ? props.folderChats.name !== "" : false);
    }, [props.folderChats]);

    return (
        <Box sx={themes.CreateOrEditFolder}>
            <Box sx={themes.TeacherField}>
                <SButton
                    variant="icon"
                    onClick={() => {
                        FullChatStore.SetFolderProcessingStep(null);
                        FullChatStore.SetFolderName("");
                        FullChatStore.setFolder();
                    }}
                >
                    <IconBackMini />
                </SButton>
                <Typography sx={textThemes.Button16Black}>Новая папка</Typography>
                <SButton
                    variant="text"
                    disabled={!FullChatStore.FolderChanged}
                    onClick={changeFolder}
                >
                    Готово
                </SButton>
            </Box>
            <Box sx={themes.CreateEditContent}>
                <TextField
                    placeholder="Название папки"
                    value={folderName}
                    disabled={disabledName}
                    onChange={handleChangeName}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Folder />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment>
                                <IconButton
                                    onClick={handleOpen}
                                    disabled={!props.folderChats}
                                >
                                    <IconSettingsMini />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={themes.TextStyles}
                    margin="none"
                />
                <Chats folderChats={props.folderChats} />
                <Popper
                    anchorEl={anchorEl}
                    open={open}
                    sx={{ zIndex: "900" }}
                    placement="bottom-start"
                >
                    <Paper sx={themes.PaperStyles}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList sx={{ padding: "0.65vh 0.33vw 0.65vh 0.33vw" }}>
                                <Box>
                                    <MenuItem
                                        disableRipple
                                        sx={themes.MenuItemFirst}
                                        onClick={() => {
                                            handleDisabled();
                                            handleClose();
                                        }}
                                    >
                                        Переименовать
                                    </MenuItem>
                                    <MenuItem
                                        disableRipple
                                        sx={themes.MenuItem}
                                        onClick={() => {
                                            handleClose();
                                            sendJsonMessage(message_delete);
                                        }}
                                    >
                                        Удалить
                                    </MenuItem>
                                </Box>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            </Box>
        </Box>
    );
});

export default memo(CreateOrEditFolder);
