export const GridFieldChat = {
    width: "100%",
    border: "1px solid #EEEEF0",
    borderRadius: "20px",
    height: "80vh",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    overflow: "hidden",
};

export const MessagesContainer = (condition1, condition2) => {
    return {
        width: "100%",
        height: condition1 ? "calc(100% - 200px)" : condition2 ? "calc(100% - 125px)" : "calc(100% - (75px + 71px))",
        position: "relative",
    };
};

export const SearchMessagesContainer = {
    width: "100%",
    zIndex: "1000",
    maxWidth: "100%",
    position: "absolute",
};

export const Messages = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "safe end",
    height: "100%",
    width: "100%",
    paddingTop: "5px",
    overflowX: "scroll",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
        width: 0,
    },
    "&::-webkit-scrollbar-thumb": {
        borderRadius: 0,
    },
};

export const MessageGroup = {
    display: "flex",
    justifyContent: "center",
};

export const ReplyOrEditContainer = {
    borderTop: "1px solid #DADADF",
    paddingTop: "8px",
};

export const NoChatSelectedContainer = {
    textAlign: "center",
    marginTop: "35%",
};

export const TypingText = {
    color: "#9E9EB0",
    fontSize: "12px",
    fontStyle: "italic",
    fontWeight: "400",
    lineHeight: "140%",
};

export const TypeMessageContainer = {
    paddingLeft: "8px",
};

export const BlockedContainer = {
    textAlign: "center",
    paddingTop: "20px",
    borderTop: "1px solid #BBBBC8",
};

export const BottomChat = {
    margin: "0px 20px 0px 20px",
};

export const NoFound = {
    height: "40px",
    paddingTop: "16px",
    zIndex: "2",
    backgroundColor: "white",
    borderBottom: "solid 1px rgba(218, 218, 223, 1)",
    textAlign: "center",
};
