const PurplePrivilegeIcon = function (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
        >
            <path
                d="M13.4999 7C13.4999 10.5898 10.5898 13.5 6.99994 13.5C3.41009 13.5 0.499939 10.5898 0.499939 7C0.499939 3.41015 3.41009 0.5 6.99994 0.5C10.5898 0.5 13.4999 3.41015 13.4999 7Z"
                fill="#6212FF"
                stroke="white"
            />
            <path
                d="M4.06 6.82577L6.61652 9.02844L9.94 4.89844"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default PurplePrivilegeIcon;
