import React from "react";
import { observer } from "mobx-react-lite";

import ReactPlayer from "react-player";

import { Box, Typography } from "@mui/material";

import MenuShowImage from "../ImageChatAttachments/MenuShowImage/MenuShowImage";

import * as styles from "./VideoChatAttachmentsThemes";

import { useGetChatImageAttachment } from "hooks/useGetChatImageAttachment";

const SingleVideoAttachment = ({ link, duration, handleDownload }) => {
    const videoBlobLink = useGetChatImageAttachment(link.link);

    return (
        <Box sx={styles.imageBox}>
            <ReactPlayer
                controls={false}
                width="130px"
                height="130px"
                url={videoBlobLink}
            />
            {duration && <Typography sx={styles.durationBox}>{duration}</Typography>}
            <MenuShowImage
                handleDownload={handleDownload}
                link={link.link}
                type="mp4"
            />
        </Box>
    );
};

export default observer(SingleVideoAttachment);
