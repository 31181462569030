import * as themes from "./CitySelectThemes";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import * as textThemes from "themes/TextThemes";
import DropDownFilterIcon from "assets/CommonAssets/SVG/DropDownFilterIcon";
import * as React from "react";
import { memo, useState } from "react";
import { useMedias } from "API/mediasHook";
import CitySelectModal from "./CitySelectModal/CitySelectModal";
import { observer } from "mobx-react-lite";
import UserStore from "store/UserStore";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";

const CitySelect = observer(({ children, ...props }) => {
    const medias = useMedias();

    const [filter, setFilter] = useState("");

    const handleClickOpenCitySelect = () => {
        setFilter("");
        UserStore.setOpenCitySelect(true);
    };

    const handleCloseCitySelect = () => {
        UserStore.setOpenCitySelect(false);
    };

    const city =
        UserStore.role === "tutor"
            ? TeacherQuestionnaireStore.teacher?.city
                ? TeacherQuestionnaireStore.teacher.city.title
                : "Москва"
            : UserStore.UserInfo?.city?.title
              ? UserStore.UserInfo?.city?.title
              : "Москва";

    const [currCity, setCurrCity] = React.useState("Москва");

    return (
        <Box sx={themes.CityLangCurrChoice(medias)}>
            <Button
                disableRipple
                sx={themes.CityButton}
                onClick={handleClickOpenCitySelect}
            >
                <Typography sx={textThemes.Body2Black}>{city}</Typography>
                <DropDownFilterIcon />
            </Button>
            <CitySelectModal
                filter={filter}
                setFilter={setFilter}
                setCurrCity={setCurrCity}
                onClose={handleCloseCitySelect}
            />
        </Box>
    );
});

export default memo(CitySelect);
