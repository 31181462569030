import React from "react";

const MicroOnIconWB = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.84892 5.05399C4.84892 3.33073 6.28428 1.96094 8.02338 1.96094C9.76248 1.96094 11.1978 3.33073 11.1978 5.05399V8.61945C11.1978 10.3427 9.76248 11.7125 8.02338 11.7125C6.28428 11.7125 4.84892 10.3427 4.84892 8.61945V5.05399ZM8.02338 2.96094C6.8072 2.96094 5.84892 3.91193 5.84892 5.05399V8.61945C5.84892 9.76151 6.8072 10.7125 8.02338 10.7125C9.23956 10.7125 10.1978 9.76151 10.1978 8.61945V5.05399C10.1978 3.91193 9.23956 2.96094 8.02338 2.96094ZM2.90869 6.91927C3.18483 6.91927 3.40869 7.14313 3.40869 7.41927V8.52132C3.40869 10.9664 5.4635 12.9805 8.0236 12.9805C10.5837 12.9805 12.6385 10.9664 12.6385 8.52132V7.41927C12.6385 7.14313 12.8624 6.91927 13.1385 6.91927C13.4147 6.91927 13.6385 7.14313 13.6385 7.41927V8.52132C13.6385 11.3404 11.4414 13.6447 8.64839 13.9469V15.612C8.64839 15.9572 8.36851 16.2371 8.02327 16.2371C7.67803 16.2371 7.39816 15.9572 7.39816 15.612V13.9469C4.60545 13.6443 2.40869 11.3402 2.40869 8.52132V7.41927C2.40869 7.14313 2.63255 6.91927 2.90869 6.91927Z"
                fill="white"
            />
        </svg>
    );
};

export default MicroOnIconWB;
