import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./OrdersRegionThemes";
import { Accordion, AccordionDetails, AccordionSummary, Chip, IconButton, Typography } from "@mui/material";
import OrdersSearchStore from "store/OrdersSearchStore";
import { CloseRounded, ExpandMore } from "@mui/icons-material";
import { ThemeProvider } from "@mui/material/styles";
import SingleChoiceLightFilter from "components/UI/SingleChoiceLightFilter";

const OrdersRegion = observer(({ children, ...props }) => {
    const handleChange = () => {
        OrdersSearchStore.ToggleOrdersRegionExpanded();
    };

    const processingOrdersRegion = (event, value) => {
        OrdersSearchStore.SetOrdersRegion(value);
    };

    return (
        <ThemeProvider theme={themes.OrdersRegionAccordionTheme}>
            <Accordion
                expanded={OrdersSearchStore.ordersRegionExpanded}
                onChange={handleChange}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography sx={themes.headerOrdersRegionStyle}>Регион</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <SingleChoiceLightFilter
                        propOption={OrdersSearchStore.optionOrdersRegion}
                        propOptions={OrdersSearchStore.optionsOrdersRegion}
                        valueToShow={"OrdersRegion"}
                        valueToBind={"ID"}
                        processingChange={processingOrdersRegion}
                        themeWrapper={themes.FiltersOrdersRegionTheme}
                        placeholder={"Выберите регион"}
                        clearOnBlur={false}
                        disableClearable={true}
                        variant={"standard"}
                        freeSolo={false}
                    ></SingleChoiceLightFilter>
                </AccordionDetails>
            </Accordion>
        </ThemeProvider>
    );
});

export default memo(OrdersRegion);
