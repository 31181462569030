import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import * as themes from "components/SettingsBodyBlock/TutorSettings/MainForm/MainFormThemes";
import SButton from "components/UI/SButton";
import SChipsGroup from "components/UI/ChipGroup/ChipGroup";
import { districts } from "API/dictionaries/const_data";
import { observer } from "mobx-react-lite";
import IOSSwitch from "components/UI/IOSSwitch";
import FormControlLabel from "@mui/material/FormControlLabel";
import CatalogStore from "store/CatalogStore";
import * as Classes from "components/CatalogBodyBlock/FiltersDrawer/LessonsPlaceThemes";
import SingleChoiceLightFilter from "components/UI/SingleChoiceLightFilter";
import "components/TeacherQuestionnaireBodyBlock/Steps/TeacherSteps.css";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import IconBackRounded from "assets/CommonAssets/SVG/IconBackRounded";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import OrangeExclamationIcon from "assets/CommonAssets/SVG/OrangeExclamationIcon";
import { useMedias } from "API/mediasHook";

const PlacesEdit = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const handleBackClick = () => {
        props.handleSet(0);
        TeacherQuestionnaireStore.setWholeTemporaryPlaces(JSON.parse(JSON.stringify(TeacherQuestionnaireStore.teacher.places)));
    };

    const handleSaveClick = () => {
        TeacherQuestionnaireStore.setTutorData("places", JSON.parse(JSON.stringify(TeacherQuestionnaireStore.temporaryPlaces)));
    };

    const handleCancelClick = () => {
        TeacherQuestionnaireStore.setWholeTemporaryPlaces(JSON.parse(JSON.stringify(TeacherQuestionnaireStore.teacher.places)));
    };

    return (
        <ScrollToTop>
            <Box sx={themes.Top(medias)}>
                <Box sx={themes.EditTitle(medias)}>
                    <SButton
                        variant="icon"
                        onClick={handleBackClick}
                    >
                        <IconBackRounded />
                    </SButton>
                    <Typography sx={medias.sm ? textThemes.HeadlineH41Black : textThemes.HeadlineH5Black}>{t("teacherSettings.questionnaire.classesPlace")}</Typography>
                </Box>
                <Box sx={themes.TitleAndEdit}>
                    <Box sx={themes.placesSwitchText}>
                        <Typography sx={textThemes.Button16Black}>{t("teacherSettings.questionnaire.online")}</Typography>
                        <Typography sx={textThemes.Body2DarkGrey}>{t("teacherSettings.questionnaire.onlineClasses")}</Typography>
                    </Box>
                    <FormControlLabel
                        sx={themes.Switcher}
                        control={
                            <IOSSwitch
                                sx={{ m: 1 }}
                                onChange={() => {
                                    TeacherQuestionnaireStore.setTemporaryPlaces("online", !TeacherQuestionnaireStore.temporaryPlaces.online);
                                }}
                                checked={TeacherQuestionnaireStore.temporaryPlaces.online}
                            />
                        }
                    />
                </Box>
                <Box sx={themes.verticalBlock}>
                    <Box sx={themes.TitleAndEdit}>
                        <Box sx={themes.placesSwitchText}>
                            <Typography sx={textThemes.Button16Black}>{t("teacherSettings.questionnaire.atHome")}</Typography>
                            <Typography sx={textThemes.Body2DarkGrey}>{t("teacherSettings.questionnaire.selectAddress")}</Typography>
                        </Box>
                        <FormControlLabel
                            sx={themes.Switcher}
                            control={
                                <IOSSwitch
                                    sx={{ m: 1 }}
                                    onChange={() => {
                                        TeacherQuestionnaireStore.setTemporaryPlaces("teacher", !TeacherQuestionnaireStore.temporaryPlaces.teacher);
                                    }}
                                    checked={TeacherQuestionnaireStore.temporaryPlaces.teacher}
                                />
                            }
                        />
                    </Box>
                    <Box>
                        {TeacherQuestionnaireStore.temporaryPlaces.teacher ? (
                            <Box sx={themes.enterStudentPlaceBox}>
                                <SingleChoiceLightFilter
                                    propOption={TeacherQuestionnaireStore.temporaryPlaces.teacherPlace}
                                    propOptions={CatalogStore.optionsLessonsPlace}
                                    valueToShow={"LessonsPlace"}
                                    valueToBind={"ID"}
                                    processingChange={(event, value) => TeacherQuestionnaireStore.setTemporaryPlaces("teacherPlace", value)}
                                    themeWrapper={Classes.FiltersLessonsPlaceTheme}
                                    placeholder={t("teacherSettings.questionnaire.address")}
                                    clearOnBlur={false}
                                    disableClearable={false}
                                    variant={"standard"}
                                    freeSolo={true}
                                />
                                <Box sx={themes.ButtonsBlock}>
                                    <Box>
                                        <OrangeExclamationIcon />
                                    </Box>
                                    <Typography sx={textThemes.Caption1Black}>{t("teacherSettings.questionnaire.nobodyWillSee")}</Typography>
                                </Box>
                            </Box>
                        ) : null}
                    </Box>
                </Box>
                <Box>
                    <Box sx={themes.TitleAndEdit}>
                        <Box sx={themes.placesSwitchText}>
                            <Typography sx={textThemes.Button16Black}>{t("teacherSettings.questionnaire.atStudentsBig")}</Typography>
                            <Typography sx={textThemes.Body2DarkGrey}>{t("teacherSettings.questionnaire.selectDistricts")}</Typography>
                        </Box>
                        <FormControlLabel
                            sx={themes.Switcher}
                            control={
                                <IOSSwitch
                                    sx={{ m: 1 }}
                                    onChange={() => {
                                        TeacherQuestionnaireStore.setTemporaryPlaces("student", !TeacherQuestionnaireStore.temporaryPlaces.student);
                                    }}
                                    checked={TeacherQuestionnaireStore.temporaryPlaces.student}
                                />
                            }
                        />
                    </Box>
                    <Box>
                        {TeacherQuestionnaireStore.temporaryPlaces.student ? (
                            <Box sx={themes.DistrictBox(TeacherQuestionnaireStore.temporaryPlaces.studentPlaces.length > 0)}>
                                <SChipsGroup
                                    width="100%"
                                    chipData={TeacherQuestionnaireStore.temporaryPlaces.studentPlaces}
                                    setChipData={(value) => TeacherQuestionnaireStore.setStudentPlaces(value)}
                                    list={districts}
                                    placeholder={t("teacherSettings.questionnaire.district")}
                                />
                            </Box>
                        ) : null}
                    </Box>
                </Box>
                <Box sx={themes.ButtonsBlock}>
                    <SButton
                        onClick={handleSaveClick}
                        disabled={TeacherQuestionnaireStore.getDisabledSavePlaces()}
                        variant="unshadowed"
                        padding="10px 20px 11px 20px"
                    >
                        {t("main.save")}
                    </SButton>
                    <SButton
                        variant="secondary"
                        padding="10px 20px 11px 20px"
                        disabled={TeacherQuestionnaireStore.getDisabledSavePlaces()}
                        onClick={handleCancelClick}
                    >
                        {t("main.cancel")}
                    </SButton>
                </Box>
            </Box>
        </ScrollToTop>
    );
});

export default memo(PlacesEdit);
