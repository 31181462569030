import { styleProcessing } from "API/plugins/Utilities";

export const TeacherAccount = (theme) => {
    let commonStyle = {
        borderRadius: "0px 0px 40px 40px",
        background: "#F7F7F7",
        paddingTop: "40px",
        display: "flex",
        justifyContent: "center",
        minHeight: "686px",
        paddingBottom: "58px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "20px 16px 40px 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherAccountContent = (theme) => {
    let commonStyle = {
        maxWidth: "1218px",
        width: "100%",
        display: "flex",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            justifyContent: "center",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const RightPanel = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const MiniWidgetBox = {
    display: "flex",
    gap: "20px",
};

export const LeftPanel = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "0px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const OrdersBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const SwiperSlide = {
    maxWidth: "fit-content",
};
