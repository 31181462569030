const MastercardPaymentIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="12"
            viewBox="0 0 20 12"
            fill="none"
        >
            <g clipPath="url(#clip0_1919_64504)">
                <path
                    d="M12.6873 1.28125H7.2793V10.7143H12.6873V1.28125Z"
                    fill="#FF5A00"
                />
                <path
                    d="M7.64 6C7.64 4.0835 8.568 2.38252 9.992 1.2835C8.944 0.483495 7.622 0 6.18 0C2.764 0 0 2.6835 0 6C0 9.31651 2.764 12 6.18 12C7.622 12 8.944 11.5165 9.992 10.7165C8.566 9.63301 7.64 7.9165 7.64 6Z"
                    fill="#EB001B"
                />
                <path
                    d="M19.9842 6C19.9842 9.31651 17.2202 12 13.8042 12C12.3622 12 11.0402 11.5165 9.99219 10.7165C11.4342 9.61553 12.3442 7.9165 12.3442 6C12.3442 4.0835 11.4162 2.38252 9.99219 1.2835C11.0382 0.483495 12.3602 0 13.8022 0C17.2202 0 19.9842 2.70097 19.9842 6Z"
                    fill="#F79E1B"
                />
            </g>
            <defs>
                <clipPath id="clip0_1919_64504">
                    <rect
                        width="20"
                        height="12"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default MastercardPaymentIcon;
