import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import StateOne from "./StateOne/StateOne";
import StateTwo from "./StateTwo/StateTwo";
import StateThree from "./StateThree/StateThree";
import MainPageStore from "store/MainPageStore";
import * as theme from "./RightCardBlockStyle";
import { Box } from "@mui/system";
import { useMedias } from "API/mediasHook";

const RightCardBlock = observer(({ children, ...props }) => {
    const medias = useMedias();

    return <Box sx={theme.RightBlock(medias)}>{MainPageStore.activeHowWorkItem === 0 ? <StateOne /> : MainPageStore.activeHowWorkItem === 1 ? <StateTwo /> : <StateThree />}</Box>;
});

export default memo(RightCardBlock);
