import React from "react";

const IconBtnSend = () => {
    return (
        <svg
            width="35"
            height="35"
            viewBox="0 0 40 40"
            fill="none"
        >
            <circle
                cx="20"
                cy="20"
                r="19"
                fill="#6212FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.0747 20L12.1038 12.8228C11.6434 11.7105 12.7998 10.6168 13.8933 11.1305L30.1221 18.754C30.3382 18.8268 30.5184 18.9514 30.6582 19.108C30.8951 19.3591 31.0036 19.6805 30.9999 20C31.0036 20.3195 30.8951 20.6409 30.6581 20.892C30.5184 21.0486 30.3382 21.1732 30.1221 21.246L13.8933 28.8695C12.7998 29.3832 11.6434 28.2895 12.1038 27.1772L15.0747 20ZM13.7678 27.1927L13.749 27.2381L13.7524 27.2365L13.7678 27.1927Z"
                fill="white"
            />
        </svg>
    );
};

export default IconBtnSend;
