import React, { forwardRef } from "react";
import { TextField } from "@mui/material";

const ChatTextField = forwardRef(({ onClickFunc, onChangeFunc, messageText, onKeyDownFunc }, ref) => {
    return (
        <TextField
            placeholder="Сообщение..."
            multiline
            maxRows={6}
            fullWidth
            sx={{
                "& fieldset": { border: "none" },
                "& .MuiOutlinedInput-root": { padding: "0px" },
            }}
            margin="none"
            onChange={(e) => onChangeFunc(e.target.value)}
            onClick={onClickFunc}
            value={messageText}
            onKeyDown={onKeyDownFunc}
            ref={ref}
        />
    );
});

export default ChatTextField;
