import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import CatalogStore from "store/CatalogStore";
import { ThemeProvider } from "@mui/material/styles";
import * as themes from "./LessonsDayTimeThemes";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import FreeMultipleChoiceFilter from "components/UI/FreeMultipleChoiceFilter";
import { useMedias } from "../../../API/mediasHook";

const LessonsDayTime = observer(({ children, ...props }) => {
    const handleChange = () => {
        CatalogStore.ToggleLessonsDayTimeExpanded();
    };

    const processingLessonsDayTime = (event, value) => {
        CatalogStore.SetLessonsDayTime(value);
    };

    const toggleDays = (value, valueAddition) => {
        CatalogStore.SetDays(value, valueAddition);
    };

    const medias = useMedias();

    return (
        <ThemeProvider theme={themes.LessonsDayTimeAccordionTheme(medias)}>
            <Accordion
                expanded={CatalogStore.lessonsDayTimeExpanded}
                onChange={handleChange}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography sx={themes.headerLessonsDayTimeStyle}>Время занятий</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FreeMultipleChoiceFilter
                        propOptions={CatalogStore.optionsLessonsDayTime}
                        valueToShow={"LessonDayTime"}
                        valueToBind={"Checked"}
                        valueForKey={"ID"}
                        useAddition={true}
                        toggleFunc={toggleDays}
                        propOptionsAddition={"Days"}
                        valueToShowAddition={"Day"}
                        valueToBindAddition={"Checked"}
                        valueForKeyAddition={"ID"}
                        processingChange={processingLessonsDayTime}
                        themeWrapper={themes.FiltersLessonsDayTimeTheme}
                        themeOptionsAdditionContainer={themes.optionsAdditionContainer}
                        themeOptionAddition={themes.OptionAdditionTheme}
                        themeOptionsAdditionText={themes.optionsAdditionText}
                    ></FreeMultipleChoiceFilter>
                </AccordionDetails>
            </Accordion>
        </ThemeProvider>
    );
});

export default memo(LessonsDayTime);
