import React from "react";

const Illustration = () => {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_4838_103836)">
                <path
                    d="M49.3984 18V44.3372C49.3984 45.688 48.8186 46.9044 47.8925 47.7561C47.0524 48.5281 45.9284 49 44.6935 49H5.10249C3.8675 49 2.74362 48.5281 1.90469 47.7569C0.978273 46.9044 0.398438 45.688 0.398438 44.3372V18H49.3984Z"
                    fill="#6212FF"
                />
                <path
                    d="M49.0665 19.0219C49.5516 18.6258 49.5575 17.8865 49.0788 17.4828L30.7813 2.05103C27.4316 -0.683678 22.5684 -0.683678 19.2187 2.05103L0.921288 17.4827C0.442581 17.8865 0.448515 18.6258 0.933641 19.0218L5.66471 22.8837L19.2187 33.9484C22.5688 36.6839 27.432 36.6839 30.7809 33.9484L44.3362 22.8837L49.0665 19.0219Z"
                    fill="#4D09D3"
                />
                <path
                    d="M44 8.61173V23.4757L30.6801 34.8846C27.3893 37.7051 22.6106 37.7051 19.3187 34.8846L6 23.4757V8.61173C6 5.51272 8.44248 3 11.4554 3H38.5454C41.5583 3 43.9999 5.51272 43.9999 8.61173H44Z"
                    fill="#E3E3E3"
                />
                <path
                    d="M44 8.68588V20.2967L30.6801 31.857C27.3893 34.7143 22.6106 34.7143 19.3187 31.857L6 20.2967V8.68588C6 5.54592 8.44248 3 11.4554 3H38.5454C41.5583 3 43.9999 5.54592 43.9999 8.68588H44Z"
                    fill="#F5F5F7"
                />
                <path
                    d="M35.7711 22.0859H14.2229C14.1264 22.0859 14.0309 22.0669 13.9418 22.03C13.8526 21.9931 13.7716 21.939 13.7034 21.8708C13.6352 21.8025 13.5811 21.7215 13.5442 21.6324C13.5073 21.5433 13.4883 21.4477 13.4883 21.3513C13.4883 21.1564 13.5657 20.9696 13.7035 20.8319C13.8413 20.6941 14.0281 20.6167 14.2229 20.6166H35.7711C35.9659 20.6167 36.1527 20.6941 36.2905 20.8319C36.4282 20.9696 36.5056 21.1564 36.5057 21.3513C36.5057 21.4477 36.4867 21.5433 36.4498 21.6324C36.4129 21.7215 36.3588 21.8025 36.2905 21.8708C36.2223 21.939 36.1413 21.9931 36.0522 22.03C35.9631 22.0669 35.8675 22.0859 35.7711 22.0859ZM35.7711 26.3526H14.2229C13.8172 26.3526 13.4883 26.0238 13.4883 25.618C13.4883 25.2123 13.8172 24.8834 14.2229 24.8834H35.7711C36.1769 24.8834 36.5057 25.2123 36.5057 25.618C36.5057 26.0238 36.1769 26.3526 35.7711 26.3526ZM35.7711 13.5532H14.2229C13.8172 13.5532 13.4883 13.2244 13.4883 12.8186C13.4883 12.4129 13.8172 12.084 14.2229 12.084H35.7711C36.1769 12.084 36.5057 12.4129 36.5057 12.8186C36.5057 13.2244 36.1769 13.5532 35.7711 13.5532ZM35.7711 17.8196H14.2229C13.8172 17.8196 13.4883 17.4907 13.4883 17.085C13.4883 16.6792 13.8172 16.3504 14.2229 16.3504H35.7711C36.1769 16.3504 36.5057 16.6791 36.5057 17.085C36.5057 17.4908 36.1769 17.8196 35.7711 17.8196Z"
                    fill="#6212FF"
                />
                <path
                    d="M48.0145 11.2845C48.0145 16.9376 43.4317 21.5205 37.7789 21.5205C32.1258 21.5205 27.543 16.9377 27.543 11.2845C27.543 5.63161 32.1258 1.04883 37.7789 1.04883C43.4317 1.04883 48.0145 5.63161 48.0145 11.2845Z"
                    fill="#19B20C"
                />
                <path
                    d="M37.4942 15.7537C37.3871 15.7536 37.2812 15.7301 37.1841 15.6848C37.087 15.6395 37.001 15.5735 36.9321 15.4915L32.6719 11.4184C32.4113 11.1074 32.4515 10.6442 32.762 10.3834C32.8359 10.3213 32.9212 10.2744 33.0132 10.2454C33.1052 10.2163 33.2021 10.2057 33.2982 10.2141C33.3943 10.2224 33.4878 10.2497 33.5734 10.2942C33.659 10.3387 33.735 10.3997 33.797 10.4736L37.2895 13.6326L42.227 7.44512C42.265 7.35644 42.3201 7.27612 42.3891 7.20875C42.4582 7.14138 42.5398 7.08827 42.6294 7.05246C42.719 7.01665 42.8148 6.99885 42.9112 7.00006C43.0077 7.00127 43.103 7.02148 43.1917 7.05952C43.2803 7.09751 43.3607 7.1526 43.428 7.22164C43.4954 7.29069 43.5485 7.37233 43.5843 7.46192C43.6201 7.5515 43.638 7.64726 43.6367 7.74372C43.6355 7.84019 43.6153 7.93547 43.5772 8.02412L38.1695 15.3084C38.1202 15.4238 38.0421 15.5246 37.9427 15.6011C37.8434 15.6777 37.726 15.7274 37.6018 15.7456C37.5662 15.7511 37.5302 15.7538 37.4942 15.7537Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_4838_103836">
                    <rect
                        width="50"
                        height="50"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Illustration;
