import { createTheme } from "@mui/material/styles";

export const Paper = {
    borderRadius: "20px",
    boxShadow: "0px 4px 40px 0px rgba(112, 108, 121, 0.30)",
    border: "none",
};

export const TitleBlock = {
    padding: "20px 20px 10px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const ReadAllButton = {
    ":hover": {
        bgcolor: "#fff",
        color: "#6212ff",
    },
    padding: 0,
    color: "#222",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    textTransform: "none",
};

export const AllNotificationsButton = (mode) => {
    return {
        ":hover": {
            background: "var(--bg-grey, #F7F7F7)",
            color: "#6212ff",
        },
        borderTop: "1px solid var(--grey-light-grey, #BBBBC8)",
        borderRadius: "0px 0px 20px 20px",
        background: "var(--bg-grey, #F7F7F7)",
        pt: "14px",
        pb: "15px",
        marginTop: mode ? "10px" : 0,
        width: "100%",
        color: "#222",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "150%",
        textTransform: "none",
    };
};

export const PopoverStyle = {
    maxHeight: "600px",
    overflowY: "auto",
    //border:'none',
    boxShadow: "none",
    "&::-webkit-scrollbar": {
        width: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        background: "rgba(187, 187, 200, 0.50)",
        height: "60px",
    },
};

export const progressContainerStyle = {
    width: "100%",
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
};

export const CircularProgressTheme = createTheme({
    components: {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: "#6212FF",
                },
            },
        },
    },
});
