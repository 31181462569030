import { makeAutoObservable } from "mobx";
import RequestService from "API/services/RequestService";

const requestService = new RequestService();

class OrderStore {
    orderArr = [];
    actualReview = {};

    timerID = null;

    response = {
        proposedPrice: "",
        totalPrice: 0,
        responseMessage: "",
    };

    multiplier = 0.85;

    negotiatedPrice = false;
    comissionPercent = 15;

    reviewsTemplates = [];

    currentTemplate = {};

    dialogResponseMode = 0;

    ordersArrRecommended = [];

    optionsReports = [];
    optionReports = null;

    reportMessage = "";

    constructor() {
        this.Init();
        makeAutoObservable(this);
    }

    debounce(callback, delay) {
        return (...args) => {
            if (this.timerID) {
                clearTimeout(this.timerID);
            }

            this.timerID = setTimeout(() => {
                callback.call(this, ...args);
            }, delay);
        };
    }

    PrefillResponse() {
        this.response.proposedPrice = this.actualReview?.proposedPrice || "";
        this.response.totalPrice = this.actualReview?.totalPrice || 0;
        this.response.responseMessage = this.actualReview?.responseMessage || "";
    }

    ClearModalResponse() {
        this.response.proposedPrice = "";
        this.response.totalPrice = 0;
        this.response.responseMessage = "";
        this.negotiatedPrice = false;
    }

    SetProposedPrice(value) {
        this.response.proposedPrice = value;
    }

    SetTotalPrice() {
        if (isNaN(parseFloat(this.response.proposedPrice.replaceAll(" ", "").replaceAll(",", ".")))) {
            this.response.proposedPrice = "";
            this.response.totalPrice = 0;
        } else {
            this.response.totalPrice = this.multiplier * parseFloat(this.response.proposedPrice.replaceAll(" ", "").replaceAll(",", "."));
        }
    }

    SetNegotiatedPrice(value) {
        this.negotiatedPrice = value;
        if (value === true) {
            this.response.proposedPrice = "";
            this.response.totalPrice = 0;
        }
    }

    SetResponseMessage(value) {
        this.response.responseMessage = value;
    }

    SetCurrentTemplate(value) {
        console.log(value);
        if (value) {
            this.currentTemplate = { ...value };
        } else {
            this.currentTemplate = {
                ID: this.reviewsTemplates.length,
                TemplateTitle: "",
                TemplateText: "",
            };
        }
    }

    AddFromTemplate(value) {
        this.response.responseMessage = value.TemplateText;
    }

    SetTemplateTitle(value) {
        this.currentTemplate = { ...this.currentTemplate, TemplateTitle: value };
    }

    SetTemplateText(value) {
        this.currentTemplate = { ...this.currentTemplate, TemplateText: value };
    }

    AddNewTemplate() {
        if (this.reviewsTemplates.filter((element) => element.ID === this.currentTemplate.ID).length) {
            this.reviewsTemplates = this.reviewsTemplates.map((element) => (element.ID === this.currentTemplate.ID ? this.currentTemplate : element));
        } else {
            this.reviewsTemplates = [...this.reviewsTemplates, this.currentTemplate];
        }
    }

    SetActualReview(mode) {
        if (mode === 0) {
            if (this.dialogResponseMode === 0) {
                this.actualReview = {
                    proposedPrice: this.response.proposedPrice,
                    totalPrice: this.response.totalPrice,
                    negotiatedPrice: this.negotiatedPrice,
                    responseMessage: this.response.responseMessage,
                };
            } else {
                this.actualReview = {
                    ...this.actualReview,
                    proposedPrice: this.response.proposedPrice,
                    totalPrice: this.response.totalPrice,
                    negotiatedPrice: false,
                };
            }
        } else {
            this.actualReview = {};
        }
    }

    SetDialogResponseMode(mode) {
        this.dialogResponseMode = mode;
    }

    SetReports(value) {
        console.log(this.optionsReports.find((x) => x.ID === parseInt(value)));
        this.optionReports = this.optionsReports.find((x) => x.ID === parseInt(value));
    }

    SetReportMessage(value) {
        this.reportMessage = value;
    }

    SubmitReport() {
        console.log(this.optionReports, this.reportMessage);
    }

    ClearModalReport() {
        this.reportMessage = "";
    }

    Init() {
        this.Load();
    }

    Load() {
        this.orderArr = [
            {
                ID: 0,
                _id: "64902a483594e963dd20818a",
                OrderType: 0,
                OrderTitle: "Репетитор по английскому языку",
                AvatarSrc: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
                IsVerified: true,
                RegistrationDate: new Date(2022, 12, 19),
                Price: {
                    PriceFrom: 9000,
                    PriceTo: 9900,
                },
                SeenByTeacher: true,
                ReviewsCount: 7,
                ViewedCount: 120,
                Discipline: "Информационная безопасность",
                Theme: "Программный модуль автоматизации проверки безопасности приложений iOS",
                Audience: "Студент",
                Course: "2 курс",
                Goal: "Подготовка к экзамену в ВУЗе",
                Edu: "МГУ",
                Deadline: "Несколько месяцев",
                Details: "50 страниц, 20 источников",
                AdditionalInfo: "Оригинальность 80%, проверка по antiplagiat.ru",
                AdditionalService: "Рецензия, выступление к защите",
                Location: {
                    Type: "У репетитора",
                    Stations: [
                        {
                            ID: 0,
                            Location: "Комсомольская",
                        },
                        {
                            ID: 1,
                            Location: "Красные ворота",
                        },
                        {
                            ID: 2,
                            Location: "Красносельская",
                        },
                    ],
                },
                LessonsPerWeek: "2–3 занятия",
                ScheduleContent: "вт, чт 12:00–18:00; сб 18:00–24:00",
                Comment:
                    "«Ищу репетитора для дочки 16 лет, задача - ЗНО и получить сертификат минимум В2 IELTS для поступления в Университет Варшавы - SWPS. Ищу репетитора для дочки 16 лет, задача - ЗНО и получить сертификат минимум В2 IELTS для поступления.»",
                FileAttachments: [
                    {
                        ID: 0,
                        FileName: "Курсовая по английскому. Требования.doc",
                        FileSize: "3.2MB",
                        FileSource: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
                    },
                    {
                        ID: 1,
                        FileName: "Требования.png",
                        FileSize: "0.2MB",
                        FileSource: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
                    },
                ],
                OrderDate: new Date(2022, 12, 19, 12, 57),
                OrderNumber: "555677453",
                StudentFIO: "Анна А.",
                LastSeen: "54 мин. назад",
            },
        ];

        this.reviewsTemplates = [
            {
                ID: 0,
                TemplateTitle: "Отклик для занятий",
                TemplateText:
                    "Здравствуйте! \n\n" +
                    "Первая встреча: Тестирование, разработка плана обучения, выбор курса.\n\n" +
                    "Диплом преподавателя английского языка, международная квалификация CELTA pass B, CAE, TKTs. Эксперт ЕГЭ, сертификация сентябрь 2022 год. IELTS Assessment Specialist, сертификация 05.11.2022.\n\n" +
                    "Большой опыт работы индивидуально и с группой, проницательность, понимание личных способностей ученика и умение мотивировать и организовать.\n\n" +
                    "Результаты моих учеников: IELTS — 7, 7.5, 8 ЕГЭ — 87, 92, 95 баллов",
            },
            {
                ID: 1,
                TemplateTitle: "Отклик для дипломов",
                TemplateText: "Че смотришь, а?  \n\nа?  \n\nа?  \n\nа?  \n\nа?  \n\nа?  \n\nа?  \n\nа?  \n\nа?  \n\nа?  \n\nа?  \n\nа?  \n\nа?  \n\nа?  \n\nа?",
            },
        ];

        this.ordersArrRecommended = [
            {
                ID: 0,
                _id: "64902a483594e963dd20818a",
                OrderType: 0,
                OrderTitle: "Репетитор по английскому языку",
                Tags: [
                    {
                        ID: 0,
                        Query: "Дипломная работа",
                    },
                    {
                        ID: 1,
                        Query: "Английский язык",
                    },
                ],
                Theme: "Лексический подход при обучении аудированию с целью улучшения навыков коммуникацииЛексический подход при обучении аудированию с целью улучшения навыков коммуникацииЛексический подход при обучении аудированию с целью улучшения навыков коммуникацииЛексический подход при обучении аудированию с целью улучшения навыков коммуникации",
                Deadline: "20 мая",
                Price: {
                    PriceFrom: 8400,
                    PriceTo: 9900,
                },
                OrderDate: "вчера",
            },
            {
                ID: 1,
                _id: "64902a483594e963dd20818a",
                OrderType: 0,
                OrderTitle: "Репетитор по английскому языку",
                Tags: [
                    {
                        ID: 0,
                        Query: "Дипломная работа",
                    },
                    {
                        ID: 1,
                        Query: "Английский язык",
                    },
                ],
                Theme: "Лексический подход при обучении аудированию с целью улучшения навыков коммуникации",
                Deadline: "20 мая",
                Price: {
                    PriceFrom: 8400,
                    PriceTo: 9900,
                },
                OrderDate: "1 ч назад",
            },
            {
                ID: 2,
                _id: "64902a483594e963dd20818a",
                OrderType: 0,
                OrderTitle: "Репетитор по английскому языку",
                Tags: [
                    {
                        ID: 0,
                        Query: "Дипломная работа",
                    },
                    {
                        ID: 1,
                        Query: "Английский язык",
                    },
                ],
                Theme: "Лексический подход при обучении аудированию с целью улучшения навыков коммуникации",
                Deadline: "20 мая",
                Price: {
                    PriceFrom: 8400,
                    PriceTo: 9900,
                },
                OrderDate: new Date(2022, 12, 19, 12, 57),
            },
            {
                ID: 3,
                _id: "64902a483594e963dd20818a",
                OrderType: 0,
                OrderTitle: "Репетитор по английскому языку",
                Tags: [
                    {
                        ID: 0,
                        Query: "Дипломная работа",
                    },
                    {
                        ID: 1,
                        Query: "Английский язык",
                    },
                ],
                Theme: "Лексический подход при обучении аудированию с целью улучшения навыков коммуникации",
                Deadline: "20 мая",
                Price: {
                    PriceFrom: 8400,
                    PriceTo: 9900,
                },
                OrderDate: new Date(2022, 12, 19, 12, 57),
            },
        ];

        this.optionsReports = [
            { ID: 0, Reports: "Мошенничество или обмен контактными данными" },
            { ID: 1, Reports: "Неуместное или оскорбительное поведение" },
            { ID: 2, Reports: "Спам" },
            { ID: 3, Reports: "Другое" },
        ];
        this.optionReports = this.optionsReports.at(-1);
    }
}

export default new OrderStore();
