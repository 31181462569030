import { Drawer } from "@mui/material";
import React from "react";
import * as theme from "./MyClassesDrawerThemes";
function MyClassesDrawer({ open, close, children }) {
    return (
        <Drawer
            open={open}
            onClose={close}
            sx={theme.DrawerStyle}
        >
            {children}
        </Drawer>
    );
}

export default MyClassesDrawer;
