import React, { memo } from "react";
import AnswerCard from "components/UI/AnswerCard/AnswerCard";

const AnswerBlock = ({ children, ...props }) => {
    return props.value.Answers.map((answer) => (
        <AnswerCard
            key={answer.ID}
            answer={answer}
            tutor={props.tutor}
        ></AnswerCard>
    ));
};

export default memo(AnswerBlock);
