import { observer } from "mobx-react-lite";
import React from "react";
import orderResponseStore from "store/OrderResponseStore";
import OrderCardWrapper from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponsCard/OrderCardWrapper";
import { Box, Divider } from "@mui/material";
import OrderResponseHiddenAvatar from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_OrderResponseHiddenAvatar/OrderResponseHiddenAvatar";
import OrderResponseCircleAvatar from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseCircleAvatar/OrderResponseCircleAvatar";
import OrderResponeAvatarName from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponeAvatarName/OrderResponeAvatarName";
import OnlineStatus from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_OnlineStatus/OnlineStatus";
import OrderResponseRating from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_OrderResponseRating/OrderResponseRating";
import OrderResponseRaitingCount from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_OrderResponseRaitingCount/OrderResponseRaitingCount";
import { NoReviews } from "components/OrderResponse/OrderResponseTutorsWrapper/ResponseCard/ResponseCardStyled";
import OrderResponeDescription from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponeDescription/OrderResponeDescription";
import OrderResponseTutorsInfo from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_OrderResponseTutorsInfo/OrderResponseTutorsInfo";
import OrderResponseComplainDismissButton from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseComplainDismissButton/OrderResponseComplainDismissButton";
import OrderResponseTime from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseTime/OrderResponseTime";
import OrderResponsePrice from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponsePrice/OrderResponsePrice";
import OrderResponseCashback from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseCashback/OrderResponseCashback";
import OrderResponseButton from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseButton/OrderResponseButton";
import TutorRejectText from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_TutorRejectText/TutorRejectText";
import OrderSnack from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/OrdersSnack/OrderSnack";
import * as themes from "./OffersCardsStyled";
const OffersCards = observer(({ ...props }) => {
    const handleСhoose = () => {
        console.log("Choose");
    };

    const handleRequestPrice = () => {
        console.log("Price");
    };

    const handleOpenDismissModal = (id) => {
        orderResponseStore.setIsId(id);
        orderResponseStore.setToggleModal();
        orderResponseStore.setIsDismissClicked(true);
    };

    const handleOpenComplainModal = (id) => {
        orderResponseStore.setIsId(id);
        orderResponseStore.setToggleModal();
        orderResponseStore.setIsComplainClicked(true);
    };

    const cancelOffer = (id) => {
        orderResponseStore.setToggleSwitcher(true);
        orderResponseStore.setIsId(id);
        orderResponseStore.setTutorStatus("cancelOffer", orderResponseStore.id);
        orderResponseStore.setHiddenUser(props.tutorsInfo.hiddenUser);
        orderResponseStore.setTutorName(props.tutorsInfo.name);
        orderResponseStore.setClickedFrom("cancelOffer");
    };

    const returnOffer = (id) => {
        orderResponseStore.setIsId(id);
        orderResponseStore.setToggleSwitcher(!orderResponseStore.toggleSwitcher);
        if (orderResponseStore.id !== null) {
            orderResponseStore.setTutorStatus("awaitingResponse", orderResponseStore.id);
            orderResponseStore.setHiddenUser(orderResponseStore.hiddenUser);
            orderResponseStore.setTutorName(orderResponseStore.tutorName);
            orderResponseStore.setClickedFrom("cancelOffer");
        }
    };

    return (
        <>
            <>
                {props.tutorsInfo.status === "complained" || props.tutorsInfo.status === "cancelOffer" ? null : (
                    <OrderCardWrapper
                        key={props.tutorsInfo.id}
                        status={props.tutorsInfo.status}
                    >
                        <OrderSnack
                            onClick={() => returnOffer(orderResponseStore.id)}
                            id={orderResponseStore.id}
                            switchResp={orderResponseStore.toggleSwitcher}
                            title={orderResponseStore.tutorName}
                            hiddenUser={orderResponseStore.hiddenUser}
                            clickedFrom={orderResponseStore.clickedFrom}
                        />
                        <Box sx={themes.OffersCardsWrapper}>
                            <Box sx={themes.OffersCardsTopContentWrapper}>
                                {props.tutorsInfo.hiddenUser ? (
                                    <OrderResponseHiddenAvatar />
                                ) : (
                                    <OrderResponseCircleAvatar
                                        avatar={props.tutorsInfo.avatar}
                                        name={props.tutorsInfo.name}
                                    />
                                )}
                                <Box sx={themes.OffersCardsLeftWrapper}>
                                    <Box sx={themes.OffersCardsNameOnlineWrapper}>
                                        {props.tutorsInfo.hiddenUser ? (
                                            <OrderResponeAvatarName>ФИО скрыто специалистом</OrderResponeAvatarName>
                                        ) : (
                                            <OrderResponeAvatarName children={props.tutorsInfo.name} />
                                        )}
                                        <OnlineStatus
                                            isOnline={props.tutorsInfo.isOnline}
                                            lastOnline={props.tutorsInfo.lastOnline}
                                            hiddenUser={props.tutorsInfo.hiddenUser}
                                        />
                                    </Box>
                                    {props.tutorsInfo.rating ? (
                                        <Box sx={themes.OffersCardsRatingReviewWrapper}>
                                            <OrderResponseRating value={props.tutorsInfo.rating} />
                                            <OrderResponseRaitingCount value={props.tutorsInfo.review} />
                                        </Box>
                                    ) : (
                                        <NoReviews sx={{ mt: "5px" }}>отзывов пока нет</NoReviews>
                                    )}
                                    {props.tutorsInfo.description ? (
                                        <OrderResponeDescription children={props.tutorsInfo.description} />
                                    ) : (
                                        <OrderResponseTutorsInfo
                                            teaches={props.tutorsInfo.teaches}
                                            where={props.tutorsInfo.where}
                                            address={props.tutorsInfo.address}
                                            education={props.tutorsInfo.education}
                                            experience={props.tutorsInfo.experience}
                                        />
                                    )}
                                    <Divider sx={themes.OffersCardsDivider} />
                                    <Box sx={themes.OffersCardsBottomWrapper}>
                                        {props.tutorsInfo.status === "awaitingResponse" ? (
                                            <OrderResponseComplainDismissButton handleClick={() => cancelOffer(props.tutorsInfo.id)}>Отменить предложение</OrderResponseComplainDismissButton>
                                        ) : (
                                            <>
                                                {props.tutorsInfo.tutorRejected ? (
                                                    <TutorRejectText>Предложение отклонено</TutorRejectText>
                                                ) : (
                                                    <Box sx={themes.OffersCardsRejectComplainWrapper}>
                                                        <OrderResponseComplainDismissButton handleClick={() => handleOpenComplainModal(props.tutorsInfo.id)}>
                                                            Пожаловаться на отклик
                                                        </OrderResponseComplainDismissButton>
                                                        {props.tutorsInfo.status === "rejected" ? null : (
                                                            <OrderResponseComplainDismissButton
                                                                key={props.tutorsInfo.id}
                                                                handleClick={() => handleOpenDismissModal(props.tutorsInfo.id)}
                                                            >
                                                                Отклонить отклик
                                                            </OrderResponseComplainDismissButton>
                                                        )}
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                        <OrderResponseTime props={props.tutorsInfo.responseTime} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={themes.OffersCardsRightSideWrapper}>
                                {props.tutorsInfo.status === "awaitingResponse" ? null : (
                                    <Box sx={themes.OffersCardsPriceWrapper}>
                                        {!props.tutorsInfo.tutorRejected ? (
                                            <>
                                                <OrderResponsePrice children={props.tutorsInfo.price} />
                                                {props.tutorsInfo.cashback === true && (
                                                    <OrderResponseCashback
                                                        price={props.tutorsInfo.price}
                                                        cashback={props.tutorsInfo.cashback}
                                                    />
                                                )}
                                            </>
                                        ) : null}
                                    </Box>
                                )}

                                <>
                                    {props.tutorsInfo.status === "awaitingResponse" ? (
                                        <OrderResponseButton
                                            text="Заказ предложен"
                                            variant="awaitingGrayDisabled"
                                            disabled="disabled"
                                            handleClick={() => console.log("Написать")}
                                        />
                                    ) : (
                                        <>
                                            {!props.tutorsInfo.tutorRejected ? (
                                                <OrderResponseButton
                                                    text={props.tutorsInfo.price === null ? "Запросить цену" : "Выбрать"}
                                                    variant="contained"
                                                    handleClick={() => {
                                                        if (props.tutorsInfo.price === null) {
                                                            handleRequestPrice();
                                                        } else {
                                                            handleСhoose();
                                                        }
                                                    }}
                                                />
                                            ) : null}
                                        </>
                                    )}
                                </>
                                <OrderResponseButton
                                    text="Написать"
                                    variant="outlined"
                                    handleClick={() => console.log("Написать")}
                                />
                            </Box>
                        </Box>
                    </OrderCardWrapper>
                )}
            </>
        </>
    );
});

export default OffersCards;
