import { styleProcessing } from "API/plugins/Utilities";

export const ClassesListTabs = (theme) => {
    let commonStyle = {
        "& .MuiTabs-flexContainer": {
            display: "flex",
            gap: "20px",
        },

        "& button": {
            minWidth: 0,
            padding: "0px",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "500",
            ":hover": { color: "#9E9EB0" },

            "& .MuiTabs-indicator": {
                color: "red",
                backgroundColor: "#6212FF",
                background: "#6212FF",
            },
        },
        "& button.Mui-selected": {
            color: "black",
            backgroundColor: "none",
            background: "none",
        },
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            marginBottom: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TabIndicatorProps = {
    sx: {
        padding: "1px",
        backgroundColor: "#6212FF",
        bottom: 6,
        boxSizing: "border-box",
    },
};

export const Tab = {
    p: 0,
    textTransform: "none",
    color: "#9E9EB0",
};

export const Theme = {
    palette: {
        secondary: {
            light: "#9E9EB0",
            main: "black",
            dark: "#9E9EB0",
            contrastText: "#9E9EB0",
        },
        primary: {
            main: "#6212FF",
        },
    },
};
