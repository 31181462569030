import { createTheme } from "@mui/material/styles";

export const bodyBlockContainer = {
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 4px 30px rgba(106, 99, 118, 0.05)",
    borderRadius: "0px 0px 40px 40px",
    background: "#F3F3F3",
    width: "100%",
    height: "fit-content",
    minHeight: "calc(100vh - 742px)",
};

export const favouritesFoundStyle = {
    width: 1216,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 60,
    marginBottom: 20,
    color: "var(--text-black, #222)",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
};

export const favouritesNotFoundStyle = {
    width: 1216,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 60,
    marginBottom: 20,
    color: "var(--text-black, #222)",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
};

export const favouritesContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 0,
    gap: 20,
    width: 1216,
    height: "fit-content",
    marginLeft: "auto",
    marginRight: "auto",
};

export const separatorContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
};

export const teacherShowMoreTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                    color: "var(--text-black, #222)!important",
                    fontSize: "16px!important",
                    fontWeight: "500!important",
                    lineHeight: "150%!important",
                    textTransform: "none!important",
                    "&:hover": {
                        background: "var(--main-purple, #6212FF)!important",
                        color: "#FFFFFF!important",
                    },
                    "&.Mui-disabled": {
                        color: "gray!important",
                    },
                },
            },
        },
    },
});

export const horizontalSeparatorStyle = {
    width: "139px",
    height: "1px",
    background: "#9E9EB0",
};

export const paginationContainerStyle = {
    marginTop: "16px",
    marginBottom: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
};

export const pagesContainerStyle = {
    display: "flex",
    alignItems: "center",
    gap: "6px",
};

export const paginationTheme = createTheme({
    components: {
        MuiPagination: {
            styleOverrides: {
                root: {
                    ".MuiButtonBase-root": {
                        display: "flex",
                        padding: "2px 7px",
                        width: "23px",
                        height: "25px",
                        minWidth: "23px",
                        margin: 0,
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                        "&:hover": {
                            background: "var(--main-purple, #6212FF)!important",
                            color: "#FFFFFF",
                        },
                        "&.Mui-selected": {
                            background: "var(--main-purple, #6212FF)",
                            color: "#FFFFFF",
                        },
                    },
                },
                ul: {
                    gap: "2px",
                },
            },
        },
    },
});

export const progressContainerStyle = {
    width: "100%",
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
};

export const CircularProgressTheme = createTheme({
    components: {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: "#6212FF",
                },
            },
        },
    },
});
