const Payment = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon/24/payment">
                <path
                    id="Union"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.01202 4.00032C2.13913 5.04764 3.28412 6.03608 5.91176 6.03608H19.3836C19.6598 6.03608 19.8824 5.81222 19.8824 5.53608V4.30078C19.8824 4.02464 19.6585 3.80078 19.3824 3.80078H2.2C2.08954 3.80078 1.99871 3.89066 2.01202 4.00032ZM2 6.23504C2.18627 6.60759 3.11765 7.35269 5.35294 7.35269H20.502C20.7781 7.35269 21 7.57655 21 7.85269V10.1468H18.7647C16.9129 10.1468 15.4118 11.648 15.4118 13.4997C15.4118 15.3515 16.9129 16.8527 18.7647 16.8527H21V19.1468C21 19.423 20.7761 19.6468 20.5 19.6468H5.35294C3.67647 19.6468 2 19.088 2 16.8527V6.23504ZM16.73 13.4507C16.73 12.3461 17.6254 11.4507 18.73 11.4507H21.03V15.4507H18.73C17.6254 15.4507 16.73 14.5552 16.73 13.4507Z"
                    fill="#6212FF"
                />
            </g>
        </svg>
    );
};

export default Payment;
