import React, { memo } from "react";
import { Avatar, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import * as themes from "./ModalReasonWhyDeleteThemes";
import * as textThemes from "themes/TextThemes";
import store from "store/TeacherSettingsStore";
import SButton from "components/UI/SButton";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import InactiveRadioIcon from "assets/CommonAssets/SVG/InactiveRadioIcon";
import ActiveRadioIcon from "assets/CommonAssets/SVG/ActiveRadioIcon";
import { observer } from "mobx-react-lite";
import TeacherSettingsStore from "store/TeacherSettingsStore";
import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import TeacherQuestionnaireStore from "../../../../../../../store/TeacherQuestionnaireStore";

const ModalReasonWhyDelete = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const handleChange = (e) => {
        TeacherSettingsStore.setDeleteAccountReason(e.target.value);
    };

    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        props.handleSave();
    };

    return (
        <ModalSettingsSmall
            visible={props.open}
            handleClose={Close}
        >
            <Box sx={themes.ReasonWhyDelete}>
                <Box sx={themes.TopContainer}>
                    <Avatar
                        sx={themes.Avatar}
                        src={TeacherQuestionnaireStore.teacher.miniature.imageSrc}
                    />
                    <Box sx={themes.ReasonWhyQuestion}>
                        <Typography sx={textThemes.HeadlineH41Black}>{t("teacherSettings.accountActions.reasonWhyDelete")}</Typography>
                    </Box>
                    <RadioGroup
                        sx={themes.RadioGroup}
                        value={TeacherSettingsStore.deleteAccountReason}
                        onChange={handleChange}
                    >
                        {TeacherSettingsStore.reasonsToDelete.map((element) => {
                            return (
                                <FormControlLabel
                                    sx={themes.FormControl}
                                    key={element.key}
                                    value={element.value}
                                    control={
                                        <Radio
                                            sx={themes.Radio}
                                            disableRipple
                                            icon={<InactiveRadioIcon />}
                                            checkedIcon={<ActiveRadioIcon />}
                                        />
                                    }
                                    label={t(element.label)}
                                />
                            );
                        })}
                    </RadioGroup>
                </Box>
                <Box sx={themes.ButtonsContainer}>
                    <SButton
                        padding="10px 20px 11px 20px"
                        variant="secondary"
                        onClick={Close}
                    >
                        {t("main.cancel")}
                    </SButton>
                    <SButton
                        padding="10px 20px 11px 20px"
                        variant="red"
                        onClick={Save}
                    >
                        {t("teacherSettings.accountActions.submit")}
                    </SButton>
                </Box>
            </Box>
        </ModalSettingsSmall>
    );
});

export default memo(ModalReasonWhyDelete);
