import { RatesServiceClient } from "API/services/grpc/proto/RatesService/rates_grpc_web_pb";

import { CreateAnswerToRateRequest, CreateRateRequest, RatesRequest, RecentRatesRequest } from "API/services/grpc/proto/RatesService/rates_pb";

const CatalogServiceUrl = "https://study-buddy.ru:8080";

const RatesService = new RatesServiceClient(CatalogServiceUrl, null, null);

export async function GetRecentReviews(data, callback) {
    let request = new RecentRatesRequest();

    let { tutorUuid } = data;

    request.setTutorUuid(tutorUuid);
    await RatesService.recentRates(request, null, callback);
}

export async function GetMoreReviews(data, callback) {
    let request = new RatesRequest();

    let { skip, tag, tutorUuid, sort } = data;

    request.setSkip(skip);
    request.setTag(tag);
    request.setTutorUuid(tutorUuid);
    request.setSort(sort);
    await RatesService.rates(request, null, callback);
}
