import { Box, ClickAwayListener, Grid, IconButton, MenuItem, MenuList, Paper, Popper, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { IconCloseMini } from "assets/MiniChat/SVG/IconCloseMini";
import { Folder } from "assets/MiniChat/SVG/Folder";
import { SearchIcon } from "assets/MiniChat/SVG/SearchIcon";
import React from "react";
import * as Styles from "components/MiniChat/OneChat/TeacherField/TeacherFieldStyles";
import * as Styles2 from "./FindFieldStyles";
import { useState } from "react";
import { Create } from "assets/MiniChat/SVG/Сreate";
import MiniChatStore from "store/MiniChatStore";
import { observer } from "mobx-react-lite";
import { convertDataInTabsChat } from "components/ChatBodyBlock/CommonData/helper_functions";
import FullChatStore from "store/FullChatStore";
import { useMemo } from "react";

const FindField = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [open, setOpen] = useState(false);

    const UpdateFolders = useMemo(() => {
        return convertDataInTabsChat(FullChatStore.tabsData);
    }, [FullChatStore.tabsData]);

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const handleOpen = (e) => {
        setAnchorEl(anchorEl ? null : e.currentTarget);
        setOpen(true);
    };

    const handleChange = (e) => {
        props.setName(e.target.value);
    };

    return (
        <Box sx={Styles.TeacherField}>
            <Popper
                open={open}
                anchorEl={anchorEl}
                sx={{ zIndex: "900" }}
                placement="bottom"
            >
                <Paper sx={Styles2.PaperStyles}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Grid>
                            <MenuList sx={{ padding: "0.7vh 0.32vw 0vh 0.32vw" }}>
                                <Grid sx={{ borderBottom: UpdateFolders.length > 4 && "1px solid #EEEEF0", paddingBottom: UpdateFolders.length > 4 && "0.7vh" }}>
                                    <MenuItem
                                        onClick={() => {
                                            props.setPage(2);
                                            props.setFolderChats();
                                        }}
                                        disableRipple
                                        sx={Styles2.MenuItem}
                                    >
                                        Создать новую папку{" "}
                                        <Grid sx={{ color: "white", paddingLeft: "6.5vw", paddingTop: "0.7vh" }}>
                                            <Create color="#4E4E4E" />{" "}
                                        </Grid>{" "}
                                    </MenuItem>
                                </Grid>
                                <Grid sx={{ paddingBottom: "1vh", paddingTop: UpdateFolders.length > 4 && "1vh" }}>
                                    {UpdateFolders.map(
                                        (item, index) =>
                                            item.index > 3 && (
                                                <MenuItem
                                                    key={index}
                                                    onClick={() => {
                                                        props.setPage(2);
                                                        props.setFolderChats(item);
                                                    }}
                                                    disableRipple
                                                    sx={Styles2.MenuItem}
                                                >
                                                    {item.name}{" "}
                                                </MenuItem>
                                            ),
                                    )}
                                </Grid>
                            </MenuList>
                        </Grid>
                    </ClickAwayListener>
                </Paper>
            </Popper>
            <Grid
                container
                sx={{ gap: "0.52vw" }}
            >
                <Grid item>
                    <TextField
                        placeholder="Найти по имени"
                        fullWidth
                        value={props.name}
                        onChange={handleChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        sx={Styles2.TextStyles}
                        margin="none"
                    />
                </Grid>
                <Grid item>
                    <IconButton
                        sx={Styles.IconBack}
                        onClick={handleOpen}
                    >
                        <Folder />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        sx={Styles.IconBack}
                        onClick={() => {
                            MiniChatStore.SetOpen(false);
                            FullChatStore.SetToScrollMessage(null);
                        }}
                    >
                        <IconCloseMini />
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
    );
};

export default observer(FindField);
