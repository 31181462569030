import React from "react";
import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import SButton from "components/UI/SButton";
import * as styles from "./ProfileThemes";
import * as textStyles from "themes/TextThemes";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import ProfilePhoto from "./ProfilePhoto/ProfilePhoto";
import ProfileForms from "./ProfileForms/ProfileForms";
import UserStore from "store/UserStore";
import SettingsStore from "store/SettingsStore";
import { useMedias } from "API/mediasHook";

const Profile = () => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const checkChanges = () => {
        return (
            UserStore.UserInfo.name === SettingsStore.TemporaryUserInfo.name &&
            UserStore.UserInfo.surname === SettingsStore.TemporaryUserInfo.surname &&
            UserStore.UserInfo.sex === SettingsStore.TemporaryUserInfo.sex &&
            UserStore.UserInfo.timezone === SettingsStore.TemporaryUserInfo.timezone &&
            UserStore.UserInfo.university === SettingsStore.TemporaryUserInfo.university &&
            UserStore.UserInfo.birthday.getDate() === SettingsStore.TemporaryUserInfo.birthDay &&
            UserStore.UserInfo.birthday.getMonth() === SettingsStore.TemporaryUserInfo.birthMonth &&
            UserStore.UserInfo.birthday.getFullYear() === SettingsStore.TemporaryUserInfo.birthYear
        );
    };

    const cancelChanges = () => {
        SettingsStore.setTemporaryProfileInfo("name", UserStore.UserInfo.name);
        SettingsStore.setTemporaryProfileInfo("surname", UserStore.UserInfo.surname);
        SettingsStore.setTemporaryProfileInfo("sex", UserStore.UserInfo.sex);
        SettingsStore.setTemporaryProfileInfo("timezone", UserStore.UserInfo.timezone);
        SettingsStore.setTemporaryProfileInfo("university", UserStore.UserInfo.university);
        SettingsStore.setTemporaryProfileInfo("birthDay", UserStore.UserInfo.birthday.getDate());
        SettingsStore.setTemporaryProfileInfo("birthMonth", UserStore.UserInfo.birthday.getMonth());
        SettingsStore.setTemporaryProfileInfo("birthYear", UserStore.UserInfo.birthday.getFullYear());
    };

    const saveChanges = () => {
        UserStore.setFirstName(SettingsStore.TemporaryUserInfo.name);
        UserStore.setSecondName(SettingsStore.TemporaryUserInfo.surname);
        UserStore.setSex(SettingsStore.TemporaryUserInfo.sex);
        UserStore.setTimezone(SettingsStore.TemporaryUserInfo.timezone);
        UserStore.setUniversity(SettingsStore.TemporaryUserInfo.university);
        UserStore.setBirthDay(SettingsStore.TemporaryUserInfo.birthDay);
        UserStore.setBirthMonth(SettingsStore.TemporaryUserInfo.birthMonth);
        UserStore.setBirthYear(SettingsStore.TemporaryUserInfo.birthYear);
    };

    return (
        <Box sx={styles.Profile}>
            <Box sx={styles.ProfileTitle(medias)}>
                <Typography sx={textStyles.HeadlineH4White}>{t("main.settings.account")}</Typography>
            </Box>
            <Box sx={styles.ProfileContent(medias)}>
                <ProfilePhoto />
                <ProfileForms />
                <Box sx={styles.ProfileButtons(medias)}>
                    <SButton
                        onClick={cancelChanges}
                        variant="secondary"
                        padding="10px 20px 11px 20px"
                        disabled={checkChanges()}
                        style={styles.buttonSize(medias)}
                    >
                        {t("main.cancel")}
                    </SButton>
                    <SButton
                        onClick={saveChanges}
                        variant="unshadowed"
                        padding="10px 20px 11px 20px"
                        disabled={checkChanges()}
                        style={styles.buttonSize(medias)}
                    >
                        {t("main.save")}
                    </SButton>
                </Box>
            </Box>
        </Box>
    );
};

export default observer(Profile);
