import * as React from "react";
import { memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

import { Box, Typography, TextField } from "@mui/material";

import { BackIcon } from "assets/Authorization/SVG/BackIcon";

import SButton from "components/UI/SButton";

import * as styles from "./EnterSMSThemes";
import * as textStyles from "themes/TextThemes";

import AuthorizationStore from "store/AuthorizationStore";
import { useMedias } from "API/mediasHook";
import Logo from "components/UI/Logo/logo";

const EnterSMS = observer(() => {
    const { t } = useTranslation();

    const medias = useMedias();

    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            if (AuthorizationStore.seconds > 0) {
                AuthorizationStore.setSeconds(AuthorizationStore.seconds - 1);
            }

            if (AuthorizationStore.seconds === 0) {
                if (AuthorizationStore.minutes === 0) {
                    clearInterval(interval);
                } else {
                    AuthorizationStore.setSeconds(59);
                    AuthorizationStore.setMinutes(AuthorizationStore.minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [AuthorizationStore.seconds]);

    const handleBack = () => {
        if (AuthorizationStore.preSMSPage === "SignUpTutor") {
            AuthorizationStore.setCurrentAuthorizationStep("SignUpTutor");
        } else if (AuthorizationStore.preSMSPage === "SignUpStudent") {
            AuthorizationStore.setCurrentAuthorizationStep("SignUpStudent");
        } else {
            AuthorizationStore.setCurrentAuthorizationStep("SignIn");
            AuthorizationStore.setTemporaryPhoneNumber(null);
            AuthorizationStore.setTemporaryEmail("");
            AuthorizationStore.setTemporaryPassword("");
            AuthorizationStore.setSignInTab(0);
        }
    };

    const handleChangeSMS = (e) => {
        AuthorizationStore.setTemporarySMS(e.target.value);
        AuthorizationStore.setSMSError(false);
    };

    const handleClickContinue = async () => {
        if (AuthorizationStore.preSMSPage === "SignUpTutor" || AuthorizationStore.preSMSPage === "SignUpStudent") {
            await AuthorizationStore.sendSmsVerifyRegister(navigate);
        } else {
            await AuthorizationStore.verifySignInSmsCode(navigate);
        }
    };

    const handleClickResend = async () => {
        if (AuthorizationStore.preSMSPage === "SignIn") {
            await AuthorizationStore.handleCheckPhoneNumber();
        } else {
            await AuthorizationStore.RegisterNewUser();
        }
    };

    return (
        <Box sx={styles.EnterSMS(medias)}>
            {!medias.sm && (
                <Box sx={styles.LogoPos}>
                    <Logo />
                </Box>
            )}
            <Box sx={styles.EnterSMSContent(medias)}>
                <Box sx={styles.TopBlock}>
                    <Box sx={styles.Title}>
                        <SButton
                            variant="icon"
                            style={styles.BackIcon(medias)}
                            onClick={handleBack}
                        >
                            <BackIcon />
                        </SButton>

                        <Typography sx={medias.sm ? textStyles.HeadlineH3Black : textStyles.HeadlineH41Black}>Введите код из СМС</Typography>
                        <Typography sx={textStyles.Body2Black}>
                            Мы отправили персональный 5-значный код на номер
                            <span style={textStyles.Button14Black}>
                                {" "}
                                {AuthorizationStore.preSMSPage === "SignUpTutor" || AuthorizationStore.preSMSPage === "SignUpStudent"
                                    ? AuthorizationStore.formatPhoneNumber(AuthorizationStore.signUpPhone)
                                    : AuthorizationStore.formatPhoneNumber(AuthorizationStore.temporaryPhoneNumber)}
                            </span>
                        </Typography>
                    </Box>
                    <TextField
                        value={AuthorizationStore.temporarySMS}
                        error={AuthorizationStore.smsError}
                        helperText={AuthorizationStore.smsError ? "Неверный код" : ""}
                        onChange={handleChangeSMS}
                        placeholder="Код из СМС"
                        sx={styles.SMSField(AuthorizationStore.smsError)}
                        variant="standard"
                        fullWidth
                    />
                </Box>
                <Box sx={styles.BottomBlock}>
                    <SButton
                        variant="arrow"
                        padding="11px 24px 12px 24px"
                        style={styles.ContinueButton}
                        onClick={handleClickContinue}
                        disabled={AuthorizationStore.temporarySMS === "" || AuthorizationStore.temporarySMS === null}
                    >
                        Продолжить
                    </SButton>
                    <Box>
                        {AuthorizationStore.seconds > 0 || AuthorizationStore.minutes > 0 ? (
                            <Typography sx={textStyles.Body2DarkGrey}>
                                {t("main.settings.loginDetails.resendSMSLabel")}
                                {AuthorizationStore.minutes < 10 ? `${AuthorizationStore.minutes}` : AuthorizationStore.minutes}:
                                {AuthorizationStore.seconds < 10 ? `0${AuthorizationStore.seconds}` : AuthorizationStore.seconds}
                            </Typography>
                        ) : (
                            <Typography
                                sx={textStyles.Body2Purple}
                                style={{ cursor: "pointer" }}
                                onClick={handleClickResend}
                            >
                                {t("main.settings.loginDetails.resendSMSButton")}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
});

export default memo(EnterSMS);
