import { createTheme } from "@mui/material/styles";

export const locationBlockContainerStyle = (styleMode) => {
    if (styleMode === 0) {
        return {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px",
            gap: "5px",
            width: "100%",
            height: "21px",
            flex: "none",
            order: 1,
            flexGrow: 0,
        };
    }

    if (styleMode === 1) {
        return {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px",
            gap: "5px",
            width: "100%",
            height: "24px",
            flex: "none",
            order: 2,
            flexGrow: 0,
        };
    }
};

export const locationLabelStyle = (styleMode) => {
    if (styleMode === 0) {
        return {
            height: "21px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "150%",
            display: "flex",
            alignItems: "center",
            color: "#9E9EB0",
            flex: "none",
            order: 0,
            flexGrow: 0,
        };
    }

    if (styleMode === 1) {
        return {
            height: "24px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "150%",
            display: "flex",
            alignItems: "center",
            color: "#9E9EB0",
            flex: "none",
            order: 0,
            flexGrow: 0,
        };
    }
};

export const locationTextContainerStyle = (styleMode) => {
    if (styleMode === 0) {
        return {
            display: "flex",
            alignItems: "center",
            gap: "5px",
        };
    }

    if (styleMode === 1) {
        return {
            display: "flex",
            alignItems: "center",
            gap: "5px",
        };
    }
};

export const locationTextStyle = (styleMode) => {
    if (styleMode === 0) {
        return {
            height: "21px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "150%",
            display: "flex",
            alignItems: "center",
            color: "#222222",
            flex: "none",
            order: 0,
            flexGrow: 0,
        };
    }

    if (styleMode === 1) {
        return {
            height: "24px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "150%",
            display: "flex",
            alignItems: "center",
            color: "#222222",
            flex: "none",
            order: 0,
            flexGrow: 0,
        };
    }
};

export const MoreChipTheme = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                // Name of the slot
                root: {
                    display: "flex",
                    flexDirection: "row",
                    gap: 4,
                    alignItems: "center",
                    // paddingBottom: "4px",
                    // paddingTop: "2px",
                    // paddingRight: "10px",
                    // paddingLeft: "10px",
                    width: "fit-content",
                    height: "27px",
                    background: "#FFFFFF !important",
                    border: "0px solid var(--grey-light-grey, #BBBBC8)",
                    "&:hover": {
                        background: "#FFFFFF !important",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "#FFFFFF !important",
                        borderColor: "#6212FF",
                    },
                    ".MuiChip-deleteIcon": {
                        width: "16px",
                        height: "16px",
                        flex: "none",
                        order: 1,
                        flexGrow: 0,
                    },
                    ".MuiIconButton-root": {
                        margin: 0,
                        color: "#222222!important",
                    },
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    ".MuiIconButton-root:hover": {
                        color: "#222222!important",
                    },
                },
                label: {
                    width: "fit-content",
                    height: "21px",
                    fontStyle: "normal",
                    padding: 0,
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "150%",
                    fontFeatureSettings: "'tnum' on, 'lnum' on",
                    color: "#222222!important",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                },
            },
        },
    },
});

export const MenuTheme = createTheme({
    components: {
        MuiMenu: {
            styleOverrides: {
                // Name of the slot
                root: {},
                paper: {
                    marginTop: 10,
                    borderRadius: "10px!important",
                    ".MuiList-root": {
                        display: "flex",
                        width: "max-content",
                        padding: "7px 4px",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    },
                    ".MuiMenuItem-root": {
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        alignSelf: "stretch",
                        padding: "5px 12px",
                        "&:hover": {
                            background: "rgba(187, 187, 200, 0.17)!important",
                            borderRadius: "10px!important",
                            "& *": {
                                color: "#6212FF!important",
                            },
                        },
                        "&.Mui-selected": {
                            background: "rgba(187, 187, 200, 0.17)!important",
                            borderRadius: "10px!important",
                            "& *": {
                                color: "#6212FF!important",
                            },
                        },
                    },
                },
            },
        },
    },
});

export const actionTextStyle = {
    color: "var(--Text-Black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    "&:hover": {
        color: "#6212FF!important",
    },
};
