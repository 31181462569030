import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import UserStore from "store/UserStore";
import IconArrow from "assets/SettingsAssets/SVG/iconarrow";
import LoginActionRequired from "./LoginActionRequired/LoginActionRequired";
import ModalEmailChanged from "./LoginModals/Modals/ModalEmailChanged";
import ModalPhoneChanged from "./LoginModals/Modals/ModalPhoneChanged";
import * as textStyles from "themes/TextThemes";
import * as styles from "./LoginDetailsThemes";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import ModalAddEmail from "./LoginModals/Modals/ModalAddEmail";
import SettingsStore from "store/SettingsStore";
import ModalVerifyPhone from "./LoginModals/Modals/ModalVerifyPhone";
import ModalEnterSMSCode from "./LoginModals/Modals/ModalEnterSMSCode";
import ModalCreatePassword from "./LoginModals/Modals/ModalCreatePassword";
import ModalVerifyPassword from "./LoginModals/Modals/ModalVerifyPassword";
import ModalPasswordChanged from "./LoginModals/Modals/ModalPasswordChanged";
import { useMedias } from "API/mediasHook";
const LoginDetails = (props) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    //Change email
    const [dialogAddEmailOpen, SetDialogAddEmailOpen] = useState(false);

    const showAddEmailDialog = () => {
        SettingsStore.setTemporaryEmail(null);
        SetDialogAddEmailOpen(true);
    };

    const discardAddEmailDialog = () => {
        SetDialogAddEmailOpen(false);
    };

    const saveAddEmailDialog = () => {
        SetDialogAddEmailOpen(false);
        showEmailChangedDialog();
    };

    //Email changed
    const [dialogEmailChangedOpen, SetDialogEmailChangedOpen] = useState(false);

    const showEmailChangedDialog = () => {
        SetDialogEmailChangedOpen(true);
    };

    const discardEmailChangedDialog = () => {
        SetDialogEmailChangedOpen(false);
    };

    //Password changed
    const [dialogPasswordChangedOpen, SetDialogPasswordChangedOpen] = useState(false);

    const showPasswordChangedDialog = () => {
        SetDialogPasswordChangedOpen(true);
    };

    const discardPasswordChangedDialog = () => {
        SetDialogPasswordChangedOpen(false);
    };

    //Change phone
    const [dialogChangePhoneOpen, SetDialogChangePhoneOpen] = useState(false);

    const showChangePhoneDialog = () => {
        SetDialogChangePhoneOpen(true);
    };

    const discardChangePhoneDialog = () => {
        SetDialogChangePhoneOpen(false);
    };

    //Verify phone
    const [dialogVerifyPhoneOpen, SetDialogVerifyPhoneOpen] = useState(false);

    const showVerifyPhoneDialog = () => {
        SetDialogVerifyPhoneOpen(true);
    };

    const discardVerifyPhoneDialog = () => {
        SetDialogVerifyPhoneOpen(false);
    };

    const saveVerifyPhoneDialog = () => {
        SetDialogVerifyPhoneOpen(false);
        showEnterSMSDialog();
    };

    const backVerifyPhoneDialog = () => {
        SetDialogVerifyPhoneOpen(false);
        showVerifyPasswordDialog();
    };

    //Enter SMS code
    const [dialogEnterSMSOpen, SetDialogEnterSMSOpen] = useState(false);

    const showEnterSMSDialog = () => {
        SetDialogEnterSMSOpen(true);
        SettingsStore.setWrongSMSCode(false);
        SettingsStore.setSMSCode(null);
    };

    const discardEnterSMSDialog = () => {
        SetDialogEnterSMSOpen(false);
    };

    const saveEnterSMSDialog = () => {
        SetDialogEnterSMSOpen(false);
        showCreatePasswordDialog();
    };

    const backEnterSMSDialog = () => {
        SetDialogEnterSMSOpen(false);
        showVerifyPhoneDialog();
    };

    //Create password
    const [dialogCreatePasswordOpen, SetDialogCreatePasswordOpen] = useState(false);

    const showCreatePasswordDialog = () => {
        SettingsStore.setNewPasswordRepeatHelper(null);
        SettingsStore.setNewPasswordHelper(null);
        SettingsStore.setNewPasswordRepeat(null);
        SettingsStore.setNewPassword(null);
        SetDialogCreatePasswordOpen(true);
    };

    const discardCreatePasswordDialog = () => {
        SetDialogCreatePasswordOpen(false);
    };

    const saveCreatePasswordDialog = () => {
        SetDialogCreatePasswordOpen(false);
        showPasswordChangedDialog();
    };

    //Verify password
    const [dialogVerifyPasswordOpen, SetDialogVerifyPasswordOpen] = useState(false);

    const showVerifyPasswordDialog = () => {
        SettingsStore.setWrongPassword(false);
        SettingsStore.setTemporaryPassword(null);
        SetDialogVerifyPasswordOpen(true);
    };

    const discardVerifyPasswordDialog = () => {
        SetDialogVerifyPasswordOpen(false);
    };

    const saveVerifyPasswordDialog = () => {
        SetDialogVerifyPasswordOpen(false);
        showCreatePasswordDialog();
    };

    const forgotVerifyPasswordDialog = () => {
        SetDialogVerifyPasswordOpen(false);
        showVerifyPhoneDialog();
    };

    return (
        <Grid>
            <ModalAddEmail
                open={dialogAddEmailOpen}
                handleDiscard={discardAddEmailDialog}
                handleSave={saveAddEmailDialog}
            />

            <ModalEmailChanged
                open={dialogEmailChangedOpen}
                handleDiscard={discardEmailChangedDialog}
            />

            <ModalPasswordChanged
                open={dialogPasswordChangedOpen}
                handleDiscard={discardPasswordChangedDialog}
            />

            <ModalPhoneChanged
                open={dialogChangePhoneOpen}
                handleDiscard={discardChangePhoneDialog}
            />

            <ModalVerifyPhone
                open={dialogVerifyPhoneOpen}
                handleDiscard={discardVerifyPhoneDialog}
                handleSave={saveVerifyPhoneDialog}
                handleBack={backVerifyPhoneDialog}
            />

            <ModalEnterSMSCode
                open={dialogEnterSMSOpen}
                handleDiscard={discardEnterSMSDialog}
                handleSave={saveEnterSMSDialog}
                handleBack={backEnterSMSDialog}
            />

            <ModalCreatePassword
                open={dialogCreatePasswordOpen}
                handleDiscard={discardCreatePasswordDialog}
                handleSave={saveCreatePasswordDialog}
            />

            <ModalVerifyPassword
                open={dialogVerifyPasswordOpen}
                handleDiscard={discardVerifyPasswordDialog}
                handleSave={saveVerifyPasswordDialog}
                handleForgot={forgotVerifyPasswordDialog}
            />

            <Box sx={props.teacher ? styles.LoginDetailsMainContainerTeacher : styles.LoginDetailsMainContainerStudent(medias)}>
                <Box>
                    <Box sx={props.teacher ? styles.TopInfoTeacher(medias) : styles.TopInfoStudent(medias)}>{t("main.settings.loginDetails.loginDetailsTitle")}</Box>
                    <Box sx={styles.LoginDetailsBlocks(medias)}>
                        <Box
                            sx={styles.LoginDetailsBlock}
                            onClick={showChangePhoneDialog}
                        >
                            <Box sx={styles.LoginDetailsContent}>
                                <Typography sx={textStyles.Button16Black}>{t("main.phone")}</Typography>
                                <Typography sx={textStyles.Body2DarkGrey}>{UserStore.UserInfo.phone}</Typography>
                            </Box>
                            <IconArrow />
                        </Box>
                        <Box
                            sx={styles.LoginDetailsBlock}
                            onClick={showAddEmailDialog}
                        >
                            <Box sx={styles.LoginDetailsContent}>
                                <Typography sx={textStyles.Button16Black}>{t("main.email")}</Typography>
                                <Typography sx={UserStore.UserInfo.mail.verified ? textStyles.Body2DarkGrey : textStyles.Body2Grey}>
                                    {UserStore.UserInfo.mail.current === null ? t("main.settings.loginDetails.emailNotSet") : UserStore.UserInfo.mail.current}
                                </Typography>
                            </Box>
                            <IconArrow />
                        </Box>
                        <Box
                            sx={styles.LoginDetailsBlock}
                            onClick={UserStore.UserInfo.password === null ? showVerifyPhoneDialog : showVerifyPasswordDialog}
                        >
                            <Box sx={styles.LoginDetailsContent}>
                                <Typography sx={textStyles.Button16Black}>{t("main.password_upper")}</Typography>
                                <Typography sx={UserStore.UserInfo.password === null ? textStyles.Body2Grey : textStyles.Body2DarkGrey}>
                                    {UserStore.UserInfo.password === null ? t("main.settings.loginDetails.passwordNotSet") : UserStore.UserInfo.passwordUpdateDate}
                                </Typography>
                            </Box>
                            <IconArrow />
                        </Box>
                        {props.teacher && (
                            <LoginActionRequired
                                addEmail={showAddEmailDialog}
                                addPassword={showVerifyPhoneDialog}
                            />
                        )}
                    </Box>
                </Box>
                {props.teacher ? null : (
                    <LoginActionRequired
                        addEmail={showAddEmailDialog}
                        addPassword={showVerifyPhoneDialog}
                    />
                )}
            </Box>
        </Grid>
    );
};

export default observer(LoginDetails);
