import React from "react";
import { MenuItem, MenuList, Typography } from "@mui/material";
import * as themes from "components/SettingsBodyBlock/TutorSettings/MainForm/MainFormThemes";
import Telegram from "./Telegram/Telegram";
import { useState, useRef } from "react";
import * as textThemes from "themes/TextThemes";
import Box from "@mui/material/Box";
import Timezone from "./Timezone/Timezone";
import { useTranslation } from "react-i18next";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import Notifications from "./Notifications/Notifications";
import { useMedias } from "API/mediasHook";

const Settings = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const [item, setItem] = useState(1);

    const sections = {
        1: useRef(null),
        2: useRef(null),
        3: useRef(null),
    };

    const scrollTo = (item) => {
        setItem(item);
        sections[item]?.current?.scrollIntoView({ behavior: "smooth" });
    };

    const menuItems = [
        { id: 1, label: t("teacherSettings.settings.timezone"), Component: Timezone },
        { id: 2, label: t("teacherSettings.settings.notifications"), Component: Notifications },
        { id: 3, label: t("teacherSettings.settings.telegramBot"), Component: Telegram },
    ];

    return (
        <ScrollToTop>
            <Box sx={themes.MainBlock(medias)}>
                <MenuList sx={themes.Menu(medias)}>
                    {menuItems.map(({ id, label }) => (
                        <MenuItem
                            key={id}
                            disableRipple
                            selected={item === id}
                            onClick={() => scrollTo(id)}
                            sx={themes.MenuItem(item === id)}
                        >
                            <Typography sx={textThemes.Body2Black}>{label}</Typography>
                        </MenuItem>
                    ))}
                </MenuList>
                <Box sx={themes.RightBlocks}>
                    {menuItems.map(({ id, Component }) => (
                        <Box
                            key={id}
                            ref={sections[id]}
                        >
                            <Component teacher={id === 1} />
                        </Box>
                    ))}
                </Box>
            </Box>
        </ScrollToTop>
    );
});

export default memo(Settings);
