import { createTheme } from "@mui/material/styles";
import { styleProcessing } from "API/plugins/Utilities";

export const TutorsFeedbackBlock = (theme) => {
    let commonStyle = {
        width: "1216px",
        height: "fit-content",
        marginTop: "140px",
        display: "flex",
        flexDirection: "column",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            marginTop: "50px",
            width: "328px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TutorsFeedbackHeader = (theme) => {
    let commonStyle = {
        width: "495px",
        color: "#222",
        fontFamily: "Inter",
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal",
        marginBottom: "40px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "328px",
            marginBottom: "20px",
            fontSize: "26px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const FeedbacksBlock = (theme) => {
    let commonStyle = {
        width: "1216px",
        height: "fit-content",
        display: "flex",
        flexWrap: "wrap",
        columnGap: "20px",
        rowGap: "40px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "328px",
            rowGap: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ReadMoreText = {
    color: "var(--text-black, #222)",
    /* Body-1 */
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%" /* 24px */,
    // textAlign:'left'
    cursor: "pointer",
};

export const ArrowIconTheme = createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                // Name of the slot
                root: {
                    color: "#6212FF",
                    cursor: "pointer",
                },
            },
        },
    },
});

export const ReadMoreBlock = {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    marginTop: "20px",
};
