import React, { memo } from "react";
import { Chip, Box } from "@mui/material";
import FeedbackStore from "store/FeedbackStore";
import { observer } from "mobx-react-lite";
import * as themes from "./FeedbacksTabsThemes";
import { useTranslation } from "react-i18next";

const FeedbacksTabs = observer(({ children, ...props }) => {
    const { t } = useTranslation();

    const handleClick = (tabId, minRating, maxRating) => {
        FeedbackStore.setCurrentPage(1);
        FeedbackStore.setReviewsStart(0);
        FeedbackStore.setReviewsEnd(4);
        FeedbackStore.setTotalPage(Math.ceil(FeedbackStore.sortedFeedbacks().filter((item) => item.rating >= minRating && item.rating <= maxRating).length / 4));
        FeedbackStore.setMinimumShowedRating(minRating);
        FeedbackStore.setMaximumShowedRating(maxRating);
        FeedbackStore.setActiveTab(tabId);
        if (
            Math.min(FeedbackStore.reviewsEnd + 1, FeedbackStore.sortedFeedbacks().filter((item) => item.rating >= minRating && item.rating <= maxRating).length) ===
            FeedbackStore.sortedFeedbacks().filter((item) => item.rating >= minRating && item.rating <= maxRating).length
        ) {
            FeedbackStore.setDisabledShowMore(true);
        } else FeedbackStore.setDisabledShowMore(false);
    };

    return (
        <Box sx={themes.TabsGroup}>
            {FeedbackStore.tabConfig.map(({ id, labelKey, minRating, maxRating }) => (
                <Chip
                    sx={themes.customChipStyle}
                    key={id}
                    disableRipple
                    style={FeedbackStore.activeTab === id ? themes.ChipSelect : themes.Chip}
                    label={t(labelKey)}
                    variant="outlined"
                    onClick={() => handleClick(id, minRating, maxRating)}
                />
            ))}
        </Box>
    );
});

export default memo(FeedbacksTabs);
