import { createTheme } from "@mui/material/styles";

export const FiltersCashbackTheme = (medias) => {
    return {
        components: {
            MuiToggleButton: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        // Some CSS
                        boxSizing: "border-box",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "11px 16px 12px",
                        gap: "10px",
                        width: medias.sm ? "85px" : "44px",
                        height: "44px",
                        background: "linear-gradient(256.9deg, rgba(4, 200, 59, 0.1) 20.58%, rgba(18, 170, 255, 0.1) 81.82%)",

                        borderRadius: medias.sm ? "10px" : "12px",
                        flex: "none",
                        order: 0,
                        flexGrow: 0,
                        "&.Mui-selected": {
                            gap: "4px",
                            width: medias.sm ? "105px" : "44px",
                            background: "linear-gradient(256.9deg, #04C83B 20.58%, #12AAFF 81.82%)",
                        },
                        "&:hover": medias.sm
                            ? {
                                  background: "linear-gradient(256.9deg, #04C83B 20.58%, #12AAFF 81.82%)!important",
                                  "& > div > span": {
                                      color: "white!important",
                                  },
                              }
                            : {},
                    },
                },
            },
        },
    };
};

export const spanCashbackDefault = {
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "150%",
    color: "#12AAFF",
};

export const spanCashbackSelected = {
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "150%",
    color: "#FFFFFF",
};

export const iconCashback = {
    transform: "rotate(-90deg)",
    color: "white",
    width: "16px",
    height: "22px",
    marginLeft: "5px",
};
