//Кто будет заниматься?
import React, { useState } from "react";

import { observer } from "mobx-react-lite";

import SRadioGroup from "components/UI/RadioGroup";
import SBButtonGroup from "components/UI/ButtonGroup";

import RequestStore from "store/RequestStore";
import { studentTypes } from "API/dictionaries/const_data";
import store from "store/RequestStore";

import RequestNullAlert from "./RequestNullAlert";

const Step2 = () => {
    const [alert, setAlert] = useState(0);

    const [stepData, setStepData] = useState(RequestStore.storage[store.activeStepIndex].substeps[store.subStepIndex].description);

    return (
        <>
            <SRadioGroup
                changefunc={setStepData}
                value={stepData}
                list={studentTypes}
            />
            <SBButtonGroup
                handleNext={() => {
                    if (stepData) {
                        RequestStore.setStep(1, stepData);
                        RequestStore.incrementStepIndex();
                    } else {
                        setAlert(1);
                    }
                }}
                handleBack={() => RequestStore.decrementStepIndex()}
                activeStep={RequestStore.activeStepIndex}
                steps={RequestStore.storage}
            />
            {alert === 1 ? <RequestNullAlert>Чтобы перейти к следующему вопросу, выберите один из вариантов</RequestNullAlert> : null}
        </>
    );
};

export default observer(Step2);
