const EmptyBalanceIcon = function (props) {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.3411 44.5938C28.5996 44.5938 28.8393 44.4591 28.974 44.2386C29.1085 44.0178 29.1182 43.7432 28.9999 43.5135C28.4023 42.3536 28.0651 41.0385 28.0651 39.6454C28.0651 34.9736 31.8578 31.1809 36.5295 31.1809C36.9796 31.1809 37.4216 31.216 37.8527 31.2839C38.0666 31.3175 38.2847 31.2559 38.4493 31.115C38.6141 30.9744 38.7087 30.7686 38.7087 30.5518V12.8134C38.7087 12.6168 38.6307 12.4283 38.4918 12.2894L27.4194 1.21698C27.2804 1.07802 27.0919 1 26.8953 1H10.2821C8.61601 1 7.01798 1.66183 5.83991 2.8399C4.66184 4.01798 4 5.61601 4 7.28209V38.3117C4 39.9778 4.66184 41.5759 5.83991 42.7539C7.01798 43.932 8.61601 44.5938 10.2821 44.5938H28.3411Z"
                fill="#6212FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.1543 1V7.63708C26.1543 9.9733 27.0824 12.214 28.7345 13.8661C30.3866 15.5179 32.6273 16.446 34.9635 16.446H38.7088V12.8134C38.7088 12.6168 38.6307 12.4283 38.4918 12.2894L27.4194 1.21698C27.2804 1.07802 27.0919 1 26.8953 1H26.1543Z"
                fill="#4508BE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.7303 19.6598H25.3666C25.7754 19.6598 26.1076 19.3278 26.1076 18.9188C26.1076 18.5097 25.7754 18.1777 25.3666 18.1777H13.7303C13.3212 18.1777 12.9893 18.5097 12.9893 18.9188C12.9893 19.3278 13.3212 19.6598 13.7303 19.6598Z"
                fill="#EEEEF0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.7303 27.4176H28.9781C29.3872 27.4176 29.7191 27.0856 29.7191 26.6766C29.7191 26.2675 29.3872 25.9355 28.9781 25.9355H13.7303C13.3212 25.9355 12.9893 26.2675 12.9893 26.6766C12.9893 27.0856 13.3212 27.4176 13.7303 27.4176Z"
                fill="#EEEEF0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.2287 44.5945H28.341C28.5995 44.5945 28.8393 44.4598 28.974 44.2393C29.1084 44.0185 29.1181 43.7439 28.9998 43.5142C28.4022 42.3543 28.065 41.0392 28.065 39.6461C28.065 35.2949 31.3552 31.7062 35.5816 31.2343C35.243 31.1994 34.9004 31.1816 34.5534 31.1816C29.0638 31.1816 24.6069 35.6385 24.6069 41.1281C24.6069 42.3467 24.8265 43.5146 25.2287 44.5945Z"
                fill="#4508BE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.1543 1V7.27237C26.1543 8.93846 26.8164 10.5363 27.9944 11.7143C29.1725 12.8924 30.7703 13.5545 32.4364 13.5545H38.7088V12.8134C38.7088 12.6168 38.6307 12.4283 38.4918 12.2894L27.4194 1.21698C27.2804 1.07802 27.0919 1 26.8953 1H26.1543Z"
                fill="#EEEEF0"
            />
            <path
                d="M36.5295 49.5922C42.0228 49.5922 46.476 45.139 46.476 39.6457C46.476 34.1524 42.0228 29.6992 36.5295 29.6992C31.0362 29.6992 26.583 34.1524 26.583 39.6457C26.583 45.139 31.0362 49.5922 36.5295 49.5922Z"
                fill="#19B20C"
            />
            <path
                d="M37.826 40.8763H32.5405V39.3739H37.7866C38.2102 39.3739 38.5583 39.3066 38.8309 39.1719C39.1067 39.0373 39.3104 38.8501 39.4417 38.6104C39.5764 38.3707 39.642 38.0899 39.6387 37.7681C39.642 37.4528 39.5764 37.1704 39.4417 36.9208C39.3104 36.668 39.11 36.4693 38.8408 36.3248C38.5748 36.177 38.2365 36.1031 37.826 36.1031H35.8458V44.6742H34.0232V34.5859H37.826C38.6076 34.5859 39.2693 34.7271 39.8112 35.0096C40.353 35.2887 40.7635 35.668 41.0426 36.1474C41.325 36.6236 41.4663 37.1589 41.4663 37.7533C41.4663 38.3707 41.3234 38.9142 41.0377 39.3838C40.752 39.8501 40.3382 40.2162 39.7964 40.4822C39.2545 40.745 38.5977 40.8763 37.826 40.8763ZM38.0575 41.625V43.1274H32.5405V41.625H38.0575Z"
                fill="white"
            />
        </svg>
    );
};

export default EmptyBalanceIcon;
