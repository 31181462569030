import { makeAutoObservable } from "mobx";

class BalanceStore {
    //Year ago date
    subtractYears(date, years) {
        date.setFullYear(date.getFullYear() - years);

        return date;
    }
    yearAgoDate = this.subtractYears(new Date(), 1);

    //Month ago date
    today = new Date();
    month = this.today.getMonth();
    monthAgoDate = this.today.setMonth(this.month - 1);

    //Week ago date
    getLastWeeksDate() {
        const now = new Date();

        return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
    }

    filterByOptionsFirst = [];
    filterByOptionsSecond = [];

    filterByOptionFirst = null;
    filterByOptionSecond = null;

    balanceExpanded = false;

    balanceList = [];
    balanceListFiltered = [];

    Init() {
        this.Load();
    }

    constructor() {
        this.Init();
        makeAutoObservable(this);
    }

    SetFilterBy(name, value) {
        this[name] = value;
    }

    SetChipFilter() {
        this.balanceListFiltered = this.balanceList.filter(
            (value) =>
                (this.filterByOptionFirst.filterValue === ""
                    ? true
                    : this.filterByOptionFirst.filterValue === "Payment"
                      ? value.service === "Оплата"
                      : this.filterByOptionFirst.filterValue === "Points"
                        ? value.service === "Зачисление кешбэка" || value.service === "Зачисление баллов"
                        : null) &&
                (this.filterByOptionSecond.filterValue === ""
                    ? true
                    : this.filterByOptionSecond.filterValue === "Year"
                      ? value.dateFormat > this.yearAgoDate
                      : this.filterByOptionSecond.filterValue === "Month"
                        ? value.dateFormat > this.today
                        : this.filterByOptionSecond.filterValue === "Week"
                          ? value.dateFormat > this.getLastWeeksDate()
                          : null),
        );
    }

    Load() {
        this.filterByOptionsFirst = [
            { ID: 0, filterBy: "Все операции", filterValue: "" },
            { ID: 1, filterBy: "Оплата услуг", filterValue: "Payment" },
            { ID: 2, filterBy: "Зачисление кэшбека и баллов", filterValue: "Points" },
        ];

        this.filterByOptionsSecond = [
            { ID: 3, filterBy: "За последнюю неделю", filterValue: "Week" },
            { ID: 2, filterBy: "За последний месяц", filterValue: "Month" },
            { ID: 1, filterBy: "За последний год", filterValue: "Year" },
            { ID: 0, filterBy: "За все время", filterValue: "" },
        ];

        this.filterByOptionFirst = this.filterByOptionsFirst[0];
        this.filterByOptionSecond = this.filterByOptionsSecond.at(-1);

        this.balanceListEmpty = [];

        this.balanceList = [
            {
                date: "13.08.2023",
                dateFormat: new Date(2023, 7, 13),
                time: "17:02",
                service: "Возврат на карту *3820",
                reason: "Английский язык (Александра А.)",
                amount: "+1 485 ₽",
                discount: "",
                points: "+15",
                note: "Занятие не состоялось",
            },
            {
                date: "01.08.2023",
                dateFormat: new Date(2023, 7, 1),
                time: "10:29",
                service: "Зачисление кешбэка",
                reason: "Кешбэк 20% за оплату занятия 1 500 ₽",
                amount: "",
                discount: "",
                points: "+30",
                note: "",
            },
            {
                date: "24.02.2023",
                dateFormat: new Date(2023, 1, 24),
                time: "17:02",
                service: "Оплата",
                reason: "Английский язык (Александра А.)",
                amount: "+1 485 ₽",
                discount: "1 500 ₽",
                points: "-15",
                note: "Зачислен кэшбек 35 баллов",
            },
            {
                date: "20.02.2023",
                dateFormat: new Date(2023, 1, 20),
                time: "19:47",
                service: "Зачисление баллов",
                reason: "Приглашённый друг",
                amount: "",
                discount: "",
                points: "+500",
                note: "",
            },
            {
                date: "17.02.2023",
                dateFormat: new Date(2023, 1, 17),
                time: "17:01",
                service: "Оплата",
                reason: "Английский язык (Александра А.) Английский язык (Александра А.)",
                amount: "+1 485 ₽",
                discount: "1 500 ₽",
                points: "-15",
                note: "",
            },
            {
                date: "15.02.2021",
                dateFormat: new Date(2021, 1, 15),
                time: "16:59",
                service: "Оплата",
                reason: "Теория вероятности (Елизавета М.)",
                amount: "1 500 ₽",
                discount: "",
                points: "0",
                note: "",
            },
        ];

        this.balanceListFiltered = [...this.balanceList];

        this.refListEmpty = [];

        this.refList = [
            // {
            //     refFriend: "Александр",
            //     date: "20.02.2023",
            //     dateFormat: new Date(2023, 1, 20),
            //     points: "500",
            // },
        ];

        this.refPoints = 0;
        this.invitedFriends = 0;
    }
}

export default new BalanceStore();
