import React, { memo } from "react";
import Box from "@mui/material/Box";
import * as themes from "./InfoAreaThemesDefault";
import NameBlock from "./NameBlock/NameBlock";
import DescriptionBlock from "./DescriptionBlock/DescriptionBlock";
import AnswerBlock from "./AnswerBlock/AnswerBlock";

const InfoArea = ({ children, ...props }) => {
    return (
        <Box sx={themes.infoAreaContainerStyle}>
            <NameBlock value={props.value}></NameBlock>
            <DescriptionBlock value={props.value}></DescriptionBlock>
            {props.value.Answers.length > 0 ? (
                <AnswerBlock
                    value={props.value}
                    tutor={props.tutor}
                ></AnswerBlock>
            ) : null}
        </Box>
    );
};

export default memo(InfoArea);
