import React from "react";
import { Box, Typography } from "@mui/material";
import ReactPlayer from "react-player";
import * as themes from "components/SettingsBodyBlock/TutorSettings/MainForm/MainFormThemes";
import SButton from "components/UI/SButton";
import NoVideo from "assets/PAAssets/SVG/novideo";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import { observer } from "mobx-react-lite";
import "./Video.css";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { useMedias } from "API/mediasHook";
import SIconButton from "../../SIconButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

const Video = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const handleEdit = () => {
        props.handleSet(3);
    };

    return (
        <Box sx={themes.SmallItem(medias)}>
            <Box sx={themes.ItemContent(medias)}>
                <Box sx={themes.TitleAndEdit}>
                    <Typography sx={medias.sm ? textThemes.HeadlineH41Black : textThemes.HeadlineH5Black}>{t("teacherSettings.questionnaire.videoCard")}</Typography>
                    {medias.sm ? (
                        <SButton
                            onClick={handleEdit}
                            variant="text"
                        >
                            {t("teacherAccount.snackbarAddCard.addButton")}
                        </SButton>
                    ) : (
                        <SIconButton onClick={handleEdit}>
                            <AddCircleOutlineOutlinedIcon />
                        </SIconButton>
                    )}
                </Box>
                {TeacherQuestionnaireStore.teacher.video === null ? (
                    <Box sx={themes.NoVideo(medias)}>
                        <Box>
                            <NoVideo />
                        </Box>
                        <Box sx={themes.AddVideo}>
                            <Typography sx={textThemes.Button16Black}>{t("teacherSettings.questionnaire.addVideo")}</Typography>
                            <Typography sx={textThemes.Body2Black}>{t("teacherSettings.questionnaire.attractAttention")}</Typography>
                        </Box>
                    </Box>
                ) : (
                    <ReactPlayer
                        className="react-player-custom"
                        url={TeacherQuestionnaireStore.teacher.video}
                        controls
                        width="289px"
                        height="auto"
                    />
                )}
            </Box>
        </Box>
    );
});

export default memo(Video);
