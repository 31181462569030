import { createTheme } from "@mui/material/styles";
import { styleProcessing } from "../../../../../API/plugins/Utilities";
// Полностью переиспользовано из components/topfiltercard/popularpart/popularpartthemes

export const filtersPopular = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 0,
        gap: 12,
        height: 27,
        marginLeft: 0,
        marginTop: 0,
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, display: "none" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const textPopular = {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#222222",
};

export const TagTheme = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                // Name of the slot
                root: {
                    height: "23px",
                    "&:hover": {
                        background: "#FFFFFF!important",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "#FFFFFF!important",
                        borderColor: "#6212FF",
                    },
                },
            },
        },
    },
});
