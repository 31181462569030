import React from "react";
import { useState } from "react";
import MessageField from "./MessageField/MessageField";
import TeacherField from "./TeacherField/TeacherField";
import TeacherPrice from "./TeacherPrice/TeacherPrice";
import FullChatStore from "store/FullChatStore";
import { observer } from "mobx-react-lite";
import SendMessageBox from "components/ChatBodyBlock/GridFieldChat/BotomFieldChat/SendMessageBox";
import { Box, Stack } from "@mui/material";
import MiniChatStore from "store/MiniChatStore";
import SButton from "components/UI/SButton";
import { findChatById } from "components/ChatBodyBlock/CommonData/helper_functions";
import ReplyOrEditInfo from "../../ChatBodyBlock/GridFieldChat/ReplyOrEditInfo/ReplyOrEditInfo";
import SearchMessagesWindow from "./SearchMessages/SearchMessagesWindow";
import SearchMessageResult from "./SearchMessages/SearchMessageResult";
import ComplainModal from "./ComplainModal/ComplainModal";
import BlockModal from "./BlockModal/BlockModal";
import PinnedMessagePanel from "components/ChatBodyBlock/GridFieldChat/PinedMessage/PinnedMessagePanel";

const { format, parseISO } = require("date-fns");

const OneChat = (props) => {
    const [dialog, setDialog] = useState(findChatById(FullChatStore.tabsData.chats, FullChatStore.id));

    const [text, setText] = useState("");

    const [openEditModalMessage, SetOpenEditModalMessage] = useState(false);

    const [openReplyModalMessage, SetOpenReplyModalMessage] = useState(false);

    const [disable, setDisabled] = useState(false);

    const [openmodal, setOpenModal] = useState(false);

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleOpen = () => {
        setOpenModal(true);
    };

    const [openmodalblock, setOpenModalBlock] = useState(false);

    const handleOpenBlock = () => {
        setOpenModalBlock(true);
    };

    return (
        <>
            {FullChatStore.openSearchMessagesMini ? (
                <SearchMessagesWindow sendJsonMessage={props.sendJsonMessage} />
            ) : (
                <>
                    <TeacherField
                        avt={"https://study-buddy.ru" + dialog.participants_info[0].photoLink}
                        muted={dialog.disabled_notifications}
                        setPage={props.setPage}
                        teacherName={dialog.participants_info[0].name}
                        status={dialog.participants_info[0].status.online !== true ? dialog.participants_info[0].status.last_seen : "online"}
                        id={dialog.chat_id}
                        sendJsonMessage={props.sendJsonMessage}
                        handleOpen={handleOpen}
                        handleOpenBlock={handleOpenBlock}
                    />
                    {FullChatStore.isBlocked !== false ? (
                        <Box
                            sx={{
                                height: "6.5vh",
                                paddingTop: "1vh",
                                paddingLeft: "0.5vw",
                            }}
                        >
                            <SButton
                                style={{ width: "23.5vw", height: "5.3vh" }}
                                onClick={() => setOpenModalBlock(true)}
                            >
                                Разблокировать
                            </SButton>
                        </Box>
                    ) : FullChatStore.isBlockedBy !== false ? (
                        <></>
                    ) : (
                        <TeacherPrice
                            price={1200}
                            bonus={35}
                        />
                    )}
                </>
            )}
            <BlockModal
                sendJsonMessage={props.sendJsonMessage}
                openmodalblock={openmodalblock}
                setOpenModalBlock={setOpenModalBlock}
                name={dialog.participants_info[0].name + " " + dialog.participants_info[0].surname}
            />
            <ComplainModal
                open={openmodal}
                handleClose={handleClose}
                sendJsonMessage={props.sendJsonMessage}
            />
            <Stack
                sx={{
                    width: "100%",
                    maxWidth: "100%",
                }}
            >
                <PinnedMessagePanel
                    sendJsonMessage={props.sendJsonMessage}
                    mini={true}
                />
            </Stack>
            <Stack
                sx={{
                    width: "100%",
                    height: FullChatStore.openSearchMessagesMini
                        ? "calc(100% - (7.8vh))"
                        : FullChatStore.isBlocked !== false
                          ? "calc(100% - (16.6vh))"
                          : FullChatStore.isBlockedBy !== false
                            ? "calc(100% - (8.8vh))"
                            : FullChatStore.currPinnedMessageId !== null
                              ? "calc(100% - (26.5vh))"
                              : "calc(100% - (18.6vh))",
                    position: "relative",
                }}
            >
                <MessageField
                    openEditModalMessage={openEditModalMessage}
                    setOpenEditModalMessage={SetOpenEditModalMessage}
                    openReplyModalMessage={openReplyModalMessage}
                    setOpenReplyModalMessage={SetOpenReplyModalMessage}
                    disable={disable}
                    setDisable={setDisabled}
                    setText={setText}
                    teacherName={dialog.participants_info[0].name + " " + dialog.participants_info[0].surname}
                    sendJsonMessage={props.sendJsonMessage}
                />
                <Box>
                    {(openEditModalMessage || openReplyModalMessage) && (
                        <ReplyOrEditInfo
                            handleOpenEditModalMessage={SetOpenEditModalMessage}
                            handleOpenReplyModalMessage={SetOpenReplyModalMessage}
                            handleText={setText}
                            handleDisable={setDisabled}
                        />
                    )}
                </Box>
                {FullChatStore.openSearchMessagesResultMini ? (
                    <SearchMessageResult />
                ) : FullChatStore.isBlocked !== false ? (
                    <Box sx={{ textAlign: "center", paddingTop: "1vh", borderTop: "1px solid #BBBBC8", fontSize: "14px" }}>
                        Переписка недоступна,
                        <br /> специалист заблокирован
                    </Box>
                ) : FullChatStore.isBlockedBy !== false ? (
                    <Box sx={{ textAlign: "center", paddingTop: "1vh", borderTop: "1px solid #BBBBC8", fontSize: "14px" }}>
                        Переписка недоступна,
                        <br /> вас заблокировали
                    </Box>
                ) : (
                    <Box sx={{ paddingLeft: "8px" }}>
                        <Box>{FullChatStore.isWrite && <Box sx={{ fontStyle: "italic", fontSize: "1.6vh", color: "#9E9EB0" }}>{dialog.participants_info[0].name} печатает...</Box>}</Box>
                        <Box>
                            <SendMessageBox
                                text={text}
                                setText={setText}
                                setDisable={setDisabled}
                                openEditModalMessage={openEditModalMessage}
                                setOpenEditModalMessage={SetOpenEditModalMessage}
                                openReplyModalMessage={openReplyModalMessage}
                                setOpenReplyModalMessage={SetOpenReplyModalMessage}
                                sendJsonMessage={props.sendJsonMessage}
                            />
                        </Box>
                    </Box>
                )}
            </Stack>
        </>
    );
};

export default observer(OneChat);
