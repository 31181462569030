import React, { memo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Checkbox, Dialog, Divider, FormControlLabel, IconButton, Menu } from "@mui/material";
import * as themes from "./ModalResponseThemes";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CloseRounded, ErrorSharp } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import OrderStore from "store/OrderStore";
import { numberFormatting, checkKeyDown } from "API/plugins/Utilities";
import MenuItem from "@mui/material/MenuItem";
import CreateIcon from "assets/CommonAssets/SVG/createIconSVG";
import EditIcon from "assets/CommonAssets/SVG/editIconSVG";
import ModalTemplates from "./ModalTemplates";

const ModalResponse = observer(({ children, ...props }) => {
    const [dialogTemplatesOpen, SetDialogTemplatesOpen] = useState(false);

    const showTemplatesDialog = () => {
        SetDialogTemplatesOpen(true);
    };

    const discardTemplatesDialog = () => {
        SetDialogTemplatesOpen(false);
    };

    const saveTemplatesDialog = () => {
        OrderStore.AddNewTemplate();
        SetDialogTemplatesOpen(false);
    };

    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        props.handleSave();
    };

    const processingProposedPrice = (event) => {
        OrderStore.SetProposedPrice(event.target.value);
    };

    const processingTotalPrice = (event) => {
        OrderStore.SetTotalPrice();
    };

    const processingNegotiatedPrice = (event) => {
        console.log(event.target.checked);
        OrderStore.SetNegotiatedPrice(event.target.checked);
    };

    const processingResponseMessage = (event) => {
        OrderStore.SetResponseMessage(event.target.value);
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleMenuToggle = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (props, event) => {
        if (props.mode === "edit") {
            OrderStore.SetCurrentTemplate(props.value);
            showTemplatesDialog();
        } else if (props.value !== null) {
            if (props.value === "default") {
                OrderStore.SetCurrentTemplate();
                showTemplatesDialog();
            } else {
                OrderStore.SetCurrentTemplate(props.value);
                OrderStore.AddFromTemplate(props.value);
            }
        }

        setAnchorEl(null);
    };

    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={props.open}
                onClose={Close}
            >
                <Box sx={themes.dialogContainerStyle}>
                    <ThemeProvider theme={themes.CloseIconTheme}>
                        <IconButton
                            onClick={Close}
                            disableRipple
                        >
                            <CloseRounded></CloseRounded>
                        </IconButton>
                    </ThemeProvider>
                    <Box sx={themes.cardContainerStyle}>
                        <Box sx={themes.headerBlockContainerStyle}>
                            <Typography sx={themes.headerTextStyle}>{props.value.OrderTitle}</Typography>
                            <Box sx={themes.studentPriceContainerStyle}>
                                <Typography sx={themes.studentPriceLabelStyle}>Цена заказчика:</Typography>
                                {props.value.Price?.PriceFrom ? (
                                    props.value.Price?.PriceTo ? (
                                        <Typography sx={themes.priceTextStyle(0)}>
                                            {props.value.Price.PriceFrom.toLocaleString()} - {props.value.Price.PriceTo.toLocaleString()} ₽/ч
                                        </Typography>
                                    ) : (
                                        <Typography sx={themes.priceTextStyle(0)}>{props.value.Price.PriceFrom.toLocaleString()} ₽/ч</Typography>
                                    )
                                ) : (
                                    <Typography sx={themes.priceTextStyle(1)}>по договоренности</Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={themes.contentBlockContainerStyle}>
                            <Box sx={themes.priceContainerStyle}>
                                <Typography sx={themes.proposedPriceHeaderTextStyle}>Твоя цена</Typography>
                                {!OrderStore.negotiatedPrice && (
                                    <Box sx={themes.bothPricesContainerStyle}>
                                        <ThemeProvider theme={themes.ProposedPriceTheme}>
                                            <TextField
                                                onKeyDown={(event) => checkKeyDown(event, ["number", "decimal", "space"])}
                                                variant={"standard"}
                                                placeholder={"Укажи цену для заказчика, ₽"}
                                                value={OrderStore.response.proposedPrice}
                                                onChange={processingProposedPrice}
                                                onBlur={processingTotalPrice}
                                            />
                                        </ThemeProvider>
                                        <Box sx={themes.totalPriceContainerStyle}>
                                            <Typography sx={themes.totalPriceLabelStyle}>Твой гонорар</Typography>
                                            <Typography sx={themes.totalPriceTextStyle}>{numberFormatting(OrderStore.response.totalPrice, 1)} ₽</Typography>
                                        </Box>
                                    </Box>
                                )}
                                {!OrderStore.dialogResponseMode ? (
                                    <ThemeProvider theme={themes.NegotiatedPriceTheme}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disableRipple={true}
                                                    checked={OrderStore.negotiatedPrice}
                                                    onChange={processingNegotiatedPrice}
                                                />
                                            }
                                            label={<Typography>По договорённости</Typography>}
                                        />
                                    </ThemeProvider>
                                ) : null}
                                <Box sx={themes.comissionContainerStyle}>
                                    <Box sx={themes.comissionInfoStyle}>
                                        <ThemeProvider theme={themes.WarningIconTheme}>
                                            <ErrorSharp></ErrorSharp>
                                        </ThemeProvider>
                                        <Typography sx={themes.comissionTextStyle}>Текущая комиссия сервиса — {OrderStore.comissionPercent}%</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            {!OrderStore.dialogResponseMode ? (
                                <Box sx={themes.responseContainerStyle}>
                                    <Box sx={themes.responseHeaderContainerStyle}>
                                        <Typography sx={themes.responseLabelStyle}>Твой отклик</Typography>
                                        <Typography
                                            sx={themes.templatesLabelStyle}
                                            onClick={handleMenuToggle}
                                        >
                                            Шаблоны
                                        </Typography>
                                        <ThemeProvider theme={themes.MenuTheme}>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleMenuClose.bind(this, { value: null })}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "right",
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right",
                                                }}
                                            >
                                                <MenuItem
                                                    key={"default"}
                                                    onClick={handleMenuClose.bind(this, { value: "default" })}
                                                    disableRipple
                                                >
                                                    <Typography sx={themes.menuItemTextStyle}>Создать шаблон</Typography>
                                                    <Box sx={themes.IconContainerStyle}>
                                                        <ThemeProvider theme={themes.IconAddTheme}>
                                                            <IconButton disableRipple>
                                                                <CreateIcon />
                                                            </IconButton>
                                                        </ThemeProvider>
                                                    </Box>
                                                </MenuItem>
                                                {OrderStore.reviewsTemplates.length > 0 ? <Divider sx={themes.dividerStyle}></Divider> : null}
                                                {OrderStore.reviewsTemplates.map((value, index) => (
                                                    <MenuItem
                                                        key={value.ID}
                                                        disableRipple
                                                    >
                                                        <Typography
                                                            sx={themes.menuItemTextStyle}
                                                            onClick={handleMenuClose.bind(this, { value: value })}
                                                        >
                                                            {value.TemplateTitle}
                                                        </Typography>
                                                        <Box
                                                            sx={themes.IconContainerStyle}
                                                            onClick={handleMenuClose.bind(this, { value: value, mode: "edit" })}
                                                        >
                                                            <ThemeProvider theme={themes.IconEditTheme}>
                                                                <IconButton disableRipple>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </ThemeProvider>
                                                        </Box>
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </ThemeProvider>
                                    </Box>
                                    <ThemeProvider theme={themes.ResponseMessageTheme(OrderStore.response.responseMessage.length === 1000)}>
                                        <TextField
                                            onChange={processingResponseMessage}
                                            value={OrderStore.response.responseMessage}
                                            variant="outlined"
                                            placeholder={"Напишите о своих сильных сторонах, опыте, это поможет выделиться среди других и заинтересовать ученика"}
                                            multiline
                                            rows={6}
                                            inputProps={{
                                                maxLength: 1000,
                                            }}
                                            FormHelperTextProps={{ component: "div" }}
                                            helperText={
                                                <Box sx={themes.helperContainerStyle(OrderStore.response.responseMessage.length === 1000)}>
                                                    {OrderStore.response.responseMessage.length === 1000 ? <Typography sx={themes.currLengthTextMaxStyle}>Достигнут лимит символов</Typography> : null}
                                                    <Box sx={themes.helperCounterContainerStyle}>
                                                        <Typography sx={themes.currLengthTextStyle(OrderStore.response.responseMessage.length)}>
                                                            {OrderStore.response.responseMessage.length}
                                                        </Typography>
                                                        <Typography sx={themes.maxLengthTextStyle}>/1000</Typography>
                                                    </Box>
                                                </Box>
                                            }
                                        ></TextField>
                                    </ThemeProvider>
                                </Box>
                            ) : null}
                        </Box>
                        <Box sx={themes.actionsBlockContainerStyle}>
                            <ThemeProvider theme={themes.ModalActionButtonTheme(OrderStore.response.responseMessage.length)}>
                                <Button
                                    onClick={Save}
                                    disabled={OrderStore.response.responseMessage.length === 0}
                                    disableRipple
                                >
                                    <Typography style={themes.buttonTextStyle}>Отправить</Typography>
                                </Button>
                            </ThemeProvider>
                        </Box>
                    </Box>
                </Box>
                <ModalTemplates
                    open={dialogTemplatesOpen}
                    handleDiscard={discardTemplatesDialog}
                    handleSave={saveTemplatesDialog}
                ></ModalTemplates>
            </Dialog>
        </ThemeProvider>
    );
});

export default memo(ModalResponse);
