import { Button, styled } from "@mui/material";

export const OrderResponseComplainDismissButtonStyled = styled(Button)({
    minWidth: "0px",

    padding: 0,
    fontFamily: '"Inter", sans-serif',
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
    letterSpacing: "0em",
    color: "rgba(158, 158, 176, 1)",
    backgroundColor: "none",
    textTransform: "none",
    transition: "opacity 0.5s",
    "&:hover": {
        color: "rgba(129, 65, 255, 1)",
        background: "none",
        transition: "opacity 0.5s",
    },
    "& .MuiTouchRipple-root": {
        color: "rgba(34, 34, 34, 1)",
    },
});
