import { createTheme } from "@mui/material/styles";
import { styleProcessing } from "API/plugins/Utilities";

export const AboutUsBlock = (theme) => {
    let commonStyle = {
        width: "1216px",
        height: "auto-fit",
        position: "relative",
        background: "#F7F7F7",
        marginTop: "140px",
        // marginBottom:'140px', //!! ДЛЯ ТЕСТА УБРАТЬ
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            marginTop: "50px",
            width: "328px",
            display: "inline-grid",
            gap: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const mainHeader = (theme) => {
    let commonStyle = {
        width: "859px",
        height: "auto-fit",
        color: "#222",
        fontSize: "40px",
        fontWeight: "600",
        lineHeight: "135%",
        position: "absolute",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "328px",
            fontSize: "24px",
            lineHeight: "130%",
            position: "unset",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const AboutUsContentBlock = (theme) => {
    let commonStyle = {
        width: "495px",
        height: "auto-fit",
        top: "94px",
        left: "618px",
        color: "#222",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "150%",
        position: "absolute",
        gap: "16px",
        display: "flex",
        flexDirection: "column",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "328px",
            fontSize: "24px",
            lineHeight: "130%",
            position: "unset",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const AboutUsCardBlock = (theme) => {
    let commonStyle = {
        gap: "16px",
        topMargin: "30px",
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: "260px",
        left: "618px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            position: "unset",
            flexDirection: "row",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Text1 = (theme) => {
    let commonStyle = {
        weight: "495px",
        height: "auto-fit",
        color: "#222",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "150%",
        flexDirection: "column",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Text2 = (theme) => {
    let commonStyle = {
        weight: "495px",
        height: "auto-fit",
        color: "#222",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "150%",
        flexDirection: "column",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const VideoBlock = (theme) => {
    let commonStyle = {
        width: "495px",
        height: "615px",
        marginTop: "94px",
        position: "relative",
        background: "#F7F7F7",
        objectFit: "cover",
        borderRadius: "20px",
        overflow: "hidden",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "328px",
            height: "270px",
            marginTop: "0px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ImgBlock = (theme) => {
    let commonStyle = {
        width: "495px",
        height: "615px",
        //top:'94px',
        position: "relative",
        background: "#F7F7F7",
        objectFit: "cover",
        borderRadius: "20px",
        cursor: "pointer",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "328px",
            height: "270px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const PlayerBlock = (theme) => {
    let commonStyle = {
        width: "495px",
        height: "615px",
        //top:'94px',
        position: "relative",
        background: "#F7F7F7",
        objectFit: "cover",
        borderRadius: "20px",
        overflow: "hidden",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "328px",
            height: "270px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const tcPlayIconBGStyle = (theme) => {
    let commonStyle = {
        position: "absolute",
        width: "64px",
        height: "64px",
        left: "30px",
        top: "615px", //'615px',
        borderRadius: "50%",
        background: "rgba(255, 255, 255, 0.5)",
        cursor: "pointer",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "38px",
            height: "38px",
            left: "16px",
            top: "220px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const mobileCardBlock = {
    gap: "21px",
    display: "flex",
    flexDirection: "column",
};

export const CarouselContainer = {
    width: "328px",
    // gap: '21px',
    // position: 'relative'    // Добавьте необходимые стили для контейнера карусели
    // width: '100%',
    //margin: '0 auto',
};

export const TCPlayIconTheme = (theme) => {
    let commonStyle = {
        components: {
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        position: "relative",
                        left: "23%",
                        top: "25%",
                        color: "#6212FF",
                        fontSize: "2.2rem",
                    },
                },
            },
        },
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            components: {
                ...commonStyle.components,
                MuiSvgIcon: {
                    ...commonStyle.components.MuiSvgIcon,
                    styleOverrides: {
                        ...commonStyle.components.MuiSvgIcon.styleOverrides,
                        root: {
                            ...commonStyle.components.MuiSvgIcon.styleOverrides.root,
                            left: "14%",
                            top: "14%",
                            color: "#6212FF",
                            fontSize: "1.8rem",
                        },
                    },
                },
            },
        },
        defaultStyle: commonStyle,
    };

    return createTheme(styleProcessing(theme, styleObject));
};
