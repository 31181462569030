import { styleProcessing } from "API/plugins/Utilities";

export const QuestionnaireCheckSuccess = {
    padding: "20px",
    maxWidth: "599px",
    width: "100%",
    borderRadius: "20px",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    boxSizing: "border-box",
};

export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
};

export const Button = (theme) => {
    let commonStyle = {
        width: "fit-content",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, width: "100%" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
