import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./VideoManagementPanelThemes";
import { Divider, Typography } from "@mui/material";
import VideoChatStore from "store/VideoChatStore";
import ManageMicro from "./ManageMicro/ManageMicro";
import ManageVideo from "./ManageVideo/ManageVideo";
import ManageDemo from "./ManageDemo/ManageVideo";
import ManageChat from "./ManageChat/ManageChat";
import ManageMore from "./ManageMore/ManageMore";
import ManageCall from "./ManageCall/ManageCall";

const VideoManagementPanel = observer(({ children, ...props }) => {
    return (
        <Box sx={themes.videoManagementPanelContainerStyle}>
            {VideoChatStore.managementPanel.demoState && (
                <Box sx={themes.demoTooltipContainerStyle}>
                    <Typography sx={themes.demoTooltipTextStyle}>Включена демонстрация экрана</Typography>
                </Box>
            )}
            <Box sx={themes.videoManagementPanelMIContainerStyle}>
                <Typography sx={themes.meetingSubjectStyle}>{VideoChatStore.call.CallTheme}</Typography>
                <Typography sx={themes.meetingStartingTimeStyle}>
                    {VideoChatStore.call.StartDateTime.toLocaleString("default", { day: "numeric", month: "long" }) + ", "}
                    {VideoChatStore.call.StartDateTime.toLocaleString("default", { weekday: "short" }) + " | "}
                    {VideoChatStore.call.StartDateTime.toLocaleString("default", { hour: "2-digit", minute: "2-digit" })}
                </Typography>
            </Box>
            <Box sx={themes.videoManagementPanelActionsContainerStyle}>
                <ManageMicro></ManageMicro>
                <ManageVideo></ManageVideo>
                <ManageDemo></ManageDemo>
                <ManageChat></ManageChat>
                <ManageMore></ManageMore>
                <Divider
                    sx={themes.dividerStyle(10, 10)}
                    orientation="vertical"
                    flexItem
                />
                <ManageCall></ManageCall>
            </Box>
        </Box>
    );
});

export default memo(VideoManagementPanel);
