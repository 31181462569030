import React, { memo } from "react";
import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as themes from "./OrdersInWorkThemes";
import * as textThemes from "themes/TextThemes";
import InProgressOrderCard from "components/OrdersBodyBlock/StudentOrders/OrderCards/InProgressCard/InProgressOrderCard";
import { useTranslation } from "react-i18next";
import StudentOrdersStore from "store/StudentOrdersStore";

const OrdersInWork = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    if (StudentOrdersStore.orders.filter((item) => item.baseOrderInfo.status === "waiting_for_finish").length > 0) {
        return (
            <Box sx={themes.OrdersInWorkThemes}>
                <Typography sx={textThemes.HeadlineH4Black}>{t("main.studentAccount.ordersInProgress")}</Typography>
                <Box sx={themes.InWorkCards}>
                    {StudentOrdersStore.orders
                        .filter((item) => item.baseOrderInfo.status === "waiting_for_finish")
                        .map((order) => (
                            <InProgressOrderCard
                                key={order.baseOrderInfo.orderUuid}
                                order={order}
                            />
                        ))}
                </Box>
            </Box>
        );
    }
});

export default memo(OrdersInWork);
