import React from "react";
import * as styles from "./ProfileFormsThemes";
import { useTranslation } from "react-i18next";
import FirstNameForm from "./FirstNameForm/FirstNameForm";
import LastNameForm from "./LastNameForm/LastNameForm";
import Box from "@mui/material/Box";
import GenderForm from "./GenderForm/GenderForm";
import BirthDateForm from "./BirthDateForm/BirthDateForm";
import TimezoneForm from "./TimezoneForm/TimezoneForm";
import UniversityForm from "./UniversityForm/UniversityForm";
import { memo } from "react";
import { useMedias } from "API/mediasHook";
const ProfileForms = ({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <Box sx={styles.ProfileForms}>
            <Box sx={styles.RowBlock(medias)}>
                <FirstNameForm />
                <LastNameForm />
            </Box>
            <Box sx={styles.RowBlock(medias)}>
                <GenderForm />
                <BirthDateForm />
            </Box>
            <TimezoneForm />
            <UniversityForm />
        </Box>
    );
};

export default memo(ProfileForms);
