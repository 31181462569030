export const WhoNeedBlock = (medias) => {
    return {
        borderRadius: medias.sm ? "40px" : "20px",
        background: "#6212FF",
        display: "flex",
        position: "relative",
        alignItems: "center",
        padding: medias.sm ? "50px 103px 50px 50px" : "30px 16px 30px 16px",
        width: medias.sm ? "1216px" : "328px",
        boxSizing: medias.sm ? "unset" : "border-box",
        flexDirection: medias.sm ? "unset" : "column",
        gap: medias.sm ? "100px" : "0px",
    };
};

export const LeftBlock = (medias) => {
    return {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "60px",
        marginBottom: medias.sm ? "0px" : "20px",
    };
};

export const ButtonBlock = (medias) => {
    return {
        display: "flex",
        width: medias.sm ? "342px" : "296px",
        padding: "20px 26px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "100px",
        background: "#FFF",
        cursor: "pointer",
        boxSizing: "border-box",
        marginTop: medias.sm ? "0px" : "30px",
    };
};

export const qualityBlock = (medias) => {
    return {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        width: medias.sm ? "550px" : "296px",
    };
};

export const IconBlock1 = (medias) => {
    return {
        left: medias.sm ? 206 : 179,
        top: medias.sm ? 52 : 65,
        position: "absolute",
    };
};

export const IconBlock2 = (medias) => {
    return {
        left: medias.sm ? 533 : 255,
        top: medias.sm ? 176 : 11,
        position: "absolute",
    };
};

export const IconBlock3 = (medias) => {
    return {
        left: medias.sm ? 452 : 245,
        top: medias.sm ? 288 : 253,
        position: "absolute",
    };
};
