import React, { memo } from "react";
import OrderStore from "store/OrderStore";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./OrderBodyBlockThemes";
import InfoCard from "./InfoCard/InfoCard";
import StickyActionBlock from "./StickyActionBlock/StickyActionBlock";
import { IconButton, Typography } from "@mui/material";
import { NavigateBeforeSharp } from "@mui/icons-material";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const OrderBodyBlock = observer(({ children, ...props }) => {
    const navigate = useNavigate();

    return (
        <Box sx={themes.orderBodyBlockStyle}>
            <Box
                sx={themes.orderBodyNavBlockStyle}
                onClick={() => navigate("/orders-search")}
            >
                <ThemeProvider theme={themes.GoBackIconTheme}>
                    <IconButton disableRipple>
                        <NavigateBeforeSharp></NavigateBeforeSharp>
                    </IconButton>
                </ThemeProvider>
                <Typography sx={themes.navTextStyle}>Все заказы</Typography>
            </Box>
            <Box sx={themes.orderBodyContentBlockStyle}>
                <InfoCard value={OrderStore.orderArr.find((value) => value._id === props.orderID)}></InfoCard>
                <StickyActionBlock value={OrderStore.orderArr.find((value) => value._id === props.orderID)}></StickyActionBlock>
            </Box>
        </Box>
    );
});

export default memo(OrderBodyBlock);
