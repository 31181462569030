export const cardTextContainerStyle = (styleMode, medias) => {
    if (styleMode === 0) {
        return {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            gap: medias.sm ? "10px" : "14.5px",
            isolation: "isolate",
            width: medias.sm ? "667px" : "296px",
            height: medias.sm ? "313px" : "fit-content",
            flex: "none",
            order: 0,
            flexGrow: 0,
        };
    }

    if (styleMode === 1) {
        return {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            gap: medias.sm ? "16px" : "20px",
            isolation: "isolate",
            width: medias.sm ? "667px" : "296px",
            height: "fit-content", // medias.sm ? "247px" : "fit-content",
            flex: "none",
            order: 0,
            flexGrow: 0,
        };
    }
};
