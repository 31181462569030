import { styleProcessing } from "../../API/plugins/Utilities";

export const rulesBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const RulesOuterContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
};

export const RulesLists = {
    display: "flex",
    flexDirection: "column",
    gap: "60px",
    maxWidth: "800px",
};

export const TutorRulesBodyBlock = {
    background: "#F7F7F7",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "0px 0px 40px 40px",
};

export const TutorRulesBodyBlockContent = (theme) => {
    let commonStyle = {
        maxWidth: "1216px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "50px",
        alignItems: "flex-start",
        padding: "50px 0 110px 0",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            boxSizing: "border-box",
            paddingRight: "16px",
            paddingLeft: "16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
