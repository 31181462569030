import { TextField, Typography, Box, Drawer } from "@mui/material";
import SButton from "components/UI/SButton";
import React from "react";
import * as styles from "./LoginModalsThemes";
import * as textStyles from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import ModalSettings from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsBig/ModalSettings";
import { observer } from "mobx-react-lite";
import UserStore from "store/UserStore";
import SettingsStore from "store/SettingsStore";
import { useMedias } from "API/mediasHook";
import DrawerHeader from "components/SettingsBodyBlock/StudentSettings/DrawerHeader/DrawerHeader";
import { memo } from "react";

const ModalAddEmail = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        if (UserStore.UserInfo.mail.current === null) {
            UserStore.setEmail(SettingsStore.temporaryEmail);
        } else {
            SettingsStore.setTemporaryEmailNotVerified(SettingsStore.temporaryEmail);
        }

        props.handleSave();
    };

    const DrawerList = (
        <Box sx={styles.AddForm(medias)}>
            <DrawerHeader
                text={t("main.settings.loginDetails.addEmailTitle")}
                handleClose={Close}
            />
            <Box sx={styles.LabelField(medias)}>
                <Box sx={styles.TextWrapper}>
                    <Typography sx={textStyles.Body2Black}>{t("main.settings.loginDetails.enterEmailDescriptionShort")}</Typography>
                    <Typography sx={textStyles.Body2Black}>{t("main.email")}</Typography>
                </Box>
                <TextField
                    sx={styles.AddLoginDetailTextField("328px")}
                    required
                    fullWidth
                    variant="standard"
                    name="email"
                    placeholder={t("main.enterEmail")}
                    onChange={(e) => SettingsStore.setTemporaryEmail(e.target.value)}
                />
            </Box>
            <Box
                container
                sx={styles.ButtonsDrawerContainer(medias)}
            >
                <SButton
                    style={{ width: "192px" }}
                    padding="10px 20px 11px 20px"
                    variant="secondary"
                    onClick={Close}
                >
                    {t("main.cancel")}
                </SButton>
                <SButton
                    style={{ width: "192px" }}
                    padding="10px 20px 11px 20px"
                    variant="unshadowed"
                    onClick={Save}
                    disabled={SettingsStore.temporaryEmail === null}
                >
                    {t("main.save")}
                </SButton>
            </Box>
        </Box>
    );

    return (
        <>
            {!medias.sm ? (
                <Drawer
                    open={props.open}
                    onClose={Close}
                >
                    {DrawerList}
                </Drawer>
            ) : (
                <ModalSettings
                    visible={props.open}
                    handleClose={Close}
                    header={t("main.settings.loginDetails.addEmailTitle")}
                >
                    <Box sx={styles.AddForm}>
                        <Typography sx={textStyles.Body2Black}>{t("main.settings.loginDetails.enterEmailDescriptionShort")}</Typography>
                        <Box sx={styles.LabelField}>
                            <Typography sx={textStyles.Body2Black}>{t("main.email")}</Typography>
                            <TextField
                                sx={styles.AddLoginDetailTextField("328px")}
                                required
                                fullWidth
                                variant="standard"
                                name="email"
                                placeholder={t("main.enterEmail")}
                                onChange={(e) => SettingsStore.setTemporaryEmail(e.target.value)}
                            />
                        </Box>
                        <Box
                            container
                            sx={styles.ButtonsContainer}
                        >
                            <SButton
                                style={{ width: "192px" }}
                                padding="10px 20px 11px 20px"
                                variant="secondary"
                                onClick={Close}
                            >
                                {t("main.cancel")}
                            </SButton>
                            <SButton
                                style={{ width: "192px" }}
                                padding="10px 20px 11px 20px"
                                variant="unshadowed"
                                onClick={Save}
                                disabled={SettingsStore.temporaryEmail === null}
                            >
                                {t("main.save")}
                            </SButton>
                        </Box>
                    </Box>
                </ModalSettings>
            )}
        </>
    );
});

export default memo(ModalAddEmail);
