import React, { memo } from "react";
import * as themes from "./InfoAreaThemes";
import CardText from "./CardText";
import CardActions from "./CardActions";
import { useMedias } from "../../../../API/mediasHook";
import Box from "@mui/material/Box";

const InfoArea = ({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={themes.tcInfoAreaStyle(props.styleMode ? props.styleMode : 0, medias)}>
            <CardText
                value={props.value}
                styleMode={props.styleMode}
            ></CardText>
            {(props.styleMode ? props.styleMode : 0) === 0 && medias.sm && <CardActions value={props.value} />}
        </Box>
    );
};

export default memo(InfoArea);
