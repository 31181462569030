import { memo } from "react";
import * as styles from "./ModalRescheduleRequestThemes";
import * as textStyles from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import { Box, Typography } from "@mui/material";
import MyClassesStore from "store/MyClassesStore";
import TextfieldReview from "components/MyClassesBodyBlock/MyClassesList/StudyListItem/Modals/ModalRateClass/TextfieldReview/TextfieldReview";
import SButton from "components/UI/SButton";
import { observer } from "mobx-react-lite";

const ModalRescheduleRequest = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const getFormatedDate = (index) => {
        const dateObject = MyClassesStore.formatTodayClassDate(index);

        return `${dateObject.date} ${t(dateObject.month)}, ${t(dateObject.weekday)}`;
    };

    return (
        <ModalSettingsSmall
            visible={props.open}
            handleClose={props.handleDiscard}
        >
            <Box sx={styles.ModalRescheduleRequest}>
                <Box sx={styles.RescheduleRequestTitle}>
                    <Typography sx={textStyles.HeadlineH41Black}>{t("main.myClasses.rescheduleRequest")}</Typography>
                    <Typography sx={textStyles.Body2DarkGrey}>{t("main.myClasses.tutorShouldConfirm")}</Typography>
                </Box>
                <Box sx={styles.timeContainer}>
                    <Box sx={styles.timeTitle}>
                        <Typography sx={textStyles.Button14Black}>{t("main.myClasses.currentTime")}</Typography>
                        <Typography
                            sx={textStyles.Body1DarkGrey}
                        >{`${getFormatedDate(MyClassesStore.classes.indexOf(props.lesson))}, ${MyClassesStore.formatTodayClassTime(props.lesson)}`}</Typography>
                    </Box>
                    <Box sx={styles.timeTitle}>
                        <Typography sx={textStyles.Button14Black}>{t("main.myClasses.requestedTime")}</Typography>
                        <Typography sx={textStyles.Body1Purple}>{MyClassesStore.getFormattedDateForReschedule(MyClassesStore.temporaryRescheduleTimeStart, props.lesson)}</Typography>
                    </Box>
                </Box>
                <Box sx={styles.RescheduleReason}>
                    <Typography sx={textStyles.Body2Black}>{t("main.myClasses.reasonOfReschedule")}</Typography>
                    <TextfieldReview
                        maxCharacters={500}
                        handleChange={MyClassesStore.setTemporaryRescheduleReason}
                        value={MyClassesStore.temporaryRescheduleReason}
                        placeholder={t("main.myClasses.writeTutorReasonOfReschedule")}
                    />
                </Box>
                <Box sx={styles.ButtonContainer}>
                    <SButton
                        variant="secondary"
                        padding="10px 20px 11px 20px"
                        style={styles.Button}
                        onClick={props.handleDiscard}
                    >
                        {t("main.cancel")}
                    </SButton>
                    <SButton
                        variant="unshadowed"
                        padding="10px 20px 11px 20px"
                        style={styles.Button}
                        onClick={props.handleSave}
                    >
                        {t("main.myClasses.sendRequest")}
                    </SButton>
                </Box>
            </Box>
        </ModalSettingsSmall>
    );
});

export default memo(ModalRescheduleRequest);
