import { Box, Typography } from "@mui/material";
import SButton from "components/UI/SButton";
import React from "react";
import { useNavigate } from "react-router-dom";
import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import * as textStyles from "themes/TextThemes";
import * as styles from "./LoginModalsThemes";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useMedias } from "API/mediasHook";

const ModalPhoneChanged = observer(({ children, ...props }) => {
    const navigate = useNavigate();

    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <ModalSettingsSmall
            visible={props.open}
            handleClose={props.handleDiscard}
        >
            <Typography
                sx={textStyles.HeadlineH41Black}
                style={styles.ModalTitlePhoneChange(medias)}
            >
                {t("main.settings.loginDetails.changePhone")}
            </Typography>
            <Typography
                sx={textStyles.Body2Black}
                style={styles.ModalTextPhoneChange}
            >
                {t("main.settings.loginDetails.changePhoneSupport")}
            </Typography>
            <Box style={styles.ModalButton}>
                <SButton
                    variant="arrow"
                    padding="10px 20px 11px 20px"
                    onClick={() => navigate("/chat")}
                >
                    {t("main.goToSupport")}
                </SButton>
            </Box>
        </ModalSettingsSmall>
    );
});

export default ModalPhoneChanged;
