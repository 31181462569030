const CalendarIcon = function (props) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon">
                <g id="Subtract">
                    <path
                        d="M16.7502 1.75V3.31C20.4602 3.31 21 4.5 20.9602 7.56C20.9902 7.84 20.7502 8.09 20.4602 8.09H3.5402C3.2602 8.09 3.0202 7.85 3.0402 7.56C3 4.5 3.5402 3.31 7.2502 3.31V1.75C7.2502 1.34 7.5902 1 8.0002 1C8.4102 1 8.7502 1.34 8.7502 1.75V3.25H15.2502V1.75C15.2502 1.34 15.5902 1 16.0002 1C16.4102 1 16.7502 1.34 16.7502 1.75Z"
                        fill="#6212FF"
                        fillOpacity="0.2"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4 9.58984H20C20.55 9.58984 21 10.0399 21 10.5899V16.7499C21 21 20 21.7499 16 21.7499H8C4 21.7499 3 21 3 16.7499V10.5899C3 10.0399 3.45 9.58984 4 9.58984ZM6.5 13C6.5 12.7239 6.72386 12.5 7 12.5H8C8.27614 12.5 8.5 12.7239 8.5 13V14C8.5 14.2761 8.27614 14.5 8 14.5H7C6.72386 14.5 6.5 14.2761 6.5 14V13ZM7 16.5C6.72386 16.5 6.5 16.7239 6.5 17V18C6.5 18.2761 6.72386 18.5 7 18.5H8C8.27614 18.5 8.5 18.2761 8.5 18V17C8.5 16.7239 8.27614 16.5 8 16.5H7ZM11 13C11 12.7239 11.2239 12.5 11.5 12.5H12.5C12.7761 12.5 13 12.7239 13 13V14C13 14.2761 12.7761 14.5 12.5 14.5H11.5C11.2239 14.5 11 14.2761 11 14V13ZM11.5 16.5C11.2239 16.5 11 16.7239 11 17V18C11 18.2761 11.2239 18.5 11.5 18.5H12.5C12.7761 18.5 13 18.2761 13 18V17C13 16.7239 12.7761 16.5 12.5 16.5H11.5ZM15.5 13C15.5 12.7239 15.7239 12.5 16 12.5H17C17.2761 12.5 17.5 12.7239 17.5 13V14C17.5 14.2761 17.2761 14.5 17 14.5H16C15.7239 14.5 15.5 14.2761 15.5 14V13ZM16 16.5C15.7239 16.5 15.5 16.7239 15.5 17V18C15.5 18.2761 15.7239 18.5 16 18.5H17C17.2761 18.5 17.5 18.2761 17.5 18V17C17.5 16.7239 17.2761 16.5 17 16.5H16Z"
                        fill="#6212FF"
                        fillOpacity="0.2"
                    />
                </g>
            </g>
        </svg>
    );
};

export default CalendarIcon;
