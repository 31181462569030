const PurpleCheckBoxChecked = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM12.7311 5.68232C13.1079 5.27857 13.0861 4.64578 12.6823 4.26894C12.2786 3.89211 11.6458 3.91393 11.2689 4.31768L6.35278 9.585L4.68394 8.02046C4.28103 7.64273 3.64819 7.66315 3.27046 8.06606C2.89273 8.46897 2.91315 9.10181 3.31606 9.47954L5.71606 11.7295C5.90983 11.9112 6.1679 12.0083 6.43335 11.9994C6.69881 11.9906 6.94983 11.8765 7.13106 11.6823L12.7311 5.68232Z"
                fill="#6212FF"
            />
        </svg>
    );
};

export default PurpleCheckBoxChecked;
