import { styleProcessing } from "API/plugins/Utilities";

export const Balance = {
    boxSizing: "border-box",
    borderRadius: "0px 0px 40px 40px",
    background: "#FFF",
    display: "flex",
    justifyContent: "center",
    padding: "0 16px 70px 16px",
    minHeight: "780px",
    width: "100%",
    margin: "0 auto",
};

export const BalanceContent = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    paddingTop: "40px",
    width: "100%",
    maxWidth: "1216px",
};

export const LeftPanel = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    width: "100%",
};

export const TableAndEmpty = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const FilterAndTable = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const BalancePanelsRightLeft = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "123px",
        alignItems: "flex-start",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
