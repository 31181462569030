import React from "react";
import * as themes from "./ServiceAdvantagesThemes";
import * as textThemes from "themes/TextThemes";
import { memo } from "react";
import { Typography } from "@mui/material";
import SearchIcon from "assets/PromoTutorAssets/PNG/SearchIcon.png";
import CalendarIcon from "assets/PromoTutorAssets/PNG/CalendarIcon.png";
import DollarIcon from "assets/PromoTutorAssets/PNG/DollarIcon.png";
import BellIcon from "assets/PromoTutorAssets/PNG/BellIcon.png";
import CrowIcon from "assets/PromoTutorAssets/PNG/CrowIcon.png";
import Img1 from "assets/PromoTutorAssets/PNG/Rectangle 6335.png";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthorizationStore from "store/AuthorizationStore";
import SButton from "components/UI/SButton";
import { useMedias } from "../../../API/mediasHook";

const ServiceAdvantages = ({ children, ...props }) => {
    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    const handleClickSignUp = () => {
        AuthorizationStore.setPreviousLocationPath(location.pathname);
        AuthorizationStore.setCurrentAuthorizationStep("SignUpTutor");
        AuthorizationStore.setSignUpPhone(null);
        AuthorizationStore.setSignUpPhoneError(false);
        AuthorizationStore.setSignUpName("");
        AuthorizationStore.setSignUpEmail("");
        AuthorizationStore.setSignUpEmailError(false);
        AuthorizationStore.setSignUpEmailErrorText("");
        AuthorizationStore.setTutorOfferAgree(false);
        AuthorizationStore.setTutorRulesAgree(false);
        navigate("/authorization");
    };

    const medias = useMedias();

    return (
        <Box sx={themes.ServiceAdvantagesBlock(medias)}>
            <Box sx={themes.LeftBlock(medias)}>
                <Box sx={themes.TitleBlock}>
                    <Typography sx={textThemes.HeadlineH2Black(medias)}>{t("promoPages.promoTutor.serviceAdvantages")}</Typography>
                    <Typography sx={textThemes.Body1Black}>{t("promoPages.promoTutor.teachAndEarn")}</Typography>
                </Box>
                <Box sx={themes.FunctionsAndButton}>
                    <Box sx={themes.FunctionsBlock}>
                        <Box sx={themes.functionElement}>
                            <Box sx={themes.iconElem}>
                                <img
                                    src={SearchIcon}
                                    alt={1}
                                />
                            </Box>
                            <Typography sx={textThemes.Body1Black}>{t("promoPages.promoTutor.easilyFind")}</Typography>
                        </Box>
                        <Box sx={themes.functionElement}>
                            <Box sx={themes.iconElem}>
                                <img
                                    src={CalendarIcon}
                                    alt={2}
                                />
                            </Box>
                            <Typography sx={textThemes.Body1Black}>{t("promoPages.promoTutor.setYourOwnPrices")}</Typography>
                        </Box>
                        <Box sx={themes.functionElement}>
                            <Box sx={themes.iconElem}>
                                <img
                                    src={DollarIcon}
                                    alt={3}
                                />
                            </Box>
                            <Typography sx={textThemes.Body1Black}>{t("promoPages.promoTutor.getPaidAfterClass")}</Typography>
                        </Box>
                        <Box sx={themes.functionElement}>
                            <Box sx={themes.iconElem}>
                                <img
                                    src={BellIcon}
                                    alt={4}
                                />
                            </Box>
                            <Typography sx={textThemes.Body1Black}>{t("promoPages.promoTutor.conductMoreClasses")}</Typography>
                        </Box>
                        <Box sx={themes.functionElement}>
                            <Box sx={themes.iconElem}>
                                <img
                                    src={CrowIcon}
                                    alt={5}
                                />
                            </Box>
                            <Typography sx={textThemes.Body1Black}>{t("promoPages.promoTutor.automaticallyGetRating")}</Typography>
                        </Box>
                    </Box>
                    <SButton
                        variant="unshadowed"
                        style={themes.SignUpButton(medias)}
                        onClick={handleClickSignUp}
                    >
                        {t("promoPages.promoTutor.becomeTutor")}
                    </SButton>
                </Box>
            </Box>
            <img
                src={Img1}
                alt={6}
            />
        </Box>
    );
};

export default memo(ServiceAdvantages);
