import { styleProcessing } from "API/plugins/Utilities";

export const ModalSize = (theme, width) => {
    let commonStyle = {
        width: width ? width : "410px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "328px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const modalSettingsSmall = {
    position: "absolute",
    marginLeft: "50vw",
    top: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
};

export const modalSettingsSmallContent = (theme, bg, pl, pr) => {
    let commonStyle = {
        borderRadius: "20px 20px 20px 20px",
        backgroundColor: bg ? bg : "white",
        paddingLeft: pl ? pl : "40px",
        paddingRight: pr ? pr : "40px",
        paddingTop: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            paddingLeft: "20px",
            paddingRight: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
