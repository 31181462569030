import { Tooltip, styled } from "@mui/material";

const StyledDiplomaTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
    />
))(({ theme }) => ({
    zIndex: theme.zIndex.tooltip + 1,

    [`& .MuiTooltip-tooltip`]: {
        display: "flex",
        alignItems: "center",
        // width: "202px",
        // height: "30px",
        padding: "10px",
        fontSize: "13px",
        fontFamily: '"Inter", sans-serif',
        backgroundColor: "rgba(255, 255, 255, 1)",
        color: "rgba(34, 34, 34, 1)",
        borderRadius: "10px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    },
    "& .MuiTooltip-arrow::before": {
        backgroundColor: "rgba(255, 255, 255, 1)",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    },
}));

export default StyledDiplomaTooltip;
