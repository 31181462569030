import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import { Button, Dialog, IconButton } from "@mui/material";
import * as themes from "./ModalNotAuthThemesDefault";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { CloseRounded, EastSharp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useMedias } from "../../../API/mediasHook";

const ModalNotAuth = observer(({ children, ...props }) => {
    const navigate = useNavigate();

    const Close = () => {
        props.handleClose();
    };

    const medias = useMedias();

    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={props.open}
                onClose={Close}
            >
                <Box sx={themes.dialogContainerStyle}>
                    <ThemeProvider theme={themes.CloseIconTheme}>
                        <IconButton
                            onClick={Close}
                            disableRipple
                        >
                            <CloseRounded></CloseRounded>
                        </IconButton>
                    </ThemeProvider>
                    <Box sx={themes.cardContainerStyle(medias)}>
                        <Box sx={themes.contentBlockContainerStyle}>
                            <Box sx={themes.contentBlockStyle(medias)}>
                                <ThemeProvider theme={themes.AvatarTheme}>
                                    <Avatar src={props.value.AvatarSrc}></Avatar>
                                </ThemeProvider>
                                <Box sx={themes.contentTextContainerStyle}>
                                    <Typography sx={themes.headerTextStyle}>{props.header}</Typography>
                                    <Typography sx={themes.subHeaderTextStyle}>{props.content}</Typography>
                                </Box>
                            </Box>
                            <ThemeProvider theme={themes.ButtonRegistrationTheme}>
                                <Button
                                    onClick={() => navigate("/start")}
                                    endIcon={<EastSharp />}
                                >
                                    <Typography style={themes.buttonRegistrationTextStyle}>Зарегистрироваться</Typography>
                                </Button>
                            </ThemeProvider>
                        </Box>
                        <Box sx={themes.actionsBlockContainerStyle}>
                            <Box sx={themes.horizontalSeparatorStyle(medias)}></Box>
                            <Box sx={themes.actionsContainerStyle}>
                                <Typography sx={themes.captionTextStyle}>Уже есть аккаунт?</Typography>
                                <Typography
                                    sx={themes.loginTextStyle}
                                    onClick={() => navigate("/start")}
                                >
                                    Войти
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </ThemeProvider>
    );
});

export default memo(ModalNotAuth);
