export const Error404BodyBlock = {
    background: "#F7F7F7",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "0px 0px 40px 40px",
    padding: "80px 0 59px 0",
    minHeight: "761px",
};

export const ErrorBlock = {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: "100px",
};

export const NumBLock = {
    display: "flex",
    alignItems: "flex-start",
    gap: "70px",
};

export const TitleBlock = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
};

export const MiddleBlock = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
};

export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "40px",
};

export const ErrorContent = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "50px",
};
