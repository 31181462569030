import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./ManageMicroThemes";
import { IconButton, Tooltip, Typography } from "@mui/material";
import MicroOffIcon from "assets/VideoChatAssets/SVG/microOffIcon";
import { ThemeProvider } from "@mui/material/styles";
import VideoChatStore from "store/VideoChatStore";
import MicroOnIcon from "assets/VideoChatAssets/SVG/microOnIcon";

const ManageMicro = observer(({ children, ...props }) => {
    const toggleMicroState = () => {
        VideoChatStore.SetMicroState(!VideoChatStore.managementPanel.microState);
    };

    return (
        <Box
            sx={themes.iconContainerStyle(VideoChatStore.managementPanel.microState)}
            onClick={toggleMicroState}
        >
            <ThemeProvider theme={themes.ActionButtonTheme(0, VideoChatStore.managementPanel.microState)}>
                <Tooltip
                    title={<Typography sx={themes.tooltipStyle}>{VideoChatStore.managementPanel.microState ? "Отключить" : "Включить"} микрофон</Typography>}
                    placement={"top"}
                >
                    <IconButton disableRipple>{VideoChatStore.managementPanel.microState ? <MicroOnIcon /> : <MicroOffIcon />}</IconButton>
                </Tooltip>
            </ThemeProvider>
        </Box>
    );
});

export default memo(ManageMicro);
