const VisibilityIcon = function (props) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon">
                <g id="eye-off">
                    <path
                        id="Vector"
                        d="M16.32 16.3179C15.0768 17.2655 13.563 17.7905 12 17.8161C6.90909 17.8161 4 11.9979 4 11.9979C4.90465 10.312 6.15937 8.83906 7.68 7.67789M10.4727 6.35425C10.9733 6.23707 11.4859 6.1785 12 6.17971C17.0909 6.17971 20 11.9979 20 11.9979C19.5585 12.8238 19.032 13.6013 18.4291 14.3179M13.5418 13.5397C13.3421 13.7541 13.1012 13.926 12.8336 14.0452C12.5659 14.1645 12.277 14.2286 11.9841 14.2338C11.6911 14.239 11.4001 14.1851 11.1284 14.0753C10.8568 13.9656 10.61 13.8023 10.4028 13.5951C10.1956 13.3879 10.0323 13.1411 9.92255 12.8694C9.81282 12.5978 9.75893 12.3068 9.7641 12.0138C9.76927 11.7209 9.83339 11.432 9.95264 11.1643C10.0719 10.8967 10.2438 10.6558 10.4582 10.4561"
                        stroke="#67677A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_2"
                        d="M4 4L20 20"
                        stroke="#67677A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </svg>
    );
};

export default VisibilityIcon;
