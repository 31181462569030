import { createTheme } from "@mui/material/styles";

export const tagsContainerStyle = {
    display: "inline-flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    rowGap: "8px",
    columnGap: "8px",
};

export const ActiveChipTheme = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                // Name of the slot
                root: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "fit-content",
                    height: "27px",
                    borderRadius: "20px",
                    background: "var(--text-black, #222)",
                    border: "1px solid var(--grey-light-grey, #BBBBC8)",
                    "&:hover": {
                        background: "#222!important",
                    },
                    "&:active": {
                        background: "#222!important",
                        borderColor: "#6212FF",
                    },
                },
                label: {
                    width: "fit-content",
                    height: "21px",
                    fontStyle: "normal",
                    padding: "2px 10px 4px 10px!important",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "150%",
                    fontFeatureSettings: "'tnum' on, 'lnum' on",
                    color: "#FFFFFF",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                },
            },
        },
    },
});

export const InactiveChipTheme = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                // Name of the slot
                root: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "fit-content",
                    height: "27px",
                    background: "#FFFFFF",
                    border: "1px solid var(--grey-light-grey, #BBBBC8)",
                    "&:hover": {
                        background: "#FFFFFF!important",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "#FFFFFF!important",
                        borderColor: "#6212FF",
                    },
                },
                label: {
                    width: "fit-content",
                    height: "21px",
                    fontStyle: "normal",
                    padding: "2px 10px 4px 10px!important",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "150%",
                    fontFeatureSettings: "'tnum' on, 'lnum' on",
                    color: "#222222",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                },
            },
        },
    },
});

export const MoreChipTheme = (state) =>
    createTheme({
        components: {
            MuiChip: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        display: "flex",
                        flexDirection: "row",
                        gap: 4,
                        alignItems: "center",
                        paddingBottom: "4px",
                        paddingTop: "2px",
                        paddingRight: "10px",
                        paddingLeft: "10px",
                        width: "fit-content",
                        height: "27px",
                        background: state ? "#FFFFFF!important" : "#222222!important",
                        border: "1px solid var(--grey-light-grey, #BBBBC8)",
                        "&:hover": {
                            background: state ? "#FFFFFF!important" : "#222222!important",
                            borderColor: state ? "#6212FF" : "",
                        },
                        "&:active": {
                            background: state ? "#FFFFFF!important" : "#222222!important",
                            borderColor: state ? "#6212FF" : "",
                        },
                        ".MuiChip-deleteIcon": {
                            width: "16px",
                            height: "16px",
                            flex: "none",
                            order: 1,
                            flexGrow: 0,
                        },
                        ".MuiIconButton-root": {
                            margin: 0,
                            color: state ? "#222222!important" : "#FFFFFF!important",
                        },
                        ".MuiSvgIcon-root": {
                            width: "16px",
                            height: "16px",
                        },
                        ".MuiIconButton-root:hover": {
                            color: state ? "#222222!important" : "#FFFFFF!important",
                        },
                    },
                    label: {
                        width: "fit-content",
                        height: "21px",
                        fontStyle: "normal",
                        padding: 0,
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "150%",
                        fontFeatureSettings: "'tnum' on, 'lnum' on",
                        color: state ? "#222222!important" : "#FFFFFF!important",
                        flex: "none",
                        order: 0,
                        flexGrow: 0,
                    },
                },
            },
        },
    });

export const MenuTheme = createTheme({
    components: {
        MuiMenu: {
            styleOverrides: {
                // Name of the slot
                root: {},
                paper: {
                    marginTop: 10,
                    borderRadius: "10px!important",
                    ".MuiList-root": {
                        display: "flex",
                        width: "max-content",
                        padding: "7px 4px",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    },
                    ".MuiMenuItem-root": {
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        alignSelf: "stretch",
                        padding: "5px 12px",
                        "&:hover": {
                            background: "rgba(187, 187, 200, 0.17)!important",
                            borderRadius: "10px!important",
                            "& *": {
                                color: "#6212FF!important",
                            },
                        },
                        "&.Mui-selected": {
                            background: "rgba(187, 187, 200, 0.17)!important",
                            borderRadius: "10px!important",
                            "& *": {
                                color: "#6212FF!important",
                            },
                        },
                    },
                },
            },
        },
    },
});

export const actionTextStyle = {
    color: "var(--Text-Black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    "&:hover": {
        color: "#6212FF!important",
    },
};

export const IconCheckTheme = createTheme({
    components: {
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    color: "#6212FF!important",
                    padding: 0,
                },
            },
        },
    },
});

export const IconContainerStyle = {
    display: "flex",
    minWidth: 24,
};
