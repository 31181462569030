import { styleProcessing } from "API/plugins/Utilities";

export const SummaryIndicators = (theme) => {
    let commonStyle = {
        display: "flex",
        alignItems: "center",
        gap: "44px",
        padding: "30px 0px 30px 20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            alignItems: "left",
            flexDirection: "column",
            gap: "9px",
            padding: "20px 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const VerticalBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "row",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Divider = {
    width: "1px",
    height: "42px",
    background: "#DADADF",
};

export const PeriodBlock = {
    maxWidth: "220px",
    width: "100%",
};

export const CentralInfoBlockWrapper = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "44px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "0px",
            justifyContent: "space-between",
            maxWidth: "300px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
