export const descriptionCardStyle = (medias) => {
    return {
        boxShadow: "none",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "20px",
        /*minHeight: "433px",*/
        padding: medias.sm ? "30px 40px" : "20px",
        width: medias.sm ? "827px" : "320px",
        borderRadius: "20px",
        background: "var(--main-white, #FFF)",
    };
};

export const descriptionFullContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    position: "relative",
    maxHeight: "fit-content",
};

export const descriptionCutContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    position: "relative",
    maxHeight: "389px",
    overflow: "hidden",
};

export const aboutTextStyle = {
    alignSelf: "stretch",
    color: "var(--text-black, #222)",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
};

export const descriptionTextStyle = {
    alignSelf: "stretch",
    color: "var(--text-black, #222)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const showMoreTextStyle = {
    position: "sticky",
    color: "var(--main-purple, #6212FF)",
    cursor: "pointer",
    fontSize: "16px",
    fontStyle: "normal",
    fontSeight: "400",
    lineHeight: "150%",
};
