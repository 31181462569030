import React from "react";
import * as themes from "./Error403BodyBlockThemes";
import * as textThemes from "themes/TextThemes";
import NumFour from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumFour/NumFour";
import NumZero from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumZero/NumZero";
import { Box, Typography } from "@mui/material";
import SupportLinkText from "components/ErrorsBodyBlock/ErrorsCommonComponents/SupportLinkText/SupportLinkText";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import NumThree from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumThree/NumThree";
import { useLocation, useNavigate } from "react-router-dom";
import MiniChatStore from "store/MiniChatStore";
import AuthorizationStore from "store/AuthorizationStore";
import { observer } from "mobx-react-lite";
import UserStore from "store/UserStore";

const Error403BodyBlock = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const location = useLocation();

    const showDialog = () => {
        MiniChatStore.SetOpen(true);
    };

    const goSignUp = () => {
        AuthorizationStore.setPreviousLocationPath(location.pathname);
        AuthorizationStore.setCurrentAuthorizationStep("SignIn");
        AuthorizationStore.setPreSMSPage(null);
        AuthorizationStore.setTemporaryEmail("");
        AuthorizationStore.setTemporaryPassword("");
        AuthorizationStore.setTemporaryPhoneNumber("");
        navigate("/authorization");
    };

    return (
        <Box sx={themes.Error403BodyBlock}>
            <Box sx={themes.ErrorBlock}>
                <Box sx={themes.NumBLock}>
                    <NumFour />
                    <NumZero />
                    <NumThree />
                </Box>
                <Box sx={themes.ErrorContent}>
                    <Box sx={themes.TopBlock}>
                        <Typography sx={textThemes.HeadlineH2Black}>{t("errors.accessRestricted")}</Typography>
                        <Typography sx={{ ...textThemes.Body1Black, ...themes.MiddleLinkText }}>
                            {t("errors.noRightsForUserPart1")}
                            <Typography
                                onClick={UserStore.isAuth === true ? showDialog : goSignUp}
                                sx={{ ...textThemes.Body1Purple, ...themes.LinkText }}
                                component={"span"}
                            >
                                {t("errors.noRightsForUserPart2")}
                            </Typography>
                            {t("errors.noRightsForUserPart3")}
                        </Typography>
                    </Box>
                    <SupportLinkText />
                </Box>
            </Box>
        </Box>
    );
});

export default memo(Error403BodyBlock);
