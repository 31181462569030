import { styleProcessing } from "API/plugins/Utilities";
export const LogoPos = { marginBottom: "20px" };

export const EnterSMS = (theme) => {
    let commonStyle = {
        padding: "30px 0px 0px 60px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "13px 16px 16px 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const BackIcon = (theme) => {
    let commonStyle = {
        width: "fit-content",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            marginLeft: "-4px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const EnterSMSContent = (theme) => {
    let commonStyle = {
        maxWidth: "328px",
        display: "flex",
        flexDirection: "column",
        gap: "40px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            maxWidth: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Title = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const BottomBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
};

export const SMSField = (condition) => {
    return {
        "& .MuiInput-underline:before": {
            borderBottom: condition ? "1px solid ##E12626" : "1px solid #BBBBC8",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: condition ? "#E12626" : "#6212FF",
        },
        "& .MuiInputBase-input": {
            fontSize: "16px",
            lineHeight: "150%",
            fontWeight: 400,
            textTransform: "none",
        },
    };
};

export const ContinueButton = {
    width: "100%",
};
