import { createTheme } from "@mui/material/styles";

export const DialogContainerTheme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: "rgba(0, 0, 0, 0)!important",
                    boxShadow: "none!important",
                },
            },
        },
    },
});

export const dialogContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "10px",
};

export const cardContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
    padding: "40px 40px 30px 40px",
    flexDirection: "column",
    gap: "30px",
    borderRadius: "20px",
    background: "var(--main-white, #FFF)",
    width: 430,
    height: "fit-content",
};

export const headerBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    width: "100%",
};

export const headerTextStyle = {
    color: "var(--text-black, #222)",
    textAlign: "center",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "140%",
};

export const contentBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
    width: "100%",
};

export const orderRefuseTextStyle = {
    color: "var(--text-black, #222)",
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const IconContainerStyle = {
    display: "flex",
    minWidth: "24px",
    minHeight: "24px",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
};

export const ModalActionButtonTheme = (mode) =>
    createTheme({
        components: {
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        display: "flex",
                        padding: "10px 20px 11px 20px!important",
                        width: 139,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        borderRadius: "42px!important",
                        background: mode === 0 ? "var(--grey-separator, #EEEEF0)" : "var(--main-purple, #6212FF)",
                        "&:hover": {
                            background: "#6212FF!important",
                            borderColor: "#6212FF!important",
                            ".MuiTypography-root": {
                                color: "white!important",
                            },
                        },
                        "&:active": {
                            background: "#6212FF!important",
                            borderColor: "#6212FF!important",
                            ".MuiTypography-root": {
                                color: "white!important",
                            },
                        },
                    },
                },
            },
        },
    });

export const buttonTextStyle = (mode) => {
    return {
        textTransform: "none",
        color: mode === 0 ? "var(--text-black, #222)" : "var(--text-white, #FFF)",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "150%",
    };
};

export const actionsBlockContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "12px",
    width: "100%",
};

export const CloseIconTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid #9E9EB0",
                    borderRadius: "50%",
                    background: "transparent",
                    width: "24px",
                    height: "24px",
                    "&:hover": {
                        background: "#transparent!important",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "#transparent!important",
                        borderColor: "#6212FF",
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    padding: 0,
                    color: "#FFFFFF",
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    "&:hover": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                    "&:active": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                },
            },
        },
    },
});

export const priceTextStyle = (mode) => {
    return {
        color: "var(--text-black, #222)",
        textAlign: "center",
        fontSize: mode ? "14px" : "16px",
        fontStyle: "normal",
        fontWeight: mode ? "400" : "600",
        lineHeight: "150%",
    };
};

export const avatarContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "6px",
};

export const AvatarTheme = createTheme({
    components: {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    position: "relative",
                    width: "24px",
                    height: "24px",
                    left: "0px",
                    top: "0px",
                    cursor: "default",
                },
            },
        },
    },
});

export const studentTextStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};
