import React from "react";
import { observer } from "mobx-react-lite";

import STabs from "./STabs/STabs";

import * as styles from "pages/DiplomaChats/styles";
import DiplomaStore from "./store/DiplomaStore";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import Box from "@mui/material/Box";

const Tabs = ({ alignment, setAlignment, filesCount = undefined }) => {
    const tabsContent = [
        { id: 1, value: "ChatBodyBlock", title: "Чат" },
        { id: 2, value: "Files", title: "Файлы", count: filesCount },
        { id: 3, value: "Details", title: "Детали" },
    ];

    return (
        <Box sx={styles.tabsContainer}>
            <STabs
                content={DiplomaStore.status === "ended" ? tabsContent : tabsContent.slice(0, 2)}
                alignment={alignment}
                setAlignment={setAlignment}
            />
        </Box>
    );
};

export default observer(Tabs);
