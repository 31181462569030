import React, { memo } from "react";
import * as themes from "./ReviewCardThemesDefault";
import AvatarArea from "./AvatarArea/AvatarArea";
import InfoArea from "./InfoArea/InfoArea";
import Box from "@mui/material/Box";
import { useMedias } from "../../../API/mediasHook";

const ReviewCard = ({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={themes.reviewContainerStyle(medias)}>
            <AvatarArea
                value={props.value}
                tutor={props.tutor}
            ></AvatarArea>
            <InfoArea
                value={props.value}
                tutor={props.tutor}
            ></InfoArea>
        </Box>
    );
};

export default memo(ReviewCard);
