const SBPAsset = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="24"
            viewBox="0 0 42 24"
            fill="none"
        >
            <g clipPath="url(#clip0_1923_66240)">
                <path
                    d="M41.3328 9.30509V15.1727H39.2376V11.0567H37.2202V15.1727H35.125V9.30469H41.3328V9.30509Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.822 15.3826C32.6976 15.3826 34.0904 14.2326 34.0904 12.4892C34.0904 10.8018 33.0627 9.7062 31.3453 9.7062C30.5527 9.7062 29.8987 9.9852 29.4061 10.4666C29.5237 9.4716 30.3651 8.7452 31.2915 8.7452C31.5053 8.7452 33.115 8.7418 33.115 8.7418L34.0251 7C34.0251 7 32.0044 7.046 31.065 7.046C28.9186 7.0834 27.4688 9.0344 27.4688 11.404C27.4688 14.1646 28.8828 15.3826 30.822 15.3826ZM30.8333 11.249C31.5297 11.249 32.0125 11.7066 32.0125 12.489C32.0125 13.1932 31.5835 13.7734 30.8333 13.775C30.1157 13.775 29.6329 13.2374 29.6329 12.501C29.6329 11.7184 30.1157 11.249 30.8333 11.249Z"
                    fill="black"
                />
                <path
                    d="M25.7641 13.2096C25.7641 13.2096 25.2693 13.4948 24.5303 13.5488C23.6807 13.574 22.9237 13.0375 22.9237 12.084C22.9237 11.1541 23.5917 10.621 24.5089 10.621C25.0713 10.621 25.8153 11.0108 25.8153 11.0108C25.8153 11.0108 26.3597 10.0118 26.6417 9.51225C26.1253 9.12085 25.4375 8.90625 24.6375 8.90625C22.6185 8.90625 21.0547 10.2226 21.0547 12.0722C21.0547 13.9454 22.5245 15.2312 24.6375 15.1925C25.2281 15.1705 26.0429 14.963 26.5395 14.6441L25.7641 13.2096Z"
                    fill="black"
                />
                <path
                    d="M0 5.22656L2.9064 10.4216V13.5904L0.0034 18.7752L0 5.22656Z"
                    fill="#5B57A2"
                />
                <path
                    d="M11.1602 8.52596L13.8836 6.85676L19.4572 6.85156L11.1602 11.9344V8.52596Z"
                    fill="#D90751"
                />
                <path
                    d="M11.1439 5.1934L11.1593 12.0714L8.24609 10.2814V0L11.1439 5.1934Z"
                    fill="#FAB718"
                />
                <path
                    d="M19.4563 6.8542L13.8825 6.8594L11.1439 5.1934L8.24609 0L19.4563 6.8542Z"
                    fill="#ED6F26"
                />
                <path
                    d="M11.1593 18.8039V15.4669L8.24609 13.7109L8.24769 24.0025L11.1593 18.8039Z"
                    fill="#63B22F"
                />
                <path
                    d="M13.876 17.15L2.9062 10.4216L0 5.22656L19.4446 17.1432L13.876 17.15Z"
                    fill="#1487C9"
                />
                <path
                    d="M8.24609 24L11.1573 18.8014L13.8739 17.1474L19.4425 17.1406L8.24609 24Z"
                    fill="#017F36"
                />
                <path
                    d="M0.00390625 18.7692L8.27051 13.7052L5.49131 12L2.90691 13.5844L0.00390625 18.7692Z"
                    fill="#984995"
                />
            </g>
            <defs>
                <clipPath id="clip0_1923_66240">
                    <rect
                        width="42"
                        height="24"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SBPAsset;
