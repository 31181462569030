import * as React from "react";
import { Checkbox, ClickAwayListener, IconButton, Menu, Popover, Popper, Typography } from "@mui/material";
import { Emoji } from "emoji-picker-react";
import * as themes from "./OutcomingMessageCardThemes";
import { observer } from "mobx-react-lite";
import { memo, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import MessageAttachments from "./MessageAttachments/MessageAttachments";
import useWebSocket from "react-use-websocket";
import IconOK from "assets/ChatAssets/SVG/iconOK";
import ModalCarousel from "components/UI/ModalCarousel/ModalCarousel";
import FullChatStore from "store/FullChatStore";
import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";
import VideoChatStore from "store/VideoChatStore";
import MessageFiles from "./MessageFiles/MessageFiles";
import SButton from "components/UI/SButton";
import PurpleCheckBoxDefault from "../../../../assets/PaymentAssets/SVG/PurpleCheckBoxDefault";
import PurpleCheckBoxChecked from "../../../../assets/PaymentAssets/SVG/PurpleCheckBoxChecked";
import { DeleteCheckbox } from "./OutcomingMessageCardThemes";

const OutcomingMessageCard = observer(({ children, ...props }) => {
    const URL = "wss://study-buddy.ru/chats/ws";

    const { sendJsonMessage } = useWebSocket(URL, {
        share: true,
    });

    const [dialogAttachmentsCarouselOpen, SetDialogAttachmentsCarouselOpen] = useState(false);

    const [carouselContent, SetCarouselContent] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const [anchorElDel, setAnchorElDel] = useState(null);

    const openDelete = Boolean(anchorElDel);

    const showAttachmentsCarouselDialog = (event) => {
        event.stopPropagation();
        SetDialogAttachmentsCarouselOpen(true);
    };

    const discardAttachmentsCarouselDialog = () => {
        SetDialogAttachmentsCarouselOpen(false);
    };

    const iconList = ["1f44d", "2b50", "2705"];

    const handleMenuToggle = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleOpenDelete = (event) => {
        event.preventDefault();
        setAnchorElDel(anchorEl);
        setAnchorEl(null);
    };

    const handleDelClose = (event) => {
        setAnchorElDel(null);
    };

    const handleDelSend = (checked) => {
        let data = {
            chat_id: props.chat_id,
            message_id: props.message.message_id,
        };

        props.diploma || props.video ? DiplomaStore.DeleteMessageAction(data, sendJsonMessage, checked) : FullChatStore.DeleteMessageAction(data, sendJsonMessage, checked);
        setAnchorElDel(null);
    };

    const handleMenuClose = (value, event) => {
        if (value !== null) {
            if (value.icon) {
                let data = {
                    chat_id: props.chat_id,
                    message_id: props.message.message_id,
                };

                FullChatStore.AddReaction(value.icon, data, sendJsonMessage);
            } else {
                let data = {
                    chat_id: props.chat_id,
                    message_id: props.message.message_id,
                };

                let callback = null;

                switch (value.action.ID) {
                    case 0:
                        callback = handleEditCallback;
                        break;
                    case 1:
                        callback = handleReplyCallback;
                        break;
                    case 2:
                        callback = sendJsonMessage;
                        break;
                    case 3:
                        callback = sendJsonMessage;
                        break;
                    default:
                        break;
                }

                FullChatStore.ExecuteAction(value.action, data, callback);
            }
        }

        setAnchorEl(null);
    };

    const removeReaction = () => {
        return; //временно
        // eslint-disable-next-line no-unreachable
        let data = {
            chat_id: props.chat_id,
            message_id: props.message.message_id,
        };

        FullChatStore.RemoveReaction(data, sendJsonMessage);
    };

    const handleReplyCallback = () => {
        props.diploma ? DiplomaStore.SetSelectedMessage(props.message) : props.video ? VideoChatStore.SetSelectedMessage(props.message) : FullChatStore.SetSelectedMessage(props.message);
        props.handleText(props.message.message);
        props.handleDisable(true);
        props.handleOpenReplyModalMessage(true);
    };

    const handleEditCallback = () => {
        props.diploma ? DiplomaStore.SetSelectedMessage(props.message) : props.video ? VideoChatStore.SetSelectedMessage(props.message) : FullChatStore.SetSelectedMessage(props.message);
        props.handleText(props.message.message);
        props.handleOpenEditModalMessage(true);
    };

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (props.message.medias) {
            const processFunc = async () => {
                SetCarouselContent(await FullChatStore.ProcessMedias(props.message.medias));
            };

            processFunc();
        }

        return () => {
            SetCarouselContent([]);
        };
    }, [props.message.medias]);

    return (
        <Box sx={themes.outcomingMessageContainerStyle}>
            <Box
                sx={themes.messageContainerStyle}
                onContextMenu={handleMenuToggle}
            >
                {props.message.medias && (
                    <Box onClick={showAttachmentsCarouselDialog}>
                        <MessageAttachments message={props.message}></MessageAttachments>
                    </Box>
                )}
                {props.message.files && (
                    <Box>
                        <MessageFiles message={props.message}></MessageFiles>
                    </Box>
                )}
                <Typography
                    sx={themes.messageTextStyle}
                    component={"div"}
                >
                    {props.reply_to_message && (
                        <Box sx={themes.replyContainerStyle}>
                            <Typography sx={themes.nameTextStyle}>{props.message.owner.name}</Typography>
                            <Typography sx={themes.replyTextStyle}>{props.reply_to_message}</Typography>
                        </Box>
                    )}
                    {props.message.message}
                    <Box sx={themes.timeContainerStyle(props.message.reactions?.length > 0 && props.message.reactions[0]?.reaction)}>
                        {props.message.reactions?.length > 0 && props.message.reactions[0]?.reaction && (
                            <ThemeProvider theme={themes.IconTheme}>
                                <IconButton
                                    onClick={removeReaction}
                                    disableRipple
                                >
                                    <Emoji
                                        unified={props.message.reactions?.length > 0 && props.message.reactions[0]?.reaction}
                                        size="25"
                                    />
                                </IconButton>
                            </ThemeProvider>
                        )}
                        <Box sx={themes.timeAndIconContainerStyle}>
                            <Typography sx={themes.timeTextStyle}>{props.message.edited ? "изменено " + props.message.time : props.message.time}</Typography>
                            <IconOK color={props.message.read ? "rgba(98, 18, 255, 1)" : "rgba(158, 158, 176, 1)"} />
                        </Box>
                    </Box>
                </Typography>
            </Box>
            <ThemeProvider theme={themes.MenuTheme}>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose.bind(this, null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <Box sx={themes.menuBodyStyle}>
                        {props.message.owner.uuid && (
                            <Box sx={themes.reactionsContainerStyle}>
                                {iconList.map((icon) => (
                                    <MenuItem
                                        key={icon}
                                        onClick={handleMenuClose.bind(this, { icon: icon })}
                                        disableRipple
                                    >
                                        <Emoji
                                            unified={icon}
                                            size="25"
                                        />
                                    </MenuItem>
                                ))}
                            </Box>
                        )}
                        <Box sx={themes.actionsContainerStyle}>
                            <ThemeProvider theme={themes.MenuItemActionsTheme}>
                                {FullChatStore.messageActionsOptions.map(
                                    (value, index) =>
                                        !((props.diploma || props.video) && value.ID === 3) && (
                                            <MenuItem
                                                sx={themes.MenuItem}
                                                key={value.ID}
                                                onClick={value.ID === 2 ? (event) => handleOpenDelete(event) : handleMenuClose.bind(this, { action: value })}
                                                disableRipple
                                            >
                                                <Typography sx={themes.actionTextStyle}>{value.actionText}</Typography>
                                            </MenuItem>
                                        ),
                                )}
                            </ThemeProvider>
                        </Box>
                    </Box>
                </Menu>
            </ThemeProvider>
            <ThemeProvider theme={themes.PopoverTheme}>
                <Popover
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    anchorEl={anchorElDel}
                    open={openDelete}
                    onClose={handleDelClose}
                >
                    <Box sx={themes.DeleteOuterContainer}>
                        <Box sx={themes.DeleteContainer}>
                            <Checkbox
                                sx={themes.DeleteCheckbox}
                                disableRipple
                                icon={<PurpleCheckBoxDefault />}
                                checkedIcon={<PurpleCheckBoxChecked />}
                                checked={checked}
                                onClick={() => {
                                    setChecked(!checked);
                                }}
                            />
                            <Typography sx={themes.DeleteForAll}>Удалить у всех?</Typography>
                        </Box>
                        <Box sx={themes.DeleteButtons}>
                            <SButton
                                style={{ minWidth: 0 }}
                                variant="text"
                                onClick={() => {
                                    handleDelClose();
                                }}
                            >
                                Отмена
                            </SButton>
                            <SButton
                                variant="redText"
                                onClick={() => handleDelSend(checked)}
                            >
                                Удалить
                            </SButton>
                        </Box>
                    </Box>
                </Popover>
            </ThemeProvider>
            {props.message.medias && (
                <ModalCarousel
                    open={dialogAttachmentsCarouselOpen}
                    handleDiscard={discardAttachmentsCarouselDialog}
                    content={carouselContent}
                ></ModalCarousel>
            )}
        </Box>
    );
});

export default memo(OutcomingMessageCard);
