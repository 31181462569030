import { ThemeProvider } from "@mui/material/styles";
import { Checkbox, ClickAwayListener, Menu, Paper, Popper, Typography } from "@mui/material";
import OrdersResponsesStore from "store/OrdersResponsesStore";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import React, { useMemo, useState } from "react";
import IconPoints from "assets/ChatAssets/SVG/iconPoints";
import SButton from "components/UI/SButton";
import * as styles from "./PartnerChatMenuThemes";
import { useNavigate } from "react-router-dom";
import { MoreHorizOutlined } from "@mui/icons-material";
import FullChatStore from "store/FullChatStore";
import { findChatById } from "components/ChatBodyBlock/CommonData/helper_functions";
import ComplainModal from "components/MiniChat/OneChat/ComplainModal/ComplainModal";
import { observer } from "mobx-react-lite";

const PartnerChatMenu = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const [anchorElDel, setAnchorElDel] = useState(null);

    const open = Boolean(anchorEl);

    const opendel = Boolean(anchorElDel);

    const UpdateChat = useMemo(() => {
        return FullChatStore.id ? findChatById(FullChatStore.tabsData.chats, FullChatStore.id) : null;
    }, [FullChatStore.tabsData, FullChatStore.change, FullChatStore.id]);

    const handleMenuToggle = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const changeArchive = () => {
        UpdateChat.folders.includes(2) ? FullChatStore.СhangeArchive(props.sendJsonMessage, true) : FullChatStore.СhangeArchive(props.sendJsonMessage, false);
    };

    const changeMute = () => {
        FullChatStore.СhangeMute(props.sendJsonMessage, !UpdateChat.disabled_notifications);
    };

    const handleMenuClose = (value, event) => {
        if (value !== null) {
            OrdersResponsesStore.SetSortBy(value);
            OrdersResponsesStore.SortOrders();
        }

        setAnchorEl(null);
        setAnchorElDel(null);
    };

    const [checked, setChecked] = useState(false);

    const [openmodal, setOpenModal] = useState(false);

    const delChat = (all) => {
        FullChatStore.DelChat(props.sendJsonMessage, all);
        setAnchorElDel(null);
        FullChatStore.SetChatID("");
    };

    const navigate = useNavigate();

    const menuItems = [
        {
            text: "Перейти в профиль",
            type: "element",
            onClick: () => {
                navigate("/tutor/64902a483594e963dd20818a");
            },
        },
        {
            text: "Смотреть файлы и ссылки",
            type: "element",
            onClick: () => {
                FullChatStore.SetMediaPage(true);
            },
        },
        { text: "", type: "divider", onClick: null },
        {
            text: UpdateChat && UpdateChat.disabled_notifications ? "Включить уведомления" : "Отключить уведомления",
            type: "element",
            onClick: () => {
                changeMute();
            },
        },
        {
            text: UpdateChat && UpdateChat.folders.includes(2) ? "Убрать из архива" : "Перенести в архив",
            type: "element",
            onClick: () => {
                changeArchive();
            },
        },
        { text: "", type: "divider", onClick: null },
        {
            text: "Удалить чат",
            type: "element",
            onClick: () => {
                var el = anchorEl;

                setAnchorEl(null);
                setAnchorElDel(anchorEl);
            },
        },
        { text: "", type: "divider", onClick: null },
        {
            text: UpdateChat && UpdateChat.folders.includes(3) ? "Разблокировать специалиста" : "Заблокировать специалиста",
            type: "element",
            onClick: () => {
                props.handleOpenBlock();
                setAnchorEl(null);
            },
        },
        {
            text: "Пожаловаться",
            type: "element",
            onClick: () => {
                setOpenModal(true);
                setAnchorEl(null);
            },
        },
    ];

    return (
        <Box>
            <Popper
                open={opendel}
                anchorEl={anchorElDel}
                placement={"bottom-start"}
            >
                <Paper sx={styles.PaperStyles}>
                    <ClickAwayListener
                        onClickAway={() => {
                            setAnchorElDel(null);
                        }}
                    >
                        <Box
                            sx={{
                                padding: "8px",
                                fontSize: "14px",
                            }}
                        >
                            <Box sx={{ textAlign: "center" }}>
                                <Checkbox
                                    checked={checked}
                                    onClick={() => setChecked(!checked)}
                                />{" "}
                                Удалить у всех?
                            </Box>
                            <Box sx={{ textAlign: "center" }}>
                                <Box sx={{ display: "inline-block" }}>
                                    <SButton
                                        variant="text"
                                        onClick={() => delChat(checked)}
                                    >
                                        Продолжить
                                    </SButton>
                                </Box>
                                <Box sx={{ display: "inline-block" }}>
                                    <SButton
                                        variant="text"
                                        onClick={() => {
                                            setAnchorEl(anchorElDel);
                                            setAnchorElDel(null);
                                        }}
                                    >
                                        Назад
                                    </SButton>
                                </Box>
                            </Box>
                        </Box>
                    </ClickAwayListener>
                </Paper>
            </Popper>
            <ComplainModal
                open={openmodal}
                handleClose={() => setOpenModal(false)}
                sendJsonMessage={props.sendJsonMessage}
            />
            <SButton
                variant="icon"
                onClick={(e) => handleMenuToggle(e)}
            >
                <MoreHorizOutlined />
            </SButton>
            <ThemeProvider theme={styles.MenuTheme}>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose.bind(this, null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    {menuItems.map((value, index) =>
                        value.type === "element" ? (
                            <MenuItem
                                key={index}
                                disableRipple
                                onClick={value.onClick}
                            >
                                <Typography sx={styles.MenuText(value.text)}>{value.text}</Typography>
                            </MenuItem>
                        ) : (
                            <Divider
                                key={index}
                                sx={styles.dividerStyle(0, 0)}
                            ></Divider>
                        ),
                    )}
                </Menu>
            </ThemeProvider>
        </Box>
    );
};

export default observer(PartnerChatMenu);
