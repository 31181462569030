const MirPaymentIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="24"
            viewBox="0 0 32 24"
            fill="none"
        >
            <path
                d="M26.7578 8H21.6602C21.9571 9.64553 23.5903 11.1943 25.372 11.1943H29.4303C29.4798 11.0491 29.4798 10.8071 29.4798 10.6619C29.4798 9.20995 28.2425 8 26.7578 8Z"
                fill="url(#paint0_linear_1919_64512)"
            />
            <path
                d="M22.1055 11.4844V15.5014H24.58V13.3719H26.7577C27.9455 13.3719 28.9848 12.5491 29.3312 11.4844H22.1055Z"
                fill="#4FAD50"
            />
            <path
                d="M13.3945 8V15.4533H15.5722C15.5722 15.4533 16.1166 15.4533 16.4135 14.9209C17.8983 12.0654 18.3437 11.1943 18.3437 11.1943H18.6406V15.4533H21.1152V8H18.9376C18.9376 8 18.3932 8.0484 18.0962 8.53238C16.8589 11.0007 16.1661 12.259 16.1661 12.259H15.8691V8H13.3945Z"
                fill="#4FAD50"
            />
            <path
                d="M3 15.5002V8.04688H5.47458C5.47458 8.04688 6.16747 8.04687 6.5634 9.11163C7.55323 11.9187 7.65222 12.3059 7.65222 12.3059C7.65222 12.3059 7.85018 11.6283 8.74103 9.11163C9.13696 8.04687 9.82985 8.04688 9.82985 8.04688H12.3044V15.5002H9.82985V11.4831H9.5329L8.14714 15.5002H7.05832L5.67256 11.4831H5.37561V15.5002H3Z"
                fill="#4FAD50"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1919_64512"
                    x1="21.6513"
                    y1="9.62003"
                    x2="29.4623"
                    y2="9.62003"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#27B1E6" />
                    <stop
                        offset="1"
                        stopColor="#148ACA"
                    />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default MirPaymentIcon;
