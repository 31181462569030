import { useEffect, useState } from "react";

import FullChatStore from "store/FullChatStore";

export const useGetChatAttachments = (type = "videos") => {
    const [attachments, setAttachments] = useState([]);

    useEffect(() => {
        function sortAttachmentsByMonthYear() {
            if (FullChatStore.selectedChatAttachments[type]) {
                let reversedAttachments = FullChatStore.selectedChatAttachments[type].map((el, index, array) => array[array.length - index - 1]);

                const monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

                const groupedMessagesMedia = {};

                const today = new Date();

                const todayMonth = today.getMonth();

                const todayYear = today.getFullYear();

                reversedAttachments.forEach((message) => {
                    const date = new Date(message.created_at);

                    const year = date.getFullYear();

                    const month = date.getMonth();

                    let key = null;

                    if (month === todayMonth && year === todayYear) {
                        key = `В этом месяце`;
                    } else if (month !== todayMonth && year === todayYear) {
                        key = `${monthNames[month]}`;
                    } else {
                        key = `${monthNames[month]} ${year}`;
                    }

                    if (!groupedMessagesMedia[key]) {
                        groupedMessagesMedia[key] = [];
                    }

                    groupedMessagesMedia[key].push({ link: message.link, date: date, ...message });
                });

                const orderedGroupedMessagesTemp = Object.keys(groupedMessagesMedia)
                    .sort((a, b) => b - a)
                    .reduce((obj, key) => {
                        obj[key] = groupedMessagesMedia[key];
                        return obj;
                    }, {});

                setAttachments(orderedGroupedMessagesTemp);
            }
        }

        sortAttachmentsByMonthYear();
    }, [FullChatStore.selectedChatAttachments[type]]);

    return attachments;
};
