import { memo } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./CurrentPrivilegesThemes";
import * as textThemes from "themes/TextThemes";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import PurplePrivilegeIcon from "assets/CommonAssets/SVG/PurplePrivelegeIcon";
import { useMedias } from "API/mediasHook";

const CurrentPrivileges = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.WhiteContainer(medias)}>
            <Typography sx={textThemes.HeadlineH5Black}>{`${t("teacherAccount.privilegeProgram.availablePrivilegesFor")}${t(TeacherQuestionnaireStore.monthNames[new Date().getMonth()])}`}</Typography>
            <Box sx={themes.PrivilegesBlock}>
                {TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.currentMonth).privileges.map((privilege, index) => {
                    return (
                        <Box
                            sx={themes.PrivilegeBlock}
                            key={index}
                        >
                            <Box sx={themes.IconBlock}>
                                <PurplePrivilegeIcon />
                            </Box>
                            <Typography sx={textThemes.Body1Black}>{t(privilege)}</Typography>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
});

export default memo(CurrentPrivileges);
