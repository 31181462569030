export const CardsContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const CardContainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid #DADADF",
    borderRadius: "8px",
    padding: "11px 16px 12px 16px",
    cursor: "pointer",
};

export const Radio = {
    margin: 0,
    padding: 0,
};

export const RadioCards = {
    display: "flex",
    gap: "16px",
    alignItems: "center",
};
