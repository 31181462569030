import React, { useState } from "react";
import { TextField, InputAdornment, ThemeProvider, Typography, IconButton } from "@mui/material";
import * as themes from "./PaidServicesBodyBlockThemes";
import * as textThemes from "themes/TextThemes";
import SButton from "components/UI/SButton";
import DownloadIcon from "assets/CommonAssets/SVG/downloadIcon";
import SearchIcon from "@mui/icons-material/Search";
import SummaryIndicators from "./SummaryIndicators/SummaryIndicators";
import TableHeader from "./TableHeader/TableHeader";
import TeacherPaidServicesStore from "store/TeacherPaidServicesStore";
import ServiceItem from "./ServiceItem/ServiceItem";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import PeriodFilter from "./PeriodFilter/PeriodFilter";
import DatepickersBlock from "./DatepickersBlock/DatepickersBlock";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import { useMedias } from "API/mediasHook";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

const PaidServicesBodyBlock = observer(({ children, ...props }) => {
    const [searchVisible, setSearchVisible] = useState(false);

    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const handleChange = (e) => {
        TeacherPaidServicesStore.setStudentName(e.target.value);
        console.log(TeacherPaidServicesStore.studentName);
        TeacherPaidServicesStore.PaidServicesUpdate();
    };

    const getFormattedDate = (date) => {
        const dateObject = TeacherPaidServicesStore.formatDate(date);

        return `${dateObject.day} ${t(dateObject.month)}, ${t(dateObject.dayOfWeek)}`;
    };

    const handleToggleSearch = () => {
        setSearchVisible((prev) => !prev);
    };

    return (
        <Box sx={themes.PaidServices(medias)}>
            <Box sx={themes.PaidServicesContent}>
                <Box sx={themes.TitleBlock}>
                    <Typography sx={medias.sm ? textThemes.HeadlineH3Black : textThemes.HeadlineH41Black}>{t("paidServices.paidServices")}</Typography>
                    <SButton
                        variant="unshadowed"
                        endIcon={medias.sm ? <DownloadIcon /> : null}
                        style={themes.pdfButton(medias)}
                        disabled={Object.keys(TeacherPaidServicesStore.groupedServices).length === 0}
                    >
                        {medias.sm ? t("paidServices.downloadPDF") : <DownloadIcon />}
                    </SButton>
                </Box>
                <Box sx={themes.Table}>
                    <Box sx={themes.HeaderBorderBottom(TeacherPaidServicesStore.activeFilterOption, medias)}>
                        <Box sx={themes.PeriodCalendar}>
                            <Box sx={themes.Filter(medias)}>
                                <Box sx={themes.PeriodFilter}>
                                    <Typography sx={textThemes.Body2Black}>{medias.sm ? t("paidServices.showReport") : t("paidServices.report")}</Typography>
                                    <PeriodFilter />
                                </Box>
                                {TeacherPaidServicesStore.activeFilterOption === 5 && <DatepickersBlock />}
                            </Box>
                        </Box>
                        <Box style={themes.TextFieldWrapper(medias, searchVisible)}>
                            {(searchVisible || medias.sm) && (
                                <ThemeProvider theme={themes.FiltersSearcherTheme}>
                                    <TextField
                                        onChange={handleChange}
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                        placeholder={t("paidServices.findStudent")}
                                        sx={themes.TextFieldStyle(searchVisible, medias)}
                                    />
                                </ThemeProvider>
                            )}
                            {!medias.sm && !searchVisible ? (
                                <IconButton
                                    disableRipple
                                    onClick={handleToggleSearch}
                                    sx={themes.TextFieldIconButton}
                                >
                                    <SearchIcon sx={{ width: "20px" }} />
                                </IconButton>
                            ) : (
                                <>
                                    {!medias.sm && (
                                        <IconButton
                                            disableRipple
                                            onClick={handleToggleSearch}
                                            sx={themes.TextFieldIconButton}
                                        >
                                            <HighlightOffOutlinedIcon sx={{ width: "24px", marginTop: "-4px" }} />
                                        </IconButton>
                                    )}
                                </>
                            )}
                        </Box>
                    </Box>
                    <SummaryIndicators />
                    {medias.sm && <TableHeader />}
                    {Object.keys(TeacherPaidServicesStore.groupedServices).length > 0 ? (
                        <Box sx={themes.SearchedServices(medias)}>
                            {Object.keys(TeacherPaidServicesStore.groupedServices).map((dateItem, index) => {
                                return (
                                    <Box
                                        key={index}
                                        sx={themes.DateBlock}
                                    >
                                        <Box sx={themes.DateContainer}>
                                            <Typography sx={textThemes.Button14BoldBlack}>{getFormattedDate(dateItem)}</Typography>
                                        </Box>
                                        <Box sx={themes.DetailsAndTotalsBlock}>
                                            <Box sx={themes.ServicesDetailsContainer}>
                                                {TeacherPaidServicesStore.groupedServices[dateItem].map((serviceItem, index) => {
                                                    return (
                                                        <ServiceItem
                                                            item={serviceItem}
                                                            itemNumber={index}
                                                            key={serviceItem.order.id}
                                                        />
                                                    );
                                                })}
                                            </Box>
                                            <Box sx={themes.TotalRow}>
                                                <Box sx={themes.LeftTotalBlock}>
                                                    {medias.sm && (
                                                        <Box sx={themes.TotalBlock}>
                                                            <Typography sx={textThemes.Button16BoldBlack}>{t("paidServices.total")}</Typography>
                                                        </Box>
                                                    )}
                                                    <Typography
                                                        sx={textThemes.Button16BoldBlack}
                                                    >{`${TeacherPaidServicesStore.groupedServices[dateItem].length} ${t(TeacherPaidServicesStore.getServiceWord(TeacherPaidServicesStore.groupedServices[dateItem].length))}`}</Typography>
                                                </Box>
                                                <Box sx={themes.TotalSumBlock(medias)}>
                                                    <Typography sx={textThemes.Button16BoldBlack}>
                                                        {TeacherPaidServicesStore.groupedServices[dateItem].reduce((acc, object) => acc + object.cost.total, 0).toLocaleString("ru-RU")} ₽
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    ) : (
                        <Box sx={themes.EmptyDetails(medias)}>
                            <Typography sx={textThemes.HeadlineH5Black}>{t("paidServices.emptyPaidServicesTitle")}</Typography>
                            <Typography sx={textThemes.Body2Black}>{t("paidServices.emptyPaidServicesText")}</Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
});

export default memo(PaidServicesBodyBlock);
