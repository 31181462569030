const ButtonArrowIcon = function (props) {
    return (
        <svg
            width="15"
            height="13"
            viewBox="0 0 17 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 7.5H16M16 7.5L9 1M16 7.5L9 14"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ButtonArrowIcon;
