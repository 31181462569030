import { Box, Typography } from "@mui/material";
import * as themes from "./DatepickersBlockThemes";
import Datepicker from "components/UI/CustomDatePicker/CustomDatePicker";
import React from "react";
import { memo } from "react";
import { observer } from "mobx-react-lite";
import TeacherPaidServicesStore from "store/TeacherPaidServicesStore";

const DatepickersBlock = observer(({ children, ...props }) => {
    return (
        <Box sx={themes.PeriodButton}>
            <Datepicker
                borderMode={true}
                inputFontSize="14px"
                selectedDate={TeacherPaidServicesStore.periodStartDate}
                setSelectedDate={TeacherPaidServicesStore.setPeriodStartDate}
                minDate={new Date()}
                noMin={true}
                styles={themes.DatePicker}
            />
            <Typography sx={themes.HyphenPos}>-</Typography>
            <Datepicker
                borderMode={true}
                inputFontSize="14px"
                selectedDate={TeacherPaidServicesStore.periodEndDate}
                setSelectedDate={TeacherPaidServicesStore.setPeriodEndDate}
                minDate={new Date()}
                noMin={true}
                styles={themes.DatePicker}
            />
        </Box>
    );
});

export default memo(DatepickersBlock);
