import * as React from "react";
import { observer } from "mobx-react-lite";

import { Stack } from "@mui/material";

import { useDraggable } from "react-use-draggable-scroll";

import "swiper/css/pagination";

import "./styles.module.css";
import "./swiper.min.module.css";
import { memo } from "react";

const TabsChat = observer(({ children, ...props }) => {
    const refScrollEllemnt = React.useRef(null);

    const { events } = useDraggable(refScrollEllemnt);

    const [indexTab, setIndexTab] = React.useState(0);

    return (
        <Stack
            sx={{
                borderTop: "1px solid #DADADF",
                borderBottom: "1px solid #DADADF",
                overflowX: "hidden",
            }}
            pl={2.5}
            pt="13px"
            ref={refScrollEllemnt}
            {...events}
        >
            <Stack
                sx={{
                    position: "relative",
                }}
                direction="row"
                spacing={2}
            >
                {props.folders?.map((value, index) => {
                    return (
                        <Stack
                            key={index}
                            sx={{
                                fontSize: "20px",
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                                "::after": index === indexTab && {
                                    border: "1px solid #6212FF",
                                    content: '""',
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    borderRadius: "2px",
                                },
                            }}
                            onClick={(e) => {
                                setIndexTab(index);
                                const scrollTop = e.currentTarget.offsetLeft - refScrollEllemnt.current.clientWidth / 2 + e.currentTarget.clientWidth / 2;

                                refScrollEllemnt.current.scrollTo({
                                    top: 0,
                                    left: scrollTop,
                                    behavior: "smooth",
                                });
                                console.log(index);
                                props.pageChange(index);
                            }}
                        >
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={"0.8vh"}
                                mb={"1.6vh"}
                            >
                                <Stack
                                    sx={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "1.9vh",
                                        lineHeight: "19.6px",
                                        whiteSpace: "nowrap",
                                        cursor: "pointer",
                                        color: index === indexTab ? "#6212FF" : "",
                                    }}
                                >
                                    {value.name}
                                </Stack>
                                {value.unread_messages > 0 && (
                                    <Stack
                                        sx={{
                                            backgroundColor: "#6212FF",
                                            borderRadius: "999px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "2px 6px",

                                            fontSize: "1.6vh",
                                            color: "white",
                                            fontWeight: 500,
                                            lineHeight: "130%",
                                            fontStyle: "normal",
                                            textAlign: "center",
                                        }}
                                    >
                                        {value.unread_messages}
                                    </Stack>
                                )}
                            </Stack>
                        </Stack>
                    );
                })}
                {/* TODO: try to do a padding without this shit */}
                <Stack
                    children="&nbsp;"
                    color="#FFF"
                />
            </Stack>
        </Stack>
    );
});

export default memo(TabsChat);
