export const IconPin = () => {
    return (
        <svg
            width="18px"
            height="18px"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.24136 9.67012L6.34808 12.7768L4.16017 14.9647C3.91565 15.2093 3.91565 15.6057 4.16017 15.8502C4.40468 16.0947 4.80112 16.0947 5.04563 15.8502L7.23354 13.6623L10.3274 16.7562C10.4432 16.8733 10.5827 16.9643 10.7367 17.0229C10.8916 17.082 11.0575 17.1069 11.2229 17.0959C11.3884 17.085 11.5495 17.0385 11.6954 16.9596C11.8409 16.8808 11.9677 16.7716 12.0672 16.6395C12.5899 15.9487 13.5606 14.3637 12.9715 12.6594L16.7703 8.86059C16.8782 8.75381 16.9639 8.62677 17.0225 8.48675C17.0815 8.34581 17.1119 8.19454 17.1119 8.04174C17.1119 7.88894 17.0815 7.73766 17.0225 7.59672C16.9639 7.4567 16.8779 7.32942 16.7701 7.22263L12.7875 3.24003C12.6807 3.13218 12.5536 3.04647 12.4136 2.98784C12.2727 2.92883 12.1214 2.89844 11.9686 2.89844C11.8158 2.89844 11.6645 2.92883 11.5236 2.98784C11.3836 3.04648 11.2562 3.13246 11.1495 3.24033L7.33645 7.05335C7.09672 6.98262 6.76576 6.9111 6.36952 6.89341C5.5362 6.8562 4.43725 7.05966 3.3278 7.95618C3.20193 8.05804 3.09894 8.18529 3.02555 8.32963C2.95215 8.47396 2.91001 8.63215 2.90185 8.79387C2.8937 8.95559 2.91972 9.11721 2.97822 9.26819C3.03656 9.41874 3.12594 9.5554 3.24044 9.66921L3.24136 9.67012ZM11.1356 15.7934L4.20297 8.86079C5.01356 8.23927 5.76752 8.12002 6.31366 8.1444C6.60208 8.15728 6.83872 8.21086 6.99975 8.25969C7.07995 8.28401 7.14031 8.30682 7.17766 8.32208C7.19629 8.3297 7.20907 8.33538 7.21559 8.33835L7.22052 8.34065C7.46077 8.45869 7.74977 8.41096 7.93932 8.22141L11.9686 4.19213L15.8182 8.04174L11.7889 12.071C11.5981 12.2619 11.551 12.5535 11.6721 12.7947C11.9612 13.3703 11.9457 13.963 11.7891 14.5042C11.6453 15.0013 11.3834 15.4508 11.1356 15.7934Z"
                fill="#9E9EB0"
            />
        </svg>
    );
};
