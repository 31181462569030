import * as themes from "components/TeacherQuestionnaireBodyBlock/Themes/ModalCropPhotoThemes";
import { ThemeProvider } from "@mui/material/styles";
import { Dialog, IconButton } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as tThemes from "themes/TextThemes";
import ImageCropperMini from "components/TeacherQuestionnaireBodyBlock/Components/CropperMini";
import SButton from "components/UI/SButton";
import UserStore from "store/UserStore";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

const CropPhotoModal = ({ dialogOpen, handleDialogClose, imageSrc, setDialogOpen, croppedImageMini, setCroppedImageMini }) => {
    const { t, i18n } = useTranslation();

    const [checker, setChecker] = useState(1);

    useEffect(() => {
        handleSaveMiniComp();
    }, [checker]);

    const [cropperMini, setCropperMini] = useState();

    const handleSaveMiniComp = () => {
        if (cropperMini && typeof cropperMini.getCroppedCanvas() !== "undefined") {
            const croppedImgMini = cropperMini.getCroppedCanvas().toDataURL();

            setCroppedImageMini(croppedImgMini);
        }
    };

    const setUploadingData = (value) => {
        UserStore.setPhoto(value);
    };

    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                maxWidth={"none"}
            >
                <Box sx={themes.dialogContainerStyle}>
                    <ThemeProvider theme={themes.CloseIconTheme}>
                        <IconButton onClick={() => setDialogOpen(false)}>
                            <CloseRounded></CloseRounded>
                        </IconButton>
                    </ThemeProvider>
                    <Box sx={themes.cardContainerStyle}>
                        <Box sx={themes.dialogContainerHeaderStyle}>
                            <Box sx={themes.dialogContainerHeaderInsideStyle}>
                                <Typography sx={tThemes.HeadlineH5Black}>{t("main.settings.profile.thumbnailSelection")}</Typography>
                            </Box>
                        </Box>
                        <Box sx={themes.dialogContainerBodyStyle}>
                            <Box sx={themes.dialogContainerBodyInsideStyle}>
                                <Typography sx={tThemes.Body2Black}>{t("main.settings.profile.thumbnailDescription")}</Typography>
                                <Box sx={themes.dialogContainerMiniCropper}>
                                    <ImageCropperMini
                                        handleSaveMiniComp={handleSaveMiniComp}
                                        imageSrc={imageSrc}
                                        setCropper={setCropperMini}
                                        height={292}
                                        width={292}
                                        checker={checker}
                                        setChecker={setChecker}
                                    />
                                    <Box sx={themes.dialogContainerMiniCropperMiniatures}>
                                        <img
                                            height={"170px"}
                                            width={"160px"}
                                            className="TeacherQuestionnaireModalBigPhoto"
                                            alt="not found"
                                            src={croppedImageMini === "" ? imageSrc : croppedImageMini}
                                            style={{ borderRadius: "16px" }}
                                        />
                                        <img
                                            height={"80px"}
                                            width={"80px"}
                                            className="TeacherQuestionnaireModalBigPhoto"
                                            alt="not found"
                                            src={croppedImageMini === "" ? imageSrc : croppedImageMini}
                                            style={{ borderRadius: "80px" }}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={themes.cardButtonsGroupStyle}>
                                    <SButton
                                        variant="unshadowed"
                                        padding="10px 20px 11px 20px"
                                        onClick={() => {
                                            setUploadingData(croppedImageMini);
                                            setDialogOpen(false);
                                        }}
                                    >
                                        {t("main.done")}
                                    </SButton>
                                    <SButton
                                        variant="secondary"
                                        padding="10px 20px 11px 20px"
                                        onClick={() => setDialogOpen(false)}
                                    >
                                        {t("main.cancel")}
                                    </SButton>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </ThemeProvider>
    );
};

export default observer(CropPhotoModal);
