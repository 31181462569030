import { memo } from "react";
import BalanceBodyBlock from "components/BalanceBodyBlock/BalanceBodyBlock";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

const BalancePage = ({ children, ...props }) => {
    return (
        <ScrollToTop>
            <BalanceBodyBlock />
        </ScrollToTop>
    );
};

export default memo(BalancePage);
