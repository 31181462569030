import { createTheme } from "@mui/material/styles";

export const ServiceBlock = {
    display: "flex",
    gap: "12px",
};

export const ServiceButton = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "16px!important",
                    //marginBottom: '20px',
                    gap: "10px",
                    width: "fit-content",
                    minHeight: "47px",
                    border: "1px solid #BBBBC8",

                    //background: '#FFF',
                    borderRadius: "42px!important",
                    "&:hover": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                        ".MuiTypography-root": {
                            color: "white!important",
                        },
                    },
                    "&:active": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                        ".MuiTypography-root": {
                            color: "white!important",
                        },
                    },
                },
            },
        },
    },
});

export const ServiceButtonText = {
    textTransform: "none",
};
