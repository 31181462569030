import AuthorizationStore from "store/AuthorizationStore";
import { getCookie } from "API/utils";

export const md1 = () => 1 && true && AuthorizationStore.signUpRole === "student";

export const md2 = () => 2 && true;

export const md3 = () => 3 && true;

export const checkTutorAccessToken = () => getCookie("tutorAccessToken");

const middlewares = {
    md1,
    md2,
    md3,
    checkTutorAccessToken,
};

const globalMiddlewares = [md1, md3];

export { middlewares, globalMiddlewares };
