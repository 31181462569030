import { memo } from "react";
import * as styles from "./SBPQRMethodThemes";
import * as textStyles from "themes/TextThemes";
import PaymentStore from "store/PaymentStore";
import { Box, Radio, Typography } from "@mui/material";
import PurpleRadioDefault from "assets/PaymentAssets/SVG/PurpleRadioDefault";
import PurpleRadioChecked from "assets/PaymentAssets/SVG/PurpleRadioChecked";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import SBPAsset from "assets/PaymentAssets/SVG/SBPAsset";

const SBPQRMethod = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Box
            sx={styles.QrCodeContainer}
            onClick={() => PaymentStore.changePaymentMethod("sbp")}
        >
            <Box sx={styles.QrCodeRadio}>
                <Radio
                    icon={<PurpleRadioDefault />}
                    checkedIcon={<PurpleRadioChecked />}
                    disableRipple
                    sx={styles.Radio}
                    checked={PaymentStore.selectedPaymentMethod === "sbp"}
                />
                <Box sx={styles.QrCodeTitle}>
                    <Typography sx={textStyles.Body2Black}>QR-код</Typography>
                    <Box sx={styles.PercentageContainer}>
                        <Typography sx={textStyles.Caption11White}>-3%</Typography>
                    </Box>
                </Box>
            </Box>
            <SBPAsset />
        </Box>
    );
});

export default memo(SBPQRMethod);
