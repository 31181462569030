import { Grid, IconButton } from "@mui/material";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import * as Styles from "./SearchStyles";
import IconDown from "assets/MiniChat/SVG/IconDown";
import IconUp from "assets/MiniChat/SVG/IconUp";
import FullChatStore from "store/FullChatStore";
import { useEffect } from "react";

const SearchMessagesResult = () => {
    const changeMessage = (up) => {
        if (up) {
            let newMes = currMessage + 1;

            let newIndex = index + 1;

            setCurMessage(newMes);
            setIndex(newIndex);
            FullChatStore.SetToScrollMessage(FullChatStore.foundMessages[newIndex].message_id);
        } else {
            let newMes = currMessage - 1;

            let newIndex = index - 1;

            setCurMessage(newMes);
            setIndex(newIndex);
            FullChatStore.SetToScrollMessage(FullChatStore.foundMessages[newIndex].message_id);
        }
    };

    const [index, setIndex] = useState(0);

    const [currMessage, setCurMessage] = useState(0);

    const [sumMessage, setSumMessage] = useState(0);

    useEffect(() => {
        if (FullChatStore.foundMessages !== null) {
            if (FullChatStore.foundMessages.length > 0) {
                FullChatStore.SetToScrollMessage(FullChatStore.foundMessages[0].message_id);
                setIndex(0);
            }
        }

        setCurMessage(FullChatStore.foundMessages !== null ? (FullChatStore.foundMessages.length > 0 ? 1 : 0) : 0);
        setSumMessage(FullChatStore.foundMessages !== null ? FullChatStore.foundMessages.length : 0);
    }, [FullChatStore.foundMessages]);

    return (
        <Grid
            sx={Styles.Main}
            container
        >
            <Grid
                item
                xs={9}
                sx={Styles.Number}
            >
                {currMessage}/{sumMessage}
            </Grid>
            <Grid
                item
                xs={1.5}
                sx={{ paddingTop: "1.4vh" }}
            >
                <IconButton
                    disabled={currMessage === sumMessage}
                    onClick={() => changeMessage(true)}
                >
                    <IconUp disabled={currMessage === sumMessage} />
                </IconButton>
            </Grid>
            <Grid
                item
                xs={1.5}
                sx={{ paddingTop: "1.4vh" }}
            >
                <IconButton
                    disabled={currMessage <= 1}
                    onClick={() => changeMessage(false)}
                >
                    <IconDown disabled={currMessage <= 1} />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default observer(SearchMessagesResult);
