import React from "react";
import OrderCardWrapper from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponsCard/OrderCardWrapper";
import { Avatar, AvatarGroup, Box } from "@mui/material";
import * as zThemes from "./ZeroCardStyled";
import * as themes from "./ZeroOffersCardStyled";
import { zaglushka } from "./ZaglushkaAvatar";
import OrderResponseButton from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseButton/OrderResponseButton";

const ZeroOffersCard = () => {
    let specialistCount = 2000;

    const pluraliz = (number, one, few, many) => {
        const lastTwoDigits = number % 100;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
            return many;
        }

        const lastDigit = number % 10;

        if (lastDigit === 1) {
            return one;
        }

        if (lastDigit >= 2 && lastDigit <= 4) {
            return few;
        }

        return many;
    };

    return (
        <OrderCardWrapper>
            <Box sx={themes.ZeroOffersCardTopWrapper}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "26px",
                        maxWidth: "610px",
                        width: "100%",
                    }}
                >
                    <zThemes.ZeroCardsTitleStyled>Здесь будут твои предложения для специалистов</zThemes.ZeroCardsTitleStyled>
                    <zThemes.ZeroResponseCardSubtitleStyled>
                        Мы уже подобрали для тебя список специалистов с подходящим опытом. Переходи, изучай информацию и предлагай заказ!
                    </zThemes.ZeroResponseCardSubtitleStyled>
                </Box>
                <Box sx={themes.ZeroOffersCardRightSideWrapper}>
                    <AvatarGroup
                        max={7}
                        spacing="small"
                        sx={themes.ZeroOffersCardAvatarGroup}
                    >
                        {zaglushka.slice(0, 7).map((el, index) => (
                            <Avatar
                                key={index}
                                alt="ddd"
                                src={el}
                            />
                        ))}
                    </AvatarGroup>
                    <themes.SpecialistCountStyled>
                        {" "}
                        {">" + specialistCount + " "}
                        {pluraliz(specialistCount, "специалист", "специалиста", "специалистов")} по твоему заказу
                    </themes.SpecialistCountStyled>
                </Box>
            </Box>
            <Box sx={themes.ZeroOffersCardBottomWrapper}>
                <OrderResponseButton
                    text="Перейти в каталог"
                    handleClick={console.log("Каталог")}
                    variant="contained"
                />
            </Box>
        </OrderCardWrapper>
    );
};

export default ZeroOffersCard;
