import { createTheme } from "@mui/material/styles";

export const incomingMessageContainerStyle = {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    margin: "8px 15px 8px 30px",
    whiteSpace: "break-spaces",
};

export const avatarContainerStyle = {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
};

export const AvatarTheme = createTheme({
    components: {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    position: "relative",
                    width: "40px",
                    height: "40px",
                    left: "0px",
                    top: "0px",
                    borderRadius: "50%",
                    cursor: "default",
                },
            },
        },
    },
});

export const messageContainerStyle = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#BBBBC826",
    borderRadius: "15px",
    padding: "8px 8px 8px 8px",
    minWidth: 60,
    maxWidth: 300,
};

export const messageTextStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "135%",
    wordBreak: "break-word",
};

export const replyContainerStyle = {
    display: "flex",
    flexDirection: "column",
    borderLeft: "2px solid rgba(98, 18, 255, 1)",
    padding: "4px 10px",
};

export const nameTextStyle = {
    color: "#6212FF",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
};

export const replyTextStyle = {
    color: "var(--text-black, #222)",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
};

export const timeContainerStyle = (icon) => {
    return {
        display: "flex",
        justifyContent: icon ? "space-between" : "flex-end",
        width: icon ? "100%" : "auto",
        alignItems: "flex-end",
        float: "right",
        margin: "10px 0 0 10px",
    };
};

export const timeTextStyle = {
    color: "#9E9EB0FF",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "130%",
};

export const MenuTheme = createTheme({
    components: {
        MuiMenu: {
            styleOverrides: {
                // Name of the slot
                root: {},
                paper: {
                    borderRadius: "10px",
                    //background: 'transparent',
                    padding: "0!important",
                    ".MuiList-root": {
                        display: "flex",
                        width: "max-content",
                        padding: "0",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "flex-start",
                    },
                    ".MuiMenuItem-root": {
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        alignSelf: "stretch",
                        padding: "0",
                        background: "#FFFFFF!important",
                    },
                },
            },
        },
    },
});

export const MenuItemActionsTheme = createTheme({
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    alignSelf: "stretch",
                    gap: 10,
                    padding: "5px 8px!important",
                    "&:hover": {
                        background: "rgba(187, 187, 200, 0.17)!important",
                        borderRadius: "10px!important",
                        "& *": {
                            color: "#6212FF!important",
                        },
                    },
                    "&.Mui-selected": {
                        background: "rgba(187, 187, 200, 0.17)!important",
                        borderRadius: "10px!important",
                        "& *": {
                            color: "#6212FF!important",
                        },
                    },
                },
            },
        },
    },
});

export const IconTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                    borderRadius: "50%",
                    background: "#f4f4f6",
                    position: "relative",
                    width: "20px",
                    height: "20px",
                    "& img": {
                        width: "20px!important",
                        height: "20px!important",
                    },
                },
            },
        },
    },
});

export const actionTextStyle = {
    color: "var(--Text-Black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    "&:hover": {
        color: "#6212FF!important",
    },
};

export const menuBodyStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const reactionsContainerStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "10px",
    padding: "8px",
    background: "#FFFFFF",
};

export const actionsContainerStyle = {
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
};

export const PopoverTheme = createTheme({
    components: {
        MuiPopover: {
            styleOverrides: {
                paper: {
                    padding: "1vh",
                },
            },
        },
    },
});
