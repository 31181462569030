import { memo } from "react";
import * as styles from "./ClassInfoThemes";
import { Avatar, Box, Checkbox, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Typography } from "@mui/material";
import PaymentStore from "store/PaymentStore";
import * as textStyles from "themes/TextThemes";
import TutorVerifiedIcon from "assets/PaymentAssets/SVG/TutorVerifiedIcon";
import TimeIcon from "assets/PaymentAssets/SVG/TimeIcon";
import RadioDefault from "assets/PaymentAssets/SVG/RadioDefault";
import RadioChecked from "assets/PaymentAssets/SVG/RadioChecked";
import CheckBoxDefault from "assets/PaymentAssets/SVG/CheckBoxDefault";
import CheckBoxChecked from "assets/PaymentAssets/SVG/CheckBoxChecked";
import EnterPromocode from "./EnterPromocode/EnterPromocode";
import SecuredIcon from "assets/PaymentAssets/SVG/SecuredIcon";
import LockIcon from "assets/PaymentAssets/SVG/LockIcon";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { useMedias } from "API/mediasHook";
import SButton from "components/UI/SButton";
import { useTranslation } from "react-i18next";
import ArrowBackShortIcon from "assets/CommonAssets/SVG/arrowBackShortSVG";
import { useNavigate } from "react-router-dom";

const ClassInfo = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const handleChangeRadio = (event) => {
        PaymentStore.setCashbackVariant(event.target.value);
    };

    const checkBoxValue = () => {
        if (PaymentStore.paymentInfo.cashbackVariant === "add") {
            return false;
        } else return true;
    };

    const handleChangeCheckbox = (event) => {
        if (event.target.checked === true) {
            PaymentStore.setCashbackVariant("subtract");
        } else {
            PaymentStore.setCashbackVariant("add");
        }
    };

    const handleProceedToPayment = () => {
        PaymentStore.setShowPaymentInfo(true);
    };

    const backToClasses = () => {
        navigate("/myclasses");
    };

    return (
        <Box sx={styles.ClassInfo(medias)}>
            <Box sx={styles.TutorInfo}>
                {!medias.sm ? (
                    <IconButton
                        sx={styles.IconButton}
                        disableRipple
                        onClick={backToClasses}
                    >
                        <ArrowBackShortIcon />
                    </IconButton>
                ) : null}
                <Avatar
                    src={PaymentStore.paymentInfo.photo}
                    sx={styles.Avatar}
                />
                <Box sx={styles.TutorAndRating}>
                    <Box sx={styles.TutorBlock}>
                        <Typography sx={textStyles.HeadlineH5Black}>{PaymentStore.paymentInfo.tutor}</Typography>
                        <TutorVerifiedIcon />
                    </Box>
                    <Box sx={styles.TutorBlock}>
                        <Box sx={styles.Rating}>
                            <Typography sx={textStyles.Button14White}>{PaymentStore.paymentInfo.rating}</Typography>
                        </Box>
                        <Typography sx={textStyles.Button14Purple}>{`${PaymentStore.paymentInfo.reviews} отзывов`}</Typography>
                    </Box>
                </Box>
            </Box>
            {PaymentStore.paymentInfo.type === "class" ? (
                <Box sx={styles.ClassDate}>
                    <Typography sx={textStyles.Body1Black}>{PaymentStore.formattedDate}</Typography>
                    <Box sx={styles.TimeBlock}>
                        <TimeIcon />
                        <Typography sx={textStyles.Body1Black}>{PaymentStore.formattedTime}</Typography>
                    </Box>
                </Box>
            ) : null}
            <Box sx={styles.YourOrderAndRadio}>
                <Box sx={styles.YourOrder}>
                    <Typography sx={medias.sm ? textStyles.HeadlineH41Black : textStyles.HeadlineH5Black}>Ваш заказ</Typography>
                    <Box sx={styles.OrderStatements}>
                        <Box sx={styles.ClassPrice}>
                            <Typography sx={textStyles.Body1Black}>{PaymentStore.paymentInfo.type === "class" ? "Занятие на 60 минут" : "Письменная работа"}</Typography>
                            <Typography sx={textStyles.Body1Black}>{`${PaymentStore.paymentInfo.classPrice.toLocaleString("ru")} ₽`}</Typography>
                        </Box>
                        {PaymentStore.paymentInfo.creditCashback !== null ? (
                            <Box sx={styles.ClassPrice}>
                                <Typography sx={textStyles.Body1Black}>Кешбэк</Typography>
                                <Typography sx={textStyles.Body1Black}>{`${PaymentStore.paymentInfo.creditCashback} баллов`}</Typography>
                            </Box>
                        ) : null}
                    </Box>
                </Box>
                {PaymentStore.paymentInfo.creditCashback !== null && PaymentStore.paymentInfo.debitCashback !== null ? (
                    <Box>
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="points"
                                name="points"
                                value={PaymentStore.paymentInfo.cashbackVariant}
                                onChange={handleChangeRadio}
                                sx={styles.RadioGroup}
                            >
                                <FormControlLabel
                                    sx={styles.FormControlAdd(PaymentStore.paymentInfo.cashbackVariant)}
                                    value="add"
                                    control={
                                        <Radio
                                            icon={<RadioDefault />}
                                            checkedIcon={<RadioChecked />}
                                            disableRipple
                                            sx={styles.Radio}
                                        />
                                    }
                                    label={`Зачислить ${PaymentStore.paymentInfo.creditCashback} баллов`}
                                />
                                <FormControlLabel
                                    sx={styles.FormControlSubstract(PaymentStore.paymentInfo.cashbackVariant)}
                                    value="subtract"
                                    control={
                                        <Radio
                                            icon={<RadioDefault />}
                                            checkedIcon={<RadioChecked />}
                                            disableRipple
                                            sx={styles.Radio}
                                        />
                                    }
                                    label={`Списать ${PaymentStore.paymentInfo.debitCashback} баллов`}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                ) : null}
            </Box>
            {PaymentStore.paymentInfo.creditCashback === null && PaymentStore.paymentInfo.debitCashback !== null ? (
                <FormControl
                    component="fieldset"
                    sx={styles.CheckBoxBlock}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                disableRipple
                                icon={<CheckBoxDefault />}
                                checkedIcon={<CheckBoxChecked />}
                                checked={checkBoxValue()}
                                onChange={handleChangeCheckbox}
                                sx={styles.CheckBox}
                            />
                        }
                        label="Списать 15 баллов"
                        sx={styles.FormControlCheckBox}
                    />
                </FormControl>
            ) : null}
            <Box sx={styles.TotalBill}>
                <Box sx={styles.OrderStatements}>
                    <Box sx={styles.ClassPrice}>
                        <Typography sx={textStyles.Body2Black}>Заказ</Typography>
                        <Typography sx={textStyles.Body2Black}>{`${PaymentStore.paymentInfo.classPrice.toLocaleString("ru")} ₽`}</Typography>
                    </Box>
                    {(PaymentStore.paymentInfo.creditCashback === null && PaymentStore.paymentInfo.debitCashback === null) ||
                    (PaymentStore.paymentInfo.creditCashback === null && PaymentStore.paymentInfo.debitCashback !== null && PaymentStore.paymentInfo.cashbackVariant === "add") ? null : (
                        <Box sx={styles.ClassPrice}>
                            <Typography sx={textStyles.Body2Black}>{PaymentStore.paymentInfo.cashbackVariant === "add" ? "Зачислятся на бонусный счёт" : "Спишутся с бонусного счёта"}</Typography>
                            <Typography
                                sx={textStyles.Body2Black}
                            >{`${PaymentStore.paymentInfo.cashbackVariant === "add" ? `+${PaymentStore.paymentInfo.creditCashback}` : `-${PaymentStore.paymentInfo.debitCashback}`} баллов`}</Typography>
                        </Box>
                    )}
                    {PaymentStore.promocodeActivated === false || PaymentStore.paymentInfo.cashbackVariant === "subtract" ? null : (
                        <Box sx={styles.ClassPrice}>
                            <Typography sx={textStyles.Body2Black}>{`Промокод ${PaymentStore.temporaryPromocode}`}</Typography>
                            <Typography sx={textStyles.Body2Black}>{`-${PaymentStore.calculateDiscount().toLocaleString("ru")} ₽`}</Typography>
                        </Box>
                    )}
                </Box>
                <Box sx={styles.ClassPrice}>
                    <Typography sx={textStyles.HeadlineH5Black}>Итого</Typography>
                    <Typography sx={textStyles.HeadlineH5Black}>{`${PaymentStore.calculateTotal().toLocaleString("ru")} ₽`}</Typography>
                </Box>
            </Box>
            <EnterPromocode />
            <Box sx={styles.SecuredBlock}>
                <Box sx={styles.SecuredStatement}>
                    <SecuredIcon />
                    <Typography sx={textStyles.Caption1LightBlack}>{`Мы зарезервируем ${PaymentStore.calculateTotal().toLocaleString("ru")} ₽`}</Typography>
                </Box>
                <Box sx={styles.SecuredStatement}>
                    <SecuredIcon />
                    <Typography sx={textStyles.Caption1LightBlack}>Репетитор получит оплату после проведения занятия</Typography>
                </Box>
                <Box sx={styles.SecuredStatement}>
                    <SecuredIcon />
                    <Typography sx={textStyles.Caption1LightBlack}>Если репетитор не провёл занятие, деньги вернутся вам</Typography>
                </Box>
            </Box>
            {medias.sm ? (
                <Box sx={styles.LockBlock}>
                    <Box>
                        <LockIcon />
                    </Box>
                    <Typography sx={textStyles.Caption2DarkGrey}>Оплата на StudyBuddy абсолютно безопасна. Все транзакции защищены SSL-шифрованием</Typography>
                </Box>
            ) : (
                <SButton
                    padding="7px 16px 8px 16px"
                    onClick={handleProceedToPayment}
                    style={{ width: "100%" }}
                >
                    Перейти к оплате
                </SButton>
            )}
        </Box>
    );
});

export default memo(ClassInfo);
