import { styleProcessing } from "API/plugins/Utilities";

export const TeacherPersonalDataStepWider = {
    display: "flex",
    flexDirection: "column",
};

export const TeacherPersonalData = {
    marginBottom: "60px",
    display: "flex",
    flexDirection: "column",
    gap: "40px",
};

export const TeacherQuestionnaireTopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const TeacherQuestionnaireSubTitle = {
    maxWidth: "596px",
    width: "100%",
};

export const TeacherQuestionnaireEducationOuterContainerWithDelete = {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    gap: "10px",
};

export const TeacherQuestionnaireEducationOuterContainerWithoutDelete = (theme) => {
    let commonStyle = {
        width: "596px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherQuestionnaireEducationOuterContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
};

export const TeacherQuestionnaireDropDownEdit = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherQuestionnaireDropDownTitle = {
    marginTop: "2px",
};

export const TeacherQuestionnaireAutocompleteContainer = (theme) => {
    let commonStyle = {
        width: "424px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherQuestionnaireStillStudying = (theme) => {
    let commonStyle = {
        marginTop: "16px",
        paddingLeft: "172px",
        display: "flex",
        flexDirection: "row",
        gap: "10px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            paddingLeft: 0,
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const FormControlLabel = { alignItems: "flex-start", m: 0 };

export const TeacherQuestionnaireDragNDropDescription = {
    marginTop: "10px",
};

export const TeacherQuestionnaireAddFieldSeparator = {
    borderBottom: "1px dashed #bbbbc8",
    borderTop: "none",
    marginTop: "40px",
};

export const TeacherQuestionnaireDeleteStatementButton = {
    cursor: "pointer",
    height: "fit-content",
};

export const TeacherQuestionnaireAddFieldsButton = {
    color: "#222",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "150%", // 24px
    borderBottom: "1px solid #9e9eb0",
    width: "fit-content",
    padding: "0 0 4px 0",
    borderRadius: "0",
    textTransform: "none",
    ":hover": {
        background: "transparent",
    },
};

export const AdditionalBlocks = {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
};

export const TooltipPopperProps = {
    sx: {
        "& .MuiTooltip-tooltip": {
            border: "none",
        },
    },
};

export const PaddingBlock = (theme) => {
    let commonStyle = {
        paddingTop: "18px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            paddingTop: "0px",
            paddingBottom: "10px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TextButtonDelete = {
    textTransform: "none",
    color: "#6212FF",
    padding: 0,
    marginTop: "24px",
    lineHeight: "21px",
    fontSize: "14px",
    "&:hover": {
        color: "#6212FF",
    },
};

export const HelperText = {
    marginTop: "10px",
};
