import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./OrdersWorkTypeThemes";
import { Accordion, AccordionDetails, AccordionSummary, Chip, IconButton, Typography } from "@mui/material";
import OrdersSearchStore from "store/OrdersSearchStore";
import { CloseRounded, ExpandMore } from "@mui/icons-material";
import { ThemeProvider } from "@mui/material/styles";
import SingleChoiceLightFilter from "components/UI/SingleChoiceLightFilter";
import Box from "@mui/material/Box";

const OrdersWorkType = observer(({ children, ...props }) => {
    const handleChange = () => {
        OrdersSearchStore.ToggleOrdersWorkTypeExpanded();
    };

    const handleDelete = (value, event) => {
        console.log(value);
        OrdersSearchStore.ClearOrdersWorkType(value);
    };

    const processingOrdersWorkType = (event, value) => {
        OrdersSearchStore.SetOrdersWorkType(value);
    };

    return (
        <ThemeProvider theme={themes.OrdersWorkTypeAccordionTheme}>
            <Accordion
                expanded={OrdersSearchStore.ordersWorkTypeExpanded}
                onChange={handleChange}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography sx={themes.headerOrdersWorkTypeStyle}>Тип учебных работ</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {OrdersSearchStore.selectedOrdersWorkTypeChipList.length > 0 ? (
                        <ThemeProvider theme={themes.OrdersChipTheme}>
                            <Box sx={themes.selectedOrdersWorkTypeContainerStyle}>
                                {OrdersSearchStore.selectedOrdersWorkTypeChipList.map((value) => (
                                    <Chip
                                        key={value.ID}
                                        label={value.OrdersWorkType}
                                        deleteIcon={
                                            <IconButton onClick={handleDelete.bind(this, value)}>
                                                <CloseRounded></CloseRounded>
                                            </IconButton>
                                        }
                                        onDelete={handleDelete.bind(this, value)}
                                    />
                                ))}
                            </Box>
                        </ThemeProvider>
                    ) : null}
                    <SingleChoiceLightFilter
                        propOption={OrdersSearchStore.optionOrdersWorkType}
                        propOptions={OrdersSearchStore.optionsOrdersWorkType}
                        valueToShow={"OrdersWorkType"}
                        valueToBind={"ID"}
                        processingChange={processingOrdersWorkType}
                        themeWrapper={themes.FiltersOrdersWorkTypeTheme}
                        placeholder={"Выберите учебную работу"}
                        clearOnBlur={true}
                        disableClearable={false}
                        variant={"standard"}
                        freeSolo={true}
                    ></SingleChoiceLightFilter>
                </AccordionDetails>
            </Accordion>
        </ThemeProvider>
    );
});

export default memo(OrdersWorkType);
