import { createTheme } from "@mui/material/styles";

export const filtersTagsContainerStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "8px",
};

export const ClearChipTheme = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                // Name of the slot
                root: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingBottom: "7px",
                    paddingTop: "7px",
                    paddingRight: "12px",
                    paddingLeft: "12px",
                    gap: "4px",
                    width: "fit-content",
                    height: "32px",
                    background: "#FFFFFF",
                    border: "1px solid #9E9EB0",
                    borderRadius: "8px!important",
                    ".MuiChip-deleteIcon": {
                        width: "16px",
                        height: "16px",
                        flex: "none",
                        order: 1,
                        flexGrow: 0,
                    },
                    ".MuiIconButton-root": {
                        margin: 0,
                        color: "#4E4E4E!important",
                    },
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    ".MuiIconButton-root:hover": {
                        color: "#6212FF!important",
                        background: "white",
                    },
                },
                label: {
                    width: "fit-content",
                    height: "18px",
                    fontStyle: "normal",
                    padding: 0,
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "130%",
                    fontFeatureSettings: "'tnum' on, 'lnum' on",
                    color: "#222222",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                },
            },
        },
    },
});

export const AllChipTheme = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                // Name of the slot
                root: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingBottom: "7px",
                    paddingTop: "7px",
                    paddingRight: "12px",
                    paddingLeft: "12px",
                    gap: "4px",
                    width: "fit-content",
                    height: "32px",
                    background: "#4E4E4E",
                    borderRadius: "8px!important",
                    ".MuiChip-deleteIcon": {
                        width: "16px",
                        height: "16px",
                        flex: "none",
                        order: 1,
                        flexGrow: 0,
                    },
                    ".MuiIconButton-root": {
                        margin: 0,
                        color: "#ffffff!important",
                    },
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    ".MuiIconButton-root:hover": {
                        color: "#ffffff!important",
                    },
                },
                label: {
                    width: "fit-content",
                    height: "18px",
                    fontStyle: "normal",
                    padding: 0,
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "130%",
                    fontFeatureSettings: "'tnum' on, 'lnum' on",
                    color: "#FFFFFF",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                },
            },
        },
    },
});
