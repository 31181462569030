export const PlaceClassIcon = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="20"
                cy="20"
                r="20"
                fill="#BBBBC8"
                fillOpacity="0.17"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.8741 20.947C23.2885 20.1088 23.5 19.3635 23.5 18.75C23.5 17.1938 23.042 16.3194 22.5108 15.8224C21.96 15.3072 21.1192 15 20 15C18.8808 15 18.04 15.3072 17.4892 15.8224C16.958 16.3194 16.5 17.1938 16.5 18.75C16.5 19.3635 16.7115 20.1088 17.1259 20.947C17.5342 21.7729 18.0944 22.5989 18.6811 23.3483C19.1428 23.938 19.607 24.4625 20 24.8814C20.393 24.4625 20.8572 23.938 21.3189 23.3483C21.9056 22.5989 22.4658 21.7729 22.8741 20.947ZM20 20C20.9665 20 21.75 19.2165 21.75 18.25C21.75 17.2835 20.9665 16.5 20 16.5C19.0335 16.5 18.25 17.2835 18.25 18.25C18.25 19.2165 19.0335 20 20 20Z"
                fill="#4E4E4E"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30 20C30 25.5228 25.5228 30 20 30C14.4772 30 10 25.5228 10 20C10 14.4772 14.4772 10 20 10C25.5228 10 30 14.4772 30 20ZM20 27C20 27 25 22.4779 25 18.75C25 15.0221 22.7614 13.5 20 13.5C17.2386 13.5 15 15.0221 15 18.75C15 22.4779 20 27 20 27Z"
                fill="#4E4E4E"
            />
        </svg>
    );
};
