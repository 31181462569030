import React, { memo, useEffect, useState } from "react";
import { Autocomplete, Box, Button, Drawer, IconButton, Radio, TextField, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { SingleFilterCheckedIcon, SingleFilterIcon } from "themes/AllPageThemes";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import * as themes from "./SingleChoiceFilterThemesDefault";
import { useMedias } from "API/mediasHook";
import { CloseRounded } from "@mui/icons-material";
import SingleChoiceLightFilter from "./SingleChoiceLightFilter";

const SingleChoiceFilter = ({ children, ...props }) => {
    const processing = (event, value) => {
        props.processingChange(event, value);
        closeDrawer();
    };

    const medias = useMedias();

    const [desktopOpen, SetDesktopOpen] = useState(false);

    const [mobileOpen, SetMobileOpen] = useState(false);

    const handleOpen = () => {
        if (props.useMobileMode && !medias.sm) {
            SetMobileOpen(true);
        } else {
            SetDesktopOpen(true);
        }
    };

    const closeDrawer = () => {
        SetMobileOpen(false);
    };

    useEffect(() => {
        if (!mobileOpen) {
            document.activeElement.blur();
        }
    }, [mobileOpen]);

    return (
        <>
            <ThemeProvider theme={Object.assign({}, themes.themeWrapper(props?.useOptionIcon), props?.themeWrapper)}>
                <Autocomplete
                    value={props.propOption}
                    onChange={processing}
                    open={desktopOpen}
                    onOpen={handleOpen}
                    onClose={() => SetDesktopOpen(false)}
                    size="small"
                    clearText={"Очистить"}
                    closeText={"Закрыть"}
                    openText={"Открыть"}
                    options={props.propOptions}
                    noOptionsText={"Вариантов не найдено"}
                    getOptionLabel={(option) => String(option[props.valueToShow])}
                    isOptionEqualToValue={(value1, value2) => value1[props.valueToBind] === value2[props.valueToBind]}
                    renderOption={(propsR, option, { inputValue, selected }) => {
                        const matches = match(option[props.valueToShow], inputValue, { insideWords: true });

                        const parts = parse(option[props.valueToShow], matches);

                        return (
                            <Box
                                {...propsR}
                                sx={Object.assign({}, themes.themeOptionContainer, props?.themeOptionContainer)}
                            >
                                {props?.useOptionIcon ? (
                                    <Radio
                                        disableRipple
                                        checkedIcon={<SingleFilterCheckedIcon></SingleFilterCheckedIcon>}
                                        icon={<SingleFilterIcon></SingleFilterIcon>}
                                        checked={selected}
                                        style={Object.assign({}, themes.themeIconContainer, props?.themeIconContainer)}
                                    />
                                ) : null}
                                <Box>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                color: part.highlight ? (selected ? "" : "#6212FF") : "",
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                </Box>
                            </Box>
                        );
                    }}
                    renderInput={(params) => (
                        <Box>
                            {(medias.sm ? (props?.showLabel ? props.showLabel : true) : props?.showLabel ? props.showLabel : false) && (
                                <Typography sx={Object.assign({}, themes.themeLabel, props?.themeLabel)}>{props.label}</Typography>
                            )}
                            <TextField
                                {...params}
                                placeholder={props.propOption?.length > 0 ? "" : props?.placeholder ? props.placeholder : "Выберите вариант"}
                            />
                        </Box>
                    )}
                />
            </ThemeProvider>

            <ThemeProvider theme={themes.DrawerTheme}>
                <Drawer
                    anchor="right"
                    open={mobileOpen}
                    onClose={closeDrawer}
                >
                    <Box sx={themes.drawerContainerStyle}>
                        <Box sx={themes.headerContainerStyle}>
                            <ThemeProvider theme={themes.CloseIconTheme}>
                                <IconButton
                                    onClick={closeDrawer}
                                    disableRipple
                                >
                                    <CloseRounded></CloseRounded>
                                </IconButton>
                            </ThemeProvider>
                        </Box>
                        <SingleChoiceLightFilter
                            propOption={props.propOption}
                            propOptions={props.propOptions}
                            valueToShow={props.valueToShow}
                            valueToBind={props.valueToBind}
                            processingChange={processing}
                            themeWrapper={themes.MobileTheme}
                            placeholder={props.propOption?.length > 0 ? "" : props?.placeholder ? props.placeholder : "Выберите вариант"}
                            clearOnBlur={false}
                            disableClearable={false}
                            variant={"standard"}
                            freeSolo={true}
                        ></SingleChoiceLightFilter>
                    </Box>
                </Drawer>
            </ThemeProvider>
        </>
    );
};

export default memo(SingleChoiceFilter);
