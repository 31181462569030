import React, { useState, useRef, forwardRef } from "react";
import { TextField, IconButton, Box, Stack, Popover } from "@mui/material";
import FullChatStore from "store/FullChatStore";
import UserStore from "store/UserStore";
import Uploady, { useFileInput, useUploady, useBatchAddListener, useAbortItem } from "@rpldy/uploady";
import { send } from "@rpldy/sender";
import IconBtnSend from "assets/ChatAssets/SVG/iconButtonSend";
import CloseIcon from "@mui/icons-material/Close";
import { asUploadButton } from "@rpldy/upload-button";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ReactPlayer from "react-player/lazy";
import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import { useEffect } from "react";
import { getCookie } from "API/utils";

const mySend = (items, url, sendOptions, onProgress) => {
    sendOptions = {
        ...sendOptions,
        paramName: "attachments",
        formatGroupParamName: (index, paramName) => {
            return "attachments";
        },
    };

    return send(items, url, sendOptions, onProgress);
};

export const GetHeaders = () => {
    if (UserStore.role === "tutor") {
        if (getCookie("tutorAccessToken") !== null) {
            return "Bearer " + getCookie("tutorAccessToken");
        }
    }

    if (UserStore.role === "student") {
        if (getCookie("studentAccessToken") !== null) {
            return "Bearer " + getCookie("studentAccessToken");
        }
    }
};

const myEnhancer = (uploader) => {
    return uploader;
};

const SendMessage = ({ sendModalMessage, setSendModalMessage, setOpenModalAttachments, setItemFile }) => {
    const { processPending } = useUploady();

    const [text, setText] = React.useState("");

    return (
        <Stack direction="row">
            <TextField
                sx={{
                    borderRadius: "8px",
                    width: "310px",
                    height: "33px",
                    flexGrow: "1",
                    border: "solid 2px rgba(158, 158, 176, 1)",
                    marginTop: "8px",
                }}
                placeholder={"Сообщение..."}
                value={text}
                InputProps={{
                    sx: {
                        "&.MuiOutlinedInput-root": {
                            backgroundColor: "white",
                            borderWidth: 0,
                            width: "100%",
                            height: "100%",
                            borderRadius: "inherit",
                            pl: "10px",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderWidth: 0,
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderWidth: 0,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderWidth: 0,
                        },
                        "& .MuiInputBase-input.MuiOutlinedInput-input": {
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "20px",
                        },
                    },
                }}
                onChange={(e) => setText(e.target.value)}
            />
            <IconButton
                onClick={() => {
                    processPending({
                        params: {
                            message: text,
                        },
                    });
                    setItemFile("");
                    setOpenModalAttachments(false);
                }}
            >
                <IconBtnSend />
            </IconButton>
        </Stack>
    );
};

const DivUploadButton = asUploadButton(
    forwardRef((props, ref) => (
        <Box
            {...props}
            sx={{
                cursor: "pointer",
                height: "40px",
                width: "40px",
                minWidth: "40px",
                border: "dashed 2px rgba(158, 158, 176, 1)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "rgba(158, 158, 176, 1)",
                borderRadius: "5px",
                position: "sticky",
                right: "0",
                zIndex: "123",
                backgroundColor: "white",
            }}
        >
            <ControlPointIcon />
        </Box>
    )),
);

const UploadListItem = ({ item, setItemFile, delItem }) => {
    return (
        <Stack
            sx={{
                width: "40px",
                height: "40px",
                display: "flex",
                marginRight: "15px",
            }}
        >
            <Box
                component="div"
                sx={{
                    position: "relative",
                    borderRadius: "5px",
                }}
            >
                <Stack
                    onClick={() => setItemFile(item.file)}
                    sx={{
                        width: "40px",
                        height: "40px",
                    }}
                >
                    {item.file.type === "video/mp4" ? (
                        <ReactPlayer
                            width="97%"
                            height="100%"
                            loop
                            playing
                            muted
                            controls
                            volume={1}
                            wrapper={"div"}
                            style={{
                                border: "solid 2px black",
                                overflow: "hidden",
                                backgroundColor: "black",
                                margin: "2px 0",
                                video: {
                                    borderRadius: "20px",
                                },
                            }}
                            url={item.file}
                        />
                    ) : (
                        <img
                            style={{
                                height: "100%",
                                width: "100%",
                            }}
                            src={window.URL.createObjectURL(item.file)}
                        />
                    )}
                </Stack>
                <Box
                    onClick={() => {
                        delItem(item);
                    }}
                    sx={{
                        right: "0",
                        top: "0",
                        marginRight: "-10px",
                        marginTop: "-5px",
                        position: "absolute",
                        width: "20px",
                        height: "20px",
                        zIndex: "23",
                        backgroundColor: "white",
                        borderRadius: "20px",
                        border: "solid 1px rgba(238, 238, 240, 1)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CloseIcon
                        sx={{
                            width: "15px",
                            height: "15px",
                        }}
                        viewBox="0 0 24 24"
                    />
                </Box>
            </Box>
        </Stack>
    );
};

const UploadList = ({ setItemFile }) => {
    const { processPending } = useUploady();

    const [items, setItems] = useState([]);

    const abortItem = useAbortItem();

    useBatchAddListener((batch) => {
        setItemFile(batch.items[batch.items.length - 1].file);
        setItems((items) => items.concat(batch.items));
    });

    const delItem = (item) => {
        abortItem(item.id);
        const res = items.filter((file) => file.id !== item.id);

        setItems(res);
    };

    return (
        <>
            {items.map((item, index) => (
                <UploadListItem
                    key={index}
                    delItem={delItem}
                    item={item}
                    setItemFile={setItemFile}
                />
            ))}
        </>
    );
};

const ExampleForm = () => {
    const inputRef = useRef();

    useFileInput(inputRef);

    return (
        <form
            action="https://study-buddy.ru/chats/upload"
            method="POST"
        >
            <input
                type="file"
                style={{ display: "none" }}
                ref={inputRef}
                multiple
            />
        </form>
    );
};

const ModalUploadImage = ({ openModalAttachments, setOpenModalAttachments, setOpenModal, setItemFile, itemFile, sendModalMessage, setSendModalMessage }) => {
    const [hovered, setHovered] = React.useState(true);

    return (
        <ModalSettingsSmall
            bg="#F7F7F7"
            pl="20px"
            pr="20px"
            visible={openModalAttachments}
            handleClose={() => {
                setOpenModalAttachments(false);
                setOpenModal(false);
                setItemFile(null);
            }}
        >
            <Uploady
                enhancer={myEnhancer}
                send={mySend}
                grouped
                maxGroupSize={15}
                inputFieldName={"attachment"}
                multiple={false}
                customInput
                autoUpload={false}
                destination={{
                    headers: {
                        Authorization: GetHeaders(),
                    },
                    params: {
                        chat_id: FullChatStore.id,
                        type: "medias",
                    },
                }}
                headers={{
                    Authorization: GetHeaders(),
                }}
            >
                <ExampleForm />
                <Stack
                    sx={{
                        width: "410px",
                        height: "380px",
                        borderRadius: "20px",
                        direction: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#F7F7F7",
                    }}
                >
                    <Stack
                        sx={{
                            width: "80%",
                        }}
                        direction="row"
                    >
                        <Box
                            component="div"
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                overflowX: "auto",
                                position: "relative",
                                "&::-webkit-scrollbar": {
                                    height: "14px",
                                },

                                "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: "rgba(187, 187, 200, 1)",
                                    border: "solid 4px white",
                                    borderRadius: "100px",
                                },

                                "&::-webkit-scrollbar-thumb:hover": {
                                    backgroundColor: "#555",
                                },

                                "&::-webkit-scrollbar-track": {
                                    backgroundColor: "#f1f1f1",
                                },
                            }}
                        >
                            <UploadList setItemFile={setItemFile} />
                            <DivUploadButton />
                        </Box>
                    </Stack>
                    <Stack
                        sx={{
                            backgroundColor: "rgba(187, 187, 200, 0.17)",
                            width: "370px",
                            height: "240px",
                            borderRadius: "10px",
                            marginTop: "10px",
                            direction: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {itemFile &&
                            (itemFile.type === "video/mp4" ? (
                                <ReactPlayer
                                    width="97%"
                                    height="100%"
                                    loop
                                    playing
                                    muted
                                    controls
                                    volume={1}
                                    wrapper={"div"}
                                    style={{
                                        border: "solid 2px black",
                                        overflow: "hidden",
                                        backgroundColor: "black",
                                        margin: "2px 0",
                                        video: {
                                            borderRadius: "20px",
                                        },
                                    }}
                                    url={itemFile}
                                />
                            ) : (
                                <img
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                    }}
                                    src={window.URL.createObjectURL(itemFile)}
                                />
                            ))}
                    </Stack>
                    <SendMessage
                        sendModalMessage={sendModalMessage}
                        setSendModalMessage={setSendModalMessage}
                        setOpenModalAttachments={setOpenModalAttachments}
                        setItemFile={setItemFile}
                    />
                </Stack>
            </Uploady>
        </ModalSettingsSmall>
    );
};

export default ModalUploadImage;
