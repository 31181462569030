import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./ManageVideoThemes";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import VideoChatStore from "store/VideoChatStore";
import DemoIcon from "assets/VideoChatAssets/SVG/demoIcon";

const ManageDemo = observer(({ children, ...props }) => {
    const displayMediaOptions = {
        video: {
            displaySurface: "browser",
            aspectRatio: 1920 / 1080,
        },
        audio: {
            suppressLocalAudioPlayback: false,
        },
        preferCurrentTab: false,
        selfBrowserSurface: "include",
        systemAudio: "include",
        surfaceSwitching: "include",
        monitorTypeSurfaces: "include",
    };

    const handleStartCapture = async () => {
        let srcStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);

        VideoChatStore.SetCapture("start", srcStream);
    };

    const handleStopCapture = () => {
        VideoChatStore.SetCapture("stop", null);
    };

    const toggleDemoState = async () => {
        try {
            if (!VideoChatStore.managementPanel.demoState) {
                await handleStartCapture();
                VideoChatStore.SetDemoState(true);
            } else {
                handleStopCapture();
                VideoChatStore.SetDemoState(false);
            }
        } catch {
            console.log("declined");
        }
    };

    return (
        <Box
            sx={themes.iconContainerStyle(VideoChatStore.managementPanel.demoState)}
            onClick={toggleDemoState}
        >
            <ThemeProvider theme={themes.ActionButtonTheme(1, VideoChatStore.managementPanel.demoState)}>
                <Tooltip
                    title={<Typography sx={themes.tooltipStyle}>{VideoChatStore.managementPanel.demoState ? "Отключить" : "Включить"} демонстрацию</Typography>}
                    placement={"top"}
                >
                    <IconButton disableRipple>
                        <DemoIcon />
                    </IconButton>
                </Tooltip>
            </ThemeProvider>
        </Box>
    );
});

export default memo(ManageDemo);
