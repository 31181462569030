import OrderMenu from "components/OrdersBodyBlock/OrdersBodyBlock";
import React, { memo } from "react";
import "./OrdersPage.css";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import Box from "@mui/material/Box";

const OrdersPage = () => {
    return (
        <ScrollToTop>
            <Box className="OrdersPage">
                <OrderMenu />
            </Box>
        </ScrollToTop>
    );
};

export default memo(OrdersPage);
