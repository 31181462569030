import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./OrderCardThemes";
import { Card } from "@mui/material";
import InfoArea from "./InfoArea/InfoArea";
import FooterArea from "./FooterArea/FooterArea";

const OrderCard = observer(({ children, ...props }) => {
    return (
        <Card sx={themes.orderCardStyle(props.styleMode ? props.styleMode : 0)}>
            <InfoArea
                value={props.value}
                styleMode={props.styleMode}
            ></InfoArea>
            <FooterArea value={props.value}></FooterArea>
        </Card>
    );
});

export default memo(OrderCard);
