import { Box, Avatar, Grid } from "@mui/material";
import { SentIcon } from "assets/MiniChat/SVG/SentIcon";
import { ReadIcon } from "assets/MiniChat/SVG/ReadIcon";
import { TabPanel } from "components/AuthorizationBodyBlock/Tabs/Tabs";
import React, { memo } from "react";
import * as Styles from "./FindFieldStyles";
import * as themes from "components/ChatBodyBlock/ChatListBlock/TabsList/TabsListThemes";
import Clock from "assets/MiniChat/SVG/Clock";
import { Muted } from "assets/MiniChat/SVG/Muted";
import TabsChat from "./TabsChat/TabsChat";
import { convertDataInTabsChat } from "components/ChatBodyBlock/CommonData/helper_functions";
import FullChatStore from "store/FullChatStore";
import userstore from "store/UserStore";
import { observer } from "mobx-react-lite";
import { IconPin } from "assets/MiniChat/SVG/IconPin";
import VideoIcon from "assets/MiniChat/SVG/VideoIcon";
import ImageIcon from "assets/MiniChat/SVG/ImageIcon";
import { StyledBadge } from "components/ChatBodyBlock/ChatListBlock/TabsList/TabsList";

const ListChats = observer(({ children, ...props }) => {
    const [page, setPage] = React.useState(0);

    const GetEmpty = (index) => {
        if (index === 0)
            return (
                <Box sx={themes.TabsDivEmpty}>
                    <Box sx={themes.TabsEmpty}> Здесь будут все ваши чаты</Box>
                </Box>
            );

        if (index === 1)
            return (
                <Box sx={themes.TabsDivEmpty}>
                    <Box sx={themes.TabsEmpty}> Здесь будут ваши {userstore.role === "tutor" ? "студенты" : "специалисты"}</Box>
                </Box>
            );

        if (index === 2)
            return (
                <Box sx={themes.TabsDivEmpty}>
                    <Box sx={themes.TabsEmpty}> Здесь будут {userstore.role === "tutor" ? "студенты" : "специалисты"}, помещенные в архив</Box>
                    <Box sx={themes.TabsInfo}> Вы можете разархивировать их в любое время</Box>
                </Box>
            );

        if (index === 3)
            return (
                <Box sx={themes.TabsDivEmpty}>
                    <Box sx={themes.TabsEmpty}> Здесь будут {userstore.role === "tutor" ? "студенты" : "специалисты"}, которых вы заблокировали </Box>
                    <Box sx={themes.TabsInfo}> Пока {userstore.role === "tutor" ? "студент" : "специалист"} в этом списке, он не сможет вам писать. Вы можете разблокировать его в любое время </Box>
                </Box>
            );

        if (index > 3)
            return (
                <Box sx={themes.TabsDivEmpty}>
                    <Box sx={themes.TabsEmpty}> В этой папке пока ничего нет </Box>
                </Box>
            );
    };

    const getStatus = (lastMessage, muted) => {
        if (lastMessage.unread_messages === 0) {
            if (lastMessage.pin) return <IconPin />;

            if (lastMessage.read_message) return <ReadIcon />;

            if (lastMessage.send_message) return <SentIcon />;
            else if (lastMessage.last_message) return <Clock />;
            else return null;
        } else {
            return <Box sx={{ ...Styles.UnreadMessage, backgroundColor: muted ? "#BBBBC8" : "#6212FF" }}>{lastMessage.unread_messages}</Box>;
        }
    };

    return (
        <>
            <Box sx={{ height: "6.5vh" }}>
                <TabsChat
                    folders={convertDataInTabsChat(FullChatStore.tabsData)}
                    pageChange={setPage}
                />
            </Box>
            {convertDataInTabsChat(FullChatStore.tabsData).map((item, tabIndex) => (
                <Box
                    key={tabIndex}
                    sx={{ overflowY: "scroll", maxHeight: "60vh", cursor: "pointer" }}
                >
                    <TabPanel
                        value={page}
                        index={item.index}
                    >
                        {item.infocards.length > 0 ? (
                            <Box>
                                {item.infocards
                                    .filter((chat) => !!(chat.name.toLowerCase().includes(props.name.toLowerCase()) && chat.pin))
                                    .map((item_chat, index) => (
                                        <Box
                                            key={index + "pinned"}
                                            sx={Styles.MessageBox}
                                            onClick={() => {
                                                props.setPageChat(0);
                                                FullChatStore.SetChatID(item_chat.chat_id);
                                                FullChatStore.SetParticipantID(item_chat.uuid);
                                                FullChatStore.SetToScrollMessage(null);
                                                FullChatStore.SetCurrMessagePinnedId(item_chat.pinned_messages?.length > 0 ? item_chat.pinned_messages[item_chat.pinned_messages?.length - 1] : null);
                                                item.blockedby.includes(item_chat.uuid) ? FullChatStore.SetBlockedBy(true) : FullChatStore.SetBlockedBy(false);
                                                item.index === 3 ? FullChatStore.SetBlocked(true) : FullChatStore.SetBlocked(false);
                                            }}
                                        >
                                            <Box sx={Styles.AvatarDiv}>
                                                <StyledBadge
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                                    variant="dot"
                                                    invisible={item_chat.status.online !== true}
                                                >
                                                    <Avatar
                                                        src={item_chat.avatar}
                                                        sx={Styles.Avatar}
                                                    ></Avatar>
                                                </StyledBadge>
                                            </Box>
                                            <Box sx={Styles.MessageCenter}>
                                                <Box sx={{ display: "flex", gap: "0.3vw" }}>
                                                    <Box>{item_chat.name}</Box>
                                                    <Box>{item_chat.disabled_notifications && <Muted />}</Box>
                                                </Box>
                                                <Box sx={{ color: "#67677A", height: "20px" }}>
                                                    {item_chat.last_message.substr(0, 25)}
                                                    {item_chat.last_message.length > 25 && "..."}
                                                </Box>
                                            </Box>
                                            <Box sx={Styles.MessageRight}>
                                                <Box sx={{ color: "#9E9EB0" }}>{item_chat.time_message}</Box>
                                                <Box>{getStatus(item_chat, item.disabled_notifications)}</Box>
                                            </Box>
                                        </Box>
                                    ))}
                                {item.infocards
                                    .filter((chat) => chat.name.toLowerCase().includes(props.name.toLowerCase()) && !chat.pin)
                                    .map((item_chat, index) => (
                                        <Box
                                            key={index + "unpinned"}
                                            sx={Styles.MessageBox}
                                            onClick={() => {
                                                props.setPageChat(0);
                                                FullChatStore.SetChatID(item_chat.chat_id);
                                                FullChatStore.SetParticipantID(item_chat.uuid);
                                                FullChatStore.SetToScrollMessage(null);
                                                FullChatStore.SetCurrMessagePinnedId(item_chat.pinned_messages?.length > 0 ? item_chat.pinned_messages[item_chat.pinned_messages?.length - 1] : null);
                                                item.blockedby.includes(item_chat.uuid) ? FullChatStore.SetBlockedBy(true) : FullChatStore.SetBlockedBy(false);
                                                item.index === 3 ? FullChatStore.SetBlocked(true) : FullChatStore.SetBlocked(false);
                                            }}
                                        >
                                            <Box sx={Styles.AvatarDiv}>
                                                <StyledBadge
                                                    overlap="circular"
                                                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                                    variant="dot"
                                                    invisible={item_chat.status.online !== true}
                                                >
                                                    <Avatar
                                                        src={item_chat.avatar}
                                                        sx={Styles.Avatar}
                                                    ></Avatar>
                                                </StyledBadge>
                                            </Box>
                                            <Box sx={Styles.MessageCenter}>
                                                <Box sx={{ display: "flex", gap: "0.3vw" }}>
                                                    <Box>{item_chat.name}</Box>
                                                    <Box>{item_chat.disabled_notifications && <Muted />}</Box>
                                                </Box>
                                                <Grid
                                                    style={{ color: "#67677A", height: "20px" }}
                                                    container
                                                >
                                                    {item_chat.is_media === "1" ? (
                                                        item_chat.last_message === "Видео" ? (
                                                            <VideoIcon />
                                                        ) : (
                                                            <Box sx={{ display: "inline", marginRight: "3px" }}>
                                                                <ImageIcon />
                                                            </Box>
                                                        )
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <Box sx={{ display: "inline" }}>
                                                        {item_chat.last_message.substr(0, 25)}
                                                        {item_chat.last_message.length > 25 && "..."}
                                                    </Box>
                                                </Grid>
                                            </Box>
                                            <Box sx={Styles.MessageRight}>
                                                <Box sx={{ color: "#9E9EB0" }}>{item_chat.time_message}</Box>
                                                <Box>{getStatus(item_chat, item.disabled_notifications)}</Box>
                                            </Box>
                                        </Box>
                                    ))}
                            </Box>
                        ) : (
                            GetEmpty(item.index)
                        )}
                    </TabPanel>
                </Box>
            ))}
        </>
    );
});

export default memo(ListChats);
