import React, { memo } from "react";
import Box from "@mui/material/Box";
import * as themes from "./YesNoBlockThemes";
import ButtonSave from "components/UI/ButtonSave";
import UserStore from "store/UserStore";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

const YesNoBlock = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.ButtonsContainer}>
            <ButtonSave
                onClick={() => UserStore.setStoryAnswer(props.story, "yes")}
                sx={themes.YesButton(UserStore.stories[props.story].answer)}
            >
                {props.mode === "diploma" ? t("main.studentAccount.stories.yesCreate") : props.mode === "increaseMotivation" ? t("main.studentAccount.stories.obviouslyYes") : t("main.yes")}
            </ButtonSave>
            <ButtonSave
                onClick={() => UserStore.setStoryAnswer(props.story, "no")}
                sx={themes.NoButton(UserStore.stories[props.story].answer)}
            >
                {props.mode === "increaseMotivation" ? t("main.studentAccount.stories.generallyYes") : t("main.no")}
            </ButtonSave>
            {props.mode === "increaseMotivation" && (
                <ButtonSave
                    onClick={() => UserStore.setStoryAnswer(props.story, "other")}
                    sx={themes.increaseMotivationButton(UserStore.stories[props.story].answer)}
                >
                    {t("main.no")}
                </ButtonSave>
            )}
        </Box>
    );
});

export default memo(YesNoBlock);
