export const experienceContainerStyle = (styleMode) => {
    if (styleMode === 0) {
        return {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px",
            gap: "5px",
        };
    }

    if (styleMode === 1) {
        return {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px",
            gap: "5px",
            order: 4,
        };
    }
};

export const experienceLabelStyle = (styleMode) => {
    if (styleMode === 0) {
        return {
            height: "21px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "150%",
            display: "flex",
            alignItems: "center",
            color: "#9E9EB0",
            flex: "none",
            order: 0,
            flexGrow: 0,
        };
    }

    if (styleMode === 1) {
        return {
            height: "24px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "150%",
            display: "flex",
            alignItems: "center",
            color: "#9E9EB0",
            flex: "none",
            order: 0,
            flexGrow: 0,
        };
    }
};

export const experienceStyle = (styleMode) => {
    if (styleMode === 0) {
        return {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
            padding: "1px 10px 1px",
            gap: "10px",
            height: "25px",
            background: "#F7F3FF",
            borderRadius: "26px",
            flex: "none",
            order: 1,
            flexGrow: 0,
        };
    }

    if (styleMode === 1) {
        return {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
            padding: "1px 10px 1px",
            gap: "10px",
            height: "28px",
            background: "#F7F3FF",
            borderRadius: "26px",
            flex: "none",
            order: 1,
            flexGrow: 0,
        };
    }
};

export const experienceTextStyle = (styleMode) => {
    if (styleMode === 0) {
        return {
            height: "21px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "150%",
            display: "flex",
            alignItems: "center",
            color: "#222222",
            flex: "none",
            order: 0,
            flexGrow: 0,
        };
    }

    if (styleMode === 1) {
        return {
            height: "24px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "150%",
            display: "flex",
            alignItems: "center",
            color: "#222222",
            flex: "none",
            order: 0,
            flexGrow: 0,
        };
    }
};
