import { TextField, styled } from "@mui/material";

export const StyledTextField = styled(TextField)({
    marginBottom: "12px",
    "& .MuiFormLabel-root": {
        fontSize: "18px",
        color: "rgba(158, 158, 176, 1)",
        fontFamily: '"Inter", sans-serif',
    },
    "& .MuiInput-input": {
        fontSize: "16px",
        color: "rgba(34, 34, 34, 1)",
        fontFamily: '"Inter", sans-serif',
    },
    "& .MuiInput-root:before": {
        bottom: -9,
    },
    "& .MuiInput-root:after": {
        bottom: -9,
    },
});

export const CanseledSubtitle = {
    fontSize: "14px",
    color: "rgba(103, 103, 122, 1)",
    fontFamily: '"Inter", sans-serif',
    fontWeight: 400,
    lineHeight: "21px",
    marginBottom: "22px",
};

export const CheckResponseTextSubtitle = {
    fontSize: "14px",
    color: "rgba(103, 103, 122, 1)",
    fontFamily: '"Inter", sans-serif',
    fontWeight: 400,
    lineHeight: "21px",
    marginBottom: "22px",
};

export const DateWrapper = {
    marginTop: "20px",
    fontWeight: 400,
    paddingBottom: "8px",
};

export const DateTextFontSize = { fontSize: "14px" };

export const DateNumberStyle = { color: "#9E9EB0", paddingRight: "4px" };

export const RenewOrderButtonWrapper = {
    position: "sticky",
    bottom: 60,
    marginLeft: "auto",
    marginRight: "20px",
};
