export const InputProps = {
    sx: {
        height: "160px",
        display: "flex",
        alignItems: "start",
        paddingRight: "55px",
        "&::-webkit-scrollbar": {
            width: "0.3em",
        },

        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#bbbbc8",
            borderRadius: "10px",
        },
    },
};

export const TextField = {
    border: "1px solid #BBBBC8",
    borderRadius: "10px",
    width: 497,
    "& ::placeholder": {},

    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: "#6212FF",
            borderWidth: "1px",
        },
        "&:hover fieldset": {
            borderColor: "#6212FF",
            borderWidth: "1px",
        },
    },
    "& .MuiInputBase-root": {
        "& input": {
            textAlign: "left",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "150%",
            color: "#222",
        },
    },
};

export const ExclamationWrappper = {
    display: "flex",
    alignItems: "top",
    marginTop: "16px",
    marginBottom: "40px",
    width: "493px",
    paddingLeft: "2px",
};

export const ExclamationText = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    color: "#222",
    marginLeft: "8px",
};

export const AddFileButton = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "150%",
    color: "#222",
    border: "1px dashed #9E9EB0",
    bgcolor: "#fff",
    paddingTop: "33px",
    paddingBottom: "33px",
    paddingLeft: "20px",
    textTransform: "none",
    boxShadow: "none",
    borderRadius: "10px",

    "&:hover": {
        backgroundColor: "rgba(187, 187, 200, 0.17);",
        boxShadow: "none",
    },
};

export const CircleiconPos = {
    display: "flex",
    alignItems: "center",
};

export const Circleicon = { ml: "128px", color: "#6212FF" };

export const Paper = {
    maxHeight: 140,
    overflow: "auto",
    boxShadow: "none",
    "&::-webkit-scrollbar": {
        width: "0.3em",
    },

    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#bbbbc8",
        borderRadius: "10px",
    },
};

export const AddedItemWrapper = {
    display: "flex",

    alignItems: "center",
    gap: "10px",
    flexDirection: "row",
    justifyContent: "space-between",
};

export const AddedItemDisplay = { display: "flex" };

export const AddedItemMrgin = { marginRight: "10px" };

export const FileName = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "135%",
    color: "#222222",
    marginBottom: "3px",
};

export const FileSizeText = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "140%",
    color: "#9E9EB0",
};

export const ToMuchFilesWrapper = {
    right: "0px",
    display: "flex",
    alignItems: "center",
};

export const ToMuchFilesPos = { marginRight: "15px" };

export const IconButton = { p: 0 };

export const ClearIcon = { color: "#4e4e4e", fontSize: 14 };

export const AttachedFiles = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "150%",
    color: "#E12626",
    marginBottom: "4px",
};

export const ToMuchFilesText = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "150%",
    color: "#67677A",
    marginBottom: "40px",
    width: "493px",
};
