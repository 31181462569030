import { styleProcessing } from "API/plugins/Utilities";

export const PaymentBodyBlock = (theme) => {
    let commonStyle = {
        minHeight: "775px",
        height: "fit-content",
        background: "#F3F3F3",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            paddingTop: "46px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const PaymentBodyBlockContent = {
    display: "flex",
    gap: "20px",
    marginTop: "-30px",
    zIndex: "500",
    height: "fit-content",
};

export const PaymentBottomBlock = {
    maxWidth: "1216px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
};

export const LawInfo = {
    cursor: "pointer",
};
