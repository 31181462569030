import * as themes from "./QuestionnaireHeaderThemes";
import { Box } from "@mui/material";
import IconLogo from "assets/ChatAssets/SVG/iconLogo";
import LanguageSelect from "components/Header/HeaderAccount/RightPanel/LanguageSelect/LanguageSelect";
import EmptyHeaderPhoto from "assets/CommonAssets/SVG/EmptyHeaderPhoto";
import * as React from "react";
import { memo } from "react";
import { useMedias } from "API/mediasHook";

const QuestionnaireHeader = ({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={themes.QuestionnaireHeader}>
            <Box sx={themes.HeaderAccountContent(medias)}>
                <Box>
                    <IconLogo />
                </Box>
                <Box sx={themes.QuestionnaireRightPanel}>
                    <LanguageSelect />
                    <Box sx={themes.EmptyPhoto}>
                        <EmptyHeaderPhoto />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default memo(QuestionnaireHeader);
