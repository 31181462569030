import * as React from "react";
import { observer } from "mobx-react-lite";

import { Box, Typography } from "@mui/material";

import ModalShowImage from "./ModalShowImage/ModalShowImage";
import SingleImageAttachment from "./SingleImageAttachment";

import * as textStyles from "themes/TextThemes";
import * as styles from "./ImageChatAttachmentsThemes";

import { useGetChatAttachments } from "hooks/useGetChatAttachments";

const ImageChatAttachments = ({ avatar, getDate, handleDownload, mergeArraysFromObject, findIndexByLink }) => {
    const [open, setOpen] = React.useState(false);

    const [opening, setOpening] = React.useState(false);

    const [chosenMessage, setChosenMessage] = React.useState("");

    const [chosenAttachment, setChosenAttachment] = React.useState("");

    const sortedImages = useGetChatAttachments("images");

    const handleClickOnImage = (link) => {
        setOpening(true);
        setChosenAttachment(link.link);
        setChosenMessage(link.date);
        setTimeout(() => {
            setOpen(true);
            setOpening(false);
        }, 0);
    };

    return (
        <Box style={styles.imageAttachmentsContainer}>
            {Object.keys(sortedImages).map((monthyear, index) => {
                return (
                    <Box
                        sx={styles.imageDateBox}
                        key={index}
                    >
                        <Typography sx={textStyles.Body2DarkGrey}>{monthyear}</Typography>
                        <Box sx={styles.imagesBox}>
                            {sortedImages[monthyear].reverse().map((link) => {
                                return (
                                    <SingleImageAttachment
                                        key={link.link}
                                        link={link}
                                        disabled={opening || open}
                                        handleClick={() => handleClickOnImage(link)}
                                        handleDownload={handleDownload}
                                    />
                                );
                            })}
                        </Box>
                    </Box>
                );
            })}
            <ModalShowImage
                open={open}
                setOpen={setOpen}
                chosenMessage={chosenMessage}
                chosenAttachment={chosenAttachment}
                avatar={avatar}
                getDate={getDate}
                orderedGroupedMessages={sortedImages}
                handleDownload={handleDownload}
                mergeArraysFromObject={mergeArraysFromObject}
                findIndexByLink={findIndexByLink}
            />
        </Box>
    );
};

export default observer(ImageChatAttachments);
