import React from "react";
import { observer } from "mobx-react-lite";
import { Stack, Typography } from "@mui/material";
import NextIcon from "@mui/icons-material/NavigateNext";
import SButton from "components/UI/SButton";
import DetailsDrawer from "./DetailsDrawer/DetailsDrawer";
import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";
import UserStore from "store/UserStore";

const OrderDetailsState = () => {
    const handleOpenOrderDetails = () => {
        DiplomaStore.ToggleDrawer();
    };

    return DiplomaStore.status === "ended" && UserStore.role === "student" ? (
        <Stack
            direction="row"
            spacing={0.5}
        >
            <Typography variant="grayText">Дата завершения:</Typography>
            <Typography variant="standard">18 апреля</Typography>
        </Stack>
    ) : (
        <Stack>
            <SButton
                variant="text"
                onClick={handleOpenOrderDetails}
            >
                Детали заказа <NextIcon style={{ fontSize: "16px" }} />
            </SButton>
            <DetailsDrawer />
        </Stack>
    );
};

export default observer(OrderDetailsState);
