import React from "react";
import { Typography, Box } from "@mui/material";
import IOSSwitch from "components/UI/IOSSwitch";
import FormControlLabel from "@mui/material/FormControlLabel";
import * as styles from "./NotificationsThemes";
import * as textStyles from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import { useMedias } from "API/mediasHook";

const Notifications = () => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <Box sx={styles.Notifications(medias)}>
            <Box sx={styles.NotificationsTitle(medias)}>
                <Typography sx={textStyles.HeadlineH4White}>{t("main.settings.notificationsBlock.notificationsTitle")}</Typography>
            </Box>
            <Box sx={styles.notificationBlocks(medias)}>
                <Typography sx={[textStyles.HeadlineH4Black, styles.notificationBlocksText(medias)]}>{t("main.settings.notificationsBlock.byEmail")}</Typography>
                <Box sx={styles.notificationBlock}>
                    <Box sx={styles.notificationContent(medias)}>
                        <Typography sx={textStyles.Button16Black}>{t("main.settings.notificationsBlock.timetableClassesTitle")}</Typography>
                        <Typography sx={textStyles.Body2Grey}>{t("main.settings.notificationsBlock.timetableClassesDescription")}</Typography>
                    </Box>
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                sx={{ m: 1 }}
                                defaultChecked
                            />
                        }
                    />
                </Box>
                <Box sx={styles.notificationBlock}>
                    <Box sx={styles.notificationContent(medias)}>
                        <Typography sx={textStyles.Button16Black}>{t("main.settings.notificationsBlock.messagesTutorsTitle")}</Typography>
                        <Typography sx={textStyles.Body2Grey}>{t("main.settings.notificationsBlock.messagesTutorsDescription")}</Typography>
                    </Box>
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                sx={{ m: 1 }}
                                defaultChecked
                            />
                        }
                    />
                </Box>
                <Box sx={styles.notificationBlock}>
                    <Box sx={styles.notificationContent(medias)}>
                        <Typography sx={textStyles.Button16Black}>{t("main.settings.notificationsBlock.notificationsPaymentsTitle")}</Typography>
                        <Typography sx={textStyles.Body2Grey}>{t("main.settings.notificationsBlock.notificationsPaymentsDescription")}</Typography>
                    </Box>
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                sx={{ m: 1 }}
                                defaultChecked
                            />
                        }
                    />
                </Box>
                <Box sx={styles.notificationBlock}>
                    <Box sx={styles.notificationContent(medias)}>
                        <Typography sx={textStyles.Button16Black}>{t("main.settings.notificationsBlock.updatesTipsTitle")}</Typography>
                        <Typography sx={textStyles.Body2Grey}>{t("main.settings.notificationsBlock.UpdatesTipsDescription")}</Typography>
                    </Box>
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                sx={{ m: 1 }}
                                defaultChecked
                            />
                        }
                    />
                </Box>
            </Box>
            <Box sx={styles.notificationBlocks(medias)}>
                <Typography sx={textStyles.HeadlineH4Black}>{t("main.settings.notificationsBlock.informationAboutStudyBuddy")}</Typography>
                <Box sx={styles.notificationBlock}>
                    <Box sx={styles.notificationContent(medias)}>
                        <Typography sx={textStyles.Button16Black}>{t("main.settings.notificationsBlock.StudyBuddyContactTitle")}</Typography>
                        <Typography sx={textStyles.Body2Grey}>{t("main.settings.notificationsBlock.StudyBuddyContactDescription")}</Typography>
                    </Box>
                    <FormControlLabel
                        control={
                            <IOSSwitch
                                sx={{ m: 1 }}
                                defaultChecked
                            />
                        }
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default memo(Notifications);
