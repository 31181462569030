import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import React from "react";
import * as styles from "./ModalClassPaidThemes";
import * as textStyles from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import { Typography, Box, Avatar } from "@mui/material";
import PaymentStore from "store/PaymentStore";
import TimeIcon from "assets/PaymentAssets/SVG/TimeIcon";
import BonusesCircleIcon from "assets/PaymentAssets/SVG/BonusesCircleIcon";
import SButton from "components/UI/SButton";
import { useNavigate } from "react-router-dom";
import { useMedias } from "API/mediasHook";

const ModalClassPaid = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    return (
        <ModalSettingsSmall
            visible={PaymentStore.openClassPaid}
            handleClose={() => PaymentStore.handleDiscardClassPaid()}
            pl={!medias.sm ? "16px" : undefined}
            pr={!medias.sm ? "16px" : undefined}
            pt={!medias.sm ? "16px" : undefined}
            pb={!medias.sm ? "16px" : undefined}
        >
            <Box sx={styles.ClassPaid(medias)}>
                <Box sx={styles.ClassPaidContent}>
                    <Box sx={styles.ClassPaidTitle(medias)}>
                        <Avatar
                            src={PaymentStore.paymentInfo.photo}
                            sx={styles.Avatar}
                        />
                        <Typography
                            sx={textStyles.HeadlineH5Black}
                        >{`Вы успешно ${PaymentStore.paymentInfo.type === "class" ? `записаны на занятие по ${PaymentStore.paymentInfo.subject} к ${PaymentStore.paymentInfo.tutor}` : `оплатили ${PaymentStore.paymentInfo.subject} у ${PaymentStore.paymentInfo.tutor}`}`}</Typography>
                    </Box>
                    {PaymentStore.paymentInfo.type === "class" ? (
                        <Box sx={styles.ClassDate}>
                            <Typography sx={textStyles.Body1Black}>{PaymentStore.formattedDate}</Typography>
                            <Box sx={styles.TimeBlock}>
                                <TimeIcon />
                                <Typography sx={textStyles.Body1Black}>{PaymentStore.formattedTime}</Typography>
                            </Box>
                        </Box>
                    ) : null}
                    {PaymentStore.paymentInfo.creditCashback !== null && PaymentStore.paymentInfo.cashbackVariant === "add" ? (
                        <Box sx={styles.Bonuses}>
                            <Box>
                                <BonusesCircleIcon />
                            </Box>
                            <Typography sx={textStyles.Body2Black}>
                                {`В течение 1 часа после ${PaymentStore.paymentInfo.type === "class" ? "проведённого занятия" : "завершения выполнения работы"} на ваш бонусный счёт зачислятся `}
                                <span style={styles.BonusesText}>{`${PaymentStore.paymentInfo.creditCashback} баллов`}</span>
                            </Typography>
                        </Box>
                    ) : null}
                </Box>
                <Box sx={styles.ButtonContainer}>
                    <SButton
                        variant="unshadowed"
                        padding="10px 20px 11px 20px"
                        style={medias.sm ? null : styles.Button}
                        onClick={() => {
                            if (PaymentStore.paymentInfo.type === "class") {
                                navigate("/myclasses");
                                PaymentStore.handleDiscardClassPaid();
                            } else {
                                navigate("/diplomas");
                                PaymentStore.handleDiscardClassPaid();
                            }
                        }}
                    >
                        {PaymentStore.paymentInfo.type === "class" ? "Перейти к занятию" : "Перейти к заказу"}
                    </SButton>
                </Box>
            </Box>
        </ModalSettingsSmall>
    );
});

export default memo(ModalClassPaid);
