import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import * as themes from "./HeaderPartThemes";
import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useMedias } from "API/mediasHook";
import CatalogStore from "store/CatalogStore";
import { observer } from "mobx-react-lite";

const HeaderPart = observer(({ children, ...props }) => {
    const navigate = useNavigate();

    const goMain = () => {
        CatalogStore.MainFiltersClearAll();
        CatalogStore.SideFiltersClearAll();
        navigate("/");
    };

    const goCalatalog = () => {
        CatalogStore.MainFiltersClearAll();
        CatalogStore.SideFiltersClearAll();
        navigate("/catalog");
    };

    const medias = useMedias();

    return (
        <Box sx={themes.headerPartContainerStyle(medias)}>
            <Breadcrumbs
                sx={themes.filtersBCStyle(medias)}
                aria-label="breadcrumb"
            >
                <Link
                    underline="hover"
                    color="#4E4E4E"
                    onClick={goMain}
                >
                    Главная
                </Link>
                <Link
                    underline="hover"
                    color="#4E4E4E"
                    onClick={goCalatalog}
                >
                    Все репетиторы
                </Link>
                {CatalogStore.optionDiscipline?.discipline ? <Typography sx={{ color: "#9E9EB0" }}>{CatalogStore.optionDiscipline.discipline}</Typography> : null}
            </Breadcrumbs>
            <Typography sx={themes.filtersMainHeader(medias)}>
                {CatalogStore.optionDiscipline?.discipline
                    ? "Репетиторы по " + CatalogStore.optionDiscipline.discipline[0].toLowerCase() + CatalogStore.optionDiscipline.discipline.slice(1)
                    : "Все репетиторы"}
            </Typography>
        </Box>
    );
});

export default memo(HeaderPart);
