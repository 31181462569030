export const Main = {
    backgroundColor: "#F7F7F7",
    height: "7.6vh",
};

export const Number = {
    paddingTop: "2.3vh",
    paddingLeft: "2.3vh",
    fontSize: "1.9vh",
};
