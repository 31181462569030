import { memo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "API/utils";
import AuthorizationStore from "store/AuthorizationStore";
import UserStore from "store/UserStore";
import { observer } from "mobx-react-lite";

const CheckHasQuestionnaire = observer(() => {
    const navigate = useNavigate();

    const unusedLocations = ["/buddyrules", "/authorization"];

    const location = useLocation();

    useEffect(() => {
        const fetchTutorQuestionnaire = async () => {
            if (getCookie("role") === "tutor" && getCookie("tutorAccessToken")) {
                await AuthorizationStore.GetTutorQuestionnaire();

                if (!AuthorizationStore.hasTutorQuestionnaire) {
                    navigate("/questionnaire");
                } else {
                    navigate("/questionnaire-on-check");
                }
            }
        };

        if (!unusedLocations.includes(location.pathname)) {
            fetchTutorQuestionnaire();
        }
    }, [AuthorizationStore.hasTutorQuestionnaire, UserStore.isAuth]);
    return null;
});

export default memo(CheckHasQuestionnaire);
