import React, { memo } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import OrdersSearchBodyBlock from "components/OrdersSearchBodyBlock/OrdersSearchBodyBlock";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import Box from "@mui/material/Box";

const theme = createTheme({
    spacing: 1,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const OrdersSearchPage = ({ props }) => {
    return (
        <ScrollToTop>
            <ThemeProvider theme={theme}>
                <Box sx={{ background: "#F3F3F3", display: "flex", flexDirection: "column", alignItems: "center", borderRadius: "0px 0px 40px 40px" }}>
                    <OrdersSearchBodyBlock></OrdersSearchBodyBlock>
                </Box>
            </ThemeProvider>
        </ScrollToTop>
    );
};

export default memo(OrdersSearchPage);
