import { styleProcessing } from "API/plugins/Utilities";

export const RescheduleRequestCard = (theme) => {
    let commonStyle = {
        borderRadius: "20px",
        maxWidth: "970px",
        width: "100%",
        padding: "20px",
        background: "#fff",
        display: "flex",
        gap: "70px",
        minHeight: "135px",
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            boxSizing: "border-box",
            flexDirection: "column",
            padding: "16px",
            gap: "16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Avatar = (theme) => {
    let commonStyle = {
        width: "36px",
        height: "36px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "24px",
            height: "24px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const StudentTextBox = {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
};

export const StudentNameBox = {
    display: "flex",
    alignItems: "center",
    gap: "4px",
};

export const IconBox = {
    height: "fit-content",
    display: "flex",
    alignItems: "center",
};

export const StudentBlock = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    width: "180px",
};

export const LeftPanel = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    maxWidth: "554px",
    width: "100%",
};

export const RightPanel = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TimeBlock = {
    maxWidth: "135px",
    display: "flex",
    justifyContent: "center",
};

export const StudentAndReasonBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
};

export const TimeContainer = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "12px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "row",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const RescheduleDetails = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "45px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            gap: "6px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Button = {
    width: "165px",
};

export const ButtonsBlock = {
    display: "flex",
    gap: "16px",
};
