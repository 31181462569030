import React from "react";
import BalanceStore from "store/BalanceStore";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import * as styles from "./OperationsTableThemes";
import { useTranslation } from "react-i18next";
import OperationsTableMobile from "./OperationsTableMobile";
import { useMedias } from "API/mediasHook";

const OperationsTable = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <>
            {!medias.sm ? (
                <OperationsTableMobile />
            ) : (
                <table style={styles.Table}>
                    <tr style={styles.HeaderRow}>
                        <th style={styles.HeaderTh}>{t("main.balance.date")}</th>
                        <th style={styles.HeaderTh}>{t("main.balance.service")}</th>
                        <th style={styles.HeaderThSum}>{t("main.balance.amount")}</th>
                        <th style={styles.HeaderThPoints}>{t("main.balance.points")}</th>
                        <th style={styles.HeaderThPoints}>{t("main.balance.note")}</th>
                    </tr>
                    {BalanceStore.balanceListFiltered.map((val, key) => {
                        return (
                            <tbody key={key}>
                                <tr style={styles.FirstRow}>
                                    <td style={styles.FirstRowTdDate}>{val.date}</td>
                                    <td style={styles.FirstRowTdService}>{val.service}</td>
                                    <td style={styles.FirstRowTdAmount}>{val.amount}</td>
                                    <td
                                        style={styles.FirstRowTdPoints(val.points.charAt(0) === "+")}
                                        rowSpan={2}
                                    >
                                        {val.points}
                                    </td>
                                    <td
                                        style={styles.FirstRowTdNote}
                                        rowSpan={2}
                                    >
                                        {val.note}
                                    </td>
                                </tr>
                                <tr
                                    style={styles.SecondRow}
                                    key={key}
                                >
                                    <td style={styles.SecondRowTd}>{val.time}</td>
                                    <td style={styles.SecondRowTd}>{val.reason}</td>
                                    <td style={styles.SecondRowTdDiscount}>{val.discount}</td>
                                </tr>
                            </tbody>
                        );
                    })}
                </table>
            )}
        </>
    );
});

export default memo(OperationsTable);
