export const descriptionBlockContainerStyle = {};

export const descriptionTextStyle = {
    alignSelf: "stretch",
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};
