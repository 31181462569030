import { memo } from "react";
import MyClassesBodyBlock from "components/MyClassesBodyBlock/MyClassesBodyBlock";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

const MyClassesPage = () => {
    return (
        <ScrollToTop>
            <MyClassesBodyBlock />
        </ScrollToTop>
    );
};

export default memo(MyClassesPage);
