import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./MyResponsesBlockThemes";
import Typography from "@mui/material/Typography";
import OrdersResponsesStore from "store/OrdersResponsesStore";

const MyResponsesBlock = observer(({ children, ...props }) => {
    return (
        <Box sx={themes.myResponsesBlockStyle}>
            {OrdersResponsesStore.myResponses.Data.length ? (
                <table style={themes.myResponsesTableStyle}>
                    <thead style={themes.myResponsesTableHeaderStyle}>
                        <tr>
                            {OrdersResponsesStore.myResponses.Headers.map((header) => (
                                <th
                                    style={themes.myResponsesTableHeaderCellStyle}
                                    key={header.ID}
                                >
                                    <Typography sx={themes.myResponsesTableHeaderTextStyle}>{header.HeaderText}</Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {OrdersResponsesStore.myResponses.Data.map((data) => (
                            <tr
                                key={data.ID}
                                style={themes.myResponsesTableDataRowStyle}
                            >
                                <td style={themes.myResponsesTableDataCellStyle}>
                                    <Box sx={themes.orderInfoContainerStyle}>
                                        <Typography
                                            sx={themes.orderTitleStyle}
                                            onClick={() => window.open("/order/" + data._id, "_blank", "noreferrer")}
                                        >
                                            {data.OrderTitle}
                                        </Typography>
                                        {data.OrderType ? (
                                            <Box sx={themes.orderInfoSubContainerStyle}>
                                                <Box style={themes.themeBlockContainerStyle}>
                                                    <Typography sx={themes.themeLabelStyle}>Тема:</Typography>
                                                    <Typography sx={themes.themeTextStyle}>{data.Theme}</Typography>
                                                </Box>
                                                <Box style={themes.deadlineBlockContainerStyle}>
                                                    <Typography sx={themes.deadlineLabelStyle}>Срок выполнения:</Typography>
                                                    <Typography sx={themes.deadlineTextStyle}>{data.Deadline}</Typography>
                                                </Box>
                                            </Box>
                                        ) : (
                                            <Box sx={themes.orderInfoSubContainerStyle}>
                                                <Box style={themes.detailsBlockContainerStyle}>
                                                    <Typography sx={themes.detailsLabelStyle}>Детали:</Typography>
                                                    <Typography sx={themes.detailsTextStyle}>
                                                        {data.Tags.reduce((acc, value, index) => {
                                                            return acc + value.Query + (index === data.Tags.length - 1 ? "" : ", ");
                                                        }, "")}
                                                    </Typography>
                                                </Box>
                                                <Box style={themes.locationBlockContainerStyle}>
                                                    <Typography sx={themes.locationLabelStyle}>Где:</Typography>
                                                    <Typography sx={themes.locationTypeTextStyle}>{data.Location.Type}</Typography>
                                                    {data.Location.Stations.map((value, index) => (
                                                        <Box
                                                            style={themes.locationTextContainerStyle}
                                                            key={value.ID}
                                                        >
                                                            <Typography sx={themes.locationTextStyle}>{value.Location}</Typography>
                                                            {index === data.Location.Stations.length - 1 ? <Box></Box> : <Box>•</Box>}
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                </td>
                                <td style={themes.myResponsesTableDataCellStyle}>
                                    <Typography sx={themes.myPriceStyle}>{data.MyPrice ? data.MyPrice.toLocaleString() + " ₽" : "по договоренности"}</Typography>
                                </td>
                                <td style={themes.myResponsesTableDataCellStyle}>
                                    <Typography sx={themes.responseDateStyle}>{data.ResponseDate.toLocaleString("default", { day: "numeric", month: "long", year: "numeric" })}</Typography>
                                </td>
                                <td style={themes.myResponsesTableDataCellStyle}>
                                    <Box sx={themes.statusContainerStyle(data.Status.ID)}>
                                        <Box sx={themes.circleContainerStyle}>
                                            <Box sx={themes.circleStyle(data.Status.ID)}></Box>
                                        </Box>
                                        <Typography sx={themes.statusTextStyle(data.Status.ID)}>{data.Status.Name}</Typography>
                                    </Box>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <Box sx={themes.emptyResponsesContainerStyle}>
                    <Box sx={themes.emptyTextContainerStyle}>
                        <Typography sx={themes.emptyHeaderStyle}>Тут будет список твоих откликов на заказы и предложения</Typography>
                        <Typography sx={themes.emptyTextStyle}>Здесь ты сможешь отслеживать статусы своих откликов. Твой отклик может быть в ожидании, отклонён или выбран</Typography>
                    </Box>
                </Box>
            )}
        </Box>
    );
});

export default memo(MyResponsesBlock);
