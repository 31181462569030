import React, { memo, useEffect } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./OrdersSearchBodyBlockThemes";
import StickyFiltersBlock from "./StickyFiltersBlock/StickyFiltersBlock";
import Typography from "@mui/material/Typography";
import { useInView } from "react-intersection-observer";
import OrdersSearchStore from "store/OrdersSearchStore";
import OrderCard from "components/UI/OrderCard/OrderCard";
import { CircularProgress } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

const OrdersSearchBodyBlock = observer(({ children, ...props }) => {
    const { ref, inView, entry } = useInView({
        threshold: 0,
    });

    useEffect(() => {
        console.log(!OrdersSearchStore.processing, inView);
        if (!OrdersSearchStore.processing && inView) {
            OrdersSearchStore.ShowMore();
        }
    }, [inView]);

    return (
        <Box sx={themes.ordersSearchBodyBlockStyle}>
            <Typography sx={themes.ordersSearchHeaderStyle}>Поиск заказов</Typography>
            <Box sx={themes.ordersSearchBodyBlockContainerStyle}>
                <StickyFiltersBlock></StickyFiltersBlock>
                <Box sx={themes.ordersSearchInfoBlockStyle}>
                    {OrdersSearchStore.ordersArr.map((value) => (
                        <OrderCard
                            key={value.ID}
                            value={value}
                            styleMode={0}
                        ></OrderCard>
                    ))}
                    <Box ref={ref}></Box>
                    <ThemeProvider theme={themes.CircularProgressTheme}>
                        {OrdersSearchStore.processing && (
                            <Box sx={themes.progressContainerStyle}>
                                <CircularProgress />
                            </Box>
                        )}
                    </ThemeProvider>
                </Box>
            </Box>
        </Box>
    );
});

export default memo(OrdersSearchBodyBlock);
