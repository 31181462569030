export const QuestionnaireButtonsWrapper = {
    display: "flex",
    background: "#fff",
    padding: "20px 0 20px 0",
    marginTop: "40px",
};

export const NextButton = (width) => {
    return { width: width, maxWidth: "236px" };
};
