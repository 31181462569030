import "react-image-crop/dist/ReactCrop.css";
import React, { memo, useState } from "react";
import * as themes from "components/TeacherQuestionnaireBodyBlock/Themes/ModalCropPhotoThemes";
import * as tThemes from "themes/TextThemes";
import Box from "@mui/material/Box";
import SButton from "components/UI/SButton";
import Typography from "@mui/material/Typography";
import EmptyPhotoIcon from "assets/TeacherQuestionnaireAssets/EmptyPhotoIcon";
import FeedBackInput from "components/OrdersBodyBlock/OrderCard/OrderRateModal/FeedBackInput";
import store from "store/TeacherQuestionnaireStore";
import GreyPointIcon from "assets/TeacherQuestionnaireAssets/GreyPointIcon";
import PhotoExamplesModal from "components/TeacherQuestionnaireBodyBlock/Components/PhotoExamplesModal";
import PhotoCropStep from "components/TeacherQuestionnaireBodyBlock/Components/PhotoCropStep";
import AdviceBlockIcon from "assets/TeacherQuestionnaireAssets/AdviceBlockIcon";
import ReactPlayer from "react-player";
import { observer } from "mobx-react-lite";
import QuestionnaireButtons from "components/TeacherQuestionnaireBodyBlock/Components/QuestionnaireButtons";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import * as theme from "./UploadingPhotoStepThemes";
import "components/SettingsBodyBlock/TutorSettings/MainForm/Video/Video.css";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import * as textThemes from "themes/TextThemes";
import { useMedias } from "API/mediasHook";

const UploadingPhotoStep = observer(({ currentStep, setCurrentStep }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const [dialogOpenPhotoExamples, SetDialogOpenPhotoExamples] = useState(false);

    const [helperSizeExceeds, setHelperSizeExceeds] = useState(false);

    const handleDialogClosePhotoExamples = () => {
        SetDialogOpenPhotoExamples(false);
    };

    const handleVideoChange = async (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileSize = file.size / 1024 ** 2;

            if (fileSize > 50) {
                setHelperSizeExceeds(true);
                e.target.value = "";
            } else if (file && file.type.startsWith("video/")) {
                setHelperSizeExceeds(false);
                await TeacherQuestionnaireStore.setVideo(file);
            } else {
                console.error("Please select a valid video file.");
                e.target.value = "";
            }
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        const reader = new FileReader();

        reader.onload = () => {
            TeacherQuestionnaireStore.setInitialInputPhoto(reader.result);
            TeacherQuestionnaireStore.setOpenCropDialog(true);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const disabledContinue =
        TeacherQuestionnaireStore.teacher.about === "" ||
        TeacherQuestionnaireStore.teacher.about === null ||
        TeacherQuestionnaireStore.teacher.about === undefined ||
        TeacherQuestionnaireStore.teacher.photo.imageSrc === "" ||
        TeacherQuestionnaireStore.teacher.photo.imageSrc === null ||
        TeacherQuestionnaireStore.teacher.photo.imageSrc === undefined;

    const fileInput = React.useRef();

    const videoInput = React.useRef();

    const MobileTopContent = (
        <Box sx={theme.TopContentWrapper(medias)}>
            <Box>
                <Box sx={theme.CaptionBox}>
                    <Typography sx={tThemes.Body2Black}>{t("TeacherQuestionnaire.goodProfilePhoto")}</Typography>
                </Box>
                <Box sx={theme.pointSentenceWrapper}>
                    <Box sx={themes.pointSentence}>
                        <GreyPointIcon />
                        <Typography sx={tThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.realPhoto")}</Typography>
                    </Box>
                    <Box sx={themes.pointSentence}>
                        <GreyPointIcon />
                        <Typography sx={tThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.verticalPhoto")}</Typography>
                    </Box>
                    <Box sx={themes.pointSentence}>
                        <GreyPointIcon />
                        <Typography sx={tThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.faceIsClearlyVisible")}</Typography>
                    </Box>
                    <Box sx={themes.pointSentence}>
                        <GreyPointIcon />
                        <Typography sx={tThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.maxPhotoSize")}</Typography>
                    </Box>
                </Box>
                <SButton
                    variant="text"
                    onClick={() => {
                        SetDialogOpenPhotoExamples(true);
                    }}
                >
                    {t("TeacherQuestionnaire.seeExamples")}
                </SButton>
            </Box>
            <Box>
                {TeacherQuestionnaireStore.teacher.photo.imageSrc === "" ? (
                    <Box sx={theme.EmptyPhotoIconWrapper}>
                        <EmptyPhotoIcon />
                    </Box>
                ) : (
                    <Box>
                        <img
                            height={"260px"}
                            width={"180px"}
                            style={theme.TeacherQuestionnaireModalBigPhoto}
                            alt="not found"
                            src={TeacherQuestionnaireStore.teacher.photo.imageSrc}
                        />
                    </Box>
                )}
                <SButton
                    style={theme.SButton(medias)}
                    variant="unshadowed"
                    padding="7px 16px 8px 16px"
                    onClick={() => {
                        fileInput.current.click();
                    }}
                >
                    <input
                        ref={fileInput}
                        style={theme.Input}
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                    />
                    {t("TeacherQuestionnaire.uploadPhoto")}
                </SButton>
            </Box>
        </Box>
    );

    return (
        <ScrollToTop>
            <Box>
                <Typography sx={tThemes.HeadlineH41Black}>{t("TeacherQuestionnaire.profilePhoto")}</Typography>
                {medias.sm ? (
                    <Box sx={theme.TopContentWrapper(medias)}>
                        <Box>
                            {TeacherQuestionnaireStore.teacher.photo.imageSrc === "" ? (
                                <Box sx={theme.EmptyPhotoIconWrapper}>
                                    <EmptyPhotoIcon />
                                </Box>
                            ) : (
                                <Box>
                                    <img
                                        height={"260px"}
                                        width={"180px"}
                                        style={theme.TeacherQuestionnaireModalBigPhoto}
                                        alt="not found"
                                        src={TeacherQuestionnaireStore.teacher.photo.imageSrc}
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box>
                            <Box sx={theme.CaptionBox}>
                                <Typography sx={tThemes.Body2Black}>{t("TeacherQuestionnaire.goodProfilePhoto")}</Typography>
                            </Box>
                            <Box sx={theme.pointSentenceWrapper}>
                                <Box sx={themes.pointSentence}>
                                    <GreyPointIcon />
                                    <Typography sx={tThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.realPhoto")}</Typography>
                                </Box>
                                <Box sx={themes.pointSentence}>
                                    <GreyPointIcon />
                                    <Typography sx={tThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.verticalPhoto")}</Typography>
                                </Box>
                                <Box sx={themes.pointSentence}>
                                    <GreyPointIcon />
                                    <Typography sx={tThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.faceIsClearlyVisible")}</Typography>
                                </Box>
                                <Box sx={themes.pointSentence}>
                                    <GreyPointIcon />
                                    <Typography sx={tThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.maxPhotoSize")}</Typography>
                                </Box>
                            </Box>
                            <SButton
                                variant="text"
                                onClick={() => {
                                    SetDialogOpenPhotoExamples(true);
                                }}
                            >
                                {t("TeacherQuestionnaire.seeExamples")}
                            </SButton>
                            <SButton
                                style={theme.SButton(medias)}
                                variant="unshadowed"
                                padding="7px 16px 8px 16px"
                                onClick={() => {
                                    fileInput.current.click();
                                }}
                            >
                                <input
                                    ref={fileInput}
                                    style={theme.Input}
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                                {t("TeacherQuestionnaire.uploadPhoto")}
                            </SButton>
                        </Box>
                    </Box>
                ) : (
                    <>{MobileTopContent}</>
                )}
                <Typography sx={[tThemes.Body2DarkGrey, theme.VideoRulesText]}>{t("TeacherQuestionnaire.photoIsMandatory")}</Typography>
                <Box sx={theme.MidleContentWrapper}>
                    <Box sx={theme.MidleContentTextWrapper}>
                        <Typography sx={tThemes.HeadlineH41Black}>{t("TeacherQuestionnaire.tellAboutYourself")}</Typography>
                        <Typography sx={tThemes.Body2Black}>{t("TeacherQuestionnaire.writeAboutYourStrengths")}</Typography>
                    </Box>
                    <Box>
                        <FeedBackInput
                            rows="8"
                            height={medias.sm ? "200px" : "auto"}
                            width={medias.sm ? "598px" : "100%"}
                            placeholder={t("TeacherQuestionnaire.aboutMyself")}
                            max={3000}
                            feedBackValue={store.teacher.about}
                            setFeedBackValue={(value) => store.setTeacherData("about", value)}
                            style={{ boxSizing: "border-box" }}
                        />
                    </Box>
                </Box>
                <Box sx={theme.BottomContentWrapper(medias)}>
                    <Box sx={theme.BottomContentTextWrapper}>
                        <Typography sx={tThemes.HeadlineH41Black}>{t("TeacherQuestionnaire.videoCard")}</Typography>
                        <Typography sx={tThemes.Body2Black}>{t("TeacherQuestionnaire.grabYourStudentsAttention")}</Typography>
                        <Box sx={theme.BottomContentDottedTextWrapper}>
                            <Box sx={themes.pointSentence}>
                                <GreyPointIcon />
                                <Typography sx={tThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.sayHello")}</Typography>
                            </Box>
                            <Box sx={themes.pointSentence}>
                                <GreyPointIcon />
                                <Typography sx={tThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.tellSpecialization")}</Typography>
                            </Box>
                            <Box sx={themes.pointSentence}>
                                <GreyPointIcon />
                                <Typography sx={tThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.tellApproach")}</Typography>
                            </Box>
                            <Box sx={themes.pointSentence}>
                                <GreyPointIcon />
                                <Typography sx={tThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.inviteStudents")}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    {store.teacher.videoUrl === null ? (
                        <Box>
                            <Button
                                disableRipple
                                onClick={() => {
                                    videoInput.current.click();
                                }}
                                sx={theme.VideoButton(medias)}
                            >
                                <input
                                    ref={videoInput}
                                    style={theme.Input}
                                    type="file"
                                    accept="video/*"
                                    onChange={handleVideoChange}
                                />
                                <Typography sx={tThemes.Button16DarkGrey}>{t("TeacherQuestionnaire.uploadVideo")}</Typography>
                                <Box sx={theme.VideoCaption}>
                                    <Typography sx={textThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.maxVideoSize")}</Typography>
                                    <Typography sx={textThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.videoFormats")}</Typography>
                                </Box>
                            </Button>
                            {helperSizeExceeds ? <Typography sx={{ ...tThemes.Body2Red, ...theme.HelperText }}>{t("TeacherQuestionnaire.moreThan50MB")}</Typography> : null}
                            <Typography sx={[tThemes.Body2DarkGrey, theme.VideoRulesText]}>{t("TeacherQuestionnaire.videoCardIsNotMandatory")}</Typography>
                        </Box>
                    ) : (
                        <Box sx={themes.videoPlayer}>
                            <ReactPlayer
                                className="react-player-custom"
                                url={store.teacher.videoUrl}
                                controls
                                width="598px"
                                height="auto"
                            />
                            <Box sx={theme.VideoButtons}>
                                <SButton
                                    style={{ width: "fit-content" }}
                                    variant="text"
                                    onClick={() => {
                                        videoInput.current.click();
                                    }}
                                >
                                    <input
                                        ref={videoInput}
                                        style={theme.Input}
                                        type="file"
                                        accept="video/*"
                                        onChange={handleVideoChange}
                                    />
                                    {t("TeacherQuestionnaire.changeVideo")}
                                </SButton>
                                <SButton
                                    style={{ width: "fit-content" }}
                                    variant="text"
                                    onClick={async () => {
                                        await TeacherQuestionnaireStore.setVideo(null);
                                    }}
                                >
                                    {t("TeacherQuestionnaire.removeVideo")}
                                </SButton>
                            </Box>
                        </Box>
                    )}
                    <Box sx={themes.adviceBlock}>
                        <Box>
                            <AdviceBlockIcon />
                        </Box>
                        <Typography sx={textThemes.Body1Black}>{t("TeacherQuestionnaire.serviceModerators")}</Typography>
                    </Box>
                </Box>
                <QuestionnaireButtons
                    disabled={disabledContinue}
                    setCurrentStep={setCurrentStep}
                    currentStep={currentStep}
                />
                <PhotoCropStep />
                <PhotoExamplesModal
                    handleClose={handleDialogClosePhotoExamples}
                    openDialog={dialogOpenPhotoExamples}
                />
            </Box>
        </ScrollToTop>
    );
});

export default memo(UploadingPhotoStep);
