import React, { memo } from "react";
import Box from "@mui/material/Box";
import * as themes from "./EducationsThemes";
import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useMedias } from "../../../../../../API/mediasHook";

const Educations = observer(({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={themes.educationContainerStyle(props.styleMode ? props.styleMode : 0, medias)}>
            <Typography sx={themes.educationLabelStyle(props.styleMode ? props.styleMode : 0)}>Образование:</Typography>
            {props.value.Education.map((v, index) => (
                <Typography
                    sx={themes.educationTextStyle(props.styleMode ? props.styleMode : 0, medias)}
                    key={v.ID}
                >
                    {v.education}
                </Typography>
            ))}
        </Box>
    );
});

export default memo(Educations);
