import React, { useState } from "react";
import * as themes from "./LinkBlockThemes";
import * as textThemes from "themes/TextThemes";
import { IconButton, Snackbar, Typography } from "@mui/material";
import OKLogo from "assets/ReferralPageAssets/SVG/OK";
import VKLogo from "assets/ReferralPageAssets/SVG/VK";
import TelegramLogo from "assets/ReferralPageAssets/SVG/Telegram";
import Box from "@mui/material/Box";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import SButton from "components/UI/SButton";
import Fade from "@mui/material/Fade";
import { CloseSnackBarIcon } from "assets/MyClassesAssets/SVG/CloseSnackBarIcon";
import UserStore from "store/UserStore";

const LinkBlock = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const [openSnackBar, setOpenSnackBar] = useState(false);

    const [vkHover, setVKHovered] = useState(false);

    const [odnHover, setOdnHovered] = useState(false);

    const [tgHover, setTGHovered] = useState(false);

    const handleClickCopy = () => {
        setOpenSnackBar(true);
        navigator.clipboard.writeText(UserStore.referralLink);
    };

    const handleClickShareVK = () => {
        window.open("https://vk.com/share.php?url=" + UserStore.referralLink + "&description=" + t("promoPages.inviteFriend.linkText"), "_blank", "noreferrer");
    };

    const handleClickShareOdn = () => {
        window.open("https://connect.ok.ru/offer?url=" + UserStore.referralLink, "_blank", "noreferrer");
    };

    const handleClickShareTG = () => {
        window.open("https://t.me/share/url?url=" + UserStore.referralLink + "&text=" + t("promoPages.inviteFriend.linkText"), "_blank", "noreferrer");
    };

    return (
        <Box sx={themes.linkBLock}>
            <Box sx={themes.textBlock}>
                <Typography sx={textThemes.HeadlineH2Black}>{t("promoPages.inviteFriend.inviteAndGetPoints")}</Typography>
                <Typography sx={textThemes.Body1Black}>{t("promoPages.inviteFriend.giftPoints")}</Typography>
            </Box>

            <Box sx={themes.linkButtonBlock}>
                <Box
                    sx={themes.linkBox}
                    onClick={handleClickCopy}
                >
                    <Typography sx={textThemes.Body1Grey}>{UserStore.referralLink}</Typography>
                </Box>
                <SButton
                    style={themes.ButtonCopy}
                    onClick={handleClickCopy}
                    variant="unshadowed"
                    padding="17px 24px 18px 24px"
                >
                    {t("promoPages.inviteFriend.copyLink")}
                </SButton>
            </Box>

            <Box sx={themes.shareSocialBlock}>
                <Typography sx={themes.shareSocialText}>{t("promoPages.inviteFriend.chooseSocialToShare")}</Typography>
                <Box sx={themes.SocialsBlock}>
                    <Box
                        onMouseEnter={() => setVKHovered(true)}
                        onMouseLeave={() => setVKHovered(false)}
                        sx={themes.IconButton}
                        onClick={handleClickShareVK}
                    >
                        <VKLogo hover={vkHover} />
                    </Box>
                    <Box
                        onMouseEnter={() => setOdnHovered(true)}
                        onMouseLeave={() => setOdnHovered(false)}
                        sx={themes.IconButton}
                        onClick={handleClickShareOdn}
                    >
                        <OKLogo hover={odnHover} />
                    </Box>
                    <Box
                        onMouseEnter={() => setTGHovered(true)}
                        onMouseLeave={() => setTGHovered(false)}
                        sx={themes.IconButton}
                        onClick={handleClickShareTG}
                    >
                        <TelegramLogo hover={tgHover} />
                    </Box>
                </Box>
            </Box>

            <Snackbar
                sx={themes.WelcomeBackSnackbar}
                ContentProps={themes.ContentProps}
                TransitionComponent={Fade}
                autoHideDuration={5000}
                open={openSnackBar}
                onClose={() => setOpenSnackBar(false)}
                message={t("promoPages.inviteFriend.linkCopied")}
                action={
                    <IconButton
                        disableRipple
                        onClick={() => setOpenSnackBar(false)}
                    >
                        <CloseSnackBarIcon />
                    </IconButton>
                }
            />
        </Box>
    );
};

export default memo(LinkBlock);
