export const TextStyles = {
    "& fieldset": { border: "none" },
    "& .MuiOutlinedInput-root": {
        marginLeft: "auto",
        marginRight: "auto",
        padding: "0.4vh 0.8vw 0.4vh 0.8vw",
        backgroundColor: "rgba(187, 187, 200, 0.17)",
        borderRadius: "1.2vh",
        width: "22.7vw",
        height: "5vh",
        fontSize: "2.1vh",
    },
};

export const ChatItem = {
    borderBottom: "1px solid #EEEEF0",
    padding: "1.1vh 1vw 1.1vh 1vw",
    fontSize: "2.15vh",
};

export const GridChats = {
    overflow: "auto",
    paddingLeft: "0.2vw",
    marginTop: "1vh",
    maxHeight: "58vh",
};
