import React, { memo } from "react";
import { observer } from "mobx-react-lite";

import { Drawer, IconButton, Stack, Typography, Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { CloseRounded } from "@mui/icons-material";

import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";
import OrderDetails from "components/DiplomaChats/OrderDetails";

import UserStore from "store/UserStore";

import * as themes from "components/CatalogBodyBlock/FiltersDrawer/FiltersDrawerThemes";
import SButton from "components/UI/SButton";

const DetailsDrawer = observer(({ children, ...props }) => {
    const closeDrawer = () => {
        DiplomaStore.ToggleDrawer();
    };

    return (
        <Drawer
            anchor="right"
            open={DiplomaStore.drawerOpened}
            onClose={closeDrawer}
            PaperProps={{
                style: {
                    width: "670px",
                },
            }}
        >
            <Box
                padding="24px 30px 0px 30px"
                sx={themes.headerContainerStyle}
            >
                <Typography style={themes.textStyle}>Дипломная работа</Typography>
                <ThemeProvider theme={themes.CloseIconTheme}>
                    <IconButton onClick={closeDrawer}>
                        <CloseRounded></CloseRounded>
                    </IconButton>
                </ThemeProvider>
            </Box>
            <Stack
                direction="column"
                padding="20px 30px"
                spacing={2.5}
            >
                <OrderDetails />
                <Stack
                    padding="16px"
                    sx={{
                        borderRadius: "10px",
                        border: "1px solid var(--grey-dark-separator, #DADADF)",
                        boxShadow: "0px 4px 30px 0px rgba(106, 99, 118, 0.05)",
                    }}
                >
                    <Typography variant="standard">Если у вас возникли сложности и недопонимания со специалистом, напишите нам в Поддержку</Typography>
                </Stack>
                {/* TODO: на проработке!!! */}
                {UserStore.role === "tutor" && (
                    <SButton
                        variant="red"
                        style={{ width: "151px" }}
                    >
                        Отменить заказ
                    </SButton>
                )}
            </Stack>
        </Drawer>
    );
});

export default memo(DetailsDrawer);
