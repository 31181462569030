import React, { memo } from "react";
import * as styles from "./WelcomeBackSnackbarThemes";
import Fade from "@mui/material/Fade";
import { IconButton, Snackbar } from "@mui/material";
import { CloseSnackBarIcon } from "assets/MyClassesAssets/SVG/CloseSnackBarIcon";
import AuthorizationStore from "store/AuthorizationStore";
import { observer } from "mobx-react-lite";
import UserStore from "store/UserStore";

const WelcomeBackSnackbar = observer(({ children, ...props }) => {
    return (
        <Snackbar
            sx={styles.WelcomeBackSnackbar}
            ContentProps={styles.ContentProps}
            TransitionComponent={Fade}
            autoHideDuration={5000}
            // open={AuthorizationStore.openWelcomeBackSnackbar}
            open={false}
            onClose={() => AuthorizationStore.setOpenWelcomeBackSnackbar(false)}
            message={`С возвращением, ${UserStore.UserInfo.name}!`}
            action={
                <IconButton
                    disableRipple
                    onClick={() => AuthorizationStore.setOpenWelcomeBackSnackbar(false)}
                >
                    <CloseSnackBarIcon />
                </IconButton>
            }
        />
    );
});

export default memo(WelcomeBackSnackbar);
