import { TutorCatalogServiceClient } from "API/services/grpc/proto/CatalogService/tutor-catalog_grpc_web_pb";

import { SearchTutorsRequest, TutorRequest, OtherSimilarTutorsRequest, FavouritesRequest, ModifyFavouritesRequest } from "API/services/grpc/proto/CatalogService/tutor-catalog_pb";

const CatalogServiceUrl = "https://study-buddy.ru:8080";

const CatalogService = new TutorCatalogServiceClient(CatalogServiceUrl, null, null);

export async function SearchTutors(data, callback) {
    let request = new SearchTutorsRequest();

    let { metaData, body } = data;

    // eslint-disable-next-line no-undef
    let metaDataPB = new proto.pb.MetaData();

    metaDataPB.setPage(metaData.page);
    metaDataPB.setTimeSearch(metaData.timeSearch);
    request.setMetaData(metaDataPB);

    if (body) {
        // eslint-disable-next-line no-undef
        let bodyPB = new proto.pb.SearchParameters();

        Object.keys(body).forEach((key) => {
            let value = body[key];

            switch (key) {
                case "subject":
                    bodyPB.setSubject(value);
                    break;
                case "audience":
                    bodyPB.setAudience(value);
                    break;
                case "purpose":
                    bodyPB.setPurpose(value);
                    break;
                case "price":
                    // eslint-disable-next-line no-undef
                    let pricePB = new proto.pb.Price();

                    if (value["min"]) {
                        pricePB.setMin(value["min"]);
                    }

                    if (value["max"]) {
                        pricePB.setMax(value["max"]);
                    }

                    bodyPB.setPrice(pricePB);
                    break;
                case "keywords":
                    bodyPB.setKeywords(value);
                    break;
                case "cashback":
                    bodyPB.setCashback(value);
                    break;
                case "sort":
                    bodyPB.setSort(value);
                    break;
                case "age":
                    // eslint-disable-next-line no-undef
                    let agePB = new proto.pb.Age();

                    if (value["min"]) {
                        agePB.setMin(value["min"]);
                    }

                    if (value["max"]) {
                        agePB.setMax(value["max"]);
                    }

                    bodyPB.setAge(agePB);
                    break;
                case "time":
                    value.forEach((timeArrValue) => {
                        console.log(timeArrValue);
                        // eslint-disable-next-line no-undef
                        let timetablePB = new proto.pb.Timetable();

                        if (timeArrValue["timetable"].morning) {
                            timetablePB.setMorning(timeArrValue["timetable"].morning);
                        }

                        if (timeArrValue["timetable"].afternoon) {
                            timetablePB.setAfternoon(timeArrValue["timetable"].afternoon);
                        }

                        if (timeArrValue["timetable"].evening) {
                            timetablePB.setEvening(timeArrValue["timetable"].evening);
                        }

                        if (timeArrValue["timetable"].night) {
                            timetablePB.setNight(timeArrValue["timetable"].night);
                        }

                        bodyPB.setTimetable(timetablePB);
                    });

                    break;
                case "university":
                    // eslint-disable-next-line no-undef
                    let universityPB = new proto.pb.University();

                    universityPB.setName(value["name"]);
                    universityPB.setOnlyUniversity(value["onlyUniversity"]);

                    bodyPB.setUniversity(universityPB);
                    break;

                case "placesStudy":
                    // eslint-disable-next-line no-undef
                    let placesStudyPB = new proto.pb.PlacesStudy();

                    placesStudyPB.setCity(value["city"]);
                    placesStudyPB.setLocationsList(value["locations"]);

                    bodyPB.setPlacesStudy(placesStudyPB);
                    break;
                default:
                    break;
            }
        });
        request.setBody(bodyPB);
    }

    await CatalogService.searchTutors(request, null, callback);
}

export async function GetTutor(data, callback) {
    let request = new TutorRequest();

    let { uuid } = data;

    request.setUuid(uuid);
    await CatalogService.tutor(request, null, callback);
}

export async function GetRecommendedTutors(data, callback) {
    let request = new OtherSimilarTutorsRequest();

    let { tags, subject, tutorUuid } = data;

    request.setTagsList(tags);
    request.setSubject(subject);
    request.setTutorUuid(tutorUuid);
    await CatalogService.otherSimilarTutors(request, null, callback);
}

export async function GetFavourites(data, metadata, callback) {
    let request = new FavouritesRequest();

    let { metaData, subject } = data;

    // eslint-disable-next-line no-undef
    let metaDataPB = new proto.pb.MetaData();

    metaDataPB.setPage(metaData.page);
    metaDataPB.setTimeSearch(metaData.timeSearch);
    request.setMetaData(metaDataPB);

    if (subject) {
        request.setSubject(subject);
    }

    await CatalogService.favourites(request, metadata, callback);
}

export async function ToggleFavourites(data, metadata, callback) {
    let request = new ModifyFavouritesRequest();

    let { tutorUuid, action } = data;

    request.setTutorUuid(tutorUuid);
    // eslint-disable-next-line no-undef
    request.setAction(proto.pb.Action[action]);
    await CatalogService.modifyFavourites(request, metadata, callback);
}
