import React, { memo } from "react";
import { ArrowBackSharp, ArrowForwardSharp } from "@mui/icons-material";
import { Pagination, PaginationItem } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";

const CustomPagination = ({ children, ...props }) => {
    const processing = (event, value) => {
        props.processingChange(value);
    };

    return (
        <Box sx={props.paginationContainerStyle}>
            <ThemeProvider theme={props.paginationTheme}>
                <Pagination
                    count={props.pagesTotal}
                    page={props.currentPage}
                    siblingCount={1}
                    boundaryCount={1}
                    onChange={processing}
                    renderItem={(item) => (
                        <PaginationItem
                            slots={{ previous: ArrowBackSharp, next: ArrowForwardSharp }}
                            {...item}
                            disableRipple
                        />
                    )}
                />
            </ThemeProvider>
        </Box>
    );
};

export default memo(CustomPagination);
