import { Box } from "@mui/material";
import React from "react";
import Cropper from "react-cropper";
import "./Cropper.css";

const ImageCropperBig = ({ imageSrc, setCropper, setCroppedImage, height, width }) => {
    return (
        <Box>
            {imageSrc && (
                <>
                    <Cropper
                        src={imageSrc}
                        style={{ height: height, width: width }}
                        initialAspectRatio={9 / 13}
                        aspectRatio={9 / 13}
                        guides={true}
                        cropend={() => {
                            setCroppedImage("");
                        }}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                    />
                </>
            )}
        </Box>
    );
};

export default ImageCropperBig;
