import React from "react";
const ReadLess = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
        >
            <g clipPath="url(#clip0_3532_424)">
                <circle
                    cx="20"
                    cy="20"
                    r="20"
                    fill="#140C23"
                />
                <path
                    d="M19.6464 26.6464L15.8536 22.8536C15.5386 22.5386 15.7617 22 16.2071 22H23.7929C24.2383 22 24.4614 22.5386 24.1464 22.8536L20.3536 26.6464C20.1583 26.8417 19.8417 26.8417 19.6464 26.6464Z"
                    fill="white"
                    fillOpacity="0.9"
                />
                <path
                    d="M20.3536 12.3536L24.1464 16.1464C24.4614 16.4614 24.2383 17 23.7929 17L16.2071 17C15.7617 17 15.5386 16.4614 15.8536 16.1464L19.6464 12.3536C19.8417 12.1583 20.1583 12.1583 20.3536 12.3536Z"
                    fill="white"
                    fillOpacity="0.9"
                />
            </g>
            <defs>
                <clipPath id="clip0_3532_424">
                    <rect
                        width="40"
                        height="40"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ReadLess;
