import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import { Dialog, IconButton } from "@mui/material";
import * as themes from "./ModalVideoThemesDefault";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CloseRounded } from "@mui/icons-material";
import ReactPlayer from "react-player";

const ModalVideo = observer(({ children, ...props }) => {
    const Close = () => {
        props.handleDiscard();
    };

    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={props.open}
                onClose={Close}
            >
                <Box sx={themes.dialogContainerStyle}>
                    <ThemeProvider theme={themes.CloseIconTheme}>
                        <IconButton
                            onClick={Close}
                            disableRipple
                        >
                            <CloseRounded></CloseRounded>
                        </IconButton>
                    </ThemeProvider>
                    <Box sx={themes.cardContainerStyle}>
                        <Box sx={themes.contentBlockContainerStyle}>
                            <ReactPlayer
                                url={props.value.VideoSrc}
                                controls
                                width="100%"
                                height="100%"
                            />
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </ThemeProvider>
    );
});

export default memo(ModalVideo);
