import { memo } from "react";
import PaymentBodyBlock from "components/PaymentBodyBlock/PaymentBodyBlock";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

const PaymentPage = () => {
    return (
        <ScrollToTop>
            <PaymentBodyBlock />
        </ScrollToTop>
    );
};

export default memo(PaymentPage);
