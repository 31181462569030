import React, { memo, useState } from "react";
import { Card, IconButton } from "@mui/material";
import * as themes from "./TeacherInfoCardThemes";
import AvatarArea from "./AvatarArea";
import InfoArea from "./InfoArea";
import Box from "@mui/material/Box";
import AlterActionsArea from "./AlterActionsArea";
import NameBlock from "./NameBlock";
import { useMedias } from "../../../../API/mediasHook";
import CardActionsAlt from "./CardActionsAlt";
import CardActions from "./CardActions";

import { ThemeProvider } from "@mui/material/styles";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import UserStore from "../../../../store/UserStore";
import CatalogStore from "../../../../store/CatalogStore";
import FavouritesStore from "../../../../store/FavouritesStore";
import CardActionsAltSticky from "./CardActionsAltSticky";

const TeacherInfoCard = ({ children, ...props }) => {
    const medias = useMedias();

    const [dialogOpen, SetDialogOpen] = useState(false);

    const handleFavourites = async () => {
        if (!UserStore.isAuth) {
            showDialog();
            return;
        }

        await toggleFavourites();
    };

    const toggleFavourites = async () => {
        await CatalogStore.ToggleFavourites(props.value);
        await FavouritesStore.ToggleLiked(props.value);
    };

    const showDialog = () => {
        SetDialogOpen(true);
    };

    const hideDialog = () => {
        SetDialogOpen(false);
    };

    return (
        <Card sx={themes.teacherInfoCardStyle(props.styleMode ? props.styleMode : 0, medias)}>
            <Box sx={themes.teacherInfoContainerStyle(props.styleMode ? props.styleMode : 0, medias)}>
                <AvatarArea
                    value={props.value}
                    styleMode={props.styleMode}
                ></AvatarArea>
                <InfoArea
                    value={props.value}
                    styleMode={props.styleMode}
                ></InfoArea>
            </Box>
            {medias.sm && (props.styleMode ? <AlterActionsArea onTabChanged={props.onTabChanged} /> : null)}

            {!medias.sm &&
                (props.styleMode === 0 ? (
                    <CardActions
                        value={props.value}
                        styleMode={props.styleMode}
                    />
                ) : (
                    <CardActionsAlt
                        value={props.value}
                        styleMode={props.styleMode}
                    />
                ))}
        </Card>
    );
};

export default memo(TeacherInfoCard);
