import React from "react";
import * as themes from "components/CashbackPromoBodyBlock/TopQuestions/TopQuestionsThemes";
import * as textThemes from "themes/TextThemes";
import AccordionRef from "components/ReferralPromoBodyBlock/Accordion/Accordion";
import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const TopQuestionsCashback = ({ children, ...props }) => {
    const { t } = useTranslation();

    return (
        <Box sx={themes.AllBlock}>
            <Typography sx={textThemes.HeadlineH2Black}>{t("promoPages.cashback.top5Questions")}</Typography>

            <Box sx={themes.AccordionBlock}>
                <AccordionRef
                    header={t("promoPages.cashback.whatIsCashbackQuestion")}
                    text={t("promoPages.cashback.whatIsCashbackAnswer")}
                />

                <AccordionRef
                    header={t("promoPages.cashback.howToGetCashbackQuestion")}
                    text={t("promoPages.cashback.howToGetCashbackAnswer1")}
                    text1={t("promoPages.cashback.howToGetCashbackAnswer2")}
                />

                <AccordionRef
                    header={t("promoPages.cashback.forWhatCashbackForStudentQuestion")}
                    text={t("promoPages.cashback.forWhatCashbackForStudentAnswer")}
                />

                <AccordionRef
                    header={t("promoPages.cashback.whatDateCashbackQuestion")}
                    text={t("promoPages.cashback.whatDateCashbackAnswer")}
                />

                <AccordionRef
                    points={true}
                    header={t("promoPages.cashback.advantagesOfCashbackQuestion")}
                    text={t("promoPages.cashback.advantagesOfCashbackAnswer1")}
                    text1={t("promoPages.cashback.advantagesOfCashbackAnswer2")}
                    text2={t("promoPages.cashback.advantagesOfCashbackAnswer3")}
                />
            </Box>
        </Box>
    );
};

export default memo(TopQuestionsCashback);
