import React from "react";

import { Stack, Typography, ThemeProvider, IconButton } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

import { CloseIconTheme } from "./themes";
import * as textStyles from "themes/TextThemes";

const ModalTitle = ({
    close,
    title = `Хотите отправить работу
    на корректировку?`,
}) => {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
                background: "#F7F7F7",
                borderBottom: "1px solid var(--Grey-Light-Grey, #BBBBC8)",
                borderRadius: "20px 20px 0px 0px",
            }}
            padding={2.5}
        >
            <Stack paddingLeft={2.5}>
                <Typography sx={textStyles.HeadlineH41Black}>{title}</Typography>
            </Stack>
            <ThemeProvider theme={CloseIconTheme}>
                <IconButton onClick={close}>
                    <CloseRounded />
                </IconButton>
            </ThemeProvider>
        </Stack>
    );
};

export default ModalTitle;
