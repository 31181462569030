import React, { memo } from "react";
import { IconButton } from "@mui/material";
import ThumbUpIconEmpty from "assets/Stories/SVG/ThumbUpIconEmpty";
import ThumbUpIconFilled from "assets/Stories/SVG/ThumbUpIconFilled";
import ThumbDownIconEmpty from "assets/Stories/SVG/ThumbDownIconEmpty";
import ThumbDownIconFilled from "assets/Stories/SVG/ThumbDownIconFilled";
import Box from "@mui/material/Box";
import * as themes from "./LikeDislikeBlockThemes";
import UserStore from "store/UserStore";
import { observer } from "mobx-react-lite";

const LikeDislikeBlock = observer(({ children, ...props }) => {
    return (
        <Box sx={themes.LikeDislikeBlock}>
            <IconButton
                onClick={() => UserStore.setStoryLike(props.story, !UserStore.stories[props.story].like)}
                sx={themes.IconButton}
                disableRipple
            >
                {UserStore.stories[props.story].like ? <ThumbUpIconFilled /> : <ThumbUpIconEmpty />}
            </IconButton>
            <IconButton
                onClick={() => UserStore.setStoryDislike(props.story, !UserStore.stories[props.story].dislike)}
                sx={themes.IconButton}
                disableRipple
            >
                {UserStore.stories[props.story].dislike ? <ThumbDownIconFilled /> : <ThumbDownIconEmpty />}
            </IconButton>
        </Box>
    );
});

export default memo(LikeDislikeBlock);
