import React, { memo } from "react";
import * as themes from "./ModalWeAreSorryThemes";
import Box from "@mui/material/Box";
import { Avatar, Typography } from "@mui/material";
import * as textThemes from "themes/TextThemes";
import SButton from "components/UI/SButton";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";

const ModalWeAreSorry = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        props.handleSave();
    };

    return (
        <ModalSettingsSmall
            visible={props.open}
            handleClose={Close}
        >
            <Box sx={themes.SorryYouLeaving}>
                <Box sx={themes.SorryYouLeavingContent}>
                    <Avatar
                        sx={themes.Avatar}
                        src={TeacherQuestionnaireStore.teacher.miniature.imageSrc}
                    />
                    <Typography sx={textThemes.HeadlineH41Black}>{t("teacherSettings.accountActions.sorryYouAreLeaving")}</Typography>
                    <Typography sx={textThemes.Body2Black}>{t("teacherSettings.accountActions.afterDelete")}</Typography>
                </Box>
                <Box sx={themes.ButtonsContainer}>
                    <SButton
                        padding="10px 20px 11px 20px"
                        variant="secondary"
                        onClick={Close}
                    >
                        {t("main.cancel")}
                    </SButton>
                    <SButton
                        padding="10px 20px 11px 20px"
                        variant="red"
                        onClick={Save}
                    >
                        {t("main.continue")}
                    </SButton>
                </Box>
            </Box>
        </ModalSettingsSmall>
    );
});

export default memo(ModalWeAreSorry);
