import { Tab } from "@mui/material";
import React from "react";
import * as theme from "./OrderTabsThemes";
import UserStore from "store/UserStore";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import TeacherOrdersStore from "store/TeacherOrdersStore";

const tabsArr = [{ label: "orders.tabs.active" }, { label: "orders.tabs.inProgress" }, { label: "orders.tabs.completed" }, { label: "orders.tabs.cancelled" }];

const OrderTabs = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const filteredTabs = UserStore.role === "tutor" ? tabsArr.slice(1) : tabsArr;

    const handleChange = (_, val) => {
        TeacherOrdersStore.setActiveTab(val);
    };

    return (
        <theme.StyledTabsOrder
            value={TeacherOrdersStore.activeTab}
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            TabIndicatorProps={{
                sx: theme.TabIndicator,
            }}
        >
            {filteredTabs.map((el) => (
                <Tab
                    disableRipple
                    sx={theme.TabStyled}
                    label={t(el.label)}
                    key={el.label}
                />
            ))}
        </theme.StyledTabsOrder>
    );
});

export default memo(OrderTabs);
