export const ServiceAdvantagesBlock = (medias) => {
    return {
        width: medias.sm ? "fit-content" : "328px",
        display: "flex",
        flexDirection: medias.sm ? "unset " : "column-reverse",
        gap: medias.sm ? "123px" : "30px",
    };
};

export const FunctionsBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    position: "relative",
};

export const functionElement = {
    display: "flex",
    gap: "16px",
};

export const iconElem = {
    height: "fit-content",
};

export const TitleBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const SignUpButton = (medias) => {
    return {
        width: medias.sm ? "289px" : "328px",
        padding: "15px 35px",
    };
};

export const FunctionsAndButton = {
    display: "flex",
    flexDirection: "column",
    gap: "34px",
};

export const LeftBlock = (medias) => {
    return {
        width: medias.sm ? "598px" : "328px",
        display: "flex",
        flexDirection: "column",
        gap: "40px",
    };
};
