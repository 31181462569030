import { Box, Button, Typography } from "@mui/material";
import React, { useState, useRef } from "react";
import * as themes from "components/SettingsBodyBlock/TutorSettings/MainForm/MainFormThemes";
import SButton from "components/UI/SButton";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { observer } from "mobx-react-lite";
import ReactPlayer from "react-player";
import IconBackRounded from "assets/CommonAssets/SVG/IconBackRounded";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import "./Video.css";
import { memo } from "react";
import { useMedias } from "API/mediasHook";

const VideoEdit = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const [changed, setChanged] = useState(false);

    const videoInput = useRef();

    const handleVideoChange = (e) => {
        const file = e.target.files[0];

        if (file && file.type.startsWith("video/")) {
            TeacherQuestionnaireStore.setTemporaryVideoCard(URL.createObjectURL(file));
            setChanged(true);
        } else {
            // Handle invalid file types or errors
            console.error("Please select a valid video file.");
        }
    };

    const handleBackClick = () => {
        setChanged(false);
        TeacherQuestionnaireStore.setTemporaryVideoCard(TeacherQuestionnaireStore.teacher.video);
        props.handleSet(0);
    };

    const handleSaveClick = () => {
        setChanged(false);
        TeacherQuestionnaireStore.setTutorData("video", TeacherQuestionnaireStore.temporaryVideoCard);
    };

    const handleCancelClick = () => {
        setChanged(false);
        TeacherQuestionnaireStore.setTemporaryVideoCard(TeacherQuestionnaireStore.teacher.video);
    };

    return (
        <ScrollToTop>
            <Box sx={themes.Top(medias)}>
                <Box sx={themes.EditTitle(medias)}>
                    <SButton
                        variant="icon"
                        onClick={handleBackClick}
                    >
                        <IconBackRounded />
                    </SButton>
                    <Typography sx={medias.sm ? textThemes.HeadlineH41Black : textThemes.HeadlineH5Black}>{t("teacherSettings.questionnaire.videoCard")}</Typography>
                </Box>
                <Box sx={themes.FormBlock}>
                    <Typography sx={textThemes.Body2Black}>{t("teacherSettings.questionnaire.attractAttention")}</Typography>
                    <Box
                        component="ul"
                        sx={themes.ul}
                    >
                        {[
                            t("teacherSettings.questionnaire.shortHello"),
                            t("teacherSettings.questionnaire.tellSpecialisation"),
                            t("teacherSettings.questionnaire.tellAboutMethod"),
                            t("teacherSettings.questionnaire.offerStudents"),
                        ].map((text, index) => (
                            <Box
                                component="li"
                                sx={themes.li}
                                key={index}
                            >
                                <Typography sx={textThemes.Body2DarkGrey}>{text}</Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
                {TeacherQuestionnaireStore.temporaryVideoCard === null ? (
                    <Box>
                        <Button
                            disableRipple
                            onClick={() => {
                                videoInput.current.click();
                            }}
                            sx={themes.VideoButton(medias)}
                        >
                            <input
                                ref={videoInput}
                                style={{ display: "none" }}
                                type="file"
                                accept="video/*"
                                onChange={handleVideoChange}
                            />
                            <Typography sx={textThemes.Button16DarkGrey}>{t("teacherSettings.questionnaire.uploadVideo")}</Typography>
                            <Box sx={themes.CenteredText}>
                                <Typography sx={textThemes.Body2DarkGrey}>{t("teacherSettings.questionnaire.maximumVideoSize")}</Typography>
                                <Typography sx={textThemes.Body2DarkGrey}>{t("teacherSettings.questionnaire.videoFormats")}</Typography>
                            </Box>
                        </Button>
                    </Box>
                ) : (
                    <Box sx={themes.FormBlock}>
                        <ReactPlayer
                            className="react-player-custom"
                            url={TeacherQuestionnaireStore.temporaryVideoCard}
                            controls
                            width={medias.sm ? "641px" : "100%"}
                            height="auto"
                        />
                        <SButton
                            variant="text"
                            style={{ width: "fit-content" }}
                            onClick={() => {
                                TeacherQuestionnaireStore.setTemporaryVideoCard(null);
                                setChanged(true);
                            }}
                        >
                            {t("teacherSettings.questionnaire.removeVideo")}
                        </SButton>
                    </Box>
                )}

                <Box sx={themes.ButtonsBlock}>
                    <SButton
                        variant="unshadowed"
                        padding="10px 20px 11px 20px"
                        disabled={!changed}
                        onClick={handleSaveClick}
                    >
                        {t("main.save")}
                    </SButton>
                    <SButton
                        variant="secondary"
                        padding="10px 20px 11px 20px"
                        onClick={handleCancelClick}
                        disabled={!changed}
                    >
                        {t("main.cancel")}
                    </SButton>
                </Box>
            </Box>
        </ScrollToTop>
    );
});

export default memo(VideoEdit);
