import React from "react";
import CheckIcon from "assets/CashbackAssets/PNG/IconCheck.png";
import * as themes from "./TutorCashbackThemes";
import * as textThemes from "themes/TextThemes";
import { Box, Typography } from "@mui/material";
import PremiumLvlCard from "./PremiumLvlCard/PremiumLvlCard";
import DefaultLvlCard from "./DefaultLvlCard/DefaultLvlCard";
import Photo1 from "assets/CashbackAssets/PNG/Photo1.png";
import Photo2 from "assets/CashbackAssets/PNG/Photo2.png";
import Photo3 from "assets/CashbackAssets/PNG/Photo3.png";
import Photo4 from "assets/CashbackAssets/PNG/Photo4.png";
import IconCrown from "assets/CashbackAssets/PNG/IconCrown.png";
import { useTranslation } from "react-i18next";
import { memo } from "react";

const TutorCashback = ({ children, ...props }) => {
    const { t } = useTranslation();

    return (
        <Box sx={themes.tutorCashbackBlock}>
            <Box sx={themes.LeftPanel}>
                <Box sx={themes.TitleBlock}>
                    <Typography sx={textThemes.HeadlineH2Black}>{t("promoPages.cashback.cashbackForTutor")}</Typography>
                    <Typography sx={textThemes.Body1Black}>{t("promoPages.cashback.getToTop")}</Typography>
                </Box>
                <Box sx={themes.cashbackFunctionsBlock}>
                    <Box sx={themes.functionElement}>
                        <Box sx={themes.iconElem}>
                            <img
                                src={CheckIcon}
                                alt="CheckIcon"
                            />
                        </Box>
                        <Typography sx={textThemes.Body1Black}>{t("promoPages.cashback.get100Points")}</Typography>
                    </Box>

                    <Box sx={themes.functionElement}>
                        <Box sx={themes.iconElem}>
                            <img
                                src={CheckIcon}
                                alt="CheckIcon"
                            />
                        </Box>
                        <Typography sx={textThemes.Body1Black}>{t("promoPages.cashback.achieveHighRating")}</Typography>
                    </Box>

                    <Box sx={themes.functionElement}>
                        <Box sx={themes.iconElem}>
                            <img
                                src={CheckIcon}
                                alt="CheckIcon"
                            />
                        </Box>
                        <Typography sx={textThemes.Body1Black}>{t("promoPages.cashback.payLowCommission")}</Typography>
                    </Box>

                    <Box sx={themes.functionElement}>
                        <Box sx={themes.iconElem}>
                            <img
                                src={CheckIcon}
                                alt="CheckIcon"
                            />
                        </Box>
                        <Typography sx={textThemes.Body1Black}>{t("promoPages.cashback.increaseIncome")}</Typography>
                    </Box>
                </Box>
            </Box>

            <Box sx={themes.bronzeDefaultLvlCardPosition}>
                <DefaultLvlCard
                    CardPhoto={Photo2}
                    CardName={t("promoPages.cashback.bronzeLevel")}
                    CardSubName={t("promoPages.cashback.beginLevel")}
                    Advantage1={t("promoPages.cashback.4rthPriority")}
                    Advantage2={t("promoPages.cashback.15commission")}
                    Advantage3={t("promoPages.cashback.100pointsForClass")}
                />
            </Box>

            <Box sx={themes.silverDefaultLvlCardPosition}>
                <DefaultLvlCard
                    CardPhoto={Photo1}
                    CardName={t("promoPages.cashback.silverLevel")}
                    CardSubName={t("promoPages.cashback.from300Points")}
                    Advantage1={t("promoPages.cashback.3rdPriority")}
                    Advantage2={t("promoPages.cashback.14commission")}
                    Advantage3={t("promoPages.cashback.100pointsForClass")}
                />
            </Box>

            <Box sx={themes.goldDefaultLvlCardPosition}>
                <DefaultLvlCard
                    CardPhoto={Photo3}
                    CardName={t("promoPages.cashback.goldLevel")}
                    CardSubName={t("promoPages.cashback.from700Points")}
                    Advantage1={t("promoPages.cashback.2ndPriority")}
                    Advantage2={t("promoPages.cashback.13commission")}
                    Advantage3={t("promoPages.cashback.2Cashback")}
                    Advantage4={t("promoPages.cashback.100pointsForClass")}
                />
            </Box>

            <Box sx={themes.goldPremiumLvlCard}>
                <PremiumLvlCard
                    CardPhoto={Photo4}
                    CardName={t("promoPages.cashback.platinumLevel")}
                    CardSubName={t("promoPages.cashback.from1200Points")}
                    Advantage1={t("promoPages.cashback.1stPriority")}
                    Advantage2={t("promoPages.cashback.12commission")}
                    Advantage3={t("promoPages.cashback.2Cashback")}
                    Advantage4={t("promoPages.cashback.personalManager")}
                />
            </Box>

            <Box sx={themes.IconCrownPostition}>
                <img
                    src={IconCrown}
                    alt="IconCrown"
                />
            </Box>
        </Box>
    );
};

export default memo(TutorCashback);
