import React from "react";
import * as photoStyle from "./PhotoContatinerStyle";
import Mouse from "./Mouse.png";
import SmartGirl from "./SmartGirl.png";
import KidHello from "./KidHello.png";
import Lady from "./Lady.png";
import Pair from "./Pair.png";
import Strange from "./Strange.png";
import { useMedias } from "API/mediasHook";
import Box from "@mui/material/Box";
import { memo } from "react";

import { photoBlockUtil } from "./PhotoBlockUtil";

const PhotoBlock = ({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={photoStyle.photoBlock(medias)}>
            <Box sx={photoStyle.Mouse(medias)}>
                <img
                    alt
                    src={Mouse}
                />
            </Box>

            <Box sx={photoStyle.SmartGirl(medias)}>
                <img
                    alt
                    src={SmartGirl}
                />
            </Box>

            <Box sx={photoStyle.KidHello(medias)}>
                <img
                    alt
                    src={KidHello}
                />
            </Box>

            <img
                alt
                src={Lady}
                style={photoStyle.Lady(medias)}
            />

            <img
                alt
                src={Pair}
                style={photoStyle.Pair(medias)}
            />

            <Box sx={photoStyle.Strange(medias)}>
                <img
                    alt
                    src={Strange}
                />
            </Box>
        </Box>
    );
};

export default memo(PhotoBlock);
