import { Typography, styled } from "@mui/material";

export const StyledTutorRejectText = styled(Typography)({
    padding: 0,
    fontFamily: '"Inter", sans-serif',
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
    letterSpacing: "0em",
    color: "rgba(225, 38, 38, 1)",
});
