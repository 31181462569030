import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./InfoBlockThemes";
import { Box } from "@mui/material";
import TitleBlock from "./TitleBlock/TitleBlock";
import TagsBlock from "./TagsBlock/TagsBlock";
import DescriptionBlock from "./DescriptionBlock/DescriptionBlock";

const InfoBlock = observer(({ children, ...props }) => {
    return (
        <Box sx={themes.infoBlockContainerStyle}>
            <TitleBlock
                value={props.value}
                styleMode={props.styleMode}
            ></TitleBlock>
            <TagsBlock value={props.value}></TagsBlock>
            <DescriptionBlock value={props.value}></DescriptionBlock>
        </Box>
    );
});

export default memo(InfoBlock);
