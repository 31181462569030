import React from "react";
import * as themes from "./CashbackDefThemes";
import * as textThemes from "themes/TextThemes";
import { Box, Typography } from "@mui/material";
import CashbackDefFunc from "./CashbackDefFunc/CashbackDefFunc";
import StarsFourPoints from "assets/CashbackAssets/SVG/StarsFourPoints";
import SkillLevelBasic from "assets/CashbackAssets/SVG/SkillLevelBasic";
import Coins from "assets/CashbackAssets/SVG/Coins";
import HandCoinsFill from "assets/CashbackAssets/SVG/HandCoinsFill";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const CashbackDef = ({ children, ...props }) => {
    const { t } = useTranslation();

    return (
        <Box sx={themes.CashbackDefBlock}>
            <Box sx={themes.CashbackDefHeader}>
                <Typography sx={textThemes.HeadlineH2Black}>{t("promoPages.cashback.whatIsCashback")}</Typography>
                <Typography sx={textThemes.Body1Black}>{t("promoPages.cashback.studentCanReturn")}</Typography>
            </Box>

            <CashbackDefFunc
                functionIcon={<StarsFourPoints />}
                functionText={t("promoPages.cashback.tutorGetPoints")}
                theme={themes.CashbackDefFuncStar}
            />

            <CashbackDefFunc
                functionIcon={<SkillLevelBasic />}
                functionText={t("promoPages.cashback.accrualPoints")}
                theme={themes.CashbackDefFuncSkillLevel}
            />

            <CashbackDefFunc
                functionIcon={<HandCoinsFill />}
                functionText={t("promoPages.cashback.studentOrder")}
                theme={themes.CashbackDefFuncHandCoins}
            />

            <CashbackDefFunc
                functionIcon={<Coins />}
                functionText={t("promoPages.cashback.spendLess")}
                theme={themes.CashbackDefFuncCoins}
            />
        </Box>
    );
};

export default memo(CashbackDef);
