import React from "react";

const MoreIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon/24/more">
                <path
                    id="more"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.85714 11.8906C6.79797 11.8906 6.75 11.9386 6.75 11.9978C6.75 12.0569 6.79797 12.1049 6.85714 12.1049C6.91632 12.1049 6.96429 12.0569 6.96429 11.9978C6.96429 11.9386 6.91632 11.8906 6.85714 11.8906ZM5.25 11.9978C5.25 11.1102 5.96954 10.3906 6.85714 10.3906C7.74474 10.3906 8.46429 11.1102 8.46429 11.9978C8.46429 12.8854 7.74474 13.6049 6.85714 13.6049C5.96954 13.6049 5.25 12.8854 5.25 11.9978ZM12.0001 11.8906C11.9409 11.8906 11.8929 11.9386 11.8929 11.9978C11.8929 12.0569 11.9409 12.1049 12.0001 12.1049C12.0593 12.1049 12.1072 12.0569 12.1072 11.9978C12.1072 11.9386 12.0593 11.8906 12.0001 11.8906ZM10.3929 11.9978C10.3929 11.1102 11.1125 10.3906 12.0001 10.3906C12.8877 10.3906 13.6072 11.1102 13.6072 11.9978C13.6072 12.8854 12.8877 13.6049 12.0001 13.6049C11.1125 13.6049 10.3929 12.8854 10.3929 11.9978ZM17.0356 11.9978C17.0356 11.9386 17.0836 11.8906 17.1428 11.8906C17.202 11.8906 17.2499 11.9386 17.2499 11.9978C17.2499 12.0569 17.202 12.1049 17.1428 12.1049C17.0836 12.1049 17.0356 12.0569 17.0356 11.9978ZM17.1428 10.3906C16.2552 10.3906 15.5356 11.1102 15.5356 11.9978C15.5356 12.8854 16.2552 13.6049 17.1428 13.6049C18.0304 13.6049 18.7499 12.8854 18.7499 11.9978C18.7499 11.1102 18.0304 10.3906 17.1428 10.3906Z"
                    fill="#6212FF"
                />
            </g>
        </svg>
    );
};

export default MoreIcon;
