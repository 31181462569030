import { Typography } from "@mui/material";
import React from "react";
import * as themes from "./SupportLinkTextThemes";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import MiniChatStore from "store/MiniChatStore";
import UserStore from "store/UserStore";
import { observer } from "mobx-react-lite";
import AuthorizationStore from "store/AuthorizationStore";
import { useLocation, useNavigate } from "react-router-dom";

const SupportLinkText = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const location = useLocation();

    const showDialog = () => {
        MiniChatStore.SetOpen(true);
    };

    const goSignUp = () => {
        AuthorizationStore.setPreviousLocationPath(location.pathname);
        AuthorizationStore.setCurrentAuthorizationStep("SignIn");
        AuthorizationStore.setPreSMSPage(null);
        AuthorizationStore.setTemporaryEmail("");
        AuthorizationStore.setTemporaryPassword("");
        AuthorizationStore.setTemporaryPhoneNumber("");
        navigate("/authorization");
    };

    return (
        <Typography sx={textThemes.Body2Black}>
            {t("errors.errorAgainPart1")}
            <Typography
                onClick={UserStore.isAuth === true ? showDialog : goSignUp}
                component={"span"}
                sx={{ ...textThemes.Body2Purple, ...themes.linkStyle }}
            >
                {t("errors.errorAgainPart2")}
            </Typography>
        </Typography>
    );
});

export default memo(SupportLinkText);
