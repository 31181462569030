const StarIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M12 0L15.2446 7.53423L23.4127 8.2918L17.2498 13.7058L19.0534 21.7082L12 17.52L4.94658 21.7082L6.75017 13.7058L0.587322 8.2918L8.75543 7.53423L12 0Z"
                fill="#F1B063"
            />
        </svg>
    );
};

export default StarIcon;
