import React, { memo, useState } from "react";
import * as themes from "./NameBlockThemes";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Favorite, FavoriteBorder, SafetyCheck, VerifiedUser } from "@mui/icons-material";
import { ThemeProvider } from "@mui/material/styles";
import CatalogStore from "store/CatalogStore";
import ModalNotAuth from "components/UI/ModalNotAuth/ModalNotAuth";
import { useTranslation } from "react-i18next";
import { useMedias } from "../../../../API/mediasHook";
import UserStore from "store/UserStore";
import FavouritesStore from "store/FavouritesStore";
import Box from "@mui/material/Box";

const NameBlock = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const [dialogOpen, SetDialogOpen] = useState(false);

    const handleFavourites = async () => {
        if (!UserStore.isAuth) {
            showDialog();
            return;
        }

        await toggleFavourites();
    };

    const toggleFavourites = async () => {
        await CatalogStore.ToggleFavourites(props.value);
        await FavouritesStore.ToggleLiked(props.value);
    };

    const showDialog = () => {
        SetDialogOpen(true);
    };

    const hideDialog = () => {
        SetDialogOpen(false);
    };

    const medias = useMedias();

    return (
        <Box sx={themes.outerContainerStyle(medias, props.styleMode ? props.styleMode : 0)}>
            <Box sx={themes.nameBlockContainerStyle(medias, props.styleMode ? props.styleMode : 0)}>
                <Box sx={themes.nameAndActivityStyle(medias, props.styleMode ? props.styleMode : 0)}>
                    <ThemeProvider theme={themes.NameAndActivityTheme}>
                        <Typography sx={themes.nameStyle(props.styleMode ? props.styleMode : 0, medias)}>{props.value.IsAnonymous ? "ФИО скрыто специалистом" : props.value.Name}</Typography>
                        <Tooltip
                            title={
                                <Typography sx={themes.tooltipNameStyle(props.value.IsAnonymous)}>
                                    {props.value.IsVerified
                                        ? props.value.IsAnonymous
                                            ? "Специалист пожелал оставаться анонимным. Все документы специалиста проверены, аккаунт подтверждён"
                                            : "Документы репетитора проверены"
                                        : "Документы репетитора ожидают проверки"}
                                </Typography>
                            }
                            arrow={true}
                            placement="right"
                        >
                            {props.value.IsVerified ? (
                                props.value.IsAnonymous ? (
                                    <VerifiedUser sx={themes.anonymousStyle(props.styleMode ? props.styleMode : 0, medias)}></VerifiedUser>
                                ) : (
                                    <VerifiedUser sx={themes.verifiedStyle(props.styleMode ? props.styleMode : 0, medias)}></VerifiedUser>
                                )
                            ) : (
                                <SafetyCheck sx={themes.safetyCheckStyle}></SafetyCheck>
                            )}
                        </Tooltip>
                        {medias.sm &&
                            (props.value.IsActive ? (
                                <Typography sx={themes.lastSeenActiveStyle(medias)}>{props.value.LastSeen}</Typography>
                            ) : (
                                <Typography sx={themes.lastSeenInactiveStyle(medias)}>{props.value.LastSeen}</Typography>
                            ))}
                    </ThemeProvider>
                </Box>
                {medias.sm && (
                    <Box sx={themes.ratingAndReviewsContainerStyle(medias, props.styleMode ? props.styleMode : 0)}>
                        {props.value.Rating ? (
                            <Box sx={themes.ratingContainerStyle}>
                                <Typography sx={themes.ratingStyle}>{props.value.Rating}</Typography>
                            </Box>
                        ) : null}
                        <Typography sx={themes.reviewsStyle(props.value.Rating)}>{t("main.reviews", { count: props.value.ReviewsCount })}</Typography>
                    </Box>
                )}
                {!medias.sm && (
                    <Box sx={themes.lastSeenBlockMobile(props.styleMode ? props.styleMode : 0)}>
                        {props.value.IsActive ? (
                            <Typography sx={themes.lastSeenActiveStyle(medias)}>{props.value.LastSeen}</Typography>
                        ) : (
                            <Typography sx={themes.lastSeenInactiveStyle(medias)}>{props.value.LastSeen}</Typography>
                        )}

                        <Box sx={themes.ratingAndReviewsContainerStyle(medias, props.styleMode ? props.styleMode : 0)}>
                            {props.value.Rating ? (
                                <Box sx={themes.ratingContainerStyle}>
                                    <Typography sx={themes.ratingStyle}>{props.value.Rating}</Typography>
                                </Box>
                            ) : null}
                            <Typography sx={themes.reviewsStyle(props.value.Rating)}>{t("main.reviews", { count: props.value.ReviewsCount })}</Typography>
                        </Box>
                    </Box>
                )}
            </Box>
            {(medias.sm || props.styleMode === 0) && (
                <ThemeProvider theme={props.value.Liked ? themes.LikeIconActiveTheme(medias, props.styleMode || 0) : themes.LikeIconInactiveTheme(medias, props.styleMode || 0)}>
                    <IconButton
                        onClick={handleFavourites}
                        disableRipple={true}
                    >
                        {props.value.Liked ? <Favorite /> : <FavoriteBorder />}
                    </IconButton>
                </ThemeProvider>
            )}
            <ModalNotAuth
                open={dialogOpen}
                handleClose={hideDialog}
                value={props.value}
                header={"Зарегистрируйтесь, чтобы добавить в избранное"}
                content={"Вы сможете добавлять понравившихся репетиторов в избранное, чтобы не потерять их"}
            ></ModalNotAuth>
        </Box>
    );
});

export default memo(NameBlock);
