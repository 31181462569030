import React, { useEffect, useMemo, useRef, useState } from "react";

import { Box, sliderClasses, Typography } from "@mui/material";

import IncomingMessageCard from "components/ChatBodyBlock/GridFieldChat/IncomingMessageCard/IncomingMessageCard";
import OutcomingMessageCard from "components/ChatBodyBlock/GridFieldChat/OutcomingMessageCard/OutcomingMessageCard";
import { reverseMassiv, getDateGroups } from "components/ChatBodyBlock/CommonData/helper_functions";

import VideoChatStore from "store/VideoChatStore";
import { observer } from "mobx-react-lite";
import * as textStyles from "themes/TextThemes";
import * as themes from "./ChatForVideoStyles";

const MessagesBox = ({ sendJsonMessage, SetOpenEditModalMessage, SetOpenReplyModalMessage, SetText, SetDisable, disable }) => {
    const boxRef = useRef(null);

    const [messagesList, SetMessagesList] = useState([]);

    const [dateGroups, SetDateGroups] = useState([]);

    const Load = () => {
        const scrollElement = boxRef.current;

        if (scrollElement.scrollTop === 0) {
            VideoChatStore.SetNoScroll(true);
            VideoChatStore.GetForScrollMessagesNoLimit(sendJsonMessage);
        }
    };

    useEffect(() => {
        if (VideoChatStore.chatData.id) {
            let messagesListReversed = reverseMassiv(VideoChatStore.chatData.tabsData);

            let dateGroupsFromList = getDateGroups(messagesListReversed);

            SetMessagesList(messagesListReversed);
            SetDateGroups(dateGroupsFromList);
        }
    }, [VideoChatStore.chatData.tabsData.messages, VideoChatStore.chatData.id, VideoChatStore.chatData.newLoadedMessages]);

    useEffect(() => {
        if (messagesList.length) {
            let lastMessageOwner = messagesList.at(-1).owner.uuid;

            let isScroolledUp = VideoChatStore.chatData.newLoadedMessages?.length;

            if (VideoChatStore.chatData.noScroll) {
                VideoChatStore.SetNoScroll(false);
                if (VideoChatStore.chatData.lastMessage) {
                    const scrollElement = boxRef.current;

                    const elementTo = document.getElementById(VideoChatStore.chatData.lastMessage);

                    console.log(elementTo);
                    scrollElement.scrollTop = elementTo.offsetTop - 100;
                }
            } else if (lastMessageOwner !== VideoChatStore.chatData.participantId || isScroolledUp === undefined) {
                const scrollElement = boxRef.current;

                scrollElement.scrollTop = scrollElement.scrollHeight;
            } else {
                VideoChatStore.SetNewLoadedMessages(undefined);
            }
        }
    }, [messagesList.length]);

    return (
        <Box
            ref={boxRef}
            onScroll={Load}
            sx={{ overflow: "auto", flexGrow: 1, height: "60vh" }}
        >
            {dateGroups.map((group, groupIndex) =>
                messagesList.map((message, messageIndex) => (
                    <Box key={groupIndex + messageIndex}>
                        {messageIndex === 0 && (
                            <Box sx={themes.MessageGroup}>
                                <Typography sx={textStyles.Caption1Grey}>{group}</Typography>
                            </Box>
                        )}
                        {message.formattedDate === group &&
                            (message.owner !== VideoChatStore.chatData.participantId ? (
                                <Box id={message.message_id}>
                                    <OutcomingMessageCard
                                        diploma={true}
                                        message={message}
                                        chat_id={VideoChatStore.chatData.id}
                                        disable={disable}
                                        handleDisable={SetDisable}
                                        handleOpenEditModalMessage={SetOpenEditModalMessage}
                                        handleOpenReplyModalMessage={SetOpenReplyModalMessage}
                                        handleText={SetText}
                                        reply_to_message={
                                            "reply_to_message" in message && message.reply_to_message
                                                ? VideoChatStore.chatData.tabsData.messages.find((element) => element.message_id === message.reply_to_message)?.message
                                                : null
                                        }
                                    />
                                </Box>
                            ) : (
                                <Box id={message.message_id}>
                                    <IncomingMessageCard
                                        diploma={true}
                                        message={message}
                                        chat_id={VideoChatStore.chatData.id}
                                        disable={disable}
                                        handleDisable={SetDisable}
                                        handleOpenReplyModalMessage={SetOpenReplyModalMessage}
                                        handleText={SetText}
                                    />
                                </Box>
                            ))}
                    </Box>
                )),
            )}
        </Box>
    );
};

export default observer(MessagesBox);
