import React from "react";

const Coins = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="54"
            height="55"
            viewBox="0 0 54 55"
            fill="none"
        >
            <path
                d="M39.5312 19.2436V18.0469C39.5312 12.6586 31.4037 8.59375 20.625 8.59375C9.84629 8.59375 1.71875 12.6586 1.71875 18.0469V26.6406C1.71875 31.1287 7.3584 34.6951 15.4688 35.7629V36.9531C15.4688 42.3414 23.5963 46.4062 34.375 46.4062C45.1537 46.4062 53.2812 42.3414 53.2812 36.9531V28.3594C53.2812 23.9121 47.8199 20.3414 39.5312 19.2436ZM49.8438 28.3594C49.8438 31.1996 43.2287 34.375 34.375 34.375C33.5736 34.375 32.7787 34.3471 31.9945 34.2955C36.6287 32.6068 39.5312 29.8633 39.5312 26.6406V22.7176C45.9486 23.6736 49.8438 26.2689 49.8438 28.3594ZM15.4688 32.2803V27.1691C17.1784 27.3919 18.9009 27.5024 20.625 27.5C22.3491 27.5024 24.0716 27.3919 25.7812 27.1691V32.2803C24.0741 32.5325 22.3507 32.6581 20.625 32.6562C18.8993 32.6581 17.1759 32.5325 15.4688 32.2803ZM36.0938 23.6178V26.6406C36.0938 28.4432 33.4275 30.3789 29.2188 31.5541V26.5332C31.9924 25.8607 34.3406 24.8596 36.0938 23.6178ZM20.625 12.0312C29.4787 12.0312 36.0938 15.2066 36.0938 18.0469C36.0938 20.8871 29.4787 24.0625 20.625 24.0625C11.7713 24.0625 5.15625 20.8871 5.15625 18.0469C5.15625 15.2066 11.7713 12.0312 20.625 12.0312ZM5.15625 26.6406V23.6178C6.90938 24.8596 9.25762 25.8607 12.0312 26.5332V31.5541C7.82246 30.3789 5.15625 28.4432 5.15625 26.6406ZM18.9062 36.9531V36.0572C19.4713 36.0787 20.0428 36.0938 20.625 36.0938C21.4586 36.0938 22.2729 36.0658 23.0721 36.0186C23.9599 36.3364 24.8643 36.6061 25.7812 36.8264V41.8666C21.5725 40.6914 18.9062 38.7557 18.9062 36.9531ZM29.2188 42.5928V37.4687C30.9279 37.6985 32.6505 37.8133 34.375 37.8125C36.0991 37.8149 37.8216 37.7044 39.5312 37.4816V42.5928C36.112 43.0941 32.638 43.0941 29.2188 42.5928ZM42.9688 41.8666V36.8457C45.7424 36.1732 48.0906 35.1721 49.8438 33.9303V36.9531C49.8438 38.7557 47.1775 40.6914 42.9688 41.8666Z"
                fill="#6212FF"
            />
        </svg>
    );
};

export default Coins;
