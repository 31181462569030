import React, { useState } from "react";
import * as themes from "./StoryThemes";
import PropTypes from "prop-types";
import { Dialog, IconButton, Grid, Drawer } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Stories from "stories-react";
import "stories-react/dist/index.css";
import Close from "assets/CommonAssets/SVG/close";
import "./Story.css";
import Box from "@mui/material/Box";
import { memo } from "react";
import { observer } from "mobx-react-lite";
import GetBonusesStory from "./GetBonuses/GetBonusesStory";
import WhatToDoStory from "./GetBonuses/WhatToDoStory";
import BonusesCreditTimeStory from "./GetBonuses/BonusesCreditTimeStory";
import GetBonusesFormStory from "./GetBonuses/GetBonusesFormStory";
import ChatBotStory from "./ChatBot/ChatBotStory";
import CashbackStory from "./Cashback/CashbackStory";
import HowItWorksStory from "./Cashback/HowItWorksStory";
import WhatAboutBonusesStory from "./Cashback/WhatAboutBonusesStory";
import AlreadyUsedCashbackStory from "./Cashback/AlreadyUsedCashbackStory";
import DiplomaStory from "./Diploma/DiplomaStory";
import HowToOrderStory from "./Diploma/HowToOrderStory";
import UnderControlStory from "./Diploma/UnderControlStory";
import OrderWorkStory from "./Diploma/OrderWorkStory";
import FindTutorStory from "./FindTutor/FindTutorStory";
import YourChoiceStory from "./FindTutor/YourChoiceStory";
import HowSearchWorksStory from "./FindTutor/HowSearchWorksStory";
import WantFindByYourselfStory from "./FindTutor/WantFindByYourselfStory";
import WhatNextStory from "./FindTutor/WhatNextStory";
import HowToHelpStory from "./FindTutor/HowToHelpStory";
import IncreaseMotivationStory from "./IncreaseMotivation/IncreaseMotivationStory";
import HelpIncreaseStory from "./IncreaseMotivation/HelpIncreaseStory";
import TutorEncourageStory from "./IncreaseMotivation/TutorEncourageStory";
import HelpSaveStory from "./IncreaseMotivation/HelpSaveStory";
import TutorSmilingStory from "./IncreaseMotivation/TutorSmilingStory";
import ReadInBlogStory from "./IncreaseMotivation/ReadInBlogStory";
import { RightIcon } from "./StoryThemes";
import { useMedias } from "API/mediasHook";
import MyClassesDrawer from "components/MyClassesBodyBlock/MyClassesDrawer";

const Story = observer(({ open, onClose, index, change, disl, disr }) => {
    const medias = useMedias();

    const [closeHovered, setCloseHovered] = useState(false);

    const [leftHovered, setLeftHovered] = useState(false);

    const [rightHovered, setRightHovered] = useState(false);

    const stories = [
        [
            {
                type: "component",
                seeMore: false,
                component: GetBonusesStory,
                like: false,
                dislike: false,
            },
            {
                type: "component",
                seeMore: false,
                component: WhatToDoStory,
                like: false,
                dislike: false,
            },
            {
                type: "component",
                seeMore: false,
                component: BonusesCreditTimeStory,
                like: false,
                dislike: false,
            },
            {
                type: "component",
                seeMore: false,
                component: GetBonusesFormStory,
                like: false,
                dislike: false,
            },
        ],
        [
            {
                type: "component",
                seeMore: false,
                component: ChatBotStory,
                like: false,
                dislike: false,
            },
        ],
        [
            {
                type: "component",
                seeMore: false,
                component: CashbackStory,
                like: false,
                dislike: false,
            },
            {
                type: "component",
                seeMore: false,
                component: HowItWorksStory,
                like: false,
                dislike: false,
            },
            {
                type: "component",
                seeMore: false,
                component: WhatAboutBonusesStory,
                like: false,
                dislike: false,
            },
            {
                type: "component",
                seeMore: false,
                component: AlreadyUsedCashbackStory,
                like: false,
                dislike: false,
            },
        ],
        [
            {
                type: "component",
                seeMore: false,
                component: DiplomaStory,
                like: false,
                dislike: false,
            },
            {
                type: "component",
                seeMore: false,
                component: HowToOrderStory,
                like: false,
                dislike: false,
            },
            {
                type: "component",
                seeMore: false,
                component: UnderControlStory,
                like: false,
                dislike: false,
            },
            {
                type: "component",
                seeMore: false,
                component: OrderWorkStory,
                like: false,
                dislike: false,
            },
        ],
        [
            {
                type: "component",
                seeMore: false,
                component: FindTutorStory,
                like: false,
                dislike: false,
            },
            {
                type: "component",
                seeMore: false,
                component: YourChoiceStory,
                like: false,
                dislike: false,
            },
            {
                type: "component",
                seeMore: false,
                component: HowSearchWorksStory,
                like: false,
                dislike: false,
            },
            {
                type: "component",
                seeMore: false,
                component: WantFindByYourselfStory,
                like: false,
                dislike: false,
            },
            {
                type: "component",
                seeMore: false,
                component: WhatNextStory,
                like: false,
                dislike: false,
            },
            {
                type: "component",
                seeMore: false,
                component: HowToHelpStory,
                like: false,
                dislike: false,
            },
        ],
        [
            {
                type: "component",
                seeMore: false,
                component: IncreaseMotivationStory,
                like: false,
                dislike: false,
            },
            {
                type: "component",
                seeMore: false,
                component: HelpIncreaseStory,
                like: false,
                dislike: false,
            },
            {
                type: "component",
                seeMore: false,
                component: TutorEncourageStory,
                like: false,
                dislike: false,
            },
            {
                type: "component",
                seeMore: false,
                component: HelpSaveStory,
                like: false,
                dislike: false,
            },
            {
                type: "component",
                seeMore: false,
                component: TutorSmilingStory,
                like: false,
                dislike: false,
            },
            {
                type: "component",
                seeMore: false,
                component: ReadInBlogStory,
                like: false,
                dislike: false,
            },
        ],
    ];

    const MobileVersion = (
        <Box sx={themes.StoryContent(medias)}>
            <IconButton
                disableRipple
                onClick={onClose}
                onMouseEnter={() => setCloseHovered(true)}
                onMouseLeave={() => setCloseHovered(false)}
                sx={themes.CloseIcon(medias)}
            >
                <Close hovered={closeHovered} />
            </IconButton>
            <Stories
                height={"auto"}
                classNames={themes.Stories}
                stories={stories[index]}
            />
        </Box>
    );

    return (
        <>
            {medias.sm ? (
                <Dialog
                    open={open}
                    onClose={onClose}
                    PaperProps={themes.StoryDialog}
                    scroll="body"
                >
                    <Grid sx={themes.StoryContainer}>
                        <IconButton
                            onMouseEnter={() => setLeftHovered(true)}
                            onMouseLeave={() => setLeftHovered(false)}
                            sx={themes.ChangeStoryButton(leftHovered)}
                            disableRipple
                            disabled={disl}
                            onClick={() => {
                                change(index - 1);
                            }}
                        >
                            <KeyboardArrowLeftIcon sx={themes.LeftIcon(disl)} />
                        </IconButton>
                        <Box sx={themes.StoryContent(medias)}>
                            <IconButton
                                disableRipple
                                onClick={onClose}
                                onMouseEnter={() => setCloseHovered(true)}
                                onMouseLeave={() => setCloseHovered(false)}
                                sx={themes.CloseIcon}
                            >
                                <Close hovered={closeHovered} />
                            </IconButton>
                            <Stories
                                height={"auto"}
                                classNames={themes.Stories}
                                stories={stories[index]}
                            />
                        </Box>
                        <IconButton
                            onMouseEnter={() => setRightHovered(true)}
                            onMouseLeave={() => setRightHovered(false)}
                            sx={themes.ChangeStoryButton(rightHovered)}
                            disableRipple
                            disabled={disr}
                            onClick={() => {
                                change(index + 1);
                            }}
                        >
                            <KeyboardArrowRightIcon sx={themes.RightIcon(disr)} />
                        </IconButton>
                    </Grid>
                </Dialog>
            ) : (
                <MyClassesDrawer
                    open={open}
                    onClose={onClose}
                >
                    {MobileVersion}
                </MyClassesDrawer>
            )}
        </>
    );
});

Story.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default memo(Story);
