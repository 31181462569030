const InfoIcon = function (props) {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon-tooltip">
                <path
                    id="Vector"
                    d="M8 1.16667C9.69168 1.16807 11.3309 1.75411 12.64 2.82554C13.9491 3.89697 14.8477 5.38793 15.1836 7.04593C15.5194 8.70393 15.2719 10.4271 14.483 11.9235C13.694 13.4199 12.4121 14.5977 10.8543 15.2573C9.06218 16.0144 7.0427 16.0284 5.24016 15.2965C3.43763 14.5645 1.99968 13.1465 1.24267 11.3543C0.485651 9.56218 0.471571 7.5427 1.20353 5.74016C1.93548 3.93763 3.35351 2.49968 5.14567 1.74267C6.04865 1.36054 7.01949 1.16462 8 1.16667ZM8 0.5C3.58167 0.5 0 4.08167 0 8.5C0 12.9183 3.58167 16.5 8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08167 12.4183 0.5 8 0.5Z"
                    fill="#77778A"
                />
                <path
                    id="Vector_2"
                    d="M6.9502 5.32734C6.9502 5.11967 7.01178 4.91667 7.12715 4.744C7.24253 4.57132 7.40652 4.43674 7.59838 4.35727C7.79024 4.2778 8.00136 4.25701 8.20504 4.29752C8.40872 4.33803 8.59581 4.43804 8.74266 4.58488C8.8895 4.73173 8.98951 4.91882 9.03002 5.1225C9.07053 5.32618 9.04974 5.5373 8.97027 5.72916C8.8908 5.92102 8.75622 6.08501 8.58354 6.20039C8.41087 6.31576 8.20787 6.37734 8.0002 6.37734C7.72258 6.37457 7.45712 6.26305 7.2608 6.06674C7.06449 5.87042 6.95297 5.60496 6.9502 5.32734ZM7.1252 7.89068H8.8752V12.724H7.1252V7.89068Z"
                    fill="#77778A"
                />
            </g>
        </svg>
    );
};

export default InfoIcon;
