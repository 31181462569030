import { Button, styled } from "@mui/material";

export const StyLedSubmitButton = styled(Button)({
    display: "flex",
    width: "180px",
    marginTop: "30px",
    fontSize: "16px",
    fontFamily: '"Inter", sans-serif',

    borderRadius: "42px",
    backgroundColor: "#6212FF",
    textTransform: "none",
    padding: "9px 0",
    transition: "opacity 0.5s",

    "&:hover": {
        backgroundColor: "#6212FF",
        opacity: 0.8,
        transition: "opacity 0.5s",
    },
    "&.MuiButton-contained.Mui-disabled": {
        color: "rgba(255, 255, 255, 1)",
        backgroundColor: "rgba(176, 136, 255, 1)",
    },
});

export const ArrowBackIconStyles = { fontSize: "18px" };

export const RenewOrderMarginBot = { marginBottom: "30px" };

export const RenewOrderCloseIconPosition = {
    display: "flex",
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    border: "1px solid",
    borderColor: "rgba(34, 34, 34, 1)",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    cursor: "pointer",
};
