import React, { memo, useState } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./StickyActionBlockThemes";
import { Button, Card, Divider, IconButton, Snackbar, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import { CheckCircleSharp, CloseRounded, SafetyCheck, VerifiedUser } from "@mui/icons-material";
import TimeIcon from "assets/CommonAssets/SVG/TimeIcon";
import ModalResponse from "./ModalResponse";
import OrderStore from "store/OrderStore";

const StickyActionBlock = observer(({ children, ...props }) => {
    const [dialogResponseOpen, SetDialogResponseOpen] = useState(false);

    const [snackBarOpen, SetSnackBarOpen] = useState(false);

    const showResponseDialog = (mode) => {
        console.log(mode);
        OrderStore.SetDialogResponseMode(mode);
        OrderStore.PrefillResponse();
        SetDialogResponseOpen(true);
    };

    const discardResponseDialog = () => {
        SetDialogResponseOpen(false);
        OrderStore.SetDialogResponseMode(0);
        OrderStore.ClearModalResponse();
    };

    const sendResponseDialog = () => {
        SetDialogResponseOpen(false);
        OrderStore.SetDialogResponseMode(0);
        OrderStore.SetActualReview(0);
        OrderStore.ClearModalResponse();
        showSnackBar();
    };

    const removeReview = () => {
        OrderStore.SetActualReview(1);
    };

    const showSnackBar = () => {
        SetSnackBarOpen(true);
    };

    const hideSnackBar = () => {
        SetSnackBarOpen(false);
    };

    return (
        <Box sx={themes.stickyActionBlockContainerStyle(OrderStore.actualReview)}>
            <Card sx={themes.stickyActionCardStyle}>
                <Box sx={themes.nameBlockStyle}>
                    <ThemeProvider theme={themes.AvatarTheme}>
                        <Avatar src={props.value.AvatarSrc}></Avatar>
                    </ThemeProvider>
                    <Box sx={themes.nameContainerStyle}>
                        <Typography sx={themes.nameStyle}>{props.value.StudentFIO}</Typography>
                        <Typography sx={themes.lastSeenStyle}>{props.value.LastSeen}</Typography>
                    </Box>
                </Box>
                <Box sx={themes.infoBlockStyle}>
                    <Box sx={themes.infoContainerStyle}>
                        {props.value.IsVerified ? <VerifiedUser sx={themes.verifiedStyle}></VerifiedUser> : <SafetyCheck sx={themes.safetyCheckStyle}></SafetyCheck>}
                        <Typography sx={themes.textStyle}>{props.value.IsVerified ? "подтверждены номер и почта" : "информация ожидает подтверждения"}</Typography>
                    </Box>
                    <Box sx={themes.infoContainerStyle}>
                        <TimeIcon
                            width={16}
                            heigth={16}
                            fill={"#9363F1"}
                            fillOpacity={1}
                            sx={themes.timeStyle}
                        ></TimeIcon>
                        <Typography sx={themes.textStyle}>на StudyBuddy c {props.value.RegistrationDate.toLocaleString("default", { day: "numeric", month: "long", year: "numeric" })}</Typography>
                    </Box>
                </Box>
            </Card>
            {!Object.keys(OrderStore.actualReview).length ? (
                <Box sx={themes.stickyActionsContainerStyle}>
                    {props.value.Price?.PriceFrom ? (
                        props.value.Price?.PriceTo ? (
                            <Typography sx={themes.priceTextStyle(0)}>
                                {props.value.Price.PriceFrom.toLocaleString()} - {props.value.Price.PriceTo.toLocaleString()} ₽/ч
                            </Typography>
                        ) : (
                            <Typography sx={themes.priceTextStyle(0)}>{props.value.Price.PriceFrom.toLocaleString()} ₽/ч</Typography>
                        )
                    ) : (
                        <Typography sx={themes.priceTextStyle(1)}>по договоренности</Typography>
                    )}
                    <ThemeProvider theme={themes.ResponseButtonTheme}>
                        <Button
                            onClick={showResponseDialog.bind(this, 0)}
                            disableRipple
                        >
                            <Typography style={themes.responseTextStyle}>Откликнуться</Typography>
                        </Button>
                    </ThemeProvider>
                </Box>
            ) : (
                <Box sx={themes.actualReviewContainerStyle}>
                    <Box sx={themes.reviewInfoContainerStyle}>
                        <Typography sx={themes.reviewHeaderStyle}>Твой отклик</Typography>
                        <Divider sx={themes.dividerStyle(20, 12)}></Divider>
                        {!OrderStore.actualReview.negotiatedPrice ? (
                            <Box sx={themes.priceContainerStyle}>
                                <Box sx={themes.priceInfoMainContainerStyle}>
                                    <Box sx={themes.priceInfoMainItemStyle}>
                                        <Typography sx={themes.priceInfoMainTextStyle(0)}>Цена для заказчика</Typography>
                                        <Typography sx={themes.priceInfoMainTextStyle(1)}>{OrderStore.actualReview.proposedPrice.toLocaleString()} ₽</Typography>
                                    </Box>
                                    <Box sx={themes.priceInfoMainItemStyle}>
                                        <Typography sx={themes.priceInfoMainTextStyle(0)}>Комиссия сервиса -{OrderStore.comissionPercent}%</Typography>
                                        <Typography sx={themes.priceInfoMainTextStyle(1)}>{(OrderStore.actualReview.proposedPrice * (1 - OrderStore.multiplier)).toLocaleString()} ₽</Typography>
                                    </Box>
                                </Box>
                                <Box sx={themes.priceInfoTotalItemStyle}>
                                    <Typography sx={themes.priceInfoTotalTextStyle}>Твой гонорар</Typography>
                                    <Typography sx={themes.priceInfoTotalTextStyle}>{OrderStore.actualReview.totalPrice.toLocaleString()} ₽</Typography>
                                </Box>
                            </Box>
                        ) : (
                            <Box>
                                <Box sx={themes.priceInfoMainContainerStyle}>
                                    <Box sx={themes.priceInfoMainItemStyle}>
                                        <Typography sx={themes.priceInfoMainTextStyle(0)}>Цена</Typography>
                                        <Typography sx={themes.priceInfoMainTextStyle(1)}>по договоренности</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        <Divider sx={themes.dividerStyle(12, 20)}></Divider>
                        <Box sx={themes.responseMessageContainerStyle}>
                            {OrderStore.actualReview.negotiatedPrice ? (
                                <ThemeProvider theme={themes.SetPriceButtonTheme}>
                                    <Button
                                        onClick={showResponseDialog.bind(this, 1)}
                                        disableRipple
                                    >
                                        <Typography style={themes.setPriceTextStyle}>Указать цену</Typography>
                                    </Button>
                                </ThemeProvider>
                            ) : null}
                            <Box sx={themes.reviewTextContainerStyle}>
                                <Typography sx={themes.reviewTextStyle}>{OrderStore.actualReview.responseMessage}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Typography
                        sx={themes.removeReviewTextStyle}
                        onClick={removeReview}
                    >
                        Отменить
                    </Typography>
                </Box>
            )}
            <ModalResponse
                value={props.value}
                open={dialogResponseOpen}
                handleDiscard={discardResponseDialog}
                handleSave={sendResponseDialog}
            ></ModalResponse>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={snackBarOpen}
                autoHideDuration={3000}
                onClose={hideSnackBar}
            >
                <Box sx={themes.snackBarContentStyle}>
                    <ThemeProvider theme={themes.CheckIconTheme}>
                        <CheckCircleSharp />
                    </ThemeProvider>
                    <Typography sx={themes.snackTextStyle}>Отклик отправлен</Typography>
                    <ThemeProvider theme={themes.CloseSnackBarIconTheme}>
                        <IconButton
                            onClick={hideSnackBar}
                            disableRipple
                        >
                            <CloseRounded></CloseRounded>
                        </IconButton>
                    </ThemeProvider>
                </Box>
            </Snackbar>
        </Box>
    );
});

export default memo(StickyActionBlock);
