import React, { memo } from "react";
import SearchBlock from "./SearchBlock/SearchBlock";
import PhotoBlock from "./PhotoBlock/PhotoBlock";
import * as themes from "./StartBlockStyle";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import { useMedias } from "API/mediasHook";

const StartBlock = observer(({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={themes.topBlock(medias)}>
            <SearchBlock />
            <PhotoBlock />
        </Box>
    );
});

export default memo(StartBlock);
