import React from "react";

const ProfileInactiveIcon = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="16"
                cy="16"
                r="16"
                fill="#E12626"
            />
            <rect
                width="21.3333"
                height="21.3333"
                transform="translate(5.33325 5.33203)"
                fill="#E12626"
            />
            <path
                d="M16.0048 7.10938C11.0997 7.10938 7.11108 11.098 7.11108 15.9983C7.11108 20.8985 11.0997 24.8871 16 24.8871C20.9002 24.8871 24.8889 20.8985 24.8889 15.9983C24.8889 11.098 20.905 7.10938 16.0048 7.10938ZM16.0048 22.4949C15.3778 22.4949 14.8666 21.9837 14.8666 21.3567C14.8666 20.7297 15.3778 20.2184 16.0048 20.2184C16.6318 20.2184 17.143 20.7297 17.143 21.3567C17.143 21.9837 16.6318 22.4949 16.0048 22.4949ZM17.635 11.4067L16.6029 18.3616C16.5546 18.6943 16.299 18.9355 16 18.9355C15.7009 18.9355 15.4453 18.6943 15.3971 18.3616L14.365 11.4067C14.2878 10.8858 14.4325 10.3505 14.7556 9.97427C15.0161 9.67042 15.3682 9.50161 15.7395 9.50161H16.2556C16.627 9.50161 16.9791 9.67042 17.2395 9.97427C17.5675 10.3505 17.7122 10.8858 17.635 11.4067Z"
                fill="white"
            />
        </svg>
    );
};

export default ProfileInactiveIcon;
