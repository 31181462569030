import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./ReviewsBodyThemes";
import Box from "@mui/material/Box";
import TeacherStore from "store/TeacherStore";
import ReviewCard from "components/UI/ReviewCard/ReviewCard";
import { useMedias } from "../../../../../API/mediasHook";

const ReviewsBody = observer(({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={themes.reviewsBodyContainerStyle}>
            {TeacherStore.reviewsListFiltered.map((value, index) => (
                <Box key={value.ID}>
                    <Box sx={themes.horizontalSeparatorStyle(medias)}></Box>
                    <ReviewCard
                        value={value}
                        tutor={props.value}
                    ></ReviewCard>
                </Box>
            ))}
        </Box>
    );
});

export default memo(ReviewsBody);
