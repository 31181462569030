import React from "react";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import * as theme2 from "components/OrdersBodyBlock/OrderCard/StyledOrderCardThemes";
import { Box, Divider, Typography } from "@mui/material";
import OrderDate from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/OrderDate/OrderDate";
import TargetBoxRounded from "components/OrdersBodyBlock/TutorOrders/OrderCards/CommonCardComponents/TargetBoxRounded/TargetBoxRounded";
import OrderSoloAvatar from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/OrderSoloAvatar/OrderSoloAvatar";
import HiddenInfo from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/HiddenInfo/HiddenInfo";
import OrderStatusRounded from "components/OrdersBodyBlock/TutorOrders/OrderCards/CommonCardComponents/OrderStatusRounded/OrderStatusRounded";
import PriceFormater from "components/OrdersBodyBlock/TutorOrders/OrderCards/CommonCardComponents/PriceFormater/PriceFormater";
import { memo } from "react";
import * as textThemes from "themes/TextThemes";
import * as themes from "./CompletedCardThemes";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";

const CompletedCard = ({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <Box sx={theme2.StyledOrderCard(medias)}>
            <Box sx={themes.TopBlock}>
                <Box sx={themes.TopCardWrapper}>
                    <Typography
                        variant="h6"
                        sx={textThemes.HeadlineH5Black}
                    >
                        {props.orderInfo.title}
                    </Typography>
                    <OrderDate date={props.orderInfo.date} />
                </Box>
                <Box sx={themes.TargetSubjWrapper}>
                    <TargetBoxRounded>{props.orderInfo.target}</TargetBoxRounded>
                    <TargetBoxRounded>{props.orderInfo.subject}</TargetBoxRounded>
                </Box>
            </Box>

            <Box>
                <Box sx={themes.AvatarPriceWrapper}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                        {!props.orderInfo.hiddenUser && (
                            <OrderSoloAvatar
                                name={props.orderInfo.name}
                                avatar={props.orderInfo.avatar}
                            />
                        )}
                        {props.orderInfo.hiddenUser && <HiddenInfo />}
                        {props.orderInfo.lessonAssessment && (
                            <Box sx={themes.LessonAssessmentWrapper}>
                                <Typography sx={themes.LessonAssessmentText}>{props.orderInfo.lessonAssessment}</Typography>
                                <StarRateRoundedIcon sx={themes.LessonAssessmentIcon} />
                            </Box>
                        )}
                    </Box>

                    <Box>
                        <Typography
                            variant="span"
                            sx={textThemes.Body2Black}
                        >
                            {t("orders.tutorOrders.price")}
                        </Typography>
                        <PriceFormater price={props.orderInfo.price} />
                    </Box>
                </Box>

                <Box>
                    <Divider sx={themes.divider} />
                    <Box sx={themes.BottomCardWrapper}>
                        <Box sx={themes.DeadlineWrapper}>
                            <Typography
                                variant="span"
                                sx={textThemes.Body2Grey}
                            >
                                {t("orders.tutorOrders.completionDate")}
                            </Typography>
                            <Typography sx={textThemes.Body2Black}>{props.orderInfo.deadline}</Typography>
                        </Box>
                        <OrderStatusRounded status={props.orderInfo.status} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default memo(CompletedCard);
