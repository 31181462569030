import React from "react";
import * as themes from "./SheduleBodyBlockThemes";
import { Box, Divider } from "@mui/material";
import SheduleHeader from "./SheduleHeader/SheduleHeader";
import SheduleTable from "./SheduleTable/SheduleTable";
import useWeekDates from "./SheduleHeader/WeekHook/useWeekDates";
import WeekDates from "./WeekDates/WeekDates";
import { memo } from "react";

const SheduleBodyBlock = ({ children, ...props }) => {
    const { addWeekToDate, backWeekToDate, weekDates, backToCurrentDate, currentDate } = useWeekDates();

    return (
        <Box sx={themes.SheduleContainer}>
            <Box sx={themes.Header}>
                <SheduleHeader
                    addWeekToDate={addWeekToDate}
                    backWeekToDate={backWeekToDate}
                    weekDates={weekDates}
                    backToCurrentDate={backToCurrentDate}
                />
                <Divider sx={themes.Divider} />
                <WeekDates
                    weekDates={weekDates}
                    currentDate={currentDate}
                />
            </Box>
            <SheduleTable
                weekDates={weekDates}
                currentDate={currentDate}
            />
        </Box>
    );
};

export default memo(SheduleBodyBlock);
