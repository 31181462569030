import { makeAutoObservable } from "mobx";

const today = new Date();

const todayDay = today.getDate();

const todayMonth = today.getMonth();

const todayYear = today.getFullYear();

let nextMonth = null;

if (todayMonth !== 11) {
    nextMonth = new Date(todayYear, todayMonth + 1, todayDay);
} else {
    nextMonth = new Date(todayYear + 1, 0, todayDay);
}

const minDateStart = today;

const minDateEnd = nextMonth;

class RequestStore {
    storage = [
        {
            substeps: [
                {
                    title: "С чем нужно помочь?",
                    label: "Задача",
                    value: "",
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 1,
                            nextSubStep: 0,
                            previousStep: 0,
                            previousSubStep: 0,
                        },
                        {
                            description: "написание",
                            nextStep: 9,
                            nextSubStep: 0,
                            previousStep: 0,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: {
                        text: "Если вам нужна помощь по нескольким задачам, то для каждой нужно создать свой заказ, и вы получите отклики репетиторов с нужным опытом",
                        icon: 1,
                    },
                },
            ],
            type: "universal",
        },
        {
            substeps: [
                {
                    title: "Кто будет заниматься?",
                    label: "Для кого",
                    value: null,
                    description: null,
                    routes: [
                        {
                            description: "Школьник",
                            nextStep: 1,
                            nextSubStep: 1,
                            previousStep: 0,
                            previousSubStep: 0,
                        },
                        {
                            description: "Студент",
                            nextStep: 1,
                            nextSubStep: 2,
                            previousStep: 0,
                            previousSubStep: 0,
                        },
                        {
                            description: "Взрослый",
                            nextStep: 2,
                            nextSubStep: 0,
                            previousStep: 0,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
                {
                    title: "В каком классе ученик?",
                    label: "Для кого",
                    value: null,
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 2,
                            nextSubStep: 0,
                            previousStep: 1,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
                {
                    title: "На каком курсе обучаетесь?",
                    label: "Для кого",
                    value: null,
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 2,
                            nextSubStep: 0,
                            previousStep: 1,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
            ],
            type: "tutor",
        },
        {
            substeps: [
                {
                    title: "Какая цель занятий?",
                    label: "Цель",
                    value: null,
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 3,
                            nextSubStep: 0,
                            previousStep: 1,
                            previousSubStep: 0,
                        },
                        {
                            description: "Подготовка к экзамену в ВУЗе",
                            nextStep: 2,
                            nextSubStep: 1,
                            previousStep: 1,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
                {
                    title: "В каком ВУЗе обучаетесь?",
                    label: "Цель",
                    value: [false, ""],
                    description: "",
                    routes: [
                        {
                            description: null,
                            nextStep: 2,
                            nextSubStep: 2,
                            previousStep: 2,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
                {
                    title: "Сколько осталось до экзамена?",
                    label: "Цель",
                    value: null,
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 3,
                            nextSubStep: 0,
                            previousStep: 2,
                            previousSubStep: 1,
                        },
                    ],
                    tooltip: null,
                },
            ],
            type: "tutor",
        },
        {
            substeps: [
                {
                    title: "Где удобно заниматься?",
                    label: "Где",
                    value: {
                        where1: {
                            title: "Онлайн",
                            selected: false,
                            subtitle: "6 410 репетиторов",
                        },
                        where2: {
                            title: "У меня",
                            selected: false,
                            subtitle: "3 890 репетиторов",
                        },
                        where3: {
                            title: "У репетитора",
                            selected: false,
                            subtitle: "1 260 репетиторов",
                        },
                    },
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 3,
                            nextSubStep: 1,
                            previousStep: 2,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: {
                        text: "Многие преподаватели работают только онлайн, если вы укажите этот вариант, ваш выбор значительно расширится",
                        icon: 2,
                    },
                },
                {
                    title: "Выберите место занятий",
                    label: "Где",
                    value: null,
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 4,
                            nextSubStep: 0,
                            previousStep: 3,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: {
                        text: "Вы можете выбрать необходимое количество районов удобных для вас",
                        icon: 3,
                    },
                },
            ],
            type: "tutor",
        },
        {
            substeps: [
                {
                    title: "Сколько занятий в неделю планируете?",
                    label: "Количество и время",
                    value: null,
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 4,
                            nextSubStep: 1,
                            previousStep: 3,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
                {
                    title: "Когда удобно заниматься?",
                    label: "Количество и время",
                    value: {
                        time1: {
                            title: "06:00–12:00",
                            selected: false,
                            list: [false, false, false, false, false, false, false],
                        },
                        time2: {
                            title: "12:00–18:00",
                            selected: false,
                            list: [false, false, false, false, false, false, false],
                        },
                        time3: {
                            title: "18:00–24:00",
                            selected: false,
                            list: [false, false, false, false, false, false, false],
                        },
                        time4: {
                            title: "00:00–06:00",
                            selected: false,
                            list: [false, false, false, false, false, false, false],
                        },
                    },
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 5,
                            nextSubStep: 0,
                            previousStep: 4,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: {
                        text: "Укажите примерное время и дни, когда вам удобно, чтобы мы могли подобрать репетиторов с похожим расписанием",
                        icon: 4,
                    },
                },
            ],
            type: "tutor",
        },
        {
            substeps: [
                {
                    title: "Какой репетитор вам подходит?",
                    label: "Опыт и стоимость",
                    value: {
                        which1: {
                            title: "Начинающий репетитор",
                            selected: false,
                            subtitle: "Занятие: ~до 800 рублей  опыт: до года",
                        },
                        which2: {
                            title: "Репетитор со средним опытом",
                            selected: false,
                            subtitle: "Занятие: ~1300–2000 рублей  опыт: ~1–3 года",
                        },
                        which3: {
                            title: "Опытный репетитор",
                            selected: false,
                            subtitle: "Занятие: ~2000–2500 рублей  опыт: ~3–7 лет",
                        },
                    },
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 6,
                            nextSubStep: 0,
                            previousStep: 4,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
            ],
            type: "tutor",
        },
        {
            substeps: [
                {
                    title: "Ваши пожелания к репетитору?",
                    label: "Пожелания",
                    value: ["", ""],
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 7,
                            nextSubStep: 0,
                            previousStep: 5,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
            ],
            type: "tutor",
        },
        {
            substeps: [
                {
                    title: "Есть ли у вас комментарии?",
                    label: "Комментарии",
                    value: { commentText: "", attachedFiles: [] },
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 8,
                            nextSubStep: 0,
                            previousStep: 6,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
            ],
            type: "tutor",
        },
        {
            substeps: [
                {
                    title: "Заказ почти готов",
                    label: "Контакты",
                    value: null,
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: null,
                            nextSubStep: null,
                            previousStep: 7,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
            ],
            type: "tutor",
        },
        {
            substeps: [
                {
                    title: "По какому предмету работа?",
                    label: "Предмет",
                    value: null,
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 10,
                            nextSubStep: 0,
                            previousStep: 0,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
            ],
            type: "work",
        },
        {
            substeps: [
                {
                    title: "Какая тема работы?",
                    label: "Тема",
                    value: null,
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 11,
                            nextSubStep: 0,
                            previousStep: 9,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
            ],
            type: "work",
        },
        {
            substeps: [
                {
                    title: "На каком курсе обучаетесь?",
                    label: "Для кого",
                    value: null,
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 11,
                            nextSubStep: 1,
                            previousStep: 10,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
                {
                    title: "Ваше место обучения?",
                    label: "Для кого",
                    value: [null, "", null],
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 12,
                            nextSubStep: 0,
                            previousStep: 11,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
            ],
            type: "work",
        },
        {
            substeps: [
                {
                    title: "Укажите срок сдачи работы",
                    label: "Сроки",
                    value: ["Указать период", [minDateStart, ""], [minDateEnd, ""]],
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 13,
                            nextSubStep: 0,
                            previousStep: 11,
                            previousSubStep: 1,
                        },
                    ],
                    tooltip: {
                        text: "Если заказ срочный выберите из списка «Срочный заказ». Тогда мы предложим специалистов, которые могут работать в сжатые сроки",
                        icon: 5,
                    },
                },
            ],
            type: "work",
        },
        {
            substeps: [
                {
                    title: "Детали работы",
                    label: "Детали",
                    value: [null, null, false],
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 14,
                            nextSubStep: 0,
                            previousStep: 12,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
            ],
            type: "work",
        },
        {
            substeps: [
                {
                    title: "Дополнительные требования",
                    label: "Дополнительные требования",
                    value: ["41-50%", "по antiplagiat.ru (бесплатный доступ)", false],
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 15,
                            nextSubStep: 0,
                            previousStep: 13,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
            ],
            type: "work",
        },
        {
            substeps: [
                {
                    title: "Сопутствующие услуги",
                    label: "Сопутствующие услуги",
                    value: {
                        add1: {
                            title: "Рецензия",
                            selected: false,
                        },
                        add2: {
                            title: "Выступление к защите",
                            selected: false,
                        },
                        add3: {
                            title: "Аннотация",
                            selected: false,
                        },
                        add4: {
                            title: "Презентация",
                            selected: false,
                        },
                        add5: {
                            title: "Раздаточный материал",
                            selected: false,
                        },
                    },
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 16,
                            nextSubStep: 0,
                            previousStep: 14,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
            ],
            type: "work",
        },
        {
            substeps: [
                {
                    title: "На какой бюджет рассчитываете?",
                    label: "Стоимость",
                    value: ["", false],
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 17,
                            nextSubStep: 0,
                            previousStep: 15,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
            ],
            type: "work",
        },
        {
            substeps: [
                {
                    title: "Есть ли у вас комментарии?",
                    label: "Комментарии",
                    value: { commentText: "", attachedFiles: [] },
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: 18,
                            nextSubStep: 0,
                            previousStep: 16,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
            ],
            type: "work",
        },
        {
            substeps: [
                {
                    title: "Заказ почти готов",
                    label: "Контакты",
                    value: null,
                    description: null,
                    routes: [
                        {
                            description: null,
                            nextStep: null,
                            nextSubStep: null,
                            previousStep: 17,
                            previousSubStep: 0,
                        },
                    ],
                    tooltip: null,
                },
            ],
            type: "work",
        },
    ];
    activeStepIndex = 0;
    subStepIndex = 0;
    subStepWay = [];
    stepData = null;
    type = "tutor";
    switchType = 0;

    stepperIndex = 0;

    currentValue = "";
    edited = false;

    setEdited() {
        this.edited = !this.edited;
    }

    setActiveStepIndex(newActiveStepIndex) {
        this.activeStepIndex = newActiveStepIndex;
    }
    setActiveSubStepIndex(newActivesubStepIndex) {
        this.subStepIndex = newActivesubStepIndex;
    }

    setStepperIndexIncrease() {
        if (this.type === "tutor") {
            this.stepperIndex = this.activeStepIndex;
        } else {
            this.stepperIndex = this.activeStepIndex - 8;
        }
    }

    setStepperIndexDecrease() {
        if (this.type === "tutor" || this.activeStepIndex === 0) {
            this.stepperIndex = this.activeStepIndex;
        } else {
            this.stepperIndex = this.activeStepIndex - 8;
        }
    }

    constructor() {
        makeAutoObservable(this);
    }

    setStepData(value) {
        this.stepData = value;
    }

    incrementStepIndex() {
        let description = this.storage[this.activeStepIndex].substeps[this.subStepIndex].description;

        let routes = this.storage[this.activeStepIndex].substeps[this.subStepIndex].routes;

        let rout = routes.find((x) => x.description === description || (this.type === "work" && x.description?.toLowerCase().includes("написание")));

        let nullRout = routes.find((x) => x.description === null);

        this.subStepWay.push([this.activeStepIndex, this.subStepIndex]);

        if (rout && 1) {
            this.activeStepIndex = rout.nextStep;
            this.subStepIndex = rout.nextSubStep;
            this.setStepperIndexIncrease();
        }

        if (nullRout && typeof rout === "undefined") {
            this.activeStepIndex = nullRout.nextStep;
            this.subStepIndex = nullRout.nextSubStep;
            this.setStepperIndexIncrease();
        }
    }

    decrementStepIndex() {
        let description = this.storage[this.activeStepIndex].substeps[this.subStepIndex].description;

        let routes = this.storage[this.activeStepIndex].substeps[this.subStepIndex].routes;

        let rout = routes.find((x) => x.description === description);

        let nullRout = routes.find((x) => x.description === null);

        if (rout && 1) {
            this.activeStepIndex = rout.previousStep;
            this.subStepIndex = rout.previousSubStep;
            this.setStepperIndexDecrease();
        } else if (nullRout) {
            this.activeStepIndex = nullRout.previousStep;
            this.subStepIndex = nullRout.previousSubStep;
            this.setStepperIndexDecrease();
        } else {
            let prevStepInfo = this.subStepWay.pop();

            this.activeStepIndex = prevStepInfo[0];
            this.subStepIndex = prevStepInfo[1];
            this.setStepperIndexDecrease();
        }
    }

    setStep(stepIndex, value) {
        this.storage[stepIndex].substeps[this.subStepIndex].description = value;
        this.currentValue = value;
    }

    setStepValue(stepIndex, value) {
        this.storage[stepIndex].substeps[this.subStepIndex].value = value;
    }

    setTooltip(stepIndex, value) {
        this.storage[stepIndex].substeps[this.subStepIndex].tooltip = value;
    }
}

export default new RequestStore();
