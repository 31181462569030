import { createTheme } from "@mui/material/styles";

export const teacherScheduleCardStyle = {
    boxShadow: "none",
    width: "300px",
    height: "279px",
    flexShrink: 0,
    borderRadius: "20px",
    background: "var(--bg-white, #FFF)",
};

export const teacherScheduleHeaderStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "150%",
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
};

export const teacherScheduleContainerStyle = {
    width: "265px",
    height: "157px",
    marginLeft: "15px",
    marginTop: "20px",
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const teacherScheduleDayStyle = {
    color: "var(--text-dark-grey, #67677A)",
    textAlign: "center",
    fontSize: "13px",
    lineHeight: "140%",
};

export const teacherScheduleHeaderContainerStyle = {
    display: "flex",
    width: "173px",
    alignItems: "flex-start",
    marginLeft: "88px",
    gap: "9px",
};

export const teacherScheduleLinkContainerStyle = {
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    marginLeft: "145px",
    marginTop: "19px",
    cursor: "pointer",
    ":hover *": {
        color: "#6212FF",
    },
};

export const teacherScheduleLinkStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    lineHeight: "150%",
    textAlign: "center",
};

export const teacherScheduleRowContainerStyle = {
    display: "flex",
    alignItems: "center",
};

export const teacherScheduleRowLabelsStyle = {
    display: "flex",
    width: "85px",
    flexDirection: "row",
    flexShrink: "0",
    color: "var(--text-black, #222)",
    fontSize: "13px",
    lineHeight: "140%",
};

export const teacherScheduleDaysContainerStyle = {
    display: "inline-flex",
    alignItems: "flex-start",
    gap: "2px",
};

export const teacherScheduleActiveDayStyle = {
    width: "23px",
    height: "24px",
    borderRadius: "4px",
    background: "var(--secondary-tiffany-green, #82D4AE)",
};

export const teacherScheduleNotActiveDayStyle = {
    width: "23px",
    height: "24px",
    borderRadius: "4px",
    background: "var(--bg-grey, #F7F7F7)",
};

export const ArrowIconTheme = createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                // Name of the slot
                root: {
                    color: "#6212FF",
                    cursor: "pointer",
                },
            },
        },
    },
});
