import React from "react";
import { observer } from "mobx-react-lite";

import EvaluateWork from "./EvaluateWork/EvaluateWork";
import YourGrade from "./YourGrade";
import DuplicateOrder from "./DuplicateOrder";

import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";

const StudentEndingState = () => {
    return (
        <>
            {!DiplomaStore.stars ? (
                <EvaluateWork />
            ) : (
                <YourGrade
                    stars={DiplomaStore.stars}
                    comment={DiplomaStore.comment}
                />
            )}

            <DuplicateOrder />
        </>
    );
};

export default observer(StudentEndingState);
