export const mainContainer = {
    border: "1px solid var(--Grey-Separator, #EEEEF0);",
    width: "100%",
    borderRadius: "20px",
    height: "80vh",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
};

export const buttonsPanel = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 20px 14px 16px",
    borderBottom: "1px solid #DADADF",
};

export const buttonsPanelVideo = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 20px 14px 30px",
};

export const attachmentsPanel = {
    display: "flex",
    justifyContent: "center",
};
