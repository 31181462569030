const AdviceIcon5 = function (props) {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_27_6)">
                <path
                    d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z"
                    fill="#6212FF"
                />
                <path
                    d="M18 7C15.8244 7 13.6977 7.64514 11.8887 8.85383C10.0798 10.0625 8.66989 11.7805 7.83733 13.7905C7.00477 15.8005 6.78693 18.0122 7.21137 20.146C7.6358 22.2798 8.68345 24.2398 10.2218 25.7782C11.7602 27.3165 13.7202 28.3642 15.854 28.7886C17.9878 29.2131 20.1995 28.9952 22.2095 28.1627C24.2195 27.3301 25.9375 25.9202 27.1462 24.1113C28.3549 22.3023 29 20.1756 29 18C28.9965 15.0837 27.8364 12.2879 25.7743 10.2257C23.7121 8.16361 20.9163 7.00355 18 7ZM18 27.2155C16.1773 27.2155 14.3956 26.6751 12.8801 25.6624C11.3646 24.6498 10.1835 23.2106 9.48595 21.5266C8.78845 19.8427 8.60595 17.9898 8.96154 16.2021C9.31712 14.4145 10.1948 12.7724 11.4836 11.4836C12.7724 10.1948 14.4145 9.31711 16.2021 8.96153C17.9898 8.60595 19.8427 8.78845 21.5266 9.48595C23.2106 10.1835 24.6498 11.3646 25.6624 12.8801C26.6751 14.3956 27.2155 16.1773 27.2155 18C27.2132 20.4434 26.2415 22.786 24.5138 24.5138C22.786 26.2415 20.4434 27.2132 18 27.2155Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.7222 11.5985C14.9885 10.7524 16.4772 10.3008 18.0001 10.3008C20.0416 10.303 21.9988 11.115 23.4423 12.5585C24.8859 14.0021 25.6979 15.9593 25.7001 18.0008C25.7001 19.5237 25.2485 21.0124 24.4024 22.2787C23.5563 23.5449 22.3537 24.5319 20.9468 25.1147C19.5398 25.6974 17.9915 25.8499 16.4979 25.5528C15.0042 25.2557 13.6322 24.5224 12.5554 23.4455C11.4785 22.3686 10.7452 20.9966 10.448 19.503C10.1509 18.0093 10.3034 16.4611 10.8862 15.0541C11.469 13.6471 12.4559 12.4446 13.7222 11.5985ZM18.2501 13C18.2501 12.5858 17.9143 12.25 17.5001 12.25C17.0859 12.25 16.7501 12.5858 16.7501 13V18.8C16.7501 19.3247 17.1754 19.75 17.7001 19.75H22.5001C22.9143 19.75 23.2501 19.4142 23.2501 19C23.2501 18.5858 22.9143 18.25 22.5001 18.25H18.2501V13Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_27_6">
                    <rect
                        width="36"
                        height="36"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default AdviceIcon5;
