import { Box, Typography } from "@mui/material";
import React from "react";
import * as themes from "./OrderResponseTutorsInfoStyled";

const OrderResponseTutorsInfo = ({ teaches, where, address, education, experience }) => {
    let specialistCount = experience;

    const pluraliz = (number, one, few, many) => {
        const lastTwoDigits = number % 100;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
            return many;
        }

        const lastDigit = number % 10;

        if (lastDigit === 1) {
            return one;
        }

        if (lastDigit >= 2 && lastDigit <= 4) {
            return few;
        }

        return many;
    };

    const displayedAddress = address.slice(0, 2);

    const remainingCount = address.length - 2;

    return (
        <Box sx={themes.OrderResponseTutorsInfoBoxWrapper}>
            <themes.StyledTutorsInfoTitle>
                Преподает:
                <themes.StyledTutorsInfoContainedText variant="span">{teaches}</themes.StyledTutorsInfoContainedText>
            </themes.StyledTutorsInfoTitle>
            <themes.StyledTutorsInfoTitle>
                Где:
                <themes.StyledTutorsInfoText variant="span">
                    {where.map((item, index) => (
                        <React.Fragment key={index}>
                            {index !== 0 && " "}
                            {item}
                            {index !== where.length - 1 && <strong>&#8226;</strong>}
                        </React.Fragment>
                    ))}{" "}
                    <Typography
                        variant="span"
                        sx={themes.OrderResponseTutorsInfoAddresText}
                    >
                        {" "}
                        {displayedAddress.join(" ")}
                    </Typography>
                    {remainingCount > 0 && ` +${remainingCount}`}
                </themes.StyledTutorsInfoText>
            </themes.StyledTutorsInfoTitle>
            <Box sx={themes.OrderResponseTutorsInfoEducationWrapper}>
                <themes.StyledTutorsInfoTitle>
                    Образование:
                    <themes.StyledTutorsInfoText variant="span">{education}</themes.StyledTutorsInfoText>
                </themes.StyledTutorsInfoTitle>
                <themes.StyledTutorsInfoTitle>
                    Опыт:
                    <themes.StyledTutorsInfoContainedText variant="span">
                        {specialistCount + " "}
                        {pluraliz(specialistCount, "год", "года", "лет")}
                    </themes.StyledTutorsInfoContainedText>
                </themes.StyledTutorsInfoTitle>
            </Box>
        </Box>
    );
};

export default OrderResponseTutorsInfo;
