import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import FullChatStore from "store/FullChatStore";
import SButton from "components/UI/SButton";
import { Box } from "@mui/material";

const BlockModal = ({ openmodalblock, setOpenModalBlock, name, sendJsonMessage }) => {
    return (
        <ModalSettingsSmall
            visible={openmodalblock}
            handleClose={() => setOpenModalBlock(false)}
        >
            <Box sx={{ fontSize: "24px", fontWeight: "600", textAlign: "center" }}>
                Вы действительно хотите {FullChatStore.isBlocked ? "разблокировать " : "заблокировать "}
                {name}?{FullChatStore.isBlocked === false && <Box sx={{ fontSize: "14px", marginTop: "2.5vh", fontWeight: "400" }}>Специалист больше не сможет вам писать</Box>}
                <Box
                    sx={{
                        textAlign: "center",
                        marginBottom: "2.5vh",
                        marginTop: "2.5vh",
                        fontWeight: "400",
                    }}
                >
                    <Box
                        sx={{
                            display: "inline-block",
                            marginRight: "1vh",
                        }}
                    >
                        <SButton
                            variant="secondary"
                            onClick={() => setOpenModalBlock(false)}
                        >
                            Отменить
                        </SButton>
                    </Box>
                    <Box sx={{ display: "inline-block" }}>
                        <SButton
                            onClick={() => {
                                FullChatStore.isBlocked === true
                                    ? FullChatStore.UnblockUser(FullChatStore.participantId, sendJsonMessage)
                                    : FullChatStore.BlockUser(FullChatStore.participantId, sendJsonMessage);
                                setOpenModalBlock(false);
                            }}
                            variant={FullChatStore.isBlocked ? "shadowed" : "red"}
                        >
                            Да, {FullChatStore.isBlocked ? "разблокировать" : "заблокировать"}
                        </SButton>
                    </Box>
                </Box>
            </Box>
        </ModalSettingsSmall>
    );
};

export default BlockModal;
