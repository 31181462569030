import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import CatalogStore from "store/CatalogStore";
import * as themes from "./AudienceFilterThemes";
import SingleChoiceFilter from "components/UI/SingleChoiceFilter";
import { useMedias } from "../../../../API/mediasHook";

const AudienceFilter = observer(({ children, ...props }) => {
    const processingAudience = (event, value) => {
        CatalogStore.SetAudience(value);
    };

    const medias = useMedias();

    return (
        <SingleChoiceFilter
            propOption={CatalogStore.optionAudience}
            propOptions={CatalogStore.optionsAudience}
            valueToShow={"audience"}
            valueToBind={"ID"}
            processingChange={processingAudience}
            themeWrapper={themes.FiltersAudienceTheme(medias)}
            themeLabel={themes.filtersAudienceLabel}
            useOptionIcon={true}
            themeOptionContainer={themes.filtersAOContainer}
            themeIconContainer={themes.FiltersRadioContainerTheme}
            label={"Для кого"}
            placeholder={medias.sm ? "Выберите для кого" : "Для кого"}
        ></SingleChoiceFilter>
    );
});

export default memo(AudienceFilter);
