import { styleProcessing } from "API/plugins/Utilities";

export const Authorization = (theme) => {
    let commonStyle = {
        width: "100vw",
        height: "100vh",
        display: "flex",
        gap: "0px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            height: "auto",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const AuthorizationLeftPanel = {
    background: "#6212FF",
    width: "30vw",
    padding: "50px 0px 100px 40px",
};

export const AuthorizationLeftPanelContent = {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
};

export const AuthorizationRightPanel = (theme) => {
    let commonStyle = {
        background: "#F3F3F3",
        width: "70vw",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const AuthorizationRightPanelContent = {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    height: "100%",
};

export const CloseButton = (theme) => {
    let commonStyle = {
        position: "absolute",
        right: "20px",
        top: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            right: "16px",
            top: "9px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const AuthorizationStepContainerContent = (theme) => {
    let commonStyle = {
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
        borderRadius: "40px",
        background: "#FFF",
        width: "610px",
        height: "720px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
            borderRadius: "0px",
            boxShadow: "none",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const AuthorizationStepContainer = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

export const LeftCircles = {
    position: "absolute",
    top: "10%",
    left: "-4%",
};

export const RightCircles = {
    position: "absolute",
    bottom: "35%",
    left: "22%",
};
