import React from "react";
import * as themes from "./OrderResponseCashbackStyled";

const OrderResponseCashback = ({ price, cashback }) => {
    if (cashback === true) {
        const calculatePercentage = (price, percentage) => {
            const result = (price * percentage) / 100;

            return result;
        };

        const number = price;

        const percentage = 14;

        const result = calculatePercentage(number, percentage);

        const formattedNumber = result.toLocaleString();

        return (
            <>
                <themes.StyledOrderResponseCashbackBox>
                    <themes.StyledOrderResponseCashbackTitle>{"+" + formattedNumber}</themes.StyledOrderResponseCashbackTitle>
                </themes.StyledOrderResponseCashbackBox>
            </>
        );
    } else return null;
};

export default OrderResponseCashback;
