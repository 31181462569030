import React from "react";
import { Typography } from "@mui/material";
import Cards from "assets/SettingsAssets/SVG/cards";
import SButton from "components/UI/SButton";
import TeacherSettingsStore from "store/TeacherSettingsStore";
import { observer } from "mobx-react-lite";
import * as themes from "./PaymentMethodThemes";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { memo } from "react";
import ModalAddCard from "./ModalAddCard/ModalAddCard";
import UserStore from "store/UserStore";
import ModalDeleteCard from "components/SettingsBodyBlock/StudentSettings/Payment/ModalDeleteCard/ModalDeleteCard";
import CardDetection from "components/SettingsBodyBlock/StudentSettings/Payment/CardDetection/CardDetection";
import { useMedias } from "API/mediasHook";

const PaymentMethod = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    //Add card

    const showAddCardDialog = () => {
        TeacherSettingsStore.setOpenDialogAddCard(true);
        TeacherSettingsStore.setTemporaryCardNumber(null);
        TeacherSettingsStore.setTemporaryCardCVC(null);
        TeacherSettingsStore.setTemporaryCardExpirationDate(null);
    };

    const discardAddCardDialog = () => {
        TeacherSettingsStore.setOpenDialogAddCard(false);
    };

    const saveAddCardDialog = () => {
        TeacherSettingsStore.setOpenDialogAddCard(false);
    };

    //Delete card
    const [dialogDeleteCardOpen, SetDialogDeleteCardOpen] = React.useState("");

    const showDeleteCardDialog = () => {
        SetDialogDeleteCardOpen(true);
    };

    const discardDeleteCardDialog = () => {
        SetDialogDeleteCardOpen(false);
    };

    return (
        <Box sx={themes.SmallItem(medias)}>
            <ModalAddCard
                open={TeacherSettingsStore.openDialogAddCard}
                handleDiscard={discardAddCardDialog}
                handleSave={saveAddCardDialog}
            />

            <ModalDeleteCard
                open={dialogDeleteCardOpen}
                handleDiscard={discardDeleteCardDialog}
            />

            <Typography sx={medias.sm ? textThemes.HeadlineH41Black : textThemes.HeadlineH5Black}>{t("teacherSettings.security.bankCard")}</Typography>
            <Box sx={themes.TextAndButtonBlock}>
                <Typography sx={textThemes.Body1Black}>{t("teacherSettings.security.addCardText")}</Typography>
                <Box sx={themes.ButtonBlock(medias)}>
                    <Box sx={themes.CardInfo}>
                        <Typography sx={textThemes.Button16Black}>{t("teacherSettings.security.card")}</Typography>
                        <Box>{UserStore.cardNumber === null ? <Cards /> : <CardDetection />}</Box>
                    </Box>
                    {UserStore.cardNumber === null ? (
                        <SButton
                            variant="shadowed"
                            onClick={showAddCardDialog}
                        >
                            {t("teacherSettings.security.addCardButton")}
                        </SButton>
                    ) : (
                        <Box sx={themes.PaymentMethodsBoxGroup}>
                            <SButton
                                variant="secondary"
                                padding="10px 20px 11px 20px"
                                onClick={showDeleteCardDialog}
                            >
                                {t("main.delete")}
                            </SButton>
                            <SButton
                                variant="unshadowed"
                                padding="10px 20px 11px 20px"
                                onClick={showAddCardDialog}
                            >
                                {t("main.settings.paymentMethodsBlock.changeCard")}
                            </SButton>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
});

export default memo(PaymentMethod);
