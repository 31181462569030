import { styleProcessing } from "API/plugins/Utilities";

export const Chip = {
    height: "39px",
    borderRadius: "30px",
};

export const ChipSelect = {
    height: "39px",
    backgroundColor: "#222",
    border: "1px solid #222",
    color: "white",
    borderRadius: "30px",
    "&:hover": {
        backgroundColor: "#222",
    },
};

export const Badge = (condition) => {
    return {
        "& .MuiBadge-badge": {
            marginRight: "5px",
            marginTop: "5px",
            backgroundColor: condition ? "#6212FF" : "#222",
            color: "white",
        },
    };
};

export const ChipStyle = (condition) => {
    return {
        backgroundColor: condition ? "#222" : "#FFF",
        boxShadow: "none",
    };
};

export const ChipContainer = (theme) => {
    let commonStyle = {
        height: "50px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        overflowX: "scroll",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "12px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
