import React from "react";

import { Stack, Typography } from "@mui/material";

import * as styles from "./styles";

const Term = () => {
    return (
        <Stack
            direction="row"
            spacing={0.5}
            alignItems="flex-start"
            sx={styles.workingStatus.timeToGo}
        >
            <Typography variant="grayText">До завершения:</Typography>
            <Typography variant="standard">18 дней 3 часа</Typography>
        </Stack>
    );
};

export default Term;
