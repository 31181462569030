import { createTheme } from "@mui/material/styles";
import { styleProcessing } from "API/plugins/Utilities";

export const Feedbacks = (theme) => {
    let commonStyle = {
        boxSizing: "border-box",
        borderRadius: "0px 0px 40px 40px",
        background: "#F7F7F7",
        display: "flex",
        justifyContent: "center",
        padding: "0 16px 70px 16px",
        width: "100%",
        margin: "0 auto",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: 0,
            paddingBottom: "40px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const FeedbacksContent = {
    maxWidth: "1216px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    paddingTop: "40px",
    minHeight: "604px",
};

export const paginationContainerStyle = {
    marginTop: "16px",
    marginBottom: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
};

export const pagesContainerStyle = {
    display: "flex",
    alignItems: "center",
    gap: "6px",
};

export const paginationTheme = createTheme({
    components: {
        MuiPagination: {
            styleOverrides: {
                root: {
                    ".MuiButtonBase-root": {
                        display: "flex",
                        padding: "2px 7px",
                        width: "23px",
                        height: "25px",
                        minWidth: "23px",
                        margin: 0,
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                        "&:hover": {
                            background: "var(--main-purple, #6212FF)!important",
                            color: "#FFFFFF",
                        },
                        "&.Mui-selected": {
                            background: "var(--main-purple, #6212FF)",
                            color: "#FFFFFF",
                        },
                    },
                },
                ul: {
                    gap: "2px",
                },
            },
        },
    },
});

export const teacherShowMoreTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    display: "flex",
                    justifyContent: "center",
                    color: "#222!important",
                    fontSize: "16px!important",
                    fontWeight: "500!important",
                    lineHeight: "150%!important",
                    textTransform: "none!important",
                    padding: "0px 0px 4px 0px!important",
                    borderRadius: "0!important",
                    borderBottom: "1px solid #9E9EB0",
                    "&:hover": {
                        background: "transparent!important",
                        color: "#6212FF!important",
                        borderBottom: "1px solid #6212FF",
                    },
                    "&.Mui-disabled": {
                        color: "gray!important",
                    },
                },
            },
        },
    },
});

export const Title = {
    display: "flex",
    alignItems: "center",
    gap: "20px",
};

export const ReviewsBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const Separator = {
    width: "100%",
    height: "1px",
    background: "#EEEEF0",
};

export const ReviewCardAndSeparator = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const RatingStarBlock = {
    display: "flex",
    alignItems: "center",
    gap: "9px",
};

export const FeedbacksRatingContainer = {
    display: "flex",
    gap: "20px",
};

export const Rating = {
    width: "259px",
    borderRadius: "20px",
    backgroundColor: "white",
    padding: "24px 0px 24px 30px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    height: "fit-content",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.05)",
};

export const RatingTopBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "row",
            justifyContent: "space-between",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const RatingsContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const ReviewsOuterBlock = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "30px",
};

export const More = {
    width: "fit-content",
};

export const ReviewContainer = (theme) => {
    let commonStyle = {
        width: "847px",
        backgroundColor: "white",
        borderRadius: "20px",
        padding: "30px",
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.05)",
        display: "flex",
        flexDirection: "column",
        gap: "60px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "20px",
            gap: "30px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const MoreAndPagination = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "0px",
};

export const NoReviews = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "12px",
    padding: "60px 0",
};

export const NoReviewsText = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
};
