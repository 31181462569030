import { ListItemButton } from "@mui/material";
import Paper from "@mui/material/Paper";
import * as React from "react";
import orderResponseStore from "store/OrderResponseStore";
import { observer } from "mobx-react-lite";
import filterStore from "store/FilterStore";
import * as themes from "./ActiveCardPopperThemes";
import * as textThemes from "themes/TextThemes";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const ActiveCardPopper = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const showDialog = () => {
        orderResponseStore.setToggleCancelOrderModal(true);
        props.setOpen(true);
    };

    const handleEditOrder = () => {
        filterStore.setToggleDrawer();
    };

    return (
        <Paper sx={themes.Paper}>
            <ListItemButton
                disableRipple
                sx={{ ...textThemes.Body1Black, ...themes.ListItemButtonEdit }}
                onClick={handleEditOrder}
            >
                {t("orders.studentOrders.editOrder")}
            </ListItemButton>
            <ListItemButton
                onClick={showDialog}
                disableRipple
                sx={{ ...textThemes.Body1Red, ...themes.ListItemButtonCancel }}
            >
                {t("orders.studentOrders.cancelOrder")}
            </ListItemButton>
        </Paper>
    );
});

export default memo(ActiveCardPopper);
