const PdfIconSVG = function (props) {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 34.48V5.52C0 2.48 2.48 0 5.52 0H25.576V8.864C25.576 11.904 27.056 13.368 30.096 13.368H40V34.48C40 37.52 37.52 40 34.48 40H5.52C2.48 40 0 37.52 0 34.48ZM30.0962 11.1677C28.8162 11.1677 27.7762 10.1277 27.7762 8.86369V0.879687L39.0082 11.1677H30.0962Z"
                fill="#9363F1"
            />
            <path
                d="M9.03516 27V19H12.1914C12.7982 19 13.3151 19.1159 13.7422 19.3477C14.1693 19.5768 14.4948 19.8958 14.7188 20.3047C14.9453 20.7109 15.0586 21.1797 15.0586 21.7109C15.0586 22.2422 14.944 22.7109 14.7148 23.1172C14.4857 23.5234 14.1536 23.8398 13.7188 24.0664C13.2865 24.293 12.763 24.4062 12.1484 24.4062H10.1367V23.0508H11.875C12.2005 23.0508 12.4688 22.9948 12.6797 22.8828C12.8932 22.7682 13.0521 22.6107 13.1562 22.4102C13.263 22.207 13.3164 21.974 13.3164 21.7109C13.3164 21.4453 13.263 21.2135 13.1562 21.0156C13.0521 20.8151 12.8932 20.6602 12.6797 20.5508C12.4661 20.4388 12.1953 20.3828 11.8672 20.3828H10.7266V27H9.03516Z"
                fill="white"
            />
            <path
                d="M19.5432 27H16.7072V19H19.5666C20.3713 19 21.064 19.1602 21.6447 19.4805C22.2255 19.7982 22.6721 20.2552 22.9846 20.8516C23.2997 21.4479 23.4572 22.1615 23.4572 22.9922C23.4572 23.8255 23.2997 24.5417 22.9846 25.1406C22.6721 25.7396 22.2229 26.1992 21.6369 26.5195C21.0536 26.8398 20.3557 27 19.5432 27ZM18.3986 25.5508H19.4729C19.9729 25.5508 20.3934 25.4622 20.7346 25.2852C21.0783 25.1055 21.3361 24.8281 21.508 24.4531C21.6825 24.0755 21.7697 23.5885 21.7697 22.9922C21.7697 22.401 21.6825 21.918 21.508 21.543C21.3361 21.168 21.0796 20.8919 20.7385 20.7148C20.3973 20.5378 19.9768 20.4492 19.4768 20.4492H18.3986V25.5508Z"
                fill="white"
            />
            <path
                d="M25.2602 27V19H30.557V20.3945H26.9516V22.3008H30.2055V23.6953H26.9516V27H25.2602Z"
                fill="white"
            />
        </svg>
    );
};

export default PdfIconSVG;
