import React from "react";
import * as themes from "./ActiveOrdersThemes";
import * as textThemes from "themes/TextThemes";
import { Box, Typography } from "@mui/material";
import Task from "assets/PAAssets/SVG/task";
import { observer } from "mobx-react-lite";
import ActiveOrderCard from "components/OrdersBodyBlock/StudentOrders/OrderCards/ActiveCard/ActiveOrderCard";
import SButton from "components/UI/SButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import StudentOrdersStore from "store/StudentOrdersStore";

const ActiveOrders = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    if (StudentOrdersStore.orders.filter((item) => item.baseOrderInfo.status === "active").length > 0) {
        return (
            <Box sx={themes.ActiveOrders}>
                <Typography sx={textThemes.HeadlineH4Black}>{t("main.studentAccount.activeOrders")}</Typography>
                <Box sx={themes.CardsContainer}>
                    {StudentOrdersStore.orders
                        .filter((item) => item.baseOrderInfo.status === "active")
                        .map((order) => (
                            <ActiveOrderCard
                                key={order.baseOrderInfo.orderUuid}
                                order={order}
                            />
                        ))}
                </Box>
                <Box sx={themes.NeedHelpContainer}>
                    <Box sx={themes.NeedHelpText}>
                        <Box>
                            <Task />
                        </Box>
                        <Typography sx={textThemes.Body1Black}>{t("main.studentAccount.needHelpWithOrders")}</Typography>
                    </Box>
                    <Box sx={themes.CreateOrderContainer}>
                        <SButton
                            onClick={() => navigate("/bid")}
                            variant="arrow"
                        >
                            {t("main.studentAccount.createOrder")}
                        </SButton>
                    </Box>
                </Box>
            </Box>
        );
    } else {
        return (
            <Box sx={themes.NoOrdersYetContainer}>
                <Box sx={themes.NoOrdersYetIconAndText}>
                    <Box>
                        <Task />
                    </Box>
                    <Box sx={themes.NoOrdersYetText}>
                        <Typography sx={textThemes.HeadlineH5Black}>{t("main.studentAccount.needHelpWithOrders")}</Typography>
                        <Typography sx={textThemes.Body1Black}>{t("main.studentAccount.toCreateOrder")}</Typography>
                    </Box>
                </Box>
                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                    <SButton
                        onClick={() => navigate("/bid")}
                        variant="arrow"
                    >
                        {t("main.studentAccount.createOrder")}
                    </SButton>
                </Box>
            </Box>
        );
    }
});

export default memo(ActiveOrders);
