export const Chip = {
    borderRadius: "30px",
    boxShadow: "none",
    minWidth: 0,
};

export const ChipSelect = {
    backgroundColor: "#222",
    color: "white",
    borderRadius: "30px",
    boxShadow: "none",
    minWidth: 0,
};

export const TabsGroup = {
    display: "flex",
    gap: "7px",
};

export const customChipStyle = {
    minWidth: "52px",
    padding: "2px 10px 4px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
    "& .MuiChip-label": {
        padding: 0, // Убираем отступы
    },
};
