import React from "react";
import { Stack, Typography } from "@mui/material";
import GiftIcon from "components/DiplomaChats/Icons/GiftIcon";
import * as styles from "pages/DiplomaChats/styles";
import Box from "@mui/material/Box";

const CashbackInfo = ({ cashback = 1000 }) => {
    return (
        <Stack
            sx={styles.cashbackInfoCont}
            direction="row"
            spacing={2}
        >
            <Box sx={styles.giftContainer}>
                <GiftIcon />
            </Box>
            <Typography variant="standard">
                По завершению заказа на ваш бонусный счёт <br />
                будут зачислены <span style={styles.cashbackCount}>{cashback} баллов</span>
            </Typography>
        </Stack>
    );
};

export default CashbackInfo;
