import { styleProcessing } from "API/plugins/Utilities";

export const NoClassesYet = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        background: "#FFF",
        borderRadius: "16px",
        justifyContent: "center",
        textAlign: "center",
        padding: "40px 0 40px 0",
        gap: "12px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "20px 20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
