const ImageIcon = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon/20/image">
                <g id="image">
                    <path
                        d="M11.1782 7.23918C11.1782 6.90781 11.4468 6.63918 11.7782 6.63918H11.7847C12.116 6.63918 12.3847 6.90781 12.3847 7.23918C12.3847 7.57055 12.116 7.83918 11.7847 7.83918H11.7782C11.4468 7.83918 11.1782 7.57055 11.1782 7.23918Z"
                        fill="#4E4E4E"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.14564 4.14369C4.62282 3.66651 5.27001 3.39844 5.94484 3.39844H13.7226C14.3974 3.39844 15.0446 3.66651 15.5218 4.14369C15.999 4.62086 16.2671 5.26805 16.2671 5.94288V13.7207C16.2671 14.3985 15.9456 15.1151 15.5121 15.5294C15.1187 15.9053 14.5278 16.2651 13.7226 16.2651H5.94484C5.27001 16.2651 4.62282 15.997 4.14564 15.5199C3.66847 15.0427 3.40039 14.3955 3.40039 13.7207V5.94288C3.40039 5.26805 3.66847 4.62086 4.14564 4.14369ZM5.94484 4.59844C5.58827 4.59844 5.2463 4.74008 4.99417 4.99222C4.74204 5.24435 4.60039 5.58631 4.60039 5.94288V11.1644L6.92266 8.84211C7.32851 8.45157 7.83129 8.2125 8.38035 8.2125C8.92941 8.2125 9.43209 8.45166 9.83794 8.8422L9.84763 8.85153L11.2974 10.3015L11.46 10.139C11.8658 9.74845 12.3686 9.50938 12.9176 9.50938C13.4667 9.50938 13.9694 9.74854 14.3752 10.1391L14.3849 10.1484L15.0671 10.8307V5.94288C15.0671 5.58631 14.9254 5.24435 14.6733 4.99222C14.4211 4.74008 14.0792 4.59844 13.7226 4.59844H5.94484ZM15.0671 12.6296C14.9294 12.606 14.7975 12.541 14.6912 12.4347L13.4001 11.1437C13.2063 10.9589 13.0391 10.9094 12.9176 10.9094C12.7961 10.9094 12.6289 10.9589 12.4351 11.1437L12.2874 11.2915L12.4401 11.4442C12.7135 11.7176 12.7135 12.1608 12.4401 12.4342C12.1668 12.7075 11.7236 12.7075 11.4502 12.4342L10.8153 11.7993C10.8109 11.7951 10.8066 11.7909 10.8023 11.7866C10.798 11.7823 10.7938 11.778 10.7896 11.7736L8.86284 9.84682C8.66906 9.66199 8.50186 9.6125 8.38035 9.6125C8.25883 9.6125 8.09164 9.66199 7.89785 9.84682L4.66236 13.0823C4.64255 13.1021 4.62185 13.1205 4.60039 13.1374V13.7207C4.60039 14.0772 4.74204 14.4192 4.99417 14.6713C5.2463 14.9235 5.58827 15.0651 5.94484 15.0651H13.7226C14.139 15.0651 14.4476 14.8868 14.683 14.6618C14.8783 14.4752 15.0671 14.0727 15.0671 13.7207V12.6296Z"
                        fill="#4E4E4E"
                    />
                </g>
            </g>
        </svg>
    );
};

export default ImageIcon;
