import React from "react";
import { Box, Typography } from "@mui/material";
import * as themes from "./RuleCompTheme";
import * as textThemes from "themes/TextThemes";
import PurplePoint from "assets/CommonAssets/SVG/PurplePoint";
import { memo } from "react";

const RuleComp = ({ children, ...props }) => {
    return (
        <Box sx={themes.ruleFont}>
            <Box>
                <PurplePoint />
            </Box>
            <Typography sx={textThemes.Body1Black}>{props.text}</Typography>
        </Box>
    );
};

export default memo(RuleComp);
