const DocIconSVG = function (props) {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 34.48V5.52C0 2.48 2.48 0 5.52 0H25.576V8.864C25.576 11.904 27.056 13.368 30.096 13.368H40V34.48C40 37.52 37.52 40 34.48 40H5.52C2.48 40 0 37.52 0 34.48ZM30.0962 11.1677C28.8162 11.1677 27.7762 10.1277 27.7762 8.86369V0.879687L39.0082 11.1677H30.0962Z"
                fill="#6369F1"
            />
            <path
                d="M10.543 27.1094H7.70703V19.1094H10.5664C11.3711 19.1094 12.0638 19.2695 12.6445 19.5898C13.2253 19.9076 13.6719 20.3646 13.9844 20.9609C14.2995 21.5573 14.457 22.2708 14.457 23.1016C14.457 23.9349 14.2995 24.651 13.9844 25.25C13.6719 25.849 13.2227 26.3086 12.6367 26.6289C12.0534 26.9492 11.3555 27.1094 10.543 27.1094ZM9.39844 25.6602H10.4727C10.9727 25.6602 11.3932 25.5716 11.7344 25.3945C12.0781 25.2148 12.3359 24.9375 12.5078 24.5625C12.6823 24.1849 12.7695 23.6979 12.7695 23.1016C12.7695 22.5104 12.6823 22.0273 12.5078 21.6523C12.3359 21.2773 12.0794 21.0013 11.7383 20.8242C11.3971 20.6471 10.9766 20.5586 10.4766 20.5586H9.39844V25.6602Z"
                fill="white"
            />
            <path
                d="M23.6115 23.1094C23.6115 23.9818 23.4462 24.724 23.1154 25.3359C22.7873 25.9479 22.3394 26.4154 21.7717 26.7383C21.2066 27.0586 20.5712 27.2188 19.8654 27.2188C19.1545 27.2188 18.5165 27.0573 17.9514 26.7344C17.3863 26.4115 16.9396 25.944 16.6115 25.332C16.2834 24.7201 16.1193 23.9792 16.1193 23.1094C16.1193 22.237 16.2834 21.4948 16.6115 20.8828C16.9396 20.2708 17.3863 19.8047 17.9514 19.4844C18.5165 19.1615 19.1545 19 19.8654 19C20.5712 19 21.2066 19.1615 21.7717 19.4844C22.3394 19.8047 22.7873 20.2708 23.1154 20.8828C23.4462 21.4948 23.6115 22.237 23.6115 23.1094ZM21.8967 23.1094C21.8967 22.5443 21.812 22.0677 21.6428 21.6797C21.4761 21.2917 21.2404 20.9974 20.9357 20.7969C20.6311 20.5964 20.2743 20.4961 19.8654 20.4961C19.4566 20.4961 19.0998 20.5964 18.7951 20.7969C18.4904 20.9974 18.2535 21.2917 18.0842 21.6797C17.9175 22.0677 17.8342 22.5443 17.8342 23.1094C17.8342 23.6745 17.9175 24.151 18.0842 24.5391C18.2535 24.9271 18.4904 25.2214 18.7951 25.4219C19.0998 25.6224 19.4566 25.7227 19.8654 25.7227C20.2743 25.7227 20.6311 25.6224 20.9357 25.4219C21.2404 25.2214 21.4761 24.9271 21.6428 24.5391C21.812 24.151 21.8967 23.6745 21.8967 23.1094Z"
                fill="white"
            />
            <path
                d="M32.473 21.9102H30.7621C30.7309 21.6888 30.6671 21.4922 30.5707 21.3203C30.4743 21.1458 30.3506 20.9974 30.1996 20.875C30.0486 20.7526 29.8741 20.6589 29.6762 20.5938C29.4809 20.5286 29.2686 20.4961 29.0395 20.4961C28.6254 20.4961 28.2647 20.599 27.9574 20.8047C27.6501 21.0078 27.4118 21.3047 27.2426 21.6953C27.0733 22.0833 26.9887 22.5547 26.9887 23.1094C26.9887 23.6797 27.0733 24.1589 27.2426 24.5469C27.4145 24.9349 27.654 25.2279 27.9613 25.4258C28.2686 25.6237 28.6241 25.7227 29.0277 25.7227C29.2543 25.7227 29.4639 25.6927 29.6566 25.6328C29.852 25.5729 30.0251 25.4857 30.1762 25.3711C30.3272 25.2539 30.4522 25.112 30.5512 24.9453C30.6527 24.7786 30.723 24.5885 30.7621 24.375L32.473 24.3828C32.4288 24.75 32.3181 25.1042 32.141 25.4453C31.9665 25.7839 31.7309 26.0872 31.434 26.3555C31.1397 26.6211 30.7881 26.832 30.3793 26.9883C29.973 27.1419 29.5134 27.2188 29.0004 27.2188C28.2868 27.2188 27.6488 27.0573 27.0863 26.7344C26.5264 26.4115 26.0837 25.944 25.7582 25.332C25.4353 24.7201 25.2738 23.9792 25.2738 23.1094C25.2738 22.237 25.4379 21.4948 25.766 20.8828C26.0941 20.2708 26.5395 19.8047 27.102 19.4844C27.6645 19.1615 28.2973 19 29.0004 19C29.4639 19 29.8936 19.0651 30.2895 19.1953C30.6879 19.3255 31.0408 19.5156 31.348 19.7656C31.6553 20.013 31.9053 20.3164 32.098 20.6758C32.2934 21.0352 32.4184 21.4466 32.473 21.9102Z"
                fill="white"
            />
        </svg>
    );
};

export default DocIconSVG;
