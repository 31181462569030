import React from "react";

const ReadMore = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_2801_2126)">
                <circle
                    cx="20"
                    cy="20"
                    r="20"
                    fill="#140C23"
                />
                <path
                    d="M20.3536 22.3536L24.1464 26.1464C24.4614 26.4614 24.2383 27 23.7929 27L16.2071 27C15.7617 27 15.5386 26.4614 15.8536 26.1464L19.6464 22.3536C19.8417 22.1583 20.1583 22.1583 20.3536 22.3536Z"
                    fill="white"
                    fillOpacity="0.9"
                />
                <path
                    d="M19.6466 16.6464L15.8537 12.8536C15.5387 12.5386 15.7618 12 16.2072 12L23.793 12C24.2385 12 24.4616 12.5386 24.1466 12.8536L20.3537 16.6464C20.1584 16.8417 19.8418 16.8417 19.6466 16.6464Z"
                    fill="white"
                    fillOpacity="0.9"
                />
            </g>
            <defs>
                <clipPath id="clip0_2801_2126">
                    <rect
                        width="40"
                        height="40"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ReadMore;
