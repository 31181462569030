import { createTheme } from "@mui/material/styles";

export const stickyActionBlockContainerStyle = (actualReview) => {
    return {
        position: "sticky",
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
        gap: actualReview ? 24 : 20,
        top: 0,
        width: "340px",
        height: "fit-content",
    };
};

export const stickyActionCardStyle = {
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    gap: 20,
    padding: 20,
    boxSizing: "border-box",
    borderRadius: "20px",
    background: "var(--main-white, #FFF)",
    height: "144px",
};

export const nameBlockStyle = {
    display: "flex",
    gap: "12px",
};

export const AvatarTheme = createTheme({
    components: {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    position: "relative",
                    width: "40px",
                    height: "40px",
                    left: "0px",
                    top: "0px",
                    borderRadius: "50%",
                    cursor: "default",
                },
            },
        },
    },
});

export const nameContainerStyle = {
    display: "flex",
    flexDirection: "column",
};

export const nameStyle = {
    color: "var(--text-black, #222)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
};

export const lastSeenStyle = {
    color: "var(--text-grey, #9E9EB0)",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
};

export const infoBlockStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
};

export const infoContainerStyle = {
    display: "flex",
    gap: "8px",
};

export const verifiedStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    alignSelf: "center",
    padding: "0px",
    gap: "5px",
    width: "16px",
    height: "16px",
    flex: "none",
    flexGrow: 0,
    color: "#19B20C",
};

export const safetyCheckStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    alignSelf: "center",
    padding: "0px",
    gap: "5px",
    width: "16px",
    height: "16px",
    flex: "none",
    flexGrow: 0,
    color: "gray",
};

export const textStyle = {
    color: "var(--text-black, #222)",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
};

export const timeStyle = {
    alignSelf: "center",
};

export const stickyActionsContainerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    flexShrink: 0,
    gap: 10,
};

export const priceTextStyle = (mode) => {
    return {
        color: "var(--text-black, #222)",
        textAlign: "center",
        fontSize: mode ? "14px" : "18px",
        fontStyle: "normal",
        fontWeight: mode ? "400" : "600",
        lineHeight: "150%",
    };
};

export const ResponseButtonTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "11px 24px 12px 24px!important",
                    gap: "10px",
                    width: "100%",
                    height: "47px",
                    background: "#6212FF",
                    borderRadius: "42px!important",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                    "&:hover": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                    },
                    "&:active": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                    },
                },
            },
        },
    },
});

export const responseTextStyle = {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    textTransform: "none",
    color: "#FFFFFF",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const actualReviewContainerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "20px",
    flexShrink: "0",
    padding: "20px",
    borderRadius: "20px",
    background: "var(--Main-White, #FFF)",
};

export const reviewInfoContainerStyle = {
    width: "100%",
};

export const reviewHeaderStyle = {
    color: "var(--Text-Black, #222)",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
};

export const dividerStyle = (marginTop, marginBottom) => {
    return {
        marginTop: marginTop,
        marginBottom: marginBottom,
    };
};

export const priceContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: 12,
};

export const priceInfoMainContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: 6,
};

export const priceInfoMainItemStyle = {
    display: "flex",
    justifyContent: "space-between",
};

export const priceInfoTotalItemStyle = {
    display: "flex",
    justifyContent: "space-between",
};

export const priceInfoMainTextStyle = (mode) => {
    return {
        color: "var(--Text-Black, #222)",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: mode ? "500" : "400",
        lineHeight: "150%",
    };
};

export const priceInfoTotalTextStyle = {
    color: "var(--Text-Black, #222)",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
};

export const reviewTextContainerStyle = {
    display: "flex",
    padding: "12px 16px 25px 16px",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    alignSelf: "stretch",
    borderRadius: "10px",
    background: "#F5F5F7",
};

export const reviewTextStyle = {
    color: "var(--Text-Black, #222)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    whiteSpace: "break-spaces",
    width: "100%",
};

export const removeReviewTextStyle = {
    position: "sticky",
    color: "var(--main-purple, #6212FF)",
    cursor: "pointer",
    fontSize: "16px",
    fontStyle: "normal",
    fontSeight: "500",
    lineHeight: "150%",
};

export const SetPriceButtonTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "7px 16px 7px 16px!important",
                    gap: "10px",
                    width: "100%",
                    height: "36px",
                    background: "#6212FF",
                    borderRadius: "42px!important",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                    "&:hover": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                    },
                    "&:active": {
                        background: "#6212FF!important",
                        borderColor: "#6212FF!important",
                    },
                },
            },
        },
    },
});

export const setPriceTextStyle = {
    color: "var(--text-white, #FFF)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
    textTransform: "none",
};

export const responseMessageContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const snackBarContentStyle = {
    display: "flex",
    width: "328px",
    padding: "16px",
    alignItems: "center",
    gap: "10px",
    borderRadius: "10px",
    background: "#6369F1",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
};

export const snackTextStyle = {
    color: "var(--text-white, #FFF)",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
    display: "flex",
    flexGrow: 1,
};

export const CheckIconTheme = createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                // Name of the slot
                root: {
                    padding: 0,
                    color: "#FFFFFF",
                    width: "20px",
                    height: "20px",
                    "&:hover": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                    "&:active": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                },
            },
        },
    },
});

export const CloseSnackBarIconTheme = createTheme({
    components: {
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    padding: 0,
                    color: "#FFFFFF",
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    "&:hover": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                    "&:active": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                },
            },
        },
    },
});
