import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { DateTime } from "luxon";
import messagesEn from "./locales/en";
import messagesRu from "./locales/ru";
import "intl-pluralrules";
import { i18nextPlugin } from "translation-check";

let messages = {
    ru: {
        translation: messagesRu,
    },
    en: {
        translation: messagesEn,
    },
};

i18n
    // i18next-http-backend
    // loads translations from your server
    // https://github.com/i18next/i18next-http-backend
    /*.use(Backend)*/
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next translation-check https://github.com/locize/translation-check (use ?showtranslations in url params to show info)
    .use(i18nextPlugin)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: "ru",
        /*lng: "ru",*/ //это форсит один язык в обход обнаружения через i18next-browser-languageDetector
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            // format: (value, format, lng) => { // legacy usage
            //   if (value instanceof Date) {
            //     return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime[format])
            //   }
            //   return value;
            // }
        },
        resources: messages,
    });

// new usage
i18n.services.formatter.add("DATE_HUGE", (value, lng, options) => {
    return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime.DATE_HUGE);
});

export default i18n;
