import { Box, Typography } from "@mui/material";
import GppGoodIcon from "@mui/icons-material/GppGood";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import AuthStatus from "assets/IncognitoStatusAssets/PNG/status-min.png";
import * as themes from "./OnlineStatusStyled";
const OnlineStatus = ({ isOnline, lastOnline, hiddenUser }) => {
    const currentDate = new Date();

    const formatLastOnline = (date) => {
        const currentDateStr = format(currentDate, "yyyyMMdd");

        const lastOnlineDateStr = format(date, "yyyyMMdd");

        if (currentDateStr === lastOnlineDateStr) {
            return "в " + format(date, "HH:mm", { locale: ru });
        } else {
            return format(date, "dd MMMM", { locale: ru });
        }
    };

    const formattedLastOnline = formatLastOnline(new Date(lastOnline));

    return (
        <Box
            variant="span"
            sx={themes.onlineStatusWrapper}
        >
            {hiddenUser ? (
                <img
                    src={AuthStatus}
                    alt="sds"
                />
            ) : (
                <GppGoodIcon sx={{ fontSize: "20px", color: "rgba(25, 178, 12, 1)" }} />
            )}
            <>{isOnline ? <Typography sx={themes.onlineStatusText}>онлайн</Typography> : <Typography sx={themes.onlinseStatusLastOnlineText}>в сети {formattedLastOnline}</Typography>}</>
        </Box>
    );
};

export default OnlineStatus;
