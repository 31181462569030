import * as React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const IOSSwitch = styled((props) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ radius, width, height, margin, theme, translate }) => ({
    width: width ? width : 48,
    height: height ? height : 24,
    padding: 0,

    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: margin ? margin : 1,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: translate ? translate : "translateX(24px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#6212FF",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: radius ? radius : 22,
        height: radius ? radius : 22,
    },
    "& .MuiSwitch-track": {
        borderRadius: 12,

        backgroundColor: theme.palette.mode === "light" ? "#D6D6D6" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

export default IOSSwitch;
