import React, { useState } from "react";
import { Box, IconButton, Snackbar, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as themes from "components/SettingsBodyBlock/TutorSettings/MainForm/MainFormThemes";
import SButton from "components/UI/SButton";
import STextField from "components/UI/STextField";
import SDropDown from "components/UI/SDropDown";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import * as textThemes from "themes/TextThemes";
import IconBackRounded from "assets/CommonAssets/SVG/IconBackRounded";
import "components/TeacherQuestionnaireBodyBlock/Steps/TeacherSteps.css";
import { useTranslation } from "react-i18next";
import OrangeExclamationIcon from "assets/CommonAssets/SVG/OrangeExclamationIcon";
import PhotoCropStep from "components/TeacherQuestionnaireBodyBlock/Components/PhotoCropStep";
import PhotoExamplesModal from "components/TeacherQuestionnaireBodyBlock/Components/PhotoExamplesModal";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { memo } from "react";
import Fade from "@mui/material/Fade";
import { CloseSnackBarIcon } from "assets/MyClassesAssets/SVG/CloseSnackBarIcon";
import TeacherSettingsStore from "store/TeacherSettingsStore";
import { useMedias } from "API/mediasHook";

const PersonalInfoItem = ({ label, value, onChange, placeholder, isDropdown, dropdownList, buttonWidth = "424px", paperWidth = "424px" }) => {
    const medias = useMedias();

    return (
        <Box sx={themes.item(medias)}>
            <Typography sx={textThemes.Body2Black}>{label}</Typography>
            {isDropdown ? (
                <SDropDown
                    disabled={TeacherQuestionnaireStore.requestSent}
                    dropDownChoice={value}
                    setDropDownChoice={onChange}
                    dropDownList={dropdownList}
                    buttonWidth={medias.sm ? buttonWidth : "100%"}
                    paperWidth={medias.sm ? paperWidth : "100%"}
                    withOutWidth={true}
                />
            ) : (
                <STextField
                    disabled={TeacherQuestionnaireStore.requestSent}
                    width={medias.sm ? "424px" : "100%"}
                    placeholder={placeholder}
                    value={value}
                    changeFunction={onChange}
                />
            )}
        </Box>
    );
};

const MainInfoSet = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const [dialogOpenPhotoExamples, SetDialogOpenPhotoExamples] = useState(false);

    const fileInput = React.useRef();

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        const reader = new FileReader();

        reader.onload = () => {
            TeacherQuestionnaireStore.setInitialInputPhoto(reader.result);
            TeacherQuestionnaireStore.setOpenCropDialog(true);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleDialogClosePhotoExamples = () => {
        SetDialogOpenPhotoExamples(false);
        TeacherQuestionnaireStore.setCroppingStep(0);
    };

    const handleDataChange = (key, value) => {
        TeacherQuestionnaireStore.setTemporaryPersonalData(key, value);
    };

    const handleBackClick = () => {
        if (TeacherQuestionnaireStore.getDisabledSendRequest() === false) {
            TeacherQuestionnaireStore.setDefaultTemporaryPersonalData();
        }

        props.handleSet(0);
    };

    const MobilePhotoContent = (
        <Box sx={themes.PhotoBlock(medias)}>
            <Box sx={themes.VerticalContainer}>
                <Box
                    component="ul"
                    sx={themes.ul}
                >
                    {[
                        t("teacherSettings.questionnaire.useRealPhoto"),
                        t("teacherSettings.questionnaire.useVerticalPhoto"),
                        t("teacherSettings.questionnaire.clearView"),
                        t("teacherSettings.questionnaire.maximumSize"),
                    ].map((text, index) => (
                        <Box
                            component="li"
                            sx={themes.li}
                            key={index}
                        >
                            <Typography sx={textThemes.Body2DarkGrey}>{text}</Typography>
                        </Box>
                    ))}
                </Box>
                <Box sx={themes.ButtonGroup}>
                    <SButton
                        variant="text"
                        style={themes.SButton}
                        onClick={() => {
                            SetDialogOpenPhotoExamples(true);
                        }}
                    >
                        {t("teacherSettings.questionnaire.viewExamples")}
                    </SButton>
                </Box>
            </Box>
            <img
                src={TeacherQuestionnaireStore.teacher.photo.imageSrc}
                style={themes.ChangePhoto}
                alt="Profile"
            />
            <SButton
                padding="7px 16px 8px 16px"
                variant="unshadowed"
                style={themes.SButton}
                onClick={() => {
                    fileInput.current.click();
                    TeacherQuestionnaireStore.setTemporaryCroppedMiniature("");
                }}
            >
                <input
                    ref={fileInput}
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                />
                {t("teacherSettings.questionnaire.updatePhoto")}
            </SButton>
        </Box>
    );

    return (
        <ScrollToTop>
            <Box sx={themes.InfoBlocks}>
                <Box sx={themes.Top(medias)}>
                    <Box sx={themes.ItemContent(medias)}>
                        <Box sx={themes.EditTitle(medias)}>
                            <SButton
                                variant="icon"
                                onClick={handleBackClick}
                            >
                                <IconBackRounded />
                            </SButton>
                            <Typography sx={medias.sm ? textThemes.HeadlineH41Black : textThemes.HeadlineH5Black}>{t("teacherSettings.questionnaire.personalInfo")}</Typography>
                        </Box>
                        <Typography sx={textThemes.HeadlineH4Black}>{t("teacherSettings.questionnaire.profilePhoto")}</Typography>
                    </Box>
                    {medias.sm ? (
                        <Box sx={themes.PhotoBlock(medias)}>
                            <img
                                src={TeacherQuestionnaireStore.teacher.photo.imageSrc}
                                style={themes.ChangePhoto}
                                alt="Profile"
                            />
                            <Box sx={themes.VerticalContainer}>
                                <Box
                                    component="ul"
                                    sx={themes.ul}
                                >
                                    {[
                                        t("teacherSettings.questionnaire.useRealPhoto"),
                                        t("teacherSettings.questionnaire.useVerticalPhoto"),
                                        t("teacherSettings.questionnaire.clearView"),
                                        t("teacherSettings.questionnaire.maximumSize"),
                                    ].map((text, index) => (
                                        <Box
                                            component="li"
                                            sx={themes.li}
                                            key={index}
                                        >
                                            <Typography sx={textThemes.Body2DarkGrey}>{text}</Typography>
                                        </Box>
                                    ))}
                                </Box>
                                <Box sx={themes.ButtonGroup}>
                                    <SButton
                                        variant="text"
                                        style={themes.SButton}
                                        onClick={() => {
                                            SetDialogOpenPhotoExamples(true);
                                        }}
                                    >
                                        {t("teacherSettings.questionnaire.viewExamples")}
                                    </SButton>
                                    <SButton
                                        padding="7px 16px 8px 16px"
                                        variant="unshadowed"
                                        style={themes.SButton}
                                        onClick={() => {
                                            fileInput.current.click();
                                            TeacherQuestionnaireStore.setTemporaryCroppedMiniature("");
                                        }}
                                    >
                                        <input
                                            ref={fileInput}
                                            style={{ display: "none" }}
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                        />
                                        {t("teacherSettings.questionnaire.updatePhoto")}
                                    </SButton>
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <>{MobilePhotoContent}</>
                    )}
                </Box>

                <Box sx={themes.TopWithPadding(medias)}>
                    <Typography sx={textThemes.HeadlineH4Black}>{t("teacherSettings.questionnaire.personalDataTitle")}</Typography>
                    <Box sx={themes.FormBlock}>
                        {[
                            { label: t("teacherSettings.questionnaire.surname"), key: "secondName", placeholder: t("teacherSettings.questionnaire.enterSurname") },
                            { label: t("teacherSettings.questionnaire.name"), key: "firstName", placeholder: t("teacherSettings.questionnaire.enterName") },
                            { label: t("teacherSettings.questionnaire.patronymic"), key: "surname", placeholder: t("teacherSettings.questionnaire.enterPatronymic") },
                            { label: t("teacherSettings.questionnaire.gender"), key: "sex", isDropdown: true, dropdownList: TeacherQuestionnaireStore.sexList },
                        ].map(({ label, key, placeholder, isDropdown, dropdownList }) => (
                            <PersonalInfoItem
                                key={key}
                                label={label}
                                value={TeacherQuestionnaireStore.temporaryPersonalData[key]}
                                onChange={(value) => handleDataChange(key, value)}
                                placeholder={placeholder}
                                isDropdown={isDropdown}
                                dropdownList={dropdownList}
                            />
                        ))}

                        <Box sx={themes.item(medias)}>
                            <Typography sx={textThemes.Body2Black}>{t("teacherSettings.questionnaire.birthdayDay")}</Typography>
                            <Box sx={themes.birthdayItem}>
                                {[
                                    {
                                        key: "birthDay",
                                        dropdownList: TeacherQuestionnaireStore.daysList,
                                        buttonWidth: medias.sm ? "120px" : "100%",
                                        paperWidth: medias.sm ? "120px" : "100%",
                                        value: TeacherQuestionnaireStore.temporaryPersonalData.birthDay,
                                    },
                                    // {
                                    //     key: "birthMonth",
                                    //     dropdownList: TeacherQuestionnaireStore.monthList,
                                    //     buttonWidth: medias.sm ? "152px" : "100%",
                                    //     paperWidth: medias.sm ? "152px" : "100%",
                                    //     value: TeacherQuestionnaireStore.temporaryPersonalData.birthMonth,
                                    // },
                                    {
                                        key: "birthYear",
                                        dropdownList: TeacherQuestionnaireStore.yearsList,
                                        buttonWidth: medias.sm ? "120px" : "100%",
                                        paperWidth: medias.sm ? "120px" : "100%",
                                        value: TeacherQuestionnaireStore.temporaryPersonalData.birthYear,
                                    },
                                ].map(({ key, dropdownList, buttonWidth, paperWidth, value }) => (
                                    <SDropDown
                                        firstValueGrey={false}
                                        disabled={TeacherQuestionnaireStore.requestSent}
                                        key={key}
                                        dropDownChoice={value}
                                        setDropDownChoice={(val) => handleDataChange(key, val)}
                                        dropDownList={dropdownList}
                                        buttonWidth={buttonWidth}
                                        paperWidth={paperWidth}
                                    />
                                ))}
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={themes.exclamationBlock}>
                        <Box>
                            <OrangeExclamationIcon />
                        </Box>
                        <Typography sx={textThemes.Body2Black}>{t("teacherSettings.questionnaire.personalData")}</Typography>
                    </Box>
                    <Box sx={themes.ButtonsBlock}>
                        <SButton
                            variant="unshadowed"
                            padding="10px 20px 11px 20px"
                            disabled={TeacherQuestionnaireStore.getDisabledSendRequest()}
                            onClick={() => TeacherQuestionnaireStore.sendRequest(true)}
                        >
                            {t("teacherSettings.questionnaire.sendRequest")}
                        </SButton>
                        <SButton
                            padding="10px 20px 11px 20px"
                            disabled={TeacherQuestionnaireStore.getDisabledSendRequest()}
                            onClick={() => TeacherQuestionnaireStore.setDefaultTemporaryPersonalData()}
                            variant="secondary"
                        >
                            {t("main.cancel")}
                        </SButton>
                    </Box>
                </Box>
            </Box>

            <PhotoCropStep />
            <PhotoExamplesModal
                handleClose={handleDialogClosePhotoExamples}
                openDialog={dialogOpenPhotoExamples}
            />
            <Snackbar
                sx={themes.WelcomeBackSnackbar}
                ContentProps={themes.ContentProps}
                TransitionComponent={Fade}
                autoHideDuration={5000}
                open={TeacherSettingsStore.openChangePhotoSnackbar}
                onClose={() => TeacherSettingsStore.setOpenChangePhotoSnackbar(false)}
                message={t("teacherSettings.questionnaire.photoUpdated")}
                action={
                    <IconButton
                        disableRipple
                        onClick={() => TeacherSettingsStore.setOpenChangePhotoSnackbar(false)}
                    >
                        <CloseSnackBarIcon />
                    </IconButton>
                }
            />
        </ScrollToTop>
    );
});

export default memo(MainInfoSet);
