export const ReadIcon = () => {
    return (
        <svg
            width="6.3vh"
            height="2.3vh"
            viewBox="0 0 51 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M36 8.05556L38.5454 11.4382C38.9455 11.9698 39.7435 11.9698 40.1435 11.4382L41.6622 9.42M45.3645 4.5L41.6622 9.42M41.6622 9.42L43.1809 11.4382C43.5809 11.9698 44.3793 11.9693 44.7793 11.4377C46.6595 8.93919 47.929 7.25217 50 4.5"
                stroke="#6212FF"
                strokeWidth="1.2"
                strokeLinecap="round"
            />
        </svg>
    );
};
