import React from "react";
import { Typography } from "@mui/material";
import * as themes from "./StartBlockThemes";
import * as textThemes from "themes/TextThemes";
import { memo } from "react";
import Woman1 from "assets/PromoTutorAssets/PNG/Rectangle 6346.png";
import Woman2 from "assets/PromoTutorAssets/PNG/Rectangle 6351.png";
import Woman3 from "assets/PromoTutorAssets/PNG/Rectangle 6353.png";
import Man1 from "assets/PromoTutorAssets/PNG/Rectangle 6350.png";
import Man2 from "assets/PromoTutorAssets/PNG/Rectangle 6352.png";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SButton from "components/UI/SButton";
import AuthorizationStore from "store/AuthorizationStore";
import { observer } from "mobx-react-lite";
import { useMedias } from "../../../API/mediasHook";
const StartBlock = observer(({ chidlren, ...props }) => {
    const navigate = useNavigate();

    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const handleClickSignUp = () => {
        AuthorizationStore.setPreviousLocationPath(location.pathname);
        AuthorizationStore.setCurrentAuthorizationStep("SignUpTutor");
        AuthorizationStore.setSignUpPhone(null);
        AuthorizationStore.setSignUpPhoneError(false);
        AuthorizationStore.setSignUpName("");
        AuthorizationStore.setSignUpEmail("");
        AuthorizationStore.setSignUpEmailError(false);
        AuthorizationStore.setSignUpEmailErrorText("");
        AuthorizationStore.setTutorOfferAgree(false);
        AuthorizationStore.setTutorRulesAgree(false);
        navigate("/authorization");
    };

    return (
        <Box sx={themes.StartBlock(medias)}>
            <Box sx={themes.TextAndButtonBlock(medias)}>
                <Box sx={themes.TextBlock(medias)}>
                    <Typography sx={textThemes.HeadlineH2Black(medias)}>{t("promoPages.promoTutor.becomeTutorOnPlatform")}</Typography>
                    <Typography sx={textThemes.Body1Black}>{t("promoPages.promoTutor.fillQuestionnaire")}</Typography>
                </Box>
                <SButton
                    onClick={handleClickSignUp}
                    style={themes.SignUpButton(medias)}
                    variant="unshadowed"
                >
                    {t("promoPages.promoTutor.signUp")}
                </SButton>
            </Box>
            <Box sx={themes.PhotosBlock}>
                <Box sx={themes.UpperPhotosBlock(medias)}>
                    <Box sx={themes.UpperLeftPhotosBlock}>
                        <Box sx={themes.ManFirstContainer(medias)}>
                            <img
                                style={themes.Man2(medias)}
                                src={Man2}
                                alt={4}
                            />
                        </Box>
                        <Box sx={themes.ManSecondContainer(medias)}>
                            <img
                                src={Man1}
                                alt={5}
                            />
                        </Box>
                    </Box>
                    <Box sx={themes.WomanFirstContainer(medias)}>
                        <img
                            style={themes.Woman1(medias)}
                            src={Woman1}
                            alt={1}
                        />
                    </Box>
                </Box>
                <Box sx={themes.BottomPhotosBlock(medias)}>
                    <Box sx={themes.WomanSecondContainer}>
                        <img
                            src={Woman2}
                            alt={2}
                        />
                    </Box>
                    <Box sx={themes.WomanThirdContainer}>
                        <img
                            src={Woman3}
                            alt={3}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
});

export default memo(StartBlock);
