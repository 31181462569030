import Button from "@mui/material/Button";
import MenuListComposition from "components/UI/PopperMenu";
import * as React from "react";
import Paper from "@mui/material/Paper";
import { List, ListItemButton } from "@mui/material";
import DropDownSVG from "assets/CommonAssets/SVG/DropDownSVG";
import timeZones from "API/dictionaries/timezonesobject";
import CheckMark from "assets/CommonAssets/SVG/CheckMark";
import * as themes from "./EditTimezoneThemes";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { useMedias } from "API/mediasHook";

const EditTimezoneContent = ({ setOpenTimeZonePopper, mode }) => {
    const handleChooseTimezone = (value, event) => {
        if (mode === "temporary") {
            TeacherQuestionnaireStore.setTemporaryTimezone(value);
        } else {
            TeacherQuestionnaireStore.setMainTimezone(value);
        }

        setOpenTimeZonePopper(false);
    };

    return (
        <Paper sx={themes.EditContentPaper}>
            <List sx={themes.EditContentList}>
                {timeZones.map((timeZone, index) => (
                    <ListItemButton
                        key={index}
                        disableRipple
                        onClick={handleChooseTimezone.bind(this, timeZone)}
                        sx={themes.EditContentListItem()}
                    >
                        {timeZone.text}
                        {mode === "temporary"
                            ? timeZone.text === TeacherQuestionnaireStore.temporaryTimezone.text && <CheckMark />
                            : timeZone.text === TeacherQuestionnaireStore.teacher.timeZone.text && <CheckMark />}
                    </ListItemButton>
                ))}
            </List>
        </Paper>
    );
};

const EditTimezone = observer(({ children, width = "424px", mode = "temporary", ...props }) => {
    const [openTimeZonePopper, setOpenTimeZonePopper] = React.useState(false);

    const medias = useMedias();

    return (
        <MenuListComposition
            placement="bottom-start"
            open={openTimeZonePopper}
            setOpen={setOpenTimeZonePopper}
            withOutWidth={medias.sm ? true : false}
            content={
                <EditTimezoneContent
                    setOpenTimeZonePopper={setOpenTimeZonePopper}
                    mode={mode}
                />
            }
            zIndex={1}
            paperSx={themes.EditPaper(width)}
        >
            <Button
                disableRipple
                sx={themes.EditButton(openTimeZonePopper, width, medias)}
            >
                {mode === "temporary"
                    ? TeacherQuestionnaireStore.temporaryTimezone.text.length > 40
                        ? TeacherQuestionnaireStore.temporaryTimezone.text.slice(0, TeacherQuestionnaireStore.temporaryTimezone.text.lastIndexOf(","))
                        : TeacherQuestionnaireStore.temporaryTimezone.text
                    : TeacherQuestionnaireStore.teacher.timeZone.text.length > 40
                      ? TeacherQuestionnaireStore.teacher.timeZone.text.slice(0, TeacherQuestionnaireStore.teacher.timeZone.text.lastIndexOf(","))
                      : TeacherQuestionnaireStore.teacher.timeZone.text}
                <DropDownSVG />
            </Button>
        </MenuListComposition>
    );
});

export default memo(EditTimezone);
