export const Avatar = {
    width: "60px",
    height: "60px",
};

export const ratingBlock = (rating) => {
    return {
        display: "flex",
        alignItems: "center",
        gap: "4px",
        backgroundColor: rating > 2 ? "#F1B063" : "#c0c0c0",
        borderRadius: "6px",
        padding: "2px 10px 3px 10px",
        color: "white",
        height: "fit-content",
        width: "fit-content",
    };
};

export const ratingText = {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "150%",
};

export const AvatarRating = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: "4px",
};

export const DescriptionButton = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: "30px",
};

export const ThanksForRate = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: "20px",
    padding: "10px 0 20px 0",
};

export const BackButton = {
    textTransform: "none",
    height: "fit-content",
    ":hover": {
        background: "transparent",
    },
};
