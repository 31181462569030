import React from "react";
import * as themes from "./NumFiveThemes";
import CircleSample from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumbersCommonComponents/CircleSample/CircleSample";
import { Box } from "@mui/material";
import { imageCircleSources } from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumbersCommonComponents/NumbersAssetsIndex";
import NumberPositionBox from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumbersCommonComponents/NumberPositionBox/NumberPositionBox";
import { getRandomImageSource } from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumbersCommonComponents/imageUtils";
import { memo } from "react";

const NumFive = ({ children, ...props }) => {
    const positions = [
        { left: 194, top: 0 },
        { left: 121, top: 166 },
        { left: 0, top: 266 },
        { left: 112, top: 0 },
        { left: 30, top: 0 },
        { left: 30, top: 76 },
        { left: 30, top: 145 },
        { left: 194, top: 201 },
        { left: 167, top: 283 },
        { left: 82, top: 302 },
    ];

    return (
        <Box sx={themes.BlockStyle}>
            {positions.map((position, index) => (
                <NumberPositionBox
                    key={index}
                    position={position}
                >
                    {index < 3 ? (
                        <CircleSample />
                    ) : (
                        <img
                            src={getRandomImageSource(imageCircleSources)}
                            alt="Random Icon"
                        />
                    )}
                </NumberPositionBox>
            ))}
        </Box>
    );
};

export default memo(NumFive);
