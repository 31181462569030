import { makeAutoObservable } from "mobx";
import Ekater from "assets/ChatAssets/PNG/Ekater.png";

class TeacherOrdersStore {
    constructor() {
        this.Init();
        makeAutoObservable(this);
    }

    activeTab = 0;

    orders = [];

    setActiveTab = (value) => {
        this.activeTab = value;
    };

    Init() {
        this.Load();
    }

    Load() {
        this.orders = [
            {
                id: 1,
                type: "inProgress",
                title: "Валовой доход торговли: источники формирования, направления использования и проблемы оптимизации",
                date: "2023-08-09T11:40:00",
                deadline: "18 апреля",
                target: "Дипломная работа",
                subject: "Макроэкономика",
                avatar: Ekater,
                name: "Екатерина М.",
                status: "waitingCheck",
                price: 14000,
                burnDeadline: true,
                lessonAssessment: 5,
                newMessages: 2,
            },
            {
                id: 2,
                type: "inProgress",
                title: "Развитие системы внутреннего контроля и аудита в сельскохозяйственных организациях",
                date: "2023-09-11T11:40:00",
                deadline: "7 мая",
                target: "Курсовая работа",
                subject: "Экономика",
                avatar: Ekater,
                name: "Екатерина М.",
                status: "inProgress",
                price: 7500,
                lessonAssessment: null,
                newMessages: 12,
            },
            {
                id: 3,
                type: "inProgress",
                title: "Валовой доход торговли: источники формирования",
                date: "2023-09-11T11:40:00",
                deadline: "10 мая",
                target: "Диссертационная работа",
                subject: "Макроэкономика",
                avatar: Ekater,
                name: "Екатерина М.",
                status: "new",
                price: 2500,
                lessonAssessment: 5,
                newMessages: null,
            },
            {
                id: 4,
                type: "completed",
                title: "Валовой доход торговли: источники формирования, направления использования и проблемы оптимизации",
                date: "2023-09-17T11:40:00",
                deadline: "18 апреля",
                target: "Дипломная работа",
                subject: "Макроэкономика",
                avatar: Ekater,
                name: "Елизавета А.",
                status: "completed",
                price: 14000,
                burnDeadline: true,
                lessonAssessment: 5,
                newMessages: null,
            },
            {
                id: 5,
                type: "completed",
                title: "Развитие системы внутреннего контроля и аудита в сельскохозяйственных организациях",
                date: "2023-09-11T11:40:00",
                deadline: "7 мая",
                target: "Курсовая работа",
                subject: "Экономика",
                avatar: Ekater,
                name: "Екатерина М.",
                status: "completed",
                price: 7500,
                lessonAssessment: null,
                newMessages: null,
            },
            {
                id: 6,
                type: "completed",
                title: "Валовой доход торговли: источники формирования",
                date: "2023-09-11T11:40:00",
                deadline: "10 мая",
                target: "Диссертационная работа",
                subject: "Макроэкономика",
                avatar: Ekater,
                name: "Екатерина М.",
                status: "completed",
                price: 2500,
                lessonAssessment: 5,
                newMessages: null,
            },
            {
                id: 7,
                type: "cancelled",
                title: "Развитие системы внутреннего контроля и аудита в сельскохозяйственных организациях",
                date: "2023-09-11T11:40:00",
                deadline: "18 апреля",
                cancelStatus: "student",
                target: "Дипломная работа",
                subject: "Макроэкономика",
                avatar: Ekater,
                name: "Елизавета А.",
                status: "cancelled",
                price: 14000,
                burnDeadline: true,
                lessonAssessment: 5,
                newMessages: null,
            },
            {
                id: 8,
                type: "cancelled",
                title: "Валовой доход торговли: источники формирования, направления использования и проблемы",
                date: "2023-09-11T11:40:00",
                deadline: "7 мая",
                cancelStatus: "teacher",
                target: "Курсовая работа",
                subject: "Экономика",
                avatar: Ekater,
                name: "Екатерина М.",
                status: "cancelled",
                price: 7500,
                lessonAssessment: null,
                newMessages: null,
            },
        ];
    }
}

export default new TeacherOrdersStore();
