export const CitySelectDialog = {
    "& .MuiDialog-container": {
        "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "400px",
            display: "flex",
            alignItems: "flex-start",
            height: "520px",
            borderRadius: "20px",
            background: "#FFF",
            padding: "15px 10px 0 30px",
            overflow: "hidden",
        },
    },
};

export const CitySelectHeader = {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    paddingRight: "5px",
};

export const CitySelectTitle = {
    p: 0,
    m: "15px 0px 0px 0px",
    fontSize: "24px",
    fontWeight: "500",
    lineHeight: "150%",
    color: "#222",
};

export const CitySelectTextField = {
    width: "340px",
    m: "22px 0 0 0",
    p: 0,
    "& .MuiInput-underline:before": { borderBottom: "1px solid #BBBBC8" },
    "& .MuiInput-underline:after": { borderBottomColor: "#6212FF" },
    "& .MuiInputBase-input": {
        fontSize: "16px",
        lineHeight: "150%",
        fontWeight: 400,
        textTransform: "none",
        p: "11px 0 11px 0",
    },
};

export const CityList = {
    orderBottom: "none",
    marginTop: "30px",
    p: 0,
    width: "100%",
    bgcolor: "background.paper",
    position: "relative",
    overflow: "auto",
    maxHeight: "300px",
    "&::-webkit-scrollbar": {
        width: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        background: "rgba(187, 187, 200, 0.50)",
        height: "60px",
    },
};

export const CityButton = {
    textTransform: "none",
    ":hover": {
        color: "#6212FF",
        borderRadius: "5px",
        background: "rgba(187, 187, 200, 0.20)",
    },
    m: "0 0 5px 0",
    p: "6px 0 5px 5px",
    color: "#222",
    fontSize: "15px",
    fontWeight: "400",
    lineHeight: "140%",
};

export const AdditionalCityText = {
    m: "0 0 0 10px",
    p: 0,
    color: "#9E9EB0",
    fontSize: "15px",
    fontWeight: "400",
    lineHeight: "140%",
};
