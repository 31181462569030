export const DrawerTheme = (medias) => {
    return {
        components: {
            MuiDrawer: {
                styleOverrides: {
                    // Name of the slot
                    paper: {
                        width: medias.sm ? "490px" : "360px",
                        background: "#FFFFFF",
                        "&::-webkit-scrollbar": {
                            width: "10px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            borderRadius: "10px",
                            background: "rgba(187, 187, 200, 0.50)",
                            height: "60px",
                        },
                    },
                },
            },
        },
    };
};

export const drawerContainerStyle = (medias) => {
    return {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        width: 430,
        marginTop: medias.sm ? "24px" : "20px",
        marginLeft: medias.sm ? "30px" : "16px",
        marginRight: "auto",
    };
};

export const headerContainerStyle = {
    display: "flex",
    alignItems: "center",
    color: "#9E9EB0",
};

export const textStyle = {
    position: "relative",
    height: "34px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "140%",
    color: "#000000",
};

export const CloseIconTheme = (medias) => {
    return {
        components: {
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        boxSizing: "border-box",
                        display: "flex",
                        justifyContent: "center",
                        border: "1px solid #9E9EB0",
                        borderRadius: "50%",
                        background: "#FFFFFF",
                        position: "relative",
                        width: "24px",
                        height: "24px",
                        marginLeft: medias.sm ? "auto" : "196px",
                        "&:hover": {
                            background: "#FFFFFF!important",
                            borderColor: "#6212FF",
                        },
                        "&:active": {
                            background: "#FFFFFF!important",
                            borderColor: "#6212FF",
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        padding: 0,
                        ".MuiSvgIcon-root": {
                            width: "16px",
                            height: "16px",
                        },
                        "&:hover": {
                            color: "#6212FF",
                            borderColor: "#6212FF",
                        },
                        "&:active": {
                            color: "#6212FF",
                            borderColor: "#6212FF",
                        },
                    },
                },
            },
        },
    };
};

export const SearchButtonTheme = (medias) => {
    return {
        components: {
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "11px 24px 12px",
                        marginBottom: "20px",
                        gap: "10px",
                        width: medias.sm ? "430px" : "328px",
                        minHeight: "47px",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "20px",
                        background: "#6212FF",
                        borderRadius: "42px!important",
                        "&:hover": {
                            background: "#6212FF!important",
                            borderColor: "#6212FF!important",
                            ".MuiTypography-root": {
                                color: "white!important",
                            },
                        },
                        "&:active": {
                            background: "#6212FF!important",
                            borderColor: "#6212FF!important",
                            ".MuiTypography-root": {
                                color: "white!important",
                            },
                        },
                    },
                },
            },
        },
    };
};

export const searchTextStyle = {
    width: "239px",
    height: "24px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    color: "#FFFFFF",
    flex: "none",
    order: 0,
    flexGrow: 0,
};
