import React, { useEffect, useState } from "react";

import { Box, CircularProgress, IconButton, ThemeProvider, Typography } from "@mui/material";

import MessagesBox from "./MessagesBox";
import SendMessageBox from "components/ChatBodyBlock/GridFieldChat/BotomFieldChat/SendMessageBox";
import VideoChatStore from "store/VideoChatStore";

import * as styles from "./ChatForVideoStyles";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import * as themes from "components/ChatBodyBlock/ChatBodyBlockThemes";
import * as textStyles from "themes/TextThemes";
import { observer } from "mobx-react-lite";
import ReplyOrEditInfo from "components/ChatBodyBlock/GridFieldChat/ReplyOrEditInfo/ReplyOrEditInfo";
import useWebSocket from "react-use-websocket";
import { set } from "components/ChatBodyBlock/CommonData/helper_functions";

import PaperClip from "assets/VideoChatAssets/SVG/paperClip";
import SearchIcon from "assets/VideoChatAssets/SVG/searchIcon";
import CloseIcon from "assets/VideoChatAssets/SVG/closeIcon";
import ChatAttachments from "../../ChatBodyBlock/ChatAttachments/ChatAttachments";

const ChatForVideo = () => {
    const URL = "wss://" + process.env.REACT_APP_API_URL + "/chats/ws";

    const { sendMessage, sendJsonMessage, lastMessage, lastJsonMessage, readyState } = useWebSocket(URL, {
        share: true,
    });

    const auth = {
        id: 1,
        token: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MjQxNjQ0MTcsInJvbGUiOiJzdHVkZW50Iiwic3ViIjoiYmEzMDZjNDUtMGIxYi00MGViLWFlM2QtNmUwZWRiY2ZhMDIyIn0.H5M9M-G0BdSYCkmCrJJOWhQoPqGKzaai27Y04iccvnSd53HRjoZKNI-Cihp-i0Wcgeu-ydvvYnVKO1Qz57v39gI00-ilAjmws5xkvPiz-Ll07EhcfTtJg1eexSd3soyGNKsw-icMpgDGd8itr1BGwwt1sMCtPF4roADKlUM6ezs",
    };

    set("token", auth.token);

    useEffect(() => {
        VideoChatStore.ProcessMessages(sendMessage, sendJsonMessage, lastMessage, lastJsonMessage);
    }, [lastMessage, lastJsonMessage]);

    const [openEditModalMessage, SetOpenEditModalMessage] = useState(false);

    const [openReplyModalMessage, SetOpenReplyModalMessage] = useState(false);

    const [disable, SetDisable] = useState(false);

    const [text, SetText] = useState("");

    return (
        <ScrollToTop>
            <Box sx={styles.chatBox}>
                {VideoChatStore.chatData.tabsData.messages ? (
                    VideoChatStore.chatData.isMedia === true ? (
                        <>
                            <ChatAttachments
                                sendJsonMessage={sendJsonMessage}
                                isVideo={true}
                            />
                        </>
                    ) : (
                        <>
                            <Box sx={styles.headerChat}>
                                <Box sx={styles.ChatFont}> Чат </Box>
                                <IconButton onClick={() => VideoChatStore.SetIsMedia(true)}>
                                    <PaperClip />
                                </IconButton>
                                <IconButton onClick={() => VideoChatStore.SetChatState(!VideoChatStore.managementPanel.chatState)}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <MessagesBox
                                SetText={SetText}
                                SetDisable={SetDisable}
                                sendJsonMessage={sendJsonMessage}
                                openReplyModalMessage={openReplyModalMessage}
                                SetOpenReplyModalMessage={SetOpenReplyModalMessage}
                                openEditModalMessage={openEditModalMessage}
                                SetOpenEditModalMessage={SetOpenEditModalMessage}
                                disable={disable}
                            />
                            {(openEditModalMessage || openReplyModalMessage) && (
                                <ReplyOrEditInfo
                                    isVideo={true}
                                    handleOpenEditModalMessage={SetOpenEditModalMessage}
                                    handleOpenReplyModalMessage={SetOpenReplyModalMessage}
                                    handleText={SetText}
                                    handleDisable={SetDisable}
                                />
                            )}
                            <Box sx={styles.sendMessage}>
                                <SendMessageBox
                                    sendJsonMessage={sendJsonMessage}
                                    isVideo={true}
                                    disable={disable}
                                    setDisable={SetDisable}
                                    text={text}
                                    setText={SetText}
                                    openReplyModalMessage={openReplyModalMessage}
                                    setOpenReplyModalMessage={SetOpenReplyModalMessage}
                                    openEditModalMessage={openEditModalMessage}
                                    setOpenEditModalMessage={SetOpenEditModalMessage}
                                />
                            </Box>
                        </>
                    )
                ) : (
                    <Box sx={themes.loadingContainerStyle}>
                        <Typography sx={textStyles.HeadlineH5Black}>Чат загружается...</Typography>
                        <ThemeProvider theme={themes.CircularProgressTheme}>
                            <CircularProgress />
                        </ThemeProvider>
                    </Box>
                )}
            </Box>
        </ScrollToTop>
    );
};

export default observer(ChatForVideo);
