const IconDown = ({ disabled }) => {
    return (
        <svg
            width="2.3vh"
            height="2.3vh"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon">
                <path
                    id="Vector (Stroke)"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.41138 4.4605C1.71106 4.13585 2.21719 4.1156 2.54185 4.41529L8.49922 9.9144L14.4566 4.41529C14.7813 4.1156 15.2874 4.13585 15.5871 4.4605C15.8867 4.78516 15.8665 5.29129 15.5418 5.59097L9.04185 11.591C8.7354 11.8738 8.26305 11.8738 7.9566 11.591L1.4566 5.59097C1.13194 5.29129 1.1117 4.78516 1.41138 4.4605Z"
                    fill={disabled === true ? "#9E9EB0" : "#6212FF"}
                />
            </g>
        </svg>
    );
};

export default IconDown;
