import React from "react";
import * as themes from "./IncomeImagePartThemes";
import DialogEarnMoney from "assets/PromoTutorAssets/PNG/DialogEarnMoney.png";
import DialogJoinSxB from "assets/PromoTutorAssets/PNG/DialogJoinSxB.png";
import Col1 from "assets/PromoTutorAssets/PNG/Rectangle 6438.png";
import Col2 from "assets/PromoTutorAssets/PNG/Rectangle 6437.png";
import Box from "@mui/material/Box";
import { memo } from "react";
import { useMedias } from "../../../../API/mediasHook";
import { Image1Size } from "./IncomeImagePartThemes";

const IncomeImagePart = ({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={themes.IncomeImageBlock}>
            <Box sx={themes.Image1}>
                <img
                    style={themes.Image1Size(medias)}
                    src={Col2}
                    alt={1}
                />
            </Box>
            <Box sx={themes.Dialog1}>
                <img
                    src={DialogJoinSxB}
                    alt={2}
                />
            </Box>
            <Box sx={themes.Image2(medias)}>
                <img
                    style={themes.Image2Size(medias)}
                    src={Col1}
                    alt={3}
                />
            </Box>

            <Box sx={themes.Dialog2(medias)}>
                <img
                    src={DialogEarnMoney}
                    alt={4}
                />
            </Box>
        </Box>
    );
};

export default memo(IncomeImagePart);
