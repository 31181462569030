import { createTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

export const ratePositivesContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    alignItems: "center",
    width: "100%",
};

export const ratingContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "center",
    width: "100%",
};

export const RatingTheme = createTheme({
    components: {
        MuiRating: {
            styleOverrides: {
                root: {
                    gap: "1px",
                    "& *": {
                        transform: "none!important",
                    },
                    svg: {
                        width: "36px!important",
                        height: "36px!important",
                    },
                },
            },
        },
    },
});

export const dividerStyle = (marginTop, marginBottom) => {
    return {
        width: "100%",
        marginTop: marginTop,
        marginBottom: marginBottom,
    };
};

export const headerTextStyle = {
    color: "var(--text-black, #222)",
    textAlign: "center",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
};

export const feedbackMessageContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    alignItems: "center",
    width: "100%",
};

export const FeedbackMessageTheme = (mode) =>
    createTheme({
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": !mode ? "#9E9EB0" : "var(--Secondary-Red, #E12626)",
                        "--TextField-brandBorderHoverColor": !mode ? "#6212FF" : "var(--Secondary-Red, #E12626)",
                        "--TextField-brandBorderFocusedColor": !mode ? "#6212FF" : "var(--Secondary-Red, #E12626)",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                        "& .Mui-focused": {
                            background: "#FFFFFF",
                        },
                        width: "100%",
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&:before, &:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&:before": {
                            borderBottom: "1px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "1px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "1px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },

            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        display: "flex",
                        margin: 0,
                    },
                },
            },

            MuiInputBase: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        boxSizing: "border-box",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "4px",
                        width: "100%",
                        flexGrow: 0,
                        "&.MuiInputBase-root": {
                            border: "1px!important solid #9E9EB0",
                            borderRadius: "12px",
                            padding: "12px 16px 12px 16px",
                        },
                        ".MuiInputBase-input": {
                            "::placeholder": {
                                fontWeight: "400!important",
                            },
                            width: "-webkit-fill-available!important",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "150%",
                            color: "#222222",
                            flex: "none",
                            order: 0,
                            flexGrow: 0,
                        },
                    },
                },
            },
        },
    });

export const helperContainerStyle = (mode) => {
    return {
        display: "flex",
        justifyContent: mode ? "space-between" : "flex-end",
        width: "100%",
    };
};

export const helperCounterContainerStyle = {
    display: "flex",
};

export const currLengthTextStyle = (length) => {
    return {
        color: length ? "var(--text-black, #222)" : "var(--text-grey, #9E9EB0)",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "150%",
    };
};

export const currLengthTextMaxStyle = {
    color: "var(--Text-Red, #E12626)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const maxLengthTextStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const tagsContainerStyle = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
};

export const ActiveChipTheme = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                // Name of the slot
                root: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "fit-content",
                    height: "35px",
                    borderRadius: "20px",
                    background: "var(--text-black, #222)",
                    border: "1px solid var(--grey-light-grey, #BBBBC8)",
                    "&:hover": {
                        background: "#222!important",
                    },
                    "&:active": {
                        background: "#222!important",
                        borderColor: "#6212FF",
                    },
                },
                label: {
                    width: "fit-content",
                    height: "21px",
                    fontStyle: "normal",
                    padding: "6px 9px 8px 10px!important",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "150%",
                    fontFeatureSettings: "'tnum' on, 'lnum' on",
                    color: "#FFFFFF",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                },
            },
        },
    },
});

export const InactiveChipTheme = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                // Name of the slot
                root: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "fit-content",
                    height: "35px",
                    background: "#FFFFFF",
                    border: "1px solid var(--grey-light-grey, #BBBBC8)",
                    "&:hover": {
                        background: "#FFFFFF!important",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "#FFFFFF!important",
                        borderColor: "#6212FF",
                    },
                },
                label: {
                    width: "fit-content",
                    height: "21px",
                    fontStyle: "normal",
                    padding: "6px 9px 8px 10px!important",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "150%",
                    fontFeatureSettings: "'tnum' on, 'lnum' on",
                    color: "#222222",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                },
            },
        },
    },
});
