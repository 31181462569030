export const RequestPage = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "0",
    border: "none",
    minHeight: "740px",
    marginBottom: "40px",
    background: "#fff",
};

export const QuestionPage = {
    marginTop: "60px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "1216px",
    padding: "0",
    border: "none",
};

export const LeftPanel = {
    width: "289px",
};

export const StepperNQuestion = {
    display: "flex",
    flexDirection: "row",
    gap: "123px",
};
