import { createTheme } from "@mui/material/styles";

export const LessonsDayTimeAccordionTheme = (medias) => {
    return {
        components: {
            MuiAccordion: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        width: medias.sm ? "430px!important" : "328px!important",
                        position: "relative",
                        boxShadow: "none!important",
                        "&:before": {
                            background: "none!important",
                        },
                        "&.Mui-expanded": {
                            marginTop: "0px!important",
                            marginBottom: "0px!important",
                        },
                        ".MuiAccordionSummary-root": {
                            minHeight: "0px!important",
                            padding: 0,
                            paddingBottom: "12px",
                            ".MuiAccordionSummary-content": {
                                marginTop: "0px!important",
                                marginBottom: "0px!important",
                                ".Mui-expanded": {
                                    marginTop: "0px!important",
                                    marginBottom: "0px!important",
                                },
                            },
                        },
                        ".MuiAccordionDetails-root": {
                            padding: 0,
                        },
                    },
                },
            },
        },
    };
};

export const headerLessonsDayTimeStyle = {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#222222",
    flex: "none",
    order: 0,
    flexGrow: 1,
};

export const FiltersLessonsDayTimeTheme = createTheme({
    components: {
        MuiFormControl: {
            styleOverrides: {
                // Name of the slot
                root: {
                    ".MuiSvgIcon-root": {
                        color: "#6212FF",
                    },
                    ".MuiButtonBase-root": {
                        paddingTop: 0,
                        paddingBottom: 0,
                        "&:hover": {
                            background: "none!important",
                        },
                    },
                    ".MuiTypography-root": {
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "150%",
                        display: "flex",
                        alignItems: "center",
                        color: "#222222",
                        flex: "none",
                        order: 0,
                        flexGrow: 1,
                        "&:hover": {
                            color: "#6212FF",
                        },
                    },
                    ".MuiFormGroup-root": {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "0px",
                        gap: "12px",
                    },
                },
            },
        },
    },
});

export const optionsAdditionContainer = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "8px",
    marginLeft: "32px",
    marginTop: "10px",
};

export const OptionAdditionTheme = createTheme({
    components: {
        MuiToggleButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    width: "33px",
                    height: "36px",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                    background: "rgba(187, 187, 200, 0.1)",
                    borderRadius: "10px",
                    "&.Mui-selected": {
                        background: "#FFFFFF!important",
                        borderColor: "#6212FF!important",
                    },
                    "&:hover": {
                        borderColor: "#6212FF",
                    },
                    /*"& .MuiTouchRipple-root span":{
                        color: '#6212FF!important',
                    },*/
                },
            },
        },
    },
});

export const optionsAdditionText = {
    width: "19px",
    height: "20px",
    fontStyle: "normal",
    fontWeight: "400!important",
    fontSize: "14px!important",
    lineHeight: "140%!important",
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    textAlign: "center",
    color: "#4E4E4E",
    flex: "none",
    order: 1,
    flexGrow: 0,
};
