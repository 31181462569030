import React, { useEffect, useState } from "react";
import * as theme from "./SheduleHeaderStyled";
import { Box, Typography } from "@mui/material";
import * as tThemes from "themes/TextThemes";
import GoToTodayButton from "./GoToTodayButton/GoToTodayButton";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowButtonShedule from "./ArrowButtonShedule/ArrowButtonShedule";
import TimeZone from "./TimeZone/TimeZone";
import DateRange from "./DateRange/DateRange";
import ChangeSheduleModal from "components/ScheduleBodyBlock/ChangeSheduleModal/ChangeSheduleModal";
import SButton from "components/UI/SButton";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import { observer } from "mobx-react-lite";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";

const SheduleHeader = observer(({ addWeekToDate, backWeekToDate, weekDates, backToCurrentDate }) => {
    const { t, i18n } = useTranslation();

    const currentDate = new Date();

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" };

        const date = new Date(dateString);

        return date.toLocaleString("ru-RU", options);
    };

    const formattedDate = formatDate(currentDate);

    //Change schedule
    const [dialogChangeScheduleOpen, SetDialogChangeScheduleOpen] = useState(false);

    const showChangeScheduleDialog = () => {
        SetDialogChangeScheduleOpen(true);
    };

    const discardChangeScheduleDialog = () => {
        SetDialogChangeScheduleOpen(false);
        TeacherQuestionnaireStore.setTemporaryTimezone(TeacherQuestionnaireStore.teacher.timeZone);
        TeacherQuestionnaireStore.temporaryTimeToClasses = TeacherQuestionnaireStore.teacher.timeToClasses;
    };

    const saveChangeScheduleDialog = () => {
        SetDialogChangeScheduleOpen(false);
        TeacherQuestionnaireStore.setMainTimezone(TeacherQuestionnaireStore.temporaryTimezone);
        TeacherQuestionnaireStore.teacher.timeToClasses = TeacherQuestionnaireStore.temporaryTimeToClasses;
    };

    return (
        <Box sx={theme.SheduleHeaderContainer}>
            <Box sx={theme.OrganizerHeaderWrapper}>
                <Box sx={theme.headerLeftSideWrapper}>
                    <Box sx={theme.headerTitleAndBackButtonWrapper}>
                        <Typography sx={tThemes.HeadlineH3Black}>{t("main.schedule.schedule")}</Typography>
                        <GoToTodayButton handleClick={() => backToCurrentDate()} />
                    </Box>
                    <Box sx={theme.weekaChangePanelWrapper}>
                        <Box sx={theme.arrowButtonWrapper}>
                            <ArrowButtonShedule
                                handleClick={() => backWeekToDate()}
                                disable={weekDates.includes(formattedDate) ? true : false}
                            >
                                <ArrowBackIosNewRoundedIcon />
                            </ArrowButtonShedule>
                            <ArrowButtonShedule handleClick={() => addWeekToDate()}>
                                <ArrowForwardIosRoundedIcon />
                            </ArrowButtonShedule>
                        </Box>
                        <DateRange weekDates={weekDates} />
                    </Box>
                </Box>
                <Box sx={theme.rightSideHeaderWrapperTimeAndChangeButton}>
                    <TimeZone />
                    <Box sx={theme.changeButtonSizeWrapper}>
                        <SButton
                            variant="unshadowed"
                            onClick={showChangeScheduleDialog}
                        >
                            {t("main.schedule.changeSchedule")}
                        </SButton>
                    </Box>
                </Box>
            </Box>
            <ChangeSheduleModal
                open={dialogChangeScheduleOpen}
                handleDiscard={discardChangeScheduleDialog}
                handleSave={saveChangeScheduleDialog}
            />
        </Box>
    );
});

export default memo(SheduleHeader);
