import React from "react";

const RatingStarIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M7.54077 3.06637C7.71431 2.66339 8.28569 2.66339 8.45923 3.06637L9.50486 5.49443C9.57724 5.66252 9.73568 5.77763 9.91791 5.79453L12.5503 6.03867C12.9871 6.07919 13.1637 6.6226 12.8341 6.91218L10.848 8.65694C10.7105 8.77773 10.65 8.96398 10.6902 9.14251L11.2714 11.7215C11.3679 12.1495 10.9057 12.4853 10.5284 12.2613L8.25528 10.9116C8.09792 10.8181 7.90208 10.8181 7.74472 10.9116L5.47161 12.2613C5.09434 12.4853 4.63209 12.1495 4.72856 11.7215L5.30981 9.14251C5.35005 8.96398 5.28953 8.77773 5.15204 8.65694L3.16593 6.91218C2.8363 6.6226 3.01286 6.07919 3.44975 6.03867L6.08209 5.79453C6.26432 5.77763 6.42276 5.66252 6.49514 5.49443L7.54077 3.06637Z"
                fill="white"
            />
        </svg>
    );
};

export default RatingStarIcon;
