import React from "react";
import TutorClassesStore from "store/TutorClassesStore";
import { observer } from "mobx-react-lite";
import * as themes from "./TutorAccountThemes";
import { Box } from "@mui/material";
import NextClass from "./NextClass/NextClass";
import CurrentLevel from "./PrivilegeProgram/CurrentLevel/CurrentLevel";
import Rating from "./Rating/Rating";
import RecommendedOrders from "./RecommendedOrders/RecommendedOrders";
import VideoPlayer from "./VideoPlayer/VideoPlayer";
import SnackbarAddCard from "./SnackbarAddCard/SnackbarAddCard";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import Stories from "./Stories/Stories";
import NextMonthLevel from "./PrivilegeProgram/NextMonth/NextMonthLevel";
import ConductedClasses from "./ConductedClasses/ConductedClasses";
import OrdersInProgress from "./OrdersInProgress/OrdersInProgress";
import TeacherOrdersStore from "store/TeacherOrdersStore";
import AccountInfo from "./AccountInfo/AccountInfo";
import OrdersSearchStore from "store/OrdersSearchStore";
import Schedule from "./Schedule/Schedule";
import QuestionnaireCheckSuccess from "./QuestionnaireCheckSuccess/QuestionnaireCheckSuccess";
import InviteStudents from "./InviteStudents/InviteStudents";
import ProfileInactive from "./ProfileInactive/ProfileInactive";
import { memo } from "react";
import TeacherSettingsStore from "store/TeacherSettingsStore";
import { useMedias } from "API/mediasHook";
import AccountSectionTitle from "../SectionTitle/AccountSectionTitle";
import { useTranslation } from "react-i18next";

import MiniWidget from "./MiniWidget/MiniWidget";

const TutorAccount = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    //VideoCard

    const [dialogVideoCardOpen, SetDialogVideoCardOpen] = React.useState(false);

    const showVideoCardDialog = () => {
        SetDialogVideoCardOpen(true);
        TeacherSettingsStore.setSnackBarClose();
        console.log(dialogVideoCardOpen);
    };

    const discardVideoCardDialog = () => {
        SetDialogVideoCardOpen(false);
    };

    const MobileVersion = (
        <Box sx={themes.TeacherAccountContent(medias)}>
            <SnackbarAddCard />

            <Box sx={{ width: "100%", maxWidth: "599px", boxSizing: "border-box" }}>
                <VideoPlayer
                    open={dialogVideoCardOpen}
                    handleDiscard={discardVideoCardDialog}
                />
                {TeacherQuestionnaireStore.teacher.verifiedProfile ? (
                    <Box sx={themes.LeftPanel(medias)}>
                        <AccountInfo play={showVideoCardDialog} />
                        <MiniWidget />
                        <AccountSectionTitle
                            pt="30px"
                            pb="16px"
                            text={t("teacherClasses.nextClass")}
                        />
                        {TutorClassesStore.classes.filter((classItem) => classItem.classStatus === "upcoming").length > 0 && <NextClass />}
                        <AccountSectionTitle
                            pt="30px"
                            pb="16px"
                            text={t("teacherAccount.stories.forYou")}
                        />
                        <Stories />
                        <AccountSectionTitle
                            pt="30px"
                            pb="48px"
                            text={t("teacherAccount.schedule.schedule")}
                        />
                        {TutorClassesStore.classes.length > 0 ? <Schedule /> : <QuestionnaireCheckSuccess />}
                    </Box>
                ) : (
                    <ProfileInactive />
                )}
                {TeacherQuestionnaireStore.teacher.verifiedProfile && (
                    <Box sx={themes.OrdersBlock}>
                        {TeacherOrdersStore.orders.filter((item) => item.type === "inProgress").length > 0 && <OrdersInProgress />}
                        {OrdersSearchStore.recommendedOrders.length > 0 && <RecommendedOrders />}
                        <InviteStudents />
                    </Box>
                )}
            </Box>
        </Box>
    );

    return (
        <>
            {medias.sm ? (
                <Box sx={themes.TeacherAccount(medias)}>
                    <Box sx={themes.TeacherAccountContent(medias)}>
                        <SnackbarAddCard />
                        <Box>
                            <VideoPlayer
                                open={dialogVideoCardOpen}
                                handleDiscard={discardVideoCardDialog}
                            />
                            {TeacherQuestionnaireStore.teacher.verifiedProfile ? (
                                <Box sx={themes.LeftPanel(medias)}>
                                    <AccountInfo play={showVideoCardDialog} />
                                    {TutorClassesStore.classes.filter((classItem) => classItem.classStatus === "upcoming").length > 0 && <NextClass />}
                                    {TutorClassesStore.classes.length > 0 ? <Schedule /> : <QuestionnaireCheckSuccess />}
                                    <InviteStudents />
                                </Box>
                            ) : (
                                <ProfileInactive />
                            )}
                        </Box>
                        <Box sx={themes.RightPanel}>
                            <Stories />
                            <Box sx={themes.MiniWidgetBox}>
                                <CurrentLevel />
                                <NextMonthLevel />
                            </Box>
                            <Box sx={themes.MiniWidgetBox}>
                                <Rating />
                                <ConductedClasses />
                            </Box>
                            {TeacherQuestionnaireStore.teacher.verifiedProfile && (
                                <Box sx={themes.OrdersBlock}>
                                    {TeacherOrdersStore.orders.filter((item) => item.type === "inProgress").length > 0 && <OrdersInProgress />}
                                    {OrdersSearchStore.recommendedOrders.length > 0 && <RecommendedOrders />}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box sx={themes.TeacherAccount(medias)}>{MobileVersion}</Box>
            )}
        </>
    );
});

export default memo(TutorAccount);
