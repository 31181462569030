export const progress = {
    mainContainer: {
        position: "relative",
        display: "inline-flex",
    },
    undoneProgress: {
        color: "#EEEEF0",
        position: "absolute",
        left: "0",
    },
    doneProgress: {
        color: "#19B20C",
    },
    textContainer: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
};

export const workingStatus = {
    checkCircle: {
        width: "20px",
        height: "20px",
    },
    timeToGo: {
        borderTop: "1px solid rgba(187, 187, 200, 0.30)",
        paddingTop: "14px",
    },
};
