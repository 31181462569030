import { OrderCardsWrapper } from "components/OrdersBodyBlock/OrderCardsWrapp/OrderCardsWrappThemes";
import { Box } from "@mui/material";
import StudentOrders from "components/OrdersBodyBlock/StudentOrders/StudentOrders";
import UserStore from "store/UserStore";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import TutorOrders from "components/OrdersBodyBlock/TutorOrders/TutorOrders";

const OrderCardsWrapp = observer(({ children, ...props }) => {
    return (
        <Box>
            <OrderCardsWrapper>
                {UserStore.role === "student" && <StudentOrders />}
                {UserStore.role === "tutor" && <TutorOrders />}
            </OrderCardsWrapper>
        </Box>
    );
});

export default memo(OrderCardsWrapp);
