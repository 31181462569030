//Сколько занятий в неделю планируете?
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import SRadioGroup from "components/UI/RadioGroup";
import SBButtonGroup from "components/UI/ButtonGroup";
import store from "store/RequestStore";
import { classesWeekList } from "API/dictionaries/const_data";

import StepHiddenButtonWrapper from "components/OrdersBodyBlock/RenewOrder/StepHiddenButtonWrapper";
import RequestNullAlert from "./RequestNullAlert";
import { Box } from "@mui/material";

const Step8 = () => {
    const [alert, setAlert] = useState(0);

    const [stepData, setStepData] = useState(store.storage[store.activeStepIndex].substeps[store.subStepIndex].description);

    const handleChoice = () => {
        if (stepData) {
            store.setStep(4, stepData);
        }
    };

    const handleIncrement = () => {
        if (stepData) {
            store.incrementStepIndex();
        } else {
            setAlert(1);
        }
    };

    const handleNext = () => {
        handleChoice();
        handleIncrement();
    };

    return (
        <Box>
            <SRadioGroup
                changefunc={setStepData}
                value={stepData}
                list={classesWeekList}
            />
            <StepHiddenButtonWrapper submitRenew={handleChoice}>
                <SBButtonGroup
                    handleNext={handleNext}
                    handleBack={() => store.decrementStepIndex()}
                    activeStep={store.activeStepIndex}
                    steps={store.storage}
                />

                {alert === 1 ? <RequestNullAlert>Чтобы перейти к следующему вопросу, выберите один из вариантов</RequestNullAlert> : null}
            </StepHiddenButtonWrapper>
        </Box>
    );
};

export default observer(Step8);
