export const TextField = {
    width: "495px",
    "& .MuiInput-underline:before": {
        borderBottom: "1px solid #BBBBC8",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#6212FF",
    },
    "& .MuiInputBase-input": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "16px",
        lineHeight: "150%",
        fontWeight: 400,
        textTransform: "none",
    },
};
