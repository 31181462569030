import { Typography, Box, styled } from "@mui/material";

export const StyledOrderResponseCashbackBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "83px",
    height: "32px",
    borderRadius: "16px",
    background: "linear-gradient(to right, rgba(25, 178, 12, 1), rgba(18, 170, 255, 1))",
});

export const StyledOrderResponseCashbackTitle = styled(Typography)({
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "21px",
    color: "rgba(255, 255, 255, 1)",
});
