import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import filterStore from "store/FilterStore";
import Box from "@mui/material/Box";

export default function CustomSelect({ value, setValue, list }) {
    const handleChange = (event) => {
        setValue(event.target.value);
        filterStore.setSubmitButtonFilter(false);
    };

    return (
        <Box>
            <FormControl variant="standard">
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={value}
                    onChange={handleChange}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                borderRadius: "10px",
                                boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
                                "& .MuiMenuItem-root.Mui-selected": {
                                    backgroundColor: "#fff",
                                },
                                "& .MuiMenuItem-root:hover": {
                                    backgroundColor: "#6212FF",
                                    color: "#fff",
                                },
                                "& .MuiMenuItem-root.Mui-selected:hover": {
                                    backgroundColor: "#6212FF",
                                    color: "#fff",
                                },
                            },
                        },
                    }}
                    sx={{
                        "& .MuiInputBase-input:focus": {
                            bgcolor: "#fff",
                        },
                        "&:before": {
                            borderBottom: "1px solid #BBBBC8",
                        },
                        "&:after": {
                            borderBottom: "1px solid #BBBBC8",
                        },
                        "&:not(.Mui-disabled):hover::before": {
                            borderColor: "#BBBBC8",
                        },
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontSize: "16px",
                        lineHeight: "150%",
                        fontWeight: 400,
                        textTransform: "none",
                        color: "#222",
                        width: "495px",
                        bgcolor: "#fff",
                    }}
                >
                    {list.map((item, index) => {
                        return (
                            <MenuItem
                                key={index}
                                value={item}
                                sx={{
                                    fontFamily: "Inter",
                                    fontStyle: "normal",
                                    fontSize: "16px",
                                    lineHeight: "150%",
                                    fontWeight: 400,
                                    textTransform: "none",
                                    color: "#222",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                {item}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
}
