import { styleProcessing } from "API/plugins/Utilities";
import { background } from "pages/DiplomaChats/styles";

export const PaymentMethods = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        maxWidth: "701px",
        width: "100%",
        borderRadius: "20px",
        background: "#FFF",
        boxShadow: "0px 4px 30px 0px rgba(106, 99, 118, 0.05)",
        paddingBottom: "30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const PaymentMethodsTitle = (theme) => {
    let commonStyle = {
        borderRadius: "20px 20px 0px 0px",
        background: "#222",
        padding: "10px 0px 10px 30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "10px 0px 10px 20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const PaymentMethodsContent = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        padding: "0px 30px 0px 30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "0px 20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const PaymentMethodsCard = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            marginBottom: "30px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const PaymentMethodsCardBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            alignItems: "flex-start",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const PaymentMethodsBoxGroup = {
    display: "flex",
    gap: "16px",
};

export const buttonSize = (theme) => {
    let commonStyle = {
        width: "150px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const DeleteButtonSize = (theme) => {
    let commonStyle = {
        width: "98px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "120px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
