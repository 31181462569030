import { styleProcessing } from "API/plugins/Utilities";

export const CurrentClasses = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "20px",
        width: "100%",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "0px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
