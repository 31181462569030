import React from "react";
import * as themes from "./Error400BodyBlockThemes";
import * as textThemes from "themes/TextThemes";
import NumFour from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumFour/NumFour";
import NumZero from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorNumbers/NumZero/NumZero";
import { Box, Typography } from "@mui/material";
import SupportLinkText from "components/ErrorsBodyBlock/ErrorsCommonComponents/SupportLinkText/SupportLinkText";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import ErrorAlternativeButtons from "components/ErrorsBodyBlock/ErrorsCommonComponents/ErrorAlternativeButtons/ErrorAlternativeButtons";

const Error400BodyBlock = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.Error400BodyBlock}>
            <Box sx={themes.ErrorBlock}>
                <Box sx={themes.NumBLock}>
                    <NumFour />
                    <NumZero />
                    <NumZero />
                </Box>
                <Box sx={themes.ErrorContent}>
                    <Box sx={themes.TopBlock}>
                        <Box sx={themes.TitleBlock}>
                            <Typography sx={textThemes.HeadlineH2Black}>{t("errors.errorOnPage")}</Typography>
                            <Typography sx={textThemes.Body1Black}>{t("errors.updatePageLater")}</Typography>
                        </Box>
                        <Box sx={themes.MiddleBlock}>
                            <Typography sx={textThemes.HeadlineH5Black}>{t("errors.usefulInfo")}</Typography>
                            <ErrorAlternativeButtons />
                        </Box>
                    </Box>
                    <SupportLinkText />
                </Box>
            </Box>
        </Box>
    );
};

export default memo(Error400BodyBlock);
