import React from "react";

import { Stack, Typography } from "@mui/material";

import DocIconSVG from "assets/CommonAssets/SVG/docIconSVG";

const NoFiles = () => {
    return (
        <Stack
            direction="column"
            paddingTop="90px"
            alignItems="center"
            spacing={2.5}
        >
            <DocIconSVG
                fill="#6212FF"
                opacity={0.2}
            />
            <Typography variant="title1">Здесь будут храниться все файлы</Typography>
        </Stack>
    );
};

export default NoFiles;
