import React from "react";

const Cards = () => {
    return (
        <svg
            width="154"
            height="30"
            viewBox="0 0 154 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_542_22334)">
                <path
                    d="M28.1289 8.73242H20.8281V21.467H28.1289V8.73242Z"
                    fill="#FF5A00"
                />
                <path
                    d="M21.314 15.1C21.314 12.5127 22.5668 10.2164 24.4892 8.73272C23.0744 7.65272 21.2897 7 19.343 7C14.7314 7 11 10.6227 11 15.1C11 19.5773 14.7314 23.2 19.343 23.2C21.2897 23.2 23.0744 22.5473 24.4892 21.4673C22.5641 20.0046 21.314 17.6873 21.314 15.1Z"
                    fill="#EB001B"
                />
                <path
                    d="M37.9775 15.1C37.9775 19.5773 34.2461 23.2 29.6345 23.2C27.6878 23.2 25.9031 22.5473 24.4883 21.4673C26.435 19.981 27.6635 17.6873 27.6635 15.1C27.6635 12.5127 26.4107 10.2164 24.4883 8.73272C25.9004 7.65272 27.6851 7 29.6318 7C34.2461 7 37.9775 10.6463 37.9775 15.1Z"
                    fill="#F79E1B"
                />
            </g>
            <rect
                x="0.5"
                y="0.5"
                width="47"
                height="29"
                rx="4.5"
                stroke="#DADADF"
            />
            <g clipPath="url(#clip1_542_22334)">
                <path
                    d="M81.8375 10.0156C79.5677 10.0156 77.5393 11.1261 77.5393 13.1777C77.5393 15.5305 81.1368 15.693 81.1368 16.875C81.1368 17.3727 80.5325 17.8182 79.5004 17.8182C78.0357 17.8182 76.9409 17.1957 76.9409 17.1957L76.4725 19.266C76.4725 19.266 77.7336 19.7919 79.408 19.7919C81.8896 19.7919 83.8424 18.6269 83.8424 16.5402C83.8424 14.054 80.2299 13.8963 80.2299 12.7992C80.2299 12.4094 80.726 11.9822 81.7551 11.9822C82.9163 11.9822 83.8637 12.4349 83.8637 12.4349L84.3221 10.4353C84.3221 10.4353 83.2913 10.0156 81.8375 10.0156ZM61.0706 10.1665L61.0156 10.4684C61.0156 10.4684 61.9706 10.6333 62.8306 10.9624C63.938 11.3397 64.0169 11.5593 64.2034 12.2416L66.2358 19.6362H68.9602L73.1573 10.1665H70.4391L67.7422 16.605L66.6418 11.1475C66.5408 10.5228 66.0296 10.1665 65.4039 10.1665H61.0706ZM74.2503 10.1665L72.118 19.6362H74.71L76.8347 10.1665H74.2503ZM88.7066 10.1665C88.0816 10.1665 87.7504 10.4824 87.5074 11.0343L83.71 19.6362H86.4281L86.954 18.2026H90.2655L90.5853 19.6362H92.9836L90.8913 10.1665H88.7066ZM89.0601 12.725L89.8658 16.2785H87.7073L89.0601 12.725Z"
                    fill="#1434CB"
                />
            </g>
            <rect
                x="53.5"
                y="0.5"
                width="47"
                height="29"
                rx="4.5"
                stroke="#DADADF"
            />
            <path
                d="M143.711 10H137.551C137.91 11.9886 139.883 13.8602 142.036 13.8602H146.941C147.001 13.6847 147.001 13.3923 147.001 13.2168C147.001 11.4622 145.505 10 143.711 10Z"
                fill="url(#paint0_linear_542_22334)"
            />
            <path
                d="M138.088 14.209V19.0634H141.078V16.49H143.71C145.145 16.49 146.401 15.4957 146.82 14.209H138.088Z"
                fill="#4FAD50"
            />
            <path
                d="M127.561 10V19.0071H130.192C130.192 19.0071 130.85 19.0071 131.209 18.3637C133.003 14.9129 133.541 13.8602 133.541 13.8602H133.9V19.0071H136.891V10H134.259C134.259 10 133.601 10.0585 133.242 10.6434C131.747 13.6262 130.91 15.1469 130.91 15.1469H130.551V10H127.561Z"
                fill="#4FAD50"
            />
            <path
                d="M115 19.0617V10.0547H117.99C117.99 10.0547 118.828 10.0547 119.306 11.3414C120.502 14.7337 120.622 15.2016 120.622 15.2016C120.622 15.2016 120.861 14.3828 121.938 11.3414C122.416 10.0547 123.254 10.0547 123.254 10.0547H126.244V19.0617H123.254V14.2073H122.895L121.22 19.0617H119.904L118.23 14.2073H117.871V19.0617H115Z"
                fill="#4FAD50"
            />
            <rect
                x="106.5"
                y="0.5"
                width="47"
                height="29"
                rx="4.5"
                stroke="#DADADF"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_542_22334"
                    x1="137.54"
                    y1="11.9578"
                    x2="146.979"
                    y2="11.9578"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#27B1E6" />
                    <stop
                        offset="1"
                        stopColor="#148ACA"
                    />
                </linearGradient>
                <clipPath id="clip0_542_22334">
                    <rect
                        width="27"
                        height="16.2"
                        fill="white"
                        transform="translate(11 7)"
                    />
                </clipPath>
                <clipPath id="clip1_542_22334">
                    <rect
                        width="32"
                        height="9.80645"
                        fill="white"
                        transform="translate(61 10)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Cards;
