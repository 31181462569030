import { Box, Typography } from "@mui/material";
import IncomingMessageCard from "components/ChatBodyBlock/GridFieldChat/IncomingMessageCard/IncomingMessageCard";
import React, { useEffect, useState } from "react";
import OutcomingMessageCard from "../../../ChatBodyBlock/GridFieldChat/OutcomingMessageCard/OutcomingMessageCard";
import FullChatStore from "store/FullChatStore";
import { reverseMassiv, findChatById, getDateGroups } from "components/ChatBodyBlock/CommonData/helper_functions";
import { observer } from "mobx-react-lite";

import * as Styles from "./MessageFieldStyles";
import * as textStyles from "themes/TextThemes";

const MessageField = (props) => {
    const boxRef = React.useRef(null);

    const [messagesList, SetMessagesList] = useState([]);

    const [dateGroups, SetDateGroups] = useState([]);

    useEffect(() => {
        if (FullChatStore.id) {
            console.log("reload effect");
            let messagesListReversed = reverseMassiv(FullChatStore.tabsData, FullChatStore.id);

            let dateGroupsFromList = getDateGroups(messagesListReversed);

            SetMessagesList(messagesListReversed);
            SetDateGroups(dateGroupsFromList);
        }
    }, [FullChatStore.tabsData.chats, FullChatStore.id, FullChatStore.change, FullChatStore.newLoadedMessages]);

    useEffect(() => {
        if (messagesList.length) {
            if (FullChatStore.toScrollMessageWait) {
                const scrollElement = boxRef.current;

                const elto = document.getElementById(FullChatStore.toScrollMessageWait);

                const elementOffset = elto.offsetTop;

                scrollElement.scrollTop = elementOffset;

                FullChatStore.SetToScrollMessageWait(null);
            } else {
                let lastMessageOwner = messagesList.at(-1).owner.uuid;

                let isScroolledUp = FullChatStore.newLoadedMessages?.length;

                if (FullChatStore.noScroll) {
                    FullChatStore.SetNoScroll(false);
                    if (FullChatStore.lastMessage) {
                        const scrollElement = boxRef.current;

                        const elto = document.getElementById(FullChatStore.lastMessage);

                        const elementOffset = elto.offsetTop;

                        scrollElement.scrollTop = elementOffset;
                    }
                } else if (lastMessageOwner === FullChatStore.tabsData.user_id && !isScroolledUp) {
                    const scrollElement = boxRef.current;

                    scrollElement.scrollTop = scrollElement.scrollHeight;
                } else {
                    FullChatStore.SetNewLoadedMessages(undefined);
                }
            }
        }
    }, [messagesList.length]);

    React.useEffect(() => {
        if (FullChatStore.toScrollMessage) {
            const scrollElement = boxRef.current;

            const elto = document.getElementById(FullChatStore.toScrollMessage);

            const elementOffset = elto.offsetTop;

            scrollElement.scrollTop = elementOffset;

            FullChatStore.SetToScrollMessage(null);
        }
    }, [FullChatStore.toScrollMessage]);

    const Load = () => {
        const scrollElement = boxRef.current;

        if (scrollElement.scrollTop === 0) {
            FullChatStore.SetNoScroll(true);
            FullChatStore.GetForScrollMessagesNoLimit(props.sendJsonMessage);
        }
    };

    return (
        <Box
            sx={Styles.MessageBox}
            ref={boxRef}
            onScroll={Load}
        >
            {dateGroups.map((group, groupIndex) =>
                messagesList.map((message, messageIndex) => (
                    <Box key={groupIndex + messageIndex}>
                        {messageIndex === 0 && (
                            <Box sx={Styles.MessageGroup}>
                                <Typography sx={textStyles.Caption1Grey}>{group}</Typography>
                            </Box>
                        )}
                        {message.formattedDate === group &&
                            (FullChatStore.tabsData.user_info.uuid === message.owner.uuid ? (
                                <Box
                                    id={message.message_id}
                                    sx={{ backgroundColor: message.message_id === FullChatStore.toScrollMessage && "#F7F7F7" }}
                                >
                                    <OutcomingMessageCard
                                        message={message}
                                        chat_id={FullChatStore.id}
                                        participant_id={FullChatStore.participantId}
                                        disable={props.disable}
                                        handleDisable={props.setDisable}
                                        handleOpenEditModalMessage={props.setOpenEditModalMessage}
                                        handleTextEditModalMessage={props.handleTextEditModalMessage}
                                        handleOpenReplyModalMessage={props.setOpenReplyModalMessage}
                                        handleTextReplyModalMessage={props.handleTextReplyModalMessage}
                                        handleText={props.setText}
                                        reply_to_message={
                                            "reply_to_message" in message && message.reply_to_message
                                                ? findChatById(FullChatStore.tabsData.chats, FullChatStore.id).messages.find((element) => element.message_id === message.reply_to_message)?.message
                                                : null
                                        }
                                    />
                                </Box>
                            ) : (
                                <Box
                                    id={message.message_id}
                                    sx={{ backgroundColor: message.message_id === FullChatStore.toScrollMessage && "#F7F7F7" }}
                                >
                                    <IncomingMessageCard
                                        message={message}
                                        disable={props.disable}
                                        handleDisable={props.setDisable}
                                        handleOpenReplyModalMessage={props.setOpenReplyModalMessage}
                                        handleTextReplyModalMessage={props.setTextReplyModalMessage}
                                        handleText={props.setText}
                                    />
                                </Box>
                            ))}
                    </Box>
                )),
            )}
        </Box>
    );
};

export default observer(MessageField);
