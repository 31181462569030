export const tcInfoAreaStyle = (styleMode, medias) => {
    if (styleMode === 0) {
        return {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            gap: medias.sm ? "20px" : "0px",
            width: medias.sm ? "667px" : "296px",
            flex: "none",
            order: 1,
            flexGrow: 0,
        };
    }

    if (styleMode === 1) {
        return {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            width: medias.sm ? "667px" : "320px",
            flex: "none",
            order: 1,
            flexGrow: 0,
        };
    }
};
