//Ваши пожелания к репетитору?
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import SRadioGroup from "components/UI/RadioGroup";
import SBButtonGroup from "components/UI/ButtonGroup";
import store from "store/RequestStore";
import { listAge } from "API/dictionaries/const_data";
import { listGender } from "API/dictionaries/const_data";
import RequestStore from "store/RequestStore";

import StepHiddenButtonWrapper from "components/OrdersBodyBlock/RenewOrder/StepHiddenButtonWrapper";
import { Box } from "@mui/material";
import * as theme from "./Step11Themes";
const Step11 = () => {
    let data = store.storage[store.activeStepIndex].substeps[store.subStepIndex]?.value;

    if (data === undefined) store.setActiveSubStepIndex(0);

    const [stepData, setStepData] = useState(RequestStore.storage[store.activeStepIndex].substeps[store.subStepIndex].value[0]);

    const [stepData1, setStepData1] = useState(RequestStore.storage[store.activeStepIndex].substeps[store.subStepIndex].value[1]);

    let sumWishes = "";

    const handleSumWishes = () => {
        if (stepData === "Не имеет значения" && stepData1 === "Не имеет значения") {
            sumWishes = "Не имеет значения";
        } else if (stepData !== "Не имеет значения" && stepData1 === "Не имеет значения") {
            sumWishes = stepData;
        } else if (stepData === "Не имеет значения" && stepData1 !== "Не имеет значения") {
            sumWishes = stepData1;
        } else {
            sumWishes = stepData + ", " + stepData1;
        }

        if (sumWishes === ", ") {
            sumWishes = "Не имеет значения";
        }

        store.setStep(6, sumWishes);
        store.setStepValue(6, [stepData, stepData1]);
    };

    const handleIncrement = () => {
        store.incrementStepIndex();
    };

    const handleNext = () => {
        handleSumWishes();
        handleIncrement();
    };

    return (
        <Box>
            <Box sx={theme.Gender}>Можете выбрать пол репетитора</Box>
            <SRadioGroup
                changefunc={setStepData}
                value={stepData}
                list={listGender}
            />

            <Box sx={theme.Age}>Можете выбрать возраст репетитора</Box>
            <SRadioGroup
                changefunc={setStepData1}
                value={stepData1}
                list={listAge}
            />

            <StepHiddenButtonWrapper submitRenew={handleSumWishes}>
                <SBButtonGroup
                    handleNext={handleNext}
                    handleBack={() => store.decrementStepIndex()}
                    activeStep={store.activeStepIndex}
                    steps={store.storage}
                />
            </StepHiddenButtonWrapper>
        </Box>
    );
};

export default observer(Step11);
