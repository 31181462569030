import { Snackbar } from "@mui/material";
import React from "react";
import * as themes from "./OrderSnackThemes";
import Fade from "@mui/material/Fade";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const OrderSnack = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Snackbar
            sx={themes.WelcomeBackSnackbar}
            ContentProps={themes.ContentProps}
            TransitionComponent={Fade}
            autoHideDuration={5000}
            open={props.openSnack}
            onClose={() => props.setOpenSnack(false)}
            message={`${t("orders.studentOrders.order")} ${
                props.order.baseOrderInfo.orderType === "tutoring"
                    ? props.order.baseOrderInfo.subject
                        ? props.order.baseOrderInfo.subject
                        : props.order.baseOrderInfo.requestTopic
                    : props.order.baseOrderInfo.requestTopic
            } ${props.order.baseOrderInfo.isOpened ? t("orders.studentOrders.openForNewResponses") : t("orders.studentOrders.closedForNewResponses")}`}
        />
    );
};

export default memo(OrderSnack);
