import React from "react";

const FeedbackFinishedIcon = () => {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="illustartion"
                clipPath="url(#clip0_1715_55991)"
            >
                <g id="Group">
                    <path
                        id="Vector"
                        d="M43.4673 36.8776C43.4673 36.8776 43.4715 40.675 43.4715 42.4675C43.4715 46 26.9998 46 27 42.4675C27.0001 40.675 27 36.8776 27 36.8776L35.2359 35.8359L43.4673 36.8776Z"
                        fill="#6212FF"
                    />
                    <path
                        id="Vector_2"
                        d="M23.1392 36.8776C23.1392 36.8776 23.1434 40.675 23.1434 42.4675C23.1434 46 6.67171 46 6.67188 42.4675C6.67196 40.675 6.67188 36.8776 6.67188 36.8776L14.9078 35.8359L23.1392 36.8776Z"
                        fill="#6212FF"
                    />
                    <path
                        id="Vector_3"
                        d="M23.1392 33.3306L23.1434 36.8789H6.67188V33.3306L14.9078 32.2891L23.1392 33.3306Z"
                        fill="#6212FF"
                        fillOpacity="0.15"
                    />
                    <path
                        id="Vector_4"
                        d="M2.62949 3.79041C2.92852 1.60066 4.81113 -0.0241426 7.021 0.0002715L7.14727 0.00154103C9.6084 0.0293731 11.5638 2.0782 11.478 4.53806L11.496 12.0168L12.2864 13.4038L14.5527 15.199L21.7143 22.5178C22.6286 23.4521 23.1406 24.7074 23.1406 26.0147V33.3272H6.67314L1.93644 25.7799C0.685683 23.787 0.177709 21.4168 0.501869 19.0864L2.62949 3.79041Z"
                        fill="#FFD2A7"
                    />
                    <path
                        id="Vector_5"
                        d="M11.7529 15.2233C10.9423 16.0023 10.9163 17.2959 11.695 18.1065L17.269 23.9095L15.1564 25.9389L9.58236 20.1359C7.68412 18.1604 7.74789 15.0086 9.72348 13.1104C10.4582 12.4047 11.4954 12.0156 11.4954 12.0156L14.5968 15.2443C13.814 14.4719 12.5505 14.4574 11.7529 15.2233Z"
                        fill="#FAB073"
                    />
                    <path
                        id="Vector_6"
                        d="M26.8597 33.3306L26.8555 36.8789H43.327V33.3306L35.091 32.2891L26.8597 33.3306Z"
                        fill="#6212FF"
                        fillOpacity="0.2"
                    />
                    <path
                        id="Vector_7"
                        d="M47.3702 3.79041C47.0712 1.60066 45.189 -0.0241426 42.9787 0.0002715L42.8525 0.00154103C40.3916 0.0293731 38.4358 2.0782 38.5217 4.53797L38.5037 12.0167L37.7133 13.4037L35.4474 15.1989L28.2857 22.5177C27.3714 23.452 26.8594 24.7073 26.8594 26.0146V33.3271H43.3267L48.0633 25.7799C49.314 23.787 49.822 21.4168 49.4978 19.0864L47.3702 3.79041Z"
                        fill="#FFC186"
                    />
                    <path
                        id="Vector_8"
                        d="M38.2466 15.2233C39.0571 16.0023 39.0831 17.2959 38.3045 18.1065L32.7305 23.9095L34.8431 25.9389L40.4171 20.1359C42.3153 18.1604 42.2521 15.0086 40.276 13.1104C39.5412 12.4047 38.504 12.0156 38.504 12.0156L35.403 15.2443C36.1854 14.4719 37.4489 14.4574 38.2466 15.2233Z"
                        fill="#F09B59"
                    />
                    <path
                        id="Union"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.1129 0C21.6673 0 23.1286 0.605006 24.2275 1.70393L25.0021 2.47891L25.7773 1.70441C26.8762 0.605486 28.3374 0 29.8914 0C31.4457 0 32.907 0.605486 34.0059 1.70441C36.2746 3.97303 36.2746 7.66449 34.0059 9.93311L26.4164 17.5226C25.6353 18.3036 24.3689 18.3036 23.5879 17.5225L15.9987 9.93263C13.7296 7.66401 13.7296 3.97255 15.9987 1.70393C17.0977 0.605006 18.5589 0 20.1129 0Z"
                        fill="#6212FF"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1715_55991">
                    <rect
                        width="50"
                        height="50"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FeedbackFinishedIcon;
