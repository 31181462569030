import React from "react";
import { Dialog, Box, ThemeProvider, Stack } from "@mui/material";
import AddFilesForm from "components/UI/AddFilesForm/AddFilesForm";
import ModalTitle from "components/DiplomaChats/LeftPanel/ChatField/WaitingState/CorrectionInfo/ModalTitle";
import Actions from "components/DiplomaChats/LeftPanel/ChatField/WaitingState/CorrectionInfo/Actions";
import SendOrderToWaitingState from "./SendOrderToWaitingState";
import * as themes from "components/OrderBodyBlock/StickyActionBlock/ModalResponseThemes";
import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";
import { observer } from "mobx-react-lite";

const SendWorkModal = ({ open, close }) => {
    const sendWork = () => {
        if (DiplomaStore.status === "working") {
            DiplomaStore.setStatus("waiting");
        }

        close();
    };

    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={open}
                onClose={close}
            >
                <Box width="575px">
                    <ModalTitle
                        close={close}
                        title={<span>Загрузка файла с учебной работой</span>}
                    />
                    <Box
                        padding="20px 40px 30px 40px"
                        sx={{
                            background: "#FFF",
                            borderRadius: "0px 0px 20px 20px",
                        }}
                    >
                        <SendOrderToWaitingState />
                        <AddFilesForm
                            addFilesText="Перетащи или добавь файл"
                            padding="32px 16px"
                        />
                        <Stack mt={5}>
                            <Actions
                                close={close}
                                send={sendWork}
                            />
                        </Stack>
                    </Box>
                </Box>
            </Dialog>
        </ThemeProvider>
    );
};

export default observer(SendWorkModal);
