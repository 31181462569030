import * as themes from "./PopularPartThemes";
import { Chip, Typography } from "@mui/material";
import React, { memo } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CatalogStore from "store/CatalogStore";
import { useMedias } from "API/mediasHook";
import Box from "@mui/material/Box";

const PopularPart = ({ children, ...props }) => {
    const handleClick = (query, e) => {
        CatalogStore.ProcessTagSearch(query.Query);
    };

    const medias = useMedias();

    return (
        <Box sx={themes.filtersPopular(medias)}>
            <Typography sx={themes.textPopular}>Популярные запросы:</Typography>
            {CatalogStore.popularQueries.map((query) => (
                <ThemeProvider
                    theme={themes.TagTheme}
                    key={query.ID}
                >
                    <Chip
                        key={query.ID}
                        label={query.Query}
                        variant="outlined"
                        size="small"
                        disableRipple
                        sx={themes.textPopular}
                        onClick={handleClick.bind(this, query)}
                    ></Chip>
                </ThemeProvider>
            ))}
        </Box>
    );
};

export default memo(PopularPart);
