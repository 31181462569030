import React from "react";
import { observer } from "mobx-react-lite";

import { Box } from "@mui/material";

import WithoutFiles from "./WithoutFiles";
import WithFiles from "./WithFiles/WithFiles";

import { useGetChatAttachments } from "hooks/useGetChatAttachments";

import { imageAttachmentsContainer } from "../ImageChatAttachments/ImageChatAttachmentsThemes";

const Files = ({ handleDownload }) => {
    const sortedFiles = useGetChatAttachments("files");

    return (
        <Box style={imageAttachmentsContainer}>
            {!Object.keys(sortedFiles).length ? (
                <WithoutFiles />
            ) : (
                <WithFiles
                    files={sortedFiles}
                    downloadFile={handleDownload}
                />
            )}
        </Box>
    );
};

export default observer(Files);
