import { Box, styled } from "@mui/material";

export const StyledOrderResponseWrapper = styled(Box)({
    maxWidth: "1216px",
    display: "grid",
    gridTemplateColumns: "minmax(700px, 1216px)",

    justifyContent: "center",
    margin: "0 auto",
    fontFamily: '"Inter", sans-serif',
});
