import { Typography } from "@mui/material";
import * as tThemes from "themes/TextThemes";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const DateRange = ({ weekDates }) => {
    const { t, i18n } = useTranslation();

    const weekdatesMapping = {
        сентября: "main.months.atSeptember",
        октября: "main.months.atOctober",
        ноября: "main.months.atNovember",
        декабря: "main.months.atDecember",
        января: "main.months.atJanuary",
        февраля: "main.months.atFebruary",
        марта: "main.months.atMarch",
        апреля: "main.months.atApril",
        мая: "main.months.atMay",
        июня: "main.months.atJune",
        июля: "main.months.atJuly",
        августа: "main.months.atAugust",
    };

    const formatWeekDateRange = (weekDates) => {
        const startMonth = weekDates[0].split(" ")[1];

        const startDay = weekDates[0].split(" ")[0];

        const endMonth = weekDates[weekDates.length - 1].split(" ")[1];

        const endDay = weekDates[weekDates.length - 1].split(" ")[0];

        return `${startDay} ${t(weekdatesMapping[startMonth])} — ${endDay} ${t(weekdatesMapping[endMonth])}, ${weekDates[0].split(" ")[2]}`;
    };

    const formattedDateRange = formatWeekDateRange(weekDates);

    return <Typography sx={tThemes.HeadlineH5Black}>{formattedDateRange}</Typography>;
};

export default memo(DateRange);
