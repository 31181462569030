import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import { Button, Dialog, IconButton } from "@mui/material";
import * as themes from "./ModalFeedbackFinishedThemes";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CloseRounded } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import FeedbackFinishedIcon from "assets/VideoChatAssets/SVG/feedbackFinishedIcon";
import { useNavigate } from "react-router-dom";

const ModalFeedbackFinished = observer(({ children, ...props }) => {
    const navigate = useNavigate();

    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        props.handleSave();
    };

    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={props.open}
                onClose={Close}
            >
                <Box sx={themes.dialogContainerStyle}>
                    <ThemeProvider theme={themes.CloseIconTheme}>
                        <IconButton
                            onClick={Close}
                            disableRipple
                        >
                            <CloseRounded></CloseRounded>
                        </IconButton>
                    </ThemeProvider>
                    <Box sx={themes.cardContainerStyle}>
                        <Box sx={themes.headerBlockContainerStyle}>
                            <FeedbackFinishedIcon></FeedbackFinishedIcon>
                            <Typography sx={themes.headerTextStyle}>Спасибо за оценку!</Typography>
                        </Box>
                        <Box sx={themes.contentBlockContainerStyle}>
                            <Typography sx={themes.contentTextStyle}>
                                Мы ценим обратную связь от вас и стараемся стать лучше. Вы можете записаться на новое занятие к вашему репетитору или вернуться в «Мои занятия»
                            </Typography>
                        </Box>
                        <Box sx={themes.actionsBlockContainerStyle}>
                            <Box sx={themes.actionsContainerStyle}>
                                <ThemeProvider theme={themes.ModalActionButtonTheme}>
                                    <Button
                                        onClick={Save}
                                        disableRipple
                                    >
                                        <Typography style={themes.buttonTextStyle}>Записаться на занятие</Typography>
                                    </Button>
                                </ThemeProvider>
                                <Typography
                                    sx={themes.backToLessonsTextStyle}
                                    onClick={() => navigate("/myclasses")}
                                >
                                    Вернуться в Мои занятия
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </ThemeProvider>
    );
});

export default memo(ModalFeedbackFinished);
