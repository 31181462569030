import React, { memo } from "react";
import Box from "@mui/material/Box";
import LogoSBWhite from "assets/CommonAssets/SVG/LogoSBWhite";
import Circles1 from "assets/CommonAssets/SVG/Circles1";
import Circles2 from "assets/CommonAssets/SVG/Circles2";
import Close from "assets/CommonAssets/SVG/close";
import Typography from "@mui/material/Typography";
import * as styles from "./AuthorizationBodyBlockThemes";
import * as textStyles from "themes/TextThemes";
import InfoForTutor from "./LogoInfoPanel/InfoForTutor/InfoForTutor";
import InfoForStudent from "./LogoInfoPanel/InfoForStudent/InfoForStudent";
import SButton from "components/UI/SButton";
import AuthorizationStore from "store/AuthorizationStore";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import SignIn from "./SignIn/SignIn";
import EnterSMS from "./EnterSMS/EnterSMS";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import SignUpTutor from "./SignUpTutor/SignUpTutor";
import SignUpStudent from "./SignUpStudent/SignUpStudent";
import { useMedias } from "API/mediasHook";

const AuthorizationBodyBlock = observer(({ children, ...props }) => {
    const navigate = useNavigate();

    const medias = useMedias();

    const getAuthorizationStep = () => {
        switch (AuthorizationStore.currentAuthorizationStep) {
            case "SignIn":
                return <SignIn />;
            case "EnterSMS":
                return <EnterSMS />;
            case "ForgotPassword":
                return <ForgotPassword />;
            case "SignUpStudent":
                return <SignUpStudent />;
            case "SignUpTutor":
                return <SignUpTutor />;
            default:
                return null;
        }
    };

    const handleClickBack = () => {
        navigate(AuthorizationStore.previousLocationPath ? AuthorizationStore.previousLocationPath : "/");
    };

    return (
        <Box sx={styles.Authorization(medias)}>
            {medias.sm && (
                <Box sx={styles.AuthorizationLeftPanel}>
                    <Box sx={styles.LeftCircles}>
                        <Circles1 />
                    </Box>
                    <Box sx={styles.RightCircles}>
                        <Circles2 />
                    </Box>
                    <Box sx={styles.AuthorizationLeftPanelContent}>
                        <Box>
                            <Box>
                                <LogoSBWhite />
                            </Box>
                            <Typography sx={textStyles.Caption1White70}>Сервис для поиска репетитора</Typography>
                        </Box>
                        {AuthorizationStore.signUpRole === "tutor" ? <InfoForTutor /> : <InfoForStudent />}
                    </Box>
                </Box>
            )}
            <Box sx={styles.AuthorizationRightPanel(medias)}>
                <Box sx={styles.AuthorizationRightPanelContent}>
                    <Box sx={styles.AuthorizationStepContainer}>
                        <Box sx={styles.AuthorizationStepContainerContent(medias)}>{getAuthorizationStep()}</Box>
                    </Box>
                    <SButton
                        variant="icon"
                        style={styles.CloseButton(medias)}
                        onClick={handleClickBack}
                    >
                        <Close />
                    </SButton>
                </Box>
            </Box>
        </Box>
    );
});

export default memo(AuthorizationBodyBlock);
