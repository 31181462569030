import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { ThemeProvider } from "@mui/material/styles";
import * as themes from "./OrdersFormatThemes";
import OrdersSearchStore from "store/OrdersSearchStore";
import FreeMultipleChoiceFilter from "components/UI/FreeMultipleChoiceFilter";

const OrdersFormat = observer(({ children, ...props }) => {
    const handleChange = () => {
        OrdersSearchStore.ToggleOrdersFormatExpanded();
    };

    const processingOrdersFormat = (event, value) => {
        OrdersSearchStore.SetOrdersFormat(value);
    };

    return (
        <ThemeProvider theme={themes.OrdersFormatAccordionTheme}>
            <Accordion
                expanded={OrdersSearchStore.ordersFormatExpanded}
                onChange={handleChange}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography sx={themes.headerOrdersFormatStyle}>Формат занятий</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FreeMultipleChoiceFilter
                        propOptions={OrdersSearchStore.optionsOrdersFormat}
                        valueToShow={"OrdersFormat"}
                        valueToBind={"Checked"}
                        valueForKey={"ID"}
                        processingChange={processingOrdersFormat}
                        themeWrapper={themes.FiltersOrdersFormatTheme}
                    ></FreeMultipleChoiceFilter>
                </AccordionDetails>
            </Accordion>
        </ThemeProvider>
    );
});

export default memo(OrdersFormat);
