import React from "react";

const StarOutlinedIcon = () => {
    return (
        <svg
            width="34"
            height="31"
            viewBox="0 0 34 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                id="Star 12"
                d="M16.7991 0.730672C16.875 0.554368 17.125 0.554368 17.2009 0.730672L21.2388 10.1071C21.4153 10.5168 21.8015 10.7974 22.2456 10.8386L32.411 11.7814C32.6021 11.7992 32.6794 12.0369 32.5351 12.1636L24.8654 18.9014C24.5302 19.1958 24.3827 19.6498 24.4808 20.0849L26.7254 30.0441C26.7676 30.2313 26.5654 30.3783 26.4003 30.2803L17.6222 25.068C17.2387 24.8402 16.7613 24.8402 16.3778 25.068L7.59967 30.2803C7.43462 30.3783 7.23238 30.2313 7.27459 30.0441L9.51918 20.0849C9.61726 19.6498 9.46975 19.1958 9.13461 18.9014L1.46486 12.1636C1.32065 12.0369 1.3979 11.7992 1.58904 11.7814L11.7544 10.8386C12.1985 10.7974 12.5847 10.5168 12.7612 10.1071L16.7991 0.730672Z"
                stroke="#BBBBC8"
            />
        </svg>
    );
};

export default StarOutlinedIcon;
