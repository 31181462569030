import React, { memo } from "react";
import { Typography } from "@mui/material";
import * as themes from "./FooterAreaThemes";
import Box from "@mui/material/Box";

const FooterArea = ({ children, ...props }) => {
    return (
        <Box sx={themes.ocFooterAreaStyle}>
            {props.value.Price?.PriceFrom ? (
                props.value.Price?.PriceTo ? (
                    <Typography sx={themes.priceTextStyle(0)}>
                        {props.value.Price.PriceFrom.toLocaleString()} - {props.value.Price.PriceTo.toLocaleString()} ₽/ч
                    </Typography>
                ) : (
                    <Typography sx={themes.priceTextStyle(0)}>{props.value.Price.PriceFrom.toLocaleString()} ₽/ч</Typography>
                )
            ) : (
                <Typography sx={themes.priceTextStyle(1)}>по договоренности</Typography>
            )}
            <Typography sx={themes.lastSeenTextStyle}>{props.value.OrderDate.toLocaleString("default", { day: "numeric", month: "long" })}</Typography>
        </Box>
    );
};

export default memo(FooterArea);
