import Button from "@mui/material/Button";
import MenuListComposition from "./PopperMenu";
import * as React from "react";
import Paper from "@mui/material/Paper";
import { ListItemButton, Typography } from "@mui/material";
import DropDownSVG from "assets/CommonAssets/SVG/DropDownSVG";
import { List } from "@mui/material";
import { useTranslation } from "react-i18next";

const EditDropDownContent = ({ dropDownList, setDropDownChoice, dropDownChoice, setOpenPopper, firstValueGrey, tr }) => {
    const { t, i18n } = useTranslation();

    const handleChooseValue = (value, event) => {
        setDropDownChoice(value);
        setOpenPopper(false);
    };

    return (
        <Paper
            sx={{
                boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
                borderRadius: "0 0 10px 10px",
                padding: "3px 5px 12px 5px",
            }}
        >
            <List
                sx={{
                    p: 0,
                    m: 0,
                    border: "none",
                    maxHeight: "200px",
                    position: "relative",
                    overflow: "auto",
                    "&::-webkit-scrollbar": {
                        width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        borderRadius: "10px",
                        background: "rgba(187, 187, 200, 0.50)",
                        height: "60px",
                    },
                }}
            >
                {dropDownList.map((dropDownValue, index) => (
                    <ListItemButton
                        key={index}
                        disableRipple
                        onClick={handleChooseValue.bind(this, dropDownValue)}
                        sx={{
                            color: firstValueGrey ? (dropDownValue === dropDownList[0] ? "#9E9EB0" : "#222") : "#222",
                            fontSize: "16px",
                            fontWeight: "400",
                            lineHeight: "140%" /* 21px */,
                            borderRadius: "5px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: "7px 10px 6px 10px",
                            ":hover": {
                                color: "#6212FF",
                                background: "rgba(187, 187, 200, 0.20)",
                            },
                        }}
                    >
                        {tr === true ? t(dropDownValue) : dropDownValue}
                        {dropDownValue === dropDownChoice ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                            >
                                <path
                                    d="M14 3L7 12.5L2 7.5"
                                    stroke="#6212FF"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        ) : null}
                    </ListItemButton>
                ))}
            </List>
        </Paper>
    );
};

const SDropDown = ({
    dropDownChoice,
    setDropDownChoice,
    dropDownList,
    disabled = false,
    paperWidth = "100%",
    buttonWidth = "100%",
    firstValueGrey = true,
    tr = false,
    withOutWidth,
    placeholder = "",
    error,
}) => {
    const [openDropDown, setOpenPopper] = React.useState(false);

    const { t, i18n } = useTranslation();

    return (
        <MenuListComposition
            disabled={disabled}
            open={openDropDown}
            setOpen={setOpenPopper}
            withOutWidth={withOutWidth}
            content={
                <EditDropDownContent
                    tr={tr}
                    error={error}
                    firstValueGrey={firstValueGrey}
                    setOpenPopper={setOpenPopper}
                    dropDownList={dropDownList}
                    dropDownChoice={dropDownChoice}
                    setDropDownChoice={setDropDownChoice}
                />
            }
            zIndex={1}
            paperSx={{
                borderRadius: "10px",
                width: paperWidth,
                border: "none",
                boxShadow: "none",
                p: 0,
                mt: "10px",
            }}
        >
            <Button
                disabled={disabled}
                disableRipple
                sx={{
                    width: buttonWidth,
                    height: "34px",
                    borderBottom: openDropDown === true ? (error ? "2px solid #d32f2f" : "2px solid #BBBBC8") : error ? "1px solid #d32f2f" : "1px solid #BBBBC8",
                    borderRadius: 0,
                    textTransform: "none",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "150%",
                    color: firstValueGrey ? (dropDownChoice === dropDownList[0] ? "#9E9EB0" : "#222") : "#222",
                    background: "transparent",
                    ":hover": {
                        color: firstValueGrey ? (dropDownChoice === dropDownList[0] ? "#9E9EB0" : "#222") : "#222",
                        background: "transparent",
                    },
                    pb: "10px",
                    pl: 0,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                {tr ? t(dropDownChoice) : dropDownChoice}
                {!dropDownChoice && <Typography sx={{ color: "#9E9EB0", textTransform: "none", fontSize: "16px", fontWeight: "400", lineHeight: "150%" }}>{placeholder}</Typography>}
                <DropDownSVG />
            </Button>
        </MenuListComposition>
    );
};

export default SDropDown;
