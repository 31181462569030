import { Button, Typography } from "@mui/material";
import React from "react";
import * as textThemes from "themes/TextThemes";
import * as themes from "./GoToTodayButtonThemes";
import { memo } from "react";
import { useTranslation } from "react-i18next";
const GoToTodayButton = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Button
            disableRipple
            sx={themes.GoToButton}
            onClick={props.handleClick}
        >
            <Typography sx={textThemes.Body2Black}>{t("main.today")}</Typography>
        </Button>
    );
};

export default memo(GoToTodayButton);
