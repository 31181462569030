import { Box, Typography } from "@mui/material";
import STextField from "components/UI/STextField";
import React, { memo } from "react";
import * as themes from "components/SettingsBodyBlock/TutorSettings/MainForm/MainFormThemes";
import SButton from "components/UI/SButton";
import STooltip from "components/TeacherQuestionnaireBodyBlock/Components/STooltip";
import ProfileLinksPlusIcon from "assets/TeacherQuestionnaireAssets/ProfileLinksPlusIcon";
import { observer } from "mobx-react-lite";
import DeleteLinkIcon from "assets/TeacherQuestionnaireAssets/DeleteLinkIcon";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import IconBackRounded from "assets/CommonAssets/SVG/IconBackRounded";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { useTranslation } from "react-i18next";
import * as textThemes from "themes/TextThemes";
import { useMedias } from "API/mediasHook";

const LinksEdit = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const handleClickPlus = () => {
        if (
            TeacherQuestionnaireStore.temporarySiteName !== "" &&
            TeacherQuestionnaireStore.temporarySiteName !== null &&
            TeacherQuestionnaireStore.temporarySiteName !== undefined &&
            TeacherQuestionnaireStore.temporarySiteLink !== "" &&
            TeacherQuestionnaireStore.temporarySiteLink !== null &&
            TeacherQuestionnaireStore.temporarySiteLink !== undefined
        ) {
            TeacherQuestionnaireStore.addLink({ siteName: TeacherQuestionnaireStore.temporarySiteName, link: TeacherQuestionnaireStore.temporarySiteLink });
            TeacherQuestionnaireStore.setTemporarySiteName("");
            TeacherQuestionnaireStore.setTemporarySiteLink("");
        }
    };

    const handleClickDeleteLink = (index) => {
        TeacherQuestionnaireStore.deleteLink(index);
    };

    const handleBackClick = () => {
        TeacherQuestionnaireStore.setTemporaryLinks(JSON.parse(JSON.stringify(TeacherQuestionnaireStore.teacher.links)));
        props.handleSet(0);
    };

    const handleSaveClick = () => {
        TeacherQuestionnaireStore.setTutorData("links", JSON.parse(JSON.stringify(TeacherQuestionnaireStore.temporaryLinks)));
    };

    const handleCancelClick = () => {
        TeacherQuestionnaireStore.setTemporaryLinks(JSON.parse(JSON.stringify(TeacherQuestionnaireStore.teacher.links)));
    };

    return (
        <ScrollToTop>
            <Box sx={themes.Top(medias)}>
                <Box sx={themes.EditTitle(medias)}>
                    <SButton
                        variant="icon"
                        onClick={handleBackClick}
                    >
                        <IconBackRounded />
                    </SButton>
                    <Typography sx={medias.sm ? textThemes.HeadlineH41Black : textThemes.HeadlineH5Black}>{t("teacherSettings.questionnaire.profilesLinks")}</Typography>
                </Box>
                <Box sx={themes.LinksColumn}>
                    <Box sx={themes.EditTitle(medias)}>
                        <Box sx={themes.LinkFields(medias)}>
                            <Box sx={themes.descriptionBlock}>
                                <Typography sx={textThemes.Body2Black}>{t("teacherSettings.questionnaire.siteName")}</Typography>
                                <STextField
                                    width={medias.sm ? "215px" : "100%"}
                                    placeholder={t("teacherSettings.questionnaire.enterSiteName")}
                                    value={TeacherQuestionnaireStore.temporarySiteName}
                                    changeFunction={(value) => {
                                        TeacherQuestionnaireStore.setTemporarySiteName(value);
                                    }}
                                />
                            </Box>
                            <Box sx={themes.descriptionBlock}>
                                <Typography sx={textThemes.Body2Black}>{t("teacherSettings.questionnaire.profileLink")}</Typography>
                                <Box sx={{ display: "flex", gap: "16px" }}>
                                    <STextField
                                        width={medias.sm ? "342px" : "100%"}
                                        placeholder={t("teacherSettings.questionnaire.copyProfileLink")}
                                        value={TeacherQuestionnaireStore.temporarySiteLink}
                                        changeFunction={(value) => {
                                            TeacherQuestionnaireStore.setTemporarySiteLink(value);
                                        }}
                                    />
                                    <STooltip
                                        title={t("main.add")}
                                        width={"fit-content"}
                                    >
                                        <button
                                            style={{ margin: 0, padding: 0 }}
                                            onClick={handleClickPlus}
                                        >
                                            <ProfileLinksPlusIcon />
                                        </button>
                                    </STooltip>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={themes.verticalDescriptionBlock(medias)}>
                        {TeacherQuestionnaireStore.temporaryLinks.length > 0 &&
                            TeacherQuestionnaireStore.temporaryLinks.map((linkElement, index) => {
                                return (
                                    <Box
                                        key={index}
                                        sx={themes.AimLinkLine(TeacherQuestionnaireStore.temporaryLinks.length > 0, medias)}
                                    >
                                        <Box sx={themes.exclamationBlock}>
                                            <SButton
                                                variant="icon"
                                                onClick={() => handleClickDeleteLink(index)}
                                            >
                                                <DeleteLinkIcon />
                                            </SButton>
                                            <Typography sx={textThemes.Body1Black}>{linkElement.siteName}</Typography>
                                        </Box>
                                        <Typography sx={[textThemes.Body1Black, themes.LinkText(medias)]}>{linkElement.link}</Typography>
                                    </Box>
                                );
                            })}
                    </Box>
                    <Box sx={themes.ButtonsBlock}>
                        <SButton
                            disabled={TeacherQuestionnaireStore.getDisabledSaveLinks()}
                            onClick={handleSaveClick}
                            variant="unshadowed"
                            padding="10px 20px 11px 20px"
                        >
                            {t("main.save")}
                        </SButton>
                        <SButton
                            variant="secondary"
                            padding="10px 20px 11px 20px"
                            disabled={TeacherQuestionnaireStore.getDisabledSaveLinks()}
                            onClick={handleCancelClick}
                        >
                            {t("main.cancel")}
                        </SButton>
                    </Box>
                </Box>
            </Box>
        </ScrollToTop>
    );
});

export default memo(LinksEdit);
