import React from "react";
import { Typography } from "@mui/material";
import MainForm from "components/SettingsBodyBlock/TutorSettings/MainForm/MainForm";
import Settings from "components/SettingsBodyBlock/TutorSettings/Settings/Settings";
import Security from "components/SettingsBodyBlock/TutorSettings/Security/Security";
import { observer } from "mobx-react-lite";
import TeacherSettingsStore from "store/TeacherSettingsStore";
import { memo } from "react";
import TutorSettingsNavigationTabs from "../TutorSettingsNavigationTabs/TutorSettingsNavigationTabs";
import { useTranslation } from "react-i18next";
import * as themes from "./TutorSettingsThemes";
import * as textThemes from "themes/TextThemes";
import Box from "@mui/material/Box";
import { useMedias } from "API/mediasHook";

const TutorSettings = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const MainWindow = () => {
        switch (TeacherSettingsStore.selectedTab) {
            case 1:
                return <MainForm />;
            case 2:
                return <Security />;
            case 3:
                return <Settings />;
            default:
                return null;
        }
    };

    return (
        <Box sx={themes.TutorSettings(medias)}>
            <Box sx={themes.TutorSettingsContent(medias)}>
                <Box sx={themes.TopBlock(medias)}>
                    <Box sx={themes.Title}>
                        <Typography sx={textThemes.HeadlineH3Black}>{t("teacherSettings.profile")}</Typography>
                    </Box>
                    <TutorSettingsNavigationTabs />
                </Box>
                <Box>{MainWindow()}</Box>
            </Box>
        </Box>
    );
});

export default memo(TutorSettings);
