import { styleProcessing } from "API/plugins/Utilities";

export const NextClass = (condition, theme) => {
    let commonStyle = {
        backgroundColor: "white",
        borderRadius: "20px",
        maxWidth: "558px",
        width: "100%",
        padding: "20px",
        border: condition ? "1px solid #DADADF" : "1px solid #19B20C",
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        position: "relative",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, boxSizing: "border-box", padding: "20px 16px 20px 16px", gap: "20px", maxWidth: "599px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Avatar = {
    width: "36px",
    height: "36px",
};

export const SubjectAimContainer = {
    display: "flex",
    flexDirection: "column",
    gap: 0,
};

export const StudentNameBox = {
    display: "flex",
    alignItems: "center",
    gap: "4px",
};

export const IconBox = {
    height: "fit-content",
    display: "flex",
    alignItems: "center",
};

export const StudentTextBox = {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
};

export const StudentBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        alignItems: "center",
        gap: "8px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, position: "absolute", bottom: "14px", left: "16px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ButtonsBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        alignItems: "center",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, flexDirection: "column-reverse", width: "100%", alignItems: "flex-end" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Divider = {
    width: "1px",
    height: "36px",
    background: "rgba(218, 218, 223, 0.50)",
};

export const BottomBlock = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
};

export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};
