/**
 * @fileoverview gRPC-Web generated client stub for pb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.12.4
// source: internal/push/pb/notifier.proto

/* eslint-disable */
// @ts-nocheck

const grpc = {};
grpc.web = require("grpc-web");

const proto = {};
proto.pb = require("./notifier_pb.js");

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.ConsumerNotifierServiceClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options.format = "text";

    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */
    this.client_ = new grpc.web.GrpcWebClientBase(options);

    /**
     * @private @const {string} The hostname
     */
    this.hostname_ = hostname.replace(/\/+$/, "");
};

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.ConsumerNotifierServicePromiseClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options.format = "text";

    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */
    this.client_ = new grpc.web.GrpcWebClientBase(options);

    /**
     * @private @const {string} The hostname
     */
    this.hostname_ = hostname.replace(/\/+$/, "");
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Empty,
 *   !proto.pb.StreamNewNotificationsResponse>}
 */
const methodDescriptor_ConsumerNotifierService_StreamNewNotifications = new grpc.web.MethodDescriptor(
    "/pb.ConsumerNotifierService/StreamNewNotifications",
    grpc.web.MethodType.SERVER_STREAMING,
    proto.pb.Empty,
    proto.pb.StreamNewNotificationsResponse,
    /**
     * @param {!proto.pb.Empty} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.StreamNewNotificationsResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.StreamNewNotificationsResponse>}
 *     The XHR Node Readable Stream
 */
proto.pb.ConsumerNotifierServiceClient.prototype.streamNewNotifications = function (request, metadata) {
    return this.client_.serverStreaming(
        this.hostname_ + "/pb.ConsumerNotifierService/StreamNewNotifications",
        request,
        metadata || {},
        methodDescriptor_ConsumerNotifierService_StreamNewNotifications,
    );
};

/**
 * @param {!proto.pb.Empty} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.pb.StreamNewNotificationsResponse>}
 *     The XHR Node Readable Stream
 */
proto.pb.ConsumerNotifierServicePromiseClient.prototype.streamNewNotifications = function (request, metadata) {
    return this.client_.serverStreaming(
        this.hostname_ + "/pb.ConsumerNotifierService/StreamNewNotifications",
        request,
        metadata || {},
        methodDescriptor_ConsumerNotifierService_StreamNewNotifications,
    );
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.Empty,
 *   !proto.pb.RecentNotificationsResponse>}
 */
const methodDescriptor_ConsumerNotifierService_RecentNotifications = new grpc.web.MethodDescriptor(
    "/pb.ConsumerNotifierService/RecentNotifications",
    grpc.web.MethodType.UNARY,
    proto.pb.Empty,
    proto.pb.RecentNotificationsResponse,
    /**
     * @param {!proto.pb.Empty} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.RecentNotificationsResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.RecentNotificationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.RecentNotificationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.ConsumerNotifierServiceClient.prototype.recentNotifications = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.ConsumerNotifierService/RecentNotifications", request, metadata || {}, methodDescriptor_ConsumerNotifierService_RecentNotifications, callback);
};

/**
 * @param {!proto.pb.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.RecentNotificationsResponse>}
 *     Promise that resolves to the response
 */
proto.pb.ConsumerNotifierServicePromiseClient.prototype.recentNotifications = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.ConsumerNotifierService/RecentNotifications", request, metadata || {}, methodDescriptor_ConsumerNotifierService_RecentNotifications);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.ReadNotificationsRequest,
 *   !proto.pb.ReadNotificationsResponse>}
 */
const methodDescriptor_ConsumerNotifierService_ReadNotifications = new grpc.web.MethodDescriptor(
    "/pb.ConsumerNotifierService/ReadNotifications",
    grpc.web.MethodType.UNARY,
    proto.pb.ReadNotificationsRequest,
    proto.pb.ReadNotificationsResponse,
    /**
     * @param {!proto.pb.ReadNotificationsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.ReadNotificationsResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.ReadNotificationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.ReadNotificationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.ReadNotificationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.ConsumerNotifierServiceClient.prototype.readNotifications = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.ConsumerNotifierService/ReadNotifications", request, metadata || {}, methodDescriptor_ConsumerNotifierService_ReadNotifications, callback);
};

/**
 * @param {!proto.pb.ReadNotificationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.ReadNotificationsResponse>}
 *     Promise that resolves to the response
 */
proto.pb.ConsumerNotifierServicePromiseClient.prototype.readNotifications = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.ConsumerNotifierService/ReadNotifications", request, metadata || {}, methodDescriptor_ConsumerNotifierService_ReadNotifications);
};

module.exports = proto.pb;
