import React, { memo } from "react";
import MyClassesStore from "store/MyClassesStore";
import MiniChatStore from "store/MiniChatStore";
import Box from "@mui/material/Box";
import * as styles from "./TutorBlockThemes";
import { Avatar, Typography } from "@mui/material";
import * as textStyles from "themes/TextThemes";
import SButton from "components/UI/SButton";
import Message from "assets/MyClassesAssets/SVG/Message";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

const TutorBlock = observer(({ children, ...props }) => {
    const navigate = useNavigate();

    return (
        <>
            {MyClassesStore.findNearestTodayClassIndex() !== null ? (
                <Box sx={styles.Tutor}>
                    <Box sx={styles.TutorProfile}>
                        <Avatar
                            sx={styles.Avatar}
                            src={MyClassesStore.classes[MyClassesStore.findNearestTodayClassIndex()].photo}
                        />
                        <Typography
                            sx={textStyles.Button14Black}
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/tutor/64902a483594e963dd20818a")}
                        >
                            {MyClassesStore.classes[MyClassesStore.findNearestTodayClassIndex()].tutor}
                        </Typography>
                    </Box>
                    <SButton
                        variant="icon"
                        onClick={() => MiniChatStore.SetOpen(true)}
                    >
                        <Message />
                    </SButton>
                </Box>
            ) : null}
        </>
    );
});

export default memo(TutorBlock);
