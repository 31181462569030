import { memo, useState } from "react";
import * as styles from "./PaymentInfoThemes";
import * as textStyles from "themes/TextThemes";
import { Box, Typography, Checkbox, IconButton } from "@mui/material";
import * as React from "react";
import PaymentStore from "store/PaymentStore";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import PurpleCheckBoxDefault from "assets/PaymentAssets/SVG/PurpleCheckBoxDefault";
import PurpleCheckBoxChecked from "assets/PaymentAssets/SVG/PurpleCheckBoxChecked";
import SButton from "components/UI/SButton";
import UsedBankCard from "./UsedBankCard/UsedBankCard";
import NewBankCard from "./NewBankCard/NewBankCard";
import SBPQRMethod from "./SBPQRMethod/SBPQRMethod";
import ModalPayQR from "./ModalPayQR/ModalPayQR";
import { useNavigate } from "react-router-dom";
import { useMedias } from "API/mediasHook";
import ArrowBackShortIcon from "assets/CommonAssets/SVG/arrowBackShortSVG";

const PaymentInfo = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const handlePay = () => {
        navigate(PaymentStore.previousPageRoute);
        PaymentStore.handleOpenClassPaid();
    };

    //Pay by QR
    const [dialogPayQROpen, SetDialogPayQROpen] = useState("");

    const showPayQRDialog = () => {
        SetDialogPayQROpen(true);
    };

    const discardPayQRDialog = () => {
        SetDialogPayQROpen(false);
    };

    const handleBack = () => {
        PaymentStore.setShowPaymentInfo(false);
    };

    return (
        <Box sx={styles.PaymentInfo(medias)}>
            <ModalPayQR
                open={dialogPayQROpen}
                handleDiscard={discardPayQRDialog}
            />
            <Box sx={styles.PaymentTitleAndMethods}>
                <Box sx={styles.headerWrapper}>
                    {!medias.sm ? (
                        <IconButton
                            sx={styles.IconButton}
                            disableRipple
                            onClick={handleBack}
                        >
                            <ArrowBackShortIcon />
                        </IconButton>
                    ) : null}
                    <Typography sx={medias.sm ? textStyles.HeadlineH41Black : textStyles.HeadlineH5Black}>Выберите способ оплаты</Typography>
                </Box>
                <Box sx={styles.PaymentMethods}>
                    <UsedBankCard />
                    <NewBankCard />
                    <SBPQRMethod />
                </Box>
            </Box>
            <Box sx={styles.PayButton}>
                <SButton
                    onClick={PaymentStore.selectedPaymentMethod === "sbp" ? showPayQRDialog : handlePay}
                    variant="unshadowed"
                    disabled={
                        PaymentStore.selectedPaymentMethod === null ||
                        PaymentStore.agreeWithRules === false ||
                        (PaymentStore.selectedPaymentMethod === "bankCard" &&
                            (PaymentStore.cardNumber == null ||
                                PaymentStore.cardNumber.length !== 19 ||
                                PaymentStore.cvc == null ||
                                PaymentStore.cvc.length !== 3 ||
                                PaymentStore.expireDate == null ||
                                PaymentStore.expireDate.length !== 5))
                    }
                    padding="11px 24px 12px 24px"
                    style={{ width: "100%" }}
                >
                    {`Оплатить ${PaymentStore.calculateTotal().toLocaleString("ru")} ₽`}
                </SButton>
                <Box sx={styles.Agreements}>
                    <Checkbox
                        checked={PaymentStore.agreeWithRules}
                        onChange={(e) => PaymentStore.setAgreeWithRules(e)}
                        sx={styles.CheckBoxAgreement}
                        icon={<PurpleCheckBoxDefault />}
                        checkedIcon={<PurpleCheckBoxChecked />}
                        disableRipple
                    />
                    <Typography sx={textStyles.Caption2DarkGrey}>
                        Нажимая «Оплатить», вы соглашаетесь с <span style={textStyles.Caption2Purple}>Правилами пользования сервиса</span> и <span style={textStyles.Caption2Purple}>возврата</span>{" "}
                        денежных средств
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
});

export default memo(PaymentInfo);
