import React, { useState } from "react";
import * as theme from "./OrderResponseTabsStyled";
import { Box, Tab } from "@mui/material";
import OrderResponseTutorsWrapper from "components/OrderResponse/OrderResponseTutorsWrapper";

const OrderResponseTabs = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box>
                <theme.StyledOrderResponseTabs
                    onChange={handleChange}
                    value={value}
                >
                    <Tab
                        label="Отклики"
                        disableRipple={true}
                    />
                    <Tab
                        label="Предложения"
                        disableRipple={true}
                    />
                    <Tab
                        label="Каталог репетиторов"
                        disableRipple={true}
                    />
                </theme.StyledOrderResponseTabs>
                <OrderResponseTutorsWrapper value={value} />
            </Box>
        </>
    );
};

export default OrderResponseTabs;
