import React from "react";
import { Stack, Typography } from "@mui/material";
import * as styles from "components/DiplomaChats/styles";
import PdfIconSVG from "assets/CommonAssets/SVG/pdfIconSVG";
import DocIconSVG from "assets/CommonAssets/SVG/docIconSVG";

const FILE_TYPES = {
    pdf: <PdfIconSVG />,
    doc: <DocIconSVG />,
    img: <span>скрин</span>,
};

const Application = ({
    fileInfo = {
        size: "3.2MB",
        type: "pdf",
        name: "Требования к дипломной работе.pdf",
    },
}) => {
    return (
        <Stack
            spacing={1.25}
            direction="row"
        >
            {/* TODO: Add Picture */}
            <span style={styles.fileCont}>{FILE_TYPES[fileInfo.type]}</span>
            <Stack spacing={0.375}>
                <Typography variant="standard">{fileInfo.name}</Typography>
                <Typography variant="grayText">{fileInfo.size}</Typography>
            </Stack>
        </Stack>
    );
};

export default Application;
