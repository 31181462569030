import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import React from "react";
import QuestionnaireButtons from "components/TeacherQuestionnaireBodyBlock/Components/QuestionnaireButtons";
import store from "store/TeacherQuestionnaireStore";
import { observer } from "mobx-react-lite";
import STextField from "components/UI/STextField";
import STooltip from "components/TeacherQuestionnaireBodyBlock/Components/STooltip";
import DeleteLinkIcon from "assets/TeacherQuestionnaireAssets/DeleteLinkIcon";
import DeleteStatementIcon from "assets/TeacherQuestionnaireAssets/DeleteStatementIcon";
import * as themes from "./ClassesPriceStepThemes";
import * as textThemes from "themes/TextThemes";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TutorLevel from "./TutorLevel/TutorLevel";
import ProfileLinks from "./ProfileLinks/ProfileLinks";
import { memo } from "react";
import { useMedias } from "API/mediasHook";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";

const ClassesPriceStep = observer(({ currentStep, setCurrentStep }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const handleDeleteAim = (subject, aim) => {
        TeacherQuestionnaireStore.setTutorAims(
            subject,
            TeacherQuestionnaireStore.teacher.subjects.find((someSubject) => someSubject.subjectName === subject).conditions.filter((aimToDelete) => aimToDelete.description !== aim),
        );
        if (store.teacher.subjects.find((someSubject) => someSubject.subjectName === subject).conditions.length === 0) {
            TeacherQuestionnaireStore.setTutorSubjects(TeacherQuestionnaireStore.teacher.subjects.filter((subjectToDelete) => subjectToDelete.subjectName !== subject));
        }
    };

    const hasEmptyPrice = () => {
        return TeacherQuestionnaireStore.teacher.subjects.some((subject) => subject.conditions.some((condition) => condition.price === ""));
    };

    return (
        <ScrollToTop>
            <Box sx={themes.TeacherPersonalDataStepWider}>
                <Box sx={themes.TeacherPersonalData}>
                    <TutorLevel />
                    <ProfileLinks />
                </Box>
                <Box sx={themes.CostBlock}>
                    <Box sx={themes.TeacherQuestionnaireTopBlock}>
                        <Typography sx={textThemes.HeadlineH41Black}>{t("TeacherQuestionnaire.costOfServices")}</Typography>
                        <Box sx={themes.TeacherQuestionnaireSubTitle}>
                            <Typography sx={textThemes.Body2DarkGrey}>{t("TeacherQuestionnaire.setPriceForEachSpecialization")}</Typography>
                        </Box>
                    </Box>
                    <Box sx={themes.SubjectsContainer}>
                        {store.teacher.subjects.map((subject, index) => {
                            return (
                                <Box
                                    key={index}
                                    sx={themes.SubjectContainer}
                                >
                                    <Box sx={themes.BottomButtonWrappe}>
                                        <Box sx={themes.TeacherQuestionnairePriceSubjects}>
                                            <Typography sx={textThemes.Button16Black}>{subject.subjectName}</Typography>
                                        </Box>
                                        <Box sx={themes.TeacherQuestionnairePriceAimsOuterContainer}>
                                            {subject.conditions.map((aim, aimIndex) => {
                                                return (
                                                    <Box
                                                        key={aimIndex}
                                                        sx={themes.TeacherQuestionnairePriceAimsInnerContainer(medias)}
                                                    >
                                                        <Box sx={themes.TeacherQuestionnairePriceAimsNameBox}>
                                                            {TeacherQuestionnaireStore.teacher.subjects.length === 1 && TeacherQuestionnaireStore.teacher.subjects[0].conditions.length === 1 ? (
                                                                <Box sx={themes.IconDelete} />
                                                            ) : (
                                                                <IconButton
                                                                    disableRipple
                                                                    sx={themes.IconDelete}
                                                                    onClick={() => {
                                                                        handleDeleteAim(subject.subjectName, aim.description);
                                                                    }}
                                                                >
                                                                    <DeleteLinkIcon />
                                                                </IconButton>
                                                            )}
                                                            <Typography sx={textThemes.Body1Black}>{aim.description}</Typography>
                                                        </Box>
                                                        <Box sx={themes.TeacherQuestionnairePriceAimsPriceBox}>
                                                            <STextField
                                                                numeric={true}
                                                                width={!medias.sm ? "100%" : aim.type === "class" ? "106px" : "186px"}
                                                                placeholder={aim.type === "class" ? t("TeacherQuestionnaire.price") : t("TeacherQuestionnaire.priceFrom")}
                                                                value={aim.price}
                                                                changeFunction={(value) => {
                                                                    TeacherQuestionnaireStore.setTutorPrice(subject.subjectName, aim.description, value);
                                                                }}
                                                            />
                                                            {aim.type === "class" ? <Typography sx={textThemes.Body1Grey}>{t("TeacherQuestionnaire.50min")}</Typography> : null}
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                        </Box>

                                        {!medias.sm ? (
                                            <>
                                                {store.teacher.subjects.length > 1 && (
                                                    <Button
                                                        sx={themes.TextButtonDelete}
                                                        disableRipple
                                                        variant="text"
                                                        onClick={() => {
                                                            TeacherQuestionnaireStore.setTutorSubjects(
                                                                TeacherQuestionnaireStore.teacher.subjects.filter((subjectToDelete) => subjectToDelete.subjectName !== subject.subjectName),
                                                            );
                                                        }}
                                                    >
                                                        {t("TeacherQuestionnaire.removeLesson")}
                                                    </Button>
                                                )}
                                            </>
                                        ) : null}
                                    </Box>
                                    <Box>
                                        {medias.sm && (
                                            <>
                                                {store.teacher.subjects.length > 1 ? (
                                                    <STooltip
                                                        title={t("main.delete")}
                                                        PopperProps={themes.TooltipPopperProps}
                                                    >
                                                        <Box
                                                            sx={themes.TeacherQuestionnaireDeleteStatementButton}
                                                            onClick={() => {
                                                                TeacherQuestionnaireStore.setTutorSubjects(
                                                                    TeacherQuestionnaireStore.teacher.subjects.filter((subjectToDelete) => subjectToDelete.subjectName !== subject.subjectName),
                                                                );
                                                            }}
                                                        >
                                                            <DeleteStatementIcon />
                                                        </Box>
                                                    </STooltip>
                                                ) : null}
                                            </>
                                        )}
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
                <QuestionnaireButtons
                    disabled={hasEmptyPrice()}
                    setCurrentStep={setCurrentStep}
                    currentStep={currentStep}
                />
            </Box>
        </ScrollToTop>
    );
});

export default memo(ClassesPriceStep);
