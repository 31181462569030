export const Table = {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
    textAlign: "left",
    borderCollapse: "collapse",
    maxWidth: "804px",
    width: "100%",
};

export const HeaderRow = {
    color: "#67677A",
    borderBottom: "1px solid #9E9EB0",
};

export const TooltipComponentProps = {
    tooltip: {
        sx: {
            margin: 0,
            padding: "10px",
            maxWidth: "309px",
            color: "#222",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "140%",
            bgcolor: "#fff",
            boxShadow: "0px 2px 10px 0px rgba(106, 99, 118, 0.15)",
            borderRadius: "10px",
        },
    },
};

export const TooltipPopperProps = {
    modifiers: [
        {
            name: "offset",
            options: {
                offset: [0, -2],
            },
        },
    ],
};

export const TooltipSx = {
    margin: 0,
    padding: 0,
};

export const IconButton = {
    margin: "0 0 0 8px",
    padding: 0,
    cursor: "initial",
};

export const HeaderRowTh = {
    padding: "12px 0 12px 0",
};

export const HeaderRowThDate = {
    padding: "12px 0 12px 30px",
};

export const HeaderRowThPoints = {
    padding: "12px 0 12px 8px",
    width: "144px",
    textAlign: "right",
};

export const Row = {
    color: "#222",
};

export const RowTd = {
    maxWidth: "356px",
    padding: "12px 0 12px 0",
    borderBottom: "1px solid #DADADF",
};

export const RowTdDate = {
    width: "120px",
    padding: "12px 0 12px 30px",
    borderBottom: "1px solid #DADADF",
};

export const RowTdPoints = {
    width: "120px",
    textAlign: "right",
    padding: "12px 24px 12px 8px",
    borderBottom: "1px solid #DADADF",
};
