import { Box, Typography } from "@mui/material";
import * as themes from "./RecommendedOrdersThemes";
import * as textThemes from "themes/TextThemes";
import OrderCard from "./OrderCard/OrderCard";
import { register } from "swiper/element/bundle";
import "swiper/css";
import "swiper/css/navigation";
import TeacherPageStore from "store/TutorClassesStore";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SButton from "components/UI/SButton";
import { memo } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import OrdersSearchStore from "../../../../store/OrdersSearchStore";
import { useMedias } from "API/mediasHook";

const RecommendedOrders = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const swiperRef = useRef();

    const navigate = useNavigate();

    const handleClickSearchOrders = () => {
        navigate("/orders-search");
    };

    useEffect(() => {
        register();
        const params = {
            spaceBetween: "21px",
            slidesPerView: "auto",
            centeredSlides: false,
            slidesPerGroupSkip: 2,
            grabCursor: true,
        };

        Object.assign(swiperRef.current, params);
        swiperRef.current.initialize();
    }, []);

    return (
        <Box style={themes.RecommendedOrders(medias)}>
            <Box sx={themes.Header(medias)}>
                <Typography sx={textThemes.HeadlineH5Black}>{t("teacherAccount.recommendedOrders.recommendedOrders")}</Typography>
                <SButton
                    variant="text-arrow"
                    onClick={handleClickSearchOrders}
                >
                    {t("teacherAccount.recommendedOrders.ordersSearch")}
                </SButton>
            </Box>
            <swiper-container
                ref={swiperRef}
                init="false"
            >
                {OrdersSearchStore.recommendedOrders.map((item, index) => {
                    return (
                        <swiper-slide
                            key={index}
                            style={themes.SwiperSlide}
                        >
                            <OrderCard item={item} />
                        </swiper-slide>
                    );
                })}
            </swiper-container>
        </Box>
    );
});

export default memo(RecommendedOrders);
