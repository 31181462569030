import React, { memo } from "react";
import { Tooltip, Box, Typography } from "@mui/material";
import * as themes from "./StartClassButtonThemes";
import * as textThemes from "themes/TextThemes";
import SButton from "components/UI/SButton";
import { useTranslation } from "react-i18next";
import { ClassPlaceIcon } from "assets/MyClassesAssets/SVG/ClassPlaceIcon";
import { observer } from "mobx-react-lite";
import TutorClassesStore from "store/TutorClassesStore";
import { useMedias } from "API/mediasHook";

const StartClassButton = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <>
            {TutorClassesStore.nextClass.place !== "online" ? (
                <Box sx={themes.TutorStudentHome}>
                    <ClassPlaceIcon />
                    <Typography sx={textThemes.Button14Black}>{TutorClassesStore.nextClass.place === "tutor" ? t("main.myClasses.tutorHome") : t("main.myClasses.studentHome")}</Typography>
                </Box>
            ) : TutorClassesStore.startClassButtonDisabled() ? (
                <Tooltip
                    title={t("main.myClasses.buttonWillBeAvailable")}
                    PopperProps={themes.TooltipPopperProps}
                    placement="top"
                >
                    <SButton
                        variant="green"
                        disabled={true}
                        style={themes.ButtonStyle(medias)}
                    >
                        {t("main.myClasses.startClass")}
                    </SButton>
                </Tooltip>
            ) : (
                <SButton
                    variant="green"
                    disabled={false}
                    style={themes.ButtonStyle(medias)}
                    onClick={() => window.open("/video-chat", "_blank")}
                >
                    {t("main.myClasses.startClass")}
                </SButton>
            )}
        </>
    );
});

export default memo(StartClassButton);
