import React, { memo } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { SingleFilterCheckedIcon, SingleFilterIcon } from "themes/AllPageThemes";

const FreeSingleChoiceFilter = ({ children, ...props }) => {
    const processing = (event) => {
        props.processingChange(event, event.target.value);
    };

    return (
        <ThemeProvider theme={props.themeWrapper}>
            <FormControl>
                <RadioGroup
                    value={props.propOption[props.valueToBind]}
                    onChange={processing}
                >
                    {props.propOptions.map((value) => (
                        <FormControlLabel
                            key={value[props.valueToBind]}
                            value={value[props.valueToBind]}
                            control={
                                <Radio
                                    disableRipple
                                    checkedIcon={<SingleFilterCheckedIcon></SingleFilterCheckedIcon>}
                                    icon={<SingleFilterIcon></SingleFilterIcon>}
                                />
                            }
                            label={value[props.valueToShow]}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </ThemeProvider>
    );
};

export default memo(FreeSingleChoiceFilter);
