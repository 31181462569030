import { Box, Button, Typography } from "@mui/material";
import React, { memo, useEffect } from "react";
import * as themes from "components/SettingsBodyBlock/TutorSettings/MainForm/MainFormThemes";
import SButton from "components/UI/SButton";
import STextField from "components/UI/STextField";
import DeleteLinkIcon from "assets/TeacherQuestionnaireAssets/DeleteLinkIcon";
import store from "store/TeacherSettingsStore";
import { observer } from "mobx-react-lite";
import { classesAimsDict } from "API/dictionaries/const_data";
import "components/TeacherQuestionnaireBodyBlock/Steps/TeacherSteps.css";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import SBAutoComplete from "components/UI/Autocomplete";
import IconBackRounded from "assets/CommonAssets/SVG/IconBackRounded";
import * as textThemes from "themes/TextThemes";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { useTranslation } from "react-i18next";
import STooltip from "components/TeacherQuestionnaireBodyBlock/Components/STooltip";
import DeleteStatementIcon from "assets/TeacherQuestionnaireAssets/DeleteStatementIcon";
import { useMedias } from "API/mediasHook";

const PricesEdit = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    useEffect(() => {
        TeacherQuestionnaireStore.addReadyToSubjects();
    }, []);

    const handleBackClick = () => {
        props.handleSet(0);
    };

    const handleSaveClick = () => {
        const clearSubjectsDict = TeacherQuestionnaireStore.getClearSubjectsDict();

        TeacherQuestionnaireStore.setTutorData("subjects", JSON.parse(JSON.stringify(clearSubjectsDict)));
    };

    const handleCancelClick = () => {
        TeacherQuestionnaireStore.setTemporarySubjectsData(JSON.parse(JSON.stringify(TeacherQuestionnaireStore.teacher.subjects)));
        TeacherQuestionnaireStore.addReadyToSubjects();
    };

    return (
        <ScrollToTop>
            <Box sx={themes.Top(medias)}>
                <Box sx={themes.EditTitle(medias)}>
                    <SButton
                        variant="icon"
                        onClick={handleBackClick}
                    >
                        <IconBackRounded />
                    </SButton>
                    <Typography sx={medias.sm ? textThemes.HeadlineH41Black : textThemes.HeadlineH5Black}>{t("teacherSettings.questionnaire.subjectsAndPrice")}</Typography>
                </Box>
                <Box sx={themes.Subjects}>
                    {Object.keys(TeacherQuestionnaireStore.temporarySubjects).map((item, index) => {
                        return (
                            <Box
                                key={index}
                                sx={themes.SubjectOuterContainer}
                            >
                                <Box sx={themes.SubjectBlock}>
                                    <Box sx={themes.TeacherQuestionnairePriceSubjects}>
                                        {TeacherQuestionnaireStore.temporarySubjects[item]["ready"] === true ? (
                                            <Typography sx={textThemes.Button16Black}>{item}</Typography>
                                        ) : (
                                            <SBAutoComplete
                                                notBlock={true}
                                                marginBottom="0px"
                                                value={item}
                                                disabled={TeacherQuestionnaireStore.temporarySubjects[item]["ready"] === true}
                                                disabledTextTheme={textThemes.Button16Black}
                                                changefunc={(value) => {
                                                    TeacherQuestionnaireStore.setSubjectData("subject", value);
                                                    TeacherQuestionnaireStore.setSubjectData("ready", value);
                                                }}
                                                inputvalue={TeacherQuestionnaireStore.temporarySubjects[item]["newSubject"]}
                                                inputchangefunc={(value) => {
                                                    TeacherQuestionnaireStore.setSubjectData("newSubject", value, item);
                                                }}
                                                list={store.subjects}
                                                placeholder={t("teacherSettings.questionnaire.enterSubject")}
                                            />
                                        )}
                                    </Box>
                                    <Box>
                                        <Box>
                                            {TeacherQuestionnaireStore.temporarySubjects[item]["ready"] && (
                                                <SBAutoComplete
                                                    notBlock={true}
                                                    marginBottom="30px"
                                                    value={TeacherQuestionnaireStore.temporarySubjects[item]["newAim"]}
                                                    changefunc={(value) => {
                                                        TeacherQuestionnaireStore.setSubjectData("aim", value, item);
                                                    }}
                                                    inputvalue={TeacherQuestionnaireStore.temporarySubjects[item]["newAim"]}
                                                    inputchangefunc={(value) => {
                                                        TeacherQuestionnaireStore.setSubjectData("newAim", value, item);
                                                    }}
                                                    list={Object.keys(classesAimsDict)}
                                                    placeholder={t("teacherSettings.questionnaire.addSpeciality")}
                                                />
                                            )}
                                        </Box>
                                        <Box sx={themes.AimPriceContainer}>
                                            {Object.keys(TeacherQuestionnaireStore.temporarySubjects[item])
                                                .filter((key) => key !== "ready" && key !== "newAim" && key !== "newSubject")
                                                .map((aim, aimIndex) => {
                                                    return (
                                                        <Box
                                                            key={index + aimIndex}
                                                            sx={themes.AimPriceLine(aim.value, medias)}
                                                        >
                                                            <Box sx={themes.removeAimBlock}>
                                                                <SButton
                                                                    variant="icon"
                                                                    onClick={() => TeacherQuestionnaireStore.setSubjectData("removeAim", aim, item)}
                                                                >
                                                                    <DeleteLinkIcon />
                                                                </SButton>
                                                                <Typography sx={textThemes.Body1Black}>{aim}</Typography>
                                                            </Box>
                                                            <Box sx={themes.priceBlock(medias)}>
                                                                <STextField
                                                                    width={!medias.sm ? "100%" : classesAimsDict[aim] === "hour" ? "106px" : "186px"}
                                                                    placeholder={
                                                                        classesAimsDict[aim] === "hour" ? t("teacherSettings.questionnaire.priceRub") : t("teacherSettings.questionnaire.fromPriceRub")
                                                                    }
                                                                    value={TeacherQuestionnaireStore.temporarySubjects[item][aim]}
                                                                    changeFunction={(value) => {
                                                                        TeacherQuestionnaireStore.setSubjectData("changePrice", aim, item, value);
                                                                    }}
                                                                />
                                                                {classesAimsDict[aim] === "hour" ? <Typography sx={textThemes.Body1Grey}>{t("teacherSettings.questionnaire.50min")}</Typography> : null}
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                        </Box>
                                        {!medias.sm ? (
                                            <>
                                                {Object.keys(TeacherQuestionnaireStore.temporarySubjects).length > 1 && (
                                                    <Button
                                                        sx={themes.TextButtonDelete}
                                                        disableRipple
                                                        variant="text"
                                                        onClick={() => {
                                                            TeacherQuestionnaireStore.setSubjectData("removeSubject", item);
                                                        }}
                                                    >
                                                        {t("TeacherQuestionnaire.removeLesson")}
                                                    </Button>
                                                )}
                                            </>
                                        ) : null}
                                    </Box>
                                </Box>
                                <Box>
                                    {medias.sm && (
                                        <>
                                            {Object.keys(TeacherQuestionnaireStore.temporarySubjects).length > 1 ? (
                                                <STooltip
                                                    title={t("main.delete")}
                                                    width={"fit-content"}
                                                    offsetY={-8}
                                                >
                                                    <Box
                                                        onClick={() => {
                                                            TeacherQuestionnaireStore.setSubjectData("removeSubject", item);
                                                        }}
                                                    >
                                                        <DeleteStatementIcon />
                                                    </Box>
                                                </STooltip>
                                            ) : null}
                                        </>
                                    )}
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
                <button
                    className="TeacherQuestionnaireAddFieldsButton"
                    style={{ marginTop: "40px" }}
                    onClick={() => {
                        TeacherQuestionnaireStore.setSubjectData("addNewSubject");
                    }}
                >
                    {t("teacherSettings.questionnaire.addSubject")}
                </button>
                <Box sx={themes.ButtonsBlock}>
                    <SButton
                        variant="unshadowed"
                        padding="10px 20px 11px 20px"
                        disabled={TeacherQuestionnaireStore.getDisabledSubjectPrices()}
                        onClick={handleSaveClick}
                    >
                        {t("main.save")}
                    </SButton>
                    <SButton
                        variant="secondary"
                        padding="10px 20px 11px 20px"
                        disabled={TeacherQuestionnaireStore.getDisabledSubjectPrices()}
                        onClick={handleCancelClick}
                    >
                        {t("main.cancel")}
                    </SButton>
                </Box>
            </Box>
        </ScrollToTop>
    );
});

export default memo(PricesEdit);
