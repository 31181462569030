import { Popper } from "@mui/material";
import React from "react";

export const CustomPopper = ({ anchorEl, open, id, onMouseLeave, children, placement, marginLeft, marginTop, minWidth, ...props }) => {
    return (
        <Popper
            anchorEl={anchorEl}
            open={open}
            placement={placement}
            id={id}
            onMouseLeave={onMouseLeave}
            marginLeft={marginLeft}
            marginTop={marginTop}
            minWidth={minWidth}
            {...props}
        >
            {children}
        </Popper>
    );
};
