import { styleProcessing } from "API/plugins/Utilities";

export const Notifications = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        maxWidth: "701px",
        width: "100%",
        borderRadius: "20px",
        background: "#FFF",
        boxShadow: "0px 4px 30px 0px rgba(106, 99, 118, 0.05)",
        paddingBottom: "30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const NotificationsTitle = (theme) => {
    let commonStyle = {
        borderRadius: "20px 20px 0px 0px",
        background: "#222",
        padding: "10px 0px 10px 30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "10px 0px 10px 20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const notificationContent = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            maxWidth: "220px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const notificationBlock = {
    display: "flex",
    justifyContent: "space-between",
};

export const notificationBlocks = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        paddingLeft: "30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "14px",
            paddingLeft: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const notificationGroup = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const notificationBlocksText = (theme) => {
    let commonStyle = {};

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            marginBottom: "-10px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
