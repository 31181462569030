import React, { useState } from "react";

import { Dialog, Box, ThemeProvider, IconButton, Stack, Typography } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

import SButton from "components/UI/SButton";

import SendForCorrectionInfoModal from "./CorrectionInfo/SendForCorrectionInfoModal";

import * as themes from "components/OrderBodyBlock/StickyActionBlock/ModalResponseThemes";
import * as styles from "pages/DiplomaChats/styles";
import * as textStyles from "themes/TextThemes";

const SendForCorrectionModal = ({ open = false, close }) => {
    const [showCorrectionInfoModal, setShowCorrectionInfoModal] = useState(false);

    const handleShowCorrectionInfoModal = () => {
        close();
        setShowCorrectionInfoModal(true);
    };

    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={open}
                onClose={close}
            >
                <Box sx={themes.dialogContainerStyle}>
                    <ThemeProvider theme={themes.CloseIconTheme}>
                        <IconButton onClick={close}>
                            <CloseRounded />
                        </IconButton>
                    </ThemeProvider>
                    <Stack
                        sx={styles.reviewModalContainerStyle}
                        padding="30px 40px 20px 40px"
                        direction="column"
                        spacing={2.5}
                    >
                        <Typography
                            textAlign="center"
                            sx={textStyles.HeadlineH41Black}
                        >
                            Отправить работу на корректировку
                        </Typography>
                        <Typography
                            sx={textStyles.Body2Black}
                            textAlign="center"
                        >
                            Если в работе есть недочёты или ошибки, вы можете отправить её на корректировку. У специалиста будет 7 дней, чтобы исправить и отправить работу повторно.
                        </Typography>
                        <Stack
                            spacing={1.5}
                            justifyContent="flex-end"
                            direction="row"
                            paddingTop="20px"
                        >
                            <SButton
                                variant="secondary"
                                onClick={close}
                            >
                                Отменить
                            </SButton>
                            <SButton style={{ height: "36px" }}>
                                <Typography
                                    variant="standardWhite"
                                    onClick={handleShowCorrectionInfoModal}
                                >
                                    Продолжить
                                </Typography>
                            </SButton>
                        </Stack>
                    </Stack>
                </Box>
            </Dialog>
            <SendForCorrectionInfoModal
                open={showCorrectionInfoModal}
                close={() => setShowCorrectionInfoModal(false)}
            />
        </ThemeProvider>
    );
};

export default SendForCorrectionModal;
