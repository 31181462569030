import Button from "@mui/material/Button";
import MenuListComposition from "components/UI/PopperMenu";
import * as React from "react";
import Paper from "@mui/material/Paper";
import { List, ListItemButton } from "@mui/material";
import DropDownSVG from "assets/CommonAssets/SVG/DropDownSVG";
import CheckMark from "assets/CommonAssets/SVG/CheckMark";
import * as styles from "components/SettingsBodyBlock/StudentSettings/Profile/ProfileForms/ProfileFormsThemes";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import SettingsStore from "store/SettingsStore";

const EditBirthMonthContent = ({ setOpenMonthPopper }) => {
    const handleChooseBirthMonth = (value, event) => {
        SettingsStore.setTemporaryProfileInfo("birthMonth", value);
        setOpenMonthPopper(false);
    };

    return (
        <Paper sx={styles.EditContentPaper}>
            <List sx={styles.EditContentList}>
                {SettingsStore.monthList.map((month, index) => (
                    <ListItemButton
                        key={index}
                        disableRipple
                        onClick={handleChooseBirthMonth.bind(this, index)}
                        sx={styles.EditContentListItem(month === SettingsStore.monthList[0])}
                    >
                        {month}
                        {month === SettingsStore.TemporaryUserInfo.birthMonth && <CheckMark />}
                    </ListItemButton>
                ))}
            </List>
        </Paper>
    );
};

const EditBirthMonth = observer(({ children, ...props }) => {
    const [openMonthPopper, setOpenMonthPopper] = React.useState(false);

    return (
        <MenuListComposition
            placement="bottom-start"
            open={openMonthPopper}
            setOpen={setOpenMonthPopper}
            content={<EditBirthMonthContent setOpenMonthPopper={setOpenMonthPopper} />}
            zIndex={1}
            paperSx={styles.EditPaper("190px")}
        >
            <Button
                disableRipple
                sx={styles.EditButton(openMonthPopper, SettingsStore.TemporaryUserInfo.birthMonth === SettingsStore.monthList[0], "113px")}
            >
                {SettingsStore.monthList[SettingsStore.TemporaryUserInfo.birthMonth]}
                <DropDownSVG />
            </Button>
        </MenuListComposition>
    );
});

export default memo(EditBirthMonth);
