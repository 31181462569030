import { createTheme } from "@mui/material/styles";
import { styleProcessing } from "API/plugins/Utilities";

export const DialogContainerTheme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: "rgba(0, 0, 0, 0)!important",
                    boxShadow: "none!important",
                },
            },
        },
    },
});

export const dialogContainerMiniCropper = {
    display: "flex",
    flexDirection: "row",
    gap: "50px",
    justifyContent: "center",
    padding: "30px 0 30px 0",
};

export const adviceBlock = {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    padding: "20px",
    borderRadius: "20px",
    border: "1px solid #BBBBC8",
    marginTop: "20px",
};

export const dialogContainerMiniCropperMiniatures = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
};

export const dialogContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "10px",
    boxSizing: "border-box",
};

export const dialogContainerHeaderStyle = {
    bgcolor: "#F7F7F7",
    borderRadius: "20px 20px 0px 0px",
    borderBottom: "1px solid var(--Grey-Light-Grey, #BBBBC8)",
    width: "100%",
};

export const dialogContainerHeaderInsideStyle = {
    padding: "16px 0 16px 30px",
};

export const videoPlayer = {
    marginTop: "10px",
    width: "289px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const dialogContainerBodyStyle = (theme) => {
    let commonStyle = {
        bgcolor: "#FFF",
        borderRadius: "0px 0px 20px 20px",
        borderBottom: "1px solid var(--Grey-Light-Grey, #BBBBC8)",
        width: "100%",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            borderRadius: "none",
            borderBottom: "none",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const dialogContainerBodyInsideStyle = (theme) => {
    let commonStyle = {
        padding: "0 30px 30px 30px",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: 0,
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const pointSentence = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
};

export const photoExamplesTextBlocks = {
    display: "flex",
    flexDirection: "column",
    gap: "67px",
};

export const photoExamplesTextBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
};

export const photoExamplesSentenceBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
};

export const cardContainerStyle = (theme) => {
    let commonStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxSizing: "border-box",
        flexDirection: "column",
        gap: "30px",
        borderRadius: "20px",
        background: "var(--main-white, #FFF)",
        width: "fit-content",
        height: "fit-content",
        maxWidth: "770px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const cardButtonsGroupStyle = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "row",
        gap: "16px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "0 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const headerBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
    width: "100%",
};

export const headerTextStyle = {
    color: "var(--text-black, #222)",
    textAlign: "center",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "140%",
};

export const photoExamplesPhotoBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "59px",
};

export const photoExamplesPhotoHorizontalBlock = {
    display: "flex",
    flexDirection: "row",
    gap: "27px",
};

export const photoExamplesPhotoVerticalBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
};

export const dialogMainPhotoContainer = {
    display: "flex",
    flexDirection: "row",
    gap: "107px",
};

export const CloseIconTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid #9E9EB0",
                    borderRadius: "50%",
                    background: "transparent",
                    width: "24px",
                    height: "24px",
                    "&:hover": {
                        background: "#transparent!important",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "#transparent!important",
                        borderColor: "#6212FF",
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    padding: 0,
                    color: "#FFFFFF",
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    "&:hover": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                    "&:active": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                },
            },
        },
    },
});

export const MobileTextPadding = {
    padding: "20px 16px 0 16px",
};
