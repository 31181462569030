import Typography from "@mui/material/Typography";
import { FormGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";
import filterStore from "store/FilterStore";
import Box from "@mui/material/Box";

const SCheckboxGroup = function ({ title, varstate, funcstate, caption = 1 }) {
    const sxlist = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "16px",
        lineHeight: "150%",
        fontWeight: 400,
        textTransform: "none",
        color: "#67677A",
        pb: "0px",
        mb: "30px",
    };

    const sxlistUnderLabel = {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "150%",
        fontWeight: 400,
        textTransform: "none",
        color: "#9E9EB0",
        ml: "32px",
    };

    return (
        <Box>
            <Typography sx={sxlist}>{title}</Typography>
            <Box sx={{ marginBottom: "0px" }}>
                <FormGroup>
                    {Object.entries(varstate).map(([k, v], index) => {
                        return (
                            <Box key={index}>
                                <FormControlLabel
                                    style={{
                                        height: "24px",
                                        marginBottom: index === Object.entries(varstate).length - 1 ? "0px" : caption === 0 ? "20px" : "4px",
                                    }}
                                    control={
                                        <Checkbox
                                            checked={v.selected}
                                            onChange={(event, newInputValue) => {
                                                filterStore.setSubmitButtonFilter(false);
                                                funcstate({
                                                    ...varstate,
                                                    [k]: { ...varstate[k], selected: newInputValue },
                                                });
                                            }}
                                            style={{ color: "#6212FF" }}
                                        />
                                    }
                                    label={v.title}
                                    sx={{
                                        fontFamily: "Inter",
                                        fontStyle: "normal",
                                        fontSize: "16px",
                                        lineHeight: "150%",
                                        fontWeight: 400,
                                        textTransform: "none",
                                        color: "#222",
                                    }}
                                />
                                {caption === 1 ? (
                                    <Typography
                                        style={{
                                            marginBottom: index === Object.entries(varstate).length - 1 ? "0px" : "20px",
                                        }}
                                        sx={sxlistUnderLabel}
                                    >
                                        {v.subtitle}
                                    </Typography>
                                ) : null}
                            </Box>
                        );
                    })}
                </FormGroup>
            </Box>
        </Box>
    );
};

export default SCheckboxGroup;
