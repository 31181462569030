import * as themes from "./LeftPanelThemes";
import * as textThemes from "themes/TextThemes";
import { Box, Drawer, IconButton, Link, Typography } from "@mui/material";
import HeaderMenuIcon from "assets/HeaderAssets/SVG/headerMenu";
import HeaderMobileMenu from "./HeaderMobileMenu/HeaderMobileMenu";
import IconLogo from "assets/ChatAssets/SVG/iconLogo";
import UserStore from "store/UserStore";
import Button from "@mui/material/Button";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { memo, useState } from "react";
import { useMedias } from "API/mediasHook";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

const LeftPanel = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const medias = useMedias();

    const navigate = useNavigate();

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClickCreateOrder = () => {
        navigate("/bid");
    };

    const handleClickTutorCatalog = () => {
        navigate("/catalog");
    };

    const handleClickSearchOrders = () => {
        navigate("/orders-search");
    };

    const handleClickStudyBuddyLogo = () => {
        navigate("/");
    };

    return (
        <Box sx={themes.LeftPanel(medias)}>
            {/*{!medias.sm && (*/}
            {/*    <Box>*/}
            {/*        <IconButton*/}
            {/*            onClick={toggleMenu}*/}
            {/*            sx={themes.HeaderMenu}*/}
            {/*        >*/}
            {/*            <HeaderMenuIcon style={themes.HeaderMobileMenuIcon} />*/}
            {/*        </IconButton>*/}
            {/*        <Drawer*/}
            {/*            anchor="left"*/}
            {/*            open={menuOpen}*/}
            {/*            onClose={toggleMenu}*/}
            {/*        >*/}
            {/*            <HeaderMobileMenu handleClose={toggleMenu} />*/}
            {/*        </Drawer>*/}
            {/*    </Box>*/}
            {/*)}*/}
            <IconButton
                disableRipple
                sx={themes.StudyBuddyLogoIcon}
                onClick={handleClickStudyBuddyLogo}
            >
                <IconLogo />
            </IconButton>
            {UserStore.role === "student" || UserStore.isAuth === false ? (
                <Box sx={themes.LeftPanelButton(medias)}>
                    <Button
                        disableRipple
                        onClick={handleClickCreateOrder}
                        component={Link}
                        sx={themes.HeaderAccountButton}
                    >
                        <Typography sx={textThemes.Body2Black}>{t("main.header.createOrder")}</Typography>
                    </Button>
                    <Button
                        disableRipple
                        onClick={handleClickTutorCatalog}
                        component={Link}
                        sx={themes.HeaderAccountButton}
                    >
                        <Typography sx={textThemes.Body2Black}>{t("main.header.allTutors")}</Typography>
                    </Button>
                </Box>
            ) : (
                <Box sx={themes.LeftPanelButton(medias)}>
                    <Button
                        disableRipple
                        onClick={handleClickSearchOrders}
                        component={Link}
                        sx={themes.HeaderAccountButton}
                    >
                        <Typography sx={textThemes.Body2Black}>{t("main.header.searchOrders")}</Typography>
                    </Button>
                </Box>
            )}
        </Box>
    );
});

export default memo(LeftPanel);
