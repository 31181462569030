import React from "react";

const IconFavourites = ({ children, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5512 18.7765C10.2468 19.0745 9.75326 19.0745 9.44885 18.7765L1.56556 11.0584C-3.25829 5.55636 4.10278 -2.26425 9.61732 2.4501C9.83349 2.63491 10.1666 2.63491 10.3827 2.4501C15.8973 -2.26425 23.2583 5.55636 18.4344 11.0584L10.5512 18.7765Z"
                fill={props.hovered ? "#8141FF" : "#222"}
            />
        </svg>
    );
};

export default IconFavourites;
