import React, { memo } from "react";
import * as themes from "./UpcomingClassCardThemes";
import * as textThemes from "themes/TextThemes";
import Box from "@mui/material/Box";
import { Avatar, Divider, Tooltip, Typography } from "@mui/material";
import RegularStudentIcon from "assets/TeacherClassesAssets/SVG/RegularStudentIcon";
import NewStudentIcon from "assets/TeacherClassesAssets/SVG/NewStudentIcon";
import { useTranslation } from "react-i18next";
import TutorClassesStore from "store/TutorClassesStore";
import Distant from "assets/PAAssets/SVG/distant";
import Home from "assets/PAAssets/SVG/home";
import { observer } from "mobx-react-lite";
import Message from "assets/PAAssets/SVG/message";
import SButton from "components/UI/SButton";
import EmptyAvatar from "assets/TeacherClassesAssets/PNG/EmptyAvatar.png";
import MiniChatStore from "store/MiniChatStore";
import ClassMoreMenu from "./ClassMoreMenu/ClassMoreMenu";
import MyClassesStore from "store/MyClassesStore";
import { useMedias } from "API/mediasHook";

const UpcomingClassCard = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const getClassTimeRange = (classItem) => {
        const timeRange = TutorClassesStore.getTimeDifference(classItem);

        return `${timeRange} ${t("main.min")}`;
    };

    const getFormatedDate = (lesson, attribute) => {
        const dateObject = TutorClassesStore.formatTodayClassDate(lesson, attribute);

        return `${dateObject.date} ${t(dateObject.month)}, ${t(dateObject.weekday)}`;
    };

    const showDialog = () => {
        MiniChatStore.SetOpen(true);
    };

    const MobileVersion = (
        <Box sx={themes.UpcomingClassCard(props.classItem.rescheduleTimeStart !== null, medias)}>
            <Box sx={themes.LeftPanel(props.classItem.rescheduleTimeStart === null, medias)}>
                {props.classItem.rescheduleTimeStart === null ? (
                    <Box sx={themes.TimeAndMinutesBlock}>
                        <Box sx={themes.TimeBlock(medias)}>
                            <Typography sx={textThemes.Button14Black}>{TutorClassesStore.formatTimeRange(props.classItem)},</Typography>
                            <Typography sx={textThemes.Button14Black}>{getClassTimeRange(props.classItem)}</Typography>
                        </Box>
                        <ClassMoreMenu lesson={props.classItem} />
                    </Box>
                ) : (
                    <Box sx={themes.RescheduleBlock(medias)}>
                        <Typography sx={textThemes.Body2Orange}>{t("teacherClasses.rescheduleRequestOne")}</Typography>
                        <Box sx={themes.RescheduleDetails}>
                            <Box sx={themes.TextAndRescheduleTimeBlock}>
                                <Typography sx={textThemes.Caption2Grey}>{t("teacherClasses.currentTime")}</Typography>
                                <Typography
                                    sx={textThemes.Body2Black}
                                >{`${getFormatedDate(props.classItem, "timeStart")}, ${MyClassesStore.formatTodayClassTime(props.classItem, "default")}`}</Typography>
                            </Box>
                            <Box sx={themes.TextAndRescheduleTimeBlock}>
                                <Typography sx={textThemes.Caption2Grey}>{t("teacherClasses.requestedTime")}</Typography>
                                <Typography
                                    sx={textThemes.Body2Black}
                                >{`${getFormatedDate(props.classItem, "rescheduleTimeStart")}, ${MyClassesStore.formatTodayClassTime(props.classItem, "reschedule")}`}</Typography>
                            </Box>
                        </Box>
                    </Box>
                )}
                <Box sx={themes.SubjectAimBlock(medias)}>
                    <Typography sx={textThemes.Caption1Grey}>{props.classItem.subject},</Typography>
                    <Typography sx={textThemes.Caption1Grey}>{props.classItem.aim}</Typography>
                </Box>
            </Box>
            <Divider />
            <Box sx={themes.RightPanel(medias)}>
                <Box sx={themes.StudentBlock}>
                    <Avatar
                        src={props.classItem.studentAvatar ? props.classItem.studentAvatar : EmptyAvatar}
                        sx={themes.Avatar(medias)}
                    />
                    {props.classItem.studentType === "constant" ? (
                        <Box sx={themes.StudentTextBox}>
                            <Box sx={themes.StudentNameBox}>
                                <Typography sx={textThemes.Body2Black}>{props.classItem.studentName}</Typography>
                                <Box sx={themes.IconBox}>
                                    <RegularStudentIcon />
                                </Box>
                            </Box>
                        </Box>
                    ) : props.classItem.studentType === "new" ? (
                        <Box sx={themes.StudentTextBox}>
                            <Box sx={themes.StudentNameBox}>
                                <Typography sx={textThemes.Body2Black}>{props.classItem.studentName}</Typography>
                                <Box sx={themes.IconBox}>
                                    <NewStudentIcon />
                                </Box>
                            </Box>
                            <Typography sx={textThemes.Caption2DarkGrey}>{t("teacherClasses.newStudent")}</Typography>
                        </Box>
                    ) : (
                        <Typography sx={textThemes.Body2Black}>{props.classItem.studentName}</Typography>
                    )}
                </Box>
                <SButton
                    variant="icon"
                    onClick={showDialog}
                >
                    <Message />
                </SButton>
            </Box>
        </Box>
    );

    return (
        <>
            {medias.sm ? (
                <Box sx={themes.UpcomingClassCard(props.classItem.rescheduleTimeStart !== null, medias)}>
                    <Box sx={themes.LeftPanel(props.classItem.rescheduleTimeStart === null, medias)}>
                        <Box sx={themes.StudentBlock}>
                            <Avatar
                                src={props.classItem.studentAvatar ? props.classItem.studentAvatar : EmptyAvatar}
                                sx={themes.Avatar(medias)}
                            />
                            {props.classItem.studentType === "constant" ? (
                                <Box sx={themes.StudentTextBox}>
                                    <Box sx={themes.StudentNameBox}>
                                        <Typography sx={textThemes.Body2Black}>{props.classItem.studentName}</Typography>
                                        <Box sx={themes.IconBox}>
                                            <RegularStudentIcon />
                                        </Box>
                                    </Box>
                                    <Typography sx={textThemes.Caption2DarkGrey}>{t("teacherClasses.constantStudent")}</Typography>
                                </Box>
                            ) : props.classItem.studentType === "new" ? (
                                <Box sx={themes.StudentTextBox}>
                                    <Box sx={themes.StudentNameBox}>
                                        <Typography sx={textThemes.Body2Black}>{props.classItem.studentName}</Typography>
                                        <Box sx={themes.IconBox}>
                                            <NewStudentIcon />
                                        </Box>
                                    </Box>
                                    <Typography sx={textThemes.Caption2DarkGrey}>{t("teacherClasses.newStudent")}</Typography>
                                </Box>
                            ) : (
                                <Typography sx={textThemes.Body2Black}>{props.classItem.studentName}</Typography>
                            )}
                        </Box>
                        <Box sx={themes.Divider(props.classItem.rescheduleTimeStart === null)} />
                        {props.classItem.rescheduleTimeStart === null ? (
                            <Box sx={themes.TimeAndMinutesBlock}>
                                <Box sx={themes.TimeBlock(medias)}>
                                    <Typography sx={textThemes.Body2Black}>{TutorClassesStore.formatTimeRange(props.classItem)}</Typography>
                                </Box>
                                <Box sx={themes.Divider} />
                                <Box sx={themes.MinutesBlock}>
                                    <Typography sx={textThemes.Body2Black}>{getClassTimeRange(props.classItem)}</Typography>
                                </Box>
                            </Box>
                        ) : (
                            <Box sx={themes.RescheduleBlock(medias)}>
                                <Typography sx={textThemes.Body2Orange}>{t("teacherClasses.rescheduleRequestOne")}</Typography>
                                <Box sx={themes.RescheduleDetails}>
                                    <Box sx={themes.TextAndRescheduleTimeBlock}>
                                        <Typography sx={textThemes.Caption2Grey}>{t("teacherClasses.currentTime")}</Typography>
                                        <Typography
                                            sx={textThemes.Body2Black}
                                        >{`${getFormatedDate(props.classItem, "timeStart")}, ${MyClassesStore.formatTodayClassTime(props.classItem, "default")}`}</Typography>
                                    </Box>
                                    <Box sx={themes.TextAndRescheduleTimeBlock}>
                                        <Typography sx={textThemes.Caption2Grey}>{t("teacherClasses.requestedTime")}</Typography>
                                        <Typography
                                            sx={textThemes.Body2Black}
                                        >{`${getFormatedDate(props.classItem, "rescheduleTimeStart")}, ${MyClassesStore.formatTodayClassTime(props.classItem, "reschedule")}`}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )}

                        <Box sx={themes.Divider(props.classItem.rescheduleTimeStart === null)} />
                        <Box sx={themes.PlaceSubjectBlock}>
                            {props.classItem.place === "online" ? (
                                <Tooltip
                                    title={t("teacherClasses.online")}
                                    PopperProps={themes.TooltipPopperProps}
                                    placement="top"
                                >
                                    <Box sx={themes.PlaceIconContainer}>
                                        <Distant />
                                    </Box>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={props.classItem.place === "tutor" ? t("teacherClasses.atTutor") : t("teacherClasses.atStudent")}
                                    PopperProps={themes.TooltipPopperProps}
                                    placement="top"
                                >
                                    <Box sx={themes.PlaceIconContainer}>
                                        <Home />
                                    </Box>
                                </Tooltip>
                            )}
                            <Box sx={themes.SubjectAimBlock(medias)}>
                                <Typography sx={textThemes.Body2Black}>{props.classItem.subject}</Typography>
                                <Typography sx={textThemes.Caption2Grey}>{props.classItem.aim}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={themes.RightPanel(medias)}>
                        <SButton
                            variant="icon"
                            onClick={showDialog}
                        >
                            <Message />
                        </SButton>
                        <ClassMoreMenu lesson={props.classItem} />
                    </Box>
                </Box>
            ) : (
                <>{MobileVersion}</>
            )}
        </>
    );
});

export default memo(UpcomingClassCard);
