/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require("google-protobuf");

var goog = jspb;

var global = Function("return this")();

var google_protobuf_timestamp_pb = require("google-protobuf/google/protobuf/timestamp_pb.js");

goog.exportSymbol("proto.pb.AddPersonalDataForAuthRequest", null, global);
goog.exportSymbol("proto.pb.AddPersonalDataForAuthResponse", null, global);
goog.exportSymbol("proto.pb.AddTutorQuestionaryRequest", null, global);
goog.exportSymbol("proto.pb.AddTutorQuestionaryResponse", null, global);
goog.exportSymbol("proto.pb.Certificate", null, global);
goog.exportSymbol("proto.pb.CertificateForCatalogExtendedCard", null, global);
goog.exportSymbol("proto.pb.Condition", null, global);
goog.exportSymbol("proto.pb.DeleteProfilePhotoRequest", null, global);
goog.exportSymbol("proto.pb.DeleteProfilePhotoResponse", null, global);
goog.exportSymbol("proto.pb.DeleteTutorQuestionaryRequest", null, global);
goog.exportSymbol("proto.pb.DeleteTutorQuestionaryResponse", null, global);
goog.exportSymbol("proto.pb.Document", null, global);
goog.exportSymbol("proto.pb.Education", null, global);
goog.exportSymbol("proto.pb.EducationForCatalogExtentedCard", null, global);
goog.exportSymbol("proto.pb.ExperienceInfo", null, global);
goog.exportSymbol("proto.pb.ExperienceInfoForCatalogExtendedCard", null, global);
goog.exportSymbol("proto.pb.ExternalProfile", null, global);
goog.exportSymbol("proto.pb.GetPersonalDataForAuthRequest", null, global);
goog.exportSymbol("proto.pb.GetPersonalDataForAuthResponse", null, global);
goog.exportSymbol("proto.pb.GetPersonalDataRequest", null, global);
goog.exportSymbol("proto.pb.GetPersonalDataResponse", null, global);
goog.exportSymbol("proto.pb.GetTutorInfoForCatalogExtendedCardRequest", null, global);
goog.exportSymbol("proto.pb.GetTutorInfoForCatalogExtendedCardResponse", null, global);
goog.exportSymbol("proto.pb.GetTutorQuestionaryRequest", null, global);
goog.exportSymbol("proto.pb.GetTutorQuestionaryResponse", null, global);
goog.exportSymbol("proto.pb.Place", null, global);
goog.exportSymbol("proto.pb.SetLastLogonTimeForAuthRequest", null, global);
goog.exportSymbol("proto.pb.SetLastLogonTimeForAuthResponse", null, global);
goog.exportSymbol("proto.pb.StudentsNotificationsSettings", null, global);
goog.exportSymbol("proto.pb.Subject", null, global);
goog.exportSymbol("proto.pb.TutorsNotificationsSettings", null, global);
goog.exportSymbol("proto.pb.UpdatePersonalDataRequest", null, global);
goog.exportSymbol("proto.pb.UpdatePersonalDataResponse", null, global);
goog.exportSymbol("proto.pb.UpdateProfilePhotoRequest", null, global);
goog.exportSymbol("proto.pb.UpdateProfilePhotoResponse", null, global);
goog.exportSymbol("proto.pb.WeekDayTimetable", null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Condition = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Condition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.Condition.displayName = "proto.pb.Condition";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Condition.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Condition.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Condition} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Condition.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                type: jspb.Message.getFieldWithDefault(msg, 1, ""),
                description: jspb.Message.getFieldWithDefault(msg, 2, ""),
                price: jspb.Message.getFieldWithDefault(msg, 3, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Condition}
 */
proto.pb.Condition.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Condition();

    return proto.pb.Condition.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Condition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Condition}
 */
proto.pb.Condition.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setType(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setDescription(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setPrice(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Condition.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Condition.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Condition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Condition.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getType();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getDescription();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getPrice();
    if (f !== 0) {
        writer.writeInt32(3, f);
    }
};

/**
 * optional string type = 1;
 * @return {string}
 */
proto.pb.Condition.prototype.getType = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.Condition.prototype.setType = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string description = 2;
 * @return {string}
 */
proto.pb.Condition.prototype.getDescription = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.pb.Condition.prototype.setDescription = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional int32 price = 3;
 * @return {number}
 */
proto.pb.Condition.prototype.getPrice = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/** @param {number} value */
proto.pb.Condition.prototype.setPrice = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Subject = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.Subject.repeatedFields_, null);
};

goog.inherits(proto.pb.Subject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.Subject.displayName = "proto.pb.Subject";
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.Subject.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Subject.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Subject.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Subject} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Subject.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                subjectName: jspb.Message.getFieldWithDefault(msg, 1, ""),
                conditionsList: jspb.Message.toObjectList(msg.getConditionsList(), proto.pb.Condition.toObject, includeInstance),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Subject}
 */
proto.pb.Subject.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Subject();

    return proto.pb.Subject.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Subject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Subject}
 */
proto.pb.Subject.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setSubjectName(value);
                break;
            case 2:
                var value = new proto.pb.Condition();

                reader.readMessage(value, proto.pb.Condition.deserializeBinaryFromReader);
                msg.addConditions(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Subject.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Subject.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Subject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Subject.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getSubjectName();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getConditionsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(2, f, proto.pb.Condition.serializeBinaryToWriter);
    }
};

/**
 * optional string subject_name = 1;
 * @return {string}
 */
proto.pb.Subject.prototype.getSubjectName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.Subject.prototype.setSubjectName = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * repeated Condition conditions = 2;
 * @return {!Array<!proto.pb.Condition>}
 */
proto.pb.Subject.prototype.getConditionsList = function () {
    return /** @type{!Array<!proto.pb.Condition>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.Condition, 2));
};

/** @param {!Array<!proto.pb.Condition>} value */
proto.pb.Subject.prototype.setConditionsList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.pb.Condition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.Condition}
 */
proto.pb.Subject.prototype.addConditions = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pb.Condition, opt_index);
};

proto.pb.Subject.prototype.clearConditionsList = function () {
    this.setConditionsList([]);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Place = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.Place.repeatedFields_, null);
};

goog.inherits(proto.pb.Place, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.Place.displayName = "proto.pb.Place";
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.Place.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Place.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Place.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Place} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Place.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                city: jspb.Message.getFieldWithDefault(msg, 1, ""),
                locationsList: jspb.Message.getRepeatedField(msg, 2),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Place}
 */
proto.pb.Place.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Place();

    return proto.pb.Place.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Place} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Place}
 */
proto.pb.Place.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setCity(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.addLocations(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Place.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Place.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Place} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Place.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getCity();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getLocationsList();
    if (f.length > 0) {
        writer.writeRepeatedString(2, f);
    }
};

/**
 * optional string city = 1;
 * @return {string}
 */
proto.pb.Place.prototype.getCity = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.Place.prototype.setCity = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * repeated string locations = 2;
 * @return {!Array<string>}
 */
proto.pb.Place.prototype.getLocationsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};

/** @param {!Array<string>} value */
proto.pb.Place.prototype.setLocationsList = function (value) {
    jspb.Message.setField(this, 2, value || []);
};

/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.pb.Place.prototype.addLocations = function (value, opt_index) {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};

proto.pb.Place.prototype.clearLocationsList = function () {
    this.setLocationsList([]);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Document = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.Document, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.Document.displayName = "proto.pb.Document";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Document.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Document.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Document} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Document.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                name: jspb.Message.getFieldWithDefault(msg, 1, ""),
                isPublic: jspb.Message.getFieldWithDefault(msg, 2, false),
                data: msg.getData_asB64(),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Document}
 */
proto.pb.Document.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Document();

    return proto.pb.Document.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Document} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Document}
 */
proto.pb.Document.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setName(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setIsPublic(value);
                break;
            case 3:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());

                msg.setData(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Document.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Document.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Document} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Document.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getName();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getIsPublic();
    if (f) {
        writer.writeBool(2, f);
    }

    f = message.getData_asU8();
    if (f.length > 0) {
        writer.writeBytes(3, f);
    }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.pb.Document.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.Document.prototype.setName = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional bool is_public = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.Document.prototype.getIsPublic = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};

/** @param {boolean} value */
proto.pb.Document.prototype.setIsPublic = function (value) {
    jspb.Message.setProto3BooleanField(this, 2, value);
};

/**
 * optional bytes data = 3;
 * @return {string}
 */
proto.pb.Document.prototype.getData = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * optional bytes data = 3;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.pb.Document.prototype.getData_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getData()));
};

/**
 * optional bytes data = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.pb.Document.prototype.getData_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getData()));
};

/** @param {!(string|Uint8Array)} value */
proto.pb.Document.prototype.setData = function (value) {
    jspb.Message.setProto3BytesField(this, 3, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Education = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.Education.repeatedFields_, null);
};

goog.inherits(proto.pb.Education, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.Education.displayName = "proto.pb.Education";
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.Education.repeatedFields_ = [7];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Education.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Education.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Education} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Education.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                university: jspb.Message.getFieldWithDefault(msg, 1, ""),
                faculty: jspb.Message.getFieldWithDefault(msg, 2, ""),
                speciality: jspb.Message.getFieldWithDefault(msg, 3, ""),
                startYear: jspb.Message.getFieldWithDefault(msg, 4, 0),
                studyNowadays: jspb.Message.getFieldWithDefault(msg, 5, false),
                finishYear: jspb.Message.getFieldWithDefault(msg, 6, 0),
                confirmatoryDocumentsList: jspb.Message.toObjectList(msg.getConfirmatoryDocumentsList(), proto.pb.Document.toObject, includeInstance),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Education}
 */
proto.pb.Education.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Education();

    return proto.pb.Education.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Education} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Education}
 */
proto.pb.Education.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setUniversity(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setFaculty(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());

                msg.setSpeciality(value);
                break;
            case 4:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setStartYear(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setStudyNowadays(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setFinishYear(value);
                break;
            case 7:
                var value = new proto.pb.Document();

                reader.readMessage(value, proto.pb.Document.deserializeBinaryFromReader);
                msg.addConfirmatoryDocuments(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Education.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Education.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Education} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Education.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getUniversity();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getFaculty();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getSpeciality();
    if (f.length > 0) {
        writer.writeString(3, f);
    }

    f = message.getStartYear();
    if (f !== 0) {
        writer.writeInt32(4, f);
    }

    f = message.getStudyNowadays();
    if (f) {
        writer.writeBool(5, f);
    }

    f = message.getFinishYear();
    if (f !== 0) {
        writer.writeInt32(6, f);
    }

    f = message.getConfirmatoryDocumentsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(7, f, proto.pb.Document.serializeBinaryToWriter);
    }
};

/**
 * optional string university = 1;
 * @return {string}
 */
proto.pb.Education.prototype.getUniversity = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.Education.prototype.setUniversity = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string faculty = 2;
 * @return {string}
 */
proto.pb.Education.prototype.getFaculty = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.pb.Education.prototype.setFaculty = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string speciality = 3;
 * @return {string}
 */
proto.pb.Education.prototype.getSpeciality = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/** @param {string} value */
proto.pb.Education.prototype.setSpeciality = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional int32 start_year = 4;
 * @return {number}
 */
proto.pb.Education.prototype.getStartYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/** @param {number} value */
proto.pb.Education.prototype.setStartYear = function (value) {
    jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional bool study_nowadays = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.Education.prototype.getStudyNowadays = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};

/** @param {boolean} value */
proto.pb.Education.prototype.setStudyNowadays = function (value) {
    jspb.Message.setProto3BooleanField(this, 5, value);
};

/**
 * optional int32 finish_year = 6;
 * @return {number}
 */
proto.pb.Education.prototype.getFinishYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/** @param {number} value */
proto.pb.Education.prototype.setFinishYear = function (value) {
    jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * repeated Document confirmatory_documents = 7;
 * @return {!Array<!proto.pb.Document>}
 */
proto.pb.Education.prototype.getConfirmatoryDocumentsList = function () {
    return /** @type{!Array<!proto.pb.Document>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.Document, 7));
};

/** @param {!Array<!proto.pb.Document>} value */
proto.pb.Education.prototype.setConfirmatoryDocumentsList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 7, value);
};

/**
 * @param {!proto.pb.Document=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.Document}
 */
proto.pb.Education.prototype.addConfirmatoryDocuments = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.pb.Document, opt_index);
};

proto.pb.Education.prototype.clearConfirmatoryDocumentsList = function () {
    this.setConfirmatoryDocumentsList([]);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.EducationForCatalogExtentedCard = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.EducationForCatalogExtentedCard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.EducationForCatalogExtentedCard.displayName = "proto.pb.EducationForCatalogExtentedCard";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.EducationForCatalogExtentedCard.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.EducationForCatalogExtentedCard.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.EducationForCatalogExtentedCard} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.EducationForCatalogExtentedCard.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                university: jspb.Message.getFieldWithDefault(msg, 1, ""),
                faculty: jspb.Message.getFieldWithDefault(msg, 2, ""),
                speciality: jspb.Message.getFieldWithDefault(msg, 3, ""),
                studyNowadays: jspb.Message.getFieldWithDefault(msg, 4, false),
                finishYear: jspb.Message.getFieldWithDefault(msg, 5, 0),
                isConfirmedWithDocuments: jspb.Message.getFieldWithDefault(msg, 6, false),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.EducationForCatalogExtentedCard}
 */
proto.pb.EducationForCatalogExtentedCard.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.EducationForCatalogExtentedCard();

    return proto.pb.EducationForCatalogExtentedCard.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.EducationForCatalogExtentedCard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.EducationForCatalogExtentedCard}
 */
proto.pb.EducationForCatalogExtentedCard.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setUniversity(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setFaculty(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());

                msg.setSpeciality(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setStudyNowadays(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setFinishYear(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setIsConfirmedWithDocuments(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.EducationForCatalogExtentedCard.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.EducationForCatalogExtentedCard.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.EducationForCatalogExtentedCard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.EducationForCatalogExtentedCard.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getUniversity();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getFaculty();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getSpeciality();
    if (f.length > 0) {
        writer.writeString(3, f);
    }

    f = message.getStudyNowadays();
    if (f) {
        writer.writeBool(4, f);
    }

    f = message.getFinishYear();
    if (f !== 0) {
        writer.writeInt32(5, f);
    }

    f = message.getIsConfirmedWithDocuments();
    if (f) {
        writer.writeBool(6, f);
    }
};

/**
 * optional string university = 1;
 * @return {string}
 */
proto.pb.EducationForCatalogExtentedCard.prototype.getUniversity = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.EducationForCatalogExtentedCard.prototype.setUniversity = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string faculty = 2;
 * @return {string}
 */
proto.pb.EducationForCatalogExtentedCard.prototype.getFaculty = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.pb.EducationForCatalogExtentedCard.prototype.setFaculty = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string speciality = 3;
 * @return {string}
 */
proto.pb.EducationForCatalogExtentedCard.prototype.getSpeciality = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/** @param {string} value */
proto.pb.EducationForCatalogExtentedCard.prototype.setSpeciality = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional bool study_nowadays = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.EducationForCatalogExtentedCard.prototype.getStudyNowadays = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};

/** @param {boolean} value */
proto.pb.EducationForCatalogExtentedCard.prototype.setStudyNowadays = function (value) {
    jspb.Message.setProto3BooleanField(this, 4, value);
};

/**
 * optional int32 finish_year = 5;
 * @return {number}
 */
proto.pb.EducationForCatalogExtentedCard.prototype.getFinishYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/** @param {number} value */
proto.pb.EducationForCatalogExtentedCard.prototype.setFinishYear = function (value) {
    jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional bool is_confirmed_with_documents = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.EducationForCatalogExtentedCard.prototype.getIsConfirmedWithDocuments = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};

/** @param {boolean} value */
proto.pb.EducationForCatalogExtentedCard.prototype.setIsConfirmedWithDocuments = function (value) {
    jspb.Message.setProto3BooleanField(this, 6, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.ExperienceInfo = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.ExperienceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.ExperienceInfo.displayName = "proto.pb.ExperienceInfo";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.ExperienceInfo.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.ExperienceInfo.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.ExperienceInfo} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.ExperienceInfo.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                organization: jspb.Message.getFieldWithDefault(msg, 1, ""),
                post: jspb.Message.getFieldWithDefault(msg, 2, ""),
                startYear: jspb.Message.getFieldWithDefault(msg, 3, 0),
                workNowadays: jspb.Message.getFieldWithDefault(msg, 4, false),
                finishYear: jspb.Message.getFieldWithDefault(msg, 5, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.ExperienceInfo}
 */
proto.pb.ExperienceInfo.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.ExperienceInfo();

    return proto.pb.ExperienceInfo.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.ExperienceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.ExperienceInfo}
 */
proto.pb.ExperienceInfo.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setOrganization(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setPost(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setStartYear(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setWorkNowadays(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setFinishYear(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.ExperienceInfo.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.ExperienceInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.ExperienceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.ExperienceInfo.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getOrganization();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getPost();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getStartYear();
    if (f !== 0) {
        writer.writeInt32(3, f);
    }

    f = message.getWorkNowadays();
    if (f) {
        writer.writeBool(4, f);
    }

    f = message.getFinishYear();
    if (f !== 0) {
        writer.writeInt32(5, f);
    }
};

/**
 * optional string organization = 1;
 * @return {string}
 */
proto.pb.ExperienceInfo.prototype.getOrganization = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.ExperienceInfo.prototype.setOrganization = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string post = 2;
 * @return {string}
 */
proto.pb.ExperienceInfo.prototype.getPost = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.pb.ExperienceInfo.prototype.setPost = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional int32 start_year = 3;
 * @return {number}
 */
proto.pb.ExperienceInfo.prototype.getStartYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/** @param {number} value */
proto.pb.ExperienceInfo.prototype.setStartYear = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional bool work_nowadays = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.ExperienceInfo.prototype.getWorkNowadays = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};

/** @param {boolean} value */
proto.pb.ExperienceInfo.prototype.setWorkNowadays = function (value) {
    jspb.Message.setProto3BooleanField(this, 4, value);
};

/**
 * optional int32 finish_year = 5;
 * @return {number}
 */
proto.pb.ExperienceInfo.prototype.getFinishYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/** @param {number} value */
proto.pb.ExperienceInfo.prototype.setFinishYear = function (value) {
    jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.ExperienceInfoForCatalogExtendedCard = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.ExperienceInfoForCatalogExtendedCard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.ExperienceInfoForCatalogExtendedCard.displayName = "proto.pb.ExperienceInfoForCatalogExtendedCard";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.ExperienceInfoForCatalogExtendedCard.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.ExperienceInfoForCatalogExtendedCard.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.ExperienceInfoForCatalogExtendedCard} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.ExperienceInfoForCatalogExtendedCard.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                organization: jspb.Message.getFieldWithDefault(msg, 1, ""),
                post: jspb.Message.getFieldWithDefault(msg, 2, ""),
                startYear: jspb.Message.getFieldWithDefault(msg, 3, 0),
                workNowadays: jspb.Message.getFieldWithDefault(msg, 4, false),
                finishYear: jspb.Message.getFieldWithDefault(msg, 5, 0),
                totalWorkingYears: jspb.Message.getFieldWithDefault(msg, 6, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.ExperienceInfoForCatalogExtendedCard}
 */
proto.pb.ExperienceInfoForCatalogExtendedCard.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.ExperienceInfoForCatalogExtendedCard();

    return proto.pb.ExperienceInfoForCatalogExtendedCard.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.ExperienceInfoForCatalogExtendedCard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.ExperienceInfoForCatalogExtendedCard}
 */
proto.pb.ExperienceInfoForCatalogExtendedCard.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setOrganization(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setPost(value);
                break;
            case 3:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setStartYear(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setWorkNowadays(value);
                break;
            case 5:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setFinishYear(value);
                break;
            case 6:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setTotalWorkingYears(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.ExperienceInfoForCatalogExtendedCard.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.ExperienceInfoForCatalogExtendedCard.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.ExperienceInfoForCatalogExtendedCard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.ExperienceInfoForCatalogExtendedCard.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getOrganization();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getPost();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getStartYear();
    if (f !== 0) {
        writer.writeInt32(3, f);
    }

    f = message.getWorkNowadays();
    if (f) {
        writer.writeBool(4, f);
    }

    f = message.getFinishYear();
    if (f !== 0) {
        writer.writeInt32(5, f);
    }

    f = message.getTotalWorkingYears();
    if (f !== 0) {
        writer.writeInt32(6, f);
    }
};

/**
 * optional string organization = 1;
 * @return {string}
 */
proto.pb.ExperienceInfoForCatalogExtendedCard.prototype.getOrganization = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.ExperienceInfoForCatalogExtendedCard.prototype.setOrganization = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string post = 2;
 * @return {string}
 */
proto.pb.ExperienceInfoForCatalogExtendedCard.prototype.getPost = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.pb.ExperienceInfoForCatalogExtendedCard.prototype.setPost = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional int32 start_year = 3;
 * @return {number}
 */
proto.pb.ExperienceInfoForCatalogExtendedCard.prototype.getStartYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/** @param {number} value */
proto.pb.ExperienceInfoForCatalogExtendedCard.prototype.setStartYear = function (value) {
    jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional bool work_nowadays = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.ExperienceInfoForCatalogExtendedCard.prototype.getWorkNowadays = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};

/** @param {boolean} value */
proto.pb.ExperienceInfoForCatalogExtendedCard.prototype.setWorkNowadays = function (value) {
    jspb.Message.setProto3BooleanField(this, 4, value);
};

/**
 * optional int32 finish_year = 5;
 * @return {number}
 */
proto.pb.ExperienceInfoForCatalogExtendedCard.prototype.getFinishYear = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/** @param {number} value */
proto.pb.ExperienceInfoForCatalogExtendedCard.prototype.setFinishYear = function (value) {
    jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional int32 total_working_years = 6;
 * @return {number}
 */
proto.pb.ExperienceInfoForCatalogExtendedCard.prototype.getTotalWorkingYears = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/** @param {number} value */
proto.pb.ExperienceInfoForCatalogExtendedCard.prototype.setTotalWorkingYears = function (value) {
    jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.Certificate = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.Certificate.repeatedFields_, null);
};

goog.inherits(proto.pb.Certificate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.Certificate.displayName = "proto.pb.Certificate";
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.Certificate.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.Certificate.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.Certificate.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.Certificate} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.Certificate.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                certificateName: jspb.Message.getFieldWithDefault(msg, 1, ""),
                yearOfGraduating: jspb.Message.getFieldWithDefault(msg, 2, 0),
                confirmatoryDocumentsList: jspb.Message.toObjectList(msg.getConfirmatoryDocumentsList(), proto.pb.Document.toObject, includeInstance),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.Certificate}
 */
proto.pb.Certificate.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.Certificate();

    return proto.pb.Certificate.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.Certificate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.Certificate}
 */
proto.pb.Certificate.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setCertificateName(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setYearOfGraduating(value);
                break;
            case 3:
                var value = new proto.pb.Document();

                reader.readMessage(value, proto.pb.Document.deserializeBinaryFromReader);
                msg.addConfirmatoryDocuments(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.Certificate.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.Certificate.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.Certificate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.Certificate.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getCertificateName();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getYearOfGraduating();
    if (f !== 0) {
        writer.writeInt32(2, f);
    }

    f = message.getConfirmatoryDocumentsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(3, f, proto.pb.Document.serializeBinaryToWriter);
    }
};

/**
 * optional string certificate_name = 1;
 * @return {string}
 */
proto.pb.Certificate.prototype.getCertificateName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.Certificate.prototype.setCertificateName = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional int32 year_of_graduating = 2;
 * @return {number}
 */
proto.pb.Certificate.prototype.getYearOfGraduating = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/** @param {number} value */
proto.pb.Certificate.prototype.setYearOfGraduating = function (value) {
    jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * repeated Document confirmatory_documents = 3;
 * @return {!Array<!proto.pb.Document>}
 */
proto.pb.Certificate.prototype.getConfirmatoryDocumentsList = function () {
    return /** @type{!Array<!proto.pb.Document>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.Document, 3));
};

/** @param {!Array<!proto.pb.Document>} value */
proto.pb.Certificate.prototype.setConfirmatoryDocumentsList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 3, value);
};

/**
 * @param {!proto.pb.Document=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.Document}
 */
proto.pb.Certificate.prototype.addConfirmatoryDocuments = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.pb.Document, opt_index);
};

proto.pb.Certificate.prototype.clearConfirmatoryDocumentsList = function () {
    this.setConfirmatoryDocumentsList([]);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.CertificateForCatalogExtendedCard = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.CertificateForCatalogExtendedCard.repeatedFields_, null);
};

goog.inherits(proto.pb.CertificateForCatalogExtendedCard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.CertificateForCatalogExtendedCard.displayName = "proto.pb.CertificateForCatalogExtendedCard";
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.CertificateForCatalogExtendedCard.repeatedFields_ = [4];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.CertificateForCatalogExtendedCard.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.CertificateForCatalogExtendedCard.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.CertificateForCatalogExtendedCard} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.CertificateForCatalogExtendedCard.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                certificateName: jspb.Message.getFieldWithDefault(msg, 1, ""),
                yearOfGraduating: jspb.Message.getFieldWithDefault(msg, 2, 0),
                isConfirmedWithDocuments: jspb.Message.getFieldWithDefault(msg, 3, false),
                documentsList: jspb.Message.toObjectList(msg.getDocumentsList(), proto.pb.Document.toObject, includeInstance),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.CertificateForCatalogExtendedCard}
 */
proto.pb.CertificateForCatalogExtendedCard.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.CertificateForCatalogExtendedCard();

    return proto.pb.CertificateForCatalogExtendedCard.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.CertificateForCatalogExtendedCard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.CertificateForCatalogExtendedCard}
 */
proto.pb.CertificateForCatalogExtendedCard.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setCertificateName(value);
                break;
            case 2:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setYearOfGraduating(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setIsConfirmedWithDocuments(value);
                break;
            case 4:
                var value = new proto.pb.Document();

                reader.readMessage(value, proto.pb.Document.deserializeBinaryFromReader);
                msg.addDocuments(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.CertificateForCatalogExtendedCard.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.CertificateForCatalogExtendedCard.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.CertificateForCatalogExtendedCard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.CertificateForCatalogExtendedCard.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getCertificateName();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getYearOfGraduating();
    if (f !== 0) {
        writer.writeInt32(2, f);
    }

    f = message.getIsConfirmedWithDocuments();
    if (f) {
        writer.writeBool(3, f);
    }

    f = message.getDocumentsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(4, f, proto.pb.Document.serializeBinaryToWriter);
    }
};

/**
 * optional string certificate_name = 1;
 * @return {string}
 */
proto.pb.CertificateForCatalogExtendedCard.prototype.getCertificateName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.CertificateForCatalogExtendedCard.prototype.setCertificateName = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional int32 year_of_graduating = 2;
 * @return {number}
 */
proto.pb.CertificateForCatalogExtendedCard.prototype.getYearOfGraduating = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/** @param {number} value */
proto.pb.CertificateForCatalogExtendedCard.prototype.setYearOfGraduating = function (value) {
    jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional bool is_confirmed_with_documents = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.CertificateForCatalogExtendedCard.prototype.getIsConfirmedWithDocuments = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};

/** @param {boolean} value */
proto.pb.CertificateForCatalogExtendedCard.prototype.setIsConfirmedWithDocuments = function (value) {
    jspb.Message.setProto3BooleanField(this, 3, value);
};

/**
 * repeated Document documents = 4;
 * @return {!Array<!proto.pb.Document>}
 */
proto.pb.CertificateForCatalogExtendedCard.prototype.getDocumentsList = function () {
    return /** @type{!Array<!proto.pb.Document>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.Document, 4));
};

/** @param {!Array<!proto.pb.Document>} value */
proto.pb.CertificateForCatalogExtendedCard.prototype.setDocumentsList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 4, value);
};

/**
 * @param {!proto.pb.Document=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.Document}
 */
proto.pb.CertificateForCatalogExtendedCard.prototype.addDocuments = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.pb.Document, opt_index);
};

proto.pb.CertificateForCatalogExtendedCard.prototype.clearDocumentsList = function () {
    this.setDocumentsList([]);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.WeekDayTimetable = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.WeekDayTimetable.repeatedFields_, null);
};

goog.inherits(proto.pb.WeekDayTimetable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.WeekDayTimetable.displayName = "proto.pb.WeekDayTimetable";
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.WeekDayTimetable.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.WeekDayTimetable.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.WeekDayTimetable.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.WeekDayTimetable} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.WeekDayTimetable.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                weekDayName: jspb.Message.getFieldWithDefault(msg, 1, ""),
                hoursList: jspb.Message.getRepeatedField(msg, 2),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.WeekDayTimetable}
 */
proto.pb.WeekDayTimetable.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.WeekDayTimetable();

    return proto.pb.WeekDayTimetable.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.WeekDayTimetable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.WeekDayTimetable}
 */
proto.pb.WeekDayTimetable.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setWeekDayName(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.addHours(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.WeekDayTimetable.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.WeekDayTimetable.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.WeekDayTimetable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.WeekDayTimetable.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getWeekDayName();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getHoursList();
    if (f.length > 0) {
        writer.writeRepeatedString(2, f);
    }
};

/**
 * optional string week_day_name = 1;
 * @return {string}
 */
proto.pb.WeekDayTimetable.prototype.getWeekDayName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.WeekDayTimetable.prototype.setWeekDayName = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * repeated string hours = 2;
 * @return {!Array<string>}
 */
proto.pb.WeekDayTimetable.prototype.getHoursList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};

/** @param {!Array<string>} value */
proto.pb.WeekDayTimetable.prototype.setHoursList = function (value) {
    jspb.Message.setField(this, 2, value || []);
};

/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.pb.WeekDayTimetable.prototype.addHours = function (value, opt_index) {
    jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};

proto.pb.WeekDayTimetable.prototype.clearHoursList = function () {
    this.setHoursList([]);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.ExternalProfile = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.ExternalProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.ExternalProfile.displayName = "proto.pb.ExternalProfile";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.ExternalProfile.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.ExternalProfile.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.ExternalProfile} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.ExternalProfile.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                websiteName: jspb.Message.getFieldWithDefault(msg, 1, ""),
                url: jspb.Message.getFieldWithDefault(msg, 2, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.ExternalProfile}
 */
proto.pb.ExternalProfile.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.ExternalProfile();

    return proto.pb.ExternalProfile.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.ExternalProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.ExternalProfile}
 */
proto.pb.ExternalProfile.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setWebsiteName(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setUrl(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.ExternalProfile.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.ExternalProfile.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.ExternalProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.ExternalProfile.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getWebsiteName();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getUrl();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};

/**
 * optional string website_name = 1;
 * @return {string}
 */
proto.pb.ExternalProfile.prototype.getWebsiteName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.ExternalProfile.prototype.setWebsiteName = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string url = 2;
 * @return {string}
 */
proto.pb.ExternalProfile.prototype.getUrl = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.pb.ExternalProfile.prototype.setUrl = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.StudentsNotificationsSettings = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.StudentsNotificationsSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.StudentsNotificationsSettings.displayName = "proto.pb.StudentsNotificationsSettings";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.StudentsNotificationsSettings.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.StudentsNotificationsSettings.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.StudentsNotificationsSettings} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.StudentsNotificationsSettings.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                timetableNotify: jspb.Message.getFieldWithDefault(msg, 1, false),
                messagesFromTutorsNotify: jspb.Message.getFieldWithDefault(msg, 2, false),
                paymentsNotify: jspb.Message.getFieldWithDefault(msg, 3, false),
                updatesTipsSuggestionsNotify: jspb.Message.getFieldWithDefault(msg, 4, false),
                connectFromServiceEnable: jspb.Message.getFieldWithDefault(msg, 5, false),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.StudentsNotificationsSettings}
 */
proto.pb.StudentsNotificationsSettings.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.StudentsNotificationsSettings();

    return proto.pb.StudentsNotificationsSettings.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.StudentsNotificationsSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.StudentsNotificationsSettings}
 */
proto.pb.StudentsNotificationsSettings.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setTimetableNotify(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setMessagesFromTutorsNotify(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setPaymentsNotify(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setUpdatesTipsSuggestionsNotify(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setConnectFromServiceEnable(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.StudentsNotificationsSettings.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.StudentsNotificationsSettings.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.StudentsNotificationsSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.StudentsNotificationsSettings.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getTimetableNotify();
    if (f) {
        writer.writeBool(1, f);
    }

    f = message.getMessagesFromTutorsNotify();
    if (f) {
        writer.writeBool(2, f);
    }

    f = message.getPaymentsNotify();
    if (f) {
        writer.writeBool(3, f);
    }

    f = message.getUpdatesTipsSuggestionsNotify();
    if (f) {
        writer.writeBool(4, f);
    }

    f = message.getConnectFromServiceEnable();
    if (f) {
        writer.writeBool(5, f);
    }
};

/**
 * optional bool timetable_notify = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.StudentsNotificationsSettings.prototype.getTimetableNotify = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};

/** @param {boolean} value */
proto.pb.StudentsNotificationsSettings.prototype.setTimetableNotify = function (value) {
    jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional bool messages_from_tutors_notify = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.StudentsNotificationsSettings.prototype.getMessagesFromTutorsNotify = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};

/** @param {boolean} value */
proto.pb.StudentsNotificationsSettings.prototype.setMessagesFromTutorsNotify = function (value) {
    jspb.Message.setProto3BooleanField(this, 2, value);
};

/**
 * optional bool payments_notify = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.StudentsNotificationsSettings.prototype.getPaymentsNotify = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};

/** @param {boolean} value */
proto.pb.StudentsNotificationsSettings.prototype.setPaymentsNotify = function (value) {
    jspb.Message.setProto3BooleanField(this, 3, value);
};

/**
 * optional bool updates_tips_suggestions_notify = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.StudentsNotificationsSettings.prototype.getUpdatesTipsSuggestionsNotify = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};

/** @param {boolean} value */
proto.pb.StudentsNotificationsSettings.prototype.setUpdatesTipsSuggestionsNotify = function (value) {
    jspb.Message.setProto3BooleanField(this, 4, value);
};

/**
 * optional bool connect_from_service_enable = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.StudentsNotificationsSettings.prototype.getConnectFromServiceEnable = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};

/** @param {boolean} value */
proto.pb.StudentsNotificationsSettings.prototype.setConnectFromServiceEnable = function (value) {
    jspb.Message.setProto3BooleanField(this, 5, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.TutorsNotificationsSettings = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.TutorsNotificationsSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.TutorsNotificationsSettings.displayName = "proto.pb.TutorsNotificationsSettings";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.TutorsNotificationsSettings.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.TutorsNotificationsSettings.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.TutorsNotificationsSettings} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.TutorsNotificationsSettings.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                timetableNotify: jspb.Message.getFieldWithDefault(msg, 1, false),
                messagesFromStudentsNotify: jspb.Message.getFieldWithDefault(msg, 2, false),
                activeOrdersStatusesNotify: jspb.Message.getFieldWithDefault(msg, 3, false),
                responsesAndNewOrdersNotify: jspb.Message.getFieldWithDefault(msg, 4, false),
                paymentsNotify: jspb.Message.getFieldWithDefault(msg, 5, false),
                updatesTipsSuggestionsNotify: jspb.Message.getFieldWithDefault(msg, 6, false),
                connectFromServiceEnable: jspb.Message.getFieldWithDefault(msg, 7, false),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.TutorsNotificationsSettings}
 */
proto.pb.TutorsNotificationsSettings.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.TutorsNotificationsSettings();

    return proto.pb.TutorsNotificationsSettings.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.TutorsNotificationsSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.TutorsNotificationsSettings}
 */
proto.pb.TutorsNotificationsSettings.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setTimetableNotify(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setMessagesFromStudentsNotify(value);
                break;
            case 3:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setActiveOrdersStatusesNotify(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setResponsesAndNewOrdersNotify(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setPaymentsNotify(value);
                break;
            case 6:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setUpdatesTipsSuggestionsNotify(value);
                break;
            case 7:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setConnectFromServiceEnable(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.TutorsNotificationsSettings.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.TutorsNotificationsSettings.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.TutorsNotificationsSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.TutorsNotificationsSettings.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getTimetableNotify();
    if (f) {
        writer.writeBool(1, f);
    }

    f = message.getMessagesFromStudentsNotify();
    if (f) {
        writer.writeBool(2, f);
    }

    f = message.getActiveOrdersStatusesNotify();
    if (f) {
        writer.writeBool(3, f);
    }

    f = message.getResponsesAndNewOrdersNotify();
    if (f) {
        writer.writeBool(4, f);
    }

    f = message.getPaymentsNotify();
    if (f) {
        writer.writeBool(5, f);
    }

    f = message.getUpdatesTipsSuggestionsNotify();
    if (f) {
        writer.writeBool(6, f);
    }

    f = message.getConnectFromServiceEnable();
    if (f) {
        writer.writeBool(7, f);
    }
};

/**
 * optional bool timetable_notify = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.TutorsNotificationsSettings.prototype.getTimetableNotify = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};

/** @param {boolean} value */
proto.pb.TutorsNotificationsSettings.prototype.setTimetableNotify = function (value) {
    jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional bool messages_from_students_notify = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.TutorsNotificationsSettings.prototype.getMessagesFromStudentsNotify = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};

/** @param {boolean} value */
proto.pb.TutorsNotificationsSettings.prototype.setMessagesFromStudentsNotify = function (value) {
    jspb.Message.setProto3BooleanField(this, 2, value);
};

/**
 * optional bool active_orders_statuses_notify = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.TutorsNotificationsSettings.prototype.getActiveOrdersStatusesNotify = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};

/** @param {boolean} value */
proto.pb.TutorsNotificationsSettings.prototype.setActiveOrdersStatusesNotify = function (value) {
    jspb.Message.setProto3BooleanField(this, 3, value);
};

/**
 * optional bool responses_and_new_orders_notify = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.TutorsNotificationsSettings.prototype.getResponsesAndNewOrdersNotify = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};

/** @param {boolean} value */
proto.pb.TutorsNotificationsSettings.prototype.setResponsesAndNewOrdersNotify = function (value) {
    jspb.Message.setProto3BooleanField(this, 4, value);
};

/**
 * optional bool payments_notify = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.TutorsNotificationsSettings.prototype.getPaymentsNotify = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};

/** @param {boolean} value */
proto.pb.TutorsNotificationsSettings.prototype.setPaymentsNotify = function (value) {
    jspb.Message.setProto3BooleanField(this, 5, value);
};

/**
 * optional bool updates_tips_suggestions_notify = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.TutorsNotificationsSettings.prototype.getUpdatesTipsSuggestionsNotify = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};

/** @param {boolean} value */
proto.pb.TutorsNotificationsSettings.prototype.setUpdatesTipsSuggestionsNotify = function (value) {
    jspb.Message.setProto3BooleanField(this, 6, value);
};

/**
 * optional bool connect_from_service_enable = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.TutorsNotificationsSettings.prototype.getConnectFromServiceEnable = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};

/** @param {boolean} value */
proto.pb.TutorsNotificationsSettings.prototype.setConnectFromServiceEnable = function (value) {
    jspb.Message.setProto3BooleanField(this, 7, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.GetPersonalDataRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.GetPersonalDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.GetPersonalDataRequest.displayName = "proto.pb.GetPersonalDataRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.GetPersonalDataRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.GetPersonalDataRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.GetPersonalDataRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.GetPersonalDataRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.GetPersonalDataRequest}
 */
proto.pb.GetPersonalDataRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.GetPersonalDataRequest();

    return proto.pb.GetPersonalDataRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.GetPersonalDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.GetPersonalDataRequest}
 */
proto.pb.GetPersonalDataRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.GetPersonalDataRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.GetPersonalDataRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.GetPersonalDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.GetPersonalDataRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.GetPersonalDataResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, proto.pb.GetPersonalDataResponse.oneofGroups_);
};

goog.inherits(proto.pb.GetPersonalDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.GetPersonalDataResponse.displayName = "proto.pb.GetPersonalDataResponse";
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.pb.GetPersonalDataResponse.oneofGroups_ = [[20, 21]];

/**
 * @enum {number}
 */
proto.pb.GetPersonalDataResponse.NotificationssettingsCase = {
    NOTIFICATIONSSETTINGS_NOT_SET: 0,
    STUDENTS_NOTIFICATIONS_SETTINGS: 20,
    TUTORS_NOTIFICATIONS_SETTINGS: 21,
};

/**
 * @return {proto.pb.GetPersonalDataResponse.NotificationssettingsCase}
 */
proto.pb.GetPersonalDataResponse.prototype.getNotificationssettingsCase = function () {
    return /** @type {proto.pb.GetPersonalDataResponse.NotificationssettingsCase} */ (jspb.Message.computeOneofCase(this, proto.pb.GetPersonalDataResponse.oneofGroups_[0]));
};

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.GetPersonalDataResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.GetPersonalDataResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.GetPersonalDataResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.GetPersonalDataResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                userUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
                email: jspb.Message.getFieldWithDefault(msg, 3, ""),
                isEmailConfirmed: jspb.Message.getFieldWithDefault(msg, 4, false),
                isChangeEmailRequestCreated: jspb.Message.getFieldWithDefault(msg, 5, false),
                changedEmailForConfirmation: jspb.Message.getFieldWithDefault(msg, 6, ""),
                isPasswordSet: jspb.Message.getFieldWithDefault(msg, 7, false),
                passwordSetDate: (f = msg.getPasswordSetDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
                lastLogonTime: (f = msg.getLastLogonTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
                isAnonymousProfile: jspb.Message.getFieldWithDefault(msg, 10, false),
                photoLink: jspb.Message.getFieldWithDefault(msg, 11, ""),
                name: jspb.Message.getFieldWithDefault(msg, 12, ""),
                surname: jspb.Message.getFieldWithDefault(msg, 13, ""),
                patronymic: jspb.Message.getFieldWithDefault(msg, 14, ""),
                gender: jspb.Message.getFieldWithDefault(msg, 15, ""),
                dateOfBirth: (f = msg.getDateOfBirth()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
                timezoneUtcOffset: jspb.Message.getFieldWithDefault(msg, 17, ""),
                city: jspb.Message.getFieldWithDefault(msg, 18, ""),
                studyPlace: jspb.Message.getFieldWithDefault(msg, 19, ""),
                studentsNotificationsSettings: (f = msg.getStudentsNotificationsSettings()) && proto.pb.StudentsNotificationsSettings.toObject(includeInstance, f),
                tutorsNotificationsSettings: (f = msg.getTutorsNotificationsSettings()) && proto.pb.TutorsNotificationsSettings.toObject(includeInstance, f),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.GetPersonalDataResponse}
 */
proto.pb.GetPersonalDataResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.GetPersonalDataResponse();

    return proto.pb.GetPersonalDataResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.GetPersonalDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.GetPersonalDataResponse}
 */
proto.pb.GetPersonalDataResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setUserUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setPhone(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());

                msg.setEmail(value);
                break;
            case 4:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setIsEmailConfirmed(value);
                break;
            case 5:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setIsChangeEmailRequestCreated(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());

                msg.setChangedEmailForConfirmation(value);
                break;
            case 7:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setIsPasswordSet(value);
                break;
            case 8:
                var value = new google_protobuf_timestamp_pb.Timestamp();

                reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
                msg.setPasswordSetDate(value);
                break;
            case 9:
                var value = new google_protobuf_timestamp_pb.Timestamp();

                reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
                msg.setLastLogonTime(value);
                break;
            case 10:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setIsAnonymousProfile(value);
                break;
            case 11:
                var value = /** @type {string} */ (reader.readString());

                msg.setPhotoLink(value);
                break;
            case 12:
                var value = /** @type {string} */ (reader.readString());

                msg.setName(value);
                break;
            case 13:
                var value = /** @type {string} */ (reader.readString());

                msg.setSurname(value);
                break;
            case 14:
                var value = /** @type {string} */ (reader.readString());

                msg.setPatronymic(value);
                break;
            case 15:
                var value = /** @type {string} */ (reader.readString());

                msg.setGender(value);
                break;
            case 16:
                var value = new google_protobuf_timestamp_pb.Timestamp();

                reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
                msg.setDateOfBirth(value);
                break;
            case 17:
                var value = /** @type {string} */ (reader.readString());

                msg.setTimezoneUtcOffset(value);
                break;
            case 18:
                var value = /** @type {string} */ (reader.readString());

                msg.setCity(value);
                break;
            case 19:
                var value = /** @type {string} */ (reader.readString());

                msg.setStudyPlace(value);
                break;
            case 20:
                var value = new proto.pb.StudentsNotificationsSettings();

                reader.readMessage(value, proto.pb.StudentsNotificationsSettings.deserializeBinaryFromReader);
                msg.setStudentsNotificationsSettings(value);
                break;
            case 21:
                var value = new proto.pb.TutorsNotificationsSettings();

                reader.readMessage(value, proto.pb.TutorsNotificationsSettings.deserializeBinaryFromReader);
                msg.setTutorsNotificationsSettings(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.GetPersonalDataResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.GetPersonalDataResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.GetPersonalDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.GetPersonalDataResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getUserUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getPhone();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getEmail();
    if (f.length > 0) {
        writer.writeString(3, f);
    }

    f = message.getIsEmailConfirmed();
    if (f) {
        writer.writeBool(4, f);
    }

    f = message.getIsChangeEmailRequestCreated();
    if (f) {
        writer.writeBool(5, f);
    }

    f = message.getChangedEmailForConfirmation();
    if (f.length > 0) {
        writer.writeString(6, f);
    }

    f = message.getIsPasswordSet();
    if (f) {
        writer.writeBool(7, f);
    }

    f = message.getPasswordSetDate();
    if (f != null) {
        writer.writeMessage(8, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
    }

    f = message.getLastLogonTime();
    if (f != null) {
        writer.writeMessage(9, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
    }

    f = message.getIsAnonymousProfile();
    if (f) {
        writer.writeBool(10, f);
    }

    f = message.getPhotoLink();
    if (f.length > 0) {
        writer.writeString(11, f);
    }

    f = message.getName();
    if (f.length > 0) {
        writer.writeString(12, f);
    }

    f = message.getSurname();
    if (f.length > 0) {
        writer.writeString(13, f);
    }

    f = message.getPatronymic();
    if (f.length > 0) {
        writer.writeString(14, f);
    }

    f = message.getGender();
    if (f.length > 0) {
        writer.writeString(15, f);
    }

    f = message.getDateOfBirth();
    if (f != null) {
        writer.writeMessage(16, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
    }

    f = message.getTimezoneUtcOffset();
    if (f.length > 0) {
        writer.writeString(17, f);
    }

    f = message.getCity();
    if (f.length > 0) {
        writer.writeString(18, f);
    }

    f = message.getStudyPlace();
    if (f.length > 0) {
        writer.writeString(19, f);
    }

    f = message.getStudentsNotificationsSettings();
    if (f != null) {
        writer.writeMessage(20, f, proto.pb.StudentsNotificationsSettings.serializeBinaryToWriter);
    }

    f = message.getTutorsNotificationsSettings();
    if (f != null) {
        writer.writeMessage(21, f, proto.pb.TutorsNotificationsSettings.serializeBinaryToWriter);
    }
};

/**
 * optional string user_uuid = 1;
 * @return {string}
 */
proto.pb.GetPersonalDataResponse.prototype.getUserUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.GetPersonalDataResponse.prototype.setUserUuid = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string phone = 2;
 * @return {string}
 */
proto.pb.GetPersonalDataResponse.prototype.getPhone = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.pb.GetPersonalDataResponse.prototype.setPhone = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string email = 3;
 * @return {string}
 */
proto.pb.GetPersonalDataResponse.prototype.getEmail = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/** @param {string} value */
proto.pb.GetPersonalDataResponse.prototype.setEmail = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional bool is_email_confirmed = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.GetPersonalDataResponse.prototype.getIsEmailConfirmed = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};

/** @param {boolean} value */
proto.pb.GetPersonalDataResponse.prototype.setIsEmailConfirmed = function (value) {
    jspb.Message.setProto3BooleanField(this, 4, value);
};

/**
 * optional bool is_change_email_request_created = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.GetPersonalDataResponse.prototype.getIsChangeEmailRequestCreated = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};

/** @param {boolean} value */
proto.pb.GetPersonalDataResponse.prototype.setIsChangeEmailRequestCreated = function (value) {
    jspb.Message.setProto3BooleanField(this, 5, value);
};

/**
 * optional string changed_email_for_confirmation = 6;
 * @return {string}
 */
proto.pb.GetPersonalDataResponse.prototype.getChangedEmailForConfirmation = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/** @param {string} value */
proto.pb.GetPersonalDataResponse.prototype.setChangedEmailForConfirmation = function (value) {
    jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional bool is_password_set = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.GetPersonalDataResponse.prototype.getIsPasswordSet = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};

/** @param {boolean} value */
proto.pb.GetPersonalDataResponse.prototype.setIsPasswordSet = function (value) {
    jspb.Message.setProto3BooleanField(this, 7, value);
};

/**
 * optional google.protobuf.Timestamp password_set_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pb.GetPersonalDataResponse.prototype.getPasswordSetDate = function () {
    return /** @type{?proto.google.protobuf.Timestamp} */ (jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};

/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.pb.GetPersonalDataResponse.prototype.setPasswordSetDate = function (value) {
    jspb.Message.setWrapperField(this, 8, value);
};

proto.pb.GetPersonalDataResponse.prototype.clearPasswordSetDate = function () {
    this.setPasswordSetDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pb.GetPersonalDataResponse.prototype.hasPasswordSetDate = function () {
    return jspb.Message.getField(this, 8) != null;
};

/**
 * optional google.protobuf.Timestamp last_logon_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pb.GetPersonalDataResponse.prototype.getLastLogonTime = function () {
    return /** @type{?proto.google.protobuf.Timestamp} */ (jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};

/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.pb.GetPersonalDataResponse.prototype.setLastLogonTime = function (value) {
    jspb.Message.setWrapperField(this, 9, value);
};

proto.pb.GetPersonalDataResponse.prototype.clearLastLogonTime = function () {
    this.setLastLogonTime(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pb.GetPersonalDataResponse.prototype.hasLastLogonTime = function () {
    return jspb.Message.getField(this, 9) != null;
};

/**
 * optional bool is_anonymous_profile = 10;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.GetPersonalDataResponse.prototype.getIsAnonymousProfile = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 10, false));
};

/** @param {boolean} value */
proto.pb.GetPersonalDataResponse.prototype.setIsAnonymousProfile = function (value) {
    jspb.Message.setProto3BooleanField(this, 10, value);
};

/**
 * optional string photo_link = 11;
 * @return {string}
 */
proto.pb.GetPersonalDataResponse.prototype.getPhotoLink = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};

/** @param {string} value */
proto.pb.GetPersonalDataResponse.prototype.setPhotoLink = function (value) {
    jspb.Message.setProto3StringField(this, 11, value);
};

/**
 * optional string name = 12;
 * @return {string}
 */
proto.pb.GetPersonalDataResponse.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};

/** @param {string} value */
proto.pb.GetPersonalDataResponse.prototype.setName = function (value) {
    jspb.Message.setProto3StringField(this, 12, value);
};

/**
 * optional string surname = 13;
 * @return {string}
 */
proto.pb.GetPersonalDataResponse.prototype.getSurname = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};

/** @param {string} value */
proto.pb.GetPersonalDataResponse.prototype.setSurname = function (value) {
    jspb.Message.setProto3StringField(this, 13, value);
};

/**
 * optional string patronymic = 14;
 * @return {string}
 */
proto.pb.GetPersonalDataResponse.prototype.getPatronymic = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};

/** @param {string} value */
proto.pb.GetPersonalDataResponse.prototype.setPatronymic = function (value) {
    jspb.Message.setProto3StringField(this, 14, value);
};

/**
 * optional string gender = 15;
 * @return {string}
 */
proto.pb.GetPersonalDataResponse.prototype.getGender = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};

/** @param {string} value */
proto.pb.GetPersonalDataResponse.prototype.setGender = function (value) {
    jspb.Message.setProto3StringField(this, 15, value);
};

/**
 * optional google.protobuf.Timestamp date_of_birth = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pb.GetPersonalDataResponse.prototype.getDateOfBirth = function () {
    return /** @type{?proto.google.protobuf.Timestamp} */ (jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};

/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.pb.GetPersonalDataResponse.prototype.setDateOfBirth = function (value) {
    jspb.Message.setWrapperField(this, 16, value);
};

proto.pb.GetPersonalDataResponse.prototype.clearDateOfBirth = function () {
    this.setDateOfBirth(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pb.GetPersonalDataResponse.prototype.hasDateOfBirth = function () {
    return jspb.Message.getField(this, 16) != null;
};

/**
 * optional string timezone_utc_offset = 17;
 * @return {string}
 */
proto.pb.GetPersonalDataResponse.prototype.getTimezoneUtcOffset = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};

/** @param {string} value */
proto.pb.GetPersonalDataResponse.prototype.setTimezoneUtcOffset = function (value) {
    jspb.Message.setProto3StringField(this, 17, value);
};

/**
 * optional string city = 18;
 * @return {string}
 */
proto.pb.GetPersonalDataResponse.prototype.getCity = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};

/** @param {string} value */
proto.pb.GetPersonalDataResponse.prototype.setCity = function (value) {
    jspb.Message.setProto3StringField(this, 18, value);
};

/**
 * optional string study_place = 19;
 * @return {string}
 */
proto.pb.GetPersonalDataResponse.prototype.getStudyPlace = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};

/** @param {string} value */
proto.pb.GetPersonalDataResponse.prototype.setStudyPlace = function (value) {
    jspb.Message.setProto3StringField(this, 19, value);
};

/**
 * optional StudentsNotificationsSettings students_notifications_settings = 20;
 * @return {?proto.pb.StudentsNotificationsSettings}
 */
proto.pb.GetPersonalDataResponse.prototype.getStudentsNotificationsSettings = function () {
    return /** @type{?proto.pb.StudentsNotificationsSettings} */ (jspb.Message.getWrapperField(this, proto.pb.StudentsNotificationsSettings, 20));
};

/** @param {?proto.pb.StudentsNotificationsSettings|undefined} value */
proto.pb.GetPersonalDataResponse.prototype.setStudentsNotificationsSettings = function (value) {
    jspb.Message.setOneofWrapperField(this, 20, proto.pb.GetPersonalDataResponse.oneofGroups_[0], value);
};

proto.pb.GetPersonalDataResponse.prototype.clearStudentsNotificationsSettings = function () {
    this.setStudentsNotificationsSettings(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pb.GetPersonalDataResponse.prototype.hasStudentsNotificationsSettings = function () {
    return jspb.Message.getField(this, 20) != null;
};

/**
 * optional TutorsNotificationsSettings tutors_notifications_settings = 21;
 * @return {?proto.pb.TutorsNotificationsSettings}
 */
proto.pb.GetPersonalDataResponse.prototype.getTutorsNotificationsSettings = function () {
    return /** @type{?proto.pb.TutorsNotificationsSettings} */ (jspb.Message.getWrapperField(this, proto.pb.TutorsNotificationsSettings, 21));
};

/** @param {?proto.pb.TutorsNotificationsSettings|undefined} value */
proto.pb.GetPersonalDataResponse.prototype.setTutorsNotificationsSettings = function (value) {
    jspb.Message.setOneofWrapperField(this, 21, proto.pb.GetPersonalDataResponse.oneofGroups_[0], value);
};

proto.pb.GetPersonalDataResponse.prototype.clearTutorsNotificationsSettings = function () {
    this.setTutorsNotificationsSettings(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pb.GetPersonalDataResponse.prototype.hasTutorsNotificationsSettings = function () {
    return jspb.Message.getField(this, 21) != null;
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.UpdatePersonalDataRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.UpdatePersonalDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.UpdatePersonalDataRequest.displayName = "proto.pb.UpdatePersonalDataRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.UpdatePersonalDataRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.UpdatePersonalDataRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.UpdatePersonalDataRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.UpdatePersonalDataRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                name: jspb.Message.getFieldWithDefault(msg, 1, ""),
                surname: jspb.Message.getFieldWithDefault(msg, 2, ""),
                patronymic: jspb.Message.getFieldWithDefault(msg, 3, ""),
                gender: jspb.Message.getFieldWithDefault(msg, 4, ""),
                dateOfBirth: (f = msg.getDateOfBirth()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
                timezoneUtcOffset: jspb.Message.getFieldWithDefault(msg, 6, ""),
                studyPlace: jspb.Message.getFieldWithDefault(msg, 7, ""),
                city: jspb.Message.getFieldWithDefault(msg, 8, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.UpdatePersonalDataRequest}
 */
proto.pb.UpdatePersonalDataRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.UpdatePersonalDataRequest();

    return proto.pb.UpdatePersonalDataRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.UpdatePersonalDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.UpdatePersonalDataRequest}
 */
proto.pb.UpdatePersonalDataRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setName(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setSurname(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());

                msg.setPatronymic(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());

                msg.setGender(value);
                break;
            case 5:
                var value = new google_protobuf_timestamp_pb.Timestamp();

                reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
                msg.setDateOfBirth(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());

                msg.setTimezoneUtcOffset(value);
                break;
            case 7:
                var value = /** @type {string} */ (reader.readString());

                msg.setStudyPlace(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());

                msg.setCity(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.UpdatePersonalDataRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.UpdatePersonalDataRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.UpdatePersonalDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.UpdatePersonalDataRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getName();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getSurname();
    if (f.length > 0) {
        writer.writeString(2, f);
    }

    f = message.getPatronymic();
    if (f.length > 0) {
        writer.writeString(3, f);
    }

    f = message.getGender();
    if (f.length > 0) {
        writer.writeString(4, f);
    }

    f = message.getDateOfBirth();
    if (f != null) {
        writer.writeMessage(5, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
    }

    f = message.getTimezoneUtcOffset();
    if (f.length > 0) {
        writer.writeString(6, f);
    }

    f = message.getStudyPlace();
    if (f.length > 0) {
        writer.writeString(7, f);
    }

    f = message.getCity();
    if (f.length > 0) {
        writer.writeString(8, f);
    }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.pb.UpdatePersonalDataRequest.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.UpdatePersonalDataRequest.prototype.setName = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string surname = 2;
 * @return {string}
 */
proto.pb.UpdatePersonalDataRequest.prototype.getSurname = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.pb.UpdatePersonalDataRequest.prototype.setSurname = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string patronymic = 3;
 * @return {string}
 */
proto.pb.UpdatePersonalDataRequest.prototype.getPatronymic = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/** @param {string} value */
proto.pb.UpdatePersonalDataRequest.prototype.setPatronymic = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string gender = 4;
 * @return {string}
 */
proto.pb.UpdatePersonalDataRequest.prototype.getGender = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/** @param {string} value */
proto.pb.UpdatePersonalDataRequest.prototype.setGender = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional google.protobuf.Timestamp date_of_birth = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pb.UpdatePersonalDataRequest.prototype.getDateOfBirth = function () {
    return /** @type{?proto.google.protobuf.Timestamp} */ (jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};

/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.pb.UpdatePersonalDataRequest.prototype.setDateOfBirth = function (value) {
    jspb.Message.setWrapperField(this, 5, value);
};

proto.pb.UpdatePersonalDataRequest.prototype.clearDateOfBirth = function () {
    this.setDateOfBirth(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pb.UpdatePersonalDataRequest.prototype.hasDateOfBirth = function () {
    return jspb.Message.getField(this, 5) != null;
};

/**
 * optional string timezone_utc_offset = 6;
 * @return {string}
 */
proto.pb.UpdatePersonalDataRequest.prototype.getTimezoneUtcOffset = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/** @param {string} value */
proto.pb.UpdatePersonalDataRequest.prototype.setTimezoneUtcOffset = function (value) {
    jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional string study_place = 7;
 * @return {string}
 */
proto.pb.UpdatePersonalDataRequest.prototype.getStudyPlace = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};

/** @param {string} value */
proto.pb.UpdatePersonalDataRequest.prototype.setStudyPlace = function (value) {
    jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional string city = 8;
 * @return {string}
 */
proto.pb.UpdatePersonalDataRequest.prototype.getCity = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};

/** @param {string} value */
proto.pb.UpdatePersonalDataRequest.prototype.setCity = function (value) {
    jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.UpdatePersonalDataResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.UpdatePersonalDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.UpdatePersonalDataResponse.displayName = "proto.pb.UpdatePersonalDataResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.UpdatePersonalDataResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.UpdatePersonalDataResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.UpdatePersonalDataResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.UpdatePersonalDataResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                success: jspb.Message.getFieldWithDefault(msg, 1, false),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.UpdatePersonalDataResponse}
 */
proto.pb.UpdatePersonalDataResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.UpdatePersonalDataResponse();

    return proto.pb.UpdatePersonalDataResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.UpdatePersonalDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.UpdatePersonalDataResponse}
 */
proto.pb.UpdatePersonalDataResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setSuccess(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.UpdatePersonalDataResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.UpdatePersonalDataResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.UpdatePersonalDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.UpdatePersonalDataResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getSuccess();
    if (f) {
        writer.writeBool(1, f);
    }
};

/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.UpdatePersonalDataResponse.prototype.getSuccess = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};

/** @param {boolean} value */
proto.pb.UpdatePersonalDataResponse.prototype.setSuccess = function (value) {
    jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.UpdateProfilePhotoRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.UpdateProfilePhotoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.UpdateProfilePhotoRequest.displayName = "proto.pb.UpdateProfilePhotoRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.UpdateProfilePhotoRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.UpdateProfilePhotoRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.UpdateProfilePhotoRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.UpdateProfilePhotoRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                data: msg.getData_asB64(),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.UpdateProfilePhotoRequest}
 */
proto.pb.UpdateProfilePhotoRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.UpdateProfilePhotoRequest();

    return proto.pb.UpdateProfilePhotoRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.UpdateProfilePhotoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.UpdateProfilePhotoRequest}
 */
proto.pb.UpdateProfilePhotoRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {!Uint8Array} */ (reader.readBytes());

                msg.setData(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.UpdateProfilePhotoRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.UpdateProfilePhotoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.UpdateProfilePhotoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.UpdateProfilePhotoRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getData_asU8();
    if (f.length > 0) {
        writer.writeBytes(1, f);
    }
};

/**
 * optional bytes data = 1;
 * @return {string}
 */
proto.pb.UpdateProfilePhotoRequest.prototype.getData = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * optional bytes data = 1;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.pb.UpdateProfilePhotoRequest.prototype.getData_asB64 = function () {
    return /** @type {string} */ (jspb.Message.bytesAsB64(this.getData()));
};

/**
 * optional bytes data = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.pb.UpdateProfilePhotoRequest.prototype.getData_asU8 = function () {
    return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getData()));
};

/** @param {!(string|Uint8Array)} value */
proto.pb.UpdateProfilePhotoRequest.prototype.setData = function (value) {
    jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.UpdateProfilePhotoResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.UpdateProfilePhotoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.UpdateProfilePhotoResponse.displayName = "proto.pb.UpdateProfilePhotoResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.UpdateProfilePhotoResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.UpdateProfilePhotoResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.UpdateProfilePhotoResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.UpdateProfilePhotoResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                success: jspb.Message.getFieldWithDefault(msg, 1, false),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.UpdateProfilePhotoResponse}
 */
proto.pb.UpdateProfilePhotoResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.UpdateProfilePhotoResponse();

    return proto.pb.UpdateProfilePhotoResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.UpdateProfilePhotoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.UpdateProfilePhotoResponse}
 */
proto.pb.UpdateProfilePhotoResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setSuccess(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.UpdateProfilePhotoResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.UpdateProfilePhotoResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.UpdateProfilePhotoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.UpdateProfilePhotoResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getSuccess();
    if (f) {
        writer.writeBool(1, f);
    }
};

/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.UpdateProfilePhotoResponse.prototype.getSuccess = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};

/** @param {boolean} value */
proto.pb.UpdateProfilePhotoResponse.prototype.setSuccess = function (value) {
    jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.DeleteProfilePhotoRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.DeleteProfilePhotoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.DeleteProfilePhotoRequest.displayName = "proto.pb.DeleteProfilePhotoRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.DeleteProfilePhotoRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.DeleteProfilePhotoRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.DeleteProfilePhotoRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.DeleteProfilePhotoRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.DeleteProfilePhotoRequest}
 */
proto.pb.DeleteProfilePhotoRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.DeleteProfilePhotoRequest();

    return proto.pb.DeleteProfilePhotoRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.DeleteProfilePhotoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.DeleteProfilePhotoRequest}
 */
proto.pb.DeleteProfilePhotoRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.DeleteProfilePhotoRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.DeleteProfilePhotoRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.DeleteProfilePhotoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.DeleteProfilePhotoRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.DeleteProfilePhotoResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.DeleteProfilePhotoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.DeleteProfilePhotoResponse.displayName = "proto.pb.DeleteProfilePhotoResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.DeleteProfilePhotoResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.DeleteProfilePhotoResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.DeleteProfilePhotoResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.DeleteProfilePhotoResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                success: jspb.Message.getFieldWithDefault(msg, 1, false),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.DeleteProfilePhotoResponse}
 */
proto.pb.DeleteProfilePhotoResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.DeleteProfilePhotoResponse();

    return proto.pb.DeleteProfilePhotoResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.DeleteProfilePhotoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.DeleteProfilePhotoResponse}
 */
proto.pb.DeleteProfilePhotoResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setSuccess(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.DeleteProfilePhotoResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.DeleteProfilePhotoResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.DeleteProfilePhotoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.DeleteProfilePhotoResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getSuccess();
    if (f) {
        writer.writeBool(1, f);
    }
};

/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.DeleteProfilePhotoResponse.prototype.getSuccess = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};

/** @param {boolean} value */
proto.pb.DeleteProfilePhotoResponse.prototype.setSuccess = function (value) {
    jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.GetPersonalDataForAuthRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.GetPersonalDataForAuthRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.GetPersonalDataForAuthRequest.displayName = "proto.pb.GetPersonalDataForAuthRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.GetPersonalDataForAuthRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.GetPersonalDataForAuthRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.GetPersonalDataForAuthRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.GetPersonalDataForAuthRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                userUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.GetPersonalDataForAuthRequest}
 */
proto.pb.GetPersonalDataForAuthRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.GetPersonalDataForAuthRequest();

    return proto.pb.GetPersonalDataForAuthRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.GetPersonalDataForAuthRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.GetPersonalDataForAuthRequest}
 */
proto.pb.GetPersonalDataForAuthRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setUserUuid(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.GetPersonalDataForAuthRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.GetPersonalDataForAuthRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.GetPersonalDataForAuthRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.GetPersonalDataForAuthRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getUserUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};

/**
 * optional string user_uuid = 1;
 * @return {string}
 */
proto.pb.GetPersonalDataForAuthRequest.prototype.getUserUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.GetPersonalDataForAuthRequest.prototype.setUserUuid = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.GetPersonalDataForAuthResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.GetPersonalDataForAuthResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.GetPersonalDataForAuthResponse.displayName = "proto.pb.GetPersonalDataForAuthResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.GetPersonalDataForAuthResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.GetPersonalDataForAuthResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.GetPersonalDataForAuthResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.GetPersonalDataForAuthResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                name: jspb.Message.getFieldWithDefault(msg, 1, ""),
                timezoneUtcOffset: jspb.Message.getFieldWithDefault(msg, 2, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.GetPersonalDataForAuthResponse}
 */
proto.pb.GetPersonalDataForAuthResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.GetPersonalDataForAuthResponse();

    return proto.pb.GetPersonalDataForAuthResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.GetPersonalDataForAuthResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.GetPersonalDataForAuthResponse}
 */
proto.pb.GetPersonalDataForAuthResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setName(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setTimezoneUtcOffset(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.GetPersonalDataForAuthResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.GetPersonalDataForAuthResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.GetPersonalDataForAuthResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.GetPersonalDataForAuthResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getName();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getTimezoneUtcOffset();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.pb.GetPersonalDataForAuthResponse.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.GetPersonalDataForAuthResponse.prototype.setName = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string timezone_utc_offset = 2;
 * @return {string}
 */
proto.pb.GetPersonalDataForAuthResponse.prototype.getTimezoneUtcOffset = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.pb.GetPersonalDataForAuthResponse.prototype.setTimezoneUtcOffset = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.AddPersonalDataForAuthRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.AddPersonalDataForAuthRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.AddPersonalDataForAuthRequest.displayName = "proto.pb.AddPersonalDataForAuthRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.AddPersonalDataForAuthRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.AddPersonalDataForAuthRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.AddPersonalDataForAuthRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.AddPersonalDataForAuthRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                userUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                name: jspb.Message.getFieldWithDefault(msg, 2, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.AddPersonalDataForAuthRequest}
 */
proto.pb.AddPersonalDataForAuthRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.AddPersonalDataForAuthRequest();

    return proto.pb.AddPersonalDataForAuthRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.AddPersonalDataForAuthRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.AddPersonalDataForAuthRequest}
 */
proto.pb.AddPersonalDataForAuthRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setUserUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setName(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.AddPersonalDataForAuthRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.AddPersonalDataForAuthRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.AddPersonalDataForAuthRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.AddPersonalDataForAuthRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getUserUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getName();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};

/**
 * optional string user_uuid = 1;
 * @return {string}
 */
proto.pb.AddPersonalDataForAuthRequest.prototype.getUserUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.AddPersonalDataForAuthRequest.prototype.setUserUuid = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string name = 2;
 * @return {string}
 */
proto.pb.AddPersonalDataForAuthRequest.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.pb.AddPersonalDataForAuthRequest.prototype.setName = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.AddPersonalDataForAuthResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.AddPersonalDataForAuthResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.AddPersonalDataForAuthResponse.displayName = "proto.pb.AddPersonalDataForAuthResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.AddPersonalDataForAuthResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.AddPersonalDataForAuthResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.AddPersonalDataForAuthResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.AddPersonalDataForAuthResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                success: jspb.Message.getFieldWithDefault(msg, 1, false),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.AddPersonalDataForAuthResponse}
 */
proto.pb.AddPersonalDataForAuthResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.AddPersonalDataForAuthResponse();

    return proto.pb.AddPersonalDataForAuthResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.AddPersonalDataForAuthResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.AddPersonalDataForAuthResponse}
 */
proto.pb.AddPersonalDataForAuthResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setSuccess(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.AddPersonalDataForAuthResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.AddPersonalDataForAuthResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.AddPersonalDataForAuthResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.AddPersonalDataForAuthResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getSuccess();
    if (f) {
        writer.writeBool(1, f);
    }
};

/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.AddPersonalDataForAuthResponse.prototype.getSuccess = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};

/** @param {boolean} value */
proto.pb.AddPersonalDataForAuthResponse.prototype.setSuccess = function (value) {
    jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.SetLastLogonTimeForAuthRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.SetLastLogonTimeForAuthRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.SetLastLogonTimeForAuthRequest.displayName = "proto.pb.SetLastLogonTimeForAuthRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.SetLastLogonTimeForAuthRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.SetLastLogonTimeForAuthRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.SetLastLogonTimeForAuthRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.SetLastLogonTimeForAuthRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                userUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
                role: jspb.Message.getFieldWithDefault(msg, 2, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.SetLastLogonTimeForAuthRequest}
 */
proto.pb.SetLastLogonTimeForAuthRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.SetLastLogonTimeForAuthRequest();

    return proto.pb.SetLastLogonTimeForAuthRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.SetLastLogonTimeForAuthRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.SetLastLogonTimeForAuthRequest}
 */
proto.pb.SetLastLogonTimeForAuthRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setUserUuid(value);
                break;
            case 2:
                var value = /** @type {string} */ (reader.readString());

                msg.setRole(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.SetLastLogonTimeForAuthRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.SetLastLogonTimeForAuthRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.SetLastLogonTimeForAuthRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.SetLastLogonTimeForAuthRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getUserUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }

    f = message.getRole();
    if (f.length > 0) {
        writer.writeString(2, f);
    }
};

/**
 * optional string user_uuid = 1;
 * @return {string}
 */
proto.pb.SetLastLogonTimeForAuthRequest.prototype.getUserUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.SetLastLogonTimeForAuthRequest.prototype.setUserUuid = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string role = 2;
 * @return {string}
 */
proto.pb.SetLastLogonTimeForAuthRequest.prototype.getRole = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/** @param {string} value */
proto.pb.SetLastLogonTimeForAuthRequest.prototype.setRole = function (value) {
    jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.SetLastLogonTimeForAuthResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.SetLastLogonTimeForAuthResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.SetLastLogonTimeForAuthResponse.displayName = "proto.pb.SetLastLogonTimeForAuthResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.SetLastLogonTimeForAuthResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.SetLastLogonTimeForAuthResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.SetLastLogonTimeForAuthResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.SetLastLogonTimeForAuthResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                success: jspb.Message.getFieldWithDefault(msg, 1, false),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.SetLastLogonTimeForAuthResponse}
 */
proto.pb.SetLastLogonTimeForAuthResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.SetLastLogonTimeForAuthResponse();

    return proto.pb.SetLastLogonTimeForAuthResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.SetLastLogonTimeForAuthResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.SetLastLogonTimeForAuthResponse}
 */
proto.pb.SetLastLogonTimeForAuthResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setSuccess(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.SetLastLogonTimeForAuthResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.SetLastLogonTimeForAuthResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.SetLastLogonTimeForAuthResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.SetLastLogonTimeForAuthResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getSuccess();
    if (f) {
        writer.writeBool(1, f);
    }
};

/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.SetLastLogonTimeForAuthResponse.prototype.getSuccess = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};

/** @param {boolean} value */
proto.pb.SetLastLogonTimeForAuthResponse.prototype.setSuccess = function (value) {
    jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.GetTutorQuestionaryRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.GetTutorQuestionaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.GetTutorQuestionaryRequest.displayName = "proto.pb.GetTutorQuestionaryRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.GetTutorQuestionaryRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.GetTutorQuestionaryRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.GetTutorQuestionaryRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.GetTutorQuestionaryRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.GetTutorQuestionaryRequest}
 */
proto.pb.GetTutorQuestionaryRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.GetTutorQuestionaryRequest();

    return proto.pb.GetTutorQuestionaryRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.GetTutorQuestionaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.GetTutorQuestionaryRequest}
 */
proto.pb.GetTutorQuestionaryRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.GetTutorQuestionaryRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.GetTutorQuestionaryRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.GetTutorQuestionaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.GetTutorQuestionaryRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.GetTutorQuestionaryResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.GetTutorQuestionaryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.GetTutorQuestionaryResponse.displayName = "proto.pb.GetTutorQuestionaryResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.GetTutorQuestionaryResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.GetTutorQuestionaryResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.GetTutorQuestionaryResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.GetTutorQuestionaryResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                isVerified: jspb.Message.getFieldWithDefault(msg, 1, false),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.GetTutorQuestionaryResponse}
 */
proto.pb.GetTutorQuestionaryResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.GetTutorQuestionaryResponse();

    return proto.pb.GetTutorQuestionaryResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.GetTutorQuestionaryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.GetTutorQuestionaryResponse}
 */
proto.pb.GetTutorQuestionaryResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setIsVerified(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.GetTutorQuestionaryResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.GetTutorQuestionaryResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.GetTutorQuestionaryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.GetTutorQuestionaryResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getIsVerified();
    if (f) {
        writer.writeBool(1, f);
    }
};

/**
 * optional bool is_verified = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.GetTutorQuestionaryResponse.prototype.getIsVerified = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};

/** @param {boolean} value */
proto.pb.GetTutorQuestionaryResponse.prototype.setIsVerified = function (value) {
    jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.AddTutorQuestionaryRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.AddTutorQuestionaryRequest.repeatedFields_, null);
};

goog.inherits(proto.pb.AddTutorQuestionaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.AddTutorQuestionaryRequest.displayName = "proto.pb.AddTutorQuestionaryRequest";
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.AddTutorQuestionaryRequest.repeatedFields_ = [1, 2, 3, 6, 7, 8, 10, 13];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.AddTutorQuestionaryRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.AddTutorQuestionaryRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.AddTutorQuestionaryRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.AddTutorQuestionaryRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                audienceList: jspb.Message.getRepeatedField(msg, 1),
                subjectsList: jspb.Message.toObjectList(msg.getSubjectsList(), proto.pb.Subject.toObject, includeInstance),
                classFormatsList: jspb.Message.getRepeatedField(msg, 3),
                tutorsPlace: (f = msg.getTutorsPlace()) && proto.pb.Place.toObject(includeInstance, f),
                studentsPlaces: (f = msg.getStudentsPlaces()) && proto.pb.Place.toObject(includeInstance, f),
                educationsList: jspb.Message.toObjectList(msg.getEducationsList(), proto.pb.Education.toObject, includeInstance),
                experienceList: jspb.Message.toObjectList(msg.getExperienceList(), proto.pb.ExperienceInfo.toObject, includeInstance),
                certificatesList: jspb.Message.toObjectList(msg.getCertificatesList(), proto.pb.Certificate.toObject, includeInstance),
                latestTimeUntilClass: jspb.Message.getFieldWithDefault(msg, 9, 0),
                weekDaysTimetablesList: jspb.Message.toObjectList(msg.getWeekDaysTimetablesList(), proto.pb.WeekDayTimetable.toObject, includeInstance),
                aboutMeText: jspb.Message.getFieldWithDefault(msg, 11, ""),
                videoCard: (f = msg.getVideoCard()) && proto.pb.Document.toObject(includeInstance, f),
                externalServicesProfilesList: jspb.Message.toObjectList(msg.getExternalServicesProfilesList(), proto.pb.ExternalProfile.toObject, includeInstance),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.AddTutorQuestionaryRequest}
 */
proto.pb.AddTutorQuestionaryRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.AddTutorQuestionaryRequest();

    return proto.pb.AddTutorQuestionaryRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.AddTutorQuestionaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.AddTutorQuestionaryRequest}
 */
proto.pb.AddTutorQuestionaryRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.addAudience(value);
                break;
            case 2:
                var value = new proto.pb.Subject();

                reader.readMessage(value, proto.pb.Subject.deserializeBinaryFromReader);
                msg.addSubjects(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());

                msg.addClassFormats(value);
                break;
            case 4:
                var value = new proto.pb.Place();

                reader.readMessage(value, proto.pb.Place.deserializeBinaryFromReader);
                msg.setTutorsPlace(value);
                break;
            case 5:
                var value = new proto.pb.Place();

                reader.readMessage(value, proto.pb.Place.deserializeBinaryFromReader);
                msg.setStudentsPlaces(value);
                break;
            case 6:
                var value = new proto.pb.Education();

                reader.readMessage(value, proto.pb.Education.deserializeBinaryFromReader);
                msg.addEducations(value);
                break;
            case 7:
                var value = new proto.pb.ExperienceInfo();

                reader.readMessage(value, proto.pb.ExperienceInfo.deserializeBinaryFromReader);
                msg.addExperience(value);
                break;
            case 8:
                var value = new proto.pb.Certificate();

                reader.readMessage(value, proto.pb.Certificate.deserializeBinaryFromReader);
                msg.addCertificates(value);
                break;
            case 9:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setLatestTimeUntilClass(value);
                break;
            case 10:
                var value = new proto.pb.WeekDayTimetable();

                reader.readMessage(value, proto.pb.WeekDayTimetable.deserializeBinaryFromReader);
                msg.addWeekDaysTimetables(value);
                break;
            case 11:
                var value = /** @type {string} */ (reader.readString());

                msg.setAboutMeText(value);
                break;
            case 12:
                var value = new proto.pb.Document();

                reader.readMessage(value, proto.pb.Document.deserializeBinaryFromReader);
                msg.setVideoCard(value);
                break;
            case 13:
                var value = new proto.pb.ExternalProfile();

                reader.readMessage(value, proto.pb.ExternalProfile.deserializeBinaryFromReader);
                msg.addExternalServicesProfiles(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.AddTutorQuestionaryRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.AddTutorQuestionaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.AddTutorQuestionaryRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getAudienceList();
    if (f.length > 0) {
        writer.writeRepeatedString(1, f);
    }

    f = message.getSubjectsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(2, f, proto.pb.Subject.serializeBinaryToWriter);
    }

    f = message.getClassFormatsList();
    if (f.length > 0) {
        writer.writeRepeatedString(3, f);
    }

    f = message.getTutorsPlace();
    if (f != null) {
        writer.writeMessage(4, f, proto.pb.Place.serializeBinaryToWriter);
    }

    f = message.getStudentsPlaces();
    if (f != null) {
        writer.writeMessage(5, f, proto.pb.Place.serializeBinaryToWriter);
    }

    f = message.getEducationsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(6, f, proto.pb.Education.serializeBinaryToWriter);
    }

    f = message.getExperienceList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(7, f, proto.pb.ExperienceInfo.serializeBinaryToWriter);
    }

    f = message.getCertificatesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(8, f, proto.pb.Certificate.serializeBinaryToWriter);
    }

    f = message.getLatestTimeUntilClass();
    if (f !== 0) {
        writer.writeInt32(9, f);
    }

    f = message.getWeekDaysTimetablesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(10, f, proto.pb.WeekDayTimetable.serializeBinaryToWriter);
    }

    f = message.getAboutMeText();
    if (f.length > 0) {
        writer.writeString(11, f);
    }

    f = message.getVideoCard();
    if (f != null) {
        writer.writeMessage(12, f, proto.pb.Document.serializeBinaryToWriter);
    }

    f = message.getExternalServicesProfilesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(13, f, proto.pb.ExternalProfile.serializeBinaryToWriter);
    }
};

/**
 * repeated string audience = 1;
 * @return {!Array<string>}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.getAudienceList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};

/** @param {!Array<string>} value */
proto.pb.AddTutorQuestionaryRequest.prototype.setAudienceList = function (value) {
    jspb.Message.setField(this, 1, value || []);
};

/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.pb.AddTutorQuestionaryRequest.prototype.addAudience = function (value, opt_index) {
    jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};

proto.pb.AddTutorQuestionaryRequest.prototype.clearAudienceList = function () {
    this.setAudienceList([]);
};

/**
 * repeated Subject subjects = 2;
 * @return {!Array<!proto.pb.Subject>}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.getSubjectsList = function () {
    return /** @type{!Array<!proto.pb.Subject>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.Subject, 2));
};

/** @param {!Array<!proto.pb.Subject>} value */
proto.pb.AddTutorQuestionaryRequest.prototype.setSubjectsList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.pb.Subject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.Subject}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.addSubjects = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.pb.Subject, opt_index);
};

proto.pb.AddTutorQuestionaryRequest.prototype.clearSubjectsList = function () {
    this.setSubjectsList([]);
};

/**
 * repeated string class_formats = 3;
 * @return {!Array<string>}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.getClassFormatsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};

/** @param {!Array<string>} value */
proto.pb.AddTutorQuestionaryRequest.prototype.setClassFormatsList = function (value) {
    jspb.Message.setField(this, 3, value || []);
};

/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.pb.AddTutorQuestionaryRequest.prototype.addClassFormats = function (value, opt_index) {
    jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};

proto.pb.AddTutorQuestionaryRequest.prototype.clearClassFormatsList = function () {
    this.setClassFormatsList([]);
};

/**
 * optional Place tutors_place = 4;
 * @return {?proto.pb.Place}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.getTutorsPlace = function () {
    return /** @type{?proto.pb.Place} */ (jspb.Message.getWrapperField(this, proto.pb.Place, 4));
};

/** @param {?proto.pb.Place|undefined} value */
proto.pb.AddTutorQuestionaryRequest.prototype.setTutorsPlace = function (value) {
    jspb.Message.setWrapperField(this, 4, value);
};

proto.pb.AddTutorQuestionaryRequest.prototype.clearTutorsPlace = function () {
    this.setTutorsPlace(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.hasTutorsPlace = function () {
    return jspb.Message.getField(this, 4) != null;
};

/**
 * optional Place students_places = 5;
 * @return {?proto.pb.Place}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.getStudentsPlaces = function () {
    return /** @type{?proto.pb.Place} */ (jspb.Message.getWrapperField(this, proto.pb.Place, 5));
};

/** @param {?proto.pb.Place|undefined} value */
proto.pb.AddTutorQuestionaryRequest.prototype.setStudentsPlaces = function (value) {
    jspb.Message.setWrapperField(this, 5, value);
};

proto.pb.AddTutorQuestionaryRequest.prototype.clearStudentsPlaces = function () {
    this.setStudentsPlaces(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.hasStudentsPlaces = function () {
    return jspb.Message.getField(this, 5) != null;
};

/**
 * repeated Education educations = 6;
 * @return {!Array<!proto.pb.Education>}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.getEducationsList = function () {
    return /** @type{!Array<!proto.pb.Education>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.Education, 6));
};

/** @param {!Array<!proto.pb.Education>} value */
proto.pb.AddTutorQuestionaryRequest.prototype.setEducationsList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 6, value);
};

/**
 * @param {!proto.pb.Education=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.Education}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.addEducations = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.pb.Education, opt_index);
};

proto.pb.AddTutorQuestionaryRequest.prototype.clearEducationsList = function () {
    this.setEducationsList([]);
};

/**
 * repeated ExperienceInfo experience = 7;
 * @return {!Array<!proto.pb.ExperienceInfo>}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.getExperienceList = function () {
    return /** @type{!Array<!proto.pb.ExperienceInfo>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.ExperienceInfo, 7));
};

/** @param {!Array<!proto.pb.ExperienceInfo>} value */
proto.pb.AddTutorQuestionaryRequest.prototype.setExperienceList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 7, value);
};

/**
 * @param {!proto.pb.ExperienceInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.ExperienceInfo}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.addExperience = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.pb.ExperienceInfo, opt_index);
};

proto.pb.AddTutorQuestionaryRequest.prototype.clearExperienceList = function () {
    this.setExperienceList([]);
};

/**
 * repeated Certificate certificates = 8;
 * @return {!Array<!proto.pb.Certificate>}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.getCertificatesList = function () {
    return /** @type{!Array<!proto.pb.Certificate>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.Certificate, 8));
};

/** @param {!Array<!proto.pb.Certificate>} value */
proto.pb.AddTutorQuestionaryRequest.prototype.setCertificatesList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 8, value);
};

/**
 * @param {!proto.pb.Certificate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.Certificate}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.addCertificates = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.pb.Certificate, opt_index);
};

proto.pb.AddTutorQuestionaryRequest.prototype.clearCertificatesList = function () {
    this.setCertificatesList([]);
};

/**
 * optional int32 latest_time_until_class = 9;
 * @return {number}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.getLatestTimeUntilClass = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};

/** @param {number} value */
proto.pb.AddTutorQuestionaryRequest.prototype.setLatestTimeUntilClass = function (value) {
    jspb.Message.setProto3IntField(this, 9, value);
};

/**
 * repeated WeekDayTimetable week_days_timetables = 10;
 * @return {!Array<!proto.pb.WeekDayTimetable>}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.getWeekDaysTimetablesList = function () {
    return /** @type{!Array<!proto.pb.WeekDayTimetable>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.WeekDayTimetable, 10));
};

/** @param {!Array<!proto.pb.WeekDayTimetable>} value */
proto.pb.AddTutorQuestionaryRequest.prototype.setWeekDaysTimetablesList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 10, value);
};

/**
 * @param {!proto.pb.WeekDayTimetable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.WeekDayTimetable}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.addWeekDaysTimetables = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.pb.WeekDayTimetable, opt_index);
};

proto.pb.AddTutorQuestionaryRequest.prototype.clearWeekDaysTimetablesList = function () {
    this.setWeekDaysTimetablesList([]);
};

/**
 * optional string about_me_text = 11;
 * @return {string}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.getAboutMeText = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};

/** @param {string} value */
proto.pb.AddTutorQuestionaryRequest.prototype.setAboutMeText = function (value) {
    jspb.Message.setProto3StringField(this, 11, value);
};

/**
 * optional Document video_card = 12;
 * @return {?proto.pb.Document}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.getVideoCard = function () {
    return /** @type{?proto.pb.Document} */ (jspb.Message.getWrapperField(this, proto.pb.Document, 12));
};

/** @param {?proto.pb.Document|undefined} value */
proto.pb.AddTutorQuestionaryRequest.prototype.setVideoCard = function (value) {
    jspb.Message.setWrapperField(this, 12, value);
};

proto.pb.AddTutorQuestionaryRequest.prototype.clearVideoCard = function () {
    this.setVideoCard(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.hasVideoCard = function () {
    return jspb.Message.getField(this, 12) != null;
};

/**
 * repeated ExternalProfile external_services_profiles = 13;
 * @return {!Array<!proto.pb.ExternalProfile>}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.getExternalServicesProfilesList = function () {
    return /** @type{!Array<!proto.pb.ExternalProfile>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.ExternalProfile, 13));
};

/** @param {!Array<!proto.pb.ExternalProfile>} value */
proto.pb.AddTutorQuestionaryRequest.prototype.setExternalServicesProfilesList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 13, value);
};

/**
 * @param {!proto.pb.ExternalProfile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.ExternalProfile}
 */
proto.pb.AddTutorQuestionaryRequest.prototype.addExternalServicesProfiles = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.pb.ExternalProfile, opt_index);
};

proto.pb.AddTutorQuestionaryRequest.prototype.clearExternalServicesProfilesList = function () {
    this.setExternalServicesProfilesList([]);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.AddTutorQuestionaryResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.AddTutorQuestionaryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.AddTutorQuestionaryResponse.displayName = "proto.pb.AddTutorQuestionaryResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.AddTutorQuestionaryResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.AddTutorQuestionaryResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.AddTutorQuestionaryResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.AddTutorQuestionaryResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                success: jspb.Message.getFieldWithDefault(msg, 1, false),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.AddTutorQuestionaryResponse}
 */
proto.pb.AddTutorQuestionaryResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.AddTutorQuestionaryResponse();

    return proto.pb.AddTutorQuestionaryResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.AddTutorQuestionaryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.AddTutorQuestionaryResponse}
 */
proto.pb.AddTutorQuestionaryResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setSuccess(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.AddTutorQuestionaryResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.AddTutorQuestionaryResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.AddTutorQuestionaryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.AddTutorQuestionaryResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getSuccess();
    if (f) {
        writer.writeBool(1, f);
    }
};

/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.AddTutorQuestionaryResponse.prototype.getSuccess = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};

/** @param {boolean} value */
proto.pb.AddTutorQuestionaryResponse.prototype.setSuccess = function (value) {
    jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.DeleteTutorQuestionaryRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.DeleteTutorQuestionaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.DeleteTutorQuestionaryRequest.displayName = "proto.pb.DeleteTutorQuestionaryRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.DeleteTutorQuestionaryRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.DeleteTutorQuestionaryRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.DeleteTutorQuestionaryRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.DeleteTutorQuestionaryRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {};

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.DeleteTutorQuestionaryRequest}
 */
proto.pb.DeleteTutorQuestionaryRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.DeleteTutorQuestionaryRequest();

    return proto.pb.DeleteTutorQuestionaryRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.DeleteTutorQuestionaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.DeleteTutorQuestionaryRequest}
 */
proto.pb.DeleteTutorQuestionaryRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.DeleteTutorQuestionaryRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.DeleteTutorQuestionaryRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.DeleteTutorQuestionaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.DeleteTutorQuestionaryRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.DeleteTutorQuestionaryResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.DeleteTutorQuestionaryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.DeleteTutorQuestionaryResponse.displayName = "proto.pb.DeleteTutorQuestionaryResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.DeleteTutorQuestionaryResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.DeleteTutorQuestionaryResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.DeleteTutorQuestionaryResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.DeleteTutorQuestionaryResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                success: jspb.Message.getFieldWithDefault(msg, 1, false),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.DeleteTutorQuestionaryResponse}
 */
proto.pb.DeleteTutorQuestionaryResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.DeleteTutorQuestionaryResponse();

    return proto.pb.DeleteTutorQuestionaryResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.DeleteTutorQuestionaryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.DeleteTutorQuestionaryResponse}
 */
proto.pb.DeleteTutorQuestionaryResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setSuccess(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.DeleteTutorQuestionaryResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.DeleteTutorQuestionaryResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.DeleteTutorQuestionaryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.DeleteTutorQuestionaryResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getSuccess();
    if (f) {
        writer.writeBool(1, f);
    }
};

/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.DeleteTutorQuestionaryResponse.prototype.getSuccess = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};

/** @param {boolean} value */
proto.pb.DeleteTutorQuestionaryResponse.prototype.setSuccess = function (value) {
    jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.GetTutorInfoForCatalogExtendedCardRequest = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.pb.GetTutorInfoForCatalogExtendedCardRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.GetTutorInfoForCatalogExtendedCardRequest.displayName = "proto.pb.GetTutorInfoForCatalogExtendedCardRequest";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.GetTutorInfoForCatalogExtendedCardRequest.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.GetTutorInfoForCatalogExtendedCardRequest.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.GetTutorInfoForCatalogExtendedCardRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.GetTutorInfoForCatalogExtendedCardRequest.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                userUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.GetTutorInfoForCatalogExtendedCardRequest}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardRequest.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.GetTutorInfoForCatalogExtendedCardRequest();

    return proto.pb.GetTutorInfoForCatalogExtendedCardRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.GetTutorInfoForCatalogExtendedCardRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.GetTutorInfoForCatalogExtendedCardRequest}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardRequest.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {string} */ (reader.readString());

                msg.setUserUuid(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardRequest.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.GetTutorInfoForCatalogExtendedCardRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.GetTutorInfoForCatalogExtendedCardRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.GetTutorInfoForCatalogExtendedCardRequest.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getUserUuid();
    if (f.length > 0) {
        writer.writeString(1, f);
    }
};

/**
 * optional string user_uuid = 1;
 * @return {string}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardRequest.prototype.getUserUuid = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/** @param {string} value */
proto.pb.GetTutorInfoForCatalogExtendedCardRequest.prototype.setUserUuid = function (value) {
    jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse = function (opt_data) {
    jspb.Message.initialize(this, opt_data, 0, -1, proto.pb.GetTutorInfoForCatalogExtendedCardResponse.repeatedFields_, null);
};

goog.inherits(proto.pb.GetTutorInfoForCatalogExtendedCardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
    proto.pb.GetTutorInfoForCatalogExtendedCardResponse.displayName = "proto.pb.GetTutorInfoForCatalogExtendedCardResponse";
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.repeatedFields_ = [9, 10, 14, 16, 17, 18, 19];

if (jspb.Message.GENERATE_TO_OBJECT) {
    /**
     * Creates an object representation of this proto suitable for use in Soy templates.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
     * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
     *     for transitional soy proto support: http://goto/soy-param-migration
     * @return {!Object}
     */
    proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.toObject = function (opt_includeInstance) {
        return proto.pb.GetTutorInfoForCatalogExtendedCardResponse.toObject(opt_includeInstance, this);
    };

    /**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Whether to include the JSPB
     *     instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.pb.GetTutorInfoForCatalogExtendedCardResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */
    proto.pb.GetTutorInfoForCatalogExtendedCardResponse.toObject = function (includeInstance, msg) {
        var f,
            obj = {
                isAnonymousProfile: jspb.Message.getFieldWithDefault(msg, 1, false),
                isVerified: jspb.Message.getFieldWithDefault(msg, 2, false),
                name: jspb.Message.getFieldWithDefault(msg, 3, ""),
                surname: jspb.Message.getFieldWithDefault(msg, 4, ""),
                patronymic: jspb.Message.getFieldWithDefault(msg, 5, ""),
                photoLink: jspb.Message.getFieldWithDefault(msg, 6, ""),
                lastLogonTime: (f = msg.getLastLogonTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
                videoCardLink: jspb.Message.getFieldWithDefault(msg, 8, ""),
                subjectsList: jspb.Message.toObjectList(msg.getSubjectsList(), proto.pb.Subject.toObject, includeInstance),
                classFormatsList: jspb.Message.getRepeatedField(msg, 10),
                tutorsPlace: (f = msg.getTutorsPlace()) && proto.pb.Place.toObject(includeInstance, f),
                studentsPlaces: (f = msg.getStudentsPlaces()) && proto.pb.Place.toObject(includeInstance, f),
                totalExperience: jspb.Message.getFieldWithDefault(msg, 13, 0),
                tagsList: jspb.Message.getRepeatedField(msg, 14),
                aboutMeText: jspb.Message.getFieldWithDefault(msg, 15, ""),
                weekDaysTimetablesList: jspb.Message.toObjectList(msg.getWeekDaysTimetablesList(), proto.pb.WeekDayTimetable.toObject, includeInstance),
                educationsList: jspb.Message.toObjectList(msg.getEducationsList(), proto.pb.EducationForCatalogExtentedCard.toObject, includeInstance),
                experienceList: jspb.Message.toObjectList(msg.getExperienceList(), proto.pb.ExperienceInfoForCatalogExtendedCard.toObject, includeInstance),
                certificatesList: jspb.Message.toObjectList(msg.getCertificatesList(), proto.pb.CertificateForCatalogExtendedCard.toObject, includeInstance),
                minCost: jspb.Message.getFieldWithDefault(msg, 20, 0),
                cashback: jspb.Message.getFieldWithDefault(msg, 21, 0),
            };

        if (includeInstance) {
            obj.$jspbMessageInstance = msg;
        }

        return obj;
    };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.pb.GetTutorInfoForCatalogExtendedCardResponse}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.deserializeBinary = function (bytes) {
    var reader = new jspb.BinaryReader(bytes);

    var msg = new proto.pb.GetTutorInfoForCatalogExtendedCardResponse();

    return proto.pb.GetTutorInfoForCatalogExtendedCardResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.pb.GetTutorInfoForCatalogExtendedCardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.pb.GetTutorInfoForCatalogExtendedCardResponse}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.deserializeBinaryFromReader = function (msg, reader) {
    while (reader.nextField()) {
        if (reader.isEndGroup()) {
            break;
        }

        var field = reader.getFieldNumber();

        switch (field) {
            case 1:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setIsAnonymousProfile(value);
                break;
            case 2:
                var value = /** @type {boolean} */ (reader.readBool());

                msg.setIsVerified(value);
                break;
            case 3:
                var value = /** @type {string} */ (reader.readString());

                msg.setName(value);
                break;
            case 4:
                var value = /** @type {string} */ (reader.readString());

                msg.setSurname(value);
                break;
            case 5:
                var value = /** @type {string} */ (reader.readString());

                msg.setPatronymic(value);
                break;
            case 6:
                var value = /** @type {string} */ (reader.readString());

                msg.setPhotoLink(value);
                break;
            case 7:
                var value = new google_protobuf_timestamp_pb.Timestamp();

                reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
                msg.setLastLogonTime(value);
                break;
            case 8:
                var value = /** @type {string} */ (reader.readString());

                msg.setVideoCardLink(value);
                break;
            case 9:
                var value = new proto.pb.Subject();

                reader.readMessage(value, proto.pb.Subject.deserializeBinaryFromReader);
                msg.addSubjects(value);
                break;
            case 10:
                var value = /** @type {string} */ (reader.readString());

                msg.addClassFormats(value);
                break;
            case 11:
                var value = new proto.pb.Place();

                reader.readMessage(value, proto.pb.Place.deserializeBinaryFromReader);
                msg.setTutorsPlace(value);
                break;
            case 12:
                var value = new proto.pb.Place();

                reader.readMessage(value, proto.pb.Place.deserializeBinaryFromReader);
                msg.setStudentsPlaces(value);
                break;
            case 13:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setTotalExperience(value);
                break;
            case 14:
                var value = /** @type {string} */ (reader.readString());

                msg.addTags(value);
                break;
            case 15:
                var value = /** @type {string} */ (reader.readString());

                msg.setAboutMeText(value);
                break;
            case 16:
                var value = new proto.pb.WeekDayTimetable();

                reader.readMessage(value, proto.pb.WeekDayTimetable.deserializeBinaryFromReader);
                msg.addWeekDaysTimetables(value);
                break;
            case 17:
                var value = new proto.pb.EducationForCatalogExtentedCard();

                reader.readMessage(value, proto.pb.EducationForCatalogExtentedCard.deserializeBinaryFromReader);
                msg.addEducations(value);
                break;
            case 18:
                var value = new proto.pb.ExperienceInfoForCatalogExtendedCard();

                reader.readMessage(value, proto.pb.ExperienceInfoForCatalogExtendedCard.deserializeBinaryFromReader);
                msg.addExperience(value);
                break;
            case 19:
                var value = new proto.pb.CertificateForCatalogExtendedCard();

                reader.readMessage(value, proto.pb.CertificateForCatalogExtendedCard.deserializeBinaryFromReader);
                msg.addCertificates(value);
                break;
            case 20:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setMinCost(value);
                break;
            case 21:
                var value = /** @type {number} */ (reader.readInt32());

                msg.setCashback(value);
                break;
            default:
                reader.skipField();
                break;
        }
    }

    return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.serializeBinary = function () {
    var writer = new jspb.BinaryWriter();

    proto.pb.GetTutorInfoForCatalogExtendedCardResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.pb.GetTutorInfoForCatalogExtendedCardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.serializeBinaryToWriter = function (message, writer) {
    var f = undefined;

    f = message.getIsAnonymousProfile();
    if (f) {
        writer.writeBool(1, f);
    }

    f = message.getIsVerified();
    if (f) {
        writer.writeBool(2, f);
    }

    f = message.getName();
    if (f.length > 0) {
        writer.writeString(3, f);
    }

    f = message.getSurname();
    if (f.length > 0) {
        writer.writeString(4, f);
    }

    f = message.getPatronymic();
    if (f.length > 0) {
        writer.writeString(5, f);
    }

    f = message.getPhotoLink();
    if (f.length > 0) {
        writer.writeString(6, f);
    }

    f = message.getLastLogonTime();
    if (f != null) {
        writer.writeMessage(7, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
    }

    f = message.getVideoCardLink();
    if (f.length > 0) {
        writer.writeString(8, f);
    }

    f = message.getSubjectsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(9, f, proto.pb.Subject.serializeBinaryToWriter);
    }

    f = message.getClassFormatsList();
    if (f.length > 0) {
        writer.writeRepeatedString(10, f);
    }

    f = message.getTutorsPlace();
    if (f != null) {
        writer.writeMessage(11, f, proto.pb.Place.serializeBinaryToWriter);
    }

    f = message.getStudentsPlaces();
    if (f != null) {
        writer.writeMessage(12, f, proto.pb.Place.serializeBinaryToWriter);
    }

    f = message.getTotalExperience();
    if (f !== 0) {
        writer.writeInt32(13, f);
    }

    f = message.getTagsList();
    if (f.length > 0) {
        writer.writeRepeatedString(14, f);
    }

    f = message.getAboutMeText();
    if (f.length > 0) {
        writer.writeString(15, f);
    }

    f = message.getWeekDaysTimetablesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(16, f, proto.pb.WeekDayTimetable.serializeBinaryToWriter);
    }

    f = message.getEducationsList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(17, f, proto.pb.EducationForCatalogExtentedCard.serializeBinaryToWriter);
    }

    f = message.getExperienceList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(18, f, proto.pb.ExperienceInfoForCatalogExtendedCard.serializeBinaryToWriter);
    }

    f = message.getCertificatesList();
    if (f.length > 0) {
        writer.writeRepeatedMessage(19, f, proto.pb.CertificateForCatalogExtendedCard.serializeBinaryToWriter);
    }

    f = message.getMinCost();
    if (f !== 0) {
        writer.writeInt32(20, f);
    }

    f = message.getCashback();
    if (f !== 0) {
        writer.writeInt32(21, f);
    }
};

/**
 * optional bool is_anonymous_profile = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getIsAnonymousProfile = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};

/** @param {boolean} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setIsAnonymousProfile = function (value) {
    jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional bool is_verified = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getIsVerified = function () {
    return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};

/** @param {boolean} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setIsVerified = function (value) {
    jspb.Message.setProto3BooleanField(this, 2, value);
};

/**
 * optional string name = 3;
 * @return {string}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getName = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/** @param {string} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setName = function (value) {
    jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string surname = 4;
 * @return {string}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getSurname = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/** @param {string} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setSurname = function (value) {
    jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string patronymic = 5;
 * @return {string}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getPatronymic = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/** @param {string} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setPatronymic = function (value) {
    jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional string photo_link = 6;
 * @return {string}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getPhotoLink = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/** @param {string} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setPhotoLink = function (value) {
    jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional google.protobuf.Timestamp last_logon_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getLastLogonTime = function () {
    return /** @type{?proto.google.protobuf.Timestamp} */ (jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};

/** @param {?proto.google.protobuf.Timestamp|undefined} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setLastLogonTime = function (value) {
    jspb.Message.setWrapperField(this, 7, value);
};

proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.clearLastLogonTime = function () {
    this.setLastLogonTime(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.hasLastLogonTime = function () {
    return jspb.Message.getField(this, 7) != null;
};

/**
 * optional string video_card_link = 8;
 * @return {string}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getVideoCardLink = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};

/** @param {string} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setVideoCardLink = function (value) {
    jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * repeated Subject subjects = 9;
 * @return {!Array<!proto.pb.Subject>}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getSubjectsList = function () {
    return /** @type{!Array<!proto.pb.Subject>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.Subject, 9));
};

/** @param {!Array<!proto.pb.Subject>} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setSubjectsList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 9, value);
};

/**
 * @param {!proto.pb.Subject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.Subject}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.addSubjects = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.pb.Subject, opt_index);
};

proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.clearSubjectsList = function () {
    this.setSubjectsList([]);
};

/**
 * repeated string class_formats = 10;
 * @return {!Array<string>}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getClassFormatsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};

/** @param {!Array<string>} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setClassFormatsList = function (value) {
    jspb.Message.setField(this, 10, value || []);
};

/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.addClassFormats = function (value, opt_index) {
    jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};

proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.clearClassFormatsList = function () {
    this.setClassFormatsList([]);
};

/**
 * optional Place tutors_place = 11;
 * @return {?proto.pb.Place}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getTutorsPlace = function () {
    return /** @type{?proto.pb.Place} */ (jspb.Message.getWrapperField(this, proto.pb.Place, 11));
};

/** @param {?proto.pb.Place|undefined} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setTutorsPlace = function (value) {
    jspb.Message.setWrapperField(this, 11, value);
};

proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.clearTutorsPlace = function () {
    this.setTutorsPlace(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.hasTutorsPlace = function () {
    return jspb.Message.getField(this, 11) != null;
};

/**
 * optional Place students_places = 12;
 * @return {?proto.pb.Place}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getStudentsPlaces = function () {
    return /** @type{?proto.pb.Place} */ (jspb.Message.getWrapperField(this, proto.pb.Place, 12));
};

/** @param {?proto.pb.Place|undefined} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setStudentsPlaces = function (value) {
    jspb.Message.setWrapperField(this, 12, value);
};

proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.clearStudentsPlaces = function () {
    this.setStudentsPlaces(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.hasStudentsPlaces = function () {
    return jspb.Message.getField(this, 12) != null;
};

/**
 * optional int32 total_experience = 13;
 * @return {number}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getTotalExperience = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};

/** @param {number} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setTotalExperience = function (value) {
    jspb.Message.setProto3IntField(this, 13, value);
};

/**
 * repeated string tags = 14;
 * @return {!Array<string>}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getTagsList = function () {
    return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};

/** @param {!Array<string>} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setTagsList = function (value) {
    jspb.Message.setField(this, 14, value || []);
};

/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.addTags = function (value, opt_index) {
    jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};

proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.clearTagsList = function () {
    this.setTagsList([]);
};

/**
 * optional string about_me_text = 15;
 * @return {string}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getAboutMeText = function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};

/** @param {string} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setAboutMeText = function (value) {
    jspb.Message.setProto3StringField(this, 15, value);
};

/**
 * repeated WeekDayTimetable week_days_timetables = 16;
 * @return {!Array<!proto.pb.WeekDayTimetable>}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getWeekDaysTimetablesList = function () {
    return /** @type{!Array<!proto.pb.WeekDayTimetable>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.WeekDayTimetable, 16));
};

/** @param {!Array<!proto.pb.WeekDayTimetable>} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setWeekDaysTimetablesList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 16, value);
};

/**
 * @param {!proto.pb.WeekDayTimetable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.WeekDayTimetable}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.addWeekDaysTimetables = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.pb.WeekDayTimetable, opt_index);
};

proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.clearWeekDaysTimetablesList = function () {
    this.setWeekDaysTimetablesList([]);
};

/**
 * repeated EducationForCatalogExtentedCard educations = 17;
 * @return {!Array<!proto.pb.EducationForCatalogExtentedCard>}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getEducationsList = function () {
    return /** @type{!Array<!proto.pb.EducationForCatalogExtentedCard>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.EducationForCatalogExtentedCard, 17));
};

/** @param {!Array<!proto.pb.EducationForCatalogExtentedCard>} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setEducationsList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 17, value);
};

/**
 * @param {!proto.pb.EducationForCatalogExtentedCard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.EducationForCatalogExtentedCard}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.addEducations = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.pb.EducationForCatalogExtentedCard, opt_index);
};

proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.clearEducationsList = function () {
    this.setEducationsList([]);
};

/**
 * repeated ExperienceInfoForCatalogExtendedCard experience = 18;
 * @return {!Array<!proto.pb.ExperienceInfoForCatalogExtendedCard>}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getExperienceList = function () {
    return /** @type{!Array<!proto.pb.ExperienceInfoForCatalogExtendedCard>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.ExperienceInfoForCatalogExtendedCard, 18));
};

/** @param {!Array<!proto.pb.ExperienceInfoForCatalogExtendedCard>} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setExperienceList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 18, value);
};

/**
 * @param {!proto.pb.ExperienceInfoForCatalogExtendedCard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.ExperienceInfoForCatalogExtendedCard}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.addExperience = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.pb.ExperienceInfoForCatalogExtendedCard, opt_index);
};

proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.clearExperienceList = function () {
    this.setExperienceList([]);
};

/**
 * repeated CertificateForCatalogExtendedCard certificates = 19;
 * @return {!Array<!proto.pb.CertificateForCatalogExtendedCard>}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getCertificatesList = function () {
    return /** @type{!Array<!proto.pb.CertificateForCatalogExtendedCard>} */ (jspb.Message.getRepeatedWrapperField(this, proto.pb.CertificateForCatalogExtendedCard, 19));
};

/** @param {!Array<!proto.pb.CertificateForCatalogExtendedCard>} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setCertificatesList = function (value) {
    jspb.Message.setRepeatedWrapperField(this, 19, value);
};

/**
 * @param {!proto.pb.CertificateForCatalogExtendedCard=} opt_value
 * @param {number=} opt_index
 * @return {!proto.pb.CertificateForCatalogExtendedCard}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.addCertificates = function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.pb.CertificateForCatalogExtendedCard, opt_index);
};

proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.clearCertificatesList = function () {
    this.setCertificatesList([]);
};

/**
 * optional int32 min_cost = 20;
 * @return {number}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getMinCost = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};

/** @param {number} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setMinCost = function (value) {
    jspb.Message.setProto3IntField(this, 20, value);
};

/**
 * optional int32 cashback = 21;
 * @return {number}
 */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.getCashback = function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};

/** @param {number} value */
proto.pb.GetTutorInfoForCatalogExtendedCardResponse.prototype.setCashback = function (value) {
    jspb.Message.setProto3IntField(this, 21, value);
};

goog.object.extend(exports, proto.pb);
