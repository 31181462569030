export const chatBox = {
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "20px",
    borderColor: "#EEEEF0",
    width: "641px",
    height: "620px",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    // alignItems: 'stretch',
    // overflow: 'hidden',
    minHeight: "650px",
    padding: "30px",
};

export const dateLabel = {
    color: "var(--text-dark-grey, #77778A)",
    textAlign: "center",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    paddingTop: "20px",
};

export const MessageGroup = {
    display: "flex",
    justifyContent: "center",
};
