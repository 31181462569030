import React, { memo } from "react";
import * as themes from "./SearchFilterThemes";
import TextFilter from "components/UI/TextFilter";
import CatalogStore from "store/CatalogStore";
import { observer } from "mobx-react-lite";

const SearchFilter = observer(({ children, ...props }) => {
    const clearSearch = () => {
        CatalogStore.SetSearchDebounced("");
    };

    const handleSearch = (e) => {
        CatalogStore.SetSearchDebounced(e.target.value);
    };

    return (
        <TextFilter
            valueToBind={CatalogStore.search}
            handleFunc={handleSearch}
            clearFunc={clearSearch}
            variant={"outlined"}
            placeholder={"Искать по слову"}
            startAdornment={true}
            endAdornment={true}
            themeWrapper={themes.FiltersSearcherTheme}
        ></TextFilter>
    );
});

export default memo(SearchFilter);
