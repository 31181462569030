import * as React from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./ChatListsTabsThemes";
import * as textThemes from "themes/TextThemes";
import { Stack, Box, Typography } from "@mui/material";
import { useDraggable } from "react-use-draggable-scroll";
import "swiper/css/pagination";
import "./styles.module.css";
import "./swiper.min.module.css";
import { memo } from "react";

const ChatListsTabs = observer(({ children, ...props }) => {
    const refScrollEllemnt = React.useRef(null);

    const { events } = useDraggable(refScrollEllemnt);

    const [indexTab, setIndexTab] = React.useState(0);

    return (
        <Box
            sx={themes.ChatListsTabs}
            ref={refScrollEllemnt}
            {...events}
        >
            <Box sx={themes.ChatListsTabsContent}>
                {props.folders?.map((value, index) => {
                    return (
                        <Stack
                            key={index}
                            sx={themes.TabContainer(index === indexTab)}
                            onClick={(e) => {
                                setIndexTab(index);
                                const scrollTop = e.currentTarget.offsetLeft - refScrollEllemnt.current.clientWidth / 2 + e.currentTarget.clientWidth / 2;

                                refScrollEllemnt.current.scrollTo({
                                    top: 0,
                                    left: scrollTop,
                                    behavior: "smooth",
                                });
                                console.log(index);
                                props.pageChange(index);
                            }}
                        >
                            <Box sx={themes.TabBox(index === indexTab)}>
                                <Typography sx={themes.TabText(index === indexTab)}>{value.name}</Typography>
                                {value.unread_messages > 0 && (
                                    <Box sx={themes.UreadMessages}>
                                        <Typography sx={textThemes.Caption2White}>{value.unread_messages}</Typography>
                                    </Box>
                                )}
                            </Box>
                        </Stack>
                    );
                })}
            </Box>
        </Box>
    );
});

export default memo(ChatListsTabs);
