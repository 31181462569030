import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import { Typography, Button, List, Box, CircularProgress } from "@mui/material";
import SingleNotification from "components/NotificationsBodyBlock/NotificationsPopper/NotificationsPopperContent/SingleNotification/SingleNotification";
import NotificationsStore from "store/NotificationsStore";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import * as textThemes from "themes/TextThemes";
import * as themes from "./NotificationsBodyBlockThemes";
import { useTranslation } from "react-i18next";
import EmptyNotifications from "./EmptyNotifications/EmptyNotifications";
import { ThemeProvider } from "@mui/material/styles";

const NotificationsBodyBlock = observer(() => {
    const { t, i18n } = useTranslation();

    const handleReadAllNotifications = async () => {
        await NotificationsStore.ReadAllNotifications();
    };

    return (
        <ScrollToTop>
            <Box sx={themes.Notifications}>
                <Box sx={themes.NotificationsContent}>
                    <Box sx={themes.TitleBlock}>
                        <Typography sx={textThemes.HeadlineH3Black}>{t("notifications.notifications")}</Typography>
                        <Button
                            onClick={handleReadAllNotifications}
                            disableRipple
                            disabled={NotificationsStore.notificationsFiltered.length === 0}
                            sx={themes.ReadAllButton}
                        >
                            {t("notifications.readAll")}
                        </Button>
                    </Box>
                    {!NotificationsStore.loadingNotificationsData ? (
                        <Box sx={themes.NotificationsPaper(NotificationsStore.notificationsFiltered.length > 0)}>
                            {NotificationsStore.notificationsFiltered.length > 0 ? (
                                <List sx={themes.NotificationsList}>
                                    {NotificationsStore.notificationsFiltered.map((item) => (
                                        <SingleNotification
                                            key={item.ID}
                                            notification={item}
                                        />
                                    ))}
                                </List>
                            ) : (
                                <EmptyNotifications />
                            )}
                        </Box>
                    ) : (
                        <ThemeProvider theme={themes.CircularProgressTheme}>
                            <Box sx={themes.progressContainerStyle}>
                                <CircularProgress />
                            </Box>
                        </ThemeProvider>
                    )}
                </Box>
            </Box>
        </ScrollToTop>
    );
});

export default memo(NotificationsBodyBlock);
