export const Gender = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "150%",
    color: "#67677A",
    marginBottom: "20px",
};

export const Age = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "150%",
    color: "#67677A",
    marginBottom: "20px",
    marginTop: "40px",
};
