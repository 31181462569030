//Где удобно заниматься?
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import SCheckboxGroup from "components/UI/CheckboxGroup";
import SBButtonGroup from "components/UI/ButtonGroup";
import store from "store/RequestStore";
import RequestStore from "store/RequestStore";

import StepHiddenButtonWrapper from "components/OrdersBodyBlock/RenewOrder/StepHiddenButtonWrapper";
import RequestNullAlert from "./RequestNullAlert";
import { Box } from "@mui/material";

const Step6 = () => {
    const [alert, setAlert] = useState(0);

    const [stepData, setStepData] = React.useState(RequestStore.storage[store.activeStepIndex].substeps[store.subStepIndex].value);

    let sumWhere = "";

    const handleChoice = () => {
        if (stepData.where1.selected === true) {
            sumWhere += "Онлайн";
        }

        if (stepData.where2.selected === true && sumWhere !== "") {
            sumWhere += ", у меня";
        } else if (stepData.where2.selected === true && sumWhere === "") {
            sumWhere += "У меня";
        }

        if (stepData.where3.selected === true && sumWhere !== "") {
            sumWhere += ", у репетитора";
        } else if (stepData.where3.selected === true && sumWhere === "") {
            sumWhere += "У репетитора";
        }

        if (sumWhere) {
            store.setStep(3, sumWhere);
            store.setStepValue(3, stepData);
        }
    };

    const handleIncrement = () => {
        if (sumWhere) {
            store.incrementStepIndex();
        } else {
            setAlert(1);
        }
    };

    const handleNext = () => {
        handleChoice();
        handleIncrement();
    };

    return (
        <Box>
            <SCheckboxGroup
                title="Можете выбрать несколько вариантов"
                varstate={stepData}
                funcstate={setStepData}
            />
            <StepHiddenButtonWrapper submitRenew={handleChoice}>
                <SBButtonGroup
                    handleNext={handleNext}
                    handleBack={() => store.decrementStepIndex()}
                    activeStep={store.activeStepIndex}
                    steps={store.storage}
                />
                {alert === 1 ? <RequestNullAlert>Чтобы перейти к следующему вопросу, выберите хотя бы один из вариантов</RequestNullAlert> : null}
            </StepHiddenButtonWrapper>
        </Box>
    );
};

export default observer(Step6);
