import React from "react";
import { observer } from "mobx-react-lite";
import orderResponseStore from "store/OrderResponseStore";
import OrderResponseCountSpDropMenuWrapper from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseCountSpDropMenuWrapper/OrderResponseCountSpDropMenuWrapper";
import { StyledRejectedDivider, StyledRejectedText } from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponeTutorsResponses/OrderResponeTutorsResponsesStyled";
import OrdersResponseDismissModal from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_OrdersResponseDismissModal/OrdersResponseDismissModal";
import CompainModal from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_CompainModal/CompainModal";
import OffersCards from "components/OrderResponse/OffersCard/OffersCards";
import { useState } from "react";
import ZeroOffersCard from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_ZeroCard/ZeroOffersCard";

const OrderResponseTutorsOffers = observer(({ page }) => {
    const [switchResp, setSwitchResp] = useState(false);

    const activeTutors = orderResponseStore.responseTutors.filter((el) => el.status === "active");

    const awaitingResponse = orderResponseStore.responseTutors.filter((el) => el.status === "awaitingResponse");

    const switchToggle = () => {
        setSwitchResp(!switchResp);
    };

    return (
        <>
            {activeTutors.length === 0 && awaitingResponse.length === 0 ? null : (
                <OrderResponseCountSpDropMenuWrapper
                    value={activeTutors.length + awaitingResponse.length}
                    page={page}
                />
            )}

            {activeTutors.length === 0 && awaitingResponse.length === 0 ? (
                <ZeroOffersCard />
            ) : (
                <>
                    {activeTutors.map((el) => (
                        <OffersCards
                            key={el.id}
                            tutorsInfo={el}
                            switchToggle={switchToggle}
                            switchResp={switchResp}
                        />
                    ))}
                    {awaitingResponse.length > 0 && (
                        <StyledRejectedDivider>
                            <StyledRejectedText>Ожидают ответа</StyledRejectedText>
                        </StyledRejectedDivider>
                    )}
                    {awaitingResponse.map((el) => (
                        <OffersCards
                            key={el.id}
                            tutorsInfo={el}
                            switchToggle={switchToggle}
                            switchResp={switchResp}
                        />
                    ))}
                </>
            )}

            {orderResponseStore.isDismissClicked && <OrdersResponseDismissModal />}
            {orderResponseStore.isComplainClicked && <CompainModal />}
        </>
    );
});

export default OrderResponseTutorsOffers;
