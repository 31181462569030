import { createTheme } from "@mui/material";

export const PlatinumIcon = {
    position: "absolute",
    left: "220px",
    bottom: "40px",
};

export const LevelRow = {
    position: "relative",
};

export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const WhiteContainer = {
    padding: "20px 20px 0px 20px",
    width: "249px",
    height: "119px",
    borderRadius: "20px",
    backgroundColor: "white",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.05)",
    cursor: "pointer",
    "&:hover": {
        transform: "scale(1.02)",
        transition: "all 0.2s ease-out",
    },
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const theme = createTheme({
    palette: {
        primary: {
            main: "#6212FF",
        },
    },
    components: {
        MuiLinearProgress: {
            styleOverrides: {
                bar: {
                    borderRadius: "9px",
                },
                root: {
                    backgroundColor: "rgba(98, 18, 255, 0.10)",
                },
            },
        },
    },
});

export const theme2 = createTheme({
    palette: {
        primary: {
            main: "#6212FF",
        },
    },
    components: {
        MuiLinearProgress: {
            styleOverrides: {
                bar: {
                    borderRadius: "9px",
                    background: "linear-gradient(79deg, #FF7612 -15.3%, #E816EC 145.68%)",
                },
                root: {
                    backgroundColor: "rgba(98, 18, 255, 0.10)",
                },
            },
        },
    },
});

export const pointsOutOfContainer = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const ProgressContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const LinearProgress = {
    borderRadius: "9px",
    height: "6px",
};
