import { makeAutoObservable } from "mobx";
import RequestService from "API/services/RequestService";
const requestService = new RequestService();

import RomanAvatar from "assets/Avatars/Roma.jpg";
import RomanMiniAvatar from "assets/Avatars/RomaMini.png";
import AlyonaAvatar from "assets/Avatars/Alyona.jpg";
import DamirAvatar from "assets/Avatars/Damir.jpg";
import AlyonaVideo from "assets/MainPage/MOV/Отзыв Алёна.mp4";
import RomanVideo from "assets/MainPage/MOV/Отзыв Рома.mp4";
import DamirVideo from "assets/MainPage/MOV/Отзыв Дамир.MOV";

import Tutor1 from "assets/PromoTutorAssets/PNG/TutorV1.png";
import Tutor2 from "assets/PromoTutorAssets/PNG/TutorV2.png";
import Tutor3 from "assets/PromoTutorAssets/PNG/TutorV3.png";

class PromoTutorStore {
    optionsRate = [];
    optionRate = null;

    optionsLesson = [];
    optionLesson = null;
    activeTutorPromo = null;

    constructor() {
        this.Init();
        makeAutoObservable(this);
    }

    SetActiveTutorPromo(value) {
        this.activeTutorPromo = value;
        console.log("Set Active Tutor command");
        console.log(this.activeTutorPromo.tutorVideoName);
    }

    Init() {
        this.LoadFilters();

        this.tutorsPromoFeedbackList = [
            {
                ID: 0,
                tutorName: "Кристина Трубак",
                tutorDescribe: "SMM, опыт 4 года",
                tutorQuote: "«Ко мне приходят ученики, которые хотят самостоятельно решить проблемы с продвижением своего бизнеса в соцсетях».",
                tutorPhoto: AlyonaAvatar,
                tutorPhoto2: Tutor1,
                tutorPreviewPhoto: AlyonaAvatar,
                tutorVideo: AlyonaVideo,
                tutorVideo2: "https://www.dropbox.com/scl/fi/nxcszvlm5ywuf8g93mg5q/.mp4?rlkey=jvsbshcsw9xe40l9i9fevswxj&dl=0",
                tutorVideoName: "ОтзывАлена",
            },
            {
                ID: 1,
                tutorName: "Елена Ермашева",
                tutorDescribe: "Математика, высшая математика, опыт 7 лет",
                tutorQuote: "«Преподаю математику из дома и провожу больше времени с ребёнком».",
                tutorPhoto: RomanAvatar,
                tutorPhoto2: Tutor2,
                tutorPreviewPhoto: RomanMiniAvatar,
                tutorVideo: RomanVideo,
                tutorVideo2: "https://www.dropbox.com/scl/fi/yitvmfin2hvex6v30dza4/.mp4?rlkey=wmox3mkytnxqqm560bc4r5rr9&dl=0",
                tutorVideoName: "ОтзывРома",
            },
            {
                ID: 2,
                tutorName: "Анна Ковалёва",
                tutorDescribe: "Русский язык, опыт 30 лет",
                tutorQuote: "«У меня забитое расписание и высокий рейтинг, благодаря которому комиссия совсем маленькая».",
                tutorPhoto: DamirAvatar,
                tutorPhoto2: Tutor3,
                tutorPreviewPhoto: DamirAvatar,
                tutorVideo: DamirVideo,
                tutorVideo2: "https://www.dropbox.com/scl/fi/l4fuhs6bkopcjwbq8g0k8/.mp4?rlkey=2h33llfomgrn1v3g0s2a2to5j&dl=0",
                tutorVideoName: "ОтзывДамир",
            },
        ];

        this.activeTutorPromo = this.tutorsPromoFeedbackList[0];
    }

    SetRate(value) {
        console.log(value);
        this.optionRate = value;
        //this.FilterTeachersArr();
    }

    SetLesson(value) {
        console.log(value);
        this.optionLesson = value;
        //this.FilterTeachersArr();
    }
    LoadFilters() {
        this.optionsRate = [
            { ID: 0, rate: 1000 },
            { ID: 1, rate: 1200 },
            { ID: 2, rate: 1400 },
            { ID: 3, rate: 1600 },
            { ID: 4, rate: 1800 },
            { ID: 5, rate: 2000 },
            { ID: 6, rate: 2200 },
            { ID: 7, rate: 2400 },
            { ID: 8, rate: 2600 },
            { ID: 9, rate: 2800 },
            { ID: 10, rate: 3000 },
            { ID: 11, rate: 3200 },
            { ID: 12, rate: 3400 },
        ];

        this.optionsLesson = [
            { ID: 0, lesson: 6 },
            { ID: 1, lesson: 7 },
            { ID: 2, lesson: 8 },
            { ID: 3, lesson: 9 },
            { ID: 4, lesson: 10 },
            { ID: 5, lesson: 11 },
            { ID: 6, lesson: 12 },
            { ID: 7, lesson: 13 },
            { ID: 8, lesson: 14 },
            { ID: 9, lesson: 15 },
        ];
    }
}

export default new PromoTutorStore();

// {ID: 0, lesson: 700},
// {ID: 1, lesson: 800},
// {ID: 2, lesson: 900},
// {ID: 3, lesson: 1100},
// {ID: 4, lesson: 1200},
// {ID: 5, lesson: 1300},
// {ID: 6, lesson: 1400},
// {ID: 7, lesson: 1500},
// {ID: 8, lesson: 1600},
// {ID: 9, lesson: 1700},
// {ID: 10, lesson: 1800},
// {ID: 11, lesson: 1900},
// {ID: 12, lesson: 2000},
