import React from "react";
import * as theme from "./OrderResponseTimeStyled";

const OrderResponseTime = ({ props }) => {
    const now = new Date();

    const date = new Date(props);

    const isToday = now.toDateString() === date.toDateString();

    const isYesterday = new Date(now.setDate(now.getDate() - 1)).toDateString() === date.toDateString();

    let formattedDate;

    if (isToday) {
        formattedDate = "сегодня";
    } else if (isYesterday) {
        formattedDate = "вчера";
    } else {
        const dateOptions = {
            day: "numeric",
            month: "long",
        };

        const RUDate = new Intl.DateTimeFormat("ru", dateOptions);

        formattedDate = RUDate.format(date);
    }

    let formattedTime = "";

    if (isToday || isYesterday) {
        const timeOptions = {
            hour: "numeric",
            minute: "numeric",
        };

        const RUTime = new Intl.DateTimeFormat("ru", timeOptions);

        formattedTime = ", " + RUTime.format(date);
    }

    return (
        <>
            <theme.StyledOrderResponseTime>&bull; {" " + formattedDate + formattedTime}</theme.StyledOrderResponseTime>
        </>
    );
};

export default OrderResponseTime;
