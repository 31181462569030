export const ChatIcon = () => {
    return (
        <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.96512 3C7.09551 3 5.57157 3.47134 4.51971 4.53105C3.46785 5.59076 3 7.12608 3 9.00964V13.4612C3 15.7446 3.44746 17.3304 4.55192 18.304C5.63449 19.2584 7.18595 19.4709 8.96512 19.4709H9.40251C9.4049 19.4712 9.4125 19.4723 9.42529 19.4758C9.44703 19.4817 9.47412 19.4918 9.50254 19.506C9.53103 19.5202 9.55422 19.5353 9.5702 19.5478C9.57668 19.5529 9.58066 19.5565 9.58263 19.5584L9.58372 19.5599C9.58372 19.5599 10.3958 20.3058 11.2162 21.1071C11.5304 21.414 11.7922 21.6454 11.9957 21.8141C12.2946 22.062 12.7054 22.062 13.0043 21.8141C13.2078 21.6454 13.4696 21.414 13.7838 21.1071C14.6042 20.3058 15.4137 19.5633 15.4137 19.5633L15.4147 19.5621C15.4592 19.5039 15.5298 19.4709 15.593 19.4709H16.0349C17.9045 19.4709 19.4284 18.9995 20.4803 17.9398C21.5322 16.8801 22 15.3448 22 13.4612V9.00964C22 7.12608 21.5322 5.59076 20.4803 4.53105C19.4284 3.47134 17.9045 3 16.0349 3H8.96512ZM8.39189 12.8606C9.10091 12.8606 9.67568 12.2816 9.67568 11.5673C9.67568 10.8529 9.10091 10.2739 8.39189 10.2739C7.68288 10.2739 7.10811 10.8529 7.10811 11.5673C7.10811 12.2816 7.68288 12.8606 8.39189 12.8606ZM13.7838 11.5673C13.7838 12.2816 13.209 12.8606 12.5 12.8606C11.791 12.8606 11.2162 12.2816 11.2162 11.5673C11.2162 10.8529 11.791 10.2739 12.5 10.2739C13.209 10.2739 13.7838 10.8529 13.7838 11.5673ZM16.6081 12.8606C17.3171 12.8606 17.8919 12.2816 17.8919 11.5673C17.8919 10.8529 17.3171 10.2739 16.6081 10.2739C15.8991 10.2739 15.3243 10.8529 15.3243 11.5673C15.3243 12.2816 15.8991 12.8606 16.6081 12.8606Z"
                fill="#9E9EB0"
            />
        </svg>
    );
};
