const PurpleCheckBoxDefault = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <g clipPath="url(#clip0_3581_78228)">
                <path
                    d="M0.5 2.5C0.5 1.67157 1.17157 1 2 1H14C14.8284 1 15.5 1.67157 15.5 2.5V14.5C15.5 15.3284 14.8284 16 14 16H2C1.17157 16 0.5 15.3284 0.5 14.5V2.5Z"
                    stroke="#6212FF"
                />
            </g>
            <defs>
                <clipPath id="clip0_3581_78228">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default PurpleCheckBoxDefault;
