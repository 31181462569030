import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./FavouritesTopBlockThemes";
import Typography from "@mui/material/Typography";
import ChipBlock from "./ChipBlock/ChipBlock";

const FavouritesTopBlock = observer(({ children, ...props }) => {
    return (
        <Box sx={themes.favouritesTopBlockContainer}>
            <Typography sx={themes.headerStyle}>Избранное</Typography>
            <ChipBlock></ChipBlock>
        </Box>
    );
});

export default memo(FavouritesTopBlock);
