export const titleBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: 8,
};

export const titleStyle = {
    color: "var(--text-black, #222)",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
    cursor: "pointer",
};

export const personalOrderContainerStyle = {
    display: "flex",
    padding: "2px 10px 4px 10px",
    width: "fit-content",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "6px",
    background: "rgba(25, 178, 12, 0.12)",
};

export const personalOrderTextStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    cursor: "pointer",
};
