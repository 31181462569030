const ProfileLinksPlusIcon = function (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
        >
            <g clipPath="url(#clip0_5426_111229)">
                <circle
                    cx="16"
                    cy="16"
                    r="16"
                    fill="#6212FF"
                />
                <path
                    d="M16.75 10C16.75 9.58579 16.4142 9.25 16 9.25C15.5858 9.25 15.25 9.58579 15.25 10V15.25L10 15.25C9.58579 15.25 9.25 15.5858 9.25 16C9.25 16.4142 9.58579 16.75 10 16.75L15.25 16.75V22C15.25 22.4142 15.5858 22.75 16 22.75C16.4142 22.75 16.75 22.4142 16.75 22V16.75L22 16.75C22.4142 16.75 22.75 16.4142 22.75 16C22.75 15.5858 22.4142 15.25 22 15.25L16.75 15.25V10Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_5426_111229">
                    <rect
                        width="32"
                        height="32"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ProfileLinksPlusIcon;
