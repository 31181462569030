import Photo from "assets/PAAssets/PNG/photo.png";
import { makeAutoObservable } from "mobx";
class PaymentStore {
    paymentInfo = {
        tutor: "Александра А.",
        photo: Photo,
        reviews: 200,
        rating: 4.9,
        date: new Date(2024, 5, 3, 18, 0),
        classPrice: 1500,
        creditCashback: 34,
        debitCashback: 14,
        cashbackVariant: "add", //'subtract'
        totalPrice: 1500,
        subject: "Английский язык",
        type: "class", // "paperwork"
    };

    paymentCards = [
        {
            id: 1,
            number: "4324432443244324",
            expireDate: "10 / 28",
            cvc: "356",
        },
        {
            id: 2,
            number: "5324432443244324",
            expireDate: "10 / 28",
            cvc: "356",
        },
    ];

    temporaryPromocode = null;
    promocodeActivated = false;
    promocodeEnterAttempts = 3;

    cardNumber = null;
    cvc = null;
    expireDate = null;

    promocodes = ["r232ew312frxer43", "231erwfre43243vf", "98ved9vdfko021fr", "8jevjiej12321ine"];

    paymentMethod = "newCard";

    selectedPaymentMethod = null;

    agreeWithRules = true;

    saveCard = true;

    qrUrl = "https://study-buddy.ru/";

    previousPageRoute = null;

    openClassPaid = false;

    formattedDate = this.getFormattedDate(this.paymentInfo.date);
    formattedTime = this.getFormattedTime(this.paymentInfo.date);

    showPaymentInfo = false;

    setShowPaymentInfo(value) {
        this.showPaymentInfo = value;
    }
    handleOpenClassPaid() {
        this.openClassPaid = true;
    }

    handleDiscardClassPaid() {
        this.openClassPaid = false;
    }

    setPreviousPageRoute(value) {
        this.previousPageRoute = value;
    }
    setSaveCard(event) {
        this.saveCard = event.target.checked;
    }

    changePaymentMethod(value) {
        this.selectPaymentMethod(value);
    }

    setAgreeWithRules(event) {
        this.agreeWithRules = event.target.checked;
    }

    selectPaymentMethod(value) {
        this.selectedPaymentMethod = value;
    }
    setCardNumber(value) {
        this.cardNumber = value;
    }
    setCVC(value) {
        this.cvc = value;
    }

    setExpireDate(value) {
        this.expireDate = value;
    }

    setPaymentMethod(value) {
        this.paymentMethod = value;
    }

    calculateTotal() {
        let total = this.paymentInfo.classPrice;

        if (this.paymentInfo.debitCashback !== null && this.paymentInfo.cashbackVariant === "subtract") {
            total = total - this.paymentInfo.debitCashback;
        } else if (this.promocodeActivated === true) {
            total = total - this.calculateDiscount();
        }

        return total;
    }

    calculateDiscount() {
        return this.paymentInfo.classPrice * 0.1;
    }

    reducePromocodeEnterAttempts() {
        this.promocodeEnterAttempts = this.promocodeEnterAttempts - 1;
    }
    setTemporaryPromocode(value) {
        this.temporaryPromocode = value;
    }

    setPromocodeActivated(value) {
        this.promocodeActivated = value;
    }

    setCashbackVariant(value) {
        this.paymentInfo.cashbackVariant = value;
    }

    handleChangeCVC(e) {
        const isCVC = (str) => /^[0-9]{0,3}$/.test(str);

        const { value } = e.target;

        if (isCVC(value)) {
            this.setCVC(value);
        }
    }

    handleChangeExpirationDate(e) {
        const { value } = e.target;

        const formattedValue = value.replace(/\D/g, "").slice(0, 4);

        let formattedOutput = "";

        for (let i = 0; i < formattedValue.length; i++) {
            if (i === 2 && formattedValue.length > 2) {
                formattedOutput += "/" + formattedValue[i];
            } else {
                formattedOutput += formattedValue[i];
            }
        }

        this.setExpireDate(formattedOutput);
    }

    handleChangeCardNumber(e) {
        let input = e.target.value.replace(/\D/g, "");

        input = input.replace(/(\d{4})/g, "$1 ").trim();
        input = input.slice(0, 19); // 16 digits + 3 spaces
        this.setCardNumber(input);
    }

    getFormattedDate(date) {
        const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];

        const days = ["воскресенье", "понедельник", "вторник", "среда", "четверг", "пятница", "суббота"];

        const day = date.getDate();

        const month = months[date.getMonth()];

        const dayOfWeek = days[date.getDay()];

        return `${day} ${month} (${dayOfWeek})`;
    }

    getFormattedTime(date) {
        const hours = date.getHours();

        const minutes = date.getMinutes();

        // Добавляем ведущий ноль для минут и часов, если необходимо
        const formattedHours = hours.toString().padStart(2, "0");

        const formattedMinutes = minutes.toString().padStart(2, "0");

        return `${formattedHours}:${formattedMinutes}`;
    }

    debounce(callback, delay) {
        return (...args) => {
            if (this.timerID) {
                clearTimeout(this.timerID);
            }

            this.timerID = setTimeout(() => {
                callback.call(this, ...args);
            }, delay);
        };
    }

    Init() {
        this.Load();
    }

    Load() {}

    constructor() {
        this.Init();
        makeAutoObservable(this);
    }
}

export default new PaymentStore();
