const ArrowIcon = function (props) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 5.75C0.585786 5.75 0.25 6.08579 0.25 6.5C0.25 6.91421 0.585786 7.25 1 7.25L1 5.75ZM13.5303 7.03033C13.8232 6.73744 13.8232 6.26256 13.5303 5.96967L8.75736 1.1967C8.46447 0.903805 7.98959 0.903805 7.6967 1.1967C7.40381 1.48959 7.40381 1.96447 7.6967 2.25736L11.9393 6.5L7.6967 10.7426C7.40381 11.0355 7.40381 11.5104 7.6967 11.8033C7.98959 12.0962 8.46447 12.0962 8.75736 11.8033L13.5303 7.03033ZM1 7.25L13 7.25L13 5.75L1 5.75L1 7.25Z"
                fill="#222"
            />
        </svg>
    );
};

export default ArrowIcon;
