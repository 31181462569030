import React, { memo } from "react";
import * as themes from "./DisciplineFilterThemes";
import SingleChoiceFilter from "components/UI/SingleChoiceFilter";
import { observer } from "mobx-react-lite";
import CatalogStore from "store/CatalogStore";
import { useMedias } from "../../../../API/mediasHook";

const DisciplineFilter = observer(({ children, ...props }) => {
    const processingDiscipline = (event, value) => {
        CatalogStore.SetDiscipline(value);
    };

    const medias = useMedias();

    return (
        <SingleChoiceFilter
            propOption={CatalogStore.optionDiscipline}
            propOptions={CatalogStore.optionsDiscipline}
            valueToShow={"discipline"}
            valueToBind={"ID"}
            processingChange={processingDiscipline}
            themeWrapper={themes.FiltersDisciplineTheme(medias)}
            themeLabel={themes.filtersDisciplineLabel}
            useOptionIcon={false}
            useMobileMode={true}
            themeOptionContainer={themes.filtersDOContainer}
            label={"Предмет"}
            placeholder={medias.sm ? "Выберите предмет" : "Предмет"}
        ></SingleChoiceFilter>
    );
});

export default memo(DisciplineFilter);
