import React from "react";

const NewStudentIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <path
                d="M14.7796 9.36112L14.7871 9.39216L14.7946 9.41543L14.8748 9.91199L14.3915 9.86544L6.48793 9.08698L6.39026 8.92146L2.34835 2.08861C2.27322 1.96447 2.27322 1.80671 2.34835 1.68257C2.42348 1.55843 2.55871 1.4886 2.70145 1.50153L10.4197 2.26188C10.5349 2.27482 10.64 2.34206 10.7026 2.44551L14.7446 9.27836L14.7521 9.29388C14.7628 9.31561 14.772 9.33807 14.7796 9.36112Z"
                fill="#82D4AE"
            />
            <path
                d="M14.1043 15.5C13.924 15.5 13.7637 15.3629 13.7337 15.1741L13.7362 15.1767L14.2262 10.631L15 10.706L14.4774 15.05C14.51 15.2595 14.3723 15.4612 14.1669 15.4948C14.1444 15.5 14.1243 15.5 14.1043 15.5Z"
                fill="#82D4AE"
            />
            <path
                d="M5.93717 9.66116C5.99978 9.76461 6.10496 9.83443 6.22266 9.84478L11.409 10.3517L12.2455 10.4344L10.5175 13.2922C10.4499 13.4086 10.3272 13.4759 10.1995 13.4759H10.1644L4.3745 12.9069C4.2568 12.8965 4.15162 12.8267 4.08901 12.7233L1.05632 7.59992C1.0195 7.53855 1 7.4678 1 7.39561C1 7.32342 1.0195 7.25266 1.05632 7.19129L2.78428 4.3335L5.93717 9.66116Z"
                fill="#82D4AE"
            />
        </svg>
    );
};

export default NewStudentIcon;
