const CardsGroupIcon = () => {
    return (
        <svg
            width="96"
            height="24"
            viewBox="0 0 96 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_78_478)">
                <path
                    d="M19.6893 7.28125H14.2812V16.7143H19.6893V7.28125Z"
                    fill="#FF5A00"
                />
                <path
                    d="M14.64 12C14.64 10.0835 15.568 8.38252 16.992 7.2835C15.944 6.4835 14.622 6 13.18 6C9.764 6 7 8.6835 7 12C7 15.3165 9.764 18 13.18 18C14.622 18 15.944 17.5165 16.992 16.7165C15.566 15.633 14.64 13.9165 14.64 12Z"
                    fill="#EB001B"
                />
                <path
                    d="M26.9842 12C26.9842 15.3165 24.2202 18 20.8042 18C19.3622 18 18.0402 17.5165 16.9922 16.7165C18.4342 15.6155 19.3442 13.9165 19.3442 12C19.3442 10.0835 18.4162 8.38252 16.9922 7.2835C18.0382 6.4835 19.3602 6 20.8022 6C24.2202 6 26.9842 8.70097 26.9842 12Z"
                    fill="#F79E1B"
                />
            </g>
            <g clipPath="url(#clip1_78_478)">
                <path
                    d="M51.2588 8.01562C49.3791 8.01562 47.6993 8.93521 47.6993 10.6342C47.6993 12.5827 50.6785 12.7172 50.6785 13.6961C50.6785 14.1082 50.178 14.4772 49.3233 14.4772C48.1103 14.4772 47.2038 13.9616 47.2038 13.9616L46.8158 15.6761C46.8158 15.6761 47.8602 16.1116 49.2468 16.1116C51.3019 16.1116 52.9191 15.1468 52.9191 13.4188C52.9191 11.3599 49.9274 11.2293 49.9274 10.3208C49.9274 9.99795 50.3383 9.64419 51.1905 9.64419C52.1521 9.64419 52.9366 10.0191 52.9366 10.0191L53.3163 8.36321C53.3163 8.36321 52.4626 8.01562 51.2588 8.01562ZM34.0611 8.1406L34.0156 8.39055C34.0156 8.39055 34.8064 8.52715 35.5187 8.79964C36.4357 9.11211 36.5011 9.29401 36.6555 9.85899L38.3386 15.9827H40.5947L44.0704 8.1406H41.8195L39.5861 13.4725L38.6748 8.95293C38.5912 8.43567 38.1678 8.1406 37.6496 8.1406H34.0611ZM44.9756 8.1406L43.2098 15.9827H45.3562L47.1158 8.1406H44.9756ZM56.9472 8.1406C56.4296 8.1406 56.1554 8.40215 55.9541 8.8592L52.8094 15.9827H55.0604L55.4959 14.7955H58.2382L58.503 15.9827H60.4891L58.7564 8.1406H56.9472ZM57.2399 10.2593L57.9072 13.202H56.1196L57.2399 10.2593Z"
                    fill="#1434CB"
                />
            </g>
            <path
                d="M90.7578 8H85.6602C85.9571 9.64553 87.5903 11.1943 89.372 11.1943H93.4303C93.4798 11.0491 93.4798 10.8071 93.4798 10.6619C93.4798 9.20995 92.2425 8 90.7578 8Z"
                fill="url(#paint0_linear_78_478)"
            />
            <path
                d="M86.1055 11.4844V15.5014H88.58V13.3719H90.7577C91.9455 13.3719 92.9848 12.5491 93.3312 11.4844H86.1055Z"
                fill="#4FAD50"
            />
            <path
                d="M77.3945 8V15.4533H79.5722C79.5722 15.4533 80.1166 15.4533 80.4135 14.9209C81.8983 12.0654 82.3437 11.1943 82.3437 11.1943H82.6406V15.4533H85.1152V8H82.9376C82.9376 8 82.3932 8.0484 82.0962 8.53238C80.8589 11.0007 80.1661 12.259 80.1661 12.259H79.8691V8H77.3945Z"
                fill="#4FAD50"
            />
            <path
                d="M67 15.5002V8.04688H69.4746C69.4746 8.04688 70.1675 8.04687 70.5634 9.11163C71.5532 11.9187 71.6522 12.3059 71.6522 12.3059C71.6522 12.3059 71.8502 11.6283 72.741 9.11163C73.137 8.04687 73.8298 8.04688 73.8298 8.04688H76.3044V15.5002H73.8298V11.4831H73.5329L72.1471 15.5002H71.0583L69.6726 11.4831H69.3756V15.5002H67Z"
                fill="#4FAD50"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_78_478"
                    x1="85.6513"
                    y1="9.62003"
                    x2="93.4623"
                    y2="9.62003"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#27B1E6" />
                    <stop
                        offset="1"
                        stopColor="#148ACA"
                    />
                </linearGradient>
                <clipPath id="clip0_78_478">
                    <rect
                        width="20"
                        height="12"
                        fill="white"
                        transform="translate(7 6)"
                    />
                </clipPath>
                <clipPath id="clip1_78_478">
                    <rect
                        width="26.5"
                        height="8.12097"
                        fill="white"
                        transform="translate(34 8)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default CardsGroupIcon;
