const LineIcon = function (props) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 8L13 8"
                stroke="#BBBBC8"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default LineIcon;
