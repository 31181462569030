export const PaymentHeader = {
    display: "flex",
    padding: "30px 0px 60px 0px",
    background: "#222",
    justifyContent: "center",
    minWidth: 0,
};

export const PaymentHeaderContent = {
    maxWidth: "1216px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: 0,
};
