export const imageOuterModal = {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
};

export const imageInnerModal = {
    width: "80vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
};

export const iconClose = {
    width: "fit-content",
    height: "fit-content",
};

export const topPanel = {
    display: "flex",
    justifyContent: "flex-end",
    height: "10vh",
    alignItems: "center",
};

export const imagePanel = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "80vh",
};

export const buttonNextPrev = (condition) => {
    return {
        visibility: condition ? "visible" : "hidden",
        display: "flex",
        height: "fit-content",
        borderRadius: "50%",
        background: "rgba(255, 255, 255, 0.70)",
        filter: "drop-shadow(0px 4px 10px rgba(106, 99, 118, 0.10))",
        alignItems: "center",
        justifyContent: "center",
        padding: "4px",
    };
};

export const bottomOuterPanel = {
    width: "100vw",
    height: "10vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(180deg, rgba(5, 1, 13, 0.00) 0%, rgba(5, 1, 13, 0.64) 100%)",
};

export const bottomInnerPanel = {
    width: "80vw",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const modalContainer = {
    width: "100%",
    height: "100%",
};

export const avatarContainer = {
    display: "flex",
    gap: "12px",
};

export const nameContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "0px",
};

export const downloadButton = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "fit-content",
    borderRadius: "50%",
    background: "rgba(23, 15, 38, 0.50)",
    padding: "10px",
};
