export const WhyUsBlock = {
    width: "1052px",
    borderRadius: "40px",
    border: "2px solid #122DFF",
    background: "#6212FF",
    marginTop: "140px",
    padding: "50px 104px 50px 60px",
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "space-between",
};

export const mainHeader = {
    maxWidth: "332px",
};

export const benefitsBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
};

export const sepLine = {
    width: "259px",
    height: "2px",
    backgroundColor: "rgb(255, 255, 255)",
};

export const BenefitsBlock = {
    display: "flex",
    gap: "20px",
};

export const SepLineBlock = {
    paddingLeft: "29px",
    display: "flex",
    gap: "51px",
};

export const FirstIcon = {
    left: 409,
    top: 92,
    position: "absolute",
};

export const SecondIcon = {
    left: 358,
    top: 201,
    position: "absolute",
};

export const ThirdIcon = {
    left: 197,
    top: 20,
    position: "absolute",
};
