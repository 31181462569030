import * as React from "react";
import { Box, Typography, TextField, Checkbox } from "@mui/material";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import * as styles from "./SignUpTutorThemes";
import * as textStyles from "themes/TextThemes";
import SButton from "components/UI/SButton";
import AuthorizationStore from "store/AuthorizationStore";
import { useNavigate } from "react-router-dom";
import { BackIcon } from "assets/Authorization/SVG/BackIcon";
import { useTranslation } from "react-i18next";
import PhoneTextField from "../PhoneTextField/PhoneTextField";
import PurpleCheckBoxDefault from "assets/PaymentAssets/SVG/PurpleCheckBoxDefault";
import PurpleCheckBoxChecked from "assets/PaymentAssets/SVG/PurpleCheckBoxChecked";
import { useMedias } from "API/mediasHook";
import Logo from "components/UI/Logo/logo";

const SignUpTutor = observer(({ setCurrent, style, current }) => {
    const { t, i18n } = useTranslation();

    const medias = useMedias();

    const navigate = useNavigate();

    React.useEffect(() => {
        AuthorizationStore.setMode("register");
    }, []);

    const handleBack = () => {
        AuthorizationStore.setCurrentAuthorizationStep("SignIn");
        AuthorizationStore.setTemporaryPhoneNumber(null);
        AuthorizationStore.setTemporaryEmail("");
        AuthorizationStore.setTemporaryPassword("");
    };

    const handleChangeName = (e) => {
        AuthorizationStore.setSignUpName(e.target.value);
    };

    const handleChangeEmail = (e) => {
        AuthorizationStore.setSignUpEmail(typeof e.target.value === "string" ? e.target.value.replaceAll(" ", "") : e.target.value);
        AuthorizationStore.setSignUpEmailError(false);
        AuthorizationStore.setSignUpEmailErrorText("");
    };

    const handleClickSend = async () => {
        AuthorizationStore.setSignUpRole("tutor");
        await AuthorizationStore.RegisterNewUser();
    };

    const handleOpenAgentContract = () => {
        const pdfUrl = "https://study-buddy.ru/data/documents/%D0%90%D0%B3%D0%B5%D0%BD%D1%82%D1%81%D0%BA%D0%B8%D0%B9%20%D0%B4%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80.docx";

        const newWindow = window.open(pdfUrl, "_blank");
    };

    const handleOpenConfidentiality = () => {
        const pdfUrl =
            "https://study-buddy.ru/data/documents/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8_%D0%B8_%D1%85%D1%80%D0%B0%D0%BD%D0%B5%D0%BD%D0%B8%D1%8F_%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.docx";

        const newWindow = window.open(pdfUrl, "_blank");
    };

    return (
        <Box sx={styles.SignUpTutor(medias)}>
            <Box sx={styles.SignUpTutorContent}>
                {!medias.sm && <Logo />}
                <Box sx={styles.TopBlock}>
                    <SButton
                        variant="icon"
                        style={styles.BackIcon(medias)}
                        onClick={handleBack}
                    >
                        <BackIcon />
                    </SButton>
                    <Typography sx={medias.sm ? textStyles.HeadlineH3Black : textStyles.HeadlineH41Black}>Регистрация репетитора</Typography>
                    <Box sx={styles.DescriptionText}>
                        <Typography sx={textStyles.Body2DarkGrey}>Никто не будет видеть твои контакты, они нужны только для регистрации</Typography>
                    </Box>
                </Box>
                <Box sx={styles.FormAndButton(medias)}>
                    <Box sx={styles.SignUpForm(medias)}>
                        <Box>
                            <Typography sx={textStyles.Body2Black}>Имя</Typography>
                            <TextField
                                value={AuthorizationStore.signUpName}
                                sx={styles.NameField}
                                fullWidth
                                variant="standard"
                                name="name"
                                onChange={handleChangeName}
                                placeholder="Введите имя"
                            />
                        </Box>
                        <PhoneTextField mode="SignUp" />
                        <Box>
                            <Typography sx={textStyles.Body2Black}>Электронная почта</Typography>
                            <TextField
                                value={AuthorizationStore.signUpEmail}
                                sx={styles.EmailField(AuthorizationStore.signUpEmailError)}
                                error={AuthorizationStore.signUpEmailError}
                                helperText={AuthorizationStore.signUpEmailErrorText}
                                fullWidth
                                variant="standard"
                                name="email"
                                onChange={handleChangeEmail}
                                placeholder="Введите электронную почту"
                            />
                        </Box>
                    </Box>
                    <Box sx={styles.ButtonAndRules}>
                        <SButton
                            disabled={
                                AuthorizationStore.tutorRulesAgree === false ||
                                AuthorizationStore.tutorOfferAgree === false ||
                                AuthorizationStore.signUpName === "" ||
                                AuthorizationStore.signUpEmail === "" ||
                                AuthorizationStore.signUpEmailError === true ||
                                AuthorizationStore.signUpPhone === null ||
                                AuthorizationStore.signUpPhoneError === true
                            }
                            style={styles.SignUpButton(medias)}
                            variant="arrow"
                            padding="11px 24px 12px 24px"
                            onClick={handleClickSend}
                        >
                            Зарегистрироваться
                        </SButton>
                        <Box sx={styles.AgreementsBlock}>
                            <Box sx={styles.Agreements}>
                                <Checkbox
                                    checked={AuthorizationStore.tutorOfferAgree}
                                    onChange={(e) => AuthorizationStore.setTutorOfferAgree(e.target.checked)}
                                    sx={styles.CheckBoxAgreement}
                                    icon={<PurpleCheckBoxDefault />}
                                    checkedIcon={<PurpleCheckBoxChecked />}
                                    disableRipple
                                />
                                <Typography
                                    sx={{ ...textStyles.Body2DarkGrey, ...styles.LinkButton }}
                                    onClick={handleOpenAgentContract}
                                >
                                    Соглашаюсь с <span style={textStyles.Body2Purple}>офертой</span>
                                </Typography>
                            </Box>
                            <Box sx={styles.Agreements}>
                                <Checkbox
                                    checked={AuthorizationStore.tutorRulesAgree}
                                    onChange={(e) => AuthorizationStore.setTutorRulesAgree(e.target.checked)}
                                    sx={styles.CheckBoxAgreement}
                                    icon={<PurpleCheckBoxDefault />}
                                    checkedIcon={<PurpleCheckBoxChecked />}
                                    disableRipple
                                />
                                <Typography
                                    sx={{ ...textStyles.Body2DarkGrey, ...styles.LinkButton }}
                                    onClick={handleOpenConfidentiality}
                                >
                                    Соглашаюсь на обработку персональных данных и 
                                    <span style={textStyles.Body2Purple}>условия сервиса</span>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.AlreadyHaveAccount}>
                <Typography sx={textStyles.Button14Black}>Уже есть аккаунт?</Typography>
                <SButton
                    variant="text"
                    onClick={handleBack}
                >
                    Войти
                </SButton>
            </Box>
        </Box>
    );
});

export default memo(SignUpTutor);
