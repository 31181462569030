import React, { useState, useCallback } from "react";
import { Avatar, Box, Rating, Typography } from "@mui/material";
import FeedBackInput from "components/OrdersBodyBlock/OrderCard/OrderRateModal/FeedBackInput";
import SButton from "components/UI/SButton";
import { observer } from "mobx-react-lite";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import * as themes from "./ReviewCardThemes";
import * as textThemes from "themes/TextThemes";
import RatingStarIcon from "assets/CommonAssets/SVG/RatingStarIcon";
import EmptyRatingIcon from "assets/CommonAssets/SVG/EmptyRatingIcon";
import { memo } from "react";
import FeedbackStore from "store/FeedbackStore";
import { useTranslation } from "react-i18next";
import NewFeedbackIcon from "assets/CommonAssets/SVG/NewFeedbackIcon";
import { useMedias } from "API/mediasHook";

const Review = observer(({ item }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const [isChange, setChange] = useState(false);

    const formatDate = useCallback(() => {
        const now = new Date();

        const reviewDate = item.date;

        if (now.getFullYear() > reviewDate.getFullYear()) {
            return reviewDate.toLocaleString("ru", { year: "numeric", day: "numeric", month: "long" });
        } else if (now.getMonth() === reviewDate.getMonth() && now.getDate() === reviewDate.getDate()) {
            return `сегодня в ${reviewDate.toLocaleString("ru", { hour: "numeric", minute: "numeric" })}`;
        } else {
            return reviewDate.toLocaleString("ru", { day: "numeric", month: "long" });
        }
    }, [item.date]);

    const sendAnswer = useCallback(() => {
        FeedbackStore.SetFeedBackAnswer(item.id, item.temporaryAnswer);
        FeedbackStore.SetFeedBackTemporaryAnswer(item.id, null);
        setChange(false);
    }, [item.temporaryAnswer, item.id]);

    const handleSetTemporaryAnswer = (value) => {
        FeedbackStore.SetFeedBackTemporaryAnswer(item.id, value);
    };

    const isDateInLast7Days = (dateString) => {
        const inputDate = new Date(dateString);

        const currentDate = new Date();

        // Устанавливаем время на начало дня для обеих дат
        inputDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        const diffTime = Math.abs(currentDate - inputDate);

        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return diffDays <= 7;
    };

    const handleClickAnswer = () => {
        setChange(true);
    };

    const AnswerBox = useCallback(() => {
        if (item.answer === null) {
            return isChange ? (
                <Box>
                    <FeedBackInput
                        placeholder={t("teacherAccount.rating.addAnswer")}
                        rows="2"
                        height="100px"
                        width={medias.sm ? "770px" : "100%"}
                        max={1000}
                        value={item.temporaryAnswer}
                        setFeedBackValue={handleSetTemporaryAnswer}
                    />
                    <SButton
                        disabled={item.temporaryAnswer === null}
                        variant="unshadowed"
                        padding="10px 20px 11px 20px"
                        onClick={sendAnswer}
                    >
                        {t("teacherAccount.rating.send")}
                    </SButton>
                </Box>
            ) : (
                <SButton
                    variant="text"
                    onClick={handleClickAnswer}
                >
                    {t("teacherAccount.rating.answer")}
                </SButton>
            );
        }

        return (
            <Box sx={themes.AnswerBlock}>
                <Avatar
                    src={TeacherQuestionnaireStore.teacher.photo.imageSrc}
                    sx={themes.TutorAvatar}
                />
                <Box sx={themes.AnswerText}>
                    <Typography sx={textThemes.Body2DarkGrey}>{t("teacherAccount.rating.yourAnswer")}</Typography>
                    <Typography sx={textThemes.Body1Black}>{item.answer}</Typography>
                </Box>
            </Box>
        );
    }, [item.answer, isChange, item.temporaryAnswer, sendAnswer]);

    return (
        <Box sx={themes.ReviewContainer(medias)}>
            <Avatar
                src={item.avt}
                sx={themes.Avatar(medias)}
            />
            <Box sx={themes.ReviewContent}>
                <Box sx={themes.TopBlock}>
                    <Box sx={themes.NewFeedbackBlock}>
                        <Typography sx={textThemes.Button16Black}>
                            {item.name}, {formatDate()}
                        </Typography>
                        {isDateInLast7Days(item.date) && <NewFeedbackIcon />}
                    </Box>
                    <Rating
                        sx={themes.Rating}
                        icon={<RatingStarIcon fontSize="inherit" />}
                        emptyIcon={<EmptyRatingIcon fontSize="inherit" />}
                        value={item.rating}
                        readOnly
                    />
                </Box>
                <Box sx={themes.ReviewTextAndAnswer}>
                    <Box sx={themes.BottomBlock}>
                        <Typography sx={textThemes.Body1Black}>{item.text}</Typography>
                        <Typography sx={textThemes.Body2Grey}>
                            {item.subject}/{item.category}
                        </Typography>
                    </Box>
                    <Box>{AnswerBox()}</Box>
                </Box>
            </Box>
        </Box>
    );
});

export default memo(Review);
