import React from "react";

const PlayIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
        >
            <circle
                cx="20"
                cy="20"
                r="20"
                fill="white"
                fillOpacity="0.5"
            />
            <path
                d="M26.8976 17.9222C28.3675 18.8854 28.3675 21.1146 26.8976 22.0778L17.6183 27.6183C16.0465 28.6482 14 27.473 14 25.5405L14 14.4595C14 12.527 16.0465 11.3518 17.6183 12.3817L26.8976 17.9222Z"
                fill="#6212FF"
            />
        </svg>
    );
};

export default PlayIcon;
