import React, { memo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Card, Tab, Tabs } from "@mui/material";
import * as themes from "./EducationCardThemes";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import TabPanel from "@mui/lab/TabPanel";
import { TabContext } from "@mui/lab";
import EducationContent from "./EducationContent/EducationContent";
import ExperienceContent from "./ExperienceContent/ExperienceContent";
import CertificatesContent from "./CertificatesContent/CertificatesContent";
import { tabPanelStyle } from "./EducationCardThemes";
import { useMedias } from "../../../API/mediasHook";

const EducationCard = observer(({ children, ...props }) => {
    const [selectedEducationTab, SetSelectedEducationTab] = useState("Education");

    const handleChange = (event, newValue) => {
        SetSelectedEducationTab(newValue);
    };

    const medias = useMedias();

    return (
        <Card
            sx={themes.educationCardStyle(medias)}
            id={"EducationCard"}
        >
            <Box sx={themes.educationContainerStyle}>
                <TabContext value={selectedEducationTab}>
                    <Box sx={themes.headerContainerStyle(medias)}>
                        <Typography sx={themes.aboutTextStyle}>Резюме</Typography>
                        <ThemeProvider theme={themes.TabsTheme}>
                            <Tabs
                                value={selectedEducationTab}
                                onChange={handleChange}
                            >
                                <Tab
                                    value={"Education"}
                                    disableRipple={true}
                                    label="Образование"
                                />
                                <Tab
                                    value={"Experience"}
                                    disableRipple={true}
                                    label="Опыт"
                                />
                                <Tab
                                    value={"Certificates"}
                                    disableRipple={true}
                                    label="Сертификаты"
                                />
                            </Tabs>
                        </ThemeProvider>
                    </Box>
                    <TabPanel
                        value={"Education"}
                        sx={themes.tabPanelStyle}
                    >
                        <EducationContent value={props.value.EducationInfo}></EducationContent>
                    </TabPanel>
                    <TabPanel
                        value={"Experience"}
                        sx={themes.tabPanelStyle}
                    >
                        <ExperienceContent value={props.value.ExperienceInfo}></ExperienceContent>
                    </TabPanel>
                    <TabPanel
                        value={"Certificates"}
                        sx={themes.tabPanelStyle}
                    >
                        <CertificatesContent value={props.value}></CertificatesContent>
                    </TabPanel>
                </TabContext>
            </Box>
        </Card>
    );
});

export default memo(EducationCard);
