import { Box, Divider, Typography, Avatar } from "@mui/material";
import OrderDate from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/OrderDate/OrderDate";
import * as themes from "./InProgressOrderCardThemes";
import * as textThemes from "themes/TextThemes";
import InProgressStatus from "./InProgressStatus/InProgressStatus";
import { useNavigate } from "react-router-dom";
import { useMedias } from "API/mediasHook";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import * as React from "react";

const InProgressOrderCard = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const medias = useMedias();

    const navigate = useNavigate();

    const formatDate = (date) => {
        const months = [
            "main.months.atJanuary",
            "main.months.atFebruary",
            "main.months.atMarch",
            "main.months.atApril",
            "main.months.atMay",
            "main.months.atJune",
            "main.months.atJuly",
            "main.months.atAugust",
            "main.months.atSeptember",
            "main.months.atOctober",
            "main.months.atNovember",
            "main.months.atDecember",
        ];

        const day = date.getDate();

        const month = months[date.getMonth()];

        return `${day} ${t(month)}`;
    };

    const getDaysDifference = (inputDate) => {
        const currentDate = new Date();

        const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

        const inputDateOnly = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());

        const timeDifference = inputDateOnly - currentDateOnly;

        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

        if (daysDifference > 0 && daysDifference <= 3) {
            return daysDifference;
        } else if (daysDifference <= 0) {
            return "red";
        } else {
            return null;
        }
    };

    const getDayWord = (number) => {
        if (number === 1) {
            return "main.dayOne";
        } else if (number >= 2 && number <= 4) {
            return "main.dayFew";
        } else {
            return "main.dayMany";
        }
    };

    const handleClickCard = () => {
        navigate("/diplomas");
    };

    return (
        <Box
            sx={themes.StyledOrderCard(medias)}
            onClick={handleClickCard}
        >
            <Box sx={themes.TopBlock}>
                <Box sx={themes.TopContentWrapper}>
                    <Typography sx={textThemes.HeadlineH5Black}>{props.order.baseOrderInfo.requestTopic}</Typography>
                    <OrderDate date={props.order.baseOrderInfo.createdAt} />
                </Box>
                <Box sx={themes.SubjectAndDeadlineBlock}>
                    <Typography>
                        <Typography
                            sx={textThemes.Body2Grey}
                            component="span"
                        >
                            {t("orders.studentOrders.subject")}
                        </Typography>
                        <Typography
                            sx={textThemes.Body2Black}
                            component="span"
                        >
                            {props.order.baseOrderInfo.subject}
                        </Typography>
                    </Typography>
                    <Box sx={themes.DeadlineBlock}>
                        <Typography sx={textThemes.Body2Grey}>{t("orders.studentOrders.deadline")}</Typography>
                        <Typography>
                            <Typography
                                component="span"
                                sx={getDaysDifference(props.order.finishTime) !== "red" ? textThemes.Body2Black : textThemes.Body2Red}
                            >
                                {formatDate(props.order.finishTime)}
                            </Typography>
                            {getDaysDifference(props.order.finishTime) && getDaysDifference(props.order.finishTime) !== "red" ? (
                                <Typography
                                    component="span"
                                    sx={textThemes.Body2Red}
                                >{` (${getDaysDifference(props.order.finishTime)} ${t(getDayWord(getDaysDifference(props.order.finishTime)))})`}</Typography>
                            ) : null}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Box sx={themes.BottomBlock}>
                    <Divider sx={themes.Divider} />
                    <Box sx={themes.BottomContentWrapper}>
                        <Box sx={themes.AvatarContainer}>
                            {props.order.baseOrderInfo.doer.anonymous ? (
                                <Box sx={themes.PhotoHidden}>
                                    <Typography sx={themes.PhotoHiddenText}>{t("orders.studentOrders.photoHidden")}</Typography>
                                </Box>
                            ) : (
                                <Avatar src={props.order.baseOrderInfo.doer.photo.link} />
                            )}
                            <Typography sx={textThemes.Body2Black}>
                                {props.order.baseOrderInfo.doer.anonymous
                                    ? t("orders.studentOrders.fioHidden")
                                    : `${props.order.baseOrderInfo.doer.name} ${props.order.baseOrderInfo.doer.surname[0]}.`}
                            </Typography>
                        </Box>
                        <InProgressStatus order={props.order} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default memo(InProgressOrderCard);
