export const TopText = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "150%",
    fontWeight: 400,
    textTransform: "none",
    color: "#67677A",
    marginBottom: "30px",
    whiteSpace: "nowrap",
};

export const BottomText = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "150%",
    fontWeight: 400,
    textTransform: "none",
    color: "#9E9EB0",
    marginBottom: "40px",
    marginTop: "16px",
};

export const ChipWrapper = { marginTop: "16px", display: "flex", gap: "8px" };
