import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import * as React from "react";
import filterStore from "store/FilterStore";
import SettingsStore from "../../store/SettingsStore";
import { Close } from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import DeleteLinkIcon from "../../assets/TeacherQuestionnaireAssets/DeleteLinkIcon";
import ClearIcon from "@mui/icons-material/Clear";

const SAutocomplete = function ({ marginBottom = "40px", ...props }) {
    const handleSubmitFilterChangeEvent = () => {
        filterStore.setSubmitButtonFilter(false);
    };

    function CustomClearIcon(props) {
        const { onClick, ...other } = props;

        return (
            <ClearIcon
                {...other}
                onClick={onClick}
                disableRipple
                style={{ cursor: "pointer" }} // Ensures the icon looks clickable
            />
        );
    }

    if (props.value === undefined) {
        return (
            <Autocomplete
                disableRipple
                disabled={props.disabled}
                onChange={filterStore.setSubmitButtonFilter(false)}
                inputValue={props.inputvalue}
                onInputChange={(event, newInputValue) => {
                    if (newInputValue === null) {
                        props.inputchangefunc("");
                    } else {
                        props.inputchangefunc(newInputValue);
                        handleSubmitFilterChangeEvent();
                    }
                }}
                id="free-solo-demo"
                freeSolo
                options={props.list}
                sx={{
                    width: "100%",
                    mb: marginBottom,
                    "& + .MuiAutocomplete-paper .MuiAutocomplete-option:hover": {
                        opacity: "100%",
                        color: "#222",
                    },
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={props.placeholder}
                        variant="standard"
                        sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                color: "red", // Change this to your desired color
                            },
                            "& .MuiInput-underline:before": {
                                borderBottom: props.disabled ? "0px" : props?.error ? "1px solid #d32f2f" : "1px solid #BBBBC8",
                            },
                            "& .MuiInput-underline:after": { borderBottomColor: props?.error ? "#d32f2f" : "#6212FF" },
                            "& .MuiInputBase-input": {
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontSize: "16px",
                                lineHeight: "150%",
                                fontWeight: 400,
                                textTransform: "none",
                            },
                        }}
                    />
                )}
                componentsProps={{
                    clearIndicator: {
                        component: CustomClearIcon,
                    },
                    paper: {
                        sx: {
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "16px",
                            lineHeight: "150%",
                            fontWeight: 400,
                            textTransform: "none",
                            color: "#222",
                            bgcolor: "#fff",
                            borderRadius: "10px",
                            filter: "drop-shadow(0px 4px 10px rgba(106, 99, 118, 0.15))",
                            border: "0px",
                            borderStyle: "solid",
                        },
                    },
                }}
            />
        );
    } else {
        return (
            <Autocomplete
                disableRipple
                disabled={props.disabled}
                value={props.value}
                onChange={(event, newValue) => {
                    if (newValue === null) {
                        if (props.notBlock === undefined) props.changefunc("");
                    } else {
                        props.changefunc(newValue);
                    }
                }}
                inputValue={props.inputvalue}
                onInputChange={(event, newInputValue) => {
                    if (newInputValue === null) {
                        props.inputchangefunc("");
                    } else {
                        props.inputchangefunc(newInputValue);
                        handleSubmitFilterChangeEvent();
                    }
                }}
                id="free-solo-demo"
                freeSolo
                options={props.list}
                sx={{ width: "100%", mb: marginBottom }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={props.placeholder}
                        variant="standard"
                        sx={{
                            "& .MuiInput-underline:before": {
                                borderBottom: props.disabled ? "0px" : props?.error ? "1px solid #d32f2f" : "1px solid #BBBBC8",
                            },
                            "& .MuiInput-underline:after": { borderBottomColor: props?.error ? "#d32f2f" : "#6212FF" },
                            "& .MuiInputBase-input": {
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontSize: "16px",
                                lineHeight: "150%",
                                fontWeight: 400,
                                textTransform: "none",
                            },
                        }}
                    />
                )}
                componentsProps={{
                    clearIndicator: {
                        component: CustomClearIcon,
                    },
                    paper: {
                        sx: {
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "16px",
                            lineHeight: "150%",
                            fontWeight: 400,
                            textTransform: "none",
                            color: "#222",
                            bgcolor: "#fff",
                            borderRadius: "10px",
                            filter: "drop-shadow(0px 4px 10px rgba(106, 99, 118, 0.15))",
                            border: "0px",
                            borderStyle: "solid",
                        },
                    },
                }}
            />
        );
    }
};

export default SAutocomplete;
