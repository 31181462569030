import { styleProcessing } from "API/plugins/Utilities";

export const TitleBlock = {
    display: "flex",
    gap: "20px",
    alignItems: "center",
};

export const CurrentLevelContainer = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "20px",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ClassAndWorksBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "20px",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const CurrentMonthAchievementsBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const BottomBlocks = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "20px",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ForecastedBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const CurrentBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const TutorLevelContent = {
    width: "1216px",
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    paddingTop: "40px",
};

export const TutorLevel = {
    boxSizing: "border-box",
    borderRadius: "0px 0px 40px 40px",
    background: "#F7F7F7",
    display: "flex",
    justifyContent: "center",
    padding: "0 16px 70px 16px",
    width: "100%",
    margin: "0 auto",
};
