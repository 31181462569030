import React, { memo } from "react";
import { ToggleButton } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";

const ButtonFilter = ({ children, ...props }) => {
    const toggle = (value) => {
        props.toggleFunc(value);
    };

    return (
        <ThemeProvider theme={props.themeWrapper}>
            <ToggleButton
                value="check"
                selected={props.valueToBind}
                onChange={() => toggle(!props.valueToBind)}
                disableRipple
            >
                {props.iconsPosition === "Prepend" && (
                    <Box sx={{ display: "flex" }}>
                        {props.valueToBind || props.iconAlwaysVisible ? props.icon : null}
                        <span style={props.valueToBind ? props.themeSpanSelected : props.themeSpanDefault}>{props.buttonText}</span>
                    </Box>
                )}
                {props.iconsPosition === "Append" && (
                    <Box sx={{ display: "flex" }}>
                        <span style={props.valueToBind ? props.themeSpanSelected : props.themeSpanDefault}>{props.buttonText}</span>
                        {props.valueToBind || props.iconAlwaysVisible ? props.icon : null}
                    </Box>
                )}
                {props.iconsPosition === "Double" && (
                    <Box sx={{ display: "flex" }}>
                        {props.valueToBind || props.iconPrependAlwaysVisible ? props.iconPrepend : null}
                        <span style={props.valueToBind ? props.themeSpanSelected : props.themeSpanDefault}>{props.buttonText}</span>
                        {props.valueToBind || props.iconAppendAlwaysVisible ? props.iconAppend : null}
                    </Box>
                )}
                <Box sx={props.activeFiltersCount === 0 || typeof props.activeFiltersCount === "undefined" ? { display: "none" } : props.filtersCounterContainerStyle}>
                    <span style={props.filtersCounterTextStyle}>{props.activeFiltersCount}</span>
                </Box>
            </ToggleButton>
        </ThemeProvider>
    );
};

export default memo(ButtonFilter);
