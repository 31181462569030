import { Tab, Tabs, styled } from "@mui/material";

export const StyledOrderResponseTabs = styled(Tabs)({
    marginBottom: "30px",
    "& button": {
        minHeight: "39px",
        textTransform: "none",
        fontSize: "14px",
        fontWeight: "500",
        borderRadius: "40px",
        border: "solid 1px rgba(103, 103, 122, 1)",
        color: "rgba(103, 103, 122, 1)",
        marginRight: "12px",
    },
    "& button.Mui-selected": {
        color: "rgba(255, 255, 255, 1)",
        backgroundColor: "rgba(34, 34, 34, 1)",
        background: "rgba(34, 34, 34, 1)",
    },
    "& .MuiTabs-indicator": {
        display: "none",
    },
});
