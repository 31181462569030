import React, { memo, useState } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./ActionsBlockThemes";
import { Box } from "@mui/material";
import { Button, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import OrdersResponsesStore from "store/OrdersResponsesStore";
import ModalRefuse from "./ModalRefuse";

const Actions = observer(({ children, ...props }) => {
    const [dialogRefuseOpen, SetDialogRefuseOpen] = useState(false);

    const showRefuseDialog = () => {
        SetDialogRefuseOpen(true);
    };

    const discardRefuseDialog = () => {
        SetDialogRefuseOpen(false);
    };

    const processRefuseDialog = (value) => {
        SetDialogRefuseOpen(false);
        OrdersResponsesStore.RemoveResponseFromList(value);
    };

    return (
        <Box sx={themes.actionsBlockContainerStyle}>
            {props.value.Price?.PriceFrom ? (
                props.value.Price?.PriceTo ? (
                    <Typography sx={themes.priceTextStyle(0)}>
                        {props.value.Price.PriceFrom.toLocaleString()} - {props.value.Price.PriceTo.toLocaleString()} ₽/ч
                    </Typography>
                ) : (
                    <Typography sx={themes.priceTextStyle(0)}>{props.value.Price.PriceFrom.toLocaleString()} ₽/ч</Typography>
                )
            ) : (
                <Typography sx={themes.priceTextStyle(1)}>по договоренности</Typography>
            )}
            <Box sx={themes.actionsContainerStyle}>
                <ThemeProvider theme={themes.ResponseButtonTheme(props.value.SeenByTeacher)}>
                    <Button
                        onClick={() => window.open("/order/" + props.value._id, "_blank", "noreferrer")}
                        disableRipple
                    >
                        <Typography sx={themes.responseTextStyle}>{props.value.SeenByTeacher ? "Отклик отправлен" : "Откликнуться"}</Typography>
                    </Button>
                </ThemeProvider>
                <ThemeProvider theme={themes.RefuseButtonTheme}>
                    <Button
                        onClick={showRefuseDialog}
                        disableRipple
                    >
                        <Typography sx={themes.refuseTextStyle}>Отклонить</Typography>
                    </Button>
                </ThemeProvider>
            </Box>

            <ModalRefuse
                value={props.value}
                open={dialogRefuseOpen}
                handleDiscard={discardRefuseDialog}
                handleRefuse={processRefuseDialog}
            ></ModalRefuse>
        </Box>
    );
});

export default memo(Actions);
