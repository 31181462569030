export const TopQuestionsBlock = {
    width: "1216px",
    height: "fit-content",
    marginTop: "140px",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "110px",
};

export const AccordionBlock = {
    gap: "20px",
    display: "flex",
    flexDirection: "column",
};

export const TitleBlock = {
    maxWidth: "495px",
};
