import React, { memo, useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CatalogBodyBlock from "components/CatalogBodyBlock/CatalogBodyBlock";
import CatalogStore from "store/CatalogStore";
import { observer } from "mobx-react-lite";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

const theme = createTheme({
    spacing: 1,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const CatalogPage = observer(({ props }) => {
    useEffect(() => {
        const fetchFunc = async () => {
            await CatalogStore.FetchPageData();
        };

        fetchFunc();
    }, []);

    return (
        <ScrollToTop>
            <ThemeProvider theme={theme}>
                <Box
                    sx={{
                        background: "#F3F3F3",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        borderRadius: "0px 0px 40px 40px",
                        minHeight: "calc(100% - 120px)",
                    }}
                >
                    <CatalogBodyBlock></CatalogBodyBlock>
                </Box>
            </ThemeProvider>
        </ScrollToTop>
    );
});

export default memo(CatalogPage);
