import React, { memo, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { register } from "swiper/element/bundle";
import { Keyboard, Navigation } from "swiper";
import * as themes from "./OtherOrdersBlockThemes";
import "swiper/css";
import "swiper/css/navigation";
import { styles } from "components/AccountBodyBlock/StudentAccount/Stories/Slider/styles";
import OrderStore from "store/OrderStore";
import OrderInfoMiniCard from "components/UI/OrderMiniCard/OrderInfoMiniCard/OrderInfoMiniCard";

const OtherTutorsBlock = ({ children, ...props }) => {
    const swiperRef = useRef();

    useEffect(() => {
        register();
        const params = {
            spaceBetween: 21,
            slidesPerView: "auto",
            centeredSlides: false,
            slidesPerGroupSkip: 1,
            grabCursor: true,
            keyboard: {
                enabled: true,
            },
            navigation: {
                disabledClass: "disabled-button",
            },
            modules: [Keyboard, Navigation],
            injectStyles: [styles],
        };

        Object.assign(swiperRef.current, params);
        swiperRef.current.initialize();
    }, []);

    return (
        <Box sx={themes.otherTutorsBlockStyle}>
            <Typography sx={themes.otherTutorsHeaderStyle}>Также могут заинтересовать</Typography>
            <Box sx={themes.otherTutorsContainerStyle}>
                <swiper-container
                    ref={swiperRef}
                    init="false"
                >
                    {OrderStore.ordersArrRecommended.map((value) => (
                        <swiper-slide
                            style={{ maxWidth: "fit-content" }}
                            key={value.ID}
                        >
                            <OrderInfoMiniCard value={value}></OrderInfoMiniCard>
                        </swiper-slide>
                    ))}
                </swiper-container>
            </Box>
        </Box>
    );
};

export default memo(OtherTutorsBlock);
