import { Box, Typography, styled } from "@mui/material";

export const StyledOrderRaiting = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "52px",
    height: "30px",
    borderRadius: "5px",
    color: "rgba(255, 255, 255, 1)",
    background: "rgba(98, 18, 255, 1)",
});

export const StyledOrderRaitingText = styled(Typography)({
    fontSize: "14px",
    fontWeight: 500,
    lineGeight: "21px",
    letterSpacing: "0em",
});
