import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "@mui/material";
import * as themes from "./InfoAreaThemes";
import InfoBlock from "./InfoBlock/InfoBlock";
import ActionsBlock from "./ActionsBlock/ActionsBlock";

const InfoArea = observer(({ children, ...props }) => {
    return (
        <Box sx={themes.infoAreaContainerStyle}>
            <InfoBlock
                value={props.value}
                styleMode={props.styleMode}
            ></InfoBlock>
            <ActionsBlock
                value={props.value}
                styleMode={props.styleMode}
            ></ActionsBlock>
        </Box>
    );
});

export default memo(InfoArea);
