import * as themes from "components/TeacherQuestionnaireBodyBlock/Themes/ModalCropPhotoThemes";
import { Dialog, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import { CloseRounded } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import * as tThemes from "themes/TextThemes";
import SButton from "components/UI/SButton";
import React, { useEffect, useState } from "react";
import ImageCropperMini from "./CropperMini";
import ImageCropperBig from "./CropperBig";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import TeacherSettingsStore from "store/TeacherSettingsStore";
import { useMedias } from "API/mediasHook";
import MyClassesDrawer from "components/MyClassesBodyBlock/MyClassesDrawer";
import DrawerHeader from "components/SettingsBodyBlock/StudentSettings/DrawerHeader/DrawerHeader";

const PhotoCropStep = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const [checker, setChecker] = useState(1);

    useEffect(() => {
        handleSaveMiniComp();
    }, [checker]);

    const [cropper, setCropper] = useState();

    const [cropperMini, setCropperMini] = useState();

    const [croppedImageComp, setCroppedImageComp] = useState("");

    const handleSaveComp = () => {
        if (cropper && typeof cropper.getCroppedCanvas() !== "undefined") {
            const croppedImg = cropper.getCroppedCanvas().toDataURL();

            TeacherQuestionnaireStore.setTemporaryCroppedPhoto(croppedImg);
            TeacherQuestionnaireStore.setCroppingStep(1);
            TeacherQuestionnaireStore.setTemporaryCroppedMiniature(TeacherQuestionnaireStore.temporaryCroppedPhoto);
        }
    };

    const handleSaveMiniComp = () => {
        if (cropperMini && typeof cropperMini.getCroppedCanvas() !== "undefined") {
            const croppedImgMini = cropperMini.getCroppedCanvas().toDataURL();

            TeacherQuestionnaireStore.setTemporaryCroppedMiniature(croppedImgMini);
        }
    };

    const handleDialogClose = () => {
        TeacherQuestionnaireStore.setOpenCropDialog(false);
        TeacherQuestionnaireStore.setCroppingStep(0);
        TeacherQuestionnaireStore.setTemporaryCroppedPhoto("");
        TeacherQuestionnaireStore.setTemporaryCroppedMiniature("");
    };

    const handleDialogSave = () => {
        TeacherQuestionnaireStore.setOpenCropDialog(false);
        TeacherQuestionnaireStore.setCroppingStep(0);
        TeacherQuestionnaireStore.setPhoto(TeacherQuestionnaireStore.temporaryCroppedPhoto);
        TeacherQuestionnaireStore.setMiniature(TeacherQuestionnaireStore.temporaryCroppedMiniature);
        TeacherSettingsStore.setOpenChangePhotoSnackbar(true);
    };

    const DrawerList = (
        <Box sx={themes.dialogContainerStyle}>
            {TeacherQuestionnaireStore.croppingStep === 0 ? (
                <Box sx={themes.cardContainerStyle(medias)}>
                    <Box sx={themes.dialogContainerBodyStyle(medias)}>
                        <DrawerHeader
                            text={t("main.photoCrop.profilePhoto")}
                            handleClose={handleDialogClose}
                        />
                        <Box sx={themes.dialogContainerBodyInsideStyle(medias)}>
                            <Typography sx={[tThemes.Body2Black, themes.MobileTextPadding]}>{t("main.photoCrop.selectedArea")}</Typography>
                            <ImageCropperBig
                                imageSrc={TeacherQuestionnaireStore.initialInputPhoto}
                                setCropper={setCropper}
                                setCroppedImage={setCroppedImageComp}
                                height={medias.sm ? 460 : 352}
                                width={medias.sm ? 710 : "100%"}
                            />
                            <Box sx={themes.cardButtonsGroupStyle(medias)}>
                                <SButton
                                    variant="unshadowed"
                                    padding="10px 20px 11px 20px"
                                    onClick={handleSaveComp}
                                >
                                    {t("main.saveAndContinue")}
                                </SButton>
                                <SButton
                                    variant="secondary"
                                    padding="10px 20px 11px 20px"
                                    onClick={handleDialogClose}
                                >
                                    {t("main.cancel")}
                                </SButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box sx={themes.cardContainerStyle(medias)}>
                    <Box sx={themes.dialogContainerHeaderStyle}>
                        <DrawerHeader
                            text={t("main.photoCrop.miniatureChoice")}
                            handleClose={handleDialogClose}
                        />
                    </Box>
                    <Box sx={themes.dialogContainerBodyStyle(medias)}>
                        <Box sx={themes.dialogContainerBodyInsideStyle(medias)}>
                            <Typography sx={tThemes.Body2Black}>{t("main.photoCrop.chooseArea")}</Typography>
                            <Box sx={themes.dialogContainerMiniCropper}>
                                <ImageCropperMini
                                    handleSaveMiniComp={handleSaveMiniComp}
                                    imageSrc={TeacherQuestionnaireStore.temporaryCroppedPhoto}
                                    setCropper={setCropperMini}
                                    width={medias.sm ? 202 : 180}
                                    height={medias.sm ? 292 : 260}
                                    checker={checker}
                                    setChecker={setChecker}
                                />
                                <Box sx={themes.dialogContainerMiniCropperMiniatures}>
                                    <img
                                        width={medias.sm ? "160px" : "90px"}
                                        height={medias.sm ? "170px" : "96px"}
                                        className="TeacherQuestionnaireMiniatures"
                                        alt="not found"
                                        src={
                                            TeacherQuestionnaireStore.temporaryCroppedMiniature === ""
                                                ? TeacherQuestionnaireStore.temporaryCroppedPhoto
                                                : TeacherQuestionnaireStore.temporaryCroppedMiniature
                                        }
                                        style={{ borderRadius: "16px" }}
                                    />
                                    <img
                                        height={"80px"}
                                        width={"80px"}
                                        className="TeacherQuestionnaireMiniatures"
                                        alt="not found"
                                        src={
                                            TeacherQuestionnaireStore.temporaryCroppedMiniature === ""
                                                ? TeacherQuestionnaireStore.temporaryCroppedPhoto
                                                : TeacherQuestionnaireStore.temporaryCroppedMiniature
                                        }
                                        style={{ borderRadius: "80px" }}
                                    />
                                </Box>
                            </Box>
                            <Box sx={themes.cardButtonsGroupStyle(medias)}>
                                <SButton
                                    variant="unshadowed"
                                    padding="10px 20px 11px 20px"
                                    onClick={handleDialogSave}
                                    style={{ width: "203px" }}
                                >
                                    {t("main.done")}
                                </SButton>
                                <SButton
                                    variant="secondary"
                                    padding="10px 20px 11px 20px"
                                    onClick={handleDialogClose}
                                >
                                    {t("main.cancel")}
                                </SButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );

    return (
        <>
            {medias.sm ? (
                <ThemeProvider theme={themes.DialogContainerTheme}>
                    <Dialog
                        open={TeacherQuestionnaireStore.openCropDialog}
                        onClose={handleDialogClose}
                        maxWidth={"none"}
                    >
                        <Box sx={themes.dialogContainerStyle}>
                            <ThemeProvider theme={themes.CloseIconTheme}>
                                <IconButton onClick={handleDialogClose}>
                                    <CloseRounded></CloseRounded>
                                </IconButton>
                            </ThemeProvider>
                            {TeacherQuestionnaireStore.croppingStep === 0 ? (
                                <Box sx={themes.cardContainerStyle(medias)}>
                                    <Box sx={themes.dialogContainerHeaderStyle}>
                                        <Box sx={themes.dialogContainerHeaderInsideStyle}>
                                            <Typography sx={tThemes.HeadlineH5Black}>{t("main.photoCrop.profilePhoto")}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={themes.dialogContainerBodyStyle(medias)}>
                                        <Box sx={themes.dialogContainerBodyInsideStyle(medias)}>
                                            <Typography sx={tThemes.Body2Black}>{t("main.photoCrop.selectedArea")}</Typography>
                                            <ImageCropperBig
                                                imageSrc={TeacherQuestionnaireStore.initialInputPhoto}
                                                setCropper={setCropper}
                                                setCroppedImage={setCroppedImageComp}
                                                height={460}
                                                width={710}
                                            />
                                            <Box sx={themes.cardButtonsGroupStyle(medias)}>
                                                <SButton
                                                    variant="unshadowed"
                                                    padding="10px 20px 11px 20px"
                                                    onClick={handleSaveComp}
                                                >
                                                    {t("main.saveAndContinue")}
                                                </SButton>
                                                <SButton
                                                    variant="secondary"
                                                    padding="10px 20px 11px 20px"
                                                    onClick={handleDialogClose}
                                                >
                                                    {t("main.cancel")}
                                                </SButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            ) : (
                                <Box sx={themes.cardContainerStyle(medias)}>
                                    <Box sx={themes.dialogContainerHeaderStyle}>
                                        <Box sx={themes.dialogContainerHeaderInsideStyle}>
                                            <Typography sx={tThemes.HeadlineH5Black}>{t("main.photoCrop.miniatureChoice")}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={themes.dialogContainerBodyStyle(medias)}>
                                        <Box sx={themes.dialogContainerBodyInsideStyle(medias)}>
                                            <Typography sx={tThemes.Body2Black}>{t("main.photoCrop.chooseArea")}</Typography>
                                            <Box sx={themes.dialogContainerMiniCropper}>
                                                <ImageCropperMini
                                                    handleSaveMiniComp={handleSaveMiniComp}
                                                    imageSrc={TeacherQuestionnaireStore.temporaryCroppedPhoto}
                                                    setCropper={setCropperMini}
                                                    height={292}
                                                    width={202}
                                                    checker={checker}
                                                    setChecker={setChecker}
                                                />
                                                <Box sx={themes.dialogContainerMiniCropperMiniatures}>
                                                    <img
                                                        height={"170px"}
                                                        width={"160px"}
                                                        className="TeacherQuestionnaireMiniatures"
                                                        alt="not found"
                                                        src={
                                                            TeacherQuestionnaireStore.temporaryCroppedMiniature === ""
                                                                ? TeacherQuestionnaireStore.temporaryCroppedPhoto
                                                                : TeacherQuestionnaireStore.temporaryCroppedMiniature
                                                        }
                                                        style={{ borderRadius: "16px" }}
                                                    />
                                                    <img
                                                        height={"80px"}
                                                        width={"80px"}
                                                        className="TeacherQuestionnaireMiniatures"
                                                        alt="not found"
                                                        src={
                                                            TeacherQuestionnaireStore.temporaryCroppedMiniature === ""
                                                                ? TeacherQuestionnaireStore.temporaryCroppedPhoto
                                                                : TeacherQuestionnaireStore.temporaryCroppedMiniature
                                                        }
                                                        style={{ borderRadius: "80px" }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box sx={themes.cardButtonsGroupStyle(medias)}>
                                                <SButton
                                                    variant="unshadowed"
                                                    padding="10px 20px 11px 20px"
                                                    onClick={handleDialogSave}
                                                >
                                                    {t("main.done")}
                                                </SButton>
                                                <SButton
                                                    variant="secondary"
                                                    padding="10px 20px 11px 20px"
                                                    onClick={handleDialogClose}
                                                >
                                                    {t("main.cancel")}
                                                </SButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Dialog>
                </ThemeProvider>
            ) : (
                <MyClassesDrawer
                    open={TeacherQuestionnaireStore.openCropDialog}
                    close={handleDialogClose}
                >
                    {DrawerList}
                </MyClassesDrawer>
            )}
        </>
    );
});

export default memo(PhotoCropStep);
