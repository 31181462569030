export const experienceContentContainerStyle = {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 10,
};

export const experienceItemStyle = (medias) => {
    return {
        display: "flex",
        width: medias.sm ? "827px" : "320px",
        alignItems: "flex-start",
        gap: "6px",
    };
};

export const experienceInfoStyle = {
    color: "var(--text-black, #222)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const experienceYearStyle = {
    color: "var(--text-dark-grey, #67677A)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};
