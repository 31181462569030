import { Box, ClickAwayListener, Grid, IconButton, MenuItem, MenuList, Paper, Popper, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { Folder } from "assets/MiniChat/SVG/Folder";
import { SearchIcon } from "assets/MiniChat/SVG/SearchIcon";
import React, { memo } from "react";
import * as themes from "./ChatTabsSearchThemes";
import { useState } from "react";
import { Create } from "assets/MiniChat/SVG/Сreate";
import { observer } from "mobx-react-lite";
import { convertDataInTabsChat } from "components/ChatBodyBlock/CommonData/helper_functions";
import FullChatStore from "store/FullChatStore";
import { useMemo } from "react";
import SButton from "../../../UI/SButton";
import { FoldersBox, PlusIcon } from "./ChatTabsSearchThemes";

const ChatTabsSearch = observer(({ children, ...props }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [open, setOpen] = useState(false);

    const [hovered, setHovered] = useState(false);

    const UpdateFolders = useMemo(() => {
        return convertDataInTabsChat(FullChatStore.tabsData);
    }, [FullChatStore.tabsData]);

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const handleOpen = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen(true);
    };

    const handleChange = (event) => {
        props.setName(event.target.value);
    };

    const handleFolderSelect = (value, event) => {
        console.log(value);
        FullChatStore.SetFolderProcessingStep(0);
        props.setFolderChats(value);
        setAnchorEl(null);
        setOpen(false);
    };

    return (
        <Box sx={themes.TeacherField}>
            <Popper
                open={open}
                anchorEl={anchorEl}
                sx={{ zIndex: "900" }}
                placement="bottom-start"
            >
                <Paper sx={themes.PaperStyles}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList sx={themes.MenuList}>
                            <Box sx={themes.FoldersContainer(UpdateFolders)}>
                                <MenuItem
                                    onMouseEnter={() => setHovered(true)}
                                    onMouseLeave={() => setHovered(false)}
                                    onClick={handleFolderSelect.bind(this, null)}
                                    disableRipple
                                    sx={themes.MenuItem}
                                >
                                    Создать новую папку
                                    <Box sx={themes.PlusIcon}>
                                        <Create color={hovered ? "#6212FF" : "#4E4E4E"} />
                                    </Box>
                                </MenuItem>
                            </Box>
                            <Box sx={themes.FoldersBox(UpdateFolders)}>
                                {UpdateFolders.map(
                                    (item, index) =>
                                        item.index > 3 && (
                                            <MenuItem
                                                key={index}
                                                onClick={handleFolderSelect.bind(this, item)}
                                                disableRipple
                                                sx={themes.MenuItem}
                                            >
                                                {item.name}
                                            </MenuItem>
                                        ),
                                )}
                            </Box>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
            <Box sx={themes.SearchBlock}>
                <TextField
                    placeholder="Найти по имени"
                    fullWidth
                    value={props.name}
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={themes.TextStyles}
                />
                <SButton
                    variant="icon"
                    onClick={handleOpen}
                >
                    <Folder />
                </SButton>
            </Box>
        </Box>
    );
});

export default memo(ChatTabsSearch);
