import React from "react";
import orderResponseStore from "store/OrderResponseStore";
import OrderResponseCircleAvatar from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseCircleAvatar/OrderResponseCircleAvatar";
import OrderResponeAvatarName from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponeAvatarName/OrderResponeAvatarName";
import { Box } from "@mui/material";
import { Divider } from "@mui/material";
import * as theme from "./ResponseCardStyled";
import OrderResponeDescription from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponeDescription/OrderResponeDescription";
import OrderResponseComplainDismissButton from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseComplainDismissButton/OrderResponseComplainDismissButton";
import OrderResponseTime from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseTime/OrderResponseTime";
import OrderResponsePrice from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponsePrice/OrderResponsePrice";
import OrderResponseCashback from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseCashback/OrderResponseCashback";
import OrderResponseButton from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseButton/OrderResponseButton";
import OrderResponseRating from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_OrderResponseRating/OrderResponseRating";
import OrderResponseRaitingCount from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_OrderResponseRaitingCount/OrderResponseRaitingCount";
import OrderResponseTutorsInfo from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_OrderResponseTutorsInfo/OrderResponseTutorsInfo";
import OrderResponseHiddenAvatar from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_OrderResponseHiddenAvatar/OrderResponseHiddenAvatar";
import { observer } from "mobx-react-lite";
import OnlineStatus from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_OnlineStatus/OnlineStatus";
import OrderCardWrapper from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponsCard/OrderCardWrapper";

const ResponseCard = observer(({ tutors }) => {
    const handleСhoose = () => {
        console.log("Choose");
    };

    const handleRequestPrice = () => {
        console.log("Price");
    };

    const handleOpenDismissModal = (id) => {
        orderResponseStore.setIsId(id);
        orderResponseStore.setToggleModal();
        orderResponseStore.setIsDismissClicked(true);
    };

    const handleOpenComplainModal = (id) => {
        orderResponseStore.setIsId(id);
        orderResponseStore.setToggleModal();
        orderResponseStore.setIsComplainClicked(true);
    };

    return (
        <>
            {tutors &&
                tutors.map((el) => (
                    <>
                        {el.status === "complained" ? null : (
                            <OrderCardWrapper
                                key={el.id}
                                status={el.status}
                            >
                                <Box sx={theme.cardWrapper}>
                                    <Box sx={theme.LeftSideWrapper}>
                                        {el.hiddenUser ? (
                                            <OrderResponseHiddenAvatar />
                                        ) : (
                                            <OrderResponseCircleAvatar
                                                avatar={el.avatar}
                                                name={el.name}
                                            />
                                        )}
                                        <Box sx={theme.LeftSideWrapperWithOutAvatarWrapper}>
                                            <Box sx={theme.NameOnlineStatusWrapper}>
                                                {el.hiddenUser ? <OrderResponeAvatarName>ФИО скрыто специалистом</OrderResponeAvatarName> : <OrderResponeAvatarName children={el.name} />}
                                                <OnlineStatus
                                                    isOnline={el.isOnline}
                                                    lastOnline={el.lastOnline}
                                                    hiddenUser={el.hiddenUser}
                                                />
                                            </Box>
                                            {el.rating ? (
                                                <Box sx={theme.RatingWrapper}>
                                                    <OrderResponseRating value={el.rating} />
                                                    <OrderResponseRaitingCount value={el.review} />
                                                </Box>
                                            ) : (
                                                <theme.NoReviews>отзывов пока нет</theme.NoReviews>
                                            )}
                                            {el.description ? (
                                                <OrderResponeDescription children={el.description} />
                                            ) : (
                                                <OrderResponseTutorsInfo
                                                    teaches={el.teaches}
                                                    where={el.where}
                                                    address={el.address}
                                                    education={el.education}
                                                    experience={el.experience}
                                                />
                                            )}
                                            <Divider sx={theme.Divider} />
                                            <Box sx={theme.ComplaineDismissWrapper}>
                                                <Box sx={{ display: "flex", gap: "20px" }}>
                                                    <OrderResponseComplainDismissButton handleClick={() => handleOpenComplainModal(el.id)}>Пожаловаться на отклик</OrderResponseComplainDismissButton>
                                                    {el.status === "rejected" ? null : (
                                                        <OrderResponseComplainDismissButton
                                                            key={el.id}
                                                            handleClick={() => handleOpenDismissModal(el.id)}
                                                        >
                                                            Отклонить отклик
                                                        </OrderResponseComplainDismissButton>
                                                    )}
                                                </Box>
                                                <OrderResponseTime props={el.responseTime} />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={theme.RightSideWrapper}>
                                        <Box sx={theme.PriceCashbackWrapper}>
                                            <OrderResponsePrice children={el.price} />
                                            {el.cashback === true && (
                                                <OrderResponseCashback
                                                    price={el.price}
                                                    cashback={el.cashback}
                                                />
                                            )}
                                        </Box>
                                        <OrderResponseButton
                                            text={el.price === null ? "Запросить цену" : "Выбрать"}
                                            variant="contained"
                                            handleClick={() => {
                                                if (el.price === null) {
                                                    handleRequestPrice();
                                                } else {
                                                    handleСhoose();
                                                }
                                            }}
                                        />
                                        <OrderResponseButton
                                            text="Написать"
                                            variant="outlined"
                                            handleClick={() => console.log("Написать")}
                                        />
                                    </Box>
                                </Box>
                            </OrderCardWrapper>
                        )}
                    </>
                ))}
        </>
    );
});

export default ResponseCard;
