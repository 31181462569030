import { styleProcessing } from "../../../../API/plugins/Utilities";
import { createTheme } from "@mui/material/styles";

export const CardTheme = (medias) => {
    return {
        display: "flex",
        flexDirection: "row",
        position: "relative",
        borderRadius: "16px",
        background: "#FFF",
        width: medias.sm ? "392px" : "312px",
        height: medias.sm ? "252px" : "292px", // Устанавливаем высоту контейнера
    };
};

export const AvatarStyle = (medias) => {
    return {
        width: medias.sm ? "161px" : "135px",
        height: medias.sm ? "251px" : "292px", // Устанавливаем высоту контейнера
    };
};

export const tcPlayIconBGStyle = (theme) => {
    let commonStyle = {
        position: "absolute",
        width: "40px",
        height: "40px",
        bottom: "10px",
        left: "5px",
        borderRadius: "20px",
        background: "rgba(255, 255, 255, 0.5)",
        cursor: "pointer",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "38px",
            height: "38px",
            left: "16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TCPlayIconTheme = (theme) => {
    let commonStyle = {
        components: {
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        position: "relative",
                        left: "18%",
                        top: "23%",
                        color: "#6212FF",
                        fontSize: "1.6rem",
                    },
                },
            },
        },
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            components: {
                ...commonStyle.components,
                MuiSvgIcon: {
                    ...commonStyle.components.MuiSvgIcon,
                    styleOverrides: {
                        ...commonStyle.components.MuiSvgIcon.styleOverrides,
                        root: {
                            ...commonStyle.components.MuiSvgIcon.styleOverrides.root,
                            left: "14%",
                            top: "14%",
                            color: "#6212FF",
                            fontSize: "1.8rem",
                        },
                    },
                },
            },
        },
        defaultStyle: commonStyle,
    };

    return createTheme(styleProcessing(theme, styleObject));
};
