import React from "react";
import { observer } from "mobx-react-lite";

import { Stack } from "@mui/material";

import OrderState from "./OrderStatus/OrderState";
import OrderInfo from "./OrderInfo/OrderInfo";
import StudentEndingState from "./EndingState/Student/StudentEndingState";

import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";
import UserStore from "store/UserStore";

const RightPanel = () => {
    return (
        <Stack
            direction="column"
            spacing={2.5}
        >
            <OrderState />
            <OrderInfo />
            {DiplomaStore.status === "ended" && UserStore.role === "student" ? <StudentEndingState /> : null}
        </Stack>
    );
};

export default observer(RightPanel);
