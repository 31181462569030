const TimeIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
        >
            <path
                d="M10.9998 3.29688C9.47689 3.29688 7.98818 3.74847 6.72192 4.59456C5.45566 5.44065 4.46873 6.64322 3.88594 8.05021C3.30314 9.4572 3.15066 11.0054 3.44776 12.4991C3.74487 13.9927 4.47822 15.3647 5.55509 16.4416C6.63195 17.5185 8.00396 18.2518 9.49761 18.5489C10.9913 18.846 12.5395 18.6935 13.9465 18.1107C15.3535 17.528 16.556 16.541 17.4021 15.2748C18.2482 14.0085 18.6998 12.5198 18.6998 10.9969C18.6976 8.95539 17.8856 6.99816 16.4421 5.55461C14.9985 4.11107 13.0413 3.2991 10.9998 3.29688ZM14.5051 13.6515C14.4369 13.738 14.35 13.808 14.251 13.8562C14.1519 13.9045 14.0433 13.9298 13.9331 13.9302C13.7673 13.9315 13.6063 13.8744 13.4784 13.7689L10.5451 11.5689C10.4587 11.5005 10.3888 11.4136 10.3406 11.3146C10.2923 11.2156 10.267 11.107 10.2665 10.9969V5.86354C10.2665 5.66905 10.3437 5.48252 10.4813 5.345C10.6188 5.20747 10.8053 5.13021 10.9998 5.13021C11.1943 5.13021 11.3808 5.20747 11.5184 5.345C11.6559 5.48252 11.7331 5.66905 11.7331 5.86354V10.6449L14.3879 12.6249C14.4642 12.6837 14.528 12.7573 14.5753 12.8412C14.6227 12.9251 14.6528 13.0177 14.6637 13.1134C14.6746 13.2092 14.6662 13.3061 14.639 13.3986C14.6118 13.491 14.5662 13.577 14.5051 13.6515Z"
                fill="#DADADF"
            />
            <path
                d="M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476623 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C21.9965 8.08371 20.8364 5.28787 18.7743 3.22574C16.7121 1.16361 13.9163 0.00354751 11 0ZM11 20.2155C9.17734 20.2155 7.39561 19.6751 5.88012 18.6624C4.36463 17.6498 3.18346 16.2106 2.48595 14.5266C1.78845 12.8427 1.60595 10.9898 1.96154 9.20214C2.31712 7.41449 3.19481 5.77244 4.48363 4.48363C5.77245 3.19481 7.4145 2.31711 9.20214 1.96153C10.9898 1.60595 12.8427 1.78845 14.5266 2.48595C16.2106 3.18345 17.6498 4.36463 18.6624 5.88012C19.6751 7.39561 20.2155 9.17734 20.2155 11C20.2132 13.4434 19.2415 15.786 17.5138 17.5138C15.786 19.2415 13.4434 20.2132 11 20.2155Z"
                fill="#DADADF"
            />
        </svg>
    );
};

export default TimeIcon;
