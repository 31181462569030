import { createTheme } from "@mui/material";
import { styleProcessing } from "API/plugins/Utilities";

export const PlatinumIcon = {
    position: "absolute",
    left: "220px",
    bottom: "50px",
};

export const LevelRow = {
    position: "relative",
};

export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
};

export const WhiteContainer = (theme) => {
    let commonStyle = {
        padding: "20px",
        width: "249px",
        height: "258px",
        borderRadius: "20px",
        backgroundColor: "white",
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.05)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
            height: "auto",
            boxSizing: "border-box",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const theme = createTheme({
    palette: {
        primary: {
            main: "#6212FF",
        },
    },
    components: {
        MuiLinearProgress: {
            styleOverrides: {
                bar: {
                    borderRadius: "9px",
                },
                root: {
                    backgroundColor: "rgba(98, 18, 255, 0.10)",
                },
            },
        },
    },
});

export const theme2 = createTheme({
    palette: {
        primary: {
            main: "#6212FF",
        },
    },
    components: {
        MuiLinearProgress: {
            styleOverrides: {
                bar: {
                    borderRadius: "9px",
                    background: "linear-gradient(79deg, #FF7612 -15.3%, #E816EC 145.68%)",
                },
                root: {
                    backgroundColor: "rgba(98, 18, 255, 0.10)",
                },
            },
        },
    },
});

export const pointsOutOfContainer = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const ProgressContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const LinearProgress = {
    borderRadius: "9px",
    height: "6px",
};

export const ForecastedLevel = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const PrivilegesContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    maxWidth: "229px",
};
