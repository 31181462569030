export const ContentProps = {
    sx: {
        background: "#6212FF",
        borderRadius: "10px",
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
    },
};

export const WelcomeBackSnackbar = {
    "& .MuiSnackbarContent-root": {
        width: "fit-content",
        minWidth: 0,
    },
};
