const TxtIconSVG = function (props) {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 34.48V5.52C0 2.48 2.48 0 5.52 0H25.576V8.864C25.576 11.904 27.056 13.368 30.096 13.368H40V34.48C40 37.52 37.52 40 34.48 40H5.52C2.48 40 0 37.52 0 34.48ZM30.0962 11.1677C28.8162 11.1677 27.7762 10.1277 27.7762 8.86369V0.879687L39.0082 11.1677H30.0962Z"
                fill="#6369F1"
            />
            <path
                d="M8.5625 20.3945V19H15.1328V20.3945H12.6836V27H11.0117V20.3945H8.5625Z"
                fill="white"
            />
            <path
                d="M18.3508 19L19.9641 21.7266H20.0266L21.6477 19H23.5578L21.1164 23L23.6125 27H21.6672L20.0266 24.2695H19.9641L18.3234 27H16.3859L18.8898 23L16.4328 19H18.3508Z"
                fill="white"
            />
            <path
                d="M24.8734 20.3945V19H31.4437V20.3945H28.9945V27H27.3227V20.3945H24.8734Z"
                fill="white"
            />
        </svg>
    );
};

export default TxtIconSVG;
