export const EmptyClassesBlock = {
    borderRadius: "20px",
    background: "#FFF",
    width: "970px",
    padding: "40px 0 40px 40px",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
};

export const TextBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const DescriptionBlock = {
    maxWidth: "620px",
};

export const Button = {
    width: "fit-content",
};
