import { Typography } from "@mui/material";
import React, { useState, useEffect, memo } from "react";
import * as theme from "./TimeZoneStyled";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { observer } from "mobx-react-lite";

const TimeZone = observer(({ children, ...props }) => {
    const moment = require("moment-timezone");

    const currentTimezone = TeacherQuestionnaireStore.teacher.timeZone.utc[0];

    const [currentTime, setCurrentTime] = useState("");

    useEffect(() => {
        const updateTime = () => {
            const time = moment().tz(currentTimezone).format("HH:mm");

            setCurrentTime(time);
        };

        updateTime();

        const intervalId = setInterval(updateTime, 60000);

        return () => clearInterval(intervalId);
    }, [currentTimezone]);

    return (
        <>
            <Typography sx={theme.timeStyle}>{currentTime}</Typography>
            <Typography sx={theme.timeZoneStyle}>{TeacherQuestionnaireStore.teacher.timeZone.text.match(/\(([^)]+)\)/)[1]}</Typography>
        </>
    );
});

export default memo(TimeZone);
