import React from "react";
import { memo } from "react";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import CashbackPromoBodyBlock from "components/CashbackPromoBodyBlock/CashbackPromoBodyBlock";

const CashbackPromoPage = ({ children, ...props }) => {
    return (
        <ScrollToTop>
            <CashbackPromoBodyBlock />
        </ScrollToTop>
    );
};

export default memo(CashbackPromoPage);
