export const RightPanelDivider = (medias) => {
    return {
        display: medias.sm ? "block" : "none",
        width: "1px",
        height: "30px",
        background: "#DADADF",
    };
};

export const RightPanel = {
    display: "flex",
    alignItems: "center",
    gap: "20px",
};

export const LoginButton = {
    display: "flex",
    flexDirection: "column",
    textTransform: "none",
    ":hover": {
        background: "transparent",
    },
};

export const ButtonsBlock = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
};

export const DividerAndBalance = {
    display: "flex",
    alignItems: "center",
    gap: "20px",
};

export const ChatsAndFavourites = {
    display: "flex",
    alignItems: "center",
    gap: "0px",
};
