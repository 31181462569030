import React, { memo } from "react";
import * as themes from "./AddToFolderThemes";
import { ClickAwayListener, MenuItem, MenuList, Paper, Popper, Box } from "@mui/material";
import FullChatStore from "store/FullChatStore";
import { Check } from "assets/MiniChat/SVG/Check";
import { IconBackMini } from "assets/MiniChat/SVG/IconBackMini";
import { observer } from "mobx-react-lite";

const AddToFolder = observer(({ children, ...props }) => {
    return (
        <Popper
            open={props.open}
            anchorEl={props.anchor}
            sx={themes.Popper}
        >
            <Paper sx={themes.Paper}>
                <ClickAwayListener onClickAway={props.handleClose}>
                    <Box>
                        <MenuList sx={themes.MenuList}>
                            <Box style={themes.UpperMenu}>
                                {FullChatStore.tabsData.folders.map(
                                    (item, index) =>
                                        index > 3 && (
                                            <MenuItem
                                                key={index}
                                                disableRipple
                                                sx={themes.MenuItem2}
                                                onClick={() => {
                                                    props.UpdateChat.folders.includes(item.index) ? props.delFromFolder(item.index) : props.addToFolder(item.index);
                                                }}
                                            >
                                                <Box style={{ width: "10vw" }}>{item.name}</Box>
                                                {props.UpdateChat.folders.includes(item.index) && (
                                                    <Box>
                                                        <Check />
                                                    </Box>
                                                )}
                                            </MenuItem>
                                        ),
                                )}
                            </Box>
                            <MenuItem
                                disableRipple
                                sx={themes.MenuItem2}
                                onClick={() => {
                                    props.SetOpenChatFoldersModal(false);
                                    props.setOpenOuter(true);
                                }}
                            >
                                <Box style={{ paddingRight: "1vw", paddingTop: "0.6vh" }}>
                                    <IconBackMini />
                                </Box>
                                <Box>Назад</Box>
                            </MenuItem>
                        </MenuList>
                    </Box>
                </ClickAwayListener>
            </Paper>
        </Popper>
    );
});

export default memo(AddToFolder);
