/**
 * @fileoverview gRPC-Web generated client stub for pb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.12.4
// source: internal/pb/rates.proto

/* eslint-disable */
// @ts-nocheck

const grpc = {};
grpc.web = require("grpc-web");

const proto = {};
proto.pb = require("./rates_pb.js");

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.RatesServiceClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options.format = "text";

    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */
    this.client_ = new grpc.web.GrpcWebClientBase(options);

    /**
     * @private @const {string} The hostname
     */
    this.hostname_ = hostname.replace(/\/+$/, "");
};

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.RatesServicePromiseClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options.format = "text";

    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */
    this.client_ = new grpc.web.GrpcWebClientBase(options);

    /**
     * @private @const {string} The hostname
     */
    this.hostname_ = hostname.replace(/\/+$/, "");
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.CreateRateRequest,
 *   !proto.pb.CreateRateResponse>}
 */
const methodDescriptor_RatesService_CreateRate = new grpc.web.MethodDescriptor(
    "/pb.RatesService/CreateRate",
    grpc.web.MethodType.UNARY,
    proto.pb.CreateRateRequest,
    proto.pb.CreateRateResponse,
    /**
     * @param {!proto.pb.CreateRateRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.CreateRateResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.CreateRateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.CreateRateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.CreateRateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.RatesServiceClient.prototype.createRate = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.RatesService/CreateRate", request, metadata || {}, methodDescriptor_RatesService_CreateRate, callback);
};

/**
 * @param {!proto.pb.CreateRateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.CreateRateResponse>}
 *     Promise that resolves to the response
 */
proto.pb.RatesServicePromiseClient.prototype.createRate = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.RatesService/CreateRate", request, metadata || {}, methodDescriptor_RatesService_CreateRate);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.RecentRatesRequest,
 *   !proto.pb.RecentRatesResponse>}
 */
const methodDescriptor_RatesService_RecentRates = new grpc.web.MethodDescriptor(
    "/pb.RatesService/RecentRates",
    grpc.web.MethodType.UNARY,
    proto.pb.RecentRatesRequest,
    proto.pb.RecentRatesResponse,
    /**
     * @param {!proto.pb.RecentRatesRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.RecentRatesResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.RecentRatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.RecentRatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.RecentRatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.RatesServiceClient.prototype.recentRates = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.RatesService/RecentRates", request, metadata || {}, methodDescriptor_RatesService_RecentRates, callback);
};

/**
 * @param {!proto.pb.RecentRatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.RecentRatesResponse>}
 *     Promise that resolves to the response
 */
proto.pb.RatesServicePromiseClient.prototype.recentRates = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.RatesService/RecentRates", request, metadata || {}, methodDescriptor_RatesService_RecentRates);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.RatesRequest,
 *   !proto.pb.RatesResponse>}
 */
const methodDescriptor_RatesService_Rates = new grpc.web.MethodDescriptor(
    "/pb.RatesService/Rates",
    grpc.web.MethodType.UNARY,
    proto.pb.RatesRequest,
    proto.pb.RatesResponse,
    /**
     * @param {!proto.pb.RatesRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.RatesResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.RatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.RatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.RatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.RatesServiceClient.prototype.rates = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.RatesService/Rates", request, metadata || {}, methodDescriptor_RatesService_Rates, callback);
};

/**
 * @param {!proto.pb.RatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.RatesResponse>}
 *     Promise that resolves to the response
 */
proto.pb.RatesServicePromiseClient.prototype.rates = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.RatesService/Rates", request, metadata || {}, methodDescriptor_RatesService_Rates);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.CreateAnswerToRateRequest,
 *   !proto.pb.CreateAnswerToRateResponse>}
 */
const methodDescriptor_RatesService_CreateAnswerToRate = new grpc.web.MethodDescriptor(
    "/pb.RatesService/CreateAnswerToRate",
    grpc.web.MethodType.UNARY,
    proto.pb.CreateAnswerToRateRequest,
    proto.pb.CreateAnswerToRateResponse,
    /**
     * @param {!proto.pb.CreateAnswerToRateRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.CreateAnswerToRateResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.CreateAnswerToRateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.CreateAnswerToRateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.CreateAnswerToRateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.RatesServiceClient.prototype.createAnswerToRate = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.RatesService/CreateAnswerToRate", request, metadata || {}, methodDescriptor_RatesService_CreateAnswerToRate, callback);
};

/**
 * @param {!proto.pb.CreateAnswerToRateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.CreateAnswerToRateResponse>}
 *     Promise that resolves to the response
 */
proto.pb.RatesServicePromiseClient.prototype.createAnswerToRate = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.RatesService/CreateAnswerToRate", request, metadata || {}, methodDescriptor_RatesService_CreateAnswerToRate);
};

module.exports = proto.pb;
