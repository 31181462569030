import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as textThemes from "themes/TextThemes";
import * as themes from "./TeacherQuestionnaireDoneBodyBlockThemes";
import SButton from "components/UI/SButton";
import React from "react";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { observer } from "mobx-react-lite";
import { useMedias } from "API/mediasHook";

const TeacherQuestionnaireDoneBodyBlock = observer(({ children, ...props }) => {
    const navigate = useNavigate();

    const medias = useMedias();

    const { t } = useTranslation();

    const handleClickGoToPersonalAccount = () => {
        navigate("/account");
        TeacherQuestionnaireStore.setVerifiedProfile(false);
    };

    return (
        <Box sx={themes.TeacherQuestionnaireDoneBodyBlock}>
            <Box sx={themes.TeacherQuestionnaireDoneBodyBlockContent(medias)}>
                <Box sx={themes.TextBlock}>
                    <Typography sx={textThemes.HeadlineH41Black}>{t("TeacherQuestionnaire.questionnaireHasBeenSentTemporary")}</Typography>
                    <Typography sx={textThemes.Body1Black}>{t("TeacherQuestionnaire.meantimeYouCanGoTemporary")}</Typography>
                </Box>
                {/*<SButton*/}
                {/*    variant="arrow"*/}
                {/*    padding="11px 30px 12px 30px"*/}
                {/*    onClick={handleClickGoToPersonalAccount}*/}
                {/*>*/}
                {/*    {t("TeacherQuestionnaire.goToYourPersonalAccount")}*/}
                {/*</SButton>*/}
            </Box>
        </Box>
    );
});

export default memo(TeacherQuestionnaireDoneBodyBlock);
