import React, { memo } from "react";
import * as themes from "./StateOneStyle";
import Bulb from "./bulb.png";
import Clock from "./clock.png";
import card_info from "./card_info.png";
import RequestCreateEnd from "./request_create_end.png";
import RequestCreateSteps from "./request_create_steps.png";
import { Box } from "@mui/material";

const StateOne = ({ children, ...props }) => {
    return (
        <Box>
            <Box sx={themes.bulbIconContainer}>
                <img
                    style={themes.bulbIconImg}
                    src={Bulb}
                    alt="Bulb"
                />
            </Box>

            <Box sx={themes.clockIconContainer}>
                <img
                    style={themes.clockIconImg}
                    src={Clock}
                    alt="Clock"
                />
            </Box>

            <Box sx={themes.rateIcon}>
                <Box sx={themes.rateIconText}>5.0</Box>
            </Box>

            <Box sx={themes.CardInfoImg}>
                <img
                    src={card_info}
                    alt="card_info"
                />
            </Box>

            <Box sx={themes.RequestCreateEndImg}>
                <img
                    src={RequestCreateEnd}
                    alt="RequestCreateEnd"
                />
            </Box>

            <Box sx={themes.RequestCreateStepsImg}>
                <img
                    src={RequestCreateSteps}
                    alt="RequestCreateSteps"
                />
            </Box>

            <Box sx={themes.bonusIcon}>
                <Box sx={themes.bonusIconText}>+35</Box>
            </Box>
        </Box>
    );
};

export default memo(StateOne);
