import React from "react";
import Datepicker from "./CustomDatePicker/CustomDatePicker";
import CustomTimeInput from "./CustomTimePicker/CustomTimePicker";
import filterStore from "store/FilterStore";
import Box from "@mui/material/Box";

const DateTimeInput = ({ selectedDate, setSelectedDate, minDate, time, setTime }) => {
    const handleClick = () => {
        filterStore.setSubmitButtonFilter(false);
    };

    return (
        <Box
            onClick={handleClick}
            sx={{
                display: "flex",
                gap: "24px",
                borderBottom: "1px solid #BBBBC8",
                paddingBottom: "10px",
                width: "260px",
            }}
        >
            <Datepicker
                onClick={() => console.log("выше на 1")}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                minDate={minDate}
            />

            <CustomTimeInput
                time={time}
                setTime={setTime}
            />
        </Box>
    );
};

export default DateTimeInput;
