import { InputAdornment, TextField, Typography, Box, Drawer } from "@mui/material";
import CardsSmall from "assets/SettingsAssets/SVG/cardssmall";
import Info from "assets/SettingsAssets/SVG/info";
import ModalSettings from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsBig/ModalSettings";
import React from "react";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import * as styles from "./ModalAddCardThemes";
import * as textStyles from "themes/TextThemes";
import SButton from "components/UI/SButton";
import UserStore from "store/UserStore";
import VisaPaymentIcon from "assets/PaymentAssets/SVG/VisaPaymentIcon";
import MastercardPaymentIcon from "assets/PaymentAssets/SVG/MastercardPaymentIcon";
import MirPaymentIcon from "assets/PaymentAssets/SVG/MirPaymentIcon";
import TeacherSettingsStore from "store/TeacherSettingsStore";
import DrawerHeader from "components/SettingsBodyBlock/StudentSettings/DrawerHeader/DrawerHeader";
import { useMedias } from "API/mediasHook";

const ModalAddCard = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        props.handleSave();
        UserStore.setCardNumber(TeacherSettingsStore.temporaryCardNumber);
        UserStore.setCardExpirationDate(TeacherSettingsStore.temporaryCardExpirationDate);
        UserStore.setCardCVC(TeacherSettingsStore.temporaryCardCVC);
    };

    const handleChangeCVC = (e) => {
        const isCVC = (str) => /^[0-9]{0,3}$/.test(str);

        const { value } = e.target;

        if (isCVC(value)) {
            TeacherSettingsStore.setTemporaryCardCVC(value);
        }
    };

    const handleChangeExpirationDate = (event) => {
        const { value } = event.target;

        const formattedValue = value.replace(/\D/g, "").slice(0, 4);

        let formattedOutput = "";

        for (let i = 0; i < formattedValue.length; i++) {
            if (i === 2 && formattedValue.length > 2) {
                formattedOutput += "/" + formattedValue[i];
            } else {
                formattedOutput += formattedValue[i];
            }
        }

        TeacherSettingsStore.setTemporaryCardExpirationDate(formattedOutput);
    };

    const handleChangeCardNumber = (event) => {
        let input = event.target.value.replace(/\D/g, "");

        input = input.replace(/(\d{4})/g, "$1 ").trim();
        input = input.slice(0, 19); // 16 digits + 3 spaces
        TeacherSettingsStore.setTemporaryCardNumber(input);
    };

    const DrawerList = (
        <Box
            container
            spacing={0}
            sx={{ height: "68%" }}
        >
            <DrawerHeader
                text={t("main.settings.paymentMethodsBlock.newCardAdd")}
                handleClose={Close}
            />
            <Box sx={styles.AddCardFields(medias)}>
                <TextField
                    sx={styles.AddCardNumber}
                    fullWidth
                    variant="outlined"
                    placeholder="1234 1234 1234 1234"
                    value={TeacherSettingsStore.temporaryCardNumber}
                    onChange={handleChangeCardNumber}
                    inputProps={{
                        maxLength: 19, // 16 digits + 3 spaces
                        inputMode: "numeric",
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                {TeacherSettingsStore.temporaryCardNumber === null ? (
                                    <CardsSmall />
                                ) : TeacherSettingsStore.temporaryCardNumber.slice(0, 1) === "4" ? (
                                    <VisaPaymentIcon />
                                ) : TeacherSettingsStore.temporaryCardNumber.slice(0, 1) === "5" ? (
                                    <MastercardPaymentIcon />
                                ) : TeacherSettingsStore.temporaryCardNumber.slice(0, 1) === "2" ? (
                                    <MirPaymentIcon />
                                ) : (
                                    <CardsSmall />
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
                <Box sx={styles.AddCardBottomFields}>
                    <TextField
                        value={TeacherSettingsStore.temporaryCardExpirationDate}
                        onChange={handleChangeExpirationDate}
                        sx={styles.AddMonthYear}
                        fullWidth
                        variant="outlined"
                        placeholder={t("main.settings.paymentMethodsBlock.mmyy")}
                    />
                    <TextField
                        value={TeacherSettingsStore.temporaryCardCVC}
                        onChange={handleChangeCVC}
                        sx={styles.AddCVC}
                        fullWidth
                        variant="outlined"
                        placeholder="CVC"
                        inputProps={{
                            maxLength: 5, // Max length is 5 for MM/YY format
                            inputMode: "numeric", // Show numeric keypad on mobile
                        }}
                    />
                </Box>
            </Box>

            <Box sx={styles.AddCardInfoAndButtonWrapper}>
                <SButton
                    style={styles.AddCardButton}
                    padding="10px 20px 11px 20px"
                    variant="unshadowed"
                    onClick={Save}
                    disabled={TeacherSettingsStore.cardButtonDisabled()}
                >
                    {t("main.settings.paymentMethodsBlock.addCard")}
                </SButton>
                <Box sx={styles.InfoSecuredBlock}>
                    <Box>
                        <Info />
                    </Box>
                    <Typography sx={textStyles.Caption2DarkGrey}>{t("main.settings.paymentMethodsBlock.infoSecured")}</Typography>
                </Box>
            </Box>
        </Box>
    );

    return (
        <>
            {medias.sm ? (
                <ModalSettings
                    visible={props.open}
                    handleClose={Close}
                    header={t("main.settings.paymentMethodsBlock.newCardAdd")}
                >
                    <Box
                        container
                        spacing={0}
                    >
                        <Box sx={styles.AddCardFields}>
                            <TextField
                                sx={styles.AddCardNumber}
                                fullWidth
                                variant="outlined"
                                placeholder="1234 1234 1234 1234"
                                value={TeacherSettingsStore.temporaryCardNumber}
                                onChange={handleChangeCardNumber}
                                inputProps={{
                                    maxLength: 19, // 16 digits + 3 spaces
                                    inputMode: "numeric",
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {TeacherSettingsStore.temporaryCardNumber === null ? (
                                                <CardsSmall />
                                            ) : TeacherSettingsStore.temporaryCardNumber.slice(0, 1) === "4" ? (
                                                <VisaPaymentIcon />
                                            ) : TeacherSettingsStore.temporaryCardNumber.slice(0, 1) === "5" ? (
                                                <MastercardPaymentIcon />
                                            ) : TeacherSettingsStore.temporaryCardNumber.slice(0, 1) === "2" ? (
                                                <MirPaymentIcon />
                                            ) : (
                                                <CardsSmall />
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box sx={styles.AddCardBottomFields}>
                                <TextField
                                    value={TeacherSettingsStore.temporaryCardExpirationDate}
                                    onChange={handleChangeExpirationDate}
                                    sx={styles.AddMonthYear}
                                    fullWidth
                                    variant="outlined"
                                    placeholder={t("main.settings.paymentMethodsBlock.mmyy")}
                                />
                                <TextField
                                    value={TeacherSettingsStore.temporaryCardCVC}
                                    onChange={handleChangeCVC}
                                    sx={styles.AddCVC}
                                    fullWidth
                                    variant="outlined"
                                    placeholder="CVC"
                                    inputProps={{
                                        maxLength: 5, // Max length is 5 for MM/YY format
                                        inputMode: "numeric", // Show numeric keypad on mobile
                                    }}
                                />
                            </Box>
                        </Box>
                        <SButton
                            style={styles.AddCardButton}
                            padding="10px 20px 11px 20px"
                            variant="unshadowed"
                            onClick={Save}
                            disabled={TeacherSettingsStore.cardButtonDisabled()}
                        >
                            {t("main.settings.paymentMethodsBlock.addCard")}
                        </SButton>
                        <Box sx={styles.InfoSecuredBlock}>
                            <Box>
                                <Info />
                            </Box>
                            <Typography sx={textStyles.Caption2DarkGrey}>{t("main.settings.paymentMethodsBlock.infoSecured")}</Typography>
                        </Box>
                    </Box>
                </ModalSettings>
            ) : (
                <Drawer
                    open={props.open}
                    onClose={Close}
                >
                    {DrawerList}
                </Drawer>
            )}
        </>
    );
});

export default memo(ModalAddCard);
