import React from "react";
import { TextField, Box, Typography } from "@mui/material";
import UserStore from "store/UserStore";
import ButtonSave from "components/UI/ButtonSave";
import LikeDislikeBlock from "components/AccountBodyBlock/StudentAccount/Stories/Stories/LikeDislikeBlock/LikeDislikeBlock";
import * as textThemes from "themes/TextThemes";
import * as themes from "components/AccountBodyBlock/StudentAccount/Stories/Stories/StoryThemes";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import { observer } from "mobx-react-lite";

const HowToHelpStory = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const [tempText, setTempText] = React.useState("");

    return (
        <Box sx={themes.StoryBox}>
            <Box sx={themes.HowToHelpOuter(UserStore.stories.HowToHelpStory.answer === null)}>
                {UserStore.stories.HowToHelpStory.answer === null ? (
                    <Box sx={themes.StoryForm}>
                        <Typography sx={textThemes.Caption700White}>{t("main.studentAccount.stories.howToHelp")}</Typography>
                        <TextField
                            placeholder={t("main.writeSomething")}
                            fullWidth
                            multiline
                            rows={5}
                            value={tempText}
                            onChange={(event) => {
                                setTempText(event.target.value);
                            }}
                            sx={themes.TextField}
                        />
                        <ButtonSave
                            onClick={() => {
                                UserStore.setStoryAnswer("HowToHelpStory", tempText);
                            }}
                            sx={themes.SendButton}
                        >
                            {t("main.send")}
                        </ButtonSave>
                    </Box>
                ) : (
                    <Box sx={themes.StoryText}>
                        <Typography sx={textThemes.Caption700White}>{t("main.studentAccount.stories.thanksForSharing")}</Typography>
                        <Typography sx={textThemes.Body1White}>{t("main.studentAccount.stories.weTryingToBeBetter")}</Typography>
                    </Box>
                )}
            </Box>
            <LikeDislikeBlock story="HowToHelpStory" />
        </Box>
    );
});

export default memo(HowToHelpStory);
