import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import { Stack, IconButton, InputAdornment, Box } from "@mui/material";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";

import { StyledTextFieldSearchChat } from "components/ChatBodyBlock/ChatListBlock/ChatTabsSearch/styled";

import IconSearchFilter from "assets/ChatAssets/SVG/iconSearchChat";
import IconClose from "assets/ChatAssets/SVG/iconClose";

import FullChatStore from "store/FullChatStore";

const SearchMessagesPanel = ({ sendJsonMessage, page, SetPage }) => {
    const handleCloseSearchMessages = () => {
        FullChatStore.SetShowSearchMessagesModal(false);
        FullChatStore.SetShowSearchMessagesResultModal(false);
        FullChatStore.SetSearchMessageText("");
        FullChatStore.SetToScrollMessage("");
        SetPage(1);
    };

    const handleClearSearchMessageField = () => {
        FullChatStore.SetShowSearchMessagesResultModal(false);
        FullChatStore.SetSearchMessageText("");
        FullChatStore.SetFoundMessages([]);
        FullChatStore.SetToScrollMessage("");
        SetPage(1);
    };

    const handleSearchMessages = () => {
        if (FullChatStore.searchMessageText) {
            FullChatStore.SetShowSearchMessagesResultModal(true);
            sendJsonMessage({
                action: "search_message",
                chat_id: FullChatStore.id,
                message: FullChatStore.searchMessageText,
            });
        }
    };

    const handleSetPage = (up) => {
        let pg = page;

        up === true ? SetPage(pg + 1) : SetPage(pg - 1);
    };

    return FullChatStore.showSearchMessagesModal ? (
        <Stack
            sx={{
                width: "100%",
                height: "46px",
                backgroundColor: "white",
                borderBottom: "solid 1px rgba(218, 218, 223, 1)",
            }}
            direction={"row"}
            alignItems={"center"}
        >
            <IconButton
                disabled={page === 1}
                onClick={() => {
                    handleSetPage(false);
                }}
            >
                <ExpandLessIcon />
            </IconButton>
            <IconButton
                disabled={FullChatStore.foundMessages?.length === undefined || FullChatStore.foundMessages?.length <= 2 || page * 2 > FullChatStore.foundMessages?.length}
                onClick={() => {
                    handleSetPage(true);
                }}
            >
                <ExpandMoreIcon />
            </IconButton>
            <StyledTextFieldSearchChat
                value={FullChatStore.searchMessageText}
                onChange={(e) => {
                    FullChatStore.SetSearchMessageText(e.target.value);
                    handleSearchMessages();
                }}
                sx={{
                    margin: "12px 12px",
                    width: "90%",
                    height: "32px",
                    maxHeight: "32px",
                }}
                placeholder="Искать в чате"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Box sx={{ height: "24px" }}>
                                <IconSearchFilter />
                            </Box>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleClearSearchMessageField}>
                                <CloseIcon
                                    sx={{
                                        width: "0.7em",
                                        height: "0.7em",
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

            <IconButton onClick={handleCloseSearchMessages}>
                <IconClose />
            </IconButton>
        </Stack>
    ) : null;
};

export default observer(SearchMessagesPanel);
