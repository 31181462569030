import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./LessonsPlaceThemes";
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, IconButton, Typography } from "@mui/material";
import CatalogStore from "store/CatalogStore";
import { CloseRounded, ExpandMore } from "@mui/icons-material";
import { ThemeProvider } from "@mui/material/styles";
import SingleChoiceLightFilter from "components/UI/SingleChoiceLightFilter";
import { useMedias } from "../../../API/mediasHook";

const LessonsPlace = observer(({ children, ...props }) => {
    const handleChange = () => {
        CatalogStore.ToggleLessonsPlaceExpanded();
    };

    const handleDelete = (value, event) => {
        console.log(value);
        CatalogStore.ClearLessonsPlace(value);
    };

    const processingLessonsPlace = (event, value) => {
        CatalogStore.SetLessonsPlace(value);
    };

    const medias = useMedias();

    return (
        <ThemeProvider theme={themes.LessonsPlaceAccordionTheme(medias)}>
            <Accordion
                expanded={CatalogStore.lessonsPlaceExpanded}
                onChange={handleChange}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography sx={themes.headerLessonsPlaceStyle}>Место занятий</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {CatalogStore.selectedLessonsPlaceChipList.length > 0 ? (
                        <ThemeProvider theme={themes.LessonsChipTheme}>
                            <Box sx={themes.selectedLessonsPlaceContainerStyle}>
                                {CatalogStore.selectedLessonsPlaceChipList.map((value) => (
                                    <Chip
                                        key={value.ID}
                                        label={value.LessonsPlace}
                                        deleteIcon={
                                            <IconButton onClick={handleDelete.bind(this, value)}>
                                                <CloseRounded></CloseRounded>
                                            </IconButton>
                                        }
                                        onDelete={handleDelete.bind(this, value)}
                                    />
                                ))}
                            </Box>
                        </ThemeProvider>
                    ) : null}
                    <SingleChoiceLightFilter
                        propOption={CatalogStore.optionLessonsPlace}
                        propOptions={CatalogStore.optionsLessonsPlace}
                        valueToShow={"LessonsPlace"}
                        valueToBind={"ID"}
                        processingChange={processingLessonsPlace}
                        themeWrapper={themes.FiltersLessonsPlaceTheme}
                        placeholder={"Выберите районы/станции метро"}
                        clearOnBlur={true}
                        disableClearable={false}
                        variant={"standard"}
                        freeSolo={true}
                    ></SingleChoiceLightFilter>
                </AccordionDetails>
            </Accordion>
        </ThemeProvider>
    );
});

export default memo(LessonsPlace);
