export const StatusContainer = {
    display: "flex",
    alignItems: "center",
    gap: "1px",
    borderRadius: "16px",
    padding: "2px 10px 4px 4px",
    color: "rgba(34, 34, 34, 1)",
};

export const hiredStatus = { backgroundColor: "rgba(98, 18, 255, 0.1)" };

export const inWorkStatus = { backgroundColor: "rgba(255, 146, 18, 0.14)" };

export const awaitingVerificationStatus = {
    backgroundColor: "rgba(225, 38, 38, 0.1)",
};

export const hiredStatusText = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "16px",
    width: "16px",
    fontSize: "20px",
    color: "rgba(98, 18, 255, 1)",
};

export const inWorkStatusText = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "16px",
    width: "16px",
    fontSize: "20px",
    color: "rgba(255, 146, 18, 1)",
};

export const awaitingVerificationStatusText = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "16px",
    width: "16px",
    fontSize: "20px",
    color: "rgba(225, 38, 38, 1)",
};
