export const EmptyNotifications = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "center",
    textAlign: "center",
    padding: "60px 30px",
};

export const Icon = {
    width: "30px",
};

export const EmptyNotificationsContent = {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    alignItems: "center",
    textAlign: "center",
};
