const BackAccountButton = function (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
        >
            <g clipPath="url(#clip0_5426_110585)">
                <circle
                    cx="12"
                    cy="12.5"
                    r="11.5"
                    transform="rotate(180 12 12.5)"
                    fill="white"
                    fillOpacity="0.5"
                    stroke="#222222"
                />
                <path
                    d="M16.5 13.25C16.9142 13.25 17.25 12.9142 17.25 12.5C17.25 12.0858 16.9142 11.75 16.5 11.75L16.5 13.25ZM6.96967 11.9697C6.67678 12.2626 6.67678 12.7374 6.96967 13.0303L11.7426 17.8033C12.0355 18.0962 12.5104 18.0962 12.8033 17.8033C13.0962 17.5104 13.0962 17.0355 12.8033 16.7426L8.56066 12.5L12.8033 8.25736C13.0962 7.96447 13.0962 7.48959 12.8033 7.1967C12.5104 6.90381 12.0355 6.90381 11.7426 7.1967L6.96967 11.9697ZM16.5 11.75L7.5 11.75L7.5 13.25L16.5 13.25L16.5 11.75Z"
                    fill="#222222"
                />
            </g>
            <defs>
                <clipPath id="clip0_5426_110585">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(24 24.5) rotate(180)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default BackAccountButton;
