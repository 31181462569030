import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import * as styles from "./TodayClassesThemes";
import * as textStyles from "themes/TextThemes";
import MyClassesStore from "store/MyClassesStore";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import SubjectDateBlock from "./SubjectDateBlock/SubjectDateBlock";
import TutorBlock from "./TutorBlock/TutorBlock";
import { observer } from "mobx-react-lite";
import { useMedias } from "API/mediasHook";

const TodayClasses = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <Box sx={styles.TodayClasses}>
            <Box sx={styles.TodayClassesTitle}>
                <Typography sx={textStyles.HeadlineH4White}>{t("main.myClasses.todayClass")}</Typography>
            </Box>
            <Box sx={styles.TodayClassesContent(MyClassesStore.findNearestTodayClassIndex() === null, props.type, medias)}>
                <Box sx={styles.SubjectContainer}>
                    <Typography sx={textStyles.HeadlineH5Black}>
                        {MyClassesStore.findNearestTodayClassIndex() === null ? t("main.myClasses.noClassesToday") : MyClassesStore.classes[MyClassesStore.findNearestTodayClassIndex()].subject}
                    </Typography>
                </Box>
                <Box sx={styles.DetailsPanel}>
                    <SubjectDateBlock type={props.type} />
                    <TutorBlock />
                </Box>
            </Box>
        </Box>
    );
});

export default memo(TodayClasses);
