export const TableHeader = {
    backgroundColor: "#EEEFF4",
    padding: "14px 20px 15px 20px",
    display: "flex",
    gap: "14px",
};

export const StudentBlock = {
    width: "210px",
};

export const ServiceBlock = {
    width: "356px",
};

export const PriceBlock = {
    width: "113px",
};

export const CommissionBlock = {
    width: "121px",
};
