import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./ManageChatThemes";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import VideoChatStore from "store/VideoChatStore";
import ChatIcon from "assets/VideoChatAssets/SVG/chatIcon";

const ManageChat = observer(({ children, ...props }) => {
    const toggleChatState = () => {
        VideoChatStore.SetChatState(!VideoChatStore.managementPanel.chatState);
    };

    return (
        <Box
            sx={themes.iconContainerStyle(VideoChatStore.managementPanel.chatState)}
            onClick={toggleChatState}
        >
            <ThemeProvider theme={themes.ActionButtonTheme(1, VideoChatStore.managementPanel.chatState)}>
                <Tooltip
                    title={<Typography sx={themes.tooltipStyle}>{VideoChatStore.managementPanel.chatState ? "Закрыть" : "Открыть"} чат</Typography>}
                    placement={"top"}
                >
                    <IconButton disableRipple>
                        <ChatIcon />
                    </IconButton>
                </Tooltip>
            </ThemeProvider>
        </Box>
    );
});

export default memo(ManageChat);
