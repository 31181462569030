import React from "react";

import { Box, ThemeProvider, TextField, Typography } from "@mui/material";

import * as themes from "components/OrderBodyBlock/InfoCard/ModalReportThemes";

const BigTextField = ({ placeholderText, text, setText, maxLength = 1000, tooltipText = "Достигнут лимит символов" }) => {
    return (
        <ThemeProvider theme={themes.ReportMessageTheme(text.length === maxLength)}>
            <TextField
                onChange={(e) => setText(e.target.value)}
                value={text}
                variant="outlined"
                placeholder={placeholderText}
                multiline
                rows={6}
                inputProps={{
                    maxLength: maxLength,
                }}
                FormHelperTextProps={{ component: "div" }}
                helperText={
                    <Box sx={themes.helperContainerStyle(text.length === maxLength)}>
                        {text.length === maxLength ? <Typography sx={themes.currLengthTextMaxStyle}>{tooltipText}</Typography> : null}
                        <Box sx={themes.helperCounterContainerStyle}>
                            <Typography sx={themes.currLengthTextStyle(text.length)}>{text.length}</Typography>
                            <Typography sx={themes.maxLengthTextStyle}>/{maxLength}</Typography>
                        </Box>
                    </Box>
                }
            ></TextField>
        </ThemeProvider>
    );
};

export default BigTextField;
