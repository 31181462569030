import * as themes from "./ReferralBenefitThemes";
import * as textThemes from "themes/TextThemes";
import CheckIcon from "assets/CommonAssets/SVG/CheckIcon";
import React from "react";
import Box from "@mui/material/Box";
import { memo } from "react";
import { Typography } from "@mui/material";

const ReferralBenefit = ({ children, ...props }) => {
    return (
        <Box sx={themes.BenefitBlockStyle}>
            <CheckIcon />
            <Typography sx={{ ...textThemes.Body1White, ...themes.TextBlock }}>{props.benefitText}</Typography>
        </Box>
    );
};

export default memo(ReferralBenefit);
