import React, { memo } from "react";
import * as themes from "./DescriptionBlockThemes";
import { observer } from "mobx-react-lite";
import { Typography } from "@mui/material";
import { useMedias } from "../../../../API/mediasHook";

const DescriptionBlock = observer(({ children, ...props }) => {
    const medias = useMedias();

    return <Typography sx={themes.descriptionStyle(medias)}>{props.value.Description}</Typography>;
});

export default memo(DescriptionBlock);
