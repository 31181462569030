import { createTheme } from "@mui/material/styles";

export const Notifications = {
    borderRadius: "0px 0px 40px 40px",
    background: "#F7F7F7",
    paddingTop: "40px",
    paddingBottom: "70px",
    minHeight: "800px",
    display: "flex",
    justifyContent: "center",
};

export const NotificationsContent = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "832px",
};

export const TitleBlock = {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    paddingRight: "58px",
};

export const ReadAllButton = {
    fontSize: "14px",
    lineHeight: "150%",
    fontWeight: 400,
    textTransform: "none",
    color: "#222",
    ":hover": {
        color: "#8141FF",
        bgcolor: "#F3F3F3",
    },
};

export const NotificationsPaper = (condition) => {
    return {
        background: "#fff",
        borderRadius: "20px",
        padding: condition ? "14px 4px 14px 4px" : "14px 100px",
    };
};

export const NotificationsList = {
    border: "none",
};

export const progressContainerStyle = {
    width: "100%",
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
};

export const CircularProgressTheme = createTheme({
    components: {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: "#6212FF",
                },
            },
        },
    },
});
