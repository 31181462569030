import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import * as themes from "./ScheduleThemes";
import * as textThemes from "themes/TextThemes";
import ClassItem from "./ClassItem/ClassItem";
import SButton from "components/UI/SButton";
import { useNavigate } from "react-router-dom";
import UserStore from "store/UserStore";
import { useTranslation } from "react-i18next";
import TutorClassesStore from "store/TutorClassesStore";
import { memo } from "react";
import { observer } from "mobx-react-lite";
import { useMedias } from "API/mediasHook";

const Schedule = observer(({ children, ...props }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const handleClickFullSchedule = () => {
        navigate("/schedule");
        UserStore.setPage(1);
    };

    const getClassesTitle = (number) => {
        if (number === 1) {
            return "teacherClasses.oneClass";
        } else if (number > 1 && number < 5) {
            return "teacherClasses.fewClasses";
        } else if (number > 5) {
            return "teacherClasses.manyClasses";
        }
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Box sx={{ position: "relative" }}>
            <Box style={themes.Schedule(medias, isExpanded)}>
                <Box sx={themes.Header}>
                    {medias.sm && <Typography sx={textThemes.HeadlineH5Black}>{t("teacherAccount.schedule.schedule")}</Typography>}

                    <SButton
                        variant="text-arrow"
                        onClick={handleClickFullSchedule}
                        style={themes.Button(medias)}
                    >
                        {t("teacherAccount.schedule.fullSchedule")}
                    </SButton>
                </Box>
                <Box sx={themes.DaysBlock}>
                    <Box sx={themes.DayBlock}>
                        <Box sx={themes.DayOuterContainer}>
                            <Box sx={themes.DateContainer}>
                                <Typography
                                    sx={textThemes.Button14BoldBlack}
                                >{`${t("main.today").charAt(0).toUpperCase() + t("main.today").slice(1)}, ${TutorClassesStore.todayAndTomorrowDates.today.date} ${t(TutorClassesStore.todayAndTomorrowDates.today.month)}, ${t(TutorClassesStore.todayAndTomorrowDates.today.dayOfWeek)}`}</Typography>
                            </Box>
                            {TutorClassesStore.todayClasses.length > 0 && (
                                <Typography sx={textThemes.Body2Black}>{`• ${TutorClassesStore.todayClasses.length} ${t(getClassesTitle(TutorClassesStore.todayClasses.length))}`}</Typography>
                            )}
                        </Box>{" "}
                        {TutorClassesStore.todayClasses.length > 0 ? (
                            <Box sx={themes.ClassesOuterContainer}>
                                {TutorClassesStore.todayClasses.map((item, index) => {
                                    return (
                                        <Box
                                            sx={themes.ClassItemOuterContainer}
                                            key={item.id}
                                        >
                                            <ClassItem item={item} />
                                            {index + 1 !== TutorClassesStore.todayClasses.length && <Box sx={themes.Divider} />}
                                        </Box>
                                    );
                                })}
                            </Box>
                        ) : (
                            <Typography sx={textThemes.Body2Black}>{t("teacherClasses.noClasses")}</Typography>
                        )}
                    </Box>
                    <Box sx={themes.DayBlock}>
                        <Box sx={themes.DayOuterContainer}>
                            <Box sx={themes.DateContainer}>
                                <Typography sx={textThemes.Button14BoldBlack}>
                                    {`${TutorClassesStore.todayAndTomorrowDates.tomorrow.date} ${t(TutorClassesStore.todayAndTomorrowDates.tomorrow.month)}, ${t(TutorClassesStore.todayAndTomorrowDates.tomorrow.dayOfWeek)}`}
                                </Typography>
                            </Box>
                            {TutorClassesStore.tomorrowClasses.length > 0 && (
                                <Typography sx={textThemes.Body2Black}>{`• ${TutorClassesStore.tomorrowClasses.length} ${t(getClassesTitle(TutorClassesStore.tomorrowClasses.length))}`}</Typography>
                            )}
                        </Box>
                        {TutorClassesStore.tomorrowClasses.length > 0 ? (
                            <Box sx={themes.ClassesOuterContainer}>
                                {TutorClassesStore.tomorrowClasses.map((item, index) => {
                                    return (
                                        <Box
                                            sx={themes.ClassItemOuterContainer}
                                            key={item.id}
                                        >
                                            <ClassItem item={item} />
                                            {index + 1 !== TutorClassesStore.tomorrowClasses.length && <Box sx={themes.Divider} />}
                                        </Box>
                                    );
                                })}
                            </Box>
                        ) : (
                            <Typography sx={textThemes.Body2Black}>{t("teacherClasses.noClasses")}</Typography>
                        )}
                    </Box>
                </Box>
                <>
                    {!isExpanded && !medias.sm && (
                        <Box sx={themes.BlurBox}>
                            <Button
                                sx={themes.ExpandToggleButton}
                                disableRipple
                                variant="text"
                                onClick={toggleExpand}
                            >
                                {t("teacherClasses.reveal")}
                            </Button>
                        </Box>
                    )}
                </>
            </Box>
        </Box>
    );
});

export default memo(Schedule);
