export const SentIcon = () => {
    return (
        <svg
            width="2.3vh"
            height="2.3vh"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon/20">
                <path
                    id="check"
                    d="M1 8.05556L3.54544 11.4382C3.9455 11.9698 4.74347 11.9698 5.14352 11.4382L6.66221 9.42M10.3645 4.5L6.66221 9.42M6.66221 9.42L8.1809 11.4382C8.58095 11.9698 9.37928 11.9693 9.77933 11.4377C11.6595 8.93919 12.929 7.25217 15 4.5"
                    stroke="#9E9EB0"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                />
            </g>
        </svg>
    );
};
