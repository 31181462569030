import { makeAutoObservable } from "mobx";
import Evgen from "assets/ChatAssets/PNG/EvgenBig.png";
import Maria from "assets/ChatAssets/PNG/MariaBig.png";
import Stas from "assets/ChatAssets/PNG/StasBig.png";

class OrderResponseStore {
    responseTutors = [
        {
            id: 1,
            avatar: Maria,
            name: "Мария Иванова",
            isOnline: true,
            lastOnline: "2023-11-25T10:40:00",
            review: null,
            rating: null,
            description:
                "Здравствуйте! С удовольствием занимаюсь со взрослыми людьми. Индивидуальный подход. Владею техникой по преодолению языкового барьера и психологического зажима, что позволяет начать говорить практически с первых занятий. ",
            responseTime: "2023-11-25T10:40:00",
            price: 1250,
            cashback: false,
            hiddenUser: false,
            status: "active",
        },
        {
            id: 2,
            avatar: Evgen,
            name: "Марк Пихонов",
            isOnline: false,
            lastOnline: "2023-10-23T23:10:00",
            review: 170,
            rating: 4.5,
            description:
                "Здравствуйте! Первая встреча: Тестирование, разработка плана обучения, выбор курса. Диплом преподавателя английского языка, международная квалификация CELTA pass B, CAE, TKTs. Эксперт ЕГЭ, сертификация сентябрь 2022 год. IELTS Assessment Specialist, сертификация 05.11.2022.Большой опыт работы индивидуально и с группой, проницательность, понимание личных способностей ученика и умение мотивировать и организовать.Результаты моих учеников: IELTS — 7, 7.5, 8 ЕГЭ — 87, 92, 95 баллов",
            responseTime: "2023-09-11T11:40:00",
            price: 250000,
            cashback: true,
            hiddenUser: false,
            status: "awaitingResponse",
        },
        {
            id: 3,
            avatar: Stas,
            name: "Станислав Миронов",
            isOnline: false,
            lastOnline: "2023-10-22T08:10:00",
            review: 200,
            rating: 4.7,
            description: null,
            responseTime: "2023-10-03T17:40:00",
            price: 25000,
            cashback: true,
            hiddenUser: false,
            teaches: "Английский язык",
            where: ["онлайн", "у ученика", "у репетитора"],
            address: ["Комсомольская", "Красные ворота", "Красные ворота", "Красные ворота"],
            education: "НИУ ВШЭ",
            experience: 5,
            status: "active",
            tutorRejected: true,
        },
        {
            id: 4,
            avatar: Evgen,
            name: "Сережка Ковырялкин",
            isOnline: false,
            lastOnline: "2023-10-21T08:10:00",
            review: 140,
            rating: 4.8,
            description:
                "Здравствуйте! С удовольствием занимаюсь со взрослыми людьми. Индивидуальный подход. Владею техникой по преодолению языкового барьера и психологического зажима, что позволяет начать говорить практически с первых занятий. ",
            responseTime: "2023-10-04T15:40:00",
            price: null,
            cashback: false,
            hiddenUser: true,
            status: "awaitingResponse",
        },
        {
            id: 5,
            avatar: Evgen,
            name: "Петька Покровский",
            isOnline: false,
            lastOnline: "2023-10-21T08:10:00",
            review: 140,
            rating: 4.8,
            description:
                "Здравствуйте! С удовольствием занимаюсь со взрослыми людьми. Индивидуальный подход. Владею техникой по преодолению языкового барьера и психологического зажима, что позволяет начать говорить практически с первых занятий. ",
            responseTime: "2023-10-04T15:40:00",
            price: null,
            cashback: false,
            hiddenUser: false,
            status: "awaitingResponse",
        },
        {
            id: 6,
            avatar: Evgen,
            name: "Алеша Покровский",
            isOnline: false,
            lastOnline: "2023-10-21T08:10:00",
            review: 140,
            rating: 4.8,
            description:
                "Здравствуйте! С удовольствием занимаюсь со взрослыми людьми. Индивидуальный подход. Владею техникой по преодолению языкового барьера и психологического зажима, что позволяет начать говорить практически с первых занятий. ",
            responseTime: "2023-10-04T15:40:00",
            price: null,
            cashback: false,
            hiddenUser: false,
            status: "awaitingResponse",
        },
    ];
    offersTutors = [{}];
    catalogTutors = [{}];

    toggleModal = false;
    id = null;
    tutorName = null;
    hiddenUser = null;
    isDisabled = true;
    isRejected = false;

    isDismissClicked = false;
    isComplainClicked = false;

    toggleCancelOrderModal = false;

    orderProps = {};

    clickedFrom = "";

    toggleSwitcher = false;

    setToggleSwitcher(value) {
        this.toggleSwitcher = value;
    }
    setClickedFrom(value) {
        this.clickedFrom = value;
    }
    setToggleModal() {
        this.toggleModal = !this.toggleModal;
    }
    setToggleCancelOrderModal(value) {
        this.toggleCancelOrderModal = value;
    }
    setOrderProps(value) {
        this.orderProps = value;
    }

    setIsDismissClicked(value) {
        this.isDismissClicked = value;
    }
    setIsComplainClicked(value) {
        this.isComplainClicked = value;
    }
    setIsDisabled(value) {
        this.isDisabled = value;
    }
    setIsId(id) {
        this.id = id;
    }
    setTutorName(tutorName) {
        this.tutorName = tutorName;
    }
    setHiddenUser(hiddenUser) {
        this.hiddenUser = hiddenUser;
    }
    setIsRejected(value) {
        this.isRejected = value;
    }

    setTutorStatus(status, tutorId) {
        const tutor = this.responseTutors.find((t) => t.id === tutorId);

        if (status === "rejected") {
            tutor.status = "rejected";
        }

        if (status === "complained") {
            tutor.status = "complained";
        }

        if (status === "cancelOffer") {
            tutor.status = "cancelOffer";
        }

        if (status === "awaitingResponse") {
            tutor.status = "awaitingResponse";
        }
    }

    setSortStatusResponseTutors() {
        this.responseTutors.sort((a, b) => {
            if (a.status === "rejected" && b.status !== "rejected") {
                return 1;
            }

            return -1;
        });
    }

    setSortResponseTutors(sortType) {
        this.responseTutors.sort((a, b) => {
            if (a.status === "rejected" && b.status !== "rejected") {
                return 1;
            } else if (a.status !== "rejected" && b.status === "rejected") {
                return -1;
            }

            switch (sortType) {
                case "Новые":
                    const aDate = new Date(a.responseTime);

                    const bDate = new Date(b.responseTime);

                    if (aDate < bDate) {
                        return 1;
                    } else if (aDate > bDate) {
                        return -1;
                    }

                    break;
                case "Подешевле":
                    if (a.price === null && b.price !== null) {
                        return 1;
                    } else if (a.price !== null && b.price === null) {
                        return -1;
                    } else if (a.price > b.price) {
                        return 1;
                    } else if (a.price < b.price) {
                        return -1;
                    }

                    break;
                case "Подороже":
                    if (a.price === null && b.price !== null) {
                        return 1;
                    } else if (a.price !== null && b.price === null) {
                        return -1;
                    } else if (a.price < b.price) {
                        return 1;
                    } else if (a.price > b.price) {
                        return -1;
                    }

                    break;
                case "С Кешбэком":
                    if (a.cashback && !b.cashback) {
                        return -1;
                    } else if (!a.cashback && b.cashback) {
                        return 1;
                    }

                    break;
                case "По рейтингу":
                    if (a.rating === null && b.rating !== null) {
                        return 1;
                    } else if (a.rating !== null && b.rating === null) {
                        return -1;
                    } else if (a.rating < b.rating) {
                        return 1;
                    } else if (a.rating > b.rating) {
                        return -1;
                    }

                    break;
                default:
                    break;
            }

            return 0;
        });
    }
    constructor() {
        makeAutoObservable(this);
    }
}

const orderResponseStore = new OrderResponseStore();

export default orderResponseStore;
