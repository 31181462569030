import { Drawer, Typography } from "@mui/material";
import ModalSettings from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsBig/ModalSettings";
import React from "react";
import { memo } from "react";
import { observer } from "mobx-react-lite";
import * as textStyles from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import * as styles from "./ModalsDeleteAccountThemes";
import SButton from "components/UI/SButton";
import UserStore from "store/UserStore";
import Box from "@mui/material/Box";
import PhoneTextField from "components/SettingsBodyBlock/StudentSettings/DeleteAccount/PhoneTextField/PhoneTextField";
import SettingsStore from "store/SettingsStore";
import { useMedias } from "API/mediasHook";
import DrawerHeader from "components/SettingsBodyBlock/StudentSettings/DrawerHeader/DrawerHeader";

const ModalDeleteAccount = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const [helper, setHelper] = React.useState(false);

    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        if (SettingsStore.temporaryPhoneNumber !== UserStore.UserInfo.phone) {
            SettingsStore.setTemporaryPhoneNumberHelper(true);
        } else {
            props.handleSave();
        }
    };

    const DrawerList = (
        <Box sx={styles.AddForm(medias)}>
            <DrawerHeader
                text={t("main.settings.deleteAccountBlock.deleteAccount")}
                handleClose={Close}
            />
            <Box sx={{ padding: "0 16px", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
                <Box sx={styles.PhoneNumber(medias)}>
                    <Typography sx={textStyles.Body2Black}>{t("main.settings.deleteAccountBlock.deleteWithoutRecover")}</Typography>
                    <PhoneTextField
                        phoneNumber1={SettingsStore.temporaryPhoneNumber}
                        setPhoneNumber1={SettingsStore.setTemporaryPhoneNumber}
                    />
                    {SettingsStore.temporaryPhoneNumberHelper && <Typography sx={textStyles.Caption1Red}>{t("main.settings.deleteAccountBlock.incorrectPhone")}</Typography>}
                </Box>
            </Box>
            <Box
                container
                sx={styles.ButtonsDrawerContainer(medias)}
            >
                <SButton
                    style={{ width: "192px" }}
                    padding="10px 20px 11px 20px"
                    variant="secondary"
                    onClick={Close}
                >
                    {t("main.cancel")}
                </SButton>
                <SButton
                    style={{ width: "192px" }}
                    padding="10px 20px 11px 20px"
                    variant="red"
                    onClick={Save}
                >
                    {t("main.delete")}
                </SButton>
            </Box>
        </Box>
    );

    return (
        <>
            {!medias.sm ? (
                <Drawer
                    open={props.open}
                    onClose={Close}
                >
                    {DrawerList}
                </Drawer>
            ) : (
                <ModalSettings
                    visible={props.open}
                    handleClose={Close}
                    header={t("main.settings.deleteAccountBlock.deleteAccount")}
                >
                    <Box sx={styles.DeleteAccount(medias)}>
                        <Typography sx={textStyles.Body2Black}>{t("main.settings.deleteAccountBlock.deleteWithoutRecover")}</Typography>
                        <Box sx={styles.PhoneNumber(medias)}>
                            <PhoneTextField
                                phoneNumber1={SettingsStore.temporaryPhoneNumber}
                                setPhoneNumber1={SettingsStore.setTemporaryPhoneNumber}
                            />
                            {SettingsStore.temporaryPhoneNumberHelper && <Typography sx={textStyles.Caption1Red}>{t("main.settings.deleteAccountBlock.incorrectPhone")}</Typography>}
                        </Box>
                        <Box
                            container
                            sx={styles.ButtonsContainer}
                        >
                            <SButton
                                style={{ width: "192px" }}
                                padding="10px 20px 11px 20px"
                                variant="secondary"
                                onClick={Close}
                            >
                                {t("main.cancel")}
                            </SButton>
                            <SButton
                                style={{ width: "192px" }}
                                padding="10px 20px 11px 20px"
                                variant="red"
                                onClick={Save}
                            >
                                {t("main.delete")}
                            </SButton>
                        </Box>
                    </Box>
                </ModalSettings>
            )}
        </>
    );
});

export default memo(ModalDeleteAccount);
