import { handleStoreActionError } from "API/plugins/errorHandlers";
import RequestService from "./RequestService";

const requestService = new RequestService();

const fetchSuggestions = async (query) => {
    try {
        // Make the POST request using Axios
        const data = {
            query: query,
        };

        let url, method;

        url = process.env.REACT_APP_ADDRESS_SUGGESTION_URL;
        method = requestService.METHOD_POST;
        requestService.setHeader("Authorization", `Token ${process.env.REACT_APP_DADATA_AUTH_TOKEN}`);
        const res = await requestService.sendAsync(url, method, data, false);

        // Handle the response data
        return res.data.suggestions.map((suggestion, index) => ({ ID: index, LessonsPlace: suggestion.value }));
    } catch (error) {
        // Handle any errors
        console.error("Error:", error);
        handleStoreActionError({
            error,
            customErrorText: "questionnaire.errors.fetchSuggestionsError",
            dispatch: true,
        });
    }
};

export default fetchSuggestions;
