import { Box, IconButton } from "@mui/material";
import UserStore from "store/UserStore";
import Profile from "assets/ChatAssets/PNG/profile_navbar.png";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import StudentPopper from "./StudentTeacherPoppers/StudentPopper";
import TeacherPopper from "./StudentTeacherPoppers/TeacherPopper";
import * as React from "react";
import { memo } from "react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import * as themes from "./ProfileButtonThemes";

const ProfileButton = observer(({ children, ...props }) => {
    const navigate = useNavigate();

    const anchorRef = useRef(null);

    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    return (
        <Box>
            <IconButton
                sx={themes.IconButton}
                disableRipple
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                size="small"
            >
                <img
                    style={themes.ProfilePhoto}
                    src={UserStore.role === "student" ? Profile : TeacherQuestionnaireStore.teacher.miniature.imageSrc}
                    alt=""
                />
            </IconButton>
            {UserStore.role === "student" ? (
                <StudentPopper
                    open={open}
                    setOpen={setOpen}
                    anchorRef={anchorRef}
                />
            ) : (
                <TeacherPopper
                    open={open}
                    setOpen={setOpen}
                    anchorRef={anchorRef}
                />
            )}
        </Box>
    );
});

export default memo(ProfileButton);
