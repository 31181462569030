const iconClose = function (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M17 7L7 17"
                stroke="#9E9EB0"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M7 7L17 17"
                stroke="#9E9EB0"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default iconClose;
