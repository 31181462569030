export const teacherCardContainerStyle = (medias) => {
    return {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        padding: 0,
        gap: 9,
        width: medias.sm ? 1216 : "fit-content",
        height: medias.sm ? 415 : 601,
        flex: "none",
        order: 0,
        flexGrow: 0,
    };
};
