export const ReschedulePlatformClassIcon = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="20"
                cy="20"
                r="20"
                fill="#BBBBC8"
                fillOpacity="0.17"
            />
            <path
                d="M25.8001 28.9505C25.8001 29.308 25.5076 29.6005 25.1501 29.6005H15.35C14.9925 29.6005 14.7 29.308 14.7 28.9505C14.7 28.593 14.9925 28.3005 15.35 28.3005H18.3V27.5005C18.3 27.2243 18.5239 27.0005 18.8 27.0005H20.25H21.7C21.9762 27.0005 22.2 27.2243 22.2 27.5005V28.3005H25.1501C25.5076 28.3005 25.8001 28.593 25.8001 28.9505ZM20.25 19.2004C20.1811 19.2004 20.1109 19.1894 20.0446 19.1673L17.65 18.3691V20.9528C17.8509 21.1901 18.5314 21.8004 20.25 21.8004C21.9563 21.8004 22.6401 21.1985 22.85 20.9489V18.3684L20.4554 19.1666C20.3891 19.1894 20.3189 19.2004 20.25 19.2004ZM17.2515 16.335C16.9965 16.42 16.9966 16.7807 17.2516 16.8656V16.8656L17.2542 16.8663L19.6173 17.6543C20.028 17.7913 20.4721 17.7913 20.8827 17.6543L23.2459 16.8663L23.2485 16.8656V16.8656C23.5035 16.7807 23.5035 16.42 23.2486 16.335L20.8825 15.5463C20.472 15.4095 20.0281 15.4095 19.6176 15.5463L17.2515 16.335ZM30.0001 14.3504V22.7504C30.0001 23.8255 29.0001 25.7004 27.0501 25.7004H13.45C11.0001 25.7004 10.5 23.8255 10.5 22.7504V14.3504C10.5 13.2753 11.0001 11.4004 13.45 11.4004H27.0501C29.0001 11.4004 30.0001 13.2753 30.0001 14.3504ZM26.7501 16.6004C26.7501 16.3203 26.5713 16.072 26.3055 15.9836L20.4554 14.0336C20.3222 13.9894 20.1772 13.9894 20.044 14.0336L14.194 15.9836C13.9288 16.0726 13.75 16.3203 13.75 16.6004C13.75 16.8806 13.9288 17.1289 14.1946 17.2173L16.35 17.9355V21.1504C16.35 21.2512 16.3734 21.3513 16.4183 21.441C16.5034 21.6106 17.3328 23.1004 20.25 23.1004C23.1672 23.1004 23.9966 21.6106 24.0818 21.441C24.1266 21.3513 24.1501 21.2512 24.1501 21.1504V17.9355L25.4501 17.502V19.5004C25.4501 19.8592 25.7406 20.1504 26.1001 20.1504C26.4595 20.1504 26.7501 19.8592 26.7501 19.5004V16.6004Z"
                fill="#FF9212"
            />
        </svg>
    );
};
