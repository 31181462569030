import * as React from "react";
import * as themes from "./ImageContentThemesDefault";
import { observer } from "mobx-react-lite";
import { memo } from "react";

const ImageContent = observer(({ children, ...props }) => {
    return (
        <img
            style={themes.imageContentStyle}
            src={props.value.src}
            alt={props.value.type}
        />
    );
});

export default memo(ImageContent);
