export const tutorPointsBlock = {
    width: "1216px",
    height: "559px",
    marginTop: "140px",
    marginLeft: "112px",
    marginRight: "112px",
    position: "relative",
};

export const imgBlock = {
    gap: "20px",
    display: "flex",
    marginTop: "40px",
};

export const img = {
    width: "392px",
    height: "311px",
};

export const textBlock = {
    display: "flex",
    gap: "57px",
    marginLeft: "19px",
    position: "relative",
    bottom: "91px",
};

export const textBlockContent = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
    width: "355px",
    padding: "28px",
    borderRadius: "12px",
    background: "#FFF",
    boxShadow: "0px 4px 10px 0px rgba(166, 166, 182, 0.20)",
    boxSizing: "border-box",
};
