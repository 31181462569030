import React, { memo } from "react";
import { Box, Typography } from "@mui/material";
import * as themes from "./TutorRulesBodyBlockThemes";
import * as textThemes from "themes/TextThemes";
import RuleComp from "./RuleComp/RuleComp";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";
import { HeadlineH3Black, HeadlineH41Black } from "themes/TextThemes";

const TutorRulesBodyBlock = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const medias = useMedias();

    return (
        <Box sx={themes.TutorRulesBodyBlock}>
            <Box sx={themes.TutorRulesBodyBlockContent(medias)}>
                <Typography sx={medias.sm ? textThemes.HeadlineH60Black : textThemes.HeadlineH3Black}>{t("rules.rules")}</Typography>
                <Box sx={themes.RulesLists}>
                    <Box sx={themes.RulesOuterContainer}>
                        <Typography sx={medias.sm ? textThemes.HeadlineH3Black : textThemes.HeadlineH41Black}>{t("rules.tutor.rulesForTutor")}</Typography>
                        <Box sx={themes.rulesBlock}>
                            <RuleComp text={t("rules.tutor.gettingPayments")} />
                            <RuleComp text={t("rules.tutor.discussionCommissions")} />
                            <RuleComp text={t("rules.tutor.sendContacts")} />
                            <RuleComp text={t("rules.tutor.postFullNames")} />
                            <RuleComp text={t("rules.tutor.abuse")} />
                            <RuleComp text={t("rules.tutor.badAvatar")} />
                            <RuleComp text={t("rules.tutor.badWords")} />
                            <RuleComp text={t("rules.tutor.fraud")} />
                            <RuleComp text={t("rules.tutor.spam")} />
                            <RuleComp text={t("rules.tutor.manyAccounts")} />
                            <RuleComp text={t("rules.tutor.sendAccount")} />
                            <RuleComp text={t("rules.tutor.badReviews")} />
                        </Box>
                    </Box>
                    <Box sx={themes.RulesOuterContainer}>
                        <Typography sx={medias.sm ? textThemes.HeadlineH3Black : textThemes.HeadlineH41Black}>{t("rules.sanctions")}</Typography>
                        <Box sx={themes.rulesBlock}>
                            <RuleComp text={t("rules.tutor.ban")} />
                            <RuleComp text={t("rules.tutor.getCheating")} />
                            <RuleComp text={t("rules.tutor.warning")} />
                            <RuleComp text={t("rules.tutor.softening")} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default memo(TutorRulesBodyBlock);
