import React from "react";
import Highlighter from "react-highlight-words";
import { styled } from "@mui/system";

const StyledHighlight = styled("span")((props) => ({
    backgroundColor: "red",
}));

const HighlightedText = ({ text, substrings }) => {
    return (
        <Highlighter
            highlightStyle={{
                backgroundColor: "white",
                color: "rgba(98, 18, 255, 1)",
            }}
            searchWords={substrings}
            autoEscape={true}
            textToHighlight={text}
        />
    );
};

export default HighlightedText;
