import TeacherAvailability from "components/TeacherQuestionnaireBodyBlock/Steps/ClassesScheduleStep/TeacherAvailability/TeacherAvailability";
import { memo } from "react";
import ModalSettings from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsBig/ModalSettings";
import { useTranslation } from "react-i18next";
import SButton from "components/UI/SButton";
import * as themes from "./ChangeSheduleModalStyled";

const ChangeSheduleModal = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        props.handleSave();
    };

    return (
        <ModalSettings
            padding="25px 6px 20px 40px"
            width="678px"
            visible={props.open}
            handleClose={Close}
            header={t("main.workSchedule")}
        >
            <TeacherAvailability
                changeSchedule="changeSchedule"
                width="592px"
            />
            <SButton
                style={themes.Button}
                onClick={Save}
                variant="unshadowed"
                padding="11px 24px 12px 24px"
            >
                {t("main.save")}
            </SButton>
        </ModalSettings>
    );
};

export default memo(ChangeSheduleModal);
