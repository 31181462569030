export const Chip = (chipTextColor, chipBorder) => {
    return {
        fontSize: "14px",
        lineHeight: "150%",
        fontWeight: 400,
        textTransform: "none",
        color: chipTextColor,
        pr: "2px",
        gap: "0px",
        border: chipBorder,
    };
};

export const InnerContainer = {
    display: "flex",
    maxWidth: "500px",
    flexWrap: "Wrap",
    gap: "8px",
};

export const Autocomplete = {
    width: "100%",
    mt: "20px",
    "& + .MuiAutocomplete-paper .MuiAutocomplete-option:hover": {
        opacity: "100%",
        color: "#222",
    },
};

export const TextField = (error) => {
    return {
        "& .MuiInput-underline:before": {
            borderBottom: error ? "1px solid #d32f2f" : "1px solid #BBBBC8",
        },
        "& .MuiInput-underline:after": { borderBottomColor: error ? "#d32f2f" : "#6212FF" },
        "& .MuiInputBase-input": {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "16px",
            lineHeight: "150%",
            fontWeight: 400,
            textTransform: "none",
        },
    };
};

export const TextFieldComponent = {
    paper: {
        sx: {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: "16px",
            lineHeight: "150%",
            fontWeight: 400,
            textTransform: "none",
            color: "#222",
            bgcolor: "#fff",
            borderRadius: "10px",
            filter: "drop-shadow(0px 4px 10px rgba(106, 99, 118, 0.15))",
            border: "0px",
            borderStyle: "solid",
        },
    },
};
