import { Typography, styled } from "@mui/material";

export const SpecialistCountStyled = styled(Typography)({
    color: "rgba(103, 103, 122, 1)",
    fontFamily: "inherit",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
});

export const ZeroOffersCardTopWrapper = {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 60px 50px 60px",
};

export const ZeroOffersCardRightSideWrapper = {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
};

export const ZeroOffersCardAvatarGroup = {
    marginBottom: "12px",
    "& .MuiAvatar-root": {
        width: 50,
        height: 50,
        color: "#000",
        fontSize: 14,
    },
    "&.MuiAvatarGroup-root .MuiAvatar-root": {
        border: "1px solid rgba(187, 187, 200, 1)",
        background: "none",
        color: "rgba(187, 187, 200, 1)",
        overflow: "hidden",
    },
    justifyContent: "flex-end",
};

export const ZeroOffersCardBottomWrapper = {
    maxWidth: "195px",
    width: "100%",
    paddingBottom: "40px",
    paddingLeft: "60px",
};
