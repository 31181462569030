import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import React from "react";
import * as styles from "./ModalPayQRThemes";
import * as textStyles from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import { Typography, Box } from "@mui/material";
import SBPBig from "assets/PaymentAssets/SVG/SBPBig";
import PaymentStore from "store/PaymentStore";
import { QRCodeSVG } from "qrcode.react";
import { useMedias } from "API/mediasHook";

const ModalPayQR = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <ModalSettingsSmall
            visible={props.open}
            handleClose={props.handleDiscard}
            pl={!medias.sm ? "16px" : undefined}
            pr={!medias.sm ? "16px" : undefined}
            pt={!medias.sm ? "16px" : undefined}
            pb={!medias.sm ? "16px" : undefined}
        >
            <Box sx={styles.PayQR(medias)}>
                <Box sx={styles.PayQRTitle}>
                    <Typography sx={medias.sm ? textStyles.HeadlineH41Black : textStyles.HeadlineH5Black}>QR-код для оплаты</Typography>
                    <Box>
                        <SBPBig />
                    </Box>
                </Box>
                <Box sx={styles.PayQRText}>
                    <Typography sx={textStyles.HeadlineH5Black}>{`К оплате: ${PaymentStore.calculateTotal().toLocaleString("ru")} ₽`}</Typography>
                    <Typography sx={textStyles.Body2Black}>Чтобы оплатить заказ, отсканируйте QR-код из приложения банка или через камеру телефона</Typography>
                </Box>
                <Box sx={styles.QRContainer}>
                    <QRCodeSVG
                        value={PaymentStore.qrUrl}
                        size={180}
                        level={"H"}
                        bgColor={"#ffffff"}
                        fgColor={"#1A123E"}
                    />
                </Box>
            </Box>
        </ModalSettingsSmall>
    );
});

export default memo(ModalPayQR);
