import * as React from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import TextField from "@mui/material/TextField";
import { MuiTelInput } from "mui-tel-input";

import { Box } from "@mui/material";
import * as theme from "./AlmostDoneThemes";
import { LoginButton } from "components/Header/HeaderAccount/HeaderAccountThemes";

export default function AlmostDonePage({ authorized }) {
    //States

    const [phoneNumber, setPhoneNumber] = React.useState("+7");

    const handleChange = (newValue) => {
        setPhoneNumber(newValue);
    };

    return (
        <Box>
            <Box>
                <AvatarGroup sx={theme.AvatarGroup}>
                    <Avatar
                        alt="Anna"
                        src="assets/Anna.jpg"
                        sx={theme.Avatar}
                    />
                    <Avatar
                        alt="Olga"
                        src="assets/Olga.jpg"
                        sx={theme.Avatar}
                    />
                    <Avatar
                        alt="Andrei"
                        src="assets/Andrei.jpg"
                        sx={theme.Avatar}
                    />
                    <Box sx={theme.TutorSearchResult}>125 130 подходящих специалистов</Box>
                </AvatarGroup>
            </Box>
            <Box sx={theme.AlmostReadyText}>Заказ почти готов</Box>

            {authorized === false ? (
                <Box>
                    <Box sx={theme.AuthText}>Ваши контакты никто не будет видеть, они нужны только для регистрации</Box>
                    <Box sx={theme.AuthTextMt}>
                        <Box sx={theme.AuthText}>Имя</Box>
                        <TextField
                            id="standard-basic"
                            placeholder="Введите имя"
                            variant="standard"
                            sx={theme.textFieldSX}
                        />
                    </Box>
                    <Box sx={theme.AuthTextMt}>
                        <Box sx={theme.AuthText}>Номер телефона</Box>
                        <MuiTelInput
                            forceCallingCode
                            defaultCountry="RU"
                            variant="standard"
                            value={phoneNumber}
                            onChange={handleChange}
                            placeholder="000 000-00-00"
                            sx={theme.textFieldSX}
                        />
                    </Box>
                    <Box sx={theme.AuthTextMt}>
                        <Box sx={theme.AuthText}>Эл.почта</Box>
                        <TextField
                            id="standard-basic"
                            placeholder="Введите эл.почту"
                            variant="standard"
                            sx={theme.textFieldSX}
                        />
                    </Box>
                    <Box sx={theme.AlreadyGotAccWrapper}>
                        <Box sx={theme.AlreadyGotAcc}>Уже есть аккаунт?</Box>
                        <LoginButton variant="bid"></LoginButton>
                    </Box>
                </Box>
            ) : (
                <Box sx={theme.StudentAvatarInfoWrapper}>
                    <Avatar
                        alt="Alexa"
                        variant="rounded"
                        src="assets/Alexa.png"
                        sx={theme.Avatar}
                    />
                    <Box sx={theme.StudentAvatarInfoPos}>
                        <Box sx={theme.NameText}>Анна</Box>
                        <Box sx={theme.PhoneText}>+7 916 222-35-11</Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
}
