import { makeAutoObservable } from "mobx";
import Photo from "assets/PAAssets/PNG/photo.png";

class UserStore {
    role = "student";

    nAccountTypes = 2;

    wasInWork = false;

    PersonalAccountState = "Work";

    isAuth = false;
    openAuth = false;

    UID = "123112312e";

    page = false;

    referralLink = "https://study-buddy.ru/";

    tokens = {
        tutorAccessToken: null,
        studentAccessToken: null,
        refreshToken: null,
    };

    UserInfo = {
        birthday: new Date(2023, 6, 5),
        name: "Елизавета",
        surname: "Тихонова",
        sex: "Не указан",
        time: "",
        timezone: "(GMT+03:00) Moscow",
        photo: Photo,
        university: "Национальный исследовательский университет «Высшая школа экономики» (НИУ ВШЭ)",
        telegram: false,
        mail: {
            current: null,
            verified: false,
        },
        password: null,
        passwordUpdateDate: null,
        phone: "79250432943",
        city: { id: 605, title: "Москва", region: "Москва", country: "Россия" },
    };

    OrdersWork = [{}];

    CurrOrders = [{}];

    TodayWorks = [{}];

    PastWork = [{}];

    classes = [];

    paymentPageActivated = false;

    StoryInfo = [0, 0, 0, 0, 0, 0, 0, 0];

    bonuses = 110;

    cancelClassAttempts = 3;

    openNotifications = false;

    openCitySelect = false;

    stories = {
        GetBonusesStory: {
            like: false,
            dislike: false,
        },
        WhatToDoStory: {
            like: false,
            dislike: false,
        },
        BonusesCreditTimeStory: {
            like: false,
            dislike: false,
        },
        GetBonusesFormStory: {
            like: false,
            dislike: false,
            answer: null,
        },
        ChatBotStory: {
            like: false,
            dislike: false,
        },
        CashbackStory: {
            like: false,
            dislike: false,
        },
        HowItWorksStory: {
            like: false,
            dislike: false,
        },
        WhatAboutBonusesStory: {
            like: false,
            dislike: false,
        },
        AlreadyUsedCashbackStory: {
            like: false,
            dislike: false,
            answer: null,
        },
        DiplomaStory: {
            like: false,
            dislike: false,
        },
        HowToOrderStory: {
            like: false,
            dislike: false,
        },
        UnderControlStory: {
            like: false,
            dislike: false,
        },
        OrderWorkStory: {
            like: false,
            dislike: false,
            answer: null,
        },
        FindTutorStory: {
            like: false,
            dislike: false,
        },
        YourChoiceStory: {
            like: false,
            dislike: false,
        },
        HowSearchWorksStory: {
            like: false,
            dislike: false,
        },
        WantFindByYourselfStory: {
            like: false,
            dislike: false,
        },
        WhatNextStory: {
            like: false,
            dislike: false,
        },
        HowToHelpStory: {
            like: false,
            dislike: false,
            answer: null,
        },
        IncreaseMotivationStory: {
            like: false,
            dislike: false,
        },
        HelpIncreaseStory: {
            like: false,
            dislike: false,
            answer: null,
        },
        TutorEncourageStory: {
            like: false,
            dislike: false,
            answer: null,
        },
        HelpSaveStory: {
            like: false,
            dislike: false,
            answer: null,
        },
        TutorSmilingStory: {
            like: false,
            dislike: false,
            answer: null,
        },
        ReadInBlogStory: {
            like: false,
            dislike: false,
        },
    };

    setCity(value) {
        this.UserInfo.city = value;
    }
    setOpenCitySelect(value) {
        this.openCitySelect = value;
    }
    setOpenNotifications(value) {
        this.openNotifications = value;
    }

    setStoryAnswer(story, value) {
        this.stories[story].answer = value;
    }

    setStoryLike(story, value) {
        if (value === true && this.stories[story].dislike === true) {
            this.stories[story].dislike = false;
        }

        this.stories[story].like = value;
    }

    setStoryDislike(story, value) {
        if (value === true && this.stories[story].like === true) {
            this.stories[story].like = false;
        }

        this.stories[story].dislike = value;
    }

    setPaymentPageActivated(value) {
        this.paymentPageActivated = value;
    }
    setCancelClassAttempts(value) {
        this.cancelClassAttempts = value;
    }
    setFirstName(value) {
        this.UserInfo.name = value;
    }
    setSecondName(value) {
        this.UserInfo.surname = value;
    }
    setSex(value) {
        this.UserInfo.sex = value;
    }

    setTimezone(value) {
        this.UserInfo.timezone = value;
    }
    setUniversity(value) {
        this.UserInfo.university = value;
    }

    setBirthDay(value) {
        const birthMonth = this.UserInfo.birthday.getMonth();

        const birthYear = this.UserInfo.birthday.getFullYear();

        this.UserInfo.birthday = new Date(birthYear, birthMonth, value);
    }

    setBirthMonth(value) {
        const birthDay = this.UserInfo.birthday.getDate();

        const birthYear = this.UserInfo.birthday.getFullYear();

        this.UserInfo.birthday = new Date(birthYear, value, birthDay);
    }

    setBirthYear(value) {
        const birthMonth = this.UserInfo.birthday.getMonth();

        const birthDay = this.UserInfo.birthday.getDate();

        this.UserInfo.birthday = new Date(value, birthMonth, birthDay);
    }

    cardNumber = null;
    cardExpirationDate = null;
    cardCVC = null;

    setCardNumber(value) {
        this.cardNumber = value;
    }

    setCardExpirationDate(value) {
        this.cardExpirationDate = value;
    }

    setCardCVC(value) {
        this.cardCVC = value;
    }

    setEmail(value) {
        this.UserInfo.mail = {
            current: value,
            verified: false,
        };
    }
    setVerifiedEmail(value) {
        this.UserInfo.mail.verified = value;
    }

    setPhoto(value) {
        this.UserInfo.photo = value;
    }

    setPage(value) {
        this.page = value;
    }

    setAuthOpen(value) {
        this.openAuth = value;
    }

    setRole(role) {
        this.role = role;
    }

    setStoryInfo(index, val) {
        this.StoryInfo[index] = val;
    }

    setPersonalAccountState(state) {
        this.PersonalAccountState = state;
    }

    setTelegram(val) {
        this.UserInfo.telegram = val;
    }
    setPassword(val) {
        this.UserInfo.password = val;
    }

    setPasswordUpdateDate(val) {
        this.UserInfo.passwordUpdateDate = val;
    }

    setWas(bool) {
        this.wasInWork = bool;
    }

    setAuth(bool) {
        this.isAuth = bool;
    }

    setUserInfo(UserInfo) {
        this.UserInfo = UserInfo;
    }

    setOrdersWork(OrdersWork) {
        this.OrdersWork = OrdersWork;
    }
    setCurrOrders(CurrOrders) {
        this.CurrOrders = CurrOrders;
    }

    setTodayWork(TodayWorks) {
        this.TodayWorks = TodayWorks;
    }

    setPastWork(PastWork) {
        this.PastWork = PastWork;
    }

    setRating(item, rating) {
        this.PastWork[item].rating = rating;
    }

    SetTokens(value) {
        this.tokens = value;
    }

    constructor() {
        this.Init();
        makeAutoObservable(this);
    }

    Init() {
        this.classes = [
            {
                subject: "Теория вероятности",
                timeStart: new Date(2024, 7, 23, 11, 50),
                timeEnd: new Date(2024, 7, 23, 22, 0),
                tutor: "Александра А.",
                place: "online",
                rating: null,
            },
            {
                subject: "Математическая статистика",
                timeStart: new Date(2024, 5, 8, 21, 0),
                timeEnd: new Date(2024, 5, 8, 22, 0),
                tutor: "Александра А.",
                place: "tutor",
                rating: null,
            },
            {
                subject: "Эконометрика",
                timeStart: new Date(2024, 6, 11, 21, 0),
                timeEnd: new Date(2024, 6, 11, 22, 0),
                tutor: "Владимир Б.",
                place: "student",
                rating: null,
            },
            {
                subject: "Английский язык",
                timeStart: new Date(2023, 10, 31, 21, 0),
                timeEnd: new Date(2023, 10, 31, 22, 0),
                tutor: "Александра А.",
                place: "online",
                rating: null,
            },
            {
                subject: "Английский язык",
                timeStart: new Date(2023, 10, 31, 21, 0),
                timeEnd: new Date(2024, 10, 31, 22, 0),
                tutor: "Александра А.",
                place: "online",
                rating: null,
            },
            {
                subject: "Английский язык",
                timeStart: new Date(2023, 10, 31, 21, 0),
                timeEnd: new Date(2024, 10, 31, 22, 0),
                tutor: "Анна В.",
                place: "online",
                rating: null,
            },
            {
                subject: "Методы машинного обучения",
                timeStart: new Date(2023, 7, 31, 21, 0),
                timeEnd: new Date(2023, 7, 31, 22, 0),
                tutor: "Дмитрий И.",
                place: "tutor",
                rating: null,
            },
        ];

        this.setOrdersWork([
            {
                thema: "Курсовая работа",
                date: "17 февраля",
                deadline: "18 марта",
                subject: "Информационная безопасность",
                teacher: "Екатерина М.",
                state: "Ждет проверку",
            },
            {
                thema: "Курсовая работа",
                date: "17 февраля",
                deadline: "18 марта",
                subject: "Информационная безопасность",
                teacher: "Екатерина М.",
                state: "Ждет проверку",
            },
        ]);

        this.setCurrOrders([
            {
                thema: "Английский язык",
                date: "сегодня",
                subject: "Подготовка к экзамену в ВУЗе",
                teacher: "Станислав М.",
            },
            {
                thema: "Теория вероятностей",
                date: "сегодня",
                subject: "Подготовка к экзамену в ВУЗе",
                teacher: "Станислав М.",
            },
            {
                thema: "Теория вероятностей",
                date: "сегодня",
                subject: "Подготовка к экзамену в ВУЗе",
                teacher: "Станислав М.",
            },
            {
                thema: "Теория вероятностей",
                date: "сегодня",
                subject: "Подготовка к экзамену в ВУЗе",
                teacher: "Станислав М.",
            },
            {
                thema: "Теория вероятностей",
                date: "сегодня",
                subject: "Подготовка к экзамену в ВУЗе",
                teacher: "Станислав М.",
            },
        ]);

        this.setTodayWork([
            {
                thema: "Теория вероятности",
                timestart: new Date(2024, 10, 31, 21, 0),
                timeend: new Date(2024, 10, 31, 22, 0),
                teacher: "Александра А.",
                home: false,
                rating: 0,
            },
            {
                thema: "СиСПИ",
                timestart: new Date(2024, 10, 31, 21, 20),
                timeend: new Date(2024, 10, 31, 22, 0),
                teacher: "Александра А.",
                home: true,
                rating: 0,
            },
            {
                thema: "Алгебра",
                timestart: new Date(2024, 10, 28, 19, 0),
                timeend: new Date(2024, 10, 28, 20, 0),
                teacher: "Александра А.",
                home: false,
                rating: 0,
            },
            {
                thema: "Тетя Катя",
                timestart: new Date(2024, 10, 28, 21, 40),
                timeend: new Date(2024, 10, 28, 22, 0),
                teacher: "Александра А.",
                home: false,
                rating: 0,
            },
        ]);

        this.setWas(false);
    }
}

export default new UserStore();
