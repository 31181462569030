export const Tutor = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "1px solid #EEEEF0",
    padding: "10px 0 0 0",
};

export const TutorProfile = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
};

export const Avatar = {
    width: "36px",
    height: "36px",
};
