const ArrowBackIcon = function (props) {
    return (
        <svg
            width="17"
            height="15"
            viewBox="0 0 17 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16 7.5L0.999999 7.5M0.999999 7.5L8 14M0.999999 7.5L8 1"
                stroke="#222222"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ArrowBackIcon;
