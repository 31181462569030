import React from "react";
import { observer } from "mobx-react-lite";

import { Stack, Typography } from "@mui/material";
import { VerifiedUser } from "@mui/icons-material";

import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";

import { Caption1LightBlack } from "themes/TextThemes";

const PaymentInfo = () => {
    const backColor = DiplomaStore.status === "ended" ? "rgba(25, 178, 12, 0.05)" : "rgba(187, 187, 200, 0.15)";

    const verifiedIconColor = DiplomaStore.status === "ended" ? "#19B20C" : "#BBBBC8";

    return (
        <Stack
            direction="column"
            spacing={0.75}
            borderRadius="10px"
            padding={2}
            sx={{ background: backColor }}
        >
            <Stack
                direction="row"
                spacing={1}
            >
                <VerifiedUser
                    sx={{ width: "16px", height: "18px" }}
                    htmlColor={verifiedIconColor}
                />
                <Typography sx={Caption1LightBlack}>Мы зарезервировали 8 500 ₽</Typography>
            </Stack>
            <Stack
                direction="row"
                spacing={1}
            >
                <VerifiedUser
                    sx={{ width: "16px", height: "18px" }}
                    htmlColor={verifiedIconColor}
                />
                <Typography sx={Caption1LightBlack}>Оплата поступит на карту после завершения заказа</Typography>
            </Stack>
        </Stack>
    );
};

export default observer(PaymentInfo);
