import React, { memo, useState } from "react";
import * as themes from "./LocationsThemes";
import { observer } from "mobx-react-lite";
import { Chip, IconButton, Menu, Typography } from "@mui/material";
import { useMedias } from "../../../../../../API/mediasHook";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";

const Locations = observer(({ children, ...props }) => {
    const medias = useMedias();

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleMenuToggle = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={themes.locationBlockContainerStyle(props.styleMode ? props.styleMode : 0)}>
            <Typography sx={themes.locationLabelStyle(props.styleMode ? props.styleMode : 0)}>Где:</Typography>

            {!medias.sm ? (
                <>
                    {props.value.Location.slice(0, 1).map((v) => (
                        <Box
                            sx={themes.locationTextContainerStyle(props.styleMode ? props.styleMode : 0)}
                            key={v.ID}
                        >
                            <Typography sx={themes.locationTextStyle(props.styleMode ? props.styleMode : 0)}>{v.location}</Typography>
                            {props.value.Location.length > 1 && <Box>•</Box>}
                        </Box>
                    ))}

                    {props.value.Location.length > 1 && (
                        <Box sx={{ order: 2 }}>
                            <ThemeProvider theme={themes.MoreChipTheme}>
                                <Chip
                                    label={`+ ${props.value.Location.length - 1}`}
                                    onClick={handleMenuToggle}
                                />
                            </ThemeProvider>
                            <ThemeProvider theme={themes.MenuTheme}>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleMenuClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                >
                                    {props.value.Location.slice(1).map((value) => (
                                        <MenuItem
                                            key={value.ID}
                                            onClick={handleMenuClose}
                                            disableRipple
                                        >
                                            <Typography sx={themes.actionTextStyle}>{value.location}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </ThemeProvider>
                        </Box>
                    )}
                </>
            ) : (
                <>
                    {props.value.Location.slice(0, 3).map((v, index) => (
                        <Box
                            sx={themes.locationTextContainerStyle(props.styleMode ? props.styleMode : 0)}
                            key={v.ID}
                        >
                            <Typography sx={themes.locationTextStyle(props.styleMode ? props.styleMode : 0)}>{v.location}</Typography>
                            {index < 2 && index < props.value.Location.length - 1 && <Box>•</Box>}
                        </Box>
                    ))}

                    {props.value.Location.length > 3 && (
                        <Box sx={{ order: 2 }}>
                            <ThemeProvider theme={themes.MoreChipTheme}>
                                <Chip
                                    label={`+ ${props.value.Location.length - 3}`}
                                    disableRipple
                                    onClick={handleMenuToggle}
                                />
                            </ThemeProvider>
                            <ThemeProvider theme={themes.MenuTheme}>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleMenuClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                >
                                    {props.value.Location.slice(3).map((value) => (
                                        <MenuItem
                                            key={value.ID}
                                            onClick={handleMenuClose}
                                            disableRipple
                                        >
                                            <Typography sx={themes.actionTextStyle}>{value.location}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </ThemeProvider>
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
});

export default memo(Locations);
