import { makeAutoObservable } from "mobx";
import RequestService from "API/services/RequestService";
import Photo from "../assets/PAAssets/PNG/photo.png";
const requestService = new RequestService();

class SettingsStore {
    temporaryEmail = null;

    setTemporaryEmail(value) {
        this.temporaryEmail = value;
    }

    temporaryEmailNotVerified = null;

    setTemporaryEmailNotVerified(value) {
        this.temporaryEmailNotVerified = value;
    }

    seconds = 0;

    setSeconds(value) {
        this.seconds = value;
    }

    minutes = 0;

    setMinutes(value) {
        this.minutes = value;
    }

    SMSCode = null;
    SMSCodesSent = "YURA";

    setSMSCode(value) {
        this.SMSCode = value;
    }

    checkSMSCode() {
        return this.SMSCode === this.SMSCodesSent;
    }

    wrongSMSCode = false;

    setWrongSMSCode(value) {
        this.wrongSMSCode = value;
    }

    newPassword = null;

    setNewPassword(value) {
        this.newPassword = value;
    }

    newPasswordRepeat = null;

    setNewPasswordRepeat(value) {
        this.newPasswordRepeat = value;
    }

    newPasswordHelper = null;
    setNewPasswordHelper(value) {
        this.newPasswordHelper = value;
    }

    newPasswordRepeatHelper = null;
    setNewPasswordRepeatHelper(value) {
        this.newPasswordRepeatHelper = value;
    }

    colorNewPasswordHelper = null;

    setColorNewPasswordHelper(value) {
        this.colorNewPasswordHelper = value;
    }

    colorNewPasswordRepeatHelper = null;

    setColorNewPasswordRepeatHelper(value) {
        this.colorNewPasswordRepeatHelper = value;
    }

    temporaryPassword = null;

    setTemporaryPassword(value) {
        this.temporaryPassword = value;
    }

    wrongPassword = false;

    setWrongPassword(value) {
        this.wrongPassword = value;
    }

    TemporaryUserInfo = {
        birthDay: 5,
        birthMonth: 6,
        birthYear: 2023,
        name: "Елизавета",
        surname: "Тихонова",
        sex: "Не указан",
        timezone: "(GMT+03:00) Moscow",
        photo: null,
        university: "Национальный исследовательский университет «Высшая школа экономики» (НИУ ВШЭ)",
    };

    setTemporaryProfileInfo(key, value) {
        this.TemporaryUserInfo[key] = value;
    }

    setTemporaryUniversity(value) {
        console.log(value);
        this.TemporaryUserInfo["university"] = value;
        console.log("dddddd");
    }

    temporaryCardNumber = null;
    temporaryCardExpirationDate = null;
    temporaryCardCVC = null;

    setTemporaryCardNumber(value) {
        this.temporaryCardNumber = value;
    }

    setTemporaryCardExpirationDate(value) {
        this.temporaryCardExpirationDate = value;
    }

    setTemporaryCardCVC(value) {
        this.temporaryCardCVC = value;
    }

    temporaryPhoneNumber = null;

    setTemporaryPhoneNumber(value) {
        this.temporaryPhoneNumber = value;
    }

    temporaryPhoneNumberHelper = false;

    setTemporaryPhoneNumberHelper(value) {
        this.temporaryPhoneNumberHelper = value;
    }

    cardDetection(card) {
        if (card.slice(0, 1) === "5") {
            return "Mastercard";
        } else if (card.slice(0, 1) === "4") {
            return "Visa";
        } else {
            return "Мир";
        }
    }

    cardButtonDisabled() {
        if (this.temporaryCardCVC !== null && this.temporaryCardNumber !== null && this.temporaryCardExpirationDate !== null) {
            return this.temporaryCardCVC.length !== 3 || this.temporaryCardNumber.length !== 19 || this.temporaryCardExpirationDate.length !== 5;
        } else {
            return true;
        }
    }

    sexList = ["Не указан", "Мужской", "Женский"];
    daysList = ["День", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    monthList = ["Месяц", "января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];
    yearsList = ["Год", 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2023];

    ClosePhone = (phone) => {
        let res = "";

        for (var i = 0; i < phone.length; i++) {
            if (i > 2 && i < 10 && phone[i] !== " ") res += "•";
            else res += phone[i];
        }

        return res;
    };

    constructor() {
        this.Init();
        makeAutoObservable(this);
    }

    debounce(callback, delay) {
        return (...args) => {
            if (this.timerID) {
                clearTimeout(this.timerID);
            }

            this.timerID = setTimeout(() => {
                callback.call(this, ...args);
            }, delay);
        };
    }

    Init() {
        this.Load();
    }

    Load() {}
}

export default new SettingsStore();
