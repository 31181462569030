import { memo } from "react";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import PaidServicesBodyBlock from "components/PaidServicesBodyBlock/PaidServicesBodyBlock";

const PaidServicesPage = () => {
    return (
        <ScrollToTop>
            <PaidServicesBodyBlock />
        </ScrollToTop>
    );
};

export default memo(PaidServicesPage);
