export const imgOrder1 = {
    left: 195,
    top: 18,
    position: "absolute",
};

export const imgOrder2 = {
    left: 0,
    top: 0,
    position: "absolute",
};

export const imgOrder3 = {
    left: 62,
    top: 130,
    position: "absolute",
};
