export const ModalRescheduleRequest = {
    padding: "20px 0 30px 0",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const RescheduleReason = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
};

export const ButtonContainer = {
    display: "flex",
    gap: "12px",
};

export const Button = {
    width: "194px",
};

export const RescheduleRequestTitle = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const timeTitle = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
};

export const timeContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "12px 0",
    borderBottom: "1px dashed #DADADF",
    borderTop: "1px dashed #DADADF",
};
