export const TextGrid = {
    borderRadius: "1.6vh",
    border: "1px solid #DADADF",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "0.8vh",
    marginTop: "0.3vh",
};

export const IconBack = {
    height: "3.9vh",
    width: "3.9vh",
    padding: "0px",
};

export const TextField = {
    "& fieldset": { border: "none" },
    "& .MuiOutlinedInput-root": { padding: "0.5vh 0px 0.5vh 0px", fontSize: "1.9vh", width: "13.4vw" },
};
