import React from "react";
import { Button } from "@mui/material";

const ButtonSave = ({ children, ...props }) => {
    return (
        <Button
            disableRipple
            disabled={props.buttondisabled}
            onClick={props.onClick}
            type={props.type}
            variant="contained"
            endIcon={props.endIcon}
            sx={{
                width: "192px",
                height: "42px",
                borderRadius: 15,
                textTransform: "none",
                justifyContent: "center",
                background: "#6212FF",
                boxShadow: "none",
                ":disabled": {
                    background: "#B088FF",
                    color: "white",
                    boxShadow: "none",
                },
                ":hover": {
                    color: "#FFFFFF",
                    boxShadow: "none",
                },
                ":active": { boxShadow: "none" },
                ...props.sx,
            }}
        >
            {children}
        </Button>
    );
};

export default ButtonSave;
