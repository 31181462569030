// import React from "react";
import { TableContainer, Table } from "@mui/material";
import SheduleTableBody from "./SheduleTableBody/SheduleTableBody.jsx";
import * as theme from "./SheduleTableStyled.js";
const SheduleTable = ({ weekDates }) => {
    return (
        <TableContainer sx={theme.tableContainer}>
            <Table>
                <SheduleTableBody weekDates={weekDates} />
            </Table>
        </TableContainer>
    );
};

export default SheduleTable;
