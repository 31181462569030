import { createTheme } from "@mui/material/styles";

export const AvatarTheme = (medias) => {
    return {
        components: {
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        position: "relative",
                        width: medias.sm ? "50px" : "36px",
                        height: medias.sm ? "50px" : "36px",
                        left: "0px",
                        top: "0px",
                        borderRadius: "50px",
                        cursor: "default",
                    },
                },
            },
        },
    };
};
