export const TopWrapper = { marginBottom: "40px" };

export const Text = {
    color: "#67677A",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    marginBottom: "20px",
};

export const FormWrapper = {
    display: "flex",
    alignItems: "center",
    marginTop: "16px",
};

export const FormControlLabel = { p: 0, mr: 0, ml: 0, mb: 0, mt: "3px" };

export const Checkbox = { p: 0, m: 0 };

export const CheckboxText = {
    marginLeft: "10px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "150%",
    fontWeight: 400,
    textTransform: "none",
    color: "#222",
    width: "493px",
};
