import React, { memo } from "react";
import * as themes from "./DescriptionBlockThemesDefault";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const DescriptionBlock = ({ children, ...props }) => {
    return (
        <Box sx={themes.descriptionBlockContainerStyle}>
            <Typography sx={themes.descriptionTextStyle}>{props.value.Answer}</Typography>
        </Box>
    );
};

export default memo(DescriptionBlock);
