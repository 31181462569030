import { Avatar, Typography, Tooltip } from "@mui/material";
import Distant from "assets/PAAssets/SVG/distant";
import Home from "assets/PAAssets/SVG/home";
import * as themes from "./ClassItemThemes";
import * as textThemes from "themes/TextThemes";
import { memo } from "react";
import Box from "@mui/material/Box";
import RegularStudentIcon from "assets/TeacherClassesAssets/SVG/RegularStudentIcon";
import NewStudentIcon from "assets/TeacherClassesAssets/SVG/NewStudentIcon";
import { useTranslation } from "react-i18next";
import EmptyAvatar from "assets/TeacherClassesAssets/PNG/EmptyAvatar.png";
import { useMedias } from "API/mediasHook";

const ClassItem = ({ chidlren, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.ClassItem(medias)}>
            <Box sx={themes.TimeBlock}>
                <Typography sx={textThemes.Body2Black}>
                    {props.item.timeStart.toLocaleString("ru", { hour: "numeric", minute: "numeric" })}-{props.item.timeEnd.toLocaleString("ru", { hour: "numeric", minute: "numeric" })}
                </Typography>
            </Box>
            <Box sx={themes.SubjectAndAvatarWrapper(medias)}>
                <Box sx={themes.PlaceSubjectBlock}>
                    {medias.sm && (
                        <>
                            {props.item.place === "online" ? (
                                <Tooltip
                                    title={t("teacherClasses.online")}
                                    PopperProps={themes.TooltipPopperProps}
                                    placement="top"
                                >
                                    <Box sx={themes.PlaceIconContainer}>
                                        <Distant />
                                    </Box>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={props.item.place === "tutor" ? t("teacherClasses.atTutor") : t("teacherClasses.atStudent")}
                                    PopperProps={themes.TooltipPopperProps}
                                    placement="top"
                                >
                                    <Box sx={themes.PlaceIconContainer}>
                                        <Home />
                                    </Box>
                                </Tooltip>
                            )}
                        </>
                    )}
                    <Box sx={themes.SubjectAimBlock}>
                        <Typography sx={textThemes.Body2Black}>{props.item.subject}</Typography>
                        <Typography sx={textThemes.Caption2Grey}>{props.item.aim}</Typography>
                    </Box>
                </Box>
                <Box sx={themes.StudentBlock}>
                    <Avatar
                        sx={themes.Avatar}
                        src={props.item.studentAvatar ? props.item.studentAvatar : EmptyAvatar}
                    />
                    <Box sx={themes.StudentNameContainer}>
                        <Typography sx={textThemes.Body2Black}>{props.item.studentName}</Typography>
                        {props.item.studentType === "constant" ? (
                            <Box>
                                <RegularStudentIcon />
                            </Box>
                        ) : props.item.studentType === "new" ? (
                            <Box>
                                <NewStudentIcon />
                            </Box>
                        ) : null}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default memo(ClassItem);
