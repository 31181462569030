import { HTTP_METHODS } from "../API/plugins/Constants";

// Сортировка ключей по алфавиту!
// Названия ключей разделять точкой (users.user).
// Последним идет тип метода рута, должен совпадать со значением поля method. В массив плейсхолдеров помещается позиция плейсхолдера в паттерне url, как если бы он был массивом.
export const routes = {
    "chats.download.get": {
        method: HTTP_METHODS.GET,
        urlPattern: "/chats/download",
        placeholdersPositions: [],
        shouldBeSuppressed: false,
    },
    "chats.block.user.post": {
        method: HTTP_METHODS.POST,
        urlPattern: "/chats/block-user",
        placeholdersPositions: [],
        shouldBeSuppressed: false,
    },
    "chats.unblock.user.post": {
        method: HTTP_METHODS.POST,
        urlPattern: "/chats/unblock-user",
        placeholdersPositions: [],
        shouldBeSuppressed: false,
    },
};

/**
 * Замена плейсхолдеров в url на значения
 * @param url Урл с плейсхолдерами
 * @param params Параметры
 * @returns {*}
 */
function prepareUrl(url, params) {
    Object.keys(params).forEach((key) => (url = url.replace(`{{${key}}}`, params[key])));
    return url;
}

/**
 * Получить маршрут бэкенда по его названию
 * @param {string} routeName
 * @param {{}} params
 * @returns {string}
 */
const backendHttpRoutes = (routeName, params = {}) => {
    if (typeof routes[routeName] === "undefined" && typeof routes[routeName]?.urlPattern === "undefined") {
        throw new Error('Route "' + routeName + '" not found!');
    }

    let url = routes[routeName]?.urlPattern ? `${routes[routeName].urlPattern}` : routes[routeName];

    return prepareUrl(url, params);
};

export default backendHttpRoutes;
