import StartBlock from "./StartBlock/StartBlock";
import HowWork from "./HowWork/HowWork";
import IncomeBlock from "./IncomeBlock/IncomeBlock";
import WithUs from "./WithUs/WithUs";
import WhoNeed from "./WhoNeed/WhoNeed";
import TutorsType from "./TutorsType/TutorsType";
import ServiceAdvantages from "./ServiceAdvantages/SerivceAdvantages";
import TopQuestions from "./TopQuestions/TopQuestions";
import Box from "@mui/material/Box";
import React from "react";
import * as themes from "./TutorPromoBodyBlockThemes";
import { memo } from "react";
import { useMedias } from "../../API/mediasHook";

const TutorPromoBodyBlock = ({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={themes.TutorPromoBodyBlock(medias)}>
            <StartBlock />
            <HowWork />
            <IncomeBlock />
            {/*<WithUs />*/}
            <WhoNeed />
            <TutorsType />
            <ServiceAdvantages />
            <TopQuestions />
        </Box>
    );
};

export default memo(TutorPromoBodyBlock);
