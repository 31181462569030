import React from "react";
import { observer } from "mobx-react-lite";

import { Avatar, Stack } from "@mui/material";

import { StyledInfoCardText } from "components/ChatBodyBlock/ChatListBlock/ChatTabsSearch/styled";
import HighlightedText from "../HighlightedText/нighlightWords";

import Evgen from "assets/ChatAssets/PNG/Evgen.png";
import Profile from "assets/ChatAssets/PNG/profile_navbar.png";

import FullChatStore from "store/FullChatStore";
import { findChatById } from "../../CommonData/helper_functions";
import * as styles from "../GridFieldChatThemes";
import Box from "@mui/material/Box";

const searchMessages = [
    {
        icon: Evgen,
        message: "Здравствуйте! Мне нужно оплатить",
        name: "Евгений Тихонов",
        searchWords: ["Мне", "Здр"],
        time: "21:39",
        message_id: "13",
    },
    {
        icon: Profile,
        message: "Здравствуйте, Евгений Александрович!",
        name: "Елена Валерьевна",
        searchWords: ["Мне", "Здр"],
        time: "21:39",
        message_id: "11",
    },
];

const handleClick = (id) => {
    FullChatStore.SetToScrollMessage(id);
};

const SearchMessagesResult = ({ page }) => {
    return FullChatStore.showSearchMessagesResultModal ? (
        <>
            {FullChatStore.foundMessages
                ? FullChatStore.foundMessages.slice((page - 1) * 2, (page - 1) * 2 + 2).map((item, index) => {
                      return (
                          <Stack
                              onClick={() => {
                                  handleClick(item.message_id);
                              }}
                              key={index}
                              sx={{
                                  height: "40px",
                                  padding: "8px",
                                  zIndex: "2",
                                  backgroundColor: "white",
                                  borderBottom: "solid 1px rgba(218, 218, 223, 1)",
                              }}
                              direction={"row"}
                          >
                              <Avatar
                                  src={
                                      item.owner === FullChatStore.participantId
                                          ? "https://study-buddy.ru" + findChatById(FullChatStore.tabsData.chats, FullChatStore.id).participants_info[0].photo.link
                                          : "https://study-buddy.ru" + FullChatStore.tabsData.user_info.photo.link
                                  }
                              />
                              <Stack
                                  sx={{
                                      marginLeft: "15px",
                                      width: "86%",
                                  }}
                                  direction={"column"}
                              >
                                  <StyledInfoCardText
                                      fontWeight={600}
                                      fontSize={"14px"}
                                      lineHeight={"20px"}
                                      color={"rgba(34, 34, 34, 1)"}
                                  >
                                      {item.owner === FullChatStore.participantId
                                          ? findChatById(FullChatStore.tabsData.chats, FullChatStore.id).participants_info[0].name
                                          : FullChatStore.tabsData.user_info.name}
                                  </StyledInfoCardText>
                                  <StyledInfoCardText
                                      fontWeight={400}
                                      fontSize={"14px"}
                                      lineHeight={"19px"}
                                      color={"rgba(119, 119, 138, 1)"}
                                  >
                                      <HighlightedText
                                          text={item.message}
                                          substrings={[FullChatStore.searchMessageText]}
                                      />
                                  </StyledInfoCardText>
                              </Stack>
                              <StyledInfoCardText
                                  fontWeight={400}
                                  fontSize={"14px"}
                                  lineHeight={"19px"}
                                  color={"rgba(119, 119, 138, 1)"}
                                  sx={{
                                      display: "flex",
                                      alignItems: "flex-end",
                                  }}
                              >
                                  {item.created_at.split(" ")[1]}
                              </StyledInfoCardText>
                          </Stack>
                      );
                  })
                : FullChatStore.searchMessageText && <Box sx={styles.NoFound}>Ничего не найдено</Box>}
        </>
    ) : null;
};

export default observer(SearchMessagesResult);
