import React from "react";

import { Divider, Stack, Typography } from "@mui/material";

import { Body2Black, Button14Black, HeadlineH5Black } from "themes/TextThemes";

const TutorFee = () => {
    return (
        <Stack
            spacing={1.5}
            direction="column"
        >
            <Divider
                flexItem
                sx={{
                    border: "1px dashed #DADADF",
                }}
            />
            <Stack
                direction="column"
                spacing={0.75}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                >
                    <Typography sx={Body2Black}>Цена для заказчика</Typography>
                    <Typography sx={Button14Black}>10 000 ₽</Typography>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                >
                    <Typography sx={Body2Black}>{"Комиссия сервиса (-15%)"}</Typography>
                    <Typography sx={Button14Black}>1 500 ₽</Typography>
                </Stack>
            </Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
            >
                <Typography sx={HeadlineH5Black}>Твой гонорар</Typography>
                <Typography sx={HeadlineH5Black}>8 500 ₽</Typography>
            </Stack>
        </Stack>
    );
};

export default TutorFee;
