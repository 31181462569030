import React, { memo } from "react";
import Box from "@mui/material/Box";
import * as themes from "./EducationContentThemes";
import { Tooltip, Typography } from "@mui/material";
import { SafetyCheck, VerifiedUser } from "@mui/icons-material";
import { ThemeProvider } from "@mui/material/styles";
import { useMedias } from "../../../../API/mediasHook";

const EducationContent = ({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={themes.educationContentContainerStyle}>
            {props.value.map((value) => (
                <Box
                    key={value.ID}
                    sx={themes.educationItemStyle(medias)}
                >
                    <ThemeProvider theme={themes.VerificationTheme(value.IsVerified)}>
                        <Tooltip
                            title={<Typography sx={themes.tooltipVerificationStyle(value.IsVerified)}>{value.IsVerified ? "Подтверждено документом" : "Документ ожидает проверки"}</Typography>}
                            arrow={true}
                            placement="top-start"
                        >
                            {value.IsVerified ? <VerifiedUser sx={themes.verifiedStyle}></VerifiedUser> : <SafetyCheck sx={themes.safetyCheckStyle}></SafetyCheck>}
                        </Tooltip>
                    </ThemeProvider>
                    <Typography sx={themes.educationInfoStyle}>
                        {value.EducationInfo}, <span style={themes.educationYearStyle}>{value.EducationYear} г.</span>
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};

export default memo(EducationContent);
