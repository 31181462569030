import { styleProcessing } from "API/plugins/Utilities";

export const OperationsTableEmpty = {
    maxWidth: "804px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const HistoryEmpty = {
    marginTop: "10px",
    borderRadius: "16px",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "20px",
    alignItems: "center",
    textAlign: "center",
};

export const CreateNewOrderTopContainer = {
    display: "flex",
    alignItems: "center",
    gap: "20px",
};

export const CreateNewOrder = {
    display: "flex",
    flexDirection: "column",
    borderRadius: "16px",
    background: "#FFF",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
    gap: "24px",
    padding: "20px",
};

export const CreateNewOrderBottomContainer = {
    display: "flex",
    justifyContent: "flex-end",
};

export const HistoryEmptyContent = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
};

export const HistoryEmptyContentSubtitle = {
    maxWidth: "598px",
    width: "100%",
};

export const ButtonSize = (theme) => {
    let commonStyle = {};

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
