import { Box, Typography, styled } from "@mui/material";

export const StyledOrderResponseHiddenAvatar = styled(Box)({
    display: "flex",
    height: "90px",
    marginRight: "20px",
    borderRadius: "50%",
    border: "1px dashed",
    borderColor: "rgba(187, 187, 200, 1)",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
});

export const StyledOrderResponseHiddenAvatarText = styled(Typography)({
    textAlign: "center",
    fontSize: "16px",
    lineHeight: "18px",
    color: "rgba(103, 103, 122, 1)",
});
