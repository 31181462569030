import { Avatar, Box, Divider, Typography } from "@mui/material";
import OrderTarget from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/OrderTarget/OrderTarget";
import OrderDate from "components/OrdersBodyBlock/StudentOrders/OrderCards/CommonCardsComponents/OrderDate/OrderDate";
import * as themes from "./CompletedOrderCardThemes";
import { useMedias } from "API/mediasHook";
import { memo, useState } from "react";
import * as textThemes from "themes/TextThemes";
import * as React from "react";
import { useTranslation } from "react-i18next";
import StarRatingIcon from "assets/CommonAssets/SVG/StarRatingIcon";
import { useNavigate } from "react-router-dom";

const CompletedOrderCard = ({ children, ...props }) => {
    const [rateHover, setRateHovered] = useState(false);

    const navigate = useNavigate();

    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const formatDate = (date) => {
        const months = [
            "main.months.atJanuary",
            "main.months.atFebruary",
            "main.months.atMarch",
            "main.months.atApril",
            "main.months.atMay",
            "main.months.atJune",
            "main.months.atJuly",
            "main.months.atAugust",
            "main.months.atSeptember",
            "main.months.atOctober",
            "main.months.atNovember",
            "main.months.atDecember",
        ];

        const day = date.getDate();

        const month = months[date.getMonth()];

        return `${day} ${t(month)}`;
    };

    const handleClickRate = () => {
        navigate("/diplomas");
    };

    return (
        <Box sx={themes.StyledOrderCard(medias)}>
            <Box sx={themes.TopBlock}>
                <Box sx={themes.TopContentWrapper}>
                    <Typography sx={{ ...textThemes.HeadlineH5Black, ...themes.CardTitle }}>
                        {props.order.baseOrderInfo.orderType === "tutoring"
                            ? props.order.baseOrderInfo.subject
                                ? props.order.baseOrderInfo.subject
                                : props.order.baseOrderInfo.requestTopic
                            : props.order.baseOrderInfo.requestTopic}
                    </Typography>
                    <OrderDate date={props.order.baseOrderInfo.createdAt} />
                </Box>
                <Box sx={themes.ContentAndRateBlock}>
                    <Box sx={themes.PurposeAndFinishTimeBlock}>
                        <OrderTarget
                            order={props.order}
                            type="Body2"
                        />
                        <Box sx={themes.FinshedDateBlock}>
                            <Typography sx={textThemes.Body2Grey}>{t("orders.studentOrders.completionDate")}</Typography>
                            <Typography sx={textThemes.Body2Black}>{formatDate(props.order.baseOrderInfo.finishedAt)}</Typography>
                        </Box>
                    </Box>
                    {props.order.baseOrderInfo.grade ? (
                        <Box sx={themes.RatingBlock(props.order.baseOrderInfo.grade)}>
                            <Typography sx={textThemes.Button14BoldWhite}>{props.order.baseOrderInfo.grade}</Typography>
                            <Box sx={themes.StarIcon}>
                                <StarRatingIcon />
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            onClick={handleClickRate}
                            sx={themes.RateButton(rateHover)}
                            onMouseEnter={() => setRateHovered(true)}
                            onMouseLeave={() => setRateHovered(false)}
                        >
                            <Typography sx={textThemes.Button14Purple}>{t("orders.studentOrders.rate")}</Typography>
                        </Box>
                    )}
                </Box>
            </Box>
            <Box>
                <Box sx={themes.BottomBlock}>
                    <Divider sx={themes.Divider} />
                    <Box sx={themes.TutorAndStatusBlock}>
                        <Box sx={themes.AvatarContainer}>
                            {props.order.baseOrderInfo.doer.anonymous ? (
                                <Box sx={themes.PhotoHidden}>
                                    <Typography sx={themes.PhotoHiddenText}>{t("orders.studentOrders.photoHidden")}</Typography>
                                </Box>
                            ) : (
                                <Avatar src={props.order.baseOrderInfo.doer.photo.link} />
                            )}
                            <Typography sx={textThemes.Body2Black}>
                                {props.order.baseOrderInfo.doer.anonymous
                                    ? t("orders.studentOrders.fioHidden")
                                    : `${props.order.baseOrderInfo.doer.name} ${props.order.baseOrderInfo.doer.surname[0]}.`}
                            </Typography>
                        </Box>
                        <Box sx={themes.StatusContainer}>
                            <Typography sx={themes.StatusBull}>&bull;</Typography>
                            <Typography sx={textThemes.Body2Black}>{t("orders.studentOrders.completed")}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default memo(CompletedOrderCard);
