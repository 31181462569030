import { Typography } from "@mui/material";
import React from "react";
import { memo } from "react";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";

const CancellationAuthorText = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Typography sx={textThemes.Body2Red}>
            {props.cancelStatus === "teacher" && t("orders.tutorOrders.youCancelledOrder")}
            {props.cancelStatus === "student" && t("orders.tutorOrders.studentCancelledOrder")}
        </Typography>
    );
};

export default memo(CancellationAuthorText);
