import { AccountManagerServiceClient } from "API/services/grpc/proto/AccountManagerService/account-manager_grpc_web_pb";

import {
    AddTutorQuestionaryRequest,
    GetPersonalDataRequest,
    UpdatePersonalDataRequest,
    UpdateProfilePhotoRequest,
    GetTutorQuestionaryRequest,
} from "API/services/grpc/proto/AccountManagerService/account-manager_pb";

const AccountManagerServiceUrl = "https://study-buddy.ru:8080";

const AccountManagerService = new AccountManagerServiceClient(AccountManagerServiceUrl, null, null);

export async function AddTutorQuestionary(data, metadata, callback) {
    let request = new AddTutorQuestionaryRequest();

    let { body } = data;

    console.log(body);

    Object.keys(body).forEach((key) => {
        let value = body[key];

        switch (key) {
            case "audience":
                request.setAudienceList(value);
                break;
            case "subjects":
                let subjectsList = [];

                value.forEach((subject) => {
                    // eslint-disable-next-line no-undef
                    let subjectPB = new proto.pb.Subject();

                    subjectPB.setSubjectName(subject.subjectName);

                    let conditionsList = [];

                    subject["conditions"].forEach((condition) => {
                        // eslint-disable-next-line no-undef
                        let conditionPB = new proto.pb.Condition();

                        conditionPB.setType(condition.type);
                        conditionPB.setDescription(condition.description);
                        conditionPB.setPrice(condition.price);

                        conditionsList.push(conditionPB);
                    });

                    if (conditionsList.length) {
                        subjectPB.setConditionsList(conditionsList);
                    }

                    subjectsList.push(subjectPB);
                });

                request.setSubjectsList(subjectsList);

                break;
            case "classFormats":
                request.setClassFormatsList(value);
                break;
            case "tutorsPlace":
                // eslint-disable-next-line no-undef
                let tutorsPlacePB = new proto.pb.Place();

                tutorsPlacePB.setCity(value.city);
                tutorsPlacePB.setLocationsList(value.locations);

                request.setTutorsPlace(tutorsPlacePB);
                break;
            case "studentsPlaces":
                // eslint-disable-next-line no-undef
                let studentsPlacesPB = new proto.pb.Place();

                studentsPlacesPB.setCity(value.city);
                studentsPlacesPB.setLocationsList(value.locations);

                request.setStudentsPlaces(studentsPlacesPB);
                break;
            case "educations":
                let educationsList = [];

                value.forEach((education) => {
                    // eslint-disable-next-line no-undef
                    let educationPB = new proto.pb.Education();

                    educationPB.setUniversity(education.university);
                    educationPB.setFaculty(education.faculty);
                    educationPB.setSpeciality(education.speciality);
                    educationPB.setStartYear(education.startYear);
                    educationPB.setStudyNowadays(education.studyNowadays);
                    educationPB.setFinishYear(education.finishYear);

                    let confirmatoryDocumentsList = [];

                    education["confirmatoryDocuments"].forEach((document) => {
                        // eslint-disable-next-line no-undef
                        let documentPB = new proto.pb.Document();

                        documentPB.setName(document.name);
                        documentPB.setIsPublic(document.isPublic);
                        documentPB.setData(document.data);

                        confirmatoryDocumentsList.push(documentPB);
                    });

                    if (confirmatoryDocumentsList.length) {
                        educationPB.setConfirmatoryDocumentsList(confirmatoryDocumentsList);
                    }

                    educationsList.push(educationPB);
                });

                request.setEducationsList(educationsList);
                break;
            case "experience":
                let experienceList = [];

                value.forEach((experience) => {
                    // eslint-disable-next-line no-undef
                    let experiencePB = new proto.pb.ExperienceInfo();

                    experiencePB.setOrganization(experience.organization);
                    experiencePB.setPost(experience.post);
                    experiencePB.setStartYear(experience.startYear);
                    experiencePB.setWorkNowadays(experience.workNowadays);
                    experiencePB.setFinishYear(experience.finishYear);

                    experienceList.push(experiencePB);
                });

                request.setExperienceList(experienceList);
                break;
            case "certificates":
                let certificatesList = [];

                value.forEach((certificate) => {
                    // eslint-disable-next-line no-undef
                    let certificatePB = new proto.pb.Certificate();

                    certificatePB.setCertificateName(certificate.certificateName);
                    certificatePB.setYearOfGraduating(certificate.yearOfGraduating);

                    let confirmatoryDocumentsList = [];

                    certificate["confirmatoryDocuments"].forEach((document) => {
                        // eslint-disable-next-line no-undef
                        let documentPB = new proto.pb.Document();

                        documentPB.setName(document.name);
                        documentPB.setIsPublic(document.isPublic);
                        documentPB.setData(document.data);

                        confirmatoryDocumentsList.push(documentPB);
                    });

                    if (confirmatoryDocumentsList.length) {
                        certificatePB.setConfirmatoryDocumentsList(confirmatoryDocumentsList);
                    }

                    certificatesList.push(certificatePB);
                });

                request.setCertificatesList(certificatesList);
                break;
            case "latestTimeUntilClass":
                request.setLatestTimeUntilClass(value);
                break;
            case "weekDaysTimetables":
                let weekDaysTimetablesList = [];

                value.forEach((timetable) => {
                    // eslint-disable-next-line no-undef
                    let timetablePB = new proto.pb.WeekDayTimetable();

                    timetablePB.setWeekDayName(timetable.weekDayName);
                    timetablePB.setHoursList(timetable.hours);

                    weekDaysTimetablesList.push(timetablePB);
                });

                request.setWeekDaysTimetablesList(weekDaysTimetablesList);
                break;
            case "aboutMeText":
                request.setAboutMeText(value);
                break;
            case "videoCard":
                // eslint-disable-next-line no-undef
                let videoCardPB = new proto.pb.Document();

                videoCardPB.setName(value.name);
                videoCardPB.setIsPublic(value.isPublic);
                videoCardPB.setData(value.data);

                request.setVideoCard(videoCardPB);
                break;
            case "externalServicesProfiles":
                let externalServicesProfilesList = [];

                value.forEach((profile) => {
                    // eslint-disable-next-line no-undef
                    let profilePB = new proto.pb.ExternalProfile();

                    profilePB.setWebsiteName(profile.websiteName);
                    profilePB.setUrl(profile.url);

                    externalServicesProfilesList.push(profilePB);
                });

                request.setExternalServicesProfilesList(externalServicesProfilesList);
                break;
            default:
                break;
        }
    });

    await AccountManagerService.addTutorQuestionary(request, metadata, callback);
}

export async function GetTutorQuestionary(data, metadata, callback) {
    let request = new GetTutorQuestionaryRequest();

    await AccountManagerService.getTutorQuestionary(request, metadata, callback);
}

export async function GetPersonalData(data, metadata, callback) {
    let request = new GetPersonalDataRequest();

    await AccountManagerService.getPersonalData(request, metadata, callback);
}

export async function UpdatePersonalData(data, metadata, callback) {
    let request = new UpdatePersonalDataRequest();

    let { name, surname, patronymic, gender, dateOfBirth, timezoneUtcOffset, city } = data;

    console.log(data, dateOfBirth);

    request.setName(name);
    request.setSurname(surname);
    request.setPatronymic(patronymic);
    request.setGender(gender);
    request.setDateOfBirth(dateOfBirth);
    request.setTimezoneUtcOffset(timezoneUtcOffset);
    request.setCity(city);

    await AccountManagerService.updatePersonalData(request, metadata, callback);
}

export async function UpdateProfilePhotoData(data, metadata, callback) {
    let request = new UpdateProfilePhotoRequest();

    let { photoData } = data;

    request.setData(photoData);

    await AccountManagerService.updateProfilePhoto(request, metadata, callback);
}
