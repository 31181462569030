import { memo, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import * as styles from "./PaymentBodyBlockThemes";
import * as textStyles from "themes/TextThemes";
import { observer } from "mobx-react-lite";
import ClassInfo from "./ClassInfo/ClassInfo";
import PaymentInfo from "./PaymentInfo/PaymentInfo";
import { useMedias } from "API/mediasHook";
import PaymentStore from "store/PaymentStore";

const PaymentBodyBlock = observer(({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={styles.PaymentBodyBlock(medias)}>
            <Box sx={styles.PaymentBodyBlockContent}>
                {!medias.sm ? (
                    PaymentStore.showPaymentInfo ? (
                        <PaymentInfo />
                    ) : (
                        <ClassInfo />
                    )
                ) : (
                    <>
                        <ClassInfo />
                        <PaymentInfo />
                    </>
                )}
            </Box>
            {medias.sm ? (
                <Box sx={styles.PaymentBottomBlock}>
                    <Typography sx={textStyles.Body2Grey}>© 2023 StudyBuddy</Typography>
                    <Typography
                        sx={textStyles.Body2Grey}
                        style={styles.LawInfo}
                    >
                        Правовая информация
                    </Typography>
                </Box>
            ) : null}
        </Box>
    );
});

export default memo(PaymentBodyBlock);
