import React, { useRef } from "react";
import { observer } from "mobx-react-lite";

import { Stack } from "@mui/material";

import ClipItems from "./SendMessageBoxItems/ClipItems/ClipItems";
import ChatTextField from "./SendMessageBoxItems/ChatTextField/ChatTextField";

import FullChatStore from "store/FullChatStore";
import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";
import VideoChatStore from "store/VideoChatStore";
import SendMessageButton from "./SendMessageBoxItems/SendMessageButton";
import EmojiButton from "./SendMessageBoxItems/EmojiButton";

const SendMessageBox = ({
    disable = false,
    setDisable,
    text,
    setText,
    sendJsonMessage,
    openReplyModalMessage,
    setOpenReplyModalMessage,
    openEditModalMessage,
    setOpenEditModalMessage,
    isDiploma,
    isVideo,
}) => {
    const textFieldRef = useRef(null);

    const message = {
        action: "send_message",
        chat_id: isDiploma ? DiplomaStore.id : isVideo ? VideoChatStore.chatData.id : FullChatStore.id,
        message: text,
    };

    const handleClickOnTextField = () => {
        console.log(openReplyModalMessage);
        openReplyModalMessage ? setText("") : setText(text);
        setDisable(false);
    };

    const [changed, setChanged] = React.useState(false);

    const handleChange = (content) => {
        setText(`${content}`);
        if (content.length > 0) {
            isDiploma ? DiplomaStore.SendIsWrite(sendJsonMessage, true) : isVideo ? VideoChatStore.SendIsWrite(sendJsonMessage, true) : FullChatStore.SendIsWrite(sendJsonMessage, true);
            setChanged(true);
        } else {
            isDiploma ? DiplomaStore.SendIsWrite(sendJsonMessage, false) : isVideo ? VideoChatStore.SendIsWrite(sendJsonMessage, false) : FullChatStore.SendIsWrite(sendJsonMessage, false);
            setChanged(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13 && !e.shiftKey && text) {
            sendJsonMessage(message);
            setText("");
            isDiploma ? DiplomaStore.SendIsWrite(sendJsonMessage, false) : isVideo ? VideoChatStore.SendIsWrite(sendJsonMessage, false) : FullChatStore.SendIsWrite(sendJsonMessage, false);
            setChanged(false);
            e.preventDefault();
        } else if (e.keyCode === 13 && e.shiftKey) {
            const currentCursorPos = textFieldRef.current.childNodes[0].childNodes[0].selectionStart;

            setText(`${text.slice(0, currentCursorPos)}\n${text.slice(currentCursorPos)}`);
            e.preventDefault();
        }
    };

    return (
        <Stack
            direction="row"
            p="10px 16px"
            borderRadius="12px"
            border="1px solid #DADADF"
            alignItems="center"
            gap="12px"
        >
            <ClipItems />
            <ChatTextField
                ref={textFieldRef}
                onClickFunc={handleClickOnTextField}
                onChangeFunc={handleChange}
                onKeyDownFunc={handleKeyDown}
                messageText={disable ? "" : text}
            />
            <EmojiButton
                text={text}
                setText={setText}
                setChanged={setChanged}
            />
            <SendMessageButton
                isDiploma={isDiploma}
                isVideo={isVideo}
                changed={changed}
                openEditModalMessage={openEditModalMessage}
                setOpenEditModalMessage={setOpenEditModalMessage}
                openReplyModalMessage={openReplyModalMessage}
                setOpenReplyModalMessage={setOpenReplyModalMessage}
                text={text}
                setText={setText}
                message={message}
                sendJsonMessage={sendJsonMessage}
            />
        </Stack>
    );
};

export default observer(SendMessageBox);
