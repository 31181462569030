import React, { memo } from "react";
import * as themes from "./NameBlockThemes";
import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";

const NameBlock = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.nameBlockContainerStyle}>
            <ThemeProvider theme={themes.NameAndActivityTheme}>
                <Typography sx={themes.nameStyle}>{props.value.IsAnonymous ? "ФИО скрыты" : props.value.Name}</Typography>
            </ThemeProvider>
            <Box sx={themes.ratingAndReviewsContainerStyle}>
                {props.value.Rating ? (
                    <Box sx={themes.ratingContainerStyle}>
                        <Typography sx={themes.ratingStyle}>{props.value.Rating}</Typography>
                    </Box>
                ) : null}
                <Typography sx={themes.reviewsStyle(props.value.Rating)}>{t("main.reviews", { count: props.value.ReviewsCount })}</Typography>
            </Box>
        </Box>
    );
});

export default memo(NameBlock);
