import { Box } from "@mui/material";
import React from "react";
import Cropper from "react-cropper";

const ImageCropperMini = ({
    imageSrc,
    setCropper,
    height,
    width,

    setChecker,
}) => {
    const handleCropMini = () => {
        setChecker(Math.floor(Math.random() * 11000));
    };

    return (
        <Box>
            {imageSrc && (
                <>
                    <Cropper
                        src={imageSrc}
                        style={{ height: height, width: width }}
                        initialAspectRatio={9 / 13}
                        aspectRatio={9 / 13}
                        guides={true}
                        cropend={handleCropMini}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                    />
                </>
            )}
        </Box>
    );
};

export default ImageCropperMini;
