//В каком ВУЗе обучаетесь?
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import SBAutoComplete from "components/UI/Autocomplete";
import SBButtonGroup from "components/UI/ButtonGroup";
import store from "store/RequestStore";
import { universities } from "API/dictionaries/const_data";
import { myUniversity } from "API/dictionaries/const_data";

import { Box, Checkbox, FormControlLabel } from "@mui/material";
import StepHiddenButtonWrapper from "components/OrdersBodyBlock/RenewOrder/StepHiddenButtonWrapper";
import filterStore from "store/FilterStore";
import { RequestNullAlert } from "./RequestNullAlertThemes";
import * as theme from "./Step14Themes";
const Step14 = () => {
    const [alert, setAlert] = useState(0);

    const [stepData, setStepData] = useState(store.storage[store.activeStepIndex].substeps[store.subStepIndex].value[1]);

    const [stepCB, setStepCB] = useState(store.storage[store.activeStepIndex].substeps[store.subStepIndex].value[0]);

    const handleStepData = () => {
        if (stepData) {
            store.setStep(2, stepData);
            store.setStepValue(2, [stepCB, stepData]);
        }
    };

    const handleIncrement = () => {
        if (stepData) {
            store.incrementStepIndex();
        }
    };

    const handleNext = () => {
        handleStepData();
        handleIncrement();
    };

    return (
        <>
            <Box sx={theme.Step14Wrapper}>
                <SBAutoComplete
                    value={stepData}
                    changefunc={setStepData}
                    inputvalue={stepData}
                    inputchangefunc={setStepData}
                    list={universities}
                    placeholder="Название или аббревиатура ВУЗа"
                />
            </Box>

            <Box sx={theme.FormWrapper}>
                <FormControlLabel
                    sx={theme.FormControlLabel}
                    control={
                        <Checkbox
                            sx={theme.Checkbox}
                            checked={stepCB}
                            onChange={(event, newInputValue) => {
                                filterStore.setSubmitButtonFilter(false);
                                setStepCB(newInputValue);
                                if (newInputValue) {
                                    setStepData(myUniversity);
                                } else {
                                    setStepData("");
                                }
                            }}
                            style={{ color: "#6212FF" }}
                        />
                    }
                />
                <Box sx={theme.Text}>Показывать репетиторов только из моего ВУЗа (студентов, выпускников, преподавателей)</Box>
            </Box>
            <StepHiddenButtonWrapper
                submitRenew={handleStepData}
                onChange={(event, newInputValue) => {
                    setStepCB(newInputValue);
                    if (newInputValue) {
                        setStepData(myUniversity);
                    } else {
                        setStepData("");
                    }
                }}
            >
                <SBButtonGroup
                    handleNext={handleNext}
                    handleBack={() => store.decrementStepIndex()}
                    activeStep={store.activeStepIndex}
                    subStep={store.subStepIndex}
                    steps={store.storage}
                />
                {alert === 1 ? <RequestNullAlert>Чтобы перейти к следующему вопросу, заполните это поле</RequestNullAlert> : null}
            </StepHiddenButtonWrapper>
        </>
    );
};

export default observer(Step14);
