export const Muted = () => {
    return (
        <svg
            width="18px"
            height="18px"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.0216 3.97511C16.7839 3.73746 16.4066 3.73746 16.1689 3.97511L13.7525 6.41149V5.43847C13.7525 4.90877 13.4842 4.45498 13.0115 4.19238L13.0079 4.19049C12.5615 3.95416 12.0317 3.95309 11.5821 4.21757L8.24927 6.31764L8.24499 6.32049C8.20842 6.34488 8.17845 6.35528 8.12801 6.35528H4.20778C3.43636 6.35528 2.7998 6.99183 2.7998 7.76326V12.6863C2.7998 13.4578 3.43636 14.0943 4.20778 14.0943H6.07252L4.63616 15.5307C4.39851 15.7683 4.39851 16.1457 4.63616 16.3833C4.74247 16.4896 4.90748 16.5558 5.05109 16.5558C5.19469 16.5558 5.3597 16.4896 5.46601 16.3833L17.0216 4.82776C17.2592 4.59011 17.2592 4.21276 17.0216 3.97511ZM8.1508 7.57579C8.41689 7.57579 8.68144 7.49652 8.89498 7.36306L12.2229 5.26601C12.2707 5.23621 12.3172 5.23021 12.3577 5.23332C12.3785 5.23493 12.3972 5.23898 12.4122 5.24343C12.4191 5.24549 12.4249 5.24753 12.4294 5.24926C12.4564 5.26773 12.4776 5.2844 12.4951 5.30776C12.5123 5.3307 12.532 5.36851 12.532 5.43847V7.58974L7.26974 12.8966H4.20778C4.09032 12.8966 3.99753 12.8038 3.99753 12.6863V7.78605C3.99753 7.66859 4.09032 7.57579 4.20778 7.57579H8.1508Z"
                fill="#9E9EB0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4602 15.2197L12.4491 15.2253L12.4387 15.2322C12.4236 15.2422 12.399 15.254 12.3713 15.2562C12.349 15.258 12.3118 15.2551 12.2594 15.2158L12.2526 15.2107L10.0574 13.8387C9.78848 13.6706 9.4079 13.7328 9.23203 14.0434C9.06747 14.3119 9.13051 14.6891 9.43908 14.8641L11.623 16.2294L11.632 16.2344C11.8577 16.3598 12.0963 16.4419 12.3673 16.4419C12.6011 16.4419 12.8439 16.39 13.0636 16.2534C13.5034 15.9937 13.7981 15.5452 13.7981 15.0111V10.9778L13.7933 10.9563C13.7329 10.6844 13.4871 10.4123 13.165 10.4123C12.8267 10.4123 12.5548 10.6841 12.5548 11.0225V15.0339C12.5548 15.0978 12.5334 15.1416 12.5102 15.172C12.488 15.2012 12.4645 15.2168 12.4602 15.2197Z"
                fill="#9E9EB0"
            />
        </svg>
    );
};
