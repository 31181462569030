import TextField from "@mui/material/TextField";
import ExclamationIcon from "assets/CommonAssets/SVG/exclamation";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import * as React from "react";
import PdfIconSVG from "assets/CommonAssets/SVG/pdfIconSVG";
import PptIconSVG from "assets/CommonAssets/SVG/pptIconSVG";
import ZipIconSVG from "assets/CommonAssets/SVG/zipIconSVG";
import RarIconSVG from "assets/CommonAssets/SVG/rarIconSVG";
import DocIconSVG from "assets/CommonAssets/SVG/docIconSVG";
import TxtIconSVG from "assets/CommonAssets/SVG/txtIconSVG";
import TifIconSVG from "assets/CommonAssets/SVG/tifIconSVG";
import PpsIconSVG from "assets/CommonAssets/SVG/ppsIconSVG";
import XlsIconSVG from "assets/CommonAssets/SVG/xlsIconSVG";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Paper from "@mui/material/Paper";
import RedExclamationIcon from "assets/CommonAssets/SVG/RedExclamation";
import { useDropzone } from "react-dropzone";
import filterStore from "store/FilterStore";
import * as theme from "./CommentsPageThemes";
import { Box } from "@mui/material";
export default function CommentsPage({ addAttach, attachedFiles, setAttachedFiles, orderCommentText, setOrderCommentText }) {
    const { getRootProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            const url = URL.createObjectURL(acceptedFiles[0]);

            console.log(url);
            const tempDict = {
                fileName: acceptedFiles[0].name,
                fileSize: (acceptedFiles[0].size / 1000000).toFixed(2),
                fileType: acceptedFiles[0].type,
                fileUrl: url,
            };

            const result = [...attachedFiles, tempDict];

            setAttachedFiles(result);
        },
    });

    const handleDelete = (attachToDelete) => () => {
        setAttachedFiles((attaches) => attaches.filter((attach) => attach !== attachToDelete));
    };

    const handleChangeSubmitFilterStore = () => {
        filterStore.setSubmitButtonFilter(false);
    };

    return (
        <Box onChange={handleChangeSubmitFilterStore}>
            <TextField
                InputProps={theme.InputProps}
                sx={theme.TextField}
                id="outlined-multiline-flexible"
                multiline
                maxRows={6}
                value={orderCommentText}
                placeholder="Напишите важные детали для репетитора, о которых мы не спросили"
                variant="outlined"
                onChange={(event) => {
                    setOrderCommentText(event.target.value);
                }}
            />
            <Box sx={theme.ExclamationWrappper}>
                <ExclamationIcon />
                <Box sx={theme.ExclamationText}>Пожалуйста, не оставляйте свои контактные данные — они будут удалены при проверке</Box>
            </Box>
            <Box onClick={handleChangeSubmitFilterStore}>
                <Button
                    {...getRootProps()}
                    sx={theme.AddFileButton}
                    variant="contained"
                    component="label"
                    className="attachButton"
                >
                    {" "}
                    Перетащите или добавьте фото/файл
                    <Box sx={theme.CircleiconPos}>
                        <AddCircleOutlineIcon
                            className="attachButtonBlur"
                            fontSize="medium"
                            sx={theme.Circleicon}
                        />

                        <AddCircleIcon
                            className="attachButtonHover"
                            fontSize="medium"
                            sx={theme.Circleicon}
                        />
                    </Box>
                </Button>
            </Box>
            <Paper sx={theme.Paper}>
                {attachedFiles.map((file, index) => {
                    return (
                        <Box
                            key={index}
                            sx={[theme.AddedItemWrapper, { marginTop: index === 0 ? "30px" : "20px" }]}
                        >
                            <Box sx={theme.AddedItemDisplay}>
                                <Box sx={theme.AddedItemMrgin}>
                                    {file.fileName.slice(-3) === "pdf" ? (
                                        <PdfIconSVG />
                                    ) : file.fileName.slice(-4) === "pptx" ? (
                                        <PptIconSVG />
                                    ) : file.fileName.slice(-3) === "zip" ? (
                                        <ZipIconSVG />
                                    ) : file.fileName.slice(-3) === "rar" ? (
                                        <RarIconSVG />
                                    ) : file.fileName.slice(-3) === "doc" ? (
                                        <DocIconSVG />
                                    ) : file.fileName.slice(-3) === "txt" ? (
                                        <TxtIconSVG />
                                    ) : file.fileName.slice(-3) === "tif" ? (
                                        <TifIconSVG />
                                    ) : file.fileName.slice(-3) === "pps" ? (
                                        <PpsIconSVG />
                                    ) : file.fileName.slice(-4) === "docx" ? (
                                        <DocIconSVG />
                                    ) : (file.fileName.slice(-3) === "xls") | (file.fileName.slice(-4) === "xlsx") | (file.fileName.slice(-4) === "xlsm") | (file.fileName.slice(-4) === "xlsb") ? (
                                        <XlsIconSVG />
                                    ) : (
                                        <img
                                            src={file.fileUrl}
                                            height="40px"
                                            width="40px"
                                            alt="ImagePreview..."
                                        />
                                    )}
                                </Box>
                                <Box>
                                    <Box sx={theme.FileName}>{file.fileName}</Box>
                                    <Box sx={theme.FileSizeText}>{file.fileSize + "MB"}</Box>
                                </Box>
                            </Box>

                            <Box sx={theme.ToMuchFilesWrapper}>
                                <Box sx={theme.ToMuchFilesPos}>{index > 9 ? <RedExclamationIcon /> : null}</Box>
                                <IconButton
                                    sx={theme.IconButton}
                                    size="small"
                                    onClick={handleDelete(file)}
                                >
                                    <ClearIcon
                                        fontSize="small"
                                        sx={theme.ClearIcon}
                                    />
                                </IconButton>
                            </Box>
                        </Box>
                    );
                })}
            </Paper>
            <Box>
                {attachedFiles.length > 10 ? (
                    <Box>
                        <Box sx={theme.AttachedFiles}>
                            {attachedFiles.length -
                                10 +
                                " " +
                                (attachedFiles.length - 10 === 1 ? " файл не прикрепился" : attachedFiles.length - 10 < 5 ? " файла не прикрепилось" : " файлов не прикрепилось")}
                        </Box>
                        <Box sx={theme.ToMuchFilesText}>К заказу можно прикрепить до 10 файлов. Удалите часть прикреплённых файлов, чтобы добавить новые</Box>
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
}
