import React, { memo, useEffect, useState } from "react";
import { Button, IconButton, Tooltip } from "@mui/material";
import { observer } from "mobx-react-lite";
import LeftArrowDisabled from "assets/CustomCalendarAssets/leftArrowDisabled";
import LeftArrowEnabled from "assets/CustomCalendarAssets/leftArrowEnabled";
import RightArrow from "assets/CustomCalendarAssets/rightArrow";
import TypeLineActive from "assets/CustomCalendarAssets/typeLineActive";
import TypeLineInactive from "assets/CustomCalendarAssets/typeLineInactive";
import TypeTableInactive from "assets/CustomCalendarAssets/typeTableInactive";
import TypeTableActive from "assets/CustomCalendarAssets/typeTableActive";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as themes from "./CustomCalendarThemes";
import * as textStyles from "themes/TextThemes";
import { useMedias } from "API/mediasHook";
import { useTranslation } from "react-i18next";

const CustomCalendar = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const medias = useMedias();

    const todayDateTime = new Date();

    const month = (new Date().getMonth() + 1).toString();

    const year = new Date().getFullYear().toString();

    const day = new Date().getDate().toString();

    const today = new Date(year + "-" + month + "-" + day);

    let monthPropsTime = null;

    let yearPropsTime = null;

    let dayPropsTime = null;

    let datePropsTime = null;

    if (props.currentClassTime !== undefined) {
        monthPropsTime = (props.currentClassTime.getMonth() + 1).toString();
        yearPropsTime = props.currentClassTime.getFullYear().toString();
        dayPropsTime = props.currentClassTime.getDate().toString();
        datePropsTime = new Date(yearPropsTime + "-" + monthPropsTime + "-" + dayPropsTime);
    }

    const [calendarType, setCalendarType] = useState("line");

    const [selectedWeek, setSelectedWeek] = useState(datePropsTime !== null ? datePropsTime : today);

    const daysOfWeek = ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"];

    const daysOfWeekShort = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];

    const mappingDates = [6, 0, 1, 2, 3, 4, 5];

    function getLastFilledDay(weekTimeSlots) {
        const days = Object.keys(weekTimeSlots);

        for (let i = days.length - 1; i >= 0; i--) {
            const day = days[i];

            if (weekTimeSlots[day].length > 0) {
                return i;
            }
        }

        return null; // Возвращаем null, если все дни пусты
    }

    const lastFilledDay = getLastFilledDay(props.weekTimeSlots);

    const lastDaySlot = props.weekTimeSlots[Object.keys(props.weekTimeSlots)[lastFilledDay]].map(Number).slice(-1)[0];

    useEffect(() => {
        if (mappingDates[selectedWeek.getDay()] > lastFilledDay || (mappingDates[selectedWeek.getDay()] === lastFilledDay && lastDaySlot < todayDateTime.getHours())) {
            const selectedw = new Date(selectedWeek);

            selectedw.setDate(selectedw.getDate() + 7);
            setSelectedWeek(selectedw);
        }
    }, []);

    const weekDates = daysOfWeek.map((day, index) => {
        const date = new Date(selectedWeek);

        if (selectedWeek.getDay() !== 0) {
            date.setDate(selectedWeek.getDate() - (selectedWeek.getDay() - 1) + index);
        } else {
            date.setDate(selectedWeek.getDate() - 6 + index);
        }

        return date;
    });

    const startOfWeek = weekDates[0];

    const endOfWeek = weekDates[6];

    const getEndOfPreviousWeek = (date) => {
        const givenDate = new Date(date);

        const dayOfWeek = givenDate.getDay(); // 0 - Sunday, 1 - Monday, ..., 6 - Saturday

        const diff = dayOfWeek === 0 ? 7 : dayOfWeek; // Calculate days to subtract to get to last Sunday

        const endOfPreviousWeek = new Date(givenDate);

        endOfPreviousWeek.setDate(givenDate.getDate() - diff);
        return endOfPreviousWeek;
    };

    const getHoursInDay = () => {
        const hours = [];

        for (let i = 0; i < 24; i++) {
            hours.push(i.toString().padStart(2, "0") + ":00");
        }

        return hours;
    };

    const formatMonthLine = (date) => {
        return date.toLocaleString("default", { day: "numeric", month: "long" });
    };

    const formatMonthTable = (date) => {
        return date.toLocaleString("default", { day: "numeric" });
    };

    const handlePreviousWeek = () => {
        const previousWeek = new Date(selectedWeek);

        previousWeek.setDate(selectedWeek.getDate() - 7);
        setSelectedWeek(previousWeek);
    };

    const handleNextWeek = () => {
        const nextWeek = new Date(selectedWeek);

        nextWeek.setDate(selectedWeek.getDate() + 7);
        setSelectedWeek(nextWeek);
    };

    const lineButtonClick = () => {
        setCalendarType("line");
    };

    const tableButtonClick = () => {
        setCalendarType("table");
    };

    const getDateToCompare = (date, hour) => {
        const event = new Date(date);

        event.setHours(Number(hour.slice(0, 2)), 0, 0);

        return event;
    };

    const findMaxNumber = (array) => {
        const numbers = array.map(Number);

        const maxNumber = Math.max(...numbers);

        return maxNumber;
    };

    return (
        <Box sx={themes.containerStyle}>
            <Box sx={themes.weekNavigation}>
                <Box sx={themes.weekNavigationContent(medias)}>
                    <Box sx={themes.weekNavigationRight(medias)}>
                        <Box sx={themes.weekChangeButtons}>
                            <IconButton
                                disableRipple
                                onClick={handlePreviousWeek}
                                disabled={getEndOfPreviousWeek(selectedWeek) < today}
                                sx={themes.weekButton}
                            >
                                {getEndOfPreviousWeek(selectedWeek) < today ? <LeftArrowDisabled /> : <LeftArrowEnabled />}
                            </IconButton>
                            <IconButton
                                disableRipple
                                onClick={handleNextWeek}
                                sx={themes.weekButton}
                            >
                                {<RightArrow />}
                            </IconButton>
                        </Box>
                        <Typography
                            sx={medias.sm ? textStyles.HeadlineH5Black : textStyles.Body16w600}
                        >{`${formatMonthLine(startOfWeek)} - ${formatMonthLine(endOfWeek)}, ${endOfWeek.getFullYear()}`}</Typography>
                    </Box>
                    {medias.sm && (
                        <Box sx={themes.calendarTypeSwitchRowStyle}>
                            <IconButton
                                disableRipple
                                onClick={lineButtonClick}
                                sx={themes.calendarSwitchTypeButtonStyle}
                            >
                                {calendarType === "line" ? <TypeLineActive /> : <TypeLineInactive />}
                            </IconButton>
                            <IconButton
                                disableRipple
                                disabled={props.disableTable}
                                onClick={tableButtonClick}
                                sx={themes.calendarSwitchTypeButtonStyle}
                            >
                                {calendarType === "line" ? <TypeTableInactive /> : <TypeTableActive />}
                            </IconButton>
                        </Box>
                    )}
                </Box>
            </Box>
            <Box sx={themes.Calendar(props.maxContentHeight, medias)}>
                {calendarType === "line" ? (
                    <Box sx={themes.weekStyleLine}>
                        <Box sx={themes.weekStyleLineContent(medias)}>
                            {weekDates.map((date, index) =>
                                (date > todayDateTime ||
                                    (date.toLocaleString().slice(0, 10) === todayDateTime.toLocaleString().slice(0, 10) &&
                                        todayDateTime.getHours() < findMaxNumber(props.weekTimeSlots[daysOfWeekShort[index]]))) &&
                                props.weekTimeSlots[daysOfWeekShort[index]].length !== 0 ? (
                                    <Box
                                        key={index}
                                        sx={themes.dayStyle}
                                    >
                                        <Box sx={themes.dayTitleContainerStyle}>
                                            <Typography style={themes.dayTitle}>{daysOfWeek[index]},</Typography>
                                            <Typography style={themes.dayNMonth}>{formatMonthLine(date)}</Typography>
                                        </Box>
                                        <Box sx={themes.timeContainerStyle}>
                                            {getHoursInDay().map((hour, hourIndex) =>
                                                (date > todayDateTime || (Number(hour.slice(0, 2)) > new Date().getHours() && !(date < today) && !(date > today))) &&
                                                props.weekTimeSlots[daysOfWeekShort[index]].includes(Number(hour.slice(0, 2))) ? (
                                                    <Tooltip
                                                        key={hourIndex}
                                                        title={
                                                            props.currentClassTime
                                                                ? props.currentClassTime.toLocaleString() === new Date(date.setHours(hour.split(":")[0], hour.split(":")[1])).toLocaleString()
                                                                    ? "Текущее время занятия"
                                                                    : ""
                                                                : ""
                                                        }
                                                        arrow
                                                        placement="top"
                                                        PopperProps={themes.TooltipPopperProps}
                                                    >
                                                        <Button
                                                            disableRipple
                                                            key={hourIndex}
                                                            onClick={() => {
                                                                if (props.currentClassTime !== undefined) {
                                                                    if (props.currentClassTime.toLocaleString() !== new Date(date.setHours(hour.split(":")[0], hour.split(":")[1])).toLocaleString()) {
                                                                        props.setDate(new Date(date.setHours(hour.split(":")[0], hour.split(":")[1])));
                                                                    }
                                                                } else props.setDate(new Date(date.setHours(hour.split(":")[0], hour.split(":")[1])));
                                                            }}
                                                            sx={themes.HourButton(
                                                                props.currentClassTime
                                                                    ? props.currentClassTime.toLocaleString() === new Date(date.setHours(hour.split(":")[0], hour.split(":")[1])).toLocaleString()
                                                                    : false,
                                                                props.date.toLocaleString() === new Date(date.setHours(hour.split(":")[0], hour.split(":")[1])).toLocaleString(),
                                                                medias,
                                                            )}
                                                        >
                                                            {hour}
                                                        </Button>
                                                    </Tooltip>
                                                ) : null,
                                            )}
                                        </Box>
                                    </Box>
                                ) : null,
                            )}
                        </Box>
                    </Box>
                ) : (
                    <Box sx={themes.weekStyleTableOuter}>
                        <Box sx={themes.weekStyleTable(props.type)}>
                            {weekDates.map((date, index) => (
                                <Box
                                    key={index}
                                    sx={themes.dayTitle}
                                >
                                    <Box sx={themes.dayTableTitleContainerStyle(date, today, props.type)}>
                                        <Typography sx={themes.dayTableTitleStyle(date, today)}>{formatMonthTable(date)}</Typography>
                                        <Typography sx={themes.dayTableNMonthStyle(date, today)}>{daysOfWeekShort[index]}</Typography>
                                    </Box>
                                    <Box sx={themes.timeTableContainerStyle}>
                                        {getHoursInDay().map((hour, hourIndex) =>
                                            props.weekTimeSlots[daysOfWeekShort[index]].includes(Number(hour.slice(0, 2))) ? (
                                                <Tooltip
                                                    key={hourIndex}
                                                    title={
                                                        props.currentClassTime
                                                            ? props.currentClassTime.toLocaleString() === new Date(date.setHours(hour.split(":")[0], hour.split(":")[1])).toLocaleString()
                                                                ? "Текущее время занятия"
                                                                : ""
                                                            : ""
                                                    }
                                                    arrow
                                                    placement="top"
                                                    PopperProps={themes.TooltipPopperProps}
                                                >
                                                    <Button
                                                        disableRipple
                                                        key={hourIndex}
                                                        onClick={() => {
                                                            if (props.currentClassTime !== undefined) {
                                                                if (props.currentClassTime.toLocaleString() !== new Date(date.setHours(hour.split(":")[0], hour.split(":")[1])).toLocaleString()) {
                                                                    props.setDate(new Date(date.setHours(hour.split(":")[0], hour.split(":")[1])));
                                                                }
                                                            } else props.setDate(new Date(date.setHours(hour.split(":")[0], hour.split(":")[1])));
                                                        }}
                                                        sx={themes.HourButton(
                                                            props.currentClassTime
                                                                ? props.currentClassTime.toLocaleString() === new Date(date.setHours(hour.split(":")[0], hour.split(":")[1])).toLocaleString()
                                                                : false,
                                                            props.date.toLocaleString() === new Date(date.setHours(hour.split(":")[0], hour.split(":")[1])).toLocaleString(),
                                                            medias,
                                                        )}
                                                        disabled={getDateToCompare(date, hour) < todayDateTime}
                                                    >
                                                        {hour}
                                                    </Button>
                                                </Tooltip>
                                            ) : null,
                                        )}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
});

export default memo(CustomCalendar);
