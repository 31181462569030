import { styleProcessing } from "API/plugins/Utilities";

export const Name = {
    fontSize: "16px",
    fontWeight: "600",
    marginBottom: "5px",
};

export const ReviewContainer = (theme) => {
    let commonStyle = {
        display: "flex",
        alignItems: "flex-start",
        gap: "15px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "10px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Avatar = (theme) => {
    let commonStyle = {
        width: "50px",
        height: "50px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "36px",
            height: "36px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Rating = {
    gap: "1px",
};

export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
};

export const BottomBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
};

export const ReviewContent = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const TutorAvatar = {
    width: "40px",
    height: "40px",
};

export const AnswerText = {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
};

export const AnswerBlock = {
    display: "flex",
    alignItems: "flex-start",
    gap: "10px",
};

export const ReviewTextAndAnswer = {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
};

export const NewFeedbackBlock = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
};
