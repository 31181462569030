import { styleProcessing } from "API/plugins/Utilities";

export const PlatinumIcon = (theme) => {
    let commonStyle = {
        position: "absolute",
        left: "220px",
        bottom: "40px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            bottom: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const LevelRow = {
    position: "relative",
};

export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const WhiteContainer = {
    padding: "20px 20px 0px 20px",
    width: "249px",
    height: "119px",
    borderRadius: "20px",
    backgroundColor: "white",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.05)",
    cursor: "pointer",
    "&:hover": {
        transform: "scale(1.02)",
        transition: "all 0.2s ease-out",
    },
    display: "flex",
    flexDirection: "column",
    gap: "26px",
};
