export const AccordionSummary = {
    border: "none",
    margin: 0,
    padding: "9px 16px 10px 16px",
    "& .MuiAccordionSummary-content": {
        margin: 0,
    },
    minHeight: 0,
    "&.Mui-expanded": {
        minHeight: 0,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
        margin: 0,
    },
};

export const Accordion = {
    borderRadius: "10px",
    border: "1px dashed #DADADF",
    boxShadow: "none",
    margin: 0,
    padding: 0,
    "&::before": { display: "none" },
    "&.Mui-expanded": { margin: 0 },
};

export const ButtonApply = {
    width: "100%",
    textTransform: "none",
    height: "fit-content",
    ":hover": { bgcolor: "#8141FF", boxShadow: "none" },
    ":active": { bgcolor: "#5513D7", boxShadow: "none" },
    ":disabled": { bgcolor: "#BBBBC8", boxShadow: "none" },
    boxShadow: "none",
    borderRadius: "42px",
    display: "flex",
    padding: "7px 16px 8px 16px",
    gap: "10px",
    alignItems: "center",
    backgroundColor: "#6212FF",
};

export const AccordionDetails = {
    margin: 0,
    padding: "2px 16px 16px 16px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
};

export const TextField = {
    "& .MuiInput-underline:before": {
        borderBottom: "1px solid #BBBBC8",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#6212FF",
    },
    "& .MuiInputBase-input": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "16px",
        lineHeight: "150%",
        fontWeight: 400,
        textTransform: "none",
    },
};

export const PromocodeEnterAttempts = {
    display: "flex",
    alignItems: "center",
    gap: "9.67px",
};

export const TextFieldAndAttempts = {
    display: "flex",
    flexDirection: "column",
    gap: "6.16px",
};

export const ContentProps = {
    sx: {
        background: "#4E4E4E",
        borderRadius: "10px",
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
    },
};
