export const PopperMenuPaperSx = (medias) => {
    return {
        width: medias.sm ? "440px" : "100%",
        borderRadius: "20px",
        border: "none",
        boxShadow: "none",
        p: 0,
        mt: "16px",
    };
};

export const navbarPosition = {};

export const Badge = {
    "& .MuiBadge-badge": {
        backgroundColor: "#6212FF",
        color: "#fff",
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "130%",
        padding: "2px 6px",
    },
};

export const Button = (hovered) => {
    return {
        backgroundColor: hovered ? "rgba(187, 187, 200, 0.17)" : "transparent",
        height: "40px",
        width: "40px",
    };
};
