import React from "react";
import StudentOrdersStore from "store/StudentOrdersStore";
import ActiveOrderCard from "components/OrdersBodyBlock/StudentOrders/OrderCards/ActiveCard/ActiveOrderCard";
import InProgressOrderCard from "components/OrdersBodyBlock/StudentOrders/OrderCards/InProgressCard/InProgressOrderCard";
import CompletedOrderCard from "components/OrdersBodyBlock/StudentOrders/OrderCards/CompletedCard/CompletedOrderCard";
import CanceledOrderCard from "components/OrdersBodyBlock/StudentOrders/OrderCards/CancelledCard/СancelledOrderCard";
import TeacherOrdersStore from "store/TeacherOrdersStore";
import { observer } from "mobx-react-lite";
import { memo } from "react";

const StudentOrders = observer(({ children, ...props }) => {
    return (
        <>
            {TeacherOrdersStore.activeTab === 0 &&
                StudentOrdersStore.orders.filter((item) => item.baseOrderInfo.status === "active").length > 0 &&
                StudentOrdersStore.orders
                    .filter((item) => item.baseOrderInfo.status === "active")
                    .map((order) => (
                        <ActiveOrderCard
                            key={order.baseOrderInfo.orderUuid}
                            order={order}
                        />
                    ))}
            {TeacherOrdersStore.activeTab === 1 &&
                StudentOrdersStore.orders.filter((item) => item.baseOrderInfo.status === "waiting_for_finish").length > 0 &&
                StudentOrdersStore.orders
                    .filter((item) => item.baseOrderInfo.status === "waiting_for_finish")
                    .map((order) => (
                        <InProgressOrderCard
                            key={order.baseOrderInfo.orderUuid}
                            order={order}
                        />
                    ))}
            {TeacherOrdersStore.activeTab === 2 &&
                StudentOrdersStore.orders.filter((item) => item.baseOrderInfo.status === "finished").length > 0 &&
                StudentOrdersStore.orders
                    .filter((item) => item.baseOrderInfo.status === "finished")
                    .map((order) => (
                        <CompletedOrderCard
                            key={order.baseOrderInfo.orderUuid}
                            order={order}
                        />
                    ))}
            {TeacherOrdersStore.activeTab === 3 &&
                StudentOrdersStore.orders.filter((item) => item.baseOrderInfo.status === "cancelled").length > 0 &&
                StudentOrdersStore.orders
                    .filter((item) => item.baseOrderInfo.status === "cancelled")
                    .map((order) => (
                        <CanceledOrderCard
                            key={order.baseOrderInfo.orderUuid}
                            order={order}
                        />
                    ))}
        </>
    );
});

export default memo(StudentOrders);
