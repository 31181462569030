import React from "react";
import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "./i18n";
import UserStore from "./store/UserStore.js";
import { axiosSetup } from "./API/plugins/AxiosSettings";
import "./API/plugins/globalErrorsHandler";
import "./API/plugins/errorHandlers";

axiosSetup();

export const Context = React.createContext({
    UserStore,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Suspense /*fallback="language is loading..."*/>
        <Context.Provider value={{ UserStore }}>
            <App />
        </Context.Provider>
    </Suspense>,
);
