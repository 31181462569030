//Когда удобно заниматься?
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import SCheckboxAndToggleGroup from "components/UI/CheckAndToggleGroup";
import SBButtonGroup from "components/UI/ButtonGroup";
import store from "store/RequestStore";
import RequestStore from "store/RequestStore";

import StepHiddenButtonWrapper from "components/OrdersBodyBlock/RenewOrder/StepHiddenButtonWrapper";
import RequestNullAlert from "./RequestNullAlert";
import { Box } from "@mui/material";

const Step9 = () => {
    const [alert, setAlert] = useState(0);

    const [stepData, setStepData] = React.useState(RequestStore.storage[store.activeStepIndex].substeps[store.subStepIndex].value);

    let classesTimeText = "";

    let weekDays = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];

    const handleChooseDate = () => {
        for (const [key, value] of Object.entries(stepData)) {
            if (value.selected === true) {
                classesTimeText += " " + value.title;
                for (let i = 0; i < value.list.length; i++) {
                    if (value.list[i] === true) {
                        classesTimeText += " " + weekDays[i] + ",";
                    }
                }
            }
        }

        if (classesTimeText) {
            store.setStep(4, classesTimeText.slice(0, -1));
            store.setStepValue(4, stepData);
        }
    };

    const handleIncrement = () => {
        if (classesTimeText) {
            store.incrementStepIndex();
        } else {
            setAlert(1);
        }
    };

    const handleNext = () => {
        handleChooseDate();
        handleIncrement();
    };

    return (
        <Box>
            <SCheckboxAndToggleGroup
                varstate={stepData}
                funcstate={setStepData}
            />
            <StepHiddenButtonWrapper submitRenew={handleChooseDate}>
                <SBButtonGroup
                    handleNext={handleNext}
                    handleBack={() => store.decrementStepIndex()}
                    activeStep={store.activeStepIndex}
                    steps={store.storage}
                />
                {alert === 1 ? <RequestNullAlert>Чтобы перейти к следующему вопросу, выберите хотя бы один из вариантов</RequestNullAlert> : null}
            </StepHiddenButtonWrapper>
        </Box>
    );
};

export default observer(Step9);
