import mainLang from "./en.json";
import uiLang from "./UI/en.json";
import errorLang from "./Error/en.json";
import catalogLang from "./Catalog/en.json";
import teacherAccountLang from "./TeacherAccount/en.json";
import teacherSettingsLang from "./TeacherSettings/en.json";
import teacherClassesLang from "./TeacherClasses/en.json";
import paidServicesLang from "./PaidServices/en.json";
import notificationsLang from "./Notifications/en.json";
import ordersLang from "./Orders/en.json";
import promoPagesLang from "./PromoPages/en.json";
import teacherQuestionnaireLang from "./TeacherQuestionnaire/en.json";
import rulesLang from "./Rules/en.json";
import errorsLang from "./Errors/en.json";

let messagesPaths = [
    errorsLang,
    rulesLang,
    teacherQuestionnaireLang,
    promoPagesLang,
    mainLang,
    uiLang,
    errorLang,
    catalogLang,
    teacherAccountLang,
    teacherSettingsLang,
    ordersLang,
    teacherClassesLang,
    notificationsLang,
    paidServicesLang,
];

function getAllMessages() {
    let messages = {};

    messagesPaths.forEach((data) => {
        messages = Object.assign(messages, data);
    });
    return messages;
}

export default getAllMessages();
