import * as themes from "./RightPanelThemes";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import * as textThemes from "themes/TextThemes";
import CitySelect from "./CitySelect/CitySelect";
import UserStore from "store/UserStore";
import Notifications from "components/NotificationsBodyBlock/NotificationsPopper/NotificationsPopper";
import UnderlineIcon from "assets/CommonAssets/SVG/UnderlineIcon";
import * as React from "react";
import { useMedias } from "API/mediasHook";
import { memo } from "react";
import AuthorizationStore from "store/AuthorizationStore";
import { useNavigate } from "react-router-dom";
import LanguageSelect from "./LanguageSelect/LanguageSelect";
import BalanceButton from "./BalanceButton/BalanceButton";
import ChatsButton from "./ChatsButton/ChatsButton";
import FavouritesButton from "./FavouritesButton/FavouritesButton";
import ProfileButton from "./ProfileButton/ProfileButton";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

const RightPanel = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const handleClickLogin = () => {
        AuthorizationStore.setPreviousLocationPath(location.pathname);
        AuthorizationStore.setCurrentAuthorizationStep("SignIn");
        AuthorizationStore.setPreSMSPage(null);
        AuthorizationStore.setTemporaryEmail("");
        AuthorizationStore.setTemporaryPassword("");
        AuthorizationStore.setTemporaryPhoneNumber("");
        navigate("/authorization");
    };

    return (
        <Box sx={themes.RightPanel}>
            <CitySelect />
            <LanguageSelect />
            {UserStore.isAuth ? (
                <Box sx={themes.ButtonsBlock}>
                    <Box sx={themes.DividerAndBalance}>
                        <Box sx={themes.RightPanelDivider(medias)} />
                        <BalanceButton />
                    </Box>
                    <Box sx={themes.ChatsAndFavourites}>
                        <ChatsButton />
                        <FavouritesButton />
                        <Notifications />
                    </Box>
                    <ProfileButton />
                </Box>
            ) : (
                <Button
                    onClick={handleClickLogin}
                    sx={themes.LoginButton}
                    disableRipple
                >
                    <Typography sx={textThemes.Body2Black}>{t("main.header.signIn")}</Typography>
                    <UnderlineIcon />
                </Button>
            )}
        </Box>
    );
});

export default memo(RightPanel);
