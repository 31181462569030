import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import StateOne from "./StateOne/StateOne";
import StateTwo from "./StateTwo/StateTwo";
import StateThree from "./StateThree/StateThree";
import MainPageStore from "store/MainPageStore";
import Box from "@mui/material/Box";
import { useMedias } from "../../../../API/mediasHook";

const RightCardBlock = observer(({ children, ...props }) => {
    const medias = useMedias();

    return <Box style={{ display: medias.sm ? "unset" : "none" }}>{MainPageStore.activeHowWorkItem === 0 ? <StateOne /> : MainPageStore.activeHowWorkItem === 1 ? <StateTwo /> : <StateThree />}</Box>;
});

export default memo(RightCardBlock);
