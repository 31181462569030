import { Button, styled } from "@mui/material";

export const StyledOrderResponseButton = styled(Button)({
    display: "flex",
    alignItems: "center",
    width: "100%",
    fontFamily: '"Inter", sans-serif',
    borderRadius: "42px",
    textTransform: "none",
    // padding: "10px 20px",
});

export const defaultText = {
    fontSize: "14px",
    lineHeight: "21px",
};

export const bigText = {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
};

export const contained = {
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "#6212FF",
    "&:hover": {
        backgroundColor: "#6212FF",
        color: "rgba(255, 255, 255, 1)",
    },
};

export const outlined = {
    border: "1px solid #6212FF",
    color: "#6212FF",
    backgroundColor: "rgba(247, 243, 255, 1)",
    "&:hover": {
        border: "1px solid #6212FF",
        backgroundColor: "rgba(247, 243, 255, 1)",
        color: "#6212FF",
    },
};

export const containedHeight = {
    height: "47px",
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "#6212FF",
    "&:hover": {
        backgroundColor: "#6212FF",
        color: "rgba(255, 255, 255, 1)",
    },
};

export const grayFilled = {
    color: "rgba(34, 34, 34, 1)",
    backgroundColor: "rgba(238, 238, 240, 1)",
    "&:hover": {
        color: "rgba(34, 34, 34, 1)",
        backgroundColor: "rgba(238, 238, 240, 1)",
    },
};

export const awaitingGrayDisabled = {
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "rgba(187, 187, 200, 1)",
    "&:hover": {
        color: "rgba(255, 255, 255, 1)",
        backgroundColor: "rgba(187, 187, 200, 1)",
    },
    "&:disabled": {
        color: "rgba(255, 255, 255, 1)",
        backgroundColor: "rgba(187, 187, 200, 1)",
    },
};
