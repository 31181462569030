import React, { memo } from "react";
import Box from "@mui/material/Box";
import * as themes from "./AnswerCardThemesDefault";
import AvatarArea from "./AvatarArea/AvatarArea";
import InfoArea from "./InfoArea/InfoArea";
import { useMedias } from "../../../API/mediasHook";

const AnswerCard = ({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={themes.answerCardContainerStyle(medias)}>
            <AvatarArea value={props.tutor}></AvatarArea>
            <InfoArea
                value={props.answer}
                tutor={props.tutor}
            ></InfoArea>
        </Box>
    );
};

export default memo(AnswerCard);
