export const singleDetailCont = {
    paddingTop: "11px",
    paddingBottom: "12px",
    width: "100%",
};

export const singleDetailComment = {
    borderRadius: "16px",
    background: "#F5F5F7",
    padding: "16px",
};

export const fileCont = {
    width: "40px",
    height: "40px",
    borderRadius: "4px",
};

export const downloadButton = {
    color: "#6212FF",
    fontFamily: "Inter",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",

    textDecoration: "none",

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
};
