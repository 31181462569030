import React, { memo, useState } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./SortByBlockThemes";
import { IconButton, Menu, Typography } from "@mui/material";
import TeacherStore from "store/TeacherStore";
import MenuItem from "@mui/material/MenuItem";
import { ThemeProvider } from "@mui/material/styles";
import { CheckSharp, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const SortByBlock = observer(({ children, ...props }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleMenuToggle = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = async (value, event) => {
        if (value !== null) {
            TeacherStore.SetSortBy(value);
            TeacherStore.SortReviews();
            await TeacherStore.FetchMoreReviewsData("reset");
        }

        setAnchorEl(null);
    };

    return (
        <Box sx={themes.sortByContainerStyle}>
            <Box
                sx={themes.sortByOptionsSwitcherStyle}
                onClick={handleMenuToggle}
            >
                <Typography sx={themes.sortByOptionsTextStyle}>{TeacherStore.sortByOption.sortBy}</Typography>
                <ThemeProvider theme={themes.IconOptionsTheme}>
                    <IconButton
                        disableRipple
                        onClick={handleMenuToggle}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </ThemeProvider>
            </Box>

            <ThemeProvider theme={themes.MenuTheme}>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose.bind(this, null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    {TeacherStore.sortByOptions.map((value, index) => (
                        <MenuItem
                            key={value.ID}
                            onClick={handleMenuClose.bind(this, value)}
                            disableRipple
                            selected={TeacherStore.sortByOption.ID === value.ID}
                        >
                            <Typography sx={themes.sortByTextStyle}>{value.sortBy}</Typography>
                            <Box sx={themes.IconContainerStyle}>
                                <ThemeProvider theme={themes.IconCheckTheme}>{TeacherStore.sortByOption.ID === value.ID ? <CheckSharp /> : null}</ThemeProvider>
                            </Box>
                        </MenuItem>
                    ))}
                </Menu>
            </ThemeProvider>
        </Box>
    );
});

export default memo(SortByBlock);
