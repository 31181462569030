import React from "react";

const Info = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.0043 2C5.5898 2 2 5.5898 2 10C2 14.4102 5.5898 18 10 18C14.4102 18 18 14.4102 18 10C18 5.5898 14.4145 2 10.0043 2ZM10.0043 15.847C9.44004 15.847 8.97992 15.3869 8.97992 14.8226C8.97992 14.2583 9.44004 13.7982 10.0043 13.7982C10.5686 13.7982 11.0288 14.2583 11.0288 14.8226C11.0288 15.3869 10.5686 15.847 10.0043 15.847ZM11.4715 5.86761L10.5426 12.127C10.4992 12.4265 10.2691 12.6435 10 12.6435C9.73087 12.6435 9.50081 12.4265 9.45741 12.127L8.52849 5.86761C8.45903 5.39881 8.58926 4.91698 8.88009 4.5784C9.11449 4.30494 9.43136 4.15301 9.7656 4.15301H10.2301C10.5643 4.15301 10.8812 4.30494 11.1156 4.5784C11.4107 4.91698 11.541 5.39881 11.4715 5.86761Z"
                fill="#19B30C"
            />
        </svg>
    );
};

export default Info;
