import React from "react";
import { Tooltip } from "@mui/material";
import * as theme from "./STooltipThemes";
const SDragAndDrop = ({ children, placement = "top", title, offsetX = 0, offsetY = 0 }) => {
    return (
        <Tooltip
            PopperProps={{
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [offsetX, offsetY],
                        },
                    },
                ],
                sx: theme.PopperProps,
            }}
            componentsProps={{
                arrow: {
                    sx: theme.componentsProps,
                },
            }}
            title={title}
            placement={placement}
            arrow
        >
            <button style={theme.button}>{children}</button>
        </Tooltip>
    );
};

export default SDragAndDrop;
