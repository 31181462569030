import React, { memo } from "react";
import * as themes from "./RescheduleRequestCardThemes";
import * as textThemes from "themes/TextThemes";
import Box from "@mui/material/Box";
import { Avatar, Typography } from "@mui/material";
import RegularStudentIcon from "assets/TeacherClassesAssets/SVG/RegularStudentIcon";
import NewStudentIcon from "assets/TeacherClassesAssets/SVG/NewStudentIcon";
import { useTranslation } from "react-i18next";
import TutorClassesStore from "store/TutorClassesStore";
import { observer } from "mobx-react-lite";
import SButton from "components/UI/SButton";
import EmptyAvatar from "assets/TeacherClassesAssets/PNG/EmptyAvatar.png";
import MyClassesStore from "store/MyClassesStore";
import { useMedias } from "API/mediasHook";

const RescheduleRequestCard = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const getFormatedDate = (lesson, attribute) => {
        const dateObject = TutorClassesStore.formatTodayClassDate(lesson, attribute);

        return `${dateObject.date} ${t(dateObject.month)}, ${t(dateObject.weekday)}`;
    };

    const handleClickAccept = () => {
        TutorClassesStore.acceptRescheduleTime(TutorClassesStore.classes.findIndex((object) => object.id === props.classItem.id));
        TutorClassesStore.ClassesUpdate();
    };

    const handleClickReject = () => {
        TutorClassesStore.rejectRescheduleTime(TutorClassesStore.classes.findIndex((object) => object.id === props.classItem.id));
        TutorClassesStore.ClassesUpdate();
    };

    return (
        <Box sx={themes.RescheduleRequestCard(medias)}>
            <Box sx={themes.LeftPanel}>
                <Typography
                    sx={textThemes.Button16BoldBlack}
                >{`${props.classItem.subject}, ${getFormatedDate(props.classItem, "timeStart")}, ${MyClassesStore.formatTodayClassTime(props.classItem, "default").slice(0, -6)}`}</Typography>
                <Box sx={themes.StudentAndReasonBlock}>
                    <Box sx={themes.StudentBlock}>
                        <Avatar
                            src={props.classItem.studentAvatar ? props.classItem.studentAvatar : EmptyAvatar}
                            sx={themes.Avatar(medias)}
                        />
                        {props.classItem.studentType === "constant" ? (
                            <Box sx={themes.StudentTextBox}>
                                <Box sx={themes.StudentNameBox}>
                                    <Typography sx={textThemes.Body2Black}>{props.classItem.studentName}</Typography>
                                    <Box sx={themes.IconBox}>
                                        <RegularStudentIcon />
                                    </Box>
                                </Box>
                                {medias.sm && <Typography sx={textThemes.Caption2DarkGrey}>{t("teacherClasses.constantStudent")}</Typography>}
                            </Box>
                        ) : props.classItem.studentType === "new" ? (
                            <Box sx={themes.StudentTextBox}>
                                <Box sx={themes.StudentNameBox}>
                                    <Typography sx={textThemes.Body2Black}>{props.classItem.studentName}</Typography>
                                    <Box sx={themes.IconBox}>
                                        <NewStudentIcon />
                                    </Box>
                                </Box>
                                <Typography sx={textThemes.Caption2DarkGrey}>{t("teacherClasses.newStudent")}</Typography>
                            </Box>
                        ) : (
                            <Typography sx={textThemes.Body2Black}>{props.classItem.studentName}</Typography>
                        )}
                    </Box>
                    <Typography sx={textThemes.Body2Black}>{props.classItem.rescheduleReason}</Typography>
                </Box>
            </Box>
            <Box sx={themes.RightPanel(medias)}>
                <Box sx={themes.RescheduleDetails(medias)}>
                    {medias.sm && (
                        <>
                            <Box sx={themes.TimeContainer(medias)}>
                                <Typography sx={textThemes.Body2Black}>{t("teacherClasses.currentTimeFull")}</Typography>
                                <Box sx={themes.TimeBlock}>
                                    <Typography
                                        sx={textThemes.Button14Grey}
                                    >{`${getFormatedDate(props.classItem, "timeStart")} ${MyClassesStore.formatTodayClassTime(props.classItem, "default")}`}</Typography>
                                </Box>
                            </Box>
                            <Box sx={themes.TimeContainer(medias)}>
                                <Typography sx={textThemes.Body2Black}>{t("teacherClasses.requestedTimeFull")}</Typography>
                                <Box sx={themes.TimeBlock}>
                                    <Typography
                                        sx={textThemes.Button14Purple}
                                    >{`${getFormatedDate(props.classItem, "rescheduleTimeStart")} ${MyClassesStore.formatTodayClassTime(props.classItem, "reschedule")}`}</Typography>
                                </Box>
                            </Box>
                        </>
                    )}
                    {!medias.sm && (
                        <>
                            <Typography>
                                <span style={textThemes.Body2Black}>{t("teacherClasses.currentTimeFull")}</span>{" "}
                                <span style={textThemes.Button14Grey}>{`${getFormatedDate(props.classItem, "timeStart")} ${MyClassesStore.formatTodayClassTime(props.classItem, "default")}`}</span>
                            </Typography>
                            <Typography>
                                <span style={textThemes.Body2Black}>{t("teacherClasses.requestedTimeFull")}</span>{" "}
                                <span
                                    style={textThemes.Button14Purple}
                                >{`${getFormatedDate(props.classItem, "rescheduleTimeStart")} ${MyClassesStore.formatTodayClassTime(props.classItem, "reschedule")}`}</span>
                            </Typography>
                        </>
                    )}
                </Box>
                <Box sx={themes.ButtonsBlock}>
                    <SButton
                        variant="secondary"
                        padding="7px 16px 8px 16px"
                        style={themes.Button}
                        onClick={handleClickReject}
                    >
                        {t("main.reject")}
                    </SButton>
                    <SButton
                        variant="unshadowed"
                        padding="7px 16px 8px 16px"
                        style={themes.Button}
                        onClick={handleClickAccept}
                    >
                        {t("main.accept")}
                    </SButton>
                </Box>
            </Box>
        </Box>
    );
});

export default memo(RescheduleRequestCard);
