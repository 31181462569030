export const OrdersInWorkThemes = {
    maxWidth: "558px",
    background: "#EEEEF0",
    borderRadius: "20px",
    padding: "20px",
};

export const InWorkCards = {
    marginTop: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};
