const Clock = () => {
    return (
        <svg
            width="18px"
            height="18px"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.0002 6.04199C10.3868 6.04199 10.7002 6.35539 10.7002 6.74199V9.68621C10.7002 9.73453 10.7393 9.77371 10.7877 9.77371H13.266C13.6525 9.77371 13.9657 10.087 13.9657 10.4734C13.9657 10.8598 13.6525 11.1731 13.266 11.1731H9.73767C9.49605 11.1731 9.30017 10.9772 9.30017 10.7356V6.74199C9.30017 6.35539 9.61357 6.04199 10.0002 6.04199Z"
                fill="#9E9EB0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 10C3 6.1402 6.1402 3 10 3C13.8598 3 17 6.1402 17 10C17 13.8598 13.8598 17 10 17C6.1402 17 3 13.8598 3 10ZM4.4 10C4.4 13.0877 6.9123 15.6 10 15.6C13.0877 15.6 15.6 13.0877 15.6 10C15.6 6.9123 13.0877 4.4 10 4.4C6.9123 4.4 4.4 6.9123 4.4 10Z"
                fill="#9E9EB0"
            />
        </svg>
    );
};

export default Clock;
