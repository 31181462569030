import React from "react";

const VideoOnIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon/24/video-on">
                <path
                    id="video-on"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.63966 4.88501C3.12312 5.24099 2.75049 5.95182 2.75049 7.79594V16.2159C2.75049 17.836 3.01152 18.5667 3.50457 18.9745C4.05097 19.4265 5.09255 19.6759 7.21049 19.6759H13.5305C15.0638 19.6759 15.8504 19.4108 16.2869 18.9751C16.7231 18.5398 16.9905 17.7535 16.9905 16.2159V15.1959C16.9897 15.182 16.9893 15.1681 16.9893 15.154V8.84404C16.9893 8.83001 16.9897 8.81604 16.9905 8.80213V7.79594C16.9905 6.25694 16.725 5.47114 16.2902 5.03627C15.8553 4.60139 15.0695 4.33594 13.5305 4.33594H7.21049C6.28861 4.33594 5.54554 4.36228 4.94098 4.45185C4.3368 4.54136 3.92753 4.68661 3.63966 4.88501ZM17.3466 20.0368C18.1585 19.2264 18.4453 18.0434 18.4854 16.5935L20.0868 17.7168L20.0878 17.7174C20.8774 18.2745 21.8092 18.5295 22.615 18.1109C23.4208 17.6923 23.7493 16.7828 23.7493 15.814V8.19404C23.7493 7.22457 23.4205 6.31659 22.6165 5.89673C21.8124 5.47679 20.8807 5.72674 20.0898 6.27919L18.4851 7.40481C18.4439 5.96234 18.159 4.78384 17.3508 3.97561C16.4707 3.09548 15.1515 2.83594 13.5305 2.83594H7.21049C6.27712 2.83594 5.44132 2.86135 4.72115 2.96804C4.00059 3.07479 3.33927 3.2703 2.78845 3.64991C1.62761 4.44995 1.25049 5.84412 1.25049 7.79594V16.2159C1.25049 17.8799 1.48921 19.2542 2.54854 20.1304C3.55452 20.9624 5.11794 21.1759 7.21049 21.1759H13.5305C15.1472 21.1759 16.4656 20.9161 17.3466 20.0368ZM20.95 16.49L18.4905 14.7648V9.23325L20.9488 7.50889C21.5176 7.11179 21.8263 7.1763 21.9221 7.22634C22.0182 7.27649 22.2493 7.4935 22.2493 8.19404V15.814C22.2493 16.5153 22.0179 16.7308 21.9236 16.7797C21.8298 16.8285 21.522 16.8938 20.9518 16.4913L20.95 16.49ZM12.4944 7.75C11.8041 7.75 11.2444 8.30964 11.2444 9C11.2444 9.69036 11.8041 10.25 12.4944 10.25H12.5034C13.1938 10.25 13.7534 9.69036 13.7534 9C13.7534 8.30964 13.1938 7.75 12.5034 7.75H12.4944Z"
                    fill="white"
                />
            </g>
        </svg>
    );
};

export default VideoOnIcon;
