import { createTheme } from "@mui/material/styles";

export const tcAvatarAreaStyle = {
    width: "160px",
    height: "170px",
    flex: "none",
};

export const anonymousAvatarContainer = {
    boxSizing: "border-box",
    width: "160px",
    height: "170px",
    border: "1px dashed #BBBBC8",
    borderRadius: "16px",
    flex: "none",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
};

export const anonymousAvatarTextStyle = {
    width: "111.61px",
    textAlign: "center",
    color: "#67677A",
};

export const tcPlayIconBGStyle = {
    position: "relative",
    width: "40px",
    height: "40px",
    left: "10px",
    bottom: "50px",
    borderRadius: "50%",
    background: "rgba(255, 255, 255, 0.5)",
    cursor: "pointer",
};

export const TCAvatarThemeClickable = createTheme({
    components: {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    position: "relative",
                    width: "160px",
                    height: "170px",
                    left: "0px",
                    top: "0px",
                    borderRadius: "16px",
                    cursor: "pointer",
                },
            },
        },
    },
});

export const TCAvatarThemeUnclickable = createTheme({
    components: {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    position: "relative",
                    width: "160px",
                    height: "170px",
                    left: "0px",
                    top: "0px",
                    borderRadius: "16px",
                    cursor: "default",
                },
            },
        },
    },
});

export const TCPlayIconTheme = createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    position: "relative",
                    left: "21%",
                    right: "40%",
                    top: "22%",
                    bottom: "30%",
                    color: "#6212FF",
                },
            },
        },
    },
});
