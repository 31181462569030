export const ChatBox = {
    width: "470px",
    border: "1px solid #F3F3F3",
    borderRadius: "10px",
};

export const ChatItem = {
    borderTop: "1px solid #EEEEF0",
    padding: "8px 16px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
};

export const Avatar = {
    height: "32px",
    width: "32px",
};

export const CreateIcon = {
    height: "32px",
    width: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

export const AddChatButton = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    padding: "7px 15px",
    cursor: "pointer",
};

export const ChatsLabel = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    borderRadius: "10px",
    background: "#F3F3F3",
    padding: "8px 12px",
};

export const ChatListContainer = {
    overflow: "auto",
    maxHeight: "31vh",
};
