export const InfoPointIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.9847 4.52465L13.9849 4.53659C14.0534 8.12491 14.1471 13.0393 8.16355 15.1133C8.05751 15.15 7.94219 15.15 7.83616 15.1133C1.85305 13.0394 1.94689 8.12512 2.01541 4.53687C2.02281 4.15122 2.02974 3.78683 2.02974 3.43437C2.02974 3.30179 2.0824 3.17465 2.17615 3.0809C2.2699 2.98715 2.39705 2.93449 2.52962 2.93449C4.66734 2.93449 6.29477 2.32009 7.6514 1.00092C7.74469 0.910159 7.86972 0.859375 7.99988 0.859375C8.13005 0.859375 8.25508 0.910159 8.34837 1.00092C9.70513 2.32009 11.3328 2.93449 13.4707 2.93449C13.6033 2.93449 13.7304 2.98715 13.8241 3.0809C13.9179 3.17465 13.9706 3.30179 13.9706 3.43437C13.9706 3.78312 13.9774 4.14349 13.9847 4.52465ZM7.30825 8.68082L9.72096 6.26824C9.81472 6.17451 9.94186 6.12186 10.0744 6.12188C10.207 6.12189 10.3341 6.17456 10.4279 6.2683C10.4743 6.31472 10.5111 6.36982 10.5362 6.43047C10.5614 6.49112 10.5743 6.55612 10.5743 6.62177C10.5743 6.68742 10.5613 6.75242 10.5362 6.81306C10.5111 6.87371 10.4742 6.9288 10.4278 6.97521L7.6617 9.74125C7.61533 9.78772 7.56023 9.82458 7.49958 9.8497C7.43892 9.87482 7.3739 9.8877 7.30825 9.88761C7.24259 9.8877 7.17757 9.87482 7.11692 9.8497C7.05626 9.82458 7.00116 9.78772 6.95479 9.74125L5.57177 8.35823C5.48063 8.26395 5.43017 8.13764 5.43127 8.00652C5.43237 7.87539 5.48495 7.74994 5.57767 7.65721C5.67039 7.56449 5.79583 7.5119 5.92695 7.51078C6.05808 7.50967 6.1844 7.56012 6.27868 7.65126L7.30825 8.68082Z"
                fill="white"
                fillOpacity="0.7"
            />
        </svg>
    );
};
