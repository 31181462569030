import { LinearProgress, ThemeProvider, Box, Typography } from "@mui/material";
import Platinum from "assets/PAAssets/SVG/platinum";
import { useNavigate } from "react-router-dom";
import * as themes from "./NextMonthLevelThemes";
import * as textThemes from "themes/TextThemes";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import { observer } from "mobx-react-lite";

const NextMonthLevel = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    return (
        <Box
            sx={themes.WhiteContainer}
            onClick={() => {
                navigate("/tutor/level");
            }}
        >
            <Box sx={themes.TopBlock}>
                <Typography sx={textThemes.Body2DarkGrey}>{`${t("teacherAccount.privilegeProgram.forecastFor")} ${t(TeacherQuestionnaireStore.monthNames[new Date().getMonth() + 1])}`}</Typography>
                <Box sx={themes.LevelRow}>
                    <Typography sx={textThemes.Button16BoldBlack}>
                        {t(TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth).title)}
                        <span style={textThemes.Caption1Black}>
                            {` (${t("teacherAccount.privilegeProgram.commission")} ${TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth).commission}%)`}
                        </span>
                    </Typography>
                    {TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth).level === 1 && (
                        <Box sx={themes.PlatinumIcon}>
                            <Platinum />
                        </Box>
                    )}
                </Box>
            </Box>
            <Box sx={themes.ProgressContainer}>
                <ThemeProvider theme={TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth).level === 1 ? themes.theme2 : themes.theme}>
                    <LinearProgress
                        sx={themes.LinearProgress}
                        variant="determinate"
                        value={
                            (TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth /
                                TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth).nextLevelBoundary) *
                            100
                        }
                    />
                </ThemeProvider>
                <Box sx={themes.pointsOutOfContainer}>
                    <Typography sx={textThemes.Caption11Black}>{`${TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth} ${t("teacherAccount.privilegeProgram.points")}`}</Typography>
                    {TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth).level !== 1 && (
                        <Typography
                            sx={textThemes.Caption1DarkGrey}
                        >{`${t("teacherAccount.privilegeProgram.outOf")} ${TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth).nextLevelBoundary}`}</Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );
});

export default memo(NextMonthLevel);
