import { styleProcessing } from "API/plugins/Utilities";

export const TopCardWrapper = (theme) => {
    let commonStyle = {
        display: "flex",
        justifyContent: "space-between",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, flexDirection: "column-reverse", gap: "10px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TargetSubjWrapper = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "4px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, flexDirection: "column" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const AvatarPriceWrapper = (theme) => {
    let commonStyle = {
        marginBottom: "12px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, flexDirection: "column-reverse", alignItems: "flex-start", gap: "18px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Price = {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0em",
};

export const divider = { marginBottom: "10px", color: "#EEEEF0" };

export const BottomCardWrapper = (theme) => {
    let commonStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, flexDirection: "column", alignItems: "flex-start", gap: "6px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const DeadlineWrapper = {
    display: "flex",
    gap: "4px",
};

export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const PurpleCircle = {
    borderRadius: "999px",
    background: "#6212FF",
    display: "inline-flex",
    padding: "2px 6px",
    justifyContent: "center",
    alignItems: "center",
    gap: "1px",
};

export const StudentNameAndMessages = {
    display: "flex",
    alignItems: "center",
    gap: "11px",
};
