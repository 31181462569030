import React from "react";
import * as TutorsFeedbackStyle from "./TutorsFeedbackStyle";
import { Box, Typography } from "@mui/material";
import FeedbackCard from "./FeedBackCard/FeedbackCard";
import MainPageStore from "store/MainPageStore";
import { observer } from "mobx-react-lite";
import { useMedias } from "API/mediasHook";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import SButton from "components/UI/SButton";

const TutorsFeedback = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const toggle = () => {
        MainPageStore.SetDisplayMoreReviewsFlag();
    };

    const medias = useMedias();

    return (
        <Box sx={TutorsFeedbackStyle.TutorsFeedbackBlock(medias)}>
            <Typography sx={TutorsFeedbackStyle.TutorsFeedbackHeader(medias)}>{t("promoPages.main.reviewsOfTutors")}</Typography>

            <Box sx={TutorsFeedbackStyle.FeedbacksBlock(medias)}>
                {MainPageStore.TutorsReviewCards.map((perReview, index) =>
                    MainPageStore.displayMoreReviews ? (
                        <FeedbackCard
                            key={perReview.ID}
                            review={perReview}
                        />
                    ) : index <= 5 ? (
                        <FeedbackCard
                            key={perReview.ID}
                            review={perReview}
                        />
                    ) : null,
                )}
            </Box>

            <Box
                onClick={toggle}
                style={TutorsFeedbackStyle.ReadMoreBlock}
            >
                <SButton
                    variant="text-arrow"
                    fontSize="16px"
                >
                    {MainPageStore.displayMoreReviews ? t("promoPages.main.collapse") : t("promoPages.main.readMore")}
                </SButton>
            </Box>
        </Box>
    );
});

export default memo(TutorsFeedback);
