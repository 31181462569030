import zIndex from "@mui/material/styles/zIndex";
import { styleProcessing } from "API/plugins/Utilities";

export const ToggleButtonSx = (theme) => {
    let commonStyle = {
        "&.MuiToggleButton-root:hover": {
            color: "#4E4E4E",
            backgroundColor: "transparent",
        },
        "&.Mui-selected, &.Mui-selected:hover": {
            color: "#fff",
            bgcolor: "#222",
            border: "none",
        },
        color: "#4E4E4E",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "150%",
        width: "fit-content",
        padding: "8px 16px 8px 16px",
        alignItems: "center",
        textTransform: "none",
        borderRadius: "30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "6px 12px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ToggleButtonStyle = (type, alignment) => {
    return {
        borderRadius: "30px",
        margin: 0,
        border: "solid 1px #67677A",
        whiteSpace: "nowrap",
    };
};

export const ToggleButtonGroup = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "10px",
        overflow: "scroll",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "8px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
