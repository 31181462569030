/**
 * @fileoverview gRPC-Web generated client stub for pb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.12.4
// source: internal/pb/tutor-catalog.proto

/* eslint-disable */
// @ts-nocheck

const grpc = {};
grpc.web = require("grpc-web");

const proto = {};
proto.pb = require("./tutor-catalog_pb.js");

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.TutorCatalogServiceClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options.format = "text";

    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */
    this.client_ = new grpc.web.GrpcWebClientBase(options);

    /**
     * @private @const {string} The hostname
     */
    this.hostname_ = hostname.replace(/\/+$/, "");
};

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.pb.TutorCatalogServicePromiseClient = function (hostname, credentials, options) {
    if (!options) options = {};
    options.format = "text";

    /**
     * @private @const {!grpc.web.GrpcWebClientBase} The client
     */
    this.client_ = new grpc.web.GrpcWebClientBase(options);

    /**
     * @private @const {string} The hostname
     */
    this.hostname_ = hostname.replace(/\/+$/, "");
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.SearchTutorsRequest,
 *   !proto.pb.SearchTutorsResponse>}
 */
const methodDescriptor_TutorCatalogService_SearchTutors = new grpc.web.MethodDescriptor(
    "/pb.TutorCatalogService/SearchTutors",
    grpc.web.MethodType.UNARY,
    proto.pb.SearchTutorsRequest,
    proto.pb.SearchTutorsResponse,
    /**
     * @param {!proto.pb.SearchTutorsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.SearchTutorsResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.SearchTutorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.SearchTutorsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.SearchTutorsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.TutorCatalogServiceClient.prototype.searchTutors = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.TutorCatalogService/SearchTutors", request, metadata || {}, methodDescriptor_TutorCatalogService_SearchTutors, callback);
};

/**
 * @param {!proto.pb.SearchTutorsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.SearchTutorsResponse>}
 *     Promise that resolves to the response
 */
proto.pb.TutorCatalogServicePromiseClient.prototype.searchTutors = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.TutorCatalogService/SearchTutors", request, metadata || {}, methodDescriptor_TutorCatalogService_SearchTutors);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.OtherSimilarTutorsRequest,
 *   !proto.pb.OtherSimilarTutorsResponse>}
 */
const methodDescriptor_TutorCatalogService_OtherSimilarTutors = new grpc.web.MethodDescriptor(
    "/pb.TutorCatalogService/OtherSimilarTutors",
    grpc.web.MethodType.UNARY,
    proto.pb.OtherSimilarTutorsRequest,
    proto.pb.OtherSimilarTutorsResponse,
    /**
     * @param {!proto.pb.OtherSimilarTutorsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.OtherSimilarTutorsResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.OtherSimilarTutorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.OtherSimilarTutorsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.OtherSimilarTutorsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.TutorCatalogServiceClient.prototype.otherSimilarTutors = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.TutorCatalogService/OtherSimilarTutors", request, metadata || {}, methodDescriptor_TutorCatalogService_OtherSimilarTutors, callback);
};

/**
 * @param {!proto.pb.OtherSimilarTutorsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.OtherSimilarTutorsResponse>}
 *     Promise that resolves to the response
 */
proto.pb.TutorCatalogServicePromiseClient.prototype.otherSimilarTutors = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.TutorCatalogService/OtherSimilarTutors", request, metadata || {}, methodDescriptor_TutorCatalogService_OtherSimilarTutors);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.FavouritesRequest,
 *   !proto.pb.FavouritesResponse>}
 */
const methodDescriptor_TutorCatalogService_Favourites = new grpc.web.MethodDescriptor(
    "/pb.TutorCatalogService/Favourites",
    grpc.web.MethodType.UNARY,
    proto.pb.FavouritesRequest,
    proto.pb.FavouritesResponse,
    /**
     * @param {!proto.pb.FavouritesRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.FavouritesResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.FavouritesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.FavouritesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.FavouritesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.TutorCatalogServiceClient.prototype.favourites = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.TutorCatalogService/Favourites", request, metadata || {}, methodDescriptor_TutorCatalogService_Favourites, callback);
};

/**
 * @param {!proto.pb.FavouritesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.FavouritesResponse>}
 *     Promise that resolves to the response
 */
proto.pb.TutorCatalogServicePromiseClient.prototype.favourites = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.TutorCatalogService/Favourites", request, metadata || {}, methodDescriptor_TutorCatalogService_Favourites);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.ModifyFavouritesRequest,
 *   !proto.pb.ModifyFavouritesResponse>}
 */
const methodDescriptor_TutorCatalogService_ModifyFavourites = new grpc.web.MethodDescriptor(
    "/pb.TutorCatalogService/ModifyFavourites",
    grpc.web.MethodType.UNARY,
    proto.pb.ModifyFavouritesRequest,
    proto.pb.ModifyFavouritesResponse,
    /**
     * @param {!proto.pb.ModifyFavouritesRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.ModifyFavouritesResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.ModifyFavouritesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.ModifyFavouritesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.ModifyFavouritesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.TutorCatalogServiceClient.prototype.modifyFavourites = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.TutorCatalogService/ModifyFavourites", request, metadata || {}, methodDescriptor_TutorCatalogService_ModifyFavourites, callback);
};

/**
 * @param {!proto.pb.ModifyFavouritesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.ModifyFavouritesResponse>}
 *     Promise that resolves to the response
 */
proto.pb.TutorCatalogServicePromiseClient.prototype.modifyFavourites = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.TutorCatalogService/ModifyFavourites", request, metadata || {}, methodDescriptor_TutorCatalogService_ModifyFavourites);
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.pb.CountTutorsRequest,
 *   !proto.pb.CountTutorsResponse>}
 */
const methodDescriptor_TutorCatalogService_CountTutors = new grpc.web.MethodDescriptor(
    "/pb.TutorCatalogService/CountTutors",
    grpc.web.MethodType.UNARY,
    proto.pb.CountTutorsRequest,
    proto.pb.CountTutorsResponse,
    /**
     * @param {!proto.pb.CountTutorsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
        return request.serializeBinary();
    },
    proto.pb.CountTutorsResponse.deserializeBinary,
);

/**
 * @param {!proto.pb.CountTutorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.pb.CountTutorsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.pb.CountTutorsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.pb.TutorCatalogServiceClient.prototype.countTutors = function (request, metadata, callback) {
    return this.client_.rpcCall(this.hostname_ + "/pb.TutorCatalogService/CountTutors", request, metadata || {}, methodDescriptor_TutorCatalogService_CountTutors, callback);
};

/**
 * @param {!proto.pb.CountTutorsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.pb.CountTutorsResponse>}
 *     Promise that resolves to the response
 */
proto.pb.TutorCatalogServicePromiseClient.prototype.countTutors = function (request, metadata) {
    return this.client_.unaryCall(this.hostname_ + "/pb.TutorCatalogService/CountTutors", request, metadata || {}, methodDescriptor_TutorCatalogService_CountTutors);
};

module.exports = proto.pb;
