const EmptyHeaderPhoto = function (props) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon/profile">
                <path
                    id="Vector"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.70331 12.6328C5.61909 12.6328 2.29706 15.9548 2.29697 20.0391L2.29688 22.2078C2.29688 23.1915 3.10533 24 4.089 24H19.9108C20.8945 24 21.703 23.1915 21.703 22.2078L21.7031 20.0391C21.7033 15.9548 18.3814 12.6328 14.2972 12.6328H9.70331ZM12 0C9.14944 0 6.89062 2.58169 6.89062 5.6835C6.89062 8.78536 9.14944 11.367 12 11.367C14.8506 11.367 17.1094 8.78536 17.1094 5.6835C17.1094 2.58169 14.8506 0 12 0Z"
                    fill="#BBBBC8"
                />
            </g>
        </svg>
    );
};

export default EmptyHeaderPhoto;
