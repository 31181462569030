import React from "react";
import { observer } from "mobx-react-lite";

import { Stack, Typography } from "@mui/material";

import Progress from "./Progress";
import TutorWorkingStatus from "./Tutor/TutorWorkingStatus";
import StudentWorkingStatus from "./Student/StudentWorkingStatus";

import UserStore from "store/UserStore";
import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";

import * as styles from "./styles";

const Working = () => {
    return (
        <Stack
            Stack
            direction="column"
            spacing={2.5}
        >
            <Stack
                direction="row"
                spacing={8}
                alignItems="flex-start"
            >
                <Stack
                    direction="column"
                    spacing={1.25}
                >
                    {UserStore.role === "student" ? <StudentWorkingStatus /> : <TutorWorkingStatus />}
                </Stack>
                <Stack
                    direction="column"
                    sx={{ paddingRight: "50px" }}
                >
                    <Progress data={DiplomaStore.tutorProgress} />
                </Stack>
            </Stack>
            <Stack
                direction="row"
                spacing={0.5}
                alignItems="flex-start"
                sx={styles.workingStatus.timeToGo}
            >
                <Typography variant="grayText">До завершения:</Typography>
                <Typography variant="standard">18 дней 3 часа</Typography>
            </Stack>
        </Stack>
    );
};

export default observer(Working);
