import { styleProcessing } from "../../API/plugins/Utilities";

export const TeacherQuestionnaireDoneBodyBlock = {
    background: "#FFF",
    width: "100%",
    minHeight: 904,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "97px",
    borderRadius: "0px 0px 40px 40px",
};

export const TeacherQuestionnaireDoneBodyBlockContent = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "60px",
        alignItems: "flex-start",
        maxWidth: "410px",
        width: "100%",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            boxSizing: "border-box",
            paddingLeft: "16px",
            paddingRight: "16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TextBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
};
