import { styleProcessing } from "API/plugins/Utilities";

export const filtersCardStyle = (theme) => {
    let commonStyle = {
        position: "relative",
        boxShadow: "0px 4px 30px rgba(106, 99, 118, 0.05)",
        borderRadius: "0px 0px 40px 40px",
        paddingBottom: 60,
        width: "100%", //(!!)
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
