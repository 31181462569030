import { makeAutoObservable } from "mobx";
import Avatar_teacher from "assets/PAAssets/PNG/avatar_teacher.png";
import questionare from "./TeacherQuestionnaireStore";

class TeacherSettingsStore {
    subjects = ["математика", "макроэкономика", "английский язык", "мат. статистика", "дискретная математика"];

    selectedTab = 1;

    snackbarAddCard = true;
    openDialogAddCard = false;

    temporaryCardNumber = null;
    temporaryCardExpirationDate = null;
    temporaryCardCVC = null;

    temporaryDayUntilBusy = "";
    dayUntilBusy = "";

    deleteAccountReason = "";

    openChangePhotoSnackbar = false;

    reasonsToDelete = [
        { key: 1, value: "noStudents", label: "teacherSettings.accountActions.noStudents" },
        { key: 2, value: "anotherWebsite", label: "teacherSettings.accountActions.anotherWebsite" },
        { key: 3, value: "noTeaching", label: "teacherSettings.accountActions.noTeaching" },
        { key: 4, value: "badService", label: "teacherSettings.accountActions.badService" },
        { key: 5, value: "spam", label: "teacherSettings.accountActions.spam" },
        { key: 6, value: "other", label: "teacherSettings.accountActions.other" },
    ];

    leftOnAccount = [
        { key: 1, type: "order", name: "«Валовой доход торговли: источники формирования, направления использования и проблемы оптимизации»", date: null },
        { key: 2, type: "class", name: "Мария Д.", date: new Date(2025, 7, 17) },
    ];

    constructor() {
        makeAutoObservable(this);
    }

    setSnackBarClose() {
        this.snackbarAddCard = false;
    }

    setOpenChangePhotoSnackbar(value) {
        this.openChangePhotoSnackbar = value;
    }

    setDeleteAccountReason(value) {
        this.deleteAccountReason = value;
    }

    setTemporaryDayUntilBusy(value) {
        this.temporaryDayUntilBusy = value;
    }

    setDayUntilBusy(value) {
        this.dayUntilBusy = value;
    }

    setTemporaryCardNumber(value) {
        this.temporaryCardNumber = value;
    }

    setTemporaryCardExpirationDate(value) {
        this.temporaryCardExpirationDate = value;
    }

    setTemporaryCardCVC(value) {
        this.temporaryCardCVC = value;
    }
    setOpenDialogAddCard = (value) => {
        this.openDialogAddCard = value;
    };

    setSelectedTab = (tab) => {
        this.selectedTab = tab;
    };

    cardButtonDisabled() {
        if (this.temporaryCardCVC !== null && this.temporaryCardNumber !== null && this.temporaryCardExpirationDate !== null) {
            return this.temporaryCardCVC.length !== 3 || this.temporaryCardNumber.length !== 19 || this.temporaryCardExpirationDate.length !== 5;
        } else {
            return true;
        }
    }
}

export default new TeacherSettingsStore();
