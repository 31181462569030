import React, { memo, useState } from "react";
import * as themes from "./AreYouSureDeleteThemes";
import * as textThemes from "themes/TextThemes";
import { Checkbox, ClickAwayListener, Paper, Popper, Box, Typography } from "@mui/material";
import SButton from "components/UI/SButton";
import { observer } from "mobx-react-lite";
import PurpleCheckBoxDefault from "assets/PaymentAssets/SVG/PurpleCheckBoxDefault";
import PurpleCheckBoxChecked from "assets/PaymentAssets/SVG/PurpleCheckBoxChecked";

const AreYouSureDelete = observer(({ children, ...props }) => {
    const [checkedDeleteForAll, SetCheckedDeleteForAll] = useState(false);

    return (
        <Popper
            open={props.open}
            anchorEl={props.anchor}
            sx={themes.Popper}
        >
            <Paper sx={themes.Paper}>
                <ClickAwayListener onClickAway={props.handleClose}>
                    <Box style={themes.PaperContent}>
                        <Box style={themes.CheckboxContainer}>
                            <Checkbox
                                sx={themes.CheckBox}
                                checked={checkedDeleteForAll}
                                onClick={() => SetCheckedDeleteForAll(!checkedDeleteForAll)}
                                icon={<PurpleCheckBoxDefault />}
                                checkedIcon={<PurpleCheckBoxChecked />}
                            />
                            <Typography sx={textThemes.Body2Black}>Удалить у всех?</Typography>
                        </Box>
                        <Box style={themes.ButtonsContainer}>
                            <SButton
                                variant="text"
                                style={themes.BackButton}
                                onClick={() => {
                                    props.setOpenOuter(true);
                                    props.setOpen(false);
                                    props.SetOpenChatDeleteModal(false);
                                }}
                            >
                                Назад
                            </SButton>
                            <SButton
                                variant="text"
                                style={themes.ContinueButton}
                                onClick={() => (props.openChatDeleteModal ? props.handleDeleteChat(checkedDeleteForAll) : props.delHistory(checkedDeleteForAll))}
                            >
                                Продолжить
                            </SButton>
                        </Box>
                    </Box>
                </ClickAwayListener>
            </Paper>
        </Popper>
    );
});

export default memo(AreYouSureDelete);
