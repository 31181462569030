import { createTheme } from "@mui/material/styles";

export const subjectBlockContainerStyle = (medias, styleMode) => {
    if (styleMode === 0) {
        return {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            gap: "4px",
            width: "100%",
            height: "79px",
            flex: "none",
            order: 1,
            flexGrow: 0,
            zIndex: 1,
        };
    }

    if (styleMode === 1) {
        return {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            gap: "4px",
            width: "100%",
            height: "fit-content", // medias.sm ? "88px" :
            flex: "none",
            order: 1,
            flexGrow: 0,
            zIndex: 1,
        };
    }
};

export const educationBlockContainerStyle = (styleMode) => {
    if (styleMode === 0) {
        return {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px",
            gap: "10px",
            width: "100%",
            height: "25px",
            flex: "none",
            order: 2,
            flexGrow: 0,
        };
    }

    if (styleMode === 1) {
        return {
            display: "none",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px",
            gap: "10px",
            width: "100%",
            height: "28px",
            flex: "none",
            order: 2,
            flexGrow: 0,
        };
    }
};

export const actionTextStyle = {
    color: "var(--Text-Black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    "&:hover": {
        color: "#6212FF!important",
    },
};
