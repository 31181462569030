import * as themes from "./EmptyNotificationsThemes";
import * as textThemes from "themes/TextThemes";
import { memo } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import EmptyNotificationsIcon from "assets/NotificationsAssets/SVG/EmptyNotificationsIcon";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import UserStore from "store/UserStore";

const EmptyNotifications = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.EmptyNotifications}>
            <Box>
                <EmptyNotificationsIcon />
            </Box>
            <Box sx={themes.EmptyNotificationsContent}>
                <Typography sx={textThemes.HeadlineH5Black}>{t("notifications.noNotificationsTitle")}</Typography>
                <Typography sx={textThemes.Body2Black}>{UserStore.role === "tutor" ? t("notifications.noNotificationsTextForTutor") : t("notifications.noNotificationsTextForStudent")}</Typography>
            </Box>
        </Box>
    );
});

export default memo(EmptyNotifications);
