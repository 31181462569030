//Дополнительные требования
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import SBButtonGroup from "components/UI/ButtonGroup";
import store from "store/RequestStore";

import { originalityPercentage } from "API/dictionaries/const_data";
import { antiplagiatMethod } from "API/dictionaries/const_data";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import CustomSelect from "components/UI/CustomSelect";
import StepHiddenButtonWrapper from "components/OrdersBodyBlock/RenewOrder/StepHiddenButtonWrapper";
import filterStore from "store/FilterStore";
import * as theme from "./Step22Themes";
const Step22 = () => {
    const [alert, setAlert] = useState(0);

    let data = store.storage[store.activeStepIndex].substeps[store.subStepIndex]?.value;

    if (data === undefined) store.setActiveSubStepIndex(0);

    const [originality, setOriginality] = useState(store.storage[store.activeStepIndex].substeps[store.subStepIndex].value[0]);

    const [antiplagiat, setAntiplagiat] = useState(store.storage[store.activeStepIndex].substeps[store.subStepIndex].value[1]);

    const [plagiatReport, setPlagiatReport] = useState(store.storage[store.activeStepIndex].substeps[store.subStepIndex].value[2]);

    const handleNext = () => {
        let description = "";

        if (originality === "Не важно") {
            description += "Нет";
        } else {
            description += "Оригинальность " + originality + ", проверка " + antiplagiat;
        }

        store.setStep(14, description);
        store.setStepValue(14, [originality, antiplagiat, plagiatReport]);
        store.incrementStepIndex();
    };

    return (
        <>
            <Box sx={theme.TopWrapper}>
                <Box sx={theme.Text}>Выберите из списка необходимую оригинальность работы</Box>
                <CustomSelect
                    value={originality}
                    setValue={setOriginality}
                    list={originalityPercentage}
                />
            </Box>
            <Box>
                <Box sx={theme.Text}>Выберите способ проверки на антиплагиат</Box>
                <CustomSelect
                    value={antiplagiat}
                    setValue={setAntiplagiat}
                    list={antiplagiatMethod}
                />
            </Box>

            <Box sx={theme.FormWrapper}>
                <FormControlLabel
                    sx={theme.FormControlLabel}
                    control={
                        <Checkbox
                            sx={theme.Checkbox}
                            checked={plagiatReport}
                            onChange={(event, newInputValue) => {
                                setPlagiatReport(newInputValue);
                                filterStore.setSubmitButtonFilter(false);
                            }}
                            style={{ color: "#6212FF" }}
                        />
                    }
                />
                <Box sx={theme.CheckboxText}>Отчёт о плагиате</Box>
            </Box>

            <StepHiddenButtonWrapper submitRenew={handleNext}>
                <SBButtonGroup
                    handleNext={handleNext}
                    handleBack={() => store.decrementStepIndex()}
                    activeStep={store.activeStepIndex}
                    subStep={store.subStepIndex}
                    steps={store.storage}
                />
            </StepHiddenButtonWrapper>
        </>
    );
};

export default observer(Step22);
