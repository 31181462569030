import { ConsumerNotifierServiceClient } from "API/services/grpc/proto/NotificationService/notifier_grpc_web_pb";

import { ReadNotificationsRequest } from "API/services/grpc/proto/NotificationService/notifier_pb";

const NotificationServiceUrl = "https://study-buddy.ru:8080";

const NotificationService = new ConsumerNotifierServiceClient(NotificationServiceUrl, null, null);

export async function GetRecentNotifications(data, metadata, callback) {
    // eslint-disable-next-line no-undef
    let request = new proto.pb.Empty();

    await NotificationService.recentNotifications(request, metadata, callback);
}

export async function ReadNotifications(data, metadata, callback) {
    let request = new ReadNotificationsRequest();

    let { notificationUuids } = data;

    request.setNotificationUuidsList(notificationUuids);

    await NotificationService.readNotifications(request, metadata, callback);
}

export function GetStreamNotifications(data, metadata, callback) {
    // eslint-disable-next-line no-undef
    let request = new proto.pb.Empty();

    return NotificationService.streamNewNotifications(request, metadata);
}
