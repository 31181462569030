import mainLang from "./ru.json";
import uiLang from "./UI/ru.json";
import errorLang from "./Error/ru.json";
import catalogLang from "./Catalog/ru.json";
import teacherAccountLang from "./TeacherAccount/ru.json";
import teacherSettingsLang from "./TeacherSettings/ru.json";
import teacherClassesLang from "./TeacherClasses/ru.json";
import notificationsLang from "./Notifications/ru.json";
import paidServicesLang from "./PaidServices/ru.json";
import ordersLang from "./Orders/ru.json";
import promoPagesLang from "./PromoPages/ru.json";
import teacherQuestionnaireLang from "./TeacherQuestionnaire/ru.json";
import rulesLang from "./Rules/ru.json";
import errorsLang from "./Errors/ru.json";

let messagesPaths = [
    errorsLang,
    rulesLang,
    teacherQuestionnaireLang,
    promoPagesLang,
    mainLang,
    uiLang,
    errorLang,
    catalogLang,
    teacherAccountLang,
    teacherSettingsLang,
    ordersLang,
    teacherClassesLang,
    notificationsLang,
    paidServicesLang,
];

function getAllMessages() {
    let messages = {};

    messagesPaths.forEach((data) => {
        messages = Object.assign(messages, data);
    });
    return messages;
}

export default getAllMessages();
