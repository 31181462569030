import { createTheme } from "@mui/material/styles";

export const educationContainerStyle = (styleMode) => {
    if (styleMode === 0) {
        return {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px",
            gap: "5px",
            //width: "100%",
        };
    }

    if (styleMode === 1) {
        return {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px",
            gap: "5px",
            //width: "100%",
            order: 3,
        };
    }
};

export const educationLabelStyle = (styleMode) => {
    if (styleMode === 0) {
        return {
            height: "21px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "150%",
            display: "flex",
            alignItems: "center",
            color: "#9E9EB0",
            flex: "none",
            order: 0,
            flexGrow: 0,
        };
    }

    if (styleMode === 1) {
        return {
            height: "24px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "150%",
            display: "flex",
            alignItems: "center",
            color: "#9E9EB0",
            flex: "none",
            order: 0,
            flexGrow: 0,
        };
    }
};

export const educationTextStyle = (styleMode, medias) => {
    if (styleMode === 0) {
        return {
            height: "21px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "150%",
            color: "#222222",
            flex: "none",
            order: 0,
            flexGrow: 0,
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
            maxWidth: medias.sm ? "446px" : "75px",
        };
    }

    if (styleMode === 1) {
        return {
            height: "24px",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "150%",
            color: "#222222",
            flex: "none",
            order: 0,
            flexGrow: 0,
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
            maxWidth: medias.sm ? "428px" : "181px",
        };
    }
};
