export const ImgBlock = {
    width: "701px",
    height: "409px",
    position: "relative",
};

export const Img = {
    width: 411,
    height: 409,
    left: 167,
    top: 0,
    position: "absolute",
};

export const ImgBackground = {
    width: "495px",
    height: "343px",
    left: 106,
    top: 66,
    position: "absolute",
    borderRadius: "41px",
    background: "#6212FF",
};

export const NoteLeftBlock = {
    position: "absolute",
    top: 305,

    display: "flex",
    width: "206px",
    padding: "15px 20px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "6px 0px 6px 6px",
    background: "#FFF",
    boxShadow: "0px 2.50519px 6.26298px 0px rgba(106, 99, 118, 0.10)",
};

export const NoteRightBlock = {
    position: "absolute",
    top: 106,
    left: 495,

    display: "flex",
    width: "206px",
    padding: "15px 20px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",

    borderRadius: "6px 6px 6px 0px",
    background: "#FFF",
    boxShadow: "0px 2.50519px 6.26298px 0px rgba(106, 99, 118, 0.10)",
};
