import React from "react";
import * as themes from "./OrderResponseCountSpDropMenuWrapperStyled";
import OrderResponseCountSpecialist from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseCountSpecialist/OrderResponseCountSpecialist";
import OrderResponseDropMenu from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseDropMenu/OrderResponseDropMenu";

const OrderResponseCountSpDropMenuWrapper = ({ value, page }) => {
    return (
        <>
            <themes.OrderResponeTutorsResponsesTopWrapper>
                <OrderResponseCountSpecialist value={value} />
                {page !== 1 && <OrderResponseDropMenu />}
            </themes.OrderResponeTutorsResponsesTopWrapper>
        </>
    );
};

export default OrderResponseCountSpDropMenuWrapper;
