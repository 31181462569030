export const ocInfoAreaStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    width: "100%",
    flexGrow: 1,
};

export const themeBlockContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "5px",
    width: "100%",
    order: 1,
    flexGrow: 0,
};

export const themeLabelStyle = {
    height: "21px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#9E9EB0",
    order: 0,
    flexGrow: 0,
};

export const themeTextStyle = {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
    color: "#222222",
    order: 0,
    flexGrow: 0,
};

export const deadlineBlockContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    gap: "5px",
    width: "100%",
    height: "21px",
    order: 1,
    flexGrow: 0,
};

export const deadlineLabelStyle = {
    height: "21px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#9E9EB0",
    order: 0,
    flexGrow: 0,
};

export const deadlineTextStyle = {
    height: "21px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#222222",
    order: 0,
    flexGrow: 0,
};
