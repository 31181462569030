import React, { memo } from "react";
import Box from "@mui/material/Box";
import StarIcon from "assets/CommonAssets/SVG/StarSVG";
import { LinearProgress, Typography } from "@mui/material";
import * as themes from "./RatingBlockThemes";
import { ThemeProvider } from "@mui/material/styles";
import { useMedias } from "../../../../API/mediasHook";

const RatingBlock = ({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={themes.ratingContainerStyle}>
            {medias.sm ? (
                <Box sx={themes.starContainerStyle}>
                    <Box sx={themes.starRowStyle}>
                        <StarIcon></StarIcon>
                        <Typography sx={themes.ratingTextStyle}>{props.value.Rating}</Typography>
                    </Box>
                    <Typography sx={themes.subTextStyle}>рейтинг основан на отзывах 200 учеников</Typography>
                </Box>
            ) : null}
            <Box sx={themes.starsLinearContainerStyle}>
                {props.value.RatingInfo.map((value) => (
                    <Box
                        sx={themes.ratingLineStyle(medias)}
                        key={value.ID}
                    >
                        <Typography sx={themes.ratingLinePreLabelStyle(value.RatingCount)}>{value.RatingText}</Typography>
                        <ThemeProvider theme={themes.RatingProgressLinearTheme}>
                            <LinearProgress
                                variant="determinate"
                                value={(value.RatingCount / props.value.ReviewsCount) * 100}
                            ></LinearProgress>
                        </ThemeProvider>
                        <Typography sx={themes.ratingLinePostLabelStyle(value.RatingCount)}>{value.RatingCount}</Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default memo(RatingBlock);
