import React, { memo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Card, Tab, Tabs } from "@mui/material";
import * as themes from "./DisciplinesCardThemes";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import TabPanel from "@mui/lab/TabPanel";
import { TabContext } from "@mui/lab";
import DisciplineContent from "./DisciplineContent/DisciplineContent";
import TeacherStore from "store/TeacherStore";
import { useMedias } from "../../../API/mediasHook";

const DisciplinesCard = observer(({ children, ...props }) => {
    const [selectedDisciplineTab, SetSelectedDisciplineTab] = useState(props.value.Disciplines[0]?.discipline + props.value.Disciplines[0]?.ID);

    const handleChange = (event, newValue) => {
        TeacherStore.ToggleDisciplinesExpanded(false);
        SetSelectedDisciplineTab(newValue);
    };

    const Toggle = () => {
        TeacherStore.ToggleDisciplinesExpanded();
    };

    const medias = useMedias();

    return (
        <Card
            sx={themes.disciplineCardStyle(medias)}
            id={"DisciplinesCard"}
        >
            <Box sx={themes.disciplineContainerStyle}>
                <TabContext value={selectedDisciplineTab}>
                    <Box sx={themes.headerContainerStyle}>
                        <Typography sx={themes.aboutTextStyle}>Предметы</Typography>
                        <ThemeProvider theme={themes.TabsTheme}>
                            <Tabs
                                value={selectedDisciplineTab}
                                onChange={handleChange}
                            >
                                {props.value.Disciplines.map((value) => (
                                    <Tab
                                        key={value.ID}
                                        value={value.discipline + value.ID}
                                        disableRipple={true}
                                        label={value.discipline}
                                    />
                                ))}
                            </Tabs>
                        </ThemeProvider>
                    </Box>
                    {props.value.Disciplines.map((value) => (
                        <TabPanel
                            key={value.ID}
                            value={value.discipline + value.ID}
                            sx={themes.tabPanelStyle}
                        >
                            <DisciplineContent value={props.value.DisciplinesInfo.filter((x) => x.DisciplineID === value.ID)}></DisciplineContent>
                        </TabPanel>
                    ))}
                </TabContext>
            </Box>
            <Typography
                sx={themes.showMoreTextStyle}
                onClick={Toggle}
            >
                {TeacherStore.disciplinesExpanded ? "Показать меньше" : "Показать больше"}
            </Typography>
        </Card>
    );
});

export default memo(DisciplinesCard);
