import React from "react";
import { Stack, Typography } from "@mui/material";
import GiftIcon from "components/DiplomaChats/Icons/GiftIcon";
import * as styles from "pages/DiplomaChats/styles";
import Box from "@mui/material/Box";

const OrderPaymentEnded = () => {
    return (
        <Stack
            direction="column"
            spacing={1.25}
            style={styles.dashedBorder}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
            >
                <Typography variant="standard2">Цена заказа</Typography>
                <Typography variant="title2">10 000 ₽</Typography>
            </Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
            >
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                >
                    <Typography variant="standard2">Начислены</Typography>
                    <Box sx={styles.giftContainerSmall}>
                        <GiftIcon size={14} />
                    </Box>
                </Stack>
                <Typography variant="title2">1000 баллов</Typography>
            </Stack>
        </Stack>
    );
};

export default OrderPaymentEnded;
