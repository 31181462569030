import React from "react";
import TutorRulesBodyBlock from "components/TutorRulesBodyBlock/TutorRulesBodyBlock";
import { memo } from "react";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

const RulesTutorPage = ({ children, ...props }) => {
    return (
        <ScrollToTop>
            <TutorRulesBodyBlock />
        </ScrollToTop>
    );
};

export default memo(RulesTutorPage);
