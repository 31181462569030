import React, { memo, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { Divider, Typography } from "@mui/material";
import { register } from "swiper/element/bundle";
import { Keyboard, Navigation } from "swiper";
import * as themes from "./OtherTutorsBlockThemes";
import "swiper/css";
import "swiper/css/navigation";
import { styles } from "components/AccountBodyBlock/StudentAccount/Stories/Slider/styles";
import TeacherInfoMiniCard from "components/UI/TeacherMiniCard/TeacherInfoMiniCard/TeacherInfoMiniCard";
import TeacherStore from "store/TeacherStore";
import { useMedias } from "../../../API/mediasHook";

const OtherTutorsBlock = ({ children, ...props }) => {
    const swiperRef = useRef();

    const medias = useMedias();

    useEffect(() => {
        if (TeacherStore.teachersArrDisciplineRecommended.length > 0) {
            register();
            const params = {
                spaceBetween: 10,
                slidesPerView: "auto",
                centeredSlides: false,
                slidesPerGroupSkip: 1,
                grabCursor: true,
                keyboard: {
                    enabled: true,
                },
                navigation: {
                    disabledClass: "disabled-button",
                },
                modules: [Keyboard, Navigation],
                injectStyles: [styles],
            };

            Object.assign(swiperRef.current, params);
            swiperRef.current.initialize();
        }
    }, [TeacherStore.teachersArrDisciplineRecommended.length]);

    return (
        <Box sx={themes.otherTutorsBlockStyle(medias)}>
            <Divider></Divider>
            <Typography sx={themes.otherTutorsHeaderStyle(medias)}>Похожие репетиторы</Typography>
            {TeacherStore.teachersArrDisciplineRecommended.length > 0 ? (
                <Box sx={themes.otherTutorsContainerStyle(medias)}>
                    <swiper-container
                        ref={swiperRef}
                        init="false"
                        style={{ width: "-webkit-fill-available" }}
                    >
                        {TeacherStore.teachersArrDisciplineRecommended.map((value) => (
                            <swiper-slide
                                style={{ maxWidth: "fit-content" }}
                                key={value.ID}
                            >
                                <TeacherInfoMiniCard value={value}></TeacherInfoMiniCard>
                            </swiper-slide>
                        ))}
                    </swiper-container>
                </Box>
            ) : (
                <Typography sx={themes.otherTutorsHeaderStyle(1)}>Похожих репетиторов не найдено!</Typography>
            )}
        </Box>
    );
};

export default memo(OtherTutorsBlock);
