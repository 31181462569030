import { styleProcessing } from "API/plugins/Utilities";

export const SignUpStudent = (theme) => {
    let commonStyle = {
        padding: "30px 60px 0px 60px",
        display: "flex",
        flexDirection: "column",
        gap: "64px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "13px 16px 16px 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const BackIcon = (theme) => {
    let commonStyle = {
        width: "fit-content",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            marginLeft: "-4px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const SignUpStudentContent = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const TopBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            maxWidth: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const DescriptionText = (theme) => {
    let commonStyle = {
        maxWidth: "328px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            maxWidth: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const FormAndButton = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        maxWidth: "500px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            maxWidth: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const SignUpForm = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        maxWidth: "328px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            maxWidth: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const SignUpButton = (theme) => {
    let commonStyle = {
        maxWidth: "328px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            maxWidth: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ButtonAndRules = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const Agreements = {
    display: "flex",
    alignItems: "flex-start",
    gap: "10px",
};

export const CheckBoxAgreement = {
    marginTop: "3px",
    padding: 0,
};

export const NameField = {
    "& .MuiInput-underline:before": {
        borderBottom: "1px solid #BBBBC8",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#6212FF",
    },
    "& .MuiInputBase-input": {
        fontSize: "16px",
        lineHeight: "150%",
        fontWeight: 400,
        textTransform: "none",
    },
};

export const EmailField = (condition) => {
    return {
        "& .MuiInput-underline:before": {
            borderBottom: condition ? "1px solid #E12626" : "1px solid #BBBBC8",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: condition ? "#E12626" : "#6212FF",
        },
        "& .MuiInputBase-input": {
            fontSize: "16px",
            lineHeight: "150%",
            fontWeight: 400,
            textTransform: "none",
        },
    };
};

export const AlreadyHaveAccount = {
    borderRadius: "20px",
    background: "rgba(187, 187, 200, 0.17)",
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 40px",
};
