import { createTheme } from "@mui/material/styles";

export const DialogContainerTheme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: "rgba(0, 0, 0, 0)!important",
                    boxShadow: "none!important",
                    maxWidth: "fit-content",
                    overflowY: "hidden!important",
                },
            },
        },
    },
});

export const dialogContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "10px",
};

export const cardContainerStyle = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "20px",
    background: "var(--text-black, #222)",
    width: 960,
    height: 520,
};

export const contentBlockContainerStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    "& *": {
        borderRadius: "20px",
    },
};

export const CloseIconTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid #9E9EB0",
                    borderRadius: "50%",
                    background: "transparent",
                    width: "24px",
                    height: "24px",
                    "&:hover": {
                        background: "#transparent!important",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "#transparent!important",
                        borderColor: "#6212FF",
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    padding: 0,
                    color: "#FFFFFF",
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    "&:hover": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                    "&:active": {
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                    },
                },
            },
        },
    },
});
