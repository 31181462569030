export const imageAttachmentStyle = (elementsInRow, finalInRow) => {
    return {
        position: "relative",
        minWidth: "300px",
        width: 100 / elementsInRow + "%",
        opacity: finalInRow ? 0.3 : 1,
        height: "100%",
        objectFit: "cover",
        borderRadius: "20px",
    };
};

export const blurContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "32.2%",
    height: "100%",
    borderRadius: "20px",
    opacity: "1",
    position: "absolute",
    right: 0,
};

export const moreToShowStyle = {
    color: "#ffffff",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};
