import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "assets/HeaderAssets/SVG/closeicongray.svg";
import * as textStyles from "themes/TextThemes";
import * as theme from "./DrawerHeaderThemes";
const DrawerHeader = ({ text, handleClose }) => {
    return (
        <Box sx={theme.DrawerHeaderWrapper}>
            <Typography sx={textStyles.HeadlineH5Black}>{text}</Typography>
            <IconButton
                onClick={handleClose}
                disableRipple
            >
                <img
                    src={CloseIcon}
                    alt="Close Icon"
                />
            </IconButton>
        </Box>
    );
};

export default DrawerHeader;
