import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import * as themes from "./MiniWidgetThemes";
import CurrentLevel from "../PrivilegeProgram/CurrentLevel/CurrentLevel";
import NextMonthLevel from "../PrivilegeProgram/NextMonth/NextMonthLevel";
import Rating from "../Rating/Rating";
import ConductedClasses from "../ConductedClasses/ConductedClasses";
import { register } from "swiper/element/bundle";
import "swiper/css";
import "swiper/css/navigation";
const MiniWidget = () => {
    const swiperRef = useRef();

    useEffect(() => {
        register();
        const params = {
            spaceBetween: "21px",
            slidesPerView: "auto",
            centeredSlides: false,
            slidesPerGroupSkip: 2,
            grabCursor: true,
        };

        Object.assign(swiperRef.current, params);
        swiperRef.current.initialize();
    }, []);

    return (
        <Box sx={themes.MiniWidgetBox}>
            <swiper-container
                ref={swiperRef}
                init="false"
            >
                <swiper-slide style={themes.SwiperSlide}>
                    <CurrentLevel />
                </swiper-slide>
                <swiper-slide style={themes.SwiperSlide}>
                    <NextMonthLevel />
                </swiper-slide>
                <swiper-slide style={themes.SwiperSlide}>
                    <Rating />
                </swiper-slide>
                <swiper-slide style={themes.SwiperSlide}>
                    <ConductedClasses />
                </swiper-slide>
            </swiper-container>
        </Box>
    );
};

export default MiniWidget;
