import React, { useState } from "react";
import { MenuItem, Typography } from "@mui/material";
import ModalUploadImage from "./ModalUploadImage/modalUploadImage";
import ModalUploadFile from "./ModalUploadFile/modalUploadFile";
import { Body2Black } from "themes/TextThemes";
import * as themes from "./ClipMenuContentThemes";

const ClipMenuContent = ({ setOpenClipMenu }) => {
    const [itemFile, setItemFile] = useState(null);

    const [sendModalMessage, setSendModalMessage] = useState("");

    const [openImageModal, setOpenImageModal] = useState(false);

    const [openUploadFileModal, setOpenUploadFileModal] = useState(false);

    return (
        <>
            <MenuItem
                disableRipple
                sx={themes.MenuItem}
                onClick={() => {
                    // setOpenClipMenu(false);
                    setOpenImageModal(true);
                }}
            >
                <Typography sx={Body2Black}>Добавить фото</Typography>
            </MenuItem>
            <MenuItem
                disableRipple
                sx={themes.MenuItem}
                onClick={() => {
                    // setOpenClipMenu(false);
                    setOpenUploadFileModal(true);
                }}
            >
                <Typography sx={Body2Black}>Прикрепить файл</Typography>
            </MenuItem>
            <ModalUploadImage
                openModalAttachments={openImageModal}
                setOpenModalAttachments={setOpenImageModal}
                setOpenModal={setOpenClipMenu}
                setItemFile={setItemFile}
                itemFile={itemFile}
                sendModalMessage={sendModalMessage}
                setSendModalMessage={setSendModalMessage}
            />
            <ModalUploadFile
                openModalUploadFile={openUploadFileModal}
                setOpenModalUploadFile={setOpenUploadFileModal}
                setOpenModal={setOpenClipMenu}
                setItemFile={setItemFile}
                itemFile={itemFile}
                sendModalMessage={sendModalMessage}
                setSendModalMessage={setSendModalMessage}
            />
        </>
    );
};

export default ClipMenuContent;
