import React from "react";
import { Box, Typography } from "@mui/material";
import OrderCardsWrapp from "components/OrdersBodyBlock/OrderCardsWrapp/OrderCardsWrapp";
import * as themes from "./OrdersBodyBlockThemes";
import * as textThemes from "themes/TextThemes";
import { useNavigate } from "react-router-dom";
import OrderTabs from "./OrderMenu/OrderTabs/OrderTabs";
import SButton from "components/UI/SButton";
import UserStore from "store/UserStore";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { memo } from "react";

const OrdersBodyBlock = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const navigateToCreateNewOrder = () => {
        navigate("/bid");
    };

    return (
        <>
            <Box sx={themes.orderMenuContainer}>
                <Box sx={themes.orderMenuWrapper}>
                    <Typography
                        variant="h4"
                        sx={textThemes.HeadlineH3Black}
                    >
                        {t("orders.myOrders")}
                    </Typography>
                    <OrderTabs />
                    {UserStore.role === "student" && (
                        <Box sx={themes.orderMenuButtonWrapper}>
                            <SButton
                                onClick={navigateToCreateNewOrder}
                                variant="arrow"
                                padding="11px 24px 12px 24px"
                            >
                                {t("orders.createNewOrder")}
                            </SButton>
                        </Box>
                    )}
                </Box>
            </Box>
            <OrderCardsWrapp />
        </>
    );
});

export default memo(OrdersBodyBlock);
