import { Button } from "@mui/material";

import React from "react";
import * as theme from "./ArrowButtonSheduleStylde";
const ArrowButtonShedule = ({ children, handleClick, disable }) => {
    return (
        <Button
            disableRipple
            disabled={disable}
            variant="text"
            sx={theme.ArrowButtonSheduleStyled}
            onClick={handleClick}
        >
            {children}
        </Button>
    );
};

export default ArrowButtonShedule;
