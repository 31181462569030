import React from "react";
import OrderCardWrapper from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponsCard/OrderCardWrapper";
import * as zThemes from "./ZeroCardStyled";
import * as themes from "./ZeroResponseCardStyled";
import { Box } from "@mui/material";

const ZeroResponseCard = () => {
    return (
        <OrderCardWrapper>
            <Box sx={themes.ZeroResponseCardWrapper}>
                <zThemes.ZeroCardsTitleStyled>Здесь будут отклики от специалистов</zThemes.ZeroCardsTitleStyled>
                <zThemes.ZeroResponseCardSubtitleStyled>
                    Специалисты пока не откликнулись на твой заказ. Обычно первые отклики появляются через 10 минут после публикации заказа
                </zThemes.ZeroResponseCardSubtitleStyled>
            </Box>
        </OrderCardWrapper>
    );
};

export default ZeroResponseCard;
