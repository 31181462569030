import { Typography } from "@mui/material";
import React from "react";
import * as themes from "./ClassStatusStyled";
import * as textThemes from "themes/TextThemes";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const ClassStatus = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const statusStyles = {
        completed: {
            class: themes.classStatusCompleted,
            textColorClass: themes.classStatusTextColorCompleted,
            convertedText: "teacherClasses.classCompletedOne",
        },
        cancelled: {
            class: themes.classStatusCanceled,
            textColorClass: themes.classStatusTextColorCanceled,
            convertedText: "teacherClasses.classCancelledOne",
        },
    };

    const { class: statusClass, textColorClass, convertedText } = statusStyles[props.status] || {};

    return (
        <Typography sx={[themes.classStatus, textThemes.Caption2Black, statusClass]}>
            <Typography
                variant="span"
                sx={[themes.classStatusTextColor, textColorClass]}
            >
                &bull;
            </Typography>
            {t(convertedText)}
        </Typography>
    );
};

export default memo(ClassStatus);
