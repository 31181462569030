const PurplePoint = function () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="5"
            height="24"
            viewBox="0 0 5 24"
            fill="none"
        >
            <circle
                cx="2.5"
                cy="11.5"
                r="2.5"
                fill="#6212FF"
            />
        </svg>
    );
};

export default PurplePoint;
