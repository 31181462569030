import { styleProcessing } from "API/plugins/Utilities";

export const MenuItem = (condition) => {
    return {
        backgroundColor: condition ? "rgba(187, 187, 200, 0.17) !important" : "#fff",
        borderRadius: "12px",
        fontSize: "14px",
        paddingTop: "10px",
        paddingBottom: "10px",
        ":hover": {
            backgroundColor: "rgba(187, 187, 200, 0.17)",
        },
    };
};

export const Menu = (theme) => {
    let commonStyle = {
        borderRadius: "12px",
        backgroundColor: "white",
        padding: "13px 12px",
        width: "265px",
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            maxWidth: "599px",
            width: "100%",
            padding: "4px",
            flexDirection: "row",
            boxSizing: "border-box",
            whiteSpace: "nowrap",
            overflowX: "scroll",
            "&::-webkit-scrollbar": {
                display: "none",
            },
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const SmallItem = (theme) => {
    let commonStyle = {
        backgroundColor: "white",
        borderRadius: "20px",
        padding: "24px 30px 30px 30px",
        maxWidth: "641px",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            boxSizing: "border-box",
            padding: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Button = {
    display: "flex",
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    border: "1px solid",
    borderColor: "rgba(34, 34, 34, 1)",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    cursor: "pointer",
};

export const Top = (theme) => {
    let commonStyle = {
        maxWidth: "641px",
        borderRadius: "20px",
        backgroundColor: "white",
        padding: "24px 30px 30px 30px",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "20px",
            gap: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Header = {
    fontSize: "24px",
    fontWeight: "600",
    marginBottom: "20px",
};

export const info = {
    paddingTop: "6px",
    fontSize: "14px",
};

export const item = (theme) => {
    let commonStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            flexDirection: "column",
            alignItems: "left",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherQuestionnaireAddFieldsButton = {
    color: "#222",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "150%",
    borderBottom: "1px solid #9E9EB0",
    width: "fit-content",
    padding: "0 0 4px 0",
    marginTop: "40px",
    borderRadius: "0",
    textTransform: "none",
    ":hover": {
        background: "transparent",
    },
};

export const TeacherQuestionnaireStillStudying = {
    marginTop: "16px",
    paddingLeft: "165px",
    display: "flex",
    flexDirection: "row",
    gap: "10px",
};

export const TeacherQuestionnaireStillStudyingTitle = {
    marginTop: "10px",
    fontSize: "14px,",
    fontWeight: "400",
};

export const TeacherQuestionnairePriceSubjects = {
    display: "flex",
    padding: "12px 12px 12px 20px",
    alignItems: "center",
    borderRadius: "10px",
    background: "#F0EFFF",
};

export const NoVideo = (theme) => {
    let commonStyle = {
        padding: "20px",
        backgroundColor: "rgba(187, 187, 200, 0.17)",
        borderRadius: "20px",
        display: "flex",
        gap: "10px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const MainBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const RightBlocks = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const descriptionBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
};

export const horizontalDescriptionBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "50px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            gap: "12px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const verticalDescriptionBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            justifyContent: "flex-start",
            gap: "12px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ImageBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        alignItems: "flex-start",
        gap: "30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "row-reverse",
            justifyContent: "space-between",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ItemContent = (theme) => {
    let commonStyle = {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "12px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Photo = {
    width: "80px",
    borderRadius: "10px",
};

export const TitleAndEdit = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const InfoBlocks = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const AddVideo = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
};

export const VerticalContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const Capitalise = {
    textTransform: "capitalize",
};

export const PriceItem = {
    display: "flex",
    justifyContent: "space-between",
};

export const TextBlock = {
    display: "flex",
    gap: 0,
};

export const ChangePhoto = {
    width: "140px",
    borderRadius: "16px",
};

export const li = {
    color: "#67677A",
};

export const ul = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    paddingLeft: "18px",
    margin: 0,
};

export const EditTitle = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "20px",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const PhotoBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "34px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            gap: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ButtonGroup = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const SButton = {
    width: "fit-content",
};

export const ButtonsBlock = {
    display: "flex",
    gap: "16px",
};

export const FormBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const birthdayItem = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "16px",
};

export const exclamationBlock = {
    display: "flex",
    gap: "8px",
};

export const LinkText = (theme) => {
    let commonStyle = {};

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "185px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TopWithPadding = (theme) => {
    let commonStyle = {
        maxWidth: "598px",
        borderRadius: "20px",
        backgroundColor: "white",
        padding: "24px 73px 30px 30px",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const BottomBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
};

export const ContentProps = {
    sx: {
        background: "#4E4E4E",
        borderRadius: "10px",
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
    },
};

export const WelcomeBackSnackbar = {
    "& .MuiSnackbarContent-root": {
        width: "fit-content",
        minWidth: 0,
    },
};

export const FeedBackBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "52px",
};

export const VideoButton = (theme) => {
    let commonStyle = {
        textTransform: "none",
        maxWidth: "598px",
        width: "100%",
        height: "302px",
        minHeight: "166px",
        borderRadius: "10px",
        background: "#EEEEF0",
        flexDirection: "column",
        alignItems: "center",
        gap: "12px",
        ":hover": {
            backgroundColor: "rgba(240,239,255,0.58)",
        },
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            height: "auto",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const CenteredText = {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    textAlign: "center",
};

export const FixWidthFormItem = (theme) => {
    let commonStyle = {
        width: "424px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,

            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const SwitcherBlock = (theme) => {
    let commonStyle = {
        width: "424px",
        display: "flex",
        gap: "10px",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            maxWidth: "424px",
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ItemWithSwitcher = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "18px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const SwitcherBlockOuter = {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
};

export const BlockWithRemove = {
    display: "flex",
    alignItems: "flex-start",
};

export const FormBlockFixWidth = {
    width: "589px",
    overflow: "visible",
};

export const RemoveButton = {
    cursor: "pointer",
    width: "fit-content",
    height: "fit-content",
};

export const AdditionalBlocks = {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
};

export const itemFlexStart = (theme) => {
    let commonStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            gap: "10px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const PaddingBlock = {
    paddingTop: "18px",
};

export const Separator = {
    borderBottom: "1px dashed #bbbbc8",
    borderTop: "none",
    marginTtop: "40px",
};

export const AimPriceContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const AimPriceLine = (value, theme) => {
    let commonStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: value === true ? "19px" : 0,
        borderBottom: value === true ? "1px solid rgba(0, 0, 0, 0.10)" : "none",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            alignItems: "left",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const AimLinkLine = (value, theme) => {
    let commonStyle = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: value === true ? "19px" : 0,
        borderBottom: value === true ? "1px solid rgba(0, 0, 0, 0.10)" : "none",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,

            alignItems: "left",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const removeAimBlock = {
    display: "flex",
    gap: "8px",
    alignItems: "center",
};

export const priceBlock = (theme) => {
    let commonStyle = {
        width: "186px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
            gap: "11px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const SubjectBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "598px",
};

export const SubjectOuterContainer = {
    display: "flex",
    alignItems: "flex-start",
};

export const Subjects = {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
};

export const LinkFields = (theme) => {
    let commonStyle = {
        display: "flex",
        alignItems: "center",
        gap: "29px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
            alignItems: "left",
            flexDirection: "column",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const LinksColumn = {
    display: "flex",
    flexDirection: "column",
    gap: "39px",
};

export const placesSwitchText = {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
};

export const enterStudentPlaceBox = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    marginTop: "20px",
};

export const Switcher = {
    alignItems: "flex-start",
};

export const DistrictBox = (condition) => {
    return {
        marginTop: condition ? "30px" : "0px",
    };
};

export const verticalBlock = {
    display: "flex",
    flexDirection: "column",
};

export const TimeZoneItem = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const NotificationsItem = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
};

export const NotificationsSwitcher = {
    m: 0,
};

export const CancelButton = {
    width: "fit-content",
};

export const TextButtonDelete = {
    textTransform: "none",
    color: "#6212FF",
    padding: 0,
    marginTop: "24px",
    lineHeight: "21px",
    fontSize: "14px",
    "&:hover": {
        color: "#6212FF",
    },
};

export const TeacherQuestionnaireAutocompleteContainer = (theme) => {
    let commonStyle = {
        width: "424px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
