import React from "react";

const MastercardIcon = () => {
    return (
        <svg
            width="48"
            height="30"
            viewBox="0 0 48 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="0.5"
                width="47"
                height="29"
                rx="4.5"
                stroke="#DADADF"
            />
            <g clipPath="url(#clip0_542_22335)">
                <path
                    d="M28.1289 8.73242H20.8281V21.467H28.1289V8.73242Z"
                    fill="#FF5A00"
                />
                <path
                    d="M21.314 15.1C21.314 12.5127 22.5668 10.2164 24.4892 8.73272C23.0744 7.65272 21.2897 7 19.343 7C14.7314 7 11 10.6227 11 15.1C11 19.5773 14.7314 23.2 19.343 23.2C21.2897 23.2 23.0744 22.5473 24.4892 21.4673C22.5641 20.0046 21.314 17.6873 21.314 15.1Z"
                    fill="#EB001B"
                />
                <path
                    d="M37.9775 15.1C37.9775 19.5773 34.2461 23.2 29.6345 23.2C27.6878 23.2 25.9031 22.5473 24.4883 21.4673C26.435 19.981 27.6635 17.6873 27.6635 15.1C27.6635 12.5127 26.4107 10.2164 24.4883 8.73272C25.9004 7.65272 27.6851 7 29.6318 7C34.2461 7 37.9775 10.6463 37.9775 15.1Z"
                    fill="#F79E1B"
                />
            </g>
            <defs>
                <clipPath id="clip0_542_22335">
                    <rect
                        width="27"
                        height="16.2"
                        fill="white"
                        transform="translate(11 7)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default MastercardIcon;
