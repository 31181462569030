import React, { memo } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as themes from "./AdvCardThemesDefault";
import { ThemeProvider } from "@mui/material/styles";
import { Button } from "@mui/material";
import { EastSharp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useMedias } from "../../API/mediasHook";

const AdvCard = ({ children, ...props }) => {
    const navigate = useNavigate();

    const medias = useMedias();

    return (
        <Box sx={themes.cardContainerStyle(medias)}>
            <Box>
                <Typography sx={themes.headerStyle(medias)}>Поможем подобрать репетитора абсолютно бесплатно</Typography>
                <Typography sx={themes.subHeaderStyle(medias)}>Для этого нужно ответить на вопросы анкеты, и мы составим список репетиторов специально для вас</Typography>
            </Box>
            <ThemeProvider theme={themes.ButtonRedirectTheme(medias)}>
                <Button
                    onClick={() => navigate("/bid")}
                    endIcon={<EastSharp />}
                    disableRipple
                >
                    <Typography style={themes.buttonRedirectTextStyle}>Начать подбор репетитора</Typography>
                </Button>
            </ThemeProvider>
        </Box>
    );
};

export default memo(AdvCard);
