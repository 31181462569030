import React from "react";

const SkillLevelBasic = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="55"
            height="55"
            viewBox="0 0 55 55"
            fill="none"
        >
            <path
                d="M51.5625 44.6875C51.5625 48.4845 48.4845 51.5625 44.6875 51.5625C40.8905 51.5625 37.8125 48.4845 37.8125 44.6875V13.75C37.8125 9.95304 40.8905 6.875 44.6875 6.875C48.4845 6.875 51.5625 9.95304 51.5625 13.75V44.6875ZM41.25 44.6875C41.25 46.586 42.789 48.125 44.6875 48.125C46.586 48.125 48.125 46.586 48.125 44.6875V13.75C48.125 11.8515 46.586 10.3125 44.6875 10.3125C42.789 10.3125 41.25 11.8515 41.25 13.75V44.6875ZM34.375 44.6875C34.375 48.4845 31.297 51.5625 27.5 51.5625C23.703 51.5625 20.625 48.4845 20.625 44.6875V27.5C20.625 23.703 23.703 20.625 27.5 20.625C31.297 20.625 34.375 23.703 34.375 27.5V44.6875ZM24.0625 44.6875C24.0625 46.586 25.6015 48.125 27.5 48.125C29.3985 48.125 30.9375 46.586 30.9375 44.6875V27.5C30.9375 25.6015 29.3985 24.0625 27.5 24.0625C25.6015 24.0625 24.0625 25.6015 24.0625 27.5V44.6875ZM17.1875 44.6875C17.1875 48.4845 14.1095 51.5625 10.3125 51.5625C6.51554 51.5625 3.4375 48.4845 3.4375 44.6875V37.8125C3.4375 34.0155 6.51554 30.9375 10.3125 30.9375C14.1095 30.9375 17.1875 34.0155 17.1875 37.8125V44.6875Z"
                fill="#6212FF"
            />
        </svg>
    );
};

export default SkillLevelBasic;
