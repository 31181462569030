import { createTheme } from "@mui/material/styles";

export const MenuText = (mode) => {
    return {
        color: mode === "Удалить чат" ? "#E12626" : "#222",
        "&:hover": {
            color: mode === "Удалить чат" ? "#E12626" : "#6212FF",
        },
    };
};

export const MenuContainer = {
    transform: "translate(0%, -370%)",
    display: "flex",
    justifyContent: "flex-end",
    padding: "5px 5px 0 0",
};

export const MenuTheme = createTheme({
    components: {
        MuiMenu: {
            styleOverrides: {
                // Name of the slot
                root: {},
                paper: {
                    marginTop: 10,
                    borderRadius: "10px!important",
                    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
                    ".MuiList-root": {
                        display: "flex",
                        width: "max-content",
                        padding: "7px 4px",
                        flexDirection: "column",
                        alignItems: "center",
                    },
                    ".MuiMenuItem-root": {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        alignSelf: "stretch",
                        gap: "12px",
                        padding: "5px 12px",
                        "&:hover": {
                            background: "rgba(187, 187, 200, 0.17)!important",
                            borderRadius: "10px!important",
                        },
                        "&.Mui-selected": {
                            background: "rgba(187, 187, 200, 0.17)!important",
                            borderRadius: "10px!important",
                            "& *": {
                                color: "#6212FF!important",
                            },
                        },
                    },
                },
            },
        },
    },
});
