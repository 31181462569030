const EmptyPhotoIcon = function (props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.3013 36.8457C16.389 36.8457 6.69977 46.535 6.69949 58.4473L6.69922 64.7728C6.69922 67.642 9.05721 70 11.9263 70H58.0732C60.9422 70 63.3004 67.642 63.3005 64.7728L63.3008 58.4473C63.3012 46.535 53.6123 36.8457 41.7003 36.8457H28.3013ZM35 0C26.6859 0 20.0977 7.52992 20.0977 16.5769C20.0977 25.624 26.6859 33.1536 35 33.1536C43.3141 33.1536 49.9023 25.624 49.9023 16.5769C49.9023 7.52992 43.3141 0 35 0Z"
                fill="#BBBBC8"
            />
        </svg>
    );
};

export default EmptyPhotoIcon;
