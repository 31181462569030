import { createTheme } from "@mui/material/styles";

export const menu = createTheme({
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    display: "inline-block",
                    overflow: "hidden",
                    ":hover": {
                        textDecoration: "underline 1.5px wavy #6212FF",
                        textDecorationSkipInk: "none",
                    },
                    ":hover::before": {
                        backgroundColor: "black",
                    },
                },
            },
        },
    },
});

export const BasicInfoContainer = {
    display: "flex",
    gap: "68px",
};

export const BasicInfoBox = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const ReadMoreIcon = {
    position: "flex",
    bottom: "-20px",
    left: "50%",
    padding: "0px",
};

export const FooterBottom = {
    borderTop: "1px solid rgba(255, 255, 255, 0.15)",
    padding: "20px 0",
    display: "flex",
    justifyContent: "space-between",
};

export const FooterMenu = {
    display: "flex",
    flexDirection: "column",
};

export const FooterMenuOuterBox = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const FooterMenus = {
    display: "flex",
    justifyContent: "center",
};

export const FooterMenusContent = (condition) => {
    return {
        borderTop: condition ? "1px solid rgba(255, 255, 255, 0.15)" : "none",
        paddingTop: "60px",
        width: "1216px",
        display: "flex",
        flexDirection: "column",
        gap: "60px",
    };
};

export const FooterMenusAndSocial = {
    display: "flex",
    justifyContent: "space-between",
};

export const FooterMenusContainer = {
    display: "flex",
    gap: "104px",
};

export const Footer = {
    position: "absolute",
    zIndex: "-1",
    backgroundColor: "#170F26",
    width: "100%",
    marginTop: "-40px",
    paddingTop: "40px",
};

export const FooterExpandable = (open) => {
    return {
        padding: "108px 0 0 0",
        overflow: "hidden",
        position: "relative",
        height: open ? "350px" : "fit-content",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    };
};

export const FooterExpandableContent = {
    maxWidth: "1216px",
    display: "flex",
    flexDirection: "column",
    gap: "60px",
};

export const FooterExpandableTitleAndInfo = {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
};

export const QueryBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    maxWidth: "945px",
};

export const Gradient = {
    position: "absolute",
    width: "100%",
    marginTop: "-200px",
    height: "200px",
    background: "linear-gradient(180deg, rgba(23, 15, 38, 0.00) 0%, #170F26 80%);",
};

export const FooterBottomRight = {
    display: "flex",
    gap: "138px",
};

export const SocialNetworks = {
    display: "flex",
    alignItems: "center",
    height: "fit-content",
    gap: "20px",
};

export const IconButton = {
    padding: 0,
    margin: 0,
    height: "fit-content",
};

export const NetworksButtons = {
    display: "flex",
    gap: "10px",
};
