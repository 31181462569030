import * as themes from "./ErrorAlternativeButtonsThemes";
import { ThemeProvider } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import * as textThemes from "themes/TextThemes";
import React from "react";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ErrorAlternativeButtons = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const handleClickCreateOrder = () => {
        navigate("/bid");
    };

    const handleClickCatalog = () => {
        navigate("/catalog");
    };

    const handleClickBecomeTutor = () => {
        navigate("/promotutor");
    };

    const handleClickCashback = () => {
        navigate("/cashback");
    };

    const handleClickInviteFrined = () => {
        navigate("/referral");
    };

    return (
        <Box sx={themes.ServiceBlock}>
            <ThemeProvider theme={themes.ServiceButton}>
                <Button
                    disableRipple
                    onClick={handleClickCreateOrder}
                >
                    <Typography sx={{ ...textThemes.Body1Black, ...themes.ServiceButtonText }}>{t("errors.createOrder")}</Typography>
                </Button>
            </ThemeProvider>
            <ThemeProvider theme={themes.ServiceButton}>
                <Button
                    disableRipple
                    onClick={handleClickCatalog}
                >
                    <Typography sx={{ ...textThemes.Body1Black, ...themes.ServiceButtonText }}>{t("errors.tutorsCatalog")}</Typography>
                </Button>
            </ThemeProvider>
            <ThemeProvider theme={themes.ServiceButton}>
                <Button
                    disableRipple
                    onClick={handleClickBecomeTutor}
                >
                    <Typography sx={{ ...textThemes.Body1Black, ...themes.ServiceButtonText }}>{t("errors.becomeTutor")}</Typography>
                </Button>
            </ThemeProvider>
            <ThemeProvider theme={themes.ServiceButton}>
                <Button
                    disableRipple
                    onClick={handleClickCashback}
                >
                    <Typography sx={{ ...textThemes.Body1Black, ...themes.ServiceButtonText }}>{t("errors.cashback")}</Typography>
                </Button>
            </ThemeProvider>
            <ThemeProvider theme={themes.ServiceButton}>
                <Button
                    disableRipple
                    onClick={handleClickInviteFrined}
                >
                    <Typography sx={{ ...textThemes.Body1Black, ...themes.ServiceButtonText }}>{t("errors.inviteFriend")}</Typography>
                </Button>
            </ThemeProvider>
        </Box>
    );
};

export default memo(ErrorAlternativeButtons);
