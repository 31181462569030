import { styleProcessing } from "API/plugins/Utilities";

export const TopBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "20px",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            alignItems: "left",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TutorSettings = (theme) => {
    let commonStyle = {
        borderRadius: "0px 0px 40px 40px",
        background: "#F7F7F7",
        display: "flex",
        justifyContent: "center",
        paddingBottom: "70px",
        boxSizing: "border-box",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            paddingBottom: "40px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TutorSettingsContent = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        maxWidth: "1216px",
        width: "100%",
        gap: "40px",
        paddingTop: "46px",
        minHeight: "780px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "20px 16px 0px 16px",
            boxSizing: "border-box",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Title = {
    width: "289px",
};
