import React from "react";
import ReactPlayer from "react-player";
import { memo } from "react";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { observer } from "mobx-react-lite";
import * as themes from "./VideoPlayerThemes";
import { Grid, IconButton, Modal } from "@mui/material";
import Close from "assets/CommonAssets/SVG/close";
import { useMedias } from "API/mediasHook";
import "./Video.css";

const VideoPlayer = observer(({ children, ...props }) => {
    const medias = useMedias();

    const [hovered, setHovered] = React.useState(false);

    const handleClose = () => {
        props.handleDiscard();
        setHovered(false);
    };

    return (
        <Modal
            open={props.open}
            onClose={handleClose}
            sx={themes.ModalSize(medias, props.width)}
        >
            <Grid sx={themes.modalSettingsSmall}>
                <IconButton
                    onClick={handleClose}
                    disableRipple
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    <Close hovered={hovered} />
                </IconButton>
                <ReactPlayer
                    className="react-player-custom"
                    url={TeacherQuestionnaireStore.teacher.video}
                    playing={false}
                    controls={true}
                />
            </Grid>
        </Modal>
    );
});

export default memo(VideoPlayer);
