import * as React from "react";
import { IconButton, Menu, Typography } from "@mui/material";
import EmojiPicker, { Emoji } from "emoji-picker-react";
import * as themes from "./IncomingMessageCardThemes";
import { observer } from "mobx-react-lite";
import { memo, useState } from "react";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import useWebSocket from "react-use-websocket";
import FullChatStore from "store/FullChatStore";
import MessageAttachments from "../OutcomingMessageCard/MessageAttachments/MessageAttachments";
import ModalCarousel from "components/UI/ModalCarousel/ModalCarousel";
import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";
import VideoChatStore from "store/VideoChatStore";

const IncomingMessageCard = observer(({ children, ...props }) => {
    const URL = "wss://study-buddy.ru/chats/ws";

    const { sendJsonMessage } = useWebSocket(URL, {
        share: true,
    });

    const [dialogAttachmentsCarouselOpen, SetDialogAttachmentsCarouselOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const showAttachmentsCarouselDialog = (event) => {
        event.stopPropagation();
        SetDialogAttachmentsCarouselOpen(true);
    };

    const discardAttachmentsCarouselDialog = () => {
        SetDialogAttachmentsCarouselOpen(false);
    };

    const iconList = ["1f44d", "2b50", "2705"];

    const handleMenuToggle = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (value, event) => {
        if (value !== null) {
            if (value.icon) {
                let data = {
                    chat_id: props.chat_id,
                    message_id: props.message.message_id,
                };

                FullChatStore.AddReaction(value.icon, data, sendJsonMessage);
            } else {
                let callback = null;

                switch (value.action.ID) {
                    case 1:
                        callback = handleReplyCallback;
                        break;
                    default:
                        break;
                }

                FullChatStore.ExecuteAction(value.action, null, callback);
            }
        }

        setAnchorEl(null);
    };

    const removeReaction = () => {
        let data = {
            chat_id: props.chat_id,
            message_id: props.message.message_id,
        };

        FullChatStore.RemoveReaction(data, sendJsonMessage);
    };

    const handleReplyCallback = () => {
        props.diploma ? DiplomaStore.SetSelectedMessage(props.message) : props.video ? VideoChatStore.SetSelectedMessage(props.message) : FullChatStore.SetSelectedMessage(props.message);
        props.handleText(props.message.message);
        props.handleOpenReplyModalMessage(true);
        props.handleDisable(true);
    };

    return (
        <Box sx={themes.incomingMessageContainerStyle}>
            <Box sx={themes.avatarContainerStyle}>
                <ThemeProvider theme={themes.AvatarTheme}>
                    <Avatar src={props.message.avatar}></Avatar>
                </ThemeProvider>
            </Box>
            <Box
                sx={themes.messageContainerStyle}
                onContextMenu={handleMenuToggle}
            >
                {props.message.medias && (
                    <Box onClick={showAttachmentsCarouselDialog}>
                        <MessageAttachments message={props.message}></MessageAttachments>
                    </Box>
                )}
                <Typography
                    sx={themes.messageTextStyle}
                    component={"div"}
                >
                    {props.reply_to_message && (
                        <Box sx={themes.replyContainerStyle}>
                            <Typography sx={themes.nameTextStyle}>{props.message.owner.name}</Typography>
                            <Typography sx={themes.replyTextStyle}>{props.reply_to_message}</Typography>
                        </Box>
                    )}
                    {props.message.message}
                    <Box sx={themes.timeContainerStyle(props.message.reactions?.length > 0 && props.message.reactions[0]?.reaction)}>
                        {props.message.reactions?.length > 0 && props.message.reactions[0]?.reaction && (
                            <ThemeProvider theme={themes.IconTheme}>
                                <IconButton
                                    onClick={removeReaction}
                                    disableRipple
                                >
                                    <Emoji
                                        unified={props.message.reactions?.length > 0 && props.message.reactions[0]?.reaction}
                                        size="25"
                                    />
                                </IconButton>
                            </ThemeProvider>
                        )}
                        <Typography sx={themes.timeTextStyle}>{props.message.edited ? "изменено " + props.message.time : props.message.time}</Typography>
                    </Box>
                </Typography>
            </Box>
            <ThemeProvider theme={themes.MenuTheme}>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose.bind(this, null)}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <Box sx={themes.menuBodyStyle}>
                        <Box sx={themes.reactionsContainerStyle}>
                            {iconList.map((icon) => (
                                <MenuItem
                                    key={icon}
                                    onClick={handleMenuClose.bind(this, { icon: icon })}
                                    disableRipple
                                >
                                    <Emoji
                                        unified={icon}
                                        size="25"
                                    />
                                </MenuItem>
                            ))}
                        </Box>
                        <Box sx={themes.actionsContainerStyle}>
                            <ThemeProvider theme={themes.MenuItemActionsTheme}>
                                {[FullChatStore.messageActionsOptions.at(1)].map((value, index) => (
                                    <MenuItem
                                        key={value.ID}
                                        onClick={handleMenuClose.bind(this, { action: value })}
                                        disableRipple
                                    >
                                        <Typography sx={themes.actionTextStyle}>{value.actionText}</Typography>
                                    </MenuItem>
                                ))}
                            </ThemeProvider>
                        </Box>
                    </Box>
                </Menu>
            </ThemeProvider>
            {props.message.medias && (
                <ModalCarousel
                    open={dialogAttachmentsCarouselOpen}
                    handleDiscard={discardAttachmentsCarouselDialog}
                    content={props.message.medias}
                ></ModalCarousel>
            )}
        </Box>
    );
});

export default memo(IncomingMessageCard);
