import React, { memo, useEffect } from "react";
import * as HowWorkStyle from "./HowWorkStyle";
import LeftCardBlock from "./LeftCardBlock/LeftCardBlock";
import RightCardBlock from "./RightCardBlock/RightCardBlock";
import { observer } from "mobx-react-lite";
import MainPageStore from "store/MainPageStore";
import { Box } from "@mui/material";
import { useMedias } from "API/mediasHook";
import { useTranslation } from "react-i18next";

const HowWork = observer(({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const interval = setInterval(() => {
            MainPageStore.SetActiveHowWorkItem(null, "auto");
        }, 3000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            MainPageStore.SetActiveHowWorkItem(null, "auto");
        }, 3000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const medias = useMedias();

    return (
        <Box sx={HowWorkStyle.HowWorkMainBlock(medias)}>
            <Box sx={HowWorkStyle.HowWorkSubBlock(medias)}>
                <Box sx={HowWorkStyle.HowWorkTitle(medias)}>{t("promoPages.main.howItWorks")}</Box>
                <LeftCardBlock />
                <RightCardBlock />
            </Box>
        </Box>
    );
});

export default memo(HowWork);
