import { createTheme } from "@mui/material/styles";

export const bodyCardStyle = (medias) => {
    return {
        // Some CSS
        boxShadow: "0px 4px 30px rgba(106, 99, 118, 0.05)",
        borderRadius: "0px 0px 40px 40px",
        background: "#F3F3F3",
        width: "100%",
        height: "fit-content",
        display: "flex",
        gap: "20px",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        minHeight: "calc(100vh - 589px)",
    };
};

export const teacherFoundStyle = (medias) => {
    return {
        width: 1216,
        height: 27,
        marginLeft: medias.sm ? "auto" : "17px",
        marginRight: "auto",
        marginTop: 50,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 18,
        lineHeight: "150%",
        display: "flex",
        alignItems: "center",
        color: "#222222",
    };
};

export const teacherNotFoundContainerStyle = (medias) => {
    return {
        marginTop: 50,
        marginLeft: medias.sm ? "auto" : "17px",
        marginRight: "auto",
        width: 1216,
    };
};

export const teacherNotFoundTextContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    marginBottom: 40,
};

export const teacherNotFoundHeaderStyle = (medias) => {
    return {
        color: "var(--text-black, #222)",
        fontSize: medias.sm ? "32px" : "18px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "140%",
    };
};

export const teacherNotFoundSubHeaderStyle = (medias) => {
    return {
        color: "var(--text-black, #222)",
        fontSize: medias.sm ? "16px" : "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "150%",
    };
};

export const teacherNotFoundLastHeaderStyle = (medias) => {
    return {
        color: "var(--text-black, #222)",
        fontSize: medias.sm ? "32px" : "18px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "140%",
        marginBottom: 40,
    };
};

export const teacherContainerStyle = (medias) => {
    return {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: 0,
        gap: 20,
        width: medias.sm ? 1216 : "fit-content",
        height: "fit-content",
        marginLeft: "auto", //medias.sm ? "auto" : "16px",
        marginRight: "auto", //medias.sm ? "auto" : "16px",
    };
};

export const separatorContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
};

export const teacherShowMoreTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                    color: "var(--text-black, #222)!important",
                    fontSize: "16px!important",
                    fontWeight: "500!important",
                    lineHeight: "150%!important",
                    textTransform: "none!important",
                    "&:hover": {
                        background: "var(--main-purple, #6212FF)!important",
                        color: "#FFFFFF!important",
                    },
                    "&.Mui-disabled": {
                        color: "gray!important",
                    },
                },
            },
        },
    },
});

export const horizontalSeparatorStyle = {
    width: "139px",
    height: "1px",
    background: "#9E9EB0",
};

export const paginationContainerStyle = {
    marginTop: "16px",
    marginBottom: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
};

export const pagesContainerStyle = {
    display: "flex",
    alignItems: "center",
    gap: "6px",
};

export const paginationTheme = createTheme({
    components: {
        MuiPagination: {
            styleOverrides: {
                root: {
                    ".MuiButtonBase-root": {
                        display: "flex",
                        padding: "2px 7px",
                        width: "23px",
                        height: "25px",
                        minWidth: "23px",
                        margin: 0,
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                        "&:hover": {
                            background: "var(--main-purple, #6212FF)!important",
                            color: "#FFFFFF",
                        },
                        "&.Mui-selected": {
                            background: "var(--main-purple, #6212FF)",
                            color: "#FFFFFF",
                        },
                    },
                },
                ul: {
                    gap: "2px",
                },
            },
        },
    },
});

export const filtersTagsContainerStyle = (medias) => {
    return {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: medias.sm ? "10px" : "8px",
        marginLeft: "0",
        marginBottom: medias.sm ? "60px" : "40px",
    };
};

export const ClearChipTheme = (medias) => {
    return {
        components: {
            MuiChip: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingBottom: "11px",
                        paddingTop: "11px",
                        paddingRight: "16px",
                        paddingLeft: "16px",
                        gap: "4px",
                        width: "fit-content",
                        height: medias.sm ? "45px" : "32px",
                        background: "#FFFFFF",
                        border: "1px solid #9E9EB0",
                        borderRadius: "8px!important",
                        ".MuiChip-deleteIcon": {
                            width: "16px",
                            height: "16px",
                            flex: "none",
                            order: 1,
                            flexGrow: 0,
                        },
                        ".MuiIconButton-root": {
                            margin: 0,
                            color: "#4E4E4E!important",
                        },
                        ".MuiSvgIcon-root": {
                            width: "16px",
                            height: "16px",
                        },
                        ".MuiIconButton-root:hover": {
                            color: "#6212FF!important",
                            background: "white",
                        },
                    },
                    label: {
                        width: "fit-content",
                        height: "18px",
                        fontStyle: "normal",
                        padding: 0,
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "130%",
                        fontFeatureSettings: "'tnum' on, 'lnum' on",
                        color: "#222222",
                        flex: "none",
                        order: 0,
                        flexGrow: 0,
                    },
                },
            },
        },
    };
};

export const AllChipTheme = (medias) => {
    return {
        components: {
            MuiChip: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingBottom: "11px",
                        paddingTop: "11px",
                        paddingRight: "16px",
                        paddingLeft: "16px",
                        gap: "4px",
                        width: "fit-content",
                        height: medias.sm ? "45px" : "32px",
                        background: "#4E4E4E",
                        borderRadius: "8px!important",
                        ".MuiChip-deleteIcon": {
                            width: "16px",
                            height: "16px",
                            flex: "none",
                            order: 1,
                            flexGrow: 0,
                        },
                        ".MuiIconButton-root": {
                            margin: 0,
                            color: "#ffffff!important",
                        },
                        ".MuiSvgIcon-root": {
                            width: "16px",
                            height: "16px",
                        },
                        ".MuiIconButton-root:hover": {
                            color: "#ffffff!important",
                        },
                    },
                    label: {
                        width: "fit-content",
                        height: "18px",
                        fontStyle: "normal",
                        padding: 0,
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "130%",
                        fontFeatureSettings: "'tnum' on, 'lnum' on",
                        color: "#FFFFFF",
                        flex: "none",
                        order: 0,
                        flexGrow: 0,
                    },
                },
            },
        },
    };
};

export const progressContainerStyle = {
    width: "100%",
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
};

export const CircularProgressTheme = createTheme({
    components: {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: "#6212FF",
                },
            },
        },
    },
});
