import { createTheme } from "@mui/material/styles";

export const tcAvatarAreaStyle = (medias, styleMode) => {
    if (styleMode === 0) {
        return {
            width: medias.sm ? "180px" : "90px",
            height: medias.sm ? "260px" : "130px",
            flex: "none",
            order: 0,
            flexGrow: 0,
            display: medias.sm ? "unset" : "flex",
            flexDirection: medias.sm ? "unset" : "row",
        };
    }

    if (styleMode === 1) {
        return {
            width: medias.sm ? "180px" : "320px",
            height: medias.sm ? "260px" : "unset",
            flex: "none",
            order: 0,
            flexGrow: 0,
            display: medias.sm ? "unset" : "flex",
            flexDirection: medias.sm ? "unset" : "column",
            alignItems: "center",
            gap: 16,
        };
    }
};

export const anonymousAvatarContainer = (id, medias) => {
    return {
        boxSizing: "border-box",
        width: medias.sm ? "180px" : "90px",
        height: medias.sm ? "260px" : "130px",
        border: "1px dashed #BBBBC8",
        borderRadius: "16px",
        flex: "none",
        order: 5,
        flexGrow: 0,
        zIndex: 5,
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        flexWrap: "nowrap",
        flexDirection: "column",
        alignItems: "center",
        cursor: !id ? "pointer" : "default",
    };
};

export const anonymousAvatarTextStyle = (medias) => {
    return {
        width: "111.61px",
        textAlign: "center",
        color: "#67677A",
        fontSize: medias.sm ? "1rem" : "0.6rem",
    };
};

export const tcPlayIconBGStyle = {
    position: "relative",
    width: "40px",
    height: "40px",
    left: "10px",
    bottom: "50px",
    borderRadius: "50%",
    background: "rgba(255, 255, 255, 0.5)",
    cursor: "pointer",
};

export const TCAvatarThemeClickable = (medias) => {
    return {
        components: {
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        position: "relative",
                        width: medias.sm ? "180px" : "90px",
                        height: medias.sm ? "260px" : "130px",
                        left: "0px",
                        top: "0px",
                        borderRadius: "16px",
                        cursor: "pointer",
                    },
                },
            },
        },
    };
};

export const TCAvatarThemeUnclickable = (medias) => {
    return {
        components: {
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        position: "relative",
                        width: medias.sm ? "180px" : "90px",
                        height: medias.sm ? "260px" : "130px",
                        left: "0px",
                        top: "0px",
                        borderRadius: "16px",
                        cursor: "default",
                    },
                },
            },
        },
    };
};

export const TCPlayIconTheme = createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    position: "relative",
                    left: "21%",
                    right: "40%",
                    top: "22%",
                    bottom: "30%",
                    color: "#6212FF",
                },
            },
        },
    },
});
