import React, { memo } from "react";
import * as themes from "./StoriesThemes";
import { Typography } from "@mui/material";
import Slider from "components/AccountBodyBlock/StudentAccount/Stories/Slider/Slider";
import Box from "@mui/material/Box";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import { HeadlineH5LightBlack } from "themes/TextThemes";
import { useMedias } from "API/mediasHook";
const Stories = ({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.StoriesBlock(medias)}>
            {medias.sm && (
                <Box sx={themes.TitleBox}>
                    <Typography sx={textThemes.HeadlineH5Black}>{t("teacherAccount.stories.forYou")}</Typography>
                </Box>
            )}
            <Slider />
        </Box>
    );
};

export default memo(Stories);
