import { memo } from "react";
import * as themes from "./ProfileInactiveThemes";
import * as textThemes from "themes/TextThemes";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SButton from "components/UI/SButton";
import { useNavigate } from "react-router-dom";
import ProfileInactiveIcon from "assets/TeacherAccountAssets/SVG/ProfileInactiveIcon";
import { useMedias } from "API/mediasHook";

const ProfileInactive = ({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const handleClickTutorRules = () => {
        navigate("/buddyrules");
    };

    return (
        <Box sx={themes.ProfileInactive}>
            <Box sx={{ position: "absolute" }}>
                <ProfileInactiveIcon />
            </Box>
            <Box sx={themes.RightPanel}>
                <Box sx={themes.TopBlock}>
                    <Typography sx={textThemes.HeadlineH5Black}>{t("teacherAccount.miniWidgets.profileInactive")}</Typography>
                    <Typography sx={textThemes.Body2Black}>{t("teacherAccount.miniWidgets.whileQuestionnaireOnCheck")}</Typography>
                </Box>
                <SButton
                    style={themes.Button(medias)}
                    variant="arrow"
                    padding="10px 20px 11px 20px"
                    onClick={handleClickTutorRules}
                >
                    {t("teacherAccount.miniWidgets.serviceRules")}
                </SButton>
            </Box>
        </Box>
    );
};

export default memo(ProfileInactive);
