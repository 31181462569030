import React from "react";

const EditIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <g clipPath="url(#clip0_2558_65544)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.7096 2.43099C11.8898 2.25082 12.1341 2.14961 12.3889 2.14961C12.5151 2.14961 12.64 2.17446 12.7566 2.22274C12.8731 2.27102 12.979 2.34178 13.0682 2.43099C13.1575 2.5202 13.2282 2.6261 13.2765 2.74266C13.3248 2.85922 13.3496 2.98414 13.3496 3.1103C13.3496 3.23646 13.3248 3.36139 13.2765 3.47794C13.2282 3.5945 13.1575 3.7004 13.0682 3.78961L3.70446 13.1534L1.89296 13.6063L2.34584 11.7948L11.7096 2.43099ZM12.3889 0.849609C11.7894 0.849609 11.2143 1.08779 10.7904 1.51175L1.29929 11.0028C1.21599 11.0861 1.15689 11.1905 1.12832 11.3048L0.369031 14.342C0.313655 14.5635 0.378556 14.7978 0.540004 14.9592C0.701451 15.1207 0.935767 15.1856 1.15727 15.1302L4.19442 14.3709C4.30871 14.3423 4.41309 14.2832 4.49639 14.1999L13.9875 4.70885C14.1974 4.49893 14.3639 4.24971 14.4775 3.97543C14.5911 3.70115 14.6496 3.40718 14.6496 3.1103C14.6496 2.81342 14.5911 2.51945 14.4775 2.24517C14.3639 1.97089 14.1974 1.72168 13.9875 1.51175C13.7776 1.30183 13.5283 1.1353 13.2541 1.02169C12.9798 0.908084 12.6858 0.849609 12.3889 0.849609ZM7.99962 13.8496C7.64064 13.8496 7.34962 14.1406 7.34962 14.4996C7.34962 14.8586 7.64064 15.1496 7.99962 15.1496H14.9996C15.3586 15.1496 15.6496 14.8586 15.6496 14.4996C15.6496 14.1406 15.3586 13.8496 14.9996 13.8496H7.99962Z"
                    fill="#4E4E4E"
                />
            </g>
            <defs>
                <clipPath id="clip0_2558_65544">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default EditIcon;
