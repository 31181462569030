import React from "react";

import TutorFee from "./TutorFee";
import PaymentInfo from "./PaymentInfo";
import InfoAboutTutor from "./InfoAboutTutor";
import InfoAboutTutorAcc from "./InfoAboutTutorAcc";

const OrderInfoForTutor = () => {
    return (
        <>
            <InfoAboutTutor />
            <InfoAboutTutorAcc />
            <TutorFee />
            <PaymentInfo />
        </>
    );
};

export default OrderInfoForTutor;
