import { createTheme } from "@mui/material/styles";

export const manageCallContainerStyle = {
    display: "flex",
    alignItems: "center",
    gap: 10,
};

export const callRecordingContainerStyle = {
    display: "flex",
    alignItems: "center",
    gap: 8,
};

export const CallRecordIconTheme = createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: "#E12626",
                },
            },
        },
    },
});

export const iconContainerStyle = (mode) => {
    let background = "";

    switch (Number(mode)) {
        case 0:
            background = "#6212FF0C";
            break;
        case 1:
            background = "#6212FF";
            break;
        case 2:
            background = "var(--Secondary-Red, #E12626)";
            break;
        default:
            break;
    }

    return {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 40,
        height: 40,
        borderRadius: "50%",
        background: background,
        cursor: "pointer",
        ":hover": {
            background: mode === 2 ? "var(--Secondary-Red, #E12626)" : "#6212FF",
        },
    };
};

export const ActionButtonTheme = (mode, state) => {
    let fillColor = "";

    if (mode === 0) {
        if (Number(state) === 1) {
            fillColor = "var(--main-white, #FFF)";
        } else {
            fillColor = "#6212FF";
        }
    }

    if (mode === 1) {
        if (Number(state) === 1) {
            fillColor = "var(--main-white, #FFF)";
        } else {
            fillColor = "#6212FF";
        }
    }

    if (mode === 2) {
        fillColor = "var(--main-white, #FFF)";
    }

    return createTheme({
        components: {
            MuiIconButton: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        "*": {
                            fill: fillColor,
                        },
                        "&:hover *": {
                            fill: "var(--main-white, #FFF)",
                        },
                    },
                },
            },

            MuiTooltip: {
                styleOverrides: {
                    root: {},
                    tooltip: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        padding: "3px 10px 4px 10px",
                        boxSizing: "border-box",
                        marginBottom: "5px!important",
                        background: "var(--BG-Footer30, rgba(23, 15, 38, 0.30))",
                        borderRadius: "42px",
                        backdropFilter: "blur(3.4033873081207275px)",
                    },
                },
            },
        },
    });
};

export const tooltipStyle = {
    color: "var(--Text-White, #FFF)",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
};

export const callTimerStyle = {
    width: 60,
    color: "var(--Text-Black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const CallMenuTheme = createTheme({
    components: {
        MuiMenu: {
            styleOverrides: {
                // Name of the slot
                root: {},
                paper: {
                    marginTop: -15,
                    borderRadius: "10px!important",
                    ".MuiList-root": {
                        display: "flex",
                        width: "max-content",
                        padding: 0,
                        flexDirection: "column",
                        alignItems: "flex-start",
                    },
                    ".MuiMenuItem-root": {
                        minWidth: 260,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        alignSelf: "stretch",
                        gap: 10,
                        padding: "5px 12px",
                    },
                },
            },
        },
    },
});

export const callMenuContainerStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "16px 20px 16px 20px",
    boxSizing: "border-box",
    gap: "15px",
    width: 256,
    borderRadius: "10px",
    background: "var(--Main-White, #FFF)",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
};

export const endCallTextStyle = {
    color: "var(--Text-Black, #222)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const callMenuActionsContainerStyle = {
    display: "flex",
    gap: "10px",
};

export const CallModalActionButtonTheme = (mode) =>
    createTheme({
        components: {
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        display: "flex",
                        padding: "7px 16px 8px 16px!important",
                        width: "fit-content",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        borderRadius: "42px!important",
                        background: mode === 0 ? "var(--grey-separator, #EEEEF0)" : "var(--main-purple, #6212FF)",
                        "&:hover": {
                            background: "#6212FF!important",
                            borderColor: "#6212FF!important",
                            ".MuiTypography-root": {
                                color: "white!important",
                            },
                        },
                        "&:active": {
                            background: "#6212FF!important",
                            borderColor: "#6212FF!important",
                            ".MuiTypography-root": {
                                color: "white!important",
                            },
                        },
                    },
                },
            },
        },
    });

export const callButtonTextStyle = (mode) => {
    return {
        textTransform: "none",
        color: mode === 0 ? "var(--text-black, #222)" : "var(--text-white, #FFF)",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "150%",
    };
};
