import { Box, Typography } from "@mui/material";
import FilterOperationType from "./FilterOperationType/FilterOperationType";
import BalanceLineSeparator from "assets/CommonAssets/SVG/BalanceLineSeparator";
import FilterOperationDate from "./FilterOperationDate/FilterOperationDate";
import UserStore from "store/UserStore";
import balanceStore from "store/BalanceStore";
import React from "react";
import * as styles from "./FilterPanelThemes";
import * as textStyles from "themes/TextThemes";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useMedias } from "API/mediasHook";
import BonusBox from "../BonusBox/BonusBox";

const FilterPanel = observer(({ alignment }) => {
    const navigate = useNavigate();

    const medias = useMedias();

    const { t } = useTranslation();

    return (
        <Box>
            {alignment === "OperationsTable" && (
                <Box sx={styles.FilterContainer(medias)}>
                    <FilterOperationType />
                    {medias.sm && <BalanceLineSeparator />}
                    <FilterOperationDate />
                </Box>
            )}
            {alignment === "RefTable" && (
                <Box sx={styles.RefStatContainer(medias)}>
                    <Box sx={styles.PointsContainter(medias)}>
                        <Typography sx={[textStyles.Body2Black, styles.ReferalPointsContainterText(medias)]}>
                            {UserStore.role === "student" ? t("main.balance.points1") : t("main.balance.points2")}:&nbsp;
                            <Typography
                                underline="none"
                                sx={[textStyles.Body2Black, styles.PointsLink(balanceStore.refPoints > 0)]}
                            >
                                {balanceStore.refPoints}
                            </Typography>
                        </Typography>
                        {medias.sm && <BalanceLineSeparator />}
                        <Typography sx={[textStyles.Body2Black, styles.ReferalPointsContainterText(medias)]}>
                            {UserStore.role === "student" ? t("main.balance.invitedFriends") : t("main.balance.invitedUsers")}:&nbsp;
                            <Typography
                                underline="none"
                                sx={[textStyles.Body2Black, styles.PointsLink(balanceStore.refPoints > 0)]}
                            >
                                {balanceStore.invitedFriends}
                            </Typography>
                        </Typography>
                    </Box>
                    <Typography
                        sx={[textStyles.Caption2Purple, styles.ReferralProgramTermsLink(medias)]}
                        onClick={() => navigate("/referral")}
                    >
                        {t("main.balance.referralProgramTerms")}
                    </Typography>
                </Box>
            )}
            <>{!medias.sm && <>{alignment === "balanceBox" && <>{UserStore.role === "student" && <BonusBox />}</>}</>}</>
        </Box>
    );
});

export default memo(FilterPanel);
