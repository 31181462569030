import { useState } from "react";

const useWeekDates = () => {
    const [currentDate, setCurrentDate] = useState(new Date());

    const getWeekDates = (startDate) =>
        [...Array(7)].map((_, i) =>
            new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000).toLocaleDateString("ru-RU", {
                day: "numeric",
                month: "long",
                year: "numeric",
            }),
        );

    const startOfWeek = new Date(currentDate);

    startOfWeek.setHours(0, 0, 0, 0);
    startOfWeek.setDate(currentDate.getDate() - ((currentDate.getDay() + 6) % 7));

    const updateCurrentDate = (offset) => setCurrentDate(new Date(currentDate.getTime() + offset));

    const addWeekToDate = () => updateCurrentDate(7 * 24 * 60 * 60 * 1000);

    const backWeekToDate = () => updateCurrentDate(-7 * 24 * 60 * 60 * 1000);

    const backToCurrentDate = () => setCurrentDate(new Date());

    return {
        weekDates: getWeekDates(startOfWeek),
        addWeekToDate,
        backWeekToDate: currentDate > new Date() ? backWeekToDate : null,
        backToCurrentDate,
        currentDate,
    };
};

export default useWeekDates;
