export const toggleButtonGroupStyles = {
    borderRadius: "30px",
    display: "flex",
    gridGap: "10px",
};

export const toggleButtonSXStyles = {
    "&.MuiToggleButton-root:hover": {
        color: "#4E4E4E",
        backgroundColor: "transparent",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "#fff",
        bgcolor: "#222",
        border: "none",
    },
    color: "#4E4E4E",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
    width: "fit-content",
    padding: "8px 16px 10px 16px",
    alignItems: "center",
    textTransform: "none",
    // borderRadius:'30px',
    // border: '1px solid var(--text-dark-grey, #67677A)',
    ml: "0px",
};
