import { createTheme } from "@mui/material/styles";

export const progressContainerStyle = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
};

export const CircularProgressTheme = createTheme({
    components: {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: "#6212FF",
                },
            },
        },
    },
});
