import { styleProcessing } from "API/plugins/Utilities";

export const textContainer = (theme) => {
    let commonStyle = {
        display: "flex",
        width: "701px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "30px",
        marginTop: "20px",
        marginBottom: "60px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, width: "328px", gap: "20px", marginTop: "0px", marginBottom: "0px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const mainHeader = (theme) => {
    let commonStyle = {
        display: "flex",
        width: "701px", //703
        flexDirection: "column",
        marginTop: 40,
        color: "#222",
        fontSize: "60px",
        fontFamily: "Arial",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "130%",
        margin: "0",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, width: "315x", fontSize: "32px", whiteSpace: "pre-line" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const supHeader = (theme) => {
    let commonStyle = {
        display: "flex",
        width: "597px",
        flexDirection: "column",
        margin: "0",
        color: "#222",
        fontSize: "16px",
        fontFamily: "Arial",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "150%",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { commonStyle, width: "328px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

//  export const supHeader = {
//     display: 'flex',
//     width: '597px',
//     flexDirection: 'column',
//     margin:'0',
//
//     color: '#222',
//     fontSize: '16px',
//     fontFamily: 'Arial',
//     fontStyle: 'normal',
//     fontWeight: '400',
//     lineHeight: '150%',
// }

/*
    font-family: "Inter-SemiBold", Helvetica,
*/
