import { styleProcessing } from "API/plugins/Utilities";

export const TutorClasses = (theme) => {
    let commonStyle = {
        borderRadius: "0px 0px 40px 40px",
        background: "#F7F7F7",
        paddingTop: "40px",
        paddingBottom: "70px",
        minHeight: "800px",
        display: "flex",
        justifyContent: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "24px 16px 40px 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TutorClassesContent = (theme) => {
    let commonStyle = {
        maxWidth: "1218px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "60px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "40px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Header = {
    fontSize: "32px",
    fontWeight: "600",
};

export const NextClassBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const ClassesBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
};

export const TabsAndClasses = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "10px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
