export const CashbackDefFuncBlock = {
    width: "289px",
    height: "179px", //'110px',//
    display: "inline-flex",
    padding: "20px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "12px",
    borderRadius: "20px",
    background: "#FFF",
    boxSizing: "border-box",
};

export const CashbackFuncText = {
    width: "250px",
};
