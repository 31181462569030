export const SettingsTabs = {
    p: 0,
    m: "30px 0 0 0",
    border: "none",
    "& .MuiTabs-flexContainer": {
        display: "flex",
        gap: "20px",
    },
    "& button.Mui-selected": { color: "#222" },
    "& button.Mui-selected:hover": { color: "#222" },
    "& button:hover": { color: "#222", outline: "none" },
    "& button:active": { color: "#222" },
};

export const TabIndicatorProps = {
    padding: "1px",
    backgroundColor: "#6212FF",
    bottom: 6,
    boxSizing: "border-box",
};

export const SettingsTab = {
    minWidth: 0,
    p: 0,
    m: 0,
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "150%",
    color: "#9E9EB0",
};
