import React from "react";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import Error404BodyBlock from "components/ErrorsBodyBlock/Error404BodyBlock/Error404BodyBlock";
import { memo } from "react";

const PageError404 = ({ children, ...props }) => {
    return (
        <ScrollToTop>
            <Error404BodyBlock />
        </ScrollToTop>
    );
};

export default memo(PageError404);
