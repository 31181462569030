export const OrderResponseWrapper = { display: "flex", alignItems: "flex-end" };

export const ResponseText = { fontSize: "14px", color: "#9E9EB0" };

export const ResponseCount = { fontSize: "14px", paddingRight: "12px" };

export const ResponseIcon = {
    fontSize: "22px",
    color: "#9E9EB0",
    paddingRight: "6px",
};

export const ResponseViews = { fontSize: "14px" };

export const ResponseViewsPlus = { color: "#6212FF" };
