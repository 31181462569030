import React, { memo } from "react";
import Button from "@mui/material/Button";
import DropDownSVG from "assets/CommonAssets/SVG/DropDownSVG";
import MenuListComposition from "components/UI/PopperMenu";
import MyClassesStore from "store/MyClassesStore";
import FilterClassesContent from "./FilterClassesContent/FilterClassesContent";
import { observer } from "mobx-react-lite";
import * as styles from "./FilterClassesThemes";
import { useMedias } from "API/mediasHook";

const FilterClasses = observer(({ children, ...props }) => {
    const [openFilter, setOpenFilter] = React.useState(false);

    const medias = useMedias();

    return (
        <MenuListComposition
            placement="bottom-end"
            open={openFilter}
            setOpen={setOpenFilter}
            content={<FilterClassesContent setOpen={setOpenFilter} />}
            zIndex={1}
            paperSx={styles.Paper}
            width={medias.sm ? null : "100%"}
        >
            <Button
                disableRipple
                sx={styles.FilterButton(medias)}
            >
                {MyClassesStore.page === 1 ? MyClassesStore.optionsPrevious[MyClassesStore.selectedIndexPrevious] : MyClassesStore.optionsUpcoming[MyClassesStore.selectedIndexUpcoming]}
                <DropDownSVG />
            </Button>
        </MenuListComposition>
    );
});

export default memo(FilterClasses);
