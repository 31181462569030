import React from "react";

import { Box, Typography } from "@mui/material";

import LinkPreview from "./LinkPreview";
import MenuShowImage from "../ImageChatAttachments/MenuShowImage/MenuShowImage";

import * as textStyles from "themes/TextThemes";
import * as styles from "../ImageChatAttachments/ImageChatAttachmentsThemes";

const WithLinks = ({ urls = {} }) => {
    return (
        <>
            {Object.keys(urls).map((monthyear, index) => {
                return (
                    <Box
                        sx={styles.imageDateBox}
                        key={index}
                    >
                        <Typography sx={textStyles.Body2DarkGrey}>{monthyear}</Typography>
                        <Box sx={styles.imagesBox}>
                            {urls[monthyear].reverse().map((link, index) => {
                                return (
                                    <Box
                                        sx={{
                                            ...styles.imageBox,
                                            width: "100%",
                                            height: undefined,
                                        }}
                                        key={`${link.link}_${index}`}
                                    >
                                        <LinkPreview url={link.link} />
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                );
            })}
        </>
    );
};

export default WithLinks;
