import { Box, Typography } from "@mui/material";
import * as themes from "./OrderTargetThemes";
import * as textThemes from "themes/TextThemes";
import { memo } from "react";
import { useTranslation } from "react-i18next";
const OrderTarget = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.OrderTargetWrapper}>
            <Typography>
                <Typography
                    component="span"
                    sx={props.type === "Body1" ? textThemes.Body1Grey : textThemes.Body2Grey}
                >
                    {props.order.baseOrderInfo.orderType === "tutoring" ? t("orders.studentOrders.aim") : t("orders.studentOrders.subject")}
                </Typography>
                <Typography
                    component="span"
                    sx={props.type === "Body1" ? textThemes.Body1Black : textThemes.Body2Black}
                >
                    {props.order.baseOrderInfo.orderType === "tutoring" ? props.order.classPurpose : props.order.baseOrderInfo.subject}
                </Typography>
            </Typography>
        </Box>
    );
};

export default memo(OrderTarget);
