import React from "react";
import { Box, Typography } from "@mui/material";
import * as themes from "./DefaultLvlCardThemes";
import * as textThemes from "themes/TextThemes";
import CheckIconPurple from "assets/CashbackAssets/PNG/IconCheckPurple.png";
import { memo } from "react";

const DefaultLvlCard = ({ children, ...props }) => {
    return (
        <Box sx={themes.CardBlock}>
            <img
                src={props.CardPhoto}
                alt="CardPhoto"
            />
            <Box sx={themes.CardContent}>
                <Box sx={themes.TopCard}>
                    <Typography sx={textThemes.Button20BoldBlack}>{props.CardName}</Typography>
                    <Typography sx={textThemes.Body1Grey}>{props.CardSubName}</Typography>
                </Box>

                <Box sx={themes.advantageBlock}>
                    {props.Advantage1 ? (
                        <Box sx={themes.advantageElement}>
                            <Box sx={themes.iconElem}>
                                <img
                                    src={CheckIconPurple}
                                    alt="CheckIconPurple"
                                />
                            </Box>
                            <Typography sx={textThemes.Body2Black}>{props.Advantage1}</Typography>
                        </Box>
                    ) : null}

                    {props.Advantage2 ? (
                        <Box sx={themes.advantageElement}>
                            <Box sx={themes.iconElem}>
                                <img
                                    src={CheckIconPurple}
                                    alt="CheckIconPurple"
                                />
                            </Box>
                            <Typography sx={textThemes.Body2Black}>{props.Advantage2}</Typography>
                        </Box>
                    ) : null}

                    {props.Advantage3 ? (
                        <Box sx={themes.advantageElement}>
                            <Box sx={themes.iconElem}>
                                <img
                                    src={CheckIconPurple}
                                    alt="CheckIconPurple"
                                />
                            </Box>
                            <Typography sx={textThemes.Body2Black}>{props.Advantage3}</Typography>
                        </Box>
                    ) : null}

                    {props.Advantage4 ? (
                        <Box sx={themes.advantageElement}>
                            <Box sx={themes.iconElem}>
                                <img
                                    src={CheckIconPurple}
                                    alt="CardPhoto"
                                />
                            </Box>
                            <Typography sx={textThemes.Body2Black}>{props.Advantage4}</Typography>
                        </Box>
                    ) : null}
                </Box>
            </Box>
        </Box>
    );
};

export default memo(DefaultLvlCard);
