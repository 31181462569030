export const LanguageCurrencyPaper = {
    borderRadius: "0 0 10px 10px",
    width: "150px",
    boxShadow: "none",
    p: 0,
};

export const LanguageCurrencyContentPaper = {
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
    borderRadius: "0 0 10px 10px",
    padding: "3px 5px 12px 5px",
};

export const LanguageCurrencyTitle = {
    color: "#645287",
    fontSize: "15px",
    fontWeight: "400",
    lineHeight: "150%",
    marginBottom: "5px",
};

export const LanguageCurrencyChoiceContainter = {
    width: "150px",
};

export const LanguageCurrencyContent = {
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px 20px 20px 20px",
    gap: "10px",
};

export const LanguageCurrencyButton = {
    fontSize: "15px",
    fontWeight: "400",
    lineHeight: "140%" /* 21px */,
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "7px 10px 6px 10px",
    ":hover": {
        color: "#6212FF",
        background: "rgba(187, 187, 200, 0.20)",
    },
};

export const LanguageCurrencyChooseButton = (langCurrency) => {
    return {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "150px",
        color: "#222",
        fontSize: "15px",
        fontWeight: "400",
        lineHeight: "150%",
        padding: "8px 15px 9px 15px",
        border: langCurrency === false ? "1px solid #E6E5EA" : "1px solid #fff",
        borderBottom: langCurrency === true ? "1px solid #E6E5EA" : null,
        borderRadius: "40px",
        margin: 0,
        textTransform: "none",
        ":hover": {
            background: "#fff",
            color: "#222",
        },
    };
};
