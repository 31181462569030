import UserStore from "store/UserStore";
import { IconButton, Box } from "@mui/material";
import IconPayment from "assets/ChatAssets/SVG/iconPayment";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import { useMedias } from "API/mediasHook";
import { useNavigate } from "react-router-dom";
import * as themes from "./BalanceButtonThemes";

const BalanceButton = observer(({ children, ...props }) => {
    const medias = useMedias();

    const navigate = useNavigate();

    const handleClickBalance = () => {
        navigate("/balance");
    };

    return (
        <Box>
            {UserStore.role === "student" && (
                <IconButton
                    sx={themes.IconBlock}
                    disableRipple
                    onClick={handleClickBalance}
                >
                    {medias.sm && <IconPayment />}
                </IconButton>
            )}
        </Box>
    );
});

export default memo(BalanceButton);
