import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import { Button, Dialog, IconButton } from "@mui/material";
import * as themes from "./SModalTheme";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { CloseRounded, EastSharp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const SModal = observer(({ children, ...props }) => {
    const navigate = useNavigate();

    const Close = () => {
        props.handleClose();
    };

    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={props.open}
                onClose={Close}
            >
                <Box sx={themes.dialogContainerStyle}>
                    <ThemeProvider theme={themes.CloseIconTheme}>
                        <IconButton
                            onClick={Close}
                            disableRipple
                        >
                            <CloseRounded></CloseRounded>
                        </IconButton>
                    </ThemeProvider>
                    <Box sx={themes.dialogMainContainerStyle}>{children}</Box>
                </Box>
            </Dialog>
        </ThemeProvider>
    );
});

export default memo(SModal);
