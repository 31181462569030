export const Paper = {
    borderRadius: "10px",
    border: "none",
    boxShadow: "none",
    p: 0,
    mr: "150px",
    mt: "10px",
};

export const ContentProps = {
    sx: {
        background: "#4E4E4E",
        borderRadius: "10px",
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
    },
};

export const MenuListCompositionMobilePos = { width: "260px", position: "absolute", left: "-206px", top: "-6px" };
