import { Divider, Typography, styled } from "@mui/material";

export const StyledRejectedDivider = styled(Divider)({
    marginTop: "10px",
    marginBottom: "30px",
    color: "rgba(158, 158, 176, 1)",
});

export const StyledRejectedText = styled(Typography)({
    marginRight: "15px",
    marginLeft: "15px",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0em",
});
