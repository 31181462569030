import { Box, Typography } from "@mui/material";
import React from "react";
import * as themes from "components/SettingsBodyBlock/TutorSettings/MainForm/MainFormThemes";
import { faculties, universities } from "API/dictionaries/const_data";
import SButton from "components/UI/SButton";
import SBAutoComplete from "components/UI/Autocomplete";
import STextField from "components/UI/STextField";
import SDropDown from "components/UI/SDropDown";
import IOSSwitch from "components/UI/IOSSwitch";
import FormControlLabel from "@mui/material/FormControlLabel";
import SDragAndDrop from "components/TeacherQuestionnaireBodyBlock/Components/SDragNDrop";
import DeleteStatementIcon from "assets/TeacherQuestionnaireAssets/DeleteStatementIcon";
import STooltip from "components/TeacherQuestionnaireBodyBlock/Components/STooltip";
import base_store from "store/TeacherQuestionnaireStore";
import { observer } from "mobx-react-lite";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import IconBackRounded from "assets/CommonAssets/SVG/IconBackRounded";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { memo } from "react";
import Button from "@mui/material/Button";
import { useMedias } from "API/mediasHook";

const EducationEdit = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const handleBackClick = () => {
        TeacherQuestionnaireStore.setTemporaryEducation(JSON.parse(JSON.stringify(TeacherQuestionnaireStore.teacher.education)));
        props.handleSet(0);
    };

    const handleSaveClick = () => {
        TeacherQuestionnaireStore.setTutorData("education", JSON.parse(JSON.stringify(TeacherQuestionnaireStore.temporaryEducation)));
    };

    const handleCancelClick = () => {
        TeacherQuestionnaireStore.setTemporaryEducation(JSON.parse(JSON.stringify(TeacherQuestionnaireStore.teacher.education)));
    };

    const handleClickAdd = () => {
        TeacherQuestionnaireStore.addEducation({
            id: TeacherQuestionnaireStore.temporaryEducation.length + 1,
            university: "",
            faculty: "",
            speciality: "",
            beginYear: "",
            endYear: "",
            stillStudying: false,
            photo: { imageSize: "", imageName: "", imageSrc: "" },
        });
    };

    return (
        <ScrollToTop>
            <Box sx={themes.Top(medias)}>
                <Box sx={themes.EditTitle(medias)}>
                    <SButton
                        variant="icon"
                        onClick={handleBackClick}
                    >
                        <IconBackRounded />
                    </SButton>
                    <Typography sx={medias.sm ? textThemes.HeadlineH41Black : textThemes.HeadlineH5Black}>{t("teacherSettings.questionnaire.education")}</Typography>
                </Box>
                <Box>
                    <Box sx={themes.AdditionalBlocks}>
                        {TeacherQuestionnaireStore.temporaryEducation.map((EducationExp, index) => {
                            return (
                                <Box
                                    key={index}
                                    sx={themes.BlockWithRemove}
                                >
                                    <Box sx={themes.FormBlockFixWidth}>
                                        <Box sx={themes.BottomBlock}>
                                            <Box sx={themes.item(medias)}>
                                                <Typography sx={textThemes.Body2Black}>{t("teacherSettings.questionnaire.university")}</Typography>
                                                <Box sx={themes.FixWidthFormItem(medias)}>
                                                    <SBAutoComplete
                                                        marginBottom="0px"
                                                        value={TeacherQuestionnaireStore.temporaryEducation[index].university}
                                                        changefunc={(value) => TeacherQuestionnaireStore.setTemporaryEducationData(index, "university", value)}
                                                        inputvalue={TeacherQuestionnaireStore.temporaryEducation[index].university}
                                                        inputchangefunc={(value) => {
                                                            TeacherQuestionnaireStore.setTemporaryEducationData(index, "university", value);
                                                        }}
                                                        list={universities}
                                                        placeholder={t("teacherSettings.questionnaire.enterUniversity")}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box sx={themes.item(medias)}>
                                                <Typography sx={textThemes.Body2Black}>{t("teacherSettings.questionnaire.faculty")}</Typography>
                                                <Box sx={themes.FixWidthFormItem(medias)}>
                                                    <SBAutoComplete
                                                        marginBottom="0px"
                                                        value={TeacherQuestionnaireStore.temporaryEducation[index].faculty}
                                                        changefunc={(value) => TeacherQuestionnaireStore.setTemporaryEducationData(index, "faculty", value)}
                                                        inputvalue={TeacherQuestionnaireStore.temporaryEducation[index].faculty}
                                                        inputchangefunc={(value) => {
                                                            TeacherQuestionnaireStore.setTemporaryEducationData(index, "faculty", value);
                                                        }}
                                                        list={faculties}
                                                        placeholder={t("teacherSettings.questionnaire.enterFaculty")}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box sx={themes.item(medias)}>
                                                <Typography sx={textThemes.Body2Black}>{t("teacherSettings.questionnaire.specialityName")}</Typography>
                                                <STextField
                                                    width={medias.sm ? "424px" : "100%"}
                                                    placeholder={t("teacherSettings.questionnaire.enterSpeciality")}
                                                    value={TeacherQuestionnaireStore.temporaryEducation[index].speciality}
                                                    changeFunction={(value) => {
                                                        TeacherQuestionnaireStore.setTemporaryEducationData(index, "speciality", value);
                                                    }}
                                                />
                                            </Box>
                                            <Box sx={themes.ItemWithSwitcher(medias)}>
                                                <Box sx={themes.BottomBlock}>
                                                    <Box sx={themes.item(medias)}>
                                                        <Typography sx={textThemes.Body2Black}>{t("teacherSettings.questionnaire.beginYear")}</Typography>
                                                        <Box sx={themes.TeacherQuestionnaireAutocompleteContainer(medias)}>
                                                            <SDropDown
                                                                firstValueGrey={false}
                                                                dropDownChoice={TeacherQuestionnaireStore.temporaryEducation[index].beginYear}
                                                                setDropDownChoice={(value) => {
                                                                    TeacherQuestionnaireStore.setTemporaryEducationData(index, "beginYear", value);
                                                                }}
                                                                dropDownList={base_store.yearsList}
                                                                buttonWidth={medias.sm ? "424px" : "100%"}
                                                                paperWidth={medias.sm ? "424px" : "100%"}
                                                            />
                                                        </Box>
                                                    </Box>
                                                    {TeacherQuestionnaireStore.temporaryEducation[index].stillStudying === false ? (
                                                        <Box sx={themes.item(medias)}>
                                                            <Typography sx={textThemes.Body2Black}>{t("teacherSettings.questionnaire.endYear")}</Typography>
                                                            <Box sx={themes.TeacherQuestionnaireAutocompleteContainer(medias)}>
                                                                <SDropDown
                                                                    firstValueGrey={false}
                                                                    dropDownChoice={TeacherQuestionnaireStore.temporaryEducation[index].endYear}
                                                                    setDropDownChoice={(value) => {
                                                                        TeacherQuestionnaireStore.setTemporaryEducationData(index, "endYear", value);
                                                                    }}
                                                                    dropDownList={base_store.yearsList}
                                                                    buttonWidth={medias.sm ? "424px" : "100%"}
                                                                    paperWidth={medias.sm ? "424px" : "100%"}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    ) : null}
                                                </Box>
                                                <Box sx={themes.SwitcherBlockOuter}>
                                                    <Box sx={themes.SwitcherBlock(medias)}>
                                                        <FormControlLabel
                                                            sx={{ alignItems: "flex-start", m: 0 }}
                                                            control={
                                                                <IOSSwitch
                                                                    sx={{ m: 1 }}
                                                                    onChange={() => {
                                                                        TeacherQuestionnaireStore.setTemporaryEducationData(
                                                                            index,
                                                                            "stillStudying",
                                                                            !TeacherQuestionnaireStore.temporaryEducation[index].stillStudying,
                                                                        );
                                                                    }}
                                                                    checked={TeacherQuestionnaireStore.temporaryEducation[index].stillStudying}
                                                                />
                                                            }
                                                        />
                                                        <Typography sx={textThemes.Body2Black}>{t("teacherSettings.questionnaire.stillStudying")}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box sx={themes.InfoBlocks}>
                                                <Box sx={themes.itemFlexStart(medias)}>
                                                    <Box sx={themes.PaddingBlock}>
                                                        <Typography sx={textThemes.Body2Black}>{t("teacherSettings.questionnaire.documentPhoto")}</Typography>
                                                    </Box>
                                                    <Box sx={themes.FixWidthFormItem(medias)}>
                                                        <SDragAndDrop
                                                            image={TeacherQuestionnaireStore.temporaryEducation[index].photo.imageSrc}
                                                            setImage={(value) => {
                                                                TeacherQuestionnaireStore.setTemporaryEducationData(index, "photo", value, "imageSrc");
                                                            }}
                                                            imageName={TeacherQuestionnaireStore.temporaryEducation[index].photo.imageName}
                                                            setImageName={(value) => TeacherQuestionnaireStore.setTemporaryEducationData(index, "photo", value, "imageName")}
                                                            imageSize={TeacherQuestionnaireStore.temporaryEducation[index].photo.imageSize}
                                                            setImageSize={(value) => TeacherQuestionnaireStore.setTemporaryEducationData(index, "photo", value, "imageSize")}
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box sx={themes.SwitcherBlockOuter}>
                                                    <Box sx={themes.SwitcherBlock(medias)}>
                                                        <Typography sx={textThemes.Body2DarkGrey}>
                                                            {TeacherQuestionnaireStore.temporaryEducation[index].photo.imageSrc === null ||
                                                            TeacherQuestionnaireStore.temporaryEducation[index].photo.imageSrc === ""
                                                                ? t("teacherSettings.questionnaire.uploadPhotoScan")
                                                                : t("teacherSettings.questionnaire.photoSecured")}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        {!medias.sm ? (
                                            <>
                                                {TeacherQuestionnaireStore.temporaryEducation.length > 1 && (
                                                    <Button
                                                        sx={themes.TextButtonDelete}
                                                        disableRipple
                                                        variant="text"
                                                        onClick={() => {
                                                            TeacherQuestionnaireStore.removeEducation(index);
                                                        }}
                                                    >
                                                        {t("main.delete")}
                                                    </Button>
                                                )}
                                            </>
                                        ) : null}
                                        <Box>
                                            {TeacherQuestionnaireStore.temporaryEducation.length > 1 && index + 1 !== TeacherQuestionnaireStore.temporaryEducation.length ? (
                                                <hr style={themes.Separator} />
                                            ) : null}
                                        </Box>
                                    </Box>
                                    {medias.sm && (
                                        <>
                                            {TeacherQuestionnaireStore.temporaryEducation.length > 1 ? (
                                                <STooltip title={t("main.delete")}>
                                                    <Box
                                                        sx={themes.RemoveButton}
                                                        onClick={() => {
                                                            TeacherQuestionnaireStore.removeEducation(index);
                                                        }}
                                                    >
                                                        <DeleteStatementIcon />
                                                    </Box>
                                                </STooltip>
                                            ) : null}
                                        </>
                                    )}
                                </Box>
                            );
                        })}
                    </Box>
                    <Box>
                        <Button
                            sx={themes.TeacherQuestionnaireAddFieldsButton}
                            onClick={handleClickAdd}
                        >
                            {t("teacherSettings.questionnaire.addEducationPlace")}
                        </Button>
                    </Box>
                </Box>
                <Box sx={themes.ButtonsBlock}>
                    <SButton
                        variant="unshadowed"
                        padding="10px 20px 11px 20px"
                        disabled={TeacherQuestionnaireStore.getDisabledSaveEducation()}
                        onClick={handleSaveClick}
                    >
                        {t("main.save")}
                    </SButton>
                    <SButton
                        variant="secondary"
                        padding="10px 20px 11px 20px"
                        disabled={TeacherQuestionnaireStore.getDisabledSaveEducation()}
                        onClick={handleCancelClick}
                    >
                        {t("main.cancel")}
                    </SButton>
                </Box>
            </Box>
        </ScrollToTop>
    );
});

export default memo(EducationEdit);
