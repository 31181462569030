import React, { memo } from "react";
import Box from "@mui/material/Box";
import * as themes from "./DisciplineContentThemes";
import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import TeacherStore from "store/TeacherStore";
import CashbackIcon from "assets/CommonAssets/SVG/CashbackIconSVG";
import { useMedias } from "../../../../API/mediasHook";

const DisciplineContent = observer(({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={themes.disciplineContainerStyle}>
            <Box sx={themes.cashbackInfoStyle}>
                <Typography sx={themes.cashbackTextStyle}>При обучении у репетитора вам вернётся от 35 баллов на бонусный счёт</Typography>
                <CashbackIcon></CashbackIcon>
            </Box>
            <Box sx={themes.disciplineContentContainerStyle}>
                {props.value.map((value, index) =>
                    !TeacherStore.disciplinesExpanded ? (
                        index <= 7 ? (
                            <Box
                                key={value.ID}
                                sx={themes.disciplineItemStyle(medias)}
                            >
                                <Typography sx={themes.disciplineInfoStyle}>{value.DisciplineInfo}</Typography>
                                <Typography sx={themes.disciplineCostStyle(value.DisciplineCost)}>
                                    {value.DisciplineCost ? value.DisciplineCost.toLocaleString() + " ₽/" + value.DisciplineCostTime + " мин" : "по договоренности"}
                                </Typography>
                            </Box>
                        ) : null
                    ) : (
                        <Box
                            key={value.ID}
                            sx={themes.disciplineItemStyle}
                        >
                            <Typography sx={themes.disciplineInfoStyle}>{value.DisciplineInfo}</Typography>
                            <Typography sx={themes.disciplineCostStyle(value.DisciplineCost)}>
                                {value.DisciplineCost ? value.DisciplineCost.toLocaleString() + " ₽/" + value.DisciplineCostTime + " мин" : "по договоренности"}
                            </Typography>
                        </Box>
                    ),
                )}
            </Box>
        </Box>
    );
});

export default memo(DisciplineContent);
