import * as themes from "./OrderCardThemes";
import { Chip, Avatar, AvatarGroup, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import { memo, useCallback } from "react";
import { useMedias } from "API/mediasHook";

const OrderCard = memo(({ children, ...props }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const { item } = props;

    const Subway = useCallback(
        (subway) => (
            <Box sx={themes.UndergroundCircleAndText}>
                <AvatarGroup>
                    {subway?.colors?.map((color, index) => (
                        <Avatar
                            key={index}
                            sx={themes.UndergroundColorBlock(color)}
                        />
                    ))}
                </AvatarGroup>
                <Typography sx={textThemes.Body2DarkGrey}>{subway?.Location}</Typography>
            </Box>
        ),
        [],
    );

    const Price = useCallback(() => {
        const { PriceFrom, PriceTo } = item.Price;

        if (PriceFrom) {
            return PriceTo
                ? `${PriceFrom}-${PriceTo} ${t("teacherAccount.recommendedOrders.rub/h")}`
                : `${t("teacherAccount.recommendedOrders.from")} ${PriceFrom} ${t("teacherAccount.recommendedOrders.rub/h")}`;
        } else if (PriceTo) {
            return `${t("teacherAccount.recommendedOrders.to")} ${PriceTo} ${t("teacherAccount.recommendedOrders.rub/h")}`;
        }

        return "";
    }, [item.Price, t]);

    const handleCardClick = () => {
        window.open(`/order/${item._id}`, "_blank", "noreferrer");
    };

    return (
        <Box
            sx={themes.OrderCard(medias)}
            onClick={handleCardClick}
        >
            <Box sx={themes.TitleBlock}>
                <Typography sx={textThemes.Button16BoldBlack}>{item.OrderTitle}</Typography>
                <Box sx={themes.ChipContainer}>
                    {item.Tags.slice(0, 4).map(({ ID, Query }) => (
                        <Chip
                            key={ID}
                            label={Query}
                            sx={themes.Chips}
                        />
                    ))}
                </Box>
            </Box>

            <Box sx={themes.BottomBlock(medias)}>
                {item.OrderType === 0 ? (
                    <Box sx={themes.WhereAndWhenContainer}>
                        <Box sx={themes.WhereBlock}>
                            <Typography sx={textThemes.Body2Grey}>
                                {t("teacherAccount.recommendedOrders.where")}
                                {item.Location.some((loc) => loc === "Онлайн") && (
                                    <Typography
                                        component="span"
                                        sx={textThemes.Body2Black}
                                    >
                                        {t("teacherAccount.recommendedOrders.online")}
                                    </Typography>
                                )}
                                {item.Location.some((loc) => loc === "Онлайн") && item.Location.length > 1 && (
                                    <Typography
                                        component="span"
                                        sx={textThemes.Body2Black}
                                    >
                                        {", "}
                                    </Typography>
                                )}
                                {item.Location.some((loc) => loc === "У репетитора") && (
                                    <Typography
                                        component="span"
                                        sx={textThemes.Body2Black}
                                    >
                                        {t("teacherAccount.recommendedOrders.atTutor")}
                                    </Typography>
                                )}
                                {item.Location.some((loc) => loc === "У репетитора") && item.Location.some((loc) => loc === "У студента") && (
                                    <Typography
                                        component="span"
                                        sx={textThemes.Body2Black}
                                    >
                                        {", "}
                                    </Typography>
                                )}
                                {item.Location.some((loc) => loc === "У студента") && (
                                    <Typography
                                        component="span"
                                        sx={textThemes.Body2Black}
                                    >
                                        {t("teacherAccount.recommendedOrders.atStudent")}
                                    </Typography>
                                )}
                            </Typography>
                            {item.Location.some((loc) => loc === "У студента" || loc === "У репетитора") && item.Stations.slice(0, 2).map(Subway)}
                            {item.Stations.length > 2 && (
                                <Typography
                                    component="span"
                                    sx={textThemes.Body2DarkGrey}
                                >
                                    +{item.Stations.length - 2}
                                </Typography>
                            )}
                        </Box>

                        <Typography sx={textThemes.Body2Grey}>
                            {t("teacherAccount.recommendedOrders.when")}
                            <Typography
                                component="span"
                                sx={textThemes.Body2Black}
                            >
                                {item.ScheduleContent}
                            </Typography>
                        </Typography>
                    </Box>
                ) : (
                    <Box sx={themes.WhereAndWhenContainer}>
                        <Typography sx={textThemes.Body2Grey}>
                            {t("teacherAccount.recommendedOrders.theme")}
                            <Typography
                                component="span"
                                sx={textThemes.Body2Black}
                            >
                                {item.Theme.length > 105 ? `${item.Theme.slice(0, 105)}...` : item.Theme}
                            </Typography>
                        </Typography>
                        <Typography sx={textThemes.Body2Grey}>
                            {t("teacherAccount.recommendedOrders.deadline")}
                            <Typography
                                component="span"
                                sx={textThemes.Body2Black}
                            >
                                {item.Deadline}
                            </Typography>
                        </Typography>
                    </Box>
                )}

                <Box sx={themes.CostAndTimeAgo}>
                    <Typography sx={textThemes.Button16BoldBlack}>{item.OrderType === 0 ? Price() : `${item.Price.PriceFrom} ${t("teacherAccount.recommendedOrders.rub/h")}`}</Typography>
                    <Typography sx={textThemes.Body2Grey}>{item.LastSeen}</Typography>
                </Box>
            </Box>
        </Box>
    );
});

export default OrderCard;
