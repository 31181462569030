import { createTheme } from "@mui/material/styles";

export const dividerStyle = (marginTop, marginBottom) => {
    return {
        width: "90%",
        marginTop: marginTop,
        marginBottom: marginBottom,
    };
};

export const MenuText = (mode) => {
    return {
        color: mode === "Удалить чат" ? "#E12626" : "#222",
        "&:hover": {
            color: mode === "Удалить чат" ? "#E12626" : "#6212FF",
        },
    };
};

export const menuItemStyle = {
    display: "flex",
    flexDirection: "column",
};

export const menuItemContainer = {
    width: "100%",
};

export const MenuTheme = createTheme({
    components: {
        MuiMenu: {
            styleOverrides: {
                // Name of the slot
                root: {},
                paper: {
                    marginTop: 10,
                    borderRadius: "10px!important",
                    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
                    ".MuiList-root": {
                        display: "flex",
                        width: "max-content",
                        padding: "7px 4px",
                        flexDirection: "column",
                        alignItems: "center",
                    },
                    ".MuiMenuItem-root": {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        alignSelf: "stretch",
                        gap: "12px",
                        padding: "5px 12px",
                        "&:hover": {
                            background: "rgba(187, 187, 200, 0.17)!important",
                            borderRadius: "10px!important",
                        },
                        "&.Mui-selected": {
                            background: "rgba(187, 187, 200, 0.17)!important",
                            borderRadius: "10px!important",
                            "& *": {
                                color: "#6212FF!important",
                            },
                        },
                    },
                },
            },
        },
    },
});

export const PaperStyles = {
    width: "17vw",
    mt: "2.5vh",
    backgroundColor: "white",
    borderRadius: "1.2vh",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
    border: "none",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
    p: 0,
};
