export const MainBox = {
    padding: "1.2vh",
};

export const Price = {
    marginTop: "1.3vh",
    marginLeft: "auto",
    marginRight: "auto",
    width: "6vw",
    fontSize: "1.8vh",
};
