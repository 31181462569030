//Какая цель занятий?
import React, { useState } from "react";

import { observer } from "mobx-react-lite";

import SRadioGroup from "components/UI/RadioGroup";
import SBButtonGroup from "components/UI/ButtonGroup";

import store from "store/RequestStore";
import { classesAims, univeristyClassesList } from "API/dictionaries/const_data";
import RequestStore from "store/RequestStore";

import RequestNullAlert from "./RequestNullAlert";
import { Box } from "@mui/material";

const Step5 = () => {
    const [alert, setAlert] = useState(0);

    const [stepData, setStepData] = useState(RequestStore.storage[store.activeStepIndex].substeps[store.subStepIndex].description);

    const [textVar, setTextVar] = useState("");

    if (classesAims.includes(stepData) === false && stepData !== "Другое" && stepData !== null) {
        setTextVar(stepData);
        setStepData("Другое");
    }

    return (
        <Box>
            <SRadioGroup
                changefunc={setStepData}
                value={stepData}
                list={classesAims}
                anotherVar={true}
                textVar={textVar}
                setTextVar={setTextVar}
            />
            <SBButtonGroup
                handleNext={() => {
                    if (stepData) {
                        if (stepData === "Другое") {
                            store.setStep(2, textVar);
                            store.incrementStepIndex();
                        } else {
                            store.setStep(2, stepData);
                            store.incrementStepIndex();
                        }
                    } else {
                        setAlert(1);
                    }
                }}
                handleBack={() => store.decrementStepIndex()}
                activeStep={store.activeStepIndex}
                steps={store.storage}
            />
            {alert === 1 ? <RequestNullAlert>Чтобы перейти к следующему вопросу, выберите один из вариантов</RequestNullAlert> : null}
        </Box>
    );
};

export default observer(Step5);
