export const StatusBox = (status) => {
    return {
        padding: "2px 10px 4px 12.375px",
        display: "inline-flex",
        gap: "3.375px",
        borderRadius: "26px",
        alignItems: "center",
        justifyContent: "center",
        background:
            status === "appliedForWork"
                ? "rgba(98, 18, 255, 0.1)"
                : status === "new"
                  ? "rgba(98, 18, 255, 0.1)"
                  : status === "inProgress"
                    ? "rgba(255, 146, 18, 0.14)"
                    : status === "waitingCheck"
                      ? "rgba(225, 38, 38, 0.1)"
                      : status === "completed"
                        ? "rgba(25, 178, 12, 0.1)"
                        : status === "cancelled"
                          ? "rgba(187, 187, 200, 0.17)"
                          : null,
    };
};

export const BullPoint = (status) => {
    return {
        fontSize: "20px",
        color:
            status === "appliedForWork"
                ? "rgba(98, 18, 255, 1)"
                : status === "new"
                  ? "rgba(98, 18, 255, 1)"
                  : status === "inProgress"
                    ? "rgba(255, 146, 18, 1)"
                    : status === "waitingCheck"
                      ? "rgba(225, 38, 38, 1)"
                      : status === "completed"
                        ? "rgba(25, 178, 12, 1)"
                        : status === "cancelled"
                          ? "rgba(158, 158, 176, 1)"
                          : null,
    };
};
