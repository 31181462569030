import { makeAutoObservable } from "mobx";
import RequestService from "API/services/RequestService";
import { handleStoreActionError } from "../API/plugins/errorHandlers";
import i18next from "../i18n";
import Bowser from "bowser";

const requestService = new RequestService();

class ErrorStore {
    error = null;
    shouldShowError = false;
    criticalError = false;

    constructor() {
        makeAutoObservable(this);
    }

    async ShowError(data) {
        try {
            this.error = data.errorText;
            this.shouldShowError = true;
            this.criticalError = data.isCritical;
        } catch (error) {
            handleStoreActionError({ error });
        }
    }

    async HideError() {
        try {
            this.shouldShowError = false;
            this.error = null;
        } catch (error) {
            handleStoreActionError({ error });
        }
    }

    async ToggleError() {
        try {
            this.shouldShowError = !this.shouldShowError;
        } catch (error) {
            handleStoreActionError({ error });
        }
    }

    async LogError(data) {
        //log to server
        try {
            //await errorService.LogError(data);
        } catch {
            console.log(i18next.t("error.errors.logErrorError"));
        }
    }

    DownloadErrorsReport() {
        let browserInfo = Bowser.parse(window.navigator.userAgent);

        /*let cookies = this.$cookies.keys().map((cookie) => {
            return { name: cookie, value: this.$cookies.get(cookie) };
        });*/

        let environmentData = {
            Browser: browserInfo.browser,
            OS: browserInfo.os,
            Platform: browserInfo.platform,
            /*token: store.state.identity.tokenParsed,*/
            /*cookies: cookies,*/
        };

        let errorsData = JSON.parse(localStorage.getItem("clientErrors"))?.errors?.length ? JSON.parse(localStorage.getItem("clientErrors"))?.errors : [];

        let errorsObject = {
            errors: errorsData,
            ...environmentData,
        };

        localStorage.setItem("clientErrors", JSON.stringify(errorsObject));

        let fileData = localStorage.getItem("clientErrors");

        let fileName = "clientErrorsReport_" + Date.now() + ".json";

        this.download(fileData, fileName);
    }

    download(data, filename) {
        const link = document.createElement("a");

        link.setAttribute("href", "data:text/plain," + encodeURIComponent(data));
        link.setAttribute("download", filename);
        link.style.display = "none";

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }
}

export default new ErrorStore();
