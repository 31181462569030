import React, { memo } from "react";
import { Chip, Typography } from "@mui/material";
import * as themes from "./HeaderAreaThemes";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";

const HeaderArea = ({ children, ...props }) => {
    return (
        <Box sx={themes.ocHeaderAreaStyle}>
            <Typography sx={themes.headerTextStyle}>{props.value.OrderTitle}</Typography>
            <Box sx={themes.tagsBlockContainerStyle}>
                {props.value.Tags.map((tag) => (
                    <ThemeProvider
                        theme={themes.TagTheme}
                        key={tag.ID}
                    >
                        <Chip
                            key={tag.ID}
                            label={tag.Query}
                            variant="outlined"
                            size="small"
                            disableRipple
                            sx={themes.textStyle}
                            onClick={() => console.log(tag)}
                        ></Chip>
                    </ThemeProvider>
                ))}
            </Box>
        </Box>
    );
};

export default memo(HeaderArea);
