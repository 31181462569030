export const orderMenuContainer = {
    maxWidth: "1216px",
    width: "100%",
    padding: "40px 112px 40px 112px",
    margin: "0 auto",
};

export const orderMenuWrapper = {
    display: "flex",
    gap: "40px",
    alignItems: "center",
};

export const orderMenuButtonWrapper = {
    maxWidth: "244px",
    marginLeft: "auto",
};
