import * as React from "react";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { Box, Typography, InputAdornment, TextField, IconButton } from "@mui/material";

import SButton from "components/UI/SButton";
import PhoneTextField from "components/AuthorizationBodyBlock/PhoneTextField/PhoneTextField";
import SignInTabs, { TabPanel } from "components/AuthorizationBodyBlock/Tabs/Tabs";
import VisibilityIcon from "assets/CommonAssets/SVG/Visibility";
import VisibilityOffIcon from "assets/CommonAssets/SVG/VisibilityOff";
import * as styles from "./SignInThemes";
import * as textStyles from "themes/TextThemes";
import AuthorizationStore from "store/AuthorizationStore";
import UserStore from "store/UserStore";
import { useMedias } from "API/mediasHook";
import Logo from "components/UI/Logo/logo";

const SignIn = observer(() => {
    const navigate = useNavigate();

    const medias = useMedias();

    React.useEffect(() => {
        AuthorizationStore.setMode("login");
    }, []);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleResetPassword = () => {
        AuthorizationStore.setCurrentAuthorizationStep("ForgotPassword");
        AuthorizationStore.setResetEmail("");
        AuthorizationStore.setResetEmailError(false);
    };

    const handleSignUpStudent = () => {
        AuthorizationStore.setCurrentAuthorizationStep("SignUpStudent");
        AuthorizationStore.setSignUpPhone(null);
        AuthorizationStore.setSignUpPhoneError(false);
        AuthorizationStore.setSignUpName("");
        AuthorizationStore.setSignUpEmail("");
        AuthorizationStore.setSignUpEmailError(false);
        AuthorizationStore.setSignUpEmailErrorText("");
        AuthorizationStore.setStudentRulesAgree(false);
    };

    const handleSignUpTutor = () => {
        AuthorizationStore.setCurrentAuthorizationStep("SignUpTutor");
        AuthorizationStore.setSignUpPhone(null);
        AuthorizationStore.setSignUpPhoneError(false);
        AuthorizationStore.setSignUpName("");
        AuthorizationStore.setSignUpEmail("");
        AuthorizationStore.setSignUpEmailError(false);
        AuthorizationStore.setSignUpEmailErrorText("");
        AuthorizationStore.setTutorOfferAgree(false);
        AuthorizationStore.setTutorRulesAgree(false);
    };

    const handleChangePassword = (e) => {
        AuthorizationStore.setTemporaryPassword(e.target.value);
        AuthorizationStore.setTypingError(false);
    };

    const handleChangeEmail = (e) => {
        AuthorizationStore.setTemporaryEmail(e.target.value);
        AuthorizationStore.setTypingError(false);
    };

    const handleClickLogin = async () => {
        if (AuthorizationStore.signInTab === 0) {
            await AuthorizationStore.handleCheckPhoneNumber();
        } else {
            await AuthorizationStore.handleCheckEmailAndPassword(navigate);
        }
    };

    const disableLogin = () => {
        return !AuthorizationStore.temporaryPhoneNumber;

        /*return (
            (AuthorizationStore.signInTab === 0 && (AuthorizationStore.temporaryPhoneNumber === null || AuthorizationStore.temporaryPhoneNumber === "")) ||
            (AuthorizationStore.signInTab === 1 &&
                (AuthorizationStore.temporaryPassword === null ||
                    AuthorizationStore.temporaryPassword === "" ||
                    AuthorizationStore.temporaryEmail === null ||
                    AuthorizationStore.temporaryEmail === ""))
        );*/
    };

    return (
        <Box sx={styles.SignIn(medias)}>
            <Box sx={styles.SignInContent}>
                {!medias.sm && <Logo />}
                <Typography sx={medias.sm ? textStyles.HeadlineH3Black : textStyles.HeadlineH41Black}>Вход в личный кабинет</Typography>
                <Box sx={styles.Form(medias)}>
                    <Box sx={styles.TabsAndForm}>
                        <SignInTabs />
                        <TabPanel
                            value={AuthorizationStore.signInTab}
                            index={0}
                        >
                            <Box sx={styles.PhoneForm}>
                                <Typography sx={textStyles.Body2DarkGrey}>Мы отправим персональный 6-значный код для входа в личный кабинет</Typography>
                                <PhoneTextField />
                            </Box>
                        </TabPanel>
                        <TabPanel
                            value={AuthorizationStore.signInTab}
                            index={1}
                        >
                            <Box sx={styles.EmailAndForgotPassword}>
                                <Box sx={styles.EmailForm}>
                                    <Box>
                                        <Typography sx={textStyles.Body2Black}>Электронная почта</Typography>
                                        <TextField
                                            value={AuthorizationStore.temporaryEmail}
                                            sx={styles.EmailField(AuthorizationStore.typingError)}
                                            error={AuthorizationStore.typingError}
                                            fullWidth
                                            variant="standard"
                                            name="email"
                                            onChange={handleChangeEmail}
                                            placeholder="Введите электронную почту"
                                        />
                                    </Box>
                                    <Box>
                                        <Typography sx={textStyles.Body2Black}>Пароль</Typography>
                                        <TextField
                                            value={AuthorizationStore.temporaryPassword}
                                            error={AuthorizationStore.typingError}
                                            helperText={AuthorizationStore.typingError ? "Неверный адрес электронной почты или пароль" : ""}
                                            onChange={handleChangePassword}
                                            placeholder="Введите пароль"
                                            name="password"
                                            sx={styles.PasswordField(AuthorizationStore.typingError)}
                                            variant="standard"
                                            fullWidth
                                            type={AuthorizationStore.showPassword ? "text" : "password"}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            sx={styles.HideButton}
                                                            disableRipple
                                                            aria-label="toggle password visibility"
                                                            onClick={() => AuthorizationStore.setShowPassword()}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {AuthorizationStore.showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={styles.ForgotPassword}>
                                    <SButton
                                        disabled={true}
                                        variant="text"
                                        onClick={handleResetPassword}
                                    >
                                        Забыли пароль?
                                    </SButton>
                                </Box>
                            </Box>
                        </TabPanel>
                    </Box>
                    <SButton
                        variant="arrow"
                        style={styles.LoginButton}
                        padding="11px 24px 12px 24px"
                        onClick={handleClickLogin}
                        disabled={disableLogin()}
                    >
                        Войти
                    </SButton>
                </Box>
            </Box>
            <Box sx={styles.SignUpContainer(medias)}>
                <Typography sx={textStyles.Button14Black}>Нет аккаунта?</Typography>
                <Box sx={styles.SignUpButtons}>
                    <SButton
                        variant="text"
                        onClick={handleSignUpStudent}
                    >
                        Зарегистрироваться как ученик
                    </SButton>
                    <SButton
                        variant="text"
                        onClick={handleSignUpTutor}
                    >
                        Зарегистрироваться как репетитор
                    </SButton>
                </Box>
            </Box>
        </Box>
    );
});

export default memo(SignIn);
