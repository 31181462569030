import React from "react";
import { Box, Typography } from "@mui/material";
import Cards from "assets/SettingsAssets/SVG/cards";
import { useState } from "react";
import * as styles from "./PaymentThemes";
import * as textStyles from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import SButton from "components/UI/SButton";
import ModalAddCard from "./ModalAddCard/ModalAddCard";
import SettingsStore from "store/SettingsStore";
import UserStore from "store/UserStore";
import CardDetection from "./CardDetection/CardDetection";
import { observer } from "mobx-react-lite";
import ModalDeleteCard from "./ModalDeleteCard/ModalDeleteCard";
import { memo } from "react";
import { useMedias } from "API/mediasHook";

const Payment = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    //Add card

    const [dialogAddCardOpen, SetDialogAddCardOpen] = useState(false);

    const showAddCardDialog = () => {
        SetDialogAddCardOpen(true);
        SettingsStore.setTemporaryCardNumber(null);
        SettingsStore.setTemporaryCardCVC(null);
        SettingsStore.setTemporaryCardExpirationDate(null);
    };

    const discardAddCardDialog = () => {
        SetDialogAddCardOpen(false);
    };

    const saveAddCardDialog = () => {
        SetDialogAddCardOpen(false);
    };

    //Delete photo
    const [dialogDeleteCardOpen, SetDialogDeleteCardOpen] = useState(false);

    const showDeleteCardDialog = () => {
        SetDialogDeleteCardOpen(true);
    };

    const discardDeleteCardDialog = () => {
        SetDialogDeleteCardOpen(false);
    };

    return (
        <Box sx={styles.PaymentMethods(medias)}>
            <ModalAddCard
                open={dialogAddCardOpen}
                handleDiscard={discardAddCardDialog}
                handleSave={saveAddCardDialog}
            />

            <ModalDeleteCard
                open={dialogDeleteCardOpen}
                handleDiscard={discardDeleteCardDialog}
            />

            <Box sx={styles.PaymentMethodsTitle(medias)}>
                <Typography sx={textStyles.HeadlineH4White}>{t("main.settings.paymentMethodsBlock.paymentMethodsTitle")}</Typography>
            </Box>
            <Box sx={styles.PaymentMethodsContent(medias)}>
                <Typography sx={textStyles.Body1Black}>{t("main.settings.paymentMethodsBlock.paymentMethodsDescription")}</Typography>
                <Box sx={styles.PaymentMethodsCardBlock(medias)}>
                    <Box sx={styles.PaymentMethodsCard(medias)}>
                        <Typography>{t("main.settings.paymentMethodsBlock.card")}</Typography>
                        {UserStore.cardNumber === null ? <Cards /> : <CardDetection />}
                    </Box>

                    {UserStore.cardNumber === null ? (
                        <SButton
                            padding="10px 20px 11px 20px"
                            variant="unshadowed"
                            onClick={showAddCardDialog}
                            style={styles.buttonSize(medias)}
                        >
                            {t("main.settings.paymentMethodsBlock.addCard")}
                        </SButton>
                    ) : (
                        <Box sx={styles.PaymentMethodsBoxGroup}>
                            <SButton
                                style={styles.DeleteButtonSize(medias)}
                                variant="secondary"
                                padding="10px 20px 11px 20px"
                                onClick={showDeleteCardDialog}
                            >
                                {t("main.delete")}
                            </SButton>
                            <SButton
                                variant="unshadowed"
                                padding="10px 20px 11px 20px"
                                onClick={showAddCardDialog}
                            >
                                {t("main.settings.paymentMethodsBlock.changeCard")}
                            </SButton>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
});

export default memo(Payment);
