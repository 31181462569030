import { styleProcessing } from "API/plugins/Utilities";

export const Schedule = (theme, isExpanded) => {
    let commonStyle = {
        maxWidth: "558px",
        width: "100%",
        borderRadius: "20px",
        backgroundColor: "#FFFFFF",
        padding: "20px",
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, maxHeight: !isExpanded ? "640px" : "100%", boxSizing: "border-box", paddingTop: "0px", overflowY: !isExpanded ? "hidden" : "visible", maxWidth: "599px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Header = {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
};

export const NavButtonMobilePos = { marginTop: "-36px", marginLeft: "-19px" };

export const DateContainer = {
    width: "fit-content",
    borderRadius: "8px",
    border: "1px solid #DADADF",
    padding: "6px 8px",
};

export const DayOuterContainer = {
    display: "flex",
    alignItems: "center",
    gap: "12px",
};

export const DayBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const DaysBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
};

export const ClassItemOuterContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
};

export const Divider = {
    width: "100%",
    borderBottom: "1px dashed rgba(187, 187, 200, 0.50)",
};

export const ClassesOuterContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
};

export const BlurBox = { backdropFilter: "blur(1px)", position: "absolute", bottom: "0", left: "0", width: "100%", height: "61px" };

export const ExpandToggleButton = {
    textTransform: "none",
    color: "#6212FF",
    padding: 0,
    marginLeft: "20px",
    marginTop: "20px",
    lineHeight: "21px",
    fontSize: "14px",
    "&:hover": {
        color: "#6212FF",
    },
};

export const Button = (theme) => {
    let commonStyle = {};

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, position: "absolute", top: "-38px", left: 0 },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
