//Какой репетитор вам подходит?
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import SCheckboxGroup from "components/UI/CheckboxGroup";
import SBButtonGroup from "components/UI/ButtonGroup";
import store from "store/RequestStore";

import StepHiddenButtonWrapper from "components/OrdersBodyBlock/RenewOrder/StepHiddenButtonWrapper";
import RequestNullAlert from "./RequestNullAlert";
import { Box } from "@mui/material";

const Step10 = () => {
    const [alert, setAlert] = useState(0);

    let data = store.storage[store.activeStepIndex].substeps[store.subStepIndex]?.value;

    if (data === undefined) store.setActiveSubStepIndex(0);

    const [stepData, setStepData] = React.useState(store.storage[store.activeStepIndex].substeps[store.subStepIndex].value);
    //  const [stepData, setStepData] = React.useState(() => {
    //   const substep =
    //     store.storage[store.activeStepIndex]?.substeps[store.subStepIndex];
    //   return substep?.value || {};
    // });

    let sumWhich = "";

    const handleSumSwitch = () => {
        if (stepData.which1.selected === true) {
            sumWhich += "Начинающий репетитор до 800 рублей";
        }

        if (stepData.which2.selected === true && sumWhich !== "") {
            sumWhich += ", репетитор со средним опытом 800–2000 рублей";
        } else if (stepData.which2.selected === true && sumWhich === "") {
            sumWhich += "Репетитор со средним опытом 800–2000 рублей";
        }

        if (stepData.which3.selected === true && sumWhich !== "") {
            sumWhich += ", опытный репетитор от 2000 рублей";
        } else if (stepData.which3.selected === true && sumWhich === "") {
            sumWhich += "Опытный репетитор от 2000 рублей";
        }

        if (sumWhich) {
            store.setStep(5, sumWhich);
            store.setStepValue(5, stepData);
        }
    };

    const handleIncrement = () => {
        if (sumWhich) {
            store.incrementStepIndex();
        } else {
            setAlert(1);
        }
    };

    const handleNext = () => {
        handleSumSwitch();
        handleIncrement();
    };

    return (
        <Box>
            <SCheckboxGroup
                title="Цена указана за 60 минут занятия"
                // varstate={stepData === undefined || stepData === null ? {} : stepData}
                varstate={stepData}
                funcstate={setStepData}
            />
            <StepHiddenButtonWrapper submitRenew={handleSumSwitch}>
                <SBButtonGroup
                    handleNext={handleNext}
                    handleBack={() => store.decrementStepIndex()}
                    activeStep={store.activeStepIndex}
                    steps={store.storage}
                />
                {alert === 1 ? <RequestNullAlert>Чтобы перейти к следующему вопросу, выберите хотя бы один из вариантов</RequestNullAlert> : null}
            </StepHiddenButtonWrapper>
        </Box>
    );
};

export default observer(Step10);
