export const QrCodeContainer = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid #DADADF",
    borderRadius: "8px",
    padding: "11px 16px 12px 16px",
    cursor: "pointer",
};

export const QrCodeRadio = {
    display: "flex",
    alignItems: "center",
    gap: "16px",
};

export const Radio = {
    margin: 0,
    padding: 0,
};

export const QrCodeTitle = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
};

export const PercentageContainer = {
    borderRadius: "33px",
    background: "#FF9212",
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    padding: "0px 6px 0px 4px",
};
