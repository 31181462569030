import { makeAutoObservable } from "mobx";
import Avatar_teacher from "../assets/PAAssets/PNG/avatar_teacher.png";
import { json } from "react-router-dom";
import Photo from "assets/PAAssets/PNG/photo.png";

class FeedbackStore {
    activeTab = 1;

    minimumShowedRating = 0;
    maximumShowedRating = 5;

    newReviewsThisWeek = 3;

    reviewsNow = 200;

    classesRated = 400;

    rating = 4.9;

    currentPage = 1;

    disabledShowMore = false;

    reviewsStart = 0;
    reviewsEnd = 4;

    feedbacks = [
        {
            id: 0,
            temporaryAnswer: null,
            answer: null,
            avt: Photo,
            name: "Елизавета А",
            date: new Date(2023, 10, 28, 23, 0),
            rating: 3,
            subject: "Английский язык",
            category: "Подготовка к ЕГЭ",
            text: "Начали заниматься за 2,5 месяца до ЕГЭ. Домашнее задание и сам урок распланированы так, чтобы максимально повысить знание языка. За ЕГЭ я получил 95 баллов. Спасибо!",
        },
        {
            id: 1,
            temporaryAnswer: null,
            answer: null,
            avt: Photo,
            name: "Станислав К.",
            date: new Date(2023, 10, 28, 23, 0),
            rating: 5,
            subject: "Английский язык",
            category: "Для работы",
            text: "Я искал работу, связанную с владением английским языком. В сжатые сроки необходимо было подтянуть свои знания. Благодаря высокой квалификации и стараниям Александры Валерьевны, я в течение месяца смогла повысить свои знания и найти ту работу, которую хотела. Своим друзьям и знакомым всегда рекомендую именно этого репетитора",
        },
        {
            id: 2,
            temporaryAnswer: null,
            answer: null,
            avt: Photo,
            name: "Мария Д.",
            date: new Date(2023, 10, 28, 23, 0),
            rating: 5,
            subject: "Английский язык",
            category: "Дипломная работа",
            text: "Я искала специалиста, который мог бы помочь с дипломом. В сжатые сроки необходимо было сделать работу. Работа была сделана по всем требованиям. Своим друзьям и знакомым рекомендую именно этого специалиста!",
        },
        {
            id: 3,
            temporaryAnswer: null,
            answer: null,
            avt: Photo,
            name: "Иван Н.",
            date: new Date(2023, 10, 28, 23, 0),
            rating: 2.5,
            subject: "Английский язык",
            category: "Реферат",
            text: "Я искал специалиста, который мог бы помочь с рефератом. В сжатые сроки необходимо было сделать работу. Работа была сделана по всем требованиям. Своим друзьям и знакомым рекомендую именно этого специалиста!",
        },
        {
            id: 4,
            temporaryAnswer: null,
            answer: null,
            avt: Photo,
            name: "Иван Н.",
            date: new Date(2023, 10, 28, 23, 0),
            rating: 2.5,
            subject: "Английский язык",
            category: "Реферат",
            text: "Я искал специалиста, который мог бы помочь с рефератом. В сжатые сроки необходимо было сделать работу. Работа была сделана по всем требованиям. Своим друзьям и знакомым рекомендую именно этого специалиста!",
        },
        {
            id: 5,
            temporaryAnswer: null,
            answer: null,
            avt: Photo,
            name: "Иван Н.",
            date: new Date(2023, 10, 28, 23, 0),
            rating: 2.5,
            subject: "Английский язык",
            category: "Реферат",
            text: "Я искал специалиста, который мог бы помочь с рефератом. В сжатые сроки необходимо было сделать работу. Работа была сделана по всем требованиям. Своим друзьям и знакомым рекомендую именно этого специалиста!",
        },
        {
            id: 6,
            temporaryAnswer: null,
            answer: null,
            avt: Photo,
            name: "Иван Н.",
            date: new Date(),
            rating: 2.5,
            subject: "Английский язык",
            category: "Реферат",
            text: "Я искал специалиста, который мог бы помочь с рефератом. В сжатые сроки необходимо было сделать работу. Работа была сделана по всем требованиям. Своим друзьям и знакомым рекомендую именно этого специалиста!",
        },
        {
            id: 7,
            temporaryAnswer: null,
            answer: null,
            avt: Photo,
            name: "Иван Н.",
            date: new Date(),
            rating: 2.5,
            subject: "Английский язык",
            category: "Реферат",
            text: "Я искал специалиста, который мог бы помочь с рефератом. В сжатые сроки необходимо было сделать работу. Работа была сделана по всем требованиям. Своим друзьям и знакомым рекомендую именно этого специалиста!",
        },
        {
            id: 8,
            temporaryAnswer: null,
            answer: null,
            avt: Photo,
            name: "Иван Н.",
            date: new Date(),
            rating: 2.5,
            subject: "Английский язык",
            category: "Реферат",
            text: "Я искал специалиста, который мог бы помочь с рефератом. В сжатые сроки необходимо было сделать работу. Работа была сделана по всем требованиям. Своим друзьям и знакомым рекомендую именно этого специалиста!",
        },
    ];

    totalPage = Math.ceil(this.feedbacks.length / 4);

    tabConfig = [
        {
            id: 1,
            labelKey: "teacherAccount.rating.all",
            minRating: 0,
            maxRating: 5,
        },
        {
            id: 2,
            labelKey: "teacherAccount.rating.positive",
            minRating: 3.1,
            maxRating: 5,
        },
        {
            id: 3,
            labelKey: "teacherAccount.rating.negative",
            minRating: 0,
            maxRating: 3,
        },
    ];

    constructor() {
        makeAutoObservable(this);
    }

    setTotalPage(value) {
        this.totalPage = value;
    }

    setReviewsEnd(value) {
        this.reviewsEnd = value;
    }

    setReviewsStart(value) {
        this.reviewsStart = value;
    }

    setDisabledShowMore(value) {
        this.disabledShowMore = value;
    }

    setCurrentPage(value) {
        this.currentPage = value;
    }

    setActiveTab(value) {
        this.activeTab = value;
    }

    setMinimumShowedRating(value) {
        this.minimumShowedRating = value;
    }

    setMaximumShowedRating(value) {
        this.maximumShowedRating = value;
    }

    SetFeedBackAnswer(id, value) {
        this.feedbacks[this.feedbacks.findIndex((element) => element.id === id)].answer = value;
    }

    SetFeedBackTemporaryAnswer(id, value) {
        this.feedbacks[this.feedbacks.findIndex((element) => element.id === id)].temporaryAnswer = value;
    }

    sortedFeedbacks = () => {
        return this.feedbacks.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
    };
}

export default new FeedbackStore();
