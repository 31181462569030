import { styleProcessing } from "API/plugins/Utilities";

export const ModalRateClassContent = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    textAlign: "center",
    alignItems: "center",
};

export const ModalRateClass = (condition) => {
    return {
        display: "flex",
        flexDirection: "column",
        gap: condition ? "15px" : "30px",
        textAlign: "center",
        alignItems: "center",
        padding: "10px 0 30px 0",
    };
};

export const ClassDetails = (theme, props) => {
    let commonStyle = {
        display: "flex",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: props === null ? "undefind" : "column",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TutorPhoto = (theme, props) => {
    let commonStyle = {
        width: "60px",
        height: "60px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            display: props === null ? "undefind" : "flex",
            alignSelf: props === null ? "undefind" : "center",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ClassInfo = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "start",
    gap: "8px",
};

export const RatingContainer = {
    padding: "20px 0 20px 0",
    borderTop: "1px dashed #DADADF",
    borderBottom: "1px dashed #DADADF",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "center",
};

export const Review = {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
};

export const ButtonSend = {
    width: "160px",
};

export const Rating = {
    "& .MuiRating-icon": {
        marginRight: "5px",
    },
};
