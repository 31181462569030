export const imageContainer = {
    position: "relative",
    width: "130px",
    height: "130px",
    borderRadius: "10px",
};

export const imageAttachmentsContainer = {
    maxHeight: "70vh",
    width: "100%",
    overflow: "scroll",
    padding: "20px 127px 0px 127px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const imageBox = {
    width: "fit-content",
    cursor: "pointer",
    height: "130px",
    borderRadius: "10px",
    background: "#F0EFFF",
};

export const durationBox = {
    transform: "translate(0%, -550%)",
    background: "#fff",
    width: "fit-content",
};

export const imageDateBox = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
};

export const imagesBox = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
};
