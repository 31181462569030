import React from "react";
import { observer } from "mobx-react-lite";
import ActiveOrders from "./ActiveOrders/ActiveOrders";
import BonusBox from "components/BalanceBodyBlock/BonusBox/BonusBox";
import OrdersInWork from "./OrdersInWork/OrdersInWork";
import Slider from "./Stories/Slider/Slider";
import TodayClasses from "components/MyClassesBodyBlock/CurrentClasses/TodayClasses/TodayClasses";
import MyClassesStore from "store/MyClassesStore";
import { Box, Typography } from "@mui/material";
import * as textStyles from "themes/TextThemes";
import * as styles from "./StudentAccountThemes";
import InviteBlock from "./InviteBlock/InviteBlock";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import { useMedias } from "API/mediasHook";

const StudentAccount = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const MobileVersion = (
        <>
            {/* {MyClassesStore.classes.length ? <TodayClasses type="account" /> : null} */}
            {/* <Slider /> */}
            <ActiveOrders />
            {/* <OrdersInWork /> */}
            {/* <BonusBox /> */}
            {/* <InviteBlock link={"https://www.study-buddy.ru"} /> */}
        </>
    );

    return (
        <>
            {medias.sm ? (
                <Box sx={styles.StudentAccount}>
                    <Box sx={styles.StudentAccountContent}>
                        <Box sx={styles.LeftPanel}>
                            {MyClassesStore.classes.length ? <TodayClasses type="account" /> : null}
                            <ActiveOrders />
                        </Box>
                        <Box sx={styles.RightPanel}>
                            <Box sx={styles.StoriesContainer}>
                                <Box sx={styles.StoriesTitle}>
                                    <Typography sx={textStyles.HeadlineH4Black}>{t("main.studentAccount.forYou")}</Typography>
                                </Box>
                                <Slider />
                            </Box>
                            <OrdersInWork />
                            <Box sx={styles.BonusesInviteContainer}>
                                <BonusBox />
                                <InviteBlock />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box sx={styles.StudentAccountContent}>{MobileVersion}</Box>
            )}
        </>
    );
});

export default memo(StudentAccount);
