const ExclamationIconGrey = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.00582 0.666016C4.40734 0.666016 0.667969 4.40539 0.667969 8.99935C0.667969 13.5933 4.40734 17.3327 9.0013 17.3327C13.5953 17.3327 17.3346 13.5933 17.3346 8.99935C17.3346 4.40539 13.5998 0.666016 9.00582 0.666016ZM9.00582 15.09C8.41801 15.09 7.93872 14.6107 7.93872 14.0229C7.93872 13.4351 8.41801 12.9558 9.00582 12.9558C9.59363 12.9558 10.0729 13.4351 10.0729 14.0229C10.0729 14.6107 9.59363 15.09 9.00582 15.09ZM10.5341 5.52811L9.5665 11.2149C9.52129 11.5269 9.28164 11.753 9.0013 11.753C8.72096 11.753 8.48132 11.5269 8.4361 11.2149L7.46848 5.52811C7.33464 4.41602 7.53178 3.70454 7.83473 3.35185C8.07889 3.06699 8.40897 2.90874 8.75714 2.90874H9.24095C9.58911 2.90874 9.91919 3.06699 10.1634 3.35185C10.4708 3.70454 10.668 4.41602 10.5341 5.52811Z"
                fill="#9E9EB0"
            />
        </svg>
    );
};

export default ExclamationIconGrey;
