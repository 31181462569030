import { observer } from "mobx-react-lite";
import React, { memo, useEffect, useState } from "react";
import { IconPin } from "assets/MiniChat/SVG/IconPin";
import { ReadIcon } from "assets/MiniChat/SVG/ReadIcon";
import { SentIcon } from "assets/MiniChat/SVG/SentIcon";
import Clock from "assets/MiniChat/SVG/Clock";
import * as themes from "./TabsListThemes";
import * as textStyles from "themes/TextThemes";
import { Avatar, Badge, Box, styled, Typography } from "@mui/material";
import { convertDataInTabsChat } from "components/ChatBodyBlock/CommonData/helper_functions";
import FullChatStore from "store/FullChatStore";
import { TabPanel } from "components/AuthorizationBodyBlock/Tabs/Tabs";
import { Muted } from "assets/MiniChat/SVG/Muted";
import ContextMenu from "./ContextMenu/ContextMenu";
import ChatListsTabs from "./Tabs/ChatListsTabs";
import userstore from "store/UserStore";

export const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        backgroundColor: "#44b700",
        color: "#44b700",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&::after": {
            position: "absolute",
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}));

const TabsList = observer(({ children, ...props }) => {
    const GetEmpty = (index) => {
        if (index === 0)
            return (
                <Box sx={themes.TabsDivEmpty}>
                    <Box sx={themes.TabsEmpty}> Здесь будут все ваши чаты</Box>
                </Box>
            );

        if (index === 1)
            return (
                <Box sx={themes.TabsDivEmpty}>
                    <Box sx={themes.TabsEmpty}> Здесь будут ваши {userstore.role === "tutor" ? "студенты" : "специалисты"}</Box>
                </Box>
            );

        if (index === 2)
            return (
                <Box sx={themes.TabsDivEmpty}>
                    <Box sx={themes.TabsEmpty}> Здесь будут {userstore.role === "tutor" ? "студенты" : "специалисты"}, помещенные в архив</Box>
                    <Box sx={themes.TabsInfo}> Вы можете разархивировать их в любое время</Box>
                </Box>
            );

        if (index === 3)
            return (
                <Box sx={themes.TabsDivEmpty}>
                    <Box sx={themes.TabsEmpty}> Здесь будут {userstore.role === "tutor" ? "студенты" : "специалисты"}, которых вы заблокировали </Box>
                    <Box sx={themes.TabsInfo}> Пока {userstore.role === "tutor" ? "студент" : "специалист"} в этом списке, он не сможет вам писать. Вы можете разблокировать его в любое время </Box>
                </Box>
            );

        if (index > 3)
            return (
                <Box sx={themes.TabsDivEmpty}>
                    <Box sx={themes.TabsEmpty}> В этой папке пока ничего нет </Box>
                </Box>
            );
    };

    const [page, setPage] = React.useState(0);

    const [open, setOpen] = useState(false);

    const [menuChatID, SetMenuChatID] = React.useState(null);

    const [menuParticipantID, SetMenuParticipantID] = React.useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpen = (item, item_chat, event) => {
        event.preventDefault();
        SetMenuChatID(item_chat.chat_id);
        SetMenuParticipantID(item_chat.uuid);
        item.blockedby.includes(item_chat.uuid) ? FullChatStore.SetBlockedBy(true) : FullChatStore.SetBlockedBy(false);
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleTabChange = (item, item_chat) => {
        FullChatStore.SetChatID(item_chat.chat_id);
        FullChatStore.SetParticipantID(item_chat.uuid);
        FullChatStore.SetToScrollMessage(null);
        FullChatStore.SetToScrollMessageWait(null);
        FullChatStore.SetCurrMessagePinnedId(item_chat.pinned_messages?.length > 0 ? item_chat.pinned_messages[item_chat.pinned_messages?.length - 1] : null);
        item.blockedby.includes(item_chat.uuid) ? FullChatStore.SetBlockedBy(true) : FullChatStore.SetBlockedBy(false);
        item.index === 3 ? FullChatStore.SetBlocked(true) : FullChatStore.SetBlocked(false);
    };

    const getStatus = (lastMessage, muted) => {
        if (lastMessage.unread_messages === 0) {
            if (lastMessage.pin === true) return <IconPin />;

            if (lastMessage.read_message) return <ReadIcon />;

            if (lastMessage.send_message) return <SentIcon />;
            else if (lastMessage.last_message) return <Clock />;
            else return null;
        } else {
            return <Box style={{ ...themes.UnreadMessage, backgroundColor: muted ? "#BBBBC8" : "#6212FF" }}>{lastMessage.unread_messages}</Box>;
        }
    };

    return (
        <Box sx={themes.TabsList}>
            <ChatListsTabs
                folders={convertDataInTabsChat(FullChatStore.tabsData)}
                pageChange={setPage}
            />
            {convertDataInTabsChat(FullChatStore.tabsData).map((item, tabIndex) => (
                <Box
                    key={tabIndex}
                    sx={themes.Chats}
                >
                    <TabPanel
                        value={page}
                        index={item.index}
                        sx={themes.TabPanel}
                    >
                        {item.infocards.length > 0 ? (
                            <Box>
                                {item.infocards
                                    .filter((chat) => !!(chat.name.toLowerCase().includes(props.name.toLowerCase()) && chat.pin))
                                    .map((item_chat, index) => (
                                        <Box
                                            key={index + "pinned"}
                                            sx={themes.MessageBox(item_chat.chat_id === FullChatStore.id)}
                                            onClick={() => handleTabChange(item, item_chat)}
                                            onContextMenu={handleOpen.bind(this, item, item_chat)}
                                        >
                                            <StyledBadge
                                                overlap="circular"
                                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                                variant="dot"
                                                invisible={item_chat.status.online !== true}
                                            >
                                                <Avatar
                                                    src={item_chat.avatar}
                                                    sx={themes.Avatar}
                                                />
                                            </StyledBadge>
                                            <Box style={themes.MessageCenter}>
                                                <Box style={themes.NameBox}>
                                                    <Typography sx={textStyles.Body2Black}>{item_chat.name}</Typography>
                                                    <Box sx={{ height: "18px" }}>{item_chat.disabled_notifications && <Muted />}</Box>
                                                </Box>
                                                <Typography sx={{ ...textStyles.Body2DarkGrey, height: "14px" }}>
                                                    {item_chat.last_message.substr(0, 25)}
                                                    {item_chat.last_message.length > 25 && "..."}
                                                </Typography>
                                            </Box>
                                            <Box style={themes.MessageRight}>
                                                <Typography sx={textStyles.Caption2Grey}>{item_chat.time_message}</Typography>
                                                {getStatus(item_chat, item.disabled_notifications)}
                                            </Box>
                                        </Box>
                                    ))}
                                {item.infocards
                                    .filter((chat) => chat.name.toLowerCase().includes(props.name.toLowerCase()) && !chat.pin)
                                    .map((item_chat, index) => (
                                        <Box
                                            key={index + "unpinned"}
                                            sx={themes.MessageBox(item_chat.chat_id === FullChatStore.id)}
                                            onClick={() => handleTabChange(item, item_chat)}
                                            onContextMenu={handleOpen.bind(this, item, item_chat)}
                                        >
                                            <StyledBadge
                                                overlap="circular"
                                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                                variant="dot"
                                                invisible={item_chat.status.online !== true}
                                            >
                                                <Avatar
                                                    src={item_chat.avatar}
                                                    sx={themes.Avatar}
                                                />
                                            </StyledBadge>
                                            <Box style={themes.MessageCenter}>
                                                <Box style={themes.NameBox}>
                                                    <Typography sx={textStyles.Body2Black}>{item_chat.name}</Typography>
                                                    <Box sx={{ height: "18px" }}>{item_chat.disabled_notifications && <Muted />}</Box>
                                                </Box>
                                                <Typography sx={{ ...textStyles.Body2DarkGrey, height: "14px" }}>
                                                    {item_chat.last_message.substr(0, 25)}
                                                    {item_chat.last_message.length > 25 && "..."}
                                                </Typography>
                                            </Box>
                                            <Box style={themes.MessageRight}>
                                                <Typography sx={textStyles.Caption2Grey}>{item_chat.time_message}</Typography>
                                                {getStatus(item_chat, item.disabled_notifications)}
                                            </Box>
                                        </Box>
                                    ))}
                            </Box>
                        ) : (
                            GetEmpty(item.index)
                        )}
                    </TabPanel>
                </Box>
            ))}

            {menuChatID && menuParticipantID && (
                <ContextMenu
                    menuChatID={menuChatID}
                    menuParticipantID={menuParticipantID}
                    open={open}
                    setOpen={setOpen}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                />
            )}
        </Box>
    );
});

export default memo(TabsList);
