import { createTheme } from "@mui/material/styles";

export const actionsContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "320px",
    height: "42px",
    flex: "none",
    order: 1,
    flexGrow: 0,
    gap: "8px",
    bottom: "66px",
    alignItems: "center",
};

export const priceContainerStyle = {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    gap: "10px",
    height: "32px",
};

export const textContainerStyle = {
    display: "flex",
    gap: "5px",
};

export const fromTextStyle = {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#9E9EB0",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const fromTextValueStyle = {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#222222",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const AddCashbackButtonTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "5px",
                    height: "32px",
                    padding: "6px 14px 6px 13px!important",
                    background: "linear-gradient(256.9deg, #04C83B 20.58%, #12AAFF 81.82%)",
                    borderRadius: "32px!important",
                    flex: "none",
                    minWidth: "0px!important",
                    order: 1,
                    flexGrow: 0,
                },
            },
        },

        MuiTooltip: {
            styleOverrides: {
                root: {},
                tooltip: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    padding: "10px",
                    boxSizing: "border-box",
                    marginLeft: "6px!important",
                    background: "#FFFFFF",
                    borderRadius: "10px",
                    flex: "none",
                    order: 1,
                    flexGrow: 1,
                    filter: "drop-shadow(0px 2px 10px rgba(106, 99, 118, 0.15))",
                },
                arrow: {
                    color: "white!important",
                    filter: "drop-shadow(0px 2px 10px rgba(106, 99, 118, 0.15))",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                },
            },
        },
    },
});

export const tooltipCashbackStyle = {
    width: "214px",
    /*maxWidth: '214px!important',*/
    /*height: '54px',*/
    fontWtyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "140%",
    display: "flex",
    alignItems: "center",
    color: "#222222!important",
    flex: "none",
    order: 0,
    flexGrow: 1,
};

export const tooltipCashbackValueStyle = {
    color: "#19B20C",
};

export const cashbackTextStyle = {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const buttonsContainerStyle = (styleMode, medias) => {
    if (styleMode === 0) {
        return {
            position: "relative",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "0px",
            gap: "10px",
            width: "286px",
            height: "42px",
            right: "0px",
            top: "0px",
        };
    }

    if (styleMode === 1) {
        return {
            position: "relative",
            display: medias.sm ? "flex" : "none",
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "0px",
            gap: "10px",
            width: "286px",
            height: "42px",
            right: "0px",
            top: "0px",
        };
    }
};

export const LikeIconActiveTheme = (medias) => {
    return {
        components: {
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        position: medias.sm ? "relative" : "static",
                        width: "24px",
                        height: "24px",
                        flex: "none",
                        order: 4,
                        flexGrow: 0,
                        zIndex: 4,
                        "&:hover": {
                            background: "#FFFFFF!important",
                            borderColor: "#6212FF",
                        },
                        "&:active": {
                            background: "#FFFFFF!important",
                            borderColor: "#6212FF",
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        padding: 0,
                        ".MuiSvgIcon-root": {
                            width: "24px",
                            height: "24px",
                            color: "#222222!important",
                            "&:hover": {
                                color: "#6212FF",
                                borderColor: "#6212FF",
                            },
                            "&:active": {
                                color: "#6212FF",
                                borderColor: "#6212FF",
                            },
                        },
                        "&:hover": {
                            color: "#6212FF",
                            borderColor: "#6212FF",
                        },
                        "&:active": {
                            color: "#6212FF",
                            borderColor: "#6212FF",
                        },
                    },
                },
            },
        },
    };
};

export const LikeIconInactiveTheme = (medias, style) => {
    return {
        components: {
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        position: medias.sm ? "relative" : "static",
                        width: "24px",
                        height: "24px",
                        flex: "none",
                        order: 4,
                        flexGrow: 0,
                        zIndex: 4,
                        "&:hover": {
                            background: "#FFFFFF!important",
                            borderColor: "#6212FF",
                        },
                        "&:active": {
                            background: "#FFFFFF!important",
                            borderColor: "#6212FF",
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        padding: 0,
                        ".MuiSvgIcon-root": {
                            width: "24px",
                            height: "24px",
                            stroke: "#FFFFFF",
                            strokeWidth: 1,
                        },
                        "&:hover": {
                            color: "#6212FF",
                            borderColor: "#6212FF",
                        },
                        "&:active": {
                            color: "#6212FF",
                            borderColor: "#6212FF",
                        },
                    },
                },
            },
        },
    };
};
