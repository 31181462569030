export const ArrowForward = () => {
    return (
        <svg
            width="2.2vh"
            height="2.2vh"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.5 4L9.5 8.5L5.5 13"
                stroke="#4E4E4E"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
