import React, { memo, useState } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./OrdersDisciplineThemes";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Button, Chip, IconButton } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import OrdersSearchStore from "store/OrdersSearchStore";
import { CloseRounded } from "@mui/icons-material";
import ModalDisciplines from "./ModalDisciplines";

const OrdersDiscipline = observer(({ children, ...props }) => {
    const [dialogOpen, SetDialogOpen] = useState(false);

    const handleFromPAClick = async () => {
        await OrdersSearchStore.SetOrdersDisciplineFromPA();
    };

    const handleFromListClick = () => {
        OrdersSearchStore.CopyOrdersDisciplineList(0);
        SetDialogOpen(true);
    };

    const discardDialog = () => {
        OrdersSearchStore.SetDisciplineSearchDebounced("");
        SetDialogOpen(false);
    };

    const saveDialog = () => {
        OrdersSearchStore.SetDisciplineSearchDebounced("");
        OrdersSearchStore.CopyOrdersDisciplineList(1);
        SetDialogOpen(false);
    };

    const handleDelete = (value, event) => {
        console.log(value);
        OrdersSearchStore.ClearOrdersDiscipline(value);
    };

    return (
        <Box sx={themes.ordersDisciplineContainerStyle}>
            <Typography sx={themes.headerOrdersDisciplineStyle}>Предметы</Typography>
            {OrdersSearchStore.selectedOrdersDisciplineChipList.length > 0 ? (
                <ThemeProvider theme={themes.OrdersChipTheme}>
                    <Box sx={themes.selectedOrdersDisciplineContainerStyle}>
                        {OrdersSearchStore.selectedOrdersDisciplineChipList.map((value) => (
                            <Chip
                                key={value.ID}
                                label={value.OrdersDiscipline}
                                deleteIcon={
                                    <IconButton onClick={handleDelete.bind(this, value)}>
                                        <CloseRounded></CloseRounded>
                                    </IconButton>
                                }
                                onDelete={handleDelete.bind(this, value)}
                            />
                        ))}
                    </Box>
                </ThemeProvider>
            ) : null}
            <Box sx={themes.ordersDisciplineActionsStyle}>
                <ThemeProvider theme={themes.FilterActionButtonTheme}>
                    <Button
                        onClick={handleFromPAClick}
                        disableRipple
                    >
                        <Typography style={themes.fromPATextStyle}>Как в анкете</Typography>
                    </Button>
                    <Button
                        onClick={handleFromListClick}
                        disableRipple
                    >
                        <Typography style={themes.fromListTextStyle}>Добавить</Typography>
                    </Button>
                </ThemeProvider>
            </Box>
            <ModalDisciplines
                open={dialogOpen}
                handleDiscard={discardDialog}
                handleSave={saveDialog}
            ></ModalDisciplines>
        </Box>
    );
});

export default memo(OrdersDiscipline);
