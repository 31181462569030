import React from "react";
import { useState } from "react";
import { FormControlLabel, RadioGroup } from "@mui/material";
import * as themes from "./OrderResponseRadioSelectStyled";
import { observer } from "mobx-react-lite";
import orderResponseStore from "store/OrderResponseStore";
import { useRef, useEffect } from "react";
import DismissTextField from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/_DismissTextField/DismissTextField";

const OrderResponseRadioSelect = observer(({ list, setIsother }) => {
    const [selectedValue, setSelectedValue] = useState("");

    const [otherValue, setOtherValue] = useState("");

    const isInitialRender = useRef(true);

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        if (setIsother !== undefined) {
            setIsother(event.target.value);
        }
    };

    const handleOtherChange = (event) => {
        setOtherValue(event.target.value);
    };

    const hasNonWhitespaceCharacters = /\S/.test(otherValue);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }

        if (selectedValue !== "other" || hasNonWhitespaceCharacters) {
            orderResponseStore.setIsDisabled(false);
        } else {
            orderResponseStore.setIsDisabled(true);
        }
    }, [selectedValue, otherValue, hasNonWhitespaceCharacters]);

    return (
        <>
            <RadioGroup
                value={selectedValue}
                onChange={handleChange}
                sx={themes.ResponseRadioSelectRadioGroup}
            >
                {list.map((el, index) => (
                    <FormControlLabel
                        key={index}
                        sx={themes.ResponseRadioFormControlLabel}
                        value={el.value}
                        control={<themes.StyledRadio />}
                        label={
                            <>
                                {orderResponseStore.isDismissClicked ? (
                                    <DismissTextField
                                        value={el.value}
                                        selectedValue={selectedValue}
                                        label={el.label}
                                        otherValue={otherValue}
                                        handleOtherChange={handleOtherChange}
                                    />
                                ) : (
                                    el.label
                                )}
                            </>
                        }
                    />
                ))}
            </RadioGroup>
        </>
    );
});

export default OrderResponseRadioSelect;
