import { makeAutoObservable } from "mobx";
import Evgen from "assets/ChatAssets/PNG/Evgen.png";
import Aleksndra from "assets/ChatAssets/PNG/Aleksndra.png";
import Liza from "assets/ChatAssets/PNG/Liza.png";
import Maria from "assets/ChatAssets/PNG/Maria.png";
import Pavel from "assets/ChatAssets/PNG/Pavel.png";
import Ekater from "assets/ChatAssets/PNG/Ekater.png";
class StudentOrdersStore {
    orders = [];

    currentId = 0;
    currenteText = "";
    toggleDrawer = false;
    submitButtonFilter = true;

    setIsOpened = (order, value) => {
        const objectToUpdate = this.orders.find((obj) => obj.baseOrderInfo.orderUuid === order.baseOrderInfo.orderUuid);

        objectToUpdate.baseOrderInfo.isOpened = value;
    };

    setIsPersonal = (order, value) => {
        const objectToUpdate = this.orders.find((obj) => obj.baseOrderInfo.orderUuid === order.baseOrderInfo.orderUuid);

        objectToUpdate.baseOrderInfo.isPersonal = value;
    };

    Init() {
        this.Load();
    }

    Load() {
        this.orders = [
            {
                baseOrderInfo: {
                    orderUuid: "123e4567-e89b-12d3-a456-426614174001",
                    ownerUuid: "789e1234-e89b-12d3-a456-426614174111",
                    orderType: "tutoring",
                    createdAt: new Date(new Date().setHours(new Date().getHours() - 10)),
                    updatedAt: null,
                    finishedAt: null,
                    removalTime: null,
                    tags: ["Английский язык", "Подготовка к экзамену в ВУЗе"],
                    status: "active",
                    isOpened: true,
                    isPersonal: false,
                    doer: null,
                    views: [
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                    ],
                    responses: [
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Evgen,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: true,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Liza,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Maria,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Pavel,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Aleksndra,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Aleksndra,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Aleksndra,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                    ],
                    offers: [
                        {
                            offerUuid: "654e3210-e89b-12d3-a456-426614174444",
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            offerTime: "2024-10-03T10:00:00Z",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Олег",
                                surname: "Максимов",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Evgen,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            isCanceled: false,
                            isAccepted: true,
                            isRejected: false,
                        },
                    ],
                    grade: 0,
                    requestTopic: "Репетитор по английскому",
                    subject: "Английский язык английский язык английский язык английский язык",
                    clientRole: "student",
                    clientPersonalInfo: {
                        course: 2,
                        class: 0,
                    },
                    estimatedCost: {
                        costValue: 1000,
                        cantEstimateValue: false,
                    },
                    comment: "Looking for an experienced tutor for exam preparation.",
                    uploadedFiles: [
                        {
                            link: "https://example.com/file1.pdf",
                            name: "Algebra syllabus",
                            contentType: "application/pdf",
                            sizeInBytes: 524288,
                        },
                    ],
                },
                classPurpose: "Подготовка к экзамену в ВУЗе",
                studentUniversityExamAdditionalInfo: {
                    university: "St. Petersburg University",
                    showTutorsOnlyFromMyUniversity: false,
                    timeToExam: "2 months",
                },
                classesPlaceTypes: ["online"],
                classesPlace: {
                    city: "St. Petersburg",
                    locations: ["Nevsky Prospekt"],
                },
                classesWeekFrequency: "3 times a week",
                classesTime: [
                    {
                        weekDay: "Monday",
                        startTime: "2024-10-07T09:00:00Z",
                        finishTime: "2024-10-07T10:30:00Z",
                    },
                    {
                        weekDay: "Wednesday",
                        startTime: "2024-10-09T09:00:00Z",
                        finishTime: "2024-10-09T10:30:00Z",
                    },
                ],
                tutorPracticeLevel: ["Advanced"],
                tutorRequirements: {
                    gender: "female",
                    minAge: 25,
                    maxAge: 40,
                },
                inWorkStatus: "accepted_for_work",
                studentWorkAdditionalInfo: {
                    institutionType: "college",
                    institutionName: "ВШЭ",
                    DontShowRequestForMyInstituteMembers: true,
                },
                isExpressOrder: false,
                startTime: "2024-10-01T10:00:00Z",
                finishTime: "2024-10-01T10:00:00Z",
                workDetails: {
                    pagesNum: 120,
                    sourcesNum: 30,
                    includeForeignSources: true,
                },
                workAdditionalRequirements: {
                    originalityPercentage: "30%",
                    originalityCheckoutMethod: "Антиплагиат.ру",
                    originalityCheckoutReport: false,
                },
                additionalServices: ["review"],
            },
            {
                baseOrderInfo: {
                    orderUuid: "123e4567-e89b-12d3-a456-426614174002",
                    ownerUuid: "789e1234-e89b-12d3-a456-426614174111",
                    orderType: "work",
                    createdAt: new Date(new Date().setHours(new Date().getHours() - 10)),
                    updatedAt: null,
                    finishedAt: null,
                    removalTime: null,
                    tags: ["Теория вероятностей", "Подготовка к экзамену в ВУЗе"],
                    status: "active",
                    isOpened: true,
                    isPersonal: false,
                    doer: null,
                    views: [
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                    ],
                    responses: [
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: true,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Aleksndra,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                    ],
                    offers: [
                        {
                            offerUuid: "654e3210-e89b-12d3-a456-426614174444",
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            offerTime: "2024-10-03T10:00:00Z",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Олег",
                                surname: "Максимов",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Evgen,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            isCanceled: false,
                            isAccepted: true,
                            isRejected: false,
                        },
                    ],
                    grade: 0,
                    requestTopic: "Написание дипломной работы",
                    subject: "Информационная безопасность",
                    clientRole: "student",
                    clientPersonalInfo: {
                        course: 2,
                        class: 0,
                    },
                    estimatedCost: {
                        costValue: 1000,
                        cantEstimateValue: false,
                    },
                    comment: "Looking for an experienced tutor for exam preparation.",
                    uploadedFiles: [
                        {
                            link: "https://example.com/file1.pdf",
                            name: "Algebra syllabus",
                            contentType: "application/pdf",
                            sizeInBytes: 524288,
                        },
                    ],
                },
                classPurpose: "Exam preparation",
                studentUniversityExamAdditionalInfo: {
                    university: "St. Petersburg University",
                    showTutorsOnlyFromMyUniversity: false,
                    timeToExam: "2 months",
                },
                classesPlaceTypes: ["online"],
                classesPlace: {
                    city: "St. Petersburg",
                    locations: ["Nevsky Prospekt"],
                },
                classesWeekFrequency: "3 times a week",
                classesTime: [
                    {
                        weekDay: "Monday",
                        startTime: "2024-10-07T09:00:00Z",
                        finishTime: "2024-10-07T10:30:00Z",
                    },
                    {
                        weekDay: "Wednesday",
                        startTime: "2024-10-09T09:00:00Z",
                        finishTime: "2024-10-09T10:30:00Z",
                    },
                ],
                tutorPracticeLevel: ["Advanced"],
                tutorRequirements: {
                    gender: "female",
                    minAge: 25,
                    maxAge: 40,
                },
                inWorkStatus: "accepted_for_work",
                studentWorkAdditionalInfo: {
                    institutionType: "college",
                    institutionName: "ВШЭ",
                    DontShowRequestForMyInstituteMembers: true,
                },
                isExpressOrder: false,
                startTime: "2024-10-01T10:00:00Z",
                finishTime: "2024-10-01T10:00:00Z",
                workDetails: {
                    pagesNum: 120,
                    sourcesNum: 30,
                    includeForeignSources: true,
                },
                workAdditionalRequirements: {
                    originalityPercentage: "30%",
                    originalityCheckoutMethod: "Антиплагиат.ру",
                    originalityCheckoutReport: false,
                },
                additionalServices: ["review"],
            },
            {
                baseOrderInfo: {
                    orderUuid: "123e4567-e89b-12d3-a456-426614174003",
                    ownerUuid: "789e1234-e89b-12d3-a456-426614174111",
                    orderType: "tutoring",
                    createdAt: new Date(new Date().setHours(new Date().getHours() - 10)),
                    updatedAt: null,
                    finishedAt: null,
                    removalTime: null,
                    tags: ["Теория вероятностей", "Подготовка к экзамену в ВУЗе"],
                    status: "active",
                    isOpened: false,
                    isPersonal: true,
                    doer: null,
                    views: [
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                    ],
                    responses: [
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Aleksndra,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                    ],
                    offers: [
                        {
                            offerUuid: "654e3210-e89b-12d3-a456-426614174444",
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            offerTime: "2024-10-03T10:00:00Z",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Олег",
                                surname: "Максимов",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Evgen,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            isCanceled: false,
                            isAccepted: true,
                            isRejected: false,
                        },
                    ],
                    grade: 0,
                    requestTopic: "Теория вероятностей",
                    subject: null,
                    clientRole: "student",
                    clientPersonalInfo: {
                        course: 2,
                        class: 0,
                    },
                    estimatedCost: {
                        costValue: 1000,
                        cantEstimateValue: false,
                    },
                    comment: "Looking for an experienced tutor for exam preparation.",
                    uploadedFiles: [
                        {
                            link: "https://example.com/file1.pdf",
                            name: "Algebra syllabus",
                            contentType: "application/pdf",
                            sizeInBytes: 524288,
                        },
                    ],
                },
                classPurpose: "Повышение успеваемости",
                studentUniversityExamAdditionalInfo: {
                    university: "St. Petersburg University",
                    showTutorsOnlyFromMyUniversity: false,
                    timeToExam: "2 months",
                },
                classesPlaceTypes: ["online"],
                classesPlace: {
                    city: "St. Petersburg",
                    locations: ["Nevsky Prospekt"],
                },
                classesWeekFrequency: "3 times a week",
                classesTime: [
                    {
                        weekDay: "Monday",
                        startTime: "2024-10-07T09:00:00Z",
                        finishTime: "2024-10-07T10:30:00Z",
                    },
                    {
                        weekDay: "Wednesday",
                        startTime: "2024-10-09T09:00:00Z",
                        finishTime: "2024-10-09T10:30:00Z",
                    },
                ],
                tutorPracticeLevel: ["Advanced"],
                tutorRequirements: {
                    gender: "female",
                    minAge: 25,
                    maxAge: 40,
                },
                inWorkStatus: "accepted_for_work",
                studentWorkAdditionalInfo: {
                    institutionType: "college",
                    institutionName: "ВШЭ",
                    DontShowRequestForMyInstituteMembers: true,
                },
                isExpressOrder: false,
                startTime: "2024-10-01T10:00:00Z",
                finishTime: "2024-10-01T10:00:00Z",
                workDetails: {
                    pagesNum: 120,
                    sourcesNum: 30,
                    includeForeignSources: true,
                },
                workAdditionalRequirements: {
                    originalityPercentage: "30%",
                    originalityCheckoutMethod: "Антиплагиат.ру",
                    originalityCheckoutReport: false,
                },
                additionalServices: ["review"],
            },
            {
                baseOrderInfo: {
                    orderUuid: "123e4567-e89b-12d3-a456-426614174004",
                    ownerUuid: "789e1234-e89b-12d3-a456-426614174111",
                    orderType: "work",
                    createdAt: new Date(new Date().setHours(new Date().getHours() - 10)),
                    updatedAt: null,
                    finishedAt: null,
                    removalTime: null,
                    tags: ["Теория вероятностей", "Подготовка к экзамену в ВУЗе"],
                    status: "waiting_for_finish",
                    isOpened: true,
                    isPersonal: false,
                    doer: {
                        uuid: "456e7890-e89b-12d3-a456-426614174222",
                        name: "Мария",
                        surname: "Иванова",
                        anonymous: false,
                        photo: {
                            link: Aleksndra,
                        },
                    },
                    views: [
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                    ],
                    responses: [
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Aleksndra,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                    ],
                    offers: [
                        {
                            offerUuid: "654e3210-e89b-12d3-a456-426614174444",
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            offerTime: "2024-10-03T10:00:00Z",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Олег",
                                surname: "Максимов",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Evgen,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            isCanceled: false,
                            isAccepted: true,
                            isRejected: false,
                        },
                    ],
                    grade: 0,
                    requestTopic: "Курсовая работа",
                    subject: "Экономическая кибернетика",
                    clientRole: "student",
                    clientPersonalInfo: {
                        course: 2,
                        class: 0,
                    },
                    estimatedCost: {
                        costValue: 1000,
                        cantEstimateValue: false,
                    },
                    comment: "Looking for an experienced tutor for exam preparation.",
                    uploadedFiles: [
                        {
                            link: "https://example.com/file1.pdf",
                            name: "Algebra syllabus",
                            contentType: "application/pdf",
                            sizeInBytes: 524288,
                        },
                    ],
                },
                classPurpose: "Exam preparation",
                studentUniversityExamAdditionalInfo: {
                    university: "St. Petersburg University",
                    showTutorsOnlyFromMyUniversity: false,
                    timeToExam: "2 months",
                },
                classesPlaceTypes: ["online"],
                classesPlace: {
                    city: "St. Petersburg",
                    locations: ["Nevsky Prospekt"],
                },
                classesWeekFrequency: "3 times a week",
                classesTime: [
                    {
                        weekDay: "Monday",
                        startTime: "2024-10-07T09:00:00Z",
                        finishTime: "2024-10-07T10:30:00Z",
                    },
                    {
                        weekDay: "Wednesday",
                        startTime: "2024-10-09T09:00:00Z",
                        finishTime: "2024-10-09T10:30:00Z",
                    },
                ],
                tutorPracticeLevel: ["Advanced"],
                tutorRequirements: {
                    gender: "female",
                    minAge: 25,
                    maxAge: 40,
                },
                inWorkStatus: "accepted_for_work",
                studentWorkAdditionalInfo: {
                    institutionType: "college",
                    institutionName: "ВШЭ",
                    DontShowRequestForMyInstituteMembers: true,
                },
                isExpressOrder: false,
                startTime: "2024-10-01T10:00:00Z",
                finishTime: new Date(new Date().setDate(new Date().getDate() + 1)),
                workDetails: {
                    pagesNum: 120,
                    sourcesNum: 30,
                    includeForeignSources: true,
                },
                workAdditionalRequirements: {
                    originalityPercentage: "30%",
                    originalityCheckoutMethod: "Антиплагиат.ру",
                    originalityCheckoutReport: false,
                },
                additionalServices: ["review"],
            },
            {
                baseOrderInfo: {
                    orderUuid: "123e4567-e89b-12d3-a456-426614174005",
                    ownerUuid: "789e1234-e89b-12d3-a456-426614174111",
                    orderType: "work",
                    createdAt: new Date(new Date().setHours(new Date().getHours() - 10)),
                    updatedAt: null,
                    finishedAt: null,
                    removalTime: null,
                    tags: ["Теория вероятностей", "Подготовка к экзамену в ВУЗе"],
                    status: "waiting_for_finish",
                    isOpened: true,
                    isPersonal: false,
                    doer: {
                        uuid: "456e7890-e89b-12d3-a456-426614174222",
                        name: "Мария",
                        surname: "Иванова",
                        anonymous: false,
                        photo: {
                            link: Aleksndra,
                        },
                    },
                    views: [
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                    ],
                    responses: [
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Aleksndra,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                    ],
                    offers: [
                        {
                            offerUuid: "654e3210-e89b-12d3-a456-426614174444",
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            offerTime: "2024-10-03T10:00:00Z",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Олег",
                                surname: "Максимов",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Evgen,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            isCanceled: false,
                            isAccepted: true,
                            isRejected: false,
                        },
                    ],
                    grade: 0,
                    requestTopic: "Курсовая работа курсовая работа курсовая работа Курсовая работа",
                    subject: "Экономическая кибернетика экономическая кибернетика экономическая кибернетика экономическая кибернетика ",
                    clientRole: "student",
                    clientPersonalInfo: {
                        course: 2,
                        class: 0,
                    },
                    estimatedCost: {
                        costValue: 1000,
                        cantEstimateValue: false,
                    },
                    comment: "Looking for an experienced tutor for exam preparation.",
                    uploadedFiles: [
                        {
                            link: "https://example.com/file1.pdf",
                            name: "Algebra syllabus",
                            contentType: "application/pdf",
                            sizeInBytes: 524288,
                        },
                    ],
                },
                classPurpose: "Exam preparation",
                studentUniversityExamAdditionalInfo: {
                    university: "St. Petersburg University",
                    showTutorsOnlyFromMyUniversity: false,
                    timeToExam: "2 months",
                },
                classesPlaceTypes: ["online"],
                classesPlace: {
                    city: "St. Petersburg",
                    locations: ["Nevsky Prospekt"],
                },
                classesWeekFrequency: "3 times a week",
                classesTime: [
                    {
                        weekDay: "Monday",
                        startTime: "2024-10-07T09:00:00Z",
                        finishTime: "2024-10-07T10:30:00Z",
                    },
                    {
                        weekDay: "Wednesday",
                        startTime: "2024-10-09T09:00:00Z",
                        finishTime: "2024-10-09T10:30:00Z",
                    },
                ],
                tutorPracticeLevel: ["Advanced"],
                tutorRequirements: {
                    gender: "female",
                    minAge: 25,
                    maxAge: 40,
                },
                inWorkStatus: "in_work",
                studentWorkAdditionalInfo: {
                    institutionType: "college",
                    institutionName: "ВШЭ",
                    DontShowRequestForMyInstituteMembers: true,
                },
                isExpressOrder: false,
                startTime: "2024-10-01T10:00:00Z",
                finishTime: new Date(new Date().setDate(new Date().getDate() - 2)),
                workDetails: {
                    pagesNum: 120,
                    sourcesNum: 30,
                    includeForeignSources: true,
                },
                workAdditionalRequirements: {
                    originalityPercentage: "30%",
                    originalityCheckoutMethod: "Антиплагиат.ру",
                    originalityCheckoutReport: false,
                },
                additionalServices: ["review"],
            },
            {
                baseOrderInfo: {
                    orderUuid: "123e4567-e89b-12d3-a456-426614174006",
                    ownerUuid: "789e1234-e89b-12d3-a456-426614174111",
                    orderType: "work",
                    createdAt: new Date(new Date().setHours(new Date().getHours() - 10)),
                    updatedAt: null,
                    finishedAt: null,
                    removalTime: null,
                    tags: ["Теория вероятностей", "Подготовка к экзамену в ВУЗе"],
                    status: "waiting_for_finish",
                    isOpened: true,
                    isPersonal: false,
                    doer: {
                        uuid: "456e7890-e89b-12d3-a456-426614174222",
                        name: "Мария",
                        surname: "Иванова",
                        anonymous: true,
                        photo: {
                            link: Aleksndra,
                        },
                    },
                    views: [
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                    ],
                    responses: [
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Aleksndra,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                    ],
                    offers: [
                        {
                            offerUuid: "654e3210-e89b-12d3-a456-426614174444",
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            offerTime: "2024-10-03T10:00:00Z",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Олег",
                                surname: "Максимов",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Evgen,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            isCanceled: false,
                            isAccepted: true,
                            isRejected: false,
                        },
                    ],
                    grade: 0,
                    requestTopic: "Курсовая работа",
                    subject: "Экономическая кибернетика",
                    clientRole: "student",
                    clientPersonalInfo: {
                        course: 2,
                        class: 0,
                    },
                    estimatedCost: {
                        costValue: 1000,
                        cantEstimateValue: false,
                    },
                    comment: "Looking for an experienced tutor for exam preparation.",
                    uploadedFiles: [
                        {
                            link: "https://example.com/file1.pdf",
                            name: "Algebra syllabus",
                            contentType: "application/pdf",
                            sizeInBytes: 524288,
                        },
                    ],
                },
                classPurpose: "Exam preparation",
                studentUniversityExamAdditionalInfo: {
                    university: "St. Petersburg University",
                    showTutorsOnlyFromMyUniversity: false,
                    timeToExam: "2 months",
                },
                classesPlaceTypes: ["online"],
                classesPlace: {
                    city: "St. Petersburg",
                    locations: ["Nevsky Prospekt"],
                },
                classesWeekFrequency: "3 times a week",
                classesTime: [
                    {
                        weekDay: "Monday",
                        startTime: "2024-10-07T09:00:00Z",
                        finishTime: "2024-10-07T10:30:00Z",
                    },
                    {
                        weekDay: "Wednesday",
                        startTime: "2024-10-09T09:00:00Z",
                        finishTime: "2024-10-09T10:30:00Z",
                    },
                ],
                tutorPracticeLevel: ["Advanced"],
                tutorRequirements: {
                    gender: "female",
                    minAge: 25,
                    maxAge: 40,
                },
                inWorkStatus: "waiting_for_check",
                studentWorkAdditionalInfo: {
                    institutionType: "college",
                    institutionName: "ВШЭ",
                    DontShowRequestForMyInstituteMembers: true,
                },
                isExpressOrder: false,
                startTime: "2024-10-01T10:00:00Z",
                finishTime: new Date(new Date().setDate(new Date().getDate() + 55)),
                workDetails: {
                    pagesNum: 120,
                    sourcesNum: 30,
                    includeForeignSources: true,
                },
                workAdditionalRequirements: {
                    originalityPercentage: "30%",
                    originalityCheckoutMethod: "Антиплагиат.ру",
                    originalityCheckoutReport: false,
                },
                additionalServices: ["review"],
            },
            {
                baseOrderInfo: {
                    orderUuid: "123e4567-e89b-12d3-a456-426614174007",
                    ownerUuid: "789e1234-e89b-12d3-a456-426614174111",
                    orderType: "tutoring",
                    createdAt: new Date(new Date().setHours(new Date().getHours() - 100)),
                    updatedAt: null,
                    finishedAt: new Date(new Date().setHours(new Date().getHours() - 90)),
                    removalTime: null,
                    tags: ["Теория вероятностей", "Подготовка к экзамену в ВУЗе"],
                    status: "finished",
                    isOpened: true,
                    isPersonal: false,
                    doer: {
                        uuid: "456e7890-e89b-12d3-a456-426614174222",
                        name: "Александра",
                        surname: "Андронова",
                        anonymous: false,
                        photo: {
                            link: Aleksndra,
                        },
                    },
                    views: [
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                    ],
                    responses: [
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Aleksndra,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                    ],
                    offers: [
                        {
                            offerUuid: "654e3210-e89b-12d3-a456-426614174444",
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            offerTime: "2024-10-03T10:00:00Z",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Олег",
                                surname: "Максимов",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Evgen,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            isCanceled: false,
                            isAccepted: true,
                            isRejected: false,
                        },
                    ],
                    grade: null,
                    requestTopic: "Английский язык",
                    subject: null,
                    clientRole: "student",
                    clientPersonalInfo: {
                        course: 2,
                        class: 0,
                    },
                    estimatedCost: {
                        costValue: 1000,
                        cantEstimateValue: false,
                    },
                    comment: "Looking for an experienced tutor for exam preparation.",
                    uploadedFiles: [
                        {
                            link: "https://example.com/file1.pdf",
                            name: "Algebra syllabus",
                            contentType: "application/pdf",
                            sizeInBytes: 524288,
                        },
                    ],
                },
                classPurpose: "Повышение успеваемости",
                studentUniversityExamAdditionalInfo: {
                    university: "St. Petersburg University",
                    showTutorsOnlyFromMyUniversity: false,
                    timeToExam: "2 months",
                },
                classesPlaceTypes: ["online"],
                classesPlace: {
                    city: "St. Petersburg",
                    locations: ["Nevsky Prospekt"],
                },
                classesWeekFrequency: "3 times a week",
                classesTime: [
                    {
                        weekDay: "Monday",
                        startTime: "2024-10-07T09:00:00Z",
                        finishTime: "2024-10-07T10:30:00Z",
                    },
                    {
                        weekDay: "Wednesday",
                        startTime: "2024-10-09T09:00:00Z",
                        finishTime: "2024-10-09T10:30:00Z",
                    },
                ],
                tutorPracticeLevel: ["Advanced"],
                tutorRequirements: {
                    gender: "female",
                    minAge: 25,
                    maxAge: 40,
                },
                inWorkStatus: "waiting_for_check",
                studentWorkAdditionalInfo: {
                    institutionType: "college",
                    institutionName: "ВШЭ",
                    DontShowRequestForMyInstituteMembers: true,
                },
                isExpressOrder: false,
                startTime: "2024-10-01T10:00:00Z",
                finishTime: new Date(new Date().setDate(new Date().getDate() + 55)),
                workDetails: {
                    pagesNum: 120,
                    sourcesNum: 30,
                    includeForeignSources: true,
                },
                workAdditionalRequirements: {
                    originalityPercentage: "30%",
                    originalityCheckoutMethod: "Антиплагиат.ру",
                    originalityCheckoutReport: false,
                },
                additionalServices: ["review"],
            },
            {
                baseOrderInfo: {
                    orderUuid: "123e4567-e89b-12d3-a456-426614174008",
                    ownerUuid: "789e1234-e89b-12d3-a456-426614174111",
                    orderType: "work",
                    createdAt: new Date(new Date().setDate(new Date().getDate() - 100)),
                    updatedAt: null,
                    finishedAt: new Date(new Date().setDate(new Date().getDate() - 90)),
                    removalTime: null,
                    tags: ["Теория вероятностей", "Подготовка к экзамену в ВУЗе"],
                    status: "finished",
                    isOpened: true,
                    isPersonal: false,
                    doer: {
                        uuid: "456e7890-e89b-12d3-a456-426614174222",
                        name: "Мария",
                        surname: "Иванова",
                        anonymous: true,
                        photo: {
                            link: Aleksndra,
                        },
                    },
                    views: [
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                    ],
                    responses: [
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Aleksndra,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                    ],
                    offers: [
                        {
                            offerUuid: "654e3210-e89b-12d3-a456-426614174444",
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            offerTime: "2024-10-03T10:00:00Z",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Олег",
                                surname: "Максимов",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Evgen,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            isCanceled: false,
                            isAccepted: true,
                            isRejected: false,
                        },
                    ],
                    grade: null,
                    requestTopic: "Курсовая работа",
                    subject: "Информационная безопасность",
                    clientRole: "student",
                    clientPersonalInfo: {
                        course: 2,
                        class: 0,
                    },
                    estimatedCost: {
                        costValue: 1000,
                        cantEstimateValue: false,
                    },
                    comment: "Looking for an experienced tutor for exam preparation.",
                    uploadedFiles: [
                        {
                            link: "https://example.com/file1.pdf",
                            name: "Algebra syllabus",
                            contentType: "application/pdf",
                            sizeInBytes: 524288,
                        },
                    ],
                },
                classPurpose: "Повышение успеваемости",
                studentUniversityExamAdditionalInfo: {
                    university: "St. Petersburg University",
                    showTutorsOnlyFromMyUniversity: false,
                    timeToExam: "2 months",
                },
                classesPlaceTypes: ["online"],
                classesPlace: {
                    city: "St. Petersburg",
                    locations: ["Nevsky Prospekt"],
                },
                classesWeekFrequency: "3 times a week",
                classesTime: [
                    {
                        weekDay: "Monday",
                        startTime: "2024-10-07T09:00:00Z",
                        finishTime: "2024-10-07T10:30:00Z",
                    },
                    {
                        weekDay: "Wednesday",
                        startTime: "2024-10-09T09:00:00Z",
                        finishTime: "2024-10-09T10:30:00Z",
                    },
                ],
                tutorPracticeLevel: ["Advanced"],
                tutorRequirements: {
                    gender: "female",
                    minAge: 25,
                    maxAge: 40,
                },
                inWorkStatus: "waiting_for_check",
                studentWorkAdditionalInfo: {
                    institutionType: "college",
                    institutionName: "ВШЭ",
                    DontShowRequestForMyInstituteMembers: true,
                },
                isExpressOrder: false,
                startTime: "2024-10-01T10:00:00Z",
                finishTime: new Date(new Date().setDate(new Date().getDate() + 55)),
                workDetails: {
                    pagesNum: 120,
                    sourcesNum: 30,
                    includeForeignSources: true,
                },
                workAdditionalRequirements: {
                    originalityPercentage: "30%",
                    originalityCheckoutMethod: "Антиплагиат.ру",
                    originalityCheckoutReport: false,
                },
                additionalServices: ["review"],
            },
            {
                baseOrderInfo: {
                    orderUuid: "123e4567-e89b-12d3-a456-426614174009",
                    ownerUuid: "789e1234-e89b-12d3-a456-426614174111",
                    orderType: "tutoring",
                    createdAt: new Date(new Date().setHours(new Date().getHours() - 100)),
                    updatedAt: null,
                    finishedAt: new Date(new Date().setHours(new Date().getHours() - 90)),
                    removalTime: null,
                    tags: ["Теория вероятностей", "Подготовка к экзамену в ВУЗе"],
                    status: "finished",
                    isOpened: true,
                    isPersonal: false,
                    doer: {
                        uuid: "456e7890-e89b-12d3-a456-426614174222",
                        name: "Александра",
                        surname: "Андронова",
                        anonymous: false,
                        photo: {
                            link: Aleksndra,
                        },
                    },
                    views: [
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                    ],
                    responses: [
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Aleksndra,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                    ],
                    offers: [
                        {
                            offerUuid: "654e3210-e89b-12d3-a456-426614174444",
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            offerTime: "2024-10-03T10:00:00Z",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Олег",
                                surname: "Максимов",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Evgen,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            isCanceled: false,
                            isAccepted: true,
                            isRejected: false,
                        },
                    ],
                    grade: 2,
                    requestTopic: "Английский языкуацауцац",
                    subject: "Английский язык",
                    clientRole: "student",
                    clientPersonalInfo: {
                        course: 2,
                        class: 0,
                    },
                    estimatedCost: {
                        costValue: 1000,
                        cantEstimateValue: false,
                    },
                    comment: "Looking for an experienced tutor for exam preparation.",
                    uploadedFiles: [
                        {
                            link: "https://example.com/file1.pdf",
                            name: "Algebra syllabus",
                            contentType: "application/pdf",
                            sizeInBytes: 524288,
                        },
                    ],
                },
                classPurpose: " Повышение успеваемости Повышение успеваемостиПовышение успеваемостиПовышение ",
                studentUniversityExamAdditionalInfo: {
                    university: "St. Petersburg University",
                    showTutorsOnlyFromMyUniversity: false,
                    timeToExam: "2 months",
                },
                classesPlaceTypes: ["online"],
                classesPlace: {
                    city: "St. Petersburg",
                    locations: ["Nevsky Prospekt"],
                },
                classesWeekFrequency: "3 times a week",
                classesTime: [
                    {
                        weekDay: "Monday",
                        startTime: "2024-10-07T09:00:00Z",
                        finishTime: "2024-10-07T10:30:00Z",
                    },
                    {
                        weekDay: "Wednesday",
                        startTime: "2024-10-09T09:00:00Z",
                        finishTime: "2024-10-09T10:30:00Z",
                    },
                ],
                tutorPracticeLevel: ["Advanced"],
                tutorRequirements: {
                    gender: "female",
                    minAge: 25,
                    maxAge: 40,
                },
                inWorkStatus: "waiting_for_check",
                studentWorkAdditionalInfo: {
                    institutionType: "college",
                    institutionName: "ВШЭ",
                    DontShowRequestForMyInstituteMembers: true,
                },
                isExpressOrder: false,
                startTime: "2024-10-01T10:00:00Z",
                finishTime: new Date(new Date().setDate(new Date().getDate() + 55)),
                workDetails: {
                    pagesNum: 120,
                    sourcesNum: 30,
                    includeForeignSources: true,
                },
                workAdditionalRequirements: {
                    originalityPercentage: "30%",
                    originalityCheckoutMethod: "Антиплагиат.ру",
                    originalityCheckoutReport: false,
                },
                additionalServices: ["review"],
            },
            {
                baseOrderInfo: {
                    orderUuid: "123e4567-e89b-12d3-a456-426614174010",
                    ownerUuid: "789e1234-e89b-12d3-a456-426614174111",
                    orderType: "work",
                    createdAt: new Date(new Date().setDate(new Date().getDate() - 100)),
                    updatedAt: null,
                    finishedAt: new Date(new Date().setDate(new Date().getDate() - 90)),
                    removalTime: null,
                    tags: ["Теория вероятностей", "Подготовка к экзамену в ВУЗе"],
                    status: "finished",
                    isOpened: true,
                    isPersonal: false,
                    doer: {
                        uuid: "456e7890-e89b-12d3-a456-426614174222",
                        name: "Мария",
                        surname: "Иванова",
                        anonymous: true,
                        photo: {
                            link: Aleksndra,
                        },
                    },
                    views: [
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                    ],
                    responses: [
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Aleksndra,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                    ],
                    offers: [
                        {
                            offerUuid: "654e3210-e89b-12d3-a456-426614174444",
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            offerTime: "2024-10-03T10:00:00Z",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Олег",
                                surname: "Максимов",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Evgen,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            isCanceled: false,
                            isAccepted: true,
                            isRejected: false,
                        },
                    ],
                    grade: 5,
                    requestTopic: "Курсовая работа",
                    subject: "Информационная безопасность Информационная безопасностьИнформационная",
                    clientRole: "student",
                    clientPersonalInfo: {
                        course: 2,
                        class: 0,
                    },
                    estimatedCost: {
                        costValue: 1000,
                        cantEstimateValue: false,
                    },
                    comment: "Looking for an experienced tutor for exam preparation.",
                    uploadedFiles: [
                        {
                            link: "https://example.com/file1.pdf",
                            name: "Algebra syllabus",
                            contentType: "application/pdf",
                            sizeInBytes: 524288,
                        },
                    ],
                },
                classPurpose: "Повышение успеваемости",
                studentUniversityExamAdditionalInfo: {
                    university: "St. Petersburg University",
                    showTutorsOnlyFromMyUniversity: false,
                    timeToExam: "2 months",
                },
                classesPlaceTypes: ["online"],
                classesPlace: {
                    city: "St. Petersburg",
                    locations: ["Nevsky Prospekt"],
                },
                classesWeekFrequency: "3 times a week",
                classesTime: [
                    {
                        weekDay: "Monday",
                        startTime: "2024-10-07T09:00:00Z",
                        finishTime: "2024-10-07T10:30:00Z",
                    },
                    {
                        weekDay: "Wednesday",
                        startTime: "2024-10-09T09:00:00Z",
                        finishTime: "2024-10-09T10:30:00Z",
                    },
                ],
                tutorPracticeLevel: ["Advanced"],
                tutorRequirements: {
                    gender: "female",
                    minAge: 25,
                    maxAge: 40,
                },
                inWorkStatus: "waiting_for_check",
                studentWorkAdditionalInfo: {
                    institutionType: "college",
                    institutionName: "ВШЭ",
                    DontShowRequestForMyInstituteMembers: true,
                },
                isExpressOrder: false,
                startTime: "2024-10-01T10:00:00Z",
                finishTime: new Date(new Date().setDate(new Date().getDate() + 55)),
                workDetails: {
                    pagesNum: 120,
                    sourcesNum: 30,
                    includeForeignSources: true,
                },
                workAdditionalRequirements: {
                    originalityPercentage: "30%",
                    originalityCheckoutMethod: "Антиплагиат.ру",
                    originalityCheckoutReport: false,
                },
                additionalServices: ["review"],
            },
            {
                baseOrderInfo: {
                    orderUuid: "123e4567-e89b-12d3-a456-426614174011",
                    ownerUuid: "789e1234-e89b-12d3-a456-426614174111",
                    orderType: "tutoring",
                    createdAt: new Date(new Date().setDate(new Date().getDate() - 100)),
                    updatedAt: null,
                    finishedAt: new Date(new Date().setDate(new Date().getDate() - 90)),
                    removalTime: new Date(new Date().setDate(new Date().getDate() + 20)),
                    tags: ["Теория вероятностей", "Подготовка к экзамену в ВУЗе"],
                    status: "cancelled",
                    isOpened: true,
                    isPersonal: false,
                    doer: {
                        uuid: "456e7890-e89b-12d3-a456-426614174222",
                        name: "Мария",
                        surname: "Иванова",
                        anonymous: true,
                        photo: {
                            link: Aleksndra,
                        },
                    },
                    views: [
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                    ],
                    responses: [
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Aleksndra,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: true,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Liza,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Maria,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Pavel,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Aleksndra,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Aleksndra,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Aleksndra,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                    ],
                    offers: [
                        {
                            offerUuid: "654e3210-e89b-12d3-a456-426614174444",
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            offerTime: "2024-10-03T10:00:00Z",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Олег",
                                surname: "Максимов",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Evgen,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            isCanceled: false,
                            isAccepted: true,
                            isRejected: false,
                        },
                    ],
                    grade: 5,
                    requestTopic: "Английский язык",
                    subject: null,
                    clientRole: "student",
                    clientPersonalInfo: {
                        course: 2,
                        class: 0,
                    },
                    estimatedCost: {
                        costValue: 1000,
                        cantEstimateValue: false,
                    },
                    comment: "Looking for an experienced tutor for exam preparation.",
                    uploadedFiles: [
                        {
                            link: "https://example.com/file1.pdf",
                            name: "Algebra syllabus",
                            contentType: "application/pdf",
                            sizeInBytes: 524288,
                        },
                    ],
                },
                classPurpose: "Подготовка к экзамену в ВУЗе",
                studentUniversityExamAdditionalInfo: {
                    university: "St. Petersburg University",
                    showTutorsOnlyFromMyUniversity: false,
                    timeToExam: "2 months",
                },
                classesPlaceTypes: ["online"],
                classesPlace: {
                    city: "St. Petersburg",
                    locations: ["Nevsky Prospekt"],
                },
                classesWeekFrequency: "3 times a week",
                classesTime: [
                    {
                        weekDay: "Monday",
                        startTime: "2024-10-07T09:00:00Z",
                        finishTime: "2024-10-07T10:30:00Z",
                    },
                    {
                        weekDay: "Wednesday",
                        startTime: "2024-10-09T09:00:00Z",
                        finishTime: "2024-10-09T10:30:00Z",
                    },
                ],
                tutorPracticeLevel: ["Advanced"],
                tutorRequirements: {
                    gender: "female",
                    minAge: 25,
                    maxAge: 40,
                },
                inWorkStatus: "waiting_for_check",
                studentWorkAdditionalInfo: {
                    institutionType: "college",
                    institutionName: "ВШЭ",
                    DontShowRequestForMyInstituteMembers: true,
                },
                isExpressOrder: false,
                startTime: "2024-10-01T10:00:00Z",
                finishTime: new Date(new Date().setDate(new Date().getDate() + 55)),
                workDetails: {
                    pagesNum: 120,
                    sourcesNum: 30,
                    includeForeignSources: true,
                },
                workAdditionalRequirements: {
                    originalityPercentage: "30%",
                    originalityCheckoutMethod: "Антиплагиат.ру",
                    originalityCheckoutReport: false,
                },
                additionalServices: ["review"],
            },
            {
                baseOrderInfo: {
                    orderUuid: "123e4567-e89b-12d3-a456-426614174012",
                    ownerUuid: "789e1234-e89b-12d3-a456-426614174111",
                    orderType: "tutoring",
                    createdAt: new Date(new Date().setDate(new Date().getDate() - 100)),
                    updatedAt: null,
                    finishedAt: new Date(new Date().setDate(new Date().getDate() - 90)),
                    removalTime: new Date(new Date().setDate(new Date().getDate() + 10)),
                    tags: ["Теория вероятностей", "Подготовка к экзамену в ВУЗе"],
                    status: "cancelled",
                    isOpened: true,
                    isPersonal: false,
                    doer: {
                        uuid: "456e7890-e89b-12d3-a456-426614174222",
                        name: "Мария",
                        surname: "Иванова",
                        anonymous: true,
                        photo: {
                            link: Aleksndra,
                        },
                    },
                    views: [
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                    ],
                    responses: [
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Aleksndra,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                    ],
                    offers: [
                        {
                            offerUuid: "654e3210-e89b-12d3-a456-426614174444",
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            offerTime: "2024-10-03T10:00:00Z",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Олег",
                                surname: "Максимов",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Evgen,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            isCanceled: false,
                            isAccepted: true,
                            isRejected: false,
                        },
                    ],
                    grade: 5,
                    requestTopic: "Математикefwfewfewfewа",
                    subject: "Математика",
                    clientRole: "student",
                    clientPersonalInfo: {
                        course: 2,
                        class: 0,
                    },
                    estimatedCost: {
                        costValue: 1000,
                        cantEstimateValue: false,
                    },
                    comment: "Looking for an experienced tutor for exam preparation.",
                    uploadedFiles: [
                        {
                            link: "https://example.com/file1.pdf",
                            name: "Algebra syllabus",
                            contentType: "application/pdf",
                            sizeInBytes: 524288,
                        },
                    ],
                },
                classPurpose: "Повышение успеваемости",
                studentUniversityExamAdditionalInfo: {
                    university: "St. Petersburg University",
                    showTutorsOnlyFromMyUniversity: false,
                    timeToExam: "2 months",
                },
                classesPlaceTypes: ["online"],
                classesPlace: {
                    city: "St. Petersburg",
                    locations: ["Nevsky Prospekt"],
                },
                classesWeekFrequency: "3 times a week",
                classesTime: [
                    {
                        weekDay: "Monday",
                        startTime: "2024-10-07T09:00:00Z",
                        finishTime: "2024-10-07T10:30:00Z",
                    },
                    {
                        weekDay: "Wednesday",
                        startTime: "2024-10-09T09:00:00Z",
                        finishTime: "2024-10-09T10:30:00Z",
                    },
                ],
                tutorPracticeLevel: ["Advanced"],
                tutorRequirements: {
                    gender: "female",
                    minAge: 25,
                    maxAge: 40,
                },
                inWorkStatus: "waiting_for_check",
                studentWorkAdditionalInfo: {
                    institutionType: "college",
                    institutionName: "ВШЭ",
                    DontShowRequestForMyInstituteMembers: true,
                },
                isExpressOrder: false,
                startTime: "2024-10-01T10:00:00Z",
                finishTime: new Date(new Date().setDate(new Date().getDate() + 55)),
                workDetails: {
                    pagesNum: 120,
                    sourcesNum: 30,
                    includeForeignSources: true,
                },
                workAdditionalRequirements: {
                    originalityPercentage: "30%",
                    originalityCheckoutMethod: "Антиплагиат.ру",
                    originalityCheckoutReport: false,
                },
                additionalServices: ["review"],
            },
            {
                baseOrderInfo: {
                    orderUuid: "123e4567-e89b-12d3-a456-426614174013",
                    ownerUuid: "789e1234-e89b-12d3-a456-426614174111",
                    orderType: "work",
                    createdAt: new Date(new Date().setDate(new Date().getDate() - 100)),
                    updatedAt: null,
                    finishedAt: new Date(new Date().setDate(new Date().getDate() - 90)),
                    removalTime: new Date(new Date().setDate(new Date().getDate() + 1)),
                    tags: ["Теория вероятностей", "Подготовка к экзамену в ВУЗе"],
                    status: "cancelled",
                    isOpened: true,
                    isPersonal: false,
                    doer: {
                        uuid: "456e7890-e89b-12d3-a456-426614174222",
                        name: "Мария",
                        surname: "Иванова",
                        anonymous: true,
                        photo: {
                            link: Aleksndra,
                        },
                    },
                    views: [
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                        {
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            viewTime: "2024-10-03T08:00:00Z",
                        },
                    ],
                    responses: [
                        {
                            responseUuid: "321e6540-e89b-12d3-a456-426614174333",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Мария",
                                surname: "Иванова",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Aleksndra,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            responseTime: "2024-10-04T15:00:00Z",
                            tutorPrice: 1500,
                            priceByAgreement: false,
                            comment: "I can help with advanced algebra.",
                            isAccepted: false,
                            isRejected: false,
                        },
                    ],
                    offers: [
                        {
                            offerUuid: "654e3210-e89b-12d3-a456-426614174444",
                            tutorUuid: "456e7890-e89b-12d3-a456-426614174222",
                            offerTime: "2024-10-03T10:00:00Z",
                            tutor: {
                                uuid: "456e7890-e89b-12d3-a456-426614174222",
                                name: "Олег",
                                surname: "Максимов",
                                anonymous: false,
                                subject: [{ subjectName: "", conditions: [] }],
                                classFormats: ["online", "at_the_students", "at_the_tutors"],
                                tutorsPlace: [{ city: "Moscow", locations: [{ station: "Фрунзенская", colors: ["red"] }] }],
                                studentsPlaces: [{ city: "Moscow", locations: [{ station: "Парк культры", colors: ["red", "brown"] }] }],
                                educations: [
                                    {
                                        educationUuid: "456e7890-e89b-12d3-a456-426614174222",
                                        university: "НИУ ВШЭ",
                                        faculty: "Международные отношения",
                                        speciality: "Восточная Азия",
                                        startYear: 2018,
                                        studyNowadays: false,
                                        finishYear: 2021,
                                        confirmatoryDocuments: [],
                                    },
                                ],
                                experience: [
                                    {
                                        organization: "Тинькофф",
                                        post: "руководитель отдела продаж",
                                        startYear: 2022,
                                        workNowadays: true,
                                        finishYear: null,
                                    },
                                ],
                                lastOnline: "2024-10-03T08:00:00Z",
                                anonymousProfile: false,
                                photo: {
                                    link: Evgen,
                                },
                                bonus: {
                                    pointsPreviousMonth: 12000,
                                    pointsThisMonth: 6500,
                                    finishedClassesCount: 1000,
                                },
                            },
                            isCanceled: false,
                            isAccepted: true,
                            isRejected: false,
                        },
                    ],
                    grade: 5,
                    requestTopic: "Дипломная работа",
                    subject: "Информационная безопасность",
                    clientRole: "student",
                    clientPersonalInfo: {
                        course: 2,
                        class: 0,
                    },
                    estimatedCost: {
                        costValue: 1000,
                        cantEstimateValue: false,
                    },
                    comment: "Looking for an experienced tutor for exam preparation.",
                    uploadedFiles: [
                        {
                            link: "https://example.com/file1.pdf",
                            name: "Algebra syllabus",
                            contentType: "application/pdf",
                            sizeInBytes: 524288,
                        },
                    ],
                },
                classPurpose: "Повышение успеваемости",
                studentUniversityExamAdditionalInfo: {
                    university: "St. Petersburg University",
                    showTutorsOnlyFromMyUniversity: false,
                    timeToExam: "2 months",
                },
                classesPlaceTypes: ["online"],
                classesPlace: {
                    city: "St. Petersburg",
                    locations: ["Nevsky Prospekt"],
                },
                classesWeekFrequency: "3 times a week",
                classesTime: [
                    {
                        weekDay: "Monday",
                        startTime: "2024-10-07T09:00:00Z",
                        finishTime: "2024-10-07T10:30:00Z",
                    },
                    {
                        weekDay: "Wednesday",
                        startTime: "2024-10-09T09:00:00Z",
                        finishTime: "2024-10-09T10:30:00Z",
                    },
                ],
                tutorPracticeLevel: ["Advanced"],
                tutorRequirements: {
                    gender: "female",
                    minAge: 25,
                    maxAge: 40,
                },
                inWorkStatus: "waiting_for_check",
                studentWorkAdditionalInfo: {
                    institutionType: "college",
                    institutionName: "ВШЭ",
                    DontShowRequestForMyInstituteMembers: true,
                },
                isExpressOrder: false,
                startTime: "2024-10-01T10:00:00Z",
                finishTime: new Date(new Date().setDate(new Date().getDate() + 55)),
                workDetails: {
                    pagesNum: 120,
                    sourcesNum: 30,
                    includeForeignSources: true,
                },
                workAdditionalRequirements: {
                    originalityPercentage: "30%",
                    originalityCheckoutMethod: "Антиплагиат.ру",
                    originalityCheckoutReport: false,
                },
                additionalServices: ["review"],
            },
        ];
    }

    constructor() {
        this.Init();
        makeAutoObservable(this);
    }
    setToggleDrawer() {
        this.toggleDrawer = !this.toggleDrawer;
    }
    setSubmitButtonFilter(submit) {
        this.submitButtonFilter = submit;
    }
}

export default new StudentOrdersStore();
