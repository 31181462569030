import ReactPlayer from "react-player";
import { observer } from "mobx-react-lite";
import * as Styles from "./EditFolderStyles";
import { Avatar, Box, Grid, InputAdornment, TextField } from "@mui/material";
import { ChatIcon } from "assets/MiniChat/SVG/ChatIcon";
import { Create } from "assets/MiniChat/SVG/Сreate";
import SButton from "components/UI/SButton";
import MiniChatStore from "store/MiniChatStore";

const Chats = (props) => {
    const ListChat = () => {
        console.log(MiniChatStore.newFolder);
        if (MiniChatStore.newFolder !== undefined) {
            if (MiniChatStore.newFolder.length > 0 || MiniChatStore.FolderChanged)
                return MiniChatStore.newFolder.map((itemchat, index) => (
                    <Grid
                        key={index}
                        container
                        sx={Styles.ChatItem}
                    >
                        <Grid
                            item
                            xs={1.4}
                        >
                            <Avatar
                                src={itemchat.avatar}
                                sx={{ width: "4.4vh", height: "4.4vh" }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={9.6}
                            sx={{ paddingTop: "0.8vh" }}
                        >
                            {itemchat.name}
                        </Grid>
                    </Grid>
                ));
            else
                return (
                    props.folderChats &&
                    props.folderChats.infocards.map((itemchat, index) => (
                        <Grid
                            key={index}
                            container
                            sx={Styles.ChatItem}
                        >
                            <Grid
                                item
                                xs={1.4}
                            >
                                <Avatar
                                    src={itemchat.avatar}
                                    sx={{ width: "4.4vh", height: "4.4vh" }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={9.6}
                                sx={{ paddingTop: "0.8vh" }}
                            >
                                {itemchat.name}
                            </Grid>
                        </Grid>
                    ))
                );
        } else
            return (
                props.folderChats &&
                props.folderChats.infocards.map((itemchat, index) => (
                    <Grid
                        key={index}
                        container
                        sx={Styles.ChatItem}
                    >
                        <Grid
                            item
                            xs={1.4}
                        >
                            <Avatar
                                src={itemchat.avatar}
                                sx={{ width: "4.4vh", height: "4.4vh" }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={9.6}
                            sx={{ paddingTop: "0.8vh" }}
                        >
                            {itemchat.name}
                        </Grid>
                    </Grid>
                ))
            );
    };

    return (
        <Box sx={Styles.ChatBox}>
            <TextField
                value="Чаты"
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <ChatIcon />
                        </InputAdornment>
                    ),
                }}
                sx={Styles.TextStyles}
                margin="none"
            />
            <Grid
                sx={{ paddingTop: "1.28vh", paddingBottom: "1.28vh" }}
                container
            >
                <Grid
                    sx={{ cursor: "pointer", marginLeft: "1.3vw" }}
                    item
                    onClick={() => props.setPage(3)}
                >
                    <Create color="#6212FF" />
                </Grid>
                <Grid
                    item
                    xs={10}
                    sx={Styles.ButtonGrid}
                >
                    {" "}
                    <SButton
                        onClick={() => {
                            props.setPage(3);
                        }}
                        variant="text"
                    >
                        <Box sx={{ fontSize: "1.9vh" }}>Добавить чат</Box>
                    </SButton>{" "}
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{ overflow: "auto", maxHeight: "31vh" }}
                >
                    {ListChat()}
                </Grid>
            </Grid>
        </Box>
    );
};

export default observer(Chats);
