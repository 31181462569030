import { styleProcessing } from "API/plugins/Utilities";

export const LevelWrapper = (theme) => {
    let commonStyle = {
        marginBottom: "60px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            marginBottom: "40px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherQuestionnaireTopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
};

export const TeacherQuestionnaireSubTitle = {
    maxWidth: "598px",
    width: "100%",
};

export const TeacherQuestionnaireProgramExamplesContainer = {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    width: "100%",
    marginTop: "30px",
};

export const TeacherQuestionnaireClassesNumberExampleBox = (theme) => {
    let commonStyle = {
        height: "94px",
        width: "269px",
        borderRadius: "20px",
        border: "1px solid #f0efff",
        background: "#fff",
        boxShadow: "0px 4px 4px 0px rgba(230, 229, 240, 0.4)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "20px 0 20px 20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            display: "none",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherQuestionnaireClassesNumberExampleInnerBox = {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
};

export const RightExampleBox = (theme) => {
    let commonStyle = {
        width: "254px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherQuestionnaireLevelExampleBox = (theme) => {
    let commonStyle = {
        height: "94px",
        borderRadius: "20px",
        border: "1px solid #f0efff",
        background: "#fff",
        boxShadow: "0px 4px 4px 0px rgba(230, 229, 240, 0.4)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "20px 15px 20px 20px",
        width: "100%",
        position: "relative",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            height: "auto",
            boxSizing: "border-box",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TeacherQuestionnaireLevelDescrBox = {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    height: "fit-content",
    alignItems: "flex-end",
};

export const TeacherQuestionnaireLevelPointsContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
};

export const TeacherQuestionnaireLevelPointsLine = {
    borderRadius: "9px",
    background: "#6212ff",
    height: "6px",
};

export const TeacherQuestionnaireLevelPointsBox = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
};

export const ProgramLevelExampleIconBox = {
    width: "40px",
    height: "40px",
    zIndex: "30",
    position: "absolute",
    right: "15px",
    top: "-15px",
};
