import { styleProcessing } from "API/plugins/Utilities";

export const TodayClasses = {
    width: "100%",
    height: "fit-content",
    boxSizing: "border-box",
};

export const TodayClassesTitle = {
    backgroundColor: "#6212FF",
    padding: "13px 0 10px 20px",
    borderRadius: "20px 20px 0px 0px",
};

export const TodayClassesContent = (condition, type, theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: condition ? (type === "account" ? "55px" : "16px") : "10px",
        padding: condition ? (type === "account" ? "20px" : "30px 0 40px 20px") : "20px 20px 10px 20px",
        background: "#FFF",
        borderRadius: "0 0 20px 20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: condition ? (type === "account" ? "20px" : "30px 20px 40px 20px") : "20px 20px 10px 20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const DetailsPanel = {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
};

export const TooltipPopperProps = {
    sx: {
        "& .MuiTooltip-tooltip": {
            border: "none",
            color: "#222",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "140%",
            borderRadius: "10px",
            bgcolor: "#fff",
            maxWidth: "196px",
            boxShadow: "0px 2px 10px 0px rgba(106, 99, 118, 0.15)",
            padding: "10px",
        },
    },
};

export const SubjectContainer = {
    maxWidth: "570px",
};
