import React from "react";
import { observer } from "mobx-react-lite";

import { Box, Typography } from "@mui/material";

import { Body1Black } from "themes/TextThemes";
import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";

const SendOrderToWaitingState = () => {
    return (
        <>
            {DiplomaStore.status === "working" ? (
                <Typography sx={{ ...Body1Black, mb: 3.75 }}>
                    Отправляя файл с работой, ты переводишь заказ в статус «Ждёт проверку». У заказчика будет 3 недели на проверку работы и корректировки
                </Typography>
            ) : (
                <Box pb={2.5}></Box>
            )}
        </>
    );
};

export default observer(SendOrderToWaitingState);
