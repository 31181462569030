import * as themes from "./StudentRulesBodyBlockThemes";
import * as textThemes from "themes/TextThemes";
import React, { memo } from "react";
import { Box, Typography } from "@mui/material";
import RuleComp from "components/TutorRulesBodyBlock/RuleComp/RuleComp";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const StudentRulesBodyBlock = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const handleClickTutorRules = () => {
        navigate("/buddyrules");
    };

    return (
        <Box sx={themes.StudentRulesBodyBlock}>
            <Box sx={themes.StudentRulesBodyBlockContent}>
                <Typography sx={textThemes.HeadlineH60Black}>{t("rules.rules")}</Typography>

                <Box sx={themes.Rules}>
                    <Box sx={themes.TitleBlock}>
                        <Typography sx={textThemes.HeadlineH3Black}>{t("rules.student.rulesForStudent")}</Typography>
                        <Box sx={themes.rulesBlock}>
                            <Typography sx={textThemes.HeadlineH5Black}>{t("rules.student.readRules")}</Typography>
                            <Typography sx={textThemes.HeadlineH5Black}>
                                {t("rules.student.readTutorRulesPart1")}
                                <Typography
                                    onClick={handleClickTutorRules}
                                    component="span"
                                    sx={{ ...textThemes.HeadlineH5Purple, cursor: "pointer" }}
                                >
                                    {t("rules.student.readTutorRulesPart2")}
                                </Typography>
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={themes.TitleBlock}>
                        <Typography sx={textThemes.HeadlineH3Black}>{t("rules.student.forbidden")}</Typography>
                        <Box sx={themes.rulesBlock}>
                            <RuleComp text={t("rules.student.postNames")} />
                            <RuleComp text={t("rules.student.politics")} />
                            <RuleComp text={t("rules.student.sendTutorContacts")} />
                            <RuleComp text={t("rules.student.paymentOutside")} />
                            <RuleComp text={t("rules.student.manyAccounts")} />
                            <RuleComp text={t("rules.student.sendAccount")} />
                            <RuleComp text={t("rules.student.postReview")} />
                            <RuleComp text={t("rules.student.advertising")} />
                            <RuleComp text={t("rules.student.fraud")} />
                        </Box>
                    </Box>

                    <Box sx={themes.Sanctions}>
                        <Box sx={themes.TitleBlock}>
                            <Typography sx={textThemes.HeadlineH3Black}>{t("rules.sanctions")}</Typography>
                            <Box sx={themes.rulesBlock}>
                                <RuleComp text={t("rules.student.warning")} />
                                <RuleComp text={t("rules.tutor.ban")} />
                            </Box>
                        </Box>
                        <Box sx={themes.rulesBlock}>
                            <Typography sx={textThemes.HeadlineH5Black}>{t("rules.student.important")}</Typography>
                            <RuleComp text={t("rules.student.differentApproaches")} />
                            <RuleComp text={t("rules.student.canSoft")} />
                        </Box>
                    </Box>

                    <Box sx={themes.rulesBlock}>
                        <Typography sx={textThemes.Body1Black}>{t("rules.student.usingServices")}</Typography>
                        <Typography sx={textThemes.Body1Black}>{t("rules.student.toImproveQuality")}</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default memo(StudentRulesBodyBlock);
