export const Accordion = {
    margin: 0,
    padding: 0,
    border: "none",
    boxShadow: "none",
    "&::before": { display: "none" },
    "&.Mui-expanded": { margin: 0 },
    cursor: "pointer",
};

export const AccordionSummary = {
    margin: 0,
    padding: "11px 16px 12px 16px",
    borderRadius: "8px",
    border: "1px solid #DADADF",
    boxShadow: "none",
    "& .MuiAccordionSummary-content": {
        margin: 0,
        display: "flex",
        justifyContent: "space-between",
    },
    minHeight: 0,
    "&.Mui-expanded": {
        minHeight: 0,
        borderRadius: "8px 8px 0px 0px",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
        margin: 0,
        display: "flex",
        justifyContent: "space-between",
    },
};

export const RadioCards = {
    display: "flex",
    gap: "16px",
    alignItems: "center",
};

export const Radio = {
    margin: 0,
    padding: 0,
};

export const AccordionDetails = {
    margin: 0,
    padding: 0,
};

export const AddCardNumber = {
    marginTop: "-1px",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: "0px",
            border: "1px solid #DADADF",
        },
        "&:hover fieldset": {
            borderRadius: "0px",
            border: "1px solid #DADADF",
        },
        "&.Mui-focused fieldset": {
            borderRadius: "0px",
            border: "1px solid #6212FF",
            zIndex: "500",
        },
    },
};

export const AddCardBottomFields = {
    display: "flex",
    marginTop: "-1px",
};

export const AddMonthYear = {
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: "0px",
            border: "1px solid #DADADF",
        },
        "&:hover fieldset": {
            borderRadius: "0px",
            border: "1px solid #DADADF",
        },
        "&.Mui-focused fieldset": {
            borderRadius: "0px",
            border: "1px solid #6212FF",
            zIndex: "500",
        },
    },
};

export const AddCVC = {
    marginLeft: "-1px",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: "0px",
            border: "1px solid #DADADF",
        },
        "&:hover fieldset": {
            borderRadius: "0px",
            border: "1px solid #DADADF",
        },
        "&.Mui-focused fieldset": {
            borderRadius: "0px",
            border: "1px solid #6212FF",
            zIndex: "500",
        },
    },
};

export const SaveCard = {
    marginTop: "-1px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    padding: "11.5px 0px 14px 10px",
    border: "1px solid #DADADF",
    borderRadius: "0px 0px 8px 8px",
};

export const CheckBox = {
    margin: 0,
    padding: 0,
};
