import { createTheme } from "@mui/material/styles";

export const outerContainerStyle = (medias, styleMode) => {
    if (styleMode === 0) {
        return {
            width: medias.sm ? "338px" : "158px",
            height: "61px",
            marginLeft: medias.sm ? "0px" : "16px",
            position: "relative",
        };
    }

    if (styleMode === 1) {
        return {
            width: medias.sm ? "338px" : "320px",
            height: medias.sm ? "68px" : "unset",
        };
    }
};

export const nameBlockContainerStyle = (medias, styleMode) => {
    if (styleMode === 0) {
        return {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            gap: medias.sm ? "8px" : "4px",
            flex: "none",
            order: 0,
            flexGrow: 0,
            zIndex: 0,
        };
    }

    if (styleMode === 1) {
        return {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            gap: medias.sm ? "8px" : "0px",
            flex: "none",
            order: 0,
            flexGrow: 0,
            zIndex: 0,
        };
    }
};
// export const nameBlockContainerStyle = (medias) => {
//     return {
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "flex-start",
//         padding: "0px",
//         gap: medias.sm ? "8px" : "4px",
//         flex: "none",
//         order: 0,
//         flexGrow: 0,
//         zIndex: 0,
//     };
// };

export const nameAndActivityStyle = (medias, styleMode) => {
    if (styleMode === 0) {
        return {
            display: "flex",
            flexDirection: medias.sm ? "row" : "row",
            alignItems: medias.sm ? "center" : "start",
            padding: "0px",
            gap: medias.sm ? "8px" : "0px",
            width: medias.sm ? "338px" : "fit-content",
            height: medias.sm ? "27px" : "unset",
            flex: "none",
            order: 0,
            flexGrow: 0,
        };
    }

    if (styleMode === 1) {
        return {
            // New
            // display: "flex",
            // flexDirection: "row",
            // flexWrap: "wrap", // позволяет тексту занимать несколько строк, если нужно
            // alignItems: "flex-start", // выравниваем иконку по первой строке текста
            // padding: "0px",
            // gap: "8px", // расстояние между текстом и иконкой
            // width: medias.sm ? "338px" : "fit-content",
            // height: "auto", // высота будет зависеть от количества строк
            // flex: "none",
            // order: 0,
            // flexGrow: 0,
            //
            // whiteSpace: "normal",
            // overflowWrap: "break-word", // перенос слов при необходимости
            // textAlign: "left", // выравнивание текста по левому краю
            // alignSelf: "center",

            // Было
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px",
            gap: "8px",
            width: medias.sm ? "338px" : "fit-content",
            height: medias.sm ? "34px" : "fit-content",
            flex: "none",
            order: 0,
            flexGrow: 0,

            whiteSpace: "normal",
            overflowWrap: "break-word",
            textAlign: "center",
            alignSelf: "center",

            // // result
            // display: "flex",
            // flexDirection: "row",
            // flexWrap: medias.sm ? "unset" : "wrap", // позволяет тексту занимать несколько строк, если нужно
            // alignItems: medias.sm ? "center" : "flex-start", // выравниваем иконку по первой строке текста
            // padding: "0px",
            // gap: "8px", // расстояние между текстом и иконкой
            // width: medias.sm ? "338px" : "fit-content",
            // height: "auto", // высота будет зависеть от количества строк
            // flex: "none",
            // order: 0,
            // flexGrow: 0,
            //
            // whiteSpace: "normal",
            // overflowWrap: "break-word", // перенос слов при необходимости
            // textAlign: "left", // выравнивание текста по левому краю
            // alignSelf: "center",
        };
    }
};

export const NameAndActivityTheme = createTheme({
    components: {
        MuiTooltip: {
            styleOverrides: {
                root: {},
                tooltip: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    padding: "10px",
                    boxSizing: "border-box",
                    marginLeft: "6px!important",
                    background: "#FFFFFF",
                    borderRadius: "10px",
                    flex: "none",
                    order: 1,
                    flexGrow: 1,
                    filter: "drop-shadow(0px 2px 10px rgba(106, 99, 118, 0.15))",
                },
                arrow: {
                    color: "white!important",
                    filter: "drop-shadow(0px 2px 10px rgba(106, 99, 118, 0.15))",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                },
            },
        },
    },
});

export const tooltipNameStyle = (isAnonymous) => ({
    width: isAnonymous ? "221px" : "152px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "140%",
    display: "flex",
    alignItems: "center",
    color: "#222222!important",
    flex: "none",
    order: 0,
    flexGrow: 1,
});

export const nameStyle = (styleMode, medias) => {
    if (styleMode === 0) {
        return {
            height: medias.sm ? "27px" : "unset",
            width: medias.sm ? "unset" : "158px",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "150%",
            display: "flex",
            alignItems: "center",
            color: "#222222",
            flex: "none",
            order: 0,
            flexGrow: 0,
        };
    }

    if (styleMode === 1) {
        return {
            height: medias.sm ? "34px" : "fit-content",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "140%",
            display: "flex",
            alignItems: "center",
            color: "#222222",
            flex: medias.sm ? "none" : "unset",
            order: 0,
            flexGrow: 0,
        };
    }
};

// export const verifiedStyle = {
//     // display: "flex",
//     flexDirection: "row",
//     alignItems: "flex-start",
//     padding: "0px",
//     gap: "5px",
//     width: "20px",
//     height: "20px",
//     flex: "none",
//     order: 1,
//     flexGrow: 0,
//     color: "#82D4AE",
//
//     // verticalAlign: "bottom", // иконка будет находиться на одной линии с нижней строкой текста
//     // display: "inline-block", // важно для корректного поведения в строчном контексте
//     // marginLeft: "8px", // отступ между текстом и иконкой
// };

export const verifiedStyle = (styleMode, medias) => {
    if (styleMode === 0) {
        return {
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "0px",
            gap: "5px",
            width: "20px",
            height: "20px",
            flex: "none",
            order: 1,
            flexGrow: 0,
            color: "#82D4AE",
        };
    }

    if (styleMode === 1) {
        return {
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "0px",
            gap: "5px",
            width: "20px",
            height: "20px",
            flex: "none",
            order: 1,
            flexGrow: 0,
            color: "#82D4AE",
        };
    }
};

export const anonymousStyle = (styleMode, medias) => {
    if (styleMode === 0) {
        return {
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "0px",
            gap: "5px",
            width: "20px",
            height: "20px",
            flex: "none",
            order: 1,
            flexGrow: 0,
            color: "#82D4AE",
        };
    }

    if (styleMode === 1) {
        return {
            flexDirection: "row",
            alignItems: "flex-start",
            padding: "0px",
            gap: "5px",
            width: "20px",
            height: "20px",
            flex: "none",
            order: 1,
            flexGrow: 0,
            color: "#82D4AE",
        };
    }
};

export const safetyCheckStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "5px",
    width: "20px",
    height: "20px",
    flex: "none",
    order: 1,
    flexGrow: 0,
    color: "gray",
};

export const lastSeenActiveStyle = (medias) => {
    return {
        height: "21px",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        // lineHeight: "150%",
        display: "flex",
        alignItems: "center",
        color: "#19B20C",
        flex: "none",
        order: medias.sm ? 2 : 1,
        flexGrow: 0,
        lineHeight: 1.1,
    };
};

export const lastSeenInactiveStyle = (medias) => {
    return {
        height: "21px",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        // lineHeight: "150%",
        display: "flex",
        alignItems: "center",
        color: "#9E9EB0",
        flex: "none",
        order: medias.sm ? 2 : 1,
        flexGrow: 0,
        lineHeight: 1.1,
    };
};

export const ratingAndReviewsContainerStyle = (medias, styleMode) => {
    if (styleMode === 0) {
        return {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px",
            gap: "10px",
            width: medias.sm ? "338px" : "fit-content",
            height: "26px",
            flex: "none",
            order: medias.sm ? 1 : 2,
            alignSelf: "stretch",
            flexGrow: 0,
        };
    }

    if (styleMode === 1) {
        return {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0px",
            gap: "10px",
            width: medias.sm ? "338px" : "fit-content",
            height: "26px",
            flex: "none",
            order: medias.sm ? 1 : 2,
            alignSelf: "center",
        };
    }
};

export const ratingContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    boxSizing: "border-box",
    padding: "2px 10px 3px",
    gap: "10px",
    width: "43px",
    height: "26px",
    background: "#6212FF",
    borderRadius: "6px",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const ratingStyle = {
    display: "flex",
    justifyContent: "center",
    width: "23px",
    height: "21px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "150%",
    fontFeatureSettings: "tnum' on, 'lnum' on'",
    color: "#FFFFFF",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const reviewsStyle = (anyReviews) => ({
    //width: '90px',
    height: "21px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "150%",
    fontFeatureSettings: "tnum' on, 'lnum' on'",
    color: anyReviews ? "#6212FF" : "var(--text-dark-grey, #67677A)",
    flex: "none",
    order: 1,
    flexGrow: 0,
});

export const LikeIconInactiveTheme = (medias, style) => {
    return {
        components: {
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        position: medias.sm ? "relative" : "absolute",
                        width: "24px",
                        height: "24px",
                        // left: medias.sm ? "645px" : "179px",
                        left: medias.sm ? "645px" : "179px",
                        // top: medias.sm ? "-63px" : "-2px",
                        top: medias.sm ? "-63px" : "-2px",
                        flex: "none",
                        order: 4,
                        flexGrow: 0,
                        zIndex: 4,
                        "&:hover": {
                            background: "#FFFFFF!important",
                            borderColor: "#6212FF",
                        },
                        "&:active": {
                            background: "#FFFFFF!important",
                            borderColor: "#6212FF",
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        padding: 0,
                        ".MuiSvgIcon-root": {
                            width: "24px",
                            height: "24px",
                            stroke: "#FFFFFF",
                            strokeWidth: 1,
                        },
                        "&:hover": {
                            color: "#6212FF",
                            borderColor: "#6212FF",
                        },
                        "&:active": {
                            color: "#6212FF",
                            borderColor: "#6212FF",
                        },
                    },
                },
            },
        },
    };
};

export const LikeIconActiveTheme = (medias) => {
    return {
        components: {
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        position: medias.sm ? "relative" : "absolute",
                        width: "24px",
                        height: "24px",
                        left: medias.sm ? "645px" : "179px",
                        top: medias.sm ? "-63px" : "-2px",
                        flex: "none",
                        order: 4,
                        flexGrow: 0,
                        zIndex: 4,
                        "&:hover": {
                            background: "#FFFFFF!important",
                            borderColor: "#6212FF",
                        },
                        "&:active": {
                            background: "#FFFFFF!important",
                            borderColor: "#6212FF",
                        },
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        padding: 0,
                        ".MuiSvgIcon-root": {
                            width: "24px",
                            height: "24px",
                            color: "#222222!important",
                            "&:hover": {
                                color: "#6212FF",
                                borderColor: "#6212FF",
                            },
                            "&:active": {
                                color: "#6212FF",
                                borderColor: "#6212FF",
                            },
                        },
                        "&:hover": {
                            color: "#6212FF",
                            borderColor: "#6212FF",
                        },
                        "&:active": {
                            color: "#6212FF",
                            borderColor: "#6212FF",
                        },
                    },
                },
            },
        },
    };
};

export const lastSeenBlockMobile = (styleMode) => {
    if (styleMode === 0) {
        return {
            gap: "10px",
            display: "flex",
            flexDirection: "column",
            border: "1xp solid blue",
        };
    }

    if (styleMode === 1) {
        return {
            gap: "10px",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            border: "1xp solid blue",
        };
    }
};
