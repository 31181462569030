import React from "react";
import { Stack, Typography } from "@mui/material";
import Comment from "./Comment";
import * as styles from "components/DiplomaChats/styles";

const SingleDetail = ({ label = "Предмет", info = "Информационная безопасность" }) => {
    return (
        <Stack
            direction="column"
            sx={styles.singleDetailCont}
            spacing={0.125}
        >
            <Typography variant="grayText">{label}</Typography>
            {typeof info === "string" ? <Typography variant="standard2">{info}</Typography> : <Comment info={info} />}
        </Stack>
    );
};

export default SingleDetail;
