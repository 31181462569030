import * as React from "react";
import * as themes from "./ReplyOrEditInfoThemes";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import Box from "@mui/material/Box";
import { IconButton, Typography } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { ThemeProvider } from "@mui/material/styles";
import FullChatStore from "store/FullChatStore";
import DiplomaStore from "components/DiplomaChats/store/DiplomaStore";
import VideoChatStore from "store/VideoChatStore";

const ReplyOrEditInfo = observer(({ children, ...props }) => {
    const handleAction = () => {
        props.handleOpenEditModalMessage(false);
        props.handleOpenReplyModalMessage(false);
        props.handleText("");
        props.handleDisable(false);
    };

    return (
        <Box sx={themes.replyOrEditInfoContainerStyle}>
            <Box sx={themes.infoTextContainerStyle}>
                <Typography sx={themes.nameTextStyle}>
                    {props.isDiploma ? DiplomaStore.selectedMessage.owner.name : props.isVideo ? VideoChatStore.chatData.selectedMessage.owner.name : FullChatStore.selectedMessage.owner.name}
                </Typography>
                <Typography sx={themes.messageTextStyle}>
                    {props.isDiploma ? DiplomaStore.selectedMessage.message : props.isVideo ? VideoChatStore.chatData.selectedMessage.message : FullChatStore.selectedMessage.message}
                </Typography>
            </Box>
            <Box sx={themes.actionContainerStyle}>
                <ThemeProvider theme={themes.CloseIconTheme}>
                    <IconButton
                        onClick={handleAction}
                        disableRipple
                    >
                        <CloseRounded></CloseRounded>
                    </IconButton>
                </ThemeProvider>
            </Box>
        </Box>
    );
});

export default memo(ReplyOrEditInfo);
