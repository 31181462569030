export const SwitchBlock = {
    display: "flex",
    gap: "8px",
    alignItems: "center",
};

export const FormControl = {
    alignItems: "flex-start",
    m: 0,
};

export const IOSSwitch = {
    m: 0,
};
