import { styleProcessing } from "API/plugins/Utilities";

export const PayQRTitle = {
    display: "flex",
    alignItems: "center",
    gap: "20px",
};

export const PayQRText = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    maxWidth: "400px",
    width: "100%",
};

export const PayQR = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "23px",
        padding: "13px 0 40px 0",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "13px 0 20px 0",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const QRContainer = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
};
