import React from "react";

const FinishIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="icon/finish">
                <g id="Login">
                    <path
                        id="Line 14 (Stroke)"
                        d="M3.9998 11.1969C3.55798 11.1969 3.1998 11.555 3.1998 11.9969C3.1998 12.4387 3.55798 12.7969 3.9998 12.7969L13.9998 12.7969C14.4416 12.7969 14.7998 12.4387 14.7998 11.9969C14.7998 11.555 14.4416 11.1969 13.9998 11.1969L3.9998 11.1969Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.2"
                        strokeLinecap="round"
                    />
                    <path
                        id="Line 15 (Stroke)"
                        d="M14.3229 12.4902C14.5963 12.7636 15.0395 12.7636 15.3128 12.4902C15.5862 12.2168 15.5862 11.7736 15.3128 11.5002L12.1614 8.34878C11.888 8.07541 11.4448 8.07541 11.1714 8.34878C10.8981 8.62214 10.8981 9.06536 11.1714 9.33873L14.3229 12.4902Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.2"
                        strokeLinecap="round"
                    />
                    <path
                        id="Line 16 (Stroke)"
                        d="M14.3229 11.502C14.5963 11.2286 15.0395 11.2286 15.3128 11.502C15.5862 11.7754 15.5862 12.2186 15.3128 12.4919L12.1614 15.6434C11.888 15.9168 11.4448 15.9168 11.1714 15.6434C10.8981 15.37 10.8981 14.9268 11.1714 14.6535L14.3229 11.502Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.2"
                        strokeLinecap="round"
                    />
                    <path
                        id="Rectangle 104 (Stroke)"
                        d="M12.4998 20.1953C12.058 20.1953 11.6998 20.5535 11.6998 20.9953C11.6998 21.4371 12.058 21.7953 12.4998 21.7953L16.6771 21.7953C18.5952 21.7953 20.2258 20.362 20.4119 18.4314C20.594 16.5431 20.7998 13.9756 20.7998 11.9953C20.7998 10.015 20.594 7.44752 20.4119 5.55919C20.2258 3.62865 18.5952 2.19531 16.6771 2.19531L12.4998 2.19531C12.058 2.19531 11.6998 2.55349 11.6998 2.99531C11.6998 3.43714 12.058 3.79531 12.4998 3.79531L16.6771 3.79531C17.7923 3.79531 18.7143 4.62359 18.8193 5.71274C19.0008 7.59489 19.1998 10.0972 19.1998 11.9953C19.1998 13.8934 19.0008 16.3957 18.8193 18.2779C18.7143 19.367 17.7923 20.1953 16.6771 20.1953L12.4998 20.1953Z"
                        fill="white"
                        stroke="white"
                        strokeWidth="0.2"
                        strokeLinecap="round"
                    />
                </g>
            </g>
        </svg>
    );
};

export default FinishIcon;
