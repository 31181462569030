import { createTheme } from "@mui/material/styles";

export const replyOrEditInfoContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    borderLeft: "2px solid rgba(98, 18, 255, 1)",
    margin: "0px 20px 20px 72px",
};

export const infoTextContainerStyle = {
    padding: "4px 10px",
};

export const nameTextStyle = {
    color: "#6212FF",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
};

export const messageTextStyle = {
    color: "var(--text-black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
};

export const actionContainerStyle = {
    display: "flex",
    alignItems: "center",
};

export const CloseIconTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid #9E9EB0",
                    borderRadius: "50%",
                    background: "#FFFFFF",
                    width: "24px",
                    height: "24px",
                    "&:hover": {
                        background: "#FFFFFF!important",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "#FFFFFF!important",
                        borderColor: "#6212FF",
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    padding: 0,
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    "&:hover": {
                        color: "#6212FF",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        color: "#6212FF",
                        borderColor: "#6212FF",
                    },
                },
            },
        },
    },
});
