import React from "react";

import * as themes from "./DismissTextFieldStyled";
const DismissTextField = ({ value, label, otherValue, selectedValue, handleOtherChange }) => {
    return (
        <>
            {value === "other" ? (
                <>
                    {selectedValue === "other" ? (
                        <themes.StyledDismissTextField
                            value={otherValue}
                            onChange={handleOtherChange}
                            placeholder="Другое"
                            variant="standard"
                        />
                    ) : (
                        <themes.StyledDismissAnother>Другое</themes.StyledDismissAnother>
                    )}
                </>
            ) : (
                label
            )}
        </>
    );
};

export default DismissTextField;
