import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./ReviewsBlockThemes";
import SortByBlock from "./SortByBlock/SortByBlock";
import ReviewsBody from "./ReviewsBody/ReviewsBody";

const ReviewsBlock = observer(({ children, ...props }) => {
    return (
        <Box sx={themes.reviewsContainerStyle}>
            <SortByBlock></SortByBlock>
            <ReviewsBody value={props.value}></ReviewsBody>
        </Box>
    );
});

export default memo(ReviewsBlock);
