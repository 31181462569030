import { marginTop } from "components/TutorClassesBodyBlock/ClassesListBlock/ClassesListThemes";

export const Button = {
    width: "100%",
};

export const Link = {
    cursor: "pointer",
    color: "#6212FF",
};

export const Ul = {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    color: "#19B20C",
    padding: "0 0 0 18px",
    margin: 0,
};

export const TopBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    paddingBottom: "20px",
    borderBottom: "1px solid #EEEEF0",
};

export const BottomBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    paddingTop: "20px",
    paddingBottom: "30px",
    height: "100%",
    justifyContent: "flex-end",
};
