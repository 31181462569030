export const linkBLock = {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "flexStart",
    gap: "40px",
    width: "618px",
};

export const textBlock = {
    display: "flex",
    width: "495px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "30px",
    marginTop: "40px",
};

export const mainHeaderText = {
    width: "450px",
    height: "108px",
    alignSelf: "stretch",
    color: "var(--text-black, #222)",
    fontFamily: "Inter",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "135%" /* 54px */,
    margin: 0,
};

export const subHeaderText = {
    color: "var(--text-black, #222)",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%" /* 22.4px */,
    margin: 0,
};

//////////////
////////////// Link and Button part
//////////////

export const linkBox = {
    width: "392px",
    display: "flex",
    padding: "15px 39px 16px 39px",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "54px",
    border: "1px solid",
    borderColor: "var(--text-grey, #9E9EB0)",
    "&:hover": { borderColor: "#6212FF" },
    background: "#F7F7F7",
    boxSizing: "border-box",
};

export const linkText = {
    color: "#9E9EB0",
    fontFamily: "Inter",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%" /* 24px */,
    margin: 0,
};

export const copyButton = {
    display: "flex",
    width: "206px",
    height: "57px",
    padding: "16px 24px 17px 24px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
    borderRadius: "42px",
    background: "var(--main-purple, #6212FF)",
    boxSizing: "border-box",
};

export const copyButtonText = {
    color: "var(--main-white, #FFF)",
    /* Button/16px */
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%" /* 24px */,
    margin: 0,
};

export const linkButtonBlock = {
    display: "flex",
    alignItems: "flex-start",
    gap: "12px",
    margin: 0,
    cursor: "pointer",
};

//////////////
////////////// Share Part
//////////////

export const shareSocialBlock = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
};

export const shareSocialText = {
    color: "var(--text-black, #222)",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
};

export const SocialsBlock = {
    display: "flex",
    alignItems: "flex-start",
    gap: "11px",
};

export const ContentProps = {
    sx: {
        background: "#4E4E4E",
        borderRadius: "10px",
        boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.10)",
    },
};

export const WelcomeBackSnackbar = {
    "& .MuiSnackbarContent-root": {
        width: "fit-content",
        minWidth: 0,
    },
};

export const ButtonCopy = {
    width: "206px",
};

export const IconButton = {
    cursor: "pointer",
};
