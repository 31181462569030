import { styleProcessing } from "API/plugins/Utilities";

export const TabsGroup = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "12px",
        boxSizing: "border-box",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            gap: "8px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Chip = {
    height: "39px",
    borderRadius: "30px",
    boxShadow: "none",
    minWidth: 0,
};

export const ChipSelect = {
    height: "39px",
    backgroundColor: "#222",
    color: "white",
    borderRadius: "30px",
    boxShadow: "none",
    minWidth: 0,
};

export const customChipStyle = {
    minWidth: "0px",
    padding: "6px 12px 8px 12px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "150%",
    "& .MuiChip-label": {
        padding: 0, // Убираем отступы
    },
};
