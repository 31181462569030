import React from "react";
import { Modal, Grid, IconButton, Typography } from "@mui/material";
import Close from "assets/CommonAssets/SVG/close";
import * as styles from "./ModalSettingsThemes";
import * as textStyles from "themes/TextThemes";
import Box from "@mui/material/Box";
import { memo } from "react";
const ModalSettings = ({ children, ...props }) => {
    const [hovered, setHovered] = React.useState(false);

    return (
        <Modal
            open={props.visible}
            onClose={props.handleClose}
            sx={{
                width: props.width ? props.width : "480px",
                ...props.sx,
            }}
        >
            <Grid
                sx={styles.ModalSettings}
                container
            >
                <IconButton
                    onClick={props.handleClose}
                    disableRipple
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    <Close hovered={hovered} />
                </IconButton>
                {props.cancel ? (
                    <></>
                ) : (
                    <Box sx={styles.ModalSettingsHeader}>
                        <Typography sx={textStyles.HeadlineH5Black}>{props.header}</Typography>
                    </Box>
                )}
                <Box sx={styles.ModalSettingsBottom(props.cancel, props.padding)}>{children}</Box>
            </Grid>
        </Modal>
    );
};

export default memo(ModalSettings);
