import { memo } from "react";
import { Box, Typography } from "@mui/material";
import * as textStyles from "themes/TextThemes";
import * as styles from "./NoClassesYetThemes";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";

const NoClassesYet = ({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <Box sx={styles.NoClassesYet(medias)}>
            <Typography sx={textStyles.HeadlineH5Black}>{t("main.myClasses.noClassesYet")}</Typography>
            <Typography
                sx={medias.sm ? textStyles.Body1Black : textStyles.Body2Black}
            >{`${t("main.myClasses.emptyClassesPart1")}${props.type === "upcoming" ? t("main.myClasses.upcomingLower") : t("main.myClasses.previousLower")}${t("main.myClasses.emptyClassesPart2")}`}</Typography>
        </Box>
    );
};

export default memo(NoClassesYet);
