import React from "react";

const IconRight = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M5.53846 14L11.2021 8.86321C11.6328 8.47253 11.6407 7.79811 11.2191 7.39755L5.53846 2"
                stroke="#222222"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default IconRight;
