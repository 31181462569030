export const IconButton = {
    m: 0,
    p: 0,
};

export const ProfilePhoto = {
    width: "40px",
    height: "40px",
    borderRadius: "10px",
};
