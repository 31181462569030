import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./RatePositivesThemes";
import Box from "@mui/material/Box";
import { Chip, Divider, Rating, TextField, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import StarFilledIcon from "assets/VideoChatAssets/SVG/starFilledIcon";
import StarOutlinedIcon from "assets/VideoChatAssets/SVG/starOutlinedIcon";
import VideoChatStore from "store/VideoChatStore";

const RatePositives = observer(({ children, ...props }) => {
    const processingPositivesFeedbackRating = (event) => {
        VideoChatStore.SetPositivesFeedbackRating(Number(event.target.value));
    };

    const processingPositivesFeedbackMessage = (event) => {
        VideoChatStore.SetPositivesFeedbackMessage(event.target.value);
    };

    const handleClick = (value, event) => {
        VideoChatStore.ToggleChip("positives", value);
        VideoChatStore.SetPositivesFeedbackList();
    };

    return (
        <Box sx={themes.ratePositivesContainerStyle}>
            <Box sx={themes.ratingContainerStyle}>
                <Divider sx={themes.dividerStyle(0, 0)}></Divider>
                <Typography sx={themes.headerTextStyle}>Расскажите, что вам понравилось? </Typography>
                <ThemeProvider theme={themes.RatingTheme}>
                    <Rating
                        value={VideoChatStore.platformFeedback.positivesFeedbackRating}
                        onChange={processingPositivesFeedbackRating}
                        icon={<StarFilledIcon fontSize="inherit" />}
                        emptyIcon={<StarOutlinedIcon fontSize="inherit" />}
                    />
                </ThemeProvider>
            </Box>
            <Box style={themes.tagsContainerStyle}>
                {VideoChatStore.positivesFeedbackOptions.map((value) => (
                    <ThemeProvider
                        key={value.ID}
                        theme={value.Checked ? themes.ActiveChipTheme : themes.InactiveChipTheme}
                    >
                        <Chip
                            label={value.Query}
                            disableRipple
                            onClick={handleClick.bind(this, value)}
                        />
                    </ThemeProvider>
                ))}
            </Box>
            {VideoChatStore.positivesFeedbackOptions.find((value) => value.ID === 4 && value.Checked) && (
                <Box sx={themes.feedbackMessageContainerStyle}>
                    <ThemeProvider theme={themes.FeedbackMessageTheme(VideoChatStore.platformFeedback.positivesFeedbackMessage.length === 1000)}>
                        <TextField
                            onChange={processingPositivesFeedbackMessage}
                            value={VideoChatStore.platformFeedback.positivesFeedbackMessage}
                            variant="outlined"
                            placeholder={"Напишите свой вариант"}
                            multiline
                            rows={6}
                            inputProps={{
                                maxLength: 1000,
                            }}
                            FormHelperTextProps={{ component: "div" }}
                            helperText={
                                <Box sx={themes.helperContainerStyle(VideoChatStore.platformFeedback.positivesFeedbackMessage.length === 1000)}>
                                    {VideoChatStore.platformFeedback.positivesFeedbackMessage.length === 1000 ? (
                                        <Typography sx={themes.currLengthTextMaxStyle}>Достигнут лимит символов</Typography>
                                    ) : null}
                                    <Box sx={themes.helperCounterContainerStyle}>
                                        <Typography sx={themes.currLengthTextStyle(VideoChatStore.platformFeedback.positivesFeedbackMessage.length)}>
                                            {VideoChatStore.platformFeedback.positivesFeedbackMessage.length}
                                        </Typography>
                                        <Typography sx={themes.maxLengthTextStyle}>/1000</Typography>
                                    </Box>
                                </Box>
                            }
                        ></TextField>
                    </ThemeProvider>
                </Box>
            )}
        </Box>
    );
});

export default memo(RatePositives);
