import React, { useState } from "react";

import { useDropzone } from "react-dropzone";

import { Button, Paper, IconButton, Box } from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ClearIcon from "@mui/icons-material/Clear";

import filterStore from "store/FilterStore";

import * as styles from "./styles";

import PdfIconSVG from "assets/CommonAssets/SVG/pdfIconSVG";
import PptIconSVG from "assets/CommonAssets/SVG/pptIconSVG";
import ZipIconSVG from "assets/CommonAssets/SVG/zipIconSVG";
import RarIconSVG from "assets/CommonAssets/SVG/rarIconSVG";
import DocIconSVG from "assets/CommonAssets/SVG/docIconSVG";
import TxtIconSVG from "assets/CommonAssets/SVG/txtIconSVG";
import TifIconSVG from "assets/CommonAssets/SVG/tifIconSVG";
import PpsIconSVG from "assets/CommonAssets/SVG/ppsIconSVG";
import XlsIconSVG from "assets/CommonAssets/SVG/xlsIconSVG";
import CsvIconSVG from "assets/CommonAssets/SVG/CsvIconSVG";

import RedExclamationIcon from "assets/CommonAssets/SVG/RedExclamation";

const AddFilesForm = ({ addFilesText = "Перетащите или добавьте фото/файл", padding }) => {
    const [attachedFiles, setAttachedFiles] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            const url = URL.createObjectURL(acceptedFiles[0]);

            console.log(url);
            const tempDict = {
                fileName: acceptedFiles[0].name,
                fileSize: (acceptedFiles[0].size / 1000000).toFixed(2),
                fileType: acceptedFiles[0].type,
                fileUrl: url,
            };

            const result = [...attachedFiles, tempDict];

            setAttachedFiles(result);
        },
    });

    const handleChangeSubmitFilterStore = () => {
        filterStore.setSubmitButtonFilter(false);
    };

    const handleDelete = (attachToDelete) => () => {
        setAttachedFiles((attaches) => attaches.filter((attach) => attach !== attachToDelete));
    };

    return (
        <>
            <Box onClick={handleChangeSubmitFilterStore}>
                <Button
                    {...getRootProps()}
                    sx={styles.addButtonContainer(padding)}
                    variant="contained"
                    component="label"
                    className="attachButton"
                    fullWidth={true}
                >
                    {" "}
                    {addFilesText}
                    <Box
                        display="flex"
                        alignItems="center"
                    >
                        <AddCircleOutlineIcon
                            className="attachButtonBlur"
                            fontSize="medium"
                            sx={styles.addIcon}
                        />
                        <AddCircleIcon
                            className="attachButtonHover"
                            fontSize="medium"
                            sx={styles.addIcon}
                        />
                    </Box>
                </Button>
            </Box>
            {attachedFiles.length ? (
                <Paper sx={styles.addedFilesContainer}>
                    {attachedFiles.map((file, index) => {
                        const fileExt = file.fileName.split(".").pop();

                        return (
                            <Box
                                key={index}
                                sx={styles.singleAddedFileContainer(index)}
                            >
                                <Box display="flex">
                                    <Box marginRight="10px">
                                        {fileExt === "pdf" ? (
                                            <PdfIconSVG />
                                        ) : fileExt.includes("ppt") ? (
                                            <PptIconSVG />
                                        ) : fileExt === "zip" ? (
                                            <ZipIconSVG />
                                        ) : fileExt === "rar" ? (
                                            <RarIconSVG />
                                        ) : fileExt.includes("doc") ? (
                                            <DocIconSVG />
                                        ) : fileExt === "txt" ? (
                                            <TxtIconSVG />
                                        ) : fileExt === "tif" ? (
                                            <TifIconSVG />
                                        ) : fileExt === "pps" ? (
                                            <PpsIconSVG />
                                        ) : fileExt === "csv" ? (
                                            <CsvIconSVG />
                                        ) : fileExt.includes("xls") ? (
                                            <XlsIconSVG />
                                        ) : (
                                            <img
                                                src={file.fileUrl}
                                                height="40px"
                                                width="40px"
                                                alt="Preview..."
                                            />
                                        )}
                                    </Box>
                                    <Box>
                                        <Box sx={styles.fileNameContainer}>{file.fileName}</Box>
                                        <Box sx={styles.fileSizeContainer}>{file.fileSize + "MB"}</Box>
                                    </Box>
                                </Box>

                                <Box
                                    sx={{
                                        right: "0px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Box sx={{ marginRight: "15px" }}>{index > 9 ? <RedExclamationIcon /> : null}</Box>
                                    <IconButton
                                        sx={{ p: 0 }}
                                        size="small"
                                        onClick={handleDelete(file)}
                                    >
                                        <ClearIcon
                                            fontSize="small"
                                            sx={{
                                                color: "#4e4e4e",
                                                fontSize: 14,
                                            }}
                                        />
                                    </IconButton>
                                </Box>
                            </Box>
                        );
                    })}
                </Paper>
            ) : null}
            <Box>
                {attachedFiles.length > 10 ? (
                    <Box>
                        <Box sx={styles.filesNotAttached}>
                            {attachedFiles.length -
                                10 +
                                " " +
                                (attachedFiles.length - 10 === 1 ? " файл не прикрепился" : attachedFiles.length - 10 < 5 ? " файла не прикрепилось" : " файлов не прикрепилось")}
                        </Box>
                        <Box sx={styles.tooltip}>К заказу можно прикрепить до 10 файлов. Удалите часть прикреплённых файлов, чтобы добавить новые</Box>
                    </Box>
                ) : null}
            </Box>
        </>
    );
};

export default AddFilesForm;
