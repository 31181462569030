import React from "react";
import * as themes from "./OrderResponseRatingStyled";

const OrderResponseRating = ({ value }) => {
    return (
        <themes.StyledOrderRaiting>
            <themes.StyledOrderRaitingText>{value}</themes.StyledOrderRaitingText>
        </themes.StyledOrderRaiting>
    );
};

export default OrderResponseRating;
