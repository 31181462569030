import { styleProcessing } from "API/plugins/Utilities";

export const TopBlock = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
};

export const DateAndMenuBlock = {
    display: "flex",
    gap: "12px",
    alignItems: "flex-start",
};

export const TopAndTargetBlock = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
};

export const CardTitle = {
    width: "450px",
};

export const PersonalApplicationContainer = {
    padding: "2px 10px 4px 10px",
    borderRadius: "26px",
    background: "rgba(25, 178, 12, 0.10)",
    width: "fit-content",
};

export const BottomBlock = (condition) => {
    return {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: condition ? "8px 0 0 0" : "12px 0 0 0",
    };
};

export const ResponsesContainer = {
    display: "flex",
    gap: "5px",
    alignItems: "center",
};

export const ViewsIcon = {
    height: "16px",
};

export const ResponsesAndViewsBlock = {
    display: "flex",
    gap: "12px",
};

export const Avatar = {
    border: "none",
    width: "36px",
    height: "36px",
};

export const AvatarGroup = {
    padding: "0 0 12px 0",
    "& .MuiAvatar-root": {
        width: "36px",
        height: "36px",
    },
    "& .MuiAvatarGroup-avatar": {
        background: "#BBBBC8",
        color: "#fff",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "150%",
    },
};

export const SingleAvatar = {
    padding: "0 0 12px 0",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    justifyContent: "flex-end",
};

export const PhotoHidden = {
    borderRadius: 10000,
    border: "1px dashed #BBBBC8",
    width: "36px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

export const PhotoHiddenText = {
    color: "#67677A",
    fontSize: "8px",
    textAlign: "center",
};

export const StyledOrderCard = (theme) => {
    let commonStyle = {
        width: "100%",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "12px",
        padding: "20px 20px 10px 20px ",
        borderRadius: "16px",
        backgroundColor: "#fff",
        boxSizing: "border-box",
        cursor: "pointer",
        boxShadow: "0px 4px 30px 0px rgba(106, 99, 118, 0.05)",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, padding: "12px 20px 16px 20px", gap: "14px" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Divider = { color: "#EEEEF0" };

export const paperSx = {
    borderRadius: "10px",
    width: "190px",
    border: "none",
    boxShadow: "none",
    p: 0,
    mr: "110px",
    mt: "10px",
};

export const IconButton = {
    m: "-7px",
    p: "7px",
    ":hover": {
        color: "#222",
        bgcolor: "rgba(187, 187, 200, 0.17)",
    },
    ":pressed": {
        color: "#222",
        bgcolor: "rgba(187, 187, 200, 0.17)",
    },
};
