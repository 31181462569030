import React from "react";
import CatalogStore from "store/CatalogStore";
import TeacherCard from "components/UI/TeacherCard/TeacherCard";
import { Button, ThemeProvider } from "@mui/material";
import * as themes from "components/CatalogBodyBlock/BodyCard/BodyCardThemes";
import CustomPagination from "components/UI/CustomPagination";
import { observer } from "mobx-react-lite";
import OrderResponseCountSpDropMenuWrapper from "components/OrderResponse/OrderResponseTutorsWrapper/OrderResponseContent/OrderResponseCountSpDropMenuWrapper/OrderResponseCountSpDropMenuWrapper";
import Box from "@mui/material/Box";

const OrderResponseTutorsCatalog = observer(() => {
    const handlePagination = async (value) => {
        await CatalogStore.ProcessPagination(value);
        window.scrollTo(0, 0);
    };

    const handleShowMore = async () => {
        await CatalogStore.ShowMore();
    };

    return (
        <>
            <OrderResponseCountSpDropMenuWrapper value={CatalogStore.teachersArr.length} />

            <>
                <Box sx={themes.teacherContainerStyle}>
                    {CatalogStore.teachersArr.map((value) => (
                        <TeacherCard
                            key={value.ID}
                            value={value}
                        ></TeacherCard>
                    ))}
                </Box>
                <Box sx={themes.separatorContainerStyle}>
                    <ThemeProvider theme={themes.teacherShowMoreTheme}>
                        <Button
                            variant="text"
                            disabled={CatalogStore.currentPage === CatalogStore.pagesTotal || CatalogStore.totalTeachersCount === CatalogStore.teachersArr.length}
                            onClick={handleShowMore}
                            disableRipple
                        >
                            Показать больше
                        </Button>
                    </ThemeProvider>
                    <Box sx={themes.horizontalSeparatorStyle}></Box>
                </Box>
                <CustomPagination
                    paginationTheme={themes.paginationTheme}
                    paginationContainerStyle={themes.paginationContainerStyle}
                    pagesContainerStyle={themes.pagesContainerStyle}
                    currentPage={CatalogStore.currentPage}
                    pagesTotal={CatalogStore.pagesTotal}
                    processingChange={handlePagination}
                ></CustomPagination>
            </>
        </>
    );
});

export default OrderResponseTutorsCatalog;
