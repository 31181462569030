import Bowser from "bowser";
import UserStore from "../../store/UserStore";
import ErrorStore from "../../store/ErrorStore";
import i18next from "../../i18n";

export const processLogs = (errorData) => {
    let browserInfo = Bowser.parse(window.navigator.userAgent);

    let environmentData = {
        Browser: browserInfo.browser,
        OS: browserInfo.os,
        Platform: browserInfo.platform,
    };

    let data = {
        ...errorData,
        UID: UserStore.UID,
        ...environmentData,
    };

    ErrorStore.LogError(data);

    localStorageLogging(errorData);
};

const localStorageLogging = (errorData) => {
    let maxDateKeep = new Date();

    maxDateKeep.setHours(maxDateKeep.getHours() + 48);

    let currentErrorObject = {
        ...errorData,
        maxDateKeep: maxDateKeep,
    };

    modifyLogs("appendNew", currentErrorObject);
};

const modifyLogs = (mode, currentErrorObject) => {
    let errorsData = JSON.parse(localStorage.getItem("clientErrors"))?.errors?.length ? JSON.parse(localStorage.getItem("clientErrors"))?.errors : [];

    switch (mode) {
        case "removeOld":
            errorsData = errorsData.filter((data) => new Date(data.maxDateKeep) >= new Date());
            break;
        case "appendNew":
            errorsData.push(currentErrorObject);
            break;
        default:
            break;
    }

    let errorsObject = {
        errors: errorsData,
    };

    localStorage.setItem("clientErrors", JSON.stringify(errorsObject));
};

export const handleStoreActionError = ({ error, customErrorText, dispatch }) => {
    let errorData = {
        Error: {
            Message: error.message,
            Stack: error.stack,
        },
    };

    processLogs(errorData);
    if (dispatch) {
        let errorText = customErrorText ? i18next.t(customErrorText) : i18next.t("error.errors.defaultUnknownError");

        ErrorStore.ShowError({ errorText: errorText });
    }
};

modifyLogs("removeOld");
