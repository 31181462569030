import Illustration from "assets/PAAssets/SVG/illustration";
import SButton from "components/UI/SButton";
import ModalSettingsSmall from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsSmall/ModalSettingsSmall";
import React from "react";
import Box from "@mui/material/Box";
import * as styles from "./LoginModalsThemes";
import * as textStyles from "themes/TextThemes";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import UserStore from "store/UserStore";
import SettingsStore from "store/SettingsStore";
import { useMedias } from "API/mediasHook";

const ModalEmailChanged = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <ModalSettingsSmall
            visible={props.open}
            handleClose={props.handleDiscard}
        >
            <Box sx={styles.ModalIllustration}>
                <Illustration />
            </Box>
            <Typography
                sx={textStyles.HeadlineH41Black}
                style={styles.ModalTitle(medias)}
            >
                {t("main.settings.loginDetails.emailWasChanged")}
            </Typography>
            <Typography
                sx={textStyles.Body2Black}
                style={styles.ModalText}
            >
                {t("main.settings.loginDetails.newEmail")}
                <br /> {SettingsStore.temporaryEmailNotVerified !== null ? SettingsStore.temporaryEmailNotVerified : UserStore.UserInfo.mail.current}
            </Typography>
            <Box style={styles.ModalButton}>
                <SButton
                    variant="text"
                    onClick={props.handleDiscard}
                >
                    {t("main.close")}
                </SButton>
            </Box>
        </ModalSettingsSmall>
    );
});

export default ModalEmailChanged;
