import React, { memo } from "react";
import * as themes from "./StateTwoThemes";
import order1 from "assets/PromoTutorAssets/PNG/order1.png";
import order2 from "assets/PromoTutorAssets/PNG/order2.png";
import order3 from "assets/PromoTutorAssets/PNG/order3.png";
import Box from "@mui/material/Box";

const StateTwo = ({ children, ...props }) => {
    return (
        <Box>
            <Box sx={themes.imgOrder1}>
                <img
                    src={order1}
                    alt={1}
                />
            </Box>

            <Box sx={themes.imgOrder2}>
                <img
                    src={order2}
                    alt={2}
                />
            </Box>

            <Box sx={themes.imgOrder3}>
                <img
                    src={order3}
                    alt={3}
                />
            </Box>
        </Box>
    );
};

export default memo(StateTwo);
