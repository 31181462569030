import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RightPanel from "components/SearchRequest/RightPanel/RightPanel";
import { Box, IconButton, ThemeProvider } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import store from "store/RequestStore";
import filterStore from "store/FilterStore";
import * as themes from "./RenewOrderFilterThemes";
import * as theme from "./RenewOrderStyled";

const RenewOrder = ({ toggleOrderDrawer }) => {
    const handleSubmitFilterChange = () => {
        filterStore.setSubmitButtonFilter(true);
    };

    const toggleOrderDrawerAndEdited = () => {
        toggleOrderDrawer();
        store.setEdited();
        handleSubmitFilterChange();
    };

    const goToAndToggleDrawer = () => {
        store.setEdited();
        handleSubmitFilterChange();
    };

    return (
        <>
            <Box sx={theme.RenewOrderMarginBot}>
                <Box
                    sx={theme.RenewOrderCloseIconPosition}
                    onClick={goToAndToggleDrawer}
                >
                    <ArrowBackIcon sx={theme.ArrowBackIconStyles} />
                </Box>
                <ThemeProvider theme={themes.OrderCloseIconTheme}>
                    <IconButton onClick={toggleOrderDrawerAndEdited}>
                        <CloseRounded></CloseRounded>
                    </IconButton>
                </ThemeProvider>
            </Box>
            <RightPanel />
        </>
    );
};

export default RenewOrder;
