export const Emoji = () => {
    return (
        <svg
            width="3.3vh"
            height="3.3vh"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.5 11C10.0523 11 10.5 10.5523 10.5 10C10.5 9.44772 10.0523 9 9.5 9C8.94772 9 8.5 9.44772 8.5 10C8.5 10.5523 8.94772 11 9.5 11Z"
                fill="#9E9EB0"
            />
            <path
                d="M15.5 10C15.5 10.5523 15.0523 11 14.5 11C13.9477 11 13.5 10.5523 13.5 10C13.5 9.44772 13.9477 9 14.5 9C15.0523 9 15.5 9.44772 15.5 10Z"
                fill="#9E9EB0"
            />
            <path
                d="M8.69845 13.226L8.69479 13.2176C8.53948 12.8358 8.10465 12.6504 7.72146 12.8036C7.33687 12.9575 7.14981 13.394 7.30364 13.7785L8 13.5C7.30364 13.7785 7.30349 13.7782 7.30364 13.7785L7.30425 13.7801L7.30496 13.7818L7.30668 13.786L7.31131 13.7972C7.31494 13.8059 7.31965 13.8169 7.32548 13.8301C7.33713 13.8566 7.35327 13.892 7.3742 13.9351C7.41599 14.0211 7.47716 14.1384 7.56001 14.2765C7.72506 14.5516 7.98001 14.9157 8.34467 15.2803C9.0833 16.019 10.2659 16.75 12 16.75C13.7341 16.75 14.9167 16.019 15.6553 15.2803C16.02 14.9157 16.2749 14.5516 16.44 14.2765C16.5228 14.1384 16.584 14.0211 16.6258 13.9351C16.6467 13.892 16.6629 13.8566 16.6745 13.8301C16.6804 13.8169 16.6851 13.8059 16.6887 13.7972L16.6933 13.786L16.695 13.7818L16.6957 13.7801C16.6959 13.7797 16.6964 13.7785 16 13.5L16.6964 13.7785C16.8502 13.394 16.6631 12.9575 16.2785 12.8036C15.8953 12.6504 15.4606 12.8355 15.3053 13.2173L15.3016 13.226C15.2971 13.2362 15.2888 13.2545 15.2765 13.2797C15.2519 13.3304 15.2115 13.4085 15.1538 13.5048C15.0376 13.6984 14.855 13.9593 14.5947 14.2197C14.0833 14.731 13.2659 15.25 12 15.25C10.7341 15.25 9.9167 14.731 9.40533 14.2197C9.14499 13.9593 8.96244 13.6984 8.84624 13.5048C8.78846 13.4085 8.74807 13.3304 8.72346 13.2797C8.71118 13.2545 8.70291 13.2362 8.69845 13.226Z"
                fill="#9E9EB0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 3.25C7.16751 3.25 3.25 7.16751 3.25 12C3.25 16.8326 7.16751 20.75 12 20.75C16.8326 20.75 20.75 16.8326 20.75 12C20.75 7.16751 16.8326 3.25 12 3.25ZM4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99593 19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99593 19.25 4.75 16.0041 4.75 12Z"
                fill="#9E9EB0"
            />
        </svg>
    );
};
