import { memo } from "react";
import * as themes from "./OrderInfoMiniCardThemes";
import HeaderArea from "./HeaderArea";
import Box from "@mui/material/Box";
import InfoArea from "./InfoArea";
import FooterArea from "./FooterArea";

const OrderInfoMiniCard = ({ children, ...props }) => {
    return (
        <Box sx={themes.orderInfoContainerStyle}>
            <HeaderArea value={props.value}></HeaderArea>
            <InfoArea value={props.value}></InfoArea>
            <FooterArea value={props.value}></FooterArea>
        </Box>
    );
};

export default memo(OrderInfoMiniCard);
