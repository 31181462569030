import { createTheme } from "@mui/material/styles";

export const sortByContainerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
};

export const sortByOptionsSwitcherStyle = {
    display: "inline-flex",
    alignItems: "stretch",
    gap: "4px",
};

export const sortByOptionsTextStyle = {
    cursor: "pointer",
    color: "var(--text-black, #222)",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
};

export const MenuTheme = createTheme({
    components: {
        MuiMenu: {
            styleOverrides: {
                // Name of the slot
                root: {},
                paper: {
                    marginTop: 10,
                    borderRadius: "10px!important",
                    ".MuiList-root": {
                        display: "flex",
                        width: "max-content",
                        padding: "7px 4px",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    },
                    ".MuiMenuItem-root": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        alignSelf: "stretch",
                        gap: 10,
                        padding: "5px 12px",
                        "&:hover": {
                            background: "rgba(187, 187, 200, 0.17)!important",
                            borderRadius: "10px!important",
                            "& *": {
                                color: "#6212FF!important",
                            },
                        },
                        "&.Mui-selected": {
                            background: "rgba(187, 187, 200, 0.17)!important",
                            borderRadius: "10px!important",
                            "& *": {
                                color: "#6212FF!important",
                            },
                        },
                    },
                },
            },
        },
    },
});

export const sortByTextStyle = {
    color: "var(--Text-Black, #222)",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    "&:hover": {
        color: "#6212FF!important",
    },
};

export const IconOptionsTheme = createTheme({
    components: {
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    color: "#222222!important",
                    padding: 0,
                },
            },
        },
    },
});

export const IconCheckTheme = createTheme({
    components: {
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    color: "#6212FF!important",
                    padding: 0,
                },
            },
        },
    },
});

export const IconContainerStyle = {
    display: "flex",
    minWidth: 24,
};
