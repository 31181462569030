import * as React from "react";
import { useNavigate } from "react-router-dom";
import { memo } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Box, Typography, TextField, Checkbox } from "@mui/material";

import SButton from "components/UI/SButton";
import PhoneTextField from "../PhoneTextField/PhoneTextField";

import PurpleCheckBoxDefault from "assets/PaymentAssets/SVG/PurpleCheckBoxDefault";
import { BackIcon } from "assets/Authorization/SVG/BackIcon";
import PurpleCheckBoxChecked from "assets/PaymentAssets/SVG/PurpleCheckBoxChecked";

import * as styles from "./SignUpStudentThemes";
import * as textStyles from "themes/TextThemes";

import AuthorizationStore from "store/AuthorizationStore";
import { useMedias } from "API/mediasHook";
import Logo from "components/UI/Logo/logo";

const SignUpStudent = observer(({ setCurrent, style, current }) => {
    const { t, i18n } = useTranslation();

    const medias = useMedias();

    const navigate = useNavigate();

    React.useEffect(() => {
        AuthorizationStore.setMode("register");
    }, []);

    const handleBack = () => {
        AuthorizationStore.setCurrentAuthorizationStep("SignIn");
        AuthorizationStore.setTemporaryPhoneNumber(null);
        AuthorizationStore.setTemporaryEmail("");
        AuthorizationStore.setTemporaryPassword("");
    };

    const handleChangeName = (e) => {
        AuthorizationStore.setSignUpName(e.target.value);
    };

    const handleChangeEmail = (e) => {
        AuthorizationStore.setSignUpEmail(e.target.value);
        AuthorizationStore.setSignUpEmailError(false);
    };

    const handleClickSend = async () => {
        AuthorizationStore.setSignUpRole("student");
        await AuthorizationStore.RegisterNewUser();
    };

    return (
        <Box sx={styles.SignUpStudent(medias)}>
            <Box sx={styles.SignUpStudentContent}>
                {!medias.sm && <Logo />}
                <Box sx={styles.TopBlock(medias)}>
                    <SButton
                        variant="icon"
                        style={styles.BackIcon(medias)}
                        onClick={handleBack}
                    >
                        <BackIcon />
                    </SButton>
                    <Typography sx={medias.sm ? textStyles.HeadlineH3Black : textStyles.HeadlineH41Black}>Регистрация ученика</Typography>
                    <Box sx={styles.DescriptionText(medias)}>
                        <Typography sx={textStyles.Body2DarkGrey}>Репетиторы не будут видеть ваши контакты, они нужны только для регистрации</Typography>
                    </Box>
                </Box>
                <Box sx={styles.FormAndButton(medias)}>
                    <Box sx={styles.SignUpForm(medias)}>
                        <Box>
                            <Typography sx={textStyles.Body2Black}>Имя</Typography>
                            <TextField
                                value={AuthorizationStore.signUpName}
                                sx={styles.NameField}
                                fullWidth
                                variant="standard"
                                name="name"
                                onChange={handleChangeName}
                                placeholder="Введите имя"
                            />
                        </Box>
                        <PhoneTextField mode="SignUp" />
                        <Box>
                            <Typography sx={textStyles.Body2Black}>Электронная почта</Typography>
                            <TextField
                                value={AuthorizationStore.signUpEmail}
                                sx={styles.EmailField(AuthorizationStore.signUpEmailError)}
                                error={AuthorizationStore.signUpEmailError}
                                fullWidth
                                variant="standard"
                                name="email"
                                onChange={handleChangeEmail}
                                placeholder="Введите электронную почту"
                            />
                        </Box>
                    </Box>
                    <Box sx={styles.ButtonAndRules}>
                        <SButton
                            disabled={
                                true
                                // AuthorizationStore.studentRulesAgree === false ||
                                // AuthorizationStore.signUpName === "" ||
                                // AuthorizationStore.signUpEmail === "" ||
                                // AuthorizationStore.signUpEmailError === true ||
                                // AuthorizationStore.signUpPhone === null ||
                                // AuthorizationStore.signUpPhoneError === true
                            }
                            style={styles.SignUpButton(medias)}
                            variant="arrow"
                            padding="11px 24px 12px 24px"
                            onClick={handleClickSend}
                        >
                            Зарегистрироваться
                        </SButton>
                        <Box sx={styles.Agreements}>
                            <Checkbox
                                checked={AuthorizationStore.studentRulesAgree}
                                onChange={(e) => AuthorizationStore.setStudentRulesAgree(e.target.checked)}
                                sx={styles.CheckBoxAgreement}
                                icon={<PurpleCheckBoxDefault />}
                                checkedIcon={<PurpleCheckBoxChecked />}
                                disableRipple
                            />
                            <Typography sx={textStyles.Body2DarkGrey}>
                                Нажимая «Зарегистрироваться», вы соглашаетесь на обработку персональных данных и<span style={textStyles.Body2Purple}>условия сервиса</span>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.AlreadyHaveAccount}>
                <Typography sx={textStyles.Button14Black}>Уже есть аккаунт?</Typography>
                <SButton
                    variant="text"
                    onClick={handleBack}
                >
                    Войти
                </SButton>
            </Box>
        </Box>
    );
});

export default memo(SignUpStudent);
