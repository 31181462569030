import { Link, FormControlLabel, Typography, Box } from "@mui/material";
import { useState, memo } from "react";
import IOSSwitch from "components/UI/IOSSwitch";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import SButton from "components/UI/SButton";
import * as textStyles from "themes/TextThemes";
import * as themes from "components/SettingsBodyBlock/TutorSettings/MainForm/MainFormThemes";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { useMedias } from "API/mediasHook";

const Telegram = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const [isBotLinked, setIsBotLinked] = useState(false);

    const handleTelegramLink = (isLinked) => {
        setIsBotLinked(false);
        TeacherQuestionnaireStore.setTelegramNotifications("linked", isLinked);
    };

    const renderBotSettings = () =>
        isBotLinked ? (
            <Box sx={themes.BottomBlock}>
                <Typography sx={textStyles.Body1Black}>
                    {t("main.settings.telegramBlock.toUnlinkPart1")}
                    <Link
                        className="Link"
                        underline="none"
                        color="#6212FF"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleTelegramLink(false)}
                    >
                        @studybuddy_bot
                    </Link>
                    {t("main.settings.telegramBlock.toUnlinkPart2")}
                </Typography>
                <SButton
                    style={themes.CancelButton}
                    variant="secondary"
                    padding="10px 20px 11px 20px"
                    onClick={() => setIsBotLinked(false)}
                >
                    {t("main.cancel")}
                </SButton>
            </Box>
        ) : (
            <Box>
                <Typography
                    sx={{ ...textStyles.Button14Purple, cursor: "pointer" }}
                    onClick={() => setIsBotLinked(true)}
                >
                    {t("main.settings.telegramBlock.unlink")}
                </Typography>
            </Box>
        );

    const renderNotificationItem = (titleKey, descriptionKey, itemKey) => (
        <Box sx={themes.NotificationsItem}>
            <Box sx={themes.placesSwitchText}>
                <Typography sx={textStyles.Button16Black}>{t(titleKey)}</Typography>
                <Typography sx={textStyles.Body2Grey}>{t(descriptionKey)}</Typography>
            </Box>
            <FormControlLabel
                sx={themes.NotificationsSwitcher}
                control={
                    <IOSSwitch
                        checked={TeacherQuestionnaireStore.teacher.telegramNotifications[itemKey]}
                        onChange={(event) => {
                            TeacherQuestionnaireStore.setTelegramNotifications(itemKey, event.target.checked);
                        }}
                    />
                }
            />
        </Box>
    );

    const telegramNotifications = [
        {
            title: t("teacherSettings.settings.classesSchedule"),
            description: t("teacherSettings.settings.notificationsAboutClasses"),
            key: "classesSchedule",
        },
        {
            title: t("teacherSettings.settings.messagesFromStudents"),
            description: t("teacherSettings.settings.notificationsAboutMessages"),
            key: "studentMessages",
        },
        {
            title: t("teacherSettings.settings.notificationsAboutOrders"),
            description: t("teacherSettings.settings.notificationsAboutStatuses"),
            key: "orders",
        },
        {
            title: t("teacherSettings.settings.notificationsAboutResponses"),
            description: t("teacherSettings.settings.notificationsAboutNewResponses"),
            key: "responses",
        },
        {
            title: t("teacherSettings.settings.notificationsAboutPayments"),
            description: t("teacherSettings.settings.notificationsAboutBillings"),
            key: "payments",
        },
        {
            title: t("teacherSettings.settings.updatesAndAdvices"),
            description: t("teacherSettings.settings.getToKnowFirst"),
            key: "updates",
        },
    ];

    return (
        <Box sx={themes.SmallItem(medias)}>
            <Box sx={themes.ItemContent(medias)}>
                <Typography sx={medias.sm ? textStyles.HeadlineH41Black : textStyles.HeadlineH5Black}>{t("main.settings.telegramBlock.telegramBotTitle")}</Typography>
                <Box sx={themes.BottomBlock}>
                    {TeacherQuestionnaireStore.teacher.telegramNotifications.linked === false ? (
                        <Box>
                            <Typography sx={textStyles.Body1Black}>
                                {t("main.settings.telegramBlock.configureNotificationsPart1")}
                                <Link
                                    underline="none"
                                    color="#6212FF"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => handleTelegramLink(true)}
                                >
                                    @studybuddy_bot
                                </Link>
                                {t("main.settings.telegramBlock.configureNotificationsPart2")}
                            </Typography>
                        </Box>
                    ) : (
                        <Box sx={themes.verticalDescriptionBlock}>
                            <Typography sx={textStyles.HeadlineH4Black}>{t("main.settings.telegramBlock.receiveNotifications")}</Typography>
                            {telegramNotifications.map((item, index) => renderNotificationItem(item.title, item.description, item.key))}
                        </Box>
                    )}
                    {TeacherQuestionnaireStore.teacher.telegramNotifications.linked && renderBotSettings()}
                </Box>
            </Box>
        </Box>
    );
});

export default memo(Telegram);
