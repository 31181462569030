export const ChatListsTabs = {
    borderTop: "1px solid #DADADF",
    borderBottom: "1px solid #DADADF",
    overflowX: "hidden",
    padding: "13px 0 0 20px",
};

export const ChatListsTabsContent = {
    position: "relative",
    display: "flex",
    gap: "16px",
};

export const UreadMessages = {
    backgroundColor: "#6212FF",
    borderRadius: "999px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2px 6px",
    textAlign: "center",
};

export const TabText = (condition) => {
    return {
        whiteSpace: "nowrap",
        cursor: "pointer",
        color: condition ? "#6212FF" : "#9E9EB0",
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "140%",
    };
};

export const TabBox = (condition) => {
    return {
        display: "flex",
        gap: "4px",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: condition ? "12px" : "13px",
    };
};

export const TabContainer = (condition) => {
    return {
        fontSize: "20px",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        "::after": condition && {
            border: "1px solid #6212FF",
            content: '""',
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderRadius: "2px",
        },
    };
};
