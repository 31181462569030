import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { StyledOrderResponseBottomWrapper } from "components/OrderResponse/OrderResponseInfo/OrderResponseInfoStyled";
import { OrederResponseInfoDetailsButton } from "./OrederResponseInfoDetailsStyled";
import filterStore from "store/FilterStore";
import { observer } from "mobx-react-lite";
import RenewOrderFilter from "components/OrdersBodyBlock/RenewOrder/RenewOrderFilter";
import { useState } from "react";

const OrederResponseInfoDetails = observer(() => {
    const [openDrawer, setOpenDrawer] = useState(false);

    const toggleOrderDrawer = () => {
        filterStore.setToggleDrawer();
        filterStore.setSubmitButtonFilter(true);
        filterStore.setChangeDrawerContent("details");
    };

    return (
        <OrederResponseInfoDetailsButton
            disableRipple
            type="text"
            endIcon={<ArrowForwardIosIcon sx={{ width: "12px" }} />}
        >
            <StyledOrderResponseBottomWrapper onClick={toggleOrderDrawer}>Детали заказа</StyledOrderResponseBottomWrapper>
            <RenewOrderFilter
                toggleOrderDrawer={toggleOrderDrawer}
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
            />
        </OrederResponseInfoDetailsButton>
    );
});

export default OrederResponseInfoDetails;
