import React from "react";
import { Stack, Typography } from "@mui/material";
import * as styles from "components/DiplomaChats/styles";
import Application from "./Application";

const Comment = ({
    info = {
        comment: "",
        files: [],
    },
}) => {
    return (
        <Stack
            spacing={1.5}
            direction="column"
            sx={styles.singleDetailComment}
        >
            <Typography variant="standard2">{info.comment}</Typography>
            <Stack
                direction="column"
                spacing={1.75}
            >
                {info.files.map((file, index) => (
                    // TODO: Add id for key
                    <Application
                        key={index}
                        fileInfo={file}
                    />
                ))}
            </Stack>
        </Stack>
    );
};

export default Comment;
