//Укажите срок сдачи работы
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import SRadioGroup from "components/UI/RadioGroup";
import SBButtonGroup from "components/UI/ButtonGroup";
import store from "store/RequestStore";
import { deadlineType } from "API/dictionaries/const_data";
import RequestStore from "store/RequestStore";

import DateTimeInput from "components/UI/DateTimeInput";
import { Box, Chip } from "@mui/material";
import StepHiddenButtonWrapper from "components/OrdersBodyBlock/RenewOrder/StepHiddenButtonWrapper";
import RequestNullAlert from "./RequestNullAlert";
import * as theme from "./Step20Themes";
const Step20 = () => {
    const russianMonthsInput = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];

    const formatDate = (date, time) => {
        const day = date.getDate();

        const monthName = russianMonthsInput[date.getMonth()];

        return `${day} ${monthName} ${time}`;
    };

    const today = new Date();

    const todayDay = today.getDate();

    const todayMonth = today.getMonth();

    const todayYear = today.getFullYear();

    let nextMonth = null;

    if (todayMonth !== 11) {
        nextMonth = new Date(todayYear, todayMonth + 1, todayDay);
    } else {
        nextMonth = new Date(todayYear + 1, 0, todayDay);
    }

    const minDateStart = today;

    const minDateEnd = nextMonth;

    const [alert, setAlert] = useState(0);

    let data = store.storage[store.activeStepIndex].substeps[store.subStepIndex]?.value;

    if (data === undefined) store.setActiveSubStepIndex(0);

    const [ddlType, setDdlType] = useState(RequestStore.storage[store.activeStepIndex].substeps[store.subStepIndex].value[0]);

    const [stepData, setStepData] = useState(RequestStore.storage[store.activeStepIndex].substeps[store.subStepIndex].description);

    const [selectedDateStart, setSelectedDateStart] = useState(RequestStore.storage[store.activeStepIndex].substeps[store.subStepIndex].value[1][0]);

    const [selectedDateEnd, setSelectedDateEnd] = useState(RequestStore.storage[store.activeStepIndex].substeps[store.subStepIndex].value[2][0]);

    const [timeStart, setTimeStart] = useState(RequestStore.storage[store.activeStepIndex].substeps[store.subStepIndex].value[1][1]);

    const [timeEnd, setTimeEnd] = useState(RequestStore.storage[store.activeStepIndex].substeps[store.subStepIndex].value[2][1]);

    useEffect(() => {
        if (ddlType === "Указать период") {
            store.setTooltip(12, {
                text: "Если заказ срочный выберите из списка «Срочный заказ». Тогда мы предложим специалистов, которые могут работать в сжатые сроки",
                icon: 5,
            });
        } else {
            store.setTooltip(12, {
                text: "Срочные заказы стоят дороже обычных, потому что выполняются в сжатые сроки",
                icon: 6,
            });
        }
    }, [ddlType]);
    const handleNext = () => {
        if (timeEnd) {
            if (ddlType === "Указать период") {
                if (timeStart === "") {
                    setTimeStart("ewewqewq");
                }

                const part1 = formatDate(selectedDateStart, timeStart);

                const part2 = formatDate(selectedDateEnd, timeEnd);

                const description = part1 + " - " + part2;

                store.setStep(12, description);
                store.setStepValue(12, [ddlType, [selectedDateStart, timeStart], [selectedDateEnd, timeEnd]]);
                store.incrementStepIndex();
            } else {
                const description = "до " + formatDate(selectedDateEnd, timeEnd);

                store.setStep(12, description);
                store.setStepValue(12, [ddlType, [selectedDateStart, timeStart], [selectedDateEnd, timeEnd]]);
                store.incrementStepIndex();
            }
        } else {
            setAlert(1);
        }
    };

    return (
        <Box>
            <Box sx={theme.TopText}>Укажите дату и время, когда работа должна быть готова</Box>
            <SRadioGroup
                changefunc={setDdlType}
                value={ddlType}
                list={deadlineType}
            />
            {ddlType === "Указать период" ? (
                <Box>
                    <Box className="DeadlinesBox">
                        <Box className="DeadlineContainer">
                            <Box className="DeadlineTitle">Начать работу</Box>
                            <DateTimeInput
                                setSelectedDate={setSelectedDateStart}
                                selectedDate={selectedDateStart}
                                minDate={minDateStart}
                                time={timeStart}
                                setTime={setTimeStart}
                            />
                        </Box>
                        <Box className="DeadlineContainer">
                            <Box className="DeadlineTitle">Закончить работу</Box>
                            <DateTimeInput
                                setSelectedDate={setSelectedDateEnd}
                                selectedDate={selectedDateEnd}
                                minDate={minDateEnd}
                                time={timeEnd}
                                setTime={setTimeEnd}
                            />
                        </Box>
                    </Box>
                    <Box sx={theme.BottomText}>Стандартный срок выполнения дипломной работы 21 день</Box>
                </Box>
            ) : (
                <Box>
                    <Box className="DeadlinesBox">
                        <Box className="DeadlineContainer">
                            <Box className="DeadlineTitle">Работа должна быть готова</Box>
                            <DateTimeInput
                                setSelectedDate={setSelectedDateEnd}
                                selectedDate={selectedDateEnd}
                                minDate={minDateEnd}
                                time={timeEnd}
                                setTime={setTimeEnd}
                            />
                            <Box sx={theme.ChipWrapper}>
                                <Chip
                                    label="Завтра"
                                    variant="outlined"
                                />
                                <Chip
                                    label="Послезавтра"
                                    variant="outlined"
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
            <StepHiddenButtonWrapper submitRenew={handleNext}>
                <SBButtonGroup
                    handleNext={handleNext}
                    handleBack={() => store.decrementStepIndex()}
                    activeStep={store.activeStepIndex}
                    steps={store.storage}
                />
                {alert === 1 ? <RequestNullAlert>Чтобы перейти к следующему вопросу, укажите дату и время сдачи работы</RequestNullAlert> : null}
            </StepHiddenButtonWrapper>
        </Box>
    );
};

export default observer(Step20);
