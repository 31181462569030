export const TodayClassDetails = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
};

export const TodayClassDateTime = {
    display: "flex",
    alignItems: "center",
    gap: "16px",
};

export const TodayClassDate = {
    display: "flex",
    alignItems: "center",
    gap: "4px",
};

export const NextClass = (type) => {
    return {
        borderTop: type === "account" ? "1px solid #EEEEF0" : "none",
        paddingTop: type === "account" ? "19px" : "none",
    };
};

export const AllClasses = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
};
