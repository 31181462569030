import React, { memo } from "react";
import { Card, CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as themes from "./ReviewsCardThemes";
import Box from "@mui/material/Box";
import RatingBlock from "./RatingBlock/RatingBlock";
import ChipBlock from "./ChipBlock/ChipBlock";
import ReviewsBlock from "./ReviewsBlock/ReviewsBlock";
import TeacherStore from "store/TeacherStore";
import { observer } from "mobx-react-lite";
import { ThemeProvider } from "@mui/material/styles";
import { useMedias } from "../../../API/mediasHook";
import StarIcon from "../../../assets/CommonAssets/SVG/StarSVG";

const ReviewsCard = observer(({ children, ...props }) => {
    const LoadMore = async () => {
        if (TeacherStore.reviewsListFiltered.length === TeacherStore.tutorData.ReviewsCount) {
            await TeacherStore.FetchMoreReviewsData("reset");
        } else {
            await TeacherStore.FetchMoreReviewsData();
        }
    };

    const medias = useMedias();

    return (
        <Card
            sx={themes.reviewsCardStyle(medias)}
            id={"ReviewsCard"}
        >
            <Box sx={themes.reviewsContainerStyle}>
                <Box sx={themes.headerContainerStyle}>
                    <Typography sx={themes.aboutTextStyle(medias)}>Отзывы</Typography>
                    <Typography sx={themes.subTextStyle}>({props.value.ReviewsCount})</Typography>
                    {!medias.sm ? (
                        <Box sx={themes.starRowStyle}>
                            <StarIcon></StarIcon>
                            <Typography sx={themes.ratingTextStyle}>{props.value.Rating}</Typography>
                        </Box>
                    ) : null}
                </Box>
                <RatingBlock value={props.value}></RatingBlock>
                <ChipBlock value={props.value}></ChipBlock>
                <ReviewsBlock value={props.value}></ReviewsBlock>
            </Box>
            {TeacherStore.reviewsListFiltered.length > 0 && TeacherStore.tutorData.ReviewsCount > 3 && (
                <Typography
                    sx={themes.showMoreTextStyle}
                    onClick={LoadMore}
                >
                    {TeacherStore.reviewsListFiltered.length > 3
                        ? TeacherStore.reviewsListFiltered.length === TeacherStore.tutorData.ReviewsCount
                            ? "Показать меньше"
                            : "Показать ещё отзывы (3)"
                        : "Показать ещё отзывы (3)"}
                </Typography>
            )}
            {TeacherStore.loadingMoreReviewsData && (
                <ThemeProvider theme={themes.CircularProgressTheme}>
                    <Box sx={themes.progressContainerStyle}>
                        <CircularProgress />
                    </Box>
                </ThemeProvider>
            )}
        </Card>
    );
});

export default memo(ReviewsCard);
