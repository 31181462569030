import React, { memo } from "react";
import ModalSettings from "components/SettingsBodyBlock/StudentSettings/LoginDetails/LoginModals/ModalSettingsBig/ModalSettings";
import { useTranslation } from "react-i18next";
import { Avatar, Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import TeacherStore from "store/TeacherStore";
import CustomCalendar from "components/CustomCalendar/CustomCalendar";
import * as styles from "./ModalBookClassThemes";
import * as textStyles from "themes/TextThemes";
import SButton from "components/UI/SButton";
import UserStore from "store/UserStore";
import MyClassesStore from "store/MyClassesStore";
import DrawerHeader from "components/SettingsBodyBlock/StudentSettings/DrawerHeader/DrawerHeader";
import { useMedias } from "API/mediasHook";
import MyClassesDrawer from "components/MyClassesBodyBlock/MyClassesDrawer";

const ModalCalendarReschedule = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const Close = () => {
        props.handleDiscard();
    };

    const Save = () => {
        props.handleSave();
        MyClassesStore.setTemporaryRescheduleTimeStart(date);
    };

    const [date, setDate] = React.useState("");

    const DrawerList = (
        <Box sx={styles.ModalCalendarReschedule(medias)}>
            <DrawerHeader
                text={t("main.chooseDateAndTime")}
                handleClose={Close}
            />
            <Box sx={styles.ClassDetails(medias)}>
                {MyClassesStore.newClassTutor ? (
                    <>
                        <Avatar
                            src={MyClassesStore.newClassTutor.photo}
                            sx={styles.Avatar}
                        />
                        <Box sx={styles.InfoTutor}>
                            <Typography sx={textStyles.Button16Black}>{MyClassesStore.newClassTutor.tutor}</Typography>
                            <Typography sx={textStyles.Body2DarkGrey}>{MyClassesStore.newClassTutor.subject}</Typography>
                        </Box>
                    </>
                ) : (
                    <Typography sx={textStyles.Body2DarkGrey}>{t("main.tutorNotAvailable")}</Typography>
                )}
            </Box>
            <Box sx={styles.CalendarContainer(medias)}>
                <CustomCalendar
                    maxContentHeight="466px"
                    type="modal"
                    weekTimeSlots={TeacherStore.teacherWeekTimeSlots}
                    setDate={setDate}
                    date={date}
                />
            </Box>
            <Box sx={styles.ButtonContainer(medias)}>
                <Box sx={styles.TimeZone(medias)}>
                    <Typography
                        sx={textStyles.Body2DarkGrey}
                        component={"div"}
                    >
                        {t("main.myClasses.yourTimezone")}
                    </Typography>
                </Box>
                <SButton
                    variant="arrow"
                    padding="11px 24px 12px 24px"
                    disabled={date === ""}
                    onClick={Save}
                >
                    {t("main.book")}
                </SButton>
            </Box>
        </Box>
    );

    if (MyClassesStore.newClassTutor !== null) {
        return (
            <>
                {medias.sm ? (
                    <ModalSettings
                        visible={props.open}
                        handleClose={Close}
                        header={t("main.chooseDateAndTime")}
                        padding="0px"
                        width="665px"
                    >
                        <Box sx={styles.ModalCalendarReschedule(medias)}>
                            <Box sx={styles.ClassDetails(medias)}>
                                <Avatar
                                    src={MyClassesStore.newClassTutor.photo}
                                    sx={styles.Avatar}
                                />

                                <Box sx={styles.InfoTutor}>
                                    <Typography sx={textStyles.Button16Black}>{MyClassesStore.newClassTutor.tutor}</Typography>
                                    <Typography sx={textStyles.Body2DarkGrey}>{MyClassesStore.newClassTutor.subject}</Typography>
                                </Box>
                            </Box>
                            <Box sx={styles.CalendarContainer(medias)}>
                                <CustomCalendar
                                    maxContentHeight="466px"
                                    type="modal"
                                    weekTimeSlots={TeacherStore.teacherWeekTimeSlots}
                                    setDate={setDate}
                                    date={date}
                                />
                            </Box>
                            <Box sx={styles.ButtonContainer(medias)}>
                                <Box sx={styles.TimeZone(medias)}>
                                    <Typography
                                        sx={textStyles.Body2DarkGrey}
                                        component={"div"}
                                    >
                                        {t("main.myClasses.yourTimezone")}
                                        <span style={textStyles.Button14DarkGrey}> {UserStore.UserInfo.timezone}</span>
                                    </Typography>
                                </Box>
                                <SButton
                                    variant="arrow"
                                    padding="11px 24px 12px 24px"
                                    disabled={date === ""}
                                    onClick={Save}
                                >
                                    {t("main.book")}
                                </SButton>
                            </Box>
                        </Box>
                    </ModalSettings>
                ) : (
                    <MyClassesDrawer
                        open={props.open}
                        close={Close}
                    >
                        {DrawerList}
                    </MyClassesDrawer>
                )}
            </>
        );
    }
});

export default memo(ModalCalendarReschedule);
