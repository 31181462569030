export const SorryYouLeavingContent = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    textAlign: "center",
};

export const Avatar = {
    width: "60px",
    height: "60px",
};

export const ButtonsContainer = {
    display: "flex",
    gap: "12px",
    width: "fit-content",
};

export const SorryYouLeaving = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "40px",
    marginBottom: "30px",
    marginTop: "10px",
};
