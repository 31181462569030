export const ROLE_STATUSES = {
    tutor: [
        {
            id: "accepted",
            text: "Новый",
            color: "#6212FF",
            tooltipText: "Начни работу над заказом, нажав на кнопку «Начать работу»",
        },
        {
            id: "working",
            text: "В работе",
            color: "#FF9212",
            tooltipText: "После завершения этапа работы, отмечай его как выполненный, так клиент будет знать, что работа над заказом идёт",
        },
        {
            id: "waiting",
            text: "Ждет проверку",
            color: "#E12626",
            tooltipText: "Работа отправлена. У студента есть время на проверку.",
        },
        {
            id: "correcting",
            text: "Ожидает корректировки",
            color: "#FF9212",
            tooltipText: "Отправлен запрос на корректировку. Внеси изменения в работу и отправь ее до 07.05.24",
        },
        {
            id: "ended",
            text: "Заказ завершен",
            color: "#19B20C",
            tooltipText: "Работа на корректировке у специалиста.",
        },
    ],
    student: [
        {
            id: "accepted",
            text: "Принят в работу",
            color: "#6212FF",
            tooltipText: "Мы передали заказ и скоро специалист начнёт работу.",
        },
        {
            id: "working",
            text: "В работе",
            color: "#FF9212",
            tooltipText: "Специалист работает над вашим заказом",
        },
        {
            id: "waiting",
            text: "Ждет проверку",
            color: "#E12626",
            tooltipText:
                // TODO: изменить фразу
                "Специалист отправил работу. У вас есть время на проверку и корректировки.",
        },
        {
            id: "correcting",
            text: "На корректировке",
            color: "#FF9212",
            tooltipText: "Работа на корректировке у специалиста.",
        },
        {
            id: "ended",
            text: "Заказ завершен",
            color: "#19B20C",
            tooltipText: "Работа на корректировке у специалиста.",
        },
    ],
};
