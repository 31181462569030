import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Avatar, Box, IconButton, Typography, Modal } from "@mui/material";

import IconClose from "assets/ChatAssets/SVG/iconClose";
import IconLeft from "assets/ChatAssets/SVG/iconLeft";
import IconRight from "assets/ChatAssets/SVG/iconRight";
import IconDownload from "assets/ChatAssets/SVG/iconDownload";

import FullChatStore from "store/FullChatStore";

import * as textStyles from "themes/TextThemes";
import * as styles from "./ModalShowImageThemes";

import { findChatById } from "../../../CommonData/helper_functions";
import { useGetChatImageAttachment } from "hooks/useGetChatImageAttachment";

const ModalShowImage = ({ open, setOpen, avatar, chosenAttachment, chosenMessage, getDate, orderedGroupedMessages, handleDownload, mergeArraysFromObject, findIndexByLink }) => {
    const [chosenImage, setChosenImage] = useState(null);

    const [chosenMessageTime, setChosenMessageTime] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (chosenAttachment) {
            setChosenImage(chosenAttachment);
            setChosenMessageTime(chosenMessage);
        }
    }, [chosenAttachment]);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const imageArray = mergeArraysFromObject(orderedGroupedMessages);

    const handleClickNext = () => {
        setChosenImage(imageArray[findIndexByLink(imageArray, chosenImage) + 1].link);
        setChosenMessageTime(imageArray[findIndexByLink(imageArray, chosenImage) + 1].date);
    };

    const handleClickPrev = () => {
        setChosenImage(imageArray[findIndexByLink(imageArray, chosenImage) - 1].link);
        setChosenMessageTime(imageArray[findIndexByLink(imageArray, chosenImage) - 1].date);
    };

    const handleKeyDown = (event) => {
        if (event.key === "ArrowLeft" && findIndexByLink(imageArray, chosenImage) > 0) {
            handleClickPrev();
        } else if (event.key === "ArrowRight" && findIndexByLink(imageArray, chosenImage) < imageArray.length - 1) {
            handleClickNext();
        }
    };

    // Добавляем обработчик события клавиатуры при монтировании компонента
    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [findIndexByLink(imageArray, chosenImage)]);

    const boxRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

    const handleClickOutside = (event) => {
        // Проверяем, был ли клик сделан в пределах одного из элементов
        const isClickInsideBox = boxRefs.some((ref) => ref.current && ref.current.contains(event.target));

        const isModalOpen = open;

        // Если клик был сделан за пределами всех элементов, выполнить действие
        if (!isClickInsideBox && isModalOpen) {
            setOpen(false);
            // Здесь можно добавить действие, которое нужно выполнить при клике за пределами элементов
        }
    };

    useEffect(() => {
        // Добавляем обработчик события клика на всем документе
        document.addEventListener("click", handleClickOutside);

        // Удаляем обработчик события при размонтировании компонента
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [open]);

    return (
        <Box styles={styles.modalContainer}>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                slotProps={{
                    backdrop: {
                        sx: {
                            backgroundColor: "rgba(5, 1, 13, 0.80)", // Цвет и прозрачность фона
                        },
                    },
                }}
            >
                <Box sx={styles.imageOuterModal}>
                    <Box sx={styles.imageInnerModal}>
                        <Box sx={styles.topPanel}>
                            <IconButton
                                ref={boxRefs[4]}
                                sx={styles.iconClose}
                                onClick={handleClose}
                                disableRipple
                            >
                                <IconClose />
                            </IconButton>
                        </Box>
                        <Box sx={styles.imagePanel}>
                            <IconButton
                                ref={boxRefs[0]}
                                sx={styles.buttonNextPrev(findIndexByLink(imageArray, chosenImage) > 0)}
                                onClick={handleClickPrev}
                                disableRipple
                            >
                                <IconLeft />
                            </IconButton>
                            <img
                                ref={boxRefs[5]}
                                style={{ maxWidth: "70vw", maxHeight: "70vh" }}
                                src={useGetChatImageAttachment(chosenImage, [chosenImage])}
                            />
                            <IconButton
                                ref={boxRefs[1]}
                                sx={styles.buttonNextPrev(findIndexByLink(imageArray, chosenImage) < imageArray.length - 1)}
                                onClick={handleClickNext}
                                disableRipple
                            >
                                <IconRight />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={styles.bottomOuterPanel}>
                        <Box sx={styles.bottomInnerPanel}>
                            <Box
                                ref={boxRefs[2]}
                                sx={styles.avatarContainer}
                            >
                                <Avatar
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => {
                                        navigate("/tutor/64902a483594e963dd20818a");
                                    }}
                                    src={avatar[FullChatStore.tabsData.chats && findChatById(FullChatStore.tabsData.chats, FullChatStore.id).participants_info[0].name]}
                                />
                                <Box sx={styles.nameContainer}>
                                    <Typography
                                        onClick={() => {
                                            navigate("/tutor/64902a483594e963dd20818a");
                                        }}
                                        sx={{ ...textStyles.Button14White, cursor: "pointer" }}
                                    >
                                        {FullChatStore.tabsData.chats && findChatById(FullChatStore.tabsData.chats, FullChatStore.id).participants_info[0].name}
                                    </Typography>
                                    <Typography sx={textStyles.Caption1Grey}>{getDate(chosenMessageTime)}</Typography>
                                </Box>
                            </Box>
                            <IconButton
                                ref={boxRefs[3]}
                                sx={styles.downloadButton}
                                onClick={() => handleDownload(chosenImage, "ChatAttachment.jpg")}
                                disableRipple
                            >
                                <IconDownload />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default ModalShowImage;
