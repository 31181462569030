import React from "react";
import { Typography } from "@mui/material";
import * as themes from "components/SettingsBodyBlock/TutorSettings/MainForm/MainFormThemes";
import SButton from "components/UI/SButton";
import Box from "@mui/material/Box";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { memo } from "react";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { useMedias } from "API/mediasHook";
import SIconButton from "../../SIconButton";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";

const Education = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const handleEdit = () => {
        props.handleSet(4);
    };

    return (
        <Box sx={themes.SmallItem(medias)}>
            <Box sx={themes.ItemContent(medias)}>
                <Box sx={themes.TitleAndEdit}>
                    <Typography sx={medias.sm ? textThemes.HeadlineH41Black : textThemes.HeadlineH5Black}>{t("teacherSettings.questionnaire.education")}</Typography>
                    {medias.sm ? (
                        <SButton
                            onClick={handleEdit}
                            variant="text"
                        >
                            {t("teacherSettings.questionnaire.edit")}
                        </SButton>
                    ) : (
                        <SIconButton onClick={handleEdit}>
                            <DriveFileRenameOutlineOutlinedIcon />
                        </SIconButton>
                    )}
                </Box>
                <Box sx={themes.VerticalContainer}>
                    {TeacherQuestionnaireStore.teacher.education.map((item, index) => (
                        <Typography
                            sx={textThemes.Body1Black}
                            key={item.id}
                        >
                            {item.university}
                            {item.university && ", "}
                            {item.faculty}
                            {item.faculty && ", "}
                            {item.speciality && t("teacherSettings.questionnaire.speciality")}
                            {item.speciality}
                            {item.speciality && ", "}
                            <span style={textThemes.Body1DarkGrey}>
                                {item.stillStudying ? t("teacherSettings.questionnaire.from") + item.beginYear : item.endYear + t("teacherSettings.questionnaire.year")}
                            </span>
                        </Typography>
                    ))}
                </Box>
            </Box>
        </Box>
    );
});

export default memo(Education);
