import React from "react";
const VKLogo = ({ hover }) => {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="VK">
                <path
                    id="Combined-Shape"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0ZM19.4656 23.6821C19.2881 23.8693 18.9416 23.9071 18.9416 23.9071H17.7941C17.7941 23.9071 15.2623 24.0564 13.032 21.7777C10.5998 19.2917 8.45215 14.3592 8.45215 14.3592C8.45215 14.3592 8.3282 14.0356 8.46248 13.8798C8.61368 13.7038 9.02593 13.6927 9.02593 13.6927L11.769 13.6752C11.769 13.6752 12.0272 13.7167 12.2122 13.8503C12.3653 13.9609 12.4507 14.1665 12.4507 14.1665C12.4507 14.1665 12.894 15.2671 13.4809 16.2627C14.6275 18.2067 15.1609 18.6317 15.5497 18.4233C16.1169 18.1201 15.9469 15.6755 15.9469 15.6755C15.9469 15.6755 15.9572 14.7887 15.6614 14.3933C15.4323 14.0872 15.0003 13.9978 14.8097 13.9729C14.6547 13.9526 14.9083 13.6005 15.237 13.4429C15.7309 13.206 16.6024 13.1922 17.6325 13.2023C18.4354 13.2106 18.6664 13.2595 18.9801 13.3341C19.708 13.5066 19.6853 14.0591 19.6363 15.2518C19.6216 15.6084 19.6046 16.0224 19.6046 16.5005C19.6046 16.6079 19.6014 16.7224 19.5981 16.8403C19.5811 17.4513 19.5616 18.1509 19.9718 18.4113C20.1821 18.5441 20.6958 18.4307 21.9814 16.2885C22.5908 15.2726 23.0472 14.0789 23.0472 14.0789C23.0472 14.0789 23.1477 13.866 23.3027 13.7747C23.4614 13.6816 23.6755 13.7102 23.6755 13.7102L26.5622 13.6927C26.5622 13.6927 27.4299 13.5904 27.5698 13.9757C27.7172 14.3785 27.2458 15.3206 26.0663 16.8637C24.9475 18.3278 24.403 18.8669 24.453 19.3436C24.4895 19.692 24.8435 20.0071 25.5226 20.6255C26.9404 21.9176 27.3204 22.5974 27.4117 22.7607C27.4193 22.7743 27.4249 22.7843 27.4289 22.7908C28.0647 23.8259 26.7237 23.9071 26.7237 23.9071L24.1591 23.9421C24.1591 23.9421 23.6088 24.049 22.8838 23.5605C22.5041 23.3048 22.133 22.8871 21.7795 22.4894C21.2397 21.8819 20.7411 21.3207 20.3155 21.4533C19.6008 21.6763 19.6224 23.1871 19.6224 23.1871C19.6224 23.1871 19.6281 23.5107 19.4656 23.6821Z"
                    fill={hover ? "#8141FF" : "#6212FF"}
                />
            </g>
        </svg>
    );
};

export default VKLogo;
