import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import React from "react";
import QuestionnaireButtons from "components/TeacherQuestionnaireBodyBlock/Components/QuestionnaireButtons";
import { observer } from "mobx-react-lite";
import TeacherAvailability from "./TeacherAvailability/TeacherAvailability";
import { Box } from "@mui/material";
import * as theme from "./ClassesScheduleStepThemes";
import { memo } from "react";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";

const ClassesScheduleStep = observer(({ currentStep, setCurrentStep }) => {
    return (
        <ScrollToTop>
            <Box sx={theme.TeacherPersonalDataStepWider}>
                <TeacherAvailability />
                <QuestionnaireButtons
                    disabled={Object.values(TeacherQuestionnaireStore.teacher.timings).every((arr) => Array.isArray(arr) && arr.length === 0)}
                    setCurrentStep={setCurrentStep}
                    currentStep={currentStep}
                />
            </Box>
        </ScrollToTop>
    );
});

export default memo(ClassesScheduleStep);
