import { styleProcessing } from "API/plugins/Utilities";

export const UpcomingClassCard = (theme) => {
    let commonStyle = {
        borderRadius: "20px",
        maxWidth: "970px",
        width: "100%",
        padding: "18px 20px 17px 20px",
        background: "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            alignItems: "left",
            boxSizing: "border-box",
            gap: "8px",
            padding: "10px 16px 10px 16px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Grey = {
    color: "#77778A",
    fontSize: "12px",
};

export const Popper = {
    "& .MuiTooltip-tooltip": {
        border: "none",
        color: "#222",
        fontFamily: "Inter",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "400",
        borderRadius: "10px",
        bgcolor: "#fff",
        boxShadow: "0px 2px 10px 0px rgba(106, 99, 118, 0.15)",
        padding: "10px",
        textAlign: "center",
    },

    "& .MuiTooltip-arrow": {
        color: "#FFFFFF",
    },
};

export const Avatar = (theme) => {
    let commonStyle = {
        width: "36px",
        height: "36px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "24px",
            height: "24px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const StudentTextBox = {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
};

export const StudentNameBox = {
    display: "flex",
    alignItems: "center",
    gap: "4px",
};

export const IconBox = {
    height: "fit-content",
    display: "flex",
    alignItems: "center",
};

export const StudentBlock = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    width: "180px",
};

export const SubjectAimContainer = {
    display: "flex",
    flexDirection: "column",
    gap: 0,
};

export const LeftPanel = (theme) => {
    let commonStyle = {
        display: "flex",
        alignItems: "center",
        gap: "10px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            alignItems: "left",
            gap: "4px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ButtonsBlock = {
    display: "flex",
    alignItems: "center",
    gap: "20px",
};

export const Divider = {
    width: "1px",
    height: "40px",
    background: "rgba(218, 218, 223, 0.50)",
};

export const PlaceSubjectBlock = {
    paddingLeft: "20px",
    display: "flex",
    gap: "8px",
    alignItems: "flex-start",
};

export const PlaceIconContainer = {
    paddingTop: "2px",
};

export const TooltipPopperProps = {
    sx: {
        "& .MuiTooltip-tooltip": {
            border: "none",
            color: "#222",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "140%",
            borderRadius: "10px",
            bgcolor: "#fff",
            maxWidth: "196px",
            boxShadow: "0px 2px 10px 0px rgba(106, 99, 118, 0.15)",
            padding: "10px",
        },
    },
};

export const SubjectAimBlock = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "2px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            alignItems: "center",
            flexDirection: "row",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const RightPanel = (theme) => {
    let commonStyle = {
        display: "flex",
        alignItems: "center",
        gap: "10px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            justifyContent: "space-between",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TimeBlock = (theme) => {
    let commonStyle = {
        width: "118px",
        display: "flex",
        justifyContent: "center",
        gap: "2px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
            justifyContent: "left",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const MinutesBlock = {
    width: "89px",
    display: "flex",
    justifyContent: "center",
};

export const Point = {
    borderRadius: 999,
    background: "#9E9EB0",
    width: "6px",
    height: "6px",
};

export const CanceledBlock = {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    padding: "2px 10px 4px 9px",
    borderRadius: "26px",
    background: "rgba(187, 187, 200, 0.17)",
};
