import React from "react";
import { TextField, Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import * as textThemes from "themes/TextThemes";
import { Caption1Red } from "themes/TextThemes";

const FeedBackInput = (props) => {
    const [length, setLength] = useState(props.value?.length);

    const [error, setError] = useState(false);

    const [errormessage, setMessage] = useState("");

    const handleChange = (e) => {
        props.setFeedBackValue(e.target.value);
        if (props.setCustomLength !== undefined) {
            props.setCustomLength(e.target.value.length);
            setLength(e.target.value.length);
        } else {
            setLength(e.target.value.length);
        }

        if (e.target.value.length > props.max) {
            setError(true);
            setMessage("Достигнут лимит символов");
        } else {
            setError(false);
            setMessage("");
        }
    };

    return (
        <Box>
            <TextField
                fullWidth
                placeholder={props.placeholder}
                multiline
                rows={props.rows ? props.rows : 6}
                error={error}
                helperText={
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <Typography sx={error ? textThemes.Caption1Red : textThemes.Caption1Black}>{errormessage ? errormessage : props.message}</Typography>
                        <Typography sx={length < props.max ? textThemes.Caption1Grey : length === props.max ? textThemes.Caption1Black : textThemes.Caption1Red}>
                            {length}/<span style={textThemes.Caption1Black}>{props.max}</span>
                        </Typography>
                    </Box>
                }
                inputProps={{
                    sx: {
                        "&::-webkit-scrollbar": {
                            width: "0.3em",
                        },

                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#bbbbc8",
                            borderRadius: "10px",
                        },
                    },
                    minLength: 500,
                    maxLength: props.max,
                }}
                sx={{
                    "& .MuiFormHelperText-root": {
                        margin: "4px 0 0 0",
                    },

                    height: props.height ? props.height : "400px",
                    borderRadius: "10px",
                    width: props.width ? props.width : "400px",
                    "& ::placeholder": {},

                    "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                            borderColor: "#6212FF",
                            borderWidth: "1px",
                        },
                        "&:hover fieldset": {
                            borderColor: "#6212FF",
                            borderWidth: "1px",
                        },
                    },
                    "& .MuiInputBase-root": {
                        "& input": {
                            textAlign: "left",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "150%",
                            color: "#222",
                        },
                    },
                }}
                onChange={handleChange}
                value={props.value}
            ></TextField>
        </Box>
    );
};

export default FeedBackInput;
