import { createTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

export const themeWrapper = (error) =>
    createTheme({
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": error ? "#d32f2f" : "#9E9EB0",
                        "--TextField-brandBorderHoverColor": error ? "#d32f2f" : "#6212FF",
                        "--TextField-brandBorderFocusedColor": error ? "#d32f2f" : "#6212FF",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                        "& .Mui-focused": {
                            background: "#FFFFFF",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&:before, &:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&:before": {
                            borderBottom: "1px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "1px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "1px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },

            MuiAutocomplete: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        ".MuiAutocomplete-popupIndicator:hover": {
                            background: "#FFFFFF",
                            color: "#6212FF",
                        },
                    },
                    option: {
                        '&[aria-selected="true"]': {
                            background: "#6212FF!important",
                            color: "#FFFFFF",
                        },
                        "&:hover": {
                            background: "#6212FF!important",
                            color: "#FFFFFF",
                        },
                        "&:hover div > span": {
                            color: "#FFFFFF!important",
                        },
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "130%",
                        fontFeatureSettings: "'tnum' on, 'lnum' on",
                        color: "#222222",
                        flex: "none",
                        order: 0,
                        flexGrow: 0,
                    },
                    clearIndicator: {
                        "&:hover": {
                            color: "#6212FF",
                            background: "white",
                        },
                    },
                },
            },

            MuiInputBase: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        boxSizing: "border-box",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "13px 16px",
                        gap: "4px",
                        width: "197px",
                        height: "44px",
                        flex: "none",
                        order: 1,
                        flexGrow: 0,
                        "&.MuiInputBase-root": {
                            border: "1px!important solid #9E9EB0",
                            height: "34px",
                            borderRadius: "12px",
                            width: "100%",
                            paddingLeft: 0,
                            paddingTop: 0,
                        },
                        ".MuiInputBase-input": {
                            width: "-webkit-fill-available!important",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "150%",
                            fontFeatureSettings: "'tnum' on, 'lnum' on",
                            color: "#222222",
                            flex: "none",
                            order: 0,
                            flexGrow: 0,
                        },
                    },
                },
            },
        },
    });
