const RedExclamationIcon = function (props) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.0054 0C4.48725 0 0 4.48725 0 10C0 15.5128 4.48725 20 10 20C15.5128 20 20 15.5128 20 10C20 4.48725 15.5182 0 10.0054 0ZM10.0054 17.3087C9.30005 17.3087 8.72491 16.7336 8.72491 16.0282C8.72491 15.3228 9.30005 14.7477 10.0054 14.7477C10.7108 14.7477 11.2859 15.3228 11.2859 16.0282C11.2859 16.7336 10.7108 17.3087 10.0054 17.3087ZM11.8394 4.83451L10.6782 12.6587C10.624 13.0331 10.3364 13.3044 10 13.3044C9.66359 13.3044 9.37602 13.0331 9.32176 12.6587L8.16061 4.83451C8.07379 4.24851 8.23657 3.64623 8.60011 3.22301C8.89311 2.88117 9.2892 2.69126 9.707 2.69126H10.2876C10.7054 2.69126 11.1015 2.88117 11.3945 3.22301C11.7634 3.64623 11.9262 4.24851 11.8394 4.83451Z"
                fill="#E12626"
            />
        </svg>
    );
};

export default RedExclamationIcon;
