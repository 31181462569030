import { Typography, TextField, Tab, Tabs, MenuItem } from "@mui/material";
import { styled } from "@mui/system";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CustomPopper } from "./dialog_chat";
import { Swiper, SwiperSlide } from "swiper/react";

export const StyledSwiper = styled(Swiper)((props) => ({
    width: "100%",
    ".swiper-slide": {
        display: "block",
        width: "100%",
        height: "100%",
    },

    ".swiper-slide div *": {
        width: "initial",
        height: "initial",
    },
}));

export const StyledInfoCardText = styled(Typography)((props) => ({
    fontWeight: "bold",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: "Inter",
    fontStyle: "normal",
    /*fontWeight: props.fontWeight,*/ //CHECK THIS
    fontSize: props.fontSize,
    lineHeight: props.lineHeight,
    color: props.color,
}));

export const StyledTextFieldSearchChat = styled(TextField)((props) => ({
    backgroundColor: "rgba(187, 187, 200, 0.2)",
    borderStyle: "solid",
    borderWidth: "0px",
    borderRadius: "8px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
        borderWidth: 0,
        width: "100%",
        height: "100%",
        borderRadius: "inherit",
        pl: "10px",
        "& .MuiInputBase-input.MuiOutlinedInput-input": {
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            color: "black",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: 0,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderWidth: 0,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: 0,
        },
    },
}));

export const StyledTab = styled(Tab)((props) => ({
    textTransform: "none",
    padding: 0,
    marginLeft: 13,
    minHeight: 0,
    paddingTop: "15px",
    color: "rgba(119, 119, 138, 1)",
    "&.Mui-selected": {
        color: "rgba(98, 18, 255, 1)",
    },
    "&.Mui-focusVisible": {
        backgroundColor: "rgba(119, 119, 138, 0.1)",
        borderRadius: "0px",
    },
    "&.MuiButtonBase-root.MuiTab-root.Mui-selected": {
        color: "rgba(119, 119, 138, 1)",
        backgroundColor: "white",
    },
    "&.MuiButtonBase-root.MuiTab-root": {
        minHeight: "50px",
        paddingBottom: "10px",
    },
}));

export const StyledTabs = styled(Tabs)((props) => ({
    height: "46px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "130%",
    borderTopStyle: "solid",
    borderBottomStyle: "solid",
    borderWidth: "1px",
    borderColor: "rgba(187, 187, 200, 0.5)",
    "& .MuiTabs-indicator": {
        backgroundColor: "rgba(98, 18, 255, 1)",
    },
}));

export const StyledCustomPopper = styled(CustomPopper)((props) => ({
    marginLeft: props.marginLeft || "-10px !important",
    marginTop: props.marginTop || "0pxx !important",
    backgroundColor: "white",
    borderRadius: "10px",
    border: "1px solid rgba(187, 187, 200, 0.3)",
    filter: "drop-shadow(0px 4px 10px rgba(106, 99, 118, 0.15))",
    maxWidth: "185px",
    minWidth: props.minWidth || "165px",
    overflow: "hidden",
    paddingTop: "6px",
    paddingBottom: "6px",
    zIndex: "998",
    "& li": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "135%",
        color: "#222222",
        padding: "3px 6px 3px",
        "&:hover": {
            backgroundColor: "rgba(243, 243, 243, 1)",
            color: "rgba(98, 18, 255, 1)",
        },
    },
}));

export const StyledMenuItem = styled(MenuItem)((props) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "10px",
    marginLeft: "6px",
    marginRight: "6px",
    paddingLeft: "2px",
    paddingRight: "2x",
}));

export const StyledCheckIcon = styled(CheckIcon)((props) => ({
    width: "15px",
    height: "15px",
    paddingRight: "4px",
}));

export const StyledArrowForwardIosIcon = styled(ArrowForwardIosIcon)((props) => ({
    width: "15px",
    height: "15px",
    paddingRight: "4px",
}));
