import { Box, TextField, styled } from "@mui/material";
export const StyledComplainTextField = styled(TextField)({
    maxWidth: "400px",
    width: "100%",
    marginTop: "20px",
    "& .MuiInputBase-input": {
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 400,
        letterSpacing: "0em",
        textTransform: "none",
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "10px",
        "&.Mui-focused fieldset": {
            borderColor: "rgba(98, 18, 255, 1)",
        },
        "&.Mui-error fieldset": {
            borderColor: "red",
        },
    },
});

export const TextBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0em",
});
