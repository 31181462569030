export const CityLangCurrChoice = (medias) => {
    return {
        display: medias.sm ? "flex" : "none",
        alignItems: "center",
    };
};

export const CityButton = {
    padding: 0,
    margin: 0,
    textTransform: "none",
    gap: "5px",
    ":hover": {
        background: "rgba(255, 255, 255, 0.00)",
        color: "#222",
    },
};
