import { Avatar, Grid, IconButton, InputAdornment, Radio, TextField } from "@mui/material";
import { Box } from "@mui/material";
import { IconBackMini } from "assets/MiniChat/SVG/IconBackMini";
import SButton from "components/UI/SButton";
import React from "react";
import * as Styles from "components/MiniChat/OneChat/TeacherField/TeacherFieldStyles";
import * as Styles2 from "./NewFolderStyles";
import { SearchIcon } from "assets/MiniChat/SVG/SearchIcon";
import MiniChatStore from "store/MiniChatStore";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import FullChatStore from "store/FullChatStore";

const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "90%",
    width: "2.1vh",
    height: "2.1vh",
    boxShadow: theme.palette.mode === "dark" ? "#6212FF" : "inset 0 0 0 1px rgba(98, 18, 255, 1), inset 0 -1px 0 rgba(98, 18, 255, 1)",
    "input:hover ~ &": {
        backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#6212FF",
    backgroundImage: "#6212FF",
    "input:hover ~ &": {
        backgroundColor: "#6212FF",
    },
});

export function BpRadio(props) {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}

const NewFolder = (props) => {
    const GetDialog = () => {
        if (MiniChatStore.newFolder) {
            if (MiniChatStore.newFolder.length > 0 || MiniChatStore.FolderChanged) return MiniChatStore.newFolder;
            else if (props.folderChats)
                return props.folderChats.infocards.map((item) => ({
                    chat_id: item.chat_id,
                    name: item.name,
                    avatar: item.avatar,
                }));
            else return [];
        } else if (props.folderChats)
            return props.folderChats.infocards.map((item) => ({
                chat_id: item.chat_id,
                name: item.name,
                avatar: item.avatar,
            }));
        else return [];
    };

    const [newdialogs, setNewDialogs] = useState(GetDialog());

    const Ready = () => {
        MiniChatStore.setFolder(newdialogs);
        MiniChatStore.SetFolderChanged(true);
        props.setPage(2);
    };

    const [edited, setEdited] = useState(false);

    const [name, setName] = useState("");

    const handleChange = (e) => {
        setName(e.target.value);
    };

    const handleChangeDialog = (e) => {
        setEdited(true);
        let ind = newdialogs.find((chat) => chat.chat_id === e.chat_id);

        if (ind === undefined) setNewDialogs([...newdialogs, e]);
        else {
            setNewDialogs(newdialogs.filter((item) => item.chat_id !== ind.chat_id));
        }
    };

    const [curserv, setCurServ] = useState(FullChatStore.tabsData.chats);

    const SearchedChats = useMemo(() => {
        return [...curserv].filter((item) => (name !== "" ? (item.participants_info[0].name + item.participants_info[0].surname).toLowerCase().includes(name.toLowerCase()) : true));
    }, [name, curserv]);

    return (
        <>
            <Box sx={{ ...Styles.TeacherField, marginBottom: "20px" }}>
                <Grid container>
                    <Grid
                        item
                        xs={1}
                    >
                        <IconButton
                            sx={Styles.IconBack}
                            onClick={() => {
                                props.setPage(2);
                                setNewDialogs();
                            }}
                        >
                            <IconBackMini />
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        xs={8.5}
                        sx={{ fontSize: "2.2vh", textAlign: "center", paddingTop: "1.25vh" }}
                    >
                        Добавить чаты
                    </Grid>
                    <Grid
                        item
                        xs={2.5}
                    >
                        <SButton
                            variant="text"
                            style={{ marginTop: "1.25vh", fontSize: "1.9vh" }}
                            disabled={!edited}
                            onClick={() => Ready()}
                        >
                            <span style={{ fontSize: "1.9vh" }}>Готово</span>
                        </SButton>
                    </Grid>
                </Grid>
            </Box>
            <TextField
                placeholder="Найти по имени"
                fullWidth
                value={name}
                onChange={handleChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                sx={Styles2.TextStyles}
                margin="none"
            />
            <Grid sx={Styles2.GridChats}>
                {SearchedChats.map((item, index) => (
                    <Grid
                        key={index}
                        container
                        sx={Styles2.ChatItem}
                    >
                        <Grid
                            item
                            xs={1.4}
                        >
                            <Avatar
                                src={"https://study-buddy.ru" + item.participants_info[0].photoLink}
                                sx={{ width: "4.4vh", height: "4.4vh" }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={9.6}
                            sx={{ paddingTop: "0.8vh" }}
                        >
                            {item.participants_info[0].name + " " + item.participants_info[0].surname}
                        </Grid>
                        <Grid
                            item
                            xs={1}
                        >
                            <BpRadio
                                checked={newdialogs && newdialogs.find((chat) => chat.chat_id === item.chat_id) !== undefined}
                                onClick={() =>
                                    handleChangeDialog({
                                        chat_id: item.chat_id,
                                        name: item.participants_info[0].name + " " + item.participants_info[0].surname,
                                        avatar: "https://study-buddy.ru" + item.participants_info[0].photoLink,
                                    })
                                }
                            ></BpRadio>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default observer(NewFolder);
