export const TeacherField = {
    width: "764px",
    borderRadius: "20px 20px 0px 0px",
    borderBottom: "1px solid #DADADF",
    backgroundColor: "#FFF",
    padding: "21px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
};

export const TextStyles = {
    width: "fit-content",
    "& fieldset": { border: "none" },
    "& .MuiOutlinedInput-input": {
        padding: 0,
        height: "24px",
    },
    "& .MuiOutlinedInput-root": {
        padding: "8px 12px",
        backgroundColor: "#F3F3F3",
        borderRadius: "10px",
        width: "470px",
        lineHeight: "150%",
        fontSize: "14px",
        fontWeight: 500,
    },
};

export const ChatItem = {
    borderBottom: "1px solid #EEEEF0",
    padding: "8px 16px",
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
};

export const GridChats = {
    overflow: "auto",
    width: "470px",
};

export const AddChatsToFolder = {
    backgroundColor: "#fff",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    minHeight: "80vh",
};

export const Avatar = {
    height: "32px",
    width: "32px",
};

export const AvatarTextCard = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
};

export const AddChatsToFolderContent = {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    alignItems: "center",
};
