import React from "react";
const Vk = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM25.3895 21.8463L22.6295 21.8842C22.6295 21.8842 22.0358 22.0021 21.2569 21.4631C20.2232 20.7536 19.2506 18.9116 18.4927 19.1473C17.7348 19.3831 17.7474 21.0421 17.7474 21.0421C17.7499 21.2367 17.6901 21.4269 17.5769 21.5853C17.4176 21.7217 17.2212 21.8075 17.0128 21.8316H15.7896C15.7896 21.8316 13.0527 21.9937 10.6632 19.4968C8.03588 16.7852 5.72219 11.3684 5.72219 11.3684C5.72219 11.3684 5.58594 11.0542 5.73481 10.8421C5.8635 10.6587 6.18087 10.639 6.34113 10.6316C7.03194 10.5992 9.10744 10.6126 9.10744 10.6126C9.28106 10.6083 9.39775 10.6174 9.61269 10.7116C9.79744 10.8029 9.93756 10.9389 10.0274 11.1579C10.3468 11.953 10.7174 12.7266 11.1369 13.4737C12.3706 15.6063 12.9454 16.0716 13.3643 15.8442C13.9727 15.5116 13.7854 12.8316 13.7854 12.8316C13.7854 12.8316 13.7854 11.8568 13.478 11.4253C13.2467 11.155 12.9203 10.9839 12.5664 10.9474C12.4001 10.9242 12.6738 10.5389 13.0275 10.3663C13.558 10.1052 14.497 10.1053 15.6043 10.1053C16.0916 10.0869 16.5791 10.1365 17.0527 10.2526C18.0717 10.4989 17.7264 11.4463 17.7264 13.7242C17.7264 14.4526 17.5938 15.48 18.1201 15.8294C18.3475 15.9747 18.8991 15.8505 20.2843 13.501C20.7262 12.7235 21.1098 11.9143 21.4317 11.0799C21.4937 10.9473 21.5877 10.8323 21.7054 10.7452C21.8298 10.6833 21.9695 10.6591 22.1075 10.6757L25.2149 10.6546C25.2149 10.6546 26.1475 10.5431 26.2991 10.9662C26.4506 11.3894 25.9496 12.4399 24.6801 14.1241C22.5959 16.903 22.3643 16.6504 24.0949 18.2482C25.7475 19.783 26.0907 20.5304 26.1475 20.623C26.8337 21.7558 25.3895 21.8463 25.3895 21.8463Z"
                fill="white"
            />
        </svg>
    );
};

export default Vk;
