import { createTheme } from "@mui/material/styles";

export const actionsContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    height: "42px",
    flex: "none",
    order: 1,
    flexGrow: 0,
};

export const priceContainerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    gap: "5px",
    height: "26px",
    left: "0px",
};

export const textContainerStyle = {
    display: "flex",
    gap: "5px",
};

export const fromTextStyle = {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "170%",
    display: "flex",
    alignItems: "center",
    color: "#9E9EB0",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const fromTextValueStyle = {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#222222",
    flex: "none",
    order: 0,
    flexGrow: 0,
};

export const AddCashbackButtonTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "5px",
                    width: "47px",
                    height: "26px",
                    padding: "2px 12px 3px 8px!important",
                    background: "linear-gradient(256.9deg, #04C83B 20.58%, #12AAFF 81.82%)",
                    borderRadius: "20px!important",
                    flex: "none",
                    minWidth: "0px!important",
                    order: 1,
                    flexGrow: 0,
                },
            },
        },

        MuiTooltip: {
            styleOverrides: {
                root: {},
                tooltip: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    padding: "10px",
                    boxSizing: "border-box",
                    marginLeft: "6px!important",
                    background: "#FFFFFF",
                    borderRadius: "10px",
                    flex: "none",
                    order: 1,
                    flexGrow: 1,
                    filter: "drop-shadow(0px 2px 10px rgba(106, 99, 118, 0.15))",
                },
                arrow: {
                    color: "white!important",
                    filter: "drop-shadow(0px 2px 10px rgba(106, 99, 118, 0.15))",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                },
            },
        },
    },
});

export const tooltipCashbackStyle = {
    width: "214px",
    fontWtyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "140%",
    display: "flex",
    alignItems: "center",
    color: "#222222!important",
    flex: "none",
    order: 0,
    flexGrow: 1,
};

export const tooltipCashbackValueStyle = {
    color: "#19B20C",
};

export const cashbackTextStyle = {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    flex: "none",
    order: 0,
    flexGrow: 0,
};
