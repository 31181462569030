import React, { useState } from "react";
import PopperMenu from "components/UI/PopperMenu";
import ClipMenuContent from "./ClipMenuContent/ClipMenuContent";
import { PaperClip } from "assets/MiniChat/SVG/PaperClip";
import * as themes from "./ClipItemsThemes";
import { memo } from "react";
import SButton from "components/UI/SButton";

const ClipItems = () => {
    const [openClipMenu, setOpenClipMenu] = useState(false);

    return (
        <PopperMenu
            open={openClipMenu}
            setOpen={setOpenClipMenu}
            content={<ClipMenuContent setOpenClipMenu={setOpenClipMenu} />}
            zIndex={1}
            placement="top"
            paperSx={themes.ClipItemsPaper}
        >
            <SButton variant="icon">
                <PaperClip />
            </SButton>
        </PopperMenu>
    );
};

export default memo(ClipItems);
