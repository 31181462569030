const PptIconSVG = function (props) {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 34.48V5.52C0 2.48 2.48 0 5.52 0H25.576V8.864C25.576 11.904 27.056 13.368 30.096 13.368H40V34.48C40 37.52 37.52 40 34.48 40H5.52C2.48 40 0 37.52 0 34.48ZM30.0962 11.1677C28.8162 11.1677 27.7762 10.1277 27.7762 8.86369V0.879687L39.0082 11.1677H30.0962Z"
                fill="#F16385"
            />
            <path
                d="M9.84766 27V19H13.0039C13.6107 19 14.1276 19.1159 14.5547 19.3477C14.9818 19.5768 15.3073 19.8958 15.5312 20.3047C15.7578 20.7109 15.8711 21.1797 15.8711 21.7109C15.8711 22.2422 15.7565 22.7109 15.5273 23.1172C15.2982 23.5234 14.9661 23.8398 14.5312 24.0664C14.099 24.293 13.5755 24.4062 12.9609 24.4062H10.9492V23.0508H12.6875C13.013 23.0508 13.2812 22.9948 13.4922 22.8828C13.7057 22.7682 13.8646 22.6107 13.9688 22.4102C14.0755 22.207 14.1289 21.974 14.1289 21.7109C14.1289 21.4453 14.0755 21.2135 13.9688 21.0156C13.8646 20.8151 13.7057 20.6602 13.4922 20.5508C13.2786 20.4388 13.0078 20.3828 12.6797 20.3828H11.5391V27H9.84766Z"
                fill="white"
            />
            <path
                d="M17.5197 27V19H20.676C21.2827 19 21.7997 19.1159 22.2268 19.3477C22.6538 19.5768 22.9794 19.8958 23.2033 20.3047C23.4299 20.7109 23.5432 21.1797 23.5432 21.7109C23.5432 22.2422 23.4286 22.7109 23.1994 23.1172C22.9702 23.5234 22.6382 23.8398 22.2033 24.0664C21.771 24.293 21.2476 24.4062 20.633 24.4062H18.6213V23.0508H20.3596C20.6851 23.0508 20.9533 22.9948 21.1643 22.8828C21.3778 22.7682 21.5367 22.6107 21.6408 22.4102C21.7476 22.207 21.801 21.974 21.801 21.7109C21.801 21.4453 21.7476 21.2135 21.6408 21.0156C21.5367 20.8151 21.3778 20.6602 21.1643 20.5508C20.9507 20.4388 20.6799 20.3828 20.3518 20.3828H19.2111V27H17.5197Z"
                fill="white"
            />
            <path
                d="M24.8871 20.3945V19H31.4574V20.3945H29.0082V27H27.3363V20.3945H24.8871Z"
                fill="white"
            />
        </svg>
    );
};

export default PptIconSVG;
