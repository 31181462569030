import React, { memo } from "react";
import TeacherInfoCard from "components/UI/TeacherCard/TeacherInfoCard/TeacherInfoCard";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import * as themes from "./TutorBodyBlockThemes";
import StickyVideoBlock from "./StickyVideoBlock/StickyVideoBlock";
import DescriptionCard from "./DescriptionCard/DescriptionCard";
import ScheduleCard from "./ScheduleCard/ScheduleCard";
import ReviewsCard from "./ReviewsCard/ReviewsCard";
import EducationCard from "./EducationCard/EducationCard";
import DisciplinesCard from "./DisciplinesCard/DisciplinesCard";
import TeacherStore from "store/TeacherStore";
import { useMedias } from "../../API/mediasHook";
import CardActionsAltSticky from "../UI/TeacherCard/TeacherInfoCard/CardActionsAltSticky";

const TutorBodyBlock = observer(({ children, ...props }) => {
    const scroll = (event, value) => {
        document.getElementById(value)?.scrollIntoView({ block: "start", behavior: "smooth" });
    };

    const medias = useMedias();

    return (
        <Box sx={themes.tutorBodyBlockStyle}>
            <Box sx={themes.tutorInfoBlockStyle(medias)}>
                <TeacherInfoCard
                    value={TeacherStore.tutorData}
                    styleMode={1}
                    onTabChanged={scroll}
                ></TeacherInfoCard>
                <DescriptionCard value={TeacherStore.tutorData}></DescriptionCard>
                <ScheduleCard value={TeacherStore.tutorData}></ScheduleCard>
                <ReviewsCard value={TeacherStore.tutorData}></ReviewsCard>
                <EducationCard value={TeacherStore.tutorData}></EducationCard>
                <DisciplinesCard value={TeacherStore.tutorData}></DisciplinesCard>
                {!medias.sm ? <CardActionsAltSticky value={TeacherStore.tutorData}></CardActionsAltSticky> : null}
            </Box>
            {medias.sm ? <StickyVideoBlock value={TeacherStore.tutorData}></StickyVideoBlock> : null}
            {/*{!medias.sm ? <CardActionsAltSticky value={TeacherStore.tutorData} />}*/}
        </Box>
    );
});

export default memo(TutorBodyBlock);
