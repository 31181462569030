import { Typography, styled } from "@mui/material";

export const ZeroResponseCardSubtitleStyled = styled(Typography)({
    fontFamily: "inherit",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
});

export const ZeroCardsTitleStyled = styled(Typography)({
    fontFamily: "inherit",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "33,6px",
});
