import React from "react";

const ArrowBackShortIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <g clipPath="url(#clip0_2566_65071)">
                <path
                    d="M16.5 12.75C16.9142 12.75 17.25 12.4142 17.25 12C17.25 11.5858 16.9142 11.25 16.5 11.25L16.5 12.75ZM6.96967 11.4697C6.67678 11.7626 6.67678 12.2374 6.96967 12.5303L11.7426 17.3033C12.0355 17.5962 12.5104 17.5962 12.8033 17.3033C13.0962 17.0104 13.0962 16.5355 12.8033 16.2426L8.56066 12L12.8033 7.75736C13.0962 7.46447 13.0962 6.98959 12.8033 6.6967C12.5104 6.40381 12.0355 6.40381 11.7426 6.6967L6.96967 11.4697ZM16.5 11.25L7.5 11.25L7.5 12.75L16.5 12.75L16.5 11.25Z"
                    fill="#222222"
                />
            </g>
            <defs>
                <clipPath id="clip0_2566_65071">
                    <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(24 24) rotate(180)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ArrowBackShortIcon;
