export const AllBlock = {
    width: "1216px",
    height: "456px",
    marginTop: "140px",
    borderRadius: "20px",
    border: "2px solid #6212FF",
    position: "relative",
};

export const TextBlock = {
    width: "445px",
    height: "142px",
    gap: "16px",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    left: "50px",
    top: "157px",
};

export const SubjectsImg = {
    width: "335px",
    height: "175px",
    left: "621px",
    top: "79px",
    position: "absolute",
};

export const TeacherResponseImg = {
    width: "157px",
    height: "207px",
    left: "969px",
    top: "90px",
    position: "absolute",
};

export const BonusAccountImg = {
    width: "180px",
    height: "140px",
    left: "801px",
    top: "228px",
    position: "absolute",
};

export const IconCoinPosition = { left: 202, top: 23, position: "absolute" };

export const IconCoinsPosition = { left: 389, top: 93, position: "absolute" };

export const IconHandPosition = { left: 339, top: 331, position: "absolute" };
