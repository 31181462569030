import React from "react";

import { observer } from "mobx-react-lite";

import ConditionComponent from "./ConditionComponent";

import store from "store/RequestStore";

import * as theme from "./RightPanelThemes";
import { Box } from "@mui/material";

const RigthPanel = () => {
    return (
        <Box>
            <Box sx={theme.RightPanelTitleStyles}>{store.storage[store.activeStepIndex].substeps[store.subStepIndex]?.title}</Box>
            <ConditionComponent />
        </Box>
    );
};

export default observer(RigthPanel);
