export const otherTutorsBlockStyle = (medias) => {
    return {
        display: "flex",
        flexDirection: "column",
        gap: 40,
        paddingBottom: 75,
        paddingTop: 61,
        width: medias.sm ? 1216 : 360,
    };
};

export const otherTutorsHeaderStyle = (mode) => {
    return {
        color: "var(--text-black, #222)",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "120%",
        textAlign: mode ? "center" : "start",
    };
};

export const otherTutorsContainerStyle = (medias) => {
    return {
        display: "flex",
        flexDirection: "row",
        gap: 18,
        width: medias.sm ? 1216 : 360,
        height: medias.sm ? 359 : 460,
    };
};
