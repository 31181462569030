import React, { memo } from "react";
import * as themes from "./CardTextThemes";
import NameBlock from "./NameBlock";
import SubjectBlock from "./SubjectBlock/SubjectBlock";
import TagsBlock from "./TagsBlock";
import DescriptionBlock from "./DescriptionBlock";
import { useMedias } from "../../../../API/mediasHook";
import Box from "@mui/material/Box";

const CardText = ({ children, ...props }) => {
    const medias = useMedias();

    return (
        <Box sx={themes.cardTextContainerStyle(props.styleMode ? props.styleMode : 0, medias)}>
            {medias.sm && (
                <NameBlock
                    value={props.value}
                    styleMode={props.styleMode}
                ></NameBlock>
            )}
            <SubjectBlock
                value={props.value}
                styleMode={props.styleMode}
            ></SubjectBlock>
            <TagsBlock
                value={props.value}
                styleMode={props.styleMode}
            ></TagsBlock>
            {(props.styleMode ? props.styleMode : 0) ? null : <DescriptionBlock value={props.value}></DescriptionBlock>}
        </Box>
    );
};

export default memo(CardText);
