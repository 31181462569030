export const FILES = [
    // {
    //     type: "doc",
    //     name: "Программный модуль автоматизации проверки безопасности приложений iOS.doc",
    //     size: "3.2MB",
    //     date: "30 апреля в 20:30",
    //     link: 'link'
    // },
    // {
    //     type: "doc",
    //     name: "Программный модуль автоматизации проверки безопасности приложений iOS. Доработанный вариант.doc",
    //     size: "3.2MB",
    //     date: "2 мая в 19:07",
    //     link: 'link'
    // },
    // {
    //     type: "pdf",
    //     name: "Доп.требования к работе.pdf",
    //     size: "3.2MB",
    //     date: "30 апреля в 20:30",
    //     link: 'link'
    // },
];
