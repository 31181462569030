import { styleProcessing } from "API/plugins/Utilities";

export const LoginDetailsContent = {
    display: "flex",
    flexDirection: "column",
    gap: "0",
    alignItems: "flex-start",
};

export const LoginDetailsBlock = {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
};

export const TopInfoTeacher = (theme) => {
    let commonStyle = {
        borderRadius: "20px 20px 0px 0px",
        background: "white",
        fontSize: "24px",
        fontWeight: "600",
        lineHeight: "150%",
        color: "black",
        paddingTop: "24px",
        paddingLeft: "30px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            paddingLeft: "20px",
            color: "#222",
            fontStyle: "normal",
            fontSize: "18px",
            fontWeight: "600",
            lineHeight: "150%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TopInfoStudent = (theme) => {
    let commonStyle = {
        borderRadius: "20px 20px 0px 0px",
        background: "#6212FF",
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "150%",
        color: "white",
        paddingTop: "9px",
        paddingLeft: "30px",
        paddingBottom: "9px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            paddingLeft: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const LoginDetailsBlocks = (theme) => {
    let commonStyle = {
        borderRadius: "0px 0px 20px 20px",
        background: "#FFF",
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const LoginDetailsMainContainerTeacher = {
    maxWidth: "701px",
    width: "100%",
};

export const LoginDetailsMainContainerStudent = (theme) => {
    let commonStyle = {
        maxWidth: "495px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column-reverse",
            maxWidth: "600px",
            gap: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
