export const getPlayerStyles = (medias) => {
    return {
        width: medias.sm ? "161px" : "135px",
        height: medias.sm ? "251px" : "292px",
    };
};

export const getPlayerTheme = () => {
    return {
        position: "absolute",
        background: "white",
        border: "1px solid white",
    };
};
