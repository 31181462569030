import React from "react";

import { Dialog, Box, ThemeProvider } from "@mui/material";

import ModalTitle from "./ModalTitle";
import ModalContent from "./ModalContent";

import * as themes from "components/OrderBodyBlock/StickyActionBlock/ModalResponseThemes";

const SendForCorrectionInfoModal = ({ open = false, close }) => {
    return (
        <ThemeProvider theme={themes.DialogContainerTheme}>
            <Dialog
                open={open}
                onClose={close}
            >
                <Box width="575px">
                    <ModalTitle
                        close={close}
                        title={
                            <span>
                                Хотите отправить работу <br /> на корректировку
                            </span>
                        }
                    />
                    <ModalContent close={close} />
                </Box>
            </Dialog>
        </ThemeProvider>
    );
};

export default SendForCorrectionInfoModal;
