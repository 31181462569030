export const Popper = {
    zIndex: "900",
};

export const Paper = {
    marginLeft: "500px",
    marginTop: "-20px",
    backgroundColor: "white",
    borderRadius: "10px",
    border: "none",
    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
    p: "12px 16px",
};

export const PaperContent = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
};

export const CheckBox = {
    padding: 0,
};

export const CheckboxContainer = {
    textAlign: "center",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    gap: "10px",
};

export const ButtonsContainer = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
};

export const BackButton = {
    minWidth: 0,
};

export const ContinueButton = {
    color: "red",
};
