export const PaperClip = () => {
    return (
        <svg
            width="3.3vh"
            height="3.3vh"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.09 13.5008L10.9558 18.635C10.0818 19.509 8.89641 20 7.6604 20C6.42438 20 5.23899 19.509 4.365 18.635C3.491 17.761 3 16.5756 3 15.3396C3 14.1036 3.491 12.9182 4.365 12.0442L7.9321 8.4771L11.4992 4.91C12.0819 4.32734 12.8721 4 13.6961 4C14.5201 4 15.3104 4.32734 15.8931 4.91C16.4757 5.49266 16.8031 6.28292 16.8031 7.10693C16.8031 7.93094 16.4757 8.7212 15.8931 9.30386L12.3221 12.871L8.7511 16.4381C8.45977 16.7294 8.06464 16.8931 7.65263 16.8931C7.24063 16.8931 6.8455 16.7294 6.55417 16.4381C6.26284 16.1467 6.09917 15.7516 6.09917 15.3396C6.09917 14.9276 6.26284 14.5325 6.55417 14.2411L11.145 9.6581"
                stroke="#9E9EB0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.5 17V22"
                stroke="#9E9EB0"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M21 19.5L16 19.5"
                stroke="#9E9EB0"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};
