import React, { memo } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import TeacherQuestionnaireDoneBodyBlock from "components/TeacherQuestionnaireDoneBodyBlock/TeacherQuestionnaireDoneBodyBlock";

const theme = createTheme({
    spacing: 1,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const TeacherQuestionnaireDonePage = ({ children, ...props }) => {
    return (
        <ScrollToTop>
            <ThemeProvider theme={theme}>
                <TeacherQuestionnaireDoneBodyBlock />
            </ThemeProvider>
        </ScrollToTop>
    );
};

export default memo(TeacherQuestionnaireDonePage);
