import React, { memo } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { CloseRounded, Search } from "@mui/icons-material";
import { ThemeProvider } from "@mui/material/styles";

const TextFilter = ({ children, ...props }) => {
    const handle = (event) => {
        props.handleFunc(event);
    };

    const clear = () => {
        props.clearFunc();
    };

    return (
        <ThemeProvider theme={props.themeWrapper}>
            <TextField
                placeholder={props?.placeholder ? props.placeholder : "Искать"}
                variant={props?.variant ? props.variant : "standard"}
                value={props.valueToBind}
                onInput={handle}
                InputProps={{
                    startAdornment: props?.startAdornment && (
                        <InputAdornment position="start">
                            <IconButton>
                                <Search></Search>
                            </IconButton>
                        </InputAdornment>
                    ),
                    endAdornment: props?.endAdornment && (
                        <InputAdornment position="end">
                            <IconButton onClick={clear}>
                                <CloseRounded></CloseRounded>
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </ThemeProvider>
    );
};

export default memo(TextFilter);
