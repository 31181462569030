import { Box, styled } from "@mui/material";

export const OrderResponeTutorsResponsesTopWrapper = styled(Box)({
    display: "flex",
    height: "36.5px",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "-20px",
    marginBottom: "10px",
});
