import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import * as themes from "components/SettingsBodyBlock/TutorSettings/MainForm/MainFormThemes";
import FeedBackInput from "components/OrdersBodyBlock/OrderCard/OrderRateModal/FeedBackInput";
import SButton from "components/UI/SButton";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { observer } from "mobx-react-lite";
import IconBackRounded from "assets/CommonAssets/SVG/IconBackRounded";
import * as textThemes from "themes/TextThemes";
import { useTranslation } from "react-i18next";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import { memo } from "react";
import { useMedias } from "API/mediasHook";

const AboutTeacherEdit = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t } = useTranslation();

    const [length, setLength] = useState(0);

    const [changed, setChanged] = useState(false);

    const [blocked, setBlocked] = useState(false);

    const handleChange = (value) => {
        if (length > 3000 || length < 500) {
            TeacherQuestionnaireStore.setTemporaryAboutTeacher(value);
            setBlocked(true);
            setChanged(false);
        } else {
            TeacherQuestionnaireStore.setTemporaryAboutTeacher(value);
            setChanged(true);
            setBlocked(false);
        }
    };

    const handleBackClick = () => {
        TeacherQuestionnaireStore.setTemporaryAboutTeacher(TeacherQuestionnaireStore.teacher.about);
        setChanged(false);
        props.handleSet(0);
    };

    return (
        <ScrollToTop>
            <Box style={themes.Top(medias)}>
                <Box sx={themes.EditTitle(medias)}>
                    <SButton
                        variant="icon"
                        onClick={handleBackClick}
                    >
                        <IconBackRounded />
                    </SButton>
                    <Typography sx={medias.sm ? textThemes.HeadlineH41Black : textThemes.HeadlineH5Black}>{t("teacherSettings.questionnaire.aboutYourself")}</Typography>
                </Box>
                <Typography sx={textThemes.Body2Black}>{t("teacherSettings.questionnaire.writeAboutAdvantages")}</Typography>
                <Box sx={themes.FeedBackBlock}>
                    <FeedBackInput
                        setCustomLength={setLength}
                        rows="20"
                        height="496px"
                        width={medias.sm ? "641px" : "100%"}
                        message={t("teacherSettings.questionnaire.minimumWord")}
                        max={3000}
                        value={TeacherQuestionnaireStore.temporaryAboutTeacher}
                        setFeedBackValue={handleChange}
                    />
                    <Box sx={themes.ButtonsBlock}>
                        <SButton
                            variant="unshadowed"
                            padding="10px 20px 11px 20px"
                            disabled={!changed || blocked}
                            onClick={() => {
                                TeacherQuestionnaireStore.setTutorData("about", TeacherQuestionnaireStore.temporaryAboutTeacher);
                                setChanged(false);
                            }}
                        >
                            {t("main.save")}
                        </SButton>
                        <SButton
                            variant="secondary"
                            padding="10px 20px 11px 20px"
                            onClick={() => {
                                TeacherQuestionnaireStore.setTemporaryAboutTeacher(TeacherQuestionnaireStore.teacher.about);
                                setChanged(false);
                            }}
                            disabled={!changed}
                        >
                            {t("main.cancel")}
                        </SButton>
                    </Box>
                </Box>
            </Box>
        </ScrollToTop>
    );
});

export default memo(AboutTeacherEdit);
