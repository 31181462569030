import { IconButton } from "@mui/material";
import React from "react";

const SIconButton = ({ children, onClick }) => {
    return (
        <IconButton
            disableRipple
            onClick={onClick}
            sx={{ color: "#6212FF", padding: "0", width: "15px", height: "15px" }}
        >
            {children}
        </IconButton>
    );
};

export default SIconButton;
