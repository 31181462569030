export const StoriesContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    backgroundColor: "#fff",
    borderRadius: "20px",
    padding: "21px 0",
    maxWidth: "599px",
};

export const StoriesTitle = {
    paddingLeft: "20px",
};

export const BonusesInviteContainer = {
    display: "flex",
    gap: "20px",
};

export const RightPanel = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

export const LeftPanel = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    maxWidth: "598px",
};

export const StudentAccountContent = {
    display: "flex",
    gap: "20px",
    paddingTop: "40px",
    minHeight: "780px",
};

export const MobileStudentAccountContent = {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    paddingTop: "40px",
    minHeight: "780px",
};

export const StudentAccount = {
    borderRadius: "0px 0px 40px 40px",
    background: "#F7F7F7",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "70px",
    // boxSizing: "border-box",
};
