import { memo } from "react";
import { observer } from "mobx-react-lite";
import { Box, LinearProgress, ThemeProvider, Typography } from "@mui/material";
import * as themes from "./ForecastedLevelThemes";
import * as textThemes from "themes/TextThemes";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import Platinum from "assets/PAAssets/SVG/platinum";
import { useTranslation } from "react-i18next";
import { useMedias } from "API/mediasHook";

const ForecastedLevel = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.WhiteContainer(medias)}>
            <Box sx={themes.ForecastedLevel}>
                <Box sx={themes.TopBlock}>
                    <Typography
                        sx={textThemes.Body1Black}
                    >{`${TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth).level}${t(TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth).levelEnding)} ${t("teacherAccount.privilegeProgram.tutorLevel")}`}</Typography>
                    <Box sx={themes.LevelRow}>
                        <Typography sx={textThemes.HeadlineH5Black}>
                            {t(TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth).title)}
                        </Typography>
                        {TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth).level === 1 && (
                            <Box sx={themes.PlatinumIcon}>
                                <Platinum />
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box sx={themes.ProgressContainer}>
                    <ThemeProvider theme={TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth).level === 1 ? themes.theme2 : themes.theme}>
                        <LinearProgress
                            sx={themes.LinearProgress}
                            variant="determinate"
                            value={
                                (TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth /
                                    TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth).nextLevelBoundary) *
                                100
                            }
                        />
                    </ThemeProvider>
                    <Box sx={themes.pointsOutOfContainer}>
                        <Typography sx={textThemes.Caption11Black}>{`${TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth} ${t("teacherAccount.privilegeProgram.points")}`}</Typography>
                        {TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth).level !== 1 && (
                            <Typography
                                sx={textThemes.Caption1DarkGrey}
                            >{`${t("teacherAccount.privilegeProgram.outOf")} ${TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth).nextLevelBoundary}`}</Typography>
                        )}
                    </Box>
                </Box>
            </Box>
            <Box sx={themes.PrivilegesContainer}>
                {TeacherQuestionnaireStore.getTutorPrivilegeLevel(TeacherQuestionnaireStore.teacher.privilegeProgram.nextMonth).privileges.map((privilege, index) => {
                    return (
                        <Typography
                            sx={textThemes.Body2DarkGrey}
                            key={index}
                        >
                            {t(privilege)}
                        </Typography>
                    );
                })}
            </Box>
        </Box>
    );
});

export default memo(ForecastedLevel);
