import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import TutorCard from "../TutorCard/TutorCard";

import { useMedias } from "../../../../API/mediasHook";
import { observer } from "mobx-react-lite";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./MobilePromoTutorCardsCarouselStyles.css";
import Box from "@mui/material/Box";
import * as theme from "./MobilePromoTutorCardsCarouselStyles";
import PromoTutorStore from "../../../../store/PromoTutorStore";

const MobilePromoTutorCardsCarousel = observer(() => {
    const medias = useMedias();

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true, // Отключаем боковые стрелки
        centerMode: true,
        centerPadding: "0",
    };

    return (
        <Box
            sx={{ width: "328px" }}
            className="feedback-slider"
        >
            <Slider {...settings}>
                {PromoTutorStore.tutorsPromoFeedbackList.map((perTutor) => (
                    <Box
                        sx={theme.TutorBLock(medias)}
                        key={perTutor.ID}
                    >
                        <TutorCard
                            key={perTutor.ID}
                            tutor={perTutor}
                            imageSrc={perTutor.tutorPhoto2}
                            title={perTutor.tutorName}
                            description={perTutor.tutorDescribe}
                            quote={perTutor.tutorQuote}
                        />
                    </Box>
                ))}
            </Slider>
        </Box>
    );
});

export default MobilePromoTutorCardsCarousel;
