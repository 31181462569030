import React, { memo, useState } from "react";
import { Avatar, Typography } from "@mui/material";
import * as themes from "./AvatarAreaThemes";
import { ThemeProvider } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import PlayIcon from "assets/CommonAssets/SVG/PlayIcon";
import ModalVideo from "components/UI/ModalVideo/ModalVideo";
import Box from "@mui/material/Box";
import { useMedias } from "../../../../API/mediasHook";
import NameBlock from "./NameBlock";

const AvatarArea = ({ children, ...props }) => {
    const { id } = useParams();

    const [dialogVideoOpen, SetDialogVideoOpen] = useState(false);

    const showVideoDialog = () => {
        SetDialogVideoOpen(true);
    };

    const discardVideoDialog = () => {
        SetDialogVideoOpen(false);
    };

    const medias = useMedias();

    return (
        <Box sx={themes.tcAvatarAreaStyle(medias, props.styleMode)}>
            {props.value.IsAnonymous ? (
                <Box>
                    <Box
                        sx={themes.anonymousAvatarContainer(id, medias)}
                        onClick={() => (!id ? window.open("/tutor/" + props.value._id, "_blank", "noreferrer") : {})}
                    >
                        <Typography sx={themes.anonymousAvatarTextStyle(medias)}>Фото скрыто специалистом</Typography>
                    </Box>
                </Box>
            ) : (
                <Box>
                    <ThemeProvider theme={!id ? themes.TCAvatarThemeClickable(medias) : themes.TCAvatarThemeUnclickable(medias)}>
                        <Avatar
                            src={props.value.AvatarSrc}
                            onClick={() => (!id ? window.open("/tutor/" + props.value._id, "_blank", "noreferrer") : {})}
                        ></Avatar>
                    </ThemeProvider>
                    {!id ? (
                        <Box
                            onClick={showVideoDialog}
                            sx={themes.tcPlayIconBGStyle}
                        >
                            <PlayIcon></PlayIcon>
                        </Box>
                    ) : null}
                </Box>
            )}
            {!medias.sm && (
                <NameBlock
                    value={props.value}
                    styleMode={props.styleMode}
                ></NameBlock>
            )}
            <ModalVideo
                open={dialogVideoOpen}
                handleDiscard={discardVideoDialog}
                value={props.value}
            ></ModalVideo>
        </Box>
    );
};

export default memo(AvatarArea);
