import { styleProcessing } from "API/plugins/Utilities";

export const TooltipPopperProps = {
    sx: {
        "& .MuiTooltip-tooltip": {
            border: "none",
            color: "#222",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "140%",
            borderRadius: "10px",
            bgcolor: "#fff",
            maxWidth: "196px",
            boxShadow: "0px 2px 10px 0px rgba(106, 99, 118, 0.15)",
            padding: "10px",
        },
    },
};

export const ButtonStyle = (theme) => {
    let commonStyle = {
        width: "fit-content",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: { ...commonStyle, width: "100%" },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TutorStudentHome = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    borderRadius: "30px",
    background: "rgba(187, 187, 200, 0.17)",
    padding: "7px 16px 8px 16px",
};
