import { styleProcessing } from "API/plugins/Utilities";

export const ModalCalendarReschedule = (theme) => {
    let commonStyle = {
        width: "665px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            maxWidth: "599px",
            width: "100%",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const InfoTutor = {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
};

export const ClassDetails = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "12px",
        padding: "20px 0px 20px 40px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            padding: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const Avatar = {
    width: "50px",
    height: "50px",
};

export const CalendarContainer = (theme) => {
    let commonStyle = {
        height: "517px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            height: "auto",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ButtonContainer = (theme) => {
    let commonStyle = {
        padding: "13px 40px 14px 40px",
        borderRadius: "0px 0px 20px 20px",
        borderTop: "1px solid var(--Grey-Light-Grey, #BBBBC8)",
        background: "#F7F7F7",
        display: "flex",
        justifyContent: "space-between",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            gap: "15px",
            padding: "0px 20px 10px 20px",
            borderTop: "none",
            background: "none",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const TimeZone = (theme) => {
    let commonStyle = {
        maxWidth: "274px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            maxWidth: "100%",
            textAlign: "center",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
