import React from "react";

import { Stack, Box, Typography } from "@mui/material";

import FileInfo from "./FileInfo";

import { imageDateBox, imagesBox } from "../../ImageChatAttachments/ImageChatAttachmentsThemes";

import * as textStyles from "themes/TextThemes";

const WithFiles = ({ files, downloadFile }) => {
    return (
        <>
            {Object.keys(files).map((monthyear, index) => {
                return (
                    <Box
                        sx={imageDateBox}
                        key={index}
                    >
                        <Typography sx={textStyles.Body2DarkGrey}>{monthyear}</Typography>
                        <Box sx={imagesBox}>
                            {files[monthyear].reverse().map((file, index) => {
                                return (
                                    <Stack
                                        key={index}
                                        direction="column"
                                        width="100%"
                                        spacing={index !== files.length - 1 ? 2.5 : 0}
                                    >
                                        <FileInfo
                                            key={file?.message_id}
                                            fileInfo={file}
                                            downloadFile={downloadFile}
                                        />
                                        {index !== files.length - 1 && (
                                            <span
                                                style={{
                                                    borderTop: "1px dashed #DADADF",
                                                }}
                                            ></span>
                                        )}
                                    </Stack>
                                );
                            })}
                        </Box>
                    </Box>
                );
            })}
        </>
    );
};

export default WithFiles;
