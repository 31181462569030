export const PopperProps = {
    "& .MuiTooltip-tooltip": {
        border: "none",
        color: "#222",
        fontFamily: "Inter",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "140%",
        borderRadius: "10px",
        bgcolor: "#fff",
        width: "fit-content",
        boxShadow: "0px 2px 10px 0px rgba(106, 99, 118, 0.15)",
        padding: "10px",
    },
};

export const componentsProps = {
    color: "#fff",
};

export const button = { padding: 0, display: "flex" };
