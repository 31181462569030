import { createTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

export const themeWrapper = (useIcon) =>
    createTheme({
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#ffffff00",
                        "--TextField-brandBorderHoverColor": "#6212FF",
                        "--TextField-brandBorderFocusedColor": "#6212FF",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)",
                        },
                        "& .Mui-focused": {
                            background: "#FFFFFF",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "var(--TextField-brandBorderColor)",
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderHoverColor)",
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: "var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&:before, &:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        "&:before": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)",
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                        },
                    },
                },
            },

            MuiAutocomplete: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        ".MuiAutocomplete-input": {
                            padding: "2.5px 4px 2px 10px!important",
                        },
                        ".MuiAutocomplete-clearIndicator:hover": {
                            background: "#FFFFFF",
                            color: "#6212FF",
                        },
                        ".MuiAutocomplete-popupIndicator:hover": {
                            background: "#FFFFFF",
                            color: "#6212FF",
                        },
                    },
                    option: {
                        '&[aria-selected="true"]': {
                            background: useIcon ? "" : "#6212FF!important",
                            color: useIcon ? "#6212FF" : "#FFFFFF",
                        },
                        "&:hover": {
                            background: useIcon ? "" : "#6212FF!important",
                            color: useIcon ? "#6212FF" : "#FFFFFF",
                        },
                        "&:hover div > span": {
                            color: useIcon ? "" : "#FFFFFF!important",
                        },
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "130%",
                        fontFeatureSettings: "'tnum' on, 'lnum' on",
                        color: "#222222",
                        flex: "none",
                        order: 0,
                        flexGrow: 0,
                    },
                },
            },

            MuiInputBase: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        boxSizing: "border-box",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "13px 16px",
                        gap: "4px",
                        width: "279px",
                        height: "60px",
                        marginTop: "-1px",
                        marginLeft: "-1px",
                        flex: "none",
                        order: 1,
                        flexGrow: 0,
                        "&.MuiInputBase-root": {
                            border: "1px!important solid #9E9EB0",
                            borderRadius: "12px",
                            display: "flex",
                            alignItems: "flex-end",
                        },
                        ".MuiAutocomplete-endAdornment": {
                            marginTop: "15px",
                        },
                        ".MuiSvgIcon-root": {
                            width: "16px",
                            height: "16px",
                        },
                        ".MuiInputBase-input": {
                            width: "-webkit-fill-available!important",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "14px",
                            lineHeight: "130%",
                            fontFeatureSettings: "'tnum' on, 'lnum' on",
                            color: "#222222",
                            flex: "none",
                            order: 0,
                            flexGrow: 0,
                        },
                    },
                },
            },
        },
    });

export const themeLabel = {
    marginLeft: "16px",
    paddingTop: "8px",
    position: "absolute",
    zIndex: 1,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "150%",
    color: "#67677A",
};

export const themeOptionContainer = {
    backgroundColor: "white",
};

export const themeIconContainer = createTheme({
    padding: "0 12px 0 0",
});

export const DrawerTheme = createTheme({
    components: {
        MuiDrawer: {
            styleOverrides: {
                // Name of the slot
                paper: {
                    width: "100%",
                    background: "#FFFFFF",
                    "&::-webkit-scrollbar": {
                        width: "10px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        borderRadius: "10px",
                        background: "rgba(187, 187, 200, 0.50)",
                        height: "60px",
                    },
                },
            },
        },
    },
});

export const drawerContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    margin: "20px 16px 0 16px",
};

export const headerContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    color: "#9E9EB0",
};

export const CloseIconTheme = createTheme({
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid #9E9EB0",
                    borderRadius: "50%",
                    background: "#FFFFFF",
                    position: "relative",
                    width: "24px",
                    height: "24px",
                    marginLeft: "196px",
                    "&:hover": {
                        background: "#FFFFFF!important",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "#FFFFFF!important",
                        borderColor: "#6212FF",
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    padding: 0,
                    ".MuiSvgIcon-root": {
                        width: "16px",
                        height: "16px",
                    },
                    "&:hover": {
                        color: "#6212FF",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        color: "#6212FF",
                        borderColor: "#6212FF",
                    },
                },
            },
        },
    },
});

export const MobileTheme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "--TextField-brandBorderColor": "#9E9EB0",
                    "--TextField-brandBorderHoverColor": "#6212FF",
                    "--TextField-brandBorderFocusedColor": "#6212FF",
                    "& label.Mui-focused": {
                        color: "var(--TextField-brandBorderFocusedColor)",
                    },
                    "& .Mui-focused": {
                        background: "#FFFFFF",
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: "var(--TextField-brandBorderColor)",
                },
                root: {
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: "var(--TextField-brandBorderHoverColor)",
                    },
                    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: "var(--TextField-brandBorderFocusedColor)",
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    "&:before, &:after": {
                        borderBottom: "2px solid var(--TextField-brandBorderColor)",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
                    },
                    "&.Mui-focused:after": {
                        borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    "&:before": {
                        borderBottom: "1px solid var(--TextField-brandBorderColor)",
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderBottom: "1px solid var(--TextField-brandBorderHoverColor)",
                    },
                    "&.Mui-focused:after": {
                        borderBottom: "1px solid var(--TextField-brandBorderFocusedColor)",
                    },
                },
            },
        },

        MuiAutocomplete: {
            styleOverrides: {
                // Name of the slot
                root: {
                    ".MuiAutocomplete-popupIndicator:hover": {
                        background: "#FFFFFF",
                        color: "#6212FF",
                    },
                },
                option: {
                    '&[aria-selected="true"]': {
                        background: "#6212FF!important",
                        color: "#FFFFFF",
                    },
                    "&:hover": {
                        background: "#6212FF!important",
                        color: "#FFFFFF",
                    },
                    "&:hover div > span": {
                        color: "#FFFFFF!important",
                    },
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "130%",
                    fontFeatureSettings: "'tnum' on, 'lnum' on",
                    color: "#222222",
                    flex: "none",
                    order: 0,
                    flexGrow: 0,
                },
                clearIndicator: {
                    "&:hover": {
                        color: "#6212FF",
                        background: "white",
                    },
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                // Name of the slot
                root: {
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "13px 16px",
                    gap: "4px",
                    width: "197px",
                    height: "44px",
                    flex: "none",
                    order: 1,
                    flexGrow: 0,
                    "&.MuiInputBase-root": {
                        border: "1px!important solid #9E9EB0",
                        height: "34px",
                        borderRadius: "12px",
                        width: "100%",
                        paddingLeft: 0,
                        paddingTop: 0,
                    },
                    ".MuiInputBase-input": {
                        width: "-webkit-fill-available!important",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "150%",
                        fontFeatureSettings: "'tnum' on, 'lnum' on",
                        color: "#222222",
                        flex: "none",
                        order: 0,
                        flexGrow: 0,
                    },
                },
            },
        },
    },
});
