import { styleProcessing } from "API/plugins/Utilities";

export const OtherTutorBlock = (theme) => {
    let commonStyle = {
        display: "inline-Flex",
        flexDirection: "column",
        left: "618px",
        top: "601px",
        position: "absolute",
        marginTop: "10px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            position: "static",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const OtherTutors = {
    position: "relative",
    display: "inline-flex",
};

export const OtherTutor1 = {
    position: "relative",
    width: "56px",
    height: "56px",
    // top:'601px',
    // left:'618px',
};

export const OtherTutor2 = {
    position: "relative",
    width: "56px",
    height: "56px",
    left: "-19px",
};

export const OtherTutor3 = {
    position: "relative",
    width: "56px",
    height: "56px",
    left: "-38px",
};

export const OtherTutor4 = {
    position: "relative",
    width: "56px",
    height: "56px",
    left: "-57px",
};

export const Stars = {
    width: "60px",
    height: "12px",
    position: "relative",
    left: "-87px",
    top: "45px",
    padding: "3px 5px",
    borderRadius: "36px",
    background: "#FFF",
    boxShadow: "1px 4px 20px 0px rgba(20, 0, 61, 0.08)",
    display: "flex",
};

export const OtherText = (theme) => {
    let commonStyle = {
        width: "255px",
        color: "#222",
        left: "-106px",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "150%" /* 21px */,
        position: "relative",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            display: "none",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const FindTutorText = (theme) => {
    let commonStyle = {
        position: "relative",
        marginTop: "28px",
        width: "fit-content",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            marginTop: "20px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const OtherTextMobile = (theme) => {
    let commonStyle = {
        display: "none",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            width: "328px",
            color: "#222",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "150%" /* 21px */,
            position: "relative",
            marginTop: "12px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
