import { Box, Typography } from "@mui/material";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import BalanceStore from "store/BalanceStore";
import * as theme from "./OperationsTableMobileThemes";
import * as tTheme from "themes/TextThemes";
import { observer } from "mobx-react-lite";
const OperationsTableMobile = observer(() => {
    const { t } = useTranslation();

    return (
        <Box sx={theme.OperationsTableMobileWrapper}>
            <Box sx={theme.Header}>
                <Typography sx={tTheme.Body2Grey}>{t("main.balance.service")}</Typography>
                <Typography sx={tTheme.Body2Grey}>{t("main.balance.amount")}</Typography>
            </Box>
            {BalanceStore.balanceListFiltered.map((el) => (
                <Box
                    key={el.date}
                    sx={theme.ContentWrapper}
                >
                    <Box>
                        <Typography sx={tTheme.Body2Black}>{el.service}</Typography>
                        <Typography sx={tTheme.Body2Grey}>{el.reason}</Typography>
                        <Typography sx={tTheme.Body2Grey}>{el.date}</Typography>
                    </Box>
                    <Box sx={theme.SummPos}>
                        <Typography sx={tTheme.Body2Black}>{el.amount}</Typography>
                        <Typography sx={[tTheme.Body2Grey, theme.discountText]}>{el.discount}</Typography>
                        <Typography sx={[tTheme.Body2Black, theme.pointsText(el.points.charAt(0) === "+")]}>{el.points}</Typography>
                    </Box>
                </Box>
            ))}
        </Box>
    );
});

export default memo(OperationsTableMobile);
