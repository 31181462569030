import SModal from "components/UI/Modal/SModal";
import React, { useState } from "react";
import AreYouSureStep from "components/OrdersBodyBlock/OrderCard/CancelOrder/AreYouSureStep";
import CancelReasonsStep from "components/OrdersBodyBlock/OrderCard/CancelOrder/CancelReasonsStep";
import OrderIsCancelled from "components/OrdersBodyBlock/OrderCard/CancelOrder/OrderIsCancelled";
import orderResponseStore from "store/OrderResponseStore";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";

const CancelStepModal = observer(({ dialogOpen, hideDialog }) => {
    const [dialogNumber, setDialogNumber] = useState(1);

    const dependencies = orderResponseStore.toggleCancelOrderModal;

    useEffect(() => {
        if (dependencies === false) {
            setTimeout(() => {
                setDialogNumber(1);
            }, 100);
        }
    }, [dependencies]);
    return (
        <>
            {dialogNumber === 1 ? (
                <SModal
                    open={dialogOpen}
                    handleClose={hideDialog}
                >
                    <AreYouSureStep setDialogNumber={setDialogNumber} />
                </SModal>
            ) : dialogNumber === 2 ? (
                <SModal
                    open={dialogOpen}
                    handleClose={hideDialog}
                >
                    <CancelReasonsStep setDialogNumber={setDialogNumber} />
                </SModal>
            ) : (
                <SModal
                    open={dialogOpen}
                    handleClose={hideDialog}
                >
                    <OrderIsCancelled hideDialog={hideDialog} />
                </SModal>
            )}
        </>
    );
});

export default CancelStepModal;
