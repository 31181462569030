import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./CardActionsThemes";
import { Button, Tooltip, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";

const CardActions = observer(({ children, ...props }) => {
    return (
        <Box sx={themes.actionsContainerStyle}>
            <Box sx={themes.priceContainerStyle}>
                <Box sx={themes.textContainerStyle}>
                    <Typography sx={themes.fromTextStyle}>от</Typography>
                    <Typography sx={themes.fromTextValueStyle}>{props.value.PriceFrom.toLocaleString()} ₽/ч</Typography>
                </Box>
                <ThemeProvider theme={themes.AddCashbackButtonTheme}>
                    <Tooltip
                        title={
                            <Typography sx={themes.tooltipCashbackStyle}>
                                <span>
                                    При обучении у репетитора вам вернётся <span style={themes.tooltipCashbackValueStyle}>{props.value.Cashback} баллов </span>на бонусный счёт
                                </span>
                            </Typography>
                        }
                        arrow={true}
                        placement="right"
                    >
                        <Button
                            onClick={() => {
                                console.log("clicked");
                            }}
                        >
                            <Typography style={themes.cashbackTextStyle}>+{props.value.Cashback}</Typography>
                        </Button>
                    </Tooltip>
                </ThemeProvider>
            </Box>
        </Box>
    );
});

export default memo(CardActions);
