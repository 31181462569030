import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import * as themes from "components/SettingsBodyBlock/TutorSettings/MainForm/MainFormThemes";
import * as TimeTheme from "./TimezoneThemes";
import { Typography } from "@mui/material";
import * as textThemes from "themes/TextThemes";
import Box from "@mui/material/Box";
import SButton from "components/UI/SButton";
import EditTimezone from "./EditTimezone/EditTimezone";
import React, { useState } from "react";
import { memo } from "react";
import TeacherQuestionnaireStore from "store/TeacherQuestionnaireStore";
import IconBackRounded from "assets/CommonAssets/SVG/IconBackRounded";
import { useMedias } from "API/mediasHook";
import SIconButton from "../../SIconButton";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";

const Timezone = observer(({ children, ...props }) => {
    const medias = useMedias();

    const { t, i18n } = useTranslation();

    const [editZone, setEditZone] = useState(false);

    const handleEditClick = () => {
        setEditZone(true);
    };

    const handleSaveClick = () => {
        TeacherQuestionnaireStore.setTutorData("timeZone", JSON.parse(JSON.stringify(TeacherQuestionnaireStore.temporaryTimezone)));
    };

    const handleCancelClick = () => {
        TeacherQuestionnaireStore.setTemporaryTimezone(JSON.parse(JSON.stringify(TeacherQuestionnaireStore.teacher.timeZone)));
    };

    const handleBackClick = () => {
        setEditZone(false);
        TeacherQuestionnaireStore.setTemporaryTimezone(JSON.parse(JSON.stringify(TeacherQuestionnaireStore.teacher.timeZone)));
    };

    return (
        <Box sx={themes.SmallItem(medias)}>
            <Box sx={themes.ItemContent(medias)}>
                <Box sx={themes.TitleAndEdit}>
                    <Box sx={themes.EditTitle(medias)}>
                        {editZone && (
                            <SButton
                                variant="icon"
                                onClick={handleBackClick}
                            >
                                <IconBackRounded />
                            </SButton>
                        )}
                        <Typography sx={medias.sm ? textThemes.HeadlineH41Black : textThemes.HeadlineH5Black}>{t("teacherSettings.settings.timezone")}</Typography>
                    </Box>
                    {!editZone && (
                        <>
                            {medias.sm ? (
                                <SButton
                                    onClick={handleEditClick}
                                    variant="text"
                                >
                                    {t("teacherSettings.questionnaire.edit")}
                                </SButton>
                            ) : (
                                <SIconButton onClick={handleEditClick}>
                                    <DriveFileRenameOutlineOutlinedIcon />
                                </SIconButton>
                            )}
                        </>
                    )}
                </Box>
                {!editZone ? (
                    <Typography sx={textThemes.Body1Black}>{TeacherQuestionnaireStore.teacher.timeZone.text}</Typography>
                ) : (
                    <Box sx={themes.AdditionalBlocks}>
                        <Box sx={themes.BottomBlock}>
                            <Typography sx={textThemes.Body1Black}>{t("teacherSettings.settings.makeSure")}</Typography>
                            <Box sx={themes.TimeZoneItem}>
                                {medias.sm && <Typography sx={[textThemes.Body2Black, TimeTheme.TextNoWrapp]}>{t("teacherSettings.settings.timezone")}</Typography>} <EditTimezone />
                            </Box>
                        </Box>
                        <Box sx={themes.ButtonsBlock}>
                            <SButton
                                onClick={handleSaveClick}
                                variant="unshadowed"
                                padding="10px 20px 11px 20px"
                                disabled={TeacherQuestionnaireStore.getDisabledSaveTimezone()}
                            >
                                {t("main.save")}
                            </SButton>
                            <SButton
                                variant="secondary"
                                padding="10px 20px 11px 20px"
                                onClick={handleCancelClick}
                                disabled={TeacherQuestionnaireStore.getDisabledSaveTimezone()}
                            >
                                {t("main.cancel")}
                            </SButton>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
});

export default memo(Timezone);
