import { createTheme } from "@mui/material/styles";
import { styleProcessing } from "API/plugins/Utilities";

export const filterByContainerStyle = (theme) => {
    let commonStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
            padding: "12px 20px",
            background: "rgba(187, 187, 200, 0.17)",
            borderRadius: "10px",
            justifyContent: "space-between",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const filterByOptionsSwitcherStyle = {
    display: "flex",
    gap: "4px",
    justifyContent: "space-between",
    width: "100%",
};

export const filterByOptionsTextStyle = {
    cursor: "pointer",
    color: "var(--text-black, #222)",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
};

export const MenuTheme = createTheme({
    components: {
        MuiMenu: {
            styleOverrides: {
                // Name of the slot
                root: {},
                paper: {
                    borderRadius: "10px",
                    boxShadow: "0px 4px 10px 0px rgba(106, 99, 118, 0.15)",
                    marginTop: 10,
                    ".MuiList-root": {
                        display: "flex",
                        width: "max-content",
                        padding: "7px 4px",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    },
                    ".MuiMenuItem-root": {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        alignSelf: "stretch",
                        gap: 10,
                        padding: "5px 12px",
                        "&:hover": {
                            background: "rgba(187, 187, 200, 0.17)!important",
                            color: "#6212FF!important",
                            borderRadius: "10px!important",
                        },
                        "&.Mui-selected": {
                            background: "rgba(187, 187, 200, 0.17)!important",
                            color: "#6212FF!important",
                            borderRadius: "10px!important",
                        },
                    },
                },
            },
        },
    },
});

export const IconOptionsTheme = createTheme({
    components: {
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    color: "#222222!important",
                    padding: 0,
                },
            },
        },
    },
});

export const IconCheckTheme = createTheme({
    components: {
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    color: "#6212FF!important",
                    padding: 0,
                },
            },
        },
    },
});

export const IconContainerStyle = {
    display: "flex",
    minWidth: 24,
};

export const horizontalSeparatorStyle = {
    width: "827px",
    height: "1px",
    marginTop: "10px",
    background: "#DADADF",
};

export const FilterContainer = (theme) => {
    let commonStyle = {
        maxWidth: "804px",
        width: "100%",
        boxSizing: "border-box",
        borderRadius: "10px",
        background: "rgba(187, 187, 200, 0.17)",
        height: "44px",
        display: "flex",
        gap: "20px",
        alignItems: "center",
        padding: "0px 20px",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            background: "none",
            gap: "10px",
            height: "",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const PointsLink = (condition) => {
    return {
        fontWeight: condition ? "500" : "400",
        color: condition ? "#19B20C" : "#222",
        lineHeight: "inherit",
    };
};

export const RefStatContainer = (theme) => {
    let commonStyle = {
        maxWidth: "804px",
        width: "100%",
        boxSizing: "border-box",
        borderRadius: "10px",
        background: "rgba(187, 187, 200, 0.17)",
        height: "44px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: "20px",
        paddingRight: "20px",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            height: "auto",
            background: "none",
            flexDirection: "column",
            width: "100%",
            padding: "0",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const PointsContainter = (theme) => {
    let commonStyle = {
        display: "flex",
        gap: "20px",
        alignItems: "center",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            flexDirection: "column",
            gap: "10px",
            width: "100%",
            marginBottom: "12px",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ReferalPointsContainterText = (theme) => {
    let commonStyle = {
        display: "flex",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            padding: "12px 20px",
            borderRadius: "10px",
            boxSizing: "border-box",
            background: "rgba(187, 187, 200, 0.17)",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};

export const ReferralProgramTermsLink = (theme) => {
    let commonStyle = {
        cursor: "pointer",
    };

    let styleObject = {
        xlStyle: commonStyle,
        lgStyle: commonStyle,
        mdStyle: commonStyle,
        smStyle: commonStyle,
        xsStyle: {
            ...commonStyle,
            marginLeft: "auto",
        },
        defaultStyle: commonStyle,
    };

    return styleProcessing(theme, styleObject);
};
