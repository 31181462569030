import React, { memo } from "react";
import LikeDislikeBlock from "components/AccountBodyBlock/StudentAccount/Stories/Stories/LikeDislikeBlock/LikeDislikeBlock";
import * as themes from "components/AccountBodyBlock/StudentAccount/Stories/Stories/StoryThemes";
import * as textThemes from "themes/TextThemes";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const WhatToDoStory = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.StoryBox}>
            <Box sx={themes.WhatToDoOuter}>
                <Box sx={themes.StoryText}>
                    <Typography sx={textThemes.Caption700White}>{t("main.studentAccount.stories.whatToDo")}</Typography>
                    <Typography sx={textThemes.Body1White}>{t("main.studentAccount.stories.rightBottomCorner")}</Typography>
                </Box>
            </Box>
            <LikeDislikeBlock story="WhatToDoStory" />
        </Box>
    );
};

export default memo(WhatToDoStory);
