import React, { memo } from "react";
import { observer } from "mobx-react-lite";
import * as themes from "./AttachedContentThemes";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import DocIconSVG from "assets/CommonAssets/SVG/docIconSVG";

const AttachedContent = observer(({ children, ...props }) => {
    return (
        <Box sx={themes.attachedContentContainerStyle}>
            <Typography sx={themes.commentTextStyle}>{props.value.Comment}</Typography>
            <Box sx={themes.attachedFilesContainerStyle}>
                {props.value.FileAttachments.map((file) => (
                    <Box
                        sx={themes.fileContainerStyle}
                        key={file.ID}
                    >
                        <DocIconSVG></DocIconSVG>
                        <Box sx={themes.fileInfoContainerStyle}>
                            <Typography sx={themes.fileNameStyle}>{file.FileName}</Typography>
                            <Typography sx={themes.fileSizeStyle}>{file.FileSize}</Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
});

export default memo(AttachedContent);
