import { createTheme } from "@mui/material/styles";

export const filtersPopular = (theme) => ({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    padding: 0,
    gap: 5,
    width: "fit-content",
    maxWidth: "1216px",
    height: "fit-content",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 32,
});

export const textPopular = {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    color: "#222222",
};

export const TagTheme = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                // Name of the slot
                root: {
                    height: "27px",
                    "&:hover": {
                        background: "#FFFFFF!important",
                        borderColor: "#6212FF",
                    },
                    "&:active": {
                        background: "#FFFFFF!important",
                        borderColor: "#6212FF",
                    },
                },
                label: {
                    padding: "2px 10px 4px 10px!important",
                },
            },
        },
    },
});
