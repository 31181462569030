import React from "react";
import * as themes from "./TopQuestionsStyle";
import * as textThemes from "themes/TextThemes";
import { Typography } from "@mui/material";
import AccordionRef from "components/ReferralPromoBodyBlock/Accordion/Accordion";
import Box from "@mui/material/Box";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const TopQuestions = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    return (
        <Box sx={themes.TopQuestionsBlock}>
            <Box sx={themes.TitleBlock}>
                <Typography sx={textThemes.HeadlineH2Black}>{t("promoPages.inviteFriend.topFiveQuestions")}</Typography>
            </Box>
            <Box sx={themes.AccordionBlock}>
                <AccordionRef
                    header={t("promoPages.inviteFriend.howToSendQuestion")}
                    text={t("promoPages.inviteFriend.howToSendAnswer")}
                />

                <AccordionRef
                    header={t("promoPages.inviteFriend.severalInvitesQuestion")}
                    text={t("promoPages.inviteFriend.severalInvitesAnswer")}
                />

                <AccordionRef
                    header={t("promoPages.inviteFriend.howToGetKnowQuestion")}
                    text={t("promoPages.inviteFriend.howToGetKnowAnswer")}
                />

                <AccordionRef
                    header={t("promoPages.inviteFriend.howToUseQuestion")}
                    text={t("promoPages.inviteFriend.howToUseAnswer")}
                />

                <AccordionRef
                    header={t("promoPages.inviteFriend.howCanIGetPointsQuestion")}
                    text={t("promoPages.inviteFriend.howCanIGetPointsAnswer")}
                />
            </Box>
        </Box>
    );
};

export default memo(TopQuestions);
